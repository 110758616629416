import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import StandardField from "../../../KF/KofiErfassung/KofiErfassungBelegForm/StandardField/StandardField";

import { replaceText } from "../../../../global/functions/utils";

import {
  cloneDeep,
  map,
} from "lodash-es";

// @vue/component
export default {
  name: "BelegButtonVersionsvergleichStandardFieldTableRow",
  components: {
    FormElementReadOnly,
    PuxTranslate,
    SnapshotIcon,
  },
  mixins: [
    StandardField,
  ],
  props: {
    modelErfasst: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: true,
      default: undefined,
    },
    modelAnerkannt: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: true,
      default: undefined,
    },
  },
  created() {
    this.initField();
    this.setFieldData();
  },
  methods: {
    setFieldData() {
      if (this.config.field === "verausgabung_az") {
        const FIELD = cloneDeep(this.field);
        const URL = replaceText({
          text: FIELD.url,
          object: {
            v_id: this.vertrag.pk
          }
        });
        const API_SAVE_URL = replaceText({
          text: FIELD.apiSaveId,
          object: {
            v_id: this.vertrag.pk
          }
        });
        this.getListHttp({
          url: URL,
          apiSaveId: API_SAVE_URL,
        }).then(
          response => {
            this.fieldData = map(
              response,
              auszahlung => {
                return {
                  pk: auszahlung.pk,
                  bez: `${ auszahlung.name } - ${ auszahlung.az_nummer } (${ this.filterDate(auszahlung.az_datum_ausgezahlt) })`
                };
              }
            );
          },
        );
      }
    },
  },
};
