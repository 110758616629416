import {
  useStore,
} from "vuex";

import {
  gettext,
  isTranslatePlaceholder,
  replaceText,
} from "../functions/utils";

export default function NotificationAPI() {
  const store = useStore();

  const addNotification = ({ text, type = "success", timeout, extra }) => {
    let textLocal = text;
    const TYPE = type === "error" ? "danger" : type;
    if (isTranslatePlaceholder(text)) {
      textLocal = replaceText({ text: gettext(textLocal), object: extra });
    }
    store.dispatch("notification/ADD_NOTIFICATION_ACT", { text: textLocal, type: TYPE, timeout });
  };

  return {
    addNotification,
  };
}
