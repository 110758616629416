"use strict";

import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import parameter from "./parameter";

const auswahl_katalog = angular.module("dias.standard_foerdermodule.kennzahlen", [
  foerdermoduleRegistry.name,
  parameter.name,
]);

export default auswahl_katalog;
