import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spinner from "../../../global/components/Spinner/Spinner.vue";

import CalendarNavigationList from "../CalendarConst";
import moment from "moment";

// @vue/component
export default {
  name: "CalendarNavigation",
  components: {
    AngularLink,
    Permission,
    PuxButton,
    PuxTranslate,
    Spinner,
  },
  mixins: [],
  props: {
    dateTypeActive: {
      type: String,
      required: true,
    },
    currentDate: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
    },
    changeDateType: {
      type: Function,
      required: true,
    },
    changeCurrentDate: {
      type: Function,
      required: true,
    },
    openModalCreate: {
      type: Function,
      required: true,
    },
    downloadCalendar: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      dateList: CalendarNavigationList,
    };
  },
  computed: {
    getButtonClass() {
      return key => key === this.dateTypeActive ? "primary" : "default";
    },
    getDateHeader() {
      if (!this.currentDate) {
        return "";
      }
      return this[`getDateHeader_${ this.dateTypeActive }`];
    },
    getDateHeader_year() {
      return moment(this.currentDate).format("YYYY");
    },
    getDateHeader_month() {
      return moment(this.currentDate).format("MMMM YYYY");
    },
    getDateHeader_week() {
      const WEEK_DAY_FIRST = moment(this.currentDate);
      const WEEK_DAY_LAST = moment(WEEK_DAY_FIRST).add(6, "days");
      return `${ WEEK_DAY_FIRST.format("DD.MM") } - ${ WEEK_DAY_LAST.format("DD.MM.YYYY") }`;
    },
    getDateHeader_day() {
      return moment(this.currentDate).format("DD. MMMM YYYY");
    },
  },
  created() {

  },
  methods: {
    onChangeCurrentDate(direction) {
      this.changeCurrentDate({ direction });
    },

    toToday() {
      this.changeDateType({ statusToday: true });
    },

    onChangeDateType(item) {
      if (this.dateTypeActive === item.key) {
        return;
      }
      this.changeDateType({ key: item.key });
    },
  },
};
