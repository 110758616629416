"use strict";

import "restangular";
import angular from "angular";

import CoreModels from "dias/core/models";
import Teams from "./teams.service";
import Teamroutings from "./teamroutings.service";
import Teamroutingzustaendige from "./teamroutingzustaendige.service";
import MitgliederUUIDs from "./mitgliederUUIDs.service";

const modelsModule = angular.module("teams.models", [
  "restangular",
  CoreModels.name
])

  .provider("Teams", Teams)
  .provider("Teamroutings", Teamroutings) 
  .provider("Teamroutingzustaendige", Teamroutingzustaendige)

  .constant("MitgliederUUIDs", MitgliederUUIDs)

;

export default modelsModule;
