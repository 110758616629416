import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  FilterDateMixin,
} from "../../../../../global/mixins/FiltersMixin";

import {
  gettext,
} from "../../../../../global/functions/utils";

// @vue/component
export default {
  name: "VertragOverviewTermin",
  components: {
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    FilterDateMixin,
  ],
  props: {
    termin: {
      type: Object,
      required: true,
    }
  },
  computed: {
    terminStartDate() {
      return this.filterDate(this.termin.ter_von, "DD. MMMM YYYY");
    },

    terminStartTime() {
      return this.filterDate(this.termin.ter_von, "time");
    },

    isTerminAllDay() {
      return Math.ceil(this.termin.duration / 3600) % 24 === 0 || this.termin.duration / 3600 / 24 >= 1;
    },

    terminDurationInDays() {
      return this.filterDuration(this.termin.duration, true);
    },

    terminDurationInHours() {
      return this.filterDuration(this.termin.duration);
    },

    terminDuration() {
      if (this.isTerminAllDay) {
        return this.terminDurationInDays;
      }
      return this.terminDurationInHours;
    },

    textTermin() {
      return this.isTerminAllDay ? "_TXT_VERTRAG_OVERVIEW_TERMIN_ALL_DAY_{{datum}}_{{duration}}_" : "_TXT_VERTRAG_OVERVIEW_TERMIN_{{datum}}_{{start}}_{{duration}}_";
    },

    extraForTerminTranslate() {
      const EXTRA = {
        datum: this.terminStartDate,
        duration: this.terminDuration,
      };
      if (!this.isTerminAllDay) {
        EXTRA.start = this.terminStartTime;
      }
      return EXTRA;
    }
  },
  methods: {
    filterDuration(value, showInDays = false) {
      const LOCAL_VALUE = Math.trunc(value) || 0;
      if (showInDays) {
        const UNIT = LOCAL_VALUE / 3600 / 24 > 1 ? "_TXT_DAYS_" : "_TXT_DAY_";
        return `${ Math.ceil(LOCAL_VALUE / 3600) / 24 } ${ gettext(UNIT) }`;
      } else if (LOCAL_VALUE > 3599) {
        const UNIT = LOCAL_VALUE / 3600 > 1 ? "_TXT_HOURS_" : "_TXT_HOUR_";
        return `${ Math.ceil(LOCAL_VALUE / 3600) } ${ gettext(UNIT) }`;
      }
      return `${ Math.floor(LOCAL_VALUE / 60) } ${ gettext("_TXT_MINUTES_") }`;
    },
  },
};
