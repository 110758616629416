// Modul zur Erfassung von einfachen Finanzierungs-Positionen
"use strict";

import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";
import VertragModels from "dias/vertrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";

const zuschuss_mit_finanzierungseingabe = angular.module("dias.kosten_finanzierung.modul.zuschuss_mit_finanzierungseingabe", [
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
  VertragModels.name,
]);

zuschuss_mit_finanzierungseingabe.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("zuschuss_mit_finanzierungseingabe", "modul-zuschuss-mit-finanzierungseingabe");
})

  .directive("modulZuschussMitFinanzierungseingabe", builder.directive(template, controller));

export default zuschuss_mit_finanzierungseingabe;
