import DokartUUIDs from "../../../../../const/DokartUUIDs";
import BelegModalRechnenhilfeButtonMixin from "../BelegModalRechnenhilfeButtonMixin";
import BelegModalMixin from "../BelegModalMixin";
import KBelegPruefmethodeUUIDs from "../../../../../const/KBelegPruefmethodeUUIDs";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PermissionMixin from "../../../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../../../global/mixins/SyConfigMixin";
import {
  EventBus,
} from "../../../../../global/functions/event-bus";
import {
  FilterDateMixin,
} from "../../../../../global/mixins/FiltersMixin";
import {
  cloneDeep,
  find,
  forEach,
  get,
  sortBy,
} from "lodash-es";
import KVertragskostenbelegStatusUUIDs from "../../../../../const/KVertragskostenbelegStatusUUIDs";

// @vue/component
export default {
  name: "BelegModalEdit",
  components: {
    PuxButton,
  },
  mixins: [
    FilterDateMixin,
    BelegModalMixin,
    BelegModalRechnenhilfeButtonMixin,
    PermissionMixin,
    SyConfigMixin
  ],
  props: {
    perioden: {
      type: Array,
      required: true,
    },
    periodeObj: {
      type: Object,
      required: true,
    },
    periodeTeilForPeriode: {
      type: Object,
      required: true,
    },
    antragPk: {
      type: String,
      required: true,
    },
    documentsForBelege: {
      type: Object,
      default: undefined,
    },
    canAnerkennenBeleg: {
      type: Boolean,
      required: true,
    },
    canUpdateBeleg: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      indexVon: undefined,
      indexBis: undefined,
      currentPeriodPk: undefined,
      period: undefined,
      periodeList: undefined,
      statusInternErgaenzt: undefined,
      showBelegnummer: true
    };
  },
  computed: {
    statusDisabledLocal() {
      return (!this.pruefer && this.currentBeleg && this.currentBeleg.status.pk === KVertragskostenbelegStatusUUIDs.GEPRUEFT) || !this.canUpdateBeleg;
    },

    statusHasPermVertragskostenpositionsbelegeAnerkennen() {
      return this.checkPermissionsSync({ perm: "vertragskostenpositionsbelege.anerkennen" });
    },

    statusDisabledButtonSave() {
      return !this.model.dokument_geschwaerzt ||
        !this.model.dokument ||
        !this.model.wert ||
        !this.model.beleg ||
        this.status.loading;
    },

    periodePk() {
      return get(this.periodeObj, `${ this.model.posperiode }.periode_obj.pk`);
    },
  },
  created() {
    this.setBelegeList();
    this.showBelegnummer = this.getSyConfigsValue("belege_nummer").anzeigen;
    this.loadNextBelegnummer();
    this.setBelegeForOptionsList();
  },
  methods: {
    setBelegeList() {
      const BELEGE = [];
      forEach(cloneDeep(this.belege), belegPeriod => {
        forEach(belegPeriod, beleg => {
          BELEGE.push(beleg);
        });
      });
      this.belegeList = sortBy(BELEGE, o => {
        if (!get(o, "argument_erfasst.von")) {
          return;
        }
        return new Date(o.argument_erfasst.von);
      });
    },

    changeBeleg({ item }) {
      this.currentBeleg = cloneDeep(item);
      this.statusInternErgaenzt = item.pruefmethode && item.pruefmethode.pk === KBelegPruefmethodeUUIDs.INTERN_ERGAENZT;
      this.currentPeriodPk = this.periodeTeilForPeriode[item.argument_erfasst.posperiode];
      this.period = cloneDeep(find(this.perioden, ["pk", this.currentPeriodPk]));
      this.setZeitgrenzen();
      this.periodeList = cloneDeep(this.periodeObj[this.currentPeriodPk]);
      this.filterPeriodeList();
      this.setOptionsList();
      this.setParametersForDatepicker();
      this.initModel();
      this.changePeriodeTeil({ item: find(this.periodeListFiltered, ["pk", this.model.posperiode]) });
    },

    setOptionsList() {
      this.options.list.splice(1, 100);
      if (!this.periodeListFiltered.length) {
        this.options.list.push({
          type: "template",
          html: "_MSG_HTML_VERTRAG_DETAILS_BELEG_MODAL_KEINE_PERIODE_TEIL_",
        });
        return;
      }

      const STATUS_DISABLED_FOR_INTERN_ERGAENZT = !this.statusInternErgaenzt && this.canAnerkennenBeleg;
      const STATUS_DISABLED = this.statusDisabledLocal;

      this.options.list.push(
        {
          id: "vps_stellenbezeichnung",
          type: "text",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_STELLE_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_STELLE_INFO_I_",
          required: true,
          view: "v",
          disabled: true,
          translate: true,
        },
        {
          id: "person",
          type: "text",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_PERSON_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_PERSON_INFO_I_",
          required: true,
          view: "v",
          disabled: true,
          translate: true,
        },
        {
          id: "periode",
          type: "text",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_PERIODE_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_PERIODE_INFO_I_",
          required: true,
          view: "v",
          disabled: true,
          translate: true,
        },
        {
          id: "posperiode",
          type: "select",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_PERIODE_TEIL_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_PERIODE_TEIL_INFO_I_",
          required: true,
          view: "v",
          disabled: true,
          keyId: "pk",
          keyLabelJson: [
            {
              teg: "span",
              keyLabel: "von",
              filter: "date",
            },
            {
              teg: "span",
              string: " - ",
            },
            {
              teg: "span",
              keyLabel: "bis",
              filter: "date",
            },
          ],
          data: this.periodeListFiltered,
          hideIf: false,
          deselect: false,
          translate: true,
        },
        {
          id: "vpb_bez",
          type: "text",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_BEZEICHNUNG_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_BEZEICHNUNG_INFO_I_",
          required: true,
          view: "v",
          disabled: STATUS_DISABLED || STATUS_DISABLED_FOR_INTERN_ERGAENZT,
          translate: true,
        },
        {
          id: "von",
          type: "date",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_NACHWEIS_VON_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_NACHWEIS_VON_INFO_I_",
          required: true,
          view: "v",
          disabled: STATUS_DISABLED,
          notAfter: undefined,
          notBefore: undefined,
          defaultValue: undefined,
          translate: true,
        },
        {
          id: "bis",
          type: "date",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_NACHWEIS_BIS_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_NACHWEIS_BIS_INFO_I_",
          view: "v",
          disabled: STATUS_DISABLED,
          notAfter: undefined,
          notBefore: undefined,
          defaultValue: undefined,
          translate: true,
        },
        {
          id: "belegbetrag",
          type: "currency",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_BELEGBETRAG_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_BELEGBETRAG_INFO_I_",
          view: "v",
          required: false,
          disabled: STATUS_DISABLED,
          translate: true,
        },
        {
          id: "wert",
          type: "currency",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_BETRAG_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_BETRAG_INFO_I_",
          view: "v",
          disabled: true,
          translate: true,
        },
        {
          id: "dokument",
          type: "document",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_DOCUMENT_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_DOCUMENT_INFO_I_",
          saveUrl: `foerderantraege/${ this.antragPk }/dokumente/?dokart=${ DokartUUIDs.kosten }`,
          keyLabel: "dokument.dok_dokumentname",
          view: "v-alt",
          disabled: STATUS_DISABLED || STATUS_DISABLED_FOR_INTERN_ERGAENZT,
          translate: true,
          slot: "dokument",
        },
        {
          id: "dokument_geschwaerzt",
          type: "boolean",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_CONFIRMATION_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_CONFIRMATION_INFO_I_",
          view: "v-alt",
          disabled: STATUS_DISABLED || STATUS_DISABLED_FOR_INTERN_ERGAENZT,
          translate: true,
        },
      );

      if (this.statusHasPermVertragskostenpositionsbelegeAnerkennen || this.currentBeleg.vpb_kommentar) {
        this.options.list.splice(10, 0, {
          id: "vpb_kommentar",
          type: "textarea",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_KOMMENTAR_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_KOMMENTAR_INFO_I_",
          maxlength: 200,
          view: "v-alt",
          disabled: !this.statusHasPermVertragskostenpositionsbelegeAnerkennen || STATUS_DISABLED,
          translate: true,
        });
      }

      if (this.showBelegnummer) {
        this.options.list.splice(4, 0, {
          id: "vpb_nummer",
          type: "text",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_BELEGNUMMER_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_BELEGNUMMER_INFO_I_",
          required: true,
          view: "v",
          disabled: STATUS_DISABLED || STATUS_DISABLED_FOR_INTERN_ERGAENZT || this.hasNextBelegnummer || this.status.loadingBelegnummer,
          translate: true,
        });
      }
    },

    initModel() {
      const ARGUMENT = this.currentBeleg.argument_anerkannt.von ? this.currentBeleg.argument_anerkannt : this.currentBeleg.argument_erfasst;

      const MODEL = {
        beleg: this.model.beleg,
        vps_stellenbezeichnung: this.position.vps_stellenbezeichnung,
        person: `${ this.person.vpsp_vorname } ${ this.person.vpsp_nachname }`,
        periode: `${ this.period.pe_kbez } (${ this.filterDate(this.period.pe_von) } - ${ this.filterDate(this.period.pe_bis) })`,
        posperiode: ARGUMENT.posperiode,
        von: ARGUMENT.von,
        bis: ARGUMENT.bis,
        dokument_geschwaerzt: ARGUMENT.dokument_geschwaerzt,
        belegbetrag: ARGUMENT.belegbetrag,
        wert: ARGUMENT.wert,
        vpb_nummer: this.currentBeleg.vpb_nummer,
        vpb_bez: this.currentBeleg.vpb_bez,
        vpb_kommentar: this.currentBeleg.vpb_kommentar,
        dokument: this.documentsForBelege[this.currentBeleg.dokument],
      };
      this.model = MODEL;
    },

    loadNextBelegnummer() {
      this.status.loadingBelegnummer = true;
      this.getHttp(
        { url: `vertraege/${ this.vertragPk }/belegnummer/` }
      ).then(
        response => {
          if (response.next) {
            this.hasNextBelegnummer = true;
          }
        }
      ).finally(
        () => this.status.loadingBelegnummer = false
      );
    },

    save() {
      this.status.loading = true;
      const DATA = this.prepareDataForSave();
      this.putHttp({
        url: `vertraege/${ this.vertragPk }/kosten/${ this.vertragkosten }/positionen/${ this.position.position }/belege/${ this.model.beleg }/`,
        data: DATA
      }).then(
        response => {
          this.addNotification({ text: "_MSG_VERTRAG_DETAILS_BELEG_MODAL_EDIT_SUCCESS_" });
          EventBus.$emit(`updateTable${ "BelegeListInVertragTable" }`);
          this.close({ beleg: response, status: true, dokument: this.model.dokument });
        },
        error => {
          this.errors = error.data || {};
          this.errorsClone = error.data || {};
        }
      ).then(
        () => this.status.loading = false
      );
    },

    prepareDataForSave() {
      const MODEL = cloneDeep(this.model);
      const DATA = {
        vpb_nummer: MODEL.vpb_nummer,
        vpb_bez: MODEL.vpb_bez,
        extra: {
          dokument: MODEL.dokument && MODEL.dokument.pk,
        },
        periode: this.periodePk,
        vertragskostenposition: this.position.position,
        argument: {
          belegbetrag: MODEL.belegbetrag,
          wert: MODEL.wert,
          dokument_geschwaerzt: MODEL.dokument_geschwaerzt,
          von: MODEL.von,
          bis: MODEL.bis,
          posperiode: MODEL.posperiode,
          personalstellenperson: this.person.pk,
        },
        vpb_kommentar: this.statusHasPermVertragskostenpositionsbelegeAnerkennen ? MODEL.vpb_kommentar : undefined,
      };
      return DATA;
    },
  },
};
