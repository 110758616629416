import FoerderorgAnsprechpartnerList from "../../../../Foerderorganisation/FoerderorgAnsprechpartnerList/FoerderorgAnsprechpartnerList.vue";
import Permission from "../../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import VertragOverviewChangeTeamButtonModal from "./VertragOverviewChangeTeamButtonModal/VertragOverviewChangeTeamButtonModal.vue";
import VertragOverviewOneAnsprechpartner from "./VertragOverviewOneAnsprechpartner/VertragOverviewOneAnsprechpartner.vue";
import VertragOverviewOneAstAnsprechpartner from "./VertragOverviewOneAstAnsprechpartner/VertragOverviewOneAstAnsprechpartner.vue";
import VertragOverviewVertragAnsprechpartner from "./VertragOverviewVertragAnsprechpartner/VertragOverviewVertragAnsprechpartner.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";

import {
  get,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragOverviewAnsprechpartner",
  components: {
    FoerderorgAnsprechpartnerList,
    Permission,
    PuxButton,
    PuxIcon,
    PuxTranslate,
    VertragOverviewChangeTeamButtonModal,
    VertragOverviewOneAnsprechpartner,
    VertragOverviewOneAstAnsprechpartner,
    VertragOverviewVertragAnsprechpartner,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    vertrag: {
      type: Object,
      required: true,
    },
    canAntragAnsprechpartnerAendern: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      canChangeTeam: undefined,
    };
  },
  computed: {
    astorgAnsprechpartner() {
      return get(this.vertrag, "astorg_ansprechpartner", []);
    },

    foerderorgAnsprechpartner() {
      return get(this.vertrag, "foerderorg_ansprechpartner", []);
    },

    ansprechpartner() {
      return get(this.vertrag, "ansprechpartner", []);
    },

    hasTeams() {
      return this.vertrag.teams.length > 0;
    },

    vertragTeamsBezeichnung() {
      if (this.hasTeams) {
        return this.vertrag.teams.map(el => el.t_kbez).join(", ");
      }
      return undefined;
    },

    ansprechpartnerUrl() {
      return `vertraege/${ this.vertrag.pk }/ansprechpartner/`;
    }
  },
  created() {
    this.checkPermissions();
  },
  methods: {
    checkPermissions() {
      this.getHttp({
        url: `foerderantraege/${ this.vertrag.antrag_id }/check_my_permissions/`,
        urlParams: {
          permissions: "foerderantrag.team.update"
        }
      }).then(
        response => {
          this.canChangeTeam = size(response) > 0;
        }
      );
    },
  }
};
