"use strict";

import ElementverzweigungReadOnlyController from "./readonly.controller";

class ElementverzweigungController extends ElementverzweigungReadOnlyController {
  /*@ngInject*/
  constructor(DiasNotification, Katalog) {
    super(Katalog);
    this.DiasNotification = DiasNotification;
    this.setDefaultModel();
    this.initData();
    this.regelAddSelections = {};
  }

  initData() {
    if (this.parameter[this.key].wert === undefined || this.parameter[this.key].wert === null) {
      this.parameter[this.key].wert = [];
    }
  }

  removeAntwort(index) {
    this.parameter[this.key].wert.splice(index, 1);
  }

  moveUpAntwort(index) {
    if (index === 0) {
      return;
    }
    const elem = this.parameter[this.key].wert[index];
    const previous = this.parameter[this.key].wert[index - 1];
    this.parameter[this.key].wert[index - 1] = elem;
    this.parameter[this.key].wert[index] = previous;
  }

  moveDownAntwort(index) {
    if (index >= this.parameter[this.key].wert.length - 1) {
      return;
    }
    const elem = this.parameter[this.key].wert[index];
    const next = this.parameter[this.key].wert[index + 1];
    this.parameter[this.key].wert[index + 1] = elem;
    this.parameter[this.key].wert[index] = next;
  }

  moveUpChild(parentIndex, index) {
    if (index === 0) {
      return;
    }
    const elem = this.parameter[this.key].wert[parentIndex].children[index];
    const previous = this.parameter[this.key].wert[parentIndex].children[index - 1];
    this.parameter[this.key].wert[parentIndex].children[index - 1] = elem;
    this.parameter[this.key].wert[parentIndex].children[index] = previous;
  }

  moveDownChild(parentIndex, index) {
    if (index >= this.parameter[this.key].wert[parentIndex].children.length - 1) {
      return;
    }
    const elem = this.parameter[this.key].wert[parentIndex].children[index];
    const next = this.parameter[this.key].wert[parentIndex].children[index + 1];
    this.parameter[this.key].wert[parentIndex].children[index + 1] = elem;
    this.parameter[this.key].wert[parentIndex].children[index] = next;
  }

  removeChild(parentIndex, index) {
    this.parameter[this.key].wert[parentIndex].children.splice(index, 1);
  }

  addChild(index) {
    if (this.addChildDisabled(index)) {
      this.DiasNotification.page.error("Kein Element ausgewählt.");
      return;
    }

    const selection = this.regelAddSelections[index][0];
    const alreadySelected = this.parameter[this.key].wert[index].children.find(
      elem => elem === selection.value
    );

    if (alreadySelected) {
      this.DiasNotification.page.error(
        "Element existiert bereits in dieser Antwormöglichkeit"
      );
      return;
    }

    this.parameter[this.key].wert[index].children.push(selection.value);
    this.regelAddSelections[index] = [];
  }

  addChildDisabled(index) {
    const selection = this.regelAddSelections[index];
    return (selection.length === 0);
  }


  isDisabled() {
    return !this.model.label;
  }

  setDefaultModel() {
    this.model = {
      label: undefined,
      children: [],
    };
  }

  addAntwort() {
    let added = false;
    if (this.model.label) {
      added = this.addToList(this.parameter[this.key].wert, this.setObj(true));
    }
    if (added) {
      this.setDefaultModel();
    }
  }

  addToList(list, el) {
    const alreadyIn = list.find(v => v.label === el.label);
    if (alreadyIn) {
      this.DiasNotification.page.error(`Antwort "${ el.label }" existiert bereits.`);
      return false;
    }
    list.push(el);
    return true;
  }

  setObj() {
    const data = {
      label: this.model.label,
      children: this.model.children,
    };
    return data;
  }
}

export default ElementverzweigungController;
