import {
  computed,
  getCurrentInstance,
  onBeforeUnmount,
  provide,
  ref,
} from "vue";
import {
  useStore,
} from "vuex";

import AnalysenStatus from "../../Analysen/AnalysenStatus/AnalysenStatus.vue";
import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import AnsprechpartnerEditButtonModal from "../../Ansprechpartner/AnsprechpartnerEditButtonModal/AnsprechpartnerEditButtonModal.vue";
import AnsprechpartnerZuweisenButtonModal from "../../Ansprechpartner/AnsprechpartnerZuweisenButtonModal/AnsprechpartnerZuweisenButtonModal.vue";
import AntragAngebotChangeWizard from "./AntragAngebotChangeWizard/AntragAngebotChangeWizard.vue";
import AntragCopyButtonConfirm from "./AntragCopyButtonConfirm/AntragCopyButtonConfirm.vue";
import AntragDeleteButtonConfirm from "./AntragDeleteButtonConfirm/AntragDeleteButtonConfirm.vue";
import AntragDetailsAngebot from "./AntragDetailsAngebot/AntragDetailsAngebot.vue";
import AntragEinreichenMessage from "./AntragEinreichenMessage/AntragEinreichenMessage.vue";
import AntragGoToBox from "./AntragGoToBox/AntragGoToBox.vue";
import AntragKofiUebersichtButtonModal from "./AntragKofiUebersichtButtonModal/AntragKofiUebersichtButtonModal.vue";
import AntragMessageRegelsatzNotValid from "./AntragMessageRegelsatzNotValid/AntragMessageRegelsatzNotValid.vue";
import AntragOverview from "./AntragOverview/AntragOverview.vue";
import AntragstellerWizardInAntrag from "../../Antragsteller/AntragstellerWizardInAntrag/AntragstellerWizardInAntrag.vue";
import AufgabeBox from "../../Aufgabe/AufgabeBox/AufgabeBox.vue";
import AntragWarningBoxAntragstellerGesperrt from "./AntragWarningBoxAntragstellerGesperrt/AntragWarningBoxAntragstellerGesperrt.vue";
import AntragWarningBoxAntragstellerorgKopfdublette from "./AntragWarningBoxAntragstellerorgKopfdublette/AntragWarningBoxAntragstellerorgKopfdublette.vue";
import AntragWarningBoxGegenpruefenInaktive from "./AntragWarningBoxGegenpruefenInaktive/AntragWarningBoxGegenpruefenInaktive.vue";
import AntragWarningBoxKeineAntragsteller from "./AntragWarningBoxKeineAntragsteller/AntragWarningBoxKeineAntragsteller.vue";
import AntragWarningBoxNegativGeprueft from "./AntragWarningBoxNegativGeprueft/AntragWarningBoxNegativGeprueft.vue";
import CsvDownload from "../../../global/components/CsvDownload/CsvDownload.vue";
import DetailsInfoBox from "../../DetailsInfoBox/DetailsInfoBox.vue";
import DokumentDownload from "../../Dokumente/DokumentDownload/DokumentDownload.vue";
import Favoriten from "../../Favoriten/Favoriten.vue";
import FormattedAntragScore from "../FormattedAntragScore/FormattedAntragScore.vue";
import FormularCreate from "../../Formulare/FormularCreate/FormularCreate.vue";
import GeschaeftsregelModul from "../../Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";
import GutachtenCreateButtonModal from "../../Gutachten/GutachtenCreateButtonModal/GutachtenCreateButtonModal.vue";
import GutachtenErgebnisse from "../../Gutachten/GutachtenErgebnisse/GutachtenErgebnisse.vue";
import KFPerioden from "../../KF/KFPerioden/KFPerioden.vue";
import ManuelleAufgabenBox from "../../Aufgabe/ManuelleAufgaben/ManuelleAufgabenBox/ManuelleAufgabenBox.vue";
import ManuelleAufgabeButtonModalCreate from "../../Aufgabe/ManuelleAufgaben/ManuelleAufgabeButtonModalCreate/ManuelleAufgabeButtonModalCreate.vue";
import MenuDetails from "../../../SingleUsageComponents/TheMenu/MenuDetails/MenuDetails.vue";
import NachrichtenObjectListBox from "../../Nachrichten/NachrichtenObjectListBox/NachrichtenObjectListBox.vue";
import NotizenBox from "../../Notizen/NotizenBox/NotizenBox.vue";
import ObjectValidation from "../../Object/ObjectValidation/ObjectValidation.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PersonalkostenPeriodenTableInAntrag from "../../KF/PersonalkostenPerioden/PersonalkostenPeriodenTableInAntrag/PersonalkostenPeriodenTableInAntrag.vue";
import PersonalkostenPeriodenWizard from "../../KF/PersonalkostenPeriodenWizard/PersonalkostenPeriodenWizard.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxDropdown from "../../../global/components/PuxDropdown/PuxDropdown.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxRubrics from "../../../global/components/PuxRubrics/PuxRubrics.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Sichtbarkeit from "../../Sichtbarkeit/Sichtbarkeit.vue";
import SnapshotsBox from "../../Snapshot/SnapshotsBox/SnapshotsBox.vue";
import Spaced from "../../../global/components/Spaced/Spaced.vue";
import TaskLogBox from "../../../global/components/TaskLogBox/TaskLogBox.vue";
import TemplateRendering from "../../Templates/TemplateRendering/TemplateRendering.vue";
import Timeline from "../../Timeline/Timeline.vue";
import VerbundeneAntraege from "./VerbundeneAntraege/VerbundeneAntraege.vue";
import WFStatusGruppen from "../../Workflows/StatusGruppen/StatusGruppen.vue";
import WorkflowFolgen from "../../Workflowfolgen/WorkflowFolgen/WorkflowFolgen.vue";

import AntragAnsprechpartnerUpdateAPI from "./compositionAPI/AntragAnsprechpartnerUpdateAPI";
import AntragAntragstellerRegelnAPI from "./compositionAPI/AntragAntragstellerRegelnAPI";
import AntragBusinessRulesAPI from "./compositionAPI/AntragBusinessRulesAPI";
import AntragDocumentCreateAPI from "./compositionAPI/AntragDocumentCreateAPI";
import AntragEinreichenMessageAPI from "./compositionAPI/AntragEinreichenMessageAPI";
import AntragFavoritAPI from "./compositionAPI/AntragFavoritAPI";
import AntragFoerderdokumenteAPI from "./compositionAPI/AntragFoerderdokumenteAPI";
import AntragKofiAPI from "./compositionAPI/AntragKofiAPI";
import AntragModuleScoreAPI from "./compositionAPI/AntragModuleScoreAPI";
import AntragNotGroupedAPI from "./compositionAPI/AntragNotGroupedAPI";
import AntragPdfSaveAPI from "./compositionAPI/AntragPdfSaveAPI";
import AntragViewAPI from "./compositionAPI/AntragViewAPI";
import AntragWizardsAPI from "./compositionAPI/AntragWizardsAPI";
import AntragWorkflowAPI from "./compositionAPI/AntragWorkflowAPI";
import GutachtenAPI from "./compositionAPI/GutachtenAPI";
import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import ManuelleAufgabenAPI from "../../Aufgabe/ManuelleAufgaben/compositionAPI/ManuelleAufgabenAPI";
import PuxRubricsAPI, {
  isRubricVisibleDefault,
} from "../../../global/components/PuxRubrics/compositionAPI/PuxRubricsAPI";
import SnapshotObjectAPI from "../../Snapshot/compositionAPI/SnapshotObjectAPI";

import FoerderangebotAnzeigekontextUUIDs from "../../../const/FoerderangebotAnzeigekontextUUIDs";
import SaveCallbackActions from "../../../const/SaveCallbackActions";
import {
  EventBus,
} from "../../../global/functions/event-bus";
import {
  forEach,
} from "lodash-es";

const GESCHAEFTSREGEL_MODUL = "einfache_foerderregel";

// @vue/component
export default {
  name: "AntragDetails",
  components: {
    AnalysenStatus,
    AngularLink,
    AnsprechpartnerEditButtonModal,
    AnsprechpartnerZuweisenButtonModal,
    AntragAngebotChangeWizard,
    AntragCopyButtonConfirm,
    AntragDeleteButtonConfirm,
    AntragDetailsAngebot,
    AntragEinreichenMessage,
    AntragGoToBox,
    AntragKofiUebersichtButtonModal,
    AntragMessageRegelsatzNotValid,
    AntragOverview,
    AntragstellerWizardInAntrag,
    AufgabeBox,
    AntragWarningBoxAntragstellerGesperrt,
    AntragWarningBoxAntragstellerorgKopfdublette,
    AntragWarningBoxGegenpruefenInaktive,
    AntragWarningBoxKeineAntragsteller,
    AntragWarningBoxNegativGeprueft,
    CsvDownload,
    DetailsInfoBox,
    DokumentDownload,
    Favoriten,
    FormattedAntragScore,
    FormularCreate,
    GeschaeftsregelModul,
    GutachtenCreateButtonModal,
    GutachtenErgebnisse,
    KFPerioden,
    ManuelleAufgabenBox,
    ManuelleAufgabeButtonModalCreate,
    MenuDetails,
    NachrichtenObjectListBox,
    NotizenBox,
    ObjectValidation,
    Permission,
    PersonalkostenPeriodenTableInAntrag,
    PersonalkostenPeriodenWizard,
    PuxButton,
    PuxDropdown,
    PuxPageDetails,
    PuxRubrics,
    PuxTranslate,
    Sichtbarkeit,
    SnapshotsBox,
    Spaced,
    TaskLogBox,
    TemplateRendering,
    Timeline,
    VerbundeneAntraege,
    WFStatusGruppen,
    WorkflowFolgen,
  },
  setup() {
    const APP = getCurrentInstance();
    const $stateParams = APP.appContext.config.globalProperties.$stateParams;
    const $goTo = APP.appContext.config.globalProperties.$goTo;
    const antrag = ref({});
    const loading = ref(true);
    const antragPk = $stateParams.id;

    const antragUrl = computed(() => {
      return `foerderantraege/${ antragPk }/`;
    });
    const antragModuleUrl = computed(() => {
      return `${ antragUrl.value }module/`;
    });

    const scrollTop = () => {
      $(window).scrollTop(0);
    };

    const {
      aktuelleAufgabeTaskId,
      antragAkteUrl,
      antragAktuelleAufgabeBez,
      antragConfig,
      antragKofiDokumentUrl,
      antragLabel,
      antragstellerName,
      anzeigekontexteAntrag,
      anzeigekontexteAntragOpen,
      anzeigekontextPks,
      astAlsButtonConfig,
      hasPerioden,
      hasPermissionFoerderantragAkteErstellen,
      hasPermissionKoFiDownload,
      hasWFPermissionFoerderantragDelete,
      hasWFPermissionFoerderantragUpdate,
      hasWFPermissionFoerderantragKopieren,
      hasPermissionFoerderantragVersionenManuellErstellen,
      isAntragEntschieden,
      notizOptionsVue,
      onCallbackFavoritenAndSichtbarkeit,
      regelsatzKbez,
      statusWFStatusGruppenInHeader,
      updateTeam,
    } = AntragNotGroupedAPI({
      antrag,
      antragUrl,
    });

    const {
      closeEinreichenMessage,
      einreichenStatus,
      setEinreichenStatus,
    } = AntragEinreichenMessageAPI({
      antrag,
    });

    const {
      hasWFPermissionFoerderantragAnsprechpartnerAstorgUpdate,
      hasWFPermissionFoerderantragAnsprechpartnerCreate,
      updateAnsprechpartner,
    } = AntragAnsprechpartnerUpdateAPI({
      antrag,
    });

    const {
      goToModule,
      loadAstRegeln,
    } = AntragAntragstellerRegelnAPI({
      antrag,
    });

    const {
      buttonToggleRulesText,
      hasWFPermissionFoerderantragViewBusinessrules,
      statusRules,
      toggleRules,
    } = AntragBusinessRulesAPI({
      antrag,
    });

    const {
      changeActiveSnapshot,
      createSnapshot,
      isSnapshot,
      snapshot,
      snapshotActivePk,
    } = SnapshotObjectAPI();

    const {
      buttonToggleFavoritText,
      hasWFPermissionFavoritenToggle,
      loadingFavorit,
      toggleFavorit,
    } = AntragFavoritAPI({
      antrag,
      antragUrl,
    });

    const {
      foerderdokumente,
      loadFoerderdokumente,
    } = AntragFoerderdokumenteAPI({
      antragUrl,
    });

    const {
      gutachten,
      gutachtenTypen,
      gutachtenUrl,
      hasWFPermissionGutachtenCreate,
      loadKGutachtentypen,
      loadGutachten,
    } = GutachtenAPI({
      obj: antrag,
      baseUrl: antragUrl,
    });

    const {
      destroyEventBusesModuleAntragScore,
      loadModuleAntragScore,
    } = AntragModuleScoreAPI({
      antragModuleUrl,
      reloadAntrag,
    });

    const {
      savePdf,
    } = AntragPdfSaveAPI({
      antrag,
      antragUrl,
    });

    const {
      hasPermissionAufgabeCreate,
      loadManuelleAufgaben,
      manuelleAufgaben,
    } = ManuelleAufgabenAPI({
      baseUrl: antragUrl,
    });

    const {
      changeViewName,
      closeWizard,
      hasWFPermissionAntragregelsatzwechselPerform,
      hasWFPermissionFoerderantragVerlaufView,
      iconForChangeView,
      openChangeAngebot,
      textTranslateForChangeView,
      timelineConfig,
      timelineUrl,
      viewName,
    } = AntragViewAPI({
      antrag,
      antragPk,
      antragUrl,
    });

    const {
      statusAntragstellerWizard,
      openAntragstellerWizard,
      closeWizardOrganisation,
      statusShowPersonalkostenPerioden,
      personalkostenPerioden,
      personalfunktionen,
      openPersonalKostenPerioden,
      closePersonalKostenPerioden,
      statusShowPersonalkostenPeriodenWizard,
      personalkostenPeriodenWizard,
      openPersonalKostenPeriodenWizard,
      closePersonalKostenPeriodenWizard,
    } = AntragWizardsAPI({ scrollTop });

    const {
      gegenpruefenInaktive,
      einreichenInaktive,
      hasWFPermissionFoerderantragValidieren,
      onValidate,
      onWorkflowInit,
      onWorkflowStarted,
      onWorkflowFinish,
      onWorkflowCanceled,
      onWorkflowSuccess,
      onWorkflowFailure,
      onWorkflowfolgenUpdated,
      reloadWorkflowfolgen,
      resetValidation,
      updateAufgabe,
      validationErrors,
      validationErrorsLoading,
      workflowTypeDefault,
      workflowfolgeUpdateKey,
    } = AntragWorkflowAPI({
      antrag,
      antragUrl,
      scrollTop,
      reloadAntrag,
      openAufgabenRubrics,
    });

    const {
      addNotification,
      getHttp,
    } = HttpAPI();

    const {
      finanzierungModule,
      finanzierungModuleNotKf,
      hasWFPermissionKoFiUpdate,
      isRubricKostenFinanzierung,
      kofiUebersichtAnzeigen,
      kostenModule,
      kostenModuleNotKf,
      loadedModuleForRubric_step_finanz,
      reloadAntragKofi,
      updateinfoprovider,
      updateKoFiModule,
      zuschussinfo,
    } = AntragKofiAPI({
      isAntragEntschieden,
      hasPerioden,
      antrag,
      antragUrl,
      reloadAntrag,
    });

    const store = useStore();
    const STATUS_DIFF_FOR_REGELS = computed(() => {
      return store.state.snapshot.STATUS_DIFF_FOR_REGELS;
    });
    const hasRubricDiffCallbacks = {
      antrag_angebot: () => {
        return STATUS_DIFF_FOR_REGELS.value.antrag_details_angebot;
      },
      kosten_finanzierung: () => {
        return STATUS_DIFF_FOR_REGELS.value.kosten_und_finanzierung;
      }
    };

    const loadedModuleForRubricCallbacks = {
      step_finanz: loadedModuleForRubric_step_finanz,
    };

    const geschaeftsregeln = ref({});
    const prepareModuleForRubric = ({ module, rubric }) => {
      const MODULE = [];
      const RUBRIC_KEY = rubric.key;
      geschaeftsregeln.value[RUBRIC_KEY] = [];
      forEach(module, item => {
        if (item.modulname === GESCHAEFTSREGEL_MODUL) {
          geschaeftsregeln.value[RUBRIC_KEY].push(item);
        } else {
          MODULE.push(item);
        }
      });
      return MODULE;
    };

    const getRubricAnzeigekontextForUrl = ({ rubric }) => {
      if (isRubricKostenFinanzierung({ rubric })) {
        return [
          FoerderangebotAnzeigekontextUUIDs.kosten,
          FoerderangebotAnzeigekontextUUIDs.finanzierung,
        ];
      }
      return rubric.rubrik_obj.anzeigekontext;
    };
    const isRubricVisible = ({ rubric, anzeigekontextPks }) => {
      const RUBRIC_KEY = rubric.axr_config.key;
      if (RUBRIC_KEY === "step_ubersicht") {
        return !!antrag.value.a_antragsdatum;
      }
      return isRubricVisibleDefault({ rubric, anzeigekontextPks });
    };

    const {
      buildRubrics,
      disableSnapshots,
      hasBaseEditPermModuleByRubricKey,
      loadAllModule,
      loadRubrics,
      loadRubricsSnapshotConfig,
      loadingModuleByRubricKey,
      moduleByRubricKey,
      openRubric,
      readonlyModuleByRubricKey,
      reloadAllModule,
      rubricsDiff,
      rubricsFiltered,
      rubricsFilteredByAnzeigekontext,
      rubricsFilteredByKey,
      rubricsSnapshotConfig,
      updateModule,
    } = PuxRubricsAPI({
      baseUrl: antragUrl,
      isSnapshot,
      hasRubricDiffCallbacks,
      loadedModuleForRubricCallbacks,
      anzeigekontextPks,
      updateReadonlyModuleByRubricKey,
      updateHasBaseEditPermModuleByRubricKey,
      objectModuleUrl: antragModuleUrl,
      objectType: "antrag",
      prepareModuleForRubric,
      getRubricAnzeigekontextForUrl,
      isRubricVisible,
    });

    const {
      goToModulAnlagen,
      hasWFPermissionDokumentCreate,
      isSubsequentFilingPossible,
    } = AntragDocumentCreateAPI({
      antrag,
      rubricsFilteredByKey,
    });

    function updateReadonlyModuleByRubricKey({ rubric }) {
      const RUBRIC_KEY = rubric.key;
      let readonly = false;
      if (!antrag.value.eingereicht) {
        // Vor dem Einreichen muss auch auf den Regelsatz geachtet
        // werden. Nach dem Einreichen ist diese Prüfung egal.
        readonly = antrag.value.is_regelsatz_valid === false;
      }

      if (RUBRIC_KEY === "step_finanz") {
        readonly = readonly || !hasWFPermissionKoFiUpdate.value;
      }
      readonlyModuleByRubricKey.value[RUBRIC_KEY] = readonly;
    }

    function updateHasBaseEditPermModuleByRubricKey({ rubric }) {
      const RUBRIC_KEY = rubric.key;
      let hasBaseEditPerm = hasWFPermissionFoerderantragUpdate.value;
      if (RUBRIC_KEY === "step_finanz") {
        hasBaseEditPerm = hasBaseEditPerm || hasWFPermissionKoFiUpdate.value;
      }

      hasBaseEditPermModuleByRubricKey.value[RUBRIC_KEY] = hasBaseEditPerm;
    }


    function openAufgabenRubrics({ isScroll = false } = {}) {
      setTimeout(() => {
        const RUBRIC_KEYS_TO_OPEN = [];
        forEach(rubricsFiltered.value, rubric => {
          const RUBRIC_KEY = rubric.key;
          if (anzeigekontexteAntragOpen.value[RUBRIC_KEY]) {
            RUBRIC_KEYS_TO_OPEN.push(RUBRIC_KEY);
          }
        });
        if (RUBRIC_KEYS_TO_OPEN.length === 1 && isScroll) {
          EventBus.$emit("scrollToContextPanel", { id: RUBRIC_KEYS_TO_OPEN[0] });
        } else {
          forEach(RUBRIC_KEYS_TO_OPEN, rubricKey => {
            EventBus.$emit("toggleContextPanel", {
              id: rubricKey,
              statusOpen: true,
              withoutScroll: true,
            });
          });
        }
      });
    }

    const reload = ref(false);


    const setAntrag = () => {
      buildRubrics();
      loadAstRegeln();
      loadGutachten();
      loadModuleAntragScore();
    };
    function reloadAntrag(checkEinreichenStatus, reloadModules) {
      if (reload.value) {
        if (reloadModules) {
          reloadAllModule();
        }
        return Promise.resolve();
      }
      reload.value = true;
      return getHttp({
        url: antragUrl.value,
      }).then(
        response => {
          setEinreichenStatus(checkEinreichenStatus, response);
          antrag.value = response;
          setAntrag();
          reloadWorkflowfolgen();
          if (reloadModules) {
            reloadAllModule();
          }
        }
      ).finally(() => reload.value = false);
    }

    const loadAntrag = () => {
      return getHttp({
        url: antragUrl.value,
      }).then(
        response => {
          antrag.value = response;
          loadFoerderdokumente();
          Promise.all([
            loadRubricsSnapshotConfig(),
            loadRubrics(),
          ])
            .finally(
              () => {
                setAntrag();
                loadAllModule();
                openAufgabenRubrics();
              }
            );
        },
        () => {
          addNotification({
            text: "_MSG_ANTRAG_LADEN_DATEN_ERROR_",
            type: "error",
          });
          $goTo("root.foerderantrag");
        }
      );
    };
    const loadData = () => {
      Promise.all([
        loadAntrag(),
        loadKGutachtentypen(),
        loadManuelleAufgaben(),
      ]).then(
        () => {
          loading.value = false;
        }
      );
    };

    const saveCallback = ({ statusSaveCallback } = {}) => {
      if (statusSaveCallback === SaveCallbackActions.RELOAD_WF) {
        reloadWorkflowfolgen();
      } else if (statusSaveCallback === SaveCallbackActions.RELOAD_ALL) {
        reloadAntrag();
      }
    };

    const initEventBuses = () => {
      EventBus.$on("openAntragstellerWizard", openAntragstellerWizard);
      EventBus.$on("antragsteller.update", reloadAntrag);
      EventBus.$on("tooglePersonalkostenPerioden", openPersonalKostenPerioden);
      EventBus.$on("openPersonalkostenPeriodenWizard", openPersonalKostenPeriodenWizard);
    };
    const destroyEventBuses = () => {
      EventBus.$off("openAntragstellerWizard", openAntragstellerWizard);
      EventBus.$off("antragsteller.update", reloadAntrag);
      EventBus.$off("tooglePersonalkostenPerioden", openPersonalKostenPerioden);
      EventBus.$off("openPersonalkostenPeriodenWizard", openPersonalKostenPeriodenWizard);
    };

    loadData();
    initEventBuses();

    onBeforeUnmount(() => {
      destroyEventBuses();
      destroyEventBusesModuleAntragScore();
    });

    provide("updateAnsprechpartner", updateAnsprechpartner);
    provide("gutachten", computed(() => gutachten.value));

    return {
      antrag,
      antragUrl,
      loading,
      reload,
      reloadAntrag,
      saveCallback,

      aktuelleAufgabeTaskId,
      antragAkteUrl,
      antragAktuelleAufgabeBez,
      antragConfig,
      antragKofiDokumentUrl,
      antragLabel,
      antragstellerName,
      anzeigekontexteAntrag,
      astAlsButtonConfig,
      hasPermissionFoerderantragAkteErstellen,
      hasPermissionKoFiDownload,
      hasWFPermissionFoerderantragDelete,
      hasWFPermissionFoerderantragKopieren,
      hasPermissionFoerderantragVersionenManuellErstellen,
      notizOptionsVue,
      onCallbackFavoritenAndSichtbarkeit,
      regelsatzKbez,
      updateTeam,

      closeEinreichenMessage,
      einreichenStatus,

      hasWFPermissionFoerderantragAnsprechpartnerAstorgUpdate,
      hasWFPermissionFoerderantragAnsprechpartnerCreate,
      updateAnsprechpartner,

      goToModule,

      buttonToggleRulesText,
      hasWFPermissionFoerderantragViewBusinessrules,
      statusRules,
      toggleRules,

      changeActiveSnapshot,
      createSnapshot,
      isSnapshot,
      snapshot,
      snapshotActivePk,

      buttonToggleFavoritText,
      hasWFPermissionFavoritenToggle,
      loadingFavorit,
      toggleFavorit,

      foerderdokumente,

      gutachten,
      gutachtenTypen,
      gutachtenUrl,
      hasWFPermissionGutachtenCreate,
      loadGutachten,

      savePdf,

      hasPermissionAufgabeCreate,
      loadManuelleAufgaben,
      manuelleAufgaben,

      changeViewName,
      closeWizard,
      hasWFPermissionAntragregelsatzwechselPerform,
      hasWFPermissionFoerderantragVerlaufView,
      iconForChangeView,
      openChangeAngebot,
      textTranslateForChangeView,
      timelineConfig,
      timelineUrl,
      viewName,

      statusAntragstellerWizard,
      closeWizardOrganisation,
      statusShowPersonalkostenPerioden,
      personalkostenPerioden,
      personalfunktionen,
      closePersonalKostenPerioden,
      statusShowPersonalkostenPeriodenWizard,
      personalkostenPeriodenWizard,
      closePersonalKostenPeriodenWizard,

      gegenpruefenInaktive,
      einreichenInaktive,
      hasWFPermissionFoerderantragValidieren,
      onValidate,
      onWorkflowInit,
      onWorkflowStarted,
      onWorkflowFinish,
      onWorkflowCanceled,
      onWorkflowSuccess,
      onWorkflowFailure,
      onWorkflowfolgenUpdated,
      resetValidation,
      statusWFStatusGruppenInHeader,
      updateAufgabe,
      validationErrors,
      validationErrorsLoading,
      workflowTypeDefault,
      workflowfolgeUpdateKey,

      finanzierungModule,
      finanzierungModuleNotKf,
      kofiUebersichtAnzeigen,
      kostenModule,
      kostenModuleNotKf,
      reloadAntragKofi,
      updateinfoprovider,
      updateKoFiModule,
      zuschussinfo,

      geschaeftsregeln,

      disableSnapshots,
      hasBaseEditPermModuleByRubricKey,
      loadingModuleByRubricKey,
      moduleByRubricKey,
      openRubric,
      readonlyModuleByRubricKey,
      rubricsDiff,
      rubricsFiltered,
      rubricsFilteredByAnzeigekontext,
      rubricsSnapshotConfig,
      updateModule,

      goToModulAnlagen,
      hasWFPermissionDokumentCreate,
      isSubsequentFilingPossible,
    };
  },
};
