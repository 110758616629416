"use strict";

import BetragsrechnerBase from "../betragsrechner.base";

class HonorarkostenRechner extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "honorarkostenrechner";
    this.component_name = "honorarkosten";
    this.labels = { taetigkeit_bez: "Bezeichnung der Tätigkeit",
                    qualifikation_bez: "Qualifikation der Honorarkraft",
                    anzahl_zeiteinheiten: "Anzahl Zeiteinheiten",
                    zeiteinheit: "Zeiteinheit",
                    kosten_pro_zeiteinheit: "Honorarsatz pro Zeiteinheit in Euro",
                    dokument_geschwaerzt: "Bestätigung" };
  }

  get_labelmapping() {
    return this.labels;
  }

  get_fields() {
    return { taetigkeit_bez: "",
             qualifikation_bez: "",
             anzahl_zeiteinheiten: "",
             zeiteinheit: "",
             kosten_pro_zeiteinheit: "",
             dokument_geschwaerzt: "",
    };
  }
}
export default HonorarkostenRechner;
