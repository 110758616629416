import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FaqDetailsItemMixin from "../FaqDetailsItemMixin";

import {
  getInternalOrExternalPathToFile,
} from "../../../../../global/functions/utils";

// @vue/component
export default {
  name: "FaqDetailsItemImage",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    FaqDetailsItemMixin,
  ],
  data() {
    return {
      isModalVisible: undefined,
      optionsModal: {
        showCloseButton: true,
        cancelLabel: "_BTN_CLOSE_",
      },
    };
  },
  computed: {
    idForButton() {
      return `btn_faq_image_${ this.data.pk }`;
    },

    srcForImageMinResolution() {
      if (this.parameter.url_for_min) {
        return getInternalOrExternalPathToFile(this.parameter.url_for_min);
      }
      return this.srcForImage;
    },

    srcForImage() {
      return getInternalOrExternalPathToFile(this.parameter.url);
    },

    altForImage() {
      return this.parameter.alt;
    },

    parentCss() {
      return this.parameter.parent_css;
    },

    imageCss() {
      return this.parameter.css || "img-fluid";
    },

    imageStyle() {
      return this.parameter.style;
    },

    parentStyle() {
      return this.parameter.parent_style;
    },

    selectorClose() {
      return `#${ this.idForButton }`;
    },
  },
  methods: {
    openModal() {
      this.isModalVisible = true;
    },

    closeModal() {
      this.isModalVisible = false;
    },
  },
};
