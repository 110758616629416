"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

const datenexport = angular.module("dias.datenexport", [
  uiRouter,
]);

datenexport.config($stateProvider => {
  $stateProvider

    // Sitzungsantrag-Liste
    .state("root.datenexport", {
      url: "/datenexport/",
      views: {
        "main@root": {
          template: "<div vueinit><datenexport-list></datenexport-list></div>",
        }
      },
      data: {
        permissions: ["datenexport.ui"]
      },
    })
  ;
});

export default datenexport;
