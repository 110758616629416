import {
  ref,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

import {
  keyBy,
} from "lodash-es";

export default function SitzungsartSitzungsmoduleAPI() {
  const sitzungsmoduleKeyByPk = ref({});
  const loadingSitzungsmodule = ref(true);

  const {
    getHttp,
  } = HttpAPI();
  const loadSitzungsmodule = () => {
    getHttp({
      url: "katalog/",
      urlParams: {
        key: "sitzungsmodule",
      },
      apiSaveId: "sitzungsmodule",
    }).then(
      response => {
        sitzungsmoduleKeyByPk.value = keyBy(response, "pk");
        loadingSitzungsmodule.value = false;
      }
    );
  };

  return {
    loadingSitzungsmodule,
    loadSitzungsmodule,
    sitzungsmoduleKeyByPk,
  };
}
