"use strict";

import diasLoading from "dias/core/loading";

import TerminAendernComponent from "./terminAendern.component";

const diasTerminAendern = angular.module("dias.terminAendern", [
  diasLoading.name
])

  .component("diasTerminAendern", TerminAendernComponent);

export default diasTerminAendern;
