var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<fieldset><div ng-attr-id=\"{{ vm.errorContainerId }}\"></div><div class=\"row\"><div ng-if=\"vm.model.rechte_choices.length === 0\" class=\"col-12 col-sm-6\">An dieser Geschäftsregel können keine Rechte konfiguriert werden.</div><div ng-if=\"vm.model.rechte_choices.length &gt; 0\" class=\"col-12 col-sm-6\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<div ng-repeat=\"recht in vm.model.rechte_choices\"><div class=\"checkbox-btn\"><input ng-attr-id=\"re_edit_rechte_{{recht[0]}}\" name=\"re_edit_rechte\" type=\"checkbox\" ng-model=\"vm.selected[recht[0]]\" auto-focus=\"{{ $index === 0 ? true : false }}\" ng-attr-aria-checked=\"{{ vm.selection[recht[0]] ? 'true' : 'false' }}\"><label ng-attr-for=\"re_edit_rechte_{{recht[0]}}\" class=\"font-weight-bold\"><span class=\"label-hidden\">Wählen Sie bitte ihre Antworten</span><span>{{ recht[1] }}</span></label></div></div>");
}
}, "re_edit_rechte", "Funktionsgruppen");
buf.push("</div></div></fieldset><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Abbrechen</span></button></div></div>");;return buf.join("");
}