"use strict";

import template from "./workflowfolgen.jade";

class WorkflowfolgenController {
  /*@ngInject*/
  constructor($scope, $q, i18n, DiasNotification, WorkflowfunktionRegistry) {
    this.$q = $q;
    this.gettext = i18n.gettext;
    this.DiasNotification = DiasNotification;
    this.WorkflowfunktionRegistry = WorkflowfunktionRegistry;
    this.loading = false;
    $scope.$watchGroup(["vm.aufgabe.pk", "vm.updateOnChange"], () => {
      this.updateWorkflowfolgen();
    });
  }

  getClassName() {
    if (!this.workflowfolgen || !this.workflowfolgen[0]) {
      return "";
    }
    return `test_${ _.trim(this.workflowfolgen[0].wfo_kbez) }`;
  }

  getTitle(idxOrFolge) {
    const folge = Number.isInteger(idxOrFolge) ? this.workflowfolgen[idxOrFolge] : idxOrFolge;
    let title = "";
    if (folge.status !== 0) {
      angular.forEach(folge.status_messages, msg => {
        title += msg + " ";
      });
    }
    return title;
  }

  onWorkflowfolge(folge) {
    this.loading = true;
    const activeFunc = this.WorkflowfunktionRegistry.get(this.aufgabe, folge, this.extra);
    if (this.onInit) {
      this.onInit()(activeFunc);
    }
    activeFunc.start().then(
      retValue => {
        if (retValue === false) {
          // Abbrechen des Dialogs
          // Workflow wird nicht durchgeführt
          if (this.onCanceled) {
            this.onCanceled()();
          }
        } else {
          // Bestätigen des Dialogs
          // Workflow wird durchgeführt
          if (this.onStarted) {
            this.onStarted()(activeFunc.folge_aufgabe, folge);
          }
          if (activeFunc.folge_aufgabe !== undefined) {
            Object.assign(this.aufgabe, activeFunc.folge_aufgabe);
          }
        }
      }
    ).finally(() => this.loading = false);
  }

  updateWorkflowfolgen() {
    if (this.aufgabe && this.aufgabe.bearbeiter) {
      this.loading = true;
      return this.aufgabe.workflowfolgen.getList().then(
        result => {
          this.workflowfolgen = _.sortBy(result, ["wfo_pos"]);
          if (this.onWorkflowfolgenUpdated) {
            this.onWorkflowfolgenUpdated()(this.workflowfolgen);
          }
        },
        () => {
          this.DiasNotification.page.error("Fehler beim Ermitteln möglicher Aktionen für die aktuelle Aufgabe");
        }
      ).finally(() => this.loading = false);
    }
    this.workflowfolgen = [];
    const defer = this.$q.defer();
    defer.resolve();
    return defer.promise;
  }
}

export default {
  template: template(),
  controller: WorkflowfolgenController,
  controllerAs: "vm",
  bindings: {
    aufgabe: "<",
    updateOnChange: "<?",
    onStarted: "&?",
    onCanceled: "&?",
    onInit: "&?",
    onWorkflowfolgenUpdated: "&?",
    disabled: "<?",
    singleButton: "<?",
    extra: "<?",
  },
};
