"use strict";

import BaseModel from "dias/core/models/baseModel";
import FoerderprogrammFoerderangebote from "./foerderprogrammFoerderangebote.service";


class Foerderprogramme extends BaseModel {
  constructor() {
    super("foerderprogramme", {
      subPaths: {
        angebote: new FoerderprogrammFoerderangebote(),
      }
    });
  }

  extendModel(model) {
    model = super.extendModel(model);
    model.aktivieren = () => model.customPUT({}, "aktivieren");
    model.deaktivieren = () => model.customPUT({}, "deaktivieren");
    return model;
  }
}

export default Foerderprogramme;
