"use strict";

import BaseModel from "dias/core/models/baseModel";

class Formularvorlage extends BaseModel {
  constructor() {
    super("formularvorlage");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.verfuegbare_vorlagenversionen = (objekt_id, contenttype) => collection.customGETLIST("verfuegbare_vorlagenversionen", { objekt_id: objekt_id, contenttype: contenttype });
    return collection;
  }
}

export default Formularvorlage;
