"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";


const parametermodule = angular.module("dias.foerderregeln.parameter.status_antrag_bedingter_nachfolgeworkflow_param", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("status_antrag_bedingter_nachfolgeworkflow_param", "parametermodul-json");
  })
;
export default parametermodule;
