"use strict";

import template from "./regelsatzstatuslabel.template.jade";

class RegelsatzStatusLabelController {
  /*@ngInject*/
  constructor($element, $scope, $compile, FoerderangebotstatusUUIDs) {
    this.name = "RegelsatzStatusLabelController";

    this.$compile = $compile;
    this.$element = $element;
    this.$scope = $scope;
    this.FoerderangebotstatusUUIDs = FoerderangebotstatusUUIDs;

    this.getRegelsatzStatusLabelKbez = () => {
      let statusKbez = this.regelsatz.status_obj.kf_kbez;
      if (this.isRegelsatzActive()) {
        statusKbez += " - aktiv";
      } else if (this.regelsatz.status_obj.kf_id === FoerderangebotstatusUUIDs.freigegeben) {
        statusKbez += " - inaktiv";
      }
      return statusKbez;
    };

    this.isRegelsatzActive = () => {
      return this.regelsatz.is_active;
    };
  }
}

export default {
  template: template(),
  controller: RegelsatzStatusLabelController,
  restrict: "E",
  controllerAs: "vm",
  bindings: {
    regelsatz: "="
  }
};
