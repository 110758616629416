import {
  isNil,
} from "lodash-es";

// @vue/component
export default {
  computed: {
    warningDisplay() {
      if (isNil(this.model) ||
        (this.modelParameter.minmax_validierung !== "warnung" &&
        isNil(this.data.expression) &&
        isNil(this.data.signal_regex))) {
        return;
      }
      const min = isNil(this.data.min) ? Number.MIN_VALUE : Number(this.data.min);
      const max = isNil(this.data.max) ? Number.MAX_VALUE : Number(this.data.max);
      const value = Number(this.model);
      if (!Number.isNaN(value) &&
        (value > max || value < min)) {
        return {
          text: this.modelParameter.zusaetzlicher_text ?
            "_TXT_GR_DYNAMISCHE_ZAHLEN_WARNUNG_{{zusaetzlicher_text}}_" :
            "_TXT_GR_DYNAMISCHE_ZAHLEN_WARNUNG_OHNE_ZUSAETZ_TEXT_",
          extra: {
            zusaetzlicher_text: this.modelParameter.zusaetzlicher_text,
          },
        };
      }
      return undefined;
    },
  },
};
