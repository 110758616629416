import BelegeModalCreateOrEdit from "../../KF/BelegeModalCreateOrEdit/BelegeModalCreateOrEdit.vue";
import BelegeModalDeleteDocument from "../BelegeModalDeleteDocument/BelegeModalDeleteDocument.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import DynamicRootForComponentsMixin from "../../DynamicRoot/DynamicRootForComponentsMixin";

import { EventBus } from "../../../global/functions/event-bus";

const MODULES_NOT_EDITABLE = [
  "personalkosten_perioden",
  "dhw_personalkosten_perioden",
];
const TABLE_ID = "vertragsbelege";

// @vue/component
export default {
  name: "BelegeList",
  components: {
    BelegeModalCreateOrEdit,
    BelegeModalDeleteDocument,
    PageTabTitle,
    PuxButton,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    DynamicRootForComponentsMixin,
  ],
  props: {
    createOrEditBeleg: {
      type: Function,
      default: () => {},
    },
    header: {
      type: String,
      required: false,
      default: "_TXT_BELEGE_TABLE_HEADER_",
      info: "Text für Tabelle-Header",
    },
    filter: {
      type: Object,
      required: false,
      default: undefined,
      info: "Zusätzliches Get-Parameter für API",
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: this.initUrl({ tableId: TABLE_ID, filter: this.filter }),
        baseUrl: this.initUrl({ tableId: TABLE_ID, filter: undefined }),
        rowActions: [
          {
            label: "_TXT_BELEGE_TABLE_ROW_ACTIONS_SHOW_VERTRAG_{{bez}}_",
            extraCallback: ({ item }) => this.getVertragBez({ item }),
            route: "root.vertrag.details",
            routeParameters: [{ routeId: "id", rowPath: "vertrag" }],
          },
          {
            label: "_TXT_BELEGE_TABLE_ROW_ACTIONS_EDIT_",
            callback: this.openModalBelegEdit,
            isHidden: ({ item }) => this.modulesNotEditable.indexOf(item.modulname) !== -1,
          },
          {
            label: "_TXT_BELEGE_TABLE_ROW_ACTIONS_DELETE_DOCUMENT_",
            callback: this.deleteDokument,
            isHidden: ({ item }) => !item.dokument,
          },
        ],
        actions: [
          {
            permission: "vertragskostenpositionsbelege.create.liste",
            label: "_TXT_BELEGE_TABLE_ACTIONS_CREATE_",
            callback: this.openModalBelegCreate,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            id: "create_beleg_global",
          },
        ],
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
        trClassCallback: ({ row }) => row.herkunft === "vertragsfinanzierung_positionsbeleg" ? "border-dark-blue" : "border-light-blue",
      },
      status: {
        loading: true,
      },
      modal: {
        deleteDocument: undefined,
      },
      currentBeleg: undefined,
      currentVertragId: undefined,
      statusModalCreateOrEdit: undefined,
      modulesNotEditable: MODULES_NOT_EDITABLE,
    };
  },
  computed: {
    getTitleTranslateBelegnummer() {
      return ({ row }) => {
        return {
          title: "_BTN_BELEGE_TABLE_BELEGNUMMER_TITLE_{{nummer}}_",
          extra: {
            nummer: row.nummer
          }
        };
      };
    },

    getRenderTypeTranslateTitle() {
      return ({ row }) => {
        return row.herkunft === "vertragsfinanzierung_positionsbeleg" ? "_TXT_BELEGE_TABLE_FINANZIERUNGSBELEG_TITLE_" : "_TXT_BELEGE_TABLE_KOSTENBELEG_TITLE_";
      };
    },

    getRenderTypeTranslateText() {
      return ({ row }) => {
        return row.herkunft === "vertragsfinanzierung_positionsbeleg" ? "_TXT_BELEGE_TABLE_FINANZIERUNGSBELEG_" : "_TXT_BELEGE_TABLE_KOSTENBELEG_";
      };
    },
  },
  created() {
    this.initEventBus();
  },
  beforeUnmount() {
    this.destroyEventBus();
  },
  methods: {
    editBeleg({ item } = {}) {
      this.createOrEditBeleg({ row: item }).then(
        response => {
          if (response) {
            EventBus.$emit(`updateTable${ TABLE_ID }`);
          }
        }
      );
    },

    createBeleg({ urlParamsObj = {} }) {
      this.createOrEditBeleg({ vertragId: urlParamsObj.vertrag }).then(
        response => {
          if (response) {
            EventBus.$emit(`updateTable${ TABLE_ID }`);
          }
        }
      );
    },

    deleteDokument({ item }) {
      this.modal.deleteDocument = true;
      this.currentBeleg = item;
    },

    closeModalDeleteDocument({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.currentBeleg = undefined;
      this.modal.deleteDocument = false;
    },

    getVertragBez({ item }) {
      return { bez: item.vertrag_bez };
    },

    openModalBelegCreate() {
      this.statusModalCreateOrEdit = true;
    },

    openModalBelegEdit({ item }) {
      this.currentVertragId = item.vertrag;
      this.currentBeleg = item;
      this.statusModalCreateOrEdit = true;
    },

    closeModalCreateOrEdit() {
      this.currentBeleg = undefined;
      this.currentVertragId = undefined;
      this.statusModalCreateOrEdit = false;
    },

    initEventBus() {
      EventBus.$on("updateBeleg", this.updateBeleg);
    },

    destroyEventBus() {
      EventBus.$off("updateBeleg", this.updateBeleg);
    },

    updateBeleg() {
      EventBus.$emit(`updateTable${ TABLE_ID }`);
    },
  },
};
