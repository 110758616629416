import Permission from "../../../global/components/Permission/Permission.vue";

// @vue/component
export default {
  name: "StyleguidePermission",
  components: {
    Permission,
  },
  data() {
    return {
      props: [
        {
          name: "permission",
          type: "[String, Array]",
          description: "Einfach die Permissions",
        },
        {
          name: "src",
          type: "Array",
          description: "Array, in dem Permissions prüfen",
        },
        {
          name: "missing",
          type: "Boolean",
          description: "die Logik umgekehrt",
        },
      ],
    };
  },
};
