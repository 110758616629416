import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import TaskLog from "../../../../global/components/TaskLog/TaskLog.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";

// @vue/component
export default {
  name: "ImportauftragModalAusfuehren",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
    TaskLog,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    obj: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      status: {
        loading: undefined,
      },
      task: undefined,
      error: undefined,
      success: false
    };
  },
  methods: {
    onTaskSuccess() {
      this.success = true;
    },

    onTaskFailure({ result }) {
      this.success = false;
      this.error = result.data || true;
    },

    onTaskFinish() {
      this.getHttp({ url: `importauftraege/${ this.obj.pk }/` }).then(
        response => {
          if (this.success) {
            this.close({ response });
          } else {
            Object.assign(this.obj, response);
          }
        }
      ).finally(() => this.status.loading = false);
    },

    execute() {
      this.status.loading = true;
      this.error = undefined;
      this.postHttp({ url: `importauftraege/${ this.obj.pk }/ausfuehren/` }).then(
        response => {
          this.task = response.task_id;
          if (!this.task) {
            this.status.loading = false;
          }
        },
        error => {
          this.error = error.data;
          this.status.loading = false;
        }
      );
    },
  },
};
