import FormElement from "../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import {
  FilterDateMixin,
} from "../../../global/mixins/FiltersMixin";

import {
  gettext,
} from "../../../global/functions/utils";
import {
  cloneDeep,
  endsWith,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "GutachtenCreateButtonModal",
  components: {
    FormElement,
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    FilterDateMixin,
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    id: {
      type: String,
      required: false,
      default: undefined,
    },
    gutachtenTyp: {
      type: Object,
      required: true,
    },
    object: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    selectorClose: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  emits: [
    "savedGutachten",
  ],
  data() {
    return {
      isModalOpen: false,
      model: {
        ga_kbez: undefined,
        ga_bez: undefined,
        ga_termin: undefined,
        nutzer: undefined,
        pruefer: undefined,
        vorlage: undefined,
      },
      optionsKbez: {
        id: "ga_kbez",
        type: "text",
        label: "_LBL_GUTACHTEN_BEZEICHNUNG_",
        view: "v",
        required: true,
      },
      optionsBez: {
        id: "ga_bez",
        type: "richtext",
        label: "_LBL_GUTACHTEN_BESCHREIBUNG_",
        view: "v-alt",
        required: false,
      },
      optionsTermin: {
        id: "ga_termin",
        type: "date",
        label: "_LBL_GUTACHTEN_ZIELTERMIN_",
        view: "v",
        required: true,
      },
      optionsNutzer: {
        id: "nutzer",
        type: "select",
        label: "_LBL_GUTACHTEN_NUTZER_",
        view: "v",
        required: true,
        url: "katalog/",
        urlParams: {
          key: "gutachter",
        },
        keyLabel: "bez",
        keyId: "pk",
        apiSaveId: "gutachter",
        search: true,
        searchList: ["bez"],
      },
      optionsPruefer: {
        id: "pruefer",
        type: "select",
        label: "_LBL_GUTACHTEN_PRUEFER_",
        view: "v",
        required: false,
        url: "katalog/",
        urlParams: {
          key: "gutachten_pruefer",
        },
        keyLabel: "bez",
        keyId: "pk",
        apiSaveId: "gutachten_pruefer",
        search: true,
        searchList: ["bez"],
      },
      loading: false,
      loadingGutachtenvorlage: false,
      errors: undefined,
      errorsClone: undefined,
      gutachtenvorlage: [],
    };
  },
  computed: {
    extraTranslate() {
      return {
        bez: this.gutachtenTyp.bez,
      };
    },

    selectorCloseLocal() {
      return this.selectorClose || `#${ this.id }`;
    },

    optionsModal() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          this.optionsKbez,
          this.optionsBez,
          this.optionsTermin,
          this.optionsNutzer,
          this.optionsPruefer,
          this.optionsVorlage,
        ],
      };
    },

    optionsVorlage() {
      return {
        id: "vorlage",
        type: "select",
        label: "_LBL_GUTACHTEN_VORLAGE_",
        view: "v",
        required: true,
        keyLabel: "bez",
        keyId: "pk",
        search: true,
        searchList: ["bez"],
        data: this.gutachtenvorlage,
        loading: this.loadingGutachtenvorlage,
      };
    },

    idForSaveButton() {
      return `${ this.id }_save`;
    },

    hasPermissionGutachtenPruefen() {
      return this.checkPermissionsSync({
        perm: "gutachten.pruefen",
      });
    },
  },
  methods: {
    openModal() {
      this.setModel();
      this.loadGutachtenvorlage();
      this.isModalOpen = true;
    },

    setModel() {
      if (this.hasPermissionGutachtenPruefen) {
        this.model.pruefer = this.me.pk;
      }
      if (!isUndefined(this.object.a_titel)) {
        this.model.ga_kbez = this.object.a_titel;
      }
    },

    loadGutachtenvorlage() {
      this.loadingGutachtenvorlage = true;
      this.getHttp({
        url: "katalog/",
        urlParams: {
          key: "gutachtenvorlagen",
          typ: this.gutachtenTyp.pk,
          regelsatz: this.object.regelsatz,
        },
      }).then(
        response => {
          this.gutachtenvorlage = response;
          if (this.gutachtenvorlage.length === 1) {
            this.model.vorlage = this.gutachtenvorlage[0].pk;
          }
          this.loadingGutachtenvorlage = false;
        }
      );
    },

    closeModal() {
      this.isModalOpen = false;
    },

    save() {
      this.changeModelKbez();
      this.postHttp({
        url: this.url,
        data: this.model,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_GUTACHTEN_CREATE_SUCCESS_",
          });
          this.closeModal();
          this.$emit("savedGutachten");
        },
        errors => {
          this.errors = errors.data;
          this.errorsClone = cloneDeep(errors.data);
        },
      );
    },

    changeModelKbez() {
      if (!this.model.ga_termin) {
        return;
      }
      const ABGABE = `(${ gettext("_TXT_ABGABE_") }: ${ this.filterDate(this.model.ga_termin) })`;
      const MODEL_KBEZ = this.model.ga_kbez || "";
      if (!endsWith(MODEL_KBEZ, ABGABE)) {
        this.model.ga_kbez = `${ MODEL_KBEZ } ${ ABGABE }`;
      }
    },
  },
};
