import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import SmartTable from "../../../global/components/table/table.vue";
import GeschaeftsregelModalCreate from "../GeschaeftsregelModalCreate/GeschaeftsregelModalCreate.vue";
import getTableApi from "../../../const/TableApis";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";

const TABLE_ID = "foerderregel";

// @vue/component
export default {
  name: "GeschaeftsregelList",
  components: {
    AngularLink,
    SmartTable,
    GeschaeftsregelModalCreate,
    PageTabTitle,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "Details anzeigen",
            route: "root.foerderregeln.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [
          {
            permission: "foerderregel.create",
            label: "Neue Geschäftsregel erstellen",
            callback: this.onCreateRegel,
            type: "button",
            className: "btn btn-primary test_create_geschaeftsregel",
            icon: "glyphicon-plus",
          },
        ],
        label: "Geschäftsregeln",
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
        },
      },
      modalCreate: false,
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },

    getCountInstrumente() {
      return row => {
        const INSTRUMENTE_LENGTH = row.instrumente ? row.instrumente.length : 0;
        const VARIANTEN_LENGTH = row.varianten ? row.varianten.length : 0;
        const REGELSAETZE_LENGTH = row.regelsaetze ? row.regelsaetze.length : 0;
        return INSTRUMENTE_LENGTH + VARIANTEN_LENGTH + REGELSAETZE_LENGTH;
      };
    },
  },
  methods: {
    onCreateRegel() {
      this.modalCreate = true;
    },

    closeCreateRegel() {
      this.modalCreate = false;
    },
  },
};
