"use strict";

import template from "./change-password.jade";

class ChangePasswordController {
  /*@ngInject*/
  constructor(DiasNotification, SyConfigs, AuthService, $window) {
    this.loading = true;
    this.$window = $window;
    this.AuthService = AuthService;
    this.DiasNotification = DiasNotification;
    this.conf = {
      passwordMinLength: SyConfigs.wert("password_rules").min_length,
      passwordMaxLength: SyConfigs.wert("password_rules").max_length,
      passwordAllowedChars: SyConfigs.wert("password_rules")
        .allowed_chars.split("").join(" "),
    };
    this.model = {};
    this.errorsLabel = {
      password_old: "Altes Passwort: ",
      password_new1: "Neues Passwort: ",
      password_new2: "Neues Passwort wiederholen: "
    };
    this.AuthService.refreshUser().then(
      user => {
        this.user = user;
        this.loading = false;
      }
    );
  }

  savePassword() {
    this.saveStatus = true;
    this.user.post("passwort_aendern", this.model).then(
      () => {
        this.DiasNotification.page.success("Ihr Passwort wurde erfolgreich aktualisiert.");
        this.AuthService.refreshUser().then(
          () => {
            this.$window.location.replace("/");
            this.saveStatus = false;
          }
        );
      },
      errors => this.onErrors(errors, this.errorsLabel)
    );
  }

  onErrors(errors, labelErrors) {
    this.DiasNotification.form.clear();
    this.DiasNotification.form.error(errors, "Ihre Daten sind fehlerhaft oder unvollständig.", {}, labelErrors);
    if (errors.data) {
      this.errors = errors.data;
    } else {
      this.errors = errors;
    }
    this.saveStatus = false;
  }
}

export default {
  template: template(),
  controller: ChangePasswordController,
  controllerAs: "vm",
  bindings: {
    regClass: "<?",
    regHeader: "<?",
    regBody: "<?"
  }
};
