import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "KAufgabeModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        au_passiv: false,
        au_termin_sichtbar: false,
        au_ende: false
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "au_kbez",
            type: "text",
            label: "_LBL_K_AUFGABE_MODAL_CREATE_KURZBEZEICHNUNG_",
            required: true,
            maxlength: 200,
            view: "v",
            translate: true,
          },
          {
            id: "au_kbez_admin",
            type: "text",
            label: "_LBL_K_AUFGABE_MODAL_CREATE_KURZBEZEICHNUNG_ADMIN_",
            required: true,
            maxlength: 200,
            view: "v",
            translate: true,
          },
          {
            id: "au_kbezbeobachter",
            type: "text",
            label: "_LBL_K_AUFGABE_MODAL_CREATE_KURZBEZEICHNUNG_BEOBACHTER_",
            required: true,
            maxlength: 200,
            view: "v",
            translate: true,
          },
          {
            id: "au_kbezdetail",
            type: "text",
            label: "_LBL_K_AUFGABE_MODAL_CREATE_KURZBEZEICHNUNG_DETAILLIERT_",
            required: true,
            maxlength: 200,
            view: "v",
            translate: true,
          },
          {
            id: "au_bez",
            type: "richtext",
            label: "_LBL_K_AUFGABE_MODAL_CREATE_LANGBEZEICHNUNG_",
            view: "v-alt",
            translate: true,
          },
          {
            id: "au_termin_sichtbar",
            type: "boolean",
            label: "_LBL_K_AUFGABE_MODAL_CREATE_TERMIN_SICHTBAR_",
            view: "v-alt",
            translate: true,
          },
          {
            id: "au_passiv",
            type: "boolean",
            label: "_LBL_K_AUFGABE_MODAL_CREATE_PASSIV_",
            view: "v-alt",
            translate: true,
          },
          {
            id: "au_ende",
            type: "boolean",
            label: "_LBL_K_AUFGABE_MODAL_CREATE_BEARBEITUNGSENDE_",
            view: "v-alt",
            translate: true,
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  methods: {
    onChange() { },

    onError({ error }) {
      this.errors = error;
      this.errorsClone = error;
    },

    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);

      this.postHttp({ url: "aufgaben/", data }).then(
        response => {
          this.addNotification({ text: "_MSG_K_AUFGABE_MODAL_CREATE_SUCCESS_" });
          this.close();
          this.$goTo("root.kaufgaben.detail", { id: response.pk });
        },
        error => {
          this.onError({ error: error.data });
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
