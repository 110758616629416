import FaqDetailsItemMixin from "../FaqDetailsItemMixin";
// import Highcharts from "highcharts";

// @vue/component
export default {
  name: "FaqDetailsItemChart",
  mixins: [
    FaqDetailsItemMixin,
  ],
};
