export default [
  {
    label: "_TXT_DOKUMENTE_SUCHEN_WIZARD_SUCHKlASSE_AUSWAEHLEN_",
    slotId: "step1",
    title: "_TXT_DOKUMENTE_SUCHEN_WIZARD_SUCHKlASSE_AUSWAEHLEN_",
  },
  {
    label: "_TXT_DOKUMENTE_SUCHEN_WIZARD_SUCHE_DEFINIEREN_",
    slotId: "step2",
    title: "_TXT_DOKUMENTE_SUCHEN_WIZARD_SUCHE_DEFINIEREN_",
  },
  {
    label: "_TXT_DOKUMENTE_SUCHEN_WIZARD_ERGEBNISSE_ANZEIGEN_",
    slotId: "step3",
    title: "_TXT_DOKUMENTE_SUCHEN_WIZARD_ERGEBNISSE_ANZEIGEN_",
  },
];
