"use strict";

import BaseEditController from "dias/standard_foerdermodule/standard_foerdermodule.edit.controller";
import {
  size,
} from "lodash-es";

class KostenFinanzierungBaseController extends BaseEditController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, BetragsrechnerRegistry, i18n) {
    super($scope, $q, Foerdermodule);
    this.gettext = i18n.gettext;
    this.name = "KostenFinanzierungBaseController";

    this.hoverEdit = {};
    // Wenn Objekt angegeben und ein restangularized Antrag ist
    if (this.obj && this.obj.a_nr && this.obj.restangularized) {
      // Nutzer den für das Objekt verwendete Rest-Service
      this.Foerderantrag = this.obj.getRestangularService();
    } else {
      this.Foerderantrag = Foerderantrag;
    }

    // Toggle-Flag für die UI zum Hinzufügen/Erstellen einer neuen Position
    this.addPositionMode = false;

    // Create-Position-Errors
    // this.createErrors = {};
    // Update-Position-Errors
    this.updateErrors = {};

    // Positionen
    this.positionen = [];
    this.editPositionModes = {};
    this.editPositionen = {};

    // Katalog-Details
    this.katalogDetails = [];

    // Object-Feld um neue Position zu erzeugen.
    // Die eigentlichen Daten-Felder werden im jeweiligen Controller definiert.
    this.newPosition = {};

    // Antrag-Kostenart / -Finanzierungsart
    this.antragKoFiArt = {
      bez: "",
      summe: 0
    };

    // Bearbeitungstyp mit Positionen:
    this.editType = "standard";
    this.loading = false;

    // Informationen für Snapshots:
    this.snapshot = this.snapshot || $scope.snapshot;
    this.snapshotdiff = this.snapshotdiff || $scope.snapshotdiff;
    this.snapshotIcons = {};
    this.snapshotPositionClass = {};
    this.snapshotPositionName = {};
    this.snapshotPositionen = {};
    this.snapshotDiffPositionen = {};

    this.firstLoad = true,
    // Positionen initial laden:
    this._reloadPositionen();

    this.BetragsrechnerRegistry = BetragsrechnerRegistry;
    this.initBetragsrechnerinfos();
  }

  showErrorLabel(field) {
    return this.betragsrechner.get_labelmapping()[field] + ":";
  }

  getSumme() {
    if (this.infoprovider && this.infoprovider.details && this.infoprovider.details[this.modul.regel.afr_id] && !_.isNil(this.infoprovider.details[this.modul.regel.afr_id].betrag)) {
      return this.infoprovider.details[this.modul.regel.afr_id].betrag;
    }
    return this.antragKoFiArt.summe;
  }

  getDefaultBetragsrechner() {}

  initBetragsrechnerinfos() {
    // Setzt den für den Controller relevanten Betragsrechner.
    // Wenn im verwendeten Modul ein Betragsrechner definiert ist, wird
    // dieser verwendet, sonst der im Controller festgelegte.
    let betragsRechnerName = this.getDefaultBetragsrechner();
    if (this.modul.regel.afr_argument.betragsrechner &&
        this.modul.regel.afr_argument.betragsrechner.wert) {
      betragsRechnerName = this.modul.regel.afr_argument.betragsrechner.wert;
    }
    const BetragsrechnerCls = this.BetragsrechnerRegistry.getBetragsrechner(
      betragsRechnerName
    );
    // Zusatzinfos für den Rechner:
    this.extras = {
      regeldata: this.modul.data,
      antragsregel: this.modul.regel,
      argument: this.modul.regel.afr_argument,
    };
    this.betragsrechner = new BetragsrechnerCls(this.$scope, "antrag", this.modul.regel, this.modul.data);
    this.vorschau = {};
    this.newPosition = this.initNewPosition();
  }

  initNewPosition() {
    return {
      argument: Object.assign({}, this.betragsrechner.get_fields(this.extras) || {}),
    };
  }

  _resetNewPosition() {
    this._resetPropsOfObj(this.newPosition);
  }

  get_single_position() {
    return this.positionen[0];
  }

  getKatalogDetails(pk) {
    // Gibt die Daten eines KatalogDetail-Eintrags anhand der ID zurück.
    for (let k = 0; k < this.katalogDetails.length; k++) {
      if (this.katalogDetails[k].pk === pk) {
        return this.katalogDetails[k];
      }
    }
  }

  _resetPropsOfObj(obj) {
    Object.keys(obj).map(key => {
      const prop = obj[key];
      if (typeof prop === "object" && prop !== null) {
        this._resetPropsOfObj(prop);
      } else {
        obj[key] = "";
      }
    });
  }

  _reloadPositionen() {
    // zum implementieren im jeweiligen controller.
    // die implementierung muss this._setPositionen(..) aufrufen.
  }

  _setPositionen(positionen) {
    this.positionen = positionen;
    this.editPositionModes = {};
    this.editPositionen = {};
    for (let i = 0; i < this.positionen.length; i++) {
      const p = this.positionen[i];
      this.editPositionModes[p.pk] = false;
      this.editPositionen[p.pk] = _.cloneDeep(p);
    }
    if (this.firstLoad && this.positionen.length === 1 && this.readonly) {
      this.statusHidePositionen = true;
    }
  }

  _initKatalogDetails(katalogResults, regelKatalogId, regelKatalogDetails) {
    this.katalogDetails = [];
    regelKatalogDetails = regelKatalogDetails || [];
    for (let k = 0; k < katalogResults.length; k++) {
      const ka = katalogResults[k];
      if (ka.pk !== regelKatalogId) {
        continue;
      }
      for (let d = 0; d < ka.details.length; d++) {
        const kd = ka.details[d];
        if (regelKatalogDetails.indexOf(kd.pk) !== -1) {
          this.katalogDetails.push(kd);
        }
      }
    }
  }

  toggleAddPosition() {
    this.addPositionMode = !this.addPositionMode;
    if (this.addPositionMode) {
      this.newPosition = this.initNewPosition();
    } else {
      this.createErrors = {};
    }
  }

  _doPostPosition() {
    // zum implementieren im jeweiligen controller.
    // die methode muss ein promise zurückgeben!
  }

  createPosition() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    return this._doPostPosition().then(
      response => {
        this.infoprovider = angular.extend(this.infoprovider, response.zuschussrechner);
        if (this.createErrors) {
          delete this.createErrors;
        }
      // this.createErrors = {};
        this._reloadPositionen();
        this.toggleAddPosition();
        this._resetNewPosition();
        this._doSavecallback();
        this.updateSnapshotDiff();
      },
      errors => {
        if (errors && errors.status !== 403) {
          this.createErrors = {};
          this.createErrors = errors.data;
        }
        return this.$q.reject(errors);
      }
    )
      .finally(() => this.loading = false);
  }

  deletePosition(pos) {
    this.loading = true;
    pos.customDELETE("entfernen").then(response => {
      this.infoprovider = angular.extend(this.infoprovider, response.zuschussrechner);
      this._reloadPositionen();
      this._doSavecallback();
      this.updateSnapshotDiff();
    })
      .finally(() => this.loading = false);
  }

  toggleEditPosition(pos) {
    this.editPositionModes[pos.pk] = !this.editPositionModes[pos.pk];
    if (!this.editPositionModes[pos.pk]) {
      const p = this.positionen.find(p => p.pk === pos.pk);
      if (p) {
        this.editPositionen[pos.pk] = _.cloneDeep(p);
      }
      this.updateErrors = {};
    }
  }

  updatePosition(pos) {
    this.loading = true;
    return pos.customPUT(pos).then(response => {
      this.infoprovider = angular.extend(this.infoprovider, response.zuschussrechner);
      this._reloadPositionen();
      this.toggleEditPosition(pos);
      this._doSavecallback();
      this.updateSnapshotDiff();
      if (this.updateErrors[pos.pk]) {
        delete this.updateErrors[pos.pk];
      }
      return response;
      // this.updateErrors[pos.pk] = {};
    }, errors => {
      if (errors && errors.status !== 403) {
        this.updateErrors[pos.pk] = {};
        this.updateErrors[pos.pk] = errors.data;
      }
      return this.$q.reject(errors);
    })
      .finally(() => this.loading = false);
  }

  get_prozent() {
    // zum implementieren im jeweiligen controller.
  }

  show_prozente() {
    // Gibt an, ob Prozente ausgewiesen werden sollen.
    return false;
  }

  updateSnapshotDiff() {
    if (this.snapshot) {
      this.Foerderantrag.one(this.modul.regel.afr_aid).snapshots.one(this.snapshot.pk).one("diff").get().then(response => {
        this.snapshotdiff = {
          modified: response.diff_result.modified.kofi || {},
          added: response.diff_result.added.kofi || {},
          removed: response.diff_result.removed.kofi || {}
        };
      });
    }
  }

  _doSavecallback(update_zuschussinfo = false) {
    if (this.savecallback !== undefined) {
        /* Je nach Art der Aktion müssen die Zuschussrechnerinformationen
         * aktualisiert werden. Beim Update und Create wird die aktualisierte
         * Info von der Api zurückgegeben, beim Delete ist die Response ohne
         * Daten daher hier die Möglichkeit des Aktualisierens.
         */
      this.savecallback()(update_zuschussinfo);
    }
  }

  toggleStatusInfo() {
    this.statusInfo = !this.statusInfo;
  }

  togglePositionen() {
    this.statusHidePositionen = !this.statusHidePositionen;
  }

  changeHoverPosition(mouseEnter) {
    this.hoverPosition = mouseEnter;
  }

  getDeburredString(string) {
    if (!string) {
      return;
    }
    const tr = { ä: "ae", ü: "ue", ö: "oe", ß: "ss" };
    return string.toLowerCase().replace(/[\u00e4|\u00fc|\u00f6|\u00df]/g, item => tr[item]).replace(/\s+/g, "_");
  }

  mouseenterPosition(pk) {
    if (pk) {
      this.hoverEdit[pk] = true;
    } else {
      this.hoverEditNew = true;
    }
  }

  mouseleavePosition(pk) {
    if (pk) {
      this.hoverEdit[pk] = false;
    } else {
      this.hoverEditNew = false;
    }
  }

  isErrors(errors) {
    return errors && size(errors);
  }
}

export default KostenFinanzierungBaseController;
