import translate from "../../../../../../global/directives/translate";
import NotificationMixin from "../../../../../../global/mixins/NotificationMixin";
import {
  assign,
  cloneDeep,
  isNil,
  isNull,
  toLower,
  forEach,
  size,
} from "lodash-es";
import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    NotificationMixin,
  ],
  props: {
    model: {
      type: Array,
      required: true,
    },
    currentModel: {
      type: Object,
      required: false,
      default: undefined,
    },
    currentIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      optionsModal: {
        showCloseButton: true,
        required: true,
      },
      modelLocal: {
        id: null,
        min: null,
        max: null,
        nachkommastellen: null,
        label: null,
        einheit: null,
        hilfetext: null,
        placeholder: null,
        value: null,
        pos: null,
        pflichtfeld: false,
        expression: null,
        signal_regex: null,
      },
    };
  },
  computed: {
    getHeader() {
      return this.currentModel ? "Antwort aktualisieren" : "Antwort hinzufügen";
    },
    isDisabled() {
      return !this.modelLocal.id;
    },
    options() {
      return {
        min: {
          type: "float",
          digits: 0,
          id: "min",
          label: "Min",
          required: false,
          view: "v",
        },
        max: {
          type: "float",
          digits: 0,
          id: "max",
          label: "Max",
          required: false,
          view: "v",
        },
        nachkommastellen: {
          type: "float",
          digits: 0,
          id: "nachkommastellen",
          label: "Nachkommastellen",
          required: false,
          view: "v",
        },
        label: {
          type: "text",
          id: "label",
          label: "Label",
          required: false,
          view: "v",
          change: this.changeLabel,
        },
        einheit: {
          type: "select",
          id: "einheit",
          label: "Einheit",
          required: false,
          view: "v",
          keyArray: true,
          data: ["%", "€", "km", "Fahrzeuge", "Personen"],
        },
        hilfetext: {
          type: "richtext",
          id: "hilfetext",
          label: "Hilfetext",
          required: false,
          view: "v-alt",
        },
        placeholder: {
          type: "text",
          id: "placeholder",
          label: "Placeholder",
          required: false,
          view: "v",
        },
        id: {
          type: "text",
          id: "id",
          label: "Technische ID",
          required: true,
          view: "v",
          change: this.changeId,
        },
        pflichtfeld: {
          type: "boolean",
          id: "pflichtfeld",
          label: "Pflichtfeld",
          required: false,
          view: "v-alt",
          disabled: size(this.modelLocal.expression) || size(this.modelLocal.signal_regex),
          helpText: "_TXT_MODUL_DYNAMISCHE_ZAHLEN_CONFIG_EDIT_HELPTEXT_PFLICHTFELD_",
        },
        expression: {
          type: "text",
          id: "expression",
          label: "Berechnungsformel",
          required: false,
          disabled: size(this.modelLocal.signal_regex),
          change: this.changeExpressionRegex,
          view: "v",
          helpText: "_TXT_MODUL_DYNAMISCHE_ZAHLEN_CONFIG_EDIT_HELPTEXT_EXPRESSION_",
        },
        signal_regex: {
          type: "text",
          id: "signal_regex",
          label: "Regulärer Ausdruck für Signalempfang",
          required: false,
          disabled: size(this.modelLocal.expression),
          change: this.changeExpressionRegex,
          view: "v",
          helpText: "_TXT_MODUL_DYNAMISCHE_ZAHLEN_CONFIG_EDIT_HELPTEXT_SIGNAL_",
        },
      };
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../../global/components/Modal/Modal.vue").default;
    this.$options.components.FormElement = require("../../../../../../global/components/FormElement/FormElement.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.currentModel) {
        this.modelLocal = assign({}, this.modelLocal, this.currentModel);
      }
    },

    changeLabel() {
      if (isNil(this.currentModel)) {
        if (isNull(this.modelLocal.label)) {
          this.modelLocal.id = null;
        } else {
          this.modelLocal.id = toLower(this.modelLocal.label).replace(/[^a-zA-Z0-9]/g, "");
        }
      }
    },

    changeId() {
      this.modelLocal.id = toLower(this.modelLocal.id).replace(/[^a-zA-Z0-9]/g, "");
    },

    changeExpressionRegex() {
      if (size(this.modelLocal.expression) || size(this.modelLocal.signal_regex)) {
        this.modelLocal.pflichtfeld = false;
      }
    },

    save() {
      if (this.checkId()) {
        return;
      }
      const DATA = cloneDeep(this.modelLocal);
      const MODEL = cloneDeep(this.model) || [];

      if (this.currentModel) {
        MODEL.splice(this.currentIndex, 1, DATA);
      } else {
        MODEL.push(DATA);
      }
      this.close({ model: MODEL });
    },

    checkId() {
      let statusError = false;
      const CURRENT_MODEL = this.currentModel || {};
      forEach(this.model, item => {
        if (item.id === this.modelLocal.id &&
          (item.id !== CURRENT_MODEL.id)) {
          this.addNotification({ text: `Technische ID "${ item.id }" existiert bereits.`, type: "error" });
          statusError = true;
          return false;
        }
      });
      return statusError;
    },
  },
};
