"use strict";

import template from "./table.jade";
import controller from "./table.controller";

const nutzerverwaltungComponent = function() {
  return {
    restrict: "EA",
    scope: {
      config: "=",
      columnsNotEditable: "<?",
      filterRight: "<?",
      rightDetails: "<",
      rowDetails: "=?",
      tableName: "<?",
      link: "<?"
    },
    template,
    controller,
    controllerAs: "vm",
    bindToController: true
  };
};

export default nutzerverwaltungComponent;
