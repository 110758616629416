import ModulAntragslisteObjektlisteElementList
  from "../../../Antragsliste/ModulAntragslisteObjektlisteElementList/ModulAntragslisteObjektlisteElementList.vue";
import ModuleMixin from "../ModuleMixin";

// @vue/component
export default {
  name: "ObjektlisteAntragsliste",
  components: {
    ModulAntragslisteObjektlisteElementList,
  },
  mixins: [
    ModuleMixin,
  ],
  computed: {
    labelHeader() {
      if (this.modelParameter.titel) {
        return this.modelParameter.titel;
      }
      return "_LBL_GR_ANTRAGSLISTE_HEADER_";
    },

    isModuleEditable() {
      return false;
    },
  },
};
