var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group-readonly"] = jade_interp = function(idx, id, label, fieldName){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div class=\"form-group\"><label" + (jade.attr("ng-attr-for", "" + (id) + "", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span></label>");
block && block();
buf.push("</div>");
};
buf.push("<div ng-repeat=\"bericht in vm.parameter[vm.key].wert\"><span class=\"clearfix\"><label class=\"font-normal inline\"><span class=\"titel\">{{ $index + 1 }}. {{ bericht.label }}<small>&nbsp;<a href=\"#\" ng-click=\"showDetail = !showDetail; vm.preventFurtherAction($event);\" style=\"white-space:nowrap;\">(Konfiguration {{showDetail?'ausblenden':'anzeigen'}})</a></small></span></label><div ng-show=\"showDetail\">");
jade_mixins["form-group-readonly"].call({
block: function(){
buf.push("<span ng-attr-id=\"param_{{::$index }}_label\" ng-attr-name=\"param_{{::$index }}_label\" class=\"form-control-static\">{{:: bericht.label }}\n</span>");
}
}, "$index", "param_{{::$index }}_label", "Bezeichnung", "label");
jade_mixins["form-group-readonly"].call({
block: function(){
buf.push("<span dias-cloak=\"vm.loading\" ng-attr-id=\"param_{{::$index }}_vorlage\" ng-attr-name=\"param_{{::$index }}_vorlage\" class=\"form-control-static\">{{:: vm.getVorlageLabel(bericht.vorlage)}}</span>");
}
}, "$index", "param_{{::$index }}_vorlage", "Vorlage", "vorlage");
jade_mixins["form-group-readonly"].call({
block: function(){
buf.push("<span ng-attr-id=\"param_{{::$index }}_minimum\" ng-attr-name=\"param_{{::$index }}_minimum\" class=\"form-control-static\">{{:: bericht.minimum }}</span>");
}
}, "$index", "param_{{::$index }}_minimum", "Mindestanzahl", "minimum");
jade_mixins["form-group-readonly"].call({
block: function(){
buf.push("<span ng-attr-id=\"param_{{::$index }}_maximum\" ng-attr-name=\"param_{{::$index }}_maximum\" class=\"form-control-static\">{{:: bericht.maximum }}</span>");
}
}, "$index", "param_{{::$index }}_maximum", "Maximalanzahl", "maximum");
buf.push("</div></span></div>");;return buf.join("");
}