import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import AsideRightBox from "../../../global/components/AsideRightBox/AsideRightBox.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTooltip from "../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import VertragsKontoSaldo from "./VertragsKontoSaldo/VertragsKontoSaldo.vue";

import {
  FilterCurrencyMixin,
  FilterDefaultForEmptyMixin,
} from "../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  components: {
    AngularLink,
    AsideRightBox,
    PuxButton,
    PuxIcon,
    PuxTooltip,
    PuxTranslate,
    VertragsKontoSaldo,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterDefaultForEmptyMixin,
  ],
  props: {
    vertrag: {
      type: Object,
      required: true,
    },
    konto: {
      type: Object,
      required: true,
    },
    showBuchungen: {
      type: Function,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      optionsAsideRightBox: {
        icon: "glyphicon-salden",
        active: false,
      },
      isHiddenSelbstbeteiligung: true,
      isHiddenZuschussAnpassung: false,
      isBuchungButtonDisabled: undefined,
    };
  },
  computed: {
    zuschussAktualisiert() {
      return this.filterCurrency(this.konto.aktualisierter_zuschuss);
    },

    zuschussBewilligt() {
      return this.filterCurrency(this.konto.bewilligt);
    },

    tagForZuschussBewilligt() {
      return this.konto.aktualisierter_zuschuss ? "div" : "strong";
    },

    bewilligtAngepasstDiff() {
      return this.konto.zuschussanpassungen.length || (this.konto.angepasster_zuschuss && this.konto.angepasster_zuschuss !== this.konto.bewilligt);
    },

    zuschussAngepasst() {
      return this.filterCurrency(this.konto.angepasster_zuschuss);
    },

    nochAuszuzahlen() {
      return this.filterCurrency(this.konto.noch_auszuzahlen);
    },

    selbstbeteiligungEingegangen() {
      return this.filterDefaultForEmpty(this.filterCurrency(this.konto.selbstbeteiligung.eingegangen));
    }
  },
  methods: {
    disableBuchungen() {
      this.isBuchungButtonDisabled = true;
    },

    enableBuchungen() {
      this.isBuchungButtonDisabled = false;
    },
  }
};
