import {
  computed,
  toRef,
} from "vue";

export default function SitzungsartenTextsAPI(props) {
  const isCreate = toRef(props, "isCreate");

  const buttonIcon = computed(() => {
    return isCreate.value ? "plus" : "pencil";
  });

  const buttonText = computed(() => {
    return isCreate.value ? "_TXT_SITZUNGSART_TAGESORDNUNGSPUNKT_MODUL_HINZUFUEGEN_" : "";
  });

  const buttonTitle = computed(() => {
    return isCreate.value ? "" : "_TXT_SITZUNGSART_TAGESORDNUNGSPUNKT_MODUL_BEARBEITEN_";
  });

  const buttonTypeClass = computed(() => {
    return isCreate.value ? ["default", "block"] : "default";
  });

  const modalHeaderText = computed(() => {
    return isCreate.value ? "_TXT_SITZUNGSART_TAGESORDNUNGSPUNKT_MODUL_HINZUFUEGEN_" : "_TXT_SITZUNGSART_TAGESORDNUNGSPUNKT_MODUL_BEARBEITEN_";
  });

  return {
    buttonIcon,
    buttonText,
    buttonTitle,
    buttonTypeClass,
    modalHeaderText,
  };
}
