import NotizenModal from "../NotizenModal/NotizenModal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

import {
  gettext,
} from "../../../global/functions/utils";
import { createNamespacedHelpers } from "vuex";

const {
  mapGetters
} = createNamespacedHelpers("notizen");

// @vue/component
export default {
  name: "NotizenIcon",
  components: {
    NotizenModal,
    PuxButton,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    notizenKey: {
      type: String,
      required: false,
      default: undefined,
    },
    notizenObjektid: {
      type: String,
      required: false,
      default: undefined,
    },
    notizenObjektContentType: {
      type: Number,
      required: false,
      default: undefined,
    },
    buttonClass: {
      type: String,
      required: false,
      default: undefined,
    },
    iconClass: {
      type: String,
      required: false,
      default: undefined,
    },
    buttonId: {
      type: String,
      required: true,
    },
    mainObjektid: { // Für Notizen in Dokumente
      type: String,
      required: false,
      default: undefined,
    },
    mainContentType: { // Für Notizen in Dokumente
      type: Number,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      statusModal: false,
    };
  },
  computed: {
    buttonClasses() {
      return [
        this.buttonClass,
      ];
    },

    statusActive() {
      return !!this.notizenLength;
    },

    notizenLength() {
      if (this.notizenObjektid) {
        if (this.mainObjektid) { // DOKUMENTE
          if (this.GET_NOTIZEN_DOCUMENTS[this.notizenObjektid] &&
            this.GET_NOTIZEN_DOCUMENTS[this.notizenObjektid][this.mainObjektid]) {
            return this.GET_NOTIZEN_DOCUMENTS[this.notizenObjektid][this.mainObjektid].length;
          }
          return 0;
        }
        if (this.GET_NOTIZEN_WEITERE_OBJEKTE[this.notizenObjektid]) {
          return this.GET_NOTIZEN_WEITERE_OBJEKTE[this.notizenObjektid].length;
        }
        return 0;
      }

      if (this.GET_NOTIZEN_OBJ[this.notizenKey]) {
        return this.GET_NOTIZEN_OBJ[this.notizenKey].length;
      }
      return 0;
    },

    translateIconTitle() {
      return {
        title: "_TXT_NOTIZEN_ICON_TITLE_{{label}}_{{length}}_",
        extra: {
          label: this.labelTranslate,
          length: this.notizenLength,
        }
      };
    },

    translateIconExtra() {
      return {
        label: this.labelTranslate,
        length: this.notizenLength,
      };
    },

    labelModalTranslate() {
      return {
        text: "_TXT_NOTIZEN_HEADER_{{label}}_",
        extra: {
          label: this.labelTranslate,
        }
      };
    },

    labelTranslate() {
      return gettext(this.label);
    },

    selectorClose() {
      return `#${ this.buttonId }`;
    },

    iconClassLocal() {
      if (this.iconClass) {
        return `${ this.iconClass } icon_middle`;
      }
      return "icon_middle";
    },

    ...mapGetters([
      "GET_NOTIZEN_READY",
      "GET_NOTIZEN_OBJ",
      "GET_NOTIZEN_WEITERE_OBJEKTE",
      "GET_NOTIZEN_DOCUMENTS",
    ]),
  },
  methods: {
    showNotizen() {
      if (!this.GET_NOTIZEN_READY) {
        return;
      }
      this.statusModal = true;
    },

    closeModal() {
      this.statusModal = false;
    },
  },
};
