"use strict";

class FavoritenUtilsService {
  /*@ngInject*/
  constructor($q) {
    this.$q = $q;
  }

  /** Ändert den Favorisierungs-Status des gegebenen Objekt ``obj``.
   * Wenn das Objekt ``obj`` bereits favorisiert ist, wird versucht die Favorisierung aufzuheben.
   * Wenn das Objekt ``obj`` noch nicht favorisiert ist, wird versucht es zu favorisieren.
   * Die ``favorit``-Eigenschaft des Objekts entsprechend aktualisiert. Falls der
   * Request fehlschlägt, wird die Eigenschaft wieder zurückgesetzt.
   * Während ein Request für ein Objekt läuft, hat diese Funktion für dieses Objekt keine Auswirkungen.
   *
   * @param obj: Instanz eines Models, das favorisiert werden kann (z.B. Förderantrag)
   * @returns promise des Requests
  */
  toggleFavorit(obj) {
    if (angular.isUndefined(obj) || obj._blockedToggleFavorit) {
      return;
    }
    obj._blockedToggleFavorit = true;
    let promise;
    const oldValue = obj.favorit;
    obj.favorit = !obj.favorit;
    if (oldValue) {
      promise = obj.customDELETE("favorisieren");
    } else {
      promise = obj.one("favorisieren").customPOST();
    }
    return promise.then(
      () => {},
      response => {
        obj.favorit = oldValue;
        this.$q.reject(response);
      }
    ).finally(() => obj._blockedToggleFavorit = false);
  }

  /** Ruft die Liste der favorisierten Objekte des gegebenen Model-Services ``modelService`` ab.
   *
   * @param modelService: Service eines Models, das favorisiert werden kann (z.B. Förderantrag)
   * @param params: Get-Parameter für den Request
   * @param nichtFavorisiert: Boolean. True, wenn nur nicht favorisierte Objekte aufgelistet werden sollen.
   * @returns promise des Requests
  */
  getFavoriten(modelService, params, nichtFavorisiert) {
    const favParams = angular.extend(params, { favorit: !nichtFavorisiert,
                                               ordering: "-nutzerfavoriten__crdate" });
    return modelService.getList(favParams);
  }

  /** Fügt einen Filter nach Favorisiert-Flag zum gegebenen FilterSet ``filterSet`` hinzu.
   *
   * @param filterSet: von ``dias/core/table/filterSet`` FilterSet abgeleitetes Objekt.
   */
  addFilter(filterSet) {
    filterSet.addFilter("favorit", {
      label: "Markiert",
      bool: true,
      param: "favorit",
    });
  }
}

export default FavoritenUtilsService;
