"use strict";

import angular from "angular";
import BaseWizardController from "./base.wizard.controller";
import template from "./details.jade";
import ansprechpartnerZuweisenTemplate from "./ansprechpartner.zuweisen.alle.modal.jade";
import ansprechpartnerZuweisenController from "./ansprechpartner.zuweisen.alle.modal.controller";
import createManuelleAufgabeTemplate from "dias/workflow/aufgaben/manuelleAufgabe.create.jade";
import createManuelleAufgabeController from "dias/workflow/aufgaben/manuelleAufgabe.create.controller";
import RSWechselWizardController from "./rswechsel.wizard.controller";
import createGutachtenController from "dias/gutachten/gutachten/modal.create.controller";
import createGutachtenTemplate from "dias/gutachten/gutachten/modal.create.jade";
import kofiUebersichtTemplate from "./antrag.kofi.uebersicht.modal.jade";

import SaveCallbackActions from "../../../vue/client/vue/const/SaveCallbackActions";

import {
  EventBus,
} from "../../../vue/client/vue/global/functions/event-bus";
import {
  MAPPING,
} from "../../../vue/client/vue/components/Geschaeftsregel/Module/Module";
import {
  cloneDeep,
  findIndex,
  forEach,
  get,
  isArray,
  isNil,
  isString,
  reduce,
} from "lodash-es";
import store from "../../../vue/client/vue/store";

const GEGENPRUEFEN_IDS = [
  "2db26051-14c5-5ed5-e050-007f01013822",
  "7c0e901d-3ec6-4b16-b64a-b8e6289804f5",
  "8af3d8dc-a051-bd39-7692-3069d37febf3",
  "c7c53887-7305-4411-be28-d8a213ea9d23",
  "640faf21-ed9b-433a-ac23-ee9c4b1e4e2a"

];
const EINREICHEN_PK = "003ed184-2c7e-4d30-a5f7-7b8aec3b47c6";

class FoerderantragAddEditAntragController extends BaseWizardController {
  /*@ngInject*/
  constructor(
    $rootScope,
    $scope,
    $sce,
    $state,
    $q,
    $stateParams,
    $filter,
    diasConfirmDialog,
    diasModalDialog,
    KAufgabe,
    Foerderantrag,
    Antragstellerorganisationen,
    DiasNotification,
    AuthService,
    Foerdermodule,
    FoerdermoduleRegistry,
    FoerderangebotAnzeigekontextUUIDs,
    $window,
    moment,
    $location,
    SyConfigs,
    FavoritenUtils,
    $anchorScroll,
    $timeout,
    i18n,
    Katalog,
    $document,
    DokumentEvents,
    Client,
  ) {
    super(
      $q,
      Foerdermodule,
      FoerdermoduleRegistry,
      FoerderangebotAnzeigekontextUUIDs,
      AuthService,
      i18n,
      $timeout
    );
    this.MAPPING = MAPPING;
    this.loading = true;
    this.statusDeleteVerknuepfungLoading = {};
    this.window = $window;
    this.$scope = $scope;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.antragPk = $stateParams.id;
    this.diasConfirmDialog = diasConfirmDialog;
    this.diasModalDialog = diasModalDialog;
    this.DiasNotification = DiasNotification;
    this.notification = DiasNotification.page;
    this.SyConfigs = SyConfigs;
    this.AuthService = AuthService;
    this.FavoritenUtils = FavoritenUtils;
    this.Foerderantrag = Foerderantrag;
    this.Antragstellerorganisationen = Antragstellerorganisationen;
    this.$state = $state;
    this.$filter = $filter;
    this.$document = $document;
    this.Katalog = Katalog;
    $anchorScroll.yOffset = 210;
    this.$anchorScroll = $anchorScroll;
    this.$timeout = $timeout;
    moment.locale("de");
    this.Client = Client;
    this.DokumentEvents = DokumentEvents;

    this.antragConfig = this.SyConfigs.wert("foerderantrag") || {};
    this.astAlsButtonConfig = this.SyConfigs.wert("ast_als_button") || {};
    const WF_STATUS_GRUPPEN_IN_HEADER = this.SyConfigs.wert("wf_statusgruppen_in_header") || {};
    WF_STATUS_GRUPPEN_IN_HEADER.objects = WF_STATUS_GRUPPEN_IN_HEADER.objects || [];
    this.statusWFStatusGruppenInHeader = WF_STATUS_GRUPPEN_IN_HEADER.objects.indexOf("antrag") !== -1;
    this.statusShowEinreichenMessage = !!this.gettext("_HTML_ANTRAG_DETAILS_EINREICHEN_MSG_{{antragsnummer}}_{{antragsid}}_");
    this.gegenpruefenInaktive = false;
    this.initData();
    this.getUser();

    this.loadAntrag();

    // Workflow callbacks bind
    this.onWorkflowInit = this.onWorkflowInit.bind(this);
    this.onWorkflowStarted = this.onWorkflowStarted.bind(this);
    this.onWorkflowFinish = this.onWorkflowFinish.bind(this);
    this.onWorkflowCanceled = this.onWorkflowCanceled.bind(this);
    this.onWorkflowSuccess = this.onWorkflowSuccess.bind(this);
    this.onWorkflowFailure = this.onWorkflowFailure.bind(this);
    this.onWorkflowfolgenUpdated = this.onWorkflowfolgenUpdated.bind(this);
    this.onAufgabeAngenommen = this.onAufgabeAngenommen.bind(this);
    this.onManuelleAufgabenChanged = this.onManuelleAufgabenChanged.bind(this);
    this.reloadAntragKofi = this.reloadAntragKofi.bind(this);
    this.reload_antrag = this.reload_antrag.bind(this);
    this.reloadWorkflowfolgen = () => {
      this.lastWfExecution = new Date().toISOString();
    };
    this.saveCallback = this.saveCallback.bind(this);

    this.closeWizardOrganisation = this.closeWizardOrganisation.bind(this);
    this.closePersonalKostenPerioden = this.closePersonalKostenPerioden.bind(this);
    this.openPersonalKostenPerioden = this.openPersonalKostenPerioden.bind(this);
    this.openPersonalKostenPeriodenWizard = this.openPersonalKostenPeriodenWizard.bind(this);
    this.closePersonalKostenPeriodenWizard = this.closePersonalKostenPeriodenWizard.bind(this);
    this.openAntragstellerWizard = this.openAntragstellerWizard.bind(this);
    this.updateModuleFromVue = this.updateModuleFromVue.bind(this);
    this.updateModuleFromVueKoFi = this.updateModuleFromVueKoFi.bind(this);
    this.updateinfoprovider = this.updateinfoprovider.bind(this);
    this.onCallbackFavoritenAndSichtbarkeit = this.onCallbackFavoritenAndSichtbarkeit.bind(this);
    this.createSnapshot = this.createSnapshot.bind(this);
    this.changeActiveSnapshot = this.changeActiveSnapshot.bind(this);
    this.updateAufgabeFromVue = this.updateAufgabeFromVue.bind(this);
    $scope.$on(this.DokumentEvents.createdDokInDokart, () => {
      this.reloadWorkflowfolgen();
    });
    $scope.$on(this.DokumentEvents.deletedDokInDokart, () => {
      this.reloadWorkflowfolgen();
    });
    $scope.$on("$destroy", () => {
      EventBus.$off("openAntragstellerWizard");
      store.commit("snapshotKF/REMOVE_SNAPSHOT_KF_MUT");
    });
    this.setEventTooglePersonalkostenPerioden();
    this.setEventTooglePersonalkostenPeriodenWizard();
    this.initEventBuses();
  }

  initEventBuses() {
    EventBus.$on("openAntragstellerWizard", this.openAntragstellerWizard);
    this.setEventAntragScore();
    this.setEventUpdateAntragsteller();
  }

  openAntragstellerWizard() {
    this.$timeout(() => {
      this.statusAntragstellerWizard = true;
      this.scrollTop();
    });
  }

  loadAstRegeln(antrag) {
    this.ast_regel_list = [];
    if (!antrag || !antrag.antragstellerorg) {
      return;
    }
    this.Antragstellerorganisationen.one(antrag.antragstellerorg)
      .getList("module").then(
        modules => {
          for (let i = 0; i < modules.length; i++) {
            this.ast_regel_list.push(modules[i].regel.asr_kbez);
          }
        }
      );
  }

  loadAntrag() {
    this.Foerderantrag.one(this.$stateParams.id).get().then(
      result => {
        this.initialStepMap(result);
        this.setAntrag(result);
        this.title_prefix = "Antrag " + this.$filter("spaced")(this.antrag.a_nr, true);
        this.loadfoerderdokumente();
        this.timelineCsvUrl = result.getRestangularUrl() + "/timeline/";
        this.openAufgabenKontextPanel();
      },
      () => {
        this.DiasNotification.page.error("Fehler beim Laden der Antragsdaten");
        this.$state.go("root.foerderantrag");
      }
    ).finally(
      () => this.loading = false
    );
  }

  setAntrag(antrag) {
    super.setAntrag(antrag);
    const baseUrl = this.antrag.getRestangularUrl().replace("/api/", "");
    this.antragUrl = baseUrl + "/";

    let extern = "";
    if (this.antragConfig.externe_nummern && antrag.a_nr_extern) {
      extern = ` (${ this.antrag.a_nr_extern })`;
    }
    const conf = antrag.aktuelle_aufgabe.au_kontext_config;
    this.anzeigekontexteAntrag = conf && conf.markiert && conf.markiert.antrag ?
      conf.markiert.antrag : {};
    this.anzeigekontexteAntragOffen = get(conf, "offen.antrag");
    this.antragLabel = `${ this.$filter("spaced")(this.antrag.a_nr) }${ extern }`;
    this.loadAstRegeln(antrag);
    this.loadGutachten(antrag);
    this.setEventAntragScore(antrag);
  }

  saveCallback({ statusSaveCallback } = {}) {
    if (statusSaveCallback === SaveCallbackActions.RELOAD_WF) {
      this.reloadWorkflowfolgen();
    } else if (statusSaveCallback === SaveCallbackActions.RELOAD_ALL) {
      this.reload_antrag();
    }
  }

  initialStepMap(antrag) {
    this.stepMap[this.UEBERSICHT].template = "uebersicht";
    if (!antrag.a_antragsdatum) {
      delete this.stepMap[this.UEBERSICHT];
    }
    forEach(this.stepMap, step => {
      step.saveCallback = this.saveCallback;
    });
    this.stepMap[this.FOERDERANGEBOT].template = "angebot";
    this.stepMap[this.ORGANISATION].template = "module";
    this.stepMap[this.KOSTEN_FINANZIERUNG].template = "kofi";
    this.stepMap[this.KOSTEN_FINANZIERUNG].setUp = () => {
      const setup = this.defaultSetUp(this.KOSTEN_FINANZIERUNG);
      if (setup) {
        setup.then(
          () => {
            // Kostenregeln aufsplitten, so dass die Regeln für den Kostenplan separat sind:
            const split_kopl = { kopl_rules: [], non_kopl_rules: [] };
            if (this.module[this.anzeigekontexte.kosten] !== undefined) {
              for (let i = 0; i < this.module[this.anzeigekontexte.kosten].length; i++) {
                const kosten_rule = this.module[this.anzeigekontexte.kosten][i];
                if (kosten_rule.modulgruppen.indexOf("kosten") > -1) {
                  split_kopl.kopl_rules.push(kosten_rule);
                } else {
                  split_kopl.non_kopl_rules.push(kosten_rule);
                }
              }
              this.module[this.anzeigekontexte.kosten] = split_kopl;
            }

            // Finanzierungsregeln aufsplitten, so dass die Regeln für den Finanzierungsplan separat sind:
            const split_fipl = { fipl_rules: [], non_fipl_rules: [] };
            if (this.module[this.anzeigekontexte.finanzierung] !== undefined) {
              for (let f = 0; f < this.module[this.anzeigekontexte.finanzierung].length; f++) {
                const finanzierung_rule = this.module[this.anzeigekontexte.finanzierung][f];
                if (finanzierung_rule.modulgruppen.indexOf("finanzierung") > -1) {
                  split_fipl.fipl_rules.push(finanzierung_rule);
                } else {
                  split_fipl.non_fipl_rules.push(finanzierung_rule);
                }
              }
              this.module[this.anzeigekontexte.finanzierung] = split_fipl;
            }
            this.update_zuschussinfo();
          }
        );
      }
    };

    this.stepMap[this.KOSTEN_FINANZIERUNG].updateHasBaseEditPerm = step => {
      step.data.hasBaseEditPerm = true;
      if (this.antrag === null) {
        return;
      }
      const permissions = this.AuthService.syncHasPerms(["foerderantrag.update",
                                                         "foerderantragkosten.update",
                                                         "foerderantragfinanzierung.update"], this.antrag.user_permissions);
      for (const key in permissions) {
        step.data.hasBaseEditPerm = step.data.hasBaseEditPerm && permissions[key];
      }
    };

    this.stepMap[this.KOSTEN_FINANZIERUNG].updateReadonly = step => {
      step.data.readonly = false;
      if (this.antrag === null) {
        return;
      }

      if (!this.antrag.eingereicht) {
        // Vor dem Einreichen muss auch auf den Regelsatz geachtet
        // werden. Nach dem Einreichen ist diese Prüfung egal.
        step.data.readonly = this.antrag.is_regelsatz_valid === false;
      }

      const permissions = this.AuthService.syncHasPerms(["foerderantrag.update",
                                                         "foerderantragkosten.update",
                                                         "foerderantragfinanzierung.update"], this.antrag.user_permissions);
      for (const key in permissions) {
        step.data.readonly = step.data.readonly || !permissions[key];
      }
    };

    this.stepMap[this.KOSTEN_FINANZIERUNG].updateSnapshot = step => {
      this.defaultUpdateSnapshot(step);
      if (step.snapshot && this.snapshot.asn_snapshot.kofi) {
        step.snapshot.asn_snapshot = angular.merge(step.snapshot.asn_snapshot, this.snapshot.asn_snapshot.kofi);
      }
    };
    this.stepMap[this.KOSTEN_FINANZIERUNG].updateSnapshotDiff = step => {
      this.defaultUpdateSnapshotDiff(step);
      if (step.snapshotDiff.diff_result.added && this.snapshotDiff.diff_result.added.kofi) {
        step.snapshotDiff.diff_result.added = angular.merge(
          step.snapshotDiff.diff_result.added,
          this.snapshotDiff.diff_result.added.kofi
        );
      }
      if (step.snapshotDiff.diff_result.modified && this.snapshotDiff.diff_result.modified.kofi) {
        step.snapshotDiff.diff_result.modified = angular.merge(
          step.snapshotDiff.diff_result.modified,
          this.snapshotDiff.diff_result.modified.kofi
        );
      }
      if (step.snapshotDiff.diff_result.removed && this.snapshotDiff.diff_result.removed.kofi) {
        step.snapshotDiff.diff_result.removed = angular.merge(
          step.snapshotDiff.diff_result.removed,
          this.snapshotDiff.diff_result.removed.kofi
        );
      }
    };
    this.stepMap[this.VORHABEN].template = "module";
    this.stepMap[this.ANLAGEN].template = "module";
    this.stepMap[this.BESCHEIDE].template = "module";
    this.stepMap[this.SACHBEARBEITER_DOKUMENTE].template = "module";
    this.stepMap[this.PROJEKTE].template = "module";
    this.stepMap[this.BUENDNISPARTNER].template = "module";
    this.stepMap[this.ERKLAERUNGEN].template = "module";
    this.stepMap[this.STELLUNGNAHME].template = "module";
    this.stepMap[this.AUFLAGEN].template = "module";
    this.stepMap[this.ANLAGEN].setUp = () => {
      const setup = this.defaultSetUp(this.ANLAGEN);
      if (setup) {
        setup.then(
          () => {
            this.setFoerderdokumenteModel();
          }
        );
      }
    };
    this.stepMap[this.ANLAGEN].updateSnapshot = step => {
      this.defaultUpdateSnapshot(step);
      if (step.snapshot && this.snapshot.asn_snapshot.anlagen) {
        step.snapshot.asn_snapshot = angular.merge(step.snapshot.asn_snapshot, this.snapshot.asn_snapshot.anlagen);
      }
    };
    this.stepMap[this.ANLAGEN].updateSnapshotDiff = step => {
      this.defaultUpdateSnapshotDiff(step);
      if (step.snapshotDiff.diff_result.added && this.snapshotDiff.diff_result.added.anlagen) {
        step.snapshotDiff.diff_result.added = angular.merge(
          step.snapshotDiff.diff_result.added,
          this.snapshotDiff.diff_result.added.anlagen
        );
      }
      if (step.snapshotDiff.diff_result.modified && this.snapshotDiff.diff_result.modified.anlagen) {
        step.snapshotDiff.diff_result.modified = angular.merge(
          step.snapshotDiff.diff_result.modified,
          this.snapshotDiff.diff_result.modified.anlagen
        );
      }
      if (step.snapshotDiff.diff_result.removed && this.snapshotDiff.diff_result.removed.anlagen) {
        step.snapshotDiff.diff_result.removed = angular.merge(
          step.snapshotDiff.diff_result.removed,
          this.snapshotDiff.diff_result.removed.anlagen
        );
      }
    };
    this.stepMap[this.SACHBEARBEITER_DOKUMENTE].setUp = () => {
      const setup = this.defaultSetUp(this.SACHBEARBEITER_DOKUMENTE);
      if (setup) {
        setup.then(
          () => {
            this.setFoerderdokumenteModel();
          }
        );
      }
    };
    this.stepMap[this.PRUEFUNG].template = "module";

    // notizen
    this.stepMap[this.VORHABEN].notizen = this.notiz;
    this.stepMap[this.KOSTEN_FINANZIERUNG].notizen = this.notiz;
    //
    this.stepMap[this.PRESSE].template = "module";
    this.stepMap[this.ERKLAERUNGEN_ZUM_EINREICHEN].template = "module";
    this.stepMap[this.KENNZAHLEN].template = "module";
    for (let i = 0; i < 31; i++) {
      this.stepMap["step_rubrik_" + i].template = "module";
    }
    this.stepMap.step_rubrik_4_1.template = "module";
    this.stepMap.step_rubrik_4_2.template = "module";
    for (let i = 1; i < 11; i++) {
      this.stepMap["step_rubrik_10_" + i].template = "module";
    }
  }

  changeView() {
    this.viewName = this.viewName === "details" ? "timeline" : "details";
  }

  filter(name) {
    if (this.statusHide[name]) {
      this.statusHide[name] = false;
    } else {
      this.statusHide[name] = true;
    }
  }

  assignAnsprechpartner() {
    return this.diasModalDialog({
      title: this.gettext("Ansprechpartner zuweisen"),
      template: ansprechpartnerZuweisenTemplate,
      controller: ansprechpartnerZuweisenController,
      extras: {
        // reloadPage: (response) => this.antrag.foerderorg_ansprechpartner = response.foerderorg_ansprechpartner,
        antrag_id: this.antrag.pk,
        antrag: this.antrag,
        ansprechpartner: this.antrag.foerderorg_ansprechpartner,
      },
    }).then(
      fsps => this.antrag.foerderorg_ansprechpartner = fsps
    );
  }

  showKofiUebersicht() {
    return this.diasModalDialog({
      title: this.gettext("Übersicht der Kosten & Finanzierung"),
      template: kofiUebersichtTemplate,
      showClose: true,
      controller: () => {},
      cls: "modal-xl",
      extras: {
        obj: this.antrag,
      }
    });
  }

  createNewAufgabe() {
    return this.diasModalDialog({
      title: "Neue Aufgabe erstellen",
      template: createManuelleAufgabeTemplate,
      controller: createManuelleAufgabeController,
      extras: {
        obj: this.antrag,
        reloadPage: this.onManuelleAufgabenChanged
      }
    });
  }

  createNewGutachten(typ) {
    return this.diasModalDialog({
      title: `${ typ.bez } ${ this.gettext("beauftragen") }`,
      template: createGutachtenTemplate,
      controller: createGutachtenController,
      extras: {
        obj: this.antrag,
        typ: typ
      }
    }).then(
      () => {
        this.$timeout(() => {
          this.loadGutachten(this.antrag);
        });
      }
    );
  }

  loadGutachten(antrag) {
    if (!antrag) {
      return;
    }
    const IS_GUTACHTEN_VISIBLE = this.AuthService.syncHasPerm("gutachten.view.overview", antrag.user_permissions);
    if (!IS_GUTACHTEN_VISIBLE) {
      return;
    }
    antrag.getList("gutachten").then(
      response => {
        antrag.gutachten = response;
        EventBus.$emit("updateObjGutachten");
      }
    );
  }

  // Löscht den aktuellen Antrag und leitet auf die Antragsübersicht
  deleteAntrag() {
    const a_nr = this.$filter("spaced")(this.antrag.a_nr, true);
    this.diasConfirmDialog({
      titleTemplate: `{{ context.gettext('Antrag') }} ${ a_nr } löschen`,
      contentTemplate: `<p>Sind Sie sicher, dass Sie {{ context.gettext("den Antrag") }} ${ a_nr } löschen wollen?</p>`,
      context: { antrag: this.antrag, gettext: this.gettext },
      okLabel: this.gettext("Antrag") + " " + a_nr + " löschen",
      okCallback: () => this.antrag.remove().then(
        () => {
          this.notification.success(this.gettext("Antrag") + " " + a_nr + " wurde gelöscht.");
          this.$state.go("root.foerderantrag");
        },
        err => this.notification.error(err, this.gettext("Antrag") + " konnte nicht gelöscht werden.")
      ),
    });
  }

  // Kopiert den aktuellen Antrag und leitet auf den neuen Antrag weiter
  copyAntrag() {
    const a_nr = this.$filter("spaced")(this.antrag.a_nr, true);
    this.diasConfirmDialog({
      titleTemplate: `{{ context.gettext('Antrag') }} ${ a_nr } kopieren`,
      contentTemplate: `<p>Sind Sie sicher, dass Sie {{ context.gettext("den Antrag") }} ${ a_nr } kopieren wollen?</p>`,
      context: { antrag: this.antrag, gettext: this.gettext },
      okLabel: this.gettext("Antrag") + " " + a_nr + " kopieren",
      okCallback: () => this.antrag.customPOST({}, "kopieren").then(
        response => {
          this.notification.success(this.gettext("Antrag") + " " + a_nr + " wurde kopiert.");
          this.$state.go("root.foerderantrag.details", { id: response.pk });
        },
        response => {
          this.notification.error("<p>" + this.gettext("Antrag") + " konnte nicht kopiert werden.</p>" + response.data.error);
        }
      ),
    });
  }

  changeActiveSnapshot({ snapshot } = {}) {
    this.$timeout(() => {
      if (snapshot) {
        this.snapshotActivePk = snapshot.pk;
        this.snapshot = cloneDeep(snapshot);
        for (let i = 0; i < this.steps.length; i++) {
          this.steps[i].updateSnapshot(this.steps[i]);
        }
        if (this.snapshotDiff) {
          delete this.snapshotDiff;
        }
        this.showDiffSnapshot(this.snapshotActivePk);
      } else {
        if (this.snapshot) {
          delete this.snapshot;
        }
        if (this.snapshotDiff) {
          delete this.snapshotDiff;
        }
        forEach(this.steps, value => {
          value.snapshot = null;
          value.snapshotDiff = null;
        });
        this.snapshotActivePk = undefined;
      }
    });
  }

  showDiffSnapshot(pk) {
    this.loadingDiff = pk;
    this.antrag.snapshots.one(pk).one("diff").get().then(
      result => {
        this.snapshotDiff = result;
        for (let i = 0; i < this.steps.length; i++) {
          this.steps[i].updateSnapshotDiff(this.steps[i]);
        }
      },
      () => {
        this.DiasNotification.page.error("Beim Abrufen der Vergleichsdaten ist ein Fehler aufgetreten");
      }
    ).finally(() => this.loadingDiff = undefined);
  }

  showSnapshots() {
    EventBus.$emit("updateSnapshots");
  }

  showSnapshotIcons(step) {
    if (step.snapshotDiff && step.snapshotDiff.diff_result) {
      if (step.key === this.KOSTEN_FINANZIERUNG || step.key === this.ANLAGEN) {
        return (step.snapshotDiff && (
          Object.keys(step.snapshotDiff.diff_result.modified).length > 0 ||
                  Object.keys(step.snapshotDiff.diff_result.added).length > 0 ||
                  Object.keys(step.snapshotDiff.diff_result.removed).length > 0));
      }
      return Object.keys(step.snapshotDiff.diff_result.modified).length > 0;
    }

    return false;
  }

  createSnapshot() {
    EventBus.$emit("createSnapshot");
  }

  isDiffKf(kfName) {
    const step = this.stepMap[this.KOSTEN_FINANZIERUNG];
    if (step.snapshotDiff) {
      const snapDiffMod = step.snapshotDiff.diff_result.modified;
      const snapDiffAdd = step.snapshotDiff.diff_result.added;
      const snapDiffRemove = step.snapshotDiff.diff_result.removed;
      if (kfName === "kosten") {
        if (snapDiffMod.kostenarten || snapDiffAdd.kostenarten || snapDiffRemove.kostenarten) {
          return true;
        }
      } else if (kfName === "finanzierung") {
        if (snapDiffMod.finanzierungsarten || snapDiffAdd.finanzierungsarten || snapDiffRemove.finanzierungsarten) {
          return true;
        }
      } else if (kfName === "zuschuesse") {
        if (snapDiffMod.finanzierungsarten || snapDiffMod.kostenarten || snapDiffAdd.finanzierungsarten || snapDiffAdd.kostenarten || snapDiffRemove.finanzierungsarten || snapDiffRemove.kostenarten) {
          return true;
        }
      }
    }
    return false;
  }

  scrollTop() {
    $(window).scrollTop(0);
  }

  showAktuelleAufgabeDetail() {
    this.aktuelleAufgabeDetailStatus = !this.aktuelleAufgabeDetailStatus;
  }

  setFoerderdokumenteModel() {
    this.foerderdokumenteModel = [];
    angular.forEach(this.module[this.anzeigekontexte.foerderangebot], value => {
      if (value.data && value.data.dokument) {
        this.foerderdokumenteModel.push(value);
      }
    });
  }

  goToModul(kontext, bottomModul, $event) {
    $event.preventDefault();
    $event.stopPropagation();
    let step;
    let idx;
    if (_.get(kontext, "pk")) {
      step = this.getStepFromKontextUUID(kontext.pk);
      idx = step ? step[0] : undefined;
    } else if (_.isString(kontext)) {
      step = this.stepMap[kontext];
      idx = step ? this.steps.indexOf(step) : undefined;
    }
    let wasOpen = undefined;
    let promise = undefined;
    if (!_.isNil(idx)) {
      wasOpen = this.steps[idx].open;
      promise = this.openStep(idx, true);
    }
    const topId = +idx + 1;
    const target = bottomModul ? bottomModul.regelnummer : `modul${ topId }`;
    if (bottomModul !== undefined) {
      if (this.ast_regel_list.indexOf(
        bottomModul.kbez
      ) >= 0
      ) {
        const url = this.$state.href("root.astorganisationen.details", { id: this.antrag.antragstellerorg });
        this.window.open(url + "?asr_nummer=" + bottomModul.regelnummer, "_blank");
      }
    }
    if (promise !== undefined) {
      this.$anchorScroll(`modul${ topId }`);
      promise.then(
        () => {
          if (bottomModul && this.steps[idx].loaded && this.steps[idx].open) {
            this.$scope.$applyAsync(() => {
              this.$anchorScroll(target);
            });
          }
        }
      );
    } else if (!wasOpen) {
      this.$timeout(() => {
        this.$scope.$applyAsync(() => {
          this.$anchorScroll(target);
        });
      }, 100);
    } else {
      this.$anchorScroll(target);
    }
  }

  checkStatus(workflowfolgen) {
    this.checkGegenpruefenStatus(workflowfolgen);
    this.checkEinreichenStatus(workflowfolgen);
  }

  checkGegenpruefenStatus(workflowfolgen) {
    this.gegenpruefenInaktive = false;
    for (let i = 0; i < workflowfolgen.length; i++) {
      if (workflowfolgen[i].status === 1 && GEGENPRUEFEN_IDS.indexOf(workflowfolgen[i].pk) !== -1) {
        this.gegenpruefenInaktive = true;
        break;
      }
    }
  }

  checkEinreichenStatus(workflowfolgen) {
    this.einreichenInaktive = false;
    for (let i = 0; i < workflowfolgen.length; i++) {
      if (workflowfolgen[i].pk === EINREICHEN_PK && workflowfolgen[i].status !== 0) {
        this.einreichenInaktive = true;
        break;
      }
    }
  }

  toggleFavorit() {
    this.FavoritenUtils.toggleFavorit(this.antrag);
  }

  reloadAntragKofi() {
    this.reloadKofi = true;
    this.reload_antrag().finally(() => this.reloadKofi = false);
  }

  reload_antrag(setEinreichenStatus, reloadModules) {
    if (this.reload) {
      return;
    }
    this.reload = true;
    return this.Foerderantrag.one(this.$stateParams.id).get().then(
      result => {
        if (setEinreichenStatus && !this.antrag.a_antragsdatum && result.a_antragsdatum) {
          this.einreichenStatus = true;
        }
        this.setAntrag(result);
        this.showSnapshots();
        this.reloadWorkflowfolgen();
        this.$rootScope.$broadcast("notizen__optionen__changed");
        if (reloadModules) {
          this.module = {};
          _.forEach(this.stepMap, val => {
            val.open = false;
            if (val.loaded === true) {
              val.loaded = false;
            }
          });
        }
        this.lastAntragReload = new Date().toISOString();
      }
    ).finally(() => this.reload = false);
  }

  openAufgabenKontextPanel() {
    if (isNil(this.anzeigekontexteAntragOffen)) {
      return;
    }
    let scrolled = false;
    forEach(this.steps, (step, idx) => {
      if (this.anzeigekontexteAntragOffen[step.key]) {
        if (!scrolled) {
          this.$timeout(() => $(`#modul${ idx + 1 } > header`).click(), 2000);
          scrolled = true;
        } else {
          this.openStep(idx);
        }
      }
    });
    return scrolled;
  }

  update_zuschussinfo() {
    // Die Zuschussinformationen müssen und dürfen nur ermittelt werden,
    // wenn der Antrag noch nicht entschieden ist:
    const HAS_PERIODEN = !!get(this.antrag, "regelsatz_obj.perioden");
    if (this.antrag && (!this.antrag.isEntschieden() || (HAS_PERIODEN && !this.antrag.a_zuschussbeantragt_data))) {
      this.Foerderantrag.one(this.$stateParams.id).customGET("zuschuss").then(
        result => {
          this.zuschussinfo = result;
        }
      );
    } else if (this.antrag && HAS_PERIODEN) {
      this.zuschussinfo = this.antrag.a_zuschussbeantragt_data;
    }
  }

  loadfoerderdokumente() {
    this.Foerderantrag.one(this.$stateParams.id).customGET("foerderdokumente").then(
      result => this.foerderdokumente = result.results
    );
  }

  getUser() {
    this.AuthService.getUser().then(
      user => this.user = user
    );
  }

  onValidate() {
    this.validationErrorsLoading = true;
    this.antrag.customGET("validieren").then(
      result => {
        this.validationErrors = result;
      },
      () => this.DiasNotification.page.error("Fehler beim Prüfen des Antrags")
    ).finally(() => {
      this.validationErrorsLoading = false;
      this.scrollTop();
    });
  }

  resetValidation() {
    this.validationErrorsLoading = undefined;
    this.validationErrors = undefined;
  }

  onSubmit() {
    this.diasConfirmDialog({
      title: "Wollen Sie den Antrag einreichen",
      content: "Sind Sie sicher, dass Sie den Antrag " + this.$filter("spaced")(this.antrag.a_nr, true) + " einreichen wollen?",
      okLabel: "Einreichen",
      okCallback: () => {
        this.submitLoading = true;
        return this.antrag.submit().then(
          aufgabe => {
            this.updateAktuelleAufgabe(aufgabe);
            this.antrag.eingereicht = true;
            // reload snapshots:
            this.showSnapshots();
          },
          validationErrors => this.validationErrors = validationErrors
        ).finally(() => this.submitLoading = false);
      }
    });
  }

  getWorkflowfolgeUpdateKey() {
    if (this.antrag) {
      return `${ this.antrag.antragstellerorg }__${ this.lastWfExecution }`;
    }
  }

  // Workflow callbacks
  onWorkflowInit(clientFunktion) {
    this.$timeout(() => {
      this.clientFunktion = clientFunktion;
    });
  }

  onWorkflowStarted(aufgabe, currentWorkflowfolge) {
    this.$timeout(() => {
      Object.assign(this.antrag.aktuelle_aufgabe, aufgabe);
      this.oldAufgabePk = this.antrag.aktuelle_aufgabe.pk;
      this.validationErrorsLoading = true;
      this.currentWorkflowfolge = currentWorkflowfolge;
    });
  }

  onWorkflowFinish() {
    this.$timeout(() => {
      this.clientFunktion = undefined;
      this.validationErrorsLoading = false;
      this.oldAufgabePk = undefined;
      this.currentWorkflowfolge = undefined;
    });
  }

  onWorkflowCanceled() {
    this.$timeout(() => {
      this.clientFunktion = undefined;
      this.validationErrorsLoading = false;
    });
  }

  onWorkflowSuccess(aufgabe) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerSuccess(aufgabe);
      }
      if (aufgabe.pk === this.oldAufgabePk) {
        this.reloadWorkflowfolgen();
      }
      this.validationErrors = undefined;
      if (get(this.currentWorkflowfolge, "wfo_reload")) {
        return this.reload_antrag(true, true).finally(() => {
          if (!this.openAufgabenKontextPanel()) {
            this.scrollTop();
          }
        });
      }
      if (!this.openAufgabenKontextPanel()) {
        this.scrollTop();
      }
    });
  }

  onWorkflowFailure(err) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerError(err);
      }
      if (err.data && err.data.error_data) {
        this.validationErrors = err.data.error_data;
      } else if (err.data && !isString(err.data)) {
        // Strings werden hier nicht ausgegeben, dann auch keine Liste von Strings ausgeben
        let isListOfStrings = false;
        if (isArray(err.data)) {
          isListOfStrings = reduce(err.data, (result, v) => {
            return result && isString(v);
          }, true);
        }
        if (!isListOfStrings) {
          this.validationErrors = err.data;
        } else {
          this.validationErrors = undefined;
        }
      } else {
        this.validationErrors = undefined;
      }
      this.scrollTop();
      return this.reload_antrag(true);
    });
  }

  onWorkflowfolgenUpdated(folgen) {
    this.$timeout(() => {
      this.checkStatus(folgen);
    });
  }

  onAufgabeAngenommen() {
    this.$timeout(() => {
      this.reload_antrag();
    });
  }

  onManuelleAufgabenChanged() {
    this.$timeout(() => {
      if (!this.antrag || !this.antrag.getList) {
        return;
      }
      this.antrag.getList("manuelle_aufgaben").then(
        result => this.manuelleAufgaben = result
      );
    });
  }

  savePdf() {
    this.Foerderantrag.one(this.$stateParams.id).withHttpConfig({ responseType: "blob" }).customGET("pdf").then(
      response => {
        const fileName = "antrag_" + this.antrag.a_nr + "_entwurf.pdf";
        if (this.window.navigator.msSaveBlob) {
          this.window.navigator.msSaveBlob(response, fileName);
        } else {
          const fileURL = (this.window.URL || this.window.webkitURL).createObjectURL(response);
          const aLink = this.window.document.createElement("a");
          aLink.download = fileName;
          aLink.href = fileURL;
          aLink.target = "_self";
          const eventClick = this.window.document.createEvent("MouseEvents");
          eventClick.initEvent(
            "click",
            true,
            true,
            this.window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
          );
          aLink.dispatchEvent(eventClick);
        }
      },
      () => this.DiasNotification.page.error("Fehler beim Herunterladen des Dokuments")
    );
  }

  showRules() {
    this.statusRules = !this.statusRules;
  }

  subsequentFilingPossible() {
    return this.antrag.eingereicht && this.stepMap[this.ANLAGEN].visible;
  }


  initData() {
    this.viewName = "details";
    this.reload = false;
    this.statusHide = {};

    this.docAnzeige = "2f413911-a316-c179-e050-007f0101705e";
    this.snapshotActivePk = undefined;
    this.loadingDiff = undefined;

    this.foerderdokumenteModel = [];

    this.currentSnapshotStatus = {};

    this.steps.currentStep = 0;
    this.zuschussinfo = {};

    const kofiConf = this.SyConfigs.wert("kofi_config") || {};
    this.kofiUebersichtAnzeigen = kofiConf.uebersicht_anbieten;
    this.kofiNummernAusblenden = kofiConf.nummern_ausblenden;
    this.showAnsprechpartnerModalEdit = false;
    this.Katalog.getKatalog("kgutachtentypen").then(
      response => this.gutachtenTypen = response
    );

    this.initNotizenData();
    this.initWizardData();
    this.initTimelineData();
  }

  initNotizenData() {
    this.notizOptions = {
      fuerSitzung: true,
      orgChoices: [],
      noOrgText: "Um Notizen zum Antrag erstellen zu können, wählen Sie bitte zunächst eine " + this.gettext("Antragstellerorganisation") + " für diesen Antrag aus."
    };

    this.notizOptionsVue = {
      noOrgText: "_TXT_NOTIZEN_FOERDERANTRAG_NO_ORG_",
    };
  }

  initWizardData() {
    this.wizardController = RSWechselWizardController;

    this.wizardData = [
      {
        icon: "glyphicon-foerderung",
        label: this.gettext("Neues Förderangebot wählen"),
        template: "RSWechselWizardStepAuswahl.html",
        tooltip: this.gettext("Auswählen eines neuen Förderangebots für den Antrag")
      },
      {
        icon: "glyphicon-foerderung",
        label: "Wechsel bestätigen",
        template: "RSWechselWizardStepBestaetigen.html",
        tooltip: this.gettext("Den Wechsel zum gewählten Förderangebot bestätigen")
      },
      {
        icon: "glyphicon-foerderung",
        label: "Ergebnis",
        template: "RSWechselWizardStepErgebnis.html",
        tooltip: this.gettext("Ergebnis des Förderangebotswechsels anzeigen")
      }
    ];
  }

  initTimelineData() {
    // Timeline
    this.timelineConfig = {
      kontexte: [
        {
          titel: "Antragstellung",
          key: "A",
        },
        {
          titel: "Antragsprüfung",
          key: "F",
        },
      ],
      benachrichtigungen: {
        bezug: "antrag",
        bezugpk: this.$stateParams.id,
        autorefresh: true,
      },
      url: `foerderantraege/${ this.$stateParams.id }/timeline/`,
    };
  }

  closeWizard() {
    this.$state.go("root.foerderantrag.details", { id: this.antragPk }, { reload: "root.foerderantrag.details" });
  }

  closeWizardOrganisation(organisation) {
    this.$timeout(() => {
      this.statusAntragstellerWizard = false;
      EventBus.$emit("closeWizardOrganisation", { organisation });
    });
  }

  closePersonalKostenPerioden() {
    this.$timeout(() => {
      this.statusShowPersonalkostenPerioden = false;
      this.setFocusToElement();
    });
  }

  setFocusToElement() {
    this.$timeout(() => {
      const ELEMENT = $(`#${ this.htmlIdForClose }`);
      if (ELEMENT) {
        ELEMENT.focus();
      }
      this.htmlIdForClose = undefined;
    });
  }

  openPersonalKostenPerioden({ positionen, perioden, personalfunktionen, htmlIdForClose }) {
    this.$timeout(() => {
      this.personalkostenPerioden = {
        positionen,
        perioden,
      };
      this.personalfunktionen = personalfunktionen;
      this.htmlIdForClose = htmlIdForClose;
      this.statusShowPersonalkostenPerioden = true;
    });
  }

  setEventTooglePersonalkostenPerioden() {
    this.personalkostenPerioden = {
      positionen: [],
      perioden: [],
    };
    this.statusShowPersonalkostenPerioden = false;
    EventBus.$on("tooglePersonalkostenPerioden", this.openPersonalKostenPerioden);
    this.$scope.$on("$destroy", () => {
      EventBus.$off("tooglePersonalkostenPerioden");
    });
  }

  setEventTooglePersonalkostenPeriodenWizard() {
    this.statusShowPersonalkostenPeriodenWizard = false;
    EventBus.$on("openPersonalkostenPeriodenWizard", this.openPersonalKostenPeriodenWizard);
    this.$scope.$on("$destroy", () => {
      EventBus.$off("openPersonalkostenPeriodenWizard");
    });
  }

  openPersonalKostenPeriodenWizard(arg) {
    this.$timeout(() => {
      this.personalkostenPeriodenWizard = arg;
      this.htmlIdForClose = arg.htmlIdForClose;
      this.statusShowPersonalkostenPeriodenWizard = true;
    });
  }

  closePersonalKostenPeriodenWizard({ statusReload/* , regel_nummer */, response } = {}) {
    this.$timeout(() => {
      this.statusShowPersonalkostenPeriodenWizard = false;
      this.personalkostenPeriodenWizard = undefined;
      EventBus.$emit("closePersonalkostenPeriodenWizard", {
        statusReload,
        response,
        htmlIdForClose: this.htmlIdForClose,
      });
      this.$timeout(() => {
        // const REGEL_EL = document.getElementById(regel_nummer) || {};
        // $(window).scrollTop(+REGEL_EL.offsetTop || 0);
        this.htmlIdForClose = undefined;
      });
    });
  }

  // Neu laden beim Aktualisieren des Antrag-Scores
  setEventAntragScore(antrag) {
    if (antrag && !this.eventsAntragScoreInitialized) {
      this.eventsAntragScoreInitialized = true;
      antrag.getList("module", {
        objekttyp: "antrag",
        modulname: "antrag_score",
      }).then(
        response => {
          response.forEach(module => {
            module.regel.signal_empfang.forEach(signal => {
              const eventName = `${ signal.signal_kennung }_${ signal.sender_reid }`;
              EventBus.$on(eventName, this.reload_antrag);
              this.$scope.$on("$destroy", () => {
                EventBus.$off(eventName, this.reload_antrag);
              });
            });
          });
        }
      );
    }
  }

  setEventUpdateAntragsteller() {
    EventBus.$on("antragsteller.update", this.reload_antrag);
    this.$scope.$on("$destroy", () => {
      EventBus.$off("antragsteller.update", this.reload_antrag);
    });
  }

  updateModuleFromVue({ group, response }) {
    this.$timeout(() => {
      if (group) {
        const INDEX = findIndex(this.module[group], ["regel.pk", response.regel.pk]);
        if (INDEX !== -1) {
          this.module[group].splice(INDEX, 1, response);
        }
      }
    });
  }

  updateModuleFromVueKoFi({ response }) {
    this.$timeout(() => {
      const KOFI_ANZEIGEKONTEXTE = [
        this.anzeigekontexte.finanzierung,
        this.anzeigekontexte.kosten,
      ];

      KOFI_ANZEIGEKONTEXTE.forEach(
        group => {
          if (group) {
            forEach(this.module[group], (value, key) => {
              const INDEX = findIndex(value, ["regel.pk", response.regel.pk]);
              if (INDEX !== -1) {
                this.module[group][key].splice(INDEX, 1, response);
              }
            });
          }
        }
      );
    });
  }

  updateinfoprovider({ response }) {
    this.$timeout(() => {
      Object.assign(this.zuschussinfo, response);
    });
  }

  getAntragsnummerFiltered() {
    return this.$filter("spaced")(this.antrag.a_nr, true);
  }

  onCallbackFavoritenAndSichtbarkeit({ data }) {
    Object.assign(this.antrag, data);
  }

  updateAufgabeFromVue(newAufgabe) {
    this.$timeout(() => {
      this.updateAufgabe(newAufgabe);
    });
  }

  updateAufgabe(newAufgabe) {
    Object.assign(this.antrag.aktuelle_aufgabe, newAufgabe);
  }
}

export default {
  template: template(),
  controller: FoerderantragAddEditAntragController,
  controllerAs: "vm"
};
