"use strict";

import template from "./teamrouting.detail.jade";

class TeamroutingController {
  /*@ngInject*/
  constructor($q, AuthService, Teamroutings, DiasNotification, Teamroutingzustaendige, ParameterUtils) {
    this.loading = true;
    this.DiasNotification = DiasNotification;
    this.Teamroutings = Teamroutings;
    this.Teamroutingzustaendige = Teamroutingzustaendige;
    this.ParameterUtils = ParameterUtils;
    this.AuthService = AuthService;
    this.model = {
      pk: this.routingEl.pk,
      rou_pos: this.routingEl.rou_pos,
    };
    this.editMode = this.editMode || !this.routingEl.pk;

    this.canChangeKonfig = this.AuthService.syncHasPerm("teamrouting.konfig.update");
    this.canChangeKriterium = this.canChangeKonfig && !this.routingEl.pk;
    this.canChangeTeamZuordnung = this.AuthService.syncHasPerm("routing_zustaendige.team");
    this.canChangeMitgliedZuordnung = this.AuthService.syncHasPerm("routing_zustaendige.teammitglied");

    $q.all([
      this.loadZustaendige(),
      this.loadKriterien()
    ]).finally(() => this.loading = false);

    this.zustaendigeTeam = null;
    this.zustaendigeMitglied = [];


    this.zustaendigIcons = {
      Teammitglied: "glyphicon-user",
      Team: "glyphicon-partner"
    };
  }

  mouseenterleave(entered) {
    if (!this.editMode) {
      this.hovering = entered;
    }
  }

  formstepClick() {
    if (!this.editMode) {
      this.setEditMode(true);
    }
  }

  loadKriterien() {
    return this.Teamroutings.get_allowed_kriterien(this.parentPk).then(
      response => {
        this.kriterien = response;
        this.initForm();
      }
    );
  }

  loadZustaendige() {
    return this.Teamroutingzustaendige.getList().then(
      response => {
        if (this.routingEl && this.routingEl.zustaendig && this.routingEl.zustaendig.length > 0) {
          this.routingEl.zustaendig.forEach(v => {
            if (response.findIndex(z => z.pk === v.pk) === -1) {
              response.push(v);
            }
            if (v.typ === "Team") {
              this.zustaendigeTeam = v.pk;
            } else {
              this.zustaendigeMitglied.push(v.pk);
            }
          });
        }
        if (this.canChangeTeamZuordnung) {
          this.zustaendigChoicesTeam = response.filter(v => v.typ === "Team");
        } else {
          this.zustaendigChoicesTeam = this.routingEl.zustaendig.filter(v => v.typ === "Team");
        }
        if (this.canChangeMitgliedZuordnung) {
          this.zustaendigChoicesMitglieder = response.filter(v => v.typ === "Teammitglied");
        } else {
          this.zustaendigChoicesMitglieder = this.routingEl.zustaendig.filter(v => v.typ === "Teammitglied");
        }
      }
    );
  }

  initForm() {
    if (this.routingEl.rou_kriterium) {
      this.model.rou_kriterium = this.routingEl.rou_kriterium;
    } else if (this.kriterien.length === 1) {
      this.model.rou_kriterium = this.kriterien[0].modulname;
    }
    this.selectForm();
  }

  selectForm() {
    if (!this.model.rou_kriterium) {
      this.formKeys = [];
      this.formModel = {};
      return;
    }
    this.selectedKriterium = this.kriterien.find(v => v.modulname === this.model.rou_kriterium);
    if (!this.selectedKriterium) {
      return;
    }
    this.formKeys = this.ParameterUtils.get_ordered_keys(this.selectedKriterium.form);
    this.formKeys = this.formKeys.map(v => {
      return { key: v, randomIdx: Math.random() };
    });
    this.formModel = this.ParameterUtils.init_parameter_in(this.selectedKriterium.form);
    if (this.routingEl.pk) {
      this.formKeys.forEach(v => {
        this.formModel[v.key].wert = this.routingEl.rou_data[v.key];
      });
    }
  }

  setEditMode(value) {
    this.editMode = value;
    if (this.editMode) {
      this.hovering = false;
    }
    if (!this.editMode && this.onCanceled) {
      this.onCanceled();
    }
  }

  save() {
    if (!this.selectedKriterium) {
      return;
    }
    this.model.rou_data = this.ParameterUtils.to_request_body(this.formModel, undefined, "");
    this.model.zustaendig_team = this.zustaendigeTeam;
    this.model.zustaendig_mitglieder = [...this.zustaendigeMitglied];
    this.saving = true;
    let promise;
    if (this.routingEl.pk) {
      this.model.parent = this.routingEl.parent;
      promise = this.Teamroutings.one(this.routingEl.pk).customPUT(this.model).then(
        response => {
          this.errors = undefined;
          this.DiasNotification.page.success("Änderungen gespeichert.");
          this.editMode = false;
          if (this.onSaved) {
            this.onSaved()(this.routingEl, response.plain());
          }
        },
        err => {
          this.DiasNotification.page.error("Fehler beim Speichern der Änderungen");
          this.onError(err);
        }
      );
    } else {
      this.model.parent = this.parentPk;
      promise = this.Teamroutings.post(this.model).then(
        response => {
          this.errors = undefined;
          this.DiasNotification.page.success("Neue Steuerungsinformationen erstellt.");
          if (this.onSaved) {
            this.onSaved()(null, response.plain());
          }
        },
        err => {
          this.DiasNotification.page.error("Fehler beim Erstellen der Steuerungsinformationen");
          this.onError(err);
        }
      );
    }
    promise.finally(() => this.saving = false);
  }

  onError(error) {
    const errorsLabel = {
      rou_pos: "Position: ",
      rou_kriterium: "Kriterium: ",
      zustaendig_team: "Zuständigkeit: ",
      zustaendig_mitglieder: "Zuständigkeit: ",
    };
    this.formKeys.forEach(v => errorsLabel[v.key] = this.formModel[v.key].label);
    this.DiasNotification.form.clear();
    this.DiasNotification.form.error(
      error.data,
      "Bitte überprüfen Sie Ihre Eingaben", 
      {}, 
      errorsLabel
    );
    this.errors = error.data;
  }
}

export default {
  template: template(),
  controller: TeamroutingController,
  controllerAs: "vm",
  bindings: {
    routingEl: "=",
    parentPk: "<",
    editMode: "=?",
    onCanceled: "&?",
    onSaved: "&?"
  }
};
