// Modul zur Erfassung von Honorar-Kosten
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "dias/kosten_finanzierung/kosten.template.jade";
import FoerderantragModels from "dias/foerderantrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const honorar_kosten = angular.module("dias.honorar_kosten", [
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
]);

honorar_kosten.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("honorar_kosten", "modul-honorar-kosten");
})

  .directive("modulHonorarKosten", builder.directive(template, controller));

export default honorar_kosten;
