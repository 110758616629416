"use strict";

import BaseController from "../config.base.controller";

class ParametermoduleController extends BaseController {
  /*@ngInject*/
  constructor() {
    super();
  }

  onChange() {
    // Leere Strings in Null umformen
    if (this.parameter[this.key].wert === "") {
      this.parameter[this.key].wert = null;
    }
  }
}

export default ParametermoduleController;
