"use strict";

export default {
  antrag: "f0705168-3bf8-46cb-9110-be6142875a88",
  vorhaben: "eae0602e-1dc9-4693-9881-a5048edae232",
  vertrag: "54cfc62b-60ee-4170-b78c-03c7e718e96b",
  sitzung: "656cc954-8855-4225-a952-f4b86bcabf99",
  auszahlung: "b2c69c92-ec79-4bad-a272-1be33aeb9ee6",
  endabrechnung: "2948f684-d9c4-4e28-b91b-b10e8b19c523",
  antragsteller: "162cafcb-0a2e-42bc-80d1-dfbd4d2f9c78",
  sammelvertrag: "f4a8cbf9-dfb0-43e4-bbd0-ce7a56e15baf",
  projektbericht: "07ceb875-81ed-423d-b1e7-9c890013760d",
};
