"use strict";

import BaseModel from "dias/core/models/baseModel";
import Dokumente from "dias/dokumente/models/dokumente.service";
import Objektaufgabe from "dias/workflow/models/objektaufgabe.service";


class Sammelvertrag extends BaseModel {
  constructor() {
    super("sammelvertraege", {
      subPaths: {
        aufgaben: new Objektaufgabe(),
        antraege: "antraege",
        dokumente: new Dokumente(),
      }
    });
  }

  extendModel(model) {
    model = super.extendModel(model);

    if (!angular.isObject(model)) {
      return model;
    }

    model.restangularizeAufgabe = aufgabe => this.Restangular.restangularizeElement(model, aufgabe, "aufgaben", {});

    if (model.aktuelle_aufgabe) {
      model.aktuelle_aufgabe = model.restangularizeAufgabe(model.aktuelle_aufgabe);
    }

    return model;
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.getKAufgaben = () => collection.customGET("kaufgaben");
    return collection;
  }
}

export default Sammelvertrag;
