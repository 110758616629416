import SmartTable from "../../../global/components/table/table.vue";

import { FilterDateMixin } from "../../../global/mixins/FiltersMixin";

import getTableApi from "../../../const/TableApis";

// @vue/component
export default {
  name: "VertretungenList",
  components: {
    SmartTable,
  },
  mixins: [
    FilterDateMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
  },
  computed: {
    options() {
      return {
        id: this.tableId,
        url: getTableApi({ id: this.tableId, params: { nutzerPk: this.nutzer.pk } }),
        cols: [
          {
            label: "Abwesend von",
            id: "na_gueltig_ab",
            filter: "date",
            titleCallback: ({ item }) => this.filterDate(item.na_gueltig_ab, "DD.MM.YY HH:mm [Uhr]")
          },
          {
            label: "Abwesend bis",
            id: "na_gueltig_bis",
            filter: "date",
            titleCallback: ({ item }) => this.filterDate(item.na_gueltig_bis, "DD.MM.YY HH:mm [Uhr]")
          },
          {
            label: "Vertretung für",
            id: "nutzer_name",
          },
        ],
        label: "Vertretungen",
        sortable: false,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
        csvHide: true,
      };
    },
  },
};
