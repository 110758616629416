"use strict";

import angular from "angular";
import VersionCheckDirective from "./versioncheck.directive";

const diasVersionCheck = angular.module("dias.versioncheck", [])

  .component("versioncheck", VersionCheckDirective);

export default diasVersionCheck;
