import translate from "../../../directives/translate";

// @vue/component
export default {
  name: "ShowMore",
  directives: {
    translate,
  },
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    maxHeight: {
      type: Number,
      required: false,
      default: undefined,
      info: "Maximale Höhe eines zusammengeklappten Blocks"
    },
    align: {
      type: String,
      required: false,
      default: "center",
      validator: value => ["center", "left", "right"].indexOf(value) !== -1,
    },
  },
  data() {
    return {
      status: {
        more: undefined,
        showMoreButton: undefined,
      },
      mutationObserver: undefined,
      intersectionObserver: undefined,
    };
  },
  computed: {
    getButtonStyle() {
      return {
        top: this.status.more ? "auto" : `${ this.maxHeightLocal }px`,
        bottom: this.status.more ? "0" : "auto",
      };
    },

    getButtonClass() {
      return [{
        show_more__button_more: !this.status.more
      }, this.getButtonAlignClass];
    },

    getButtonAlignClass() {
      return `show_more__button_${ this.align }`;
    },

    getMaxHeight() {
      return {
        maxHeight: this.status.more ? "none" : `${ this.maxHeightLocal }px`
      };
    },

    getMaxHeightContainer() {
      const buttonHeight = this.status.showMoreButton ? 20 : 0;
      return {
        maxHeight: this.status.more ? "none" : `${ this.maxHeightLocal - buttonHeight }px`,
      };
    },

    getButtonText() {
      return this.status.more ?
        "_BTN_SHOW_MORE_WENIGER_" :
        "_BTN_SHOW_MORE_MEHR_";
    },

    maxHeightLocal() {
      return this.maxHeight || this.options.maxHeight || 200;
    },
  },
  mounted() {
    this.checkHeight();
    this.startObservingMutation();
  },
  beforeUnmount() {
    this.stopObservingMutation();
    this.stopObservingVisiblity();
  },
  methods: {
    toggleMore() {
      this.status.more = !this.status.more;
    },

    checkHeight() {
      if (this.$refs.show_more.clientHeight > this.maxHeightLocal) {
        this.status.showMoreButton = true;
        this.stopObservingVisiblity();
      } else if (this.$refs.show_more.clientHeight === 0) {
        this.startObservingVisiblity();
      }
    },

    startObservingMutation() {
      this.mutationObserver = new MutationObserver(() => {
        this.checkHeight();
      });
      this.mutationObserver.observe(
        this.$refs.show_more,
        { subtree: true, characterData: true, childList: true }
      );
    },

    stopObservingMutation() {
      if (this.mutationObserver) {
        this.mutationObserver.disconnect();
      }
    },

    startObservingVisiblity() {
      if (this.mutationObserver || this.intersectionObserver) {
        return;
      }
      this.intersectionObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.checkHeight();
          }
        });
      });
      this.intersectionObserver.observe(this.$refs.show_more);
    },

    stopObservingVisiblity() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect();
      }
    },
  },
};
