import FormstepDetail from "../../../global/components/FormstepDetail/FormstepDetail.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import ModalCreateOrUpdate from "./ModalCreateOrUpdate/ModalCreateOrUpdate.vue";
import ShowMore from "../../../global/components/ui/ShowMore/ShowMore.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../global/compositionAPI/NotificationAPI";
import PermissionAPI from "../../../global/compositionAPI/PermissionAPI";

import getTableApi from "../../../const/TableApis";
import {
  EventBus,
} from "../../../global/functions/event-bus";
import {
  isNil,
} from "lodash-es";

const TABLE_ID = "workflowfristInKaufgabe";

// @vue/component
export default {
  name: "WorkflowfristListInKAufgabe",
  components: {
    FormstepDetail,
    Modal,
    ModalCreateOrUpdate,
    ShowMore,
    SmartTable,
  },
  props: {
    aufgabePk: {
      type: String,
      default: undefined,
    },
  },
  setup() {
    const {
      deleteHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      addNotification,
      checkPermissionsSync,
      deleteHttp,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: undefined,
        cols: [
          {
            id: "workflow_bez",
            label: "_LBL_WORKFLOWFRIST_WORKFLOW_",
            sortable: true,
            sortId: "workflow__wf_kbez",
            hide: true,
          }, {
            id: "wfr_mahnstufe",
            label: "_LBL_WORKFLOWFRIST_WFR_MAHNSTUFE_",
            sortable: true,
          }, {
            id: "wfr_frist",
            label: "_LBL_WORKFLOWFRIST_WFR_FRIST_",
            sortable: false,
          }, {
            id: "terminberechnung_bez",
            label: "_LBL_WORKFLOWFRIST_TERMINBERECHNUNG_",
            sortable: false,
            hide: false,
          }, {
            id: "wfr_mahntext",
            label: "_LBL_WORKFLOWFRIST_WFR_MAHNTEXT_",
            sortable: false,
          }, {
            id: "workflowfolge_bez",
            label: "_LBL_WORKFLOWFRIST_WORKFLOWFOLGE_",
            sortable: true,
            sortId: "workflowfolge__wfo_kbez",
          }, {
            id: "wfr_betreff",
            label: "_LBL_WORKFLOWFRIST_WFR_BETREFF_",
            sortable: false,
            hide: true,
          }, {
            id: "wfr_inhalt",
            label: "_LBL_WORKFLOWFRIST_WFR_INHALT_",
            slot: "wfr_inhalt",
            sortable: false,
            hide: true,
          }, {
            id: "benachrichtigungstyp_bez",
            label: "_LBL_WORKFLOWFRIST_BENACHRICHTIGUNGSTYP_",
            sortable: false,
            hide: true,
          }, {
            id: "empfaenger_bez",
            label: "_LBL_WORKFLOWFRIST_EMPFAENGER_",
            sortable: false,
            hide: true,
          }
        ],
        rowActions: [
          {
            label: "_LBL_WORKFLOWFRIST_LIST_IN_KAUFGABEN_TABLE_ROW_ACTIONS_EDIT_",
            isHidden: () => (!this.canUpdate),
            callback: this.openModalUpdate,
          },
          {
            label: "_LBL_WORKFLOWFRIST_LIST_IN_KAUFGABEN_TABLE_ROW_ACTIONS_DELETE_",
            isHidden: () => (!this.canUpdate),
            callback: this.openModalDelete,
          },
        ],
        actions: [
          {
            permission: "kaufgabe.update.fristen",
            label: "_LBL_WORKFLOWFRIST_LIST_IN_KAUFGABEN_TABLE_ACTIONS_CREATE_",
            callback: () => this.modal.create = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "workflowfrist_in_kaufgabe_create",
          },
        ],
        label: "_LBL_WORKFLOWFRIST_LIST_IN_KAUFGABEN_TABLE_",
        sortable: true,
        pagination: true,
        details: false,
      },
      modal: {
        create: undefined,
        update: undefined,
        delete: undefined,
      },
      rowActionSelectorClose: undefined,
      modalDeleteOptions: undefined,
      deleteSelectorClose: undefined,
      filters: [],
      currentObj: undefined,
      formstepOptions: {
        required: false,
        hideHeader: true,
      },
      htmlId: "aufgabenvorlagen_details_fristen",
    };
  },
  computed: {
    canUpdate() {
      return this.checkPermissionsSync({ perm: "kaufgabe.update.fristen" });
    },
  },
  created() {
    this.initUrl();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { auPk: this.aufgabePk } });
    },
    updateRowActionSelectorClose(index) {
      if (!isNil(index)) {
        this.rowActionSelectorClose = `#workflowfristInKaufgabe_action_dropdown_${ index }`;
      } else {
        this.rowActionSelectorClose = undefined;
      }
    },
    closeModalCreate(status) {
      this.modal.create = false;
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
    },
    openModalUpdate({ item, index }) {
      this.modal.update = true;
      this.currentObj = item;
      this.updateRowActionSelectorClose(index);
    },
    closeModalUpdate(status) {
      this.modal.update = false;
      this.currentObj = undefined;
      this.updateRowActionSelectorClose();
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
    },
    openModalDelete({ item, index }) {
      this.updateRowActionSelectorClose(index);
      this.modalDeleteOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_WORKFLOWFRIST_LIST_IN_KAUFGABEN_MODAL_DELETE_TITLE_",
        msg: "_HTML_WORKFLOWFRIST_LIST_IN_KAUFGABEN_MODAL_DELETE_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteFrist(item),
        cancelCallback: this.closeModalDelete,
        loading: false,
      };
      this.modal.delete = true;
    },
    closeModalDelete() {
      this.modal.delete = false;
      this.modalDeleteOptions = undefined;
      this.deleteSelectorClose = this.rowActionSelectorClose;
      this.updateRowActionSelectorClose();
    },
    deleteFrist(item) {
      return this.deleteHttp({
        url: `aufgaben/${ this.aufgabePk }/fristen/${ item.pk }/`
      }).then(
        () => {
          EventBus.$emit(`updateTable${ TABLE_ID }`);
          this.closeModalDelete();
          this.deleteSelectorClose = "#workflowfrist_in_kaufgabe_create";
          this.addNotification({ text: "_MSG_WORKFLOWFRIST_LIST_IN_KAUFGABEN_MODAL_DELETE_SUCCESS_" });
        },
        () => this.addNotification({ text: "_MSG_WORKFLOWFRIST_LIST_IN_KAUFGABEN_MODAL_DELETE_ERROR_", type: "error" })
      );
    }
  },
};
