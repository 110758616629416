"use strict";

class AufgabeZuweisenController {
  /*@ngInject*/
  constructor($scope, $q, DiasNotification, Foerderantrag, Teams) {
    this.DiasNotification = DiasNotification;
    this.Foerderantrag = Foerderantrag;
    this.team = null;
    this.antrag = $scope.extras.antrag;
    this.vertrag = $scope.extras.vertrag;
    this.antrag_id = $scope.extras.antrag_id;
    this.vertrag_id = $scope.extras.vertrag_id;

    this.teamChoices = [];

    Teams.one("alle").customGET("").then(result => this.teamChoices = result);

    this.save = () => {
      const deferred = $q.defer();
      this.Foerderantrag.one(this.antrag_id).customPOST({ team: this.team }, "team_aendern").then(
        response => {
          this.DiasNotification.page.success("Team zugewiesen");
          if (this.antrag) {
            this.antrag.team = response.team_obj;
          }
          if (this.vertrag) {
            this.vertrag.team = response.team_obj;
          }
          deferred.resolve();
        },
        error => {
          deferred.reject();
          this.onError(error);
        }
      );
      return deferred.promise;
    };

    this.onError = error => {
      const errorsLabel = {
        team: "Team: ",
      };
      this.DiasNotification.form.clear();
      this.DiasNotification.form.error(
        error.data,
        "Bitte überprüfen Sie Ihre Eingaben", 
        {}, 
        errorsLabel
      );
      this.errors = error.data;
    };
  }
}

export default AufgabeZuweisenController;
