export default [
  {
    type: "text",
    id: "aov_inhaber",
    view: "v",
    maxlength: 60,
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_BANKVERRBINDUNG_KONTOINHABER_",
    required: true,
    translate: true,
  },
  {
    type: "iban",
    id: "aov_iban",
    view: "v",
    maxlength: 34,
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_BANKVERRBINDUNG_IBAN_",
    required: true,
    translate: true,
  },
  {
    type: "text",
    id: "aov_bic",
    view: "v",
    maxlength: 11,
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_BANKVERRBINDUNG_BIC_",
    required: false,
    translate: true,
  },
  {
    type: "text",
    id: "aov_bank",
    view: "v",
    maxlength: 60,
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_BANKVERRBINDUNG_GELDINSTITUT_",
    required: false,
    translate: true,
  },
];

