import {
  computed,
  ref,
  toRef,
} from "vue";

import HttpAPI from "../../../../../../global/compositionAPI/HttpAPI";
import { cloneDeep } from "lodash-es";
import { toFormElementFromParameter } from "../../../../../../global/functions/mappingForParameterToFormElement";

export default function SitzungsartenModalAPI(props, { emit }, {
  currentSitzungModul = computed(() => ({})),
  loadSitzungsmodulKatalog = () => {},
  model = ref({}),
  modelParameter = ref({}),
  parameterList = ref([]),
}) {
  const optionsModal = {
    required: true,
    showCloseButton: true,
  };

  const isModalVisible = ref(false);
  const openModal = () => {
    loadSitzungsmodulKatalog();
    isModalVisible.value = true;
  };
  const closeModal = () => {
    model.value = {};
    modelParameter.value = {};
    parameterList.value = [];
    isModalVisible.value = false;
  };

  const loading = ref(false);
  const errors = ref(undefined);
  const tagesordnungspunktModuleUrl = toRef(props, "tagesordnungspunktModuleUrl");
  const urlSave = computed(() => {
    return `${ tagesordnungspunktModuleUrl.value }validate/`;
  });
  const toId = toRef(props, "toId");
  const modulIndex = toRef(props, "modulIndex");
  const getDataToSave = () => {
    return Object.assign({}, model.value, {
      parameter: modelParameter.value,
      top: toId.value,
    });
  };
  const {
    postHttp,
  } = HttpAPI();
  const save = () => {
    loading.value = true;
    const DATA = getDataToSave();
    postHttp({
      url: urlSave.value,
      data: DATA,
    }).then(
      () => {
        const MODUL = cloneDeep(DATA);
        MODUL.model = DATA.parameter;
        MODUL.tom_parameterin = currentSitzungModul.value.ssm_parameterin;
        MODUL.modul_obj = currentSitzungModul.value;
        MODUL.parameterList = toFormElementFromParameter({
          obj: MODUL.tom_parameterin,
          showLabel: true,
        }).list;

        emit("updateModul", {
          modul: MODUL,
          modulIndex: modulIndex.value,
        });
        closeModal();
      },
      error => {
        errors.value = error.data;
      }
    ).finally(() => {
      loading.value = false;
    });
  };

  return {
    closeModal,
    errors,
    isModalVisible,
    loading,
    openModal,
    optionsModal,
    save,
  };
}
