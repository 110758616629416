import BetragsrechnerMixin from "../Mixins/BetragsrechnerMixin";
import PersonalkostenMitPktFormElementsMixin from "./PersonalkostenMitPktFormElementsMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenMitPkt",
  mixins: [
    BetragsrechnerMixin,
    PersonalkostenMitPktFormElementsMixin,
    SyConfigMixin,
  ],
  emits: [
    "changeModel",
  ],
  computed: {
    personalKostenConfig() {
      return this.getSyConfigsValue("personalkosten", {});
    },

    errorLabels() {
      return {
        [this.optionsVollzeitstelle.id]: {
          label: this.optionsVollzeitstelle.label,
          link: true,
          id: this.optionsVollzeitstelle.htmlId,
        },
        [this.optionsStellenbezeichnung.id]: {
          label: this.optionsStellenbezeichnung.label,
          link: true,
          id: this.optionsStellenbezeichnung.htmlId,
        },
        [this.optionsName.id]: {
          label: this.optionsName.label,
          link: true,
          id: this.optionsName.htmlId,
        },
        [this.optionsEinwilligung.id]: {
          label: this.optionsEinwilligung.label,
          link: true,
          id: this.optionsEinwilligung.htmlId,
        },
        [this.optionsPersonalstelle.id]: {
          label: this.optionsPersonalstelle.label,
          link: true,
          id: this.optionsPersonalstelle.htmlId,
        },
        [this.optionsBeschaeftigtVon.id]: {
          label: this.optionsBeschaeftigtVon.label,
          link: true,
          id: this.optionsBeschaeftigtVon.htmlId,
        },
        [this.optionsBeschaeftigtBis.id]: {
          label: this.optionsBeschaeftigtBis.label,
          link: true,
          id: this.optionsBeschaeftigtBis.htmlId,
        },
        [this.optionsPersonalfunktion.id]: {
          label: this.optionsPersonalfunktion.label,
          link: true,
          id: this.optionsPersonalfunktion.htmlId,
        },
        [this.optionsStellendauer.id]: {
          label: this.optionsStellendauer.label,
          link: true,
          id: this.optionsStellendauer.htmlId,
        },
        [this.optionsStellenumfang.id]: {
          label: this.optionsStellenumfang.label,
          link: true,
          id: this.optionsStellenumfang.htmlId,
        },
        [this.optionsStellenumfangProjekt.id]: {
          label: this.optionsStellenumfangProjekt.label,
          link: true,
          id: this.optionsStellenumfangProjekt.htmlId,
        },
        [this.optionsMonatsgehalt.id]: {
          label: this.optionsMonatsgehalt.label,
          link: true,
          id: this.optionsMonatsgehalt.htmlId,
        },
        [this.optionsEinmalzahlung.id]: {
          label: this.optionsEinmalzahlung.label,
          link: true,
          id: this.optionsEinmalzahlung.htmlId,
        },
        [this.optionsDokumentGeschwaerzt.id]: {
          label: this.optionsDokumentGeschwaerzt.label,
          link: true,
          id: this.optionsDokumentGeschwaerzt.htmlId,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
  },
  methods: {
    updateModelWithPktId(pktId) {
      const MODEL = cloneDeep(this.model);
      MODEL.argument = MODEL.argument || {};
      MODEL.argument.pkt_id = pktId;
      this.$emit("changeModel", { model: MODEL });
    },
  },
};
