export default {
  ast_registernummer: {
    type: "text",
    id: "ast_registernummer",
    view: "v",
    maxlength: 60,
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_REGISTERNUMMER_REGISTERNUMMER_",
    required: false,
    translate: true,
  },
  ast_gericht: {
    type: "text",
    id: "ast_gericht",
    view: "v",
    maxlength: 200,
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_REGISTERNUMMER_GERICHT_",
    required: false,
    translate: true,
  },
};
