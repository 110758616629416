"use strict";

import extendableDirective from "./extendable.directive";

const diasExtendable = angular.module("dias.extendable", [
])

  .directive("diasExtendable", extendableDirective)
;


export default diasExtendable;
