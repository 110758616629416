import AngularLink from "../../../../../../global/components/AngularLink/AngularLink.vue";
import PuxIcon from "../../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../../../../global/components/ui/ShowMore/ShowMore.vue";

import PermissionMixin from "../../../../../../global/mixins/PermissionMixin";

import FiltersAPI from "../../../../../../global/compositionAPI/FiltersAPI";

import {
  get,
  indexOf,
} from "lodash-es";

const TEXT_FIELDS = [
  "ga_nr", "ga_kbez"
];
const NAME_FIELDS = [
  "ersteller", "pruefer", "nutzer"
];
const DATE_FIELDS = [
  "ga_veroeffentlichtdatum", "ga_gutachtendatum", "ga_termin"
];
const ERGEBNIS_FIELDS = [
  "ergebnis_kurz", "ergebnis_lang"
];

// @vue/component
export default {
  name: "GutachtenUebersichtTr",
  components: {
    AngularLink,
    PuxIcon,
    PuxTranslate,
    ShowMore,
  },
  mixins: [
    PermissionMixin,
  ],
  props: {
    htmlId: {
      type: String,
      required: true,
    },
    gutachten: {
      type: Object,
      required: true,
    },
    cols: {
      type: Array,
      required: true,
    },
    showColorBorder: {
      type: Boolean,
      default: false
    },
    canLink: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    return {
      filterDate,
    };
  },
  computed: {
    ergebnisKurzTooltipId() {
      return `${ this.htmlId }_ergebnis_kurz_tooltip`;
    },

    rowCls() {
      const CLS = [];
      if (this.showColorBorder) {
        CLS.push(`border-${ this.gutachten.css_klasse }`);
      }
      return CLS;
    },

    isLink() {
      return this.gutachten.can_link && this.canLink;
    },

    rowRole() {
      return this.isLink ? "button" : undefined;
    },

    btnTitle() {
      if (this.isLink) {
        return "_TXT_GUTACHTEN_UEBERSICHT_LINK_TO_GUTACHTEN_TITLE_{{nr}}_{{bez}}_";
      }
      return "";
    },

    btnExtra() {
      return { nr: this.gutachten.ga_nr, bez: this.gutachten.ga_kbez };
    },
  },
  methods: {
    getValue(col) {
      const ID = col.id;
      if (indexOf(TEXT_FIELDS, ID) !== -1) {
        return get(this.gutachten, ID);
      } else if (indexOf(NAME_FIELDS, ID) !== -1) {
        return get(this.gutachten, `${ ID }_name`);
      } else if (indexOf(DATE_FIELDS, ID) !== -1) {
        return this.filterDate(get(this.gutachten, `${ ID }`));
      } else if (ID === "typ") {
        return get(this.gutachten, "typ_bez");
      } else if (ID === "vorlage") {
        return get(this.gutachten, "vorlage_bez");
      }
    },

    isErgebnisCol(col) {
      const ID = col.id;
      return indexOf(ERGEBNIS_FIELDS, ID) !== -1;
    },
  },
};
