"use strict";

import template from "./auszahlung.details.jade";

import betragEditTemplate from "./auszahlungsbetrag.edit.jade";
import betragEditController from "./auszahlungsbetrag.edit.controller";

import betragGeaendertEditTemplate from "./auszahlungsbetrag.geaendert.edit.jade";
import betragGeaendertEditController from "./auszahlungsbetrag.geaendert.edit.controller";

import editBelegTemplate from "./belege/modal.beleg.edit.jade";
import editBelegController from "./belege/modal.beleg.edit.component";

import SaveCallbackActions from "../../../vue/client/vue/const/SaveCallbackActions";

import {
  get,
  size,
  findIndex,
  isArray,
  isNil,
  forEach,
  cloneDeep,
  isString,
  filter,
  keyBy,
} from "lodash-es";
import {
  MAPPING,
} from "../../../vue/client/vue/components/Geschaeftsregel/Module/Module";
import {
  EventBus
} from "../../../vue/client/vue/global/functions/event-bus";

const ERROR_MODULE_KEYS = {
  vertretungsberechtigte: "Vertretungsberechtigte",
  belege: "Belege",
  bankverbindung: "Bankverbindung",
  auszahlung_einreichen: "Allgemeine Fehler",
  sachbericht: "Sachbericht",
  pruefbericht: "Prüfbericht",
};

const UNTERZEICHNENDE_STATUS_HINWEIS = {
  null: { class: "info", text: undefined },
  false: { class: "success", text: undefined },
  true: { class: "warning", text: undefined }
};


class VertragController {
  /*@ngInject*/
  constructor(
    $scope,
    $q,
    $stateParams,
    $document,
    $timeout,
    $filter,
    Vertrag,
    Auszahlungsplan,
    Auszahlung,
    AuthService,
    DiasNotification,
    Foerderantrag,
    Workflow,
    KAufgabe,
    WorkflowfunktionRegistry,
    diasModalDialog,
    Antragstellerorganisationen,
    Nutzeraufgabe,
    $window,
    $anchorScroll,
    diasConfirmDialog,
    FoerderangebotAnzeigekontextUUIDs,
    Restangular,
    SyConfigs,
    Katalog,
    i18n,
    Client,
    KommunikationsdatenUUIDs,
  ) {
    this.MAPPING = MAPPING;
    this.SyConfigs = SyConfigs;
    this.$q = $q;
    this.abs = Math.abs;
    this.gettext = i18n.gettext;
    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.$filter = $filter;
    this.gettext = i18n.gettext;
    this.Vertrag = Vertrag;
    this.Auszahlung = Auszahlung;
    this.Auszahlungsplan = Auszahlungsplan;
    this.AuthService = AuthService;
    this.DiasNotification = DiasNotification;
    this.Foerderantrag = Foerderantrag;
    this.FoerderangebotAnzeigekontextUUIDs = FoerderangebotAnzeigekontextUUIDs;
    this.Workflow = Workflow;
    this.Katalog = Katalog;
    this.KAufgabe = KAufgabe;
    this.WorkflowfunktionRegistry = WorkflowfunktionRegistry;
    this.diasModalDialog = diasModalDialog;
    this.diasConfirmDialog = diasConfirmDialog;
    this.Antragstellerorganisationen = Antragstellerorganisationen;
    this.Nutzeraufgabe = Nutzeraufgabe;
    this.$window = $window;
    this.$document = $document;
    this.$anchorScroll = $anchorScroll;
    this.Client = Client;
    this.Restangular = Restangular;
    this.$timeout = $timeout;
    this.KommunikationsdatenUUIDs = KommunikationsdatenUUIDs;
    this.updateinfoprovider = this.updateinfoprovider.bind(this);
    $anchorScroll.yOffset = 255;
    this.sachberichtVorlageObj = {};
    this.sachberichtRubrikName = "";
    this.pruefberichtRubrikName = "";
    this.sbModule = [];
    this.pbModule = [];

    this.wfKontext = null;
    this.astAlsButtonConfig = this.SyConfigs.wert("ast_als_button") || {};
    const WF_STATUS_GRUPPEN_IN_HEADER = this.SyConfigs.wert("wf_statusgruppen_in_header") || {};
    WF_STATUS_GRUPPEN_IN_HEADER.objects = WF_STATUS_GRUPPEN_IN_HEADER.objects || [];
    this.statusWFStatusGruppenInHeader = WF_STATUS_GRUPPEN_IN_HEADER.objects.indexOf("auszahlung") !== -1;
    this.mandant = SyConfigs.wert("adress_mandant");
    this.statusKoplfiplAnzeigen = false;

    this.wizardConfig = this.SyConfigs.wert("auszahlung_wizard") || {};

    this.showBV = true;
    if (_.isObject(SyConfigs.wert("auszahlung"))) {
      const auszahlungsconfig = SyConfigs.wert("auszahlung");
      if (auszahlungsconfig.bv_relevant === false) {
        this.showBV = false;
      }
      UNTERZEICHNENDE_STATUS_HINWEIS.null.text = auszahlungsconfig.hinweis_vertreter_leer;
      UNTERZEICHNENDE_STATUS_HINWEIS.true.text = auszahlungsconfig.hinweis_vertreter_geaendert;
      UNTERZEICHNENDE_STATUS_HINWEIS.false.text = auszahlungsconfig.hinweis_vertreter_gleich;
    }
    this.statusShowEinreichenMessage = !!this.gettext("_HTML_AUSZAHLUNG_DETAILS_EINREICHEN_MSG_{{auszahlung_name}}_{{referenznr}}_");

    this.statusShowDokumenteRubrik = false;
    this.statusShowSachberichtRubrik = false;
    this.statusShowPruefberichtRubrik = false;

    this.modelVertreter = [];
    this.allAuszahlungenWithoutCurrent = [];
    this.statusAllAuszahlungenForVertragLoaded = false;
    this.zuschuss = null;

    this.showModalitaetWechsel = false;
    this.modalitaetWechselLoading = false;
    this.onModalitaetLoading = status => {
      $scope.$applyAsync(() => this.modalitaetWechselLoading = status);
    };
    this.openModalitaetWechsel = () => {
      this.showModalitaetWechsel = true;
    };
    this.closeModalitaetWechsel = () => {
      $scope.$applyAsync(() => {
        this.showModalitaetWechsel = false;
        this.saveCallBack();
      });
    };

    this.getUnterzeichnendeHinweis = () => {
      if (this.canShowBvStatus() && this.auszahlung.az_vertreter_geaendert !== null) {
        if (UNTERZEICHNENDE_STATUS_HINWEIS[`${ this.auszahlung.az_vertreter_geaendert }`] && size(UNTERZEICHNENDE_STATUS_HINWEIS[`${ this.auszahlung.az_vertreter_geaendert }`].text) > 0) {
          return UNTERZEICHNENDE_STATUS_HINWEIS[`${ this.auszahlung.az_vertreter_geaendert }`];
        }
      }
    };

    this.initData();
    this.init();

    // Timeline
    this.timelineConfig = {
      kontexte: [
        {
          titel: "Erstellung",
          key: "A",
        },
        {
          titel: "Prüfung",
          key: "F"
        },
      ],
      benachrichtigungen: {
        bezug: "auszahlung",
        bezugpk: $stateParams.aus_id,
        autorefresh: true,
      },
      url: `vertraege/${ $stateParams.id }/auszahlungen/${ $stateParams.aus_id }/timeline/`,
    };

    this.onAufgabeAngenommen = () => {
      this.$timeout(() => {
        this.loadAuszahlung();
      });
    };
    this.updateModuleSachbericht = this.updateModuleSachbericht.bind(this);
    this.updateModulePruefbericht = this.updateModulePruefbericht.bind(this);
    this.saveCallBack = this.saveCallBack.bind(this);
    this.onLoadDokarten = this.onLoadDokarten.bind(this);
    this.updateAuszahlungFromVue = this.updateAuszahlungFromVue.bind(this);
    this.closeWizard = this.closeWizard.bind(this);
    this.updateAuszahlungAfterModalitaetChange = this.updateAuszahlungAfterModalitaetChange.bind(this);
    this.onWorkflowInit = this.onWorkflowInit.bind(this);
    this.onWorkflowCanceled = this.onWorkflowCanceled.bind(this);
    this.onWorkflowStarted = this.onWorkflowStarted.bind(this);
    this.onWorkflowFinish = this.onWorkflowFinish.bind(this);
    this.onWorkflowSuccess = this.onWorkflowSuccess.bind(this);
    this.onWorkflowFailure = this.onWorkflowFailure.bind(this);
    this.updateAufgabeFromVue = this.updateAufgabeFromVue.bind(this);
    this.updateWorkflowfolgen = this.updateWorkflowfolgen.bind(this);

    this.weitereAktionen = {
      uebersichtPdf: () => {
        return this.allowAuszahlungUebersichtPDFDownload() && this.vertrag.pk && this.auszahlung.pk;
      },
      auszahlungPdf: () => {
        return this.allowAuszahlungsPDFDownload() && this.vertrag.pk && this.auszahlung.pk;
      },
      belegeInPruefung: () => {
        return (this.Client === "dias-am" || this.Client === "dias-sdb") && this.AuthService.syncHasPerm("vertragskostenpositionsbelege.anerkennen", this.auszahlung.user_permissions);
      },
      ungepruefteBelegeAnerkennen: () => {
        return this.auszahlung.belege_vorhanden && this.AuthService.syncHasPerm("vertragskostenpositionsbelege.anerkennen.pauschal", this.auszahlung.user_permissions);
      },
      beantragteBelegeAnerkennen: () => {
        return !this.auszahlung.belege_vorhanden && this.AuthService.syncHasPerm("vertragskostenpositionsbelege.anerkennen.pauschal", this.auszahlung.user_permissions);
      },
      betragAnpassen: () => {
        return this.showAuszahlungsbetragGeaendertEdit();
      },
      zahlungsanweisungPdf: () => {
        return this.vertrag.pk && this.auszahlung.pk && this.AuthService.syncHasPerm("auszahlung.zahlungsanweisung", this.auszahlung.user_permissions);
      },
      dhwVerwendungsnachweis: () => {
        return this.vertrag.pk && this.AuthService.syncHasPerm("dhw_verwendungsnachweisdokument.view", this.auszahlung.user_permissions);
      },
      modalitaetWechseln: () => {
        return this.AuthService.syncHasPerm("auszahlung.modalitaet.wechsel", this.auszahlung.user_permissions);
      },
      auszahlungValidate: () => {
        return this.AuthService.syncHasPerm("auszahlung.validieren", this.auszahlung.user_permissions);
      }
    };
  }

  showWeitereAktionen() {
    let show = false;
    forEach(this.weitereAktionen, func => {
      if (func()) {
        show = true;
        return false; // forEach abbrechen
      }
    });
    return show;
  }

  initData() {
    this.initNotizenData();
  }

  initNotizenData() {
    this.notizOptions = { orgChoices: [] };
    this.stepMap = [
      "step_ubersicht",
      "step_belege",
      "step_bv",
      "step_vertretungsberechtigte",
      "step_berichte",
      "step_pruefberichte",
      "step_dokumente",
      "step_zeitraum"
    ];
    this.steps = [
      {
        key: "step_ubersicht",
        kbez: "Übersicht"
      },
      {
        key: "step_belege",
        kbez: this.gettext("Belege")
      },
      {
        key: "step_bv",
        kbez: "Bankverbindung"
      },
      {
        key: "step_vertretungsberechtigte",
        kbez: "Vertretungsberechtigte"
      },
      {
        key: "step_berichte",
        kbez: "Berichte"
      },
      {
        key: "step_pruefberichte",
        kbez: this.gettext("_TXT_AUSZAHLUNG_DETAILS_RUBRIK_PRUEFBERICHTE_NAME_")
      },
      {
        key: "step_dokumente",
        kbez: "Dokumente"
      },
      {
        key: "step_zeitraum",
        kbez: this.gettext("_TXT_AUSZAHLUNG_ZEITRAUM_TITLE_")
      },
    ];
  }

  init() {
    this.loading = {
      vertrag: true,
      auszahlung: true,
      auszahlungsplan: true,
      antrag: true,
      ast: true,
      bericht: true,
      workflow: true,
      finanzierungsmodule: true,
      zuschuss: true,
    };

    this.loadVertrag().then(
      () => {
        this.checkPermissionCreateBeleg();
        this.loadAntrag().then(() => {
          this.updateKoFiModule().finally(() => {
            this.loading.kofimodule = false;
          });
          this.loadAuszahlung({ statusSetViewName: true }).then(
            () => {
              this.loadAuszahlungsplan().finally(() => this.loading.auszahlungsplan = false);
            }
          ).finally(() => this.loading.auszahlung = false);
        }).finally(() => this.loading.antrag = false);
        this.loadAntragstellerorg().finally(() => this.loading.ast = false);
      }
    ).finally(() => this.loading.vertrag = false);
  }

  saveCallBack({ statusReloadVertrag, statusSaveCallback } = {}) {
    if (statusSaveCallback === SaveCallbackActions.RELOAD_ALL) {
      this.loadAuszahlung().then(
        () => {
          this.loadAuszahlungsplan();
          this.updateWorkflowfolgen();
        }
      );
      this.loading.kofimodule = true;
      this.loading.zuschuss = true;
      this.updateKoFiModule().finally(() => this.loading.kofimodule = false);
      if (this.usesZuschussberechnung() || statusReloadVertrag) {
        this.loading.vertrag = true;
        /* Bei bestimmten Konstellationen (Nachträgliches Ändern von Belegen, wenn schon ein
          Auszahlungsbetrag gesetzt ist, siehe KMS-619) können sich Werte am Vertrag
          ändern, daher muss dieser beim Speichern auch neu geladen werden.
        */
        this.loadVertrag().finally(() => this.loading.vertrag = false);
        this.updateZuschuss().finally(() => this.loading.zuschuss = false);
      } else {
        this.loading.zuschuss = false;
      }
    } else if (statusSaveCallback === SaveCallbackActions.RELOAD_WF) {
      this.updateWorkflowfolgen();
    }
  }

  updateAuszahlungAfterModalitaetChange() {
    return new Promise(resolve => {
      this.$timeout(() => {
        this.loadAuszahlung({ statusSetViewName: true }).then(
          () => {
            this.updateWorkflowfolgen();
            Promise.all([
              this.loadAuszahlungsplan(),
              this.loadBerichte(),
            ]).then(
              () => {
                resolve();
              }
            );
          }
        );
      });
    });
  }

  updateWorkflowfolgen() {
    this.$timeout(() => {
      this.lastWfUpdate = new Date();
    });
  }

  canShowBvStatus() {
    return this.auszahlung && this.AuthService.syncHasPerm("auszahlung.bv_status.view", this.auszahlung.user_permissions);
  }

  canShowSachbericht() {
    return this.auszahlung.sachbericht && this.AuthService.syncHasPerm("sachbericht.view", this.auszahlung.user_permissions);
  }

  canShowPruefbericht() {
    return this.auszahlung.pruefbericht && this.AuthService.syncHasPerm("pruefbericht.view", this.auszahlung.user_permissions);
  }

  canEditVertreter() {
    return this.AuthService.syncHasPerm("auszahlung.vertreter.update", this.auszahlung.user_permissions);
  }

  canEdit() {
    return this.AuthService.syncHasPerm("auszahlung.update", this.auszahlung.user_permissions);
  }

  canEditBelege() {
    return this.AuthService.syncHasPerm("vertragskostenpositionsbelege.update", this.auszahlung.user_permissions);
  }

  usesZuschussberechnung() {
    const modus_zuschussberechnung = "e6a564f4-65cd-40e8-b39e-27fc791bbd4d";
    return this.auszahlungsplan && ((this.auszahlungsplan.zp_modalitaet && this.auszahlungsplan.zp_modalitaet.modus === modus_zuschussberechnung) || this.auszahlungsplan.is_last);
  }

  showAuszahlungsbetragEdit() {
    const canEdit = this.AuthService.syncHasPerm("auszahlung.auszahlungsbetrag.edit", this.auszahlung.user_permissions);
    const canReset = this.AuthService.syncHasPerm("auszahlung.auszahlungsbetrag.reset", this.auszahlung.user_permissions);
    return (canEdit && ((canReset && this.auszahlung.az_betrag === null) || !canReset));
  }

  showAuszahlungsbetragGeaendertEdit() {
    if (!this.auszahlung
        || !this.auszahlungsplan
        || !this.auszahlung.az_betrag_anerkannt) {
      return;
    }
    return this.AuthService.syncHasPerm("auszahlung.auszahlungsbetrag.update", this.auszahlung.user_permissions);
  }

  loadVertrag() {
    if (!this.$stateParams.id) {
      const deferred = this.$q.defer();
      deferred.reject();
      return deferred.promise;
    }
    return this.Vertrag.one(this.$stateParams.id).get().then(
      response => {
        this.vertrag = response;
        this.checkPermissionVertragspersonalstellen();
        // this.antrag = response.antrag;
        // this.ast = response.antragstellerorg;
      }
    );
  }

  loadAllAuszahlungenForVertrag() {
    if (this.statusAllAuszahlungenForVertragLoaded) {
      return;
    }
    this.vertrag.auszahlungen.getList({ ordering: "az_nummer" }).then(
      response => {
        this.filterAllAuszahlungen(response);
        this.statusAllAuszahlungenForVertragLoaded = true;
      }
    );
  }

  filterAllAuszahlungen(response) {
    this.allAuszahlungenWithoutCurrent = filter(response, item => {
      return item.pk !== this.auszahlung.pk;
    });
  }

  checkPermissionVertragspersonalstellen() {
    this.permVertragspersonalstellen = this.AuthService.syncHasPerm("vertragskostenpersonalstellen.view", this.vertrag.user_permissions);
    if (!this.permVertragspersonalstellen) {
      this.isPersonalBaumVisible = false;
      return;
    }
    this.loadVertragspersonalstellen();
  }

  loadVertragspersonalstellen() {
    this.vertrag.one("vertragspersonalstellen").getList().then(
      response => {
        this.isPersonalBaumVisible = response.length !== 0;
      }
    );
  }

  checkPermissionCreateBeleg() {
    this.canCreateBeleg = false;
    this.vertrag.checkPermissions(["vertragskostenpositionsbelege.create"], true).then(
      response => {
        this.canCreateBeleg = response.length > 0;
      }
    );
  }

  setViewName() {
    if (this.canSeeWizard) {
      this.viewName = "wizard";
    } else {
      this.viewName = "details";
    }
  }

  openWizard() {
    this.viewName = "wizard";
  }

  checkVisibleForWizard() {
    this.canSeeWizard = this.AuthService.syncHasPerm("auszahlung.wizard.view", this.auszahlung.user_permissions) && this.wizardConfig.wizard_anzeigen;
  }

  loadAuszahlung({ statusSetViewName } = {}) {
    if (!this.$stateParams.id && !this.$stateParams.aus_id) {
      const deferred = this.$q.defer();
      deferred.reject();
      return deferred.promise;
    }
    this.reloading = true;
    return this.Vertrag.one(this.$stateParams.id).auszahlungen.one(this.$stateParams.aus_id).get().then(
      response => {
        this.timelineCsvUrl = response.getRestangularUrl() + "/timeline/";
        this.auszahlung = response;
        this.checkVisibleForWizard();
        if (statusSetViewName) {
          this.setViewName();
        }
        this.urlAuszahlungen = `vertraege/${ this.$stateParams.id }/auszahlungen/`;
        this.urlAuszahlungenOhneSlash = this.urlAuszahlungen.slice(0, -1);
        const conf = this.auszahlung.aktuelle_aufgabe.au_kontext_config;
        this.anzeigekontexteAuszahlung = conf && conf.markiert && conf.markiert.auszahlung ?
          conf.markiert.auszahlung : {};

        this.auszahlungLabel = `${ this.$filter("spaced")(this.vertrag.v_nr) } - ${ this.auszahlung.az_nummer }`;
        const baseUrl = this.auszahlung.getRestangularUrl().replace("/api/", "");
        this.baseUrl = baseUrl + "/";
        this.loadWorkflowTyp();
        this.dokAuszahlung = this.auszahlung.clone().plain();
        this.Auszahlung.restangularizeElement(this.dokAuszahlung);
        this.getStatusAuszahlungEinreichen();
        this.loadAllAuszahlungenForVertrag();
        this.AuthService.syncHasPerm("dhw_verwendungsnachweisdokument.view", this.auszahlung.user_permissions);
        this.showCreateButton = this.AuthService.syncHasPerm("vertragskostenpositionsbelege.create.button", this.auszahlung.user_permissions);
        this.sachberichtVorlageObj = keyBy(this.auszahlung.sachberichtvorlage, "pk");
        this.reloading = false;
      }
    );
  }

  loadWorkflowTyp() {
    if (this.auszahlung.aktuelle_aufgabe && this.auszahlung.aktuelle_aufgabe.workflowstatusgruppe) {
      this.Katalog.getKatalog("workflowtyp", { pk: this.auszahlung.aktuelle_aufgabe.workflowstatusgruppe.workflowtyp }).then(
        response => {
          this.wfKontext = response[0];
        }
      );
    }
  }

  loadAuszahlungsplan() {
    if (!this.$stateParams.id) {
      this.statusKoplfiplAnzeigen = true;
      const deferred = this.$q.defer();
      deferred.reject();
      return deferred.promise;
    }
    return this.Auszahlungsplan.one(this.auszahlung.auszahlungsplan).get().then(
      response => {
        this.auszahlungsplan = response;
        this.sachberichtRubrikName = this.getPruefOderSachberichtRubrikName(true);
        if (this.sachberichtRubrikName) {
          this.statusShowSachberichtRubrik = true;
        }
        this.pruefberichtRubrikName = this.getPruefOderSachberichtRubrikName(false);
        if (this.pruefberichtRubrikName) {
          this.statusShowPruefberichtRubrik = true;
        }
        if (this.usesZuschussberechnung()) {
          this.updateZuschuss().finally(() => {
            this.loading.zuschuss = false;
          });
        } else {
          this.loading.zuschuss = false;
        }
        if (!this.auszahlungsplan.zp_modalitaet || this.auszahlungsplan.zp_modalitaet.koplfipl_anzeigen !== false) {
          this.statusKoplfiplAnzeigen = true;
        }
        this.loading.sachbericht = true;
        if (get(this.auszahlungsplan, "zp_modalitaet.sachbericht") || get(this.auszahlungsplan, "zp_modalitaet.pruefbericht")) {
          this.loadBerichte().finally(() => this.loading.sachbericht = false);
        } else {
          this.loading.sachbericht = false;
        }
      }
    );
  }

  updateZuschuss() {
    // Der Zuschuss muss nur solange ermittelt werden, solange die Auszahlung
    // noch nicht ausgezahlt ist.
    if (!this.$stateParams.id) {
      const deferred = this.$q.defer();
      deferred.reject();
      return deferred.promise;
    }
    return this.Vertrag.one(this.$stateParams.id).auszahlungen.one(this.$stateParams.aus_id).customGET("zuschuss").then(
      response => {
        this.zuschuss = response;
      }
    );
  }

  updateKoFiModule() {
    return this.antrag.module.getList(
      {
        anzeigekontext: [
          this.FoerderangebotAnzeigekontextUUIDs.finanzierung,
          this.FoerderangebotAnzeigekontextUUIDs.kosten
        ],
        objekttyp: "antrag",
      }
    ).then(
      response => {
        this.kostenModule = [];
        this.finanzierungsModule = [];
        angular.forEach(response, modul => {
          if (modul.modulgruppen.indexOf("kosten") !== -1) {
            this.kostenModule.push(modul);
          }
          if (modul.modulgruppen.indexOf("finanzierung") !== -1) {
            this.finanzierungsModule.push(modul);
          }
        });
      }
    );
  }

  loadAntragstellerorg() {
    return this.Antragstellerorganisationen.one(this.vertrag.antragstellerorg.pk).get().then(
      response => {
        this.ast = response;
      }
    );
  }

  loadBerichte() {
    if (isNil(this.auszahlung.sachbericht) && isNil(this.auszahlung.pruefbericht)) {
      this.loading.bericht = false;
      return Promise.resolve();
    }
    return this.auszahlung.module.getList().then(
      response => {
        const MODALITAET = this.auszahlungsplan.zp_modalitaet;
        this.sbModule = [];
        this.pbModule = [];
        response.forEach(modul => {
          if (!isNil(this.auszahlung.sachbericht) &&
              modul.regel.sachberichtvorlage === MODALITAET.sachbericht) {
            this.sbModule.push(modul);
          }
          if (!isNil(this.auszahlung.pruefbericht) &&
              modul.regel.sachberichtvorlage === MODALITAET.pruefbericht) {
            this.pbModule.push(modul);
          }
        });
      }
    ).finally(() => this.loading.bericht = false);
  }

  getPruefOderSachberichtRubrikName(is_sachbericht) {
    const modalitaet = this.auszahlungsplan.zp_modalitaet;
    if (is_sachbericht && get(modalitaet, "sachbericht")) {
      return this.sachberichtVorlageObj[modalitaet.sachbericht].sbv_rubriklabel;
    } else if (!is_sachbericht && get(modalitaet, "pruefbericht")) {
      return this.sachberichtVorlageObj[modalitaet.pruefbericht].sbv_rubriklabel;
    }
  }

  loadAntrag() {
    return this.Foerderantrag.one(this.vertrag.antrag_id).get().then(
      response => {
        this.antrag = response;
      }
    );
  }

  scrollTop() {
    $(window).scrollTop(0);
  }

  resetValidation() {
    this.validationErrorsLoading = undefined;
    this.validationErrors = undefined;
  }

  getStatusAuszahlungEinreichen() {
    if (!this.auszahlung.eingereicht &&
        !this.AuthService.syncHasPerm("vertragskostenpositionsbelege.anerkennen", this.auszahlung.user_permissions)) {
      this.statusAuszahlungEinreichen = true;
    } else {
      this.statusAuszahlungEinreichen = false;
    }
  }

  allowAuszahlungUebersichtPDFDownload() {
    // Gibt an, ob die Auszahlungsübersicht als PDF heruntergeladen werden kann.
    return (this.AuthService.syncHasPerm("dokument.view", this.auszahlung.user_permissions)
      && this.AuthService.syncHasPerm("auszahlung.dokument.uebersicht.view", this.auszahlung.user_permissions));
  }

  allowAuszahlungsPDFDownload() {
    // Gibt an, ob die Auszahlungsanforderung als PDF heruntergeladen werden kann.
    // Das ist aktuell nur möglich, wenn der Nutzer das Recht zum Einreichen hat und die Auszahlung eingereicht ist.
    if (this.auszahlung && this.auszahlung.eingereicht &&
        this.AuthService.syncHasPerm("auszahlung.einreichen", this.auszahlung.user_permissions)) {
      return true;
    }
    return false;
  }

  einreichenAuszahlung() {
    return this.auszahlung.einreichenAuszahlung().then(
      () => {
        this.DiasNotification.page.success("Die Auszahlung wurde eingereicht.");
        this.loadAuszahlung();
      }
    );
  }

  showErrorModulName(anzeigekontext) {
    if (anzeigekontext === "sachbericht") {
      return this.sachberichtVorlageObj[this.auszahlungsplan.zp_modalitaet.sachbericht].sbv_rubriklabel;
    }
    if (anzeigekontext === "pruefbericht") {
      return this.pruefberichtRubrikName;
    }
    return ERROR_MODULE_KEYS[this.gettext(anzeigekontext)];
  }

  goTo(anzeigekontext, modul) {
    const anchor = this.$document[0].getElementById(anzeigekontext);
    const angularAnchor = anchor ? angular.element(anchor) : null;

    if (angularAnchor && !angularAnchor.hasClass("active")) {
      angularAnchor.addClass("active");
    }

    if (modul) {
      this.$anchorScroll(modul.regelnummer);
    } else {
      this.$anchorScroll(anzeigekontext);
    }
  }

  changeViewName() {
    if (this.viewName === "timeline") {
      this.viewName = "details";
    } else if (this.viewName === "details") {
      this.viewName = "timeline";
    }
  }

  changeBelegeStatusEingereicht() {
    this.loadingChangeAllBelege = true;
    this.auszahlung.customPUT({}, "kostenbeleg_status_eingereicht").then(
      () => {
        this.saveCallBack();
        this.kofiErfassungReloadStatus = true;
        this.loadingChangeAllBelege = false;
      }
    );
  }

  acceptAllBelege() {
    this.loadingChangeAllBelege = true;
    this.auszahlung.customPUT({}, "belege_anerkennen").then(
      () => {
        this.saveCallBack();
        this.kofiErfassungReloadStatus = true;
      }
    ).finally(() => {
      this.loadingChangeAllBelege = false;
      EventBus.$emit("updateBeleg", { kosten: true, finanzierung: true });
    });
  }

  acceptBeantragtenBetrag() {
    this.loadingChangeAllBelege = true;
    this.auszahlung.customPUT({}, "beantragten_betrag_anerkennen").then(
      () => {
        this.saveCallBack();
        this.kofiErfassungReloadStatus = true;
      },
      err => this.DiasNotification.page.error(err)
    ).finally(() => this.loadingChangeAllBelege = false);
  }

  editAuszahlungsbetrag() {
    this.diasModalDialog({
      title: this.gettext("Auszahlungsbetrag setzen"),
      template: betragEditTemplate,
      controller: betragEditController,
      extras: { auszahlung: this.auszahlung }
    }).then(
      () => {
        this.saveCallBack();
      }
    );
  }

  editAuszahlungsbetragGeaendert() {
    this.diasModalDialog({
      title: this.gettext("Auszahlungsbetrag anpassen"),
      template: betragGeaendertEditTemplate,
      controller: betragGeaendertEditController,
      extras: {
        auszahlung: this.auszahlung,
        auszahlungsplan: this.auszahlungsplan
      }
    }).then(
      () => {
        this.saveCallBack();
      }
    );
  }

  resetAuszahlungsbetrag() {
    this.auszahlung.resetAuszahlungsbetrag().then(() => {
      this.DiasNotification.page.success("Der Auszahlungsbetrag wurde zurückgesetzt");
      this.saveCallBack();
    }, () => {
      this.DiasNotification.page.error("Der Auszahlungsbetrag konnte nicht zurückgesetzt werden");
    });
  }

  loadDocument(url, fileName) {
    this.Restangular.one(url).withHttpConfig({ responseType: "blob" }).get().then(
      response => {
        if (this.$window.navigator.msSaveBlob) {
          this.$window.navigator.msSaveBlob(response, fileName);
        } else {
          const fileURL = (this.$window.URL || this.$window.webkitURL).createObjectURL(response);
          const aLink = this.$window.document.createElement("a");
          aLink.download = fileName;
          aLink.href = fileURL;
          aLink.target = "_self";
          const eventClick = this.$window.document.createEvent("MouseEvents");
          eventClick.initEvent(
            "click",
            true,
            true,
            this.$window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
          );
          aLink.dispatchEvent(eventClick);
        }
      },
      err => this.DiasNotification.page.error(err, "Fehler beim erstellen des Dokuments")
    );
  }

  createBeleg() {
    return this.diasModalDialog({
      title: this.gettext("_TXT_AUSZAHLUNG_BELEGE_MODAL_CREATE_HEADER_"),
      template: editBelegTemplate,
      controller: editBelegController,
      extras: {
        vertrag: this.vertrag,
      },
    }).then(
      response => {
        if (!_.isUndefined(response)) {
          this.kofiErfassungReloadStatus = true;
          this.loadingChangeAllBelege = false;
        }
      }
    );
  }

  updateModuleSachbericht({ response }) {
    this.$timeout(() => {
      const INDEX = findIndex(this.sbModule, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.sbModule.splice(INDEX, 1, response);
      }
    });
  }

  updateModulePruefbericht({ response }) {
    this.$timeout(() => {
      const INDEX = findIndex(this.pbModule, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.pbModule.splice(INDEX, 1, response);
      }
    });
  }

  updateinfoprovider({ response }) {
    this.$timeout(() => {
      if (isNil(this.zuschuss)) {
        this.zuschuss = {};
      }
      Object.assign(this.zuschuss, response || {});
    });
  }

  onLoadDokarten(response) {
    this.$timeout(() => {
      if (response && response.length) {
        this.statusShowDokumenteRubrik = true;
      }
    });
  }

  updateAuszahlungFromVue(response, statusUpdateWorkflowfolgen) {
    this.$timeout(() => {
      Object.assign(this.auszahlung, response);
      if (statusUpdateWorkflowfolgen) {
        this.updateWorkflowfolgen();
      }
    });
  }

  closeWizard() {
    this.$timeout(() => {
      this.viewName = "details";
    });
  }

  onWorkflowInit(clientFunktion) {
    this.$timeout(() => {
      this.clientFunktion = clientFunktion;
      this.loading.workflow = true;
    });
  }

  onWorkflowCanceled() {
    this.$timeout(() => {
      this.clientFunktion = undefined;
      this.loading.workflow = false;
    });
  }

  onWorkflowStarted(aufgabe, currentWorkflowfolge) {
    this.$timeout(() => {
      this.closeWizard();
      this.resetValidation();
      this.updateAktuelleAufgabe(aufgabe);
      this.currentWorkflowfolge = currentWorkflowfolge;
    });
  }

  onWorkflowFinish() {
    this.$timeout(() => {
      this.clientFunktion = undefined;
      this.loading.workflow = false;
      this.currentWorkflowfolge = undefined;
    });
  }

  onWorkflowSuccess(aufgabe) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerSuccess(aufgabe);
      }
      this.scrollTop();
      this.$scope.$broadcast("workflow.aufgabe.changed");
      EventBus.$emit("workflow.aufgabe.changed");
      this.az_datum_eingereicht = this.auszahlung.az_datum_eingereicht;
      this.checkPermissionCreateBeleg();
      if (get(this.currentWorkflowfolge, "wfo_reload")) {
        return this.loadAuszahlung().finally(
          () => {
            this.updating = false;
            if (!this.az_datum_eingereicht && this.auszahlung.az_datum_eingereicht) {
              this.einreichenStatus = true;
            }
          }
        );
      }
    });
  }

  onWorkflowFailure(err) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerError(err);
      }
      this.validationErrorsLoading = false;
      let errorData = err.data;
      if (errorData && errorData.error_data) {
        errorData = errorData.error_data;
      }
      if (errorData && !angular.isString(errorData)) {
        const errData = [];
        forEach(errorData, (modulErr, key) => {
          errData[key] = cloneDeep(modulErr);
          forEach(errData[key][1], _err => {
            forEach(_err.errors, (_errors, field) => {
              if (!isArray(_errors) || !isString(_errors[0])) {
                _err.errors[field] = [this.gettext("Bitte prüfen Sie die Eingaben.")];
              }
            });
          });
        });
        this.validationErrors = errData;
      } else {
        this.validationErrors = undefined;
      }
      this.scrollTop();
      return this.loadAuszahlung();
    });
  }

  getValidierenMsg() {
    return this.gettext("_MSG_AUSZAHLUNG_VALIDIEREN_VALIDATE_{{name}}_").replace("{{name}}", this.auszahlung.name);
  }

  onValidate() {
    this.validationErrorsLoading = true;
    this.auszahlung.customGET("validieren").then(
      result => {
        this.validationErrors = result;
      },
      () => this.DiasNotification.page.error(this.getttext("_ERR_AUSZAHLUNG_DETAILS_VALIDIEREN_"))
    ).finally(() => {
      this.validationErrorsLoading = false;
      this.scrollTop();
    });
  }

  updateAufgabeFromVue(aufgabeFromResponse) {
    this.$timeout(() => {
      this.updateAktuelleAufgabe(aufgabeFromResponse);
    });
  }

  updateAktuelleAufgabe(newAufgabe) {
    Object.assign(this.auszahlung.aktuelle_aufgabe, newAufgabe);
  }
}

export default {
  template: template(),
  controller: VertragController,
  controllerAs: "vm"
};
