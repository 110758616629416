import PuxTooltip from "../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../SnapshotIcon/SnapshotIcon.vue";

import {
  FilterCurrencyMixin,
  FilterDateMixin,
} from "../../../global/mixins/FiltersMixin";

import {
  get,
  isArray,
  isFunction,
  isNil,
  isPlainObject,
  round,
} from "lodash-es";

// @vue/component
export default {
  name: "SnapshotItem",
  components: {
    PuxTooltip,
    PuxTranslate,
    SnapshotIcon,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterDateMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    idSuffix: {
      type: [String, Array, Number],
      required: false,
      default: "",
    },
    tag: {
      type: String,
      required: false,
      default: "div",
    },
    data: {
      type: [Object, String, Number],
      required: false,
      default: undefined,
    },
    snapshot: {
      type: [Object, String, Number],
      required: false,
      default: undefined,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    keyLabel: {
      type: [String, Array],
      required: false,
      default: undefined,
    },
    keyLabelCallback: {
      type: Function,
      required: false,
      default: undefined,
    },
    keyLabelCallbackTitle: {
      type: Function,
      required: false,
      default: undefined,
    },
    isSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    isSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    isSnapshotChanges: {
      type: Boolean,
      required: false,
    },
    isMoney: {
      type: Boolean,
      required: false,
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
    },
    defaultValue: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
    titleTdClass: {
      type: String,
      required: false,
      default: undefined,
    },
    isSnapshotIconVisible: {
      type: Boolean,
      required: false,
    },
    snapshotIconType: {
      type: String,
      required: false,
      default: "changes",
    },
  },
  computed: {
    idLocal() {
      return `${ this.id }${ this.idSuffixLocal }`;
    },

    idSuffixLocal() {
      if (isArray(this.idSuffix)) {
        return this.idSuffix.join("_");
      }
      return this.idSuffix;
    },

    valueFiltered() {
      if (this.isMoney && this.value !== "-") {
        return this.filterCurrency(this.value);
      }
      return this.value;
    },

    value() {
      if (this.isSnapshotDelete) {
        if (this.isMoney) {
          return this.defaultValue;
        }
        return this.getValue(this.snapshot, false);
      }
      return this.getValue(this.data, false);
    },

    valueSnapshotFiltered() {
      if (this.isMoney && this.valueSnapshot !== "-") {
        return this.filterCurrency(this.valueSnapshot);
      }
      return this.valueSnapshot;
    },

    valueSnapshot() {
      if (this.isMoney && this.isSnapshotAdd) {
        return this.defaultValue;
      }
      return this.getValue(this.snapshot, true);
    },

    isSnapshotValueVisible() {
      if (this.isMoney) {
        if (this.isSnapshotAdd || this.isSnapshotDelete || this.isSnapshotChanges) {
          return true;
        }
      } else if (this.isSnapshotChanges) {
        return true;
      }
      return false;
    },

    extraDate() {
      return {
        date: this.filterDate(this.snapshotDate, "datetime"),
      };
    },

    divMoneyFiltered() {
      return this.filterCurrency(this.diffMoney);
    },

    diffMoney() {
      return this.valueNumber - this.valueSnapshotNumber;
    },

    diffPercentFiltered() {
      let diffPercent = 0;
      if (this.valueSnapshotNumber === 0) {
        if (this.valueNumber !== 0) {
          diffPercent = "-";
          return diffPercent;
        }
      } else {
        diffPercent = this.diffPercent;
      }
      return this.filterCurrency(diffPercent, "%");
    },

    diffPercent() {
      return round(this.diffMoney / this.valueSnapshotNumber * 100, 2);
    },

    valueNumber() {
      const VALUE = this.value === this.defaultValue || this.value === "-" ? 0 : this.value;
      return +VALUE;
    },

    valueSnapshotNumber() {
      const VALUE = this.valueSnapshot === this.defaultValue || this.valueSnapshot === "-" ? 0 : this.valueSnapshot;
      return +VALUE;
    },

    valueTitleFiltered() {
      if (this.keyLabelCallbackTitle) {
        return this.keyLabelCallbackTitle({
          data: this.data,
          extra: this.extra,
          isSnapshot: false,
        });
      }
      return this.valueFiltered;
    },

    valueTitleSnapshotFiltered() {
      if (this.keyLabelCallbackTitle) {
        return this.keyLabelCallbackTitle({
          data: this.snapshot,
          extra: this.extra,
          isSnapshot: true,
          isSnapshotDelete: this.isSnapshotDelete,
          isTitle: true,
        });
      }
      return this.valueSnapshotFiltered;
    },

    keyLabelLocal() {
      if (isArray(this.keyLabel)) {
        return this.keyLabel.join(".");
      }
      return this.keyLabel;
    },
  },
  methods: {
    getValue(data, isSnapshot) {
      let value = undefined;
      if (this.keyLabel) {
        value = get(data, this.keyLabelLocal);
      } else if (isFunction(this.keyLabelCallback)) {
        value = this.keyLabelCallback({
          data,
          extra: this.extra,
          isSnapshot,
          isSnapshotDelete: this.isSnapshotDelete,
        });
      } else if (!isPlainObject(data)) {
        value = data;
      }
      if (!isNil(this.defaultValue) && !value) {
        value = this.defaultValue;
      }
      return value;
    },
  },
};
