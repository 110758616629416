import Modal from "../../global/components/Modal/Modal.vue";
import PuxButton from "../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../global/components/PuxCloak/PuxCloak.vue";
import ReleaseNotesModalElement from "./Element/Element.vue";
import translate from "../../global/directives/translate";
import HttpMixin from "../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "TheReleaseNotesModal",
  components: {
    Modal,
    PuxButton,
    PuxCloak,
    ReleaseNotesModalElement,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    close: {
      type: Function,
      required: true,
      info: "Schießen Modal-Fenster",
    },
  },
  data() {
    return {
      statusLoading: true,
      releaseNotes: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getListHttp({
        url: "releasenotes/",
      }).then(
        response => {
          this.releaseNotes = response;
          this.statusLoading = false;
        }
      );
    },
  },
};
