"use strict";

import template from "./readonly.jade";

class ZielgruppenAuswahlController {
  /*@ngInject*/
  constructor(KZielgruppe) {
    this.zielgruppenChoices = [];
    KZielgruppe.getList().then(
      result => this.zielgruppenChoices = result
    );
  }
}

export default {
  bindings: {
    parameter: "=",
    key: "@"
  },
  template: template(),
  controller: ZielgruppenAuswahlController,
  controllerAs: "vm"
};
