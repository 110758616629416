import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotItem from "../../../Snapshot/SnapshotItem/SnapshotItem.vue";

import translate from "../../../../global/directives/translate";

import KofiErfassungMixin from "../Mixins/KofiErfassungMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import {
  FilterCurrencyMixin,
} from "../../../../global/mixins/FiltersMixin";
import HttpMixin from "../../../../global/mixins/HttpMixin";

import {
  cloneDeep,
  filter,
  get,
  includes,
  isUndefined,
  keyBy,
  size,
  sortBy,
} from "lodash-es";
import {
  initModelFromList,
  toFormElementFromParameter
} from "../../../../global/functions/mappingForParameterToFormElement";

// @vue/component
export default {
  components: {
    PuxButton,
    PuxIcon,
    PuxTranslate,
    SnapshotIcon,
    SnapshotItem,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    KofiErfassungMixin,
    FilterCurrencyMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  props: {
    kofi: {
      type: Object,
      required: true,
      info: "Hauptinformation",
    },
    contextNumber: {
      type: Number,
      required: true,
      info: "Applikationskontextnummer",
    },
    prefixCurrent: {
      type: Object,
      required: true,
      info: "Information vom Kofiart(kosten oder Finanzierung)",
    },
    auszahlung: {
      type: Object,
      required: true,
      info: "Auszahlung",
    },
    auszahlungsplan: {
      type: Object,
      required: false,
      default: undefined,
      info: "Auszahlungsplan",
    },
    vertrag: {
      type: Object,
      required: true,
      info: "Vertrag",
    },
    labelKoFi: {
      type: String,
      required: true,
      validator: value => ["kosten", "finanzierung"].indexOf(value) !== -1,
      info: "Kofi-Hauptschlüssel",
    },
    modul: {
      type: Object,
      required: false,
      default: () => ({}),
      info: "Geschaeftsregel-Modul",
    },
    infoprovider: {
      type: Object,
      required: false,
      default: undefined,
      info: "Infoprovider",
    },
    koFiStatuses: {
      type: Object,
      required: true,
      info: "erfasst, anerkannt, bewilligt",
    },
    statusPositionenShow: {
      type: Boolean,
      required: true,
      info: "Positionen anzeigen",
    },
    apiKoFi: {
      type: String,
      required: true,
      info: "API vertraege/{ VertragId }/auszahlungen/{ AuszahlungId }/{ finanzierung || kosten }/",
    },
    relevanteAuszahlungen: {
      type: Array,
      required: true,
      info: "Alle vorhergehenden Auszahlungen (und die eigene)",
    },
    relevantePerioden: {
      type: Array,
      required: false,
      default: undefined,
      info: "Alle für die Auszahlung relevanten Perioden",
    },
    apiAntrag: {
      type: String,
      required: true,
      info: "API foerderantraege/{{ AntragPk }}/",
    },
    apiAntragDokumente: {
      type: String,
      required: true,
      info: "API foerderantraege/{{ AntragPk }}/dokumente/?dokart={{ dokart }}",
    },
    belegDokumente: {
      type: Array,
      required: true,
      info: "Belegdokumente",
    },
    belegDokumenteAnzahl: {
      type: Object,
      required: false,
      info: "Mapping dok_id zur Anzahl der Belege, in der dieses Dokument benutzt wird",
      default: () => ({}),
    },
    positionen: {
      type: Array,
      required: true,
      info: "Alle Positionen",
    },
    diffKofi: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    snapshot: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    modulNumberDifference: {
      type: Number,
      required: false,
      default: 0,
    },
    showHiddenKoFi: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      statusInfo: false,
      statusHideNumber: false,
      modelParameter: {},
      statusAddPosition: false,
      koFiArtenObj: {},
    };
  },
  computed: {
    htmlIdLocal() {
      return this.kofi.regel_nummer;
    },

    keyKoFiKbez() {
      return `${ this.prefixCurrent.bezeichnung }_kbez`;
    },

    keyKoFiBetragBewilligt() {
      return `${ this.prefixCurrent.vkofi }_betrag_bewilligt`;
    },

    keyKoFiBetragErfasst() {
      return `${ this.prefixCurrent.vkofi }_betrag_erfasst`;
    },

    keyKoFiBetragAnerkannt() {
      return `${ this.prefixCurrent.vkofi }_betrag_anerkannt`;
    },

    keyKoFiAstbez() {
      return `${ this.prefixCurrent.vkofi }_astbez`;
    },

    koFiBetragBewilligt() {
      return this.filterCurrency(this.kofi[this.keyKoFiBetragBewilligt]);
    },

    modulNumber() {
      return `${ this.contextNumber }.${ this.currentNumber }`;
    },

    currentNumber() {
      return this.modulNumberDifference + this.kofi[this.keyKoFiPos];
    },

    modulLabel() {
      return get(this.kofi, `${ this.prefixCurrent.art }.${ this.keyKoFiKbez }`);
    },

    buttonInfoTooltip() {
      return this.statusInfo ?
        "_TXT_FORMSTEP_DETAILS_INFO_I_TITLE_AUS_" :
        "_TXT_FORMSTEP_DETAILS_INFO_I_TITLE_EIN_";
    },

    titleBewilligtGekapptTranslate() {
      return this.kofi.bewilligt_gekappt ?
        "_TXT_KOFI_ERFASSUNG_BEWILLIGT_GEKAPPT_TITLE_" :
        "";
    },

    titleErfasstGekapptTranslate() {
      return this.kofi.erfasst_gekappt ?
        "_TXT_KOFI_ERFASSUNG_ERFASST_GEKAPPT_TITLE_" :
        "";
    },

    titleAnerkanntGekapptTranslate() {
      return this.kofi.anerkannt_gekappt ?
        "_TXT_KOFI_ERFASSUNG_ANERKANNT_GEKAPPT_TITLE_" :
        "";
    },

    betragErfasstFilterView() {
      return this.filterView(this.kofi.erfasst, this.kofi[this.keyKoFiBetragErfasst], this.auszahlung);
    },

    betragAnerkanntFilterView() {
      return this.filterView(this.kofi.anerkannt, this.kofi[this.keyKoFiBetragAnerkannt], this.auszahlung);
    },

    infoText() {
      return this.kofi[this.keyKoFiAstbez];
    },

    ariaDescribedbyForButtons() {
      // TODO: Barrierefreiheit
      return "";
    },

    positionenSorted() {
      return sortBy(this.kofi.positionen || [], [this.keyPositionPos]);
    },

    urlSavePosition() {
      return `${ this.apiKoFi }${ this.kofi.pk }/positionen/`;
    },

    formExtraTranslate() {
      return {
        kofiart: this.modulLabel,
      };
    },

    extraKF() {
      return {
        regel: this.regel,
        regeldata: this.modul.data,
        modelParameter: this.modelParameter,
      };
    },

    modulePrefix() {
      const PREFIXES = {};
      let prefix = "";
      for (const key in this.regel) {
        if (key.indexOf("_") !== -1) {
          const TMP = key.split("_", 1)[0];
          prefix = TMP;
          PREFIXES[TMP] = isUndefined(PREFIXES[TMP]) ? 0 : PREFIXES[TMP] + 1;
        }
      }
      for (const pref in PREFIXES) {
        if (PREFIXES[prefix] < PREFIXES[pref]) {
          prefix = pref;
        }
      }
      return prefix;
    },

    regel() {
      return this.modul.regel || {};
    },

    buttonInfoId() {
      return `${ this.htmlIdLocal }_info_toggle`;
    },

    idForTooltipSumme() {
      const ID_PREFIX = `${ this.htmlIdLocal }_summe_`;
      return {
        bewilligt: `${ ID_PREFIX }bewilligt`,
        erfasst: `${ ID_PREFIX }erfasst`,
        anerkannt: `${ ID_PREFIX }anerkannt`,
      };
    },

    diffChangesKofI() {
      return get(this.diffKofi, `changes.${ this.kofi.pk }`, {}) || {};
    },

    idForBewilligt() {
      return `${ this.htmlIdLocal }_bewilligt`;
    },

    idForErfasst() {
      return `${ this.htmlIdLocal }_erfasst`;
    },

    idForAnerkannt() {
      return `${ this.htmlIdLocal }_anerkannt`;
    },

    buttonAddId() {
      return `${ this.htmlIdLocal }_add_position`;
    },

    keyBezeichnungNachreichenErlaubt() {
      return `${ this.prefixCurrent.bezeichnung }_nachreichen_erlaubt`;
    },

    snapshotPositionen() {
      return this.snapshot.positionen || {};
    },

    diffAddPositionen() {
      if (this.diffKofi.status) {
        return get(this.diffKofi.addPositionen, this.kofi.pk, {});
      }
      return {};
    },

    snapshotPositionenDeleteSorted() {
      return sortBy(this.diffDeletePositionen, [this.keyPositionPos]);
    },

    diffDeletePositionen() {
      if (this.diffKofi.status) {
        return get(this.diffKofi.deletePositionen, this.kofi.pk, []);
      }
      return [];
    },

    diffChangesKofIPeriodenSum() {
      return this.diffChangesKofI.perioden_sum || {};
    },

    statusHasPermissionVertragspositionenCreate() {
      return this.checkPermissionsSync({ perm: this.permissionPositionCreate, permArray: this.auszahlung.user_permissions });
    },

    statusHasPermissionVertragspositionenNachreichen() {
      return this.checkPermissionsSync({ perm: this.permissionPositionNachreichen, permArray: this.auszahlung.user_permissions });
    },

    statusNachreichenErlaubt() {
      return this.kofi[this.prefixCurrent.art] && this.kofi[this.prefixCurrent.art][this.keyBezeichnungNachreichenErlaubt];
    },

    extraSave() {
      const detailLabel = this.labelKoFi === "kosten" ? "kostenartdetail" : "finanzierungsartdetail";
      return {
        [`vertrags${ this.labelKoFi }`]: this.kofi.pk,
        [detailLabel]: null,
      };
    },

    statusShowButtonAdd() {
      return !this.statusAddPosition &&
        (this.statusHasPermissionVertragspositionenCreate ||
          (this.statusNachreichenErlaubt &&
            this.statusHasPermissionVertragspositionenNachreichen));
    },

    apiKoFiArten() {
      if (this.labelKoFi === "kosten") {
        return {
          api: "kostenarten/",
          apiSaveId: "kostenarten"
        };
      } else if (this.labelKoFi === "finanzierung") {
        return {
          api: "finanzierungsarten/",
          apiSaveId: "finanzierungsarten"
        };
      }
      return {};
    },

    kofiArtAvailableOptions() {
      let KOFI_ART_AVAILABLE_OPTIONS = [];
      if (get(this.modul, "modulgruppen[0]") === "kosten") {
        KOFI_ART_AVAILABLE_OPTIONS = get(this.modul, "regel.afr_argument.kostendetails.wert");
      }
      if (get(this.modul, "modulgruppen[0]") === "finanzierung") {
        KOFI_ART_AVAILABLE_OPTIONS = get(this.modul, "regel.afr_argument.finanzierungsdetails.wert");
      }
      return KOFI_ART_AVAILABLE_OPTIONS;
    },

    katalogDetails() {
      const KOFI_ART_PK = get(this.kofi, `${ this.prefixCurrent.art }.pk`);
      const KOFI_ART_OBJECT_CURRENT = this.koFiArtenObj[KOFI_ART_PK];
      if (KOFI_ART_PK && KOFI_ART_OBJECT_CURRENT && KOFI_ART_OBJECT_CURRENT.details) {
        let KOFI_ART_OBJECT_CURRENT_DETAILS = cloneDeep(KOFI_ART_OBJECT_CURRENT.details);
        if (size(this.kofiArtAvailableOptions)) {
          KOFI_ART_OBJECT_CURRENT_DETAILS = filter(KOFI_ART_OBJECT_CURRENT_DETAILS, item => {
            return includes(this.kofiArtAvailableOptions, item.pk);
          });
        }
        return KOFI_ART_OBJECT_CURRENT_DETAILS;
      }
      return [];
    },
  },
  created() {
    this.checkSyConfig();
    this.prepareParameter();
    this.loadKoFiArten();
  },
  methods: {
    checkSyConfig() {
      this.statusHideNumber = this.getSyConfigsValue("kofi_config", {}).nummern_ausblenden || false;
    },

    prepareParameter() {
      const PARAMETER = toFormElementFromParameter({ obj: this.regel[`${ this.modulePrefix }_argument`] });
      const PARAMETER_LIST = PARAMETER.list;
      this.modelParameter = initModelFromList({ list: PARAMETER_LIST });
    },

    toggleStatusInfo() {
      this.statusInfo = !this.statusInfo;
    },

    closeInfoBlock() {
      this.statusInfo = false;
    },

    addPosition() {
      this.statusAddPosition = true;
    },

    saveLocal() {
      this.statusAddPosition = false;
      this.$attrs.onReloadKofi();
    },

    closeLocal() {
      this.statusAddPosition = false;
    },

    loadKoFiArten() {
      this.getListHttp({
        url: this.apiKoFiArten.api,
        apiSaveId: this.apiKoFiArten.apiSaveId,
      }).then(
        response => {
          this.koFiArtenObj = keyBy(response, "pk");
        }
      );
    },
  },
};
