import AngularLink from "../../../../../../../libs/vue/client/vue/global/components/AngularLink/AngularLink.vue";
import FormstepDetail from "../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";

// @vue/component
export default {
  name: "StudiengangDetailsHochschule",
  components: {
    AngularLink,
    FormstepDetail,
  },
  props: {
    hochschuleObj: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      optionsForFormstepDetails: {
        hideHeader: true,
      },
    };
  },
};
