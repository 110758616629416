import {
  computed,
  ref,
  toRef,
} from "vue";

import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import {
  cloneDeep,
  forEach,
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "GeschaeftsregelFunktionsgruppenBearbeitung",
  components: {
    FormElement,
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    regel: {
      type: Object,
      required: true,
    },
    regelUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "updateRegel",
  ],
  setup(props, { emit }) {
    const regel = toRef(props, "regel");
    const regelUrl = toRef(props, "regelUrl");

    const editRechte = computed(() => {
      return regel.value.re_edit_rechte || [];
    });

    const rechteChoices = computed(() => {
      const LIST = [];
      forEach(regel.value.rechte_choices, item => {
        LIST.push({
          value: item[0],
          label: item[1],
        });
      });
      return LIST;
    });

    const rechteChoicesMapping = computed(() => {
      return keyBy(rechteChoices.value, "value");
    });

    const model = ref([]);
    const errors = ref(undefined);
    const open = () => {
      model.value = cloneDeep(regel.value.re_edit_rechte) || [];
    };
    const {
      putHttp,
    } = HttpAPI();
    const {
      addNotification,
    } = NotificationAPI();
    const deleteErrors = () => {
      errors.value = undefined;
    };
    const save = () => {
      deleteErrors();
      return new Promise((resolve, reject) => {
        putHttp({
          url: regelUrl.value,
          data: {
            re_edit_rechte: model.value,
          },
        }).then(
          response => {
            addNotification({ text: "_MSG_GR_DETAILS_FUNKTIONSGRUPPEN_SUCCESS_" });
            emit("updateRegel", response);
            resolve();
          },
          error => {
            errors.value = error.data;
            reject(error);
          },
        );
      });
    };

    const optionsFormstepDetail = {
      label: "_TXT_GR_DETAILS_BEARBEITUNG_FUNKTIONSGRUPPEN_",
      openCallback: open,
      saveCallback: regel.value.rechte_choices.length ? save : undefined,
      required: true,
    };

    const optionsFunktionsgruppen = {
      type: "checkbox",
      label: "_TXT_GR_DETAILS_FUNKTIONSGRUPPEN_",
      view: "v-alt",
      id: "re_edit_rechte",
    };

    const errorsLabelsOptions = [
      optionsFunktionsgruppen,
    ];

    return {
      editRechte,
      errors,
      errorsLabelsOptions,
      model,
      optionsFormstepDetail,
      optionsFunktionsgruppen,
      rechteChoices,
      rechteChoicesMapping,
    };
  },
};
