import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  cloneDeep,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "ProfilDetailsNachrichtenTr",
  components: {
    FormElement,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    data: {
      type: Object,
      required: true,
      info: "Hauptinformation",
    },
    statusEdit: {
      type: Boolean,
      required: true,
      info: "Status füe Edit-Modus",
    },
    nutzerEinstellungen: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    index: {
      type: Number,
      required: false,
      default: undefined,
      info: "Index in Array",
    },
    model: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    idForSecondTh: {
      type: String,
      required: true,
    },
    idForThirdTh: {
      type: String,
      required: true,
    },
  },
  emits: [
    "changeModel",
  ],
  computed: {
    benachrichtigungEinstellungMailIcon() {
      return getBenachrichtigungEinstellungMailIcon({
        benachrichtigungstyp: this.data,
        nutzerEinstellungen: this.nutzerEinstellungen,
      });
    },

    benachrichtigungEinstellungBenIcon() {
      return getBenachrichtigungEinstellungBenIcon({
        benachrichtigungstyp: this.data,
        nutzerEinstellungen: this.nutzerEinstellungen,
      });
    },

    einstellungKey() {
      return this.data.einstellung_key;
    },

    translateBenachtichtigungenArtBen() {
      return this.getTitleBenachtichtigungen({ art: "ben", editmode: this.statusEdit });
    },

    translateBenachtichtigungenArtMail() {
      return this.getTitleBenachtichtigungen({ art: "mail", editmode: this.statusEdit });
    },

    labelId() {
      return `bnt_eintellung_${ this.index }`;
    },

    optionsBen() {
      return {
        id: "ben",
        htmlId: `checkbox_ben_${ this.index }`,
        type: "one_checkbox",
        disabled: !this.data.bnt_ben_abbestellbar,
        name: "checkbox_bnt_eintellung",
        className: "invisible-label",
        attributesHtml: {
          "aria-labelledby": `${ this.labelId } ${ this.idForSecondTh }`,
        },
      };
    },

    optionsMail() {
      return {
        id: "mail",
        htmlId: `checkbox_mail_${ this.index }`,
        type: "one_checkbox",
        disabled: !this.data.bnt_mail_abbestellbar,
        name: "checkbox_bnt_eintellung",
        className: "invisible-label",
        attributesHtml: {
          "aria-labelledby": `${ this.labelId } ${ this.idForThirdTh }`,
        },
      };
    },

    extraForBenachtichtigungen() {
      return {
        kbez: this.data.bnt_kbez,
      };
    },

    attributesForFirstTd() {
      if (this.data.bnt_beschreibung) {
        return {
          title: this.data.bnt_beschreibung,
        };
      }
      return {};
    },
  },
  methods: {
    changeModel({ model, options }) {
      const MODEL = cloneDeep(this.model);
      MODEL[options.id] = model;
      this.$emit("changeModel", {
        id: this.einstellungKey,
        value: MODEL,
      });
    },

    getTitleBenachtichtigungen({ art, editmode } = {}) {
      if (!this.nutzerEinstellungen || !this.nutzerEinstellungen[this.data.einstellung_key]) {
        return;
      }
      if (art === "ben") {
        return this.getTitleBenachtichtigungenArtBen({ editmode });
      }
      if (art === "mail") {
        return this.getTitleBenachtichtigungenArtMail({ editmode });
      }
    },

    getTitleBenachtichtigungenArtBen({ editmode }) {
      if (editmode && !(this.data.bnt_ben_abbestellbar && this.data.konfigurierbar)) {
        return "_LBL_PROFIL_DETAILS_NACHRICHTEN_SYSTEM_NOT_CONFIGURABLE_{{kbez}}_";
      } else if (this.data.bnt_ben_abbestellbar && !this.nutzerEinstellungen[this.data.einstellung_key].ben) {
        return "_LBL_PROFIL_DETAILS_NACHRICHTEN_SYSTEM_NO_{{kbez}}_";
      }
      return "_LBL_PROFIL_DETAILS_NACHRICHTEN_SYSTEM_YES_{{kbez}}_";
    },

    getTitleBenachtichtigungenArtMail({ editmode }) {
      if (!this.data.mail_vorlage) {
        return "_LBL_PROFIL_DETAILS_NACHRICHTEN_EMAIL_NOT_SENT_{{kbez}}_";
      } else if (this.data.bnt_mail_abbestellbar && !this.nutzerEinstellungen[this.data.einstellung_key].mail) {
        return "_LBL_PROFIL_DETAILS_NACHRICHTEN_EMAIL_NO_{{kbez}}_";
      } else if (editmode && !(this.data.bnt_mail_abbestellbar && this.data.konfigurierbar)) {
        return "_LBL_PROFIL_DETAILS_NACHRICHTEN_EMAIL_NOT_CONFIGURABLE_{{kbez}}_";
      }
      return "_LBL_PROFIL_DETAILS_NACHRICHTEN_EMAIL_YES_{{kbez}}_";
    },
  },
};

export function getBenachrichtigungEinstellungMailIcon({ benachrichtigungstyp, nutzerEinstellungen }) {
  const EINSTELLUNG_KEY = benachrichtigungstyp.einstellung_key;
  if (benachrichtigungstyp.mail_vorlage &&
    (!benachrichtigungstyp.bnt_mail_abbestellbar ||
      get(nutzerEinstellungen, [EINSTELLUNG_KEY, "mail"]))) {
    return "check";
  }
  if (benachrichtigungstyp.mail_vorlage &&
    benachrichtigungstyp.bnt_mail_abbestellbar &&
    !get(nutzerEinstellungen, [EINSTELLUNG_KEY, "mail"])) {
    return "unchecked";
  }
  return "";
}

export function getBenachrichtigungEinstellungBenIcon({ benachrichtigungstyp, nutzerEinstellungen }) {
  const EINSTELLUNG_KEY = benachrichtigungstyp.einstellung_key;
  if (benachrichtigungstyp.benachrichtigung_vorlage &&
    (!benachrichtigungstyp.bnt_ben_abbestellbar ||
      get(nutzerEinstellungen, [EINSTELLUNG_KEY, "ben"]))) {
    return "check";
  }
  if (benachrichtigungstyp.benachrichtigung_vorlage &&
    benachrichtigungstyp.bnt_ben_abbestellbar &&
    !get(nutzerEinstellungen, [EINSTELLUNG_KEY, "ben"])) {
    return "unchecked";
  }
  return "";
}
