import ContactUserEdit from "../../../Geschaeftsregel/Module/AntragstellerAuswahl/ContactUserEdit/ContactUserEdit.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import NotificationMixin from "../../../../global/mixins/NotificationMixin";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import {
  EventBus,
} from "../../../../global/functions/event-bus";

import {
  gettext,
} from "../../../../global/functions/utils";

import {
  cloneDeep,
  findIndex,
  forEach,
  get,
  isNil,
  toNumber,
} from "lodash-es";


// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    ContactUserEdit,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    SyConfigMixin,
  ],
  props: {
    oldAnsprechpartners: {
      type: Array,
      required: true,
      info: "Ansprechpartners, die sind im Antrag",
    },
    antragPk: {
      type: String,
      required: true,
      info: "Antrag UUID",
    },
    astPk: {
      type: String,
      required: true,
      info: "Antragstellerorganisation UUID",
    },
    dufoPk: {
      type: String,
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      required: true,
    },
    selectorClose: {
      type: [String, Array],
      required: true,
    },
  },
  data() {
    return {
      contactUsersEdit: [],
      dufo: [],
      contactUsersForEdit: [],
      contactUsersCount: undefined,
      errors: undefined,
      errorsClone: undefined,
      options: {
        showCloseButton: true,
        required: true,
      },
      status: {
        loading: undefined,
      },
    };
  },
  computed: {
    dufoLength() {
      return this.dufo.length;
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.checkSyConfig();
    this.initModel();
  },
  methods: {
    checkSyConfig() {
      const MAX_ANSPRECHPARTNER = this.getSyConfigsValue("max_ansprechpartner") || {};
      this.contactUsersCount = !isNil(MAX_ANSPRECHPARTNER.astorg) ? toNumber(MAX_ANSPRECHPARTNER.astorg) : 99;
    },

    initModel() {
      const modelEdit = {};
      modelEdit.ast = this.astPk;
      for (let i = 0; i < this.contactUsersCount; i++) {
        if (i <= this.oldAnsprechpartners.length - 1) {
          modelEdit[`asp_anu_${ i }`] = this.checkUser(i);
          modelEdit[`asp_kommentar_${ i }`] = this.checkKommentar(i);
        }
      }
      this.modelEdit = modelEdit;
      this.loadUsers();
      this.loadDufo();
    },

    loadUsers() {
      this.status.loading = true;
      this.getListHttp({
        url: `antragstellerorganisationen/${ this.astPk }/nutzer/`,
        urlParams: {
          aktiv: true,
          fields: ["status", "nutzer_status", "pk", "name"],
        },
      }).then(
        response => {
          this.contactUsersEdit = response;
          this.setContactUsersForEdit();
          this.status.loading = false;
        }
      );
    },

    loadDufo() {
      this.getListHttp({
        url: `antragstellerorganisationen/${ this.astPk }/durchfuehrendeorgeinheiten/`
      }).then(
        response => {
          this.dufo = response;
        }
      );
    },


    changeModelContactUser({ contactUser, index }) {
      const MODEL_EDIT = cloneDeep(this.modelEdit);
      MODEL_EDIT[`asp_anu_${ index }`] = contactUser;
      this.modelEdit = MODEL_EDIT;
      this.setContactUsersForEdit();
    },

    changeModelComment({ comment, index }) {
      const MODEL_EDIT = cloneDeep(this.modelEdit);
      MODEL_EDIT[`asp_kommentar_${ index }`] = comment;
      this.modelEdit = MODEL_EDIT;
    },

    setContactUsersForEdit() {
      const SELECTED_USERS = [];
      for (let i = 0; i < this.contactUsersCount; i++) {
        SELECTED_USERS[i] = cloneDeep(this.contactUsersEdit);
        for (let j = 0; j < this.contactUsersCount; j++) {
          if (i === j) {
            continue;
          }
          const CURRENT_USER_KEY = `asp_anu_${ j }`;
          const CURRENT_MODEL = this.modelEdit[CURRENT_USER_KEY];
          if (CURRENT_MODEL) {
            const INDEX = findIndex(SELECTED_USERS[i], ["pk", CURRENT_MODEL]);
            if (INDEX !== -1) {
              SELECTED_USERS[i].splice(INDEX, 1);
            }
          }
        }
      }
      this.contactUsersForEdit = SELECTED_USERS;
    },

    checkUser(index) {
      return get(this.oldAnsprechpartners[index], "astorg_nutzer", undefined);
    },

    checkKommentar(index) {
      return get(this.oldAnsprechpartners[index], "asp_kommentar", undefined);
    },

    checkForEmptyCommentAndExistingValue(data, key, val, index) {
      return key.indexOf("asp_kommentar_") !== -1 && !val && Boolean(data[`asp_anu_${ index }`]);
    },

    checkForMissingValue(key, val) {
      return key.indexOf("asp_anu_") !== -1 && !val;
    },

    prepareDataForSave() {
      const dataForSave = cloneDeep(this.modelEdit);
      forEach(dataForSave, (val, key) => {
        const index = key.match("\\d");

        if (this.checkForEmptyCommentAndExistingValue(dataForSave, key, val, index)) {
          if (index > 0) {
            dataForSave[key] = gettext(`_LBL_GR_ANTRAGSTELLER_AUSWAHL_ANSPRECHPARTNER_${ +index + 1 }_`);
          }
        }

        if (this.checkForMissingValue(key, val) && index > 0) {
          delete dataForSave[key];
          delete dataForSave[`asp_kommentar_${ index }`];
        }
      });
      this.modelEdit = dataForSave;
    },

    save() {
      this.status.loading = true;
      this.prepareDataForSave();
      const DATA = cloneDeep(this.modelEdit);
      DATA.ast_is_dufo = !this.dufoLength;
      if (!DATA.ast_is_dufo) {
        DATA.dufo = this.dufoPk;
      }
      return this.postHttp({
        url: `foerderantraege/${ this.antragPk }/astorg_ansprechpartner_aendern/`,
        data: DATA,
      }).then(
        () => {
          this.addNotification({
            text: `_MSG_ANTRAG_DETAILS_ANSPRECHPARTNERS_MODAL_UPDATE_SUCCESS_`,
          });
          EventBus.$emit("antragsteller.update", false, true);
          this.errors = undefined;
          this.close();
        },
        error => {
          if (error.status !== 429) {
            this.errors = error.data;
          }
        }
      ).finally(
        () => this.status.loading = false
      );
    },
  },
};
