"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import startComponent from "./start.component";

const startModule = angular.module("dias.start", [
  uiRouter,
]);

startModule.config($stateProvider => {
  $stateProvider
    .state("root.home", {
      url: "/?m&next",
      views: {
        "main@root": {
          template: "<start></start>"
        }
      }
    });
})

  .component("start", startComponent);

export default startModule;
