import BetragsrechnerMixin from "../../Mixins/BetragsrechnerMixin";
import KumastaBetragsrechnerMixin from "../KumastaBetragsrechnerMixin";

// @vue/component
export default {
  name: "KumastaEhrenamtskostenrechner",
  mixins: [
    BetragsrechnerMixin,
    KumastaBetragsrechnerMixin,
  ],
  data() {
    return {
      labelBegruendung: "_LBL_EHRENAMTSKOSTENRECHNER_BEGRUENDUNG_",
      labelZeiteinheit: "Zeiteinheit",
      labelAnzahlZeiteinheiten: "Anzahl Zeiteinheiten",
      labelKostenProZeiteinheit: "Honorarsatz pro Zeiteinheit in Euro",
      labelWert: "Betrag in EUR",
    };
  },
  computed: {
    optionsBegruendung() {
      return {
        id: "begruendung",
        htmlId: this.htmlIdBegruendung,
        label: this.labelBegruendung,
        view: this.view,
        type: "text",
        maxlength: 300,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdBegruendung() {
      return `${ this.htmlId }_begruendung`;
    },

    optionsZeiteinheit() {
      return {
        id: "zeiteinheit",
        htmlId: this.htmlIdZeiteinheit,
        label: this.labelZeiteinheit,
        view: this.view,
        type: "select",
        required: true,
        data: [
          {
            label: "Stunde(n)",
            value: "h",
          },
          {
            label: "Tag(e)",
            value: "d",
          },
        ],
        keyLabel: "label",
        keyId: "value",
        search: true,
        searchList: ["label"],
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdZeiteinheit() {
      return `${ this.htmlId }_zeiteinheit`;
    },

    optionsAnzahlZeiteinheiten() {
      return {
        id: "anzahl_zeiteinheiten",
        htmlId: this.htmlIdAnzahlZeiteinheiten,
        label: this.labelAnzahlZeiteinheiten,
        view: this.view,
        type: "float",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdAnzahlZeiteinheiten() {
      return `${ this.htmlId }_anzahl_zeiteinheiten`;
    },

    optionsKostenProZeiteinheit() {
      return {
        id: "kosten_pro_zeiteinheit",
        htmlId: this.htmlIdKostenProZeiteinheit,
        label: this.labelKostenProZeiteinheit,
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 9,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdKostenProZeiteinheit() {
      return `${ this.htmlId }_kosten_pro_zeiteinheit`;
    },

    optionsWert() {
      return {
        id: "wert",
        htmlId: this.htmlIdWert,
        label: this.labelWert,
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdWert() {
      return `${ this.htmlId }_wert`;
    },

    errorLabels() {
      return {
        projekt: {
          label: this.labelProjekt,
          link: true,
          id: this.htmlIdProjekt,
        },
        begruendung: {
          label: this.labelBegruendung,
          link: true,
          id: this.htmlIdBegruendung,
        },
        zeiteinheit: {
          label: this.labelZeiteinheit,
          link: true,
          id: this.htmlIdZeiteinheit,
        },
        anzahl_zeiteinheiten: {
          label: this.labelAnzahlZeiteinheiten,
          link: true,
          id: this.htmlIdAnzahlZeiteinheiten,
        },
        kosten_pro_zeiteinheit: {
          label: this.labelKostenProZeiteinheit,
          link: true,
          id: this.htmlIdKostenProZeiteinheit,
        },
        wert: {
          label: this.labelWert,
          link: true,
          id: this.htmlIdWert,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
  },
};
