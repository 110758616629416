import NachrichtenListCellEmpfaenger from "./NachrichtenListCellEmpfaenger/NachrichtenListCellEmpfaenger.vue";
import NachrichtenListCellObject from "./NachrichtenListCellObject/NachrichtenListCellObject.vue";
import NachrichtenListDetails from "../NachrichtenListDetails/NachrichtenListDetails.vue";
import NachrichtenModalComment from "../NachrichtenModalComment/NachrichtenModalComment.vue";
import NachrichtenModalCreate from "../NachrichtenModalCreate/NachrichtenModalCreate.vue";
import NachrichtenModalDelete from "../NachrichtenModalDelete/NachrichtenModalDelete.vue";
import NutzerschlagwortBadge from "../../Nutzerschlagwort/NutzerschlagwortBadge/NutzerschlagwortBadge.vue";
import NutzerschlagwortObjektModalAdd from "../../Nutzerschlagwort/NutzerschlagwortObjektModalAdd/NutzerschlagwortObjektModalAdd.vue";
import NutzerschlagwortObjektModalEdit from "../../Nutzerschlagwort/NutzerschlagwortObjektModalEdit/NutzerschlagwortObjektModalEdit.vue";
import NutzerschlagwortObjektModalRemove from "../../Nutzerschlagwort/NutzerschlagwortObjektModalRemove/NutzerschlagwortObjektModalRemove.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTooltip from "../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import { EventBus } from "../../../global/functions/event-bus";

import getTableApi from "../../../const/TableApis";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../global/compositionAPI/NotificationAPI";
import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";
import PermissionAPI from "../../../global/compositionAPI/PermissionAPI";

import {
  gettext,
  getTextFromPlaceholder,
  replaceText,
} from "../../../global/functions/utils";

import {
  get,
  pick,
  size,
} from "lodash-es";

const BEZUEGE = [
  { property: "antrag", pk: "a_id" },
  { property: "antragsteller", pk: "ast_id" },
  { property: "sitzung", pk: "si_id" },
  { property: "top", pk: "tos_id" },
  { property: "vertrag", pk: "v_id" },
  { property: "auszahlung", pk: "az_id" },
  { property: "sammelvertrag", pk: "sv_id" },
  { property: "formular", pk: "for_id" },
];

// @vue/component
export default {
  name: "NachrichtenList",
  components: {
    NachrichtenListCellEmpfaenger,
    NachrichtenListCellObject,
    NachrichtenListDetails,
    NachrichtenModalComment,
    NachrichtenModalCreate,
    NachrichtenModalDelete,
    NutzerschlagwortBadge,
    NutzerschlagwortObjektModalAdd,
    NutzerschlagwortObjektModalEdit,
    NutzerschlagwortObjektModalRemove,
    PageTabTitle,
    PuxIcon,
    PuxTooltip,
    PuxTranslate,
    SmartTable,
  },
  props: {
    tableId: {
      type: String,
      default: undefined,
    },
    tableHeader: {
      type: String,
      default: undefined,
    },
    pageHeader: {
      type: String,
      default: undefined,
    },
    pageTitlePlaceholder: {
      type: String,
      required: true,
      info: "Text für <title>",
    },
    rootDetails: {
      type: String,
      default: "root.benachrichtigungen.detail",
    },
  },
  setup() {
    const {
      filterDate,
      filterLimitTo,
      filterSpaced,
    } = FiltersAPI();

    const {
      patchHttp,
      postHttp,
    } = HttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      checkPermissionsSync,
      patchHttp,
      filterDate,
      filterLimitTo,
      filterSpaced,
      postHttp,
    };
  },
  data() {
    return {
      modal: {
        create: undefined,
        delete: undefined,
        comment: undefined,
        nutzerschlagwortobjektEdit: undefined,
        nutzerschlagwortobjektMassActionAdd: undefined,
        nutzerschlagwortobjektMassActionRemove: undefined,
      },
      extrasModalCreate: undefined,
      currentIndex: undefined,
      currentNachricht: undefined,
      selectedRows: undefined,
    };
  },
  computed: {
    options() {
      return {
        id: this.tableId,
        url: getTableApi({ id: this.tableId }),
        rowActions: [
          {
            label: "_TXT_NACHRICHTEN_TABLE_GELESEN_MARKIEREN_BTN_",
            callback: this.markRead,
            isHidden: ({ item }) => item.bnn_gelesen,
          },
          {
            label: "_TXT_NACHRICHTEN_TABLE_UNGELESEN_MARKIEREN_BTN_",
            callback: this.markUnread,
            isHidden: ({ item }) => !item.bnn_gelesen,
          },
          {
            label: "_TXT_NACHRICHTEN_TABLE_KOMMENTAR_UPDATE_BTN_",
            callback: this.openModalKommentar,
          },
          {
            label: "_TXT_NACHRICHTEN_TABLE_NUTZERSCHLAGWORTOBJEKT_UPDATE_BTN_",
            callback: this.openModalNutzerschlagwortobjektEdit,
            isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          },
          {
            label: "_TXT_NACHRICHTEN_TABLE_ANTWORTEN_BTN_",
            callback: this.replyToNachricht,
            isHidden: ({ item }) => !item.autor || !item.autor.aktiv,
          },
          {
            label: "_TXT_NACHRICHTEN_TABLE_WEITERLEITEN_BTN_",
            callback: this.forwardNachricht,
          },
          {
            label: "_TXT_NACHRICHTEN_TABLE_LOESCHEN_BTN_",
            callback: this.removeNachricht,
          },
          {
            labelCallback: this.showAntragsLabel,
            route: "root.foerderantrag.details",
            routeParameters: [{ routeId: "id", rowPath: "objekt.a_id" }],
            isHidden: ({ item }) => item.objekt.type !== "antrag",
          },
          {
            labelCallback: this.showAntragstellerLabel,
            route: "root.astorganisationen.details",
            routeParameters: [{ routeId: "id", rowPath: "objekt.ast_id" }],
            isHidden: ({ item }) => item.objekt.type !== "antragsteller",
          },
          {
            labelCallback: this.showSitzungLabel,
            route: "root.sitzungen.details",
            routeParameters: [{ routeId: "id", rowPath: "objekt.si_id" }],
            isHidden: ({ item }) => item.objekt.type !== "sitzung",
          },
          {
            labelCallback: this.showTopLabel,
            route: "root.sitzungen.details.top",
            routeParameters: [
              { routeId: "id", rowPath: "objekt.si_id" },
              { routeId: "tos_id", rowPath: "objekt.tos_id" },
            ],
            isHidden: ({ item }) => item.objekt.type !== "top",
          },
          {
            labelCallback: this.showVertragLabel,
            route: "root.vertrag.details",
            routeParameters: [{ routeId: "id", rowPath: "objekt.v_id" }],
            isHidden: ({ item }) => item.objekt.type !== "vertrag",
          },
          {
            labelCallback: this.showAuszahlungLabel,
            route: "root.vertrag.details.auszahlung",
            routeParameters: [
              { routeId: "id", rowPath: "objekt.v_id" },
              { routeId: "aus_id", rowPath: "objekt.az_id" },
            ],
            isHidden: ({ item }) => item.objekt.type !== "auszahlung",
          },
          {
            labelCallback: this.showSammelvertragLabel,
            route: "root.sammelvertrag.details",
            routeParameters: [{ routeId: "id", rowPath: "objekt.sv_id" }],
            isHidden: ({ item }) => item.objekt.type !== "sammelvertrag",
          },
          {
            labelCallback: this.showFormularLabel,
            route: "root.basisformularcortex.detail",
            routeParameters: [{ routeId: "id", rowPath: "objekt.for_id" }],
            isHidden: ({ item }) => item.objekt.type !== "formular" || (item.objekt.cortex !== null && item.objekt.cortex !== "basisformularcortex"),
          },
          {
            labelCallback: this.showFormularLabel,
            route: "root.auszahlungslisten.details",
            routeParameters: [{ routeId: "id", rowPath: "objekt.for_id" }],
            isHidden: ({ item }) => item.objekt.type !== "formular" || item.objekt.cortex !== "cortex_auszahlungsliste",
          },
          {
            labelCallback: this.showUmfrageLabel,
            route: "root.umfragecortex.detail",
            routeParameters: [{ routeId: "id", rowPath: "objekt.for_id" }],
            isHidden: ({ item }) => item.objekt.type !== "formular" || item.objekt.cortex !== "umfragecortex",
          },
          {
            label: "_TXT_NACHRICHTEN_TABLE_PRINT_BTN_",
            route: this.rootDetails,
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [
          {
            permission: "benachrichtigungen.contextfree.create",
            label: "_TXT_NACHRICHTEN_TABLE_CREATE_MODAL_BTN_",
            callback: this.createNachricht,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "nachricht_create",
          },
        ],
        massActions: [{
          label: "_TXT_NACHRICHTEN_TABLE_MASS_LOESCHEN_BTN_",
          callback: ({ pks }) => this.deleteNachrichten({ pks }),
        }],
        label: this.tableHeader,
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
        },
      };
    },

    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },

    nutzerschlagwortModalEditTitleTranslation() {
      return {
        text: "_TXT_NACHRICHTENLISTE_NUTZERSCHLAGWORT_MODAL_EDIT_TITLE_"
      };
    },

    nutzerschlagwortModalEditMsgTranslation() {
      return {
        html: "_HTML_NACHRICHTENLISTE_NUTZERSCHLAGWORT_MODAL_EDIT_INFO_"
      };
    },

    nutzerschlagwortModalMassActionAddTitleTranslation() {
      return {
        text: "_TXT_NACHRICHTENLISTE_NUTZERSCHLAGWORT_MODAL_MANY_ADD_TITLE_"
      };
    },

    nutzerschlagwortModalMassActionAddMsgTranslation() {
      return {
        html: "_HTML_NACHRICHTENLISTE_NUTZERSCHLAGWORT_MODAL_MANY_ADD_INFO_{{anz}}_",
        extra: {
          anz: size(this.selectedRows) || 0,
        }
      };
    },

    nutzerschlagwortModalMassActionRemoveTitleTranslation() {
      return {
        text: "_TXT_NACHRICHTENLISTE_NUTZERSCHLAGWORT_MODAL_MANY_REMOVE_TITLE_"
      };
    },

    nutzerschlagwortModalMassActionRemoveMsgTranslation() {
      return {
        html: "_HTML_NACHRICHTENLISTE_NUTZERSCHLAGWORT_MODAL_MANY_REMOVE_INFO_{{anz}}_",
        extra: {
          anz: size(this.selectedRows) || 0,
        }
      };
    },

    pageTitle() {
      return this.pageTitlePlaceholder || "_PAGE_TITLE_NACHRICHTEN_LIST_";
    }
  },
  created() {
    this.initRestFunctions();
  },
  methods: {
    initMassActions() {
      if (this.checkPermissionsSync({ perm: "nutzerschlagworte.update" })) {
        this.options.massActions.push({
          label: "_TXT_NACHRICHTEN_TABLE_MASS_ACTIONS_NUTZERSCHLAGWORT_ADD_BTN_",
          callback: ({ rows }) => this.openModalNutzerschlagwortobjektMassActionAdd({ rows }),
          isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          modal: true,
        });
        this.options.massActions.push({
          label: "_TXT_NACHRICHTEN_TABLE_MASS_ACTIONS_NUTZERSCHLAGWORT_REMOVE_BTN_",
          callback: ({ rows }) => this.openModalNutzerschlagwortobjektMassActionRemove({ rows }),
          isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          modal: true,
        });
      }
    },

    initRestFunctions() {
      this.initMassActions();
    },

    createNachricht({ row, modus } = {}) {
      let title = getTextFromPlaceholder("_TXT_NACHRICHTEN_LIST_MESSAGE_SEND_");
      let feste_empfaenger = null;
      let titel = null;
      let text = null;
      let bezug = null;
      let bezugpk = null;
      let weiterleitung = null;
      let antwort = null;

      if (modus === "reply") {
        title = replaceText({
          text: gettext("_TXT_NACHRICHTEN_LIST_ANSWER_TO_{{name}}_"),
          object: {
            name: row.autor.name,
          },
        });
        feste_empfaenger = [row.autor];
        titel = `Re: ${ row.bnn_titel }`;
        text = this.getNachrichtInfo(row);
        antwort = row.pk;
      } else if (modus === "forward") {
        title = getTextFromPlaceholder("_TXT_NACHRICHTEN_LIST_MESSAGE_FORWARD_");
        titel = `Fw: ${ row.bnn_titel }`;
        text = this.getNachrichtInfo(row);
        weiterleitung = row.pk;
      }
      if (row) {
        for (let i = 0; i < BEZUEGE.length; i++) {
          const bez = BEZUEGE[i];
          if (row && get(row, "objekt.type") === bez.property) {
            bezug = bez.property;
            bezugpk = row.objekt[bez.pk];
            break;
          }
        }
      }

      this.extrasModalCreate = {
        title,
        feste_empfaenger,
        titel,
        text,
        bezug,
        bezugpk,
        weiterleitung,
        antwort,
      };

      this.modal.create = true;
    },

    getNachrichtInfo(nachricht) {
      let autorname = getTextFromPlaceholder("_TXT_NACHRICHTEN_LIST_DAS_ANTRAGSSYSTEM_");
      if (nachricht.autor) {
        autorname = nachricht.autor.name;
      }
      const infotext = `
        <p>&nbsp;</p><p>--------------------------------------------------------------</p>
        Am ${ this.filterDate(nachricht.crdate, "dddd, DD. MMMM YYYY, HH:mm [Uhr]") } schrieb ${ autorname }: ${ nachricht.bnn_text }
      `;
      return infotext;
    },

    renderGelesen({ row }) {
      let className;
      if (row.bnn_gelesen && !row.bnn_weitergeleitet && !row.bnn_beantwortet) {
        className = "glyphicon-message-read";
      } else if (!row.bnn_gelesen && !row.bnn_weitergeleitet && !row.bnn_beantwortet) {
        className = "glyphicon-eingegangen";
      } else if (row.bnn_weitergeleitet || row.bnn_beantwortet) {
        className = "glyphicon-gesendet";
      }
      return `<i class="glyphicon ${ className }"></i>`;
    },

    renderTitel({ row }) {
      return `${ !row.bnn_gelesen ? `<strong>${ row.bnn_titel }</strong>` : `<span>${ row.bnn_titel }</span>` }`;
    },

    showKommentar({ row }) {
      return size(row.bnn_kommentar) > 0;
    },

    closeModalKommentar({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.comment = false;
    },

    openModalKommentar({ item }) {
      this.currentNachricht = item;
      this.modal.comment = true;
    },

    closeModalCreate({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.create = false;
    },

    closeModalNutzerschlagwortobjektEdit({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektEdit = false;
    },

    openModalNutzerschlagwortobjektEdit({ item }) {
      this.currentNachricht = item;
      this.modal.nutzerschlagwortobjektEdit = true;
    },

    closeModalNutzerschlagwortobjektMassActionAdd({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektMassActionAdd = false;
      EventBus.$emit(`cancelMassActions${ this.tableId }`, { status });
    },

    openModalNutzerschlagwortobjektMassActionAdd({ rows }) {
      this.selectedRows = rows;
      this.modal.nutzerschlagwortobjektMassActionAdd = true;
    },

    closeModalNutzerschlagwortobjektMassActionRemove({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektMassActionRemove = false;
      EventBus.$emit(`cancelMassActions${ this.tableId }`, { status });
    },

    openModalNutzerschlagwortobjektMassActionRemove({ rows }) {
      this.selectedRows = rows;
      this.modal.nutzerschlagwortobjektMassActionRemove = true;
    },

    deleteNachrichten({ pks }) {
      return this.postHttp({
        url: `${ this.options.url }batch_delete/`,
        data: { pks },
      }).then(
        () => this.addNotification({ text: "_MSG_NACHRICHTENLIST_BATCH_DELETE_SUCCESS_" }),
        () => this.addNotification({ text: "_MSG_NACHRICHTENLIST_BATCH_DELETE_ERROR_", type: "error" })
      );
    },

    markRead({ item, index }) {
      this.markRow({ item, index, status: true });
    },

    markUnread({ item, index }) {
      this.markRow({ item, index, status: false });
    },

    markRow({ item, index, status }) {
      const data = pick(item, ["pk", "bnn_gelesen"]);
      if (data.bnn_gelesen === status) {
        return;
      }
      data.bnn_gelesen = status;
      this.patchHttp({ url: `${ this.options.url }${ item.pk }/`, data }).then(
        response => {
          EventBus.$emit(`updateRow${ this.tableId }`, ({ row: response, index }));
        }
      );
    },

    replyToNachricht({ item }) {
      this.createNachricht({ modus: "reply", row: item });
    },

    forwardNachricht({ item }) {
      this.createNachricht({ modus: "forward", row: item });
    },

    removeNachricht({ item }) {
      this.currentNachricht = item;
      this.modal.delete = true;
    },

    closeModalDelete({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.delete = false;
    },

    showAntragstellerLabel({ item }) {
      return replaceText({
        text: gettext("_TXT_NACHRICHTEN_LIST_ANTRAGSTELLER_LABEL_{{ast_name}}_"),
        object: {
          ast_name: this.filterLimitTo(item.objekt.ast_name, 50),
        }
      },);
    },

    showAntragsLabel({ item }) {
      return replaceText({
        text: gettext("_TXT_NACHRICHTEN_LIST_ANTRAG_LABEL_{{a_nr}}_"),
        object: {
          a_nr: this.filterSpaced(item.objekt.a_nr),
        }
      },);
    },

    showSitzungLabel({ item }) {
      return replaceText({
        text: gettext("_TXT_NACHRICHTEN_LIST_SITZUNG_LABEL_{{si_sitzungsnr}}_"),
        object: {
          si_sitzungsnr: item.objekt.si_sitzungsnr,
        }
      },);
    },

    showTopLabel({ item }) {
      return replaceText({
        text: gettext("_TXT_NACHRICHTEN_LIST_TAGESORDNUNG_LABEL_{{tos_bez}}_"),
        object: {
          tos_bez: item.objekt.tos_bez,
        }
      },);
    },

    showVertragLabel({ item }) {
      return replaceText({
        text: gettext("_TXT_NACHRICHTEN_LIST_VERTRAG_LABEL_{{v_nr}}_"),
        object: {
          v_nr: this.filterSpaced(item.objekt.v_nr),
        }
      },);
    },

    showAuszahlungLabel({ item }) {
      return replaceText({
        text: gettext("_TXT_NACHRICHTEN_LIST_AUSZAHLUNG_LABEL_{{v_nr}}_{{az_nummer}}_"),
        object: {
          v_nr: this.filterSpaced(item.objekt.v_nr),
          az_nummer: item.objekt.az_nummer,
        }
      },);
    },

    showSammelvertragLabel({ item }) {
      return replaceText({
        text: gettext("_TXT_NACHRICHTEN_LIST_WEITERLEITUNGSVERTRAG_LABEL_{{ast_nr}}_{{sv_nr}}_"),
        object: {
          ast_nr: item.objekt.ast_nr,
          sv_nr: item.objekt.sv_nr,
        }
      },);
    },

    showFormularLabel({ item }) {
      return replaceText({
        text: gettext("_TXT_NACHRICHTEN_LIST_FORMULAR_LABEL_{{for_titel}}_{{for_nummer}}_"),
        object: {
          for_titel: item.objekt.for_titel,
          for_nummer: item.objekt.for_nummer,
        }
      },);
    },

    showUmfrageLabel({ item }) {
      return replaceText({
        text: gettext("_TXT_NACHRICHTEN_LIST_UMFRAGE_LABEL_{{for_titel}}_"),
        object: {
          for_titel: item.objekt.for_titel,
        }
      },);
    },

    reloadTable() {
      EventBus.$emit(`updateTable${ this.tableId }`);
    },
  },
};
