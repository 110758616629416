"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import diasLoading from "dias/core/loading";
import models from "./models";
import parameter from "./parameter";
import cortexe from "./cortexe";

import DiasFoerderantrag from "dias/foerderantrag";
import diasAuthService from "dias/auth/services";

import buchungen from "./buchungen";

import Vertragsbelege from "./belege";
import Vertragsbelegdokumente from "./belegdokumente";


import detailsComponent from "./details.component";
import auszahlungDetailsComponent from "./auszahlung.details.component";
import vertragComponent from "./overview.component";
import kofiErfassungComponent from "./kofierfassung.component";
import aktZuschussComponent from "./auszahlung.aktualisierter_zuschuss.component";
import kontoComponent from "./vertragskonto.component";
import vertretungsberechtigteComponent from "./vertretungsberechtigte.component";
import BetragsrechnerRegistry from "dias/kosten_finanzierung/betragsrechner/services";
import BetragsrechnerComponent from "dias/kosten_finanzierung/betragsrechner/betragsrechner.component.js";

const modul = angular.module("dias.vertrag", [
  uiRouter,
  diasLoading.name,
  buchungen.name,
  cortexe.name,
  models.name,
  parameter.name,
  DiasFoerderantrag.name,
  diasAuthService.name,
  BetragsrechnerRegistry.name,
  Vertragsbelege.name,
  Vertragsbelegdokumente.name,
])

  .config(/*@ngInject*/$stateProvider => {
    $stateProvider

    // Liste
      .state("root.vertrag", {
        url: `/vertrag/?
            panel&
            user_cookie&
            antragstellerorg&
            friststatus&
            meine_teams&
            status&
            status_detail&
            workflowstatusgruppe&
            zustaendig&
            meine_ast_vertraege&
            mit_auszahlung&
            show_gesperrte_ast&
            zu_bearbeiten
      `,
        reloadOnSearch: false,
        views: {
          "main@root": {
            template: "<div vueinit><vue-vertrag-list></vue-vertrag-list></div>",
          }
        },
        data: {
          permissions: ["vertrag.view"]
        }
      })
      .state("root.vertrag1", {
        url: `/vertrag1/?
            panel&
            user_cookie&
            antragstellerorg&
            friststatus&
            meine_teams&
            status&
            status_detail&
            workflowstatusgruppe&
            zustaendig&
            meine_ast_vertraege&
            mit_auszahlung&
            show_gesperrte_ast&
            zu_bearbeiten
      `,
        reloadOnSearch: false,
        views: {
          "main@root": {
            template: "<div vueinit><vue-vertrag-list></vue-vertrag-list></div>",
          }
        },
        data: {
          permissions: ["vertrag.view"]
        }
      })
      .state("root.vertrag.details", {
        url: ":id/",
        reloadOnSearch: false,
        views: {
          "main@root": {
            template: "<div vueinit><vertrag-details></vertrag-details></div>"
          }
        },
      })
      .state("root.vertrag1.details", {
        url: ":id/",
        reloadOnSearch: false,
        views: {
          "main@root": {
            template: "<angular-vertrag-details></angular-vertrag-details>"
          }
        },
      })
      .state("root.vertrag.details.auszahlung", {
        url: "auszahlung/:aus_id/",
        views: {
          "main@root": {
            template: "<div vueinit><auszahlung-details></auszahlung-details></div>",
          },
        },
      })
      .state("root.vertrag.details.auszahlung1", {
        url: "auszahlung1/:aus_id/",
        views: {
          "main@root": {
            template: "<auszahlung-details-angular></auszahlung-details-angular>"
          }
        },
      })
      .state("root.auszahlung", {
        url: `/auszahlung/?
        friststatus&
        meine_teams&
        status&
        status_detail&
        workflowstatusgruppe&
        zustaendig&
        workflowtyp&
        meine_ast_auszahlungen&
        show_gesperrte_ast&
        zu_bearbeiten`,
        reloadOnSearch: false,
        views: {
          "main@root": {
            template: "<div vueinit><vue-auszahlung-list></vue-auszahlung-list></div>"

          }
        },
        data: {
          permissions: ["auszahlung.view"]
        }
      })
    ;
  })

  .filter("duration", [function() {
    return function(seconds, showInDays) {
      const input = Math.trunc(seconds) || 0;
      if (showInDays) {
        const unit = input / 3600 / 24 > 1 ? "Tage" : "Tag";
        return `${ Math.ceil(input / 3600) / 24 } ${ unit }`;
      }
      if (input > 3599) {
        const unit = input / 3600 >= 2 ? "Stunden" : "Stunde";
        return `${ Math.ceil(input / 3600) } ${ unit }`;
      }
      return `${ Math.floor(input / 60) } Minuten`;
    };
  }])

  .component("angularVertragDetails", detailsComponent)
  .component("auszahlungDetailsAngular", auszahlungDetailsComponent)
  .component("vertragOverviewAngular", vertragComponent)
  .component("kofiErfassung", kofiErfassungComponent)
  .component("aktualisierterZuschuss", aktZuschussComponent)
  .component("vertretungsberechtigte", vertretungsberechtigteComponent)
  .component("betragsrechnerxxx", BetragsrechnerComponent)
  .component("vertragskonto", kontoComponent)
;

export default modul;
