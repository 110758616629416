import AngularLink from "../../../../../../../libs/vue/client/vue/global/components/AngularLink/AngularLink.vue";
import DokumenteSimpleList from "../../../../../../../libs/vue/client/vue/components/Dokumente/DokumenteSimpleList/DokumenteSimpleList.vue";
import ContextPanel from "../../../../../../../libs/vue/client/vue/global/components/ContextPanel/ContextPanel.vue";
import FormstepDetail from "../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";
import PuxGet from "../../../../../../../libs/vue/client/vue/global/components/PuxGet/PuxGet.vue";

import {
  FilterDateMixin,
} from "../../../../../../../libs/vue/client/vue/global/mixins/FiltersMixin";

import KAkkreditierungsstatusUUIDs from "../../../../../../../libs/vue/client/vue/const/KAkkreditierungsstatusUUIDs";

import {
  size
} from "lodash-es";

// @vue/component
export default {
  name: "AkkreditierteStudiengangDetailsOverview",
  components: {
    AngularLink,
    ContextPanel,
    DokumenteSimpleList,
    FormstepDetail,
    PuxGet,
  },
  mixins: [
    FilterDateMixin,
  ],
  props: {
    studiengang: {
      type: Object,
      required: true,
    },
    akkr: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      optionsForFormstepDetail: {
        hideHeader: true,
      },
    };
  },
  computed: {
    contextLabel() {
      const DATE_PART = this.isNegativEntscheidung
        ? `${ this.filterDate(this.akkr.pro_entscheidungsdatum) }`
        : `${ this.filterDate(this.akkr.pro_von) } - ${ this.filterDate(this.akkr.pro_bis) }`;
      return `${ this.akkr.typ_kbez } ${ DATE_PART }`;
    },

    oeffentlicheDokumente() {
      return this.akkr.oeffentlich_dokumente || [];
    },

    showExternesGutachten() {
      return size(this.oeffentlicheDokumente) === 0 && this.akkr.pro_gutachten_extern;
    },

    isStatusAktuelleAkkreditierungAkkreditiertMitAuflagen() {
      return [
        KAkkreditierungsstatusUUIDs.AKKREDITIERT_MIT_AUFLAGEN,
        KAkkreditierungsstatusUUIDs.AKKREDITIERT_AUFLAGEN_ERFUELLT,
        KAkkreditierungsstatusUUIDs.AKKREDITIERT_AUFLAGEN_TEILW_ERFUELLT
      ].indexOf(this.akkr.status) !== -1;
    },

    isAuflagenerfuellungKommentarAndStatusTeilweiseErfuellt() {
      return this.akkr.pro_auflagenerfuellung_kommentar &&
      this.akkr.status === KAkkreditierungsstatusUUIDs.AKKREDITIERT_AUFLAGEN_TEILW_ERFUELLT;
    },

    showAuflagenBeschlussText() {
      return this.akkr.pro_auflagenerfuellung_beschluss && [
        KAkkreditierungsstatusUUIDs.AKKREDITIERT_AUFLAGEN_TEILW_ERFUELLT,
        KAkkreditierungsstatusUUIDs.AKKREDITIERT_MIT_AUFLAGEN
      ].indexOf(this.akkr.status) !== -1;
    },

    isNegativEntscheidung() {
      return this.akkr.status === KAkkreditierungsstatusUUIDs.NICHT_AKKREDITIERT;
    }
  },
};
