import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "ManuelleAufgabenBoxWiedervorlageModal",
  components: {
    FormElement,
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    aufgabe: {
      type: Object,
      required: true,
    },
    selectorClose: {
      type: String,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      options: {
        showCloseButton: true,
      },
      errors: undefined,
      model: {
        wiedervorlage: undefined,
      },
      optionsForWiedervorlage: {
        id: "wiedervorlage",
        htmlId: "modal_wiedervorlage",
        type: "date",
        view: "v",
        label: "_TXT_MANUELLE_AUFGABEN_WIEDERVORLAGE_",
        required: false,
      },
      loading: false,
    };
  },
  computed: {
    urlSave() {
      return `aktuelle_aufgaben/${ this.aufgabe.pk }/wiedervorlage_aendern/`;
    },

    alertLabels() {
      return {
        [this.optionsForWiedervorlage.id]: this.optionsForWiedervorlage.label,
      };
    },
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      this.model.wiedervorlage = this.aufgabe.wiedervorlage || null;
    },

    save() {
      this.loading = true;
      this.deleteErrors();
      this.putHttp({
        url: this.urlSave,
        data: this.model,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_MANUELLE_AUFGABEN_WIEDERVORLAGE_MODAL_SUCCESS_",
          });
          this.close({
            isSaved: true,
          });
        },
        error => {
          this.errors = error.data;
        }
      ).finally(
        () => this.loading = false
      );
    },

    deleteErrors() {
      this.errors = undefined;
    },
  },
};
