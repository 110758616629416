"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";
import componentRo from "./readonly.controller.js";


const parametermodule = angular.module("dias.standard_workflowmodule.parameter.object", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("object", "parametermodul-object");
  })
  .component("parametermodulObject", componentRo)
  .component("parametermodulObjectRo", componentRo);

export default parametermodule;
