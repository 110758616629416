var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-element"] = jade_interp = function(label, fieldName, errorsMap, prefix){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-class", "{'has-error': " + (errorsMap) + "['" + (fieldName) + "']}", true, true)) + (jade.cls(['form-group','row',attributes.class], [null,null,false])) + "><label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"col-form-label col-sm-3 text-right font-weight-bold required\"><span" + (jade.attr("ng-class", "{'required':vm.isRequired('" + (fieldName) + "')}", true, true)) + " class=\"titel\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span></label><div class=\"col-sm-9 col-xl-6\">");
block && block();
buf.push("<label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"help-block\"><ul" + (jade.attr("ng-if", "" + (errorsMap) + "['" + (fieldName) + "']", true, true)) + " class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errorsMap) + "['" + (fieldName) + "']", true, true)) + ">{{ err }}</li></ul></label></div></div>");
};


























jade_mixins["vorschau-daten"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-repeat", "(key, val) in " + (vorschau) + "", true, true)) + "><span ng-if=\"val\">{{key}}: {{ val }}</span></div>");
};
jade_mixins["vorschau-container"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div>");
jade_mixins["vorschau-daten"](vorschau);
buf.push("</div>");
};
jade_mixins["form-element"] = jade_interp = function(label, fieldName, errors, prefix){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-class", "{'has-error': " + (errors) + " }", true, true)) + (jade.cls(['form-group','row',attributes.class], [null,null,false])) + "><label" + (jade.attr("ng-attr-for", "{{" + (prefix) + " + '_' + " + (fieldName) + " }}", true, true)) + " class=\"col-form-label col-sm-3 text-right font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span></label><div class=\"col-sm-8\">");
block && block();
buf.push("<label" + (jade.attr("ng-attr-for", "{{" + (prefix) + " + '_' + " + (fieldName) + " }}", true, true)) + " class=\"help-block\"><ul" + (jade.attr("ng-if", "" + (errors) + "", true, true)) + " class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errors) + "", true, true)) + ">{{ err }}</li></ul></label></div></div>");
};
buf.push("<script type=\"text/ng-template\" id=\"personalstelleMatchTemplate.html\"><span ng-bind=\"vm.selected.ps_kbez\"></span></script><script type=\"text/ng-template\" id=\"personalstelleChoicesTemplate.html\"><span class=\"label-hidden\">Wählen Sie bitte eine Antwort</span><span ng-bind=\"item.ps_kbez\"></span></script>");
jade_mixins["vorschau-daten"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<hr><div><div" + (jade.cls(['form-group','row',attributes.class], [null,null,false])) + "><label ng-attr-for=\"{{ctx.prefix}}_personalkosten_betragsrechner_kostenposition\" class=\"col-form-label col-sm-3 text-right font-weight-bold\"><span class=\"titel\">{{ ctx.gettext(\"Kostenposition (berechnet)\") }}</span></label><div class=\"col-sm-8\"><input type=\"text\"" + (jade.attr("value", "{{" + (vorschau) + ".betrag ? (" + (vorschau) + ".betrag | currency) : 'noch nicht ermittelbar'}} ", true, true)) + " disabled ng-attr-id=\"{{ctx.prefix}}_personalkosten_betragsrechner_kostenposition\" class=\"form-control\"><span class=\"help-block\">{{ " + (jade.escape((jade_interp = vorschau) == null ? '' : jade_interp)) + ".bezeichnung ? " + (jade.escape((jade_interp = vorschau) == null ? '' : jade_interp)) + ".bezeichnung : '-' }}</span></div></div></div>");
};
buf.push("<div ng-if=\"ctx.kontext === 'antrag'\"><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input type=\"text\" maxlength=\"300\" ng-model=\"ctx.posmodel.argument.stellenbezeichnung\" name=\"stellenbezeichnung\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_stellenbezeichnung\" ng-attr-aria-invalid=\"{{ctx.errors.stellenbezeichnung ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "Stellenbezeichnung*", "'stellenbezeichnung'", "ctx.errors.stellenbezeichnung", "ctx.prefix");
buf.push("</div></div><div ng-if=\"ctx.kontext==='beleg'\"><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input type=\"text\" maxlength=\"300\" ng-model=\"ctx.posmodel.argument.name\" name=\"name\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_name\" ng-attr-aria-invalid=\"{{ctx.errors.name ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "Name*", "'name'", "ctx.errors.name", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<dias-ui-select select-model=\"ctx.posmodel.argument.personalstelle\" select-list=\"ctx.personalstellen\" select-required=\"true\" select-label-id=\"'pk'\" select-id=\"ctx.prefix + '_personalstelle'\" select-placeholder=\"'Wählen Sie die Art der Personalstelle aus.'\" select-choices-template=\"'personalstelleChoicesTemplate.html'\" select-match-template=\"'personalstelleMatchTemplate.html'\"></dias-ui-select>");
}
}, "Art der Personalstelle*", "personalstelle", "ctx.errors.personalstelle", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input type=\"text\" ng-model=\"ctx.posmodel.argument.beschaeftigt_von\" name=\"beschaeftigt_von\" bs-datepicker aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_beschaeftigt_von\" ng-attr-aria-invalid=\"{{ctx.errors.beschaeftigt_von ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "Beschäftigt ab*", "'beschaeftigt_von'", "ctx.errors.beschaeftigt_von", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input type=\"text\" ng-model=\"ctx.posmodel.argument.beschaeftigt_bis\" name=\"beschaeftigt_bis\" bs-datepicker ng-attr-id=\"{{ctx.prefix}}_beschaeftigt_bis\" ng-attr-aria-invalid=\"{{ctx.errors.beschaeftigt_bis ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "Beschäftigt bis", "'beschaeftigt_bis'", "ctx.errors.beschaeftigt_bis", "ctx.prefix");
buf.push("</div></div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input type=\"text\" maxlength=\"300\" ng-model=\"ctx.posmodel.argument.personalfunktion\" name=\"personalfunktion\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_personalfunktion\" ng-attr-aria-invalid=\"{{ctx.errors.personalfunktion ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "Funktion im Projekt*", "'personalfunktion'", "ctx.errors.personalfunktion", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" maxlength=\"14\" ng-model=\"ctx.posmodel.argument.vollzeitstelle\" name=\"vollzeitstelle\" dias-decimal-field auto-focus=\"true\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_vollzeitstelle\" ng-attr-aria-invalid=\"{{ctx.errors.vollzeitstelle ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">Stunden</span></div></div>");
}
}, "Umfang einer Vollzeitstelle in der Organisation (in Stunden pro Woche)*", "'vollzeitstelle'", "ctx.errors.vollzeitstelle", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" maxlength=\"14\" ng-model=\"ctx.posmodel.argument.stellenumfang\" name=\"stellenumfang\" dias-decimal-field aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_stellenumfang\" ng-attr-aria-invalid=\"{{ctx.errors.stellenumfang ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">Stunden</span></div></div>");
}
}, "Gesamter Umfang dieser Stelle in Ihrer Organisation (in Stunden pro Woche)*", "'stellenumfang'", "ctx.errors.stellenumfang", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" maxlength=\"14\" ng-model=\"ctx.posmodel.argument.stellenumfang_projekt\" name=\"stellenumfang_projekt\" dias-decimal-field aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_stellenumfang_projekt\" ng-attr-aria-invalid=\"{{ctx.errors.stellenumfang_projekt ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">Stunden</span></div></div>");
}
}, "davon im Projekt (in Stunden pro Woche*", "'stellenumfang_projekt'", "ctx.errors.stellenumfang_projekt", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" maxlength=\"14\" ng-model=\"ctx.posmodel.argument.jahresbrutto\" ng-change=\"ctx.onJahresbruttoChanged()\" ng-blur=\"ctx.blurJahresbrutto()\" name=\"jahresbrutto\" dias-decimal-field aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_jahresbrutto\" ng-attr-aria-invalid=\"{{ctx.errors.jahresbrutto ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">&euro;</span></div></div><div ng-if=\"ctx.statusShowWarningJahresbrutto\" class=\"help-block\">Veränderungen dieses Wertes werden in die Perioden übernommen!</div>");
}
}, "Jährliches Bruttogehalt*", "'jahresbrutto'", "ctx.errors.jahresbrutto", "ctx.prefix");
buf.push("</div><div ng-if=\"ctx.posmodel.argument.perioden.length === 0\" class=\"alert alert-warning\"><i class=\"glyphicon glyphicon-warning\"></i><span>Sie müssen einen Durchführungszeitraum angeben, bevor Sie die Personalkostenerfassung vervollständigen können.</span></div><div ng-repeat=\"periode in ctx.posmodel.argument.perioden\"><div class=\"row\"><div class=\"offset-md-1\"><h5 class=\"font-weight-bold\">{{ periode.periode.kbez }} ({{ ctx.perioden[$index].von | date: \"dd.MM.yyyy\" }}-{{ ctx.perioden[$index].bis | date: \"dd.MM.yyyy\" }})</h5></div></div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" bs-datepicker ng-model=\"ctx.posmodel.argument.perioden[$index].von\" name=\"perioden_{{$index}}_von\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_perioden_{{$index}}_von\" ng-attr-aria-invalid=\"{{ctx.errors.perioden[$index].von ? 'true' : 'false'}}\" class=\"form-control\"></div>");
}
}, "Beginn*", "'perioden_' + $index + '_von'", "ctx.errors.perioden[$index].von", "ctx.prefix");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" bs-datepicker ng-model=\"ctx.posmodel.argument.perioden[$index].bis\" name=\"perioden_{{$index}}_bis\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_perioden_{{$index}}_bis\" ng-attr-aria-invalid=\"{{ctx.errors.perioden[$index].bis ? 'true' : 'false'}}\" class=\"form-control\"></div>");
}
}, "Ende*", "'perioden_' + $index + '_bis'", "ctx.errors.perioden[$index].bis", "ctx.prefix");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" maxlength=\"14\" ng-model=\"ctx.posmodel.argument.perioden[$index].gehalt\" ng-change=\"ctx.changeGehalt()\" name=\"perioden_{{$index}}_gehalt\" dias-decimal-field aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_perioden_{{$index}}_gehalt\" ng-attr-aria-invalid=\"{{ctx.errors.perioden[$index].gehalt ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">&euro;</span></div></div>");
}
}, "Gehalt*", "'perioden_' + $index + '_gehalt'", "ctx.errors.perioden[$index].gehalt", "ctx.prefix");
buf.push("</div>");
jade_mixins["vorschau-container"]("ctx.vorschau");
buf.push("<div ng-if=\"ctx.kontext==='beleg'\"><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"checkbox-btn\"><input ng-model=\"ctx.posmodel.argument.dokument_geschwaerzt\" type=\"checkbox\" name=\"dokument_geschwaerzt\" ng-disabled=\"ctx.asPruefer()\" ng-attr-id=\"{{:: ctx.prefix }}_dokument_geschwaerzt\" ng-attr-aria-checked=\"{{ ctx.posmodel.argument.dokument_geschwaerzt ? 'true' : 'false' }}\" ng-attr-aria-invalid=\"{{ctx.errors.dokument_geschwaerzt ? 'true' : 'false'}}\" class=\"form-control\"><label ng-attr-for=\"{{:: ctx.prefix }}_dokument_geschwaerzt\" class=\"font-weight-bold\"><span class=\"titel\">{{:: ctx.gettext(\"_LBL_VERTRAG_DETAILS_BELEG_MODAL_CONFIRMATION_\") }}</span></label></div>");
}
}, "", "dokument_geschwaerzt", "ctx.errors", "ctx.prefix");
buf.push("</div></div>");;return buf.join("");
}