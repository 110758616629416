"use strict";

import template from "./details.jade";

import antraegeReadonlyTemplate from "./antraege.readonly.jade";
import antraegeReadonlyController from "./antraege.readonly.controller";

import {
  get,
} from "lodash-es";


class SammelvertragdetailController {
  /*@ngInject*/
  constructor($q, $stateParams, Sammelvertrag, $filter, $timeout) {
    this.name = "SammelvertragdetailsController";
    this.Sammelvertrag = Sammelvertrag;
    this.$stateParams = $stateParams;
    this.$filter = $filter;
    this.$timeout = $timeout;
    this.antraegeReadonlyTemplate = antraegeReadonlyTemplate;
    this.antraegeReadonlyController = antraegeReadonlyController;
    this.docAnzeige = "58329dec-6c9c-4bdd-ab2b-957a87760dd4";
    this.dokumentePermissions = {
      view: "sammelvertragdokument.view",
      create: "sammelvertragdokument.create",
      delete: "sammelvertragdokument.delete",
      update: "sammelvertragdokument.update",
      update_gueltigkeit: "sammelvertragdokument.update",
    };

    this.loading = {
      sv: true,
      aufgabe: false,
    };

    this.reloadSammelvertrag();

    // Timeline
    this.timelineConfig = {
      benachrichtigungen: {
        bezug: "sammelvertrag",
        bezugpk: $stateParams.id,
        autorefresh: true,
      },
      url: `sammelvertraege/${ $stateParams.id }/timeline/`,
    };

    this.updateAufgabeFromVue = this.updateAufgabeFromVue.bind(this);
    this.onWorkflowInit = this.onWorkflowInit.bind(this);
    this.onWorkflowStarted = this.onWorkflowStarted.bind(this);
    this.onWorkflowFinish = this.onWorkflowFinish.bind(this);
    this.onWorkflowCanceled = this.onWorkflowCanceled.bind(this);
    this.onWorkflowSuccess = this.onWorkflowSuccess.bind(this);
    this.onWorkflowFailure = this.onWorkflowFailure.bind(this);
    this.onAufgabeAngenommen = this.onAufgabeAngenommen.bind(this);
  }

  reloadSammelvertrag() {
    this.loading.sv = true;
    this.Sammelvertrag.one(this.$stateParams.id).get().then(
      result => {
        this.sv = result;
        const baseUrl = this.sv.getRestangularUrl().replace("/api/", "");
        this.svUrl = baseUrl + "/";
        this.svLabel = `${ this.$filter("spaced")(this.sv.antragstellerorg && this.sv.antragstellerorg.ast_nr) } - ${ this.sv.sv_nr }`;
        this.loading.sv = false;
      }
    );
  }

  changeTimelineStatus() {
    this.timelineStatus = !this.timelineStatus;
  }

  scrollTop() {
    $(window).scrollTop(0);
  }

  updateAufgabeFromVue(newAufgabe) {
    this.$timeout(() => {
      this.updateAufgabe(newAufgabe);
    });
  }

  updateAufgabe(newAufgabe) {
    Object.assign(this.sv.aktuelle_aufgabe, newAufgabe);
  }

  onWorkflowInit(clientFunktion) {
    this.$timeout(() => {
      this.loading.aufgabe = true;
      this.clientFunktion = clientFunktion;
    });
  }

  onWorkflowStarted(newAufgabe, currentWorkflowfolge) {
    this.$timeout(() => {
      this.updateAufgabe(newAufgabe);
      this.currentWorkflowfolge = currentWorkflowfolge;
    });
  }

  onWorkflowFinish() {
    this.$timeout(() => {
      this.loading.aufgabe = false;
      this.clientFunktion = undefined;
      this.currentWorkflowfolge = undefined;
    });
  }

  onWorkflowCanceled() {
    this.$timeout(() => {
      this.loading.aufgabe = false;
      this.clientFunktion = undefined;
    });
  }

  onWorkflowSuccess(aufgabe) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerSuccess(aufgabe);
      }
      this.scrollTop();
      if (get(this.currentWorkflowfolge, "wfo_reload")) {
        return this.reloadSammelvertrag();
      }
    });
  }

  onWorkflowFailure(err) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerError(err);
      }
      if (err.data && err.data.error_data) {
        this.validationErrors = err.data.error_data;
      } else if (err.data && !angular.isString(err.data)) {
        this.validationErrors = err.data;
      } else {
        this.validationErrors = undefined;
      }
      this.scrollTop();
      return this.reloadSammelvertrag();
    });
  }

  onAufgabeAngenommen() {
    this.$timeout(() => {
      this.reloadSammelvertrag();
    });
  }
}

export default {
  template: template(),
  controller: SammelvertragdetailController,
  controllerAs: "vm"
};
