import ModuleMixin from "../ModuleMixin";
import ElementverzweigungElement from "./ElementverzweigungElement/ElementverzweigungElement.vue";
import loading from "../../../../global/directives/loading";
import {
  cloneDeep,
  forEach,
  keyBy,
  get,
  isEmpty,
} from "lodash-es";
import {
  EventBus,
} from "../../../../global/functions/event-bus";
import { createNamespacedHelpers } from "vuex";
const {
  mapState,
} = createNamespacedHelpers("snapshot");

// @vue/component
export default {
  name: "Elementverzweigung",
  components: {
    ElementverzweigungElement,
  },
  directives: {
    loading,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      statusLoadingButton: false,
      statusLoadingCheckbox: false,
      unterregelnObj: {},
      timerSignalChildren: undefined,
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS_FORMSTEP_DETAIL = cloneDeep(this.optionsFormstepDetail);
      OPTIONS_FORMSTEP_DETAIL.saveCallback = this.saveLocal;
      return OPTIONS_FORMSTEP_DETAIL;
    },

    labelHeader() {
      return this.modelParameter.frage;
    },

    antworten() {
      return this.model.antworten || {};
    },

    statusCanAddElement() {
      return this.editable; // TODO: ILIA
    },

    editable() {
      return !this.statusReadonly;
    },

    snapshotModuleAntworten() {
      if (this.statusSnapshot &&
        this.snapshotModule &&
        this.snapshotModule.antworten) {
        return this.snapshotModule.antworten;
      }
      return {};
    },

    snapshotFiltered() {
      const SNAPSHOT = {
        add: {},
        delete: {},
      };
      if (this.statusSnapshot) {
        forEach(this.modelParameter.antwort, item => {
          const LABEL = item.label;
          const STATUS_ANTWORTEN = get(this.antworten, `${ LABEL }.status`, false);
          const STATUS_ANTWORTEN_SNAPSHOT = get(this.snapshotModuleAntworten, `${ LABEL }.status`, false);
          if (STATUS_ANTWORTEN) {
            if (!STATUS_ANTWORTEN_SNAPSHOT) {
              SNAPSHOT.add[LABEL] = true;
            }
          } else {
            if (STATUS_ANTWORTEN_SNAPSHOT) {
              SNAPSHOT.delete[LABEL] = true;
            }
          }
        });
      }
      return SNAPSHOT;
    },

    statusDiff() {
      return !isEmpty(this.snapshotFiltered.delete) ||
        !isEmpty(this.snapshotFiltered.add) ||
        this.statusSnapshotChange;
    },

    statusSnapshotChange() {
      let statusChange = false;
      forEach(this.modelParameter.antwort, (element, elementIndex) => {
        forEach(element.children, unterregelPk => {
          if (this.STATUS_DIFF_FOR_REGELS[`${ unterregelPk }_${ elementIndex }`]) {
            statusChange = true;
            return false;
          }
        });
        if (statusChange) {
          return false;
        }
      });

      return statusChange;
    },

    ...mapState([
      "STATUS_DIFF_FOR_REGELS",
    ]),
  },
  beforeUnmount() {
    this.registerOrDeactiveSignalReceiverForChildren({ eventBusAction: "$off" });
  },
  methods: {
    initDataLocal({ statusFirstLoad }) {
      if (statusFirstLoad) {
        this.unterregelnObj = keyBy(this.regel.unterregeln, "regel");
        this.registerOrDeactiveSignalReceiverForChildren({ eventBusAction: "$on" });
      }
    },

    registerOrDeactiveSignalReceiverForChildren({ eventBusAction }) {
      forEach(this.regel.unterregeln, unterregel => {
        forEach(unterregel.signal_empfang, empfang => {
          if (empfang.signal_kennung && empfang.sender_reid) {
            const SIGNAL_NAME = `${ empfang.signal_kennung }_${ empfang.sender_reid }`;
            EventBus[eventBusAction](SIGNAL_NAME, arg => this.onReceiveSignalForChildren(empfang.signal_kennung, arg));
          }
        });
      });
    },

    onReceiveSignalForChildren(signal_kennung, arg = {}) {
      const REGEL = arg.regel && arg.regel.regel;
      if (REGEL && this.unterregelnObj[REGEL]) { // Wenn Signal aus child von elementliste kommt
        return;
      }
      clearTimeout(this.timerSignalChildren);
      this.timerSignalChildren = setTimeout(() => { // Workaround, wenn 2 oder mehrere Signale gleichzeitig kommen
        this.reloadModule();
      }, 100);
    },

    perepareDataForSave({ data, label, regel, elementStatus } = {}) {
      const DATA = cloneDeep(this.model) || {};
      DATA.antworten = DATA.antworten || {};
      DATA.antworten[label] = DATA.antworten[label] || {};
      if (regel) {
        DATA.antworten[label].status = true;
        DATA.antworten[label].children = {
          [regel]: data,
        };
        DATA.antworten.partial_update = [regel];
      } else {
        this.statusLoadingCheckbox = true;
        if (!this.modelParameter.mehrfachauswahl) {
          forEach(DATA.antworten, (antwort, key) => {
            if (key !== "partial_update") {
              antwort.status = false;
            }
          });
        }
        DATA.antworten[label].status = elementStatus;
        DATA.antworten.only_status = true;
        DATA.antworten.partial_update = [];
      }
      return DATA;
    },

    updateElement({ data, label, regel, elementStatus }) {
      const DATA = this.perepareDataForSave({ data, label, regel, elementStatus });
      return this.save({
        model: DATA,
        childObj: {
          label,
          childRegel: regel,
        },
      }).finally(
        () => this.statusLoadingCheckbox = false
      );
    },

    onError({ errors, childObj = {} } = {}) {
      const {
        label,
        childRegel,
      } = childObj;
      const ERRORS = cloneDeep(errors);
      const CURRENT_DATA = get(ERRORS, `data.antworten["${ label }"].children.${ childRegel }`);
      ERRORS.data = CURRENT_DATA;
      return ERRORS;
    },

    updateModuleLocal({ response, extra, group }) {
      const RESPONSE = cloneDeep(response);
      const MODULE = cloneDeep(this.module);
      MODULE.data = RESPONSE.data;
      this.updateModule({ response: MODULE, extra, group });
    },

    saveLocal() {
      return new Promise(resolve => {
        resolve();
      });
    },

    showErrorsInModule({ errors }) {
      if (this.$refs.formstepDetail) {
        if (this.isModuleEditable) {
          this.$refs.formstepDetail.openEditMode();
        }
        const MODULES_INDEPENDENT_ERRORS = get(errors, "antworten.__all__");
        if (MODULES_INDEPENDENT_ERRORS) {
          this.$refs.formstepDetail.setErrors({
            errors: {
              __all__: MODULES_INDEPENDENT_ERRORS,
            },
          });
        } else {
          this.$refs.formstepDetail.setErrors({
            errors: undefined,
          });
        }
      }
      this.showErrorsInChildrenModule({ errors });
    },

    showErrorsInChildrenModule({ errors }) {
      const ERRORS_ANTWORTEN = errors.antworten || {};
      forEach(ERRORS_ANTWORTEN, (item, key) => {
        if (item.children) {
          forEach(item.children, (childErrors, regelId) => {
            const REGEL_ID_FOR_CHILD = `${ this.regelIdWithParentsRegelId }_${ regelId }_${ key }`;
            EventBus.$emit("showErrorsInModule", {
              regelId: REGEL_ID_FOR_CHILD,
              errors: childErrors });
          });
        }
      });
    },
  },
};
