import moment from "moment";
import translate from "../../../../global/directives/translate";
import {
  FilterDateMixin,
} from "../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "PersonalkostenPeriodenDurchfuehrungszeitraumLine",
  directives: {
    translate,
  },
  mixins: [
    FilterDateMixin,
  ],
  props: {
    period: {
      type: Object,
      required: true,
    },
    start: {
      type: Boolean,
      required: true,
    },
    up: {
      type: Boolean,
      required: true,
    },
    down: {
      type: Boolean,
      required: true,
    }
  },

  data() {
    return {
      styles: {
        left: 0,
        right: 0,
      },
    };
  },
  computed: {
    getTitleTranslate() {
      return {
        title: this.getTitlePlaceholder,
        extra: {
          begin: this.getBegin,
          end: this.getEnd,
        },
      };
    },

    getBegin() {
      return this.filterDate(this.period.von_aktuell);
    },

    getEnd() {
      return this.filterDate(this.period.bis_aktuell);
    },

    getTitlePlaceholder() {
      if (this.start) {
        return "_TXT_PERSONAL_KOSTEN_PERIODE_D_ZEITRAUM_LINE_TITLE_BEGIN_{{begin}}_";
      }
      return "_TXT_PERSONAL_KOSTEN_PERIODE_D_ZEITRAUM_LINE_TITLE_ENDE_{{end}}_";
    },
  },
  created() {
    this.initStyles();
  },
  methods: {
    initStyles() {
      const BEGIN_PERIOD_MIN = moment(this.period.pe_von);
      const END_PERIOD_MAX = moment(this.period.pe_bis);
      const COUNT_DAYS = END_PERIOD_MAX.diff(BEGIN_PERIOD_MIN, "days");
      if (this.start) {
        const BEGIN_PERIOD = moment(this.period.von_aktuell);
        const BEGIN_COUNT_DAYS = BEGIN_PERIOD.diff(BEGIN_PERIOD_MIN, "days");
        const LEFT = BEGIN_COUNT_DAYS * 100 / COUNT_DAYS;
        this.styles = {
          left: `${ LEFT }%`,
          right: "auto",
        };
      } else {
        const END_PERIOD = moment(this.period.bis_aktuell);
        const END_COUNT_DAYS = END_PERIOD_MAX.diff(END_PERIOD, "days");
        const RIGHT = END_COUNT_DAYS * 100 / COUNT_DAYS;
        this.styles = {
          left: "auto",
          right: `${ RIGHT }%`,
        };
      }
    },
  },
};
