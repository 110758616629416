import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import KostenMixin from "../Mixins/KostenMixin";

// @vue/component
export default {
  name: "AllgemeinkostenPauschale",
  components: {
    KFStandard,
  },
  mixins: [
    KostenMixin,
  ],
  computed: {
    sumLocal() {
      return this.summeCurrency;
    },
  },
};
