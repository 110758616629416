/* Basis-Komponenten einer DIAS-Applikation. */

"use strict";

// Core
import AsideInit from "dias/core/asideinit";
import Anonymisierung from "dias/core/anonymisierung";
import Autofocus from "dias/core/autofocus";
import Benachrichtigungen from "dias/benachrichtigungen";
import Chart from "dias/core/chart";
import DynamicRoot from "dias/core/dynamicRoot";
import DropdownMultiselect from "dias/core/dropdownMultiselect";
import FormFields from "dias/core/formfields";
import Auth from "dias/auth";
import HeightWatch from "dias/core/heightWatch";
import Hilfe from "dias/hilfe";
import Icons from "dias/core/icons";
import Katalog from "dias/core/katalog";
import InfoBox from "dias/core/infoBox";
import Log from "dias/core/log";
import News from "dias/core/news";
import Q from "dias/core/q";
import ReleaseNotes from "dias/core/releasenotes";
import Registration from "dias/core/registration";
import Statusfolgen from "dias/core/statusfolgen";
import Statusgruppen from "dias/core/statusgruppen";
import Tabledetail from "dias/core/tabledetail";
import DiasTemplates from "dias/core/dias_templates";
import Tooltip from "dias/core/tooltip";
import VersionCheck from "dias/core/versioncheck";
import TerminAendern from "dias/core/terminAendern/";
import Tracking from "dias/core/tracking";
import Wizard from "dias/core/wizard/";
import SyConfigAdmin from "dias/core/syconfig/";
import Configurable from "dias/core/configurable/";

// DIAS
import Antragsteller from "dias/antragsteller";
import Berichte from "dias/berichte";
import Bewertungen from "dias/bewertungen";
import Budget from "dias/budget";
import Codes from "dias/codes";
import Datenexport from "dias/datenexport";
import Deckblatt from "dias/deckblatt";
import Dokumente from "dias/dokumente";
import FAQ from "dias/faq";
import Fibu from "dias/fibu";
import Foerderangebot from "dias/foerderangebote";
import Foerderantrag from "dias/foerderantrag";
import Foerderfinder from "dias/foerderfinder";
import Foerderinstrument from "dias/foerderangebote/instrumente";
import Foerderinstrumentvarianten from "dias/foerderangebote/instrumentvarianten";
import Foerderlandkarte from "dias/foerderlandkarte";
import Foerdermodule from "dias/foerderantrag/foerdermodule";
import Foerderregeln from "dias/foerderregeln";
import Gutachten from "dias/gutachten";
import Formular from "dias/formular";
import i18n from "dias/core/i18n";
import Importauftraege from "dias/importauftraege";
import Info from "dias/info";
import Kalender from "dias/kalender";
import KostenFinanzierung from "dias/kosten_finanzierung/index";
import Personal from "dias/personal";
import Privacy from "dias/privacy";
import Projektberichte from "dias/projektberichte";
import Pruefkriterien from "dias/pruefkriterien";
import Sachberichte from "dias/sachberichte";
import Sitzungen from "dias/sitzungen";
import StandardFoerdermodule from "dias/standard_foerdermodule";
import StandardSitzungsmodule from "dias/standard_sitzungsmodule";
import StandardWorkflowmodule from "dias/standard_workflowmodule";
import Suche from "dias/suche";
import Tasklog from "dias/tasklog";
import Tableau from "dias/tableau";
import Tealium from "dias/tealium";
import Teams from "dias/teams";
import Templates from "dias/templates";
import Textbausteine from "dias/textbausteine";
import Themen from "dias/themen";
import Vertrag from "dias/vertrag";
import Sammelvertrag from "dias/sammelvertrag";
import Start from "dias/start";
import Workflow from "dias/workflow";
import Zielgruppen from "dias/zielgruppen";


// Gibt die grundlegenden Komponenten zurück.
const _BuildCoreComponents = () => {
  return [
    AsideInit.name,
    Autofocus.name,
    Anonymisierung.name,
    HeightWatch.name,
    Hilfe.name,
    InfoBox.name,
    i18n.name,
    Auth.name,
    Benachrichtigungen.name,
    Bewertungen.name,
    DropdownMultiselect.name,
    Statusfolgen.name,
    FormFields.name,
    Icons.name,
    Katalog.name,
    Log.name,
    News.name,
    Q.name,
    ReleaseNotes.name,
    Registration.name,
    Tasklog.name,
    Tealium.name,
    Tooltip.name,
    Statusgruppen.name,
    Tabledetail.name,
    Tracking.name,
    Chart.name,
    VersionCheck.name,
    Wizard.name,
    SyConfigAdmin.name,
    Configurable.name,
    DynamicRoot.name,
    DiasTemplates.name,
  ];
};

// Ergänzt die grundlegenden Komponenten um Antragssystem-Komponenten.
const _BuildDiasComponents = () => {
  const comp = _BuildCoreComponents();
  return comp.concat([
    Antragsteller.name,
    Codes.name,
    Datenexport.name,
    FAQ.name,
    Fibu.name,
    Foerderantrag.name,
    Foerderangebot.name,
    Foerderinstrument.name,
    Foerderinstrumentvarianten.name,
    Foerderlandkarte.name,
    Foerdermodule.name,
    Foerderregeln.name,
    Importauftraege.name,
    Gutachten.name,
    Personal.name,
    Projektberichte.name,
    Pruefkriterien.name,
    Sachberichte.name,
    StandardFoerdermodule.name,
    StandardSitzungsmodule.name,
    StandardWorkflowmodule.name,
    Themen.name,
    Zielgruppen.name,
    Kalender.name,
    KostenFinanzierung.name,
    Dokumente.name,
    Workflow.name,
    Sitzungen.name,
    Suche.name,
    Vertrag.name,
    Tableau.name,
    Berichte.name,
    Teams.name,
    Templates.name,
    Textbausteine.name,
    Foerderfinder.name,
    Deckblatt.name,
    TerminAendern.name,
    Sammelvertrag.name,
    Start.name,
    Budget.name,
    Info.name,
    Privacy.name,
    Formular.name,
  ]);
};


// Modul-Exporte
const BaseComponents = {
  Core: _BuildCoreComponents,
  DIAS: _BuildDiasComponents,
};

export default BaseComponents;
