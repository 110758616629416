import {
  getCurrentInstance,
} from "vue";
import {
  useStore
} from "vuex";

import NotificationAPI from "./NotificationAPI";

import axios from "axios";
import GlobalOptions from "../const/GlobalOptions";
import store from "../../store";
import {
  setUrlWithParams
} from "../functions/http";
import {
  cloneDeep,
  forEach,
  isArray,
  isFunction,
  isObject,
  isString,
  keyBy,
  startsWith,
} from "lodash-es";


const BASE_URL = "/api/";

const API = axios.create({
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});

export default function HttpAPI() {
  const store = useStore();
  const APP = getCurrentInstance();
  const $Raven = APP.appContext.config.globalProperties.$Raven;
  const $goTo = APP.appContext.config.globalProperties.$goTo; // nur für angular.js

  const {
    addNotification,
  } = NotificationAPI();

  const showErrorInNotification = ({ errorTextHeader, sentryId, errorNumber }) => {
    const TEXT_HTML = `
        <div>
          <h2>${ errorTextHeader }</h2>
          ${ sentryId ? `<p>Sollten Sie Unterstützung bei diesem Problem benötigen, geben Sie bitte die folgende Kennung an: <strong>${ sentryId }</strong></p>` : "" }
          <p><strong>Fehlernummer:&nbsp;</strong>${ errorNumber }</p>
          <p>
            <a href="/" class="btn btn-primary">Zur Startseite</a>
          </p>
        </div>`;

    addNotification({
      text: TEXT_HTML,
      type: "danger",
      timeout: 0,
    });
  };

  const ERROR_HANDLERS = {
    on401: ({ showError }) => {
      window.location.assign(
        `/login/?m=session_expired&next=${ window.location.pathname }${ window.location.search }`
      );
      return showError;
    },
    on403: ({ error, showError }) => {
      if (error && isObject(error.data) &&
        isString(error.data.detail) &&
        startsWith(error.data.detail, "CSRF Failed")) {
        // entferne gespeicherte Tokens um clientseitig auszuloggen
        postHttp({
          url: "auth/logout/",
        }).then(
          () => {
            window.location.assign(
              `/login/?m=session_expired&next=${ window.location.pathname }${ window.location.search }`
            );
          },
          () => {
            addNotification({
              text: "_MSG_HTTPERRORS_403_ERROR_LOGOUT_",
              type: "error",
            });
          }
        );
        // Fehler muss nicht mehr von Aufrufer verarbeitet werden
        return showError;
      }
      addNotification({
        text: "_MSG_HTTPERRORS_403_ERROR_",
        type: "error",
      });
      return true;
    },
    on404: ({ showError }) => {
      return showError;
    },
    on429: ({ error, showError }) => {
      if (error.data.detail) {
        addNotification({
          text: error.data.detail,
          type: "error",
        });
      } else {
        addNotification({
          text: "_MSG_HTTPERRORS_429_ERROR_MAX_TRIES_",
          type: "error",
        });
      }
      return showError;
    },
    on500: ({ error, showError }) => {
      if ($goTo) {
        const PARAMS = {};
        PARAMS.sentryId = error.headers["X-Sentry-ID"];
        $goTo("root.errors.500", PARAMS, { location: false });
      } else {
        showErrorInNotification({
          errorTextHeader: "Der Server hat einen unerwarteten Fehler gemeldet",
          sentryId: error.headers["X-Sentry-ID"],
          errorNumber: 500,
        });
      }
      return showError;
    },
    on502: ({ error, showError }) => {
      if ($goTo) {
        const PARAMS = {};
        PARAMS.sentryId = error.headers["X-Sentry-ID"];
        $goTo("root.errors.504", PARAMS, { location: false });
      } else {
        showErrorInNotification({
          errorTextHeader: "Der Server hat einen unerwarteten Fehler gemeldet",
          sentryId: error.headers["X-Sentry-ID"],
          errorNumber: 502,
        });
      }
      return showError;
    },
    on503: ({ error, showError }) => {
      if ($goTo) {
        const PARAMS = {};
        PARAMS.sentryId = error.headers["X-Sentry-ID"];
        $goTo("root.errors.503", PARAMS, { location: false });
      } else {
        showErrorInNotification({
          errorTextHeader: "Anwendung ist vorübergehend nicht erreichbar",
          sentryId: error.headers["X-Sentry-ID"],
          errorNumber: 503,
        });
      }
      return showError;
    },
    on504: ({ error, showError }) => {
      if ($goTo) {
        const PARAMS = {};
        PARAMS.sentryId = error.headers["X-Sentry-ID"];
        $goTo("root.errors.504", PARAMS, { location: false });
      } else {
        showErrorInNotification({
          errorTextHeader: "Es konnte keine Verbindung zum Server aufgebaut werden",
          sentryId: error.headers["X-Sentry-ID"],
          errorNumber: 504,
        });
      }
      return showError;
    },
  };

  const checkErrorStatus = ({ error, showError, client, resolve, reject }) => {
    if (!error) {
      return true;
    }
    const ERROR_HANDLER = () => {
      const HANDLER = ERROR_HANDLERS[`on${ error.status }`];
      // if Handler is defined for this Status call it
      if (isFunction(HANDLER)) {
        return HANDLER({ error, showError });
      }
    };
    if (error.status > 500 && error.status !== 503) { // Retry and after 3 Fails send Server Error to Sentry
      const do_retry = retry => {
        if (retry > 3) {
          try {
            if ($Raven) {
              $Raven.captureException(new Error(`Server Response ${ error.status } on Axios-Request`), error);
            }
          } catch (err) {
            console.error(err);
          }
          ERROR_HANDLER();
          reject();
          return;
        }
        const RANDOM_DELAY = Math.round(Math.random() * 600) + 150;
        console.warn(`Error ${ error.status }. Retry ${ retry } in ${ RANDOM_DELAY }ms...`);
        setTimeout(() => {
          client(error.config).then(resolve, () => do_retry(retry + 1));
        }, RANDOM_DELAY);
      };
      do_retry(1);
      return false;
    }

    ERROR_HANDLER();
    // Let the promise handle the error
    return true;
  };

  const setKeyData = ({ data, keyId }) => {
    if (!keyId) {
      return undefined;
    }
    return keyBy(data, keyId);
  };

  const checkedExpectedList = ({ expectedList, response }) => {
    if (expectedList) {
      if (isArray(response.data)) {
        return response.data;
      }
      return response.data[GlobalOptions.getListHttpKeyList] ? response.data[GlobalOptions.getListHttpKeyList] : [];
    }
    return response.data;
  };

  const callHttpRequestAndCheckSavedApi = ({
    methodHttp,
    url,
    urlParams,
    data,
    headerParams,
    responseType = "json",
    apiSaveId,
    keyId,
    fullResponse,
    showError = false,
    expectedList
  }) => {
    let api__data_and_loading = undefined;
    if (apiSaveId) {
      api__data_and_loading = store.getters["apis/GET_SAVED_API"]({ apiSaveId });
      if (api__data_and_loading) {
        if (api__data_and_loading.loading) {
          return api__data_and_loading.promise;
        }
      }
    }

    const PROMISE = new Promise((resolve, reject) => {
      if (api__data_and_loading) {
        if (!api__data_and_loading.loading) {
          if (keyId) {
            if (api__data_and_loading.keyData) {
              return resolve(api__data_and_loading.keyData);
            }
            const KEY_DATA = setKeyData({ data: api__data_and_loading.data, keyId });
            store.commit("apis/MUT_INSERT_API_KEY_DATA", { apiSaveId, keyData: KEY_DATA });
            return resolve(KEY_DATA);
          }
          return resolve(api__data_and_loading.data);
        }
      }
      const URL_NEW = setUrlWithParams({ url, params: urlParams });
      let url_full = `${ BASE_URL }${ URL_NEW }`;
      url_full = url_full.replace(/\/\//g, "/");
      API({
        method: methodHttp,
        url: url_full,
        data,
        headers: headerParams,
        responseType,
      }).then(
        response => {
          if (fullResponse) {
            return resolve(response);
          }
          const DATA = checkedExpectedList({ expectedList, response });
          const KEY_DATA = setKeyData({ data: DATA, keyId });
          if (apiSaveId) {
            store.commit("apis/MUT_INSERT_API", { apiSaveId, data: DATA, keyData: KEY_DATA });
          }
          if (keyId) {
            return resolve(KEY_DATA);
          }
          return resolve(DATA);
        },
        error => {
          if (checkErrorStatus({ error: error.response, showError, client: API, reject, resolve })) {
            return reject(error.response);
          }
        }
      );
    });

    if (!api__data_and_loading) {
      store.commit("apis/MUT_INSERT_PROMISE_API", { apiSaveId, promise: PROMISE });
    }

    return PROMISE;
  };

  function getHttp({
    url,
    data,
    urlParams = {},
    headerParams,
    responseType,
    apiSaveId,
    keyId,
    fullResponse,
    showError,
  }) {
    return callHttpRequestAndCheckSavedApi({
      methodHttp: "get",
      url,
      urlParams,
      data,
      headerParams,
      responseType,
      apiSaveId,
      keyId,
      fullResponse,
      showError,
    });
  }

  function getListHttp({
    url,
    data,
    urlParams = {},
    headerParams,
    responseType,
    apiSaveId,
    keyId,
    fullResponse,
    showError,
  }) {
    return callHttpRequestAndCheckSavedApi({
      methodHttp: "get",
      url,
      urlParams,
      data,
      headerParams,
      responseType,
      apiSaveId,
      keyId,
      fullResponse,
      showError,
      expectedList: true,
    });
  }

  function getOptionsHttp({
    url,
    data,
    urlParams = {},
    headerParams,
    responseType,
    keyId,
    fullResponse,
    showError,
  }) {
    return callHttpRequestAndCheckSavedApi({
      methodHttp: "options",
      url,
      urlParams,
      data,
      headerParams,
      responseType,
      keyId,
      fullResponse,
      showError,
    });
  }

  function postHttp({
    url,
    data,
    urlParams = {},
    headerParams,
    responseType,
    fullResponse,
    showError,
  }) {
    return callHttpRequestAndCheckSavedApi({
      methodHttp: "post",
      url,
      urlParams,
      data,
      headerParams,
      responseType,
      fullResponse,
      showError,
    });
  }

  function putHttp({
    url,
    data,
    urlParams = {},
    headerParams,
    responseType,
    fullResponse,
    showError,
  }) {
    return callHttpRequestAndCheckSavedApi({
      methodHttp: "put",
      url,
      urlParams,
      data,
      headerParams,
      responseType,
      fullResponse,
      showError,
    });
  }

  function patchHttp({
    url,
    data,
    urlParams = {},
    headerParams,
    responseType,
    fullResponse,
    showError,
  }) {
    return callHttpRequestAndCheckSavedApi({
      methodHttp: "patch",
      url,
      urlParams,
      data,
      headerParams,
      responseType,
      fullResponse,
      showError,
    });
  }

  function deleteHttp({
    url,
    data,
    urlParams = {},
    headerParams,
    responseType,
    fullResponse,
    showError,
  }) {
    return callHttpRequestAndCheckSavedApi({
      methodHttp: "delete",
      url, urlParams,
      data,
      headerParams,
      responseType,
      fullResponse,
      showError,
    });
  }

  function getChoicesHttp({
    id,
    label,
    url,
    params = {},
  } = {}) {
    const list = [];
    if (id && label) {
      params.fields = [id, label];
    }
    return new Promise((resolve, reject) => {
      getHttp({ url, urlParams: params }).then(
        response => {
          const RESULTS = response.results || response;
          if (id && label) {
            forEach(RESULTS, item => {
              list.push({
                value: item[id],
                label: item[label],
              });
            });
            return resolve(list);
          }
          return resolve(RESULTS);
        },
        error => {
          return reject(error);
        }
      );
    });
  }

  return {
    addNotification,
    deleteHttp,
    getChoicesHttp,
    getHttp,
    getListHttp,
    getOptionsHttp,
    patchHttp,
    postHttp,
    putHttp,
  };
}

export function getProfile() {
  const PROMISE = new Promise((resolve, reject) => {
    const ME = store.state.profile.ME;
    if (ME) {
      return resolve(ME);
    }
    if (window.userPromise) {
      return resolve(window.userPromise);
    }

    API({
      method: "get",
      url: `${ BASE_URL }profil/`,
    }).then(
      response => {
        store.commit("profile/MUT_SET_ME", cloneDeep(response.data));
        return resolve(response.data);
      },
      error => {
        return reject(error);
      }
    );
  });
  return PROMISE;
}
