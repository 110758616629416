import FoerderdokumenteDokumentversionenDetails from "./FoerderdokumenteDokumentversionenDetails/FoerderdokumenteDokumentversionenDetails.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../../global/components/table/table.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import {
  EventBus,
} from "../../../../global/functions/event-bus";
import {
  forEach,
} from "lodash-es";

const TABLE_ID = "Dokumentversionen";

// @vue/component
export default {
  name: "FoerderdokumenteDokumentversionen",
  components: {
    FoerderdokumenteDokumentversionenDetails,
    FormElement,
    Modal,
    PuxButton,
    PuxCloak,
    PuxTranslate,
    SmartTable,
  },
  props: {
    foerderdokument: {
      type: Object,
      required: true,
    },
    foerderdokumenteUrl: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      deleteHttp,
      getHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      deleteHttp,
      getHttp,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        rowActions: [
          {
            label: "_TXT_FOERDERDOKUMENTE_DETAILS_DOKUMENTVERSIONEN_TABLE_ACTIONS_ANZEIGEN_",
            type: "button",
            href: ({ item }) => `${ item.url }`,
            target: "_blank",
          },
          {
            isHidden: ({ item }) => this.isSyncing({ document: item }),
            label: "_TXT_FOERDERDOKUMENTE_DETAILS_DOKUMENTVERSIONEN_TABLE_ACTIONS_DELETE_",
            type: "button",
            callback: ({ item, index }) => this.openModalDeleteDokument({ item, index }),
          },
        ],
        actions: [],
        label: "_TXT_FOERDERDOKUMENTE_DETAILS_DOKUMENTVERSIONEN_TABLE_LABEL_",
        sortable: false,
        pagination: false,
        showHeader: false,
        showColsConfig: false,
        details: true,
        detailsCloseButtonHide: true,
        data: [],
      },
      modal: {
        delete: undefined,
      },
      confirmOptions: undefined,
      statusTableLoading: true,
      uploadAllowed: undefined,
      timer: undefined,
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },

    dokumenteSyncingUrl() {
      return `${ this.dokumenteUrl }syncing/`;
    },

    dokumenteUrl() {
      return `${ this.foerderdokumenteUrl }dokumente/`;
    },

    optionsUpload() {
      return {
        multiple: true,
        drop: true,
        type: "document",
        saveUrl: this.dokumenteUrl,
        saveUrlParams: {
          dokart: this.foerderdokument.dokart,
        },
      };
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getHttp({
        url: this.dokumenteUrl,
        urlParams: {
          dokart: this.foerderdokument.dokart,
        },
      }).then(
        response => {
          this.options.data = response;
          this.statusTableLoading = false;
          this.startSynchronisation({ response });
        }
      );
    },

    startSynchronisation({ response }) {
      for (let i = 0; i < response.length; i++) {
        if (response[i].is_sync) {
          this.checkSynchronization();
          break;
        }
      }
    },

    openModalDeleteDokument({ item, index }) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_FOERDERDOKUMENTE_DETAILS_DOKUMENTVERSIONEN_DELETE_MODAL_HEADER_",
        msg: "_TXT_FOERDERDOKUMENTE_DETAILS_DOKUMENTVERSIONEN_DELETE_MODAL_BODY_",
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: () => this.deleteDocument({ item, index }),
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.modal.delete = true;
    },

    closeConfirm() {
      this.modal.delete = false;
    },

    deleteDocument({ item, index }) {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: `${ this.dokumenteUrl }${ item.pk }/`,
      }).then(
        () => {
          EventBus.$emit(`deleteRow${ this.options.id }`, ({ index: index }));
          this.addNotification({ text: "_MSG_FOERDERDOKUMENTE_DETAILS_DOKUMENTVERSIONEN_DELETE_SUCCESS_" });
          this.closeConfirm();
        },
        () => {
          this.addNotification({
            text: "_MSG_FOERDERDOKUMENTE_DETAILS_DOKUMENTVERSIONEN_DELETE_ERROR_",
            type: "error",
          });
        }
      ).then(
        () => this.confirmOptions.loading = false
      );
    },

    updateDocument({ row, index }) {
      EventBus.$emit(`updateRow${ this.options.id }`, ({ row, index }));
    },

    isSyncing({ document }) {
      return document.is_sync === true;
    },

    closeUpload() {
      this.uploadAllowed = false;
    },

    openUpload() {
      this.uploadAllowed = true;
    },

    addInput({ currentModel }) {
      EventBus.$emit(`addRow${ this.options.id }`, ({ row: currentModel }));
      this.checkSynchronization();
    },

    checkSynchronization() {
      if (this.timer) {
        return;
      }
      setTimeout(() => {
        this.getHttp({
          url: this.dokumenteSyncingUrl,
        }).then(
          response => {
            const syncDocs = response.data || {};
            this.checkDocumentsInSync({ syncDocs });
            this.timer = undefined;
            if (response && response.data) {
              this.checkSynchronization();
            }
          }
        );
      }, 3000);
    },

    checkDocumentsInSync({ syncDocs }) {
      forEach(this.options.data, (item, index) => {
        if (item.is_sync && (!syncDocs[item.pk] || !syncDocs[item.pk].in_sync)) {
          this.options.data[index].is_sync = false;
          this.reloadDokument({ pk: item.pk, index });
        }
      });
    },

    reloadDokument({ pk, index }) {
      this.getHttp({
        url: `${ this.dokumenteUrl }${ pk }/meta_info/`
      }).then(
        response => {
          this.updateDocument({ row: response, index });
        }
      );
    },
  },
};
