import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  FilterSpacedMixin,
} from "../../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "VerbundenerAntragLink",
  components: {
    AngularLink,
    PuxTranslate,
  },
  mixins: [
    FilterSpacedMixin,
  ],
  props: {
    verbund: {
      type: Object,
      required: true,
    },
  },
  computed: {
    extraForAntragTranslate() {
      return {
        a_nr: this.filterSpaced(this.verbund.verbundener_antrag.a_nr),
        verbundart: this.verbund.verbundart_bez
      };
    },

    extraForAntragTitleTranslate() {
      return {
        a_nr: this.filterSpaced(this.verbund.verbundener_antrag.a_nr, true),
        verbundart: this.verbund.verbundart_bez
      };
    },
  },
};
