"use strict";

import BaseModel from "dias/core/models/baseModel";

class Nutzeraufgabe extends BaseModel {
  constructor() {
    super("aktuelle_aufgaben");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.batchZuweisen = (pks, nutzer) => {
      return collection.customPOST({ pks: pks, nutzer: nutzer }, "batch_zuweisen");
    };

    return collection;
  }

  extendModel(model) {
    model = super.extendModel(model);
    model.updateStatus = status => {
      return model.customPUT({ status: status }, "status_aendern").then(
        response => {
          model.status = response.status;
        }
      );
    };
    return model;
  }
}

export default Nutzeraufgabe;
