import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import {
  FilterSpacedMixin,
} from "../../../global/mixins/FiltersMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "SitzungsantraegeModalProtokollEdit",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    FilterSpacedMixin,
  ],
  props: {
    baseSaveUrl: {
      type: String,
      default: undefined,
    },
    sitzungsantrag: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      model: {
        sla_protokoll: undefined,
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "sla_protokoll",
            type: "richtext",
            required: true,
            label: "_TXT_SITZUNG_ANTRAG_PROTOKOLL_",
            view: "v-alt",
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  created() {
    this.loadProtokoll();
  },
  methods: {
    loadProtokoll() {
      this.getHttp({
        url: `${ this.baseSaveUrl }${ this.sitzungsantrag.pk }/protokoll_ansehen/`
      }).then(
        response => this.model.sla_protokoll = response.sla_protokoll
      ).then(
        () => this.status.loading = false
      );
    },

    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.putHttp({
        url: `${ this.baseSaveUrl }${ this.sitzungsantrag.pk }/protokoll_bearbeiten/`,
        data
      }).then(
        response => {
          this.addNotification({ text: "_MSG_SITZUNGSANTRAEGE_MODAL_PROTOKOLL_EDIT_SUCCESS_" });
          const NEW_SITZUNGSANTRAG = cloneDeep(this.sitzungsantrag);
          NEW_SITZUNGSANTRAG.sla_protokoll = response.sla_protokoll;
          this.close({ NEW_SITZUNGSANTRAG });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
