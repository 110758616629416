import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "SitzungAntraegeGoToBox",
  components: {
    AngularLink,
    AsideRightBox,
    PuxTranslate,
  },
  props: {
    antrag: {
      type: Object,
      required: true,
    },
    antragLabel: {
      type: String,
      required: true,
    },
    sitzungListeAntrag: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optionsAsideRightBox: {
        icon: "verbunden",
        active: true,
      },
    };
  },
  computed: {
    extraAntragLabelTranslate() {
      return {
        antragLabel: this.antragLabel,
      };
    },

    sitzung() {
      return this.sitzungListeAntrag.sitzung || {};
    },

    top() {
      return this.sitzungListeAntrag.top || {};
    },
  },
};
