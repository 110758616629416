import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import WorkflowfolgeclientfunktionenDetailsKonfiguration
  from "./WorkflowfolgeclientfunktionenDetailsKonfiguration/WorkflowfolgeclientfunktionenDetailsKonfiguration.vue";
import WorkflowfolgeclientfunktionenDetailsWorkflowfolgen
  from "./WorkflowfolgeclientfunktionenDetailsWorkflowfolgen/WorkflowfolgeclientfunktionenDetailsWorkflowfolgen.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../global/compositionAPI/NotificationAPI";

// @vue/component
export default {
  name: "WorkflowfolgeclientfunktionenDetails",
  components: {
    ContextPanel,
    Modal,
    Permission,
    PuxButton,
    PuxPageDetails,
    PuxTranslate,
    WorkflowfolgeclientfunktionenDetailsKonfiguration,
    WorkflowfolgeclientfunktionenDetailsWorkflowfolgen,
  },
  setup() {
    const {
      deleteHttp,
      getHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();
    return {
      addNotification,
      deleteHttp,
      getHttp,
    };
  },
  data() {
    return {
      loading: true,
      confirmShow: false,
      confirmOptions: {},
      clientfunktionen: {},
      panelClientfunktionOptions: {
        label: "_TXT_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_CLIENTFUNKTION_",
        icon: "glyphicon-file",
        active: true,
      },
      panelWorkflowfolgenOptions: {
        label: "_TXT_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_VERWENDUNG_IN_WORKFLOWFOLGEN_",
        icon: "glyphicon-file",
        active: false,
      },
      infoTexts: {
        bez: "_TXT_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_INFOTEXTS_BEZ_",
        modul: "_TXT_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_INFOTEXTS_MODUL_",
        parameterIn: "_TXT_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_INFOTEXTS_PARAMETER_IN_",
        parameterOut: "_TXT_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_INFOTEXTS_PARAMETER_OUT_",
      },
    };
  },
  computed: {
    baseUrl() {
      return `clientfunktionen/${ this.$stateParams.id }/`;
    },

    workflowfolgenUrl() {
      return `workflowfolgen/?clientfunktion=${ this.$stateParams.id }`;
    },
  },
  created() {
    this.loadClientfunktionen();
  },
  methods: {
    loadClientfunktionen() {
      this.getHttp({
        url: this.baseUrl,
      }).then(
        response => {
          this.clientfunktionen = response;
          this.loading = false;
        }
      );
    },

    updateClientfunktionen({ clientfunktionen }) {
      this.clientfunktionen = Object.assign({}, this.clientfunktionen, clientfunktionen);
    },

    openConfirmDelete() {
      this.confirmOptions = {
        id: "_confirm_delete_button_",
        okClass: "btn btn-primary",
        title: "_TXT_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_MODAL_DELETE_TITLE_{{name}}_",
        msg: "_MSG_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_MODAL_DELETE_MESSAGE_{{name}}_",
        okLabel: "_BTN_DELETE_",
        okCallback: this.deleteClientfunktionen,
        cancelCallback: this.closeConfirmDelete,
        extra: { name: this.clientfunktionen.wfc_bez },
        loading: false,
      };
      this.confirmShow = true;
    },

    closeConfirmDelete() {
      this.confirmShow = false;
    },

    deleteClientfunktionen() {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: this.baseUrl,
        data: this.clientfunktion,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_DELETE_SUCCESSFUL_",
          });
          this.$goTo("root.workflowfolgeclientfunktionen");
        },
        errors => {
          this.addNotification({
            type: "error",
            text: errors.data || "_MSG_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_DELETE_ERROR_",
          });
        }
      ).finally(
        () => this.confirmOptions.loading = false,
      );
    },
  }
};
