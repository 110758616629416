import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";

// @vue/component
export default {
  name: "Beschreibung",
  components: {
    FormstepDetail,
  },
  props: {
    organisation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        hideHeader: true,
      },
    };
  },
  methods: {

  },
};
