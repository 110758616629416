import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxAlert from "../../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxGet from "../../../../../global/components/PuxGet/PuxGet.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../../../global/components/ui/ShowMore/ShowMore.vue";

import PermissionAPI from "../../../../../global/compositionAPI/PermissionAPI";

// @vue/component
export default {
  name: "WorkflowtypDetailsStatusgruppenBlock",
  components: {
    AngularLink,
    PuxAlert,
    PuxGet,
    PuxTranslate,
    ShowMore,
  },
  props: {
    workflowGruppenBlock: {
      type: Object,
      required: true,
    },
    workflowGroupEmptyPlaceholderText: {
      type: String,
      required: true,
    },
    stepNumberIcon: {
      type: String,
      required: false,
      default: "",
    },
    boxWizardDetailClass: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup() {
    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      checkPermissionsSync,
    };
  },
  computed: {
    isGroupEmpty() {
      return !this.workflowGruppenBlock.workflowStatusGruppenTypen.length;
    },

    hasPermissionKaufgabeUi() {
      return this.checkPermissionsSync({ perm: "kaufgabe.ui" });
    },

    showStepNumber() {
      return !this.stepNumberIcon;
    },
  },
};
