import {
  forEach,
} from "lodash-es";

export default class Styles {
  constructor({
    model,
    colors,
    name,
    type,
  }) {
    this.model = model;
    this.colors = colors;
    this.name = name;
    this.type = type;

    this.modelWithColors = this.setModelWithColors();
    this.addCurrentStylesInHeader();
  }

  setModelWithColors() {
    const MODEL = {};
    forEach(this.model, (item, key) => {
      if (this.colors[item]) {
        MODEL[key] = this.colors[item];
      } else {
        MODEL[key] = item;
      }
    });
    return MODEL;
  }

  addCurrentStylesInHeader() {
    const STYLES_LOCAL = this[`getStyles_${ this.name }`]();
    const STYLE_ID = this.getStyleId();
    const STYLE_TEG = `<style id="${ STYLE_ID }">${ STYLES_LOCAL }</style>`;
    const CURRENT_STYLE = $(`#${ STYLE_ID }`);
    if (CURRENT_STYLE.length) {
      CURRENT_STYLE.replaceWith(STYLE_TEG);
    } else {
      $("head").append(STYLE_TEG);
    }
  }

  getStyleId() {
    return `styles_${ this.name }${ this.type ? `_${ this.type }` : "" }`;
  }

  getStyles_global_config() {
    return `
      .page_container,
      .filter_right__button_parent {
        background-color: ${ this.modelWithColors.bg };
      }
      @media screen {
        body {
          color: ${ this.modelWithColors.color };
        }
      }
      .btn:focus,
      .form_control:focus,
      .not-button:focus {
        box-shadow: 0 0 1px 4px ${ this.modelWithColors.shadowFocusColor } !important;
      }
      .box_highlight {
        box-shadow: 0 0 1px 4px ${ this.modelWithColors.shadowFocusColor };
      }
      a:focus {
        box-shadow: 0 0 1px 4px ${ this.modelWithColors.shadowFocusColor };
      }
      .category .category__button:focus,
      .pux_datepicker__panel_focus {
        box-shadow: 0 0 1px 4px ${ this.modelWithColors.shadowFocusColor };
      }
      .panel_menu__link_dropdown:focus,
      .panel_menu__dropdown_menu__link:focus {
        box-shadow: inset 0 0 0 4px ${ this.modelWithColors.shadowFocusColor };
      }
      .ui_select__element_clickable:focus,
      .menu_proandi__link:focus,
      .menu_proandi__level_2__link:focus,
      .pux_datepicker__panel__cell_focus,
      .pux_datepicker__calendar__tab:focus {
        box-shadow: inset 0 0 1px 3px ${ this.modelWithColors.shadowFocusColor };
      }
      .invisible-label .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: 0 0 1px 4px ${ this.modelWithColors.shadowFocusColor };
      }
      .page-link:focus {
        box-shadow: 0 0 1px 4px ${ this.modelWithColors.shadowFocusColor };
      }
    `;
  }

  getStyles_link() {
    return `
      a,
      .btn.btn-link {
        color: ${ this.modelWithColors.color };
        text-decoration: ${ this.modelWithColors.textDecoration };
      }
      a:hover,
      .btn.btn-link:hover {
        color: ${ this.modelWithColors.hoverColor };
        text-decoration: ${ this.modelWithColors.hoverTextDecoration };
      }
    `;
  }

  getStyles_alert() {
    return `
      .alert-success {
        color: ${ this.modelWithColors.successColor };
        background-color: ${ this.modelWithColors.successBg };
        border-color: ${ this.modelWithColors.successBorderColor };
      }
      .alert-danger {
        color: ${ this.modelWithColors.dangerColor };
        background-color: ${ this.modelWithColors.dangerBg };
        border-color: ${ this.modelWithColors.dangerBorderColor };
      }
      .alert-warning {
        color: ${ this.modelWithColors.warningColor };
        background-color: ${ this.modelWithColors.warningBg };
        border-color: ${ this.modelWithColors.warningBorderColor };
      }
      .alert-info {
        color: ${ this.modelWithColors.infoColor };
        background-color: ${ this.modelWithColors.infoBg };
        border-color: ${ this.modelWithColors.infoBorderColor };
      }
    `;
  }

  getStyles_tooltip() {
    return `
      .tooltip-inner {
        background-color: ${ this.modelWithColors.bg };
        color: ${ this.modelWithColors.color };
      }
      .vue-tooltip-theme[x-placement^="top"] .tooltip-arrow,
      .vue-tooltip-theme[data-popper-placement="top"] .tooltip-arrow,
      .vue-popover-theme[x-placement^="top"] .tooltip-arrow,
      .vue-popover-theme[data-popper-placement="top"] .tooltip-arrow {
        background-color: ${ this.modelWithColors.bg };
        box-shadow: 2px 2px 0 0 ${ this.modelWithColors.shadowColor };
      }
      .vue-tooltip-theme[x-placement^="bottom"] .tooltip-arrow,
      .vue-tooltip-theme[data-popper-placement="bottom"] .tooltip-arrow,
      .vue-popover-theme[x-placement^="bottom"] .tooltip-arrow,
      .vue-popover-theme[data-popper-placement="bottom"] .tooltip-arrow {
        background-color: ${ this.modelWithColors.bg };
        box-shadow: -1px -1px 0 0 ${ this.modelWithColors.shadowColor };
      }
      .vue-tooltip-theme[x-placement^="left"] .tooltip-arrow,
      .vue-tooltip-theme[data-popper-placement="left"] .tooltip-arrow,
      .vue-popover-theme[x-placement^="left"] .tooltip-arrow,
      .vue-popover-theme[data-popper-placement="left"] .tooltip-arrow {
        background-color: ${ this.modelWithColors.bg };
        box-shadow: 2px -2px 0 0 ${ this.modelWithColors.shadowColor };
      }
      .vue-tooltip-theme[x-placement^="right"] .tooltip-arrow,
      .vue-tooltip-theme[data-popper-placement="right"] .tooltip-arrow,
      .vue-popover-theme[x-placement^="right"] .tooltip-arrow,
      .vue-popover-theme[data-popper-placement="right"] .tooltip-arrow {
        background-color: ${ this.modelWithColors.bg };
        box-shadow: -1px 1px 0 0 ${ this.modelWithColors.shadowColor };
      }
      .vue-tooltip-theme .tooltip-inner,
      .vue-popover-theme .tooltip-inner {
        box-shadow: 2px 2px 0 0 ${ this.modelWithColors.shadowColor };
      }
    `;
  }

  getStyles_footer() {
    return `
      .panel_footer {
        background-color: ${ this.modelWithColors.bg };
        color: ${ this.modelWithColors.color };
      }
      @media (min-width: 992px) {
        .panel_footer,
        .panel_footer__parent {
          min-height: ${ this.modelWithColors.height }px;
          height: ${ this.modelWithColors.height }px;
        }
      }
      .extra-footer {
        margin-bottom: ${ this.modelWithColors.height }px;
      }
      #wrapper {
        min-height: calc(100vh - ${ this.modelWithColors.height }px);
      }
      @media screen {
        body .page-wrapper {
          min-height: calc(100vh - ${ this.modelWithColors.height }px);
        }
      }
      .menu_footer__link {
        color: ${ this.modelWithColors.linkColor };
        background-color: ${ this.modelWithColors.linkBg };
      }
      .menu_footer__link:hover,
      .menu_footer__link.current {
        color: ${ this.modelWithColors.hoverLinkColor };
        background-color: ${ this.modelWithColors.hoverLinkBg };
      }
      .menu_footer__link:hover:before,
      .menu_footer__link.current:before {
        color: ${ this.modelWithColors.hoverLinkColor };
      }
      .menu_footer__link:hover .menu_footer__text,
      .menu_footer__link.current .menu_footer__text {
        border-color: ${ this.modelWithColors.hoverLinkBorderColor };
        border-style: solid;
        border-width: ${ this.modelWithColors.hoverLinkBorderWidth };
      }
    `;
  }

  getStyles_navbar() {
    return `
      .panel_menu {
        background-color: ${ this.modelWithColors.bg };
        border: 0 solid ${ this.modelWithColors.borderColor };
        border-top-width: ${ this.modelWithColors.borderWidthTop }px;
        border-bottom-width: ${ this.modelWithColors.borderWidthBottom }px;
      }
      .panel_menu__logo {
        max-height: ${ this.modelWithColors.height }px;
        height: ${ this.modelWithColors.height }px;
      }
      .panel_menu__btn {
        height: ${ this.modelWithColors.linkHeight }px;
      }
      .body-row {
        margin-top: ${ +this.modelWithColors.borderWidthTop + +this.modelWithColors.borderWidthBottom + +this.modelWithColors.height }px;
      }
      .panel_menu__link {
        color: ${ this.modelWithColors.linkColor };
        max-height: ${ this.modelWithColors.linkHeight }px;
        font-weight: ${ this.modelWithColors.fontWeight };
      }
      .panel_menu__link:before {
        color: ${ this.modelWithColors.linkColor };
      }
      .panel_menu__link:hover {
        background-color: ${ this.modelWithColors.hoverLinkBg };
        color: ${ this.modelWithColors.hoverLinkColor };
      }
      .panel_menu__link:hover:before {
        color: ${ this.modelWithColors.hoverLinkColor };
      }
      .panel_menu__link_dropdown {
        background-color: ${ this.modelWithColors.profileBg };
        color: ${ this.modelWithColors.profileColor };
        max-height: ${ this.modelWithColors.linkHeight }px;
        font-weight: ${ this.modelWithColors.fontWeight };
      }
      .panel_menu__link_dropdown:hover {
         background-color: ${ this.modelWithColors.hoverProfileBg };
         color: ${ this.modelWithColors.hoverProfileColor };
      }
      .panel_menu__dropdown_menu__link {
        background-color: ${ this.modelWithColors.profileBg };
        color: ${ this.modelWithColors.profileColor };
        font-weight: ${ this.modelWithColors.fontWeight } !important;
      }
      .panel_menu__dropdown_menu__link:hover {
        background-color: ${ this.modelWithColors.hoverProfileBg };
        color: ${ this.modelWithColors.hoverProfileColor };
      }
    `;
  }

  getStyles_button() {
    let stylesLocal = `
      .btn-${ this.type } {
        background-color: ${ this.modelWithColors.bg };
        border-width: ${ this.modelWithColors["border-width"] };
        border-style: ${ this.modelWithColors["border-style"] };
        border-color: ${ this.modelWithColors["border-color"] };
        color: ${ this.modelWithColors.color };
        padding: ${ this.modelWithColors.padding };
        font-weight: ${ this.modelWithColors["font-weight"] };
        border-radius: ${ this.modelWithColors["border-radius"] };
      }
      .btn-${ this.type }:focus,
      .btn-${ this.type }.active:focus,
      .btn-${ this.type }:active:focus {
        background-color: ${ this.modelWithColors["focus-bg"] };
        border-width: ${ this.modelWithColors["focus-border-width"] };
        border-style: ${ this.modelWithColors["focus-border-style"] };
        border-color: ${ this.modelWithColors["focus-border-color"] };
        color: ${ this.modelWithColors["focus-color"] };
      }
      .btn-${ this.type }:hover,
      .btn-${ this.type }.active,
      .btn-${ this.type }:active,
      .btn-${ this.type }.active:hover,
      .btn-${ this.type }.active:active {
        background-color: ${ this.modelWithColors["hover-bg"] };
        border-width: ${ this.modelWithColors["hover-border-width"] };
        border-style: ${ this.modelWithColors["hover-border-style"] };
        border-color: ${ this.modelWithColors["hover-border-color"] };
        color: ${ this.modelWithColors["hover-color"] };
      }
      .btn-${ this.type }.disabled,
      .btn-${ this.type }.disabled:hover,
      .btn-${ this.type }.disabled:focus,
      .btn-${ this.type }.disabled.active,
      .btn-${ this.type }.disabled:active,
      .btn-${ this.type }[disabled],
      .btn-${ this.type }[disabled]:hover,
      .btn-${ this.type }[disabled]:focus,
      .btn-${ this.type }[disabled]:active,
      .btn-${ this.type }.active[disabled],
      fieldset[disabled] .btn-${ this.type },
      fieldset[disabled] .btn-${ this.type }:hover,
      fieldset[disabled] .btn-${ this.type }:focus,
      fieldset[disabled] .btn-${ this.type }:active,
      fieldset[disabled] .btn-${ this.type }.active {
        background-color: ${ this.modelWithColors["disabled-bg"] };
        border-width: ${ this.modelWithColors["disabled-border-width"] };
        border-style: ${ this.modelWithColors["disabled-border-style"] };
        border-color: ${ this.modelWithColors["disabled-border-color"] };
        color: ${ this.modelWithColors["disabled-color"] };
      }
    `;

    if (this.type === "switch") {
      stylesLocal += `
        .btn-${ this.type }.active:not(:disabled):not(.disabled),
        .btn-${ this.type }:not(:disabled):not(.disabled):active {
          background-color: ${ this.modelWithColors["active-bg"] };
          border-width: ${ this.modelWithColors["active-border-width"] };
          border-style: ${ this.modelWithColors["active-border-style"] };
          border-color: ${ this.modelWithColors["active-border-color"] };
          color: ${ this.modelWithColors["active-color"] };
        }
        .panel_context__icons_box__faqs.active {
          background-color: ${ this.modelWithColors["active-bg"] };
          color: ${ this.modelWithColors["active-color"] };
        }
      `;
    } else {
      stylesLocal += `
        .btn-${ this.type }.active:not(:disabled):not(.disabled),
        .btn-${ this.type }:not(:disabled):not(.disabled):active {
          background-color: ${ this.modelWithColors["hover-bg"] };
          border-width: ${ this.modelWithColors["hover-border-width"] };
          border-style: ${ this.modelWithColors["hover-border-style"] };
          border-color: ${ this.modelWithColors["hover-border-color"] };
          color: ${ this.modelWithColors["hover-color"] };
        }
      `;
    }
    if (this.type === "primary") {
      stylesLocal += `
        .timepicker.dropdown-menu button.btn-primary,
        .timepicker.dropdown-menu button.btn-primary:hover {
          border-width: ${ this.modelWithColors["hover-border-width"] };
          border-style: ${ this.modelWithColors["hover-border-style"] };
          border-color: ${ this.modelWithColors["hover-border-color"] };
        }
      `;
    } else if (this.type === "default") {
      stylesLocal += `
        .btn-icon.secondary-action {
          background-color: ${ this.modelWithColors.bg };
          border-width: ${ this.modelWithColors["border-width"] };
          border-style: ${ this.modelWithColors["border-style"] };
          border-color: ${ this.modelWithColors["border-color"] };
          color: ${ this.modelWithColors.color };
        }
        .btn-icon.secondary-action:hover {
          background-color: ${ this.modelWithColors["hover-bg"] };
          border-width: ${ this.modelWithColors["hover-border-width"] };
          border-style: ${ this.modelWithColors["hover-border-style"] };
          border-color: ${ this.modelWithColors["hover-border-color"] };
          color: ${ this.modelWithColors["hover-color"] };
        }
        .ui-select-toggle.btn-default:hover .ui_select__placeholder {
          color: ${ this.modelWithColors["hover-color"] } !important;
        }
      `;
    }
    return stylesLocal;
  }

  getStyles_menu() {
    return `
      .menu_proandi__li:hover {
        background-color: ${ this.modelWithColors.hoverBg };
      }
      .menu_proandi__li.active,
      .menu_proandi__li.current {
         background-color: ${ this.modelWithColors.activeBg };
      }
      .menu_proandi__li.active .menu_proandi__link,
      .menu_proandi__li.current .menu_proandi__link {
        color: ${ this.modelWithColors.activeColor };
      }
      .menu_proandi__li.active .menu_proandi__link:hover,
      .menu_proandi__li.current .menu_proandi__link:hover {
        color: ${ this.modelWithColors.hoverColor };
      }
      .menu_proandi__link:hover {
        color: ${ this.modelWithColors.hoverColor };
        background-color: ${ this.modelWithColors.hoverBg };
      }
      .menu_proandi_details__link {
        background-color: ${ this.modelWithColors.detailsBg };
        color: ${ this.modelWithColors.detailsColor } !important;
      }
      .menu_proandi_details__link:hover {
        color: ${ this.modelWithColors.detailsHoverColor } !important;
        background-color: ${ this.modelWithColors.detailsHoverBg };
      }
      .menu_proandi__text {
        width: ${ +this.modelWithColors.widthCloseSecondLevel + 1 }px;
        background: ${ this.modelWithColors.activeBg };
      }
      .menu_proandi__level_2__header {
        width: ${ this.modelWithColors.widthCloseSecondLevel }px;
        background-color: ${ this.modelWithColors.activeBg };
      }
      .menu_proandi__level_2 {
        width: ${ this.modelWithColors.widthCloseSecondLevel }px;
        border: 2px solid ${ this.modelWithColors.activeBg };
      }
      .menu_proandi__level_2__link:hover {
        color: ${ this.modelWithColors.hoverColor };
        background-color: ${ this.modelWithColors.hoverBg };
      }
      .menu_proandi_details__level_2__link:hover {
        background-color: ${ this.modelWithColors.detailsHoverBg };
        color: ${ this.modelWithColors.detailsHoverColor } !important;
      }
      .navbar-static-side.active .menu_proandi__li_has_children.active .menu_proandi__link {
        border-left: 8px solid ${ this.modelWithColors.activeBg };
        color: ${ this.modelWithColors.activeColor };
        background-color: ${ this.modelWithColors.activeBg };
      }
      .navbar-static-side.active .menu_proandi__li_has_children.active .menu_proandi__link:hover {
        background-color: ${ this.modelWithColors.hoverBg };
        color: ${ this.modelWithColors.hoverColor };
      }
      .navbar-static-side.active .menu_proandi__li_has_children.active .menu_proandi__link:hover:before {
        color: ${ this.modelWithColors.hoverColor };
      }
      .navbar-static-side.active .menu_proandi__li_has_children.active .menu_proandi_details__link {
        border-color: ${ this.modelWithColors.detailsBg };
        background-color: ${ this.modelWithColors.detailsBg };
      }
      .navbar-static-side.active .menu_proandi__li_has_children.active .menu_proandi_details__link:hover {
        background-color: ${ this.modelWithColors.detailsHoverBg };
      }
      .navbar-static-side.active .menu_proandi__level_2__item {
        border-left: 8px solid ${ this.modelWithColors.activeBg };
        border-top: 1px solid ${ this.modelWithColors.activeBg };
      }
      .navbar-static-side.active .menu_proandi_details__level_2__item {
        border-left-color: ${ this.modelWithColors.detailsBg };
        border-top-color: ${ this.modelWithColors.detailsBg };
      }
      .navbar-static-side.active .menu_proandi_details__level_2__item.current {
        background-color: ${ this.modelWithColors.detailsBg };
      }
      .navbar-static-side.active .menu_proandi__level_2__link:hover {
        color: ${ this.modelWithColors.hoverColor };
      }
      .menu_proandi__level_2__item {
        border-top: 2px solid ${ this.modelWithColors.activeBg };
      }
      .menu_proandi__level_2__item.current {
        color: ${ this.modelWithColors.activeColor };
        background-color: ${ this.modelWithColors.activeBg } !important;
      }
      .wrapper-content.active .panel_always_visible.fixed {
        left: ${ +this.modelWithColors.widthOpen + 40 }px;
        width: calc(100% - ${ +this.modelWithColors.widthOpen + 40 + 300 }px);
      }
      .wrapper-content.active .uncollapsed .panel_always_visible.fixed {
        width: calc(100% - ${ +this.modelWithColors.widthOpen + 40 + 40 }px);
      }
      .wrapper-content.active .full-screen .panel_always_visible.fixed,
      .wrapper-content.active .page_main_full .panel_always_visible.fixed {
        width: calc(100% - ${ +this.modelWithColors.widthOpen + 40 }px);
      }
      .navbar-static-side.active {
        width: ${ this.modelWithColors.widthOpen }px;
      }
      @media (min-width: 992px) {
        .wrapper-content.active {
          padding-left: ${ this.modelWithColors.widthOpen }px;
        }
      }
    `;
  }

  getStyles_antrag_steps() {
    return `
      .circle-box {
        border: 1px solid ${ this.modelWithColors.circleBoxBorderColor };
        color: ${ this.modelWithColors.circleBoxColor };
      }
    `;
  }
}

export function addStylesInHeader({
  config,
}) {
  if (!config) {
    return;
  }
  const COLORS = {};
  forEach(config.colors || [], color => {
    COLORS[color.key] = color.color;
  });
  const BUTTONS = {
    primary: config.button_primary || {},
    default: config.button_default || {},
    secondary: config.button_secondary || {},
    switch: config.button_switch || {},
  };
  ["primary", "default", "secondary", "switch"].forEach(buttonType => {
    new Styles({
      model: BUTTONS[buttonType],
      colors: COLORS,
      name: "button",
      type: buttonType,
    });
  });
  new Styles({
    model: config.global_config,
    colors: COLORS,
    name: "global_config",
    type: "main",
  });
  new Styles({
    model: config.alert,
    colors: COLORS,
    name: "alert",
    type: "main",
  });
  new Styles({
    model: config.link,
    colors: COLORS,
    name: "link",
    type: "main",
  });
  new Styles({
    model: config.tooltip,
    colors: COLORS,
    name: "tooltip",
    type: "main",
  });
  new Styles({
    model: config.antrag_steps,
    colors: COLORS,
    name: "antrag_steps",
    type: "main",
  });
  new Styles({
    model: config.menu,
    colors: COLORS,
    name: "menu",
    type: "main",
  });
  new Styles({
    model: config.navbar,
    colors: COLORS,
    name: "navbar",
    type: "main",
  });
  new Styles({
    model: config.footer,
    colors: COLORS,
    name: "footer",
    type: "main",
  });
}
