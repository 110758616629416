"use strict";

import "restangular";
import angular from "angular";

import CoreModels from "dias/core/models";
import DiasErrors from "dias/core/errors";
import Vertrag from "./vertrag.service";
import Auszahlung from "./auszahlungen.service";
import Auszahlungsplan from "./auszahlungsplan.service";
import Vertragsbeleg from "./vertragsbeleg.service";

import KVertragskostenbelegStatusUUIDs from "./kvertragskostenbelegstatusUUIDs.service";
import KAuszahlungsplanstatusUUIDs from "./kauszahlungsplanstatusUUIDs.service";
import KBelegePruefmethodeUUIDs from "./kbelegpruefmethodeUUIDs.service";

const modul = angular.module("dias.vertrag.models", [
  "restangular",
  CoreModels.name,
  DiasErrors.name
])

  .provider("Vertrag", Vertrag)
  .provider("Auszahlung", Auszahlung)
  .provider("Auszahlungsplan", Auszahlungsplan)
  .provider("Vertragsbeleg", Vertragsbeleg)

  .constant("KVertragskostenbelegStatusUUIDs", KVertragskostenbelegStatusUUIDs)
  .constant("KAuszahlungsplanstatusUUIDs", KAuszahlungsplanstatusUUIDs)
  .constant("KBelegePruefmethodeUUIDs", KBelegePruefmethodeUUIDs)
;

export default modul;
