import KFStandard from "../../../KFDetail/KFStandard/KFStandard.vue";
import FinanzierugMixin from "../../Mixins/FinanzierugMixin";

// @vue/component
export default {
  name: "KumastaProjektfinanzierung",
  components: {
    KFStandard,
  },
  mixins: [
    FinanzierugMixin,
  ],
  computed: {
    betragsrechner() {
      return this.modelParameter.betragsrechner || "kumasta_standardfinanzierungrechner";
    },

    sumLocal() {
      return this.antragBetragCurrency;
    },
  },
};
