import translate from "../../../../../../global/directives/translate";
import {
  FilterCurrencyMixin,
} from "../../../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "PersonalfunktionElementInListe",
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
  ],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getTranslateOptions() {
      return {
        text: this.getPlaceholder,
        title: this.getPlaceholder,
        extra: {
          bez: this.item.bez,
          obergrenze: this.filterCurrency(this.item.obergrenze),
        },
        debugger: true,
      };
    },

    getPlaceholder() {
      return this.item.obergrenze ?
        "_TXT_ANTRAG_DETAILS_PERSONAL_WIZARD_FUNKTION_IM_PROJEKT_ELEMENT_IN_LISTE_WITH_OBERGRENZE_{{bez}}_{{obergrenze}}_" :
        "_TXT_ANTRAG_DETAILS_PERSONAL_WIZARD_FUNKTION_IM_PROJEKT_ELEMENT_IN_LISTE_WITHOUT_OBERGRENZE_{{bez}}_";
    },
  },
};
