"use strict";

import styleguide from "./styleguide";

import angular from "angular";
import Navbar from "./navbar";
import Notification from "./notification";
import Datepicker from "./datepicker";
import DynamicElement from "./dynamicElement";
import Timepicker from "./timepicker";
import AngularMoment from "./momentjs";
import IBAN from "./iban";
import FormSteps from "./formsteps";
import Scroll from "./scroll";
import Select from "./select";
import Spaced from "./spaced";
import ExpandableList from "./expandableList";
import ZeroNumber from "./zeroNumber";
import AnchorOffset from "./anchorOffset";
import CheckTempAntraege from "./checkTempAntraege";
import Requirement from "./requirement";
import Print from "./print";
import PLZ from "./plz";
import VueInit from "./vueinit";
import VueStyleguide from "./vue-styleguide";
import Autosize from "./autosize";

const optModules = [];
if (styleguide && styleguide.name) {
  optModules.push(styleguide.name);
}
if (VueStyleguide && VueStyleguide.name) {
  optModules.push(VueStyleguide.name);
}

const commonModule = angular.module("app.common", [
  Navbar.name,
  Notification.name,
  Datepicker.name,
  DynamicElement.name,
  Timepicker.name,
  AngularMoment.name,
  IBAN.name,
  FormSteps.name,
  Scroll.name,
  Select.name,
  Spaced.name,
  ExpandableList.name,
  ZeroNumber.name,
  AnchorOffset.name,
  CheckTempAntraege.name,
  PLZ.name,
  Print.name,
  Requirement.name,
  VueInit.name,
  Autosize.name,
  ...optModules
]);

export default commonModule;
