"use strict";

import BaseController from "dias/foerderregeln/parameter/config.base.controller";

class ParametermoduleController extends BaseController {
  /*@ngInject*/
  constructor() {
    super();
  }
}

export default ParametermoduleController;
