"use strict";

import builder from "../utils/builder.js";

import template from "./detail.jade";
import EditController from "../standard_sitzungsmodule.edit.controller";

class TopProtrokollDetailController extends EditController {
  /*@ngInject*/
  constructor($scope, $q, AuthService) {
    super($scope, $q);
    this.AuthService = AuthService;
    this.hasViewPermission = this.canView();
    this.readonly = !this.canEdit();
  }

  canView() {
    return this.AuthService.syncHasPerm("sitzungen.protokoll.view", this.permissionSrc);
  }

  canEdit() {
    return this.AuthService.syncHasPerm("sitzungen.protokoll.update", this.permissionSrc);
  }
}

export default builder.detail({
  template: template(),
  controller: TopProtrokollDetailController,
  controllerAs: "vm"
});
