"use strict";

import angular from "angular";

import AnonymisierungsstatusUUIDs from "./anonymisierungsstatusUUIDs.service";

const serviceModule = angular.module("core.anonymisierung.services", [
])

  .constant("AnonymisierungsstatusUUIDs", AnonymisierungsstatusUUIDs)
;

export default serviceModule;
