import DynamischeTabelle from "../DynamischeTabelle/DynamischeTabelle.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import KofiErfassungBelegFormExtraField from "../../../KF/KofiErfassung/KofiErfassungBelegForm/ExtraField/ExtraField.vue";
import KofiErfassungBelegFormStandardField from "../../../KF/KofiErfassung/KofiErfassungBelegForm/StandardField/StandardField.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import ModuleMixin from "../ModuleMixin";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import { EventBus } from "../../../../global/functions/event-bus";

import {
  cloneDeep,
  filter,
  forEach,
  get,
  isEmpty,
  isNil,
  keyBy,
  keys,
  toNumber,
} from "lodash-es";

// @vue/component
export default {
  name: "BelegeDynamischeTabelle",
  components: {
    DynamischeTabelle,
    FormElement,
    FormElementReadOnly,
    KofiErfassungBelegFormExtraField,
    KofiErfassungBelegFormStandardField,
    Modal,
    SnapshotIcon,
    SnapshotModule,
    PuxAlert,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    ModuleMixin,
  ],
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      filterCurrency,
    };
  },
  data() {
    return {
      antrag: {},
      belegDokumente: [],
      classesHorizontal: [
        "col-sm-3 text-left text-sm-right",
        "col-sm-6",
        "offset-sm-3 col-sm-6",
      ],
      confirmDeleteOptions: undefined,
      errors: {},
      finanzierung: [],
      kosten: [],
      loading: false,
      model: {},
      modelEdit: {},
      optionsList: [],
      positionen: [],
      prefix: {
        kosten: {
          beleg: "vpb",
          position: "vkp",
          vertragsPos: "vertragskostenposition",
          art: "kostenart",
          bezeichnung: "kos",
          vkofi: "vk",
          artdetail: "kod",
          dokart: "2f413911-a2dc-c179-e050-007f0101705e",
          key: "kosten",
        },
        finanzierung: {
          beleg: "vfb",
          position: "vfp",
          vertragsPos: "vertragsfinanzierungposition",
          art: "finanzierungsart",
          bezeichnung: "fin",
          vkofi: "vf",
          artdetail: "fid",
          dokart: "5f681393-a2d3-4423-ba35-54d09156651b",
          dokument_required: false,
          key: "finanzierung",
        },
      },
      statusModalDelete: false,
      vertrag: {},
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.open;
      OPTIONS.saveCallback = this.saveLocal;
      OPTIONS.notCloseCallbackAfterSave = false;
      return OPTIONS;
    },

    belegOptionsListReadOnly() {
      const OPTIONS = cloneDeep(this.optionsList);
      OPTIONS.push(this.optionsForSelectPosition);
      OPTIONS.push(this.optionsBez);
      OPTIONS.push(this.optionsNr);
      OPTIONS.push(this.optionsBetrag);
      return OPTIONS;
    },

    belegOptionsList() {
      const OPTIONS = cloneDeep(this.optionsList);
      OPTIONS.push(
        this.optionsForSelectPosition
      );
      if (!isNil(get(this.modelEdit, "beleg.pos"))) {
        OPTIONS.push(this.optionsBez);
        if (this.isNummerFeldVisible) {
          OPTIONS.push(this.optionsNr);
        }
      }
      return OPTIONS;
    },

    errorsLabelsOptions() {
      const OPTIONS = [];
      OPTIONS.push(this.optionsForSelectPosition);
      OPTIONS.push(this.optionsBez);
      OPTIONS.push(this.optionsNr);
      OPTIONS.push(this.optionsBetrag);
      if (this.extraFelder) {
        forEach(keys(this.extraFelder), key => {
          OPTIONS.push(this.extraFelder[key]);
        });
      }
      if (this.standardFelder) {
        forEach(keys(this.standardFelder), key => {
          OPTIONS.push(this.standardFelder[key]);
        });
      }
      return OPTIONS;
    },

    labelHeader() {
      return this.modelParameter.frage;
    },

    idButtonDelete() {
      return `${ this.htmlRegelNummer }_delete_${ this.model.beleg.pk }`;
    },

    betrag() {
      if (!this.modelEdit.elemente) {
        return 0;
      }
      return this.modelEdit.elemente.reduce(
        (sum, curr) => sum + toNumber(curr[this.modelParameter.betrag_spalte]),
        0
      );
    },

    fremdWaehrungBetrag() {
      if (!this.model.elemente) {
        return 0;
      }
      return this.model.elemente.reduce(
        (sum, curr) => sum + toNumber(curr[this.modelParameter.fremdwaehrung_betrag_spalte]),
        0
      );
    },

    isModuleEditable() {
      return !this.statusReadonly && this.hasModulePositionen;
    },

    hasModulePositionen() {
      return !!this.positionen.length;
    },

    hasStandardFieldConfigs() {
      return get(this.currentPosition, "standardfelder");
    },

    hasExtraFieldsConfigs() {
      return get(this.currentPosition, "extrafelder");
    },

    optionsForSelectPosition() {
      return {
        id: "pos",
        type: "select",
        label: "_LBL_GR_BELEGE_DYNAMISCHE_TABELLE_POSITION_LABEL_",
        required: true,
        placeholder: "_LBL_GR_BELEGE_DYNAMISCHE_TABELLE_POSITION_PLACEHOLDER_",
        data: this.positionen,
        loading: this.loading,
        view: "v-alt",
        keyId: "pk",
        keyLabel: "bez",
        keyGroup: "art_bez",
        showLabel: true,
        change: this.onPositionChange,
        htmlId: `${ this.htmlRegelNummer }_pos`,
      };
    },

    optionsBez() {
      return {
        id: "bez",
        htmlId: `${ this.htmlRegelNummer }_bez`,
        type: "text",
        maxlength: 60,
        label: this.modelParameter.bezeichnung_default,
        required: true,
        showLabel: true,
        view: "v",
      };
    },

    optionsNr() {
      return {
        id: "nr",
        htmlId: `${ this.htmlRegelNummer }_nr`,
        type: "text",
        label: "_LBL_GR_BELEGE_DYNAMISCHE_TABELLE_NR_LABEL_",
        required: true,
        maxlength: 30,
        showLabel: true,
        view: "v",
      };
    },

    optionsBetrag() {
      return {
        id: "betrag",
        htmlId: `${ this.htmlRegelNummer }_betrag`,
        type: "float",
        label: "_LBL_GR_BELEGE_DYNAMISCHE_TABELLE_BETRAG_LABEL_",
        maxlength: 30,
        showLabel: true,
        disabled: true,
        addonBack: "_HTML_SUFFIX_EURO_",
        classesHorizontal: this.classesHorizontal,
        view: "h",
      };
    },

    kostenarten() {
      return this.modelParameter.kostenarten;
    },

    finanzarten() {
      return this.modelParameter.finanzarten;
    },

    kostenAPI() {
      if (get(this.obj, "vertrag")) {
        return `vertraege/${ this.obj.vertrag }/kosten/`;
      }
      return `vertraege/${ this.obj.pk }/kosten/`;
    },

    tooltipButtonDelete() {
      return "_BTN_GR_BELEGE_DYN_TABELLE_DELETE_TITLE_{{bez}}_";
    },

    finanzierungAPI() {
      if (get(this.obj, "vertrag")) {
        return `vertraege/${ this.obj.vertrag }/finanzierung/`;
      }
      return `vertraege/${ this.obj.pk }/finanzierung/`;
    },

    vertragAPI() {
      if (get(this.obj, "vertrag")) {
        return `vertraege/${ this.obj.vertrag }/`;
      }
      return `vertraege/${ this.obj.pk }/`;
    },

    antragAPI() {
      return `foerderantraege/${ this.vertrag.antrag_id }/`;
    },

    isNummerFeldVisible() {
      return !this.vertrag.v_belege_fortlaufend;
    },

    extraFelder() {
      return get(this.currentPosition, "extrafelder");
    },

    standardFelder() {
      return get(this.currentPosition, "standardfelder");
    },

    apiAntragDokumente() {
      if (get(this.currentPosition, "vkp_pos")) {
        return `${ this.antragAPI }dokumente/?dokart=${ this.prefix.kosten.dokart }`;
      }
      if (get(this.currentPosition, "vfp_pos")) {
        return `${ this.antragAPI }dokumente/?dokart=${ this.prefix.finanzierung.dokart }`;
      }
      return "";
    },

    extraForTranslate() {
      return {
        bez: get(this.model, "beleg.bez", ""),
      };
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(this.belegOptionsList, item => {
          const KEY = item.key || item.id;
          if (get(this.model, KEY) !== get(this.snapshotModule, KEY)) {
            DIFF[KEY] = true;
          }
        });
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },

    positionenKeyBy() {
      return keyBy(this.positionen, "pk");
    },

    currentPosition() {
      if (!isNil(this.model.beleg.pos)) {
        return get(this.positionenKeyBy, this.model.beleg.pos);
      }
      if (get(this.modelEdit, "beleg.pos")) {
        return get(this.positionenKeyBy, this.modelEdit.beleg.pos);
      }
      return undefined;
    },
  },
  created() {
    this.loading = true;
    this.loadVertrag();
    this.loadKosten();
    this.loadFinanzierung();
  },
  methods: {
    open() {
      this.modelEdit = cloneDeep(this.model) || {};
      this.modelEdit.beleg.betrag = this.betrag;
      if (this.positionen.length === 1) {
        this.setModelEditPos(this.positionen[0]);
        this.prepareStandardFieldsModel();
        this.prepareExtraFieldModel();
        this.loadBelegDokumente();
      }
    },

    loadKosten() {
      this.getHttp({ url: this.kostenAPI }).then(response => {
        this.kosten = filter(
          response.results,
          item => this.kostenarten.indexOf(item.kostenart.pk) !== -1
        );
        this.buildPositionen(this.kosten, "vkp", "kostenart");
      });
    },

    loadFinanzierung() {
      this.getHttp({ url: this.finanzierungAPI }).then(response => {
        this.finanzierung = filter(
          response.results,
          item => this.finanzarten.indexOf(item.finanzierungsart.pk) !== -1
        );
        this.buildPositionen(this.finanzierung, "vfp", "finanzierungsart");
        this.loading = false;
      });
    },

    loadVertrag() {
      this.getHttp({ url: this.vertragAPI }).then(
        response => {
          this.vertrag = response;
        }
      );
    },

    loadBelegDokumente() {
      this.getListHttp({
        url: this.apiAntragDokumente,
      }).then(
        response => {
          this.belegDokumente = response;
        }
      );
    },

    reloadBelegDokumente() {
      this.loadBelegDokumente();
    },

    buildPositionen(array, praefix, art) {
      forEach(array, element => {
        forEach(element.positionen, pos => {
          pos.art_bez = element[art].bez;
          if (praefix === "vkp") {
            pos.bez = pos.vkp_bez;
            pos.extrafelder = pos.antragkostenartinfo.antragsregel.argument.beleg_extra_fields.wert;
            pos.standardfelder = pos.antragkostenartinfo.antragsregel.argument.beleg_fields.wert;
            pos.art = "k";
          } else {
            pos.bez = pos.vfp_bez;
            pos.extrafelder = pos.antragfinanzierungsartinfo.antragsregel.argument.beleg_extra_fields.wert;
            pos.standardfelder = pos.antragfinanzierungsartinfo.antragsregel.argument.beleg_fields.wert;
            pos.art = "f";
          }
          this.positionen.push(pos);
        });
      });
    },

    onPositionChange({ item }) {
      this.setModelEditPos(item);
      this.prepareStandardFieldsModel();
      this.prepareExtraFieldModel();
      this.loadBelegDokumente();
    },

    setModelEditPos(pos) {
      this.modelEdit.beleg.pos = pos.pk;
      this.modelEdit.beleg.art = pos.art;
      if (this.modelParameter.fremdwaehrung_betrag_spalte && get(this.modelEdit, "standardfelder.landeswaehrung_betrag")) {
        this.modelEdit.standardfelder.landeswaehrung_betrag = this.fremdWaehrungBetrag;
      }
    },
    
    prepareModel() {
      const MODEL = cloneDeep(this.module.data) || {};
      MODEL.elemente = MODEL.elemente || [];
      MODEL.standardfelder = MODEL.standardfelder || {};
      MODEL.extrafelder = MODEL.extrafelder || {};
      MODEL.beleg = MODEL.beleg || {};
      MODEL.beleg.pk = MODEL.beleg.pk || undefined;
      MODEL.beleg.pos = MODEL.beleg.pos || undefined;
      MODEL.beleg.art = MODEL.beleg.art || undefined;
      MODEL.beleg.bez = MODEL.beleg.bez || undefined;
      MODEL.beleg.nr = MODEL.beleg.nr || undefined;
      MODEL.beleg.betrag = this.filterCurrency(MODEL.beleg.betrag || 0);
      this.model = cloneDeep(MODEL);
    },

    prepareStandardFieldsModel() {
      const MODEL = cloneDeep(this.modelEdit);
      if (get(this.currentPosition, "standardfelder")) {
        forEach(this.currentPosition.standardfelder, feld => {
          MODEL.standardfelder[feld.id] = MODEL.standardfelder[feld.id] || undefined;
          if (feld.id === "landeswaehrung_betrag" && this.modelParameter.fremdwaehrung_betrag_spalte) {
            delete MODEL.standardfelder[feld.id];
          }
        });
      }
      this.modelEdit = cloneDeep(MODEL);
    },

    prepareExtraFieldModel() {
      const MODEL = cloneDeep(this.modelEdit);
      if (get(this.currentPosition, "extrafelder")) {
        forEach(this.currentPosition.extrafelder, feld => {
          MODEL.extrafelder[feld.id] = MODEL.extrafelder[feld.id] || undefined;
        });
      }
      this.modelEdit = cloneDeep(MODEL);
    },

    changeModelExtraField({ model, id }) {
      this.modelEdit.extrafelder[id] = model;
    },

    changeModelStandardField({ model, id }) {
      this.modelEdit.standardfelder[id] = model;
    },

    updateModuleLocal({ response, extra, group }) {
      const RESPONSE = cloneDeep(response);
      const MODULE = cloneDeep(this.module);
      MODULE.data = RESPONSE.data;
      this.updateModule({ response: MODULE, extra, group });
      const MODEL_EDIT = cloneDeep(this.modelEdit);
      MODEL_EDIT.elemente = RESPONSE.data.elemente;
      this.modelEdit = MODEL_EDIT;
      this.modelEdit.beleg.betrag = this.betrag;
      if (get(this.modelEdit, "standardfelder.landeswaehrung_betrag")) {
        this.modelEdit.standardfelder.landeswaehrung_betrag = this.fremdWaehrungBetrag;
      }
    },

    deleteBelegLocal() {
      this.onDeleteBeleg({
        selectorClose: `#${ this.idButtonDelete }`,
      });
    },

    onDeleteBeleg({ selectorClose }) {
      this.selectorClose = [
        selectorClose,
        this.selectorHtmlRegelNummer,
      ];
      this.confirmDeleteOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_GR_BELEG_DYN_TABELLE_MODAL_DELETE_HEADER_{{bez}}_",
        msg: "_HTML_GR_BELEG_DYN_TABELLE_MODAL_DELETE_BODY_{{bez}}_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteBeleg(),
        cancelCallback: this.closeDeleteBeleg,
        loading: false,
        extra: this.extraForTranslate,
      };
      this.statusModalDelete = true;
    },

    deleteBeleg() {
      const data = {};
      this.confirmDeleteOptions.loading = true;
      this.errors = undefined;
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.moduleUrl,
          data,
        }).then(
          response => {
            this.onRestFunctions({ response });
            this.deleteErrors();
            if (this.modelEdit.beleg.art === "k") {
              EventBus.$emit("updateBeleg", { kosten: true });
            }
            if (this.modelEdit.beleg.art === "f") {
              EventBus.$emit("updateBeleg", { finanzierung: true });
            }
            this.onSaved();
            resolve(response);
          },
          errors => {
            reject(this.onError({ errors }));
          }
        );
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_GR_BELEGE_DYN_TABELLE_MODAL_DELETE_SUCCESS_{{bez}}_",
            extra: this.extraForTranslate,
          });
          this.modelEdit = cloneDeep(this.model);
          this.closeDeleteBeleg();
        },
        () => {
          this.addNotification({
            text: "_MSG_GR_BELEGE_DYN_TABELLE_MODAL_DELETE_ERROR_{{bez}}_",
            extra: this.extraForTranslate,
            type: "error",
          });
        }
      ).then(
        () => this.confirmDeleteOptions.loading = false
      );
    },

    closeDeleteBeleg() {
      this.statusModalDelete = false;
    },

    saveLocal() {
      const MODEL = cloneDeep(this.modelEdit);
      if (get(MODEL, "beleg")) {
        MODEL.beleg.betrag = this.betrag;
        this.modelEdit = cloneDeep(MODEL);
      }
      return this.save().then(
        () => {
          if (MODEL.beleg.art === "k") {
            EventBus.$emit("updateBeleg", { kosten: true });
          }
          if (MODEL.beleg.art === "f") {
            EventBus.$emit("updateBeleg", { finanzierung: true });
          }
        }
      );
    },
  },
};
