"use strict";

import BaseModel from "dias/core/models/baseModel";
import Regelsatz from "./regelsaetze.service";

class Foerderangebote extends BaseModel {
  constructor() {
    super("foerderangebote", {
      subPaths: {
        regelsaetze: new Regelsatz(),
        foerderdokumente: "foerderdokumente",
        kategorien: "kategorien"
      }
    });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.getDetailedPath = () => collection.one("").all("detailed");

    return collection;
  }

  extendModel(model) {
    model = super.extendModel(model);
    model.createNewRegelsatzVersion = regelsatzQuellePK => model.customPOST({ regelsatz_quelle_pk: regelsatzQuellePK }, "neue_regelsatz_version_erstellen");
    model.getRegelsaetze = ordering => model.regelsaetze.getList({ ordering: ordering });
    model.restangularizeRegelsatz = regelsatz => this.Restangular.restangularizeElement(model, regelsatz, "regelsaetze", {});
    return model;
  }
}

export default Foerderangebote;
