import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import { createNamespacedHelpers } from "vuex";
import {
  get,
} from "lodash-es";

const {
  mapMutations,
} = createNamespacedHelpers("snapshot");

// @vue/component
export default {
  name: "Verwendungszweck",
  components: {
    FormElement,
    FormstepDetail,
    PuxTranslate,
    SnapshotIcon,
    SnapshotModule,
  },
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    object: {
      type: Object,
      required: true,
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
    snapshotObjectName: {
      type: String,
      required: false,
      default: undefined,
    },
    snapshotPrefix: {
      type: String,
      required: false,
      default: undefined,
    },
    verwendungszweckKey: {
      type: String,
      required: false,
      default: "az_verwendungszweck",
    },
  },
  emits: [
    "updateObject",
  ],
  setup() {
    const {
      addNotification,
      putHttp,
    } = HttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      addNotification,
      checkPermissionsSync,
      putHttp,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        label: "_TXT_VERWENDUNGSZWECK_",
        info: "_TXT_AUSZAHLUNG_VERWENDUNGSZWECK_INFO_",
        list: [
          this.optionsForVerwendungszweck,
        ],
        required: true,
        openCallback: this.open,
        saveCallback: this.save,
      };
    },

    isVerwendungszweckEditable() {
      return this.checkPermissionsSync({
        perm: "auszahlung.verwendungszweck.update",
        permArray: this.userPermissions,
      });
    },

    userPermissions() {
      return this.object.user_permissions;
    },

    optionsForVerwendungszweck() {
      return {
        id: this.verwendungszweckKey,
        htmlId: `${ this.id }_${ this.verwendungszweckKey }`,
        label: "_TXT_VERWENDUNGSZWECK_",
        type: "text",
        view: "v",
        maxlength: "50",
        name: this.verwendungszweckKey,
        required: true,
        editonly: true,
      };
    },

    verwendungszweckValue() {
      return this.object[this.verwendungszweckKey];
    },

    urlForSave() {
      return `${ this.baseUrl }${ this.object.pk }/verwendungszweck_aendern/`;
    },

    isSnapshot() {
      return !!this.snapshot;
    },

    snapshotObject() {
      return get(this.snapshot, `${ this.snapshotPrefix }_snapshot.${ this.snapshotObjectName }`);
    },

    snapshotDate() {
      return get(this.snapshot, `${ this.snapshotPrefix }_snapshot_datum`);
    },

    verwendungszweckValueFromSnapshot() {
      return get(this.snapshotObject, this.verwendungszweckKey);
    },

    isDiff() {
      let isDiff = false;
      if (this.isSnapshot) {
        isDiff = this.verwendungszweckValue !== this.verwendungszweckValueFromSnapshot;
      }
      return isDiff;
    },
  },
  watch: {
    isDiff: {
      immediate: true,
      handler(newVal) {
        this.MUT_SET_STATUS_DIFF_FOR_REGEL({
          regelId: this.id,
          statusDiff: newVal,
        });
      },
    },
  },
  beforeUnmount() {
    this.MUT_DELETE_STATUS_DIFF_FOR_REGEL({ regelId: this.id });
  },
  methods: {
    open() {
      return {
        model: {
          [this.verwendungszweckKey]: this.verwendungszweckValue,
        },
      };
    },

    save({ model }) {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.urlForSave,
          data: model,
        }).then(
          response => {
            this.$emit("updateObject", response);
            this.addNotification({ text: "_MSG_UPDATE_SUCCESS_" });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },

    ...mapMutations([
      "MUT_SET_STATUS_DIFF_FOR_REGEL",
      "MUT_DELETE_STATUS_DIFF_FOR_REGEL",
    ]),
  },
};
