"use strict";

class ProjektberichteConfigController {
  /*@ngInject*/
  constructor($scope, ParameterUtils, Katalog) {
    this.$scope = $scope;
    this.ParameterUtils = ParameterUtils;
    this.Katalog = Katalog;
    $scope.$on(
      ParameterUtils.events.error,
      ($event, errors) => {
        if (angular.isUndefined(errors[this.key])) {
          return;
        }
        try {
          this.modulErrors = JSON.parse(errors[this.key]);
          errors[this.key] = ["Fehler in der Konfiguration mindestens einer Auszahlungsmodalität."];
        } catch (SyntaxError) {
          this.errors = errors[this.key][0];
        }
      }
    );
    this.init();
  }

  init() {
    if (angular.isUndefined(this.parameter[this.key].wert) || this.parameter[this.key].wert === null) {
      this.parameter[this.key].wert = [];
    }
    this.loading = true;
    this.Katalog.getKatalog("projektberichtvorlagen").then(
      result => {
        this.vorlagen = result;
      }
    ).finally(() => this.loading = false);
  }

  add() {
    this.parameter[this.key].wert.push({});
  }

  remove(idx) {
    this.parameter[this.key].wert.splice(idx, 1);
  }

  up(idx) {
    if (idx === 0) {
      return;
    }
    const newIdx = idx - 1;
    const el = this.parameter[this.key].wert[idx];
    this.remove(idx);
    this.parameter[this.key].wert.splice(newIdx, 0, el);
  }

  down(idx) {
    if (idx === this.parameter[this.key].wert.length - 1) {
      return;
    }
    const newIdx = idx + 1;
    const el = this.parameter[this.key].wert[idx];
    this.remove(idx);
    this.parameter[this.key].wert.splice(newIdx, 0, el);
  }

  preventFurtherAction($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }
}

export default ProjektberichteConfigController;
