import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import ChangePassword from "./ChangePassword/ChangePassword.vue";
import FormElement from "../../../global/components/FormElement/FormElement.vue";
import PuxAlert from "../../../global/components/PuxAlert/PuxAlert.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxRequired from "../../../global/components/PuxRequired/PuxRequired.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import translate from "../../../global/directives/translate";

import HttpMixin from "../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";
import UserMixin from "../../../global/mixins/UserMixin";

import {
  getCookie,
  deleteCookie,
} from "../../../global/functions/utils";

import { cloneDeep } from "lodash-es";

const PASSWORT_ABGELAUFEN_STATUS_ID = "2d4d03f5-6c2e-4ebe-b612-bdc544582739";

// @vue/component
export default {
  components: {
    AngularLink,
    ChangePassword,
    FormElement,
    PuxAlert,
    PuxButton,
    PuxRequired,
    PuxTranslate,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
    UserMixin,
  ],
  data() {
    return {
      statusLoading: true,
      statusPasswortAbgelaufen: undefined,
      statusPage: undefined,
      authenticating: undefined,
      model: {
        username: null,
        password: null,
      },
      usernameOptions: {
        id: "username",
        type: "email",
        view: "v-alt",
        label: "_LBL_LOGIN_BOX_USERNAME_",
        required: true,
        placeholder: "_LBL_LOGIN_BOX_USERNAME_",
        autocomplete: "email",
      },
      passwortOptions: {
        id: "password",
        type: "password",
        view: "v-alt",
        label: "_LBL_LOGIN_BOX_PASSWORT_",
        required: true,
        placeholder: "_LBL_LOGIN_BOX_PASSWORT_",
        autocomplete: "current-password",
      },
      error: undefined,
      warning: undefined,
      warningTranslate: undefined,
    };
  },
  computed: {
    isError() {
      return !!this.error;
    },

    isWarning() {
      return !!this.warning;
    },

    isWarningTranslate() {
      return !!this.warningTranslate;
    },
  },
  created() {
    this.initModel();
    this.checkUser();
  },
  methods: {
    initModel() {
      this.model = {
        username: getCookie("diasTempEmail") || null,
        password: null,
      };
    },

    checkUser() {
      this.getUser().then(
        user => {
          if (user && user.pk) {
            if (user.status.ns_id === PASSWORT_ABGELAUFEN_STATUS_ID) {
              if (this.statusPage) {
                this.statusPasswortAbgelaufen = true;
              } else {
                this.$goTo("root.login");
              }
            } else {
              if (this.$stateParams.next) {
                window.location.assign(this.$stateParams.next);
              } else {
                this.$goTo("root.home");
              }
            }
          }
          this.checkNotification();
          this.statusLoading = false;
        }
      );
    },

    checkNotification() {
      if (this.$stateParams.m === "session_expired") {
        this.warningTranslate = `_MSG_HTML_LOGIN_BOX_WARNING_SSESION_EXPIRED_`;
        this.warning = undefined;
      } else if (this.$stateParams.m === "datenschutz_declined") {
        const DATENSCHUTZERKLAER = this.getSyConfigsValue("datenschutzerklaer");
        if (DATENSCHUTZERKLAER && DATENSCHUTZERKLAER.hinweis_ablehnung) {
          this.warning = `<p>${ DATENSCHUTZERKLAER.hinweis_ablehnung }</p>`;
          this.warningTranslate = undefined;
        }
      }
    },

    deleteErrors() {
      this.error = undefined;
    },

    login() {
      if (this.authenticating) {
        return;
      }
      this.deleteErrors();
      deleteCookie("diasTempEmail");
      this.authenticating = true;
      this.postHttp({
        url: "auth/login/",
        data: this.model,
      }).then(
        response => {
          if (response.user) {
            localStorage.clear();
            this.MUT_SET_ME(cloneDeep(response.user));
            if (response.user.status && response.user.status.ns_id === PASSWORT_ABGELAUFEN_STATUS_ID) {
              if (this.statusPage) {
                this.statusPasswortAbgelaufen = true;
              } else {
                this.$goTo("root.login");
              }
            } else if (this.$stateParams.next) {
              window.location.replace(decodeURIComponent(this.$stateParams.next));
            } else {
              window.location.replace("/");
            }
          } else {
            this.error = response.error;
          }
        }
      ).then(
        () => this.authenticating = false
      );
    },
  },
};
