"use strict";
import Parameters from "../../../../../../vue/client/vue/const/StandardFoerdermoduleDynamischeTabelleParameters";

class DynamischeZahlenController {
  /*@ngInject*/
  constructor(DiasNotification, KDokart, Katalog, SyConfigs) {
    this.DiasNotification = DiasNotification;
    this.KDokart = KDokart;
    this.Katalog = Katalog;
    this.SyConfigs = SyConfigs;
    this.Parameters = Parameters;
    this.stylesList = ["text-right", "text-left", "text-center", "text-nowrap"];
    this.objektkatalogList = [];
    this.katalogList = [];
    this.katalogListObj = {};
    this.dokartList = [];
    this.wertList = [];
    this.modelEdit = {};
    this.statusEdit = {};
    this.allowedMimeTypeList = [];
    this.defaultValueList = [
      {
        label: "Aktiv",
        value: true,
      },
      {
        label: "Inaktiv",
        value: false,
      },
    ];
    this.objektkatalogCols = [];

    this.initAllowedMimeTypeList();
    this.initWertList();
    this.initialModel();
    this.loadDokumentarten();
    this.loadKatalogeList();
    this.loadObjektkatalogeList();
    this.updateObjektkatalogCols();
  }

  initAllowedMimeTypeList() {
    this.allowedMimeTypeList = this.SyConfigs.wert("allowed_mime_type");
  }

  initWertList() {
    if (!this.parameter[this.key].wert) {
      return;
    }
    _.forEach(_.cloneDeep(this.parameter[this.key].wert), item => {
      this.wertList.push(item);
    });
    this.wertList = _.orderBy(this.wertList, ["pos"], ["asc"]);
    _.forEach(this.wertList, (item, idx) => item.pos = idx);
  }

  loadObjektkatalogeList() {
    this.Katalog.one().get({ key: "kobjektkatalog" }).then(
      response => {
        this.objektkatalogList = response;
      }
    );
  }

  loadKatalogeList() {
    this.Katalog.one().get({ key: "katalog_mit_dynkatalog" }).then(
      response => {
        this.katalogList = response;
      }
    );
  }

  loadDokumentarten() {
    this.KDokart.getList().then(
      response => {
        this.dokartList = response;
      }
    );
  }

  addColumn() {
    const data = _.cloneDeep(this.model);
    if (_.find(this.wertList, v => v.id === data.id)) {
      this.newIdError = "new";
      return;
    }
    data.pos = this.wertList.length;
    this.wertList.push(data);
    this.initialModel();
    this.statusChangeId = false;
    this.newIdError = false;
    this.updateWert();
  }

  removeAntwort(position) {
    const CURRENT_INDEX = _.findIndex(this.wertList, ["pos", position]);
    this.wertList.splice(CURRENT_INDEX, 1);
    for (let i = 0; i < this.wertList.length; i++) {
      if (this.wertList[i].pos > position) {
        this.wertList[i].pos--;
      }
    }
    this.updateWert();
  }

  moveUpAntwort(position) {
    if (position === 0) {
      return;
    }
    const CURRENT_INDEX = _.findIndex(this.wertList, ["pos", position]);
    const CURRENT_INDEX_MINUS_1 = _.findIndex(this.wertList, ["pos", position - 1]);
    const elem = this.wertList[CURRENT_INDEX];
    elem.pos--;
    const previous = this.wertList[CURRENT_INDEX_MINUS_1];
    previous.pos++;
    this.wertList[CURRENT_INDEX_MINUS_1] = elem;
    this.wertList[CURRENT_INDEX] = previous;
    this.updateWert();
  }

  moveDownAntwort(position) {
    if (position >= this.wertList.length - 1) {
      return;
    }
    const CURRENT_INDEX = _.findIndex(this.wertList, ["pos", position]);
    const CURRENT_INDEX_PLUS_1 = _.findIndex(this.wertList, ["pos", position + 1]);
    const elem = this.wertList[CURRENT_INDEX];
    elem.pos++;
    const next = this.wertList[CURRENT_INDEX_PLUS_1];
    next.pos--;
    this.wertList[CURRENT_INDEX_PLUS_1] = elem;
    this.wertList[CURRENT_INDEX] = next;
    this.updateWert();
  }

  initialModel() {
    this.model = {
      label: "",
      id: "",
      pos: null,
      required: false,
      type: null,
      styles: [],
    };
  }

  isDisabled({ model }) {
    if (!model.label || !model.type || !model.id) {
      return true;
    }
    if (model.type === "document" && (!model.dokart || !model.allowed_mime_type.length)) {
      return true;
    }
    if (model.type === "decimal" && !model.decimal_places && !model.max_digits) {
      return true;
    }
    return (model.type === "single_choice" || model.type === "multi_select") && !model.katalog;
  }

  updateWert() {
    const wertObj = {};
    _.forEach(_.cloneDeep(this.wertList), (item, index) => {
      if (wertObj[item.id]) {
        wertObj[`${ item.id }_${ index }`] = item;
      } else {
        wertObj[item.id] = item;
      }
    });
    this.parameter[this.key].wert = wertObj;
    this.updateObjektkatalogCols();
  }

  changeLabel({ position }) {
    if (!this.statusChangeId && position === "new") {
      this.model.id = _.toLower(this.model.label).replace(/[^a-zA-Z0-9]/g, "");
      this.newIdError = false;
    }
  }

  changeId({ position }) {
    if (position !== "new") {
      return;
    }
    this.model.id = _.toLower(this.model.id).replace(/[^a-zA-Z0-9]/g, "");
    this.statusChangeId = true;
    this.newIdError = false;
  }

  changeKatalogDataId() {
    this.model.objektkatalog_data_id = _.toLower(this.model.objektkatalog_data_id).replace(/[^a-zA-Z0-9]/g, "");
  }

  updateObjektkatalogCols() {
    this.objektkatalogCols = _.cloneDeep(_.filter(this.wertList, col => col.type === "single_choice_objektkatalog"));
    _.forEach(this.wertList, col => {
      if (!_.isNil(col.objektkatalog_spalte_id)) {
        if (!_.find(this.objektkatalogCols, oCol => oCol.id === col.objektkatalog_spalte_id)) {
          col.objektkatalog_spalte_id = undefined;
        }
      }
    });
  }

  changeType({ position, model }) {
    const modelTemp = _.cloneDeep(model);
    const modelObj = {
      type: modelTemp.type,
      label: modelTemp.label,
      pos: modelTemp.pos,
      id: modelTemp.id,
      required: modelTemp.required,
      styles: modelTemp.styles,
    };
    if (modelObj.type === "decimal") {
      modelObj.decimal_places = modelTemp.decimal_places || 2;
      modelObj.max_digits = modelTemp.max_digits || 14;
      modelObj.min_value = modelTemp.min_value;
      modelObj.max_value = modelTemp.max_value;
      modelObj.sum = modelTemp.sum || false;
    } else if (modelObj.type === "number") {
      modelObj.min_value = modelTemp.min_value;
      modelObj.max_value = modelTemp.max_value;
      modelObj.sum = modelTemp.sum || false;
    } else if (modelObj.type === "single_choice" || modelObj.type === "multi_select") {
      modelObj.komplett = modelTemp.komplett || true;
      modelObj.ids = modelTemp.ids || [];
    } else if (modelObj.type === "document") {
      modelObj.allowed_mime_type = modelTemp.allowed_mime_type || [];
    } else if (modelObj.type === "math_expression") {
      modelObj.expression = modelTemp.expression || null;
      modelObj.decimal_places = modelTemp.decimal_places || 2;
      modelObj.max_digits = modelTemp.max_digits || 14;
      modelObj.min_value = modelTemp.min_value;
      modelObj.max_value = modelTemp.max_value;
    } else if (modelObj.type === "decimal_signal") {
      modelObj.signal_regex = modelTemp.signal_regex || null;
      modelObj.decimal_places = modelTemp.decimal_places || 2;
      modelObj.max_digits = modelTemp.max_digits || 14;
      modelObj.min_value = modelTemp.min_value;
      modelObj.max_value = modelTemp.max_value;
    } else if (modelObj.type === "currency") {
      modelObj.decimal_places = Math.min(modelTemp.decimal_places || 2, 2);
      modelObj.max_digits = modelTemp.max_digits || 14;
      modelObj.sum = modelTemp.sum || false;
      modelObj.min_value = modelTemp.min_value;
      modelObj.max_value = modelTemp.max_value;
    } else if (modelObj.type === "text" || modelObj.type === "richtext" || modelObj.type === "string") {
      modelObj.max_length = modelTemp.max_length;
    } else if (modelObj.typ === "text_objektkatalog_data") {
      modelObj.max_length = modelTemp.max_length;
      modelObj.objektkatalog_data_id = modelTemp.objektkatalog_data_id;
      modelObj.objektkatalog_spalte_id = modelTemp.objektkatalog_spalte_id || (this.objektkatalogCols.length === 1 ? this.objektkatalogCols[0].id : undefined);
    } else if (modelObj.typ === "decimal_objektkatalog_data") {
      modelObj.decimal_places = modelTemp.decimal_places || 2;
      modelObj.max_digits = modelTemp.max_digits || 14;
      modelObj.objektkatalog_data_id = modelTemp.objektkatalog_data_id;
      modelObj.objektkatalog_spalte_id = modelTemp.objektkatalog_spalte_id || (this.objektkatalogCols.length === 1 ? this.objektkatalogCols[0].id : undefined);
    }
    if (position === "new") {
      this.model = modelObj;
    } else {
      this.modelEdit[position] = modelObj;
    }
  }

  inputDecimalPlaces(model) {
    if (model.type === "currency") {
      model.decimal_places = Math.min(model.decimal_places, 2);
    }
    model.decimal_places = Math.max(0, model.decimal_places);
  }

  changeSelectKatalog({ position }) {
    if (position === "new") {
      this.model.komplett = true;
      this.model.ids = [];
    } else {
      this.modelEdit[position].komplett = true;
      this.modelEdit[position].ids = [];
    }
  }

  changeSelectKomplet({ position, model }) {
    if (model.komplett === false) {
      if (position === "new") {
        this.model.ids = [];
      } else {
        this.modelEdit[position].ids = [];
      }
      this.model.ids = [];
      this.loadCurrentKatalogeList({ model });
    }
  }

  loadCurrentKatalogeList({ model }) {
    if (this.katalogListObj[model.katalog]) {
      return;
    }
    this.Katalog.one().get({ key: model.katalog }).then(
      response => {
        this.katalogListObj[model.katalog] = response;
      }
    );
  }

  toggleEditMod({ position, antwort, status }) {
    if (status) {
      this.statusEdit[position] = false;
      return;
    }
    this.modelEdit[position] = _.cloneDeep(antwort);
    this.statusEdit[position] = true;
  }

  editColumn({ position }) {
    const CURRENT_INDEX = _.findIndex(this.wertList, ["pos", position]);
    const id = this.modelEdit[position].id;
    if (_.find(this.wertList, (v, idx) => v.id === id && idx !== CURRENT_INDEX)) {
      this.newIdError = CURRENT_INDEX;
      return;
    }
    this.wertList.splice(CURRENT_INDEX, 1, _.cloneDeep(this.modelEdit[position]));
    this.updateWert();
    this.toggleEditMod({ status: true, position });
  }
}

export default DynamischeZahlenController;
