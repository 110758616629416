"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import "restangular";
import detailComponent from "./detail.component";
import listComponent from "./list.component";
import historyComponent from "./history.component";
import simpleListComponent from "./simpleList.component";
import dokumenteSitzungComponent from "./dokumenteSitzung.component";
import ngFileUpload from "ng-file-upload";
import "ng-infinite-scroll";
import dokumentDownloadComponent from "./dokument.download.component";
import services from "./services";
import models from "./models";
import pipes from "./pipes";
import modul from "./modul";
import ngAnimate from "angular-animate";

const dokumenteModule = angular.module("dias.dokumente", [
  ngAnimate,
  uiRouter,
  "restangular",
  ngFileUpload,
  services.name,
  models.name,
  pipes.name,
  modul.name,
  "infinite-scroll"
]);

dokumenteModule.config($stateProvider => {
  $stateProvider

    .state("root.dokumentenupload1", {
      url: "/dokumentenupload1/",
      views: {
        "main@root": {
          template: "<dokumenten-upload></dokumenten-upload>",
        }
      },
      data: {
        permissions: "dokumentimport.view"
      }
    })

    .state("root.dokumentenupload", {
      url: "/dokumentenupload/",
      views: {
        "main@root": {
          template: "<div vueinit><vue-dokumente-upload></vue-dokumente-upload></div>",
        }
      },
      data: {
        permissions: "dokumentimport.view"
      }
    })
    .state("root.dokumentensuchen", {
      url: "/dokumentsuche/",
      views: {
        "main@root": {
          template: "<div vueinit><dokumente-suchen></dokumente-suchen></div>"
        }
      },
      data: {
        permissions: "dokumentsuche.view"
      }
    })
    .state("root.foerderdokumente", {
      url: `/foerderdokumente/?
          user_cookie&
          foerderangebot&
          dokart
    `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-foerderdokumente-list></vue-foerderdokumente-list></div>"
        }
      },
      data: {
        permissions: "foerderdokument.management"
      }
    })

    .state("root.foerderdokumente.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><foerderdokumente-details></foerderdokumente-details></div>"
        }
      },
      data: {
        permissions: "foerderdokument.management"
      }
    })
  ;
})

  .component("dokumente", detailComponent)
  .component("dokumenteList", listComponent)
  .component("dokumentehistory", historyComponent)
  .component("dokumenteSimpleList", simpleListComponent)
  .component("dokumentDownload", dokumentDownloadComponent)
  .component("dokumenteSitzung", dokumenteSitzungComponent)
;

export default dokumenteModule;
