import Alert from "../../../global/components/Alert/Alert.vue";

// @vue/component
export default {
  name: "StyleguideAlert",
  components: {
    Alert,
  },
  data() {
    return {
      optionsAlert1: {
        list: [
          {
            label: "Aloha",
            id: "aloha",
          },
          {
            label: "Bar",
            id: "foo",
          },
        ]
      },
      errors1: {
        aloha: ["Dieses Feld ist erforderlich."],
        foo: ["Dieses Feld ist erforderlich."],
      },

      optionsAlert2: {
        message: "Achtung!!! Das ist eine neue Nachricht.",
        type: "info",
      },
      errors2: {
        aloha: ["Dieses Feld ist erforderlich."],
        foo: ["Dieses Feld ist erforderlich."],
      },
      labelsAlert2: {
        aloha: "Aloha",
        foo: "Bar",
      },

      props: [
        {
          name: "errors",
          type: "Object",
          description: "Alle Fehler",
        },
        {
          name: "options",
          type: "Object",
          description: "Einstellungen",
        },
        {
          name: "labels",
          type: "Object",
          description: "Labels für Fehler",
        },
      ],

      options: [
        {
          name: "message",
          type: "String",
          description: "Überschrift",
          default: "Bitte überprüfen Sie Ihre Eingaben.",
          choices: [],
        },
        {
          name: "type",
          type: "String",
          description: "Klassname für Alert",
          default: "danger",
          choices: ["danger", "primary", "secondary", "success", "warning", "info", "light", "dark"],
        },
        {
          name: "labels",
          type: "Array",
          description: "Die Liste von Labels",
          default: "",
          choices: [],
        },
      ],
    };
  },
};
