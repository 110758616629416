// Modul zur Erfassung von Stückkosten
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "dias/kosten_finanzierung/kosten.controller";
import template from "dias/kosten_finanzierung/kosten.template.jade";
import FoerderantragModels from "dias/foerderantrag/models";
import BetragsrechnerRegistry from "dias/kosten_finanzierung/betragsrechner/services";

import builder from "dias/standard_foerdermodule/utils/builder";

const stueckkosten = angular.module("dias.kosten_finanzierung.stueckkosten", [
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
  BetragsrechnerRegistry.name,
]);

stueckkosten.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("stueckkosten", "modul-stueckkosten");
})

  .directive("modulStueckkosten", builder.directive(template, controller));

export default stueckkosten;
