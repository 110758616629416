"use strict";

import template from "./infoBox.jade";

class InfoBoxController {
  /*@ngInject*/
  constructor($scope, i18n) {
    this.gettext = i18n.gettext;
    this.statusShow = this.statusShow || false;

    $scope.$watch("vm.editMode", () => {
      if (!angular.isUndefined(this.editMode)) {
        this.statusShow = this.editMode;
      }
    });
  }


  toggleStatusShow() {
    this.statusShow = !this.statusShow;
  }
}

export default {
  template: template(),
  controller: InfoBoxController,
  controllerAs: "vm",
  bindings: {
    editable: "<?",
    editMode: "=?",
    editModeDisabled: "<?",
    idx: "<?",
    infoClass: "<?",
    infoData: "<?",
    infoLabelTemplate: "<?",
    infoTemplate: "<?",
    infoText: "<?",
    isMissingInput: "&?",
    isReadonly: "&?",
    name: "<?",
    namePrefix: "<?",
    rulesBez: "<?",
    rulesKbez: "<?",
    statusShow: "<?",
    toggleEditMode: "&?",
    tealiumTrackInfo: "=?",
    notizObj: "<?",
    notizWeitereObj: "<?",
    notizoptions: "<?",
    notizkey: "<?",
    notizen: "<?",
    regel: "<?",
  }
};
