"use strict";

import KostenFinanzierungBaseController from "../../kosten_finanzierung.base.controller";

class KostenController extends KostenFinanzierungBaseController {
  /*@ngInject*/
  constructor(
    $scope, 
    $q, 
    Foerdermodule, 
    Foerderantrag, 
    KKostenart,
    BetragsrechnerRegistry, 
    i18n, 
    Katalog
  ) {
    super($scope, $q, Foerdermodule, Foerderantrag, BetragsrechnerRegistry, i18n);
    this.angular = angular;
    this.$scope = $scope;
    this.addPositionMode = {};
    this.editPositionModes = {};
    this.positionen = {};
    this.antragKostenart = {};
    Katalog.getKatalog("personalfunktionen", { pk: this.parameter.personalfunktionen }).then(
      result => this.personalfunktionen = result
    );
  }

  toggleAddPosition(funktionPK) {
    this.addPositionMode[funktionPK] = !this.addPositionMode[funktionPK];
    if (this.addPositionMode[funktionPK]) {
      this.newPosition = this.initNewPosition();
    }
  }


  createPosition(funktionPK) {
    this.loading = true;
    return this._doPostPosition().then(
      response => {
        this.infoprovider = angular.extend(this.infoprovider, response.zuschussrechner);
        if (this.createErrors) {
          delete this.createErrors;
        }
      // this.createErrors = {};
        this._reloadPositionen();
        this.toggleAddPosition(funktionPK);
        this._resetNewPosition();
        this._doSavecallback();
        this.updateSnapshotDiff();
      },
      errors => {
        if (errors && errors.status !== 403) {
          this.createErrors = {};
          this.createErrors = errors.data;
        }
        return this.$q.reject(errors);
      }
    )
      .finally(() => this.loading = false);
  }
  getDefaultBetragsrechner() {
    return "personalkosten_lohnsteuer";
  }

  getPosBezeichnung(p) {
    return p.akp_bez;
  }

  getPosWert(p) {
    if (p) {
      return p.akp_wert || 0;
    }
    return 0;
  }

  getPosNr(p, funktion) {
    const i = this.personalfunktionen.indexOf(funktion) + 1;
    const j = this.positionen[funktion.pk].indexOf(p) + 1;
    return i + "." + j;
  }

  update_bez(model) {
    const kod = this.getKatalogDetails(model.kostenartdetail);
    if (kod && kod.kod_standardtext) {
      model.akp_bez = kod.kod_standardtext;
    }
  }

  _reloadPositionen() {
    this.loading = true;
    const antragId = this.modul.regel.afr_aid;
    const antragregelId = this.modul.regel.afr_id;
    this.Foerderantrag.one(antragId).getList("kostenarten", { antragregel: antragregelId }).then(result => {
      this.antragKostenart = result[0]; // sollte pro antragregel max. eine geben
      this.antragKoFiArt.bez = this.antragKostenart.kostenart.kos_kbez;
      this.antragKoFiArt.summe = this.antragKostenart.aka_summe;
      this.antragKoFiArt.gekappt = this.parameter.max_betrag && this.antragKostenart.aka_summe === this.parameter.max_betrag;
      this.antragKoFiArt.artPk = this.antragKostenart.kostenart.pk;
      this.antragKoFiArt.diffKey = "kostenarten";
      this.antragKoFiArt.pk = this.antragKostenart.pk;
      this.antragKostenart.getList("positionen", { ordering: "akp_pos" })
        .then(result_pos => this._setPositionen(result_pos))
        .finally(() => this.loading = false);
    });
  }

  _setPositionen(positionen) {
    super._setPositionen(positionen);
    this.positionen = {};
    angular.forEach(positionen, pos => {
      const funktion = pos.akp_argument.personalfunktion;
      if (this.positionen[funktion] === undefined) {
        this.positionen[funktion] = [];
      }
      this.positionen[funktion].push(pos);
    });
  }

  updatePosition(pos) {
    let promise;
    if (pos.pk) {
      promise = super.updatePosition(pos);
    } else {
      this.newPosition = pos;
      promise = super.createPosition();
    }
    promise.then(
      response => {
        if (this.updateErrors[pos.kostenartdetail]) {
          delete this.updateErrors[pos.kostenartdetail];
        }
        return response;
      },
      err => {
        if (pos.kostenartdetail) {
          this.updateErrors[pos.kostenartdetail] = err.data;
        }
        return err;
      }
    );
    return promise;
  }

  _doPostPosition() {
    return this.antragKostenart.customPOST(this.newPosition, "positionen");
  }

  initNewPosition() {
    const newPos = super.initNewPosition();
    newPos.kostenartdetail = "";
    return newPos;
  }

  searchInSnapshot(artPk, koFiArt, pk) {
    if (!this.snapshot) {
      return;
    }
    if (this.snapshotDiffPositionen[artPk]) {
      delete this.snapshotDiffPositionen[artPk];
    }
    if (this.snapshotPositionen[artPk]) {
      delete this.snapshotPositionen[artPk];
    }
    if (this.snapshotdiff && this.snapshotdiff.modified && this.snapshotdiff.modified[koFiArt]) {
      for (let i = 0; i < this.snapshotdiff.modified[koFiArt].length; i++) {
        if (this.snapshotdiff.modified[koFiArt][i].pk === pk) {
          this.snapshotDiffPositionen[artPk] = this.snapshotdiff.modified[koFiArt][i].positionen;
        }
      }
    }
    if (this.snapshot.asn_snapshot && this.snapshot.asn_snapshot[koFiArt]) {
      for (let i = 0; i < this.snapshot.asn_snapshot[koFiArt].length; i++) {
        if (this.snapshot.asn_snapshot[koFiArt][i].kostenart.kos_id === artPk) {
          this.snapshotPositionen[artPk] = this.snapshot.asn_snapshot[koFiArt][i].positionen;
          return this.snapshot.asn_snapshot[koFiArt][i].aka_summe;
        }
      }
    }

    return;
  }

  searchInPositionSnapshot(position, artPk) {
    if (!this.snapshot || !position) {
      return;
    }
    if (this.snapshotIcons[position.akp_id]) {
      delete this.snapshotIcons[position.akp_id];
    }
    if (this.snapshotPositionClass[position.akp_id]) {
      delete this.snapshotPositionClass[position.akp_id];
    }
    if (this.snapshotPositionName[position.akp_id]) {
      delete this.snapshotPositionName[position.akp_id];
    }
    for (let i = 0; this.snapshotPositionen[artPk] && i < this.snapshotPositionen[artPk].length; i++) {
      if (this.snapshotPositionen[artPk][i].akp_id === position.akp_id) {
        if (this.snapshotDiffPositionen[artPk] && this.snapshotDiffPositionen[artPk].length) {
          for (let j = 0; j < this.snapshotDiffPositionen[artPk].length; j++) {
            if (this.snapshotDiffPositionen[artPk][j].pk === position.akp_id && (this.snapshotDiffPositionen[artPk][j].akp_wert || this.snapshotDiffPositionen[artPk][j].akp_bez)) {
              this.snapshotIcons[position.akp_id] = "glyphicon-changes";
              this.snapshotPositionClass[position.akp_id] = true;
              if (this.snapshotDiffPositionen[artPk][j].akp_bez) {
                this.snapshotPositionName[position.akp_id] = this.snapshotPositionen[artPk][i].akp_bez;
              }
            }
          }
        }
        return this.snapshotPositionen[artPk][i].akp_wert;
      }
    }
    this.snapshotIcons[position.akp_id] = "glyphicon-changes-add";
    this.snapshotPositionClass[position.akp_id] = true;
    return "0";
  }

  isChanges() {
    if (this.snapshotdiff) {
      if (this.snapshotdiff.modified.finanzierungsarten || this.snapshotdiff.modified.kostenarten) {
        return true;
      } else if (this.snapshotdiff.added.finanzierungsarten || this.snapshotdiff.added.kostenarten) {
        return true;
      } else if (this.snapshotdiff.removed.finanzierungsarten || this.snapshotdiff.removed.kostenarten) {
        return true;
      }
    }
    return false;
  }

  showRemovePos(diffKey, pk) {
    if (this.snapshotdiff && this.snapshotdiff.removed[diffKey]) {
      for (let i = 0; i < this.snapshotdiff.removed[diffKey].length; i++) {
        if (this.snapshotdiff.removed[diffKey][i].pk === pk) {
          return this.snapshotdiff.removed[diffKey][i].positionen;
        }
      }
    }
  }
}

export default KostenController;
