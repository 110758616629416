"use strict";

export default () => num => {
  if (num === undefined) {
    return num;
  }
  num += "";
  const fValue = num.replace(/\[em\]/g, "<em>").replace(/\[\/em\]/g, "</em>");
  return fValue;
};
