var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<div dias-cloak=\"vm.loadingEditData\"><fieldset><div ng-attr-id=\"{{vm.errorContainerId}}\"></div><div class=\"row\"><div class=\"col-sm-6 col-12\">");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"do_orgname\" ng-model=\"vm.model.do_orgname\" type=\"text\" name=\"do_orgname\" maxlength=\"250\" tabindex=\"1\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.do_orgname ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "do_orgname", "{{ vm.gettext('_LBL_DUFO_MODAL_CREATE_FORM_') }}");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"do_strasse\" ng-model=\"vm.model.do_strasse\" type=\"text\" name=\"do_strasse\" maxlength=\"100\" tabindex=\"2\" ng-attr-aria-invalid=\"{{vm.errors.do_strasse ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "do_strasse", "Straße");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"do_hsnr\" ng-model=\"vm.model.do_hsnr\" type=\"text\" name=\"do_hsnr\" maxlength=\"10\" tabindex=\"3\" ng-attr-aria-invalid=\"{{vm.errors.do_hsnr ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "do_hsnr", "Hausnummer");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"do_plz\" ng-model=\"vm.model.do_plz\" type=\"text\" name=\"do_plz\" maxlength=\"10\" tabindex=\"4\" ng-attr-aria-invalid=\"{{vm.errors.do_plz ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "do_plz", "PLZ");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"do_ort\" ng-model=\"vm.model.do_ort\" type=\"text\" name=\"do_ort\" maxlength=\"100\" tabindex=\"5\" ng-attr-aria-invalid=\"{{vm.errors.do_ort ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "do_ort", "Ort");
jade_mixins["form-group"].call({
block: function(){
buf.push("<select id=\"bundesland\" name=\"bundesland\" ng-model=\"vm.model.bundesland_id\" ng-change=\"vm.onChangeBundesland()\" aria-required=\"false\" role=\"listbox\" ng-attr-aria-invalid=\"{{vm.errors.bundesland ? 'true' : 'false'}}\" class=\"form-control\"><option value=\"\">Ohne Bundesland</option><option ng-repeat=\"bundesland in vm.bundeslandChoices\" value=\"{{ bundesland.bun_id }}\">{{ bundesland.bun_bez }}</option></select>");
}
}, 'bundesland', 'Bundesland');
jade_mixins["form-group"].call({
block: function(){
buf.push("<select id=\"lkz\" name=\"lkz\" ng-model=\"vm.model.lkz\" ng-change=\"vm.model.land.lkz_bez = vm.lkzChoices.getById(vm.model.lkz).lkz_bez\" tabindex=\"6\" role=\"listbox\" ng-attr-aria-invalid=\"{{vm.errors.lkz ? 'true' : 'false'}}\" class=\"form-control\"><option value=\"\">---------</option><option ng-repeat=\"lkz in vm.lkzChoices\" value=\"{{ lkz.pk }}\">{{ lkz.lkz_bez }}</option></select>");
}
}, 'lkz', 'Land');
buf.push("</div><div class=\"col-sm-6 col-12\">");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<textarea id=\"do_beschreibung\" ng-model=\"vm.model.do_beschreibung\" name=\"do_beschreibung\" maxlength=\"4000\" tabindex=\"7\" dias-richtext aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.do_beschreibung ? 'true' : 'false'}}\" class=\"form-control\"></textarea>");
}
}, 'do_beschreibung', 'Beschreibung');
buf.push("</div></div></fieldset></div><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save, vm.errorsLabel)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button dias-confirm confirm-title=\"{{ vm.gettext('_TXT_DUFO_DELETE_MODAL_HEADER_') }}\" confirm-type=\"danger\" confirm-msg=\"Wollen Sie {{ vm.gettext('_LBL_MSG_DELETE_DUFO_') }} {{vm.model.do_orgname}} wirklich löschen?\" confirm-ok=\"setEditMode(false, vm.remove)\" confirm-ok-label=\"Löschen\" confirm-ok-icon=\"glyphicon-trash\" confirm-switch-btn-pos=\"true\" title=\"{{ vm.gettext('_TXT_DELETE_DUFO_TITLE_') }}\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-default\"><span>Löschen</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" class=\"btn btn-default\"><span>Abbrechen</span></button></div></div>");;return buf.join("");
}