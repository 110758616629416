import FormstepDetail from "../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../global/compositionAPI/NotificationAPI";
import PermissionAPI from "../../../global/compositionAPI/PermissionAPI";

import {
  gettext,
} from "../../../global/functions/utils";

import { isNil } from "lodash-es";
// @vue/component
export default {
  name: "FoerderorganisationBudget",
  components: {
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    organisation: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      getHttp,
      putHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      addNotification,
      checkPermissionsSync,
      getHttp,
      putHttp,
    };
  },
  data: () => {
    return {
      budget: undefined,
      budgetKontoBez: undefined,
      budgetSaldo: undefined,
      loading: undefined,
      kontos: undefined,
      selectedKontoPk: undefined,
      errors: undefined,
    };
  },
  computed: {
    htmlId() {
      return `org_${ this.organisation.pk }_budget_rubric`;
    },

    budgetUrl() {
      return `foerderorganisationen/${ this.organisation.pk }/budget/`;
    },

    budgetUpdateUrl() {
      return `foerderorganisationen/${ this.organisation.pk }/budget_update/`;
    },

    katalogUrl() {
      return "katalog/";
    },

    formstepDetailOptions() {
      return {
        label: "_LBL_FOERDERORGANISATION_DETAILS_KONTO_",
        saveCallback: this.save,
      };
    },

    bezeichnung() {
      return !isNil(this.budgetKontoBez) ? this.budgetKontoBez : gettext("_TXT_KEINE_ANGABE_");
    },

    saldoHaben() {
      return !isNil(this.budgetSaldo) ? this.budgetSaldo.haben : gettext("_TXT_KEINE_ANGABE_");
    },

    saldoSoll() {
      return !isNil(this.budgetSaldo) ? this.budgetSaldo.soll : gettext("_TXT_KEINE_ANGABE_");
    },

    saldoDiff() {
      return !isNil(this.budgetSaldo) ? this.budgetSaldo.diff : gettext("_TXT_KEINE_ANGABE_");
    },

    isEditable() {
      return this.checkPermissionsSync({
        perm: "foerderorg.budgetkonto.update",
        permArray: this.organisation.user_permissions
      });
    },

    canSeeSaldo() {
      return this.checkPermissionsSync({
        perm: "foerderorg.saldo.view",
        permArray: this.organisation.user_permissions
      });
    },

    canSeeBudget() {
      return this.checkPermissionsSync({
        perm: "foerderorg.budgetkonto.view",
        permArray: this.organisation.user_permissions,
      });
    },

    optionsForSelectKonto() {
      return {
        id: `${ this.htmlId }_budget_konto_select`,
        type: "select",
        label: "_LBL_FOERDERORGANISATION_BUDGET_RUBRIK_SELECT_KONTO_LABEL_",
        required: true,
        placeholder: "_LBL_FOERDERORGANISATION_BUDGET_RUBRIK_SELECT_KONTO_PLACEHOLDER_",
        data: this.kontos,
        loading: this.loading,
        view: "v-alt",
        keyId: "pk",
        keyLabel: "bez",
        showLabel: true,
        change: this.onChange,
        htmlId: `${ this.htmlId }_budget_konto_select`,
      };
    },

  },
  created() {
    this.loadData();
    this.loadKontos();
  },
  methods: {
    loadData() {
      if (this.canSeeBudget) {
        this.loading = true;
        this.getHttp({ url: this.budgetUrl }).then(
          response => {
            this.selectedKontoPk = response.budgetkonto;
            this.budgetKontoBez = response.budgetkonto_bez;
            this.budgetSaldo = response.budgetkonto_saldo;
          }
        ).finally(() => this.loading = false);
      }
    },

    loadKontos() {
      this.loading = true;
      this.getHttp({ url: this.katalogUrl, urlParams: {
        key: "fibu_konto",
      } }).then(response => this.kontos = response);
    },

    onChange({ item }) {
      this.selectedKontoPk = item.pk;
    },

    save() {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.budgetUpdateUrl,
          data: { budgetkonto: this.selectedKontoPk },
        }).then(
          response => {
            this.selectedKontoPk = response.budgetkonto;
            this.budgetKontoBez = response.budgetkonto_bez;
            this.budgetSaldo = response.budgetkonto_saldo;
            this.addNotification({ text: "_MSG_UPDATE_SUCCESS_" });
            resolve();
          },
          error => {
            this.errors = error.data;
            reject(error);
          }
        );
      });
    },
  }
};
