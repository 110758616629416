import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PanelComponents from "./PanelComponents";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import {
  initModelFromList,
  toFormElementFromParameter,
} from "../../../global/functions/mappingForParameterToFormElement";
import {
  assign,
  cloneDeep,
  get,
  isNil,
  isString,
} from "lodash-es";

// @vue/component
export default {
  name: "WorkflowfolgestatusfunktionenDetails",
  components: {
    ContextPanel,
    Modal,
    Permission,
    PuxButton,
    PuxPageDetails,
    PuxTranslate,
    ...PanelComponents,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  data() {
    return {
      loading: true,
      reloading: undefined,
      statusfunktion: undefined,
      showConfirmModal: false,
      confirmOptions: undefined,
      confirmErrors: undefined,
      idForDeleteButton: "statusfunktion_modal_confirm_delete",
      panels: [
        {
          label: "_TXT_STATUSFUNKTION_DETAILS_BASISDATEN_PANEL_HEADER_",
          icon: "glyphicon-list",
          active: true,
          pk: "statusfunktion",
          components: [
            {
              name: "NameUndModul",
              pk: "NameUndModul",
            },
            {
              name: "Konfiguration",
              pk: "Konfiguration",
            },
          ],
        },
        {
          label: "_TXT_STATUSFUNKTION_DETAILS_VERWENDUNGEN_PANEL_HEADER_",
          icon: "glyphicon-table",
          active: false,
          pk: "verwendungen",
          components: [
            {
              name: "VerwendungList",
              pk: "VerwendungList",
            },
          ],
        },
      ],
    };
  },
  computed: {
    translationPageTitle() {
      return {
        text: "_PAGE_TITLE_STATUSFUNKTION_DETAILS_{{bez}}_",
        extra: { bez: get(this.statusfunktion, "wts_bez", "") }
      };
    },

    translationHeader() {
      return {
        text: "_TXT_STATUSFUNKTION_DETAILS_HEADER_{{bez}}_",
        extra: { bez: get(this.statusfunktion, "wts_bez", "") }
      };
    },

    translationBtnDelete() {
      return {
        title: "_BTN_STATUSFUNKTION_DETAILS_DELETE_TITLE_",
        text: "_BTN_STATUSFUNKTION_DETAILS_DELETE_LABEL_"
      };
    },

    selectorClose() {
      return `#${ this.idForDeleteButton }`;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData({ reload } = {}) {
      this.reloading = reload;
      this.getHttp({
        url: `statusfunktionen/${ this.$stateParams.id }/`,
      }).then(
        response => {
          this.updateStatusfunktion({ statusfunktion: response });
        }
      ).finally(
        () => {
          this.loading = false;
          this.reloading = false;
        }
      );
    },

    removeStatusfunktion() {
      this.reloading = true;
      this.confirmErrors = undefined;
      this.deleteHttp({
        url: `statusfunktionen/${ this.$stateParams.id }/`,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_STATUSFUNKTION_DETAILS_DELETE_SUCCESS_{{bez}}_",
            extra: {
              bez: get(this.statusfunktion, "wts_bez", "")
            },
          });
          this.closeConfirm();
          this.$goTo("root.workflowfolgestatusfunktionen");
        },
        err => {
          this.addNotification({
            text: "_MSG_STATUSFUNKTION_DETAILS_DELETE_ERROR_{{bez}}_",
            type: "error",
            extra: {
              bez: get(this.statusfunktion, "wts_bez", "")
            }
          });
          if (isString(err.data)) {
            this.confirmErrors = { non_field_errors: err.data };
          } else {
            this.confirmErrors = err.data;
          }
        },
      ).finally(
        () => {
          this.reloading = false;
        }
      );
    },

    updateStatusfunktion({ statusfunktion }) {
      const STATUSFUNKTION = cloneDeep(statusfunktion);
      if (!isNil(STATUSFUNKTION.config_in)) {
        const CONF = toFormElementFromParameter({ obj: STATUSFUNKTION.config_in }).list;
        STATUSFUNKTION.wts_parameterin = assign(initModelFromList({ list: CONF }), STATUSFUNKTION.wts_parameterin || {});
      }
      this.statusfunktion = STATUSFUNKTION;
    },

    confirmDeleteStatusfunktion() {
      this.confirmOptions = {
        okClass: "btn btn-danger",
        title: "_TXT_STATUSFUNKTION_DETAILS_DELETE_HEADER_{{bez}}_",
        msg: "_TXT_STATUSFUNKTION_DETAILS_DELETE_BODY_{{bez}}_",
        extra: {
          bez: get(this.statusfunktion, "wts_bez", "")
        },
        okLabel: "_BTN_DELETE_",
        okCallback: this.removeStatusfunktion,
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.showConfirmModal = true;
    },

    closeConfirm() {
      this.confirmOptions = undefined;
      this.showConfirmModal = false;
      this.confirmErrors = undefined;
    },
  },
};
