import PuxButton from "../../../../../../../global/components/PuxButton/PuxButton.vue";

import translate from "../../../../../../../global/directives/translate";

import {
  cloneDeep,
  findIndex,
} from "lodash-es";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    PuxButton,
  },
  directives: {
    translate,
  },
  props: {
    model: {
      type: Array,
      default: undefined,
    },
    currentModel: {
      type: Object,
      default: undefined,
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modelLocal: {
        value: undefined,
        label: undefined
      },
    };
  },
  computed: {
    getHeader() {
      return this.currentModel
      ? "_TXT_UI_ANTRAG_SCORE_OPTIONS_LIST_MODAL_UPDATE_TITLE_"
      : "_TXT_UI_ANTRAG_SCORE_OPTIONS_LIST_MODAL_CREATE_TITLE_";
    },
    options() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "id",
            htmlId: `antrag_score_kontext_konfig_id`,
            type: "text",
            label: "_LBL_ANTRAG_SCORE_NUM_KONTEXT_ID_",
            helpText: "_TXT_ANTRAG_SCORE_NUM_KONTEXT_ID_HELP_TEXT_",
            showLabel: true,
            translate: true,
            required: true,
            view: "v",
          }, {
            id: "regel",
            htmlId: `antrag_score_kontext_konfig_regel`,
            type: "single_choice",
            label: "_LBL_ANTRAG_SCORE_NUM_KONTEXT_REGEL_",
            helpText: "_TXT_ANTRAG_SCORE_NUM_KONTEXT_REGEL_HELP_TEXT_",
            url: "katalog/?key=foerderregel",
            urlParams: {
              suche: [
                "dynamische_zahlen",
                "dynamische_tabelle",
                "dynamische_mehrfachauswahl",
              ]
            },
            keyId: "pk",
            keyLabel: "bez",
            keyGroup: "group",
            search: true,
            searchList: ["bez", "group"],
            showLabel: true,
            translate: true,
            required: true,
            view: "v",
          }, {
            id: "regex",
            htmlId: `antrag_score_kontext_konfig_regex`,
            type: "text",
            label: "_LBL_ANTRAG_SCORE_NUM_KONTEXT_REGEX_",
            helpText: "_TXT_ANTRAG_SCORE_NUM_KONTEXT_REGEX_HELP_TEXT_",
            showLabel: true,
            translate: true,
            required: true,
            view: "v",
          }, {
            id: "default",
            htmlId: `antrag_score_kontext_konfig_default`,
            type: "float",
            label: "_LBL_ANTRAG_SCORE_NUM_KONTEXT_DEFAULT_",
            helpText: "_TXT_ANTRAG_SCORE_NUM_KONTEXT_DEFAULT_HELP_TEXT_",
            digits: 6,
            showLabel: true,
            translate: true,
            required: false,
            view: "v",
          },
        ],
      };
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.currentModel) {
        this.modelLocal = cloneDeep(this.currentModel);
      }
    },

    save() {
      if (this.isDisabledSaveButton) {
        return;
      }
      const DATA = cloneDeep(this.modelLocal) || {};

      const MODEL = cloneDeep(this.model) || [];

      if (this.currentModel) {
        const CURRENT_IDX = findIndex(this.model, el => el.id === this.currentModel.id);
        MODEL[CURRENT_IDX] = DATA;
      } else {
        MODEL.push(DATA);
      }
      this.close({ model: MODEL });
    },
  },
};
