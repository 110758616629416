import GeschaeftsregelModul from "../../../Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";

import SyConfigAPI from "../../../../global/compositionAPI/SyConfigAPI";

import {
  getTranslatedText,
} from "../../../../global/functions/utils";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "SitzungAntraegeAnlagen",
  components: {
    GeschaeftsregelModul,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    obj: {
      type: Object,
      required: true,
    },
    infoText: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup() {
    const {
      getSyConfigsValue,
    } = SyConfigAPI();

    const documentAnzeige = getSyConfigsValue("sitzungsantraege", {}).anzeige;

    return {
      documentAnzeige,
    };
  },
  data() {
    return {
      dokumentePermissionsFromObj: {
        view: "dokument.view",
        create: "dokument.create",
        delete: "dokument.delete",
        update: "dokument.update",
        update_gueltigkeit: "",
      },
    };
  },
  computed: {
    parameter() {
      return {
        frage: {
          typ: "string",
          wert: null,
        },
        header_info: {
          typ: "string",
          wert: getTranslatedText(this.infoText),
        },
        readonly: {
          typ: "boolean",
          wert: false,
        },
        anzeigeart: {
          typ: "single_choice",
          wert: this.documentAnzeige,
          katalog: "anzeigeart",
        },
        pflichtfeld: {
          typ: "boolean",
          wert: true,
        },
        default_sort: {
          typ: "single_choice",
          wert: "-dokument.crdate",
        },
        antragsteller: {
          typ: "boolean",
          wert: false,
        },
        anzeige_details: {
          typ: "multi_select",
          wert: [
            "dokument.dok_titel",
            "dokument.dokumentart.name",
            "dokument.dok_posteingang",
            "pruefstatus.status",
            "pruefstatus_gesetzt_am",
            "geprueft_von",
            "dokument.crdate",
            "dokument.user",
            "dokument.dok_loeschdatum",
            "dokument.dok_dokumentname",
            "dokument.dok_typ",
            "dokument.dok_groesse",
            "dokument.dok_unterschriftsdatum",
          ],
        },
        header_optional: {
          typ: "string",
          wert: "_TXT_SITZUNG_ANTRAG_SONSTIGE_DOKUMENTE_",
        },
        header_required: {
          typ: "string",
          wert: "_TXT_SITZUNG_ANTRAG_PFLICHTDOKUMENTE_",
        },
        mehrfachauswahl: {
          typ: "boolean",
          wert: false,
        },
        allowed_mime_type: {
          typ: "json",
          wert: [],
        },
        header_optional_info: {
          typ: "text",
          wert: "_TXT_SITZUNG_ANTRAG_SONSTIGE_DOKUMENTE_INFO_",
        },
        header_required_info: {
          typ: "text",
          wert: "_TXT_SITZUNG_ANTRAG_PFLICHTDOKUMENTE_INFO_",
        },
        validate_pflichtdoks: {
          typ: "boolean",
          wert: false,
        },
        gueltig_pflicht_felder: {
          typ: "multi_select",
          wert: [
            "von"
          ],
        },
        gueltig_fuer_dokument_hochladen: {
          typ: "boolean",
          wert: false,
        },
      };
    },

    baseUrl() {
      return `sitzungsantraege/${ this.obj.pk }/`;
    },

    extraStatic() {
      return {
        hideHeader: true,
      };
    },

    config() {
      return {
        module: {
          modulname: "anlagen_allgemein",
          regel: {
            regel: this.id,
            regel_nummer: `${ this.id }_1`,
            _argument: this.parameter,
          },
        },
        readonly: false,
      };
    },

    objectLocal() {
      const OBJECT = cloneDeep(this.obj);
      OBJECT.user_permissions = ["dokument.view", "notizen.view"];
      return OBJECT;
    },
  },
};
