"use strict";

import angular from "angular";
import store from "../../../../vue/client/vue/store";
import {
  addStylesInHeader,
} from "../../../../vue/client/vue/global/classes/Styles";

class SyConfigs {
  constructor() {
    this.configs = {};
  }

  initConfigs(cfgData) {
    angular.forEach(cfgData, c => this.configs[c.syc_kennung] = c.syc_wert);
    store.commit("syConfigs/MUT_ADD_SY_CONFIGS_OBJ", this.configs);
    addStylesInHeader({
      config: this.configs.styles,
    });
  }

  /*@ngInject*/
  $get() {
    return {
      wert: syc_kennung => this.configs[syc_kennung],
      all: () => this.configs,
    };
  }
}

export default SyConfigs;
