"use strict";

import "restangular";
import angular from "angular";

import Sammelvertrag from "./sammelvertrag.service";

const modelsModule = angular.module("sammelvertrag.models", [
  "restangular",
])

  .provider("Sammelvertrag", Sammelvertrag)
;

export default modelsModule;
