"use strict";

import BaseController from "../config.base.controller";

class ParametermoduleController extends BaseController {
  /*@ngInject*/
  constructor() {
    super();
    if (this.parameter[this.key].options
        && this.parameter[this.key].options.initial_tagesdatum
        && !this.parameter[this.key].wert) {
      this.parameter[this.key].wert = new Date();
    }
  }
}

export default ParametermoduleController;
