import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "FoerderfinderSymbolDynamicChildDetails",
  components: {
    PuxTranslate,
  },
  props: {
    symbolNameData: {
      type: Object,
      required: true,
    },
    symbolNameOpen: {
      type: Boolean,
      required: true,
    },
    isFoerderangebot: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    getTitle() {
      return count => {
        return count === 1 ? "_TXT_FOERDERFINDER_LIST_FOERDERANGEBOT_{{ count }}_" : "_TXT_FOERDERFINDER_LIST_FOERDERANGEBOTE_{{ count }}_";
      };
    },
  },
};
