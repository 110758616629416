import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import KostenMixin from "../Mixins/KostenMixin";
import {
  forEach,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "KostenSummenzeile",
  components: {
    KFStandard,
  },
  mixins: [
    KostenMixin,
  ],
  computed: {
    sumLocal() {
      return this.filterCurrency(this.summeLocal);
    },

    summeLocal() {
      let summe = 0;
      if (!this.infoprovider || !this.infoprovider.details) {
        return summe;
      }
      forEach(this.modelParameter.kostenarten, art => {
        if (this.infoprovider.details[this.artReMap[art]]) {
          summe += this.infoprovider.details[this.artReMap[art]].betrag || 0;
        }
      });
      return summe;
    },

    artReMap() {
      const ART_RE_MAP = {};
      if (this.infoprovider && this.infoprovider.details) {
        forEach(this.infoprovider.details, (detail, reId) => {
          ART_RE_MAP[detail.art] = reId;
        });
      }
      return ART_RE_MAP;
    },

    snapshotSum() {
      let summe = 0;
      forEach(this.modelParameter.kostenarten || [], art => {
        summe += this.snapshotKosMap[art] || 0;
      });
      return summe;
    },

    snapshotKosMap() {
      const SNAPSHOT_KOS_MAP = {};
      const SNAPSHOT_KOSTENARTEN = get(this.snapshot, "asn_snapshot.kostenarten", []);
      if (SNAPSHOT_KOSTENARTEN.length) {
        forEach(SNAPSHOT_KOSTENARTEN, aka => {
          const KOS_ID = get(aka, "kostenart.kos_id");
          if (KOS_ID) {
            SNAPSHOT_KOS_MAP[KOS_ID] = aka.aka_summe || 0;
          }
        });
      }
      return SNAPSHOT_KOS_MAP;
    },

    snapshotSummeCurrency() {
      return this.filterCurrency(this.snapshotSum);
    },
  },
};
