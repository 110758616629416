import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";

import {
  getTextFromPlaceholder,
} from "../../../../global/functions/utils";
import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "GutachtenErgebnisseBoxItem",
  components: {
    PuxButton,
    PuxIcon,
  },
  props: {
    gutachtenErgebnis: {
      type: Object,
      required: true,
    },
    gutachtenErgebnisIndex: {
      type: Number,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    groupIndex: {
      type: Number,
      required: true,
    },
    idForActiveErgebnis: {
      type: String,
      required: false,
      default: undefined,
    },
    idForDetailsBox: {
      type: String,
      required: true,
    },
  },
  emits: [
    "selectErgebnis",
  ],
  computed: {
    idLocal() {
      return `gutachten_ergebnis_btn_${ this.groupIndex }_${ this.gutachtenErgebnisIndex }`;
    },

    classLocal() {
      return [
        this.gutachtenErgebnis.gae_cssklasse,
        {
          active: this.isDetailsOpen,
        },
      ];
    },

    isDetailsOpen() {
      return this.gutachtenErgebnis.pk === this.idForActiveErgebnis;
    },

    titleLocal() {
      return `${ this.gutachtenErgebnisBezTranslate } x ${ this.groupGutachtenLength } (${ this.groupGutachtenPercentage })`;
    },

    gutachtenErgebnisBezTranslate() {
      return getTextFromPlaceholder(this.gutachtenErgebnis.gae_bez);
    },

    groupGutachtenLength() {
      return get(this.group, `[${ this.gutachtenErgebnis.pk }].gutachten.length`);
    },

    groupGutachtenPercentage() {
      return get(this.group, `[${ this.gutachtenErgebnis.pk }].percentage`);
    },
  },
  methods: {
    selectErgebnis() {
      this.$emit("selectErgebnis", this.gutachtenErgebnis);
    },
  },
};
