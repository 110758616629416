import AuszahlungErrorsBlock from "./AuszahlungErrorsBlock/AuszahlungErrorsBlock.vue";
import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "AuszahlungErrors",
  components: {
    AuszahlungErrorsBlock,
    PuxAlert,
    PuxTranslate,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    validationErrors: {
      type: Array,
      required: true,
      default: () => [],
    },
    rubricsMapping: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  computed: {
    extraNameForTranslate() {
      return {
        name: this.name,
      };
    },
  },
};
