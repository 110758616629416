import Alert from "../../../../global/components/Alert/Alert.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import ModalBelegdokumentDeleteBeleg from "./Beleg/Beleg.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxErrors from "../../../../global/components/PuxErrors/PuxErrors.vue";
import ShowMore from "../../../../global/components/ui/ShowMore/ShowMore.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import translate from "../../../../global/directives/translate";

import {
  sortBy,
  forEach,
  size,
  isString,
} from "lodash-es";

// @vue/component
export default {
  name: "ModalBelegdokumentDelete",
  components: {
    Alert,
    FormElement,
    Modal,
    ModalBelegdokumentDeleteBeleg,
    PuxButton,
    PuxCloak,
    PuxErrors,
    ShowMore,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    vertragId: {
      type: String,
      required: true,
      info: "Vertrag-UUID",
    },
    documentId: {
      type: String,
      required: true,
      info: "Dokument-UUID",
    },
    selectorClose: {
      type: [String, Array],
      required: true,
      info: "CSS-Selector für Fokus, wenn man Modal-Fenster schließt",
    },
    close: {
      type: Function,
      required: true,
      info: "Schließen-Funktion",
    },
  },
  data() {
    return {
      statusLoadingSave: undefined,
      statusLoadingDocument: true,
      statusLoadingBelege: false,
      statusLoadingPermission: false,
      belegDocument: undefined,
      belege: [],
      belegePermissions: {},
      modelConfirmed: false,
      optionsModal: {
        showCloseButton: true,
      },
      errors: undefined,
    };
  },
  computed: {
    documentInBelegHtmlTranslateOptions() {
      if (this.belegeIdsLocal.length === 1) {
        return {
          html: "_HTML_DOKUMENT_IN_BELEG_",
        };
      }
      return {
        html: "_HTML_DOKUMENT_IN_BELEGE_{{belegeLength}}_",
        extra: {
          belegeLength: this.belegeIdsLocal.length,
        },
      };
    },

    belegeIdsLocal() {
      return this.belegDocument.belege || [];
    },

    apiBelegDocument() {
      return `vertraege/${ this.vertragId }/belegdokumente/${ this.documentId }/`;
    },

    belegeSortBy() {
      return sortBy(this.belege, ["nummer"]);
    },

    statusDeleteDisabled() {
      return (
        this.statusLoadingDocument ||
        this.statusLoadingBelege ||
        this.statusLoadingPermission ||
        this.statusLoadingSave ||
        (this.statusNeedsConfirmation && !this.modelConfirmed) ||
        !this.statusHasAllBelegePermissions
      );
    },

    statusHasAllBelegePermissions() {
      if (this.statusLoadingBelege || this.statusLoadingPermission) {
        return false;
      }
      let status = true;
      forEach(this.belegePermissions, item => {
        if (!item) {
          status = false;
          return false;
        }
      });
      return status;
    },

    optionsCheckbox() {
      return {
        id: "beleg_document_checkbox",
        label: "Ja, das Dokument soll von den aufgelisteten Belegen entfernt und endgültig gelöscht werden.",
        view: "v",
        type: "one_checkbox",
        required: false,
        disabled: !this.statusHasAllBelegePermissions,
      };
    },

    statusNeedsConfirmation() {
      return size(this.belegeIdsLocal) > 0;
    }
  },
  created() {
    this.loadBelegDocument();
  },
  methods: {
    loadBelegDocument() {
      this.getHttp({
        url: this.apiBelegDocument,
      }).then(
        response => {
          this.belegDocument = response;
          this.statusLoadingDocument = false;
          if (size(this.belegeIdsLocal) > 0) {
            this.statusLoadingBelege = true;
            this.loadBelege();
          }
        },
        () => {
          this.addNotification({
            text: "_MSG_LOADING_BELEGDOKUMENT_ERROR_",
            type: "error",
          });
        }
      );
    },

    loadBelege() {
      this.getListHttp({
        url: `vertragsbelege/`,
        urlParams: {
          pk: this.belegeIdsLocal,
        },
      }).then(
        response => {
          this.belege = response;
          this.statusLoadingBelege = false;
          this.statusLoadingPermission = true;
          this.checkPermissionVertragskostenpositionsbelegeUpdate();
        },
        () => {
          this.addNotification({
            text: "_MSG_LOADING_BELEGE_ERROR_",
            type: "error",
          });
        }
      );
    },

    checkPermissionVertragskostenpositionsbelegeUpdate() {
      Promise.all(this.belege.map(
        beleg => this.getHttp({
          url: `vertragsbelege/${ beleg.pk }/check_my_permissions/`,
          urlParams: {
            permissions: "vertragskostenpositionsbelege.update",
          },
        }),
      )).then(
        responses => {
          const BELEGE_PERMISSIONS = {};
          forEach(this.belege, (beleg, index) => {
            BELEGE_PERMISSIONS[beleg.pk] = this.checkPermissionsSync({
              perm: "vertragskostenpositionsbelege.update",
              permArray: responses[index] || [],
            }) || false;
          });
          this.belegePermissions = BELEGE_PERMISSIONS;
          this.statusLoadingPermission = false;
        }
      );
    },

    deleteDocument() {
      this.statusLoadingSave = true;
      this.deleteHttp({
        url: this.apiBelegDocument,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_BELEGDOKUMENT_DELETE_SUCCESS_",
          });
          this.close({ statusReload: true });
        },
        errors => {
          this.errors = errors.data;
          if (isString(this.errors)) {
            this.errors = { non_field_erorrs: [this.errors] };
          }
        }
      ).then(
        () => this.statusLoadingSave = false
      );
    },
  },
};
