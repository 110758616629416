import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import ZielgruppenAuswahlCheckboxOrRadio from "./ZielgruppenAuswahlCheckboxOrRadio/ZielgruppenAuswahlCheckboxOrRadio.vue";
import {
  cloneDeep,
  forEach,
  isEmpty,
} from "lodash-es";

// @vue/component
export default {
  name: "ZielgruppenAuswahl",
  components: {
    SnapshotModule,
    SnapshotIcon,
    ZielgruppenAuswahlCheckboxOrRadio,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      loading: true,
      zielgruppen: [],
      zielgruppenByPk: {},
      statusCheckbox: false,
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.open;
      return OPTIONS;
    },

    labelHeader() {
      return "_TXT_GR_ZIELGRUPPEN_HEADER_";
    },

    wahl() {
      const WAHL = [];
      forEach(this.zielgruppen, item => {
        if (this.model.wahl.indexOf(item.pk) !== -1) {
          const CURRENT_WAHL = cloneDeep(item);
          CURRENT_WAHL.untergruppen = [];
          if (item.untergruppen) {
            forEach(item.untergruppen, ug => {
              if (this.model.wahl.indexOf(ug.pk) !== -1) {
                CURRENT_WAHL.untergruppen.push(cloneDeep(ug));
              }
            });
          }
          WAHL.push(CURRENT_WAHL);
        }
      });
      return WAHL;
    },

    wahlSnapshot() {
      if (!this.snapshotModule || !this.snapshotModule.auswahl) {
        return [];
      }
      const WAHL = [];
      forEach(this.zielgruppen, item => {
        if (this.snapshotModule.wahl.indexOf(item.pk) !== -1) {
          const CURRENT_WAHL = cloneDeep(item);
          CURRENT_WAHL.untergruppen = [];
          if (item.untergruppen) {
            forEach(item.untergruppen, ug => {
              if (this.snapshotModule.wahl.indexOf(ug.pk) !== -1) {
                CURRENT_WAHL.untergruppen.push(cloneDeep(ug));
              }
            });
          }
          WAHL.push(CURRENT_WAHL);
        }
      });
      return WAHL;
    },

    modelObj() {
      if (!this.modelEdit.wahl) {
        return {};
      }
      const MODEL_OBJ = {};
      forEach(this.modelEdit.wahl, item => {
        MODEL_OBJ[item] = true;
      });
      return MODEL_OBJ;
    },

    snapshotModuleWahl() {
      if (this.snapshotModule && this.snapshotModule.wahl) {
        return this.snapshotModule.wahl;
      }
      return [];
    },

    modelWahl() {
      return this.model.wahl || [];
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(this.modelWahl, pk => {
          if (this.snapshotModuleWahl.indexOf(pk) === -1) {
            DIFF[pk] = true;
          }
        });
        forEach(this.snapshotModuleWahl, pk => {
          if (this.modelWahl.indexOf(pk) === -1) {
            DIFF[pk] = true;
          }
        });
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },
  },
  created() {
    this.loadZielgruppen();
  },
  methods: {
    prepareModel() {
      const MODEL = cloneDeep(this.module.data) || {};
      MODEL.wahl = MODEL.wahl || [];
      this.model = MODEL;
    },

    loadZielgruppen() {
      this.getListHttp({ url: "zielgruppen/" }).then(
        response => {
          this.setZielgruppen({ response });
          this.loading = false;
        }
      );
    },

    setZielgruppen({ response }) {
      if (!this.modelParameter.auswahl) {
        return;
      }
      const ZIELGRUPPEN = [];
      const ZIELGRUPPEN_BY_PK = {};
      forEach(response, hg => {
        if (this.modelParameter.auswahl.indexOf(hg.pk) >= 0) {
          ZIELGRUPPEN_BY_PK[hg.pk] = cloneDeep(hg);
          const UNTERGRUPPEN = [];
          forEach(hg.untergruppen, ug => {
            if (this.modelParameter.auswahl.indexOf(ug.pk) >= 0) {
              ZIELGRUPPEN_BY_PK[ug.pk] = cloneDeep(ug);
              UNTERGRUPPEN.push(ug);
            }
          });
          if (UNTERGRUPPEN.length) {
            this.statusCheckbox = true;
          }

          hg.untergruppen = UNTERGRUPPEN;
          ZIELGRUPPEN.push(hg);
        }
      });
      this.zielgruppen = ZIELGRUPPEN;
      this.zielgruppenByPk = ZIELGRUPPEN_BY_PK;
    },

    open() {
      this.modelEdit = cloneDeep(this.model);
    },

    updateModel({ model } = {}) {
      this.modelEdit = cloneDeep(model);
    },
  },
};
