import FoerdermittelModalAnpassen from "../FoerdermittelModalAnpassen/FoerdermittelModalAnpassen.vue";
import FoerdermittelModalCreate from "../FoerdermittelModalCreate/FoerdermittelModalCreate.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import PermissionMixin from "../../../global/mixins/PermissionMixin";

import getTableApi from "../../../const/TableApis";
import { EventBus } from "../../../global/functions/event-bus";

const TABLE_ID = "foerdermittel";

// @vue/component
export default {
  name: "FoerdermittelList",
  components: {
    FoerdermittelModalAnpassen,
    FoerdermittelModalCreate,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    PermissionMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_TXT_FOERDERMITTEL_TABLE_ROW_ACTIONS_ANPASSEN_",
            callback: ({ item }) => this.anpassen({ item }),
            isHidden: () => !this.checkPermissionsSync({ perm: "mittel.update" }),
            className: "btn btn-default",
          },
        ],
        actions: [
          {
            permission: "mittel.create",
            label: "_TXT_FOERDERMITTEL_TABLE_ACTIONS_CREATE_",
            callback: () => this.modal.create = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "foerdermittel_create",
          },
        ],
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      modal: {
        create: undefined,
        anpassen: undefined,
      },
      currentRow: undefined,
    };
  },
  methods: {
    anpassen({ item }) {
      this.currentRow = item;
      this.modal.anpassen = true;
    },

    closeModalCreate({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.modal.create = false;
    },

    closeModalAnpassen({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.modal.anpassen = false;
    },
  },
};
