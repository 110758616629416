"use strict";

import template from "./registration.jade";

class RegistrationController {
  /*@ngInject*/
  constructor($location, Registrieren, DiasNotification, SyConfigs, NewsExtern, Katalog) {
    this.loading = true;
    this.$location = $location;
    this.Registrieren = Registrieren;
    this.DiasNotification = DiasNotification;
    this.NewsExtern = NewsExtern;
    this.Katalog = Katalog;
    this.SyConfigs = SyConfigs;
    this.model = { n_loginname: this.getCookie("diasTempEmail") || "" };
    this.conf = this.SyConfigs.wert("registration_default") || {};
    this.titelAuswahl = this.SyConfigs.wert("nutzer_titel");
    this.code_conf = this.SyConfigs.wert("codes_einloesen") || {};

    if (this.titelAuswahl) {
      this.Katalog.getKatalog("titel").then(
        response => {
          this.titel = response;
        }
      );
    }
    this.errorsLabel = {
      n_loginname: `${ this.benutzername ? "Benutzername" : "E-Mail-Adresse" }: `,
      n_mail: "E-Mail-Adresse: ",
      anrede: "Anrede: ",
      n_vorname: "Vorname: ",
      n_nachname: "Nachname: ",
      // "kommdaten_255ee9e6-e26a-7544-e053-0fd5a8c09460": "Telefonnr. 1: ",
      datenschutzeinwilligung: "Datenschutzerklärung: ",
      code: "Zuordnungscode: ",
    };

    if (this.conf.kot_ids && this.conf.kot_ids.length > 0) {
      this.Katalog.getKatalog("kkommtyp", { pk: this.conf.kot_ids }).then(
        response => {
          response.forEach(
            kot => {
              this.errorsLabel[`kommdaten_${ kot.pk }`] = `${ kot.kot_kbez }: `;
              this.model[`kommdaten_${ kot.pk }`] = null;
            }
          );
          this.kots = response.plain();
        }
      );
    }
    if (this.code_conf) {
      this.model.code = this.$location.search().code;
      this.showCode = this.model.code && this.code_conf.on_register;
    }

    this.status = {
      loadingNews: true,
      submitting: false,
    };
    this.newsExtern = [];

    this.loadDatenschutzfragen().finally(
      () => this.initDefaultValues()
    );
    this.loadNewsExtern();
  }

  removeCode() {
    this.model.code = undefined;
    this.showCode = false;
  }

  loadDatenschutzfragen() {
    return this.Katalog.getKatalog("kdatenschutzfrage").then(
      response => this.datenschutzfragen = response
    );
  }

  initDefaultValues() {
    this.regBody = this.regBody || {};
    this.regBody.class = this.regBody.class || "col-12";
    this.regBody.success = this.regBody.success || `<p>
                                                      <strong>Vielen Dank, dass Sie sich registriert haben.</strong>
                                                      <br>
                                                      Sie werden in Kürze eine E-Mail erhalten, mit der Sie Ihre Registrierung abschließen können.
                                                    </p>`;

    if (!this.regHeader) {
      this.regHeader = {
        class: "card-header",
        label: `<h4 class="card-title">Registrieren</h4>`,
        successLabel: `<h4 class="card-title">Registrierung gespeichert</h4>`
      };
    }
    if (!this.regClass) {
      this.regClass = "col-12 col-sm-10 col-lg-7 no-padding-right";
    }


    angular.forEach(this.datenschutzfragen, frage => {
      this.model["datenschutzfrage_" + frage.pk] = {
        einwilligung: frage.def_default,
        frage: frage.pk
      };
    });

    this.loading = false;
  }

  submitOK() {
    // Alle Pflicht-Datenschutzfragen müssen angehakt sein
    let countPflicht = 0;
    let countChecked = 0;
    angular.forEach(this.datenschutzfragen, frage => {
      if (frage.def_pflicht === true) {
        countPflicht += 1;
        if (this.model["datenschutzfrage_" + frage.pk].einwilligung) {
          countChecked += 1;
        }
      }
    });
    return !this.status.submitting && countPflicht === countChecked;
  }

  registrieren() {
    this.deleteCookie("diasTempEmail");
    this.status.submitting = true;
    // Flatten data:
    const data = angular.copy(this.model);
    angular.forEach(this.datenschutzfragen, frage => {
      data["datenschutzfrage_" + frage.pk] = data["datenschutzfrage_" + frage.pk].einwilligung;
    });

    this.Registrieren.post(data).then(
      response => {
        if (response.error) {
          this.onError(response.error);
        } else {
          this.success = true;
        }
      },
      errors => {
        this.onError(errors);
      }
    ).finally(() => this.status.submitting = false);
  }

  onError(errors) {
    this.DiasNotification.form.clear();
    this.DiasNotification.form.error(
      errors,
      "Bitte überprüfen Sie Ihre Eingaben",
      {},
      this.errorsLabel
    );
    this.errors = errors;
  }

  deleteErrors() {
    this.DiasNotification.form.clear();
    this.errors = [];
  }

  getCookie(name) {
    const r = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    if (r) {
      return r[2].replace("%40", "@");
    }
    return "";
  }

  deleteCookie(name) {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  loadNewsExtern() {
    this.NewsExtern.getList().then(
      response => {
        this.newsExtern = response;
        this.status.loadingNews = false;
      }
    );
  }
}

export default {
  template: template(),
  controller: RegistrationController,
  controllerAs: "vm",
  bindings: {
    benutzername: "<?",
    regBody: "<?",
    regClass: "<?",
    regHeader: "<?",
    regSuccessBody: "<?",
    extra: "<?",
    templateRightClass: "<?",
  }
};
