import NotificationMixin from "./NotificationMixin";
import {
  isFunction,
  isObject,
  isString,
  startsWith,
} from "lodash-es";

export default {
  mixins: [
    NotificationMixin,
  ],
  methods: {
    checkErrorStatus({ error, showError, client, resolve, reject }) {
      if (!error) {
        return true;
      }
      const ERROR_HANDLER = () => {
        const HANDLER = this["on" + error.status];
        // if Handler is defined for this Status call it
        if (isFunction(HANDLER)) {
          return HANDLER({ error, showError });
        }
      };
      if (error.status > 500 && error.status != 503) { // Retry and after 3 Fails send Server Error to Sentry
        const do_retry = retry => {
          if (retry > 3) {
            try {
              if (this.$Raven) {
                this.$Raven.captureException(new Error(`Server Response ${ error.status } on Axios-Request`), error);
              }
            } catch (err) {
              console.error(err);
            }
            ERROR_HANDLER();
            reject();
            return;
          }
          const RANDOM_DELAY = Math.round(Math.random() * 600) + 150;
          console.warn(`Error ${ error.status }. Retry ${ retry } in ${ RANDOM_DELAY }ms...`);
          setTimeout(() => {
            client(error.config).then(resolve, () => do_retry(retry + 1));
          }, RANDOM_DELAY);
        };
        do_retry(1);
        return false;
      }

      ERROR_HANDLER();
      // Let the promise handle the error
      return true;
    },

    on401({ showError }) {
      window.location.assign("/login/?m=session_expired&next=" + window.location.pathname + window.location.search);
      return showError;
    },

    on403({ error, showError }) {
      if (error && isObject(error.data) &&
        isString(error.data.detail) &&
        startsWith(error.data.detail, "CSRF Failed")) {
        // entferne gespeicherte Tokens um clientseitig auszuloggen
        this.postHttp({ url: "auth/logout/" }).then(
          () => {
            window.location.assign("/login/?m=session_expired&next=" + window.location.pathname + window.location.search);
          },
          () => {
            this.addNotification({ text: "_MSG_HTTPERRORS_403_ERROR_LOGOUT_", type: "error" });
          }
        );
        // Fehler muss nicht mehr von Aufrufer verarbeitet werden
        return showError;
      }
      this.addNotification({ text: "_MSG_HTTPERRORS_403_ERROR_", type: "error" });
      return true;
    },

    on404({ showError }) {
      return showError;
    },

    on429({ error, showError }) {
      if (error.data.detail) {
        this.addNotification({ text: error.data.detail, type: "error" });
      } else {
        this.addNotification({ text: "_MSG_HTTPERRORS_429_ERROR_MAX_TRIES_", type: "error" });
      }
      return showError;
    },

    on500({ error, showError }) {
      if (this.$goTo) {
        const PARAMS = {};
        PARAMS.sentryId = error.headers["X-Sentry-ID"];
        this.$goTo("root.errors.500", PARAMS, { location: false });
      } else {
        this.showErrorInNotification({
          errorTextHeader: "Der Server hat einen unerwarteten Fehler gemeldet",
          sentryId: error.headers["X-Sentry-ID"],
          errorNumber: 500,
        });
      }
      return showError;
    },

    on502({ error, showError }) {
      if (this.$goTo) {
        const PARAMS = {};
        PARAMS.sentryId = error.headers["X-Sentry-ID"];
        this.$goTo("root.errors.504", PARAMS, { location: false });
      } else {
        this.showErrorInNotification({
          errorTextHeader: "Der Server hat einen unerwarteten Fehler gemeldet",
          sentryId: error.headers["X-Sentry-ID"],
          errorNumber: 502,
        });
      }
      return showError;
    },

    on503({ error, showError }) {
      if (this.$goTo) {
        const PARAMS = {};
        PARAMS.sentryId = error.headers["X-Sentry-ID"];
        this.$goTo("root.errors.503", PARAMS, { location: false });
      } else {
        this.showErrorInNotification({
          errorTextHeader: "Anwendung ist vorübergehend nicht erreichbar",
          sentryId: error.headers["X-Sentry-ID"],
          errorNumber: 503,
        });
      }
      return showError;
    },

    on504({ error, showError }) {
      if (this.$goTo) {
        const PARAMS = {};
        PARAMS.sentryId = error.headers["X-Sentry-ID"];
        this.$goTo("root.errors.504", PARAMS, { location: false });
      } else {
        this.showErrorInNotification({
          errorTextHeader: "Es konnte keine Verbindung zum Server aufgebaut werden",
          sentryId: error.headers["X-Sentry-ID"],
          errorNumber: 504,
        });
      }
      return showError;
    },

    showErrorInNotification({ errorTextHeader, sentryId, errorNumber }) {
      const TEXT_HTML = `
        <div>
          <h2>${ errorTextHeader }</h2>
          ${ sentryId ? `<p>Sollten Sie Unterstützung bei diesem Problem benötigen, geben Sie bitte die folgende Kennung an: <strong>${ sentryId }</strong></p>` : "" }
          <p><strong>Fehlernummer:&nbsp;</strong>${ errorNumber }</p>
          <p>
            <a href="/" class="btn btn-primary">Zur Startseite</a>
          </p>
        </div>`;

      this.addNotification({ text: TEXT_HTML, type: "danger", timeout: 0 });
    },
  },
};
