"use strict";

import template from "./datenschutzabfrage.jade";

class DatenschutzAbfrageController {
  /*@ngInject*/
  constructor(Datenschutzeinwilligung) {
    this.Datenschutzeinwilligung = Datenschutzeinwilligung;
    this.id = this.id || "datenschutz-einwilligung";
  }
}

export default {
  template: template(),
  controller: DatenschutzAbfrageController,
  controllerAs: "vm",
  bindings: {
    model: "=ngModel",
    id: "@inputId",
    frage: "=frage",
  }
};
