import PuxCloak from "../../global/components/PuxCloak/PuxCloak.vue";

// @vue/component
export default {
  components: {
    PuxCloak,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    statusFoerderantragCreate: {
      type: Boolean,
    },
    extern: {
      type: Boolean,
      default: false,
    },
    statusUser: {
      type: Boolean,
    },
    statusAngebotDetails: {
      type: Boolean,
    },
    // Functions
    createFoerderantrag: {
      type: Function,
      required: false,
      default: () => {},
    },
    replace: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  computed: {
    isUserHasPerm() {
      return this.statusUser ? this.statusFoerderantragCreate : true;
    },

    showButtonForAngebot() {
      return angebot => {
        return !(angebot.organisation_obj && angebot.organisation_obj.typ && angebot.organisation_obj.typ.kbez === "Initiative");
      };
    },

    getTitleAntragCreate() {
      const part1 = this.$gettext("Antrag zum Förderangebot");
      return angebot => `${ part1 } ${ angebot.kbez } erstellen`;
    },
  },
  methods: {
    goToNewAntrag(angebotPk) {
      window.top.location.href = `/login/?next=/foerderantrag/neu_mit_foerderangebot/?id=${ angebotPk }`;
    },

    onBeantragen({ angebot, extern }) {
      if (typeof window.utag !== "undefined") {
        try {
          const LINK_OBJ = {
            event_id: "DIAS_foerderung_beantragen",
            etracker_event_action: "click",
          };
          if (angebot) {
            LINK_OBJ.antrag_angebot = this.angebot.kbez;
            LINK_OBJ.antrag_angebot_id = this.angebot.pk;
          }
          window.utag.link(LINK_OBJ);
        } catch (err) {
          console.error(err);
        }
      }
      if (extern) {
        this.goToNewAntrag(angebot.pk);
      }
    },
  },
};
