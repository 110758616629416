"use strict";

import "angular-animate/angular-animate";
import "angular-strap";
import "angular-strap/dist/angular-strap.tpl";
import "angular-motion/dist/angular-motion.css";

import DiasModalDirective from "./modal.directive";
import Services from "./services";

const diasModal = angular.module("dias.modal", [
  "mgcrea.ngStrap",
  Services.name
])
  .directive("diasModal", DiasModalDirective);

export default diasModal;
