"use strict";

import template from "./vertretungsberechtigte.details.jade";


class VertretungsberechtigteController {
  /*@ngInject*/
  constructor(DiasNotification, diasModalDialog, i18n, $timeout, AuthService) {
    this.$timeout = $timeout;
    this.DiasNotification = DiasNotification;
    this.diasModalDialog = diasModalDialog;
    this.AuthService = AuthService;
    this.gettext = i18n.gettext;
    this.statusEditMode = {};
    this.infoStatusShow = {
      0: false,
      1: false,
    };
    this.refreshVertretungsberechtigteAst();
    this.setUrl();
    this.closeModalCreate = this.closeModalCreate.bind(this);
    this.astEditable = this.AuthService.syncHasPerm("antragstellerorg.update", this.ast.user_permissions);
  }


  refreshVertretungsberechtigteAst(vertreterPk, index) {
    this.loadingVertreter = true;
    this.ast.vertretungsberechtigte.getList({
      valid: true,
    }).then(
      response => {
        this.verberechtigteAst = response;
      }
    ).then(() => this.refreshVertretungsberechtigte(vertreterPk, index));
  }

  refreshVertretungsberechtigte(vertreterPk, index) {
    this.loadingVertreter = true;
    this.relevantObj.vertretungsberechtigte.getList().then(
      response => {
        this.vertretungsberechtigte = response;
        if (response.length === 0) {
          this.relevantObj.az_vertreter_geaendert = null;
        } else {
          this.relevantObj.az_vertreter_geaendert = response[0].geaendert;
        }
        this.filtrateVertretungsberechtigteAst(vertreterPk, index);
      }
    ).finally(() => this.loadingVertreter = false);
  }

  filtrateVertretungsberechtigteAst(vertreterPk, index) {
    this.modelVertreter = [];
    let statusOne = 1;
    this.vertretungsberechtigteAst = [this.verberechtigteAst.clone(), this.verberechtigteAst.clone()];
    // this.vertretungsberechtigteAst[1] = this.vertretungsberechtigteAst[0].clone();
    for (let i = 0; i < this.vertretungsberechtigte.length; i++) {
      for (let j = 0; j < this.verberechtigteAst.length; j++) {
        if (this.vertretungsberechtigte[i].astorg_vertreter && this.vertretungsberechtigte[i].astorg_vertreter.includes(this.verberechtigteAst[j].pk)) {
          this.modelVertreter.push(this.verberechtigteAst[j].pk);
          if (statusOne >= 0) {
            this.vertretungsberechtigteAst[statusOne].splice(j, 1);
            statusOne--;
          }
          break;
        }
      }
    }
    if (vertreterPk) {
      this.modelVertreter[index] = vertreterPk;
    }
  }

  saveVertreter(index) {
    this.loadingVertreter = true;
    if (this.vertretungsberechtigte[index]) {
      this.isUpdate = true;
    }
    if (this.modelVertreter[index]) {
      this.updateVertretungsberechtigte(index);
    } else {
      this.refreshVertretungsberechtigte();
    }
    if (this.updated) {
      this.updated();
    }
    this.toggleEditMode(index);
  }

  updateVertretungsberechtigte(index) {
    const data = {
      vertreter: this.modelVertreter[index]
    };
    if (this.isUpdate) {
      if (this.vertretungsberechtigte[index] && this.vertretungsberechtigte[index].pk) {
        data.prev_vertreter = this.vertretungsberechtigte[index].pk;
      }
    }
    this.relevantObj.vertretungsberechtigte.post(data).then(
      () => {
        this.DiasNotification.page.success(this.gettext("_MSG_VERTRETUNGSBERECHTIGTE_UPDATE_SUCCESS_"));
      },
      error => {
        this.DiasNotification.page.error(error);
      }
    ).finally(() => {
      this.refreshVertretungsberechtigte();
      if (this.updated) {
        this.updated();
      }
    });
  }

  deleteVertretungsberechtigte(index) {
    return this.vertretungsberechtigte[index].remove().then(
      () => {
        this.DiasNotification.page.success(this.gettext("_MSG_VERTRETUNGSBERECHTIGTE_DELETE_SUCCESS_"));
      },
      error => {
        this.DiasNotification.page.error(error);
      }
    );
  }

  deleteVertreter(index) {
    this.loadingVertreter = true;
    this.deleteVertretungsberechtigte(index).finally(
      () => {
        this.toggleEditMode(index);
        this.refreshVertretungsberechtigte();
        if (this.updated) {
          this.updated();
        }
      }
    );
  }

  createVertretungsberechtigten() {
    this.modalCreateVertreter = true;
    this.isVertrag = true;
  }

  setUrl() {
    this.urlVertreter = `antragstellerorganisationen/${ this.ast.pk }/vertretungsberechtigte/`;
  }

  closeModalCreate({ response, index } = {}) {
    this.$timeout(() => {
      if (this.updated) {
        this.updated();
      }
      if (response) {
        this.refreshVertretungsberechtigteAst(response.pk, index);
      }
      this.modalCreateVertreter = false;
    });
  }

  toggleEditMode(index) {
    this.statusEditMode[index] = !this.statusEditMode[index];
    if (this.statusEditMode[index]) {
      this.infoStatusShow[index] = true;
    } else {
      this.infoStatusShow[index] = false;
    }
  }

  showEditStatus(index) {
    if (this.editable) {
      if (index === +0) {
        if (this.statusEditMode[1]) {
          return false;
        }
        return true;
      }
      if (this.statusEditMode[0]) {
        return false;
      }
      return true;
    }
    return false;
  }

  getDisplayName(av) {
    if (!av) {
      return "";
    } else if (av.display_name) {
      return av.display_name;
    }
    let name = av.av_name;
    if (av.av_vorname) {
      name = `${ av.av_vorname } ${ name }`;
    }
    if (av.av_titel) {
      name = `${ av.av_titel } ${ name }`;
    }
    return name;
  }

  toggleInfoStatusShow(index) {
    this.infoStatusShow[index] = !this.infoStatusShow[index];
  }
}


export default {
  template: template(),
  controller: VertretungsberechtigteController,
  controllerAs: "vm",
  bindings: {
    relevantObj: "<",
    ast: "<",
    editable: "<",
    updated: "&?",
    info: "<?",
  }
};
