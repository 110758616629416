"use strict";

import BaseModel from "dias/core/models/baseModel";
import Bankverbindung from "./bankverbindung.service";
import Vertreter from "./vertretungsberechtigter.service";
import antragstellerorgPruefstatus from "./antragstellerorgPruefstatus.service";
import Dokumente from "dias/dokumente/models/dokumente.service";
import Preview from "dias/dokumente/models/preview.service";
import Objektaufgabe from "dias/workflow/models/objektaufgabe.service";
import Foerdermodule from "dias/foerderantrag/foerdermodule/models/foerdermodule.service";


class Antragstellerorganisationen extends BaseModel {
  constructor() {
    super("antragstellerorganisationen", {
      subPaths: {
        nutzer: "nutzer",
        dokumente: new Dokumente(),
        durchfuehrendeorgeinheiten: "durchfuehrendeorgeinheiten",
        preview: new Preview(),
        bankverbindungen: new Bankverbindung(),
        vertretungsberechtigte: new Vertreter(),
        pruefungen: new antragstellerorgPruefstatus(),
        aufgaben: new Objektaufgabe(),
        module: new Foerdermodule(),
        snapshots: "snapshots",
        sammelvertraege: "sammelvertraege",
      }
    });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.search = searchInput => collection.one("suche").customGETLIST("", { suche: searchInput }).then(
      response => this.Restangular.restangularizeCollection(undefined, response, this.path, { suche: searchInput })
    );
    return collection;
  }

  extendModel(model) {
    model = super.extendModel(model);
    model.updateAnzeigestatus = newStatusId => {
      return model.customPUT({ anzeigestatus: newStatusId }, "anzeigestatus_aendern").then(
        result => model.merge(model, result)
      );
    };
    model.updateSperrung = (newGesperrt, newSperrbemerkung) => model.customPUT(
      { ast_gesperrt: newGesperrt, ast_sperrbemerkung: newSperrbemerkung },
      "sperrung_aendern"
    );

    model.updateBeschreibung = newValue => model.customPUT({ ast_beschreibung: newValue }, "beschreibung_aendern");
    model.updateAstorgTypen = newValue => model.customPUT({ typen: newValue }, "antragstellerorgtypen_aendern");
    model.updateAdresse = newAddrData => {
      const addrData = angular.extend({
        ast_strasse: model.ast_strasse,
        ast_hsnr: model.ast_hsnr,
        ast_plz: model.ast_plz,
        ast_ort: model.ast_ort,
        bundesland: model.bundesland,
        buchfuehrungsmethode: model.buchfuehrungsmethode
      }, newAddrData);
      return model.customPUT(addrData, "adresse_aendern");
    };
    model.updateVerband = newVerbandData => {
      return model.customPUT({ foerderorganisation: newVerbandData }, "verband_aendern");
    };
    model.setGeprueft = () => {
      return model.customPUT({}, "geprueft");
    };
    model.setUngeprueft = () => {
      return model.customPUT({}, "ungeprueft");
    };
    model.restangularizeAufgabe = aufgabe => this.Restangular.restangularizeElement(model, aufgabe, "aufgaben", {});
    if (model.aktuelle_aufgabe) {
      model.aktuelle_aufgabe = model.restangularizeAufgabe(model.aktuelle_aufgabe);
      model.deaktivieren = () => {
        return model.customPUT({}, "deaktivieren");
      };
    }
    model.delete = () => {
      return model.customDELETE();
    };

    model.sammelvertragVerknuepfbareAntraege = parameter => {
      return model.customGET("sammelvertraege/verknuepfbare_antraege", parameter);
    };

    model.doTraegerwechsel = (zielAstId, antragIds, anuId, fnus) => {
      const data = {
        ziel_antragsteller: zielAstId,
        antraege: antragIds,
        astnutzer: anuId,
        orgzustaendige: fnus
      };
      return model.customPOST(data, "traegerwechsel");
    };

    model.getKonto = () => {
      const deferred = this.$q.defer();
      model.customGET("check_fibu_permission").then(
        response => {
          if (response.status) {
            model.customGET("konto").then(
              response => deferred.resolve(response),
              err => deferred.reject(err)
            );
          } else {
            deferred.reject();
          }
        },
        err => deferred.reject(err)
      );
      return deferred.promise;
    };

    return model;
  }
  //
  // updateAnzeigestatus(newStatusId) {
  //   return this.customPUT({anzeigestatus: newStatusId}, "anzeigestatus_aendern").then(
  //     (result) => super.merge(this, result)
  //   );
  // }
}

export default Antragstellerorganisationen;
