"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import "restangular";

import errors from "dias/core/errors";
import notification from "dias/core/notification";

import Anonymisierungsauftrag from "./anonymisierungsauftrag.service";

const modelsModule = angular.module("core.anonymisierung.models", [
  "restangular",
  uiRouter,
  errors.name,
  notification.name
])

  .provider("Anonymisierungsauftrag", Anonymisierungsauftrag)
;

export default modelsModule;
