var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["dropdownAll"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div class=\"dropdown-all\"><div data-toggle=\"inactive-all\" title=\"alle schließen\" tabindex=\"-1\"><span class=\"glyphicon-double-up\"></span></div><div data-toggle=\"active-all\" title=\"alle öffnen\" tabindex=\"-1\"><span class=\"glyphicon-double-bottom\"></span></div></div>");
};
jade_mixins["schaltflaechen"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div id=\"always-visible\" class=\"actions right panel_always_visible\"><a ui-sref=\"root.foerderfinderangebote\" class=\"btn btn-icon float-left\"><span class=\"glyphicon glyphicon-chevron-left\"></span><span> Zur Übersicht der {{ vm.gettext(\"Förderangebote\") }}</span></a><button ng-if=\"!(vm.org &amp;&amp; vm.org.typ_name === 'Initiative') &amp;&amp; vm.statusCreateButton\" ng-click=\"vm.checkCreateAntrag()\" class=\"btn btn-primary\"><i class=\"glyphicon glyphicon-plus\">&nbsp;</i><span>Antrag zu diesem {{ vm.gettext(\"Förderangebot\") }} erstellen</span></button></div>");
};
buf.push("<div dias-cloak=\"vm.loading\" cloak-msg=\"'Daten werden geladen'\"><section class=\"main row no-margin\"><section class=\"main-dias application-form\"><header class=\"page-title header-detail\"><div vueinit props=\"{ ars_kbez: vm.regelsatz.ars_kbez }\"><page-tab-title v-bind:placeholder=\"'_PAGE_TITLE_FOERDERFINDERANGEBOTE_{{ars_kbez}}_'\" v-bind:extra=\"propsAngular\"></page-tab-title></div><h1>{{ vm.gettext(\"Förderangebot\") }}</h1><h2>{{ vm.regelsatz.ars_kbez }}</h2>");
jade_mixins["schaltflaechen"]();
buf.push("</header><div class=\"main-details main-details\"><form formsteps><div ng-if=\"vm.uebersichtStatus\" class=\"form-step active\"><header formstep><h3><span class=\"glyphicon glyphicon-uebersicht\"></span><span> Übersicht</span></h3>");
jade_mixins["dropdownAll"]();
buf.push("</header><div class=\"form-step-detail\"><fieldset><div class=\"table-details no-border\"><h4 class=\"h3-color\">{{ vm.regelsatz.kbez }}</h4><p ng-bind-html=\"vm.regelsatz.foerderfinder_kurz\"></p><p ng-bind-html=\"vm.regelsatz.foerderfinder_lang\"></p><h4 class=\"margin-top-lg\"><i class=\"glyphicon glyphicon-antrag font-size-lg icon\"></i><span> Förderfähige Kosten</span></h4><article><span ng-repeat=\"kosten in vm.regelsatz.kosten\">{{ kosten.kostenart }}{{ $index !== vm.regelsatz.kosten.length - 1 ? \" | \" : \"\"}}</span></article><div class=\"row margin-top-lg\"><div class=\"col-4\"><h4><i class=\"glyphicon glyphicon-bald-zu-erledigen font-size-lg icon\"></i><span> Förderzeitraum</span></h4><div>{{vm.regelsatz.durchfuehrungszeitraum.max_durchfuehrungsdauer }} Monate</div><div>maximal</div></div><div class=\"col-4\"><h4><i class=\"glyphicon glyphicon-euro font-size-lg icon\"></i><span> Förderhöhe</span></h4><div>{{ vm.regelsatz.zuschuss[0].max_foerderhoehe }}</div></div><div class=\"col-4\"><h4><i class=\"glyphicon glyphicon-auszahlung-anfordern font-size-lg icon\"></i><span> Eigenmittel</span></h4><div>{{ vm.regelsatz.eigenmittelanteil }}</div></div></div></div></fieldset></div></div><div class=\"form-step\"><header formstep><h3><span class=\"glyphicon glyphicon-foerderangebot\"></span><span> Beschreibung</span></h3>");
jade_mixins["dropdownAll"]();
buf.push("</header><div class=\"form-step-detail\"><fieldset><div ng-if=\"!vm.regelsatz.ars_foerderfinder_lang\">Keine Beschreibung hinterlegt.</div><div ng-if=\"vm.regelsatz.ars_foerderfinder_lang\" ng-bind-html=\"vm.regelsatz.ars_foerderfinder_lang\"></div></fieldset></div></div><div ng-repeat=\"aspekt in vm.regelsatz.aspekte\" class=\"form-step\"><header formstep><h3><span class=\"glyphicon glyphicon-details\"></span><span>{{ vm.gettext(\"Aspekt\") }}: {{ aspekt.ask_titel }}</span></h3>");
jade_mixins["dropdownAll"]();
buf.push("</header><div class=\"form-step-detail\"><fieldset><p ng-bind-html=\"aspekt.ask_kurzbeschreibung\"></p><a ng-click=\"vm.showMore(aspekt)\" ng-if=\"!vm.statusShowMore[aspekt.pk]\" class=\"btn btn-link no-padding-left\">Ausführliche Beschreibung einblenden</a><p ng-bind-html=\"aspekt.ask_beschreibung\" ng-if=\"vm.statusShowMore[aspekt.pk]\"></p></fieldset></div></div><div class=\"form-step\"><header formstep><h3><span class=\"glyphicon glyphicon-dokumente\"></span><span>{{ vm.gettext(\"Dokumente zur Antragstellung\") }}</span></h3>");
jade_mixins["dropdownAll"]();
buf.push("</header><div class=\"form-step-detail\"><fieldset><div><div ng-show=\"vm.regelsatz.foerderdokumente.length &lt; 1\"><span>{{ vm.gettext(\"Es sind keine Dokumente zur Antragstellung hinterlegt.\") }}</span></div><div ng-show=\"vm.regelsatz.foerderdokumente.length &gt; 0\"><span>Bitte entnehmen Sie weitere Informationen den folgenden Dokumenten:</span><br><br><div ng-repeat=\"dok in vm.regelsatz.foerderdokumente\"><a ng-href=\"{{:: dok.url}}\" target=\"_self\" ng-attr-title=\"Datei '{{:: dok.datei }}' öffnen\"><span class=\"glyphicon glyphicon-download\"></span><span>&nbsp;{{:: dok.titel }} ({{dok.dok_typ}}, {{dok.groesse / 1024 | number:2}} KB)</span></a><br></div></div></div></fieldset></div></div></form></div></section><aside id=\"aside-focus\" sticky tabindex=\"-1\" class=\"public-pages\"><div class=\"aside-overflow\"><foerderorganisation-kontakt-box ng-if=\"vm.org\" label=\"vm.org.typ_name || vm.gettext('Ansprechpartner')\" org=\"vm.org\" link=\"true\"></foerderorganisation-kontakt-box><div ng-if=\"vm.org.pk\" class=\"box\"><h1>Konzept</h1><h2><a ui-sref=\"root.foerderorganisationen_kennenlernen.details({id: vm.org.pk})\" ng-bind-html=\"vm.org.o_konzept_titel\"></a></h2><div ng-bind-html=\"vm.org.o_konzept_kbez\" class=\"margin-top-sm\"></div></div><div class=\"box\"><h1>Das {{ vm.gettext(\"Förderangebot\") }} fördert...</h1><categories-box relevant-kategoriewerte=\"vm.regelsatz.kategoriewerte\"></categories-box></div></div></aside></section></div>");;return buf.join("");
}