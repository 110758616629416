"use strict";

import angular from "angular";

import RechnerRegistry from "./registry.service";

const betragsrechner = angular.module("dias.betragsrechner.services", [
])

  .provider("BetragsrechnerRegistry", RechnerRegistry);

export default betragsrechner;
