import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import PersonalFunktion from "./PersonalFunktion/PersonalFunktion.vue";
import KostenMixin from "../Mixins/KostenMixin";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import {
  forEach,
  get,
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenLohnjournal",
  components: {
    KFStandard,
    PersonalFunktion,
  },
  mixins: [
    KostenMixin,
    HttpMixin,
  ],
  data() {
    return {
      personalfunktionen: [],
      positionenKeyByPersonalfunktionen: {},
      pkt_id: undefined,
    };
  },
  computed: {
    betragsrechner() {
      return this.modelParameter.betragsrechner || "personalkosten_lohnsteuer";
    },

    sumLocal() {
      return this.filterCurrency(this.antragKoFiArt.summe);
    },

    extraKFLocal() {
      const EXTRA = cloneDeep(this.extraKF);
      EXTRA.pkt_id = this.pkt_id;
      return EXTRA;
    },

    extraForVorschau() {
      return {
        pkt_id: this.pkt_id,
      };
    },

    diffMock() {
      return {
        sum: this.diff.sum,
        delete: [],
        add: {},
        changes: {},
        status: this.diff.status,
      };
    },

    diffLocalDeleteByPersonalfunktionen() {
      const DIFF = cloneDeep(this.diff);
      DIFF.deleteByPersonalfunktionen = {};
      forEach(this.personalfunktionen, funktion => {
        DIFF.deleteByPersonalfunktionen[funktion.pk] = [];
      });
      if (DIFF.delete.length) {
        forEach(DIFF.delete, position => {
          const CURRENT_PERSONALFUNKTION = get(position, `akp_argument.personalfunktion`);
          if (CURRENT_PERSONALFUNKTION && DIFF.deleteByPersonalfunktionen[CURRENT_PERSONALFUNKTION]) {
            DIFF.deleteByPersonalfunktionen[CURRENT_PERSONALFUNKTION].push(position);
          }
        });
      }
      return DIFF;
    },
  },
  created() {
    this.loadPersonalfunktionen();
  },
  methods: {
    loadPersonalfunktionen() {
      this.getListHttp({
        url: "katalog/",
        urlParams: {
          key: "personalfunktionen",
          pk: this.modelParameter.personalfunktionen,
        },
      }).then(
        response => {
          this.personalfunktionen = response;
        }
      );
    },

    setPositionen_KFBaseMixin(response) {
      this.positionen = response;
      const POSITIONEN = {};
      forEach(response, pos => {
        const FUNKTION = get(pos, "akp_argument.personalfunktion");
        if (POSITIONEN[FUNKTION] === undefined) {
          POSITIONEN[FUNKTION] = [];
        }
        POSITIONEN[FUNKTION].push(pos);
      });
      this.positionenKeyByPersonalfunktionen = POSITIONEN;
    },

    updateValueFromBetragsrechner({ pkt_id }) {
      this.pkt_id = pkt_id;
    },

    prepareModel_KFBaseMixin({ model, personalfunktion }) {
      const MODEL = cloneDeep(model);
      MODEL.argument = MODEL.argument || {};
      MODEL.argument.pkt_id = this.pkt_id;
      MODEL.argument.personalfunktion = personalfunktion;
      return MODEL;
    },
  },
};
