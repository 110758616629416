import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import TaskLog from "../../../global/components/TaskLog/TaskLog.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "AntragstellerModalZusammenfuehrenDublette",
  components: {
    Modal,
    PuxButton,
    PuxIcon,
    PuxTranslate,
    TaskLog,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    dublette: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalOptions: {},
      taskId: undefined,
      error: undefined,
      executed: undefined,
      success: undefined,
      loading: undefined,
    };
  },
  methods: {
    onSave() {
      this.loading = true;
      this.putHttp({ url: `antragstellerorganisationen/${ this.dublette.pk }/dublette_zusammenfuehren/` }).then(
        response => this.taskId = response.task_id,
        err => this.error = err.data
      );
    },

    onSuccess() {
      this.taskId = null;
      this.executed = true;
      this.success = "_TXT_ANTRAGSTELLER_TABLE_MODAL_DUBLETTE_ZUSAMMENFUEHREN_SUCCESS_";
      this.loading = false;
    },

    onFailure() {
      this.taskId = null;
      this.executed = true;
      this.error = "_ERR_ANTRAGSTELLER_TABLE_MODAL_DUBLETTE_ZUSAMMENFUEHREN_ERROR_";
      this.loading = false;
    },
  },
};
