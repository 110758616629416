"use strict";

import releaseNotesTemplate from "./releaseNotes.jade";


class ReleaseNotesController {
  /*@ngInject*/
  constructor(ReleaseNotes) {
    this.releaseNotes = [];
    ReleaseNotes.getList().then(result => this.releaseNotes = result);

    this.cancel = function() {};
  }
}


export default /*@ngInject*/ diasModalDialog => {
  return {
    restrict: "A",
    link: function(scope, element) {
      $(element).click(function() {
        diasModalDialog(
	  {
	    title: "Versionsinformationen",
	    controller: ReleaseNotesController,
	    template: releaseNotesTemplate
	  }
        );
      });
    }
  };
};
