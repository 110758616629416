"use strict";

import BaseController from "./standard_sitzungsmodule.base.controller";

class StandardSitzungsmodulPreviewController extends BaseController {
  /*@ngInject*/
  constructor($scope, $element) {
    super($scope);
    this.$element = $element;
    this.setContainerClass();
  }

  getContainerClass() {}

  setContainerClass() {
    // Für allgemeines Styling von Modul-Previews erhalten alle die Klasse modul-preview
    this.$element.addClass("modul-preview");
    this.$element.addClass(this.getContainerClass());
  }

  prepareParameter() {}

  prepareUserInput() {
    angular.extend(this.userInput, this.modul.data);
  }
}

export default StandardSitzungsmodulPreviewController;
