// Modul zur Anzeige der Standard-Pauschale
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";
import VertragModels from "dias/vertrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const standard_pauschale = angular.module("dias.kosten_finanzierung.modul.standard_pauschale", [
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
  VertragModels.name,
]);

standard_pauschale.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("standard_pauschale", "modul-standard-pauschale");
})

  .directive("modulStandardPauschale", builder.directive(template, controller));

export default standard_pauschale;
