import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import AuszahlungGoToBoxAuszahlungLink from "../../../Auszahlung/AuszahlungDetails/AuszahlungGoToBox/AuszahlungGoToBoxAuszahlungLink/AuszahlungGoToBoxAuszahlungLink.vue";
import FormularListeBox from "../../../Formulare/FormularListeBox/FormularListeBox.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import VerbundenerAntragLink from "./VerbundenerAntragLink/VerbundenerAntragLink.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";
import {
  FilterSpacedMixin,
} from "../../../../global/mixins/FiltersMixin";

import {
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragGoToBox",
  components: {
    AngularLink,
    AsideRightBox,
    AuszahlungGoToBoxAuszahlungLink,
    FormularListeBox,
    PuxTranslate,
    VerbundenerAntragLink,
  },
  mixins: [
    FilterSpacedMixin,
    HttpMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  props: {
    vertrag: {
      type: Object,
      required: true,
    },
    reloadVertrag: {
      type: Boolean,
      required: false,
    },
    auszahlungsplan: {
      type: Array,
      required: false,
      default: () => [],
    },
    auszahlungen: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      optionsAsideRightBox: {
        icon: "verbunden",
        active: true,
      },
      isFormularListeVisible: undefined,
      instanceConfig: {},
      verbundeneAntraege: [],
      verbundarten: []
    };
  },
  computed: {
    extraForAntragstellerTranslate() {
      return {
        ast_name: this.vertrag.antragstellerorg.ast_name,
      };
    },

    canViewAntrag() {
      return this.checkPermissionsSync({
        perm: "foerderantrag.view",
      });
    },

    extraForAntragTranslate() {
      return {
        a_nr: this.filterSpaced(this.vertrag.antrag_nr),
      };
    },

    extraForAntragTitleTranslate() {
      return {
        a_nr: this.filterSpaced(this.vertrag.antrag_nr, true),
      };
    },

    urlVerbundeneAntraege() {
      return `foerderantraege/${ this.vertrag.antrag_id }/verbundene_antraege/`;
    },

    filteredVerbundeneAntraege() {
      return filter(this.verbundeneAntraege, item => item.can_link);
    },

    verbundeneAntraegeExist() {
      return this.filteredVerbundeneAntraege && this.filteredVerbundeneAntraege.length;
    },

    canViewBelege() {
      return !!this.instanceConfig.vertragsbeleg &&
        this.checkPermissionsSync({
          perm: "vertragskostenpositionsbelege.view",
          permArray: this.vertrag.user_permissions });
    },

    extraForVertragTranslate() {
      return {
        v_nr: this.filterSpaced(this.vertrag.v_nr),
      };
    },

    extraForVertragTitleTranslate() {
      return {
        v_nr: this.filterSpaced(this.vertrag.v_nr, true),
      };
    },

    statusShowBox() {
      return this.verbundeneAntraegeExist || this.vertrag.antragstellerorg ||
        this.canViewAntrag || this.isFormularListeVisible ||
        (this.auszahlungsplan.length && this.auszahlungen.length);
    },
  },
  created() {
    this.setConfigs();
    this.loadVerbundeneAntraege();
  },
  methods: {
    setConfigs() {
      this.instanceConfig = this.getSyConfigsValue("instance_pages", {});
      const CONFIG = this.getSyConfigsValue("vertrag", {});
      this.verbundarten = CONFIG.goto_verbundarten || [];
    },

    loadVerbundeneAntraege() {
      if (!this.verbundarten.length) {
        return;
      }
      this.getHttp({
        url: this.urlVerbundeneAntraege,
        urlParams: {
          verbundart: this.verbundarten
        }
      }).then(
        response => {
          this.verbundeneAntraege = response || [];
        }
      );
    },
  },
};
