import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import { cloneDeep } from "lodash";

// @vue/component
export default {
  name: "SachberichtvorlageDetailsBasisdaten",
  components: {
    FormElement,
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    sachberichtvorlage: {
      type: Object,
      required: true,
    },
    sachberichtvorlageUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "updateSachberichtvorlage",
  ],
  setup() {
    const {
      putHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      putHttp,
    };
  },
  data() {
    return {
      loading: false,
      model: {},
      errors: {},
      optionsList: [
        {
          id: "sbv_rubriklabel",
          type: "text",
          label: "_LBL_SACHBERICHTVORLAGE_DETAILS_RUBRIKLABEL_",
          maxlength: 100,
          required: true,
          view: "v-alt",
        },
        {
          id: "sbv_kbez",
          type: "text",
          label: "_LBL_SACHBERICHTVORLAGE_DETAILS_NAME_",
          maxlength: 60,
          required: true,
          view: "v-alt",
        },
        {
          id: "sbv_bez",
          type: "richtext",
          label: "_LBL_SACHBERICHTVORLAGE_DETAILS_DESCRIPTION_",
          required: true,
          view: "v-alt",
        },
      ],
    };
  },
  computed: {
    formstepDetailOptions() {
      return {
        label: "_LBL_SACHBERICHTVORLAGE_DETAILS_BASISDATEN_",
        showCloseButton: true,
        required: true,
        saveCallback: this.save,
        openCallback: this.open,
        closeCallback: this.close,
      };
    },
  },
  methods: {
    open() {
      this.errors = {};
      this.model = cloneDeep(this.sachberichtvorlage);
    },

    close() {
      this.errors = {};
    },

    save() {
      return this.putHttp({
        url: this.sachberichtvorlageUrl,
        data: this.model,
      }).then(
        response => {
          this.addNotification({ text: "_MSG_SACHBERICHTVORLAGE_DETAILS_BASISDATEN_SAVE_SUCCESS_" });
          this.$emit("updateSachberichtvorlage", response);
          return Promise.resolve();
        },
        errors => {
          this.errors = errors.data;
          return Promise.reject(errors);
        },
      );
    },
  },
};
