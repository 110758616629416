import DashboardBalkendiagrammStatisticRowBar from "./DashboardBalkendiagrammStatisticRowBar/DashboardBalkendiagrammStatisticRowBar.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  size
} from "lodash-es";


// @vue/component
export default {
  name: "DashboardBalkendiagrammStatisticRow",
  components: {
    DashboardBalkendiagrammStatisticRowBar,
    PuxTranslate,
  },
  props: {
    row: {
      type: Object,
      required: true
    },
  },
  computed: {
    show() {
      return size(this.row.bars) > 0;
    },

    showSeperator() {
      return !this.row.isLast && this.row.seperator;
    },

    spacingStyles() {
      return {
        height: this.row.spacing,
      };
    },
  },
};
