"use strict";


import template from "./listNew.jade";

import editBelegTemplate from "./modal.beleg.edit.jade";
import editBelegController from "./modal.beleg.edit.component";

class VertragsbelegNewController {
  /*@ngInject*/
  constructor(i18n, diasModalDialog) {
    this.gettext = i18n.gettext;
    this.diasModalDialog = diasModalDialog;

    this.createOrEditBeleg = ({ row, vertragId } = {}) => {
      if (row) {
        return this.diasModalDialog({
          title: `${ this.gettext("Beleg") } "${ row.nummer }" bearbeiten`,
          template: editBelegTemplate,
          controller: editBelegController,
          extras: { model: row }
        });
      } 
      return this.diasModalDialog({
        title: this.gettext("Neuen Beleg erstellen"),
        template: editBelegTemplate,
        controller: editBelegController,
        extras: { model: undefined, vertragId },
      });
    };
  }
}

export default {
  template: template(),
  controller: VertragsbelegNewController,
  controllerAs: "vm"
};
