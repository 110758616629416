"use strict";

import angular from "angular";

import katalog from "dias/core/katalog";

import ergebnis from "./gutachten.ergebnis.component";
import ergebnisAuswahl from "./gutachten.ergebnisauswahl.component";

const modelsModule = angular.module("gutachten.ergebnis", [
  katalog.name,
])

  .component("diasGutachtenErgebnis", ergebnis)
  .component("diasGutachtenErgebnisauswahl", ergebnisAuswahl)
;

export default modelsModule;
