"use strict";

import BaseModel from "dias/core/models/baseModel";

class Datenschutzeinwilligung extends BaseModel {
  constructor() {
    super("datenschutzeinwilligungen");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.getDokumentUrl = frage_id => {
      return "/api/datenschutz/dokument/" + frage_id + "/";
    };

    return collection;
  }
}

export default Datenschutzeinwilligung;
