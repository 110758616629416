import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxPopover from "../../../../global/components/PuxPopover/PuxPopover.vue";

import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "ImportauftragListProtokoll",
  components: {
    PuxIcon,
    PuxPopover,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      protokollPopover: undefined,
      popoverElementId: "",
    };
  },
  computed: {
    protokollGroups() {
      const ORDER = [
        "danger",
        "warning",
        "info",
        "success",
      ];
      const ICONS = {
        danger: "glyphicon-warning",
        warning: "glyphicon-warning",
        info: "glyphicon-info-sign",
        success: "glyphicon-ok",
      };
      const GROUPS = [];
      if (!this.row.iea_protokoll || !this.row.iea_protokoll.msgs) {
        return GROUPS;
      }
      const GROUPS_KEY_BY_TYPE = {};
      forEach(this.row.iea_protokoll.msgs, msg => {
        if (msg.type === "error") {
          msg.type = "danger";
        }
        GROUPS_KEY_BY_TYPE[msg.type] = GROUPS_KEY_BY_TYPE[msg.type] || {
          icon: ICONS[msg.type],
          btnCls: `btn-outline-${ msg.type } no-border p-1`,
          alertCls: `alert-${ msg.type }`,
          showPopover: false,
          msgs: [],
          type: msg.type
        };
        GROUPS_KEY_BY_TYPE[msg.type].msgs.push(msg);
      });
      forEach(ORDER, type => {
        if (GROUPS_KEY_BY_TYPE[type]) {
          GROUPS.push(GROUPS_KEY_BY_TYPE[type]);
        }
      });
      return GROUPS;
    },

    idBase() {
      return `protokoll_${ this.rowIndex }_`;
    },

    idForPopover() {
      return `${ this.idBase }popover`;
    },

    isPopoverOpen() {
      return !!this.protokollPopover;
    },
  },
  methods: {
    openProtokollGroupPopover({ group, groupIndex }) {
      if (this.popoverElementId === `${ this.idBase }${ groupIndex }`) {
        this.closeProtokollPopover();
      } else {
        this.closeProtokollPopover();
        setTimeout(() => {
          this.protokollPopover = group;
          this.popoverElementId = `${ this.idBase }${ groupIndex }`;
        });
      }
    },

    closeProtokollPopover() {
      this.protokollPopover = undefined;
      this.popoverElementId = "";
    },
  },
};
