"use strict";

class BenachrichtigungenService {
  /*@ngInject*/
  constructor($rootScope, $timeout, $interval, BackgroundBenachrichtigungen) {
    this.name = "BenachrichtigungenService";
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this._restService = BackgroundBenachrichtigungen;

    this._pullInterval = 10000 * 60; // alle 10 Minuten
    this._numUnread = 0;
    this._benachrichtigungen = [];

    // bekannte events
    this._events = {
      update: "dias.benachrichtigungen.update.event",
      // registerYourEventHere: "dias.benachrichtigungen.foo.bar.event",
    };

    // Initiales Pull
    // Der erste Abruf geschieht erst 5 Sekunden nach dem Laden der Komponente,
    // um anderen UI-Elementen Zeit zu geben, zuerst Requests an das Backend
    // zu schicken.
    this.$timeout(() => this._continuousPulling(), 5000);

    this.timer = $interval(() => {
      this._continuousPulling();
    }, this._pullInterval);

    $rootScope.$on("AuthService.userRefreshed", (event, user) => {
      if (!user || !user.pk) {
        $interval.cancel(this.timer);
      }
    });
  }

  _broadcast(eventName, eventData) {
    this.$rootScope.$broadcast(eventName, eventData);
  }

  _subscribe(scope, eventName, eventListener) {
    scope.$on(eventName, eventListener);
  }

  // Holt die Benachrichtigungen in regelmäßigen Zeitabständen vom Server
  _continuousPulling() {
    this._restService.getList({ limit: 1, bnn_gelesen: "False" }).then(result => {
      this._handleUpdate(result.pagination.count);
    });
  }

  _handleUpdate(newCount) {
    // ergebnisse intern speichern
    this._numUnread = newCount;
    // und broadcasten
    const data = {
      numUnread: this._numUnread,
    };
    this._broadcast(this._events.update, data);
    this.sendEventInVueJS(data);
  }

  sendEventInVueJS(data) {
    const EVENT = document.createEvent("Event");
    EVENT.initEvent("updateNachrichten", true, true);
    EVENT.data = data;
    document.dispatchEvent(EVENT);
  }

  // Markiert eine Nachricht als gelesen
  markRead(benItem) {
    this._markReadState(benItem, true);
  }

  // Markiert eine Nachricht als nicht-gelesen
  markUnread(benItem) {
    this._markReadState(benItem, false);
  }

  _markReadState(benItem, state) {
    benItem.ben_gelesen = state;
    benItem.save();
    this._handleUpdate(this._numUnread + (state ? -1 : 1));
  }

  subscribeOnUpdates(scope, listener) {
    this._subscribe(scope, this._events.update, listener);
  }
}

export default BenachrichtigungenService;
