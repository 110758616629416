"use strict";

import angular from "angular";

import models from "dias/auth/models";
import coreServices from "dias/core/services";

import authService from "./auth.service";
import nutzerkommdatenService from "./nutzerkommdaten.service";
import kontextTypen from "./kontextTypen.service";
import kdatenschutzfragetypen from "./kdatenschutzfragetypen.service";

const serviceModule = angular.module("auth.service", [
  models.name,
  coreServices.name
])

  .service("NutzerkommdatenService", nutzerkommdatenService)
  .constant("KontextTypen", kontextTypen)
  .constant("KDatenschutzfragetypen", kdatenschutzfragetypen)
  .provider("AuthService", authService);

serviceModule.run(/*@ngInject*/($rootScope, AuthService, Restangular, Profil) => {
  Profil.fetch().then(
    result => AuthService.setUser(result)
  );

  $rootScope.$on("$stateChangeStart", function(event, $toState) {
    $rootScope.toState = $toState;
    $rootScope.stateChangeEvent = event;

    // Wenn User aufgelöst ist, prüfe ob der Nutzer für den State Autorisiert
    // ist.
    // Ist der Nutzer nicht aufgelöst wird er im root State resolved und
    // anschließend autorisiert.
    if (AuthService.isAuthenticated()) {
      AuthService.authorizeState();
    }
    $("body").click(); // Dropdown-Fenster schließen
  });
});

export default serviceModule;
