"use strict";

import angular from "angular";

import foerdermodulDirective from "./foerdermodule.directive";
import foerdermodulChildDirective from "./foerdermodule.child.directive";
import models from "./models";
import services from "./services";

const foerdermodule = angular.module("dias.foerdermodule", [
  services.name,
  models.name
])

  .directive("foerdermodule", foerdermodulDirective)
  .directive("foerdermoduleChild", foerdermodulChildDirective);

export default foerdermodule;
