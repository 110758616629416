"use strict";

class ProjektberichteConfigController {
  /*@ngInject*/
  constructor($scope, ParameterUtils, Katalog) {
    this.$scope = $scope;
    this.ParameterUtils = ParameterUtils;
    this.Katalog = Katalog;
    this.init();
  }

  init() {
    if (angular.isUndefined(this.parameter[this.key].wert) || this.parameter[this.key].wert === null) {
      this.parameter[this.key].wert = [];
    }
    this.loading = true;
    this.Katalog.getKatalog("projektberichtvorlagen").then(
      result => {
        this.vorlagen = result;
      }
    ).finally(() => this.loading = false);
  }

  getVorlageLabel(id) {
    const vorlage = this.vorlagen.getById(id);
    if (vorlage) {
      return vorlage.bez;
    }
    return "-";
  }

  preventFurtherAction($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }
}

export default ProjektberichteConfigController;
