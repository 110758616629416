import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../../global/mixins/NotificationMixin";

import {
  initModelFromList
} from "../../../../../global/functions/mappingForParameterToFormElement";

import {
  assign,
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    currentModel: {
      type: Object,
      required: false,
      default: undefined,
    },
    indexBenachrichtigung: {
      type: Number,
      required: false,
      default: undefined,
      info: "Benachrichtigung index",
    },
    benachrichtigungenArt: {
      type: Object,
      required: true,
      info: "Katalogdaten benachrichtigungenArt für Select",
    },
    benachrichtigungenTyp: {
      type: Object,
      required: true,
      info: "Katalogdaten benachrichtigungenTyp für Select",
    },
    close: {
      type: Function,
      required: true,
      info: "Funktion für Schließen",
    },
    regelsatzUrl: {
      type: String,
      required: true,
      info: "Url für Regelsatz",
    },
    regelsatzPk: {
      type: String,
      required: true,
      info: "Regelsatz UUID",
    },
    selectorClose: {
      type: [String, Array],
      required: true,
    },
    saveCallBack: {
      type: Function,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      modelLocal: {},
      list: [
        {
          id: "benachrichtigungsart",
          type: "select",
          label: "_LBL_ANGEBOTDETAILS_BENACHRICHTIGUNGEN_MODAL_CREATE_BENACHRICHTIGUNGSART_",
          required: true,
          keyLabel: "kfb_kbez",
          keyId: "pk",
          view: "v",
          data: this.benachrichtigungenArt,
          searchList: ["bez"],
          menuWidthAll: true,
          deselect: true,
        },
        {
          id: "benachrichtigungstyp",
          type: "select",
          label: "_LBL_ANGEBOTDETAILS_BENACHRICHTIGUNGEN_MODAL_CREATE_BENACHRICHTIGUNSTYP_",
          required: true,
          keyLabel: "bnt_kbez",
          keyId: "pk",
          view: "v",
          data: this.benachrichtigungenTyp,
          searchList: ["bez"],
          menuWidthAll: true,
          deselect: false,
        },
        {
          id: "asb_titel",
          type: "text",
          label: "_LBL_ANGEBOTDETAILS_BENACHRICHTIGUNGEN_MODAL_CREATE_TITEL_",
          required: true,
          maxlength: 200,
          view: "v",
        },
        {
          id: "asb_inhalt",
          type: "richtext",
          label: "_LBL_ANGEBOTDETAILS_BENACHRICHTIGUNGEN_MODAL_CREATE_INHALT_",
          required: true,
          view: "v-alt",
        },
      ],
      errors: undefined,
      errorsClone: undefined,
      status: {
        loading: undefined,
      },
    };
  },
  computed: {
    getHeader() {
      return this.currentModel ? "_LBL_ANGEBOTDETAILS_BENACHRICHTIGUNGEN_MODAL_UPDATE_HEADER_" : "_LBL_ANGEBOTDETAILS_BENACHRICHTIGUNGEN_MODAL_CREATE_HEADER_";
    },

    getOptions() {
      return {
        showCloseButton: true,
        required: true,
        list: this.list,
      };
    },

    saveUrl() {
      if (this.statusUpdate) {
        return `${ this.regelsatzUrl }${ this.currentModel.pk }/`;
      }
      return this.regelsatzUrl;
    },

    nameSaveFunction() {
      return this.statusUpdate ? "putHttp" : "postHttp";
    },

    statusUpdate() {
      return !!this.currentModel;
    },

    isDisabled() {
      const statusDisabled = false;
      return statusDisabled;
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.currentModel) {
        this.initModel();
      }
      this.modelLocal.regelsatz = this.regelsatzPk;
    },

    initModel() {
      const MODEL = initModelFromList({ list: this.list });
      this.modelLocal = assign(MODEL, this.currentModel);
    },

    save() {
      this.status.loading = true;
      if (this.isDisabledSaveButton) {
        return;
      }
      const INDEX = cloneDeep(this.indexBenachrichtigung);
      const DATA = cloneDeep(this.modelLocal);
      this[this.nameSaveFunction]({
        url: this.saveUrl,
        data: DATA }).then(
        response => {
          const NEW_DATA = response;
          this.addNotification({
            text: `_MSG_ANGEBOTDETAILS_BENACHRICHTIGUNGEN_MODAL_CREATE_SUCCESS_{{titel}}_`,
            extra: { titel: response.asb_titel, }
          });
          this.saveCallBack({ item: NEW_DATA, index: INDEX, statusDelete: false });
          this.close();
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
