"use strict";

import BaseModel from "dias/core/models/baseModel";

class KAufgabenstatus extends BaseModel {
  constructor() {
    super("aufgabenstatus");
  }
}

export default KAufgabenstatus;
