import FormstepDetail from "../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";

import HttpMixin from "../../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import PermissionMixin from "../../../../../../../libs/vue/client/vue/global/mixins/PermissionMixin";

import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "HochschuleDetailsAdressInformationen",
  components: {
    FormstepDetail,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    hochschule: {
      type: Object,
      required: true,
    },
    hochschuleModel: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "updateHochschule",
  ],
  data() {
    return {
      errors: {},
      optionsList: [
        {
          id: "hs_strasse",
          type: "text",
          view: "v-alt",
          label: "Straße",
          maxlength: 100,
          required: false,
          editonly: true,
        },
        {
          id: "hs_hsnr",
          type: "text",
          view: "v-alt",
          label: "Hausnummer",
          maxlength: 10,
          required: false,
          editonly: true,
        },
        {
          id: "hs_plz",
          type: "text",
          view: "v-alt",
          label: "Postleitzahl",
          maxlength: 10,
          required: false,
          editonly: true,
        },
        {
          id: "hs_ort",
          type: "text",
          view: "v-alt",
          label: "Ort",
          maxlength: 10,
          required: false,
          editonly: true,
        },
        {
          id: "bundesland",
          type: "select",
          label: "Bundesland",
          view: "v-alt",
          url: "bundeslaender/",
          urlParams: {
            fields: ["bun_id", "bun_bez"],
          },
          apiSaveId: "bundeslaender_id_bez",
          required: true,
          keyLabel: "bun_bez",
          keyId: "bun_id",
          editonly: true,
        },
        {
          id: "hs_website",
          type: "text",
          view: "v-alt",
          label: "Webseite",
          maxlength: 250,
          required: false,
          editonly: true,
        },
      ],
    };
  },
  computed: {
    hochschulenUrl() {
      return `hochschulen/${ this.hochschule.pk }/`;
    },

    hasPermissionHochschuleUpdate() {
      return this.checkPermissionsSync({
        perm: "hochschule.update",
        permArray: this.hochschule.user_permissions,
      });
    },

    formstepDetailOptions() {
      return {
        showCloseButton: true,
        required: true,
        label: "Adressinformationen",
        list: this.optionsList,
        saveCallback: this.save,
        openCallback: this.open,
      };
    },
  },
  methods: {
    open() {
      this.errors = {};
      const HOCHSCHULE = cloneDeep(this.hochschule);
      const MODEL = {};
      forEach(this.hochschuleModel, (item, key) => {
        MODEL[key] = HOCHSCHULE[key];
      });
      return { model: MODEL };
    },

    save({ model }) {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.hochschulenUrl,
          data: model,
        }).then(
          response => {
            this.$emit("updateHochschule", response);
            resolve();
          },
          errors => {
            this.onErrors(errors);
            reject(errors);
          }
        );
      });
    },

    onErrors(errors) {
      this.errors = errors.data;
    },
  },
};
