import DokumentDownloadConfigurationModal from "../DokumentDownloadConfigurationModal/DokumentDownloadConfigurationModal.vue";
import Spinner from "../../../global/components/Spinner/Spinner.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import fileDownload from "js-file-download";
import {
  cloneDeep,
  merge,
} from "lodash-es";

// @vue/component
export default {
  name: "DokumentDownload",
  components: {
    DokumentDownloadConfigurationModal,
    Spinner,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: false,
      default: undefined,
    },
    linkClass: {
      type: String,
      required: false,
      default: undefined,
    },
    containerClass: {
      type: String,
      required: false,
      default: "d-inline-block"
    },
    apiCall: {
      type: String,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: true,
    },
    callParams: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    allowConfig: {
      type: Boolean,
      required: false,
      default: true,
    },
    isAsynchronous: {
      type: Boolean,
      required: false,
      default: false,
    },
    linkAttributes: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    stop: {
      type: Boolean,
      required: false,
      default: true,
      info: "stopPropagation",
    },
  },
  data() {
    return {
      loading: undefined,
      options: undefined,
      filename: undefined,
      infoText: undefined,
      isModalOpen: false,
    };
  },
  computed: {
    linkAttributesLocal() {
      const LINK_ATTRIBUTES = cloneDeep(this.linkAttributes);
      if (this.href) {
        LINK_ATTRIBUTES.href = this.href;
        LINK_ATTRIBUTES.target = "_self";
      } else {
        LINK_ATTRIBUTES.role = "button";
        LINK_ATTRIBUTES.tabindex = 0;
      }
      return LINK_ATTRIBUTES;
    },

    selectorClose() {
      return `#${ this.id }`;
    },

    callParamsForConfiguration() {
      const COLL_PARAMS = cloneDeep(this.callParams);
      COLL_PARAMS.get_config_info = true;
      return COLL_PARAMS;
    },

    idForModal() {
      return `${ this.id }_modal`;
    },
  },
  methods: {
    getDocument($event) {
      if (this.stop) {
        $event.stopPropagation();
      }
      if (this.loading || this.href) {
        return;
      }
      // this.noconfig && this.downloadmode === "synchron"
      if (!this.allowConfig && !this.isAsynchronous) {
        this.saveDocument();
      } else {
        this.loadConfiguration();
      }
    },

    loadConfiguration() {
      this.loading = true;
      this.getHttp({
        url: this.apiCall,
        urlParams: this.callParamsForConfiguration,
      }).then(
        response => {
          this.loading = false;
          this.options = response.config_options;
          this.filename = response.filename;
          this.infoText = response.infotext;

          this.isModalOpen = true;
        }
      );
    },

    saveDocument(configOptions = {}, taskId) {
      const URL_PARAMS = merge(configOptions, this.callParams);
      if (taskId) {
        URL_PARAMS.task_id = taskId;
      }
      this.loading = true;
      this.getHttp({
        url: this.apiCall,
        urlParams: URL_PARAMS,
        responseType: "blob",
        fullResponse: true,
      }).then(
        response => {
           // wenn der Filename nicht via Config bestimmt wird (z.B. wenn keine Config vorgesehen ist), dann wird
           // versucht, den filename aus der Response zu entnehmen:
          if (!this.filename) {
            const disposition = response.headers["content-disposition"];
            if (disposition && disposition.indexOf("attachment") !== -1) {
              const filenameRegex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i;
              const matches = filenameRegex.exec(disposition);
              if (matches !== null && matches[2]) {
                this.filename = matches[2];
              }
              if (!this.filename) {
                this.filename = "unbenanntes_dokument";
              }
            }
          }
          fileDownload(response.data, this.filename);
        },
        () => {
          this.addNotification({
            text: "_MSG_DOKUMENTDOWNLOAD_SAVE_ERROR_",
            type: "error",
          });
        }
      ).finally(() => this.loading = false);
    },

    closeModal({ configOptions, taskId } = {}) {
      if (configOptions) {
        this.saveDocument(configOptions, taskId);
      }
      this.isModalOpen = false;
    },
  },
};
