"use strict";

import StandardFinanzierungController from "../../finanzierung.controller";

class RolliplatzpauschaleController extends StandardFinanzierungController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService) {
    super($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService);
    this.name = "RolliplatzpauschaleController";
    this.allowsInput = true;
  }

  getDefaultBetragsrechner() {
    return "rolliplaetzerechner";
  }

  showErrorLabel(field) {
    if (field === "anzahl_plaetze") {
      return "Anzahl Rollstuhlfahrerplätze: ";
    }
  }

  isRolliplatzEditable() {
    return !this.readonly;
  }
}

export default RolliplatzpauschaleController;
