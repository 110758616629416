import translate from "../../../../../global/directives/translate";
import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Alert from "../../../../../global/components/Alert/Alert.vue";
import {
  find,
  forEach,
  get,
  isNil,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "Ausgabeparameter",
  components: {
    FormstepDetail,
    Alert,
  },
  directives: {
    translate
  },
  props: {
    serverfunktion: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasIn() {
      return size(this.serverfunktion.params_in) > 0;
    },

    hasOut() {
      return size(this.serverfunktion.params_out) > 0;
    },

    options() {
      const OPTIONS = {
        label: "_TXT_SERVERFUNKTION_DETAILS_AUSFUEHRUNG_PANEL_FORMSTEP_NAME_",
        info: "_HTML_SERVERFUNKTION_DETAILS_AUSFUEHRUNG_PANEL_FORMSTEP_INFO_",
      };
      OPTIONS.required = !isNil(find(this.listIn, el => el.required));
      return OPTIONS;
    },

    translationNoIn() {
      return {
        text: "_MSG_SERVERFUNKTION_DETAILS_AUSFUEHRUNG_NO_IN_INFO_"
      };
    },

    translationLabelIn() {
      return {
        text: "_LBL_SERVERFUNKTION_DETAILS_AUSFUEHRUNG_IN_"
      };
    },

    translationNoOut() {
      return {
        text: "_MSG_SERVERFUNKTION_DETAILS_AUSFUEHRUNG_NO_OUT_INFO_"
      };
    },

    translationLabelOut() {
      return {
        text: "_LBL_SERVERFUNKTION_DETAILS_AUSFUEHRUNG_OUT_"
      };
    },

    listOut() {
      const CONF = [];
      if (!this.hasOut) {
        return CONF;
      }
      forEach(this.serverfunktion.params_out, (v, k) => {
        CONF.push({
          id: k,
          help_text: v.help_text,
          translation: {
            title: `_TXT_SERVERFUNKTION_DETAILS_AUSFUEHRUNG_PARAM_TITLE_{{key}}_{{bez}}_`,
            text: `_TXT_SERVERFUNKTION_DETAILS_AUSFUEHRUNG_PARAM_BEZ_{{key}}_{{bez}}_`,
            extra: {
              key: k,
              bez: v.bez
            }
          }
        });
      });
      return CONF;
    },

    listIn() {
      const CONF = [];
      if (!this.hasIn) {
        return CONF;
      }
      forEach(this.serverfunktion.params_in, (v, k) => {
        CONF.push({
          id: k,
          help_text: v.help_text,
          required: get(v, "required") || get(v, "options.required"),
          translation: {
            title: `_TXT_SERVERFUNKTION_DETAILS_AUSFUEHRUNG_PARAM_TITLE_{{key}}_{{bez}}_`,
            text: `_TXT_SERVERFUNKTION_DETAILS_AUSFUEHRUNG_PARAM_BEZ_{{key}}_{{bez}}_`,
            extra: {
              key: k,
              bez: v.bez
            }
          }
        });
      });
      return CONF;
    },

    translationRequired() {
      return { text: "_TXT_REQUIRED_" };
    },
  },
};
