import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../../global/components/ui/ShowMore/ShowMore.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import {
  cloneDeep,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "SitzungAntraegeProtokoll",
  components: {
    FormElement,
    FormstepDetail,
    PuxTranslate,
    ShowMore,
  },
  props: {
    sitzungListeAntrag: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "updateSitzungListeAntrag",
  ],
  setup() {
    const {
      checkPermissionsSync,
    } = PermissionAPI();

    const {
      putHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      checkPermissionsSync,
      putHttp,
    };
  },
  data() {
    return {
      htmlId: "sitzung_protokoll",
      model: {},
      errors: undefined,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        openCallback: this.open,
        saveCallback: this.save,
      };
    },

    hasPermissionSitzungenProtokollView() {
      return this.checkPermissionsSync({
        perm: "sitzungen.protokoll.view",
        permArray: this.sitzungListeAntrag.user_permissions,
      });
    },

    isEditable() {
      return this.checkPermissionsSync({
        perm: "sitzungen.protokoll.update",
        permArray: this.sitzungListeAntrag.user_permissions,
      });
    },

    optionsProtokoll() {
      const ID = "sla_protokoll";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_TXT_PROTOKOLL_",
        type: "richtext",
        required: false,
        view: "v-alt",
      };
    },

    saveUrl() {
      const SITZUNG_PK = get(this.sitzungListeAntrag, "sitzung.pk");
      const TOP_PK = get(this.sitzungListeAntrag, "top.pk");
      return `sitzungen/${ SITZUNG_PK }/tops/${ TOP_PK }/sitzungslisten/${ this.sitzungListeAntrag.sitzungsliste }/festgeschrieben/${ this.sitzungListeAntrag.pk }/protokoll_bearbeiten/`;
    },

    errorsLabelsOptions() {
      return [
        this.optionsProtokoll,
      ];
    },
  },
  methods: {
    open() {
      this.model = {
        sla_protokoll: this.sitzungListeAntrag.sla_protokoll,
      };
    },

    save() {
      this.removeErrors();
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.saveUrl,
          data: this.model,
        }).then(
          response => {
            this.updateSitzungListeAntragLocal(response);
            this.addNotification({ text: "_MSG_UPDATE_SUCCESS_" });
            resolve();
          },
          error => {
            this.errors = error.data;
            reject(error);
          }
        );
      });
    },

    updateSitzungListeAntragLocal(response) {
      const SITZUNG_LISTE_ANTRAG = cloneDeep(this.sitzungListeAntrag);
      SITZUNG_LISTE_ANTRAG.sla_protokoll = response.sla_protokoll;
      this.$emit("updateSitzungListeAntrag", SITZUNG_LISTE_ANTRAG);
    },

    removeErrors() {
      this.errors = undefined;
    },
  },
};
