import {
  computed,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

import fileDownload from "js-file-download";

export default function AntragPdfSaveAPI({ antrag, antragUrl }) {
  const antragPdfUrl = computed(() => {
    return `${ antragUrl.value }pdf/`;
  });
  const {
    addNotification,
    getHttp,
  } = HttpAPI();
  const savePdf = () => {
    getHttp({
      url: antragPdfUrl.value,
      responseType: "blob",
    }).then(
      response => {
        const FILE_NAME = `antrag_${ antrag.value.a_nr }_entwurf.pdf`;
        fileDownload(response, FILE_NAME);
      },
      () => {
        addNotification({
          text: "_MSG_DOCUMENT_DOWNLOAD_ERROR_",
          type: "error",
        });
      }
    );
  };

  return {
    savePdf,
  };
}
