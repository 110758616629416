import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  computed: {
    labelsPosition() {
      return {
        labelMain: this.modelParameter.titel,
        buttonTogglePositionTextHide: "Veranstaltungsdaten zeigen",
        buttonTogglePositionTextShow: "Veranstaltungsdaten verbergen",
        buttonAddText: "Veranstaltungsdaten hinzufügen",
        labelEdit: "Veranstaltungsdaten bearbeiten"
      };
    },

    extraForVorschau() {
      return {
        tage_teilnehmer_pauschale: this.modelParameter.tage_teilnehmer_pauschale,
        teilnehmer_uebernachtung_pauschale: this.modelParameter.teilnehmer_uebernachtung_pauschale,
        honorartage_pauschale: this.modelParameter.honorartage_pauschale,
      };
    },
  },
  methods: {
    prepareModel_KFBaseMixin({ model }) {
      const MODEL = cloneDeep(model);
      MODEL.argument = MODEL.argument || {};
      MODEL.argument.tage_teilnehmer_pauschale = this.modelParameter.tage_teilnehmer_pauschale;
      MODEL.argument.teilnehmer_uebernachtung_pauschale = this.modelParameter.teilnehmer_uebernachtung_pauschale;
      MODEL.argument.honorartage_pauschale = this.modelParameter.honorartage_pauschale;
      return MODEL;
    },
  },
};
