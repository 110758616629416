"use strict";

import BaseModel from "dias/core/models/baseModel";

class ObjektBenachrichtigung extends BaseModel {
  constructor() {
    super("benachrichtigungen");
  }
}

export default ObjektBenachrichtigung;
