"use strict";


const TEN_MINUTES = 1000 * 60 * 10;


class VersionCheckController {
  /*@ngInject*/
  constructor($window, $timeout, SysInfo) {
    this.$window = $window;
    this.$timeout = $timeout;
    this.SysInfo = SysInfo;
    this.updateAvailable = false;
    this.bundleVersion = null;
    this.setBundleVersion();
  }

  // Durchsucht die inkludierten JS-Dateien und extrahiert
  // die Version des geladenen Bundles.
  setBundleVersion() {
    const pattern = new RegExp(/bundle\.([\w\d]+)\.js$/g);
    const scripts = this.$window.document.getElementsByTagName("script");
    angular.forEach(scripts, item => {
      const match = pattern.exec(item.src);
      if (match !== null) {
        this.bundleVersion = match[1];
      }
    });
    this.$timeout(() => this.checkVersion(), 300);
  }

  checkVersion() {
    this.SysInfo.one().get().then(
      response => {
        this.updateAvailable = response.bundle !== this.bundleVersion;
        this.$timeout(() => this.checkVersion(), TEN_MINUTES);
      }
    );
  }
}

export default VersionCheckController;
