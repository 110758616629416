import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxGet from "../../../global/components/PuxGet/PuxGet.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import {
  FilterCurrencyMixin,
  FilterSpacedMixin,
} from "../../../global/mixins/FiltersMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "sitzungsantraege";

// @vue/component
export default {
  name: "SitzungAntraegeList",
  components: {
    AngularLink,
    PageTabTitle,
    PuxGet,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterSpacedMixin,
    HttpMixin,
    SyConfigMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [],
        actions: [

        ],
        label: "_LBL_SITZUNG_ANTRAEGE_LIST_SITZUNGSANTRAEGE_",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      filters: [],
      monetaere_foerderung: undefined,
      antragConfig: {},
      statusLoading: true,
      foerderorgtypen: [],
    };
  },
  computed: {
    antragScoreConfig() {
      return this.getSyConfigsValue("antrag_score") || {};
    }
  },
  created() {
    this.checkMonetaereFoerderung();
    this.loadFoerderorgtypen();
  },
  methods: {
    checkMonetaereFoerderung() {
      this.monetaere_foerderung = this.getSyConfigsValue("monetaere_foerderung");
      this.antragConfig = this.getSyConfigsValue("foerderantrag") || {};
    },

    loadFoerderorgtypen() {
      this.getListHttp({ url: "foerderorgtypen/" }).then(
        response => {
          this.foerderorgtypen = response;
          this.initCols();
          this.initFliters();
          this.statusLoading = false;
        },
      );
    },
    initCols() {},

    initFliters() {},

    renderEntscheidung({ row }) {
      const obj = row.entscheidungsstatus_obj || {};
      return `<span class="${ obj.est_icon } text-${ obj.est_cssklasse }" title="${ obj.est_kbez }"></span>`;
    },

    renderFoerderorg({ row, typ, field }) {
      let result = "";
      if (row.antrag[field] && row.antrag[field].length > 0) {
        result = row.antrag[field].filter(item => item.o_otid === typ).map(item => item.o_kbez).join(", ");
      }
      return result;
    },

    renderAnmerkung({ row }) {
      return row.hat_entscheidung_anmerkung ? this.$gettext("_LBL_YES_") : this.$gettext("_LBL_NO_");
    },
  },
};
