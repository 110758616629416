"use strict";

const syconfigAdmin = angular.module("dias.syconfigadmin", [])

  .config($stateProvider => {
    $stateProvider
      .state("root.syconfigadmin", {
        url: `/syconfigadmin/?
            user_cookie
          `,
        reloadOnSearch: false,
        views: {
          "main@root": {
            template: "<div vueinit><vue-system-konfiguration-list></vue-system-konfiguration-list></div>"
          }
        },
        data: {
          permissions: "syconfigadmin.view"
        }
      })
      .state("root.syconfigadmin.details", {
        url: ":kennung/",
        views: {
          "main@root": {
            template: "<div vueinit><system-konfiguration-details><"
          }
        },
      })
    ;
  });

export default syconfigAdmin;
