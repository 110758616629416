"use strict";

import template from "./detail.jade";
import angular from "angular";
import on429 from "./documentErrors";
import {
  EventBus,
} from "../../../vue/client/vue/global/functions/event-bus";
import {
  findIndex,
  isFunction,
} from "lodash-es";

const mesageDownload = "Dokument wird heruntergeladen...";
const mesageDelete = "Dokument wird gelöscht...";
const dokumentPruefStatusNeu = "30d847af-74b6-1dcc-e050-007f01012f99";
const ERROR_DEFAULT_TEXT = "Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunk erneut!";

class DokumenteController {
  /*@ngInject*/
  constructor(
    ProandiScroll,
    Dokumentarten,
    Dokumentpruefstatus,
    Upload,
    $scope,
    $window,
    diasConfirmDialog,
    AuthService,
    $filter,
    DiasNotification,
    $timeout,
    ListenConstantDokumente,
    $interval,
    SyConfigs,
    $rootScope,
    DokumentEvents,
    i18n,
    moment
  ) {
    this.gettext = i18n.gettext;
    this.allowedMimeType = SyConfigs.wert("allowed_mime_type").join(",");
    // Notizen
    this.notizenStatus = {
      load: false,
    };
    this.notizenWatchStatus = false;
    this.notizenStatus.load = false;
    $scope.$watch("vm.dokumentnotizen", () => {
      if (this.notizenStatus.load) {
        this.filterNotizen();
      }
      this.notizenStatus.load = true;
    }, true);
    this.$rootScope = $rootScope;
    this.DokumentEvents = DokumentEvents;
    this.$timeout = $timeout;
    this.$interval = $interval;
    this.ProandiScroll = ProandiScroll;
    this.DiasNotification = DiasNotification;
    this.ListenConstantDokumente = ListenConstantDokumente;
    this.window = $window;
    this.Dokumentarten = Dokumentarten;
    this.scope = $scope;
    this.diasConfirmDialog = diasConfirmDialog;
    this.upload = Upload;
    this.showUpload = false;
    this.filter = $filter;
    this.AuthService = AuthService;
    this.Dokumentpruefstatus = Dokumentpruefstatus;
    this.loading = [];
    this.loadingSaveChanges = [];
    this.loadingMesage = [];
    this.createPreviewLoading = {};
    this.docAnzeige = $scope.vm.docAnzeige;
    this.moduleObj = $scope.vm.docpk;

    this.moduleNameObj = {};
    this.downloadFiles = {};
    this.anzahlFile = {};
    this.statusButtonFertig = {};
    this.statusEditButton = {};
    this.statusFile = {};
    this.uploadGueltigAb = {};
    this.uploadGueltigBis = {};
    this.condition = {};
    this.showTrDetail = {};
    this.detailLoaded = {};
    this.editStatus = {};
    this.enterTr = {};
    this.enterBut = {};
    this.errFiles = {};
    this.errorServer = {};
    this.errorEdit = {};
    this.urlUpload = {};
    this.model = {};
    this.notizen = {};

    this.AuthService = AuthService;
    this.moment = moment;

    $scope.$on("$destroy", () => {
      this.$interval.cancel(this.timer);
    });

    // Dokumenten-Struktur aus dem Snapshot und Snapshot-Diff für das Template nachbauen:
    this.snapshotDokumente = {};
    $scope.$watch("vm.snapshot", snapshot => {
      this.snapshotDokumente = this._initSnapshotDokumente(snapshot);
    });

    this.snapshotDiffModifiedDokumente = {};
    this.snapshotDiffRemovedDokumenteByDokart = {};
    this.snapshotDiffChangesIcons = {};
    $scope.$watch("vm.snapshotdiff", snapshotdiff => {
      this.snapshotDiffModifiedDokumente = this._getDokumenteFromSnapshotDiff(snapshotdiff, "modified");
      this.snapshotDiffRemovedDokumenteByDokart = this._getGroupedDokumenteFromSnapshotDiff(snapshotdiff, "removed");
      this.snapshotDiffChangesIcons = this._initSnapshotDiffChangesIcons(snapshotdiff);
    });
    $scope.$watchGroup(["vm.docPermissions", "vm.docUpload"], () => this.updatePermissions());

    $scope.$on(this.DokumentEvents.createdDokInDokart, (event, dok, dokartPk) => {
      if (this.anzeigeobj && this.anzeigeobj.find(anzeige => anzeige.dokumentart.pk === dokartPk)) {
        this.updateObject(dokartPk);
      }
    });

    $scope.$on("workflow.aufgabe.changed", () => {
      this.reloadPage();
    });

    $scope.$on(this.DokumentEvents.deletedDokInDokart, (event, dokPk, dokartPk) => {
      if (this.anzeigeobj && this.anzeigeobj.find(anzeige => anzeige.dokumentart.pk === dokartPk)) {
        this.updateObject(dokartPk);
        this._updateSnapshotDiff();
        this.deleteNotizen(dokPk);
      }
    });

    this.changeDocumentFromEventBus = this.changeDocumentFromEventBus.bind(this);
    this.initEventBusses();
    $scope.$on("$destroy", this.destroyEventBusses);
  }

  updatePermissions() {
    if (angular.isUndefined(this.docUpload)) {
      this.showUpload = this.AuthService.syncHasPerm(this.dokumentePermissions.create, this.docPermissions);
    } else {
      this.showUpload = this.docUpload;
    }
    this.dokumentUpdate = this.AuthService.syncHasPerm(this.dokumentePermissions.update, this.docPermissions);
    this.dokumentUpdateUnterschriftsdatum = this.AuthService.syncHasPerm("dokument.update_unterschriftsdatum", this.docPermissions);
    this.dokumentUpdatePosteingang = this.AuthService.syncHasPerm("dokument.update_posteingang", this.docPermissions);
    this.dokumentUpdateGueltigkeit = this.AuthService.syncHasPerm(this.dokumentePermissions.update_gueltigkeit, this.docPermissions);
    this.dokumentUpdateState = this.AuthService.syncHasPerm("dokument.update_state", this.docPermissions);
    this.dokumentViewState = this.AuthService.hasPerm("dokument.view_state", this.docPermissions);
    this.dokumentNotizPerm = this.AuthService.hasPerm("notizen.view", this.docPermissions);
    const viewPerm = this.AuthService.syncHasPerm(this.dokumentePermissions.view);
    if (viewPerm && !this.anzeigeobj) {
      this.initialPage();
      this.Dokumentpruefstatus.getList().then(
        result => {
          this.dokumentPruefStatus = result;
        }
      );
    } else if (!viewPerm && this.anzeigeobj) {
      this.anzeigeobj = undefined;
    }
  }

  reloadPage() {
    if (this.anzeigeobj && this.anzeigeobj.length) {
      this.loadDokuments(this.anzeigeobj);
    }
  }

  initialPage(dokartPk) {
    if (!this.AuthService.syncHasPerm(this.dokumentePermissions.view, this.docPermissions)) {
      return;
    }
    this.Dokumentarten.getList({ anzeige: this.docAnzeige }).then(
      result => {
        if (isFunction(this.onLoadDokarten)) {
          this.onLoadDokarten()(result);
        }
        this.showErrorIfDokartenListEmpty(result);
        this.anzeigeobj = result;
        for (let i = 0; i < result.length; i++) {
          this.statusEditButton[result[i].dokumentart.pk] = {};

          this.loading[result[i].dokumentart.pk] = [];
          this.loadingMesage[result[i].dokumentart.pk] = [];
          this.loadingSaveChanges[result[i].dokumentart.pk] = [];
          this.downloadFiles[result[i].dokumentart.pk] = {};
          this.statusButtonFertig[result[i].dokumentart.pk] = true;
          this.statusFile[result[i].dokumentart.pk] = false;
          this.showTrDetail[result[i].dokumentart.pk] = {};
          this.detailLoaded[result[i].dokumentart.pk] = {};
          this.editStatus[result[i].dokumentart.pk] = [];
          this.model[result[i].dokumentart.pk] = [];
          this.anzahlFile[result[i].dokumentart.pk] = 0;
          this.errFiles[result[i].dokumentart.pk] = [];
          this.errorServer[result[i].dokumentart.pk] = {};
          this.errorEdit[result[i].dokumentart.pk] = {};
          this.urlUpload[result[i].dokumentart.pk] = this.moduleObj.dokumente.getUploadUrl(result[i].dokumentart.pk);
        }
        this.loadDokuments(result, dokartPk);
      }
    );
  }

  showErrorIfDokartenListEmpty(dokarten) {
    if (!dokarten.length) {
      console.warn(`Der Dokumentanzeige (SyAnzeige) (${ this.docAnzeige }) sind keine Dokumentenarten zugewiesen.`);
    }
  }

  loadDokuments(objs, dokartPk) {
    if (!this.AuthService.syncHasPerm(this.dokumentePermissions.view, this.docPermissions)) {
      return;
    }
    const dokartPks = [];
    for (let i = 0; i < objs.length; i++) {
      dokartPks.push(objs[i].dokumentart.pk);
    }
    this.moduleObj.dokumente.getList({ dokart: dokartPks }).then(
      document => {
        for (let i = 0; i < dokartPks.length; i++) {
          this.moduleNameObj[dokartPks[i]] = [];
        }
        for (let i = 0; i < document.length; i++) {
          if (this.moduleNameObj[document[i].dokument.dokart_id]) {
            this.moduleNameObj[document[i].dokument.dokart_id].push(document[i]);
          }
        }
        for (let i = 0; i < dokartPks.length; i++) {
          if (this.moduleNameObj[dokartPks[i]].length === 0) {
            delete this.moduleNameObj[dokartPks[i]];
          }
        }
        if (document.length > 0) {
          this.checkSynchronization();
        } else {
          if (dokartPk && this.moduleNameObj[dokartPk] && this.moduleNameObj[dokartPk].length === 0) {
            delete this.moduleNameObj[dokartPk];
          }
        }
      }
    );
  }

  loadNotizen(documents) {
    // Läd die Notizen der übergebenen Dokumente in den Notiz-Container:
    for (let k = 0; k < documents.length; k++) {
      this.notizen[documents[k].dokument.pk] = [];
      angular.extend(this.notizen[documents[k].dokument.pk], documents[k].dokument.notizen);
    }
  }

  newObjectFiles(filesIn, filesOut) {
    const anzahlFiles = Object.keys(filesOut).length; // Anzahl filesOut
    if (anzahlFiles) {
      for (let i = 0; i < filesIn.length; i++) {
        const anzahlFilesIndex = anzahlFiles + i;
        filesOut[anzahlFilesIndex] = filesIn[i];
      }
    } else {
      for (let j = 0; j < filesIn.length; j++) {
        filesOut[j] = filesIn[j];
      }
    }
    return filesOut;
  }

  uploadPic(files, dokartPk, errFiles) {
    if (this.statusButtonFertig[dokartPk]) {
      this.statusButtonFertig[dokartPk] = false;
    }
    this.newObjectFiles(files, this.downloadFiles[dokartPk]);
    this.newObjectFiles(errFiles, this.errFiles[dokartPk]);
    if (this.errFiles[dokartPk] && this.errFiles[dokartPk].length) {
      this.statusFile[dokartPk] = true;
    }
    if (!Object.keys(this.downloadFiles[dokartPk]).length) {
      this.statusButtonFertig[dokartPk] = true;
    }
    if (this.anzahlFile[dokartPk] === Object.keys(this.downloadFiles[dokartPk]).length) {
      this.statusButtonFertig[dokartPk] = true;
    }
    if (files && files.length) {
      let url = this.urlUpload[dokartPk];
      if (this.uploadGueltigAb[dokartPk] && this.dokumentUpdateGueltigkeit) {
        url = url + "&gueltig_ab=" + this.uploadGueltigAb[dokartPk];
      }
      if (this.uploadGueltigBis[dokartPk] && this.dokumentUpdateGueltigkeit) {
        url = url + "&gueltig_bis=" + this.uploadGueltigBis[dokartPk];
      }
      angular.forEach(files, function(file) {
        file.upload = this.upload.upload({
          url: url,
          data: { file: file },
          this: this
        });
        file.upload.then(
          response => {
            // file is uploaded successfully
            if (response.status === 200) {
              file.progress = 100;
            }
            this.statusFile[dokartPk] = false;
            this.uploadGueltigAb[dokartPk] = null;
            this.uploadGueltigBis[dokartPk] = null;
            // this.updateObject(dokartPk);
            this.addDokument(response.data);
            this.changeStatusButtonFertig(dokartPk);
            this._updateSnapshotDiff();
            this.$rootScope.$broadcast(this.DokumentEvents.createdDokInDokart, response.data, dokartPk);
            EventBus.$emit("changeDocument", {
              dokartPk: response.data.dokument && response.data.dokument.dokart_id,
              componentId: this.htmlId,
            });
          },
          response => {
            // handle error
            if (on429({ response, DiasNotification: this.DiasNotification })) {
              this.workServerErrors(dokartPk, response);
            }
            this.changeStatusButtonFertig(dokartPk);
          },
          evt => {
            // progress notify
            file.progress = Math.min(50, parseInt(100.0 * evt.loaded / evt.total));
          }
        );
      }, this);
    }
  }


  workServerErrors(dokartPk, errorMsg) {
    this.statusFile[dokartPk] = true;
    const ZahlErrServer = Object.keys(this.errorServer[dokartPk]).length;
    if (ZahlErrServer) {
      this.errorServer[dokartPk][ZahlErrServer] = errorMsg;
    } else {
      this.errorServer[dokartPk][0] = errorMsg;
    }
  }

  changeStatusButtonFertig(dokartPk) {
    this.anzahlFile[dokartPk]++;
    if (Object.keys(this.downloadFiles[dokartPk]).length) {
      const anzahlFiles = Object.keys(this.downloadFiles[dokartPk]).length; // Anzahl this.downloadFiles[dokartPk]
      if (this.anzahlFile[dokartPk] === anzahlFiles) {
        this.statusButtonFertig[dokartPk] = true;
      }
    }
  }

  toogleButton(dokartPk, status) {
    if (status) {
      const anzahlFiles = Object.keys(this.downloadFiles[dokartPk]).length;
      if (anzahlFiles) {
        for (let i = 0; i < anzahlFiles; i++) {
          delete this.downloadFiles[dokartPk][i];
        }
      }
      const anzahlError = Object.keys(this.errFiles[dokartPk]).length;
      if (anzahlError) {
        for (let j = 0; j < anzahlError; j++) {
          delete this.errFiles[dokartPk][j];
        }
      }
      const anzahlErrorServer = Object.keys(this.errorServer[dokartPk]).length;
      if (anzahlErrorServer) {
        for (let k = 0; k < anzahlErrorServer; k++) {
          delete this.errorServer[dokartPk][k];
        }
      }
      this.anzahlFile[dokartPk] = 0;
      this.statusFile[dokartPk] = false;
    }
    if (!this.condition[dokartPk]) {
      this.condition[dokartPk] = true;
    } else {
      this.condition[dokartPk] = false;
    }
  }

  toggleTrDetails(dokartPk, index) {
    this.showTrDetail[dokartPk][index] = !this.showTrDetail[dokartPk][index];
    if (!this.detailLoaded[dokartPk][index] && this.showTrDetail[dokartPk][index]) {
      const dok = this.moduleNameObj[dokartPk][index];
      this.moduleObj.dokumente.one(dok.pk).loadDetails().then(
        details => {
          dok.dokument = angular.merge(dok.dokument, details.plain());
          this.loadNotizen([dok]);
        },
        err => this.DiasNotification.page.error("Fehler beim laden der details des Dokuments", err)
      ).finally(() => this.detailLoaded[dokartPk][index] = true);
    }
  }

  enterRow(dokartPk, index) {
    if (!this.enterTr[dokartPk]) {
      this.enterTr[dokartPk] = {};
    }
    this.enterTr[dokartPk][index] = true;
  }
  leaveRow(dokartPk, index) {
    this.enterTr[dokartPk][index] = false;
  }
  enterButton(dokartPk, index) {
    if (!this.enterBut[dokartPk]) {
      this.enterBut[dokartPk] = {};
    }
    this.enterBut[dokartPk][index] = true;
  }
  leaveButton(dokartPk, index) {
    this.enterBut[dokartPk][index] = false;
    this.enterBut[dokartPk][index] = false;
  }

  setEditMode(dokartPk, index, filePk) {
    if (this.editStatus[dokartPk][index]) {
      this.editStatus[dokartPk][index] = false;
      if (this.errorEdit[dokartPk][index]) {
        delete this.errorEdit[dokartPk][index];
      }
    } else {
      if (filePk) {
        for (let i = 0; i < this.moduleNameObj[dokartPk].length; i++) {
          if (this.moduleNameObj[dokartPk][i].dokument.pk === filePk) {
            this.model[dokartPk][filePk] = {};
            this.model[dokartPk][filePk].dok_posteingang = this.moduleNameObj[dokartPk][i].dokument.dok_posteingang ? this.moduleNameObj[dokartPk][i].dokument.dok_posteingang : null;
            if (this.model[dokartPk][filePk].dok_posteingang) {
              const dok_posteingang = this.model[dokartPk][filePk].dok_posteingang;
              this.model[dokartPk][filePk].dok_posteingang = this.moment(dok_posteingang).format("YYYY-MM-DD");
              this.model[dokartPk][filePk].dok_posteingang_time = this.moment(dok_posteingang).format("HH:mm");
            }
            this.model[dokartPk][filePk].dok_unterschriftsdatum = this.moduleNameObj[dokartPk][i].dokument.dok_unterschriftsdatum ? this.moduleNameObj[dokartPk][i].dokument.dok_unterschriftsdatum : null;
            if (this.model[dokartPk][filePk].dok_unterschriftsdatum) {
              const dok_unterschriftsdatum = this.model[dokartPk][filePk].dok_unterschriftsdatum;
              this.model[dokartPk][filePk].dok_unterschriftsdatum = this.moment(dok_unterschriftsdatum).format("YYYY-MM-DD");
              this.model[dokartPk][filePk].dok_unterschriftsdatum_time = this.moment(dok_unterschriftsdatum).format("HH:mm");
            }
            this.model[dokartPk][filePk].dok_titel = this.moduleNameObj[dokartPk][i].dokument.dok_titel ? this.moduleNameObj[dokartPk][i].dokument.dok_titel : null;
            this.model[dokartPk][filePk].dokart = this.moduleNameObj[dokartPk][i].dokument.dokart_id ? this.moduleNameObj[dokartPk][i].dokument.dokart_id : null;
            this.model[dokartPk][filePk].pruefstatus = (this.moduleNameObj[dokartPk][i].pruefstatus ? this.moduleNameObj[dokartPk][i].pruefstatus.pk : null) || dokumentPruefStatusNeu;
            this.model[dokartPk][filePk].gueltig_ab = this.moduleNameObj[dokartPk][i].gueltig_ab ? this.moduleNameObj[dokartPk][i].gueltig_ab : null;
            this.model[dokartPk][filePk].gueltig_bis = this.moduleNameObj[dokartPk][i].gueltig_bis ? this.moduleNameObj[dokartPk][i].gueltig_bis : null;
            break;
          }
        }
      }
      this.editStatus[dokartPk][index] = true;
    }
  }

  saveChange(dokartPk, index, file) {
    if (this.model[dokartPk][file.pk].dok_posteingang_time) {
      this.model[dokartPk][file.pk].dok_posteingang += " " + this.model[dokartPk][file.pk].dok_posteingang_time;
    }
    if (this.model[dokartPk][file.pk].dok_unterschriftsdatum_time) {
      this.model[dokartPk][file.pk].dok_unterschriftsdatum += " " + this.model[dokartPk][file.pk].dok_unterschriftsdatum_time;
    }
    this.loadingSaveChanges[dokartPk][index] = true;
    file.customPUT(this.model[dokartPk][file.pk]).then(
      response => {
        for (let i = 0; i < this.moduleNameObj[dokartPk].length; i++) {
          if (this.moduleNameObj[dokartPk][i].dokument.pk === file.pk) {
            if (this.moduleNameObj[dokartPk][i].dokument.dokart_id === this.model[dokartPk][file.pk].dokart) {
              this.changeStatusAfterDel(dokartPk, index);
              this.updateObject(dokartPk, index);
            } else {
              this.initialPage(dokartPk);
            }
            this.loadingSaveChanges[dokartPk][index] = false;
            break;
          }
        }
        this._updateSnapshotDiff();
        EventBus.$emit("changeDocument", {
          dokartPk: response.dokument && response.dokument.dokart_id,
          dokartPkAlt: dokartPk,
          componentId: this.htmlId,
        });
      },
      error => {
        if (error.status === 400) {
          if (error.data) {
            if (_.isString(error.data)) {
              this.DiasNotification.page.error(error.data);
              if (this.errorEdit[dokartPk][index]) {
                delete this.errorEdit[dokartPk][index];
              }
            } else {
              this.errorEdit[dokartPk][index] = error.data;
            }
          } else {
            this.DiasNotification.page.error(ERROR_DEFAULT_TEXT);
          }
        }
        this.loadingSaveChanges[dokartPk][index] = false;
      }
    ).finally(() => {
      this.ProandiScroll.to(`dok_row_${ file.pk }`);
    });
  }

  confirmDeletePdf(filePk, dokartPk, index) {
    return this.diasConfirmDialog({
      titleTemplate: "Dokument löschen",
      contentTemplate: `<p>Sind Sie sicher, dass Sie dieses Dokument löschen wollen?</p>`,
      okLabel: "Löschen",
      okCallback: () => this.deletePdf(filePk, dokartPk, index)
    });
  }

  deletePdf(filePk, dokartPk, index) {
    this.loadingMesage[dokartPk][index] = mesageDelete;
    this.loading[dokartPk][index] = true;

    this.moduleObj.dokumente.one(filePk).remove().then(() => {
      this.updateObject(dokartPk, index, true);
      this._updateSnapshotDiff();
      this.deleteNotizen(filePk);
      this.$rootScope.$broadcast(this.DokumentEvents.deletedDokInDokart, filePk, dokartPk);
      EventBus.$emit("changeDocument", {
        dokartPk: dokartPk,
        componentId: this.htmlId,
      });
    }, error => {
      if (error.status === 400) {
        if (error.data) {
          this.DiasNotification.page.error(error.data);
        } else {
          this.DiasNotification.page.error(ERROR_DEFAULT_TEXT);
        }
      }
      this.loading[dokartPk][index] = false;
    });
  }

  deleteNotizen(dokumentPk) {
    if (this.notizen[dokumentPk]) {
      delete this.notizen[dokumentPk];
    }
  }

  updateObject(dokartPk, index, loading) {
    this.moduleObj.dokumente.getDokList(dokartPk).then(
      result => {
        if (result.length > 0) {
          this.moduleNameObj[dokartPk] = result;
          for (let k = 0; k < result.length; k++) {
            this.loadNotizen(result);
          }
          this.checkSynchronization();
        } else {
          delete this.moduleNameObj[dokartPk];
        }
        if (loading) {
          this.loading[dokartPk][index] = false;
          this.changeStatusAfterDel(dokartPk, index);
        }
      }
    );
  }

  changeStatusAfterDel(dokartPk, index) {
    if (this.showTrDetail[dokartPk][index]) {
      this.showTrDetail[dokartPk][index] = false;
    }
    if (this.editStatus[dokartPk][index]) {
      this.editStatus[dokartPk][index] = false;
    }
    this.statusEditButton[dokartPk][index] = false;

    if (this.errorEdit[dokartPk][index]) {
      delete this.errorEdit[dokartPk][index];
    }
  }

  savePdf(fileName, filePk, dokartPk, index) {
    this.loadingMesage[dokartPk][index] = mesageDownload;
    this.loading[dokartPk][index] = true;

    // this.moduleObj.dokument.one(filePk).withHttpConfig({responseType: "blob"}).get().then(
    this.moduleObj.dokumente.getSaveBlob(filePk).then(response => {
      if (this.window.navigator.msSaveBlob) {
        this.window.navigator.msSaveBlob(response, fileName);
      } else {
        const fileURL = (this.window.URL || this.window.webkitURL).createObjectURL(response);
        const aLink = this.window.document.createElement("a");
        aLink.download = fileName;
        aLink.href = fileURL;
        aLink.target = "_self";

        const eventClick = this.window.document.createEvent("MouseEvents");
        eventClick.initEvent(
          "click",
          true,
          true,
          this.window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        aLink.dispatchEvent(eventClick);
      }

      this.loading[dokartPk][index] = false;
    }, () => {
      this.loading[dokartPk][index] = false;
    });
  }

  hasSnapshotChanges() {
    return (this.snapshot && this.snapshotdiff &&
            (this.snapshotdiff.diff_result.added.dokumente ||
             this.snapshotdiff.diff_result.removed.dokumente ||
             this.snapshotdiff.diff_result.modified.dokumente));
  }

  _getSnapshotData(snapshot) {
    let snapshotData;
    angular.forEach(["asn_snapshot", "ats_snapshot"], field => {
      if (snapshot[field] !== undefined) {
        snapshotData = snapshot[field];
        return;
      }
    });
    return snapshotData;
  }

  _initSnapshotDokumente(snapshot) {
    if (snapshot) {
      const docsByIds = {};
      const snapshotData = this._getSnapshotData(snapshot);
      if (snapshotData.dokumente) {
        for (let i = 0; i < snapshotData.dokumente.length; i++) {
          const d = snapshotData.dokumente[i];
          if (d.dokument && d.dokument.pk) {
            docsByIds[d.dokument.pk] = d;
          }
        }
      }
      return docsByIds;
    }
  }

  _hasSnapshotDiffDocOperation(snapshotdiff, diffField) {
    return (snapshotdiff && snapshotdiff.diff_result[diffField] &&
            snapshotdiff.diff_result[diffField].dokumente);
  }

  _getDokumenteFromSnapshotDiff(snapshotdiff, diffField) {
    const docsByIds = {};
    if (this._hasSnapshotDiffDocOperation(snapshotdiff, diffField)) {
      const docs = snapshotdiff.diff_result[diffField].dokumente;
      for (let i = 0; i < docs.length; i++) {
        const d = docs[i];
        if (d.dokument && d.dokument.pk) {
          docsByIds[d.dokument.pk] = d;
        }
      }
    }
    return docsByIds;
  }

  _getGroupedDokumenteFromSnapshotDiff(snapshotdiff, diffField) {
    // Gibt die Dokument aus dem gewünschten Snapshot-Diff-Field ("added", "modified" oder "removed")
    // gruppiert nach Dokument-Art zurück:
    const docsByDokart = {};
    if (this._hasSnapshotDiffDocOperation(snapshotdiff, diffField)) {
      const docs = snapshotdiff.diff_result[diffField].dokumente;
      for (let i = 0; i < docs.length; i++) {
        const d = docs[i];
        if (!d.dokument || !d.dokument.dokart_id) {
          continue;
        }
        if (!docsByDokart[d.dokument.dokart_id]) {
          docsByDokart[d.dokument.dokart_id] = [];
        }
        docsByDokart[d.dokument.dokart_id].push(d);
      }
    }
    return docsByDokart;
  }

  _initSnapshotDiffChangesIcons(snapshotdiff) {
    const iconsByDocIds = {};
    // modified icons:
    if (snapshotdiff && snapshotdiff.diff_result.modified.dokumente) {
      const modifiedDoks = snapshotdiff.diff_result.modified.dokumente;
      for (let i = 0; i < modifiedDoks.length; i++) {
        const d = modifiedDoks[i];
        if (d.dokument && d.dokument.pk) {
          iconsByDocIds[d.dokument.pk] = "glyphicon-changes";
        }
      }
    }
    // added icons:
    if (snapshotdiff && snapshotdiff.diff_result.added.dokumente) {
      const addedDoks = snapshotdiff.diff_result.added.dokumente;
      for (let i = 0; i < addedDoks.length; i++) {
        const d = addedDoks[i];
        if (d.dokument && d.dokument.pk) {
          iconsByDocIds[d.dokument.pk] = "glyphicon-changes-add";
        }
      }
    }
    // removed icons:
    if (snapshotdiff && snapshotdiff.diff_result.removed.dokumente) {
      const removedDoks = snapshotdiff.diff_result.removed.dokumente;
      for (let i = 0; i < removedDoks.length; i++) {
        const d = removedDoks[i];
        if (d.dokument && d.dokument.pk) {
          iconsByDocIds[d.dokument.pk] = "glyphicon-changes-delete";
        }
      }
    }
    return iconsByDocIds;
  }

  _updateSnapshotDiff() {
    if (this.snapshot) {
      this.moduleObj.snapshots.one(this.snapshot.pk).one("diff").get().then(response => {
        this.snapshotdiff = {
          diff_result: {
            modified: response.diff_result.modified.anlagen || {},
            added: response.diff_result.added.anlagen || {},
            removed: response.diff_result.removed.anlagen || {}
          }
        };
      }, errors => {
        console.error(errors);
      });
    }
  }

  getColspan(initialCols, dokArtPk) {
    if (this.dokumentViewState) {
      initialCols += 1;
    }
    if (this.moduleNameObj[dokArtPk] &&
        this.moduleNameObj[dokArtPk][0] &&
        this.moduleNameObj[dokArtPk][0].gueltig_bis !== undefined) {
      initialCols += 1;
    }
    if (this.hasSnapshotChanges()) {
      initialCols += 1;
    }
    return initialCols;
  }

  showErrorLabel(field) {
    if (field === "dok_titel") {
      return "Titel: ";
    } else if (field === "dokart") {
      return "Dokumenttyp: ";
    } else if (field === "gueltig_ab") {
      return "Dokument gültig ab: ";
    } else if (field === "gueltig_bis") {
      return "Dokument gültig bis: ";
    } else if (field === "pruefstatus") {
      return "Status: ";
    }
  }

  antragDokInSitzung(doc, dokartPk, index) {
    this.loading[dokartPk][index] = true;
    const data = {
      src_dokid: doc.dokument.pk
    };
    this.moduleObj.customPOST(data, "dokument_in_sitzung_uebernehmen").then(
      () => {
        this.reloadDokument(doc);
        this.DiasNotification.page.success("Dokument " + doc.dokument.dok_titel + " wird für der Sitzung vorgesehen!");
        this.loading[dokartPk][index] = false;
        EventBus.$emit("changeDocument", {
          dokartPk: doc.dokument && doc.dokument.dokart_id,
          componentId: this.htmlId,
        });
      },
      error => {
        if (error.status === 400) {
          if (error.data) {
            this.DiasNotification.page.error(error.data);
          } else {
            this.DiasNotification.page.error(ERROR_DEFAULT_TEXT);
          }
        }
        this.loading[dokartPk][index] = false;
      }
    );
  }

  copyDocument(doc, dokartPk, targetdokartPk, index) {
    this.loading[dokartPk][index] = true;
    const data = {
      target_mod_name: this.moduleObj.route,
      target_akt_mod_id: this.moduleObj.pk,
      target_dokart: targetdokartPk
    };
    this.moduleObj.dokumente.copyDoc(doc.pk, data).then(
      () => {
        this.DiasNotification.page.success("Dokument " + doc.dokument.dokumentart.name + " (" + doc.dokument.dok_titel + ") wird für der Sitzung vorgesehen!");
        this.loading[dokartPk][index] = false;
      },
      error => {
        if (error.status === 400) {
          if (error.data) {
            this.DiasNotification.page.error(error.data);
          } else {
            this.DiasNotification.page.error(ERROR_DEFAULT_TEXT);
          }
        }
        this.loading[dokartPk][index] = false;
      }
    );
  }

  filterNotizen() {
    this.reloadingNotizen = true;
    angular.forEach(this.moduleNameObj, dokumenteArr => {
      angular.forEach(dokumenteArr, dokument => {
        this.notizen[dokument.pk] = [];
        angular.forEach(this.dokumentnotizen, notiz => {
          if (notiz.no_key.indexOf(dokument.pk) !== -1) {
            this.notizen[dokument.pk].push(notiz);
          }
        });
      });
    });
    this.$timeout(() => {
      this.reloadingNotizen = false;
    }, 1);
  }

  filterNotizenBack() {
    this.notizenStatus.load = false;
    let tempArr = [];
    tempArr = angular.copy(this.dokumentnotizen);
    angular.forEach(this.moduleNameObj, dokumenteArr => {
      angular.forEach(dokumenteArr, dokument => {
        for (let i = 0; i < tempArr.length; i++) {
          if (tempArr[i].no_key.indexOf(dokument.pk) !== -1) {
            tempArr.splice(i, 1);
            i--;
          }
        }
      });
    });
    angular.forEach(this.notizen, notizArr => {
      angular.forEach(notizArr, notiz => {
        tempArr.push(notiz);
      });
    });
    this.dokumentnotizen = angular.copy(tempArr);

    this.notizenStatus.load = true;
  }

  checkSynchronization() {
    if (!this.timer) {
      this.timer = this.$interval(() => {
        this.moduleObj.dokumente.one("syncing").get().then(
          response => {
            const syncDoks = response.plain().data || {};
            if (this.moduleNameObj) {
              Object.keys(this.moduleNameObj).forEach(
                artPk => {
                  this.moduleNameObj[artPk].forEach(
                    dok => {
                      const stillSync = syncDoks[dok.pk] && syncDoks[dok.pk].in_sync;
                      if (dok.is_sync && !stillSync) {
                        this.reloadDokument(dok);
                      }
                    }
                  );
                }
              );
            }
            if (!response || !response.data) {
              this.$interval.cancel(this.timer);
              this.timer = null;
            }
          }
        );
      }, 3000);
    }
  }

  reloadDokument(dok) {
    this.moduleObj.dokumente.one(dok.pk).customGET("meta_info").then(
      response => {
        this.replaceDokList(response);
      }
    );
  }

  addDokument(dok) {
    this.moduleNameObj[dok.dokument.dokart_id] = this.moduleNameObj[dok.dokument.dokart_id] || [];
    this.moduleNameObj[dok.dokument.dokart_id].push(dok);
    if (dok.is_sync) {
      this.checkSynchronization();
    }
  }

  validateGueltigkeit(dokartPk) {
    if (!this.dokumentUpdateGueltigkeit) {
      return { valid: true };
    }

    if (!this.uploadGueltigAb[dokartPk]) {
      return { valid: false, msg: "Bitte zuerst den Beginn der Gültigkeit setzen!" };
    }

    if (this.uploadGueltigBis[dokartPk]) {
      const today = this.filter("date")(new Date(), "yyyy-MM-dd");
      if (this.uploadGueltigBis[dokartPk] < today) {
        return { valid: false, msg: "Das Ende der Gültigkeit darf nicht in der Vergangenheit liegen." };
      }
      if (this.uploadGueltigBis[dokartPk] < this.uploadGueltigAb[dokartPk]) {
        return { valid: false, msg: "Das Ende der Gültigkeit muss nach dem Beginn liegen." };
      }
    }

    return { valid: true };
  }

  createPreview(dok) {
    this.createPreviewLoading[dok.pk] = true;
    this.moduleObj.dokumente.one(dok.pk).one("create_preview").post().then(
      response => {
        this.replaceDokList(response);
        this.createPreviewLoading[dok.pk] = false;
      }
    );
  }

  replaceDokList(dok) {
    if (this.moduleNameObj[dok.dokument.dokart_id] && this.moduleNameObj[dok.dokument.dokart_id].length) {
      for (let i = 0; i < this.moduleNameObj[dok.dokument.dokart_id].length; i++) {
        if (this.moduleNameObj[dok.dokument.dokart_id][i].pk === dok.pk) {
          this.moduleNameObj[dok.dokument.dokart_id][i] = angular.extend(this.moduleNameObj[dok.dokument.dokart_id][i], dok);
          this.notizen[dok.pk] = [];
          break;
        }
      }
    }
  }

  getButtonId(name) {
    return `documents_${ _.toString(name).replace(/\s/g, "") }`;
  }

  changeDocumentFromEventBus({ dokartPk, dokartPkAlt, componentId }) {
    if (componentId === this.htmlId) { // Event gehört zu dieser Komponente
      return;
    }
    if (findIndex(this.anzeigeobj, ["dokumentart.pk", dokartPk]) === -1 &&
      findIndex(this.anzeigeobj, ["dokumentart.pk", dokartPkAlt]) === -1) { // Dokart vom Dokument gehört nicht zu dieser Komponente
      return;
    }
    this.loadDokuments(this.anzeigeobj);
  }

  initEventBusses() {
    EventBus.$on("changeDocument", this.changeDocumentFromEventBus);
  }

  destroyEventBusses() {
    if (this && this.changeDocumentFromEventBus) {
      EventBus.$off("changeDocument", this.changeDocumentFromEventBus);
    } else {
      EventBus.$off("changeDocument");
    }
  }
}

export default {
  template: template(),
  controller: DokumenteController,
  controllerAs: "vm",
  bindings: {
    notizkey: "<",
    docpk: "<",
    docAnzeige: "<",
    docUpload: "<",
    docPermissions: "<?",
    dokumentePermissions: "<?",
    snapshot: "=",
    snapshotdiff: "=",
    condition: "=?",
    updateStatus: "=?",
    obj: "=?",
    dokumentnotizen: "=?",
    notizOptions: "<?",
    pflichtdokumenteInfoText: "<?",
    htmlId: "<?",
    onLoadDokarten: "&?",
    textKeineDokumente: "<?"
  }
};
