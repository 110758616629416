import {
  computed,
  inject,
  toRefs,
} from "vue";

import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SitzungDetailsTagesordnungSelectedModul from "../SitzungDetailsTagesordnungSelectedModul/SitzungDetailsTagesordnungSelectedModul.vue";

import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";
import SitzungDetailsTagesordnungItemAPI from "../compositionAPI/SitzungDetailsTagesordnungItemAPI";

// @vue/component
export default {
  name: "SitzungDetailsTagesordnungModalCreate",
  components: {
    FormElement,
    Modal,
    PuxButton,
    PuxTranslate,
    SitzungDetailsTagesordnungSelectedModul,
  },
  props: {
    sitzung: {
      type: Object,
      required: true,
    },
    tagesordnung: {
      type: Array,
      required: true,
    },
    topsUrl: {
      type: String,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      tagesordnung,
      topsUrl,
    } = toRefs(props);

    const punkteChoices = computed(() => {
      const PUNKTE = [];
      for (let key = 0; key <= tagesordnung.value.length; key++) {
        const INDEX = key + 1;
        const PUNKT = {
          label: INDEX + "",
          value: INDEX + "",
        };
        PUNKTE.push(PUNKT);
        if (tagesordnung.value[key]) {
          for (let uKey = 0; uKey <= tagesordnung.value[key].unterpunkte.length; uKey++) {
            const U_INDEX = uKey + 1;
            const U_PUNKT = {
              label: INDEX + "." + U_INDEX,
              value: INDEX + "." + U_INDEX,
            };
            PUNKTE.push(U_PUNKT);
          }
        }
      }
      return PUNKTE;
    });

    const reloadTagesordnung = inject("reloadTagesordnung");

    const {
      addNotification,
      postHttp,
    } = HttpAPI();

    const {
      addModule,
      loadKonfigurierbareModuleKatalog,
      moveModuleDown,
      moveModuleUp,
      removeModule,

      buttonAddModuleDisabled,
      loadingModuleKatalog,
      model,
      modelOptionsModule,
      optionsModule,
      optionsTosBez,
      optionsTosInfo,
      optionsTosPos,
    } = SitzungDetailsTagesordnungItemAPI({
      topsUrl, punkteChoices
    });

    loadKonfigurierbareModuleKatalog();

    return {
      addModule,
      addNotification,
      moveModuleDown,
      moveModuleUp,
      postHttp,
      removeModule,

      buttonAddModuleDisabled,
      loadingModuleKatalog,
      model,
      modelOptionsModule,
      optionsModule,
      optionsTosBez,
      optionsTosInfo,
      optionsTosPos,
      reloadTagesordnung,
    };
  },
  data() {
    return {
      loading: false,
      errors: undefined,
      modalOptions: {
        showCloseButton: true,
        required: true,
      },
    };
  },
  computed: {
    alertLabels() {
      return {
        [this.optionsTosPos.id]: this.optionsTosPos.label,
        [this.optionsTosBez.id]: this.optionsTosBez.label,
        [this.optionsTosInfo.id]: this.optionsTosInfo.label,
      };
    },
  },
  methods: {
    save() {
      this.deleteErrors();
      this.loading = true;
      const DATA = {};
      let position;
      let oberpunkt;
      if (this.model.tos_pos) {
        const POSITION_ARRAY = this.model.tos_pos.split(".");
        if (POSITION_ARRAY.length === 1) {
          position = POSITION_ARRAY[0];
          oberpunkt = null;
        } else {
          position = POSITION_ARRAY[1];
          oberpunkt = this.tagesordnung[POSITION_ARRAY[0] - 1].pk;
        }
      }
      DATA.tos_bez = this.model.tos_bez;
      DATA.tos_info = this.model.tos_info;
      DATA.tos_pos = position;
      DATA.oberpunkt = oberpunkt;
      DATA.module = this.model.modules.map(value => value.modul);

      this.postHttp({
        url: this.topsUrl,
        data: DATA,
      }).then(
        () => {
          this.reloadTagesordnung();
          this.addNotification({
            text: "_MSG_SITZUNGSDETAILS_TAGESORDNUNGSPUNKT_TOP_CREATED_SUCCESS_",
          });
          this.close();
        },
        errors => {
          this.onErrors(errors);
        },
      ).finally(
        () => this.loading = false
      );
    },

    onErrors(errors) {
      this.errors = errors.data;
    },

    deleteErrors() {
      this.errors = undefined;
    },
  },
};
