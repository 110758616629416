// @vue/component
export default {
  data() {
    return {
      fields: [
        {
          id: "file",
          type: "document",
          label: "_LBL_IMPORTEXPORTAUFTRAG_JSON_WIZARD_IMPEXPDATEI_",
          required: true,
          allowedMimeType: [
            "json",
            "zip",
          ],
          view: "v-alt",
          translate: true,
        },
      ],
    };
  },
};
