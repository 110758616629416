var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-class", "{'has-error' :vm.errors['" + (field) + "']}", true, true)) + " class=\"form-group row\"><label" + (jade.attr("for", "" + (field) + "", true, true)) + " class=\"col-form-label col-sm-4 text-right font-weight-bold\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></label><div class=\"col-sm-6\">");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></div></div>");
};
buf.push("<section dias-cloak=\"vm.loading\"><div class=\"row\"><div ng-class=\"vm.regClass\"><div ng-if=\"vm.successStatus\" class=\"card\"><div ng-bind-html=\"vm.regHeader.successLabel\" ng-class=\"vm.regHeader.class\"></div><div class=\"card-body\"><div class=\"row\"><div ng-bind-html=\"vm.regBody.success\" ng-class=\"vm.regBody.class\"></div></div><div class=\"row\"><div ng-attr-id=\"{{ vm.DiasNotification.form.containerId }}\" ng-class=\"vm.regBody.class\"></div></div><form>");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"password_new1\" auto-focus=\"true\" aria-required=\"true\" name=\"password_new1\" ng-attr-aria-invalid=\"{{vm.errors.new_password1 ? 'true' : 'false'}}\" ng-change=\"vm.deleteErrors()\" ng-model=\"vm.model.new_password1\" placeholder=\"Passwort\" type=\"password\" class=\"form-control\">");
}
}, "new_password1", "Passwort*");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"password_new2\" aria-required=\"true\" name=\"password_new2\" ng-attr-aria-invalid=\"{{vm.errors.new_password2 ? 'true' : 'false'}}\" ng-change=\"vm.deleteErrors()\" ng-model=\"vm.model.new_password2\" placeholder=\"Passwort (Wiederholung)\" type=\"password\" class=\"form-control\">");
}
}, "new_password2", "Passwort (Wiederholung)*");
buf.push("<div class=\"form-group row\"><span class=\"offset-sm-4 col-sm-6\"><dias-requirement></dias-requirement></span></div><div class=\"form-group row\"><div class=\"offset-sm-4 col-sm-6\"><div class=\"float-right inline\"><button ng-click=\"vm.absenden()\" type=\"submit\" class=\"btn btn-primary\">Absenden</button></div></div></div></form></div></div><div ng-if=\"!vm.successStatus\" class=\"card\"><div ng-bind-html=\"vm.regHeader.unsuccessLabel\" ng-class=\"vm.regBody.class\"></div><div class=\"card-body\"><div class=\"row\"><div ng-bind-html=\"vm.regBody.unsuccess\" ng-class=\"vm.regBody.class\"></div></div><div class=\"row\"><div ng-class=\"vm.regBody.class\"><a ui-sref=\"root.login\" style=\"white-space:nowrap;\"><i class=\"glyphicon glyphicon-chevron-right\"></i>&nbsp;Zur Anmeldeseite</a><span>&nbsp;</span><a ui-sref=\"root.registration\" style=\"white-space:nowrap;\"><i class=\"glyphicon glyphicon-chevron-right\"></i>&nbsp;Zur erneuten Registrierung</a></div></div></div></div></div></div></section>");;return buf.join("");
}