"use strict";

import template from "./template.jade";

class StueckkostenBetragsrechnerController {
  /*@ngInject*/
  constructor(i18n, Katalog) {
    this.gettext = i18n.gettext;
    if (!this.kontext) {
      this.kontext = "antrag";
    }
    this.einheitChoices = [];
    Katalog.getKatalog("keinheit", { pk: this.extras.antragsregel.afr_argument.einheiten.wert }).then(result => {
      this.einheitChoices = result;
      if (this.einheitChoices.length === 1) {
        this.posmodel.argument.einheit = this.einheitChoices[0].pk;
      }
    });
  }
}

export default {
  template: template(),
  controller: StueckkostenBetragsrechnerController,
  controllerAs: "ctx",
  bindings: {
    rechner: "=",
    posmodel: "=",
    errors: "=",
    extras: "=",
    kontext: "=?",
    vorschau: "=",
    prefix: "<?"
  },
};
