import PuxButton from "../../../../PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../PuxTranslate/PuxTranslate.vue";

import UiValidatedJsonModeManyMixin from "../../UiValidatedJsonModeManyMixin";

// @vue/component
export default {
  name: "ModeJsonReadOnly",
  components: {
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    UiValidatedJsonModeManyMixin
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
};
