import ModalCreateOrUpdate from "./ModalCreateOrUpdate/ModalCreateOrUpdate.vue";
import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import {
  cloneDeep,
  forEach,
  orderBy,
  size,
  findKey,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeTabelle",
  components: {
    ModalCreateOrUpdate,
    PuxButton,
  },
  mixins: [
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      confirmShow: undefined,
      confirmOptions: undefined,
      statusModal: undefined,
      currentModel: undefined,
    };
  },
  computed: {
    getModelSize() {
      return size(this.model);
    },
    getModelList() {
      return orderBy(this.model, ["pos"]);
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../global/components/Modal/Modal.vue").default;
  },
  methods: {
    openModalCreate() {
      this.statusModal = true;
    },

    openModalUpdate({ item }) {
      this.currentModel = item;
      this.statusModal = true;
    },

    closeModal({ model } = {}) {
      if (model) {
        this.onInput_mixin({ value: model });
      }
      this.statusModal = undefined;
      this.currentModel = undefined;
    },

    moveUpAntwort({ id }) {
      if (this.model[id].pos < 1) {
        return;
      }
      const MODEL = cloneDeep(this.model);
      const POS = MODEL[id].pos - 1;
      const KEY = findKey(MODEL, ["pos", POS]);
      MODEL[id].pos--;
      MODEL[KEY].pos++;
      this.onInput_mixin({ value: MODEL });
    },

    moveDownAntwort({ id }) {
      if (this.model[id].pos >= this.getModelSize - 1) {
        return;
      }
      const MODEL = cloneDeep(this.model);
      const POS = MODEL[id].pos + 1;
      const KEY = findKey(MODEL, ["pos", POS]);
      MODEL[id].pos++;
      MODEL[KEY].pos--;
      this.onInput_mixin({ value: MODEL });
    },

    openConfirmDelete({ id }) {
      const VALIDATE = this.validateDelete({ id });
      let msg = "<p>Sind Sie sicher, dass sie die Antwortmöglichkeit von dieser Regel entfernen wollen?</p>";
      let okCallback = () => this.deleteAntwort({ id });
      const EXTRA = {};
      if (!VALIDATE.statusValid) {
        msg = VALIDATE.msgTranslate;
        okCallback = undefined;
        EXTRA.errors = VALIDATE.errors;
      }
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "Antwortmöglichkeit entfernen",
        msg,
        okLabel: "Fortfahren",
        okCallback,
        cancelCallback: this.closeConfirmDelete,
        extra: EXTRA,
        loading: false,
      };
      this.confirmShow = true;
    },

    validateDelete({ id }) {
      const VALIDATE = {
        statusValid: true,
        msgTranslate: "_MSG_PARAMETER_DYN_TABELLE_CONFIRM_VALIDATE_{{errors}}_",
        errors: "",
      };
      const CURRENT_MODEL = this.model[id];
      if (CURRENT_MODEL.type === "single_choice_objektkatalog") {
        forEach(this.model, item => {
          if ((item.type === "decimal_objektkatalog_data" ||
            item.type === "text_objektkatalog_data") &&
            item.objektkatalog_spalte_id === id) {
            VALIDATE.errors = `${ VALIDATE.errors }<p>${ item.label }</p>`;
            VALIDATE.statusValid = false;
          }
        });
      }
      if (CURRENT_MODEL.type === "single_choice") {
        forEach(this.model, item => {
          if ((item.type === "decimal_katalog_data" ||
            item.type === "text_katalog_data") &&
            item.katalog_spalte_id === id) {
            VALIDATE.errors = `${ VALIDATE.errors }<p>${ item.label }</p>`;
            VALIDATE.statusValid = false;
          }
        });
      }
      return VALIDATE;
    },

    deleteAntwort({ id }) {
      const MODEL = cloneDeep(this.model);
      const POS = MODEL[id].pos;
      if (MODEL[id]) {
        delete MODEL[id];
      }
      forEach(MODEL, (item, key) => {
        if (item.pos > POS) {
          MODEL[key].pos--;
        }
      });
      this.onInput_mixin({ value: MODEL });
      this.closeConfirmDelete();
    },

    closeConfirmDelete() {
      this.confirmShow = false;
    },
  },
};
