"use strict";

import angular from "angular";

export default () => {
  return input => {
    if (angular.isUndefined(input)) {
      return 0;
    }
    return Number(input);
  };
};
