"use strict";

import BaseModel from "dias/core/models/baseModel";

class FoerderfinderHierarchie extends BaseModel {
  constructor() {
    super("foerderfinderhierarchie", {
    });
  }
}

export default FoerderfinderHierarchie;
