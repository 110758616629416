// @vue/component
export default {
  name: "QuartierskarteMarkerTooltip",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    adresstypObj: {
      type: Object,
      default: () => {},
    },
    typ: {
      type: String,
      default: undefined,
    },
  },
};
