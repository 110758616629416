import UiDocumentErrorCurrent from "./UiDocumentErrorCurrent/UiDocumentErrorCurrent.vue";
import translate from "../../../../directives/translate";

// @vue/component
export default {
  name: "UiDocumentAlert",
  components: {
    UiDocumentErrorCurrent,
  },
  directives: {
    translate,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    errors: {
      type: Array,
      required: true,
    },
    errorPlaceholders: {
      type: Object,
      required: true,
    },
    deleteErrors: {
      type: Function,
      required: true,
    },
  },
};
