import AnalysenDetails from "../AnalysenDetails/AnalysenDetails.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import {
  forEach,
  merge,
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "AnalysenStatus",
  components: {
    AnalysenDetails,
    PuxButton,
    PuxIcon,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    autoRun: {
      type: Boolean,
      default: true,
    },
    objUrl: {
      type: String,
      required: false,
      default: undefined,
    },
    title: {
      type: String,
      required: false,
      default: "_TXT_ANALYSE_STATUS_DATENBANKKONSISTENZ_",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    kontexte: {
      type: Array,
      required: false,
      default: () => [],
    },
    htmlId: {
      type: String,
      required: false,
      default: "analysen_status",
    },
  },
  data() {
    return {
      loading: {
        analysen: false,
        tags: false
      },
      statusMapping: {
        unknown: { icon: "glyphicon-ersatzantrag" },
        info: { icon: "glyphicon-info-sign" },
        impossible: { icon: "glyphicon-minus" },
        ok: { icon: "glyphicon-ok" },
        warning: { icon: "glyphicon-warning" },
        error: { icon: "glyphicon-warning" },
        critical: { icon: "glyphicon-warning" },
        failed: { icon: "glyphicon-warning" },
      },
      displayStatus: ["failed", "critical", "error", "warning", "ok", "info", "unknown"],
      statusModal: undefined,
      analysen: [],
      tags: {},
    };
  },
  computed: {
    statusLoading() {
      return this.loading.analysen || this.loading.tags;
    },

    statusHasPermissionAnalyseRun() {
      return this.checkPermissionsSync({ perm: "analyse.run" });
    },

    statusCumulated() {
      return this.getCumulatedStatus(this.analysen);
    },

    statusIcon() {
      return this.getStatusIcon(this.statusCumulated);
    },

    statusIconForButton() {
      if (this.statusLoading) {
        return;
      }
      return this.statusIcon;
    },

    selectorCloseForAnalysenDetails() {
      return `#${ this.htmlId }`;
    },
  },
  created() {
    this.init();
  },
  methods: {
    openDetails() {
      if (this.statusLoading) {
        return;
      }
      this.statusModal = true;
    },

    closeDetails() {
      this.statusModal = false;
    },

    init() {
      if (this.statusHasPermissionAnalyseRun) {
        this.loading.tags = true;
        this.getHttp({
          url: "katalog/",
          urlParams: {
            key: "tags",
          },
        }).then(
          response => {
            this.tags = keyBy(response, "id");
            this.updateAnalysen({ run: this.autoRun });
          }
        ).finally(() => this.loading.tags = false);
      }
    },

    updateAnalysen({ tag, key, run }) {
      const url = this.getAnalysenURL(run);
      this.loading.analysen = true;
      // this.analysen = [{"kennung":"syconfig_analyse","bezeichnung":"System-Konfigurationsanalyse","beschreibung":"Überprüft, ob alle Konfigurationseinträge (SyConfig) gültig sind.","tags":["allgemein"],"model":null,"result":{"status":"warning","status_text":"Es wurden 13 Probleme in der Systemkonfiguration ermittelt.","details":[["Kennung","Ergebnis","Details"],["demo_foerderantrag","Unbekannt",""],["test_aa","Unbekannt",""],["dok_suche","Ungültige Konfiurationswerte","antragstellerorganisationen_dokarten, foerderantraege_dokarten"],["bv_mandant","Ungültige Konfiurationswerte","inhaber, bankname, iban, bic"],["sitzungsantraege","Ungültige Konfiurationswerte","anzeige, default_dokart"],["dokart_antragsPDF","Ungültige Konfiurationswerte","dokarten"],["default_ntc_anzahl","Ungültige Konfiurationswerte","ntc_anzahl"],["az_restriction","Ungültige Konfiurationswerte","permission"],["objektpruefung_abgelaufen","Ungültige Konfiurationswerte","wfo_ids"],["azp_ignore_auids","Ungültige Konfiurationswerte","azp_ignore_auids"],["dokart_snapshot","Ungültige Konfiurationswerte","dokarten"],["en_gutachten","Ungültige Konfiurationswerte","beschaeftigte, umsatz, registereintrag, registerdatum, aufgaben_ausfuellen, kostenart_personal, kostenart_uebrige, kostenart_projekt, kostenart_fue, mitarbeiter, qualifikation, personalkapazitaet, finart_eigen, fue_auftrag, arbeitspaket, foerdersatz, innovationsgehalt, wirtschaftl_situation, darstellung, umsatzentwicklung, kostenrahmen, vorherige_foerderung, wirtschaftl_entwicklung, wirtschaftl_wirkung, techn_wirkung, sonstige_bemerkungen, projektkategorien, aussteller, foerderempfehlung"],["az_relevant_aufgabe","Ungültige Konfiurationswerte","au_ids"]]}}];
      return this.getListHttp({
        url: url,
        tags: tag,
        key: key,
        kontexte: this.kontexte
      }).then(
        response => this.analysen = merge(this.analysen, response)
      ).finally(() => this.loading.analysen = false);
    },

    getAnalysenURL(run) {
      if (run) {
        if (this.objUrl) {
          return `${ this.objUrl }/run_analysen/`;
        }
        return "analysen/run/";
      }
      if (this.objUrl) {
        return `${ this.objUrl }/analysen/`;
      }
      return "analysen/";
    },

    getStatusIcon(status) {
      if (this.statusMapping[status]) {
        return this.statusMapping[status].icon;
      }
      return this.statusMapping.unknown.icon;
    },

    getCumulatedStatus(analysen) {
      const STATUS_COUNT = {};
      forEach(analysen, analyse => {
        if (analyse.result && analyse.result.status) {
          const STATUS = analyse.result.status;
          if (!STATUS_COUNT[STATUS]) {
            STATUS_COUNT[STATUS] = 0;
          }
          STATUS_COUNT[STATUS] = STATUS_COUNT[STATUS] + 1;
        }
      });
      const occuring = this.displayStatus.filter(ds => STATUS_COUNT[ds] > 0);
      return occuring ? occuring[0] : null;
    },
  },
};
