import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../../../global/mixins/PermissionMixin";

import {
  map,
  pick,
} from "lodash-es";

// @vue/component
export default {
  name: "NameUndModul",
  components: {
    FormstepDetail,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    statusfunktion: {
      type: Object,
      required: true,
      info: "Hauptinformation",
    },
    updateStatusfunktion: {
      type: Function,
      required: true,
      info: "Statusfunktion aktualisieren",
    },
  },
  data() {
    return {
      options: {
        label: "_TXT_STATUSFUNKTION_DETAILS_NAME_UND_MODUL_PANEL_FORMSTEP_NAME_",
        info: "_HTML_STATUSFUNKTION_DETAILS_NAME_UND_MODUL_PANEL_FORMSTEP_INFO_",
        list: [
          {
            id: "wts_bez",
            type: "text",
            label: "_LBL_STATUSFUNKTION_DETAILS_BEZEICHNUNG_",
            showLabel: true,
            view: "v",
            required: true,
          },
          {
            id: "statusfunktion",
            type: "select",
            label: "_LBL_STATUSFUNKTION_DETAILS_MODUL_",
            url: "katalog/?key=statusfunktion_module",
            showLabel: true,
            view: "v",
            keyId: "pk",
            keyLabel: "bez",
            required: true,
            search: true,
            searchList: ["bez"],
          },
        ],
        required: true,
        saveCallback: this.save,
      },
      canEdit: undefined,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.canEdit = this.checkPermissionsSync({ perm: "statusfunktion.update" });
    },

    save({ model }) {
      const DATA = pick(model, map(this.options.list, option => option.id));
      return this.patchHttp({
        url: `statusfunktionen/${ this.statusfunktion.pk }/`,
        data: DATA,
      }).then(
        response => {
          this.updateStatusfunktion({ statusfunktion: response });
          this.addNotification({
            text: "_MSG_STATUSFUNKTION_DETAILS_NAME_UND_MODUL_UPDATE_{{bez}}_",
            extra: {
              frage: this.statusfunktion.wts_bez,
            },
          });
        },
      );
    },
  },
};
