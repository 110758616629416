"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";


const ampel = angular.module("dias.pruefkriterien.module.parameter", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("pruefkriterien_auswahl", "parametermodul-multi-select");
  })
;
export default ampel;
