"use strict";

import parameterServices from "../services";
import template from "./config.jade";
import controller from "./controller";


const parametermoduleDayMonth = angular.module("dias.foerderregeln.parameter.daymonth", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("daymonth", "parametermodul-daymonth");
  })
  .component("parametermodulDaymonth", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulDaymonthRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: "{{ vm.parameter[vm.key].wert.day }}.{{ vm.parameter[vm.key].wert.month }}.",
    controllerAs: "vm",
  });
export default parametermoduleDayMonth;
