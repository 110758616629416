import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import NachrichtenLink from "../../Nachrichten/NachrichtenLink/NachrichtenLink.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "FiBuJournalListDetails",
  components: {
    AngularLink,
    NachrichtenLink,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    buchung: {
      type: Object,
      default: () => ({}),
    },
    replace: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const {
      filterCurrency,
      filterDate,
    } = FiltersAPI();

    return {
      filterCurrency,
      filterDate,
    };
  },
  data() {
    return {
      statusLoading: true,
    };
  },
  computed: {
    getHrefAntrag() {
      return `/foerderantrag/${ this.buchung.antrag.pk }/`;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.buchung._details) {
        this.statusLoading = false;
        return;
      }
      this.getHttp({ url: `fibujournals/${ this.buchung.pk }/vorschau/` }).then(
        response => {
          response._details = true;
          this.replace(response);
          this.statusLoading = false;
        }
      );
    },
  },
};
