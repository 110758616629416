"use strict";

import BaseModel from "dias/core/models/baseModel";


class RegistrierungAbschliessen extends BaseModel {
  constructor() {
    super("auth/registrierung_abschliessen/");
  }
}

export default RegistrierungAbschliessen;
