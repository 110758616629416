import Alert from "../../../../../global/components/Alert/Alert.vue";
import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import AntragstellerAuswahlReadonlyDufo from "./AntragstellerAuswahlReadonlyDufo/AntragstellerAuswahlReadonlyDufo.vue";
import MissingAntragstellerBeschreibung from "../MissingAntragstellerBeschreibung/MissingAntragstellerBeschreibung.vue";
import NutzerGesperrtIcon from "../../../../Nutzer/NutzerGesperrtIcon/NutzerGesperrtIcon.vue";
import PuxCloak from "../../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../../../global/components/ui/ShowMore/ShowMore.vue";
import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import translate from "../../../../../global/directives/translate";

// @vue/component
export default {
  name: "AntragstellerAuswahlReadonly",
  components: {
    AngularLink,
    AntragstellerAuswahlReadonlyDufo,
    Alert,
    NutzerGesperrtIcon,
    MissingAntragstellerBeschreibung,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
    SnapshotIcon,
    ShowMore,
  },
  directives: {
    translate,
  },
  props: {
    data: {
      type: Object,
      default: undefined,
    },
    diff: {
      type: Object,
      default: undefined,
    },
    orderedFoerderorgArr: {
      type: Array,
      default: undefined,
    },
    hideBeschreibung: {
      type: Boolean,
    },
    expectBeschreibung: {
      type: Boolean,
    },
    canViewContacts: {
      type: Boolean,
    },
    contactUsersCount: {
      type: Number,
      required: true,
    },
    contactUsersObj: {
      type: Object,
      required: true,
    },
    statusDufo: {
      type: Boolean,
      required: true,
    },
    statusDufoLoading: {
      type: Boolean,
      required: true,
    },
    dufoObj: {
      type: Object,
      required: true,
    },
    showLink: {
      type: Boolean,
      default: () => true
    },
  },
  data() {
    return {
      contactUsersCountEmptyMax: 4,
    };
  },
  computed: {
    statusDiffAst() {
      return this.diff["ast_obj.ast_name"] ||
        this.diff["ast_obj.ast_namezusatz"] ||
        this.diff["ast_obj.is_beantragt"] ||
        this.diff["ast_obj.kopfdublette"];
    },

    statusDiffAnschrift() {
      return this.diff["ast_obj.ast_strasse"] ||
        this.diff["ast_obj.ast_hsnr"] ||
        this.diff["ast_obj.ast_plz"] ||
        this.diff["ast_obj.ast_ort"];
    },

    contactUsersCountLocal() {
      if (this.contactUsersCount <= this.contactUsersCountEmptyMax) {
        return this.contactUsersCount;
      }
      let contactUsersCountLocal = this.contactUsersCountEmptyMax;
      for (let i = this.contactUsersCountEmptyMax; i < this.contactUsersCount; i++) {
        const KEY = `asp_anu_${ i }`;
        if (this.data[KEY]) {
          contactUsersCountLocal++;
        } else {
          break;
        }
      }
      return contactUsersCountLocal;
    },
  },
};
