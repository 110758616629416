"use strict";

import angular from "angular";

import parameterTextbaustein from "./textbaustein";

const parameter = angular.module("dias.textbausteine.parameter", [
  parameterTextbaustein.name,
])

;

export default parameter;
