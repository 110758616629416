import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import translate from "../../../../../global/directives/translate";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "AstBankverbindungenTr",
  components: {
    SnapshotIcon,
    PuxButton,
  },
  directives: {
    translate,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: undefined,
    },
    statusEditable: {
      type: Boolean,
      required: false,
    },
    statusDeletable: {
      type: Boolean,
      required: false,
    },
    statusSnapshot: {
      type: Boolean,
      required: false,
    },
    statusSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    statusSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    statusSnapshotChanges: {
      type: Boolean,
      required: false,
    },
    statusSnapshotChangesIcon: {
      type: Boolean,
      required: false,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    snapshotChangesDiff: {
      type: Object,
      required: false,
      default: undefined,
    },
    htmlRegelNummer: {
      type: String,
      required: false,
      default: undefined,
    },
    openModalCreateOrUpdate: {
      type: Function,
      required: false,
      default: () => {},
    },
    openModalDelete: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  setup() {
    const {
      filterIban,
    } = FiltersAPI();

    return {
      filterIban,
    };
  },
  computed: {
    ibanFiltered() {
      return this.filterIban(this.data.aov_iban);
    },

    idButtonEdit() {
      return `${ this.htmlRegelNummer }_edit_${ this.data.pk }`;
    },

    idButtonDelete() {
      return `${ this.htmlRegelNummer }_delete_${ this.data.pk }`;
    },

    statusShowButtonEdit() {
      return this.statusEditable && !this.statusSnapshotDelete && !this.statusSnapshotChanges;
    },

    statusShowButtonDelete() {
      return this.statusDeletable && !this.statusSnapshotDelete && !this.statusSnapshotChanges;
    },

    statusShowButtons() {
      return this.statusShowButtonEdit || this.statusShowButtonDelete;
    },

    snapshotIconFirstTdType() {
      if (this.statusSnapshotAdd) {
        return "add";
      }
      if (this.statusSnapshotDelete) {
        return "delete";
      }
      if (this.statusSnapshotChangesIcon) {
        return "changes";
      }
      return "";
    },

    translateVersionTdOptions() {
      return {
        text: this.translateVersionTdText,
        extra: {
          date: this.snapshotDate,
        },
      };
    },

    translateVersionTdText() {
      if (this.statusSnapshotAdd) {
        return "_TXT_AKTUELLE_VERSION_ADDED_COLUMN_{{date}}_";
      }
      if (this.statusSnapshotDelete) {
        return "_TXT_VERSION_REMOVED_COLUMN_{{date}}_";
      }
      if (this.statusSnapshotChanges) {
        return "_TXT_VERSION_MODIFIED_COLUMN_{{date}}_";
      }
      return "_TXT_AKTUELLE_VERSION_";
    },

    classVersionFirst() {
      if (this.statusSnapshotDelete || this.statusSnapshotChanges) {
        return "version-bg";
      }
      return "";
    },

    rowspanFirstTd() {
      return this.statusSnapshotChangesIcon ? 2 : 1;
    },

    statusShowFirstTd() {
      return !(this.statusSnapshotChanges && !this.statusSnapshotChangesIcon);
    },

    diffLocal() {
      return this.snapshotChangesDiff || {};
    },
  },
};
