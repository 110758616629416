"use strict";

import BaseModel from "dias/core/models/baseModel";


class KBerichttyp extends BaseModel {
  constructor() {
    super("berichttypen");
  }
}

export default KBerichttyp;
