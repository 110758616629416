"use strict";

import template from "./versioncheck.jade";
import VersionCheckController from "./versioncheck.controller";

export default {
  template: template(),
  controller: VersionCheckController,
  controllerAs: "vm"
};
