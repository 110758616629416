import FormstepDetail from "../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";
import StudiengangDetailsTeilstudiengang
  from "./StudiengangDetailsTeilstudiengang/StudiengangDetailsTeilstudiengang.vue";

import HttpMixin from "../../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";

import { EventBus } from "../../../../../../../libs/vue/client/vue/global/functions/event-bus";

// @vue/component
export default {
  name: "StudiengangDetailsTeilstudiengaenge",
  components: {
    FormstepDetail,
    StudiengangDetailsTeilstudiengang,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    studiengang: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      teilStudiengaenge: [],
      loading: false,
    };
  },
  computed: {
    teilStudiengangUrl() {
      return `studiengaenge/${ this.studiengang.pk }/teilstudiengaenge/`;
    },

    htmlId() {
      return "studiengang_details_teilstudiengaenge";
    },

    formstepDetailOptions() {
      return {
        label: "Übersicht",
      };
    },
  },
  created() {
    this.loadTeilStudiengange();
    this.initEventBus();
  },
  beforeUnmount() {
    EventBus.$off("loadTeilStudiengange", this.loadTeilStudiengange);
  },
  methods: {
    initEventBus() {
      EventBus.$on("loadTeilStudiengange", this.loadTeilStudiengange);
    },

    loadTeilStudiengange() {
      this.loading = true;
      this.getListHttp({
        url: this.teilStudiengangUrl,
        urlParams: {
          katalog: true,
        },
      }).then(
        response => {
          this.teilStudiengaenge = response;
        }
      ).finally(
        () => {
          this.loading = false;
        }
      );
    },
  },
};
