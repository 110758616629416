import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import ImportauftragListProtokoll from "./ImportauftragListProtokoll/ImportauftragListProtokoll.vue";
import ImportauftragModalAusfuehren from "./ImportauftragModalAusfuehren/ImportauftragModalAusfuehren.vue";
import ImportauftragModalLoeschen from "./ImportauftragModalLoeschen/ImportauftragModalLoeschen.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";
import TaskLogStatus from "../../../global/components/TaskLog/TaskLogStatus/TaskLogStatus.vue";
import UiJsonReadOnly from "../../../global/components/ui/UiJson/UiJsonReadOnly/UiJsonReadOnly.vue";

import NotificationMixin from "../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import { EventBus } from "../../../global/functions/event-bus";
import getTableApi from "../../../const/TableApis";
import {
  assign,
} from "lodash-es";

const TABLE_ID = "impexpauftrag";

// @vue/component
export default {
  name: "ImportauftragList",
  components: {
    AngularLink,
    ImportauftragListProtokoll,
    ImportauftragModalAusfuehren,
    ImportauftragModalLoeschen,
    PageTabTitle,
    PuxIcon,
    PuxTranslate,
    SmartTable,
    TaskLogStatus,
    UiJsonReadOnly,
  },
  mixins: [
    NotificationMixin,
    PermissionMixin,
  ],
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    return {
      filterDate,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_TXT_IMPORTEXPORTAUFTRAG_TABLE_ACTIONS_EXECUTE_",
            callback: this.askImportauftragExecute,
            isHidden: ({ item }) => !this.canExecute({ item }),
          },
          {
            label: "_TXT_IMPORTEXPORTAUFTRAG_TABLE_ACTIONS_DELETE_",
            callback: this.askImportauftragDelete,
            isHidden: ({ item }) => !this.canDelete({ item }),
          },
        ],
        actions: [],
        label: "_LBL_IMPORTAUFTRAG_LIST_IMPORTAUFTRAEGE_",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      modal: {
        delete: undefined,
        execute: undefined
      },
    };
  },
  methods: {
    canUpdate({ item }) {
      return this.checkPermissionsSync({ perm: "importauftraege.update", permArray: item.user_permissions });
    },

    canExecute({ item }) {
      return !item.iea_impexp_datum && this.checkPermissionsSync({ perm: "importauftraege.ausfuehren", permArray: item.user_permissions });
    },

    canDelete({ item }) {
      return item.deletable && this.checkPermissionsSync({ perm: "importauftraege.delete", permArray: item.user_permissions });
    },

    askImportauftragExecute({ item, index }) {
      this.currentRow = item;
      this.currentIndex = index;
      this.modal.execute = true;
    },

    askImportauftragDelete({ item, index }) {
      this.currentRow = item;
      this.currentIndex = index;
      this.modal.delete = true;
    },

    updateRow({ response }) {
      response = response || this.currentRow;
      if (response) {
        const row = assign({}, this.currentRow, response);
        EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row, index: this.currentIndex }));
      }
    },

    closeModalExecute({ response } = {}) {
      this.modal.execute = false;
      this.updateRow({ response });
    },

    closeModalDelete({ deleted } = {}) {
      this.modal.delete = false;
      if (deleted) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
    },

    getHref({ obj, herkunftField }) {
      const MAP = {
        foerderantrag: "root.foerderantrag.details",
        vertrag: "root.vertrag.details",
        antragstellerorganisation: "root.astorganisationen.details",
      };
      return MAP[obj[herkunftField]];
    },

    getDownloadHref({ obj }) {
      return `/api/importauftraege/${ obj.pk }/download/`;
    },

    canViewTasklog({ item }) {
      return this.checkPermissionsSync({ perm: "tasklog.list", permArray: item.user_permissions });
    },

    getTasklogHref({ obj }) {
      return `/hintergrundaufgaben/${ obj.pk }/`;
    },
  },
};
