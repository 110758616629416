"use strict";

import template from "./details.jade";

import basedataEditTemplate from "./basisdaten.edit.jade";

class SachberichtvorlageDetailsController {
  /*@ngInject*/
  constructor(
    $q,
    $filter,
    $state,
    $stateParams,
    DiasNotification,
    diasConfirmDialog,
    Sachberichtvorlagen,
    Foerderregeln
  ) {
    this.$q = $q;
    this.$filter = $filter;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.DiasNotification = DiasNotification;
    this.Sachberichtvorlagen = Sachberichtvorlagen;
    this.Foerderregeln = Foerderregeln;

    this.baseEditTemplate = basedataEditTemplate;

    this.init();
  }

  init() {
    this.loading = {
      sachberichtvorlage: true,
      sachberichtregeln: true,
      regeln: true
    };
    this.loadSachberichtvorlage().finally(
      () => this.loading.sachberichtvorlage = false
    );
    const existingRules = this.loadSachberichtregeln().finally(
      () => this.loading.sachberichtregeln = false
    );
    const allRules = this.loadRegeln().finally(
      () => this.loading.regeln = false
    );
    this.$q.all(
      [existingRules, allRules]
    ).then(() => this.initVerwendbareRegeln());
  }

  loadSachberichtvorlage() {
    return this.Sachberichtvorlagen.one(this.$stateParams.id).get().then(
      response => this.sbv = response,
      error => this.DiasNotification.page.error(error, "Fehler beim Laden der Sachberichtvorlage")
    );
  }

  loadSachberichtregeln() {
    return this.Sachberichtvorlagen.one(this.$stateParams.id).regeln.getList().then(
      response => this.sbvRegeln = response,
      error => this.DiasNotification.page.error(error, "Fehler beim Laden der Sachberichtvorlage")
    );
  }

  loadRegeln() {
    return this.Foerderregeln.getSachberichtregeln().then(
      response => this.regeln = angular.forEach(response, item => {
        item.titel = (item.re_nummer ? ("[" + item.re_nummer + "] ") : "") + item.re_kbez;
      }),
      error => this.DiasNotification.page.error(error, "Fehler beim Laden der Geschäftsregeln")
    );
  }

  initVerwendbareRegeln() {
    if (!angular.isUndefined(this.regeln)) {
      this.updateVerwendbareRegeln(this.$filter("filter")(this.regeln, value => this.filterVerwendbareRegeln(value)));
    }
  }

  filterVerwendbareRegeln(value) {
    for (let i = 0; i < this.sbvRegeln.length; i++) {
      if (this.sbvRegeln[i].regel === value.pk) {
        return false;
      }
    }
    return true;
  }

  updateVerwendbareRegeln(newRegeln) {
    this.verwendbareRegeln = this.$filter("orderBy")(newRegeln, "re_nummer");
  }

  addSachberichtregel() {
    return regel => {
      this.loading.sachberichtregeln = true;
      return this.sbvRegeln.post({ regel: regel.pk }).then(
        response => {
          this.sbvRegeln.push(response);
          this.updateVerwendbareRegeln(this.$filter("filter")(this.verwendbareRegeln, { pk: "!".concat(response.regel) }));
          this.DiasNotification.page.success("Geschäftsregel erfolgreich hinzugefügt");
        },
        error => this.DiasNotification.page.error(error, "Fehler beim Hinzufügen der Geschäftsregel")
      ).finally(() => this.loading.sachberichtregeln = false);
    };
  }

  deleteSachberichtregel() {
    return sbvRegel => {
      this.loading.sachberichtregeln = true;
      return sbvRegel.remove().then(
        () => {
          // remove from our list
          this.sbvRegeln.splice(this.sbvRegeln.indexOf(sbvRegel), 1);
          // update remainig positions
          const rule = this.$filter("filter")(this.regeln, { pk: sbvRegel.regel })[0];
          this.verwendbareRegeln.push(rule);
          this.updateVerwendbareRegeln(this.verwendbareRegeln);
          this.DiasNotification.page.success("Geschäftsregel erfolgreich enfernt");
        },
        error => this.DiasNotification.page.error(error, "Fehler beim Entfernen der Geschäftsregel")
      ).finally(() => this.loading.sachberichtregeln = false);
    };
  }

  moveUpSachberichtregel() {
    return sbvRegel => {
      if (sbvRegel.sbr_pos === 1) {
        // Can't move first further up
        return;
      }
      this.loading.sachberichtregeln = true;
      return sbvRegel.moveUp().then(
        () => {
          const previous = this.sbvRegeln[sbvRegel.sbr_pos - 2];
          previous.sbr_pos += 1;
          sbvRegel.sbr_pos -= 1;
          this.sbvRegeln[previous.sbr_pos - 1] = previous;
          this.sbvRegeln[sbvRegel.sbr_pos - 1] = sbvRegel;
          this.DiasNotification.page.success("Geschäftsregel erfolgreich verschoben");
        },
        error => this.DiasNotification.page.error(error, "Fehler beim Verschieben der Geschäftsregel")
      ).finally(() => this.loading.sachberichtregeln = false);
    };
  }

  moveDownSachberichtregel() {
    return sbvRegel => {
      if (sbvRegel.sbr_pos === this.sbvRegeln.length) {
        // Can't move last further down
        return;
      }
      this.loading.sachberichtregeln = true;
      return sbvRegel.moveDown().then(
        () => {
          const next = this.sbvRegeln[sbvRegel.sbr_pos];
          next.sbr_pos -= 1;
          sbvRegel.sbr_pos += 1;
          this.sbvRegeln[next.sbr_pos - 1] = next;
          this.sbvRegeln[sbvRegel.sbr_pos - 1] = sbvRegel;
          this.DiasNotification.page.success("Geschäftsregel erfolgreich verschoben");
        },
        error => this.DiasNotification.page.error(error, "Fehler beim Verschieben der Geschäftsregel")
      ).finally(() => this.loading.sachberichtregeln = false);
    };
  }

  deleteSachberichtvorlage() {
    if (this.sbv.deletable) {
      this.diasConfirmDialog({
        titleTemplate: `Sachberichtvorlage löschen`,
        contentTemplate: `<p>Sind Sie sicher, dass Sie die Sachberichtvorlage {{ context.sbv.sbv_kbez }} löschen wollen?</p>`,
        context: { sbv: this.sbv },
        okLabel: "Sachberichtvorlage löschen",
        okCallback: () => this.doDelete()
      });
    }
  }

  doDelete() {
    this.loading.deleting = true;
    return this.sbv.remove().then(
      () => {
        this.DiasNotification.page.success(
          "Sachberichtvorlage <strong>" + this.sbv.sbv_kbez + "' erfolgreich gelöscht."
        );
        this.$state.go("root.sachberichtvorlagen");
      },
      error => {
        this.DiasNotification.page.error(error, "Fehler beim löschen der Sachberichtvorlage");
        this.loading.deleting = false;
      }
    );
  }
}

export default {
  template: template(),
  controller: SachberichtvorlageDetailsController,
  controllerAs: "vm"
};
