import {
  FilterCurrencyMixin,
} from "../../../mixins/FiltersMixin";

// @vue/component
export default {
  name: "TaskLogProgress",
  mixins: [
    FilterCurrencyMixin,
  ],
  props: {
    status: {
      type: String,
      required: false,
      default: undefined,
      info: "Status(PENDING, RUNNING, STARTED, SUCCESS, PARTIAL_SUCCESS, FAILURE)",
    },
    progress: {
      type: [String, Number],
      required: false,
      default: undefined,
      info: "Progress",
    },
  },
  computed: {
    classLocal() {
      return {
        info: this.status === "PENDING" || this.status === "RUNNING" || this.status === "STARTED",
        success: this.status === "SUCCESS",
        warning: this.status === "PARTIAL_SUCCESS",
        danger: this.status === "FAILURE",
      };
    },

    value() {
      return this.progress || 0;
    },

    valueFiltered() {
      return this.filterCurrency(this.value, "%", 1);
    },
  },
};
