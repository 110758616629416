"use strict";

import "angular-highlightjs";

import parameterServices from "dias/foerderregeln/parameter/services";
import configComponent from "./config.component";
import roComponent from "./readonly.component";


const zielgruppenAuswahl = angular.module("dias.zielgruppen.parameter", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("zielgruppen_auswahl", "parametermodul-zielgruppen-auswahl");
  })

  .component("parametermodulZielgruppenAuswahl", configComponent)
  .component("parametermodulZielgruppenAuswahlRo", roComponent);


export default zielgruppenAuswahl;
