import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxAlert from "../../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxErrors from "../../../../../global/components/PuxErrors/PuxErrors.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../../global/mixins/NotificationMixin";

// @vue/component
export default {
  name: "EMailAdresse",
  components: {
    FormElement,
    FormstepDetail,
    Modal,
    PuxAlert,
    PuxButton,
    PuxErrors,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
    reloadNutzer: {
      type: Function,
      required: true,
    },
    objektUrlName: {
      type: String,
      default: "nutzer",
    },
    askPassword: {
      type: Boolean,
      default: false,
    },
    panelPermissions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        label: "_LBL_NUTZER_DETAILS_EMAIL_ADRESSE_",
        required: true,
        saveCallback: this.save,
        closeCallback: this.close,
      },
      optionsFormElement: {
        email: {
          id: "email_new",
          type: "text",
          label: "_LBL_NUTZER_DETAILS_EMAIL_NEUE_ADRESSE_",
          required: true,
          view: "v",
          attributesHtml: {
            autocomplete: "off",
          },
          autoFocus: true,
          maxlength: 200,
        },
        password: {
          id: "email_new_password",
          type: "password",
          label: "_LBL_NUTZER_DETAILS_EMAIL_AKTUELLES_PASSWORT_",
          required: true,
          view: "v",
          attributesHtml: {
            autocomplete: "off",
          },
        }
      },
      modelEmail: "",
      modelPassword: "",
      errors: {},
      confirmShow: undefined,
      confirmOptions: undefined,
      statusAutocompleteOff: true,
      idForConfirmButton: "emailadresse_openmodalconfirm",
    };
  },
  computed: {
    noteText() {
      return this.nutzer.n_email_aenderung ?
        "_HTML_NUTZER_DETAILS_EMAIL_ADRESSE_HINWEIS_2_" :
        "_HTML_NUTZER_DETAILS_EMAIL_ADRESSE_HINWEIS_";
    },

    selectorClose() {
      return `#${ this.idForConfirmButton }`;
    },

    optionsListForPuxErrors() {
      return [
        this.optionsFormElement.email,
        this.optionsFormElement.password,
      ];
    },
  },
  methods: {
    save() {
      const DATA = {
        email_new: this.modelEmail,
      };
      if (this.askPassword) {
        DATA.email_new_password = this.modelPassword;
      }
      return new Promise((resolve, reject) => {
        this.postHttp({
          url: `${ this.objektUrlName }/${ this.nutzer.pk }/email_aenderung_starten/`,
          data: DATA,
        }).then(
          () => {
            this.reloadNutzer({ reload: true });
            this.addNotification({ text: "_MSG_NUTZER_DETAILS_EMAIL_CHANGE_SUCCESS_" });
            this.setDefaultModel();
            resolve();
          },
          error => {
            this.errors = error.data || {};
            reject();
          }
        );
      });
    },

    openConfirm() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_NUTZER_DETAILS_EMAIL_CHANGE_CANCEL_MODAL_HEADER_",
        msg: "_HTML_NUTZER_DETAILS_EMAIL_CHANGE_CANCEL_MODAL_BODY_",
        okLabel: "_BTN_YES_",
        cancelLabel: "_BTN_NO_",
        okCallback: this.updateEmailCancel,
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.confirmShow = true;
    },

    closeConfirm() {
      this.confirmShow = false;
    },

    updateEmailCancel() {
      this.confirmOptions.loading = true;
      this.postHttp({
        url: `${ this.objektUrlName }/${ this.nutzer.pk }/email_aenderung_abbrechen/`,
      }).then(
        () => {
          this.addNotification({ text: "_MSG_NUTZER_DETAILS_EMAIL_CHANGE_CANCEL_SUCCESS_" });
          this.reloadNutzer({ reload: true });
          this.closeConfirm();
          this.confirmOptions.loading = false;
          this.setDefaultModel();
        }
      );
    },

    setDefaultModel() {
      this.modelEmail = "";
      this.modelPassword = "";
    },

    close() {
      this.resetErrors();
    },

    resetErrors() {
      this.errors = {};
    },
  },
};
