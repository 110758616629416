import DokumenteSimpleList from "../../../../Dokumente/DokumenteSimpleList/DokumenteSimpleList.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import SyConfigMixin from "../../../../../global/mixins/SyConfigMixin";
import {
  FilterCurrencyMixin,
  FilterDateMixin,
} from "../../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "AntragOverviewFoerderung",
  components: {
    DokumenteSimpleList,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterDateMixin,
    SyConfigMixin,
  ],
  props: {
    antrag: {
      type: Object,
      required: true,
    },
    antragUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dokAnzeigeAblehnungsschreiben: "9807cf97-95a2-4b10-b78a-44b01325f627",
    };
  },
  computed: {
    isMonetaereFoerderung() {
      return this.getSyConfigsValue("monetaere_foerderung");
    },

    antragsdatumFiltered() {
      return this.filterDate(this.antrag.a_antragsdatum);
    },

    zuschussbeantragt() {
      return this.filterCurrency(this.antrag.a_zuschussbeantragt || 0);
    },

    bewilligungsdatumFiltered() {
      return this.filterDate(this.antrag.a_bewilligungsdatum);
    },

    zuschussbewilligt() {
      return this.filterCurrency(this.antrag.a_zuschussbewilligt || 0);
    },

    ablehnungsdatumFiltered() {
      return this.filterDate(this.antrag.a_ablehnungsdatum);
    },
  },
};
