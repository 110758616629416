"use strict";

import antwortTemplate from "./antwort.details.jade";
import createAngebotzuordnungController from "./modal.create.angebotszuordnung.controller.js";
import createAngebotzuordnungTemplate from "./modal.create.angebotszuordnung.template.jade";
import editAntwortController from "./editAntwort.controller.js";

class FoerderfinderAntwortController {
  /*@ngInject*/
  constructor(
    $q, 
    $stateParams, 
    Foerderfinderfragen, 
    KFoerderfinderantwortTypUUIDS,
    DiasTableConfig, 
    diasModalDialog, 
    DiasNotification
  ) {
    this.Foerderfinderfragen = Foerderfinderfragen;
    this.KFoerderfinderantwortTypUUIDS = KFoerderfinderantwortTypUUIDS;
    this.diasModalDialog = diasModalDialog;
    this.$stateParams = $stateParams;
    this.notification = DiasNotification.page;
    this.editAntwortController = editAntwortController;
    this.angeboteTableDeferred = $q.defer();
    this.angeboteTable = this.angeboteTableDeferred.promise;
    this.loading = true;
    this.loadAntwort();
    this.reloadPage = () => {
      this.angeboteLastChange = new Date().getTime();
    };
  }

  loadAntwort() {
    return this.Foerderfinderfragen.one(
      this.$stateParams.id
    ).antworten.one(this.$stateParams.ffa_id).get().then(
      response => {
        this.antwort = response;
        this.resolveAngeboteTable();
        this.loading = false;
      }
    );
  }

  showAngebote() {
    if (this.antwort && this.antwort.typ === this.KFoerderfinderantwortTypUUIDS.FILTER) {
      return true;
    }
    return false;
  }

  deleteAngebotzuordnung(row) {
    this.antwort.customPUT({ foe_id: row.pk }, "remove_zuordnung").then(
      () => {
        this.notification.success("Zuordnung wurde gelöscht.");
        this.reloadPage();
      },
      () => this.notification.error("Zuordnung konnte nicht gelöscht werden.")
    );
  }


  resolveAngeboteTable() {
    this.angeboteTableDeferred.resolve({
      dataSource: this.antwort.angebote.getList,
      tableTitle: "Zugeordnete Förderangebote",
      columnDefs: [
        { label: "Bezeichnung", field: "foe_kbez", sortable: true, sortingDefault: true, sortingDefaultDirection: "asc" },
      ],
      tableActions: [
        { label: "Neue Förderangebotszuordnung erstellen",
          icon: "glyphicon glyphicon-plus", primary: true,
          callback: () => this.diasModalDialog({
            title: "Förderangebotszuordnung erstellen",
            template: createAngebotzuordnungTemplate,
            controller: createAngebotzuordnungController,
            extras: { reloadPage: this.reloadPage }
          })
        }
      ],
      actions: [
        { label: "entfernen",
          isVisible: true,
          callback: this.deleteAngebotzuordnung.bind(this) },
      ],
      isDirty: scope => {
        return this.angeboteLastChange > scope.vm.lastRefresh;
      }
    });
  }
}

export default {
  template: antwortTemplate(),
  controller: FoerderfinderAntwortController,
  controllerAs: "vm"
};
