"use strict";
import angular from "angular";
import antragNumberFormat from "./antragNumberFormat.pipe";
import trunc from "./trunc.pipe";
import htmlToPlaintext from "./htmlToPlaintext.pipe";
import geldFormat from "./geldFormat.pipe";
import unique from "./unique.pipe";
import dynZahlFormat from "./dynZahlFormat.pipe";
import searchFormat from "./searchFormat.pipe";

const antragFiltersModule = angular.module("foerderantrag.pipes", [])

  .filter("trunc", trunc)
  .filter("antragNumberFormat", antragNumberFormat)
  .filter("htmlToPlaintext", htmlToPlaintext)
  .filter("geldFormat", geldFormat)
  .filter("unique", unique)
  .filter("dynZahlFormat", dynZahlFormat)
  .filter("searchFormat", searchFormat)
;

export default antragFiltersModule;
