"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";


class KAufgabeStatusgruppenEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, Funktionsgruppen, KontextTypen, DiasNotification, i18n) {
    super($q, $scope, DiasNotification);
    this.Funktionsgruppen = Funktionsgruppen;
    this.KontextTypen = KontextTypen;
    this.gettext = i18n.gettext;
  }

  _save() {
    const data = {
      statusgruppen: Object.keys(this.selectedStatusgruppen).map(
        key => this.selectedStatusgruppen[key]
      )
    };

    const promise = this.model.customPUT(data, "statusgruppen_bearbeiten").then(
      () => {
        const oldStatusgruppen = angular.copy(this.$scope.extra.statusgruppen);
        Object.keys(oldStatusgruppen).forEach(
          key => delete this.$scope.extra.statusgruppen[key]
        );
        Object.assign(this.$scope.extra.statusgruppen, this.selectedStatusgruppen);
        this.$scope.extra.workflowtypen.forEach(
          wft => {
            if (!oldStatusgruppen[wft.pk] && !this.selectedStatusgruppen[wft.pk]) {
              return;
            }
            wft.statusgruppen.forEach(
              wsg => {
                if (wsg.pk === oldStatusgruppen[wft.pk]
                    && wsg.pk !== this.selectedStatusgruppen[wft.pk]) {
                  wsg.aufgaben = wsg.aufgaben.filter(au => au.pk !== this.model.pk);
                } else if (wsg.pk !== oldStatusgruppen[wft.pk]
                           && wsg.pk === this.selectedStatusgruppen[wft.pk]) {
                  wsg.aufgaben.push(this.model.plain());
                }
              }
            );
          }
        );
        this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
      },
      errors => {
        return this.onErrors(errors);
      }
    );
    return promise;
  }

  onEnterEditMode() {
    super.onEnterEditMode();
    this.selectedStatusgruppen = angular.copy(this.$scope.extra.statusgruppen);
    this.orderedStatusgruppen = angular.copy(this.$scope.extra.orderedStatusgruppen);
    this.wftMap = angular.copy(this.$scope.extra.wftMap);
    this.wftChoices = this.$scope.extra.workflowtypen.filter(
      wft => Object.keys(this.selectedStatusgruppen).indexOf(wft.pk) === -1
    );
  }

  addWorkflowtyp(wftId) {
    const wftIdx = this.wftChoices.findIndex(wft => wft.pk === wftId);
    if (wftIdx === -1) {
      return;
    }
    this.orderedStatusgruppen.push(wftId);
    this.wftMap[wftId]._added = this.$scope.extra.orderedStatusgruppen.indexOf(wftId) === -1;
    this.wftChoices.splice(wftIdx, 1);
    this.selectedWft = null;
  }

  removeWorkflowtyp(wftId) {
    const wftIdx = this.orderedStatusgruppen.indexOf(wftId);
    if (wftIdx === -1) {
      return;
    }
    this.wftChoices.push(this.wftMap[wftId]);
    this.wftChoices = this.wftChoices.sort((a, b) => a.wft_bez.localeCompare(b.wft_bez));
    delete this.selectedStatusgruppen[wftId];
    this.orderedStatusgruppen.splice(wftIdx, 1);
  }
}

export default KAufgabeStatusgruppenEditController;
