"use strict";

import template from "./config.jade";
import {
  initModelFromList,
  toFormElementFromParameter,
} from "../../../../vue/client/vue/global/functions/mappingForParameterToFormElement";

class FoerderangebotRegelConfigController {
  /*@ngInject*/
  constructor(ParameterUtils) {
    this.name = "FoerderangebotRegelConfigController";
    this.loading = {};
    this.doDelete = regel => this.onDelete()(regel);
    this.moveUp = regel => this.onMoveUp()(regel);
    this.moveDown = regel => this.onMoveDown()(regel);

    if (angular.isUndefined(this.regel.param_keys)) {
      this.regel.param_keys = ParameterUtils.get_ordered_keys(this.regel.far_argument);
    }
    this.listConfig = toFormElementFromParameter({
      obj: this.regel.far_argument,
      showLabel: true,
      staticOptionsForType: {
        dyn_tabelle_objektkatalog_data: {
          dependency: "spalten",
        },
        dyn_tabelle_spaltenauswahl_radio: {
          dependency: "spalten",
        },
      },
    }).list;
    this.modelConfig = initModelFromList({ list: this.listConfig });
  }

  changeOeffentlich() {
    this.loading.oeffentlich = true;
    const data = { far_oeffentlich: !this.regel.far_oeffentlich };
    this.regel.customPUT(data, "oeffentlich_aendern").then(
      response => {
        const data = { far_oeffentlich: response.far_oeffentlich };
        this.regel = Object.assign({}, this.regel, data);
        this.loading.oeffentlich = false;
      }
    );
  }
}

export default {
  template: template(),
  controller: FoerderangebotRegelConfigController,
  controllerAs: "vm",
  bindings: {
    regel: "=",
    editable: "=",
    idx: "@",
    onDelete: "&",
    onMoveUp: "&",
    onMoveDown: "&",
  }
};
