import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragWarningBoxAntragstellerorgKopfdublette",
  components: {
    AsideRightBox,
    PuxTranslate,
  },
  props: {
    antrag: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optionsAsideRightBoxWarning: {
        icon: "warning",
        toggle: false,
      },
    };
  },
  computed: {
    extraForDubletteTranslate() {
      return {
        ast_nr: this.kopfdubletteAstNummer,
      };
    },

    antragstellerorgKopfdublette() {
      return get(this.antrag, "antragstellerorg_obj.kopfdublette");
    },

    kopfdubletteAstNummer() {
      return get(this.antrag, "antragstellerorg_obj.kopfdublette_obj.ast_nr");
    },
  },
};
