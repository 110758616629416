import { createNamespacedHelpers } from "vuex";
const {
  mapState,
  mapMutations
} = createNamespacedHelpers("dashboard");

// @vue/component
export default {
  computed: {
    ...mapState([
      "DASHBOARD_OPEN_INFO",
    ]),
  },
  methods: {
    ...mapMutations([
      "SET_DASHBOARD_OPEN_INFO",
    ]),
  },
};
