"use strict";

import template from "./aufgabe.box.jade";

import aufgabeZuweisenTemplate from "./aufgabe.zuweisen.modal.jade";
import aufgabeZuweisenController from "./aufgabe.zuweisen.modal.controller";


class AufgabeBoxController {
  /*@ngInject*/
  constructor(
    $state,
    Nutzeraufgabe,
    DiasNotification,
    diasModalDialog,
    diasConfirmDialog,
    AuthService
  ) {
    this.$state = $state;
    this.DiasNotification = DiasNotification;
    this.diasConfirmDialog = diasConfirmDialog;
    this.diasModalDialog = diasModalDialog;
    this.Nutzeraufgabe = Nutzeraufgabe;
    this.AuthService = AuthService;
    AuthService.getUser().then(user => this.user = user);
    this.empfaenger = this.aufgabe ? [this.aufgabe.nutzer] : [];
    this.canSeeDetail = AuthService.syncHasPerm("workflow.list.column.status_detail.view");
    this.showKorrekturModal = false;
    this.korrektur = {
      url: this.bezugObj.all("aufgaben").getRestangularUrl(),
      allowed: AuthService.syncHasPerm("workflow.korrektur"),
      showModal: false,
      reload: () => $state.reload()
    };
  }

  canEditTermin() {
    return this.AuthService.syncHasPerm("aufgabe.termin_aendern", this.bezugObj.user_permissions);
  }

  refreshAufgabe(aufgabe, response) {
    aufgabe.nutzer = response.nutzer;
    this.empfaenger = [response.nutzer];
    if (this.onAngenommen) {
      this.onAngenommen()(response);
    }
  }

  acceptAufgabe(aufgabe) {
    if (angular.isUndefined(aufgabe)) {
      return;
    }
    this.diasConfirmDialog({
      title: "Aufgabe annehmen",
      content: "Wollen Sie die Aufgabe \"" + aufgabe.kbez + "\" annehmen?",
      okCallback: () => {
        return this.Nutzeraufgabe.one(aufgabe.pk).customPOST({}, "annehmen").then(
          response => {
            this.DiasNotification.page.success("Aufgabe angenommen.");
            this.refreshAufgabe(aufgabe, response);
          },
          () => this.DiasNotification.page.error("Fehler beim Annehmen der Aufgabe.")
        );
      }
    });
  }

  assignAufgabe(aufgabe) {
    if (angular.isUndefined(aufgabe)) {
      return;
    }
    return this.diasModalDialog({
      title: "Aufgabe zuweisen",
      template: aufgabeZuweisenTemplate,
      controller: aufgabeZuweisenController,
      extras: {
        reloadPage: response => this.refreshAufgabe(aufgabe, response),
        aufgabe: aufgabe
      },
    });
  }
}

export default {
  template: template(),
  controller: AufgabeBoxController,
  controllerAs: "vm",
  bindings: {
    aufgabe: "<",
    bezugName: "<",
    bezugObj: "<",
    onAngenommen: "&?"
  },
};
