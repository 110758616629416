"use strict";

const UUIDs = {
  ANGELEGT: "524665d8-ca55-4bc2-ab70-f1f85100b508",
  AKTIV: "8a2e1f7b-9f0d-4302-acc4-04e4c97ff9ba",
  GESPERRT: "4cb597bd-3939-474b-9076-ad74a7909405",
  PASSWORT_ABGELAUFEN: "2d4d03f5-6c2e-4ebe-b612-bdc544582739",
  ANONYMISIERT: "c5169937-e223-44a3-a1e6-28414220c44d",
  isActive: status => {
    return status === UUIDs.AKTIV || status === UUIDs.PASSWORT_ABGELAUFEN;
  }
};

export default UUIDs;
