"use strict";


class DurchfuehrendeorgeinheitEditController {
  /*@ngInject*/
  constructor(
    $q, 
    $scope, 
    Budget, 
    Katalog, 
    DiasNotification,
    Antragstellerorganisationen
  ) {
    this.$q = $q;
    this.$scope = $scope;
    this.DiasNotification = DiasNotification;
    this.Antragstellerorganisationen = Antragstellerorganisationen;

    this.ast = $scope.extras.ast;
    this.sv = {};

    Budget.getList().then(
      response => this.budgetChoices = response
    );
    Katalog.getKatalog("regelsatz").then(
      response => this.regelsatzChoices = response
    );

    const year = new Date().getFullYear();
    this.haushaltsjahrChoices = [];
    for (let i = year + 2; i > year - 2; i--) {
      this.haushaltsjahrChoices.push(i);
    }
    this.save = this.save.bind(this);
  }

  save() {
    return this.ast.sammelvertraege.post(this.sv).then(
      response => {
        this.DiasNotification.page.success(
          "Weiterleitungsvertrag erfolgreich erstellt."
        );
        return response;
      },
      error => {
        const errorsLabel = {
          budget: "Budget: ",
          regelsatz: "Förderangebot: ",
          sv_budget: "Zugewiesener Betrag: ",
          haushaltsjahr: "Haushaltsjahr: ",
        };

        this.DiasNotification.form.clear();
        this.DiasNotification.form.error(error.data, "Ihre Daten sind fehlerhaft oder unvollständig.", {}, errorsLabel);
        this.errors = error.data;
        return this.$q.reject(error);
      }
    );
  }

  updateAntraege() {
    this.ast.sammelvertragVerknuepfbareAntraege(this.sv).then(
      result => this.verknuepfbareAntraege = result
    );
  }
}

export default DurchfuehrendeorgeinheitEditController;
