// Modul zur Erfassung von Kosten mit Pauschale
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "dias/kosten_finanzierung/kosten.template.jade";
import FoerderantragModels from "dias/foerderantrag/models";
import Parameters from "./parameter";

import builder from "dias/standard_foerdermodule/utils/builder";


const kosten_mit_pauschale = angular.module("dias.kosten_finanzierung.modul.signal_kosten", [
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
  Parameters.name,
]);

kosten_mit_pauschale.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("signal_kosten", "modul-signal-kosten");
})

  .directive("modulSignalKosten", builder.directive(template, controller));

export default kosten_mit_pauschale;
