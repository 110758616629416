import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

// @vue/component
export default {
  name: "NutzerModalAnonymisieren",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      status: {
        loading: undefined,
      },
    };
  },
  methods: {
    anonymisierenNutzer() {
      this.status.loading = true;
      this.postHttp({ url: `nutzer/${ this.nutzer.pk }/anonymisieren/` }).then(
        () => {
          this.addNotification({ text: "_MSG_NUTZER_MODAL_ANONYMISIEREN_SUCCESS_" });
          this.close({ status: true });
        },
        () => this.addNotification({ text: "_MSG_NUTZER_MODAL_ANONYMISIEREN_ERROR_", type: "error" })
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
