"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import "restangular";

import errors from "dias/core/errors";
import notification from "dias/core/notification";

import ServiceLog from "./ServiceLog.service";

const modelsModule = angular.module("core.log.models", [
  "restangular",
  uiRouter,
  errors.name,
  notification.name
])

  .provider("ServiceLog", ServiceLog)
;

export default modelsModule;
