import PuxChartBar from "./PuxChartBar/PuxChartBar.vue";

import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "PuxChart",
  components: {
    PuxChartBar,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    relevantList: {
      type: Array,
      required: true,
    },
    isLink: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    idLocal() {
      return `${ this.id }_chart`;
    },

    blocksWidth() {
      const BLOCKS_WIDTH = [];
      if (this.maxValue) {
        forEach(this.relevantList, block => {
          BLOCKS_WIDTH.push(block.value / this.maxValue * 100);
        });
      }
      return BLOCKS_WIDTH;
    },

    maxValue() {
      let maxValue = 0;
      forEach(this.relevantList, block => {
        if (maxValue < block.value) {
          maxValue = block.value;
        }
      });
      return maxValue;
    },
  },
};
