"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import sendComponent from "./send.component";
import models from "./models";
import services from "./services";

const benachrichtigungenModule = angular.module("auth.benachrichtigungen", [
  uiRouter,
  models.name,
  services.name,
  diasTable.name,
  diasNotification.name
]);

benachrichtigungenModule.config($stateProvider => {
  $stateProvider

    // Nachrichten-Liste
    .state("root.benachrichtigungen", {
      url: `/benachrichtigungen/?
            user_cookie&
            content&
            ben_gelesen&
            ben_weitergeleitet&
            autor&
            a_nr
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-nachrichten-inbox-list></vue-nachrichten-inbox-list></div>"
        }
      },
      data: {
        permissions: ["benachrichtigungen.view"]
      }
    })
    .state("root.benachrichtigungen_out", {
      url: `/postausgang/?
            user_cookie&
            content&
            ben_gelesen&
            ben_weitergeleitet&
            autor&
            a_nr
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-nachrichten-outbox-list></vue-nachrichten-outbox-list></div>"
        }
      },
      data: {
        permissions: ["benachrichtigungen.view"]
      }
    })
    // Detail-Seite einer Nachricht
    .state("root.benachrichtigungen.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><vue-nachrichten-details base-url='inbox/' list-url='root.benachrichtigungen'></vue-nachrichten-details></div>"
        }
      },
    })
    // Detail-Seite einer Nachricht
    .state("root.benachrichtigungen_out.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><vue-nachrichten-details base-url='outbox/' list-url='root.benachrichtigungen_out'></vue-nachrichten-details></div>"
        }
      },
    })
  ;
})

  .component("nachrichtenlink", sendComponent)
;
export default benachrichtigungenModule;
