// @vue/component
export default {
  data() {
    return {
      fields: {
        stellenbezeichnung: {
          id: "stellenbezeichnung",
          type: "text",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_STELLENBEZEICHNUNG_",
          required: true,
          view: "v-alt",
          translate: true,
        },
        anzeige_personalstelle: {
          id: "anzeige_personalstelle",
          type: "select",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_ART_DER_PERSONALSTELLE_",
          required: false,
          data: this.personalstellenart,
          keyId: "pk",
          keyLabel: "bez",
          view: "v-alt",
          hideIf: !this.personalstellenart.length,
          translate: true,
        },
        vollzeitstelle: {
          id: "vollzeitstelle",
          type: "float",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_VOLLZEITSTELLE_",
          required: true,
          addonBack: "Stunden pro Woche",
          view: "v-alt",
          helpText: undefined,
          translate: true,
          maxValue: 50,
          statusChangePeriod: true,
        },
        stellenumfang: {
          id: "stellenumfang",
          type: "float",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_STELLENUMFANG_",
          required: true,
          addonBack: "Stunden pro Woche",
          view: "v-alt",
          translate: true,
          maxValue: 50,
          statusChangePeriod: true,
        },
        jahresbrutto: {
          id: "jahresbrutto",
          type: "currency",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_JAHRESBRUTTO_",
          required: true,
          disabled: true,
          view: "v-alt",
          translate: true,
          statusChangePeriod: true,
        },
        ag_anteil_altersvorsorge: {
          id: "ag_anteil_altersvorsorge",
          type: "float",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_AG_ANTEIL_ALTERVORSORGE_",
          required: false,
          // view: "v-alt",
          suffix: "%",
          helpText: undefined,
          translate: true,
          nullValue: 0,
          statusInputByBlur: true,
          statusChangePeriod: true,
        },
        an_brutto_monat: {
          id: "an_brutto_monat",
          type: "currency",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_AN_BRUTTO_MONAT_",
          required: true,
          helpText: undefined,
          translate: true,
          statusChangePeriod: true,
        },
        bestandspersonal: {
          id: "bestandspersonal",
          type: "boolean",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_BESTANDSPERSONAL_",
          required: true,
          view: "v-alt",
          translate: true,
          trueLabel: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_BESTANDSPERSONAL_TRUE_",
          falseLabel: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_BESTANDSPERSONAL_FALSE_",
          trueValue: false,
          falseValue: true,
        },
        einmalzahlung: {
          id: "einmalzahlung",
          type: "currency",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_EINMALZAHLUNG_",
          required: false,
          helpText: undefined,
          translate: true,
          statusChangePeriod: true,
        },
        finanzierung_bestandspersonal: {
          id: "finanzierung_bestandspersonal",
          type: "select",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_FINANZIERUNG_BESTANDPERSONAL_",
          required: true,
          keyId: "value",
          keyLabel: "label",
          translateLabel: true,
          data: [
            {
              value: "keine",
              label: "Angabe nicht erforderlich - Stellenaufstockung um mind. 25% (Nachweis der Aufstockung bei Mittelabruf erforderlich)",
            },
            {
              value: "eigen",
              label: "Eigenmittel (Nachweis der Stellennachbesetzung bei Mittelabruf erforderlich)",
            },
            {
              value: "regel",
              label: "Leistungen der öffentlichen Hand (Nachweis der Stellennachbesetzung bei Mittelabruf erforderlich)",
            },
            {
              value: "rahmen",
              label: "Rahmenvereinbarung, Vergütungsregelung o.Ä. mit z.B. Pflegekasse (Nachweis der Stellennachbesetzung bei Mittelabruf erforderlich)",
            },
            {
              value: "befristet",
              label: "Befristete, ausgelaufene Projektmittel (Nachweis über Befristung bei Mittelabruf erforderlich)",
            },
          ],
          view: "v-alt",
          translate: true,
        },
        tarifvertrag: {
          id: "tarifvertrag",
          type: "text",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_TARIFVERTRAG_",
          required: true,
          view: "v-alt",
          translate: true,
        },
        vorherige_taetigkeit: {
          id: "vorherige_taetigkeit",
          type: "text",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_VORHERIGE_TAETIGKEIT_",
          required: true,
          view: "v-alt",
          translate: true,
        },
        arbeitsgeberpauschale: {
          id: "arbeitsgeberpauschale",
          type: "float",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_ARBEITGEBERPAUSCHALE_",
          required: true,
          suffix: "%",
          disabled: true,
          // view: "v-alt",
          translate: true,
        },
      },
    };
  },
};
