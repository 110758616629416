"use strict";

import BaseController from "./base.controller";
import cloakTemplate from "./cloak.jade";

export default () => {
  return {
    restrict: "A",
    transclude: true,
    template: cloakTemplate,
    controller: BaseController,
    scope: {
      loading: "=diasCloak",
      msg: "=cloakMsg",
      size: "=cloakSize",
      align: "=cloakAlign",
      left: "=cloakLeft",
      onBlock: "&",
      onUnblock: "&",
    },
  };
};
