import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "StandardVorhabenfrage",
  components: {
    SnapshotModule,
    SnapshotIcon,
  },
  mixins: [
    ModuleMixin,
  ],
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.list = [
        {
          id: "antwort",
          type: "richtext",
          htmlId: `${ this.htmlRegelNummer }_antwort`,
          label: "_LBL_GR_VORHABENFRAGE_ANTWORT_",
          translate: true,
          required: this.modelParameter.pflichtfeld,
          view: "v-alt",
          editonly: true,
          max: this.modelParameter.max_antwort,
          min: this.modelParameter.min_antwort,
          startupFocus: true,
        },
      ];
      return OPTIONS;
    },

    statusDiff() {
      if (!this.snapshotModule) {
        return false;
      }
      return this.snapshotModule.antwort !== this.model.antwort;
    },
  },
  methods: {
    prepareModel() {
      this.model = cloneDeep(this.module.data) || {};
    },
  },
};
