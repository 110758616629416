"use strict";

import "restangular";
import angular from "angular";
import uiRouter from "angular-ui-router";

import models from "./models";
import registrationPageModule from "./registration-page";
import dashboardModule from "./dashboard";
import foerderorganisationModule from "./foerderorganisation";
import foerderfinderangeboteModule from "./foerderfinderangebote";
import foerderorganisationKennenlernenModule from "./wiederbeschreibbare_module/foerderorganisation.kennenlernen";

const infoModule = angular.module("info", [
  uiRouter,
  models.name,
  registrationPageModule.name,
  dashboardModule.name,
  foerderorganisationModule.name,
  foerderfinderangeboteModule.name,
  foerderorganisationKennenlernenModule.name
]);

export default infoModule;
