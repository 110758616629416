import {
  forEach,
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  data() {
    return {
      objects: [
        {
          value: "antrag",
          label: "_TXT_TRACKING_ANTRAG_",
          url: "foerderantraege/",
          numberKey: "a_nr",
          fields: [
            "pk",
            "a_nr",
          ],
          labelNumber: "_LBL_TRACKING_ANTRAG_NUMBER_",
          h2: "_TXT_TRACKING_ANTRAG_H2_",
          h4: "_TXT_TRACKING_ANTRAG_VERFOLGEN_",
          state: "root.foerderantrag.details",
        },
        {
          value: "vertrag",
          label: "_TXT_TRACKING_VERTRAG_",
          url: "vertraege/",
          numberKey: "v_nr",
          fields: [
            "pk",
            "v_nr",
          ],
          labelNumber: "_LBL_TRACKING_VERTRAG_NUMBER_",
          h2: "_TXT_TRACKING_VERTRAG_H2_",
          h4: "_TXT_TRACKING_VERTRAG_VERFOLGEN_",
          state: "root.vertrag.details",
        },
        {
          value: "auszahlung",
          label: "_TXT_TRACKING_AUSZAHLUNG_",
          url: "auszahlungen/",
          numberKey: "az_nummer",
          fields: [
            "pk",
            "az_nummer",
            "name",
            "vertrag",
          ],
          labelNumber: "_LBL_TRACKING_AUSZAHLUNG_NUMBER_",
          h2: {
            abschlag: "_TXT_TRACKING_ABSCHLAG_H2_",
            auszahlung: "_TXT_TRACKING_AUSZAHLUNG_H2_",
            endabrechnung: "_TXT_TRACKING_ENDABRECHNUNG_H2_",
          },
          h4: {
            abschlag: "_TXT_TRACKING_ABSCHLAG_VERFOLGEN_",
            auszahlung: "_TXT_TRACKING_AUSZAHLUNG_VERFOLGEN_",
            endabrechnung: "_TXT_TRACKING_ENDABRECHNUNG_H2_",
          },
          state: "root.vertrag.details.auszahlung",
        },
      ],
    };
  },
  computed: {
    objectsByValue() {
      return keyBy(this.objects, "value");
    },
  },
};

// TODO: Wenn server fertig wird, können wir "POSSIBLE_OBJECTS" erweitern
// const POSSIBLE_OBJECTS = ["antrag", "vertrag", "auszahlung"];
export const POSSIBLE_OBJECTS = ["antrag"];

export function validateObjects(keysForObjects) {
  let isValide = true;
  forEach(keysForObjects, key => {
    if (POSSIBLE_OBJECTS.indexOf(key) === -1) {
      isValide = false;
      return false;
    }
  });
  if (!isValide) {
    console.error(`!!!TRACKING: Falsche Konfiguration. Sie benutzen [${ keysForObjects.join(",") }], aber Sie sollen die Elemente aus [${ POSSIBLE_OBJECTS.join(",") }] benutzen`);
  }
  return isValide;
}
