"use strict";

import template from "./buchungen.table.jade";


class BuchungenTableController {
  /*@ngInject*/
  constructor($q, DiasNotification) {
    this.$q = $q;
    this.DiasNotification = DiasNotification;
    this.typeFilter = {
      antrag_nr: "spaced:undefined:true",
      currency: "currency",
      date: "date:'shortDate'"
    };
    this.showBuchungen();
    this.csvUrl = this.obj.getRestangularUrl();
    this.csvParams = {};
  }


  showBuchungen() {
    this.loadingBuchungen = true;
    this.obj.customGET("buchungen").then(
      response => {
        this.buchungenData = response;
        if (this.buchungenData) {
          this.prepareBuchungenTable();
          this.showBuchungenTable();
        } else {
          this.loadingBuchungen = false;
        }
      }
    );
  }

  prepareBuchungenTable() {
    this.columnDefs = [];
    angular.forEach(this.buchungenData.schema, item => {
      const DEF = {
        label: item.label,
        field: item.key
      };
      if (item.type === "antrag_nr") {
        DEF.cellTemplate = `<span style="white-space:nowrap;"><spaced ng-model=row['${ item.key }']></span>`;
      } else {
        DEF.cellFilter = this.typeFilter[item.type];
        DEF.cellClass = "";
      }
      this.columnDefs.push(DEF);
    });
  }

  showBuchungenTable() {
    const buchungenTableDeferred = this.$q.defer();
    this.buchungenTable = buchungenTableDeferred.promise;
    this.loadingBuchungen = false;

    buchungenTableDeferred.resolve({
      dataSource: () => this.buchungenData.data,
      columnDefs: this.columnDefs,
      actions: []
    });
  }

  refreshData() {
    this.showBuchungen();
  }
}


export default {
  template: template(),
  controller: BuchungenTableController,
  controllerAs: "vm",
  bindings: {
    obj: "<"
  }
};
