import {
  FilterCurrencyMixin,
} from "../../../../../global/mixins/FiltersMixin";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  mixins: [
    FilterCurrencyMixin,
  ],
  computed: {
    textFreigegebenOrZurueckgefordert() {
      return this.betrag >= 0 ?
        "_LBL_AUSZAHLUNG_DETAILS_AUSZAHLUNG_BETRAG_FREIGEGEBEN_" :
        "_LBL_AUSZAHLUNG_DETAILS_AUSZAHLUNG_BETRAG_ZURUECKGEFORDERT_";
    },

    betragClass() {
      return this.betrag > 0 ? "text-color-success" : "text-color-remove";
    },

    betragCurrency() {
      return this.filterCurrency(this.betrag);
    },

    textAusstehendOrRueckforderung() {
      return this.auszahlungDifferenz >= 0 ?
        "_LBL_AUSZAHLUNG_DETAILS_AUSZAHLUNG_BETRAG_AUSSTEHEND_" :
        "_LBL_AUSZAHLUNG_DETAILS_AUSZAHLUNG_BETRAG_AUSSTEHEND_RUECKFORDERUNG_";
    },

    auszahlungDifferenzClass() {
      return this.auszahlungDifferenz > 0 ? "text-color-success" : "text-color-remove";
    },

    auszahlungDifferenz() {
      return get(this.auszahlung, "uebersicht.auszahlungsdifferenz", 0) || 0;
    },

    auszahlungDifferenzCurrency() {
      return this.filterCurrency(Math.abs(this.auszahlungDifferenz));
    },
  },
};
