// Modul zur Erfassung von einfachen Finanzierungs-Positionen
"use strict";

import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";
import VertragModels from "dias/vertrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const deckungsluecke_finanzierung = angular.module("dias.kosten_finanzierung.deckungsluecke_finanzierung", [
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
  VertragModels.name,
]);

deckungsluecke_finanzierung.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("deckungsluecke_finanzierung", "modul-deckungsluecke-finanzierung");
})

  .directive("modulDeckungslueckeFinanzierung", builder.directive(template, controller));

export default deckungsluecke_finanzierung;
