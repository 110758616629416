import template from "./details.jade";
import BaseController from "dias/formular/cortex/basisformular/details.controller.js";
import { findIndex } from "lodash";

import SaveCallbackActions from "../../../../../vue/client/vue/const/SaveCallbackActions";

import {
  MAPPING,
} from "../../../../../vue/client/vue/components/Geschaeftsregel/Module/Module";
import {
  EventBus,
} from "../../../../../vue/client/vue/global/functions/event-bus";

import { get } from "lodash-es";


class AntragslistenDetailController extends BaseController {
  /*@ngInject*/
  constructor(
    $q,
    $filter,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $anchorScroll,
    $window,
    i18n,
    diasConfirmDialog,
    diasModalDialog,
    AuthService,
    DiasNotification,
    Formular,
    Foerderantrag,
    Vertrag,
    Antragstellerorganisationen,
    Sitzungen,
    Antragslisten,
  ) {
    super(
      $q,
      $filter,
      $state,
      $stateParams,
      $timeout,
      $anchorScroll,
      $window,
      i18n,
      diasConfirmDialog,
      diasModalDialog,
      AuthService,
      DiasNotification,
      Formular,
      Foerderantrag,
      Vertrag,
      Antragstellerorganisationen,
      Sitzungen,
    );
    this.$q = $q;
    this.MAPPING = MAPPING;
    this.loading.rubriken = true;
    this.gettext = i18n.gettext;
    this.Antragslisten = Antragslisten;

    this.notizen = {};
    this.stepMap = [
      "uebersicht",
      "formular"
    ];
    this.steps = [
      {
        key: "uebersicht",
        kbez: "Allgemeine Informationen"
      },
      {
        key: "formular",
        kbez: "Daten der Antragsliste"
      },
    ];
    this.updateAntragslisteModuleFromVue = this.updateAntragslisteModuleFromVue.bind(this);
    this.kontextName = this.gettext("Antragsliste");
    this.initRubriken().finally(
      () => this.loading.rubriken = false
    );
    this.lastWfExecution = new Date().toISOString();
    this.updateWorkflowfolgen = () => this.lastWfExecution = new Date().toISOString();
    this.updateModuleFromVue = this.updateModuleFromVue.bind(this);
    this.onWorkflowSuccess = this.onWorkflowSuccess.bind(this);
    this.saveCallback = this.saveCallback.bind(this);
    this.initEventBuses();
    $scope.$on("$destroy", () => this.destroyEventBuses());
  }


  initEventBuses() {
    EventBus.$on("objektliste.update", this.loadFormular.bind(this));
  }

  destroyEventBuses() {
    EventBus.$off("objektliste.update", this.loadFormular.bind(this));
  }

  saveCallback({ statusSaveCallback } = {}) {
    if (statusSaveCallback === SaveCallbackActions.RELOAD_WF) {
      this.updateWorkflowfolgen();
    } else if (statusSaveCallback === SaveCallbackActions.RELOAD_ALL) {
      this.loadFormular();
      this.updateWorkflowfolgen();
    }
  }

  loadFormular() {
    const defer = this.$q.defer();
    const getData = () => {
      if (!this.Antragslisten) {
        setTimeout(getData, 50);
      }
      this.Antragslisten.one(this.$stateParams.id).get().then(
        response => {
          if (this.formular && !this.formular.for_formulardatum && response.for_formulardatum) {
            this.eingereichtStatus = true;
          }
          this.antragsliste = response;
          this.formular = response.plain();
          this.Formular.restangularizeElement(this.formular);
          this.updatePermissions();
          this.setUrl();
          defer.resolve(response);
        },
        error => {
          this.DiasNotification.page.error(error, "Fehler beim Laden der Antragsliste");
          defer.reject(error);
        }
      );
    };
    setTimeout(getData);
    return defer.promise;
  }

  updateAntragslisteModuleFromVue({ response }) {
    this.$timeout(() => {
      const INDEX = findIndex(this.module, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.module.splice(INDEX, 1, response);
      }
    });
  }

  groupModuleByRubrik(modules) {
    if (!modules || modules.length === 0) {
      return [];
    }
    const grouped = [];
    angular.forEach(modules, mod => {
      if (grouped[mod.regel.rubrik] === undefined) {
        grouped[mod.regel.rubrik] = [];
      }
      grouped[mod.regel.rubrik].push(mod);
    });
    return grouped;
  }

  initFixedRubriken() {
    this.stepMap.uebersicht = {
      kbez: this.gettext("Allgemeine Informationen"),
      bez: this.gettext("Allgemeine Informationen"),
      icon: "glyphicon-step_ubersicht",
      key: "uebersicht",
      template: "uebersicht",
      priority: 10,
      // number: 1,
      open: false
    };
    this.steps.push(this.stepMap.uebersicht);
  }

  initRubriken() {
    return this.Formular.one(this.$stateParams.id).rubriken.getList().then(
      response => {
        this.initFixedRubriken();
        this.anzeigekontextrubriken = response.plain();
        this.loading.module = true;
        this.Formular.one(this.$stateParams.id).module.getList().then(
          response => {
            this.module = response;
            let counter = this.steps.length + 1;
            this.grouped_module = this.groupModuleByRubrik(this.module);
            angular.forEach(this.anzeigekontextrubriken, axr => {
              if (this.grouped_module[axr.rubrik]) {
                this.stepMap[axr.axr_config.key] = {
                  kbez: axr.axr_config.label,
                  bez: axr.axr_config.label,
                  key: axr.axr_config.key,
                  rubrik: axr.rubrik,
                  template: "module",
                  icon: axr.axr_config.icon,
                  priority: counter * 10,
                  // number: counter
                  open: false
                };
                this.steps.push(this.stepMap[axr.axr_config.key]);
                counter ++;
              }
            });
          }
        ).finally(() => this.loading.module = false);
      }
    );
  }

  updateModuleFromVue({ group, response }) {
    this.$timeout(() => {
      if (group) {
        const INDEX = findIndex(this.grouped_module[group], ["regel.pk", response.regel.pk]);
        if (INDEX !== -1) {
          this.grouped_module[group].splice(INDEX, 1, response);
        }
      } else {
        const INDEX = findIndex(this.module[group], ["regel.pk", response.regel.pk]);
        if (INDEX !== -1) {
          this.module[group].splice(INDEX, 1, response);
        }
      }
    });
  }

  onWorkflowSuccess(aufgabe) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerSuccess(aufgabe);
      }
      this.updateAufgabeFromVue(aufgabe);
      this.resetValidation();
      this.scrollTop();
      if (get(this.currentWorkflowfolge, "wfo_reload")) {
        this.loading.module = true;
        const promise = this.$q.all([
          this.loadFormular(),
          this.initRubriken(),
        ]);
        promise.finally(() => {
          this.loading.module = false;
        });
        return promise;
      }
    });
  }
}

export default {
  template: template(),
  controller: AntragslistenDetailController,
  controllerAs: "vm"
};
