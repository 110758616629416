import KFPosition from "../KFPosition/KFPosition.vue";
import KFPositionForm from "../KFPositionForm/KFPositionForm.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import Alert from "../../../../global/components/Alert/Alert.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";
import translate from "../../../../global/directives/translate";
import loading from "../../../../global/directives/loading";
import {
  FilterCurrencyMixin,
} from "../../../../global/mixins/FiltersMixin";
import {
  isUndefined,
  get,
  keyBy,
  isArray,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "KFStandard",
  components: {
    Alert,
    KFPosition,
    KFPositionForm,
    PuxButton,
    PuxIcon,
    PuxCloak,
    Modal,
  },
  directives: {
    translate,
    loading,
  },
  mixins: [
    HttpMixin,
    FilterCurrencyMixin,
    SyConfigMixin,
  ],
  props: {
    idx: {
      type: String,
      required: false,
      default: undefined,
    },
    antragKoFiArt: {
      type: Object,
      required: true,
    },
    htmlId: {
      type: String,
      required: true,
    },
    ariaDescribedbyActual: {
      type: String,
      required: true,
    },
    ariaDescribedbyVersion: {
      type: String,
      required: true,
    },
    regel: {
      type: Object,
      required: true,
    },
    infoprovider: {
      type: Object,
      required: false,
      default: undefined,
    },
    positionen: {
      type: Array,
      required: true,
    },
    urlPositionen: {
      type: String,
      required: true,
    },
    betragsrechner: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    reloading: {
      type: Boolean,
      required: false,
    },
    sum: {
      type: String,
      required: false,
      default: undefined,
    },
    statusHideButtonAdd: {
      type: Boolean,
      required: false,
    },
    statusHideButtonDelete: {
      type: Boolean,
      required: false,
    },
    statusHideButtonPositionenToggle: {
      type: Boolean,
      required: false,
    },
    statusShowProzente: {
      type: Boolean,
      required: true,
    },
    statusPositionenNummerHide: {
      type: Boolean,
      required: false,
    },
    statusSummFromBertrag: {
      type: Boolean,
      required: false,
    },
    katalogDetails: {
      type: Array,
      required: true,
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
    },
    extraForVorschau: {
      type: Object,
      required: false,
      default: undefined,
    },
    typeKoFi: {
      type: String,
      required: true,
      validator: value => ["kosten", "finanzierung"].indexOf(value) !== -1,
    },
    kontext: {
      type: String,
      required: true,
    },
    prozent: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    labelsPosition: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    editType: {
      type: String,
      required: true,
    },
    currentSnapshot: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    diff: {
      type: Object,
      required: true,
    },
    statusKFDiff: {
      type: Boolean,
      required: true,
    },
    prepareModel: {
      type: Function,
      required: true,
    },
  },
  emits: [
    "updatePositionen",
    "updateValue",
  ],
  data() {
    return {
      statusInfo: false,
      statusInfoButton: false,
      statusHidePositionen: false,
      statusAddPosition: false,
      confirmShow: undefined,
      confirmOptions: undefined,
      selectorClose: undefined,
      refFocus: undefined,
      statusHideNumber: false,
    };
  },
  computed: {
    ariaDescribedbyForButtons() {
      return `${ this.positionNumberId } ${ this.positionNameId }`;
    },

    positionNumberId() {
      return `${ this.htmlId }_position_number`;
    },

    positionNameId() {
      return `${ this.htmlId }_position_name`;
    },

    buttonInfoId() {
      return `${ this.htmlId }_info_toogle`;
    },

    blockInfoId() {
      return `${ this.htmlId }_info_block`;
    },

    buttonInfoCloseId() {
      return `${ this.htmlId }_info_close`;
    },

    buttonAddId() {
      return `${ this.htmlId }_add`;
    },

    buttonTogglePositionId() {
      return `${ this.htmlId }_position_toggle`;
    },

    htmlIdAddPosition() {
      return `${ this.htmlId }_new_position`;
    },

    positionDetailsId() {
      return `${ this.htmlId }_position_details`;
    },

    buttonTogglePositionTranslateOptions() {
      return {
        text: this.buttonTogglePositionText,
        extra: {
          kofiart: this.labelMainLocal,
        },
      };
    },

    buttonTogglePositionText() {
      return this.statusHidePositionen ?
        this.buttonTogglePositionTextHide :
        this.buttonTogglePositionTextShow;
    },

    buttonTogglePositionTextHide() {
      if (this.labelsPosition.buttonTogglePositionTextHide) {
        return this.labelsPosition.buttonTogglePositionTextHide;
      }
      return "_BTN_POSITION_TOGGLE_HIDE_{{kofiart}}_";
    },

    buttonTogglePositionTextShow() {
      if (this.labelsPosition.buttonTogglePositionTextShow) {
        return this.labelsPosition.buttonTogglePositionTextShow;
      }
      return "_BTN_POSITION_TOGGLE_SHOW_{{kofiart}}_";
    },

    buttonTogglePositionIcon() {
      return this.statusHidePositionen ?
        "chevron-down" :
        "chevron-up";
    },

    buttonInfoTooltip() {
      return this.statusInfoButton ?
        "_TXT_FORMSTEP_DETAILS_INFO_I_TITLE_AUS_" :
        "_TXT_FORMSTEP_DETAILS_INFO_I_TITLE_EIN_";
    },

    buttonInfoExtra() {
      return {
        idx: this.idx,
        kofiart: this.labelMainLocal,
      };
    },

    statusShowButtonAddLocal() {
      return this.statusShowButtonAddLocalGeneral && !this.statusHideButtonAdd && !this.statusAddPosition && !this.readonly;
    },

    statusShowButtonAddNotPositionenLocal() {
      return this.statusShowButtonAddLocalGeneral && !this.statusAddPosition && !this.readonly;
    },

    statusShowButtonAddLocalGeneral() {
      if (this.statusNoPositionDetails) {
        return false;
      }
      if (this.statusSinglePosition) {
        const EINE_POSITION = get(this.extra, "modelParameter.eine_position");
        if (this.positionen.length && (isUndefined(EINE_POSITION) || EINE_POSITION)) {
          return false;
        }
      }
      return true;
    },

    eventsForPosition() {
      if (this.$attrs.onOpenEditPosition) {
        return {
          openEditPosition: this.openEditPositionLocal,
        };
      }
      return {};
    },

    labelMainLocal() {
      if (this.labelsPosition.labelMain) {
        return this.labelsPosition.labelMain;
      }
      return this.antragKoFiArt.bez;
    },

    defaultBez() {
      return this.statusNoPosition ? this.antragKoFiArt.bez : "";
    },

    buttonAddTextLocal() {
      if (this.labelsPosition.buttonAddText) {
        return this.labelsPosition.buttonAddText;
      }
      return "_BTN_ADD_POSITION_";
    },

    buttonAddNoPositionTextLocal() {
      if (this.labelsPosition.buttonAddNoPositionText) {
        return this.labelsPosition.buttonAddNoPositionText;
      }
      if (this.typeKoFi === "kosten") {
        return "_BTN_NO_POSITION_ADD_KOSTEN_";
      }
      if (this.typeKoFi === "finanzierung") {
        return "_BTN_NO_POSITION_ADD_FINANZIERUNG_";
      }
      return "";
    },

    buttonEditId() {
      return `${ this.htmlId }_edit`;
    },

    buttonEditTooltipLocal() {
      if (this.labelsPosition.buttonEditTooltip) {
        return this.labelsPosition.buttonEditTooltip;
      }
      return "_BTN_KF_POSITION_EDIT_TITLE_";
    },

    buttonDeleteId() {
      return `${ this.htmlId }_delete`;
    },

    buttonDeleteIdTooltipLocal() {
      if (this.labelsPosition.buttonDeleteTooltip) {
        return this.labelsPosition.buttonDeleteTooltip;
      }
      return "_BTN_KF_POSITION_DELETE_TITLE_";
    },

    statusNoPosition() {
      return this.editType === "no-position";
    },

    statusNoPositionDetails() {
      return this.editType === "no-position-details";
    },

    statusSinglePosition() {
      return this.editType === "single-position";
    },

    noPositionBez() {
      if (!this.statusNoPosition) {
        return;
      }
      return get(this.positionen, "[0].akp_bez");
    },

    statusNewForPositionForm() {
      return !(this.statusNoPosition && this.positionen.length);
    },

    urlSaveLocal() {
      if (this.statusNoPosition && this.positionen.length) {
        return `${ this.urlPositionen }${ this.positionen[0].pk }/`;
      }
      return this.urlPositionen;
    },

    modalDeletePositionHeader() {
      if (this.labelsPosition.confirmDeleteHeader) {
        return this.labelsPosition.confirmDeleteHeader;
      }
      if (this.typeKoFi === "kosten") {
        return "_TXT_KF_DELETE_CONFIRM_HEADER_KOSTEN_";
      }
      if (this.typeKoFi === "finanzierung") {
        return "_TXT_KF_DELETE_CONFIRM_HEADER_FINANZIERUNG_";
      }
      return "_TXT_KF_DELETE_CONFIRM_HEADER_";
    },

    keyModelDetail() {
      if (this.typeKoFi === "kosten") {
        return "kostenartdetail";
      }
      if (this.typeKoFi === "finanzierung") {
        return "finanzierungsartdetail";
      }
      return "";
    },

    mappingPositionenByKeyModelDetail() {
      return keyBy(this.positionen, this.keyModelDetail);
    },

    buttonAddIcon() {
      if (this.labelsPosition.buttonAddIcon) {
        return this.labelsPosition.buttonAddIcon;
      }
      return "plus";
    },

    snapshotSum() {
      return this.filterCurrency(this.currentSnapshot[this.prefixSnapshotSum] || 0);
    },

    prefixSnapshotSum() {
      if (this.typeKoFi === "kosten") {
        return "aka_summe";
      }
      if (this.typeKoFi === "finanzierung") {
        return "afa_summe";
      }
      return "";
    },

    snapshotPositionenObj() {
      return this.currentSnapshot.positionenObj || {};
    },
  },
  watch: {
    reloading() {
      if (!this.reloading) {
        this.setFocusAfterReloading();
      }
    },
  },
  created() {
    this.checkSyConfig();
  },
  methods: {
    checkSyConfig() {
      this.statusHideNumber = this.getSyConfigsValue("kofi_config", {}).nummern_ausblenden || false;
    },

    setFocusAfterReloading() {
      if (!this.refFocus) {
        return;
      }
      setTimeout(() => {
        if (isArray(this.refFocus)) {
          forEach(this.refFocus, focus => {
            if (this.setFocusAfterReloadingForCurrentRef(focus)) {
              return false;
            }
          });
        } else {
          this.setFocusAfterReloadingForCurrentRef(this.refFocus);
        }
        this.refFocus = undefined;
      });
    },

    setFocusAfterReloadingForCurrentRef(ref) {
      if (this.$refs[ref] && this.$refs[ref].$el) {
        this.$refs[ref].$el.focus();
        return true;
      }
      return false;
    },

    toggleStatusInfo() {
      if (this.statusInfoButton) {
        this.statusInfo = false;
        this.statusInfoButton = false;
      } else {
        this.statusInfo = true;
        setTimeout(() => {
          if (this.$refs.alert_info && this.$refs.alert_info.$el) {
            this.$refs.alert_info.$el.focus();
          }
          setTimeout(() => {
            this.statusInfoButton = true;
          });
        });
      }
    },

    closeInfoBlock() {
      this.statusInfo = false;
      setTimeout(() => {
        if (this.$refs.button_info && this.$refs.button_info.$el) {
          this.$refs.button_info.$el.focus();
        }
        this.statusInfoButton = false;
      });
    },

    togglePositionen() {
      this.statusHidePositionen = !this.statusHidePositionen;
      if (!this.statusHidePositionen) {
        setTimeout(() => {
          if (this.$refs.position_detail) {
            this.$refs.position_detail.focus();
          }
        });
      }
    },

    addPositionLocal() {
      if (this.$attrs.onAddPosition) {
        this.$attrs.onAddPosition();
        return;
      }
      this.statusAddPosition = true;
    },

    closeAddPosition({ statusCloseAfterSave } = {}) {
      this.statusAddPosition = false;
      if (!statusCloseAfterSave) {
        this.setFocusByCloseEditMode();
      }
    },

    setFocusByCloseEditMode() {
      setTimeout(() => {
        if (this.$refs.button_add && this.$refs.button_add.$el) { // button Position hinzufügen
          this.$refs.button_add.$el.focus();
        } else if (this.$refs.button_add_not_positionen && this.$refs.button_add_not_positionen.$el) { // button Position hinzufügen, wenn statusNoPosition
          this.$refs.button_add_not_positionen.$el.focus();
        } else if (this.$refs.button_edit_not_positionen && this.$refs.button_edit_not_positionen.$el) { // button Position akktualisieren, wenn statusNoPosition
          this.$refs.button_edit_not_positionen.$el.focus();
        } else { // default
          this.$el.focus();
        }
      });
    },

    saveLocal({ response, refFocus }) {
      this.statusAddPosition = false;
      this.$emit("updatePositionen", { response });
      this.$el.focus();
      if (refFocus) {
        this.refFocus = refFocus;
      } else {
        this.refFocus = `position_${ this.positionen.length }`;
      }
    },

    openEditPositionLocal(arg) {
      this.$attrs.onOpenEditPosition(arg);
    },

    updateValueLocal(arg) {
      this.$emit("updateValue", arg);
    },

    openModalDeletePosition() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: this.modalDeletePositionHeader,
        msg: "_HTML_KF_DELETE_CONFIRM_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: this.deletePosition,
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.confirmShow = true;
    },

    deletePosition() {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: `${ this.urlSaveLocal }entfernen/`,
      }).then(
        response => {
          this.closeAddPosition();
          this.closeConfirm();
          this.saveLocal({ response });
          this.refFocus = "button_delete_not_positionen";
        }
      ).then(
        () => {
          this.confirmOptions.loading = false;
        }
      );
    },

    closeConfirm() {
      this.confirmShow = false;
      setTimeout(() => {
        if (this.$refs.button_delete_not_positionen && this.$refs.button_delete_not_positionen.$el) {
          this.$refs.button_delete_not_positionen.$el.focus();
        }
      });
    },
  },
};
