import ModuleMixin from "../ModuleMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import Spinner from "../../../../global/components/Spinner/Spinner.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import {
  EventBus,
} from "../../../../global/functions/event-bus";
import {
  cloneDeep,
  forEach,
  findIndex,
  indexOf,
  sortBy,
  isFunction,
  size,
  groupBy,
  isNil,
  get,
  find,
} from "lodash-es";

// @vue/component
export default {
  name: "Dokumentenfrage",
  components: {
    Modal,
    Permission,
    PuxButton,
    Spinner,
    SnapshotIcon,
    SnapshotModule,
    PuxIcon,
  },
  mixins: [
    ModuleMixin,
    NotificationMixin,
  ],
  setup() {
    const {
      filterFileSize,
    } = FiltersAPI();

    return {
      filterFileSize,
    };
  },
  data() {
    return {
      confirmOptions: undefined,
      confirmShow: undefined,
      loadingDokumenteDelete: {},
      dokumenteInSync: [],
      timer: undefined,
      selectorCloseDelete: undefined,
      modelClone: {},
      currentSave: undefined,
      sheduledSave: undefined,
      addedList: [],
    };
  },
  computed: {
    labelHeader() {
      return this.modelParameter.frage;
    },

    getDokumentLinkTitle() {
      return dok => `${ dok.datei } (${ this.filterFileSize(dok.groesse) })`;
    },

    optionsUpload() {
      return {
        htmlId: `${ this.htmlRegelNummer }_${ this.group }_upload`,
        multiple: this.multipleDoks,
        drop: true,
        dropButton: true,
        dropButtonClass: "list-group-item list-group-item-action list-group-item-dark text-center",
        type: "document",
        saveUrl: `tempdok/`,
        allowedMimeType: this.modelParameter.allowed_mime_type,
        maximum: this.modelParameter.max_dateien,
        uploadBtnLabel: this.modelParameter.btn_label,
        showAllowedMimeType: this.modelParameter.btn_show_mimetype,
        disabled: this.isDisabledUpload,
      };
    },

    isDisabledUpload() {
      if (this.modelParameter.max_dateien > 0 && get(this.model, "dokumente", []).length >= this.modelParameter.max_dateien) {
        return true;
      }
      return undefined;
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        const MODEL_DOKS = get(this.model, "dokumente", []);
        const SNAPSHOT_DOKS = get(this.snapshotModule, "dokumente", []);
        forEach(MODEL_DOKS, item => {
          if (!find(SNAPSHOT_DOKS, ditem => ditem.pk === item.pk)) {
            DIFF.added = DIFF.added || {};
            DIFF.added[item.pk] = true;
          }
        });
        forEach(SNAPSHOT_DOKS, item => {
          if (!find(MODEL_DOKS, ditem => ditem.pk === item.pk)) {
            DIFF.removed = DIFF.removed || {};
            DIFF.removed[item.pk] = true;
          }
        });
      }
      return DIFF;
    },

    getDiffIcon() {
      const TYPES = {};
      if (this.diff &&
        this.diff.modified) {
        forEach(this.diff.modified, (val, key) => {
          TYPES[key] = "changes";
        });
      }
      if (this.diff &&
        this.diff.added) {
        forEach(this.diff.added, (val, key) => {
          TYPES[key] = "add";
        });
      }
      if (this.diff &&
        this.diff.removed) {
        forEach(this.diff.removed, (val, key) => {
          TYPES[key] = "delete";
        });
      }
      return TYPES;
    },

    statusDiff() {
      return !!(this.snapshotModuleDoks && size(this.diff));
    },

    buttonIdDelete() {
      return dok => `${ this.htmlRegelNummer }_${ this.group }_modal_confirm_delete_${ dok.pk }`;
    },

    snapshotModuleDoks() {
      if (this.snapshotModule && this.snapshotModule.dokumente && this.snapshotModule.dokumente.length) {
        const SNAP_DOKS = cloneDeep(this.snapshotModule);
        const GROUPS = groupBy(SNAP_DOKS.dokumente, dok => !!dok.readonly);
        SNAP_DOKS.readonlyDoks = sortBy(GROUPS.true || [], ["titel"]);
        SNAP_DOKS.dokumente = sortBy(GROUPS.false || [], ["titel"]);
        return SNAP_DOKS;
      }
      return this.snapshotModule;
    },

    modelSorted() {
      if (isNil(this.model)) {
        return {
          dokumente: [],
          readonlyDoks: [],
        };
      }
      const MODEL_SORTED = cloneDeep(this.model);
      MODEL_SORTED.dokumente = sortBy(get(MODEL_SORTED, "dokumente", []), ["titel"]);
      if (MODEL_SORTED.readonlyDoks) {
        MODEL_SORTED.readonlyDoks = sortBy(get(MODEL_SORTED, "readonlyDoks", []), ["titel"]);
      }
      return MODEL_SORTED;
    },

    anyDoksDeleted() {
      return findIndex(get(this.model, "dokumente", []), el => isNil(el.url)) > -1;
    },

    multipleDoks() {
      return this.modelParameter.max_dateien !== 1;
    },

    isModuleEditable() {
      return false;
    },
  },
  watch: {
    "module.data"() {
      this.prepareModel();
    },
  },
  created() {
    this.initEventBuses();
  },
  beforeUnmount() {
    EventBus.$off("changeDocument", this.onChangeDocument);
  },
  methods: {
    initEventBuses() {
      EventBus.$on("changeDocument", this.onChangeDocument);
    },

    onChangeDocument({ type, document, dokartPk, dokartPkAlt, regel } = {}) {
      if (regel === this.regel.regel) { // Event from this Regel
        return;
      }
      if (isFunction(this[`onChangeDocument_${ type }`])) {
        this[`onChangeDocument_${ type }`]({ document, dokartPk, dokartPkAlt });
      }
    },

    onChangeDocument_update({ document, dokartPk, dokartPkAlt }) {
      if (dokartPkAlt === this.modelParameter.dokart) {
        if (dokartPk !== this.modelParameter.dokart) { // change dokart
          this.deleteOrUpdateDocument({ document, type: "delete" });
        }
      }
      if (dokartPk !== this.modelParameter.dokart) {
        return;
      }
      this.deleteOrUpdateDocument({ document, type: "update" });
    },

    onChangeDocument_create({ document, dokartPk }) {
      if (dokartPk !== this.modelParameter.dokart ||
        !this.modelParameter.andere_quellen) {
        return;
      }
      let currentDocument = cloneDeep(document);
      if (currentDocument.dokument) {
        currentDocument = currentDocument.dokument;
      }
      currentDocument.readonly = true;
      this.model.readonlyDoks.push(currentDocument);
    },

    onChangeDocument_delete({ document, dokartPk }) {
      if (dokartPk !== this.modelParameter.dokart) {
        return;
      }
      this.deleteOrUpdateDocument({ document, type: "delete" });
    },

    deleteOrUpdateDocument({ document, type }) {
      const DOC_PK = document.dokument ? document.dokument.pk : document.pk;
      const DOC_TITEL = document.dokument ? document.dokument.dok_titel : document.titel;
      const INDEX_IN_DOKUMENTE = findIndex(this.model.dokumente, ["pk", DOC_PK]);
      if (INDEX_IN_DOKUMENTE !== -1) {
        const CURRENT_DOCUMENT = cloneDeep(this.model.dokumente[INDEX_IN_DOKUMENTE]);
        if (type === "delete") {
          CURRENT_DOCUMENT.url = undefined;
        } else {
          CURRENT_DOCUMENT.titel = DOC_TITEL;
        }
        this.model.dokumente.splice(INDEX_IN_DOKUMENTE, 1, CURRENT_DOCUMENT);
        return;
      }
      const INDEX_IN_READONLY_DOKUMENTE = findIndex(this.model.readonlyDoks, ["pk", DOC_PK]);
      if (INDEX_IN_READONLY_DOKUMENTE !== -1) {
        if (type === "delete") {
          this.model.readonlyDoks.splice(INDEX_IN_READONLY_DOKUMENTE, 1);
        } else {
          const CURRENT_DOCUMENT = cloneDeep(this.model.readonlyDoks[INDEX_IN_READONLY_DOKUMENTE]);
          CURRENT_DOCUMENT.titel = DOC_TITEL;
          this.model.readonlyDoks.splice(INDEX_IN_READONLY_DOKUMENTE, 1, CURRENT_DOCUMENT);
        }
      }
    },

    prepareModel() {
      const MODEL = cloneDeep(this.module.data) || {};
      if (!MODEL.dokumente) {
        MODEL.dokumente = [];
        MODEL.readonlyDoks = [];
      } else {
        const GROUPS = groupBy(MODEL.dokumente, dok => !!dok.readonly);
        MODEL.readonlyDoks = GROUPS.true || [];
        MODEL.dokumente = GROUPS.false || [];
      }
      this.model = MODEL;
      this.modelClone = cloneDeep(this.model);
      forEach(this.model.dokumente, dokument => {
        if (dokument.is_sync && this.dokumenteInSync.indexOf(dokument.pk) === -1) {
          this.dokumenteInSync.push(dokument.pk);
        }
      });
      if (this.dokumenteInSync.length) {
        this.checkSynchronisation();
      }
    },

    addInput({ currentModel }) {
      const DOK = {
        titel: currentModel.titel,
        groesse: currentModel.groesse,
        datei: currentModel.datei,
        url: currentModel.url,
        pk: currentModel.pk,
        is_sync: false,
        readonly: false,
        dokart: this.modelParameter.dokart,
      };
      if (currentModel.is_sync) {
        this.dokumenteInSync.push(currentModel.dokument.pk);
      }
      this.addedList.push(DOK);
      if (this.currentSave && !this.sheduledSave) {
        this.sheduledSave = true;
        this.currentSave.finally(() => {
          this.sheduledSave = false;
          this.doSave();
        });
      } else if (!this.currentSave) {
        this.doSave();
      }
    },

    doSave() {
      const ADDING = cloneDeep(this.addedList);
      this.addedList = [];
      this.modelClone.dokumente.push(...ADDING);
      const PROMISE = this.save({ model: this.modelClone }).then(
        response => {
          this.model = response.data;
          forEach(ADDING, dok => {
            EventBus.$emit("changeDocument", {
              type: "create",
              dokartPk: this.modelParameter.dokart,
              document: dok,
              regel: this.regel.regel,
            });
          });
        },
        () => {
          forEach(ADDING, dok => {
            const INDEX_IN_DOKS = indexOf(this.model.dokumente, dok.pk);
            if (INDEX_IN_DOKS !== -1) {
              this.model.dokumente.splice(INDEX_IN_DOKS, 1);
            }
          });
        }
      ).finally(() => this.currentSave = undefined);
      this.currentSave = PROMISE;
      return PROMISE;
    },

    checkSynchronisation({ time = 3000 } = {}) {
      if (this.timer) {
        return;
      }
      this.timer = true;
      setTimeout(() => {
        if (!this.dokumenteInSync.length) {
          this.timer = undefined;
          return;
        }
        this.getListHttp({
          url: "dokument_sync/",
          urlParams: {
            dok_id: this.dokumenteInSync,
          },
        }).then(
          response => {
            this.timer = undefined;
            this.checkDokumenteInSync({ response });
            this.checkSynchronisation();
          }
        );
      }, time);
    },

    checkDokumenteInSync({ response }) {
      forEach(response, dokument => {
        if (!dokument.is_sync) {
          const INDEX_IN_DOK_SYNC = indexOf(this.dokumenteInSync, dokument.pk);
          if (INDEX_IN_DOK_SYNC !== -1) {
            this.dokumenteInSync.splice(INDEX_IN_DOK_SYNC, 1);
          }
          const INDEX_IN_DOKUMENTE = findIndex(this.model.dokumente, ["pk", dokument.pk]);
          if (INDEX_IN_DOKUMENTE !== -1) {
            const DOKUMENT_CLONE = cloneDeep(this.model.dokumente[INDEX_IN_DOKUMENTE]);
            DOKUMENT_CLONE.is_sync = false;
            this.model.dokumente.splice(INDEX_IN_DOKUMENTE, 1, DOKUMENT_CLONE);
          }
        }
      });
    },

    openModalDeleteDokument({ dok }) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_DOKUMENTENFRAGE_GR_DELETE_MODAL_HEADER_",
        msg: "_TXT_DOKUMENTENFRAGE_GR_DELETE_MODAL_BODY_{{titel}}_",
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: () => this.deleteDokument({ dokument: dok }),
        cancelCallback: this.closeConfirmDokument,
        extra: {
          titel: dok.titel,
        },
      };
      this.selectorCloseDelete = `#${ this.buttonIdDelete(dok) }`;
      this.confirmShow = true;
    },

    closeConfirmDokument() {
      this.confirmShow = false;
    },

    deleteDokumentModule({ dokument }) {
      const DOK_INDEX = findIndex(this.model.dokumente, ["pk", dokument.pk]);
      if (DOK_INDEX !== -1) {
        const DATA = cloneDeep(this.model);
        DATA.dokumente.splice(DOK_INDEX, 1);
        this.save({ model: DATA }).then(
          () => {
            this.model = DATA;
            this.modelClone = cloneDeep(this.model);
            this.addNotification({ text: "_MSG_DOKUMENTENFRAGE_GR_DELETE_SUCCESS_" });
            this.loadingDokumenteDelete[dokument.pk] = false;
            EventBus.$emit("changeDocument", {
              type: "delete",
              dokartPk: this.modelParameter.dokart,
              document: dokument,
              regel: this.regel.regel,
            });
          },
          () => this.addNotification({ text: "_MSG_DOKUMENTENFRAGE_GR_DELETE_ERROR_", type: "error" })
        );
      }
    },

    deleteDokument({ dokument }) {
      this.selectorCloseDelete = undefined;
      this.closeConfirmDokument();
      if (!dokument.url) {
        this.deleteDokumentModule({ dokument: dokument });
        return;
      }
      this.loadingDokumenteDelete[dokument.pk] = true;
      this.deleteDokumentModule({ dokument: dokument });
    },

    showReadonlyDoks(data) {
      return this.modelParameter.andere_quellen && size(get(data, "readonlyDoks", [])) > 0;
    },

  },
};
