import BelegButtonVersionsvergleichBetragsrechnerTableRow
  from "../../BelegButtonVersionsvergleichBetragsrechnerTableRow/BelegButtonVersionsvergleichBetragsrechnerTableRow.vue";
import FormElementReadOnly from "../../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import KostenOhneBezeichnung from "../../../../../KF/Betragsrechner/KostenOhneBezeichnung/KostenOhneBezeichnung";

// @vue/component
export default {
  name: "KostenOhneBezeichnungBelegVergleich",
  components: {
    BelegButtonVersionsvergleichBetragsrechnerTableRow,
    FormElementReadOnly,
    PuxTranslate,
    SnapshotIcon,
  },
  mixins: [
    KostenOhneBezeichnung,
  ],
  props: {
    modelErfasst: {
      type: Object,
      required: true,
    },
    modelAnerkannt: {
      type: Object,
      required: true,
    },
  },
};
