"use strict";

import angular from "angular";
import scrollIntoViewOn from "./scrollIntoViewOn.directive";

const diasAnchorOffset = angular.module("dias.ui.scroll", [
])

  .directive("scrollIntoViewOn", scrollIntoViewOn);

export default diasAnchorOffset;
