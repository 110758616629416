import FormElement from "../../../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxGet from "../../../../../../global/components/PuxGet/PuxGet.vue";

import StudiengangEditMixin from "./StudiengangEditMixin";

import KStudiengangartUUIDs from "../../../../../../const/KStudiengangartUUIDs";

// @vue/component
export default {
  name: "StudiengangEdit",
  components: {
    FormElement,
    FormstepDetail,
    PuxButton,
    PuxCloak,
    PuxGet,
  },
  mixins: [
    StudiengangEditMixin,
  ],
  props: {
    saveobj: {
      type: Object,
      required: true,
    },
    apicall: {
      type: Object,
      default: undefined,
    },
    extras: {
      type: Object,
      default: undefined,
    },
    onsave: {
      type: Function,
      required: true,
    },
    onabbrechen: {
      type: Function,
      required: true,
    },
    copy: {
      type: Function,
      required: false,
      default: undefined,
    },
    customSaveUrl: {
      type: String,
      required: false,
      default: undefined,
    }
  },
  data() {
    return {
      loadingSave: false,
      copying: false,
    };
  },
  computed: {
    formstepDetailOption() {
      return {
        label: "Studiengangs-Stammdaten bearbeiten",
        closeCallback: this.reset,
      };
    },

    idForCopyButton() {
      return `${ this.id }_studiengang_copy`;
    },

    showCopy() {
      if (!this.copymode || this.model.kopiervorlage) {
        return false;
      }
      return true;
    },
  },
  methods: {
    reset() {
      this.onabbrechen(this.saveobj);
    },

    showAkkrCopy() {
      if (!this.copymode || this.model.kopiervorlage) {
        return false;
      }
      if (this.model.art === KStudiengangartUUIDs.TEIL) {
        return false;
      }
      return this.checkPermissionsSync({ perm: "studiengang.copy_akkr" });
    },

    save() {
      const defaultUrl = `studiengaenge/${ this.model.sg_id }/`;
      this.loadingSave = true;
      this.putHttp({
        url: this.customSaveUrl || defaultUrl,
        data: {
          sg_id: this.model.sg_id,
          sg_bez: this.model.sg_bez,
          abschlussgrad: this.model.abschlussgrad,
          mastertyp: this.model.mastertyp,
          abschlussbezeichnung: this.model.abschlussbezeichnung,
          typ: this.model.typ,
          studienformen: this.model.studienformen,
          art: this.model.art,
          sg_regelstudienzeit: this.model.sg_regelstudienzeit,
          regelstudieneinheit: this.model.regelstudieneinheit,
          sg_laufnr: this.model.sg_laufnr,
          sg_fakturieren: this.model.sg_fakturieren,
          sg_abschlussbez_freitext: this.model.sg_abschlussbez_freitext,
          standorte: this.model.standorte,
          hochschule: this.model.hochschule_obj.pk,
          studienfelder: this.model.studienfelder,
          studienorganisatorische_teileinheit: this.model.studienorganisatorische_teileinheit,
          sg_beginn_studienbetrieb: this.model.sg_beginn_studienbetrieb,
          sg_aufnahmekapazitaet: this.model.sg_aufnahmekapazitaet,
          sg_studienanfaenger: this.model.sg_studienanfaenger,
          sg_absolventen: this.model.sg_absolventen,
          sg_ects_punkte: this.model.sg_ects_punkte,
          sg_erfolgsquote: this.model.sg_erfolgsquote || null,
          sg_studiendauer: this.model.sg_studiendauer,
          sg_studierende_w: this.model.sg_studierende_w,
          sg_studierende_m: this.model.sg_studierende_m,
          sg_studierende_s: this.model.sg_studierende_s,
          lehramtstyp: this.model.lehramtstyp,
          polyvalenz_erlaeuterung: this.model.polyvalenz_erlaeuterung,
          polyvalent: this.model.polyvalent,
        },
      }).then(
        response => {
          this.model = Object.assign({}, this.model, response);
          this.onsave(this.saveobj, this.extras);
        },
        errors => {
          return this.onErrors(errors);
        }
      ).finally(
        () => this.loadingSave = false
      );
    },
  },
};
