"use strict";


class BankverbindungCreateController {
  /*@ngInject*/
  constructor($q, $scope, Katalog, DiasNotification, SyConfigs) {
    this.$q = $q;
    this.$scope = $scope;
    this.DiasNotification = DiasNotification;
    this.ast = $scope.extras.ast;
    this.bv = { antragstellerorg: this.ast.pk };
    this.conf = SyConfigs.wert("bankverbindung_weitere_pflichtfelder");

    // this.save = this.save.bind(this);
    this.save = () => {
      const defer = $q.defer();
      const promise = this.ast.bankverbindungen.post(this.bv);
      promise.then(
        response => {
          this.DiasNotification.page.success("Bankverbindung erfolgreich erstellt.");
          defer.resolve(response);
        },
        error => {
          defer.reject();
          const errorsLabel = {
            aov_inhaber: "Inhaber: ",
            aov_iban: "IBAN: ",
            aov_bic: "BIC: ",
            aov_bank: "Geldinstitut",
          };

          this.DiasNotification.form.clear();
          this.DiasNotification.form.error(error.data, "Ihre Daten sind fehlerhaft oder unvollständig.", {}, errorsLabel);
          this.errors = error.data;
        }
      );
      return defer.promise;
    };
  }
}

export default BankverbindungCreateController;
