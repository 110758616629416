import {
  setHeightAsideAndMenu,
} from "../../../../vue/client/vue/global/functions/utilsDOM";

export default /*@ngInject*/ () => {
  return {
    restrict: "A",
    link: function() {
      setHeightAsideAndMenu();
    }
  };
};
