import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import loading from "../../../../global/directives/loading";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";
import {
  FilterCurrencyMixin,
} from "../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  components: {
    PuxCloak,
    PuxTranslate,
  },
  directives: {
    loading,
  },
  mixins: [
    FilterCurrencyMixin,
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    vertrag: {
      type: Object,
      required: true,
    },
    disableBuchungen: {
      type: Function,
      required: true,
    },
    enableBuchungen: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loadingSaldo: true,
      salden: undefined,
    };
  },
  computed: {
    vertragSaldoUrl() {
      return `vertraege/${ this.vertrag.pk }/saldo/`;
    }
  },
  created() {
    this.getSaldo();
  },
  methods: {
    getSaldo() {
      this.loadingSaldo = true;
      this.getHttp({
        url: this.vertragSaldoUrl,
      }).then(
        response => {
          this.salden = response;
          if (!this.salden) {
            this.disableBuchungen();
          } else {
            this.enableBuchungen();
          }
        },
        () => {
          this.addNotification({ text: "_MSG_VERTRAG_SALDO_ERROR_", type: "error" });
          this.disableBuchungen();
        }
      ).finally(
        () => this.loadingSaldo = false
      );
    }
  },
};
