"use strict";

import angular from "angular";
import pipes from "./pipes";


const zeroNumberModule = angular.module("zeroNumber", [
  pipes.name
])

;

export default zeroNumberModule;
