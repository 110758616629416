"use strict";

import BaseModel from "dias/core/models/baseModel";

class Preview extends BaseModel {
  constructor() {
    super("preview");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.getPreviewUrl = dokartId => {
      return collection.getRestangularUrl() + "/" + dokartId + "/";
    };
    return collection;
  }
}

export default Preview;
