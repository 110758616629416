"use strict";

import angular from "angular";

import Keys from "./keys.service";

const modelsModule = angular.module("dias.statusfolgen.services", [])

  .constant("STATUSFOLGEN_KEYS", Keys)
;

export default modelsModule;
