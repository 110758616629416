import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FieldsMixin from "./FieldsMixin";

import { EventBus } from "../../../../../global/functions/event-bus";
import {
  cloneDeep,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "ImportauftragAntragWizardStepAnlagen",
  components: {
    FormElement,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    FieldsMixin,
  ],
  props: {
    antragId: {
      type: String,
      default: undefined,
    },
    dokartId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      editModel: {
        files: [],
      }
    };
  },
  created() {
    this.initFieldOptions();
  },
  methods: {
    initFieldOptions() {
      this.fields[0].saveUrl = `foerderantraege/${ this.antragId }/dokumente/?dokart=${ this.dokartId }`;
    },

    addFile({ currentModel }) {
      const CURR_MODEL = cloneDeep(currentModel);
      this.editModel.files.push(cloneDeep(CURR_MODEL));
      EventBus.$emit("addDocument", { document: CURR_MODEL });
    },

    filesEmpty() {
      return !size(this.editModel.files);
    },
  },
};
