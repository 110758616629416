// @vue/component
export default {
  data() {
    return {
      labelGesamt: "_LBL_REGIONALE_EFFEKTE_GESAMT_",
      labelPartDe: "_LBL_REGIONALE_EFFEKTE_PART_DE_",
      labelPartDeSn: "_LBL_REGIONALE_EFFEKTE_PART_DE_SN_",
      labelPartDeSt: "_LBL_REGIONALE_EFFEKTE_PART_DE_ST_",
      labelPartDeTh: "_LBL_REGIONALE_EFFEKTE_PART_DE_TH_",
    };
  },
  computed: {
    optionsGesamt() {
      return {
        id: "gesamt",
        htmlId: this.htmlIdGesamt,
        label: this.labelGesamt,
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdGesamt() {
      return `${ this.htmlId }_gesamt`;
    },

    optionsPartDe() {
      return {
        id: "part_de",
        htmlId: this.htmlIdPartDe,
        label: this.labelPartDe,
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdPartDe() {
      return `${ this.htmlId }_part_de`;
    },

    optionsPartDeSn() {
      return {
        id: "part_de_sn",
        htmlId: this.htmlIdPartDeSn,
        label: this.labelPartDeSn,
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 14,
        required: false,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdPartDeSn() {
      return `${ this.htmlId }_part_de_sn`;
    },

    optionsPartDeSt() {
      return {
        id: "part_de_st",
        htmlId: this.htmlIdPartDeSt,
        label: this.labelPartDeSt,
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 14,
        required: false,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdPartDeSt() {
      return `${ this.htmlId }_part_de_st`;
    },

    optionsPartDeTh() {
      return {
        id: "part_de_th",
        htmlId: this.htmlIdPartDeTh,
        label: this.labelPartDeTh,
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 14,
        required: false,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdPartDeTh() {
      return `${ this.htmlId }_part_de_th`;
    },

    errorLabels() {
      return {
        gesamt: {
          label: this.labelGesamt,
          link: true,
          id: this.htmlIdGesamt,
        },
        part_de: {
          label: this.labelPartDe,
          link: true,
          id: this.htmlIdPartDe,
        },
        part_de_sn: {
          label: this.labelPartDeSn,
          link: true,
          id: this.htmlIdPartDeSn,
        },
        part_de_st: {
          label: this.labelPartDeSt,
          link: true,
          id: this.htmlIdPartDeSt,
        },
        part_de_th: {
          label: this.labelPartDeTh,
          link: true,
          id: this.htmlIdPartDeTh,
        },
      };
    },
  },
};
