import MediaPlayer from "../../../../../global/components/MediaPlayer/MediaPlayer.vue";

import FaqDetailsItemMixin from "../FaqDetailsItemMixin";

import {
  getInternalOrExternalPathToFile,
} from "../../../../../global/functions/utils";

// @vue/component
export default {
  name: "FaqDetailsItemVideo",
  components: {
    MediaPlayer,
  },
  mixins: [
    FaqDetailsItemMixin,
  ],
  computed: {
    idLocal() {
      return `faq_video_${ this.data.pk }`;
    },

    sources() {
      return this.parameter.sources || [];
    },

    videoDescription() {
      return this.parameter.video_description || [];
    },

    audioDescription() {
      return this.parameter.audio_description || [];
    },

    poster() {
      return getInternalOrExternalPathToFile(this.parameter.poster);
    },

    alt() {
      return this.parameter.alt;
    },

    idForDescription() {
      return `${ this.idLocal }_description`;
    },

    subtitles() {
      return this.parameter.subtitles || [];
    },
  },
};
