"use strict";

class MassenstatuswechselController {
  /*@ngInject*/
  constructor($scope) {
    $scope.extras.startTask().then(
      result => this.taskId = result
    );
    this.onSuccess = result => {
      if (result.fail === 0) {
        this.resultType = "success";
      } else {
        this.resultType = "warning";
      }
      this.result = result;
    };

    this.onFailure = result => {
      this.resultType = "danger";
      this.result = result.data;
    };
  }
}

export default MassenstatuswechselController;
