import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import SmartTable from "../../../global/components/table/table.vue";
import getTableApi from "../../../const/TableApis";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";

const TABLE_ID = "workflowtypen";

// @vue/component
export default {
  name: "WorkflowtypenList",
  components: {
    AngularLink,
    SmartTable,
    PageTabTitle,
  },
  mixins: [
    PermissionMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [
          {
            label: this.$gettext("Name"),
            id: "wft_kbez",
            sortable: true,
            sortId: "wft_kbez",
            sortingDefault: "asc",
            slot: "wft_kbez",
          },
          {
            label: this.$gettext("Workflows"),
            id: "workflow_set",
            slot: "workflow_set",
          },
          {
            label: this.$gettext("Statusgruppen"),
            id: "statusgruppen",
            hide: true,
            templateCallback: ({ row }) => {
              let str = "";
              row.statusgruppen.forEach(
                wsg => str = `${ str }<li>${ wsg.wsg_kbez } (${ wsg.workflowstatusgruppentyp_bez })</li>`
              );
              return `<ul>${ str }</ul>`;
            }
          },
        ],
        rowActions: [
          {
            label: this.$gettext("Details anzeigen"),
            route: "root.workflowtypen.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [],
        label: "Workflowtypen",
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
        perms: {}
      },
      filters: [
        {
          type: "text",
          id: "wft_kbez",
          label: this.$gettext("Name"),
          main: true,
        }
      ],
    };
  },
  created() {
    this.perms = {
      "workflow.ui": this.checkPermissionsSync({ perm: "workflow.ui" }),
    };
  },
};
