"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";
import template from "./config.jade";
import templateRo from "./readonly.jade";
import controller from "./controller";


const ampel = angular.module("dias.auswahl_katalog.parameter.auswahl_katalog_eintraege", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("auswahl_katalog_eintraege", "parametermodul-auswahl-katalog-eintraege");
  })
  .component("parametermodulAuswahlKatalogEintraege", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulAuswahlKatalogEintraegeRo", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: templateRo(),
    controller: controller,
    controllerAs: "vm",
  });
export default ampel;
