"use strict";

import template from "./teamrouting.jade";


class TeamroutingController {
  /*@ngInject*/
  constructor(
    Teamroutings, 
    diasModalDialog, 
    DiasNotification, 
    Teamroutingzustaendige,
    AuthService, 
    ParameterUtils
  ) {
    this.loading = {
      root: true
    };
    this.diasModalDialog = diasModalDialog;
    this.DiasNotification = DiasNotification;
    this.Teamroutings = Teamroutings;
    this.Teamroutingzustaendige = Teamroutingzustaendige;
    this.ParameterUtils = ParameterUtils;
    this.AuthService = AuthService;

    this.perms = AuthService.syncHasPerms([
      "teamrouting.delete",
      "teamrouting.create",
      "teamrouting.update",
      "teamrouting.konfig.update"
    ]);

    this.zustaendigIcons = {
      Teammitglied: "glyphicon-user",
      Team: "glyphicon-partner"
    };
    this.resetFilter();
    this.loadZustaendige();
    this.loadKriterien();
    this.resetRoutes();
    this.loadRoot().finally(() => this.loading.root = false);
  }

  resetRoutes() {
    this.activeRoute = undefined;
    this.addModels = {};
    this.editMode = {};
    this.showDetail = {};
    this.showSubtree = {};
    this.routingElMap = {};
  }

  getExpandTitle(item) {
    if (item.count_children > 0) {
      return `${ item.count_children } Unterelement${ item.count_children === 1 ? "" : "e" } von "${ item.bez }" ${ this.showSubtree[item.pk] ? "ausblenden" : "anzeigen" }`;
    }
    return "Dieses Steuerelement besitzt keine Unterlemente zum Anzeigen";
  }

  loadKriterien() {
    this.loading.kriterien = true;
    this.Teamroutings.get_allowed_kriterien().then(
      response => {
        this.kriterien = response.filter(v => Object.keys(v.form).length > 0);
      }
    ).finally(() => this.loading.kriterien = false);
  }

  selectKriteriumFilter() {
    this.formKeys = [];
    this.formModel = {};
    this.kriterien.forEach(krit => {
      if (this.filter.kriterium && krit.modulname === this.filter.kriterium) {
        this.formKeys = this.ParameterUtils.get_ordered_keys(krit.form);
        this.formKeys = this.formKeys.map(v => {
          return { key: v, randomIdx: Math.random() };
        });
        this.formModel = this.ParameterUtils.init_parameter_in(krit.form);
      }
    });
  }

  loadZustaendige() {
    this.loading.zustaendige = true;
    this.Teamroutingzustaendige.getList().then(
      response => {
        this.zustaendigChoicesTeam = response.filter(v => v.typ === "Team");
        this.zustaendigChoicesMitglieder = response.filter(v => v.typ === "Teammitglied");
      }
    ).finally(() => this.loading.zustaendige = false);
  }

  activateFilter() {
    this.filtered = true;
    this.loading[this.root.pk] = true;
    this.loadRoot().finally(() => this.loading[this.root.pk] = false);
  }

  resetFilter() {
    this.filter = {
      teams: [],
      mitglieder: []
    };
    this.formKeys = [];
    this.formModels = {};
    const wasFiltered = this.filtered;
    this.filtered = false;
    if (wasFiltered) {
      this.loading[this.root.pk] = true;
      this.loadRoot().finally(() => this.loading[this.root.pk] = false);
    }
  }

  loadRoot() {
    return this.loadRoutes().then(root => {
      this.root = root;
      this.routingElMap[root.pk] = root;
    });
  }

  loadSubtree(root_pk) {
    this.loadRoutes(root_pk, true).then(response => {
      const root = this.routingElMap[root_pk];
      root.children = response.children;
      root.count_children = response.children.length;
    });
  }

  prepareFilter() {
    if (!this.filtered) {
      return {};
    }
    return {
      zustaendige: [
        ...this.filter.teams,
        ...this.filter.mitglieder
      ]
    };
  }

  prepareData() {
    if (!this.filtered || !this.filter.kriterium) {
      return;
    }
    const data = {};
    data[this.filter.kriterium] = this.ParameterUtils.to_request_body(this.formModel, undefined, "");
    return data;
  }

  loadRoutes(root_pk, skip_filters) {
    this.loading[root_pk] = true;
    let filters = {};
    let data = {};
    if (!skip_filters) {
      filters = this.prepareFilter();
      data = this.prepareData();
    }
    const query_params = Object.assign({}, filters, { root: root_pk });
    return this.Teamroutings.getAsTree(query_params, data).then(
      response => {
        if (response.children !== undefined) {
          response.children.forEach(el => {
            this.prepareRoute(el);
          });
        }
        return response;
      }
    ).finally(() => this.loading[root_pk] = false);
  }

  prepareRoute(route) {
    this.routingElMap[route.pk] = route;
    this.showSubtree[route.pk] = false;
    this.addModels[route.pk] = undefined;
    this.editMode[route.pk] = false;
    this.setZustaendigeStr(route);
    route.children.forEach(el => {
      this.prepareRoute(el);
    });
  }

  setZustaendigeStr(item) {
    item.zusteandigStr = "";
    if (item.zustaendig && item.zustaendig.length > 0) {
      item.zustaendig.forEach(cur => {
        item.zusteandigStr = `${ item.zusteandigStr }${ (item.zusteandigStr.length > 0) ? ", " : "" }${ cur.bez }`;
      });
    }
  }

  toggleSubtree(item) {
    this.showSubtree[item.pk] = !this.showSubtree[item.pk];
  }

  toggleDetail(item) {
    this.showDetail[item.pk] = !this.showDetail[item.pk];
    this.editMode[item.pk] = false;
    this.setActive(item);
  }

  showEdit(item) {
    this.showDetail[item.pk] = true;
    this.editMode[item.pk] = true;
    this.setActive(item);
  }

  setAdd(item, status) {
    if (status) {
      this.showSubtree[item.pk] = true;
      this.addModels[item.pk] = {
        rou_pos: item.children ? item.children.length + 1 : 1,
      };
      if (item.children && item.children.length > 0) {
        this.addModels[item.pk].rou_kriterium = item.children[0].rou_kriterium;
      }
    } else {
      this.addModels[item.pk] = undefined;
      if (!item.children || item.children.length === 0) {
        this.showSubtree[item.pk] = false;
      }
    }
    this.activeRoute = undefined;
  }

  onSaved() {
    return (item, changes) => {
      if (!item || item.rou_pos !== changes.rou_pos) {
        this.setAdd(this.routingElMap[changes.parent], false);
        this.showSubtree[changes.parent] = true;
        this.loadSubtree(changes.parent);
        this.setActive(changes);
      } else {
        Object.assign(item, changes);
        this.setZustaendigeStr(item);
      }
    };
  }

  deleteRoute(item) {
    this.Teamroutings.one(item.pk).remove().then(
      () => {
        this.DiasNotification.page.success(`Steuerelement "${ item.bez }" wurde gelöscht.`);
        this.loadSubtree(item.parent);
      }
    );
  }

  showDetail(index) {
    this.statusShowDetail[index] = !this.statusShowDetail[index];
  }

  changePos(item, posChange) {
    this.loading[item.parent] = true;
    this.setActive(item);
    this.Teamroutings.one(item.pk)
      .customPUT({ rou_pos: item.rou_pos + posChange }).then(
        () => {
          this.DiasNotification.page.success(`${ item.bez } erfolgreich verschoben.`);
          const siblings = this.routingElMap[item.parent].children;
          const itemIdx = siblings.findIndex(v => v.pk === item.pk);
          const otherItem = siblings[itemIdx + posChange];
          otherItem.rou_pos -= posChange;
          item.rou_pos += posChange;
          siblings.sort((el1, el2) => el1.rou_pos - el2.rou_pos);
        },
        err => this.DiasNotification.page.error(err)
      ).finally(() => this.loading[item.parent] = false);
  }

  setActive(item) {
    this.activeRoute = item.pk;
  }
}

export default {
  template: template(),
  controller: TeamroutingController,
  controllerAs: "vm"
};
