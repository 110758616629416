"use strict";

import template from "./listNew.jade";

import createController from "./modal.create.controller";
import createTemplate from "./modal.create.jade";


class ProjektberichtListController {
  /*@ngInject*/
  constructor(diasModalDialog) {
    this.createProjektbericht = () => {
      return diasModalDialog({
        title: "Projektbericht erstellen",
        template: createTemplate,
        controller: createController
      });
    };
  }
}

export default {
  template: template(),
  controller: ProjektberichtListController,
  controllerAs: "vm"
};
