"use strict";

import BetragsrechnerBase from "../betragsrechner.base";

class FerienkostenRechner extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "ferienkostenrechner";
    this.component_name = "ferienkosten";
    this.labels = { datum_anreise: "Anreisedatum",
                    datum_abreise: "Abreisedatum",
                    anzahl_betreuer: "Anzahl Betreuer" };
  }

  get_labelmapping() {
    return this.labels;
  }

  get_fields(params) {
    return { datum_anreise: "",
             datum_abreise: "",
             anzahl_betreuer: "",
             tagespauschale: params.tagespauschale,
    };
  }
}
export default FerienkostenRechner;
