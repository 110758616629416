import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import {
  assign,
  cloneDeep,
  get,
  isUndefined,
} from "lodash-es";


export class WorkflowModulBase {
  constructor({ workflowfolge, extra }) {
    this.workflowfolge = cloneDeep(workflowfolge);
    this.extra = cloneDeep(extra);
  }

  onServerSuccess() {
    const WORKFLOWFOLGE_TRACKING_INFO = this.workflowfolge.wfo_tracking_info;
    if (isUndefined(window.utag) ||
      !WORKFLOWFOLGE_TRACKING_INFO ||
      !WORKFLOWFOLGE_TRACKING_INFO.event_id ||
      !WORKFLOWFOLGE_TRACKING_INFO.etracker_event_action ||
      !this.extra) {
      return;
    }
    try {
      let linkObj = cloneDeep(WORKFLOWFOLGE_TRACKING_INFO);
      linkObj = assign({}, linkObj, this.extra);
      window.utag.link(linkObj);
    } catch (err) {
      console.error(err);
    }
  }

  onServerError() { }
}

// @vue/component
export default {
  components: {
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    urlWorkflowfolgen: {
      type: String,
      required: true,
    },
    workflowfolge: {
      type: Object,
      required: true,
    },
    aufgabe: {
      type: Object,
      required: true,
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
    },
    selectorClose: {
      type: [String, Array],
      required: true,
    },
    cancelWorkflowfolge: {
      type: Function,
      required: true,
    },
    startWorkflowfolge: {
      type: Function,
      required: true,
    },
  },
  emits: [
    "startWorkflowModul",
  ],
  computed: {
    buttonType() {
      return this.workflowfolge.wfo_anzeige_typ || "primary";
    },

    urlForSave() {
      return `${ this.urlWorkflowfolgen }${ this.workflowfolge.pk }/ausfuehren/`;
    },
  },
  methods: {
    prepareParameterIn(parameterInObject) {
      const PARAMETER = {};
      const PARAMETER_IN = parameterInObject || get(this.workflowfolge, "clientfunktion.wfc_parameterin", {});
      for (const key in PARAMETER_IN) {
        PARAMETER[key] = PARAMETER_IN[key].wert;
      }
      return PARAMETER;
    },

    prepareParameterOut() {
      const PARAMETER = {};
      const PARAMETER_OUT = get(this.workflowfolge, "clientfunktion.wfc_parameterout", {});
      for (const key in PARAMETER_OUT) {
        PARAMETER[key] = PARAMETER_OUT[key].default;
      }
      return PARAMETER;
    },

    finish({ model, parameterOut } = {}) {
      if (this.aufgabe === null) {
        return this.finishMassAction({ model, parameterOut });
      }
      return this.finishSingleAction({ model, parameterOut });
    },

    finishMassAction({ parameterOut }) {
      // Mehrfachaktion, daher kein direkter Aufruf der Serverfunktion
      return new Promise(resolve => {
        return resolve({ finished: true, params: parameterOut });
      });
    },

    finishSingleAction({ model, parameterOut }) {
      // Einzelaktion, d.h. direkter Aufruf der Serverfunktion
      const DATA = model || this.getDataForSave(parameterOut);
      return new Promise((resolve, reject) => {
        this.postHttp({
          url: this.urlForSave,
          data: DATA,
        }).then(
          response => {
            return resolve(response);
          },
          errors => {
            this.addNotification("_MSG_WF_FOLGEN_START_AUFGABE_ERROR_");
            return reject(errors);
          }
        );
      });
    },

    getDataForSave(userInput) {
      const DATA_FOR_SAVE = {};
      if (this.aufgabe) {
        DATA_FOR_SAVE.obj_id = this.aufgabe.pk;
      }
      for (const key in userInput) {
        DATA_FOR_SAVE[key] = userInput[key].wert;
      }
      return DATA_FOR_SAVE;
    },

    getModuleInstance() {
      return new WorkflowModulBase({
        workflowfolge: this.workflowfolge,
        extra: this.extra,
      });
    },

    startModul() {
      this.$emit("startWorkflowModul", this.getModuleInstance());
    },
  },
};
