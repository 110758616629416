"use strict";

import frageTemplate from "./hierarchiefrage.jade";


class FoerderfinderHierarchieFrageController {
  /*@ngInject*/
  constructor(KFoerderfinderantwortTypUUIDS) {
    this.KFoerderfinderantwortTypUUIDS = KFoerderfinderantwortTypUUIDS;
  }

  getAntwortypClass(antwort) {
    if (antwort.typ === this.KFoerderfinderantwortTypUUIDS.STRUKTUR) {
      return "glyphicon-arrow-right";
    } else if (antwort.typ === this.KFoerderfinderantwortTypUUIDS.FILTER) {
      return "glyphicon-filter";
    } 
    return "glyphicon-remove-circle";
  }

  showAnzFoe(antwort) {
    if (antwort.typ === this.KFoerderfinderantwortTypUUIDS.FILTER) {
      return true;
    }
    return false;
  }

  getFoeTooltip(antwort) {
    let toolTip = "";
    for (let i = 0; i < antwort.foerderangebote.length; i += 1) {
      toolTip = toolTip + "<li>" + antwort.foerderangebote[i].foe_kbez + "</li>";
    }
    if (toolTip) {
      return "<strong>Förderangebote</strong><ul>" + toolTip + "</ul>";
    } 
    return "Es sind keine Förderangebote zugeordnet.";
  }

  getRange(level) {
    const range = [];
    for (let i = 0; i <= level * 10; i += 1) {
      range.push(i);
    }
    return range;
  }
}

export default {
  template: frageTemplate(),
  controller: FoerderfinderHierarchieFrageController,
  controllerAs: "ctx",
  bindings: {
    frage: "=",
    level: "=",
  },
};
