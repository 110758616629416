import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import FormstepDetail from "../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spaced from "../../../global/components/Spaced/Spaced.vue";

// @vue/component
export default {
  name: "FormularDetailsBasisdaten",
  components: {
    AngularLink,
    FormstepDetail,
    PuxTranslate,
    Spaced,
  },
  props: {
    formular: {
      type: Object,
      required: true,
      info: "Hauptinformation",
    },
    canViewFormularobjekt: {
      type: Boolean,
      required: false,
      info: "Hat man WF-Berechtigung Objekt zu sehnen",
    },
    vertragId: {
      type: String,
      required: false,
      default: undefined,
      info: "Vertrag-UUId",
    },
  },
  computed: {
    parameterForAngularLink() {
      if (this.formular.herkunft !== "auszahlung") {
        return {
          id: this.formular.for_objektid,
        };
      }
      return {
        id: this.vertragId,
        aus_id: this.formular.for_objektid,
      };
    },
  },
};
