import AbhaengigeKostenpauschale from "./AbhaengigeKostenpauschale/AbhaengigeKostenpauschale.vue";
import AbzugNichtFoerderfaehigeKosten from "./AbzugNichtFoerderfaehigeKosten/AbzugNichtFoerderfaehigeKosten.vue";
import AllgemeinkostenPauschale from "./AllgemeinkostenPauschale/AllgemeinkostenPauschale.vue";
import BearbeitbarePauschale from "./BearbeitbarePauschale/BearbeitbarePauschale.vue";
import DeckungslueckeFinanzierung from "./DeckungslueckeFinanzierung/DeckungslueckeFinanzierung.vue";
import EhrenamtPauschale from "./EhrenamtPauschale/EhrenamtPauschale.vue";
import HonorarKosten from "./HonorarKosten/HonorarKosten.vue";
import KostenabhaengigeFinanzierung from "./KostenabhaengigeFinanzierung/KostenabhaengigeFinanzierung.vue";
import KostenMitPauschale from "./KostenMitPauschale/KostenMitPauschale.vue";
import KostenSummenzeile from "./KostenSummenzeile/KostenSummenzeile.vue";
import ManuellePauschale from "./ManuellePauschale/ManuellePauschale.vue";
import ManuelleRueckforderung from "./ManuelleRueckforderung/ManuelleRueckforderung.vue";
import PeriodenSignalFinanzierung from "./PeriodenSignalFinanzierung/PeriodenSignalFinanzierung.vue";
import PeriodenSignalKosten from "./PeriodenSignalKosten/PeriodenSignalKosten.vue";
import PeriodenSignalZuschussEingabe from "./PeriodenSignalZuschussEingabe/PeriodenSignalZuschussEingabe.vue";
import PersonalkostenLohnjournal from "./PersonalkostenLohnjournal/PersonalkostenLohnjournal.vue";
import PersonalkostenPerioden from "./PersonalkostenPerioden/PersonalkostenPerioden.vue";
import PersonalkostenPkt from "./PersonalkostenPkt/PersonalkostenPkt.vue";
import RollstuhlplatzPauschale from "./RollstuhlplatzPauschale/RollstuhlplatzPauschale.vue";
import Rueckforderungsverzicht from "./Rueckforderungsverzicht/Rueckforderungsverzicht.vue";
import StandardFinanzierung from "./StandardFinanzierung/StandardFinanzierung.vue";
import SignalFinanzierung from "./SignalFinanzierung/SignalFinanzierung.vue";
import SignalKosten from "./SignalKosten/SignalKosten.vue";
import SignalZuschuss from "./SignalZuschuss/SignalZuschuss.vue";
import StandardKosten from "./StandardKosten/StandardKosten.vue";
import StandardPauschale from "./StandardPauschale/StandardPauschale.vue";
import StandardZuschuss from "./StandardZuschuss/StandardZuschuss.vue";
import Stueckkosten from "./Stueckkosten/Stueckkosten.vue";
import ZuschussMitEingabe from "./ZuschussMitEingabe/ZuschussMitEingabe.vue";
import ZuschussMitFinanzierungseingabe from "./ZuschussMitFinanzierungseingabe/ZuschussMitFinanzierungseingabe.vue";
import ZuschussPerioden from "./ZuschussPerioden/ZuschussPerioden.vue";
import ZuschussZeitraum from "./ZuschussZeitraum/ZuschussZeitraum.vue";
// AM /
import TagespauschaleKostenSignal from "./AM/TagespauschaleKostenSignal/TagespauschaleKostenSignal.vue";
// AM
// DVV /
import DvvVeranstaltungen from "./DVV/DvvVeranstaltungen/DvvVeranstaltungen.vue";
import DvvVeranstaltungenEinfach from "./DVV/DvvVeranstaltungenEinfach/DvvVeranstaltungenEinfach.vue";
// DVV
// Kumasta /
import KumastaProjektfinanzierung from "./Kumasta/KumastaProjektfinanzierung/KumastaProjektfinanzierung.vue";
// Kumasta
// DHW /
// DHW

export default {
  AbhaengigeKostenpauschale,
  AbzugNichtFoerderfaehigeKosten,
  AllgemeinkostenPauschale,
  BearbeitbarePauschale,
  DeckungslueckeFinanzierung,
  EhrenamtPauschale,
  HonorarKosten,
  KostenabhaengigeFinanzierung,
  KostenMitPauschale,
  KostenSummenzeile,
  ManuellePauschale,
  ManuelleRueckforderung,
  PeriodenSignalFinanzierung,
  PeriodenSignalKosten,
  PeriodenSignalZuschussEingabe,
  PersonalkostenLohnjournal,
  PersonalkostenPerioden,
  PersonalkostenPkt,
  RollstuhlplatzPauschale,
  Rueckforderungsverzicht,
  StandardFinanzierung,
  SignalFinanzierung,
  SignalKosten,
  SignalZuschuss,
  StandardKosten,
  StandardPauschale,
  StandardZuschuss,
  Stueckkosten,
  ZuschussMitEingabe,
  ZuschussMitFinanzierungseingabe,
  ZuschussPerioden,
  ZuschussZeitraum,
  // AM /
  TagespauschaleKostenSignal,
  // AM
  // DVV /
  DvvVeranstaltungen,
  DvvVeranstaltungenEinfach,
  // DVV
  // Kumasta /
  KumastaProjektfinanzierung,
  // Kumasta
};

export const MAPPING_KF = {
  abhaengige_kostenpauschale: "AbhaengigeKostenpauschale",
  abzug_nicht_foerderfaehige_kosten: "AbzugNichtFoerderfaehigeKosten",
  allgemeinkosten_pauschale: "AllgemeinkostenPauschale",
  bearbeitbare_pauschale: "BearbeitbarePauschale",
  deckungsluecke_finanzierung: "DeckungslueckeFinanzierung",
  ehrenamt_pauschale: "EhrenamtPauschale",
  honorar_kosten: "HonorarKosten",
  kostenabhaengige_finanzierung: "KostenabhaengigeFinanzierung",
  kosten_mit_pauschale: "KostenMitPauschale",
  kosten_summenzeile: "KostenSummenzeile",
  manuelle_pauschale: "ManuellePauschale",
  manuelle_rueckforderung: "ManuelleRueckforderung",
  perioden_signal_finanzierung: "PeriodenSignalFinanzierung",
  perioden_signal_kosten: "PeriodenSignalKosten",
  perioden_signal_zuschuss_eingabe: "PeriodenSignalZuschussEingabe",
  personalkosten_lohnjournal: "PersonalkostenLohnjournal",
  personalkosten_perioden: "PersonalkostenPerioden",
  personalkosten_pkt: "PersonalkostenPkt",
  rollstuhlplatz_pauschale: "RollstuhlplatzPauschale",
  rueckforderungsverzicht: "Rueckforderungsverzicht",
  standard_finanzierung: "StandardFinanzierung",
  signal_finanzierung: "SignalFinanzierung",
  signal_kosten: "SignalKosten",
  signal_zuschuss: "SignalZuschuss",
  standard_kosten: "StandardKosten",
  standard_pauschale: "StandardPauschale",
  standard_zuschuss: "StandardZuschuss",
  stueckkosten: "Stueckkosten",
  zuschuss_mit_eingabe: "ZuschussMitEingabe",
  zuschuss_mit_finanzierungseingabe: "ZuschussMitFinanzierungseingabe",
  zuschuss_perioden: "ZuschussPerioden",
  zuschuss_zeitraum: "ZuschussZeitraum",
  zuschuss_extra_validierung: "StandardZuschuss",
  // AM /
  tagespauschale_kosten_signal: "TagespauschaleKostenSignal",
  // AM
  // DVV /
  dvv_veranstaltungen: "DvvVeranstaltungen",
  dvv_veranstaltungen_einfach: "DvvVeranstaltungenEinfach",
  // DVV
  // Kumasta /
  kumasta_projektfinanzierung: "KumastaProjektfinanzierung",
  // Kumasta
  // DHW /
  dhw_personalkosten_perioden: "PersonalkostenPerioden",
  // DHW
};
