import AngebotModalZuordnen from "./AngebotModalZuordnen/AngebotModalZuordnen.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import SmartTable from "../../../../global/components/table/table.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import getTableApi from "../../../../const/TableApis";
import {
  EventBus,
} from "../../../../global/functions/event-bus";

const TABLE_ID = "FoerderangeboteListInFoerderprogramm";

// @vue/component
export default {
  name: "ProgrammeFoerderangeboteList",
  components: {
    AngebotModalZuordnen,
    Modal,
    SmartTable,
  },
  props: {
    programm: {
      type: Object,
      required: true,
    },
    programmUrl: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      deleteHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      deleteHttp,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: "",
        cols: [
          {
            label: "_LBL_PROGRAMME_KURZBEZEICHNUNG_",
            id: "angebot_kbez",
            linkTo: {
              url: "/foerderangebote/{{angebot}}/",
            },
          },
        ],
        rowActions: [
          {
            label: "_LBL_PROGRAMME_FOERDERANGEBOTE_LIST_ACTION_ROW_DETAILS_",
            route: "root.foerderangebote.detail",
            routeParameters: [{ routeId: "id", rowPath: "angebot" }],
          },
          {
            label: "_LBL_PROGRAMME_FOERDERANGEBOTE_LIST_ZUORDNUNG_ENTFERNEN_",
            callback: this.openConfirmDeleteAngebot,
          },
        ],
        actions: [
          {
            label: "_LBL_PROGRAMME_FOERDERANGEBOTE_LIST_FOERDERANGEBOT_ZUORDNEN_",
            callback: () => this.modal.angebotZurdnen = true,
            type: "button",
            className: "btn btn-primary",
            htmlId: "foerderangebot_zuordnen",
          },
        ],
        label: "_LBL_PROGRAMME_FOERDERANGEBOTE_LIST_FOERDERANGEBOTE_",
        sortable: false,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
      },
      confirmShow: undefined,
      confirmOptions: undefined,
      modal: {
        angebotZurdnen: undefined,
      },
    };
  },
  created() {
    this.initUrl();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { programmPk: this.programm.pk } });
    },

    openConfirmDeleteAngebot({ item }) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_PROGRAMME_FOERDERANGEBOTE_LIST_ZUORDNUNG_ENTFERNEN_",
        msg: "_HTML_PROGRAMME_FOERDERANGEBOTE_LIST_ENTFERNEN_{{angebot_kbez}}_",
        extra: {
          angebot_kbez: item.angebot_kbez,
        },
        okLabel: "_BTN_FORTFAHREN_",
        okCallback: () => this.deleteAngebot({ angebot: item }),
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.confirmShow = true;
    },

    closeConfirm() {
      this.confirmShow = false;
    },

    deleteAngebot({ angebot }) {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: `${ this.programmUrl }angebote/${ angebot.pk }/`,
      }).then(
        () => {
          this.addNotification({ text: "_MSG_PROGRAMME_FOERDERANGEBOTE_LIST_ZUORDNUNG_ENTFERNT_" });
          this.confirmOptions.loading = false;
          EventBus.$emit(`updateTable${ TABLE_ID }`);
          this.closeConfirm();
        }
      );
    },

    closeModalAngebotZuordnen({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.modal.angebotZurdnen = false;
    },
  },
};
