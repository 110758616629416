// @vue/component
export default {
  data() {
    return {
      fields: {
        name: {
          id: "name",
          type: "text",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_NAME_",
          required: false,
          view: "v-alt",
          translate: true,
          tooltip: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_NAME_INFO_I_",
        },
        stellenumfang_projekt: {
          id: "stellenumfang_projekt",
          type: "float",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_STELLENUMFANG_PROJEKT_",
          addonBack: "_LBL_SUFFIX_STUNDEN_PRO_WOCHE_",
          required: true,
          view: "v-alt",
          maxValue: undefined,
          translate: true,
          tooltip: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_STELLENUMFANG_PROJEKT_INFO_I_",
        },
        zeitraum_von: {
          id: "zeitraum_von",
          type: "date",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_ZEITRAUM_VON_",
          required: true,
          view: "v-alt",
          translate: true,
          tooltip: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_ZEITRAUM_VON_INFO_I_",
          notBefore: undefined,
          notAfter: undefined,
          clearable: false,
        },
        zeitraum_bis: {
          id: "zeitraum_bis",
          type: "date",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_ZEITRAUM_BIS_",
          required: true,
          view: "v-alt",
          translate: true,
          tooltip: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_ZEITRAUM_BIS_INFO_I_",
          notBefore: undefined,
          notAfter: undefined,
          clearable: false,
        },
      },
    };
  },
};
