import Modal from "../../../../../global/components/Modal/Modal.vue";
import SmartTable from "../../../../../global/components/table/table.vue";
import WizardStep3TableDetails from "./WizardStep3TableDetails/WizardStep3TableDetails.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";
import ModuleMappingMixin from "../../../../../global/mixins/ModuleMappingMixin";
import NotificationMixin from "../../../../../global/mixins/NotificationMixin";

import getTableApi from "../../../../../const/TableApis";
import { EventBus } from "../../../../../global/functions/event-bus";
import {
  setUrlWithParams,
} from "../../../../../global/functions/help";

const TABLE_ID = "DokumenteSuche";
const ERROR_DEFAULT_TEXT = "Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunk erneut!";


// @vue/component
export default {
  name: "DokumenteSuchenWizardStep3",
  components: {
    Modal,
    SmartTable,
    WizardStep3TableDetails,
  },
  mixins: [
    HttpMixin,
    ModuleMappingMixin,
    NotificationMixin,
  ],
  props: {
    urlParams: {
      type: Object,
      required: true,
    },
    modelKontext: {
      type: String,
      required: true,
    },
    sitzungsPDF: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: undefined,
        rowActions: [
          {
            label: "_LBL_DOKUMENTE_SUCHEN_WIZARD_DOKUMENT_ANZEIGEN_",
            target: "_blank",
            href: ({ item }) => item.url,
          },
          {
            labelCallback: ({ item }) => item.dokument.link_external_viewer.label,
            target: "_blank",
            href: ({ item }) => item.dokument.link_external_viewer.link,
            isHidden: ({ item }) => !item.dokument || !item.dokument.link_external_viewer || item.dokument.link_external_viewer.link,
          },
          {
            label: "_LBL_DOKUMENTE_SUCHEN_WIZARD_SITZUNG_VORSEHEN_",
            callback: this.copyDocument,
            isHidden: ({ item }) => this.modelKontext !== "foerderantraege" || item.copy_disabled || item.dokument.dokumentart.pk === this.sitzungsPDF,
            permission: "dokument.copy",
          },
          {
            label: "_LBL_DOKUMENTE_SUCHEN_WIZARD_DOKUMENT_LOESCHEN_",
            callback: this.confirmDeleteDocument,
            isHidden: ({ item }) => item.delete_disabled,
            permission: "dokumentsuche.delete",
          },
        ],
        label: "_LBL_DOKUMENTE_SUCHEN_WIZARD_DOKUMENTE_",
        sortable: false,
        pagination: true,
        details: true,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
      },
      confirmShow: undefined,
      confirmOptions: undefined,
      loadingDeleteDocument: {},
      urlMainPart: undefined,
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },

    getHrefForDocument() {
      return doc => {
        return this.getAngularRout({
          model: doc.link_to_obj.model,
          id: doc.link_to_obj.pk,
          // parentId: doc.link_to_obj.parentId // Das ist für Auszahlung und TOP, aber server schickt die Daten nicht
        });
      };
    },
  },
  created() {
    this.setUrlForTable();
  },
  methods: {
    setUrlForTable() {
      this.urlMainPart = getTableApi({
        id: TABLE_ID,
        params: {
          modelKontext: this.modelKontext,
        },
      });
      this.options.url = setUrlWithParams({
        url: this.urlMainPart,
        params: this.urlParams,
      });
    },

    copyDocument({ item }) {
      const DATA = {
        target_mod_name: "foerderantraege",
        target_akt_mod_id: item.link_to_obj.pk,
        target_dokart: this.sitzungsPDF
      };

      this.putHttp({
        url: `foerderantraege/${ item.link_to_obj.pk }/dokumente/${ item.pk }/copy_doc/`,
        data: DATA,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_DOKUMENTE_SUCHEN_WIZARD_DOKUMENT_SITZUNG_VORGESEHEN_{{titel}}_",
            extra: {
              titel: item.dokument.dok_titel
            }
          });
        }
      );
    },

    confirmDeleteDocument({ item }) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "Dokument löschen",
        msg: "_HTML_DOKUMENTE_SUCHEN_WIZARD_MODAL_LOESCHEN_BODY_",
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: () => {
          this.deleteDocument({ item });
        },
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteDocument({ item }) {
      if (this.loadingDeleteDocument[item.pk]) {
        return;
      }
      this.loadingDeleteDocument[item.pk] = true;
      const URL = setUrlWithParams({
        url: `${ this.urlMainPart }${ item.pk }/`,
        params: this.urlParams,
      });
      this.deleteHttp({
        url: URL
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_DOKUMENTE_SUCHEN_WIZARD_DOKUMENT_LOESCHEN_ERFOLGREICH_{{titel}}_",
            extra: {
              titel: item.dokument.dok_titel
            }
          });
          EventBus.$emit(`updateTable${ TABLE_ID }`);
        },
        error => {
          if (error.status === 400) {
            this.addNotification({
              text: error.data || ERROR_DEFAULT_TEXT,
              type: "error",
            });
          }
        }
      ).then(
        () => {
          this.closeConfirm();
          this.loadingDeleteDocument[item.pk] = false;
        }
      );
    },

    closeConfirm() {
      this.confirmShow = false;
    },
  },
};
