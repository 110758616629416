import Modal from "../../../../global/components/Modal/Modal.vue";
import {
  cloneDeep,
  toString,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  components: {
    Modal,
  },
  props: {
    statusPeriodenAusgefuellt: {
      type: Boolean,
      required: true,
    },
    changeStatusPeriodenAusgefuellt: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      warningTexts: {
        helpText: "_TXT_ANTRAG_DETAILS_PERSONAL_WIZARD_WARNUNG_HELP_TEXT_",
        modalHeader: "_TXT_ANTRAG_DETAILS_PERSONAL_WIZARD_WARNUNG_CONFIRM_FENSTER_HEADER_",
        modalBody: `_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_WARNUNG_CONFIRM_FENSTER_BODY_`,
      },
      statusConfirmWarning: false,
      confirmWarningOptions: undefined,
      statusChangePeriodenDependencies: undefined,
      modelChangePeriodenTemp: undefined,
      modelClone: undefined,
    };
  },
  methods: {
    onFocusChangePeriod_mixin({ id } = {}) {
      if (this.statusPeriodenAusgefuellt) {
        this.modelChangePeriodenTemp = toString(this.model[id]);
        this.modelClone = cloneDeep(this.model);
      }
    },

    onBlurChangePeriod_mixin({ id } = {}) {
      if (!this.statusChangePeriodenDependencies) {
        return;
      }
      this.confirmWarningOptions = {
        okClass: "btn btn-primary",
        title: this.warningTexts.modalHeader,
        msg: this.warningTexts.modalBody,
        okLabel: "_BTN_YES_",
        okCallback: () => {
          this.closeConfirmBlurChangePeriod_mixin({ id, saveStatus: true });
        },
        cancelCallback: () => this.closeConfirmBlurChangePeriod_mixin({ id, saveStatus: false }),
        loading: false,
      };
      this.statusConfirmWarning = true;
    },

    closeConfirmBlurChangePeriod_mixin({ id, saveStatus } = {}) {
      if (saveStatus) {
        this.changeStatusPeriodenAusgefuellt({ status: false });
      } else {
        this.model[id] = this.modelChangePeriodenTemp;
        if (isFunction(this.initModelInParent)) {
          this.initModelInParent({ model: cloneDeep(this.modelClone) });
        }
        this.modelChangePeriodenTemp = undefined;
        this.modelClone = undefined;
        if (this.changeJahresbrutto) {
          this.changeJahresbrutto({ id });
        }
      }
      this.fields[id].helpText = undefined;
      this.statusChangePeriodenDependencies = false;
      this.statusConfirmWarning = false;
    },

    onInputChangePeriod_mixin({ id }) {
      if (!this.statusPeriodenAusgefuellt || !id) {
        return;
      }
      const CURRENT_MODEL_STRING = toString(this.model[id]);
      if (CURRENT_MODEL_STRING === this.modelChangePeriodenTemp) {
        this.fields[id].helpText = undefined;
        this.statusChangePeriodenDependencies = false;
      } else {
        this.fields[id].helpText = this.warningTexts.helpText;
        this.statusChangePeriodenDependencies = true;
      }
    },
  },
};
