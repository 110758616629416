"use strict";

export default /*@ngInject*/ $compile => {
  return {
    restrict: "E",
    replace: true,
    link: function(scope, element, attrs) {
      scope._ = _;
      const unbindWatcher = scope.$watch(attrs.row, function() {
        const col = scope[attrs.col];
        // cellFilter funktionieren NUR beim Default-Template
        let filter = "";
        if (col.cellFilter) {
          filter = "|" + col.cellFilter;
        }
        const defaultTemplate = "{{ row." + col.field + filter + "}}";
        let template = col.cellTemplate || defaultTemplate;
        if (col.linkToDetail) {
          template = "<a ui-sref='{{vm.getDetailRoute(row)}}' ng-click='$event.stopPropagation()'>" + template + "</a>";
        }
        element.html(template);
        $compile(element.contents())(scope);
        unbindWatcher();
      });
    }
  };
};
