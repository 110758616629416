import ImageDND from "./ImageDND/ImageDND.vue";
import ModalCreateOrEdit from "./ModalCreateOrEdit/ModalCreateOrEdit.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";

import loading from "../../../../global/directives/loading";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

// @vue/component
export default {
  name: "FaqAdminImagesDND",
  components: {
    ImageDND,
    ModalCreateOrEdit,
    PuxButton,
    PuxCloak,
  },
  directives: {
    loading,
  },
  props: {
    allowedMimeType: {
      type: Array,
      default: () => [
        "png",
        "jpeg",
        "jpg",
      ],
    },
    apiPrefix: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      getListHttp,
    } = HttpAPI();

    return {
      getListHttp,
    };
  },
  data() {
    return {
      images: [],
      statusModal: undefined,
      statusLoading: true,
      statusReloading: undefined,
    };
  },
  created() {
    this.loadImages();
  },
  methods: {
    loadImages({ statusReloading } = {}) {
      if (statusReloading) {
        this.statusReloading = true;
      }
      this.getListHttp({
        url: `${ this.apiPrefix }/assets/`
      }).then(
        response => {
          this.images = response;
          this.statusLoading = false;
          this.statusReloading = false;
        }
      );
    },

    addImage({ image }) {
      this.images.unshift(image);
    },

    updateImages({ image, imageIndex, status }) {
      if (status === "delete") {
        this.images.splice(imageIndex, 1);
      } else if (status === "update") {
        this.images.splice(imageIndex, 1, image);
      }
    },

    openModalCreate() {
      this.statusModal = true;
    },

    closeModalCreate({ create, response } = {}) {
      if (create) {
        this.addImage({ image: response });
      }
      this.statusModal = false;
    },
  },
};
