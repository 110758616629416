"use strict";
import angular from "angular";

import DiasNotification from "dias/core/notification";

import parameter from "./parameter";

const diasBewertungen = angular.module("dias.bewertungen", [
  DiasNotification.name,
  parameter.name,
]);

export default diasBewertungen;
