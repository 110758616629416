var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :ctx.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "ctx.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in ctx.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<div class=\"modal-body height-400\"><div class=\"row\"><section class=\"main col-10 offset-1\"><div ng-attr-id=\"{{ ctx.DiasNotification.form.containerId }}\"></div><form><fieldset>");
jade_mixins["form-group"].call({
block: function(){
buf.push("<ui-select ng-model=\"ctx.model.tos_pos\" name=\"tos_pos\" aria-required=\"true\"><ui-select-match placeholder=\"--------\"><span ng-bind=\"$select.selected.label\"></span></ui-select-match><ui-select-choices repeat=\"item.value as item in (ctx.punkten)\"><span ng-bind=\"item.label\"></span></ui-select-choices></ui-select>");
}
}, "tos_pos", "Anfügen an Position *");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"to_tos_bez\" ng-model=\"ctx.model.tos_bez\" type=\"text\" name=\"to_tos_bez\" auto-focus=\"true\" aria-required=\"true\" ng-attr-aria-invalid=\"{{ctx.errors.tos_bez ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "tos_bez", "Name *");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"to_tos_bez\" ng-model=\"ctx.model.tos_info\" type=\"text\" name=\"to_tos_info\" maxlength=\"100\" ng-attr-aria-invalid=\"{{ctx.errors.tos_info ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "tos_info", "{{:: ctx.gettext('_LBL_SITZUNGSTAGESORDNUNG_TOS_INFO_') }} *");
buf.push("</fieldset><fieldset><label>Ausgewählte Module</label><div ng-if=\"ctx.module &amp;&amp; ctx.module.length &gt; 0\"><div ng-repeat-start=\"wahl in ctx.module\"><div class=\"clearfix form-control-static\">{{ $index + 1 }}. {{ wahl.ssm_bez }}<div class=\"btn-group float-right\"><button title=\"Nach oben schieben\" ng-click=\"ctx.moveUpModul($index)\" class=\"btn btn-default\"><i class=\"glyphicon glyphicon-chevron-up\">&nbsp;</i><span class=\"sr-only\">Nach oben schieben</span></button><button title=\"Nach unten schieben\" ng-click=\"ctx.moveDownModul($index)\" class=\"btn btn-default\"><i class=\"glyphicon glyphicon-chevron-down\">&nbsp;</i><span class=\"sr-only\">Nach unten schieben</span></button><button title=\"Modul entfernen\" ng-click=\"ctx.removeModul($index)\" class=\"btn btn-primary\"><i class=\"glyphicon glyphicon-trash\">&nbsp;</i><span class=\"sr-only\">Modul entfernen</span></button></div></div></div><hr ng-repeat-end></div><p ng-if=\"!ctx.module || ctx.model.length === 0\" class=\"form-control-static\">Keine Module ausgewählt</p>");
jade_mixins["form-group"].call({
block: function(){
buf.push("<ui-select ng-model=\"ctx.selectedModul\" name=\"module\"><ui-select-match placeholder=\"--------\"><span ng-bind=\"$select.selected.ssm_bez\"></span></ui-select-match><ui-select-choices repeat=\"item in ctx.filtered_ssm\"><span ng-bind=\"item.ssm_bez\"></span></ui-select-choices></ui-select>");
}
}, "module", "Modulauswahl");
buf.push("<button type=\"submit\" ng-click=\"ctx.addModul()\" ng-disabled=\"ctx.modulInputMissing()\" class=\"btn btn-default float-right\"><i class=\"glyphicon glyphicon-plus\">&nbsp;</i><span>Modul hinzufügen</span></button></fieldset></form></section></div></div><div class=\"modal-footer\"><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"exitWithCallback(ctx.save)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"exit()\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-default\"><span>Abbrechen</span></button></div></div></div>");;return buf.join("");
}