import {
  inject,
  onMounted,
  ref,
  toRefs,
  watch,
} from "vue";

import SitzungDetailsTagesordnungSortingItem from "./SitzungDetailsTagesordnungSortingItem/SitzungDetailsTagesordnungSortingItem.vue";

import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";

import { cloneDeep } from "lodash-es";

// @vue/component
export default {
  name: "SitzungDetailsTagesordnungSorting",
  components: {
    SitzungDetailsTagesordnungSortingItem,
  },
  props: {
    sitzung: {
      type: Object,
      required: true,
    },
    tagesordnung: {
      type: Array,
      default: () => [],
    },
    topsUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "onErrors",
  ],
  setup(props) {
    const {
      tagesordnung,
    } = toRefs(props);

    const tagesordnungModel = ref([]);

    const setModel = () => {
      tagesordnungModel.value = cloneDeep(tagesordnung.value);
    };

    const {
      putHttp,
    } = HttpAPI();

    const reloadTagesordnung = inject("reloadTagesordnung");
    const toggleLoadingTagesordnung = inject("toggleLoadingTagesordnung");

    onMounted(setModel);
    watch(tagesordnung, setModel);

    return {
      putHttp,
      reloadTagesordnung,
      tagesordnungModel,
      toggleLoadingTagesordnung,
    };
  },
  data() {
    return {
      statusChanges: {
        movedDown: undefined,
        movedUp: undefined,
        lastChange: undefined,
      },
      loading: false,
      draggedItem: {},
      draggedItemIndex: undefined,
      draggedItemParentIndex: undefined,
      sortingStatusLevel2: false,
      dragover: {
        pk: undefined,
        clientX: undefined,
        clientY: undefined,
        placeholderTop: false,
        placeholderBottom: false,
        unterpunkt: false,
      },
    };
  },
  computed: {

  },
  methods: {
    movePunktUp({ punkt, index, parentIndex = undefined }) {
      if (parentIndex !== undefined) {
        this.tagesordnungModel[parentIndex].unterpunkte.splice(index, 1);
        this.tagesordnungModel[parentIndex].unterpunkte.splice(index - 1, 0, punkt);
      } else {
        this.tagesordnungModel.splice(index, 1);
        this.tagesordnungModel.splice(index - 1, 0, punkt);
      }
      this.saveChanges({ punkt, operation: "movedUp" });
    },

    movePunktDown({ punkt, index, parentIndex = undefined }) {
      if (parentIndex !== undefined) {
        this.tagesordnungModel[parentIndex].unterpunkte.splice(index, 1);
        this.tagesordnungModel[parentIndex].unterpunkte.splice(index + 1, 0, punkt);
      } else {
        this.tagesordnungModel.splice(index, 1);
        this.tagesordnungModel.splice(index + 1, 0, punkt);
      }
      this.saveChanges({ punkt, operation: "movedDown" });
    },

    saveChanges({ punkt, operation }) {
      this.loading = true;
      let position = null;
      let oberpunkt = null;
      for (let i = 0; i < this.tagesordnungModel.length; i++) {
        if (this.tagesordnungModel[i].pk === punkt.pk) {
          position = i + 1;
          break;
        }
        if (this.tagesordnungModel[i].unterpunkte.length > 0) {
          for (let j = 0; j < this.tagesordnungModel[i].unterpunkte.length; j++) {
            if (this.tagesordnungModel[i].unterpunkte[j].pk === punkt.pk) {
              position = j + 1;
              oberpunkt = this.tagesordnungModel[i].pk;
              break;
            }
          }
        }
      }
      const DATA = {
        pk: punkt.pk,
        tos_bez: punkt.tos_bez,
        tos_info: punkt.tos_info,
        tos_pos: position,
        oberpunkt: oberpunkt,
        top: punkt.top
      };
      this.updatePositionServer({ punkt, data: DATA, operation });
    },

    resetStatusChanges() {
      this.statusChanges = {
        movedDown: undefined,
        movedUp: undefined,
        lastChange: Date.now(),
      };
    },

    updatePositionServer({ punkt, data, operation }) {
      this.toggleLoadingTagesordnung();
      return this.putHttp({
        url: `${ this.topsUrl }${ punkt.pk }/`,
        data: data,
      }).then(
        () => {
          this.reloadTagesordnung().then(
            () => {
              if (operation !== undefined) {
                this.resetStatusChanges();
                this.statusChanges[operation] = punkt.pk;
              }
            },
          );
          return Promise.resolve();
        },
        errors => {
          this.$emit("onErrors", { errorsData: errors });
          return Promise.reject(errors);
        }
      ).finally(
        () => this.loading = false,
      );
    },

    cutItemFromTagesordnung() {
      if (this.draggedItemParentIndex !== undefined) {
        this.tagesordnungModel[this.draggedItemParentIndex].unterpunkte.splice(this.draggedItemIndex, 1);
      } else {
        this.tagesordnungModel.splice(this.draggedItemIndex, 1);
      }
    },

    addItemToTagesordnung({ punkt, index, parentIndex = undefined }) {
      if (parentIndex !== undefined) {
        this.tagesordnungModel[parentIndex].unterpunkte.splice(index, 0, punkt);
      } else {
        this.tagesordnungModel.splice(index, 0, punkt);
      }
      this.saveChanges({ punkt });
    },

    dragoverScroll(event) {
      const windowHeight = $(window).height();
      const scrollTop = $(window).scrollTop();

      if (event.screenY + 20 > windowHeight) {
        $(window).scrollTop(scrollTop + 5);
      } else if (event.screenY - 20 < windowHeight / 2) {
        $(window).scrollTop(scrollTop - 5);
      }
    },

    onDrag(event) {
      if (event.clientY > this.dragover.clientY) {
        this.dragover.placeholderBottom = true;
        this.dragover.placeholderTop = false;
      } else {
        this.dragover.placeholderBottom = false;
        this.dragover.placeholderTop = true;
      }
      this.dragover.unterpunkt = event.clientX > this.dragover.clientX;
    },

    dragStart(event, item, index, parentIndex) {
      event.stopPropagation();
      this.draggedItem = item;
      this.draggedItemIndex = index;
      this.draggedItemParentIndex = parentIndex;
      this.sortingStatusLevel2 = !!(item.unterpunkte && item.unterpunkte.length > 0);
      setTimeout(function() {
        event.target.style.display = "none";
      });
    },

    onDragEnd(event) {
      this.dragover.pk = undefined;
      this.draggedItem = {};
      event.target.style.display = "";
    },

    onDragEnter(event, item) {
      event.stopPropagation();
      this.dragover.pk = item.pk;
      this.dragover.clientX = event.clientX;
      this.dragover.clientY = event.clientY;
    },

    onDragleave(event) {
      event.preventDefault();
      event.stopPropagation();
    },

    onDragOver(event) {
      event.preventDefault();
    },

    onDrop(event, item, index, parentIndex = undefined) {
      event.preventDefault();
      event.stopPropagation();

      if (parentIndex !== undefined && this.sortingStatusLevel2) {
        // no way
      } else {
        const DROPPED_ITEM_INDEX = cloneDeep(this.draggedItemIndex);
        const DROPPED_ITEM_PARENT_INDEX = cloneDeep(this.draggedItemParentIndex);
        const DROPPED_ITEM = cloneDeep(this.draggedItem);
        this.cutItemFromTagesordnung();
        let indexAdd = 0;
        let indexParentAdd = undefined;

        if (parentIndex === undefined) {
          if (this.dragover.unterpunkt && this.dragover.placeholderBottom) {
            if (DROPPED_ITEM_PARENT_INDEX === undefined) {
              indexParentAdd = DROPPED_ITEM_INDEX < index ? index - 1 : index;
              indexAdd = 0;
            } else {
              indexParentAdd = index;
              indexAdd = 0;
            }
          } else {
            if (DROPPED_ITEM_PARENT_INDEX === undefined) {
              if (DROPPED_ITEM_INDEX < index) {
                indexAdd = this.dragover.placeholderTop ? index - 1 : index;
              } else {
                indexAdd = this.dragover.placeholderTop ? index : index + 1;
              }
            } else {
              indexAdd = this.dragover.placeholderTop ? index : index + 1;
            }
          }
        } else {
          if (DROPPED_ITEM_PARENT_INDEX === undefined) {
            indexParentAdd = DROPPED_ITEM_INDEX < parentIndex ? parentIndex - 1 : parentIndex;
            indexAdd = this.dragover.placeholderTop ? index : index + 1;
          } else {
            indexParentAdd = parentIndex;
            if (DROPPED_ITEM_PARENT_INDEX === parentIndex) {
              if (DROPPED_ITEM_INDEX < index) {
                indexAdd = this.dragover.placeholderTop ? index - 1 : index;
              } else {
                indexAdd = this.dragover.placeholderTop ? index : index + 1;
              }
            } else {
              indexAdd = this.dragover.placeholderTop ? index : index + 1;
            }
          }
        }

        this.addItemToTagesordnung({
          punkt: DROPPED_ITEM,
          index: indexAdd,
          parentIndex: indexParentAdd,
        });
      }
    },
  },
};
