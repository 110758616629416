import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import ShowMore from "../../../../global/components/ui/ShowMore/ShowMore.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import ModuleMixin from "../ModuleMixin";

import {
  cloneDeep,
  find,
  forEach,
  get,
  isEmpty,
  isNil,
  map,
  orderBy,
} from "lodash-es";

// @vue/component
export default {
  name: "Bestaetigung",
  components: {
    FormElement,
    FormElementReadOnly,
    ShowMore,
    SnapshotIcon,
    SnapshotModule,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      modelEdit: {},
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.open;
      OPTIONS.required = !isNil(find(this.formFields, "required"));
      return OPTIONS;
    },

    beschreibung() {
      return this.modelParameter.beschreibung;
    },

    beschreibungMaxHeight() {
      return this.modelParameter.beschreibung_max_hoehe;
    },

    aussagenConf() {
      return this.modelParameter.aussagen;
    },

    useShowMore() {
      return !isNil(this.modelParameter.beschreibung_max_hoehe);
    },

    formFields() {
      const FIELDS = map(orderBy(this.aussagenConf, ["pos"]), conf => {
        return {
          id: conf.id,
          htmlId: `${ this.htmlRegelNummer }_${ conf.id }`,
          label: conf.text,
          required: conf.pflicht,
          type: "one_checkbox",
          change: this.updateEditModel
        };
      });
      return FIELDS;
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(this.formFields, item => {
          const KEY = item.key || item.id;
          if (get(this.model, KEY) !== get(this.snapshotModule, KEY)) {
            DIFF[KEY] = true;
          }
        });
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },

    errorsLabelsOptions() {
      return this.formFields;
    },
  },
  methods: {
    open() {
      this.modelEdit = cloneDeep(this.model) || {};
    },

    updateEditModel({ model, id }) {
      const MODEL = cloneDeep(this.modelEdit);
      MODEL[id] = model;
      this.modelEdit = MODEL;
    },

    setLocalErrorsFromGlobal({ errors }) {
      this.errors = errors;
    },
  },
};
