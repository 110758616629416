"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import "restangular";
import models from "./models";
import ngAnimate from "angular-animate";

const faqModule = angular.module("dias.faq", [
  ngAnimate,
  uiRouter,
  models.name
]);

faqModule.config($stateProvider => {
  $stateProvider

    .state("root.faqs", {
      url: `/faqs/?
            rubric&
            keyword&
            search&
            media_type`,
      views: {
        "main@root": {
          template: "<div vueinit><faq-details></faq-details></div>",
        }
      },
      data: {
        // permissions: ["faq.rubrik.view"]
      }
    })
    .state("root.adminfaqs", {
      url: `/admin-faqs/?
            user_cookie&
            rubric&
            keyword&
            search&
            media_type
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-faq-admin-list></vue-faq-admin-list></div>"
        }
      },
      data: {
        permissions: ["faq.rubrik.create"]
      }
    })
    .state("root.adminfaqs.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><faq-admin-details></faq-admin-details></div>"
        }
      }
    })
  ;
});

export default faqModule;
