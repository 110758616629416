import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import {
  cloneDeep,
  isUndefined,
  forEach,
  get,
  isEmpty,
} from "lodash-es";

const SNAPSHOT_CHANGES_LIST = [
  "antwort",
  "text",
];

// @vue/component
export default {
  name: "AuswahlMitText",
  components: {
    SnapshotModule,
    SnapshotIcon,
    FormElement,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      modelEdit: {
        antwort: "n",
        text: undefined,
      },
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.open;
      return OPTIONS;
    },

    optionsAntwort() {
      const ID = "antwort";
      return {
        id: ID,
        htmlId: `${ this.htmlRegelNummer }_${ ID }`,
        type: "boolean",
        label: "_LBL_GR_AUSWAHL_MIT_TEXT_ANTWORT_",
        translate: true,
        required: true,
        view: "v-alt",
        trueValue: "j",
        falseValue: "n",
        startupFocus: true,
      };
    },

    optionsText() {
      const ID = "text";
      return {
        id: ID,
        htmlId: `${ this.htmlRegelNummer }_${ ID }`,
        type: "textarea",
        label: this.modelParameter.text_label,
        view: "v-alt",
        required: true,
      };
    },

    labelHeader() {
      return this.modelParameter.frage;
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(SNAPSHOT_CHANGES_LIST, key => {
          if (get(this.model, key) !== get(this.snapshotModule, key)) {
            DIFF[key] = true;
          }
        });
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },

    errorsLabelsOptions() {
      return [
        this.optionsAntwort,
        this.optionsText,
      ];
    },
  },
  methods: {
    prepareModel() {
      this.model = cloneDeep(this.module.data) || {};
    },

    open() {
      const MODEL = cloneDeep(this.model);
      if (isUndefined(MODEL.antwort)) {
        MODEL.antwort = "n";
      }
      this.modelEdit = MODEL;
    },

    setLocalErrorsFromGlobal({ errors }) {
      this.errors = errors;
    },
  },
};
