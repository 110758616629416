import {
  provide,
} from "vue";

import AnonymisierungPlan from "../../Anonymisierung/AnonymisierungPlan/AnonymisierungPlan.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SitzungsartInfo from "./SitzungsartInfo/SitzungsartInfo.vue";
import SitzungsartTagesordnung from "./SitzungsartTagesordnung/SitzungsartTagesordnung.vue";

import SitzungsartAPI from "./compositionAPI/SitzungsartAPI";
import SitzungsartNotGroupedAPI from "./compositionAPI/SitzungsartNotGroupedAPI";
import SitzungsartSitzungsmoduleAPI from "./compositionAPI/SitzungsartSitzungsmoduleAPI";
import SitzungsartTagesordnungspunkteAPI from "./compositionAPI/SitzungsartTagesordnungspunkteAPI";


// @vue/component
export default {
  name: "SitzungsartDetails",
  components: {
    AnonymisierungPlan,
    ContextPanel,
    Permission,
    PuxPageDetails,
    PuxTranslate,
    SitzungsartInfo,
    SitzungsartTagesordnung,
  },
  setup() {
    const {
      loadingSitzungsart,
      loadSitzungsart,
      sitzungsart,
      sitzungsartUrl,
    } = SitzungsartAPI();

    const {
      loadingTagesordnungspunkte,
      loadTagesordnungspunkte,
      tagesordnungspunkte,
      tagesordnungspunkteUrl,
      updateTagesordnungspunkt,
    } = SitzungsartTagesordnungspunkteAPI({
      sitzungsartUrl,
    });

    const {
      loadingSitzungsmodule,
      loadSitzungsmodule,
      sitzungsmoduleKeyByPk,
    } = SitzungsartSitzungsmoduleAPI();

    const {
      hasPermissionSitzungsartenAdminAnonymisierungsplanUpdate,
    } = SitzungsartNotGroupedAPI();

    loadSitzungsart().then(
      () => loadTagesordnungspunkte()
    );
    loadSitzungsmodule();

    provide("updateTagesordnungspunkt", updateTagesordnungspunkt);

    return {
      loadingSitzungsart,
      sitzungsart,
      sitzungsartUrl,

      loadingTagesordnungspunkte,
      tagesordnungspunkte,
      tagesordnungspunkteUrl,

      loadingSitzungsmodule,
      sitzungsmoduleKeyByPk,

      hasPermissionSitzungsartenAdminAnonymisierungsplanUpdate,
    };
  },
};
