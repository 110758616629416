"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";
import template from "./config.jade";
import templateRo from "./readonly.jade";
import controller from "./controller";


const ampel = angular.module("dias.ampel.parameter", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("ampel", "parametermodul-ampel");
  })
  .component("parametermodulAmpel", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulAmpelRo", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: templateRo(),
    controller: () => {},
    controllerAs: "vm",
  });
export default ampel;
