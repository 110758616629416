import {
  computed,
  getCurrentInstance,
  ref,
} from "vue";

import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

export default function AntragViewAPI({ antrag, antragPk, antragUrl }) {
  const viewName = ref("details");
  const iconForChangeView = computed(() => {
    return viewName.value === "timeline" ? "antrag" : "time";
  });
  const textTranslateForChangeView = computed(() => {
    return viewName.value === "timeline" ?
      "_BTN_ANTRAG_ANZEIGEN_" :
      "_BTN_VERLAUF_ANZEIGEN_";
  });
  const {
    checkPermissionsSync,
  } = PermissionAPI();
  const hasWFPermissionAntragregelsatzwechselPerform = computed(() => {
    return checkPermissionsSync({
      perm: "antragregelsatzwechsel.perform",
      permArray: antrag.value.user_permissions,
    });
  });
  const openChangeAngebot = () => {
    viewName.value = "wizard";
  };
  const changeViewName = () => {
    if (viewName.value === "timeline") {
      viewName.value = "details";
    } else if (viewName.value === "details") {
      viewName.value = "timeline";
    }
  };
  const APP = getCurrentInstance();
  const $goTo = APP.appContext.config.globalProperties.$goTo;
  const closeWizard = ({ statusReload } = {}) => {
    if (statusReload) {
      $goTo("root.foerderantrag.details", { id: antragPk }, { reload: "root.foerderantrag.details" });
    } else {
      viewName.value = "details";
    }
  };
  const timelineUrl = computed(() => {
    return `${ antragUrl.value }timeline/`;
  });
  const timelineConfig = computed(() => {
    return {
      kontexte: [
        {
          titel: "Antragstellung",
          key: "A",
        },
        {
          titel: "Antragsprüfung",
          key: "F",
        },
      ],
      benachrichtigungen: {
        bezug: "antrag",
        bezugpk: antragPk,
        autorefresh: true,
      },
      url: timelineUrl.value,
    };
  });
  const hasWFPermissionFoerderantragVerlaufView = computed(() => {
    return checkPermissionsSync({
      perm: "foerderantrag.verlauf.view",
      permArray: antrag.value.user_permissions,
    });
  });

  return {
    changeViewName,
    closeWizard,
    hasWFPermissionAntragregelsatzwechselPerform,
    hasWFPermissionFoerderantragVerlaufView,
    iconForChangeView,
    openChangeAngebot,
    textTranslateForChangeView,
    timelineConfig,
    timelineUrl,
    viewName,
  };
}
