"use strict";

class FoerdermoduleRegistryProvider {
  constructor() {
    this.name = "FoerdermoduleRegistryProvider";
    this.registeredModules = {};
  }

  register(key, directiveName) {
    // directiveName=null => Modul mit key bekannt, aber keine Komponente zum Rendern
    if (this.registeredModules[key]) {
      console.warn("Fördermodul " + key + " wurde mehrmals registriert:", [this.registeredModules[key], directiveName]);
      return;
    }
    this.registeredModules[key] = directiveName;
  }

  unregister(key) {
    delete this.registeredModules[key];
    console.info("Fördermodul " + key + " deregistriert.");
  }

  /*@ngInject*/
  $get() {
    return {
      getModule: key => {
        return this.registeredModules[key];
      },
      getModuleList: () => {
        const list = [];
        for (const key in this.registeredModules) {
          list.push({ key: key, directive: this.registeredModules[key] });
        }
        return list;
      }
    };
  }
}

export default FoerdermoduleRegistryProvider;
