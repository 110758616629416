"use strict";

import template from "./foerderorganisation.kontakt.box.jade";

class FoerderorganisationKontaktBoxController {
  /*@ngInject*/
  constructor() {
    this.emailTypId = "27377530-c4b0-4697-866b-3e0dc314f5b7";
    this.urlTypId = "255ee9e6-e26d-7544-e053-0fd5a8c09460";
  }
}

export default {
  template: template(),
  controller: FoerderorganisationKontaktBoxController,
  controllerAs: "vm",
  bindings: {
    org: "<",
    label: "<",
    link: "<"
  }
};
