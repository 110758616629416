import ModuleMixin from "../ModuleMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import {
  cloneDeep,
  get,
  find,
  isFunction,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragstellerSichtbarkeit",
  components: {
    SnapshotModule,
    SnapshotIcon,
  },
  mixins: [
    ModuleMixin,
    PermissionMixin,
  ],
  data: () => {
    return {
      anzeigestatusChoices: []
    };
  },
  computed: {
    translationNoneSelected() {
      return {
        text: "_TXT_GR_ANTRAGSTELLER_SICHTBARKEIT_NONE_SELECTED_"
      };
    },

    translationAnzeigestatusLabel() {
      return {
        text: "_LBL_GR_ANTRAGSTELLER_SICHTBARKEIT_ANZEIGESTATUS_"
      };
    },

    moduleUrl() {
      return `${ this.baseUrl }anzeigestatus_aendern/`;
    },

    isModuleEditable() {
      return !this.statusReadonly && this.checkPermissionsSync({ perm: "antragstellerorgsperrung.update", permArray: get(this.obj, "user_permissions") });
    },

    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.list = [
        {
          id: "anzeigestatus",
          type: "radio",
          htmlId: `${ this.htmlRegelNummer }_anzeigestatus`,
          label: "_LBL_GR_ANTRAGSTELLER_SICHTBARKEIT_ANZEIGESTATUS_",
          translate: true,
          required: true,
          editonly: true,
          view: "v-alt",
          startupFocus: true,
          data: this.anzeigestatusChoices,
          keyLabel: "azs_kbez",
          keyId: "pk",
        },
      ];
      return OPTIONS;
    },

    labelHeader() {
      return "_TXT_GR_ANTRAGSTELLER_SICHTBARKEIT_HEADER_";
    },

    statusDiff() {
      return this.snapshotModule && get(this.module.data, "anzeigestatus.pk") !== get(this.snapshotModule, "anzeigestatus.pk");
    },

  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadAnzeigestatus();
    },

    prepareModel() {
      const AZS_ID = get(this.module, "data.anzeigestatus.pk", undefined);
      if (AZS_ID && !find(this.anzeigestatusChoices, { pk: AZS_ID })) {
        this.anzeigestatusChoices.push(this.module.data.anzeigestatus);
      }
      this.model = { anzeigestatus: AZS_ID };
    },

    save({ model, childObj } = {}) {
      let data = this.modelEdit && size(this.modelEdit) ? cloneDeep(this.modelEdit) : cloneDeep(model);
      if (isFunction(this.prepareToSave)) {
        data = this.prepareToSave({ data });
      }
      this.errors = undefined;
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.moduleUrl,
          data,
        }).then(
          response => {
            const MODULE = cloneDeep(this.module);
            MODULE.data = {
              anzeigestatus: cloneDeep(get(response, "anzeigestatus"))
            };
            this.onRestFunctions({ response: MODULE });
            resolve(MODULE);
          },
          errors => {
            reject(this.onError({ errors, childObj }));
          }
        );
      });
    },

    anzeigestatusLabel(item) {
      const PK = get(item, "anzeigestatus.pk");
      if (PK) {
        const EL = find(this.anzeigestatusChoices, el => el.pk === PK);
        return get(EL, "azs_kbez");
      }
    },

    loadAnzeigestatus() {
      this.loading = true;
      this.getListHttp({
        url: "katalog/?key=anzeigestatus",
      }).then(
        response => {
          this.anzeigestatusChoices = cloneDeep(response);
          this.loading = false;
        }
      );
    },
  },
};
