"use strict";

import "restangular";

import angular from "angular";
import Component from "./component";
import models from "./models";

const Module = angular.module("check-temp-antraege", [
  models.name,
])

  .component("checkTempAntraege", Component);

export default Module;
