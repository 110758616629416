import NotizCreateOrEditModal from "../NotizCreateOrEditModal/NotizCreateOrEditModal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotizenReadonlyMixin from "../../NotizenReadonlyMixin";

import { EventBus } from "../../../../global/functions/event-bus";
import { createNamespacedHelpers } from "vuex";
const {
  mapMutations,
} = createNamespacedHelpers("notizen");

// @vue/component
export default {
  name: "NotizModalReadonly",
  components: {
    NotizCreateOrEditModal,
    PuxButton,
  },
  mixins: [
    HttpMixin,
    NotizenReadonlyMixin,
  ],
  props: {
    user: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    singlenotiz: {
      type: Boolean,
    },
    copyNotiz: {
      type: Function,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      statusEdit: undefined,
      confirmShow: undefined,
      confirmOptions: undefined,
    };
  },
  computed: {
    statusCanEdit() {
      return (this.options.orgList.length || this.options.antragstellerorg) && this.notiz.autor.pk === this.user.pk;
    },

    htmlIdEdit() {
      return `notiz_edit_${ this.notiz.pk }`;
    },

    htmlIdDelete() {
      return `notiz_delete_${ this.notiz.pk }`;
    },

    selectorCloseEdit() {
      return `#${ this.htmlIdEdit }`;
    },

    selectorCloseDelete() {
      return [
        `#${ this.htmlIdDelete }`,
        "#modal_0",
      ];
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../global/components/Modal/Modal.vue").default;
  },
  methods: {
    editNotiz() {
      this.statusEdit = true;
    },

    closeCreateNotiz() {
      this.statusEdit = false;
    },

    copyNotizLocal() {
      this.copyNotiz({ notiz: this.notiz });
    },

    confirmDeleteNotiz() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_NOTIZEN_MODAL_DELETE_HEADER_",
        msg: "_TXT_NOTIZEN_MODAL_DELETE_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: this.deleteNotiz,
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteNotiz() {
      const NO_KEY = this.notiz.no_key;
      this.confirmOptions.loading = true;
      this.deleteHttp({ url: `notizen/${ this.notiz.pk }/` }).then(
        () => {
          this.addNotification({ text: "_MSG_NOTIZEN_MODAL_DELETE_SUCCESS_" });
          this.closeConfirm();
          // Modalen Dialog schließen, wenn es eine Einzelbearbeitung ist:
          if (this.singlenotiz) {
            this.closeModal();
          }
          this.DELETE_NOTIZ({ notiz: this.notiz });
          EventBus.$emit("updateNotizen", { no_key: NO_KEY });
        },
        () => {
          this.addNotification({ text: "_MSG_NOTIZEN_MODAL_DELETE_ERROR_", type: "error" });
        }
      ).then(
        () => {
          this.confirmOptions.loading = false;
        }
      );
    },

    closeConfirm() {
      this.confirmShow = false;
    },

    ...mapMutations([
      "DELETE_NOTIZ",
    ]),
  },
};
