var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-element"] = jade_interp = function(label, fieldName, errorsMap, prefix){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-class", "{'has-error': " + (errorsMap) + "['" + (fieldName) + "']}", true, true)) + (jade.cls(['form-group','row',attributes.class], [null,null,false])) + "><label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"col-form-label col-sm-3 text-right font-weight-bold required\"><span" + (jade.attr("ng-class", "{'required':vm.isRequired('" + (fieldName) + "')}", true, true)) + " class=\"titel\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span></label><div class=\"col-sm-9 col-xl-6\">");
block && block();
buf.push("<label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"help-block\"><ul" + (jade.attr("ng-if", "" + (errorsMap) + "['" + (fieldName) + "']", true, true)) + " class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errorsMap) + "['" + (fieldName) + "']", true, true)) + ">{{ err }}</li></ul></label></div></div>");
};
jade_mixins["not-required-form-element"] = jade_interp = function(label, fieldName, errorsMap, prefix){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-class", "{'has-error': " + (errorsMap) + "['" + (fieldName) + "']}", true, true)) + (jade.cls(['form-group','row',attributes.class], [null,null,false])) + "><label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"col-form-label col-sm-3 text-right font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span></label><div class=\"col-sm-9 col-xl-6\">");
block && block();
buf.push("<label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"help-block\"><ul" + (jade.attr("ng-if", "" + (errorsMap) + "['" + (fieldName) + "']", true, true)) + " class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errorsMap) + "['" + (fieldName) + "']", true, true)) + ">{{ err }}</li></ul></label></div></div>");
};




















jade_mixins["vorschau-daten"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-repeat", "(key, val) in " + (vorschau) + "", true, true)) + "><span ng-if=\"val\">{{key}}: {{ val }}</span></div>");
};






buf.push("<script type=\"text/ng-template\" id=\"lkzMatchTemplate.html\"><span ng-bind=\"vm.selected.lkz_bez\"></span></script><script type=\"text/ng-template\" id=\"lkzChoicesTemplate.html\"><span class=\"label-hidden\">Wählen Sie bitte das Land</span><span ng-bind=\"item.lkz_bez\"></span></script><script type=\"text/ng-template\" id=\"kategorieMatchTemplate.html\"><span ng-bind=\"vm.selected.fid_kbez\"></span></script><script type=\"text/ng-template\" id=\"kategorieChoicesTemplate.html\"><span class=\"label-hidden\">Wählen Sie bitte die Kategorie</span><span ng-bind=\"item.fid_kbez\"></span></script><script type=\"text/ng-template\" id=\"finanziererMatchTemplate.html\"><span ng-bind=\"vm.selected.kfi_bez\"></span></script><script type=\"text/ng-template\" id=\"finanziererChoicesTemplate.html\"><span class=\"label-hidden\">Wählen Sie bitte den Finanzierer</span><span ng-bind=\"item.kfi_bez\"></span></script><script type=\"text/ng-template\" id=\"statusMatchTemplate.html\"><span ng-bind=\"vm.selected.fis_bez\"></span></script><script type=\"text/ng-template\" id=\"statusChoicesTemplate.html\"><span class=\"label-hidden\">Wählen Sie bitte den Finanzierungstatus</span><span ng-bind=\"item.fis_bez\"></span></script><div ng-if=\"ctx.kontext === 'antrag'\"><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<dias-ui-select select-model=\"ctx.posmodel.argument.land\" select-required=\"true\" select-list=\"ctx.lkzChoices\" select-label-id=\"pk\" select-id=\"ctx.prefix + '_land'\" select-placeholder=\"'Wählen Sie das Land aus.'\" select-search=\"true\" search-label=\"'lkz_bez'\" select-choices-template=\"'lkzChoicesTemplate.html'\" select-match-template=\"'lkzMatchTemplate.html'\"></dias-ui-select>");
}
}, "Land", "land", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<dias-ui-select select-model=\"ctx.posmodel.argument.kategorie\" select-required=\"true\" select-list=\"ctx.finartdetails\" select-label-id=\"pk\" select-id=\"ctx.prefix + '_kategorie'\" select-placeholder=\"'Wählen Sie die Kategorie aus.'\" select-search=\"true\" search-label=\"'fid_kbez'\" select-choices-template=\"'kategorieChoicesTemplate.html'\" select-match-template=\"'kategorieMatchTemplate.html'\"></dias-ui-select>");
}
}, "Typ / Kategorie", "kategorie", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<dias-ui-select select-model=\"ctx.posmodel.argument.finanzierer\" select-required=\"true\" select-list=\"ctx.finanzierer\" select-label-id=\"pk\" select-id=\"ctx.prefix + '_finanzierer'\" select-placeholder=\"'Wählen Sie den Finanzierer aus.'\" select-search=\"true\" search-label=\"'kfi_bez'\" select-choices-template=\"'finanziererChoicesTemplate.html'\" select-match-template=\"'finanziererMatchTemplate.html'\"></dias-ui-select>");
}
}, "Finanzierer (Auswahl)", "finanzierer", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["not-required-form-element"].call({
block: function(){
buf.push("<input type=\"text\" maxlength=\"100\" placeholder=\"Freie Eingabe des Finanzierers\" ng-model=\"ctx.posmodel.argument.finanzierer_freitext\" name=\"finanzierer_freitext\" ng-change=\"ctx.onChangeFinanziererFreitext()\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_finanzierer_freitext\" ng-attr-aria-invalid=\"{{ctx.errors['finanzierer_freitext'] ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "{{ctx.gettext('Finanzierer (Freie Eingabe)')}}", "finanzierer_freitext", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" maxlength=\"14\" ng-model=\"ctx.posmodel.argument.wert\" name=\"wert\" dias-decimal-field aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_wert\" ng-attr-aria-invalid=\"{{ctx.errors['wert'] ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">&euro;</span></div></div>");
}
}, "Betrag in EUR", "wert", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<dias-ui-select select-model=\"ctx.posmodel.argument.status\" select-required=\"true\" select-list=\"ctx.finanzierungsstatus\" select-label-id=\"pk\" select-id=\"ctx.prefix + '_status'\" select-placeholder=\"'Wählen Sie den Finanzierungstatus aus.'\" select-search=\"true\" search-label=\"'kfi_bez'\" select-choices-template=\"'statusChoicesTemplate.html'\" select-match-template=\"'statusMatchTemplate.html'\"></dias-ui-select>");
}
}, "Finanzierungstatus", "status", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["not-required-form-element"].call({
block: function(){
buf.push("<input type=\"text\" ng-model=\"ctx.posmodel.argument.statusdatum\" placeholder=\"Datum im Format DD.MM.JJJJ eingeben\" name=\"statusdatum\" bs-datepicker aria-required=\"false\" ng-attr-id=\"{{ctx.prefix}}_statusdatum\" ng-attr-aria-invalid=\"{{ctx.errors['statusdatum'] ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "{{ctx.gettext('Datum der Bestätigung/Entscheidung')}}", "statusdatum", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["not-required-form-element"].call({
block: function(){
buf.push("<input type=\"text\" maxlength=\"100\" ng-model=\"ctx.posmodel.argument.bemerkung\" name=\"bemerkung\" aria-required=\"false\" ng-attr-id=\"{{ctx.prefix}}_bemerkung\" ng-attr-aria-invalid=\"{{ctx.errors['bemerkung'] ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "{{ctx.gettext('Bemerkung')}}", "bemerkung", "ctx.errors", "ctx.prefix");
buf.push("</div></div><div ng-if=\"ctx.kontext == 'beleg'\">");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" maxlength=\"14\" ng-model=\"ctx.posmodel.argument.wert\" name=\"wert\" dias-decimal-field aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_wert\" ng-attr-aria-invalid=\"{{ctx.errors['wert'] ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">&euro;</span></div></div>");
}
}, "Betrag in EUR", "wert", "ctx.errors", "ctx.prefix");
buf.push("</div>");;return buf.join("");
}