import AntraegeGeloeschteListPreview from "../AntraegeGeloeschteListPreview/AntraegeGeloeschteListPreview.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "geloeschte_antraege";

// @vue/component
export default {
  name: "AntraegeGeloeschteList",
  components: {
    AntraegeGeloeschteListPreview,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [],
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
        },
      },
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },
  },
};
