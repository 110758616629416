import HttpMixin from "../../../../../../global/mixins/HttpMixin";

import Highcharts from "highcharts";

// @vue/component
export default {
  name: "BerichtStandardCharts",
  mixins: [
    HttpMixin,
  ],
  props: {
    berichte: {
      type: Array,
      default: () => [],
    },
    updateCurrentBericht: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: {
        global: true,
        orgs: true,
        currentChart: false
      },
      chartIdActive: undefined,
    };
  },
  computed: {
    getSrc() {
      return index => `/static/img/chart/chart${ index + 1 }.png`;
    },
  },
  methods: {
    loadChart(index) {
      this.loading.currentChart = true;
      this.chartIdActive = this.berichte[index].bsq_id;
      if (!this.berichte[index].highcharts_definition) {
        this.loadCurrentChart(index);
      } else {
        this.showChart(index);
      }
    },

    loadCurrentChart(index) {
      this.getHttp({
        url: `berichte/standard/${ this.berichte[index].bsq_id }/`,
      }).then(
        response => {
          this.updateCurrentBericht({ response, index });
          this.showChart(index);
        }
      );
    },

    showChart(index) {
      const DATA = this.berichte[index].highcharts_definition;
      DATA.lang = {
        decimalPoint: ",",
        thousandsSep: "."
      };
      Highcharts.chart("container-chart", DATA);
      this.loading.currentChart = false;
    },
  },
};
