import FormstepDetail from "../../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import FormElement from "../../../../../../global/components/FormElement/FormElement.vue";
import WizardStepsErrorsMixin from "../../WizardStepsErrorsMixin";
import WizardStepsRechnerMixin from "../../WizardStepsRechnerMixin";
import WizardStepsFieldsChangeWarningMixin from "../../WizardStepsFieldsChangeWarningMixin";
import WizardStepsPeriodenMixin from "../../WizardStepsPeriodenMixin";
import fields from "./fields";
import translate from "../../../../../../global/directives/translate";
import WizardStepDHWMixin from "../../WizardStepDHWMixin";
import {
  cloneDeep,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenPeriodenWizardStep2DHW",
  components: {
    FormElement,
    FormstepDetail,
  },
  directives: {
    translate,
  },
  mixins: [
    WizardStepsErrorsMixin,
    WizardStepsRechnerMixin,
    WizardStepsFieldsChangeWarningMixin,
    WizardStepDHWMixin,
    WizardStepsPeriodenMixin,
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: cloneDeep(fields),
      errorLabels: {},
    };
  },
  computed: {
    getStellenumfangProjektNumber() {
      return this.getNumber({ item: this.model.stellenumfang_projekt });
    },

    getANBruttogehaltFuerPersonImProjekt() {
      const JAHRESBRUTTO = this.getJahresbruttoNumber;
      if (isUndefined(JAHRESBRUTTO)) {
        return undefined;
      }

      const STELLENUMFANG_PROJEKT = this.getStellenumfangProjektNumber;
      if (isUndefined(STELLENUMFANG_PROJEKT)) {
        return undefined;
      }

      const VOLLZEITSTELLE = this.getVollzeitstelleNumber;
      if (isUndefined(VOLLZEITSTELLE)) {
        return undefined;
      }
      return JAHRESBRUTTO * STELLENUMFANG_PROJEKT / VOLLZEITSTELLE;
    },

    getAGBruttogehaltVollzeitstelle() {
      const A_N_BRUTTOGEHALT_FUER_PERSON_IM_PROJEKT = this.getANBruttogehaltFuerPersonImProjekt;
      if (isUndefined(A_N_BRUTTOGEHALT_FUER_PERSON_IM_PROJEKT)) {
        return undefined;
      }
      const PKT_PAUSCHALE = this.getPktPauschaleNumber;
      return A_N_BRUTTOGEHALT_FUER_PERSON_IM_PROJEKT * (1 + PKT_PAUSCHALE / 100);
    },

    zeitraum_von() {
      const OPTIONS = cloneDeep(this.fields.zeitraum_von);
      OPTIONS.notAfter = this.model.zeitraum_bis;
      OPTIONS.notBefore = this.durchfuehrungszeitraumVon;
      return OPTIONS;
    },

    zeitraum_bis() {
      const OPTIONS = cloneDeep(this.fields.zeitraum_bis);
      OPTIONS.notAfter = this.durchfuehrungszeitraumBis;
      OPTIONS.notBefore = this.model.zeitraum_von;
      return OPTIONS;
    },
  },
  created() {
    this.setStellenumfangProjektMaxValue();
    this.hideName();
    this.initErrorLabels_mixin();
  },
  methods: {
    setStellenumfangProjektMaxValue() {
      const STELLENUMFANG = this.getNumber({ item: this.model.stellenumfang });
      if (isUndefined(STELLENUMFANG)) {
        this.fields.stellenumfang_projekt.maxValue = 50;
        return;
      }
      this.fields.stellenumfang_projekt.maxValue = STELLENUMFANG;
    },
    hideName() {
      if (!this.model.bestandspersonal) {
        this.fields.name.hideIf = true;
        // eslint-disable-next-line vue/no-mutating-props
        this.model.name = undefined;
      }
    },
  },
};
