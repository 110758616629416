import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  FilterDateMixin,
} from "../../../global/mixins/FiltersMixin";

import SNAPSHOT_MODES from "./modes";

// @vue/component
export default {
  name: "SnapshotModule",
  components: {
    PuxTranslate,
  },
  mixins: [
    FilterDateMixin,
  ],
  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
    snapshot: {
      type: [Object, Array],
      default: undefined,
    },
    snapshotMode: {
      type: String,
      default: "snapshot",
      validator: value => SNAPSHOT_MODES.indexOf(value) !== -1,
    },
    snapshotDate: {
      type: String,
      default: undefined,
    },
    statusDiff: {
      type: Boolean,
      required: false,
    },
    extra: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
    extraSnapshot: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
  },
  computed: {
    isModeSnapshot() {
      return this.snapshotMode === "snapshot";
    },

    isModeCompact() {
      return this.snapshotMode === "compact";
    },

    compactModeContentCls() {
      if (this.statusDiff) {
        return "module_version__box_content_snapshot";
      }
      return "";
    },
  },
};
