import Alert from "../../../../global/components/Alert/Alert.vue";
import BankverbindungModalCreate from "./BankverbindungModalCreate/BankverbindungModalCreate.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import IbanInSelect from "./IbanInSelect/IbanInSelect.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import ZahlungsverkehrTr from "./ZahlungsverkehrTr/ZahlungsverkehrTr.vue";

import ModuleMixin from "../ModuleMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import {
  cloneDeep,
  forEach,
  isEmpty,
  get,
  indexOf,
} from "lodash-es";

const SNAPSHOT_CHANGES_LIST_FIELDS = [
  {
    id: "aov_bank",
    path: "aov_bank",
  },
  {
    id: "aov_bic",
    path: "aov_bic",
  },
  {
    id: "aov_iban",
    path: "aov_iban",
  },
  {
    id: "aov_inhaber",
    path: "aov_inhaber",
  },
  {
    id: "externe_referenz",
    path: "externe_referenz",
  },
];

// @vue/component
export default {
  name: "Zahlungsverkehr",
  components: {
    FormElement,
    Alert,
    ZahlungsverkehrTr,
    PuxButton,
    BankverbindungModalCreate,
    IbanInSelect,
  },
  mixins: [
    ModuleMixin,
    PermissionMixin
  ],
  data() {
    return {
      astPermissions: [],
      bankverbindungen: [],
      bankverbindungenList: [],
      snapshotChangesListFields: [],
      loading: false,
      statusLoadingPermissions: true,
      modelEdit: {},
      labels: {},
      statusModalCreate: undefined,
      selectorClose: undefined,
      statusCanAstNotShow: false,
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.openEditMode;
      return OPTIONS;
    },

    labelHeader() {
      if (this.modelParameter.frage_text) {
        return this.modelParameter.frage_text;
      }
      return "_LBL_GR_ZAHLUNGSVERKEHR_HEADER_";
    },

    bankverbindungenListDefault() {
      return [
        this.aov_inhaber,
        this.aov_iban,
        this.aov_bic,
        this.aov_bank,
        this.externe_referenz,
      ];
    },

    aov_inhaber() {
      return {
        id: "aov_inhaber",
        htmlId: `${ this.htmlRegelNummer }_aov_inhaber`,
        type: "text",
        view: "v",
        required: true,
      };
    },

    aov_iban() {
      return {
        id: "aov_iban",
        htmlId: `${ this.htmlRegelNummer }_aov_iban`,
        type: "text",
        view: "v",
        required: true,
      };
    },

    aov_bic() {
      return {
        id: "aov_bic",
        htmlId: `${ this.htmlRegelNummer }_aov_bic`,
        type: "text",
        view: "v",
        required: false,
      };
    },

    aov_bank() {
      return {
        id: "aov_bank",
        htmlId: `${ this.htmlRegelNummer }_aov_bank`,
        type: "text",
        view: "v",
        required: false,
      };
    },

    externe_referenz() {
      return {
        id: "externe_referenz",
        htmlId: `${ this.htmlRegelNummer }_externe_referenz`,
        type: "text",
        view: "v",
      };
    },

    modelLocal() {
      return this.model || {};
    },

    statusModel() {
      return !isEmpty(this.model);
    },

    isLoading() {
      return this.statusLoadingPermissions || this.loading;
    },

    canView() {
      return this.obj.antragstellerorg && indexOf(this.astPermissions, "astorgbankverbindung.view") !== -1;
    },

    canAdd() {
      return this.obj.antragstellerorg && indexOf(this.astPermissions, "astorgbankverbindung.create") !== -1;
    },

    statusShowExterneReferenz() {
      return get(this.modelParameter, "felder.externe_referenz.show", false);
    },

    optionsBankverbindung() {
      return {
        type: "select",
        label: this.labels.bankverbindung,
        required: true,
        view: "v",
        keyId: "pk",
        keyLabel: "aov_iban",
        slot: "iban",
        deselect: false,
        search: true,
        searchList: ["aov_iban"],
        id: "bankverbindung",
        data: this.bankverbindungen,
        htmlId: `${ this.htmlRegelNummer }_bankverbindung`,
      };
    },

    optionsExterne_referenz() {
      return {
        type: "text",
        label: this.labels.externe_referenz,
        required: false,
        view: "v",
        id: "externe_referenz",
        htmlId: `${ this.htmlRegelNummer }_externe_referenz`,
      };
    },

    statusShowOpenCreateButton() {
      return this.modelParameter.anlegen_erlaubt && this.canAdd;
    },

    buttonCreateId() {
      return `${ this.htmlRegelNummer }_add`;
    },

    urlSaveBankverbindung() {
      return `antragstellerorganisationen/${ this.obj.antragstellerorg }/bankverbindungen/`;
    },

    snapshotFiltered() {
      const SNAPSHOT = {
        changes: {},
        status: false,
      };
      if (this.statusSnapshot) {
        const SNAPSHOT_MODULE_OBJ = cloneDeep(this.snapshotModule) || {};
        forEach(this.snapshotChangesListFields, key => {
          if (get(this.modelLocal, key) !== get(SNAPSHOT_MODULE_OBJ, key)) {
            SNAPSHOT.changes[key] = true;
            SNAPSHOT.status = true;
          }
        });
      }
      return SNAPSHOT;
    },

    statusDiff() {
      return !!(this.snapshotModule && this.snapshotFiltered.status);
    },

    statusNoData() {
      return isEmpty(this.snapshotModule) && isEmpty(this.modelLocal);
    },

    felder() {
      return this.modelParameter.felder;
    },

    errorsLabelsOptions() {
      return [
        this.optionsBankverbindung,
        this.optionsExterne_referenz
      ];
    },

    isModuleEditable() {
      return !this.statusReadonly && this.canView;
    },
  },
  watch: {
    "obj.antragstellerorg"() {
      this.reloadModule();
      this.loadData();
    }
  },
  methods: {
    initDataLocal({ statusFirstLoad }) {
      if (statusFirstLoad) {
        this.setBankverbindungenList();
        this.setSnapshotChangesListFields();
        this.setLabels();
        this.loadData();
      }
    },

    loadData() {
      const ASTORG = this.obj.antragstellerorg_obj;
      this.statusLoadingPermissions = true;
      if (!ASTORG) {
        this.astPermissions = [];
        this.statusLoadingPermissions = false;
        return;
      }
      if (this.kontext === "sitzungsantrag") {
        this.astPermissions = ["astorgbankverbindung.view"];
        this.statusLoadingPermissions = false;
        this.bankverbindungen = [];
        return;
      }
      this.checkPermissionsApi({
        perms: [
          "astorgbankverbindung.create",
          "astorgbankverbindung.view"
        ],
        content_type: ASTORG.content_type,
        pk: ASTORG.pk
      }).then(
        response => {
          this.astPermissions = response;
          if (indexOf(this.astPermissions, "astorgbankverbindung.view") !== -1) {
            this.loadBankverbindungen();
          }
        },
        () => this.astPermissions = []
      ).finally(() => this.statusLoadingPermissions = false);
    },

    setBankverbindungenList() {
      const BANKVERBINDUNGEN_LIST = [];
      forEach(this.bankverbindungenListDefault, item => {
        const OPTIONS = this.felder[item.id];
        if (OPTIONS && OPTIONS.show) {
          const ITEM = cloneDeep(item);
          ITEM.label = OPTIONS.label_editonly;
          ITEM.labelTable = OPTIONS.label_readonly;
          BANKVERBINDUNGEN_LIST.push(ITEM);
        }
      });
      this.bankverbindungenList = BANKVERBINDUNGEN_LIST;
    },

    setSnapshotChangesListFields() {
      const LIST = [];
      forEach(SNAPSHOT_CHANGES_LIST_FIELDS, item => {
        const OPTIONS = this.felder[item.id];
        if (OPTIONS && OPTIONS.show) {
          LIST.push(item.path);
        }
      });
      this.snapshotChangesListFields = LIST;
    },

    setLabels() {
      const LABELS = {
        bankverbindung: "_LBL_GR_ZAHLUNGSVERKEHR_BANKVERBINDUNG_",
      };
      forEach(this.felder, (item, key) => {
        if (item.show) {
          LABELS[key] = item.label_editonly;
        }
      });
      this.labels = LABELS;
    },

    loadBankverbindungen() {
      if (!this.obj.antragstellerorg_obj) {
        this.bankverbindungen = [];
        return;
      }
      this.loading = true;
      this.getListHttp({
        url: `antragstellerorganisationen/${ this.obj.antragstellerorg_obj.pk }/bankverbindungen/`,
        showError: false,
      }).then(
        response => this.bankverbindungen = response,
      ).finally(() => this.loading = false);
    },

    openEditMode() {
      const MODEL_EDIT = {
        bankverbindung: this.modelLocal.bankverbindung || null,
      };
      if (this.statusShowExterneReferenz) {
        MODEL_EDIT.externe_referenz = this.modelLocal.externe_referenz || null;
      }
      this.modelEdit = MODEL_EDIT;
    },

    openModalCreate() {
      this.selectorClose = [`#${ this.buttonCreateId }`];
      this.statusModalCreate = true;
    },

    closeModalCreate({ response } = {}) {
      if (response) {
        this.bankverbindungen.push(response);
        this.modelEdit.bankverbindung = response.pk;
      }
      this.selectorClose = undefined;
      this.statusModalCreate = false;
    },

    setLocalErrorsFromGlobal({ errors }) {
      this.errors = errors;
    },
  },
};
