"use strict";

import "angular-animate/angular-animate";
import "angular-strap";
import "angular-strap/dist/angular-strap.tpl";
import "angular-motion/dist/angular-motion.css";

import NotificationServices from "./services";
import alertComponent from "./alert.component";

const diasNotification = angular.module("dias.notification", [
  "mgcrea.ngStrap.alert",
  NotificationServices.name
])

  .component("alert", alertComponent)
;

export default diasNotification;
