import PuxDropdown from "../PuxDropdown/PuxDropdown.vue";
import PuxTemplateDownloadElement from "./PuxTemplateDownloadElement/PuxTemplateDownloadElement.vue";
import PuxIcon from "../PuxIcon/PuxIcon.vue";

// @vue/component
export default {
  name: "PuxTemplateDownload",
  components: {
    PuxIcon,
    PuxDropdown,
    PuxTemplateDownloadElement,
  },
  props: {
    async: {
      type: Boolean,
      default: false,
      info: "Laden Date asynchron oder synchron",
    },
    data: {
      type: Array,
      required: true,
    },
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    action: {
      type: String,
      default: "dokument_async",
    },
    downloadAction: {
      type: String,
      default: "dokument_download",
    },
    btnType: {
      type: String,
      default: "default",
    },
    btnIcon: {
      type: String,
      default: "glyphicon-download",
    },
    btnClass: {
      type: String,
      default: "btn btn-default",
    },
    htmlId: {
      type: String,
      required: true,
    },
  },
};
