import {
  computed,
  ref,
} from "vue";

import HttpAPI from "../../../../../libs/vue/client/vue/global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../../libs/vue/client/vue/global/compositionAPI/PermissionAPI";
import SyConfigAPI from "../../../../../libs/vue/client/vue/global/compositionAPI/SyConfigAPI";

import KStudiengangartUUIDs from "../../../../../libs/vue/client/vue/const/KStudiengangartUUIDs";
import VersionStatus from "../const/VersionStatus";
import {
  cloneDeep,
  find,
  findLast,
  get,
  uniq,
} from "lodash-es";

export default function AkkreditierungItemAPI({
  akkreditierung,
  baseUrl,
  emit,
  akkreditierungHistoriePk,
  basePermission = "programmakkreditierung",
}) {
  const {
    addNotification,
    getHttp,
    putHttp,
  } = HttpAPI();
  const {
    getSyConfigsValue,
  } = SyConfigAPI();
  const {
    checkPermissionsSync,
  } = PermissionAPI();

  const isProgrammakkreditierung = basePermission === "programmakkreditierung";
  const documentAnzeigeIntern = isProgrammakkreditierung ? getSyConfigsValue("akkr_doks").programm_intern : getSyConfigsValue("akkr_doks").system_intern;
  const documentAnzeigeOeffentlich = isProgrammakkreditierung ? getSyConfigsValue("akkr_doks").programm_oeffentlich : getSyConfigsValue("akkr_doks").system_oeffentlich;
  const versionen = ref([]);
  const displayVersion = ref({});
  const loading = ref({
    versionen: true,
  });
  const editAkkr = ref({});
  const displayVersionDefault = ref({});
  const selectedVersion = ref(null);
  const studiengangEditMode = ref(false);
  const saving = ref(false);
  const saveObj = computed(() => {
    return editAkkr.value ? editAkkr.value.pk : "";
  });
  const akkreditierungenAkkreditiertUrl = computed(() => {
    return `${ baseUrl }/${ displayVersion.value.pk }/teilstudiengangsakkreditierungen_akkreditiert/`;
  });
  const akkrteilstudiengaengeNichtAkkreditiertUrl = computed(() => {
    return `${ baseUrl }/${ displayVersion.value.pk }/teilstudiengangsakkreditierungen_nicht_akkreditiert/`;
  });
  const showAkkreditierteTeilstudiengaenge = computed(() => {
    return get(displayVersion.value, "studiengang_obj.art") === KStudiengangartUUIDs.KOMBI && !displayVersion.value.statusUpdate;
  });
  const programmAkkreditierungUrl = computed(() => {
    return `${ baseUrl }/${ displayVersion.value.pk }/`;
  });
  const tableColumnsTeilstudiengangsakkreditierungen = [
    {
      label: "Nummer",
      id: "sg_laufnr"
    },
    {
      label: "Name",
      id: "sg_bez"
    },
    {
      label: "Akkreditierung",
      id: "akkreditierungstyp_bez",
      linkTo: {
        url: "/programmakkreditierung/{{pk}}/",
      },
    },
    {
      label: "Beginn Begutachtungsfrist",
      id: "pro_von",
      filter: "date"
    },
    {
      label: "Ende Begutachtungsfrist",
      id: "pro_bis",
      filter: "date",
    },
    {
      label: "Status",
      id: "status_bez"
    },
    {
      label: "In dieser Akkreditierung akkreditiert",
      id: "kombiakkr_ursprung_id",
      slot: "in_dieser_akkreditierung_akkreditiert"
    }
  ];
  const blankParameterForDocuments = {
    frage: {
      typ: "string",
      wert: null,
    },
    readonly: {
      typ: "boolean",
      wert: false,
    },
    pflichtfeld: {
      typ: "boolean",
      wert: true,
    },
    default_sort: {
      typ: "single_choice",
      wert: "-dokument.crdate",
    },
    antragsteller: {
      typ: "boolean",
      wert: false,
    },
    anzeige_details: {
      typ: "multi_select",
      wert: [
        "dokument.dok_titel",
        "dokument.dokumentart.name",
        "dokument.crdate",
        "pruefstatus.status",
        "sync",
      ],
    },
    header_optional: {
      typ: "string",
      wert: "Sonstige Dokumente",
    },
    header_required: {
      typ: "string",
      wert: "Pflichtdokumente",
    },
    mehrfachauswahl: {
      typ: "boolean",
      wert: false,
    },
    allowed_mime_type: {
      typ: "json",
      wert: [],
    },
    validate_pflichtdoks: {
      typ: "boolean",
      wert: false,
    },
    gueltig_pflicht_felder: {
      typ: "multi_select",
      wert: [
        "von"
      ],
    },
    gueltig_fuer_dokument_hochladen: {
      typ: "boolean",
      wert: false,
    },
  };
  const documentConfig = computed(() => {
    return {
      module: {
        modulname: "anlagen_allgemein",
        regel: {
          regel: akkreditierungHistoriePk,
          regel_nummer: `${ akkreditierungHistoriePk }_1`,
          _argument: {
            ...blankParameterForDocuments,
            anzeigeart: {
              typ: "single_choice",
              wert: [
                documentAnzeigeIntern,
                documentAnzeigeOeffentlich,
              ],
              katalog: "anzeigeart",
            },
          },
        },
      },
      readonly: false,
    };
  });
  const akkreditierungUserPermissions = computed(() => {
    return get(akkreditierung.value, "user_permissions", []);
  });
  const canUpdate = computed(() => {
    return checkPermissionsSync({
      perm: `${ basePermission }.update`,
      permArray: akkreditierungUserPermissions.value,
    });
  });
  const canEditAkkr = computed(() => {
    return canUpdate.value && displayVersion.value.versionstatus === VersionStatus.BEARBEITUNG;
  });
  const canViewInternalDocuments = computed(() => {
    return checkPermissionsSync({
      perm: `${ basePermission }.dokumente.view`,
      permArray: akkreditierungUserPermissions.value,
    });
  });
  const programmAkkreditierungVersionErstellenUrl = computed(() => {
    return `${ programmAkkreditierungUrl.value }/version_erstellen/`;
  });
  const akkreditierungenAktivierenUrl = computed(() => {
    return `${ programmAkkreditierungUrl.value }/akkreditierung_aktivieren/`;
  });
  const akkreditierungenLoeschenUrl = computed(() => {
    return `${ programmAkkreditierungUrl.value }/loeschen/`;
  });
  const akkreditierungenVeroeffentlichenUrl = computed(() => {
    return `${ programmAkkreditierungUrl.value }/veroeffentlichen/`;
  });
  const getProgrammakkreditierungVersionenUrl = ({ pk }) => {
    return `${ baseUrl }/${ pk }/versionen/`;
  };
  const canViewDetail = (row, extra) => {
    return extra.akkr.versionstatus !== 1;
  };

  const loadTeilstudiengangsakkreditierungen = async() => {
    try {
      const tableConfig = {
        sortable: false,
        pagination: false,
        details: false,
        urlUpdate: false,
        showHeader: false,
        hideTitle: true,
        showColsConfig: false,
        rowActions: [
          {
            label: "Programmakkreditierung anzeigen",
            route: "root.programmakkreditierung.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
            isHidden: !canViewDetail
          }
        ],
      };

      displayVersion.value.teilstudiengaengeTable = {
        data: await getHttp({
          url: akkreditierungenAkkreditiertUrl.value,
        }),
        id: `teilstudiengaenge_table_akkreditiert_${ displayVersion.value.pk }`,
        cols: tableColumnsTeilstudiengangsakkreditierungen,
        ...tableConfig,
      };

      displayVersion.value.teilstudiengaengeTableOld = {
        data: await getHttp({
          url: akkrteilstudiengaengeNichtAkkreditiertUrl.value,
        }),
        id: `teilstudiengaenge_table_nicht_akkreditiert_${ displayVersion.value.pk }`,
        cols: tableColumnsTeilstudiengangsakkreditierungen,
        ...tableConfig,
      };
    } catch (e) {
      console.error(e);
      addNotification({
        text: "Fehler API",
        type: "error",
      });
    }
  };

  const loadTableConfigIfNeeded = async() => {
    try {
      if (displayVersion.value.studiengang_obj && displayVersion.value.studiengang_obj.art === KStudiengangartUUIDs.KOMBI) {
        displayVersion.value.statusUpdate = true;
        await loadTeilstudiengangsakkreditierungen();
        displayVersion.value.statusUpdate = false;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setDisplayVersion = akkr => {
    displayVersion.value = akkr;
    selectedVersion.value = displayVersion.value.pk;
    if (displayVersion.value.studiengang_obj) {
      displayVersion.value.studiengang_obj.studienformen_bez = uniq(displayVersion.value.studiengang_obj.studienformen_bez);
      displayVersion.value.studiengang_obj.standorte_bez = uniq(displayVersion.value.studiengang_obj.standorte_bez);
    }
    loadTableConfigIfNeeded();
  };

  const findVersionToDisplay = akkr => {
    const unpublished = findLast(versionen.value, { versionstatus: VersionStatus.BEARBEITUNG });
    const future = findLast(versionen.value, { versionstatus: VersionStatus.ZUKUENFTIG });
    const current = findLast(versionen.value, { versionstatus: VersionStatus.AKTUELL });

    return unpublished || future || current || akkr;
  };

  const onVersionenLoaded = () => {
    emit("versionenLoaded", versionen.value, akkreditierung.value.ursprung);
  };

  const initDisplay = async(forceVersion = false, newAkkreditierung = null) => {
    try {
      const akkreditierungToInit = newAkkreditierung || akkreditierung.value;
      loading.value.versionen = true;
      if (akkreditierungToInit.has_versionen || forceVersion) {
        try {
          const response = await getHttp({
            url: getProgrammakkreditierungVersionenUrl(akkreditierungToInit),
          });

          versionen.value = response;

          const versionToDisplay = findVersionToDisplay(akkreditierungToInit);

          setDisplayVersion(versionToDisplay);
        } catch (e) {
          console.error(e);
          addNotification({
            text: "Fehler API",
            type: "error",
          });
        }
      } else {
        versionen.value = [akkreditierungToInit];
        setDisplayVersion(akkreditierungToInit);
      }

      loading.value.versionen = false;

      onVersionenLoaded();
    } catch (e) {
      console.error(e);
    }
  };

  const changeDisplayFor = val => {
    selectedVersion.value = val;
    const versionToDisplay = find(versionen.value, { pk: selectedVersion.value });

    if (versionToDisplay) {
      setDisplayVersion(versionToDisplay);
    }
  };

  const setStudiengangEditMode = async(akkr, editMode = false) => {
    try {
      editAkkr.value = null;

      if (editMode) {
        loading.value.versionen = true;
        displayVersionDefault.value = cloneDeep(akkr.studiengang_obj);

        const response = await getHttp({
          url: programmAkkreditierungUrl.value,
        });

        editAkkr.value = response;
        studiengangEditMode.value = editMode;
        loading.value.versionen = false;
      } else {
        akkr.studiengang_obj = Object.assign(akkr.studiengang_obj, cloneDeep(displayVersionDefault.value));
        studiengangEditMode.value = editMode;
      }
    } catch (e) {
      console.error(e);
      addNotification({
        text: "Fehler API",
        type: "error",
      });
    }
  };

  const commentUngueltig = akkrNew => {
    addNotification({ text: "Akkreditierungsdatensatz wurde ungültig gesetzt." });
    initDisplay(true, akkrNew);
  };

  const createVersion = async() => {
    try {
      saving.value = true;

      const response = await putHttp({
        url: programmAkkreditierungVersionErstellenUrl.value,
        data: {},
      });

      initDisplay(true, response);
      addNotification({ text: "Unveröffentlichte Version wurde erstellt." });

      saving.value = false;
    } catch (e) {
      console.error(e);
      addNotification({
        text: "Fehler beim Erstellen der Version",
        type: "error",
      });
    }
  };

  const activateAkkr = async() => {
    try {
      saving.value = true;

      const response = await putHttp({
        url: akkreditierungenAktivierenUrl.value,
        data: displayVersion.value,
      });

      addNotification({ text: "Akkreditierungsdatensatz wurde gültig gesetzt." });
      initDisplay(true, response);

      saving.value = false;
    } catch (e) {
      console.error(e);
      addNotification({
        text: "Fehler beim Aktivieren der Version",
        type: "error",
      });
    }
  };

  const deleteVersion = async() => {
    try {
      saving.value = true;

      const response = await putHttp({
        url: akkreditierungenLoeschenUrl.value,
        data: {},
      });

      initDisplay(true, response);
      addNotification({ text: "Ursprüngliche Version wurde wieder hergestellt." });

      saving.value = false;
    } catch (e) {
      console.error(e);
      addNotification({
        text: "Fehler beim Löshen der Version",
        type: "error",
      });
    }
  };

  const publishVersion = async() => {
    try {
      saving.value = true;

      const response = await putHttp({
        url: akkreditierungenVeroeffentlichenUrl.value,
        data: {},
      });

      initDisplay(true, response);
      addNotification({ text: "Akkreditierungsdaten wurden veröffentlicht." });
      if (response.auflagenfrist_geaendert) {
        addNotification({
          text: response.auflagenfrist_geaendert,
          type: "danger"
        });
      }

      saving.value = false;
    } catch (e) {
      console.error(e);
      addNotification({
        text: "Fehler beim Veröffentlichung der Version",
        type: "error",
      });
    }
  };

  const auflagenerfuellungBearbeiten = () => {
    emit("auflagenerfuellungBearbeiten", displayVersion.value);
  };
  const gutachtenAustauschenDialogoeffnen = () => {
    emit("gutachtenAustauschenDialogoeffnen", displayVersion.value);
  };

  const onSaveStudiengang = akkr => {
    addNotification({ text: "Studiengangsdaten wurden geändert." });
    setStudiengangEditMode(akkr, false);
    initDisplay(true);
  };
  const onCancelStudiengang = () => {
    editAkkr.value = null;
    studiengangEditMode.value = false;
  };
  const onSaveAkkreditierung = akkr => {
    addNotification({ text: "Änderungen erfolgreich gespeichert" });
    displayVersion.value = Object.assign(displayVersion.value, akkr);
  };
  const onSaveHochschule = akkr => {
    addNotification({ text: "Hochschuldaten wurden geändert" });
    initDisplay(true, akkr);
  };

  return {
    activateAkkr,
    auflagenerfuellungBearbeiten,
    canEditAkkr,
    canUpdate,
    canViewInternalDocuments,
    changeDisplayFor,
    commentUngueltig,
    createVersion,
    deleteVersion,
    displayVersion,
    documentConfig,
    editAkkr,
    gutachtenAustauschenDialogoeffnen,
    initDisplay,
    loading,
    onSaveHochschule,
    onSaveStudiengang,
    onCancelStudiengang,
    onSaveAkkreditierung,
    programmAkkreditierungUrl,
    publishVersion,
    saveObj,
    saving,
    selectedVersion,
    setStudiengangEditMode,
    showAkkreditierteTeilstudiengaenge,
    studiengangEditMode,
    versionen,
  };
}
