import PeriodModalTeilenMixin from "../../PeriodModalTeilenMixin";
import WizardStepsANBruttoMixin from "../../../WizardStepsANBruttoMixin";
import PuxButton from "../../../../../../../global/components/PuxButton/PuxButton.vue";
import {
  round,
} from "lodash-es";

// @vue/component
export default {
  name: "PeriodModalTeilenDHW",
  components: {
    PuxButton,
  },
  mixins: [
    PeriodModalTeilenMixin,
    WizardStepsANBruttoMixin,
  ],
  data() {
    return {
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "period1",
            type: "template",
            html: "<h4 class='m-0'>Periode 1</h4>",
          },
          {
            id: "von1",
            type: "date",
            label: "Beginn",
            required: true,
            view: "v",
            notBefore: undefined,
            notAfter: undefined,
            defaultValue: undefined,
            change: this.changeDate,
          },
          {
            id: "bis1",
            type: "date",
            label: "Ende",
            required: true,
            view: "v",
            notBefore: undefined,
            notAfter: undefined,
            defaultValue: undefined,
            change: this.changeDate,
          },
          {
            id: "brutto_monat1",
            type: "currency",
            label: "Monatliches Arbeitnehmer-Bruttogehalt",
            required: true,
            view: "v",
          },
          {
            id: "einmalzahlung1",
            type: "currency",
            label: "Einmalzahlung",
            view: "v",
          },
          {
            id: "gehalt1",
            type: "currency",
            label: "Förderfähiges Arbeitgeber-Bruttogehalt",
            disabled: true,
            view: "v",
          },
          {
            id: "period2",
            type: "template",
            html: "<h4 class='m-0'>Periode 2</h4>",
          },
          {
            id: "von2",
            type: "date",
            label: "Beginn",
            required: true,
            view: "v",
            notBefore: undefined,
            notAfter: undefined,
            defaultValue: undefined,
            change: this.changeDate,
          },
          {
            id: "bis2",
            type: "date",
            label: "Ende",
            required: true,
            view: "v",
            notBefore: undefined,
            notAfter: undefined,
            defaultValue: undefined,
            change: this.changeDate,
          },
          {
            id: "brutto_monat2",
            type: "currency",
            label: "Monatliches Arbeitnehmer-Bruttogehalt",
            required: true,
            view: "v",
          },
          {
            id: "einmalzahlung2",
            type: "currency",
            label: "Einmalzahlung",
            view: "v",
          },
          {
            id: "gehalt2",
            type: "currency",
            label: "Förderfähiges Arbeitgeber-Bruttogehalt",
            disabled: true,
            view: "v",
          },
        ],
      },
    };
  },
  created() {
    this.initModel();
    this.setDateRange();
  },
  methods: {
    initModel() {
      this.model = {
        von1: this.period.von,
        bis1: undefined,
        von2: undefined,
        bis2: this.period.bis,
        brutto_monat1: this.period.brutto_monat,
        brutto_monat2: this.period.brutto_monat,
        gehalt1: this.period.gehalt,
        gehalt2: this.period.gehalt,
        einmalzahlung1: this.period.einmalzahlung,
        einmalzahlung2: this.period.einmalzahlung,
      };
    },

    changeModel({ id }) {
      const SUFFIX = id.indexOf("1") !== -1 ? "1" : "2";
      const {
        PKT_PAUSCHALE,
        STELLENUMFANG_PROJEKT,
        VOLLZEITSTELLE,
      } = this.infoForBruttoGehalt;
      const A_N_BRUTTO = this.getANBrutto_mixin({
        period: {
          bis: this.model[`bis${ SUFFIX }`],
          von: this.model[`von${ SUFFIX }`],
          einmalzahlung: this.model[`einmalzahlung${ SUFFIX }`],
          brutto_monat: this.model[`brutto_monat${ SUFFIX }`],
        }
      });
      this.model[`gehalt${ SUFFIX }`] = round(A_N_BRUTTO * (1 + PKT_PAUSCHALE / 100) * STELLENUMFANG_PROJEKT / VOLLZEITSTELLE, 2);
    },
  },
};
