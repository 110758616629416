"use strict";

class DynamischeTabelleObjektatalogData {
  /*@ngInject*/
  constructor($scope) {
    this.param_config = {
      _selection_: {
        choices: [],
        wert: this.parameter[this.key].wert,
      }
    };
    $scope.$watch(() => this.parameter.spalten.wert, () => {
      this.updateChoices();
    });
    $scope.$watch(() => this.param_config._selection_.wert, () => {
      this.parameter[this.key].wert = this.param_config._selection_.wert;
    });
    $scope.$watch(() => this.parameter[this.key], () => {
      this.param_config._selection_.wert = this.parameter[this.key].wert;
    });
  }

  updateChoices() {
    this.param_config._selection_.choices = [];
    if (!this.parameter.spalten.wert) {
      return;
    }
    _.forEach(this.parameter.spalten.wert, (value, key) => {
      this.param_config._selection_.choices.push({
        id: key,
        bez: value.label
      });
    });
  }
}

export default DynamischeTabelleObjektatalogData;
