"use strict";

import BetragsrechnerBase from "dias/kosten_finanzierung/betragsrechner/betragsrechner.base";


class BetragsrechnerDetailliert extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "finanzierung_detailliert_betragsrechner";
    this.component_name = "finanzierungdetailliert";
    this.betrag_editable = true;
    this.fields = {
      antrag: { wert: null,
                bemerkung: "",
                finanzierer: null,
                finanzierer_freitext: "",
                kategorie: null,
                status: null,
                statusdatum: "",
                land: null },
      beleg: { wert: null }
    };
  }

  get_labelmapping() {
    return {
      wert: "Betrag in EUR",
      land: "Land",
      kategorie: "Typ / Kategorie",
      finanzierer: "Finanzierer (Auswahl)",
      finanzierer_freitext: "Finanzierer (Freie Eingabe)",
      bemerkung: "Bemerkung",
      status: "Finanzierungstatus",
      statusdatum: "Datum der Bestätigung/Entscheidung"
    };
  }
}

export default BetragsrechnerDetailliert;
