import EditMixin from "../EditMixin";
import {
  cloneDeep,
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "SchaltflaecheEdit",
  mixins: [
    EditMixin,
  ],
  props: {
    antwort: {
      type: Object,
      required: true,
    },
    antwortIndex: {
      type: Number,
      required: true,
    },
    arrowShowIndex: {
      type: Number,
      default: undefined,
    },
    model: {
      type: Object,
      default: () => ({}),
    },
    mehrfachauswahl: {
      type: Boolean,
      required: true,
    },
    openChildren: {
      type: Function,
      required: true,
    },
    labelCallback: {
      type: Function,
      required: true,
      info: "returns label mit oder ohne Sterne"
    },
    changeModel: {
      type: Function,
      required: true,
    },
  },
  computed: {
    getModelAntworten() {
      return this.model.antworten || {};
    },
    getChildren() {
      if (!this.antwort.children ||
        !this.antwort.children.length ||
        !this.getModelAntworten[this.antwort.label]) {
        return [];
      }
      if (this.mehrfachauswahl) {
        return filter(this.antwort.children, child => {
          return this.getModelAntworten[this.antwort.label].children &&
            this.getModelAntworten[this.antwort.label].children[child.label] &&
            this.getModelAntworten[this.antwort.label].children[child.label].status;
        });
      }
      return filter(this.antwort.children, child => {
        return this.getModelAntworten[this.antwort.label].children &&
          this.getModelAntworten[this.antwort.label].children[child.label] &&
          this.getModelAntworten[this.antwort.label].children[child.label].status;
      });
    },
    isActive() {
      if (this.getModelAntworten[this.antwort.label] && this.getModelAntworten[this.antwort.label].status) {
        return true;
      }
      return this.getChildren.length;
    },
  },
  methods: {
    onClick() {
      if (this.antwort.children && this.antwort.children.length) {
        this.openChildren({ index: this.antwortIndex });
        return;
      }
      let model = cloneDeep(this.model);
      model.antworten = model.antworten || {};
      model.antworten[this.antwort.label] = model.antworten[this.antwort.label] || {};
      if (!this.mehrfachauswahl) {
        model = this.setModelFalseForAll({ model });
      }
      model.antworten[this.antwort.label].status = !model.antworten[this.antwort.label].status;
      this.changeModel({ model: model });
    },
  },
};
