"use strict";

import template from "./overview.jade";

class ProjektberichtOverviewController {
  /*@ngInject*/
  constructor(Vertrag, Foerderantrag, i18n) {
    this.Vertrag = Vertrag;
    this.Foerderantrag = Foerderantrag;
    this.gettext = i18n.gettext;
    this.loading = {
      modul: true,
    };
    this.loadModul();
  }

  loadModul() {
    this.Foerderantrag.one(this.projektbericht.antrag_id).module.one(
      this.projektbericht.regel
    ).get().then(
      response => {
        this.modul = response;
        if (!this.modul || !this.modul.regel) {
          return;
        }
        this.vorlage_config = this.modul.regel.afr_argument.projektberichte.wert[this.projektbericht.pb_afr_argument_idx];
        this.vorlage_data = this.modul.data.find(v => v.pb_afr_argument_idx === this.projektbericht.pb_afr_argument_idx);
      }
    ).finally(() => this.loading.modul = false);
  }
}

export default {
  template: template(),
  controller: ProjektberichtOverviewController,
  controllerAs: "vm",
  bindings: {
    projektbericht: "=",
    vertrag: "<"
  }
};
