import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import { createNamespacedHelpers } from "vuex";
import {
  get,
} from "lodash-es";

const {
  mapMutations,
} = createNamespacedHelpers("snapshot");

// @vue/component
export default {
  name: "HinweiseZurAuszahlung",
  components: {
    FormstepDetail,
    PuxTranslate,
    SnapshotIcon,
    SnapshotModule,
  },
  props: {
    auszahlung: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  emits: [
    "updateAuszahlung",
  ],
  setup() {
    const {
      addNotification,
      putHttp,
    } = HttpAPI();
    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      addNotification,
      checkPermissionsSync,
      putHttp,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        label: "_TXT_HINWEISE_ZUR_AUSZAHLUNG_",
        list: [
          this.optionsForHinweis,
        ],
        openCallback: this.open,
        saveCallback: this.save,
      };
    },

    isEditable() {
      return this.checkPermissionsSync({
        perm: "auszahlung.hinweis.update",
        permArray: this.userPermissions,
      });
    },

    userPermissions() {
      return this.auszahlung.user_permissions;
    },

    optionsForHinweis() {
      return {
        id: "az_hinweis",
        htmlId: `${ this.id }_az_hinweis`,
        label: "_TXT_HINWEISE_ZUR_AUSZAHLUNG_",
        type: "text",
        view: "v",
        name: "az_hinweis",
        required: false,
        editonly: true,
      };
    },

    urlForSave() {
      return `${ this.baseUrl }${ this.auszahlung.pk }/hinweis_aendern/`;
    },

    isSnapshot() {
      return !!this.snapshot;
    },

    snapshotObject() {
      return get(this.snapshot, "osn_snapshot.auszahlung");
    },

    snapshotDate() {
      return get(this.snapshot, "osn_snapshot_datum");
    },

    hinweisValue() {
      return this.auszahlung.az_hinweis;
    },

    hinweisValueFromSnapshot() {
      return get(this.snapshotObject, "az_hinweis");
    },

    isDiff() {
      let isDiff = false;
      if (this.isSnapshot) {
        isDiff = this.hinweisValue !== this.hinweisValueFromSnapshot;
      }
      return isDiff;
    },
  },
  watch: {
    isDiff: {
      immediate: true,
      handler(newVal) {
        this.MUT_SET_STATUS_DIFF_FOR_REGEL({
          regelId: this.id,
          statusDiff: newVal,
        });
      },
    },
  },
  beforeUnmount() {
    this.MUT_DELETE_STATUS_DIFF_FOR_REGEL({ regelId: this.id });
  },
  methods: {
    open() {
      return {
        model: {
          az_hinweis: this.hinweisValue,
        },
      };
    },

    save({ model }) {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.urlForSave,
          data: model,
        }).then(
          response => {
            this.$emit("updateAuszahlung", response);
            this.addNotification({ text: "_MSG_UPDATE_SUCCESS_" });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },

    ...mapMutations([
      "MUT_SET_STATUS_DIFF_FOR_REGEL",
      "MUT_DELETE_STATUS_DIFF_FOR_REGEL",
    ]),
  },
};
