export default {
  props: {
    parentId: {
      type: String,
      required: true,
    },
    tab: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    idForActiveTab: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    idLocal() {
      return `${ this.parentId }_${ this.index }`;
    },

    idForContent() {
      return `${ this.idLocal }_content`;
    },

    isActive() {
      return this.idForActiveTab === this.tab.id;
    },
  },
};
