"use strict";

import template from "./statusgruppen.jade";

class StatusgruppenController {
  /*@ngInject*/
  constructor(Workflowstatusgruppen, Katalog, i18n, $scope) {
    this.WGT_BEARBEITUNG = "1d761616-7bbd-486e-88eb-1626d3b26af8";
    this.WGT_ERFOLG = "6acf334c-d5fd-411e-9dff-536949f7bebf";
    this.WGT_MISSERFOLG = "9da607d6-940f-4913-a1e5-8f7d9a7c4c97";

    this.gettext = i18n.gettext;

    this.loading = true;
    this.$scope = $scope;
    this.Workflowstatusgruppen = Workflowstatusgruppen;
    this.Katalog = Katalog;

    if (this.relevantObj && this.relevantObj.workflow) {
      this.getWorkflowtypUUID();
    } else {
      this.statusComponentDisable = true;
      this.loading = false;
    }
  }

  getWorkflowtypUUID() {
    if (this.wfKontext) {
      this.getWorkflowstatusgruppen(this.wfKontext.pk);
      this.kontextName = this.kontextName || this.gettext(this.wfKontext.wft_kbez);
    } else {
      this.Katalog.getKatalog("workflowtyp", { pk: this.relevantObj.workflowstatusgruppe.workflowtyp }).then(
        response => {
          this.getWorkflowstatusgruppen(response[0].pk);
          this.kontextName = this.kontextName || this.gettext(response[0].wft_kbez);
        }
      );
    }
  }

  getWorkflowstatusgruppen(workflowtypUUID) {
    this.Workflowstatusgruppen.getList({ workflowtyp: workflowtypUUID }).then(
      response => {
        this.workflowstatusgruppenResponse = response;
        this.setWatch();
      }
    );
  }

  filterWorkflowstatusgruppen(response) {
    this.wfStatusGruppen = [];
    angular.forEach(response, gruppe => {
      if (gruppe.workflowstatusgruppentyp === this.relevantObj.workflowstatusgruppe.workflowstatusgruppentyp) {
        if (gruppe.workflowstatusgruppentyp === this.WGT_ERFOLG) {
          gruppe.icon = "glyphicon-ok";
        } else if (gruppe.workflowstatusgruppentyp === this.WGT_MISSERFOLG) {
          gruppe.icon = "glyphicon-remove";
        } else {
          gruppe.wizard_pos = gruppe.wsg_pos;
        }
        this.wfStatusGruppen.push(gruppe);
      }
    });

    this.loading = false;
  }

  setWatch() {
    this.$scope.$watch("vm.relevantObj.workflowstatusgruppe.pk", () => {
      if (this.relevantObj && this.relevantObj.workflowstatusgruppe) {
        this.loading = true;
        this.filterWorkflowstatusgruppen(this.workflowstatusgruppenResponse);
      } else {
        this.loading = false;
      }
    });
  }
}

export default {
  template: template(),
  controller: StatusgruppenController,
  controllerAs: "vm",
  bindings: {
    relevantObj: "<",
    relObjName: "<?",
    relObjLabel: "<?",
    wfKontext: "<?",
    kontextName: "@?",
    addionalText: "<",
    foeangebot: "<",
  }
};
