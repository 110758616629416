import Modal from "../../../../../global/components/Modal/Modal.vue";
import translate from "../../../../../global/directives/translate";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "PruefkriteriumDetailRemoveModal",
  components: {
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  props: {
    obj: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      status: {
        loading: undefined,
      },
    };
  },
  computed: {
    translationTxtHeader() {
      return {
        text: "_TXT_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_MODAL_REMOVE_HEADER_{{pruefkriterium}}_",
        extra: { pruefkriterium: this.obj.pruefkriterium.prf_kbez }
      };
    },

    translationHtmlBody() {
      return {
        html: "_HTML_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_MODAL_REMOVE_BODY_{{pruefkriterium}}_",
        extra: { pruefkriterium: this.obj.pruefkriterium.prf_kbez }
      };
    },
  },
  methods: {
    remove() {
      this.close({
        opr_geprueft: null,
        opr_subkriterien: null
      });
    }
  },
};
