import AuswahlKatalogAuswahlMixin from "../AuswahlKatalogAuswahlMixin.js";
import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";

// @vue/component
export default {
  name: "AuswahlKatalogCheckboxReadonly",
  components: {
    SnapshotIcon,
    PuxIcon,
  },
  mixins: [
    AuswahlKatalogAuswahlMixin
  ],
  props: {
    snapshotdiff: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    showSnapshot: {
      type: Boolean,
      default: () => false
    },
    showUnselected: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    inDiff(el) {
      return this.snapshotdiff[el.pk];
    },

    getSelectionStatusIcon(el) {
      if (this.getSelectionStatus(el)) {
        return "check";
      }
      return "unchecked";
    },

    getSelectionStatusIconClass(el) {
      if (this.getSelectionStatus(el)) {
        if (this.hasSubGroup(el)) {
          return "";
        }
        return "text-success";
      }
      return "";
    },

    showElement(el) {
      return el.aktiv && (this.showUnselected || this.getSelectionStatus(el));
    }

  }
};
