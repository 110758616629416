import BetragsrechnerMixin from "../../Mixins/BetragsrechnerMixin";
import KumastaBetragsrechnerMixin from "../KumastaBetragsrechnerMixin";

// @vue/component
export default {
  name: "KumastaHonorarkostenrechner",
  mixins: [
    BetragsrechnerMixin,
    KumastaBetragsrechnerMixin,
  ],
  data() {
    return {
      labelTaetigkeitBez: "Bezeichnung der Tätigkeit",
      labelQualifikationBez: "Qualifikation der Honorarkraft",
      labelZeiteinheit: "Zeiteinheit",
      labelAnzahlZeiteinheiten: "Anzahl Zeiteinheiten",
      labelKostenProZeiteinheit: "Honorarsatz pro Zeiteinheit in Euro",
      labelWert: "Betrag in EUR",
    };
  },
  computed: {
    optionsTaetigkeitBez() {
      return {
        id: "taetigkeit_bez",
        htmlId: this.htmlIdTaetigkeitBez,
        label: this.labelTaetigkeitBez,
        view: this.view,
        type: "text",
        maxlength: 300,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdTaetigkeitBez() {
      return `${ this.htmlId }_taetigkeit_bez`;
    },

    optionsQualifikationBez() {
      return {
        id: "qualifikation_bez",
        htmlId: this.htmlIdQualifikationBez,
        label: this.labelQualifikationBez,
        view: this.view,
        type: "text",
        maxlength: 300,
        required: false,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdQualifikationBez() {
      return `${ this.htmlId }_qualifikation_bez`;
    },

    optionsZeiteinheit() {
      return {
        id: "zeiteinheit",
        htmlId: this.htmlIdZeiteinheit,
        label: this.labelZeiteinheit,
        view: this.view,
        type: "select",
        required: true,
        data: [
          {
            label: "Einheit(en) (45 Minuten)",
            value: "u",
          },
          {
            label: "Einheit(en) (60 Minuten)",
            value: "h",
          },
          {
            label: "Tag(e)",
            value: "d",
          },
          {
            label: "Woche(n)",
            value: "w",
          },
        ],
        keyLabel: "label",
        keyId: "value",
        search: true,
        searchList: ["label"],
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdZeiteinheit() {
      return `${ this.htmlId }_zeiteinheit`;
    },

    optionsAnzahlZeiteinheiten() {
      return {
        id: "anzahl_zeiteinheiten",
        htmlId: this.htmlIdAnzahlZeiteinheiten,
        label: this.labelAnzahlZeiteinheiten,
        view: this.view,
        type: "float",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdAnzahlZeiteinheiten() {
      return `${ this.htmlId }_anzahl_zeiteinheiten`;
    },

    optionsKostenProZeiteinheit() {
      return {
        id: "kosten_pro_zeiteinheit",
        htmlId: this.htmlIdKostenProZeiteinheit,
        label: this.labelKostenProZeiteinheit,
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 9,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdKostenProZeiteinheit() {
      return `${ this.htmlId }_kosten_pro_zeiteinheit`;
    },

    optionsWert() {
      return {
        id: "wert",
        htmlId: this.htmlIdWert,
        label: this.labelWert,
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdWert() {
      return `${ this.htmlId }_wert`;
    },

    errorLabels() {
      return {
        projekt: {
          label: this.labelProjekt,
          link: true,
          id: this.htmlIdProjekt,
        },
        taetigkeit_bez: {
          label: this.labelTaetigkeitBez,
          link: true,
          id: this.htmlIdTaetigkeitBez,
        },
        qualifikation_bez: {
          label: this.labelQualifikationBez,
          link: true,
          id: this.htmlIdQualifikationBez,
        },
        zeiteinheit: {
          label: this.labelZeiteinheit,
          link: true,
          id: this.htmlIdZeiteinheit,
        },
        anzahl_zeiteinheiten: {
          label: this.labelAnzahlZeiteinheiten,
          link: true,
          id: this.htmlIdAnzahlZeiteinheiten,
        },
        kosten_pro_zeiteinheit: {
          label: this.labelKostenProZeiteinheit,
          link: true,
          id: this.htmlIdKostenProZeiteinheit,
        },
        wert: {
          label: this.labelWert,
          link: true,
          id: this.htmlIdWert,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
  },
};
