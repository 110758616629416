import Modal from "../../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../../global/mixins/HttpMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "RollenModalAdd",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    baseUrl: {
      type: String,
      default: undefined,
    },
    prefix: {
      type: String,
      default: undefined,
    },
    optionsList: {
      type: Array,
      default: () => [],
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        gueltigkeit: {
          gueltigkeit_after: undefined,
          gueltigkeit_before: undefined,
        }
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    options() {
      return {
        showCloseButton: true,
        required: true,
        list: this.optionsList || [],
      };
    }
  },
  methods: {

    save() {
      this.status.loading = true;
      const DATA = cloneDeep(this.model);
      DATA[`${ this.prefix }_gueltig_ab`] = this.model.gueltigkeit.gueltigkeit_after;
      DATA[`${ this.prefix }_gueltig_bis`] = this.model.gueltigkeit.gueltigkeit_before;
      if (DATA.gueltigkeit) {
        delete DATA.gueltigkeit;
      }

      this.postHttp({ url: this.baseUrl, data: DATA }).then(
        () => {
          this.close({ status: true });
        },
        error => {
          const ERROR = error.data;
          if (ERROR[`${ this.prefix }_gueltig_ab`]) {
            ERROR.gueltigkeit = ERROR[`${ this.prefix }_gueltig_ab`];
            delete ERROR[`${ this.prefix }_gueltig_ab`];
          }
          if (ERROR[`${ this.prefix }_gueltig_bis`]) {
            if (ERROR.gueltigkeit) {
              ERROR.gueltigkeit.push(...ERROR[`${ this.prefix }_gueltig_bis`]);
            } else {
              ERROR.gueltigkeit = ERROR[`${ this.prefix }_gueltig_bis`];
            }
            delete ERROR[`${ this.prefix }_gueltig_bis`];
          }
          this.errors = cloneDeep(ERROR);
          this.errorsClone = cloneDeep(ERROR);
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
