"use strict";

import BaseModel from "dias/core/models/baseModel";

class Katalog extends BaseModel {
  constructor() {
    super("katalog", { pk: "id" });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    const getById = collection.getById;
    collection.getById = id => {
      const result = getById(id);
      if (!angular.isUndefined(result)) {
        return result.plain();
      }
      return result;
    };
    collection.getKatalog = (key, filter, uncached) => {
      if (!filter) {
        filter = {};
      }
      const params = angular.extend(filter, { key: key });
      return collection.withHttpConfig({ cache: !uncached }).getList(params);
    };
    return collection;
  }

  configRestangular(RestangularConfigurer) {
    this.cache = this.$injector.get("$cacheFactory");
    RestangularConfigurer.setDefaultHttpFields({ cache: this.cache });
    RestangularConfigurer.setResponseInterceptor(
      (response, operation) => {
        if (operation === "put" || operation === "post" || operation === "remove") {
          this.cache.removeAll();
        }
        return response;
      }
    );
  }

  createGetChoices(collection) {
    return (key, valueField, labelField, params, groupBy) => {
      const deferred = this.$q.defer();
      const data = [];
      params = params || {};
      params.fields = params.fields || [];
      params.fields.push(valueField, labelField);
      collection.getKatalog(key, params).then(result => {
        if (!groupBy) {
          angular.forEach(result, item => {
            data.push(Object.assign({}, item.plain(), { value: item[valueField], label: item[labelField] }));
          });
        } else {
          angular.forEach(result, item => {
            const existing = data.find(v => v[labelField] === item[labelField]);
            if (existing) {
              existing[valueField] = `${ existing[valueField] }_${ item[valueField] }`;
              existing.value = existing[valueField];
              existing.id = existing[valueField];
            } else {
              data.push(Object.assign({}, item.plain(), { value: item[valueField], label: item[labelField] }));
            }
          });
        }
        deferred.resolve(data);
      });
      return deferred.promise;
    };
  }
}

export default Katalog;
