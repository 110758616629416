import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";

import LoginMixin from "../LoginMixin";

// @vue/component
export default {
  name: "LoginBox",
  components: {
    FormstepDetail,
  },
  mixins: [
    LoginMixin,
  ],
};
