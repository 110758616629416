"use strict";

import KostenController from "../../kosten.controller";


class KostenMitPauschaleController extends KostenController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KKostenart, BetragsrechnerRegistry, i18n) {
    super($scope, $q, Foerdermodule, Foerderantrag, KKostenart, BetragsrechnerRegistry, i18n);
    // Es wird nur ein Eintrag erlaubt:
    this.editType = "single-position";
  }
}

export default KostenMitPauschaleController;
