"use strict";

import angular from "angular";

import template from "./extendable.jade";

class ExtendableController {
  /*@ngInject*/
  constructor($scope, $element, $document, $window) {
    this.outerEl = angular.element($element.children()[0]);
    this.innerEl = angular.element(this.outerEl.children()[0]);

    this.collapsedState = {
      cls: (this.collapsedClass || "") + " collapsed",
      style: { "max-height": this.collapsedHeight || "15em" },
      actionIcon: this.extendActionIcon || "glyphicon-chevron-down",
      actionTitle: this.extendActionTitle || "mehr anzeigen",
      action: () => this.setExtended()
    };

    this.extendedState = {
      cls: (this.extendedClass || "") + " expanded",
      style: { "max-height": "auto" },
      actionIcon: this.collapseActionIcon || "glyphicon-chevron-up",
      actionTitle: this.collapseActionTitle || "weniger anzeigen",
      action: () => this.setCollapsed()
    };

    this.evaluateOverflow = () => {
      const testContainer = angular.element("<div></div>");
      angular.element($document[0].body).append(testContainer);
      testContainer.css({
        display: "inline-block",
        visibility: "hidden",
        "max-height": this.collapsedState.style["max-height"],
        width: this.outerEl[0].clientWidth + "px",
        position: "absolute"
      });
      const inner = this.innerEl.clone();
      testContainer.append(inner);
      this.isOverflowing = inner[0].clientHeight > testContainer[0].clientHeight;
      if (this.isOverflowing) {
        this.outerEl.addClass("overflowing");
      } else {
        this.outerEl.removeClass("overflowing");
      }
      testContainer.remove();
    };

    this.onChange = () => {
      this.evaluateOverflow();
      if (this.isOverflowing) {
        this.state = this.collapsedState;
      }
    };

    // inhalt ändert sich
    $scope.$watch(() => {
      return this.innerEl[0].innerHTML;
    }, (newVal, oldVal) => {
      // nur bei neuem Wert & Element sichtbar ermitteln
      if (oldVal !== newVal && this.outerEl[0].clientWidth > 0) {
        this.onChange();
      }
    });

    // Wird sichtbar
    $scope.$watch(() => {
      return this.outerEl[0].clientWidth;
    }, (newVal, oldVal) => {
      if (newVal !== 0 && oldVal === 0) {
        this.onChange();
      }
    });

    // Fenstergröße ändert sich
    $window.addEventListener("resize", () => {
      if (this.outerEl[0].clientWidth > 0) {
        this.evaluateOverflow();
        $scope.$applyAsync();
      }
    });

    // initial ermitteln, wenn sinnvoll
    if (this.outerEl[0].clientWidth !== 0) {
      this.onChange();
    }
  }

  setCollapsed() {
    this.state = this.collapsedState;
    this.onCollapse();
  }

  setExtended() {
    this.state = this.extendedState;
    this.onExtend();
  }
}

export default () => {
  return {
    restrict: "A",
    transclude: true,
    template: template,
    controller: ExtendableController,
    controllerAs: "vm",
    bindToController: true,
    scope: {
      collapsedHeight: "=?",
      collapsedClass: "=?",
      collapseActionTitle: "=?",
      collapseActionIcon: "=?",
      extendActionTitle: "=?",
      extendActionIcon: "=?",
      extendedClass: "=?",
      onExtend: "&",
      onCollapse: "&",
    },
  };
};
