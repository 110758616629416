"use strict";

import template from "./details.jade";

import fvbEditTemplate from "./foerdervertragsbestaetitgung.edit.jade";
import fvbEditController from "./foerdervertragsbestaetitgung.edit.controller";
import bvEditTemplate from "./bankverbindung.edit.vertrag.jade";
import bvEditController from "./bankverbindung.edit.vertrag.controller";
import abschlagEditTemplate from "./abschlag.edit.vertrag.jade";
import abschlagEditController from "./abschlag.edit.vertrag.controller";
import createManuelleAufgabeTemplate from "dias/workflow/aufgaben/manuelleAufgabe.create.jade";
import createManuelleAufgabeController from "dias/workflow/aufgaben/manuelleAufgabe.create.controller";
import WizardController from "./wizard.controller";
import WizardEinreichenController from "./wizard.einreichen.controller";
import editBelegTemplate from "./belege/modal.beleg.edit.jade";
import editBelegController from "./belege/modal.beleg.edit.component";

import SaveCallbackActions from "../../../vue/client/vue/const/SaveCallbackActions";

import {
  MAPPING,
} from "../../../vue/client/vue/components/Geschaeftsregel/Module/Module";
import {
  findIndex,
  forEach,
  get,
  isNil,
  sortBy,
  isUndefined,
  isArray,
} from "lodash-es";

const ERROR_MODULE_KEYS = {
  vertretungsberechtigte: "Vertretungsberechtigte",
  bankverbindung: "Bankverbindung"
};

const AKTUELLE_AUFGABE = {
  vertrag_in_erstellung: "3e5ce8f4-b44d-45d1-b0a5-95f0b4250389",
  vertrag_auf_postweg: "62ff76b4-9f6b-4301-b7f7-4e526aa257b9"
};

class VertragController {
  /*@ngInject*/
  constructor(
    $rootScope,
    $q,
    $stateParams,
    $filter,
    $state,
    $document,
    AuthService,
    SyConfigs,
    Vertrag,
    DiasNotification,
    diasModalDialog,
    diasConfirmDialog,
    KAufgabe,
    Workflow,
    Nutzeraufgabe,
    WorkflowfunktionRegistry,
    Foerderantrag,
    Antragstellerorganisationen,
    $anchorScroll,
    moment,
    KVertragskostenbelegStatusUUIDs,
    KAuszahlungsplanstatusUUIDs,
    i18n,
    Client,
    $timeout,
    $location
  ) {
    this.$location = $location;
    this.MAPPING = MAPPING;
    this.gettext = i18n.gettext;
    this.viewName = "details";
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;
    this.$filter = $filter;
    this.$q = $q;
    this._ = _;
    this.$state = $state;
    this.$document = $document;
    this.$anchorScroll = $anchorScroll;
    $anchorScroll.yOffset = 255;
    this.$timeout = $timeout;
    this.Vertrag = Vertrag;
    this.AuthService = AuthService;
    this.diasModalDialog = diasModalDialog;
    this.diasConfirmDialog = diasConfirmDialog;
    this.DiasNotification = DiasNotification;
    this.KAufgabe = KAufgabe;
    this.Workflow = Workflow;
    this.Nutzeraufgabe = Nutzeraufgabe;
    this.WorkflowfunktionRegistry = WorkflowfunktionRegistry;
    this.Foerderantrag = Foerderantrag;
    this.Antragstellerorganisationen = Antragstellerorganisationen;
    this.KVertragskostenbelegStatusUUIDs = KVertragskostenbelegStatusUUIDs;
    this.KAuszahlungsplanstatusUUIDs = KAuszahlungsplanstatusUUIDs;
    this.moment = moment;
    this.Client = Client;
    this.SyConfigs = SyConfigs;

    const WF_STATUS_GRUPPEN_IN_HEADER = this.SyConfigs.wert("wf_statusgruppen_in_header") || {};
    WF_STATUS_GRUPPEN_IN_HEADER.objects = WF_STATUS_GRUPPEN_IN_HEADER.objects || [];
    this.statusWFStatusGruppenInHeader = WF_STATUS_GRUPPEN_IN_HEADER.objects.indexOf("vertrag") !== -1;

    this.astAlsButtonConfig = this.SyConfigs.wert("ast_als_button") || {};
    const wizard_conf = this.SyConfigs.wert("vertrag_wizards");
    this.WIZARD_PKS = wizard_conf.ausfuellen_wizard_auto || ["3e5ce8f4-b44d-45d1-b0a5-95f0b4250389"];
    this.WIZARD_PKS_BUTTON = wizard_conf.ausfuellen_wizard_button || ["3e5ce8f4-b44d-45d1-b0a5-95f0b4250389", "648bfbb3-a479-4296-9cde-2d3af0f16824"];
    this.WIZARD_EINREICHEN_PKS = wizard_conf.einreichen_wizard_auto || ["43a8e87b-9bb6-4848-874b-a2359eb2842e"];
    this.WIZARD_EINREICHEN_PKS_BUTTON = wizard_conf.einreichen_wizard_button || ["43a8e87b-9bb6-4848-874b-a2359eb2842e"];
    this.WIZARD_EINREICHEN_REGELN = wizard_conf.einreichen_wizard_regeln || [];


    this.fvbEditTemplate = fvbEditTemplate;
    this.fvbEditController = fvbEditController;
    this.bvEditTemplate = bvEditTemplate;
    this.bvEditController = bvEditController;
    this.abschlagEditController = abschlagEditController;
    this.abschlagEditTemplate = abschlagEditTemplate;

    this.dokart = "2f413911-a2dc-c179-e050-007f0101705e";

    this.showVertragsbeleg = SyConfigs.wert("instance_pages").vertragsbeleg;
    this.showVertragskonto = SyConfigs.wert("instance_pages").vertragskonto;
    this.showVertragAusgezahlt = SyConfigs.wert("instance_pages").vertrag_ausgezahlt;
    this.vertragspersonalstellen = [];

    this.lastWfExecution = new Date().toISOString();

    AuthService.getUser().then(
      user => this.user = user
    );

    this.wfTypes = {};
    this.loadWorkflowTyps();

    this.loading = true;
    this.initData();

    this.reloadVertrag({ statusCheckWizard: true });

    // Timeline
    this.timelineConfig = {
      kontexte: [
        {
          titel: "Vertragserstellung",
          key: "A",
        },
        {
          titel: "Vertragsprüfung",
          key: "F",
        },
      ],
      benachrichtigungen: {
        bezug: "vertrag",
        bezugpk: $stateParams.id,
        autorefresh: true,
      },
      url: `vertraege/${ this.$stateParams.id }/timeline/`,
    };

    this.onAufgabeAngenommen = () => {
      this.$timeout(() => {
        this.reloadVertrag();
      });
    };

    this.onManuelleAufgabenChanged = () => {
      this.$timeout(() => {
        this.vertrag.getList("manuelle_aufgaben").then(
          result => this.manuelleAufgaben = result
        );
      });
    };

    this.createBeleg = () => {
      return this.diasModalDialog({
        title: this.gettext("Neuen Beleg erstellen"),
        // title: "Neuen Beleg erstellen",
        template: editBelegTemplate,
        controller: editBelegController,
        extras: {
          vertrag: this.vertrag,
        },
      }).then(
        response => response
      );
    };


    this.initEinreichenWizardData();
    this.updateModuleFromVue = this.updateModuleFromVue.bind(this);
    this.updateEinreichenWizardModuleFromVue = this.updateEinreichenWizardModuleFromVue.bind(this);
    this.showErrorModulName = this.showErrorModulName.bind(this);
    this.showBuchungen = this.showBuchungen.bind(this);
    this.updateAufgabeFromVue = this.updateAufgabeFromVue.bind(this);
    this.onWorkflowInit = this.onWorkflowInit.bind(this);
    this.onWorkflowStarted = this.onWorkflowStarted.bind(this);
    this.onWorkflowFinish = this.onWorkflowFinish.bind(this);
    this.onWorkflowCanceled = this.onWorkflowCanceled.bind(this);
    this.onWorkflowSuccess = this.onWorkflowSuccess.bind(this);
    this.onWorkflowFailure = this.onWorkflowFailure.bind(this);
    this.saveCallback = this.saveCallback.bind(this);
    this.onModulSave = this.onModulSave.bind(this);
    this.updateVertrag = this.updateVertrag.bind(this);

    this.weitereAktionen = {
      fvb_erstellen: () => {
        return !isNil(this.vertrag.foerdervertragsbestaetigung.dok_url);
      },
      aufgabe_erstellen: () => {
        return this.AuthService.syncHasPerm("aufgabe.create");
      },
      wizardAusfuellhilfe: () => {
        return this.isShowWizardButton();
      },
      wizardEinreichen: () => {
        return this.isShowEinreichenWizardButton();
      },
      dhwVerwendungsnachweis: () => {
        return this.AuthService.syncHasPerm("dhw_verwendungsnachweisdokument.view", this.vertrag.user_permissions);
      },
      vertragValidate: () => {
        return this.AuthService.syncHasPerm("vertrag.validieren", this.vertrag.user_permissions);
      }
    };
  }

  showWeitereAktionen() {
    let show = false;
    forEach(this.weitereAktionen, func => {
      if (func()) {
        show = true;
        return false; // forEach abbrechen
      }
    });
    return show;
  }

  initData() {
    this.initNotizenData();
    ERROR_MODULE_KEYS.vertretungsberechtigte = this.gettext("_LBL_VERTRAG_DETAILS_ERROR_MODULE_KEY_");
    this.reload = false;
  }

  initNotizenData() {
    this.notizOptions = { orgChoices: [] };
    this.stepMap = [
      "step_ubersicht",
      "step_foerdervertrag",
      "step_belege",
      "step_auszahlung",
      "step_berichte",
      "step_weiteredaten"
    ];
    this.steps = [
      {
        key: "step_ubersicht",
        kbez: "Übersicht",
      },
      {
        key: "step_personalbaum",
        kbez: this.gettext("_TXT_VERTRAG_DETAILS_PERSONALBAUM_HEADER_CONTEXT_"),
      },
      {
        key: "step_foerdervertrag",
        kbez: this.gettext("_TXT_VERTRAG_DETAILS_FOERDERVERTRAG_BESTAETIGUNG_"),
      },
      {
        key: "step_belegliste",
        kbez: this.gettext("Belegliste"),
      },
      {
        key: "step_auszahlung",
        kbez: this.gettext("Auszahlung"),
      },
      {
        key: "step_berichte",
        kbez: "Berichte",
      }
    ];

    if (this.Client === "dias-kumasta") {
      this.removeFromSteps({ key: "step_foerdervertrag" });
    }
  }

  loadWorkflowTyps() {
    this.Workflow.getList().then(
      response => {
        this.filterWorkflowTyps(response);
      }
    );
  }

  getWorkflowfolgeUpdateKey() {
    if (this.vertrag) {
      return `${ this.lastWfExecution }`;
    }
  }

  filterWorkflowTyps(wfTypes) {
    angular.forEach(wfTypes, item => this.wfTypes[item.pk] = item);
  }

  reloadVertrag({ setEinreichenStatus, statusCheckWizard } = {}) {
    this.reload = true;
    this.canCreateBeleg = false;
    return this.Vertrag.one(this.$stateParams.id).get().then(
      response => {
        this.timelineCsvUrl = response.getRestangularUrl() + "/timeline/";
        this.timelineFullCsvUrl = response.getRestangularUrl() + "/timeline_full/";
        if (setEinreichenStatus && this.vertrag.aktuelle_aufgabe.aufgabe === AKTUELLE_AUFGABE.vertrag_in_erstellung && response.aktuelle_aufgabe.aufgabe === AKTUELLE_AUFGABE.vertrag_auf_postweg) {
          this.einreichenStatus = true;
        }
        if (!this.vertrag || this.vertrag.pk === undefined) {
          this.vertrag = response;
        } else {
          // only copy server received properties into existing sitzung object
          angular.extend(this.vertrag, response.plain());
          // this.vertrag = this.vertrag.merge(this.vertrag, response);
        }
        const conf = this.vertrag.aktuelle_aufgabe.au_kontext_config;
        this.anzeigekontexteVertrag = conf && conf.markiert && conf.markiert.vertrag ?
            conf.markiert.vertrag : {};

        this.vertragLabel = this.$filter("spaced")(this.vertrag.v_nr);
        this.checkPermissionCreateBeleg();
        this.checkPermissionModule();
        if (statusCheckWizard) {
          this.checkVertragWizard();
          this.checkVertragEinreichenWizard();
        }

        const promises = [];
        if (this.AuthService.syncHasPerm("auszahlung.view")) {
          promises.push(this.loadAuszahlungen());
          promises.push(this.loadAuszahlungsplan());
          this.statusAuszahlungView = true;
        }
        if (this.AuthService.syncHasPerm("vertragskostenpersonalstellen.view", this.vertrag.user_permissions)) {
          promises.push(this.loadVertragspersonalstellen());
        }
        this.loadAntragstellerorg();
        promises.push(this.loadVertragsmodule());
        this.canAuflagenpruefen = this.AuthService.syncHasPerm("vertrag.auflagen.pruefen", this.vertrag.user_permissions);
        const belegSichtbarkeit = this.SyConfigs.wert("belege_sichtbarkeit");
        const astorgKontext = this.user.kontexte.find(k => k.org_id === this.vertrag.antragstellerorg.pk);
        this.canViewBelege = this.AuthService.syncHasPerm("vertragskostenpositionsbelege.view", this.vertrag.user_permissions);
        this.AuthService.syncHasPerm("dhw_verwendungsnachweisdokument.view", this.vertrag.user_permissions);
        if (this.canViewBelege && belegSichtbarkeit.astorgrollen && belegSichtbarkeit.astorgangebote && astorgKontext) {
          const foundR = belegSichtbarkeit.astorgrollen.findIndex(r => astorgKontext.rollen.indexOf(r) !== -1);
          const foundA = belegSichtbarkeit.astorgangebote.findIndex(foe_id => foe_id === this.vertrag.foe_id);
          this.canViewBelege = foundR === -1 || foundA === -1;
        }
        this.loadKonto();
        this.$q.all(promises).finally(() => {
          if (!(this.vertragspersonalstellen && this.vertragspersonalstellen.length)) {
            this.removeFromSteps({ key: "step_personalbaum" });
          }
          if (!(this.auszahlungsplan && this.auszahlungsplan.length)) {
            this.removeFromSteps({ key: "step_auszahlung" });
          }
          if (!(this.showVertragsbeleg && this.canViewBelege)) {
            this.removeFromSteps({ key: "step_belegliste" });
          }
          this.reload = false;
          this.loading = false;
          this.goToPersonalBaum();
        });
      }
    );
  }

  loadKonto() {
    this.konto = null;
    if (this.canViewBelege && this.showVertragsbeleg) {
      this.vertrag.getKonto().then(
        response => {
          this.konto = response;
          this.kontoParams = {
            vertrag: "vm.vertrag",
            konto: "vm.konto",
          };
          if (this.konto && this.konto.mit_buchungen) {
            this.kontoParams["on-saldo-click"] = this.showBuchungen;
          }
        }
      );
    }
  }

  loadAntragstellerorg() {
    this.Antragstellerorganisationen.one(this.vertrag.antragstellerorg.pk).get().then(
      response => {
        this.ast = response;
        this.canViewVertretungsberechtigte = this.AuthService.syncHasPerm("astorgvertreter.view", this.ast.user_permissions);
        this.initWizardData();
      }
    );
  }

  loadAuszahlungen() {
    return this.vertrag.auszahlungen.getList().then(
      response => {
        this.auszahlungen = response;
        this.auszahlungenLastChanged = new Date().getTime();
      }
    );
  }

  loadAuszahlungsplan() {
    return this.vertrag.auszahlungsplan.getList().then(
      response => {
        this.auszahlungsplan = response;
        this.loadCurrentAuszahlungsplanWorkflow();
        this.auszahlungsplanLastChanged = new Date().getTime();
      }
    );
  }

  saveCallback({ statusSaveCallback } = {}) {
    if (statusSaveCallback === SaveCallbackActions.RELOAD_WF) {
      this.reloadWorkflowfolgen();
    } else if (statusSaveCallback === SaveCallbackActions.RELOAD_ALL) {
      this.reloadVertrag();
      this.reloadWorkflowfolgen();
    }
  }

  /** Auszahlungsplan workflow */

  loadCurrentAuszahlungsplanWorkflow() {
    this.loadAuszahlungsplanWorkflow = true;
    let first = this.auszahlungsplan[0];
    for (let i = 1; i < this.auszahlungsplan.length; i++) {
      if (first.zp_nummer > this.auszahlungsplan[i].zp_nummer) {
        first = this.auszahlungsplan[i];
      }
    }
    if (first) {
      first.get().then(
        response => {
          this.primaryAuszahlungsplan = response;
          this.canAdminAuszahlungsplan = false;
          this.AuthService.hasOnePerm([
            "auszahlungsplan.berechnungsmodus",
            "auszahlungsplan.fristen.update",
            "auszahlungsplan.oeffnen"
          ], this.primaryAuszahlungsplan.user_permissions).then(
            hasAny => this.canAdminAuszahlungsplan = hasAny
          );
        },
        err => this.DiasNotification.page.error("Fehler beim Abrufen des Auszahlungsplan Workflows", err)
      ).finally(() => this.loadAuszahlungsplanWorkflow = false);
    } else {
      this.loadAuszahlungsplanWorkflow = false;
    }
  }
  /** END Auszahungsplan Workflow */

  groupModuleByAnzeigekontext(modules) {
    if (!modules || modules.length === 0) {
      return [];
    }
    const GROUPED = [];
    const GROUPED_OBJ = {};
    forEach(modules, mod => {
      const ANZEIGEKONTEXT = mod.regel.anzeigekontext_obj;
      if (isUndefined(GROUPED_OBJ[ANZEIGEKONTEXT.pk])) {
        GROUPED_OBJ[ANZEIGEKONTEXT.pk] = GROUPED.length;
        GROUPED.push([
          ANZEIGEKONTEXT,
          [mod],
        ]);
      } else {
        GROUPED[GROUPED_OBJ[ANZEIGEKONTEXT.pk]][1].push(mod);
      }
    });

    forEach(GROUPED, el => {
      el[1] = sortBy(el[1], modul => modul.regel.afr_pos);
    });

    return GROUPED;
  }

  loadVertragsmodule() {
    if (!this.AuthService.syncHasPerm("vertrag.module.view", this.vertrag.user_permissions)) {
      const defer = this.$q.defer();
      defer.resolve([]);
      return defer.promise;
    }
    return this.Vertrag.one(this.$stateParams.id).module.getList(
      {
        ordering: "regel__anzeigekontext__faz_pos"
      }
    ).then(
      response => {
        this.module = this.groupModuleByAnzeigekontext(response);
        this.notizen = this.notizen || {};
        response.forEach(modul => {
          this.notizen[modul.regel.pk] = this.notizen[modul.regel.pk] || [];
        });
        this.einreichenWizardModule = _.filter(response, modul => this.WIZARD_EINREICHEN_REGELN.indexOf(modul.regel.regel) !== -1);
        this.completeNotizenData();
      }
    );
  }

  completeNotizenData() {
    angular.forEach(this.module, mod => {
      this.stepMap.push(_.trim(mod[0].faz_kbez));
      this.steps.push({
        key: _.trim(mod[0].faz_kbez),
        kbez: mod[0].faz_kbez
      });
    });
  }

  showErrorModulName(error) {
    if (error && error.anzeigekontext && error.anzeigekontext.key) {
      return ERROR_MODULE_KEYS[error.anzeigekontext.key];
    }
    if (error && error.pk) {
      const IDX = _.findIndex(this.module, mod => {
        return error.pk === mod[0].pk;
      });
      return IDX !== -1 ? this.module[IDX][0].faz_kbez : "";
    }
  }

  goTo(error, { onRegel = false } = {}) {
    const ID = error.context || (error.anzeigekontext && error.anzeigekontext.pk) || error.pk;
    const anchor = this.$document[0].getElementById(ID);
    const angularAnchor = anchor ? angular.element(anchor) : null;

    if (angularAnchor && !angularAnchor.hasClass("active")) {
      angularAnchor.addClass("active");
    }

    this.$timeout(() => {
      if (error && ((error.anzeigekontext && (error.anzeigekontext.key || error.anzeigekontext.pk)) || error.pk)) {
        const messageBoxFixed = $("#message-box-fixed").outerHeight(true) || 0;
        const navbarFixedTop = $("#navbar-fixed-top").outerHeight(true) || 0;
        const alwaysVisible = $("#always-visible");
        const alwaysVisibleHeight = alwaysVisible.hasClass("fixed") ? $("#always-visible").outerHeight(true) - 10 : $("#always-visible").outerHeight(true) + 35;
        const proandiHeaderHeight = $(".navbar-static-top").outerHeight(true) || 0;
        let EL;
        if (onRegel) {
          EL = error.modul && error.modul.regelnummer;
        } else {
          EL = error.context || (error.anzeigekontext && error.anzeigekontext.pk) || error.pk;
        }
        const elementError = $(`#${ EL }`);
        let elementTop = 0;
        if (elementError && elementError.offset()) {
          elementTop = elementError.offset().top;
        }
        const offset = messageBoxFixed + navbarFixedTop + alwaysVisibleHeight + proandiHeaderHeight;
        $("html, body").animate({
          scrollTop: elementTop - offset
        }, 500);
      }
    }, 500);
  }

  changeTimelineStatus() {
    this.timelineStatus = !this.timelineStatus;
  }

  resetValidation() {
    this.validationErrorsLoading = undefined;
    this.validationErrors = undefined;
  }

  createNewAufgabe() {
    return this.diasModalDialog({
      title: "Neue Aufgabe erstellen",
      template: createManuelleAufgabeTemplate,
      controller: createManuelleAufgabeController,
      extras: {
        obj: this.vertrag,
        reloadPage: this.onManuelleAufgabenChanged
      }
    });
  }

  changeView() {
    this.viewName = this.viewName === "details" ? "timeline" : "details";
  }

  showBuchungen() {
    this.$timeout(() => {
      this.viewName = "buchungen";
    });
  }

  canEditVertretungsberechtigte() {
    if (!this.vertrag) {
      return false;
    }
    return this.AuthService.syncHasPerm("vertrag.fvb.update", this.vertrag.user_permissions);
  }

  checkPermissionModule() {
    this.canUpdateModule = this.AuthService.syncHasPerm("vertrag.module.update", this.vertrag.user_permissions);
  }

  scrollTop() {
    $(window).scrollTop(0);
  }

  // Wizard
  isShowWizardButton() {
    return this.vertrag.aktuelle_aufgabe && this.vertrag.aktuelle_aufgabe.aufgabe && this.WIZARD_PKS_BUTTON.indexOf(this.vertrag.aktuelle_aufgabe.aufgabe) !== -1;
  }

  checkVertragWizardButton() {
    this.viewName = "wizard";
  }

  checkVertragWizard() {
    if (this.vertrag.aktuelle_aufgabe && this.vertrag.aktuelle_aufgabe.aufgabe && this.WIZARD_PKS.indexOf(this.vertrag.aktuelle_aufgabe.aufgabe) !== -1) {
      this.viewName = "wizard";
    }
  }

  initWizardData() {
    this.wizardController = WizardController;

    this.wizardData = [
      {
        icon: "glyphicon-foerderung",
        label: "Dokumente und Hinweise",
        template: "WizardStep1.html",
        tooltip: "Dokumente und Hinweise",
        class: " ",
      },
      {
        icon: "glyphicon-foerderung",
        label: "Durchführungszeitraum",
        template: "WizardStep2.html",
        tooltip: "Durchführungszeitraum",
        class: " ",
      },
    ];

    if (this.vertrag.foerdervertragsbestaetigung.abschlag_moeglich || this.vertrag.foerdervertragsbestaetigung.bv_gefordert) {
      this.wizardData.push({
        icon: "glyphicon-foerderung",
        label: "Bankverbindung",
        template: "WizardStep3.html",
        tooltip: "Bankverbindung",
        key: "bankverbindung",
        class: " ",
      });
    }

    if (this.vertrag.foerdervertragsbestaetigung.abschlag_moeglich) {
      this.wizardData.push({
        icon: "glyphicon-foerderung",
        label: "Abschlag",
        template: "WizardStep4.html",
        tooltip: "Abschlag",
        key: "abschlag",
        class: " ",
      });
    }
    this.wizardData.push(
      {
        icon: "glyphicon-foerderung",
        label: this.gettext("_LBL_AST_DETAILS_VERTRETUNGSBERECHTIGTER_DETAIL_"),
        template: "WizardStep5.html",
        tooltip: this.gettext("_LBL_AST_DETAILS_VERTRETUNGSBERECHTIGTER_DETAIL_"),
        key: "vertretungsberechtigte",
        class: " ",
      },
      {
        icon: "glyphicon-foerderung",
        label: "Zusammenfassung",
        template: "WizardStep6.html",
        tooltip: "Zusammenfassung",
        class: " ",
      },
    );
    this.statusWizardInit = true;
  }

  closeWizard() {
    this.viewName = "details";
    this.checkVertragEinreichenWizard();
  }
  // Wizard /

  // Einreichen-Wizard

  isShowEinreichenWizardButton() {
    return this.vertrag.aktuelle_aufgabe && this.vertrag.aktuelle_aufgabe.aufgabe && this.WIZARD_EINREICHEN_PKS_BUTTON.indexOf(this.vertrag.aktuelle_aufgabe.aufgabe) !== -1;
  }

  activateVertragEinreichenWizardButton() {
    this.viewName = "wizard-einreichen";
  }

  checkVertragEinreichenWizard() {
    if (this.vertrag.aktuelle_aufgabe && this.vertrag.aktuelle_aufgabe.aufgabe && this.WIZARD_EINREICHEN_PKS.indexOf(this.vertrag.aktuelle_aufgabe.aufgabe) !== -1) {
      this.viewName = "wizard-einreichen";
    }
  }

  initEinreichenWizardData() {
    this.wizardEinreichenController = WizardEinreichenController;

    this.wizardEinreichenData = [
      {
        icon: "glyphicon-foerderung",
        label: this.gettext("_LBL_VERTRAG_WIZARD_EINREICHEN_STEP1_"),
        template: "WizardEinreichenStep1.html",
        tooltip: this.gettext("_LBL_VERTRAG_WIZARD_EINREICHEN_STEP1_TOOLTIP_"),
        class: " ",
      },
      {
        icon: "glyphicon-foerderung",
        label: this.gettext("_LBL_VERTRAG_WIZARD_EINREICHEN_STEP2_"),
        template: "WizardEinreichenStep2.html",
        tooltip: this.gettext("_LBL_VERTRAG_WIZARD_EINREICHEN_STEP2_TOOLTIP_"),
        class: " ",
      },
    ];

    this.statusWizardEinreichenInit = true;
  }

  closeEinreichenWizard() {
    this.viewName = "details";
  }

  // Einreichen-Wizard

  checkPermissionCreateBeleg() {
    this.canCreateBeleg = false;
    this.canUpdateBeleg = false;
    this.canDeleteBeleg = false;
    this.vertrag.checkPermissions([
      "vertragskostenpositionsbelege.create",
      "vertragskostenpositionsbelege.create.liste",
      "vertragskostenpositionsbelege.update",
      "vertragskostenpositionsbelege.delete",
      "vertragskostenpositionsbelege.ablehnen",
      "vertragskostenpositionsbelege.anerkennen",
    ], true).then(
      response => {
        this.canCreateBeleg = response.indexOf("vertragskostenpositionsbelege.create") !== -1;
        this.canCreateBelegListe = response.indexOf("vertragskostenpositionsbelege.create.liste") !== -1;
        this.canUpdateBeleg = response.indexOf("vertragskostenpositionsbelege.update") !== -1;
        this.canDeleteBeleg = response.indexOf("vertragskostenpositionsbelege.delete") !== -1;
        this.canAblehnenBeleg = response.indexOf("vertragskostenpositionsbelege.ablehnen") !== -1;
        this.canAnerkennenBeleg = response.indexOf("vertragskostenpositionsbelege.anerkennen") !== -1;
      }
    );
  }

  loadVertragspersonalstellen() {
    return this.Vertrag.one(this.$stateParams.id).one("vertragspersonalstellen").getList().then(
      response => {
        this.vertragspersonalstellen = response.plain();
      }
    );
  }

  goToPersonalBaum() {
    if (this.$stateParams.panel !== "step_personalbaum") {
      return;
    }
    this.$timeout(() => {
      const PERSONALBAUM_HEADER = document.getElementById("personalbaum_header");
      if (PERSONALBAUM_HEADER) {
        PERSONALBAUM_HEADER.click();
        this.$location.search({});
      }
    });
  }

  updateModuleFromVue({ group, response }) {
    this.$timeout(() => {
      if (!isNil(group) && this.module[group][1]) {
        const INDEX = findIndex(this.module[group][1], ["regel.pk", response.regel.pk]);
        if (INDEX !== -1) {
          this.module[group][1].splice(INDEX, 1, response);
        }
      }
    });
  }
  updateEinreichenWizardModuleFromVue({ response }) {
    this.$timeout(() => {
      const INDEX = findIndex(this.einreichenWizardModule, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.einreichenWizardModule.splice(INDEX, 1, response);
      }
    });
  }
  isRegelValidation() {
    return this.validationErrors && isArray(this.validationErrors[0]);
  }

  updateAufgabeFromVue(newAufgabe) {
    this.$timeout(() => {
      this.updateAufgabe(newAufgabe);
    });
  }

  updateAufgabe(newAufgabe) {
    Object.assign(this.vertrag.aktuelle_aufgabe, newAufgabe);
  }

  removeFromSteps({ key = "" }) {
    const INDEX = findIndex(this.steps, ["key", key]);
    if (INDEX !== -1) {
      this.steps.splice(INDEX, 1);
    }
  }

  onValidate() {
    this.validationErrorsLoading = true;
    this.vertrag.customGET("validieren").then(
      result => {
        this.validationErrors = result;
      },
      () => this.DiasNotification.page.error(this.gettext("_ERR_VERTRAG_DETAILS_VALIDIEREN_"))
    ).finally(() => {
      this.validationErrorsLoading = false;
      this.scrollTop();
    });
  }

  onWorkflowInit(clientFunktion) {
    this.$timeout(() => {
      this.reload = true;
      this.clientFunktion = clientFunktion;
    });
  }

  onWorkflowStarted(newAufgabe, currentWorkflowfolge) {
    this.$timeout(() => {
      this.updateAufgabe(newAufgabe);
      this.currentWorkflowfolge = currentWorkflowfolge;
    });
  }

  onWorkflowFinish() {
    this.$timeout(() => {
      this.reload = false;
      this.clientFunktion = undefined;
      this.currentWorkflowfolge = undefined;
    });
  }

  onWorkflowCanceled() {
    this.$timeout(() => {
      this.reload = false;
      this.clientFunktion = undefined;
    });
  }

  onWorkflowSuccess(aufgabe) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerSuccess(aufgabe);
      }
      this.validationErrors = undefined;
      this.viewName = "details";
      this.scrollTop();
      if (get(this.currentWorkflowfolge, "wfo_reload")) {
        return this.reloadVertrag({ setEinreichenStatus: true, statusCheckWizard: true });
      }
    });
  }

  onWorkflowFailure(err) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerError(err);
      }
      if (err.data && err.data.error_data) {
        this.validationErrors = err.data.error_data;
      } else if (err.data && !angular.isString(err.data)) {
        this.validationErrors = err.data;
      } else {
        this.validationErrors = undefined;
      }
      this.scrollTop();
      return this.reloadVertrag();
    });
  }

  onModulSave() {
    this.$timeout(() => {
      this.lastWfExecution = new Date().toISOString();
    });
  }

  updateVertrag({ vertrag }) {
    this.$timeout(() => {
      Object.assign(this.vertrag, vertrag);
    });
  }
}

export default {
  template: template(),
  controller: VertragController,
  controllerAs: "vm"
};
