import FormElement from "../../FormElement/FormElement.vue";
import Modal from "../../Modal/Modal.vue";
import PuxButton from "../../PuxButton/PuxButton.vue";
import PuxIcon from "../../PuxIcon/PuxIcon.vue";
import PuxLabel from "../../FormElement/PuxLabel/PuxLabel.vue";
import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";

import translate from "../../../directives/translate";

import HttpMixin from "../../../mixins/HttpMixin";
import NotificationMixin from "../../../mixins/NotificationMixin";
import PermissionMixin from "../../../mixins/PermissionMixin";
import TableFilterMixin from "../TableFilter.mixin";

import GlobalOptions from "../../../const/GlobalOptions";
import {
  EventBus,
} from "../../../functions/event-bus";
import {
  form_element_view,
} from "../../../functions/mappingForParameterToFormElement";
import {
  createNamespacedHelpers,
} from "vuex";
import {
  cloneDeep,
  concat,
  find,
  findIndex,
  forEach,
  get,
  isArray,
  isNil,
  isPlainObject,
  values,
  uniq,
} from "lodash-es";

const {
  mapMutations,
  mapGetters,
} = createNamespacedHelpers("table");

// @vue/component
export default {
  name: "TableFilterTop",
  components: {
    FormElement,
    Modal,
    PuxButton,
    PuxIcon,
    PuxLabel,
    PuxTranslate,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
    TableFilterMixin,
  ],
  data() {
    return {
      alwaysVisibleFilters: [],
      filterSave: GlobalOptions.tableOptions.filterSave,
      hideFilters: [],
      hideFiltersModel: undefined,
      hideFiltersOptions: {
        id: `${ this.options.id }_filter_list`,
        keyId: "id",
        keyLabel: "label",
        type: "select",
        buttonClass: "btn btn-default test_add_filter",
        search: true,
        searchList: ["label"],
        keyGroup: "group",
        menuWidthAll: false,
        fixedPlaceholder: `<span class="text-nowrap">Filter hinzufügen</span>`,
        keyGroupList: undefined,
        sortList: ["label"],
        dataTranslate: true,
      },
      filterMain: undefined,
      searchList: [],
      searchListModel: undefined,
      searchListName: undefined,
      showFilters: [],
      status: {
        loading: true,
        loadingSavedFilters: true,
        dataListLoading: {},
        modal: undefined,
        isAuthenticated: undefined,
        modalDelete: undefined,
        modalDeleteLoading: undefined,
      },
      init: undefined,
      statusSaveFilter: undefined,
      // Modal
      modelModal: {
        name: "",
      },
      modalOptions: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "readonly",
            type: "readonly",
            text: "Bitte geben Sie einen Namen für Ihre zu speichernde Suche ein. Die Eingabe eines bestehenden Namens überschreibt die bestehende Suche. Bei Eingabe eines neuen Namens wird eine neue Suche gespeichert."
          },
          {
            id: "name",
            type: "text",
            label: "Name",
            required: true,
            view: "v",
          },
        ],
      },
      selectorCloseForModalSave: undefined,
      eventBussesForFilter: {},
      isBoxVisible: false,
    };
  },
  computed: {
    isButtonDeleteFilterVisible() {
      return !!this.searchListModel && this.searchListModel !== "null";
    },

    isButtonSaveSearchVisible() {
      return this.filterSave && this.status.isAuthenticated && !this.isBoxVisible;
    },

    idForButtonDeleteSavedFilter() {
      return `${ this.idLocal }_btn_del_saved_filter`;
    },

    idForButtonStartSearch() {
      return `${ this.idLocal }_btn_search_start`;
    },

    idForButtonSaveSearch() {
      return `${ this.idLocal }_btn_search_save`;
    },

    idForButtonToggle() {
      return `${ this.idLocal }_btn_toggle`;
    },

    idForCollapsedBox() {
      return `${ this.idLocal }_box_collapsed`;
    },

    idForButtonCloseFilter() {
      return `${ this.idLocal }_close_filter_`;
    },

    idForButtonSearch() {
      return `${ this.idLocal }_btn_search`;
    },

    idForButtonSearchModalOpen() {
      return `${ this.idLocal }_btn_search_modal`;
    },

    idForButtonSearchModalSave() {
      return `${ this.idLocal }_btn_search_modal_save`;
    },

    idForButtonSearchModalDelete() {
      return `${ this.idLocal }_btn_search_modal_delete`;
    },

    searchListOptions() {
      return {
        id: `${ this.idLocal }_search_list`,
        label: "_LBL_TABLE_FILTER_MY_SAVED_SEARCHES_",
        type: "select",
        view: "v",
        keyLabel: "nfi_kbez",
        keyId: "pk",
        deselect: false,
        paddingRight: "65px",
        menuWidthAll: true,
        emptyLabel: "_LBL_TABLE_FILTER_NEW_SEARCH_",
        emptyValue: "null",
      };
    },

    isSelectSearchListDisabled() {
      return this.status.loadingSavedFilters || !this.searchList.length;
    },

    iconForButtonToggle() {
      return this.isBoxVisible ?
        "chevron-up" :
        "chevron-down";
    },

    isAddFilterDisabled() {
      return !this.hideFilters.length;
    },

    textForButtonToggle() {
      return this.isBoxVisible ?
        "_BTN_TABLE_FILTER_ADVANCED_SEARCH_CLOSE_" :
        "_BTN_TABLE_FILTER_ADVANCED_SEARCH_OPEN_";
    },

    extraStaticForFilters() {
      return {
        isLabelVisible: true,
        notLoadList: true,
        initLabel: this.initCurrentModelLabelForSearchGlobal,
      };
    },

    ...mapGetters([
      "GET_DATA_LIST",
      "GET_TABLE_DEFINED_CURRENT_MODEL",
      "GET_LABEL_BY_ID",
      "GET_LABEL_ARRAY_BY_ID",
    ]),
  },
  created() {
    this.initAllFilters();
    this.initDataForFilters();
    this.loadSavedFilters();
  },
  beforeUnmount() {
    this.destroyEventBusses();
  },
  methods: {
    initAllFilters() {
      if (this.options.filterKeyGroupList) {
        this.hideFiltersOptions.keyGroupList = this.options.filterKeyGroupList;
      }

      this.alwaysVisibleFilters = [];
      this.showFilters = [];
      this.hideFilters = [];
      forEach(this.filters, filter => {
        const currentModel = this.GET_TABLE_DEFINED_CURRENT_MODEL({ tableId: this.options.id, filterId: filter.key || filter.id, });
        if (filter.main) {
          const FILTER_MAIN = cloneDeep(filter);
          FILTER_MAIN.view = form_element_view[filter.type] || "v-alt";
          FILTER_MAIN.htmlId = `filter_main_${ FILTER_MAIN.id }`;
          this.filterMain = FILTER_MAIN;
        } else if (filter.alwaysVisible) {
          this.alwaysVisibleFilters.push(cloneDeep(filter));
        } else if (filter.initialStatus || (isArray(currentModel) && currentModel.length)) {
          this.showFilters.push(cloneDeep(filter));
        } else if (!isArray(currentModel) && !isNil(currentModel) &&
          this.hasDateRangeFilterModel(filter, currentModel) &&
          this.hasNumberRangeFilterModel(filter, currentModel)) {
          this.showFilters.push(cloneDeep(filter));
        } else {
          this.hideFilters.push(cloneDeep(filter));
        }
      });
    },

    hasDateRangeFilterModel(filter, currentModel) {
      const FILTER_ID = filter.key || filter.id;
      return filter.type !== "daterange" ||
        (filter.type === "daterange" && (currentModel[`${ FILTER_ID }_after`] || currentModel[`${ FILTER_ID }_before`]));
    },

    hasNumberRangeFilterModel(filter, currentModel) {
      const FILTER_ID = filter.key || filter.id;
      return filter.type !== "numberrange" ||
        (filter.type === "numberrange" && (currentModel[`${ FILTER_ID }_min`] || currentModel[`${ FILTER_ID }_max`]));
    },

    initDataForFilters() {
      forEach(this.filters, filter => {
        if (filter.data) {
          this.MUT_ADD_DATA_LIST({
            tableId: this.options.id,
            filterId: filter.key || filter.id,
            list: cloneDeep(filter.data),
          });
        } else if (!filter.searchGlobal && filter.url) {
          this.loadDataList({ filter });
        }
        if (filter.event) {
          this.setEventBusForFilterForUpdateData(filter);
        }
      });
    },

    setEventBusForFilterForUpdateData(filter) {
      const EVENT = filter.event;
      this.eventBussesForFilter[EVENT] = true;
      EventBus.$on(EVENT, () => this.updateDataForFilter(EVENT));
    },

    loadSavedFilters() {
      if (this.isAuthenticatedSinc()) {
        this.status.isAuthenticated = true;
      } else {
        this.status.isAuthenticated = false;
        return;
      }
      this.getHttp({
        url: "nutzerfilter/",
        urlParams: {
          nfi_kennung: this.options.id,
        },
      }).then(
        response => {
          this.searchList = response;
          this.status.loadingSavedFilters = false;
        }
      );
    },

    updateDataForFilter(EVENT) {
      const FILTER = find(this.filters, ["event", EVENT]);
      if (FILTER) {
        this.loadDataList({ filter: FILTER });
      }
    },

    loadDataList({ filter }) {
      this.status.dataListLoading[filter.key || filter.id] = true;
      this.getChoicesHttp({
        url: filter.url,
      }).then(
        response => {
          let list;
          if (filter.combineCopies) {
            list = this.onCombineCopiesData({ list: response, filter });
          } else {
            list = response;
          }

          this.checkFilterModel({
            filter,
            list,
          });
          this.initModelLabel({
            filter,
            response: list,
          });
          this.MUT_ADD_DATA_LIST({
            tableId: this.options.id,
            filterId: filter.key || filter.id,
            list,
          });
          this.status.dataListLoading[filter.key || filter.id] = false;
        }
      );
    },

    onCombineCopiesData({ list, filter }) {
      const KEY_ID = filter.keyId || "id";
      const KEY_LABEL = filter.keyLabel || "label";
      const LIST_COPY_OBJ = {};
      forEach(list, item => {
        if (LIST_COPY_OBJ[item[KEY_LABEL]]) {
          LIST_COPY_OBJ[item[KEY_LABEL]][KEY_ID] = `${ LIST_COPY_OBJ[item[KEY_LABEL]][KEY_ID] }_${ item[KEY_ID] }`;
        } else {
          LIST_COPY_OBJ[item[KEY_LABEL]] = item;
        }
      });
      return values(LIST_COPY_OBJ);
    },

    checkFilterModel({ filter, list }) {
      let currentModelNew = undefined;
      let statusChangeModel = false;
      const CURRENT_MODEL = cloneDeep(this.GET_TABLE_DEFINED_CURRENT_MODEL({
        tableId: this.options.id,
        filterId: filter.id,
      }));
      if (isArray(CURRENT_MODEL)) {
        if (CURRENT_MODEL.length) {
          currentModelNew = [];
          if (filter.combineCopies) {
            const tempObj = this.checkFilterModelForCombineCopies({ filter, list, CURRENT_MODEL });
            currentModelNew = tempObj.currentModelNew;
            statusChangeModel = tempObj.statusChangeModel;
          } else {
            forEach(CURRENT_MODEL, model => {
              if (findIndex(list, [filter.keyId, model]) !== -1) {
                currentModelNew.push(model);
              } else {
                statusChangeModel = true;
              }
            });
          }
        }
      } else if (!isNil(CURRENT_MODEL)) {
        if (filter.combineCopies) {
          const tempObj = this.checkFilterModelForCombineCopies({ filter, list, CURRENT_MODEL });
          currentModelNew = tempObj.currentModelNew;
          statusChangeModel = tempObj.statusChangeModel;
        } else {
          if (findIndex(list, [filter.keyId, CURRENT_MODEL]) !== -1) {
            currentModelNew = CURRENT_MODEL;
          } else {
            statusChangeModel = true;
          }
        }
      }
      if (statusChangeModel) {
        if (this.model[filter.id]) {
          this.model[filter.id] = currentModelNew;
        }
        this.MUT_CHANGE_CURRENT_MODEL_TABLE_BY_ID({
          tableId: this.options.id,
          model: currentModelNew,
          filterId: filter.id,
        });
      }
    },

    checkFilterModelForCombineCopies({ filter, list, CURRENT_MODEL }) {
      let currentModelNew = [];
      const TEMP_OBJ = {};
      if (isArray(CURRENT_MODEL)) {
        forEach(CURRENT_MODEL, model => {
          const MODEL_SPLIT = model.split("_");
          forEach(MODEL_SPLIT, modSplit => {
            const INDEX = findIndex(list, item => {
              return item[filter.keyId].indexOf(modSplit) !== -1;
            });

            if (INDEX !== -1) {
              if (list[INDEX][filter.keyId] !== modSplit) {
                if (!TEMP_OBJ[list[INDEX][filter.keyLabel]]) {
                  TEMP_OBJ[list[INDEX][filter.keyLabel]] = {
                    indexInList: INDEX,
                    ids: [],
                  };
                }

                TEMP_OBJ[list[INDEX][filter.keyLabel]].ids = uniq(concat(TEMP_OBJ[list[INDEX][filter.keyLabel]].ids, [modSplit]));
              } else {
                currentModelNew.push(modSplit);
              }
            }
          });
        });
      } else {
        const MODEL_SPLIT = CURRENT_MODEL.split("_");
        forEach(MODEL_SPLIT, modSplit => {
          const INDEX = findIndex(list, item => {
            return item[filter.keyId].indexOf(modSplit) !== -1;
          });

          if (INDEX !== -1) {
            if (list[INDEX][filter.keyId] !== modSplit) {
              TEMP_OBJ[list[INDEX][filter.keyLabel]] = {
                indexInList: INDEX,
                ids: uniq(concat(get(TEMP_OBJ[list[INDEX][filter.keyLabel]], "ids", []), [modSplit])),
              };
            } else {
              currentModelNew = list[INDEX][filter.keyId];
            }
          }
        });
      }

      forEach(TEMP_OBJ, (item, key) => {
        const ID_SPLIT = list[item.indexInList][filter.keyId].split("_");
        if (ID_SPLIT.length !== item.ids.length) {
          const ID_JOIN = item.ids.join("_");
          const INDEX_IN_LIST = findIndex(list, [filter.keyId, ID_JOIN]);
          if (INDEX_IN_LIST === -1) { // Es gibt noch nicht
            list.push({ // New element
              [filter.keyId]: ID_JOIN,
              [filter.keyLabel]: `${ key } (teilweise ${ item.ids.length }/${ ID_SPLIT.length })`,
            });
          }
          currentModelNew.push(ID_JOIN);
        } else {
          currentModelNew.push(list[item.indexInList][filter.keyId]);
        }
      });
      return { currentModelNew, statusChangeModel: true };
    },

    initModelLabel({ filter, response, model }) {
      const currentModel = model ? cloneDeep(model) : this.GET_TABLE_DEFINED_CURRENT_MODEL({
        tableId: this.options.id,
        filterId: filter.id,
      });
      if (isArray(currentModel)) {
        if (currentModel.length) {
          forEach(currentModel, modelItem => {
            this.initCurrentModelLabel({ currentModel: modelItem, filter, response, statusArray: true });
          });
        }
      } else if (currentModel) {
        this.initCurrentModelLabel({ currentModel, filter, response });
      }
    },

    initCurrentModelLabelForSearchGlobal({ label, currentModel, options }) {
      this.MUT_ADD_LABEL_BY_ID({
        tableId: this.options.id,
        labelId: currentModel,
        label,
        filterId: options.id,
      });
    },

    initCurrentModelLabel({ currentModel, filter, response, statusArray }) {
      const currentLabel = statusArray ?
        this.GET_LABEL_ARRAY_BY_ID(this.options.id, currentModel) :
        this.GET_LABEL_BY_ID(this.options.id, filter.key || filter.id, currentModel);

      if (currentLabel !== "Loading" && !isNil(currentLabel)) {
        return;
      }

      let childLabel;
      if (filter.keyLabelCallback) {
        const item = find(response, [filter.keyId || "id", currentModel]);
        childLabel = filter.keyLabelCallback({ item: item });
      } else if (filter.keyArray) {
        const index = response.indexOf(currentModel);
        if (index === -1) {
          return;
        }
        childLabel = response[index];
      } else {
        childLabel = get(find(response, [filter.keyId || "id", currentModel]), filter.keyLabel || "label");
      }
      const label = {
        child: childLabel,
        parent: filter.label,
      };
      this.MUT_ADD_LABEL_BY_ID({
        tableId: this.options.id,
        labelId: currentModel,
        label: label,
        filterId: filter.id,
      });
    },

    changeSearchListModel({ item }) {
      if (item.pk === "null") {
        this.initAllFiltersAgain();
        return;
      }

      const { nfi_conf } = item;
      this.searchListName = item.nfi_kbez;
      const model = {};
      this.showFilters = [];
      this.hideFilters = [];

      forEach(this.filters, filter => {
        if (!filter.alwaysVisible && !filter.main) {
          if (!isNil(nfi_conf[filter.id])) {
            this.showFilters.push(cloneDeep(filter));
            model[filter.id] = nfi_conf[filter.id];
            if (filter.combineCopies) {
              model[filter.id] = this.changeListForCombineCopies({ filter, model: model[filter.id] });
            }
          } else {
            this.hideFilters.push(cloneDeep(filter));
            model[filter.id] = this.tf_setModelDefault({ filter });
          }
        } else {
          model[filter.id] = nfi_conf[filter.id];
        }
      });
      this.model = model;
      this.initExtraFilterLabels();
      this.toggleBox({ status: true });
    },

    changeListForCombineCopies({ filter, model }) {
      const LIST = cloneDeep(this.GET_DATA_LIST(this.options.id)[filter.key || filter.id]) || [];
      const LIST_LENGTH = LIST.length;

      const { currentModelNew } = this.checkFilterModelForCombineCopies({ filter, list: LIST, CURRENT_MODEL: model });
      if (LIST_LENGTH !== LIST.length) {
        this.MUT_ADD_DATA_LIST({
          tableId: this.options.id,
          filterId: filter.key || filter.id,
          list: LIST,
        });
      }
      return currentModelNew;
    },

    initAllFiltersAgain() {
      this.searchListName = "";
      EventBus.$emit(`removeFilter_${ this.options.id }`, { all: true, statusUpdate: false });
      this.initAllFilters();
    },

    initExtraFilterLabels() {
      forEach(this.filters, filter => {
        const filterId = filter.key || filter.id;
        if ((isArray(this.model[filterId]) && this.model[filterId].length && filter.type === "multiselect") ||
          this.model[filterId] && filter.type === "select") {
          if (filter.searchGlobal) {
            // TODO: ILIA Wird gemacht mit Filter-Center
          } else if (filter.url) {
            this.initModelLabel({
              filter,
              response: this.GET_DATA_LIST(this.options.id)[filterId],
              model: this.model[filterId],
            });
          }
        }
      });
    },

    clearModel({ filter }) {
      this.model[filter.key || filter.id] = this.tf_setModelDefault({ filter });
    },

    hideFilter({ filter, index }) {
      this.showFilters.splice(index, 1);
      this.hideFilters.push(filter);
      this.clearModel({ filter });
    },

    onSearchFilterMain() {
      this.onSearch();
    },

    onSearch() {
      this.toggleBox({ status: false });
      this.MUT_CHANGE_MODEL_TABLE_BY_ID({
        id: this.options.id,
        model: this.tf_removeEmptyValues({ statusNotUrlParameter: true }),
      });
      this.startSearch(this.tf_removeEmptyValues());
    },

    openModal() {
      this.selectorCloseForModalSave = `#${ this.idForButtonSaveSearch }`;
      this.modelModal.name = this.searchListName || "";
      this.status.modal = true;
    },

    openModalErweitert() {
      this.openModal();
      this.selectorCloseForModalSave = `#${ this.idForButtonSearchModalOpen }`;
    },

    closeModal() {
      this.status.modal = false;
    },

    saveFilter() {
      this.statusSaveFilter = true;
      const data = {
        nfi_kbez: this.modelModal.name,
        nfi_kennung: this.options.id,
        nfi_conf: this.getFiltersUndValues(),
      };
      const index = findIndex(this.searchList, ["nfi_kbez", this.modelModal.name]);
      if (index === -1) {
        this.postHttp({
          url: "nutzerfilter/",
          data,
        }).then(
          response => {
            this.searchList.push(response);
            this.searchListModel = response.pk;
            this.searchListName = response.nfi_kbez;
            this.closeModal();
            this.addNotification({ text: "Suche ist gespeichert." });
            this.statusSaveFilter = false;
          }
        );
      } else {
        this.searchListModel = this.searchList[index].pk;
        this.putHttp({
          url: `nutzerfilter/${ this.searchListModel }/`,
          data,
        }).then(
          response => {
            this.searchList.splice(index, 1, response);
            this.searchListName = response.nfi_kbez;
            this.closeModal();
            this.addNotification({ text: "Suche ist gespeichert." });
            this.statusSaveFilter = false;
          }
        );
      }
    },

    onDeleteSavedFilter() {
      this.status.modalDelete = true;
    },

    deleteSavedFilter() {
      this.status.modalDeleteLoading = true;
      this.deleteHttp({
        url: `nutzerfilter/${ this.searchListModel }/`,
      }).then(
        () => {
          this.deleteFromSearchList();
          this.searchListModel = undefined;
          this.searchListName = undefined;
          this.addNotification({ text: "Suche ist gelöscht." });
          this.status.modalDeleteLoading = false;
          this.closeModalDelete();
          this.initAllFilters();
        }
      );
    },

    deleteFromSearchList() {
      forEach(this.searchList, (item, index) => {
        if (item.pk === this.searchListModel) {
          this.searchList.splice(index, 1);
          return false;
        }
      });
    },

    closeModalDelete() {
      this.status.modalDelete = false;
    },

    getFiltersUndValues() {
      const filters = {};
      if (this.filterMain) {
        filters[this.filterMain.id] = this.model[this.filterMain.id];
      }
      forEach(this.alwaysVisibleFilters, item => {
        if (isPlainObject(this.model[item.id])) {
          const tempObj = {};
          forEach(this.model[item.id], (m, key) => {
            tempObj[key] = m;
          });
          filters[item.id] = tempObj;
        } else if (isArray(this.model[item.id])) {
          filters[item.id] = cloneDeep(this.model[item.id]);
        } else {
          filters[item.id] = this.model[item.id];
        }
      });
      forEach(this.showFilters, item => {
        if (isPlainObject(this.model[item.id])) {
          const tempObj = {};
          forEach(this.model[item.id], (m, key) => {
            tempObj[key] = m;
          });
          filters[item.id] = tempObj;
        } else if (isArray(this.model[item.id])) {
          filters[item.id] = cloneDeep(this.model[item.id]);
        } else {
          filters[item.id] = this.model[item.id];
        }
      });
      return filters;
    },

    showFilter({ item }) {
      this.showFilters.push(cloneDeep(item));
      const idName = item.key ? "key" : "id";
      const hideFiltersIndex = findIndex(this.hideFilters, [idName, item.key || item.id]);
      this.hideFilters.splice(hideFiltersIndex, 1);
      this.hideFiltersModel = undefined;
    },

    toggleBox({ status } = {}) {
      if (!isNil(status)) {
        this.isBoxVisible = status;
        return;
      }
      this.isBoxVisible = !this.isBoxVisible;
    },

    destroyEventBusses() {
      forEach(this.eventBussesForFilter, (item, key) => {
        EventBus.$off(key);
      });
    },

    changeFilterValue({ options, item }) {
      if (options.type === "select" || options.type === "multiselect") {
        let _child = item[options.keyLabel] || item;
        if (options.keyLabelCallback) {
          _child = options.keyLabelCallback({ item: item });
        }
        const label = {
          parent: options.label,
          child: _child,
        };
        this.MUT_ADD_LABEL_BY_ID({
          tableId: this.options.id,
          labelId: item[options.keyId] || item,
          label: label,
          filterId: options.id,
        });
      }
    },

    ...mapMutations([
      "MUT_CHANGE_MODEL_TABLE_BY_ID",
      "MUT_ADD_LABEL_BY_ID",
      "MUT_ADD_DATA_LIST",
      "MUT_CHANGE_CURRENT_MODEL_TABLE_BY_ID",
    ]),
  },
};
