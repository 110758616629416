import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import ModuleMixin from "../ModuleMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import {
  cloneDeep,
  filter,
  forEach,
  get,
  indexOf,
  isEmpty,
  isEqual,
  isObject,
  mergeWith,
  padStart,
  size,
} from "lodash-es";
import {
  EventBus,
} from "../../../../global/functions/event-bus";

// @vue/component
export default {
  name: "Wahlkreise",
  components: {
    FormElement,
    SnapshotModule,
    SnapshotIcon,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    ModuleMixin,
    PermissionMixin,
  ],
  data() {
    return {
      auswahlList: [],
      activeWahlkreise: [],
    };
  },
  computed: {
    editOptions() {
      const ID = "auswahl";
      return {
        id: ID,
        htmlId: `${ this.htmlRegelNummer }_${ ID }`,
        type: "checkbox",
        keyId: "wk_id",
        slot: "uiCheckboxLabel",
        data: this.model.wahlkreise || [],
        isErrorLink: false,
      };
    },

    isModuleEditable() {
      return this.checkPermissionsSync({ perm: "wahlkreise.update", permArray: this.obj.user_permissions });
    },

    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.openEditModeCallback;
      return OPTIONS;
    },

    labelHeader() {
      return this.module.regel[`${ this.modulePrefix }_argument`].frage_text.wert;
    },

    statusSelected() {
      return this.model && size(this.model);
    },

    diff() {
      if (!this.snapshotModule) {
        return {};
      }
      const MODEL = cloneDeep(this.model) || {};
      MODEL.wahlkreise = Object.assign({}, ...MODEL.wahlkreise.map(x => ({ [x.pk]: x })));
      const SNAPSHOT = cloneDeep(this.snapshotModule);
      SNAPSHOT.wahlkreise = Object.assign({}, ...SNAPSHOT.wahlkreise.map(x => ({ [x.pk]: x })));
      if (!MODEL.auswahl) {
        MODEL.auswahl = {};
        forEach(MODEL.wahlkreise, item => MODEL.auswahl[item.pk] = true);
      }
      if (!SNAPSHOT.auswahl) {
        SNAPSHOT.auswahl = {};
        forEach(SNAPSHOT.wahlkreise, item => SNAPSHOT.auswahl[item.pk] = true);
      }
      let STATUS = false;
      const DIFF = mergeWith(MODEL, SNAPSHOT, function(objectValue, sourceValue, key) {
        if (key === "pk") {
          return;
        }
        if (!(isEqual(objectValue, sourceValue)) && (!isObject(objectValue) || !isObject(sourceValue))) {
          STATUS = true;
          return true;
        }
        if (!isObject(objectValue)) {
          return false;
        }
      });
      DIFF.status = STATUS;
      return DIFF;
    },

    statusDiff() {
      if (!this.snapshotModule) {
        return false;
      }
      return !!(this.diff.status);
    },

    snapshotStatusSelected() {
      return this.snapshotModule && !isEmpty(this.snapshotModule);
    },

    errorsLabelsOptions() {
      return [
        this.editOptions,
      ];
    },
  },
  created() {
    this.initEventBus();
  },
  beforeUnmount() {
    this.destroyEventBus();
  },
  methods: {
    getGem18(gem18) {
      return padStart(gem18 + "", 8, "0");
    },

    filteredWahlkreise(model) {
      if (model.auswahl) {
        return filter(model.wahlkreise, item => get(model.auswahl, item.pk, true));
      }
      return model.wahlkreise;
    },

    openEditModeCallback() {
      this.initAuswahl();
    },

    initAuswahl() {
      if (isEmpty(this.model.auswahl)) {
        this.auswahlList = this.model.wahlkreise.map(x => x.pk);
      } else {
        const AUSWAHL = [];
        forEach(this.model.auswahl, (item, key) => {
          if (item) {
            AUSWAHL.push(key);
          }
        });
        this.auswahlList = AUSWAHL;
      }
    },

    showOtherAdr(model) {
      return model.ast_plz !== model.plz || model.ast_ort !== model.ort;
    },

    getTranslationOtherAdr(model) {
      return {
        title: "_MSG_WAHLKREISE_ABWEICHENDE_ADR_{{ast_plz}}_{{other_plz}}_{{ast_ort}}_{{other_ort}}_",
        extra: {
          ast_plz: model.ast_plz,
          other_plz: model.plz,
          ast_ort: model.ast_ort,
          other_ort: model.ort,
        }
      };
    },

    toggleDetails(id) {
      const index = indexOf(this.activeWahlkreise, id);
      if (index > -1) {
      	this.activeWahlkreise.splice(index, 1);
      } else {
      	this.activeWahlkreise.push(id);
      }
    },

    showDetails(id) {
      return indexOf(this.activeWahlkreise, id) > -1;
    },

    prepareToSave({ data }) {
      if (isEmpty(this.auswahlList)) {
        return {};
      }
      const DATA = { auswahl: {} };
      forEach(data.wahlkreise, el => {
        DATA.auswahl[el.pk] = indexOf(this.auswahlList, el.pk) > -1;
      });
      return DATA;
    },

    initEventBus() {
      EventBus.$on("antragsteller.update", this.reloadModule);
    },

    destroyEventBus() {
      EventBus.$off("antragsteller.update", this.reloadModule);
    },

    setLocalErrorsFromGlobal({ errors }) {
      this.errors = errors;
    },
  },
};
