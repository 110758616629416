"use strict";
import angular from "angular";

import sitzungsmodule from "dias/sitzungen/sitzungsmodule";

import preview from "./preview.component";

const modul = angular.module("standard_sitzungsmodule.muendlicher_bericht", [
  sitzungsmodule.name,
])

  .config(/*@ngInject*/SitzungsmodulRegistryProvider => {
    SitzungsmodulRegistryProvider.register("muendlicher_bericht", undefined, "modul-muendlicher-bericht-preview");
  })

  .component("modulMuendlicherBerichtPreview", preview)
;

export default modul;
