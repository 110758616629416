"use strict";

import "restangular";
import angular from "angular";

import regelServices from "dias/foerderregeln/services";

import Foerderregeln from "./foerderregeln.service";
import FoerderregelAppkontexte from "./foerderregelappkontext.service";
import Elementfoerderregeln from "./elementfoerderregeln.service";
import Applikationskontextrubriken from "./applikationskontextrubriken.service";

const modelsModule = angular.module("foerderregeln.models", [
  "restangular",
  regelServices.name
])

  .provider("Foerderregeln", Foerderregeln)
  .provider("FoerderregelAppkontexte", FoerderregelAppkontexte)
  .provider("Elementfoerderregeln", Elementfoerderregeln)
  .provider("Applikationskontextrubriken", Applikationskontextrubriken)
;

export default modelsModule;
