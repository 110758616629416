"use strict";

import diasLoading from "dias/core/loading";

import TableDetailComponent from "./table.detail.component";

const diasStatusGruppen = angular.module("dias.tabledetail", [
  diasLoading.name
])

  .component("tableDetail", TableDetailComponent);

export default diasStatusGruppen;
