import AnlagenAuszahlung from "../AnlagenAuszahlung/AnlagenAuszahlung.vue";
import AuszahlungBankverbindung from "../AuszahlungBankverbindung/AuszahlungBankverbindung.vue";
import AuszahlungBelegdokumenteStep from "./AuszahlungBelegdokumenteStep/AuszahlungBelegdokumenteStep.vue";
import AuszahlungEinreichenStep from "./AuszahlungEinreichenStep/AuszahlungEinreichenStep.vue";
import AuszahlungKoFiStep from "./AuszahlungKoFiStep/AuszahlungKoFiStep.vue";
import AuszahlungUebersichtStep from "./AuszahlungUebersichtStep/AuszahlungUebersichtStep.vue";
import AuszahlungZeitraum from "../AuszahlungZeitraum/AuszahlungZeitraum.vue";
import HinweiseZurAuszahlung from "../HinweiseZurAuszahlung/HinweiseZurAuszahlung.vue";
import SachPruefBericht from "../SachPruefBericht/SachPruefBericht.vue";
import Vertretungsberechtigte from "../../../Vertretungsberechtigte/ObjectVertretungsberechtigte/Vertretungsberechtigte.vue";
import Verwendungszweck from "../Verwendungszweck/Verwendungszweck.vue";
import Wizard from "../../../../global/components/Wizard/Wizard.vue";

import AuszahlungWizardStepsAPI from "./compositionAPI/AuszahlungWizardStepsAPI";

import {
  get,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungWizard",
  components: {
    AnlagenAuszahlung,
    AuszahlungBankverbindung,
    AuszahlungBelegdokumenteStep,
    AuszahlungEinreichenStep,
    AuszahlungKoFiStep,
    AuszahlungUebersichtStep,
    AuszahlungZeitraum,
    HinweiseZurAuszahlung,
    SachPruefBericht,
    Vertretungsberechtigte,
    Verwendungszweck,
    Wizard,
  },
  props: {
    auszahlung: {
      type: Object,
      required: true,
    },
    urlAuszahlungen: {
      type: String,
      required: true,
    },
    client: {
      type: String,
      required: true,
    },
    isSachberichtVisible: {
      type: Boolean,
      required: false,
    },
    isPruefberichtVisible: {
      type: Boolean,
      required: false,
    },
    sbModule: {
      type: Array,
      required: true,
    },
    pbModule: {
      type: Array,
      required: true,
    },
    auszahlungsplan: {
      type: Object,
      required: true,
    },
    antrag: {
      type: Object,
      required: true,
    },
    antragsteller: {
      type: Object,
      required: true,
    },
    vertrag: {
      type: Object,
      required: true,
    },
    kostenModule: {
      type: Array,
      required: false,
      default: () => [],
    },
    finanzierungModule: {
      type: Array,
      required: false,
      default: () => [],
    },
    infoprovider: {
      type: Object,
      required: false,
      default: undefined,
    },
    kofiErfassungReloadStatus: {
      type: Boolean,
      required: false,
    },
    updateOnChangeWf: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
    onStartedWf: {
      type: Function,
      required: true,
    },
    onCanceledWf: {
      type: Function,
      required: true,
    },
    onInitWf: {
      type: Function,
      required: true,
    },
    extraWf: {
      type: Object,
      required: true,
    },
    savecallback: {
      type: Function,
      required: true,
    },
    updateModuleSachbericht: {
      type: Function,
      required: true,
    },
    updateModulePruefbericht: {
      type: Function,
      required: true,
    },
    updateAuszahlungAfterModalitaetChange: {
      type: Function,
      required: true,
    },
  },
  emits: [
    "closeWizard",
  ],
  setup(props) {
    const {
      wizardSteps,
    } = AuszahlungWizardStepsAPI(props);

    return {
      wizardSteps,
    };
  },
  data() {
    return {
      booleanPropsWizard: {
        backTargetStepDisabled: undefined,
        backStepHide: undefined,
        backStepDisabled: undefined,
        forwardStepHide: undefined,
        forwardStepDisabled: false,
        buttonsLoading: false,
        closeButtonShow: true,
        closeButtonBottomHide: false,
        buttonStepNeverDisabled: true,
      },
      stepActive: 0,
      stepsVisited: {
        0: true,
      },
    };
  },
  computed: {
    urlAuszahlung() {
      return `${ this.urlAuszahlungen }${ this.auszahlung.pk }/`;
    },
  },
  methods: {
    closeWizardLocal() {
      this.$emit("closeWizard");
    },

    goOneStepForward() {
      this.booleanPropsWizard.buttonsLoading = true;
      this.leavingStep().then(
        response => {
          this.booleanPropsWizard.buttonsLoading = false;
          const STEP_ACTIVE_NEW = this.stepActive + 1;
          this.setStepActiveInStepsVisited(response, STEP_ACTIVE_NEW);
          this.setCloseButtonBottomHide(STEP_ACTIVE_NEW);
          this.stepActive = STEP_ACTIVE_NEW;
          this.comingStep();
        }
      );
    },

    goOneStepBack() {
      this.booleanPropsWizard.buttonsLoading = true;
      this.leavingStep().then(
        response => {
          this.booleanPropsWizard.buttonsLoading = false;
          const STEP_ACTIVE_NEW = this.stepActive - 1;
          this.setStepActiveInStepsVisited(response, STEP_ACTIVE_NEW);
          this.setCloseButtonBottomHide(STEP_ACTIVE_NEW);
          this.stepActive = STEP_ACTIVE_NEW;
          this.comingStep();
        }
      );
    },

    goStepBack({ stepIndex }) {
      this.booleanPropsWizard.buttonsLoading = true;
      this.leavingStep().then(
        response => {
          this.booleanPropsWizard.buttonsLoading = false;
          this.setStepActiveInStepsVisited(response, stepIndex);
          this.setCloseButtonBottomHide(stepIndex);
          this.stepActive = stepIndex;

          this.comingStep();
        }
      );
    },

    setStepActiveInStepsVisited({ isPreviousStepNotVisited } = {}, stepActiveNew) {
      this.stepsVisited[stepActiveNew] = true;
      if (isPreviousStepNotVisited) {
        this.stepsVisited[this.stepActive] = false;
      }
    },

    leavingStep() {
      const CURRENT_STEP = this.wizardSteps[this.stepActive];
      const REF = CURRENT_STEP.slotId;
      if (isFunction(get(this.$refs, `[${ REF }].leavingStep`))) {
        return this.$refs[REF].leavingStep();
      }
      return new Promise(resolve => {
        resolve();
      });
    },

    comingStep() {
      const CURRENT_STEP = this.wizardSteps[this.stepActive];
      const REF = CURRENT_STEP.slotId;
      if (isFunction(get(this.$refs, `[${ REF }].comingStep`))) {
        return this.$refs[REF].comingStep();
      }
    },

    setCloseButtonBottomHide(stepActiveIndex) {
      if (stepActiveIndex === this.wizardSteps.length - 1) {
        this.booleanPropsWizard.closeButtonBottomHide = true;
      } else {
        this.booleanPropsWizard.closeButtonBottomHide = false;
      }
    },
  },
};
