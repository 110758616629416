// Modul zur Elementverzweigung
"use strict";

import parameter from "./parameter";

import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";

const elementverzweigung = angular.module("dias.standard_foerdermodule.elementverzweigung", [
  parameter.name,
  foerdermoduleRegistry.name,
]);

export default elementverzweigung;
