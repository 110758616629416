var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;













buf.push("<fieldset dias-cloak=\"vm.loadingEditData\"><div ng-attr-id=\"{{ vm.errorContainerId }}\"></div><div ng-repeat=\"key in vm.form_keys\"><div ng-class=\"{'has-error has-feedback':vm.errors[key]}\" class=\"form-group\"><label ng-attr-for=\"{{:: key }}\" ng-class=\"{'has-error has-feedback':vm.errors[key]}\" class=\"font-weight-bold\"><span class=\"titel\">{{:: vm.formModel[key].bez }} {{:: vm.formModel[key].options.required?'*':''}}<span class=\"alert-danger\"></span></span></label><div ng-class=\"{'form-control-staic': vm.formModel[key].readonly}\"><parametermodule parameter=\"vm.formModel\" key=\"key\" readonly=\"{{:: vm.formModel[key].readonly}}\"></parametermodule></div><span ng-if=\"vm.errors[key]\" class=\"help-block\"><ul class=\"errorlist\"><li ng-repeat=\"err in vm.errors[key]\">{{ err }}</li></ul></span></div></div></fieldset><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save, vm.errorLabels)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Abbrechen</span></button></div></div>");;return buf.join("");
}