import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";
import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import moment from "moment";
import { createNamespacedHelpers } from "vuex";
import {
  get,
} from "lodash-es";

const {
  mapMutations,
} = createNamespacedHelpers("snapshot");

// @vue/component
export default {
  name: "AuszahlungZeitraum",
  components: {
    FormElement,
    FormstepDetail,
    PuxButton,
    PuxTranslate,
    SnapshotIcon,
    SnapshotModule,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    auszahlung: {
      type: Object,
      required: true,
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
    vertrag: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "updateAuszahlung",
  ],
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    const {
      addNotification,
      putHttp,
    } = HttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      addNotification,
      checkPermissionsSync,
      filterDate,
      putHttp,
    };
  },
  data() {
    return {
      errors: undefined,
      model: {
        dateRangeFrom: this.auszahlung.az_zeitraum_von,
        dateRangeTill: this.auszahlung.az_zeitraum_bis,
      },
    };
  },
  computed: {
    extraForTranslate() {
      return {
        von: this.zeitraumVonFiltered,
        bis: this.zeitraumBisFiltered,
      };
    },

    zeitraumVonFiltered() {
      return this.filterDate(this.zeitraumVonValue);
    },

    zeitraumBisFiltered() {
      return this.filterDate(this.zeitraumBisValue);
    },

    zeitraumVonFilteredFromSnapshot() {
      return this.filterDate(this.zeitraumVonValueFromSnapshot);
    },

    zeitraumBisFilteredFromSnapshot() {
      return this.filterDate(this.zeitraumBisValueFromSnapshot);
    },

    extraForTranslateFromSnapshot() {
      return {
        von: this.zeitraumVonFilteredFromSnapshot,
        bis: this.zeitraumBisFilteredFromSnapshot,
      };
    },

    objectData() {
      return {
        extra: this.extraForTranslate,
      };
    },

    snapshotData() {
      return {
        extra: this.extraForTranslateFromSnapshot,
      };
    },

    isEditable() {
      return this.checkPermissionsSync({
        perm: "auszahlung.zeitraum.update",
        permArray: this.userPermissions,
      });
    },

    userPermissions() {
      return this.auszahlung.user_permissions;
    },

    optionsFormstepDetail() {
      const ID = "title";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        label: "_TXT_AUSZAHLUNG_ZEITRAUM_TITLE_",
        saveCallback: this.save,
        openCallback: this.open,
        info: "_TXT_AUSZAHLUNG_ZEITRAUM_INFO_BOX_",
        required: true,
      };
    },

    optionsForFormElementPayoutPeriodFrom() {
      const ID = "az_zeitraum_von";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "date",
        view: "v",
        required: true,
        label: "_LBL_AUSZAHLUNG_ZEITRAUM_FROM_",
        editOnly: true,
      };
    },

    optionsForFormElementPayoutPeriodTill() {
      const ID = "az_zeitraum_bis";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "date",
        view: "v",
        required: true,
        label: "_LBL_AUSZAHLUNG_ZEITRAUM_TILL_",
        editOnly: true,
      };
    },

    errorsLabelsOptions() {
      return [
        this.optionsForFormElementPayoutPeriodFrom,
        this.optionsForFormElementPayoutPeriodTill,
      ];
    },

    idForSaveButton() {
      return `${ this.id }_btn_save`;
    },

    idForLastYearButton() {
      return `${ this.id }_btn_last_year`;
    },

    idForCurrentYearButton() {
      return `${ this.id }_btn_current_year`;
    },

    idForImplementationPeriodButton() {
      return `${ this.id }_btn_implementation_period`;
    },

    urlForSave() {
      return `${ this.baseUrl }${ this.auszahlung.pk }/zeitraum_aendern/`;
    },

    isSnapshot() {
      return !!this.snapshot;
    },

    snapshotObject() {
      return get(this.snapshot, "osn_snapshot.auszahlung");
    },

    zeitraumVonValue() {
      return this.auszahlung.az_zeitraum_von;
    },

    zeitraumBisValue() {
      return this.auszahlung.az_zeitraum_bis;
    },

    snapshotDate() {
      return get(this.snapshot, "osn_snapshot_datum");
    },

    zeitraumVonValueFromSnapshot() {
      return get(this.snapshotObject, "az_zeitraum_von");
    },

    zeitraumBisValueFromSnapshot() {
      return get(this.snapshotObject, "az_zeitraum_bis");
    },

    isDiff() {
      let isDiff = false;
      if (this.isSnapshot) {
        if (
          this.zeitraumBisValueFromSnapshot !== this.zeitraumBisValue ||
          this.zeitraumVonValueFromSnapshot !== this.zeitraumVonValue
        ) {
          isDiff = true;
        }
      }
      return isDiff;
    },
  },
  watch: {
    isDiff: {
      immediate: true,
      handler(newVal) {
        this.MUT_SET_STATUS_DIFF_FOR_REGEL({
          regelId: this.id,
          statusDiff: newVal,
        });
      },
    },
  },
  beforeUnmount() {
    this.MUT_DELETE_STATUS_DIFF_FOR_REGEL({ regelId: this.id });
  },
  methods: {
    save() {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.urlForSave,
          data: {
            az_zeitraum_von: this.model.dateRangeFrom,
            az_zeitraum_bis: this.model.dateRangeTill
          },
        }).then(
          response => {
            this.addNotification({ text: "_MSG_UPDATE_SUCCESS_", type: "success" });
            this.errors = undefined;
            this.$emit("updateAuszahlung", response);
            resolve();
          },
          errors => {
            this.addNotification({ text: "_MSG_ERROR_", type: "error" });
            this.onErrors(errors);
            reject(errors);
          }
        );
      });
    },

    open() {
      this.model.dateRangeFrom = this.auszahlung.az_zeitraum_von;
      this.model.dateRangeTill = this.auszahlung.az_zeitraum_bis;
    },

    setCurrentYearDateRange() {
      this.model.dateRangeFrom = moment().startOf("year").format("YYYY-MM-DD");
      this.model.dateRangeTill = moment().endOf("year").format("YYYY-MM-DD");
    },

    setLastYearDateRange() {
      this.model.dateRangeFrom = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
      this.model.dateRangeTill = moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD");
    },

    setImplementationPeriodDateRange() {
      this.model.dateRangeFrom = this.vertrag.antrag_durchfuehrung_von;
      this.model.dateRangeTill = this.vertrag.antrag_durchfuehrung_bis;
    },

    onErrors(errors) {
      if (errors.data) {
        this.errors = errors.data;
      } else {
        this.errors = errors;
      }
    },

    ...mapMutations([
      "MUT_SET_STATUS_DIFF_FOR_REGEL",
      "MUT_DELETE_STATUS_DIFF_FOR_REGEL",
    ]),
  },
};
