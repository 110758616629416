import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import { isFunction } from "lodash-es";

// @vue/component
export default {
  name: "NutzerModalAction",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    options: {
      type: Object,
      default: () => ({
        notificationsSuccessText: "",
        buttonLabel: "",
        bodyText: "",
        header: "",
        urlSave: "",
        documentReload: false,
        onSave: undefined
      }),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      status: {
        loading: undefined,
      },
    };
  },
  methods: {
    save() {
      this.status.loading = true;
      this.postHttp({ url: this.options.urlSave }).then(
        response => {
          this.addNotification({ text: this.options.notificationsSuccessText });
          this.close({ response, status: true });
          if (isFunction(this.options.onSave)) {
            this.options.onSave();
          }
          if (this.options.documentReload) {
            document.location.reload(true);
          }
        },
        () => this.addNotification({ text: "_MSG_NUTZER_MODAL_ACTION_ERROR_", type: "error" })
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
