"use strict";

import angular from "angular";

const tealiumModule = angular.module("tealium", []);

tealiumModule.run(/*@ngInject*/($rootScope, $window) => {
  // Tracking des Seitenzugriffs auf Tealium
  $rootScope.$on("$stateChangeSuccess", function() {
    // Wrappen in großen try-catch-Block, damit Fehler an dieser
    // Stelle den Programmablauf nicht unterbrechen.
    try {
      if (typeof $window.utag !== "undefined") {
        $window.utag.view({
          dias_userid: $window.utag_data.dias_userid,
          page_name: $window.location.pathname,
          page_title: $window.document.title
        });
      }
    } catch (err) {
      console.error(err);
    }
  });
});

tealiumModule.directive("tealiumTrack", /*@ngInject*/$window => {
  return {
    link: (scope, element, attrs) => {
      element.on("click", () => {
        if (typeof $window.utag !== "undefined") {
          try {
            $window.utag.link({
              event_id: "dias_" + attrs.tealiumTrack,
              etracker_event_action: "click" });
          } catch (err) {
            console.error(err);
          }
        }
      });
    }
  };
});

export default tealiumModule;
