import ContextPanel from "../ContextPanel/ContextPanel.vue";

import {
  EventBus,
} from "../../functions/event-bus";

// @vue/component
export default {
  name: "PuxRubrics",
  components: {
    ContextPanel,
  },
  props: {
    rubrics: {
      type: Array,
      required: true,
    },
    rubricsMarked: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    rubricsDiff: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    rubricsSnapshotConfig: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isSnapshot: {
      type: Boolean,
      required: false,
    },
    loadingByRubricKey: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  methods: {
    togglePanel({ statusOpen, notizenKey }) {
      EventBus.$emit("togglePuxRubric", { statusOpen, rubricKey: notizenKey });
    },
  },
};
