import FormstepDetail from "../../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PeriodModalTeilenDHW from "./PeriodModalTeilenDHW/PeriodModalTeilenDHW.vue";
import PeriodModalUpdateDHW from "./PeriodModalUpdateDHW/PeriodModalUpdateDHW.vue";
import WizardStepsANBruttoMixin from "../../WizardStepsANBruttoMixin";
import WizardStep3Mixin from "../WizardStep3Mixin";
import WizardStepsPeriodenMixin from "../../WizardStepsPeriodenMixin";
import translate from "../../../../../../global/directives/translate";
import WizardStepDHWMixin from "../../WizardStepDHWMixin";
import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";
import {
  forEach,
  cloneDeep,
  round,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenPeriodenWizardStep3DHW",
  components: {
    PeriodModalTeilenDHW,
    PeriodModalUpdateDHW,
    FormstepDetail,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    WizardStep3Mixin,
    WizardStepsANBruttoMixin,
    WizardStepDHWMixin,
    WizardStepsPeriodenMixin,
  ],
  computed: {
    getANBruttoColumn() {
      return ({ period }) => {
        return this.getANBrutto_mixin({ period });
      };
    },

    getAGBruttogehaltColumn() {
      return ({ period }) => {
        const GEHALT = this.getANBruttoColumn({ period });

        const PKT_PAUSCHALE = this.getPktPauschaleNumber;
        return GEHALT * (1 + PKT_PAUSCHALE / 100);
      };
    },

    getFoerderfaehigeColumn() {
      return ({ period }) => {
        const AG = this.getAGBruttogehaltColumn({ period });
        const STELLENUMFANG_PROJEKT = this.model.stellenumfang_projekt || 0;
        const VOLLZEITSTELLE = this.model.vollzeitstelle;
        return AG * STELLENUMFANG_PROJEKT / VOLLZEITSTELLE;
      };
    },
  },
  created() {
    this.splitModelPeriodenWithVonUndBis();
  },
  methods: {
    splitModelPeriodenWithVonUndBis() {
      if (this.statusPeriodenAusgefuellt) {
        return;
      }
      const ZEITRAUM_VON = this.model.zeitraum_von;
      const ZEITRAUM_BIS = this.model.zeitraum_bis;

      const MODEL = cloneDeep(this.model);
      MODEL.perioden = this.getModePerioden();
      const MODEL_PERIODEN = [];
      forEach(MODEL.perioden, period => {
        const MODEL_FOR_CURRENT_PERIOD = this.splitOnePeriod({
          period,
          von: ZEITRAUM_VON,
          bis: ZEITRAUM_BIS,
        });
        MODEL_PERIODEN.push(...MODEL_FOR_CURRENT_PERIOD);
      });

      MODEL.perioden = MODEL_PERIODEN;
      this.setGehaltForPerioden({ model: MODEL });
    },

    setGehaltForPerioden({ model }) {
      const MODEL = model;
      const PAUSCHALE = MODEL.pauschale || 0;
      const STELLENUMFANG_PROJEKT = MODEL.stellenumfang_projekt || 0;
      const VOLLZEITSTELLE = MODEL.vollzeitstelle;
      const ZEITRAUM_VON = MODEL.zeitraum_von;
      const ZEITRAUM_BIS = MODEL.zeitraum_bis;

      forEach(MODEL.perioden, (period, index) => {
        if (period.von < ZEITRAUM_VON || period.bis > ZEITRAUM_BIS) {
          MODEL.perioden[index].brutto_monat = 0;
          MODEL.perioden[index].einmalzahlung = 0;
          MODEL.perioden[index].gehalt = 0;
          return;
        }

        MODEL.perioden[index].brutto_monat = this.model.an_brutto_monat;
        MODEL.perioden[index].einmalzahlung = this.model.einmalzahlung;
        const A_N_BRUTTO = this.getANBrutto_mixin({ period });
        MODEL.perioden[index].gehalt = round(A_N_BRUTTO * (1 + PAUSCHALE / 100) * STELLENUMFANG_PROJEKT / VOLLZEITSTELLE, 2);
      });
      this.initModelInParent({ model: MODEL });
    },

    closeModalUpdate({ model } = {}) {
      if (model) {
        this.model.perioden[this.currentIndex].von = model.von;
        this.model.perioden[this.currentIndex].bis = model.bis;
        this.model.perioden[this.currentIndex].brutto_monat = model.brutto_monat;
        this.model.perioden[this.currentIndex].gehalt = model.gehalt;
        this.model.perioden[this.currentIndex].einmalzahlung = model.einmalzahlung;
      }

      this.modalUpdate = false;
    },

    closeModalTeilen({ model } = {}) {
      if (model) {
        this.model.perioden[this.currentIndex].von = model.von1;
        this.model.perioden[this.currentIndex].bis = model.bis1;
        this.model.perioden[this.currentIndex].brutto_monat = model.brutto_monat1;
        this.model.perioden[this.currentIndex].gehalt = model.gehalt2;
        this.model.perioden[this.currentIndex].einmalzahlung = model.einmalzahlung1;
        this.model.perioden[this.currentIndex].info.teil = true;
        this.model.perioden.splice(this.currentIndex + 1, 0, {
          von: model.von2,
          bis: model.bis2,
          brutto_monat: model.brutto_monat2,
          gehalt: model.gehalt2,
          einmalzahlung: model.einmalzahlung2,
          periode: this.model.perioden[this.currentIndex].periode,
          info: cloneDeep(this.model.perioden[this.currentIndex].info),
        });
      }

      this.modalTeilen = false;
    },
  },
};
