import {
  getCurrentInstance,
  computed,
  ref,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

import {
  orderBy,
  values,
} from "lodash-es";

export default function SitzungDetailsAPI() {
  const APP = getCurrentInstance();
  const $stateParams = APP.appContext.config.globalProperties.$stateParams;
  const $goTo = APP.appContext.config.globalProperties.$goTo;
  const sitzungUrl = `sitzungen/${ $stateParams.id }/`;

  const sitzung = ref({});
  const lastReload = ref(undefined);
  const sitzungLoading = ref(true);
  const pageLoading = ref(true);

  const {
    getHttp,
  } = HttpAPI();

  const sitzungDetailsRubricsMapping = {
    ubersicht: {
      id: "step_ubersicht",
      key: "step_ubersicht",
      kbez: "_TXT_SITZUNG_DETAILS_RUBRIK_UEBERSICHT_",
      prio: 0,
    },
    sitzungsdaten: {
      id: "step_sitzungsdaten",
      key: "step_sitzungsdaten",
      kbez: "_TXT_SITZUNG_DETAILS_RUBRIK_SITZUNGSDATEN_",
      prio: 1,
    },
    tops: {
      id: "step_tops",
      key: "step_tops",
      kbez: "_TXT_SITZUNG_DETAILS_RUBRIK_TOPS_",
      prio: 2,
    },
    mittel: {
      id: "step_mittel",
      key: "step_mittel",
      kbez: "_TXT_SITZUNG_DETAILS_RUBRIK_MITTEL_",
      prio: 3,
    },
    protokoll: {
      id: "step_protokoll",
      key: "step_protokoll",
      kbez: "_TXT_SITZUNG_DETAILS_RUBRIK_PROTOKOLL_",
      prio: 4,
    },
    teilnehmer: {
      id: "step_teilnehmer",
      key: "step_teilnehmer",
      kbez: "_TXT_SITZUNG_DETAILS_RUBRIK_TEILNEHMER_",
      prio: 5,
    },
    dokumente: {
      id: "step_dokumente",
      key: "step_dokumente",
      kbez: "_TXT_SITZUNG_DETAILS_RUBRIK_DOKUMENTE_",
      prio: 6,
    },
  };

  const sitzungDetailsNotizenSteps = computed(() => {
    return orderBy(values(sitzungDetailsRubricsMapping), ["prio"]);
  });

  const reloadSitzung = () => {
    sitzungLoading.value = true;
    return getHttp({
      url: sitzungUrl,
    }).then(
      response => {
        sitzung.value = response;
        lastReload.value = new Date().getTime();
        sitzungLoading.value = false;
      },
      errors => {
        if (errors.status && errors.status === 404) {
          $goTo("root.sitzungen", { user_cookie: true });
        }
      },
    ).finally(
      () => pageLoading.value = false,
    );
  };

  const updateSitzung = ({ sitzungLocal }) => {
    sitzung.value = Object.assign({}, sitzung.value, sitzungLocal);
  };

  const updateAufgabe = ({ newAufgabe }) => {
    sitzung.value.aktuelle_aufgabe = Object.assign({}, sitzung.value.aktuelle_aufgabe, newAufgabe);
  };

  return {
    lastReload,
    pageLoading,
    sitzung,
    sitzungDetailsNotizenSteps,
    sitzungDetailsRubricsMapping,
    sitzungLoading,
    sitzungUrl,

    reloadSitzung,
    updateSitzung,
    updateAufgabe,
  };
}
