"use strict";

const PASSWORT_ABGELAUFEN_STATUS_ID = "2d4d03f5-6c2e-4ebe-b612-bdc544582739";

class LoginController {
  /*@ngInject*/
  constructor(
    Login, 
    $location, 
    $state, 
    $stateParams, 
    $window, 
    $timeout,
    SyConfigs, 
    AuthService, 
    Nutzer, 
    DiasNotification, 
    NewsExtern
  ) {
    this.loading = true;
    this.model = { username: this.getCookie("diasTempEmail") || "" };
    this.Login = Login;
    this.SyConfigs = SyConfigs;
    this.DiasNotification = DiasNotification;
    this.AuthService = AuthService;
    this.Nutzer = Nutzer;
    this.$location = $location;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$window = $window;
    this.$timeout = $timeout;
    this.NewsExtern = NewsExtern;

    this.status = {
      loadingNews: true,
    };
    this.newsExtern = [];

    this.checkUser();
  }

  checkUser() {
    this.AuthService.getUser().then(
      user => {
        if (user && user.pk) {
          if (user.status.ns_id === PASSWORT_ABGELAUFEN_STATUS_ID) {
            this.initDefaultValues();
            this.passwortAbgelaufenStatus = true;
          } else {
            if (this.$stateParams.next) {
              this.$window.location.assign(this.$stateParams.next);
            } else {
              this.$state.go("root.home");
            }
          }
        } else {
          this.initDefaultValues();
          this.loadNewsExtern();
        }
        this.loading = false;
        this.checkNotification();
      }
    );
  }

  initDefaultValues() {
    /* if(this.$stateParams.m === "session_expired") {
      this.DiasNotification.form.clear();
      this.DiasNotification.form.warning(
        "Bitte melden Sie sich erneut mit Ihren Anmeldedaten an.",
        "Ihre Anmeldung ist abgelaufen", {}, {});
    }*/
    if (!this.regHeader) {
      this.regHeader = {
        class: "card-header",
        label: `<h4 class="card-title">Anmelden</h4>`,
        labelChangePassword: `<h4 class="card-title">Passwort aktualisieren</h4>`
      };
    }

    if (!this.regClass) {
      this.regClass = "col-12 col-sm-10 col-lg-7 no-padding-right";
    }
  }

  checkNotification() {
    if (this.$stateParams.m === "session_expired") {
      this.DiasNotification.form.clear();
      this.DiasNotification.form.warning(
        "Bitte melden Sie sich erneut mit Ihren Anmeldedaten an.",
        "Ihre Anmeldung ist abgelaufen", 
        {}, 
        {}
      );
    } else if (this.$stateParams.m === "datenschutz_declined" &&
      this.SyConfigs.wert("datenschutzerklaer") &&
      this.SyConfigs.wert("datenschutzerklaer").hinweis_ablehnung) {
      this.DiasNotification.form.clear();
      this.DiasNotification.form.warning(
        this.SyConfigs.wert("datenschutzerklaer").hinweis_ablehnung,
        undefined, 
        {}, 
        {}
      );
    }
  }

  login() {
    this.deleteCookie("diasTempEmail");
    this.authenticating = true;
    this.Login.post(this.model).then(
      response => {
        if (response.user) {
          localStorage.clear();
          if (response.user.status && response.user.status.ns_id === PASSWORT_ABGELAUFEN_STATUS_ID) {
            this.passwortAbgelaufenStatus = true;
          } else if (this.$stateParams.next) {
            this.$window.location.replace(decodeURIComponent(this.$stateParams.next));
          } else {
            this.$window.location.replace("/");
          }
        } else {
          this.onError(response.error);
        }
        this.authenticating = false;
      },
      () => this.authenticating = false
    );
  }

  onError(error) {
    this.error = error;
  }

  deleteErrors() {
    this.error = undefined;
  }

  getCookie(name) {
    const r = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    if (r) {
      return r[2].replace("%40", "@");
    } 
    return "";
  }

  deleteCookie(name) {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  loadNewsExtern() {
    if (!this.isPage) {
      return;
    }
    this.NewsExtern.getList({ sichtbar: true }).then(
      response => {
        this.newsExtern = response;
        this.status.loadingNews = false;
      }
    );
  }
}

export default LoginController;
