import PuxGet from "../../../../global/components/PuxGet/PuxGet.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import AktualisierterZuschussMixin from "../../AktualisierterZuschuss/AktualisierterZuschussMixin";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "KFAuszahlungPeriodenZuschuss",
  components: {
    PuxGet,
    PuxTranslate,
  },
  mixins: [
    AktualisierterZuschussMixin,
  ],
  props: {
    perioden: {
      type: Array,
      required: true,
    },
    periodenFiltered: {
      type: Array,
      required: true,
    },
    periodKeyShow: {
      type: String,
      required: true,
      info: "Welche Perioden-Teil werden angezeigt",
    },
    isDiffPerioden: {
      type: Boolean,
      required: false,
    },
    sumPeriodenKosten: {
      type: Object,
      required: false,
      default: () => {},
    },
    sumPeriodenFinanzierung: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    extraForModule() {
      return {
        antrag: this.antrag,
        vertrag: this.vertrag,
        periodenFiltered: this.periodenFiltered,
        periodKeyShow: this.periodKeyShow,
        isDiffPerioden: this.isDiffPerioden,
        diffZuschuss: this.diffZuschuss,
        kofiZuschussKeyByRegelNummer: this.kofiZuschussKeyByRegelNummer,
        sumPeriodenKosten: this.sumPeriodenKosten,
        sumPeriodenFinanzierung: this.sumPeriodenFinanzierung,
      };
    },

    extraStatic() {
      return {
        perioden: this.perioden,
      };
    },

    periodenLocalBewilligt() {
      return get(this.antrag, "a_zuschussbewilligt_data.perioden");
    },
  },
};
