"use strict";

import sucheTemplate from "./suche.jade";
import store from "../../../vue/client/vue/store";
import {
  EventBus,
} from "../../../vue/client/vue/global/functions/event-bus";

const LIMIT = 25;
let offset = 25;

class SucheController {
  /*@ngInject*/
  constructor(Search, $scope, $filter, i18n) {
    this.$filter = $filter;
    this.Search = Search;
    this.gettext = i18n.gettext;
    this.searchTerm = "";
    this.searchResults = [];
    this.model = {};
    this.kontext = {
      Foerderantrag: true,
      Antragstellerorganisation: true
    };
    this.checkModelInStore();
    this.onChangeSearchGlobalModel = this.onChangeSearchGlobalModel.bind(this);
    this.onSearchGlobal = this.onSearchGlobal.bind(this);
    this.initEventBusses();
    $scope.$on("$destroy", () => {
      this.destroyEventBusses();
    });
  }

  checkModelInStore() {
    this.searchTerm = store.state.searchGlobal.MODEL;
    if (this.searchTerm) {
      this.loadMore(true);
    }
  }

  initEventBusses() {
    EventBus.$on("onChangeSearchGlobalModel", this.onChangeSearchGlobalModel);
    EventBus.$on("onSearchGlobal", this.onSearchGlobal);
  }

  destroyEventBusses() {
    EventBus.$off("onChangeSearchGlobalModel");
    EventBus.$off("onSearchGlobal");
  }

  onSearchGlobal() {
    this.loadMore(true);
  }

  onChangeSearchGlobalModel(model) {
    this.searchTerm = model;
  }

  changeSearchTerm() {
    store.commit("searchGlobal/MUT_CHANGE_MODEL", this.searchTerm);
  }

  loadMore(firstTime, statusIncorrektTerm) {
    if (!this.kontext.Foerderantrag && !this.kontext.Antragstellerorganisation) {
      return;
    }
    if (this.loading) {
      return;
    }
    this.loading = true;

    this.model = {};
    this.model.limit = LIMIT;
    this.model.term = this.searchTerm;

    if (this.kontext.Foerderantrag && !this.kontext.Antragstellerorganisation) {
      this.model.model = "Foerderantrag";
    } else if (!this.kontext.Foerderantrag && this.kontext.Antragstellerorganisation) {
      this.model.model = "Antragstellerorganisation";
    }

    if (firstTime) {
      offset = 0;
      this.searchCount = 0;
      this.statusFirstTime = false;
      this.searchResults = [];
      if (!statusIncorrektTerm) {
        this.incorrektTerm = null;
      }
    } else {
      this.model.offset = offset;
    }

    this.statusLoadMore = false;

    this.statusSpellcheck = false;
    this.correctTerm = null;

    this.Search.doSearch(this.model).then(
      response => {
        if (response.results.length === LIMIT) {
          offset += LIMIT;
          this.statusLoadMore = true;
        } else {
          offset = 0;
        }
        /* angular.forEach(response.data, value => {
         if (value.model === "Foerderantrag" && value.a_nr.length !== 8) {
         value.a_nr += " ";
         }
         });*/
        this.searchCount += response.count;
        if (firstTime) {
          this.statusFirstTime = true;
          this.searchResults = response.results;
        } else {
          this.searchResults = this.searchResults.concat(response.results);
        }

        if (response.spellcheck) {
          this.filterSpellcheck(response.spellcheck);
          if (!this.searchResults.length && this.correctTerm && this.correctTerm.misspellingsAndCorrections[1]) {
            this.loading = false;
            this.incorrektTerm = this.searchTerm;
            this.showCorrectTerm(this.correctTerm.misspellingsAndCorrections[1], true);
          }
        }
        this.loading = false;
      }
    );
  }

  showDescription(description) {
    if (!description) {
      return "";
    }
    if (description.length < 101) {
      return description;
    }
    const tempString = this.$filter("limitTo")(description, 100);
    const tempArr = tempString.split(" ");
    tempArr.pop();
    const result = tempArr.join(" ") + "...";
    return result;
  }

  showCorrectTerm(item, statusIncorrektTerm) {
    this.searchTerm = item;
    this.loadMore(true, statusIncorrektTerm);
  }

  filterSpellcheck(spellcheck) {
    const tempHits = 0;
    for (let i = 1; i < spellcheck.collations.length; i = i + 2) {
      if (spellcheck.collations[i].hits > tempHits) {
        this.correctTerm = spellcheck.collations[i];
      }
    }
    this.statusSpellcheck = true;
  }
}

export default {
  template: sucheTemplate(),
  controller: SucheController,
  controllerAs: "vm"
};
