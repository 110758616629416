import MitgliederModalCreate from "./MitgliederModalCreate/MitgliederModalCreate.vue";
import SmartTable from "../../../../../global/components/table/table.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";

import getTableApi from "../../../../../const/TableApis";

import {
  EventBus
} from "../../../../../global/functions/event-bus";

const TABLE_ID = "mitglieder";
const TEAM_LEITER_UUID = "ff39a9f1-57d3-48e1-bb4a-5a4df3d693a0";

// @vue/component
export default {
  name: "MitgliederList",
  components: {
    MitgliederModalCreate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    teamPk: {
      type: String,
      default: undefined,
    },
    organisationPk: {
      type: String,
      default: undefined,
    }
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: "",
        rowActions: [
          {
            label: "_TXT_TEAMS_DETAILS_MITGLIEDER_TABLE_ACTIONS_SET_TEAMLEITER_",
            callback: ({ item }) => this.setTeamleiter({ mitglied: item }),
          },
          {
            label: "_TXT_TEAMS_DETAILS_MITGLIEDER_TABLE_ACTIONS_DELETE_",
            callback: ({ item }) => this.mitgliedEntfernen({ mitglied: item }),
          },
        ],
        actions: [
          {
            label: "_TXT_TEAMS_DETAILS_MITGLIEDER_TABLE_ACTIONS_CREATE_",
            callback: () => this.openModalCreateMitglieder(),
            type: "button",
            className: "btn btn-default",
            icon: "glyphicon-plus",
            htmlId: "team_mitglieder_create",
          },
        ],
        label: "_TXT_TEAMS_DETAILS_MITGLIEDER_TABLE_LABEL_",
        sortable: false,
        pagination: true,
        details: false,
        showHeader: false,
        showColsConfig: false,
      },
      modal: {
        create: false,
      },
    };
  },
  created() {
    this.initUrl();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { teamPk: this.teamPk } });
    },

    setTeamleiter({ mitglied }) {
      mitglied.rolle = TEAM_LEITER_UUID;
      this.putHttp({ url: `${ this.options.url }${ mitglied.pk }/update_leiter/`, data: mitglied
      }).then(
        () => {
          this.addNotification({ text: "_MSG_TEAMS_DETAILS_MITGLIEDER_SET_TEAMLEITER_SUCCESS_" });
          EventBus.$emit(`updateTable${ TABLE_ID }`);
        },
        () => {
          this.addNotification({ text: "_MSG_TEAMS_DETAILS_MITGLIEDER_SET_TEAMLEITER_ERROR_", type: "error" });
        }
      );
    },

    mitgliedEntfernen({ mitglied }) {
      this.deleteHttp({ url: `${ this.options.url }${ mitglied.pk }/` }).then(
        () => {
          this.addNotification({ text: "_MSG_TEAMS_DETAILS_MITGLIEDER_TABLE_DELETE_SUCCESS_" });
          EventBus.$emit(`updateTable${ TABLE_ID }`);
        },
        () => {
          this.addNotification({ text: "_MSG_TEAMS_DETAILS_MITGLIEDER_TABLE_DELETE_ERROR_", type: "error" });
        }
      );
    },

    openModalCreateMitglieder() {
      this.modal.create = true;
    },

    closeModalCreateMitglieder({ status } = {}) {
      this.modal.create = false;
      if (status === true) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
    }
  },
};
