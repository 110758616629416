"use strict";

import parameterServices from "../services";
import template from "./config.jade";
import readonly from "./readonly.jade";
import controller from "./controller";


const parametermoduleJSON = angular.module("dias.foerderregeln.parameter.json", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("json", "parametermodul-json");
  })
  .component("parametermodulJson", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulJsonRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: readonly(),
    controllerAs: "vm",
  });
export default parametermoduleJSON;
