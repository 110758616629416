import {
  cloneDeep,
  forEach,
  size,
} from "lodash-es";

// @vue/component
export default {
  methods: {
    setModelFalseForAll({ model }) {
      const MODEL = cloneDeep(model);
      forEach(MODEL.antworten, antwort => {
        antwort.status = false;
        if (antwort.children && size(antwort.children)) {
          forEach(antwort.children, child => {
            child.status = false;
          });
        }
      });
      return MODEL;
    },
  },
};
