import HttpMixin from "../../../../../global/mixins/HttpMixin";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import translate from "../../../../../global/directives/translate";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import SyConfigMixin from "../../../../../global/mixins/SyConfigMixin";
import {
  cloneDeep,
  forEach,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "AstBankverbindungenModalCreateOrUpdate",
  components: {
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin
  ],
  props: {
    currentBv: {
      type: Object,
      required: false,
      default: undefined,
    },
    modelFromModul: {
      type: Object,
      required: true,
    },
    selectorClose: {
      type: [String, Array],
      required: true,
    },
    urlSave: {
      type: String,
      required: true,
    },
    infoIText: {
      type: String,
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    bvIndex() {
      if (isUndefined(this.currentBv)) {
        return (this.modelFromModul.bankverbindungen.length);
      }
      let idx = undefined;
      forEach(this.modelFromModul.bankverbindungen, (item, index) => {
        if (this.currentBv.pk === item.pk) {
          idx = index;
          return false;
        }
      });
      return idx;
    },

    headerText() {
      return this.currentBv ?
        "_TXT_GR_AST_BANKVERBINDUNGEN_MODAL_UPDATE_HEADER_" :
        "_TXT_GR_AST_BANKVERBINDUNGEN_MODAL_CREATE_HEADER_";
    },

    msgSuccess() {
      return this.currentBv ?
        "_TXT_GR_AST_BANKVERBINDUNGEN_MODAL_UPDATE_SUCCESS_" :
        "_TXT_GR_AST_BANKVERBINDUNGEN_MODAL_CREATE_SUCCESS_";
    },

    msgError() {
      return this.currentBv ?
        "_TXT_GR_AST_BANKVERBINDUNGEN_MODAL_UPDATE_ERROR_" :
        "_TXT_GR_AST_BANKVERBINDUNGEN_MODAL_CREATE_ERROR_";
    },

    optionsModal() {
      const sy_conf = this.getSyConfigsValue("bankverbindung_weitere_pflichtfelder");
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "aov_inhaber",
            htmlId: "modal_aov_inhaber",
            type: "text",
            label: "_LBL_GR_AST_BANKVERBINDUNGEN_INHABER_",
            view: "v",
            required: true,
          },
          {
            id: "aov_iban",
            htmlId: "modal_aov_iban",
            type: "text",
            label: "_LBL_GR_AST_BANKVERBINDUNGEN_IBAN_",
            view: "v",
            required: true,
          },
          {
            id: "aov_bic",
            htmlId: "modal_aov_bic",
            type: "text",
            label: "_LBL_GR_AST_BANKVERBINDUNGEN_BIC_",
            view: "v",
            required: sy_conf.bic,
          },
          {
            id: "aov_bank",
            htmlId: "modal_aov_bank",
            type: "text",
            label: "_LBL_GR_AST_BANKVERBINDUNGEN_BANK_",
            view: "v",
            required: sy_conf.bank,
          },
        ],
      };
    },
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      let model = {};
      if (this.currentBv) {
        model = cloneDeep(this.currentBv);
      } else {
        model = {};
      }
      model.validierung_erforderlich = true;
      this.model = model;
    },

    saveLocal() {
      this.status.loading = true;
      this.clearErrors();
      const DATA = this.prepareDataForSave();
      this.save({
        model: DATA,
      }).then(
        () => {
          this.addNotification({
            text: this.msgSuccess,
          });
          this.close();
        },
        error => {
          this.onError({ error: error.data });
          this.addNotification({
            text: this.msgError,
            type: "error",
          });
        }
      ).then(
        () => this.status.loading = false
      );
    },

    prepareDataForSave() {
      const DATA = cloneDeep(this.modelFromModul);
      DATA.bankverbindungen.splice(this.bvIndex, 1, this.model);
      return DATA;
    },

    onError({ error }) {
      if (error.bankverbindungen && error.bankverbindungen[this.bvIndex]) {
        this.errors = error.bankverbindungen[this.bvIndex];
        this.errorsClone = cloneDeep(error.bankverbindungen[this.bvIndex]);
      }
    },

    clearErrors() {
      this.errors = undefined;
      this.errorsClone = undefined;
    },
  },
};
