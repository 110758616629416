import FormElementReadOnly from "../../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import Modal from "../../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxErrors from "../../../../../../global/components/PuxErrors/PuxErrors.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../../../global/mixins/NotificationMixin";
import SyConfigMixin from "../../../../../../global/mixins/SyConfigMixin";

import moment from "moment";
import {
  cloneDeep,
  concat,
  get,
  isNil,
  join,
  map,
  omit,
  omitBy,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "ModalCreateOrEdit",
  components: {
    FormElementReadOnly,
    Modal,
    PuxButton,
    PuxCloak,
    PuxErrors,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
    NotificationMixin,
  ],
  props: {
    model: {
      type: Object,
      required: false,
      default: undefined,
    },
    objekttypen: {
      type: Array,
      required: false,
      default: undefined,
    },
    personal: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modelLocal: {
        foe_ids: [],
        pk_anmerkung: undefined,
        gueltigkeit: {
          gueltigkeit_after: undefined,
          gueltigkeit_before: undefined,
        },
        content_type: undefined,
      },
      url: undefined,
      httpMehtod: undefined,
      status: {
        loading: undefined,
      },
      errors: undefined,
    };
  },
  computed: {
    translationTitle() {
      if (this.isCreateMode) {
        return "_HTML_PERSONAL_KOMPETENZ_MODAL_CREATE_HEADER_{{name}}_{{o_name}}_";
      }
      return "_HTML_PERSONAL_KOMPETENZ_MODAL_UPDATE_HEADER_{{name}}_{{o_name}}_{{angebote_list}}_{{objekttyp}}_";
    },

    translationExtra() {
      const EXTRA = {
        name: this.personal.name,
        o_name: this.personal.o_name,
      };
      if (!this.isCreateMode) {
        EXTRA.angebote_list = join(map(this.model.angebote, foe => foe.foe_kbez), ", ");
        EXTRA.objekttyp = this.model.content_type_bez || "_TXT_KEINE_ANGABE_";
      }
      return EXTRA;
    },

    modalOptions() {
      const OPTIONS = {
        showCloseButton: true,
        required: true,
        list: []
      };
      if (this.isCreateMode) {
        if (this.showObjekttyp) {
          OPTIONS.list.push(this.objekttypFormElement);
        }
        OPTIONS.list.push(this.angeboteFormElement);
      }
      OPTIONS.list.push({
        id: "gueltigkeit",
        type: "daterange",
        label: "_LBL_PERSONAL_KOMPETENZ_GUELTIGKEIT_",
        helpText: "_TXT_PERSONAL_KOMPETENZ_GUELTIGKEIT_HELP_TEXT_",
        view: "v-alt",
        placeholderMin: "_LBL_DATERANGE_AB_HEUTE_",
        placeholderMax: "_LBL_DATERANGE_UNEINGESCHRAENKT_",
        disabledMin: this.isDisabledGueltigAb,
        disabledMax: this.isDisabledGueltigBis,
      }, {
        id: "pk_anmerkung",
        type: "richtext",
        label: "_LBL_PERSONAL_KOMPETENZ_ANMERKUNG_",
        helpText: "_TXT_PERSONAL_KOMPETENZ_ANMERKUNG_HELP_TEXT_",
        required: false,
        translate: true,
        view: "v",
      });
      return OPTIONS;
    },

    isCreateMode() {
      return isNil(get(this.model, "pk"));
    },

    showObjekttyp() {
      return size(this.objekttypen) > 1;
    },

    isDisabledGueltigAb() {
      return !this.isCreateMode
        && !isNil(this.model.pk_gueltig_ab)
        && moment().isSameOrAfter(this.model.pk_gueltig_ab);
    },

    isDisabledGueltigBis() {
      return !this.isCreateMode
        && !isNil(this.model.pk_gueltig_bis)
        && moment().isSameOrAfter(this.model.pk_gueltig_bis);
    },

    objekttypFormElement() {
      return {
        id: "content_type",
        type: "select",
        label: "_LBL_PERSONAL_KOMPETENZ_CONTENT_TYPE_",
        helpText: "_TXT_PERSONAL_KOMPETENZ_CONTENT_TYPE_HELP_TEXT_",
        required: true,
        showLabel: true,
        search: true,
        searchList: ["bez"],
        view: "v",
        keyId: "pk",
        keyLabel: "bez",
        menuWidthAll: true,
        data: this.objekttypen,
      };
    },

    angeboteFormElement() {
      const OPTIONS = {
        id: "foe_ids",
        type: "multiselect",
        label: "_LBL_FOERDERANGEBOT_PLURAL_",
        helpText: "_TXT_FOERDERANGEBOT_PLURAL_HELP_TEXT_",
        required: true,
        showLabel: true,
        search: true,
        searchList: ["foe_kbez"],
        selectAll: true,
        deselectAll: true,
        view: "v",
        keyId: "pk",
        keyLabel: "foe_kbez",
        menuWidthAll: true,
        translate: true,
      };
      if (this.isCreateMode) {
        OPTIONS.url = "katalog/";
        OPTIONS.urlParams = {
          key: "angebot"
        };
        OPTIONS.apiSaveId = "katalog_angebot";
      } else {
        OPTIONS.data = cloneDeep(this.model.angebote);
      }
      return OPTIONS;
    },

    errorsLabelsOptions() {
      return [{
        id: "content_type",
        label: "_LBL_PERSONAL_KOMPETENZ_CONTENT_TYPE_",
      }, {
        id: "foe_ids",
        label: "_LBL_FOERDERANGEBOT_PLURAL_",
      }, {
        id: "pk_anmerkung",
        label: "_LBL_PERSONAL_KOMPETENZ_ANMERKUNG_",
      }, {
        id: "gueltigkeit",
        htmlId: "gueltigkeit",
        label: "_LBL_PERSONAL_KOMPETENZ_GUELTIGKEIT_",
      }];
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (!this.isCreateMode) {
        this.modelLocal = {
          foe_ids: map(this.model.angebote, foe => foe.pk),
          pk_anmerkung: this.model.pk_anmerkung,
          gueltigkeit: {
            gueltigkeit_after: this.model.pk_gueltig_ab,
            gueltigkeit_before: this.model.pk_gueltig_bis,
          },
          content_type: this.model.content_type,
        };
        this.httpMehtod = this.putHttp;
        this.url = `personal/${ this.personal.pk }/kompetenzen/${ this.model.pk }/`;
      } else {
        this.httpMehtod = this.postHttp;
        this.url = `personal/${ this.personal.pk }/kompetenzen/`;
        if (size(this.objekttypen) === 1) {
          this.modelLocal.content_type = this.objekttypen[0].pk;
        }
      }
    },

    getSaveData() {
      const DATA = omit(this.modelLocal, ["gueltigkeit"]);
      DATA.pk_gueltig_ab = get(this.modelLocal, "gueltigkeit.gueltigkeit_after");
      DATA.pk_gueltig_bis = get(this.modelLocal, "gueltigkeit.gueltigkeit_before");
      if (!isNil(DATA.pk_gueltig_ab) && moment().isSame(DATA.pk_gueltig_ab, "day")
          && !this.isDisabledGueltigAb
          && (this.isCreateMode || !moment().isSame(get(this.model, "pk_gueltig_ab"), "day"))) {
        DATA.pk_gueltig_ab = null;
      }
      if (!isNil(DATA.pk_gueltig_bis) && !this.isDisabledGueltigBis) {
        DATA.pk_gueltig_bis = moment(DATA.pk_gueltig_bis).endOf("day").format();
      }
      DATA.personal = this.personal.pk;
      return DATA;
    },

    save() {
      this.status.loading = true;
      this.httpMehtod({
        url: this.url,
        data: this.getSaveData(),
        showError: false,
      }).then(
        response => {
          if (this.isCreateMode) {
            this.addNotification({ text: "_MSG_PERSONAL_KOMPETENZ_MODAL_CREATE_SUCCESS_" });
          } else {
            this.addNotification({ text: "_MSG_PERSONAL_KOMPETENZ_MODAL_UPDATE_SUCCESS_" });
          }
          this.close({ response });
        },
        error => this.onError({ error: error.data })
      ).finally(
        () => this.status.loading = false
      );
    },

    onError({ error }) {
      if (get(error, "foe_ids.non_field_errors")) {
        error.foe_ids = error.foe_ids.non_field_errors;
      }
      error.gueltigkeit = concat(
        get(error, "pk_gueltig_ab", []),
        get(error, "pk_gueltig_bis", []),
      );
      this.errors = omit(
        omitBy(error, err => size(err) < 1),
        ["pk_gueltig_ab", "pk_gueltig_bis"]
      );
    }
  },
};
