"use strict";

import template from "./details.jade";


class TableauDetailsController {
  /*@ngInject*/
  constructor($stateParams, Tableau) {
    this.loading = true;

    Tableau.one($stateParams.id).get().then(
      response => this.tableau = response
    ).finally(() => this.loading = false);
  }
}

export default {
  template: template(),
  controller: TableauDetailsController,
  controllerAs: "vm"
};
