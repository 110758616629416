"use strict";

import BaseModel from "dias/core/models/baseModel";

class TempDokument extends BaseModel {
  constructor() {
    super("tempdok");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.getUploadUrl = () => {
      return `${ collection.getRestangularUrl() }/`;
    };
    return collection;
  }
}

export default TempDokument;
