import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import {
  FilterDateMixin,
} from "../../../global/mixins/FiltersMixin";

import { gettext } from "../../../global/functions/utils";

// @vue/component
export default {
  name: "NutzerModalAktivieren",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    FilterDateMixin,
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      status: {
        loading: undefined,
      },
    };
  },
  computed: {
    getSperrbemerkung() {
      return this.nutzer.n_sperrbemerkung || gettext("_TXT_NUTZER_MODAL_AKTIVIEREN_KEINE_BEMERKUNG_");
    },

    getNutzerGesperrtDate() {
      return this.filterDate(this.nutzer.n_gesperrt_am, "DD.MM.YYYY");
    },
  },
  methods: {
    aktivierenNutzer() {
      this.status.loading = true;
      this.postHttp({ url: `nutzer/${ this.nutzer.pk }/aktivieren/` }).then(
        response => {
          this.addNotification({ text: "_MSG_NUTZER_MODAL_AKTIVIEREN_SUCCESS_" });
          this.close({ response });
        },
        () => this.addNotification({ text: "_MSG_NUTZER_MODAL_AKTIVIEREN_ERROR_", type: "error" })
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
