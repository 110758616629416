import Alert from "../../../../global/components/Alert/Alert.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import GeschaeftsregelModul from "../../../Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";

import {
  findIndex,
  indexOf,
  size,
  sortBy,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragstellerWizardRegeln",
  components: {
    Alert,
    FormstepDetail,
    GeschaeftsregelModul,
    PuxCloak,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    organisation: {
      type: Object,
      required: true,
    },
    regelSaveCallback: {
      type: Function,
      required: false,
      default: () => {}
    },
    regelIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      regeln: [],
      statusLoading: false
    };
  },
  computed: {
    baseUrl() {
      return `antragstellerorganisationen/${ this.organisation.pk }/`;
    }
  },
  created() {
    this.loadRegeln();
  },
  methods: {
    loadRegeln() {
      if (!this.organisation || size(this.regelIds) === 0) {
        return;
      }
      this.statusLoading = true;
      this.getHttp({
        url: `antragstellerorganisationen/${ this.organisation.pk }/module/`,
        urlParams: {
          regelsatzregel: this.regelIds
        }
      }).then(
        response => this.regeln = sortBy(response, el => indexOf(this.regelIds, el.regel.regelsatz_regel_id))
      ).finally(
        () => this.statusLoading = false
      );
    },

    updateModule({ response }) {
      const INDEX = findIndex(this.regeln, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.regeln.splice(INDEX, 1, response);
      }
    },
  },
};
