"use strict";

import BaseModel from "dias/core/models/baseModel";

class Foerderangebotstatus extends BaseModel {
  constructor() {
    super("foerderangebotstatus");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.fetchDeletable = () => collection.customGET("deletable");
    collection.fetchEditable = () => collection.customGET("editable");

    return collection;
  }
}

export default Foerderangebotstatus;
