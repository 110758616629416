import DashboardModule from "../Module";
import FormElement from "../../../global/components/FormElement/FormElement.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTooltip from "../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "DashboardPrivate",
  components: {
    FormElement,
    PageTabTitle,
    PuxTooltip,
    PuxTranslate,
    ...DashboardModule,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  data() {
    return {
      statusLoadingInit: true,
      statusLoadingElements: true,
      user: {},
      availableDashboards: [],
      selectedDashboard: undefined,
      news: [],
      interval: undefined,
      dashboard: undefined,
      dashboardData: {},
      loadedDashboards: {},
      loadingDashboardElements: {},
      classGrid: {},
    };
  },
  computed: {
    statusShowDashboardSelect() {
      return this.availableDashboards.length >= 2;
    },

    dashboardUrl() {
      const SUFFIX = this.selectedDashboard ? `${ this.selectedDashboard }/` : "";
      return `dashboard/${ SUFFIX }`;
    },

    selectOptions() {
      return {
        change: this.changeDashboard,
        data: [],
        id: "dashboard-role",
        keyId: "pk",
        keyValue: "pk",
        keyLabel: "name",
        label: "_LBL_DASHBOARD_PRIVATE_ROLLE_",
        view: "v",
        type: "select",
      };
    }
  },
  created() {
    this.setClassGrid();
    this.getUser();
    this.initDashboard();
    this.reloadNews();
    this.initInterval();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    setClassGrid() {
      const CLASS_GRID = {};
      for (let i = 1; i <= 8; i++) {
        CLASS_GRID[i] = `flex-col col-dias-md-${ i } col-dias-sm-${ i * 2 }`;
      }
      this.classGrid = CLASS_GRID;
    },

    initInterval() {
      this.interval = setInterval(() => {
        this.reloadDashboard();
        this.reloadNews();
      }, 50 * 60 * 1000);
    },

    getUser() {
      this.user = cloneDeep(this.me);
    },

    /**
     * Lädt die Konfiguration des Dashboards für den Nutzer
     */
    initDashboard() {
      if (this.loadedDashboards[this.selectedDashboard]) {
        this.dashboard = this.loadedDashboards[this.selectedDashboard];
        this.statusLoadingElements = false;
        return;
      }

      this.getHttp({
        url: this.dashboardUrl,
        urlParams: {
          config: true,
        },
      }).then(
        response => {
          this.dashboard = response.current.config;
          this.availableDashboards = response.dashboards;
          this.selectOptions.data = this.availableDashboards;
          this.selectedDashboard = response.current.pk;
          this.loadedDashboards[this.selectedDashboard] = cloneDeep(this.dashboard);
          this.statusLoadingInit = false;
          this.reloadDashboard();
        }
      );
    },

    reloadDashboard() {
      if (!this.dashboard || !this.selectedDashboard) {
        return;
      }
      this.statusLoadingElements = true;
      const PROMISES = [];
      forEach(this.dashboard, item => {
        this.loadingDashboardElements[item.pk] = true;
        const ITEM_PROMISE = this.getHttp({
          url: `dashboard/${ this.selectedDashboard }/elemente/${ item.pk }/`
        }).then(
          response => {
            this.dashboardData[response.pk] = response;
            this.loadingDashboardElements[item.pk] = false;
          },
          error => console.error("Fehler bei DashboardElement abrufen", item, error)
        );
        PROMISES.push(ITEM_PROMISE);
      });
      Promise.all(PROMISES).then(
        () => {
          this.statusLoadingElements = false;
        }
      );
    },

    reloadNews() {
      this.getListHttp({
        url: "news/",
        urlParams: {
          nws_intern: true,
          sichtbar: true,
        },
      }).then(
        response => {
          this.news = response;
        }
      );
    },

    changeDashboard() {
      this.statusLoadingElements = true;
      this.initDashboard();
    },

    attributesLocal(item, index) {
      if (item.bez !== "div") {
        return {
          pk: item.pk,
          argument: this.dashboardData[item.pk] || item,
          tooltipId: `dashboardelement_tooltip_${ index }`,
          loading: this.loadingDashboardElements[item.pk],
          dataCount: item.class,
        };
      }
    },
  },
};
