"use strict";

import "angular-animate/angular-animate";
import "angular-strap";
import "angular-strap/dist/angular-strap.tpl";
import "angular-motion/dist/angular-motion.css";

import addComponent from "./add.component";
import configComponent from "./config.component";

import parametermodule from "dias/foerderregeln/parameter";

const regel = angular.module("dias.foerderangebote.regel", [
  parametermodule.name,
])

  .component("foerderangebotRegelConfig", configComponent)
  .component("foerderangebotRegelAdd", addComponent)
;

export default regel;
