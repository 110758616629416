var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (rulesBez, rulesKbez) {









jade_mixins["sitzungsmodul"] = jade_interp = function(idx, title, info, diffBlock){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-class", "{'dirty': !vm.isClean(), 'edit': vm.editMode, 'version-detail': " + (diffBlock) + "}", true, true)) + " class=\"form-step-detail\"><dias-info-box" + (jade.attr("idx", "" + (idx) + "", true, true)) + (jade.attr("name", "" + (title) + "", true, true)) + (jade.attr("info-text", "" + (info) + "", true, true)) + (jade.attr("rules-kbez", "" + (rulesKbez) + "", true, true)) + (jade.attr("rules-bez", "" + (rulesBez) + "", true, true)) + " disabled=\"vm.loading\" editable=\"!vm.readonly &amp;&amp; vm.hasEdit\" edit-mode=\"vm.editMode\" toggle-edit-mode=\"vm.toggleEditMode()\" is-missing-input=\"vm.isMissingInput()\"></dias-info-box><!--h4span.states(ng-if=\"vm.isMissingInput()\")\n  i.glyphicon.glyphicon-achtung.text-warning(title=\"unvollständig ausgefüllt\") &nbsp;\nif idx\n  span " + (jade.escape((jade_interp = idx) == null ? '' : jade_interp)) + "&nbsp;\nspan " + (jade.escape((jade_interp = title) == null ? '' : jade_interp)) + "\nif info\n  button.btn.btn-icon.toggle-info(type=\"button\" data-toggle=\"info\")\n    span.glyphicon.glyphicon-info-sign--><!--div.actionsbutton.btn.btn-icon(type=\"button\" ng-disabled=\"vm.loading\" ng-show=\"!vm.readonly && vm.hasEdit\" ng-click=\"vm.toggleEditMode()\"\n                    data-toggle=\"edit\" ng-switch=\"vm.editMode\")\n  span.glyphicon.glyphicon-remove(ng-switch-when=\"true\")\n  span(ng-switch-default)\n    i.glyphicon.glyphicon-bearbeiten\n    span Bearbeiten--><!--if infodiv.info(ng-bind-html=\"" + (jade.escape((jade_interp = info) == null ? '' : jade_interp)) + "\")--><form name=\"vm.form\" novalidate dias-loading=\"vm.loading\" loading-msg=\"'Daten werden gespeichert'\" class=\"form-horizontal\">");
block && block();
buf.push("</form></div>");
};
jade_mixins["readonly-block"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div ng-show=\"!vm.editMode\" ng-click=\"!vm.readonly &amp;&amp; vm.hasEdit ? vm.toggleEditMode() : null\" data-toggle=\"{{!vm.readonly &amp;&amp; vm.hasEdit ? 'edit' : ''}}\" ng-class=\"{ 'editable' : !vm.readonly &amp;&amp; vm.hasEdit }\" class=\"clearfix\">");
block && block();
buf.push("</div>");
};
jade_mixins["edit-block"] = jade_interp = function(title){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div ng-if=\"vm.editEnteredOnce\" ng-show=\"vm.editMode\"><fieldset><div ng-if=\"vm.hasErrors()\" role=\"alert\" class=\"alert alert-danger\"><p><strong>Bitte prüfen Sie Ihre Eingaben.</strong></p><ul ng-if=\"vm.errors['non_field_errors']\" class=\"list-unstyled\"><li ng-repeat=\"err in vm.errors['non_field_errors']\">{{ err }}</li></ul></div>");
block && block();
buf.push("</fieldset><div class=\"form-actions edit-actions row\"><div class=\"col-lg-6\"><dias-requirement></dias-requirement></div><div class=\"col-lg-6 right\"><div class=\"form-actions\"><button ng-click=\"vm.save()\"" + (jade.attr("ng-attr-title", "Formular " + (title) + " speichern", true, true)) + " class=\"btn btn-primary primary-action test-save\">Speichern</button><button ng-click=\"vm.setEditMode(false);\"" + (jade.attr("ng-attr-title", "Formular " + (title) + " abbrechen", true, true)) + " class=\"btn btn-icon secondary-action\">Abbrechen</button></div></div></div></div>");
};
jade_mixins["form-element"] = jade_interp = function(idx, label, helpText, fieldName){
var block = (this && this.block), attributes = (this && this.attributes) || {};
jade_mixins["client-form-error-list-els"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
};
buf.push("<div" + (jade.cls([attributes.class], [false])) + "><label" + (jade.attr("ng-class", "{'has-error': vm.errors['" + (fieldName) + "'] || vm.form['" + (fieldName) + "'].$invalid , 'warning': vm.isMissing('" + (fieldName) + "')}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = idx) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span" + (jade.attr("ng-show", "vm.isFieldRequired('" + (fieldName) + "');", true, true)) + ">*</span><span class=\"alert-danger\"></span></span><br><span class=\"hint\">" + (jade.escape(null == (jade_interp = helpText) ? "" : jade_interp)) + "</span><div>");
block && block();
buf.push("<div" + (jade.attr("ng-if", "vm.form['" + (fieldName) + "'].$invalid || vm.errors['" + (fieldName) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (fieldName) + "']", true, true)) + ">{{ err }}</li>");
jade_mixins["client-form-error-list-els"]();
buf.push("</ul></div></div></label></div>");
};
jade_mixins["sitzungsmodul"].call({
block: function(){
jade_mixins["edit-block"].call({
block: function(){
buf.push("<div ng-if=\"vm.errors.non_field_errors\" role=\"alert\" class=\"alert alert-danger\"><p><strong>Beim Speichern der Daten sind Fehler aufgetreten:</strong></p><ul class=\"list-unstyled\"><li ng-repeat=\"err in vm.errors.non_field_errors\">{{ err }}</li></ul></div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<textarea ng-model=\"vm.userInput.protokoll\" name=\"protokoll\" dias-richtext startup-focus class=\"form-control\"></textarea>");
}
}, "", "Protokoll", "", "protokoll");
}
}, "Tagesordnungspunkt-Protokoll");
jade_mixins["readonly-block"].call({
block: function(){
buf.push("<fieldset><alert type=\"'warning'\" ng-if=\"!vm.hasViewPermission\"><span class=\"glyphicon glyphicon-warning\"></span> Sie haben nicht die Berechtigung das Protokoll einzusehen.</alert><div ng-if=\"vm.hasViewPermission\"><div ng-if=\"vm.userInput.protokoll\" ng-bind-html=\"vm.userInput.protokoll\"></div><p ng-if=\"!vm.userInput.protokoll\">[Noch kein Protokoll hinterlegt]</p></div></fieldset>");
}
});
}
}, "", "'Tagesordnungspunkt-Protokoll'", undefined, "false");}.call(this,"rulesBez" in locals_for_with?locals_for_with.rulesBez:typeof rulesBez!=="undefined"?rulesBez:undefined,"rulesKbez" in locals_for_with?locals_for_with.rulesKbez:typeof rulesKbez!=="undefined"?rulesKbez:undefined));;return buf.join("");
}