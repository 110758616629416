import HttpMixin from "../../../../global/mixins/HttpMixin";
import Alert from "../../../../global/components/Alert/Alert.vue";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  components: {
    Alert,
  },
  mixins: [
    HttpMixin,
  ],
  emits: [
    "changeModel",
  ],
  data() {
    return {
      projekte: [],
      statusLoadingProjekte: true,
      textKeineProjekte: "Erfassen Sie bitte zunächst ein Projekt (Punkt 4), bevor Sie hier weitere Angaben machen.",
      labelProjekt: "Projekt",
    };
  },
  computed: {
    eingabeHilfetext() {
      if (this.kontext === "antrag") {
        return "Bitte geben Sie die Werte pro Einzelprojekt an.";
      }
      if (this.kontext === "beleg") {
        return "Bitte geben Sie nur einen Wert für das Gesamtprojekt an.";
      }
      return "";
    },

    urlProjekte() {
      const ANTRAG_ID = this.extra.regel.afr_aid;
      return `foerderantraege/${ ANTRAG_ID }/projekte/`;
    },

    optionsProjekt() {
      return {
        id: "projekt",
        htmlId: this.htmlIdProjekt,
        label: this.labelProjekt,
        view: this.view,
        type: "select",
        required: true,
        data: this.projekte,
        loading: this.statusLoadingProjekte,
        keyLabel: "titel",
        keyId: "pk",
        search: true,
        searchList: ["titel"],
        placeholder: this.placeholderProjekt,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdProjekt() {
      return `${ this.htmlId }_projekt`;
    },

    placeholderProjekt() {
      return this.statusLoadingProjekte ?
        "Daten werden geladen..." :
        "--- Wählen Sie das Projekt aus. ---";
    },
  },
  created() {
    this.loadProjekte();
  },
  methods: {
    loadProjekte() {
      /* Ermittelt die in einem Antrag erfassten Projekte.*/
      this.getHttp({
        url: this.urlProjekte,
      }).then(
        response => {
          this.projekte = response;
          this.initProjektModel();
          this.statusLoadingProjekte = false;
        }
      );
    },

    initProjektModel() {
      if (this.projekte.length === 1 && !this.model.argument.projekt) {
        const MODEL = cloneDeep(this.model);
        MODEL.argument.projekt = this.projekte[0].pk;
        this.$emit("changeModel", { model: MODEL });
      }
    },
  },
};
