import Modal from "../../../../../global/components/Modal/Modal.vue";
import HttpMixin from "../../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../../global/mixins/NotificationMixin";
import translate from "../../../../../global/directives/translate";
import DokartUUIDs from "../../../../../const/DokartUUIDs";
import BelegModalRechnenhilfeButtonMixin from "../BelegModalRechnenhilfeButtonMixin";
import PermissionMixin from "../../../../../global/mixins/PermissionMixin";
import {
  EventBus,
} from "../../../../../global/functions/event-bus";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import SyConfigMixin from "../../../../../global/mixins/SyConfigMixin";

import {
  cloneDeep,
  filter,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "BelegModalCreate",
  components: {
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    BelegModalRechnenhilfeButtonMixin,
    PermissionMixin,
    SyConfigMixin
  ],
  props: {
    position: {
      type: Object,
      required: true,
    },
    person: {
      type: Object,
      required: true,
    },
    vertragPk: {
      type: String,
      required: true,
    },
    vertragkosten: {
      type: String,
      required: true,
    },
    antragPk: {
      type: String,
      required: true,
    },
    periodenWithTeile: {
      type: Array,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      status: {
        loading: undefined,
        loadingBelegnummer: undefined,
      },
      periodeListFiltered: [],
      zeitgrenzen: {
        von: undefined,
        bis: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
      model: {

      },
      notAfterVon: undefined,
      notBeforeVon: undefined,
      notAfterBis: undefined,
      notBeforeBis: undefined,
      defaultValueVonAndBis: undefined,
      hasNextBelegnummer: false,
      showBelegnummer: true,
    };
  },
  computed: {
    options() {
      return {
        showCloseButton: true,
        required: true,
        autocomplete: "off",
        list: this.optionsList,
      };
    },

    optionsList() {
      if (!this.periodeListFiltered.length) {
        return [{
          type: "template",
          html: "_MSG_HTML_VERTRAG_DETAILS_BELEG_MODAL_KEINE_PERIODE_TEIL_",
        }];
      }
      const LIST = [
        {
          id: "vps_stellenbezeichnung",
          type: "text",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_STELLE_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_STELLE_INFO_I_",
          required: true,
          view: "v",
          disabled: true,
        },
        {
          id: "person",
          type: "text",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_PERSON_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_PERSON_INFO_I_",
          required: true,
          view: "v",
          disabled: true,
        },
        {
          id: "posperiode",
          type: "select",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_PERIODE_TEIL_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_PERIODE_TEIL_INFO_I_",
          required: true,
          view: "v",
          disabled: this.periodeListFiltered.length === 1,
          keyId: "pk",
          keyLabelJson: [
            {
              teg: "span",
              keyLabel: "von",
              filter: "date",
            },
            {
              teg: "span",
              string: " - ",
            },
            {
              teg: "span",
              keyLabel: "bis",
              filter: "date",
            },
          ],
          data: this.periodeListFiltered,
          change: this.changePeriodeTeil,
          hideIf: false,
          deselect: false,
        },
        {
          id: "vpb_bez",
          type: "text",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_BEZEICHNUNG_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_BEZEICHNUNG_INFO_I_",
          required: true,
          view: "v",
          disabled: false,
        },
        {
          id: "von",
          type: "date",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_NACHWEIS_VON_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_NACHWEIS_VON_INFO_I_",
          required: true,
          view: "v",
          disabled: false,
          notAfter: this.notAfterVon,
          notBefore: this.notBeforeVon,
          defaultValue: this.defaultValueVonAndBis,
        },
        {
          id: "bis",
          type: "date",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_NACHWEIS_BIS_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_NACHWEIS_BIS_INFO_I_",
          view: "v",
          disabled: false,
          notAfter: this.notAfterBis,
          notBefore: this.notBeforeBis,
          defaultValue: this.defaultValueVonAndBis,
          required: true,
        },
        {
          id: "belegbetrag",
          type: "currency",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_BELEGBETRAG_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_BELEGBETRAG_INFO_I_",
          view: "v",
          disabled: false,
          required: false,
        },
        {
          id: "wert",
          type: "currency",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_BETRAG_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_BETRAG_INFO_I_",
          view: "v",
          disabled: true,
          required: true,
        },
        {
          id: "dokument",
          type: "document",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_DOCUMENT_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_DOCUMENT_INFO_I_",
          saveUrl: `foerderantraege/${ this.antragPk }/dokumente/?dokart=${ DokartUUIDs.kosten }`,
          keyLabel: "dokument.dok_dokumentname",
          view: "v-alt",
          required: true,
          slot: "dokument",
        },
        {
          id: "dokument_geschwaerzt",
          type: "boolean",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_CONFIRMATION_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_CONFIRMATION_INFO_I_",
          view: "v-alt",
          disabled: false,
        },
      ];
      if (this.statusHasPermVertragskostenpositionsbelegeAnerkennen) {
        LIST.splice(8, 0, {
          id: "vpb_kommentar",
          type: "textarea",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_KOMMENTAR_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_KOMMENTAR_INFO_I_",
          maxlength: 200,
          view: "v-alt",
          disabled: false,
        });
      }
      if (!this.hasNextBelegnummer && this.showBelegnummer) {
        LIST.splice(3, 0, {
          id: "vpb_nummer",
          type: "text",
          label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_BELEGNUMMER_",
          tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_BELEGNUMMER_INFO_I_",
          required: true,
          view: "v",
          disabled: this.hasNextBelegnummer || this.status.loadingBelegnummer,
        });
      }
      return LIST;
    },

    statusHasPermVertragskostenpositionsbelegeAnerkennen() {
      return this.checkPermissionsSync({ perm: "vertragskostenpositionsbelege.anerkennen" });
    },

    statusDisabledButtonSave() {
      return !this.model.dokument_geschwaerzt ||
        !this.model.dokument ||
        !this.model.wert ||
        this.status.loading;
    },

    periodePk() {
      return get(this.periodeObj, `${ this.model.posperiode }.periode_obj.pk`);
    },
  },
  created() {
    this.filterPeriodeTeile();
    this.showBelegnummer = this.getSyConfigsValue("belege_nummer").anzeigen;
    this.initModel();
  },
  methods: {
    filterPeriodeTeile() {
      this.zeitgrenzen.von = this.person.vpsp_beschaeftigt_von;
      this.zeitgrenzen.bis = this.person.vpsp_beschaeftigt_bis;

      this.periodeListFiltered = filter(this.periodenWithTeile, item => {
        return !(item.bis < this.zeitgrenzen.von || item.von > this.zeitgrenzen.bis);
      });
    },

    changePeriodeTeil({ item }) {
      const VON_LOCAL = item.von > this.zeitgrenzen.von ? item.von : this.zeitgrenzen.von;
      const BIS_LOCAL = !this.zeitgrenzen.bis || item.bis < this.zeitgrenzen.bis ? item.bis : this.zeitgrenzen.bis;
      this.notAfterVon = BIS_LOCAL;
      this.notAfterBis = BIS_LOCAL;
      this.notBeforeVon = VON_LOCAL;
      this.notBeforeBis = VON_LOCAL;
      this.defaultValueVonAndBis = VON_LOCAL;
      this.setModelVonAndBis({ von: VON_LOCAL, bis: BIS_LOCAL });
    },

    setModelVonAndBis({ von, bis }) {
      if (this.model.von) {
        if (this.model.von < von || this.model.von > bis) {
          this.model.von = undefined;
        }
      }
      if (this.model.bis) {
        if (this.model.bis < von || this.model.bis > bis) {
          this.model.bis = undefined;
        }
      }
    },

    initModel() {
      const MODEL = {
        vps_stellenbezeichnung: this.position.vps_stellenbezeichnung,
        person: `${ this.person.vpsp_vorname } ${ this.person.vpsp_nachname }`,
        posperiode: this.periodeListFiltered.length === 1 ? this.periodeListFiltered[0].pk : undefined,
        von: undefined,
        bis: undefined,
        dokument_geschwaerzt: false,
        belegbetrag: null,
        vpb_kommentar: undefined,
      };
      this.model = MODEL;
      this.loadNextBelegnummer();
    },

    loadNextBelegnummer() {
      this.status.loadingBelegnummer = true;
      this.getHttp(
        { url: `vertraege/${ this.vertragPk }/belegnummer/` }
      ).then(
        response => {
          if (response.next) {
            this.model.vpb_nummer = response.next;
            this.hasNextBelegnummer = true;
          }
        }
      ).finally(
        () => this.status.loadingBelegnummer = false
      );
    },

    save() {
      this.status.loading = true;
      const DATA = this.prepareDataForSave();
      this.postHttp({
        url: `vertraege/${ this.vertragPk }/kosten/${ this.vertragkosten }/positionen/${ this.position.position }/belege/`,
        data: DATA
      }).then(
        response => {
          this.addNotification({ text: "_MSG_VERTRAG_DETAILS_BELEG_MODAL_CREATE_SUCCESS_" });
          EventBus.$emit(`updateTable${ "BelegeListInVertragTable" }`);
          this.close({ beleg: response, dokument: this.model.dokument });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },

    prepareDataForSave() {
      const MODEL = cloneDeep(this.model);
      const DATA = {
        vpb_nummer: MODEL.vpb_nummer,
        vpb_bez: MODEL.vpb_bez,
        extra: {
          dokument: MODEL.dokument && MODEL.dokument.dokument && MODEL.dokument.dokument.pk
        },
        vertragskostenposition: this.position.position,
        periode: this.periodePk,
        vpb_kommentar: MODEL.vpb_kommentar,
        argument: {
          wert: MODEL.wert,
          dokument_geschwaerzt: MODEL.dokument_geschwaerzt,
          von: MODEL.von,
          bis: MODEL.bis,
          posperiode: MODEL.posperiode,
          personalstellenperson: this.person.pk,
          belegbetrag: MODEL.belegbetrag,
        },
      };
      return DATA;
    },
  },
};
