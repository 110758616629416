"use strict";


class FoerderorganisationUpdateController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, FoerderorgInfo, i18n) {
    this.gettext = i18n.gettext;
    this.$q = $q;
    this.$scope = $scope;
    this.DiasNotification = DiasNotification;
    this.FoerderorgInfo = FoerderorgInfo;

    this.angebot = $scope.extras.angebot;

    this.loading = true;

    this.loadFoerderorganisationen();

    this.save = () => {
      const data = { organisation: this.model };
      data.instrumentvariante = this.angebot.instrumentvariante;
      data.foe_bez = this.angebot.foe_bez;
      data.foe_kbez = this.angebot.foe_kbez;
      data.foe_kuerzel = this.angebot.foe_kuerzel;

      const defer = $q.defer();
      const promise = this.angebot.customPUT(data);
      promise.then(
        response => {
          this.DiasNotification.page.success(this.gettext("Förderorganisation") + " erfolgreich verknüpft.");
          defer.resolve(response);
        },
        error => {
          defer.reject();
          this.errorsLabel = {
            organisation: this.gettext("Förderorganisationen") + ": ",
          };
          this.DiasNotification.form.clear();
          this.DiasNotification.form.error(error.data, "Bitte überprüfen Sie Ihre Eingaben", {}, this.errorsLabel);
          this.errors = error.data;
        }
      );
      return defer.promise;
    };
  }

  loadFoerderorganisationen() {
    if (this.angebot.organisation_obj && this.angebot.organisation_obj.pk) {
      this.model = this.angebot.organisation_obj.pk;
    }
    this.FoerderorgInfo.getList().then(
      response => {
        this.foerderorganisationen = response;
        this.loading = false;
      }
    );
  }
}

export default FoerderorganisationUpdateController;
