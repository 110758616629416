import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "FunktionsgruppenAssignmentItem",
  components: {
    PuxTranslate,
  },
  props: {
    funktionsgruppe: {
      type: Object,
      required: true,
      info: "Information von Funktionsgruppe",
    },
    searchModel: {
      type: String,
      required: false,
      default: undefined,
      info: "Suche-Text",
    },
    modelValue: {
      type: Array,
      required: true,
    },
  },
  emits: [
    "update:modelValue",
  ],
  setup() {
    const {
      filterSearchHighlight,
    } = FiltersAPI();

    return {
      filterSearchHighlight,
    };
  },
  computed: {
    statusChecked() {
      return this.statusCheckedIndex !== -1;
    },

    statusCheckedIndex() {
      return this.modelValue.indexOf(this.funktionsgruppe.key);
    },

    ariaChecked() {
      return `${ this.statusChecked }`;
    },

    nameWithFilter() {
      return this.filterSearchHighlight(this.funktionsgruppe.name, this.searchModel);
    },

    beschreibungWithFilter() {
      return this.filterSearchHighlight(this.funktionsgruppe.beschreibung, this.searchModel);
    },
  },
  methods: {
    changeLocal() {
      setTimeout(() => {
        const MODEL_VALUE = cloneDeep(this.modelValue);
        if (this.statusCheckedIndex !== -1) {
          MODEL_VALUE.splice(this.statusCheckedIndex, 1);
        } else {
          MODEL_VALUE.push(this.funktionsgruppe.key);
        }
        this.$emit("update:modelValue", MODEL_VALUE);
      });
    },
  },
};
