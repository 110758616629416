import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import KostenMixin from "../Mixins/KostenMixin";
import {
  cloneDeep,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenPkt",
  components: {
    KFStandard,
  },
  mixins: [
    KostenMixin,
  ],
  data() {
    return {
      pkt_id: undefined,
    };
  },
  computed: {
    sumLocal() {
      return this.summeCurrency;
    },

    extraKFLocal() {
      const EXTRA = cloneDeep(this.extraKF);
      EXTRA.pkt_id = this.pkt_id;
      return EXTRA;
    },
  },
  methods: {
    updateValueFromBetragsrechner({ pkt_id }) {
      this.pkt_id = pkt_id;
    },

    prepareModel_KFBaseMixin({ model }) {
      const MODEL = cloneDeep(model);
      const PKT_ID_LOCAL = get(MODEL, "argument.pkt_id");
      if (!PKT_ID_LOCAL) {
        MODEL.argument = MODEL.argument || {};
        MODEL.argument.pkt_id = this.pkt_id;
      }
      return MODEL;
    },
  },
};
