"use strict";

import BaseModel from "dias/core/models/baseModel";
import KatalogAdminEintrag from "./katalogAdmin.eintrag.service";

class KatalogAdmin extends BaseModel {
  constructor() {
    super("katalogadmin", {
      subPaths: {
        eintraege: new KatalogAdminEintrag()
      }
    });
  }
}

export default KatalogAdmin;
