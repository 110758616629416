var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<fieldset dias-cloak=\"vm.loadingEditData\"><alert type=\"'info'\" ng-if=\"!vm.hide &amp;&amp; vm.model.is_used\" dismissable=\"true\" on-dismiss=\"vm.hide = !vm.hide\"><span class=\"glyphicon-information\"></span>\nEs sollten nur noch redaktionelle Änderungen an dieser Geschäftsregel\ndurchgeführt werden,\nda sie bereits in mindestens einem Förderangebot verwendet wird.</alert><div ng-attr-id=\"{{ vm.errorContainerId }}\"></div><div class=\"row\"><div class=\"col-12 col-sm-12\">");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"re_kbez_admin\" type=\"text\" name=\"re_kbez_admin\" ng-model=\"vm.model.re_kbez_admin\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.re_kbez_admin ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "re_kbez_admin", "Kurzbezeichnung für Administration");
buf.push("</div></div><div class=\"row\"><div class=\"col-12 col-sm-12\"><label class=\"font-weight-bold\">Nummer:<p class=\"form-control-static mb-0\"><span class=\"titel\">{{ vm.model.re_nummer }}</span></p></label></div></div><div class=\"row\"><div class=\"col-12 col-sm-6\">");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"re_kbez\" type=\"text\" name=\"re_kbez\" ng-model=\"vm.model.re_kbez\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.re_kbez ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "re_kbez", "Meldungstext bei Validierungsmeldungen");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<textarea id=\"re_bezeichnung\" type=\"text\" name=\"re_bezeichnung\" dias-richtext ng-model=\"vm.model.re_bezeichnung\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.re_bezeichnung ? 'true' : 'false'}}\" class=\"form-control\"></textarea>");
}
}, "re_bezeichnung", "Beschreibung");
buf.push("</div><div class=\"col-12 col-sm-6\">");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<select id=\"anzeigekontext\" name=\"anzeigekontext\" ng-model=\"vm.model.anzeigekontext\" aria-required=\"true\" role=\"listbox\" ng-attr-aria-invalid=\"{{vm.errors.anzeigekontext ? 'true' : 'false'}}\" class=\"form-control\"><option value=\"\">---------</option><option ng-repeat=\"faz in vm.anzeigekontextChoices\" value=\"{{ faz.faz_id }}\">{{ faz.faz_kbez }} – {{ faz.faz_bez }}</option></select>");
}
}, "anzeigekontext", "Anzeigekontext");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<textarea id=\"re_astbez\" type=\"text\" name=\"re_astbez\" dias-richtext ng-model=\"vm.model.re_astbez\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.re_astbez ? 'true' : 'false'}}\" class=\"form-control\"></textarea>");
}
}, "re_astbez", "{{ vm.gettext('_LBL_FOERDERREGEL_EDIT_HILFETEXT_ANTRAGSTELLER_') }}");
buf.push("</div><div class=\"row\"></div><div class=\"col-12 col-sm-6\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<select id=\"re_savecallback\" name=\"re_savecallback\" ng-model=\"vm.model.re_savecallback\" aria-required=\"true\" role=\"listbox\" ng-attr-aria-invalid=\"{{vm.errors.re_savecallback ? 'true' : 'false'}}\" class=\"form-control\"><option value=\"\">Keine</option><option ng-repeat=\"(id, bez) in vm.model.savecallback_choices track by id\" value=\"{{ id }}\">{{ bez }}</option></select>");
}
}, "re_savecallback", "{{ vm.gettext('_LBL_FOERDERREGEL_DETAILS_SAVECALLBACK_') }}:");
jade_mixins["form-group"].call({
block: function(){
buf.push("<select id=\"key\" name=\"key\" ng-model=\"vm.model.key\" aria-required=\"true\" role=\"listbox\" ng-attr-aria-invalid=\"{{vm.errors.key ? 'true' : 'false'}}\" class=\"form-control\"><option value=\"\">---------</option><option ng-repeat=\"rek in vm.keyChoices\" value=\"{{ rek.pk }}\">{{ rek.rek_kbez }}</option></select>");
}
}, "key", "Schlüssel (optional)");
buf.push("</div></div></fieldset><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Abbrechen</span></button></div></div>");;return buf.join("");
}