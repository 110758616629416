
"use strict";

import ConfigService from "./config.service";
import NutzerfilterService from "./nutzerfilter.service";
import NutzertabellenconfigsService from "./nutzertabellenconfigs.service";

const tableModule = angular.module("dias.table.services", [
])

  .provider("DiasTableConfig", ConfigService)
  .provider("Nutzerfilter", NutzerfilterService)
  .provider("Nutzertabellenconfigs", NutzertabellenconfigsService)  
;

export default tableModule;
