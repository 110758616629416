"use strict";

import BaseModel from "./baseModel";

class KTitel extends BaseModel {
  constructor() {
    super("titel", { pk: "kti_id" });
  }
}

export default KTitel;
