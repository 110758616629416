import DocumentsModalCreate from "./DocumentsModalCreate/DocumentsModalCreate.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxDropdown from "../../../../global/components/PuxDropdown/PuxDropdown.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spinner from "../../../../global/components/Spinner/Spinner.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import {
  cloneDeep,
  findIndex,
  forEach,
  indexOf,
  sortBy,
} from "lodash-es";

const DOC_ANZEIGE = "2cfdb929-817a-1aa7-e050-007f010163b4";

// @vue/component
export default {
  name: "AntragstellerWizardDocuments",
  components: {
    DocumentsModalCreate,
    FormstepDetail,
    Modal,
    Permission,
    PuxButton,
    PuxCloak,
    PuxDropdown,
    PuxTranslate,
    Spinner,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    organisation: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    return {
      filterDate,
    };
  },
  data() {
    return {
      statusLoading: true,
      statusDeleting: {},
      statusModalCreate: undefined,
      currentKey: undefined,
      selectorClose: undefined,
      timer: undefined,
      confirmShow: undefined,
      confirmOptions: undefined,
      dokumentartenList: {
        pflicht: [],
        optional: [],
      },
      dokumentartenObj: {
        pflicht: [],
        optional: [],
      },
      documents: {
        pflicht: [],
        optional: [],
      },
      documentsInSync: [],
      ids: {
        pflicht: "open_modal_create_documents_pflicht",
        optional: "open_modal_create_documents_optional",
      },
      headerName: {
        pflicht: "_TXT_AST_WIZARD_PFLICHTDOKUMENTE_",
        optional: "_TXT_AST_WIZARD_SONSTIGE_DOKUMENTE_",
      },
    };
  },
  computed: {
    baseUrl() {
      return `antragstellerorganisationen/${ this.organisation.pk }/`;
    },

    prefixUrlSave() {
      return `api/${ this.baseUrl }dokumente/`;
    },

    documentsOnlyWithLength() {
      const DOCUMENTS = cloneDeep(this.documents);
      forEach(this.dokumentartenList, (item, key) => {
        if (!item.length && DOCUMENTS[key]) {
          delete DOCUMENTS[key];
        }
      });
      return DOCUMENTS || {};
    },
  },
  created() {
    this.loadDokumentarten();
  },
  methods: {
    loadDokumentarten() {
      this.getListHttp({
        url: "dokumentarten/",
        urlParams: {
          anzeige: DOC_ANZEIGE,
        },
      }).then(
        response => {
          this.groupDokumentartenByPflicht({ response });
          this.loadDocuments();
        },
      );
    },

    groupDokumentartenByPflicht({ response }) {
      const DOKUMENTARTEN_OBJ = {
        pflicht: [],
        optional: [],
      };
      const DOKUMENTARTEN_LIST = {
        pflicht: [],
        optional: [],
      };
      forEach(sortBy(response, ["position"]), item => {
        if (item.dokumentart && item.dokumentart.pflicht) {
          DOKUMENTARTEN_OBJ.pflicht.push(item.dokumentart);
          DOKUMENTARTEN_LIST.pflicht.push(item.dokumentart.pk);
        } else {
          DOKUMENTARTEN_OBJ.optional.push(item.dokumentart);
          DOKUMENTARTEN_LIST.optional.push(item.dokumentart.pk);
        }
      });
      this.dokumentartenObj = DOKUMENTARTEN_OBJ;
      this.dokumentartenList = DOKUMENTARTEN_LIST;
    },

    loadDocuments() {
      Promise.all([
        this.getListHttp({
          url: `antragstellerorganisationen/${ this.organisation.pk }/dokumente/`,
          urlParams: {
            dokart: this.dokumentartenList.pflicht,
          },
        }),
        this.getListHttp({
          url: `antragstellerorganisationen/${ this.organisation.pk }/dokumente/`,
          urlParams: {
            dokart: this.dokumentartenList.optional,
          },
        }),
      ]).then(
        responses => {
          this.documents.pflicht.push(...responses[0]);
          this.documents.optional.push(...responses[1]);
          this.initDokumenteInSync({ response: responses[0] });
          this.initDokumenteInSync({ response: responses[1] });
          if (this.documentsInSync.length) {
            this.checkSynchronization();
          }
          this.statusLoading = false;
        }
      );
    },

    initDokumenteInSync({ response }) {
      forEach(response, document => {
        if (document.is_sync) {
          this.documentsInSync.push(document.pk);
        }
      });
    },

    checkSynchronization({ time = 3000 } = {}) {
      if (this.timer) {
        return;
      }
      this.timer = true;
      setTimeout(() => {
        if (!this.documentsInSync.length) {
          this.timer = undefined;
          return;
        }
        this.getListHttp({
          url: "dokument_sync/",
          urlParams: {
            dok_id: this.documentsInSync,
          },
        }).then(
          response => {
            this.checkDocumentsInSync({ response });
            this.timer = undefined;
            this.checkSynchronization();
          }
        );
      }, time);
    },

    checkDocumentsInSync({ response }) {
      forEach(response, document => {
        if (!document.is_sync) {
          const INDEX_IN_DOK_SYNC = indexOf(this.documentsInSync, document.pk);
          if (INDEX_IN_DOK_SYNC !== -1) {
            this.documentsInSync.splice(INDEX_IN_DOK_SYNC, 1);
          }
          forEach(this.documents, (documents, key) => {
            const INDEX_IN_DOCUMENTS = findIndex(documents, ["pk", document.pk]);
            if (INDEX_IN_DOCUMENTS !== -1) {
              const DOCUMENT_CLONE = cloneDeep(documents[INDEX_IN_DOCUMENTS]);
              DOCUMENT_CLONE.is_sync = false;
              this.documents[key].splice(INDEX_IN_DOCUMENTS, 1, DOCUMENT_CLONE);
              return false;
            }
          });
        }
      });
    },

    openModalCreate(key) {
      this.currentKey = key;
      this.selectorClose = `#${ this.ids[key] }`;
      this.statusModalCreate = true;
    },

    closeModalCreate() {
      this.currentKey = undefined;
      this.selectorClose = undefined;
      this.statusModalCreate = false;
    },

    addDocument({ document, key }) {
      this.documents[key].unshift(document);
      this.documentsInSync.push(document.pk);
      this.checkSynchronization({ time: 0 });
    },

    openModalDelete(document, key) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_AST_WIZARD_MODAL_DELETE_HEADER_",
        msg: "_HTML_AST_WIZARD_MODAL_DELETE_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteDocument(document, key),
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteDocument(document, key) {
      this.statusDeleting[document.pk] = true;
      this.confirmOptions.loading = true;
      this.deleteHttp({ url: `${ this.baseUrl }dokumente/${ document.pk }/` }).then(
        () => {
          this.addNotification({ text: "_MSG_AST_WIZARD_MODAL_DELETE_SUCCESS_" });
          const INDEX_IN_DOCUMENTS = findIndex(this.documents[key], ["pk", document.pk]);
          if (INDEX_IN_DOCUMENTS !== -1) {
            this.documents[key].splice(INDEX_IN_DOCUMENTS, 1);
          }
          this.closeConfirm();
        },
        () => {
          this.addNotification({ text: "_MSG_AST_WIZARD_MODAL_DELETE_ERROR_", type: "error" });
        }
      ).then(
        () => {
          this.statusDeleting[document.pk] = false;
        }
      );
    },

    closeConfirm() {
      this.confirmShow = false;
    },
  },
};
