import {
  FilterCurrencyMixin
} from "../../../../global/mixins/FiltersMixin";
import {
  isUndefined,
  isNull,
  toNumber,
  round,
} from "lodash-es";

// @vue/component
export default {
  mixins: [
    FilterCurrencyMixin,
  ],
  props: {
    personalkostentabelleObj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      errors: {},
      errorLabels: {},
    };
  },
  computed: {
    getVollzeitstelleNumber() {
      return this.getNumber({ item: this.model.vollzeitstelle });
    },

    getStellenumfangNumber() {
      return this.getNumber({ item: this.model.stellenumfang });
    },

    getJahresbruttoNumber() {
      return this.getNumber({ item: this.model.jahresbrutto });
    },

    getStellenanteilNumber() {
      const STELLENUMFANG = this.getStellenumfangNumber;
      if (isUndefined(STELLENUMFANG)) {
        return undefined;
      }
      const VOLLZEITSTELLE = this.getVollzeitstelleNumber;
      if (isUndefined(VOLLZEITSTELLE) ||
        VOLLZEITSTELLE === 0) {
        return undefined;
      }
      const STELLENANTEIL = +STELLENUMFANG * 100 / VOLLZEITSTELLE;
      if (isNaN(STELLENANTEIL)) {
        return undefined;
      }
      return STELLENANTEIL;
    },

    getStellenanteil() {
      const STELLENANTEIL = this.getStellenanteilNumber;
      if (isUndefined(STELLENANTEIL)) {
        return "";
      }
      return `${ round(STELLENANTEIL, 2) } %`;
    },

    getPktPauschaleNumber() {
      const PKT_PAUSCHALE = this.getNumber({ item: this.personalkostentabelleObj.pkt_pauschale }) || 0;
      const AG_ANTEIL_ALTERSVORSORGE = this.getNumber({ item: this.model.ag_anteil_altersvorsorge }) || 0;
      return PKT_PAUSCHALE + AG_ANTEIL_ALTERSVORSORGE;
    },

    getPktLohnsteigerung() {
      const PKT_LOHNSTEIGERUNG = this.getNumber({ item: this.personalkostentabelleObj.pkt_lohnsteigerung });
      if (isUndefined(PKT_LOHNSTEIGERUNG)) {
        return 0;
      }
      return PKT_LOHNSTEIGERUNG;
    },

    getAGBruttogehaltVollzeitstelleNumber() {
      const JAHRESBRUTTO = this.getJahresbruttoNumber;
      if (isUndefined(JAHRESBRUTTO)) {
        return undefined;
      }
      const PKT_PAUSCHALE = this.getPktPauschaleNumber;
      return JAHRESBRUTTO * (1 + PKT_PAUSCHALE / 100);
    },

    getANBruttogehaltStelle75Number() {
      const JAHRESBRUTTO = this.getJahresbruttoNumber;
      if (isUndefined(JAHRESBRUTTO)) {
        return undefined;
      }
      const STELLENANTEIL = this.getStellenanteilNumber;
      if (isUndefined(STELLENANTEIL)) {
        return undefined;
      }

      return JAHRESBRUTTO * STELLENANTEIL / 100;
    },

    getAGBruttogehaltStelle75Number() {
      const A_N_BRUTTO_STELLE75 = this.getANBruttogehaltStelle75Number;
      if (isUndefined(A_N_BRUTTO_STELLE75)) {
        return undefined;
      }
      const PKT_PAUSCHALE = this.getPktPauschaleNumber;
      return A_N_BRUTTO_STELLE75 * (1 + PKT_PAUSCHALE / 100);
    },
  },
  methods: {
    getNumber({ item }) {
      if (isNull(item) || item === "") {
        return undefined;
      }
      const ITEM_NUMBER = toNumber(item);
      if (isNaN(ITEM_NUMBER)) {
        return undefined;
      }
      return ITEM_NUMBER;
    },
  },
};
