"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import diasLoading from "dias/core/loading";

import CoreModels from "dias/core/models";
import DiasErrors from "dias/core/errors";
import Antragslisten from "./antragslisten.service";

const modul = angular.module("dias.foerderantrag.cortexe.antragslisten.models", [
  uiRouter,
  diasLoading.name,
  CoreModels.name,
  DiasErrors.name,
])

  .provider("Antragslisten", Antragslisten)
;

export default modul;
