import NutzerschlagwortBadge from "../../Nutzerschlagwort/NutzerschlagwortBadge/NutzerschlagwortBadge.vue";
import NutzerschlagwortObjektModalAdd from "../../Nutzerschlagwort/NutzerschlagwortObjektModalAdd/NutzerschlagwortObjektModalAdd.vue";
import NutzerschlagwortObjektModalEdit from "../../Nutzerschlagwort/NutzerschlagwortObjektModalEdit/NutzerschlagwortObjektModalEdit.vue";
import NutzerschlagwortObjektModalRemove from "../../Nutzerschlagwort/NutzerschlagwortObjektModalRemove/NutzerschlagwortObjektModalRemove.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spaced from "../../../global/components/Spaced/Spaced.vue";
import SmartTable from "../../../global/components/table/table.vue";
import VertragListDetails from "../VertragListDetails/VertragListDetails.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import DynamicRootForComponentsMixin from "../../DynamicRoot/DynamicRootForComponentsMixin";
import {
  FilterLimitToMixin,
  FilterSpacedMixin,
} from "../../../global/mixins/FiltersMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import {
  render_ffk_columns
} from "../../../global/components/table/TableColsUndFiltersFromSyConfig";
import {
  EventBus
} from "../../../global/functions/event-bus";
import {
  gettext,
  replaceText
} from "../../../global/functions/utils";
import {
  forEach,
  size,
} from "lodash-es";

const TABLE_ID = "vertrag";
// const VIEW_STATUS_COLUMN_PERM = "workflow.list.column.status_detail.view";

// @vue/component
export default {
  name: "VertragList",
  components: {
    NutzerschlagwortBadge,
    NutzerschlagwortObjektModalAdd,
    NutzerschlagwortObjektModalEdit,
    NutzerschlagwortObjektModalRemove,
    PageTabTitle,
    PuxTranslate,
    Spaced,
    SmartTable,
    VertragListDetails,
  },
  mixins: [
    DynamicRootForComponentsMixin,
    FilterLimitToMixin,
    FilterSpacedMixin,
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    header: {
      type: String,
      required: false,
      info: "Text für Tabelle-Header",
      default: "_TXT_VERTRAG_TABLE_HEADER_"
    },
    filter: {
      type: Object,
      required: false,
      default: undefined,
      info: "Zusätzliches Get-Parameter für API",
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      tableNameAlt: "vertrag",
      options: {
        id: TABLE_ID,
        url: this.initUrl({ tableId: TABLE_ID, filter: this.filter }),
        baseUrl: this.initUrl({ tableId: TABLE_ID, filter: undefined }),
        rowActions: [
          {
            label: "_LBL_VERTRAG_LIST_ACTION_ROW_DETAILS_",
            route: "root.vertrag.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
          {
            label: "_LBL_VERTRAG_LIST_ANTRAG_ANZEIGEN_",
            labelCallback: this.showAntragLabel,
            route: "root.foerderantrag.details",
            routeParameters: [{ routeId: "id", rowPath: "antrag" }],
          },
          {
            label: "_LBL_VERTRAG_LIST_ANTRAGSTELLER_ANZEIGEN_",
            route: "root.astorganisationen.details",
            routeParameters: [{ routeId: "id", rowPath: "antragstellerorg" }],
          },
          {
            label: "_TXT_VERTRAG_TABLE_NUTZERSCHLAGWORTOBJEKT_UPDATE_BTN_",
            callback: this.openModalNutzerschlagwortobjektEdit,
            isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          },
        ],
        massActions: [],
        label: "_LBL_VERTRAG_LIST_VERTRAEGE_",
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
          details: "right",
        },
      },

      modal: {
        nutzerschlagwortobjektEdit: undefined,
        nutzerschlagwortobjektMassActionAdd: undefined,
        nutzerschlagwortobjektMassActionRemove: undefined,
      },
      statusLoading: true,
      user: undefined,
      render_ffk_columns: render_ffk_columns,
      currentVertrag: undefined,
      selectedRows: undefined,
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },

    getSlotNameTableDetailsHeader() {
      return `${ this.options.id }_table_details_header`;
    },
    nutzerschlagwortModalEditTitleTranslation() {
      return {
        text: "_TXT_VERTRAGLISTE_NUTZERSCHLAGWORT_MODAL_EDIT_TITLE_"
      };
    },
    nutzerschlagwortModalEditMsgTranslation() {
      return {
        html: "_HTML_VERTRAGLISTE_NUTZERSCHLAGWORT_MODAL_EDIT_INFO_"
      };
    },
    nutzerschlagwortModalMassActionAddTitleTranslation() {
      return {
        text: "_TXT_VERTRAGLISTE_NUTZERSCHLAGWORT_MODAL_MANY_ADD_TITLE_"
      };
    },
    nutzerschlagwortModalMassActionAddMsgTranslation() {
      return {
        html: "_HTML_VERTRAGLISTE_NUTZERSCHLAGWORT_MODAL_MANY_ADD_INFO_{{anz}}_",
        extra: {
          anz: size(this.selectedRows) || 0,
        }
      };
    },
    nutzerschlagwortModalMassActionRemoveTitleTranslation() {
      return {
        text: "_TXT_VERTRAGLISTE_NUTZERSCHLAGWORT_MODAL_MANY_REMOVE_TITLE_"
      };
    },
    nutzerschlagwortModalMassActionRemoveMsgTranslation() {
      return {
        html: "_HTML_VERTRAGLISTE_NUTZERSCHLAGWORT_MODAL_MANY_REMOVE_INFO_{{anz}}_",
        extra: {
          anz: size(this.selectedRows) || 0,
        }
      };
    },
  },
  created() {
    this.getUser();
    this.initRestFunctions();
  },
  methods: {
    showAntragLabel({ item }) {
      return replaceText({
        text: gettext("_LBL_VERTRAG_LIST_ANTRAG_ANZEIGEN_{{antrag_nr}}_"),
        object: {
          antrag_nr: this.filterSpaced(item.antrag_nr),
        }
      },);
    },

    renderFoerderorgAnsprechpartner({ row }) {
      let foerderorg_ansprechpartner = "";
      forEach(row.foerderorg_ansprechpartner, item => {
        foerderorg_ansprechpartner = `${ foerderorg_ansprechpartner }<li>${ item.nutzer && item.nutzer.name } (${ item.typ.apt_kbez })</li>`;
      });
      return `<ul class="list-unstyled">${ foerderorg_ansprechpartner }</ul>`;
    },

    getUser() {
      this.user = this.me;
    },
    closeModalNutzerschlagwortobjektEdit({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektEdit = false;
    },

    openModalNutzerschlagwortobjektEdit({ item }) {
      this.currentVertrag = item;
      this.modal.nutzerschlagwortobjektEdit = true;
    },

    closeModalNutzerschlagwortobjektMassActionAdd({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektMassActionAdd = false;
      EventBus.$emit(`cancelMassActions${ this.tableId }`, { status });
    },

    openModalNutzerschlagwortobjektMassActionAdd({ rows }) {
      this.selectedRows = rows;
      this.modal.nutzerschlagwortobjektMassActionAdd = true;
    },

    closeModalNutzerschlagwortobjektMassActionRemove({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektMassActionRemove = false;
      EventBus.$emit(`cancelMassActions${ this.tableId }`, { status });
    },

    openModalNutzerschlagwortobjektMassActionRemove({ rows }) {
      this.selectedRows = rows;
      this.modal.nutzerschlagwortobjektMassActionRemove = true;
    },

    initMassActions() {
      if (this.checkPermissionsSync({ perm: "nutzerschlagworte.update" })) {
        this.options.massActions.push({
          label: "_TXT_VERTRAG_TABLE_MASS_ACTIONS_NUTZERSCHLAGWORT_ADD_BTN_",
          callback: ({ rows }) => this.openModalNutzerschlagwortobjektMassActionAdd({ rows }),
          isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          modal: true,
        });
        this.options.massActions.push({
          label: "_TXT_VERTRAG_TABLE_MASS_ACTIONS_NUTZERSCHLAGWORT_REMOVE_BTN_",
          callback: ({ rows }) => this.openModalNutzerschlagwortobjektMassActionRemove({ rows }),
          isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          modal: true,
        });
      }
    },

    initRestFunctions() {
      this.initMassActions();
    },
  },
};
