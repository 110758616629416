import AufgabenvorlagenDetailsStatusgruppenTableHeader from "./AufgabenvorlagenDetailsStatusgruppenTableHeader/AufgabenvorlagenDetailsStatusgruppenTableHeader.vue";
import AufgabenvorlagenDetailsStatusgruppenTableRow from "./AufgabenvorlagenDetailsStatusgruppenTableRow/AufgabenvorlagenDetailsStatusgruppenTableRow.vue";
import AufgabenvorlagenDetailsStatusgruppenTableRowEdit from "./AufgabenvorlagenDetailsStatusgruppenTableRowEdit/AufgabenvorlagenDetailsStatusgruppenTableRowEdit.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import KWorkflowstatusgrupppenUUIDs from "../../../../const/KWorkflowstatusgrupppenUUIDs";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import { cloneDeep } from "lodash-es";

// @vue/component
export default {
  name: "AufgabenvorlagenDetailsStatusgruppen",
  components: {
    AufgabenvorlagenDetailsStatusgruppenTableHeader,
    AufgabenvorlagenDetailsStatusgruppenTableRow,
    AufgabenvorlagenDetailsStatusgruppenTableRowEdit,
    FormElement,
    FormstepDetail,
    PuxButton,
    PuxTranslate,
  },
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
    kAufgabe: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      getHttp,
      getListHttp,
      putHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      getHttp,
      getListHttp,
      putHttp,
    };
  },
  data() {
    return {
      loading: false,
      htmlId: "aufgabenvorlagen_details_statusgruppen",
      workflowtypen: [],
      formstepOptions: {
        label: "_TXT_AUFGABENVORLAGENDETAILS_STATUSGRUPPEN_",
        required: false,
        showCloseButton: true,
        editablePermission: "kaufgabe.update.statusgruppen",
        saveCallback: this.save,
        openCallback: this.open,
        closeCallback: this.close,
      },
      statusgruppen: {},
      orderedWorkflowTypenIds: [],
      workflowTypenGroups: {},
      workflowTypenMap: {},
      workflowTypenMapModel: {},
      selectedStatusgruppen: {},
      orderedWorkflowTypenIdsModel: {},
      workflowtypenChoices: {},
      workflowtypChoicesSelectModel: undefined,
      errors: {},
    };
  },
  computed: {
    KWorkflowstatusgrupppenUUIDs() {
      return KWorkflowstatusgrupppenUUIDs;
    },

    optionsForWorkflowtypenChoices() {
      const ID = "workflowtyp_choice";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "select",
        keyId: "pk",
        keyLabel: "wft_bez",
        data: this.workflowtypenChoices,
        change: this.onSelectWorkflowtyp,
      };
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      const PROMISES = [];
      PROMISES.push(this.loadWorkflowtypen());
      PROMISES.push(this.loadWorkflowStatusGruppenTypen());
      Promise.all(PROMISES).then(
        () => {
          this.loading = false;
        }
      );
    },

    loadWorkflowtypen() {
      return this.getListHttp({
        url: "workflowtypen/",
      }).then(
        response => {
          this.workflowtypen = response;
          this.updateStatusgruppen();
        },
      );
    },

    loadWorkflowStatusGruppenTypen() {
      return this.getHttp({
        url: "katalog/",
        urlParams: {
          key: "workflowstatusgruppentypen",
        },
      }).then(
        response => {
          Object.keys(this.KWorkflowstatusgrupppenUUIDs).map(
            key => this.KWorkflowstatusgrupppenUUIDs[key]
          ).forEach(
            pk => {
              const GROUP = response.find(group => group.pk === pk) || {};
              this.workflowTypenGroups[pk] = { bez: GROUP.bez };
            }
          );
        }
      );
    },

    updateStatusgruppen() {
      const WORKFLOW_TYPEN_MAP = {};
      this.workflowtypen.forEach(
        workflowType => {
          WORKFLOW_TYPEN_MAP[workflowType.pk] = workflowType;
          workflowType.wsgPosMap = {};
          workflowType.statusgruppen.forEach(
            workflowStatusGroup => {
              workflowType.wsgPosMap[workflowStatusGroup.wsg_pos] = workflowStatusGroup;
              if (workflowStatusGroup.aufgaben.find(aufgabe => aufgabe.pk === this.kAufgabe.pk)) {
                this.statusgruppen[workflowType.pk] = workflowStatusGroup.pk;
              }
            }
          );
        }
      );
      this.workflowTypenMap = WORKFLOW_TYPEN_MAP;
      this.orderedWorkflowTypenIds = Object.keys(this.statusgruppen).sort(
        (a, b) => WORKFLOW_TYPEN_MAP[a].wft_bez.localeCompare(WORKFLOW_TYPEN_MAP[b].wft_bez)
      );
    },

    open() {
      this.workflowtypChoicesSelectModel = undefined;
      this.selectedStatusgruppen = cloneDeep(this.statusgruppen);
      this.orderedWorkflowTypenIdsModel = cloneDeep(this.orderedWorkflowTypenIds);
      this.workflowTypenMapModel = cloneDeep(this.workflowTypenMap);
      this.workflowtypenChoices = this.workflowtypen.filter(
        workflowtyp => Object.keys(this.selectedStatusgruppen).indexOf(workflowtyp.pk) === -1
      );
    },

    close() {
      this.errors = {};
    },

    save() {
      const DATA = {
        statusgruppen: Object.keys(this.selectedStatusgruppen).map(
          key => this.selectedStatusgruppen[key]
        )
      };
      return this.putHttp({
        url: `${ this.baseUrl }statusgruppen_bearbeiten/`,
        data: DATA,
      }).then(
        () => {
          const OLD_STATUSGRUPPEN = cloneDeep(this.statusgruppen);
          Object.keys(OLD_STATUSGRUPPEN).forEach(
            key => delete this.statusgruppen[key]
          );
          this.statusgruppen = Object.assign(this.statusgruppen, this.selectedStatusgruppen);
          const WORKFLOWTYPEN_DATA = cloneDeep(this.workflowtypen);
          WORKFLOWTYPEN_DATA.forEach(
            workflowtyp => {
              if (!OLD_STATUSGRUPPEN[workflowtyp.pk] && !this.selectedStatusgruppen[workflowtyp.pk]) {
                return;
              }
              workflowtyp.statusgruppen.forEach(
                workflowstatusgruppe => {
                  if (workflowstatusgruppe.pk === OLD_STATUSGRUPPEN[workflowtyp.pk]
                    && workflowstatusgruppe.pk !== this.selectedStatusgruppen[workflowtyp.pk]) {
                    workflowstatusgruppe.aufgaben = workflowstatusgruppe.aufgaben.filter(aufgabe => aufgabe.pk !== this.kAufgabe.pk);
                  } else if (workflowstatusgruppe.pk !== OLD_STATUSGRUPPEN[workflowtyp.pk]
                    && workflowstatusgruppe.pk === this.selectedStatusgruppen[workflowtyp.pk]) {
                    workflowstatusgruppe.aufgaben.push({
                      pk: this.kAufgabe.pk,
                      au_kbezdetail: this.kAufgabe.au_kbezdetail,
                    });
                  }
                },
              );
            }
          );
          this.workflowtypen = WORKFLOWTYPEN_DATA;
          this.orderedWorkflowTypenIds = cloneDeep(this.orderedWorkflowTypenIdsModel);
          this.addNotification({ text: "_MSG_UPDATE_SUCCESS_" });
          return Promise.resolve();
        },
        errors => {
          this.errors = errors.data;
          return Promise.reject(errors.data);
        },
      );
    },

    onSelectWorkflowtyp(item) {
      this.workflowtypChoicesSelectModel = item.model;
    },

    addWorkflowtyp() {
      const WORKFLOWTYP_INDEX = this.workflowtypenChoices.findIndex(worjflowtyp => worjflowtyp.pk === this.workflowtypChoicesSelectModel);
      if (WORKFLOWTYP_INDEX === -1) {
        return;
      }
      this.workflowTypenMapModel[this.workflowtypChoicesSelectModel]._added = this.orderedWorkflowTypenIds.indexOf(this.workflowtypChoicesSelectModel) === -1;
      this.orderedWorkflowTypenIdsModel.push(this.workflowtypChoicesSelectModel);
      this.workflowtypenChoices.splice(WORKFLOWTYP_INDEX, 1);
      this.workflowtypChoicesSelectModel = undefined;
    },

    removeWorkflowtyp({ workflowtypId: workflowtypId }) {
      const WORKFLOWTYP_INDEX = this.orderedWorkflowTypenIdsModel.indexOf(workflowtypId);
      if (WORKFLOWTYP_INDEX === -1) {
        return;
      }
      this.workflowtypenChoices.push(this.workflowTypenMapModel[workflowtypId]);
      this.workflowtypenChoices = this.workflowtypenChoices.sort((a, b) => a.wft_bez.localeCompare(b.wft_bez));
      delete this.selectedStatusgruppen[workflowtypId];
      this.orderedWorkflowTypenIdsModel.splice(WORKFLOWTYP_INDEX, 1);
    },
  },
};
