var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["schaltflaechen"] = jade_interp = function(visible){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div id=\"always-visible\" class=\"actions right panel_always_visible\"><a ui-sref=\"root.foerderfinderfragen\"><button type=\"button\" class=\"btn btn-icon float-left\"><span class=\"glyphicon glyphicon-chevron-left\"></span><span> Zur Förderfinderübersicht</span></button></a><a ui-sref=\"root.foerderfinderfragen.detail({id: vm.antwort.frage})\"><button type=\"button\" class=\"btn btn-icon float-left\"><span class=\"glyphicon glyphicon-chevron-left\"></span><span> Zur Frage</span></button></a></div>");
};
jade_mixins["form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors[" + (field) + "]}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors[" + (field) + "]", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors[" + (field) + "]", true, true)) + ">{{ err }}</li></ul></span></label>");
};
jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors[" + (field) + "]}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors[" + (field) + "]", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors[" + (field) + "]", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<script type=\"text/ng-template\" id=\"editAntwortReadonly.html\"><fieldset><dl class=\"dl-horizontal\"><dt>Text</dt><dd>{{ model.ffa_text }}</dd><dt>Info</dt><dd>{{ model.ffa_info }}</dd><dt>Überschrift</dt><dd>{{ model.ffa_ueberschrift }}</dd><dt>Erklärung</dt><dd>{{ model.ffa_erklaerung }}</dd><dt>Antworttyp</dt><dd>{{ model.typ_obj.fft_kbez }}</dd><dt>Folgefrage</dt><dd>{{ model.folgefrage_bez }}</dd><dt>Position</dt><dd>{{ model.ffa_pos }}</dd></dl></fieldset></script><script type=\"text/ng-template\" id=\"editAntwortEdit.html\"><fieldset><div ng-attr-id=\"{{vm.errorContainerId}}\"></div><div class=\"row\"><div class=\"col-12 col-sm-6\">");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input ng-model=\"vm.model.ffa_text\" type=\"text\" name=\"ffa_text\" maxlength=\"250\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.ffa_text ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "ffa_text", "Text");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input ng-model=\"vm.model.ffa_info\" type=\"text\" name=\"ffa_info\" ng-attr-aria-invalid=\"{{vm.errors.ffa_info ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "ffa_info", "Info");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input ng-model=\"vm.model.ffa_ueberschrift\" type=\"text\" name=\"ffa_ueberschrift\" maxlength=\"250\" ng-attr-aria-invalid=\"{{vm.errors.ffa_ueberschrift ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "ffa_ueberschrift", "Überschrift");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input ng-model=\"vm.model.ffa_erklaerung\" type=\"text\" name=\"ffa_erklaerung\" ng-attr-aria-invalid=\"{{vm.errors.ffa_erklaerung ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "ffa_erklaerung", "Erklärung");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<ui-select ng-model=\"vm.model.typ\" aria-required=\"true\"><ui-select-match placeholder=\"Wählen Sie den Typ der Antwort aus.\"><span ng-bind=\"$select.selected.fft_bez\"></span></ui-select-match><ui-select-choices repeat=\"item.pk as item in (vm.antworttypen | filter: $select.search)\"><span ng-bind=\"item.fft_bez\"></span></ui-select-choices></ui-select>");
}
}, "typ", "Antworttyp");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<ui-select ng-model=\"vm.model.folgefrage\" aria-required=\"true\"><ui-select-match placeholder=\"Wählen Sie die Folgefrage aus.\" allow-clear=\"true\"><span ng-bind=\"$select.selected.ffr_text\"></span></ui-select-match><ui-select-choices repeat=\"item.pk as item in (vm.folgefragen | filter: $select.search)\"><span ng-bind=\"item.ffr_text\"></span></ui-select-choices></ui-select>");
}
}, "folgefrage", "Folgefrage");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input ng-model=\"vm.model.ffa_pos\" type=\"text\" name=\"ffa_pos\" ng-attr-aria-invalid=\"{{vm.errors.ffa_pos ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "ffa_pos", "Position");
buf.push("</div></div></fieldset><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Abbrechen</span></button></div></div></script><script type=\"text/ng-template\" id=\"angebotsListe.html\"><div class=\"table-scrollable\"><dias-simple-table config=\"model\"></dias-simple-table></div></script><div dias-cloak=\"vm.loading\"><section class=\"main row no-margin\"><section class=\"main-dias application-form\"><header class=\"page-title header-detail\"><div vueinit props=\"{ ffa_text: vm.antwort.ffa_text }\"><page-tab-title v-bind:placeholder=\"'_PAGE_TITLE_FOERDERFINDERANTWORT_{{ffa_text}}_'\" v-bind:extra=\"propsAngular\"></page-tab-title></div></header><h1>Förderfinderantwort:  {{ vm.antwort.ffa_text }}");
jade_mixins["schaltflaechen"]();
buf.push("</h1><div class=\"main-details\"><form formsteps><div class=\"form-step active\"><header formstep><h3><span class=\"glyphicon glyphicon-file\"></span><span> {{ vm.antwort.ffa_text }}</span></h3></header><formstep-detail ng-if=\"!vm.loading\" formstep-title=\"Antwort\" ng-model=\"vm.antwort\" formstep-editable=\"true\" formstep-edit-template-url=\"editAntwortEdit.html\" formstep-edit-controller=\"vm.editAntwortController\" formstep-readonly-template-url=\"editAntwortReadonly.html\"></formstep-detail></div><div ng-if=\"vm.showAngebote()\" class=\"form-step active\"><header formstep><h3><span class=\"glyphicon glyphicon-file\"></span><span> Zugeordnete Angebote</span></h3></header><formstep-detail formstep-title=\"\" ng-model=\"vm.angeboteTable\" formstep-editable=\"false\" formstep-readonly-template-url=\"angebotsListe.html\"></formstep-detail></div></form></div></section></section></div>");;return buf.join("");
}