"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import diasAuthService from "dias/auth/services";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";
import diasParameter from "dias/foerderregeln/parameter";

import models from "./models";
import services from "./services";
import plan from "./plan";
import listComponent from "./list.component";


const AnonModule = angular.module("root.anonymisierung", [
  uiRouter,
  models.name,
  services.name,
  plan.name,
  diasAuthService.name,
  diasParameter.name,
  diasTable.name,
  diasLoading.name,
  diasNotification.name
]);

AnonModule.config($stateProvider => {
  $stateProvider

    // Sitzungsantrag-Liste
    .state("root.anonymisierung", {
      url: `/anonymisierungsauftraege/?
        suche&
        ano_objektname&
        ano_status&
        ano_stufe&
        content_type&
        quelle`,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-anonymisierung-list></vue-anonymisierung-list></div>"
        }
      },
      data: {
        permissions: ["anonymisierung.view"]
      },
    })
    .state("root.anonymisierung1", {
      url: `/anonymisierungsauftraege1/?
        suche&
        ano_objektname&
        ano_status&
        ano_stufe&
        content_type&
        quelle`,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<anonymisierungsauftraege-list></anonymisierungsauftraege-list>"
        }
      },
      data: {
        permissions: ["anonymisierung.view"]
      },
    })
  ;
})

  .component("anonymisierungsauftraegeList", listComponent)
;

export default AnonModule;
