"use strict";

class ChangeGueltigBisController {
  /*@ngInject*/
  constructor($q, $timeout, $scope) {
    this.name = "ChangeGueltigBisController";
    this.model = $scope.extras.regelsatz;
    this.angebot = $scope.extras.angebot;
    this.ok = () => {
      const defer = $q.defer();
      this.angebot.regelsaetze.one(this.model.pk).updateGueltigkeitszeitraum(this.model.ars_gueltig_bis).then(
        response => defer.resolve(response),
        response => {
          this.errors = response.data;
          defer.reject();
        }
      );
      return defer.promise;
    };
  }
}

export default ChangeGueltigBisController;
