"use strict";

class BetragsrechnerRegistryProvider {
  constructor() {
    this.name = "BetragsrechnerRegistryProvider";
    this.registeredRechner = {};
  }

  register(key, betragsrechner) {
    if (this.registeredRechner[key] !== undefined) {
      console.warn("Betragsrechner " + key + " wurde mehrmals registriert:", [this.registeredRechner[key], betragsrechner]);
      return;
    }
    this.registeredRechner[key] = betragsrechner;
  }

  unregister(key) {
    this.registeredRechner[key] = undefined;
  }

  /*@ngInject*/
  $get() {
    return {
      getBetragsrechner: key => {
        return this.registeredRechner[key];
      },
      getBetragsrechnerList: () => {
        const list = [];
        for (const key in this.registeredRechner) {
          list.push({ key: key, betragsrechner: this.registeredRechner[key] });
        }
        return list;
      }
    };
  }
}

export default BetragsrechnerRegistryProvider;
