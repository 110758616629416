"use strict";

import BaseModel from "dias/core/models/baseModel";

class Anzeigekontextstatus extends BaseModel {
  constructor() {
    super("anzeigekontextstatus");
  }
}

export default Anzeigekontextstatus;
