import DokumenteHistory from "../../Dokumente/DokumenteHistory/DokumenteHistory.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  filter,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragstellerListDetails",
  components: {
    DokumenteHistory,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    antragsteller: {
      type: Object,
      default: () => ({}),
    },
    orgtypen: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      docAnzeige: "2cfdb929-817a-1aa7-e050-007f010163b4",
    };
  },
  computed: {
    getHrefEmail() {
      return `mailto:${ this.antragsteller.master[0].n_email }`;
    },

    getFilterOrgtypen() {
      return filter(this.orgtypen, orgType => {
        let result = undefined;
        if (this.antragsteller.foerderorganisation_obj) {
          forEach(this.antragsteller.foerderorganisation_obj.foerderorghierarchie, item => {
            if (item.o_otid === orgType.pk) {
              result = item.o_kbez;
              return false;
            }
          });
        }
        return result;
      });
    },

    renderFoerderorg() {
      return typ => {
        let result = undefined;
        if (this.antragsteller.foerderorganisation_obj) {
          forEach(this.antragsteller.foerderorganisation_obj.foerderorghierarchie, item => {
            if (item.o_otid === typ) {
              result = item.o_kbez;
              return false;
            }
          });
        }
        return result;
      };
    },

    getDocUrl() {
      return `antragstellerorganisationen/${ this.antragsteller.pk }/`;
    },
  },
};
