import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

// @vue/component
export default {
  name: "DeckblattModalDelete",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    deckblatt: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      status: {
        loading: undefined,
      },
    };
  },
  methods: {
    deleteDeckblatt() {
      this.status.loading = true;
      this.deleteHttp({ url: `deckblaetter/${ this.deckblatt.pk }/` }).then(
        response => {
          this.addNotification({ text: "_MSG_DECKBLAETTER_TABLE_MODAL_DELETE_SUCCESS_" });
          this.close({ response });
        },
        () => this.addNotification({ text: "_MSG_DECKBLAETTER_TABLE_MODAL_DELETE_ERROR_", type: "error" })
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
