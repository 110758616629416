import BetragsrechnerMixin from "../Mixins/BetragsrechnerMixin";
import HttpMixin from "../../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "Personalkosten",
  mixins: [
    BetragsrechnerMixin,
    HttpMixin,
  ],
  data() {
    return {
      personalstellen: [],
      labelVollzeitstelle: "_LBL_PERSONALKOSTEN_VOLLZEITSTELLE_",
      labelStellenbezeichnung: "_LBL_PERSONALKOSTEN_STELLENBEZEICHNUNG_",
      labelName: "_LBL_PERSONALKOSTEN_NAME_",
      labelPersonalstelle: "_LBL_PERSONALKOSTEN_PERSONALSTELLE_",
      labelBeschaeftigtVon: "_LBL_PERSONALKOSTEN_BESCHAEFTIGT_VON_",
      labelBeschaeftigtBis: "_LBL_PERSONALKOSTEN_BESCHAEFTIGT_BIS_",
      labelPersonalfunktion: "_LBL_PERSONALKOSTEN_PERSONALFUNKTION_",
      labelStellendauer: "_LBL_PERSONALKOSTEN_STELLENDAUER_",
      labelStellenumfang: "_LBL_PERSONALKOSTEN_STELLENUMFANG_",
      labelStellenumfangProjekt: "_LBL_PERSONALKOSTEN_STELLENUMFANG_PROJEKT_",
      labelMonatsgehalt: "_LBL_PERSONALKOSTEN_MONATSGEHALT_",
      labelDokumentGeschwaerzt: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_CONFIRMATION_",
    };
  },
  computed: {
    optionsVollzeitstelle() {
      return {
        id: "vollzeitstelle",
        htmlId: this.htmlIdVollzeitstelle,
        label: this.labelVollzeitstelle,
        view: this.view,
        type: "float",
        addonBack: "_TXT_HOURS_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdVollzeitstelle() {
      return `${ this.htmlId }_vollzeitstelle`;
    },

    optionsStellenbezeichnung() {
      return {
        id: "stellenbezeichnung",
        htmlId: this.htmlIdStellenbezeichnung,
        label: this.labelStellenbezeichnung,
        view: this.view,
        type: "text",
        maxlength: 300,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdStellenbezeichnung() {
      return `${ this.htmlId }_stellenbezeichnung`;
    },

    optionsName() {
      return {
        id: "name",
        htmlId: this.htmlIdName,
        label: this.labelName,
        view: this.view,
        type: "text",
        maxlength: 300,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdName() {
      return `${ this.htmlId }_name`;
    },

    optionsPersonalstelle() {
      return {
        id: "personalstelle",
        htmlId: this.htmlIdPersonalstelle,
        label: this.labelPersonalstelle,
        view: this.view,
        type: "select",
        keyLabel: "ps_kbez",
        keyId: "pk",
        search: true,
        searchList: ["ps_kbez"],
        required: true,
        data: this.personalstellen,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdPersonalstelle() {
      return `${ this.htmlId }_personalstelle`;
    },

    optionsBeschaeftigtVon() {
      return {
        id: "beschaeftigt_von",
        htmlId: this.htmlIdBeschaeftigtVon,
        label: this.labelBeschaeftigtVon,
        view: this.view,
        type: "date",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdBeschaeftigtVon() {
      return `${ this.htmlId }_beschaeftigt_von`;
    },

    optionsBeschaeftigtBis() {
      return {
        id: "beschaeftigt_bis",
        htmlId: this.htmlIdBeschaeftigtBis,
        label: this.labelBeschaeftigtBis,
        view: this.view,
        type: "date",
        required: false,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdBeschaeftigtBis() {
      return `${ this.htmlId }_beschaeftigt_bis`;
    },

    optionsPersonalfunktion() {
      return {
        id: "personalfunktion",
        htmlId: this.htmlIdPersonalfunktion,
        label: this.labelPersonalfunktion,
        view: this.view,
        type: "text",
        required: true,
        maxlength: 300,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdPersonalfunktion() {
      return `${ this.htmlId }_personalfunktion`;
    },

    optionsStellendauer() {
      return {
        id: "stellendauer",
        htmlId: this.htmlIdStellendauer,
        label: this.labelStellendauer,
        placeholder: "_TXT_PERSONALKOSTEN_STELLENDAUER_PLACEHOLDER_",
        view: this.view,
        type: "float",
        addonBack: "_TXT_MONTHS_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdStellendauer() {
      return `${ this.htmlId }_stellendauer`;
    },

    optionsStellenumfang() {
      return {
        id: "stellenumfang",
        htmlId: this.htmlIdStellenumfang,
        label: this.labelStellenumfang,
        view: this.view,
        type: "float",
        addonBack: "_TXT_HOURS_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdStellenumfang() {
      return `${ this.htmlId }_stellenumfang`;
    },

    optionsStellenumfangProjekt() {
      return {
        id: "stellenumfang_projekt",
        htmlId: this.htmlIdStellenumfangProjekt,
        label: this.labelStellenumfangProjekt,
        view: this.view,
        type: "float",
        addonBack: "_TXT_HOURS_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdStellenumfangProjekt() {
      return `${ this.htmlId }_stellenumfang_projekt`;
    },

    optionsMonatsgehalt() {
      return {
        id: "monatsgehalt",
        htmlId: this.htmlIdMonatsgehalt,
        label: this.labelMonatsgehalt,
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdMonatsgehalt() {
      return `${ this.htmlId }_monatsgehalt`;
    },

    optionsDokumentGeschwaerzt() {
      return {
        id: "dokument_geschwaerzt",
        htmlId: this.htmlIdDokumentGeschwaerzt,
        label: this.labelDokumentGeschwaerzt,
        view: this.view,
        type: "one_checkbox",
        required: false,
        disabled: this.statusPruefer,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdDokumentGeschwaerzt() {
      return `${ this.htmlId }_dokument_geschwaerzt`;
    },

    errorLabels() {
      return {
        vollzeitstelle: {
          label: this.labelVollzeitstelle,
          link: true,
          id: this.htmlIdVollzeitstelle,
        },
        stellenbezeichnung: {
          label: this.labelStellenbezeichnung,
          link: true,
          id: this.htmlIdStellenbezeichnung,
        },
        name: {
          label: this.labelName,
          link: true,
          id: this.htmlIdName,
        },
        personalstelle: {
          label: this.labelPersonalstelle,
          link: true,
          id: this.htmlIdPersonalstelle,
        },
        beschaeftigt_von: {
          label: this.labelBeschaeftigtVon,
          link: true,
          id: this.htmlIdBeschaeftigtVon,
        },
        beschaeftigt_bis: {
          label: this.labelBeschaeftigtBis,
          link: true,
          id: this.htmlIdBeschaeftigtBis,
        },
        personalfunktion: {
          label: this.labelPersonalfunktion,
          link: true,
          id: this.htmlIdPersonalfunktion,
        },
        stellendauer: {
          label: this.labelStellendauer,
          link: true,
          id: this.htmlIdStellendauer,
        },
        stellenumfang: {
          label: this.labelStellenumfang,
          link: true,
          id: this.htmlIdStellenumfang,
        },
        stellenumfang_projekt: {
          label: this.labelStellenumfangProjekt,
          link: true,
          id: this.htmlIdStellenumfangProjekt,
        },
        monatsgehalt: {
          label: this.labelMonatsgehalt,
          link: true,
          id: this.htmlIdMonatsgehalt,
        },
        dokument_geschwaerzt: {
          label: this.labelDokumentGeschwaerzt,
          link: true,
          id: this.htmlIdDokumentGeschwaerzt,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
    this.initValues();
  },
  methods: {
    initValues() {
      if (this.kontext === "beleg") {
        this.getListHttp({
          url: "personalstellen/",
          apiSaveId: "personalstellen",
        }).then(
          response => {
            this.personalstellen = response;
          }
        );
      }
    },
  },
};
