var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["schaltflaechen"] = jade_interp = function(visible){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div id=\"always-visible\" class=\"actions right panel_always_visible\"><a ui-sref=\"root.foerderfinderfragen\"><button type=\"button\" class=\"btn btn-icon float-left\"><span class=\"glyphicon glyphicon-chevron-left\"></span><span> Zur Förderfinderübersicht</span></button></a></div>");
};
jade_mixins["form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors[" + (field) + "]}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors[" + (field) + "]", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors[" + (field) + "]", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<script type=\"text/ng-template\" id=\"editFrageReadonly.html\"><fieldset><dl class=\"dl-horizontal\"><dt>Interne Bezeichnung</dt><dd>{{ model.ffr_name }}</dd><dt>Text</dt><dd>{{ model.ffr_text }}</dd><dt>Überschrift</dt><dd>{{ model.ffr_ueberschrift }}</dd><dt>Hauptfrage</dt><dd>{{ model.ffr_hauptfrage === false?\"Nein\":\"Ja\" }}</dd><dt>Relevant für Dias-Nutzer</dt><dd>{{ model.ffr_relevant_in_dias === false?\"Nein\":\"Ja\" }}</dd></dl></fieldset></script><script type=\"text/ng-template\" id=\"editFrageEdit.html\"><fieldset><div ng-attr-id=\"{{vm.errorContainerId}}\"></div><div class=\"row\"><div class=\"col-12 col-sm-6\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input ng-model=\"vm.model.ffr_name\" type=\"text\" name=\"ffr_name\" maxlength=\"250\" ng-attr-aria-invalid=\"{{vm.errors.ffr_name ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "ffr_name", "Interne Bezeichnung");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input ng-model=\"vm.model.ffr_text\" type=\"text\" name=\"ffr_text\" maxlength=\"250\" ng-attr-aria-invalid=\"{{vm.errors.ffr_text ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "ffr_text", "Text");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input ng-model=\"vm.model.ffr_ueberschrift\" type=\"text\" name=\"ffr_text\" maxlength=\"250\" ng-attr-aria-invalid=\"{{vm.errors.ffr_ueberschrift ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "ffr_ueberschrift", "Überschrift");
buf.push("<div class=\"checkbox-btn\"><input id=\"ffr_hauptfrage\" ng-model=\"vm.model.ffr_hauptfrage\" type=\"checkbox\" name=\"ffr_checkbox\" ng-attr-aria-checked=\"{{ vm.model.ffr_hauptfrage ? 'true' : 'false' }}\" class=\"form-control\"><label for=\"ffr_hauptfrage\" class=\"font-weight-bold\"><span class=\"titel\">Hauptfrage<span class=\"alert-danger\"></span></span></label></div><div class=\"checkbox-btn\"><input id=\"ffr_relevant_in_dias\" ng-model=\"vm.model.ffr_relevant_in_dias\" type=\"checkbox\" name=\"ffr_checkbox\" ng-attr-aria-checked=\"{{ vm.model.ffr_relevant_in_dias ? 'true' : 'false' }}\" class=\"form-control\"><label for=\"ffr_relevant_in_dias\" class=\"font-weight-bold\"><span class=\"titel\">Relevant für angemeldete DIAS-Nutzer<span class=\"alert-danger\"></span></span></label></div></div></div></fieldset><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Abbrechen</span></button></div></div></script><script type=\"text/ng-template\" id=\"antwortListe.html\"><div class=\"table-scrollable\"><dias-simple-table config=\"model\"></dias-simple-table></div></script><div dias-cloak=\"vm.loading\"><section class=\"main row no-margin\"><section class=\"main-dias application-form\"><header class=\"page-title header-detail\"><div vueinit props=\"{ bezeichnung: vm.frage.bezeichnung }\"><page-tab-title v-bind:placeholder=\"'_PAGE_TITLE_FOERDERFINDER_FRAGE_{{bezeichnung}}_'\" v-bind:extra=\"propsAngular\"></page-tab-title></div></header><h1>Förderfinderfrage:  {{ vm.frage.bezeichnung }}");
jade_mixins["schaltflaechen"]();
buf.push("</h1><div class=\"main-details\"><form formsteps><div class=\"form-step active\"><header formstep><h3><span class=\"glyphicon glyphicon-file\"></span><span> {{ vm.frage.bezeichnung }}</span></h3></header><formstep-detail ng-if=\"!vm.loading\" formstep-title=\"Frage\" ng-model=\"vm.frage\" formstep-editable=\"true\" formstep-edit-template-url=\"editFrageEdit.html\" formstep-edit-controller=\"vm.editFrageController\" formstep-readonly-template-url=\"editFrageReadonly.html\"></formstep-detail></div><div class=\"form-step active\"><header formstep><h3><span class=\"glyphicon glyphicon-file\"></span><span> Antworten</span></h3></header><formstep-detail formstep-title=\"\" ng-model=\"vm.antwortenTable\" formstep-editable=\"false\" formstep-readonly-template-url=\"antwortListe.html\"></formstep-detail></div></form></div></section></section></div>");;return buf.join("");
}