// Modul für die Ausgabe der Allgemeinkosten-Pauschale (ein readonly Kostenmodul)
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const allgemeinkosten_pauschale = angular.module("dias.allgemeinkosten_pauschale", [
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
]);

allgemeinkosten_pauschale.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("allgemeinkosten_pauschale", "modul-allgemeinkosten-pauschale");
})

  .directive("modulAllgemeinkostenPauschale", builder.directive(template, controller));

export default allgemeinkosten_pauschale;
