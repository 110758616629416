"use strict";
import store from "../../../../vue/client/vue/store";

const PASSWORT_ABGELAUFEN_STATUS_ID = "2d4d03f5-6c2e-4ebe-b612-bdc544582739";

class AuthService {
  /*@ngInject*/
  $get(
    $q,
    $rootScope,
    $state,
    Profil,
    Raven,
    DiasNotification,
    SyConfigs,
    KontextTypen,
    $location
  ) {
    let user;
    let userDeferred = $q.defer();

    const _inKontext = function(typ) {
      if (angular.isUndefined(user)) {
        return false;
      }
      for (let i = 0; i < user.kontexte.length; i++) {
        if (user.kontexte[i].kontext_typ === typ) {
          return true;
        }
      }
      return false;
    };

    const syncInKontextAntragstellung = function() {
      return _inKontext(KontextTypen.ANTRAGSTELLUNG);
    };

    const syncInKontextFoerderung = function() {
      return _inKontext(KontextTypen.FOERDERORG);
    };

    const syncInKontextSystem = function() {
      return _inKontext(KontextTypen.SYSTEM);
    };

    const _hasPerm = function(perm, src) {
      if (angular.isUndefined(src)) {
        return false;
      }
      return src.indexOf(perm) !== -1;
    };

    const hasPerm = function(perm, src) {
      const deferred = $q.defer();
      if (angular.isUndefined(src)) {
        userDeferred.promise.then(user => deferred.resolve(_hasPerm(perm, user.permissions)));
      } else {
        deferred.resolve(_hasPerm(perm, src));
      }
      return deferred.promise;
    };

    const syncHasPerm = function(perm, src) {
      if (angular.isUndefined(user)) {
        return false;
      }
      if (angular.isUndefined(src)) {
        src = user.permissions;
      }
      return _hasPerm(perm, src);
    };

    const _hasPerms = function(perms, src) {
      const perms_map = {};
      if (angular.isUndefined(src)) {
        for (let i = 0; i < perms.length; i++) {
          perms_map[perms[i]] = false;
        }
      } else {
        for (let i = 0; i < perms.length; i++) {
          perms_map[perms[i]] = src.indexOf(perms[i]) !== -1;
        }
      }
      return perms_map;
    };

    const hasPerms = function(perms, src) {
      const deferred = $q.defer();
      if (angular.isUndefined(src)) {
        userDeferred.promise.then(user => {
          deferred.resolve(_hasPerms(perms, user.permissions));
        });
      } else {
        deferred.resolve(_hasPerms(perms, src));
      }
      return deferred.promise;
    };

    const syncHasPerms = function(perms, src) {
      if (angular.isUndefined(user)) {
        const result = {};
        for (let i = 0; i < perms.length; i++) {
          result[perms[i]] = false;
        }
        return result;
      }
      if (angular.isUndefined(src)) {
        src = user.permissions;
      }
      return _hasPerms(perms, src);
    };

    const authorizeState = function() {
      // Für die Seiten, die für Mandanten konfigurierbar sind
      const instancePages = $rootScope.toState.data.instance_pages;
      const syConfigsInstancePages = SyConfigs.wert("instance_pages");
      if (instancePages && (!syConfigsInstancePages || !syConfigsInstancePages[instancePages])) {
        $rootScope.stateChangeEvent.preventDefault();
        DiasNotification.page.warning("Diese Seite konnte nicht gefunden werden.");
        $state.go("root.home");
      }

      if (user && user.pk && user.offene_datenschutzfragen.length &&
          !($rootScope.stateChangeEvent.targetScope &&
            $rootScope.stateChangeEvent.targetScope.toState &&
            ($rootScope.stateChangeEvent.targetScope.toState.name === "root.datenschutzeinwilligung" ||
              $rootScope.stateChangeEvent.targetScope.toState.name === "root.datenschutz"))) {
        $rootScope.stateChangeEvent.preventDefault();
        DiasNotification.page.warning("Bevor Sie die Anwendung weiter verwenden können, müssen Sie die folgenden Fragen beantworten.");
        $state.go("root.datenschutzeinwilligung");
      }

      let authorized = true;
      const permissions = $rootScope.toState.data.permissions;

      let passwortAbgelaufenStatus;
      if (user && user.status && user.status.ns_id === PASSWORT_ABGELAUFEN_STATUS_ID) {
        passwortAbgelaufenStatus = true;
      }

      if (angular.isArray(permissions)) {
        if (!permissions.length) {
          passwortAbgelaufenStatus = false;
        }
        const perms_map = syncHasPerms(permissions);
        for (const perm in perms_map) {
          if (!perms_map[perm]) {
            authorized = perms_map[perm];
            break;
          }
        }
      } else if (angular.isString(permissions)) {
        authorized = syncHasPerm(permissions);
      } else {
        passwortAbgelaufenStatus = false;
      }

      if (!authorized || passwortAbgelaufenStatus) {
        $rootScope.stateChangeEvent.preventDefault();
        DiasNotification.page.warning("Sie sind nicht berechtigt diese Seite aufzurufen.");
        let params = {};
        if (user && !user.pk) {
          // Pfad nur merken, wenn noch nicht angemeldet
          params = { next: $location.url() };
        }
        $state.go("root.home", params);
      }
    };

    const _hasOnePerm = function(perms, src) {
      for (let i = 0; i < perms.length; i++) {
        if (src.indexOf(perms[i]) !== -1) {
          return true;
        }
      }
    };

    const hasOnePerm = function(perms, src) {
      const deferred = $q.defer();
      if (angular.isUndefined(src)) {
        userDeferred.promise.then(function(user) {
          deferred.resolve(_hasOnePerm(perms, user.permissions));
        });
      } else {
        deferred.resolve(_hasOnePerm(perms, src));
      }
      return deferred.promise;
    };

    const refreshUser = function() {
      const deferred = $q.defer();
      userDeferred.promise.then(() => {
        // GET-Aufruf auf ME-Endpunkt, damit alle zusätzlichen Daten wie
        // Permissions geladen werden.
        Profil.fetch().then(
          freshUser => {
            user = freshUser;
            deferred.resolve(freshUser);
            $rootScope.$broadcast("AuthService.userRefreshed", freshUser);
            sendEventInVueJS(freshUser);
            // update internal userDeferred
            userDeferred = deferred;
          },
          reason => deferred.reject(reason)
        );
      });
      return deferred.promise;
    };

    return {
      getUser: () => userDeferred.promise,
      setUser: usr => {
        user = usr;
        store.commit("profile/MUT_INIT_ME", user);
        Raven.setUserContext({
          id: usr.n_id,
          username: usr.n_loginname,
          email: usr.n_email
        });
        userDeferred.resolve(usr);
      },
      getUserSync: () => user,
      isAuthenticated: () => !!(user && user.pk),
      refreshUser: refreshUser,
      hasPerm: hasPerm,
      hasPerms: hasPerms,
      hasOnePerm: hasOnePerm,
      syncHasPerm: syncHasPerm,
      syncHasPerms: syncHasPerms,
      authorizeState: authorizeState,
      syncInKontextSystem: syncInKontextSystem,
      syncInKontextFoerderung: syncInKontextFoerderung,
      syncInKontextAntragstellung: syncInKontextAntragstellung,
      syncInKontext: _inKontext,
    };
  }
}

export default AuthService;

function sendEventInVueJS(user) {
  const EVENT = document.createEvent("Event");
  EVENT.initEvent("updateProfile", true, true);
  EVENT.user = user;
  document.dispatchEvent(EVENT);
}
