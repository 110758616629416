import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";

// @vue/component
export default {
  name: "FormularListeBoxItem",
  components: {
    AngularLink,
  },
  props: {
    formular: {
      type: Object,
      required: true,
    },
  },
  computed: {
    srefParameterForFormularLink() {
      return `root.${ this.formular.cortex }.detail`;
    },
  },
};
