import ModuleMixin from "../ModuleMixin";

// @vue/component
export default {
  name: "AutomatischerAntragstitel",
  mixins: [
    ModuleMixin,
  ],
  computed: {
    labelHeader() {
      return "_TXT_GR_AUTOMATISCHER_ANTRAGSTITEL_HEADER_";
    },

    isModuleEditable() {
      return false;
    },
  },
};
