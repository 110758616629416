"use strict";

import angular from "angular";

import models from "./models";
import services from "./services";

import sitzungsmodule from "./sitzungsmodule.component";
import sitzungsmodulePreview from "./sitzungsmodule.preview.component";

const modelsModule = angular.module("sitzungen.sitzungsmodule", [
  models.name,
  services.name
])

  .component("sitzungsmodule", sitzungsmodule)
  .component("sitzungsmodulePreview", sitzungsmodulePreview)

/**
 * Lauscht auf Events der Sitzungsmodulemodule und verteilt sie
 * zurück an alle Sitzungsmodule.
 */
  .run(/*@ngInject*/$rootScope => {
    $rootScope.$on("sitzungsmodule.distributor.notify", (event, extras) => {
      $rootScope.$broadcast("sitzungsmodule.notify", extras);
      event.stopPropagation();
    });
  })
;

export default modelsModule;
