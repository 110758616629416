import {
  computed,
  getCurrentInstance,
  ref,
  provide,
} from "vue";

import AntragDetailsAngebot from "../../Antraege/AntragDetails/AntragDetailsAngebot/AntragDetailsAngebot.vue";
import AntragWarningBoxAntragstellerGesperrt from "../../Antraege/AntragDetails/AntragWarningBoxAntragstellerGesperrt/AntragWarningBoxAntragstellerGesperrt.vue";
import AntragWarningBoxAntragstellerorgKopfdublette from "../../Antraege/AntragDetails/AntragWarningBoxAntragstellerorgKopfdublette/AntragWarningBoxAntragstellerorgKopfdublette.vue";
import AntragWarningBoxNegativGeprueft from "../../Antraege/AntragDetails/AntragWarningBoxNegativGeprueft/AntragWarningBoxNegativGeprueft.vue";
import Favoriten from "../../Favoriten/Favoriten.vue";
import FormattedAntragScore from "../../Antraege/FormattedAntragScore/FormattedAntragScore.vue";
import GeschaeftsregelModul from "../../Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";
import GutachtenErgebnisse from "../../Gutachten/GutachtenErgebnisse/GutachtenErgebnisse.vue";
import KFPerioden from "../../KF/KFPerioden/KFPerioden.vue";
import NotizenBox from "../../Notizen/NotizenBox/NotizenBox.vue";
import ObjectGutachtenReadonly from "../../Gutachten/ObjectGutachtenReadonly/ObjectGutachtenReadonly.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxGet from "../../../global/components/PuxGet/PuxGet.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxRubrics from "../../../global/components/PuxRubrics/PuxRubrics.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SitzungAntraegeAnlagen from "./SitzungAntraegeAnlagen/SitzungAntraegeAnlagen.vue";
import SitzungAntraegeEntscheidung from "./SitzungAntraegeEntscheidung/SitzungAntraegeEntscheidung.vue";
import SitzungAntraegeEntscheidungsstatusBox from "./SitzungAntraegeEntscheidungsstatusBox/SitzungAntraegeEntscheidungsstatusBox.vue";
import SitzungAntraegeGoToBox from "./SitzungAntraegeGoToBox/SitzungAntraegeGoToBox.vue";
import SitzungAntraegeProtokoll from "./SitzungAntraegeProtokoll/SitzungAntraegeProtokoll.vue";
import Spaced from "../../../global/components/Spaced/Spaced.vue";
import WFStatusGruppen from "../../Workflows/StatusGruppen/StatusGruppen.vue";

import AntragKofiAPI from "../../Antraege/AntragDetails/compositionAPI/AntragKofiAPI";
import AntragNotGroupedAPI from "../../Antraege/AntragDetails/compositionAPI/AntragNotGroupedAPI";
import GutachtenAPI from "../../Antraege/AntragDetails/compositionAPI/GutachtenAPI";
import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../global/compositionAPI/NotificationAPI";
import PermissionAPI from "../../../global/compositionAPI/PermissionAPI";
import PuxRubricsAPI, {
  isRubricVisibleDefault,
} from "../../../global/components/PuxRubrics/compositionAPI/PuxRubricsAPI";
import SitzungAntraegeDocumentsAPI from "./compositionAPI/SitzungAntraegeDocumentsAPI";
import SitzungAntraegeNotGroupedAPI from "./compositionAPI/SitzungAntraegeNotGroupedAPI";

import FoerderangebotAnzeigekontextUUIDs from "../../../const/FoerderangebotAnzeigekontextUUIDs";
import RegelMobilappkontextUUIDs from "../../../const/RegelMobilappkontextUUIDs";
import {
  cloneDeep,
  filter,
  indexOf,
  reject,
  some,
} from "lodash-es";


// @vue/component
export default {
  name: "SitzungAntraegeDetails",
  components: {
    AntragDetailsAngebot,
    AntragWarningBoxAntragstellerGesperrt,
    AntragWarningBoxAntragstellerorgKopfdublette,
    AntragWarningBoxNegativGeprueft,
    Favoriten,
    FormattedAntragScore,
    GeschaeftsregelModul,
    GutachtenErgebnisse,
    KFPerioden,
    NotizenBox,
    ObjectGutachtenReadonly,
    Permission,
    PuxGet,
    PuxPageDetails,
    PuxRubrics,
    PuxTranslate,
    SitzungAntraegeAnlagen,
    SitzungAntraegeEntscheidung,
    SitzungAntraegeEntscheidungsstatusBox,
    SitzungAntraegeGoToBox,
    SitzungAntraegeProtokoll,
    Spaced,
    WFStatusGruppen,
  },
  setup() {
    const APP = getCurrentInstance();
    const $stateParams = APP.appContext.config.globalProperties.$stateParams;
    const $goTo = APP.appContext.config.globalProperties.$goTo;

    const sitzungListeAntrag = ref({});
    const regelmobilappkontext = ref([]);
    const antrag = ref({});
    const loading = ref(true);

    const allSitzungAntragUrl = computed(() => {
      return `all_sitzungsantraege/${ $stateParams.id }/`;
    });
    const sitzungAntragUrl = computed(() => {
      return `sitzungsantraege/${ antrag.value.pk }/`;
    });
    const sitzungAntragModuleUrl = computed(() => {
      return `${ sitzungAntragUrl.value }module/`;
    });

    const {
      getHttp,
      getListHttp,
    } = HttpAPI();
    const {
      addNotification,
    } = NotificationAPI();

    const {
      antragConfig,
      antragLabel,
      antragLabelWithoutHtml,
      anzeigekontexteAntrag,
      anzeigekontextPks,
      hasPerioden,
      isAntragEntschieden,
      regelsatzKbez,
      statusWFStatusGruppenInHeader,
    } = AntragNotGroupedAPI({
      antrag,
    });

    const {
      dokumentePermissions,
      docPermissions,
    } = SitzungAntraegeDocumentsAPI();

    const {
      notizOptions,
      updateSitzungListeAntrag,
    } = SitzungAntraegeNotGroupedAPI({
      sitzungListeAntrag: sitzungListeAntrag,
    });

    const {
      finanzierungModule,
      finanzierungModuleNotKf,
      hasWFPermissionKoFiUpdate,
      isRubricKostenFinanzierung,
      kofiUebersichtAnzeigen,
      kostenModule,
      kostenModuleNotKf,
      loadedModuleForRubric_step_finanz,
      zuschussinfo,
    } = AntragKofiAPI({
      isAntragEntschieden,
      hasPerioden,
      antrag,
      antragUrl: sitzungAntragUrl,
    });

    const {
      gutachten,
      loadGutachten,
    } = GutachtenAPI({
      obj: antrag,
      baseUrl: sitzungAntragUrl,
    });

    const loadedModuleForRubricCallbacks = {
      step_finanz: loadedModuleForRubric_step_finanz,
    };
    const getRubricAnzeigekontextForUrl = ({ rubric }) => {
      if (isRubricKostenFinanzierung({ rubric })) {
        return [
          FoerderangebotAnzeigekontextUUIDs.kosten,
          FoerderangebotAnzeigekontextUUIDs.finanzierung,
        ];
      }
      return rubric.rubrik_obj.anzeigekontext;
    };
    const {
      checkPermissionsSync,
    } = PermissionAPI();
    const isRubricVisible = ({ rubric, anzeigekontextPks }) => {
      const RUBRIC_KEY = rubric.axr_config.key;
      if (RUBRIC_KEY === "gutachten") {
        return checkPermissionsSync({
          perm: "gutachten.view",
        });
      }
      return isRubricVisibleDefault({ rubric, anzeigekontextPks });
    };

    const loadFoerderregelMobilappkontext = () => {
      return getListHttp({
        url: "/foerderregelappkontexte/",
        urlParams: {
          sitzungsart: sitzungListeAntrag.value.sitzungsart
        }
      }).then(
        response => {
          const RELEVANTE_MOBILAPPKONTEXTE = [
            RegelMobilappkontextUUIDs.moduleSitzungNichtLaden,
            RegelMobilappkontextUUIDs.moduleSitzungSekundaer,
          ];
          regelmobilappkontext.value = filter(response, rea => indexOf(RELEVANTE_MOBILAPPKONTEXTE, rea.appkontext) !== -1);
        }
      );
    };

    const prepareModuleForRubric = ({ module }) => reject(module, mod => some(regelmobilappkontext.value, ["regel", mod.regel.regel]));

    const {
      buildRubrics,
      loadAllModule,
      loadRubrics,
      loadingModuleByRubricKey,
      moduleByRubricKey,
      rubricsFiltered,
    } = PuxRubricsAPI({
      baseUrl: sitzungAntragUrl,
      loadedModuleForRubricCallbacks,
      anzeigekontextPks,
      objectModuleUrl: sitzungAntragModuleUrl,
      objectType: "antrag",
      getRubricAnzeigekontextForUrl,
      isRubricVisible,
      prepareModuleForRubric,
    });


    const setAntrag = responseAntrag => {
      antrag.value = responseAntrag;
    };

    const loadData = () => {
      getHttp({
        url: allSitzungAntragUrl.value,
      }).then(
        response => {
          sitzungListeAntrag.value = response;
          setAntrag(cloneDeep(response.antrag));
          return Promise.all([
            loadGutachten(),
            loadRubrics(),
            loadFoerderregelMobilappkontext(),
          ]).then(
            () => {
              buildRubrics();
              loadAllModule();
              loading.value = false;
            }
          );
        },
        () => {
          addNotification({
            type: "error",
            text: "_MSG_SITZUNG_ANTRAG_LADEN_DATEN_ERROR_",
          });
          $goTo("root.sitzungsantraege");
        }
      );
    };

    provide("gutachten", computed(() => gutachten.value));

    loadData();

    return {
      antrag,
      sitzungListeAntrag,
      loading,
      sitzungAntragUrl,

      antragConfig,
      antragLabel,
      antragLabelWithoutHtml,
      anzeigekontexteAntrag,
      regelsatzKbez,
      statusWFStatusGruppenInHeader,

      dokumentePermissions,
      docPermissions,

      notizOptions,
      updateSitzungListeAntrag,

      finanzierungModule,
      finanzierungModuleNotKf,
      hasWFPermissionKoFiUpdate,
      kofiUebersichtAnzeigen,
      kostenModule,
      kostenModuleNotKf,
      zuschussinfo,

      loadingModuleByRubricKey,
      moduleByRubricKey,
      rubricsFiltered,
    };
  },
};
