import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  FilterSpacedMixin,
} from "../../../../global/mixins/FiltersMixin";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragMessageRegelsatzNotValid",
  components: {
    PuxAlert,
    PuxTranslate,
  },
  mixins: [
    FilterSpacedMixin,
  ],
  props: {
    antrag: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isVisible() {
      return !this.antrag.is_regelsatz_valid && !this.antrag.eingereicht;
    },

    extraAntragNummer() {
      return {
        antragsnummer: this.filterSpaced(this.antrag.a_nr, true),
      };
    },

    extraForTranslateNotActive() {
      return {
        a_nr: this.antrag.a_nr,
        ars_gueltig_bis: get(this.antrag, "regelsatz_obj.ars_gueltig_bis"),
      };
    },
  },
};
