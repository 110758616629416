import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTooltip from "../../../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import NotificationMixin from "../../../../../global/mixins/NotificationMixin";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

import { copyToClipboard } from "../../../../../global/functions/utils";

// @vue/component
export default {
  name: "TemplateAdminParameterBoxItem",
  components: {
    PuxIcon,
    PuxTooltip,
    PuxTranslate,
  },
  mixins: [
    NotificationMixin,
  ],
  props: {
    parameter: {
      type: Object,
      required: true,
    },
    parentIndex: {
      type: Number,
      required: true,
    },
    parameterIndex: {
      type: Number,
      required: true,
    },
    groupsAndParameterNamesHiddenWithSearch: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterBoolean,
    } = FiltersAPI();

    return {
      filterBoolean,
    };
  },
  data() {
    return {
      typesMapping: {
        string: "String",
        boolean: "Boolean",
        number: "Kommazahl",
        integer: "Ganzzahl",
        date: "Datum",
        datetime: "Zeitpunkt",
        unknown: "Unbekannt",
        list: "Liste",
        dict: "Dictionary",
      },
    };
  },
  computed: {
    isVisible() {
      return !this.groupsAndParameterNamesHiddenWithSearch.parameterNames[this.parameter.paramname];
    },

    id() {
      return `template_parameter_title_${ this.parentIndex }_${ this.parameterIndex }`;
    },

    icon() {
      return this.parameter.used ? "glyphicon-check" : "glyphicon-unchecked";
    },

    textUsed() {
      return this.parameter.used ?
        "_TXT_TEMPLATEADMIN_PARAMETER_IM_TEMPLATE_VERWENDET_" :
        "_TXT_TEMPLATEADMIN_PARAMETER_NICHT_IM_TEMPLATE_VERWENDET_";
    },

    typeInfotext() {
      return this.typesMapping[this.parameter.infotext] || this.parameter.infotext;
    },

    typeDatatype() {
      return this.typesMapping[this.parameter.datatype] || this.parameter.datatype;
    },

    createdByContentFiltered() {
      return this.filterBoolean(this.parameter.created_by_content, {
        isNilUsed: false,
      });
    },

    changedByContentFiltered() {
      return this.filterBoolean(this.parameter.changed_by_content, {
        isNilUsed: false,
      });
    },
  },
  methods: {
    copyParamToClipboard() {
      copyToClipboard({ text: `{${ this.parameter.paramname }}` });
      this.addNotification({ text: "_MSG_PARAMETER_TO_CLIPBOARD_SUCCESS_" });
    }
  },
};
