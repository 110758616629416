var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-element"] = jade_interp = function(label, fieldName, errorsMap, prefix){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-class", "{'has-error': " + (errorsMap) + "['" + (fieldName) + "']}", true, true)) + (jade.cls(['form-group','row',attributes.class], [null,null,false])) + "><label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"col-form-label col-sm-3 text-right font-weight-bold required\"><span" + (jade.attr("ng-class", "{'required':vm.isRequired('" + (fieldName) + "')}", true, true)) + " class=\"titel\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span></label><div class=\"col-sm-9 col-xl-6\">");
block && block();
buf.push("<label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"help-block\"><ul" + (jade.attr("ng-if", "" + (errorsMap) + "['" + (fieldName) + "']", true, true)) + " class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errorsMap) + "['" + (fieldName) + "']", true, true)) + ">{{ err }}</li></ul></label></div></div>");
};


























jade_mixins["vorschau-daten"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-repeat", "(key, val) in " + (vorschau) + "", true, true)) + "><span ng-if=\"val\">{{key}}: {{ val }}</span></div>");
};
jade_mixins["vorschau-container"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div>");
jade_mixins["vorschau-daten"](vorschau);
buf.push("</div>");
};
jade_mixins["vorschau-daten"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<hr><div><div" + (jade.cls(['form-group','row',attributes.class], [null,null,false])) + "><label ng-attr-for=\"{{ctx.prefix}}_honorarkosten_betragsrechner_kostenposition\" class=\"col-form-label col-sm-3 text-right font-weight-bold\"><span class=\"titel\">{{ ctx.gettext(\"Kostenposition (berechnet)\") }}</span></label><div class=\"col-sm-8\"><input type=\"text\"" + (jade.attr("value", "{{" + (vorschau) + ".betrag ? (" + (vorschau) + ".betrag | currency) : 'noch nicht ermittelbar'}} ", true, true)) + " disabled ng-attr-id=\"{{ctx.prefix}}_honorarkosten_betragsrechner_kostenposition\" class=\"form-control\"><span class=\"help-block\">{{ " + (jade.escape((jade_interp = vorschau) == null ? '' : jade_interp)) + ".bezeichnung ? " + (jade.escape((jade_interp = vorschau) == null ? '' : jade_interp)) + ".bezeichnung : '-' }}</span></div></div></div>");
};
buf.push("<div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input type=\"text\" maxlength=\"300\" ng-model=\"ctx.posmodel.argument.taetigkeit_bez\" name=\"taetigkeit_bez\" auto-focus=\"true\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_taetigkeit_bez\" ng-attr-aria-invalid=\"{{ctx.errors['taetigkeit_bez'] ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "Bezeichnung der Tätigkeit", "taetigkeit_bez", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input type=\"text\" maxlength=\"300\" ng-model=\"ctx.posmodel.argument.qualifikation_bez\" name=\"qualifikation_bez\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_qualifikation_bez\" ng-attr-aria-invalid=\"{{ctx.errors['qualifikation_bez'] ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "Qualifikation der Honorarkraft", "qualifikation_bez", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<select ng-model=\"ctx.posmodel.argument.zeiteinheit\" name=\"zeiteinheit\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_zeiteinheit\" role=\"listbox\" ng-attr-aria-invalid=\"{{ctx.errors['zeiteinheit'] ? 'true' : 'false'}}\" class=\"form-control\"><option value=\"\">--- Bitte wählen Sie ---</option><option value=\"h\">Stunde(n)</option><option value=\"d\">Tag(e)</option></select>");
}
}, "Zeiteinheit", "zeiteinheit", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input type=\"text\" ng-model=\"ctx.posmodel.argument.anzahl_zeiteinheiten\" name=\"anzahl_zeiteinheiten\" dias-decimal-field aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_anzahl_zeiteinheiten\" ng-attr-aria-invalid=\"{{ctx.errors['anzahl_zeiteinheiten'] ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "Anzahl Zeiteinheiten", "anzahl_zeiteinheiten", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" maxlength=\"9\" ng-model=\"ctx.posmodel.argument.kosten_pro_zeiteinheit\" name=\"kosten_pro_zeiteinheit\" dias-decimal-field aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_kosten_pro_zeiteinheit\" ng-attr-aria-invalid=\"{{ctx.errors['kosten_pro_zeiteinheit'] ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">&euro;</span></div></div>");
}
}, "Honorarsatz pro Zeiteinheit in Euro", "kosten_pro_zeiteinheit", "ctx.errors", "ctx.prefix");
buf.push("</div>");
jade_mixins["vorschau-container"]("ctx.vorschau");
buf.push("<div ng-if=\"ctx.kontext==='beleg'\"><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"checkbox-btn\"><input ng-model=\"ctx.posmodel.argument.dokument_geschwaerzt\" type=\"checkbox\" name=\"dokument_geschwaerzt\" ng-disabled=\"ctx.asPruefer()\" ng-attr-id=\"{{:: ctx.prefix }}_dokument_geschwaerzt\" ng-attr-aria-checked=\"{{ ctx.posmodel.argument.dokument_geschwaerzt ? 'true' : 'false' }}\" ng-attr-aria-invalid=\"{{ctx.errors.dokument_geschwaerzt ? 'true' : 'false'}}\" class=\"form-control\"><label ng-attr-for=\"{{:: ctx.prefix }}_dokument_geschwaerzt\" class=\"font-weight-bold\"><span class=\"titel\">{{:: ctx.gettext(\"_LBL_VERTRAG_DETAILS_BELEG_MODAL_CONFIRMATION_\") }}</span></label></div>");
}
}, "", "dokument_geschwaerzt", "ctx.errors", "ctx.prefix");
buf.push("</div></div>");;return buf.join("");
}