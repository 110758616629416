"use strict";

import BaseModel from "dias/core/models/baseModel";

class TaskLog extends BaseModel {
  constructor() {
    super("tasklogs");
  }

  extendModel(model) {
    model = super.extendModel(model);

    model.isSuccess = () => model.tl_status === "SUCCESS" || model.tl_status === "PARTIAL_SUCCESS";
    model.isFailure = () => model.tl_status === "FAILURE";
    model.isInProgress = () => !(model.isSuccess() || model.isFailure());

    return model;
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.getUnteraufgaben = oberaufgabe_id => params => collection.getList(Object.assign(params, { uebergeordnete_aufgabe: oberaufgabe_id }));

    return collection;
  }
}

export default TaskLog;
