"use strict";

import template from "./readonly.jade";

class ObjectParameterReadonly {
  /*@ngInject*/
  constructor(i18n) {
    this.gettext = i18n.gettext;
  }
}

export default {
  bindings: {
    parameter: "=",
    key: "@",
    controlId: "@?"
  },
  template: template(),
  controller: ObjectParameterReadonly,
  controllerAs: "vm",
};
