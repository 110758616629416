import PuxPageDetails from "../../../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import SmartTable from "../../../../../global/components/table/table.vue";

import getTableApi from "../../../../../const/TableApis";

const TABLE_ID = "BerichtAstorgAM";

// @vue/component
export default {
  name: "BerichtAstorgAM",
  components: {
    SmartTable,
    PuxPageDetails,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }) + "?tab_id=" + this.report.pk,
        rowActions: [
          {
            label: "_LBL_BERICHT_ASTORG_ROW_ACTIONS_DETAILS_",
            route: "root.astorganisationen.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        label: this.report.tab_kbez,
        sortable: true,
        pagination: true,
        details: false,
        showHeader: false,
        view: {
          filter: "top",
        },
      },
    };
  },
};
