"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

const iconsModule = angular.module("root.icons", [
  uiRouter,
]);

iconsModule.config($stateProvider => {
  $stateProvider

    .state("root.icons", {
      url: `/icons/`,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><icons-list></icons-list></div>"
        }
      },
      data: {
        permissions: ["asset.admin_app_view"],
      },
    })
  ;
})
;

export default iconsModule;
