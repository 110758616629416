"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import AuthService from "dias/auth/services";
import permissions from "dias/auth/permissions";
import DiasNotification from "dias/core/notification";
import DiasSearch from "dias/suche/models";
import i18n from "dias/core/i18n";

import navbarComponent from "./navbar.component";


const navbarModule = angular.module("navbar", [
  uiRouter,
  AuthService.name,
  permissions.name,
  DiasNotification.name,
  DiasSearch.name,
  i18n.name
])

  .component("navbar", navbarComponent);


export default navbarModule;
