/* Component, das die Betragsrechner-Components rendert */

"use strict";

import template from "./betragsrechner.component.template.jade";

class BetragsrechnerComponentController {
  /*@ngInject*/
  constructor($scope, $element, $compile, Betragsrechner) {
    this.Betragsrechner = Betragsrechner;
    const el = $compile("<" + this.rechner.component_name + " posmodel='ctx.posmodel' errors='ctx.errors' extras='ctx.extras' kontext='ctx.kontext' vorschau='ctx.vorschau' prefix='ctx.prefix'></" + this.rechner.component_name + ">")($scope);
    $element.append(el);
    this.vorschau = {};
    const watchStr = "ctx.posmodel." + (this.rechner.argumentAttr || "argument");
    if (!this.disablePreview) {
      $scope.$watch(watchStr, (newValues, oldValues, scope) => {
        this.updateVorschau(newValues, oldValues, scope);
      }, true);
      if (this.extraFieldUpdate) {
        $scope.$watch("ctx.posmodel." + this.extraFieldUpdate, () => {
          this.updateModelWithExtraField();
        });
      }
    }
  }

  updateModelWithExtraField() {
    this.posmodel[this.rechner.argumentAttr || "argument"][this.extraFieldUpdate] = this.posmodel[this.extraFieldUpdate];
  }

  updateVorschau(newValues, oldValues, scope) {
    // Aktualsiert die Vorschau mit ungespeicherten Werten:
    const data = _.cloneDeep(this.posmodel[this.rechner.argumentAttr || "argument"]) || {};
      // Kontext hinzufügen, da eventuell die Vorschau sich pro Anzeige-
      // Kontext unterscheiden:
    const params = {
      kontext: scope.ctx.kontext,
      afr_id: scope.ctx.extras.antragsregel.pk
    };
    scope.ctx.Betragsrechner.one(scope.ctx.rechner.name).customPOST(data, "vorschau", params).then(
      response => {
        scope.ctx.vorschau = response.vorschau;
        const errors = response.validation_errors;
        if (!this.extras.showErrorsHowInApi && angular.isObject(errors)) {
          Object.keys(errors).forEach(key => {
            if (!angular.isArray(errors[key])) {
              errors[key] = [errors[key]];
            }
          });
        }
        scope.ctx.errors = errors;
      }
    );
  }
}

export default {
  template: template(),
  controller: BetragsrechnerComponentController,
  controllerAs: "ctx",
  bindings: {
    rechner: "=",
    posmodel: "=",
    errors: "=",
    extras: "=",
    kontext: "=?",
    prefix: "<?",
    extraFieldUpdate: "<?",
  },
};
