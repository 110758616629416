"use strict";

import angular from "angular";
import "angular-strap";

const diasTimepickerModule = angular.module("dias.timepicker", [
  "mgcrea.ngStrap.timepicker",
]);

// Globale Konfigurieruation von AngularStraps bs-timepicker
diasTimepickerModule.config($timepickerProvider => {
  angular.extend($timepickerProvider.defaults, {
    iconUp: "glyphicon glyphicon-chevron-up font-size-md",
    iconDown: "glyphicon glyphicon-chevron-down font-size-md",
    autoclose: true,
    container: "body",
    // Nur Datums-Teil des ISO-Zeitstempels für Django-Rest-Framework Kompatibilität
    timeType: "string",
    modelTimeFormat: "HH:mm",
    timeFormat: "HH:mm",
  });
});

export default diasTimepickerModule;
