"use strict";

import template from "./auszahlungsmodi.readonly.jade";
import BaseController from "./auszahlungsmodi.base.controller";

class AuszahlungsmodiReadonlyController extends BaseController {
  /*@ngInject*/
  constructor($scope, ParameterUtils) {
    super($scope, ParameterUtils);
    $scope.$on(
      ParameterUtils.events.saved,
      ($event, keys, params) => {
        if (keys.indexOf(this.key) === -1) {
          return;
        }
        this.updateParamWert(params);
      }
    );
  }
}

export default {
  bindings: {
    parameter: "=",
    key: "@",
    controlId: "@?"
  },
  template: template(),
  controller: AuszahlungsmodiReadonlyController,
  controllerAs: "vm",
};
