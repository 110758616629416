"use strict";

import BaseController from "dias/standard_foerdermodule/standard_foerdermodule.base.controller";

class ObjektlisteController extends BaseController {
  /*@ngInject*/
  constructor($scope, $q, i18n) {
    super();
    this.gettext = i18n.gettext;
  }

  isReadonly() {
    return true;
  }

  setRequiredFields() {
    // not required from user
  }

  getTitel() {
    if (this.parameter.titel) {
      return this.parameter.titel;
    }
    return this.gettext("Objekte");
  }
}

export default ObjektlisteController;
