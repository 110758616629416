import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import Highcharts from "highcharts";
import {
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragsuebersichtGrafik",
  components: {
    PuxTranslate,
  },
  data() {
    return {
      showChart: undefined,
    };
  },
  methods: {
    rerenderCharts_fromParent({ dataCountAntraege, dataCountBetraege }) {
      if (isUndefined(dataCountAntraege)) {
        this.showChart = false;
        return;
      }
      this.showChart = true;
      Highcharts.setOptions({
        lang: {
          decimalPoint: ",",
          thousandsSep: ".",
        },
      });
      setTimeout(() => {
        Highcharts.chart("container_chart__anzahl", dataCountAntraege);
        Highcharts.chart("container_chart__betraege", dataCountBetraege);
      });
    },
  },
};
