import HttpMixin from "../../../../../global/mixins/HttpMixin";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import PuxCloak from "../../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxGet from "../../../../../global/components/PuxGet/PuxGet.vue";
import FormElementReadOnly from "../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import {
  toFormElementFromParameter,
  initModelFromList,
} from "../../../../../global/functions/mappingForParameterToFormElement";
import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "WorkflowsDetailsVisualisierungConnectionModal",
  components: {
    Modal,
    PuxTranslate,
    PuxCloak,
    AngularLink,
    PuxButton,
    PuxGet,
    FormElementReadOnly,
    PuxIcon,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    workflowfolgeId: {
      type: String,
      required: true,
      info: "Workflowfolge UUID",
    },
    selectorClose: {
      type: [String, Array],
      required: true,
      info: "CSS-Selector für Fokus, wenn man Modal-Fenster schließt",
    },
    close: {
      type: Function,
      required: true,
      info: "Schießen Modal-Fenster",
    },
  },
  data() {
    return {
      options: {
        showCloseButton: true,
        required: true,
      },
      statusLoading: true,
      statusShowDetailsStatusfunktionen: {},
      statusShowDetailsClientfunktion: false,
      statusShowDetailsServerfunktion: {},
      workflowfolge: {},
      funktionen: {},
      serverfunktionen: [],
      statusfunktionen: [],
      clientfunktion: {},
    };
  },
  computed: {
    extraForTranslate() {
      return {
        wfo_kbez: this.workflowfolge.wfo_kbez,
        wfo_kbez_admin: this.workflowfolge.wfo_kbez_admin,
      };
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      Promise.all([
        this.getHttp({
          url: `workflowfolgen/${ this.workflowfolgeId }/`
        }),
        this.getHttp({
          url: `workflowfolgen/${ this.workflowfolgeId }/funktionen/`
        }),
      ]).then(
        responses => {
          this.workflowfolge = responses[0];
          this.funktionen = responses[1];
          this.initServerfunktionen();
          this.initStatusfunktionen();
          this.initClientfunktion();
          this.statusLoading = false;
        }
      );
    },

    initServerfunktionen() {
      const SERVERFUNKTIONEN = [];
      const SERVERFUNKTIONEN_OBJ = cloneDeep(this.funktionen.serverfunktionen_objs) || [];
      forEach(SERVERFUNKTIONEN_OBJ, item => {
        const OBJ = {
          pk: item.pk,
          wfs_bez: item.wfs_bez,
          parameterin: toFormElementFromParameter({
            obj: item.params_in || {},
          }).list,
          parameterout: toFormElementFromParameter({
            obj: item.params_out || {},
          }).list,
        };
        OBJ.modelParameterin = initModelFromList({
          list: OBJ.parameterin,
        });
        SERVERFUNKTIONEN.push(OBJ);
      });
      this.serverfunktionen = SERVERFUNKTIONEN;
    },

    initStatusfunktionen() {
      const STATUSFUNKTIONEN = [];
      const STATUSFUNKTIONEN_OBJ = cloneDeep(this.funktionen.statusfunktionen_objs) || [];
      forEach(STATUSFUNKTIONEN_OBJ, item => {
        const OBJ = {
          pk: item.pk,
          wts_bez: item.wts_bez,
          parameterin: toFormElementFromParameter({
            obj: item.wts_parameterin || {},
          }).list,
        };
        OBJ.modelParameterin = initModelFromList({
          list: OBJ.parameterin,
        });
        STATUSFUNKTIONEN.push(OBJ);
      });
      this.statusfunktionen = STATUSFUNKTIONEN;
    },

    initClientfunktion() {
      const STATUSFUNKTIONEN_OBJ = cloneDeep(this.funktionen.clientfunktion_obj) || {};
      const CLIENTFUNKTION = {
        parameterin: toFormElementFromParameter({
          obj: STATUSFUNKTIONEN_OBJ.wfc_parameterin || {},
        }).list,
        parameterout: toFormElementFromParameter({
          obj: STATUSFUNKTIONEN_OBJ.wfc_parameterout || {},
        }).list,
      };
      CLIENTFUNKTION.modelParameterin = initModelFromList({
        list: CLIENTFUNKTION.parameterin,
      });
      CLIENTFUNKTION.modelParameterout = initModelFromList({
        list: CLIENTFUNKTION.parameterout,
      });
      this.clientfunktion = CLIENTFUNKTION;
    },

    toggleShowDetailsStatusfunktionen(pk) {
      this.statusShowDetailsStatusfunktionen[pk] = !this.statusShowDetailsStatusfunktionen[pk];
    },

    toggleShowDetails(key) {
      this[key] = !this[key];
    },

    toggleShowDetailsServerFunktion(pk) {
      this.statusShowDetailsServerfunktion[pk] = !this.statusShowDetailsServerfunktion[pk];
    },
  },
};
