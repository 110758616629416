import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "WorkflowfolgeclientfunktionenModalCreate",
  components: {
    Modal,
    PuxButton,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "wfc_bez",
            type: "text",
            label: this.$gettext("Name"),
            required: true,
            maxlength: 200,
            view: "v",
          },
          {
            id: "clientfunktion",
            type: "select",
            label: this.$gettext("Modul"),
            required: true,
            keyLabel: "bez",
            keyId: "id",
            search: true,
            searchList: ["bez"],
            view: "v",
            url: "katalog/?key=clientfunktion_module",
          }
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  methods: {
    onChange() { },

    onError({ error }) {
      this.errors = error;
      this.errorsClone = error;
    },

    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);

      this.postHttp({ url: "clientfunktionen/", data }).then(
        response => {
          this.addNotification({ text: "_MSG_WFC_MODAL_CREATE_SUCCESS_" });
          this.close();
          this.$goTo("root.workflowfolgeclientfunktionen.detail", { id: response.pk });
        },
        error => {
          this.onError({ error: error.data });
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
