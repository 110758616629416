import UiBelegeExtraFieldFinanzierung from "./ui/UiBelegeExtraFieldFinanzierung/UiBelegeExtraFieldFinanzierung.vue";
import UiBelegeExtraFieldKosten from "./ui/UiBelegeExtraFieldKosten/UiBelegeExtraFieldKosten.vue";
import UiBelegeField from "./ui/UiBelegeField/UiBelegeField.vue";

import UiBelegeFieldReadOnly from "./ui/UiBelegeField/UiBelegeFieldReadOnly/UiBelegeFieldReadOnly.vue";

export default {
  UiBelegeExtraFieldFinanzierung,
  UiBelegeExtraFieldKosten,
  UiBelegeField,
};

export const READ_ONLY = {
  UiBelegeFieldReadOnly,
};

export const MAPPING = {
  belege_extra_field_kosten: "ui-belege-extra-field-kosten",
  belege_extra_field_finanz: "ui-belege-extra-field-finanzierung",
  belege_field: "ui-belege-field",
};

export const MAPPING_READONLY = {
  belege_extra_field_kosten: "ui-dynamische-form-definition-read-only",
  belege_extra_field_finanz: "ui-dynamische-form-definition-read-only",
  belege_field: "ui-belege-field-read-only",
};

export const MODEL = {
  // belege_extra_field_kosten: () => ({ __new__: true }),
  // belege_extra_field_finanz: () => ({ __new__: true }),
};

export const EXTRA_OPTIONS_FOR_TYPE = {
};
