import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import FinanzierugMixin from "../Mixins/FinanzierugMixin";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PeriodenMixin from "../Mixins/PeriodenMixin";

// @vue/component
export default {
  name: "PeriodenSignalFinanzierung",
  components: {
    KFStandard,
    PuxButton,
  },
  mixins: [
    FinanzierugMixin,
    PeriodenMixin,
  ],
  data() {
    return {
      statusTableShow: {},
    };
  },
  computed: {
    sumLocal() {
      return this.antragBetragCurrency;
    },
  },
  methods: {
    onReceiveSignal(signal_kennung) {
      if (signal_kennung === "dynamische_tabelle.update" ||
        signal_kennung === "dynamische_zahlen.update") {
        this.reloadPositionen_KFBaseMixin({ statusSaveCallback: false });
        this.updateZuschuss_KFBaseMixin();
      }
    },

    updateInfoproviderLocal_KFBaseMixin({ response }) {
      response.gespeichert = true;
      this.updateInfoprovider({ response });
    },

    toggleTable(position) {
      const PK = position.pk;
      this.statusTableShow[PK] = !this.statusTableShow[PK];
    },
  },
};
