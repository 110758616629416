"use strict";

import template from "./chart.jade";

class ChartController {
  /*@ngInject*/
  constructor($state) {
    this.$state = $state;

    this.getWidth();
  }

  getWidth() {
    this.allValues = 0;
    this.countEmptyValues = 0;
    angular.forEach(this.relevantObj, block => {
      this.allValues += block.value;
      this.countEmptyValues = block.value ? this.countEmptyValues : ++this.countEmptyValues;
    });
    if (this.countEmptyValues !== this.relevantObj.length) {
      angular.forEach(this.relevantObj, block => {
        block.width = block.value / this.allValues * 100;
        block.width += "%";
      });
    }
  }

  goTo(block) {
    if (block.value > 1) {
      this.$state.go(block.goTo, block.goToParams);
    } else if (block.value === 1 && block.relevantesObjektPk) {
      if (angular.isArray(block.relevantesObjektPk)) {
        this.$state.go(block.goToRelevantesObjekt, { id: block.relevantesObjektPk[0], aus_id: block.relevantesObjektPk[1] });
      } else {
        this.$state.go(block.goToRelevantesObjekt, { id: block.relevantesObjektPk });
      }
    } else {
      // Fallback, falls id nicht gesetzt:
      this.$state.go(block.goTo, block.goToParams);
    }
  }

  createGoToLink(block) {
    if (!block.value) {
      return "#";
    }
    if (block.value > 1) {
      return this.$state.href(block.goTo, block.goToParams);
    } else if (block.value === 1 && block.relevantesObjektPk) {
      if (angular.isArray(block.relevantesObjektPk)) {
        return this.$state.href(block.goToRelevantesObjekt, { id: block.relevantesObjektPk[0], aus_id: block.relevantesObjektPk[1] });
      } 
      return this.$state.href(block.goToRelevantesObjekt, { id: block.relevantesObjektPk });
    } 
      // Fallback, falls id nicht gesetzt:
    return this.$state.href(block.goTo, block.goToParams);
  }

  showTitle(block) {
    if (block.value === 1) {
      return block.title.prefixTitleOne + " " + block.title.suffixTitleOne + " (" + block.signature + ")";
    }
    return block.title.prefixTitle + " " + (!block.value ? "keine" : block.value) + " " + block.title.suffixTitle + " (" + block.signature + ")";
  }
}

export default {
  template: template(),
  controller: ChartController,
  controllerAs: "vm",
  bindings: {
    relevantObj: "<"
  }
};
