// Modul zur Beantragung einer Ehrenamts-Pauschale
"use strict";

import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";
import VertragModels from "dias/vertrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const ehrenamt_pauschale = angular.module("dias.ehrenamt_pauschale", [
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
  VertragModels.name,
]);

ehrenamt_pauschale.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("ehrenamt_pauschale", "modul-ehrenamt-pauschale");
})

  .directive("modulEhrenamtPauschale", builder.directive(template, controller));

export default ehrenamt_pauschale;
