import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import {
  cloneDeep,
  forEach,
  get,
  isEmpty,
  isNil,
  keyBy,
  values,
} from "lodash-es";
import {
  initModelFromList,
  toFormElementFromParameter
} from "../../../../global/functions/mappingForParameterToFormElement";

const TYPE_MAP_SELECT = {
  anrede: {
    url: "katalog/?key=kanrede",
    apiSaveId: "kanrede",
  },
  rechtsform: {
    url: "katalog/?key=krechtsform",
    apiSaveId: "krechtsform",
  },
};

// @vue/component
export default {
  name: "DynamischeAdressen",
  components: {
    SnapshotModule,
    SnapshotIcon,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      antwortStatus: false,
      modelAdresse: {},
      labels: {},
      optionsForFormElement: {},
      data: {},
      dataMap: {},
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.saveCallback = this.saveLocal;
      OPTIONS.openCallback = this.openLocal;
      return OPTIONS;
    },

    labelHeader() {
      return this.modelParameter.frage;
    },

    headerTranslate() {
      if (this.parameterEingabeFelderObj.strasse ||
        this.parameterEingabeFelderObj.plz ||
        this.parameterEingabeFelderObj.ort ||
        this.parameterEingabeFelderObj.land) {
        return "_TXT_GR_DYNAMISCHE_ADRESSEN_ANSCHRIFT_";
      } else if (this.parameterEingabeFelderObj.anrede ||
        this.parameterEingabeFelderObj.vorname ||
        this.parameterEingabeFelderObj.name ||
        this.parameterEingabeFelderObj.rechtsform) {
        return "_TXT_GR_DYNAMISCHE_ADRESSEN_NAME_";
      }
      return "";
    },

    parameterEingabeFelderObj() {
      const EINGABE_FELDER = {};
      forEach(this.modelParameter.eingabe_felder || [], item => {
        EINGABE_FELDER[Object.keys(item)[0]] = true;
      });
      return EINGABE_FELDER;
    },

    statusShowAnredeVornameName() {
      return this.parameterEingabeFelderObj.anrede ||
        this.parameterEingabeFelderObj.vorname ||
        this.parameterEingabeFelderObj.name;
    },

    statusShowStrasseHausnummer() {
      return this.parameterEingabeFelderObj.strasse ||
        this.parameterEingabeFelderObj.hausnummer;
    },

    statusShowPlzOrt() {
      return this.parameterEingabeFelderObj.plz ||
        this.parameterEingabeFelderObj.ort;
    },

    diff() {
      const DIFF = {};
      if (!isEmpty(this.snapshotAdresse)) {
        forEach(this.parameterEingabeFelderObj, (item, key) => {
          if (this.snapshotAdresse[key] !== this.model[key]) {
            DIFF[key] = true;
          }
        });
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },

    snapshotAdresse() {
      const SNAPSHOT_ADRESSE = {};
      if (this.snapshotModule &&
        this.snapshotModule.eingabe_felder) {
        forEach(this.snapshotModule.eingabe_felder, (item, key) => {
          if (item.value) {
            this.antwortStatus = true;
            if (TYPE_MAP_SELECT[key]) {
              SNAPSHOT_ADRESSE[key] = item.value && item.value.pk;
            } else {
              SNAPSHOT_ADRESSE[key] = item.value;
            }
          }
        });
      }
      return SNAPSHOT_ADRESSE;
    },

    snapshotAntwortStatus() {
      if (isEmpty(this.snapshotAdresse)) {
        return false;
      }
      let snapshotAntwortStatus = false;
      forEach(this.snapshotAdresse, item => {
        if (!isNil(item)) {
          snapshotAntwortStatus = true;
          return false;
        }
      });
      return snapshotAntwortStatus;
    },

    errorsLabelsOptions() {
      return values(this.optionsForFormElement);
    },
  },
  methods: {
    prepareParameter() {
      const PARAMETER = toFormElementFromParameter({ obj: this.regel[`${ this.modulePrefix }_argument`] });
      this.parameterObj = PARAMETER.object;
      this.parameterList = PARAMETER.list;
      this.modelParameter = initModelFromList({ list: this.parameterList });

      this.labels = this.initLabelsMap();
      const OPTIONS_FOR_FORM_ELEMENT = {};
      const URLS = [];
      forEach(this.modelParameter.eingabe_felder, item => {
        const KEY = Object.keys(item)[0];
        const ITEM = item[KEY];
        OPTIONS_FOR_FORM_ELEMENT[KEY] = {
          id: KEY,
          htmlId: `${ this.htmlRegelNummer }_${ KEY }`,
          label: this.labels[KEY],
          required: !!ITEM.required,
          view: "v",
        };
        if (TYPE_MAP_SELECT[KEY]) {
          OPTIONS_FOR_FORM_ELEMENT[KEY].type = "select";
          OPTIONS_FOR_FORM_ELEMENT[KEY].keyLabel = "bez";
          OPTIONS_FOR_FORM_ELEMENT[KEY].keyId = "pk";
          OPTIONS_FOR_FORM_ELEMENT[KEY].search = true;
          OPTIONS_FOR_FORM_ELEMENT[KEY].searchList = ["bez"];
          URLS.push({
            key: KEY,
            url: TYPE_MAP_SELECT[KEY].url,
            apiSaveId: TYPE_MAP_SELECT[KEY].apiSaveId,
          });
        } else {
          OPTIONS_FOR_FORM_ELEMENT[KEY].type = "text";
        }
      });

      this.optionsForFormElement = OPTIONS_FOR_FORM_ELEMENT;
      this.loadData({ urls: URLS });
    },

    initLabelsMap() {
      const LABELS_MAP = {};
      const CHOICES = get(this.regel, `${ this.modulePrefix }_argument.eingabe_felder.choices`, []);
      forEach(CHOICES, item => {
        LABELS_MAP[item[0]] = item[1];
      });
      return LABELS_MAP;
    },

    loadData({ urls }) {
      if (!urls.length) {
        return;
      }
      forEach(urls, item => {
        this.getListHttp({
          url: item.url,
          apiSaveId: item.apiSaveId,
        }).then(
          response => {
            this.data[item.key] = response;
            this.dataMap[item.key] = keyBy(response, "pk");
          }
        );
      });
    },

    prepareModel() {
      this.antwortStatus = false;
      const MODEL_ADRESSE = {};
      const MODEL = cloneDeep(this.module.data) || {};
      MODEL.eingabe_felder = MODEL.eingabe_felder || {};
      forEach(MODEL.eingabe_felder, (item, key) => {
        if (item.value) {
          this.antwortStatus = true;
          if (TYPE_MAP_SELECT[key]) {
            MODEL_ADRESSE[key] = item.value && item.value.pk;
          } else {
            MODEL_ADRESSE[key] = item.value;
          }
        }
      });
      this.model = MODEL_ADRESSE;
    },

    saveLocal() {
      const DATA = {
        eingabe_felder: {},
      };
      forEach(this.modelParameter.eingabe_felder, item => {
        const KEY = Object.keys(item)[0];
        DATA.eingabe_felder[KEY] = {
          key: KEY,
          value: null,
        };

        if (this.modelAdresse[KEY]) {
          let value = this.modelAdresse[KEY];
          if (TYPE_MAP_SELECT[KEY]) {
            value = {
              pk: this.modelAdresse[KEY],
            };
          }
          DATA.eingabe_felder[KEY].value = value;
        }
      });
      return this.save({ model: DATA });
    },

    openLocal() {
      this.modelAdresse = cloneDeep(this.model);
    },

    setLocalErrorsFromGlobal({ errors }) {
      this.errors = errors;
    },
  },
};
