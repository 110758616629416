import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import AufgabenvorlagenDetailsBerechtigungenTableHeader from "./AufgabenvorlagenDetailsBerechtigungenTableHeader/AufgabenvorlagenDetailsBerechtigungenTableHeader.vue";
import AufgabenvorlagenDetailsBerechtigungenTableRow from "./AufgabenvorlagenDetailsBerechtigungenTableRow/AufgabenvorlagenDetailsBerechtigungenTableRow.vue";
import AufgabenvorlagenDetailsBerechtigungenTableRowEdit from "./AufgabenvorlagenDetailsBerechtigungenTableRowEdit/AufgabenvorlagenDetailsBerechtigungenTableRowEdit.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import KontextTypen from "../../../../const/KontextTypen";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import { cloneDeep } from "lodash-es";

// @vue/component
export default {
  name: "AufgabenvorlagenDetailsBerechtigungen",
  components: {
    AngularLink,
    AufgabenvorlagenDetailsBerechtigungenTableHeader,
    AufgabenvorlagenDetailsBerechtigungenTableRow,
    AufgabenvorlagenDetailsBerechtigungenTableRowEdit,
    FormElement,
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    kAufgabe: {
      type: Object,
      required: true,
    },
    infoTexts: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      getHttp,
      getListHttp,
      putHttp,
    } = HttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      checkPermissionsSync,
      getHttp,
      getListHttp,
      putHttp,
    };
  },
  data() {
    return {
      htmlId: "aufgabenvorlagen_details_berechtigungen",
      loading: true,
      errors: {},
      loadingFunktionsgruppen: false,
      loadingRolle: {
        bearbeiter: false,
        beobachter: false,
      },
      berechtigungen: {},
      rollen: [],
      funktionsgruppenChoices: [],
      funktionsgruppen: [],
      funktionsgruppenModel: undefined,
      selectedRollen: [],
      selectedRolle: {
        bearbeiter: {},
        beobachter: {},
      },
      rollenChoices: [],
      rollenChoicesModel: [],
      funktionsgruppenMap: {},
      funktionsgruppenMapModel: {},
      orderedFunktionsgruppenKeys: [],
      orderedFunktionsgruppenKeysModel: [],
      formstepOptions: {
        label: "_TXT_AUFGABENVORLAGENDETAILS_BERECHTIGUNGEN_",
        required: false,
        editablePermission: "kaufgabe.update.berechtigungen",
        saveCallback: this.save,
        openCallback: this.open,
        closeCallback: this.close,
      },
    };
  },
  computed: {
    hasPermissionRollenUi() {
      return this.checkPermissionsSync({
        perm: "rollen.ui",
      });
    },

    funktionsgruppenOptions() {
      const ID = "funktionsgruppen";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        loading: this.loadingFunktionsgruppen,
        label: "_TXT_AUFGABENVORLAGENDETAILS_BERECHTIGUNG_",
        type: "select",
        view: "v",
        keyId: "id",
        keyLabel: "bez",
        search: true,
        searchList: ["bez", "name"],
        data: this.funktionsgruppenChoices,
        deselect: true,
      };
    },

    buttonAddFunktionsgruppeDisabled() {
      return this.loadingFunktionsgruppen || !this.funktionsgruppenModel;
    },
  },
  created() {
    this.loadBerechtigungen();
  },
  methods: {
    loadBerechtigungen() {
      return this.getHttp({
        url: `${ this.baseUrl }berechtigungen/`
      }).then(
        response => {
          this.berechtigungen = response;
          this.updateBerechtigungen();
          if (this.hasPermissionRollenUi) {
            this.loadRollen();
          }
        },
      ).finally(
        () => this.loading = false,
      );
    },

    loadRollen() {
      this.loading = true;
      return this.getListHttp({
        url: "rollen/"
      }).then(
        response => {
          this.rollen = response.sort((r1, r2) => r1.kbez.localeCompare(r2.kbez));
          this.initRollen();
        },
        () => {
          this.addNotification({
            text: "_MSG_AUFGABENVORLAGENDETAILS_LOADING_ROLLEN_ERROR_",
            type: "error",
          });
        },
      ).finally(
        () => this.loading = false,
      );
    },

    loadFunktionsgruppen() {
      this.loadingFunktionsgruppen = true;
      return this.getListHttp({
        url: "funktionsgruppen/",
      }).then(
        response => {
          this.funktionsgruppen = response;
          this.initFunktionsgruppen();
        },
        errors => {
          this.addNotification({
            text: "_MSG_AUFGABENVORLAGENDETAILS_ERROR_LOADING_FUNKTIONSGRUPPEN_{{error}}_",
            extra: {
              error: errors.data || errors,
            }
          });
        },
      ).finally(
        () => this.loadingFunktionsgruppen = false,
      );
    },

    initFunktionsgruppen() {
      this.funktionsgruppen.forEach(funktionsgruppe => {
        funktionsgruppe.id = funktionsgruppe.key;
        funktionsgruppe.bez = funktionsgruppe.name;
      });
      this.funktionsgruppenChoices = this.funktionsgruppen.filter(
        funktionsgruppe => this.orderedFunktionsgruppenKeysModel.indexOf(funktionsgruppe.key) === -1
      );
    },

    loadSelectedRolle({ pk, type, editMode }) {
      if (!pk) {
        this.selectedRolle[type] = {};
        return;
      }
      this.loadingRolle[type] = true;
      return this.getHttp({
        url: `rollen/${ pk }/`,
      }).then(
        response => {
          this.checkRolle({
            rolle: response,
            type: type,
            map: editMode ? this.funktionsgruppenMapModel : this.funktionsgruppenMap,
          });
          this.selectedRolle[type] = response;
        },
      ).finally(
        () => this.loadingRolle[type] = false,
      );
    },

    updateBerechtigungen() {
      this.funktionsgruppenMap = {};
      this.berechtigungen.bearbeiter_fktgruppen.forEach(
        bearbeiter_fktgruppe => {
          this.funktionsgruppenMap[bearbeiter_fktgruppe.key] = this.funktionsgruppenMap[bearbeiter_fktgruppe.key] || { obj: bearbeiter_fktgruppe };
          this.funktionsgruppenMap[bearbeiter_fktgruppe.key].bearbeiter = true;
        }
      );
      this.berechtigungen.beobachter_fktgruppen.forEach(
        beobachter_fktgruppe => {
          this.funktionsgruppenMap[beobachter_fktgruppe.key] = this.funktionsgruppenMap[beobachter_fktgruppe.key] || { obj: beobachter_fktgruppe };
          this.funktionsgruppenMap[beobachter_fktgruppe.key].beobachter = true;
        }
      );
      this.berechtigungen.rollen.forEach(ro => this.checkRolle({
        rolle: ro, type: "bearbeiter", map: this.funktionsgruppenMap
      }));
      this.orderedFunktionsgruppenKeys = Object.keys(this.funktionsgruppenMap)
        .sort(
          (k1, k2) => this.funktionsgruppenMap[k1].obj.name.localeCompare(this.funktionsgruppenMap[k2].obj.name),
        );
    },

    checkRolle({ rolle, type, map }) {
      rolle.funktionsgruppen.forEach(
        funktionsgruppe => {
          if (!map[funktionsgruppe.key]) {
            return;
          }
          map[funktionsgruppe.key][rolle.pk] = map[funktionsgruppe.key][rolle.pk] || {};
          map[funktionsgruppe.key][rolle.pk][type] = map[funktionsgruppe.key][type];
        }
      );
    },

    initRollen() {
      this.rollen.forEach(rolle => {
        rolle.id = rolle.pk;
        rolle.bez = rolle.kbez;
        rolle.group = rolle.kontexttyp.kbez;
      });
      this.rollenChoices = this.rollen.filter(
        rolle => !this.berechtigungen.rollen.find(selectedRolle => selectedRolle.pk === rolle.pk)
      );
    },

    initSelectedRollen() {
      this.selectedRollen = cloneDeep(this.berechtigungen.rollen);
      this.rollenChoicesModel = this.rollen.filter(
        rolle => !this.selectedRollen.find(selectedRolle => selectedRolle.pk === rolle.pk)
      );
    },

    open() {
      this.selectedRolle.bearbeiter = {};
      this.selectedRolle.beobachter = {};
      this.initSelectedRollen();
      this.funktionsgruppenMapModel = cloneDeep(this.funktionsgruppenMap);
      this.orderedFunktionsgruppenKeysModel = cloneDeep(this.orderedFunktionsgruppenKeys);
      this.loadFunktionsgruppen();
    },

    save() {
      const DATA = {
        aro_ids: this.selectedRollen.filter(
          rolle => rolle.kontexttyp.id === KontextTypen.ANTRAGSTELLUNG,
        ).map(rolle => rolle.pk),
        fro_ids: this.selectedRollen.filter(
          rolle => rolle.kontexttyp.id === KontextTypen.FOERDERORG,
        ).map(rolle => rolle.pk),
        sor_ids: this.selectedRollen.filter(
          rolle => rolle.kontexttyp.id === KontextTypen.SYSTEM,
        ).map(rolle => rolle.pk),
        bearbeiter_funktionsgruppen: this.orderedFunktionsgruppenKeysModel.filter(
          key => this.funktionsgruppenMapModel[key].bearbeiter,
        ),
        beobachter_funktionsgruppen: this.orderedFunktionsgruppenKeysModel.filter(
          key => this.funktionsgruppenMapModel[key].beobachter,
        ),
      };

      return this.putHttp({
        url: `${ this.baseUrl }berechtigungen_bearbeiten/`,
        data: DATA,
      }).then(
        response => {
          this.berechtigungen = Object.assign({}, this.berechtigungen, response);
          this.updateBerechtigungen();
          this.addNotification({
            text: "_MSG_UPDATE_SUCCESS_"
          });
          return Promise.resolve();
        },
        errors => {
          this.errors = errors.data;
          return Promise.reject(errors.data);
        },
      );
    },

    close() {
      this.selectedRolle.bearbeiter = {};
      this.selectedRolle.beobachter = {};
      this.errors = {};
    },

    addRolle({ rollePk }) {
      if (this.selectedRolle.bearbeiter.pk !== rollePk) {
        return;
      }
      const ROLLE = cloneDeep(this.selectedRolle.bearbeiter);
      ROLLE._added = !this.berechtigungen.rollen.find(item => item.pk === ROLLE.pk);
      this.selectedRollen.push(ROLLE);
      this.rollenChoicesModel = this.rollen.filter(
        rolle => !this.selectedRollen.find(selectedRolle => selectedRolle.pk === rolle.pk)
      );
      this.selectedRolle.bearbeiter = {};
      if (this.selectedRolle.beobachter && this.selectedRolle.beobachter.pk === ROLLE.pk) {
        this.selectedRolle.beobachter = {};
      }
    },

    removeRolle({ rollePk }) {
      const INDEX = this.selectedRollen.findIndex(rolle => rolle.pk === rollePk);
      if (INDEX === -1) {
        return;
      }
      this.selectedRollen.splice(INDEX, 1);
      this.rollenChoicesModel = this.rollen.filter(
        rolle => !this.selectedRollen.find(selectedRolle => selectedRolle.pk === rolle.pk)
      );
    },

    addFunktionsgruppe() {
      const INDEX = this.funktionsgruppenChoices.findIndex(
        funktionsgruppe => funktionsgruppe.key === this.funktionsgruppenModel
      );
      if (INDEX === -1) {
        return;
      }
      this.funktionsgruppenMapModel[this.funktionsgruppenModel] = this.funktionsgruppenMapModel[this.funktionsgruppenModel]
      || { bearbeiter: false, beobachter: false, obj: this.funktionsgruppenChoices[INDEX] };
      this.orderedFunktionsgruppenKeysModel.push(this.funktionsgruppenModel);
      this.funktionsgruppenMapModel[this.funktionsgruppenModel]._added = this.orderedFunktionsgruppenKeys.indexOf(this.funktionsgruppenModel) === -1;
      this.funktionsgruppenChoices = this.funktionsgruppen.filter(
        funktionsgruppe => this.orderedFunktionsgruppenKeysModel.indexOf(funktionsgruppe.key) === -1
      );
      this.funktionsgruppenModel = undefined;
      this.updateRollenFunktionGruppen();
    },

    removeFunktionsgruppe({ key }) {
      const INDEX = this.orderedFunktionsgruppenKeysModel.findIndex(funktionsgruppeKey => funktionsgruppeKey === key);
      if (INDEX === -1) {
        return;
      }
      this.funktionsgruppenChoices = this.funktionsgruppen.filter(
        funktionsgruppe => this.orderedFunktionsgruppenKeysModel.indexOf(funktionsgruppe.key) === -1
      );
      delete this.funktionsgruppenMapModel[key];
      this.orderedFunktionsgruppenKeysModel.splice(INDEX, 1);
    },

    updateRollenFunktionGruppen() {
      this.selectedRollen.forEach(rolle => this.checkRolle({
        rolle: rolle,
        type: "bearbeiter",
        map: this.funktionsgruppenMapModel,
      }));
      if (this.selectedRolle.bearbeiter.pk) {
        this.checkRolle({
          rolle: this.selectedRolle.bearbeiter,
          type: "bearbeiter",
          map: this.funktionsgruppenMapModel,
        });
      }
      if (this.selectedRolle.beobachter.pk) {
        this.checkRolle({
          rolle: this.selectedRolle.beobachter,
          type: "beobachter",
          map: this.funktionsgruppenMapModel,
        });
      }
    },

    changeRollenBerechtigung({ key, type }) {
      this.funktionsgruppenMapModel[key][type] = !this.funktionsgruppenMapModel[key][type];
    }
  },
};
