export default {
  xl: {
    width: 1200,
    count: 4,
  },
  lg: {
    width: 992,
    count: 3,
  },
  md: {
    width: 768,
    count: 2,
  },
  sm: {
    width: 576,
    count: 1,
  },
};
