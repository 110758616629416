"use strict";

import BaseController from "./base.controller";
import LoadingTemplate from "./loading.jade";

export default () => {
  return {
    restrict: "A",
    transclude: true,
    template: LoadingTemplate,
    controller: BaseController,
    scope: {
      loading: "=diasLoading",
      msg: "=loadingMsg",
      size: "=loadingSize",
      align: "=loadingAlign",
      left: "=loadingLeft",
      onBlock: "&",
      onUnblock: "&",
    },
  };
};
