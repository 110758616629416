"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";


class WorkflowfolgenEditBeschreibungController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, ParameterUtils, DiasNotification, i18n) {
    super($q, $scope, DiasNotification);
    this.ParameterUtils = ParameterUtils;
    this.gettext = i18n.gettext;
    this.initForm();
  }

  _save() {
    const data = this.ParameterUtils.to_request_body(this.formModel, null, "");
    return this.model.customPOST(data, "funktionen_bearbeiten").then(
      response => {
        this.$scope.model.merge(this.$scope.model, response.plain());
        this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
      },
      errors => {
        return this.onErrors(errors, this.errorLabels);
      }
    );
  }

  onEnterEditMode() {
    super.onEnterEditMode();
    this.model.clientfunktion = this.model.clientfunktion_obj ? this.model.clientfunktion_obj.pk : undefined;
    this.model.statusfunktionen = this.model.statusfunktionen_objs.map(wts => wts.pk);
    this.model.serverfunktionen = this.model.serverfunktionen_objs.map(wfs => wfs.pk);
    this.updateFormModel();
  }

  updateFormModel() {
    if (this.ParameterUtils && this.formModelTemplate && this.model) {
      this.formModel = this.ParameterUtils.params_from_template(this.formModelTemplate, this.model);
    }
  }

  initForm() {
    this.formModelTemplate = this.ParameterUtils.init_parameter_in({
      clientfunktion: {
        bez: this.gettext("Clientfunktion"),
        typ: "single_choice",
        katalog: "clientfunktionen",
        localSearch: true,
        prio: 1,
        options: {
          required: false
        }
      },
      statusfunktionen: {
        bez: this.gettext("Statusfunktionen"),
        typ: "multi_select",
        katalog: "statusfunktionen",
        prio: 2,
        options: {
          required: false,
          displaymode: "select2"
        }
      },
      serverfunktionen: {
        bez: this.gettext("Serverfunktionen"),
        typ: "multi_select",
        katalog: "serverfunktionen",
        prio: 3,
        options: {
          required: false,
          displaymode: "ordered"
        }
      }
    });
    this.form_keys = this.ParameterUtils.get_ordered_keys(this.formModelTemplate);
    this.errorLabels = {};
    this.form_keys.forEach(
      k => {
        this.errorLabels[k] = `${ this.formModelTemplate[k].bez }: `;
      }
    );
    this.form_keys_left = this.form_keys.slice(0, 2);
    this.form_keys_right = this.form_keys.slice(2);
    this.updateFormModel();
  }
}

export default WorkflowfolgenEditBeschreibungController;
