"use strict";


class TraegerwechselComponent {
  /*@ngInject*/
  constructor($stateParams) {
    this.astId = $stateParams.id;
  }
}

export default {
  template: `<ast-traegerwechsel ast-id="vm.astId"></ast-traegerwechsel>`,
  controller: TraegerwechselComponent,
  controllerAs: "vm",
};
