"use strict";

import BaseModel from "dias/core/models/baseModel";

class Foerderfinderkategorien extends BaseModel {
  constructor() {
    super("foerderfinderkategorien");
  }
}

export default Foerderfinderkategorien;
