import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import ProfilDetailsNachrichtenTr from "../ProfilDetailsNachrichtenTr/ProfilDetailsNachrichtenTr.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";

import {
  getBenachrichtigungEinstellungMailIcon,
  getBenachrichtigungEinstellungBenIcon,
} from "../ProfilDetailsNachrichtenTr/ProfilDetailsNachrichtenTr";
import {
  cloneDeep,
  filter,
  findIndex,
  forEach,
  get,
  isNil,
} from "lodash-es";


// @vue/component
export default {
  name: "ProfilDetailsNachrichten",
  components: {
    FormstepDetail,
    ProfilDetailsNachrichtenTr,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
    updateNutzer: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      options: {
        label: "_TXT_PROFIL_DETAILS_NACHRICHTEN_FORMSTEP_NAME_",
        required: true,
        saveCallback: this.save,
        openCallback: this.setModel,
        list: [],
      },
      benachrichtigungstypen: [],
      model: {},
      idForSecondTh: "nachrichten_th_2",
      idForThirdTh: "nachrichten_th_3",
    };
  },
  computed: {
    filteredBenachrichtigungstypen() {
      return filter(this.benachrichtigungstypen, bnt => isNil(get(this.nutzer, `n_einstellungen[${ bnt.einstellung_key }]`)));
    },
  },
  created() {
    this.initBenachrichtigungstypen();
  },
  methods: {
    initBenachrichtigungstypen() {
      this.getHttp({
        url: "katalog/",
        urlParams: {
          key: "benachrichtigungstypen",
        },
        apiSaveId: "benachrichtigungstypen",
      }).then(
        response => {
          this.setBenachrichtigungstypen(response);
          this.initModel();
          this.setNutzerEinstellungen();
        }
      );
    },

    setBenachrichtigungstypen(response) {
      const NUTZER_ROLLEN = this.getNutzerRollenFromKontext();
      this.benachrichtigungstypen = filter(response, benachrichtigungstyp => this.isCurrentBenachrichtigungstypVisible({
        nutzerRollen: NUTZER_ROLLEN,
        benachrichtigungstyp,
      }));
    },

    getNutzerRollenFromKontext() {
      const ROLLEN = [];
      forEach(this.nutzer.kontexte, kontext => ROLLEN.push(...kontext.rollen));
      return ROLLEN;
    },

    isCurrentBenachrichtigungstypVisible({
      benachrichtigungstyp,
      nutzerRollen,
    }) {
      return (benachrichtigungstyp.rollen.length === 0 ||
        this.isOneRolleByByBenachrichtigungstypInNutzerRollen({
          nutzerRollen,
          rollenByBenachrichtigungstyp: benachrichtigungstyp.rollen,
        }))
        && this.isByBenachrichtigungstypBenachrichtigungOrMailAbbestellbar(benachrichtigungstyp);
    },

    isOneRolleByByBenachrichtigungstypInNutzerRollen({ nutzerRollen, rollenByBenachrichtigungstyp }) {
      return findIndex(rollenByBenachrichtigungstyp, rolle => nutzerRollen.indexOf(rolle) !== -1) !== -1;
    },

    isByBenachrichtigungstypBenachrichtigungOrMailAbbestellbar(benachrichtigungstyp) {
      return benachrichtigungstyp.bnt_ben_abbestellbar || benachrichtigungstyp.bnt_mail_abbestellbar;
    },

    initModel() {
      const MODEL = {};
      forEach(this.filteredBenachrichtigungstypen, benachrichtigungstyp => {
        MODEL[benachrichtigungstyp.einstellung_key] = {
          ben: true,
          mail: !!benachrichtigungstyp.mail_vorlage,
        };
      });
      this.model = MODEL;
    },

    setNutzerEinstellungen() {
      const NUTZER = cloneDeep(this.nutzer);
      NUTZER.n_einstellungen = NUTZER.n_einstellungen || {};
      forEach(this.filteredBenachrichtigungstypen, benachrichtigungstyp => {
        NUTZER.n_einstellungen[benachrichtigungstyp.einstellung_key] = {
          ben: true,
          mail: !!benachrichtigungstyp.mail_vorlage,
        };
      });
      this.updateNutzer({ nutzer: NUTZER });
    },

    save() {
      const DATA = cloneDeep(this.nutzer);
      DATA.n_einstellungen = cloneDeep(this.model);
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: `profil/${ this.nutzer.pk }/einstellungen_aendern/`,
          data: DATA,
        }).then(
          response => {
            this.updateNutzer({ nutzer: response });
            this.addNotification({ text: "_MSG_PROFIL_DETAILS_UPDATE_SUCCESS_" });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },

    changeModelFromChild({ id, value }) {
      this.model[id] = value;
    },

    setModel() {
      const MODEL = {};
      forEach(this.benachrichtigungstypen, benachrichtigungstyp => {
        MODEL[benachrichtigungstyp.einstellung_key] = {
          mail: getBenachrichtigungEinstellungMailIcon({
            benachrichtigungstyp,
            nutzerEinstellungen: this.nutzer.n_einstellungen,
          }) === "check",
          ben: getBenachrichtigungEinstellungBenIcon({
            benachrichtigungstyp,
            nutzerEinstellungen: this.nutzer.n_einstellungen,
          }) === "check",
        };
      });
      this.model = MODEL;
    },
  },
};
