import BelegButtonVersionsvergleich from "../../../Belege/BelegButtonVersionsvergleich/BelegButtonVersionsvergleich.vue";
import KofiErfassungBelegForm from "../KofiErfassungBelegForm/KofiErfassungBelegForm.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import ModalBelegAblehnen from "../ModalBelegAblehnen/ModalBelegAblehnen.vue";
import ModalBelegdokumentDelete from "../ModalBelegdokumentDelete/ModalBelegdokumentDelete.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxGet from "../../../../global/components/PuxGet/PuxGet.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTooltip from "../../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotItem from "../../../Snapshot/SnapshotItem/SnapshotItem.vue";

import loading from "../../../../global/directives/loading";
import translate from "../../../../global/directives/translate";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import KofiErfassungMixin from "../Mixins/KofiErfassungMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";
import {
  FilterCurrencyMixin,
  FilterLimitToMixin,
} from "../../../../global/mixins/FiltersMixin";

import KVertragskostenbelegStatusUUIDs, {
  getClassBetragAnerkanntInPersonalbaum,
} from "../../../../const/KVertragskostenbelegStatusUUIDs";
import {
  cloneDeep,
  filter,
  get,
  intersection,
  isEmpty,
  isFunction,
  isNil,
  size,
} from "lodash-es";

const VOLLSTAENDIG_ANERKANNT = 4;
const TEILWEISE_ANERKANNT = 3;
const ABGELEHENT = 2;
const EINGEREICHT = 1;
const NEU = 0;

export const BelegStatusMixin = {
  methods: {
    getBelegStatus({ beleg, keyBelegBetragAnerkannt, keyBelegBetragErfasst }) {
      const STATUS_PK = get(beleg, "status.pk");
      if (STATUS_PK === KVertragskostenbelegStatusUUIDs.NEU) {
        return NEU;
      } else if (STATUS_PK === KVertragskostenbelegStatusUUIDs.EINGEREICHT) {
        return EINGEREICHT;
      } else if (STATUS_PK === KVertragskostenbelegStatusUUIDs.GEPRUEFT) {
        const anerkannt = Number(beleg[keyBelegBetragAnerkannt]);
        const erfasst = Number(beleg[keyBelegBetragErfasst]);
        if (anerkannt >= erfasst) {
          return VOLLSTAENDIG_ANERKANNT;
        } else if (anerkannt > 0 && anerkannt < erfasst) {
          return TEILWEISE_ANERKANNT;
        } else if (anerkannt === 0) {
          return ABGELEHENT;
        }
      }
    },
  },
};

// @vue/component
export default {
  name: "KofiErfassungBeleg",
  components: {
    BelegButtonVersionsvergleich,
    KofiErfassungBelegForm,
    Modal,
    ModalBelegAblehnen,
    ModalBelegdokumentDelete,
    Permission,
    PuxButton,
    PuxGet,
    PuxIcon,
    PuxTooltip,
    PuxTranslate,
    SnapshotIcon,
    SnapshotItem,
  },
  directives: {
    loading,
    translate,
  },
  mixins: [
    BelegStatusMixin,
    FilterCurrencyMixin,
    FilterLimitToMixin,
    HttpMixin,
    KofiErfassungMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  props: {
    beleg: {
      type: Object,
      required: true,
      info: "Hauptinformation",
    },
    belegIndex: {
      type: Number,
      required: true,
      info: "Index in Array-Belege",
    },
    htmlId: {
      type: String,
      required: true,
      info: "{{modul.regel_nummer}}_{{positionIndex}}",
    },
    auszahlung: {
      type: Object,
      required: true,
      info: "Auszahlung",
    },
    vertrag: {
      type: Object,
      required: true,
      info: "Vertrag",
    },
    position: {
      type: Object,
      required: true,
      info: "Position",
    },
    prefixCurrent: {
      type: Object,
      required: true,
      info: "Information vom Kofiart(kosten oder Finanzierung)",
    },
    belegnummerAnzeigen: {
      type: Boolean,
      required: true,
      info: "Anzeigen Belegnummer",
    },
    apiAntrag: {
      type: String,
      required: true,
      info: "API foerderantraege/{{ AntragPk }}/",
    },
    apiBelege: {
      type: String,
      required: true,
      info: "API vertraege/{ID}/auszahlungen/{ID}/kosten/{ID}/positionen/{ID}/belege/",
    },
    belegDokumente: {
      type: Array,
      required: true,
      info: "Belegdokumente",
    },
    belegDokumenteAnzahl: {
      type: Object,
      required: false,
      info: "Mapping dok_id zur Anzahl der Belege, in der dieses Dokument benutzt wird",
      default: () => ({}),
    },
    apiAntragDokumente: {
      type: String,
      required: true,
      info: "API foerderantraege/{{ AntragPk }}/dokumente/?dokart={{ dokart }}",
    },
    extraKF: {
      type: Object,
      required: true,
      info: "{ regel, regeldata, modelParameter, positionArgument }",
    },
    positionen: {
      type: Array,
      required: true,
      info: "Alle Positionen",
    },
    relevantePerioden: {
      type: Array,
      required: false,
      default: undefined,
      info: "Alle für die Auszahlung relevanten Positionen",
    },
    contextNumber: {
      type: Number,
      required: true,
      info: "Applikationskontextnummer",
    },
    extraTranslate: {
      type: Object,
      required: true,
      info: "Die zusätzliche Information für Übersetzung { position_nummer, position_name }",
    },
    betragsrechner: {
      type: String,
      required: false,
      default: undefined,
      info: "Betragsrechner",
    },
    statusShowAllBelege: {
      type: Boolean,
      required: true,
      info: "",
    },
    alteBelegeAusblendbar: {
      type: Boolean,
      required: true,
      info: "SyConfig: auszahlung.alte_belege_ausblendbar",
    },
    statusSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    statusSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    diffKofi: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    kofiPk: {
      type: String,
      required: false,
      default: undefined,
    },
    positionPk: {
      type: String,
      required: false,
      default: undefined,
    },
    snapshot: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      confirmShow: undefined,
      confirmOptions: undefined,
      selectorClose: undefined,
      statusReloading: undefined,
      statusEdit: false,
      statusReadonlyShow: false,
      statusBelegExtra: false,
      statusModalDeleteDocument: false,
      isBelegbezeichnungAbgeschnitten: false,
      isAblehnenWithKommentar: false,
      statusModalAblehnen: undefined,
    };
  },
  computed: {
    statusShowBeleg() {
      return !this.alteBelegeAusblendbar || this.statusShowAllBelege || !this.beleg.auszahlung || this.beleg.auszahlung === this.auszahlung.pk;
    },

    statusShowPeriode() {
      return size(this.relevantePerioden);
    },

    htmlIdLocal() {
      return `${ this.htmlId }_${ this.belegIndex }`;
    },

    classBeleg() {
      const CLASS_BELEG = [];
      if (this.beleg.auszahlung &&
        this.beleg.auszahlung !== this.auszahlung.pk) {
        CLASS_BELEG.push("alt");
      }
      CLASS_BELEG.push(getClassBetragAnerkanntInPersonalbaum({ beleg: this.beleg, light: true }));
      return CLASS_BELEG;
    },

    keyBelegBetragAnerkannt() {
      return `${ this.prefixCurrent.beleg }_betrag_anerkannt`;
    },

    keyBelegBetragErfasst() {
      return `${ this.prefixCurrent.beleg }_betrag_erfasst`;
    },

    keyBelegNummer() {
      return `${ this.prefixCurrent.beleg }_nummer`;
    },

    keyBelegBez() {
      return `${ this.prefixCurrent.beleg }_bez`;
    },

    keyBelegKommentar() {
      return `${ this.prefixCurrent.beleg }_kommentar`;
    },

    keyBelegExtern() {
      return `${ this.prefixCurrent.beleg }_extern`;
    },

    belegCls() {
      switch (this.belegStatus) {
      case NEU:
        return "";
      case EINGEREICHT:
        return "text-info";
      case ABGELEHENT:
        return "text-danger";
      case TEILWEISE_ANERKANNT:
      case VOLLSTAENDIG_ANERKANNT:
        return "text-success";
      }
      return "";
    },

    belegTitle() {
      switch (this.belegStatus) {
      case NEU:
        return "";
      case EINGEREICHT:
        return "_TXT_BELEG_STATUS_TITLE_EINGEREICHT_";
      case ABGELEHENT:
        return "_TXT_BELEG_STATUS_TITLE_ABGELEHENT_";
      case TEILWEISE_ANERKANNT:
        return "_TXT_BELEG_STATUS_TITLE_TEILWEISE_ANERKANNT_";
      case VOLLSTAENDIG_ANERKANNT:
        return "_TXT_BELEG_STATUS_TITLE_VOLLSTAENDIG_ANERKANNT_";
      }
      return "";
    },

    belegIcon() {
      switch (this.belegStatus) {
      case NEU:
        return;
      case EINGEREICHT:
        return "ersatzantrag";
      case ABGELEHENT:
        return "close";
      case TEILWEISE_ANERKANNT:
      case VOLLSTAENDIG_ANERKANNT:
        return "ok";
      }
      return "";
    },

    belegStatus() {
      return this.getBelegStatus({
        beleg: this.beleg,
        keyBelegBetragAnerkannt: this.keyBelegBetragAnerkannt,
        keyBelegBetragErfasst: this.keyBelegBetragErfasst,
      });
    },

    statusShowTeilsAnerkannt() {
      return this.belegStatus === TEILWEISE_ANERKANNT;
    },

    belegnummer() {
      return this.beleg[this.keyBelegNummer];
    },

    documentLink() {
      return `api/${ this.apiAntrag }dokumente/${ this.beleg.dokument }/`;
    },

    statusHasPermissionVertragskostenpositionsbelegeAnerkennen() {
      return this.checkPermissionsSync({ perm: "vertragskostenpositionsbelege.anerkennen", permArray: this.auszahlung.user_permissions });
    },

    belegErfasst() {
      return this.beleg[this.keyBelegBetragErfasst] === null ? "-" : this.filterCurrency(this.beleg[this.keyBelegBetragErfasst]);
    },

    belegAnerkannt() {
      return this.beleg[this.keyBelegBetragAnerkannt] === null ? "-" : this.filterCurrency(this.beleg[this.keyBelegBetragAnerkannt]);
    },

    statusShowButtonAnerkennen() {
      const STATUS_PK = get(this.beleg, "status.pk");
      return STATUS_PK !== KVertragskostenbelegStatusUUIDs.INTERN_ERGAENZT;
    },

    statusDisabledButtonsAnerkennenAblehnen() {
      return this.beleg.is_eingereicht;
    },

    ariaDescribedbyForButtons() {
      // TODO: Barrierefreiheit
      return "";
    },

    buttonBelegAnerkennenId() {
      return `${ this.htmlIdLocal }_annerkennen`;
    },

    buttonBelegEditId() {
      return `${ this.htmlIdLocal }_edit`;
    },

    buttonBelegShowExtraId() {
      return `${ this.htmlIdLocal }_show_extra`;
    },

    buttonBelegDeleteId() {
      return `${ this.htmlIdLocal }_delete`;
    },

    buttonBelegAblehnenId() {
      return `${ this.htmlIdLocal }_ablehnen`;
    },

    buttonBelegResetId() {
      return `${ this.htmlIdLocal }_reset`;
    },

    buttonBelegShowId() {
      return `${ this.htmlIdLocal }_show`;
    },

    buttonDeleteDocumentId() {
      return `${ this.htmlIdLocal }_delete_document`;
    },

    idForBelegKommentar() {
      return `${ this.htmlIdLocal }_beleg_kommentar`;
    },

    belegKommentar() {
      return this.beleg[this.keyBelegKommentar];
    },

    statusShowBelegExtra() {
      // Dropdown für Beleg-Extras nur anzeigen, wenn es außer dem
      // Dokument Extra-Felder gibt
      return !!this.positionExtraFields.length;
    },

    apiBelegLocal() {
      return `${ this.apiBelege }${ this.beleg.pk }/`;
    },

    apiBelegAnerkennen() {
      return `${ this.apiBelegLocal }anerkennen/`;
    },

    apiBelegAblehnen() {
      return `${ this.apiBelegLocal }ablehnen/`;
    },

    apiBelegZuruecksetzen() {
      return `${ this.apiBelegLocal }zuruecksetzen/`;
    },

    positionExtraFields() {
      return filter(this.position.extra_fields || [], item => {
        return item[0] !== "dokument";
      });
    },

    extraTranslateLocal() {
      const EXTRA_TRANSLATE = cloneDeep(this.extraTranslate);
      EXTRA_TRANSLATE.beleg_name = this.beleg[this.keyBelegBez];
      return EXTRA_TRANSLATE;
    },

    belegBez() {
      return this.beleg[this.keyBelegBez];
    },

    belegExtern() {
      return this.beleg[this.keyBelegExtern];
    },

    belegBezFilter() {
      if (this.isBelegbezeichnungAbgeschnitten) {
        return this.filterLimitTo(this.belegBez, 50);
      }
      return this.belegBez;
    },

    isPeriodsVertrag() {
      return get(this.extraKF, "modelParameter.perioden_vertrag", true);
    },

    snapshotIconType() {
      if (this.statusSnapshotDelete) {
        return "delete";
      }
      if (this.statusSnapshotAdd) {
        return "add";
      }
      if (this.isDiffChanges) {
        return "changes";
      }
      return "";
    },

    diffChangesBeleg() {
      return get(this.diffKofi, `changes.${ this.kofiPk }.positionen.${ this.positionPk }.belege.${ this.beleg.pk }`, {}) || {};
    },

    isDiffChanges() {
      return this.diffKofi.status && !isEmpty(this.diffChangesBeleg);
    },

    idForBelegErfasst() {
      return `${ this.htmlIdLocal }_beleg_erfasst`;
    },

    idForBelegAnerkannt() {
      return `${ this.htmlIdLocal }_beleg_anerkannt`;
    },

    idForBelegStatus() {
      return `${ this.htmlIdLocal }_beleg_status`;
    },

    idForBelegPruefmethode() {
      return `${ this.htmlIdLocal }_beleg_pruefmethode`;
    },

    idForBelegPeriodeBez() {
      return `${ this.htmlIdLocal }_beleg_periode_bez`;
    },

    idForBelegNummer() {
      return `${ this.htmlIdLocal }_beleg_nummer`;
    },

    isInlineWindowBelegOpen() {
      return this.statusReadonlyShow || this.statusEdit || this.statusBelegExtra;
    },

    belegPermissions() {
      const AZ_PERMS = get(this.auszahlung, "user_permissions");
      const BELEG_PERMS = get(this.beleg, "user_permissions");
      if (isNil(AZ_PERMS)) {
        return BELEG_PERMS;
      }
      return intersection(BELEG_PERMS, AZ_PERMS);
    }
  },
  created() {
    this.checkSyConfig();
  },
  methods: {
    checkSyConfig() {
      this.isBelegbezeichnungAbgeschnitten = this.getSyConfigsValue("beleg_config", {}).belegbezeichnung_abschneiden || false;
      this.isAblehnenWithKommnetar = this.getSyConfigsValue("beleg_config", {}).ablehnen_kommentar || false;
    },

    anerkennenBeleg() {
      this.statusReloading = true;
      this.postHttp({
        url: this.apiBelegAnerkennen,
      }).then(
        () => {
          this.addNotification({ text: "_MSG_BELEG_ANERKANNT_SUCCESS_" });
          this.$attrs.onReloadKofi({ statusCallback: true });
        }
      ).then(
        () => {
          this.statusReloading = false;
        }
      );
    },

    toggleEditBeleg() {
      this.statusEdit = !this.statusEdit;
      this.statusBelegExtra = false;
    },

    toggleShowBeleg() {
      this.statusReadonlyShow = !this.statusReadonlyShow;
    },

    closeEditBeleg() {
      this.statusEdit = false;
      this.selectorClose = undefined;
      this.statusReadonlyShow = false;
    },

    toggleBelegExtra() {
      this.statusBelegExtra = !this.statusBelegExtra;
      this.statusEdit = false;
    },

    confirmDeleteBeleg() {
      this.selectorClose = [
        `#${ this.buttonBelegDeleteId }`,
        `#${ this.htmlId }`,
      ];
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "Beleg löschen?",
        msg: `<p>Sind Sie sicher, dass Sie diesen Beleg ${ this.beleg[this.keyBelegBez] } löschen wollen?</p>`,
        okLabel: "_BTN_DELETE_",
        okCallback: this.deleteBeleg,
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.confirmShow = true;
      this.onOpenModalLocal();
    },

    deleteBeleg() {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: this.apiBelegLocal,
      }).then(
        () => {
          this.$attrs.onReloadKofi({ statusReloadBelegDokumente: true, statusCallback: true });
          this.closeConfirm();
        },
        () => {
          this.confirmOptions.loading = true;
        }
      );
    },

    belegAblehnen() {
      if (this.isAblehnenWithKommnetar) {
        this.openModalAblehnen();
      } else {
        this.belegDirektAblehnen();
      }
    },

    belegDirektAblehnen({ kommentar } = {}) {
      this.statusReloading = true;
      const DATA = this.isAblehnenWithKommnetar ? { kommentar: kommentar } : undefined;
      return this.postHttp({
        url: this.apiBelegAblehnen,
        data: DATA
      }).then(
        () => {
          this.addNotification({ text: "_MSG_BELEG_ABGELEHNT_SUCCESS_" });
          this.$attrs.onReloadKofi({ statusCallback: true });
        }
      ).then(
        () => {
          this.statusReloading = false;
        }
      );
    },

    confirmResetBeleg() {
      this.selectorClose = [
        `#${ this.buttonBelegResetId }`,
        `#${ this.htmlId }`,
      ];
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "Beleg zurücksetzen?",
        msg: `<p>Sind Sie sicher, dass Sie die Anerkennung des Belegs ${ this.beleg[this.keyBelegBez] } zurücksetzen wollen?</p>`,
        okLabel: "_BTN_ZURUECKZETZEN_",
        okCallback: this.resetBeleg,
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.confirmShow = true;
      this.onOpenModalLocal();
    },

    resetBeleg() {
      this.confirmOptions.loading = true;
      this.postHttp({
        url: this.apiBelegZuruecksetzen,
      }).then(
        () => {
          this.addNotification({ text: "_MSG_BELEG_ZURUECKGESETZT_SUCCESS_" });
          this.$attrs.onReloadKofi({ statusCallback: true });
          this.closeConfirm();
        },
        () => {
          this.confirmOptions.loading = true;
        }
      );
    },

    closeConfirm() {
      this.confirmShow = false;
      this.confirmOptions = undefined;
      this.selectorClose = undefined;
    },

    openDeleteDokument() {
      this.selectorClose = [
        `#${ this.buttonDeleteDocumentId }`,
        `#${ this.htmlId }`,
      ];
      this.statusModalDeleteDocument = true;
      this.onOpenModalLocal();
    },

    closeDeleteDokument({ statusReload } = {}) {
      if (statusReload) {
        this.$attrs.onReloadKofi();
      }
      this.selectorClose = undefined;
      this.statusModalDeleteDocument = false;
    },

    openModalAblehnen() {
      this.selectorClose = [
        `#${ this.buttonBelegAblehnenId }`,
        `#${ this.htmlId }`,
      ];
      this.statusModalAblehnen = true;
      this.onOpenModalLocal();
    },

    closeModalAblehnen() {
      this.selectorClose = undefined;
      this.statusModalAblehnen = false;
    },

    getBelegBezWithDocument({ data, extra, isSnapshot, isSnapshotDelete, isTitle }) {
      const {
        apiAntrag,
        keyBelegBez,
        isBelegbezeichnungAbgeschnitten,
      } = extra;
      const BELEG_BEZ = get(data, keyBelegBez, "");
      const BELEG_DOCUMENT = get(data, "dokument", "");
      if (isSnapshotDelete || isSnapshot || !BELEG_DOCUMENT) {
        return `<span title="${ BELEG_BEZ }">${ BELEG_BEZ }</span>`;
      }
      const BELEG_BEZ_FILTERED = isBelegbezeichnungAbgeschnitten && !isTitle ? this.filterLimitTo(BELEG_BEZ, 50) : BELEG_BEZ;
      return `<a href="api/${ apiAntrag }dokumente/${ BELEG_DOCUMENT }/" title="${ BELEG_BEZ }" target="_blank">${ BELEG_BEZ_FILTERED }</a>`;
    },

    onOpenModalLocal() {
      if (isFunction(this.$attrs.onOpenModal)) {
        this.$attrs.onOpenModal();
      }
    }
  },
};
