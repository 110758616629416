import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

import { find } from "lodash-es";

// @vue/component
export default {
  name: "SitzungDetailsTagesordnungSelectedModul",
  components: {
    Modal,
    PuxButton,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    module: {
      type: Object,
      required: true,
    },
    modulesLength: {
      type: Number,
      required: true,
    },
    moduleKatalog: {
      type: Array,
      default: () => [],
    },
    tagesordnungItem: {
      type: Object,
      default: undefined,
    },
  },
  emits: [
    "moveModuleUp",
    "moveModuleDown",
    "removeModule",
  ],
  data() {
    return {
      modalRemoveConfirmOptions: {},
      showModalRemoveConfirm: false,
    };
  },
  computed: {
    buttonMoveUpId() {
      return `button_move_up_id_${ this.index }`;
    },

    aboveButtonMoveUpId() {
      if (this.index > 0) {
        return `button_move_up_id_${ this.index - 1 }`;
      }
      return undefined;
    },

    belowButtonMoveDownId() {
      if (this.index + 1 < this.modulesLength) {
        return `button_move_down_id_${ this.index + 1 }`;
      }
      return undefined;
    },

    buttonMoveDownId() {
      return `button_move_down_id_${ this.index }`;
    },

    buttonRemoveId() {
      return `button_remove_id_${ this.index }`;
    },

    buttonRemoveIdSelectorClose() {
      return `#button_remove_id_${ this.index }`;
    },

    removeModuleButtonDisabled() {
      if (!this.moduleKatalog.length) {
        return false;
      }
      return !find(this.moduleKatalog, module => module.pk === this.module.modul);
    },
  },
  methods: {
    moveModuleUp() {
      this.$emit("moveModuleUp", { index: this.index });
      if (this.aboveButtonMoveUpId !== undefined) {
        setTimeout(() => {
          $(`#${ this.aboveButtonMoveUpId }`).focus();
        });
      }
    },

    moveModuleDown() {
      this.$emit("moveModuleDown", { index: this.index });
      if (this.belowButtonMoveDownId !== undefined) {
        setTimeout(() => {
          $(`#${ this.belowButtonMoveDownId }`).focus();
        });
      }
    },

    removeModule() {
      this.$emit("removeModule", { index: this.index });
      this.showModalRemoveConfirm = false;
    },

    confirmRemoveModule() {
      if (this.tagesordnungItem !== undefined) {
        this.modalRemoveConfirmOptions = {
          okClass: "btn btn-primary",
          okLabel: "_LBL_SITZUNGSDETAILS_TAGESORDNUNGSPUNKT_BUTTON_PROCEED_",
          title: "_TXT_SITZUNGSDETAILS_TAGESORDNUNGSPUNKT_REMOVE_MODULE_",
          msg: "_MSG_SITZUNGSDETAILS_TAGESORDNUNGSPUNKT_REMOVE_MODULE_{{bez}}_",
          extra: {
            bez: this.module.stm_kbez,
          },
          okCallback: this.removeModule,
          cancelCallback: this.closeConfirmRemoveModule,
          loading: false,
        };
        this.showModalRemoveConfirm = true;
      } else {
        this.removeModule();
      }
    },

    closeConfirmRemoveModule() {
      this.showModalRemoveConfirm = false;
    },
  },
};
