import PuxPageDetails from "../../../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import SmartTable from "../../../../../global/components/table/table.vue";
import Spaced from "../../../../../global/components/Spaced/Spaced.vue";

import getTableApi from "../../../../../const/TableApis";

const TABLE_ID = "BerichtProduktionsspiegelMdm";

// @vue/component
export default {
  name: "BerichtProduktionsspiegelMdm",
  components: {
    PuxPageDetails,
    SmartTable,
    Spaced,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_LBL_BERICHT_DETAILS_MDM_PRODUKTIONSSPIEGEL_TABLE_ROW_ACTIONS_DETAILS_",
            route: "root.basisformularcortex.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        sortable: true,
        pagination: true,
        details: false,
        showHeader: false,
        view: {
          filter: "top",
        },
      },
    };
  },
};
