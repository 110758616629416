"use strict";
import {
  isUndefined,
  isEmpty,
} from "lodash-es";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";

class FoerdervertragsbestaetigungEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, moment, i18n) {
    super($q, $scope, DiasNotification);
    this.moment = moment;
    this.gettext = i18n.gettext;
    this.errorsLabel = {
      von: "Beginn: ",
      bis: "Ende: "
    };

    this.updateDifference();

    this.save = () => {
      const FORMAT_ERRORS = this.formatErrors();
      if (!isEmpty(FORMAT_ERRORS)) {
        return this.onErrors(FORMAT_ERRORS, this.errorsLabel);
      }
      return this.model.putFvb(
        this.model.foerdervertragsbestaetigung.von,
        this.model.foerdervertragsbestaetigung.bis
      ).then(
        () => {
          this.$scope.model.merge(this.$scope.model.foerdervertragsbestaetigung, this.model.foerdervertragsbestaetigung);
          this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
          console.log(this.$scope.extra, this.$scope.extra.onSave);
          if (this.$scope.extra.onSave) {
            this.$scope.extra.onSave();
          }
        },
        errors => {
          return this.onErrors(errors, this.errorsLabel);
        }
      );
    };
  }

  updateDifference() {
    if (this.model.foerdervertragsbestaetigung.bis && this.model.foerdervertragsbestaetigung.von) {
      // this.curDiff = this.moment(this.model.foerdervertragsbestaetigung.bis).diff(this.moment(this.model.foerdervertragsbestaetigung.von), "days") + 1;
      const foerdervertragsbestaetigungVon = this.moment(this.model.foerdervertragsbestaetigung.von).add(-1, "days");
      this.model.foerdervertragsbestaetigung.vertragMonths = this.moment(this.model.foerdervertragsbestaetigung.bis).diff(foerdervertragsbestaetigungVon, "months");
      foerdervertragsbestaetigungVon.add(this.model.foerdervertragsbestaetigung.vertragMonths, "months");
      this.model.foerdervertragsbestaetigung.vertragDays = this.moment(this.model.foerdervertragsbestaetigung.bis).diff(foerdervertragsbestaetigungVon, "days");
    } else {
      this.curDiff = 0;
    }
  }

  formatErrors() {
    const ERRORS_LOCAL = {};
    const ERR_MSG = this.gettext("Datum im Format TT.MM.JJJJ eingeben.");
    if (isUndefined(this.model.foerdervertragsbestaetigung.von)) {
      ERRORS_LOCAL.von = [ERR_MSG];
    }
    if (isUndefined(this.model.foerdervertragsbestaetigung.bis)) {
      ERRORS_LOCAL.bis = [ERR_MSG];
    }
    return ERRORS_LOCAL;
  }
}

export default FoerdervertragsbestaetigungEditController;
