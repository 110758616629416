import PuxIcon from "../PuxIcon/PuxIcon.vue";

// @vue/component
export default {
  name: "PuxAlert",
  components: {
    PuxIcon,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      info: "Ist Alert-Inhalt sichtbar?",
    },
    type: {
      type: String,
      required: false,
      default: "danger",
      validator: type => [
        "danger",
        "info",
        "success",
        "warning",
      ].indexOf(type) !== -1,
      info: "Alert-Typ",
    },
    isDismissible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    "onDismiss",
  ],
  computed: {
    alertClass() {
      let alertClass = `alert-${ this.type }`;
      if (this.isDismissible) {
        alertClass += " pr-5";
      }
      return alertClass;
    },
  },
  methods: {
    onDismissLocal() {
      this.$emit("onDismiss");
    },
  },
};
