import PuxButton from "../../PuxButton/PuxButton.vue";
import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";

import UiValidatedJsonModeMixin from "./UiValidatedJsonModeMixin";
import HttpMixin from "../../../mixins/HttpMixin";

import {
  assign,
  cloneDeep,
  filter,
  forEach,
  get,
  isArray,
  isNil,
  isString,
  join,
  map,
  maxBy,
  pick,
  pickBy,
  size,
  sortBy,
  uniqueId,
} from "lodash-es";

import { replaceText } from "../../../functions/utils";
import { toFormElementFromParameter } from "../../../functions/mappingForParameterToFormElement";
import { FilterBooleanMixin, FilterCurrencyMixin } from "../../../mixins/FiltersMixin";

export default {
  components: {
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    FilterBooleanMixin,
    FilterCurrencyMixin,
    HttpMixin,
    UiValidatedJsonModeMixin,
  ],
  data() {
    return {
      showDetails: {},
      labelModel: undefined,
    };
  },
  computed: {
    modelSize() {
      return size(this.model);
    },
    keyField() {
      return this.options.key_field;
    },
    posField() {
      return this.options.pos_field;
    },
    minLength() {
      return this.options.min_length;
    },
    maxLength() {
      return this.options.max_length;
    },
    deactivateOrdering() {
      return this.options.deactivate_ordering;
    },
    deactivatePreview() {
      return this.options.deactivate_preview;
    },
    hidePos() {
      return this.options.hide_pos;
    },
    nextPos() {
      if (isNil(this.posField)) {
        return;
      }
      return get(maxBy(this.model, this.posField), this.posField);
    },
    iterationModel() {
      const ITER = map(this.localModel, (el, key) => key);
      if (!isNil(this.posField)) {
        return sortBy(ITER, key => get(this.localModel, [key, this.posField]));
      }
      return sortBy(ITER, key => key);
    },
    elementLabels() {
      if (!isString(this.elementTemplate)) {
        return {};
      }
      const LABELS = {};
      const MODEL = this.labelModel || this.localModel;
      forEach(MODEL, (el, key) => {
        LABELS[key] = replaceText({
          text: this.elementTemplate,
          object: assign({ element_label: this.elementLabel }, el || {})
        });
      });
      return LABELS;
    }
  },
  created() {
    this.updateLabelModel();
  },
  methods: {
    updateLabelModel() {
      if (!isString(this.elementTemplate)) {
        return;
      }
      const RELEVANTE_KEYS = filter(
        map(
          [...this.elementTemplate.matchAll(/\{\{([\w\d_]+)\}\}/ig)],
          match => match[1]
        ),
        v => v !== "element_label"
      );
      if (size(RELEVANTE_KEYS) === 0) {
        return;
      }
      const FIELDS = pick(this.fields, RELEVANTE_KEYS);
      const KATALOG_FIELDS = toFormElementFromParameter(
        { obj: pickBy(FIELDS, field => !isNil(field.katalog)) }
      ).list;
      if (size(KATALOG_FIELDS) > 0) {
        Promise.all(
          map(KATALOG_FIELDS, field => this.getListHttp({
            url: field.url,
            apiSaveId: field.apiSaveId,
            urlParams: field.urlParams,
          }))
        ).then(responses => {
          const KATALOG_DATA = {};
          forEach(KATALOG_FIELDS, (field, idx) => KATALOG_DATA[field.id] = responses[idx]);
          this.buildLabelModel(FIELDS, KATALOG_DATA);
        });
      } else {
        this.buildLabelModel(FIELDS, {});
      }
    },

    buildLabelModel(fields, katalogData) {
      const LABEL_MODEL = {};
      forEach(this.localModel, (model, mkey) => {
        const LABELS = {};
        forEach(fields, (field, key) => {
          let selectModel;
          const TYP = field.typ || field.type;
          if (isNil(model[key])) {
            LABELS[key] = "-";
            return;
          }
          if (TYP === "single_choice") {
            selectModel = [model[key]];
          } else {
            selectModel = model[key];
          }
          if (isString(field.katalog) && !isNil(katalogData[key])) {
            const ENTRIES = map(
              filter(katalogData[key], entry => selectModel.indexOf(entry.pk) !== -1),
              entry => entry.bez
            );
            ENTRIES.sort();
            LABELS[key] = join(ENTRIES, ", ");
          } else if (isArray(field.choices)) {
            const ENTRIES = map(
              filter(field.choices, entry => selectModel.indexOf(entry[0]) !== -1),
              entry => entry[1]
            );
            ENTRIES.sort();
            LABELS[key] = join(ENTRIES, ", ");
          } else if (TYP === "multi_select") {
            const ENTRIES = cloneDeep(selectModel);
            ENTRIES.sort();
            LABELS[key] = join(ENTRIES, ", ");
          } else if (TYP === "bool" || TYP === "boolean") {
            LABELS[key] = this.filterBoolean(model[key], {
              trueValue: get(field, "options.true_label", undefined),
              falseValue: get(field, "options.false_label", undefined),
            });
          } else if (TYP === "decimal" || TYP === "float") {
            LABELS[key] = this.filterCurrency(model[key], null, get(field, "options.decimal_places", 2), true);
          } else {
            LABELS[key] = model[key];
          }
        });
        LABEL_MODEL[mkey] = LABELS;
      });
      this.labelModel = LABEL_MODEL;
    },

    getIterationKey(key) {
      return uniqueId(key);
    },

    getBtnId(btn, key) {
      return `${ this.idPrefix }_${ btn }_${ key || "" }`;
    },

    getElementId(key) {
      return `${ this.idPrefix }_${ key }`;
    },

    toggleElement(key) {
      const SHOW_DETAILS = cloneDeep(this.showDetails);
      SHOW_DETAILS[key] = !this.isElementDetailsVisible(key);
      this.showDetails = SHOW_DETAILS;
    },

    isElementDetailsVisible(key) {
      return get(this.showDetails, key, false);
    },

    getElementDetailsToggleTitle(key) {
      if (this.isElementDetailsVisible(key)) {
        return "_BTN_UI_VALIDATED_JSON_DETAILS_TOGGLE_HIDE_";
      }
      return "_BTN_UI_VALIDATED_JSON_DETAILS_TOGGLE_SHOW_";
    },

    getElementDetailsToggleIcon(key) {
      if (this.isElementDetailsVisible(key)) {
        return "glyphicon-eye-close";
      }
      return "glyphicon-eye";
    },
  }
};
