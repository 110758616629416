"use strict";

import BetragsrechnerBase from "dias/kosten_finanzierung/betragsrechner/betragsrechner.base";


class BetragsrechnerAbhaengigeKostenpauschale extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "abhaengige_kostenpauschale_rechner";
    this.component_name = "abhaengigekostenpauschale";
    this.betrag_editable = true;
    this.fields = {
      antrag: { wert: "",
                bezeichnung: "" },
      beleg: { wert: "" }
    };
  }

  get_labelmapping() {
    return {
      wert: "Betrag in EUR",
      bezeichnung: "Bezeichnung",
      kostenartdetail: "Typ / Kategorie"
    };
  }
}

export default BetragsrechnerAbhaengigeKostenpauschale;
