"use strict";

import "restangular";

import KZielgruppe from "./kzielgruppe.service";

const modelsModule = angular.module("dias.zielgruppen.models", [
  "restangular",
])

  .provider("KZielgruppe", KZielgruppe)
;

export default modelsModule;
