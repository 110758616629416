"use strict";

import DynamischeFeldwertlisteReadOnlyController from "./readonly.controller";

class DynamischeFeldwertliste extends DynamischeFeldwertlisteReadOnlyController {
  /*@ngInject*/
  constructor(DiasNotification, Katalog) {
    super(Katalog);
    this.DiasNotification = DiasNotification;
    this.setDefaultModel();
    this.initData();
    this.feldAddSelections = {};
  }

  initData() {
    if (this.parameter[this.key].wert === undefined || this.parameter[this.key].wert === null) {
      this.parameter[this.key].wert = [];
    }
  }

  removeFeld(index) {
    this.parameter[this.key].wert.splice(index, 1);
  }

  moveUpFeld(index) {
    if (index === 0) {
      return;
    }
    const elem = this.parameter[this.key].wert[index];
    const previous = this.parameter[this.key].wert[index - 1];
    this.parameter[this.key].wert[index - 1] = elem;
    this.parameter[this.key].wert[index] = previous;
  }

  moveDownFeld(index) {
    if (index >= this.parameter[this.key].wert.length - 1) {
      return;
    }
    const elem = this.parameter[this.key].wert[index];
    const next = this.parameter[this.key].wert[index + 1];
    this.parameter[this.key].wert[index + 1] = elem;
    this.parameter[this.key].wert[index] = next;
  }


  isDisabled() {
    return !this.model.id;
  }

  onSelectFeld() {
    if (this.model.id) {
      this.model.bez = this.getOriginalBez(this.model.id);
      this.model.label = this.model.bez;
    }
  }

  setDefaultModel() {
    this.model = {
      label: null,
      id: null,
      bez: null,
      empty_text: null,
      omit_if_empty: false
    };
  }

  addFeld() {
    let added = false;
    if (this.model.id) {
      added = this.addToList(this.parameter[this.key].wert, this.setObj(true));
    }
    if (added) {
      this.setDefaultModel();
    }
  }

  addToList(list, el) {
    const alreadyIn = list.find(v => v.id === el.id);
    if (alreadyIn) {
      this.DiasNotification.page.error(`Feld "${ el.bez }" wurde bereits hinzgefügt.`);
      return false;
    }
    list.push(el);
    return true;
  }

  getOriginalBez(id) {
    let x;
    angular.forEach(this.feldChoices, feld => {
      if (feld.id === id) {
        x = feld.bez;
      }
    });
    return x;
  }

  setObj() {
    let alternative_label = this.model.label;
    if (!alternative_label) {
      alternative_label = this.model.bez;
    }
    const data = {
      label: alternative_label,
      bez: this.model.bez,
      id: this.model.id,
      omit_if_empty: this.model.omit_if_empty,
      empty_text: this.model.empty_text,
    };
    return data;
  }
}

export default DynamischeFeldwertliste;
