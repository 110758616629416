export default {
  name: {
    id: "name",
    type: "text",
    label: "Name",
    required: true,
    view: "v-alt",
    hideIf: false,
  },
  stellenumfang_projekt: {
    id: "stellenumfang_projekt",
    type: "float",
    label: "Umfang dieser Stelle im Projekt",
    addonBack: "Stunden pro Woche",
    required: true,
    view: "v-alt",
    maxValue: 50,
    helpText: undefined,
  },
  zeitraum_von: {
    id: "zeitraum_von",
    type: "date",
    label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_ZEITRAUM_VON_",
    required: true,
    view: "v-alt",
    translate: true,
    tooltip: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_ZEITRAUM_VON_INFO_I_",
    notBefore: undefined,
    notAfter: undefined,
    clearable: false,
    helpText: undefined,
  },
  zeitraum_bis: {
    id: "zeitraum_bis",
    type: "date",
    label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_ZEITRAUM_BIS_",
    required: true,
    view: "v-alt",
    translate: true,
    tooltip: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_ZEITRAUM_BIS_INFO_I_",
    notBefore: undefined,
    notAfter: undefined,
    clearable: false,
    helpText: undefined,
  },
};
