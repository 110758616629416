"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";
import diasTable from "dias/core/table";

const nutzerdatensucheModule = angular.module("nutzerdatensuche", [
  uiRouter,
  diasTable.name,
]);

nutzerdatensucheModule.config($stateProvider => {
  $stateProvider

    // Nutzer-Liste
    .state("root.nutzerdatensuche", {
      url: "/nutzerdatensuche/",
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-nutzer-daten-suche-list></vue-nutzer-daten-suche-list></div>"
        }
      },
      data: {
        permissions: ["nutzerdatensuche.view"]
      }
    });
});

export default nutzerdatensucheModule;
