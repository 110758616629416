"use strict";

import TemplateServices from "./services";

const diasTemplate = angular.module("dias.dias_template", [
  TemplateServices.name
]).config($stateProvider => {
  $stateProvider
    .state("root.templateadmin", {
      url: `/templates/`,
      views: {
        "main@root": {
          template: "<div vueinit><template-admin-list></template-admin-list></div>"
        }
      },
    })
    .state("root.templateadmin.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><template-admin-details></template-admin-details></div>"
        }
      },
    });
});

export default diasTemplate;
