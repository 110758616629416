import BetragsrechnerMixin from "../Mixins/BetragsrechnerMixin";
import {
  cloneDeep,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "Rolliplaetzerechner",
  mixins: [
    BetragsrechnerMixin,
  ],
  emits: [
    "changeModel",
  ],
  data() {
    return {
      labelAnzahlPlaetze: "_LBL_ROLLIPLAETZERECHNER_ANZAHL_PLAETZE_",
    };
  },
  computed: {
    optionsAnzahlPlaetze() {
      return {
        id: "anzahl_plaetze",
        htmlId: this.htmlIdAnzahlPlaetze,
        label: this.labelAnzahlPlaetze,
        view: this.view,
        type: "text",
        required: true,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdAnzahlPlaetze() {
      return `${ this.htmlId }_anzahl_plaetze`;
    },

    optionsBetrag() {
      return {
        id: "betrag",
        htmlId: `${ this.htmlId }_betrag`,
        label: "_LBL_ROLLIPLAETZERECHNER_BETRAG_",
        view: this.view,
        type: "text",
        required: false,
        disabled: true,
        helpText: this.vorschau.bezeichnung || "-",
        classesHorizontal: this.classesHorizontal,
      };
    },

    errorLabels() {
      return {
        anzahl_plaetze: {
          label: this.labelAnzahlPlaetze,
          link: true,
          id: this.htmlIdAnzahlPlaetze,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
    this.setPauschaleForModel();
  },
  methods: {
    setPauschaleForModel() {
      if (this.model.argument.pauschale) {
        return;
      }
      const MODEL = cloneDeep(this.model);
      const PAUSCHALE = get(this.extra, "modelParameter.pauschale", 20000);
      MODEL.argument.pauschale = PAUSCHALE;
      this.$emit("changeModel", { model: MODEL });
    },
  },
};
