// Stellt einen Service bereit, um programmatisch einen Bestätigungsdialog
// zu erzeugen.

"use strict";

import template from "../modal.jade";
import controller from "../modal.controller";

class ModalFactory {
  /*@ngInject*/
  $get($rootScope, $q, $modal) {
    return params => {
      const scope = $rootScope.$new();
      scope.modalTitle = params.title;
      scope.modalShowClose = params.showClose;
      scope.modalTemplate = params.template;
      scope.modalController = params.controller;
      scope.modalCls = params.cls;
      scope.extras = params.extras;
      scope.responseScope = scope;
      const modalInstance = $modal({
        scope: scope,
        template: template(),
        controller: controller,
        controllerAs: "modal",
        bindToController: true,
        backdrop: "static",
      });
      const modalDefer = $q.defer();
      scope.modalInstance = modalInstance;
      scope.$on("modal.hide.before", () => {
        modalDefer.resolve(scope.response);
      });
      return modalDefer.promise;
    };
  }
}


export default ModalFactory;
