import {
  computed,
  ref,
  toRef,
} from "vue";

import HttpAPI from "../../../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../../../global/compositionAPI/NotificationAPI";

import {
  initModelFromList,
  toFormElementFromParameter,
} from "../../../../../../global/functions/mappingForParameterToFormElement";
import {
  cloneDeep,
  keyBy,
  merge,
} from "lodash-es";

export default function SitzungsartenModuleAPI(props, {
  model = ref({})
}) {
  const isCreate = toRef(props, "isCreate");

  const modelParameter = ref({});
  const sitzungsmodulKatalog = ref([]);
  const sitzungsmodulKatalogKeyByPk = ref({});
  const sitzungsmodulKatalogLoading = ref(true);

  const currentSitzungModul = computed(() => {
    return sitzungsmodulKatalogKeyByPk.value[model.value.modul];
  });

  const parameterList = ref([]);
  const setParameterList = () => {
    parameterList.value = toFormElementFromParameter({
      obj: currentSitzungModul.value.ssm_parameterin,
      showLabel: true,
      staticOptionsForType: {
        multiselect: {
          selectAll: true,
          deselectAll: true,
        },
      },
    }).list;
  };
  const setModelParameter = () => {
    modelParameter.value = initModelFromList({ list: parameterList.value });
  };

  const onSelectSitzungsmodul = () => {
    setParameterList();
    setModelParameter();
  };

  const optionsModul = computed(() => {
    return {
      type: "select",
      label: "_TXT_SITZUNGSART_TAGESORDNUNGSPUNKT_MODUL_",
      required: true,
      view: "v",
      id: "modul",
      keyId: "pk",
      keyLabel: "ssm_bez",
      search: true,
      searchList: ["ssm_bez"],
      data: sitzungsmodulKatalog.value,
      loading: sitzungsmodulKatalogLoading.value,
      change: onSelectSitzungsmodul,
      disabled: !isCreate.value,
    };
  });

  const modul = toRef(props, "modul");
  const {
    getHttp,
  } = HttpAPI();
  const {
    addNotification,
  } = NotificationAPI();
  const loadSitzungsmodulKatalog = () => {
    getHttp({
      url: "katalog/",
      urlParams: {
        key: "sitzungsmodule",
      },
      apiSaveId: "sitzungsmodule",
    }).then(
      response => {
        sitzungsmodulKatalog.value = response;
        sitzungsmodulKatalogKeyByPk.value = keyBy(response, "pk");
        if (!isCreate.value) {
          model.value = cloneDeep(modul.value);

          setParameterList();
          setModelParameter();
          modelParameter.value = merge({}, modelParameter.value, cloneDeep(modul.value.model));
        }
      },
      () => {
        addNotification({
          text: "_MSG_SITZUNGSART_MODULE_ERROR_",
          type: "error",
        });
      }
    ).finally(() => {
      sitzungsmodulKatalogLoading.value = false;
    });
  };

  return {
    currentSitzungModul,
    loadSitzungsmodulKatalog,
    modelParameter,
    optionsModul,
    parameterList,
  };
}
