import {
  getCurrentInstance,
  toRef,
} from "vue";

import ContextPanel from "../../../../../../../libs/vue/client/vue/global/components/ContextPanel/ContextPanel.vue";
import GeschaeftsregelModul from "../../../../../../../libs/vue/client/vue/components/Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";
import SystemakkreditierungDetailsAkkreditierung from "../SystemakkreditierungDetailsAkkreditierung/SystemakkreditierungDetailsAkkreditierung.vue";
import SystemakkreditierungDetailsHochschule from "../SystemakkreditierungDetailsHochschule/SystemakkreditierungDetailsHochschule.vue";
import SystemakkreditierungDetailsVersionOptions from "../SystemakkreditierungDetailsVersionOptions/SystemakkreditierungDetailsVersionOptions.vue";
import PuxCloak from "../../../../../../../libs/vue/client/vue/global/components/PuxCloak/PuxCloak.vue";

import AkkreditierungItemAPI from "../../../compositionAPI/AkkreditierungItemAPI";

// @vue/component
export default {
  name: "SystemakkreditierungDetailsItem",
  components: {
    ContextPanel,
    GeschaeftsregelModul,
    SystemakkreditierungDetailsAkkreditierung,
    SystemakkreditierungDetailsHochschule,
    SystemakkreditierungDetailsVersionOptions,
    PuxCloak,
  },
  props: {
    akkreditierung: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "versionenLoaded",
  ],
  setup(props, { emit }) {
    const APP = getCurrentInstance();
    const $stateParams = APP.appContext.config.globalProperties.$stateParams;
    const akkreditierungHistoriePk = $stateParams.id;

    const {
      activateAkkr,
      canEditAkkr,
      canUpdate,
      changeDisplayFor,
      commentUngueltig,
      createVersion,
      deleteVersion,
      displayVersion,
      documentConfig,
      initDisplay,
      loading,
      onSaveHochschule,
      onSaveAkkreditierung,
      programmAkkreditierungUrl,
      publishVersion,
      saving,
      selectedVersion,
      versionen,
    } = AkkreditierungItemAPI({
      akkreditierung: toRef(props, "akkreditierung"),
      baseUrl: "systemakkreditierungen",
      emit,
      akkreditierungHistoriePk,
      basePermission: "systemakkreditierung",
    });

    initDisplay();

    return {
      activateAkkr,
      canEditAkkr,
      canUpdate,
      changeDisplayFor,
      commentUngueltig,
      createVersion,
      deleteVersion,
      displayVersion,
      documentConfig,
      initDisplay,
      loading,
      onSaveHochschule,
      onSaveAkkreditierung,
      programmAkkreditierungUrl,
      publishVersion,
      saving,
      selectedVersion,
      versionen,
    };
  },
  data() {
    return {
      dokumentePermissions: {
        view: "systemakkreditierung.dokumente.view",
        create: "systemakkreditierung.dokumente.create",
        delete: "systemakkreditierung.dokumente.delete",
        update: "systemakkreditierung.dokumente.update",
        update_gueltigkeit: "",
      },
    };
  },
};
