"use strict";

import BetragsrechnerRegistry from "dias/kosten_finanzierung/betragsrechner/services";

import BetragsrechnerStueckkosten from "./betragsrechner.js";
import BetragsrechnerStueckkostenController from "./controller";

const BetragsrechnerStueckkostenModul = angular.module("dias.betragsrechner.stueckkosten", [
  BetragsrechnerRegistry.name,
]);

BetragsrechnerStueckkostenModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("stueckkosten", BetragsrechnerStueckkosten);
})
  .component("stueckkosten", BetragsrechnerStueckkostenController);

export default BetragsrechnerStueckkostenModul;
