import {
  ref,
} from "vue";

import {
  EventBus,
} from "../../../../global/functions/event-bus";
import {
  initModelFromList,
  toFormElementFromParameter,
} from "../../../../global/functions/mappingForParameterToFormElement";
import {
  assign,
  cloneDeep,
  pick,
} from "lodash";

export default function GeschaeftsregelModuleAPI({
  regel = ref({}),
  regelUrl = "",
}) {
  const listConfig = ref([]);
  const modelConfig = ref({});

  const moduleVorbelegungUrl = `${ regelUrl }defaultwerte_aendern/`;

  const updateVorbelegungModule = ({ response }) => {
    regel.value.userdefault = response;
  };

  const setListConfig = ({ regel }) => {
    listConfig.value = toFormElementFromParameter({
      obj: regel.re_argument,
      showLabel: true,
      staticOptionsForType: {
        dyn_tabelle_objektkatalog_data: {
          dependency: "spalten",
        },
        dyn_tabelle_spaltenauswahl_radio: {
          dependency: "spalten",
        },
      },
    }).list;
  };

  const setModelConfig = () => {
    modelConfig.value = initModelFromList({ list: listConfig.value });
  };

  const updateRegelModule = ({ regelLocal }) => {
    setListConfig({ regel: regelLocal });
    setModelConfig();
    const REGEL = cloneDeep(regel.value);
    assign(REGEL, pick(regelLocal, ["re_argument", "modul", "modul_bez", "modul_obj", "userdefault"]));
    regel.value = REGEL;
    EventBus.$emit("updateVorbelegung");
  };

  return {
    listConfig,
    modelConfig,
    moduleVorbelegungUrl,
    setModelConfig,
    setListConfig,
    updateRegelModule,
    updateVorbelegungModule,
  };
}
