"use strict";

import builder from "../utils/builder.js";

import template from "./preview.jade";
import BasePreviewController from "./../standard_sitzungsmodule.preview.controller";

class AntragslisteMittellosPreviewController extends BasePreviewController {
  /*@ngInject*/
  constructor($scope, $element) {
    super($scope, $element);
  }

  getContainerClass() {
    return "typ";
  }
}

export default builder.preview({
  template: template(),
  controller: AntragslisteMittellosPreviewController,
  controllerAs: "vm"
});
