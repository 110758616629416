import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import {
  cloneDeep,
  findIndex,
} from "lodash-es";

// @vue/component
export default {
  name: "ThemenauswahlCheckboxOrRadio",
  components: {
    FormElement,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    mehrfachauswahl: {
      type: Boolean,
      required: true,
    },
    htmlRegelNummer: {
      type: String,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    updateModel: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      freitext: null,
    };
  },
  computed: {
    checkboxOrRadio() {
      return this.mehrfachauswahl ? "checkbox" : "radio";
    },

    idCheckbox() {
      return `${ this.htmlRegelNummer }_check_${ this.data.pk }`;
    },

    nameCheckbox() {
      return `${ this.htmlRegelNummer }_check`;
    },

    indexChecked() {
      return findIndex(this.model.wahl, ["pk", this.data.pk]);
    },

    statusChecked() {
      return this.indexChecked !== -1;
    },

    ariaChecked() {
      return `${ this.statusChecked }`;
    },

    optionsFreitext() {
      return {
        type: "text",
        id: "freitext",
        htmlId: `${ this.htmlRegelNummer }_freitext_${ this.data.pk }`,
        placeholder: "_TXT_GR_THEMENAUSWAHL_FEITEXT_PLACEHOLDER_",
        translate: true,
        change: this.changeFreitext,
      };
    },
  },
  created() {
    this.setFreitext();
  },
  methods: {
    setFreitext() {
      if (!this.statusChecked) {
        return;
      }
      this.freitext = this.model.wahl[this.indexChecked].freitext || null;
    },

    clickCheckbox() {
      const MODEL = cloneDeep(this.model);
      if (this.mehrfachauswahl) {
        if (this.statusChecked) {
          MODEL.wahl.splice(this.indexChecked, 1);
        } else {
          MODEL.wahl.push({
            freitext: this.freitext,
            pk: this.data.pk,
          });
        }
      } else {
        MODEL.wahl = [
          {
            freitext: this.freitext,
            pk: this.data.pk,
          }
        ];
      }
      this.updateModel({ model: MODEL });
    },

    changeFreitext() {
      const MODEL = cloneDeep(this.model);
      MODEL.wahl.splice(this.indexChecked, 1, {
        freitext: this.freitext,
        pk: this.data.pk,
      });
      this.updateModel({ model: MODEL });
    },
  },
};
