var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-element"] = jade_interp = function(label, fieldName){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div class=\"form-group row\"><label" + (jade.attr("ng-attr-for", "" + (fieldName) + "", true, true)) + " class=\"col-form-label col-sm-3 text-right font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span></label><div class=\"col-sm-8\">");
block && block();
buf.push("</div></div>");
};
buf.push("<script type=\"text/ng-template\" id=\"foerderdokumenteMatchTemplate.html\"><!--span(ng-bind=\"vm.selected.fod_name\")--></script><script type=\"text/ng-template\" id=\"foerderdokumenteChoicesTemplate.html\"><span class=\"label-hidden\">Wählen Sie bitte eine Antwort</span><span ng-bind=\"item.fod_name\"></span></script><fieldset dias-cloak=\"vm.loadingEditData\"><div ng-attr-id=\"{{ vm.errorContainerId }}\"></div><div ng-repeat=\"dokPk in vm.dokumenteList\" class=\"row\"><div class=\"col-lg-12\"><div class=\"foerder-regel\"><div><span style=\"max-width:80%; display:inline-block;\"><strong>{{$index + 1}} {{ vm.dokumenteObj[dokPk].fod_name }}</strong></span><div class=\"float-right\"><button ng-click=\"vm.deleteDokument(dokPk, $index)\" title=\"Dokument enfernen\" class=\"btn btn-xs btn-primary\"><i class=\"glyphicon glyphicon-trash\"></i><span class=\"sr-only\">Dokument enfernen</span></button><span>&nbsp;</span><div class=\"btn-group\"><button ng-click=\"vm.moveUp(dokPk, $index)\" title=\"Nach oben schieben\" ng-disabled=\"$index === 0\" class=\"btn btn-xs btn-default\"><i class=\"glyphicon glyphicon-chevron-up\"></i><span class=\"sr-only\">Dokument nach oben schieben</span></button><button ng-click=\"vm.moveDown(dokPk, $index)\" title=\"Nach unten schieben\" ng-disabled=\"$last\" class=\"btn btn-xs btn-default\"><i class=\"glyphicon glyphicon-chevron-down\"></i><span class=\"sr-only\">Dokument nach unten schieben</span></button></div></div></div><hr></div></div></div><div class=\"row\"><div class=\"col-lg-12\">");
jade_mixins["form-element"]("Förderdokumente", "foerderdokumente");
buf.push("<div dias-cloak=\"vm.loading\"><dias-ui-select on-select=\"initPlaceholder();vm.selectDokument()\" select-model=\"vm.modelDokumente\" select-required=\"true\" select-list=\"vm.dokumente\" select-label-id=\"'pk'\" select-id=\"'foerderdokumente'\" select-placeholder=\"'Wählen Sie die Foerderdokumente aus.'\" select-search=\"true\" search-label=\"'fod_name'\" select-choices-template=\"'foerderdokumenteChoicesTemplate.html'\" select-match-template=\"'foerderdokumenteMatchTemplate.html'\"></dias-ui-select></div></div></div></fieldset><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save, vm.errorsLabel)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Abbrechen</span></button></div></div>");;return buf.join("");
}