"use strict";

import template from "./spaced.jade";

class DiasSpacedController {
  /*@ngInject*/
  constructor($sce) {
    this.$sce = $sce;
  }
}

export default {
  template: template(),
  controller: DiasSpacedController,
  controllerAs: "vm",
  bindings: {
    model: "<ngModel",
  }
};
