"use strict";

class ParametermoduleController {
  /*@ngInject*/
  constructor($q, $element, $scope, $compile, ParametermoduleRegistry) {
    this.ParametermoduleRegistry = ParametermoduleRegistry;
    this.$compile = $compile;
    this.$element = $element;
    this.$scope = $scope;
    this.loading = true;
    this.readonly = this.readonly === "true";
    this.create();
  }

  create() {
    const foo = this.buildTemplate(this.readonly, () => {});
    if (foo === undefined) {
      return;
    }
    const el = this.$compile(foo)(this.$scope);
    this.$element.append(el);
  }

  buildTemplate(readonly, rejectFn) {
    let errMsg;
    if (this.parameter[this.key] === undefined || this.parameter[this.key].typ === undefined) {
      errMsg = "Angegebenes Argument existiert nicht oder hat keinen Typ definiert.";
      rejectFn(errMsg);
      return;
    }

    const modul = this.ParametermoduleRegistry.getParametermodule(this.parameter[this.key].typ);
    if (modul === undefined) {
      errMsg = "Parametermodul " + this.parameter[this.key].typ + " nicht gefunden";
      console.error(errMsg);
      rejectFn(errMsg);
      return;
    }
    const tpl = `<${ modul }${ readonly ? "-ro" : "" }${ this.change ? ` change="vm.change()"` : "" } parameter="vm.parameter" key="${ this.key }"${ this.controlId ? ` control-id="${ this.controlId }"` : "" }>`;
    return tpl;
  }
}

export default {
  bindings: {
    parameter: "=",
    key: "<",
    controlId: "@?",
    readonly: "@",
    change: "&?",
  },
  template: "",
  controller: ParametermoduleController,
  controllerAs: "vm"
};
