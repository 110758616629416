"use strict";

import angular from "angular";
import diasLoading from "dias/core/loading";
import parametermodul from "./parameter/";

const themen_modul = angular.module("dias.themen", [
  diasLoading.name,
  parametermodul.name,
]);

export default themen_modul;
