import AsideRightBox from "../../../global/components/AsideRightBox/AsideRightBox.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import {
  find,
  get,
  indexOf,
  isNil,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "FormattedAntragScore",
  components: {
    AsideRightBox,
    PuxCloak,
    PuxTranslate,
  },
  mixins: [
    SyConfigMixin,
  ],
  props: {
    showTitle: {
      type: Boolean,
      default: () => true
    },
    scoreText: {
      type: String,
      required: false,
      default: undefined,
    },
    scoreNumber: {
      type: String,
      required: false,
      coerce: function(val) {
        return Number(val);
      },
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      filterCurrency,
    };
  },
  data: () => {
    return {
      conf: {},
      optionsAsideRightBox: {
        active: true,
        hideHeader: true,
      },
    };
  },
  computed: {
    showNumber() {
      return !isNil(this.scoreNumber);
    },

    stylesNumber() {
      if (!this.showNumber || !size(this.conf.number_formatting)) {
        return "";
      }
      const CONF = this.conf.number_formatting || [];
      const CONF_EL = find(CONF, el => (
        (isNil(el.min) || this.scoreNumber >= el.min)
        && (isNil(el.max) || this.scoreNumber <= el.max)
      ));
      return get(CONF_EL, "styles", "");
    },

    formattedNumber() {
      return this.filterCurrency(this.scoreNumber, "", this.conf.decimal || 0);
    },

    showText() {
      return !isNil(this.scoreText);
    },

    stylesText() {
      if (!this.showText || !size(this.conf.text_formatting)) {
        return "";
      }
      const CONF = this.conf.text_formatting || [];
      const CONF_EL = find(CONF, el => (
        isNil(el.values) || indexOf(el.values, this.scoreText) !== -1
      ));
      return get(CONF_EL, "styles", "");
    },

    showDivider() {
      return this.divider && this.showNumber && this.showText;
    },

    divider() {
      return this.conf.divider_content;
    },
  },
  created() {
    this.checkSyConfig();
  },
  methods: {
    checkSyConfig() {
      this.conf = this.getSyConfigsValue("antrag_score") || {};
    },
  },
};
