import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import {
  FilterCurrencyMixin,
} from "../../../../../global/mixins/FiltersMixin";

import {
  get,
  isNil,
  keys,
  keyBy,
  round,
  toNumber,
} from "lodash-es";

// @vue/component
export default {
  name: "KofiErfassungModulBetragWarningIcon",
  components: {
    PuxIcon,
  },
  mixins: [
    FilterCurrencyMixin,
  ],
  props: {
    betragKey: {
      type: String,
      required: true,
      info: "der Schlüssel des Betrags, kann 'wert', 'erfasst' oder 'anerkannt' sein",
    },
    kofi: {
      type: Object,
      required: true,
      info: "Hauptinfo",
    },
    koFiStatuses: {
      type: Object,
      required: true,
      info: "erfasst, anerkannt, bewilligt"
    },
    modelParameter: {
      type: Object,
      required: true,
      info: "Parameter der Regel",
    },
    periodePk: {
      type: String,
      required: false,
      default: undefined,
      info: "UUID der Periode",
    },
    relevantePerioden: {
      type: Array,
      required: false,
      default: undefined,
      info: "Alle für die Auszahlung relevanten Perioden",
    },
    gesamtSumPeriode: {
      type: Object,
      required: false,
      default: undefined,
      info: "Gesamte Zahlen der Periode"
    }
  },
  computed: {
    periodenObjekt() {
      if (this.relevantePerioden) {
        return keyBy(this.relevantePerioden, "pk");
      }
      return {};
    },

    periodeSum() {
      if (this.periodenObjekt[this.periodePk]) {
        return get(this.kofi.perioden_sum[this.periodePk], "wert", 0);
      }
      return -1;
    },

    periodeErfasst() {
      if (this.periodenObjekt[this.periodePk]) {
        return get(this.kofi.perioden_sum[this.periodePk], "betrag_erfasst", 0);
      }
      return -1;
    },

    periodeAnerkannt() {
      if (this.periodenObjekt[this.periodePk]) {
        return get(this.kofi.perioden_sum[this.periodePk], "betrag_anerkannt", 0);
      }
      return -1;
    },

    erfasstOderAnerkannt() {
      return this.betragKey.includes("erfasst") || this.betragKey.includes("anerkannt");
    },

    aktuellerBetrag() {
      if (this.betragKey.includes("erfasst")) {
        return this.periodeErfasst >= 0 ? this.periodeErfasst : this.kofi.erfasst;
      }
      return this.periodeAnerkannt >= 0 ? this.periodeAnerkannt : this.kofi.anerkannt;
    },

    bewilligtBetrag() {
      return this.periodeSum >= 0 ? this.periodeSum : this.kofi.bewilligt;
    },

    bewilligtGesamt() {
      if (isNil(this.gesamtSumPeriode)) {
        return get(this.koFiStatuses, "bewilligt", 0);
      }
      return get(this.gesamtSumPeriode, "wert", 0);
    },

    aktuellerBetragGesamt() {
      if (isNil(this.gesamtSumPeriode)) {
        return get(this.koFiStatuses, this.betragKey, 0);
      }
      return get(this.gesamtSumPeriode, this.betragKey.includes("erfasst") ? "betrag_erfasst" : "betrag_anerkannt", 0);
    },

    bewilligtBetragGesamtAnteilProzent() {
      if (this.bewilligtGesamt !== 0) {
        return round((this.bewilligtBetrag / this.bewilligtGesamt) * 100, 2);
      }
      return 0;
    },

    betragGesamtAnteilProzent() {
      if (this.aktuellerBetragGesamt !== 0) {
        return round((this.aktuellerBetrag / this.aktuellerBetragGesamt) * 100, 2);
      }
      return 0;
    },

    maxReduzierungProzent() {
      if (!isNil(this.modelParameter.max_reduzierung_prozent)) {
        return this.modelParameter.max_reduzierung_prozent;
      }
      return this.$gettext("_TXT_KOFI_MODULN_PARAMETER_NICHT_BERECHNBAR_");
    },

    maxReduzierungEuro() {
      if (!isNil(this.modelParameter.max_reduzierung_euro)) {
        return this.modelParameter.max_reduzierung_euro;
      }
      return this.$gettext("_TXT_KOFI_MODULN_PARAMETER_NICHT_BERECHNBAR_");
    },

    maxErhoehungEuro() {
      if (!isNil(this.modelParameter.max_erhoehung_euro)) {
        return this.modelParameter.max_erhoehung_euro;
      }
      return this.$gettext("_TXT_KOFI_MODULN_PARAMETER_NICHT_BERECHNBAR_");
    },

    maxErhoehungProzent() {
      if (!isNil(this.modelParameter.max_erhoehung_prozent)) {
        return this.modelParameter.max_erhoehung_prozent;
      }
      return this.$gettext("_TXT_KOFI_MODULN_PARAMETER_NICHT_BERECHNBAR_");
    },

    veranderungProzent() {
      if (this.bewilligtBetrag !== 0) {
        return round(((this.aktuellerBetrag / this.bewilligtBetrag) - 1) * 100, 2).toFixed(2);
      }
      return this.$gettext("_TXT_KOFI_MODULN_PARAMETER_NICHT_BERECHNBAR_");
    },

    veranderungEuro() {
      return this.aktuellerBetrag - this.bewilligtBetrag;
    },

    veranderungGesamtProzent() {
      if (this.betragGesamtAnteilProzent && this.bewilligtBetragGesamtAnteilProzent) {
        return (this.betragGesamtAnteilProzent - this.bewilligtBetragGesamtAnteilProzent).toFixed(2);
      }
      return 0;
    },

    maxReduzierungAnteilgesamt() {
      if (!isNil(this.modelParameter.max_reduzierung_anteilgesamt)) {
        return this.modelParameter.max_reduzierung_anteilgesamt;
      }
      return this.$gettext("_TXT_KOFI_MODULN_PARAMETER_NICHT_BERECHNBAR_");
    },

    maxErhoehungAnteilgesamt() {
      if (!isNil(this.modelParameter.max_erhoehung_anteilgesamt)) {
        return this.modelParameter.max_erhoehung_anteilgesamt;
      }
      return this.$gettext("_TXT_KOFI_MODULN_PARAMETER_NICHT_BERECHNBAR_");
    },

    betragProzent() {
      if (this.bewilligtBetrag !== 0) {
        return round((this.aktuellerBetrag / this.bewilligtBetrag) * 100, 2).toFixed(2);
      }
      return this.$gettext("_TXT_KOFI_MODULN_PARAMETER_NICHT_BERECHNBAR_");
    },

    isAlertIconVisible() {
      return this.erfasstOderAnerkannt && this.aktuellerBetrag > 0 && (
        (this.modelParameter.max_reduzierung_euro && toNumber(this.veranderungEuro < 0) && toNumber(this.modelParameter.max_reduzierung_euro) < Math.abs(this.veranderungEuro)) ||
        (this.modelParameter.max_reduzierung_prozent && toNumber(this.veranderungProzent) < 0 && this.modelParameter.max_reduzierung_prozent < Math.abs(this.veranderungProzent)) ||
        (this.modelParameter.max_erhoehung_euro && toNumber(this.modelParameter.max_erhoehung_euro) < toNumber(this.veranderungEuro)) ||
        (this.modelParameter.max_erhoehung_prozent && toNumber(this.modelParameter.max_erhoehung_prozent) < toNumber(this.veranderungProzent)) ||
        (this.modelParameter.max_reduzierung_anteilgesamt && toNumber(this.veranderungGesamtProzent) < 0 && toNumber(this.modelParameter.max_reduzierung_anteilgesamt) < Math.abs(this.veranderungGesamtProzent)) ||
        (this.modelParameter.max_erhoehung_anteilgesamt && toNumber(this.modelParameter.max_erhoehung_anteilgesamt) < toNumber(this.veranderungGesamtProzent))
      );
    },

    hinweisTextParameter() {
      return {
        max_reduzierung_euro: this.filterCurrency(this.maxReduzierungEuro, ""),
        max_reduzierung_prozent: this.maxReduzierungProzent.toString().replace(".", ","),
        max_erhoehung_euro: this.filterCurrency(this.maxErhoehungEuro, ""),
        max_erhoehung_prozent: this.maxErhoehungProzent.toString().replace(".", ","),
        max_reduzierung_anteilgesamt: this.maxReduzierungAnteilgesamt.toString().replace(".", ","),
        max_erhoehung_anteilgesamt: this.maxErhoehungAnteilgesamt.toString().replace(".", ","),
        veraenderung_prozent: this.veranderungProzent.toString().replace(".", ","),
        veraenderung_euro: this.filterCurrency(this.veranderungEuro, ""),
        betrag_bewilligt_euro: this.filterCurrency(this.bewilligtBetrag, ""),
        betrag_euro: this.filterCurrency(this.aktuellerBetrag, ""),
        betrag_prozent: this.betragProzent.toString().replace(".", ","),
      };
    },

    hinweisText() {
      let tooltipString = this.modelParameter.ueberschreitung_hinweis;
      keys(this.hinweisTextParameter).forEach(key => {
        tooltipString = tooltipString.replaceAll(`{{${ key }}}`, this.hinweisTextParameter[key]);
      });
      return tooltipString;
    },
  },
};
