import {
  isNil,
} from "lodash-es";

export default {
  computed: {
    digits_UiFloatMixin() {
      if (!isNil(this.options.digits)) {
        return this.options.digits;
      }
      if (!isNil(this.options.decimal_places)) {
        return this.options.decimal_places;
      }
      return 2;
    },

    suffixOption_UiFloatMixin() {
      return this.options.suffix || null;
    },

    suffix_UiFloatMixin() {
      return !isNil(this.suffixOption_UiFloatMixin) ? ` ${ this.suffixOption_UiFloatMixin }` : "";
    },

    prefixOption_UiFloatMixin() {
      return this.options.prefix || null;
    },

    prefix_UiFloatMixin() {
      return !isNil(this.prefixOption_UiFloatMixin) ? `${ this.prefixOption_UiFloatMixin } ` : "";
    },
  },
};
