import KofiErfassungBeleg from "../KofiErfassungBeleg/KofiErfassungBeleg.vue";
import KofiErfassungPositionForm from "../KofiErfassungPositionForm/KofiErfassungPositionForm.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";

import KofiErfassungPositionMixin from "../Mixins/KofiErfassungPositionMixin";

import KAuszahlungBelegAnzeigeModus from "../../../../const/KAuszahlungBelegAnzeigeModus";

import {
  cloneDeep,
  get,
  isFunction,
  size,
} from "lodash-es";

// @vue/component
export default {
  components: {
    KofiErfassungBeleg,
    KofiErfassungPositionForm,
    Modal,
  },
  mixins: [
    KofiErfassungPositionMixin,
  ],
  data() {
    return {
      statusEditPosition: false,
      statusShowAllBelegeLocal: false,
      selectorClose: undefined,
      confirmOptions: undefined,
      confirmShow: undefined,
      showPeriodenTable: false,
    };
  },
  computed: {
    keyPositionBetragBewilligt() {
      return `${ this.prefixCurrent.position }_betrag_bewilligt`;
    },

    keyPositionBetragErfasst() {
      return `${ this.prefixCurrent.position }_betrag_erfasst`;
    },

    keyPositionBetragAnerkannt() {
      return `${ this.prefixCurrent.position }_betrag_anerkannt`;
    },

    positionBetragBewilligtCurrency() {
      // TODO: Prüfen, ob man das benutzt
      return this.filterCurrency(this.position[this.keyPositionBetragBewilligt]);
    },

    positionBetragErfasstFilterView() {
      // TODO: Prüfen, ob man das benutzt
      return this.filterView(this.position.erfasst, this.position[this.keyPositionBetragErfasst], this.auszahlung);
    },

    positionBetragAnerkanntFilterView() {
      // TODO: Prüfen, ob man das benutzt
      return this.filterView(this.position.anerkannt, this.position[this.keyPositionBetragAnerkannt], this.auszahlung);
    },

    buttonEditId() {
      return `${ this.htmlIdLocal }_edit`;
    },

    buttonDeleteId() {
      return `${ this.htmlIdLocal }_delete`;
    },

    buttonEditTooltip() {
      if (this.statusEditPosition) {
        return "_BTN_KF_POSITION_EDIT_OPEN_TITLE_";
      }
      return "_BTN_KF_POSITION_EDIT_TITLE_";
    },

    buttonDeleteTooltip() {
      return "_BTN_KF_POSITION_DELETE_TITLE_";
    },

    statusShowButtonDelete() {
      return this.position.belege &&
        this.position.belege.length === 0 &&
        this.position[this.keyPositionBetragBewilligt] === "0.00";
    },

    formExtraTranslate() {
      const EXTRA_TRANSLATE = cloneDeep(this.extraTranslate);
      EXTRA_TRANSLATE.position = this.positionNumber;
      return EXTRA_TRANSLATE;
    },

    belegeAnzeigeModus() {
      return get(this.auszahlungsplan, "zp_modalitaet.beleg_anzeige", KAuszahlungBelegAnzeigeModus.RELEVANT);
    },

    statusShowButtonToggleAllBelege() {
      return this.syConfigsAuszahlung.alte_belege_ausblendbar
        && this.relevanteAuszahlungen.length > 1
        && this.belegeAnzeigeModus === KAuszahlungBelegAnzeigeModus.RELEVANT;
    },

    statusShowAllBelege() {
      return this.statusShowAllBelegeLocal || this.belegeAnzeigeModus === KAuszahlungBelegAnzeigeModus.ALL;
    },

    buttonToggleAllBelegeId() {
      return `${ this.htmlIdLocal }_toogle_all_belege`;
    },

    buttonLinkToBelegeId() {
      return `${ this.htmlIdLocal }_link_to_belege`;
    },

    buttonToggleAllBelegeText() {
      return this.statusShowAllBelegeLocal ?
        "_BTN_AKTUELLE_BELEGE_ANZEIGEN_" :
        "_BTN_ALLE_BELEGE_ZEIGEN_";
    },

    statusShowPeriodenTable() {
      return size(this.relevantePerioden) > 0 || size(this.position.posperioden) > 0;
    },

    isPeriodsVertrag() {
      return get(this.extraKF, "modelParameter.perioden_vertrag", true);
    },
  },
  methods: {
    toggleEdit() {
      this.statusEditPosition = !this.statusEditPosition;
      this.onOpenModalLocal();
    },

    togglePeriodenTable() {
      this.showPeriodenTable = !this.showPeriodenTable;
      this.onOpenModalLocal();
    },

    openDelete() {
      this.selectorClose = [
        `#${ this.buttonDeleteId }`,
        `#${ this.htmlId }`,
      ];
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "Position löschen?",
        msg: "<p>Sind Sie sicher, dass Sie diese Position löschen wollen?</p>",
        okLabel: "_BTN_DELETE_",
        okCallback: this.deletePosition,
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.confirmShow = true;
      this.onOpenModalLocal();
    },

    deletePosition() {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: this.urlSaveLocal,
      }).then(
        () => {
          this.statusEditPosition = false;
          this.closeConfirm();
          this.$attrs.onReloadKofi();
        }
      ).then(
        () => {
          this.confirmOptions.loading = false;
        }
      );
    },

    closeConfirm() {
      this.confirmShow = false;
    },

    saveLocal() {
      this.statusEditPosition = false;
      this.$attrs.onReloadKofi();
    },

    closeEditLocal() {
      this.statusEditPosition = false;
    },

    toggleAllBelege() {
      this.statusShowAllBelegeLocal = !this.statusShowAllBelegeLocal;
    },

    onOpenModalLocal() {
      if (isFunction(this.$attrs.onOpenModal)) {
        this.$attrs.onOpenModal();
      }
    }
  },
};
