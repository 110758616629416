var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<!--label.font-weight-bold(ng-class=\"{'has-error' :vm.errors['#{field}']}\")--><label><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<!--span.alert-danger--></span>");
block && block();
buf.push("</label>");
};
jade_mixins["param-form"] = jade_interp = function(model){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div class=\"col-lg-12\"><div class=\"custom-control custom-checkbox\"><input" + (jade.attr("ng-attr-id", "{{ " + (model) + ".pos }}__pflichtfeld", true, true)) + " type=\"checkbox\" name=\"pflichtfeld\"" + (jade.attr("ng-model", "" + (model) + ".pflichtfeld", true, true)) + " class=\"custom-control-input\"><label" + (jade.attr("ng-attr-for", "{{ " + (model) + ".pos }}__pflichtfeld", true, true)) + " class=\"custom-control-label\">Pflichtfeld</label></div></div><div class=\"col-lg-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input" + (jade.attr("ng-attr-id", "{{ " + (model) + ".pos }}__label", true, true)) + " type=\"text\" name=\"label\"" + (jade.attr("ng-model", "" + (model) + ".label", true, true)) + (jade.attr("ng-change", "vm.changeLabel({model: " + ( model ) + "})", true, true)) + " aria-required=\"false\" class=\"form-control no-margin\">");
}
}, "label", "Label");
buf.push("</div><div class=\"col-lg-12\"><label><span class=\"titel\">Technische ID*</span><input" + (jade.attr("ng-attr-id", "{{ " + (model) + ".pos }}__id", true, true)) + " type=\"text\" name=\"id\"" + (jade.attr("ng-model", "" + (model) + ".id", true, true)) + (jade.attr("ng-change", "vm.changeId({model: " + ( model ) + "})", true, true)) + " aria-required=\"true\" class=\"form-control no-margin\"></label></div><div class=\"col-lg-12 d-flex no-padding\"><div class=\"col-lg-4\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input" + (jade.attr("ng-attr-id", "{{ " + (model) + ".pos }}__min", true, true)) + " type=\"number\" name=\"min\"" + (jade.attr("ng-model", "" + (model) + ".min", true, true)) + " class=\"form-control no-margin\">");
}
}, "min", "Min");
buf.push("</div><div class=\"col-lg-4\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input" + (jade.attr("ng-attr-id", "{{ " + (model) + ".pos }}__max", true, true)) + " type=\"number\" name=\"max\"" + (jade.attr("ng-model", "" + (model) + ".max", true, true)) + " class=\"form-control no-margin\">");
}
}, "max", "Max");
buf.push("</div><div class=\"col-lg-4\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input" + (jade.attr("ng-attr-id", "{{ " + (model) + ".pos }}__nachkommastellen", true, true)) + " type=\"number\" name=\"nachkommastellen\"" + (jade.attr("ng-model", "" + (model) + ".nachkommastellen", true, true)) + " class=\"form-control no-margin\">");
}
}, "nachkommastellen", "Nachkommastellen");
buf.push("</div></div><div class=\"col-lg-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input" + (jade.attr("ng-attr-id", "{{ " + (model) + ".pos }}__expression", true, true)) + " type=\"text\" name=\"expression\"" + (jade.attr("ng-model", "" + (model) + ".expression", true, true)) + " aria-required=\"false\" class=\"form-control no-margin\">");
}
}, "expression", "Berechnungsformel");
buf.push("</div><div class=\"col-lg-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input" + (jade.attr("ng-attr-id", "{{ " + (model) + ".pos }}__signal_regex", true, true)) + " type=\"text\" name=\"signal_regex\"" + (jade.attr("ng-model", "" + (model) + ".signal_regex", true, true)) + " aria-required=\"false\" class=\"form-control no-margin\">");
}
}, "signal_regex", "Regulärer Ausdruck für Signalempfang");
buf.push("</div><div class=\"col-lg-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<select" + (jade.attr("ng-attr-id", "{{ " + (model) + ".pos }}__einheit", true, true)) + " name=\"einheit\"" + (jade.attr("ng-model", "" + (model) + ".einheit", true, true)) + " role=\"listbox\" class=\"form-control no-margin\"><option value=\"\"></option><option ng-repeat=\"einheit in vm.einheiten\" value=\"{{einheit}}\">{{einheit}}</option></select>");
}
}, "einheit", "Einheit");
buf.push("</div><div class=\"col-lg-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<textarea" + (jade.attr("ng-attr-id", "{{ " + (model) + ".pos }}__hilfetext", true, true)) + (jade.attr("ng-model", "" + (model) + ".hilfetext", true, true)) + " dias-richtext name=\"hilfetext\" class=\"form-control no-margin\"></textarea>");
}
}, "hilfetext", "Hilfetext");
buf.push("</div><div class=\"col-lg-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"placeholder\"" + (jade.attr("ng-attr-id", "{{ " + (model) + ".pos }}__placeholder", true, true)) + " type=\"text\" name=\"placeholder\"" + (jade.attr("ng-model", "" + (model) + ".placeholder", true, true)) + " class=\"form-control no-margin\">");
}
}, "placeholder", "Placeholder");
buf.push("</div>");
};
buf.push("<div ng-if=\"vm.parameter[vm.key].wert &amp;&amp; vm.parameter[vm.key].wert.length &gt; 0\"><div ng-repeat-start=\"antwort in vm.parameter[vm.key].wert track by $index\" class=\"d-flex\"><div class=\"d-flex align-items-center flex-wrap flex-fill\"><strong>{{ $index + 1 }}. {{ antwort.label?antwort.label:\"[kein Label]\" }}</strong><span ng-if=\"antwort.pflichtfeld\" class=\"ml-1\">*</span><span ng-if=\"antwort.id\" class=\"ml-2\">({{ antwort.id }})</span><small class=\"w-100\"><a href=\"#\" ng-click=\"showDetail = !showDetail\" style=\"white-space:nowrap;\">(Konfiguration {{showDetail?'ausblenden':'anzeigen'}})</a></small></div><div class=\"flex-shrink-0\"><div class=\"btn-group\"><button type=\"button\" title=\"Nach oben schieben\" ng-click=\"vm.moveUpAntwort($index)\" class=\"btn btn-default\"><i class=\"glyphicon glyphicon-chevron-up\"></i><span class=\"sr-only\">Nach oben schieben</span></button><button type=\"button\" title=\"Nach unten schieben\" ng-click=\"vm.moveDownAntwort($index)\" class=\"btn btn-default\"><i class=\"glyphicon glyphicon-chevron-down\"></i><span class=\"sr-only\">Nach unten schieben</span></button><button type=\"button\" title=\"Antwortmöglichkeit entfernen\" dias-confirm confirm-title=\"Antwortmöglichkeit entfernen\" confirm-msg=\"Sind Sie sicher, dass sie die Antwortmöglichkeit von dieser Regel entfernen wollen?\" confirm-type=\"danger\" confirm-ok=\"vm.removeAntwort($index)\" class=\"btn btn-primary\"><i class=\"glyphicon glyphicon-trash\"></i><span class=\"sr-only\">Antwortmöglichkeit löschen</span></button></div></div></div><div ng-show=\"showDetail\" class=\"w-100 d-flex flex-wrap pt-2\">");
jade_mixins["param-form"]("vm.parameter[vm.key].wert[$index]");
buf.push("</div><hr ng-repeat-end></div><div class=\"no-error row\">");
jade_mixins["param-form"]("vm.currentAntwort");
buf.push("<div class=\"col-lg-12 no-padding\"><button type=\"submit\" ng-click=\"vm.addAntwort()\" class=\"btn btn-primary float-right\"><i class=\"glyphicon glyphicon-plus\">&nbsp;</i><span>Antwort hinzufügen</span></button></div><div class=\"clearfix\"></div><hr></div>");;return buf.join("");
}