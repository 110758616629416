"use strict";

import angular from "angular";

const unitChain = ["Bytes", "kB", "MB", "GB", "TB"];

function chainUp(value) {
  return value / 1024;
}
function chainDown(value) {
  return value * 1000;
}
function intLength(value) {
  return Math.ceil(value).toString().length;
}

export default () => {
  return (input, from) => {
    if (angular.isUndefined(input)) {
      return "?? " + unitChain[1];
    }
    let value = Number(input);
    from = from || unitChain[0];
    let curIndex = unitChain.indexOf(from);

    if (value >= 1) {
      while (intLength(value) > 3 && curIndex < (unitChain.length - 1)) {
        value = chainUp(value);
        curIndex++;
      }
    } else {
      while (value < 1 && curIndex > 0) {
        value = chainDown(value);
        curIndex--;
      }
    }

    return Number(value.toFixed(2)).toLocaleString() + " " + unitChain[curIndex];
  };
};
