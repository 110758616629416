import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import TrackingFilter from "../../../Tracking/TrackingFilter/TrackingFilter.vue";

import DashboardModuleMixin from "../DashboardModuleMixin";
import HttpMixin from "../../../../global/mixins/HttpMixin";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "DashboardObjectTracking",
  components: {
    PuxTranslate,
    TrackingFilter,
  },
  mixins: [
    DashboardModuleMixin,
    HttpMixin,
  ],
  props: {
    argument: {
      type: Object,
      required: true,
      validator: argument => {
        const OBJECTS = get(argument, "parameter.objects", []);
        if (OBJECTS.length) {
          return true;
        }
      },
      info: "Hauptinformation",
    },
  },
  data() {
    return {
      classesForBox: {
        edit: "edit-box",
        success: "success-box",
        info: "info help",
      },
    };
  },
  computed: {
    keysForFilterObjects() {
      return this.parameter.objects || [];
    },

    classBox() {
      if (!this.parameter) {
        return;
      }
      return this.classesForBox[this.parameter.css_klasse];
    },
  },
  methods: {
    goToTrackingPage({ objectNumber, objectName }) {
      this.$goTo("root.tracking", {
        object: objectName,
        number: objectNumber,
        objects: this.keysForFilterObjects,
      });
    },
  },
};
