"use strict";

import BetragsrechnerRegistry from "dias/kosten_finanzierung/betragsrechner/services";

import AbhaengigeKostenpauschaleBetragsrechner from "./betragsrechner.js";
import AbhaengigeKostenpauschaleController from "./controller";

const AbhaengigeKostenpauschaleBetragsrechnerModul = angular.module("dias.betragsrechner.AbhaengigeKostenpauschaleBetragsrechnerModul", [
  BetragsrechnerRegistry.name,
]);

AbhaengigeKostenpauschaleBetragsrechnerModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("abhaengige_kostenpauschale_rechner", AbhaengigeKostenpauschaleBetragsrechner);
})
  .component("abhaengigekostenpauschale", AbhaengigeKostenpauschaleController);

export default AbhaengigeKostenpauschaleBetragsrechnerModul;
