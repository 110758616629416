import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "SystemKonfigurationInformationen",
  components: {
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterBoolean,
    } = FiltersAPI();

    return {
      filterBoolean
    };
  },
  data() {
    return {
      options: {
        label: "_TXT_SY_KONFIG_DETAILS_INFORMATIONEN_LABEL_",
      },
    };
  },
};
