import AktualisierterZuschuss from "../AktualisierterZuschuss/AktualisierterZuschuss.vue";
import KFAuszahlungPeriodenModul from "./KFAuszahlungPeriodenModul/KFAuszahlungPeriodenModul.vue";
import KFAuszahlungPeriodenZuschuss from "./KFAuszahlungPeriodenZuschuss/KFAuszahlungPeriodenZuschuss.vue";
import KofiErfassung from "../KofiErfassung/KofiErfassung.vue";
import KofiErfassungModul from "../KofiErfassung/KofiErfassungModul/KofiErfassungModul.vue";
import PeriodsTabs from "../PeriodsTabs/PeriodsTabs.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxGet from "../../../global/components/PuxGet/PuxGet.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTooltip from "../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotItem from "../../Snapshot/SnapshotItem/SnapshotItem.vue";
import SnapshotIcon from "../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import loading from "../../../global/directives/loading";

import HttpMixin from "../../../global/mixins/HttpMixin";
import KFAuszahlungMixin from "./Mixins/KFAuszahlungMixin";
import KFPeriodenModuleMixin from "../Mixins/KFPeriodenModuleMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";
import {
  BelegStatusMixin,
} from "../KofiErfassung/Mixins/KofiErfassungBelegMixin";
import {
  FilterLimitToMixin,
} from "../../../global/mixins/FiltersMixin";
import {
  CheckPermissionsSyncMixin,
} from "../../../global/mixins/PermissionMixin";
import {
  ShowFormatName,
} from "../KofiErfassung/Mixins/KofiErfassungPositionMixin";

import SaveCallbackActions from "../../../const/SaveCallbackActions";

import {
  EventBus,
} from "../../../global/functions/event-bus";
import {
  getDifferenceObjects,
  setNumberToStringInPythonFormat,
  setNumberToStringInPythonFormatJson,
} from "../../../global/functions/utils";
import {
  getZuschussAngepasst,
  getVertragBetrag,
  getStatusShowPositionZuschuss,
  getZuschussanpassungSumme,
} from "../AktualisierterZuschuss/AktualisierterZuschussMixin";
import {
  getVertragBetragValue,
} from "../Module/Mixins/FinanzierugMixin";
import {
  usesZuschussberechnung,
} from "../../Auszahlung/AuszahlungDetails/AuszahlungDetails";
import { createNamespacedHelpers } from "vuex";
import {
  cloneDeep,
  filter,
  findIndex,
  forEach,
  get,
  isEmpty,
  isNil,
  keyBy,
  keys,
  round,
  sortBy,
  values,
} from "lodash-es";

import moment from "moment";

const {
  mapMutations,
} = createNamespacedHelpers("snapshot");

// @vue/component
export default {
  name: "KFAuszahlungPerioden",
  components: {
    AktualisierterZuschuss,
    KFAuszahlungPeriodenModul,
    KFAuszahlungPeriodenZuschuss,
    KofiErfassung,
    KofiErfassungModul,
    PeriodsTabs,
    PuxCloak,
    PuxGet,
    PuxIcon,
    PuxTooltip,
    PuxTranslate,
    SnapshotItem,
    SnapshotIcon,
  },
  directives: {
    loading,
  },
  mixins: [
    BelegStatusMixin,
    CheckPermissionsSyncMixin,
    FilterLimitToMixin,
    HttpMixin,
    KFAuszahlungMixin,
    KFPeriodenModuleMixin,
    ShowFormatName,
    SyConfigMixin,
  ],
  props: {
    vertrag: {
      type: Object,
      required: true,
    },
    auszahlung: {
      type: Object,
      required: true,
    },
    auszahlungsplan: {
      type: Object,
      required: false,
      default: undefined,
    },
    antrag: {
      type: Object,
      required: true,
    },
    infoprovider: {
      type: Object,
      required: false,
      default: undefined,
    },
    reloadStatus: {
      type: Boolean,
      required: false,
    },
    saveCallback: {
      type: Function,
      required: true,
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
    loading: {
      type: Boolean,
      required: false,
      info: "StatusLoading (kofimodule || zuschuss)",
    },
    readonly: {
      type: Boolean,
      required: true,
      info: "Status, mit dem man nichts bearbeiten darf",
    },
    zuschussRelevant: {
      type: Boolean,
      required: false,
      info: "Status zuschussRelevant",
    },
    updateinfoprovider: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      KoFiKosten: [],
      gesamtKosten: {},
      positionenKosten: [],
      KoFiStatusesKosten: {},
      loadingKosten: true,
      KoFiFinanzierung: [],
      gesamtFinanzierung: {},
      positionenFinanzierung: [],
      KoFiStatusesFinanzierung: {},
      loadingFinanzierung: true,
      kofiZuschussKeyByRegelNummer: [],
      // TODO: siehe BelegeModalCreateOrEdit.js:175.
      // Wenn dort dynamisch gemacht wird, muss hier auch
      contextNumber: 4,
      belegDokumenteKosten: [],
      belegDokumenteFinanzierung: [],
      belegDokumenteAnzahl: {},
      statusReloadingKosten: false,
      statusReloadingFinanzierung: false,
      tableHeadersBetrag: [
        "_TXT_KOFI_ERFASSUNG_HEADER_BEWILLIGT_",
        "_TXT_KOFI_ERFASSUNG_HEADER_ERFASST_",
        "_TXT_KOFI_ERFASSUNG_HEADER_ANERKANNT_",
      ],
      betragKeys: [
        "wert",
        "betrag_erfasst",
        "betrag_anerkannt",
      ],
      numberDifferenceKostenPerioden: 0,
      idForActiveTab: 0,
    };
  },
  computed: {
    loadingLocal() {
      return this.loadingKosten || this.loadingFinanzierung;
    },

    statusReloadingLocal() {
      return this.statusReloadingKosten || this.statusReloadingFinanzierung;
    },

    apiKoFiKosten() {
      return `${ this.apiBase }kosten/`;
    },

    apiKoFiFinanzierung() {
      return `${ this.apiBase }finanzierung/`;
    },

    apiAntragDokumenteKosten() {
      return `${ this.apiAntrag }dokumente/?dokart=${ this.prefix.kosten.dokart }`;
    },

    apiAntragDokumenteFinanzierung() {
      return `${ this.apiAntrag }dokumente/?dokart=${ this.prefix.finanzierung.dokart }`;
    },

    apiGesamtKosten() {
      return `${ this.apiVertrag }gesamtkosten/`;
    },

    apiGesamtFinanzierung() {
      return `${ this.apiVertrag }gesamtfinanzierung/`;
    },

    regelNummerVonModuleGroupedKosten() {
      return this.getRegelNummerVonModuleGrouped(this.kostenModuleGrouped);
    },

    regelNummerVonModuleGroupedFinanzierung() {
      return this.getRegelNummerVonModuleGrouped(this.finanzierungModuleGrouped);
    },

    koFiGroupedKosten() {
      return this.getKoFiGrouped({
        koFiList: this.KoFiKosten,
        regelNummerVonModuleGrouped: this.regelNummerVonModuleGroupedKosten,
      });
    },

    koFiGroupedKostenSnapshot() {
      if (!this.isSnapshot) {
        return {
          koFiStatuses: {},
          perioden: {},
          notPerioden: {},
        };
      }
      const {
        koFiKeyByPk,
        koFiStatuses,
      } = this.snapshotKosten;
      const {
        perioden,
        notPerioden,
      } = this.getKoFiGroupedSnapshot({
        koFiSnapshot: koFiKeyByPk,
        regelNummerVonModuleGrouped: this.regelNummerVonModuleGroupedKosten,
      });
      return {
        koFiStatuses,
        perioden,
        notPerioden,
      };
    },

    koFiGroupedFinanzierung() {
      return this.getKoFiGrouped({
        koFiList: this.KoFiFinanzierung,
        regelNummerVonModuleGrouped: this.regelNummerVonModuleGroupedFinanzierung,
      });
    },

    perioden() {
      let PERIODE = get(this.antrag, "regelsatz_obj.perioden", []);
      if (this.isDurchfuehrung && this.periodenPksWithData) {
        PERIODE = filter(PERIODE, item => {
          if ((moment(item.pe_von).isBefore(this.durchfuehrungBis) && moment(item.pe_bis).isAfter(this.durchfuehrungVon)) || this.periodenPksWithData.indexOf(item.pk) !== -1) {
            return item;
          }
        });
      }
      return sortBy(PERIODE, ["pe_von"]);
    },

    periodenPksWithData() {
      const PERIODEN = new Set();
      if (get(this.koFiGroupedKosten, "perioden")) {
        forEach(this.koFiGroupedKosten.perioden, item => {
          if (!isNil(item.perioden_sum)) {
            PERIODEN.add(...keys(item.perioden_sum));
          }
        });
      }
      if (get(this.koFiGroupedFinanzierung, "perioden")) {
        forEach(this.koFiGroupedFinanzierung.perioden, item => {
          if (!isNil(item.perioden_sum)) {
            PERIODEN.add(...keys(item.perioden_sum));
          }
        });
      }
      return [...PERIODEN];
    },

    koFiGroupedFinanzierungSnapshot() {
      if (!this.isSnapshot) {
        return {
          koFiStatuses: {},
          perioden: {},
          notPerioden: {},
        };
      }
      const {
        koFiKeyByPk,
        koFiStatuses,
      } = this.snapshotFinanzierung;
      const {
        perioden,
        notPerioden,
      } = this.getKoFiGroupedSnapshot({
        koFiSnapshot: koFiKeyByPk,
        regelNummerVonModuleGrouped: this.regelNummerVonModuleGroupedFinanzierung,
      });
      return {
        koFiStatuses,
        perioden,
        notPerioden,
      };
    },

    moduleFilteredByKostenart() {
      return this.getModuleFilteredByKofiart(this.kostenModule, "kostenart");
    },

    moduleFilteredByFinanzierungsart() {
      return this.getModuleFilteredByKofiart(this.finanzierungModule, "finanzierungsart");
    },

    sumPeriodenKosten() {
      return this.getSumPerioden(this.koFiGroupedKosten.perioden, this.prefix.kosten.vkofi);
    },

    sumPeriodenKostenSnapshot() {
      if (this.isSnapshot) {
        return this.getSumPerioden(this.koFiGroupedKostenSnapshot.perioden, this.prefix.kosten.vkofi);
      }
      return undefined;
    },

    sumPeriodenFinanzierung() {
      return this.getSumPerioden(this.koFiGroupedFinanzierung.perioden, this.prefix.finanzierung.vkofi);
    },

    sumPeriodenFinanzierungSnapshot() {
      if (this.isSnapshot) {
        return this.getSumPerioden(this.koFiGroupedFinanzierungSnapshot.perioden, this.prefix.finanzierung.vkofi);
      }
      return undefined;
    },

    titleForKappungSumme() {
      return {
        wert: "_TXT_KOFI_ERFASSUNG_BEWILLIGT_GEKAPPT_TITLE_",
        betrag_erfasst: "_TXT_KOFI_ERFASSUNG_ERFASST_GEKAPPT_TITLE_",
        betrag_anerkannt: "_TXT_KOFI_ERFASSUNG_ANERKANNT_GEKAPPT_TITLE_",
      };
    },

    isSnapshot() {
      return !!this.snapshot;
    },

    snapshotKosten() {
      if (!this.isSnapshot) {
        return;
      }
      const CURRENT_SNAPSHOT = get(this.snapshot, "osn_snapshot.kofi.kosten");
      const {
        positionen,
        koFi,
        koFiStatuses,
      } = this.filterKoFi({
        response: CURRENT_SNAPSHOT,
        labelKoFi: "kosten",
      });
      const KO_FI_KEY_BY_PK = this.getSnapshotKoFiAndPositionenAndBelegeKeyByPk({
        koFi
      });
      return {
        positionen,
        koFiKeyByPk: KO_FI_KEY_BY_PK,
        koFiStatuses,
      };
    },

    snapshotFinanzierung() {
      if (!this.isSnapshot) {
        return;
      }
      const CURRENT_SNAPSHOT = cloneDeep(get(this.snapshot, "osn_snapshot.kofi.finanzierung"));
      const {
        positionen,
        koFi,
        koFiStatuses,
      } = this.filterKoFi({
        response: CURRENT_SNAPSHOT,
        labelKoFi: "finanzierung",
      });
      const KO_FI_KEY_BY_PK = this.getSnapshotKoFiAndPositionenAndBelegeKeyByPk({
        koFi
      });
      return {
        positionen,
        koFiKeyByPk: KO_FI_KEY_BY_PK,
        koFiStatuses,
      };
    },

    snapshotFinanzierungKeyByRegelNummer() {
      const CURRENT_SNAPSHOT = get(this.snapshot, "osn_snapshot.kofi.finanzierung");
      return keyBy(CURRENT_SNAPSHOT, "regel_nummer");
    },

    diffKosten() {
      const {
        perioden: periodenKostenSnapshot,
        notPerioden: notPeriodenKostenSnapshot,
        koFiStatuses: koFiStatusesKostenSnapshot
      } = this.koFiGroupedKostenSnapshot;
      const {
        perioden: periodenKosten,
        notPerioden: notPeriodenKosten,
      } = this.koFiGroupedKosten;
      return {
        perioden: this.getDiffForKoFiList({
          kofiList: periodenKosten,
          kofiSnapshot: periodenKostenSnapshot,
          kofiArt: "kosten",
          sumPerioden: this.sumPeriodenKosten,
          sumPeriodenSnapshot: this.sumPeriodenKostenSnapshot,
          isPerioden: true,
        }),
        notPerioden: this.getDiffForKoFiList({
          kofiList: notPeriodenKosten,
          kofiSnapshot: notPeriodenKostenSnapshot,
          koFiStatuses: this.KoFiStatusesKosten,
          koFiStatusesSnapshot: koFiStatusesKostenSnapshot,
          kofiArt: "kosten",
          isPerioden: false,
        }),
      };
    },

    diffFinanzierung() {
      const {
        perioden: periodenFinanzierungSnapshot,
        notPerioden: notPeriodenFinanzierungSnapshot,
        koFiStatuses: koFiStatusesFinanzierungSnapshot
      } = this.koFiGroupedFinanzierungSnapshot;
      const {
        perioden: periodenFinanzierung,
        notPerioden: notPeriodenFinanzierung,
      } = this.koFiGroupedFinanzierung;

      return {
        perioden: this.getDiffForKoFiList({
          kofiList: periodenFinanzierung,
          kofiSnapshot: periodenFinanzierungSnapshot,
          kofiArt: "finanzierung",
          sumPerioden: this.sumPeriodenFinanzierung,
          sumPeriodenSnapshot: this.sumPeriodenFinanzierungSnapshot,
          isPerioden: true,
        }),
        notPerioden: this.getDiffForKoFiList({
          kofiList: notPeriodenFinanzierung,
          kofiSnapshot: notPeriodenFinanzierungSnapshot,
          koFiStatuses: this.KoFiStatusesFinanzierung,
          koFiStatusesSnapshot: koFiStatusesFinanzierungSnapshot,
          kofiArt: "finanzierung",
          isPerioden: false,
        }),
      };
    },

    diffZuschuss() {
      const DIFF = {
        status: false,
        details: {},
        changes: {
          gesamtzuschuss: {
            status: false,
            angepasst: false,
            betrag: false,
          },
          meldungen: {
            beleg: {},
            snapshot: {},
          },
          anpassung: false,
        },
        anpassungen: {
          add: {},
          delete: [],
          changes: {},
        },
      };
      if (this.isSnapshot) {
        if (this.zuschussModuleGrouped.perioden.length) {
          const INFOPROVIDER_PERIODEN = get(this.infoprovider, "perioden", {}) || {};
          const INFOPROVIDER_PERIODEN_SNAPSHOT = get(cloneDeep(this.infoproviderSnapshot), "perioden", {}) || {};
          forEach(INFOPROVIDER_PERIODEN, (item, periodPk) => {
            const CURRENT_SNAPSHOT = INFOPROVIDER_PERIODEN_SNAPSHOT[periodPk] || {};
            if (item.gesamtzuschuss !== CURRENT_SNAPSHOT.gesamtzuschuss) {
              DIFF.changes.gesamtzuschuss[periodPk] = true;
              DIFF.changes.gesamtzuschuss.status = true;
            }
          });
        }
        const ZUSCHUSS_ANGEPASST = setNumberToStringInPythonFormat(getZuschussAngepasst({
          isSnapshot: false,
          vertrag: this.vertrag,
          antrag: this.antrag,
          snapshot: this.currentSnapshot,
        }));
        const ZUSCHUSS_ANGEPASST_SNAPSHOT = setNumberToStringInPythonFormat(getZuschussAngepasst({
          isSnapshot: true,
          vertrag: this.vertrag,
          antrag: this.antrag,
          snapshot: this.currentSnapshot,
        }));
        if (ZUSCHUSS_ANGEPASST !== ZUSCHUSS_ANGEPASST_SNAPSHOT) {
          DIFF.changes.gesamtzuschuss.angepasst = true;
          DIFF.changes.gesamtzuschuss.status = true;
        }
        if (this.zuschussRelevant || this.zuschussRelevantSnapshot) {
          const VERTRAG_BETRAG = getVertragBetrag({
            data: this.infoprovider,
            isSnapshot: false,
            vertrag: this.vertrag,
            snapshot: this.currentSnapshot,
            zuschussRelevant: this.zuschussRelevant,
            zuschussRelevantSnapshot: this.zuschussRelevantSnapshot,
          });
          const VERTRAG_BETRAG_FORMATTED = setNumberToStringInPythonFormat(VERTRAG_BETRAG);
          const VERTRAG_BETRAG_SNAPSHOT = getVertragBetrag({
            data: this.infoproviderSnapshot,
            isSnapshot: true,
            vertrag: this.vertrag,
            snapshot: this.currentSnapshot,
            zuschussRelevant: this.zuschussRelevant,
            zuschussRelevantSnapshot: this.zuschussRelevantSnapshot,
          });
          const VERTRAG_BETRAG_SNAPSHOT_FORMATTED = setNumberToStringInPythonFormat(VERTRAG_BETRAG_SNAPSHOT);
          if (VERTRAG_BETRAG_FORMATTED !== VERTRAG_BETRAG_SNAPSHOT_FORMATTED) {
            DIFF.changes.gesamtzuschuss.betrag = true;
            DIFF.changes.gesamtzuschuss.status = true;
          }
        }
        if (!this.readonly) {
          const INFOPROVIDER_MELDUNGEN = get(this.infoprovider, "meldungen") || [];
          const INFOPROVIDER_MELDUNGEN_SNAPSHOT = get(this.infoproviderSnapshot, "meldungen") || [];
          forEach(INFOPROVIDER_MELDUNGEN, (meldung, index) => {
            if (INFOPROVIDER_MELDUNGEN_SNAPSHOT.indexOf(meldung) === -1) {
              DIFF.changes.meldungen.beleg[index] = true;
            }
          });
          forEach(INFOPROVIDER_MELDUNGEN_SNAPSHOT, (meldung, index) => {
            if (INFOPROVIDER_MELDUNGEN.indexOf(meldung) === -1) {
              DIFF.changes.meldungen.snapshot[index] = true;
            }
          });
        }

        if (this.zuschussModuleGrouped.perioden.length || this.zuschussModuleGrouped.notPerioden.length) {
          const ALL_ZUSCHUSS_MODULE = [...this.zuschussModuleGrouped.perioden, ...this.zuschussModuleGrouped.notPerioden];
          const diffDetails = this.getDiffZuschussModule({ modules: ALL_ZUSCHUSS_MODULE });
          DIFF.details = diffDetails;
          if (!isEmpty(diffDetails)) {
            DIFF.status = true;
          }
        }
        if (this.zuschussModuleGrouped.notPerioden.length) {
          if (this.statusShowPositionZuschuss && this.isAnpassungRelevant) {
            const ZUSCHUSS_ANPASSUNG_SUM = getZuschussanpassungSumme({
              vertrag: this.vertrag,
            });
            const ZUSCHUSS_ANPASSUNG_SUM_SNAPSHOT = getZuschussanpassungSumme({
              vertrag: this.vertragSnapshot,
            });
            if (ZUSCHUSS_ANPASSUNG_SUM !== ZUSCHUSS_ANPASSUNG_SUM_SNAPSHOT) {
              DIFF.changes.anpassung = true;
              DIFF.status = true;
            }
          }
          if (this.isAnpassungRelevant) {
            const VERTRAG_ZUSCHUSS_ANPASSUNGEN_SNAPSHOT = cloneDeep(this.vertragZuschussanpassungenSnapshot);
            forEach(this.vertragZuschussanpassungen, anpassung => {
              const ANPASSUNG_PK = anpassung.pk;
              const CURRENT_SNAPSHOT = VERTRAG_ZUSCHUSS_ANPASSUNGEN_SNAPSHOT[ANPASSUNG_PK];
              if (!CURRENT_SNAPSHOT) {
                DIFF.anpassungen.add[ANPASSUNG_PK] = true;
                DIFF.status = true;
              } else {
                ["vza_kbez", "vza_betrag"].forEach(key => {
                  if (anpassung[key] !== CURRENT_SNAPSHOT[key]) {
                    DIFF.anpassungen.changes[ANPASSUNG_PK] = DIFF.anpassungen.changes[ANPASSUNG_PK] || {};
                    DIFF.anpassungen.changes[ANPASSUNG_PK][key] = true;
                    DIFF.status = true;
                  }
                });
                delete VERTRAG_ZUSCHUSS_ANPASSUNGEN_SNAPSHOT[ANPASSUNG_PK];
              }
            });
            if (!isEmpty(VERTRAG_ZUSCHUSS_ANPASSUNGEN_SNAPSHOT)) {
              DIFF.anpassungen.delete = values(VERTRAG_ZUSCHUSS_ANPASSUNGEN_SNAPSHOT);
              DIFF.status = true;
            }
          }
        }

        if (DIFF.changes.gesamtzuschuss.status) {
          DIFF.status = true;
        }
      }
      return DIFF;
    },

    infoproviderSnapshot() {
      return get(this.snapshot, "osn_snapshot.kofi.zuschuss");
    },

    hasAuszahlungWfPermissionVertragskostenpositionenViewPercentage() {
      return this.checkPermissionsSync({
        perm: "vertragskostenpositionen.view.percentage",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionVertragskostenpositionsbelegeAnerkennen() {
      return this.checkPermissionsSync({
        perm: "vertragskostenpositionsbelege.anerkennen",
        permArray: this.auszahlung.user_permissions,
      });
    },

    isDiff() {
      return this.diffKosten.notPerioden.status ||
        this.diffKosten.perioden.status ||
        this.diffFinanzierung.notPerioden.status ||
        this.diffFinanzierung.perioden.status ||
        this.diffZuschuss.status;
    },

    isDiffPerioden() {
      return this.diffKosten.perioden.status || this.diffFinanzierung.perioden.status;
    },

    belegnummerAnzeigen() {
      return this.getSyConfigsValue("belege_nummer", {}).anzeigen;
    },

    snapshotDate() {
      return get(this.snapshot, "osn_snapshot_datum");
    },

    isDiffIconSumKostenPerioden() {
      return !isEmpty(this.diffKosten.perioden.sumPerioden);
    },

    isDiffIconSumFinanzierungPerioden() {
      return !isEmpty(this.diffFinanzierung.perioden.sumPerioden);
    },

    currentSnapshot() {
      return get(this.snapshot, "osn_snapshot", {});
    },

    isAnpassungRelevant() {
      return this.zushussanpassungConfig.anpassung_relevant;
    },

    zushussanpassungConfig() {
      return this.getSyConfigsValue("zuschussanpassung_zuschussberechnung", {});
    },

    statusShowPositionZuschuss() {
      return getStatusShowPositionZuschuss({
        antrag: this.antrag,
        vertrag: this.vertrag,
      });
    },

    snapshotVertrag() {
      return get(this.currentSnapshot, "vertrag");
    },

    zuschussRelevantSnapshot() {
      return usesZuschussberechnung({
        auszahlungsplan: this.snapshotAuszahlugnsplan,
      });
    },

    snapshotAuszahlugnsplan() {
      return get(this.currentSnapshot, "auszahlugnsplan");
    },

    vertragZuschussanpassungen() {
      return this.vertrag.zuschussanpassungen || [];
    },

    vertragZuschussanpassungenSnapshot() {
      return keyBy(get(this.snapshotVertrag, "zuschussanpassungen") || [], "pk");
    },

    modulnameFinanzierungModuleKeyByRegelNummer() {
      const MODULNAME_KEY_BY_REGEL_NUMMER = {};
      forEach(this.finanzierungModule, modul => {
        MODULNAME_KEY_BY_REGEL_NUMMER[modul.regel.regel_nummer] = modul.modulname;
      });
      return MODULNAME_KEY_BY_REGEL_NUMMER;
    },

    numberDifferenceFinanzierungPerioden() {
      return this.numberDifferenceKostenPerioden + this.koFiGroupedKosten.perioden.length;
    },

    numberDifferenceZuschussPerioden() {
      return this.numberDifferenceFinanzierungPerioden + this.koFiGroupedFinanzierung.perioden.length;
    },

    numberDifferenceKostenNotPerioden() {
      return this.numberDifferenceZuschussPerioden + this.zuschussModuleGrouped.perioden.length;
    },

    numberDifferenceFinanzierungNotPerioden() {
      return this.numberDifferenceKostenNotPerioden + this.koFiGroupedKosten.notPerioden.length;
    },
  },
  watch: {
    isDiff: {
      immediate: true,
      handler(newVal) {
        this.MUT_SET_STATUS_DIFF_FOR_REGEL({
          regelId: "belege",
          statusDiff: newVal,
        });
      },
    },
    perioden: {
      immediate: true,
      handler() {
        this.setPeriodKeyShow();
      },
    }
  },
  created() {
    this.setIdForActiveTabInitial();
    this.setPeriodKeyShow();
    this.loadKosten();
    this.loadFinanzierung();
    this.loadRelevanteAuszahlungen();
    this.loadBelegDokumenteKosten();
    this.loadBelegDokumenteFinanzierung();
    this.initEventBus();
  },
  beforeUnmount() {
    this.destroyEventBus();
  },
  methods: {
    setIdForActiveTabInitial() {
      if (!isNil(this.auszahlung.periode)) {
        const idx = findIndex(this.perioden, ["pk", this.auszahlung.periode.pk]);
        if (idx !== -1) {
          this.idForActiveTab = idx;
        }
      }
    },

    setPeriodKeyShow() {
      if (!this.statusShowPerioden) {
        return;
      }
      const PERIODEN_PK = get(this.perioden, `${ this.idForActiveTab }.pk`);
      if (PERIODEN_PK) {
        this.periodKeyShow = PERIODEN_PK;
      }
    },

    loadKosten() {
      this.getListHttp({
        url: this.apiKoFiKosten,
      }).then(
        response => {
          this.loadGesamtKosten();
          const {
            positionen,
            koFi,
            koFiStatuses,
          } = this.filterKoFi({
            response,
            labelKoFi: "kosten",
          });
          this.positionenKosten = positionen;
          this.KoFiKosten = koFi;
          this.KoFiStatusesKosten = koFiStatuses;
          this.loadingKosten = false;
          this.statusReloadingKosten = false;
        }
      );
    },

    loadFinanzierung() {
      this.getListHttp({
        url: this.apiKoFiFinanzierung,
      }).then(
        response => {
          this.kofiZuschussKeyByRegelNummer = this.getKofiZuschussKeyByRegelNummer({ response });
          this.loadGesamtFinanzierung();
          const {
            positionen,
            koFi,
            koFiStatuses,
          } = this.filterKoFi({
            response,
            labelKoFi: "finanzierung",
          });
          this.positionenFinanzierung = positionen;
          this.KoFiFinanzierung = koFi;
          this.KoFiStatusesFinanzierung = koFiStatuses;
          this.loadingFinanzierung = false;
          this.statusReloadingFinanzierung = false;
        }
      );
    },

    getKofiZuschussKeyByRegelNummer({ response }) {
      const KOFI_ZUSCHUSS = filter(cloneDeep(response), finanzierung => {
        return finanzierung.modulgruppen &&
          finanzierung.modulgruppen.indexOf("pauschale") !== -1 ||
          finanzierung.modulgruppen.indexOf("zuschuss") !== -1 ||
          finanzierung.modulgruppen.indexOf("deckelung") !== -1;
      });
      return keyBy(KOFI_ZUSCHUSS, "regel_nummer");
    },

    loadBelegDokumenteKosten() {
      this.getListHttp({
        url: this.apiAntragDokumenteKosten,
      }).then(
        response => {
          this.belegDokumenteKosten = response;
        }
      );
    },

    loadBelegDokumenteFinanzierung() {
      this.getListHttp({
        url: this.apiAntragDokumenteFinanzierung,
      }).then(
        response => {
          this.belegDokumenteFinanzierung = response;
        }
      );
    },

    loadBelegDokumenteAnzahl() {
      this.getHttp({
        url: `${ this.apiVertrag }belegdokumente/`,
      }).then(
        response => {
          this.belegDokumenteAnzahl = response;
        }
      );
    },

    loadGesamtKosten() {
      this.getHttp({
        url: this.apiGesamtKosten,
      }).then(
        response => {
          this.gesamtKosten = response || {};
        }
      );
    },

    loadGesamtFinanzierung() {
      this.getHttp({
        url: this.apiGesamtFinanzierung,
      }).then(
        response => {
          this.gesamtFinanzierung = response || {};
        }
      );
    },

    changeTab({ tab }) {
      this.idForActiveTab = tab.id;
      this.periodKeyShow = tab.periodKey;
    },

    getRegelNummerVonModuleGrouped(moduleGrouped) {
      const REGEL_NUMMER_GROUPED = {};
      forEach(moduleGrouped, (module, key) => {
        forEach(module, modul => {
          const REGEL_NUMMER = get(modul, "regel.regel_nummer");
          REGEL_NUMMER_GROUPED[REGEL_NUMMER] = key;
        });
      });
      return REGEL_NUMMER_GROUPED;
    },

    getKoFiGrouped({ koFiList, regelNummerVonModuleGrouped }) {
      const KOSTEN_GROUPED = {
        perioden: [],
        notPerioden: [],
      };
      forEach(cloneDeep(koFiList), kofi => {
        const REGEL_NUMMER = kofi.regel_nummer;
        const KEY_KOSTEN_GROUPED = regelNummerVonModuleGrouped[REGEL_NUMMER];
        if (!KEY_KOSTEN_GROUPED) {
          console.error(`Konfigurationsfehler mit GR: ${ REGEL_NUMMER }`);
        }
        if (KEY_KOSTEN_GROUPED === "perioden") {
          kofi.perioden_sum = this.getPeriodenSum(kofi);
        }
        KOSTEN_GROUPED[KEY_KOSTEN_GROUPED].push(kofi);
      });
      return KOSTEN_GROUPED;
    },

    getKoFiGroupedSnapshot({ koFiSnapshot, regelNummerVonModuleGrouped }) {
      const KOSTEN_GROUPED = {
        perioden: {},
        notPerioden: {},
      };
      if (koFiSnapshot) {
        forEach(cloneDeep(koFiSnapshot), (kofi, kofiPk) => {
          const REGEL_NUMMER = kofi.regel_nummer;
          const KEY_KOSTEN_GROUPED = regelNummerVonModuleGrouped[REGEL_NUMMER];
          if (KEY_KOSTEN_GROUPED === "perioden") {
            kofi.perioden_sum = this.getPeriodenSum(kofi);
          }
          KOSTEN_GROUPED[KEY_KOSTEN_GROUPED][kofiPk] = kofi;
        });
      }
      return KOSTEN_GROUPED;
    },

    getPeriodenSum(kofi) {
      const PERIODEN_SUM = {};
      forEach(kofi.positionen, position => {
        forEach(position.posperioden, posPeriod => {
          if (!PERIODEN_SUM[posPeriod.periode]) {
            PERIODEN_SUM[posPeriod.periode] = {
              wert: 0,
              betrag_erfasst: 0,
              betrag_anerkannt: 0,
            };
          }
          PERIODEN_SUM[posPeriod.periode].wert += +posPeriod.wert;
          PERIODEN_SUM[posPeriod.periode].betrag_erfasst += +posPeriod.betrag_erfasst;
          PERIODEN_SUM[posPeriod.periode].betrag_anerkannt += +posPeriod.betrag_anerkannt;
        });
      });
      return PERIODEN_SUM;
    },

    getModuleFilteredByKofiart(module, kofiart) {
      const MODULE_FILTERED_BY_KOFIART = {};
      forEach(module, modul => {
        const PK = get(modul, `regel.argument.${ kofiart }.wert`);
        if (PK) {
          MODULE_FILTERED_BY_KOFIART[PK] = modul;
        }
      });
      return MODULE_FILTERED_BY_KOFIART;
    },

    getSumPerioden(kofi, prefix) {
      const SUM_PERIODEN = {
        sum: {
          wert: 0,
          betrag_erfasst: 0,
          betrag_anerkannt: 0,
        },
        sumGekappt: {
          wert: false,
          betrag_erfasst: false,
          betrag_anerkannt: false,
        },
      };
      const KEY_KOFI_BEWILLIGT = `${ prefix }_betrag_bewilligt`;
      const KEY_KOFI_ERFASST = `${ prefix }_betrag_erfasst`;
      const KEY_KOFI_ANERKANNT = `${ prefix }_betrag_anerkannt`;
      forEach(kofi, item => {
        forEach(item.perioden_sum, (periodSum, periodPk) => {
          if (SUM_PERIODEN[periodPk]) {
            SUM_PERIODEN[periodPk].wert += periodSum.wert;
            SUM_PERIODEN[periodPk].betrag_erfasst += periodSum.betrag_erfasst;
            SUM_PERIODEN[periodPk].betrag_anerkannt += periodSum.betrag_anerkannt;
          } else {
            SUM_PERIODEN[periodPk] = cloneDeep(periodSum);
          }
        });
        SUM_PERIODEN.sum.wert += +item[KEY_KOFI_BEWILLIGT];
        SUM_PERIODEN.sum.betrag_erfasst += +item[KEY_KOFI_ERFASST];
        SUM_PERIODEN.sum.betrag_anerkannt += +item[KEY_KOFI_ANERKANNT];

        SUM_PERIODEN.sumGekappt.wert = SUM_PERIODEN.sumGekappt.wert || item.bewilligt_gekappt;
        SUM_PERIODEN.sumGekappt.betrag_erfasst = SUM_PERIODEN.sumGekappt.betrag_erfasst || item.erfasst_gekappt;
        SUM_PERIODEN.sumGekappt.betrag_anerkannt = SUM_PERIODEN.sumGekappt.betrag_anerkannt || item.anerkannt_gekappt;
      });
      return SUM_PERIODEN;
    },

    reloadKosten({ statusReloadBelegDokumente } = {}) {
      this.statusReloadingKosten = true;
      this.loadKosten();
      if (statusReloadBelegDokumente) {
        this.loadBelegDokumenteKosten();
      }
      this.saveCallback({ statusSaveCallback: SaveCallbackActions.RELOAD_KOFI });
    },

    reloadFinanzierung({ statusReloadBelegDokumente } = {}) {
      this.statusReloadingFinanzierung = true;
      this.loadFinanzierung();
      if (statusReloadBelegDokumente) {
        this.loadBelegDokumenteFinanzierung();
      }
      this.saveCallback({ statusSaveCallback: SaveCallbackActions.RELOAD_KOFI });
    },

    reloadBelegDokumenteKosten() {
      this.loadBelegDokumenteKosten();
    },

    reloadBelegDokumenteFinanzierung() {
      this.loadBelegDokumenteFinanzierung();
    },

    initEventBus() {
      EventBus.$on("updateBeleg", this.updateBeleg);
    },

    destroyEventBus() {
      EventBus.$off("updateBeleg", this.updateBeleg);
    },

    updateBeleg({ kosten, finanzierung, statusReloadBelegDokumente = true }) {
      if (kosten) {
        this.reloadKosten({ statusReloadBelegDokumente });
      }
      if (finanzierung) {
        this.reloadFinanzierung({ statusReloadBelegDokumente });
      }
    },

    getSnapshotKoFiAndPositionenAndBelegeKeyByPk({ koFi }) {
      const KOFI = {};
      forEach(koFi, kf => {
        const POSITIONEN = {};
        if (kf.positionen && kf.positionen.length) {
          forEach(kf.positionen, position => {
            const BELEGE = {};
            if (position.belege && position.belege.length) {
              forEach(position.belege, beleg => {
                BELEGE[beleg.pk] = beleg;
              });
            }
            position.belege = BELEGE;
            POSITIONEN[position.pk] = position;
          });
        }
        kf.positionen = POSITIONEN;
        KOFI[kf.pk] = kf;
      });
      return KOFI;
    },

    getDiffForKoFiList({
      kofiList,
      kofiSnapshot,
      kofiArt,
      koFiStatuses,
      koFiStatusesSnapshot = {},
      isPerioden,
      sumPerioden,
      sumPeriodenSnapshot,
    }) {
      const DIFF = {
        deleteBelege: {},
        addBelege: {},
        deletePositionen: {},
        addPositionen: {},
        changes: {},
        status: false,
        sum: undefined,
        sumPerioden: {},
      };
      if (this.isSnapshot) {
        if (isPerioden) {
          const SUM_PERIODEN_CLONE = cloneDeep(sumPerioden);
          const SUM_PERIODEN_SNAPSHOT_CLONE = cloneDeep(sumPeriodenSnapshot) || {};
          SUM_PERIODEN_CLONE.sumGekappt = undefined;
          SUM_PERIODEN_SNAPSHOT_CLONE.sumGekappt = undefined;
          DIFF.sumPerioden = getDifferenceObjects(SUM_PERIODEN_CLONE, SUM_PERIODEN_SNAPSHOT_CLONE);
          if (!isEmpty(DIFF.sumPerioden)) {
            DIFF.status = true;
          }
        } else {
          ["erfasst", "anerkannt", "bewilligt"].forEach(key => {
            if (round(koFiStatuses[key], 2) !== round(koFiStatusesSnapshot[key], 2)) {
              DIFF.changes[key] = true;
            }
          });
        }

        forEach(kofiList, kofi => {
          const KOFI_PK = kofi.pk;
          const CURRENT_SNAPSHOT_KOFI = cloneDeep(kofiSnapshot[KOFI_PK]) || {};
          const {
            changesKofi,
            addPositionenKofi,
            deletePositionenKofi,
            deleteBelegeKofi,
            addBelegeKofi,
          } = this.getDiffForKoFi({
            kofi,
            currentSnapshot: CURRENT_SNAPSHOT_KOFI,
            kofiArt,
            isPerioden,
          });
          DIFF.status = changesKofi.status || DIFF.status;
          DIFF.changes[KOFI_PK] = changesKofi;
          DIFF.addPositionen[KOFI_PK] = addPositionenKofi;
          DIFF.deletePositionen[KOFI_PK] = deletePositionenKofi;
          DIFF.deleteBelege[KOFI_PK] = deleteBelegeKofi;
          DIFF.addBelege[KOFI_PK] = addBelegeKofi;
        });
      }
      return DIFF;
    },

    getDiffForKoFi({ kofi, currentSnapshot, kofiArt, isPerioden }) {
      const DIFF_KOFI = {
        changesKofi: {
          positionen: {},
          status: false,
        },
        addPositionenKofi: {},
        deletePositionenKofi: [],
        deleteBelegeKofi: {},
        addBelegeKofi: {},
      };
      const KOFI_KEYS = {
        kosten: {
          betrag: [
            "vk_betrag_bewilligt",
            "erfasst",
            "anerkannt",
          ],
        },
        finanzierung: {
          betrag: [
            "vf_betrag_bewilligt",
            "erfasst",
            "anerkannt",
          ],
        },
      };
      forEach(KOFI_KEYS[kofiArt].betrag, key => {
        if (kofi[key] !== currentSnapshot[key]) {
          DIFF_KOFI.changesKofi[key] = true;
          DIFF_KOFI.changesKofi.status = true;
        }
      });
      if (isPerioden && kofi.perioden_sum) {
        DIFF_KOFI.changesKofi.perioden_sum = {};
        const PERIODEN_SUM_SNAPSHOT = cloneDeep(currentSnapshot.perioden_sum) || {};
        forEach(kofi.perioden_sum, (periodSum, periodPk) => {
          const CURRENT_PERIOD_SUM_SNAPSHOT = PERIODEN_SUM_SNAPSHOT[periodPk] || {};
          DIFF_KOFI.changesKofi.perioden_sum[periodPk] = {};
          forEach(this.betragKeys, key => {
            if (periodSum[key] !== CURRENT_PERIOD_SUM_SNAPSHOT[key]) {
              DIFF_KOFI.changesKofi.perioden_sum[periodPk][key] = true;
              DIFF_KOFI.changesKofi.status = true;
            }
          });
          if (PERIODEN_SUM_SNAPSHOT[periodPk]) {
            delete PERIODEN_SUM_SNAPSHOT[periodPk];
          }
        });
        if (!isEmpty(PERIODEN_SUM_SNAPSHOT)) {
          forEach(PERIODEN_SUM_SNAPSHOT, (_, periodPk) => {
            DIFF_KOFI.changesKofi.perioden_sum[periodPk] = {};
            forEach(this.betragKeys, key => {
              DIFF_KOFI.changesKofi.perioden_sum[periodPk][key] = true;
            });
          });
          DIFF_KOFI.changesKofi.status = true;
        }
      }
      if (kofi.positionen && kofi.positionen.length) {
        forEach(kofi.positionen, position => {
          const POSITION_PK = position.pk;
          const CURRENT_SNAPSHOT_POSITION = currentSnapshot.positionen && currentSnapshot.positionen[POSITION_PK];
          if (!CURRENT_SNAPSHOT_POSITION) {
            DIFF_KOFI.addPositionenKofi[POSITION_PK] = true;
            DIFF_KOFI.changesKofi.status = true;
          } else {
            const {
              changesPosition,
              deleteBelegePosition,
              addBelegePosition,
            } = this.getDiffForPosition({
              position,
              currentSnapshot: cloneDeep(CURRENT_SNAPSHOT_POSITION),
              kofiArt,
              isPerioden,
            });
            DIFF_KOFI.changesKofi.status = changesPosition.status || DIFF_KOFI.changesKofi.status;
            DIFF_KOFI.changesKofi.positionen[POSITION_PK] = changesPosition;
            DIFF_KOFI.deleteBelegeKofi[POSITION_PK] = deleteBelegePosition;
            DIFF_KOFI.addBelegeKofi[POSITION_PK] = addBelegePosition;
            delete currentSnapshot.positionen[POSITION_PK];
          }
        });
      }
      if (!isEmpty(currentSnapshot.positionen)) {
        DIFF_KOFI.deletePositionenKofi = values(currentSnapshot.positionen) || [];
        DIFF_KOFI.changesKofi.status = true;
      }
      return {
        changesKofi: DIFF_KOFI.changesKofi,
        addPositionenKofi: DIFF_KOFI.addPositionenKofi,
        deletePositionenKofi: DIFF_KOFI.deletePositionenKofi,
        deleteBelegeKofi: DIFF_KOFI.deleteBelegeKofi,
        addBelegeKofi: DIFF_KOFI.addBelegeKofi,
      };
    },

    getDiffForPosition({ position, currentSnapshot, kofiArt, isPerioden }) {
      const DIFF_POSITION = {
        changesPosition: {
          belege: {},
          status: false,
        },
        addBelegePosition: {},
        deleteBelegePosition: [],
      };

      if (isPerioden) {
        DIFF_POSITION.changesPosition.posperioden = {};
        const CURRENT_SNAPSHOT_POS_PERIODEN = keyBy(currentSnapshot.posperioden || {}, "periode") || {};
        if (position.posperioden) {
          forEach(position.posperioden, posPeriod => {
            const PERIOD_PK = posPeriod.periode;
            const SNAPSHOT_FOR_PERIOD = CURRENT_SNAPSHOT_POS_PERIODEN[PERIOD_PK] || {};
            DIFF_POSITION.changesPosition.posperioden[PERIOD_PK] = {};
            forEach(this.betragKeys, key => {
              if (posPeriod[key] !== SNAPSHOT_FOR_PERIOD[key]) {
                DIFF_POSITION.changesPosition.posperioden[PERIOD_PK][key] = true;
                DIFF_POSITION.changesPosition.status = true;
              }
              if (CURRENT_SNAPSHOT_POS_PERIODEN[PERIOD_PK]) {
                delete CURRENT_SNAPSHOT_POS_PERIODEN[PERIOD_PK];
              }
            });
          });

          if (!isEmpty(CURRENT_SNAPSHOT_POS_PERIODEN)) {
            forEach(CURRENT_SNAPSHOT_POS_PERIODEN, (_, periodPk) => {
              DIFF_POSITION.changesPosition.posperioden[periodPk] = {};
              forEach(this.betragKeys, key => {
                DIFF_POSITION.changesPosition.posperioden[periodPk][key] = true;
              });
            });
            DIFF_POSITION.changesPosition.status = true;
          }
        }
      }

      if (this.isDiffForPositionName({ position, currentSnapshot, kofiArt })) {
        DIFF_POSITION.changesPosition.name = true;
        DIFF_POSITION.changesPosition.status = true;
      }
      const POSITION_BETRAG_BEWILLIGT_KEY = `${ this.prefix[kofiArt].position }_betrag_bewilligt`;
      if (position[POSITION_BETRAG_BEWILLIGT_KEY] !== currentSnapshot[POSITION_BETRAG_BEWILLIGT_KEY]) {
        DIFF_POSITION.changesPosition.bewilligt = true;
        DIFF_POSITION.changesPosition.status = true;
      }
      if (position.erfasst !== currentSnapshot.erfasst) {
        DIFF_POSITION.changesPosition.erfasst = true;
        DIFF_POSITION.changesPosition.status = true;
      }
      if (position.anerkannt !== currentSnapshot.anerkannt) {
        DIFF_POSITION.changesPosition.anerkannt = true;
        DIFF_POSITION.changesPosition.status = true;
      }
      if (position.belege && position.belege.length) {
        forEach(position.belege, beleg => {
          const BELEG_PK = beleg.pk;
          const CURRENT_SNAPSHOT_BELEG = currentSnapshot.belege && currentSnapshot.belege[BELEG_PK];
          if (!CURRENT_SNAPSHOT_BELEG) {
            DIFF_POSITION.addBelegePosition[BELEG_PK] = true;
            DIFF_POSITION.changesPosition.status = true;
          } else {
            const {
              statusBeleg,
              changesBeleg,
            } = this.getDiffForBeleg({
              beleg,
              currentSnapshot: cloneDeep(CURRENT_SNAPSHOT_BELEG),
              kofiArt,
            });
            DIFF_POSITION.changesPosition.status = statusBeleg || DIFF_POSITION.changesPosition.status;
            DIFF_POSITION.changesPosition.belege[BELEG_PK] = changesBeleg;
            delete currentSnapshot.belege[BELEG_PK];
          }
        });
      }
      if (!isEmpty(currentSnapshot.belege)) {
        DIFF_POSITION.deleteBelegePosition = values(currentSnapshot.belege) || [];
        DIFF_POSITION.changesPosition.status = true;
      }
      return DIFF_POSITION;
    },

    getDiffForBeleg({ beleg, currentSnapshot, kofiArt }) {
      const DIFF_BELEG = {
        statusBeleg: false,
        changesBeleg: {},
      };
      DIFF_BELEG.changesBeleg = this.getFullDiffForBeleg({ beleg, currentSnapshot, kofiArt });

      if (this.isDiffForBelegIcon({ beleg, currentSnapshot, kofiArt })) {
        DIFF_BELEG.changesBeleg.icon = true;
      }
      if (!isEmpty(DIFF_BELEG.changesBeleg)) {
        DIFF_BELEG.statusBeleg = true;
      }
      return DIFF_BELEG;
    },

    getFullDiffForBeleg({ beleg, currentSnapshot, kofiArt }) {
      const BELEG_CLONE = cloneDeep(beleg);
      const SNAPSHOT_CLONE = cloneDeep(currentSnapshot);
      BELEG_CLONE.user_permissions = undefined;
      SNAPSHOT_CLONE.user_permissions = undefined;
      BELEG_CLONE.url = undefined;
      SNAPSHOT_CLONE.url = undefined;
      if (!this.belegnummerAnzeigen) {
        const BELEG_NUMMER_KEY = `${ this.prefix[kofiArt].beleg }_nummer`;
        BELEG_CLONE[BELEG_NUMMER_KEY] = undefined;
        SNAPSHOT_CLONE[BELEG_NUMMER_KEY] = undefined;
      }
      if (!this.hasAuszahlungWfPermissionVertragskostenpositionsbelegeAnerkennen) {
        BELEG_CLONE.pruefmethode = undefined;
        SNAPSHOT_CLONE.pruefmethode = undefined;
      }
      // numbers to string in snapshot und manchmal in Beleg, weil API manchmal number, manchmal string für wert schickt
      if (SNAPSHOT_CLONE.argument_anerkannt) {
        SNAPSHOT_CLONE.argument_anerkannt = setNumberToStringInPythonFormatJson(SNAPSHOT_CLONE.argument_anerkannt);
      }
      if (BELEG_CLONE.argument_anerkannt) {
        BELEG_CLONE.argument_anerkannt = setNumberToStringInPythonFormatJson(BELEG_CLONE.argument_anerkannt);
      }
      return getDifferenceObjects(BELEG_CLONE, SNAPSHOT_CLONE);
    },

    isDiffForPositionName({ position, currentSnapshot, kofiArt }) {
      const POSITION_BEZ_KEY = `${ this.prefix[kofiArt].position }_bez`;
      const ART_DETAIL_OBJ_KEY = `${ this.prefix[kofiArt].art }detail_obj`;
      const ART_DETAIL_KBEZ_KEY = `${ this.prefix[kofiArt].artdetail }_kbez`;
      const POSITION_ART_DETAIL_KBEZ = get(position, `${ ART_DETAIL_OBJ_KEY }.${ ART_DETAIL_KBEZ_KEY }`, "");
      const SNAPSHOT_ART_DETAIL_KBEZ = get(currentSnapshot, `${ ART_DETAIL_OBJ_KEY }.${ ART_DETAIL_KBEZ_KEY }`, "");
      const POSITION_NAME = this.showFormatName(POSITION_ART_DETAIL_KBEZ, position[POSITION_BEZ_KEY]);
      const SNAPSHOT_POSITION_NAME = this.showFormatName(SNAPSHOT_ART_DETAIL_KBEZ, currentSnapshot[POSITION_BEZ_KEY]);
      return POSITION_NAME !== SNAPSHOT_POSITION_NAME;
    },

    isDiffForBelegIcon({ beleg, currentSnapshot, kofiArt }) {
      const BELEG_BETRAG_ANERKANNT_KEY = `${ this.prefix[kofiArt].beleg }_betrag_anerkannt`;
      const BELEG_BETRAG_ERFASST_KEY = `${ this.prefix[kofiArt].beleg }_betrag_erfasst`;
      const BELEG_STATUS = this.getBelegStatus({
        beleg,
        keyBelegBetragAnerkannt: BELEG_BETRAG_ANERKANNT_KEY,
        keyBelegBetragErfasst: BELEG_BETRAG_ERFASST_KEY,
      });
      const SNAPSHOT_BELEG_STATUS = this.getBelegStatus({
        beleg: currentSnapshot,
        keyBelegBetragAnerkannt: BELEG_BETRAG_ANERKANNT_KEY,
        keyBelegBetragErfasst: BELEG_BETRAG_ERFASST_KEY,
      });
      return BELEG_STATUS !== SNAPSHOT_BELEG_STATUS;
    },

    getDiffZuschussModule({ modules }) {
      const DIFF_DETAILS = {};
      forEach(modules, modul => {
        const REGEL_PK = get(modul, "regel.pk");
        const REGEL_NUMMER = get(modul, "regel.regel_nummer");
        const MODUL_NAME = this.modulnameFinanzierungModuleKeyByRegelNummer[REGEL_NUMMER];
        const FINANZIERUNG = this.kofiZuschussKeyByRegelNummer[REGEL_NUMMER];
        const FINANZIERUNG_SNAPSHOT = this.snapshotFinanzierungKeyByRegelNummer[REGEL_NUMMER];
        const BETRAG = setNumberToStringInPythonFormat(getVertragBetragValue({
          data: this.infoprovider,
          regelPk: REGEL_PK,
          vertragFinanzierungsart: FINANZIERUNG,
        }) || 0);
        const BETRAG_SNAPSHOT = setNumberToStringInPythonFormat(getVertragBetragValue({
          data: this.infoproviderSnapshot,
          regelPk: REGEL_PK,
          vertragFinanzierungsart: FINANZIERUNG_SNAPSHOT,
        }) || 0);
        if (BETRAG !== BETRAG_SNAPSHOT) {
          DIFF_DETAILS[REGEL_PK] = {
            betrag: true,
            betragSnapshot: BETRAG_SNAPSHOT,
          };
        }
        const diffPositionen = this.getDiffZuschussModulePositionen({
          finanzierung: FINANZIERUNG,
          finanzierungSnapshot: FINANZIERUNG_SNAPSHOT,
          modulName: MODUL_NAME,
          betrag: BETRAG,
          betragSnapshot: BETRAG_SNAPSHOT,
        });
        if (!isEmpty(diffPositionen)) {
          DIFF_DETAILS[REGEL_PK] = DIFF_DETAILS[REGEL_PK] || {};
          DIFF_DETAILS[REGEL_PK].positionen = diffPositionen;
        }
      });
      return DIFF_DETAILS;
    },

    getDiffZuschussModulePositionen({ finanzierung, finanzierungSnapshot, modulName, betrag, betragSnapshot }) {
      const DIFF_POSITIONEN = {};
      const POSITIONEN_SNAPSHOT = keyBy(cloneDeep(finanzierungSnapshot.positionen), "pk");
      forEach(finanzierung.positionen, position => {
        const POSITION_PK = position.pk;
        const POSITION_SNAPSHOT = POSITIONEN_SNAPSHOT[POSITION_PK];
        if (!POSITION_SNAPSHOT) {
          DIFF_POSITIONEN[POSITION_PK] = {
            add: true,
          };
        } else {
          if (position.vfp_bez !== POSITION_SNAPSHOT.vfp_bez) {
            DIFF_POSITIONEN[POSITION_PK] = {
              changes: true,
              vfp_bez: true,
              vfpBezSnapshot: POSITION_SNAPSHOT.vfp_bez,
            };
          }
          let positionBetrag = betrag;
          let positionBetragSnapshot = betragSnapshot;

          if (modulName === "manuelle_pauschale") { // :single-position="false" in <zuschuss-standard>
            positionBetrag = position.vfp_betrag_aktualisiert || position.afp_wert;
            positionBetragSnapshot = POSITION_SNAPSHOT.vfp_betrag_aktualisiert || POSITION_SNAPSHOT.afp_wert;
          }
          if (setNumberToStringInPythonFormat(positionBetrag) !== setNumberToStringInPythonFormat(positionBetragSnapshot)) {
            DIFF_POSITIONEN[POSITION_PK] = DIFF_POSITIONEN[POSITION_PK] || {};
            DIFF_POSITIONEN[POSITION_PK].betrag = true;
            DIFF_POSITIONEN[POSITION_PK].betragSnapshot = positionBetragSnapshot;
            DIFF_POSITIONEN[POSITION_PK].changes = true;
          }
          delete POSITIONEN_SNAPSHOT[POSITION_PK];
        }
      });
      const DELETE_POSITIONEN = values(POSITIONEN_SNAPSHOT);
      if (!isEmpty(DELETE_POSITIONEN)) {
        forEach(DELETE_POSITIONEN, position => {
          position.betragSnapshot = modulName === "manuelle_pauschale" ?
            position.vfp_betrag_aktualisiert || position.afp_wert :
            betragSnapshot;
        });
        DIFF_POSITIONEN.delete = DELETE_POSITIONEN;
      }
      return DIFF_POSITIONEN;
    },

    ...mapMutations([
      "MUT_SET_STATUS_DIFF_FOR_REGEL",
      "MUT_DELETE_STATUS_DIFF_FOR_REGEL",
    ]),
  },
};
