import NachrichtenList from "../NachrichtenList/NachrichtenList.vue";

const TABLE_ID = "benachrichtigungen";

// @vue/component
export default {
  name: "NachrichtenInboxList",
  components: {
    NachrichtenList
  },
  data() {
    return {
      tableId: TABLE_ID,
      tableHeader: "_TXT_NACHRICHTEN_INBOX_LIST_HEADER_",
      pageHeader: "_TXT_NACHRICHTEN_INBOX_PAGE_HEADER_",
      rootDetails: "root.benachrichtigungen.detail",
      pageTitle: "_PAGE_TITLE_NACHRICHTEN_INBOX_LIST_"
    };
  },
};
