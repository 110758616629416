import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import KofiErfassungModulProzent from "../../KofiErfassung/KofiErfassungModul/KofiErfassungModulProzent/KofiErfassungModulProzent";

import FinanzierugMixin from "../Mixins/FinanzierugMixin";
import ZuschussMixin from "../Mixins/ZuschussMixin";

import InfoproviderStatuses from "../../../../const/InfoproviderStatuses";
import {
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "ZuschussMitFinanzierungseingabe",
  components: {
    KFStandard,
    KofiErfassungModulProzent,
  },
  mixins: [
    FinanzierugMixin,
    ZuschussMixin,
  ],
  data() {
    return {
      allowsInput: true,
    };
  },
  computed: {
    sumLocal() {
      let sum = this.antragKoFiArt.summe;
      if (this.infoprovider &&
        this.infoprovider.status === InfoproviderStatuses.PROCESSOR_STATUS_OK &&
        this.infoprovider.details &&
        this.infoprovider.details[this.regel.afr_id] &&
        !isNil(this.infoprovider.details[this.regel.afr_id].betrag)) {
        sum = this.infoprovider.details[this.regel.afr_id].betrag;
      }
      return this.filterCurrency(sum);
    },

    statusReadonly() {
      if (this.kontext === "zuschuss" ||
        this.kontext === "zuschuss_vertrag") {
        return true;
      }
      return !this.hasModulPermissionUpdate;
    },
  },
};
