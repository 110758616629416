import FormstepDetail from "../../../global/components/FormstepDetail/FormstepDetail.vue";
import LoginBox from "../../Registration/Login/LoginBox/LoginBox.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import TheAntragSteps from "../../../SingleUsageComponents/TheAntragSteps/TheAntragSteps.vue";
import TheFoerderfinder from "../../../SingleUsageComponents/TheFoerderfinder/TheFoerderfinder.vue";
import TheFoerderorganisationKennenlernen from "../../../SingleUsageComponents/TheFoerderorganisationKennenlernen/TheFoerderorganisationKennenlernen.vue";
import TheSearchStart from "../../../SingleUsageComponents/TheSearchStart/TheSearchStart.vue";

import PermissionMixin from "../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "DashboardPublic",
  components: {
    FormstepDetail,
    LoginBox,
    PageTabTitle,
    PuxCloak,
    TheAntragSteps,
    TheFoerderfinder,
    TheFoerderorganisationKennenlernen,
    TheSearchStart,
  },
  mixins: [
    PermissionMixin,
    SyConfigMixin,
  ],
  data() {
    return {
      loading: true,
      config: {},
      user: {},
    };
  },
  computed: {
    optionsForBoxTop() {
      return {
        hideHeader: !this.config.ueberschrift_oben,
      };
    },

    optionsForBoxBottom() {
      return {
        hideHeader: !this.config.ueberschrift_unten,
      };
    },
  },
  created() {
    this.initConfig();
    this.checkUser();
  },
  methods: {
    initConfig() {
      this.config = this.getSyConfigsValue("dashboard_public", {});
    },

    checkUser() {
      this.user = cloneDeep(this.me);
      this.loading = false;
    },
  },
};
