"use strict";

import actionsCellTemplate from "./cell.action.jade";
import detailToggleCellTemplate from "./cell.toggle.jade";
import createFilterController from "./filter.modal.create.controller";
import createFilterTemplate from "./filter.modal.create.jade";
import massenstatusController from "./massenstatuswechsel.modal.controller";
import massenstatusTemplate from "./massenstatuswechsel.modal.jade";


class DiasTableController {
  /*@ngInject*/
  constructor(
    $scope,
    $q,
    $sessionStorage,
    $templateCache,
    $location,
    $httpParamSerializer,
    $stateParams,
    $timeout,
    DiasNotification,
    diasConfirmDialog,
    $filter,
    moment,
    WorkflowfunktionRegistry,
    Workflowfolge,
    diasModalDialog,
    Nutzerfilter,
    Nutzertabellenconfigs,
    SyConfigs,
    AuthService,
    i18n,
    Katalog
  ) {
    this.gettext = i18n.gettext;
    this.loadingConfiguration = true;
    this.Katalog = Katalog;
    this.AuthService = AuthService;
    this.Nutzerfilter = Nutzerfilter;
    this.Nutzertabellenconfigs = Nutzertabellenconfigs;
    this.$stateParams = $stateParams;
    this.moment = moment;
    this.SyConfigs = SyConfigs;

    this.$storage = $sessionStorage.$default({});
    this.user_cookie = $location.search().user_cookie;
    this.isAuthenticated = undefined;
    this.checkUser();

    this.tableRowsModel = [];
    this.tableRowsMultiselectSettings = {
      showUncheckAll: true,
      scrollableHeight: "300px",
      scrollable: true,
      enableNewItem: true,
      keyboardControls: true,
      // enableSearch: true,
      displayProp: "label",
      idProp: "id",
      externalIdProp: "",
      template: "{{option.label || option.field}}",
      buttonClasses: "btn btn-default glyphicon-konfiguration",
      smartButtonMaxItems: 100,
      dynamicTitle: false,
      smartButtonTextConverter: () => {
        return " ";
      }
    };

    this.tableRowsTranslationText = {
      checkAll: "alle auswählen",
      uncheckAll: "auf Standard zurücksetzen",
      searchPlaceholder: "Suche",
      dynamicButtonTextSuffix: "",
      buttonDefaultText: ""
    };

    this.focusRowIndex = null;
    this.filterSelection = {};

    this.sortFilter = [];
    this.sortFeld = 0;
    this.ngDropdownMultiselectSettings = {
      scrollableHeight: "300px",
      scrollable: true,
      keyboardControls: true,
      enableSearch: true,
      displayProp: "label",
      idProp: "value"
    };
    this.ngDropdownSelectSettings = {
      showUncheckAll: false,
      showCheckAll: false,
      scrollableHeight: "300px",
      scrollable: true,
      keyboardControls: true,
      enableSearch: true,
      displayProp: "label",
      idProp: "value",
      smartButtonMaxItems: 100,
      smartButtonTextConverter: text => {
        if (text && text.length > 20) {
          return $filter("limitTo")(text, 20) + "...";
        }
        return text;
      }
    };
    this.filterTranslationText = {
      checkAll: "alle auswählen",
      uncheckAll: "alle abwählen",
      searchPlaceholder: "Suche",
      dynamicButtonTextSuffix: "ausgewählt",
      buttonDefaultText: "Bitte auswählen"
    };

    this.rowsModeltoColumnDefs = (rowsModel, columnDefs) => {
      const columns = columnDefs.filter(column => {
        return rowsModel.findIndex(rm => {
          return column.label === rm.label && column.field === rm.field;
        }) !== -1;
      });
      this._addActionColumn();
      return columns;
    };

    this.multiselectRowsInfo = {
      debounce: 1000,
      debounceFn: undefined,
      saveAgain: false,
      saving: false
    };

    this.multiselectRowsChanged = () => {
      if (!this.tableRowsModel.length) {
        this.multiselectRowsDefault();
      }
      this.columnDefs = this.rowsModeltoColumnDefs(this.tableRowsModel, this.collDefault);
      if (!this.isAuthenticated) {
        return;
      }

      if (this.multiselectRowsInfo.debounceFn) {
        this.$timeout.cancel(this.multiselectRowsInfo.debounceFn);
      }
      this.multiselectRowsInfo.debounceFn = this.$timeout(() => {
        this.multiselectRowsSaveDebounced();
      }, this.multiselectRowsInfo.debounce);
    };

    this.multiselectRowsSaveDebounced = force => {
      if (!this.multiselectRowsInfo.saving || force) {
        this.multiselectRowsInfo.saving = true;
        this.multiselectRowsSave().finally(
          () => {
            if (this.multiselectRowsInfo.saveAgain) {
              this.multiselectRowsSaveDebounced(true);
              this.multiselectRowsInfo.saveAgain = false;
            } else {
              this.multiselectRowsInfo.saving = false;
            }
          }
        );
      } else {
        this.multiselectRowsInfo.saveAgain = true;
      }
    };

    this.multiselectRowsSave = size => {
      this.multiselectRowsStatus = true;
      const data = {
        ntc_kennung: this.tableName,
        ntc_conf: this.tableRowsModel.sort(
          this.compareNumeric
        ).map(
          v => {
            return {
              id: v.id,
              label: v.label,
              field: v.field
            };
          }
        ),
        ntc_anzahl: size || this.itemsPerPage
      };
      if (this.userConfig) {
        return this.Nutzertabellenconfigs.one(this.userConfig.pk).customPUT(data).then(
          () => {
            if (size) {
              this.itemsPerPage = size;
            }
          }
        );
      }
      return this.Nutzertabellenconfigs.post(data).then(
        response => {
          if (size) {
            this.itemsPerPage = size;
          }
          this.userConfig = response;
        }
      );
    };

    this.multiselectRowsDefault = () => {
      for (let i = 0; i < this.collDefault.length; i++) {
        if (!this.collDefault[i].invisible) {
          this.tableRowsModel.push(this.collDefault[i]);
        }
      }
    };

    this.selectFilterChanged = () => {
      for (let i = 0; i < this.sortFilter.length; i++) {
        if (this.filter.filter[this.sortFilter[i]].choices && !this.filter.filter[this.sortFilter[i]].multiSelect) {
          if (this.filter.filter[this.sortFilter[i]].selected.length > 1) {
            this.filter.filter[this.sortFilter[i]].selected.splice(0, 1);
            break;
          }
        }
      }
    };

    this.multiselectFilterChanged = () => {
    };

    this.name = "DiasTableController";
    this.firstLoad = true;
    if (SyConfigs.wert("enable_load_param")) {
      this.blockFirstLoad = false;
    } else {
      this.blockFirstLoad = undefined;
    }
    this.$q = $q;
    this.$scope = $scope;
    this.$templateCache = $templateCache;
    this.$location = $location;
    this.$httpParamSerializer = $httpParamSerializer;
    this.$timeout = $timeout;
    this.DiasNotification = DiasNotification;
    this.diasConfirmDialog = diasConfirmDialog;
    this.WorkflowfunktionRegistry = WorkflowfunktionRegistry;
    this.Workflowfolge = Workflowfolge;
    this.diasModalDialog = diasModalDialog;
    // Finalisierte Konfiguration, promise ist aufgelöst.
    this._config = null;
    this._refreshOnLoad = null;
    this.stTable = null;
    this.columnDefs = null;
    this.globalTableConf = {
      hide: [],
      not_details: false,
      default_filter: {},
      wf_status_columns: [],
      ffk_columns: [],
      exclude_columns: [],
      exclude_filters: [],
    };
    if (this.tableName) {
      let conf = SyConfigs.wert("tabellenkonfig");
      conf = conf ? conf.tabellenkonfig : {};
      if (conf && conf[this.tableName]) {
        this.globalTableConf.hide = conf[this.tableName].hide || [];
        this.globalTableConf.not_details = conf[this.tableName].not_details;
        this.globalTableConf.default_filter = conf[this.tableName].default_filter || {};
        this.globalTableConf.wf_status_columns = conf[this.tableName].wf_status_columns || [];
        this.globalTableConf.ffk_columns = conf[this.tableName].ffk_columns || [];
        this.globalTableConf.exclude_columns = conf[this.tableName].exclude_columns || [];
        this.globalTableConf.exclude_filters = conf[this.tableName].exclude_filters || [];
      }
    }
    this.paramInfo = {};
    this.rowClass = null;
    this.tableActions = [];
    this.rowActions = {};
    this.filter = null;
    this.data = [];
    this.tableTitle = null;
    this.emptyText = "Keine Datensätze gefunden";
    this.detailTemplate = null;
    this.sort = {
      options: [],
      current: null,
    };
    this.massenaktionen = [];
    this.massenaktionActive = null;
    this.massenaktionProcessing = false;
    this.rowSelection = {};
    this.rowSelectionAll = false;
    this.showDetails = {};
    this.showRightDetails = {};
    this.editDetails = {};
    this.rowPreviews = {};
    this.rowHoverIndex = undefined;
    this.isDirty = () => false;
    this.pageSizes = [10, 25, 50, 100];
    this.enterRow = angular.noop;
    this.leaveRow = angular.noop;
    this.clickRow = angular.noop;
    this.renderSingleActions = false;
    this.lastParams = undefined;
    this.filterDebounce = {}; // promise für timeout des select filters
    this.filterLoading = {}; // fetching filter data

    // Abruf der Konfiguration
    this.loadConfAttr = () => {
      const confAttr = this._assumePromise($scope.vm.config);
      this.initializing = true;

      confAttr.then(conf => {
        if (angular.isFunction(conf.onRefreshData)) {
          this.onRefreshData = conf.onRefreshData;
        } else {
          this.onRefreshData = () => {};
        }
        this.extra = conf.extra;
        this.emptyText = conf.emptyText || this.emptyText;

        this.detailTemplate = this.globalTableConf.not_details ? null : conf.detailTemplate || this.detailTemplate;
        this.rightDetails = this.globalTableConf.not_details ? null : this.rightDetails;

        if (this.detailTemplate) {
          this.enterRow = this.startRowHover;
          this.leaveRow = this.stopRowHover;
          this.clickRow = this.toggleRowDetails;
        } else if (!conf.disabledDefaultRowAction) {
          this.clickRow = this.callRowCallback;
        }
        this.tableTitle = conf.tableTitle || this.tableTitle;
        this.docAnzeige = conf.docAnzeige || this.docAnzeige;
        this.customArgs = conf.customArgs || this.customArgs;
        this.actionDefault = conf.actionDefault || this.actionDefault;
        this.rowClass = conf.rowClass || null;
        if (angular.isFunction(conf.getDetailRoute)) {
          this.getDetailRoute = conf.getDetailRoute;
        }
        if (angular.isFunction(conf.actions)) {
          this.actions = conf.actions;
        } else if (conf.actions) {
          this.actions = () => angular.copy(conf.actions);
        }
        this.tableActions = this.prepareTableActions(conf.tableActions);
        this.massenaktionenTable = conf.massActions || [];
        this.massenaktionen = this.massenaktionenTable;
        this.filter = conf.filter || null;
        this.isDirty = conf.isDirty || this.isDirty;
        if (this.blockFirstLoad !== undefined) {
          this.blockFirstLoad = this.$location.search().blockFirstLoad === "true";
        }
        if (angular.isFunction(conf.loadPreviewData)) {
          this.loadPreviewData = conf.loadPreviewData;
        } else {
          this.loadPreviewData = this._defaultLoadPreviewData;
        }
        // watch for dirty data and update if neccessary
        $scope.$watch(this.isDirty, newValue => {
          if (this.loading || this.initializing) {
            return;
          } else if (newValue) {
            this.reloadData();
          }
        });
        this._initFilter();
        this.renderSingleActions = conf.renderSingleActions || this.renderSingleActions;
        if (angular.isDefined(conf.columnDefs)) {
          this.columnDefs = [];
          angular.forEach(conf.columnDefs, item => {
            if (angular.isUndefined(item.label)) {
              item.label = item.field;
            }
            // item.disableDefaultAction = item.disableDefaultAction || item.linkToDetail;
            item.cellClass = item.cellClass || "";
            item.cellClass += item.disableDefaultAction ? " dias-noop" : "";
            if (this.globalTableConf === undefined || (angular.isArray(this.globalTableConf.hide) && this.globalTableConf.hide.indexOf(item.field) < 0)) {
              this.columnDefs.push(item);
            }
          });

          // Es kann konfiguriert werden, dass zu beliebigen Workflowschritten
          // noch Datumswerte in der Tabelle angezeigt werden:
          angular.forEach(this.globalTableConf.wf_status_columns, col => {
            this.columnDefs.push({
              field: this._wfStatusColumnsKey(col),
              label: col.bez,
              cellFilter: "date:'shortDate'",
              sortable: true,
              invisible: true,
            });
          });
          // Konfigurierte Förderfinderkategoriezuordnungen
          angular.forEach(this.globalTableConf.ffk_columns, col => {
            const key = this._ffkColumnsKey(col);
            this.columnDefs.push({
              field: key,
              label: col.bez,
              cellTemplate: `
                <ul class="list-unstyled">
                  <li ng-repeat="fkw in row.` + key + ` | filter:{kategorie: '${ col.pk }'}">
                    {{:: fkw.fkw_kbez }}
                  </li>
                </ul>
              `,
              sortable: false,
              invisible: true,
            });
          });
          // Konfigurierte, auszuschließenede Spalten entfernen.
          this.columnDefs = this.columnDefs.filter(col => {
            return this.globalTableConf.exclude_columns.findIndex(
              excluded => col.field === excluded || col.label === excluded
            ) === -1;
          });
          this._addDetailToggleColumn();
          this._addActionColumn();
        }
        this._initSorting();
        this._furtherConfig(conf);
        this._config = conf;
        if (this.hasFilter() && this._refreshOnLoad !== null) {
          this.pipe(this._refreshOnLoad);
          this._refreshOnLoad = null;
        }
        if (this.tableName && this.isAuthenticated) {
          this.getFilterVarianten();
        }
      }).then(
        () => {
          this.initializing = false;
          this.initialCollMultiselect();
        }
      );
    };
    // Loading die Nutzerkonfiguration vor Dias-Table init
    if (this.tableName && this.isAuthenticated) {
      this.Nutzertabellenconfigs.getCurrentList(this.tableName).then(
        response => {
          if (response.length) {
            this.userConfig = response[0];
            this.tableRowsModel = response[0].ntc_conf;
            this.itemsPerPage = this.userConfig.ntc_anzahl;
          } else {
            this.itemsPerPage = 10;
          }
          this.loadingConfiguration = false;
          this.paginationStatus = true;
          this.loadConfAttr();
        }
      );
    } else {
      this.itemsPerPage = 10;
      this.loadingConfiguration = false;
      this.paginationStatus = true;
      this.loadConfAttr();
    }

    this.initDestroy = () => {
      if (!this.statusInitDestroy && this.tableName) {
        this.statusInitDestroy = true;
        angular.element($("#dias_table")).on("$destroy", () => {
          if (angular.element($(`#table_name_${ this.tableName } #st_pagination`)) && angular.isFunction(angular.element($(`#table_name_${ this.tableName } #st_pagination`)).scope) && angular.element($(`#table_name_${ this.tableName } #st_pagination`)).scope()) {
            if (angular.isFunction(angular.element($(`#table_name_${ this.tableName } #st_pagination`)).scope().$destroy)) {
              angular.element($(`#table_name_${ this.tableName } #st_pagination`)).scope().$destroy();
            }
            angular.element($(`#table_name_${ this.tableName } #st_pagination`)).remove();
          }
        });
      }
    };

    // Aktualisiert die Daten
    this.pipe = tableState => {
      this.initDestroy();
      if (this._config === null) { // config-deferred noch nicht aufgelöst
        this._refreshOnLoad = tableState;
        return;
      }
      // Paginierung
      let params = {
        limit: this.itemsPerPage || tableState.pagination.number || 10,
      };
      if (tableState.pagination.start > 1) {
        params.offset = tableState.pagination.start;
      } else if (this.offsetParameter) {
        params.offset = this.offsetParameter;
        tableState.pagination.start = this.offsetParameter;
      }
      // Sortierung
      if (this.sort.current !== null) {
        let prefix = "";
        if (this.sort.current.direction === "desc") {
          prefix = "-";
        }
        params.ordering = prefix + this.sort.current.field;
      }
      // Filterung
      params = this._updateFilterParams(params);
      if (angular.equals(params, this.lastParams) && !tableState.forceReload) {
        return;
      }
      this.lastParams = params;

      if (this.firstLoad && this.blockFirstLoad && !this.hasFilter()) {
        this.firstLoad = false;
        return;
      }
      const query = this._assumePromise(this._config.dataSource(params));
      this.loading = true;
      query.then(
        result => {
        // Workaround, damit unpaginierbare Daten angezeigt werden.
          if (result.pagination) {
            tableState.pagination.totalItemCount = result.pagination.count;
            tableState.pagination.totalUnfilteredItemCount = result.pagination.countUnfiltered;
          } else {
            tableState.pagination.totalItemCount = result.length;
            tableState.pagination.totalUnfilteredItemCount = result.length;
          }
          tableState.pagination.numberOfPages = Math.ceil(tableState.pagination.totalItemCount / tableState.pagination.number);
          tableState.pagination.isFiltered = tableState.pagination.totalItemCount !== tableState.pagination.totalUnfilteredItemCount;
          tableState.pagination.itemsOnPage = result.length;
          this.pagination = tableState.pagination;

        // Spalten automatisch ermitteln, falls nicht angegeben.
          if (this.columnDefs === null && result.length > 0) {
            this.columnDefs = [];
            angular.forEach(result[0], (value, key) => {
              this.columnDefs.push({
                label: key,
                field: key });
            });
            this.responsiveColumnStopIndex = this.columnDefs.length - 1;
            this._addDetailToggleColumn();
            this._addActionColumn();
          }

        // Daten in Scope setzen
          this._updateRowActions(result);
          this.data = result;
          this.data.tableState = tableState;
          if (result.massenstatuswechsel) {
            this.massenaktionen = this.massenaktionenTable.concat(
              result.massenstatuswechsel.massenstatuswechsel
            );
            if (result.massenstatuswechsel.workflowfolgen) {
              this.massenaktionActive.workflowfolgen = result.massenstatuswechsel.workflowfolgen;
            }
          }

          if (result.extra && result.extra.csvAvailable) {
            this.csvUrl = result.getRestangularUrl();
          }
          this.csvDownloadTitle = "Suchergebnis als CSV-Datei herunterladen";
          if (result.extra && result.extra.csvMaxEntries) {
            this.csvDownloadTitle += " (maximal " + result.extra.csvMaxEntries + " Datensätze)";
          }

          this.firstLoad = false;

          this.onRefreshData(this.data);
        // Workaround (jquery/angular): ruft man die Funktion direkt auf, sind
        // die Elemente im DOM noch nicht gerendert.
        // $timeout(medialen.initTable, 500);

        // reset Detailansicht
          this.rowDetails = {};
          this.showDetails = {};
          this.showRightDetails = {};
        // reset preview Data
          this.rowPreviews = {};
        // reset selection checkboxes for mass action
          this.rowSelection = {};
          this.rowSelectionAll = false;
          this.loading = false;
        },
        error => {
          const locationParams = this.$location.search();
          let errorsLabel = "";
          _.forEach(error.data, (val, key) => {
            if (locationParams[key]) {
              delete locationParams[key];
            }
            _.forEach(this.filter.filter, (filter, keyFilter) => {
              if (keyFilter === key && filter.selected) {
                errorsLabel = errorsLabel ? errorsLabel + ", " : "";
                errorsLabel += filter.label;
                if (_.isArray(filter.selected)) {
                  filter.selected = [];
                } else {
                  filter.selected = "";
                }
              }
            });
            if (this.$storage[this.tableName + "_filter"]) {
              _.forEach(this.$storage[this.tableName + "_filter"], (filter, keyFilter) => {
                if (keyFilter === key && this.$storage[this.tableName + "_filter"][keyFilter]) {
                  this.$storage[this.tableName + "_filter"][keyFilter] = undefined;
                }
              });
            }
          });
          this.DiasNotification.page.error(`Ungültiger Filter ${ errorsLabel } wurde${ _.size(error.data) > 1 ? "n" : "" } entfernt.`);
          this.$location.search(locationParams);

          this.reloadData();
        }
      ).then(
        () => {
          this.lastRefresh = Date.now();
        }
      );
    };
  }

  checkUser() {
    this.isAuthenticated = this.AuthService.isAuthenticated();

    this.AuthService.getUser().then(
      user => this.user = user
    );
  }

  _furtherConfig(conf) {
    return conf;
  }

  startRowHover(rowIndex) {
    this.rowHoverIndex = rowIndex;
  }

  stopRowHover(rowIndex) {
    if (this.rowHoverIndex === rowIndex) {
      this.rowHoverIndex = undefined;
    }
  }

  callRowCallback(rowIndex, columnIndex, row) {
    if (this.rowActions[row.pk] && this.rowActions[row.pk].length === 1) {
      this.rowActions[row.pk][0].callback(row);
    }
  }

  toggleRowDetails(rowIndex, columnIndex) {
    if (!this.columnDefs[columnIndex].disableDefaultAction) {
      this.focusRowIndex = rowIndex;
      // PRODIAS
      if (this.rightDetails) {
        if (this.rowDetails && this.rowDetails.currentRow === rowIndex) {
          this.rowDetails = {
            statusShow: false,
            loading: false,
            // [rowIndex]: false,
            currentRow: null
          };
        } else {
          this.rowDetails = {
            statusShow: true,
            loading: true,
            // [rowIndex]: true,
            currentRow: rowIndex
          };
          if (this.rowPreviews[this.data[rowIndex].pk] && !this.rowPreviews[this.data[rowIndex].pk].loading) {
            this._actionDefault(this.rowPreviews[this.data[rowIndex].pk].data);
            this.rowDetails = {
              statusShow: true,
              data: this.rowPreviews[this.data[rowIndex].pk].data,
              loading: false,
              [rowIndex]: true,
              currentRow: rowIndex
            };
          }
        }
        // PRODIAS/
      } else {
        this.showDetails[rowIndex] = !this.showDetails[rowIndex];
      }
      this._loadPreviewData(this.data[rowIndex], rowIndex);
    }
  }

  reloadData() {
    const tableState = this.stTable.tableState();
    tableState.pagination.start = 0;
    tableState.forceReload = true;
    this.pipe(tableState);
  }

  _defaultLoadPreviewData(row) {
    if (angular.isFunction(row.getPreview)) {
      return row.getPreview();
    }
    return row;
  }

  _loadPreviewData(row, rowIndex) {
    if (angular.isUndefined(this.rowPreviews[row.pk])) {
      this.rowPreviews[row.pk] = {
        data: null,
        errors: null,
        loading: true
      };
      this._assumePromise(this.loadPreviewData(row)).then(
        data => this.rowPreviews[row.pk].data = data,
        error => this.rowPreviews[row.pk].errors = error
      ).finally(() => {
        // PRODIAS
        if (this.rightDetails) {
          this._actionDefault(row);

          this.rowDetails = {
            statusShow: true,
            data: this.rowPreviews[row.pk].data,
            loading: false,
            [rowIndex]: true,
            currentRow: rowIndex
          };
        }
        // PRODIAS/
        this.rowPreviews[row.pk].loading = false;
      });
    }
  }

  // Stellt sicher, dass result ein Promise ist.
  _assumePromise(result) {
    if (angular.isUndefined(result) || angular.isUndefined(result.then)) {
      const deferred = this.$q.defer();
      deferred.resolve(result);
      return deferred.promise;
    }
    return result;
  }

  // Fügt die Aktionsspalte hinzu.
  _addDetailToggleColumn() {
    if (this.detailTemplate !== null) {
      this.columnDefs.push({
        field: "detailToggle", label: "",
        // enableSorting: false, enableHiding: false,
        // enableColumnMenu: false,
        cellTemplate: detailToggleCellTemplate(),
        cellClass: "toggle",
        // width: 100, cellClass: "text-center"
      });
      this.responsiveColumnStopIndex = this.columnDefs.length - 1;
    }
  }

  // Fügt die Aktionsspalte hinzu.
  _addActionColumn() {
    const actionsAlreadyDefined = this.columnDefs.findIndex(c => c.field === "aktionen") !== -1;
    if (!actionsAlreadyDefined && (this.actions !== undefined || this.getDetailRoute !== undefined)) {
      this.columnDefs.push({
        field: "aktionen", label: "",
        // enableSorting: false, enableHiding: false,
        // enableColumnMenu: false,
        cellTemplate: actionsCellTemplate(),
        cellClass: "dias-actions",
        disableDefaultAction: true
      });
      this.responsiveColumnStopIndex = this.columnDefs.length - 2;
    }
  }

  // Entfernt die Aktionsspalte hinzu.
  _removeActionColumn() {
    if (this.actions !== undefined || this.getDetailRoute !== undefined) {
      this.columnDefs.pop();
      this.responsiveColumnStopIndex = this.columnDefs.length + 2;
    }
  }

  _updateRowActions(rows) {
    if (this.actions !== undefined) {
      const _get_update_actions = row => () => this.prepareRowActions(row);
      for (let i = 0; i < rows.length; i++) {
        this.prepareRowActions(rows[i]);
        rows[i].updateActions = _get_update_actions(rows[i]);
      }
    }
  }

  prepareTableActions(actions) {
    if (!actions) {
      return;
    }
    const getActionIsDisabled = action => () => action.isDisabled;
    const getActionTitle = action => () => action.tooltip || action.label;
    for (let i = 0; i < actions.length; i++) {
      if (angular.isFunction(actions[i].isDisabled)) {
        actions[i].getDisabled = actions[i].isDisabled;
      } else if (!angular.isUndefined(actions[i].isDisabled)) {
        actions[i].getDisabled = getActionIsDisabled(actions[i]);
      }
      if (angular.isFunction(actions[i].tooltip)) {
        actions[i].getTooltip = actions[i].tooltip;
      } else {
        actions[i].getTooltip = getActionTitle(actions[i]);
      }
    }
    return actions;
  }

  // Bereitet Zeilen-Aktionen
  prepareRowActions(row) {
    this.rowActions[row.pk] = this.actions(row);
    let hasActions = false;
    // Ausführen einer Aktion kann Zustand der Zeile ändern -> Aktionen neu ermitteln
    const cb = (row, action) => {
      // Wenn die Aktion ein Promise zurückgibt, warte auf promise
      return row => this._assumePromise(action.callback(row)).then(
        () => this._updateRowActions(this.data)
      );
    };
    for (let i = 0; i < this.rowActions[row.pk].length; i++) {
      const action = this.rowActions[row.pk][i];
      if (!angular.isObject(action)) {
        continue;
      }
      if (!angular.isFunction(action.isVisible)) {
        action.visible = true; // Default: Sichtbar
      } else {
        action.visible = action.isVisible(row, this.extra);
      }
      if (!angular.isFunction(action.isActive)) {
        action.active = true; // Default: Aktiv
      } else {
        action.active = action.isActive(row);
      }
      action.type = action.type ? "btn-" + action.type : "btn-default";
      action.performCallback = cb(row, action);
      hasActions = hasActions || action.visible;
    }
    this.rowActions[row.pk].hasActions = hasActions;
  }

  _initSorting() {
    let defaultSortEntry = null;
    let urlSortEntry = null;
    const urlparams = this.getUrlParams();
    const urlSortField = { field: urlparams.ordering, direction: "asc" };
    if (angular.isString(urlSortField.field) && urlSortField.field.indexOf("-") === 0) {
      urlSortField.field = urlSortField.field.substr(1);
      urlSortField.direction = "desc";
    }
    angular.forEach(this.columnDefs, item => {
      if (item.sortable === true) {
        const field = item.sortField || item.field;
        const sortEntryAsc = { field: field, direction: "asc", label: item.label + " aufsteigend" };
        const sortEntryDesc = { field: field, direction: "desc", label: item.label + " absteigend" };
        if (urlSortField.field === field) {
          urlSortEntry = urlSortField.direction === "asc" ? sortEntryAsc : sortEntryDesc;
        }
        if (this.sort.current === null) {
          this.sort.current = sortEntryAsc;
        }
        if (item.sortingDefault === true) {
          const dir = item.sortingDefaultDirection || "asc";
          defaultSortEntry = dir === "asc" ? sortEntryAsc : sortEntryDesc;
        }
        this.sort.options.push(sortEntryAsc);
        this.sort.options.push(sortEntryDesc);
      }
    });

    // Wenn URL eine Sortierung vorgibt diese verwenden:
    if (urlSortEntry) {
      this.sort.current = urlSortEntry;
    } else if (defaultSortEntry) {
      // Nur Default verwenden, wenn URL keine Sortierung vorgibt
      this.sort.current = defaultSortEntry;
    }
  }

  // Ändert die Sortierung
  setSorting(sort) {
    this.sort.current = sort;
    if (this._config === null) {
      return;
    }
    this.pipe(this.stTable.tableState());
  }

  // Mehrfachaktion-Auswahl alle aus-/abwählen
  rowSelectionToggleAll() {
    if (this.rowSelectionAll) {
      angular.forEach(this.data, row => this.rowSelection[row.pk] = true);
    } else {
      this.rowSelection = {};
    }
  }

  // Massenaktion-Modus aktivieren (Massenstatuswechsel Workflow oder
  // reguläre Massenaktionen)
  enterMassenaktion(aktion) {
    this.massenaktionActive = aktion;
    this._removeActionColumn();
    if (aktion.msw_kbez || aktion.filter) {
      // Massenstatuswechsel Workflow
      this.reloadData();
    }
  }

  // Massenaktion-Modus deaktivieren
  exitMassenaktion() {
    this.massenaktionActive = null;
    this.massenaktionProcessing = false;
    this._addActionColumn();
    this.reloadData();
  }


  _getSelectedObjects() {
    const objIDs = [];
    Object.keys(this.rowSelection).map(pk => {
      if (this.rowSelection[pk] === true) {
        objIDs.push(pk);
      }
    });
    return objIDs;
  }

  // Reguläre Massenaktionen
  doMassenaktion() {
    const objIDs = this._getSelectedObjects();
    const modal = this.massenaktionActive.modal;
    if (modal) {
      modal(objIDs, () => this.exitMassenaktion());
    } else {
      if (this.massenaktionActive.downloadFilename === undefined) {
        // Massenaktion ist eine Aktion
        this.diasConfirmDialog({
          title: "Mehrfachaktion durchführen?",
          content: "Möchten Sie die Aktion <strong>" + this.massenaktionActive.label + "</strong> auf " + objIDs.length + " Objekten durchführen?",
          okCallback: () => this.massenaktionActive.callback(objIDs).then(
            () => this.DiasNotification.page.success("Aktion erfolgreich ausgeführt."),

            () => this.DiasNotification.page.error("Fehler beim Ausführen der Aktion.")
          ).finally(() => this.exitMassenaktion())
        });
      } else {
        this.diasConfirmDialog({
          title: "Daten exportieren?",
          content: "Möchten Sie die die Daten für " + objIDs.length + " Objekte exportieren?",
          okCallback: () => this.massenaktionActive.callback(objIDs).then(
            response => this.doMassendownload(response, this.massenaktionActive.downloadFilename),
            () => this.DiasNotification.page.error("Fehler beim Exportieren der Daten.")
          ).finally(() => this.exitMassenaktion())
        });
      }
    }
  }

  doMassendownload(response, filename) {
    const data = JSON.stringify(response, null, 2);
    const blob = new Blob([data], { type: "application/json" });
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      const a = window.document.createElement("a");
      a.href = url;
      a.download = filename;
      a.target = "_self";
      const eventClick = window.document.createEvent("MouseEvents");
      eventClick.initEvent(
        "click",
        true,
        true,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      a.dispatchEvent(eventClick);
    }
  }

  // Massenstatuswechsel ausführen (für Workflow)
  doMassenstatuswechsel(wff) {
    const objIDs = this._getSelectedObjects();
    this.Workflowfolge.restangularizeElement(wff);
    // FIXME: restangularizeElement führt extendModel nicht aus, d.h.
    // es fehlen die am Model definierten Methoden
    wff.getClientKey = () => wff.clientfunktion.modulname;

    const folgeFunc = this.WorkflowfunktionRegistry.get(null, wff);
    folgeFunc.start().then(
      result => {
        if (result !== undefined && result.finished === true) {
          const data = { objects: objIDs,
                         params: result.params,
                         workflowfolge: wff.pk };
          this.diasConfirmDialog({
            title: "Mehrfachaktion durchführen?",
            content: "Möchten Sie die Aktion <strong>" + wff.wfo_kbez + "</strong> auf " + objIDs.length + " Objekten durchführen?",
            okCallback: () => {
              this.diasModalDialog({
                title: `"${ wff.wfo_kbez }" wird auf ${ objIDs.length } ${ objIDs.length === 1 ? "Objekt" : "Objekten" } durchgeführt`,
                template: massenstatusTemplate,
                controller: massenstatusController,
                extras: {
                  startTask: () => this.data.customPOST(data, "massenstatuswechsel")
                }
              }).then(() => this.exitMassenaktion());
            }
          });
        }
      }
    );
  }

  getUrlParams() {
    const urlparams = this.$location.search() || {};
    angular.forEach(this.$stateParams, (stateParam, key) => {
      if (stateParam) {
        urlparams[key] = stateParam;
      }
    });

    if ((Object.keys(urlparams).length === 0 || this.user_cookie) && this.$storage[this.tableName + "_filter"]) {
      _.forEach(this.$storage[this.tableName + "_filter"], (item, key) => {
        if (((_.isArray(item) && item.length) || !_.isNil(item)) && _.isUndefined(urlparams[key])) {
          urlparams[key] = item;
        }
      });
    }
    if (this.user_cookie && urlparams.offset) {
      this.offsetParameter = urlparams.offset;
    }
    return urlparams;
  }

  setUrlParams(params) {
    if (this.filter_initialized) {
      this.$location.search(this.$httpParamSerializer(params)).replace();
      this.$storage[this.tableName + "_filter"] = params;
    }
  }


  setCsvParams(params) {
    if (this.filter_initialized) {
      this.csvParams = angular.copy(params);
    }
  }

  // Initialisiert Filter
  _initFilter() {
    if (this.filter === null) {
      return;
    }
    const urlparams = this.getUrlParams();

    // Es kann konfiguriert werden, dass zu beliebigen Workflowschritten
    // noch Datumswerte in der Tabelle angezeigt werden:
    angular.forEach(this.globalTableConf.wf_status_columns, col => {
      const key = this._wfStatusColumnsKey(col);
      this.filter.addFilter(key, {
        field: key,
        label: col.bez,
        dateRange: true,
        dateRangeMinPlaceholder: "Beginn beliebig",
        dateRangeMaxPlaceholder: "Ende beliebig",
        param: key,
        group: this.gettext("Bearbeitung")
      });
    });

    angular.forEach(this.globalTableConf.ffk_columns, col => {
      const key = this._ffkColumnsKey(col);
      this.filter.addFilter(key, {
        field: key,
        label: col.bez,
        choices: this.Katalog.getChoices("foerderfinderkategoriewerte", "pk", "bez", { kategorie: col.pk }),
        multiSelect: true,
        param: key,
        group: this.gettext("Bearbeitung")
      });
    });

    this.globalTableConf.exclude_filters.forEach(key => {
      if (this.filter.filter[key] !== undefined) {
        delete this.filter.filter[key];
        const sortOrderIdx = this.filter.sortOrder.indexOf(key);
        if (sortOrderIdx !== -1) {
          this.filter.sortOrder.splice(sortOrderIdx, 1);
        }
      }
    });
    angular.forEach(this.filter.filter, (options, key) => {
      if (this.globalTableConf.default_filter[key] !== undefined) {
        options.selected = this.globalTableConf.default_filter[key];
      }

      this._init_filter(urlparams, options);
      if (options.template) {
        options.templateUrl = "filter-" + key + ".html";
        this.$templateCache.put(options.templateUrl, options.template);
      }
      if (options.multiSelect && !options.selected) {
        options.selected = [];
      } else if (options.choices && !options.selected) {
        options.selected = [];
      } else if (!options.selected && (options.checkbox || options.radio)) {
        options.selected = [];
      } else if (!options.selected && (options.numberRange || options.dateRange)) {
        options.selected = [];
      }
      if (options.selected) {
        if (angular.isArray(options.selected) && (options.selected.length || options.checkbox || options.radio)) {
          options.statusShow = true;
          this.sortFilter.push(key);
        } else if (angular.isObject(options.selected)) {
          angular.forEach(options.selected, model => {
            if (model) {
              options.statusShow = true;
              this.sortFilter.push(key);
            }
          });
        } else {
          if (!(options.alwaysActive && ((this.$storage[this.tableName + "_filter_close"] && this.$storage[this.tableName + "_filter_close"][options.param]) ||
            !this.$storage[this.tableName + "_filter_close"]))) {
            options.statusShow = true;
            this.sortFilter.push(key);
          }
        }
      } else if (options.initialStatus) {
        options.statusShow = true;
        this.sortFilter.push(key);
      }
    });
    this.filteredFilterSelection();
    this.filter_initialized = true;
  }

  // Aktualisiert nach Änderung der Filter
  filterChanged() {
    this.offsetParameter = undefined;
    this.reloadData();
  }

  toggleCheck(filterKey, choiceValue) {
    if (this.filter.filter[filterKey].selected.indexOf(choiceValue) === -1) {
      this.filter.filter[filterKey].selected.push(choiceValue);
    } else {
      this.filter.filter[filterKey].selected.splice(this.filter.filter[filterKey].selected.indexOf(choiceValue), 1);
    }
    this.filterChanged();
  }

  toggleRadio(filterKey, choiceValue) {
    this.filter.filter[filterKey].selected = [choiceValue];
    this.filterChanged();
  }

  hasFilter() {
    const params = {};
    this._updateFilterParams(params);
    for (const key in params) {
      if (params[key] !== undefined &&
        !(this.paramInfo[key] && this.paramInfo[key].alwaysActive) &&
        !(this.paramInfo[key] && this.paramInfo[key].isInitial) &&
        (!angular.isArray(params[key]) || params[key].length > 0)) {
        return true;
      }
    }
    return false;
  }

  // Ergänzt die URL-Parameter bzgl. der Filterung
  _updateFilterParams(param) {
    let params = _.cloneDeep(param);
    if (this.filter === null) {
      return params;
    }
    this.paramInfo = this.paramInfo || {};
    angular.forEach(this.filter.filter, options => {
      if (options.selected) {
        if (typeof options.param === "function") {
          options.param(params, options);
        } else if (options.multiSelect || options.choices) {
          const paramsTemp = [];
          angular.forEach(options.selected, value => {
            paramsTemp.push(value.id);
          });

          if (angular.isUndefined(params[options.param])) {
            params[options.param] = paramsTemp;
          } else {
            params[options.param] = params[options.param].concat(paramsTemp);
          }
        // } else if (options.choices) {
          // params[options.param] = options.selected.id;
        } else if (options.checkbox || options.radio) {
          const paramsTemp = [];
          angular.forEach(options.selected, value => {
            paramsTemp.push(value);
          });
          if (angular.isUndefined(params[options.param])) {
            params[options.param] = paramsTemp;
          } else {
            params[options.param] = params[options.param].concat(paramsTemp);
          }
        } else if (options.numberRange) {
          params[options.param + "_min"] = options.selected[0];
          params[options.param + "_max"] = options.selected[1];
        } else if (options.dateRange) {
          params[options.param + "_after"] = options.selected[0];
          params[options.param + "_before"] = options.selected[1];
        } else {
          params[options.param] = options.selected;
        }
      } else {
        // Set empty for URL
        if (typeof options.param === "function") {
          options.param(params, "");
        } else if (options.multiSelect) {
          // Don't touch multiSelects
        } else if (options.choices) {
          // Don't touch selects
        } else if (options.numberRange || options.dateRange) {
          params[options.param] = [];
        } else {
          params[options.param] = "";
        }
      }

      let keys = [options.param];
      if (options.numberRange || options.dateRange) {
        keys = [options.param + "_min", options.param + "_max"];
      }
      angular.forEach(keys, key => {
        if (params[key]) {
          this.paramInfo[key] = this.paramInfo[key] || {};
          this.paramInfo[key].alwaysActive = options.alwaysActive;
          this.paramInfo[key].isInitial = options.initial === options.selected;
        }
      });
    });

    // remove Empty from QueryParams
    for (const key in params) {
      if (params[key] === "") {
        params[key] = undefined;
      }
    }
    this.setUrlParams(params);
    this.setCsvParams(params);

    if (this.massenaktionActive) {
      if (this.massenaktionActive.aufgabe) {
        params.msw = this.massenaktionActive.aufgabe;
      }
      if (this.massenaktionActive.filter) {
        params = _.assign({}, params, this.massenaktionActive.filter);
      }
    }
    return params;
  }

  /**
   * Erwartet einen filter und liefert den zugehörigen URL-Parameter zurück
   */
  _init_filter(urlparams, filter) {
    // wenn param über eine Funktion gesetzt wird, muss sie über eine Funktion
    // gelesen werden.
    if (angular.isFunction(filter.param)) {
      if (angular.isFunction(filter.init_from_urlparam)) {
        filter.init_from_urlparam(urlparams, filter);
      }
      return;
    }
    // Wenn urlparam nicht gesetzt ist nutze default Wert oder null
    if (angular.isUndefined(urlparams[filter.param]) &&
       !((filter.numberRange || filter.dateRange) && (urlparams[filter.param + "_min"] || urlparams[filter.param + "_max"] || urlparams[filter.param + "_after"] || urlparams[filter.param + "_before"]))) {
      filter.selected = filter.selected || null;
      if (this.$stateParams[filter.param]) {
        const tempUrl$stateParams = [];
        if (angular.isArray(this.$stateParams[filter.param])) {
          angular.forEach(this.$stateParams[filter.param], urlparam => {
            tempUrl$stateParams.push({ id: urlparam });
          });
        } else {
          tempUrl$stateParams.push({ id: this.$stateParams[filter.param] });
        }
        filter.selected = tempUrl$stateParams;
      }
      return;
    }
    // Wenn urlparam leer setze filter auf null
    if (urlparams[filter.param] === "") {
      filter.selected = null;
      return;
    }

    if (filter.multiSelect || filter.choices) {
      const tempUrlparams = [];
      if (angular.isArray(urlparams[filter.param])) {
        angular.forEach(urlparams[filter.param], urlparam => {
          tempUrlparams.push({ id: urlparam });
        });
      } else {
        tempUrlparams.push({ id: urlparams[filter.param] });
      }
      filter.selected = tempUrlparams;
    // } else if (filter.choices) {
    //  filter.selected = {"id": urlparams[filter.param]};
    } else if (filter.checkbox || filter.radio) {
      const tempUrlparams = [];
      if (angular.isArray(urlparams[filter.param])) {
        angular.forEach(urlparams[filter.param], urlparam => {
          for (let i = 0; i < filter.choicesList.length; i++) {
            if (filter.choicesList[i].value === urlparam) {
              tempUrlparams.push(urlparam);
              break;
            }
          }
        });
      } else {
        for (let i = 0; i < filter.choicesList.length; i++) {
          if (filter.choicesList[i].value === urlparams[filter.param]) {
            tempUrlparams.push(urlparams[filter.param]);
            break;
          }
        }
      }
      filter.selected = tempUrlparams;
    } else if (filter.numberRange) {
      filter.selected = [
        Number(urlparams[filter.param + "_min"]) || undefined,
        Number(urlparams[filter.param + "_max"]) || undefined
      ];
    } else if (filter.dateRange) {
      filter.selected = [
        urlparams[filter.param + "_after"] || undefined,
        urlparams[filter.param + "_before"] || undefined
      ];
    } else if (filter.bool) {
      const v = urlparams[filter.param];
      if (v === true || v === "true" || v === "True" || v === "t") {
        filter.selected = "True";
      } else if (v === false || v === "false" || v === "False" || v === "f") {
        filter.selected = "False";
      }
    } else {
      filter.selected = urlparams[filter.param];
    }
  }

  chooseFilterField(key) {
    if (this.filter.filter[key].statusShow) {
      this.sortFilter.splice(this.sortFilter.indexOf(key), 1);
      if (!this.filter.filter[key].alwaysActive) {
        if (this.filter.filter[key].selected) {
          if (this.filter.filter[key].multiSelect) {
            this.filter.filter[key].selected = [];
          } else if (this.filter.filter[key].choices) {
            this.filter.filter[key].selected = [];
          } else if (angular.isObject(this.filter.filter[key].selected)) {
            angular.forEach(this.filter.filter[key].selected, (model, modelkey) => {
              this.filter.filter[key].selected[modelkey] = null;
            });
          } else {
            this.filter.filter[key].selected = null;
          }
        }
      } else {
        // für alwaysActive
        this.$storage[this.tableName + "_filter"][this.filter.filter[key].param] = this.filter.filter[key].initial;
        this.$storage[this.tableName + "_filter_close"] = this.$storage[this.tableName + "_filter_close"] || {};
        this.$storage[this.tableName + "_filter_close"][this.filter.filter[key].param] = true;

        const params = this.$storage[this.tableName + "_filter"];
        this.$location.search(this.$httpParamSerializer(params)).replace();
        this.filter.filter[key].selected = this.filter.filter[key].initial;
      }
    } else if (!this.filter.filter[key].statusShow) {
      this.sortFilter.push(key);
      this.autoFocus = key;
      if (this.filter.filter[key].alwaysActive) {
        this.$storage[this.tableName + "_filter_close"] = this.$storage[this.tableName + "_filter_close"] || {};
        this.$storage[this.tableName + "_filter_close"][this.filter.filter[key].param] = false;
      }
    }
    this.filter.filter[key].statusShow = !this.filter.filter[key].statusShow;
    this.filterSearch = undefined;
    this.filteredFilterSelection();
  }

  getFilterVarianten() {
    this.Nutzerfilter.getCurrentList(this.tableName).then(
      response => {
        this.filterVarianten = response;
      }
    );
  }

  chooseFilterVarianten(obj) {
    if (obj) {
      this.modelFilterVarianten = obj;
      for (let i = 0; i < this.filterVarianten.length; i++) {
        if (this.filterVarianten[i].pk === obj.pk) {
          this.currentFilter = this.filterVarianten[i];
          break;
        }
      }
      this.showFilterFields();
    } else {
      this.modelFilterVarianten = null;
      this.currentFilter = null;
      this.showFilterFields();
    }
  }

  showFilterFields() {
    this.sortFilter = [];
    angular.forEach(this.filter.filter, (filter, key) => {
      if (this.currentFilter && angular.isDefined(this.currentFilter.nfi_conf[key])) {
        this.filter.filter[key].selected = this.currentFilter.nfi_conf[key];
        this.filter.filter[key].statusShow = true;
        this.sortFilter.push(key);
      } else {
        this.filter.filter[key].statusShow = false;
        if (this.filter.filter[key].multiSelect) {
          this.filter.filter[key].selected = [];
        } else if (this.filter.filter[key].choices) {
          this.filter.filter[key].selected = [];
        } else {
          this.filter.filter[key].selected = null;
        }
      }
    });
  }

  filterSelectOpen($event) {
    this.$timeout(() => {
      let parent;
      let current = $event.target;
      while (!parent && current) {
        if (current.className.search("dias-filter") !== -1) {
          parent = current;
        } else {
          current = current.parentNode;
        }
      }
      if (!parent) {
        return;
      }
      const input = angular.element(parent).find("input");
      if (input && input.length > 0) {
        input[0].focus();
      }
    });
  }

  filteredFilterSelection() {
    let search;
    this.countFilterOptions = 0;
    if (this.filterSearch && this.filterSearch.length > 0) {
      search = this.filterSearch.toLowerCase();
    }
    const numGroups = Object.keys(this.filterSelection).length;
    Object.keys(this.filterSelection).forEach(
      group => this.filterSelection[group].splice(0)
    );
    this.filter.sortOrder.forEach(key => {
      if ((
        !this.filter.filter[key]
      ) || (
        search &&
          numGroups > 1 &&
          this.filter.filter[key].label.toLowerCase().search(search) === -1 &&
          this.filter.filter[key].group.toLowerCase().search(search) === -1
      ) || (
        search &&
          numGroups === 1 &&
          this.filter.filter[key].label.toLowerCase().search(search) === -1
      ) ||
          this.filter.filter[key].statusShow) {
        return;
      }
      if (!angular.isArray(this.filterSelection[this.filter.filter[key].group])) {
        this.filterSelection[this.filter.filter[key].group] = [key];
        this.countFilterOptions++;
      } else {
        this.filterSelection[this.filter.filter[key].group].push(key);
        this.countFilterOptions++;
      }
    });
    if (angular.isArray(this.filter.groupOrder)) {
      this.groupOrder = Object.keys(this.filterSelection).sort((a, b) => {
        return this.filter.groupOrder.indexOf(a) - this.filter.groupOrder.indexOf(b);
      });
    } else {
      this.groupOrder = Object.keys(this.filterSelection).sort();
    }
  }

  createModalFilter() {
    this.diasModalDialog({
      title: "Suche speichern unter",
      template: createFilterTemplate,
      controller: createFilterController,
      extras: {
        filterName: this.tableName,
        sortFilter: this.sortFilter,
        filter: this.filter.filter
      }
    }).then(
      response => {
        if (response) {
          this.addInFilterVarianten(response);
        }
      }
    );
  }

  addInFilterVarianten(response) {
    this.filterVarianten.unshift(response);
    this.modelFilterVarianten = response.pk;
    this.chooseFilterVarianten();
  }

  updateFilterVarianten(response) {
    for (let i = 0; i < this.filterVarianten.length; i++) {
      if (this.filterVarianten[i].pk === response.pk) {
        this.filterVarianten[i] = response;
        break;
      }
    }
    this.modelFilterVarianten = response.pk;
    this.chooseFilterVarianten();
  }

  confirmUpdateFilter() {
    return this.diasConfirmDialog({
      titleTemplate: "Suchformular",
      contentTemplate: `<p>Sind Sie sicher, dass Sie dieses Suchformular ` + this.currentFilter.nfi_kbez + ` ändern wollen?</p>`,
      okLabel: "Ändern",
      okCallback: () => this.updateFilter()
    });
  }

  updateFilter() {
    const data = {
      nfi_kennung: this.tableName,
      nfi_kbez: this.currentFilter.nfi_kbez,
      nfi_conf: this.collectObj()
    };
    this.Nutzerfilter.one(this.currentFilter.pk).customPUT(data).then(
      response => {
        this.DiasNotification.page.success("Suchformular ist geändert");
        this.updateFilterVarianten(response);
      }
    );
  }

  collectObj() {
    const filterTemp = {};
    angular.forEach(this.sortFilter, key => {
      filterTemp[key] = this.filter.filter[key].selected;
    });
    return filterTemp;
  }

  confirmDeleteFilter() {
    return this.diasConfirmDialog({
      titleTemplate: "Suchformular",
      contentTemplate: `<p>Sind Sie sicher, dass Sie dieses Suchformular ` + this.currentFilter.nfi_kbez + ` löschen wollen?</p>`,
      okLabel: "Löschen",
      okCallback: () => this.deleteFilter()
    });
  }

  deleteFilter() {
    this.Nutzerfilter.one(this.currentFilter.pk).remove().then(
      () => {
        this.DiasNotification.page.success("Suchformular ist gelöscht");
        this.defaultFilter();
      }
    );
  }

  defaultFilter() {
    for (let i = 0; i < this.filterVarianten.length; i++) {
      if (this.filterVarianten[i].pk === this.currentFilter.pk) {
        this.filterVarianten.splice(i, 1);
        break;
      }
    }
    this.currentFilter = null;
    this.modelFilterVarianten = null;
    this.sortFilter = [];
    angular.forEach(this.filter.filter, filter => {
      filter.statusShow = false;
    });
  }

  compareNumeric(a, b) {
    if (a.id > b.id) {
      return 1;
    }
    if (a.id < b.id) {
      return -1;
    }
  }

  initialCollMultiselect() {
    for (let i = 0; i < this.columnDefs.length; i++) {
      if (this.columnDefs[i].field === "detailToggle") {
        this.columnDefs.splice(i, 1);
        i--;
      } else {
        this.columnDefs[i].id = i;
      }
    }
    this.columnDefs[this.columnDefs.length - 1].disabled = true;
    this.collDefault = this.collDefault || angular.copy(this.columnDefs);

    if (!this.userConfig) {
      this.tableRowsModel = [];
      for (let i = 0; i < this.collDefault.length; i++) {
        if (!this.collDefault[i].invisible) {
          this.tableRowsModel.push(this.collDefault[i]);
        }
      }
    }
    this.columnDefs = this.rowsModeltoColumnDefs(this.tableRowsModel, this.columnDefs);
    // Für neue oder geänderte Felder
    this.tableRowsModel = angular.copy(this.columnDefs);
  }

  _actionDefault(row) {
    if (this.actionDefault && this.actionDefault.callback && !row[this.actionDefault.condition]) {
      this.actionDefault.callback(row);
      this.prepareRowActions(row);
    }
  }

  changePageSize(size) {
    if (this.tableName && this.user && this.user.pk) {
      this.multiselectRowsSave(size);
    } else {
      if (size) {
        this.itemsPerPage = size;
      } else {
        this.$scope.vm.reloadData();
      }
    }
  }

  _wfStatusColumnsKey(col) {
    let key = "wf_datum_";
    if (col.lookup) {
      key = key + col.lookup + "_";
    }
    key = key + col.pk.replace(/-/g, "");
    return key;
  }

  _ffkColumnsKey(col) {
    let key = "ffk_";
    if (col.lookup) {
      key = key + col.lookup + "_";
    }
    key = key + col.pk.replace(/-/g, "");
    return key;
  }
}

export default DiasTableController;
