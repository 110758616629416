import translate from "../../../../../../global/directives/translate";
import {
  assign,
  cloneDeep,
  forEach,
  isNil,
} from "lodash-es";
import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    PuxButton
  },
  directives: {
    translate,
  },
  props: {
    listConfig: {
      type: Array,
      required: true,
    },
    modelDefault: {
      type: Object,
      required: true,
    },
    currentModel: {
      type: Object,
      required: false,
      default: undefined,
    },
    currentIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
    model: {
      type: Array,
      required: true,
    },
    errors: {
      type: Object,
      default: undefined,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modelLocal: {},
    };
  },
  computed: {
    header() {
      return this.currentModel ? "Abschlag aktualisieren" : "Auszahlungsmodularität hinzufügen";
    },

    options() {
      return {
        showCloseButton: true,
        required: true,
        list: this.listConfig,
      };
    },

    statusDisabled() {
      let statusDisabled = false;
      forEach(this.listConfig, item => {
        if (item.required) {
          if (item.type === "multiselect") {
            if (!this.modelLocal[item.id] || !this.modelLocal[item.id].length) {
              statusDisabled = true;
              return false;
            }
          } else {
            if (isNil(this.modelLocal[item.id])) {
              statusDisabled = true;
              return false;
            }
          }
        }
      });
      return statusDisabled;
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.currentModel) {
        forEach(this.currentModel, (item, key) => {
          if (isNil(item) && key in this.modelDefault) {
            // eslint-disable-next-line vue/no-mutating-props
            this.currentModel[key] = this.modelDefault[key];
          }
        });
        this.modelLocal = assign({}, this.modelDefault, this.currentModel);
      } else {
        this.modelLocal = cloneDeep(this.modelDefault);
      }
    },

    save() {
      const DATA = cloneDeep(this.modelLocal);
      const MODEL = cloneDeep(this.model) || [];

      if (this.currentModel) {
        MODEL.splice(this.currentIndex, 1, DATA);
      } else {
        MODEL.push(DATA);
      }
      this.close({ model: MODEL });
    },
  },
};
