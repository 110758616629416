"use strict";

import FoerderantragBase from "./foerderantraege.base.service";

class Foerderantrag extends FoerderantragBase {
  constructor() {
    super("foerderantraege");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.batchZuweisen = (pks, nutzer) => {
      return collection.customPOST({ pks: pks, nutzer: nutzer }, "batch_zuweisen");
    };

    return collection;
  }
}

export default Foerderantrag;
