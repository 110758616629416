import {
  computed,
  ref,
  toRef,
} from "vue";

import HttpAPI from "../../../../../../global/compositionAPI/HttpAPI";

import {
  initModelFromList,
  toFormElementFromParameter
} from "../../../../../../global/functions/mappingForParameterToFormElement";
import {
  forEach,
} from "lodash-es";

export default function SitzungsartTagesordnungModuleAPI(props) {
  const tagesordnungspunkt = toRef(props, "tagesordnungspunkt");
  const tagesordnungspunkteUrl = toRef(props, "tagesordnungspunkteUrl");
  const tagesordnungspunktUrl = computed(() => {
    return `${ tagesordnungspunkteUrl.value }${ tagesordnungspunkt.value.pk }/`;
  });
  const tagesordnungspunktModuleUrl = computed(() => {
    return `${ tagesordnungspunktUrl.value }module/`;
  });

  const module = ref([]);
  const loadingModule = ref(false);

  const sitzungsmoduleKeyByPk = toRef(props, "sitzungsmoduleKeyByPk");
  const prepareModule = response => {
    forEach(response, item => {
      item.modul_obj = sitzungsmoduleKeyByPk.value[item.modul] || {};
      item.parameterList = toFormElementFromParameter({
        obj: item.tom_parameterin,
        showLabel: true,
      }).list;
      item.model = initModelFromList({ list: item.parameterList });
    });
    module.value = response;
  };

  const {
    getListHttp,
  } = HttpAPI();
  const loadTopModules = () => {
    loadingModule.value = true;
    getListHttp({
      url: tagesordnungspunktModuleUrl.value,
    }).then(
      response => {
        prepareModule(response);
        loadingModule.value = false;
      }
    );
  };

  return {
    loadingModule,
    loadTopModules,
    module,
    tagesordnungspunktUrl,
    tagesordnungspunktModuleUrl,
  };
}
