import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";

// @vue/component
export default {
  name: "ModulePreviewIcon",
  components: {
    PuxIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: true,
    },
    tooltipExtra: {
      type: Object,
      required: false,
      default: undefined,
    },
    count: {
      type: Number,
      default: 0,
    },
  },
};
