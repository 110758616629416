import Alert from "../../../../global/components/Alert/Alert.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragstellerWizardBeschreibung",
  components: {
    Alert,
    FormElement,
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    errors: {
      type: Object,
      default: () => ({}),
    },
    changeModel: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      optionsBeschreibung: {
        type: "richtext",
        id: "ast_beschreibung",
        view: "v-alt",
        label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_BESCHREIBUNG_ORGANISATION_",
        required: true,
        translate: true,
      },
      model: {},
    };
  },
  computed: {
    isErrors() {
      return !!size(this.errors);
    },
  },
  methods: {
    onChangeModel() {
      this.changeModel({ model: this.model });
    },
  },
};
