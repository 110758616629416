import TaskLog from "../../../../global/components/TaskLog/TaskLog.vue";

// @vue/component
export default {
  name: "UebermittelnProgress",
  components: {
    TaskLog,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    taskId: {
      type: String,
      default: undefined,
    },
    resolveLog: {
      type: Function,
      required: true,
    },
    updateTable: {
      type: Function,
      required: true,
    },
  },
  methods: {
    onFinish() {
      this.resolveLog({ item: this.item });
      this.updateTable();
    },
  },
};
