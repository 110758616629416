import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import IjfTemplateTreeBranch from "./IjfTemplateTreeBranch/IjfTemplateTreeBranch.vue";
import IjfTemplateFeldModalCreateOrUpdate from "./IjfTemplateFeldModalCreateOrUpdate/IjfTemplateFeldModalCreateOrUpdate.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";

import {
  cloneDeep,
  find,
  forEach,
  get,
  includes,
  last,
  map,
  maxBy,
  size,
  sortBy,
} from "lodash-es";

// @vue/component
export default {
  name: "IjfTemplateDetails",
  components: {
    ContextPanel,
    IjfTemplateTreeBranch,
    IjfTemplateFeldModalCreateOrUpdate,
    Modal,
    PuxPageDetails,
    PuxTranslate
  },
  setup() {
    const {
      getHttp,
      postHttp,
    } = HttpAPI();

    return {
      getHttp,
      postHttp,
    };
  },
  data() {
    return {
      template_ver: undefined,
      statusModalCreateOrUpdate: false,
      statusModalDeleteItem: false,
      currentElement: undefined,
      currentElementIndex: undefined,
      createNewVersionButtonId: "ijf_template_new_version",
      statusModalNewVersion: false,
      tree: [],
      loading: {
        global: true,
        modal: false,
      },
      feldtypen: undefined,
      kijffeldtypUrl: "katalog/?key=kijffeldtyp",
      itemsWithFalsePositions: [],
      flatTree: [],
    };
  },
  computed: {
    pageTitleExtra() {
      return { 
        vorlage_kbez: this.pageTitle,
      };
    },

    pageTitleSecondaryExtra() {
      const version = get(this.template_ver, "ivv_version");

      return {
        vorlage_title: this.pageTitle,
        version,
      };
    },

    pageTitle() {
      return get(this.template_ver, "vorlage_kbez");
    },

    id() {
      return this.$stateParams.id;
    },

    createVersionUrl() {
      return `ijf_vorlage/${ this.template_ver.vorlage }/create_new_version/`;
    },

    vorlageVersionUrl() {
      return `ijf_vorlageversion/${ this.id }/`;
    },

    updateFieldUrl() {
      return `ijf_vorlageversion/${ this.id }/update_or_create_fields/`;
    },

    deleteItemUrl() {
      return `ijf_vorlageversion/${ this.id }/delete_field/`;
    },

    itemToDeleteTitle() {
      return get(this.currentElement, "ivf_name");
    },

    selectorClose() {
      return `#${ this.createNewVersionButtonId }`;
    },

    confirmOptionsNewVersion() {
      return {
        okClass: "btn btn-primary",
        title: "_TXT_IJF_DETAILS_CONFIRM_MSG_NEW_VERSION_TITLE_",
        msg: "_TXT_IJF_DETAILS_CONFIRM_MSG_NEW_VERSION_{{vorlage_title}}_",
        okLabel: "_TXT_IJF_DETAILS_CONFIRM_MSG_NEW_VERSION_TITLE_",
        okCallback: this.createNewVersion,
        cancelCallback: this.closeNewVersionModal,
        loading: this.loading.modal,
        extra: { vorlage_title: this.pageTitle },
      };
    },

    confirmOptionsDeleteItem() {
      return {
        okClass: "btn btn-primary",
        title: "_TXT_IJF_DETAILS_CONFIRM_MSG_DELETE_ITEM_TITLE_",
        msg: "_TXT_IJF_DETAILS_CONFIRM_MSG_DELETE_ITEM_{{item_title}}_",
        okLabel: "_TXT_IJF_DETAILS_CONFIRM_MSG_DELETE_ITEM_TITLE_",
        okCallback: this.deleteElement,
        cancelCallback: this.closeDeleteItemModal,
        loading: this.loading.modal,
        extra: { item_title: this.itemToDeleteTitle },
      };
    },

    spalten() {
      return [
        {
          id: "ivf_name",
          htmlId: "ijf_ver_ivf_name",
          type: "text",
          minlength: 3,
          maxlength: 200,
          label: "_TXT_IJF_DETAILS_NAME_LABEL_",
          required: true,
          pos: 1,
          view: "v",
          width: 0
        },
        {
          id: "feldtyp",
          htmlId: "ijf_ver_feldtyp",
          type: "select",
          url: this.kijffeldtypUrl,
          keyId: "pk",
          keyLabel: "bez",
          label: "_TXT_IJF_DETAILS_TYPE_LABEL_",
          required: true,
          pos: 2,
          view: "v",
          width: 10
        },
        {
          id: "ivf_required",
          htmlId: "ijf_ver_ivf_required",
          type: "boolean",
          label: "_TXT_IJF_DETAILS_REQUIRED_LABEL_",
          trueLabel: "_TXT_YES_",
          falseLabel: "_TXT_NO_",
          required: true,
          defaultValue: true,
          pos: 3,
          view: "v-alt",
          width: 10
        },
        {
          id: "ivf_kbez",
          htmlId: "ijf_ver_ivf_kbez",
          type: "text",
          minlength: 3,
          maxlength: 200,
          label: "_TXT_IJF_DETAILS_DESCRIPTION_LABEL_",
          required: true,
          pos: 4,
          view: "v",
          width: 25
        },
      ];
    },

    modelDefault() {
      return {
        children: [],
        feldtyp: null,
        ivf_argument: null,
        ivf_kbez: null,
        ivf_name: null,
        ivf_required: true,
        ivf_pos: null,
        max_val: null,
        min_val: null,
        parent: null,
        vorlageversion: this.id,
      };
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading.global = true;
        const [
          templateVer,
          feldtypen,
        ] = await Promise.all([
          this.getHttp({
            url: this.vorlageVersionUrl,
          }),
          this.getHttp({
            url: this.kijffeldtypUrl,
          }),
        ]);

        this.initTree(templateVer);
        this.feldtypen = feldtypen;
        this.loading.global = false;
      } catch (e) {
        console.error(e);
      }
    },

    initTree(response, forceUpdate = true) {
      this.template_ver = response;
      this.tree = this.orderTree(this.template_ver.ijfvorlagefeld);
      this.flatTree = [];
      this.checkPositions(this.tree);
      if (forceUpdate && size(this.itemsWithFalsePositions)) {
        this.updatePositions();
      }
    },

    orderTree(items) {
      forEach(items, item => {
        if (size(item.children)) {
          item.children = this.orderTree(item.children);
        }
      });

      return sortBy(items, item => Number(item.ivf_pos));
    },

    checkPositions(items) {
      forEach(items, (item, idx) => {
        if (`${ item.ivf_pos }` !== `${ idx }`) {
          item.ivf_pos = `${ idx }`;
          this.itemsWithFalsePositions.push(item);
        }
        if (size(item.children)) {
          this.checkPositions(item.children);
        }
        if (includes(["Array", "Object"], item.feldtyp_kbez)) {
          this.flatTree.push(item);
        }
      });
    },

    updatePositions() {
      const ijfvorlagefields = map(this.itemsWithFalsePositions, item => {
        const itemLocal = cloneDeep(item);
        delete itemLocal.children;

        return itemLocal;
      });
      const model = {
        ijfvorlagefields,
      };
      this.itemsWithFalsePositions = [];
      this.save({ model });
    },

    openModalCreateOrEdit({ element, parent }) {
      this.currentElement = element;
      this.currentParent = parent;
      this.currentElementIndex = parent ? size(parent.children) : size(this.tree);
      this.statusModalCreateOrUpdate = true;
    },

    openModalDeleteItem({ element }) {
      this.currentElement = element;
      this.statusModalDeleteItem = true;
    },

    closeModalCreateOrUpdate() {
      this.currentElement = undefined;
      this.currentElementIndex = undefined;
      this.statusModalCreateOrUpdate = false;
    },

    closeDeleteItemModal() {
      this.currentElement = undefined;
      this.statusModalDeleteItem = false;
    },

    async save({ model }, global = false, holdModel = false) {
      try {
        if (global) {
          this.loading.global = true;
        } 
        if (holdModel) {
          this.initTree(this.template_ver);
        }

        this.currentElement = undefined;
        this.currentElementIndex = undefined;

        const response = await this.postHttp({
          url: this.updateFieldUrl,
          data: model,
        });

        if (global) {
          this.loading.global = false;
        }
        if (!holdModel) {
          this.initTree(response, false);
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async createNewVersion() {
      try {
        this.loading.modal = true;
        const response = await this.postHttp({
          url: this.createVersionUrl,
        });
        const newVersion = maxBy(response.versionen, "ivv_version");
        this.loading.modal = false;

        this.$goTo("root.ijf_template_ver.detail", { id: newVersion.pk });
      } catch (e) {
        console.error(e);
      }
    },

    async deleteElement() {
      try {
        this.loading.modal = true;
        const response = await this.postHttp({
          url: this.deleteItemUrl,
          data: {
            ijfvorlagefield_pk: this.currentElement.pk,
          },
        });

        this.initTree(response);
        this.closeDeleteItemModal();
        this.loading.modal = false;
      } catch (e) {
        console.error(e);
      }
    },

    openNewVersionModal() {
      this.statusModalNewVersion = true;
    },

    closeNewVersionModal() {
      this.statusModalNewVersion = false;
    },

    showMoveUpButtonLocal(item) {
      const { ivf_pos } = item;
      
      return Number(ivf_pos) !== 0;
    },

    showMoveDownButtonLocal(item) {
      const parentArray = item.parent ? get(find(this.flatTree, { pk: item.parent }), "children") : this.tree;
      const { ivf_pos } = item;

      return Number(ivf_pos) !== Number(get(last(parentArray), "ivf_pos"));
    },

    moveUp(firstItemToSwap) {
      const parentArray = firstItemToSwap.parent ? get(find(this.flatTree, { pk: firstItemToSwap.parent }), "children") : this.tree;
      const { ivf_pos } = firstItemToSwap;
      const secondItemToSwap = find(parentArray, item => Number(ivf_pos) === (Number(item.ivf_pos) + 1));
      firstItemToSwap.ivf_pos = `${ Number(firstItemToSwap.ivf_pos) - 1 }`;
      secondItemToSwap.ivf_pos = `${ Number(secondItemToSwap.ivf_pos) + 1 }`;
      const firstItemToSwapLocal = cloneDeep(firstItemToSwap);
      const secondItemToSwapLocal = cloneDeep(secondItemToSwap);
      delete firstItemToSwapLocal.children;
      delete secondItemToSwapLocal.children;

      this.save({
        model: {
          ijfvorlagefields: [
            firstItemToSwapLocal,
            secondItemToSwapLocal,
          ]
        },
      }, false, true);
    },

    moveDown(firstItemToSwap) {
      const parentArray = firstItemToSwap.parent ? get(find(this.flatTree, { pk: firstItemToSwap.parent }), "children") : this.tree;
      const { ivf_pos } = firstItemToSwap;
      const secondItemToSwap = find(parentArray, item => Number(ivf_pos) === (Number(item.ivf_pos) - 1));
      firstItemToSwap.ivf_pos = `${ Number(firstItemToSwap.ivf_pos) + 1 }`;
      secondItemToSwap.ivf_pos = `${ Number(secondItemToSwap.ivf_pos) - 1 }`;
      const firstItemToSwapLocal = cloneDeep(firstItemToSwap);
      const secondItemToSwapLocal = cloneDeep(secondItemToSwap);
      delete firstItemToSwapLocal.children;
      delete secondItemToSwapLocal.children;

      this.save({
        model: {
          ijfvorlagefields: [
            firstItemToSwapLocal,
            secondItemToSwapLocal,
          ]
        },
      }, false, true);
    },
  },
};
