import ActionsPageMain from "../ActionsPageMain/ActionsPageMain.vue";
import AngularLink from "../AngularLink/AngularLink.vue";
import AsideRight from "../AsideRight/AsideRight.vue";
import PageTabTitle from "../PageTabTitle/PageTabTitle.vue";
import PuxCloak from "../PuxCloak/PuxCloak.vue";
import PuxIcon from "../PuxIcon/PuxIcon.vue";
import PuxTranslate from "../PuxTranslate/PuxTranslate.vue";

import loading from "../../directives/loading";

// @vue/component
export default {
  name: "PuxPageDetails",
  components: {
    ActionsPageMain,
    AngularLink,
    AsideRight,
    PageTabTitle,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
  },
  directives: {
    loading,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      info: "Nur Loading anzeigen",
    },
    loadingMsg: {
      type: String,
      required: false,
      default: undefined,
      info: "Text für Loading",
    },
    reloading: {
      type: Boolean,
      required: false,
      info: "Status v-loading zu anzeigen",
    },
    reloadingMsg: {
      type: String,
      required: false,
      default: undefined,
      info: "Text für v-loading",
    },
    pageTitlePlaceholder: {
      type: String,
      required: true,
      info: "Text für <title>",
    },
    pageTitleExtra: {
      type: Object,
      required: false,
      default: undefined,
      info: "Extra-Objekt für Übersetzung von <title>",
    },
    linkBack: {
      type: String,
      required: false,
      default: undefined,
      info: "Props: 'sref' für AngularLink (Zurück). Z.B.: 'root.rollenverwaltung'",
    },
    linkBackParameter: {
      type: Object,
      required: false,
      default: undefined,
    },
    linkBackText: {
      type: String,
      required: false,
      default: undefined,
      info: "Text für AngularLink (Zurück)"
    },
    linkBackExtra: {
      type: Object,
      required: false,
      default: undefined,
      info: "Extra-Objekt für Übersetzung von AngularLink (Zurück)",
    },
    classHeader: {
      type: [String, Object],
      required: false,
      default: undefined,
      info: "Zusätzliche CSS-Klasse für header",
    },
    classActions: {
      type: [String, Object],
      required: false,
      default: undefined,
      info: "Zusätzliche CSS-Klasse für actions",
    },
    classBody: {
      type: [String, Object],
      required: false,
      default: undefined,
      info: "Zusätzliche CSS-Klasse für body",
    },
    classTable: {
      type: [String, Object],
      required: false,
      default: undefined,
      info: "Zusätzliche CSS-Klasse für Tabelle",
    },
    isPageDomRenderedWhenWizard: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    isPageTabTitleVisible() {
      return !(this.pageTitleExtra && this.loading);
    },

    isAngular() {
      return !this.$statusNotAngular;
    },
  },
};
