import NachrichtenLink from "../../../../Nachrichten/NachrichtenLink/NachrichtenLink.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";

import KommunikationsdatenUUIDs from "../../../../../const/KommunikationsdatenUUIDs";
import {
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungUebersichtAstAnsprechpartner",
  components: {
    NachrichtenLink,
    PuxIcon,
  },
  props: {
    ansprechpartner: {
      type: Object,
      required: true,
    },
    ansprechpartnerIndex: {
      type: Number,
      required: true,
    },
    vertragPk: {
      type: String,
      required: true,
    },
  },
  computed: {
    phoneNumbers() {
      return filter(this.ansprechpartner.nutzer.kommunikationsdaten, item => {
        return item.kommtyp && item.kommtyp.kot_id === KommunikationsdatenUUIDs.telefonnr1;
      });
    },

    nachrichtenLinkLabel() {
      return `${ this.ansprechpartner.nutzer.name } (${ this.ansprechpartner.asp_kommentar })`;
    },

    nachrichtenLinkId() {
      return `nachrichten_link_ansprechpartner_${ this.ansprechpartnerIndex }`;
    },
  },
};
