<div><h3>Filters</h3><code>currency</code><div class="row margin-top-md"><div class="col-lg-6"><ul><li>{{ filterCurrency(123456789.123) }} €</li><li>{{ filterCurrency(123456789) }} €</li><li>{{ filterCurrency("234.345,456") }} €</li><li>{{ filterCurrency("345.456") }} €</li><li>{{ currency2 }} €</li></ul></div><div class="col-lg-6"><div v-highlight=""><pre>PUG<code>{{ currency1 }}</code></pre><pre>JS<code>import FilterCurrencyMixin from ".../global/mixins/FiltersMixin";

export default {
  name: "vue-styleguide-filters",
  mixins: [
    FilterCurrencyMixin,
  ],
  data () {
    return {
      currency2: this.filterCurrency(1231321321321.5454),
    }
  },
}</code></pre></div></div></div><code>date</code><div class="row margin-top-md"><div class="col-lg-6"><ul><li>{{ filterDate("2018-01-23") }}</li><li>{{ filterDate("2018-01-23", "MM.YYYY") }}</li><li>{{ filterDate("2018-01-23", "YYYY") }}</li><li>{{ filterDate("2018-01-23", "MM") }}</li></ul></div><div class="col-lg-6"><div v-highlight=""><pre>PUG<code>{{ date1 }}</code></pre><pre>JS<code>import {
  FilterDateMixin
} from ".../global/mixins/FiltersMixin";

export default {
  name: "vue-styleguide-filters",
  mixins: [
    FilterDateMixin,
  ],
}
</code></pre></div></div></div><code>iban</code><div class="row margin-top-md"><div class="col-lg-6"><ul><li>{{ filterIban("de12345678901234567890", true) }}</li><li>{{ filterIban("de12345678901234567890") }}</li></ul></div><div class="col-lg-6"><div v-highlight=""><pre>PUG<code>{{ iban1 }}</code></pre><pre>JS<code>import {
  FilterIbanMixin
} from ".../global/mixins/FiltersMixin";

export default {
  name: "vue-styleguide-filters",
  mixins: [
    FilterIbanMixin,
  ],
}</code></pre></div></div></div><code>spaced</code><div class="row margin-top-md"><div class="col-lg-6"><ul><li>{{ filterSpaced("6000000000") }}</li></ul></div><div class="col-lg-6"><div v-highlight=""><pre>PUG<code>{{ spaced1 }}</code></pre><pre>JS<code>import {
  FilterSpacedMixin
} from ".../global/mixins/FiltersMixin";

export default {
  name: "vue-styleguide-filters",
  mixins: [
    FilterSpacedMixin,
  ],
}</code></pre></div></div></div></div>