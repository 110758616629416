import AngularLink from "../../../../../../libs/vue/client/vue/global/components/AngularLink/AngularLink.vue";
import PageTabTitle from "../../../../../../libs/vue/client/vue/global/components/PageTabTitle/PageTabTitle.vue";
import SmartTable from "../../../../../../libs/vue/client/vue/global/components/table/table.vue";
import Spaced from "../../../../../../libs/vue/client/vue/global/components/Spaced/Spaced.vue";

import getTableApi from "../../../../../../libs/vue/client/vue/const/TableApis";

const TABLE_ID = "akkrstudiengang";

// @vue/component
export default {
  name: "AkkreditierteStudiengaengeList",
  components: {
    AngularLink,
    SmartTable,
    PageTabTitle,
    Spaced,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [
          {
            label: this.$gettext("Akkreditierungs-ID"),
            id: "akkr_pro_ursprung_proid",
            hide: true,
            priority: 9,
          },
          {
            label: this.$gettext("Name"),
            id: "name",
            sortable: true,
            sortId: "ordering_name",
            slot: "name",
            sortingDefault: "asc",
            priority: 10,
          },
          {
            label: this.$gettext("Kombinationsstudiengang"),
            id: "mantelsg_name",
            sortable: false,
            hide: false,
            priority: 11,
          },
          {
            label: this.$gettext("Studiengangs-ID (Stammdaten)"),
            id: "sg_head_id",
            hide: true,
            priority: 11,
          },
          {
            label: this.$gettext("HSK Laufnummer"),
            id: "sg_laufnr",
            hide: true,
            priority: 20,
          },
          {
            label: this.$gettext("Akkreditiert ab"),
            id: "akkr_pro_von",
            sortable: true,
            sortId: "pro_von",
            filter: "date",
            priority: 30,
          },
          {
            label: this.$gettext("Akkreditiert bis"),
            id: "akkr_pro_bis",
            sortable: true,
            sortId: "pro_bis",
            filter: "date",
            priority: 40,
          },
          {
            label: this.$gettext("Akkreditierungstyp"),
            id: "akkr_typ_kbez",
            sortable: true,
            sortId: "typ__akt_kbez",
            priority: 50,
          },
          {
            label: this.$gettext("Akkreditierungsstatus"),
            id: "akkr_status_kbez",
            sortable: true,
            sortId: "status__kas_kbez",
            hide: true,
            priority: 60,
          },
          {
            label: this.$gettext("Akkreditierungsverfahren"),
            id: "akkr_verfahren_kbez",
            sortable: true,
            sortId: "verfahren__kav_kbez",
            hide: true,
            priority: 70,
          },
          {
            label: this.$gettext("Begutachtende Agentur"),
            id: "akkr_agentur_kbez",
            sortable: true,
            sortId: "agentur__o_kbez",
            hide: true,
            priority: 80,
          },
          {
            label: this.$gettext("Studientyp"),
            id: "typ_kbez",
            sortable: true,
            sortId: "sg_typ_kbez",
            hide: true,
            priority: 90,
          },
          {
            label: this.$gettext("Lehramtstyp"),
            id: "lehramtstyp_kbez",
            sortable: true,
            sortId: "sg_lehramttyp_kbez",
            hide: true,
            priority: 100,
          },
          {
            label: this.$gettext("Abschlussgrad"),
            id: "abschlussgrad_kbez",
            sortable: true,
            sortId: "studiengang__abschlussgrad__ag_kbez",
            priority: 110,
          },
          {
            label: this.$gettext("Hochschule"),
            id: "hs_bez",
            sortable: true,
            sortId: "studiengang__hochschule__hs_bez",
            priority: 120,
          },
          {
            label: this.$gettext("Hochschul-ID"),
            id: "hs_id",
            hide: true,
            priority: 121,
          },
          {
            label: this.$gettext("Standort"),
            id: "standorte_kbez",
            slot: "standorte_kbez",
            priority: 130,
          },
          {
            label: this.$gettext("Studiengangart"),
            id: "art_kbez",
            sortable: true,
            sortId: "sg_art_kbez",
            priority: 140,
          },
          {
            label: this.$gettext("Studienformen"),
            id: "studienformen_bez",
            slot: "studienformen_bez",
            hide: true,
            priority: 150,
          },
          {
            label: this.$gettext("Regelstudienzeit"),
            id: "sg_regelstudienzeit",
            sortable: true,
            sortId: "studi_regelstudienzeit",
            hide: true,
            priority: 160,
          },
          {
            label: this.$gettext("Regelstudieneinheit"),
            id: "regelstudieneinheit_kbez",
            sortable: true,
            sortId: "studi_regelstudieneinheit",
            hide: true,
            priority: 170,
          },
          {
            label: this.$gettext("Mastertyp"),
            id: "mastertyp_kbez",
            sortable: true,
            sortId: "sg_mastertyp_kbez",
            hide: true,
            priority: 180,
          },
          {
            label: this.$gettext("Studienfelder"),
            id: "studienfelder_bez",
            slot: "studienfelder",
            hide: true,
            priority: 190,
          },
          {
            label: this.$gettext("Studienbereiche"),
            id: "studienbereiche",
            slot: "studienbereiche",
            hide: true,
            priority: 200,
          },
          {
            label: this.$gettext("Fächergruppen"),
            id: "faechergruppen",
            slot: "faechergruppen",
            hide: true,
            priority: 210,
          },
          {
            label: this.$gettext("Akkreditiert"),
            id: "akkreditiert",
            priority: 220,
          },
          {
            label: this.$gettext("Akkreditierende Organisation"),
            id: "akkr_organisation",
            sortId: "akkr_orga_kbez",
            sortable: true,
            hide: true,
            priority: 230,
          },

        ],
        rowActions: [
          {
            label: "Details Anzeigen",
            route: "root.akkrstudiengang.detail",
            routeParameters: [{ routeId: "id", rowPath: "sg_head_id" }],
          },
        ],

        label: this.$gettext("Akkreditierte Studiengänge"),
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
        filterKeyGroupList: [
          this.$gettext("Studiengang"),
          this.$gettext("Akkreditierung"),
        ],
      },
      filters: [
        {
          type: "text",
          id: "sg_bez",
          label: this.$gettext("Name"),
          group: this.$gettext("Studiengang"),
          main: true,
        },
        {
          type: "multiselect",
          url: "hochschulen_oeffentlich/?fields=hs_id&fields=hs_kbez&fields=hs_bez",
          id: "hochschule",
          label: this.$gettext("Hochschule"),
          keyLabel: "hs_kbez",
          keyId: "hs_id",
          search: true,
          searchList: ["hs_kbez", "hs_bez"],
          group: this.$gettext("Studiengang"),
        },
        {
          type: "text",
          id: "sg_laufnr",
          label: this.$gettext("HSK Laufnummer"),
          group: this.$gettext("Studiengang"),
        },
        {
          type: "multiselect",
          url: "studienformen/?fields=sdf_id&fields=sdf_kbez&key=studienform",
          id: "studienformen",
          label: this.$gettext("Studienformen"),
          keyLabel: "sdf_kbez",
          keyId: "sdf_id",
          group: this.$gettext("Studiengang"),
        },
        {
          type: "multiselect",
          url: "studientypen/?fields=kst_id&fields=kst_kbez&key=studientyp",
          id: "typ",
          label: this.$gettext("Studientypen"),
          keyLabel: "kst_kbez",
          keyId: "kst_id",
          group: this.$gettext("Studiengang"),
        },
        {
          type: "multiselect",
          url: "lehramtstypen/?fields=lat_id&fields=lat_kbez&key=lehramtstyp",
          id: "lehramtstyp",
          label: this.$gettext("Lehramtstypen"),
          keyLabel: "lat_kbez",
          keyId: "lat_id",
          group: this.$gettext("Studiengang"),
        },
        {
          type: "multiselect",
          url: "studienfelder/?fields=stf_id&fields=stf_kbez&key=studienfeld",
          id: "studienfelder",
          label: this.$gettext("Studienfelder"),
          keyLabel: "stf_kbez",
          keyId: "stf_id",
          search: true,
          searchList: ["stf_kbez"],
          group: this.$gettext("Studiengang"),
        },
        {
          type: "multiselect",
          url: "studienbereiche/?fields=sdb_id&fields=sdb_kbez&key=studienbereiche",
          id: "studienbereiche",
          label: this.$gettext("Studienbereiche"),
          keyLabel: "sdb_kbez",
          keyId: "sdb_id",
          search: true,
          searchList: ["sdb_kbez"],
          group: this.$gettext("Studiengang"),
        },
        {
          type: "multiselect",
          url: "faechergruppen/?fields=fae_id&fields=fae_kbez&key=faechergruppe",
          id: "faechergruppen",
          label: this.$gettext("Fächergruppen"),
          keyLabel: "fae_kbez",
          keyId: "fae_id",
          search: true,
          searchList: ["fae_kbez"],
          group: this.$gettext("Studiengang"),
        },
        {
          type: "multiselect",
          url: "abschlussgrade/?fields=ag_id&fields=ag_kbez&key=abschlussgrad",
          id: "abschlussgrad",
          label: this.$gettext("Abschlussgrade"),
          keyLabel: "ag_kbez",
          keyId: "ag_id",
          group: this.$gettext("Studiengang"),
        },
        {
          type: "multiselect",
          url: "abschlussbezeichnungen/?fields=abs_id&fields=abs_kbez&key=abschlussbezeichnung",
          id: "abschlussbezeichnung",
          label: this.$gettext("Abschlussbezeichnungen"),
          keyLabel: "abs_kbez",
          keyId: "abs_id",
          search: true,
          searchList: ["abs_kbez"],
          group: this.$gettext("Studiengang"),
        },
        {
          type: "multiselect",
          id: "mastertyp",
          url: "mastertypen/?fields=mt_id&fields=mt_kbez",
          label: this.$gettext("Mastertypen"),
          keyLabel: "mt_kbez",
          keyId: "mt_id",
          search: true,
          searchList: ["mt_kbez"],
          group: this.$gettext("Studiengang"),
        },
        {
          type: "text",
          id: "sg_regelstudienzeit",
          label: this.$gettext("Regelstudienzeit"),
          group: this.$gettext("Studiengang"),
        },
        {
          type: "multiselect",
          url: "studiengangarten/?fields=sga_id&fields=sga_kbez&key=studiengangart",
          id: "art",
          label: this.$gettext("Studiengangart"),
          keyLabel: "sga_kbez",
          keyId: "sga_id",
          group: this.$gettext("Studiengang"),
        },
        {
          type: "text",
          id: "standorte",
          label: this.$gettext("Standort"),
          group: this.$gettext("Studiengang"),
        },
        {
          type: "select",
          id: "akkreditiert",
          label: this.$gettext("Akkreditiert"),
          keyLabel: "label",
          keyId: "value",
          data: [
            {
              value: "Ja",
              label: "Ja"
            },
            {
              value: "Nein",
              label: "Nein"
            },
            {
              value: "laufendesVerfahren",
              label: "laufendes Verfahren"
            }
          ],
          group: this.$gettext("Studiengang"),
        },
        {
          type: "daterange",
          id: "programmakkreditierung__pro_von",
          label: this.$gettext("Akkreditierung Anfang"),
          placeholderMin: "Beginn beliebig",
          placeholderMax: "Ende beliebig",
          group: this.$gettext("Akkreditierung"),
        },
        {
          type: "daterange",
          id: "programmakkreditierung__pro_bis",
          label: this.$gettext("Akkreditierung Ende"),
          placeholderMin: "Beginn beliebig",
          placeholderMax: "Ende beliebig",
          group: this.$gettext("Akkreditierung"),
        },
        {
          type: "multiselect",
          id: "akkr_typ",
          url: "akkreditierungstypen/?fields=akt_id&fields=akt_kbez",
          label: this.$gettext("Akkreditierungstyp"),
          keyLabel: "akt_kbez",
          keyId: "akt_id",
          search: true,
          searchList: ["akt_kbez"],
          group: this.$gettext("Akkreditierung"),
        },
        {
          type: "multiselect",
          id: "akkr_status",
          url: "akkreditierungsstatus/?fields=kas_id&fields=kas_kbez",
          label: this.$gettext("Akkreditierungsstatus"),
          keyLabel: "kas_kbez",
          keyId: "kas_id",
          search: true,
          searchList: ["kas_kbez"],
          group: this.$gettext("Akkreditierung"),
        },
        {
          type: "multiselect",
          id: "akkr_verfahren",
          url: "akkreditierungsverfahren/?fields=kav_id&fields=kav_bez",
          label: this.$gettext("Akkreditierungsverfahren"),
          keyLabel: "kav_bez",
          keyId: "kav_id",
          search: true,
          searchList: ["kav_bez"],
          group: this.$gettext("Akkreditierung"),
        },
        {
          type: "multiselect",
          id: "akkr_agentur",
          url: "begutachtende_organisationen/?fields=pk&fields=kbez",
          label: this.$gettext("Begutachtende Agentur"),
          keyLabel: "kbez",
          keyId: "pk",
          search: true,
          searchList: ["kbez"],
          group: this.$gettext("Akkreditierung"),
        },
        {
          type: "multiselect",
          url: "programmakkreditierende_organisationen/?fields=pk&fields=kbez&key=akkreditierende+Organisation",
          id: "akkr_organisation",
          label: this.$gettext("Akkreditierende Organisation"),
          keyLabel: "kbez",
          keyId: "pk",
          search: true,
          searchList: ["kbez"],
          group: this.$gettext("Akkreditierung"),
        },
      ],
    };
  },
  computed: {
    /* getHrefAntrag() {
      return row => `/foerderantrag/${ row.antrag_id }/`;
    }, */
  },
};
