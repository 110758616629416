import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "basisformular";

// @vue/component
export default {
  name: "FormulareList",
  components: {
    AngularLink,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_LBL_BASISFORMULAR_TABLE_ROW_ACTIONS_DETAILS_",
            route: "root.basisformularcortex.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
    };
  },
};
