import moment from "moment";
import translate from "../../../../../global/directives/translate";
import {
  FilterCurrencyMixin,
} from "../../../../../global/mixins/FiltersMixin";
import {
  getClassBetragAnerkanntInPersonalbaum,
} from "../../../../../const/KVertragskostenbelegStatusUUIDs";
import {
  isNil,
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "BelegInTd",
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
  ],
  props: {
    data: {
      type: Object,
      required: true,
    },
    period: {
      type: Object,
      required: true,
    },
  },
  computed: {
    titleTranslate() {
      const ARGUMENT = this.argument;
      const EXTRA = cloneDeep(this.data);
      EXTRA.argument = ARGUMENT;
      return {
        title: "_TXT_VERTRAG_DETAILS_PERSONALBAUM_BELEG_TITLE_{{argument}}_{{ALL_API}}_",
        extra: EXTRA,
      };
    },

    styles() {
      const ARGUMENT = this.argument;
      const BEGIN_PERIOD = moment(this.period.pe_von);
      const END_PERIOD = moment(this.period.pe_bis);
      const BEGIN_ELEMENT = moment(ARGUMENT.von);
      const END_ELEMENT = moment(ARGUMENT.bis);
      const COUNT_DAYS = END_PERIOD.diff(BEGIN_PERIOD, "days");
      const BEGIN_COUNT_DAYS = BEGIN_ELEMENT.diff(BEGIN_PERIOD, "days");
      const END_COUNT_DAYS = END_PERIOD.diff(END_ELEMENT, "days");
      const LEFT = BEGIN_COUNT_DAYS * 100 / COUNT_DAYS;
      const RIGHT = END_COUNT_DAYS * 100 / COUNT_DAYS;
      return {
        left: `${ LEFT }%`,
        right: `${ RIGHT }%`,
      };
    },

    argument() {
      return this.data.argument_anerkannt && !isNil(this.data.argument_anerkannt.wert) ?
        this.data.argument_anerkannt :
        this.data.argument_erfasst;
    },

    classes() {
      return getClassBetragAnerkanntInPersonalbaum({ beleg: this.data });
    },

    value() {
      if (this.data.argument_anerkannt && !isNil(this.data.argument_anerkannt.wert)) {
        return this.data.argument_anerkannt.wert;
      }
      return this.data.argument_erfasst.wert;
    },
  },
};
