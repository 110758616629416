"use strict";
import angular from "angular";

import sitzungsmodule from "dias/sitzungen/sitzungsmodule";

import detail from "./detail.component";
import preview from "./preview.component";

const modul = angular.module("standard_sitzungsmodule.automatische_antragsliste", [
  sitzungsmodule.name,
])

  .config(/*@ngInject*/SitzungsmodulRegistryProvider => {
    SitzungsmodulRegistryProvider.register("automatische_antragsliste", "modul-automatische-antragsliste", "modul-automatische-antragsliste-preview");
  })

  .component("modulAutomatischeAntragsliste", detail)
  .component("modulAutomatischeAntragslistePreview", preview)
;

export default modul;
