"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import "restangular";
import models from "./models";
import dokumentation from "./dokumentation";


const hilfeModule = angular.module("dias.hilfen", [
  uiRouter,
  models.name,
  dokumentation.name,
]);

hilfeModule.config($stateProvider => {
  $stateProvider

    .state("root.hilfen", {
      url: "/hilfe/",
      views: {
        "main@root": {
          template: "<div vueinit=''><vue-hilfe></vue-hilfe></div>",
        }
      },
      data: {}
    })

    .state("root.dokumente-zur-foerderung", {
      url: "/dokumente-zur-foerderung/?dokart",
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-dokumente-zur-foerderung-list></vue-dokumente-zur-foerderung-list></div>",
        }
      },
      data: {}
    })

  ;
});

export default hilfeModule;
