"use strict";

import BaseModel from "dias/core/models/baseModel";

class Nutzerdatensuche extends BaseModel {
  constructor() {
    super("nutzerdatensuche", {});
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.batchExportURL = () => {
      return "api/nutzerdatensuche/batch_export/";
    };

    collection.batchExport = pks => {
      return collection.customPOST({ pks: pks }, "batch_export");
    };

    return collection;
  }
}

export default Nutzerdatensuche;
