import AngularLink from "../../global/components/AngularLink/AngularLink.vue";
import FoerderfinderCategory from "./FoerderfinderCategory/FoerderfinderCategory.vue";
import FormstepDetail from "../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../global/compositionAPI/HttpAPI";
import SyConfigAPI from "../../global/compositionAPI/SyConfigAPI";

import {
  assign,
  filter,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "TheFoerderfinder",
  components: {
    AngularLink,
    FoerderfinderCategory,
    FormstepDetail,
    PuxTranslate,
  },
  setup() {
    const {
      getHttp,
      getListHttp,
    } = HttpAPI();

    const {
      getSyConfigsValue,
    } = SyConfigAPI();

    return {
      getHttp,
      getListHttp,
      getSyConfigsValue,
    };
  },
  data() {
    return {
      activeButtons: {},
      buttonStyles: {
        bez: "",
        kbez: "",
        parent: "",
      },
      categories: [],
      countAngebote: undefined,
      isLoading: true,
      isPictureVisibleByDefault: true,
      isVisibleBez: true,
      isVisibleKbez: true,
    };
  },
  computed: {
    idsByActiveWords() {
      const IDS_BY_ACTIVE_WORDS = [];
      forEach(this.allWords, word => {
        if (this.activeButtons[word.pk]) {
          IDS_BY_ACTIVE_WORDS.push(word.pk);
        }
      });
      return IDS_BY_ACTIVE_WORDS;
    },

    categoriesFiltered() {
      return filter(this.categories, ["sichtbar", true]);
    },

    allWords() {
      const ALL_WORDS = [];
      forEach(this.categoriesFiltered, category => {
        ALL_WORDS.push(...category.werte);
      });
      return ALL_WORDS;
    },

    categoriesWithSelectedWords() {
      const CATEGORIES_WITH_SELECTED_WORDS = [];
      forEach(this.categoriesFiltered, category => {
        const SELECTED_WORDS_FOR_CURRENT_CATEGORY = this.getSelectedWordsBy(category);
        if (SELECTED_WORDS_FOR_CURRENT_CATEGORY.length) {
          CATEGORIES_WITH_SELECTED_WORDS.push({
            pk: category.pk,
            words: SELECTED_WORDS_FOR_CURRENT_CATEGORY,
          });
        }
      });
      return CATEGORIES_WITH_SELECTED_WORDS;
    },

    optionsForLink() {
      return {
        kategoriewert: this.idsByActiveWords,
      };
    },
  },
  created() {
    this.loadFoerderfinderkategorien();
  },
  methods: {
    loadFoerderfinderkategorien() {
      this.getListHttp({
        url: "ff/v3/foerderfinderkategorien/",
      }).then(
        response => {
          this.categories = response;
        }
      ).finally(
        () => {
          this.setVariablesFromSyConfig();
          this.initActiveButtons();
          this.checkSessionStorage();
          this.showCountAngebote();
          this.isLoading = false;
        }
      );
    },

    setVariablesFromSyConfig() {
      const SY_CONFIG_F_FINDER_STARTSEITE = this.getSyConfigsValue("f_finder_startseite", {});
      SY_CONFIG_F_FINDER_STARTSEITE.bezeichnungen = SY_CONFIG_F_FINDER_STARTSEITE.bezeichnungen || ["kbez"];
      this.setButtonStylesFromSyConfig(SY_CONFIG_F_FINDER_STARTSEITE);
      this.setIsVisibleKbezFromSyConfig(SY_CONFIG_F_FINDER_STARTSEITE);
      this.setIsVisibleBezFromSyConfig(SY_CONFIG_F_FINDER_STARTSEITE);
    },

    setButtonStylesFromSyConfig(syConfigFFinderStartseite) {
      this.buttonStyles = {
        kbez: syConfigFFinderStartseite.kbez_styles || "",
        bez: syConfigFFinderStartseite.bez_styles || "",
        parent: syConfigFFinderStartseite.parent_styles || "",
      };
    },

    setIsVisibleKbezFromSyConfig(syConfigFFinderStartseite) {
      this.isVisibleKbez = syConfigFFinderStartseite.bezeichnungen.indexOf("kbez") !== -1;
    },

    setIsVisibleBezFromSyConfig(syConfigFFinderStartseite) {
      this.isVisibleBez = syConfigFFinderStartseite.bezeichnungen.indexOf("bez") !== -1;
    },

    setIsPictureVisibleByDefaultFromSyConfig(syConfigFFinderStartseite) {
      this.isPictureVisibleByDefault = !syConfigFFinderStartseite.change_picture_text_order;
    },

    checkButton({ category, wordPk }) {
      this.setActiveButtons({ category, wordPk });
      this.showCountAngebote();
      this.setSessionStorage();
    },

    setActiveButtons({ category, wordPk }) {
      if (category.multivalue) {
        this.activeButtons[wordPk] = !this.activeButtons[wordPk];
      } else {
        forEach(category.werte, value => {
          if (value.pk !== wordPk) {
            this.activeButtons[value.pk] = false;
          } else {
            this.activeButtons[value.pk] = !this.activeButtons[value.pk];
          }
        });
      }
    },

    initActiveButtons() {
      const ACTIVE_BUTTONS = {};
      forEach(this.categoriesFiltered, category => {
        forEach(category.werte, word => {
          ACTIVE_BUTTONS[word.pk] = word.default || false;
        });
      });
      this.activeButtons = ACTIVE_BUTTONS;
    },

    checkSessionStorage() {
      const ACTIVE_BUTTONS_STORAGE = JSON.parse(sessionStorage.getItem("categoriesButton"));
      if (!ACTIVE_BUTTONS_STORAGE) {
        return;
      }

      this.activeButtons = assign({}, this.activeButtons, ACTIVE_BUTTONS_STORAGE);
    },

    resetFilter(category) {
      forEach(category.werte, word => {
        this.activeButtons[word.pk] = word.default || false;
      });
      this.setSessionStorage();
      this.showCountAngebote();
    },

    setSessionStorage() {
      sessionStorage.setItem("categoriesButton", JSON.stringify(this.activeButtons));
    },

    showCountAngebote() {
      this.getHttp({
        url: "info/foerderangebote/count/",
        urlParams: {
          kategoriewert: this.idsByActiveWords,
        },
      }).then(
        response => {
          this.countAngebote = response || 0;
        }
      );
    },

    getSelectedWordsBy(category) {
      const SELECTED_WORDS = [];
      forEach(category.werte, word => {
        if (this.activeButtons[word.pk]) {
          SELECTED_WORDS.push(word.kbez);
        }
      });
      return SELECTED_WORDS;
    },
  },
};
