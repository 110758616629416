import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Wizard from "../../../global/components/Wizard/Wizard.vue";
import WizardStep1 from "./WizardSteps/WizardStepVorbereiten/WizardStepVorbereiten.vue";
import WizardStep2 from "./WizardSteps/WizardStepEintraegeWaehlen/WizardStepEintraegeWaehlen.vue";
import WizardStep3 from "./WizardSteps/WizardStepAusfuehren/WizardStepAusfuehren.vue";
import WizardStep4 from "./WizardSteps/WizardStepAbschluss/WizardStepAbschluss.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";
import WizardStepsMixin from "./WizardStepsMixin";

import fileDownload from "js-file-download";
import {
  cloneDeep,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "ExportauftragJsonWizard",
  components: {
    PageTabTitle,
    PuxCloak,
    PuxTranslate,
    Wizard,
    WizardStep1,
    WizardStep2,
    WizardStep3,
    WizardStep4,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
    WizardStepsMixin,
  ],
  data() {
    return {
      stepActive: 0,
      stepsVisited: {
        0: true,
        1: false,
        2: false,
        3: false,
      },
      booleanPropsWizard: {
        backTargetStepDisabled: true,
        backStepHide: true,
        backStepDisabled: true,
        forwardStepHide: undefined,
        forwardStepDisabled: true,
        buttonsLoading: false,
        closeButtonShow: true,
      },
      wizardOptions: {
        forwardStepButtonSuffix: undefined,
      },
      loading: {
        init: true,
        step1: false,
        step2: false,
      },
      dataStep1: {
        impexpauftragvorlage: [],
        tabelle: [],
        errors: {},
      },
      modelStep1: {
        vorlage: undefined
      },
      dataStep2: {
        errors: {},
        vorlage: undefined,
      },
      modelStep2: {
        eintraege: [],
        eintraegetyp: 1,
      },
      dataStep3: {
        auftrag: undefined,
        tabelle: undefined,
        task: undefined,
        taskFailure: false,
        taskError: undefined,
      },
      dataStep4: {
        auftrag: undefined
      },
      fileName: undefined,
    };
  },
  computed: {
    getVorlageName() {
      return this.dataStep1.impexpauftragvorlage.find(element => element.pk === this.modelStep1.vorlage);
    },

    getEintraegeName() {
      const result = [];
      for (let i = 0, len = this.modelStep2.eintraege.length; i < len; ++i) {
        result.push(this.modelStep2.obj[this.modelStep2.eintraege[i]]);
      }
      return result;
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.loadData();
    },

    loadData() {
      this.loading.init = true;
      this.getHttp({
        url: `katalog/?key=impexpauftragvorlage`,
      }).then(
        response => {
          this.dataStep1.impexpauftragvorlage = response;
        }
      ).finally(
        () => {
          this.loading.init = false;
        }
      );
    },

    updateModelStep1({ model }) {
      this.modelStep1 = cloneDeep(model);
      if (this.modelStep1.vorlage) {
        this.booleanPropsWizard.forwardStepDisabled = false;
        this.loading.step2 = true;
        this.getHttp({
          url: `impexpauftraege_vorlageaktion/get_tabelle/?vorlage=${ this.modelStep1.vorlage }`,
        }).then(
          response => {
            this.dataStep1.tabelle = response.results;
            this.dataStep3.tabelle = response.results;
          }
        ).finally(
          () => {
            this.loading.step2 = false;
          }
        );
      }
    },

    updateModelStep2({ model }) {
      this.modelStep2 = cloneDeep(model);
      this.booleanPropsWizard.forwardStepDisabled = this.modelStep2.eintraege.length == 0 && this.modelStep2.eintraegetyp == 1;
    },

    goOneStepForward() {
      const NUMBER_STEP_TO_GO = this.stepActive + 2;
      if (isFunction(this[`goForwardToStep${ NUMBER_STEP_TO_GO }`])) {
        this[`goForwardToStep${ NUMBER_STEP_TO_GO }`]();
      } else {
        this.goNextStep();
      }
    },

    goForwardToStep2() {
      this.loading.step1 = true;
      this.dataStep1.errors = {};
      this.dataStep2.vorlage = this.modelStep1.vorlage;
      this.booleanPropsWizard.buttonsLoading = false;
      this.booleanPropsWizard.forwardStepDisabled = true;
      this.loading.step1 = false;
      this.goNextStep();
    },

    goForwardToStep3() {
      this.dataStep2.errors = {};
      this.loading.step2 = true;
      this.booleanPropsWizard.buttonsLoading = true;
      this.booleanPropsWizard.forwardStepDisabled = true;
      const DATA = {
        eintraege: this.modelStep2.eintraege,
        vorlage: this.modelStep1.vorlage,
        vorlage_name: this.getVorlageName.bez,
        iea_impexpdatei_name: "undefined"
      };
      this.postHttp({
        url: `exportauftraege_json/`, data: DATA }).then(
        iea => {
          this.dataStep3.auftrag = iea;
          this.dataStep4.auftrag = iea;
          this.fileName = this.dataStep3.auftrag.iea_impexpdatei_name;
          this.booleanPropsWizard.buttonsLoading = false;
          this.booleanPropsWizard.forwardStepDisabled = true;
          this.loading.step2 = false;
          this.goNextStep();
        },
        errors => {
          this.dataStep2.errors = cloneDeep(errors.data);
          this.booleanPropsWizard.buttonsLoading = false;
          this.booleanPropsWizard.forwardStepDisabled = false;
          this.loading.step2 = false;
          this.loading.updateModelStep2 = false;
        }
      );
    },

    goNextStep() {
      this.stepActive += 1;
      this.stepsVisited[this.stepActive] = true;
    },

    goOneStepBack() {
      if (isFunction(this[`goBackToStep${ this.stepActive }`])) {
        this[`goBackToStep${ this.stepActive }`]();
      }
    },

    goBackToStep2() {
      this.stepActive -= 1;
      this.stepsVisited[2] = false;
    },

    goBackToStep1() {
      this.stepActive -= 1;
      this.stepsVisited[1] = false;
    },

    updateModelKontext({ model }) {
      this.model.kontext = model;
      this.booleanPropsWizard.forwardStepDisabled = false;
    },

    taskStart() {
      this.loading.step3 = true;
      this.dataStep3.taskFailure = false;
      this.postHttp({ url: `importauftraege/${ this.dataStep3.auftrag.pk }/ausfuehren/` }).then(
        response => {
          this.dataStep3.task = response.task_id;
        },
        errors => {
          this.dataStep3.taskErrors = errors;
        }
      ).finally(() => this.loading.step3 = false);
    },

    onTaskSuccess() {
      this.getHttp({ url: `importauftraege/${ this.dataStep3.auftrag.pk }/` }).then(
        response => {
          this.dataStep3.auftrag = cloneDeep(response);
          this.booleanPropsWizard.forwardStepDisabled = false;
        }
      );
    },

    onTaskFailure({ result }) {
      this.dataStep3.taskError = result.data || true;
      this.getHttp({ url: `importauftraege/${ this.dataStep3.auftrag.pk }/` }).then(
        response => this.dataStep3.auftrag = cloneDeep(response)
      );
    },

    onTaskFinish() {
      this.loading.step3 = false;
    },

    resetWizard() {
      // save initial Data
      const SYCONF = cloneDeep(this.syConfig);
      const IMPEXPAUFTRAGVORLAGE = cloneDeep(this.dataStep1.impexpauftragvorlage);
      // Internen Zustand auf initialen Zustand setzen
      Object.assign(this.$data, this.$options.data.apply(this));
      // restore initial Data
      this.dataStep1.impexpauftragvorlage = IMPEXPAUFTRAGVORLAGE;
      this.syConfig = SYCONF;
      this.loading.init = false;
    },

    updateFileNameStep4({ fileName }) {
      this.fileName = fileName;
    },

    downloadFile() {
      this.getHttp({ url: `importauftraege/${ this.dataStep3.auftrag.pk }/download/`, responseType: "blob", fullResponse: true }).then(
        response => {
          if (this.fileName) {
            fileDownload(response.data, this.fileName);
          } else {
            fileDownload(response.data, this.dataStep3.auftrag.iea_impexpdatei_name);
          }
        }
      );
    }
  },
};
