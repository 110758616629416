import BetragsrechnerMixin from "../Mixins/BetragsrechnerMixin";
import {
  gettext,
  replaceText,
} from "../../../../global/functions/utils";

// @vue/component
export default {
  name: "Kategoriemitpauschalerechner",
  mixins: [
    BetragsrechnerMixin,
  ],
  emits: [
    "isKatalogDetailsInArgument",
  ],
  data() {
    return {
      labelErlaeuterung: "_LBL_KATEGORIE_MIT_PAUSCHALE_RECHNER_ERLAEUTERUNG_",
    };
  },
  computed: {
    optionsErlaeuterung() {
      return {
        id: "erlaeuterung",
        htmlId: this.htmlIdErlaeuterung,
        label: this.labelErlaeuterung,
        view: this.view,
        type: "text",
        maxlength: 300,
        required: true,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdErlaeuterung() {
      return `${ this.htmlId }_erlaeuterung`;
    },

    optionsAnzahl() {
      return {
        id: "anzahl",
        htmlId: this.htmlIdAnzahl,
        label: this.labelAnzahl,
        view: this.view,
        type: "float",
        required: true,
        classesHorizontal: this.classesHorizontal,
        extra: {
          einheit: this.vorschau.einheit,
        },
        change: this.changeVorschauLocal,
      };
    },

    htmlIdAnzahl() {
      return `${ this.htmlId }_anzahl`;
    },

    labelAnzahl() {
      return this.vorschau.einheit ?
        "_LBL_KATEGORIE_MIT_PAUSCHALE_RECHNER_ANZAHL_{{einheit}}_" :
        "_LBL_KATEGORIE_MIT_PAUSCHALE_RECHNER_ANZAHL_";
    },
  },
  watch: {
    "vorschau.einheit"() {
      this.initErrorLabelsLocal();
    },
  },
  created() {
    this.initErrorLabelsLocal();
    this.$emit("isKatalogDetailsInArgument");
  },
  methods: {
    initErrorLabelsLocal() {
      let anzahlLabel = "_LBL_KATEGORIE_MIT_PAUSCHALE_RECHNER_ANZAHL_";
      if (this.vorschau.einheit) {
        anzahlLabel = replaceText({
          text: gettext("_LBL_KATEGORIE_MIT_PAUSCHALE_RECHNER_ANZAHL_{{einheit}}_"),
          object: {
            einheit: this.vorschau.einheit,
          },
        });
      }
      const LABELS = {
        erlaeuterung: {
          label: this.labelErlaeuterung,
          link: true,
          id: this.htmlIdErlaeuterung,
        },
        anzahl: {
          label: anzahlLabel,
          link: true,
          id: this.htmlIdAnzahl,
        },
      };
      this.initErrorLabels(LABELS);
    },
  },
};
