import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import NachrichtenLink from "../../../../Nachrichten/NachrichtenLink/NachrichtenLink.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "AntragOverviewAntragsteller",
  components: {
    AngularLink,
    NachrichtenLink,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    antrag: {
      type: Object,
      required: true,
    },
  },
  computed: {
    antragstellerNameExtraTranslate() {
      return {
        name: this.antrag.antragstellerorg_obj.ast_name,
      };
    },

    foerderorganisationNameExtraTranslate() {
      return {
        name: this.antrag.antragstellerorg_obj.foerderorganisation_obj.o_name,
      };
    },
  },
};
