"use strict";

import template from "./config.jade";
import controller from "./controller";


const parametermoduleString = angular.module("dias.foerderregeln.parameter.textbaustein", [
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("textbaustein", "parametermodul-textbaustein");
  })
  .component("parametermodulTextbaustein", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulTextbausteinRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: "<span ng-bind-html='vm.parameter[vm.key].wert'></span>",
    controllerAs: "vm",
  });
export default parametermoduleString;
