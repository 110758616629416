"use strict";

import BaseController from "./controller";

class Controller extends BaseController {
  /*@ngInject*/
  constructor(DiasNotification, ParameterUtils, i18n) {
    super(DiasNotification, ParameterUtils, i18n);
    this.readonly = true;
  }
}

export default Controller;
