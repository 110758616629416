import {
  ref,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import {
  cloneDeep,
  isNil,
} from "lodash-es";

export default function SitzungsartTagesordnungspunkteAPI({
  sitzungsartUrl = "",
}) {
  const tagesordnungspunkte = ref([]);
  const loadingTagesordnungspunkte = ref(true);

  const tagesordnungspunkteUrl = `${ sitzungsartUrl }tops/`;

  const {
    getListHttp,
  } = HttpAPI();
  const {
    addNotification,
  } = NotificationAPI();
  const loadTagesordnungspunkte = () => {
    getListHttp({
      url: tagesordnungspunkteUrl,
    }).then(
      response => {
        tagesordnungspunkte.value = response;
        loadingTagesordnungspunkte.value = false;
      },
      () => {
        addNotification({
          text: "_MSG_SITZUNGSART_TOPS_LADEN_ERROR_",
          type: "error",
        });
      }
    ).finally(() => {
      loadingTagesordnungspunkte.value = false;
    });
  };

  const updateTagesordnungspunkt = ({ tagesordnungspunkt, tagesordnungspunktIndex, tagesordnungspunktParentIndex }) => {
    if (!isNil(tagesordnungspunktParentIndex)) {
      tagesordnungspunkte.value[tagesordnungspunktParentIndex].unterpunkte.splice(tagesordnungspunktIndex, 1, tagesordnungspunkt);
    } else {
      tagesordnungspunkte.value.splice(tagesordnungspunktIndex, 1, cloneDeep(tagesordnungspunkt));
    }
  };

  return {
    loadingTagesordnungspunkte,
    loadTagesordnungspunkte,
    tagesordnungspunkte,
    tagesordnungspunkteUrl,
    updateTagesordnungspunkt,
  };
}
