"use strict";

class WorkflowfolgeKopierenController {
  /*@ngInject*/
  constructor($scope, $q, $state, DiasNotification) {
    this.DiasNotification = DiasNotification;
    this.$q = $q;
    this.$state = $state;
    this.Workflowfolge = $scope.extras.Workflowfolge;
    this.wfo = $scope.extras.wfo;
    this.model = {
      newWfoKbez: this.wfo.wfo_kbez + " (Kopie)",
      newWfoKbezAdmin: this.wfo.wfo_kbez_admin + " (Kopie)",
      autoFocus: true
    };

    this.save = () => {
      return this.Workflowfolge.one(this.wfo.pk).create_copy(this.model.newWfoKbez.trim(), this.model.newWfoKbezAdmin.trim()).then(
        response => {
          this.DiasNotification.page.success("Kopie der Workflowfolge erfolgreich erstellt");
          this.$state.go("root.workflowfolgen.detail", { id: response.pk });
        },
        error => {
          this.onError(error);
          console.trace(error);
          return this.$q.reject(error);
        }
      );
    };

    this.onError = error => {
      const errorsLabel = {
        wfo_kbez: "Kurzbeschreibung ",
        wfo_kbez_admin: "Kurzbeschreibung in Administration ",
      };
      this.DiasNotification.form.clear();
      this.DiasNotification.form.error(error.data, "Bitte überprüfen Sie Ihre Eingaben", {}, errorsLabel);
      this.errors = error.data;
    };
  }
}

export default WorkflowfolgeKopierenController;
