"use strict";

import KostenController from "../../kosten.controller";


class AbzugNichtFoerderfaehigeKostenController extends KostenController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KKostenart, BetragsrechnerRegistry, i18n) {
    super($scope, $q, Foerdermodule, Foerderantrag, KKostenart, BetragsrechnerRegistry, i18n);
    this.readonly = true;
  }
}

export default AbzugNichtFoerderfaehigeKostenController;
