import FoerderorgAnsprechpartnerMirZuweisenButtonModal from "../FoerderorgAnsprechpartnerMirZuweisenButtonModal/FoerderorgAnsprechpartnerMirZuweisenButtonModal.vue";
import FoerderorgAnsprechpartnerZuweisenButtonModal from "../FoerderorgAnsprechpartnerZuweisenButtonModal/FoerderorgAnsprechpartnerZuweisenButtonModal.vue";
import NachrichtenLink from "../../../Nachrichten/NachrichtenLink/NachrichtenLink.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";

import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import KommunikationsdatenUUIDs from "../../../../const/KommunikationsdatenUUIDs";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import {
  get,
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "FoerderorgOneAnsprechpartner",
  components: {
    FoerderorgAnsprechpartnerMirZuweisenButtonModal,
    FoerderorgAnsprechpartnerZuweisenButtonModal,
    NachrichtenLink,
    Permission,
    PuxButton,
    PuxIcon,
  },
  mixins: [
    PermissionMixin,
  ],
  inject: [
    "updateAnsprechpartner",
  ],
  props: {
    ansprechpartner: {
      type: Object,
      required: true,
    },
    ansprechpartnerIndex: {
      type: Number,
      required: true,
    },
    objekt: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { deleteHttp, addNotification } = HttpAPI();
    const { checkPermissionsSync } = PermissionAPI();
    return { checkPermissionsSync, deleteHttp, addNotification };
  },
  data() {
    return {
      statusModalDelete: undefined,
      confirmDeleteOptions: undefined,
    };
  },
  computed: {
    ansprechpartnerName() {
      return get(this.ansprechpartner, "nutzer.name");
    },

    ansprechpartnerType() {
      return get(this.ansprechpartner, "typ_bez");
    },

    ansprechpartnerEmail() {
      return get(this.ansprechpartner, "nutzer.n_email");
    },

    nachrichtenLinkLabel() {
      return this.ansprechpartnerType ? `${ this.ansprechpartnerName } (${ this.ansprechpartnerType })` : this.ansprechpartnerName;
    },

    nachrichtenLinkId() {
      return `nachrichten_link_ansprechpartner_${ this.ansprechpartnerIndex }`;
    },

    ansprechpartnerKommdaten() {
      return get(this.ansprechpartner, "nutzer.kommunikationsdaten", []);
    },

    ansprechpartnerKommdatenExist() {
      return !!(this.ansprechpartnerKommdaten.length);
    },

    ansprechparterOrganisation() {
      return get(this.ansprechpartner, "org.o_name");
    },

    bezug() {
      if (get(this.objekt, "vertrag")) {
        return "auszahlung";
      }
      return "vertrag";
    },

    phoneNumbers() {
      return filter(this.ansprechpartner.nutzer.kommunikationsdaten, item => {
        return item.kommtyp && item.kommtyp.kot_id === KommunikationsdatenUUIDs.telefonnr1;
      });
    },

    ansprechpartnerNotCurrentUser() {
      return get(this.ansprechpartner, "nutzer.pk") !== this.me.pk;
    },

    idForButtonMirZuweisen() {
      return `ap_${ this.ansprechpartnerIndex }_mir_zuweisen`;
    },

    idForButtonZuweisen() {
      return `ap_${ this.ansprechpartnerIndex }_zuweisen`;
    },

    idForButtonEntfernen() {
      return `ap_${ this.ansprechpartnerIndex }_entfernen`;
    },

    deleteExtra() {
      return {
        name: this.ansprechpartnerName,
      };
    },

    canDeleteAnsprechpartner() {
      return this.checkPermissionsSync({
        perm: "ansprechpartner.delete",
        permArray: this.objekt.user_permissions,
      });
    },

    canUpdateAnsprechpartner() {
      return this.checkPermissionsSync({
        perm: "ansprechpartner.update",
        permArray: this.objekt.user_permissions,
      });
    },

    canDeleteOrUpdateAnsprechpartner() {
      return this.canDeleteAnsprechpartner || this.canUpdateAnsprechpartner;
    },
  },
  methods: {
    openModalDelete() {
      this.statusModalDelete = true;
      this.confirmDeleteOptions = {
        okClass: "btn btn-primary",
        title: `_LBL_ANSPRECHPARTNER_DELETE_`,
        msg: "_MSG_ANSPRECHPARTNER_DELETE_MSG_{{name}}_",
        extra: this.deleteExtra,
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: this.onDelete,
        cancelCallback: this.closeConfirm,
        loading: false,
      };
    },

    onDelete() {
      this.confirmDeleteOptions.loading = true;
      this.deleteHttp({
        url: `${ this.baseUrl }/${ this.ansprechpartner.pk }/`,
      }).then(
        () => {
          this.addNotification({ text: `_MSG_ANSPRECHPARTNER_DELETE_SUCCESS_` });
          this.closeConfirm();
          this.updateAnsprechpartner();
        }
      ).then(
        () => this.confirmDeleteOptions.loading = false
      );
    },

    closeConfirm() {
      this.statusModalDelete = false;
    },
  },
};
