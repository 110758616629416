import DeckblattModalDelete from "../DeckblattModalDelete/DeckblattModalDelete.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import getTableApi from "../../../const/TableApis";
import { EventBus } from "../../../global/functions/event-bus";

const TABLE_ID = "deckblaetter";

// @vue/component
export default {
  name: "DeckblattList",
  components: {
    DeckblattModalDelete,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_TXT_DECKBLAETTER_TABLE_ROW_ACTIONS_DELETE_",
            isHidden: () => !(this.checkPermissionsSync({ perm: "deckblatt.delete" })),
            callback: ({ item, index }) => this.deleteDeckblatt({ item, index }),
          },
          {
            label: "_TXT_DECKBLAETTER_TABLE_ROW_ACTIONS_PRINT_",
            isHidden: () => !(this.checkPermissionsSync({ perm: "deckblatt.print" })),
            callback: ({ item, index }) => this.printDeckblaetter({ item, index }),
          },
        ],
        actions: [
          {
            permission: "deckblatt.print",
            label: "_TXT_DECKBLAETTER_TABLE_ACTIONS_PRINT_",
            callback: ({ urlParams }) => this.printDeckblaetter({ urlParams }),
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-print",
          },
        ],
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      modal: {
        delete: undefined,
      },
      currentDeckblatt: undefined,
      currentIndex: undefined,
    };
  },
  methods: {
    printDeckblaetter({ item, urlParams }) {
      const url = item ? `deckblaetter/print/?pk=${ item.pk }` : `deckblaetter/print/?${ urlParams }`;
      this.putHttp({ url, headerParams: { responseType: "blob" } }).then(
        response => {
          this.loadCurrentFile({ response });
        }
      );
    },

    loadCurrentFile({ response }) {
      const fileName = "deckblaetter.pdf";
      const pdffile = response;
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(pdffile, fileName);
      } else {
        const URL = (window.URL || window.webkitURL);
        const aLink = document.createElement("a");
        aLink.download = fileName;
        aLink.href = URL.createObjectURL(pdffile);
        aLink.target = "_self";
        const eventClick = document.createEvent("MouseEvents");
        eventClick.initEvent(
          "click",
          true,
          true,
          this.window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        aLink.dispatchEvent(eventClick);
      }
    },

    deleteDeckblatt({ item, index }) {
      this.currentDeckblatt = item;
      this.currentIndex = index;
      this.modal.delete = true;
    },

    closeModalDelete({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.modal.delete = false;
    },
  },
};
