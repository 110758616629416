import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import NutzerStatusUUIDs from "../../../const/NutzerStatusUUIDs";
import {
  isString,
  isObject,
  size,
} from "lodash-es";
import {
  gettext,
  replaceText,
} from "../../../global/functions/utils";

// @vue/component
export default {
  name: "Nachrichtenlink",
  components: {
    Modal,
    PuxButton,
    PuxIcon,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    empfaenger: {
      type: Array,
      default: () => [],
    },
    empfaengerKeyId: {
      type: String,
      default: "pk",
    },
    bezug: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    bezugpk: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    bezugtext: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    savecallback: {
      type: Function,
      default: () => {},
    },
    linklabel: {
      type: String,
      default: undefined,
    },
    linktitel: {
      type: String,
      default: undefined,
    },
    linkclass: {
      type: String,
      default: "",
    },
    notLink: {
      type: Boolean,
    },
    htmlId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      empfaengerClone: undefined,
      message: {
        inactive: "",
        abwesend: "",
      },
      model: {
        bezug: this.bezug,
        bezugpk: this.bezugpk,
        empfaenger: [],
        titel: null,
        text: null,
      },
      status: {
        active: undefined,
        inactive: undefined,
        abwesend: undefined,
        notLink: undefined,
        loading: true,
        modal: undefined,
        modalDisabled: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
      data: {
        empfaenger: [],
      },
      disabledObj: {
        empfaenger: true,
      },
      errorLabels: {
        empfaenger: "Empfänge",
        titel: "Betref",
        text: "Text"
      },
    };
  },
  computed: {
    getLinkTitel() {
      let linktitel = this.linktitel;
      if (!linktitel) {
        linktitel = "_TXT_NACHRICHTEN_LINK_EINE_NACHRICHT_VERSENDEN_";
        if (this.empfaenger.length === 1) {
          linktitel = "_TXT_NACHRICHTEN_LINK_EINE_NACHRICHT_VERSENDEN_AN_{{name}}_";
        }
      }
      return linktitel;
    },

    getLinkExtra() {
      if (this.empfaenger.length === 1 && this.empfaenger[0]) {
        return this.empfaenger[0].name;
      }
      return {};
    },

    getLinkLabel() {
      const linklabel = this.linklabel;
      if (!linklabel) {
        if (this.empfaenger.length === 1) {
          return this.empfaenger[0].name;
        }
        return "_TXT_NACHRICHTEN_LIST_MESSAGE_SEND_";
      }
      return linklabel;
    },

    isErrors() {
      return size(this.errors);
    },

    optionsModal() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "empfaenger",
            type: "multiselect",
            label: "_LBL_NACHRICHTEN_LINK_EMPFAENGER_",
            required: true,
            view: "v",
            keyId: this.empfaengerKeyId,
            keyLabel: "name",
            menuWidthAll: true,
          },
          {
            id: "titel",
            type: "text",
            label: "_LBL_NACHRICHTEN_LINK_BETREFF_",
            required: true,
            view: "v",
          },
          {
            id: "text",
            type: "richtext",
            label: "_LBL_NACHRICHTEN_LINK_TEXT_",
            required: true,
            view: "v-alt",
          },
        ],
      };
    },

    selectorClose() {
      return `#${ this.htmlId }`;
    },
  },
  created() {
    this.initData();
    this.initListRecipient();
  },
  methods: {
    initData() {
      this.status.active = this.hasActiveEmpfaenger();
      this.status.inactive = this.hasInActiveEmpfaenger();
      this.status.abwesend = this.hasAbwesendEmpfaenger();
      this.status.notLink = this.notLink || !this.status.active;
      this.data.empfaenger = _.cloneDeep(this.empfaenger);
      this.status.loading = false;
    },

    initListRecipient() {
      // Bereits eindeutig vorgegebener Empfänger: vorbelegen:
      const inaktiv = [];
      this.disabledObj.empfaenger = this.empfaenger.length > 0;
      for (let i = 0; i < this.empfaenger.length; i++) {
        if (isString(this.empfaenger[i])) {
          this.model.empfaenger.push(this.empfaenger[i]);
        } else if (isObject(this.empfaenger[i]) && this.isUserAktiv(this.empfaenger[i])) {
          this.model.empfaenger.push(this.empfaenger[i][this.empfaengerKeyId]);
        } else {
          inaktiv.push(this.empfaenger[i]);
        }
      }
      if (inaktiv.length > 0) {
        let usrList = "";
        for (let i = 0; i < inaktiv.length; i++) {
          usrList += `<li>${ inaktiv[i].name }</li>`;
        }

        const ERROR_STRING = replaceText({
          text: gettext("_HTML_NACHRICHTEN_LIST_MESSAGE_SEND_RECIPIENT_EXCLUDED_{{usersList}}_"),
          object: {
            usersList: usrList,
          }
        });

        this.errors = { non_field_error: [ERROR_STRING] };
      }
    },

    isUserAktiv(usr) {
      return usr.aktiv || (usr.status && NutzerStatusUUIDs.isActive(usr.status.ns_id));
    },

    hasActiveEmpfaenger() {
      for (let i = 0; i < this.empfaenger.length; i++) {
        if (this.empfaenger[i].aktiv || (this.empfaenger[i].status && NutzerStatusUUIDs.isActive(this.empfaenger[i].status.ns_id))) {
          return true;
        }
      }
      return false;
    },

    hasInActiveEmpfaenger() {
      let inactive = 0;
      for (let i = 0; i < this.empfaenger.length; i++) {
        if ((this.empfaenger[i].aktiv !== undefined && !this.empfaenger[i].aktiv) || (this.empfaenger[i].status && !NutzerStatusUUIDs.isActive(this.empfaenger[i].status.ns_id))) {
          inactive++;
        }
      }
      if (inactive === 1 && this.empfaenger.length === 1) {
        this.message.inactive = "_MSG_NACHRICHTEN_LINK_NUTZER_GESPERRT_";
      } else if (inactive > 0 && inactive === this.empfaenger.length) {
        this.message.inactive = "_MSG_NACHRICHTEN_LINK_ALLE_NUTZER_GESPERRT_";
      } else if (inactive > 0) {
        this.message.inactive = `_MSG_NACHRICHTEN_LINK_SOME_USERS_BLOCKED_`;
      }
      return inactive > 0;
    },

    hasAbwesendEmpfaenger() {
      // Abwesenheitsinfo (nur bei einzelnen Empfängern anzeigen)
      if (this.empfaenger.length === 1 && this.empfaenger[0].abwesenheit) {
        this.message.abwesend = this.empfaenger[0].abwesenheit;
        return true;
      }
      return false;
    },

    showSend() {
      this.resetModel();
      this.removeErrors();
      this.status.modal = true;
    },

    save() {
      this.status.modalDisabled = true;
      this.postHttp({ url: "benachrichtigungen/send/", data: this.model }).then(
        () => {
          this.closeModal();
          this.savecallback();
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).finally(
        () => this.status.modalDisabled = false
      );
    },

    resetModel() {
      this.model.text = null;
      this.model.titel = null;
    },

    removeErrors() {
      this.errors = undefined;
      this.errorsClone = undefined;
    },

    closeModal() {
      this.status.modal = false;
    },
  },
};
