import Analyse from "../Analyse/Analyse.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spinner from "../../../global/components/Spinner/Spinner.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "AnalysenDetails",
  components: {
    Analyse,
    ContextPanel,
    Modal,
    PuxTranslate,
    Spinner,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
    analysen: {
      type: Array,
      required: true,
    },
    tags: {
      type: Object,
      required: true,
    },
    statusMapping: {
      type: Object,
      required: true,
    },
    getIcon: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    getCumulatedStatus: {
      type: Function,
      required: true
    },
    updateAnalysen: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      visibleTags: [],
      loading: false,
      model: {},
      options: {
        showCloseButton: true,
        required: true,
      },
      status: {
        loading: undefined,
      },
    };
  },
  computed: {
    grouped() {
      const gr = {};
      forEach(this.analysen, analyse => {
        forEach(analyse.tags, tag => {
          const analysenPerTag = gr[tag] ? gr[tag] : [];
          analysenPerTag.push(analyse);
          gr[tag] = analysenPerTag;
        });
      });

      forEach(gr, (group, tag) => {
        gr[tag] = { analysen: group, status: this.getCumulatedStatus(group) };
      });
      return gr;
    },
  },
  created() {
    this.visibleTags = this.getVisibleTags();
  },
  methods: {
    updateDetailsAnalysen() {
      this.loading = true;
      this.updateAnalysen({ run: true }).then(
        () => this.loading = false,
      );
    },
    getOptionsForPanels() {
      const OPTIONS_FOR_PANELS = [];
      forEach(this.visibleTags, tag => {
        const TAG = {
          label: tag.bezeichnung + " (" + tag.analysen_count + ")",
          icon: tag.icon,
          active: true,
          pk: tag.id,
        };
        OPTIONS_FOR_PANELS.push(TAG);
      });
      return OPTIONS_FOR_PANELS;
    },
    getVisibleTags() {
      const VISIBLE_TAGS = [];
      forEach(this.grouped, (elemente, tagpk) => {
        VISIBLE_TAGS.push(this.getTaginfo(tagpk, elemente.analysen));
      });
      return VISIBLE_TAGS;
    },
    getTaginfo(tagpk, analysen) {
      if (this.tags[tagpk]) {
        const tag = this.tags[tagpk];
        tag.analysen_count = analysen.length;
        return tag;
      }
      return {
        beschreibung: null,
        analysen_count: analysen.length,
        bezeichnung: tagpk,
        icon: null,
        id: tagpk,
        kennung: tagpk
      };
    }
  },
};
