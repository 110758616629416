import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import GeschaeftsregelModul from "../../../Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import VertragWizardEinreichenErrors from "./VertragWizardEinreichenErrors/VertragWizardEinreichenErrors.vue";
import Wizard from "../../../../global/components/Wizard/Wizard.vue";

import {
  getTranslatedText,
} from "../../../../global/functions/utils";

// @vue/component
export default {
  name: "VertragWizardEinreichen",
  components: {
    FormstepDetail,
    GeschaeftsregelModul,
    PuxTranslate,
    VertragWizardEinreichenErrors,
    Wizard,
  },
  props: {
    vertrag: {
      type: Object,
      required: true,
    },
    einreichenWizardModule: {
      type: Array,
      required: true,
    },
    areModulesEditable: {
      type: Boolean,
      required: true,
    },
    validationErrors: {
      type: Array,
      required: false,
      default: () => [],
    },
    vertragUrl: {
      type: String,
      required: true,
    },
    updateEinreichenWizardModule: {
      type: Function,
      required: true,
    },
    saveCallback: {
      type: Function,
      required: true,
    },
  },
  emits: [
    "close",
  ],
  data() {
    return {
      wizardSteps: [
        {
          label: "_LBL_VERTRAG_WIZARD_EINREICHEN_STEP1_",
          slotId: "step1",
          title: "_LBL_VERTRAG_WIZARD_EINREICHEN_STEP1_TOOLTIP_",
        },
        {
          label: "_LBL_VERTRAG_WIZARD_EINREICHEN_STEP2_",
          slotId: "step2",
          title: "_LBL_VERTRAG_WIZARD_EINREICHEN_STEP2_TOOLTIP_",
        },
      ],
      stepActive: 0,
      stepsVisited: {
        0: true,
        1: true,
      },
      booleanPropsWizard: {
        backTargetStepDisabled: undefined,
        backStepHide: undefined,
        backStepDisabled: undefined,
        forwardStepHide: undefined,
        forwardStepDisabled: false,
        buttonsLoading: false,
        closeButtonShow: true,
      },
      wizardOptions: {
        closeButtonLabel: "_BTN_CLOSE_",
      },
      formstepDetailOptionsStep2: {
        hideHeader: true,
      },
    };
  },
  computed: {
    isStep1InfoBoxVisible() {
      return !!getTranslatedText("_HTML_VERTRAG_WIZARD_EINREICHEN_STEP1_INFO_BOX_");
    },

    isStep2InfoBoxVisible() {
      return !!getTranslatedText("_HTML_VERTRAG_WIZARD_EINREICHEN_STEP2_INFO_BOX_");
    },
  },
  methods: {
    goOneStepForward() {
      this.stepActive++;
    },

    goOneStepBack() {
      this.stepActive--;
    },

    closeLocal() {
      this.$emit("close");
    },

    goToStep1() {
      this.stepActive = 0;
    },
  },
};
