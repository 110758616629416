import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import {
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "NutzerAnonymisiertListPreview",
  components: {
    PuxCloak,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    replace: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      statusLoading: true,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (!isUndefined(this.data.upd_name)) {
        this.statusLoading = false;
        return;
      }
      this.getHttp({ url: `anonymisierte_nutzer/${ this.data.pk }/vorschau/` }).then(
        response => {
          this.replace(response);
          this.statusLoading = false;
        }
      );
    },
  },
};
