import {
  cloneDeep,
  get,
} from "lodash-es";

// @vue/component
export default {
  emits: [
    "changeModel",
  ],
  methods: {
    changeModelWithTagespauschale() {
      const MODEL = cloneDeep(this.model);
      let tagespauschale = get(this.extra, "argument.tagespauschale");
      if (!tagespauschale) {
        tagespauschale = get(this.extra, "modelParameter.pauschale");
      }
      MODEL.argument = MODEL.argument || {};
      MODEL.argument.tagespauschale = tagespauschale;
      this.$emit("changeModel", { model: MODEL });
    },
  },
};
