import { EventBus } from "../../../functions/event-bus";
import {
  isArray,
  isPlainObject,
} from "lodash-es";

import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("table");

// @vue/component
export default {
  name: "TableFilterCenter",
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isArray() {
      return item => isArray(item);
    },

    isPlainObject() {
      return item => {
        return isPlainObject(item);
      };
    },

    ...mapGetters([
      "GET_TABLE_DEFINED_MODEL_BY_ID",
      "GET_LABEL_BY_ID",
      "GET_LABEL_ARRAY_BY_ID",
      "GET_IS_FILTER_BY_ID",
    ]),
  },
  methods: {
    removeFilter({ filterId, value, all, filterKey }) {
      EventBus.$emit(`removeFilter_${ this.options.id }`, { filterId, value, all, filterKey });
    },
  },
};
