var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["foerdermodul"] = jade_interp = function(idx, title, info, diffBlock){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if ( info === undefined)
{
var info = "vm.modul.regel[vm.modul.prefix + '_astbez']"
}
var rulesKbez = "vm.modul.regel[vm.modul.prefix + '_kbez']"
var rulesBez = "vm.modul.regel[vm.modul.prefix + '_bezeichnung']"
buf.push("<div" + (jade.attr("ng-class", "{'dirty': !vm.isClean(), 'edit': vm.editMode, 'version-detail': " + (diffBlock) + ", 'version-loading': vm.loadingDiff}", true, true)) + " class=\"form-step-detail\"><dias-info-box" + (jade.attr("idx", "" + (idx) + "", true, true)) + (jade.attr("name", "" + (title) + "", true, true)) + (jade.attr("info-text", "" + (info) + "", true, true)) + (jade.attr("rules-kbez", "" + (rulesKbez) + "", true, true)) + (jade.attr("rules-bez", "" + (rulesBez) + "", true, true)) + " disabled=\"vm.loading\" is-readonly=\"vm.isReadonly()\" edit-mode=\"vm.editMode\" toggle-edit-mode=\"vm.toggleEditMode()\" is-missing-input=\"vm.isMissingInput()\" notizen=\"vm.notizen\" notiz-obj=\"vm.obj\" notizoptions=\"vm.notizoptions\" notizkey=\"vm.notizkey\" notiz-weitere-obj=\"[vm.modul.regel]\" tealium-track-info=\"vm.modul.regel.regel_nummer\" regel=\"vm.modul.regel\"></dias-info-box><form ng-submit=\"vm.saveChanges()\" name=\"{{:: vm.getFormName()}}\" novalidate dias-loading=\"vm.loading\" loading-msg=\"'Daten werden gespeichert'\" class=\"form-horizontal\">");
block && block();
buf.push("</form></div>");
};













jade_mixins["readonly-block"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div ng-if=\"vm.isReadonly() || !vm.globalBearbeiten\" ng-show=\"!vm.editMode\" ng-class=\"{'clearfix-hover' : vm.hoverPosition}\" class=\"clearfix box_hover\">");
block && block();
buf.push("</div><div ng-if=\"!vm.isReadonly() &amp;&amp; vm.globalBearbeiten\" ng-show=\"!vm.editMode\" ng-click=\"!vm.isReadonly() &amp;&amp; vm.globalBearbeiten ? vm.toggleEditMode() : null\" data-toggle=\"{{!vm.isReadonly() &amp;&amp; vm.globalBearbeiten ? 'edit' : ''}}\" ng-class=\"{ 'editable' : !vm.isReadonly() &amp;&amp; vm.globalBearbeiten }\" tabindex=\"-1\" class=\"clearfix box_hover\">");
block && block();
buf.push("</div>");
};
jade_mixins["edit-block"] = jade_interp = function(title, notNecessary){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<!--div(ng-if=\"vm.editEnteredOnce\" ng-show=\"vm.editMode\")--><div ng-if=\"vm.editEnteredOnce &amp;&amp; vm.editMode\"><fieldset><div ng-if=\"vm.hasErrors()\" role=\"alert\" class=\"alert alert-danger\"><p><strong>Bitte prüfen Sie Ihre Eingaben.</strong></p><ul ng-if=\"vm.errors['non_field_errors']\" class=\"list-unstyled\"><li ng-repeat=\"err in vm.errors['non_field_errors']\">{{ err }}</li></ul></div>");
block && block();
buf.push("</fieldset><div class=\"form-actions edit-actions row\">");
if ( notNecessary)
{
buf.push("<div class=\"col-lg-6\"></div>");
}
else
{
buf.push("<div class=\"col-lg-6\"><dias-requirement></dias-requirement></div>");
}
buf.push("<div class=\"col-lg-6 right\"><div class=\"form-actions\"><button type=\"submit\"" + (jade.attr("ng-attr-title", "Formular " + (title) + " speichern", true, true)) + " class=\"btn btn-primary primary-action test-save\">Speichern</button><button ng-click=\"vm.setEditMode(false)\" type=\"button\"" + (jade.attr("ng-attr-title", "Formular " + (title) + " abbrechen", true, true)) + " class=\"btn btn-icon secondary-action\">Abbrechen</button></div></div></div></div>");
};
jade_mixins["form-element"] = jade_interp = function(idx, label, helpText, fieldName, helpTextChange){
var block = (this && this.block), attributes = (this && this.attributes) || {};
jade_mixins["client-form-error-list-els"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
};
buf.push("<div" + (jade.cls([attributes.class], [false])) + "><!--label.font-weight-bold(ng-class=\"{'error': vm.errors['#{fieldName}'] || vm.form['#{fieldName}'].$invalid , 'warning': vm.isMissing('#{fieldName}')}\")--><label" + (jade.attr("ng-class", "{'has-error': vm.errors['" + (fieldName) + "'] || vm.form['" + (fieldName) + "'].$invalid , 'warning': vm.isMissing('" + (fieldName) + "')}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = idx) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span" + (jade.attr("ng-show", "vm.isFieldRequired('" + (fieldName) + "');", true, true)) + ">*</span><span class=\"alert-danger\"></span></span><br><span class=\"hint\">" + (jade.escape(null == (jade_interp = helpText) ? "" : jade_interp)));
if ( helpTextChange)
{
buf.push("<span class=\"hint\">&nbsp; {{" + (jade.escape((jade_interp = helpTextChange) == null ? '' : jade_interp)) + " < 0 ? -1 * " + (jade.escape((jade_interp = helpTextChange) == null ? '' : jade_interp)) + " + \" zu lang\" : \"noch \" + " + (jade.escape((jade_interp = helpTextChange) == null ? '' : jade_interp)) + " + \" Zeichen\"}}</span>");
}
buf.push("</span><div>");
block && block();
buf.push("<div" + (jade.attr("ng-if", "vm.form['" + (fieldName) + "'].$invalid || vm.errors['" + (fieldName) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (fieldName) + "']", true, true)) + ">{{ err }}</li>");
jade_mixins["client-form-error-list-els"]();
buf.push("</ul></div></div></label></div>");
};






jade_mixins["terminreiheMixin"] = jade_interp = function(obj, label, snapshotStatus, date){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div class=\"box-left\"><div class=\"title-left\"><div>" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "</div><div" + (jade.attr("ng-if", "!" + (snapshotStatus) + "", true, true)) + " class=\"version-date\">({{ " + (jade.escape((jade_interp = date) == null ? '' : jade_interp)) + " }})</div></div></div><div class=\"box-right\"><div class=\"col-lg-12 text\"><span ng-if=\"vm.snapshot &amp;&amp; vm.showDiff()\" class=\"glyphicon glyphicon-changes version-color version-left\"></span><div class=\"version-box\"><div" + (jade.attr("ng-if", "" + (obj) + ".terminreihe", true, true)) + "><strong>{{ " + (jade.escape((jade_interp = obj) == null ? '' : jade_interp)) + ".terminreihe.trr_titel }}</strong><br><br><div" + (jade.attr("ng-repeat", "termin in " + (obj) + ".terminreihe.termine", true, true)) + "><span>{{ $index + 1}}. {{ termin }}</span></div></div><div" + (jade.attr("ng-if", "!" + (obj) + ".terminreihe", true, true)) + ">[Keine Terminreihe ausgewählt]</div><br><div" + (jade.attr("ng-if", "" + (obj) + ".einreichfrist", true, true)) + ">Es wurde eine gesonderte Einreichfrist vereinbart: {{ " + (jade.escape((jade_interp = obj) == null ? '' : jade_interp)) + ".einreichfrist | date }}.</div></div></div></div>");
};
buf.push("<script type=\"text/ng-template\" id=\"TerminreiheChoicesTemplate.html\"><span class=\"label-hidden\">Wählen Sie bitte eine Terminreihe</span><span>{{ item.trr_titel }}</span></script><script type=\"text/ng-template\" id=\"TerminreiheMatchTemplate.html\"><span>{{ vm.selected.trr_titel }}</span></script>");
jade_mixins["foerdermodul"].call({
block: function(){
jade_mixins["edit-block"].call({
block: function(){
buf.push("<div ng-if=\"vm.errors.non_field_errors\" class=\"alert alert-danger\"><p><strong>Beim Speichern der Daten sind Fehler aufgetreten:</strong></p><ul class=\"list-unstyled\"><li ng-repeat=\"err in vm.errors.non_field_errors\">{{ err }}</li></ul></div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<dias-ui-select select-list=\"vm.reihenChoices\" select-model=\"vm.userInput.terminreihe\" select-choices-template=\"'TerminreiheChoicesTemplate.html'\" select-match-template=\"'TerminreiheMatchTemplate.html'\" select-placeholder=\"'Terminreihe auswählen'\" select-id=\"'terminreihe'\" select-label-id=\"'trr_id'\" search-label=\"'trr_titel'\" select-required=\"true\"></dias-ui-select>");
}
}, "", "Terminreihe", "", "terminreihe");
buf.push("<div ng-if=\"vm.parameter.activate_field_einreichfrist\">");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input id=\"einreichfrist\" ng-model=\"vm.userInput.einreichfrist\" name=\"einreichfrist\" type=\"text\" bs-datepicker aria-required=\"false\" ng-attr-aria-invalid=\"{{vm.errors.einreichfrist ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "", "Alternative Einreichfrist", "", "einreichfrist");
buf.push("</div>");
}
});
jade_mixins["readonly-block"].call({
block: function(){
buf.push("<div ng-if=\"!vm.obj.a_antragsdatum &amp;&amp; !vm.einreichbar\" class=\"alert alert-warning\">Das Einreichdatum ist bereits überschritten, der Antrag kann nicht eingereicht werden.</div><fieldset class=\"fieldset-version\">");
jade_mixins["terminreiheMixin"]("vm.modul.data", "Aktuelle Version", "true");
buf.push("</fieldset><fieldset ng-if=\"vm.snapshot &amp;&amp; vm.showDiff()\" class=\"fieldset-version version\">");
jade_mixins["terminreiheMixin"]("vm.getSnapshot()", "Vergleichsversion", "false", "vm.snapshot.asn_snapshot_datum | date:'dd.MM.yyyy HH:mm'");
buf.push("</fieldset>");
}
});
}
}, "vm.idx", "vm.parameter.frage_text", undefined, "vm.showDiff()");;return buf.join("");
}