import HttpMixin from "../../../../../global/mixins/HttpMixin";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import translate from "../../../../../global/directives/translate";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import {
  cloneDeep,
  isUndefined,
} from "lodash-es";
import {
  initModelFromList,
} from "../../../../../global/functions/mappingForParameterToFormElement";

// @vue/component
export default {
  name: "TermineingabeModalCreateOrEdit",
  components: {
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    termineList: {
      type: Array,
      required: true,
    },
    termin: {
      type: Object,
      required: false,
      default: undefined,
    },
    selectorClose: {
      type: [String, Array],
      required: true,
    },
    urlSave: {
      type: String,
      required: true,
    },
    infoIText: {
      type: String,
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      termineListLocal: cloneDeep(this.termineList),
      status: {
        loading: undefined,
      },
      model: {},
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    headerText() {
      return this.termin ? "_TXT_TERMINEINGABE_GR_MODAL_EDIT_HEADER_" : "_TXT_TERMINEINGABE_GR_MODAL_CREATE_HEADER_";
    },

    optionsModal() {
      return {
        showCloseButton: true,
        required: true,
        list: this.termineListLocal,
      };
    },
  },
  created() {
    this.initModel();
  },
  methods: {
    save() {
      this.status.loading = true;
      this.clearErrors();
      const DATA = {
        termin: cloneDeep(this.model)
      };
      if (DATA.termin && !isUndefined(DATA.termin.private_termin)) {
        DATA.termin.private = DATA.termin.private_termin;
      }
      this.putHttp({
        url: this.urlSave,
        data: DATA,
      }).then(
        response => {
          const TEXT = this.termin ?
            "_MSG_TERMINEINGABE_GR_EDIT_SUCCESS_" :
            "_MSG_TERMINEINGABE_GR_CREATE_SUCCESS_";
          this.addNotification({ text: TEXT });
          this.close({ response });
        },
        error => {
          this.onError({ error: error.data });
          const TEXT = this.termin ?
            "_MSG_TERMINEINGABE_GR_EDIT_ERROR_" :
            "_MSG_TERMINEINGABE_GR_CREATE_ERROR_";
          this.addNotification({ text: TEXT, type: "error" });
        }
      ).then(
        () => this.status.loading = false
      );
    },

    onError({ error }) {
      const ERRORS = cloneDeep(error);
      if (ERRORS.termin) {
        if (ERRORS.termin && !isUndefined(ERRORS.termin.private)) {
          ERRORS.termin.private_termin = ERRORS.termin.private;
        }
        this.errors = ERRORS.termin;
        this.errorsClone = ERRORS.termin;
      }
    },

    clearErrors() {
      this.errors = undefined;
      this.errorsClone = undefined;
    },

    initModel() {
      if (this.termin) {
        this.model = cloneDeep(this.termin);
      } else {
        this.model = initModelFromList({ list: this.termineListLocal });
      }
    },
  },
};
