import AktualisierterZuschussPosition from "./AktualisierterZuschussPosition/AktualisierterZuschussPosition.vue";
import Alert from "../../../global/components/Alert/Alert.vue";
import GeschaeftsregelModul from "../../Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotItem from "../../Snapshot/SnapshotItem/SnapshotItem.vue";
import ZuschussAnpassungModalCreateOrUpdate from "./ZuschussAnpassungModalCreateOrUpdate/ZuschussAnpassungModalCreateOrUpdate.vue";

import loading from "../../../global/directives/loading";
import translate from "../../../global/directives/translate";

import {
  FilterCurrencyMixin,
} from "../../../global/mixins/FiltersMixin";


import InfoproviderStatuses from "../../../const/InfoproviderStatuses";
import SaveCallbackActions from "../../../const/SaveCallbackActions";
import {
  cloneDeep,
  filter,
  forEach,
  get,
  isNil,
  keyBy,
} from "lodash-es";

export function getZuschussAngepasst({ isSnapshot, vertrag, snapshot, antrag }) {
  let v_zuschussangepasst = vertrag.v_zuschussangepasst;
  if (isSnapshot) {
    v_zuschussangepasst = get(snapshot, "vertrag.v_zuschussangepasst");
  }
  if (isNil(v_zuschussangepasst)) {
    return isSnapshot ?
      get(snapshot, "antrag.a_zuschussbewilligt") :
      antrag.a_zuschussbewilligt;
  }
}

export function getVertragBetrag({ data, isSnapshot, snapshot, vertrag, zuschussRelevant, zuschussRelevantSnapshot }) {
  if ((!zuschussRelevant && !isSnapshot) ||
    (!zuschussRelevantSnapshot && isSnapshot)) {
    return 0;
  }
  if (data && data.status) {
    if (data.status === InfoproviderStatuses.PROCESSOR_STATUS_OK) {
      if (!data.gesamtzuschuss) {
        return 0;
      }
      return data.gesamtzuschuss;
    }
    return 0;
  }
  const V_ZUSCHUSS_AKTUALISIERT = isSnapshot ?
    get(snapshot, "vertrag.v_zuschussaktualisiert") :
    vertrag.v_zuschussaktualisiert;
  if (V_ZUSCHUSS_AKTUALISIERT) {
    return V_ZUSCHUSS_AKTUALISIERT;
  }
  return 0;
}

export function getZuschussanpassungSumme({ vertrag = {} }) {
  if (!vertrag.zuschussanpassungen) {
    return 0;
  }
  let sum = 0;
  forEach(vertrag.zuschussanpassungen, item => {
    sum += Number(item.vza_betrag || 0);
  });
  return sum;
}

export function getStatusShowPositionZuschuss({ vertrag = {}, antrag = {} }) {
  return vertrag.zuschussanpassungen.length > 0 ||
    (vertrag.v_zuschussangepasst &&
      vertrag.v_zuschussangepasst !== antrag.a_zuschussbewilligt);
}

// @vue/component
export default {
  components: {
    AktualisierterZuschussPosition,
    Alert,
    GeschaeftsregelModul,
    Permission,
    PuxButton,
    PuxIcon,
    PuxTranslate,
    SnapshotIcon,
    SnapshotItem,
    ZuschussAnpassungModalCreateOrUpdate,
  },
  directives: {
    loading,
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
  ],
  props: {
    vertrag: {
      type: Object,
      required: true,
      info: "Vertrag",
    },
    auszahlung: {
      type: Object,
      required: true,
      info: "Auszahlung",
    },
    antrag: {
      type: Object,
      required: true,
      info: "Antrag",
    },
    infoprovider: {
      type: Object,
      required: false,
      default: undefined,
      info: "Zuschuss",
    },
    module: {
      type: Array,
      required: false,
      default: () => [],
      info: "GR-Module",
    },
    loading: {
      type: Boolean,
      required: false,
      info: "StatusLoading (kofimodule || zuschuss)",
    },
    readonly: {
      type: Boolean,
      required: true,
      info: "Status, mit dem man nichts bearbeiten darf",
    },
    saveCallback: {
      type: Function,
      required: true,
      info: "Reload viele Informationen(Auszahlung, Plan, Workflowfolgen, Vertrag, Zuschuss)",
    },
    updateinfoprovider: {
      type: Function,
      required: true,
      info: "Funktion updateinfoprovider",
    },
    zuschussRelevant: {
      type: Boolean,
      required: false,
      info: "Status zuschussRelevant",
    },
    zuschussRelevantSnapshot: {
      type: Boolean,
      required: false,
    },
    kofiZuschussKeyByRegelNummer: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
      info: "Snapshot-Information",
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    diffZuschuss: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      statusShowAnpassungen: true,
      htmlId: "aktualisierter_zuschuss",
      statusModalZuschussAnpassung: false,
      selectorClose: undefined,
      anpassungCurrent: undefined,
      infoproviderStatuses: InfoproviderStatuses,
    };
  },
  computed: {
    loadingOptions() {
      return {
        status: this.loading,
        msg: "_TXT_LOADING_ZUSCHUSSINFORMATION_",
        size: "lg",
      };
    },

    showmessages() {
      return !this.readonly;
    },

    zuschussModule() {
      return filter(this.module, modul => {
        return modul.modulgruppen.indexOf("zuschuss") !== -1 ||
          modul.modulgruppen.indexOf("pauschale") !== -1 ||
          modul.modulgruppen.indexOf("deckelung") !== -1;
      });
    },

    zuschussanpassungSumme() {
      return getZuschussanpassungSumme({ vertrag: this.vertrag });
    },

    zuschussanpassungSummeCurrency() {
      return this.filterCurrency(this.zuschussanpassungSumme);
    },

    zuschussangepasst() {
      if (this.vertrag.v_zuschussangepasst === null) {
        return this.antrag.a_zuschussbewilligt;
      }
      return this.vertrag.v_zuschussangepasst;
    },

    zuschussangepasstCurrency() {
      return this.filterCurrency(this.zuschussangepasst);
    },

    vertragBetrag() {
      if (this.infoprovider && this.infoprovider.status) {
        if (this.infoprovider.status === InfoproviderStatuses.PROCESSOR_STATUS_OK) {
          return this.filterCurrency(this.infoprovider.gesamtzuschuss || 0);
        }
        return "-";
      }
      if (this.vertrag.v_zuschussaktualisiert) {
        return this.filterCurrency(this.vertrag.v_zuschussaktualisiert || 0);
      }
      return this.filterCurrency(0);
    },

    statusShowPositionZuschuss() {
      return getStatusShowPositionZuschuss({
        vertrag: this.vertrag,
        antrag: this.antrag,
      });
    },

    ariaDescribedbyForButtons() {
      // TODO: Barrierefreiheit
      return "";
    },

    positionDetailsId() {
      return `${ this.htmlId }_position_details`;
    },

    buttonTogglePositionId() {
      return `${ this.htmlId }_position_toggle`;
    },

    buttonTogglePositionTranslate() {
      return this.statusShowAnpassungen ? "_BTN_ANPASSUNGEN_VERBERGEN_" : "_BTN_ANPASSUNGEN_ZEIGEN_";
    },

    buttonTogglePositionIcon() {
      return this.statusShowAnpassungen ? "chevron-up" : "chevron-down";
    },

    vertragZuschussanpassungen() {
      return this.vertrag.zuschussanpassungen || [];
    },

    statusShowAlert() {
      return this.zuschussRelevant &&
        this.infoprovider &&
        ((this.infoprovider.status === InfoproviderStatuses.PROCESSOR_STATUS_OK &&
          (this.infoprovider.meldungen.length || this.infoproviderMeldungenSnapshot.length) &&
          this.showmessages) ||
          this.infoprovider.status === InfoproviderStatuses.PROCESSOR_STATUS_IMPERFECT);
    },

    alertType() {
      const INFOPROVIDER_STATUS = get(this.infoprovider, "status");
      if (INFOPROVIDER_STATUS === 1) {
        return "warning";
      }
      if (INFOPROVIDER_STATUS === 2) {
        return "danger";
      }
      return undefined;
    },

    buttonCreateAnpassungId() {
      return `${ this.htmlId }_create_anpassung`;
    },

    statusDiff() {
      // TODO: Snapshot
      return false;
    },

    extraForModule() {
      return {
        antrag: this.antrag,
        vertrag: this.vertrag,
        diffZuschuss: this.diffZuschuss,
        kofiZuschussKeyByRegelNummer: this.kofiZuschussKeyByRegelNummer,
      };
      // TODO: Barrierefreiheit
    },

    infoproviderSnapshot() {
      return get(this.snapshot, "osn_snapshot.kofi.zuschuss");
    },

    infoproviderMeldungenSnapshot() {
      const MELDUNGEN = get(this.infoproviderSnapshot, "meldungen") || [];
      if (this.diffZuschuss.status) {
        return filter(MELDUNGEN, (_, index) => {
          return this.diffZuschuss.changes.meldungen.snapshot[index];
        });
      }
      return [];
    },

    currentSnapshot() {
      return get(this.snapshot, "osn_snapshot", {});
    },

    vertragSnapshot() {
      return get(this.currentSnapshot, "vertrag");
    },

    snapshotAnpassungen() {
      return keyBy(get(this.vertragSnapshot, "zuschussanpassungen") || [], "pk");
    },
  },
  methods: {
    deleteAnpassung() {
      this.saveCallback({ statusSaveCallback: SaveCallbackActions.RELOAD_ALL });
    },

    updateAnpassung({ anpassung, selectorClose }) {
      this.anpassungCurrent = cloneDeep(anpassung);
      this.selectorClose = selectorClose;
      this.statusModalZuschussAnpassung = true;
    },

    createAnpassung() {
      this.statusModalZuschussAnpassung = true;
    },

    closeModalZuschussAnpassung({ response } = {}) {
      if (response) {
        this.saveCallback({ statusSaveCallback: SaveCallbackActions.RELOAD_ALL });
      }
      this.statusModalZuschussAnpassung = false;
      this.anpassungCurrent = undefined;
    },

    togglePositionen() {
      this.statusShowAnpassungen = !this.statusShowAnpassungen;
    },

    updateinfoproviderLocal(arg) {
      this.updateinfoprovider(arg);
    },

    saveCallbackLocal(arg) {
      this.saveCallback(arg);
    },

    getZuschussAngepasstLocal({ isSnapshot }) {
      return getZuschussAngepasst({
        isSnapshot,
        vertrag: this.vertrag,
        snapshot: this.currentSnapshot,
        antrag: this.antrag,
      });
    },

    getVertragBetragLocal({ data, isSnapshot }) {
      return getVertragBetrag({
        data,
        isSnapshot,
        snapshot: this.currentSnapshot,
        vertrag: this.vertrag,
        zuschussRelevant: this.zuschussRelevant,
        zuschussRelevantSnapshot: this.zuschussRelevantSnapshot,
      });
    },

    getZuschussanpassungSummeLocal({ data }) {
      return getZuschussanpassungSumme({ vertrag: data });
    }
  },
};


