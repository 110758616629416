import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxPageDetails from "../../../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import SmartTable from "../../../../../global/components/table/table.vue";
import Spaced from "../../../../../global/components/Spaced/Spaced.vue";

import getTableApi from "../../../../../const/TableApis";

const TABLE_ID = "BerichtCoronaAntraege";

// @vue/component
export default {
  name: "BerichtCoronaAntraege",
  components: {
    AngularLink,
    PuxPageDetails,
    SmartTable,
    Spaced,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_LBL_BERICHT_CORONA_ANTRAEGE_ROW_ACTIONS_DETAILS_",
            route: "root.foerderantrag.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        label: this.report.tab_kbez,
        sortable: true,
        pagination: true,
        details: false,
        showHeader: false,
        view: {
          filter: "top",
        },
      },
    };
  },
  methods: {
    renderFoerderorg({ row, typ, field }) {
      let result = "";
      if (row[field] && row[field].length > 0) {
        result = row[field].filter(item => item.o_otid === typ).map(item => item.o_kbez).join(", ");
      }
      return result;
    },
  },
};
