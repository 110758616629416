"use strict";

import angular from "angular";
import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";
import _ from "lodash";

class RegelsatzVersionEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, KZielgruppe, Workflow, KWorkflowtypUUIDs, i18n, Katalog, $filter) {
    super($q, $scope, DiasNotification);
    this.$filter = $filter;
    this.gettext = i18n.gettext;
    this.errorsLabel = {
      ars_eigenstaendig: "Eigenständig: ",
      ars_kbez: "Version Kurzbezeichnung: ",
      ars_bez: "Version Bezeichnung: ",
      ars_gueltig_ab: "Gültig ab: ",
      ars_gueltig_bis: "Gültig bis: ",
      ars_max_antraege: "Maximale Anzahl Anträge pro Antragsteller: ",
      perioden: "Perioden: ",
      gutachtenvorlage: "Gutachtenvorlagen: ",
      zielgruppen: "Zielgruppen: ",
      einstiegsworkflows: "Workflow-Einstieg für Anträge: ",
      antragstyp: "Antragstyp:"
    };
    this.labelArsBez = "Version Bezeichnung (max 200 Zeichen)";
    this.KWorkflowtypUUIDs = KWorkflowtypUUIDs;
    this.errorContainerId = "errors_edit_" + Math.random().toString(36).slice(2);

    this.zielgruppenChoices = $scope.extra.zielgruppen;
    this.workflowChoices = $scope.extra.workflows;
    this.periodenConfig = {
      id: "perioden",
      type: "multiselect",
      label: "_LBL_REGELSATZ_DETAIL_PERIODEN_",
      keyLabel: "pe_kbez",
      keyId: "pk",
      view: "v-alt",
      data: this.$scope.extra.perioden,
      required: false,
    };
    this.model.perioden = _.cloneDeep($scope.model.perioden) || [];
    this.periodenChange = ({ model }) => {
      this.model.perioden = model;
    };
    this.gutachtenvorlagenConfig = {
      id: "gutachtenvorlagen",
      type: "multiselect",
      label: "_LBL_REGELSATZ_DETAIL_GUTACHTENVORLAGEN_",
      keyLabel: "gav_kbez",
      keyId: "pk",
      view: "v-alt",
      data: this.$scope.extra.gutachtenvorlagen,
      required: false,
    };
    this.model.gutachtenvorlagen = _.cloneDeep($scope.model.gutachtenvorlagen) || [];
    this.gutachtenvorlagenChange = ({ model }) => {
      this.model.gutachtenvorlagen = model;
    };

    for (let i = 0; i < $scope.model.einstiegsworkflows.length; i++) {
      if ($scope.model.einstiegsworkflows[i].einstieg_workflow_obj.workflowtyp === KWorkflowtypUUIDs.antrag) {
        this.selected_antrag_workflow = $scope.model.einstiegsworkflows[i].einstieg_workflow;
      }
    }
    Katalog.getKatalog("kantragstyp").then(
      response => this.antragsTypen = response
    );

    this.zielgruppenSelection = {};
    angular.forEach(
      $scope.model.zielgruppen,
      gruppe => this.zielgruppenSelection[gruppe.pk] = true
    );
    this.extra = this.$scope.extra;
    this.onEnterEditMode = this.onEnterEditMode.bind(this);
  }

  changeArsBez() {
    if (!this.model.ars_bez) {
      this.labelArsBez = "Version Bezeichnung (max 200 Zeichen)";
    } else {
      const plaintext = this.$filter("htmlToPlaintext")(this.model.ars_bez || "");
      const remaining = 200 - plaintext.length;
      this.labelArsBez = "Version Bezeichnung (noch " + remaining + " Zeichen)";
    }
  }

  onEnterEditMode() {
    super.onEnterEditMode();
    this.atSelection = {};
    if (this.$scope.extra.antragstellerorgtypen) {
      this.$scope.extra.antragstellerorgtypen.forEach(
        v => this.atSelection[v.pk] = this.model.antragstellerorgtypen.indexOf(v.pk) !== -1
      );
    }
    if (this.periodenConfig && this.$scope.extra.perioden) {
      this.periodenConfig.data = this.$scope.extra.perioden;
    }
    if (this.gutachtenvorlagenConfig && this.$scope.extra.gutachtenvorlagen) {
      this.gutachtenvorlagenConfig.data = this.$scope.extra.gutachtenvorlagen;
    }
  }

  _save(errorsLabel) {
    this.model.zielgruppen_ids = [];
    this.model.zielgruppen.splice(0, this.model.zielgruppen.length);
    angular.forEach(this.zielgruppenSelection, (selected, pk) => {
      if (selected === true) {
        this.model.zielgruppen_ids.push(pk);
        this.model.zielgruppen.push(this.zielgruppenChoices.getById(pk));
      }
    });
    this.model.einstiegsworkflows = [{ einstieg_workflow: this.selected_antrag_workflow }];
    this.model.antragstellerorgtypen = Object.keys(this.atSelection).filter(
      key => this.atSelection[key]
    );
    return super._save(errorsLabel).then(
      () => {
        this.$scope.model.einstiegsworkflows[0].einstieg_workflow_obj = this.workflowChoices.getById(this.selected_antrag_workflow);
      }
    );
  }
}

export default RegelsatzVersionEditController;
