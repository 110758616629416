import Modal from "../../../global/components/Modal/Modal.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import {
  FilterSpacedMixin,
} from "../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "SitzungsantraegeModalProtokollView",
  components: {
    Modal,
    PuxCloak,
    PuxTranslate,
  },
  mixins: [
    FilterSpacedMixin,
    HttpMixin,
  ],
  props: {
    baseSaveUrl: {
      type: String,
      default: undefined,
    },
    sitzungsantrag: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      protokollText: "",
      loading: true,
    };
  },
  computed: {
    options() {
      return {
        showCloseButton: true,
        required: false,
        list: this.optionsList,
      };
    },

    optionsList() {
      if (this.loading) {
        return [];
      }
      return [
        {
          id: "sla_protokoll",
          type: "readonly",
          text: this.protokollText,
        },
      ];
    },
  },
  created() {
    this.loadProtokoll();
  },
  methods: {
    loadProtokoll() {
      this.getHttp({
        url: `${ this.baseSaveUrl }${ this.sitzungsantrag.pk }/protokoll_ansehen/`
      }).then(
        response => {
          this.protokollText = response.sla_protokoll || "_TXT_MODAL_PROTOKOLL_VIEW_KEIN_DATA_";
          this.loading = false;
        }
      );
    },
  },
};
