"use strict";

import template from "./objektgutachten.readonly.template.jade";

class ObektgutachtenController {
  /*@ngInject*/
  constructor(i18n) {
    this.loading = false;
    this.gettext = i18n.gettext;
    this.gutachten = [];
    this.loadGutachtenliste();
  }

  loadGutachtenliste() {
    this.loading = true;
    this.source.getList("gutachten").then(
      response => this.gutachten = response
    ).finally(() => this.loading = false);
  }
}

export default {
  template: template(),
  controller: ObektgutachtenController,
  controllerAs: "vm",
  bindings: {
    source: "=",
  }
};
