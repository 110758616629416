import {
  computed,
  toRef,
} from "vue";

import PermissionAPI from "../../../../../global/compositionAPI/PermissionAPI";
import SyConfigAPI from "../../../../../global/compositionAPI/SyConfigAPI";

import {
  capitalize,
  find,
  forEach,
  get,
} from "lodash-es";

export default function AuszahlungWizardStepsAPI(props) {
  const auszahlung = toRef(props, "auszahlung");
  const antrag = toRef(props, "antrag");
  const client = toRef(props, "client");
  const isSachberichtVisible = toRef(props, "isSachberichtVisible");
  const isPruefberichtVisible = toRef(props, "isPruefberichtVisible");
  const sbModule = toRef(props, "sbModule");
  const pbModule = toRef(props, "pbModule");

  const {
    checkPermissionsSync,
  } = PermissionAPI();

  const {
    getSyConfigsValue,
  } = SyConfigAPI();

  const wizardConfigSteps = computed(() => {
    const WIZARD_CONFIG = getSyConfigsValue("auszahlung_wizard", {});
    return get(
      find(
        WIZARD_CONFIG.angebot_wizard_schritte,
        ["angebot", antrag.value.angebot]
      ),
      "config",
      WIZARD_CONFIG.standard_wizard_schritte
    ) || [];
  });

  const auszahlungConfig = computed(() => {
    return getSyConfigsValue("auszahlung", {});
  });
  const isStepBankverbindungVisible = computed(() => {
    return auszahlungConfig.value.bv_relevant !== false;
  });

  const hasWFPermissionVertreterView = computed(() => {
    return checkPermissionsSync({
      perm: "auszahlung.vertreter.view",
      permArray: auszahlung.value.user_permissions,
    });
  });
  const isStepVertreterberechtigteVisible = computed(() => {
    return client.value !== "dias-kumasta" &&
      hasWFPermissionVertreterView.value;
  });

  const hasWFPermissionSachberichtView = computed(() => {
    return checkPermissionsSync({
      perm: "sachbericht.view",
      permArray: auszahlung.value.user_permissions,
    });
  });
  const isStepSachberichtVisible = computed(() => {
    return !!(auszahlung.value.sachbericht &&
      isSachberichtVisible.value &&
      sbModule.value.length &&
      hasWFPermissionSachberichtView.value);
  });

  const hasWFPermissionPruefberichtView = computed(() => {
    return checkPermissionsSync({
      perm: "pruefbericht.view",
      permArray: auszahlung.value.user_permissions,
    });
  });
  const isStepPruefberichtVisible = computed(() => {
    return !!(auszahlung.value.pruefbericht &&
      isPruefberichtVisible.value &&
      pbModule.value.length &&
      hasWFPermissionPruefberichtView.value);
  });

  const isStepZeitraumVisible = computed(() => {
    return checkPermissionsSync({
      perm: "auszahlung.zeitraum.view",
      permArray: auszahlung.value.user_permissions,
    });
  });

  const isStepHinweisVisible = computed(() => {
    return checkPermissionsSync({
      perm: "auszahlung.hinweis.view",
      permArray: auszahlung.value.user_permissions,
    });
  });

  const isStepsVisibleMap = computed(() => {
    return {
      isStepBankverbindungVisible: isStepBankverbindungVisible.value,
      isStepVertreterberechtigteVisible: isStepVertreterberechtigteVisible.value,
      isStepSachberichtVisible: isStepSachberichtVisible.value,
      isStepPruefberichtVisible: isStepPruefberichtVisible.value,
      isStepZeitraumVisible: isStepZeitraumVisible.value,
      isStepHinweisVisible: isStepHinweisVisible.value,
    };
  });

  const isStepVisible = step => {
    const CONDITION_NAME = `isStep${ capitalize(step.step) }Visible`;
    return isStepsVisibleMap.value[CONDITION_NAME] !== false;
  };

  const wizardSteps = computed(() => {
    const WIZARD_STEPS = [];
    forEach(wizardConfigSteps.value, step => {
      if (!isStepVisible(step)) {
        return;
      }
      WIZARD_STEPS.push({
        label: step.label,
        title: step.title || step.label,
        slotId: step.step,
      });
    });
    return WIZARD_STEPS;
  });

  return {
    wizardSteps,
  };
}
