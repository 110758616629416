import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import SmartTable from "../../../global/components/table/table.vue";
import AnalysenStatus from "../../Analysen/AnalysenStatus/AnalysenStatus.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import getTableApi from "../../../const/TableApis";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";

const TABLE_ID = "syconfigadmin";

// @vue/component
export default {
  name: "SystemKonfigurationList",
  components: {
    AngularLink,
    AnalysenStatus,
    Permission,
    SmartTable,
    PageTabTitle,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        rowId: "kennung",
        url: getTableApi({ id: TABLE_ID }),
        cols: [
          {
            label: "Schlüssel",
            id: "kennung",
            slot: "kennung",
            sortable: true,
            sortId: "kennung",
            hide: true,
          },
          {
            label: "Bezeichnung",
            id: "bezeichnung",
            slot: "bezeichnung",
            sortable: true,
            sortId: "bezeichnung",
          },
          {
            label: "Beschreibung",
            id: "beschreibung",
          },
          {
            label: "Status",
            id: "status",
          },
          {
            label: "Definiert in",
            id: "quelle",
          },
          {
            label: "DB-Eintrag",
            id: "db_eintrag",
            templateCallback: ({ row }) => row.db_eintrag === false ? "Nein" : "Ja",
            hide: true,
          },
          {
            label: "Benötigt",
            id: "benoetigt",
            templateCallback: ({ row }) => row.benoetigt === false ? "Nein" : "Ja",
            hide: true,
          },
        ],
        rowActions: [
          {
            label: "Details anzeigen",
            route: "root.syconfigadmin.details",
            routeParameters: [{ routeId: "kennung", rowPath: "kennung" }],
          },
        ],
        label: "System-Konfigurationen",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      filters: [
        {
          type: "text",
          id: "suche",
          label: "Suche",
          main: true,
        },
        {
          type: "select",
          id: "status",
          label: "Status",
          keyLabel: "label",
          keyId: "value",
          alwaysVisible: true,
          data: [
            {
              label: "Ok",
              value: "Ok"
            },
            {
              label: "Fehlerhaft",
              value: "Fehlerhaft"
            },
            {
              label: "Unbekannt",
              value: "Unbekannt"
            },
            {
              label: "Eintrag benötigt",
              value: "Eintrag benötigt"
            },
          ],
          search: true,
          searchList: ["label"],
        },
        {
          type: "select",
          url: "syconfigquelle/?fields=quelle&key=quelle",
          id: "quelle",
          label: this.$gettext("Definiert in"),
          keyLabel: "quelle",
          keyId: "quelle",
          search: false,
        },
      ],
    };
  },
};
