"use strict";

import BaseModel from "dias/core/models/baseModel";

class Bankverbindung extends BaseModel {
  constructor() {
    super("bankverbindungen");
  }

  extendModel(model) {
    model.uebernehmen = () => model.customPUT({}, "uebernehmen");
    return model;
  }
}

export default Bankverbindung;
