"use strict";

import BaseModel from "dias/core/models/baseModel";

class AnonymisierteNutzer extends BaseModel {
  constructor() {
    super("anonymisierte_nutzer", {});
  }

  extendModel(model) {
    model = super.extendModel(model);
    model.getPreview = () => model.customGET("vorschau");
    return model;
  }
}

export default AnonymisierteNutzer;
