import FormElement from "../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "TheNavbarSearch",
  components: {
    FormElement,
    PuxButton,
  },
  data() {
    return {
      modelSearch: "",
      options: {
        id: "search_global",
        type: "text",
        label: "Was suchen Sie?",
        view: "v",
      },
    };
  },
  methods: {
    onSearch() {
      // TODO:
    },
  },
};
