import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "JsonEingabe",
  components: {
    SnapshotModule,
    SnapshotIcon,
    FormElementReadOnly,
  },
  mixins: [
    ModuleMixin,
  ],
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.list = [
        {
          id: "json",
          htmlId: `${ this.htmlRegelNummer }_json`,
          type: "json",
          label: "_LBL_GR_JSON_EINGABE_",
          translate: true,
          required: this.modelParameter.pflichtfeld,
          view: "v-alt",
          editonly: true,
          startupFocus: true,
        },
      ];
      return OPTIONS;
    },

    optionsJsonReadonly() {
      return {
        id: "json",
        htmlId: `${ this.htmlRegelNummer }_json`,
        type: "json",
        label: "_LBL_GR_JSON_EINGABE_",
        translate: true,
        required: this.modelParameter.pflichtfeld,
        view: "v-alt",
        showLabel: true,
      };
    },

    labelHeader() {
      return this.modelParameter.frage;
    },

    isModuleEditable() {
      return !this.modelParameter.read_only && !this.statusReadonly;
    },
  },
};
