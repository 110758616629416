import PuxButton from "../PuxButton/PuxButton.vue";
import PuxErrorsElement from "./PuxErrorsElement/PuxErrorsElement.vue";
import PuxIcon from "../PuxIcon/PuxIcon.vue";
import PuxTranslate from "../PuxTranslate/PuxTranslate.vue";

import {
  toFormElementFromParameter,
} from "../../functions/mappingForParameterToFormElement";
import {
  forEach,
  isEmpty,
  isNil,
  isPlainObject,
  isUndefined,
} from "lodash-es";

const TIME = new Date().getTime();

// @vue/component
export default {
  name: "PuxErrors",
  components: {
    PuxButton,
    PuxErrorsElement,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: () => `pux_errors_${ TIME }`,
    },
    errors: {
      type: Object,
      required: false,
      default: undefined,
    },
    header: {
      type: String,
      required: false,
      default: () => "_MSG_ERROR_"
    },
    labels: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
    },
    optionsList: {
      type: Array,
      required: false,
      default: () => [],
      info: "Die Liste mit Options für FormElement",
    },
    autoFocus: {
      type: Boolean,
      required: false,
      default: true,
      info: "Fokus setzen, wenn this.statusShowErrors === true",
    },
    goToError: {
      type: Function,
      required: false,
      default: undefined,
    },
    isErrorLink: {
      type: Boolean,
      required: false,
      default: true,
    },
    isDismissible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: [
    "onDismiss",
  ],
  computed: {
    labelsLocal() {
      return Object.assign({}, this.labelsFromOptionsList, this.labels);
    },

    labelsFromOptionsList() {
      const ADD_SUBOPTIONS = (parent_labels, options) => {
        if (!isNil(options.fields)) {
          const CURRENT_LABEL_OPTIONS = parent_labels[options.id];
          const SUB_OPTIONS = toFormElementFromParameter({ obj: options.fields }).list;
          forEach(SUB_OPTIONS, opt => {
            CURRENT_LABEL_OPTIONS[opt.id] = {
              label: opt.label,
              link: !options.element_errors,
              id: `${ CURRENT_LABEL_OPTIONS.id }_${ opt.htmlId || opt.id }`,
              hasElementErrors: opt.element_errors,
            };
            ADD_SUBOPTIONS(CURRENT_LABEL_OPTIONS, opt);
          });
        }
      };
      const LABELS_FROM_OPTIONS_LIST = {};
      this.optionsList.forEach(options => {
        LABELS_FROM_OPTIONS_LIST[options.id] = {
          label: options.label,
          link: !isUndefined(options.isErrorLink) ? options.isErrorLink : true,
          id: options.htmlId || options.id,
          hasElementErrors: options.element_errors,
        };
        ADD_SUBOPTIONS(LABELS_FROM_OPTIONS_LIST, options);
      });
      return LABELS_FROM_OPTIONS_LIST;
    },

    errorsLocal() {
      return this.errors || {};
    },

    statusShowErrors() {
      return !isEmpty(this.errorsLocal);
    },

    statusErrorsObject() {
      return isPlainObject(this.errors);
    },

    idForButtonClose() {
      return `${ this.id }_btn_close`;
    },
  },
  watch: {
    statusShowErrors: {
      deep: true,
      handler() {
        this.setFocusToElement();
      },
    },
  },
  methods: {
    setFocusToElement() {
      if (this.autoFocus && this.statusShowErrors) {
        setTimeout(() => {
          this.$el.focus();
        });
      }
    },

    onDismissLocal() {
      this.$emit("onDismiss");
    },
  },
};
