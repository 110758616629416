"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import "angular-drag-and-drop-lists";

import diasAuthService from "dias/auth/services";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";

import sitzungsmodule from "../sitzungsmodule";

import models from "../models";
import services from "../services";
import ui from "../ui";
import detailsComponent from "./details.component";


const sitzungenModule = angular.module("root.sitzungen.verwaltung", [
  uiRouter,
  "dndLists",
  "chart.js",
  models.name,
  services.name,
  ui.name,
  sitzungsmodule.name,
  diasAuthService.name,
  diasTable.name,
  diasLoading.name,
  diasNotification.name,
]);

sitzungenModule.config($stateProvider => {
  $stateProvider

    // Sitzungs-Liste
    .state("root.sitzungsarten", {
      url: `/sitzungsarten/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-sitzungsarten-list></vue-sitzungsarten-list></div>"
        }
      },
      data: {
        permissions: ["sitzungsarten.admin.view"]
      },
    })
    .state("root.sitzungsarten1", {
      url: `/sitzungsarten1/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-sitzungsarten-list></vue-sitzungsarten-list></div>"
        }
      },
      data: {
        permissions: ["sitzungsarten.admin.view"]
      },
    })

    // Detail-Seite einer Sitzung
    .state("root.sitzungsarten.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><sitzungsart-details></sitzungsart-details></div>"
        }
      },
      data: {
        permissions: ["sitzungsarten.admin.view"]
      },
    })
    .state("root.sitzungsarten1.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<ksitzungsarten-details></ksitzungsarten-details>"
        }
      },
      data: {
        permissions: ["sitzungsarten.admin.view"]
      },
    })
  ;
})

  .component("ksitzungsartenDetails", detailsComponent)
;

export default sitzungenModule;
