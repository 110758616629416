"use strict";

import template from "./details.jade";

import bezugsdatumEditTemplate from "./bezugsdatum.edit.jade";

import SaveCallbackActions from "../../../../vue/client/vue/const/SaveCallbackActions";

import {
  MAPPING,
} from "../../../../vue/client/vue/components/Geschaeftsregel/Module/Module";
import {
  cloneDeep,
  findIndex,
  forEach,
  get,
  isArray,
  isString,
} from "lodash-es";

class ProjektberichtDetailsController {
  /*@ngInject*/
  constructor(
    $q,
    $filter,
    $state,
    $stateParams,
    $timeout,
    $anchorScroll,
    $window,
    i18n,
    diasConfirmDialog,
    diasModalDialog,
    AuthService,
    Projektberichte,
    Vertrag,
    DiasNotification,
    SyConfigs,
  ) {
    this.MAPPING = MAPPING;
    this.$q = $q;
    this.$filter = $filter;
    this.$state = $state;
    this.$timeout = $timeout;
    this.$anchorScroll = $anchorScroll;
    this.$window = $window;
    this.gettext = i18n.gettext;
    this.$stateParams = $stateParams;
    this.AuthService = AuthService;
    this.DiasNotification = DiasNotification;
    this.Projektberichte = Projektberichte;
    this.Vertrag = Vertrag;
    this.diasModalDialog = diasModalDialog;
    this.diasConfirmDialog = diasConfirmDialog;
    this.bezugsdatumEditTemplate = bezugsdatumEditTemplate;

    this.astAlsButtonConfig = SyConfigs.wert("ast_als_button") || {};

    this.initNotizenData();
    this.init();

    // Verlauf
    this.viewName = "details";
    this.timelineConfig = {
      kontexte: [
        {
          titel: "Berichterstellung",
          key: "A",
        },
        {
          titel: "Berichtprüfung",
          key: "F",
        },
      ],
      benachrichtigungen: {
        bezug: "projektbericht",
        bezugpk: $stateParams.id,
        autorefresh: true,
      },
      url: `projektberichte/${ $stateParams.id }/timeline/`,
    };

    // Workflow
    this.onAufgabeAngenommen = () => {
      this.$timeout(() => {
        this.loadProjektbericht();
      });
    };


    this.lastWfExecution = new Date().toISOString();

    this.reloadWorkflowfolgen = () => {
      this.lastWfExecution = new Date().toISOString();
    };

    this.updateModuleFromVue = this.updateModuleFromVue.bind(this);
    this.updateAufgabeFromVue = this.updateAufgabeFromVue.bind(this);
    this.onWorkflowInit = this.onWorkflowInit.bind(this);
    this.onWorkflowStarted = this.onWorkflowStarted.bind(this);
    this.onWorkflowFinish = this.onWorkflowFinish.bind(this);
    this.onWorkflowCanceled = this.onWorkflowCanceled.bind(this);
    this.onWorkflowSuccess = this.onWorkflowSuccess.bind(this);
    this.onWorkflowFailure = this.onWorkflowFailure.bind(this);
    this.saveCallback = this.saveCallback.bind(this);
  }

  initNotizenData() {
    this.notizOptions = {
      fuerSitzung: true,
      orgChoices: []
    };
    this.stepMap = [
      "uebersicht",
      "bericht"
    ];
    this.steps = [
      {
        key: "uebersicht",
        kbez: "Übersicht"
      },
      {
        key: "bericht",
        kbez: "dem Bericht"
      }
    ];
  }

  init() {
    this.loading = {
      projektbericht: true,
      vertrag: true,
      module: true,
    };
    this.openBericht();
    this.loadProjektbericht().then(
      () => {
        this.loadVertrag().finally(
          () => {
            this.loading.vertrag = false;
          }
        );
      }
    ).finally(
      () => this.loading.projektbericht = false
    );
    this.loadModule().finally(
      () => this.loading.module = false
    );
  }

  updatePermissions() {
    this.permissions = this.AuthService.syncHasPerms([
      "projektbericht.update",
      "projektbericht.deactivate",
      "projektbericht.delete",
    ], this.pb.user_permissions);
  }

  onValidate() {
    this.validationErrorsLoading = true;
    this.pb.customGET("validieren").then(
      result => {
        if (isArray(result)) {
          const errData = [];
          forEach(result, modulErr => {
            const dat = cloneDeep(modulErr);
            if (!isArray(dat.errors)) {
              dat.errors = [{ non_field_errors: this.gettext("Bitte prüfen Sie Ihre Eingaben.") }];
            }
            errData.push(dat);
          });
          this.validationErrors = errData;
        } else {
          this.validationErrors = result;
        }
      },
      () => this.DiasNotification.page.error("Fehler beim Prüfen des Projektberichts")
    ).finally(() => {
      this.validationErrorsLoading = false;
      this.scrollTop();
    });
  }

  resetValidation() {
    this.validationErrorsLoading = undefined;
    this.validationErrors = undefined;
  }

  scrollTop() {
    $(window).scrollTop(0);
  }

  goToModul(modul) {
    this.openBericht();
    this.$timeout(() => {
      this.$anchorScroll.yOffset = $(window).height() / 2;
      if (modul) {
        this.$anchorScroll(modul.regelnummer);
      } else {
        this.$anchorScroll("bericht-container");
      }
    }, 1000);
  }

  getWorkflowfolgeUpdateKey() {
    if (this.pb) {
      return `${ this.lastWfExecution }`;
    }
  }

  saveCallback({ statusSaveCallback } = {}) {
    if (statusSaveCallback === SaveCallbackActions.RELOAD_WF) {
      this.reloadWorkflowfolgen();
    } else if (statusSaveCallback === SaveCallbackActions.RELOAD_ALL) {
      this.loadProjektbericht();
      this.reloadWorkflowfolgen();
    }
  }

  openBericht() {
    this.berichtOpen = true;
  }

  loadVertrag() {
    return this.Vertrag.one(this.pb.vertrag).get().then(
      response => {
        this.vertrag = response;
      }
    );
  }

  loadProjektbericht() {
    return this.Projektberichte.one(this.$stateParams.id).get().then(
      response => {
        this.pb = response;
        this.setUrls();
        this.updatePermissions();
      },
      error => this.DiasNotification.page.error(error, "Fehler beim Laden des Projektberichts")
    );
  }

  setUrls() {
    this.dokBaseUrl = `vertraege/${ this.pb.vertrag }/`;
    this.sdbExcelUrl = `api/projektberichte/${ this.pb.pk }/wirkungstreppe/excel/`;
    this.pbUrl = `projektberichte/${ this.pb.pk }/`;
  }

  deleteProjektbericht() {
    const v_nr = this.$filter("spaced")(this.pb.vertrag_nr, true);
    this.diasConfirmDialog({
      titleTemplate: `${ this.gettext("Projektbericht") } "${ v_nr } - ${ this.pb.pb_nr } ${ this.pb.pb_kbez }" löschen`,
      contentTemplate: `<p>Sind Sie sicher, dass Sie {{ context.gettext("den Projektbericht") }} "${ v_nr } - ${ this.pb.pb_nr } ${ this.pb.pb_kbez }" löschen wollen?</p>`,
      context: { projektbericht: this.pb, gettext: this.gettext },
      okLabel: `${ this.gettext("Projektbericht") } "${ this.pb.pb_kbez }" löschen`,
      okCallback: () => this.pb.remove().then(
        () => {
          this.DiasNotification.page.success(`${ v_nr } - ${ this.pb.pb_nr } ${ this.pb.pb_kbez } wurde gelöscht.`);
          this.$state.go("root.projektberichte");
        },
        err => this.DiasNotification.page.error(err, `${ this.gettext("Projektbericht") } konnte nicht gelöscht werden.`)
      ),
    });
  }

  savePdf() {
    this.Projektberichte.one(this.$stateParams.id).withHttpConfig({ responseType: "blob" }).customGET("pdf").then(
      response => {
        const fileName = `projektbericht_${ this.pb.pb_nr }_${ this.pb.pb_kbez }_entwurf.pdf`;
        if (this.$window.navigator.msSaveBlob) {
          this.$window.navigator.msSaveBlob(response, fileName);
        } else {
          const fileURL = (this.$window.URL || this.$window.webkitURL).createObjectURL(response);
          const aLink = this.$window.document.createElement("a");
          aLink.download = fileName;
          aLink.href = fileURL;
          aLink.target = "_self";
          const eventClick = this.$window.document.createEvent("MouseEvents");
          eventClick.initEvent(
            "click",
            true,
            true,
            this.$window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
          );
          aLink.dispatchEvent(eventClick);
        }
      },
      err => this.DiasNotification.page.error(err, "Fehler beim erstellen des Dokuments")
    );
  }


  loadModule() {
    return this.Projektberichte.one(this.$stateParams.id).module.getList().then(
      response => {
        this.module = response;
      }
    );
  }

  changeView() {
    this.viewName = this.viewName === "details" ? "timeline" : "details";
  }

  updateModuleFromVue({ response }) {
    this.$timeout(() => {
      const INDEX = findIndex(this.module, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.module.splice(INDEX, 1, response);
      }
    });
  }

  updateAufgabeFromVue(newAufgabe) {
    this.$timeout(() => {
      this.updateAufgabe(newAufgabe);
    });
  }

  updateAufgabe(newAufgabe) {
    Object.assign(this.pb.aktuelle_aufgabe, newAufgabe);
  }

  onWorkflowInit(clientFunktion) {
    this.$timeout(() => {
      this.clientFunktion = clientFunktion;
    });
  }

  onWorkflowStarted(newAufgabe, currentWorkflowfolge) {
    this.$timeout(() => {
      this.updateAufgabe(newAufgabe);
      this.currentWorkflowfolge = currentWorkflowfolge;
      this.validationErrorsLoading = true;
    });
  }

  onWorkflowFinish() {
    this.$timeout(() => {
      this.clientFunktion = undefined;
      this.currentWorkflowfolge = undefined;
      this.validationErrorsLoading = false;
    });
  }

  onWorkflowCanceled() {
    this.$timeout(() => {
      this.reload = false;
      this.validationErrorsLoading = false;
    });
  }

  onWorkflowSuccess(aufgabe) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerSuccess(aufgabe);
      }
      this.resetValidation();
      this.scrollTop();
      if (get(this.currentWorkflowfolge, "wfo_reload")) {
        this.loadModule();
        return this.loadProjektbericht();
      }
    });
  }

  onWorkflowFailure(err) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerError(err);
      }
      let errorData = err.data;
      if (errorData && errorData.error_data) {
        errorData = errorData.error_data;
      }
      if (errorData && !angular.isString(errorData)) {
        const errData = [];
        forEach(errorData, (modulErr, key) => {
          errData[key] = cloneDeep(modulErr);
          forEach(errData[key][1], _err => {
            forEach(_err.errors, (_errors, field) => {
              if (!isArray(_errors) || !isString(_errors[0])) {
                _err.errors[field] = [this.gettext("Bitte prüfen Sie die Eingaben.")];
              }
            });
          });
        });
        this.validationErrors = errData;
        this.validationErrorsLoading = false;
      } else {
        this.resetValidation();
      }
      this.scrollTop();
      return this.loadProjektbericht();
    });
  }
}

export default {
  template: template(),
  controller: ProjektberichtDetailsController,
  controllerAs: "vm"
};
