import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import AnonymisierungModalAbbrechen from "../AnonymisierungModalAbbrechen/AnonymisierungModalAbbrechen.vue";
import AnonymisierungModalBlockierenUndFreigeben from "../AnonymisierungModalBlockierenUndFreigeben/AnonymisierungModalBlockierenUndFreigeben.vue";
import AnonymisierungModalErneutFreigeben from "../AnonymisierungModalErneutFreigeben/AnonymisierungModalErneutFreigeben.vue";
import AnonymisierungModalStarten from "../AnonymisierungModalStarten/AnonymisierungModalStarten.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import SmartTable from "../../../global/components/table/table.vue";

import PermissionMixin from "../../../global/mixins/PermissionMixin";

import { EventBus } from "../../../global/functions/event-bus";
import AnonymisierungsstatusUUIDs from "../../../const/AnonymisierungsstatusUUIDs";
import getTableApi from "../../../const/TableApis";

const TABLE_ID = "anonymisierungsauftraege";

// @vue/component
export default {
  name: "AnonymisierungList",
  components: {
    AngularLink,
    AnonymisierungModalAbbrechen,
    AnonymisierungModalBlockierenUndFreigeben,
    AnonymisierungModalErneutFreigeben,
    AnonymisierungModalStarten,
    PageTabTitle,
    PuxIcon,
    SmartTable,
  },
  mixins: [
    PermissionMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [],
        actions: [],
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      modal: {
        abbrechen: undefined,
        blockierenUndFreigeben: undefined,
        erneutFreigeben: undefined,
        starten: undefined,
      },
      currentAuftrag: undefined,
      currentIndex: undefined,
      statusBlockieren: undefined,
    };
  },
  computed: {
    getObjektRoute() {
      return ({ item }) => {
        if (item.ano_status !== AnonymisierungsstatusUUIDs.PLANNED) {
          return undefined;
        }
        const MAP = {
          // foerderantrag: `/foerderantrag/${ item.ano_objektid }/`,
          // vertrag: `/vertrag/${ item.ano_objektid }/`,
          // nutzer: `/nutzerverwaltung/${ item.ano_objektid }/`,
          foerderantrag: "root.foerderantrag.details",
          vertrag: "root.vertrag.details",
          nutzer: "root.nutzerverwaltung.details",
        };
        return MAP[item.objekt];
      };
    },
  },
  created() {
    this.initRowActions();
  },
  methods: {
    initRowActions() {
      const PERMS = {
        "anonymisierung.update": this.checkPermissionsSync({ perm: "anonymisierung.update" }),
        "anonymisierung.create": this.checkPermissionsSync({ perm: "anonymisierung.create" })
      };
      this.options.rowActions.push(
        {
          label: "_TXT_ANONYMISIERUNG_TABLE_ACTIONS_STARTEN_",
          isHidden: ({ item }) => !(PERMS["anonymisierung.create"] && item.ano_status === AnonymisierungsstatusUUIDs.PLANNED && !item.ano_blockieren),
          callback: this.starten,
        },
        {
          label: "_TXT_ANONYMISIERUNG_TABLE_ACTIONS_BLOCKIEREN_",
          isHidden: ({ item }) => !(PERMS["anonymisierung.update"] && item.ano_status === AnonymisierungsstatusUUIDs.PLANNED && !item.ano_blockieren),
          callback: ({ item, index }) => this.blockierenUndFreigeben({ item, index, status: true }),
        },
        {
          label: "_TXT_ANONYMISIERUNG_TABLE_ACTIONS_FREIGEBEN_",
          isHidden: ({ item }) => !(PERMS["anonymisierung.update"] && item.ano_status === AnonymisierungsstatusUUIDs.PLANNED && item.ano_blockieren),
          callback: ({ item, index }) => this.blockierenUndFreigeben({ item, index, status: false }),
        },
        {
          label: "_TXT_ANONYMISIERUNG_TABLE_ACTIONS_ERNEUT_FREIGEBEN_",
          isHidden: ({ item }) => !(PERMS["anonymisierung.create"] && item.ano_status === AnonymisierungsstatusUUIDs.FAILURE),
          callback: this.erneutFreigeben,
        },
        {
          label: "_TXT_ANONYMISIERUNG_TABLE_ACTIONS_ABBRECHEN_",
          isHidden: ({ item }) => !(PERMS["anonymisierung.update"] && item.ano_status === AnonymisierungsstatusUUIDs.PLANNED),
          callback: this.abbrechen,
        }
      );
    },

    starten({ item, index }) {
      this.currentAuftrag = item;
      this.currentIndex = index;
      this.modal.starten = true;
    },

    closeModalStarten({ newRow } = {}) {
      if (newRow) {
        EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: newRow, index: this.currentIndex }));
      }
      this.modal.starten = false;
    },

    blockierenUndFreigeben({ item, index, status }) {
      this.statusBlockieren = status;
      this.currentAuftrag = item;
      this.currentIndex = index;
      this.modal.blockierenUndFreigeben = true;
    },

    closeModalBlockierenUndFreigeben({ newRow } = {}) {
      if (newRow) {
        EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: newRow, index: this.currentIndex }));
      }
      this.modal.blockierenUndFreigeben = false;
    },

    erneutFreigeben({ item, index }) {
      this.currentAuftrag = item;
      this.currentIndex = index;
      this.modal.erneutFreigeben = true;
    },

    closeModalErneutFreigeben({ newRow } = {}) {
      if (newRow) {
        EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: newRow, index: this.currentIndex }));
      }
      this.modal.erneutFreigeben = false;
    },

    abbrechen({ item, index }) {
      this.currentAuftrag = item;
      this.currentIndex = index;
      this.modal.abbrechen = true;
    },

    closeModalAbbrechen({ newRow } = {}) {
      if (newRow) {
        EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: newRow, index: this.currentIndex }));
      }
      this.modal.abbrechen = false;
    },

  },
};
