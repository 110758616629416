"use strict";

import DefaultWizardController from "dias/core/wizard/defaultWizard.controller.js";


class WizardController extends DefaultWizardController {
  /*@ngInject*/
  constructor() {
    super();
  }
}

export default WizardController;
