import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import {
  isNil,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxCloak,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        o_id: undefined,
        fnu_id: undefined,
      },
      status: {
        initializing: undefined,
        loading: undefined,
      },
      orgsData: undefined,
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    translationTitle() {
      return "_HTML_PERSONAL_LIST_MODAL_CREATE_HEADER_";
    },
    translationBody() {
      return "_HTML_PERSONAL_LIST_MODAL_CREATE_BODY_";
    },
    createBtnDisabled() {
      return this.status.loading || this.status.initializing || isNil(this.model.fnu_id);
    },
    modalOptions() {
      const OPTIONS = {
        showCloseButton: true,
        required: true,
        list: []
      };
      if (!this.status.initializing) {
        if (this.orgsData) {
          OPTIONS.list.push({
            id: "o_id",
            type: "select",
            label: "_LBL_PERSONAL_MODAL_CREATE_ORGANISATION_",
            required: true,
            view: "v",
            keyId: "pk",
            keyLabel: "o_name",
            menuWidthAll: true,
            data: this.orgsData,
            change: () => this.model.fnu_id = undefined
          });
        }
        OPTIONS.list.push({
          id: "fnu_id",
          type: "select",
          label: "_LBL_PERSONAL_MODAL_CREATE_FOERDERORGNUTZER_",
          required: true,
          view: "v",
          keyId: "pk",
          keyLabel: "name",
          menuWidthAll: true,
          disabled: isNil(this.model.o_id),
          search: true,
          searchList: ["name"],
          url: !isNil(this.model.o_id) ? "katalog/" : undefined,
          urlParams: {
            key: "foerderorgnutzer",
            foerderorg: this.model.o_id
          }
        });
      }
      return OPTIONS;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.status.initializing = true;
      this.getHttp({
        url: "personal/list_create_organisations/",
        apiSaveId: "personal_list_create_organisations",
      }).then(
        response => {
          if (size(response) > 1) {
            this.orgsData = response;
          } else {
            this.model.o_id = response[0].pk;
          }
        },
        error => this.onError({ error })
      ).finally(
        () => this.status.initializing = false
      );
    },
    save() {
      this.status.loading = true;
      const data = {
        orgnutzer: this.model.fnu_id
      };
      this.postHttp({
        url: "personal/",
        data
      }).then(
        response => {
          this.addNotification({ text: "_MSG_PERSONAL_MODAL_CREATE_SUCCESS_" });
          this.close();
          this.$goTo("root.personal.details", { id: response.pk });
        },
        error => this.onError({ error: error.data })
      ).finally(
        () => this.status.loading = false
      );
    },
    onError({ error }) {
      this.errors = error;
      this.errorsClone = error;
    }
  },
};
