"use strict";

import "restangular";
import angular from "angular";

import Deckblatt from "./deckblatt.service.js";
import BtypUUIDs from "./btypUUIDs.service";

const modelsModule = angular.module("deckblatt.models", [
  "restangular",
])

  .constant("BtypUUIDs", BtypUUIDs)
  .provider("Deckblatt", Deckblatt);

export default modelsModule;
