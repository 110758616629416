// @vue/component
export default {
  data() {
    return {
      wizardModules: {
        personalkosten_mit_pkt: "PersonalkostenMitPktWizard",
        personalkosten_perioden: "PersonalkostenPeriodenWizard",
        dhw_personalkosten_perioden: "PersonalkostenPeriodenWizard",
      },
    };
  },
};
