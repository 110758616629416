"use strict";

import basisformular from "./basisformular";
import umfrageformular from "./umfrageformular";

const cortexModule = angular.module("root.cortex", [
  basisformular.name,
  umfrageformular.name,
]);

export default cortexModule;
