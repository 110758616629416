import AntragstellerWizardAccess from "./AntragstellerWizardAccess/AntragstellerWizardAccess.vue";
import AntragstellerWizardBankverbindung from "./AntragstellerWizardBankverbindung/AntragstellerWizardBankverbindung.vue";
import AntragstellerWizardBeschreibung from "./AntragstellerWizardBeschreibung/AntragstellerWizardBeschreibung.vue";
import AntragstellerWizardDocuments from "./AntragstellerWizardDocuments/AntragstellerWizardDocuments.vue";
import AntragstellerWizardFinish from "./AntragstellerWizardFinish/AntragstellerWizardFinish.vue";
import AntragstellerWizardRegeln from "./AntragstellerWizardRegeln/AntragstellerWizardRegeln.vue";
import AntragstellerWizardRegisternummer from "./AntragstellerWizardRegisternummer/AntragstellerWizardRegisternummer.vue";
import AntragstellerWizardSaveOrganisation from "./AntragstellerWizardSaveOrganisation/AntragstellerWizardSaveOrganisation.vue";
import AntragstellerWizardSearch from "./AntragstellerWizardSearch/AntragstellerWizardSearch.vue";
import AntragstellerWizardVertretungsberechtigte from "./AntragstellerWizardVertretungsberechtigte/AntragstellerWizardVertretungsberechtigte.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Wizard from "../../../global/components/Wizard/Wizard.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import PossibleStepsObj, { allSteps } from "./AntragstellerWizardPossibleSteps";
import {
  cloneDeep,
  forEach,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragstellerWizard",
  components: {
    AntragstellerWizardAccess,
    AntragstellerWizardBankverbindung,
    AntragstellerWizardBeschreibung,
    AntragstellerWizardDocuments,
    AntragstellerWizardFinish,
    AntragstellerWizardRegeln,
    AntragstellerWizardRegisternummer,
    AntragstellerWizardSaveOrganisation,
    AntragstellerWizardSearch,
    AntragstellerWizardVertretungsberechtigte,
    PuxTranslate,
    Wizard,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    SyConfigMixin,
    PermissionMixin,
  ],
  props: {
    headerName: {
      type: String,
      default: undefined,
    },
    closeWizard: {
      type: Function,
      required: true,
    },
    hideSteps: {
      type: Array,
      default: () => [],
    },
    closeButtonHideInSteps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      wizardConfig: undefined,
      user: {},
      wizardSteps: [],
      statusLoading: true,
      statusNewOrganisation: undefined,
      statusSaveAntragstellerorgtypen: undefined,
      statusSkipBeschreibung: undefined,
      stepActive: 0,
      stepsVisited: {
        0: true,
      },
      booleanPropsWizard: {
        backTargetStepDisabled: undefined,
        backStepHide: undefined,
        backStepDisabled: undefined,
        forwardStepHide: undefined,
        forwardStepDisabled: undefined,
        buttonsLoading: undefined,
        closeButtonShow: true,
      },
      errors: undefined,
      organisation: undefined,
      createdOrganisation: undefined,
      modelCreateOrganisation: {},
      modelBankverbindung: {},
      modelBeschreibung: {},
      modelRegisternumer: {},
      modelAntragstellerorgtypen: undefined,
    };
  },
  created() {
    this.initWizardConfig();
    this.initWizardSteps();
    this.checkIsCloseButtonForCurrentStep();
    this.checkIsFirstStepDisabled();
    this.getUser();
  },
  methods: {
    initWizardConfig() {
      this.wizardConfig = this.getSyConfigsValue("wizard_ast") || {};
    },

    initWizardSteps() {
      forEach(allSteps, step => {
        const POSSIBLE_STEP = PossibleStepsObj[step];
        if (POSSIBLE_STEP && this.wizardConfig.steps.indexOf(step) !== -1 && this.hideSteps.indexOf(step) === -1) {
          this.wizardSteps.push(this.changeCurrentStepLabelAndTitle({ currentStep: POSSIBLE_STEP }));
        }
      });
    },

    changeCurrentStepLabelAndTitle({ currentStep }) {
      const CURRENT_STEP_CONFIG_LABEL = this.wizardConfig[`${ currentStep.slotId }_label`];
      if (CURRENT_STEP_CONFIG_LABEL) {
        return {
          label: CURRENT_STEP_CONFIG_LABEL,
          slotId: currentStep.slotId,
          title: CURRENT_STEP_CONFIG_LABEL,
        };
      }
      return currentStep;
    },

    checkIsCloseButtonForCurrentStep() {
      if (!this.closeButtonHideInSteps.length) {
        return;
      }
      this.booleanPropsWizard.closeButtonShow = this.closeButtonHideInSteps.indexOf(this.wizardSteps[this.stepActive].slotId) === -1;
    },

    checkIsFirstStepDisabled() {
      if (this.wizardSteps[0].slotId === "suche") {
        this.booleanPropsWizard.forwardStepDisabled = true;
      }
    },

    getUser() {
      this.user = cloneDeep(this.me);
      this.statusLoading = false;
    },

    selectOrganisation({ organisation, forwardStepDisabled, statusNewOrganisation } = {}) {
      this.organisation = organisation;
      this.statusNewOrganisation = statusNewOrganisation;
      this.booleanPropsWizard.forwardStepDisabled = forwardStepDisabled;
    },

    onCloseWizard() {
      this.closeWizard({ organisation: this.createdOrganisation });
    },

    goStepBack({ stepIndex }) {
      this.stepActive - stepIndex === 1 ? this.goOneStepBack() : this.goTargetStepBack({ stepIndex });
    },

    goOneStepBack() {
      const CURRENT_STEP_NAME = this.wizardSteps[this.stepActive].slotId;
      if (this[`goOneStepBack_${ CURRENT_STEP_NAME }`]) {
        this[`goOneStepBack_${ CURRENT_STEP_NAME }`]();
      } else {
        this.stepActive -= 1;
      }
      this.setButtonBackDisabledOrNot();
      this.checkIsCloseButtonForCurrentStep();
    },

    setButtonBackDisabledOrNot() {
      if (this.stepActive < 1) {
        return;
      }
      const LAST_STEP_NAME = this.wizardSteps[this.stepActive - 1].slotId;
      if (LAST_STEP_NAME === "speichern") {
        this.booleanPropsWizard.backStepDisabled = true;
        this.booleanPropsWizard.backTargetStepDisabled = true;
      } else if (LAST_STEP_NAME === "beschreibung" && this.statusSkipBeschreibung) {
        this.booleanPropsWizard.backStepDisabled = true;
        this.booleanPropsWizard.backTargetStepDisabled = true;
      }
    },

    goOneStepBack_suche() {
      this.stepActive -= 1;
      this.booleanPropsWizard.forwardStepDisabled = false;
    },

    goOneStepBack_speichern() {
      this.stepActive -= 1;
      this.booleanPropsWizard.forwardStepDisabled = false;
    },

    goTargetStepBack({ stepIndex }) {
      this.stepActive = stepIndex;
    },

    goOneStepForward() {
      const CURRENT_STEP_NAME = this.wizardSteps[this.stepActive].slotId;
      this[`goOneStepForward_${ CURRENT_STEP_NAME }`]();
    },

    goOneStepForward_zugang() {
      if (isUndefined(this.statusNewOrganisation)) {
        this.booleanPropsWizard.forwardStepDisabled = true;
      }
      this.goNextStep();
    },

    goOneStepForward_suche() {
      this.goNextStep();
    },

    goOneStepForward_speichern() {
      if (this.statusNewOrganisation) {
        this.createOrganisation();
      } else {
        this.assignOrganisation();
      }
    },

    goOneStepForward_beschreibung() {
      this.saveOrganisationBeschreibung();
    },

    goOneStepForward_registernummer() {
      this.saveOrganisationRegisternummer();
    },

    goOneStepForward_vertretungsberechtigte() {
      this.setBackStepNotDisabled();
      this.goNextStep();
    },

    goOneStepForward_bankverbindung() {
      this.createBankverbindungen();
    },

    goOneStepForward_documents() {
      this.setBackStepNotDisabled();
      this.goNextStep();
    },

    goOneStepForward_regeln() {
      this.goNextStep();
    },

    goNextStep() {
      this.stepActive += 1;
      this.stepsVisited[this.stepActive] = true;
      this.checkIsCloseButtonForCurrentStep();
    },

    changeModel({ model }) {
      this.modelCreateOrganisation = cloneDeep(model);
    },

    createOrganisation() {
      this.booleanPropsWizard.buttonsLoading = true;
      const DATA = this.modelCreateOrganisation;
      this.postHttp({
        url: "antragstellerorganisationen/",
        data: DATA,
        showError: true,
      }).then(
        response => {
          this.addNotification({ text: "_MSG_ANTRAGSTELLER_WIZARD_ORGANISATION_CREATE_SUCCESS_" });
          // Kontexte haben sich geändert.
          this.$AuthService.refreshUser().then(user => this.user = user);
          this.organisation = response;
          this.createdOrganisation = response;
          this.saveAntragstellerorgtypen();

          this.errors = undefined;
          this.booleanPropsWizard.backStepDisabled = true;
          this.booleanPropsWizard.backTargetStepDisabled = true;
          this.goNextStep();
          if (DATA.skip_create_user) {
            this.statusSkipBeschreibung = true;
            this.goNextStep();
          }
        },
        error => {
          if (error.status !== 429) {
            this.errors = error.data;
          }
        }
      ).then(
        () => this.booleanPropsWizard.buttonsLoading = false
      );
    },

    saveAntragstellerorgtypen() {
      if (this.statusSaveAntragstellerorgtypen) {
        this.putHttp({
          url: `antragstellerorganisationen/${ this.organisation.pk }/antragstellerorgtypen_aendern/`,
          data: this.modelAntragstellerorgtypen,
        }).then(
          () => {

          }
        );
      }
    },

    assignOrganisation() {
      this.booleanPropsWizard.buttonsLoading = true;
      this.postHttp({
        url: `profil/${ this.user.pk }/antragstellerorganisationen/`,
        data: { antragstellerorganisation: this.organisation.pk },
        showError: true,
      }).then(
        () => {
          this.createdOrganisation = cloneDeep(this.organisation);
          this.addNotification({
            text: "_MSG_ANTRAGSTELLER_WIZARD_ORGANISATION_ASSIGN_SUCCESS_{{name}}_",
            extra: { name: this.organisation.ast_name }
          });
          this.booleanPropsWizard.backStepDisabled = true;
          this.booleanPropsWizard.backTargetStepDisabled = true;
          this.goToLastStep();
        },
        error => {
          if (error.status !== 429) {
            this.addNotification({ text: error.data.antragstellerorganisation[0], type: "error" });
          }
        }
      ).then(
        () => this.booleanPropsWizard.buttonsLoading = false
      );
    },

    changeModelBeschreibung({ model }) {
      this.modelBeschreibung = model;
    },

    saveOrganisationBeschreibung() {
      this.booleanPropsWizard.buttonsLoading = true;
      this.putHttp({ url: `antragstellerorganisationen/${ this.organisation.pk }/beschreibung_aendern/`, data: this.modelBeschreibung }).then(
        () => {
          this.addNotification({ text: "_MSG_ANTRAGSTELLER_WIZARD_BESCHREIBUNG_SAVE_SUCCESS_" });
          this.errors = undefined;
          this.setBackStepNotDisabled();
          this.goNextStep();
        },
        error => this.errors = error.data
      ).then(
        () => this.booleanPropsWizard.buttonsLoading = false
      );
    },

    changeModelRegisternummer({ model }) {
      this.modelRegisternummer = model;
    },

    saveOrganisationRegisternummer() {
      this.booleanPropsWizard.buttonsLoading = true;
      this.putHttp({ url: `antragstellerorganisationen/${ this.organisation.pk }/registernummer_aendern/`, data: this.modelRegisternummer }).then(
        () => {
          this.addNotification({ text: "_MSG_ANTRAGSTELLER_WIZARD_REGISTERNUMMER_SAVE_SUCCESS_" });
          this.errors = undefined;
          this.setBackStepNotDisabled();
          this.goNextStep();
        },
        error => this.errors = error.data
      ).then(
        () => this.booleanPropsWizard.buttonsLoading = false
      );
    },

    changeModelBankverbindung({ model }) {
      this.modelBankverbindung = model;
    },

    createBankverbindungen() {
      this.booleanPropsWizard.buttonsLoading = true;
      const Data = cloneDeep(this.modelBankverbindung);
      Data.antragstellerorg = this.organisation.pk;
      this.postHttp({ url: `antragstellerorganisationen/${ this.organisation.pk }/bankverbindungen/`, data: Data }).then(
        () => {
          this.addNotification({ text: "_MSG_ANTRAGSTELLER_WIZARD_BANKVERBINDUNG_CREATE_SUCCESS_" });
          this.setBackStepNotDisabled();
          this.goNextStep();
          this.errors = undefined;
        },
        error => this.errors = error.data
      ).then(
        () => this.booleanPropsWizard.buttonsLoading = false
      );
    },

    goToLastStep() {
      this.booleanPropsWizard.backStepDisabled = true;
      this.booleanPropsWizard.backStepHide = true;
      this.booleanPropsWizard.forwardStepHide = true;
      this.stepActive = this.wizardSteps.length - 1;
      this.stepsVisited[this.stepActive] = true;
    },

    setBackStepNotDisabled() {
      this.booleanPropsWizard.backStepDisabled = false;
      this.booleanPropsWizard.backTargetStepDisabled = false;
    },

    changeModelAntragstellerorgtypen({ model, status }) {
      if (status) {
        this.statusSaveAntragstellerorgtypen = true;
      }
      if (model) {
        this.modelAntragstellerorgtypen = model;
      }
    },
  },
};
