"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";
import ngAnimate from "angular-animate";

import dokumentationComponent from "./dokumentation.component";

const dokumentationModule = angular.module("dokumentation", [
  ngAnimate,
  uiRouter,
  "angularMoment",
]);

dokumentationModule.config($stateProvider => {
  $stateProvider

    // Liste
    .state("root.dokumentation", {
      url: "/dokumentation/",
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<dias-dokumentation></dias-dokumentation>"
        }
      },
      data: {
        permissions: ["dokumentation.view"]
      }
    });
})

  .component("diasDokumentation", dokumentationComponent)

;
export default dokumentationModule;
