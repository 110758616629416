"use strict";

class StandardFoerdermoduleBaseController {
  /*@ngInject*/
  constructor() {
    this.name = "StandardFoerdermoduleBaseController";
    this.parameter = {};
    this.userInput = {};
    this.requiredFields = [];
    this.processedSnapshot = undefined;
    this.convertedSnapshotData = undefined;
    this.processedSnapshotDiff = undefined;
    this.convertedSnapshotDiffData = undefined;
    this.simplifyDiff = true;
    this.prepareParameter();
    this.prepareUserInput();
  }

  getFormName() {
    if (!this.modul) {
      return;
    }
    return `form_${ this.modul.regel.pk.replace(/-/g, "") }`;
  }

  setRequiredFields() {
    this.requiredFields.splice(0, this.requiredFields.length);
    this.requiredFields.push(...arguments);
  }

  isFieldRequired(fieldName) {
    return this.requiredFields.indexOf(fieldName) !== -1;
  }

  isMissing(fieldName) {
    return this.isFieldRequired(fieldName) && (this.userInput[fieldName] === null || this.userInput[fieldName] === undefined || this.userInput[fieldName].length === 0);
  }

  isMissingInput() {
    let anyMissing = false;
    for (const key in this.userInput) {
      anyMissing = anyMissing || this.isMissing(key);
    }
    return anyMissing;
  }

  prepareParameter() {
    angular.merge(this.parameter, this.modul.getParameter());
  }

  prepareUserInput() {
    angular.extend(this.userInput, this.modul.data);
    this.setRequiredFields(...Object.keys(this.userInput));
  }

  checkSnapshot() {
    if (this.snapshot === this.processedSnapshot) {
      return;
    }
    this.convertSnapshot(this.getSnapshotData(this.snapshot));
    this.processedSnapshot = this.snapshot;
  }

  checkSnapshotDiff() {
    if (this.snapshotdiff === this.processedSnapshotDiff) {
      return;
    }
    this.convertSnapshotDiff(this.getSnapshotDiffData(this.snapshotdiff));
    this.processedSnapshotDiff = this.snapshotdiff;
  }

  getSnapshotData(snapshot) {
    if (snapshot) {
      snapshot = (snapshot.asn_snapshot !== undefined ? snapshot.asn_snapshot : snapshot.ats_snapshot);
      if (snapshot &&
         snapshot[this.modul.modulname] &&
         snapshot[this.modul.modulname][this.modul.regel.pk]) {
        return snapshot[this.modul.modulname][this.modul.regel.pk];
      }
    }
  }

  getSnapshotDiffData(snapshotdiff) {
    if (this.simplifyDiff) {
      return this.getSimpleSnapshotDiffData(snapshotdiff);
    }
    return this.getDetailedSnapshotDiffData(snapshotdiff);
  }

  getSimpleSnapshotDiffData(snapshotdiff) {
    if (snapshotdiff &&
       snapshotdiff.diff_result.modified[this.modul.modulname] &&
       snapshotdiff.diff_result.modified[this.modul.modulname][this.modul.regel.pk]) {
      return snapshotdiff.diff_result.modified[this.modul.modulname][this.modul.regel.pk];
    }
  }

  getDetailedSnapshotDiffData(snapshotdiff) {
    if (!snapshotdiff || !snapshotdiff.diff_result) {
      return;
    }

    const diff = {};
    if (snapshotdiff.diff_result.modified &&
       snapshotdiff.diff_result.modified[this.modul.modulname] &&
       snapshotdiff.diff_result.modified[this.modul.modulname][this.modul.regel.pk]) {
      diff.modified = snapshotdiff.diff_result.modified[this.modul.modulname][this.modul.regel.pk];
    }
    if (snapshotdiff.diff_result.added &&
       snapshotdiff.diff_result.added[this.modul.modulname] &&
       snapshotdiff.diff_result.added[this.modul.modulname][this.modul.regel.pk]) {
      diff.added = snapshotdiff.diff_result.added[this.modul.modulname][this.modul.regel.pk];
    }
    if (snapshotdiff.diff_result.removed &&
       snapshotdiff.diff_result.removed[this.modul.modulname] &&
       snapshotdiff.diff_result.removed[this.modul.modulname][this.modul.regel.pk]) {
      diff.removed = snapshotdiff.diff_result.removed[this.modul.modulname][this.modul.regel.pk];
    }
    if (diff.modified || diff.added || diff.removed) {
      return diff;
    }
  }

  convertSnapshot(snapshotData) {
    this.convertedSnapshotData = snapshotData;
  }

  convertSnapshotDiff(snapshotDiffData) {
    this.convertedSnapshotDiffData = snapshotDiffData;
  }


  showSnapshot() {
    this.checkSnapshot();
    return this.convertedSnapshotData !== undefined;
  }

  getSnapshot() {
    if (this.showSnapshot()) {
      return this.convertedSnapshotData;
    }
  }

  showDiff() {
    this.checkSnapshotDiff();
    return this.convertedSnapshotDiffData !== undefined;
  }

  getDiff() {
    if (this.showDiff()) {
      return this.convertedSnapshotDiffData;
    }
  }
}

export default StandardFoerdermoduleBaseController;
