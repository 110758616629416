import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FiltersAPI from "../../../../../../global/compositionAPI/FiltersAPI";

import {
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragsuebersichtTable",
  components: {
    PuxTranslate,
  },
  props: {
    instrumentvarianten: {
      type: Object,
      default: () => ({}),
    },
    filteredAntragListUrl: {
      type: Object,
      default: () => ({}),
    },
    gesamt: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      filterCurrency,
    };
  },
  computed: {
    getCountInstrumentvariante() {
      return size(this.instrumentvarianten);
    },
  },
};
