"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import diasLoading from "dias/core/loading";

import diasAuthService from "dias/auth/services";

import models from "../models";
import parameter from "../parameter";

import listNewComponent from "./listNew.component";

const modul = angular.module("dias.vertrag.belege", [
  uiRouter,
  diasLoading.name,
  models.name,
  parameter.name,
  diasAuthService.name,
])

  .config(/*@ngInject*/$stateProvider => {
    $stateProvider

    // Liste
      .state("root.vertragsbeleg", {
        url: "/vertragsbeleg/?vertrag&status&vpb_nummer" +
        "&kostenart" +
        "&finanzierungsart" +
        "&kofipos_pos" +
        "&vpb_bez&vpb_kommentar&blockFirstLoad&typ",
        reloadOnSearch: false,
        views: {
          "main@root": {
          // template: "<div vueinit><vue-belege-list></vue-belege-list></div>"
            template: "<vertragsbelege-list-new></vertragsbelege-list-new>"
          }
        },
        data: {
          instance_pages: "vertragsbeleg",
          permissions: ["vertragskostenpositionsbelege.view"]
        }
      })
    ;
  })

  .component("vertragsbelegeListNew", listNewComponent)
;

export default modul;
