"use strict";

class ElementverzweigungReadOnlyController {
  /*@ngInject*/
  constructor(Katalog) {
    this.Katalog = Katalog;
    this.regelChoices = [];
    this.regelnByPK = {};
    this.regelChoices = [];
    this.loadRegeln();
    this.loading = true;
  }

  loadRegeln() {
    this.Katalog.getKatalog(
      "elementfoerderregel",
      { fields: ["pk", "re_nummer", "re_kbez", "bez"] }
    ).then(
      result => {
        angular.forEach(result, regel => {
          this.regelnByPK[regel.pk] = regel;
          this.regelChoices.push(
            { value: regel.pk, label: this.getRegelbez(regel.pk) }
          );
        });
        this.loading = false;
      }
    );
  }

  getRegelbez(pk) {
    const regel = this.regelnByPK[pk];
    if (regel) {
      return "[" + regel.re_nummer + "] " + regel.bez;
    }
  }
}

export default ElementverzweigungReadOnlyController;
