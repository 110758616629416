import {
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import {
  useStore,
} from "vuex";

import FormElement from "../../global/components/FormElement/FormElement.vue";
import Modal from "../../global/components/Modal/Modal.vue";
import PuxButton from "../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";

import NotificationAPI from "../../global/compositionAPI/NotificationAPI";

import {
  EventBus,
} from "../../global/functions/event-bus";

const MILLISECONDS_IN_SECOND = 1000;

// @vue/component
export default {
  name: "TheAccessibilityButton",
  components: {
    FormElement,
    Modal,
    PuxButton,
    PuxTranslate,
  },
  setup() {
    const APP = getCurrentInstance();
    const $cookies = APP.appContext.config.globalProperties.$cookies;
    const store = useStore();

    const {
      addNotification,
    } = NotificationAPI();

    const model = ref({
      contrast_button: false,
      pop_up_message_timeout: 5,
    });
    const cookieNames = {
      contrast: "accessibility_contrast",
      popUpMessageTimeout: "accessibility_pop_up_message_timeout",
    };
    const setModelFromCookie = () => {
      model.value = {
        contrast_button: $cookies.get(cookieNames.contrast) === "true",
        pop_up_message_timeout: +($cookies.get(cookieNames.popUpMessageTimeout) || 5),
      };
    };
    const setCookieContrastButtonActive = () => {
      $cookies.set(cookieNames.contrast, true);
    };
    const setCookieContrastButtonInactive = () => {
      $cookies.set(cookieNames.contrast, false);
    };
    const serCookieForPopUpMessageTimeout = () => {
      $cookies.set(cookieNames.popUpMessageTimeout, model.value.pop_up_message_timeout);
    };

    const classForBody = "contrast_accessibility";
    const addContrast = () => {
      document.body.classList.add(classForBody);
    };
    const removeContrast = () => {
      document.body.classList.remove(classForBody);
    };
    const initContrast = () => {
      if (model.value.contrast_button) {
        addContrast();
      }
    };
    const setContrast = () => {
      if (model.value.contrast_button) {
        addContrast();
        setCookieContrastButtonActive();
      } else {
        removeContrast();
        setCookieContrastButtonInactive();
      }
    };

    const isModalOpen = ref(false);
    const openModal = () => {
      setModelFromCookie();
      isModalOpen.value = true;
    };
    const closeModal = () => {
      isModalOpen.value = false;
    };

    const setPopUpMessageTimeout = () => {
      store.commit("notification/SET_NOTIFICATION_TIMEOUT_MUT", model.value.pop_up_message_timeout * MILLISECONDS_IN_SECOND);
      EventBus.$emit("updatePopUpMessageTimeoutInAngular", model.value.pop_up_message_timeout);
    };

    const save = () => {
      setContrast();
      setPopUpMessageTimeout();
      serCookieForPopUpMessageTimeout();

      closeModal();
      addNotification({
        text: "_MSG_ACCESSIBILITY_MODAL_SAVE_SUCCESS_",
      });
    };

    const idForButtonAccessibilityModalOpen = ref("btn_accessibility_modal");
    const selectorClose = computed(() => {
      return `#${ idForButtonAccessibilityModalOpen.value }`;
    });
    const optionsForContrast = {
      id: "contrast_button",
      type: "boolean",
      view: "v-alt",
      label: "_LBL_ACCESSIBILITY_CONTRAST_",
      trueLabel: "_LBL_ACCESSIBILITY_CONTRAST_TRUE_",
      falseLabel: "_LBL_ACCESSIBILITY_CONTRAST_FALSE_",
      helpText: "_TXT_ACCESSIBILITY_CONTRAST_HELP_TEXT_",
    };
    const optionsForPopUpMessageTimeout = {
      id: "pop_up_message_timeout",
      type: "integer",
      view: "v-alt",
      label: "_LBL_ACCESSIBILITY_POP_UP_MESSAGE_TIMOUT_",
      helpText: "_TXT_ACCESSIBILITY_POP_UP_MESSAGE_TIMOUT_HELP_TEXT_",
      addonBack: "_LBL_SECONDS_",
      nullValue: 0,
    };

    setModelFromCookie();
    initContrast();
    setPopUpMessageTimeout();

    return {
      closeModal,
      idForButtonAccessibilityModalOpen,
      isModalOpen,
      model,
      openModal,
      optionsForContrast,
      optionsForPopUpMessageTimeout,
      save,
      selectorClose,
    };
  },
};
