import FinanzierugMixin from "../Mixins/FinanzierugMixin";
import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import PuxGet from "../../../../global/components/PuxGet/PuxGet.vue";
import ZuschussStandard from "../../KFDetail/ZuschussStandard/ZuschussStandard.vue";
import {
  cloneDeep,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "BearbeitbarePauschale",
  components: {
    KFStandard,
    PuxGet,
    ZuschussStandard,
  },
  mixins: [
    FinanzierugMixin,
  ],
  data() {
    return {
      allowsInput: true,
    };
  },
  computed: {
    statusNotLoad() {
      return this.kontext !== "zuschuss" && this.kontext !== "zuschuss_vertrag";
    },

    sumLocal() {
      return this.antragBetragCurrency;
    },

    meldungen() {
      return get(this.infoprovider, `details.${ this.regel.afr_id }.meldungen`, []);
    },

    statusHideButtonAdd() {
      return this.positionen.length > 0;
    },

    labelsPosition() {
      return {
        buttonTogglePositionTextHide: "_BTN_BEARBEITBARE_PAUSCHALE_POSITION_TOGGLE_SHOW_{{kofiart}}_",
        buttonTogglePositionTextShow: "_BTN_BEARBEITBARE_PAUSCHALE_POSITION_TOGGLE_HIDE_{{kofiart}}_",
        buttonAddText: "_BTN_BEARBEITBARE_PAUSCHALE_ADD_POSITION_",
        buttonAddIcon: "edit",
        labelAdd: "_TXT_BEARBEITBARE_PAUSCHALE_POSITION_ADD_HEADER_{{kofiart}}_",
        labelEdit: "_TXT_BEARBEITBARE_PAUSCHALE_POSITION_EDIT_HEADER_",
        buttonEditTooltip: "_BTN_BEARBEITBARE_PAUSCHALE_KF_POSITION_EDIT_TITLE_",
        buttonDeleteTooltip: "_BTN_BEARBEITBARE_PAUSCHALE_KF_POSITION_DELETE_TITLE_",
        confirmDeleteHeader: "_TXT_BEARBEITBARE_PAUSCHALE_KF_DELETE_CONFIRM_HEADER_{{position}}_{{kofiart}}_",
      };
    },

    labelsPositionZuschussVertrag() {
      const LABELS = cloneDeep(this.labelsPosition);
      LABELS.confirmDeleteHeader = "_TXT_BEARBEITBARE_PAUSCHALE_KF_DELETE_CONFIRM_HEADER_";
      return LABELS;
    },

    finKbez() {
      return get(this.vertragFinanzierungsart, "finanzierungsart.fin_kbez", "");
    },

    vfBetragBewilligtCurrency() {
      return this.filterCurrency(this.vertragFinanzierungsart.vf_betrag_bewilligt || 0);
    },
  },
};
