"use strict";

import BetragsrechnerRegistry from "../services";

import BetragsrechnerKostenOhneBezeichnung from "./betragsrechner.js";
import BetragsrechnerKostenOhneBezeichnungController from "./controller";

const BetragsrechnerKostenOhneBezeichnungModul = angular.module("dias.betragsrechner.BetragsrechnerKostenOhneBezeichnungModul", [
  BetragsrechnerRegistry.name,
]);

BetragsrechnerKostenOhneBezeichnungModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("kosten_ohne_bezeichnung", BetragsrechnerKostenOhneBezeichnung);
})
  .component("kostenohnebezeichnung", BetragsrechnerKostenOhneBezeichnungController);

export default BetragsrechnerKostenOhneBezeichnungModul;
