"use strict";

import KostenController from "../../kosten.controller";


class HonorarKostenController extends KostenController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KKostenart, BetragsrechnerRegistry, i18n) {
    super($scope, $q, Foerdermodule, Foerderantrag, KKostenart, BetragsrechnerRegistry, i18n);
  }

  getDefaultBetragsrechner() {
    return "honorarkostenrechner";
  }
}

export default HonorarKostenController;
