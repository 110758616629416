import {
  computed,
  toRef,
} from "vue";

import AngularLink from "../../global/components/AngularLink/AngularLink.vue";
import PuxIcon from "../../global/components/PuxIcon/PuxIcon.vue";
import TheAccessibilityButton from "../TheAccessibilityButton/TheAccessibilityButton.vue";
import TheGlobal from "../TheGlobal/TheGlobal.vue";
import TheModalTranslate from "../TheModalTranslate/TheModalTranslate.vue";
import TheMenuMobileButton from "../TheMenuMobileButton/TheMenuMobileButton.vue";
import TheMenuUser from "../TheMenuUser/TheMenuUser.vue";
import TheNavbarSearch from "../TheNavbarSearch/TheNavbarSearch.vue";
import TheSearchGlobal from "../TheSearchGlobal/TheSearchGlobal.vue";

import translate from "../../global/directives/translate";

import PermissionAPI from "../../global/compositionAPI/PermissionAPI";
import SyConfigAPI from "../../global/compositionAPI/SyConfigAPI";

import {
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "TheNavbar",
  components: {
    AngularLink,
    PuxIcon,
    TheAccessibilityButton,
    TheModalTranslate,
    TheMenuUser,
    TheMenuMobileButton,
    TheNavbarSearch,
    TheSearchGlobal,
    TheGlobal,
  },
  directives: {
    translate
  },
  props: {
    config: {
      type: Object,
      required: false,
      default: undefined,
    },
    isAuthenticated: {
      type: Boolean,
      required: false,
    },
    partMarked: {
      type: String,
      required: false,
      validator: value => ["left", "center", "right"].indexOf(value) !== -1,
      default: undefined,
      info: "Teil markieren",
    },
    isMock: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const isMock = toRef(props, "isMock");
    const isAuthenticated = toRef(props, "isAuthenticated");
    const {
      isAuthenticatedSync,
    } = PermissionAPI();
    const isAuthenticatedLocal = computed(() => {
      return isMock.value ?
        isAuthenticated.value :
        isAuthenticatedSync.value;
    });

    const {
      getSyConfigsValue,
    } = SyConfigAPI();
    const config = toRef(props, "config");
    const navbarSyConfig = computed(() => {
      return isMock.value ?
        config.value :
        getSyConfigsValue("navbar", {}).config;
    });

    const configLocalLeft = computed(() => {
      return navbarSyConfig.value.left;
    });
    const configLocalCenter = computed(() => {
      return navbarSyConfig.value.center;
    });
    const configLocalRight = computed(() => {
      return navbarSyConfig.value.right;
    });
    const configLocalRightChildrenFiltered = computed(() => {
      return filter(configLocalRight.value.children || [], item => {
        if (item.statusAuthenticated === true) {
          return isAuthenticatedLocal.value;
        }
        if (item.statusAuthenticated === false) {
          return !isAuthenticatedLocal.value;
        }
        return true;
      });
    });

    const partMarked = toRef(props, "partMarked");
    const markedPartClassLeft = computed(() => {
      return partMarked.value === "left" ? "outline-red-3" : "";
    });
    const markedPartClassCenter = computed(() => {
      return partMarked.value === "center" ? "outline-red-3" : "";
    });
    const markedPartClassRight = computed(() => {
      return partMarked.value === "right" ? "outline-red-3" : "";
    });

    return {
      markedPartClassCenter,
      markedPartClassLeft,
      markedPartClassRight,
      configLocalCenter,
      configLocalLeft,
      configLocalRight,
      configLocalRightChildrenFiltered,
    };
  },
};
