import WorkflowtypDetailsStatusgruppenBlockEditForm
  from "../WorkflowtypDetailsStatusgruppenBlockEditForm/WorkflowtypDetailsStatusgruppenBlockEditForm.vue";

export default {
  name: "WorkflowtypDetailsStatusgruppenBlockEdit",
  components: {
    WorkflowtypDetailsStatusgruppenBlockEditForm,
  },
  props: {
    bezeichnung: {
      type: String,
      required: true,
    },
    workflowGruppenBlock: {
      type: Object,
      required: true,
    },
    stepNumberIcon: {
      type: String,
      required: false,
      default: "",
    },
    boxWizardDetailClass: {
      type: String,
      required: false,
      default: "",
    },
    workflowstatusgruppentypen: {
      type: Array,
      required: true,
    },
    getStatus: {
      type: Function,
      required: true,
    },
    errors: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  emits: [
    "updateModel",
  ],
  computed: {
    showStepNumber() {
      return !this.stepNumberIcon;
    },
  },
  methods: {
    updateModel({ item }) {
      this.$emit("updateModel", { item: item });
    },
  }
};
