"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";


const parametermodule = angular.module("dias.foerderregeln.parameter.auszahlungsplanFilter", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("auszahlungsplan_filter", "parametermodul-json");
  })
;
export default parametermodule;
