"use strict";

import BaseModel from "dias/core/models/baseModel";

class KNutzerstatus extends BaseModel {
  constructor() {
    super("nutzerstatus", {});
  }
}

export default KNutzerstatus;
