import HttpMixin from "../../../../../global/mixins/HttpMixin";
import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";
import ModalCreateOrUpdate from "./ModalCreateOrUpdate/ModalCreateOrUpdate.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import {
  cloneDeep,
  filter,
  keyBy,
  some,
} from "lodash-es";

// @vue/component
export default {
  name: "UiThemenauswahl",
  components: {
    ModalCreateOrUpdate,
    PuxButton,
  },
  mixins: [
    HttpMixin,
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      dataForFeldDefault: [],
      dataForFeldByPK: {},
      confirmShow: undefined,
      confirmOptions: undefined,
      statusModal: undefined,
      currentModel: undefined,
      currentIndex: undefined,
    };
  },
  computed: {
    getDataForFeld() {
      return filter(this.dataForFeldDefault, item => {
        if (this.currentModel && this.currentModel.pk === item.pk) {
          return true;
        }
        return !some(this.model, ["pk", item.pk]);
      });
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getListHttp({
        url: `katalog/`,
        urlParams: {
          key: "kthema",
        },
      }).then(
        response => {
          this.dataForFeldByPK = keyBy(response, "pk");
          this.dataForFeldDefault = response;
          this.loading = false;
        }
      );
    },
    openModalCreate() {
      this.statusModal = true;
    },
    openModalUpdate({ item, index }) {
      this.currentModel = item;
      this.currentIndex = index;
      this.statusModal = true;
    },
    closeModal({ model } = {}) {
      if (model) {
        this.onInput_mixin({ value: model });
      }
      this.statusModal = false;
      this.currentModel = undefined;
      this.currentIndex = undefined;
    },
    moveUpFeld(index) {
      if (index === 0) {
        return;
      }
      const MODEL = cloneDeep(this.model);

      const ELEM = MODEL[index];
      MODEL.splice(index, 1);
      MODEL.splice(index - 1, 0, ELEM);
      this.onInput_mixin({ value: MODEL });
    },
    moveDownFeld(index) {
      if (index >= this.model.length - 1) {
        return;
      }
      const MODEL = cloneDeep(this.model);
      const ELEM = MODEL[index];
      MODEL.splice(index, 1);
      MODEL.splice(index + 1, 0, ELEM);
      this.onInput_mixin({ value: MODEL });
    },
    openConfirmDelete(index) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "Antwortmöglichkeit entfernen",
        msg: `<p>Sind Sie sicher, dass sie die Antwortmöglickgeit von dieser Regel entfernen wollen?</p>`,
        okLabel: "Fortfahren",
        okCallback: () => this.deleteFeld(index),
        cancelCallback: this.closeConfirmDelete,
        loading: false,
      };
      this.confirmShow = true;
    },
    deleteFeld(index) {
      const MODEL = cloneDeep(this.model);
      MODEL.splice(index, 1);
      this.onInput_mixin({ value: MODEL });
      this.closeConfirmDelete();
    },
    closeConfirmDelete() {
      this.confirmShow = false;
    },
  },
};
