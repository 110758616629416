import DokumenteUploadAllDetails from "./DokumenteUploadAllDetails/DokumenteUploadAllDetails.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import SmartTable from "../../../../global/components/table/table.vue";
import Spinner from "../../../../global/components/Spinner/Spinner.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import {
  EventBus,
} from "../../../../global/functions/event-bus";
import {
  cloneDeep,
  findIndex,
  get,
  isNil,
  isString,
} from "lodash-es";

const TABLE_ID = "DokumentUploadTable";

// @vue/component
export default {
  name: "DokumenteUploadAll",
  components: {
    DokumenteUploadAllDetails,
    Modal,
    PuxIcon,
    SmartTable,
    Spinner,
  },
  setup() {
    const {
      deleteHttp,
      getHttp,
      getListHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      deleteHttp,
      getHttp,
      getListHttp,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        cols: [
          {
            label: "_TXT_ALLE_DOKUMENTE_DATEINAME_",
            id: "dokument.dok_dokumentname",
            priority: 10,
          },
          {
            label: "_TXT_ALLE_DOKUMENTE_DOKUMENTENTYP_",
            id: "dokument.dok_typ",
            priority: 20,
          },
          {
            label: "_TXT_ALLE_DOKUMENTE_AkTENNUMMER_",
            id: "label",
            priority: 30,
          },
          {
            label: "_TXT_ALLE_DOKUMENTE_ANGEFUEGT_AM_",
            id: "dokument.crdate",
            filter: "date",
            filterParameter: "DD.MM.YYYY HH:mm",
            priority: 40,
          },
          {
            label: "_TXT_ALLE_DOKUMENTE_SYNC_",
            id: "is_sync",
            slot: "is_sync",
            priority: 50,
          }
        ],
        rowActions: [
          {
            label: "_LBL_ALLE_DOKUMENTE_ANZEIGEN_",
            type: "button",
            href: ({ item }) => `/api/${ item.url }`,
            target: "_self",
          },
          {
            isHidden: ({ item }) => this.isSyncing({ document: item }),
            label: "_LBL_ALLE_DOKUMENTE_DATEI_LOESCHEN_",
            type: "button",
            callback: ({ item, index }) => this.openModalDeleteDokument({ item, index }),
          },
        ],
        data: [],
        label: "_TXT_ALLE_DOKUMENTE_",
        pagination: false,
        showColsConfig: false,
        details: true,
      },
      filters: [],
      modal: {
        delete: undefined,
      },
      confirmOptions: undefined,
      documentsInSync: [],
      timer: undefined,
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },
  },
  created() {
    this.initEventBus();
  },
  beforeUnmount() {
    EventBus.$off("addDocument", this.addDocument);
  },
  methods: {
    initEventBus() {
      EventBus.$on("addDocument", this.addDocument);
    },

    addDocument({ document }) {
      const DOCUMENT = cloneDeep(document);
      DOCUMENT.preview_url = DOCUMENT.url.replace("/dokumente/", "/preview/");
      this.documentsInSync.push(document.pk);
      EventBus.$emit(`addRow${ TABLE_ID }`, ({ row: DOCUMENT }));
      this.checkSynchronization({ time: 0 });
    },

    checkSynchronization({ time = 3000 } = {}) {
      if (this.timer || !this.documentsInSync.length) {
        return;
      }
      this.timer = true;
      setTimeout(() => {
        if (!this.documentsInSync.length) {
          return;
        }
        this.getListHttp({
          url: "dokument_sync/",
          urlParams: {
            dok_id: this.documentsInSync,
          },
        }).then(
          response => {
            this.checkDocumentsInSync({ response });
            this.timer = undefined;
            this.checkSynchronization();
          }
        );
      }, time);
    },

    checkDocumentsInSync({ response }) {
      for (let i = 0; i < response.length; i++) {
        const DOCUMENT = response[i];
        if (!DOCUMENT.is_sync) {
          const INDEX = this.documentsInSync.indexOf(DOCUMENT.pk);
          if (INDEX !== -1) {
            this.documentsInSync.splice(INDEX, 1);
            this.reloadDokument({ pk: DOCUMENT.pk });
          }
        }
      }
    },

    reloadDokument({ pk }) {
      const INDEX = findIndex(this.options.data, ["pk", pk]);
      this.getHttp({
        url: `${ this.options.data[INDEX].url }meta_info/`
      }).then(
        response => {
          response.preview_url = response.url.replace("/dokumente/", "/preview/");
          response.url = response.url.replace(/.+\/api\//, "");
          this.updateDocument({ row: response, pk });
        }
      );
    },

    updateDocument({ row, pk }) {
      const INDEX = findIndex(this.options.data, ["pk", pk]);
      EventBus.$emit(`updateRow${ this.options.id }`, ({ row, index: INDEX }));
    },

    openModalDeleteDokument({ item, index }) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_ALLE_DOKUMENTE_LOESCHEN_",
        msg: "_HTML_ALLE_DOKUMENTE_MSG_",
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: () => this.deleteDocument({ item, index }),
        cancelCallback: this.closeConfirm,
      };
      this.modal.delete = true;
    },

    deleteDocument({ item, index }) {
      this.deleteHttp({ url: `${ item.url }` }).then(
        () => {
          EventBus.$emit(`deleteRow${ this.options.id }`, ({ index: index }));
          this.addNotification({ text: "_MSG_ALLE_DOKUMENTE_LOESCHEN_" });
        },
        error => {
          console.log(error);
          let cur = error;
          while (!isNil(cur) && !isString(cur)) {
            cur = get(cur, "data");
          }
          this.addNotification({ text: cur, type: "error" });
        }
      );
      this.modal.delete = false;
    },

    closeConfirm() {
      this.modal.delete = false;
    },

    isSyncing({ document }) {
      return document.is_sync === true;
    },
  },
};
