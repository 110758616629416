import {
  computed,
  toRef,
} from "vue";

import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxAlert from "../../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import SitzungModulePropsMixin from "../SitzungModulePropsMixin";

import PermissionAPI from "../../../../../global/compositionAPI/PermissionAPI";
import SitzungModuleAPI from "../compositionAPI/SitzungModuleAPI";

// @vue/component
export default {
  name: "ModuleTopProtokollMain",
  components: {
    FormElement,
    FormstepDetail,
    PuxAlert,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    SitzungModulePropsMixin,
  ],
  setup(props, context) {
    const {
      htmlId,
      model,
      modulname,
      saveModule,
    } = SitzungModuleAPI(props, context);

    const permissionSrc = toRef(props, "permissionSrc");
    const {
      checkPermissionsSync,
    } = PermissionAPI();

    const canView = computed(() => {
      return checkPermissionsSync({
        perm: "sitzungen.protokoll.view",
        permArray: permissionSrc.value,
      });
    });

    const readonly = computed(() => {
      return !checkPermissionsSync({
        perm: "sitzungen.protokoll.update",
        permArray: permissionSrc.value,
      });
    });

    return {
      htmlId,
      model,
      modulname,
      saveModule,

      readonly,
      canView,
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      return {
        saveCallback: this.saveModule,
        list: [
          this.optionsProtokoll,
        ],
      };
    },

    isModuleEditable() {
      return !this.readonly;
    },

    optionsProtokoll() {
      const ID = "protokoll";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "richtext",
        label: "_TXT_TOP_MODULE_PROTOKOLL_",
        view: "v-alt",
        editonly: true,
        emptyText: "_TXT_TOP_KEINE_PROTOKOLL_",
      };
    },
  },
};
