import HttpMixin from "../../../global/mixins/HttpMixin";
import Modal from "../../../global/components/Modal/Modal.vue";
import FormElement from "../../../global/components/FormElement/FormElement.vue";
import Alert from "../../../global/components/Alert/Alert.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

import SaveCallbackActions from "../../../const/SaveCallbackActions";
import {
  initModelFromList,
  toFormElementFromParameter
} from "../../../global/functions/mappingForParameterToFormElement";
import {
  assign,
  cloneDeep,
  size,
  concat,
  forEach,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "GeschaeftsregelModalCreate",
  components: {
    Alert,
    Modal,
    FormElement,
    PuxButton,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: true,
      },
      status: {
        loading: undefined,
      },
      errors: {},
      errorsClone: {},
      listMain: [
        {
          id: "re_kbez_admin",
          type: "text",
          label: "Kurzbezeichnung für Administration",
          view: "v",
          required: true,
        },
        {
          id: "re_kbez",
          type: "text",
          label: "Meldungstext bei Validierungsmeldungen",
          view: "v",
          required: true,
        },
        {
          id: "re_bezeichnung",
          type: "richtext",
          label: "Beschreibung",
          view: "v-alt",
          required: true,
        },
        {
          id: "anzeigekontext",
          type: "select",
          label: "Anzeigekontext",
          view: "v",
          required: true,
          url: "foerderanzeigekontexte/",
          apiSaveId: "oerderanzeigekontexte/",
          keyId: "faz_id",
          keyLabelJson: [
            {
              teg: "span",
              keyLabel: "faz_kbez",
            },
            {
              teg: "span",
              string: " - ",
            },
            {
              teg: "span",
              keyLabel: "faz_bez",
            },
          ],
          search: true,
          searchList: ["faz_kbez", "faz_bez"],
        },
        {
          id: "re_astbez",
          type: "richtext",
          label: "Hilfetext für den Antragsteller",
          view: "v-alt",
          required: false,
        },
        {
          id: "re_savecallback",
          type: "select",
          label: "_LBL_FOERDERREGEL_DETAILS_SAVECALLBACK_",
          keyId: "value",
          keyLabel: "label",
          placeholder: "_LBL_FOERDERREGEL_SAVECALLBACK_NO_ACTION_",
          dataTranslate: true,
          data: [
            {
              value: SaveCallbackActions.RELOAD_WF,
              label: "_LBL_FOERDERREGEL_SAVECALLBACK_RELOAD_WF_"
            },
            {
              value: SaveCallbackActions.RELOAD_ALL,
              label: "_LBL_FOERDERREGEL_SAVECALLBACK_RELOAD_ALL_"
            },
            {
              value: SaveCallbackActions.NO_ACTION,
              label: "_LBL_FOERDERREGEL_SAVECALLBACK_NO_ACTION_"
            },
          ],
          view: "v",
          required: false,
        },
        {
          id: "key",
          type: "select",
          label: "Schlüssel (optional)",
          keyId: "pk",
          search: true,
          searchList: ["rek_kbez"],
          keyLabel: "rek_kbez",
          url: "katalog/?key=regelkeys",
          view: "v",
          required: false,
        },
        {
          id: "hr",
          type: "template",
          html: "<hr>",
        },
        {
          id: "modul",
          type: "select",
          label: "Modul",
          view: "v",
          required: true,
          url: "system_module/",
          apiSaveId: "system_module/",
          keyId: "sm_id",
          keyLabel: "sm_bez",
          change: this.onModulChange,
          search: true,
          searchList: ["sm_bez"],
          deselect: false,
        },
      ],
      modul_obj: undefined,
      listParameter: [],
      modelParameter: {},
    };
  },
  computed: {
    showAlert() {
      return size(this.errors);
    },

    alertList() {
      return concat(this.listMain, this.listParameter);
    },

    dependencyValues() {
      const DEPS = {};
      forEach(this.listParameter, item => {
        if (item.dependency) {
          DEPS[item.id] = get(this.modelParameter, item.dependency);
        }
      });
      return DEPS;
    },
  },
  methods: {
    onModulChange({ item }) {
      this.modul_obj = cloneDeep(item) || {};
      this.deleteErrors();
      this.setList();
      this.setModelFromList();
    },

    setList() {
      this.listParameter = toFormElementFromParameter({
        obj: this.modul_obj.sm_parameterin,
        showLabel: true,
        staticOptionsForType: {
          dyn_tabelle_objektkatalog_data: {
            dependency: "spalten",
          },
          dyn_tabelle_spaltenauswahl_radio: {
            dependency: "spalten",
          },
        },
      }).list || [];
    },

    setModelFromList() {
      this.modelParameter = initModelFromList({ list: this.listParameter }) || {};
    },

    save() {
      this.deleteErrors();
      this.status.loading = true;
      const data = assign({}, this.model, this.modelParameter);
      this.postHttp({
        url: "foerderregeln/",
        data: data,
      }).then(
        response => {
          this.close({ response, status: true });
          this.$goTo("root.foerderregeln.detail", { id: response.pk });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = cloneDeep(error.data);
        }
      ).then(
        () => this.status.loading = false
      );
    },

    deleteErrors() {
      this.errors = {};
      this.errorsClone = {};
    },

    updateErrors({ errors, id }) {
      this.errors[id] = errors;
    },
  },
};
