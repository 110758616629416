import translate from "../../../../global/directives/translate";
import { size } from "lodash-es";

// @vue/component
export default {
  directives: {
    translate
  },
  props: {
    data: {
      type: Object,
      default: undefined,
    },
    config: {
      type: Object,
      default: undefined,
    },
    htmlRegelNummer: {
      type: String,
      default: undefined,
    },
    currentGroup: {
      type: String,
      default: "null",
    },
    groups: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    inputType() {
      if (this.config.mehrfachauswahl) {
        return "checkbox";
      }
      return "radio";
    },

    inputTypeClass() {
      return `custom-${ this.inputType }`;
    },
  },
  methods: {
    getSelectionStatus(el) {
      if (this.data[el.pk]) {
        return this.data[el.pk].status;
      }
    },

    getFreitext(el) {
      if (this.data[el.pk]) {
        return this.data[el.pk].freitext;
      }
    },

    showFreitext(el) {
      return el.freitext && this.getFreitext(el);
    },

    getSubGroup(el) {
      return this.groups[el.pk];
    },

    hasSubGroup(el) {
      return size(this.getSubGroup(el)) > 0;
    },

    getIndeterminateState(el) {
      if (!this.hasSubGroup(el)) {
        return;
      }
      let anyTrue = null;
      let anyFalse = null;
      const SUB_GROUP = this.getSubGroup(el);
      for (let i = 0; i < size(SUB_GROUP); i++) {
        const ul = SUB_GROUP[i];
        if (this.getIndeterminateState(ul)) {
          return true;
        }
        const UL_STATUS = this.getSelectionStatus(ul);
        if (UL_STATUS) {
          anyTrue = true;
        } else {
          anyFalse = false;
        }
        if ((anyTrue === true && anyFalse === false)
            || (anyTrue === false && anyFalse === true)) {
          return true;
        }
      }
      return false;
    },
  },
};
