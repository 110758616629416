import PuxButton from "../../../PuxButton/PuxButton.vue";
import PuxTranslate from "../../../PuxTranslate/PuxTranslate.vue";

import UICheckboxAndRadiobuttonItemMixin from "../../UICheckboxAndRadiobuttonItemMixin";

import {
  forEach,
  indexOf,
  isArray,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "UiRadiobuttonItem",
  components: {
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    UICheckboxAndRadiobuttonItemMixin,
  ],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    model: {
      type: [String, Array, Boolean],
      default: undefined,
    },
  },
  emits: [
    "change",
    "input",
  ],
  data() {
    return {
      status: {
        loading: true,
      },
    };
  },
  computed: {
    containerClass() {
      return this.item.info ? "d-flex" : "";
    },
    isChecked() {
      if (isArray(this.model)) {
        return indexOf(this.model, this.value) !== -1;
      }
      if (isUndefined(this.model)) {
        return false;
      }
      return this.value === this.model;
    },
  },
  methods: {
    getValueByKey(item) {
      if (this.options.keyId) {
        return item[this.options.keyId];
      }
      return item.value;
    },

    toggleModel(item, $event) {
      if (isArray(this.model)) {
        if (indexOf(this.model, this.value) !== -1) {
          return;
        }
        forEach(this.data, dataItem => {
          const indexInModel = indexOf(this.model, this.getValueByKey(dataItem));
          if (indexInModel !== -1) {
            // eslint-disable-next-line vue/no-mutating-props
            this.model.splice(indexInModel, 1);
          }
        });

        // eslint-disable-next-line vue/no-mutating-props
        this.model.push(this.value);
        this.$emit("change", {
          model: this.model,
          options: this.options,
          id: this.options.id,
          $event: $event,
          item: item,
        });
        this.$emit("input", {
          model: this.model,
          id: this.options.id,
          $event: $event,
          options: this.options,
        });
        return;
      }

      const CURRENT_MODEL = this.value;
      if (CURRENT_MODEL === this.model) {
        return;
      }

      this.$emit("change", {
        model: CURRENT_MODEL,
        options: this.options,
        id: this.options.id,
        $event: $event,
        item: item,
      });
      this.$emit("input", {
        model: CURRENT_MODEL,
        id: this.options.id,
        $event: $event,
        options: this.options,
      });
    },
  },
};
