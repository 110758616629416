"use strict";

import builder from "dias/standard_sitzungsmodule/utils/builder.js";

import template from "./detail.jade";
import EditController from "../standard_sitzungsmodule.edit.controller";

class AntragslisteMittellosDetailController extends EditController {
  /*@ngInject*/
  constructor(
    $scope,
    $q,
    $stateParams,
  ) {
    super($scope, $q);
    this.sitzungPk = $stateParams.id;
    this.emitEventAngular = () => {
      this.emitEvent(this.EVENT_ACTION_UPDATED);
    };
  }
}

export default builder.detail({
  template: template(),
  controller: AntragslisteMittellosDetailController,
  controllerAs: "vm"
});
