"use strict";


class AuszahlungsbetragEditController {
  /*@ngInject*/
  constructor($q, $scope, i18n) {
    this.$q = $q;
    this.gettext = i18n.gettext;
    this.auszahlung = $scope.extras.auszahlung;
    this.auszahlungsplan = $scope.extras.auszahlungsplan;
    this.az_betrag_geaendert = this.auszahlung.az_betrag_geaendert;
    this.az_betrag_geaendert_grund = this.auszahlung.az_betrag_geaendert_grund;
    this.save = this.save.bind(this);
    this.init();
  }

  init() {
    this.grund_required = this.auszahlungsplan.zp_modalitaet.anpassung_begruenden;
  }

  save() {
    const defer = this.$q.defer();
    this.auszahlung.auszahlungsbetragAnpassen(this.az_betrag_geaendert, this.az_betrag_geaendert_grund).then(response => {
      defer.resolve(response);
    }, errors => {
      this.errors = errors.data;
      defer.reject();
    });

    return defer.promise;
  }
}

export default AuszahlungsbetragEditController;
