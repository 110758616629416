"use strict";

import DynamischeTabelleParam from "./dynamische_tabelle";
import DynTabelleObjektkatalogDataParam from "./dyn_tabelle_objektkatalog_data";

const dynTabelle = angular.module("dias.dynamische_tabelle.parameter", [
  DynamischeTabelleParam.name,
  DynTabelleObjektkatalogDataParam.name
])
;
export default dynTabelle;
