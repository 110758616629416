import KofiErfassungBelegFormModalWizard from "../KofiErfassungBelegFormModalWizard/KofiErfassungBelegFormModalWizard.vue";
import KofiErfassungBelegWizard from "../KofiErfassungBelegWizard/KofiErfassungBelegWizard.vue";

import KofiErfassungPositionBaseMixin from "../KofiErfassungPosition/KofiErfassungPositionBaseMixin";

// @vue/component
export default {
  name: "KofiErfassungPositionWizard",
  components: {
    KofiErfassungBelegFormModalWizard,
    KofiErfassungBelegWizard,
  },
  mixins: [
    KofiErfassungPositionBaseMixin,
  ],
  data() {
    return {
      isKofiShouldReloaded: false,
    };
  },
  methods: {
    setKofiShouldReloaded() {
      this.isKofiShouldReloaded = true;
    },

    addBeleg() {
      this.selectorClose = [
        `#${ this.buttonAddBelegId }`,
        `#${ this.htmlId }`,
      ];
      this.statusAddBeleg = true;
      this.onOpenModalLocal();
    },

    closeAddBeleg() {
      this.statusAddBeleg = false;
      this.selectorClose = undefined;
      if (this.isKofiShouldReloaded) {
        this.$attrs.onReloadKofi({ statusCallback: true });
        this.isKofiShouldReloaded = false;
      }
    },

    reloadKofiLocal(arg) {
      this.$attrs.onReloadKofi(arg);
      this.isKofiShouldReloaded = false;
    },
  },
};
