"use strict";

// import medialen from "dias/die-medialen/system.js";

export default /*@ngInject*/ () => {
  return {
    restrict: "A",
    link: (scope, el, attrs) => {
      scope.$watch(attrs.scrollIntoViewOn, v => {
        if (v === true) {
          // medialen.openFormStep(attrs.stepId);
        }
      });
    }
  };
};
