import loading from "../../../global/directives/loading";

// @vue/component
export default {
  name: "StyleguideLoading",
  directives: {
    loading,
  },
  data() {
    return {
      status: {
        loading: undefined,
      },
      model: {
        msg: "",
      },
    };
  },
  methods: {
    toggleStatus() {
      this.status.loading = !this.status.loading;
    },
  },
};
