import AuszahlungPlanModalBerechnungsmodus from "./AuszahlungPlanModalBerechnungsmodus/AuszahlungPlanModalBerechnungsmodus.vue";
import AuszahlungPlanModalFristverlaengerung from "./AuszahlungPlanModalFristverlaengerung/AuszahlungPlanModalFristverlaengerung.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import getTableApi from "../../../const/TableApis";
import KAuszahlungsplanstatusUUIDs from "../../../const/KAuszahlungsplanstatusUUIDs";
import { EventBus } from "../../../global/functions/event-bus";

const TABLE_ID = "AuszahlungPlanListInVertragTable";

// @vue/component
export default {
  name: "AuszahlungPlanListInVertrag",
  components: {
    AuszahlungPlanModalBerechnungsmodus,
    AuszahlungPlanModalFristverlaengerung,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
  ],
  props: {
    vertragPk: {
      type: String,
      default: undefined,
    },
    auszahlungsplan: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      currentItem: undefined,
      modal: {
        fristverlaengerung: false,
        berechnungsmodus: false
      },
      options: {
        id: TABLE_ID,
        url: undefined,
        cols: [
          {
            label: "_LBL_AUSZAHLUNG_PLAN_IN_VERTRAG_STUFE_",
            id: "zp_nummer",
            sortable: true,
            sortId: "zp_nummer",
            sortingDefault: "asc",
          },
          {
            label: "_LBL_AUSZAHLUNG_PLAN_IN_VERTRAG_NAME_",
            id: "name",
          },
          {
            label: "_LBL_AUSZAHLUNG_PLAN_IN_VERTRAG_MODALITAET_",
            id: "display",
          },
          {
            label: "_LBL_AUSZAHLUNG_PLAN_IN_VERTRAG_STATUS_",
            id: "status.aps_kbez",
          },
        ],
        rowActions: [
          {
            type: "button",
            label: "_TXT_AUSZAHLUNGPLAN_IN_VERTRAG_TABLE_ROW_ACTIONS_CREATE_",
            callback: ({ item }) => this.createAuszahlung({ item }),
            isHidden: ({ item }) => !item.can_create_auszahlung || !this.checkPermissionsSync({ perm: "auszahlung.create", permArray: item.user_permissions }),
            labelCallback: ({ item }) => `${ item.name } erstellen`,
          },
          {
            type: "button",
            label: "_TXT_AUSZAHLUNGPLAN_IN_VERTRAG_TABLE_ROW_ACTIONS_OPEN_",
            callback: ({ item }) => this.openAuszahlungsplan({ item }),
            isHidden: ({ item }) => (item.status.pk !== KAuszahlungsplanstatusUUIDs.ABGESCHLOSSEN &&
                                    item.status.pk !== KAuszahlungsplanstatusUUIDs.AUSGELASSEN) ||
                                    !this.checkPermissionsSync({ perm: "auszahlungsplan.oeffnen", permArray: item.user_permissions }),
          },
          {
            type: "button",
            label: "_TXT_AUSZAHLUNGPLAN_IN_VERTRAG_TABLE_ROW_ACTIONS_FRISTVERLAENGERUNG_",
            callback: ({ item }) => this.openFristverlaengerungModal({ item }),
            isHidden: ({ item }) => !this.checkPermissionsSync({ perm: "auszahlungsplan.fristen.update", permArray: item.user_permissions }),
          },
          {
            type: "button",
            label: "_TXT_AUSZAHLUNGPLAN_IN_VERTRAG_TABLE_ROW_ACTIONS_BERECHNUNGSMODUS_CHANGE_",
            callback: ({ item }) => this.openBerechnungsmodusModal({ item }),
            isHidden: ({ item }) => !this.checkPermissionsSync({ perm: "auszahlungsplan.berechnungsmodus", permArray: item.user_permissions }),
          },
        ],
        label: "_LBL_AUSZAHLUNG_PLAN_IN_VERTRAG_AUSZAHLUNGSPLAN_",
        sortable: true,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        infoHeaderSlot: "auszahlungInfoHeader",
        showColsConfig: false,
      },
      statusCreateAuszahlung: undefined,
    };
  },
  created() {
    this.initUrl();
    this.initCols();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { vertragPk: this.vertragPk } });
    },

    initCols() {
      if (this.checkPermissionsSync({ perm: "auszahlungsplan.fristen.view" })) {
        this.options.cols.push(
          {
            label: "_LBL_AUSZAHLUNG_PLAN_IN_VERTRAG_FRIST_",
            id: "auszahlungsfrist",
            filter: "date",
          }
        );
      }
    },

    createAuszahlung({ item } = {}) {
      if (this.statusCreateAuszahlung) {
        return;
      }
      this.statusCreateAuszahlung = true;
      this.postHttp({ url: `vertraege/${ this.vertragPk }/auszahlungsplaene/${ item.pk }/auszahlung_erstellen/` }).then(
        response => {
          this.$goTo("root.vertrag.details.auszahlung", { id: this.vertragPk, aus_id: response.pk });
        },
        () => {
          this.addNotification({
            text: "_MSG_AUSZAHLUNGPLAN_IN_VERTRAG_TABLE_AUSZAHLUNGPLAN_CREATE_ERROR_",
            type: "error"
          });
        }
      ).then(
        () => {
          this.statusCreateAuszahlung = false;
        }
      );
    },

    openAuszahlungsplan({ item }) {
      this.putHttp({ url: `vertraege/${ this.vertragPk }/auszahlungsplaene/${ item.pk }/oeffnen/` }).then(
        () => {
          this.addNotification({ text: "_MSG_AUSZAHLUNGPLAN_IN_VERTRAG_TABLE_AUSZAHLUNGPLAN_OPEN_SUCCESS_" });
          EventBus.$emit(`updateTable${ TABLE_ID }`);
        },
        () => {
          this.addNotification({
            text: "_MSG_AUSZAHLUNGPLAN_IN_VERTRAG_TABLE_AUSZAHLUNGPLAN_OPEN_ERROR_",
            type: "error"
          });
        }
      );
    },

    openFristverlaengerungModal({ item }) {
      this.currentItem = item;
      this.modal.fristverlaengerung = true;
    },

    closeFristverlaengerungModal({ status }) {
      this.modal.fristverlaengerung = false;
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
    },


    openBerechnungsmodusModal({ item }) {
      this.currentItem = item;
      this.modal.berechnungsmodus = true;
    },

    closeBerechnungsmodusModal() {
      this.modal.berechnungsmodus = false;
    },
  },
};
