import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

import SaveCallbackActions from "../../../../const/SaveCallbackActions";
import {
  cloneDeep,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungBetragAnpassenButtonModal",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  props: {
    auszahlung: {
      type: Object,
      required: true,
    },
    auszahlungsplan: {
      type: Object,
      required: true,
    },
    auszahlungUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "saveCallback"
  ],
  setup() {
    const {
      postHttp,
    } = HttpAPI();

    return {
      postHttp,
    };
  },
  data() {
    return {
      isModalVisible: undefined,
      buttonId: "auszahlung_betrag_anpassen",
      errors: undefined,
      errorsClone: undefined,
      loading: false,
      model: {},
    };
  },
  computed: {
    urlSave() {
      return `${ this.auszahlungUrl }auszahlungsbetrag_anpassen/`;
    },

    isGrundRequired() {
      return !!get(this.auszahlungsplan, "zp_modalitaet.anpassung_begruenden");
    },

    optionsForModal() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "az_betrag_anerkannt",
            label: "_LBL_ANERKANNTER_AUSZAHLUNGSBETRAG_",
            type: "currency",
            required: true,
            view: "v",
            disabled: true,
          },
          {
            id: "az_betrag_geaendert",
            label: "_LBL_ANGEPASSTER_AUSZAHLUNGSBETRAG_",
            type: "currency",
            required: true,
            view: "v",
          },
          {
            id: "az_betrag_geaendert_grund",
            label: "_TXT_BEGRUENDUNG_FUER_ANPASSUNG_",
            type: "richtext",
            required: this.isGrundRequired,
            view: "v-alt",
          },
        ],
      };
    },
  },
  methods: {
    openModal() {
      this.initModel();
      this.isModalVisible = true;
    },

    initModel() {
      this.model = {
        az_betrag_anerkannt: this.auszahlung.az_betrag_anerkannt,
        az_betrag_geaendert: this.auszahlung.az_betrag_geaendert,
        az_betrag_geaendert_grund: this.auszahlung.az_betrag_geaendert_grund,
      };
    },

    save() {
      this.loading = true;
      this.postHttp({
        url: this.urlSave,
        data: {
          az_betrag_geaendert: this.model.az_betrag_geaendert,
          az_betrag_geaendert_grund: this.model.az_betrag_geaendert_grund,
        },
      }).then(
        () => {
          this.$emit("saveCallback", { statusSaveCallback: SaveCallbackActions.RELOAD_ALL });
          this.closeModal();
        },
        error => {
          this.errors = error.data;
          this.errorsClone = cloneDeep(error.data);
        }
      ).then(
        () => this.loading = false
      );
    },

    closeModal() {
      this.isModalVisible = false;
    },
  },
};
