"use strict";

import LoadingDirective from "./loading.directive";
import CloakDirective from "./cloak.directive";

const diasLoading = angular.module("dias.loading", [
])

  .directive("diasLoading", LoadingDirective)
  .directive("diasCloak", CloakDirective)
;


export default diasLoading;
