"use strict";

import Modal from "dias/core/modal";
import Tasklog from "dias/tasklog";
import diasLoading from "dias/core/loading";
import diasNotification from "dias/core/notification";

import models from "./models";

import CSVDownload from "./csv.download.component";

const diasCSV = angular.module("dias.csv", [
  diasLoading.name,
  diasNotification.name,
  models.name,
  Modal.name,
  Tasklog.name,
])
  .component("diasCsvDownload", CSVDownload);

export default diasCSV;
