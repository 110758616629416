"use strict";

import BaseModel from "./baseModel";

class ReleaseNotes extends BaseModel {
  constructor() {
    super("releasenotes");
  }
}

export default ReleaseNotes;
