import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import SmartTable from "../../../global/components/table/table.vue";
import getTableApi from "../../../const/TableApis";
import WorkflowsModalCreate from "../WorkflowsModalCreate/WorkflowsModalCreate.vue";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";

const TABLE_ID = "workflow";

// @vue/component
export default {
  name: "WorkflowsList",
  components: {
    AngularLink,
    SmartTable,
    WorkflowsModalCreate,
    PageTabTitle,
  },
  mixins: [
    PermissionMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [
          {
            label: this.$gettext("Name"),
            id: "wf_kbez",
            sortable: true,
            sortId: "wf_kbez",
            slot: "wf_kbez",
          },
          {
            label: this.$gettext("Beschreibung"),
            id: "wf_bez",
            sortable: true,
            sortId: "wf_bez",
          },
          {
            label: this.$gettext("Typ"),
            id: "workflowtyp_obj.wft_kbez",
            sortable: true,
            sortId: "workflowtyp__wft_kbez",
            slot: "wft_kbez",
          },
          {
            label: this.$gettext("Einstiegsworkflow des Typs"),
            id: "wf_start",
            sortable: true,
            sortId: "wf_start",
            hide: true,
            templateCallback: ({ row }) => this.$gettext(row.wf_start ? "Ja" : "Nein"),
          },
        ],
        rowActions: [
          {
            label: this.$gettext("Details anzeigen"),
            route: "root.workflows.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [
          {
            label: this.$gettext("Neuen Workflow erstellen"),
            callback: () => this.modal.create = true,
            permission: "workflow.create",
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "workflow_create",
          },
        ],
        label: "Workflows",
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
        perms: {},
      },
      filters: [
        {
          type: "text",
          id: "wf_kbez",
          label: this.$gettext("Name"),
          main: true,
        },
        {
          type: "text",
          id: "wf_bez",
          label: this.$gettext("Beschreibung"),
        },
        {
          type: "select",
          id: "workflowtyp",
          url: "katalog/?key=workflowtyp&fields=pk&fields=wft_kbez",
          label: this.$gettext("Typ"),
          keyLabel: "wft_kbez",
          keyId: "pk",
          search: true,
          searchList: ["wft_kbez"],
        },
        {
          type: "boolean",
          id: "wf_start",
          label: this.$gettext("Einstiegsworkflow des Typs"),
        },
      ],
      modal: {
        create: undefined,
      },
    };
  },
  created() {
    this.perms = {
      "workflowtyp.ui": this.checkPermissionsSync({ perm: "workflowtyp.ui" }),
    };
  },
  methods: {
    closeModalCreate() {
      this.modal.create = false;
    },
  },
};
