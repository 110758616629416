import angular from "angular";

import Registry from "./registry.service";

const parameterregistry = angular.module("dias.foerderregeln.parameter.services", [])

  .provider("ParametermoduleRegistry", Registry)
;

export default parameterregistry;
