import FormElement from "../../../../../../../libs/vue/client/vue/global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";
import PuxButton from "../../../../../../../libs/vue/client/vue/global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../../../../libs/vue/client/vue/global/components/PuxCloak/PuxCloak.vue";

import StudiengangEditMixin
  from "../../../../../../../libs/vue/client/vue/components/Geschaeftsregel/Module/AR/StudiengangAuswahl/StudiengangEdit/StudiengangEditMixin";

import KStudiengangartUUIDs from "../../../../../../../libs/vue/client/vue/const/KStudiengangartUUIDs";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "StudiengangDetailsKopieErstellen",
  components: {
    FormElement,
    FormstepDetail,
    PuxButton,
    PuxCloak,
  },
  mixins: [
    StudiengangEditMixin,
  ],
  props: {
    close: {
      type: Function,
      required: true,
    },
  },
  computed: {
    formstepDetailOption() {
      return {
        label: `Kopie erstellen von: ${ this.studiengang.sg_bez }`,
        closeCallback: this.close,
      };
    },

    idForCloseButton() {
      return `${ this.id }_close`;
    },

    idForCopyButton() {
      return `${ this.id }_copy`;
    },

    showInfoKombi() {
      return (this.studiengang.art === KStudiengangartUUIDs.KOMBI);
    },

    showInfoTeil() {
      return (this.studiengang.art === KStudiengangartUUIDs.TEIL);
    },

    showCopy() {
      return (this.copymode || !this.model.kopiervorlage);
    },

    showAkkreditierungCopy() {
      return (this.showCopy && !this.showInfoTeil && this.checkPermissionsSync({ perm: "studiengang.copy_akkr" }));
    },
  },
  methods: {
    copy() {
      this.loading = true;
      const data = cloneDeep(this.model);
      delete data.sg_id;
      this.postHttp({
        url: `studiengaenge/${ this.model.sg_id }/kopieren/`,
        data,
      }).then(
        response => {
          if (response.kopie) {
            this.$goTo("root.studiengang.detail", { id: response.kopie });
          }
        },
        errors => {
          return this.onErrors(errors);
        }
      ).finally(
        () => this.loading = false
      );
    },
  },
};
