import PuxButton from "../../global/components/PuxButton/PuxButton.vue";

import {
  EventBus,
} from "../../global/functions/event-bus";

// @vue/component
export default {
  name: "TheMenuMobileButton",
  components: {
    PuxButton,
  },
  setup() {
    const openMenu = () => {
      EventBus.$emit("openMobileMenu");
    };

    return {
      openMenu,
    };
  },
};
