import BetragsrechnerMixin from "../Mixins/BetragsrechnerMixin";

// @vue/component
export default {
  name: "KostenOhneBezeichnung",
  mixins: [
    BetragsrechnerMixin,
  ],
  data() {
    return {
      labelWert: "_LBL_STANDARD_KOSTENRECHNER_WERT_",
    };
  },
  computed: {
    optionsWert() {
      return {
        id: "wert",
        htmlId: this.htmlIdWert,
        label: this.labelWert,
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdWert() {
      return `${ this.htmlId }_wert`;
    },

    errorLabels() {
      return {
        wert: {
          label: this.labelWert,
          link: true,
          id: this.htmlIdWert,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
  },
};
