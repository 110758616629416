var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;


























jade_mixins["readonly-block"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div ng-if=\"vm.isReadonly() || !vm.globalBearbeiten\" ng-show=\"!vm.editMode\" ng-class=\"{'clearfix-hover' : vm.hoverPosition}\" class=\"clearfix box_hover\">");
block && block();
buf.push("</div><div ng-if=\"!vm.isReadonly() &amp;&amp; vm.globalBearbeiten\" ng-show=\"!vm.editMode\" ng-click=\"!vm.isReadonly() &amp;&amp; vm.globalBearbeiten ? vm.toggleEditMode() : null\" data-toggle=\"{{!vm.isReadonly() &amp;&amp; vm.globalBearbeiten ? 'edit' : ''}}\" ng-class=\"{ 'editable' : !vm.isReadonly() &amp;&amp; vm.globalBearbeiten }\" tabindex=\"-1\" class=\"clearfix box_hover\">");
block && block();
buf.push("</div>");
};





































































jade_mixins["vorschau-daten"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-repeat", "(key, val) in " + (vorschau) + "", true, true)) + "><span ng-if=\"val\">{{key}}: {{ val }}</span></div>");
};






buf.push("<div ng-if=\"vm.kontext === 'zuschuss'\">");
jade_mixins["readonly-block"].call({
block: function(){
buf.push("<div class=\"position\"><div>{{vm.idx}}</div><div>{{vm.antragKoFiArt.bez}}<button ng-click=\"vm.toggleStatusInfo()\" ng-class=\"{ 'active': vm.statusInfo }\" type=\"button\" ng-attr-title=\"Zusatzinformation bezüglich {{vm.antragKoFiArt.bez}} {{vm.statusInfo ? 'ausblenden': 'einblenden'}}\" ng-attr-aria-expanded=\"{{vm.statusInfo}}\" ng-if=\"vm.modul.regel.afr_astbez\" class=\"btn btn-icon toggle-info\"><span class=\"glyphicon glyphicon-info-sign\"></span></button></div><div ng-if=\"vm.infoprovider.status &amp;&amp; vm.infoprovider.status !== 1\">Noch nicht ermittelbar</div><div ng-if=\"!vm.infoprovider || !vm.infoprovider.status || vm.infoprovider.status == 1\"> {{ vm.get_antrag_betrag() }}</div><div class=\"version\">{{vm.searchInSnapshot(vm.antragKoFiArt.artPk,  vm.antragKoFiArt.diffKey) | currency}}</div><div class=\"procent\"></div><div><!-- placeholder--></div></div><div ng-if=\"vm.kontext === 'zuschuss'\"><div ng-if=\"vm.infoprovider.details[vm.modul.regel.afr_id].meldungen.length &gt; 0\" class=\"alert no-margin-bottom alert-warning\"><span ng-repeat=\"m in vm.infoprovider.details[vm.modul.regel.afr_id].meldungen\">{{ m }}</span></div></div><div ng-show=\"vm.modul.regel.afr_kbez || vm.modul.regel.afr_bezeichnung\" class=\"rules\"><h5 ng-bind-html=\"vm.modul.regel.afr_kbez\"></h5><div ng-bind-html=\"vm.modul.regel.afr_bezeichnung\"></div></div><div ng-show=\"vm.statusInfo\" role=\"alert\" class=\"info\"><button type=\"button\" ng-attr-title=\"Zusatzinformation bezüglich {{vm.antragKoFiArt.bez}} {{vm.statusInfo ? 'ausblenden': 'einblenden'}}\" ng-click=\"vm.toggleStatusInfo()\" class=\"btn btn-icon btn-absolute\"><i class=\"glyphicon glyphicon-remove\"></i></button><div ng-bind-html=\"vm.modul.regel.afr_astbez\"></div></div>");
}
});
buf.push("</div><div ng-if=\"vm.kontext === 'zuschuss_vertrag'\" class=\"position\"><div>{{ vm.vertragFinanzierungsart.finanzierungsart.fin_kbez  }}</div><div>{{ vm.vertragFinanzierungsart.vf_betrag_bewilligt | currency }}<span ng-bind-html=\"vm.get_vertrag_prozent('bewilligt')\"></span></div><div></div><div>{{ vm.get_vertrag_betrag() }}<span ng-bind-html=\"vm.get_vertrag_prozent('aktualisiert')\"></span></div><div></div></div>");;return buf.join("");
}