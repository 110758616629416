import {
  FilterCurrencyMixin,
  FilterDateMixin,
  FilterIbanMixin,
  FilterSpacedMixin,
} from "../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "vue-styleguide-filters",
  mixins: [
    FilterCurrencyMixin,
    FilterDateMixin,
    FilterIbanMixin,
    FilterSpacedMixin,
  ],
  data() {
    return {
      currency1: `ul\n  li {{ filterCurrency(123456789.123) }} \n  li {{ filterCurrency(123456789) }} \n  li {{ filterCurrency(234.345,456) }} \n  li {{ filterCurrency("345.456") }} \n  li {{ currency2 }} `,
      currency2: this.filterCurrency(1231321321321.5454),
      date1: `ul\n  li {{ filterDate("2018-01-23") }}\n  li {{ filterDate("2018-01-23", "MM.YYYY") }}\n  li {{ filterDate("2018-01-23", "YYYY") }}\n  li {{ filterDate("2018-01-23", "MM") }}`,
      iban1: `ul\n  li {{ filterIban("de12345678901234567890", true) }}\n  li {{ filterIban("de12345678901234567890") }}`,
      spaced1: `ul\n  li {{ filterSpaced("6000000000) }}`,
    };
  },
};
