import {
  MAPPING,
} from "../../../components/Geschaeftsregel/Module/UiComponents";
import {
  MAPPING as KF_MAPPING,
} from "../../../components/KF/UiComponents";

export default {
  text: "ui-input",
  email: "ui-input",
  password: "ui-input",
  number: "ui-input",
  regex_config: "ui-input",
  color: "ui-input",
  float: "ui-float",
  currency: "ui-currency",
  numberrange: "ui-number-range",
  integer: "ui-input",
  decimal: "ui-input",
  math_expression: "ui-automatic",
  decimal_signal: "ui-automatic",
  decimal_objektkatalog_data: "ui-input-read-only",
  text_objektkatalog_data: "ui-input-read-only",
  decimal_katalog_data: "ui-input-read-only",
  text_katalog_data: "ui-input-read-only",
  textarea: "ui-textarea",
  select: "ui-select",
  selectordered: "ui-select-ordered",
  single_choice: "ui-select",
  single_choice_objektkatalog: "ui-select",
  multiselect: "ui-select",
  multi_select: "ui-select",
  select_objektkatalog: "ui-select",
  date: "ui-datepicker",
  date_expression: "ui-automatic",
  datetime: "ui-datepicker",
  time: "ui-datepicker",
  daterange: "ui-datepicker-range",
  richtext: "ui-ckeditor",
  textbaustein: "ui-textbaustein",
  checkbox: "ui-checkbox",
  radio: "ui-radiobutton",
  boolean: "ui-boolean",
  document: "ui-document",
  readonly: "ui-read-only",
  template: "ui-template",
  range: "ui-range",
  json: "ui-json",
  json_editor: "ui-json-editor",
  coordinates: "ui-coordinates",
  daymonth: "ui-day-month",
  iban: "ui-iban",
  rating: "ui-rating",
  str_mapping: "ui-str-mapping",
  dynamische_feldwertliste: "ui-dynamische-feldwertliste",
  one_checkbox: "ui-one-checkbox",
  form_definition: "ui-dynamische-form-definition",
  dynamische_mehrfachauswahl_anzeigen: "ui-select",
  validated_json: "ui-validated-json",
  tree: "ui-tree",
  user: "ui-read-only",

  ...MAPPING,
  ...KF_MAPPING,
};
