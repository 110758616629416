import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import {
  cloneDeep,
  get,
  forEach,
  isEmpty,
} from "lodash-es";

const SNAPSHOT_CHANGES_LIST = [
  "datum",
  "ende_datum",
];

// @vue/component
export default {
  name: "Datum",
  components: {
    SnapshotModule,
    SnapshotIcon,
  },
  mixins: [
    ModuleMixin,
  ],
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    return {
      filterDate,
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      let type;
      let placeholderTime;
      if (!this.modelParameter.uhrzeit) {
        type = "date";
      } else {
        type = "datetime";
        placeholderTime = "_TXT_GR_DATUM_PLACEHOLDER_TIME_";
      }
      OPTIONS.list = [
        {
          id: "datum",
          htmlId: `${ this.htmlRegelNummer }_datum`,
          type,
          required: this.modelParameter.pflichtfeld,
          label: this.startDateTextTranslate,
          placeholder: "_TXT_GR_DATUM_PLACEHOLDER_DATE_",
          placeholderTime,
          translate: true,
          view: "v-alt",
          editonly: true,
          width: "300px",
          startupFocus: true,
        },
      ];
      if (this.modelParameter.zeitraum) {
        OPTIONS.list.push({
          id: "ende_datum",
          htmlId: `${ this.htmlRegelNummer }_ende_datum`,
          type,
          label: this.endDateTextTranslate,
          placeholder: "_TXT_GR_DATUM_PLACEHOLDER_DATE_",
          placeholderTime,
          translate: true,
          required: this.modelParameter.pflichtfeld,
          view: "v-alt",
          editonly: true,
          width: "300px",
        });
      }
      return OPTIONS;
    },

    startDateTextTranslate() {
      if (this.modelParameter.zeitraum) {
        if (this.modelParameter.uhrzeit) {
          return "_TXT_GR_DATUM_ANFANGSDATUM_UHRZEIT_";
        }
        return "_TXT_GR_DATUM_ANFANGSDATUM_";
      }
      if (this.modelParameter.uhrzeit) {
        return "_TXT_GR_DATUM_DATUM_UHRZEIT_";
      }
      return "_TXT_GR_DATUM_DATUM_";
    },

    endDateTextTranslate() {
      if (!this.modelParameter.uhrzeit) {
        return "_TXT_GR_DATUM_ENDDATUM_";
      }
      return "_TXT_GR_DATUM_ENDDATUM_UHRZEIT_";
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        const MODEL = this.model || {};
        forEach(SNAPSHOT_CHANGES_LIST, key => {
          if (get(MODEL, key) !== get(this.snapshotModuleLocal, key)) {
            DIFF[key] = true;
          }
        });
      }
      return DIFF;
    },

    snapshotModuleLocal() {
      return this.snapshotModule || {};
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },
  },
  methods: {
    prepareModel() {
      this.model = cloneDeep(this.module.data) || {};
    },
  },
};
