import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";

import DashboardModuleMixin from "../DashboardModuleMixin";

// @vue/component
export default {
  name: "DashboardBaldZuErledigen",
  components: {
    AngularLink,
  },
  mixins: [
    DashboardModuleMixin,
  ],
  computed: {
    linkAttributesLocal() {
      const ATTRS = {
        class: this.classBox
      };
      if (this.anzahl) {
        ATTRS.sref = "root.termine";
        ATTRS["aria-labelledby"] = this.ariaLabelledbyForBadgeLink;
      }
      return ATTRS;
    },

    classBox() {
      return {
        disabled: !this.anzahl,
        warning: this.anzahl_mahnstufe > 0,
        info: !this.anzahl_mahnstufe
      };
    },

    tooltipBoxTranslate() {
      const TRANSLATE_OBJ = {};
      if (!this.anzahl) {
        TRANSLATE_OBJ.title = "_TXT_DASHBOARD_BALD_ZU_ERLEDIGEN_KEINE_TERMINE_";
      } else if (this.anzahl === 1) {
        TRANSLATE_OBJ.title = "_TXT_DASHBOARD_BALD_ZU_ERLEDIGEN_EIN_TERMIN_";
      } else {
        TRANSLATE_OBJ.title = "_TXT_DASHBOARD_BALD_ZU_ERLEDIGEN_TERMINE_{{anzahl}}_";
        TRANSLATE_OBJ.extra = {
          anzahl: this.anzahl,
        };
      }
      return TRANSLATE_OBJ;
    },

    anzahl() {
      return this.data && this.data.anzahl;
    },

    anzahl_mahnstufe() {
      return this.data && this.data.anzahl_mahnstufe;
    },

    componentLocal() {
      return this.anzahl ? "angular-link" : "div";
    },
  },
};
