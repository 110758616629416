import Modal from "../../../../../../../../libs/vue/client/vue/global/components/Modal/Modal.vue";
import PuxButton from "../../../../../../../../libs/vue/client/vue/global/components/PuxButton/PuxButton.vue";

import HttpMixin from "../../../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import NotificationMixin from "../../../../../../../../libs/vue/client/vue/global/mixins/NotificationMixin";

// @vue/component
export default {
  name: "HochschuleDetailsStandorteModalCreate",
  components: {
    Modal,
    PuxButton,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    standorteUrl: {
      type: String,
      required: true,
    },
    hochschule: {
      type: Object,
      required: true,
    },
    selectorClose: {
      type: String,
      required: true,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  emits: [
    "loadStandorte",
  ],
  data() {
    return {
      model: {},
      loading: false,
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "ort_bez",
            type: "text",
            label: "Ort",
            maxlength: 200,
            required: true,
            view: "v-alt",
          },
        ],
      },
      errors: undefined,
    };
  },
  methods: {
    save() {
      this.loading = true;
      this.postHttp({
        url: this.standorteUrl,
        data: {
          hochschule: this.hochschule.pk,
          ort_bez: this.model.ort_bez,
          ort_kbez: this.model.ort_bez,
        },
      }).then(
        () => {
          this.$emit("loadStandorte");
          this.close();
          this.addNotification({ text: "Standorte erfolgreich erstellt." });
        },
        errors => {
          delete errors.data.ort_kbez;
          this.addNotification({ text: "Ihre Daten sind fehlerhaft oder unvollständig.", type: "error" });
          this.onErrors(errors);
        }
      ).finally(
        () => {
          this.loading = false;
        }
      );
    },

    onErrors(errors) {
      this.errors = errors.data;
    },
  },
};
