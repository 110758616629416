import BetragsrechnerMixin from "../../Mixins/BetragsrechnerMixin";
import MdmRegionaleEffekteMixin from "../MdmRegionaleEffekteMixin";

// @vue/component
export default {
  name: "MdmRegionaleEffekte",
  mixins: [
    BetragsrechnerMixin,
    MdmRegionaleEffekteMixin
  ],
  created() {
    this.initErrorLabels();
  },
};
