"use strict";

import BaseModel from "dias/core/models/baseModel";


class PasswortNeu extends BaseModel {
  constructor() {
    super("auth/passwort_neu/");
  }
}

export default PasswortNeu;
