// Modul zur Anzeige und Eingabe eines Zuschusses
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";
import VertragModels from "dias/vertrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const zuschuss_mit_eingabe = angular.module("dias.kosten_finanzierung.modul.zuschuss_mit_eingabe", [
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
  VertragModels.name,
]);

zuschuss_mit_eingabe.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("zuschuss_mit_eingabe", "modul-zuschuss-mit-eingabe");
})

  .directive("modulZuschussMitEingabe", builder.directive(template, controller));

export default zuschuss_mit_eingabe;
