"use strict";

import parameterServices from "../services";
import template from "./config.jade";
import rotemplate from "./readonly.jade";
import controller from "./controller";

const parametermoduleDynamischeAdressen = angular.module("dias.foerderregeln.parameter.dynamische_adressen", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("dynamische_adressen", "parametermodul-dynamische-adressen");
  })
  .component("parametermodulDynamischeAdressen", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulDynamischeAdressenRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: rotemplate(),
    controller: controller,
    controllerAs: "vm",
  });
export default parametermoduleDynamischeAdressen;
