"use strict";

import BaseController from "dias/foerderregeln/parameter/config.base.controller";

class ParametermoduleController extends BaseController {
  /*@ngInject*/
  constructor() {
    super();
    this.orderedKeys = [];
    this.newSrc = undefined;
    this.newDest = undefined;
    this.parameter[this.key].wert = this.parameter[this.key].wert || {};
    this.updateOrderedKeys();
  }

  updateOrderedKeys() {
    this.orderedKeys = this.getOrderedKeys();
  }

  getOrderedKeys() {
    if (!this.parameter[this.key].wert) {
      return [];
    }
    return Object.keys(this.parameter[this.key].wert).sort();
  }

  add() {
    if (this.parameter[this.key].wert[this.newSrc] !== undefined) {
      return;
    }
    this.parameter[this.key].wert[this.newSrc] = this.newDest;
    this.newSrc = undefined;
    this.newDest = undefined;
    this.updateOrderedKeys();
  }

  remove(src) {
    if (this.parameter[this.key].wert[src] === undefined) {
      return;
    }
    delete this.parameter[this.key].wert[src];
    this.updateOrderedKeys();
  }
}

export default ParametermoduleController;
