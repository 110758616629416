"use strict";

import builder from "../utils/builder.js";

import BaseController from "../standard_sitzungsmodule.base.controller";

class ManuelleAntragslisteDetailController extends BaseController {
  /*@ngInject*/
  constructor($scope, $q) {
    super($scope, $q);
  }
}

export default builder.detail({
  template: "",
  controller: ManuelleAntragslisteDetailController,
  controllerAs: "vm"
});
