import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxErrors from "../../../../global/components/PuxErrors/PuxErrors.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import moment from "moment";
import {
  concat,
  get,
  isNil,
  omit,
  omitBy,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "ModalCreateOrEdit",
  components: {
    FormElementReadOnly,
    Modal,
    PuxButton,
    PuxCloak,
    PuxErrors,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
    NotificationMixin,
  ],
  props: {
    model: {
      type: Object,
      required: false,
      default: undefined,
    },
    organisation: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modelLocal: {
        organisation: undefined,
        rolle: undefined,
        frf_verwenden: true,
        frf_weiterreichen: false,
        gueltigkeit: {
          gueltigkeit_after: undefined,
          gueltigkeit_before: undefined,
        },
      },
      url: undefined,
      httpMehtod: undefined,
      status: {
        loading: undefined,
      },
      errors: undefined,
    };
  },
  computed: {
    translationTitle() {
      if (this.isCreateMode) {
        return "_HTML_FOERDERORGROLLEN_FREIGABE_MODAL_CREATE_HEADER_{{organisation}}_";
      }
      return "_HTML_FOERDERORGROLLEN_FREIGABE_MODAL_UPDATE_HEADER_{{organisation}}_{{rolle}}_";
    },

    translationExtra() {
      const EXTRA = {
        organisation: this.organisation.o_name,
      };
      if (!isNil(this.currentItem)) {
        EXTRA.rolle = this.model.rolle_bez || "_TXT_KEINE_ANGABE_";
      }
      return EXTRA;
    },

    modalOptions() {
      const OPTIONS = {
        showCloseButton: true,
        required: true,
        list: []
      };
      if (this.isCreateMode) {
        OPTIONS.list.push(this.rolleFormElement);
      }
      OPTIONS.list.push({
        id: "gueltigkeit",
        type: "daterange",
        label: "_LBL_FOERDERORGROLLEN_FREIGABE_GUELTIGKEIT_",
        helpText: "_TXT_FOERDERORGROLLEN_FREIGABE_GUELTIGKEIT_HELP_TEXT_",
        view: "v-alt",
        placeholderMin: "_LBL_DATERANGE_AB_HEUTE_",
        placeholderMax: "_LBL_DATERANGE_UNEINGESCHRAENKT_",
        disabledMin: this.isDisabledGueltigAb,
        disabledMax: this.isDisabledGueltigBis,
      }, {
        id: "frf_verwenden",
        type: "boolean",
        label: "_LBL_FOERDERORGROLLEN_FREIGABE_VERWENDEN_",
        helpText: "_TXT_FOERDERORGROLLEN_FREIGABE_VERWENDEN_HELP_TEXT_",
        required: false,
        translate: true,
        view: "v-alt",
      }, {
        id: "frf_weiterreichen",
        type: "boolean",
        label: "_LBL_FOERDERORGROLLEN_FREIGABE_WEITERREICHEN_",
        helpText: "_TXT_FOERDERORGROLLEN_FREIGABE_WEITERREICHEN_HELP_TEXT_",
        required: false,
        translate: true,
        view: "v-alt",
      });
      return OPTIONS;
    },

    baseUrl() {
      return `foerderorganisationen/${ this.organisation.pk }/rollenfreigaben/`;
    },

    isCreateMode() {
      return isNil(get(this.model, "pk"));
    },

    isDisabledGueltigAb() {
      return !this.isCreateMode
        && !isNil(this.model.frf_gueltig_ab)
        && moment().isSameOrAfter(this.model.frf_gueltig_ab);
    },

    isDisabledGueltigBis() {
      return !this.isCreateMode
        && !isNil(this.model.frf_gueltig_bis)
        && moment().isSameOrAfter(this.model.frf_gueltig_bis);
    },

    rolleFormElement() {
      const OPTIONS = {
        id: "rolle",
        type: "select",
        label: "_LBL_FOERDERORGROLLEN_FREIGABE_ROLLE_",
        helpText: "_TXT_FOERDERORGROLLEN_FREIGABE_ROLLE_HELP_TEXT_",
        required: true,
        showLabel: true,
        search: true,
        searchList: ["fro_kbez"],
        selectAll: true,
        deselectAll: true,
        view: "v",
        keyId: "pk",
        keyLabel: "fro_kbez",
        menuWidthAll: true,
        translate: true,
      };
      if (this.isCreateMode) {
        OPTIONS.url = `${ this.baseUrl }moegliche_rollen/`;
        OPTIONS.apiSaveId = `rollenfreigaben__moegliche_rollen__${ this.organisation.pk }`;
      } else {
        OPTIONS.data = [
          { pk: this.model.rolle, fro_kbez: this.model.rolle_bez }
        ];
      }
      return OPTIONS;
    },

    errorsLabelsOptions() {
      return [{
        id: "rolle",
        label: "_LBL_FOERDERORGROLLEN_FREIGABE_ROLLE_",
      }, {
        id: "foe_ids",
        label: "_LBL_FOERDERANGEBOT_PLURAL_",
      }, {
        id: "frf_anmerkung",
        label: "_LBL_FOERDERORGROLLEN_FREIGABE_ANMERKUNG_",
      }, {
        id: "gueltigkeit",
        htmlId: "gueltigkeit",
        label: "_LBL_FOERDERORGROLLEN_FREIGABE_GUELTIGKEIT_",
      }];
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (!this.isCreateMode) {
        this.modelLocal = {
          rolle: this.model.rolle,
          frf_verwenden: this.model.frf_verwenden,
          frf_weiterreichen: this.model.frf_weiterreichen,
          gueltigkeit: {
            gueltigkeit_after: this.model.frf_gueltig_ab,
            gueltigkeit_before: this.model.frf_gueltig_bis,
          },
        };
        this.httpMehtod = this.putHttp;
        this.url = `${ this.baseUrl }${ this.model.pk }/`;
      } else {
        this.httpMehtod = this.postHttp;
        this.url = this.baseUrl;
      }
    },

    getSaveData() {
      const DATA = omit(this.modelLocal, ["gueltigkeit"]);
      DATA.frf_gueltig_ab = get(this.modelLocal, "gueltigkeit.gueltigkeit_after");
      DATA.frf_gueltig_bis = get(this.modelLocal, "gueltigkeit.gueltigkeit_before");
      if (!isNil(DATA.frf_gueltig_ab) && moment().isSame(DATA.frf_gueltig_ab, "day")
          && !this.isDisabledGueltigAb
          && (this.isCreateMode || !moment().isSame(get(this.model, "frf_gueltig_ab"), "day"))) {
        DATA.frf_gueltig_ab = null;
      }
      if (!isNil(DATA.frf_gueltig_bis) && !this.isDisabledGueltigBis) {
        DATA.frf_gueltig_bis = moment(DATA.frf_gueltig_bis).endOf("day").format();
      }
      DATA.organisation = this.organisation.pk;
      return DATA;
    },

    save() {
      this.status.loading = true;
      this.httpMehtod({
        url: this.url,
        data: this.getSaveData(),
        showError: false,
      }).then(
        response => {
          if (this.isCreateMode) {
            this.addNotification({ text: "_MSG_FOERDERORGROLLEN_FREIGABE_MODAL_CREATE_SUCCESS_" });
          } else {
            this.addNotification({ text: "_MSG_FOERDERORGROLLEN_FREIGABE_MODAL_UPDATE_SUCCESS_" });
          }
          this.close({ response });
        },
        error => this.onError({ error: error.data })
      ).finally(
        () => this.status.loading = false
      );
    },

    onError({ error }) {
      error.gueltigkeit = concat(
        get(error, "frf_gueltig_ab", []),
        get(error, "frf_gueltig_bis", []),
      );
      this.errors = omit(
        omitBy(error, err => size(err) < 1),
        ["frf_gueltig_ab", "frf_gueltig_bis"]
      );
    }
  },
};
