// @vue/component
export default {
  name: "StyleguideTableOptionsFilters",
  data() {
    return {
      filter: [
        {
          label: "type",
          type: "String",
          default: "undefined",
          description: "Typ des Filters.",
          required: true,
          example: `type: "text",`,
          options: `"text", "select", "multuselect", "boolean", "daterange", "numberrange"`,
        },
        {
          label: "id",
          type: "String",
          default: "undefined",
          description: "Angabe der Id des Filtervalue.",
          required: true,
          example: `id: "a_titel",`,
          options: "",
        },
        {
          label: "label",
          type: "String",
          default: "undefined",
          description: "Anzeige des Filternamens.",
          required: true,
          example: `label: "Titel",`,
          options: "",
        },
        {
          label: "alwaysVisible",
          type: "Boolean",
          default: "undefined",
          description: "Einstellung ob der Filter zu beginn Sichtbar ist.",
          required: false,
          example: `alwaysVisible: true,`,
          options: "",
        },
        {
          label: "group",
          type: "String",
          default: "undefined",
          description: "Gruppe unter der der Filter angezeigt wird.",
          required: true,
          example: `group: this.$gettext("Antragsteller"),`,
          options: "",
        },
        {
          label: "placeholderMin",
          type: "String",
          default: `""`,
          description: "Zum Angeben von Wertspannen, nur bei 'daterange' und 'numberrange' Filtern.",
          required: false,
          example: `placeholderMin: "Beginn beliebig",`,
          options: "",
        },
        {
          label: "placeholderMax",
          type: "String",
          default: `""`,
          description: "Zum Angeben von Wertspannen, nur bei 'daterange' und 'numberrange' Filtern.",
          required: false,
          example: `placeholderMax: "Ende beliebig",`,
          options: "",
        },
        {
          label: "trueValue",
          type: "String",
          default: `"True"`,
          description: "True-Value bei einem 'boolean' Filter.",
          required: false,
          example: `trueValue: "True",`,
          options: "",
        },
        {
          label: "falseValue",
          type: "String",
          default: `"False"`,
          description: "False-Value bei einem 'boolean' Filter.",
          required: false,
          example: `falseValue: "False",`,
          options: "",
        },
        {
          label: "defaultLabel",
          type: "String",
          default: `"Egal"`,
          description: "Defaultlabel bei einem 'boolean' Filter.",
          required: false,
          example: `defaultLabel: "Nicht ausgewählt",`,
          options: "",
        },
        {
          label: "trueLabel",
          type: "String",
          default: `"Ja"`,
          description: "True-Label bei einem 'boolean' Filter.",
          required: false,
          example: `trueLabel: "Das ist trueLabel",`,
          options: "",
        },
        {
          label: "falseLabel",
          type: "String",
          default: `"Nein"`,
          description: "False-Label bei einem 'boolean' Filter.",
          required: false,
          example: `falseLabel: "Das ist falseLabel",`,
          options: "",
        },
        {
          label: "url",
          type: "String",
          default: "undefined",
          description: "Angabe der URL bei 'select' und 'multiselect' Filtern.",
          required: false,
          example: `url: "foerderantraege/regelsaetze/",`,
          options: "",
        },
        {
          label: "keyLabel",
          type: "String",
          default: `"label"`,
          description: "Angabe des Keylabel bei 'select' und 'multiselect' Filtern.",
          required: false,
          example: `keyLabel: "label",`,
          options: "",
        },
        {
          label: "keyId",
          type: "String",
          default: `"id"`,
          description: "Angabe der Key-ID bei 'select' und 'multiselect' Filtern.",
          required: false,
          example: `keyId: "value",`,
          options: "",
        },
        {
          label: "searchParameter",
          type: "String",
          default: "undefined",
          description: "Nur bei 'select' und 'multiselect' Filtern. Parameter der zum Suchen verwendet wird. ",
          required: false,
          example: `searchParameter: "bez", / Es kann zbs die 'id' verwendet werden.`,
          options: "",
        },
        {
          label: "searchGlobal",
          type: "String",
          default: "undefined",
          description: "Nur bei 'select' und 'multiselect' Filtern. " +
                      "Die Suche startet erst nach kompletter Eingabe und Bestätigung. " +
                      "Für die Suche wird die url und der searchParameter verwendet.",
          required: false,
          example: `searchGlobal: true,`,
          options: "",
        },
        {
          label: "emtyLabel",
          type: "String",
          default: "undefined",
          description: "Nur bei einem 'select' Filter.",
          required: false,
          example: `emtyLabel: "Das ist emtyLabel",`,
          options: "",
        },
        {
          label: "emtyValue",
          type: "String",
          default: "undefined",
          description: "Nur bei einem 'select' Filter.",
          required: false,
          example: `emtyValue: "Das ist emtyValue",`,
          options: "",
        },
        {
          label: "main",
          type: "String",
          default: "undefined",
          description: "Nur maximal einmal im 'Filters' Array und nur als 'text' Filter.",
          required: false,
          example: `main: true,`,
          options: "",
        },
        {
          label: "key",
          type: "String",
          default: "undefined",
          description: "Wenn zwei Filter dieselbe ID haben, werden diese mit einem Index versehen und das 'key' Attribut erhält den Value der urprünglichen ID.",
          required: false,
          example: "id1: abc, id2: abc ==> key1: abc, key2: abc, id1: abc1, id2: abc2",
          options: "",
        },
      ],
    };
  },
};
