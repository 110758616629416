"use strict";

import StandardFinanzierungController from "../../finanzierung.controller";

class ManuelleRueckforderungController extends StandardFinanzierungController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService) {
    super($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService);
    this.name = "ManuelleRueckforderungController";
    this.AuthService = AuthService;
    this.allowsInput = true;
    this.canView = false;
  }

  onArtLoaded() {
    let betrag;
    if (this.kontext !== "zuschuss_vertrag") {
      betrag = Number(this.get_antrag_betrag_value());
      this.readonly = true;
      this.canView = !Number.isNaN(betrag) && betrag !== 0;
    } else {
      betrag = this.get_vertrag_betrag_value();
      this.readonly = !this.AuthService.syncHasPerm("kofi.rueckforderung.create", this.obj.user_permissions);
      this.canView = (!Number.isNaN(betrag) && betrag !== 0) || !this.readonly;
    }
  }

  _process_zuschuss_vertrag_pos(result_pos) {
    const relevant_pos = result_pos.filter(vfp => Number(vfp.vfp_betrag_anerkannt) !== 0);
    this._setPositionen(relevant_pos);
  }

  getDefaultBetragsrechner() {
    return "manuelle_rueckforderungen";
  }

  showErrorLabel(field) {
    if (field === "kommentar") {
      return "Kommentar: ";
    }
    if (field === "grund") {
      return "Begründung: ";
    }
    if (field === "wert") {
      return "Betrag in EUR: ";
    }
  }
}

export default ManuelleRueckforderungController;
