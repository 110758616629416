"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";

class FoerderdokumenteEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, Foerderdokument) {
    super($q, $scope, DiasNotification);
    this.Foerderdokument = Foerderdokument;

    this.dokumente = [];
    this.dokumenteObj = {};
    this.dokumenteList = [];

    this.loadDokumente();

    this.save = () => {
      return $scope.model.customPOST({ foerderdokument: this.dokumenteList }).then(
        response => {
          $scope.model.merge($scope.model, response);
          this.DiasNotification.page.success("Änderungen erfolgreich gespeichert.");
        },
        errors => {
          return this.onErrors(errors, {});
        }
      );
    };
  }

  loadDokumente() {
    this.loading = true;
    this.Foerderdokument.getList().then(
      
      response => {
        this.loading = false;
        this.dokumente = response;

        this.initDokumenteObj();
        this.initModel();
      }
    );
  }

  initDokumenteObj() {
    angular.forEach(this.dokumente, dok => {
      this.dokumenteObj[dok.pk] = dok;
    });
  }

  initModel() {
    angular.forEach(this.model, mod => {
      this.dokumenteList.push(mod.dokument.pk);
      for (let i = 0; i < this.dokumente.length; i++) {
        if (this.dokumente[i].pk === mod.dokument.pk) {
          this.dokumente.splice(i, 1);
          break;
        }
      }
    });
  }

  selectDokument() {
    this.dokumenteList.push(this.modelDokumente);

    for (let i = 0; i < this.dokumente.length; i++) {
      if (this.dokumente[i].pk === this.modelDokumente) {
        this.dokumente.splice(i, 1);
        break;
      }
    }

    this.modelDokumente = null;
  }

  deleteDokument(pk, index) {
    this.dokumente.push(this.dokumenteObj[pk]);
    this.dokumenteList.splice(index, 1);
  }

  moveUp(pk, index) {
    if (index > 0) {
      this.dokumenteList.splice(index, 1);
      this.dokumenteList.splice(index - 1, 0, pk);
    }
  }

  moveDown(pk, index) {
    if (index < this.dokumenteList.length - 1) {
      this.dokumenteList.splice(index, 1);
      this.dokumenteList.splice(index + 1, 0, pk);
    }
  }
}

export default FoerderdokumenteEditController;
