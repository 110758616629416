import CsvDownload from "../../../components/CsvDownload/CsvDownload.vue";
import FormElement from "../../../components/FormElement/FormElement.vue";
import Modal from "../../../components/Modal/Modal.vue";
import PuxButton from "../../../components/PuxButton/PuxButton.vue";
import PuxDropdown from "../../PuxDropdown/PuxDropdown.vue";
import PuxTemplateDownload from "../../../components/PuxTemplateDownload/PuxTemplateDownload.vue";
import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";
import TableModalWorkflowMassActionsClientFunction from "../TableModalWorkflowMassActionsClientFunction/TableModalWorkflowMassActionsClientFunction.vue";
import TableModalWorkflowMassActionsConfirm from "../TableModalWorkflowMassActionsConfirm/TableModalWorkflowMassActionsConfirm.vue";
import TableModalWorkflowMassActionsTaskLog from "../TableModalWorkflowMassActionsTaskLog/TableModalWorkflowMassActionsTaskLog.vue";
import TablePanelActionsButton from "../TablePanelActionsButton/TablePanelActionsButton.vue";
import TablePanelActionsLink from "../TablePanelActionsLink/TablePanelActionsLink.vue";
import TablePanelActionsList from "../TablePanelActionsList/TablePanelActionsList.vue";

import translate from "../../../directives/translate";

import HttpMixin from "../../../mixins/HttpMixin";

import GlobalOptions from "../../../const/GlobalOptions";
import TableActionsMapping from "../TableActionsMapping";
import {
  EventBus,
} from "../../../functions/event-bus";
import {
  createNamespacedHelpers,
} from "vuex";
import {
  cloneDeep,
  filter,
  forEach,
  isFunction,
  isUndefined,
  map,
  sortBy,
} from "lodash-es";

const {
  mapMutations,
  mapGetters,
} = createNamespacedHelpers("table");

// @vue/component
export default {
  name: "TablePanelActions",
  components: {
    CsvDownload,
    FormElement,
    Modal,
    PuxButton,
    PuxDropdown,
    PuxTemplateDownload,
    PuxTranslate,
    TableModalWorkflowMassActionsClientFunction,
    TableModalWorkflowMassActionsConfirm,
    TableModalWorkflowMassActionsTaskLog,
    TablePanelActionsButton,
    TablePanelActionsLink,
    TablePanelActionsList,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    loading: {
      type: Boolean,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    optionsClone: {
      type: Object,
      default: () => ({}),
    },
    sortOptions: {
      type: Object,
      default: () => ({}),
    },
    cols: {
      type: Array,
      default: () => [],
    },
    colsInitModel: {
      type: Array,
      default: () => [],
    },
    colsInitModelDefault: {
      type: Array,
      default: () => [],
    },
    csv: {
      type: Object,
      default: () => ({}),
    },
    workflowfolgen: {
      type: Array,
      default: () => [],
    },
    sortTable: {
      type: Function,
      default: () => {},
    },
    changeView: {
      type: Function,
      default: () => {},
    },
    changeModelConfigurator: {
      type: Function,
      default: () => {},
    },
    reloadTableWithWorkflow: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      idLabel: GlobalOptions.tableOptions.idLabel,
      TableActionsMapping: TableActionsMapping,
      isCsv: GlobalOptions.tableOptions.csv,
      isButtonSorting: GlobalOptions.tableOptions.buttonSorting,
      optionsSortSelect: {
        id: "sort",
        type: "select",
        keyId: "id",
        keyLabel: "label",
        label: "Sortieren nach",
        deselect: false,
        view: "v",
      },
      model: {
        sort: "",
        cols: [],
      },
      status: {
        loadingConfigurator: undefined,
        modal: undefined,
        modalWorkflowMassActions: undefined,
        modalWorkflowMassActionsTaskLog: undefined,
        modalWorkflowMassActionsConfirm: undefined,
      },
      modalOptions: {
        showCloseButton: true,
      },
      multipleActionObj: undefined,
      multipleActionObjData: undefined,
      statusMultipleActionWorkflow: undefined,
      modalWorkflowMassActionsData: undefined,
      pks: [],
      templatesLoading: false,
      templateFilterParams: {},
      templateDownloadData: [],
    };
  },
  computed: {
    showViewChangeButton() {
      return this.optionsClone.view.symbol && this.optionsClone.view.table;
    },

    getMultipleActionWorkflowDropdownLabel() {
      return `${ this.multipleActionObj.label }: Mehrfachaktion wählen`;
    },

    getSortedWorkflowfolgen() {
      return sortBy(this.workflowfolgen, ["wfo_pos"]);
    },

    getActions() {
      return filter(this.options.actions, item => {
        if (isFunction(item.isHidden)) {
          return !item.isHidden();
        }
        return !item.isHidden;
      });
    },

    optionsTableConfigSelect() {
      return {
        keyId: "id",
        keyLabel: "label",
        id: `table_configurator_${ this.options.id }`,
        type: "multiselect",
        fixedPlaceholder: `<i class="glyphicon glyphicon-cog font-size-xs absolute-center-center"></i>`,
        closeClick: false,
        showCaret: false,
        menuRight: true,
        menuStyles: "width: 270px;",
        buttonClass: "btn btn-primary table_configurator",
        title: "_TITLE_CONFIGURATOR_TABLE_COLUMNS_",
        separator: true,
        menuWidthAll: false,
        search: true,
        searchList: ["label"],
        dataTranslate: true,
      };
    },

    idForTableConfigLabel() {
      return `${ this.optionsTableConfigSelect.id }_label`;
    },

    isSelectedRows() {
      return map(this.GET_MULTIPLE_MODEL({ tableId: this.options.id }), "pk").length;
    },

    multipleActionButtonCls() {
      return this.multipleActionObj.css_klassen || "btn-primary";
    },

    urlLocal() {
      return this.options.baseUrl || this.options.url;
    },

    ...mapGetters([
      "GET_CONFIG",
      "GET_MULTIPLE_MODEL",
      "GET_MULTIPLE_MODEL_COUNT",
    ]),
  },
  created() {
    this.initEventBus();
    this.initSort();
    this.initConfigurator();
    this.initTemplates();
  },
  beforeUnmount() {
    if (this.multipleActionObj) {
      EventBus.$emit(`cancelMassActions${ this.options.id }`);
    }
    EventBus.$off(`changeSort${ this.options.id }`, this.changeModelSort);
    EventBus.$off(`cancelMassActions${ this.options.id }`, this.cancelMassActions);
  },
  methods: {
    initEventBus() {
      EventBus.$on(`changeSort${ this.options.id }`, this.changeModelSort);
      EventBus.$on(`cancelMassActions${ this.options.id }`, this.cancelMassActions);
    },

    changeModelSort({ id } = {}) {
      this.model.sort = id;
    },

    initSort() {
      if (!this.options.sortable || !this.sortOptions.id) {
        return;
      }
      let sortModel = this.sortOptions.id;
      if (!isUndefined(sortModel)) {
        if (this.sortOptions.asc) {
          sortModel = sortModel.replace("-", "");
        } else if (sortModel[0] !== "-") {
          sortModel = `-${ sortModel }`;
        }
      }

      this.MUT_SET_SORT_MODEL({
        tableId: this.options.id,
        sortModel: sortModel,
      });
      this.model.sort = this.sortOptions.id;
    },

    setConfiguratorDefault() {
      if (this.status.loadingConfigurator) {
        return;
      }
      this.status.loadingConfigurator = true;
      this.model.cols = cloneDeep(this.colsInitModelDefault);
      this.changeConfigurator();
    },

    initConfigurator() {
      if (!this.optionsClone.showColsConfig) {
        return;
      }
      this.status.loadingConfigurator = true;
      this.model.cols = cloneDeep(this.colsInitModel);
      this.changeConfigurator();
    },

    initTemplates() {
      if (this.options.template && this.options.template.obj_url && this.options.template.obj_id) {
        this.templatesLoading = true;
        this.getHttp({ url: `${ this.options.template.obj_url }/${ this.options.template.obj_id }/templates/` }).then(
          response => {
            const TEMPLATE_DATA = [];
            forEach(response, value => {
              TEMPLATE_DATA.push({ url: `${ this.options.template.obj_url }/${ this.options.template.obj_id }/render_template/?tpl=${ value.id }`,
                                   label: value.bez,
                                   title: `${ value.bez } herunterladen` });
            });
            this.templateDownloadData = TEMPLATE_DATA;
          }
        ).finally(
          () => this.templatesLoading = false
        );
      }
    },

    changeSort() {
      this.MUT_SET_SORT_MODEL({
        tableId: this.options.id,
        sortModel: this.model.sort,
      });
      this.sortTable(this.model.sort);
    },

    changeConfigurator() {
      this.changeModelConfigurator(this.model.cols);
      if (!this.options.id || !this.optionsClone.user || !GlobalOptions.tableOptions.configurationSave) {
        this.status.loadingConfigurator = false;
        return;
      }

      const config = this.GET_CONFIG(this.options.id);

      const data = {
        ntc_kennung: config.ntc_kennung || this.options.id,
        ntc_conf: cloneDeep(this.model.cols),
        ntc_anzahl: config.ntc_anzahl,
      };

      this.MUT_SET_CONFIG({
        tableId: this.options.id,
        ntc_kennung: config.ntc_kennung || this.options.id,
        ntc_conf: cloneDeep(this.model.cols),
      });

      if (config.id) {
        this.putHttp({ url: `nutzertabellenconfigs/${ config.id }/`, data }).then(
          () => {
            this.status.loadingConfigurator = false;
          }
        );
      } else {
        this.postHttp({ url: "nutzertabellenconfigs/", data }).then(
          response => {
            this.MUT_SET_CONFIG({
              tableId: this.options.id,
              configId: response[this.idLabel],
            });
            this.status.loadingConfigurator = false;
          }
        );
      }
    },

    checkAllCols() {
      this.status.loadingConfigurator = true;
      this.model.cols = [];
      forEach(this.cols, col => {
        this.model.cols.push(col.id);
      });
      this.changeConfigurator();
    },

    onMultipleActions({ item }) {
      this.multipleActionObj = item;
      if (item.msw_kbez) {
        this.statusMultipleActionWorkflow = true;
        this.reloadTableWithWorkflow({ item });
      }
      this.MUT_TOGGLE_MULTIPLE_STATUS({ tableId: this.options.id, status: true, });
    },

    onMultipleActionsWorkflow({ item }) {
      $("body").click();
      const rows = this.GET_MULTIPLE_MODEL({ tableId: this.options.id });
      this.pks = map(rows, "pk");
      this.modalWorkflowMassActionsObj = item;
      if (this.modalWorkflowMassActionsObj.clientfunktion && this.modalWorkflowMassActionsObj.clientfunktion.modulname === "simple") {
        this.multipleActionObjData = {
          objects: this.pks,
          workflowfolge: item.pk,
        };
        this.multipleActionObj = {
          label: item.wfo_kbez,
          workflowFunction: true,
        };
        this.status.modalWorkflowMassActionsConfirm = true;
      } else {
        this.status.modalWorkflowMassActions = true;
      }
    },

    closeModalWorkflowMassActions({ data, multipleActionObj } = {}) {
      if (data) {
        this.multipleActionObjData = data;
        this.multipleActionObj = multipleActionObj;
        this.status.modalWorkflowMassActionsConfirm = true;
      }
      this.status.modalWorkflowMassActions = false;
    },

    onClickMultipleAction() {
      const rows = this.GET_MULTIPLE_MODEL({ tableId: this.options.id });
      this.pks = map(rows, "pk");
      if (this.multipleActionObj.modal) {
        this.multipleActionObj.callback({ rows, pks: this.pks });
      } else {
        this.status.modalWorkflowMassActionsConfirm = true;
      }
    },

    continueMassActions() {
      if (this.multipleActionObj.workflowFunction) {
        this.continueMultipleActionWorkflow();
      } else {
        this.continueMultipleAction();
      }
    },

    async continueMultipleAction() {
      const rows = this.GET_MULTIPLE_MODEL({ tableId: this.options.id });
      const pks = map(rows, "pk");
      await this.multipleActionObj.callback({ rows, pks });

      this.cancelMassActions({ status: true });
    },

    continueMultipleActionWorkflow() {
      this.status.modalWorkflowMassActionsConfirm = false;
      setTimeout(() => {
        this.status.modalWorkflowMassActionsTaskLog = true;
      });
    },

    cancelMassActions({ status } = {}) {
      this.closeModalWorkflowMassActionsConfirm();
      this.multipleActionObj = undefined;
      this.MUT_TOGGLE_MULTIPLE_STATUS({ tableId: this.options.id, status: false, });
      this.MUT_CLEAN_MULTIPLE_MODEL({ tableId: this.options.id });
      EventBus.$emit(`clearMultipleModel${ this.options.id }`);
      if (status) {
        EventBus.$emit(`updateTable${ this.options.id }`);
      }
    },

    closeModalWorkflowMassActionsConfirm() {
      this.status.modalWorkflowMassActionsConfirm = false;
    },

    closeModalWorkflowMassActionsTaskLog() {
      this.multipleActionObjData = undefined;
      this.cancelMassActions();
      this.status.modalWorkflowMassActionsTaskLog = false;
      this.reloadTableWithWorkflow();
    },

    cancelMultipleAction() {
      this.cancelMassActions();
    },

    cancelMultipleActionWorkflow() {
      this.statusMultipleActionWorkflow = undefined;
      this.cancelMassActions();
      this.reloadTableWithWorkflow();
    },

    ...mapMutations([
      "MUT_SET_CONFIG",
      "MUT_SET_SORT_MODEL",
      "MUT_TOGGLE_MULTIPLE_STATUS",
      "MUT_CLEAN_MULTIPLE_MODEL",
    ]),
  },
};
