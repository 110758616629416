"use strict";

import template from "./gutachten.ergebnisauswahl.jade";

class DiasGutachtenErgebnisauswahlController {
  /*@ngInject*/
  constructor($q, Katalog) {
    this.Katalog = Katalog;
    this.$q = $q;
    this.loading = {
      ergebnisse: true,
      gruppe: true,
      auswahl: true
    };
    this.readonly = (this.readonly === undefined) || this.readonly;
    this.init();
  }

  init() {
    let ergebnisgruppe;
    if (this.model) {
      ergebnisgruppe = this.model.ergebnisgruppe;
    }
    this.$q.all([
      this.Katalog.getKatalog("kgutachtenergebnisse").then(
        response => this.ergebnisse = response
      ),
      this.Katalog.getKatalog("kgutachtenergebnisgruppen", { pk: ergebnisgruppe }).then(
        response => this.gruppe = response[0]
      ),
      this.Katalog.getKatalog("kgutachtenergebnisauswahl", { gruppe: ergebnisgruppe }).then(
        response => this.auswahl = response
      )
    ]).finally(() => this.loading = false);
  }

  getElementCssKlasse(gaa) {
    let styleKlasse = "";
    const aktivKlasse = (this.model && this.model.ergebnis === gaa.pk) ? "active" : "";
    if (this.ergebnisse) {
      const e = this.ergebnisse.find(e => e.pk === gaa.ergebnis);
      if (e) {
        styleKlasse = e.gae_cssklasse;
      }
    }
    return `${ styleKlasse } ${ aktivKlasse }`;
  }

  selectAuswahl(gaa) {
    if (this.readonly || !this.model) {
      return;
    }
    this.model.ergebnis = gaa.pk;
  }

  getSelectedAuswahl() {
    if (!this.auswahl) {
      return;
    }
    return this.auswahl.find(gaa => this.model && this.model.ergebnis === gaa.pk);
  }
}

export default {
  template: template(),
  controller: DiasGutachtenErgebnisauswahlController,
  controllerAs: "vm",
  bindings: {
    model: "<ngModel",
    readonly: "<?",
    minimal: "<?",
    enforceStyling: "<?"
  }
};
