import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

// @vue/component
export default {
  name: "PersonalModalReactivate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    personal: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      status: {
        loading: undefined,
      },
      errors: undefined
    };
  },
  computed: {
    translationTitle() {
      return "_HTML_PERSONAL_LIST_MODAL_REACTIVATE_HEADER_{{name}}_{{o_name}}_";
    },
    translationBody() {
      return "_HTML_PERSONAL_LIST_MODAL_REACTIVATE_BODY_{{name}}_{{o_name}}_";
    },
    translateExtra() {
      return {
        name: this.personal.name,
        o_name: this.personal.o_name,
      };
    },
  },
  methods: {
    reactivate() {
      this.status.loading = true;
      this.postHttp({
        url: `personal/`,
        data: { orgnutzer: this.personal.orgnutzer },
        fullResponse: true,
        showError: false,
      }).then(
        response => {
          this.addNotification({ text: "_MSG_PERSONAL_REACTIVATE_SUCCESS_" });
          this.close({ response });
        },
        error => {
          this.errors = error.data;
        }
      ).finally(
        () => this.status.loading = false
      );
    },
  },
};
