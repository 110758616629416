import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import { get, isEmpty, } from "lodash-es";

// @vue/component
export default {
  name: "DokumentSelectItem",
  components: {
    PuxTranslate,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    belegDokumenteAnzahl: {
      type: Object,
      required: false,
      info: "Mapping dok_id zur Anzahl der Belege, in der dieses Dokument benutzt wird",
      default: () => ({}),
    }
  },
  computed: {
    dokumentAnzahlUebersetzung() {
      if (get(this.belegDokumenteAnzahl, this.data.pk)) {
        return {
          tag: "span",
          text: "_TXT_BELEGDOKUMENT_ZUGEORDNET_{{anzahl}}_",
          extra: { anzahl: get(this.belegDokumenteAnzahl, this.data.pk) },
        };
      }
      return { tag: "span", text: "_TXT_BELEGDOKUMENT_NICHT_ZUGEORDNET_" };
    },

    elementLabel() {
      if (!get(this.data, "dokument.dok_titel")) {
        return;
      }
      return `${ this.data.dokument.dok_titel }`;
    },

    isBelegDokumentAnzahl() {
      return !isEmpty(this.belegDokumenteAnzahl);
    },
  },
};
