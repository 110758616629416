import AnkuendigungBasicInformation from "./AnkuendigungBasicInformation/AnkuendigungBasicInformation.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../global/compositionAPI/NotificationAPI";

import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "AnkuendigungDetails",
  components: {
    AnkuendigungBasicInformation,
    ContextPanel,
    Modal,
    PuxButton,
    PuxPageDetails,
    PuxTranslate,
  },
  setup() {
    const {
      deleteHttp,
      getHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      deleteHttp,
      getHttp,
    };
  },
  data() {
    return {
      loading: true,
      ankuendigung: undefined,
      confirmShow: undefined,
      confirmOptions: undefined,
      buttonDeleteId: "akuendugung_modal_confirm_delete",
      panelOption: {
        label: "_TXT_ANKUENDIGUNG_DETAILS_ANKUENDIGUNG_PANEL_NAME_",
        icon: "glyphicon-star",
        active: true,
      },
    };
  },
  computed: {
    selectorClose() {
      return `#${ this.buttonDeleteId }`;
    },

    newsUrl() {
      return `news/${ this.$stateParams.id }/`;
    },
  },
  created() {
    this.loadAnkuendigung();
  },
  methods: {
    loadAnkuendigung() {
      this.getHttp({
        url: this.newsUrl,
      }).then(
        response => {
          this.setAnkuendigung({ response });
          this.loading = false;
        }
      );
    },

    setAnkuendigung({ response }) {
      const ANKUENDIGUNG = response;
      ANKUENDIGUNG.rollen_ids = [];
      forEach(ANKUENDIGUNG.rollen, rolle => {
        ANKUENDIGUNG.rollen_ids.push(rolle.pk);
      });
      this.ankuendigung = ANKUENDIGUNG;
    },

    updateAnkuendigung({ ankuendigung }) {
      this.ankuendigung = ankuendigung;
    },

    openModalDeleteAnkuendigung() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_ANKUENDIGUNG_DETAILS_DELETE_MODAL_HEADER_",
        msg: "_TXT_ANKUENDIGUNG_DELETE_MODAL_BODY_{{titel}}_",
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: () => this.deleteAnkuendigung(),
        cancelCallback: this.closeConfirmAnkuendigung,
        extra: {
          titel: this.ankuendigung.nws_titel,
        },
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteAnkuendigung() {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: this.newsUrl,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_ANKUENDIGUNG_DETAILS_DELETE_{{titel}}_",
            extra: {
              titel: this.ankuendigung.nws_titel,
            },
          });
          this.closeConfirmAnkuendigung();
          this.$goTo("root.news");
        }
      ).then(
        () => {
          this.confirmOptions.loading = false;
        }
      );
    },

    closeConfirmAnkuendigung() {
      this.confirmShow = false;
    },
  },
};
