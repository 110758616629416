"use strict";

import frageTemplate from "./frage.details.jade";
import createAntwortController from "./modal.create.antwort.controller.js";
import createAntwortTemplate from "./modal.create.antwort.template.jade";
import editFrageController from "./editFrage.controller.js";

class FoerderfinderFrageController {
  /*@ngInject*/
  constructor(
    $q, 
    $stateParams, 
    Foerderfinderfragen, 
    diasModalDialog,
    diasConfirmDialog, 
    Foerderfinderantworttypen, 
    DiasNotification
  ) {
    this.Foerderfinderfragen = Foerderfinderfragen;
    this.$stateParams = $stateParams;
    this.diasModalDialog = diasModalDialog;
    this.diasConfirmDialog = diasConfirmDialog;
    this.notification = DiasNotification.page;
    this.antwortenTableDeferred = $q.defer();
    this.antwortenTable = this.antwortenTableDeferred.promise;
    this.getDetailRoute = row => "root.foerderfinderfragen.detail.antwort({id: '" + row.frage + "', ffa_id: '" + row.pk + "'})";
    this.editFrageController = editFrageController;
    this.loading = true;
    this.loadFrage();
    this.reloadPage = () => {
      this.antwortLastChange = new Date().getTime();
    };
  }

  loadFrage() {
    return this.Foerderfinderfragen.one(this.$stateParams.id).get().then(
      response => {
        this.frage = response;
        this.resolveAntwortenTable();
        this.loading = false;
      }
    );
  }

  resolveAntwortenTable() {
    this.antwortenTableDeferred.resolve({
      dataSource: this.frage.antworten.getList,
      tableTitle: "Antworten",
      getDetailRoute: this.getDetailRoute,
      columnDefs: [
        { label: "Text", field: "ffa_text", sortable: true, sortingDefault: true, sortingDefaultDirection: "asc" },
        { label: "Position", field: "ffa_pos", sortable: true, sortingDefault: true, sortingDefaultDirection: "asc" },
        { label: "Erklärung", field: "ffa_erklaerung" },
        { label: "Antwort-Typ", field: "typ_obj.fft_kbez" },
        { label: "Folgefrage", field: "folgefrage_bez" },
      ],
      tableActions: [
        { label: "Neue Antwortmöglichkeit erstellen",
          icon: "glyphicon glyphicon-plus", primary: true,
          callback: () => this.diasModalDialog({
            title: "Antwort erstellen",
            template: createAntwortTemplate,
            controller: createAntwortController
          })
        }
      ],
      actions: [
        { label: "löschen",
          isVisible: true,
          callback: this.deleteAntwort.bind(this) },
      ],
      isDirty: scope => {
        return this.antwortLastChange > scope.vm.lastRefresh;
      }
    });
  }

  deleteAntwort(row) {
    this.diasConfirmDialog({
      titleTemplate: "Förderfinderantwort {{ antwort.ffa_text }} löschen",
      contentTemplate: "<p>Sind Sie sicher, dass Sie die Antwort löschen wollen?</p>",
      context: { frage: row },
      okLabel: "Anwort löschen",
      okCallback: () => this.frage.antworten.one(row.pk).remove().then(
        () => {
          this.notification.success("Antwort wurde gelöscht.");
          this.reloadPage();
        },
        () => this.notification.error("Antwort konnte nicht gelöscht werden.")
      )
    });
  }
}

export default {
  template: frageTemplate(),
  controller: FoerderfinderFrageController,
  controllerAs: "vm"
};
