"use strict";

export default /*@ngInject*/ () => {
  return {
    restrict: "A",
    link: (scope, el) => {
      el.find("header").attr("tabindex", 0);
      el.find("header").attr("role", "button");
      el.find("header").on("click keypress", e => {
        if (e.keyCode !== undefined && e.keyCode !== 13 && e.keyCode !== 32) {
          return;
        }
        el.toggleClass("opened");
        e.preventDefault();
      });
    }
  };
};
