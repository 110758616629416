import HttpMixin from "../../../../../global/mixins/HttpMixin";
import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";
import ModalCreateOrUpdate from "./ModalCreateOrUpdate/ModalCreateOrUpdate.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import {
  toFormElementFromParameter,
  initModelFromList,
} from "../../../../../global/functions/mappingForParameterToFormElement";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "UiAuszahlungsmodi",
  components: {
    ModalCreateOrUpdate,
    PuxButton,
    PuxIcon,
  },
  mixins: [
    HttpMixin,
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Array,
      required: true,
    },
    errors: {
      type: [Object, Array],
      default: undefined,
    },
    updateErrors: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modelDefault: {},
      confirmShow: undefined,
      confirmOptions: undefined,
      listConfig: [],
      errorsLocal: {},
      statusModal: undefined,
      currentModel: undefined,
      currentIndex: undefined,
      changesObj: {},
    };
  },
  watch: {
    errors: {
      handler() {
        if (this.errors && this.errors.length) {
          try {
            this.errorsLocal = JSON.parse(this.errors[0]) || {};
            this.updateErrors({ errors: ["Beim Speichern ist ein Fehler aufgetreten. Schauen Sie bitte in die Details."], id: this.options.id });
          } catch (SyntaxError) {
            this.errorsLocal = this.errors[0] || {};
          }
        }
      },
      deep: true
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.setList();
    this.setModelFromList();
  },
  methods: {
    setList() {
      this.listConfig = toFormElementFromParameter({ obj: this.options.konfig, showLabel: true }).list;
    },

    setModelFromList() {
      this.modelDefault = initModelFromList({ list: this.listConfig });
    },

    openModalCreate() {
      this.statusModal = true;
    },

    openModalUpdate({ item, index }) {
      this.currentIndex = index;
      this.currentModel = item;
      this.statusModal = true;
    },

    closeModal({ model } = {}) {
      if (model) {
        this.onInput_mixin({ value: model });
      }
      this.currentIndex = undefined;
      this.currentModel = undefined;
      this.statusModal = false;
    },

    moveUpFeld(index) {
      if (index === 0) {
        return;
      }
      const MODEL = cloneDeep(this.model);

      const ELEM = MODEL[index];
      const INDEX2 = index - 1;
      MODEL.splice(index, 1);
      MODEL.splice(INDEX2, 0, ELEM);
      this.setChangesObj({ index1: index, index2: INDEX2 });
      this.onInput_mixin({ value: MODEL });
    },

    moveDownFeld(index) {
      if (index >= this.model.length - 1) {
        return;
      }
      const MODEL = cloneDeep(this.model);
      const ELEM = MODEL[index];
      const INDEX2 = index + 1;
      MODEL.splice(index, 1);
      MODEL.splice(index + 1, 0, ELEM);
      this.setChangesObj({ index1: index, index2: INDEX2 });
      this.onInput_mixin({ value: MODEL });
    },

    setChangesObj({ index1, index2 }) {
      this.changesObj[index1] = true;
      this.changesObj[index2] = true;
    },

    openConfirmDelete(index) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "Auszahlungsmodularität entfernen",
        msg: "<p>Sind Sie sicher, dass sie die Auszahlungsmodularität entfernen wollen?</p>",
        okLabel: "Fortfahren",
        okCallback: () => this.deleteFeld(index),
        cancelCallback: this.closeConfirmDelete,
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteFeld(index) {
      const MODEL = cloneDeep(this.model);
      MODEL.splice(index, 1);
      this.onInput_mixin({ value: MODEL });
      this.closeConfirmDelete();
    },

    closeConfirmDelete() {
      this.confirmShow = false;
    },
  },
};
