import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import AuszahlungBetragBoxBetragAndDifferenzMixin from "./AuszahlungBetragBoxBetragAndDifferenzMixin";

// @vue/component
export default {
  name: "AuszahlungBetragBoxBetragAndDifferenz",
  components: {
    PuxTranslate,
  },
  mixins: [
    AuszahlungBetragBoxBetragAndDifferenzMixin,
  ],
  props: {
    auszahlung: {
      type: Object,
      required: true,
    },
    betrag: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    isModalitaetLast: {
      type: Boolean,
      required: true,
    },
  },
};
