"use strict";

export default /*@ngInject*/ $filter => {
  return {
    restrict: "A",
    require: "ngModel",
    link: function(scope, element, attrs, ngModel) {
      if (!ngModel) {
        return;
      }

      ngModel.$formatters.push(value => {
        if (!value) {
          return;
        }

        return $filter("iban")(value, true);
      });
    }
  };
};
