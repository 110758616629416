import ModuleMixin from "../../../Geschaeftsregel/Module/ModuleMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";
import {
  FilterCurrencyMixin,
} from "../../../../global/mixins/FiltersMixin";
import {
  EventBus,
} from "../../../../global/functions/event-bus";
import {
  forEach,
  get,
  isEmpty,
  cloneDeep,
  values,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  mixins: [
    ModuleMixin,
    FilterCurrencyMixin,
    SyConfigMixin,
  ],
  props: {
    statusKFDiff: {
      type: Boolean,
      required: true,
      info: "Status aus KFPlan",
    },
    updateInfoprovider: {
      type: Function,
      required: true,
      info: "update Infoprovider im Antrag",
    },
  },
  data() {
    return {
      loading: false,
      reloading: false,
      antragKoFiArt: {
        bez: "",
        summe: 0,
      },
      positionen: [],
      katalogDetails: [],
      editType: "standard",
      infoproviderIsUpdating: false,
    };
  },
  computed: {
    betragsrechner() {
      return this.modelParameter.betragsrechner || "standard_kostenrechner";
    },

    extraKF() {
      return {
        regel: this.regel,
        regeldata: this.module.data,
        modelParameter: this.modelParameter,
      };
    },

    statusShowProzente() {
      return false;
    },

    summe() {
      let summe = this.antragKoFiArt.summe;
      if (this.infoprovider &&
        this.infoprovider.details &&
        this.infoprovider.details[this.regel.afr_id] &&
        !isNil(this.infoprovider.details[this.regel.afr_id].betrag)) {
        summe = this.infoprovider.details[this.regel.afr_id].betrag;
      }
      return summe || 0;
    },

    summeCurrency() {
      return this.filterCurrency(this.summe);
    },

    prozent() {
      return 0;
    },

    ariaDescribedbyActual() {
      if (this.extra && this.extra.headerActualId) {
        return this.extra.headerActualId;
      }
      return "";
    },

    ariaDescribedbyVersion() {
      if (this.extra && this.extra.headerVersionId) {
        return this.extra.headerVersionId;
      }
      return "";
    },

    diff() {
      const DIFF = {
        sum: undefined,
        delete: [],
        add: {},
        changes: {},
        status: false,
      };
      if (this.statusSnapshot && this.currentSnapshot) {
        const SNAPSHOT_POSITIONEN_OBJ = cloneDeep(this.currentSnapshot.positionenObj);
        // Workaround mit currency, weil in API-Positionen wert string ist "600.00", aber in snapshot wert number ist 600
        if (this.sumLocal !== this.filterCurrency(this.snapshotSum)) {
          DIFF.sum = true;
        }
        forEach(this.positionen, position => {
          const POSITION_PK = position.pk;
          const CURRENT_POSITION_SNAPSHOT = SNAPSHOT_POSITIONEN_OBJ[POSITION_PK];
          if (CURRENT_POSITION_SNAPSHOT) {
            if (position[this.positionBezKey] !== CURRENT_POSITION_SNAPSHOT[this.positionBezKey]) {
              DIFF.changes[POSITION_PK] = {
                bez: true,
              };
            }
            // Workaround mit currency, weil in API-Positionen wert string ist "600.00", aber in snapshot wert number ist 600
            if (this.filterCurrency(position[this.positionWertKey], null) !== this.filterCurrency(CURRENT_POSITION_SNAPSHOT[this.positionWertKey], null)) {
              DIFF.changes[POSITION_PK] = DIFF.changes[POSITION_PK] || {};
              DIFF.changes[POSITION_PK].wert = true;
            }
            delete SNAPSHOT_POSITIONEN_OBJ[POSITION_PK];
          } else {
            DIFF.add[POSITION_PK] = true;
          }
        });
        if (!isEmpty(SNAPSHOT_POSITIONEN_OBJ)) {
          DIFF.delete = values(SNAPSHOT_POSITIONEN_OBJ) || [];
        }

        if (!isEmpty(DIFF.add) ||
          !isEmpty(DIFF.changes) ||
          DIFF.delete.length ||
          DIFF.sum) {
          DIFF.status = true;
        }
      }
      return DIFF;
    },

    snapshotSum() {
      return this.currentSnapshot[this.summeKey] || 0;
    },

    statusDiff() {
      return this.diff.status;
    },

    statusNotLoad() {
      // Wenn 'kontext' nicht passt.
      return false;
    },

    koFiConfig() {
      return this.getSyConfigsValue("kofi_config", {});
    },

    infoproviderProzent() {
      return get(this.infoproviderDetails, "prozent");
    },

    infoproviderDetails() {
      return get(this.infoprovider, `details.${ this.regel.afr_id }`);
    },
  },
  created() {
    this.initData_KFBaseMixin();
    this.reloadPositionen_KFBaseMixin({ statusFirst: true });
  },
  methods: {
    reloadPositionen_KFBaseMixin({ statusFirst } = {}) {
      if (this.statusNotLoad) {
        return;
      }
      if (statusFirst) {
        this.loading = true;
      } else {
        this.reloading = true;
      }
      const ANTRAGREGEL_ID = this.regel.afr_id;
      this.getListHttp({
        url: this.urlArten,
        urlParams: {
          antragregel: ANTRAGREGEL_ID,
        },
      }).then(
        response => {
          this.setAntragKoFiArt_KFBaseMixin({ obj: response[0] }); // sollte pro antragregel max. eine geben
          if (this.kontext !== "zuschuss_vertrag") {
            this.onArtLoaded_KFBaseMixin();
            this.loadPositionen_KFBaseMixin();
          } else {
            this.loadVertragFinanzierung_KFBaseMixin();
          }
        }
      );
    },

    loadVertragFinanzierung_KFBaseMixin() {

    },

    setAntragKoFiArt_KFBaseMixin() {
      // In KostenMixin und in FinanzierugMixin
    },

    loadPositionen_KFBaseMixin() {
      this.getListHttp({
        url: this.urlPositionen,
        urlParams: {
          ordering: this.positionenOrderingKey,
        },
      }).then(
        response => {
          this.setPositionen_KFBaseMixin(response);
          this.onPosLoaded_KFBaseMixin();
        }
      ).then(
        () => {
          this.loading = false;
          this.reloading = false;
        }
      );
    },

    setPositionen_KFBaseMixin(response) {
      this.positionen = response;
    },

    initKatalogDetails_KFBaseMixin(katalogResults, regelKatalogId, regelKatalogDetails = []) {
      const KATALOG_DETAILS = [];
      for (let k = 0; k < katalogResults.length; k++) {
        const ka = katalogResults[k];
        if (ka.pk !== regelKatalogId) {
          continue;
        }
        for (let d = 0; d < ka.details.length; d++) {
          const kd = ka.details[d];
          if (regelKatalogDetails.indexOf(kd.pk) !== -1) {
            KATALOG_DETAILS.push(kd);
          }
        }
      }
      this.katalogDetails = KATALOG_DETAILS;
    },

    updateZuschuss_KFBaseMixin() {
      EventBus.$emit("updateZuschuss", { antragKoFiArt: this.antragKoFiArt });
      this.getHttp({
        url: `${ this.baseUrl }zuschuss/`,
      }).then(
        response => {
          this.updateInfoproviderLocal_KFBaseMixin({ response });
        }
      );
    },

    updatePositionen_KFBaseMixin({ response, statusSaveCallback } = {}) {
      if (response && response.zuschussrechner) {
        this.infoproviderIsUpdating = true;
        this.updateInfoproviderLocal_KFBaseMixin({ response: response.zuschussrechner });
        setTimeout(() => {
          this.infoproviderIsUpdating = false;
        });
      }
      this.reloadPositionen_KFBaseMixin();
      if (statusSaveCallback !== false) {
        this.saveCallback();
      }
      if (this.kontext === "zuschuss_vertrag") {
        EventBus.$emit("updateBeleg", { finanzierung: true, statusReloadBelegDokumente: false });
      }
    },

    updateInfoproviderLocal_KFBaseMixin({ response }) {
      this.updateInfoprovider({ response });
    },

    prepareModel_KFBaseMixin({ model }) {
      // hook
      return model;
    },

    initData_KFBaseMixin() {
      // hook
    },

    onArtLoaded_KFBaseMixin() {
      // hook
    },

    onPosLoaded_KFBaseMixin() {
      // hook
    }
  },
};
