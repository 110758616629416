import FormElement from "../../FormElement/FormElement.vue";

import init from "../../../directives/init";

import TableFilterMixin from "../TableFilter.mixin";

import widthMap from "../../../const/WidthMap";
import {
  setHeightAsideAndMenu,
  setStyleTopForFixedVertical,
} from "../../../functions/utilsDOM";
import {
  createNamespacedHelpers,
} from "vuex";
import {
  filter,
  groupBy, isPlainObject,
  orderBy,
} from "lodash-es";

const { mapState, mapMutations, mapGetters } = createNamespacedHelpers("table");

// @vue/component
export default {
  name: "TableFilterRight",
  components: {
    FormElement,
  },
  directives: {
    init,
  },
  mixins: [
    TableFilterMixin,
  ],
  props: {
    componentId: {
      type: String,
      default: "dias_filter_",
    },
    statusShow: {
      type: Boolean,
    },
    toggleTableCollapse: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      status: {
        loading: true,
      },
      model: {},
      windowWidth: 0,
      headerHeight: 0,
      timer: undefined,
    };
  },
  computed: {
    getButtonCollapseTitle() {
      return `Filterbereich ${ this.statusShow ? "aus" : "ein" }blenden`;
    },

    getButtonCollapseAriaExpanded() {
      return this.statusShow ? "true" : "false";
    },

    getFilterId() {
      return filter => filter.key || filter.id;
    },

    getFilterDataWithoutGroup() {
      return data => {
        return filter(data, item => !item.group);
      };
    },

    getFilterDataWithGroup() {
      return data => {
        return groupBy(orderBy(filter(data, i => i.group), item => item.group.pos, ["asc"]), itemGroup => itemGroup.group.pos);
      };
    },

    getCheckboxId() {
      return (filter, choiceValue = "", suffix = "") => `${ this.componentId }${ this.getFilterId(filter) }${ choiceValue ? "_" : "" }${ choiceValue }${ suffix }`;
    },

    getStylesFilterBody() {
      return { height: `calc(100% - ${ this.headerHeight }px)` };
    },

    ...mapGetters([
      "GET_TABLE_MODEL_BY_ID",
    ]),

    ...mapState([
      "TABLES",
    ]),
  },
  mounted() {
    this.setHeaderHeight();
    this.windowWidth = window.innerWidth;
    this.initToggleFilterRight();
    window.addEventListener("resize", this.resizeToggleFilterRight);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeToggleFilterRight);
  },
  methods: {
    onSearch({ options, item } = {}) {
      if (options && options.type === "text") {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.onSearchLocal({ options, item });
        }, 500);
      } else {
        this.onSearchLocal({ options, item });
      }
    },

    async onSearchLocal({ options, item } = {}) {
      if (options) {
        let label, labelId;
        if (item && item.label) {
          label = {
            parent: options.label,
            child: item.label,
          };
          labelId = item.value;
        } else {
          label = {
            child: options.label,
          };
          labelId = options.id;
        }
        this.MUT_ADD_LABEL_BY_ID({
          tableId: this.options.id,
          labelId: labelId,
          label: label,
          filterId: options.id,
        });
      }

      this.MUT_CHANGE_MODEL_TABLE_BY_ID({
        id: this.options.id,
        model: this.model,
      });

      await this.startSearch(this.tf_removeEmptyValues());
      this.setFocusToFormElement({ options, item });
    },

    setFocusToFormElement({ options, item } = {}) {
      if (!options) {
        return;
      }
      setTimeout(() => {
        if (isPlainObject(item)) {
          $(`#${ options.id }_${ item[options.keyValue || "value"] }`).focus();
        } else {
          $(`#${ options.id }`).focus();
        }
      });
    },

    initToggleFilterRight() {
      if (this.windowWidth > widthMap.md.width && !this.statusShow) {
        this.toggleTableCollapse();
      } else if (this.windowWidth < widthMap.md.width && this.statusShow) {
        this.toggleTableCollapse();
      }
    },

    resizeToggleFilterRight() {
      const currentWindowWight = window.innerWidth;
      if (this.windowWidth > widthMap.lg.width) {
        if (currentWindowWight <= widthMap.lg.width) {
          this.toggleTableCollapse(false);
        }
      } else {
        if (currentWindowWight > widthMap.lg.width) {
          this.toggleTableCollapse(true);
        }
      }
      this.windowWidth = window.innerWidth;
    },

    setHeaderHeight() {
      setTimeout(() => {
        this.headerHeight = this.$refs.fixed_vertical__header.clientHeight;
      });
    },

    setAsideHeight() {
      setTimeout(() => {
        setStyleTopForFixedVertical();
        setHeightAsideAndMenu();
      });
    },

    ...mapMutations([
      "MUT_CHANGE_MODEL_TABLE_BY_ID",
      "MUT_ADD_LABEL_BY_ID",
      "MUT_INIT_LABEL_BY_ID",
    ]),
  },
};
