import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "funktionsgruppen";

// @vue/component
export default {
  name: "FunktionsgruppenList",
  components: {
    AngularLink,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  props: {
    fixed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        rowId: "key",
        url: getTableApi({ id: TABLE_ID }),
        label: "_LBL_FUNKTIONSGRUPPEN_LIST_FUNKTIONSGRUPPEN_",
        sortable: false,
        pagination: true,
        details: true,
        showColsConfig: false,
        view: {
          filter: "top",
        },
      },
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },
  },
};
