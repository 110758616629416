// Modul zur Erfassung von Personalkosten mit Perioden
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const personalkosten_perioden = angular.module("dias.kosten_finanzierung.modul.personalkosten_perioden", [
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
]);

personalkosten_perioden.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("personalkosten_perioden", "modul-personalkosten-perioden");
})

  .directive("modulPersonalkostenPerioden", builder.directive(template, controller));

export default personalkosten_perioden;
