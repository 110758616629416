import moment from "moment";

// @vue/component
export default {
  name: "CalendarEvent",
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    eventStyle() {
      return `background-color: ${ this.event.bg }; color: ${ this.event.color }`;
    },

    eventSrc() {
      return `/api/assets/${ this.event.icon }`;
    },

    eventDuration() {
      return `${ moment(this.event.start).format("HH:mm") } - ${ moment(this.event.end).format("HH:mm") }`;
    },
  },
};
