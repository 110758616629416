import {
  computed,
  toRef,
} from "vue";

import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import IconListenstatus from "../../../../../global/components/ui/IconListenstatus/IconListenstatus.vue";
import PuxAlert from "../../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxGet from "../../../../../global/components/PuxGet/PuxGet.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import SitzungModulePropsMixin from "../SitzungModulePropsMixin";

import ModuleEventBusAPI from "./compositionAPI/ModuleEventBusAPI";
import PermissionAPI from "../../../../../global/compositionAPI/PermissionAPI";
import SitzungModuleAPI from "../compositionAPI/SitzungModuleAPI";
import TagesordnungspunktInBearbeitungAPI from "./compositionAPI/TagesordnungspunktInBearbeitungAPI";

import {
  EventBus,
} from "../../../../../global/functions/event-bus";

// @vue/component
export default {
  name: "ModuleAntragslistenUebersichtMain",
  components: {
    FormElement,
    FormstepDetail,
    IconListenstatus,
    PuxAlert,
    PuxGet,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    SitzungModulePropsMixin,
  ],
  setup(props, context) {
    const {
      htmlId,
      model,
      modulname,
      reloadModule,
      sitzungModuleEventName,
    } = SitzungModuleAPI(props, context);

    const {
      hideTagesordnungspunktInBearbeitungText,
      isTagesordnungspunktInBearbeitungTextVisible,
    } = TagesordnungspunktInBearbeitungAPI({
      model,
    });

    const permissionSrc = toRef(props, "permissionSrc");
    const {
      checkPermissionsSync,
    } = PermissionAPI();

    const canView = computed(() => {
      return checkPermissionsSync({
        perm: "tops.mittel.view",
        permArray: permissionSrc.value,
      });
    });

    const optionsFormstepDetailLocal = {
      hideHeader: true,
    };

    const {
      onEvent,
    } = ModuleEventBusAPI(props, {
      reloadModule,
      sitzungModuleEventName,
    });

    EventBus.$on(sitzungModuleEventName, onEvent);

    return {
      htmlId,
      model,
      modulname,

      hideTagesordnungspunktInBearbeitungText,
      isTagesordnungspunktInBearbeitungTextVisible,

      canView,
      optionsFormstepDetailLocal,
    };
  },
};
