import {
  computed,
  toRef,
} from "vue";

import FormElement from "../../../../global/components/FormElement/FormElement.vue";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "AnonymisierungPlanStufeEdit",
  components: {
    FormElement,
  },
  props: {
    katalogItem: {
      type: Object,
      required: true,
    },
    stufe: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "update:modelValue",
  ],
  setup(props, { emit }) {
    const katalogItem = toRef(props, "katalogItem");
    const stufe = toRef(props, "stufe");
    const modelValue = toRef(props, "modelValue");

    const groupId = computed(() => {
      return `${ katalogItem.value.pk }_${ stufe.value.pk }`;
    });

    const optionsForUiTemplate = computed(() => {
      return {
        id: groupId.value,
        type: "template",
        slot: "template",
      };
    });

    const optionsForCheckbox = computed(() => {
      return {
        id: `${ groupId.value }_checkbox`,
        type: "one_checkbox",
        label: stufe.value.bez,
      };
    });

    const optionsForFrist = computed(() => {
      return {
        id: `${ groupId.value }_anp_frist`,
        type: "integer",
        label: "_TXT_MONTHS_",
        required: true,
        view: "v",
      };
    });

    const changeModelCheckbox = ({ model }) => {
      const MODEL = cloneDeep(modelValue.value);
      MODEL[groupId.value].selected = model;
      emit("update:modelValue", MODEL);
    };

    const changeModelFrist = ({ model }) => {
      const MODEL = cloneDeep(modelValue.value);
      MODEL[groupId.value].el.anp_frist = model;
      emit("update:modelValue", MODEL);
    };

    return {
      changeModelCheckbox,
      changeModelFrist,
      groupId,
      optionsForCheckbox,
      optionsForFrist,
      optionsForUiTemplate,
    };
  },
};
