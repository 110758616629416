import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../../global/mixins/NotificationMixin";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

import {
  flatten,
  isObject,
  isArray,
  values,
} from "lodash-es";

// @vue/component
export default {
  name: "DashboardAntragErstellenModalCreate",
  components: {
    Modal,
    PuxCloak,
    PuxButton,
    PuxTranslate
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    angebotId: {
      type: String,
      required: true
    },
    regelsatzId: {
      type: String,
      required: true
    },
    regelsatzKbez: {
      type: String,
      required: true
    },
    antragsquelleId: {
      type: String,
      required: true
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      filterSpaced,
    } = FiltersAPI();

    return {
      filterSpaced,
    };
  },
  data() {
    return {
      loading: true,
      errors: undefined
    };
  },
  created() {
    this.save();
  },
  methods: {
    save() {
      const data = {
        regelsatz: this.regelsatzId,
        quelle: this.antragsquelleId,
      };
      this.postHttp({
        url: "foerderantraege/",
        data: data,
        showError: true,
      }).then(
        response => {
          if (typeof window.utag !== "undefined") {
            try {
              const LINK_OBJ = {
                event_id: "DIAS_antrag_erstellen",
                etracker_event_action: "click",
              };
              if (this.angebot) {
                LINK_OBJ.antrag_angebot = this.regelsatzKbez;
                LINK_OBJ.antrag_angebot_id = this.angebotId;
              }
              window.utag.link(LINK_OBJ);
            } catch (err) {
              console.error(err);
            }
          }
          this.$goTo("root.foerderantrag.details", { id: response.pk });
          this.close();
          this.addNotification({
            text: "_MSG_DASHBOARD_ANTRAG_ERSTELLEN_MODAL_ANTRAG_CREATE_SUCCESS_{{a_nr}}_",
            extra: {
              a_nr: this.filterSpaced(response.a_nr)
            }
          });
        },
        error => {
          if (error.status === 429) {
            this.close();
            return;
          }
          if (error && error.data) {
            if (isObject(error.data)) {
              this.errors = flatten(values(error.data));
            } else if (!isArray(error.data)) {
              this.errors = [error.data];
            } else {
              this.errors = error.data;
            }
          }
        },
        () => {}
      ).finally(() => this.loading = false);
    },
  },
};
