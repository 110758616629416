"use strict";

class SitzungenEditController {
  /*@ngInject*/
  constructor($q, DiasNotification, Katalog, $scope, i18n) {
    this.sitzung = $scope.extras.sitzung;
    this.tagesordnung = $scope.extras.tagesordnung;
    this.punkten = [];
    this.module = [];
    this.gettext = i18n.gettext;
    for (let i = 0; i <= this.tagesordnung.length; i++) {
      const index = i + 1;
      const data = {
        label: index,
        value: index
      };
      this.punkten.push(data);
      if (this.tagesordnung[i]) {
        for (let j = 0; j <= this.tagesordnung[i].unterpunkte.length; j++) {
          const index1 = j + 1;
          const data1 = {
            label: index + "." + index1,
            value: index + "." + index1
          };
          this.punkten.push(data1);
        }
      }
    }
    this.ssm_objs = [];
    this.filtered_ssm = [];
    Katalog.getKatalog("sitzungsmodule", { ssm_konfigurierbar: "True" }).then(
      response => {
        this.ssm_objs.push(...response);
        this.filterSelectableModule();
      },
      error => DiasNotification.page.error("Fehler beim laden der Sitzungsmodule", error)
    );
    this.DiasNotification = DiasNotification;
    this.model = {};

    this.save = () => {
      let position;
      let oberpunkt;
      if (this.model.tos_pos) {
        this.model.tos_pos = this.model.tos_pos + "";
        const positionArr = this.model.tos_pos.split(".");
        if (positionArr.length === 1) {
          position = positionArr[0];
          oberpunkt = null;
        } else {
          position = positionArr[1];
          oberpunkt = this.tagesordnung[positionArr[0] - 1].pk;
        }
      }
      this.module = this.module || [];
      const data = {
        tos_bez: this.model.tos_bez,
        tos_info: this.model.tos_info,
        tos_pos: position,
        oberpunkt: oberpunkt,
        module: this.module.map(value => value.pk)
      };
      const defer = $q.defer();
      const promise = this.sitzung.tops.post(data);
      promise.then(
        () => {
          this.DiasNotification.page.success("Tagesordnungspunkt wurde erstelt");
          defer.resolve();
        },
        error => {
          defer.reject();
          this.onError(error);
        }
      );
      return defer.promise;
    };

    this.onError = error => {
      const errorsLabel = {
        tos_bez: "Name: ",
        tos_pos: "Position: ",
      };
      this.DiasNotification.form.clear();
      this.DiasNotification.form.error(
        error.data,
        "Bitte überprüfen Sie Ihre Eingaben",
        {},
        errorsLabel
      );
      this.errors = error.data;
    };
  }

  changePosition() {
    if (this.tagesordnung[this.model.tos_pos - 1]) {
      this.unterpunktStatus = true;
      const untenpunktLength = this.tagesordnung[this.model.tos_pos - 1].unterpunkte.length;
      this.countUntenpunkten = [];
      for (let i = 0; i <= untenpunktLength; i++) {
        const j = i + 1;
        const temp = {
          label: this.model.tos_pos + "." + j,
          value: j
        };
        this.countUntenpunkten.push(temp);
      }
    } else {
      this.unterpunktStatus = false;
    }
  }

  filterSelectableModule() {
    this.filtered_ssm = [];
    for (let i = 0; i < this.ssm_objs.length; i++) {
      let alreadyIn = false;
      for (let j = 0; j < this.module.length; j++) {
        if (this.module[j].pk === this.ssm_objs[i].pk) {
          alreadyIn = true;
          break;
        }
      }
      if (!alreadyIn) {
        this.filtered_ssm.push(this.ssm_objs[i]);
      }
    }
  }

  modulInputMissing() {
    return !this.selectedModul;
  }

  addModul() {
    if (!this.modulInputMissing()) {
      this.module.push(this.selectedModul);
      this.selectedModul = null;
      this.filterSelectableModule();
    }
  }

  removeModul(index) {
    this.module.splice(index, 1);
    this.filterSelectableModule();
  }

  moveUpModul(index) {
    if (index === 0) {
      return;
    }
    const elem = this.module[index];
    const previous = this.module[index - 1];
    this.module[index - 1] = elem;
    this.module[index] = previous;
  }

  moveDownModul(index) {
    if (index >= this.module.length - 1) {
      return;
    }
    const elem = this.module[index];
    const next = this.module[index + 1];
    this.module[index + 1] = elem;
    this.module[index] = next;
  }
}

export default SitzungenEditController;
