import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  uniq,
} from "lodash-es";

// @vue/component
export default {
  name: "FoerderdokumenteFoerderangebote",
  components: {
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    foerderdokument: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        label: "_TXT_FOERDERDOKUMENTE_DETAILS_FOERDERDOKUMENT_PANEL_FOERDERANGEBOTE_FORMSTEP_NAME_",
        list: [
          {
            id: "angebote",
            view: "v-alt",
            type: "template",
            slot: "angebote",
            translate: true,
          },
        ],
      }
    };
  },
  computed: {
    uniqueAngebote() {
      return uniq(this.foerderdokument.angebote);
    },
  },
};
