import {
  computed,
  toRefs,
} from "vue";

import teilnehmerModalCreate from "./teilnehmerModalCreate/teilnehmerModalCreate.vue";
import SmartTable from "../../../../global/components/table/table.vue";

import getTableApi from "../../../../const/TableApis";
import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import { EventBus } from "../../../../global/functions/event-bus";
import { gettext } from "../../../../global/functions/utils";

import {
  cloneDeep,
  forEach,
} from "lodash-es";

const TABLE_ID = "teilnehmer";

// @vue/component
export default {
  name: "SitzungDetailsTeilnehmer",
  components: {
    teilnehmerModalCreate,
    SmartTable,
  },
  props: {
    sitzung: {
      type: Object,
      default: undefined,
    },
  },
  setup(props) {
    const {
      sitzung,
    } = toRefs(props);

    const {
      deleteHttp,
      getHttp,
      putHttp,
      addNotification,
    } = HttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    const hasWFPermissionSitzungTeilnehmerView = computed(() => {
      return checkPermissionsSync({
        perm: "sitzungsteilnehmer.view",
        permArray: sitzung.value.user_permissions,
      });
    });

    const hasWFPermissionSitzungTeilnehmerCreate = computed(() => {
      return checkPermissionsSync({
        perm: "sitzungsteilnehmer.create",
        permArray: sitzung.value.user_permissions,
      });
    });

    const hasWFPermissionSitzungTeilnehmerUpdate = computed(() => {
      return checkPermissionsSync({
        perm: "sitzungsteilnehmer.update",
        permArray: sitzung.value.user_permissions,
      });
    });

    const hasWFPermissionSitzungTeilnehmerDelete = computed(() => {
      return checkPermissionsSync({
        perm: "sitzungsteilnehmer.delete",
        permArray: sitzung.value.user_permissions,
      });
    });

    return {
      addNotification,
      deleteHttp,
      getHttp,
      putHttp,

      hasWFPermissionSitzungTeilnehmerCreate,
      hasWFPermissionSitzungTeilnehmerDelete,
      hasWFPermissionSitzungTeilnehmerUpdate,
      hasWFPermissionSitzungTeilnehmerView,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID, params: { sitzungPk: this.sitzung.pk } }),
        cols: [
          { label: "_LBL_SITZUNGSDETAILS_TEILNEHMER_NAME_",
            id: "nutzer_obj.name"
          },
          { label: "_LBL_SITZUNGSDETAILS_TEILNEHMER_ORGANISATION_",
            id: "org_obj.name"
          },
          { label: "_LBL_SITZUNGSDETAILS_TEILNEHMER_TEILNAHME_",
            id: "status_obj.tst_kbez",
            templateCallback: ({ row }) => row.status_obj ? row.status_obj.tst_kbez : gettext("_LBL_SITZUNGSDETAILS_TEILNEHMER_KEINE_RUECKMELDUNG_"),
          },
          { label: "_LBL_SITZUNGSDETAILS_TEILNEHMER_VORSITZ_",
            id: "ste_vorsitz",
            filter: "boolean",
            translate: true,
          },
        ],
        rowActions: [
          {
            label: "_LBL_SITZUNGSDETAILS_TEILNEHMER_VORSITZ_ZUWEISEN_",
            callback: ({ item }) => this.teilnehmerChangeVorsitz(item, true),
            isHidden: ({ item }) => !this.hasWFPermissionSitzungTeilnehmerUpdate || !item.vorsitz_moeglich || item.ste_vorsitz,
          },
          {
            label: "_LBL_SITZUNGSDETAILS_TEILNEHMER_VORSITZ_ENTZIEHEN_",
            callback: ({ item }) => this.teilnehmerChangeVorsitz(item, false),
            isHidden: ({ item }) => !this.hasWFPermissionSitzungTeilnehmerUpdate || !item.ste_vorsitz,
          },
          {
            label: "_LBL_SITZUNGSDETAILS_TEILNEHMER_ENTFERNEN_",
            callback: ({ item }) => this.deleteTeilnehmer({ teilnehmerPk: item.pk }),
            isHidden: () => !this.hasWFPermissionSitzungTeilnehmerDelete,
          },
        ],
        actions: [
          {
            label: "_LBL_SITZUNGSDETAILS_TEILNEHMER_HINZUFUEGEN_",
            icon: "glyphicon-plus",
            callback: () => this.openModalCreate(),
            isHidden: () => !this.hasWFPermissionSitzungTeilnehmerCreate,
          },
        ],
        sortable: false,
        pagination: true,
        details: false,
        showHeader: false,
        showColsConfig: false,
        loading: false
      },
      modal: {
        create: false,
      },
      status: {
        loading: true,
      }
    };
  },
  created() {
    this.loadStatus();
  },
  methods: {
    openModalCreate() {
      this.modal.create = true;
    },

    loadStatus() {
      this.getHttp({
        url: `katalog/`,
        apiSaveId: "katalog_teilnehmerstatus",
        urlParams: {
          key: "teilnehmerstatus"
        },
      }).then(
        response => {
          forEach(response, status => {
            this.options.rowActions.push({
              labelCallback: () => this.statusLabel({ status }),
              callback: ({ item }) => this.teilnehmerChangeStatus({ item, status: status }),
              isHidden: ({ item }) => this.isHiddenStatus({ item, status: status }),
            });
          });
        },
      ).finally(
        () => this.status.loading = false,
      );
    },

    isHiddenStatus({ item, status }) {
      return !this.hasWFPermissionSitzungTeilnehmerUpdate || item.status === status.pk;
    },

    statusLabel({ status }) {
      return `${ status.tst_kbez } ${ gettext("_TXT_SITZUNGSDETAILS_TEILNEHMER_STATUS_NOTE_DOWN_") }`;
    },

    deleteTeilnehmer({ teilnehmerPk }) {
      this.deleteHttp({ url: `sitzungen/${ this.sitzung.pk }/teilnehmer/${ teilnehmerPk }/` }).then(
        () => {
          this.addNotification({ text: "_MSG_SITZUNGSDETAILS_TEILNEHMER_ENTFERNT_" });
          EventBus.$emit(`updateTable${ TABLE_ID }`);
        },
        () => {
          this.addNotification({ text: "_MSG_SITZUNGSDETAILS_TEILNEHMER_ENTFERNT_ERROR_", type: "error" });
        }
      );
    },

    teilnehmerChangeVorsitz(item, vorsitz) {
      const DATA = cloneDeep(item);
      DATA.ste_vorsitz = vorsitz;
      this.putHttp({
        url: `sitzungen/${ this.sitzung.pk }/teilnehmer/${ item.pk }/`,
        data: DATA,
      }).then(
        () => {
          this.addNotification({ text: "_MSG_SITZUNGSDETAILS_TEILNEHMER_VOSITZ_" });
          EventBus.$emit(`updateTable${ TABLE_ID }`);
        },
        () => {
          this.addNotification({ text: "_MSG_SITZUNGSDETAILS_TEILNEHMER_VORSITZ_ERROR_" });
        }
      );
    },

    teilnehmerChangeStatus({ item, status }) {
      const DATA = cloneDeep(item);
      DATA.status = status.pk;
      this.putHttp({
        url: `sitzungen/${ this.sitzung.pk }/teilnehmer/${ item.pk }/`,
        data: DATA,
      }).then(
        () => {
          this.addNotification({ text: "_MSG_SITZUNGSDETAILS_TEILNEHMER_STATUS_" });
          EventBus.$emit(`updateTable${ TABLE_ID }`);
        },
        () => {
          this.addNotification({ text: "_MSG_SITZUNGSDETAILS_TEILNEHMER_STATUS_ERROR_" });
        }
      );
    },

    closeModalCreate({ status } = {}) {
      this.modal.create = false;
      if (status === true) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
    }
  },
};
