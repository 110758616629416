const MAX_SOUTH_COORDINATE_DE = 47.2708333;
const MAX_NORTH_COORDINATE_DE = 55.0591667;
const MAX_WEST_COORDINATE_DE = 5.866944444444445;
const MAX_EAST_COORDINATE_DE = 15.043611111111112;

import {
  forEach,
  isUndefined,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  data() {
    return {
      bounds: [
        [MAX_NORTH_COORDINATE_DE, MAX_WEST_COORDINATE_DE],
        [MAX_SOUTH_COORDINATE_DE, MAX_EAST_COORDINATE_DE],
      ],
      maxBounds: [
        [MAX_NORTH_COORDINATE_DE, MAX_WEST_COORDINATE_DE],
        [MAX_SOUTH_COORDINATE_DE, MAX_EAST_COORDINATE_DE],
      ],
    };
  },
  computed: {
    getBounds() {
      if (isUndefined(this.bounds[0][0]) ||
        isUndefined(this.bounds[0][1]) ||
        isUndefined(this.bounds[1][0]) ||
        isUndefined(this.bounds[1][1])) {
        return [
          [MAX_NORTH_COORDINATE_DE, MAX_WEST_COORDINATE_DE],
          [MAX_SOUTH_COORDINATE_DE, MAX_EAST_COORDINATE_DE],
        ];
      }
      return this.bounds;
    },
  },
  methods: {
    showMapWithAllObjects() {
      this.initBounds();
      this.$refs.map.mapObject.fitBounds(this.getBounds);
    },

    initBounds() {
      if (this.polylineLatlngs || (this.model.adressen && this.model.adressen.length) || this.adresse.length) {
        this.initBoundsFromPolylineAndAdressen();
      }
    },

    initBoundsFromPolylineAndAdressen() {
      let maxSouthCoordinate;
      let maxNorthCoordinate;
      let maxWestCoordinate;
      let maxEastCoordinate;

      forEach(this.model.punkte, points => {
        forEach(points, point => {
          if (isUndefined(maxSouthCoordinate)) {
            maxSouthCoordinate = point[0];
            maxNorthCoordinate = point[0];
            maxWestCoordinate = point[1];
            maxEastCoordinate = point[1];
          }
          maxSouthCoordinate = point[0] < maxSouthCoordinate ? point[0] : maxSouthCoordinate;
          maxNorthCoordinate = point[0] > maxNorthCoordinate ? point[0] : maxNorthCoordinate;

          maxWestCoordinate = point[1] < maxWestCoordinate ? point[1] : maxWestCoordinate;
          maxEastCoordinate = point[1] > maxEastCoordinate ? point[1] : maxEastCoordinate;
        });
      });

      forEach(this.model.adressen, adress => {
        if (isNil(adress.adr_geokoord)) {
          return;
        }
        if (isUndefined(maxSouthCoordinate)) {
          maxSouthCoordinate = adress.adr_geokoord[0];
          maxNorthCoordinate = adress.adr_geokoord[0];
          maxWestCoordinate = adress.adr_geokoord[1];
          maxEastCoordinate = adress.adr_geokoord[1];
        }
        maxSouthCoordinate = adress.adr_geokoord[0] < maxSouthCoordinate ? adress.adr_geokoord[0] : maxSouthCoordinate;
        maxNorthCoordinate = adress.adr_geokoord[0] > maxNorthCoordinate ? adress.adr_geokoord[0] : maxNorthCoordinate;

        maxWestCoordinate = adress.adr_geokoord[1] < maxWestCoordinate ? adress.adr_geokoord[1] : maxWestCoordinate;
        maxEastCoordinate = adress.adr_geokoord[1] > maxEastCoordinate ? adress.adr_geokoord[1] : maxEastCoordinate;
      });

      forEach(this.adresse, adress => {
        if (isNil(adress.adr_geokoord)) {
          return;
        }
        if (isUndefined(maxSouthCoordinate)) {
          maxSouthCoordinate = adress.adr_geokoord[0];
          maxNorthCoordinate = adress.adr_geokoord[0];
          maxWestCoordinate = adress.adr_geokoord[1];
          maxEastCoordinate = adress.adr_geokoord[1];
        }
        maxSouthCoordinate = adress.adr_geokoord[0] < maxSouthCoordinate ? adress.adr_geokoord[0] : maxSouthCoordinate;
        maxNorthCoordinate = adress.adr_geokoord[0] > maxNorthCoordinate ? adress.adr_geokoord[0] : maxNorthCoordinate;

        maxWestCoordinate = adress.adr_geokoord[1] < maxWestCoordinate ? adress.adr_geokoord[1] : maxWestCoordinate;
        maxEastCoordinate = adress.adr_geokoord[1] > maxEastCoordinate ? adress.adr_geokoord[1] : maxEastCoordinate;
      });
      this.bounds = [
        [maxNorthCoordinate, +maxWestCoordinate + 0.0000001],
        [maxSouthCoordinate, maxEastCoordinate],
      ];
    },
  },
};
