"use strict";

import BaseModel from "./baseModel";

class KAnrede extends BaseModel {
  constructor() {
    super("anreden", { pk: "ank_id" });
  }
}

export default KAnrede;
