"use strict";

import BetragsrechnerBase from "dias/kosten_finanzierung/betragsrechner/betragsrechner.base";


class StueckkostenRechner extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext, antragregel) {
    super($scope, kontext, antragregel);
    this.name = "stueckkosten";
    this.component_name = "stueckkosten";
    this.labels = { textfeld_1: this.antragregel.afr_argument.label_textfeld_1.wert,
                    textfeld_2: this.antragregel.afr_argument.label_textfeld_2.wert,
                    textfeld_3: this.antragregel.afr_argument.label_textfeld_3.wert,
                    anzahl_einheiten: this.antragregel.afr_argument.label_anzahl_einheiten.wert,
                    einheit: this.antragregel.afr_argument.label_einheit.wert,
                    stueckkosten: this.antragregel.afr_argument.label_stueckkosten.wert };
  }

  get_labelmapping() {
    return this.labels;
  }

  get_fields() {
    return { textfeld_1: "",
             textfeld_2: "",
             textfeld_3: "",
             anzahl_einheiten: "",
             einheit: null,
             stueckkosten: "",
    };
  }
}
export default StueckkostenRechner;
