<div><code class="font-size-md">smart-table with Filter-Oben</code><ul class="nav nav-tabs"><li class="nav-item" role="tablist"><a class="nav-link" @click="currentItem = 'demo'" :class="{ active: currentItem === 'demo' }" role="button">Demo</a></li><li class="nav-item" role="tablist"><a class="nav-link" @click="currentItem = 'options'" :class="{ active: currentItem === 'options' }" role="button">Optionen</a></li></ul><article v-show="currentItem === 'demo'"><smart-table :options="options" :filters="filters"></smart-table><styleguide-table-options-cols></styleguide-table-options-cols><styleguide-table-options-filters></styleguide-table-options-filters><styleguide-table-options-row-actions></styleguide-table-options-row-actions><styleguide-table-options-mass-actions></styleguide-table-options-mass-actions><styleguide-table-options-actions></styleguide-table-options-actions><styleguide-table-options-list></styleguide-table-options-list></article><article v-show="currentItem === 'options'"><div v-highlight=""><pre>PUG<code>smart-table(
  :options="options"
)</code></pre><pre>JS<code>import mock from "./mock.json"; // Das ist nur Mock-server
import SmartTable from ".../global/components/table/table.vue";

export default {
  name: "vue-styleguide-table",
  components: {
    VueTable,
  },
  filters: {
    date,
  },
  data () {
    return {
      options: {
        id: "StyleguideTableEasy",
        cols: [
          {
            label: "Titel",
            id: "kbez",
          },
          {
            label: "Gültig ab",
            id: "gueltig_ab",
            template: row => `<span>${ this.filterDate(row.gueltig_ab, "DD.MM.YYYY") }</span>`,
          },
          {
            label: "Gültig bis",
            id: "gueltig_bis",
            template: row => `<span>${ this.filterDate(row.gueltig_bis, "DD.MM.YYYY") }</span>`,
            hide: true,
          },
        ],
        rowActions: [
          {
            label: "Etwas schaffen1",
            callback: row => console.log("row", row),
            titleCallback: row => `${ row.kbez } schaffen`,
          },
          {
            label: "Etwas schaffen2",
            href: row => `/vuestyleguide/${ row.pk }/`,
            titleCallback: row => `${ row.kbez } schaffen`,
          },
        ],
        filters: [
          {
            type: "text",
            id: "suche",
            label: "Suche",
            main: true,
          },
        ],
        data: mock,
        label: "Mock Tabelle1",
        sortable: false,
        pagination: false,
      },
    }
  },
}




</code></pre></div></article></div>