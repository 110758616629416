import AntragOverviewAnsprechpartner from "./AntragOverviewAnsprechpartner/AntragOverviewAnsprechpartner.vue";
import AntragOverviewAntragsteller from "./AntragOverviewAntragsteller/AntragOverviewAntragsteller.vue";
import AntragOverviewFoerderung from "./AntragOverviewFoerderung/AntragOverviewFoerderung.vue";
import AntragOverviewRegelsatz from "./AntragOverviewRegelsatz/AntragOverviewRegelsatz.vue";
import DokumenteHistory from "../../../Dokumente/DokumenteHistory/DokumenteHistory.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";

// @vue/component
export default {
  name: "AntragOverview",
  components: {
    AntragOverviewAnsprechpartner,
    AntragOverviewAntragsteller,
    AntragOverviewFoerderung,
    AntragOverviewRegelsatz,
    DokumenteHistory,
    FormstepDetail,
  },
  props: {
    antrag: {
      type: Object,
      required: true,
    },
    antragUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      docAnzeige: "2f413911-a316-c179-e050-007f0101705e",
      optionsForFormstepDetails: {
        hideHeader: true,
      },
    };
  },
};
