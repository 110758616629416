import AufgabenvorlagenDetailsBerechtigungen from "./AufgabenvorlagenDetailsBerechtigungen/AufgabenvorlagenDetailsBerechtigungen.vue";
import AufgabenvorlagenDetailsBeschreibung from "./AufgabenvorlagenDetailsBeschreibung/AufgabenvorlagenDetailsBeschreibung.vue";
import AufgabenvorlagenDetailsStatusgruppen from "./AufgabenvorlagenDetailsStatusgruppen/AufgabenvorlagenDetailsStatusgruppen.vue";
import AufgabenvorlagenModalCopy from "./AufgabenvorlagenModalCopy/AufgabenvorlagenModalCopy.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import WorkflowfristListInKAufgabe from "../WorkflowfristListInKAufgabe/WorkflowfristListInKAufgabe.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../global/compositionAPI/NotificationAPI";

// @vue/component
export default {
  name: "AufgabenvorlagenDetails",
  components: {
    AufgabenvorlagenDetailsBerechtigungen,
    AufgabenvorlagenDetailsBeschreibung,
    AufgabenvorlagenDetailsStatusgruppen,
    AufgabenvorlagenModalCopy,
    ContextPanel,
    Permission,
    PuxButton,
    PuxPageDetails,
    WorkflowfristListInKAufgabe,
  },
  setup() {
    const {
      deleteHttp,
      getHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      deleteHttp,
      getHttp,
      addNotification,
    };
  },
  data() {
    return {
      loading: true,
      kAufgabe: {},
      infoTexts: {
        bearbeiter: "_TXT_AUFGABENVORLAGENDETAILS_INFOTEXT_BEARBEITER_",
        beobachter: "_TXT_AUFGABENVORLAGENDETAILS_INFOTEXT_BEOBACHTER_",
        passiv: "_TXT_AUFGABENVORLAGENDETAILS_INFOTEXT_PASSIV_",
        ende: "_TXT_AUFGABENVORLAGENDETAILS_INFOTEXT_ENDE_",
      },
      copyModalVisible: false,
      deleteModalVisible: false,
      modalConfirmOptions: {},
    };
  },
  computed: {
    aufgabenVorlagenId() {
      return this.$stateParams.id;
    },

    baseUrl() {
      return `aufgaben/${ this.aufgabenVorlagenId }/`;
    },
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.getHttp({
        url: this.baseUrl,
      }).then(
        response => {
          this.kAufgabe = response;
        },
      ).finally(
        () => this.loading = false,
      );
    },

    delete() {
      this.modalConfirmOptions.loading = true;
      return this.deleteHttp({
        url: this.baseUrl,
        data: this.KAufgabe,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_AUFGABENVORLAGENDETAILS_DELETE_SUCCESS_"
          });
          this.$goTo("root.kaufgaben");
          return Promise.resolve();
        },
        errors => {
          this.addNotification({
            text: errors.data || "_MSG_AUFGABENVORLAGENDETAILS_DELETE_ERROR_",
            type: "error",
          });
          return Promise.reject(errors);
        },
      ).finally(
        () => this.modalConfirmOptions.loading = false,
      );
    },

    openModalCreateCopyKAufgabe() {
      this.copyModalVisible = true;
    },

    closeModalCreateCopyKAufgabe() {
      this.copyModalVisible = false;
    },

    openModalDeleteKAufgabe() {
      this.modalConfirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_AUFGABENVORLAGENDETAILS_CONFIRM_DELETE_TITLE_{{au_kbez_admin}}_",
        msg: "_MSG_AUFGABENVORLAGENDETAILS_CONFIRM_DELETE_{{au_kbez_admin}}_",
        extra: { au_kbez_admin: this.kAufgabe.au_kbez_admin },
        okLabel: "_BTN_DELETE_",
        okCallback: this.delete,
        cancelCallback: this.closeModalDeleteKAufgabe,
        loading: false,
      };
      this.deleteModalVisible = true;
    },

    closeModalDeleteKAufgabe() {
      this.deleteModalVisible = false;
    },

    updateAufgabenvorlage({ data }) {
      this.kAufgabe = Object.assign({}, this.kAufgabe, data);
    },
  },
};
