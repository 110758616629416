"use strict";

import "restangular";
import angular from "angular";

import Nutzerdatensuche from "./nutzerdatensuche.service";

const modelsModule = angular.module("privacy.models", [
  "restangular",
])

  .provider("Nutzerdatensuche", Nutzerdatensuche)
;

export default modelsModule;
