import HttpMixin from "../../../global/mixins/HttpMixin";
import PopupMixin from "./PopupMixin";

import FoerderlandkarteKarteOptions from "./FoerderlandkarteKarteOptions";
import {
  PruneCluster,
  PruneClusterForLeaflet
} from "prunecluster";

import {
  cloneDeep,
  forEach,
  isNil,
  isUndefined,
} from "lodash-es";

import L from "leaflet";
import "leaflet/dist/leaflet.css";

// @vue/component
export default {
  name: "FoerderlandkarteKarte",
  mixins: [
    HttpMixin,
    PopupMixin,
  ],
  props: {
    mapParameters: {
      type: Object,
      required: true,
    },
    markerTypes: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      markerIconOptions: {
        iconUrl: "/api/assets/map-icon-0.png",
        iconSize: [35, 40],
        iconAnchor: [15, 15],
        shadowUrl: "/api/assets/map-icon-0.png",
      },
      // Karte Einstellungen
      mapOptions: cloneDeep(FoerderlandkarteKarteOptions),
      markerAllInfo: {},

      map: undefined,
      pruneCluster: {},

      bounds: [],
      showTooltip: false,
    };
  },
  computed: {
    getSpinner() {
      return `<div class="loading_container robot_process_loading">
                <span class="loading_container__spinner_container">
                  <span class="loading_container__msg_container left">
                    <i class="loading_container__msg_container__spinner spinner">
                      <div class="bounce1"></div>
                      <div class="bounce2"></div>
                      <div class="bounce3"></div>
                    </i>
                  </span>
                </span>
              </div>`;
    },
  },
  mounted() {
    setTimeout(() => {
      this.initMap();
    });
  },
  methods: {
    initMap() {
      this.map = L.map(this.$el, {
        center: [50, 10],
        zoom: this.mapParameters.min_zoom || 6,
        minZoom: this.mapParameters.min_zoom || 6,
        maxZoom: this.mapParameters.max_zoom || 18,
        maxBounds: cloneDeep(FoerderlandkarteKarteOptions.coordinates.de.maxBounds)
      });
      L.tileLayer(FoerderlandkarteKarteOptions.layer.url, {
        attribution: FoerderlandkarteKarteOptions.layer.attribution,
      }).addTo(this.map);

      this.map.on("zoomstart", () => {
        if (!this.showTooltip) {
          return;
        }
        const POPUP_CLOSE_BUTTON = document.querySelector(".leaflet-popup-close-button");
        if (POPUP_CLOSE_BUTTON) {
          POPUP_CLOSE_BUTTON.click();
        }
        this.showTooltip = false;
      });
    },

    rerenderClusters({ response }) {
      this.clearClusters();
      let maxSouthCoordinate;
      let maxNorthCoordinate;
      let maxWestCoordinate;
      let maxEastCoordinate;
      // const TEMP_LAT_LNG = {};
      // const COEFFICIENT_ZOOM = this.mapOptions.coefficientZoom[this.mapOptions.maxZoom];
      forEach(response.content, (marker, markerIndex) => {
        const LAT = marker.lat;
        const LNG = marker.lng;
        // Wenn es keine Koordinaten gibt(PLZ ist falsch), zeigen wir den Marker nicht.
        if (isNil(LAT) || isNil(LNG)) {
          return; // continue in Lodash
        }

        const CLUSTER_KEY = marker.typ;
        this.addPruneCluster({ clusterKey: CLUSTER_KEY });
        this.rerenderMarker({ marker });

        if (markerIndex === 0) {
          maxSouthCoordinate = LAT;
          maxNorthCoordinate = LAT;
          maxWestCoordinate = LNG;
          maxEastCoordinate = LNG;
        }
        maxSouthCoordinate = LAT < maxSouthCoordinate ? LAT : maxSouthCoordinate;
        maxNorthCoordinate = LAT > maxNorthCoordinate ? LAT : maxNorthCoordinate;

        maxWestCoordinate = LNG < maxWestCoordinate ? LNG : maxWestCoordinate;
        maxEastCoordinate = LNG > maxEastCoordinate ? LNG : maxEastCoordinate;
      });
      this.bounds = [
        [maxNorthCoordinate, maxWestCoordinate],
        [maxSouthCoordinate, maxEastCoordinate],
      ];
      forEach(this.pruneCluster, cluster => {
        this.map.addLayer(cluster);
      });
      this.changeMapBounds();
    },

    changeMapBounds() {
      if (isUndefined(this.bounds[0][0]) ||
          isUndefined(this.bounds[0][1]) ||
          isUndefined(this.bounds[1][0]) ||
          isUndefined(this.bounds[1][1])) {
        return;
      }
      this.map.fitBounds(this.bounds);
    },

    clearClusters() {
      forEach(this.pruneCluster, cluster => {
        this.map.removeLayer(cluster);
      });
      this.pruneCluster = {};
    },

    addPruneCluster({ clusterKey }) {
      if (this.pruneCluster[clusterKey]) {
        return;
      }
      const CURRENT_PRUNE_CLUSTER = this.initEventForCluster({
        pruneCluster: this.initIconForCluster({ clusterKey }),
        clusterKey,
      });

      this.pruneCluster[clusterKey] = CURRENT_PRUNE_CLUSTER;
    },

    initIconForCluster({ clusterKey }) {
      const CURRENT_PRUNE_CLUSTER = new PruneClusterForLeaflet();
      CURRENT_PRUNE_CLUSTER.BuildLeafletClusterIcon = clusterLocal => {
        return L.divIcon({
          html: `
          <div class="cluster" style="width: ${ this.markerTypes[clusterKey].cluster_icon_width }px;">
            <img src="/api/assets/${ this.markerTypes[clusterKey].cluster_icon }" alt="cluster" class="cluster__img">
            <div class="cluster__count">
              <div class="cluster__count__child">${ clusterLocal.population }</div>
            </div>
          </div>`,
        });
      };
      return CURRENT_PRUNE_CLUSTER;
    },

    initEventForCluster({ pruneCluster, clusterKey }) {
      pruneCluster.PrepareLeafletMarker = (leafletMarker, data) => {
        leafletMarker.setIcon(data.icon);
        leafletMarker.on("click", () => {
          this.loadAllInfo({ pk: data.id, leafletMarker, clusterKey });
          this.showTooltip = true;
        });

        leafletMarker.on("popupclose", () => {
          this.showTooltip = false;
        });

        if (leafletMarker.getPopup()) {
          leafletMarker.setPopupContent(data.popup);
        } else {
          leafletMarker.bindPopup(data.popup);
        }
      };
      return pruneCluster;
    },

    rerenderMarker({ marker }) {
      const CLUSTER_KEY = marker.typ;
      const MARKER = new PruneCluster.Marker(marker.lat, marker.lng);
      const MARKER_INFO = this.markerTypes[CLUSTER_KEY];
      MARKER.data.icon = L.icon({
        iconUrl: `/api/assets/${ MARKER_INFO.map_icon }` || "/api/assets/map-icon-0.png",
        iconSize: [MARKER_INFO.map_icon_width || 35, MARKER_INFO.map_icon_height || 40],
        iconAnchor: [15, 15],
        shadowUrl: `/api/assets/${ MARKER_INFO.map_icon }` || "/api/assets/map-icon-0.png",
      });
      MARKER.data.id = marker.pk;
      MARKER.data.popup = this.markerAllInfo[marker.pk] ? this.getPopupHtml({ pk: marker.pk, clusterKey: CLUSTER_KEY }) : this.getSpinner;
      this.pruneCluster[CLUSTER_KEY].RegisterMarker(MARKER);
    },

    loadAllInfo({ pk, leafletMarker, clusterKey }) {
      if (this.markerAllInfo[pk]) {
        const POPUP_HTML = this.getPopupHtml({ pk, clusterKey });
        leafletMarker.bindPopup(POPUP_HTML);
        return;
      }
      this.getHttp({
        url: `foerderlandkarte/${ pk }/`
      }).then(
        response => {
          this.setMarkerAllInfo({ response });
          const POPUP_HTML = this.getPopupHtml({ pk, clusterKey });
          leafletMarker.bindPopup(POPUP_HTML);
        }
      );
    },

    setMarkerAllInfo({ response }) {
      this.markerAllInfo[response.pk] = response;
    },

    changeCenter({ center }) {
      if (!center.pbu_latitude || !center.pbu_longitude) {
        return;
      }
      this.map.setView(new L.LatLng(center.pbu_latitude, center.pbu_longitude), 14);
    },
  },
};
