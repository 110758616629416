"use strict";

import angular from "angular";
import "moment";
import "moment-de";
import "angular-moment";

const angularMomentModule = angular.module("dias.angularMoment", [
  "angularMoment",
]);

// Globale Konfigurieruation von AngularStraps bs-datepicker
angularMomentModule.run(/*@ngInject*/amMoment => {
  amMoment.changeLocale("de");
});

export default angularMomentModule;
