import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";

import DashboardModelFilterMixin from "../DashboardModelFilterMixin";
import DashboardModuleMixin from "../DashboardModuleMixin";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";

import { changeTextToId } from "../../../../global/functions/utils";
import {
  cloneDeep,
  debounce,
} from "lodash-es";

// @vue/component
export default {
  name: "DashboardAktionAntragsliste",
  components: {
    PuxCloak,
  },
  mixins: [
    DashboardModelFilterMixin,
    DashboardModuleMixin,
    HttpMixin,
    NotificationMixin,
  ],
  data() {
    return {
      status: {
        loading: undefined,
      },
      classBoxMapping: {
        edit: "edit-box",
        success: "success-box",
        info: "info help",
        info_big: "info help",
        info_edit: "info edit",
        info_success: "info success",
      },
      classIconMapping: {
        edit: "text-color-edit",
        success: "text-color-success",
        info: "",
      },
    };
  },
  computed: {
    hoverText() {
      if (!this.parameter) {
        return;
      }
      if (this.statusClickable) {
        return this.parameter.hover;
      }
      return this.parameter.hover_keine_antraege;
    },

    statusClickable() {
      return this.anz_antraege > 0;
    },

    classIcon() {
      return this.classIconMapping[this.parameter.css_klasse];
    },

    classBox() {
      return this.classBoxMapping[this.parameter.css_klasse];
    },

    classForRobot() {
      return `test_dashboard_${ changeTextToId(this.titel) }`;
    },

    objektliste() {
      return this.data && this.data.objektliste;
    },

    anz_antraege() {
      let anz = 0;
      if (this.data && this.data.anz_antraege) {
        anz = this.data.anz_antraege;
      }
      return anz;
    },

    parameterIcon() {
      return this.parameter && this.parameter.icon;
    },

    filterTeam() {
      return this.parameter && this.parameter.filter_team;
    },
    modelFilter() {
      return this.additionalFilters;
    },

    taglocal() {
      return this.statusClickable ? "a" : "div";
    },

    eventsLocal() {
      if (this.statusClickable) {
        return {
          click: this.antragAnnehmen,
        };
      }
      return {};
    },

    attributesLocal() {
      if (this.statusClickable) {
        return {
          tabindex: 0,
          role: "button",
        };
      }
      return {};
    },
  },
  created() {
    this.debouncedReload = debounce(() => {
      this.sendReloadEvent();
    }, 5 * 1000);
  },

  methods: {
    antragAnnehmen() {
      this.status.loading = true;
      const data = { filter_team: this.filterTeam };
      this.putHttp({
        url: `objektlisten/${ this.objektliste }/antragslistenelemente/do_action_on_next/`,
        urlParams: this.modelFilter,
        data,
      }).then(
        response => {
          if (response.msg) {
            this.addNotification({ text: response.msg });
            /* Konfigurierte Dashboardelemente
            (Parameter reload_receivers) benachrichten: */
            this.debouncedReload();
            const DATA_LOCAL = cloneDeep(this.data);
            DATA_LOCAL.anz_antraege = response.anzahl_remaining;
            this.dataLocal = DATA_LOCAL;
          }
        },
        error => {
          this.addNotification({ text: error.error });
          const DATA_LOCAL = cloneDeep(this.data);
          DATA_LOCAL.anz_antraege = error.anz_antraege;
          this.dataLocal = DATA_LOCAL;
        }
      ).finally(() => this.status.loading = false);
    },
  },
};
