"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";
import ngAnimate from "angular-animate";
require("angular-moment");

// import listComponent from "./list.component";

const anonymisierteNutzerModule = angular.module("auth.anonymisierte_nutzer", [
  ngAnimate,
  uiRouter,
  "angularMoment",
]);

anonymisierteNutzerModule.config($stateProvider => {
  $stateProvider

    // Liste
    .state("root.anonymisierte_nutzer", {
      url: "/anonymisierte_nutzer/",
      reloadOnSearch: false,
      views: {
        "main@root": {
          // template: "<anonymisierte-nutzer-list></anonymisierte-nutzer-list>"
          template: "<div vueinit><vue-nutzer-anonymisiert-list></vue-nutzer-anonymisiert-list></div>"
        }
      },
      data: {
        permissions: ["nutzer.anonymise.view"]
      }
    })
  ;
})

// .component("anonymisierteNutzerList", listComponent)

;
export default anonymisierteNutzerModule;
