import DokumentDownload from "../../Dokumente/DokumentDownload/DokumentDownload.vue";

import TemplateAdminBasisdaten from "./TemplateAdminBasisdaten/TemplateAdminBasisdaten.vue";
import TemplateAdminFunktionenBox from "./TemplateAdminFunktionenBox/TemplateAdminFunktionenBox.vue";
import TemplateAdminInhalt from "./TemplateAdminInhalt/TemplateAdminInhalt.vue";
import TemplateAdminKonfiguration from "./TemplateAdminKonfiguration/TemplateAdminKonfiguration.vue";
import TemplateAdminModalCopy from "./TemplateAdminModalCopy/TemplateAdminModalCopy.vue";
import TemplateAdminParameterBox from "./TemplateAdminParameterBox/TemplateAdminParameterBox.vue";
import TemplateAdminVorlage from "./TemplateAdminVorlage/TemplateAdminVorlage.vue";

import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import {
  isNull,
} from "lodash-es";

// @vue/component
export default {
  name: "TemplateAdminDetails",
  components: {
    ContextPanel,
    DokumentDownload,
    Modal,
    PuxButton,
    PuxPageDetails,
    PuxTranslate,
    TemplateAdminBasisdaten,
    TemplateAdminFunktionenBox,
    TemplateAdminInhalt,
    TemplateAdminKonfiguration,
    TemplateAdminModalCopy,
    TemplateAdminParameterBox,
    TemplateAdminVorlage,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  data() {
    return {
      allParameters: [],
      allFunktionen: [],
      buttonDeleteId: "template_modal_confirm_delete",
      confirmDeleteShow: false,
      confirmDeleteOptions: undefined,
      copyModalVisible: false,
      funktionenLoadingError: null,
      loading: {
        template: true,
        params: false,
        funktionen: false,
      },
      panelOptionContent: {
        label: "_TXT_TEMPLATEADMIN_DETAILS_TEMPLATEINHALT_PANEL_NAME_",
        icon: "empty-document",
        active: true,
      },
      panelOptionOverview: {
        label: "_TXT_TEMPLATEADMIN_DETAILS_UEBERSICHT_PANEL_NAME_",
        icon: "list",
        active: false,
      },
      paramLoadingError: null,
      template: {},
    };
  },
  computed: {
    urlRender() {
      return `templates/${ this.template.tpl_id }/render/`;
    },

    urlTemplateFunktionen() {
      return `templates/${ this.$stateParams.id }/funktionen/`;
    },

    urlTemplateParameter() {
      return `templates/${ this.$stateParams.id }/parameter/`;
    },

    urlTemplate() {
      return `templates/${ this.$stateParams.id }/`;
    },

    canGenerateTemplate() {
      return this.checkPermissionsSync({ perm: ["template.generation"] }) && this.template.content_editable;
    },

    canCopyTemplate() {
      return this.checkPermissionsSync({ perm: ["templateadmin.create"] });
    },

    canDeleteTemplate() {
      return this.checkPermissionsSync({ perm: ["template.delete"] });
    },

    isTemp() {
      return !isNull(this.template.tpl_content_temp);
    },

    parameters() {
      return this.allParameters;
    },

    funktionen() {
      return this.allFunktionen;
    },
  },
  created() {
    this.loadTemplate();
  },

  selectorDeleteClose() {
    return `#${ this.buttonDeleteId }`;
  },
  methods: {
    loadTemplate() {
      this.getHttp({
        url: this.urlTemplate,
      }).then(
        response => {
          this.template = response;
          this.template.tpl_parameterin = this.template.tpl_parameterin || {};
          this.loading.template = false;
          this.loadParameters();
          this.loadFunktionen();
        }
      );
    },

    loadParameters() {
      if (this.template.content_editable) {
        this.loading.params = true;
        this.getListHttp({
          url: this.urlTemplateParameter,
        }).then(
          response => {
            this.allParameters = response;
            this.paramLoadingError = null;
          },
          error => {
            this.paramLoadingError = error.data[0];
          }
        ).finally(
          () => {
            this.loading.params = false;
          }
        );
      }
    },

    loadFunktionen() {
      if (this.template.content_editable) {
        this.loading.funktionen = true;
        this.getListHttp({
          url: this.urlTemplateFunktionen,
        }).then(
          response => {
            this.allFunktionen = response;
            this.funktionenLoadingError = null;
          },
          error => {
            this.funktionenLoadingError = error.data[0];
          }
        ).finally(
          () => {
            this.loading.funktionen = false;
          }
        );
      }
    },

    openModalCreateCopyTemplate() {
      this.copyModalVisible = true;
    },

    closeModalCreateCopyTemplate() {
      this.copyModalVisible = false;
    },

    updateTemplate({ template }) {
      this.template = template;
      this.loadParameters();
      this.loadFunktionen();
    },

    openModalDeleteTemplate() {
      this.confirmDeleteOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_TEMPLATE_ADMIN_CONFIRM_DELETE_HEADER_{{bez}}_",
        msg: "_TXT_TEMPLATE_ADMIN_CONFIRM_DELETE_BODY_{{bez}}_",
        extra: {
          bez: this.template.tpl_kbez
        },
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: () => this.deleteTemplate(),
        cancelCallback: this.closeConfirmDelete,
        loading: false,
      };
      this.confirmDeleteShow = true;
    },

    deleteTemplate() {
      this.confirmDeleteOptions.loading = true;
      this.deleteHttp({
        url: `templates/${ this.template.pk }/`,
      }).then(
        () => {
          this.closeConfirmDelete();
          this.$goTo("root.templateadmin");
        }
      ).then(
        () => {
          this.confirmDeleteOptions.loading = false;
        }
      );
    },

    closeConfirmDelete() {
      this.confirmDeleteShow = false;
    },
  },
};
