"use strict";

import BaseModel from "dias/core/models/baseModel";
import Objektaufgabe from "dias/workflow/models/objektaufgabe.service";
import Kosten from "./kosten.service";
import Finanzierung from "./finanzierung.service";
import Auszahlungen from "./auszahlungen.service";
import Dokumente from "dias/dokumente/models/dokumente.service";
import Auszahlungsplan from "./auszahlungsplan.service";
import Vertreter from "dias/antragsteller/models/vertretungsberechtigter.service";
import ObjektBenachrichtigung from "dias/benachrichtigungen/models/objektbenachrichtigung.service";
import Belegdokumente from "./vertragsbelegdokument.service";
import Foerdermodule from "dias/foerderantrag/foerdermodule/models/foerdermodule.service";

class Vertrag extends BaseModel {
  constructor() {
    super("vertraege", {
      subPaths: {
        aufgaben: new Objektaufgabe(),
        kosten: new Kosten(),
        finanzierung: new Finanzierung(),
        dokumente: new Dokumente(),
        auszahlungen: new Auszahlungen(),
        vertretungsberechtigte: new Vertreter(),
        benachrichtigungen: new ObjektBenachrichtigung(),
        auszahlungsplan: new Auszahlungsplan(),
        belege: "belege",
        belegdokumente: new Belegdokumente(),
        module: new Foerdermodule(),
      }
    });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.getKAufgaben = () => collection.customGET("kaufgaben");
    return collection;
  }

  extendModel(model) {
    model = super.extendModel(model);

    model.getPreview = () => model.customGET("vorschau");

    model.getGesamtKoFi = labelKoFi => model.customGET("gesamt" + labelKoFi);

    model.createAuszahlung = () => model.customPOST({}, "auszahlungen");

    model.checkPermissions = (permissions, fuerAktuelleAuszahlung) => {
      const params = {
        permissions: permissions,
      };
      if (fuerAktuelleAuszahlung !== undefined) {
        params.aktuelle_auszahlung = fuerAktuelleAuszahlung ? "True" : "False";
      }
      return model.customGET("check_my_permissions", params);
    };

    model.putFvb = (von, bis) => model.customPUT({ von: von, bis: bis }, "fvb_durchfuehrungszeitraum").then(
      response => {
        model.merge(model.foerdervertragsbestaetigung, response);
        model._updateFvbData();
      }
    );

    model._updateFvbData = () => {
      if (!model.foerdervertragsbestaetigung) {
        return;
      }
      if (!model.foerdervertragsbestaetigung.durchfuehrungszeitraum) {
        model.foerdervertragsbestaetigung.durchfuehrungszeitraum = "Keine Angabe";
      }
      if (!model.foerdervertragsbestaetigung.durchfuehrungsdauer) {
        model.foerdervertragsbestaetigung.durchfuehrungsdauer = "-";
      }
    };

    model.restangularizeAufgabe = aufgabe => this.Restangular.restangularizeElement(model, aufgabe, "aufgaben", {});

    if (model.aktuelle_aufgabe) {
      // console.log("restangularize");
      model.aktuelle_aufgabe = model.restangularizeAufgabe(model.aktuelle_aufgabe);
      // console.log(model.aktuelle_aufgabe);
    }
    model._updateFvbData();

    model.saveBankverbindung = data => {
      return model.customPUT(data, "fvb_bankverbindung");
    };

    model.saveAbschlag = data => {
      return model.customPUT(data, "fvb_abschlag");
    };

    model.getKonto = () => {
      const deferred = this.$q.defer();
      model.customGET("check_fibu_permission").then(
        response => {
          if (response.status) {
            model.customGET("konto").then(
              response => deferred.resolve(response),
              err => deferred.reject(err)
            );
          } else {
            deferred.reject();
          }
        },
        err => deferred.reject(err)
      );
      return deferred.promise;
    };

    return model;
  }
}

export default Vertrag;
