"use strict";

import angular from "angular";

import ListenstatusUUIDs from "./listenstatusUUIDs.service";
import TeilnehmerstatusUUIDs from "./teilnehmerstatusUUIDs.service";

const modelsModule = angular.module("sitzungen.services", [
])

  .constant("ListenstatusUUIDs", ListenstatusUUIDs)
  .constant("TeilnehmerstatusUUIDs", TeilnehmerstatusUUIDs)
;

export default modelsModule;
