"use strict";

import angular from "angular";

import models from "./models";

import nutzerdatensuche from "./nutzerdatensuche";


const privacyModule = angular.module("privacy", [
  models.name,
  nutzerdatensuche.name,
]);

export default privacyModule;
