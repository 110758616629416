import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  get,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragErrorsDdItem",
  components: {
    PuxButton,
    PuxTranslate,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
    errorIndex: {
      type: Number,
      required: true,
    },
    errorGroupIndex: {
      type: [Number, String],
      required: true,
    },
  },
  emits: [
    "goTo",
  ],
  computed: {
    buttonId() {
      return `vertrag_goto_module_error_${ this.errorIndex }_${ this.errorGroupIndex }`;
    },

    extraNameForTranslate() {
      return {
        name: get(this.error, "modul.kbez"),
      };
    },

    modulname() {
      return get(this.error, "modul.modulname");
    },

    errorsAntworten() {
      return get(this.error, "errors.antworten");
    },

    hasErrorsAntwortenSize() {
      return !!(this.errorsAntworten && size(this.errorsAntworten));
    },

    hasErrorsSize() {
      return !!size(this.error.errors);
    },
  },
  methods: {
    goTo() {
      this.$emit("goTo", { error: this.error, onRegel: true });
    },

    size(arg) {
      return size(arg);
    },
  },
};
