// Modul zur Erfassung von einfachen Kosten
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";
import VertragModels from "dias/vertrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const standard_zuschuss = angular.module("dias.kosten_finanzierung.modul.standard_zuschuss", [
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
  VertragModels.name,
]);

standard_zuschuss.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("standard_zuschuss", "modul-standard-zuschuss");
})

  .directive("modulStandardZuschuss", builder.directive(template, controller));

export default standard_zuschuss;
