import translate from "../../../../../global/directives/translate";
import AuswahlKatalogAuswahlMixin from "../AuswahlKatalogAuswahlMixin.js";
import { debounce } from "lodash-es";

// @vue/component
export default {
  name: "AuswahlKatalogCheckboxEdit",
  directives: {
    translate
  },
  mixins: [
    AuswahlKatalogAuswahlMixin,
  ],
  props: {
    errors: {
      type: Object,
      default: undefined
    },
    onToggle: {
      type: Function,
      default: undefined,
    },
    onFreitext: {
      type: Function,
      default: undefined,
    },
  },
  computed: {
    translationFreitext() {
      return { placeholder: "_STANDARD_FOERDERMODUL_AUSWAHL_KATALOG_AUSWAHL_FREITEXT_PLACEHOLDER_" };
    },

    debouncedOnFreitext() {
      return debounce((el, $event) => {
        this.onFreitext(el, $event);
      }, 250);
    },
  },
  methods: {
    getSelectionId(el) {
      return `${ this.htmlRegelNummer }_${ el.pk }_selection`;
    },

    getInputId(el) {
      return `${ this.htmlRegelNummer }_${ el.pk }_freitext`;
    },

    showFreitext(el) {
      return this.getSelectionStatus(el) && el.freitext;
    },

    getError(el) {
      if (this.errors) {
        return this.errors[el.pk];
      }
    },

    getAriaInvalid(el) {
      return this.getError(el);
    },

    getFreitextClass(el) {
      if (this.getError(el)) {
        return "is-invalid";
      }
    },

    getSelectionClass(el) {
      if (this.getError(el)) {
        return "is-invalid";
      }
    },
  },
};
