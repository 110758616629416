import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";

import DashboardModuleMixin from "../DashboardModuleMixin";

import {
  changeTextToId,
} from "../../../../global/functions/utils";

// @vue/component
export default {
  name: "DashboardEinfacherZaehler",
  components: {
    AngularLink,
  },
  mixins: [
    DashboardModuleMixin,
  ],
  computed: {
    dataLabel() {
      return this.data && this.data.label;
    },

    dataIcon() {
      return this.data && this.data.icon;
    },

    dataCount() {
      return this.data && this.data.count;
    },

    statusClickable() {
      return !!(this.data && this.data.state_name);
    },

    classForRobot() {
      const CLASS_NAME = this.data && this.data.label;
      return `test_dashboard_${ changeTextToId(CLASS_NAME) }`;
    },

    localAttributes() {
      if (!(this.data && this.data.state_name)) {
        return;
      }
      const ATTRIBUTES = {
        "aria-labelledby": this.ariaLabelledbyForBadgeLink,
      };
      if (this.data.count === 1) {
        ATTRIBUTES.sref = `root.${ this.data.state_name }.details`;
        ATTRIBUTES.parameter = { id: this.data.obj_pk };
        return ATTRIBUTES;
      }
      let params = {};
      if (this.data.state_list_params) {
        params = this.data.state_list_params;
      }
      ATTRIBUTES.sref = `root.${ this.data.state_name }`;
      ATTRIBUTES.options = params;
      return ATTRIBUTES;
    },

    titleLocal() {
      return `${ this.dataLabel }: ${ this.dataCount }`;
    },
  },
};
