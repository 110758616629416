import AntraegeList from "../Antraege/AntraegeList/AntraegeList.vue";
import AntragstellerList from "../Antragsteller/AntragstellerList/AntragstellerList.vue";
import AuszahlungList from "../Auszahlung/AuszahlungList/AuszahlungList.vue";
import BelegeList from "../Belege/BelegeList/BelegeList.vue";
import VertragList from "../Vertrag/VertragList/VertragList.vue";

import NotificationMixin from "../../global/mixins/NotificationMixin";
import DynamicRootMixin from "./DynamicRootMixin";
import PermissionMixin from "../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../global/mixins/SyConfigMixin";

import {
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "DynamicRoot",
  components: {
    AntraegeList,
    AntragstellerList,
    AuszahlungList,
    BelegeList,
    VertragList,
  },
  mixins: [
    DynamicRootMixin,
    NotificationMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  data() {
    return {
      config: undefined,
    };
  },
  computed: {
    componentsMapping() {
      return keyBy(this.componentsListe, "value");
    },
  },
  created() {
    this.checkSyConfig();
  },
  methods: {
    checkSyConfig() {
      const CONFIG = this.getSyConfigsValue("menue_config", {}).dynamic_root || {};
      const CONFIG_CURRENT = CONFIG[this.$stateParams.id] || {};
      const COMPONENT_CURRENT = this.componentsMapping[CONFIG_CURRENT.component];
      if (COMPONENT_CURRENT) {
        if (COMPONENT_CURRENT.permissions && !this.checkPermissionsSync({ perm: COMPONENT_CURRENT.permissions })) {
          this.addNotification({
            text: "_MSG_DYNAMIC_ROOT_ERROR_KEINE_PERMISSIONS_",
            type: "error",
          });
          return;
        }
        this.config = CONFIG_CURRENT;
      } else {
        this.addNotification({
          text: "_MSG_DYNAMIC_ROOT_ERROR_",
          type: "error",
        });
      }
    },
  },
};
