import BetragsrechnerMixin from "../Mixins/BetragsrechnerMixin";
import TagespauschaleMixin from "../Mixins/TagespauschaleMixin";

import {
  cloneDeep,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "Ferienkostenrechner",
  mixins: [
    BetragsrechnerMixin,
    TagespauschaleMixin,
  ],
  emits: [
    "changeModel",
  ],
  data() {
    return {
      labelDatumAnreise: "_LBL_FERIENKOSTENRECHNER_MIT_REISETAGEN_DATUM_ANREISE_",
      labelDatumAbreise: "_LBL_FERIENKOSTENRECHNER_MIT_REISETAGEN_DATUM_ABREISE_",
      labelAnzahlBetreuer: "_LBL_FERIENKOSTENRECHNER_MIT_REISETAGEN_ANZAHL_BETREUER_",
    };
  },
  computed: {
    optionsDatumAnreise() {
      return {
        id: "datum_anreise",
        htmlId: this.htmlIdDatumAnreise,
        label: this.labelDatumAnreise,
        view: this.view,
        type: "date",
        required: true,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdDatumAnreise() {
      return `${ this.htmlId }_datum_anreise`;
    },

    optionsDatumAbreise() {
      return {
        id: "datum_abreise",
        htmlId: this.htmlIdDatumAbreise,
        label: this.labelDatumAbreise,
        view: this.view,
        type: "date",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdDatumAbreise() {
      return `${ this.htmlId }_datum_abreise`;
    },

    optionsAnzahlBetreuer() {
      return {
        id: "anzahl_betreuer",
        htmlId: this.htmlIdAnzahlBetreuer,
        label: this.labelAnzahlBetreuer,
        view: this.view,
        type: "text",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdAnzahlBetreuer() {
      return `${ this.htmlId }_anzahl_betreuer`;
    },

    errorLabels() {
      return {
        datum_anreise: {
          label: this.labelDatumAnreise,
          link: true,
          id: this.htmlIdDatumAnreise,
        },
        datum_abreise: {
          label: this.labelDatumAbreise,
          link: true,
          id: this.htmlIdDatumAbreise,
        },
        anzahl_betreuer: {
          label: this.labelAnzahlBetreuer,
          link: true,
          id: this.htmlIdAnzahlBetreuer,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
    this.changeModelWithTagespauschale();
    this.vorbelegungInBeleg();
  },
  methods: {
    vorbelegungInBeleg() {
      if (this.kontext !== "beleg") {
        return;
      }
      const STATUS_BELEG_VORBELEGUNG = get(this.extra, "modelParameter.beleg_vorbelegen", false);
      if (STATUS_BELEG_VORBELEGUNG && !this.model.pk) {
        const MODEL = cloneDeep(this.model);
        MODEL.argument = Object.assign({}, MODEL.argument, this.extra.positionArgument);
        // Belegfelder vorbelegen beim Erstellen
        // forEach(MODEL.argument, (value, key) => {
        //   MODEL.argument[key] = MODEL.argument[key] || EXTRA_ARGUMENT[key] || null;
        // });

        this.$emit("changeModel", { model: MODEL });
      }
    },
  },
};
