export default [
  {
    id: "a_bundesland",
    label: "_LBL_BERICHT_ANTRAG_DETAILS_ASIDE_FILTERS_BUNDESLAND_",
    type: "multiselect",
    url: "katalog/?fields=id&fields=bun_bez&key=kbundesland",
    keyId: "pk",
    keyLabel: "bun_bez",
    view: "v",
    search: true,
    searchList: ["bun_bez"],
    selectAll: true,
    deselectAll: true,
    countMulti: 2,
    translate: true,
  },
  {
    id: "zeitraum_antragsdatum",
    type: "template",
    html: "_HTML_BERICHT_ANTRAG_DETAILS_ASIDE_FILTERS_ZEITRAUM_ANTRAGSDATUM_",
    translate: true,
  },
  {
    id: "a_antragsdatum_after",
    label: "_LBL_BERICHT_ANTRAG_DETAILS_ASIDE_FILTERS_VON_",
    type: "date",
    view: "v",
    translate: true,
  },
  {
    id: "a_antragsdatum_before",
    label: "_LBL_BERICHT_ANTRAG_DETAILS_ASIDE_FILTERS_BIS_",
    type: "date",
    view: "v",
    translate: true,
  },
  {
    id: "zeitraum_bewilligungsdatum",
    type: "template",
    html: "_HTML_BERICHT_ANTRAG_DETAILS_ASIDE_FILTERS_ZEITRAUM_BEWILLIGUNGSDATUM_",
    translate: true,
  },
  {
    id: "a_bewilligungsdatum_after",
    label: "_LBL_BERICHT_ANTRAG_DETAILS_ASIDE_FILTERS_VON_",
    type: "date",
    view: "v",
    translate: true,
  },
  {
    id: "a_bewilligungsdatum_before",
    label: "_LBL_BERICHT_ANTRAG_DETAILS_ASIDE_FILTERS_BIS_",
    type: "date",
    view: "v",
    translate: true,
  },
];
