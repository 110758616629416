import PuxPageDetails from "../../../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import SmartTable from "../../../../../global/components/table/table.vue";
import getTableApi from "../../../../../const/TableApis";
import Spaced from "../../../../../global/components/Spaced/Spaced.vue";

const TABLE_ID = "EntscheidungslisteAPNRW";

// @vue/component
export default {
  name: "EntscheidungslisteAPNRW",
  components: {
    SmartTable,
    Spaced,
    PuxPageDetails,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        label: this.report.tab_kbez,
        sortable: true,
        pagination: true,
        details: false,
        showHeader: false,
        view: {
          filter: "top",
        },
        template: {
          obj_url: "tableau",
          obj_id: this.report.pk
        }
      }
    };
  },

  methods: {
  },
};
