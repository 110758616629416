import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "VertragEingereichtMeldung",
  components: {
    AngularLink,
    PuxAlert,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    einreichenStatus: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isMsgVisible: true,
    };
  },
  computed: {
    isMsgVisibleLocal() {
      return this.einreichenStatus && this.isMsgVisible;
    },
  },
  methods: {
    closeMsg() {
      this.isMsgVisible = false;
    },
  },
};
