import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../../../global/mixins/SyConfigMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "BankverbindungModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin
  ],
  props: {
    selectorClose: {
      type: [String, Array],
      required: true,
    },
    urlSave: {
      type: String,
      required: true,
    },
    messageCreateSuccess: {
      type: String,
      required: false,
      default: "_TXT_GR_AST_BANKVERBINDUNGEN_MODAL_CREATE_SUCCESS_",
    },
    idPrefix: {
      type: String,
      required: false,
      default: "",
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    optionsModal() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          this.optionsForInhaber,
          this.optionsForIban,
          this.optionsForBic,
          this.optionsForBank,
        ],
      };
    },

    optionsForInhaber() {
      const ID = `${ this.idPrefix }inhaber`;
      return {
        id: ID,
        htmlId: `modal_${ ID }`,
        type: "text",
        label: "_LBL_GR_AST_BANKVERBINDUNGEN_INHABER_",
        view: "v",
        required: true,
      };
    },

    optionsForIban() {
      const ID = `${ this.idPrefix }iban`;
      return {
        id: ID,
        htmlId: `modal_${ ID }`,
        type: "text",
        label: "_LBL_GR_AST_BANKVERBINDUNGEN_IBAN_",
        view: "v",
        required: true,
      };
    },

    optionsForBic() {
      const ID = `${ this.idPrefix }bic`;
      return {
        id: ID,
        htmlId: `modal_${ ID }`,
        type: "text",
        label: "_LBL_GR_AST_BANKVERBINDUNGEN_BIC_",
        view: "v",
        required: this.isFeldBicRequired,
      };
    },

    isFeldBicRequired() {
      return this.bankverbindungAdditionalFieldsRequired.bic;
    },

    optionsForBank() {
      const ID = `${ this.idPrefix }bank`;
      return {
        id: ID,
        htmlId: `modal_${ ID }`,
        type: "text",
        label: "_LBL_GR_AST_BANKVERBINDUNGEN_BANK_",
        view: "v",
        required: this.isFeldBankRequired,
      };
    },

    isFeldBankRequired() {
      return this.bankverbindungAdditionalFieldsRequired.bank;
    },

    bankverbindungAdditionalFieldsRequired() {
      return this.getSyConfigsValue("bankverbindung_weitere_pflichtfelder", {});
    },
  },
  methods: {
    saveLocal() {
      this.status.loading = true;
      this.clearErrors();
      const DATA = this.model;
      this.postHttp({
        url: this.urlSave,
        data: DATA,
      }).then(
        response => {
          this.addNotification({
            text: this.messageCreateSuccess,
          });
          this.close({ response });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = cloneDeep(error.data);
        }
      ).then(
        () => this.status.loading = false
      );
    },

    clearErrors() {
      this.errors = undefined;
      this.errorsClone = undefined;
    },
  },
};
