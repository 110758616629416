import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "WorkflowfolgenModalCreate",
  components: {
    Modal,
    PuxButton,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        wfo_pos: 0
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "wfo_kbez_admin",
            type: "text",
            label: this.$gettext("Name in Administration"),
            required: true,
            maxlength: 100,
            view: "v",
          },
          {
            id: "wfo_kbez",
            type: "text",
            label: this.$gettext("Name"),
            required: true,
            maxlength: 60,
            view: "v",
          },
          {
            id: "aufgabe",
            type: "select",
            label: this.$gettext("Ausgangsaufgabe"),
            required: false,
            keyLabel: "bez",
            keyId: "id",
            search: true,
            searchList: ["bez"],
            view: "v",
            url: "katalog/?fields=id&fields=bez&key=kaufgabe",
          },
          {
            id: "nachfolge_aufgabe",
            type: "select",
            label: this.$gettext("Nachfolgeaufgabe"),
            required: false,
            keyLabel: "bez",
            keyId: "id",
            search: true,
            searchList: ["bez"],
            view: "v",
            url: "katalog/?fields=id&fields=bez&key=kaufgabe",
          },
          {
            id: "workflow",
            type: "select",
            label: this.$gettext("Workflow"),
            required: false,
            keyLabel: "bez",
            keyId: "id",
            keyGroup: "group",
            search: true,
            searchList: ["bez"],
            view: "v",
            url: "katalog/?fields=id&fields=bez&key=workflow",
          },
          {
            id: "nachfolge_workflow",
            type: "select",
            label: this.$gettext("Nachfolgeworkflow"),
            required: false,
            keyLabel: "bez",
            keyId: "id",
            keyGroup: "group",
            search: true,
            searchList: ["bez"],
            view: "v",
            url: "katalog/?fields=id&fields=bez&key=workflow",
          },
          {
            id: "wfo_anzeige_typ",
            type: "text",
            label: this.$gettext("Anzeige-Styling"),
            required: false,
            default: "default",
            maxlength: 200,
            view: "v",
          },
          {
            id: "wfo_anzeige_icon",
            type: "text",
            label: this.$gettext("Icon"),
            required: false,
            default: "glyphicon-ok",
            maxlength: 200,
            view: "v",
          },
          {
            id: "wfo_pos",
            type: "number",
            label: this.$gettext("Position"),
            required: true,
            default: 0,
            maxlength: 200,
            view: "v",
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  created() {

  },
  methods: {
    onChange() { },

    onError({ error }) {
      this.errors = error;
      this.errorsClone = error;
    },

    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);

      this.postHttp({ url: "workflowfolgen/", data }).then(
        response => {
          this.addNotification({ text: "_MSG_WFF_MODAL_CREATE_SUCCESS_" });
          this.close();
          this.$goTo("root.workflowfolgen.detail", { id: response.pk });
        },
        error => {
          this.onError({ error: error.data });
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
