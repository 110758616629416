"use strict";

class TeilnehmerAddController {
  /*@ngInject*/
  constructor($q, DiasNotification, $scope) {
    this.model = {};
    this.$q = $q;
    this.DiasNotification = DiasNotification;
    this.users = $scope.extras.users;
    this.sitzung = $scope.extras.sitzung;
    this.saveTeilnehmer = this.saveTeilnehmer.bind(this);
  }

  saveTeilnehmer() {
    const deferred = this.$q.defer();

    if (!this.modelUsers || !this.modelUsers.length) {
      deferred.resolve();
      return deferred.promise;
    }

    let counter = 0;
    angular.forEach(this.modelUsers, value => {
      const data = {
        sitzung: this.sitzung.pk,
        teilnehmer: value
      };
      this.sitzung.teilnehmer.post(data).then(
        () => {
          counter++;
          if (counter === this.modelUsers.length) {
            this.DiasNotification.page.success("Teilnehmer erfolgreich hinzugefügt");
            deferred.resolve();
          }
        },
        error => {
          deferred.reject();
          this.onError(error);
        }
      );
    });

    return deferred.promise;
  }
}

export default TeilnehmerAddController;
