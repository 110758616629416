import NotizenInlineItem from "../NotizenInlineItem/NotizenInlineItem.vue";

import NotizenMixin from "../NotizenMixin";

// @vue/component
export default {
  name: "NotizenInline",
  components: {
    NotizenInlineItem,
  },
  mixins: [
    NotizenMixin,
  ],
};
