import Alert from "../../../../../global/components/Alert/Alert.vue";
import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import translate from "../../../../../global/directives/translate";

// @vue/component
export default {
  name: "MissingAntragstellerBeschreibung",
  components: {
    Alert,
    AngularLink,
  },
  directives: {
    translate,
  },
  props: {
    obj: {
      type: Object,
      required: true,
    },
    astPk: {
      type: String,
      required: true,
    },
    keyName: {
      type: String,
      required: true,
    },
    keyBeschreibung: {
      type: String,
      required: true,
    },
    astName: {
      type: String,
      required: true,
    },
    translatePrefix: {
      type: String,
      required: true,
    },
    translateSuffix: {
      type: String,
      required: true,
    },
  },
  computed: {
    translateOptionsPrefix() {
      return {
        html: this.translatePrefix,
        extra: {
          astName: this.obj[this.keyName],
        },
      };
    },
  },
};
