import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import DokumenteUploadAll from "./DokumenteUploadAll/DokumenteUploadAll.vue";
import DokumenteUploadNew from "./DokumenteUploadNew/DokumenteUploadNew.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "DokumenteUpload",
  components: {
    ContextPanel,
    DokumenteUploadAll,
    DokumenteUploadNew,
    PuxPageDetails,
    PuxTranslate,
  },
  data() {
    return {
      panelOptionsNew: {
        label: "_TXT_DOKUMENTE_UPLOAD_HINZUEFUGEN_",
        icon: "glyphicon-file",
        active: true,
      },
      panelOptionsAll: {
        label: "_TXT_DOKUMENTE_UPLOAD_AKTUELL_",
        icon: "glyphicon-file",
        active: true,
      },
    };
  },
};
