"use strict";

const diasTemplates = angular.module("dias.templates", []);

diasTemplates.config($stateProvider => {
  $stateProvider
    .state("root.impressum", {
      url: "/impressum/",
      views: {
        "main@root": {
          template: `<div vueinit class="col-lg-6 no-padding"><vue-impressum></vue-impressum></div>`,
        }
      },
    })
    .state("root.kontakt", {
      url: "/kontakt/",
      views: {
        "main@root": {
          template: `<div vueinit class="col-lg-6 no-padding"><vue-contact></vue-contact></div>`,
        }
      },
    })
    .state("root.datenschutz", {
      url: "/datenschutz/",
      views: {
        "main@root": {
          template: `<div vueinit class="col-lg-6 no-padding"><vue-datenschutz></vue-datenschutz></div>`,
        }
      },
    })
    .state("root.cookierichtlinien", {
      url: "/cookie-richtlinien/",
      views: {
        "main@root": {
          template: `<div vueinit class="col-lg-6 no-padding"><vue-cookie-richtlinien></vue-cookie-richtlinien></div>`,
        }
      },
    })
    .state("root.barrierefreiheit", {
      url: "/barrierefreiheit/",
      views: {
        "main@root": {
          template: `<div vueinit class="col-lg-6 no-padding"><vue-barrierefreiheit></vue-barrierefreiheit></div>`,
        }
      },
    })
  ;
})
;

export default diasTemplates;
