import ContextPanel from "../../../../global/components/ContextPanel/ContextPanel.vue";
import PanelComponents from "./PanelComponents";
import PuxPageDetails from "../../../../global/components/PuxPageDetails/PuxPageDetails.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "BerichtStandard",
  components: {
    ContextPanel,
    PuxPageDetails,
    ...PanelComponents,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      berichte: [],
      panels: [
        {
          label: "_TXT_BERICHT_STANDARD_DETAILS_CHARTS_PANEL_NAME_",
          icon: "glyphicon-list",
          active: true,
          pk: "berichtstandard",
          components: [
            {
              name: "BerichtStandardCharts",
              pk: "berichtstandardcharts",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.loadStandardBerichte();
  },
  methods: {
    loadStandardBerichte() {
      this.getListHttp({
        url: "berichte/standard/",
      }).then(
        response => {
          this.berichte = response;
        }
      );
    },

    updateCurrentBericht({ response, index }) {
      this.berichte.splice(index, 1, response);
    },
  },
};
