"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import "restangular";

import errors from "dias/core/errors";
import notification from "dias/core/notification";

import Katalog from "./katalog.service";
import KatalogAdmin from "./katalogAdmin.service";

const modelsModule = angular.module("core.kataloge.models", [
  "restangular",
  uiRouter,
  errors.name,
  notification.name
])

  .provider("Katalog", Katalog)
  .provider("KatalogAdmin", KatalogAdmin)
;

export default modelsModule;
