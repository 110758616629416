import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "WorkflowtypDetailsStatusgruppenBlockEditForm",
  components: {
    PuxIcon,
    PuxTranslate,
    FormElement,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    workflowgruppe: {
      type: Object,
      required: true,
    },
    getStatus: {
      type: Function,
      required: true,
    },
    errors: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  emits: [
    "updateModel",
  ],
  data() {
    return {
      htmlIdPrefix: "edit_workflowtypgruppe_",
      view: "v-alt",
      model: {
        wsg_kbez: undefined,
        au_ids: [],
      },
    };
  },
  computed: {
    optionsForBezeichnung() {
      const ID = "wsg_kbez";
      return {
        id: ID,
        htmlId: `${ this.htmlIdPrefix }_${ this.workflowgruppe.wsg_pos }_${ ID }`,
        type: "text",
        view: this.view,
        label: "_LBL_WORKFLOWTYP_DETAILS_BEZEICHNUNG_",
        required: true,
        change: item => this.onChangeBezechnung({ item: item }),
      };
    },

    optionsForAufgaben() {
      const ID = "au_ids";
      return {
        id: ID,
        htmlId: `${ this.htmlIdPrefix }_${ this.workflowgruppe.wsg_pos }_${ ID }`,
        type: "selectordered",
        view: this.view,
        startupFocus: true,
        deselect: true,
        menuWidthAll: true,
        label: "_LBL_WORKFLOWTYP_DETAILS_AUFGABEN_",
        keyId: "pk",
        url: `katalog/?key=kaufgabe&workflowtypen=${ this.workflowgruppe.workflowtyp }`,
        apiSaveId: "kaufgabe",
        keyLabel: "bez",
        search: true,
        searchList: ["bez"],
        searchParameter: "bez",
        change: this.onChangeAufgaben,
      };
    },

    errorsLocal() {
      if (this.errors.length) {
        return this.errors.find(item => item.wgs_pos === this.workflowgruppe.wsg_pos);
      }
      return {};
    },

    workflogruppeStatus() {
      return this.getStatus({
        wsg_pos: this.workflowgruppe.wsg_pos,
        wtg_id: this.workflowgruppe.workflowstatusgruppentyp,
      });
    }
  },
  created() {
    this.setModel();
  },
  methods: {
    setModel() {
      this.model.wsg_kbez = this.workflowgruppe.wsg_kbez;
      this.model.au_ids = this.workflowgruppe.au_ids;
    },

    onChangeBezechnung({ item }) {
      this.changeModel({
        field: item.id,
        value: item.model,
      });
    },

    onChangeAufgaben() {
      this.changeModel({
        field: "au_ids",
        value: this.model.au_ids,
      });
    },

    changeModel({ field, value }) {
      const ITEM = {
        workflowstatusgruppentyp: this.workflowgruppe.workflowstatusgruppentyp,
        index: this.index,
        field: field,
        value: value,
      };
      this.$emit("updateModel", { item: ITEM });
    },
  },
};
