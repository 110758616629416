import Teleport from "../../../../directives/Teleport";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import {
  filter,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDocumentProgress",
  components: {
    PuxIcon,
  },
  directives: {
    Teleport,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    progressList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    progressListShowFiltered() {
      return filter(this.progressList, ["show", true]);
    },
  },
  methods: {
    isSuccess(item) {
      return item.progress == 100 && isNil(item.errors);
    },

    isError(item) {
      return !isNil(item.errors);
    }
  },
};
