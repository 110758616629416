import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotItem from "../../../Snapshot/SnapshotItem/SnapshotItem.vue";

import KFBaseMixin from "./KFBaseMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import InfoproviderStatuses from "../../../../const/InfoproviderStatuses";
import {
  gettext
} from "../../../../global/functions/utils";
import {
  createNamespacedHelpers,
} from "vuex";
import {
  cloneDeep,
  find,
  get,
  keyBy,
} from "lodash-es";

const {
  mapGetters,
} = createNamespacedHelpers("snapshotKF");

export function getVertragBetragValue({ data, vertragFinanzierungsart = {}, regelPk }) {
  if (data && data.status === InfoproviderStatuses.PROCESSOR_STATUS_OK && data.details) {
    const DETAILS = data.details[regelPk];
    return get(DETAILS, "betrag");
  }
  return vertragFinanzierungsart.vf_betrag_aktualisiert;
}

// @vue/component
export default {
  components: {
    SnapshotIcon,
    SnapshotItem,
  },
  mixins: [
    KFBaseMixin,
    PermissionMixin,
  ],
  data() {
    return {
      antragFinanzierungsart: {},
      positionenOrderingKey: "afp_pos",
      urlPositionen: "",
      typeKoFi: "finanzierung",
      positionBezKey: "afp_bez",
      positionWertKey: "afp_wert",
      summeKey: "afa_summe",
      allowsInput: false,
    };
  },
  computed: {
    urlArten() {
      if (this.kontext === "zuschuss_vertrag") {
        return `foerderantraege/${ this.extra.antrag.pk }/finanzierungsarten/`;
      }
      return `${ this.baseUrl }finanzierungsarten/`;
    },

    statusShowProzente() {
      if (!this.koFiConfig.finanzierung_prozente_anzeigen) {
        return false;
      }
      // Wenn eine Zuschussberechnung durchgeführt werden konnte:
      if (this.infoprovider && this.infoprovider.status === InfoproviderStatuses.PROCESSOR_STATUS_OK) {
        return this.infoproviderProzent !== null;
      }
      // Wenn keine Zuschussberechnung durchgeführt wurde:
      if (!this.infoprovider) {
        return this.antragFinanzierungsart.afa_prozent !== null;
      }
      return false;
    },

    prozent() {
      const PROZENT = this.infoproviderProzent || this.antragFinanzierungsart.afa_prozent;
      return this.filterCurrency(PROZENT, "%");
    },

    currentSnapshot() {
      if (this.antragKoFiArt.pk && this.SNAPSHOT_FINANZIERUNGSARTEN_BY_AFA_ID_GET) {
        const CURRENT_SNAPSHOT = cloneDeep(this.SNAPSHOT_FINANZIERUNGSARTEN_BY_AFA_ID_GET[this.antragKoFiArt.pk]) || {};
        if (CURRENT_SNAPSHOT.positionen) {
          CURRENT_SNAPSHOT.positionenObj = keyBy(CURRENT_SNAPSHOT.positionen, "pk");
        } else {
          CURRENT_SNAPSHOT.positionenObj = {};
        }
        return CURRENT_SNAPSHOT;
      }
      return undefined;
    },

    antragBetragValue() {
      // Gibt es Betrag zurück, der für die Finanzierungsart angzeigt werden
      // soll. Das ist normalerweise nur notwendig, wenn es sich bei der
      // Finanzierungsart um Zuschüsse oder Pauschalen handelt, da diese sich
      // im Rahmen einer Zuschussberechnung ändern können.
      if (!this.zuschussSaved) {
        // Wenn nicht gespeichert wurde, kann immer der ursprüngliche Wert
        // angzeigt werden, da sich seit dem daran nichts geändert haben kann:
        return this.antragKoFiArt.summe || 0;
      }
      // Falls gepspeichert wurde, hat sich der Wert eventuell geändert,
      // hier
      return get(this.infoprovider, `details[${ this.regel.afr_id }].betrag`, 0);
    },

    antragBetragCurrency() {
      return this.filterCurrency(this.antragBetragValue);
    },

    zuschussSaved() {
      // Gibt an ob die Zuschussinformationen gespeichert wurden oder nicht.
      return this.infoprovider && this.infoprovider.gespeichert === true;
    },

    koFiStatuses() {
      return {
        bewilligt: get(this.vertragFinanzierungsart, "vf_prozent_bewilligt"),
        anerkannt: this.vertragBetragValue,
      };
    },

    vertragBetragValue() {
      return getVertragBetragValue({
        data: this.infoprovider,
        vertragFinanzierungsart: this.vertragFinanzierungsart,
        regelPk: this.regel.pk,
      });
    },

    vertragBetragValueCurrency() {
      if (this.vertragBetragValue) {
        return this.filterCurrency(this.vertragBetragValue);
      }
      return "-";
    },

    vertragFinanzierungsartFinanzierungsartKbez() {
      return get(this.vertragFinanzierungsart, "finanzierungsart.fin_kbez", "");
    },

    vertragFinanzierungsartBetragBewilligtCurrency() {
      return this.filterCurrency(get(this.vertragFinanzierungsart, "vf_betrag_bewilligt") || 0);
    },

    vertragProzentBewilligt() {
      return this.get_vertrag_prozent("bewilligt");
    },

    vertragProzentAktualisiert() {
      return this.get_vertrag_prozent("aktualisiert");
    },

    vertragUrlFinanzierung() {
      return `vertraege/${ this.extra.vertrag.pk }/finanzierung/${ this.vertragFinanzierungsart.pk }/`;
    },

    vertragUrlPositionen() {
      return `${ this.vertragUrlFinanzierung }positionen/`;
    },

    snapshotFinanzierung() {
      if (this.kontext === "zuschuss_vertrag") {
        return get(this.snapshot, `osn_snapshot.kofi.finanzierung`) || [];
      }
      return [];
    },

    currentSnapshotFinanzierung() {
      return find(this.snapshotFinanzierung, ["regel_nummer", this.regelNummer]);
    },

    diffZuschuss() {
      return get(this.extra, "diffZuschuss");
    },

    infoproviderSnapshot() {
      return get(this.snapshot, "osn_snapshot.kofi.zuschuss");
    },

    infoproviderKennzahlenSummeKosten() {
      return get(this.infoprovider, "kennzahlen.summe_foerderfaehige_kosten");
    },

    infoproviderZuschuss() {
      return get(this.infoprovider, "zuschuss");
    },

    objUebersichtVerschlagteKosten() {
      return get(this.obj, "uebersicht.veranschlagte_kosten");
    },

    objZuschussBewilligtOhnePauschale() {
      return get(this.obj, "zuschuss_bewilligt_ohne_pauschale");
    },

    vertragFinanzierungsart() {
      return this.extra.kofiZuschussKeyByRegelNummer[this.regelNummer];
    },

    ...mapGetters([
      "SNAPSHOT_FINANZIERUNGSARTEN_BY_AFA_ID_GET",
    ]),
  },
  created() {
    this.loadFinanzierungsart_FinanzierugMixin();
  },
  methods: {
    loadFinanzierungsart_FinanzierugMixin() {
      this.getListHttp({
        url: "finanzierungsarten/",
        apiSaveId: "finanzierungsarten",
      }).then(
        response => {
          this.initKatalogDetails_FinanzierugMixin(response, this.modelParameter.finanzierungsart, this.modelParameter.finanzierungsdetails);
        }
      );
    },

    initKatalogDetails_FinanzierugMixin(katalogResults, regelKatalogId, regelKatalogDetails) {
      this.initKatalogDetails_KFBaseMixin(katalogResults, regelKatalogId, regelKatalogDetails);
      if (this.modelParameter.ohne_positionen && this.katalogDetails.length > 0) {
        this.editType = "no-position-details";
      //   for (let i = 0; i < this.katalogDetails.length; i++) {
      //     if (!this.finArtDetailPos[this.katalogDetails[i].pk]) {
      //       this.finArtDetailPos[this.katalogDetails[i].pk] = this.initNewPosition();
      //       this.finArtDetailPos[this.katalogDetails[i].pk].finanzierungsartdetail = this.katalogDetails[i].pk;
      //     }
      //   }
      } else if (this.modelParameter.ohne_positionen) {
        this.editType = "no-position";
      }
    },

    setAntragKoFiArt_KFBaseMixin({ obj }) {
      this.antragFinanzierungsart = obj;
      this.setUrlPositionen();
      const ANTRAG_KO_FI_ART = cloneDeep(this.antragKoFiArt) || {};
      ANTRAG_KO_FI_ART.bez = get(this.antragFinanzierungsart, "finanzierungsart.fin_kbez");
      ANTRAG_KO_FI_ART.summe = this.antragFinanzierungsart.afa_summe;
      ANTRAG_KO_FI_ART.artPk = get(this.antragFinanzierungsart, "finanzierungsart.pk");
      ANTRAG_KO_FI_ART.diffKey = "finanzierungsarten";
      ANTRAG_KO_FI_ART.pk = this.antragFinanzierungsart.pk;
      this.antragKoFiArt = ANTRAG_KO_FI_ART;
    },

    setUrlPositionen() {
      this.urlPositionen = `${ this.urlArten }${ this.antragFinanzierungsart.pk }/positionen/`;
    },

    prepareModel_KFBaseMixin({ model }) {
      const MODEL = cloneDeep(model) || {};
      if (!MODEL.finanzierungsartdetail) {
        MODEL.finanzierungsartdetail = "";
      }
      return MODEL;
    },

    loadVertragFinanzierung_KFBaseMixin() {
      this.loadVertragFinanzierungRest_FinanzierugMixin();
    },

    loadVertragFinanzierungRest_FinanzierugMixin() {
      this.onArtLoaded_KFBaseMixin();
      if (this.allowsInput) {
        this.loadVertragPositionen_FinanzierugMixin();
      } else {
        this.loading = false;
        this.reloading = false;
      }
    },

    loadVertragPositionen_FinanzierugMixin() {
      this.getListHttp({
        url: this.vertragUrlPositionen,
        urlParams: {
          ordering: "vfp_pos",
        },
      }).then(
        response => {
          this.setVertragPosition(response);
          this.loading = false;
          this.reloading = false;
        }
      );
    },

    setVertragPosition(response) {
      const RESPONSE_FIRST = response[0];
      if (RESPONSE_FIRST) {
        const POSITION = {
          pk: RESPONSE_FIRST.pk,
          betrag_bewilligt: RESPONSE_FIRST.vfp_betrag_bewilligt,
          afp_bez: RESPONSE_FIRST.vfp_bez,
          afp_argument: cloneDeep(this.vertragFinanzierungsart.vf_argument) || {},
          afp_wert: this.vertragFinanzierungsart.vf_betrag_aktualisiert,
          argument: cloneDeep(this.vertragFinanzierungsart.vf_argument) || {},
        };
        POSITION.argument.wert = this.vertragFinanzierungsart.vf_betrag_aktualisiert;
        POSITION.argument.bezeichnung = RESPONSE_FIRST.vfp_bez;
        this.positionen = [POSITION];
      } else {
        this.positionen = [];
      }
    },

    get_vertrag_prozent_value(base, typ) {
      let betrag;
      if (base === 0) {
        return 0;
      }
      if (typ === "bewilligt") {
        betrag = Number(get(this.vertragFinanzierungsart, "vf_betrag_bewilligt"));
      } else {
        if (this.vertragBetragValue) {
          betrag = Number(this.vertragBetragValue);
        } else {
          return undefined;
        }
      }
      return 100 * betrag / base;
    },

    get_vertrag_prozent(typ) {
      let base;
      let title;
      let prozent;

      if (this.regel &&
        this.regel.argument &&
        this.regel.argument.max_prozent_zuschuss) {
        if (typ === "bewilligt") {
          base = Number(this.objZuschussBewilligtOhnePauschale);
        } else {
          base = Number(this.infoproviderZuschuss);
        }
        title = gettext("vom Zuschuss");
      } else {
        if (typ === "bewilligt") {
          base = Number(this.objUebersichtVerschlagteKosten);
        } else {
          base = Number(this.infoproviderKennzahlenSummeKosten);
        }
        title = gettext("von den förderfähigen Kosten");
      }
      if (typ === "bewilligt" &&
        get(this.vertragFinanzierungsart, "vf_prozent_bewilligt")) {
        prozent = this.vertragFinanzierungsart.vf_prozent_bewilligt;
      } else if (typ === "anerkannt" &&
        get(this.vertragFinanzierungsart, "vf_prozent_aktualisiert")) {
        prozent = this.vertragFinanzierungsart.vf_prozent_aktualisiert;
      } else {
        prozent = this.get_vertrag_prozent_value(base, typ);
      }
      if (prozent !== undefined) {
        const pStr = this.filterCurrency(prozent, "%");
        const baseStr = this.filterCurrency(base);
        return `<br><small class="procent" title="${ pStr }% ${ title } (${ baseStr })">${ pStr }</small>`;
      }
      return "";
    },

    getVertragBetragValueLocal({ data, isSnapshot }) {
      return getVertragBetragValue({
        data,
        vertragFinanzierungsart: isSnapshot ? this.currentSnapshotFinanzierung : this.vertragFinanzierungsart,
        regelPk: this.regel.pk,
      });
    },
  },
};
