"use strict";

export default /*@ngInject*/ () => {
  return {
    restrict: "A",
    scope: {
      ngModel: "=",
    },
    link: function(scope, element, attrs) {
      attrs.$set("ngTrim", "false");
      scope.$watch("ngModel", function(input) {
        if (_.isNil(input)) {
          return;
        }
        let output = "";
        _.forEach(input, i => {
          if (i !== " " && !_.isNaN(+i)) {
            output += i;
          }
        });
        scope.ngModel = output;
      });
    }
  };
};
