"use strict";

import template from "./config.jade";
import BaseController from "./base.controller";

class BewertungConfigController extends BaseController {
  /*@ngInject*/
  constructor(Katalog, ParameterUtils) {
    super(Katalog, ParameterUtils);
  }
}

export default {
  bindings: {
    parameter: "=",
    key: "@",
  },
  template: template(),
  controller: BewertungConfigController,
  controllerAs: "vm",
};
