import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "CodeModalReaktivieren",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    obj: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      status: {
        loading: undefined,
      },
    };
  },
  computed: {
    translationTxtHeader() {
      return {
        text: "_TXT_CODE_TABLE_MODAL_REACTIVATE_HEADER_{{code}}_",
        extra: { code: this.obj.co_code }
      };
    },

    translationHtmlBody() {
      return {
        html: "_HTML_CODE_TABLE_MODAL_REACTIVATE_BODY_{{code}}_",
        extra: { code: this.obj.co_code }
      };
    },

    translationMsgSuccess() {
      return {
        text: "_MSG_CODE_TABLE_MODAL_REACTIVATE_SUCCESS_{{code}}_",
        extra: { code: this.obj.co_code }
      };
    },

    translationMsgError() {
      return {
        text: "_MSG_CODE_TABLE_MODAL_REACTIVATE_ERROR_{{code}}_",
        extra: { code: this.obj.co_code }
      };
    },
  },
  methods: {
    deactivateCode() {
      this.status.loading = true;
      const data = cloneDeep(this.obj);
      data.co_deaktiviert = false;
      this.putHttp({ url: `codes/${ this.obj.pk }/`, data }).then(
        response => {
          this.addNotification(this.translationMsgSuccess);
          this.close({ response });
        },
        () => this.addNotification(this.translationMsgError)
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
