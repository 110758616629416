import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "Kontakt",
  components: {
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        label: "_LBL_KONTAKT_",
      },
    };
  },
};
