"use strict";

import "restangular";
import angular from "angular";

import Budget from "./budget.service";

const modelsModule = angular.module("budget.models", [
  "restangular",
])

  .provider("Budget", Budget)
;

export default modelsModule;
