import HttpMixin from "../../../../../global/mixins/HttpMixin";
import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxCloak from "../../../../../global/components/PuxCloak/PuxCloak.vue";
import SnapshotModule from "../../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import Permission from "../../../../../global/components/Permission/Permission.vue";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import translate from "../../../../../global/directives/translate";
import PermissionMixin from "../../../../../global/mixins/PermissionMixin";
import OneDocumentSnapshotMixin from "../OneDocumentSnapshotMixin";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

import {
  cloneDeep,
  get,
  has,
} from "lodash-es";

const DOKUMENT_PRUEFSTATUS_NEU = "30d847af-74b6-1dcc-e050-007f01012f99";

// @vue/component
export default {
  name: "AnlagenAllgemeinOneDocumentDetails",
  components: {
    PuxCloak,
    FormstepDetail,
    SnapshotModule,
    SnapshotIcon,
    Permission,
    FormElement,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
    OneDocumentSnapshotMixin,
  ],
  props: {
    document: {
      type: Object,
      required: true,
    },
    urlDocuments: {
      type: String,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    updateDocument: {
      type: Function,
      required: true,
    },
    snapshotDate: {
      type: String,
      default: undefined,
    },
    dokumentartenAll: {
      type: Array,
      required: true,
    },
    documentPruefStatuses: {
      type: Array,
      default: undefined,
    },
    dokumentePermissionsLocal: {
      type: Object,
      required: true,
    },
    userPermissions: {
      type: Array,
      required: true,
    },
    htmlId: {
      type: String,
      required: true,
    },
    notizenOptions: {
      type: Object,
      required: false,
      default: undefined,
    },
    obj: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    anzeigeDetailsObject: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    anzeigeDetails: {
      type: Array,
      required: false,
      default: () => [],
    },
    dokumentartenAllByPk: {
      type: Object,
      required: true,
    },
    statusRequiredGueltigVon: {
      type: Boolean,
      required: false,
      default: false,
    },
    statusRequiredGueltigBis: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {
      filterDate,
      filterDefaultForEmpty,
      filterFileSize,
    } = FiltersAPI();

    return {
      filterDate,
      filterDefaultForEmpty,
      filterFileSize,
    };
  },
  data() {
    return {
      loading: true,
      loadingPreview: false,
      modelEdit: {},
    };
  },
  computed: {
    htmlIdLocal() {
      return `${ this.htmlId }_${ this.document.pk }`;
    },

    previewUrl() {
      return `/api/${ this.baseUrl }preview/${ this.document.pk }/`;
    },

    optionsFormstepDetail() {
      return {
        editablePermission: this.dokumentePermissionsLocal.update,
        permissionList: this.userPermissions,
        openCallback: this.openEditMode,
        saveCallback: this.save,
        classBodyChildReadonly: "p-0",
      };
    },

    htmlIdFormstepDetail() {
      return `${ this.htmlId }_${ this.document.pk }_details`;
    },

    notizenOptionsLocal() {
      if (!this.checkPermissionsSync({ perm: "notizen.doc.create" })) {
        return;
      }
      const NOTIZEN_OPTIONS = cloneDeep(this.notizenOptions) || {
        label: null,
        key: null,
        objektid: null,
        content_type: null,
        user_permissions: this.obj.user_permissions,
      };
      NOTIZEN_OPTIONS.label = this.document.dokument.dok_titel;
      NOTIZEN_OPTIONS.objektid = this.obj.pk;
      NOTIZEN_OPTIONS.content_type = this.obj.content_type;
      NOTIZEN_OPTIONS.mainObjektid = this.document.dokument.pk;
      NOTIZEN_OPTIONS.mainContentType = this.document.dokument.content_type;

      return NOTIZEN_OPTIONS;
    },

    optionsGueltigAb() {
      if (has(this.modelEdit, "dokart")) {
        const LABEL_GUELTIG_AB = this.dokumentartenAllByPk[this.modelEdit.dokart].label_gueltig_ab;
        return {
          type: "date",
          label: LABEL_GUELTIG_AB,
          htmlId: `${ this.htmlId }_${ this.document.pk }_gueltig_ab`,
          translate: true,
          required: this.statusRequiredGueltigVon,
          view: "v",
          id: "gueltig_ab",
        };
      }
      return undefined;
    },

    optionsGueltigBis() {
      if (has(this.modelEdit, "dokart")) {
        const LABEL_GUELTIG_BIS = this.dokumentartenAllByPk[this.modelEdit.dokart].label_gueltig_bis;
        return {
          type: "date",
          label: LABEL_GUELTIG_BIS,
          htmlId: `${ this.htmlId }_${ this.document.pk }_gueltig_bis`,
          translate: true,
          required: this.statusRequiredGueltigBis,
          view: "v",
          id: "gueltig_bis",
        };
      }
      return undefined;
    },

    optionsTitel() {
      return {
        type: "text",
        label: "_LBL_GR_DOCUMENTS_TITEL_",
        htmlId: `${ this.htmlId }_${ this.document.pk }_dok_titel`,
        translate: true,
        required: true,
        view: "v",
        id: "dok_titel",
        maxlength: 60,
      };
    },

    optionsDokart() {
      return {
        type: "select",
        label: "_LBL_GR_DOCUMENTS_DOKUMENTTYP_",
        htmlId: `${ this.htmlId }_${ this.document.pk }_dokart`,
        translate: true,
        required: true,
        view: "v",
        id: "dokart",
        keyLabel: "name",
        keyId: "pk",
      };
    },

    optionsPruefstatus() {
      return {
        type: "select",
        label: "_LBL_GR_DOCUMENTS_STATUS_",
        htmlId: `${ this.htmlId }_${ this.document.pk }_pruefstatus`,
        translate: true,
        required: true,
        view: "v",
        id: "pruefstatus",
        keyLabel: "status",
        keyId: "pk",
      };
    },

    optionsDokPosteingang() {
      return {
        type: "date",
        label: "_LBL_GR_DOCUMENTS_POSTEINGANG_",
        htmlId: `${ this.htmlId }_${ this.document.pk }_dok_posteingang`,
        translate: true,
        required: false,
        view: "v",
        id: "dok_posteingang",
      };
    },

    optionsDokUnterschriftsdatum() {
      return {
        type: "date",
        label: "_LBL_GR_DOCUMENTS_UNTERSCHRIFTSDATUM_",
        htmlId: `${ this.htmlId }_${ this.document.pk }_dok_unterschriftsdatum`,
        translate: true,
        required: false,
        view: "v",
        id: "dok_unterschriftsdatum",
      };
    },

    labels() {
      if (has(this.modelEdit, "dokart")) {
        const LABEL_GUELTIG_AB = this.dokumentartenAllByPk[this.modelEdit.dokart].label_gueltig_ab;
        const LABEL_GUELTIG_BIS = this.dokumentartenAllByPk[this.modelEdit.dokart].label_gueltig_bis;
        return {
          dok_titel: "_LBL_GR_DOCUMENTS_TITEL_",
          dokart: "_LBL_GR_DOCUMENTS_DOKUMENTTYP_",
          gueltig_ab: LABEL_GUELTIG_AB,
          gueltig_bis: LABEL_GUELTIG_BIS,
          pruefstatus: "_LBL_GR_DOCUMENTS_STATUS_",
          dok_posteingang: "_LBL_GR_DOCUMENTS_POSTEINGANG_",
        };
      }
      return undefined;
    },

    statusShowBlock1() {
      return this.anzeigeDetailsObject["pruefstatus.status"] ||
        this.anzeigeDetailsObject.pruefung_gueltig_bis ||
        this.anzeigeDetailsObject.pruefstatus_gesetzt_am ||
        this.anzeigeDetailsObject.geprueft_von;
    },

    statusShowBlock2() {
      return this.anzeigeDetailsObject["dokument.dok_posteingang"] ||
        this.anzeigeDetailsObject["dokument.crdate"] ||
        this.anzeigeDetailsObject["dokument.user"] ||
        this.anzeigeDetailsObject["dokument.dok_loeschdatum"];
    },

    statusShowBlock3() {
      return this.anzeigeDetailsObject["dokument.dok_dokumentname"] ||
        this.anzeigeDetailsObject["dokument.dok_typ"] ||
        this.anzeigeDetailsObject["dokument.dok_groesse"];
    },

    statusEditable() {
      return !!(this.statusEditDokTitel ||
        this.statusEditDokart ||
        this.statusEditGueltigAb ||
        this.statusEditGueltigBis ||
        this.statusEditPruefStatus ||
        this.statusEditDokPosteingang ||
        this.statusEditDokUnterschriftsdatum);
    },

    statusEditDokTitel() {
      return this.anzeigeDetailsObject["dokument.dok_titel"] &&
        this.document.dokument &&
        this.document.dokument.dok_titel !== undefined;
    },

    statusEditDokart() {
      return this.anzeigeDetailsObject["dokument.dokumentart.name"] &&
        this.document.dokument &&
        this.document.dokument.dokumentart &&
        this.document.dokument.dokumentart.name !== undefined;
    },

    statusEditGueltigAb() {
      return this.anzeigeDetailsObject.gueltig_ab &&
        this.modelEdit.dokart &&
        this.document.gueltig_ab !== undefined;
    },

    statusEditGueltigBis() {
      return this.anzeigeDetailsObject.gueltig_bis &&
        this.modelEdit.dokart &&
        this.document.gueltig_bis !== undefined;
    },

    statusEditPruefStatus() {
      return this.anzeigeDetailsObject["pruefstatus.status"] &&
        this.document.pruefstatus !== undefined &&
        this.checkPermissionsSync({
          perm: ["dokument.view_state", "dokument.update_state"],
          permArray: this.userPermissions,
        });
    },

    statusEditDokPosteingang() {
      return this.anzeigeDetailsObject["dokument.dok_posteingang"] &&
        this.checkPermissionsSync({
          perm: ["dokument.update_posteingang"],
          permArray: this.userPermissions,
        });
    },
    statusEditDokUnterschriftsdatum() {
      return this.anzeigeDetailsObject["dokument.dok_unterschriftsdatum"] &&
      this.checkPermissionsSync({
        perm: ["dokument.update_unterschriftsdatum"],
        permArray: this.userPermissions,
      });
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.loadDetails().then(
        response => {
          const DATA = cloneDeep(this.document);
          DATA.dokument = response;
          this.updateDocument({ document: DATA });
        }
      ).finally(() => this.loading = false);
    },

    loadDetails() {
      return this.getHttp(
        { url: `${ this.urlDocuments }${ this.document.pk }/details/` }
      );
    },

    createPreview() {
      this.loadingPreview = true;
      this.postHttp({
        url: `${ this.urlDocuments }${ this.document.pk }/create_preview/`
      }).then(
        response => {
          if (response && response.dokument && response.dokument.dok_preview) {
            const DATA = cloneDeep(this.document);
            DATA.dokument = response.dokument;
            this.updateDocument({ document: DATA });
          }
          this.loadingPreview = false;
        }
      );
    },

    openEditMode() {
      this.modelEdit = {
        dok_titel: this.document.dokument && this.document.dokument.dok_titel ? this.document.dokument.dok_titel : null,
        dokart: this.document.dokument && this.document.dokument.dokart_id ? this.document.dokument.dokart_id : null,
        gueltig_ab: this.document.gueltig_ab || null,
        gueltig_bis: this.document.gueltig_bis || null,
        pruefstatus: this.document.pruefstatus ? this.document.pruefstatus.pk : DOKUMENT_PRUEFSTATUS_NEU,
        dok_posteingang: get(this.document, "dokument.dok_posteingang", null),
        dok_unterschriftsdatum: get(this.document, "dokument.dok_unterschriftsdatum", null),
      };
    },

    save() {
      return new Promise((resolve, reject) => {
        const MODEL = cloneDeep(this.modelEdit);
        this.putHttp({
          url: `${ this.urlDocuments }${ this.document.pk }/`,
          data: MODEL,
        }).then(
          response => {
            this.loadDetails().then(
              dok_details => {
                const DATA = cloneDeep(response);
                DATA.dokument = dok_details;
                this.updateDocument({ document: DATA, statusUpdate: true });
              },
              error => {
                reject(error);
              }
            ).finally(() => resolve());
          },
          error => {
            reject(error);
          }
        );
      });
    },
  },
};
