import Modal from "../../../../../../../libs/vue/client/vue/global/components/Modal/Modal.vue";
import PuxButton from "../../../../../../../libs/vue/client/vue/global/components/PuxButton/PuxButton.vue";

import HttpMixin from "../../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import NotificationMixin from "../../../../../../../libs/vue/client/vue/global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "DeleteStudiengangModal",
  components: {
    Modal,
    PuxButton,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    studiengang: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      status: {
        loading: undefined,
      },
    };
  },
  methods: {
    remove() {
      const DATA = cloneDeep(this.studiengang);
      this.postHttp({
        url: `studiengaenge/${ this.studiengang.pk }/studiengang_loeschen/`,
        data: DATA,
      }).then(
        () => {
          this.close();
          this.addNotification({ text: "Der Studiengang wurde erfolgreich gelöscht." });
          this.$goTo("root.studiengang");
        },
        error => {
          if (error.status === 400) {
            if (error.data) {
              this.addNotification({ text: error.data, type: "error" });
            } else {
              this.addNotification({ text: "Der Studiengang konnte nicht gelöscht werden, da dieser referenziert wird." });
            }
          }
        }
      ).finally(
        () => {
          this.status.loading = false;
        }
      );
    },
  },
};
