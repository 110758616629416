"use strict";

import template from "./email-change.jade";

class ResetPasswordAbschliessenController {
  /*@ngInject*/
  constructor($state, $stateParams, AuthService, DiasNotification, EmailAendern, Logout) {
    this.loading = true;

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.AuthService = AuthService;
    this.DiasNotification = DiasNotification;
    this.EmailAendern = EmailAendern;
    this.Logout = Logout;

    this.initDefaultValues();
    this.checkUrl();
  }

  initDefaultValues() {
    this.regBody = this.regBody || {};
    this.regBody.class = this.regBody.class || "col-12";
    this.regBody.success = this.regBody.success || `<p>
                                                      Bitte bestätigen Sie die Änderung auf die neue E-Mail-Adresse. Anschließend können Sie sich mit dieser E-Mail-Adresse anmelden.
                                                    </p>`;
    this.regBody.unsuccess = this.regBody.unsuccess || `
<p>
 Der eingegebene Aktivierungslink ist falsch oder abgelaufen. Stellen Sie sicher, dass Sie den Link vollständig aus der E-Mail kopieren.
</p>
<p>
 Wann wird der Aktivierungslink ungültig?
 <ul>
  <li>Wenn eine Abmeldung vom System nach dem Speichern der neuen E-Mail-Adresse erfolgte.</li>
  <li>Wenn das Passwort vor der Aktivierung der neuen E-Mail-Adresse geändert wurde.</li>
  <li>Wenn der Aktivierungslink bereits angeklickt wurde.</li>
 </ul>
</p>
<p>
  Sie können jederzeit unter &quot;Profil &gt; Anmeldedaten &gt; E-Mail-Adresse&quot; einen neuen Aktivierungslink anfordern.
</p>
`;
    if (!this.regHeader) {
      this.regHeader = {
        class: "card-header",
        unsuccessLabel: `<h2 class="card-title">Ungültiger Aktivierungslink</h2>`,
        successLabel: `<h2 class="card-title">Neue E-Mail-Adresse aktivieren</h2>`
      };
    }

    if (!this.regClass) {
      this.regClass = "col-12 col-sm-10 col-lg-7 no-padding-right";
    }
  }

  checkUrl() {
    if (!this.$stateParams.uuid || !this.$stateParams.tok) {
      this.successStatus = false;
      this.loading = false;
      return;
    }
    this.EmailAendern.one(this.$stateParams.uuid).one(this.$stateParams.tok).get().then(response => {
      this.successStatus = response.validlink;
      this.email = response.n_email_aenderung;
      this.loading = false;
    }, error => {
      this.successStatus = error.validlink;
      this.loading = false;
    });
  }

  aktivierenEmail() {
    this.EmailAendern.one(this.$stateParams.uuid).one(this.$stateParams.tok).customPOST().then(
      () => {
        this.DiasNotification.page.success("Ihre Email-Adresse wurde erfolgreich geändert.");
        this.Logout.post().then(
          () => {
            this.AuthService.refreshUser().then(
              () => this.$state.go("root.home", {}, { reload: "root.home" })
            );
          }
        );
      }
    );
  }
}

export default {
  template: template(),
  controller: ResetPasswordAbschliessenController,
  controllerAs: "vm",
  bindings: {
    regClass: "<?",
    regHeader: "<?",
    regBody: "<?"
  }
};
