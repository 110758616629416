"use strict";

import "restangular";
import angular from "angular";

import Benachrichtigungen from "./benachrichtigungen.service";
import Empfaenger from "./empfaenger.service";
import BackgroundBenachrichtigungen from "./benachrichtigungen.background.service";

const modelsModule = angular.module("benachrichtigungen.models", [
  "restangular",
])

  .provider("Benachrichtigungen", Benachrichtigungen)
  .provider("BackgroundBenachrichtigungen", BackgroundBenachrichtigungen)
  .provider("Empfaenger", Empfaenger)
;

export default modelsModule;
