import Spinner from "../../../../../global/components/Spinner/Spinner.vue";
import translate from "../../../../../global/directives/translate";
import {
  FilterCurrencyMixin,
} from "../../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "ModalBelegdokumentDeleteBeleg",
  components: {
    Spinner,
  },
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
  ],
  props: {
    beleg: {
      type: Object,
      required: true,
      info: "Beleg",
    },
    statusLoadingPermission: {
      type: Boolean,
      required: true,
      info: "Status loading permission",
    },
    statusBelegPermissions: {
      type: Boolean,
      required: true,
      info: "Hat Beleg Berechtigungen?",
    },
  },
  computed: {
    herkunftText() {
      return this.beleg.herkunft === "vertragsfinanzierung_positionsbeleg" ?
        "_TXT_FINANZIERUNGSPOSITION_" :
        "_TXT_KOSTENPOSITION_";
    },

    betragErfasstCurrency() {
      return this.filterCurrency(this.beleg.betrag_erfasst);
    },

    betragAnerkanntCurrency() {
      return this.filterCurrency(this.beleg.betrag_anerkannt);
    },
  },
};
