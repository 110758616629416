import KofiErfassungBelegFormModalWizard from "../KofiErfassungBelegFormModalWizard/KofiErfassungBelegFormModalWizard.vue";

import KofiErfassungBelegMixin from "../Mixins/KofiErfassungBelegMixin";

import KVertragskostenbelegStatusUUIDs from "../../../../const/KVertragskostenbelegStatusUUIDs";
import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "KofiErfassungBelegWizard",
  components: {
    KofiErfassungBelegFormModalWizard,
  },
  mixins: [
    KofiErfassungBelegMixin,
  ],
  data() {
    return {
      isKofiShouldReloaded: false,
    };
  },
  computed: {
    belegNameText() {
      let BELEG_NAME_TEXT = "_TXT_AUSZAHLUNG_WIZARD_BELEG_NAME_{{bez}}_";
      if (this.belegnummerAnzeigen) {
        BELEG_NAME_TEXT += "{{number}}_";
      }
      if (this.messageStatusUnvollstaendig) {
        BELEG_NAME_TEXT += "{{unvollstaendig}}_";
      }
      return BELEG_NAME_TEXT;
    },

    extraForBelegNameTranslate() {
      return {
        bez: this.belegBez,
        number: this.belegnummer,
        unvollstaendig: this.messageStatusUnvollstaendig,
      };
    },

    messageStatusUnvollstaendig() {
      const STATUS = get(this.beleg, "status.pk");
      if (STATUS === KVertragskostenbelegStatusUUIDs.UNVOLLSTAENDIG) {
        return get(this.beleg, "status.vbs_kbez");
      }
      return "";
    },
  },
  methods: {
    setKofiShouldReloaded() {
      this.isKofiShouldReloaded = true;
    },

    closeEditBeleg() {
      this.statusEdit = false;
      this.selectorClose = undefined;
      if (this.isKofiShouldReloaded) {
        this.$attrs.onReloadKofi({ statusCallback: true });
        this.isKofiShouldReloaded = false;
      }
    },

    toggleEditBeleg() {
      this.selectorClose = [
        `#${ this.buttonBelegEditId }`,
        `#${ this.htmlId }`,
      ];
      this.statusEdit = !this.statusEdit;
      this.statusBelegExtra = false;
      if (this.statusEdit) {
        this.onOpenModalLocal();
      }
    },

    reloadKofiLocal(arg) {
      this.$attrs.onReloadKofi(arg);
      this.isKofiShouldReloaded = false;
    },
  },
};
