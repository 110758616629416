import ModuleMixin from "../ModuleMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";

import QuartierskartePolylineMixin from "./QuartierskartePolylineMixin";
import QuartierskarteBoundsMixin from "./QuartierskarteBoundsMixin";
import QuartierskarteMarkersMixin from "./QuartierskarteMarkersMixin";
import QuartierskartePanelMixin from "./QuartierskartePanelMixin";
import QuartierskarteMarkerTooltip from "./QuartierskarteMarkerTooltip/QuartierskarteMarkerTooltip.vue";
import QuartierskarteLegende from "./QuartierskarteLegende/QuartierskarteLegende.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";

import {
  LMap,
  LTileLayer,
  LTooltip,
} from "../../../../global/components/PuxLeaflet/index";
import {
  EventBus,
} from "../../../../global/functions/event-bus";

import L from "leaflet";
import "leaflet/dist/leaflet.css";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

import {
  forEach,
  cloneDeep,
  isUndefined,
  isNil,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "StandardFoerdermoduleQuartierskarte",
  components: {
    LMap,
    LTileLayer,
    LTooltip,
    QuartierskarteMarkerTooltip,
    QuartierskarteLegende,
    PuxButton,
    PuxIcon,
  },
  mixins: [
    ModuleMixin,
    NotificationMixin,
    QuartierskartePolylineMixin,
    QuartierskarteBoundsMixin,
    QuartierskarteMarkersMixin,
    QuartierskartePanelMixin,
  ],
  data() {
    return {
      videoSrc_mixin: "/api/assets/quartierskarte-info.mp4",
      videoText_mixin: "Muster Text",
      editable_mixin: true,
      statusInfo: undefined,
      statusReady: undefined,
      adresstypObj: undefined,
      adresstypenEditierbar: [],
      statusAdresstypenEditierbarActive: undefined,
      adresse: [],
      // Karte Einstellungen
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution: `&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`,
      currentZoom: 12,
      mapOptions: {
        minZoom: 6,
        maxZoom: 18,
        scrollWheelZoom: false,
      },
      centerAddress: undefined,
      statusEditMode_mixin: undefined,
      statusLoading_mixin: undefined,
      statusReinitialized: false,
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.openCallback;
      OPTIONS.closeCallback = this.closeCallback;
      return OPTIONS;
    },
  },
  created() {
    this.initMarkersEditable();
    this.initPolyline();
    this.loadData();
  },
  mounted() {
    setTimeout(() => {
      this.statusReady = true;
    });
    EventBus.$on("togglePuxRubric", this.togglePuxRubric);
  },
  beforeUnmount() {
    EventBus.$off("togglePuxRubric", this.togglePuxRubric); 
  },
  methods: {
    loadData() {
      const URL_PARAMS_ADDRESS = this.getUrlParams({ type: "address" });
      Promise.all([
        this.getListHttp({
          url: "katalog/?key=adresstyp",
          apiSaveId: "adresstyp",
          keyId: "pk",
        }),
        this.getListHttp({
          url: "adressen/",
          urlParams: URL_PARAMS_ADDRESS,
        }),
      ]).then(
        responses => {
          this.setAdresstyps({ response: responses[0] });
          this.setAdresse({ response: responses[1] });
          this.initBounds();
          this.checkPermissionAreale();
        }
      );
    },

    togglePuxRubric({ statusOpen, rubricKey }) {
      if (!this.statusReinitialized && statusOpen && rubricKey === this.notizenKey) {
        this.statusReinitialized = true;
        this.$refs.map.updateMapSize();
      }
    },

    onReceiveSignal() {
      this.reloadAdresse();
    },

    setAdresstyps({ response }) {
      this.adresstypObj = response;
      this.setAdresstypenEditierbar();
    },

    setAdresstypenEditierbar() {
      forEach(this.modelParameter.adresstypen_editierbar, uuid => {
        if (isUndefined(this.adresstypObj[uuid])) {
          console.error(`Diese UUID: ${ uuid } muss auch in der Katalogtabelle: "k_adresstyp" vorhanden sein`);
        } else {
          this.adresstypenEditierbar.push(this.adresstypObj[uuid]);
        }
      });
      this.statusAdresstypenEditierbarActive = true;
    },

    getUrlParams({ type }) {
      let typ;
      if (type === "areale") {
        typ = this.modelParameter.arealtyp;
      } else {
        typ = cloneDeep(this.modelParameter.adresstypen_anzeige);
        typ.push(this.modelParameter.adresstyp_mittelpunkt);
      }
      const URL_PARAMS = {
        objekt_id: this.$stateParams.id,
        typ,
        // umkreis: "50.732056,7.096770,7",
      };
      return URL_PARAMS;
    },

    reloadAdresse() {
      const URL_PARAMS_ADDRESS = this.getUrlParams({ type: "address" });
      this.getListHttp({
        url: "adressen/",
        urlParams: URL_PARAMS_ADDRESS,
      }).then(
        response => {
          this.setAdresse({ response });
          this.initBounds();
        }
      );
    },

    setAdresse({ response }) {
      const RESPONSE = this.filterAdresseAndFindCenter({ response });
      this.adresse = RESPONSE;
    },

    filterAdresseAndFindCenter({ response }) {
      const RESPONSE = [];
      let centerAddress = undefined;
      const CENTER_TYPE = this.modelParameter.adresstyp_mittelpunkt;
      forEach(response, item => {
        if (item.adr_geokoord) {
          RESPONSE.push(item);
          if (item.typ === CENTER_TYPE) {
            centerAddress = cloneDeep(item);
          }
        }
      });

      this.centerAddress = centerAddress;
      if (this.centerAddress
        && !isNil(this.centerAddress.adr_geokoord[0])
        && !isNil(this.centerAddress.adr_geokoord[1])) {
        this.setMapCenterAndZoom({
          latitude: this.centerAddress.adr_geokoord[0],
          longitude: this.centerAddress.adr_geokoord[1],
          zoom: 12,
        });
      }

      if (!this.centerAddress && this.obj.antragstellerorg_obj
          && this.obj.antragstellerorg_obj.ast_plz) {
        this.getHttp({
          url: "katalog/?key=plz_bundesland",
          urlParams: {
            pbu_plz: this.obj.antragstellerorg_obj.ast_plz,
          }
        }).then(
          addr => {
            if (addr.length === 1) {
              addr = addr[0];
              if (isNil(addr.pbu_latitude) ||
                  isNil(addr.pbu_longitude)) {
                return;
              }
              this.setMapCenterAndZoom({
                latitude: addr.pbu_latitude,
                longitude: addr.pbu_longitude,
                zoom: 12,
              });
            }
          }
        );
      }
      return RESPONSE;
    },

    setMapCenterAndZoom({ latitude, longitude, zoom }) {
      setTimeout(() => {
        this.$refs.map.setZoom(zoom);
        this.$refs.map.setCenter([latitude, longitude]);
      });
    },

    TEMP_SET_BONN({ response }) {
      const RESPONSE = response;
      let bonnLat = 50.73585;
      let bonnLng = 7.10066;
      forEach(response, (item, index) => {
        if (!item.adr_geokoord) {
          response[index].adr_geokoord = [bonnLat, bonnLng];
          bonnLat += 0.1;
          bonnLng += 0.1;
        }
      });
      return RESPONSE;
    },

    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },

    toggleEditMode_mixin() {
      if (this.statusEditMode_mixin) {
        this.statusChangePolyline = undefined;
        this.togglePanel({ name: "legende", statusShow: true });
      } else {
        this.setStatusPolylineMarkersDraggable({ status: true });
      }
      this.statusEditMode_mixin = !this.statusEditMode_mixin;
    },

    save() {
      this.statusLoading_mixin = true;
      this.toggleActivatePolyline({ status: true });
      const DATA = {
        punkte: this.polylineLatlngs.length ? this.polylineLatlngs : undefined,
        adressen: this.markersEditable,
      };
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: `${ this.baseUrl }module/${ this.regel.pk }/`,
          data: DATA,
        }).then(
          response => {
            if (isFunction(this.savecallback)) {
              this.savecallback();
            }
            this.updateModule({
              response,
              extra: this.extra,
              group: this.group,
            });
            this.updateSnapshotDiffLocal();
            this.sendSignal();
            setTimeout(() => {
              this.initData();
              this.initMarkersEditable();
              this.initPolyline();
            });
            this.errors = undefined;
            resolve();
          },
          error => {
            this.onError({ error: error.data });
            reject();
          }
        ).then(
          () => this.statusLoading_mixin = false
        );
      });
    },

    onError({ error = {} }) {
      if (error.punkte) {
        this.addNotification({ text: error.punkte, type: "error" });
      }
    },

    openCallback() {
      this.statusEditMode_mixin = true;
    },

    closeCallback() {
      this.statusEditMode_mixin = false;
      this.statusChangePolyline = false;
      this.setActiveMarkerEditableIndexUndefined();
      this.initMarkersEditable();
      this.initPolyline();
      this.deselectPolyline();
      this.togglePanel({ name: "legende", statusShow: true });
    },
  },
};
