import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import {
  FilterSpacedMixin,
} from "../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "AntragDeleteButtonConfirm",
  components: {
    Modal,
    PuxButton,
  },
  mixins: [
    FilterSpacedMixin,
    HttpMixin,
  ],
  props: {
    antrag: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModalOpen: false,
      confirmOptions: undefined
    };
  },
  computed: {
    antragNummerFiltered() {
      return this.filterSpaced(this.antrag.a_nr, true);
    },

    antragUrl() {
      return `foerderantraege/${ this.antrag.pk }/`;
    },
  },
  methods: {
    openModal() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_ANTRAG_DELETE_CONFIRM_{{a_nr}}_",
        msg: "_HTML_ANTRAG_DELETE_CONFIRM_BODY_{{a_nr}}_",
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: this.deleteAntrag,
        cancelCallback: this.closeModal,
        extra: {
          a_nr: this.antragNummerFiltered,
        },
        loading: false,
      };
      this.isModalOpen = true;
    },

    closeModal() {
      this.isModalOpen = false;
    },

    deleteAntrag() {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: this.antragUrl,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_ANTRAG_DELETE_CONFIRM_SUCCESS_{{a_nr}}_",
            extra: {
              a_nr: this.antragNummerFiltered,
            },
          });
          this.closeModal();
          this.$goTo("root.foerderantrag");
        },
        () => {
          this.addNotification({
            text: "_MSG_ANTRAG_DELETE_CONFIRM_ERROR_",
            type: "error",
          });
        }
      ).finally(
        () => {
          this.confirmOptions.loading = false;
        }
      );
    },
  },
};
