"use strict";

import template from "./terminAendern.jade";

class TerminAendernController {
  /*@ngInject*/
  constructor() {
    this.reset();
  }

  save() {
    this.aufgabe.customPOST({ termin: this.termin }, "termin_aendern").then(
      result => {
        this.aufgabe.termin = result.termin;
        this.reset();
      },
      errors => this.errors = errors.data
    );
  }

  reset() {
    this.editMode = false;
    this.termin = this.aufgabe.termin;
    this.errors = false;
  }
}

export default {
  template: template(),
  controller: TerminAendernController,
  controllerAs: "vm",
  bindings: {
    aufgabe: "=",
    obj: "=",
  }
};
