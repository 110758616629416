import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

// @vue/component
export default {
  name: "vue-datenschutz",
  components: {
    PageTabTitle,
    PuxTranslate,
  },
  mixins: [
    SyConfigMixin,
  ],
  data() {
    return {
      inhalt: undefined,
    };
  },
  created() {
    this.initInhalt();
  },
  methods: {
    initInhalt() {
      this.inhalt = this.getSyConfigsValue("datenschutz");
    },
  },
};
