"use strict";

import angular from "angular";
import "restangular";
import diasLoading from "dias/core/loading";

import buchungenTableComponent from "./buchungen.table.componoent";

const buchungenTable = angular.module("dias.vertrag.buchungen", [
  diasLoading.name,
]);


buchungenTable.component("buchungenTable", buchungenTableComponent);


export default buchungenTable;
