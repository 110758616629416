"use strict";

import template from "./kofierfassung.details.jade";
import StandardBetragsrechner from "dias/kosten_finanzierung/betragsrechner/standard_betragsrechner/betragsrechner";
import deleteDokumentController from "./belegdokumente/modal.belegdokument.delete.component";
import deleteDokumentTemplate from "./belegdokumente/modal.belegdokument.delete.jade";
import {
  getClassBetragAnerkanntInPersonalbaum,
} from "../../../vue/client/vue/const/KVertragskostenbelegStatusUUIDs";

const VOLLSTAENDIG_ANERKANNT = 4;
const TEILWEISE_ANERKANNT = 3;
const ABGELEHENT = 2;
const EINGEREICHT = 1;
const NEU = 0;

class KoFiErfassungController {
  /*@ngInject*/
  constructor(
    $scope,
    AuthService,
    KKostenart,
    KFinanzierungsart,
    KVertragskostenbelegStatusUUIDs,
    KBelegePruefmethodeUUIDs,
    diasModalDialog,
    diasConfirmDialog,
    DiasNotification,
    BetragsrechnerRegistry,
    $filter,
    Upload,
    ListenConstantDokumente,
    SyConfigs,
    i18n,
    Katalog,
    $timeout
  ) {
    this.$timeout = $timeout;
    this.gettext = i18n.gettext;
    this.syConfigsAuszahlung = SyConfigs.wert("auszahlung");
    this.belegnummerAnzeigen = SyConfigs.wert("belege_nummer").anzeigen;
    angular.forEach(this.module, modul => {
      if (modul.modulname === "personalkosten_lohnjournal") {
        Katalog.getKatalog("personalfunktionen", { pk: modul.regel.afr_argument.personalfunktionen.wert }).then(
          result => this.personalfunktionen = result
        );
      }
    });


    this.prefix = {
      kosten: {
        beleg: "vpb",
        position: "vkp",
        vertragsPos: "vertragskostenposition",
        art: "kostenart",
        bezeichnung: "kos",
        vkofi: "vk",
        titel: this.gettext("Kosten"),
        artdetail: "kod",
        dokart: "2f413911-a2dc-c179-e050-007f0101705e",
      },
      finanzierung: {
        beleg: "vfb",
        position: "vfp",
        vertragsPos: "vertragsfinanzierungposition",
        art: "finanzierungsart",
        bezeichnung: "fin",
        vkofi: "vf",
        titel: "Finanzierung",
        artdetail: "fid",
        dokart: "5f681393-a2d3-4423-ba35-54d09156651b",
        dokument_required: false,
      }
    };
    this.KoFiIndex = 4;
    this.Kkostenart = KKostenart;
    this.Kfinanzierungart = KFinanzierungsart;
    this.KVertragskostenbelegStatusUUIDs = KVertragskostenbelegStatusUUIDs;
    this.KBelegePruefmethodeUUIDs = KBelegePruefmethodeUUIDs;
    this.diasModalDialog = diasModalDialog;
    this.diasConfirmDialog = diasConfirmDialog;
    this.AuthService = AuthService;
    this.DiasNotification = DiasNotification;
    this.BetragsrechnerRegistry = BetragsrechnerRegistry;
    this.$scope = $scope;
    this.$filter = $filter;
    this.Upload = Upload;
    this.ListenConstantDokumente = ListenConstantDokumente;

    this.statusInfo = {};
    this.errorFile = [];
    this.errorServer = [];
    // Position
    this.positionKDetails = {};
    this.addPositionMode = {};
    this.editPositionModes = {};
    this.newPosition = {};
    this.editPositionen = {};
    this.createPositionErrors = {};
    this.updatePositionErrors = {};
    this.statusPositionDetail = {};
    this.positionen = [];
    this.antragkostenarten = {};
    this.canEditKofi = false;
    this.hoverEdit = {};
    this.kofiModul = {};
    // Beleg
    this.addBelegMode = {};
    this.editBelegModes = {};
    this.belegExtraModes = {};
    this.newBeleg = {};
    this.editBelege = {};
    this.createBelegErrors = {};
    this.updateBelegErrors = {};
    this.showAllBelege = {};
    this.nextBelegNummer = undefined;
    this.loadingBelegnummer = true;

    this.statusDokumentDownload = {};
    this.loadingPosition = {};
    this.relevante_auszahlungen = [];

    if (this.auszahlung) {
      this.apiBase = this.auszahlung;
    } else {
      this.apiBase = this.vertrag;
    }

    $scope.$on("workflow.aufgabe.changed", () => {
      this.loadRelevanteAuszahlungen();
      this.loadKoFi();
    });

    this.loadRelevanteAuszahlungen();
    this.loadKoFi();
    this.loadBelegDokumente();

    this["K" + this.labelKoFi + "art"].getList().then(
      response => {
        this[this.labelKoFi + "arten"] = response;
      }
    );

    $scope.$watch("vm.reloadStatus", () => {
      if (this.reloadStatus) {
        this.loadRelevanteAuszahlungen();
        this.loadKoFi();
        this.reloadStatus = false;
      }
    });

    this.modalBelegEdit = undefined;
    this.closeModalBelegEdit = ({ status } = {}) => {
      this.$timeout(() => {
        this.currentPosition = undefined;
        this.currentBeleg = undefined;
        this.statusPruefer = undefined;
        this.modalBelegEdit = false;
        if (status) {
          this.loadKoFi();
          this.loadBelegDokumente();
          this.callCallback();
        }
      });
    };
  }

  loadBelegDokumente() {
    return this.antrag.dokumente.getList({ dokart: this.prefix[this.labelKoFi].dokart }).then(
      response => {
        this.belegDokumente = response;
        this.statusCountBelegDokumente = response.length === 0;
      }
    );
  }

  loadNextBelegnummer(position) {
    this.loadingBelegnummer = true;
    this.nextBelegNummer = undefined;
    this.vertrag.customGET("belegnummer").then(
      response => {
        if (response.next) {
          this.nextBelegNummer = response.next;
        }

        if (this.nextBelegNummer && this.newBeleg && position && position.pk && this.newBeleg[position.pk]) {
          this.newBeleg[position.pk][this.prefix[this.labelKoFi].beleg + "_nummer"] =
              this.nextBelegNummer;
        }
      },
      () => this.DiasNotification.error("Fehler beim Abrufen der nächsten Belegnummer.")
    ).finally(
      () => this.loadingBelegnummer = false
    );
  }

  get_betragsrechner(position) {
    /* Ermittelt den für die aktuelle position zu verwendenen Betragsrechner */

    if (!position || !position[`antrag${ this.prefix[this.labelKoFi].art }info`]) {
      return;
    }
    const betragsrechnername = position[`antrag${ this.prefix[this.labelKoFi].art }info`].betragsrechner;
    if (betragsrechnername) {
      const BetragsrechnerCls = this.BetragsrechnerRegistry.getBetragsrechner(betragsrechnername);
      return new BetragsrechnerCls(this.$scope, "beleg");
    }
    return new StandardBetragsrechner(this.$scope, "beleg");
  }

  loadRelevanteAuszahlungen() {
    // Relevant für die Anzeige sind alle vorhergehenden Auszahlungen (und die eigene)
    if (this.auszahlung) {
      for (let i = 0; i < this.auszahlung.vorherige_auszahlungen.length; i++) {
        this.relevante_auszahlungen.push(this.auszahlung.vorherige_auszahlungen[i].pk);
      }
      this.relevante_auszahlungen.push(this.auszahlung.pk);
    }
  }

  disableStammdatenEdit(beleg) {
    if (!beleg) {
      return false;
    }
    return this.AuthService.syncHasPerm("vertragskostenpositionsbelege.update.intern", beleg.user_permissions) &&
           beleg.pruefmethode && beleg.pruefmethode.pk !== this.KBelegePruefmethodeUUIDs.INTERN_ERGAENZT;
  }

  showBetrag(beleg) {
    return !((beleg === undefined || beleg.pk === undefined) && this.AuthService.syncHasPerm("vertragskostenpositionsbelege.create.intern")) &&
           (beleg === undefined || !beleg.pruefmethode || beleg.pruefmethode.pk !== this.KBelegePruefmethodeUUIDs.INTERN_ERGAENZT);
  }

  _getBelegStatus(beleg) {
    if (beleg.status.pk === this.KVertragskostenbelegStatusUUIDs.NEU) {
      return NEU;
    } else if (beleg.status.pk === this.KVertragskostenbelegStatusUUIDs.EINGEREICHT) {
      return EINGEREICHT;
    } else if (beleg.status.pk === this.KVertragskostenbelegStatusUUIDs.GEPRUEFT) {
      const anerkannt = Number(beleg[this.prefix[this.labelKoFi].beleg + "_betrag_anerkannt"]);
      const erfasst = Number(beleg[this.prefix[this.labelKoFi].beleg + "_betrag_erfasst"]);
      if (anerkannt >= erfasst) {
        return VOLLSTAENDIG_ANERKANNT;
      } else if (anerkannt > 0 && anerkannt < erfasst) {
        return TEILWEISE_ANERKANNT;
      } else if (anerkannt === 0) {
        return ABGELEHENT;
      }
    }
  }

  getBelegIcon(beleg) {
    switch (this._getBelegStatus(beleg)) {
    case NEU:
      return "";
    case EINGEREICHT:
      return "ersatzantrag";
    case ABGELEHENT:
      return "remove";
    case TEILWEISE_ANERKANNT:
    case VOLLSTAENDIG_ANERKANNT:
      return "success";
    }
  }

  getBelegCls(beleg) {
    switch (this._getBelegStatus(beleg)) {
    case NEU:
      return "";
    case EINGEREICHT:
      return "text-info";
    case ABGELEHENT:
      return "text-danger";
    case TEILWEISE_ANERKANNT:
    case VOLLSTAENDIG_ANERKANNT:
      return "text-success";
    }
  }

  getBelegTitle(beleg) {
    switch (this._getBelegStatus(beleg)) {
    case NEU:
      return "";
    case EINGEREICHT:
      return this.gettext("Noch nicht bearbeitet");
    case ABGELEHENT:
      return this.gettext("abgelehnt");
    case TEILWEISE_ANERKANNT:
      return this.gettext("mit Änderungen anerkannt");
    case VOLLSTAENDIG_ANERKANNT:
      return this.gettext("in voller Höhe anerkannt");
    }
  }

  getClassBelegPosition(beleg) {
    return getClassBetragAnerkanntInPersonalbaum({ beleg, light: true });
  }

  showIcon(beleg) {
    if (beleg.status.pk === this.KVertragskostenbelegStatusUUIDs.NEU) {
      return "";
    } else if (beleg.status.pk === this.KVertragskostenbelegStatusUUIDs.EINGEREICHT) {
      return "ersatzantrag";
    } else if (beleg.status.pk === this.KVertragskostenbelegStatusUUIDs.GEPRUEFT) {
      if (+beleg[this.prefix[this.labelKoFi].beleg + "_betrag_anerkannt"] === 0) {
        return "remove";
      } return "success";
    }
  }

  showTitle(beleg) {
    if (beleg.status.pk === this.KVertragskostenbelegStatusUUIDs.NEU) {
      return "";
    } else if (beleg.status.pk === this.KVertragskostenbelegStatusUUIDs.EINGEREICHT) {
      return this.gettext("Noch nicht bearbeitet");
    } else if (beleg.status.pk === this.KVertragskostenbelegStatusUUIDs.GEPRUEFT) {
      const anerkannt = Number(beleg[this.prefix[this.labelKoFi].beleg + "_betrag_anerkannt"]);
      const erfasst = Number(beleg[this.prefix[this.labelKoFi].beleg + "_betrag_erfasst"]);
      if (anerkannt >= erfasst) {
        return this.gettext("in voller Höhe anerkannt");
      } else if (anerkannt > 0 && anerkannt < erfasst) {
        return this.gettext("mit Änderungen anerkannt");
      } else if (anerkannt === 0) {
        return this.gettext("abgelehnt");
      }
    }
  }

  showTeilsAnerkannt(beleg) {
    return this._getBelegStatus(beleg) === TEILWEISE_ANERKANNT;
  }

  getBelegAnerkannt(beleg) {
    return beleg[this.prefix[this.labelKoFi].beleg + "_betrag_anerkannt"] === null ? "-" : this.$filter("currency")(beleg[this.prefix[this.labelKoFi].beleg + "_betrag_anerkannt"]);
  }

  getBelegErfasst(beleg) {
    return beleg[this.prefix[this.labelKoFi].beleg + "_betrag_erfasst"] === null ? "-" : this.$filter("currency")(beleg[this.prefix[this.labelKoFi].beleg + "_betrag_erfasst"]);
  }

  callCallback() {
    if (this.savecallback) {
      this.savecallback();
    }
  }

  loadKoFi() {
    this.loading = true;
    this.apiBase[this.labelKoFi].getList().then(
      response => {
        this.KoFi = response;
        this.loadGesamtKoFI();
        this.filterKoFi();
        this.loading = false;
      }
    );
  }

  getPositionenForFunktion(funktion, positionen) {
    const filteredPos = [];
    angular.forEach(positionen, pos => {
      if (pos.vkp_argument.personalfunktion == funktion.pk) {
        filteredPos.push(pos);
      }
    });
    return filteredPos;
  }

  getKostenModul(kofi) {
    if (this.kofiModul[kofi.pk]) {
      return this.kofiModul[kofi.pk];
    }
    if (!this.module) {
      return;
    }
    const m = this.module.find(v => {
      if (v &&
         v.regel &&
         v.regel.argument &&
         v.regel.argument.kostenart &&
         kofi &&
         kofi.kostenart &&
         v.regel.argument.kostenart.wert === kofi.kostenart.pk) {
        return true;
      }
    });
    this.kofiModul[kofi.pk] = m;
    return m;
  }

  getFinanzModul(kofi) {
    if (this.kofiModul[kofi.pk]) {
      return this.kofiModul[kofi.pk];
    }
    if (!this.module) {
      return;
    }
    const m = this.module.find(v => {
      if (v &&
         v.regel &&
         v.regel.argument &&
         v.regel.argument.finanzierungsart &&
         kofi &&
         kofi.finanzierungsart &&
         v.regel.argument.finanzierungsart.wert === kofi.finanzierungsart.pk) {
        return true;
      }
    });
    this.kofiModul[kofi.pk] = m;
    return m;
  }

  getProzent(kofi, typ) {
    if (!this.AuthService.syncHasPerm("vertragskostenpositionen.view.percentage", this.auszahlung.user_permissions)) {
      return "";
    }
    let p;
    let title;
    let base;
    if (this.labelKoFi === "finanzierung") {
      const m = this.getFinanzModul(kofi);
      if (m &&
         m.regel &&
         m.regel.argument &&
         m.regel.argument.externe_mittel &&
         m.regel.argument.externe_mittel.wert) {
        title = this.gettext("Externes Mittel - Wird für die Berechnung von den angegebenen Kosten abgezogen.");
        return `<br><small class="procent" title="${ title }">-</small>`;
      }
      p = this.getProzentFinanz(kofi, typ, m);
      title = this.gettext("von den förderfähigen Kosten");
      if (this.infoprovider && typ === "anerkannt") {
        base = this.infoprovider.kennzahlen.summe_foerderfaehige_kosten;
      } else {
        base = this.auszahlung.uebersicht.veranschlagte_kosten;
      }
    } else {
      p = this.getProzentKosten(kofi, typ);
      title = this.gettext("von den Gesamtkosten");
      base = this.KoFi[typ];
    }
    if (p !== undefined) {
      const pStr = this.$filter("number")(Number(p), 2);
      let kostenStr = "";
      if (base) {
        kostenStr = ` (${ this.$filter("currency")(base) })`;
      }
      return `<br><small class="procent" title="${ pStr }% ${ title }${ kostenStr }">${ pStr }&nbsp;%</small>`;
    }
    return "";
  }

  getProzentFinanz(kofi, typ, m) {
    if (typ === "anerkannt" &&
       m &&
       m.regel &&
       this.infoprovider &&
       this.infoprovider.details &&
       this.infoprovider.details[m.regel.pk] &&
       this.infoprovider.details[m.regel.pk].prozent !== undefined) {
      return this.infoprovider.details[m.regel.pk].prozent;
    } else if (typ === "anerkannt" &&
              kofi &&
              kofi.vf_prozent_aktualisiert) {
      return kofi.vf_prozent_aktualisiert;
    } else if (typ === "anerkannt" &&
              this.infoprovider &&
              this.infoprovider.kennzahlen &&
              this.infoprovider.kennzahlen.summe_foerderfaehige_kosten &&
              kofi &&
              kofi.vf_betrag_anerkannt) {
      return 100 * Number(kofi[`vf_betrag_${ typ }`]) / this.infoprovider.kennzahlen.summe_foerderfaehige_kosten;
    } else if (typ === "bewilligt" &&
              kofi &&
              kofi.vf_prozent_bewilligt) {
      return kofi.vf_prozent_bewilligt;
    } else if (typ === "bewilligt" &&
              this.auszahlung &&
              this.auszahlung.uebersicht &&
              this.auszahlung.uebersicht.veranschlagte_kosten &&
              kofi &&
              kofi[`vf_betrag_${ typ }`]) {
      return 100 * Number(kofi[`vf_betrag_${ typ }`]) / this.auszahlung.uebersicht.veranschlagte_kosten;
    }
    return undefined;
  }

  getProzentKosten(kofi, typ) {
    if (this.KoFi &&
       this.KoFi[typ] !== undefined &&
       kofi[`vk_betrag_${ typ }`] !== undefined) {
      return 100 * Number(kofi[`vk_betrag_${ typ }`]) / this.KoFi[typ];
    }
    return undefined;
  }


  filterKoFi() {
    this.positionen = [];
    this.KoFi.erfasst = 0;
    this.KoFi.anerkannt = 0;
    this.KoFi.bewilligt = 0;
    if (this.labelKoFi === "finanzierung") {
      // Für die Finanzierungssicht die Pauschalen rausfiltern:
      for (let i = 0; i < this.KoFi.length; i++) {
        if (this.KoFi[i].modulgruppen.indexOf("pauschale") !== -1 ||
            this.KoFi[i].modulgruppen.indexOf("zuschuss") !== -1 ||
            this.KoFi[i].modulgruppen.indexOf("deckelung") !== -1) {
          this.KoFi.splice(i, 1);
          i--;
        }
      }
    }
    for (let i = 0; i < this.KoFi.length; i++) {
      this.KoFi[i].erfasst = 0;
      this.KoFi[i].anerkannt = 0;
      this.KoFi[i].bewilligt = 0;
      let max_betrag = null;
      for (let j = 0; j < this.KoFi[i].positionen.length; j++) {
        if (this.KoFi[i].positionen[j].antragkostenartinfo) {
          const param = this.KoFi[i].positionen[j].antragkostenartinfo.antragsregel.afr_argument;
          max_betrag = param.max_betrag ? Number(param.max_betrag.wert) : null;
        }
        this.KoFi[i].positionen[j].oberpunkt = this.KoFi[i][this.prefix[this.labelKoFi].vkofi + "_pos"];
        this.positionen.push(this.KoFi[i].positionen[j]);
        this.KoFi[i].positionen[j].erfasst = 0;
        this.KoFi[i].positionen[j].anerkannt = 0;
        for (let k = 0; k < this.KoFi[i].positionen[j].belege.length; k++) {
          this.KoFi[i].positionen[j].erfasst += +this.KoFi[i].positionen[j].belege[k][this.prefix[this.labelKoFi].beleg + "_betrag_erfasst"];
          this.KoFi[i].positionen[j].anerkannt += +this.KoFi[i].positionen[j].belege[k][this.prefix[this.labelKoFi].beleg + "_betrag_anerkannt"];
        }

        this.KoFi[i].erfasst += +this.KoFi[i].positionen[j].erfasst;
        this.KoFi[i].anerkannt += +this.KoFi[i].positionen[j].anerkannt;
        this.KoFi[i].bewilligt += +this.KoFi[i].positionen[j][this.prefix[this.labelKoFi].position + "_betrag_bewilligt"];
        if (max_betrag) {
          this.KoFi[i].max_betrag = max_betrag;
          this.KoFi[i].erfasst = Math.min(max_betrag, this.KoFi[i].erfasst);
          this.KoFi[i].erfasst_gekappt = max_betrag === this.KoFi[i].erfasst;
          this.KoFi[i].anerkannt = Math.min(max_betrag, this.KoFi[i].anerkannt);
          this.KoFi[i].anerkannt_gekappt = max_betrag === this.KoFi[i].anerkannt;
          this.KoFi[i].bewilligt = Math.min(max_betrag, this.KoFi[i].bewilligt);
          this.KoFi[i].bewilligt_gekappt = max_betrag === this.KoFi[i].bewilligt;
        }
      }

      this.KoFi.erfasst += this.KoFi[i].erfasst;
      this.KoFi.anerkannt += this.KoFi[i].anerkannt;
      this.KoFi.bewilligt += +this.KoFi[i][this.prefix[this.labelKoFi].vkofi + "_betrag_bewilligt"];
    }
    this.loading = false;
  }

  loadGesamtKoFI() {
    this.vertrag.getGesamtKoFi(this.labelKoFi).then(
      response => {
        this.gesamtKoFI = response;
      }
    );
  }

  loadBeleg(position) {
    this.loadingPosition[position.pk] = true;
    position.positionBelege.getList().then(
      response => {
        position.belege = response;
        this.filterKoFi();
        this.loadingPosition[position.pk] = false;
      }
    );
  }

  toggleShowAllBelege(position) {
    this.showAllBelege[position.pk] = !this.showAllBelege[position.pk];
  }

  getPosById(pk) {
    for (let i = 0; i < this.positionen.length; i++) {
      if (this.positionen[i].pk === pk) {
        return this.positionen[i];
      }
    }
  }

  toggleAddBeleg(position, kofi) {
    if (!this.checkBetragRechner(position)) {
      this.currentPosition = position;
      this.modalBelegEdit = true;
      this.statusPruefer = false;
      return;
    }
    const pk = position.pk;

    this.errorFile[pk] = null;
    this.errorServer[pk] = null;

    this.addBelegMode[pk] = !this.addBelegMode[pk];

    if (!this.addBelegMode[pk]) {
      return;
    }

    // clear model
    if (this.newBeleg[pk]) {
      delete this.newBeleg[pk];
    }

    const pos = this.getPosById(pk);
    const betragsrechner = this.get_betragsrechner(pos);
    if (betragsrechner) {
      this.newBeleg[pk] = {};
      this.loadNextBelegnummer(position);
      if (this.syConfigsAuszahlung.belegdaten_vorbelegen) {
        this.newBeleg[pk][this.prefix[this.labelKoFi].beleg + "_nummer"] =
          `${ this.KoFiIndex }.${ kofi ? kofi[this.prefix[this.labelKoFi].vkofi + "_pos"] : "" }.${ position[this.prefix[this.labelKoFi].position + "_pos"] }-${ position.belege.length + 1 }`;
      }
      this.newBeleg[pk].argument = betragsrechner.get_fields({});
      this.newBeleg[pk].rechner = betragsrechner;
      this.newBeleg[pk].extras = {
        antragsregel: pos[`antrag${ this.prefix[this.labelKoFi].art }info`].antragsregel,
        argument: pos[`antrag${ this.prefix[this.labelKoFi].art }info`].argument };
      if (this.labelKoFi === "kosten") {
        this.newBeleg[pk].extras.akpargument = pos.foerderantragkostenartpos.argument;
      }
    }
  }

  toggleAddPosition(KoFi, funktion) {
    let key = KoFi.pk;
    if (funktion) {
      key += funktion.pk;
    }
    if (!funktion && !this.addPositionMode[key] && !this.positionKDetails[KoFi.pk]) {
      this.setPositionKDetails(KoFi);
    }
    // clear model
    if (this.newPosition[KoFi.pk]) {
      delete this.newPosition[KoFi.pk];
    }

    this.addPositionMode[key] = !this.addPositionMode[key];
  }

  setPositionKDetails(KoFi) {
    for (let i = 0; i < this[this.labelKoFi + "arten"].length; i++) {
      if (this[this.labelKoFi + "arten"][i].pk === KoFi[this.prefix[this.labelKoFi].art].pk) {
        this.positionKDetails[KoFi.pk] = this[this.labelKoFi + "arten"][i].details;
        break;
      }
    }
  }

  toggleEditBeleg(beleg, position) {
    if (!this.editBelegModes[beleg.pk]) {
      if (!this.checkBetragRechner(position)) {
        this.currentPosition = position;
        this.currentBeleg = beleg;
        this.modalBelegEdit = true;
        this.statusPruefer = this.AuthService.syncHasPerm("vertragskostenpositionsbelege.anerkennen", this.auszahlung.user_permissions);
        return;
      }

      this.editBelege[beleg.pk] = beleg.clone();
      this.loadNextBelegnummer(position);
      const pos = this.getPosById(beleg[this.prefix[this.labelKoFi].vertragsPos]);
      const betragsrechner = this.get_betragsrechner(pos);
      if (betragsrechner) {
        let argument_betrag;
        let argument_params;

        if (beleg.status.pk === this.KVertragskostenbelegStatusUUIDs.NEU) {
          /* Wenn der Beleg neu ist, dann müssen die Werte aus den "erfasst"
             Werten entnommen werden: */
          argument_params = beleg[`${ this.prefix[this.labelKoFi].beleg }_argument_erfasst`];
          argument_betrag = beleg[`${ this.prefix[this.labelKoFi].beleg }_betrag_erfasst`];
        } else if (beleg.status.pk === this.KVertragskostenbelegStatusUUIDs.GEPRUEFT) {
          /* Wenn der Beleg durch den Safo angelegt wurde, dann müssen die Werte aus den "anerkannt"
             Werten entnommen werden: */
          argument_betrag = beleg[`${ this.prefix[this.labelKoFi].beleg }_betrag_anerkannt`];
          argument_params = beleg[`${ this.prefix[this.labelKoFi].beleg }_argument_anerkannt`];
        } else if (beleg.status.pk === this.KVertragskostenbelegStatusUUIDs.EINGEREICHT) {
          /* Wenn der Beleg eingereicht ist, dann muss differenziert werden: */
          if (beleg[`${ this.prefix[this.labelKoFi].beleg }_betrag_anerkannt`] === null) {
            // Der Beleg wurde noch nicht durch den Sachbearbeiter angefasst:
            argument_betrag = beleg[`${ this.prefix[this.labelKoFi].beleg }_betrag_erfasst`];
            argument_params = beleg[`${ this.prefix[this.labelKoFi].beleg }_argument_erfasst`];
          } else if (beleg[`${ this.prefix[this.labelKoFi].beleg }_betrag_anerkannt`] === "0.00") {
            // Der Beleg wurde schon mal abgelehnt:
            argument_betrag = "0.00";
            argument_params = betragsrechner.get_fields({});
          } else {
            // Der Beleg wurde schon mal vom Sachbearbeiter bearbeitet:
            argument_betrag = beleg[`${ this.prefix[this.labelKoFi].beleg }_betrag_anerkannt`];
            argument_params = beleg[`${ this.prefix[this.labelKoFi].beleg }_argument_anerkannt`];
          }
        }

        this.editBelege[beleg.pk].argument = argument_params;
        if (betragsrechner.betrag_editable) {
          if (!_.isObject(this.editBelege[beleg.pk].argument)) {
            this.editBelege[beleg.pk].argument = {};
          }
          this.editBelege[beleg.pk].argument.wert = argument_betrag;
        }

        this.editBelege[beleg.pk].rechner = betragsrechner;
        this.editBelege[beleg.pk].extras = {
          antragsregel: pos[`antrag${ this.prefix[this.labelKoFi].art }info`].antragsregel,
          argument: pos[`antrag${ this.prefix[this.labelKoFi].art }info`].argument };
      }
    } else {
      if (this.updateBelegErrors[beleg.pk]) {
        delete this.updateBelegErrors[beleg.pk];
      }
    }

    this.errorFile[beleg.pk] = null;
    this.errorServer[beleg.pk] = null;
    this.editBelegModes[beleg.pk] = !this.editBelegModes[beleg.pk];
    this.belegExtraModes[beleg.pk] = false;
  }

  toggleEditPosition(position, KoFi) {
    if (!this.editPositionModes[position.pk]) {
      this.editPositionen[position.pk] = position.clone();
      if (!this.positionKDetails[KoFi.pk]) {
        this.setPositionKDetails(KoFi);
      }
    }
    this.editPositionModes[position.pk] = !this.editPositionModes[position.pk];
  }

  createBeleg(position) {
    if (this.createBelegErrors[position.pk]) {
      delete this.createBelegErrors[position.pk];
    }
    this.errorFile[position.pk] = null;
    this.errorServer[position.pk] = null;
    const data = this.newBeleg[position.pk] || {};
    data["vertrags" + this.labelKoFi + "position"] = position.pk;
    data.auszahlung = this.auszahlung.pk;
    if (data.dokument && angular.isObject(data.dokument)) {
      data.dokument = data.dokument.pk;
    }
    position.createBeleg(data).then(() => {
      this.toggleAddBeleg(position);
      this.loadKoFi();
      this.loadBelegDokumente();
      this.callCallback();
    }, error => {
      this.createBelegErrors[position.pk] = error.data;
    });
  }

  createPosition(KoFi, funktion) {
    this.positionLoadingStatus = true;
    if (this.createPositionErrors[KoFi.pk]) {
      delete this.createPositionErrors[KoFi.pk];
    }
    const data = this.newPosition[KoFi.pk] || {};
    data[this.prefix[this.labelKoFi].art + "detail"] = data[this.prefix[this.labelKoFi].art + "detail"] || null;
    data["vertrags" + this.labelKoFi] = KoFi.pk;
    if (funktion) {
      data.vkp_argument = { personalfunktion: funktion.pk };
    }
    KoFi.createPosition(data).then(() => {
      this.toggleAddPosition(KoFi, funktion);
      this.positionLoadingStatus = false;
      this.loadKoFi();
    }, error => {
      this.createPositionErrors[KoFi.pk] = error.data;
      this.positionLoadingStatus = false;
    });
  }

  updateBeleg(beleg/* , position*/) {
    if (this.updateBelegErrors[beleg.pk]) {
      delete this.updateBelegErrors[beleg.pk];
    }
    const data = this.editBelege[beleg.pk].clone();
    if (this.auszahlung && beleg.status.pk === this.KVertragskostenbelegStatusUUIDs.EINGEREICHT) {
      if (data[this.prefix[this.labelKoFi].beleg + "_betrag_erfasst"]) {
        delete data[this.prefix[this.labelKoFi].beleg + "_betrag_erfasst"];
      }
    }
    if (!data[this.prefix[this.labelKoFi].beleg + "_betrag_anerkannt"] || data[this.prefix[this.labelKoFi].beleg + "_betrag_anerkannt"].trim() === "") {
      data[this.prefix[this.labelKoFi].beleg + "_betrag_anerkannt"] = null;
    }
    if (data.dokument && angular.isObject(data.dokument)) {
      data.dokument = data.dokument.pk;
    }
    beleg.customPUT(data).then(() => {
      this.toggleEditBeleg(beleg);
      this.loadKoFi();
      this.loadBelegDokumente();
      this.callCallback();
    }, error => {
      this.updateBelegErrors[beleg.pk] = error.data;
    });
  }

  updatePosition(position) {
    this.positionLoadingStatus = true;
    if (this.updatePositionErrors[position.pk]) {
      delete this.updatePositionErrors[position.pk];
    }
    const data = this.editPositionen[position.pk];
    position.customPUT(data).then(() => {
      this.toggleEditPosition(position);
      this.positionLoadingStatus = false;
      this.loadKoFi();
    }, error => {
      this.updatePositionErrors[position.pk] = error.data;
      this.positionLoadingStatus = false;
    });
  }

  confirmDeleteBeleg(beleg, position) {
    return this.diasConfirmDialog({
      titleTemplate: this.gettext("Beleg") + " löschen?",
      contentTemplate: `<p>Sind Sie sicher, dass Sie diesen ` + this.gettext("Beleg") + " " + beleg.vpb_bez + ` löschen wollen?</p>`,
      okLabel: "Löschen",
      okCallback: () => this.deleteBeleg(beleg, position)
    });
  }

  deleteBeleg(beleg/* , position*/) {
    beleg.remove().then(() => {
      if (this.editBelegModes[beleg.pk]) {
        delete this.editBelegModes[beleg.pk];
        delete this.belegExtraModes[beleg.pk];
      }
      this.loadKoFi();
      this.loadBelegDokumente();
      this.callCallback();
    });
  }

  confirmDeletePosition(position) {
    return this.diasConfirmDialog({
      titleTemplate: "Position löschen?",
      contentTemplate: `<p>Sind Sie sicher, dass Sie diese Position löschen wollen?</p>`,
      okLabel: "Löschen",
      okCallback: () => this.deletePosition(position)
    });
  }

  deletePosition(position) {
    position.remove().then(() => {
      if (this.editPositionModes[position.pk]) {
        delete this.editPositionModes[position.pk];
      }
      this.loadKoFi();
    });
  }

  canDeleteDokument(beleg) {
    return beleg.dokument && this.AuthService.syncHasPerm("vertragskostenpositionsbelege.update", beleg.user_permissions);
  }

  deleteDokument(beleg) {
    return this.diasModalDialog({
      title: "Dokument an Beleg löschen",
      template: deleteDokumentTemplate,
      controller: deleteDokumentController,
      extras: {
        vId: this.vertrag.pk,
        dokId: beleg.dokument
      }
    }).then(result => {
      if (result) {
        this.loadKoFi();
      }
    });
  }

  confirmResetBeleg(beleg, position) {
    return this.diasConfirmDialog({
      titleTemplate: this.gettext("Beleg") + " zurücksetzen?",
      contentTemplate: `<p>Sind Sie sicher, dass Sie die Anerkennung des ` + this.gettext("Belegs") + ` ` + beleg.vpb_bez + ` zurücksetzen wollen?</p>`,
      okLabel: "Zurücksetzen",
      okCallback: () => this.zuruecksetzenBeleg(beleg, position)
    });
  }

  zuruecksetzenBeleg(beleg) {
    beleg.zuruecksetzenBeleg({}).then(
      () => {
        this.DiasNotification.page.success(this.gettext("Beleg wurde zurückgesetzt"));
        this.loadKoFi();
        this.callCallback();
      }
    );
  }

  anerkennenBeleg(beleg) {
    beleg.anerkennenBeleg({}).then(
      () => {
        this.DiasNotification.page.success(this.gettext("Beleg wurde anerkannt"));
        this.loadKoFi();
        this.callCallback();
      }
    );
  }

  ablehnenBeleg(beleg) {
    beleg.ablehnenBeleg().then(
      () => {
        this.DiasNotification.page.success(this.gettext("Beleg wurde abgelehnt"));
        this.loadKoFi();
        this.callCallback();
      }
    );
  }

  togglePositionDetail(position) {
    this.statusPositionDetail[position.pk] = !this.statusPositionDetail[position.pk];
  }

  toggleBelegExtra(position) {
    this.belegExtraModes[position.pk] = !this.belegExtraModes[position.pk];
    this.editBelegModes[position.pk] = false;
  }

  showErrorLabel(field, position) {
    if (field === this.prefix[this.labelKoFi].position + "_betrag_bewilligt") {
      return "Betrag in EUR: ";
    } else if (field === this.prefix[this.labelKoFi].position + "_bez") {
      return "Bezeichnung: ";
    } else if (field === this.prefix[this.labelKoFi].art + "detail") {
      return "Typ / Kategorie: ";
    } else if (field === this.prefix[this.labelKoFi].beleg + "_nummer") {
      return this.gettext("Belegnummer") + ": ";
    } else if (field === this.prefix[this.labelKoFi].beleg + "_bez") {
      return "Bezeichnung: ";
    } else if (field === this.prefix[this.labelKoFi].beleg + "_betrag_erfasst") {
      return this.gettext("Belegbetrag") + ": ";
    } else if (field === this.prefix[this.labelKoFi].beleg + "_betrag_anerkannt") {
      return "Anerkannter Betrag: ";
    } else if (field === "dokument") {
      return "Datei: ";
    } else if (field === "wert") {
      return "Betrag in EUR: ";
    } else if (position) {
      let label;
      angular.forEach(position.extra_fields, item => {
        if ((field === "dokument" && field === item[0]) || field === "extra_" + item[0]) {
          label = item[1].bez + ":";
        }
      });
      return label;
    }
  }

  toggleDokumentDownload(position) {
    this.statusDokumentDownload[position.pk] = !this.statusDokumentDownload[position.pk];
  }

  getDokumenteLink(beleg) {
    return this.antrag.getRestangularUrl() + "/dokumente/" + beleg.dokument + "/";
  }

  showFormatName(kbez, bez) {
    let name;
    let namesuffix = "";
    if (kbez) {
      name = kbez + ": " + bez;
    } else {
      name = bez;
    }
    if (name.length > 50) {
      namesuffix = "...";
    }
    name = this.$filter("limitTo")(name, 50);
    return name + namesuffix;
  }

  filterView(firstItem, secondItem, condition) {
    let tempItem = condition ? firstItem : secondItem;
    tempItem = tempItem ? this.$filter("currency")(tempItem) : "-";
    return tempItem;
  }

  uploadDokument(file, invalidFile, positionPk, belegPk) {
    this.statusDokumentDownload[positionPk] = true;
    this.deleteErrors(positionPk, belegPk);
    this.downloadFiles = file;
    if (file) {
      file.upload = this.Upload.upload({
        url: this.antrag.dokumente.getUploadUrl(this.prefix[this.labelKoFi].dokart),
        data: { file: file }
      });
      file.upload.then(
        response => {
          // file is uploaded successfully
          if (response.status === 200) {
            file.progress = 100;
          }
          this.deleteErrors();
          this.loadBelegDokumente().finally(() => {
            if (!belegPk) {
              this.newBeleg[positionPk].dokument = response.data;
            } else {
              this.editBelege[belegPk].dokument = response.data;
            }
            this.statusDokumentDownload[positionPk] = false;
          });
        },
        error => {
          // handle error
          if (!belegPk) {
            this.errorServer[positionPk] = error.data;
          } else {
            this.errorServer[belegPk] = error.data;
          }
          this.statusDokumentDownload[positionPk] = false;
        },
        evt => {
          // progress notify
          file.progress = Math.min(50, parseInt(100.0 * evt.loaded / evt.total));
        }
      );
    } else if (invalidFile) {
      if (!belegPk) {
        this.errorFile[positionPk] = invalidFile;
      } else {
        this.errorFile[belegPk] = invalidFile;
      }
      this.statusDokumentDownload[positionPk] = false;
    }
  }

  deleteErrors(positionPk, belegPk) {
    if (!belegPk) {
      this.errorFile[positionPk] = null;
      this.errorServer[positionPk] = null;
    } else {
      this.errorFile[belegPk] = null;
      this.errorServer[belegPk] = null;
    }
  }

  toggleStatusInfo(index) {
    this.statusInfo[index] = !this.statusInfo[index];
  }

  mouseenterPosition(pk) {
    if (pk) {
      this.hoverEdit[pk] = true;
    } else {
      this.hoverEditNew = true;
    }
  }

  mouseleavePosition(pk) {
    if (pk) {
      this.hoverEdit[pk] = false;
    } else {
      this.hoverEditNew = false;
    }
  }

  filterExtraFields(field) {
    return (field[0] !== "dokument");
  }

  showBelegExtra(position) {
    // Dropdown für Beleg-Extras nur anzeigen, wenn es außer dem
    // Dokument Extra-Felder gibt
    for (let i = 0; i < position.extra_fields.length; i++) {
      if (position.extra_fields[i][0] !== "dokument") {
        return true;
      }
    }
    return false;
  }

  showCurrentBeleg(beleg, position) {
    return !this.auszahlung || this.showAllBelege[position.pk] || !beleg.auszahlung || beleg.auszahlung === this.auszahlung.pk;
  }

  showButtonAddBeleg(position) {
    if (!this.auszahlung) {
      return true;
    }
    let count = 0;
    angular.forEach(position.belege, beleg => {
      if (beleg.auszahlung === this.auszahlung.pk) {
        count++;
      }
    });
    return this.syConfigsAuszahlung.max_anz_belege_pos > count;
  }

  checkBetragRechner(position) {
    if (!position || !position.antragkostenartinfo || !position.antragkostenartinfo.betragsrechner) {
      return true;
    }
  }

  canAnerkennen(beleg) {
    if (!beleg && !this.auszahlung) {
      return;
    }
    const perms = beleg ? beleg.user_permissions : this.auszahlung.user_permissions;
    return this.AuthService.syncHasPerm("vertragskostenpositionsbelege.anerkennen", perms);
  }

  canCreatePosition(KoFi) {
    const nachreichenErlaubt = KoFi[this.prefix[this.labelKoFi].art][this.prefix[this.labelKoFi].bezeichnung + "_nachreichen_erlaubt"];
    // PA-3127: Komponente wird nicht verwendet, weswegen die vertragskostenpositionen Berechtigung nicht angepasst wird.
    const permCreate = this.AuthService.syncHasPerm("vertragskostenpositionen.create", this.auszahlung.user_permissions);
    const permNachreichen = this.AuthService.syncHasPerm("vertragskostenpositionen.nachreichen", this.auszahlung.user_permissions);
    return (permCreate || (nachreichenErlaubt && permNachreichen));
  }
}


export default {
  template: template(),
  controller: KoFiErfassungController,
  controllerAs: "vm",
  bindings: {
    vertrag: "<",
    auszahlung: "<",
    antrag: "<",
    labelKoFi: "<",
    savecallback: "&?",
    reloadStatus: "=?",
    module: "<?",
    infoprovider: "<",
  }

};
