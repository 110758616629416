import AngebotRegelsatzVorlage from "../AngebotRegelsatzVorlage/AngebotRegelsatzVorlage.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Modal from "../../../global/components/Modal/Modal.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "AngebotModalCreate",
  components: {
    AngebotRegelsatzVorlage,
    PuxButton,
    PuxTranslate,
    Modal,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "instrumentvariante",
            type: "select",
            label: "_LBL_ANGEBOT_MODAL_CREATE_INSTRUMENTVARIANTE_",
            required: true,
            keyLabelCallback: ({ item }) => `${ item.fiv_kbez } (${ item.instrument_obj.foi_kbez })`,
            keyId: "fiv_id",
            view: "v",
            menuWidthAll: true,
            url: "foerderinstrumentvarianten/",
            apiSaveId: "foerderinstrumentvarianten",
            translate: true,
          },
          {
            id: "foe_kbez",
            type: "text",
            label: "_LBL_ANGEBOT_MODAL_CREATE_KURZBEZEICHNUNG_",
            required: true,
            view: "v",
            translate: true,
          },
          {
            id: "foe_bez",
            type: "richtext",
            label: "_LBL_ANGEBOT_MODAL_CREATE_BEZEICHNUNG_",
            required: true,
            view: "v-alt",
            translate: true,
          },
          {
            id: "foe_kuerzel",
            type: "text",
            label: "_LBL_ANGEBOT_MODAL_CREATE_KUERZEL_",
            required: true,
            view: "v",
            translate: true,
          },
          {
            id: "organisation",
            type: "select",
            label: "_LBL_ANGEBOT_MODAL_CREATE_FOERDERORGANISATION_",
            keyLabel: "o_name",
            keyId: "o_id",
            view: "v",
            menuWidthAll: true,
            url: "foerderorganisationen/",
            apiSaveId: "foerderorganisationen",
            translate: true,
          },
          {
            id: "regelsatz_vorlage",
            type: "select",
            label: "_LBL_ANGEBOT_MODAL_CREATE_REGELSATZ_VORLAGE_",
            keyId: "ars_id",
            view: "v",
            menuWidthAll: true,
            url: "regelsatz_vorlagen/",
            apiSaveId: "regelsatz_vorlagen",
            translate: true,
            slot: "regelsatz_vorlage",
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  methods: {
    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.postHttp({ url: `foerderangebote/`, data }).then(
        response => {
          this.addNotification({ text: "_MSG_ANGEBOT_MODAL_CREATE_SUCCESS_" });
          this.close();
          this.$goTo("root.foerderangebote.detail", { id: response.pk });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
