import AngularLink from "../../global/components/AngularLink/AngularLink.vue";
import MenuElementNachrichten from "./MenuElementNachrichten/MenuElementNachrichten.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";

import translate from "../../global/directives/translate";

import {
  isTranslatePlaceholder,
  gettext,
} from "../../global/functions/utils";
import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "MenuElement",
  components: {
    AngularLink,
    benachrichtigungen: MenuElementNachrichten,
    PuxTranslate,
  },
  directives: {
    translate,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    statusLinkDisabled: {
      type: Boolean,
      required: true,
    },
    setStatusLinkDisabled: {
      type: Function,
      required: true,
    },
  },
  computed: {
    state() {
      return this.data.state.kru_name;
    },

    stateOptions() {
      if (this.data.block_first_load) {
        return {
          blockFirstLoad: "true"
        };
      }
      return {};
    },

    titleLevel1WithChildrenTranslateOptions() {
      if (this.statusLink1Active) {
        return {
          title: "_TXT_MENU_PUNKT_WITH_CHILDREN_AUSGEWAELT_{{title}}_",
          extra: {
            title: this.titleTranslate,
          },
        };
      }
      return {
        title: this.titleTranslate,
      };
    },

    titleLevel1TranslateOptions() {
      if (this.statusLink1Active) {
        return this.titleTranslateForActiveLink;
      }
      return {
        title: this.titleTranslate,
      };
    },

    titleLevel2TranslateOptions() {
      if (this.statusLink2Active) {
        return this.titleTranslateForActiveLink;
      }
      return {
        title: this.titleTranslate,
      };
    },

    titleTranslateForActiveLink() {
      return {
        title: "_TXT_MENU_PUNKT_AUSGEWAELT_{{title}}_",
        extra: {
          title: this.titleTranslate,
        },
      };
    },

    titleTranslate() {
      const TITLE = this.data.title || this.data.label;
      if (isTranslatePlaceholder(TITLE)) {
        return gettext(TITLE);
      }
      return TITLE;
    },

    textTranslateOptions() {
      return {
        text: this.data.label,
      };
    },

    linkClass() {
      return `test_${ this.data.key }`;
    },

    linkLevel1Attributes() {
      const ATTRIBUTES = {};
      if (this.statusLink1Active) {
        ATTRIBUTES["aria-current"] = "page";
      }
      return ATTRIBUTES;
    },

    linkLevel2Attributes() {
      const ATTRIBUTES = {};
      if (this.statusLink2Active) {
        ATTRIBUTES["aria-current"] = "page";
      }
      return ATTRIBUTES;
    },

    statusLink1Active() {
      return this.stateParentActivePk && this.stateParentActivePk === this.data.pk;
    },

    statusLink2Active() {
      return this.stateChildActivePk && this.stateChildActivePk === this.data.pk;
    },

    stateParameter() {
      if (get(this.data, "state.kru_name") === "root.dynamicroot") {
        return {
          id: this.data.key,
        };
      }
      return {};
    },
  },
};
