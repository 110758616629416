<script>
import { optionsMerger, propsBinder, findRealParent } from "../utils/utils.js";
import Popper from "../mixins/Popper.js";
import Options from "../mixins/Options.js";
import { tooltip, DomEvent } from "leaflet";

import {
  DomEventsAPI
} from "../API/DomEventsAPI";

/**
 * Display a tooltip on the map
 */
export default {
  name: "LTooltip",
  mixins: [Popper, Options],
  emits: [
    "ready",
  ],
  setup(props, context) {
    const {
      domEvents,
    } = DomEventsAPI(props, context);

    return {
      domEvents,
    };
  },
  mounted() {
    const options = optionsMerger(this.popperOptions, this);
    this.mapObject = tooltip(options);
    DomEvent.on(this.mapObject, this.domEvents);
    propsBinder(this, this.mapObject, this.$options.props);
    this.mapObject.setContent(this.content || this.$el);
    this.parentContainer = findRealParent(this.$parent);
    this.parentContainer.mapObject.bindTooltip(this.mapObject);
    this.$nextTick(() => {
      /**
       * Triggers when the component is ready
       * @type {object}
       * @property {object} mapObject - reference to leaflet map object
       */
      this.$emit("ready", this.mapObject);
    });
  },
  beforeUnmount() {
    if (this.parentContainer) {
      if (this.parentContainer.unbindTooltip) {
        this.parentContainer.unbindTooltip();
      } else if (
        this.parentContainer.mapObject &&
        this.parentContainer.mapObject.unbindTooltip
      ) {
        this.parentContainer.mapObject.unbindTooltip();
      }
    }
  },
};
</script>
