import PuxButton from "../../../PuxButton/PuxButton.vue";
import PuxTranslate from "../../../PuxTranslate/PuxTranslate.vue";

import UICheckboxAndRadiobuttonItemMixin from "../../UICheckboxAndRadiobuttonItemMixin";

import {
  indexOf,
} from "lodash-es";

// @vue/component
export default {
  name: "UiCheckboxItem",
  components: {
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    UICheckboxAndRadiobuttonItemMixin,
  ],
  props: {
    model: {
      type: Array,
      default: undefined,
    },
    hasMaxSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "change",
    "input",
  ],
  computed: {
    isChecked() {
      return indexOf(this.model, this.value) !== -1;
    },

    isDisabled() {
      return this.options.disabled || this.disabled || (this.hasMaxSelected && !this.isChecked);
    },
    containerClass() {
      return this.item.info ? "d-flex" : "";
    },
  },
  methods: {
    toggleModel(item, $event) {
      const indexInModel = indexOf(this.model, this.value);
      if (indexInModel === -1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.model.push(this.value);
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.model.splice(indexInModel, 1);
      }
      this.$emit("input", {
        model: this.model,
        id: this.options.id,
        $event: $event,
        options: this.options,
        item: item,
      });
      this.$emit("change", {
        model: this.model,
        id: this.options.id,
        options: this.options,
        $event: $event,
        item: item,
      });
    },
  },
};
