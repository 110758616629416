"use strict";

import parameterServices from "../services";
import template from "./config.jade";
import controller from "./controller";


const parametermoduleDate = angular.module("dias.foerderregeln.parameter.date", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("date", "parametermodul-date");
  })
  .component("parametermodulDate", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulDateRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: "{{ vm.parameter[vm.key].wert | date }}",
    controllerAs: "vm",
  });
export default parametermoduleDate;
