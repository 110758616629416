import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  initModelFromList,
} from "../../../../../global/functions/mappingForParameterToFormElement";
import {
  assign,
} from "lodash-es";

// @vue/component
export default {
  name: "DokumenteSuchenWizardStep2",
  components: {
    FormstepDetail,
    FormElement,
    PuxButton,
    PuxTranslate,
  },
  props: {
    formElementOptionsFromParent: {
      type: Array,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    kontext: {
      type: Object,
      required: true,
    },
    modelKontext: {
      type: String,
      required: true,
    },
    dokumentPruefStatus: {
      type: Array,
      required: true,
    },
    filteredDokarten: {
      type: Array,
      required: true,
    },
    initModelFromChild: {
      type: Function,
      required: true,
    },
    changeModel: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      panelShow: false,
      formElementOptions: {
        dok_titel: {
          id: "dok_titel",
          label: "_LBL_DOKUMENTE_SUCHEN_WIZARD_TITEL_",
          type: "text",
          view: "v",
          maxlength: 60,
        },
        dok_dokart: {
          id: "dok_dokart",
          label: "_LBL_DOKUMENTE_SUCHEN_WIZARD_DOKUMENTTYP_",
          type: "select",
          view: "v",
          keyId: "pk",
          keyLabel: "bez",
          search: true,
          searchList: ["bez"],
        },
        dok_angefuegt_am: {
          id: "dok_angefuegt_am",
          label: "_LBL_DOKUMENTE_SUCHEN_WIZARD_ANGEFUEGT_AM_",
          type: "daterange",
          view: "v-alt",
        },
        dok_ist_gueltig: {
          id: "dok_ist_gueltig",
          label: "_LBL_DOKUMENTE_SUCHEN_WIZARD_DOKUMENT_AKTUELL_GUELTIG_",
          type: "select",
          view: "v",
          keyId: "value",
          keyLabel: "label",
          data: [
            {
              label: "ja",
              value: "True"
            },
            {
              label: "nein",
              value: "False"
            },
          ]
        },
        pruefstatus: {
          id: "pruefstatus",
          label: "_LBL_DOKUMENTE_SUCHEN_WIZARD_PRUEFSTATUS_",
          type: "select",
          view: "v",
          keyId: "pk",
          keyLabel: "status",
        },
        pruefung_ist_gueltig: {
          id: "pruefung_ist_gueltig",
          label: "_LBL_DOKUMENTE_SUCHEN_WIZARD_PRUEFUNG_AKTUELL_GUELTIG_",
          type: "select",
          view: "v",
          keyId: "value",
          keyLabel: "label",
          data: [
            {
              label: "ja",
              value: "True"
            },
            {
              label: "nein",
              value: "False"
            },
          ]
        },
        dok_datei_name: {
          id: "dok_datei_name",
          label: "_LBL_DOKUMENTE_SUCHEN_WIZARD_DATEINAME_",
          type: "text",
          view: "v",
          maxlength: 60,
        },
      },
    };
  },
  created() {
    this.initModel();
  },
  methods: {
    togglePanel() {
      this.panelShow = !this.panelShow;
    },

    initModel() {
      const MODEL1 = initModelFromList({ list: this.formElementOptions });
      const MODEL2 = initModelFromList({ list: this.formElementOptionsFromParent });
      const MODEL = assign({}, MODEL1, MODEL2);
      this.initModelFromChild({ model: MODEL });
    },

    onChange(arg) {
      this.changeModel(arg);
    },
  },
};
