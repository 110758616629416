var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-class", "{'has-error' :vm.errors['" + (field) + "']}", true, true)) + " class=\"form-group row\"><label" + (jade.attr("for", "" + (field) + "", true, true)) + " class=\"col-form-label col-sm-4 text-right font-weight-bold\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></label><div class=\"col-sm-6\">");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></div></div>");
};
buf.push("<div dias-loading=\"vm.loading\" class=\"row\"><div ng-class=\"vm.regClass\"><div class=\"card\"><div ng-bind-html=\"vm.regHeader.labelChangePassword\" ng-class=\"vm.regHeader.class\"></div><div class=\"card-body\"><div class=\"row\"><div ng-attr-id=\"{{ vm.DiasNotification.form.containerId }}\" ng-class=\"vm.regBody.class\"></div></div><div class=\"row\"><div class=\"col-lg-12\"><p><strong>Ihr Passwort ist abgelaufen. Bitte hinterlegen Sie ein neues Passwort.</strong></p><div class=\"alert alert-info\"><p><strong>Hinweis zur Passwortvorgabe</strong></p><p>Um das Passwort ändern zu können, müssen Sie Ihr bisheriges Passwort korrekt eingeben.\nDas neue Passwort muss zweimal angegeben werden, um Tippfehler zu vermeiden.\nFür Passwörter gelten die folgenden Regeln:</p><ul><li>Ihr Passwort muss mindestens {{ vm.conf.passwordMinLength }} und darf maximal {{ vm.conf.passwordMaxLength }} Zeichen lang sein.</li><li>Es muss mindestens einen Buchstaben und eine Zahl enthalten.</li><li>Folgende Sonderzeichen können verwendet werden:<br>{{ vm.conf.passwordAllowedChars }}</li></ul></div></div></div><form>");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"password_old\" autocomplete=\"off\" ng-attr-maxlength=\"{{vm.conf.passwordMaxLength}}\" name=\"password_old\" ng-change=\"vm.deleteErrors()\" ng-model=\"vm.model.password_old\" type=\"password\" ng-disabled=\"vm.saveStatus\" placeholder=\"Altes Passwort\" class=\"form-control\">");
}
}, 'password_old', 'Altes Passwort*');
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"password_new1\" autocomplete=\"off\" ng-attr-maxlength=\"{{vm.conf.passwordMaxLength}}\" name=\"password_new1\" ng-change=\"vm.deleteErrors()\" ng-model=\"vm.model.password_new1\" type=\"password\" ng-disabled=\"vm.saveStatus\" placeholder=\"Neues Passwort\" class=\"form-control\">");
}
}, 'password_new1', 'Neues Passwort*');
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"password_new2\" autocomplete=\"off\" ng-attr-maxlength=\"{{vm.conf.passwordMaxLength}}\" name=\"password_new2\" ng-change=\"vm.deleteErrors()\" ng-model=\"vm.model.password_new2\" type=\"password\" ng-disabled=\"vm.saveStatus\" placeholder=\"Neues Passwort wiederholen\" class=\"form-control\">");
}
}, 'password_new2', 'Neues Passwort wiederholen*');
buf.push("<div class=\"form-group row\"><div class=\"offset-sm-4 col-sm-6\"><div class=\"text-right\"><span ng-if=\"vm.loading\"><i class=\"spinner inline\"><div class=\"bounce1\"></div><div class=\"bounce2\"></div><div class=\"bounce3\"></div></i><span>&nbsp;</span></span><button type=\"submit\" ng-click=\"vm.savePassword()\" ng-disabled=\"vm.saveStatus\" class=\"btn btn-primary test-save\"><span class=\"glyphicon glyphicon-log-in\"></span>&nbsp;Speichern</button></div><p><dias-requirement></dias-requirement></p></div></div></form></div></div></div></div>");;return buf.join("");
}