"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";

class AnonplanEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification) {
    super($q, $scope, DiasNotification);

    this.onEnterEditMode = this.onEnterEditMode.bind(this);
    this.extra = this.$scope.extra;
    this.errorsLabel = {};
  }

  onEnterEditMode() {
    this.model = {};
    this.$scope.model.forEach(anp => this.model[`${ anp.anon_content_type }_${ anp.anp_stufe }`] = {
      selected: true,
      el: anp.plain ? anp.plain() : anp });
    if (this.$scope.extra.anon_cts) {
      this.$scope.extra.anon_cts.forEach(ct => {
        ct.stufen.forEach(stufe => {
          this.model[`${ ct.pk }_${ stufe.pk }`] = this.model[`${ ct.pk }_${ stufe.pk }`] || {
            selected: false,
            el: {
              anon_content_type: parseInt(ct.pk),
              anp_stufe: stufe.pk,
              anp_frist: undefined
            }
          };
        });
      });
    }
  }

  onSelected(key) {
    if (this.errors && this.errors[key]) {
      delete this.errors[key];
    }
  }

  _save(errorsLabel) {
    const pushModel = [];
    Object.keys(this.model).forEach(key => {
      if (this.model[key].selected) {
        pushModel.push(this.model[key].el);
      }
    });
    return this.extra.obj.anonymisierungsplaene.setKonfig(pushModel).then(
      response => {
        this.$scope.model.splice(0, this.$scope.model.length, ...(response.plain()));
        this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
      },
      errors => {
        if (errors.data) {
          errors = errors.data;
        }
        const reformedErrors = {};
        if (!angular.isArray(errors)) {
          errors = [errors];
        }
        errors.forEach((err, idx) => {
          if (Object.keys(err).length === 0) {
            return;
          }
          const errModel = pushModel[idx];
          const ct = this.extra.anon_cts.find(ct => ct.id === errModel.anon_content_type);
          const stufe = ct.stufen.find(st => st.pk === errModel.anp_stufe);
          const errList = [];
          Object.keys(err).forEach(errKey => errList.push(...err[errKey]));
          reformedErrors[`${ errModel.anon_content_type }_${ errModel.anp_stufe }`] = errList;
          errorsLabel[`${ errModel.anon_content_type }_${ errModel.anp_stufe }`] = `${ ct.bez } - ${ stufe.bez }: `;
        });
        const ret = this.onErrors(reformedErrors, errorsLabel);
        return ret;
      }
    );
  }
}

export default AnonplanEditController;
