"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";
import diasTable from "dias/core/table";

const modul = angular.module("dias.dashboard_alternative", [
  uiRouter,
  diasTable.name,
]);

modul.config($stateProvider => {
  $stateProvider

    .state("root.dashboard_alternative", {
      url: "/dashboard_alternative/",
      views: {
        "main@root": {
          template: "<div vueinit><dashboard-public></dashboard-public></div>"
        }
      },
    })
  ;
});

export default modul;
