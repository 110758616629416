import {
  forEach,
  isEmpty,
  some,
} from "lodash-es";

// @vue/component
export default {
  computed: {
    modelKeywordsGroups() {
      return this.setModelKeywordsGroups(this.modelKeywords, this.keywordsMapByPk);
    },
  },
  methods: {
    setModelKeywordsGroups(modelKeywords, keywordsMapByPk) {
      if (!this.modelKeywords.length || isEmpty(keywordsMapByPk)) {
        return {};
      }
      const MODEL_KEYWORDS_GROUPS = {};
      forEach(modelKeywords, keywordId => {
        const CURRENT_KEYWORD = keywordsMapByPk[keywordId];
        if (!CURRENT_KEYWORD) {
          return;
        }
        const CURRENT_GROUP = CURRENT_KEYWORD.gruppe;
        if (!MODEL_KEYWORDS_GROUPS[CURRENT_GROUP]) {
          MODEL_KEYWORDS_GROUPS[CURRENT_GROUP] = {
            name: CURRENT_KEYWORD.gruppen_bez,
            pk: CURRENT_KEYWORD.gruppe,
            keywords: [],
            keywordsFullName: [],
          };
        }
        MODEL_KEYWORDS_GROUPS[CURRENT_GROUP].keywords.push(keywordId);
        MODEL_KEYWORDS_GROUPS[CURRENT_GROUP].keywordsFullName.push(CURRENT_KEYWORD.sw_kbez);
      });
      return MODEL_KEYWORDS_GROUPS;
    },

    hasFaqItemKeywords(faqItem) {
      if (!this.modelKeywords.length) {
        return true;
      }
      let hasKeywordsStatus = true;
      forEach(this.modelKeywordsGroups, keywordsGroup => {
        if (!this.hasFaqItemKeywordsFromGroup(faqItem, keywordsGroup.keywords)) {
          hasKeywordsStatus = false;
          return false;
        }
      });
      return hasKeywordsStatus;
    },

    hasFaqItemKeywordsFromGroup(faqItem, keywordsInGroup) {
      return some(faqItem.schlagworte, keyword => {
        return keywordsInGroup.indexOf(keyword) !== -1;
      });
    },
  },
};
