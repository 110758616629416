import {
  forEach,
  get,
  isNil,
  map,
  uniqBy
} from "lodash-es";

// @vue/component
export default {
  name: "GenericListSlot",
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  computed: {
    elements() {
      if (isNil(this.row)) {
        return;
      }
      let data = get(this.row.data, this.row.col.id);
      if (!isNil(this.row.col.extra)) {
        forEach(this.row.col.extra, (val, key) => {
          if (key !== "field") {
            data = data.filter(el => get(el, key) === val);
          }
        });
      }
      data = uniqBy(data, "pk");
      return map(data, el => {
        return {
          pk: el.pk,
          bez: get(el, get(this.row, "col.extra.field", "bez"))
        };
      });
    },
  },
};
