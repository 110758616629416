import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import AnsprechpartnerModalZuweisen from "../../Ansprechpartner/AnsprechpartnerModalZuweisen/AnsprechpartnerModalZuweisen.vue";
import AnsprechpartnerModalZuweisenMe from "../../Ansprechpartner/AnsprechpartnerModalZuweisenMe/AnsprechpartnerModalZuweisenMe.vue";
import AntraegeModalChangeTeam from "../AntraegeModalChangeTeam/AntraegeModalChangeTeam.vue";
import DokumenteHistory from "../../Dokumente/DokumenteHistory/DokumenteHistory.vue";
import DokumenteSimpleList from "../../Dokumente/DokumenteSimpleList/DokumenteSimpleList.vue";
import NachrichtenLink from "../../Nachrichten/NachrichtenLink/NachrichtenLink.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import KommunikationsdatenUUIDs from "../../../const/KommunikationsdatenUUIDs";
import {
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "AntraegeListDetails",
  components: {
    AngularLink,
    AnsprechpartnerModalZuweisen,
    AnsprechpartnerModalZuweisenMe,
    AntraegeModalChangeTeam,
    DokumenteHistory,
    DokumenteSimpleList,
    NachrichtenLink,
    Permission,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
  ],
  props: {
    antrag: {
      type: Object,
      default: () => ({}),
    },
    replace: {
      type: Function,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const {
      filterCurrency,
      filterDate,
    } = FiltersAPI();

    return {
      filterCurrency,
      filterDate,
    };
  },
  data() {
    return {
      ansprechpartner: undefined,
      statusLoading: true,
      monetaere_foerderung: undefined,
      docAnzeige: "2f413911-a316-c179-e050-007f0101705e",
      dokAnzeigeAblehnungsschreiben: "9807cf97-95a2-4b10-b78a-44b01325f627",
      modal: {
        ansprechpartnerZuweisen: undefined,
        ansprechpartnerZuweisenMe: undefined,
        changeTeam: undefined,
      },
    };
  },
  computed: {
    getDocUrl() {
      return `foerderantraege/${ this.antrag.pk }/`;
    },

    getFilterKommunikationsdaten() {
      return ({ obj }) => {
        if (!obj.nutzer || !obj.nutzer.kommunikationsdaten) {
          return [];
        }
        return filter(obj.nutzer.kommunikationsdaten, item => item.kommtyp && item.kommtyp.kot_id === KommunikationsdatenUUIDs.telefonnr1);
      };
    },
  },
  created() {
    this.initData();
    this.loadData();
  },
  methods: {
    initData() {
      this.monetaere_foerderung = this.getSyConfigsValue("monetaere_foerderung");
    },

    loadData({ statusReload } = {}) {
      if (this.antrag._details && !statusReload) {
        this.statusLoading = false;
        return;
      }
      this.getHttp({ url: `foerderantraege/${ this.antrag.pk }/vorschau/` }).then(
        response => {
          response._details = true;
          this.replace(response);
          this.statusLoading = false;
        }
      );
    },

    assignAnsprechpartnerMe(asp) {
      this.ansprechpartner = asp;
      this.modal.ansprechpartnerZuweisenMe = true;
    },

    assignAnsprechpartner(asp) {
      this.ansprechpartner = asp;
      this.modal.ansprechpartnerZuweisen = true;
    },

    closeModalAnsprechpartnerZuweisen({ status } = {}) {
      if (status) {
        this.loadData({ statusReload: true });
      }
      this.modal.ansprechpartnerZuweisen = false;
    },

    closeModalAnsprechpartnerZuweisenMe({ status } = {}) {
      if (status) {
        this.loadData({ statusReload: true });
      }
      this.modal.ansprechpartnerZuweisenMe = false;
    },

    changeTeam() {
      this.modal.changeTeam = true;
    },

    closeModalChangeTeam({ status } = {}) {
      this.modal.changeTeam = false;
      if (status) {
        this.loadData({ statusReload: true });
      }
    },
  },
};
