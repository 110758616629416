"use strict";

import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import antragsteller from "dias/antragsteller/models";
import controller from "./anlage.controller";
import template from "./anlage.jade";
import readonlyController from "./anlage.readonly.controller";
import readonlyTemplate from "./anlage.readonly.jade";
import alleController from "./anlage.alle.controller";
import alleTemplate from "./anlage.alle.jade";

const modul = angular.module("dias.dokumente.module", [
  foerdermoduleRegistry.name,
  antragsteller.name,
]);

modul.config(FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("anlagen", "modul-anlagen");
  FoerdermoduleRegistryProvider.register("anlagen_readonly", "modul-anlagen-readonly");
  FoerdermoduleRegistryProvider.register("anlagen_vertrag", "modul-anlagen-vertrag");
  FoerdermoduleRegistryProvider.register("anlagen_alle", "modul-anlagen-alle");
})

  .directive("modulAnlagen", () => {
    return {
      restrict: "A",
      scope: {
        idx: "@",
        group: "@",
        modul: "=",
        obj: "=",
        readonly: "<?",
        snapshot: "=",
        snapshotdiff: "=",
        dokumenteCondition: "=?",
        updateStatus: "=?",
        dokumentnotizen: "=?",
        notizoptions: "<?",
        notizkey: "<",
      },
      template: template,
      controller: controller,
      controllerAs: "vm",
      bindToController: true
    };
  })

  .directive("modulAnlagenReadonly", () => {
    return {
      restrict: "A",
      scope: {
        idx: "@",
        group: "@",
        modul: "=",
        obj: "=",
        readonly: "<?",
        snapshot: "=",
        snapshotdiff: "=",
        dokumenteCondition: "=?",
        updateStatus: "=?",
        dokumentnotizen: "=?",
        notizoptions: "<?",
        notizkey: "<",
      },
      template: readonlyTemplate,
      controller: readonlyController,
      controllerAs: "vm",
      bindToController: true
    };
  })


  .directive("modulAnlagenVertrag", () => {
    return {
      restrict: "A",
      scope: {
        idx: "@",
        group: "@",
        modul: "=",
        obj: "=",
        readonly: "<?",
        snapshot: "=",
        snapshotdiff: "=",
        dokumenteCondition: "=?",
        updateStatus: "=?",
        dokumentnotizen: "=?",
        notizoptions: "<?",
        notizkey: "<",
      },
      template: template,
      controller: controller,
      controllerAs: "vm",
      bindToController: true
    };
  })

  .directive("modulAnlagenAlle", () => {
    return {
      restrict: "A",
      scope: {
        idx: "@",
        group: "@",
        modul: "=",
        obj: "=",
        readonly: "<?",
        snapshot: "=",
        snapshotdiff: "=",
        dokumenteCondition: "=?",
        updateStatus: "=?",
        dokumentnotizen: "=?",
        notizoptions: "<?",
        notizkey: "<?",
        htmlId: "<?",
      },
      template: alleTemplate,
      controller: alleController,
      controllerAs: "vm",
      bindToController: true
    };
  })
;

export default modul;
