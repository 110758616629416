import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import NutzerStatusUUIDs from "../../../const/NutzerStatusUUIDs";
import {
  isArray,
  isNil,
  isObject,
  isString,
  unescape,
} from "lodash-es";

// @vue/component
export default {
  name: "NachrichtenModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    baseUrl: {
      type: String,
      default: undefined,
    },
    extras: {
      type: Object,
      default: () => ({}),
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        empfaenger: undefined,
        titel: "",
        text: "",
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "empfaenger",
            type: "single_choice",
            label: "_LBL_NACHRICHTEN_MODAL_CREATE_EMPFAENGER_",
            required: true,
            keyId: "pk",
            keyLabel: "name_mit_abwesenheit",
            menuWidthAll: true,
            view: "v",
            url: "nachrichten_empfaenger/?fields=pk&fields=name_mit_abwesenheit",
            searchParameter: "suche",
            searchGlobal: true,
          },
          {
            id: "titel",
            type: "text",
            label: "_LBL_NACHRICHTEN_MODAL_CREATE_BETREFF_",
            required: true,
            view: "v",
          },
          {
            id: "text",
            type: "richtext",
            label: "_LBL_NACHRICHTEN_MODAL_CREATE_TEXT_",
            required: true,
            view: "v-alt",
          },
        ],
      },
      data: {
        empfaenger: undefined,
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
      disabledObj: {
        empfaenger: false,
      },
    };
  },
  computed: {
    isDisabled() {
      const isEmpfaenger = isArray(this.model.empfaenger) ? this.model.empfaenger.length : this.model.empfaenger;
      return !this.model.text.length || !this.model.titel.length || !isEmpfaenger;
    },
  },
  created() {
    this.initModel();
    this.initData();
  },
  methods: {
    initModel() {
      this.model.titel = unescape(this.extras.titel || "");
      // unescape wandet &nbsp; nicht um
      this.model.titel = this.model.titel.replace(/(&nbsp;)/g, " ");
      this.model.text = this.extras.text || "";
    },

    // Bereits eindeutig vorgegebener Empfänger: vorbelegen:
    initData() {
      if (this.extras.feste_empfaenger) {
        this.model.empfaenger = [];
        this.options.list[0].type = "multiselect";
        const inaktiv = [];
        this.disabledObj.empfaenger = this.extras.feste_empfaenger.length > 0;

        for (let i = 0; i < this.extras.feste_empfaenger.length; i++) {
          if (isString(this.extras.feste_empfaenger[i])) {
            this.model.empfaenger.push(this.extras.feste_empfaenger[i]);
          } else if (isObject(this.extras.feste_empfaenger[i]) && this.isUserAktiv(this.extras.feste_empfaenger[i])) {
            this.model.empfaenger.push(this.extras.feste_empfaenger[i].pk);
          } else {
            inaktiv.push(this.extras_empfaenger[i]);
          }
        }
        if (inaktiv.length > 0) {
          let usrList = "";
          for (let i = 0; i < inaktiv.length; i++) {
            usrList += `<li>${ inaktiv[i].name }</li>`;
          }
          const errorStr = `Folgende vorausgewählte Empfänger sind nicht mehr aktiv und wurden aus der Empfängerliste entfernt:<ul>${ usrList }</ul>`;
          this.errors = {
            data: { non_field_error: [errorStr] },
          };
          this.errorsClone = {
            data: { non_field_error: [errorStr] },
          };
        }
      }
    },

    isUserAktiv(usr) {
      return usr.aktiv || (usr.status && NutzerStatusUUIDs.isActive(usr.status.ns_id));
    },

    save() {
      this.status.loading = true;
      let nids = [];
      if (isArray(this.model.empfaenger)) {
        nids = this.model.empfaenger;
      } else {
        nids.push(this.model.empfaenger);
      }

      const data = {
        titel: this.model.titel,
        text: this.model.text,
        bezug: this.extras.bezug,
        bezugpk: this.extras.bezugpk,
        empfaenger: nids,
      };

      let url = "benachrichtigungen/send/";
      if (!isNil(this.extras.weiterleitung)) {
        url = `${ this.baseUrl }${ this.extras.weiterleitung }/forward/`;
      } else if (!isNil(this.extras.antwort)) {
        url = `${ this.baseUrl }${ this.extras.antwort }/reply/`;
      }

      this.postHttp({ url: url, data }).then(
        () => {
          this.addNotification({ text: "_MSG_NACHRICHTEN_MODAL_CREATE_SUCCESS_" });
          this.close({ status: true });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
