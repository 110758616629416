import PuxIcon from "../../../../components/PuxIcon/PuxIcon.vue";

import translate from "../../../../directives/translate";

import UIReadOnlyMixin from "../../UIReadOnlyMixin";

import {
  isUndefined,
} from "lodash-es";

const ICONS = {
  true: "glyphicon-check",
  false: "glyphicon-unchecked",
};

const ICONS_EXTRA_CLS = {
  true: "text-success",
  false: "text-dark",
};

// @vue/component
export default {
  name: "UiBooleanReadOnly",
  components: {
    PuxIcon
  },
  directives: {
    translate,
  },
  mixins: [
    UIReadOnlyMixin,
  ],
  computed: {
    value() {
      if (this.model === true || (!isUndefined(this.options.trueValue) && this.model === this.options.trueValue)) {
        return true;
      } else if (this.model === false || (!isUndefined(this.options.falseValue) && this.model === this.options.falseValue)) {
        return false;
      }
      return null;
    },
    modelLocal() {
      if (this.value === true) {
        return this.options.trueLabel || "_TXT_YES_";
      }
      if (this.value === false) {
        return this.options.falseLabel || "_TXT_NO_";
      }
      return this.options.defaultLabel || "";
    },
    anzeigemodus() {
      return this.options.anzeigemodus || 0;
    },
    icon() {
      return ICONS[String(this.value)];
    },
    iconCls() {
      return ICONS_EXTRA_CLS[String(this.value)];
    }
  },
};
