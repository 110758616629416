"use strict";

import BaseModel from "dias/core/models/baseModel";


class Budget extends BaseModel {
  constructor() {
    super("budgets", {});
  }
}

export default Budget;
