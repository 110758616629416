"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import diasLoading from "dias/core/loading";

import kalender_foerdermodule from "./foerdermodule";

const calendar = angular.module("dias.kalender", [
  uiRouter,
  diasLoading.name,
  kalender_foerdermodule.name,
]);

calendar.config($stateProvider => {
  $stateProvider

    .state("root.calendar", {
      url: `/calendar/`,
      views: {
        "main@root": {
          template: `<div vueinit=""><vue-calendar></vue-calendar></div>`,
        }
      },
      data: {
        permissions: "termin.view"
      }
    })

    .state("root.terminliste", {
      url: `/terminliste/`,
      views: {
        "main@root": {
          template: `<div vueinit><vue-calendar-list></vue-calendar-list></div>`,
        }
      },
      data: {
        permissions: "termin.view"
      }
    });
});


export default calendar;
