"use strict";

import template from "./details.jade";

import createEditModalController from "./tagesordnungsmodul.modal.edit.component";
import createEditModalTemplate from "./tagesordnungsmodul.modal.edit.jade";

import {
  initModelFromList,
  toFormElementFromParameter,
} from "../../../../vue/client/vue/global/functions/mappingForParameterToFormElement";
import {
  forEach,
  isNil
} from "lodash-es";


class SitzungsartDetailsController {
  /*@ngInject*/
  constructor(
    $stateParams,
    $rootScope,
    $q,
    $location,
    $anchorScroll,
    AuthService,
    Sitzungsarten,
    DiasNotification,
    diasModalDialog,
    diasConfirmDialog,
    Katalog,
    ParameterUtils,
    i18n
  ) {
    this.diasModalDialog = diasModalDialog;
    this.diasConfirmDialog = diasConfirmDialog;
    this.$q = $q;
    this.gettext = i18n.gettext;
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;
    this.$location = $location;
    this.$anchorScroll = $anchorScroll;
    $anchorScroll.yOffset = 255;
    this.AuthService = AuthService;
    this.DiasNotification = DiasNotification;
    this.Sitzungsarten = Sitzungsarten;
    this.Katalog = Katalog;
    this.ParameterUtils = ParameterUtils;
    this.loading = {};

    this.posShowDetails = {};
    this.errors = {};
    this.hoverEdit = {};
    this.addPositionMode = {};
    this.editPositionModes = {};
    this.editPositionen = {};
    this.editPositionLoaded = {};
    this.editPositionLoading = {};

    this.canEditAnonymisierungsplan = AuthService.syncHasPerm("sitzungsarten.admin.anonymisierungsplan.update");

    this.errorLabaels = {
      module: "Konfiguration: ",
      sto_pos: "Postition: ",
      to_bez: "Name: ",
      sto_info: `${ this.gettext("_LBL_KSITZUNGSARTTOP_STO_INFO_") }: `
    };

    this.loadSitzungsart();
    this.loadTops();
    this.loadSitzungsmodulkatalog();
  }

  loadSitzungsart() {
    this.loading.sitzungsart = true;
    this.Sitzungsarten.one(this.$stateParams.id).get().then(
      response => this.sitzungsart = response,
      err => this.DiasNotification.page.error(err, "Fehler beim Laden der Sitzungsart")
    ).finally(() => this.loading.sitzungsart = false);
  }

  loadTops() {
    this.loading.tops = true;
    this.Sitzungsarten.one(this.$stateParams.id).tops.getList().then(
      response => {
        this.tops = response.plain();
        this.prepareTops(this.tops);
      },
      err => this.DiasNotification.page.error(err, "Fehler beim Laden der Tagesordnungspunkte")
    ).finally(() => this.loading.tops = false);
  }

  loadSitzungsmodulkatalog() {
    this.loading.sysitzungsmodule = true;
    this.Katalog.getKatalog("sitzungsmodule").then(
      response => this.sitzungsmodulkatalog = response,
      err => this.DiasNotification.page.error(err, "Fehler beim Laden der auswählbaren Sitzungsmodule")
    ).finally(() => this.loading.sysitzungsmodule = false);
  }

  prepareTops(tops, parentIdx) {
    tops.forEach(top => {
      const idx = parentIdx ? `${ parentIdx }.` : "";
      top.absolut_pos = `${ idx }${ top.sto_pos }`;
      if (angular.isArray(top.unterpunkte)) {
        this.prepareTops(top.unterpunkte, top.absolut_pos);
      }
    });
  }

  prepareModul(modul) {
    this.posShowDetails[modul.pk] = false;
    modul.modul_obj = this.sitzungsmodulkatalog.getById(modul.modul);
    if (modul.tom_parameterin) {
      modul.param_keys = this.ParameterUtils.get_ordered_keys(modul.tom_parameterin);
      modul.tom_parameterin = this.ParameterUtils.init_parameter_in(modul.tom_parameterin);
      modul.vueParameterList = toFormElementFromParameter({
        obj: modul.tom_parameterin,
        showLabel: true,
        staticOptions: {
          translate: true,
        }
      }).list;
      modul.vueModel = initModelFromList({ list: modul.vueParameterList });
      forEach(modul.tom_parameterin, (v, k) => {
        modul.vueModel[k] = v.wert;
      });
    }
  }

  loadTopModules(top, model) {
    return this.Sitzungsarten.one(this.$stateParams.id).tops.one(top.pk).module.getList().then(
      response => {
        model.module = response.plain();
        model.module.forEach(v => this.prepareModul(v));
      },
      err => this.DiasNotification.page.error(err, "Fehler beim Laden der bereits hinterlegten Sitzungsmodule")
    );
  }

  toggleEditPosition(punkt) {
    if (!this.editPositionModes[punkt.pk]) {
      this.editPositionen[punkt.pk] = Object.assign({}, punkt);
      this.loadTopModules(punkt, this.editPositionen[punkt.pk]).finally(
        () => {
          this.editPositionLoaded[punkt.pk] = true;
        }
      );
    }
    this.editPositionModes[punkt.pk] = !this.editPositionModes[punkt.pk];
  }

  updatePosition(punkt, model) {
    model.module = model.module || [];
    const data = Object.assign({}, model);
    data.module = model.module.map((value, idx) => {
      value.tom_pos = idx;
      value.top = punkt.top;
      return value;
    });
    this.editPositionLoading[punkt.pk] = true;
    this.updatePositionServer(punkt, data, false).finally(
      () => this.editPositionLoading[punkt.pk] = false
    );
  }

  updatePositionServer(punkt, data, updateStatus) {
    return this.sitzungsart.tops.updateTop(punkt.pk, data).then(
      response => {
        if (!updateStatus) {
          this.toggleEditPosition(punkt);
        }
        this.DiasNotification.page.success(
          `Tagesordnungspunkt "${ punkt.absolut_pos } ${ punkt.top_obj.to_bez }" erfolgreich gespeichert.`
        );
        Object.assign(punkt, response.plain());
        if (this.$location.hash() !== punkt.pk) {
          this.$location.hash(punkt.pk);
        } else {
          this.$anchorScroll();
        }
        if (this.errors[punkt.pk]) {
          delete this.errors[punkt.pk];
        }
      },
      errors => {
        this.onError(errors, data, punkt);
      }
    );
  }

  onError(errors, data, punkt) {
    this.errors[punkt.pk] = {};
    this.errors[punkt.pk] = errors.data;
    if (angular.isArray(this.errors[punkt.pk].module)) {
      const modulErrors = [];
      this.errors[punkt.pk].module.forEach((err, idx) => {
        if (angular.isObject(err) && Object.keys(err).length > 0) {
          modulErrors.push(`Fehler im ${ idx + 1 }. Modul "${ data.module[idx].tom_kbez }": Bitte prüfen Sie die Konfiguration des Moduls.`);
          modulErrors[data.module[idx].pk] = true;
        }
      });
      this.errors[punkt.pk].module = modulErrors;
    }
  }

  showErrorLabel(field) {
    if (this.errorLabaels[field]) {
      return this.errorLabaels[field];
    }
    return "";
  }

  mouseenterPosition(pk) {
    if (pk) {
      this.hoverEdit[pk] = true;
    } else {
      this.hoverEditNew = true;
    }
  }

  mouseleavePosition(pk) {
    if (pk) {
      this.hoverEdit[pk] = false;
    } else {
      this.hoverEditNew = false;
    }
  }

  removeModul(index, topModel) {
    topModel.module.splice(index, 1);
  }

  moveUpModul(index, topModel) {
    if (index === 0) {
      return;
    }
    const elem = topModel.module[index];
    const previous = topModel.module[index - 1];
    topModel.module[index - 1] = elem;
    topModel.module[index] = previous;
  }

  moveDownModul(index, topModel) {
    if (index >= topModel.module.length - 1) {
      return;
    }
    const elem = topModel.module[index];
    const next = topModel.module[index + 1];
    topModel.module[index + 1] = elem;
    topModel.module[index] = next;
  }

  editModul(index, topModel) {
    this.diasModalDialog({
      title: "Modul bearbeiten",
      template: createEditModalTemplate,
      controller: createEditModalController,
      extras: {
        sia_id: this.sitzungsart.pk,
        tos_id: topModel.pk,
        to_id: topModel.top_obj.pk,
        ssm_id: topModel.module[index].modul,
        tagesordnungsmodul: topModel.module[index]
      }
    }).then(
      response => {
        if (isNil(response)) {
          return;
        }
        const wasOpen = this.posShowDetails[response.pk];
        topModel.module[index] = response;
        this.prepareModul(response);
        this.posShowDetails[response.pk] = wasOpen;
      }
    );
  }

  addModul(topModel) {
    this.diasModalDialog({
      title: "Modul hinzufügen",
      template: createEditModalTemplate,
      controller: createEditModalController,
      extras: {
        sia_id: this.sitzungsart.pk,
        tos_id: topModel.pk,
        to_id: topModel.top_obj.pk
      }
    }).then(
      response => {
        if (!response) {
          return;
        }
        this.prepareModul(response);
        topModel.module.push(response);
      }
    );
  }
}


export default {
  template: template(),
  controller: SitzungsartDetailsController,
  controllerAs: "vm"
};
