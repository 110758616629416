import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxPageDetails from "../../../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../../../global/components/table/table.vue";
import Spaced from "../../../../../global/components/Spaced/Spaced.vue";

import getTableApi from "../../../../../const/TableApis";

const TABLE_ID = "BerichtMinisterien";

// @vue/component
export default {
  name: "BerichtMinisterien",
  components: {
    AngularLink,
    PuxPageDetails,
    PuxTranslate,
    Spaced,
    SmartTable,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        label: this.report.tab_kbez,
        sortable: true,
        pagination: true,
        details: false,
        showHeader: false,
        view: {
          filter: "top",
        },
        template: {
          obj_url: "tableau",
          obj_id: this.report.pk
        }
      },
      liegezeitDurchschnitt: undefined,
    };
  },
};
