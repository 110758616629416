"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import errorReloadTemplate from "./errors.reload.jade";
import errorInlineTemplate from "./errors.inline.jade";
import errorFullpageTemplate from "./errors.fullpage.jade";
import errorController from "./controller";
import error404Controller from "./error.404.controller";
import errorServices from "./services";

const errorModul = angular.module("dias.errors", [
  uiRouter,
  errorServices.name
]);

errorModul

  .config($urlRouterProvider => {
    $urlRouterProvider.otherwise("/404/");
  })

  .config($stateProvider => {
    $stateProvider
      .state("root.errors", {
        url: "/",
        abstract: true,
        resolve: {
          $title: () => "Es ist ein Fehler aufgetreten",
          $message: () => ""
        }
      })
      .state("root.errors.404", {
        url: "404/",
        views: {
          "main@root": {
            template: errorInlineTemplate,
            controller: error404Controller,
            controllerAs: "vm"
          }
        },
        resolve: {
          $title: () => "Die Seite konnte nicht gefunden werden"
        }
      })
      .state("root.errors.500", {
        url: "500/:sentryId",
        views: {
          "app@": {
            template: errorFullpageTemplate,
            controller: errorController,
            controllerAs: "vm"
          }
        },
        resolve: {
          $title: () => "Der Server hat einen unerwarteten Fehler gemeldet"
        }
      })
      .state("root.errors.503", {
        url: "503/:sentryId",
        views: {
          "app@": {
            template: errorFullpageTemplate,
            controller: errorController,
            controllerAs: "vm"
          }
        },
        resolve: {
          $title: () => "Anwendung ist vorübergehend nicht erreichbar"
        }
      })
      .state("root.errors.504", {
        url: "504/:sentryId",
        views: {
          "app@": {
            template: errorFullpageTemplate,
            controller: errorController,
            controllerAs: "vm"
          }
        },
        resolve: {
          $title: () => "Es konnte keine Verbindung zum Server aufgebaut werden"
        }
      })
      .state("root.errors.js", {
        url: "500js/:sentryId",
        views: {
          "app@": {
            template: errorReloadTemplate,
            controller: errorController,
	  controllerAs: "vm"
          }
        },
        resolve: {
          $title: () => "Ein unerwarteter Fehler ist aufgetreten"
        }
      })
    ;
  })
;

export default errorModul;
