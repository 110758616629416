import {
  cloneDeep,
  get,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  emits: [
    "changeModel",
  ],
  computed: {
    optionsAnzahlTage() {
      return {
        id: "anzahl_tage",
        htmlId: this.htmlIdAnzahlTage,
        label: this.labelAnzahlTage,
        view: this.view,
        type: "text",
        required: true,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdAnzahlTage() {
      return `${ this.htmlId }_anzahl_tage`;
    },

    optionsAnzahlPersonen() {
      return {
        id: "anzahl_personen",
        htmlId: this.htmlIdAnzahlPersonen,
        label: this.labelAnzahlPersonen,
        view: this.view,
        type: "text",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdAnzahlPersonen() {
      return `${ this.htmlId }_anzahl_personen`;
    },

    errorLabels() {
      return {
        anzahl_tage: {
          label: this.labelAnzahlTage,
          link: true,
          id: this.htmlIdAnzahlTage,
        },
        anzahl_personen: {
          label: this.labelAnzahlPersonen,
          link: true,
          id: this.htmlIdAnzahlPersonen,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
    this.vorbelegungForBeleg();
  },
  methods: {
    vorbelegungForBeleg() {
      const MODEL = cloneDeep(this.model);
      const STATUS_BELEG_VORBELEGEN = get(this.extra, "modelParameter.beleg_vorbelegen", false);
      if (this.kontext === "beleg" && STATUS_BELEG_VORBELEGEN && !this.model.pk) {
        const EXTRA_ARGUMENT = this.extra.argument || {};
        forEach(MODEL.argument, (value, key) => {
          MODEL.argument[key] = MODEL.argument[key] || EXTRA_ARGUMENT[key];
        });
      }
      this.$emit("changeModel", { model: MODEL });
    },
  },
};
