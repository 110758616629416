import WizardStep4Mixin from "../WizardStep4Mixin";
import FormElementReadOnly from "../../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PersonalfunktionElementInListe from "../../WizardStep1/PersonalfunktionElementInListe/PersonalfunktionElementInListe.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import Alert from "../../../../../../global/components/Alert/Alert.vue";
import WizardStepsMessageObergrenzeMixin from "../../WizardStepsMessageObergrenzeMixin";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenPeriodenWizardStep4",
  components: {
    Alert,
    FormElementReadOnly,
    PersonalfunktionElementInListe,
    PuxTranslate,
  },
  mixins: [
    WizardStep4Mixin,
    WizardStepsMessageObergrenzeMixin,
  ],
  props: {
    personalfunktionen: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stellenbezeichnungOptions: {
        id: "stellenbezeichnung",
        type: "text",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_STELLENBEZEICHNUNG_",
        showLabel: true,
      },
      nameOptions: {
        id: "name",
        type: "text",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_NAME_",
        showLabel: true,
      },
      vollzeitstelleOptions: {
        id: "vollzeitstelle",
        type: "float",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_VOLLZEITSTELLE_",
        addonBack: "_LBL_SUFFIX_STUNDEN_PRO_WOCHE_",
        showLabel: true,
      },
      stellenumfangOptions: {
        id: "stellenumfang",
        type: "float",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_STELLENUMFANG_",
        addonBack: "_LBL_SUFFIX_STUNDEN_PRO_WOCHE_",
        showLabel: true,
      },
      stellenumfangProjektOptions: {
        id: "stellenumfang_projekt",
        type: "float",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_STELLENUMFANG_PROJEKT_",
        addonBack: "_LBL_SUFFIX_STUNDEN_PRO_WOCHE_",
        showLabel: true,
      },
      zeitraumVonOptions: {
        id: "zeitraum_von",
        type: "date",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_ZEITRAUM_VON_",
        showLabel: true,
      },
      zeitraumBisOptions: {
        id: "zeitraum_bis",
        type: "date",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_ZEITRAUM_BIS_",
        showLabel: true,
      },
      lohnsteigerungOptions: {
        id: "lohnsteigerung",
        type: "float",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_LOHNSTEIGERUNG_",
        suffix: "%",
        showLabel: true,
      },
      startdatumOptions: {
        id: "startdatum",
        type: "date",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_STARTDATUM_",
        showLabel: true,
      },
    };
  },
  computed: {
    personalfunktionOptions() {
      return {
        id: "personalfunktion",
        type: "select",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_FUNKTION_IM_PROJEKT_",
        data: this.personalfunktionen,
        keyId: "pk",
        keyLabel: "bez",
        slot: "personalfunktion",
        showLabel: true,
      };
    },

    anJahresbruttoOptions() {
      return {
        id: "an_jahresbrutto",
        type: "currency",
        showLabel: true,
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_AN_JAHRESBRUTTO_{{stellenanteil}}_",
        extra: {
          stellenanteil: this.model.stellenanteil,
        },
      };
    },

    formElementOptionsLocal() {
      const OPTIONS = cloneDeep(this.formElementOptions);
      OPTIONS.showLabel = true;
      return OPTIONS;
    },
  },
};
