import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "SitzungModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    selectorClose: {
      type: String,
      required: true,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      model: {
        si_sitzungsdatum: undefined,
        si_buchungsdatum: undefined,
        si_redaktionsschluss: undefined,
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "sitzungsart",
            type: "select",
            label: "_LBL_SITZUNG_MODAL_CREATE_SITZUNGSART_",
            required: true,
            keyLabel: "sia_kbez",
            keyId: "pk",
            view: "v",
            menuWidthAll: true,
            deselect: false,
          },
          {
            id: "si_sitzungsdatum",
            type: "date",
            label: "_LBL_SITZUNG_MODAL_CREATE_SITZUNGSDATUM_",
            required: true,
            view: "v",
          },
          {
            id: "si_buchungsdatum",
            type: "date",
            label: "_LBL_SITZUNG_MODAL_CREATE_BUCHUNGSDATUM_",
            required: true,
            view: "v",
          },
          {
            id: "si_redaktionsschluss",
            type: "date",
            label: "_LBL_SITZUNG_MODAL_CREATE_REDAKTIONSSCHLUSS_",
            required: true,
            view: "v",
          },
          {
            id: "si_durchfuehrungsort",
            type: "text",
            label: "_LBL_SITZUNG_MODAL_CREATE_DURCHFUEHRUNGSORT_",
            view: "v",
          },
          {
            id: "si_strasse",
            type: "text",
            label: "_LBL_SITZUNG_MODAL_CREATE_STRASSE_",
            view: "v",
          },
          {
            id: "si_hsnr",
            type: "text",
            label: "_LBL_SITZUNG_MODAL_CREATE_HAUSNUMMER_",
            view: "v",
          },
          {
            id: "si_plz",
            type: "text",
            label: "_LBL_SITZUNG_MODAL_CREATE_POSTLEITZAHL_",
            view: "v",
          },
          {
            id: "si_ort",
            type: "text",
            label: "_LBL_SITZUNG_MODAL_CREATE_ORT_",
            view: "v",
          },
        ],
      },
      status: {
        loading: undefined,
      },
      data: {
        sitzungsart: [],
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  created() {
    this.loadSitzungsarten();
  },
  methods: {
    loadSitzungsarten() {
      this.getListHttp({ url: "sitzungsarten/", apiSaveId: "sitzungsarten" }).then(
        response => this.data.sitzungsart = response
      );
    },

    onChange({ id, model }) {
      if (id === "sitzungsart") {
        this.changeSitzungsart({ model });
      }
    },

    changeSitzungsart({ model }) {
      for (let i = 0; i < this.data.sitzungsart.length; i++) {
        const currentSitzungsart = this.data.sitzungsart[i];
        if (currentSitzungsart.pk === model) {
          this.model.si_sitzungsdatum = currentSitzungsart.naechstes_datum;
          this.model.si_buchungsdatum = currentSitzungsart.naechstes_datum;
          this.model.si_redaktionsschluss = currentSitzungsart.naechstes_datum;
          break;
        }
      }
    },

    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.postHttp({ url: `sitzungen/`, data }).then(
        response => {
          this.addNotification({ text: "_MSG_SITZUNG_MODAL_CREATE_SUCCESS_" });
          this.close();
          this.$goTo("root.sitzungen.details", { id: response.pk });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
