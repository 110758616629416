import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  cloneDeep,
  filter,
  sortBy,
  values,
} from "lodash-es";

// @vue/component
export default {
  name: "FaqDetailsFilterBox",
  components: {
    AsideRightBox,
    FormElement,
    PuxButton,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    keywords: {
      type: Array,
      required: true,
    },
    keywordsMapByPk: {
      type: Object,
      required: true,
    },
    modelKeywordsGroups: {
      type: Object,
      required: true,
    },
    mediaTypes: {
      type: Array,
      required: true,
    },
    mediaTypesMapByPk: {
      type: Object,
      required: true,
    },
    modelMediaTypes: {
      type: Array,
      required: true,
    },
    modelRubric: {
      type: Array,
      required: true,
    },
    modelSearch: {
      type: String,
      required: false,
      default: undefined,
    },
    faqRubricsAll: {
      type: Array,
      required: true,
    },
    rubricsMapByPk: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "changeFilter",
    "onFuzzySearch",
  ],
  data() {
    return {
      optionsAsideBox: {
        name: "_TXT_FAQ_DETAILS_ASIDE_FILTER_HEADER_",
        active: true,
        icon: "glyphicon-filter",
      },
      optionsForFuzzySearch: {
        id: "unscharfe_suche",
        type: "text",
        view: "v",
        label: "_LBL_FAQ_DETAILS_ASIDE_FUZZY_SEARCH_",
        change: this.changeSearchModel,
      },
      optionsForKeywords: {
        id: "faq_keywords",
        type: "select",
        view: "v",
        label: "_LBL_FAQ_DETAILS_ASIDE_KEYWORDS_",
        change: this.changeKeywordsModel,
        keyId: "pk",
        keyLabel: "sw_kbez",
        search: true,
        searchList: ["sw_kbez"],
        keyGroup: "gruppen_bez",
      },
      optionsForMediaTypes: {
        id: "faq_media_types",
        type: "select",
        view: "v",
        label: "_LBL_FAQ_DETAILS_ASIDE_MEDIA_TYPES_",
        change: this.changeMediaType,
        keyId: "pk",
        keyLabel: "kmd_kbez",
        search: true,
        searchList: ["kmd_kbez"],
      },
      optionsForRubrics: {
        id: "faq_rubrics",
        type: "select",
        view: "v",
        label: "_LBL_FAQ_DETAILS_ASIDE_RUBRICS_",
        change: this.changeRubric,
        keyId: "pk",
        keyLabel: "fqr_kbez",
        search: true,
        searchList: ["fqr_kbez"],
      },
      loading: false,
    };
  },
  computed: {
    modelKeywordsGroupsSorted() {
      const MODEL_KEYWORDS_GROUPS_SORTED = [];
      const MODEL_KEYWORDS_GROUPS = cloneDeep(this.modelKeywordsGroups);
      if (MODEL_KEYWORDS_GROUPS.null) {
        MODEL_KEYWORDS_GROUPS_SORTED.push(MODEL_KEYWORDS_GROUPS.null);
        delete MODEL_KEYWORDS_GROUPS.null;
      }
      const MODEL_KEYWORDS_GROUPS_LIST_SORTED = sortBy(values(MODEL_KEYWORDS_GROUPS), ["name"]);
      MODEL_KEYWORDS_GROUPS_SORTED.push(...MODEL_KEYWORDS_GROUPS_LIST_SORTED);
      return MODEL_KEYWORDS_GROUPS_SORTED;
    },

    mediaTypesFiltered() {
      return filter(this.mediaTypes, mediaType => this.modelMediaTypes.indexOf(mediaType.pk) === -1);
    },

    rubricsFiltered() {
      return filter(this.faqRubricsAll, rubric => this.modelRubric.indexOf(rubric.pk) === -1);
    },
  },
  methods: {
    resetModel() {
      this.$emit("changeFilter", {
        filterKey: "all"
      });
    },

    changeKeywordsModel({ currentModel }) {
      this.$emit("changeFilter", {
        filterKey: "keywords",
        model: currentModel,
        action: "add",
      });
    },

    removeKeyword(keyword) {
      this.$emit("changeFilter", {
        filterKey: "keywords",
        model: keyword,
        action: "delete",
      });
    },

    changeMediaType({ currentModel }) {
      this.$emit("changeFilter", {
        filterKey: "mediaTypes",
        model: currentModel,
        action: "add",
      });
    },

    removeMediaType(mediaType) {
      this.$emit("changeFilter", {
        filterKey: "mediaTypes",
        model: mediaType,
        action: "delete",
      });
    },

    changeRubric({ currentModel }) {
      this.$emit("changeFilter", {
        filterKey: "rubric",
        model: currentModel,
        action: "add",
      });
    },

    removeRubric(rubric) {
      this.$emit("changeFilter", {
        filterKey: "rubric",
        model: rubric,
        action: "delete",
      });
    },

    changeSearchModel({ currentModel }) {
      this.$emit("changeFilter", {
        filterKey: "search",
        model: currentModel,
      });
    },

    onFuzzySearch() {
      this.$emit("onFuzzySearch");
    },
  },
};
