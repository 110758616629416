var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group"] = jade_interp = function(errors, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' : vm.errors['" + (errors) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (errors) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (errors) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<fieldset dias-cloak=\"vm.loadingEditData\"><div ng-attr-id=\"{{ vm.errorContainerId }}\"></div><div class=\"row\"><div class=\"col-12 col-sm-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"ask_titel\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.ask_titel ? 'true' : 'false'}}\" name=\"ask_titel\" ng-model=\"vm.model.ask_titel\" type=\"text\" class=\"form-control\">");
}
}, "ask_titel", "Titel*");
buf.push("</div></div><div class=\"row\"><div class=\"col-12 col-sm-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<textarea id=\"ask_kurzbeschreibung\" dias-richtext ng-attr-aria-invalid=\"{{vm.errors.ask_kurzbeschreibung ? 'true' : 'false'}}\" name=\"ask_kurzbeschreibung\" ng-model=\"vm.model.ask_kurzbeschreibung\" type=\"text\" class=\"form-control\"></textarea>");
}
}, "ask_kurzbeschreibung", "Kurzbeschreibung");
buf.push("</div></div><div class=\"row\"><div class=\"col-12 col-sm-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<textarea id=\"ask_beschreibung\" dias-richtext ng-attr-aria-invalid=\"{{vm.errors.ask_beschreibung ? 'true' : 'false'}}\" name=\"ask_beschreibung\" ng-model=\"vm.model.ask_beschreibung\" type=\"text\" class=\"form-control\"></textarea>");
}
}, "ask_beschreibung", "Beschreibung*");
buf.push("</div></div></fieldset><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save, vm.errorsLabel)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button dias-confirm confirm-title=\"{{vm.gettext('Aspekt')}} löschen?\" confirm-type=\"danger\" confirm-msg=\"Wollen Sie den {{vm.gettext('Aspekt')}} {{vm.model.ask_titel}} wirklich löschen?\" confirm-ok=\"setEditMode(false, vm.remove)\" confirm-ok-label=\"Löschen\" confirm-ok-icon=\"glyphicon-trash\" confirm-switch-btn-pos=\"true\" ng-attr-title=\"{{ vm.gettext('Aspekt') }} löschen\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Löschen</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Abbrechen</span></button></div></div>");;return buf.join("");
}