import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";
import {
  cloneDeep,
  filter,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "UiElementverzweigungElement",
  components: {
    PuxButton,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    model: {
      type: Array,
      required: true,
    },
    regelnByPK: {
      type: Object,
      required: true,
    },
    dataForSelectDefault: {
      type: Array,
      required: true,
    },
    onInput: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modelLocal: null,
      confirmShow: undefined,
      confirmOptions: undefined,
    };
  },
  computed: {
    idPrefix() {
      return `${ this.index }_`;
    },

    getDataForSelect() {
      return filter(this.dataForSelectDefault, item => {
        return this.model[this.index].children.indexOf(item.pk) === -1 && item.pk !== get(this, "$stateParams.id");
      });
    },

    getOptions() {
      return {
        type: "select",
        id: "element",
        label: "Element auswählen",
        required: false,
        view: "v",
        keyLabel: "bez",
        keyId: "id",
        search: true,
        searchList: ["bez"],
        deselect: false,
        data: this.getDataForSelect,
      };
    },
  },
  beforeCreate() {
    this.$options.components.FormElement = require("../../../../../../global/components/FormElement/FormElement.vue").default;
    this.$options.components.Modal = require("../../../../../../global/components/Modal/Modal.vue").default;
  },
  methods: {
    setDefaultModelLocal() {
      this.modelLocal = null;
    },

    moveUpFeld(index) {
      if (index === 0) {
        return;
      }
      const MODEL = cloneDeep(this.model);

      const ELEM = MODEL[index];
      MODEL.splice(index, 1);
      MODEL.splice(index - 1, 0, ELEM);
      this.onInput({ value: MODEL });
    },

    moveDownFeld(index) {
      if (index >= this.model.length - 1) {
        return;
      }
      const MODEL = cloneDeep(this.model);
      const ELEM = MODEL[index];
      MODEL.splice(index, 1);
      MODEL.splice(index + 1, 0, ELEM);
      this.onInput({ value: MODEL });
    },

    openConfirmDelete(index) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "Antwortmöglichkeit entfernen",
        msg: `<p>Sind Sie sicher, dass sie die Antwortmöglichkeit von dieser Regel entfernen wollen?</p>`,
        okLabel: "Fortfahren",
        okCallback: () => this.deleteFeld(index),
        cancelCallback: this.closeConfirmDelete,
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteFeld(index) {
      const MODEL = cloneDeep(this.model);
      MODEL.splice(index, 1);
      this.onInput({ value: MODEL });
      this.closeConfirmDelete();
    },

    closeConfirmDelete() {
      this.confirmShow = false;
    },

    moveUpFeldRegel(index) {
      if (index === 0) {
        return;
      }
      const MODEL = cloneDeep(this.model);

      const ELEM = MODEL[this.index].children[index];
      MODEL[this.index].children.splice(index, 1);
      MODEL[this.index].children.splice(index - 1, 0, ELEM);
      this.onInput({ value: MODEL });
    },

    moveDownFeldRegel(index) {
      if (index >= this.model[this.index].children.length - 1) {
        return;
      }
      const MODEL = cloneDeep(this.model);
      const ELEM = MODEL[this.index].children[index];
      MODEL[this.index].children.splice(index, 1);
      MODEL[this.index].children.splice(index + 1, 0, ELEM);
      this.onInput({ value: MODEL });
    },

    openConfirmDeleteRegel(index) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "Antwortmöglichkeit entfernen",
        msg: `<p>Sind Sie sicher, dass sie die Antwortmöglichkeit von dieser Regel entfernen wollen?</p>`,
        okLabel: "Fortfahren",
        okCallback: () => this.deleteFeldRegel(index),
        cancelCallback: this.closeConfirmDelete,
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteFeldRegel(index) {
      const MODEL = cloneDeep(this.model);
      MODEL[this.index].children.splice(index, 1);
      this.onInput({ value: MODEL });
      this.closeConfirmDelete();
    },

    addRegel() {
      const MODEL = cloneDeep(this.model);
      MODEL[this.index].children.push(this.modelLocal);
      this.onInput({ value: MODEL });
      this.setDefaultModelLocal();
    },
  },
};
