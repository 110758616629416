import AngularLink from "../../../../../../global/components/AngularLink/AngularLink.vue";
import PuxIcon from "../../../../../../global/components/PuxIcon/PuxIcon.vue";
import RollenModalAdd from "../RollenModalAdd/RollenModalAdd.vue";
import Spaced from "../../../../../../global/components/Spaced/Spaced.vue";

import PermissionMixin from "../../../../../../global/mixins/PermissionMixin";
import RolenListInNutzerMixin from "../RolenListInNutzer.mixin";

import getTableApi from "../../../../../../const/TableApis";
import { gettext } from "../../../../../../global/functions/utils";

import moment from "moment";

const TABLE_ID = "RolenAntragstellerListInNutzer";

// @vue/component
export default {
  name: "RollenAntragstellerList",
  components: {
    AngularLink,
    PuxIcon,
    RollenModalAdd,
    Spaced,
  },
  mixins: [
    PermissionMixin,
    RolenListInNutzerMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: "",
        cols: [
          {
            label: "_LBL_ROLLEN_LIST_STATUS_",
            id: "aktiv",
            slot: "aktiv",
          },
          {
            label: "_LBL_ROLLEN_LIST_ORGANISATION_",
            id: "org_name",
            slot: "org_name",
          },
          {
            label: "_LBL_ROLLEN_LIST_ZUGEHOERIGKEIT_",
            id: "zugehoerig_name",
            slot: "zugehoerig_name",
          },
          {
            label: "_LBL_ROLLEN_LIST_ROLLE_",
            id: "rolle_name",
            slot: "rolle_name",
          },
          {
            label: "_LBL_ROLLEN_LIST_GUELTIG_AB_",
            id: "anr_gueltig_ab",
            slot: "anr_gueltig_ab",
          },
          {
            label: "_LBL_ROLLEN_LIST_GUELTIG_BIS_",
            id: "anr_gueltig_bis",
            slot: "anr_gueltig_bis",
          },
        ],
        rowActions: [
          {
            label: "_LBL_ROLLEN_LIST_ACTION_ROW_DETAILS_",
            route: "root.rollenverwaltung.detail",
            routeParameters: [{ routeId: "id", rowPath: "rolle" }],
            isHidden: () => !this.canViewRoles
          },
          // {
          //   label: "Rollenzuordnung entfernen",
          //   callback: this.confirmRemoveNutzerRole_mixin,
          //   isHidden: ({ item }) => item.aktiv || moment().isAfter(item.fnr_gueltig_ab),
          // },
          {
            label: "_LBL_ROLLEN_LIST_ROLLENZUORDNUNG_DEAKTIVIEREN_",
            callback: this.confirmDeactivateNutzerRole_mixin,
            isHidden: ({ item }) => !item.aktiv || !this.canUpdate({ item }),
          },
          {
            label: "_LBL_ROLLEN_LIST_GUELTIGKEITSZEITRAUM_ANPASSEN_",
            callback: this.openModalGueltigkeitszeitraumAnpassen_mixin,
            isHidden: ({ item }) => this.isPast({ item }) || !this.canUpdate({ item }),
          },
        ],
        actions: [
          {
            permission: "nutzer.antragstellerorgrollen.create",
            label: "_LBL_ROLLEN_LIST_ROLLE_HINZUFUEGEN_",
            callback: this.addRolle_mixin,
            type: "button",
            className: "btn btn-default",
            icon: "glyphicon-plus",
            htmlId: "nutzer_antragstellerorgrollen_create",
          },
        ],
        label: "_LBL_ROLLEN_LIST_ANTRAGSTELLER_",
        sortable: false,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
      },
      prefix: "anr",
      apiSufix: "antragstellerorgrollen",
      modalAddOptionsList: [
        {
          id: "antragstellerorg",
          type: "select",
          required: true,
          label: "_LBL_ROLLEN_LIST_ANTRAGSTELLER_",
          url: "antragstellerorganisationen/?fields=ast_name&fields=pk",
          keyId: "pk",
          keyLabel: "ast_name",
          view: "v",
          searchParameter: "prefer_nr_exact",
          searchGlobal: true,
        },
        {
          id: "rolle",
          type: "select",
          required: true,
          url: "astorgrolle/?fields=aro_id&fields=aro_kbez&fields=aro_bez",
          keyId: "aro_id",
          keyLabelCallback: ({ item }) => `${ item.aro_kbez } - ${ item.aro_bez }`,
          search: true,
          searchList: ["aro_kbez", "aro_bez"],
          label: "_LBL_ROLLEN_LIST_ROLLE_",
          view: "v",
        },
        {
          id: "gueltigkeit",
          type: "daterange",
          label: gettext("_LBL_ROLLEN_LIST_GUELTIGKEITSZEITRAUM_"),
          view: "v-alt",
          placeholderMin: gettext("_LBL_ROLLEN_LIST_AB_HEUTE_"),
          placeholderMax: gettext("_LBL_ROLLEN_LIST_UNEINGESCHRAENKT_"),
        },
      ],
    };
  },
  computed: {
    canViewRoles() {
      return this.checkPermissionsSync({ perm: "rollen.view" });
    },

  },
  created() {
    this.initUrl();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { nutzerPk: this.nutzer.pk } });
    },

    canUpdate({ item }) {
      return this.checkPermissionsSync({
        perm: "nutzer.antragstellerorgrollen.update",
        permArray: item.user_permissions
      });
    },

    isPast({ item }) {
      return item.anr_gueltig_bis && moment().isAfter(item.anr_gueltig_bis);
    },
  },
};
