"use strict";

import detailTemplate from "./foerderorg.detail.jade";
import orgDataEditTemplate from "./foerderorg.edit.jade";
import orgDescEditTemplate from "./foerderorg.descedit.jade";
import orgSichtbarEditTemplate from "./foerderorg.sichtbar.edit.jade";
import orgZuordnungEditTemplate from "./foerderorg.zuordnung.edit.jade";

import createAnsprechpartnerTemplate from "./modal.ansprechpartner.create.jade";
import createAnsprechpartnerController from "./modal.ansprechpartner.create.controller";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";


class OrgDataEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification) {
    super($q, $scope, DiasNotification);
    this.errorsLabel = {
      o_name: "Name: ",
      o_kbez: "Kurzbezeichnung: ",
      o_strasse: "Straße: ",
      o_hsnr: "Hausnummer: ",
      o_plz: "PLZ: ",
      o_ort: "Ort: "
    };

    this.save = () => {
      return this.model.patch({
        o_name: this.model.o_name,
        o_kbez: this.model.o_kbez,
        o_strasse: this.model.o_strasse,
        o_hsnr: this.model.o_hsnr,
        o_plz: this.model.o_plz,
        o_ort: this.model.o_ort,
      }).then(
        response => {
          $scope.model.merge($scope.model, response);
          this.DiasNotification.page.success("Änderungen erfolgreich gespeichert.");
        },
        errors => {
          return this.onErrors(errors, this.errorsLabel);
        }
      );
    };
  }
}


class OrgSichtbarEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification) {
    super($q, $scope, DiasNotification);
    this.errorsLabel = {
      o_oeffentlich: "Öffentlich: ",
      o_antragsannahme: "Antragannehmend: ",
    };

    this.save = () => {
      return this.model.patch({
        o_oeffentlich: this.model.o_oeffentlich,
        o_antragsannahme: this.model.o_antragsannahme,
      }).then(
        response => {
          $scope.model.merge($scope.model, response);
          this.DiasNotification.page.success("Änderungen erfolgreich gespeichert.");
        },
        errors => {
          return this.onErrors(errors, this.errorsLabel);
        }
      );
    };
  }
}


class OrgZuordnungEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, Katalog, i18n) {
    super($q, $scope, DiasNotification);

    this.gettext = i18n.gettext;
    this.loadingOrgtyp = true;
    Katalog.getKatalog("foerderorgtyp").then(
      response => {
        this.typChoices = response;
      },
      err => this.DiasNotification.page.error(err, "Fehler beim Laden der auswählbaren Orgasisationstypen")
    ).finally(() => this.loadingOrgtyp = false);

    // FIXME: Bearbeiten übergeordneter Organisationen, zu klären: Wer darf
    // welche Orginasitionen zuordnen?
    this.loadingOrgs = false;
    this.canEditUebergeordnete = false;
    // this.loadingOrgs = true;
    // Foerderorganisation.getList({ serializer: "minimal", eigene: "True" }).then(
    //   response => {
    //     // Übergeordnete Organisation nur bearbeitbar, wenn der Nutzer
    //     // die übergeordnete Organisation sehen darf, oder wenn
    //     // übergeordnete Organisation leer ist
    //     this.canEditUebergeordnete = this.model.uebergeordnete_org === null;
    //     angular.forEach(response, org => {
    //       if (org.pk === this.model.uebergeordnete_org) {
    //         this.canEditUebergeordnete = true;
    //       }
    //     });
    //     this.orgChoicesAll = response;
    //     this.orgChoices = response;
    //   },
    //   err => this.DiasNotification.page.error(err, "Fehler beim Laden der auswählbaren Förderorganisationen")
    // ).finally(() => this.loadingOrgs = false);

    this.errorsLabel = {
      typ_pk: "Organisationstyp: ",
      uebergeordnete_org_pk: "Übergeordnete Organisation: ",
    };

    this.save = () => {
      const data = { typ_pk: this.model.typ_pk };
      if (this.canEditUebergeordnete) {
        data.uebergeordnete_org_pk = this.model.uebergeordnete_org_pk;
      }
      return this.model.patch(data).then(
        response => {
          $scope.model.merge($scope.model, response);
          this.DiasNotification.page.success("Änderungen erfolgreich gespeichert.");
        },
        errors => {
          return this.onErrors(errors, this.errorsLabel);
        }
      );
    };
  }
}


class OrgDescEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification) {
    super($q, $scope, DiasNotification);
    this.errorsLabel = {
      o_beschreibung: "Beschreibung der Organisation: ",
      o_konzept_titel: "Titel des Konzepts: ",
      o_konzept_kbez: "Kurze Beschreibung des Konzepts: ",
      o_konzept_bez: "Ausführliche Beschreibung des Konzepts: "
    };
    this.save = () => {
      return this.model.patch({
        o_beschreibung: this.model.o_beschreibung,
        o_konzept_titel: this.model.o_konzept_titel,
        o_konzept_kbez: this.model.o_konzept_kbez,
        o_konzept_bez: this.model.o_konzept_bez
      }).then(
        response => {
          $scope.model.merge($scope.model, response);
          this.DiasNotification.page.success("Änderungen erfolgreich gespeichert.");
        },
        errors => {
          return this.onErrors(errors, this.errorsLabel);
        }
      );
    };
  }
}


class FoerderorgdetailsController {
  /*@ngInject*/
  constructor($q, $stateParams, AuthService, diasConfirmDialog, diasModalDialog, DiasNotification, Foerderorganisation, i18n, Client) {
    this.gettext = i18n.gettext;
    this.Client = Client;
    this.$stateParams = $stateParams;
    this.AuthService = AuthService;
    this.diasConfirmDialog = diasConfirmDialog;
    this.diasModalDialog = diasModalDialog;
    this.DiasNotification = DiasNotification;
    this.Foerderorganisation = Foerderorganisation;
    this.loading = {
      org: true
    };
    this.orgDataEditController = OrgDataEditController;
    this.orgDataEditTemplate = orgDataEditTemplate;
    this.orgSichtbarEditController = OrgSichtbarEditController;
    this.orgSichtbarEditTemplate = orgSichtbarEditTemplate;
    this.orgZuordnungEditController = OrgZuordnungEditController;
    this.orgZuordnungEditTemplate = orgZuordnungEditTemplate;
    this.orgDescEditController = OrgDescEditController;
    this.orgDescEditTemplate = orgDescEditTemplate;

    this.orgEditable = this.AuthService.syncHasPerm("foerderorg.update");
    this.orgBasicEditable = this.orgEditable || this.AuthService.syncHasPerm("foerderorg.basicdata_update");

    this.ansprechpartnerTableDeferred = $q.defer();
    this.ansprechpartnerTable = this.ansprechpartnerTableDeferred.promise;

    this.nutzerTableDeferred = $q.defer();
    this.nutzerTable = this.nutzerTableDeferred.promise;

    this.unterorgTableDeferred = $q.defer();
    this.unterorgTable = this.unterorgTableDeferred.promise;

    this.init();
  }

  init() {
    this.Foerderorganisation.one(this.$stateParams.id).get().then(
      response => {
        this.org = response;
        this.loading.org = false;
        this.resolveAnsprechpartnerTable();
        this.resolveNutzerTable();
        this.resolveUnterorgTable();
      }
    );
  }

  resolveAnsprechpartnerTable() {
    const getDetailRoute = row => `root.foerderorganisationen.details.ansprechpartner({id: '${ this.org.pk }', ap_id: '${ row.pk }'})`;
    this.ansprechpartnerTableDeferred.resolve({
      dataSource: this.org.ansprechpartner.getList,
      columnDefs: [
        {
          label: "Name",
          field: "fas_name",
          cellTemplate: `<a ui-sref="root.foerderorganisationen.details.ansprechpartner({id: '` + this.org.pk + `', ap_id:'{{row.pk}}'})">
                           {{row.fas_name}}
                         </a>`
        },
        { label: "Funktion", field: "fas_funktion" },
        // {label: "Reihenfolge", field: "fas_pos"},
      ],
      actions: [
        { label: "Ansprechpartner löschen",
          isVisible: () => this.AuthService.syncHasPerm("foerderorg.basicdata_update"),
          callback: row => this.deleteAnsprechpartner(row) },
      ],
      tableActions: [
        { label: "Neuen Ansprechpartner erstellen",
          icon: "glyphicon glyphicon-plus", primary: true,
          callback: () => this.createAnsprechpartner(),
          isVisible: () => this.AuthService.syncHasPerm("foerderorg.basicdata_update")
        }
      ],
      getDetailRoute: getDetailRoute,
      isDirty: scope => this.lastChange > scope.vm.lastRefresh,
    });
  }

  createAnsprechpartner() {
    this.diasModalDialog({
      title: "Ansprechpartner erstellen",
      template: createAnsprechpartnerTemplate,
      controller: createAnsprechpartnerController,
      extras: this.org
    }).then(() => {
      this.lastChange = new Date().getTime();
    });
  }

  deleteAnsprechpartner(row) {
    this.diasConfirmDialog({
      title: "Ansprechpartner löschen",
      content: "Sind Sie sicher, dass Sie den Ansprechpartner <strong>" + row.fas_name + "</strong> löschen wollen?",
      okLabel: "Löschen",
      okCallback: () => row.remove().then(
        () => {
          this.DiasNotification.page.success("Ansprechpartner gelöscht");
        },
        () => this.DiasNotification.page.error("Ansprechpartner konnte nicht gelöscht werden")
      )
    }).then(() => {
      this.lastChange = new Date().getTime();
    });
  }

  resolveNutzerTable() {
    if (!this.AuthService.syncHasPerm("foerderorgnutzer.view")) {
      this.nutzerTableDeferred.reject();
      return;
    }
    this.nutzerTableDeferred.resolve({
      dataSource: this.org.nutzer.getList,
      columnDefs: [
        { label: "Name", field: "name" },
        { label: "E-Mail",
          field: "email",
          cellTemplate: `<div class="text-left">{{ row.email }}</div>`,
        },
      ],
    });
  }

  resolveUnterorgTable() {
    if (!this.AuthService.syncHasPerm("foerderorgnutzer.view")) {
      this.unterorgTableDeferred.reject();
      return;
    }
    this.unterorgTableDeferred.resolve({
      dataSource: this.org.untergeordnete_orgs.getList,
      columnDefs: [
        { label: "Name", field: "o_name", linkToDetail: true },
        { label: "Organisationstyp",
          field: "typ.ot_kbez",
          cellTemplate: `<div class="text-left">{{ row.typ.ot_kbez }}</div>`,
        },
      ],
      getDetailRoute: row => {
        return "root.foerderorganisationen.details({id: '" + row.pk + "'})";
      },
    });
  }
}


export default {
  template: detailTemplate(),
  controller: FoerderorgdetailsController,
  controllerAs: "vm"
};
