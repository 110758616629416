import Modal from "../../../../../global/components/Modal/Modal.vue";
import moment from "moment";
import {
  findIndex,
  size,
  cloneDeep,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  components: {
    Modal,
  },
  props: {
    period: {
      type: Object,
      required: true,
    },
    periodGroup: {
      type: Array,
      required: true,
    },
    infoForBruttoGehalt: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      vonIndex: undefined,
      bisIndex: undefined,
      bisNotAfterDate: undefined,
      vonNotBeforeDate: undefined,
      errors: undefined,
      errorsClone: undefined,
      errorTextRequired: "_MSG_ERROR_REQUIRED_",
    };
  },
  methods: {
    setDateRange() {
      this.vonIndex = findIndex(this.options.list, ["id", "von"]);
      this.bisIndex = findIndex(this.options.list, ["id", "bis"]);
      const CURRENT_PERIOD_INDEX = findIndex(this.periodGroup, ["von", this.period.von]);
      const PREVIOUS_PERIOD = this.periodGroup[CURRENT_PERIOD_INDEX - 1];
      const NEXT_PERIOD = this.periodGroup[CURRENT_PERIOD_INDEX + 1];

      if (PREVIOUS_PERIOD) {
        this.vonNotBeforeDate = moment(PREVIOUS_PERIOD.bis).add(1, "days").format("YYYY-MM-DD");
      } else {
        this.vonNotBeforeDate = this.period.info.von_aktuell;
      }

      if (NEXT_PERIOD) {
        this.bisNotAfterDate = moment(NEXT_PERIOD.von).subtract(1, "days").format("YYYY-MM-DD");
      } else {
        this.bisNotAfterDate = this.period.info.bis_aktuell;
      }

      this.setOptionsForDate({
        date: this.period.bis,
        optionName: "notAfter",
        index: this.vonIndex,
      });
      this.setOptionsForDate({
        date: this.vonNotBeforeDate,
        optionName: "notBefore",
        index: this.vonIndex,
      });
      this.setOptionsForDate({
        date: this.vonNotBeforeDate,
        optionName: "defaultValue",
        index: this.vonIndex,
      });

      this.setOptionsForDate({
        date: this.bisNotAfterDate,
        optionName: "notAfter",
        index: this.bisIndex,
      });
      this.setOptionsForDate({
        date: this.period.von,
        optionName: "notBefore",
        index: this.bisIndex,
      });
      this.setOptionsForDate({
        date: this.period.von,
        optionName: "defaultValue",
        index: this.bisIndex,
      });
    },

    setOptionsForDate({ date, optionName, index }) {
      this.options.list[index][optionName] = date;
    },

    changeDate({ id }) {
      this[`changeDate_${ id }`]();
    },

    changeDate_bis() {
      let dateNotAfter = this.model.bis;
      if (!this.model.bis) {
        dateNotAfter = this.bisNotAfterDate;
      }
      this.setOptionsForDate({
        date: dateNotAfter,
        optionName: "notAfter",
        index: this.vonIndex,
      });
    },

    changeDate_von() {
      let dateNotBefore = this.model.von;
      if (!this.model.von) {
        dateNotBefore = this.vonNotBeforeDate;
      }

      this.setOptionsForDate({
        date: dateNotBefore,
        optionName: "notBefore",
        index: this.bisIndex,
      });
    },

    save() {
      if (!this.validate()) {
        return;
      }
      this.close({ model: this.model });
    },

    validate() {
      let error = {};
      if (!this.model.von) {
        error.von = [this.errorTextRequired];
      }
      if (!this.model.bis) {
        error.bis = [this.errorTextRequired];
      }
      if (isFunction(this.validateLocal)) {
        error = this.validateLocal({ error });
      }
      this.errors = cloneDeep(error);
      this.errorsClone = cloneDeep(error);
      return !size(error);
    },
  },
};
