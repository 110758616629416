import FinanzierugMixin from "../Mixins/FinanzierugMixin";
import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import ZuschussMixin from "../Mixins/ZuschussMixin";
import {
  get, 
} from "lodash-es";

// @vue/component
export default {
  name: "ZuschussMitEingabe",
  components: {
    KFStandard,
  },
  mixins: [
    FinanzierugMixin,
    ZuschussMixin,
  ],
  data() {
    return {
      allowsInput: true,
    };
  },
  computed: {
    sumLocal() {
      return this.antragBetragCurrency;
    },

    statusReadonly() {
      if (this.kontext === "zuschuss" ||
        this.kontext === "zuschuss_vertrag") {
        return true;
      }
      return !this.hasModulPermissionUpdate;
    },

    meldungen() {
      return get(this.infoprovider, `details.${ this.regel.afr_id }.meldungen`, []);
    },

    labelsPosition() {
      return {
        buttonAddNoPositionText: "_BTN_ZUSCHUSS_MIT_EINGABE_ADD_POSITION_",
        labelAdd: "_TXT_ZUSCHUSS_MIT_EINGABE_POSITION_ADD_HEADER_{{kofiart}}_",
        labelEdit: "_TXT_ZUSCHUSS_MIT_EINGABE_POSITION_EDIT_HEADER_",
        buttonEditTooltip: "_BTN_ZUSCHUSS_MIT_EINGABE_KF_POSITION_EDIT_TITLE_",
        buttonDeleteTooltip: "_BTN_ZUSCHUSS_MIT_EINGABE_KF_POSITION_DELETE_TITLE_",
        confirmDeleteHeader: "_TXT_ZUSCHUSS_MIT_EINGABE_KF_DELETE_CONFIRM_HEADER_{{position}}_{{kofiart}}_",
      };
    },
  },
};
