"use strict";

import template from "./detail.jade";
import editPermissionTemplate from "./berechtigungen.edit.jade";
import editPermissionController from "./berechtigungen.edit.controller";
import editDescriptionTemplate from "./beschreibung.edit.jade";
import editDescriptionController from "./beschreibung.edit.controller";
import editStatusgruppenTemplate from "./statusgruppen.edit.jade";
import editStatusgruppenController from "./statusgruppen.edit.controller";
import KaufgabeKopierenController from "./kafugabekopieren.modal";
import KaufgabeKopierenTemplate from "./kaufgabekopieren.modal.jade";

class DetailController {
  /*@ngInject*/
  constructor(
    $rootScope,
    $state,
    $stateParams,
    AuthService,
    Katalog,
    Rolle,
    KAufgabe,
    WorkflowTyp,
    DiasNotification,
    KWorkflowstatusgrupppenUUIDs,
    i18n,
    diasModalDialog,
    diasConfirmDialog
  ) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.Katalog = Katalog;
    this.KAufgabe = KAufgabe;
    this.WorkflowTyp = WorkflowTyp;
    this.AuthService = AuthService;
    this.Rolle = Rolle;
    this.DiasNotification = DiasNotification;
    this.diasModalDialog = diasModalDialog;
    this.diasConfirmDialog = diasConfirmDialog;
    this.KWorkflowstatusgrupppenUUIDs = KWorkflowstatusgrupppenUUIDs;
    this.editPermissionTemplate = editPermissionTemplate;
    this.editPermissionController = editPermissionController;
    this.editDescriptionTemplate = editDescriptionTemplate;
    this.editDescriptionController = editDescriptionController;
    this.editStatusgruppenTemplate = editStatusgruppenTemplate;
    this.editStatusgruppenController = editStatusgruppenController;
    this.gettext = i18n.gettext;
    this.params = {};
    this.loading = {
      kaufgabe: true,
      berechtigungen: true,
      statusgruppen: true,
    };
    this.infoTexts = {
      bearbeiter: this.gettext("Nutzer die mind. eine der Rollen besitzen, die dieser Aufgabe zugeordnet sind."),
      beobachter: this.gettext("Nutzer die keiner der Rollen dieser Aufgabe zugeordnet sind."),
      passiv: this.gettext("Diese Aufgabe muss nicht aktiv vom Nutzer bearbeitet werden und wird auch nicht unter den zu erledigenden Aufgaben gelistet."),
      ende: this.gettext("Diese Aufgabe stellt das Ende eines Bearbeitungsprozesses dar und muss nicht weiter bearbeitet werden."),
    };
    this.perms = AuthService.syncHasPerms([
      "kaufgabe.update", "kaufgabe.delete",
      "kaufgabe.update.fristen", "kaufgabe.update.berechtigungen",
      "kaufgabe.update.statusgruppen",
      "workflowtyp.view", "workflowtyp.ui", "rollen.view",
      "rollen.ui", "funktionsgruppen.ui",
    ]);
    this.loadRolle = this.loadRolle.bind(this);
    this.checkRolle = this.checkRolle.bind(this);
    this.updateBerechtigungen = this.updateBerechtigungen.bind(this);
    this.init();
  }

  remove() {
    this.loading.delete = true;
    this.kaufgabe.remove().then(
      () => {
        this.DiasNotification.page.success("Aufgabenvorlage erfolgreich gelöscht");
        this.$state.go("root.kaufgaben");
      },
      err => this.DiasNotification.page.error(`${ err.data }`, "Fehler beim Löschen der Aufgabenvorlage")
    ).finally(() => this.loading.delete = false);
  }

  init() {
    this.KAufgabe.one(this.$stateParams.id).get().then(
      result => {
        this.kaufgabe = result;
        this.setTitle();
      }
    ).finally(() => this.loading.kaufgabe = false);

    this.KAufgabe.one(this.$stateParams.id).getBerechtigungen().then(
      result => {
        this.berechtigungen = result;
        this.updateBerechtigungen();
        if (this.perms["rollen.view"]) {
          this.loading.rollen = true;
          this.Rolle.getList().then(
            response => {
              this._rollen = response.sort((r1, r2) => r1.kbez.localeCompare(r2.kbez));
              this.initRollen();
            },
            err => this.DiasNotification.page.error("Fehler beim Laden der Rollen", err)
          ).finally(() => this.loading.rollen = false);
        } else {
          this._rollen = [];
          this.initRollen();
        }
      },
      err => this.DiasNotification.page.error("Fehler beim Laden der Berechtigungen", err)
    ).finally(() => this.loading.berechtigungen = false);
    if (this.perms["workflowtyp.view"]) {
      this.WorkflowTyp.getList().then(
        result => {
          this.workflowtypen = result.plain();
          this.updateStatusgruppen();
        }
      ).finally(() => this.loading.statusgruppen = false);
      this.wtgs = {};
      this.Katalog.getKatalog("workflowstatusgruppentypen").then(
        response => {
          Object.keys(this.KWorkflowstatusgrupppenUUIDs).map(
            key => this.KWorkflowstatusgrupppenUUIDs[key]
          ).forEach(
            pk => {
              const wtg = response.find(wtg => wtg.pk === pk) || {};
              this.wtgs[pk] = { bez: wtg.bez };
            }
          );
        }
      );
    }
  }

  assignKaufgabe(KAufgabe) {
    if (angular.isUndefined(KAufgabe)) {
      return;
    }
    return this.diasModalDialog({
      title: "Aufgaben kopieren",
      template: KaufgabeKopierenTemplate,
      controller: KaufgabeKopierenController,
      extras: {
        KAufgabe: KAufgabe,
        kaufgabe: this.kaufgabe
      },
    });
  }


  initRollen() {
    this.rollen = angular.copy(this._rollen);
    this.rollen.forEach(ro => {
      ro.id = ro.pk;
      ro.bez = ro.kbez;
      ro.group = ro.kontexttyp.kbez;
    });
    this.rollenChoices = this.rollen.filter(
      ro => !this.berechtigungen.rollen.find(sro => sro.pk === ro.pk)
    );
    this.params.beobachter = {
      bez: this.gettext("Beobachter-Rollen"),
      typ: "single_choice",
      choices: this.rollenChoices,
      wert: null,
      options: {
        required: false
      },
      localSearch: true
    };
  }

  updateStatusgruppen() {
    this.statusgruppen = {};
    this.wftMap = {};
    this.workflowtypen.forEach(
      wft => {
        this.wftMap[wft.pk] = wft;
        wft.wsgPosMap = {};
        wft.statusgruppen.forEach(
          wsg => {
            wft.wsgPosMap[wsg.wsg_pos] = wsg;
            if (wsg.aufgaben.find(au => au.pk === this.$stateParams.id)) {
              this.statusgruppen[wft.pk] = wsg.pk;
            }
          }
        );
      }
    );
    this.orderedWftIds = Object.keys(this.statusgruppen).sort(
      (k1, k2) => this.wftMap[k1].wft_bez.localeCompare(this.wftMap[k2].wft_bez)
    );
  }

  updateBerechtigungen() {
    this.fgMap = {};
    this.berechtigungen.bearbeiter_fktgruppen.forEach(
      fg => {
        this.fgMap[fg.key] = this.fgMap[fg.key] || { obj: fg };
        this.fgMap[fg.key].bearbeiter = true;
      }
    );
    this.berechtigungen.beobachter_fktgruppen.forEach(
      fg => {
        this.fgMap[fg.key] = this.fgMap[fg.key] || { obj: fg };
        this.fgMap[fg.key].beobachter = true;
      }
    );
    this.berechtigungen.rollen.forEach(ro => this.checkRolle(ro, "bearbeiter", this.fgMap));

    this.orderedFgKeys = Object.keys(this.fgMap).sort(
      (k1, k2) => this.fgMap[k1].obj.name.localeCompare(this.fgMap[k2].obj.name)
    );
  }

  loadSelectedRolle() {
    this.loadRolle(this.params.beobachter.wert, "beobachter", this.fgMap);
  }

  loadRolle(rollePk, vs, fgMap) {
    if (!rollePk || rollePk.length === 0) {
      return;
    }
    this.loading.rollen = true;
    const promise = this.Rolle.one(rollePk).get().then(
      response => {
        this.checkRolle(response, vs, fgMap);
        return response;
      }
    );
    promise.finally(() => this.loading.rollen = false);
    return promise;
  }

  checkRolle(rolle, vs, fgMap) {
    rolle.funktionsgruppen.forEach(
      fg => {
        if (!fgMap[fg.key]) {
          return;
        }
        fgMap[fg.key][rolle.pk] = fgMap[fg.key][rolle.pk] || {};
        fgMap[fg.key][rolle.pk][vs] = fgMap[fg.key][vs];
      }
    );
  }

  setTitle() {
    this.$rootScope.$title = `Aufgabe - ${ this.kaufgabe.au_kbezdetail }`;
  }
}

export default {
  template: template(),
  controller: DetailController,
  controllerAs: "vm",
};
