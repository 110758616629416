import Alert from "../../../global/components/Alert/Alert.vue";
import FormElement from "../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "NutzerModalSperren",
  components: {
    Alert,
    FormElement,
    Modal,
    PuxButton,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      model: "",
      optionsFormElement: {
        id: "n_sperrbemerkung",
        type: "textarea",
        label: "_LBL_NUTZER_MODAL_SPERREN_SPERRBEMERKUNG_",
        required: true,
        view: "v-alt",
      },
      status: {
        loading: undefined,
      },
      errors: {},
      errorLabels: {
        n_sperrbemerkung: "_LBL_NUTZER_MODAL_SPERREN_SPERRBEMERKUNG_"
      }
    };
  },
  computed: {
    isErrors() {
      return size(this.errors);
    },
  },
  methods: {
    sperrenNutzer() {
      this.status.loading = true;
      const data = cloneDeep(this.nutzer);
      data.n_sperrbemerkung = this.model;
      this.putHttp({ url: `nutzer/${ this.nutzer.pk }/sperren/`, data }).then(
        response => {
          this.addNotification({ text: "_MSG_NUTZER_MODAL_SPERREN_SUCCESS_" });
          this.close({ response });
        },
        error => {
          this.errors = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
