"use strict";

import BaseModel from "dias/core/models/baseModel";


class Login extends BaseModel {
  constructor() {
    super("/auth/login/");
  }
}

export default Login;
