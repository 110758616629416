"use strict";

import template from "./textbaustein.component.jade";

class TextbausteinComponent {
  /*@ngInject*/
  constructor($scope, Katalog) {
    this.Katalog = Katalog;
    this.model = this.model || "";
    this.bausteinChoices = [];
    this.selectedBaustein = null;
    this.editorAPI = {};
    this.bausteinParam = {
      typ: "single_choice",
      katalog: "textbausteine",
      filter: { pk: this.gruppen },
      wert: null
    };
    this.selectId = `${ this.id }_select_baustein`;
    this.textId = `${ this.id }_text_input`;
    $scope.$watch("vm.gruppen", () => {
      if (this.gruppen && this.gruppen.length > 0) {
        this.Katalog.getKatalog("textbausteine", { gruppe: this.gruppen }).then(
          response => this.bausteinChoices = response.plain()
        );
      }
    });
  }

  onInsertBaustein() {
    const baustein = this.bausteinChoices.find(v => v.pk === this.selectedBaustein);
    this.editorAPI.insertHtml(baustein.txb_text);
    this.selectedBaustein = null;
  }
}

export default {
  template: template(),
  controller: TextbausteinComponent,
  controllerAs: "vm",
  bindings: {
    gruppen: "=txtGruppen",
    model: "=ngModel",
    disabled: "=ngDisabled",
    id: "@id"
  }
};
