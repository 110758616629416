import Wizard from "../../../global/components/Wizard/Wizard.vue";
import HttpMixin from "../../../global/mixins/HttpMixin";
import Spinner from "../../../global/components/Spinner/Spinner.vue";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import WizardStepsMixin from "./WizardStepsMixin";
import translate from "../../../global/directives/translate";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import {
  FilterCurrencyMixin,
} from "../../../global/mixins/FiltersMixin";
import {
  cloneDeep,
  forEach,
  isUndefined,
  isFunction,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenPeriodenWizard",
  components: {
    Wizard,
    Spinner,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
    HttpMixin,
    NotificationMixin,
    WizardStepsMixin,
  ],
  props: {
    options: {
      type: Object,
      default: undefined,
    },
    antrag: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      wizardConfig: {},
      model: {},
      stepActive: 0,
      stepsVisited: {
        0: true,
        1: false,
        2: false,
        3: false,
      },
      booleanPropsWizard: {
        backTargetStepDisabled: undefined,
        backStepHide: undefined,
        backStepDisabled: undefined,
        forwardStepHide: undefined,
        forwardStepDisabled: undefined,
        buttonsLoading: false,
        closeButtonShow: true,
      },
      statusPeriodenAusgefuellt: false,
      statusInitModel: true,
      loadingSave: undefined,
      personalFunktionenLocal: [],
      katalogPersonalkostenObj: {},
      objShowMessageObergrenze: {
        1: false,
        2: false,
        3: false,
      },
    };
  },
  computed: {
    getPktPauschaleNumber() {
      const PKT_PAUSCHALE = this.options.personalkostentabelleObj.pkt_pauschale || 0;
      const AG_ANTEIL_ALTERSVORSORGE = this.model.ag_anteil_altersvorsorge || 0;
      return +PKT_PAUSCHALE + +AG_ANTEIL_ALTERSVORSORGE;
    },

    betragsrechner() {
      if (this.options.parameter) { // TODO: ILIA. Das soll gelöscht werden.
        return this.options.parameter.betragsrechner;
      }
      return get(this.options, "modelParameter.betragsrechner");
    },

    statusShowMessageObergrenze() {
      let statusShowMessageObergrenze = false;
      forEach(this.objShowMessageObergrenze, item => {
        if (item) {
          statusShowMessageObergrenze = item;
          return false;
        }
      });
      return statusShowMessageObergrenze;
    },
  },
  created() {
    this.initStatusPeriodenAusgefuellt();
    this.setPersonalfunktionen();
  },
  methods: {
    initStatusPeriodenAusgefuellt() {
      if (this.options.position) {
        this.statusPeriodenAusgefuellt = true;
      }
    },

    setPersonalfunktionen() {
      const KATALOG_PERSONALKOSTEN_OBJ = {};
      forEach(cloneDeep(this.options.katalogPersonalkosten), item => {
        if (item.personalfunktionen && item.personalfunktionen.length) {
          KATALOG_PERSONALKOSTEN_OBJ[item.personalfunktionen[0]] = item;
        }
      });
      this.katalogPersonalkostenObj = KATALOG_PERSONALKOSTEN_OBJ;

      const PERSONAL_FUNKTIONEN_LOCAL = [];

      forEach(cloneDeep(this.options.personalfunktionen), item => {
        const ITEM_LOCAL = item;
        ITEM_LOCAL.obergrenze = this.katalogPersonalkostenObj[item.pk] ? this.katalogPersonalkostenObj[item.pk].spk_kosten : undefined;
        PERSONAL_FUNKTIONEN_LOCAL.push(ITEM_LOCAL);
      });
      this.personalFunktionenLocal = PERSONAL_FUNKTIONEN_LOCAL;
    },

    initModelFromChild({ model, statusInitModel }) {
      this.model = model;
      if (!isUndefined(statusInitModel)) {
        this.statusInitModel = statusInitModel;
      }
    },

    initModel() {
      if (this.options.position) {
        this.initModelWithPosition();
        this.statusPeriodenAusgefuellt = true;
      } else {
        this.initModelWithoutPosition();
      }
    },

    initModelWithoutPosition() {
      this.model = {
        perioden: this.getModePerioden(),
        pkt_id: this.getPktId(),
        name: "N.N.",
      };
    },

    initModelWithPosition() {
      const MODEL = cloneDeep(this.options.position.argument);
      MODEL.perioden = this.getModePeriodenWithPosition({ perioden: MODEL.perioden });
      if (this.options.katalogDetails && this.options.katalogDetails.length) {
        MODEL.kostenartdetail = this.options.position.kostenartdetail;
      }
      this.model = MODEL;
    },

    getModePerioden() {
      const MODEL_PERIODEN = [];
      forEach(this.options.perioden, period => {
        const INFO = cloneDeep(period);
        INFO.teil = undefined;
        MODEL_PERIODEN.push({
          periode: period.pk,
          von: period.von_aktuell,
          bis: period.bis_aktuell,
          gehalt: 0,
          info: INFO,
        });
      });
      return MODEL_PERIODEN;
    },

    getModePeriodenWithPosition({ perioden }) {
      const MODEL_PERIODEN = [];
      const PERIODE_TEMP = {};
      let statusPeriodenTeil = false;
      forEach(perioden, period => {
        if (!PERIODE_TEMP[period.periode]) {
          PERIODE_TEMP[period.periode] = 1;
        } else {
          PERIODE_TEMP[period.periode]++;
          statusPeriodenTeil = true;
        }
        const INFO = cloneDeep(period.periode_obj);
        INFO.teil = undefined;
        const PERIOD_CLONE = cloneDeep(period);
        PERIOD_CLONE.period_obj = undefined;
        PERIOD_CLONE.info = INFO;
        if (PERIOD_CLONE.argument) {
          forEach(PERIOD_CLONE.argument, (item, key) => {
            PERIOD_CLONE[key] = item;
          });
        }
        MODEL_PERIODEN.push(PERIOD_CLONE);
        // MODEL_PERIODEN.push({
        //   periode: period.periode,
        //   von: period.von,
        //   bis: period.bis,
        //   gehalt: period.gehalt,
        //   einmalzahlung: period.einmalzahlung,
        //   brutto_monat: period.brutto_monat,
        //   info: INFO,
        // });
      });
      if (statusPeriodenTeil) {
        forEach(MODEL_PERIODEN, (period, index) => {
          if (PERIODE_TEMP[period.periode] > 1) {
            MODEL_PERIODEN[index].info.teil = true;
          }
        });
      }

      return MODEL_PERIODEN;
    },

    getPktId() {
      return this.options.personalkostentabelleObj.pkt_id;
    },

    onCloseWizard() {
      this.close({ regel_nummer: this.options.regel_nummer });
    },

    goOneStepForward() {
      this.validateStep();
    },

    validateStep() {
      if (this.$refs[`step${ this.stepActive + 1 }`].validateStep) {
        this.booleanPropsWizard.buttonsLoading = true;
        this.$refs[`step${ this.stepActive + 1 }`].validateStep({
          antragId: this.antrag.pk, kostenartId: this.options.kostenartId,
        }).then(
          () => {
            this.goNextStep();
          },
          () => {}
        ).then(
          () => this.booleanPropsWizard.buttonsLoading = false
        );
      } else {
        this.goNextStep();
      }
    },

    goOneStepBack() {
      this.setStatusPeriodenAusgefuellt();
      this.stepActive -= 1;
      this.setStepsVisited();
      this.stepsVisited[this.stepActive] = true;
    },

    goNextStep() {
      this.stepActive += 1;
      this.stepsVisited[this.stepActive] = true;
    },

    setStepsVisited() {
      forEach(this.stepsVisited, (item, key) => {
        this.stepsVisited[key] = false;
      });
    },

    setStatusPeriodenAusgefuellt() {
      if (this.stepActive === 2) {
        this.statusPeriodenAusgefuellt = true;
      }
    },

    closeSaveWizard({ response } = {}) {
      this.close({ statusReload: true, regel_nummer: this.options.regel_nummer, response });
    },

    changeStatusPeriodenAusgefuellt({ status }) {
      this.statusPeriodenAusgefuellt = status;
    },

    savePosition() {
      this.loadingSave = true;
      let data = {
        kostenartdetail: this.model.kostenartdetail,
        argument: this.preparePeriodenDataForSave(),
      };
      if (this.$refs.step1 && isFunction(this.$refs.step1.prepareDataForValidateAndSave)) {
        data = this.$refs.step1.prepareDataForValidateAndSave({ data });
      }
      if (this.options.position) {
        this.updatePosition({ data });
      } else {
        this.createPosition({ data });
      }
    },

    preparePeriodenDataForSave() {
      const MODEL = cloneDeep(this.model);
      forEach(MODEL.perioden, (period, index) => {
        if (!isUndefined(period.einmalzahlung) || !isUndefined(period.brutto_monat) || !isUndefined(period.an_bruttogehalt)) {
          MODEL.perioden[index].argument = {
            einmalzahlung: period.einmalzahlung,
            brutto_monat: period.brutto_monat,
            an_bruttogehalt: period.an_bruttogehalt,
          };
        }
      });
      return MODEL;
    },

    updatePosition({ data }) {
      this.putHttp({
        url: `foerderantraege/${ this.antrag.pk }/kostenarten/${ this.options.kostenartId }/positionen/${ this.options.position.pk }/`,
        data,
      }).then(
        response => {
          this.addNotification({ text: "_MSQ_ANTRAG_DETAILS_PERSONAL_WIZARD_POSITION_UPDATE_" });
          this.closeSaveWizard({ response });
        }
      ).then(
        () => this.loadingSave = false
      );
    },

    createPosition({ data }) {
      this.postHttp({
        url: `foerderantraege/${ this.antrag.pk }/kostenarten/${ this.options.kostenartId }/positionen/`,
        data,
      }).then(
        response => {
          this.addNotification({ text: "_MSQ_ANTRAG_DETAILS_PERSONAL_WIZARD_POSITION_SAVE_" });
          this.closeSaveWizard({ response });
        }
      ).then(
        () => this.loadingSave = false
      );
    },

    changeStatusShowMessageObergrenze({ wizardNummer, status }) {
      for (let i = 3; i >= 1; i--) {
        if (i === wizardNummer) {
          this.objShowMessageObergrenze[i] = status;
          break;
        }
        this.objShowMessageObergrenze[i] = false;
      }
    },
  },
};
