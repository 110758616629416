import {
  computed,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import fileDownload from "js-file-download";

export default function TagesordnungspunktNotGroupedAPI({
  sitzung = computed(() => ({})),
  tagesordnungspunkt = computed(() => ({})),
  tagesordnungspunktUrl = "",
}) {
  const pageTitleExtra = computed(() => {
    return {
      sitzungsnr: sitzung.value.si_sitzungsnr,
      posStr: tagesordnungspunkt.value.absolutPositionString,
      tos_bez: tagesordnungspunkt.value.tos_bez,
    };
  });

  const extraHeaderH2Translate = computed(() => {
    return {
      absolutPositionString: tagesordnungspunkt.value.absolutPositionString,
      tos_bez: tagesordnungspunkt.value.tos_bez,
    };
  });


  const {
    getHttp,
  } = HttpAPI();
  const {
    filterDate,
  } = FiltersAPI();
  const urlSavePdfUebersicht = `${ tagesordnungspunktUrl }uebersicht_pdf/`;
  // this.top.posStr.replace(".", "-")
  const absolutPositionStringFormatted = computed(() => {
    return tagesordnungspunkt.value.absolutPositionString.replaceAll(".", "-");
  });
  const savePdfUebersicht = () => {
    getHttp({
      url: urlSavePdfUebersicht,
      responseType: "blob",
    }).then(
      response => {
        const FILE_NAME = `${ filterDate(new Date().toISOString(), "YYYYMMDD") }_sitzung_${ sitzung.value.si_sitzungsnr }_top_${ absolutPositionStringFormatted.value }_uebersicht.pdf`;
        fileDownload(response, FILE_NAME);
      }
    );
  };

  return {
    extraHeaderH2Translate,
    pageTitleExtra,
    savePdfUebersicht,
  };
}
