var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group"] = jade_interp = function(errors, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :" + (errors) + "}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "" + (errors) + "", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errors) + "", true, true)) + ">{{ err }}</li></ul></span></label>");
};
jade_mixins["required-form-group"] = jade_interp = function(errors, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :" + (errors) + "}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "" + (errors) + "", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errors) + "", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<fieldset dias-cloak=\"vm.loadingEditData\"><div ng-attr-id=\"{{ vm.errorContainerId }}\"></div><div class=\"row\"><div class=\"col-12 col-sm-6\"><div class=\"mb-2\"><label ng-class=\"{'has-error has-feedback': vm.errors.ars_eigenstaendig}\" class=\"mb-0 font-weight-bold\"><span class=\"titel\">Eigenständig<span class=\"alert-danger\"></span></span></label><div class=\"radio-btn line\"><input name=\"ars_eigenstaendig\" id=\"ars_eigenstaendig-true\" type=\"radio\" ng-model=\"vm.model.ars_eigenstaendig\" ng-value=\"true\" ng-attr-aria-checked=\"{{ vm.model.ars_eigenstaendig === true ? 'true' : 'false' }}\"><label for=\"ars_eigenstaendig-true\" class=\"font-weight-bold\"><span>Ja</span></label></div><div class=\"radio-btn line\"><input name=\"ars_eigenstaendig\" id=\"ars_eigenstaendig-false\" type=\"radio\" ng-model=\"vm.model.ars_eigenstaendig\" ng-value=\"false\" ng-attr-aria-checked=\"{{ vm.model.ars_eigenstaendig === false ? 'true' : 'false' }}\"><label for=\"ars_eigenstaendig-false\" class=\"font-weight-bold\"><span>Nein</span></label></div><span ng-if=\"vm.errors.ars_eigenstaendig\" class=\"help-block\"><ul class=\"errorlist\"><li ng-repeat=\"err in vm.errors.ars_eigenstaendig\">{{ err }}</li></ul></span></div>");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"foe_kbez\" ng-model=\"vm.model.ars_kbez\" type=\"text\" name=\"ars_kbez\" maxlength=\"120\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.ars_kbez ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "vm.errors.ars_kbez", "Version Kurzbezeichnung");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<textarea id=\"foe_bez\" ng-model=\"vm.model.ars_bez\" dias-richtext name=\"ars_bez\" aria-required=\"true\" ng-change=\"vm.changeArsBez()\" ng-attr-aria-invalid=\"{{vm.errors.ars_bez ? 'true' : 'false'}}\" class=\"form-control\"></textarea>");
}
}, "vm.errors.ars_bez", "{{vm.labelArsBez}}");
buf.push("</div><div class=\"col-12 col-sm-6\">");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input ng-model=\"vm.model.ars_gueltig_ab\" name=\"ars_gueltig_ab\" type=\"text\" bs-datepicker ng-attr-aria-invalid=\"{{vm.errors.ars_gueltig_ab ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "vm.errors[ars_gueltig_ab]", "Gültig ab");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input ng-model=\"vm.model.ars_gueltig_bis\" name=\"ars_gueltig_bis\" type=\"text\" bs-datepicker ng-attr-aria-invalid=\"{{vm.errors.ars_gueltig_bis ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "vm.errors[ars_gueltig_bis]", "Gültig bis");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input ng-model=\"vm.model.ars_max_antraege\" name=\"ars_max_antraege\" type=\"number\" ng-attr-aria-invalid=\"{{vm.errors.ars_max_antraege ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "vm.errors[ars_max_antraege]", "Maximal Anzahl Anträge pro Antragsteller");
buf.push("<div ng-if=\"vm.zielgruppenChoices.length\">");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<div ng-repeat=\"gruppe in vm.zielgruppenChoices\" class=\"checkbox-btn\"><input type=\"checkbox\" ng-attr-name=\"{{gruppe.pk}}\" ng-attr-id=\"{{gruppe.pk}}\" ng-model=\"vm.zielgruppenSelection[gruppe.pk]\" aria-required=\"true\" ng-attr-aria-checked=\"{{ vm.zielgruppenSelection[gruppe.pk] ? 'true' : 'false' }}\" ng-attr-aria-invalid=\"{{vm.errors.zielgruppen ? 'true' : 'false'}}\"><label ng-attr-for=\"{{gruppe.pk}}\" class=\"font-weight-bold\"><span class=\"label-hidden\">Zielgruppen</span><span>{{ gruppe.zgr_kbez }}</span></label></div>");
}
}, "vm.errors.zielgruppen", "Zielgruppen");
buf.push("</div><div vueinit props=\"{ config: vm.periodenConfig, errors: vm.errors.perioden, change: vm.periodenChange, model: vm.model.perioden }\"><form-element v-bind:options=\"propsAngular.config\" v-bind:errors=\"propsAngular.errors\" v-bind:change=\"propsAngular.change\" v-bind:model-value=\"propsAngular.model\"></form-element></div><div vueinit props=\"{ config: vm.gutachtenvorlagenConfig, errors: vm.errors.gutachtenvorlagen, change: vm.gutachtenvorlagenChange, model: vm.model.gutachtenvorlagen }\"><form-element v-bind:options=\"propsAngular.config\" v-bind:errors=\"propsAngular.errors\" v-bind:change=\"propsAngular.change\" v-bind:model-value=\"propsAngular.model\"></form-element></div>");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<select id=\"einstiegsworkflows\" ng-model=\"vm.selected_antrag_workflow\" aria-required=\"true\" role=\"listbox\" ng-attr-aria-invalid=\"{{vm.errors.einstiegsworkflows[0].einstieg_workflow ? 'true' : 'false'}}\" class=\"form-control\"><option ng-repeat=\"item in vm.workflowChoices\" value=\"{{ item.pk }}\">{{ item.wf_kbez }}</option></select>");
}
}, "vm.errors.einstiegsworkflows[0].einstieg_workflow", "Workflow-Einstieg für Anträge");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<select id=\"antragstyp\" ng-model=\"vm.model.antragstyp\" aria-required=\"true\" role=\"listbox\" ng-attr-aria-invalid=\"{{vm.errors.antragstyp ? 'true' : 'false'}}\" class=\"form-control\"><option ng-repeat=\"item in vm.antragsTypen\" value=\"{{ item.pk }}\">{{ item.ant_kbez }}</option></select>");
}
}, "vm.errors.antragstyp", "Antragstyp");
buf.push("</div></div><div class=\"row\"><div class=\"col-sm-12\"><div class=\"form-group\"><label ng-class=\"{'has-error has-feedback': vm.errors.antragstellerorgtypen}\" class=\"font-weight-bold\"><span class=\"titel\">Erlaubte {{ vm.gettext('Antragstellerorganisationstypen') }}<span class=\"alert-danger\"></span></span></label><div style=\"column-count: 2; column-gap: 2em;\"><div ng-repeat=\"typ in vm.extra.antragstellerorgtypen\" class=\"checkbox-btn\"><input ng-attr-id=\"antragstellerorgtypen_{{typ.pk}}\" ng-attr-name=\"antragstellerorgtypen\" type=\"checkbox\" ng-model=\"vm.atSelection[typ.pk]\" ng-attr-aria-checked=\"{{ vm.atSelection[typ.pk] ? 'true' : 'false' }}\"><label ng-attr-for=\"antragstellerorgtypen_{{typ.pk}}\" class=\"font-weight-bold\">{{ typ.at_kbez }}</label></div></div><span ng-if=\"vm.errors.antragstellerorgtypen\" class=\"help-block\"><ul class=\"errorlist\"><li ng-repeat=\"err in vm.errors.antragstellerorgtypen\">{{ err }}</li></ul></span></div></div></div></fieldset><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save, vm.errorsLabel)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Abbrechen</span></button></div></div>");;return buf.join("");
}