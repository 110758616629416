"use strict";

import BaseRegelModel from "dias/foerderangebote/models/baseRegelModel";
import Funktionsgruppen from "dias/auth/models/funktionsgruppen.service";


class Foerderregeln extends BaseRegelModel {
  constructor() {
    super("foerderregeln", {
      subPaths: {
        funktionsgruppen: new Funktionsgruppen(),
      }
    });
  }

  extendModel(model) {
    super.extendModel(model);
    model.create_copy = () => model.customPOST({}, "kopieren");
    model.titel = (model.re_nummer ? ("[" + model.re_nummer + "] ") : "") + model.re_kbez;
    return model;
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.getAngebotsregeln = params => this.Restangular.all(
      "foerderangebote/regeln"
    ).getList(params);
    collection.getSachberichtregeln = params => this.Restangular.all(
      "sachberichtvorlagen/regeln"
    ).getList(params);
    collection.getProjektberichtregeln = params => this.Restangular.all(
      "projektberichtvorlagen/regeln"
    ).getList(params);
    collection.getGutachtenregeln = params => this.Restangular.all(
      "gutachtenvorlagen/regeln"
    ).getList(params);
    return collection;
  }
}

export default Foerderregeln;
