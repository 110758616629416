import DokumentDownload from "../../Dokumente/DokumentDownload/DokumentDownload.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "TemplateRendering",
  components: {
    DokumentDownload,
    PuxButton,
  },
  mixins: [
    HttpMixin
  ],
  props: {
    objUrl: {
      type: String,
      default: undefined,
    },
    triggerForUpdate: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      templates: [],
      loading: true,
    };
  },
  watch: {
    triggerForUpdate() {
      this.loadTemplates();
    },
  },
  created() {
    this.loadTemplates();
  },
  methods: {
    loadTemplates() {
      this.getListHttp({
        url: `${ this.objUrl }/templates/`,
      }).then(
        response => {
          this.templates = response;
          this.loading = false;
        }
      );
    },
  },
};
