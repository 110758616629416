import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import ZuschussStandard from "../../KFDetail/ZuschussStandard/ZuschussStandard.vue";

import FinanzierugMixin from "../Mixins/FinanzierugMixin";

import InfoproviderStatuses from "../../../../const/InfoproviderStatuses";
import {
  gettext,
} from "../../../../global/functions/utils";

import {
  cloneDeep,
  get,
  map,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "ManuellePauschale",
  components: {
    KFStandard,
    ZuschussStandard,
  },
  mixins: [
    FinanzierugMixin,
  ],
  data() {
    return {
      allowsInput: true,
    };
  },
  computed: {
    statusNotLoad() {
      return !this.showAntrag && !this.showVertrag;
    },

    showAntrag() {
      return this.modelParameter.anzeige_antrag && this.kontext === "zuschuss";
    },

    showVertrag() {
      return this.modelParameter.anzeige_vertrag && this.kontext === "zuschuss_vertrag";
    },

    hidePosToggle() {
      return this.statusReadonly && size(this.positionen) === 0;
    },

    sumLocal() {
      if (this.statusNochNichtErmittelbar) {
        return gettext("_TXT_NOCH_NICHT_ERMITTELBAR_");
      }
      return this.summeCurrency;
    },

    vfBetragBewilligtCurrency() {
      return this.filterCurrency(this.vertragFinanzierungsart.vf_betrag_bewilligt || 0);
    },

    statusNochNichtErmittelbar() {
      return this.infoprovider &&
        this.infoprovider.status &&
        this.infoprovider.status !== InfoproviderStatuses.PROCESSOR_STATUS_OK;
    },

    meldungen() {
      return get(this.infoprovider, `details.${ this.regel.afr_id }.meldungen`, []);
    },

    vertragUrlFinanzierungPos() {
      return `${ this.vertragUrlFinanzierung }pauschale_positionen/`;
    },

    labelsPosition() {
      return {
        buttonTogglePositionTextHide: "_BTN_MANUELLE_PAUSCHALE_POSITION_TOGGLE_SHOW_{{kofiart}}_",
        buttonTogglePositionTextShow: "_BTN_MANUELLE_PAUSCHALE_POSITION_TOGGLE_HIDE_{{kofiart}}_",
        buttonAddText: "_BTN_MANUELLE_PAUSCHALE_ADD_POSITION_",
        buttonAddIcon: "edit",
        labelAdd: "_TXT_MANUELLE_PAUSCHALE_POSITION_ADD_HEADER_{{kofiart}}_",
        labelEdit: "_TXT_MANUELLE_PAUSCHALE_POSITION_EDIT_HEADER_{{position}}_{{kofiart}}_",
        buttonEditTooltip: "_BTN_MANUELLE_PAUSCHALE_KF_POSITION_EDIT_TITLE_",
        buttonDeleteTooltip: "_BTN_MANUELLE_PAUSCHALE_KF_POSITION_DELETE_TITLE_",
        confirmDeleteHeader: "_TXT_MANUELLE_PAUSCHALE_KF_DELETE_CONFIRM_HEADER_{{position}}_{{kofiart}}_",
        confirmDeleteBody: "_HTML_MANUELLE_PAUSCHALE_KF_DELETE_CONFIRM_BODY_{{position}}_{{kofiart}}_",
      };
    },

    finKbez() {
      return get(this.vertragFinanzierungsart, "finanzierungsart.fin_kbez", "");
    },

    betragsrechner() {
      return "freier_betrag";
    },
  },
  methods: {
    setVertragPosition(response) {
      this.positionen = map(response, vfp => {
        const POSITION = {
          pk: vfp.pk,
          betrag_bewilligt: vfp.vfp_betrag_bewilligt,
          afp_bez: vfp.vfp_bez,
          afp_argument: cloneDeep(vfp.vfp_argument) || {},
          afp_wert: vfp.vfp_betrag_aktualisiert,
          argument: cloneDeep(vfp.vfp_argument) || {},
          foerderantragfinanzierungsartpos: vfp.foerderantragfinanzierungsartpos,
        };
        POSITION.argument.wert = vfp.vfp_betrag_aktualisiert;
        POSITION.argument.bezeichnung = vfp.vfp_bez;
        return POSITION;
      });
    }
  },
};
