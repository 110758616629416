import Modal from "../../global/components/Modal/Modal.vue";
import PuxButton from "../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";

import translate from "../../global/directives/translate";

import HttpMixin from "../../global/mixins/HttpMixin";
import PermissionMixin from "../../global/mixins/PermissionMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "Sichtbarkeit",
  components: {
    Modal,
    PuxButton,
    PuxIcon,
    PuxTranslate,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    baseUrl: {
      type: String,
      required: true,
    },
    rowIndex: {
      type: [String, Number],
      default: undefined,
    },
  },
  emits: [
    "callback",
  ],
  data() {
    return {
      loading: undefined,
      options: {
        showCloseButton: true,
        list: [
          {
            type: "readonly",
            text: "_TXT_SICHTBARKEIT_MODAL_CHANGE_BODY_",
          }
        ],
      },
      modal: {
        confirm: undefined,
      },
      status: {
        loading: undefined,
      },
    };
  },
  computed: {
    idLocal() {
      return `${ this.id }_visibility`;
    },

    selectorClose() {
      return `#${ this.idLocal }`;
    },

    titleTranslate() {
      return this.data.a_privat ?
        "_TXT_SICHTBARKEIT_AKTUELL_PRIVAT_TITLE_" :
        "_TXT_SICHTBARKEIT_AKTUELL_NICHT_PRIVAT_TITLE_";
    },

    iconLocal() {
      return this.data.a_privat ? "lock" : "lock-open";
    },

    canToggle() {
      return this.checkPermissionsSync({
        perm: "foerderantrag.sichtbarkeit",
        permArray: this.data.user_permissions,
      });
    },

    urlSichtbarkeit() {
      return `${ this.baseUrl }/${ this.data.pk }/sichtbarkeit_aendern/`;
    },
  },
  methods: {
    confirmSichtbarkeit() {
      if (this.loading) {
        return;
      }
      if (this.data.a_privat) {
        this.showModal();
      } else {
        this.toggleSichtbarkeit();
      }
    },

    toggleSichtbarkeit() {
      this.postHttp({
        url: this.urlSichtbarkeit,
      }).then(
        response => {
          const dataTemp = cloneDeep(this.data);
          dataTemp.a_privat = response.a_privat;
          this.$emit("callback", {
            rowIndex: this.rowIndex,
            data: dataTemp,
            a_privat: response.a_privat,
          });
          this.loading = false;
        },
        () => {}
      );
    },

    showModal() {
      this.modal.confirm = true;
    },

    closeModal() {
      this.modal.confirm = false;
    },

    confirmModal() {
      this.toggleSichtbarkeit();
      this.modal.confirm = false;
    },
  },
};
