"use strict";

import BaseModel from "dias/core/models/baseModel";

class RegelsatzAktiv extends BaseModel {
  constructor() {
    super("aktive_regelsaetze");
  }

  extendModel(model) {
    model = super.extendModel(model);

    if (!angular.isObject(model)) {
      return model;
    }

    model.getPreview = () => model.get();

    return model;
  }
}


export default RegelsatzAktiv;
