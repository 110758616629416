"use strict";

import createFrageController from "./modal.create.frage.controller.js";
import createFrageTemplate from "./modal.create.frage.template.jade";


import template from "./list.jade";

class FoerderfinderController {
  /*@ngInject*/
  constructor(
    $q, 
    $state, 
    Foerderfinderfragen, 
    AuthService,
    DiasTableConfig, 
    diasModalDialog, 
    diasConfirmDialog,
    DiasNotification, 
    FoerderfinderHierarchie
  ) {
    this.$state = $state;
    this.AuthService = AuthService;
    this.Foerderfinderfragen = Foerderfinderfragen;
    this.notification = DiasNotification.page;
    this.diasConfirmDialog = diasConfirmDialog;
    this.FoerderfinderHierarchie = FoerderfinderHierarchie;
    const getDetailRoute = row => "root.foerderfinderfragen.detail({id: '" + row.pk + "'})";
    this.loading = true;
    this.meldungen = [];
    this.loadHierarchie();
    this.displayTree = true;
    this.treeviewPossible = true;
    this.fragenTable = DiasTableConfig.fromService(Foerderfinderfragen, {
      // Reihenfolge der angezeigten Spalten.
      columnOrder: ["ffr_name", "ffr_text", "ffr_ueberschrift", "ffr_hauptfrage"],
      updateColumnDefs: columns => {
        columns.ffr_text.label = "Text";
        columns.ffr_name.label = "Interne Bezeichnung";
        columns.ffr_ueberschrift.label = "Überschrift";
        columns.ffr_hauptfrage.label = "Hauptfrage";
        columns.ffr_hauptfrage.cellTemplate = "{{ row.ffr_hauptfrage === false?'Nein':'Ja' }}";
      },

      // Tabellenaktionen
      tableActions: [
        { label: "Neue Frage erstellen",
          icon: "glyphicon glyphicon-plus", primary: true,
          callback: () => diasModalDialog({
            title: "Förderfinderfrage erstellen",
            template: createFrageTemplate,
            controller: createFrageController
          })
        }
      ],
      actions: [
        { label: "löschen",
          isVisible: true,
          callback: this.deleteFrage.bind(this) },
      ],
      tableTitle: "Förderfinderfragen",
      getDetailRoute: getDetailRoute,
      isDirty: scope => {
        return this.frageLastChange > scope.vm.lastRefresh;
      }

    });
    this.reloadPage = () => {
      this.frageLastChange = new Date().getTime();
    };
  }

  loadHierarchie() {
    this.FoerderfinderHierarchie.getList().then(
      result => {
        this.meldungen = [];
        if (result[0].error) {
          this.hierarchie = [];
          this.meldungen.push([result[0].error, "alert-danger"]);
          this.displayTree = false;
          this.treeviewPossible = false;
        } else {
          this.hierarchie = result;
          this.treeviewPossible = true;
          if (this.hierarchie && this.hierarchie.length > 1) {
            this.meldungen.push(["Es wurden mehrere Fragen auf oberster Ebene angelegt. Dadurch kann der Förderfinder keine eindeutige Einstiegsfrage ermitteln. Bitte entfernen die die überzähligen Fragen auf oberster Ebene z.B. durch Zuweisen als Folgefrage zu einer Antwort.", "alert-warning"]);
          }
        }
      }
    ).finally(() => this.loading = false);
  }

  toggleDisplay() {
    this.displayTree = !this.displayTree;
  }

  deleteFrage(row) {
    this.diasConfirmDialog({
      titleTemplate: "Förderfinderfrage {{ frage.ffr_text }} löschen",
      contentTemplate: "<p>Sind Sie sicher, dass Sie die Frage löschen wollen? Es werden hierbei ebenfalls alle der Frage zugeordeten Antworten gelöscht.</p>",
      context: { frage: row },
      okLabel: "Frage löschen",
      okCallback: () => this.Foerderfinderfragen.one(row.pk).remove().then(
        () => {
          this.notification.success("Frage wurde gelöscht.");
          this.reloadPage();
        },
        () => this.notification.error("Frage konnte nicht gelöscht werden.")
      )
    });
  }
}

export default {
  template: template(),
  controller: FoerderfinderController,
  controllerAs: "vm"
};
