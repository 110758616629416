"use strict";

import BetragsrechnerBase from "../betragsrechner.base";

class PersonalkostenMitPkt extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "personalkosten";
    this.component_name = "personalkosten";
    this.fields = {
      antrag: { stellenbezeichnung: "",
                personalfunktion: "",
                stellendauer: "",
                stellenumfang: "",
                stellenumfang_projekt: "",
                monatsgehalt: "" },
      beleg: { name: "",
               einwilligung: "",
               stellendauer: "",
               beschaeftigt_von: "",
               beschaeftigt_bis: "",
               stellenumfang: "",
               stellenumfang_projekt: "",
               monatsgehalt: "",
               personalfunktion: "",
               personalstelle: "",
               dokument_geschwaerzt: "" }
    };
    this.kontext = kontext;
    this.labels = { antrag: { stellenbezeichnung: "Stellenbezeichnung",
                              personalfunktion: "Funktion im Projekt",
                              vollzeitstelle: "Umfang einer Vollzeitstelle in der Organisation (in Stunden pro Woche)",
                              stellendauer: "Stellendauer",
                              stellenumfang: "Gesamter Umfang dieser Stelle in Ihrer Organisation (in Stunden pro Woche)",
                              stellenumfang_projekt: "davon im Projekt (in Stunden pro Woche)",

                              monatsgehalt: "Monatliches Bruttogehalt" },
                    beleg: { name: "Name",
                             einwilligung: "Einwilligungserklärung",
                             personalfunktion: "Funktion im Projekt",
                             vollzeitstelle: "Umfang einer Vollzeitstelle in der Organisation (in Stunden pro Woche)",
                             stellendauer: "Stellendauer",
                             stellenumfang: "Gesamter Umfang dieser Stelle in Ihrer Organisation (in Stunden pro Woche)",
                             stellenumfang_projekt: "davon im Projekt (in Stunden pro Woche)",
                             monatsgehalt: "Monatliches Bruttogehalt",
                             personalstelle: "Art der Personalstelle",
                             beschaeftigt_von: "Beschäftigt_seit",
                             dokument_geschwaerzt: "Bestätigung" } };
  }

  get_labelmapping() {
    return this.labels[this.kontext];
  }
}
export default PersonalkostenMitPkt;
