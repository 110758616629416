"use strict";

import BetragsrechnerRegistry from "../services";

import BetragsrechnerUebernachtung from "./betragsrechner.js";
import BetragsrechnerUebernachtungController from "./controller";

const BetragsrechnerUebernachtungModul = angular.module("dias.betragsrechner.BetragsrechnerUebernachtungModul", [
  BetragsrechnerRegistry.name,
]);

BetragsrechnerUebernachtungModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("uebernachtungskostenrechner", BetragsrechnerUebernachtung);
})
  .component("uebernachtungskosten", BetragsrechnerUebernachtungController);

export default BetragsrechnerUebernachtungModul;
