"use strict";

import BaseController from "../config.base.controller";

class ParametermoduleMultiSelectController extends BaseController {
  /*@ngInject*/
  constructor($scope, $filter, Katalog, DiasNotification) {
    super();
    this.choices = [];
    this.filtered_choices = [];
    this.labelIdField = "id"; // Default-Feld
    this.orderedChoices = [];
    this.selection = {}; // Das Binding für Checkboxen
    this.displaymode = "checkbox"; // Defaulteinstellung für die Darstellung
    this.requiredSelection = {};
    this.loading = true;
    this.ceil = Math.ceil;
    if (this.parameter[this.key].wert === undefined || this.parameter[this.key].wert === null) {
      this.parameter[this.key].wert = [];
      if (this.parameter[this.key].default !== undefined && this.parameter[this.key].default !== null) {
        this.parameter[this.key].wert = [...this.parameter[this.key].default];
      }
    }
    if (this.parameter[this.key].options) {
      if (this.parameter[this.key].options.displaymode) {
        this.displaymode = this.parameter[this.key].options.displaymode;
      }
      // DAM-5554 - Workaround damit alte Regeln weiterhin funktionieren (Sergej).
      if (this.parameter[this.key].options.required) {
        this.requiredChangeable = true;
        this.requiredSelection = {};
      }
    }
    this.update_choices = new_value => {
      if (new_value !== undefined) {
        this.filtered_choices = $filter("filter")(this.choices, { group: new_value });
      } else {
        this.filtered_choices = [];
        this.selection = {};
      }
      // Sicherstellen, dass alle aktuell in der abhängigen Liste ausgewählten
      // Einträge noch gültig sind, sonst entfernen:
      if (this.parameter[this.key].wert && this.choices.length > 0) {
        const allowed_values = this.filtered_choices.map(function(e) {
          return e.id;
        });
        for (let i = this.parameter[this.key].wert.length; i > -1; i--) {
          if (allowed_values.indexOf(this.parameter[this.key].wert[i]) === -1) {
            this.selection[this.parameter[this.key].wert[i]] = false;
            this.parameter[this.key].wert.splice(i, 1);
          }
        }
      }
    };
    this.get_selectionvalues = argumentwert => {
      const selected = {};
      const allowed_values = this.filtered_choices.map(function(e) {
        return e.id;
      });
      if (argumentwert !== undefined) {
        angular.forEach(argumentwert, c => {
          if (allowed_values.indexOf(c) > -1) {
            selected[c] = true;
          }
        });
      }
      return selected;
    };
    this.update_argumentwert = () => {
      this.parameter[this.key].wert = [];
      angular.forEach(this.selection, (selected, pk) => {
        if (selected === true) {
          this.parameter[this.key].wert.push(pk);
        }
      });
    };
    if (this.parameter[this.key].katalog) {
      Katalog.getKatalog(this.parameter[this.key].katalog, this.parameter[this.key].katalog_filter, this.parameter[this.key].katalog_uncached).then(
        result => {
          this.choices = result;
          // Prüfe auf Abhängigkeiten zu anderem Parameter:
          if (this.parameter !== undefined && this.parameter[this.key].dependency !== undefined) {
            $scope.$watch("vm.parameter['" + this.parameter[this.key].dependency + "'].wert", this.update_choices);
            this.filtered_choices = $filter("filter")(this.choices, this.parameter[this.parameter[this.key].dependency].wert);
          } else {
            this.filtered_choices = result;
          }
          this.updateLabelIDField();
          this.selection = this.get_selectionvalues(this.parameter[this.key].wert);
        },
        error => {
          DiasNotification.form.error(error.data.detail, "Katalog nicht gefunden");
        }
      ).finally(() => this.loading = false);
    } else {
      $scope.$watchCollection(() => this.parameter[this.key].choices, () => {
        this.choices = [];
        for (let i = 0; i < this.parameter[this.key].choices.length; i++) {
          if (angular.isArray(this.parameter[this.key].choices[i])) {
            this.choices.push({ id: this.parameter[this.key].choices[i][0], bez: this.parameter[this.key].choices[i][1] });
          } else {
            this.choices.push(this.parameter[this.key].choices[i]);
          }
        }
        this.filtered_choices = this.choices;
        this.updateLabelIDField();
        this.selection = this.get_selectionvalues(this.parameter[this.key].wert);
      });
      this.loading = false;
    }
    this.get_displayvalue = function(id) {
      let x = "";
      angular.forEach(this.choices, c => {
        if (c.id == id) {
          x = c.bez;
        }
      });
      return x;
    };
  }

  updateLabelIDField() {
    if (this.filtered_choices && this.filtered_choices.length > 0 && this.filtered_choices[0].pk) {
      this.labelIdField = "pk";
    } else {
      this.labelIdField = "id";
    }
  }

  updateSelection(choicePk) {
    if (choicePk && !this.selection[choicePk] && this.requiredSelection[choicePk]) {
      delete this.requiredSelection[choicePk];
    }
    this.parameter[this.key].wert = [];
    angular.forEach(this.selection, (selected, pk) => {
      if (selected === true) {
        this.parameter[this.key].wert.push(pk);
      }
    });
  }

  // ordered
  orderedUpdateChoices() {
    this.orderedChoices.splice(
      0,
      this.orderedChoices.length,
      ...this.filtered_choices.filter(v => this.parameter[this.key].wert.indexOf(v.id) === -1).map(
        v => {
          return { value: v.id, label: v.bez, group: v.group };
        }
      )
    );
  }

  orderedGetBez(pk) {
    const item = this.filtered_choices.find(el => el.id === pk);
    if (item) {
      return item.bez;
    }
  }

  orderedRemove(index) {
    this.parameter[this.key].wert.splice(index, 1);
  }

  orderedMoveUp(index) {
    if (index === 0) {
      return;
    }
    const elem = this.parameter[this.key].wert[index];
    const previous = this.parameter[this.key].wert[index - 1];
    this.parameter[this.key].wert[index - 1] = elem;
    this.parameter[this.key].wert[index] = previous;
  }

  orderedMoveDown(index) {
    if (index >= this.parameter[this.key].wert.length - 1) {
      return;
    }
    const elem = this.parameter[this.key].wert[index];
    const next = this.parameter[this.key].wert[index + 1];
    this.parameter[this.key].wert[index + 1] = elem;
    this.parameter[this.key].wert[index] = next;
  }

  orderedIsDisabled() {
    return !this.orderedSelection;
  }

  orderedAdd() {
    if (!this.orderedSelection[0]) {
      return;
    }
    this.orderedAddToList(this.parameter[this.key].wert, this.orderedSelection[0]);
    this.orderedSelection = [];
    this.orderedUpdateChoices();
  }

  orderedAddToList(list, el) {
    const alreadyIn = list.find(v => v === el.value);
    if (alreadyIn) {
      this.DiasNotification.page.error(`"${ el.label }" ist bereits ausgewählt.`);
      return false;
    }
    list.push(el.value);
    return true;
  }
}

export default ParametermoduleMultiSelectController;
