import store from "../../store";
import KontextTypes from "../const/KontextTypes";
import { createNamespacedHelpers } from "vuex";
const {
  mapGetters,
} = createNamespacedHelpers("profile");
import {
  getProfile,
  getHttp,
} from "./HttpMixin";

import {
  isString,
  isArray,
  indexOf,
  isUndefined,
  forEach,
  cloneDeep,
} from "lodash-es";

export const CheckPermissionsSyncMixin = {
  methods: {
    checkPermissionsSync({ perm, permArray }) {
      return checkPermissionsSync({ perm, permArray });
    },
  },
};

export const HasOneOfPermissions = {
  methods: {
    hasOnePermissions({ perm = [], permArray }) {
      for (let i = 0; i < perm.length; i++) {
        if (checkPermissionsSync({ perm: perm[i], permArray })) {
          return true;
        }
      }
      return false;
    },
  },
};

export const CurrentUserMixin = {
  computed: {
    me() {
      return this.GET_ME ? cloneDeep(this.GET_ME) : undefined;
    },

    ...mapGetters([
      "GET_ME",
    ]),
  },
};

// @vue/component
export default {
  mixins: [
    CheckPermissionsSyncMixin,
    HasOneOfPermissions,
    CurrentUserMixin,
  ],
  computed: {
    fullName() {
      if (!this.statusAuthenticated || !this.me) {
        return "";
      }
      const parts = [];
      if (this.me.n_vorname) {
        parts.push(this.me.n_vorname);
      }
      if (this.me.n_nachname) {
        parts.push(this.me.n_nachname);
      }
      if (parts.length === 0) {
        parts.push(this.me.n_loginname);
      }
      return parts.join(" ");
    },

    statusAuthenticated() {
      return this.isAuthenticatedSinc();
    },
  },
  methods: {
    checkPermissions(perm) {
      return checkPermissions(perm);
    },

    checkPermissionsApi({ perms, content_type, pk }) {
      return _checkPermissionsApi({ perms, content_type, pk });
    },

    isAuthenticatedSinc() {
      const user = this.me;
      return !!(user && user.pk);
    },

    checkContextTypeSync({ contextType }) {
      if (isUndefined(contextType)) {
        return true;
      }

      const USER = this.me;
      if (isUndefined(USER) || !USER.kontexte) {
        return false;
      }
      for (let i = 0; i < USER.kontexte.length; i++) {
        if (USER.kontexte[i].kontext_typ === KontextTypes[contextType]) {
          return true;
        }
      }
      return false;
    },

    preparePermissionsList({ response }) {
      const PERMISSIONS_LIST = [];
      const PERMISSIONS_OBJ = {};
      forEach(response, fGroup => {
        forEach(fGroup.berechtigungen, permission => {
          if (!PERMISSIONS_OBJ[permission.key]) {
            PERMISSIONS_LIST.push(permission);
            PERMISSIONS_OBJ[permission.key] = true;
          }
        });
      });
      return PERMISSIONS_LIST;
    },
  },
};

export function checkPermissions(perm) {
  return new Promise(resolve => {
    getProfile().then(
      response => {
        if (!response || !isArray(response.permissions)) {
          resolve(false);
        } else {
          resolve(_checkPermissionsWithArray({ perm, permArray: response.permissions }));
        }
      },
      () => {
        resolve(false);
      }
    );
  });
}

export function checkPermissionsSync({ perm, permArray }) {
  if (isUndefined(perm) || (isArray(perm) && !perm.length)) {
    return true;
  }
  let permArrayLocal = [];
  if (permArray) {
    permArrayLocal = permArray;
  } else {
    const ME = store.state.profile.ME;
    if (ME && ME.permissions) {
      permArrayLocal = ME.permissions;
    }
  }
  return _checkPermissionsWithArray({ perm, permArray: permArrayLocal });
}

function _checkPermissionsWithArray({ perm, permArray }) {
  if (isString(perm)) {
    return _checkSinglePermissions({ perm, permArray });
  } else if (isArray(perm)) {
    return _checkArrayPermissions({ perm, permArray });
  }
  return false;
}

function _checkArrayPermissions({ perm, permArray }) {
  for (let i = 0; i < perm.length; i++) {
    const CURRENT_PERM = perm[i];
    if (permArray.indexOf(CURRENT_PERM) === -1) {
      return false;
    }
  }
  return true;
}

function _checkSinglePermissions({ perm, permArray }) {
  return isArray(permArray) && indexOf(permArray, perm) !== -1;
}

export function _checkPermissionsApi({ perms, content_type, pk }) {
  const PROMISE = new Promise((resolve, reject) => {
    getHttp({
      url: `generics/${ content_type }/objekte/${ pk }/permissions/`,
      urlParams: {
        permissions: perms
      }
    }).then(
      response => resolve(response.data),
      err => reject(err)
    );
  });
  return PROMISE;
}
