import BetragsrechnerMixin from "../Mixins/BetragsrechnerMixin";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import {
  get,
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "Stueckkosten",
  mixins: [
    BetragsrechnerMixin,
    HttpMixin,
  ],
  emits: [
    "changeModel",
  ],
  data() {
    return {
      statusLoadKeinheit: true,
      einheitList: [],
    };
  },
  computed: {
    optionsTextfeld1() {
      return {
        id: "textfeld_1",
        htmlId: this.htmlIdTextfeld1,
        label: this.modelParameter.label_textfeld_1,
        view: this.view,
        type: "text",
        maxlength: 300,
        required: true,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdTextfeld1() {
      return `${ this.htmlId }_textfeld_1`;
    },

    optionsTextfeld2() {
      return {
        id: "textfeld_2",
        htmlId: this.htmlIdTextfeld2,
        label: this.modelParameter.label_textfeld_2,
        view: this.view,
        type: "text",
        maxlength: 300,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdTextfeld2() {
      return `${ this.htmlId }_textfeld_2`;
    },

    optionsTextfeld3() {
      return {
        id: "textfeld_3",
        htmlId: this.htmlIdTextfeld3,
        label: this.modelParameter.label_textfeld_3,
        view: this.view,
        type: "text",
        maxlength: 300,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdTextfeld3() {
      return `${ this.htmlId }_textfeld_3`;
    },

    optionsEinheit() {
      return {
        id: "einheit",
        htmlId: this.htmlIdEinheit,
        label: this.modelParameter.label_einheit,
        view: this.view,
        type: "select",
        keyId: "pk",
        keyLabel: "ein_kbez",
        search: true,
        searchList: ["ein_kbez"],
        data: this.einheitList,
        disabled: this.einheitList.length === 1,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdEinheit() {
      return `${ this.htmlId }_einheit`;
    },

    optionsAnzahlEinheiten() {
      return {
        id: "anzahl_einheiten",
        htmlId: this.htmlIdAnzahlEinheiten,
        label: this.modelParameter.label_anzahl_einheiten,
        view: this.view,
        type: "float",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdAnzahlEinheiten() {
      return `${ this.htmlId }_anzahl_einheiten`;
    },

    optionsStueckkosten() {
      return {
        id: "stueckkosten",
        htmlId: this.htmlIdStueckkosten,
        label: this.modelParameter.label_stueckkosten,
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 9,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdStueckkosten() {
      return `${ this.htmlId }_stueckkosten`;
    },

    einheiten() {
      return get(this.extra, "modelParameter.einheiten", []);
    },

    modelParameter() {
      return get(this.extra, "modelParameter", {});
    },

    errorLabels() {
      return {
        textfeld_1: {
          label: this.modelParameter.label_textfeld_1,
          link: true,
          id: this.htmlIdTextfeld1,
        },
        textfeld_2: {
          label: this.modelParameter.label_textfeld_2,
          link: true,
          id: this.htmlIdTextfeld2,
        },
        textfeld_3: {
          label: this.modelParameter.label_textfeld_3,
          link: true,
          id: this.htmlIdTextfeld3,
        },
        einheit: {
          label: this.modelParameter.label_einheit,
          link: true,
          id: this.htmlIdEinheit,
        },
        anzahl_einheiten: {
          label: this.modelParameter.label_anzahl_einheiten,
          link: true,
          id: this.htmlIdAnzahlEinheiten,
        },
        stueckkosten: {
          label: this.modelParameter.label_stueckkosten,
          link: true,
          id: this.htmlIdStueckkosten,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
    this.loadKeinheit();
  },
  methods: {
    loadKeinheit() {
      const API_SAVE_ID = this.einheiten.join("_");
      this.getListHttp({
        url: "katalog/",
        urlParams: {
          key: "keinheit",
          pk: this.einheiten,
        },
        apiSaveId: API_SAVE_ID,
      }).then(
        response => {
          this.einheitList = response;
          if (this.einheitList.length === 1) {
            const MODEL = cloneDeep(this.model);
            MODEL.argument = MODEL.argument || {};
            MODEL.argument.einheit = this.einheitList[0].pk;
            this.$emit("changeModel", { model: MODEL });
          }
          this.statusLoadKeinheit = false;
        }
      );
    },
  },
};
