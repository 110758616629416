import Alert from "../../../global/components/Alert/Alert.vue";
import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import AsideRightBox from "../../../global/components/AsideRightBox/AsideRightBox.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spaced from "../../../global/components/Spaced/Spaced.vue";
import TrackingDetailsMilestone from "../TrackingDetailsMilestone/TrackingDetailsMilestone.vue";
import TrackingFilter from "../TrackingFilter/TrackingFilter.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import TrackingObjectsMixin from "../TrackingObjectsMixin";

import {
  FilterDateMixin,
} from "../../../global/mixins/FiltersMixin";

import {
  setUrlWithParams,
} from "../../../global/functions/help";
import {
  cloneDeep,
  isArray,
  isString,
  lowerCase,
  reverse,
} from "lodash-es";

// @vue/component
export default {
  name: "TrackingDetails",
  components: {
    Alert,
    AngularLink,
    AsideRightBox,
    PuxPageDetails,
    PuxTranslate,
    Spaced,
    TrackingDetailsMilestone,
    TrackingFilter,
  },
  mixins: [
    FilterDateMixin,
    HttpMixin,
    TrackingObjectsMixin,
  ],
  data() {
    return {
      isLoading: true,
      isReloading: false,
      isUrlCorrect: true,
      objectFromResponse: {},
      milestones: [],
      urlParameters: {},
      asideBoxTrackingOptions: {
        name: "_TXT_TRACKING_ASIDE_HEADER_FILTER_",
        icon: "glyphicon-filter",
        active: true,
      },
    };
  },
  computed: {
    keysForFilterObjects() {
      return this.urlParameters.objects;
    },

    currentObject() {
      return this.objectsByValue[this.urlParameters.object];
    },

    numberFromObjectFromResponse() {
      return this.objectFromResponse[this.currentObject.numberKey];
    },

    isCurrentObjectAuszahlung() {
      return this.urlParameters.object === "auszahlung";
    },

    labelH2ForCurrentObject() {
      if (this.isCurrentObjectAuszahlung) {
        const NAME = lowerCase(this.objectFromResponse.name);
        return this.currentObject.h2[NAME];
      }
      return this.currentObject.h2;
    },

    labelH4ForCurrentObject() {
      if (this.isCurrentObjectAuszahlung) {
        const NAME = lowerCase(this.objectFromResponse.name);
        return this.currentObject.h4[NAME];
      }
      return this.currentObject.h4;
    },

    isLastMilestoneReached() {
      return this.lastMilestone.erreicht;
    },

    lastMilestone() {
      return this.milestones[0];
    },

    textForDateLastMilestoneTranslate() {
      if (this.lastMilestoneDay < 11) {
        return "_TXT_TRACKING_VORAUSSICHTLICH_BIS_ANFANG_{{datum}}_";
      }
      if (this.lastMilestoneDay < 21) {
        return "_TXT_TRACKING_VORAUSSICHTLICH_BIS_MITTE_{{datum}}_";
      }
      return "_TXT_TRACKING_VORAUSSICHTLICH_BIS_ENDE_{{datum}}_";
    },

    lastMilestoneDay() {
      return +this.filterDate(this.lastMilestone.datum, "D");
    },

    extraForDateLastMilestoneTranslate() {
      return {
        datum: this.filterDate(this.lastMilestone.datum, "MMMM YYYY"),
      };
    },
  },
  created() {
    this.setUrlParameters(this.$stateParams);
    this.loadObject();
  },
  methods: {
    setUrlParameters(urlParams) {
      const STATE_PARAMS = cloneDeep(urlParams) || {};
      let objects = [];
      if (isArray(STATE_PARAMS.objects)) {
        objects = STATE_PARAMS.objects;
      } else if (isString(STATE_PARAMS.objects)) {
        objects = [STATE_PARAMS.objects];
      }
      this.urlParameters = {
        object: STATE_PARAMS.object,
        number: STATE_PARAMS.number,
        objects,
      };
    },

    loadObject() {
      if (!this.currentObject) {
        this.setError();
        return;
      }
      this.getListHttp({
        url: `${ this.currentObject.url }`,
        urlParams: {
          [this.currentObject.numberKey]: this.urlParameters.number,
          limit: 1,
          fields: this.currentObject.fields,
        },
      }).then(
        response => {
          this.setObjectFromResponse(response);
          this.loadTrackingInfo();
        },
        () => {
          this.setError();
        }
      );
    },

    setObjectFromResponse(response = []) {
      if (response.length) {
        this.objectFromResponse = response[0];
      } else {
        this.objectFromResponse = {};
      }
    },

    loadTrackingInfo() {
      if (!this.objectFromResponse.pk) {
        this.setError();
        return;
      }
      this.getHttp({
        url: `${ this.currentObject.url }${ this.objectFromResponse.pk }/meilensteine/`,
      }).then(
        response => {
          this.setMilestonesWithRevers(response);
          this.setLoading(false);
          this.setReloading(false);
        }
      );
    },

    setMilestonesWithRevers(response) {
      this.milestones = reverse(response);
    },

    setLoading(isLoading) {
      this.isLoading = isLoading;
    },

    setReloading(isReloading) {
      this.isReloading = isReloading;
    },

    setError() {
      this.setUrlWrong();
      this.setLoading(false);
      this.setObjectFromResponse();
      this.setMilestonesEmpty();
    },

    setUrlWrong() {
      this.isUrlCorrect = false;
    },

    setMilestonesEmpty() {
      this.milestones = [];
    },

    onSearchSuccess({ objectNumber, objectName }) {
      this.setUrlParameters({
        object: objectName,
        number: objectNumber,
        objects: this.urlParameters.objects,
      });
      this.updateUrl();
      this.setReloading(true);
      this.loadObject();
    },

    updateUrl() {
      const URL = setUrlWithParams({
        params: this.urlParameters,
      });
      this.$locationReplace(URL);
    },
  },
};
