"use strict";
//////////////////////////////////////////////////
// Achtung!!!!!!!!!
// Das ist schon in Vue.js
/////////////////////////////////////////////////
import builder from "../utils/builder.js";

import template from "./detail.jade";
import EditController from "../standard_sitzungsmodule.edit.controller";

import protrokollViewTemplate from "../utils/sitzungsantrag/protokoll.modal.view.jade";
import protrokollViewController from "../utils/sitzungsantrag/protokoll.modal.view.controller";
import protrokollEditTemplate from "../utils/sitzungsantrag/protokoll.modal.edit.jade";
import protrokollEditController from "../utils/sitzungsantrag/protokoll.modal.edit.controller";

import entscheidenModalTemplate from "../utils/sitzungsantrag/entscheiden.modal.jade";
import entscheidenModalController from "../utils/sitzungsantrag/entscheiden.modal.controller";
import entscheidungAnmerkungViewTemplate from "../utils/sitzungsantrag/entscheidungAnmerkung.modal.edit.jade";
import entscheidungAnmerkungViewController from "../utils/sitzungsantrag/entscheidungAnmerkung.modal.edit.controller";

class AutomatischeAntragslisteDetailController extends EditController {
  /*@ngInject*/
  constructor(
    $scope,
    $q,
    $filter,
    $stateParams,
    Restangular,
    AuthService,
    diasConfirmDialog,
    diasModalDialog,
    DiasNotification,
    Katalog,
    ListenstatusUUIDs,
    SyConfigs,
    i18n,
    FoerderorgTyp
  ) {
    super($scope, $q);
    this.tableName = "sitzungslisteantrag";
    this.$filter = $filter;
    this.Restangular = Restangular;
    this.AuthService = AuthService;
    this.DiasNotification = DiasNotification;
    this.diasConfirmDialog = diasConfirmDialog;
    this.diasModalDialog = diasModalDialog;
    this.Katalog = Katalog;
    this.ListenstatusUUIDs = ListenstatusUUIDs;
    this.gettext = i18n.gettext;
    this.FoerderorgTyp = FoerderorgTyp;

    this.monetaereFoerderung = SyConfigs.wert("monetaere_foerderung");
    this.antragConfig = SyConfigs.wert("foerderantrag") || {};
    let conf = SyConfigs.wert("tabellenkonfig");
    conf = conf ? conf.tabellenkonfig : {};
    conf = conf[this.tableName] || {};
    this.tabellenConfig = conf;

    // this.loadData().finally(() => {
    //   this.prepareAntraegeTable();
    //   this.hasViewPermission = this.canView();
    // });
    this.sitzungPk = $stateParams.id;
  }

  canDecide() {
    return this.AuthService.syncHasPerm("tops.antrag.decide", this.list.user_permissions);
  }

  canProtocol() {
    return this.AuthService.syncHasPerm("sitzungen.protokoll.update", this.list.user_permissions) ||
           this.AuthService.syncHasPerm("sitzungen.protokoll.view", this.list.user_permissions);
  }

  canRank() {
    return this.AuthService.syncHasPerm("tops.antrag.rank", this.list.user_permissions);
  }

  canView() {
    return this.AuthService.syncHasPerm("tops.antrag.view", this.list.user_permissions);
  }

  loadData() {
    return this.$q.all([
      this.top.listen.one(this.userInput.sl_id).get(),
      this.Katalog.getKatalog("listenstatus"),
      this.Katalog.getKatalog("entscheidungsstatus", { gruppe: this.parameter.entscheidungsstatusgruppe })
    ]).then(
      responses => {
        this.list = responses[0];
        this.listenstatus = responses[1];
        this.entscheidungsstatus = responses[2];
        this.entscheidungsAktionen = this.createEntscheidungsAktionen();
        this.priorisierungAktionen = this.createPriorisierungsAktionen();
        this.protokollierungAktionen = this.createProtokollierungAktionen();
      }
    );
  }

  createEntscheidungsAktionen() {
    const actions = [];
    if (!this.canDecide()) {
      return actions;
    }
    const createSetEntscheidungCallback = status => {
      return row => this.setEntscheidung(row, status);
    };
    for (let i = 0; i < this.entscheidungsstatus.length; i++) {
      actions[this.entscheidungsstatus[i].pos - 1] = {
        label: this.entscheidungsstatus[i].est_aktion_bez,
        callback: createSetEntscheidungCallback(this.entscheidungsstatus[i].pk),
      };
    }
    actions.push({
      label: this.gettext("Auflage erfassen"),
      callback: row => this.diasModalDialog({
        title: "Auflage für Antrag " + this.$filter("spaced")(row.antrag.a_nr, true) + " erfassen",
        template: entscheidungAnmerkungViewTemplate,
        controller: entscheidungAnmerkungViewController,
        extras: { sla: row }
      }),
    });
    return actions;
  }

  createProtokollierungAktionen() {
    const actions = [];
    if (this.AuthService.syncHasPerm("sitzungen.protokoll.update", this.list.user_permissions)) {
      actions.push({
        label: this.gettext("Protokoll bearbeiten"),
        callback: row => this.diasModalDialog({
          title: "Protokoll für Antrag " + this.$filter("spaced")(row.antrag.a_nr, true),
          template: protrokollEditTemplate,
          controller: protrokollEditController,
          extras: { sla: row }
        })
      });
    } else if (this.AuthService.syncHasPerm("sitzungen.protokoll.view", this.list.user_permissions)) {
      actions.push({
        label: this.gettext("Protokoll einsehen"),
        callback: row => this.diasModalDialog({
          title: "Protokoll für Antrag " + this.$filter("spaced")(row.antrag.a_nr, true),
          template: protrokollViewTemplate,
          controller: protrokollViewController,
          extras: { sla: row }
        })
      });
    }
    return actions;
  }

  createPriorisierungsAktionen() {
    return [
      { label: this.gettext("Als ersten Vorzulegenden setzen"),
        callback: row => this.moveToFirstVorgesehen(row) },
      { label: this.gettext("Als letzten Vorzulegenden setzen"),
        callback: row => this.setLastVorgesehen(row) },
      null,
      { label: this.gettext("Als ersten Ersatzantrag setzen"),
        callback: row => this.setFirstErsatz(row) },
      { label: this.gettext("Als letzten Ersatzantrag setzen"),
        callback: row => this.moveToLastErsatz(row) },
      null,
      { label: this.gettext("Als nicht vorzulegen setzen"),
        callback: row => this.setNichtVorgesehen(row) },
    ];
  }

  makeSaveRequest() {
    return this.getDataSource().put_priorisiert(this.userInput.priorisiert).then(
      response => {
        this.lastChange = new Date().getTime();
        return { data: {
          festgeschrieben: response.sl_festgeschrieben,
          priorisiert: response.sl_priorisiert,
          sl_id: response.pk
        } };
      }
    );
  }

  setPriorisierung(newValue) {
    const actionStr = (newValue ? "abschließen" : "wieder starten");
    const doneStr = (newValue ? "abgeschlossen" : "wieder gestartet");

    this.diasConfirmDialog({
      title: this.gettext("Priorisierung ") + actionStr,
      contentTemplate: `<p>Sind Sie sicher, dass Sie die Priorisierung ` + actionStr + ` möchten?</p>`,
      okCallback: () => {
        const oldValue = this.userInput.priorisiert;
        this.userInput.priorisiert = newValue;
        this.save().then(
          () => {
            this.DiasNotification.page.success("Priorisierung erfolgreich " + doneStr);
          },
          error => {
            this.userInput.priorisiert = oldValue;
            this.DiasNotification.page.error(error);
          }
        );
      }
    });
  }

  getDataSource() {
    let dataSource = this.top.listen.one(this.userInput.sl_id);
    if (this.userInput.festgeschrieben) {
      dataSource = dataSource.festgeschrieben;
    } else {
      dataSource = dataSource.dynamisch;
    }
    return dataSource;
  }

  prepareAntraegeTable() {
    const dataSource = this.getDataSource().getList;
    const antraegeTableDeferred = this.$q.defer();
    this.antraegeTable = antraegeTableDeferred.promise;
    this.prepareOrgColumns().then(
      () => {
        const cols = [
          ...this.getStatusColumn(),
          { label: this.gettext("Pos."), field: "sla_pos", cellClass: "text-right" },
          { label: this.gettext("Antrag-Nr."), field: "antrag.a_nr", cellTemplate: `
            <a ui-sref="root.sitzungsantraege.details({id: '{{row.pk}}'})" ng-if="!vm.dndActive && row.pk">
              <spaced ng-model="row.antrag.a_nr"/>
            </a>
            <spaced ng-if="vm.dndActive || !row.pk" ng-model="row.antrag.a_nr"/>` },
          ...this.getExterneANrColumn(),
          { label: this.gettext("Titel"), field: "antrag.a_titel" },
          { label: this.gettext("Antragsteller"), field: "antrag.antragstellerorg_obj.ast_name" },
          { label: this.gettext("Betrag"), cellTemplate: `{{row.antrag.a_zuschussbeantragt | zeroNumber | currency }}`, cellClass: "text-right" },
          { label: this.gettext("Förderangebot"), field: "antrag.regelsatz_kbez" },
          { label: this.gettext("Eingereicht am"), cellTemplate: `{{row.antrag.a_antragsdatum | date:'shortDate' }}`, cellClass: "no-min-width" },
          ...this.getZuschussColumn(),
          ...this.foeorg_columns,
          ...this.getAnmerkungColumn(),
        ];

        antraegeTableDeferred.resolve({
          dataSource: dataSource,
          onRefreshData: list => {
            list.refreshListeninfo(list.tableState.pagination.number, list.tableState.pagination.start);
          },
          columnDefs: cols,
          rowClass: row => {
            if (row.listenstatus === this.ListenstatusUUIDs.nichtVorgesehen) {
              row.listenstatus_obj = this.listenstatus.getById(this.ListenstatusUUIDs.nichtVorgesehen);
              return "kreuz";
            }
            if (row.sumUpTo() > this.top.tos_bereitgestellt) {
              row.listenstatus_obj = this.listenstatus.getById(this.ListenstatusUUIDs.ersatz);
              return "frage";
            }
            row.listenstatus_obj = this.listenstatus.getById(this.ListenstatusUUIDs.vorgesehen);
          },
          dndDisabled: () => {
            return !this.canRank() || !this.parameter.ranking || this.top.tos_bereitgestellt > this.top.tos_bedarf || !this.userInput.festgeschrieben || this.userInput.priorisiert;
          },
          dndDrop: (event, index, row, type, external, list) => {
            list.restangularizeAntrag(row);
            let pos = list[0].sla_pos;
            if (index > 0) {
              pos = list[index - 1].sla_pos + 1;
            }
            if (pos > row.sla_pos) {
              pos--;
            }
            if (pos !== row.sla_pos) {
              this.handleMovePromise(row.sendDrop(pos));
            }
            return row;
          },
          // Umweg über (row) => this.func(row), damit der scope des "this" erhalten bleibt
          [this.isPriorisierungActive() || this.canDecide() || this.canProtocol() ? "actions" : ""]: this.getRowActions(),
          isDirty: scope => this.lastChange > scope.vm.lastRefresh,
          // Tabellenaktionen
          tableActions: [
            { label: this.gettext("Restliche Anträge entscheiden"),
              icon: "glyphicon glyphicon-bearbeiten-2", primary: true,
              callback: () => this.setRestlicheEntscheidungen(),
              isVisible: () => this.canDecide() && this.userInput.festgeschrieben,
              className: "test_restliche_antraege_entscheiden", },
            { label: this.gettext("Priorisierung abschließen"),
              icon: "glyphicon glyphicon-check", primary: true,
              callback: () => this.setPriorisierung(true),
              isVisible: () => this.canRank() && this.userInput.festgeschrieben && !this.userInput.priorisiert },
            { label: this.gettext("Priorisierung wieder starten"),
              icon: "glyphicon glyphicon-unchecked", primary: true,
              callback: () => this.setPriorisierung(false),
              isVisible: () => this.canRank() && this.userInput.festgeschrieben && this.userInput.priorisiert }
          ],
          tableName: this.tableName,
          extra: {
            orgtypen: this.orgtypen,
            renderFoerderorg: (ast, typ) => {
              let result = "";
              if (ast.foerderorghierarchie && ast.foerderorghierarchie.length > 0) {
                result = ast.foerderorghierarchie.filter(item => item.o_otid === typ).map(item => item.o_kbez).join(", ");
              }
              return result;
            }
          },
        });
      }
    );
  }

  getExterneANrColumn() {
    if (this.antragConfig.externe_nummern) {
      return [{
        label: this.gettext("Externe Nummer"),
        cellTemplate: `{{ row.antrag.a_nr_extern }}`
      }];
    }
    return [];
  }

  getZuschussColumn() {
    if (!this.monetaereFoerderung) {
      return [];
    }
    return [
      { label: this.gettext("Beantragt"), cellTemplate: `{{row.antrag.a_zuschussbeantragt | zeroNumber | currency }}`, cellClass: "text-right" },
    ];
  }

  prepareOrgColumns() {
    this.orgtypen = [];
    this.foeorg_columns = [];
    const orgConf = this.tabellenConfig.org_columns || {};
    const orgHierarchie = orgConf.foerderorghierarchie || [];
    let otypPromise;
    if (orgHierarchie.length > 0) {
      otypPromise = this.FoerderorgTyp.getList();
      otypPromise.then(result => {
        angular.forEach(result, item => {
          if (orgHierarchie.indexOf(item.pk) !== -1) {
            this.foeorg_columns.push({
              label: item.ot_kbez,
              cellClass: "text-left",
              field: `foerderorghierarchie_${ item.pk }`,
              cellTemplate: "{{:: vm.extra.renderFoerderorg(row.antrag.antragstellerorg_obj, '" + item.pk + "')}}",
            });
          }
        });
        this.orgtypen = result;
      });
    } else {
      const defer = this.$q.defer();
      otypPromise = defer.promise;
      defer.resolve();
    }
    return otypPromise;
  }

  getStatusColumn() {
    if (!this.userInput.festgeschrieben) {
      return [];
    }
    if (this.canRank()) {
      return [{
        label: this.gettext("Status"),
        cellTemplate: "<icon-listenstatus title='{{row.listenstatus_obj.lst_kbez}}' uuid='row.listenstatus_obj.pk'></icon-listenstatus>",
        cellClass: "status"
      }];
    }
    return [{
      label: "",
      cellTemplate: "",
      cellClass: "status listenstatus-border",
      cellTitle: row => row.listenstatus_obj.lst_kbez
    }, {
      label: this.gettext("Status"),
      cellTemplate: `
        <span ng-class="{'anmerkung': row.sla_entscheidung_anmerkung}" bs-tooltip data-trigger="hover" data-title="{{row.entscheidungsstatus_obj.est_kbez}}<br>{{row.sla_entscheidung_anmerkung}}" data-placement="right" data-html="true">
          <span ng-class="[row.entscheidungsstatus_obj.est_cssklasse, row.entscheidungsstatus_obj.est_icon]"></span>
        </span>`,
      cellClass: "status"
    }];
  }

  getAnmerkungColumn() {
    if (!this.canDecide()) {
      return [];
    }
    const coll = [
      {
        label: this.gettext("Anmerkung"),
        field: "",
        cellTemplate: `
          <div ng-if="row.sla_protokoll" class="anmerkung">
            <span class="badge" data-toggle="tooltip" data-placement="top" data-original-title="{{row.sla_protokoll}}" tooltip data-html="true" tabindex="0">Protokoll</span>
          </div>
          <div ng-if="row.sla_entscheidung_anmerkung" class="anmerkung">
            <span class="badge" data-toggle="tooltip" data-placement="top"  data-original-title="{{row.sla_entscheidung_anmerkung}}" tooltip data-html="true" tabindex="0">Auflage</span>
          </div>`
        /* cellTemplate: `
          <div ng-if="row.sla_protokoll" class="anmerkung">
            <span class="badge" bs-tooltip data-trigger="hover" data-title="{{row.sla_protokoll}}" data-placement="top" data-html="true">Protokoll</span>
          </div>
          <div ng-if="row.sla_entscheidung_anmerkung" class="anmerkung">
            <span class="badge" bs-tooltip data-trigger="hover" data-title="{{row.sla_entscheidung_anmerkung}}" data-placement="right" data-html="true">Auflage</span>
          </div>`*/
      }
    ];
    return coll;
  }

  getRowActions() {
    return () => {
      const actions = [];
      if (this.isPriorisierungActive()) {
        actions.push(...this.priorisierungAktionen);
      }
      if (this.canDecide()) {
        actions.push(...this.entscheidungsAktionen);
      }
      if (this.canProtocol()) {
        actions.push(...this.protokollierungAktionen);
      }
      return actions;
    };
  }

  setEntscheidung(row, uuid) {
    this.loading = true;
    return row.setEntscheidung(uuid).then(
      () => {
        this.DiasNotification.page.success("Antrag erfolgreich als " + this.entscheidungsstatus.getById(uuid).bez + " gesetzt.");
        this.emitEvent(this.EVENT_ACTION_UPDATED);
      },
      () => this.DiasNotification.page.error("Beim speichern der Entscheidung ist ein Fehler aufgetreten.")
    ).finally(() => this.loading = false);
  }

  setRestlicheEntscheidungen() {
    this.diasModalDialog({
      title: this.gettext("Restliche Anträge entscheiden"),
      template: entscheidenModalTemplate,
      controller: entscheidenModalController,
      extras: { sl: this.getDataSource(),
                entscheidungsstatus: this.entscheidungsstatus }
    }).then(() => {
      this.lastChange = new Date().getTime();
      this.emitEvent(this.EVENT_ACTION_UPDATED);
    });
  }

  isPriorisierungActive() {
    return this.canRank() && this.userInput.festgeschrieben && !this.userInput.priorisiert;
  }

  moveToFirstVorgesehen(row) {
    return this.setzeAnPos(row, 1);
  }

  setLastVorgesehen(row) {
    return this.handleMovePromise(row.sendSetzeVorgesehen());
  }

  setFirstErsatz(row) {
    return this.handleMovePromise(row.sendSetzeErsatz());
  }

  moveToLastErsatz(row) {
    const pos = row.calcPosAsLast();
    return this.setzeAnPos(row, pos);
  }

  setNichtVorgesehen(row) {
    this.loading = true;
    return row.sendSetzeNichtVorgesehen().then(
      () => {
        this.DiasNotification.page.success("Antrag erfolgreich ausgeschlossen.");
        this.emitEvent(this.EVENT_ACTION_UPDATED);
      },
      () => this.DiasNotification.page.error("Beim Ausschließen des Antrags ist ein Fehler aufgetreten.")
    ).finally(() => this.loading = false);
  }

  isNotNichtVorgesehen(row) {
    return row.listenstatus !== this.ListenstatusUUIDs.nichtVorgesehen;
  }

  setzeAnPos(row, pos) {
    return this.handleMovePromise(row.sendMove(pos));
  }

  handleMovePromise(promise) {
    this.loading = true;
    return promise.then(
      response => {
        this.DiasNotification.page.success("Antrag erfolgreich an Position " + response.sla_pos + " verschoben.");
        this.emitEvent(this.EVENT_ACTION_UPDATED);
      },
      () => this.DiasNotification.page.error("Beim Verschieben des Antrags ist ein Fehler aufgetreten.")
    ).finally(() => this.loading = false);
  }
}

export default builder.detail({
  template: template(),
  controller: AutomatischeAntragslisteDetailController,
  controllerAs: "vm"
});
