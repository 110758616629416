import NachrichtenList from "../NachrichtenList/NachrichtenList.vue";

const TABLE_ID = "benachrichtigungen_outbox";

// @vue/component
export default {
  name: "NachrichtenOutboxList",
  components: {
    NachrichtenList
  },
  data() {
    return {
      tableId: TABLE_ID,
      tableHeader: "_TXT_NACHRICHTEN_OUTBOX_LIST_HEADER_",
      pageHeader: "_TXT_NACHRICHTEN_OUTBOX_PAGE_HEADER_",
      rootDetails: "root.benachrichtigungen_out.detail",
      pageTitle: "_PAGE_TITLE_NACHRICHTEN_OUTBOX_LIST_"
    };
  },
};
