// Modul für die Ausgabe der abhängigen Kostenpauschale
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const abhaengige_kostenpauschale = angular.module("dias.abhaengige_kostenpauschale", [
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
]);

abhaengige_kostenpauschale.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("abhaengige_kostenpauschale", "modul-abhaengige-kostenpauschale");
})

  .directive("modulAbhaengigeKostenpauschale", builder.directive(template, controller));

export default abhaengige_kostenpauschale;
