// Modul zur Erfassung von einfachen Kosten
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";


import builder from "dias/standard_foerdermodule/utils/builder";


const standard_kosten = angular.module("dias.kosten_finanzierung.modul.kosten_summenzeile", [
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
]);

standard_kosten.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("kosten_summenzeile", "modul-kosten-summenzeile");
})

  .directive("modulKostenSummenzeile", builder.directive(template, controller));

export default standard_kosten;
