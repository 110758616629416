"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";
import diasModal from "dias/core/modal";
import diasCoreServices from "dias/core/services";
import diasAuth from "dias/auth/services";
import diasNotification from "dias/core/notification";
import FoerdermoduleModels from "dias/foerderantrag/foerdermodule/models";

import FoerderregelnModels from "./models";
import parameter from "./parameter";
import detailsComponent from "./details.component";

const foerderregelnModule = angular.module("foerderregeln", [
  uiRouter,
  diasAuth.name,
  diasModal.name,
  diasCoreServices.name,
  diasNotification.name,
  FoerderregelnModels.name,
  FoerdermoduleModels.name,
  parameter.name,
]);

foerderregelnModule.config($stateProvider => {
  $stateProvider

    // Liste
    .state("root.foerderregeln", {
      url: `/foerderregeln/?
            user_cookie&
            anzeigekontext&
            foerderinstrumentregel__instrument&
            foerderinstrumentvarianteregel__instrumentvariante&
            modul
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-geschaeftsregel-list></vue-geschaeftsregel-list></div>"
        }
      },
      data: {
        permissions: "foerderregel.update"
      }
    })
    .state("root.foerderregeln1", {
      url: `/foerderregeln1/?
            user_cookie&
            anzeigekontext&
            foerderinstrumentregel__instrument&
            foerderinstrumentvarianteregel__instrumentvariante&
            modul
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-geschaeftsregel-list></vue-geschaeftsregel-list></div>"
        }
      },
      data: {
        permissions: "foerderregel.update"
      }
    })
    // Detailseite
    // Sollte immer als letztes eingefügt werden, sonst werden Sub-URLs
    // as ID aufgelöst
    .state("root.foerderregeln.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><geschaeftsregel-details></geschaeftsregel-details></div>"
        }
      },
    })
    .state("root.foerderregeln1.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<foerderregeln-details></foerderregeln-details>"
        }
      },
    })
  ;
})

  .component("foerderregelnDetails", detailsComponent)

;
export default foerderregelnModule;
