"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import diasAuthService from "dias/auth/services";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";
import diasParameter from "dias/foerderregeln/parameter";

import models from "./models";
import serviceListComponent from "./list.servicelog.component";


const logModule = angular.module("core.log", [
  uiRouter,
  models.name,
  diasAuthService.name,
  diasParameter.name,
  diasTable.name,
  diasLoading.name,
  diasNotification.name
]);

logModule.config($stateProvider => {
  $stateProvider
    .state("root.servicelog", {
      url: "/log/service/?" +
          "slo_kontext&slo_request&slo_response&slo_fehler",
      views: {
        "main@root": {
          template: "<div vueinit><vue-log-list></vue-log-list></div>"
        }
      },
      data: {
        permissions: ["log.service.view"]
      },
    })
    // Sitzungsantrag-Liste
    .state("root.servicelog1", {
      url: "/log/service1/?" +
           "slo_kontext&slo_request&slo_response&slo_fehler",
      views: {
        "main@root": {
          template: "<service-log-list></service-log-list>"
        }
      },
      data: {
        permissions: ["log.service.view"]
      },
    })
  ;
})

  .component("serviceLogList", serviceListComponent)
;

export default logModule;
