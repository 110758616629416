import {
  computed,
  toRefs,
} from "vue";

import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import { cloneDeep } from "lodash-es";

// @vue/component
export default {
  name: "SitzungDetailsProtokoll",
  components: {
    FormElement,
    FormElementReadOnly,
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    sitzung: {
      type: Object,
      required: true,
    },
    sitzungUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      sitzung,
    } = toRefs(props);

    const {
      getHttp,
      putHttp,
    } = HttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    const hasWFPermissionSitzungProtokollUpdate = computed(() => {
      return checkPermissionsSync({
        perm: "sitzungen.protokoll.update",
        permArray: sitzung.value.user_permissions,
      });
    });

    return {
      getHttp,
      hasWFPermissionSitzungProtokollUpdate,
      putHttp,
    };
  },
  data() {
    return {
      loading: true,
      protokoll: undefined,
      model: undefined,
      errors: undefined,
    };
  },
  computed: {
    optionsForProtokoll() {
      return {
        id: "si_protokoll",
        type: "richtext",
        label: "_LBL_SITZUNGSDETAILS_PROTOKOLL_",
        required: true,
        view: "v-alt",
      };
    },

    optionsFormstepDetail() {
      return {
        label: "_LBL_SITZUNGSDETAILS_PROTOKOLL_",
        openCallback: this.open,
        saveCallback: this.save,
        closeCallback: this.close,
        required: true,
      };
    },

    errorsLabelsOptions() {
      return [this.optionsForProtokoll];
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getHttp({
        url: `${ this.sitzungUrl }protokoll_ansehen/`,
      }).then(
        response => {
          this.protokoll = response;
        }
      ).finally(
        () => this.loading = false,
      );
    },

    open() {
      this.deleteErrors();
      this.model = cloneDeep(this.protokoll);
    },

    close() {
      this.deleteErrors();
    },

    save() {
      this.loading = true;
      return this.putHttp({
        url: `${ this.sitzungUrl }protokoll_bearbeiten/`,
        data: { si_protokoll: this.model.si_protokoll },
      }).then(
        response => {
          this.protokoll = response;
          return Promise.resolve(response);
        },
        errors => {
          this.onErrors(errors);
          return Promise.reject(errors);
        }
      ).finally(
        () => this.loading = false,
      );
    },

    onErrors(errors) {
      this.errors = errors.data;
    },

    deleteErrors() {
      this.errors = undefined;
    },
  }
};
