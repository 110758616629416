import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import NachrichtenLink from "../../Nachrichten/NachrichtenLink/NachrichtenLink.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spaced from "../../../global/components/Spaced/Spaced.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import KommunikationsdatenUUIDs from "../../../const/KommunikationsdatenUUIDs";
import {
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungListDetails",
  components: {
    AngularLink,
    NachrichtenLink,
    Permission,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
    Spaced,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    auszahlung: {
      type: Object,
      default: () => ({}),
    },
    replace: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const {
      filterCurrency,
      filterDate,
      filterDefaultForEmpty,
    } = FiltersAPI();

    return {
      filterCurrency,
      filterDate,
      filterDefaultForEmpty,
    };
  },
  data() {
    return {
      statusLoading: true,
    };
  },
  computed: {
    getFilteredKommunikationsdaten() {
      return kommunikationsdaten => filter(kommunikationsdaten, item => item.kommtyp && item.kommtyp.kot_id === KommunikationsdatenUUIDs.telefonnr1);
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.auszahlung._details) {
        this.statusLoading = false;
        return;
      }
      this.getHttp({ url: `auszahlungen/${ this.auszahlung.pk }/vorschau/` }).then(
        response => {
          response._details = true;
          this.replace(response);
          this.statusLoading = false;
        }
      );
    },
  },
};
