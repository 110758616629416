"use strict";

// Container-Klasse für Filter-Definitionen.
class FilterSet {
  /*@ngInject*/
  constructor() {
    this.name = "FilterSet";
    this.filter = {};
    this.sortOrder = [];
    this.defaultGroup = "Sonstige";
  }

  addFilter(key, options) {
    this.filter[key] = options;
    if (this.sortOrder.indexOf(key) !== -1) {
      this.sortOrder.splice(this.sortOrder.indexOf(key), 1);
    }
    this.sortOrder.push(key);
    if (!options.forceSelected) {
      options.selected = options.initial;
    } else {
      if (options.selected && !angular.isArray(options.selected)) {
        options.selected = [options.selected];
      }
    }
    if (options.afterInit) {
      options.choicesData = [];
    } else {
      if (options.choices && angular.isDefined(options.choices.then)) {
        options.choicesData = [];
        options.choices.then(result => options.choicesData = result);
      } else {
        options.choicesData = options.choices;
      }
    }

    if (options.multiSelect) {
      options.selected = [];
    }

    if (!options.group) {
      options.group = this.defaultGroup;
    }
  }
}

export default FilterSet;
