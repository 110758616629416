import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import WorkflowsDetailsVisualisierungStateModal from "./StateModal/StateModal.vue";
import WorkflowsDetailsVisualisierungConnectionModal from "./ConnectionModal/ConnectionModal.vue";
import "jsplumb";
import {
  cloneDeep,
  forEach,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "WorkflowsDetailsVisualisierung",
  components: {
    FormstepDetail,
    PuxIcon,
    PuxButton,
    WorkflowsDetailsVisualisierungStateModal,
    WorkflowsDetailsVisualisierungConnectionModal,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    workflowPk: {
      type: String,
      required: true,
      info: "Workflow UUID",
    },
  },
  data() {
    return {
      statusLoading: true,
      statusSaving: false,
      statusModalState: false,
      statusModalConnection: false,
      statusReloading: false,
      workflow: {},
      workflowDefault: {},
      instance: undefined,
      options: {
        label: "_TXT_WF_DETAILS_PANEL_VISUALISIERUNG_",
      },
      htmlId: "workflows_details_visualisierung",
      aufgabePk: undefined,
      workflowfolgeId: undefined,
    };
  },
  computed: {
    selectorClose() {
      return `#${ this.htmlId }`;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getHttp({
        url: `workflow_editor/${ this.workflowPk }/`,
      }).then(
        response => {
          this.workflow = response;
          this.workflowDefault = cloneDeep(response);
          this.statusLoading = false;
          setTimeout(() => {
            this.setupJsPlumbInstance(cloneDeep(response));
          });
        }
      );
    },

    setupJsPlumbInstance(workflow) {
      let draggedConnection = {};
      const jsPlumbInstanceOptions = {
        DragOptions: {
          cursor: "pointer",
          zIndex: 2000
        },
        ConnectionOverlays: [
          ["Arrow", { location: 0.99 }],
        ],
        Container: "workflow_editor"
      };
      const jsPlumbEndpointOptions = {
        endpoint: ["Dot", { radius: 4 }],
        isSource: false, // we set those dynamically on assignment
        isTarget: false, // we set those dynamically on assignment
        maxConnections: 1, // this is important to make drag & drop of connections work
        connector: [
          "Flowchart",
          {
            stub: [40, 60],
            gap: 10,
            cornerRadius: 5
          }
        ],
        connectorStyle: {
          strokeWidth: 2,
          stroke: "#333",
          joinstyle: "round",
          outlineStroke: "white",
          outlineWidth: 2
        },
        connectorHoverStyle: {
          strokeWidth: 3,
          stroke: "#333",
          outlineWidth: 5,
          outlineStroke: "white"
        },
        hoverPaintStyle: {
          fill: "#333",
          stroke: "#333"
        },
        dragOptions: {}
      };
      const anchors = [
        // [x, y, anchorOrientationX, anchorOrientationY, x offset, y offset]
        [0.20, 0, 0, -1, 0, 0, "TopLeft"],
        [0.40, 0, 0, -1, 0, 0, "TopMiddleLeft"],
        [0.60, 0, 0, -1, 0, 0, "TopMiddleRight"],
        [0.80, 0, 0, -1, 0, 0, "TopRight"],
        [0.80, 1, 0, 1, 0, 0, "BottomRight"],
        [0.60, 1, 0, 1, 0, 0, "BottomMiddleRight"],
        [0.40, 1, 0, 1, 0, 0, "BottomMiddleLeft"],
        [0.20, 1, 0, 1, 0, 0, "BottomLeft"],
        [0, 0.80, -1, 0, 0, 0, "LeftLower"],
        [0, 0.60, -1, 0, 0, 0, "LeftMiddleLower"],
        [0, 0.40, -1, 0, 0, 0, "LeftMiddleUpper"],
        [0, 0.20, -1, 0, 0, 0, "LeftUpper"],
        [1, 0.20, 1, 0, 0, 0, "RightUpper"],
        [1, 0.40, 1, 0, 0, 0, "RightMiddleUpper"],
        [1, 0.60, 1, 0, 0, 0, "RightMiddleLower"],
        [1, 0.80, 1, 0, 0, 0, "RightLower"],
      ];
      /* global jsPlumb*/
      const instance = jsPlumb.getInstance(jsPlumbInstanceOptions);
      const _addEndpoints = function(toId) {
        for (let i = 0; i < anchors.length; i++) {
          const sourceUUID = toId + anchors[i][6];
          const options = Object.create(jsPlumbEndpointOptions);
          options.anchor = anchors[i];
          options.uuid = sourceUUID;
          if (i % 2 === 0) {
            // target endpoint
            options.isSource = false;
            options.isTarget = true;
            options.endpoint = ["Dot", { radius: 5 }];
            options.paintStyle = {
              stroke: "#ccc",
              fill: "transparent",
              radius: 4,
              strokeWidth: 1
            };
          } else {
            // source endpoint
            options.isSource = true;
            options.isTarget = false;
            options.paintStyle = {
              stroke: "#ccc",
              fill: "#ccc",
              radius: 4,
              strokeWidth: 1
            };
          }
          instance.addEndpoint(
            toId,
            options
          );
        }
      };
      const _addTransition = function(transition) {
        /*
         * {
         *   "title": "Retract",
         *   "from": "0",
         *   "to": "1",
         *   "fromAnchor": "RightUpper",
         *   "toAnchor": "LeftUpper"
         * }
         *
         */
        // we use the uuids approach here so we don"t override the connection styles
        const from = "state" + transition.from_state + transition.from_anchor;
        const to = "state" + transition.to_state + transition.to_anchor;
        // check if a connection already exists
        const connections = instance.getConnections();
        let skip = false;
        connections.forEach(function(connection) {
          const existing_from = connection.source.id + connection.endpoints[0]._jsPlumb.currentAnchorClass;
          const existing_to = connection.target.id + connection.endpoints[1]._jsPlumb.currentAnchorClass;
          // connection.wf_id = transition.from_workflow;
          // connection.wfo_id = transition.id;
          // connection.id = transition.pk;
          // console.log(from + " === " + existing_from + " && " + to + " == " + existing_to);
          if (from === existing_from || to === existing_to) {
            skip = true;
            console.warn(
              "Skip " + transition.title + ": " +
              connection.source.id + connection.endpoints[0]._jsPlumb.currentAnchorClass + " -> " +
              connection.target.id + connection.endpoints[1]._jsPlumb.currentAnchorClass
            );
          }
        });
        if (skip === false) {
          instance.connect({
            id: transition.pk,
            uuids: [from, to],
            parameters: {
              pk: transition.pk,
              wf_id: transition.from_workflow,
              wfo_id: transition.id,
            },
            overlays: [
              [
                "Label",
                {
                  id: "Label",
                  label: transition.title,
                  location: 0.55,
                  cssClass: "transitionLabel"
                }
              ]
            ],
            editable: true
          });
        }
      };

      jsPlumb.ready(() => {
        instance.batch(() => {
          // endpoints
          workflow.states.forEach(function(node) {
            _addEndpoints("state" + node.id);
          });

          // listen for new connections; initialise them the same way we initialise the connections at startup.
          instance.bind("connection", function() {
            // init(connInfo.connection);
          });

          // draggable connections
          // (it is important that we do that before we add connections)
          instance.draggable(
            jsPlumb.getSelector("#workflow_editor .state"),
            {
              grid: [20, 20],
              handle: ".handle",
              // stop: this.dropElement,
            }
          );

          // connections
          workflow.transitions.forEach(transition => {
            _addTransition(transition);
          });

          // temporarily store a connection that is being dragged
          instance.bind("connectionDrag", connection => {
            draggedConnection = {
              id: connection.getParameter("wfo_id"),
              title: connection.getOverlays("Label").Label.label,
              from_workflow: connection.getParameter("wf_id"),
              to_workflow: connection.getParameter("wf_id"),
              from_state: connection.source.id.replace("state", ""),
              to_state: connection.target.id.replace("state", ""),
              from_anchor: connection.endpoints[0]._jsPlumb.currentAnchorClass,
              to_anchor: connection.endpoints[1]._jsPlumb.currentAnchorClass
            };
          });

          // re-add a connection that has not been properly re-added to an endpoint
          instance.bind("connectionDragStop", connection => {
            if (connection.source === null || connection.target === null) {
              _addTransition(draggedConnection);
            } else {
              if (connection.source.id.replace("state", "") !== draggedConnection.from_state || connection.target.id.replace("state", "") !== draggedConnection.to_state) {
                instance.detach(connection);
                _addTransition(draggedConnection);
              }
            }
          });

          // instance.bind("beforeDrop", this.onBeforeDrop);

          // event click on workflow transition
          instance.bind("click", this.onClickOnWorkflowTransition);
        });
      });
      this.instance = instance;
    },

    onClickOnWorkflowState(state) {
      this.aufgabePk = state.id;
      this.statusModalState = true;
    },

    closeModalState() {
      this.aufgabePk = undefined;
      this.statusModalState = false;
    },

    onClickOnWorkflowTransition(connection) {
      this.workflowfolgeId = connection.getParameter("wfo_id");
      this.statusModalConnection = true;
    },

    closeModalConnection() {
      this.workflowfolgeId = undefined;
      this.statusModalConnection = false;
    },

    // dropElement(arg) {
    //   console.log("arg", arg);
    // },

    // onBeforeDrop(arg) {
    //   console.log("arg", arg);
    // },

    save() {
      this.statusSaving = true;
      const STATES = [];
      forEach(this.workflow.states, state => {
        if (this.$refs[state.id] && this.$refs[state.id][0]) {
          const ELEMENT = this.$refs[state.id][0];
          state.top = get(ELEMENT, "style.top", "").replace("px", "");
          state.left = get(ELEMENT, "style.left", "").replace("px", "");
        }
        const STATE = {
          id: state.id,
          title: state.title,
          top: state.top,
          left: state.left,
        };
        STATES.push(STATE);
      });
      const TRANSITIONS = this.instance.getConnections();
      const TRANSITIONS_WF = [];
      forEach(TRANSITIONS, transition => {
        TRANSITIONS_WF.push({
          id: transition.getParameter("wfo_id"),
          from_state: transition.source.id.replace("state", ""),
          to_state: transition.target.id.replace("state", ""),
          from_workflow: transition.getParameter("wf_id"),
          to_workflow: transition.getParameter("wf_id"),
          from_anchor: transition.endpoints[0].anchor.cssClass.replace("state", ""),
          to_anchor: transition.endpoints[1].anchor.cssClass.replace("state", ""),
          title: transition.getOverlays("Label").Label.label
        });
      });
      const WORKFLOW = {
        pk: this.workflowPk,
        name: this.workflow.name,
        states: STATES,
        transitions: TRANSITIONS_WF,
      };
      this.putHttp({
        url: `workflow_editor/${ this.workflowPk }/`,
        data: WORKFLOW,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_VISUALISIERUNG_SAVE_SUCCESS_",
          });
        },
        () => {
          this.addNotification({
            text: "_MSG_VISUALISIERUNG_SAVE_ERROR_",
            type: "error",
          });
        }
      ).finally(
        () => {
          this.statusSaving = false;
        }
      );
    },

    arrange() {
      const WORKFLOW = cloneDeep(this.workflowDefault);
      const STEP_X = 300;
      const STEP_Y = 250;
      const START_X = 100;
      let start_current_x = START_X;
      let start_current_y = 100;
      const COUNT_IN_ROW = 4;
      forEach(WORKFLOW.states, (state, index) => {
        state.top = start_current_y;
        state.left = start_current_x;
        if (index && (index + 1) % COUNT_IN_ROW === 0) {
          start_current_x = START_X;
          start_current_y += STEP_Y;
        } else {
          start_current_x += STEP_X;
        }
      });
      this.workflow = WORKFLOW;
      this.statusReloading = true;
      setTimeout(() => {
        this.statusReloading = false;
        setTimeout(() => {
          this.setupJsPlumbInstance(cloneDeep(this.workflow));
        });
      });
    },
  },
};
