import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import AufgabeModalAnnehmen from "../../../components/Aufgabe/AufgabeModalAnnehmen/AufgabeModalAnnehmen.vue";
import SmartTable from "../../../global/components/table/table.vue";
import Spaced from "../../../global/components/Spaced/Spaced.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import { EventBus } from "../../../global/functions/event-bus";
import getTableApi from "../../../const/TableApis";
import { replaceText } from "../../../global/functions/utils";


const TABLE_ID = "modulantragslisteobjektlisteelementlist";

// @vue/component
export default {
  name: "ModulAntragslisteObjektlisteElementList",
  components: {
    AngularLink,
    AufgabeModalAnnehmen,
    SmartTable,
    Spaced,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
  ],
  props: {
    modul: {
      type: Object,
      default: () => ({}),
    },
    objekt: {
      type: Object,
      default: () => ({}),
    }
  },
  setup() {
    const {
      filterBoolean,
      filterCurrency,
      filterDate,
      filterLimitTo,
      filterSpaced,
    } = FiltersAPI();

    return {
      filterBoolean,
      filterCurrency,
      filterDate,
      filterLimitTo,
      filterSpaced,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: "",
        rowActions: [
          {
            label: "_LBL_ANTRAGSLISTE_OBJEKTLISTE_TABLE_ROW_ACTIONS_DETAILS_ANZEIGEN_",
            route: "root.foerderantrag.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
          {
            label: "_LBL_ANTRAGSLISTE_OBJEKTLISTE_TABLE_ROW_ACTIONS_AUFGABE_ANNEHMEN_",
            isHidden: () => !this.checkPermissionsSync({ perm: "foerderantrag.list.action.annehmen" }) || !this.checkPermissionsSync({ perm: "workflow.ausfuehren" }),
            callback: this.openModalAufgabeAnnehmen,
          },
        ],
        actions: [],
        label: "_LBL_ANTRAGSLISTE_OBJEKTLISTE_ANTRAGSLISTEN_",
        sortable: true,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        view: {
          filter: "top",
        },
      },
      modal: {
        aufgabeAnnehmen: undefined,
      },
    };
  },
  created() {
    this.initUrl();
    this.initEventBus();
  },
  beforeUnmount() {
    this.destroyEventBus();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { olId: this.modul.data.ol_id } });
    },

    reloadTable() {
      EventBus.$emit(`updateTable${ this.tableId }`);
    },

    initEventBus() {
      EventBus.$on("objektliste.update", this.reloadTable);
    },

    destroyEventBus() {
      EventBus.$off("objektliste.update", this.reloadTable);
    },

    renderFoerderorg({ row, typ, field }) {
      let result = "";
      if (row[field] && row[field].length > 0) {
        result = row[field].filter(item => item.o_otid === typ).map(item => item.o_kbez).join(", ");
      }
      return result;
    },

    openModalAufgabeAnnehmen({ item }) {
      this.currentAntrag = item;
      this.currentAufgabe = {
        pk: item.aktuelle_aufgabe,
        kbez: replaceText(
          {
            text: window.gettext("_TXT_ANTRAGSLISTE_OBJEKTLISTE_TABLE_ROW_ACTIONS_AUFGABE_ANNEHMEN_AUFGABE_LABEL_{{aufgabe}}_{{antragsnummer}}_"),
            object: {
              aufgabe: item.status_detail || item.status,
              antragsnummer: this.filterSpaced(item.a_nr, true),
            }
          },
        )
      };
      this.modal.aufgabeAnnehmen = true;
    },

    closeModalAufgabeAnnehmen({ status } = {}) {
      if (status) {
        this.$goTo("root.foerderantrag.details", { id: this.currentAntrag.pk });
      }
      this.modal.aufgabeAnnehmen = false;
    },
  },
};
