"use strict";

import BaseModel from "dias/core/models/baseModel";
import Belege from "./belege.service";

class Positionen extends BaseModel {
  constructor() {
    super("positionen", {
      subPaths: {
        positionBelege: new Belege()
      }
    });
  }

  extendModel(model) {
    model = super.extendModel(model);

    model.createBeleg = data => model.customPOST(data, "belege");

    model.restangularizeBelege = belege => this.Restangular.restangularizeCollection(model, belege, "belege", {});

    if (model.belege) {
      model.belege = model.restangularizeBelege(model.belege);
    }

    return model;
  }
}

export default Positionen;
