"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

const modul = angular.module("root.importauftraege", [
  uiRouter,
]);

modul.config($stateProvider => {
  $stateProvider
    .state("root.importauftraege", {
      url: "/importauftraege/",
      views: {
        "main@root": {
          template: "<div vueinit><importauftrag-list></importauftrag-list></div>"
        }
      },
      data: {
        permissions: ["importauftraege.ui"]
      }
    })
    .state("root.importauftraege_wizard_antrag", {
      url: "/antragimport/",
      views: {
        "main@root": {
          template: "<div vueinit><importauftrag-antrag-wizard></importauftrag-antrag-wizard></div>"
        }
      },
      data: {
        permissions: ["importauftraege.antrag.wizard"]
      }
    })
    .state("root.importauftraege_wizard_json", {
      url: "/jsonimport/",
      views: {
        "main@root": {
          template: "<div vueinit><importauftrag-json-wizard></importauftrag-json-wizard></div>"
        }
      },
      data: {
        permissions: ["importauftraege.json.wizard"]
      }
    })
    .state("root.exportauftraege_wizard_json", {
      url: "/jsonexport/",
      views: {
        "main@root": {
          template: "<div vueinit><exportauftrag-json-wizard></exportauftrag-json-wizard></div>"
        }
      },
      data: {
        permissions: ["importauftraege.json.wizard"]
      }
    }) 
    .state("root.ijf_template_ver", {
      url: "/ijf_template_ver/",
      views: {
        "main@root": {
          template: "<div vueinit><ijf-template-list></ijf-template-list></div>"
        }
      },
      data: {

      }
    })
    .state("root.ijf_template_ver.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><ijf-template-details></ijf-template-details></div>"
        }
      },
      data: {

      }
    })
  ;
})
;

export default modul;
