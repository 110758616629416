import ModalCreateOrUpdate from "./ModalCreateOrUpdate/ModalCreateOrUpdate.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";

import {
  cloneDeep,
  forEach,
  get,
  indexOf,
  isNil,
  orderBy,
  size,
  without,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeTabellePerioden",
  components: {
    ModalCreateOrUpdate,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
    dependencyValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      confirmShow: undefined,
      confirmOptions: undefined,
      statusModal: undefined,
      currentModel: undefined,
      currentColumn: undefined,
    };
  },
  computed: {
    getModelSize() {
      return size(this.model);
    },

    getModelList() {
      return orderBy(this.model, ["pos"]);
    },

    hasSpalten() {
      return size(this.dependencyValue);
    },

    getSpaltenList() {
      return orderBy(this.dependencyValue, ["pos"]);
    },

    spaltenMapping() {
      const MAPPING = {};
      forEach(this.dependencyValue, (value, key) => {
        MAPPING[key] = this.getTypeLabel(key);
      });
      return MAPPING;
    },
  },
  watch: {
    dependencyValue: {
      deep: true,
      handler(val, oldVal) {
        forEach(oldVal, (el, key) => {
          if (isNil(get(val, key))) {
            this.removeKey(key);
          }
        });
      },
    }
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../global/components/Modal/Modal.vue").default;
  },
  methods: {
    removeKey(key) {
      const MODEL = cloneDeep(this.model);
      if (MODEL.periode === key) {
        MODEL.periode = undefined;
      }
      if (indexOf(MODEL.abhaengige_spalten, key) !== -1) {
        MODEL.abhaengige_spalten = without(MODEL.abhaengige_spalten, key);
      }
      if (indexOf(MODEL.unabhaengige_spalten, key) !== -1) {
        MODEL.unabhaengige_spalten = without(MODEL.unabhaengige_spalten, key);
      }
      if (MODEL.sortierung === key) {
        MODEL.sortierung = undefined;
      }
      if (MODEL) {
        this.onInput_mixin({ value: MODEL });
      }
    },

    getTypeLabel(key) {
      if (this.model.periode === key) {
        return "_LBL_GR_DYNAMISCHE_TABELLE_PERIODE_COLUMN_";
      }
      if (indexOf(this.model.abhaengige_spalten, key) !== -1) {
        return "_LBL_GR_DYNAMISCHE_TABELLE_ABHAENGIG_COLUMN_";
      }
      if (indexOf(this.model.unabhaengige_spalten, key) !== -1) {
        return "_LBL_GR_DYNAMISCHE_TABELLE_UNABHAENGIG_COLUMN_";
      }
      return "_LBL_GR_DYNAMISCHE_TABELLE_NO_CONFIG_COLUMN_";
    },

    openModalUpdate({ item }) {
      this.currentColumn = item;
      this.statusModal = true;
    },

    closeModal({ model } = {}) {
      if (model) {
        this.onInput_mixin({ value: model });
      }
      this.statusModal = undefined;
      this.currentColumn = undefined;
    },
  },
};
