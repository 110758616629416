"use strict";

function filterNum(num) {
  const arr = num.split("");
  let fValue = "";
  let i;
  const length = arr.length - 1;
  for (i = length; i >= 0; i--) {
    fValue += arr[length - i];
    if (i % 3 === 0 && i !== 0) {
      fValue += " ";
    }
  }
  return fValue;
}

export default () => num => {
  if (num === undefined || num === null) {
    return num;
  }
  if (num.indexOf("<em>") !== -1) {
    num = num.substr(4, num.length - 9);
    num = "<em>" + filterNum(num) + "</em>";
    return num;
  }
  if (num.match(/^\d+$/) === null) {
    return num;
  }

  return filterNum(num);
};
