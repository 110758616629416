import {
  isObject,
  isString,
} from "lodash-es";

export default function on429({ response, DiasNotification }) {
  if (response.status !== 429) {
    return true;
  }
  DiasNotification.page.clear();
  if (response && isObject(response.data) && isString(response.data.detail)) {
    DiasNotification.page.error(response.data.detail);
  } else {
    DiasNotification.page.error("Sie haben die maximale Anzahl von Anfragen überschritten. Versuchen Sie es später erneut.");
  }
  return false;
}
