import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import TemplateAdminParameterBoxGroup from "./TemplateAdminParameterBoxGroup/TemplateAdminParameterBoxGroup.vue";

import {
  forEach,
  sortBy,
  toLower,
  values,
} from "lodash-es";

// @vue/component
export default {
  name: "TemplateAdminParameterBox",
  components: {
    AsideRightBox,
    FormElement,
    PuxCloak,
    PuxTranslate,
    TemplateAdminParameterBoxGroup,
  },
  props: {
    parameters: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    paramLoadingError: {
      type: String,
      required: false,
      default: undefined,
    },
    initialOpen: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      search: "",
      used: false,
      optionsAsideRightBox: {
        name: "_TXT_TEMPLATEADMIN_DETAILS_PARAMETER_BOX_HEADER_",
        active: this.initialOpen,
        icon: "info-sign"
      },
      optionsSearch: {
        type: "text",
        label: "Suche",
        view: "v",
        id: "template_parameter_search",
      },
      optionsUsed: {
        type: "one_checkbox",
        label: "Verwendet",
        view: "v",
        id: "template_parameter_used",
      },
      groupsAndParameterNamesHiddenWithSearch: {
        groupNames: {},
        parameterNames: {},
      },
      timer: undefined,
    };
  },
  computed: {
    parametersGroupsSorted() {
      return sortBy(this.parametersGroups, ["name"]);
    },

    parametersGroups() {
      const GROUPS = {};
      this.parametersSorted.forEach(parameter => {
        const GROUP_NAME = parameter.group || "Allgemein";
        GROUPS[GROUP_NAME] = GROUPS[GROUP_NAME] || {
          children: [],
          name: GROUP_NAME,
        };
        GROUPS[GROUP_NAME].children.push(parameter);
      });
      return values(GROUPS);
    },

    parametersSorted() {
      return sortBy(this.parameters, ["paramname"]);
    },

    searchToLower() {
      return toLower(this.search);
    },
  },
  watch: {
    parameters: {
      handler() {
        this.setGroupsAndParameterNamesHiddenWithSearch();
      },
      deep: true
    },
  },
  methods: {
    changeFilterWithTimeout() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.setGroupsAndParameterNamesHiddenWithSearch();
      }, 300);
    },

    setGroupsAndParameterNamesHiddenWithSearch() {
      const GROUP_NAMES = {};
      const PARAMETER_NAMES = {};
      if (this.search || this.used) {
        this.parametersGroupsSorted.forEach(group => {
          let isGroupVisible = false;
          group.children.forEach(parameter => {
            if (this.isParameterVisible(parameter)) {
              isGroupVisible = true;
            } else {
              PARAMETER_NAMES[parameter.paramname] = true;
            }
          });
          if (!isGroupVisible) {
            GROUP_NAMES[group.name] = true;
          }
        });
      }
      this.groupsAndParameterNamesHiddenWithSearch = {
        groupNames: GROUP_NAMES,
        parameterNames: PARAMETER_NAMES,
      };
    },

    isParameterVisible(parameter) {
      let isVisibleUsed = false;
      let isVisibleSearch = false;
      if ((this.used && parameter.used) || !this.used) {
        isVisibleUsed = true;
      }
      if (this.search) {
        if (isVisibleUsed) {
          forEach(["paramname", "infotext"], key => {
            const TEXT = toLower(parameter[key]);
            if (TEXT.indexOf(this.searchToLower) !== -1) {
              isVisibleSearch = true;
              return false;
            }
          });
        }
      } else {
        isVisibleSearch = true;
      }
      return isVisibleUsed && isVisibleSearch;
    },
  },
};
