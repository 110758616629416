import {
  ref,
} from "vue";

export default function AntragEinreichenMessageAPI({ antrag }) {
  const einreichenStatus = ref(false);
  const setEinreichenStatus = (checkEinreichenStatus, antragNew) => {
    if (checkEinreichenStatus && !antrag.value.a_antragsdatum && antragNew.a_antragsdatum) {
      einreichenStatus.value = true;
    }
  };
  const closeEinreichenMessage = () => {
    einreichenStatus.value = false;
  };

  return {
    closeEinreichenMessage,
    einreichenStatus,
    setEinreichenStatus,
  };
}
