"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import ReleaseNotesDirective from "./releasenotes.directive";

const diasReleaseNotes = angular.module("dias.releasenotes", [
  uiRouter,
])

  .directive("diasReleaseNotes", ReleaseNotesDirective);

diasReleaseNotes.config($stateProvider => {
  $stateProvider
    .state("root.releasenotes", {
      url: "/releasenotes/",
      views: {
        "main@root": {
          template: "<div vueinit><vue-versionsinformationen-list></vue-versionsinformationen-list></div>",
        }
      },
      data: {
        permissions: ["releasenotes.view"]
      },
    })
    .state("root.releasenotes.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: `<div vueinit=""><versionsinformationen-details></versionsinformationen-details></div>`
        }
      },
      data: {
        permissions: ["releasenotes.update"]
      },
    });
})
;

export default diasReleaseNotes;
