"use strict";

import StandardFinanzierungsController from "../../finanzierung.controller";
import {
  EventBus,
} from "../../../../../vue/client/vue/global/functions/event-bus";

class StandardZuschussController extends StandardFinanzierungsController {
  /*@ngInject*/
  constructor(
    $scope,
    $q,
    Foerdermodule,
    Foerderantrag,
    KFinanzierungsart,
    BetragsrechnerRegistry,
    Vertrag,
    $stateParams,
    $filter,
    i18n,
    AuthService,
    DiasNotification
  ) {
    super($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService);
    this.name = "StandardZuschussController";
    // Antrag-Finanzierungsart
    this.antragFinanzierungsart = {};
    this.vertragFinanzierungsart = {};
    this.DiasNotification = DiasNotification;
    this._reloadAntragFinanzierungsart();
    this.registerOrDeactiveSignalReceiver();
  }

  registerOrDeactiveSignalReceiver({ eventBusAction = "$on" } = {}) {
    const SIGNAL_EMPFANG = this.modul.regel.signal_empfang || [];
    if (!SIGNAL_EMPFANG.length) {
      return;
    }

    SIGNAL_EMPFANG.forEach(item => {
      if (item.signal_kennung && item.sender_reid) {
        EventBus[eventBusAction](`${ item.signal_kennung }_${ item.sender_reid }`, arg => this.onReceiveSignal(item.signal_kennung, arg));
      }
    });
  }

  onReceiveSignal(signal_kennung) {
    if (signal_kennung === "durchfuehrungszeitraum.update") {
      this.onZeitraumUpdate();
    }
  }

  onZeitraumUpdate() {
    this.loading = true;
    if (this.kontext !== "zuschuss") {
      this.obj.customGET("zuschuss").then(
        response => {
          this.DiasNotification.page.info(this.i18n.gettext(
            "Aufgrund einer Änderung am Durchführungszeitraum wurde der Zuschuss neu berechnet."
          ));
          this.infoprovider = Object.assign(this.infoprovider, response.plain());
        }
      ).finally(() => this.loading = false);
      this.updateSnapshotDiff();
    }
    this._reloadPositionen();
  }

  get_prozent() {
    // Im Zweifelsfalle immer den Wert aus der aktuellen Berechnung zurückgeben:
    if (this.infoprovider.details && this.infoprovider.details[this.modul.regel.afr_id]) {
      return this.infoprovider.details[this.modul.regel.afr_id].prozent;
    } else if (this.antragFinanzierungsart) {
      return this.antragFinanzierungsart.afa_prozent;
    }
  }

  show_prozente() {
    // Im Zuschusskontext werden keine Prozente angezeigt
    if (this.kontext === "zuschuss") {
      return false;
    }
    // Wenn eine Zuschussberechnung durchgeführt werden konnte:
    if (this.infoprovider && this.infoprovider.status === 1) {
      return true;
    }
    // Wenn keine Zuschussberechnung durchgeführt wurde:
    if (this.infoprovider && !this.infoprovider.status) {
      return this.antragFinanzierungsart.afa_prozent !== null;
    }
  }

  _reloadAntragFinanzierungsart() {
    const antragId = this.modul.regel.afr_aid;
    const antragregelId = this.modul.regel.afr_id;
    this.Foerderantrag.one(antragId).getList("finanzierungsarten", { antragregel: antragregelId }).then(result => {
      this.antragFinanzierungsart = result[0]; // sollte pro antragregel max. eine geben
      if (this.kontext === "zuschuss_vertrag") {
        this.Vertrag.one(this.$stateParams.id).getList("finanzierung", { foerderantragfinanzierungsart: this.antragFinanzierungsart.pk }).then(
          result => {
            this.vertragFinanzierungsart = result[0];
          }
        );
      }
    });
  }
}

export default StandardZuschussController;
