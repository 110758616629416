"use strict";

import "restangular";
import FilterSet from "dias/core/table/filterSet";
import template from "./list.servicelog.jade";
import moment from "moment";


class ServiceLogFilterSet extends FilterSet {
  constructor() {
    super();
    this.addFilter("suche", {
      label: "Suche",
      textInput: true,
      param: "suche",
      initialStatus: true
    });
    this.addFilter("crdate", {
      label: "Zeitpunkt",
      dateRange: true,
      dateRangeMinPlaceholder: "Beginn beliebig",
      dateRangeMaxPlaceholder: "Ende beliebig",
      initial: [moment().format("YYYY-MM-DD"), null],
      param: "crdate",
      initialStatus: true
    });
    this.addFilter("hat_fehler", {
      label: "Mit Fehler",
      bool: true,
      param: "hat_fehler",
      initialStatus: true
    });
    this.addFilter("slo_fehler", {
      label: "In Fehler suchen",
      textInput: true,
      param: "slo_fehler",
      
    });
    this.addFilter("slo_request", {
      label: "In Anfrage suchen",
      textInput: true,
      param: "slo_request",
    });
    this.addFilter("slo_response", {
      label: "In Antwort suchen",
      textInput: true,
      param: "slo_response",
    });
    this.addFilter("kontext", {
      label: "Kontext",
      textInput: true,
      param: "slo_kontext",
    });
  }
}


class ServiceLogController {
  /*@ngInject*/
  constructor($q, ServiceLog) {
    const logTableDefer = $q.defer();
    this.logTable = logTableDefer.promise;

    const cols = [
      {
        label: "Zeitpunkt",
        field: "crdate",
        cellTemplate: `{{:: row.crdate | date:"short"}}`,
        sortingDefault: true,
        sortingDefaultDirection: "desc",
        sortable: true
      }, {
        label: "Kontext",
        field: "slo_kontext",
        sortable: true
      }, {
        label: "Anfrage",
        field: "slo_request",
        cellTemplate: `<div
          class="code-content scroll-content"
          style="max-height: 200px; width: 100%;">{{:: row.slo_request | json:2}}</div>`
      }, {
        label: "Antwort",
        field: "slo_response",
        cellTemplate: `<div
          class="code-content scroll-content"
          style="max-height: 200px; width: 100%;">{{:: row.slo_response | json:2}}</div>`
      }, {
        label: "Fehler",
        field: "slo_fehler",
        cellClass: "text-left"
        // cellTemplate: `<div
        //   ng-if="row.slo_fehler"
        //   class="code-content scroll-content"
        //   style="max-height: 200px; width: 100%;">{{:: row.slo_fehler | json:2}}</div>`
      },
    ];

    logTableDefer.resolve({
      dataSource: ServiceLog.getList,
      // Template für Detailansicht
      tableTitle: "Schnittstellenprotokoll",
      // detailTemplate: "antragDetailRow.html",
      columnDefs: cols,
      // Aktionen je Zeile
      filter: new ServiceLogFilterSet(),
      filterName: "servicelogs",
    });
  }
}

export default {
  template: template(),
  controller: ServiceLogController,
  controllerAs: "vm"
};
