"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";
import AuthModels from "dias/auth/models";

import models from "./models";
import services from "./services";

import datenschutzeinwilligung from "./datenschutzeinwilligung.component";
import datenschutzabfrage from "./datenschutzabfrage.component";


const datenschutzModule = angular.module("dias.auth.datenschutz", [
  uiRouter,
  AuthModels.name,
  services.name,
  models.name,
  diasNotification.name,
  diasLoading.name,
]);

datenschutzModule.config($stateProvider => {
  $stateProvider
    .state("root.datenschutzeinwilligung", {
      url: "/datenschutzeinwilligung/",
      views: {
        "main@root": {
          template: "<datenschutz-einwilligung></datenschutz-einwilligung>"
        }
      },
      data: {}
    });
})

  .component("datenschutzEinwilligung", datenschutzeinwilligung)
  .component("datenschutzAbfrage", datenschutzabfrage);

export default datenschutzModule;
