import CalendarObjectTypes from "../CalendarObjectTypes";

export default [
  {
    id: "ter_titel",
    type: "text",
    label: "_TXT_CALENDAR_EVENT_DETAILS_TITEL_",
    required: true,
    view: "v",
    translate: true,
  },
  {
    id: "typ_pk",
    type: "select",
    label: "_TXT_CALENDAR_EVENT_DETAILS_TYP_",
    required: true,
    view: "v",
    keyId: "pk",
    keyLabel: "tmt_kbez",
    menuWidthAll: true,
    url: "katalog/?key=termintyp",
    apiSaveId: "termintyp",
    translate: true,
  },
  {
    id: "allDay",
    type: "boolean",
    label: "_LBL_CALENDAR_EVENT_DETAILS_MODAL_ALL_DAY_",
    view: "v-alt",
    translate: true,
  },
  {
    id: "ter_von",
    type: "datetime",
    label: "_TXT_CALENDAR_EVENT_DETAILS_VON_",
    required: true,
    view: "v-alt",
    disabledTime: false,
    translate: true,
  },
  {
    id: "ter_bis",
    type: "datetime",
    label: "_TXT_CALENDAR_EVENT_DETAILS_BIS_",
    required: true,
    view: "v-alt",
    disabledTime: false,
    translate: true,
  },
  {
    id: "content_type_id",
    label: "_LBL_CALENDAR_EVENT_DETAILS_MODAL_OBJECT_",
    type: "select",
    keyId: "value",
    keyLabel: "label",
    view: "v",
    data: CalendarObjectTypes,
    translate: true,
    slot: "content_type_id",
    deselect: true,
  },
  {
    key: "antragstellerorg",
    id: "objekt_id",
    type: "select",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_ANTRAGSTELLERORGANISATION_",
    url: "antragstellerorganisationen/?fields=ast_name&fields=pk",
    keyId: "pk",
    keyLabel: "ast_name",
    view: "v",
    searchParameter: "ast_name",
    searchGlobal: true,
    hideIf: true,
    translate: true,
  },
  {
    key: "aufgabe",
    id: "objekt_id",
    type: "select",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_AUFGABE_",
    keyLabel: "kbez",
    keyId: "pk",
    view: "v",
    url: "aktuelle_aufgaben/",
    searchParameter: "suche",
    searchGlobal: true,
    hideIf: true,
    translate: true,
  },
  {
    key: "auszahlung",
    id: "objekt_id",
    type: "select",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_AUSZAHLUNG_",
    keyId: "pk",
    view: "v",
    url: "auszahlungen/",
    searchParameter: "suche",
    searchGlobal: true,
    keyLabelCallback: ({ item }) => `${ item.az_nummer } - ${ item.vertrag_nr }`,
    hideIf: true,
    translate: true,
  },
  {
    key: "antrag",
    id: "objekt_id",
    type: "select",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_ANTRAG_",
    keyId: "pk",
    view: "v",
    url: "foerderantraege/?serializer=minimal",
    searchParameter: "a_nr",
    searchGlobal: true,
    keyLabelCallback: ({ item }) => `${ item.a_nr } ${ item.a_titel ? " - " + item.a_titel : "" }`,
    hideIf: true,
    translate: true,
  },
  {
    key: "sitzung",
    id: "objekt_id",
    type: "select",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_SITZUNG_",
    keyId: "pk",
    view: "v",
    url: "sitzungen/?fields=pk&fields=kbez",
    searchParameter: "kbez",
    searchGlobal: true,
    keyLabel: "kbez",
    hideIf: true,
    translate: true,
  },
  {
    key: "vertrag",
    id: "objekt_id",
    type: "select",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_VERTRAG_",
    keyId: "pk",
    view: "v",
    url: "vertraege/",
    searchParameter: "suche",
    searchGlobal: true,
    keyLabel: "v_nr",
    hideIf: true,
    translate: true,
  },
  {
    key: "gutachten",
    id: "objekt_id",
    type: "select",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_GUTACHTEN_",
    keyId: "pk",
    view: "v",
    url: "gutachten/",
    searchParameter: "suche",
    searchGlobal: true,
    keyLabel: "ga_kbez",
    hideIf: true,
    translate: true,
  },
  {
    key: "formular",
    id: "objekt_id",
    type: "select",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_FORMULAR_",
    keyId: "pk",
    view: "v",
    url: "formulare/",
    searchParameter: "suche",
    searchGlobal: true,
    keyLabel: "for_titel",
    hideIf: true,
    translate: true,
  },
  {
    key: "projektbericht",
    id: "objekt_id",
    type: "select",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_PROJEKTBERICHT_",
    keyId: "pk",
    view: "v",
    url: "projektberichte/",
    searchParameter: "suche",
    searchGlobal: true,
    keyLabel: "pb_kbez",
    hideIf: true,
    translate: true,
  },
  {
    key: "auszahlungsplan",
    id: "objekt_id",
    type: "select",
    label: "_LBL_CALENDAR_FILTER_OBJEKT_TYP_AUSZAHLUNGSPLAN_",
    keyId: "pk",
    view: "v",
    url: "auszahlungsplaene/",
    searchParameter: "suche",
    searchGlobal: true,
    keyLabelCallback: ({ item }) => `${ item.zp_modalitaet ? item.zp_modalitaet.name : "" } ${ item.zp_nummer } (${ item.v_nr })`,
    hideIf: true,
    translate: true,
  },
  {
    id: "private",
    type: "boolean",
    label: "_TXT_CALENDAR_EVENT_DETAILS_SICHTBARKEIT_PRIVATE_",
    view: "v-alt",
    disabled: true,
    attrId: "private_modal",
    translate: true,
  },
  {
    id: "ter_ort",
    type: "text",
    label: "_TXT_CALENDAR_EVENT_DETAILS_ORT_",
    view: "v",
    translate: true,
  },
  {
    id: "ter_bemerkung",
    type: "richtext",
    label: "_TXT_CALENDAR_EVENT_DETAILS_BEMERKUNG_",
    view: "v-alt",
    translate: true,
  },
  {
    id: "offentlich",
    type: "boolean",
    label: "_TXT_CALENDAR_EVENT_DETAILS_OFFENTLICHKEIT_",
    view: "v-alt",
    translate: true,
  },
];
