"use strict";

import KostenFinanzierungBaseController from "./kosten_finanzierung.base.controller";

class StandardFinanzierungController extends KostenFinanzierungBaseController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService) {
    super($scope, $q, Foerdermodule, Foerderantrag, BetragsrechnerRegistry, i18n);
    this.i18n = i18n;
    this.$stateParams = $stateParams;
    this.AuthService = AuthService;
    this.$filter = $filter;
    this.Vertrag = Vertrag;
    this.finArtDetailPos = {};
    this.editPositionModes = {};
    this.allowsInput = false;
    this.currency = $filter("currency");

    // Katalog-Finanzierungsart + Details laden:
    KFinanzierungsart.getList().then(result => {
      this._initKatalogDetails(result, this.parameter.finanzierungsart, this.parameter.finanzierungsdetails);
    });

    this.antragFinanzierungsart = {};
    this.vertragFinanzierungsart = {};
  }

  _initKatalogDetails(katalogResults, regelKatalogId, regelKatalogDetails) {
    super._initKatalogDetails(katalogResults, regelKatalogId, regelKatalogDetails);
    if (this.parameter.ohne_positionen && this.katalogDetails.length > 0) {
      this.editType = "no-position-details";
      for (let i = 0; i < this.katalogDetails.length; i++) {
        if (!this.finArtDetailPos[this.katalogDetails[i].pk]) {
          this.finArtDetailPos[this.katalogDetails[i].pk] = this.initNewPosition();
          this.finArtDetailPos[this.katalogDetails[i].pk].finanzierungsartdetail = this.katalogDetails[i].pk;
        }
      }
    } else if (this.parameter.ohne_positionen) {
      this.editType = "no-position";
    }
  }


  getDefaultBetragsrechner() {
    return "standard_finanzierungsrechner";
  }

  getPosBezeichnung(p) {
    return (p.finanzierungsartdetail_kbez ? p.finanzierungsartdetail_kbez + ": " : "") + p.afp_bez;
  }

  getPosWert(p) {
    if (p) {
      return p.afp_wert || 0;
    }
    return 0;
  }

  getPosNr(p) {
    return p.afp_pos;
  }

  update_bez(model) {
    const fid = this.getKatalogDetails(model.finanzierungsartdetail);
    if (fid && fid.fid_standardtext) {
      if (model.argument && model.argument.bezeichnung !== undefined && model.argument.bezeichnung !== null &&
         (model.argument.bezeichnung.length === 0 || model.afp_bez === model.argument.bezeichnung)) {
        model.argument.bezeichnung = fid.fid_standardtext;
      }
      model.afp_bez = fid.fid_standardtext;
    }
  }

  get_prozent() {
    if (this.infoprovider && this.infoprovider.details && this.infoprovider.details[this.modul.regel.afr_id]) {
      return this.infoprovider.details[this.modul.regel.afr_id].prozent;
    } else if (this.antragFinanzierungsart) {
      return this.antragFinanzierungsart.afa_prozent;
    }
  }

  show_prozente() {
    // Wenn eine Zuschussberechnung durchgeführt werden konnte:
    if (this.infoprovider && this.infoprovider.status === 1) {
      return this.infoprovider.details[this.modul.regel.afr_id].prozent !== null;
    }
    // Wenn keine Zuschussberechnung durchgeführt wurde:
    if (!this.infoprovider) {
      return this.antragFinanzierungsart.afa_prozent !== null;
    }
  }

  onArtLoaded() {
    // hook
  }

  onPosLoaded() {
    // hook
  }

  toggleEditPosition(pos) {
    if (this.editType !== "no-position-details") {
      super.toggleEditPosition(pos);
    } else {
      this.editPositionModes[pos.pk] = !this.editPositionModes[pos.pk];
      if (!this.editPositionModes[pos.pk]) {
        const p = this.positionen.find(p => p.finanzierungsartdetail === pos.pk);
        if (p) {
          this.finArtDetailPos[pos.pk] = _.cloneDeep(p);
        }
        this.updateErrors = {};
      }
    }
    if (pos.fid_id && !this.finArtDetailPos[pos.pk].pk) {
      this.update_bez(this.finArtDetailPos[pos.pk]);
    } else {
      this.update_bez(pos);
    }
  }

  _setPositionen(positionen) {
    super._setPositionen(positionen);
    for (let i = 0;this.katalogDetails && i < this.katalogDetails.length; i++) {
      this.finArtDetailPos[this.katalogDetails[i].pk] = this.initNewPosition();
      this.finArtDetailPos[this.katalogDetails[i].pk].finanzierungsartdetail = this.katalogDetails[i].pk;
    }
    for (let i = 0; i < this.positionen.length; i++) {
      const p = this.positionen[i];
      this.finArtDetailPos[p.finanzierungsartdetail] = _.cloneDeep(p);
    }
  }

  updatePosition(pos) {
    let promise;
    if (pos.pk) {
      promise = super.updatePosition(pos);
    } else {
      this.newPosition = pos;
      promise = super.createPosition();
    }
    promise.then(
      response => {
        if (this.updateErrors[pos.finanzierungsartdetail]) {
          delete this.updateErrors[pos.finanzierungsartdetail];
        }
        return response;
      },
      err => {
        if ((!err.status || err.status !== 403) && pos.finanzierungsartdetail) {
          this.updateErrors[pos.finanzierungsartdetail] = err.data;
        }
        return err;
      }
    );
    return promise;
  }

  _reloadPositionen() {
    this.loadingPositionen = true;
    const antragId = this.modul.regel.afr_aid;
    const antragregelId = this.modul.regel.afr_id;
    this.Foerderantrag.one(antragId).getList("finanzierungsarten", { antragregel: antragregelId }).then(result => {
      this.antragFinanzierungsart = result[0]; // sollte pro antragregel max. eine geben
      this.antragKoFiArt.bez = this.antragFinanzierungsart.finanzierungsart.fin_kbez;
      this.antragKoFiArt.summe = this.antragFinanzierungsart.afa_summe;
      this.antragKoFiArt.artPk = this.antragFinanzierungsart.finanzierungsart.pk;
      this.antragKoFiArt.diffKey = "finanzierungsarten";
      this.antragKoFiArt.pk = this.antragFinanzierungsart.pk;
      if (this.kontext !== "zuschuss_vertrag") {
        this.onArtLoaded();
        this.antragFinanzierungsart.getList("positionen", { ordering: "afp_pos" }).then(
          result_pos => {
            this._setPositionen(result_pos);
            this.onPosLoaded();
            this.loadingPositionen = false;
          }
        );
      }
      if (this.kontext === "zuschuss_vertrag") {
        this.Vertrag.one(this.$stateParams.id).getList("finanzierung", { foerderantragfinanzierungsart: this.antragFinanzierungsart.pk }).then(
          result => {
            this.vertragFinanzierungsart = result[0];
            if (this.modul.modulgruppen.indexOf("zuschuss") !== -1) {
              this.obj.zuschuss_bewilligt_ohne_pauschale = +this.vertragFinanzierungsart.vf_betrag_bewilligt;
            }
            this.onArtLoaded();
            if (this.allowsInput) {
              this.vertragFinanzierungsart.getList("positionen", { ordering: "vfp_pos" }).then(
                result_pos => {
                  this._process_zuschuss_vertrag_pos(result_pos);
                  this.onPosLoaded();
                  this.loadingPositionen = false;
                }
              );
            } else {
              this.loadingPositionen = false;
            }
          }
        );
      }
    });
  }

  _process_zuschuss_vertrag_pos(result_pos) {
    if (result_pos[0]) {
      const pos = { pk: result_pos[0].pk,
                    betrag_bewilligt: result_pos[0].vfp_betrag_bewilligt,
                    afp_bez: result_pos[0].vfp_bez,
                    afp_argument: this.vertragFinanzierungsart.vf_argument,
                    afp_wert: this.vertragFinanzierungsart.vf_betrag_aktualisiert };
      this.positionen = [pos];
      this.editPositionModes = {};
      this.editPositionen = {};
      this.editPositionModes[pos.pk] = false;
      this.editPositionen[pos.pk] = { pk: result_pos[0].pk,
                                      bezeichnung: result_pos[0].vfp_bez,
                                      argument: Object.assign(
                                        { wert: this.vertragFinanzierungsart.vf_betrag_aktualisiert,
                                          bezeichnung: result_pos[0].vfp_bez },
                                        this.vertragFinanzierungsart.vf_argument
                                      ),
                                      wert: this.vertragFinanzierungsart.vf_betrag_aktualisiert };
    }
  }

  _doPostPosition() {
    return this.antragFinanzierungsart.customPOST(this.newPosition, "positionen");
  }

  initNewPosition() {
    const newPos = super.initNewPosition();
    newPos.finanzierungsartdetail = "";
    return newPos;
  }

  searchInSnapshot(artPk, koFiArt, pk) {
    if (!this.snapshot || !this.snapshot.asn_snapshot || !this.snapshot.asn_snapshot[koFiArt]) {
      return;
    }
    if (this.snapshotDiffPositionen[artPk]) {
      delete this.snapshotDiffPositionen[artPk];
    }
    if (this.snapshotPositionen[artPk]) {
      delete this.snapshotPositionen[artPk];
    }
    if (this.snapshotdiff && this.snapshotdiff.modified && this.snapshotdiff.modified[koFiArt]) {
      for (let i = 0; i < this.snapshotdiff.modified[koFiArt].length; i++) {
        if (this.snapshotdiff.modified[koFiArt][i].pk === pk) {
          this.snapshotDiffPositionen[artPk] = this.snapshotdiff.modified[koFiArt][i].positionen;
        }
      }
    }
    for (let i = 0; i < this.snapshot.asn_snapshot[koFiArt].length; i++) {
      if (this.snapshot.asn_snapshot[koFiArt][i].finanzierungsart.fin_id === artPk) {
        this.snapshotPositionen[artPk] = this.snapshot.asn_snapshot[koFiArt][i].positionen;
        return this.snapshot.asn_snapshot[koFiArt][i].afa_summe;
      }
    }

    return;
  }

  searchInPositionSnapshot(position, artPk) {
    if (!this.snapshot) {
      return;
    }
    if (this.snapshotIcons[position.pk]) {
      delete this.snapshotIcons[position.pk];
    }
    if (this.snapshotPositionClass[position.pk]) {
      delete this.snapshotPositionClass[position.pk];
    }
    if (this.snapshotPositionName[position.pk]) {
      delete this.snapshotPositionName[position.pk];
    }

    for (let i = 0; this.snapshotPositionen[artPk] && i < this.snapshotPositionen[artPk].length; i++) {
      if (this.snapshotPositionen[artPk][i].pk === position.pk) {
        if (this.snapshotDiffPositionen[artPk] && this.snapshotDiffPositionen[artPk].length) {
          for (let j = 0; j < this.snapshotDiffPositionen[artPk].length; j++) {
            if (this.snapshotDiffPositionen[artPk][j].pk === position.pk && (this.snapshotDiffPositionen[artPk][j].afp_wert || this.snapshotDiffPositionen[artPk][j].afp_bez)) {
              this.snapshotIcons[position.pk] = "glyphicon-changes";
              this.snapshotPositionClass[position.pk] = true;
              if (this.snapshotDiffPositionen[artPk][j].afp_bez) {
                this.snapshotPositionName[position.pk] = this.snapshotPositionen[artPk][i].afp_bez;
              }
              break;
            }
          }
        }
        return this.snapshotPositionen[artPk][i].afp_wert;
      }
    }
    this.snapshotIcons[position.pk] = "glyphicon-changes-add";
    this.snapshotPositionClass[position.pk] = true;
    return "0";
  }

  isChanges() {
    if (this.snapshotdiff) {
      if (this.snapshotdiff.modified.finanzierungsarten || this.snapshotdiff.modified.kostenarten) {
        return true;
      } else if (this.snapshotdiff.added.finanzierungsarten || this.snapshotdiff.added.kostenarten) {
        return true;
      } else if (this.snapshotdiff.removed.finanzierungsarten || this.snapshotdiff.removed.kostenarten) {
        return true;
      }
    }
    return false;
  }

  showRemovePos(diffKey, pk) {
    if (this.snapshotdiff && this.snapshotdiff.removed[diffKey]) {
      for (let i = 0; i < this.snapshotdiff.removed[diffKey].length; i++) {
        if (this.snapshotdiff.removed[diffKey][i].pk === pk) {
          return this.snapshotdiff.removed[diffKey][i].positionen;
        }
      }
    }
  }

  updatePositionVertrag(pos) {
    this.loading = true;
    const data = { bez: pos.argument.bezeichnung || pos.bezeichnung,
                   argument: pos.argument,
                   wert: pos.argument.wert || pos.wert };
    this.vertragFinanzierungsart.customPOST(data, "einzelposition_speichern").then(response => {
      this.infoprovider = response.zuschussrechner;
      if (this.createErrors) {
        delete this.createErrors;
      }
      if (this.updateErrors[pos.pk]) {
        delete this.updateErrors[pos.pk];
      }
      this._reloadPositionen();
      this.toggleEditPosition(pos);
      this._resetNewPosition();
      this._doSavecallback();
    }, errors => {
      this.updateErrors[pos.pk] = {};
      this.updateErrors[pos.pk] = { bez: errors.data.bez,
                                    wert: errors.data.wert,
                                    argument: errors.data.argument };
    })
      .finally(() => this.loading = false);
  }

  deletePositionVertrag() {
    this.loading = true;
    this.vertragFinanzierungsart.customPOST({}, "einzelposition_loeschen").then(() => {
      this._reloadPositionen();
      this._doSavecallback();
    })
      .finally(() => this.loading = false);
  }

  createPositionVertrag() {
    this.loading = true;
    const data = { bez: this.newPosition.argument.bezeichnung || this.newPosition.bezeichnung,
                   argument: this.newPosition.argument,
                   wert: this.newPosition.argument.wert || this.newPosition.wert };
    this.vertragFinanzierungsart.customPOST(data, "einzelposition_erzeugen").then(
      response => {
        this.infoprovider = response.zuschussrechner;
        if (this.createErrors) {
          delete this.createErrors;
        }
        this._reloadPositionen();
        this.toggleAddPosition();
        this._resetNewPosition();
        this._doSavecallback();
        this.updateSnapshotDiff();
      },
      errors => {
        this.createErrors = {};
        this.createErrors = errors.data;
      }
    )
      .finally(() => this.loading = false);
  }

  zuschuss_saved() {
    // Gibt an ob die Zuschussinformationen gespeichert wurden oder nicht.
    if (this.infoprovider && this.infoprovider.gespeichert === true) {
      return true;
    }
    return false;
  }

  get_antrag_betrag_value() {
    // Gibt es Betrag zurück, der für die Finanzierungsart angzeigt werden
    // soll. Das ist normalerweise nur notwendig, wenn es sich bei der
    // Finanzierungsart um Zuschüsse oder Pauschalen handelt, da diese sich
    // im Rahmen einer Zuschussberechnung ändern können.
    if (!this.zuschuss_saved()) {
      // Wenn nicht gespeichert wurde, kann immer der ursprüngliche Wert
      // angzeigt werden, da sich seit dem daran nichts geändert haben kann:
      return this.antragKoFiArt.summe || 0;
    }
      // Falls gepspeichert wurde, hat sich der Wert eventuell geändert,
      // hier
    return this.infoprovider.details[this.modul.regel.afr_id].betrag;
  }

  get_antrag_betrag() {
    const betrag = this.get_antrag_betrag_value();
    if (betrag !== undefined) {
      return this.currency(betrag);
    }
    return "-";
  }

  get_vertrag_betrag_value() {
    if (!this.infoprovider) {
      return this.vertragFinanzierungsart.vf_betrag_aktualisiert;
    } else if (this.infoprovider.status === 1) {
      const detailinfo = this.infoprovider.details[this.modul.regel.pk];
      return detailinfo.betrag;
    }
  }

  get_vertrag_betrag() {
    const betrag = this.get_vertrag_betrag_value();
    if (betrag !== undefined) {
      return this.currency(betrag);
    }
    return "-";
  }

  get_vertrag_prozent_value(base, typ) {
    let betrag;
    if (typ === "bewilligt") {
      betrag = Number(this.vertragFinanzierungsart.vf_betrag_bewilligt);
    } else {
      betrag = Number(this.get_vertrag_betrag_value());
    }
    return 100 * betrag / base;
  }

  get_vertrag_prozent(typ) {
    if (!this.infoprovider ||
       !this.AuthService.syncHasPerm("vertragskostenpositionen.view.percentage", this.obj.user_permissions)) {
      return "";
    }
    let base;
    let title;
    let prozent;

    if (this.modul &&
        this.modul.regel &&
        this.modul.regel.argument &&
        this.modul.regel.argument.max_prozent_zuschuss) {
      if (typ === "bewilligt") {
        base = Number(this.obj.zuschuss_bewilligt_ohne_pauschale);
      } else {
        base = Number(this.infoprovider.zuschuss);
      }
      title = this.i18n.gettext("vom Zuschuss");
    } else {
      if (typ === "bewilligt") {
        base = Number(this.obj.uebersicht.veranschlagte_kosten);
      } else {
        base = Number(this.infoprovider.kennzahlen.summe_foerderfaehige_kosten);
      }
      title = this.i18n.gettext("von den förderfähigen Kosten");
    }
    if (typ === "bewilligt" &&
       this.vertragFinanzierungsart.vf_prozent_bewilligt) {
      prozent = this.vertragFinanzierungsart.vf_prozent_bewilligt;
    } else if (typ === "anerkannt" &&
              this.vertragFinanzierungsart.vf_prozent_aktualisiert) {
      prozent = this.vertragFinanzierungsart.vf_prozent_aktualisiert;
    } else {
      prozent = this.get_vertrag_prozent_value(base, typ);
    }
    if (prozent !== undefined) {
      const pStr = this.$filter("number")(prozent, 2);
      const baseStr = this.$filter("currency")(base);
      return `<br><small class="procent" title="${ pStr }% ${ title } (${ baseStr })">${ pStr }&nbsp;%</small>`;
    }
  }
}

export default StandardFinanzierungController;
