"use strict";

import angular from "angular";

class SitzungsmodulController {
  /*@ngInject*/
  constructor($rootScope, $element, $scope, $compile, SitzungsmodulRegistry) {
    this.SitzungsmodulRegistry = SitzungsmodulRegistry;
    this.$compile = $compile;
    this.$element = $element;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.loading = true;
    this.readonly = !angular.isUndefined(this.readonly) ? this.readonly : false;
    this.create();
  }

  create() {
    let template = "";
    if (angular.isArray(this.module)) {
      if (this.module.length === 0) {
        return;
      }
      for (let i = 0; i < this.module.length; i++) {
        const temp = this.buildTemplate(this.module[i], this.buildIndex(this.idx, i + 1), "vm.readonly", "vm.module[" + i + "]", "vm.top", "vm.permissionSrc");
        if (!angular.isString(temp)) {
          template += temp;
        }
      }
    } else {
      template = this.buildTemplate(this.module, this.idx, "vm.readonly", "vm.module", "vm.top", "vm.permissionSrc");
    }
    if (!template) {
      return;
    }
    const el = this.$compile(template)(this.$scope);
    this.$element.replaceWith(el);
  }

  buildTemplate(obj, idx, readonlyPropertyName, modulPropertyName, topPropertyName, permissionPropertyName) {
    let errMsg;
    if (angular.isUndefined(obj) || angular.isUndefined(obj.modulname)) {
      errMsg = "Angegebene Eigenschaft " + modulPropertyName + " am Scope hat keinen Modulnamen.";
      console.error(errMsg, obj);
      return;
    }
    const modul = this.SitzungsmodulRegistry.getMain(obj.modulname);
    if (angular.isUndefined(modul)) {
      return;
    }
    return "<" + modul + " modul='" + modulPropertyName + "' top='" + topPropertyName + "' idx='" + idx + "' readonly='" + readonlyPropertyName + "' " +
      (this.onSave ? "on-save='vm.onSave()' " : "") + (permissionPropertyName ? "permission-src='" + permissionPropertyName + "'" : "") + "></" + modul + ">";
  }

  buildIndex(idx, i) {
    let idxStr = "";
    if (angular.isUndefined(idx)) {
      return "";
    } else if (idx.length > 0) {
      idxStr = idx + ".";
    }
    return idxStr + i;
  }
}

export default {
  bindings: {
    module: "=",
    top: "=",
    readonly: "=?",
    idx: "@?",
    onSave: "&?",
    permissionSrc: "=?"
  },
  template: "",
  controller: SitzungsmodulController,
  controllerAs: "vm",
};
