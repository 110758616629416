"use strict";

import BaseModel from "dias/core/models/baseModel";

class Workflowfolgestatusfunktion extends BaseModel {
  constructor() {
    super("statusfunktionen");
  }
}

export default Workflowfolgestatusfunktion;
