"use strict";

import BaseModel from "dias/core/models/baseModel";

class Deckblatt extends BaseModel {
  constructor() {
    super("deckblaetter");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.createBatch = data => {
      return collection.customPOST(data, "batch_create");
    };

    collection.deleteBatch = gedruckte => {
      return collection.customPOST({ gedruckte: gedruckte }, "batch_delete");
    };

    collection.print = params => {
      return collection.withHttpConfig({ responseType: "blob" }).customPUT({}, "print", params);
    };

    return collection;
  }
}

export default Deckblatt;
