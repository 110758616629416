import {
  computed,
  ref,
} from "vue";

export default function TagesordnungspunktInBearbeitungAPI({
  model = ref({}),
}) {
  const isTagesordnungspunktInBearbeitungTextHide = ref(false);

  const isTagesordnungspunktInBearbeitung = computed(() => {
    return model.value.bereitgestellt === null || model.value.bedarf === null;
  });

  const isTagesordnungspunktInBearbeitungTextVisible = computed(() => {
    return isTagesordnungspunktInBearbeitung.value && !isTagesordnungspunktInBearbeitungTextHide.value;
  });

  const hideTagesordnungspunktInBearbeitungText = () => {
    isTagesordnungspunktInBearbeitungTextHide.value = true;
  };

  return {
    hideTagesordnungspunktInBearbeitungText,
    isTagesordnungspunktInBearbeitungTextVisible,
  };
}
