<div><h3>Ein Beispiel</h3><div class="row margin-top-md"><div class="col-lg-6" v-highlight=""><pre>Dateistruktur<code>styleguide-example.js
styleguide-example.pug
styleguide-example.vue
</code></pre><pre>styleguide-example.vue<code>{{ text1 }}</code></pre><pre>styleguide-example.pug<code>{{ text2 }}</code></pre></div><div class="col-lg-6" v-highlight=""><pre>styleguide-example.js<code>import VueUiSelect from ".../global/components/ui/ui-select/ui-select.vue";

export default {
  name: "vue-styleguide-filters",
  components: {
    VueUiSelect,
  },
  props: {
    foo: {
      type: Boolean,
      default: true,
    },
    bar: {
      type: [Array, String],
    },
  },
  data () {
    return {
      status: undefined,
    }
  },
  methods: {
    initData() {
      console.log("initData");
    },
  },
  computed: {
    getSomething() {
      return `something`;
    },
    getSomethingWithParameter() {
      parameter => `${ parameter } something`;
    },
  },
  created() {
    this.initData();
  },
}</code></pre></div></div></div>