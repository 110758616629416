import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Permission from "../../../../../global/components/Permission/Permission";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../../../global/components/ui/ShowMore/ShowMore.vue";
import SmartTable from "../../../../../global/components/table/table.vue";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";
import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";

import getTableApi from "../../../../../const/TableApis";
import { map, size } from "lodash";

const TABLE_ID = "KompetenzenListInProfil";

// @vue/component
export default {
  name: "ProfilDetailsPersonalItem",
  components: {
    FormstepDetail,
    Permission,
    PuxIcon,
    PuxTranslate,
    ShowMore,
    SmartTable,
  },
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
    personal: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    const {
      getHttp,
    } = HttpAPI();

    return {
      filterDate,
      getHttp,
    };
  },
  data() {
    return {
      loading: false,
      objekttypen: undefined,
    };
  },
  computed: {
    textGueltigTranslate() {
      if (this.personal.gueltig_bis) {
        return "_TXT_PROFIL_DETAILS_PERSONAL_ITEM_GUELTIG_{{gueltig_ab}}_{{gueltig_bis}}_";
      }
      return "_TXT_PROFIL_DETAILS_PERSONAL_ITEM_GUELTIG_AB_{{gueltig_ab}}_";
    },

    extraGueltigTranslate() {
      return {
        gueltig_ab: this.filterDate(this.personal.gueltig_ab),
        gueltig_bis: this.filterDate(this.personal.gueltig_bis),
      };
    },

    formstepDetailOptions() {
      return {
        // Bei AM gibt es nur Kompetenzen zu der Root-Förderorganisation ( = AM) -> ausblenden
        hideHeader: this.$Client === "dias-am" ||
          this.$Client === "dias-sdb",
        label: this.personal.o_name,
      };
    },

    kompetenzenTableOptions() {
      const OPTIONS = {
        id: TABLE_ID,
        url: getTableApi({
          id: TABLE_ID,
          params: { nutzerPk: this.nutzer.pk, personalPk: this.personal.p_id }
        }),
        cols: [
          {
            label: "_LBL_PROFIL_DETAILS_FOERDERANGEBOT_PLURAL_",
            id: "angebote",
            slot: "angebote"
          },
          {
            label: "_LBL_PROFIL_DETAILS_PERSONAL_KOMPETENZ_GUELTIG_AB_",
            id: "pk_gueltig_ab",
            sortable: true,
            sortingDefault: "desc",
            filter: "date",
            titleCallback: ({ item }) => this.filterDate(item.pk_gueltig_ab, "DD.MM.YY HH:mm [Uhr]")
          },
          {
            label: "_LBL_PROFIL_DETAILS_PERSONAL_KOMPETENZ_GUELTIG_BIS_",
            id: "pk_gueltig_bis",
            sortable: true,
            filter: "date",
            titleCallback: ({ item }) => this.filterDate(item.pk_gueltig_bis, "DD.MM.YY HH:mm [Uhr]")
          },
          {
            label: "_LBL_PROFIL_DETAILS_PERSONAL_KOMPETENZ_ANMERKUNG_",
            id: "pk_anmerkung",
            slot: "more",
          },
        ],
        rowActions: [],
        actions: [],
        label: "_LBL_PROFIL_DETAILS_PERSONAL_KOMPETENZEN_TABLE_",
        sortable: true,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
        csvHide: true,
      };
      if (size(this.objekttypen) > 1) {
        OPTIONS.cols.splice(0, 0, {
          label: "_LBL_PROFIL_DETAILS_PERSONAL_KOMPETENZ_CONTENT_TYPE_",
          id: "content_type_bez",
          sortable: false,
        });
      }
      return OPTIONS;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.getHttp({
        url: "katalog/",
        urlParams: {
          key: "personal_kompetenzen_organisationen_contenttypen",
          organisation: this.personal.o_id
        },
        apiSaveId: `personal_kompetenzen_organisationen_contenttypen__${ this.personal.o_id }`
      }).then(
        response => this.objekttypen = map(response, ook => {
          return {
            pk: ook.content_type,
            bez: ook.ct_name
          };
        })
      ).finally(() => this.loading = false);
    },
  },
};
