import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "FoerdermittelModalAnpassen",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "mib_kbez",
            type: "text",
            label: "_LBL_FOERDERMITTEL_MODAL_ANPASSEN_NAME_",
            required: true,
            view: "v",
            translate: true,
          },
          {
            id: "mib_kennung",
            type: "text",
            label: "_LBL_FOERDERMITTEL_MODAL_ANPASSEN_KENNUNG_",
            required: true,
            view: "v",
            translate: true,
          },
          {
            id: "mib_mittel",
            type: "currency",
            label: "_LBL_FOERDERMITTEL_MODAL_ANPASSEN_MITTEL_",
            maxlength: 20,
            view: "v",
            translate: true,
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      this.model = {
        mib_kbez: this.row.mib_kbez,
        mib_kennung: this.row.mib_kennung,
        mib_mittel: this.row.mib_mittel,
      };
    },

    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      data.pk = this.row.pk;
      this.putHttp({ url: `foerdermittel/${ this.row.pk }/`, data }).then(
        () => {
          this.addNotification({ text: "_MSG_FOERDERMITTEL_MODAL_ANPASSEN_SUCCESS_" });
          this.close({ status: true });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
