import FinanzierungsPlan from "../KFPlan/FinanzierungsPlan/FinanzierungsPlan.vue";
import GeschaeftsregelModul from "../../Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";
import KostenPlan from "../KFPlan/KostenPlan/KostenPlan.vue";
import PeriodsTabs from "../PeriodsTabs/PeriodsTabs.vue";
import PuxAlert from "../../../global/components/PuxAlert/PuxAlert.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import ZuschussPlan from "../KFPlan/ZuschussPlan/ZuschussPlan.vue";

import translate from "../../../global/directives/translate";

import KFPeriodenModuleMixin from "../Mixins/KFPeriodenModuleMixin";
import {
  FilterCurrencyMixin,
  FilterDateMixin,
} from "../../../global/mixins/FiltersMixin";
import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import { EventBus } from "../../../global/functions/event-bus";
import { createNamespacedHelpers } from "vuex";
import {
  cloneDeep,
  forEach,
  filter,
  get,
  isEmpty,
  sortBy,
} from "lodash-es";

import moment from "moment";

const {
  mapMutations,
  mapState,
} = createNamespacedHelpers("snapshot");

// @vue/component
export default {
  name: "KFPerioden",
  components: {
    FinanzierungsPlan,
    GeschaeftsregelModul,
    KostenPlan,
    PeriodsTabs,
    PuxAlert,
    PuxTranslate,
    SnapshotIcon,
    ZuschussPlan,
  },
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterDateMixin,
    KFPeriodenModuleMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    kostenModuleNotKf: {
      type: Array,
      required: false,
      default: () => [],
      info: "GR-Module für Anzeigekontext-Kosten (kein KF-Module)",
    },
    finanzierungModuleNotKf: {
      type: Array,
      required: false,
      default: () => [],
      info: "GR-Module für Anzeigekontext-Finanzierung (kein KF-Module)",
    },
    idx: {
      type: Number,
      required: true,
    },
    infoprovider: {
      type: Object,
      required: false,
      default: undefined,
      info: "Zuschussinfo",
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
      info: "Snapshot",
    },
    obj: {
      type: Object,
      required: true,
      info: "Objekt",
    },
    kostenplan: {
      type: Object,
      required: true,
      info: "Kostenplan",
    },
    finanzierungsplan: {
      type: Object,
      required: true,
      info: "Finanzierungsplan",
    },
    nummerierungausblenden: {
      type: Boolean,
      required: false,
    },
    readonly: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    saveCallback: {
      type: Function,
      required: true,
    },
    updateinfoprovider: {
      type: Function,
      required: true,
    },
    baseUrl: {
      type: String,
      required: false, // TODO: soll true sein, wenn wir Antrag in angular wegschmeissen
      default: undefined,
    },
    updateModule: {
      type: Function,
      required: false,
      default: undefined,
    },
  },
  setup() {
    const { getHttp } = HttpAPI();

    return { getHttp };
  },
  data() {
    return {
      loading: false,
      sumPeriodenFromModuleKosten: {},
      sumPeriodenFromModuleKostenSnapshot: {},
      sumPeriodenFromModuleFinanzierung: {},
      sumPeriodenFromModuleFinanzierungSnapshot: {},
      sumPeriodenFromModuleZuschuss: {},
      sumPeriodenFromModuleZuschussSnapshot: {},
      startIndexKostenPerioden: 1,
      durchfuehrungBisLocal: undefined,
      durchfuehrungVonLocal: undefined,
    };
  },
  computed: {
    snapshotKoFi() {
      if (this.snapshot) {
        const SNAPSHOT = cloneDeep(this.snapshot);
        SNAPSHOT.asn_snapshot = SNAPSHOT.asn_snapshot.kofi;
        return SNAPSHOT;
      }
      return undefined;
    },

    perioden() {
      let PERIODE = get(this.obj, "regelsatz_obj.perioden", []);
      PERIODE = filter(PERIODE, item => {
        if ((moment(item.pe_von).isBefore(this.durchfuehrungBis) && moment(item.pe_bis).isAfter(this.durchfuehrungVon)) || get(this.infoprovider.perioden, item.pk)) {
          return item;
        }
      });
      return sortBy(PERIODE, ["pe_von"]);
    },

    extraKF() {
      return {
        periodenFiltered: this.periodenFiltered,
      };
    },

    extraForModule() {
      return {
        perioden: this.perioden,
        setPeriodenWert: this.setPeriodenWertFromModule
      };
    },

    extraStatic() {
      return {
        perioden: this.perioden,
        setPeriodenWert: this.setPeriodenWertFromModule
      };
    },

    extraStaticKosten() {
      const EXTRA = cloneDeep(this.extraStatic);
      EXTRA.text = "_TXT_ANTRAEGE_TABLE_HEADER_KOSTEN_";
      return EXTRA;
    },

    extraStaticFinanzierung() {
      const EXTRA = cloneDeep(this.extraStatic);
      EXTRA.text = "_TXT_ANTRAEGE_TABLE_HEADER_FINANZIERUNG_";
      return EXTRA;
    },

    extraStaticZuschuss() {
      const EXTRA = cloneDeep(this.extraStatic);
      EXTRA.text = "_TXT_ANTRAEGE_TABLE_HEADER_GESAMTZUSCHUSS_";
      return EXTRA;
    },

    sumPeriodenDefault() {
      const SUM_PERIODEN = {
        all: {
          wert: 0,
          wertCurrency: this.filterCurrency(0),
        }
      };
      forEach(this.perioden, period => {
        const PERIOD_PK = period.pk;
        SUM_PERIODEN[PERIOD_PK] = {
          wert: 0,
          wertCurrency: this.filterCurrency(0),
        };
      });
      return SUM_PERIODEN;
    },

    sumPeriodenKosten() {
      return this.getSumPerioden({ sumPeriodenFromModule: this.sumPeriodenFromModuleKosten });
    },

    sumPeriodenSnapshotKosten() {
      return this.getSumPerioden({ sumPeriodenFromModule: this.sumPeriodenFromModuleKostenSnapshot });
    },

    sumPeriodenFinanzierung() {
      return this.getSumPerioden({ sumPeriodenFromModule: this.sumPeriodenFromModuleFinanzierung });
    },

    sumPeriodenSnapshotFinanzierung() {
      return this.getSumPerioden({ sumPeriodenFromModule: this.sumPeriodenFromModuleFinanzierungSnapshot });
    },

    sumPeriodenZuschuss() {
      return this.getSumPerioden({ sumPeriodenFromModule: this.sumPeriodenFromModuleZuschuss });
    },

    sumPeriodenSnapshotZuschuss() {
      return this.getSumPerioden({ sumPeriodenFromModule: this.sumPeriodenFromModuleZuschussSnapshot });
    },

    statusDiff() {
      return !!(this.statusDiffPerioden || this.statusDiffNotPerioden);
    },

    statusDiffPerioden() {
      return this.statusDiffKosten ||
        this.statusDiffFinanzierung ||
        this.statusDiffZuschuss;
    },

    statusDiffKosten() {
      return this.getStatusDiff({ typeKoFi: "kosten" });
    },

    statusDiffFinanzierung() {
      return this.getStatusDiff({ typeKoFi: "finanzierung" });
    },

    statusDiffZuschuss() {
      return this.getStatusDiff({ typeKoFi: "zuschuss" });
    },

    diffFooterDefault() {
      const DIFF = {
        status: false,
        all: false,
      };
      forEach(this.perioden, period => {
        const PERIOD_PK = period.pk;
        DIFF[PERIOD_PK] = false;
      });
      return DIFF;
    },

    diffFooterKosten() {
      return this.getDiffFooter({ typeKoFi: "Kosten" });
    },

    diffFooterFinanzierung() {
      return this.getDiffFooter({ typeKoFi: "Finanzierung" });
    },

    diffFooterZuschuss() {
      return this.getDiffFooter({ typeKoFi: "Zuschuss" });
    },

    snapshotDatum() {
      return this.filterDate(this.snapshot.asn_snapshot_datum, "DD.MM.YYYY HH:mm");
    },

    startIndexFinanzierungPerioden() {
      return this.startIndexKostenPerioden + this.kostenModuleGrouped.perioden.length;
    },

    startIndexZuschussPerioden() {
      return this.startIndexFinanzierungPerioden + this.finanzierungModuleGrouped.perioden.length;
    },

    startIndexKostenNotPerioden() {
      return this.startIndexZuschussPerioden + this.zuschussModuleGrouped.perioden.length;
    },

    startIndexFinanzierungNotPerioden() {
      return this.startIndexKostenNotPerioden + this.kostenModuleGrouped.notPerioden.length;
    },

    startIndexZuschussNotPerioden() {
      return this.startIndexFinanzierungNotPerioden + this.finanzierungModuleGrouped.notPerioden.length;
    },

    statusDiffNotPerioden() {
      return this.hasDiffModules({
        modules: this.kostenModuleGrouped.notPerioden,
      }) || this.hasDiffModules({
        modules: this.kostenModuleNotKf,
      }) || this.hasDiffModules({
        modules: this.finanzierungModuleGrouped.notPerioden,
      }) || this.hasDiffModules({
        modules: this.finanzierungModuleNotKf,
      });
    },

    durchfuehrungBis() {
      if (this.durchfuehrungBisLocal) {
        return this.durchfuehrungBisLocal;
      }
      return get(this.obj, "durchfuehrung_bis");
    },

    durchfuehrungVon() {
      if (this.durchfuehrungVonLocal) {
        return this.durchfuehrungVonLocal;
      }
      return get(this.obj, "durchfuehrung_von");
    },

    isDurchfuehrung() {
      return !!(this.durchfuehrungVon && this.durchfuehrungBis);
    },

    hasRegelsatzPerioden() {
      return !isEmpty(get(this.obj, "regelsatz_obj.perioden", []));
    },

    ...mapState([
      "STATUS_DIFF_FOR_REGELS",
    ]),
  },
  watch: {
    statusDiff: {
      immediate: true,
      flush: "post",
      handler(newVal) {
        this.MUT_SET_STATUS_DIFF_FOR_REGEL({
          regelId: this.id,
          statusDiff: newVal,
        });
      },
    },
    perioden: {
      immediate: true,
      handler() {
        this.setPeriodKeyShow();
      },
    },
  },
  created() {
    this.setPeriodKeyShow();
    this.initEventBus();
  },
  beforeUnmount() {
    this.MUT_DELETE_STATUS_DIFF_FOR_REGEL({ regelId: this.id });
    this.destroyEventBus();
  },
  methods: {
    setPeriodKeyShow() {
      if (!this.statusShowPerioden) {
        return;
      }
      this.periodKeyShow = "all";
    },

    changeTab({ tab }) {
      this.periodKeyShow = tab.periodKey;
    },

    setPeriodenWertFromModule({ model, regel, statusSnapshot, typeKoFi }) {
      if (typeKoFi === "kosten") {
        if (statusSnapshot) {
          this.sumPeriodenFromModuleKostenSnapshot[regel] = cloneDeep(model);
        } else {
          this.sumPeriodenFromModuleKosten[regel] = cloneDeep(model);
        }
      } else if (typeKoFi === "finanzierung") {
        if (statusSnapshot) {
          this.sumPeriodenFromModuleFinanzierungSnapshot[regel] = cloneDeep(model);
        } else {
          this.sumPeriodenFromModuleFinanzierung[regel] = cloneDeep(model);
        }
      } else if (typeKoFi === "zuschuss") {
        if (statusSnapshot) {
          this.sumPeriodenFromModuleZuschussSnapshot[regel] = cloneDeep(model);
        } else {
          this.sumPeriodenFromModuleZuschuss[regel] = cloneDeep(model);
        }
      }
    },

    getSumPerioden({ sumPeriodenFromModule }) {
      const SUM_PERIODEN = cloneDeep(this.sumPeriodenDefault);
      forEach(sumPeriodenFromModule, dataFromModule => {
        SUM_PERIODEN.all.wert += dataFromModule.all.wert;
        forEach(this.perioden, period => {
          const PERIOD_PK = period.pk;
          SUM_PERIODEN[PERIOD_PK].wert += get(dataFromModule[PERIOD_PK], "wert", 0);
          SUM_PERIODEN[PERIOD_PK].wertCurrency = this.filterCurrency(SUM_PERIODEN[PERIOD_PK].wert);
        });
      });
      SUM_PERIODEN.all.wertCurrency = this.filterCurrency(SUM_PERIODEN.all.wert);
      return SUM_PERIODEN;
    },

    getStatusDiff({ typeKoFi }) {
      if (!this.snapshot) {
        return false;
      }
      const KEY = `${ typeKoFi }ModuleGrouped`;
      if (!this[KEY] || !this[KEY].perioden) {
        return false;
      }
      let statusChange = false;
      forEach(this[KEY].perioden, modul => {
        const REGEL_ID = modul.regel.regel;
        if (this.STATUS_DIFF_FOR_REGELS[REGEL_ID]) {
          statusChange = true;
          return false;
        }
      });
      return statusChange;
    },

    getDiffFooter({ typeKoFi }) {
      const DIFF = cloneDeep(this.diffFooterDefault);
      if (!this[`statusDiff${ typeKoFi }`]) {
        return DIFF;
      }
      const SUM_SNAPSHOT = this[`sumPeriodenSnapshot${ typeKoFi }`];
      const SUM = this[`sumPerioden${ typeKoFi }`];
      forEach(this.perioden, period => {
        const PERIOD_PK = period.pk;
        if (SUM[PERIOD_PK].wertCurrency !== SUM_SNAPSHOT[PERIOD_PK].wertCurrency) {
          DIFF[PERIOD_PK] = true;
          DIFF.status = true;
        }
      });
      if (SUM.all.wertCurrency !== SUM_SNAPSHOT.all.wertCurrency) {
        DIFF.all = true;
        DIFF.status = true;
      }
      return DIFF;
    },

    hasDiffModules({ modules = [] }) {
      let hasDiff = false;
      forEach(modules, module => {
        const REGEL_ID = module.regel.regel;
        hasDiff = this.STATUS_DIFF_FOR_REGELS[REGEL_ID];
        if (hasDiff) {
          return false;
        }
      });
      return hasDiff;
    },

    initEventBus() {
      EventBus.$on("durchfuehrungszeitraum.update", this.setDurchfuehurngzeitraum);
    },

    destroyEventBus() {
      EventBus.$off("durchfuehrungszeitraum.update", this.setDurchfuehurngzeitraum);
    },

    setDurchfuehurngzeitraum() {
      return this.getHttp({ url: this.baseUrl }).then(
        response => {
          this.durchfuehrungVonLocal = get(response, "durchfuehrung_von");
          this.durchfuehrungBisLocal = get(response, "durchfuehrung_bis");
        }
      );
    },

    ...mapMutations([
      "MUT_SET_STATUS_DIFF_FOR_REGEL",
      "MUT_DELETE_STATUS_DIFF_FOR_REGEL",
    ]),
  },
};
