import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import TaskLogStatus from "../../../global/components/TaskLog/TaskLogStatus/TaskLogStatus.vue";
import SmartTable from "../../../global/components/table/table.vue";

import PermissionMixin from "../../../global/mixins/PermissionMixin";

import getTableApi from "../../../const/TableApis";
import moment from "moment";
import { gettext } from "../../../global/functions/utils";

const TABLE_ID = "tasklog";
const permTasklogAll = "tasklog.view_all";
const permNutzerList = "nutzer.view";

// @vue/component
export default {
  name: "TaskLogList",
  components: {
    AngularLink,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
    TaskLogStatus,
  },
  mixins: [
    PermissionMixin,
  ],
  props: {
    fixed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [
          {
            label: "_LBL_TASK_LOG_LIST_STATUS_",
            id: "tl_status",
            sortable: true,
            sortId: "tl_status",
            slot: "task-log-status",
            className: "text-center text-nowrap",
          },
          {
            label: "_LBL_TASK_LOG_LIST_BEZEICHNUNG_",
            id: "tl_kbez",
            sortable: true,
            sortId: "tl_kbez",
            slot: "tl_kbez",
          },
          {
            label: "_LBL_TASK_LOG_LIST_UNTERAUFGABEN_",
            id: "count_unteraufgaben",
            sortable: true,
            sortId: "count_unteraufgaben",
          },
          {
            label: "_LBL_TASK_LOG_LIST_ERSTELLT_AM_",
            id: "crdate",
            sortable: true,
            sortId: "crdate",
            sortingDefault: "desc",
            filter: "date",
            filterParameter: "DD.MM.YYYY HH:mm",
          },
          {
            label: "_LBL_TASK_LOG_LIST_ABGESCHLOSSEN_AM_",
            id: "tl_date_done",
            filter: "date",
            filterParameter: "DD.MM.YYYY HH:mm",
            sortable: true,
            sortId: "tl_date_done",
          },
          {
            label: "_LBL_TASK_LOG_LIST_ABARBEITUNGSSCHLANGE_",
            id: "queue_bez",
            sortable: true,
            sortId: "queue__tq_kbez",
            permission: ["tasklog.view.queue"],
            hide: true
          },
        ],
        rowActions: [
          {
            label: "_LBL_TASK_LOG_LIST_ACTIONS_ROW_DETAILS_",
            route: "root.tasklog.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [],
        label: "_LBL_TASK_LOG_LIST_HINTERGRUNDAUFGABEN_",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      filters: [
        {
          type: "text",
          id: "kbez",
          label: "_LBL_TASK_LOG_LIST_KURZBEZEICHNUNG_",
          main: true,
        },
        {
          type: "daterange",
          id: "crdate",
          label: "_LBL_TASK_LOG_LIST_ERSTELLT_AM_",
          placeholderMin: "_LBL_TASK_LOG_LIST_BEGINN_BELIEBIG_",
          placeholderMax: "_LBL_TASK_LOG_LIST_ENDE_BELIEBIG_",
          default: {
            crdate_after: moment().format("YYYY-MM-DD"),
          },
          alwaysVisible: true,
        },
        {
          type: "select",
          id: "tl_status",
          url: "katalog/?fields=pk&fields=bez&key=filter_tasklogstatus",
          label: gettext("_LBL_TASK_LOG_LIST_STATUS_"),
          keyLabel: "bez",
          keyId: "pk",
          search: true,
          searchList: ["bez"],
        },
        {
          type: "boolean",
          id: "include_unteraufgabe",
          label: gettext("_LBL_TASK_LOG_LIST_UNTERAUFGABEN_ANZEIGEN_"),
          trueValue: "_LBL_TASK_LOG_LIST_TRUE_",
          falseValue: "_LBL_TASK_LOG_LIST_FALSE_",
          defaultLabel: "_LBL_TASK_LOG_LIST_NICHT_AUSGEWAEHLT_",
        },
        {
          type: "multiselect",
          id: "queue",
          url: "katalog/?key=taskqueues",
          label: gettext("_LBL_TASK_LOG_LIST_ABARBEITUNGSSCHLANGE_"),
          keyLabel: "tq_kbez",
          keyId: "pk",
          search: true,
          searchList: ["tq_kbez"],
          permission: ["tasklog.view.queue"],
        },
      ],
    };
  },
  created() {
    this.updateTableOptions();
  },
  methods: {
    updateTableOptions() {
      if (this.checkPermissionsSync({ perm: [permTasklogAll, permNutzerList] })) {
        this.options.cols.splice(2, 0, {
          label: gettext("_LBL_TASK_LOG_LIST_NUTZER_"),
          id: "nutzer__n_loginname",
          sortable: true,
          sortId: "nutzer__n_loginname",
          slot: "nutzer__n_loginname",
        });
        this.filters.push({
          type: "select",
          id: "nutzer",
          url: "nutzer/?fields=pk&fileds=name",
          label: gettext("_LBL_TASK_LOG_LIST_NUTZER_"),
          keyLabel: "name",
          keyId: "pk",
          searchParameter: "name",
          searchGlobal: true,
        });
      }
    },
  },
};
