import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SachberichtvorlageGeschaeftregel from "./SachberichtvorlageGeschaeftregel/SachberichtvorlageGeschaeftregel.vue";
import SachberichvorlageGeschaeftsregelAdd
  from "./SachberichvorlageGeschaeftsregelAdd/SachberichvorlageGeschaeftsregelAdd.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import { cloneDeep } from "lodash-es";

// @vue/component
export default {
  name: "SachberichtvorlageDetailsKonfiguration",
  components: {
    FormElement,
    FormstepDetail,
    Modal,
    PuxAlert,
    PuxButton,
    PuxTranslate,
    SachberichtvorlageGeschaeftregel,
    SachberichvorlageGeschaeftsregelAdd,
  },
  props: {
    sachberichtvorlage: {
      type: Object,
      required: true,
    },
    sachberichtvorlageUrl: {
      type: String,
      required: true,
    },
    reloadSignale: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const {
      deleteHttp,
      getListHttp,
      postHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      deleteHttp,
      getListHttp,
      postHttp,
    };
  },
  data() {
    return {
      sachberichtRegelnLoading: false,
      isChanging: false,
      sachberichtregelList: [],
      errors: {},
      showAdd: false,
      confirmDeleteShow: false,
      confirmDeleteOptions: {},
      htmlId: "sachberichtvorlage_details_formstep_konfiguration",
      selectorClose: undefined,
      regelMoveUpButtonIdPrefix: "move_up_button_",
      regelMoveDownButtonIdPrefix: "move_down_button_"
    };
  },
  computed: {
    sachberichtvorlageRegelnUrl() {
      return `${ this.sachberichtvorlageUrl }regeln/`;
    },

    formstepDetailOptions() {
      return {
        label: "_LBL_SACHBERICHTVORLAGE_DETAILS_GESCHAEFTSREGELN_",
      };
    },
  },
  created() {
    this.loadSachberichtregeln();
  },
  methods: {
    loadSachberichtregeln() {
      this.sachberichtRegelnLoading = true;
      this.getListHttp({
        url: this.sachberichtvorlageRegelnUrl,
      }).then(
        response => {
          this.sachberichtregelList = response;
        }
      ).finally(
        () => {
          this.sachberichtRegelnLoading = false;
        }
      );
    },

    toggleRegel({ regelIndex }) {
      this.sachberichtregelList[regelIndex].showDetails = !this.sachberichtregelList[regelIndex].showDetails;
    },

    toggleShowAdd() {
      this.showAdd = !this.showAdd;
    },

    setChanging({ changing }) {
      this.isChanging = changing;
    },

    openModalConfirmDeleteSachberichtregel({ regelIndex, deleteButtonHtmlId }) {
      this.confirmDeleteOptions = {
        okClass: "btn btn-primary",
        title: "_LBL_BTN_SACHBERICHTVORLAGE_DETAILS_REMOVE_REGEL_",
        msg: "_MSG_SACHBERICHTVORLAGE_DETAILS_REGEL_ENTFERNEN_CONFIRM_",
        okLabel: "_LBL_BTN_SACHBERICHTVORLAGE_DETAILS_FORTFAHREN_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: () => this.removeSachberichtregel({ regelIndex }),
        cancelCallback: this.closeModalConfirmDeleteSachberichtregel,
        loading: false,
      };
      this.confirmDeleteShow = true;
      this.selectorClose = [
        `#${ deleteButtonHtmlId }`,
        `#${ this.htmlId }_body`
      ];
    },

    closeModalConfirmDeleteSachberichtregel() {
      this.confirmDeleteShow = false;
    },

    moveUpSachberichtregelUI({ regelIndex }) {
      const CURRENT_REGEL = cloneDeep(this.sachberichtregelList[regelIndex]);
      this.sachberichtregelList.splice(regelIndex, 1);
      CURRENT_REGEL.sbr_pos--;
      const NEW_INDEX = regelIndex - 1;
      this.sachberichtregelList[NEW_INDEX].sbr_pos = this.sachberichtregelList[NEW_INDEX].sbr_pos + 1;
      this.sachberichtregelList.splice(NEW_INDEX, 0, CURRENT_REGEL);
      setTimeout(() => {
        $(`#${ this.regelMoveUpButtonIdPrefix }${ NEW_INDEX }`).focus();
      });
    },

    moveDownSachberichtregelUI({ regelIndex }) {
      const CURRENT_REGEL = cloneDeep(this.sachberichtregelList[regelIndex]);
      this.sachberichtregelList.splice(regelIndex, 1);
      CURRENT_REGEL.sbr_pos++;
      const NEW_INDEX = regelIndex + 1;
      this.sachberichtregelList[regelIndex].sbr_pos = this.sachberichtregelList[regelIndex].sbr_pos - 1;
      this.sachberichtregelList.splice(NEW_INDEX, 0, CURRENT_REGEL);
      setTimeout(() => {
        $(`#${ this.regelMoveDownButtonIdPrefix }${ NEW_INDEX }`).focus();
      });
    },

    moveUpSachberichtregel({ regelIndex }) {
      if (this.isChanging) {
        return;
      }
      this.isChanging = true;
      this.postHttp({
        url: `${ this.sachberichtvorlageRegelnUrl }${ this.sachberichtregelList[regelIndex].pk }/nach_oben/`,
      }).then(
        () => {
          this.moveUpSachberichtregelUI({ regelIndex: regelIndex });
          this.addNotification({ text: "_MSG_SACHBERICHTVORLAGE_DETAILS_REGEL_MOVING_SUCCESS_" });
        },
        () => {
          this.addNotification({ type: "error", text: "_MSG_SACHBERICHTVORLAGE_DETAILS_REGEL_MOVING_ERROR_" });
        },
      ).finally(
        () => {
          this.isChanging = false;
        }
      );
    },

    moveDownSachberichtregel({ regelIndex }) {
      if (this.isChanging) {
        return;
      }
      this.isChanging = true;
      this.postHttp({
        url: `${ this.sachberichtvorlageRegelnUrl }${ this.sachberichtregelList[regelIndex].pk }/nach_unten/`,
      }).then(
        () => {
          this.moveDownSachberichtregelUI({ regelIndex: regelIndex });
          this.addNotification({ text: "_MSG_SACHBERICHTVORLAGE_DETAILS_REGEL_MOVING_SUCCESS_" });
        },
        () => {
          this.addNotification({ type: "error", text: "_MSG_SACHBERICHTVORLAGE_DETAILS_REGEL_MOVING_ERROR_" });
        },
      ).finally(
        () => {
          this.isChanging = false;
        }
      );
    },

    removeSachberichtregelUI({ regelIndex }) {
      this.sachberichtregelList.splice(regelIndex, 1);
      for (let i = regelIndex; i < this.sachberichtregelList.length; i++) {
        this.sachberichtregelList[i].sbr_pos = this.sachberichtregelList[i].sbr_pos - 1;
      }
    },

    removeSachberichtregel({ regelIndex }) {
      if (this.isChanging) {
        return;
      }
      this.isChanging = true;
      this.deleteHttp({
        url: `${ this.sachberichtvorlageRegelnUrl }${ this.sachberichtregelList[regelIndex].pk }/`,
      }).then(
        () => {
          this.removeSachberichtregelUI({ regelIndex: regelIndex });
          this.closeModalConfirmDeleteSachberichtregel();
          this.reloadSignale();
          this.addNotification({ text: "_MSG_SACHBERICHTVORLAGE_DETAILS_REMOVE_REGEL_SUCCESS_" });
        },
        () => {
          this.addNotification({ type: "error", text: "_MSG_SACHBERICHTVORLAGE_DETAILS_REMOVE_REGEL_ERROR_" });
        },
      ).finally(
        () => {
          this.isChanging = false;
        },
      );
    },

    addSachberichtregel({ regel }) {
      this.sachberichtregelList.push(regel);
      this.reloadSignale();
    },
  },
};
