"use strict";

import diasLoading from "dias/core/loading";

import StatusGruppenComponent from "./statusgruppen.component";

const diasStatusGruppen = angular.module("dias.statusgruppen", [
  diasLoading.name
])

  .component("diasStatusGruppen", StatusGruppenComponent);

export default diasStatusGruppen;
