import Modal from "../../../../global/components/Modal/Modal.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotItem from "../../../Snapshot/SnapshotItem/SnapshotItem.vue";

import translate from "../../../../global/directives/translate";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import {
  isEmpty,
} from "lodash-es";

// @vue/component
export default {
  name: "AktualisierterZuschussPosition",
  components: {
    Modal,
    Permission,
    PuxButton,
    PuxIcon,
    SnapshotIcon,
    SnapshotItem,
  },
  directives: {
    translate,
  },
  props: {
    anpassung: {
      type: Object,
      required: true,
      info: "Hauptinformation",
    },
    anpassungIndex: {
      type: Number,
      required: true,
      info: "Index in Array",
    },
    readonly: {
      type: Boolean,
      required: true,
      info: "Status, mit dem man nichts bearbeiten darf",
    },
    htmlId: {
      type: String,
      required: true,
      info: "Parent-HTML-Id",
    },
    vertrag: {
      type: Object,
      required: true,
      info: "Vertrag",
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    isSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    isSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    diff: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  emits: [
    "deleteAnpassung",
    "updateAnpassung",
  ],
  setup() {
    const {
      addNotification,
      deleteHttp,
    } = HttpAPI();

    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      addNotification,
      deleteHttp,
      filterCurrency,
    };
  },
  data() {
    return {
      confirmShow: undefined,
      confirmOptions: undefined,
    };
  },
  computed: {
    htmlIdLocal() {
      return `${ this.htmlId }_${ this.anpassungIndex }`;
    },

    anpassungBetragCurrency() {
      return this.filterCurrency(this.anpassung.vza_betrag || 0);
    },

    buttonDeleteId() {
      return `${ this.htmlIdLocal }_delete_confirm`;
    },

    buttonUpdateId() {
      return `${ this.htmlIdLocal }_update`;
    },

    selectorClose() {
      return [
        `#${ this.buttonDeleteId }`,
        `#${ this.htmlId }`,
      ];
    },

    ariaDescribedbyForButtons() {
      // TODO: Barrierefreiheit
      return "";
    },

    snapshotIconType() {
      if (this.isSnapshotDelete) {
        return "delete";
      }
      if (this.isSnapshotAdd) {
        return "add";
      }
      if (this.diff && !isEmpty(this.diff)) {
        return "changes";
      }
      return "";
    },
  },
  methods: {
    openDeletePosition() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_MODAL_ANPASSUNG_DELETE_HEADER_{{kbez}}_",
        okLabel: "_BTN_DELETE_",
        okCallback: this.deletePosition,
        cancelCallback: this.closeConfirm,
        loading: false,
        extra: {
          kbez: this.anpassung.vza_kbez,
        },
      };
      this.confirmShow = true;
    },

    deletePosition() {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: `vertraege/${ this.vertrag.pk }/zuschussanpassungen/${ this.anpassung.pk }`,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_MODAL_ANPASSUNG_DELETE_SUCCESS_",
          });
          this.closeConfirm();
          this.$emit("deleteAnpassung");
        },
        () => {
          this.addNotification({
            text: "_MSG_MODAL_ANPASSUNG_DELETE_ERROR_",
            type: "error",
          });
        }
      ).then(
        () => {
          this.confirmOptions.loading = false;
        }
      );
    },

    closeConfirm() {
      this.confirmShow = false;
    },

    updateAnpassung() {
      const SELECTOR_CLOSE = [
        `#${ this.buttonUpdateId }`,
        `#${ this.htmlId }`,
      ];
      this.$emit("updateAnpassung", { anpassung: this.anpassung, selectorClose: SELECTOR_CLOSE });
    },
  },
};
