export default {
  ast_name: {
    type: "text",
    id: "ast_name",
    view: "v",
    maxlength: 400,
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_NAME_",
    required: true,
    translate: true,
  },
  rechtsform: {
    type: "select",
    id: "rechtsform",
    view: "v",
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_RECHTSFORM_",
    required: true,
    url: "katalog/?key=krechtsform&ref_aktiv=true&ref_foerderberechtigt=true",
    urlRetrieve: "katalog/?key=krechtsform",
    apiSaveId: "rechtsform",
    keyId: "ref_id",
    keyLabelCallback: ({ item }) => `${ item.ref_kbez } - ${ item.ref_bez }`,
    translate: true,
  },
  ast_namezusatz: {
    type: "text",
    id: "ast_namezusatz",
    view: "v",
    maxlength: 400,
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_ZUSATZ_NAME_",
    required: false,
    translate: true,
  },
  kategorien: {
    type: "multiselect",
    id: "kategorien",
    view: "v",
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_KATEGORIEN_",
    url: "katalog/?key=organisationskategorien&ok_deaktiviert=False",
    keyLabel: "bez",
    keyId: "pk",
    search: true,
    required: false,
    translate: true,
  },
  ast_strasse: {
    type: "text",
    id: "ast_strasse",
    view: "v",
    maxlength: 100,
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_STRASSE_",
    required: true,
    translate: true,
  },
  ast_hsnr: {
    type: "text",
    id: "ast_hsnr",
    view: "v",
    maxlength: 10,
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_HAUSNUMMER_",
    required: false,
    translate: true,
  },
  ast_plz: {
    type: "text",
    id: "ast_plz",
    view: "v",
    maxlength: 10,
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_PLZ_",
    required: true,
    translate: true,
  },
  ast_ort: {
    type: "text",
    id: "ast_ort",
    view: "v",
    maxlength: 100,
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_ORT_",
    required: true,
    translate: true,
  },
  ast_email: {
    type: "text",
    id: "ast_email",
    view: "v",
    maxlength: 200,
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_EMAIL_",
    required: false,
    translate: true,
  },
  buchfuehrungsmethode: {
    type: "select",
    id: "buchfuehrungsmethode",
    apiSaveId: "buchfuehrungsmethode",
    view: "v",
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_BUCHFUEHRUNGSMETHODE_",
    required: true,
    keyId: "kbm_id",
    keyLabel: "kbm_kbez",
    translate: true,
  },
  create_user: {
    type: "boolean",
    id: "create_user",
    view: "v-alt",
    label: "",
    translate: true,
  },
  antragstellerorgtyp: {
    type: "select",
    id: "typen",
    label: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_ORGANISATIONSTYP_",
    translate: true,
    required: true,
    view: "v",
    keyId: "pk",
    keyLabel: "at_kbez",
    deselect: false,
  },
};
