import BaseController from "dias/standard_foerdermodule/standard_foerdermodule.base.controller";

class AnlagenController extends BaseController {
  /*@ngInject*/
  constructor($scope, $q, i18n, Foerdermodule, Antragstellerorganisationen) {
    super($scope, $q, Foerdermodule);
    this.$scope = $scope;
    this.gettext = i18n.gettext;
    this.Antragstellerorganisationen = Antragstellerorganisationen;
    this.dokumentePermissions = {
      view: "dokument.view",
      create: "_notSupported",
      delete: "_notSupported",
      update: "_notSupported"
    };
    this.setRefObj();
    this.htmlId = `regel_${ this.modul.regel.regel_nummer }`;
  }

  astChanged() {
    this.changed = true;
    this.$scope.$applyAsync(() => {
      this.refObj = angular.merge(
        {},
        this.Antragstellerorganisationen.one(this.obj.antragstellerorg),
        { pk: this.obj.antragstellerorg }
      );
      this.changed = false;
    });
  }

  setRefObj() {
    if (this.parameter.antragsteller === true) {
      this.$scope.$watch(() => this.obj.antragstellerorg, () => {
        if (this.obj.antragstellerorg) {
          this.astChanged();
        }
      });
    } else {
      this.refObj = this.obj;
    }
  }
}

export default AnlagenController;
