"use strict";

import BaseModel from "dias/core/models/baseModel";


class Sitzungsteilnehmer extends BaseModel {
  constructor() {
    super("teilnehmer");
  }
}

export default Sitzungsteilnehmer;
