import ShowMore from "../../global/components/ui/ShowMore/ShowMore.vue";
import PuxButton from "../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "DetailsInfoBox",
  components: {
    ShowMore,
    PuxButton,
  },
  props: {
    text: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: "info",
    },
  },
  data() {
    return {
      statusShow: true,
    };
  },
  computed: {
    getClass() {
      return `alert-${ this.type }`;
    },
  },
  methods: {
    closeBox() {
      this.statusShow = false;
    },
  },
};
