import Modal from "../../../../../global/components/Modal/Modal.vue";
import ModalCreateOrUpdate from "./ModalCreateOrUpdate/ModalCreateOrUpdate.vue";
import SmartTable from "../../../../../global/components/table/table.vue";

import { FilterDateMixin } from "../../../../../global/mixins/FiltersMixin";
import HttpMixin from "../../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../../../global/mixins/PermissionMixin";

import getTableApi from "../../../../../const/TableApis";

import { EventBus } from "../../../../../global/functions/event-bus";
import {
  get,
  isNil
} from "lodash-es";

// @vue/component
export default {
  name: "AbwesenheitenList",
  components: {
    SmartTable,
    Modal,
    ModalCreateOrUpdate,
  },
  mixins: [
    FilterDateMixin,
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
    objektUrlName: {
      type: String,
      default: "nutzer",
    },
    checkObjectPermissions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: {
        id: this.tableId,
        url: undefined,
        cols: [
          {
            label: "_LBL_ABWESENHEITEN_LIST_ABWESEND_VON_",
            id: "na_gueltig_ab",
            filter: "date",
            titleCallback: ({ item }) => this.filterDate(item.na_gueltig_ab, "DD.MM.YY HH:mm [Uhr]")
          },
          {
            label: "_LBL_ABWESENHEITEN_LIST_ABWESEND_BIS_",
            id: "na_gueltig_bis",
            filter: "date",
            titleCallback: ({ item }) => this.filterDate(item.na_gueltig_bis, "DD.MM.YY HH:mm [Uhr]")
          },
          {
            label: "_LBL_ABWESENHEITEN_LIST_VERTRETUNG_DURCH_",
            id: "vertreter_name",
          },
        ],
        rowActions: [
          {
            label: "_LBL_ABWESENHEIT_EDIT_",
            isHidden: () => (!this.canUpdate),
            callback: this.openModalUpdate,
          },
          {
            label: "_LBL_ABWESENHEIT_DELETE_",
            isHidden: () => (!this.canDelete),
            callback: this.openModalDelete,
          },
        ],
        actions: [
          {
            label: "_TXT_ABWESENHEIT_CREATE_",
            isHidden: () => (!this.canCreate),
            callback: () => this.modal.create = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "abwesenheit_create",
          },
        ],
        label: "_LBL_ABWESENHEITEN_TABLE_",
        sortable: false,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
        csvHide: true,
      },
      modal: {
        create: undefined,
        update: undefined,
        delete: undefined,
      },
      rowActionSelectorClose: undefined,
      modalDeleteOptions: undefined,
      deleteSelectorClose: undefined,
      filters: [],
      currentObj: undefined,
    };
  },
  computed: {
    userPermissions() {
      return get(this.nutzer, "user_permissions");
    },

    permArray() {
      return this.checkObjectPermissions ? this.userPermissions : undefined;
    },

    canView() {
      const permission = `${ this.objektUrlName }.abwesenheit.view`;
      return this.checkPermissionsSync({ perm: permission, permArray: this.permArray });
    },
    canCreate() {
      const permission = `${ this.objektUrlName }.abwesenheit.create`;
      return this.checkPermissionsSync({ perm: permission, permArray: this.permArray });
    },
    canUpdate() {
      const permission = `${ this.objektUrlName }.abwesenheit.update`;
      return this.checkPermissionsSync({ perm: permission, permArray: this.permArray });
    },
    canDelete() {
      const permission = `${ this.objektUrlName }.abwesenheit.delete`;
      return this.checkPermissionsSync({ perm: permission, permArray: this.permArray });
    },
  },
  created() {
    this.initUrl();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: this.tableId, params: { nutzerPk: this.nutzer.pk } });
    },
    updateRowActionSelectorClose(index) {
      if (!isNil(index)) {
        this.rowActionSelectorClose = `#abwesenheit_create${ index }`;
      } else {
        this.rowActionSelectorClose = undefined;
      }
    },
    closeModalCreate(status) {
      this.modal.create = false;
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
    },
    openModalUpdate({ item, index }) {
      this.modal.update = true;
      this.currentObj = item;
      this.updateRowActionSelectorClose(index);
    },
    closeModalUpdate(status) {
      this.modal.update = false;
      this.currentObj = undefined;
      this.updateRowActionSelectorClose();
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
    },
    openModalDelete({ item, index }) {
      this.updateRowActionSelectorClose(index);
      this.modalDeleteOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_ABWESENHEITEN_MODAL_DELETE_TITLE_",
        msg: "_HTML_ABWESENHEITEN_MODAL_DELETE_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteAbwesenheit(item),
        cancelCallback: this.closeModalDelete,
        loading: false,
      };
      this.modal.delete = true;
    },
    closeModalDelete() {
      this.modal.delete = false;
      this.modalDeleteOptions = undefined;
      this.deleteSelectorClose = this.rowActionSelectorClose;
      this.updateRowActionSelectorClose();
    },
    deleteAbwesenheit(item) {
      const url = `${ this.objektUrlName }/${ this.nutzer.pk }/abwesenheiten/${ item.pk }/`;
      return this.deleteHttp({
        url: url
      }).then(
        () => {
          EventBus.$emit(`updateTable${ this.tableId }`);
          this.closeModalDelete();
          this.deleteSelectorClose = "#abwesenheit_create";
          this.addNotification({ text: "_MSG_ABWESENHEITEN_MODAL_DELETE_SUCCESS_" });
        },
        () => this.addNotification({ text: "_MSG_ABWESENHEITEN_MODAL_DELETE_ERROR_", type: "error" })
      );
    }
  }
};
