import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "BeiRegistrierungBeantworteteFragen",
  components: {
    FormstepDetail,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    registrierungsantworten: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      options: {
        label: "_LBL_BEI_REGISTRIERUNG_BEANTWORTETE_FRAGEN_",
      },
    };
  },
};
