import {
  computed,
} from "vue";

import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

export default function AntragAnsprechpartnerUpdateAPI({ antrag }) {
  const updateAnsprechpartner = ({ ansprechpartner }) => {
    antrag.value.foerderorg_ansprechpartner = ansprechpartner;
  };
  const {
    checkPermissionsSync,
  } = PermissionAPI();
  const hasWFPermissionFoerderantragAnsprechpartnerCreate = computed(() => {
    return checkPermissionsSync({
      perm: "foerderantrag.ansprechpartner.create",
      permArray: antrag.value.user_permissions,
    });
  });
  const hasWFPermissionFoerderantragAnsprechpartnerAstorgUpdate = computed(() => {
    return checkPermissionsSync({
      perm: "foerderantrag.ansprechpartner.astorg.update",
      permArray: antrag.value.user_permissions,
    });
  });

  return {
    hasWFPermissionFoerderantragAnsprechpartnerAstorgUpdate,
    hasWFPermissionFoerderantragAnsprechpartnerCreate,
    updateAnsprechpartner,
  };
}
