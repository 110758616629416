"use strict";

class DynamischeMehrfachauswahlController {
  /*@ngInject*/
  constructor(DiasNotification) {
    this.setDefaultModel();
    this.initData();
    this.DiasNotification = DiasNotification;
    this.showDetails = {};
  }

  initData() {
    if (this.parameter[this.key].wert === undefined || this.parameter[this.key].wert === null) {
      this.parameter[this.key].wert = [];
    }
  }

  doShowDetails(antwort, parent) {
    if (!antwort.label) {
      return;
    }
    let key = antwort.label;
    if (parent) {
      key = `${ parent.label }__${ key }`;
    }
    return this.showDetails[key];
  }

  toggleDetails($event, antwort, parent) {
    $event.stopPropagation();
    $event.preventDefault();
    if (!antwort.label) {
      return;
    }
    let key = antwort.label;
    if (parent) {
      key = `${ parent.label }__${ key }`;
    }
    this.showDetails[key] = !this.showDetails[key];
  }

  updateLabel() {
    this.model.id = _.toLower(this.model.antwort).replace(/[^a-zA-Z0-9]/g, "");
  }

  updateId() {
    this.model.id = _.toLower(this.model.id).replace(/[^a-zA-Z0-9]/g, "");
  }

  removeAntwort(index) {
    this.parameter[this.key].wert.splice(index, 1);
  }

  moveUpAntwort(index) {
    if (index === 0) {
      return;
    }
    const elem = this.parameter[this.key].wert[index];
    const previous = this.parameter[this.key].wert[index - 1];
    this.parameter[this.key].wert[index - 1] = elem;
    this.parameter[this.key].wert[index] = previous;
  }

  moveDownAntwort(index) {
    if (index >= this.parameter[this.key].wert.length - 1) {
      return;
    }
    const elem = this.parameter[this.key].wert[index];
    const next = this.parameter[this.key].wert[index + 1];
    this.parameter[this.key].wert[index + 1] = elem;
    this.parameter[this.key].wert[index] = next;
  }

  moveUpUnterpunkt(parentIndex, index) {
    if (index === 0) {
      return;
    }
    const elem = this.parameter[this.key].wert[parentIndex].children[index];
    const previous = this.parameter[this.key].wert[parentIndex].children[index - 1];
    this.parameter[this.key].wert[parentIndex].children[index - 1] = elem;
    this.parameter[this.key].wert[parentIndex].children[index] = previous;
  }

  moveDownUnterpunkt(parentIndex, index) {
    if (index >= this.parameter[this.key].wert[parentIndex].children.length - 1) {
      return;
    }
    const elem = this.parameter[this.key].wert[parentIndex].children[index];
    const next = this.parameter[this.key].wert[parentIndex].children[index + 1];
    this.parameter[this.key].wert[parentIndex].children[index + 1] = elem;
    this.parameter[this.key].wert[parentIndex].children[index] = next;
  }

  getOberpunkte() {
    const antworten = [];
    _.forEach(this.parameter[this.key].wert, antwort => {
      antworten.push(antwort.label);
    });
    return antworten;
  }

  removeUnterpunkt(parentIndex, index) {
    this.parameter[this.key].wert[parentIndex].children.splice(index, 1);
  }

  hasUnterpunkte(option) {
    return _.isArray(option);
  }

  isDisabled() {
    return !this.model.antwort || (this.model.radio && (!this.model.freitext || !this.model.count));
  }

  setDefaultModel() {
    this.model = {
      radio: false,
      id: undefined,
      value: undefined,
      freitext: "",
      count: 20,
      oberpunkt: undefined,
      antwort: undefined,
    };
  }

  addAntwort() {
    let added = false;
    if (this.model.antwort) {
      if (this.model.oberpunkt) {
        const oberpunkt = _.find(this.parameter[this.key].wert, ["label", this.model.oberpunkt]);
        if (!oberpunkt.children) {
          oberpunkt.children = [];
        }
        if (!oberpunkt.children.length) {
          this.deleteFreitext(oberpunkt);
        }
        added = this.addToList(oberpunkt.children, this.setObj(true));
      } else {
        added = this.addToList(this.parameter[this.key].wert, this.setObj(true));
      }
      if (added) {
        this.setDefaultModel();
      }
    }
  }

  addToList(list, el) {
    const alreadyIn = list.find(v => v.label === el.label);
    if (alreadyIn) {
      this.DiasNotification.page.error(`Antwort "${ el.label }" existiert bereits.`);
      return false;
    }
    list.push(el);
    return true;
  }

  setObj(statusChildren) {
    const data = {
      label: this.model.antwort,
    };
    if (this.model.radio) {
      data.freitext = this.model.freitext;
      data.count = this.model.count;
    }
    if (this.model.value) {
      data.value = this.model.value;
    }
    if (this.model.id) {
      data.id = this.model.id;
    }
    if (statusChildren) {
      data.children = [];
    } else {
      data.typ = "json";
    }
    return data;
  }

  deleteFreitext(oberpunkt) {
    if (oberpunkt.freitext) {
      delete oberpunkt.freitext;
    }
    if (oberpunkt.count) {
      delete oberpunkt.count;
    }
  }
}

export default DynamischeMehrfachauswahlController;
