import Spinner from "../../../../../global/components/Spinner/Spinner.vue";
import Permission from "../../../../../global/components/Permission/Permission.vue";
import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import translate from "../../../../../global/directives/translate";
import OneDocumentSnapshotMixin from "../OneDocumentSnapshotMixin";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import AnlagenAllgemeinOneDocumentTd from "./AnlagenAllgemeinOneDocumentTd/AnlagenAllgemeinOneDocumentTd.vue";
import PuxDropdown from "../../../../../global/components/PuxDropdown/PuxDropdown.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import { cloneDeep } from "lodash-es";

const DOKUMENT_PRUEFSTATUS_NEU = "30d847af-74b6-1dcc-e050-007f01012f99";

// @vue/component
export default {
  name: "AnlagenAllgemeinOneDocument",
  components: {
    Spinner,
    Permission,
    SnapshotIcon,
    PuxButton,
    AnlagenAllgemeinOneDocumentTd,
    PuxDropdown,
    PuxTranslate,
  },
  directives: {
    translate,
  },
  mixins: [
    OneDocumentSnapshotMixin,
  ],
  props: {
    document: {
      type: Object,
      required: true,
    },
    prefixUrlSave: {
      type: String,
      required: false,
      default: undefined,
    },
    userPermissions: {
      type: Array,
      required: false,
      default: undefined,
    },
    dokumentePermissionsLocal: {
      type: Object,
      required: true,
    },
    statusDetailsShow: {
      type: Boolean,
      required: false,
    },
    openModalDelete: {
      type: Function,
      required: false,
      default: undefined,
    },
    toggleDetails: {
      type: Function,
      required: false,
      default: undefined,
    },
    vorsehenDocumentInSitzung: {
      type: Function,
      required: false,
      default: undefined,
    },
    hideDetail: {
      type: Boolean,
      required: false,
    },
    canDelete: {
      type: Boolean,
      required: false,
    },
    canUpdate: {
      type: Boolean,
      required: false,
    },
    snapshotIconType: {
      type: String,
      required: false,
      validator: value => ["add", "delete"].indexOf(value) !== -1,
      default: undefined,
    },
    htmlId: {
      type: String,
      required: false,
      default: undefined,
    },
    colsForTableFiltered: {
      type: Array,
      required: true,
    },
    infoTextDokart: {
      type: String,
      required: false,
      default: undefined,
    }
  },
  computed: {
    htmlIdLocal() {
      return `${ this.htmlId }_${ this.document.pk }`;
    },

    urlSave() {
      return `${ this.prefixUrlSave }${ this.document.pk }/`;
    },

    tdClass() {
      if (this.hideDetail || this.document.is_sync) {
        return "";
      }
      return "pointer";
    },

    documentLocal() {
      const DOCUMENT = cloneDeep(this.document);
      if (!DOCUMENT.pruefstatus) {
        DOCUMENT.pruefstatus = {
          pk: DOKUMENT_PRUEFSTATUS_NEU,
          status: "neu",
        };
      }
      return DOCUMENT;
    },

    tdTooltipId() {
      return `${ this.htmlIdLocal }_info`;
    },

    snapshotIconTypeLocal() {
      if (this.snapshotIconType) {
        return this.snapshotIconType;
      }
      if (this.statusSnapshotChanges) {
        return "changes";
      }
      return undefined;
    },

    arrowIcon() {
      if (this.statusDetailsShow) {
        return "chevron-up";
      }
      return "chevron-down";
    },

    arrowTooltip() {
      if (this.statusDetailsShow) {
        return "_TXT_GR_DOCUMENTS_DETAILS_VERBERGEN_";
      }
      return "_TXT_GR_DOCUMENTS_DETAILS_ZEIGEN_";
    },
  },
  methods: {
    toggleDetailsLocal() {
      if (this.document.is_sync || this.hideDetail) {
        return;
      }
      this.toggleDetails({ documentPk: this.document.pk });
    },
  },
};
