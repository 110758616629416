import Modal from "../../Modal/Modal.vue";
import Permission from "../../Permission/Permission.vue";
import PuxIcon from "../../PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";
import TableViewTableCell from "../TableViewTableCell/TableViewTableCell.vue";
import TableViewTableCellActions from "../TableViewTableCellActions/TableViewTableCellActions.vue";
import TableViewTableTh from "../TableViewTableTh/TableViewTableTh.vue";

import loading from "../../../directives/loading";

import GlobalOptions from "../../../const/GlobalOptions";
import {
  isClickOnLink,
} from "../../../functions/utilsDOM";
import {
  EventBus,
} from "../../../functions/event-bus";
import {
  createNamespacedHelpers,
} from "vuex";
import {
  filter,
  range,
} from "lodash-es";

const {
  mapGetters,
  mapMutations,
} = createNamespacedHelpers("table");

// @vue/component
export default {
  name: "TableViewTable",
  components: {
    Modal,
    Permission,
    PuxIcon,
    PuxTranslate,
    TableViewTableCell,
    TableViewTableCellActions,
    TableViewTableTh,
  },
  directives: {
    loading,
  },
  props: {
    cols: {
      type: Array,
      default: () => [],
    },
    modelCols: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    disabledObj: {
      type: Object,
      default: () => ({}),
    },
    detailsObj: {
      type: Object,
      default: () => ({}),
    },
    replaceDetailsObj: {
      type: Function,
      default: () => {},
    },
    sortTable: {
      type: Function,
      default: () => {},
    },
    toggleTableCollapse: {
      type: Function,
      default: () => {},
    },
    dataLoaded: {
      type: Boolean,
    },
  },
  data() {
    return {
      idLabel: GlobalOptions.tableOptions.idLabel,
      hoverRows: {},
      activeRows: {},
      activeRowWas: undefined,
      detailsBottomShow: {},
      currentRowPk: undefined,
      multipleModel: [],
      multipleAllModel: undefined,
    };
  },
  computed: {
    getMultipleActionsId() {
      return (index = "") => `${ this.options.id }_multiple_${ index }`;
    },

    getTrClass() {
      return ({ row }) => [
        {
          focus: this.activeRows[row[this.options.rowId]],
          focus_was: this.activeRowWas === row[this.options.rowId],
        },
        this.options.trClassCallback ? this.options.trClassCallback({ row }) : ""
      ];
    },

    getSlotNameTableDetails() {
      return `${ this.options.id }_table_details`;
    },

    isCheckedMultipleCheckbox() {
      return this.multipleAllModel === "checked";
    },

    isIndeterminateMultipleCheckbox() {
      return this.multipleAllModel === "indeterminate";
    },

    translationHtmlNoData() {
      return this.dataLoaded ?
        "_HTML_TABLE_VIEW_NO_DATA_FOUND_MSG_" :
        "_HTML_TABLE_VIEW_NO_DATA_LOADED_MSG_";
    },

    currentSortingModel() {
      return this.GET_SORT_MODEL(this.options.id);
    },

    ...mapGetters([
      "GET_SORT_MODEL",
      "GET_MULTIPLE_STATUS",
    ]),
  },
  created() {
    this.initEventBus();
  },
  beforeUnmount() {
    EventBus.$off(`${ this.options.id }updateData`, this.onAfterUpdateData);
    EventBus.$off(`closeRightDetails${ this.options.id }`, this.onAfterCloseRightDetails);
    EventBus.$off(`clearMultipleModel${ this.options.id }`, this.onAfterClearMultipleModel);
  },
  methods: {
    initEventBus() {
      EventBus.$on(`${ this.options.id }updateData`, this.onAfterUpdateData);
      EventBus.$on(`closeRightDetails${ this.options.id }`, this.onAfterCloseRightDetails);
      EventBus.$on(`clearMultipleModel${ this.options.id }`, this.onAfterClearMultipleModel);
    },

    onAfterUpdateData() {
      this.detailsBottomShow = {};
      this.setDefaultMultipleModel();
    },

    onAfterCloseRightDetails() {
      this.closeRightDetails();
      this.activeRows = {};
    },

    onAfterClearMultipleModel() {
      this.multipleModel = [];
      this.multipleAllModel = undefined;
    },

    closeRightDetails() {
      this.activeRowWas = this.currentRowPk;
      this.currentRowPk = undefined;
    },

    onClickDetails({ row, $event }) {
      if (isClickOnLink($event)) { // Öffnen keine Details, wenn wir auf den Link geklickt haben
        return;
      }
      if (this.options.view.details === "bottom") {
        this.showDetailsBottom({ row });
      } else {
        this.showDetailsRight({ row });
      }
    },

    showDetailsBottom({ row }) {
      if (this.detailsBottomShow[row[this.options.rowId]]) {
        this.detailsBottomShow[row[this.options.rowId]] = false;
        this.activeRows[row[this.options.rowId]] = false;
      } else {
        if (!this.detailsObj[row[this.options.rowId]]) {
          this.replaceDetailsObj(row);
        } else {
          this.replaceDetailsObj(row, true);
        }
        this.detailsBottomShow[row[this.options.rowId]] = true;
        this.activeRows[row[this.options.rowId]] = true;
      }
    },

    showDetailsRight({ row }) {
      if (this.currentRowPk === row[this.options.rowId]) {
        this.activeRowWas = this.currentRowPk;
        this.currentRowPk = undefined;
        this.activeRows[row[this.options.rowId]] = false;
        this.toggleTableCollapse(false);
        EventBus.$emit(`showDetails${ this.options.id }`, { pk: this.currentRowPk });
        return;
      }
      this.activeRowWas = undefined;
      this.activeRows = {};
      this.activeRows[row[this.options.rowId]] = true;
      if (!this.detailsObj[row[this.options.rowId]]) {
        this.replaceDetailsObj(row);
      } else {
        this.replaceDetailsObj(row, true);
      }

      this.currentRowPk = row[this.options.rowId];
      EventBus.$emit(`showDetails${ this.options.id }`, { pk: this.currentRowPk });
      this.toggleTableCollapse(true);
    },

    closeDetailsBottom(row) {
      this.activeRows[row[this.options.rowId]] = false;
      this.detailsBottomShow[row[this.options.rowId]] = false;
    },

    changeSort(id, sortInvert) {
      const modelSort = this.currentSortingModel;
      let tempId;
      if (modelSort === id) {
        tempId = `-${ id }`;
      } else if (modelSort === `-${ id }`) {
        tempId = id.replace("-", "");
      } else {
        if (sortInvert) {
          tempId = `-${ id }`;
        } else {
          tempId = id;
        }
      }

      this.MUT_SET_SORT_MODEL({
        tableId: this.options.id,
        sortModel: tempId,
      });
      EventBus.$emit(`changeSort${ this.options.id }`, { id: tempId });
      this.sortTable(tempId);
    },

    clickMultipleCheckbox() {
      setTimeout(() => {
        if (this.multipleModel.length === this.rows.length) {
          this.multipleAllModel = "checked";
          this.MUT_ADD_MULTIPLE_MODEL({ tableId: this.options.id, model: this.rows });
        } else if (!this.multipleModel.length) {
          this.multipleAllModel = undefined;
          this.MUT_ADD_MULTIPLE_MODEL({ tableId: this.options.id, model: [] });
        } else {
          this.multipleAllModel = "indeterminate";
          this.MUT_ADD_MULTIPLE_MODEL({ tableId: this.options.id, model: filter(this.rows, (item, index) => this.multipleModel.indexOf(index) !== -1) });
        }
      });
    },

    clickMultipleCheckboxAll() {
      setTimeout(() => {
        if (this.multipleAllModel === "indeterminate" || this.multipleAllModel === "checked") {
          this.multipleAllModel = undefined;
          this.multipleModel = [];
          this.MUT_ADD_MULTIPLE_MODEL({ tableId: this.options.id, model: [] });
        } else {
          this.multipleAllModel = "checked";
          this.multipleModel = range(this.rows.length);
          this.MUT_ADD_MULTIPLE_MODEL({ tableId: this.options.id, model: this.rows });
        }
      });
    },

    setDefaultMultipleModel() {
      this.multipleAllModel = undefined;
      this.multipleModel = [];
      this.MUT_CLEAN_MULTIPLE_MODEL({ tableId: this.options.id });
    },

    showCol(col) {
      return !this.options.showColsConfig || this.modelCols.indexOf(col.id) !== -1;
    },

    ...mapMutations([
      "MUT_SET_SORT_MODEL",
      "MUT_ADD_MULTIPLE_MODEL",
      "MUT_CLEAN_MULTIPLE_MODEL",
    ]),
  },
};
