"use strict";

class ProjektberichtvorlageCreateController {
  /*@ngInject*/
  constructor(
    $scope,
    $q,
    $state,
    Projektberichte,
    Vertrag,
    Foerderantrag,
    DiasNotification,
    i18n
  ) {
    this.$scope = $scope;
    this.$state = $state;
    this.$q = $q;
    this.Projektberichte = Projektberichte;
    this.Vertrag = Vertrag;
    this.Foerderantrag = Foerderantrag;
    this.DiasNotification = DiasNotification;
    this.gettext = i18n.gettext;
    this.vertragSelection = [];
    this.vertragExtraFields = ["antrag_id"];
    this.init();
    this.save = () => this.create();
  }

  init() {
    this.vertragChoices = {
      getChoices: (valueField, labelField, params) => {
        params.fields = params.fields || [];
        params.fields.push(...this.vertragExtraFields);
        return this.Vertrag.getChoices(valueField, labelField, params);
      }
    };
  }

  getLineClasses() {
    const classes = [];

    if (this.canCreate && this.vorlagen && !this.loadingPerms && !this.loadingVorlagen) {
      classes.push("glyphicon-chevron-down", "success");
    } else {
      classes.push("glyphicon-close");
      if (!this.loadingPerms && !this.loadingVorlagen && this.selectedVertragNr) {
        classes.push("danger");
      }
    }
    return classes;
  }

  onVertragChanged() {
    this.vorlagen = undefined;
    this.selected = undefined;
    if (!this.vertragSelection.length) {
      this.selectedVertragNr = undefined;
      return;
    }
    this.selectedVertragNr = this.vertragSelection[0].v_nr;
    if (this.vertragSelection[0].id) {
      this.loadingPerms = true;
      this.loadingMsg = "Berechtigungen werden geprüft";
      this.Vertrag.one(this.vertragSelection[0].id).checkPermissions(
        ["projektbericht.create"]
      ).then(
        response => {
          this.vertragSelection[0].user_permissions = response;
          this.perms = response;
          this.canCreate = response.length > 0;
          if (this.canCreate) {
            this.loadModulData();
          }
        },
        err => this.onError(err)
      ).finally(() => this.loadingPerms = false);
    }
  }

  loadModulData() {
    this.loadingVorlagen = true;
    this.loadingMsg = "Projektberichte werden geladen";
    this.Foerderantrag.one(this.vertragSelection[0].antrag_id).module.getList({
      gruppe: "projektberichte",
      ordering: "regel__anzeigekontext__faz_pos"
    }).then(
      response => this.vorlagen = this.transformModules(response),
      err => this.DiasNotification.form.error(err, "Fehler beim Laden der Projektberichte")
    ).finally(() => this.loadingVorlagen = false);
  }

  transformModules(modules) {
    if (!modules || modules.length === 0) {
      return undefined;
    }
    // wenn vorhanden sollte immer nur 1 sein
    const mod = modules[0];
    return {
      regel: mod.regel.pk,
      regel_nummer: mod.regel.regel_nummer,
      info: mod.regel.afr_argument.info.wert,
      entries: mod.regel.afr_argument.projektberichte.wert.map((pbv, idx) => {
        const active = mod.data.find(v => v.pb_afr_argument_idx === idx);
        const entry = Object.assign({}, pbv, { count: active ? active.num : 0 });
        let einschraenkung = "";
        if (entry.minimum === entry.maximum && entry.minimum > 0) {
          einschraenkung = ` von ${ entry.minimum } benötigten`;
        }
        if (entry.minimum !== entry.maximum && (entry.minimum > 0 || entry.maximum > 0)) {
          einschraenkung = " von";
          if (entry.minimum > 0) {
            einschraenkung = `${ einschraenkung } mindestens ${ entry.minimum }`;
          }
          if (entry.minimum > 0 && entry.maximum > 0) {
            einschraenkung = `${ einschraenkung } und`;
          }
          if (entry.maximum > 0) {
            einschraenkung = `${ einschraenkung } maximal ${ entry.maximum }`;
          }
          einschraenkung = `${ einschraenkung } benötigten`;
        }
        let aktuell = "";
        if (entry.count === 1) {
          aktuell = `Es existiert bereits 1 ${ this.gettext("Projektbericht") }`;
        } else if (entry.count === 0) {
          aktuell = `Es existieren 0 ${ this.gettext("Projektberichte") }`;
        } else {
          aktuell = `Es existieren bereits ${ entry.count } ${ this.gettext("Projektberichte") }`;
        }
        let noMore = "";
        if (entry.maximum <= entry.count) {
          noMore = ". Es darf kein weiterer erstellt werden.";
        }
        entry.title = `${ aktuell } "${ entry.label }"${ einschraenkung }${ noMore }`;
        return entry;
      })
    };
  }

  create() {
    const defer = this.$q.defer();
    this.Projektberichte.post({
      regel: this.vorlagen.regel,
      pb_afr_argument_idx: this.selected,
      pb_kbez: this.vorlagen.entries[this.selected].label,
      vorlage: this.vorlagen.entries[this.selected].vorlage,
      vertrag: this.vertragSelection[0].id
    }).then(
      response => {
        this.DiasNotification.page.success("Projektbericht erfolgreich erstellt");
        this.$state.go("root.projektberichte.detail", { id: response.pk });
        defer.resolve(response);
      },
      err => {
        this.DiasNotification.form.clear();
        this.DiasNotification.form.error(err);
        defer.reject(err);
      }
    );
    return defer.promise;
  }

  goTo(vertragPk) {
    this.$scope.exit();
    this.$state.go("root.vertrag.details", { id: vertragPk });
  }
}

export default ProjektberichtvorlageCreateController;
