"use strict";

import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";

import builder from "../utils/builder";


const antragstellerregel = angular.module("dias.standard_foerdermodule.antragstellerregel", [
  foerdermoduleRegistry.name,
]);

antragstellerregel.config(FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("antragstellerregel", "modul-antragstellerregel");
})

  .directive("modulAntragstellerregel", builder.directive(template, controller));

export default antragstellerregel;
