import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import FormElement from "../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxAlert from "../../../global/components/PuxAlert/PuxAlert.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import WorkflowfolgenDetailsBeschreibung from "./WorkflowfolgenDetailsBeschreibung/WorkflowfolgenDetailsBeschreibung.vue";
import WorkflowfolgenDetailsAusfuerungskonfiguration
  from "./WorkflowfolgenDetailsAusfuerungskonfiguration/WorkflowfolgenDetailsAusfuerungskonfiguration.vue";
import WorkflowfolgenDetailsCopyModal from "./WorkflowfolgenDetailsCopyModal/WorkflowfolgenDetailsCopyModal.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../global/compositionAPI/NotificationAPI";
import PermissionAPI from "../../../global/compositionAPI/PermissionAPI";

import { isEmpty } from "lodash-es";

// @vue/component
export default {
  name: "WorkflowfolgenDetails",
  components: {
    ContextPanel,
    FormElement,
    Modal,
    Permission,
    PuxAlert,
    PuxCloak,
    PuxButton,
    PuxPageDetails,
    PuxTranslate,
    WorkflowfolgenDetailsBeschreibung,
    WorkflowfolgenDetailsAusfuerungskonfiguration,
    WorkflowfolgenDetailsCopyModal,
  },
  setup() {
    const {
      deleteHttp,
      getHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      addNotification,
      checkPermissionsSync,
      deleteHttp,
      getHttp,
    };
  },
  data() {
    return {
      loading: false,
      workflowfolge: {},
      infoTexts: {
        aufgabe: "_TXT_WORKFLOWFOLGENDETAILS_INFOTEXT_AUFGABE_",
        nachfolge_aufgabe: "_TXT_WORKFLOWFOLGENDETAILS_INFOTEXT_NACHFOLGE_AUFGABE_",
        workflow: "_TXT_WORKFLOWFOLGENDETAILS_INFOTEXT_WORKFLOW_",
        nachfolge_workflow: "_TXT_WORKFLOWFOLGENDETAILS_INFOTEXT_NACHFOLGE_WORKFLOW_",
        statusfunktionen: "_TXT_WORKFLOWFOLGENDETAILS_INFOTEXT_STATUSFUNKTIONEN_",
        clientfunktion: "_TXT_WORKFLOWFOLGENDETAILS_INFOTEXT_CLIENTFUNKTIONEN_",
        serverfunktionen: "_TXT_WORKFLOWFOLGENDETAILS_INFOTEXT_SERVERFUNKTIONEN_",
        wfo_kbez: "_TXT_WORKFLOWFOLGENDETAILS_INFOTEXT_WHO_KBEZ_",
        wfo_pos: "_TXT_WORKFLOWFOLGENDETAILS_INFOTEXT_WHO_POS_",
        wfo_anzeige_typ: "_TXT_WORKFLOWFOLGENDETAILS_INFOTEXT_WHO_ANZEIGE_TYP_",
        wfo_anzeige_icon: "_TXT_WORKFLOWFOLGENDETAILS_INFOTEXT_WHO_ANZEIGE_ICON_",
        wfo_reload: "_TXT_WORKFLOWFOLGENDETAILS_INFOTEXT_WFO_RELOAD_",
        wfo_kbez_admin: "_TXT_WORKFLOWFOLGENDETAILS_INFOTEXT_WFO_KBEZ_ADMIN_",
        queue: "_TXT_WORKFLOWFOLGENDETAILS_INFOTEXT_QUEUE_",
      },
      validationLabels: {
        aufgabe: "_TXT_WORKFLOWFOLGENDETAILS_AUSGANGSAUFGABE_",
        workflow: "_TXT_WORKFLOWFOLGENDETAILS_WORKFLOW_",
        clientfunktion: "_TXT_WORKFLOWFOLGENDETAILS_CLIENTFUNKTION_",
        serverfunktionen: "_TXT_WORKFLOWFOLGENDETAILS_SERVERFUNKTIONEN_",
        statusfunktionen: "_TXT_WORKFLOWFOLGENDETAILS_STATUSFUNKTIONEN_",
      },
      confirmDeleteShow: false,
      showConfirmCopy: false,
      confirmDeleteOptions: {},
      showValidationInfo: false,
      validationLoading: false,
      validationErrors: {},
    };
  },
  computed: {
    workflowfolgeUrl() {
      return `workflowfolgen/${ this.$stateParams.id }/`;
    },

    validationAlertType() {
      return this.validationAlert ? "warning" : "success";
    },

    validationAlert() {
      return !isEmpty(this.validationErrors);
    },

    pageLoading() {
      return this.loading || this.validationLoading;
    },

    pageLoadingMessage() {
      return this.validationLoading ? "_MSG_WORKFLOWFOLGENDETAILS_VALIDATION_PROCESS_" : "_MSG_WORKFLOWFOLGENDETAILS_LOADING_WORKFLOWFOLGE_";
    },
  },
  created() {
    this.loadWorkflowfolge();
  },
  methods: {
    loadWorkflowfolge() {
      this.loading = true;
      this.getHttp({
        url: this.workflowfolgeUrl,
      }).then(
        response => {
          this.workflowfolge = response;
        },
      ).finally(
        () => this.loading = false
      );
    },

    updateWorkflowfolge({ data }) {
      this.workflowfolge = Object.assign({}, this.workflowfolge, data);
    },

    deleteWorkflowfolge() {
      this.loading = true;
      this.deleteHttp({
        url: this.workflowfolgeUrl,
        data: this.workflowfolge,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_WORKFLOWFOLGENDETAILS_WORKFLOWFOLGE_DELETED_SUCCESSFUL_",
          });
          this.$goTo("root.workflowfolgen");
        },
        () => {
          this.addNotification({
            text: "_MSG_WORKFLOWFOLGENDETAILS_WORKFLOWFOLGE_ERROR_ON_DELETE_",
            type: "error",
          });
        },
      ).finally(
        () => this.loading = false,
      );
    },

    validateWorkflowfolge() {
      this.validationLoading = true;
      this.getHttp({
        url: `${ this.workflowfolgeUrl }validieren/`
      }).then(
        response => {
          this.validationErrors = response;
        },
      ).finally(
        () => {
          this.validationLoading = false;
          this.showValidationInfo = true;
        }
      );
    },

    openModalConfirmCopyWorkflowfolge() {
      this.showConfirmCopy = true;
    },

    closeModalConfirmCopyWorkflowfolge() {
      this.showConfirmCopy = false;
    },

    openModalConfirmDeleteWorkflowfolge() {
      this.confirmDeleteOptions = {
        okClass: "btn btn-primary",
        title: "_TITLE_WORKFLOWFOLGENDETAILS_WORKFLOWFOLGE_LOESCHEN_{{name}}_",
        msg: "_MSG_WORKFLOWFOLGENDETAILS_WORKFLOWFOLGE_LOESCHEN_{{name}}_",
        extra: { name: this.workflowfolge.wfo_kbez_admin },
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: this.deleteWorkflowfolge,
        cancelCallback: this.closeModalConfirmDeleteWorkflowfolge,
        loading: false,
      };
      this.confirmDeleteShow = true;
    },

    closeModalConfirmDeleteWorkflowfolge() {
      this.confirmDeleteShow = false;
    },

    onValidationAlertDismiss() {
      this.showValidationInfo = false;
    },
  },
};
