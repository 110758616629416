import DynamischeTabelleModalCreateOrUpdate from "../../DynamischeTabelle/DynamischeTabelleModalCreateOrUpdate/DynamischeTabelleModalCreateOrUpdate.js";

import {
  cloneDeep,
  filter,
  findIndex,
  forEach,
  get,
  indexOf,
  isUndefined,
  slice,
} from "lodash-es";

// @vue/component
export default {
  name: "DynamischeTabellePeriodenModalCreateOrUpdate",
  mixins: [
    DynamischeTabelleModalCreateOrUpdate,
  ],
  props: {
    parameter: {
      type: Object,
      required: true,
    },
  },
  computed: {
    elementeFromModel() {
      const LIST = [];
      const MODEL_PERIODEN = {};
      MODEL_PERIODEN[this.periodenConfig.id] = undefined;
      const MODEL_UNABHAENGIG = {};
      forEach(this.spaltenUnabhaengig, col => MODEL_UNABHAENGIG[col] = this.model[col]);
      forEach(this.perioden, periode => {
        const MODEL_ABHAENGIG = {};
        MODEL_PERIODEN[Object.keys(MODEL_PERIODEN)[0]] = periode.id;
        forEach(this.spaltenAbhaengig, col => MODEL_ABHAENGIG[col] = get(this.model, periode.id + "." + col));
        LIST.push(
          {
            ...MODEL_PERIODEN,
            ...MODEL_UNABHAENGIG,
            ...MODEL_ABHAENGIG,
            validierung_erforderlich: true,
          }
        );
      });
      return LIST;
    },

    errorsLocal() {
      const ERRORS = cloneDeep(this.modelDefaultLocal);
      forEach(this.spaltenUnabhaengig, key => {
        const ERROR = get(this.errorsClone, "0." + key) || [];
        ERRORS[key] = ERROR.map(err => this.getErrorString({ key, err, periode: "" }));
      });
      forEach(this.perioden, (periode, idx) => {
        const ERROR = {};
        forEach(get(this.errorsClone, idx) || {}, (error, key) => {
          if (!error || indexOf(this.spaltenUnabhaengig, key) !== -1) {
            return;
          }
          ERROR[key] = error.map(err => this.getErrorString({ key, err, periode: periode.pe_kbez }));
        });
        ERRORS[periode.id] = ERROR;
      });
      return ERRORS;
    },

    modelDefaultLocal() {
      const MODEL = cloneDeep(this.modelDefaultUnabhaengig);
      forEach(this.perioden, periode => MODEL[periode.id] = cloneDeep(this.modelDefaultAbhaengig));
      return MODEL;
    },

    periodenConfig() {
      return filter(this.spalten, spalte => spalte.id === this.spaltenConfig.periode)[0];
    },

    spaltenLabelMapping() {
      const MAPPING = {};
      forEach(this.spalten, spalte => {
        MAPPING[spalte.id] = spalte.label;
      });
      return MAPPING;
    },

    spaltenConfig() {
      return this.parameter.perioden_spalten || {};
    },

    spaltenAbhaengig() {
      return this.spaltenConfig.abhaengige_spalten || [];
    },

    spaltenUnabhaengig() {
      return this.spaltenConfig.unabhaengige_spalten || [];
    },

    perioden() {
      let perioden = [];
      if (this.data.periode) {
        perioden = cloneDeep(this.data.periode);
        if (this.parameter.perioden_durchfuehrungszeitraum && this.obj.durchfuehrungszeitraum) {
          perioden = filter(
            perioden,
            pe => pe.pe_von >= this.obj.durchfuehrung_von && pe.pe_bis <= this.obj.durchfuehrung_bis
          );
        }
      }
      return perioden;
    },

    modelDefaultUnabhaengig() {
      const MODEL = {};
      forEach(this.modelDefault, (item, key) => {
        if (indexOf(this.spaltenUnabhaengig, key) !== -1) {
          MODEL[key] = item;
        }
      });
      return MODEL;
    },

    modelDefaultAbhaengig() {
      const MODEL = {};
      forEach(this.modelDefault, (item, key) => {
        if (indexOf(this.spaltenAbhaengig, key) !== -1) {
          MODEL[key] = item;
        }
      });
      return MODEL;
    },

    optionsListAbhaengig() {
      const OPTIONS = [];
      forEach(this.spalten, spalte => {
        if (findIndex(this.spaltenAbhaengig, id => id === spalte.id) !== -1) {
          OPTIONS.push(spalte);
        }
      });
      return OPTIONS;
    },

    optionsListUnabhaengig() {
      const OPTIONS = [];
      forEach(this.spalten, spalte => {
        if (findIndex(this.spaltenUnabhaengig, id => id === spalte.id) !== -1) {
          OPTIONS.push(spalte);
        }
      });
      return OPTIONS;
    },

    optionsModal() {
      return {
        showCloseButton: true,
        required: true,
        list: this.element ? this.spalten : [],
      };
    },
  },
  methods: {
    getErrorString({ key, err, periode }) {
      return periode + " " + this.spaltenLabelMapping[key] + ": " + err;
    },

    getOption(option, periodeId) {
      const OPTION = cloneDeep(option);
      OPTION.htmlId = OPTION.htmlId + "_" + periodeId;
      return OPTION;
    },

    initModel() {
      let model = {};
      if (this.element) {
        model = cloneDeep(this.element);
      } else {
        model = cloneDeep(this.modelDefaultLocal);
        model.validierung_erforderlich = true;
      }
      this.model = model;
    },

    prepareDataForSave() {
      const DATA = cloneDeep(this.modelFromModul);
      if (!isUndefined(this.elementIndex)) {
        DATA.elemente.splice(this.elementIndex, 1, this.model);
      } else {
        DATA.elemente = DATA.elemente.concat(this.elementeFromModel);
      }
      return DATA;
    },

    onError({ error }) {
      if (error.elemente) {
        const ERRORS = slice(error.elemente, this.elementIndex || error.elemente.length - this.perioden.length);
        this.errors = ERRORS;
        this.errorsClone = ERRORS;
      }
    },
  },
};
