"use strict";

import template from "./tasklog.jade";

class TaskLogController {
  /*@ngInject*/
  constructor($q, $timeout, $scope, TaskLog, DiasNotification) {
    this.$q = $q;
    this.$timeout = $timeout;
    this.TaskLog = TaskLog;
    this.DiasNotification = DiasNotification;
    this.updateTime = 1000;
    this.loading = true;
    this.taskLog = {
      tl_data: {
        msg: "Ausführung wird vorbereitet"
      }
    };
    this.promise = this.$timeout(() => {
      this.updateTask().finally(
        () => this.loading = false
      );
    }, this.updateTime);

    $scope.$on("$destroy", () => {
      this.$timeout.cancel(this.promise);
    });
  }

  updateTask() {
    return this.TaskLog.one(this.task).get().then(
      taskLog => {
        this.taskLog = taskLog;
        if (taskLog.isInProgress()) {
          if (this.onProgress) {
            this.onProgress()(taskLog.tl_result);
          }
          this.promise = this.$timeout(() => this.updateTask(), this.updateTime);
        } else {
          this.$timeout.cancel(this.timer);
          let cbHandle;
          if (this.onSuccess && taskLog.isSuccess()) {
            if (taskLog.tl_status === "SUCCESS" && !this.blockDefaultNotifications) {
              this.DiasNotification.page.success("Aufgabe erfolgreich abgeschlossen");
            } else if (taskLog.tl_status === "PARTIAL_SUCCESS" && !this.blockDefaultNotifications) {
              this.DiasNotification.page.warning("Aufgabe teilweise erfolgreich abgeschlossen");
            }
            if (this.onSuccess) {
              cbHandle = this.onSuccess()(taskLog.tl_result);
            }
          } else if (this.onFailure && taskLog.isFailure()) {
            let errorMsg;
            if (angular.isString(taskLog.tl_result.data)) {
              errorMsg = taskLog.tl_result.data;
            } else if (taskLog.tl_result && taskLog.tl_result.data && taskLog.tl_result.data.kommentar) {
              errorMsg = "Bitte erfassen Sie einen Kommentar.";
            }
            if (!this.blockDefaultNotifications) {
              this.DiasNotification.page.error(errorMsg, "Fehler beim Abschließen der Aufgabe");
            }
            if (this.onFailure) {
              cbHandle = this.onFailure()(taskLog.tl_result);
            }
          }
          if (this.onFinish) {
            if (cbHandle && angular.isFunction(cbHandle.finally)) {
              cbHandle.finally(() => this.onFinish()(taskLog.tl_result));
            } else {
              this.onFinish()(taskLog.tl_result);
            }
          }
        }
      },
      err => {
        this.error = err;
        if (this.onError) {
          this.onError()(err);
        }
      }
    );
  }
}

export default {
  template: template(),
  controller: TaskLogController,
  controllerAs: "vm",
  bindings: {
    task: "<",
    blockDefaultNotifications: "<?",
    onSuccess: "&?",
    onFailure: "&?",
    onFinish: "&?",
    onProgress: "&?",
    onError: "&?",
  },
};
