import FaqStation from "./FaqStation/FaqStation.vue";

import DashboardModuleMixin from "../DashboardModuleMixin";
import DashboardStylesMixin from "../DashboardStylesMixin";

import { createNamespacedHelpers } from "vuex";
const {
  mapGetters,
} = createNamespacedHelpers("faq");

// @vue/component
export default {
  name: "DashboardFaqStationen",
  components: {
    FaqStation,
  },
  mixins: [
    DashboardModuleMixin,
    DashboardStylesMixin,
  ],
  props: {
    argument: {
      type: Object,
      required: true,
      info: "Hauptinformation",
    },
  },
  computed: {
    parameter() {
      return this.argument.parameter || {};
    },

    footerText() {
      return this.parameter.text;
    },

    stationen() {
      return this.parameter.stationen;
    },

    ...mapGetters([
      "GET_FAQ_RUBRIK_DATA",
    ]),
  },
};
