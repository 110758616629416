"use strict";
import {
  assign,
  cloneDeep,
} from "lodash-es";

class BaseFunction {
  constructor(DiasNotification, $q, workflowfolge, obj, extra) {
    this.DiasNotification = DiasNotification;
    this.workflowfolge = workflowfolge;
    this.obj = obj;
    this.$q = $q;
    this.extra = extra;
  }

  prepareParameterIn() {
    const parameter = {};
    const parameterin = this.workflowfolge.clientfunktion.wfc_parameterin;
    for (const key in parameterin) {
      parameter[key] = parameterin[key].wert;
    }
    return parameter;
  }

  prepareParameterOut() {
    const userInput = {};
    const parameterout = this.workflowfolge.clientfunktion.wfc_parameterout;
    for (const key in parameterout) {
      userInput[key] = parameterout[key].default;
    }
    return userInput;
  }

  start() {
    const parameterout = this.prepareParameterOut(this.workflowfolge);
    return this.finish(parameterout);
  }

  finishSingleAction(parameterout) {
    // Einzelaktion, d.h. direkter Aufruf der Serverfunktion
    return this.workflowfolge.startServerProcess(this.obj, parameterout).then(
      response => {
        this.folge_aufgabe = response;
      },
      errors => {
        this.DiasNotification.page.error(errors.data, "Fehler beim Starten der Aufgabe");
        return this.$q.reject(errors);
      }
    );
  }

  finishMassAction(parameterout) {
    // Mehrfachaktion, daher kein direkter Aufruf der Serverfunktion
    return this.$q.resolve({ finished: true, params: parameterout });
  }

  finish(parameterout) {
    if (this.obj === null) {
      return this.finishMassAction(parameterout);
    }
    return this.finishSingleAction(parameterout);
  }

  cancel() {
    return this.$q.resolve(false);
  }

  onServerSuccess() {
    if (typeof window.utag === "undefined" ||
      !this.workflowfolge ||
      !this.workflowfolge.wfo_tracking_info ||
      !this.workflowfolge.wfo_tracking_info.event_id ||
      !this.workflowfolge.wfo_tracking_info.etracker_event_action ||
      !this.extra) {
      return;
    }
    try {
      let linkObj = cloneDeep(this.workflowfolge.wfo_tracking_info);
      linkObj = assign({}, linkObj, this.extra);
      console.log("utag for test", linkObj);
      window.utag.link(linkObj);
    } catch (err) {
      console.error(err);
    }
  }

  onServerError() { }
}

export default BaseFunction;
