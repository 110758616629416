"use strict";

import angular from "angular";

import CategoriesTypUUIDs from "./categoriesTypUUIDs.service";

const modelsModule = angular.module("foerderfinderangebote.services", [
])

  .constant("CategoriesTypUUIDs", CategoriesTypUUIDs)
;

export default modelsModule;
