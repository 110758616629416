"use strict";

import angular from "angular";

import registryService from "./registry.service";

const foerdermodule = angular.module("dias.foerdermodule.services", [
])

  .provider("FoerdermoduleRegistry", registryService);

export default foerdermodule;
