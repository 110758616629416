import PuxIcon from "../PuxIcon/PuxIcon.vue";
import PuxTranslate from "../PuxTranslate/PuxTranslate.vue";
import Spinner from "../Spinner/Spinner.vue";
import TooltipContent from "../PuxTooltip/TooltipContent/TooltipContent.vue";

import TooltipMixin from "../PuxTooltip/TooltipMixin";

import {
  cloneDeep,
  isString,
  isArray,
  forEach,
  isUndefined,
  startsWith,
} from "lodash-es";

const TYPE_CLASS_LIST = [
  "default",
  "primary",
  "secondary",
  "switch",
  "link",
  "block",
  "small",
  "danger",
  "sm",
  "lg",
  "dropdown",
];

// @vue/component
export default {
  name: "PuxButton",
  components: {
    Spinner,
    PuxIcon,
    PuxTranslate,
    TooltipContent,
  },
  mixins: [
    TooltipMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
      info: "HTML-Attribut 'id'",
    },
    testClass: {
      type: String,
      required: true,
      validator: value => startsWith(value, "test_"),
      info: "CSS-Klasse für Robot-Test",
    },
    type: {
      type: String,
      default: "button",
      required: false,
      validator: value => ["button", "submit"].indexOf(value) !== -1,
      info: "HTML-Attribut 'type'",
    },
    typeClass: {
      type: [String, Array],
      validator: value => {
        if (isString(value)) {
          return TYPE_CLASS_LIST.indexOf(value) !== -1;
        }
        if (isArray(value)) {
          if (value.length) {
            for (let i = 0; i < value.length; i++) {
              if (TYPE_CLASS_LIST.indexOf(value[i]) !== -1) {
                return true;
              }
            }
          }
        }
        return false;
      },
      required: false,
      default: undefined,
      info: "CSS-Klasse z.B. für 'default' -> 'btn.default'",
    },
    disabled: {
      type: Boolean,
      required: false,
      info: "HTML-Attribut 'disabled'",
    },
    loading: {
      type: Boolean,
      required: false,
      info: "Spinner anzeigen",
    },
    loadingAlign: {
      type: String,
      default: "right",
      required: false,
      validator: value => ["right", "left"].indexOf(value) !== -1,
      info: "Richtung, wo Spinner angezeigt wird",
    },
    active: { // For btn-switch
      type: Boolean,
      required: false,
      info: "Parameter für typeClass: 'switch'. CSS-Klasse: 'active', wenn Parameter === true",
    },
    text: {
      type: String,
      required: false,
      default: undefined,
      info: "Text für button",
    },
    textScreenReader: {
      type: String,
      required: false,
      default: undefined,
      info: "Text für screen-reader",
    },
    textAriaHidden: {
      type: Boolean,
      required: false,
      info: "HTML-Attribut 'aria-hidden' für Text",
    },
    textHtml: {
      type: String,
      required: false,
      default: undefined,
      info: "Text als HTML für button",
    },
    textClass: {
      type: String,
      required: false,
      default: undefined,
      info: "CSS-Klasse für Text",
    },
    icon: {
      type: String,
      required: false,
      default: undefined,
      info: "Icon",
    },
    iconClass: {
      type: String,
      required: false,
      default: undefined,
      info: "Zusätzliche CSS-Klasse für Icon",
    },
    iconAriaLabel: {
      type: String,
      required: false,
      default: undefined,
      info: "HTML-Attribut 'aria-label' für Icon",
    },
    iconRole: {
      type: String,
      required: false,
      default: undefined,
      info: "HTML-Attribut 'role' für Icon",
    },
    iconAttributes: {
      type: Object,
      required: false,
      default: undefined,
      info: "Weitere HTML-Attributes für Icon",
    },
    iconTag: {
      type: String,
      required: false,
      default: undefined,
      info: "Semantisch-relevanter HTML-Tag.(i, span, ...)",
    },
    tooltip: {
      type: String,
      required: false,
      default: undefined,
      info: "Tooltip",
    },
    tooltipDisabled: {
      type: String,
      required: false,
      default: undefined,
      info: "Tooltip, wenn button disabled ist",
    },
    tooltipType: {
      type: String,
      required: false,
      default: "default",
      validator: value => ["default", "html"].indexOf(value) !== -1,
      info: "Tooltip-Typ: default oder html",
    },
    tooltipPlacement: {
      type: String,
      required: false,
      default: "top",
      validator: value => ["top", "left", "bottom", "right"].indexOf(value) !== -1,
      info: "Standard Tooltip-Richtung(nur für tooltipType === 'html')",
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
      info: "Extra-Objekt für Übersetzung",
    },
    prevent: {
      type: Boolean,
      required: false,
      info: "JS-Funktion: preventDefault",
    },
    stop: {
      type: Boolean,
      required: false,
      info: "JS-Funktion: stopPropagation",
    },
    attributes: {
      type: Object,
      required: false,
      default: () => ({}),
      info: "Weitere HTML-Attributes für button",
    },
    ariaHaspopup: {
      type: Boolean,
      required: false,
      default: undefined,
      info: "HTML-Attribut 'aria-haspopup'",
    },
    ariaExpanded: {
      type: Boolean,
      required: false,
      default: undefined,
      info: "HTML-Attribut 'aria-expanded'",
    },
    ariaLabelledby: {
      type: String,
      required: false,
      default: undefined,
      info: "HTML-Attribut 'aria-labelledby'",
    },
    ariaDescribedby: {
      type: String,
      required: false,
      default: "",
    },
    ariaLabel: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  emits: [
    "blur",
    "click",
    "focus",
    "mouseenter",
    "mouseleave",
  ],
  computed: {
    typeClassLocal() {
      if (!this.typeClass) {
        return;
      }
      let buttonClass = "btn";
      if (isArray(this.typeClass)) {
        forEach(this.typeClass, cl => {
          buttonClass = `${ buttonClass } btn-${ cl }`;
        });
      } else {
        buttonClass = `${ buttonClass } btn-${ this.typeClass }`;
      }
      return buttonClass;
    },

    buttonClassLocal() {
      return [
        {
          active: this.active,
          disabled: this.statusDisabled,
        },
        this.typeClassLocal,
        this.testClass,
      ];
    },

    statusDisabled() {
      return this.disabled || this.loading;
    },

    statusLoadingLeft() {
      return this.loadingAlign === "left" && this.loading;
    },

    statusLoadingRight() {
      return this.loadingAlign === "right" && this.loading;
    },

    statusShowSpan() {
      return this.text || this.textHtml;
    },

    spanTranslateOptions() {
      const TRANSLATE_OPTIONS = {
        extra: this.extra,
      };
      if (this.text) {
        TRANSLATE_OPTIONS.text = this.text;
      } else if (this.textHtml) {
        TRANSLATE_OPTIONS.html = this.textHtml;
      }
      return TRANSLATE_OPTIONS;
    },

    spanClass() {
      const TEXT_CLASS = this.textClass || "";
      const TEXT_CLASS_WITH_ICON = this.icon ? "ml-2" : "";
      return [TEXT_CLASS, TEXT_CLASS_WITH_ICON];
    },

    attributesLocal() {
      const ATTRIBUTES = cloneDeep(this.attributes);
      if (this.ariaLabelledby) {
        if (ATTRIBUTES["aria-labelledby"]) {
          ATTRIBUTES["aria-labelledby"] = `${ ATTRIBUTES["aria-labelledby"] } ${ this.ariaLabelledby }`;
        } else {
          ATTRIBUTES["aria-labelledby"] = this.ariaLabelledby;
        }
      }
      if (this.ariaDescribedbyLocal) {
        ATTRIBUTES["aria-describedby"] = this.ariaDescribedbyLocal;
      }
      if (this.statusSwitchButton) {
        ATTRIBUTES["aria-pressed"] = `${ !!this.active }`;
      }
      if (!isUndefined(this.ariaHaspopup)) {
        ATTRIBUTES["aria-haspopup"] = `${ this.ariaHaspopup }`;
      }
      if (!isUndefined(this.ariaExpanded)) {
        ATTRIBUTES["aria-expanded"] = `${ this.ariaExpanded }`;
      }
      if (this.disabled) {
        ATTRIBUTES.tabindex = -1;
      }
      return ATTRIBUTES;
    },

    ariaDescribedbyLocal() {
      if (this.statusTooltipHtml) {
        return `${ this.ariaDescribedby ? `${ this.ariaDescribedby } ` : "" }${ this.ariaDescribedbyForHtmlTooltip }`;
      }
      return this.ariaDescribedby;
    },

    tooltipStandard() {
      if (this.tooltipActual && this.tooltipType === "default") {
        return this.tooltipActual;
      }
      return "";
    },

    statusTooltipHtml() {
      return this.tooltip && this.tooltipType === "html";
    },

    tooltipTranslateOptions() {
      return {
        html: this.tooltipActual,
        extra: this.extra,
      };
    },

    tooltipActual() {
      if (this.disabled && this.tooltipDisabled) {
        return this.tooltipDisabled;
      }
      return this.tooltip;
    },

    statusSwitchButton() {
      if (!this.typeClass) {
        return false;
      }
      if (isArray(this.typeClass)) {
        return this.typeClass.indexOf("switch");
      }
      return this.typeClass === "switch";
    },

    statusShowTooltipContent() {
      return this.statusTooltipHtml && this.statusShowTooltip;
    },

    attributesForText() {
      const ATTRIBUTES = cloneDeep(this.spanTranslateOptions);
      if (this.textAriaHidden) {
        ATTRIBUTES["aria-hidden"] = true;
      }
      return ATTRIBUTES;
    },

    watchProperty() {
      return [
        this.text,
        this.textHtml,
        this.icon,
        this.tooltip,
        this.tooltipPlacement,
      ];
    },

    ariaDisabled() {
      return this.statusDisabled ? "true" : "false";
    },

    idForTooltipContent() {
      return `${ this.id }_tooltip_content`;
    },
  },
  watch: {
    watchProperty: {
      handler() {
        this.updateHtmlTooltip();
      },
      deep: true
    },
  },
  methods: {
    onClick($event) {
      if (this.prevent) {
        $event.preventDefault();
      }
      if (this.stop) {
        $event.stopPropagation();
      }
      if (this.statusDisabled) {
        return;
      }
      this.$emit("click", this.argumentsForParent($event));
    },

    onFocus($event) {
      this.$emit("focus", this.argumentsForParent($event));
      if (!this.statusTooltipHtml) {
        return;
      }
      this.showTooltip();
    },

    onBlur($event) {
      this.$emit("blur", this.argumentsForParent($event));
      if (!this.statusTooltipHtml) {
        return;
      }
      this.closeTooltip();
    },

    argumentsForParent($event) {
      const PROPS = cloneDeep(this.$props);
      PROPS.$event = $event;
      return PROPS;
    },

    mouseenterLocal($event) {
      this.$emit("mouseenter", this.argumentsForParent($event));
      if (!this.statusTooltipHtml) {
        return;
      }
      this.showTooltip();
    },

    mouseleaveLocal($event) {
      this.$emit("mouseleave", this.argumentsForParent($event));
      if (!this.statusTooltipHtml) {
        return;
      }
      this.closeTooltipWithTimer();
    },

    updateHtmlTooltip() {
      if (this.statusTooltipHtml) {
        setTimeout(() => {
          this.updateTooltipOptions();
        });
      }
    },
  },
};
