import AngularLink from "../../../../../../../libs/vue/client/vue/global/components/AngularLink/AngularLink.vue";
import FormElement from "../../../../../../../libs/vue/client/vue/global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";
import StudiengangEdit from "../../../../../../../libs/vue/client/vue/components/Geschaeftsregel/Module/AR/StudiengangAuswahl/StudiengangEdit/StudiengangEdit.vue";

import { FilterDateMixin } from "../../../../../../../libs/vue/client/vue/global/mixins/FiltersMixin";
import HttpMixin from "../../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import PermissionMixin from "../../../../../../../libs/vue/client/vue/global/mixins/PermissionMixin";

// @vue/component
export default {
  name: "StudiengangDetailsOverview",
  components: {
    AngularLink,
    FormElement,
    FormstepDetail,
    StudiengangEdit,
  },
  mixins: [
    FilterDateMixin,
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    studiengang: {
      type: Object,
      required: true,
    },
    parentHtmlId: {
      type: String,
      required: true,
    },
    parentLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "loadStudiengang",
  ],
  data() {
    return {
      loading: false,
      editMode: false,
    };
  },
  computed: {
    htmlId() {
      return `${ this.parentHtmlId }_container`;
    },

    sgBeginnStudienbetriebFiltered() {
      return this.filterDate(this.studiengang.sg_beginn_studienbetrieb);
    },

    htmlIdEdit() {
      return `${ this.parentHtmlId }_container_edit`;
    },

    hsExternalLinkTitle() {
      return `Zum Hochschulkompass wechseln für Studiengang Nr. ${ this.studiengang.sg_laufnr }`;
    },

    hasPermissionStudiengangCopy() {
      return this.checkPermissionsSync({
        perm: "studiengang.copy",
      });
    },

    hasPermissionStudiengangUpdate() {
      return this.checkPermissionsSync({
        perm: "studiengang.update",
      });
    },

    hasPermissionfakturierungVerwalten() {
      return this.checkPermissionsSync({
        perm: "studiengang.fakturierung.verwalten",
      });
    },

    formstepDetailOptions() {
      return {
        label: `Studiengang: ${ this.studiengang.sg_bez }`,
        openCallback: this.open,
      };
    },
  },
  methods: {
    open() {
      this.editMode = true;
    },

    onSave() {
      this.editMode = false;
      this.$emit("loadStudiengang");
    },

    onAbbrechen() {
      this.editMode = false;
    },
  },
};
