class WorkflowEditorService {
  /*@ngInject*/
  constructor($http) {
    this.$http = $http;
  }
  getWorkflow(workflowId) {
    return this.$http({
      method: "GET",
      headers: {
        Accept: "application/json"
      },
      url: "api/workflow-editor/" + workflowId + "/?format=json"
    }).success(function(data) {
      // this callback will be called asynchronously
      // when the response is available
      return data;
    }).error(function(data, status) {
      // called asynchronously if an error occurs
      // or server returns response with an error status.
      // alert(status);
      console.error(status);
    });
  }
  setWorkflow(workflow) {
    return this.$http({
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      url: "api/workflow-editor/2db26051-14ad-5ed5-e050-007f01013822/",
      data: workflow,
    }).success(function(data) {
      // this callback will be called asynchronously
      // when the response is available
      return data;
    }).error(function(data, status) {
      // called asynchronously if an error occurs
      // or server returns response with an error status.
      // alert(status);
      console.error(status);
    });
  }
}

export default WorkflowEditorService;
