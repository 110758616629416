import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import SitzungsartTagesordnungItem from "./SitzungsartTagesordnungItem/SitzungsartTagesordnungItem.vue";

// @vue/component
export default {
  name: "SitzungsartTagesordnung",
  components: {
    FormstepDetail,
    SitzungsartTagesordnungItem,
  },
  props: {
    tagesordnungspunkte: {
      type: Array,
      required: true,
    },
    tagesordnungspunkteUrl: {
      type: String,
      required: true,
    },
    sitzungsmoduleKeyByPk: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const optionsFormstepDetail = {
      hideHeader: true,
    };

    return {
      optionsFormstepDetail,
    };
  },
};
