"use strict";

/**
 * Erweitert eine gegebene Componente um Konfigurationen, die für Sitzungsmodule
 * erforderlich sind.
 */
export default {
  directive: (template, controller) => () => {
    return {
      restrict: "A",
      scope: {
        idx: "@",
        group: "@",
        kontext: "@?",
        obj: "<",
        modul: "<",
        readonly: "<?",
        hasBaseEditPerm: "<?",
        snapshot: "<",
        snapshotdiff: "=",
        savecallback: "&?",
        infoprovider: "=?",
        notizen: "<?",
        notizoptions: "<?",
        notizkey: "<?",
      },
      template: template,
      controller: controller,
      controllerAs: "vm",
      bindToController: true
    };
  }
};
