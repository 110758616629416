import AnkuendigungCards from "../../../Ankuendigung/AnkuendigungCards/AnkuendigungCards.vue";
import PuxPageDetails from "../../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import RegistrationHeader from "../../RegistrationHeader/RegistrationHeader.vue";

import LoginMixin from "../LoginMixin";

const CLASSES_HORIZONTAL = [
  "col-sm-4 text-left text-sm-right",
  "col-sm-6",
  "offset-sm-4 col-sm-6",
];

// @vue/component
export default {
  name: "LoginPage",
  components: {
    AnkuendigungCards,
    PuxPageDetails,
    RegistrationHeader,
  },
  mixins: [
    LoginMixin
  ],
  data() {
    return {
      statusPage: true,
      usernameOptions: {
        id: "username",
        type: "email",
        view: "h",
        label: "_LBL_LOGIN_BOX_USERNAME_",
        required: true,
        translate: true,
        classesHorizontal: CLASSES_HORIZONTAL,
      },
      passwortOptions: {
        id: "password",
        type: "password",
        view: "h",
        label: "_LBL_LOGIN_BOX_PASSWORT_",
        required: true,
        translate: true,
        classesHorizontal: CLASSES_HORIZONTAL,
      },
    };
  },
};
