import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "AufgabeModalZuweisen",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    aufgabe: {
      type: Object,
      default: () => ({}),
    },
    selectorClose: {
      type: [String, Array],
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "nutzer",
            type: "select",
            label: "_LBL_AUFGABE_MODAL_ZUWEISEN_NUTZER_",
            required: true,
            view: "v-alt",
            url: `aktuelle_aufgaben/${ this.aufgabe.pk }/zustaendige/`,
            keyLabelCallback: ({ item }) => `${ item.name }${ item.abwesenheit ? ` (${ item.abwesenheit })` : "" }`,
            keyId: "pk",
            placeholder: "_LBL_AUFGABE_MODAL_ZUWEISEN_NUTZER_PLACEHOLDER_",
            searchParameter: "suche",
            searchGlobal: true,
            menuWidthAll: true,
            translate: true,
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  methods: {
    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.postHttp({ url: `aktuelle_aufgaben/${ this.aufgabe.pk }/zuweisen/`, data }).then(
        () => {
          this.addNotification({ text: "_MSG_AUFGABE_MODAL_ZUWEISEN_SUCCESS_" });
          this.close({ status: true });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
