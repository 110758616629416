"use strict";

const diasModalComponent = /*@ngInject*/ diasModalDialog => {
  return {
    restrict: "A",
    scope: {
      modalTitle: "@",
      modalTemplate: "&",
      modalController: "=",
    },
    link: function(scope, element) {
      element.on("click", function() {
        diasModalDialog({
          title: scope.modalTitle,
          template: scope.modalTemplate,
          controller: scope.modalController
        });
      });
    }
  };
};

export default diasModalComponent;
