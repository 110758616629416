import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  FilterDateMixin,
} from "../../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "Sperrung",
  components: {
    FormstepDetail,
    PuxTranslate,
  },
  mixins: [
    FilterDateMixin
  ],
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
    panelPermissions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        label: "_LBL_SPERRUNG_",
      },
    };
  },
};
