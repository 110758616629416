import FoerderorgAnsprechpartnerModalMirZuweisen from "../FoerderorgAnsprechpartnerModalMirZuweisen/FoerderorgAnsprechpartnerModalMirZuweisen.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "FoerderorgAnsprechpartnerMirZuweisenButtonModal",
  components: {
    FoerderorgAnsprechpartnerModalMirZuweisen,
    PuxButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    ansprechpartner: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModalMirZuweisenVisible: false,
    };
  },
  computed: {
    selectorClose() {
      return `#${ this.id }`;
    },
  },
  methods: {
    openModalMirZuweisenAnsprechpartner() {
      this.isModalMirZuweisenVisible = true;
    },

    closeModalMirZuweisenAnsprechpartner() {
      this.isModalMirZuweisenVisible = false;
    },
  },
};
