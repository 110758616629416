import {
  computed,
  ref,
  toRef,
} from "vue";

import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import {
  cloneDeep,
  forEach,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "GeschaeftsregelInfo",
  components: {
    FormElement,
    FormstepDetail,
    PuxAlert,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    regel: {
      type: Object,
      required: true,
    },
    regelUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "updateRegel",
  ],
  setup(props, { emit }) {
    const regel = toRef(props, "regel");
    const regelUrl = toRef(props, "regelUrl");

    const {
      checkPermissionsSync,
    } = PermissionAPI();
    const isEditable = computed(() => {
      return checkPermissionsSync({
        perm: "foerderregel.update",
        permArray: regel.value.user_permissions,
      });
    });

    const saveCallbackChoices = computed(() => {
      return regel.value.savecallback_choices || {};
    });
    const saveCallbackChoicesList = computed(() => {
      const LIST = [];
      forEach(saveCallbackChoices.value, (item, key) => {
        LIST.push({
          value: key,
          label: item,
        });
      });
      return LIST;
    });
    const saveCallbackLabel = computed(() => {
      return get(saveCallbackChoices.value, regel.value.re_savecallback, "_TXT_KEINE_");
    });

    const model = ref({});
    const errors = ref(undefined);
    const open = () => {
      model.value = cloneDeep(regel.value);
      if (model.value.re_savecallback) {
        model.value.re_savecallback = `${ model.value.re_savecallback }`;
      } else {
        model.value.re_savecallback = "null";
      }
    };
    const {
      putHttp,
    } = HttpAPI();
    const {
      addNotification,
    } = NotificationAPI();
    const getDataForSave = () => {
      return {
        re_kbez: model.value.re_kbez,
        re_kbez_admin: model.value.re_kbez_admin,
        re_bezeichnung: model.value.re_bezeichnung,
        re_astbez: model.value.re_astbez,
        re_savecallback: model.value.re_savecallback === "null" ? null : model.value.re_savecallback,
        key: model.value.key,
        anzeigekontext: model.value.anzeigekontext,
      };
    };
    const deleteErrors = () => {
      errors.value = undefined;
    };
    const save = () => {
      deleteErrors();
      return new Promise((resolve, reject) => {
        putHttp({
          url: regelUrl.value,
          data: getDataForSave(),
        }).then(
          response => {
            addNotification({ text: "_MSG_GR_DETAILS_INFO_SAVE_SUCCESS_" });
            if (regel.value.is_used) {
              addNotification({ text: "_MSG_GR_DETAILS_INFO_SAVE_USED_SUCCESS_" });
            }
            response.key_bez = response.key_bez || null;
            emit("updateRegel", response);
            resolve();
          },
          error => {
            errors.value = error.data;
            reject(error);
          },
        );
      });
    };

    const optionsFormstepDetail = {
      label: "_TXT_GR_DETAILS_REGEL_",
      openCallback: open,
      saveCallback: save,
      required: true,
    };

    const isMessageHide = ref(false);
    const closeMessage = () => {
      isMessageHide.value = true;
    };

    const kbezAdminOptions = {
      type: "text",
      label: "_TXT_GR_DETAILS_KURZBEZEICHNUNG_FUER_ADMINISTRATION_",
      view: "v",
      id: "re_kbez_admin",
      required: true,
    };
    const kbezOptions = {
      type: "text",
      label: "_TXT_GR_DETAILS_MELDUNGSTEXT_BEI_VALIDIERUNGSMELDUNGEN_",
      view: "v",
      id: "re_kbez",
      required: true,
    };
    const bezeichnungOptions = {
      type: "richtext",
      label: "_TXT_GR_DETAILS_BESCHREIBUNG_",
      view: "v-alt",
      id: "re_bezeichnung",
      required: true,
    };
    const saveCallbackOptions = {
      type: "select",
      label: "_LBL_FOERDERREGEL_DETAILS_SAVECALLBACK_",
      view: "v",
      id: "re_savecallback",
      required: false,
      keyLabel: "label",
      keyId: "value",
      emptyValue: "null",
      emptyLabel: "_TXT_KEINE_"
    };
    const keyOptions = {
      type: "select",
      label: "_TXT_GR_DETAILS_SCHLUESSEL_OPTIONAL_",
      view: "v",
      id: "key",
      required: false,
      keyLabel: "rek_kbez",
      keyId: "pk",
      search: true,
      searchList: ["rek_kbez"],
      url: "katalog/",
      urlParams: {
        key: "regelkeys",
      },
      apiSaveId: "regelkeys",
      deselect: true,
    };
    const anzeigekontextOptions = {
      type: "select",
      label: "_TXT_GR_DETAILS_ANZEIGEKONTEXT_",
      view: "v",
      id: "anzeigekontext",
      required: true,
      keyLabelJson: [
        {
          teg: "span",
          keyLabel: "faz_kbez",
        },
        {
          teg: "span",
          string: " - ",
        },
        {
          teg: "span",
          keyLabel: "faz_bez",
        },
      ],
      keyId: "faz_id",
      search: true,
      searchList: [
        "faz_kbez",
        "faz_bez",
      ],
      url: "foerderanzeigekontexte/",
    };
    const astbezOptions = {
      type: "richtext",
      label: "_LBL_FOERDERREGEL_EDIT_HILFETEXT_ANTRAGSTELLER_",
      view: "v-alt",
      id: "re_astbez",
      required: true,
    };
    const errorsLabelsOptions = [
      kbezAdminOptions,
      kbezOptions,
      bezeichnungOptions,
      saveCallbackOptions,
      keyOptions,
      anzeigekontextOptions,
      astbezOptions,
    ];

    return {
      anzeigekontextOptions,
      astbezOptions,
      bezeichnungOptions,
      closeMessage,
      errors,
      errorsLabelsOptions,
      isEditable,
      isMessageHide,
      kbezAdminOptions,
      kbezOptions,
      keyOptions,
      model,
      optionsFormstepDetail,
      saveCallbackChoicesList,
      saveCallbackLabel,
      saveCallbackOptions,
    };
  },
};
