import {
  computed,
  ref,
} from "vue";

import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";

import {
  cloneDeep,
  filter,
  find,
  isNil,
} from "lodash-es";

export default function SitzungDetailsTagesordnungItemAPI({ topsUrl, tagesordnungItem, punkteChoices }) {
  const moduleKatalog = ref([]);
  const modules = ref([]);
  const view = ref("v-alt");
  const selectedModule = ref(undefined);
  const modelOptionsModule = ref(undefined);
  const loadingModuleKatalog = ref(false);
  const loadingTagesordnungItemModules = ref(false);

  const model = ref({
    tos_pos: undefined,
    tos_bez: undefined,
    tos_info: undefined,
    oberpunkt: undefined,
    modules: [],
  });

  const optionsTosPos = computed(() => {
    return {
      id: "tos_pos",
      label: "_LBL_SITZUNGSDETAILS_TAGESORDNUNGSPUNKT_APPEND_TO_POSITION_",
      view: "v-alt",
      type: "select",
      required: true,
      keyId: "value",
      keyLabel: "label",
      data: punkteChoices ? punkteChoices.value : [],
    };
  });

  const optionsTosBez = computed(() => {
    return {
      id: "tos_bez",
      label: "_TXT_SITZUNGSDETAILS_TAGESORDNUNGSPUNKT_NAME_",
      view: "v-alt",
      type: "text",
      required: true,
    };
  });

  const optionsTosInfo = computed(() => {
    return {
      id: "tos_info",
      label: "_LBL_SITZUNGSTAGESORDNUNG_TOS_INFO_",
      view: "v-alt",
      maxlength: 100,
      type: "text",
      required: false,
    };
  });

  const optionsModuleFilteredChoices = computed(() => {
    return filter(
      moduleKatalog.value,
      item => !find(
        model.value.modules,
        modul => modul.modul === item.pk
      )
    );
  });

  const moduleChoicesAvailable = computed(() => {
    return !!optionsModuleFilteredChoices.value.length;
  });

  const buttonAddModuleDisabled = computed(() => {
    return !modelOptionsModule.value;
  });

  const onSelectModule = ({ model }) => {
    selectedModule.value = find(
      moduleKatalog.value,
      modul => modul.pk === model
    );
  };

  const optionsModule = computed(() => {
    const ID = "tos_module_selectable";
    return {
      id: ID,
      label: "_LBL_SITZUNGSDETAILS_TAGESORDNUNGSPUNKT_SELECT_MODULE_",
      view: view.value,
      type: "select",
      keyId: "pk",
      keyLabel: "ssm_bez",
      data: optionsModuleFilteredChoices.value,
      change: onSelectModule,
    };
  });

  const setModel = () => {
    if (tagesordnungItem !== undefined) {
      model.value.tos_pos = tagesordnungItem.value.tos_pos;
      model.value.tos_bez = tagesordnungItem.value.tos_bez;
      model.value.tos_info = tagesordnungItem.value.tos_info;
      model.value.oberpunkt = tagesordnungItem.value.oberpunkt;
      model.value.modules = modules.value;
    }
  };

  const {
    addNotification,
    getListHttp,
    deleteHttp,
    postHttp,
  } = HttpAPI();

  const loadKonfigurierbareModuleKatalog = () => {
    loadingModuleKatalog.value = true;
    return getListHttp({
      url: "katalog/",
      apiSaveId: "katalog_sitzungsmodule_ssm_konfigurierbar",
      urlParams: {
        key: "sitzungsmodule",
        ssm_konfigurierbar: true,
      },
    }).then(
      response => {
        moduleKatalog.value = cloneDeep(response);
        loadingModuleKatalog.value = false;
        return Promise.resolve();
      },
      errors => {
        addNotification({
          text: "_MSG_SITZUNGSDETAILS_TAGESORDNUNGSPUNKT_ERROR_LOADING_SESSION_MODULES_",
          type: "error",
        });
        return Promise.reject(errors);
      },
    ).finally(
      () => loadingModuleKatalog.value = false,
    );
  };

  const loadSitzungTagesordnungModules = () => {
    loadingTagesordnungItemModules.value = true;
    return getListHttp({
      url: `${ topsUrl.value }${ tagesordnungItem.value.pk }/sitzungtagesordnungmodule/`
    }).then(
      response => {
        modules.value = cloneDeep(response);
        return Promise.resolve();
      },
      errors => Promise.reject(errors)
    ).finally(
      () => loadingTagesordnungItemModules.value = false,
    );
  };

  const addModule = () => {
    if (!isNil(selectedModule.value)) {
      model.value.modules.push({
        modul: selectedModule.value.pk,
        stm_kbez: selectedModule.value.ssm_bez,
        stm_paramterin: {},
      });

      selectedModule.value = undefined;
      modelOptionsModule.value = undefined;
    }
  };

  const removeModule = ({ index }) => {
    model.value.modules.splice(index, 1);
  };

  const moveModuleUp = ({ index }) => {
    if (index === 0) {
      return;
    }
    const ELEMENT = model.value.modules[index];
    const PREVIOUS = model.value.modules[index - 1];
    model.value.modules[index - 1] = ELEMENT;
    model.value.modules[index] = PREVIOUS;
  };

  const moveModuleDown = ({ index }) => {
    if (index >= model.value.modules.length - 1) {
      return;
    }
    const ELEMENT = model.value.modules[index];
    const NEXT = model.value.modules[index + 1];
    model.value.modules[index + 1] = ELEMENT;
    model.value.modules[index] = NEXT;
  };

  const deleteTagesordnungItem = () => {
    return deleteHttp({
      url: `${ topsUrl.value }${ tagesordnungItem.value.pk }/`,
    }).then(
      () => Promise.resolve(),
      errors => Promise.reject(errors),
    );
  };

  const resetTagesordnungItem = () => {
    return postHttp({
      url: `${ topsUrl.value }${ tagesordnungItem.value.pk }/reset/`,
    }).then(
      () => Promise.resolve(),
      errors => Promise.reject(errors),
    );
  };

  return {
    buttonAddModuleDisabled,
    loadingModuleKatalog,
    loadingTagesordnungItemModules,
    model,
    moduleChoicesAvailable,
    modelOptionsModule,
    moduleKatalog,
    optionsModule,
    optionsModuleFilteredChoices,
    optionsTosBez,
    optionsTosInfo,
    optionsTosPos,

    addModule,
    deleteTagesordnungItem,
    loadKonfigurierbareModuleKatalog,
    loadSitzungTagesordnungModules,
    moveModuleDown,
    moveModuleUp,
    removeModule,
    resetTagesordnungItem,
    setModel,
  };
}
