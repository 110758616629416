import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import ExtraField from "../../../KF/KofiErfassung/KofiErfassungBelegForm/ExtraField/ExtraField";

// @vue/component
export default {
  name: "BelegButtonVersionsvergleichExtraFieldTableRow",
  components: {
    FormElementReadOnly,
    PuxTranslate,
    SnapshotIcon,
  },
  mixins: [
    ExtraField,
  ],
  props: {
    modelErfasst: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
    modelAnerkannt: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
  },
};
