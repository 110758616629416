import PuxCloak from "../../global/components/PuxCloak/PuxCloak.vue";
import PuxTooltip from "../../global/components/PuxTooltip/PuxTooltip.vue";
import SitzungsantraegeModalEntscheiden from "./SitzungsantraegeModalEntscheiden/SitzungsantraegeModalEntscheiden.vue";
import SitzungsantraegeModalEntscheidungAnmerkung from "./SitzungsantraegeModalEntscheidungAnmerkung/SitzungsantraegeModalEntscheidungAnmerkung.vue";
import SitzungsantraegeModalProtokollEdit from "./SitzungsantraegeModalProtokollEdit/SitzungsantraegeModalProtokollEdit.vue";
import SitzungsantraegeModalProtokollView from "./SitzungsantraegeModalProtokollView/SitzungsantraegeModalProtokollView.vue";
import SmartTable from "../../global/components/table/table.vue";
import Spaced from "../../global/components/Spaced/Spaced.vue";

import Loading from "../../global/directives/loading";

import HttpMixin from "../../global/mixins/HttpMixin";
import NotificationMixin from "../../global/mixins/NotificationMixin";
import { FilterCurrencyMixin } from "../../global/mixins/FiltersMixin";
import PermissionMixin from "../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../global/mixins/SyConfigMixin";

import getTableApi from "../../const/TableApis";
import { EventBus } from "../../global/functions/event-bus";
import {
  assign,
  forEach,
  sortBy,
} from "lodash-es";

const TABLE_ID = "sitzungslisteantrag";

// @vue/component
export default {
  components: {
    PuxCloak,
    PuxTooltip,
    SitzungsantraegeModalEntscheiden,
    SitzungsantraegeModalEntscheidungAnmerkung,
    SitzungsantraegeModalProtokollEdit,
    SitzungsantraegeModalProtokollView,
    SmartTable,
    Spaced,
  },
  directives: {
    Loading,
  },
  mixins: [
    FilterCurrencyMixin,
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  props: {
    parameter: {
      type: Object,
      default: () => ({}),
    },
    userInput: {
      type: Object,
      default: () => ({}),
    },
    sitzungPk: {
      type: String,
      default: undefined,
    },
    top: {
      type: Object,
      default: undefined,
    },
    emitEventAngular: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      status: {
        loading: true,
        entscheidung: false,
      },
      modal: {
        entscheidungAnmerkung: undefined,
        protokollEdit: undefined,
        protokollView: undefined,
        entscheiden: undefined,
      },
      hasViewPermission: undefined,
      currentSitzungsantrag: undefined,
      currentSitzungsantragIndex: undefined,
      list: undefined,
      entscheidungsstatus: [],
      entscheidungsAktionen: undefined,
      priorisierungAktionen: undefined,
      protokollierungAktionen: undefined,
    };
  },
  computed: {
    antragScoreConfig() {
      return this.getSyConfigsValue("antrag_score") || {};
    },

    canDecide_mixin() {
      return this.checkPermissionsSync({ perm: "tops.antrag.decide", permArray: this.list.user_permissions });
    },

    showAnmerkungColumn_mixin() {
      const SY_CONFIG_WERT = this.getSyConfigsValue("sitzunglisteantrag_auflagen_protokoll_sichtbar") || {};
      const PERMISSION = SY_CONFIG_WERT.permission || "tops.antrag.decide";
      return this.checkPermissionsSync({ perm: PERMISSION, permArray: this.list.user_permissions });
    },

    canProtocol_mixin() {
      return this.checkPermissionsSync({ perm: "sitzungen.protokoll.update", permArray: this.list.user_permissions }) ||
        this.checkPermissionsSync({ perm: "sitzungen.protokoll.view", permArray: this.list.user_permissions });
    },

    canView_mixin() {
      return this.checkPermissionsSync({ perm: "tops.antrag.view", permArray: this.list.user_permissions });
    },

    getTableUrl_mixin() {
      const URL_SUFFIX = this.userInput.festgeschrieben ? "festgeschrieben" : "dynamisch";
      return getTableApi({
        id: TABLE_ID,
        params: {
          sitzungPk: this.sitzungPk,
          topPk: this.top.pk,
          sl_id: this.userInput.sl_id,
          urlSuffix: URL_SUFFIX,
        }
      });
    },

    getStatusTitle_mixin() {
      return row => `${ row.entscheidungsstatus_obj.est_kbez }${ row.sla_entscheidung_anmerkung ? `<br>${ row.sla_entscheidung_anmerkung }` : "" }`;
    },
  },
  methods: {
    initUrl_mixin() {
      this.options.url = this.getTableUrl_mixin;
    },

    renderFoerderorg_mixin({ ast, type }) {
      let result = "";
      if (ast.foerderorghierarchie && ast.foerderorghierarchie.length > 0) {
        result = ast.foerderorghierarchie.filter(item => item.o_otid === type).map(item => item.o_kbez).join(", ");
      }
      return result;
    },

    initEntscheidungsAktionen_mixin() {
      forEach(sortBy(this.entscheidungsstatus, ["pos"]), status => {
        this.options.rowActions.push({
          label: status.est_aktion_bez,
          callback: ({ item, index }) => this.setEntscheidung_mixin({ item, index, status: status }),
          isHidden: () => !this.canDecide_mixin,
        });
      });
      this.options.rowActions.push({
        label: "_BTN_SITZUNG_ANTRAG_AUFLAGE_ERFASSEN_",
        callback: this.openModalEntscheidungAnmerkung_mixin,
        isHidden: () => !this.canDecide_mixin,
      });
    },

    setEntscheidung_mixin({ item, index, status }) {
      if (this.status.entscheidung) {
        return;
      }
      this.status.entscheidung = true;
      this.putHttp({
        url: `${ this.getTableUrl_mixin }${ item.pk }/setze_entscheidung/`,
        data: { entscheidungsstatus: status.pk },
      }).then(
        response => {
          this.addNotification({
            text: "_MSG_SITZUNGSANTRAEGE_LIST_IN_TAGESORDNUNG_MIXIN_SET_ENTSCHEIDUNG_SUCCESS_{{bez}}_",
            extra: {
              bez: status.bez
            }
          });
          this.setUpdateRow({ index, response, item });
          this.emitEventAngular();
        },
        () => this.addNotification({ text: "_MSG_SITZUNGSANTRAEGE_LIST_IN_TAGESORDNUNG_MIXIN_SET_ENTSCHEIDUNG_ERROR_", type: "error" })
      ).then(
        () => this.status.entscheidung = false
      );
    },

    setUpdateRow({ index, response, item }) {
      const ROW = assign({}, item, response);
      EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: ROW, index }));
    },

    initProtokollierungAktionen_mixin() {
      this.options.rowActions.push({
        label: "_BTN_SITZUNG_ANTRAG_PROTOKOLL_EDIT_",
        callback: this.openModalProtokollEdit_mixin,
        isHidden: () => !this.canDecide_mixin ||
                        !this.checkPermissionsSync({ perm: "sitzungen.protokoll.update", permArray: this.list.user_permissions }),
      });
      this.options.rowActions.push({
        label: "_BTN_SITZUNG_ANTRAG_PROTOKOLL_EINSEHEN_",
        callback: this.openModalProtokollView_mixin,
        isHidden: () => !this.canDecide_mixin ||
                        (this.checkPermissionsSync({ perm: "sitzungen.protokoll.update", permArray: this.list.user_permissions }) &&
                        !this.checkPermissionsSync({ perm: "sitzungen.protokoll.view", permArray: this.list.user_permissions })),
      });
    },

    openModalEntscheidungAnmerkung_mixin({ item, index }) {
      this.setCurrentSitzunsantragAndIndex_mixin({ item, index });
      this.modal.entscheidungAnmerkung = true;
    },

    closeModalEntscheidungAnmerkung_mixin({ NEW_SITZUNGSANTRAG } = {}) {
      if (NEW_SITZUNGSANTRAG) {
        EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: NEW_SITZUNGSANTRAG, index: this.currentSitzungsantragIndex }));
      }
      this.modal.entscheidungAnmerkung = false;
    },

    openModalProtokollEdit_mixin({ item, index }) {
      this.setCurrentSitzunsantragAndIndex_mixin({ item, index });
      this.modal.protokollEdit = true;
    },

    closeModalProtokollEdit_mixin({ NEW_SITZUNGSANTRAG } = {}) {
      if (NEW_SITZUNGSANTRAG) {
        EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: NEW_SITZUNGSANTRAG, index: this.currentSitzungsantragIndex }));
      }
      this.modal.protokollEdit = false;
    },

    openModalProtokollView_mixin({ item, index }) {
      this.setCurrentSitzunsantragAndIndex_mixin({ item, index });
      this.modal.protokollView = true;
    },

    closeModalProtokollView_mixin() {
      this.modal.protokollView = false;
    },

    setCurrentSitzunsantragAndIndex_mixin({ item, index }) {
      this.currentSitzungsantrag = item;
      this.currentSitzungsantragIndex = index;
    },

    openModalEntscheiden_mixin() {
      this.modal.entscheiden = true;
    },

    closeModalEntscheiden_mixin({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
        this.emitEventAngular();
      }
      this.modal.entscheiden = false;
    },
  },
};
