"use strict";

import angular from "angular";
import vueInit from "./vueInit.directive";

const diasVueinit = angular.module("dias.ui.vueinit", [
])

  .directive("vueinit", vueInit);

export default diasVueinit;
