"use strict";

import BaseModel from "dias/core/models/baseModel";

class Bankverbindung extends BaseModel {
  constructor() {
    super("bankverbindungen");
  }
}

export default Bankverbindung;
