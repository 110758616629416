import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import {
  cloneDeep,
  forEach,
  isEmpty,
} from "lodash-es";
import translate from "../../../../global/directives/translate";

// @vue/component
export default {
  name: "Schlagworte",
  components: {
    SnapshotModule,
    SnapshotIcon,
  },
  directives: {
    translate,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.list = [
        {
          id: "schlagworte",
          htmlId: `${ this.htmlRegelNummer }_schlagworte`,
          type: "multiselect",
          label: "_LBL_GR_SCHLAGWORTE_SELECT_SCHLAGWORTE_",
          placeholder: "_LBL_GR_SCHLAGWORTE_SELECT_SCHLAGWORTE_PLACEHOLDER_",
          editonly: true,
          view: "v-alt",
          addNew: true,
          keyArray: true,
          translate: true,
          startupFocus: true,
          url: `schlagworte/`,
          urlParams: {
            content_type: this.model.content_type,
          },
          searchGlobal: true,
          searchParameter: "sw_kbez",
          keyId: "sw_kbez",
          keyLabel: "sw_kbez",
        },
      ];
      return OPTIONS;
    },

    snapshotModuleSchlagworte() {
      if (this.snapshotModule && this.snapshotModule.schlagworte) {
        return this.snapshotModule.schlagworte;
      }
      return [];
    },

    modelSchlagworte() {
      return this.model.schlagworte || [];
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(this.modelSchlagworte, wort => {
          if (this.snapshotModuleSchlagworte.indexOf(wort) === -1) {
            DIFF[wort] = true;
          }
        });
        forEach(this.snapshotModuleSchlagworte, wort => {
          if (this.modelSchlagworte.indexOf(wort) === -1) {
            DIFF[wort] = true;
          }
        });
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },

    labelHeader() {
      return "_LBL_GR_SCHLAGWORTE_HEADER_";
    },
  },
  created() {
  },
  methods: {
    prepareModel() {
      const MODEL = cloneDeep(this.module.data) || {};
      MODEL.schlagworte = MODEL.schlagworte || [];
      this.model = MODEL;
    },
  },
};
