import FaqDetailsItemMixin from "../FaqDetailsItemMixin";

// @vue/component
export default {
  name: "FaqDetailsItemHtml",
  mixins: [
    FaqDetailsItemMixin,
  ],
  computed: {
    html() {
      return this.parameter.html;
    },
  },
};
