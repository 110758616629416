<div><h3>Alert</h3><h4>Beispliel 1</h4><alert :errors="errors1" :options="optionsAlert1"></alert><div v-highlight=""><pre>JS<code>import Alert from ".../global/components/Alert/Alert.vue";

export default {
  ...
  components: [
    Alert,
  ],
data() {
  return {
    optionsAlert1: {
      list: [
        {
          label: "Aloha",
          id: "aloha",
        },
        {
          label: "Bar",
          id: "foo",
        },
      ]
    },
    errors1: {
      aloha: ["Dieses Feld ist erforderlich."],
      foo: ["Dieses Feld ist erforderlich."],
    },
  }
}
  ...
}</code></pre><pre>PUG<code>alert(
  :errors="errors1"
  :options="optionsAlert1"
)
</code></pre></div><h4>Beispliel 2</h4><alert :errors="errors2" :options="optionsAlert2" :labels="labelsAlert2"></alert><div v-highlight=""><pre>JS<code>import Alert from ".../global/components/Alert/Alert.vue";

export default {
  ...
  components: [
    Alert,
  ],
data() {
  return {
    optionsAlert2: {
      message: "Achtung!!! Das ist eine neue Nachricht.",
      type: "info",
    },
    errors2: {
      aloha: ["Dieses Feld ist erforderlich."],
      foo: ["Dieses Feld ist erforderlich."],
    },
    labelsAlert2: {
      aloha: "Aloha",
      foo: "Bar",
    },
  }
}
  ...
}</code></pre><pre>PUG<code>alert(
  :errors="errors2"
  :options="optionsAlert2"
  :labels="labelsAlert2"
)
</code></pre></div><h4>Props</h4><table class="table table-striped table-bordered"><thead><tr><th style="width: 150px;">Name</th><th>Beschreibung</th><th>Typ</th></tr></thead><tbody><tr v-for="prop in props" :key="prop.name"><td>{{ prop.name }}</td><td>{{ prop.description }}</td><td>{{ prop.type }}</td></tr></tbody></table><h4>Options</h4><table class="table table-striped table-bordered"><thead><tr><th style="width: 150px;">Name</th><th>Beschreibung</th><th>Standard</th><th>Typ</th><th>Auswahlmöglichkeit</th></tr></thead><tbody><tr v-for="option in options" :key="option.name"><td>{{ option.name }}</td><td>{{ option.description }}</td><td>{{ option.default }}</td><td>{{ option.type }}</td><td><ul><li v-for="choice in option.choices" :key="choice">{{ choice }}</li></ul></td></tr></tbody></table></div>