import BelegButtonVersionsvergleichBetragsrechner
  from "./BelegButtonVersionsvergleichBetragsrechner/BelegButtonVersionsvergleichBetragsrechner.vue";
import BelegButtonVersionsvergleichExtraFieldTableRow
  from "./BelegButtonVersionsvergleichExtraFieldTableRow/BelegButtonVersionsvergleichExtraFieldTableRow.vue";
import BelegButtonVersionsvergleichStandardFieldTableRow
  from "./BelegButtonVersionsvergleichStandardFieldTableRow/BelegButtonVersionsvergleichStandardFieldTableRow.vue";
import BelegButtonVersionsvergleichTableRow
  from "./BelegButtonVersionsvergleichTableRow/BelegButtonVersionsvergleichTableRow.vue";
import FormElementReadOnly from "../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import KofiErfassungBelegFormMixin from "../../KF/KofiErfassung/KofiErfassungBelegForm/KofiErfassungBelegFormMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "BelegButtonVersionsvergleich",
  components: {
    BelegButtonVersionsvergleichBetragsrechner,
    BelegButtonVersionsvergleichExtraFieldTableRow,
    BelegButtonVersionsvergleichStandardFieldTableRow,
    BelegButtonVersionsvergleichTableRow,
    FormElementReadOnly,
    Modal,
    Permission,
    PuxButton,
    PuxTranslate,
    SnapshotIcon,
  },
  mixins: [
    KofiErfassungBelegFormMixin,
  ],
  data() {
    return {
      statusModal: undefined,
      argumentList: [],
      modelErfasst: {},
      modelAnerkannt: {},
      collection: {},
      loadingCollection: {},
    };
  },
  computed: {
    buttonOpenModalHtmlId() {
      return `${ this.htmlId }_button_versionsvergleich_openmodal`;
    },
  },
  methods: {
    openModal() {
      this.setModel();
      this.changeVorschau();
      this.loadNextBelegnummer();
      this.statusModal = true;
    },

    closeModal() {
      this.statusModal = false;
      this.modelErfasst = {};
      this.modelAnerkannt = {};
    },

    setModel() {
      const MODEL_ERFASST = cloneDeep(this.beleg);
      const ARGUMENT_ERFASST = cloneDeep(MODEL_ERFASST.argument_erfasst) || {};
      ARGUMENT_ERFASST.wert = this.beleg[this.optionsBelegBetragErfasst.id] || 0;
      MODEL_ERFASST.argument = ARGUMENT_ERFASST;
      MODEL_ERFASST.standardfelder = MODEL_ERFASST.standardfelder_erfasst;
      MODEL_ERFASST.extra = this.initModelExtraFields(MODEL_ERFASST.extra_input_erfasst);
      this.modelErfasst = MODEL_ERFASST;

      const MODEL_ANERKANNT = cloneDeep(this.beleg);
      const ARGUMENT_ANERKANNT = cloneDeep(MODEL_ANERKANNT.argument_anerkannt) || {};
      ARGUMENT_ANERKANNT.wert = this.beleg[this.keyBelegBetragAnerkannt] || 0;
      MODEL_ANERKANNT.argument = ARGUMENT_ANERKANNT;
      MODEL_ANERKANNT.standardfelder = MODEL_ANERKANNT.standardfelder_anerkannt;
      MODEL_ANERKANNT.extra = this.initModelExtraFields(MODEL_ANERKANNT.extra_input_anerkannt);
      this.modelAnerkannt = MODEL_ANERKANNT;
    },
  },
};
