// @vue/component
import AngularLink from "../../global/components/AngularLink/AngularLink.vue";
import FormElement from "../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../global/components/FormstepDetail/FormstepDetail.vue";

import PuxAlert from "../../global/components/PuxAlert/PuxAlert.vue";
import PuxGet from "../../global/components/PuxGet/PuxGet.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../global/mixins/HttpMixin";
import NotificationMixin from "../../global/mixins/NotificationMixin";

import {
  cloneDeep,
  filter,
  forEach,
  get,
  groupBy,
  isEmpty,
  map,
  orderBy,
  sortBy,
} from "lodash";

export default {
  name: "Signalverarbeitung",
  components: {
    AngularLink,
    FormElement,
    FormstepDetail,
    PuxAlert,
    PuxGet,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    obj: {
      type: Object,
      required: true,
    },
    objUrl: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      konfigurierteSignale: undefined,
      loadingEmpfaenger: false,
      loadingSender: false,
      loadingSignale: false,
      model: {},
      signalEmpfaenger: [],
      konfigurierbareSender: [],
    };
  },
  computed: {
    baseUrl() {
      return this.objUrl + "regelsignale/";
    },

    editableLocal() {
      return this.editable && this.hasSignalEmpfang;
    },

    signalKey() {
      return {
        far: "asv_id",
        sbr: "ssv_id",
      }[this.prefix];
    },

    empfaengerIDKey() {
      return "empfaenger_" + this.prefix + "_id";
    },

    empfaengerNummerKey() {
      return "empfaenger_" + this.prefix + "_nummer";
    },

    empfaengerKbezKey() {
      return "empfaenger_" + this.prefix + "_kbez";
    },

    empfaengerKbezAdminKey() {
      return "empfaenger_" + this.prefix + "_kbez_admin";
    },

    senderIDKey() {
      return "sender_" + this.prefix + "_id";
    },

    senderNummerKey() {
      return "sender_" + this.prefix + "_nummer";
    },

    senderKbezKey() {
      return "sender_" + this.prefix + "_kbez";
    },

    senderKbezAdminKey() {
      return "sender_" + this.prefix + "_kbez_admin";
    },

    formstepDetailOptions() {
      return {
        showCloseButton: true,
        label: "_LBL_SIGNALVERARBEITUNG_FORMSTEP_DETAIL_LABEL_",
        saveCallback: this.save,
      };
    },

    konfigurierbareEmpfaengerUrl() {
      return this.baseUrl + "konfigurierbare_empfaenger/";
    },

    konfigurierbareSenderUrl() {
      return this.baseUrl + "konfigurierbare_sender/";
    },

    setKonfigUrl() {
      return this.baseUrl + "set_konfig/";
    },

    loading() {
      return this.loadingSignale || this.loadingSender || this.loadingEmpfaenger;
    },

    hasSignalEmpfang() {
      return !isEmpty(this.filteredEmpfaenger);
    },

    filteredEmpfaenger() {
      return filter(this.signalEmpfaenger, "signal_relevant");
    },

    optionsList() {
      const OPTIONS_MAP = {};
      forEach(this.groupedSignalEmpfaenger, (mses, key) => {
        OPTIONS_MAP[key] = [];
        forEach(mses, mse => {
          mse.senderPKs = cloneDeep(mse.senderPKs || []);
          this.model[mse.repeaterTrackId] = cloneDeep(mse.senderPKs || []);
          OPTIONS_MAP[key].push({
            id: mse.repeaterTrackId,
            type: "multi_select",
            label: mse.mse_kbez,
            tooltip: mse.mse_bez,
            required: mse.mse_pflicht,
            disabled: isEmpty(this.possibleSender[mse.repeaterTrackId]),
            view: "v-alt",
            keyId: this.senderIDKey,
            keyLabelCallback: ({ item }) => `[${ item[this.senderNummerKey] }] ${ item[this.senderKbezAdminKey] }`,
            search: true,
            deselect: true,
            searchList: [this.senderNummerKey, this.senderKbezAdminKey],
            data: this.possibleSender[mse.repeaterTrackId] || [],
          });
        });
      });
      return OPTIONS_MAP;
    },

    orderedSignalEmpfaenger() {
      return orderBy(this.filteredEmpfaenger, this.empfaengerNummerKey);
    },

    orderedSignalEmpfaengerKeys() {
      return [...new Set(map(this.orderedSignalEmpfaenger, this.empfaengerIDKey))];
    },

    possibleSender() {
      const SENDER_MAP = {};
      forEach(this.filteredEmpfaenger, empfang => {
        SENDER_MAP[empfang.repeaterTrackId] = filter(this.konfigurierbareSender, sender => sender.signal === empfang.signal && empfang.empfaenger_re_id !== sender.sender_re_id);
      });
      return SENDER_MAP;
    },

    groupedSignalEmpfaenger() {
      return groupBy(this.filteredEmpfaenger, this.empfaengerIDKey);
    },
  },
  created() {
    this.onRegelUpdate();
  },
  methods: {
    getEmpfaengerTranslateExtra(regel) {
      return {
        nummer: get(regel, this.empfaengerNummerKey),
        kbez: get(regel, this.empfaengerKbezKey),
      };
    },

    getSenderTranslateExtra(sender) {
      return {
        nummer: sender.sender_nummer || sender[this.senderNummerKey],
        kbez: sender.sender_kbez || sender[this.senderKbezKey],
      };
    },

    onRegelUpdate() {
      if (!this.obj) {
        return;
      }
      this.reloadSignale();
    },

    toggleHelpBox(el) {
      el._showSignalBeschreibung = !el._showSignalBeschreibung;
    },

    loadKonfigurierbareSender() {
      this.loadingSender = true;
      this.konfigurierbareSender = {};
      this.getHttp({
        url: this.konfigurierbareSenderUrl,
      }).then(
        response => {
          response = sortBy(response, [this.senderNummerKey]);
          this.konfigurierbareSender = response;
          Promise.resolve();
        },
        errors => {
          this.addNotification({ text: "_MSG_SIGNALVERARBEITUNG_KONFIGURIERBARE_SENDER_FAILURE_", type: "error" });
          return Promise.reject(errors);
        },
      ).finally(() => this.loadingSender = false);
    },

    setTrackId(obj) {
      return obj.forEach(v => {
        v.repeaterTrackId = `${ v.mse_id }__${ v[this.empfaengerIDKey] }`;
        v.sender = orderBy((this.konfigurierteSignale[v[this.empfaengerIDKey]] || []).filter(sv => sv.signalempfang === v.mse_id), sv => sv.sender_nummer);
        v.senderPKs = v.sender.map(sv => sv.sender_regel);
      });
    },

    loadKonfigurierbareEmpfaenger() {
      this.loadingEmpfaenger = true;
      this.getHttp({
        url: this.konfigurierbareEmpfaengerUrl,
      }).then(
        response => {
          this.setTrackId(response);
          this.signalEmpfaenger = response;
          return Promise.resolve();
        },
        errors => {
          this.addNotification({ text: "_MSG_SIGNALVERARBEITUNG_KONFIGURIERBARE_EMPFAENGER_FAILURE_", type: "error" });
          return Promise.reject(errors);
        },
      ).finally(() => this.loadingEmpfaenger = false);
    },

    loadKonfigurierteSignale() {
      this.loadingSignale = true;
      this.getHttp({
        url: this.baseUrl,
      }).then(
        response => {
          this.konfigurierteSignale = {};
          response.results.forEach(sv => {
            if (this.konfigurierteSignale[sv.empfaenger_regel] === undefined) {
              this.konfigurierteSignale[sv.empfaenger_regel] = [];
            }
            this.konfigurierteSignale[sv.empfaenger_regel].push(sv);
          });
          return Promise.resolve();
        },
        errors => {
          this.addNotification({ text: "_MSG_SIGNALVERARBEITUNG_KONFIGURIERTE_SIGNALE_FAILURE_", type: "error" });
          return Promise.reject(errors);
        }
      ).finally(() => {
        this.loadingSignale = false;
        return this.loadKonfigurierbareEmpfaenger();
      });
    },

    reloadSignale() {
      return Promise.all([
        this.loadKonfigurierteSignale(),
        this.loadKonfigurierbareSender(),
      ]);
    },

    save() {
      const tmpModel = [];
      forEach(this.groupedSignalEmpfaenger, v => tmpModel.push(...v));
      const pushModel = tmpModel.map(signalkonfig => {
        const data = {
          signalempfang: signalkonfig.mse_id,
          empfaenger_regel: signalkonfig[this.empfaengerIDKey],
          sender_regeln: cloneDeep(this.model[signalkonfig.repeaterTrackId]),
          pk: signalkonfig[this.signalKey],
        };
        return data;
      });
      return this.postHttp({
        url: this.setKonfigUrl,
        data: pushModel,
      }).then(
        () => {
          this.reloadSignale();
          this.addNotification({ text: "_MSG_SIGNALVERARBEITUNG_SET_KONFIG_SUCCESS_" });
        },
        errors => {
          this.addNotification({ text: "_MSG_SIGNALVERARBEITUNG_SET_KONFIG_FAILURE_", type: "error" });
          this.errors = errors.data;
        },
      );
    }
  },
};
