import Alert from "../../../global/components/Alert/Alert.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "AufgabeModalAnnehmen",
  components: {
    Alert,
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    aufgabe: {
      type: Object,
      default: () => ({}),
    },
    sectorClose: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      options: {
        showCloseButton: true,
      },
      status: {
        loading: undefined,
      },
      alertOptions: {
        message: "_TXT_AUFGABE_MODAL_ANNEHMEN_ERROR_ALERT_TITLE_",
        type: "danger",
      },
      errors: undefined,
    };
  },
  methods: {
    save() {
      this.status.loading = true;
      this.postHttp({ url: `aktuelle_aufgaben/${ this.aufgabe.pk }/annehmen/`, showError: true }).then(
        () => {
          this.addNotification({ text: "_MSG_AUFGABE_MODAL_ANNEHMEN_SUCCESS_" });
          this.close({ status: true });
        },
        response => {
          if (response.status === 404) {
            this.errors = ["_MSG_AUFGABE_MODAL_ANNEHMEN_ERROR_NOT_BEARBEITER_"];
          } else if (get(response, "data")) {
            this.errors = get(response, "data");
          } else {
            this.errors = ["_MSG_AUFGABE_MODAL_ANNEHMEN_ERROR_"];
          }
        }
      ).finally(
        () => this.status.loading = false
      );
    },
  },
};
