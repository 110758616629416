import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import Ansprechpartner from "./Ansprechpartner/Ansprechpartner.vue";
import FormstepDetail from "../../../global/components/FormstepDetail/FormstepDetail.vue";
import Kommunikationsdaten from "./Kommunikationsdaten/Kommunikationsdaten.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

const EMAIL_TYP_ID = "27377530-c4b0-4697-866b-3e0dc314f5b7";
const URL_TYP_ID = "255ee9e6-e26d-7544-e053-0fd5a8c09460";

// @vue/component
export default {
  name: "FoerderorganisationKontaktBox",
  components: {
    AngularLink,
    Ansprechpartner,
    FormstepDetail,
    Kommunikationsdaten,
    PuxTranslate,
  },
  props: {
    organisation: {
      type: Object,
      required: true,
    },
    kontaktLabel: {
      type: String,
      required: true,
    },
    link: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      emailTypId: EMAIL_TYP_ID,
      urlTypId: URL_TYP_ID,
      options: {
      },
    };
  },
};
