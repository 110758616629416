import AntraegeModalChangeTeam from "../../../../../Antraege/AntraegeModalChangeTeam/AntraegeModalChangeTeam.vue";
import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragOverviewChangeTeamButtonModal",
  components: {
    AntraegeModalChangeTeam,
    PuxButton,
  },
  props: {
    vertrag: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "updateVertrag",
  ],
  data() {
    return {
      isModalChangeOpen: undefined,
    };
  },
  methods: {
    openTeamChangeModal() {
      this.isModalChangeOpen = true;
    },

    closeModalChangeTeam({ response } = {}) {
      if (response) {
        const VERTRAG = cloneDeep(this.vertrag);
        VERTRAG.team = response.team_obj;
        this.$emit("updateVertrag", {
          vertrag: VERTRAG,
        });
      }
      this.isModalChangeOpen = false;
    },
  },
};
