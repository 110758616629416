"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import "angular-chart.js";
import "angular-drag-and-drop-lists";

import diasAuthService from "dias/auth/services";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";

import sitzungsmodule from "./sitzungsmodule";

import models from "./models";
import services from "./services";
import parameter from "./parameter";
import mittel from "./mittel";
import antraege from "./antraege";
import ui from "./ui";
import verwaltung from "./verwaltung";
import detailsComponent from "./details.component";

import topDetailsComponent from "./top.details.component";


const sitzungenModule = angular.module("root.sitzungen", [
  uiRouter,
  "dndLists",
  "chart.js",
  models.name,
  services.name,
  parameter.name,
  mittel.name,
  antraege.name,
  ui.name,
  verwaltung.name,
  sitzungsmodule.name,
  diasAuthService.name,
  diasTable.name,
  diasLoading.name,
  diasNotification.name,
]);

sitzungenModule.config($stateProvider => {
  $stateProvider

    // Sitzungs-Liste
    .state("root.sitzungen", {
      url: `/sitzungen/?
            user_cookie&
            status&
            status_detail&
            workflowstatusgruppe&
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-sitzung-list></vue-sitzung-list></div>"
        }
      },
      data: {
        permissions: ["sitzungen.view"]
      },
    })
    .state("root.sitzungen1", {
      url: `/sitzungen1/?
            user_cookie&
            status&
            status_detail&
            workflowstatusgruppe&
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-sitzung-list></vue-sitzung-list></div>"
        }
      },
      data: {
        permissions: ["sitzungen.view"]
      },
    })

    // Detail-Seite einer Sitzung
    .state("root.sitzungen.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><sitzung-details></sitzung-details></div>"
        }
      },
      data: {
        permissions: ["sitzungen.view"]
      },
    })
    .state("root.sitzungen1.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<sitzungen-details></sitzungen-details>"
        }
      },
      data: {
        permissions: ["sitzungen.view"]
      },
    })

    .state("root.sitzungen.details.top", {
      url: "top/:tos_id/",
      views: {
        "main@root": {
          template: "<div vueinit><tagesordnungspunkt-details></tagesordnungspunkt-details></div>"
        }
      },
    })
    .state("root.sitzungen1.details.top", {
      url: "top/:tos_id/",
      views: {
        "main@root": {
          template: "<top-details></top-details>"
        }
      },
    })
  ;
})

  .component("sitzungenDetails", detailsComponent)
  .component("topDetails", topDetailsComponent)
;

export default sitzungenModule;
