"use strict";

import template from "./list.jade";
import searchController from "./search.controller";
import searchTemplate from "./search.jade";


class DeckblattListController {
  /*@ngInject*/
  constructor(
    $q, 
    $window, 
    AuthService, 
    Deckblatt, 
    Dokumentarten,
    Foerderantrag, 
    Antragstellerorganisationen,
    DiasNotification, 
    diasConfirmDialog,
    diasModalDialog, 
    BtypUUIDs
  ) {
    this.Dokumentarten = Dokumentarten;
    this.Deckblatt = Deckblatt;
    this.Foerderantrag = Foerderantrag;
    this.AuthService = AuthService;
    this.Antragstellerorganisationen = Antragstellerorganisationen;
    this.DiasNotification = DiasNotification;
    this.diasConfirmDialog = diasConfirmDialog;
    this.diasModalDialog = diasModalDialog;
    this.window = $window;
    this.BtypUUIDs = BtypUUIDs;
    this.dokklassen = [{ pk: this.BtypUUIDs.ANTRAG,
                         name: "Antrag" },
                       { pk: this.BtypUUIDs.ANTRAGSTELLER,
                         name: "Antragsteller" }];

    const deckblattTableDefer = $q.defer();
    this.deckblattTable = deckblattTableDefer.promise;

    const cols = [
      {
        label: "Aktennummer",
        field: "aktennummer",
        cellTemplate: `
          <spaced ng-if="row.btyp === 'Antrag'" ng-model="row.aktennummer"/>
          <span style="white-space:nowrap;" ng-if="row.btyp !== 'Antrag'">{{row.aktennummer}}</span>`
      }, {
        label: "Aktentyp",
        field: "btyp",
      }, {
        label: "Dokumententyp",
        field: "dtyp",
      }, {
        label: "Status",
        field: "status",
        cellTemplate: "{{ row.status.kds_kbez }}"
      },
    ];

    const deckblattSource = (params, headers) => {
      params = Object.assign({ druckbar: "True" }, params);
      return Deckblatt.getList(params, headers);
    };

    deckblattTableDefer.resolve({
      dataSource: deckblattSource,
      columnDefs: cols,
      tableTitle: "Vorbereitete Deckblätter",
      disabledDefaultRowAction: true,
      actions: [
        { label: "Deckblatt löschen",
          callback: this.delete_deckblatt.bind(this),
          isVisible: () => this.AuthService.syncHasPerm("deckblatt.delete")
        }
      ],
      tableActions: [{
        label: "Deckblätter drucken",
        icon: "glyphicon glyphicon-print", primary: true,
        callback: () => {
          this.print_deckblaetter({ druckbar: "True" });
        },
        isVisible: () => this.AuthService.syncHasPerm("deckblatt.print"),
      },
                     {
                       label: "Alle Deckblätter löschen",
                       icon: "glyphicon glyphicon-delete", primary: false,
                       callback: () => {
                         this.delete_deckblaetter();
                       },
                       isVisible: () => this.AuthService.syncHasPerm("deckblatt.delete")
                     },
                     {
                       label: "Gedruckte Deckblätter löschen",
                       icon: "glyphicon glyphicon-delete", primary: false,
                       callback: () => {
                         this.delete_deckblaetter(true);
                       },
                       isVisible: () => this.AuthService.syncHasPerm("deckblatt.delete")
                     }],
      isDirty: scope => {
        return this.deckblattLastChange > scope.vm.lastRefresh;
      }
    });

    this.dokumentarten = {};
    this.dokumentarten[this.BtypUUIDs.ANTRAG] = [];
    this.dokumentarten[this.BtypUUIDs.ANTRAGSTELLER] = [];
    this.load_dokumentarten();
    this.reset_form();
  }

  changeAktentyp() {
    this.selectedEl = null;
    this.deckblatt.aktennummer = null;
    this.update_objectinfo(true);
  }

  canCreateDeckblatt() {
    return (this.objectinfo && this.deckblatt.dokart &&
            this.deckblatt.dokart.length > 0 && this.deckblatt.btyp);
  }

  showAstSwitch() {
    return (
      this.deckblatt.btyp === this.BtypUUIDs.ANTRAG &&
      this.selectedEl && this.selectedEl.antragstellerorg_obj);
  }

  switchToAst() {
    const aktennummer = this.selectedEl.antragstellerorg_obj.ast_dmsakte;
    this.searching = true;
    this.Antragstellerorganisationen.one("suche_alle").customGET("", { ast_dmsakte_exact: aktennummer }).then(
      response => {
        this.selectedEl = response.results[0];
        this.deckblatt.aktennummer = aktennummer;
        this.deckblatt.btyp = this.BtypUUIDs.ANTRAGSTELLER;
        this.update_objectinfo(true);
      }
    ).finally(() => this.searching = false);
  }

  delete_deckblatt(row) {
    return this.diasConfirmDialog({
      titleTemplate: `Deckblatt löschen?`,
      content: `Sind Sie sicher, dass Sie das Deckblatt löschen wollen?`,
      okLabel: "Löschen",
      okCallback: () => this.Deckblatt.one(row.pk).remove().then(() => {
        this.DiasNotification.page.success("Deckblatt wurde gelöscht.");
        this.reloadPage();
      })
    });
  }

  delete_deckblaetter(gedruckte) {
    let titleText = "Alle Deckblätter löschen?";
    if (gedruckte) {
      titleText = "Gedruckte Deckblätter löschen?";
    }
    return this.diasConfirmDialog({
      titleTemplate: titleText,
      contentTemplate: `<p>Deckblätter werden gelöscht.</p>`,
      context: { gedruckte: gedruckte },
      okLabel: "Löschen",
      okCallback: () => this.Deckblatt.deleteBatch(gedruckte).then(() => {
        this.DiasNotification.page.success("Deckblätter wurden gelöscht.");
        this.reloadPage();
      })
    });
  }

  reloadPage() {
    this.deckblattLastChange = new Date().getTime();
  }

  load_dokumentarten() {
    this.Dokumentarten.searchAntragTyp(
      this.BtypUUIDs.ANTRAG
    ).then(
      response => {
        this.dokumentarten[this.BtypUUIDs.ANTRAG] = response;
      }
    );
    this.Dokumentarten.searchAntragTyp(
      this.BtypUUIDs.ANTRAGSTELLER
    ).then(
      response => {
        this.dokumentarten[this.BtypUUIDs.ANTRAGSTELLER] = response;
      }
    );
  }

  update_objectinfo(aktentypChangeStatus) {
    if (aktentypChangeStatus) {
      this.deckblatt.dokart = [];
    }
    this.validateAktennummer();


    this.objectinfo = null;
    if (this.selectedEl) {
      this.objectinfo = {};
      if (this.deckblatt.btyp === this.BtypUUIDs.ANTRAG) {
        this.objectinfo.a_nr = this.selectedEl.a_nr;
        this.objectinfo.angebot = this.selectedEl.regelsatz_kbez;
        this.objectinfo.a_titel = this.selectedEl.a_titel;
        this.objectinfo.ast_name = "-";
        this.objectinfo.ast_dmsakte = "-";
        if (this.selectedEl.antragstellerorg_obj) {
          this.objectinfo.ast_name = this.selectedEl.antragstellerorg_obj.ast_name;
          this.objectinfo.ast_dmsakte = this.selectedEl.antragstellerorg_obj.ast_dmsakte;
        }
      }
      if (this.deckblatt.btyp === this.BtypUUIDs.ANTRAGSTELLER) {
        this.objectinfo.a_nr = "-";
        this.objectinfo.angebot = "-";
        this.objectinfo.a_titel = "-";
        this.objectinfo.ast_name = this.selectedEl.ast_name;
        this.objectinfo.ast_dmsakte = this.selectedEl.ast_dmsakte;
      }
    }
  }

  create_deckblaetter() {
    this.Deckblatt.createBatch(this.deckblatt).then(() => {
      this.reloadPage();
    });
  }

  reset_form() {
    // Default: Antrag:
    this.deckblatt = { btyp: this.BtypUUIDs.ANTRAG };
    this.update_objectinfo();
  }

  print_deckblaetter(params) {
    this.Deckblatt.print(params).then(response => {
      const fileName = "deckblaetter.pdf";
      const pdffile = response;
      if (this.window.navigator.msSaveBlob) {
        this.window.navigator.msSaveBlob(pdffile, fileName);
      } else {
        const fileURL = (this.window.URL || this.window.webkitURL).createObjectURL(pdffile);
        const aLink = this.window.document.createElement("a");
        aLink.download = fileName;
        aLink.href = fileURL;
        aLink.target = "_self";
        const eventClick = this.window.document.createEvent("MouseEvents");
        eventClick.initEvent(
          "click", 
          true, 
          true, 
          this.window,
          0, 
          0, 
          0, 
          0, 
          0, 
          false, 
          false, 
          false, 
          false, 
          0, 
          null
        );
        aLink.dispatchEvent(eventClick);
      }
      this.reloadPage();
      this.reset_form();
    });
  }

  showSearch() {
    const extraData = { deckblatt: this.deckblatt,
                        selectedEl: null };
    this.diasModalDialog({
      title: "Akte suchen",
      template: searchTemplate,
      controller: searchController,
      extras: extraData
    }).then(
      () => {
        this.selectedEl = extraData.selectedEl;
        this.update_objectinfo();
      }
    );
  }

  validateAktennummer() {
    if (this.deckblatt && this.deckblatt.aktennummer) {
      this.deckblatt.aktennummer = this.deckblatt.aktennummer.replace(/\s+/g, "");
    }
  }
}

export default {
  template: template(),
  controller: DeckblattListController,
  controllerAs: "vm"
};
