"use strict";

import BaseModel from "dias/core/models/baseModel";
import Ansprechpartner from "./ansprechpartner.service";

class Foerderorganisation extends BaseModel {
  constructor() {
    super("foerderorganisationen", {
      subPaths: {
        ansprechpartner: new Ansprechpartner(),
        nutzer: "nutzer",
        untergeordnete_orgs: "untergeordnete_orgs",
      }
    });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    window.RT = this.Restangular;
    collection.search = params => this.Restangular.all("info/foerderorganisationen").getList(params).then(
      response => this.Restangular.restangularizeCollection(undefined, response, this.path)
    );
    return collection;
  }
}

export default Foerderorganisation;
