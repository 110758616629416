"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import diasAuthService from "dias/auth/services";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";

import models from "./models";


const mittelModule = angular.module("root.sitzungen.mittel", [
  uiRouter,
  models.name,
  diasAuthService.name,
  diasTable.name,
  diasLoading.name,
  diasNotification.name
]);

mittelModule.config($stateProvider => {
  $stateProvider

    // Sitzungs-Liste
    .state("root.mittel", {
      url: "/foerdermittel/",
      views: {
        "main@root": {
          template: "<div vueinit><vue-foerdermittel-list></vue-foerdermittel-list></div>"
        }
      },
      data: {
        permissions: ["mittel.view"]
      },
    })
  ;
})
;

export default mittelModule;
