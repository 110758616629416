import HttpMixin from "../../../../../global/mixins/HttpMixin";
import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import {
  cloneDeep,
  filter,
  get,
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "UiElementliste",
  components: {
    PuxButton,
  },
  mixins: [
    HttpMixin,
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modelLocal: {},
      loading: true,
      dataForFeldDefault: [],
      dataForFeld: [],
      regelnByPK: {},
      confirmShow: undefined,
      confirmOptions: undefined,
    };
  },
  computed: {
    isDisabledButton() {
      return !this.modelLocal.id;
    },
    getOptions() {
      return {
        type: "select",
        id: "element",
        label: "Element auswählen",
        required: false,
        view: "v",
        keyId: "id",
        keyLabel: "bez",
        search: true,
        searchList: ["bez"],
        deselect: false,
        data: this.dataForFeld,
        loading: this.loading,
      };
    },
  },
  beforeCreate() {
    this.$options.components.FormElement = require("../../../../../global/components/FormElement/FormElement.vue").default;
    this.$options.components.Modal = require("../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.setDefaultModelLocal();
    this.loadData();
  },
  methods: {
    loadData() {
      this.getListHttp({
        url: `katalog/`,
        urlParams: {
          fields: ["pk", "re_nummer", "re_kbez", "bez"],
          key: "elementfoerderregel",
        },
      }).then(
        response => {
          this.regelnByPK = keyBy(response, "pk");
          this.dataForFeldDefault = response;
          this.filterDataForFeld();
          this.loading = false;
        }
      );
    },

    filterDataForFeld() {
      this.dataForFeld = filter(this.dataForFeldDefault, item => {
        return this.model.indexOf(item.pk) === -1 && item.pk !== get(this, "$stateParams.id");
      });
    },

    setDefaultModelLocal() {
      this.modelLocal = {
        element: null,
      };
    },

    addFeld() {
      if (!this.modelLocal.element) {
        return;
      }
      const MODEL = cloneDeep(this.model) || [];
      MODEL.push(this.modelLocal.element);
      this.onInput_mixin({ value: MODEL });
      this.setDefaultModelLocal();
      setTimeout(() => {
        this.filterDataForFeld();
      });
    },

    moveUpFeld(index) {
      if (index === 0) {
        return;
      }
      const MODEL = cloneDeep(this.model);

      const ELEM = MODEL[index];
      MODEL.splice(index, 1);
      MODEL.splice(index - 1, 0, ELEM);
      this.onInput_mixin({ value: MODEL });
    },

    moveDownFeld(index) {
      if (index >= this.model.length - 1) {
        return;
      }
      const MODEL = cloneDeep(this.model);
      const ELEM = MODEL[index];
      MODEL.splice(index, 1);
      MODEL.splice(index + 1, 0, ELEM);
      this.onInput_mixin({ value: MODEL });
    },

    openConfirmDelete(index) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "Element entfernen",
        msg: `<p>Sind Sie sicher, dass sie das ${ index + 1 }. Element von dieser Regel entfernen wollen?</p>`,
        okLabel: "Fortfahren",
        okCallback: () => this.deleteFeld(index),
        cancelCallback: this.closeConfirmDelete,
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteFeld(index) {
      const MODEL = cloneDeep(this.model);
      MODEL.splice(index, 1);
      this.onInput_mixin({ value: MODEL });
      this.closeConfirmDelete();
      setTimeout(() => {
        this.filterDataForFeld();
      });
    },

    closeConfirmDelete() {
      this.confirmShow = false;
    },
  },
};
