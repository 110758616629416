"use strict";

import BaseModel from "dias/core/models/baseModel";
import Foerdermodule from "dias/foerderantrag/foerdermodule/models/foerdermodule.service";
import Dokumente from "dias/dokumente/models/dokumente.service";
import Preview from "dias/dokumente/models/preview.service";
import Objektaufgabe from "dias/workflow/models/objektaufgabe.service";
import Foerderantragbenachrichtigung from "./foerderantragbenachrichtigung.service";
import Foerderantragorganisation from "./foerderantragorganisation.service";

class FoerderantragBase extends BaseModel {
  getPath() {
    return "foerderantraege";
  }

  constructor(path) {
    super(path, {
      subPaths: {
        module: new Foerdermodule(),
        dokumente: new Dokumente(),
        preview: new Preview(),
        aufgaben: new Objektaufgabe(),
        benachrichtigungen: new Foerderantragbenachrichtigung(),
        organisationen: new Foerderantragorganisation(),
        snapshots: "snapshots"
      }
    });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.getTimeline = a_id => collection.one(a_id).all(this.subPaths.timeline.path).getList();
    collection.getKAufgaben = () => collection.customGET("kaufgaben");
    collection.getRegelsaetze = () => collection.customGET("regelsaetze");
    return collection;
  }

  extendModel(model) {
    model = super.extendModel(model);

    if (!angular.isObject(model)) {
      return model;
    }

    model.restangularizeAufgabe = aufgabe => this.Restangular.restangularizeElement(model, aufgabe, "aufgaben", {});

    if (model.aktuelle_aufgabe) {
      model.aktuelle_aufgabe = model.restangularizeAufgabe(model.aktuelle_aufgabe);
    }

    model.getPreview = () => model.customGET("vorschau");

    model.isEntschieden = () => {
      if (model.a_bewilligungsdatum || model.a_ablehnungsdatum) {
        return true;
      } 
      return false;
    };

    model.submit = () => {
      return model.customPOST({}, "einreichen");
    };

    return model;
  }
}

export default FoerderantragBase;
