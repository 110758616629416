"use strict";

class DynamicElementComponent {
  /*@ngInject*/
  constructor($q, $element, $scope, $compile) {
    this.$compile = $compile;
    this.$element = $element;
    this.$scope = $scope;
    this.create();
  }

  create() {
    const foo = this.buildTemplate();
    if (foo === undefined) {
      return;
    }
    const el = this.$compile(foo)(this.$scope.$parent);
    this.$element.append(el);
  }

  buildTemplate() {
    if (!this.name || this.name.length === 0) {
      return;
    }
    let paramsStr = "";
    const params = this.params || {};
    Object.keys(params).forEach(k => {
      paramsStr = `${ paramsStr } ${ k }="${ params[k] }"`;
    });
    return `<${ this.name }${ paramsStr }></${ this.name }>`;
  }
}

export default {
  bindings: {
    name: "<",
    params: "<?",
  },
  template: "",
  controller: DynamicElementComponent,
};
