"use strict";

import DefaultEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";

class GutachtenCreateController extends DefaultEditController {
  /*@ngInject*/
  constructor($q, $scope, Gutachten, ParameterUtils, DiasNotification, i18n) {
    super($q, $scope, DiasNotification);
    this.ParameterUtils = ParameterUtils;
    this.Gutachten = Gutachten;
    this.gettext = i18n.gettext;
    this.initForm();
  }

  onEnterEditMode() {
    super.onEnterEditMode();
    this.updateFormModel();
  }

  _save() {
    const data = this.ParameterUtils.to_request_body(this.formModel, null, "");
    Object.assign(this.model, data);
    const promise = this.Gutachten.one(this.model.pk).customPUT(data).then(
      response => {
        this.$scope.model.merge(this.$scope.model, response.plain());
        this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
      },
      errors => {
        return this.onErrors(errors, this.errorLabels);
      }
    );
    return promise;
  }

  updateFormModel() {
    if (this.ParameterUtils && this.formModelTemplate && this.model) {
      this.formModel = this.ParameterUtils.params_from_template(this.formModelTemplate, this.model);
    }
  }

  initForm() {
    this.formModelTemplate = this.ParameterUtils.init_parameter_in({
      ga_kbez: {
        bez: this.gettext("Bezeichnung"),
        typ: "string",
        prio: 1,
        options: {
          required: true
        }
      },
      ga_bez: {
        bez: this.gettext("Anmerkung und Hinweise"),
        typ: "richtext",
        prio: 2,
        options: {
          required: false
        }
      },
      ga_termin: {
        bez: this.gettext("Zieltermin"),
        typ: "date",
        prio: 3,
        options: {
          required: true
        }
      },
      nutzer: {
        bez: this.gettext("GutachterIn"),
        typ: "single_choice",
        prio: 4,
        katalog: "gutachter",
        options: {
          required: true,
        }
      },
      pruefer: {
        bez: this.gettext("PrüferIn"),
        typ: "single_choice",
        prio: 4,
        katalog: "gutachten_pruefer",
        options: {
          required: false,
        }
      },
      ersteller: {
        bez: this.gettext("Angefordert von"),
        typ: "single_choice",
        prio: 4,
        katalog: "gutachten_ersteller",
        readonly: true,
        options: {
          required: false,
        }
      },
      ga_objektname: {
        bez: this.gettext("Gutachtenobjekt"),
        typ: "string",
        prio: 5,
        readonly: true,
        options: {
          required: false,
        }
      },
      vorlage: {
        bez: this.gettext("Vorlage"),
        typ: "single_choice",
        prio: 6,
        katalog: "gutachtenvorlagen",
        readonly: true,
        options: {
          required: false,
        }
      },
    });
    this.form_keys = this.ParameterUtils.get_ordered_keys(this.formModelTemplate);
    this.errorLabels = {};
    this.form_keys.forEach(
      k => {
        this.errorLabels[k] = `${ this.formModelTemplate[k].bez }: `;
      }
    );
    this.updateFormModel();
  }
}

export default GutachtenCreateController;
