"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";
import ngAnimate from "angular-animate";

const geloeschteAntraegeModule = angular.module("geloeschte_antraege", [
  ngAnimate,
  uiRouter,
  "angularMoment",
]);

geloeschteAntraegeModule.config($stateProvider => {
  $stateProvider
    // Liste
    .state("root.geloeschte_antraege", {
      url: "/geloeschte_antraege/",
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-antraege-geloeschte-list></vue-antraege-geloeschte-list></div>"
        }
      },
      data: {
        permissions: ["geloeschte_antraege.view"]
      }
    });
});
export default geloeschteAntraegeModule;
