import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import VueFoerderfinderIcons from "../../../../Foerderfinder/FoerderfinderIcons/FoerderfinderIcons.vue";

// @vue/component
export default {
  name: "Foerderangebote",
  components: {
    AngularLink,
    FormstepDetail,
    PuxTranslate,
    VueFoerderfinderIcons,
  },
  props: {
    organisation: {
      type: Object,
      required: true,
    },
    kategoriewerte: {
      type: Object,
      required: true,
    },
    categoriesAll: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      options: {
        hideHeader: true,
      },
    };
  },
};
