import BetragsrechnerMixin from "../Mixins/BetragsrechnerMixin";
import FinanzierungDetailliertMixin from "../Mixins/FinanzierungDetailliertMixin";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "FinanzierungDetailliertNationalBetragsrechner",
  mixins: [
    BetragsrechnerMixin,
    FinanzierungDetailliertMixin,
  ],
  emits: [
    "changeModel",
  ],
  computed: {
    optionsKategorieLocal() {
      const OPTIONS = cloneDeep(this.optionsKategorie);
      OPTIONS.autoFocus = this.autoFocus;
      return OPTIONS;
    },
  },
  created() {
    this.initErrorLabels();
    this.setLandForModel();
  },
  methods: {
    setLandForModel() {
      const MODEL = cloneDeep(this.model);
      MODEL.argument.land = "2545f35d-754b-1e33-e050-007f010106dc";
      this.$emit("changeModel", { model: MODEL });
    },
  },
};
