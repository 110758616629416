"use strict";

import BeschreibungController from "./beschreibung.edit.controller";
import BeschreibungTemplate from "./beschreibung.edit.jade";

import template from "./detail.jade";

class DetailController {
  /*@ngInject*/
  constructor($rootScope, $state, $stateParams, AuthService, Workflowfolge, Workflowfolgeclientfunktion, DiasNotification, i18n, ParameterUtils) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.ParameterUtils = ParameterUtils;
    this.Workflowfolgeclientfunktion = Workflowfolgeclientfunktion;
    this.Workflowfolge = Workflowfolge;
    this.AuthService = AuthService;
    this.DiasNotification = DiasNotification;
    this.gettext = i18n.gettext;

    this.editDescriptionController = BeschreibungController;
    this.editDescriptionTemplate = BeschreibungTemplate;

    this.loading = {
      fkt: true,
      wfo: true,
    };
    this.params = {};
    this.infoTexts = {
      bez: this.gettext("Beschreibender Name dieser konkreten Konfiguration der Clientfunktion."),
      modul: this.gettext("Das dieser Clientfunktion zugrunde liegende Modul. Bestimmt grundsätzliche Funktionalität und benötigte Parameter-Konfiguration."),
      parameterin: this.gettext("Konfigurierte oder von einer vorhergegangenen Client-/Clientfunktion bereitgestellte Parameter zur Steuerung der Ausführung dieser Clientfunktion."),
      parameterout: this.gettext("Aus der Ausführung dieser Funktion resultierende Daten, die folgenden Serverfunktionen in der Ausführung einer Workflowfolge bereitgestellt werden."),
    };
    this.perms = AuthService.syncHasPerms([
      "clientfunktion.update", "clientfunktion.delete", "workflowfolge.ui"
    ]);
    this.updateParams = this.updateParams.bind(this);
    this.init();
  }

  remove() {
    this.loading.delete = true;
    this.fkt.remove().then(
      () => {
        this.DiasNotification.page.success("Clientfunktion erfolgreich gelöscht");
        this.$state.go("root.workflowfolgeclientfunktionen");
      },
      err => this.DiasNotification.page.error(`${ err.data }`, "Fehler beim Löschen der Clientfunktion")
    ).finally(() => this.loading.delete = false);
  }

  init() {
    this.Workflowfolgeclientfunktion.one(this.$stateParams.id).get().then(
      result => {
        this.fkt = result;
        this.updateParams();
      }
    ).then(
      () => this.loadWorkflowfolgen()
    ).finally(() => {
      this.loading.fkt = false;
    });
  }

  updateParams() {
    if (this.fkt.wfc_parameterin) {
      this.params.in = {
        keys: this.ParameterUtils.get_ordered_keys(this.fkt.wfc_parameterin),
        config: this.ParameterUtils.init_parameter_in(this.fkt.wfc_parameterin)
      };
    } else {
      this.params.in = { keys: [], config: {} };
    }
    if (this.fkt.wfc_parameterout) {
      this.params.out = {
        keys: this.ParameterUtils.get_ordered_keys(this.fkt.wfc_parameterout),
        config: this.ParameterUtils.init_parameter_in(this.fkt.wfc_parameterout)
      };
    } else {
      this.params.out = { keys: [], config: {} };
    }
  }

  loadWorkflowfolgen() {
    this.Workflowfolge.getList({ clientfunktion: this.$stateParams.id }).then(
      response => {
        this.fkt.workflowfolgen_objs = response;
      }
    ).finally(
      () => this.loading.wfo = false
    );
  }
}

export default {
  template: template(),
  controller: DetailController,
  controllerAs: "vm",
};
