"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";
import foerderorganisationKennenlernenComponent from "./foerderorganisation.kennenlernen.component";

const modul = angular.module("dias.foerderorganisation_kennenlernen", [
  uiRouter,
]);

modul

  .component("foerderorganisationKennenlernen", foerderorganisationKennenlernenComponent)
;

export default modul;
