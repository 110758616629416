"use strict";


import template from "./requirement.jade";

const diasRequirement = angular.module("dias.requirement", [])

  .component("diasRequirement", { template: template() });

export default diasRequirement;
