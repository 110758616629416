"use strict";

import angular from "angular";

import uiRouter from "angular-ui-router";
import ngFileUpload from "ng-file-upload";

import coreModal from "dias/core/modal";
import coreTable from "dias/core/table";
import coreLoading from "dias/core/loading";
import coreModels from "dias/core/models";
import coreExtendable from "dias/core/extendable";
import coreNotification from "dias/core/notification";
import coreStatusfolgen from "dias/core/statusfolgen";
import Dokumente from "dias/dokumente";

import konto from "./konto";
import models from "./models";
import services from "./services";
import pipes from "./pipes";
import traegerwechsel from "./traegerwechsel";
import parameters from "./parameters";
import BudgetModels from "dias/budget/models";
import astOrgDetailComponent from "./detail.component";
import foerderOrgDetailComponent from "./foerderorg.detail.component";
import foerderOrgDetailAnsprechpartnerComponent from "./foerderorg.detail.ansprechpartner.component";
import foerderorgviewComponent from "./foerderorg.element.view.component";
import foerderorgselectComponent from "./foerderorg.element.select.component";

const astorganisationenModule = angular.module("astorganisationen", [
  uiRouter,
  ngFileUpload,
  coreModal.name,
  coreTable.name,
  coreLoading.name,
  coreModels.name,
  coreExtendable.name,
  coreNotification.name,
  coreStatusfolgen.name,
  Dokumente.name,
  parameters.name,
  models.name,
  pipes.name,
  services.name,
  traegerwechsel.name,
  konto.name,
  BudgetModels.name
]);

astorganisationenModule.config($stateProvider => {
  $stateProvider

    .state("root.astorganisationen", {
      url: `/astorganisationen/?
            user_cookie&
            rechtsform&
            status&
            status_detail&
            workflowstatusgruppe&
            show_dubletten&
            show_deaktiviert&
            zu_bearbeiten&
            friststatus
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: `<div vueinit=""><vue-antragsteller-list></vue-antragsteller-list></div>`,
        }
      },
      data: {
        permissions: "antragstellerorg.view"
      }
    })

    .state("root.astorganisationen.details", {
      url: ":id/?asr_nummer",
      views: {
        "main@root": {
          template: "<astorg-detail></astorg-detail>"
        }
      },
    })

    .state("root.foerderorganisationen", {
      url: "/foerderorganisationen/",
      views: {
        "main@root": {
          template: "<div vueinit><vue-foerderorganisation-bearbeiten-list></vue-foerderorganisation-bearbeiten-list></div>"
        },
      },
      data: {
	      permissions: "foerderorg.view"
      }
    })

    .state("root.foerderorganisationen.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<foerderorg-detail></foerderorg-detail>"
        }
      },
      data: {
	      permissions: "foerderorg.view"
      }
    })
    .state("root.foerderorganisationen.details.ansprechpartner", {
      url: "ansprechpartner/:ap_id/",
      views: {
        "main@root": {
          template: "<foerderorg-detail-ansprechpartner></foerderorg-detail-ansprechpartner>"
        }
      },
      data: {
        permissions: "foerderorg.view"
      }
    })
  ;
})

  .component("astorgDetail", astOrgDetailComponent)
  .component("foerderorgDetail", foerderOrgDetailComponent)
  .component("foerderorgDetailAnsprechpartner", foerderOrgDetailAnsprechpartnerComponent)
  .component("foerderorgselect", foerderorgselectComponent)
  .component("foerderorgview", foerderorgviewComponent)

;

export default astorganisationenModule;
