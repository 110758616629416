import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import KofiErfassungBelegForm from "../KofiErfassungBelegForm/KofiErfassungBelegForm.vue";
import KofiErfassungPositionForm from "../KofiErfassungPositionForm/KofiErfassungPositionForm.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotItem from "../../../Snapshot/SnapshotItem/SnapshotItem.vue";

import translate from "../../../../global/directives/translate";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import KofiErfassungMixin from "../Mixins/KofiErfassungMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";
import {
  FilterCurrencyMixin,
  FilterLimitToMixin,
} from "../../../../global/mixins/FiltersMixin";

import KVertragskostenbelegStatusUUIDs from "../../../../const/KVertragskostenbelegStatusUUIDs";
import KAuszahlungBelegAnzeigeModus from "../../../../const/KAuszahlungBelegAnzeigeModus";

import {
  cloneDeep,
  forEach,
  get,
  isArray,
  isEmpty,
  isFunction,
  isPlainObject,
  isUndefined,
  sortBy,
  values,
} from "lodash-es";

export const ShowFormatName = {
  methods: {
    showFormatName(kbez, bez) {
      let name;
      if (kbez) {
        name = kbez + ": " + bez;
      } else {
        name = bez;
      }
      if (this.isPositionsbezeichnungAbgeschnitten) {
        name = this.filterLimitTo(name, 50);
      }
      return name;
    },
  },
};

// @vue/component
export default {
  components: {
    AngularLink,
    KofiErfassungBelegForm,
    KofiErfassungPositionForm,
    Modal,
    Permission,
    PuxButton,
    PuxIcon,
    PuxTranslate,
    SnapshotIcon,
    SnapshotItem,
  },
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterLimitToMixin,
    HttpMixin,
    KofiErfassungMixin,
    PermissionMixin,
    ShowFormatName,
    SyConfigMixin,
  ],
  props: {
    position: {
      type: Object,
      required: true,
      info: "Hauptinformation",
    },
    positionIndex: {
      type: Number,
      required: true,
      info: "Index in Array-Positionen",
    },
    parentNumber: {
      type: String,
      required: true,
      info: "Applikationskontextnummer.Modulnummer",
    },
    prefixCurrent: {
      type: Object,
      required: true,
      info: "Information vom Kofiart(kosten oder Finanzierung)",
    },
    auszahlung: {
      type: Object,
      required: true,
      info: "Auszahlung",
    },
    auszahlungsplan: {
      type: Object,
      required: false,
      default: undefined,
      info: "Auszahlung",
    },
    vertrag: {
      type: Object,
      required: true,
      info: "Vertrag",
    },
    htmlId: {
      type: String,
      required: true,
      info: "modul.regel_nummer",
    },
    urlSave: {
      type: String,
      required: true,
      info: "URL für speichern",
    },
    extraTranslate: {
      type: Object,
      required: true,
      info: "Die zusätzliche Information für Übersetzung",
    },
    katalogDetails: {
      type: Array,
      required: true,
      info: "Die Liste von 'Typ / Kategorie'",
    },
    relevanteAuszahlungen: {
      type: Array,
      required: true,
      info: "Alle vorhergehenden Auszahlungen (und die eigene)",
    },
    apiAntrag: {
      type: String,
      required: true,
      info: "API foerderantraege/{{ AntragPk }}/",
    },
    belegDokumente: {
      type: Array,
      required: true,
      info: "Belegdokumente",
    },
    belegDokumenteAnzahl: {
      type: Object,
      required: false,
      info: "Mapping dok_id zur Anzahl der Belege, in der dieses Dokument benutzt wird",
      default: () => ({}),
    },
    apiAntragDokumente: {
      type: String,
      required: true,
      info: "API foerderantraege/{{ AntragPk }}/dokumente/?dokart={{ dokart }}",
    },
    extraKF: {
      type: Object,
      required: true,
      info: "{ regel, regeldata, modelParameter }",
    },
    positionen: {
      type: Array,
      required: true,
      info: "Alle Positionen",
    },
    relevantePerioden: {
      type: Array,
      required: false,
      default: undefined,
      info: "Alle für die Auszahlung relevanten Positionen",
    },
    contextNumber: {
      type: Number,
      required: true,
      info: "Applikationskontextnummer",
    },
    statusHideNumber: {
      type: Boolean,
      required: true,
      info: "Positionsnummer ausblenden",
    },
    statusSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    statusSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    diffKofi: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    kofiPk: {
      type: String,
      required: false,
      default: undefined,
    },
    snapshot: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      statusShowPositionDetail: false,
      statusAddBeleg: false,
      belegnummerAnzeigen: true,
      syConfigsAuszahlung: {},
      isPositionsbezeichnungAbgeschnitten: true,
      selectorClose: undefined,
      confirmOptions: undefined,
      confirmShow: false,
      statusEditPosition: false,
    };
  },
  computed: {
    htmlIdLocal() {
      return `${ this.htmlId }_${ this.positionIndex }`;
    },

    positionNumber() {
      return `${ this.parentNumber }.${ this.position[this.keyPositionPos] }`;
    },

    keyArtDetailObj() {
      return `${ this.prefixCurrent.art }detail_obj`;
    },

    keyArtdetailKbez() {
      return `${ this.prefixCurrent.artdetail }_kbez`;
    },

    keyPositionBez() {
      return `${ this.prefixCurrent.position }_bez`;
    },

    keyPositionBetragBewilligt() {
      return `${ this.prefixCurrent.position }_betrag_bewilligt`;
    },

    keyPositionBetragErfasst() {
      return `${ this.prefixCurrent.position }_betrag_erfasst`;
    },

    keyPositionBetragAnerkannt() {
      return `${ this.prefixCurrent.position }_betrag_anerkannt`;
    },

    keyPositionPos() {
      return `${ this.prefixCurrent.position }_pos`;
    },

    keyBelegPos() {
      return `${ this.prefixCurrent.beleg }_pos`;
    },

    keyAntragArtInfo() {
      return `antrag${ this.prefixCurrent.art }info`;
    },

    kofiArtPK() {
      return get(this.extraKFPosition, `modelParameter.${ this.prefixCurrent.art }`);
    },

    positionTitle() {
      return `${ this.positionArtdetailKbez ? `${ this.positionArtdetailKbez }: ` : "" }${ this.position[this.keyPositionBez] }`;
    },

    positionArtdetailKbez() {
      return this.position[this.keyArtDetailObj] ?
        this.position[this.keyArtDetailObj][this.keyArtdetailKbez] :
        "";
    },

    positionName() {
      return this.showFormatName(this.positionArtdetailKbez, this.position[this.keyPositionBez]);
    },

    automatischBelegen() {
      return get(this.extraKF, "modelParameter.automatisch_belegen", false);
    },

    positionBetragBewilligtCurrency() {
      return this.filterCurrency(this.position[this.keyPositionBetragBewilligt]);
    },

    positionBetragErfasstFilterView() {
      return this.filterView(this.position.erfasst, this.position[this.keyPositionBetragErfasst], this.auszahlung);
    },

    positionBetragAnerkanntFilterView() {
      return this.filterView(this.position.anerkannt, this.position[this.keyPositionBetragAnerkannt], this.auszahlung);
    },

    buttonTogglePositionDetailId() {
      return `${ this.htmlIdLocal }_toggle`;
    },

    ariaDescribedbyForButtons() {
      // TODO: Barrierefreiheit
      return "";
    },

    buttonTogglePositionDetailIcon() {
      return this.statusShowPositionDetail ? "chevron-up" : "chevron-down";
    },

    urlSaveLocal() {
      return `${ this.urlSave }${ this.position.pk }/`;
    },

    posBelegeFilter() {
      const FILTER = {};
      if (this.vertrag) {
        FILTER.vertrag = this.vertrag.pk;
      }
      if (this.kofiArtPK) {
        FILTER[this.prefixCurrent.art] = this.kofiArtPK;
      }
      if (get(this.position, this.keyPositionPos)) {
        FILTER.kofipos_pos = this.position[this.keyPositionPos];
      }
      return FILTER;
    },

    apiBelege() {
      return `${ this.urlSaveLocal }belege/`;
    },

    statusHasPermissionVertragskostenpositionsbelegeAnerkennen() {
      return this.checkPermissionsSync({ perm: "vertragskostenpositionsbelege.anerkennen", permArray: this.auszahlung.user_permissions });
    },

    belegeSorted() {
      return sortBy(this.belegeLocal || [], [this.keyBelegPos]);
    },

    buttonAddBelegId() {
      return `${ this.htmlIdLocal }_add_beleg`;
    },

    statusShowButtonAddBeleg() {
      if (this.statusAddBeleg || this.automatischBelegen) {
        return false;
      }
      if (this.syConfigsAuszahlung.max_anz_belege_pos) {
        let belegeCount = 0;
        forEach(this.belegeLocal, beleg => {
          if (beleg.auszahlung === this.auszahlung.pk) {
            belegeCount++;
          }
        });
        return this.syConfigsAuszahlung.max_anz_belege_pos > belegeCount;
      }
      return true;
    },

    statusDisabledButtonAddBeleg() {
      return this.belegeLocal.some(beleg => !this.isGeprueft({ beleg }) && !this.hasBetragErfasst({ beleg }));
    },

    belegeLocal() {
      if (isArray(this.position.belege)) {
        return this.position.belege;
      }
      if (isPlainObject(this.position.belege)) {
        return values(this.position.belege);
      }
      return [];
    },

    formBelegId() {
      return `${ this.htmlIdLocal }_new`;
    },

    formBelegExtraTranslate() {
      return {
        position_nummer: this.positionNumber,
        position_name: this.positionName
      };
    },

    formPositionExtraTranslate() {
      return {
        position: this.positionNumber,
        kofiart: this.positionName,
      };
    },

    statusShowButtonDelete() {
      return this.position.belege &&
        this.position.belege.length === 0 &&
        this.position[this.keyPositionBetragBewilligt] === "0.00";
    },

    betragsrechner() {
      if (!this.position[this.keyAntragArtInfo]) {
        return;
      }
      return this.position[this.keyAntragArtInfo].betragsrechner;
    },

    extraKFPosition() {
      const EXTRA = cloneDeep(this.extraKF);
      EXTRA.positionArgument = get(this.position, `antrag${ this.prefixCurrent.art }info.argument`, {});
      return EXTRA;
    },

    showLinkToBelege() {
      return this.belegeAnzeigeModus === KAuszahlungBelegAnzeigeModus.LINK
        && !isEmpty(this.belegeLocal);
    },

    hideBelege() {
      return this.belegeAnzeigeModus === KAuszahlungBelegAnzeigeModus.NONE
        || this.belegeAnzeigeModus === KAuszahlungBelegAnzeigeModus.LINK;
    },

    isButtonBelegeVisible() {
      if (this.hideBelege) {
        return false;
      }
      if (this.statusSnapshotDelete) {
        return this.belegeLocal.length > 0;
      }
      return true;
    },

    snapshotIconType() {
      if (this.statusSnapshotAdd) {
        return "add";
      }
      if (this.statusSnapshotDelete) {
        return "delete";
      }
      if (this.diffKofi.status && this.diffChangesPosition.status) {
        return "changes";
      }
      return "";
    },

    diffChangesPosition() {
      return get(this.diffKofi, `changes.${ this.kofiPk }.positionen.${ this.position.pk }`, {}) || {};
    },

    idForPositionName() {
      return `${ this.htmlIdLocal }_name`;
    },

    idForBetragBewilligt() {
      return `${ this.htmlIdLocal }_betrag_bewilligt`;
    },

    idForBetragErfasst() {
      return `${ this.htmlIdLocal }_betrag_erfasst`;
    },

    idForBetragAnerkannt() {
      return `${ this.htmlIdLocal }_betrag_anerkannt`;
    },

    buttonEditId() {
      return `${ this.htmlIdLocal }_edit`;
    },

    buttonDeleteId() {
      return `${ this.htmlIdLocal }_delete`;
    },

    buttonDeleteTooltip() {
      return "_BTN_KF_POSITION_DELETE_TITLE_";
    },

    buttonEditTooltip() {
      if (this.statusEditPosition) {
        return "_BTN_KF_POSITION_EDIT_OPEN_TITLE_";
      }
      return "_BTN_KF_POSITION_EDIT_TITLE_";
    },

    snapshotBelege() {
      return this.snapshot.belege || {};
    },

    diffAddBelege() {
      if (this.diffKofi.status) {
        return get(this.diffKofi.addBelege, `${ this.kofiPk }.${ this.position.pk }`, {});
      }
      return {};
    },

    diffDeleteBelegeSorted() {
      return sortBy(this.diffDeleteBelege, [this.keyBelegPos]);
    },

    diffDeleteBelege() {
      if (this.diffKofi.status) {
        return get(this.diffKofi.deleteBelege, `${ this.kofiPk }.${ this.position.pk }`, []);
      }
      return [];
    },
  },
  created() {
    this.checkSyConfig();
  },
  methods: {
    checkSyConfig() {
      this.syConfigsAuszahlung = this.getSyConfigsValue("auszahlung", {});
      this.isPositionsbezeichnungAbgeschnitten = this.syConfigsAuszahlung.positionsbezeichnung_abschneiden || false;
      this.belegnummerAnzeigen = this.getSyConfigsValue("belege_nummer", {}).anzeigen || false;
    },

    togglePositionDetail() {
      this.statusShowPositionDetail = !this.statusShowPositionDetail;
    },

    addBeleg() {
      this.statusAddBeleg = true;
    },

    closeAddBeleg() {
      this.statusAddBeleg = false;
    },

    isGeprueft({ beleg }) {
      const BELEG_STATUS = isPlainObject(beleg.status) ? beleg.status.pk : beleg.status;
      return BELEG_STATUS === KVertragskostenbelegStatusUUIDs.GEPRUEFT;
    },

    hasBetragErfasst({ beleg }) {
      const PROPERTY_NAME = `${ this.prefixCurrent.beleg }_betrag_erfasst`;
      return isUndefined(beleg[PROPERTY_NAME]) ? +beleg.betrag_erfasst : +beleg[PROPERTY_NAME];
    },

    getPositionNameWithFilter({ data, extra }) {
      const {
        isPositionsbezeichnungAbgeschnitten,
      } = extra;

      let name = this.getPositionName({ data, extra });
      if (isPositionsbezeichnungAbgeschnitten) {
        name = this.filterLimitTo(name, 50);
      }
      return name;
    },

    getPositionName({ data, extra }) {
      const {
        keyArtDetailObj,
        keyArtdetailKbez,
        keyPositionBez,
      } = extra;

      const POSITION_ARTDETAIL_KBEZ = get(data, `${ keyArtDetailObj }.${ keyArtdetailKbez }`, "");

      let name = get(data, keyPositionBez, "");
      if (POSITION_ARTDETAIL_KBEZ) {
        name = `${ POSITION_ARTDETAIL_KBEZ }: ${ name }`;
      }
      return name;
    },

    toggleEdit() {
      this.statusEditPosition = !this.statusEditPosition;
      this.onOpenModalLocal();
    },

    closeEditLocal() {
      this.statusEditPosition = false;
    },

    openDelete() {
      this.selectorClose = [
        `#${ this.buttonDeleteId }`,
        `#${ this.htmlId }`,
      ];
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_KF_DELETE_CONFIRM_HEADER_",
        msg: "_HTML_KF_DELETE_CONFIRM_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: this.deletePosition,
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.confirmShow = true;
      this.onOpenModalLocal();
    },

    deletePosition() {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: this.urlSaveLocal,
      }).then(
        () => {
          this.statusEditPosition = false;
          this.closeConfirm();
          this.$attrs.onReloadKofi();
        }
      ).then(
        () => {
          this.confirmOptions.loading = false;
        }
      );
    },

    closeConfirm() {
      this.confirmShow = false;
    },

    onOpenModalLocal() {
      if (isFunction(this.$attrs.onOpenModal)) {
        this.$attrs.onOpenModal();
      }
    },

    saveLocal() {
      this.statusEditPosition = false;
      this.$attrs.onReloadKofi();
    },
  },
};
