"use strict";

export default () => {
  return {
    require: "stTable",
    link: function(scope, element, attrs, ctrl) {
      scope[attrs.diasTableInit].stTable = ctrl;
    }
  };
};
