import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotItem from "../../../../Snapshot/SnapshotItem/SnapshotItem.vue";

import moment from "moment";

// @vue/component
export default {
  name: "VertragDurchfuehrungszeitraumDifference",
  components: {
    PuxTranslate,
    SnapshotItem,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    von: {
      type: String,
      required: false,
      default: undefined,
    },
    bis: {
      type: String,
      required: false,
      default: undefined,
    },
    vonSnapshot: {
      type: String,
      required: false,
      default: undefined,
    },
    bisSnapshot: {
      type: String,
      required: false,
      default: undefined,
    },
    isDiff: {
      type: Boolean,
      required: true,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    textMonth: {
      type: String,
      required: false,
      default: "_TXT_MONTH_{{months}}_"
    },
    textMonths: {
      type: String,
      required: false,
      default: "_TXT_MONTHS_{{months}}_"
    },
    textDay: {
      type: String,
      required: false,
      default: "_TXT_DAY_{{days}}_",
    },
    textDays: {
      type: String,
      required: false,
      default: "_TXT_DAYS_{{days}}_",
    },
  },
  computed: {
    timeDifferenceCalculated() {
      return this.calculateTimeDifference({
        von: this.von,
        bis: this.bis,
      });
    },

    timeDifferenceCalculatedSnapshot() {
      return this.calculateTimeDifference({
        von: this.vonSnapshot,
        bis: this.bisSnapshot,
      });
    },

    textTranslateMonths() {
      return this.timeDifferenceCalculated.months === 1 ?
        this.textMonth :
        this.textMonths;
    },

    textTranslateMonthsSnapshot() {
      return this.timeDifferenceCalculatedSnapshot.months === 1 ?
        this.textMonth :
        this.textMonths;
    },

    textTranslateDays() {
      return this.timeDifferenceCalculated.days === 1 ?
        this.textDay :
        this.textDays;
    },

    textTranslateDaysSnapshot() {
      return this.timeDifferenceCalculatedSnapshot.days === 1 ?
        this.textDay :
        this.textDays;
    },

    textBeforeDays() {
      return this.timeDifferenceCalculated.months ? " " : "";
    },

    textBeforeDaysSnapshot() {
      return this.timeDifferenceCalculatedSnapshot.months ? " " : "";
    },
  },
  methods: {
    calculateTimeDifference({ von, bis }) {
      if (!von || !bis) {
        return {};
      }
      const VON = moment(von);
      const BIS = moment(bis).add(1, "days");
      const MONTHS = BIS.diff(VON, "months");
      VON.add(MONTHS, "months");
      const DAYS = BIS.diff(VON, "days");
      return {
        months: MONTHS,
        days: DAYS,
      };
    },
  },
};
