"use strict";

import angular from "angular";

class ParameterUtils {
  /*@ngInject*/
  constructor(i18n) {
    this.i18n = i18n;
    this.events = {
      saved: "parameter.saved",
      error: "parameter.error"
    };
  }

  get_ordered_keys(parameters) {
    if (!angular.isObject(parameters)) {
      return [];
    }
    const params = Object.keys(parameters);
    params.sort((a, b) => {
      return (parameters[a].prio || 0) - (parameters[b].prio || 0);
    });
    return params;
  }

  to_request_body(parameters, requiredParam, prefix = "") {
    if (angular.isArray(parameters)) {
      const data = [];
      for (let i = 0; i < parameters.length; i++) {
        data.push(this.to_request_body(parameters[i], requiredParam));
      }
      return data;
    } else if (angular.isObject(parameters) && parameters.typ === "json") {
      return parameters.wert;
    } else if (angular.isObject(parameters) && parameters.typ === "elementliste_regeldata") {
      return parameters.wert;
    } else if (angular.isObject(parameters) && angular.isUndefined(parameters.wert)) {
      const data = {};
      for (const key in parameters) {
        const paramRequired = (parameters[key] && parameters[key].options && parameters[key].options.required) || false;
        if (requiredParam) {
          data[`${ prefix }${ key }`] = this.to_request_body(parameters[key], requiredParam);
        } else {
          data[`${ prefix }${ key }`] = this.to_request_body(parameters[key], paramRequired);
        }
      }
      return data;
    } else if (angular.isObject(parameters) && !angular.isUndefined(parameters.wert)) {
      return this.to_request_body(parameters.wert, requiredParam);
    } else if (angular.isString(parameters) && parameters.length === 0 && !requiredParam) {
      return null;
    }
    return parameters;
  }

  init_parameter_in(parameters) {
    const c = angular.copy(parameters);
    for (const key in c) {
      c[key].options = c[key].options || {};
      // get defaualt value
      if (c[key].wert !== undefined) {
        // do nothing
      } else if (c[key].default !== undefined) {
        c[key].wert = c[key].default;
      } else if (c[key].options.default !== undefined) {
        c[key].wert = c[key].options.default;
      } else {
        c[key].wert = null;
      }
      if (!c[key].options) {
        c[key].options = { required: true };
      } else if (_.isNil(c[key].options.required)) {
        c[key].options.required = true;
      }
    }
    return c;
  }

  params_from_template(template, values) {
    const c = angular.copy(template);
    for (const key in c) {
      if (!(_.isNil(values[key]) || _.isUndefined(values[key].wert))) {
        c[key].wert = values[key].wert;
      } else {
        c[key].wert = values[key];
      }
    }
    return c;
  }

  getErrorLabels(parameters) {
    const errorLabels = {};
    if (parameters) {
      Object.keys(parameters).forEach(key => {
        errorLabels[key] = `${ this.i18n.gettext(parameters[key].bez) || "" }: `;
      });
    }
    return errorLabels;
  }
}

export default ParameterUtils;
