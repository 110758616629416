import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import ModuleMixin from "../ModuleMixin";

// @vue/component
export default {
  name: "Antragsdatum",
  components: {
    PuxTranslate,
  },
  mixins: [
    ModuleMixin,
  ],
  computed: {
    labelHeader() {
      if (this.modelParameter.frage_text) {
        return this.modelParameter.frage_text;
      }
      return "_TXT_GR_ANTRAGSDATUM_HEADER_";
    },

    translateOptionsDatum() {
      return {
        html: "_HTML_ANTRAGSDATUM_EINGEREICHT_{{antragsdatum}}_",
        extra: {
          antragsdatum: this.obj.a_antragsdatum,
        },
      };
    },

    translateOptionsDatumUpdate() {
      return {
        html: "_HTML_ANTRAGSDATUM_UPDATE_{{upddate}}_",
        extra: {
          upddate: this.obj.upddate,
        },
      };
    },

    isModuleEditable() {
      return false;
    },
  },
};
