import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import TemplateAdminFunktionenBoxGroup from "./TemplateAdminFunktionenBoxGroup/TemplateAdminFunktionenBoxGroup.vue";

import {
  forEach,
  sortBy,
  toLower,
  values,
} from "lodash-es";

// @vue/component
export default {
  name: "TemplateAdminFunktionenBox",
  components: {
    AsideRightBox,
    FormElement,
    PuxCloak,
    PuxTranslate,
    TemplateAdminFunktionenBoxGroup,
  },
  props: {
    funktionen: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    funktionenLoadingError: {
      type: String,
      required: false,
      default: undefined,
    },
    initialOpen: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      search: "",
      used: false,
      optionsAsideRightBox: {
        name: "_TXT_TEMPLATEADMIN_DETAILS_FUNKTIONEN_BOX_HEADER_",
        active: this.initialOpen,
        icon: "info-sign"
      },
      optionsSearch: {
        type: "text",
        label: "Suche",
        view: "v",
        id: "template_funktionen_search",
      },
      optionsUsed: {
        type: "one_checkbox",
        label: "Verwendet",
        view: "v",
        id: "template_funktionen_used",
      },
      groupsAndFunktionenNamesHiddenWithSearch: {
        groupNames: {},
        funktionenNames: {},
      },
      timer: undefined,
    };
  },
  computed: {
    funktionenGroupsSorted() {
      return sortBy(this.funktionenGroups, ["name"]);
    },

    funktionenGroups() {
      const GROUPS = {};
      this.funktionenSorted.forEach(funktion => {
        const GROUP_NAME = funktion.group || "Allgemein";
        GROUPS[GROUP_NAME] = GROUPS[GROUP_NAME] || {
          children: [],
          name: GROUP_NAME,
        };
        GROUPS[GROUP_NAME].children.push(funktion);
      });
      return values(GROUPS);
    },

    funktionenSorted() {
      return sortBy(this.funktionen, ["displayname"]);
    },

    searchToLower() {
      return toLower(this.search);
    },
  },
  watch: {
    funktionen() {
      this.setGroupsAndFunktionenNamesHiddenWithSearch();
    },
  },
  methods: {
    changeFilterWithTimeout() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.setGroupsAndFunktionenNamesHiddenWithSearch();
      }, 300);
    },

    setGroupsAndFunktionenNamesHiddenWithSearch() {
      const GROUP_NAMES = {};
      const FUNKTIONEN_NAMES = {};
      if (this.search || this.used) {
        this.funktionenGroupsSorted.forEach(group => {
          let isGroupVisible = false;
          group.children.forEach(funktion => {
            if (this.isFunktionVisible(funktion)) {
              isGroupVisible = true;
            } else {
              FUNKTIONEN_NAMES[funktion.registered_name] = true;
            }
          });
          if (!isGroupVisible) {
            GROUP_NAMES[group.name] = true;
          }
        });
      }
      this.groupsAndFunktionenNamesHiddenWithSearch = {
        groupNames: GROUP_NAMES,
        funktionenNames: FUNKTIONEN_NAMES,
      };
    },

    isFunktionVisible(funktion) {
      let isVisibleUsed = false;
      let isVisibleSearch = false;
      if ((this.used && funktion.used) || !this.used) {
        isVisibleUsed = true;
      }
      if (this.search) {
        if (isVisibleUsed) {
          forEach(["registered_name", "displayname", "description"], key => {
            const TEXT = toLower(funktion[key]);
            if (TEXT.indexOf(this.searchToLower) !== -1) {
              isVisibleSearch = true;
              return false;
            }
          });
        }
      } else {
        isVisibleSearch = true;
      }
      return isVisibleUsed && isVisibleSearch;
    },
  },
};
