"use strict";

class AktualisierenDialogController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, Sammelvertrag) {
    this.DiasNotification = DiasNotification;
    this.errors = null;
    this.extras = $scope.extras;

    Sammelvertrag.one(this.extras.obj.obj_id).customGET("aktualisierungsvorschau").then(
      response => {
        this.vorschau = response;
        this.extras.parameterout.budget.wert = response.sv_budget;
      }
    );

    this.validate = () => {
      this.errors = {};
      if (!this.extras.parameterout.budget.wert) {
        this.errors.budget = ["Bitte geben Sie ein Budget ein"];
      }
      if (this.extras.parameterout.kommentar.options.required &&
          !this.extras.parameterout.kommentar.wert) {
        this.errors.kommentar = ["Bitte geben Sie einen Kommentar ein"];
      }
    };

    this.save = () => {
      this.validate();
      let promise;
      if (!angular.equals({}, this.errors)) {
        const deferred = $q.defer();
        deferred.reject();
        promise = deferred.promise;
      } else {
        promise = this.extras.finish(this.extras.parameterout).then(
          result => {
            return $q.resolve(result); 
          },
          errors => {
            return $q.resolve(errors); 
          }
        );
      }
      return promise;
    };

    this.onError = errors => {
      const errorsLabel = {
        budget: "Zugewiesener Betrag: ",
        kommentar: "Kommentar: ",
      };
      DiasNotification.form.clear();
      DiasNotification.form.error(
        errors.data,
        "Bitte überprüfen Sie Ihre Eingaben", 
        {}, 
        errorsLabel
      );
      this.errors = errors.data;
    };

    this.cancel = () => {
      return $q.resolve(false);
    };
  }
}

export default AktualisierenDialogController;
