var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :ctx.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "ctx.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in ctx.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<div style=\"overflow-y: initial;\" class=\"modal-body\"><div class=\"row\"><section class=\"main col-10 offset-1\"><div ng-attr-id=\"{{ ctx.DiasNotification.form.containerId }}\"></div><alert type=\"'info'\"><p>Für <strong>alle</strong> Anträge dieser Liste mit dem Status\n<strong>{{ ctx.initial.bez }}</strong> wird die ausgewählte Entscheidung\nübernommen.</p><p><i class=\"glyphicon glyphicon-warning\"></i> Dies kann nicht rückgängig gemacht werden.</p></alert><form><fieldset>");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<ui-select ng-model=\"ctx.entscheidungsstatus\" name=\"entscheidungsstatus\" ng-disabled=\"ctx.loading || loading\"><ui-select-match placeholder=\"Wählen Sie eine Entscheidung aus.\"><span ng-bind=\"$select.selected.bez\"></span></ui-select-match><ui-select-choices repeat=\"item.pk as item in (ctx.entscheidungstati | filter: $select.search)\"><span ng-bind=\"item.bez\"></span></ui-select-choices></ui-select>");
}
}, "entscheidungsstatus", "Entscheidung");
buf.push("</fieldset></form></section></div></div><div class=\"modal-footer\"><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"exitWithCallback(ctx.save)\" ng-disabled=\"loading || !ctx.entscheidungsstatus\" type=\"button\" class=\"btn btn-primary\"><span>Entscheidung übernehmen</span></button><button ng-click=\"exit()\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-default\"><span>Abbrechen</span></button></div></div></div>");;return buf.join("");
}