"use strict";
import angular from "angular";

import sitzungsmodule from "dias/sitzungen/sitzungsmodule";

import preview from "./preview.component";

const modul = angular.module("standard_sitzungsmodule.beratungsvorlage", [
  sitzungsmodule.name,
])

  .config(/*@ngInject*/SitzungsmodulRegistryProvider => {
    SitzungsmodulRegistryProvider.register("beratungsvorlage", undefined, "modul-beratungsvorlage-preview");
  })

  .component("modulBeratungsvorlagePreview", preview)
;

export default modul;
