import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import FormElementReadOnly from "../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import showMore from "../../../../../global/components/ui/ShowMore/ShowMore.vue";

import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../../global/compositionAPI/NotificationAPI";

import {
  initModelFromList,
  toFormElementFromParameter,
} from "../../../../../global/functions/mappingForParameterToFormElement";
import {
  cloneDeep,
  filter,
  orderBy,
} from "lodash-es";

// @vue/component
export default {
  name: "SachberichvorlageGeschaeftsregelAdd",
  components: {
    FormElement,
    FormElementReadOnly,
    FormstepDetail,
    PuxButton,
    PuxTranslate,
    showMore,
  },
  props: {
    sachberichtregelList: {
      type: Array,
      required: true,
    },
    sachberichtvorlageRegelnUrl: {
      type: String,
      required: true,
    },
    isChanging: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    "closeAdd",
    "addSachberichtregel",
    "setChanging",
  ],
  setup() {
    const {
      getListHttp,
      postHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      getListHttp,
      postHttp,
    };
  },
  data() {
    return {
      loading: false,
      selectedRegelPk: undefined,
      selectedRegel: {},
      listConfig: [],
      modelConfig: undefined,
      regelnDefault: [],
    };
  },
  computed: {
    formstepDetailOptions() {
      return {
        label: "_LBL_SACHBERICHTVORLAGE_DETAILS_GESCHAEFTSREGEL_ADD_",
        okLabel: "_LBL_BTN_SACHBERICHTVORLAGE_DETAILS_ADD_REGEL_",
        required: true,
        showCloseButton: true,
        showSaveButton: true,
        statusSaveDisabled: this.isChanging || !this.selectedRegelPk,
        notCloseCallbackAfterSave: true,
        saveCallback: this.addRegel,
        closeCallback: this.onClose,
      };
    },

    optionsForSelectRegel() {
      return {
        id: "regel",
        type: "select",
        sortList: ["re_nummer", "re_kbez"],
        label: "_LBL_SACHBERICHTVORLAGE_DETAILS_GESCHAEFTSREGEL_",
        required: true,
        slot: "regel",
        placeholder: "_LBL_SACHBERICHTVORLAGE_DETAILS_CHOOSE_REGEL_",
        searchOutside: this.loadRegeln,
        data: this.verwendbareRegelnList,
        change: this.onRegelChange,
        loading: this.loading,
        view: "v-alt",
        keyId: "pk",
      };
    },

    verwendbareRegelnList() {
      return filter(this.regelnDefault, item => this.filterVerwendbareRegeln(item.pk));
    },

    regelsaetze() {
      if (this.selectedRegel.regelsaetze && this.selectedRegel.regelsaetze.length) {
        return orderBy(this.selectedRegel.regelsaetze, ["kbez"]);
      }
      return [];
    },
  },
  methods: {
    loadRegeln(searchModel) {
      this.loading = true;
      this.getListHttp({
        url: "/sachberichtvorlagen/regeln/",
        urlParams: {
          re_kbez_admin: searchModel,
        },
      }).then(
        response => {
          this.regelnDefault = response;
        }
      ).finally(
        () => {
          this.loading = false;
        }
      );
    },

    onClose() {
      this.$emit("closeAdd");
    },

    onRegelChange({ item }) {
      this.selectedRegel = cloneDeep(item);
      this.setList();
      this.setModelFromList();
    },

    unsetSelectedRegel() {
      this.selectedRegelPk = undefined;
      this.selectedRegel = {};
    },

    filterVerwendbareRegeln(regelPk) {
      for (let i = 0; i < this.sachberichtregelList.length; i++) {
        if (this.sachberichtregelList[i].regel === regelPk) {
          if (regelPk === this.selectedRegelPk) {
            this.unsetSelectedRegel();
          }
          return false;
        }
      }
      return true;
    },

    addRegel() {
      this.$emit("setChanging", { changing: true });
      return this.postHttp({
        url: this.sachberichtvorlageRegelnUrl,
        data: { regel: this.selectedRegelPk },
      }).then(
        response => {
          this.$emit("addSachberichtregel", { regel: response });
          this.addNotification({ text: "_MSG_SACHBERICHTVORLAGE_DETAILS_ADD_REGEL_SUCCESS_" });
          return Promise.resolve();
        },
        errors => {
          this.addNotification({ type: "error", text: "_MSG_SACHBERICHTVORLAGE_DETAILS_ADD_REGEL_ERROR_" });
          return Promise.reject(errors);
        },
      ).finally(
        () => this.$emit("setChanging", { changing: false }),
      );
    },

    setList() {
      this.listConfig = toFormElementFromParameter({

        obj: this.selectedRegel.re_argument,
        showLabel: true,
        staticOptionsForType: {
          dyn_tabelle_objektkatalog_data: {
            dependency: "spalten",
          },
          dyn_tabelle_spaltenauswahl_radio: {
            dependency: "spalten",
          },
        },
      }).list;
    },

    setModelFromList() {
      this.modelConfig = initModelFromList({ list: this.listConfig });
    },
  },
};
