import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import translate from "../../../global/directives/translate";
import { isNil, keyBy } from "lodash-es";

// @vue/component
export default {
  name: "FoerderorgAnsprechpartnerAddModal",
  components: {
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [HttpMixin, NotificationMixin],
  inject: [
    "updateAnsprechpartner",
  ],
  props: {
    objekt: {
      type: Object,
      required: true,
      info: "Objekt an dem die Ansprechpartner hinterlegt werden",
    },
    orgs: {
      type: Array,
      required: true,
      info: "Die Liste der Organisationen",
    },
    baseUrl: {
      type: String,
      default: undefined,
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    options() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "typ",
            htmlId: `ansprechpartner_add_typ`,
            type: "single_choice",
            label: "_LBL_ANSPRECHPARTNER_ADD_MODAL_TYP_",
            helpText: "_TXT_ANSPRECHPARTNER_ADD_MODAL_TYP_HELP_TEXT_",
            url: "katalog/?key=ansprechpartnertypen",
            keyId: "pk",
            keyLabel: "bez",
            showLabel: true,
            translate: true,
            required: true,
            view: "v",
          },
          {
            id: "org",
            htmlId: `ansprechpartner_add_org`,
            type: "single_choice",
            label: "_LBL_ANSPRECHPARTNER_ADD_MODAL_ORG_",
            helpText: "_TXT_ANSPRECHPARTNER_ADD_MODAL_ORG_HELP_TEXT_",
            data: this.orgs,
            keyId: "pk",
            keyLabel: "o_name",
            showLabel: true,
            translate: true,
            required: true,
            view: "v",
          },
          {
            id: "foerderorg_nutzer",
            htmlId: `ansprechpartner_add_foerderorg_nutzer`,
            type: "single_choice",
            label: "_LBL_ANSPRECHPARTNER_ADD_MODAL_FOERDERORG_NUTZER_",
            helpText: "_TXT_ANSPRECHPARTNER_ADD_MODAL_FOERDERORG_NUTZER_HELP_TEXT_",
            url: this.model.org ? `/foerderorgnutzer/` : undefined,
            urlParams: {
              foerderorganisation: this.model.org,
            },
            disabled: isNil(this.model.org), 
            keyId: "pk",
            keyLabelCallback: ({ item }) => `${ item.nutzer_obj.name_mit_abwesenheit }`,
            showLabel: true,
            translate: true,
            required: true,
            view: "v",
          },
          {
            id: "ap_kommentar",
            htmlId: `ansprechpartner_add_ap_kommentar`,
            type: "text",
            label: "_LBL_ANSPRECHPARTNER_ADD_MODAL_AP_KOMMENTAR_",
            helpText: "_TXT_ANSPRECHPARTNER_ADD_MODAL_AP_KOMMENTAR_",
            showLabel: true,
            translate: true,
            required: false,
            view: "v",
          },
        ],
      };
    },

    orgsMapping() {
      return keyBy(this.orgs, "pk");
    },

    isSaveButtonDisabled() {
      return this.status.loading || isNil(this.model.typ) || isNil(this.model.org) || isNil(this.model.foerderorg_nutzer);
    }
  },
  methods: {
    save() {
      this.status.loading = true;
      const data = {
        foerderorg_nutzer: this.model.foerderorg_nutzer,
        typ: this.model.typ,
        ap_kommentar: this.model.ap_kommentar,
        ap_objektid: this.objekt.pk,
        ap_pos: this.orgsMapping[this.model.org].cur_anz,
        content_type: this.objekt.content_type,
      };
      this.postHttp({
        url: `${ this.baseUrl }/ansprechpartner/`,
        data,
      })
        .then(
          response => {
            this.addNotification({
              text: "_MSG_MODAL_FOERDERORG_ANSPRECHPARTNER_ADD_SUCCESS_",
            });
            this.close({ response: response, status: true });
            this.updateAnsprechpartner();
          },
          error => {
            this.errors = error.data;
            this.errorsClone = error.data;
          }
        )
        .then(() => (this.status.loading = false));
    },
  },
};
