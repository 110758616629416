import FormElementReadOnly from "../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import KatalogAdminDetailsModalCreateOrEdit from "./KatalogAdminDetailsModalCreateOrEdit/KatalogAdminDetailsModalCreateOrEdit.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../global/components/ui/ShowMore/ShowMore.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import getTableApi from "../../../const/TableApis";
import {
  toFormElementFromParameter
} from "../../../global/functions/mappingForParameterToFormElement";
import {
  EventBus,
} from "../../../global/functions/event-bus";
import {
  forEach,
} from "lodash-es";

const TABLE_ID = "KatalogDetailsList";

// @vue/component
export default {
  name: "KatalogeAdminDetails",
  components: {
    FormElementReadOnly,
    KatalogAdminDetailsModalCreateOrEdit,
    Modal,
    PuxIcon,
    PuxPageDetails,
    PuxTranslate,
    ShowMore,
    SmartTable,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  data() {
    return {
      loading: true,
      katalog: {},
      options: {
        id: "",
        url: getTableApi({ id: TABLE_ID, params: { id: this.$stateParams.id } }),
        cols: [],
        rowActions: [
          {
            label: "_TXT_KATALOGE_DETAILS_TABLE_ACTIONS_EDIT_",
            callback: this.updateRow,
            isHidden: ({ item }) => item.locked || !this.katalog.edit,
          },
          {
            label: "_TXT_KATALOGE_DETAILS_TABLE_ACTIONS_DELETE_",
            callback: this.confirmDeleteRow,
            isHidden: ({ item }) => item.locked || !this.katalog.delete,
          },
        ],
        actions: [
          {
            label: "_TXT_KATALOGE_DETAILS_TABLE_ACTIONS_CREATE_",
            type: "button",
            className: "btn btn-primary",
            callback: this.createRow,
            isHidden: () => !this.katalog.create,
            htmlId: "katalog_create",
          },
        ],
        label: "_TXT_KATALOGE_DETAILS_TABLE_LABEL_",
        sortable: true,
        pagination: true,
        details: false,
        showHeader: false,
      },
      filters: [
        {
          label: "_LBL_KATALOGE_ADMIN_DETAILS_SUCHE_",
          type: "text",
          id: "suche",
          main: true,
        },
      ],
      confirmShow: undefined,
      confirmOptions: undefined,
      currentRow: undefined,
      statusModal: undefined,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getHttp({
        url: `katalogadmin/${ this.$stateParams.id }/`,
      }).then(
        response => {
          this.katalog = response;
          this.initTable();
        }
      ).then(
        () => this.loading = false
      );
    },

    initTable() {
      this.initId();
      this.initCols();
    },

    initId() {
      this.options.id = `${ TABLE_ID }_${ this.katalog.pk }`;
    },

    initCols() {
      const COLS = [];
      if (this.katalog.edit) {
        COLS.push({
          label: "_LBL_KATALOGE_ADMIN_DETAILS_BEARBEITBARKEIT_",
          id: "bearbeitbarkeit",
          slot: "bearbeitbarkeit",
        });
      }

      const COLS_TEMP = toFormElementFromParameter({
        obj: this.katalog.form,
        staticOptionsForType: {
          richtext: {
            hideShowMore: true,
          },
        },
      }).list;
      forEach(COLS_TEMP, item => {
        const ITEM_TEMP = item;
        ITEM_TEMP.slot = "form-element-readonly";
        ITEM_TEMP.helpText = "";
        COLS.push(ITEM_TEMP);
      });

      this.options.cols = COLS;
    },

    confirmDeleteRow({ item }) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_KATALOGE_DETAILS_DELETE_MODAL_HEADER_{{bez}}_",
        msg: "_TXT_KATALOGE_DETAILS_DELETE_MODAL_HEADER_{{bez}}_{{katalogPk}}_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteRow({ item }),
        cancelCallback: this.closeConfirm,
        extra: {
          bez: item.bez,
          katalogPk: this.katalog.pk,
        },
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteRow({ item }) {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: `katalogadmin/${ this.katalog.pk }/eintraege/${ item.id || item.pk }/`,
        responseType: "text",
      }).then(
        () => {
          this.addNotification({ text: "_MSG_KATALOGE_DETAILS_DELETE_SUCCESS_" });
          EventBus.$emit(`updateTable${ this.options.id }`);
          this.closeConfirm();
        },
        error => this.addNotification({ text: error.data, type: "error" })
      ).then(
        () => {
          this.confirmOptions.loading = false;
          this.closeConfirm();
        }
      );
    },

    closeConfirm() {
      this.confirmShow = false;
    },

    createRow() {
      this.statusModal = true;
    },

    updateRow({ item }) {
      this.currentRow = item;
      this.statusModal = true;
    },

    closeModal({ status }) {
      if (status) {
        EventBus.$emit(`updateTable${ this.options.id }`);
      }
      this.currentRow = undefined;
      this.statusModal = false;
    },
  },
};
