"use strict";

export default () => (collection, keyname) => {
  const output = [];
  const keys = [];
  const found = [];

  if (!keyname) {
    angular.forEach(collection, row => {
      let is_found = false;
      angular.forEach(found, function(foundRow) {
        if (foundRow === row) {
          is_found = true;
        }
      });
      if (is_found) {
        return; 
      }
      found.push(row);
      output.push(row);
    });
  } else {
    angular.forEach(collection, row => {
      const item = row[keyname];
      if (item === null || item === undefined) {
        return;
      }
      if (keys.indexOf(item) === -1) {
        keys.push(item);
        output.push(row);
      }
    });
  }

  return output;
};

