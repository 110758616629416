import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import NachrichtenLink from "../../Nachrichten/NachrichtenLink/NachrichtenLink.vue";
import PuxGet from "../../../global/components/PuxGet/PuxGet.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../global/components/ui/ShowMore/ShowMore.vue";

import TimelineOptions from "../TimelineOptions";
import {
  FilterDateMixin,
} from "../../../global/mixins/FiltersMixin";
import {
  gettext,
} from "../../../global/functions/utils";
import {
  get,
  isFunction,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "TimelineElement",
  components: {
    AngularLink,
    NachrichtenLink,
    PuxGet,
    PuxIcon,
    PuxTranslate,
    ShowMore,
  },
  mixins: [
    FilterDateMixin,
  ],
  props: {
    data: {
      type: Object,
      required: true,
      info: "Timeline-Element Objekt",
    },
    index: {
      type: Number,
      required: true,
      info: "Index in Array",
    },
    statusTermine: {
      type: Boolean,
      required: true,
      info: "Ist Timeline für Termine?",
    },
    statusHide: {
      type: Object,
      required: true,
      info: "Objekt mit keys, die nicht angezeigt werden",
    },
    statusHideWorkflowType: {
      type: Object,
      required: true,
      info: "Objekt mit keys(workflowtyp), die nicht angezeigt werden",
    },
    statusShowFiltersWorkflowType: {
      type: Boolean,
      required: true,
      info: "Ist 'WF-Typ'-Filter angezeigt?",
    },
    config: {
      type: Object,
      required: true,
      info: "Timeline-Konfiguration",
    },
    objects: {
      type: Array,
      required: true,
      info: "Termin-Objekten"
    },
    loadData: {
      type: Function,
      required: true,
      info: "Reloading-Funktion",
    },
  },
  data() {
    return {
      classList: TimelineOptions.options.classList,
    };
  },
  computed: {
    statusElementShow() {
      if (this.statusTermine) {
        if (!this.data.mahnstufe) {
          return !this.statusHide.dok;
        }
        return !this.statusHide.benachrichtigung;
      }
      return !this.statusHide[this.data.typ];
    },

    statusNotFilteredModel() {
      if (this.data.herkunft) {
        return !this.statusHide[this.data.herkunft] ;
      }
      return true;
    },

    terminStatusText() {
      return this.data.ist_faellig ? "_TXT_TIMELINE_FAELLIG_" : "_TXT_TIMELINE_ZU_ERLEDIGEN_";
    },

    statusShowWithWorkflowType() {
      if (!this.statusShowFiltersWorkflowType) {
        return true;
      }
      return !this.statusHideWorkflowType[this.data.workflowtyp.pk];
    },

    classBg() {
      return this.getBg("_bg");
    },

    classBgLight() {
      return this.getBg("_bg_light");
    },

    icon() {
      if (this.statusTermine) {
        return get(TimelineOptions, `type[${ this.data.herkunft }].icon`);
      }
      if (this.data.typ === "aufgabe") {
        return this.data.status_text === "offen" ?
          TimelineOptions.type.aufgabe_offen.icon :
          TimelineOptions.type[this.data.typ].icon;
      }
      return TimelineOptions.type[this.data.typ].icon;
    },

    classPosition() {
      for (let i = 0; i < this.config.kontexte.length; i++) {
        if (this.data.kontext === this.config.kontexte[i].key) {
          return this.classList[i] || this.classList[0];
        }
      }
      return this.classList[0];
    },

    linkState() {
      return get(TimelineOptions, `type.${ this.data.herkunft }.state`);
    },

    linkParameter() {
      const GET_STATE_PARAMETER = get(TimelineOptions, `type.${ this.data.herkunft }.getStateParameter`);
      if (isFunction(GET_STATE_PARAMETER)) {
        return GET_STATE_PARAMETER(this.data);
      }
      return undefined;
    },

    titelText() {
      if (this.typ.notizen) {
        return "_TXT_TIMLINE_NOTIZ_TITEL_";
      }
      return !this.statusTermine ? this.data.titel : this.data.kbez;
    },

    showTitel() {
      return size(this.titelText) > 0;
    },

    linkText() {
      return get(TimelineOptions, `type.${ this.data.herkunft }.text`);
    },

    aufgabeHtml() {
      if (!this.data.status_abgeschlossen) {
        return "_TXT_TIMELINE_AUFGABE_OFFEN_";
      }
      if (!this.nutzer) {
        return "_HTML_TIMELINE_AUFGABE_{{workflowfolge}}_";
      }
      return "_HTML_TIMELINE_AUFGABE_{{workflowfolge}}_{{nutzer}}_";
    },

    aufgabeExtra() {
      return {
        nutzer: this.nutzer,
        workflowfolge: this.data.workflowfolge || gettext("_TXT_TIMELINE_AUFGABE_STATUS_WECHSELN_"),
      };
    },


    showNutzer() {
      return this.data.nutzer && this.typ.aufgabe;
    },

    showNutzerLink() {
      return this.showNutzer && !this.statusTermine;
    },

    nutzer() {
      return get(this.data, "nutzer.name_mit_abwesenheit", "");
    },

    nutzerLabel() {
      if (this.typ.aufgabe) {
        return "_TXT_TIMELINE_ZUSTAENDIG_{{nutzer}}_";
      }
      return "";
    },

    nutzerExtra() {
      return {
        nutzer: this.nutzer,
      };
    },

    showErsteller() {
      return this.data.ersteller && (
        this.typ.benachrichtigung
        || this.typ.notizen
        || this.typ.timelineobjekt
      );
    },

    showErstellerLink() {
      return this.showErsteller && !this.statusTermine;
    },

    ersteller() {
      return get(this.data, "ersteller.name_mit_abwesenheit", "");
    },

    erstellerLabel() {
      if (this.typ.benachrichtigung) {
        return "_TXT_TIMELINE_NACHRICHT_VON_{{ersteller}}_";
      } else if (this.typ.notizen) {
        return "_TXT_TIMELINE_NOTIZ_ERSTELLT_VON_{{ersteller}}_";
      } else if (this.typ.timelineobjekt) {
        return "_TXT_TIMELINE_OBJEKT_ERSTELLT_VON_{{ersteller}}_";
      }
      return "";
    },

    erstellerExtra() {
      return {
        ersteller: this.ersteller,
      };
    },

    dateFilter() {
      return this.filterDate(this.data.datum, "DD. MMMM YYYY");
    },

    dateFilterTime() {
      return this.filterDate(this.data.datum, "H:mm");
    },

    terminFilter() {
      return this.filterDate(this.data.termin, "DD. MMMM YYYY");
    },

    dateCreateFilter() {
      return this.filterDate(this.data.crdate, "DD. MMMM YYYY");
    },

    typ() {
      return {
        benachrichtigung: this.data.typ === "benachrichtigung",
        timelineobjekt: this.data.typ === "timelineobjekt",
        notizen: this.data.typ === "notizen",
        aufgabe: this.data.typ === "aufgabe",
      };
    }
  },
  methods: {
    getBg(suffix) {
      if (this.statusTermine) {
        if (!this.data.mahnstufe) {
          return `timeline__dok${ suffix }`;
        } else if (this.objects.indexOf(this.data.herkunft) !== -1) {
          return `timeline__${ this.data.herkunft }${ suffix }`;
        }
        return `timeline__benachrichtigung${ suffix }`;
      }
      return `timeline__${ this.data.typ }${ suffix }`;
    },
  },
};
