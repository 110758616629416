import PersonInTd from "../PersonInTd/PersonInTd.vue";
import DurchfuehrungszeitraumLine from "../DurchfuehrungszeitraumLine/DurchfuehrungszeitraumLine.vue";
import DurchfuehrungszeitraumLineMixin from "../DurchfuehrungszeitraumLine/DurchfuehrungszeitraumLineMixin";

// @vue/component
export default {
  name: "PersonTd",
  components: {
    PersonInTd,
    DurchfuehrungszeitraumLine,
  },
  mixins: [
    DurchfuehrungszeitraumLineMixin,
  ],
  props: {
    period: {
      type: Object,
      required: true,
    },
    person: {
      type: Object,
      required: true,
    },
    durchfuehrung: {
      type: Object,
      required: true,
    },
  },
};
