"use strict";

import angular from "angular";

import RegelAppkontextUUIDs from "./regelappkontextUUIDs.service";

const serviceModule = angular.module("foerderregeln.services", [
])

  .constant("RegelAppkontextUUIDs", RegelAppkontextUUIDs)
;

export default serviceModule;
