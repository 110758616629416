"use strict";

class ZuschuesseController {
  /*@ngInject*/
  constructor(i18n) {
    this.name = "ZuschuesseController";
    this.zuschussModule = [];
    this.gettext = i18n.gettext;
    if (this.module && this.module.length) {
      for (let i = 0; i < this.module.length; i++) {
        if (this.module[i].modulgruppen.indexOf("zuschuss") !== -1 || this.module[i].modulgruppen.indexOf("pauschale") !== -1) {
          this.zuschussModule.push(this.module[i]);
        }
      }
    }
  }

  zuschuss_saved() {
    // Gibt an ob die Zuschussinformationen gespeichert wurden oder nicht.
    if (this.infoprovider && this.infoprovider.gespeichert === true) {
      return true;
    }
    return false;
  }

  showErrors() {
    return this.infoprovider && (
      (
        this.infoprovider.meldungen &&
        this.infoprovider.meldungen.length
      ) ||
      this.infoprovider.status === 2 ||
      (
        this.infoprovider.status === 1 &&
        this.infoprovider.zuschuss === 0 &&
        this.infoprovider.gesamtzuschuss === 0
      )
    );
  }
}

export default ZuschuesseController;
