import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "AntraegeModalChangeTeam",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    antragId: {
      type: String,
      default: undefined,
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        style: "min-height: 420px",
        list: [
          {
            id: "team",
            type: "select",
            label: "_LBL_ANTRAEGE_MODAL_CHANGE_TEAM_TEAM_",
            required: true,
            view: "v",
            keyId: "pk",
            keyLabel: "t_kbez",
            menuWidthAll: true,
            url: "teams/alle/",
            translate: true,
          },
        ],
      },
      data: {
        team: [],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  methods: {
    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.postHttp({ url: `foerderantraege/${ this.antragId }/team_aendern/`, data }).then(
        response => {
          this.addNotification({ text: "_MSG_ANTRAEGE_MODAL_CHANGE_TEAM_SUCCESS_" });
          this.close({ response: response, status: true });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
