"use strict";


class EditorConnectionModalController {
  /* @ngInject */
  constructor($scope, $filter, Workflowfolge, ParameterUtils) {
    const wfo_id = $scope.extras.wfo_id;
    if (!angular.isUndefined(wfo_id)) {
      this.activeTab = "Clientfunktion";
      this.loading = {
        folge: true,
        funktionen: true
      };
      Workflowfolge.one(wfo_id).get().then(
        response => {
          this.folge = response;
          $scope.setTitle("<span title='" + this.folge.wfo_kbez + "'>Aktion \"" + this.folge.wfo_kbez + "\"</span>");
        },
        () => this.error = true
      ).finally(() => this.loading.folge = false);
      Workflowfolge.one(wfo_id).getFunktionen().then(
        response => {
          this.funktionen = response;
          this.client_paramin_keys = ParameterUtils.get_ordered_keys(this.funktionen.clientfunktion.wfc_parameterin);
          this.client_paramout_keys = ParameterUtils.get_ordered_keys(this.funktionen.clientfunktion.wfc_parameterout);

          this.status_paramin_keys = ParameterUtils.get_ordered_keys(this.funktionen.statusfunktion.wts_parameterin);

          this.server_paramin_keys = {};
          this.server_paramout_keys = {};
          for (let i = 0; i < this.funktionen.serverfunktionen.length; i++) {
            this.server_paramin_keys[this.funktionen.serverfunktionen[i].pk] = ParameterUtils.get_ordered_keys(this.funktionen.serverfunktionen[i].wfs_parameterin);
            this.server_paramout_keys[this.funktionen.serverfunktionen[i].pk] = ParameterUtils.get_ordered_keys(this.funktionen.serverfunktionen[i].wfs_parameterout);
          }
        },
        () => this.error = true
      ).finally(() => this.loading.funktionen = false);
    } else {
      this.error = true;
    }
  }
}

export default EditorConnectionModalController;
