import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";
import {
  forEach,
  filter,
} from "lodash-es";

// @vue/component
export default {
  components: {
    FormElement,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  data() {
    return {
      statusTogglePanel: "legende",
      sliderOptions: {
        label: "Entfernung 5km.",
        type: "range",
        view: "v-alt",
        scale: true,
        min: 0,
        max: 5,
      },
      modelSlider: 3,
      sliderDistance: [
        {
          value: 0.5,
        },
        {
          value: 1,
        },
        {
          value: 2,
        },
        {
          value: 5,
        },
        {
          value: 10,
        },
        {
          value: 50,
        },
      ],
      loadingAreale: false,
      timerAreale: undefined,
      arealeList: [],
      arealeOptions: {
        label: "Quartiere zu weiteren Anträgen ein-/ausblenden",
        type: "multiselect",
        view: "v",
        keyId: "pk",
        keyLabel: "weitere_objekte[0].arj_objektname",
        selectAll: true,
        textsSelectAll: "Alle Quartiere einblenden",
        deselectAll: true,
        textsDeselectAll: "Alle Quartiere ausblenden",
        countMulti: 2,
      },
      modelAreale: [],
      loadingOrteFromAreale: false,
      weitereOrteFromArealeList: [],
      permArealVergleichen: false,
    };
  },
  computed: {
    classIconOrte() {
      return this.getClassIcon("orte");
    },

    classIconLegende() {
      return this.getClassIcon("legende");
    },

    classIconGebiete() {
      return this.getClassIcon("gebiete");
    },

    classIconWeitereQuartiere() {
      return this.getClassIcon("weitere_quartiere");
    },

    getFilterAreale() {
      return filter(this.arealeList, areal => {
        return this.modelAreale.indexOf(areal.pk) !== -1;
      });
    },
  },
  methods: {
    getClassIcon(name) {
      return this.statusTogglePanel === name ? "chevron-down" : "chevron-up";
    },

    togglePanel({ name, statusShow }) {
      if (statusShow) {
        this.statusTogglePanel = name;
        return;
      }

      if (this.statusTogglePanel === name) {
        this.statusTogglePanel = undefined;
      } else {
        this.statusTogglePanel = name;
      }
    },

    changeSlider() {
      clearTimeout(this.timerAreale);
      this.timerAreale = setTimeout(() => {
        this.changeSliderLabel();
        this.loadAreale();
      }, 500);
    },

    changeSliderLabel() {
      this.sliderOptions.label = `Entfernung ${ this.sliderDistance[this.modelSlider].value }km.`;
    },

    checkPermissionAreale() { // areal.vergleichen
      this.permArealVergleichen = this.checkPermissionsSync({ perm: "areal.vergleichen", permArray: this.obj.user_permissions });
      if (this.permArealVergleichen) {
        this.loadAreale();
      }
    },

    loadAreale() {
      if (this.loadingAreale) {
        return;
      }
      this.loadingAreale = true;
      const URL_PARAMS = {
        umkreis: `${ this.centerAddress.adr_geokoord[0] },${ this.centerAddress.adr_geokoord[1] },${ this.sliderDistance[this.modelSlider].value }`,
        typ: this.modelParameter.arealtyp,
      };
      this.getListHttp({
        url: "areale/",
        urlParams: URL_PARAMS,
      }).then(
        response => {
          this.arealeList = this.filterArealeList({ response });
          this.weitereOrteFromArealeList = [];
          this.checkAllAreale();
          this.changeAreale();
          this.loadingAreale = false;
        }
      );
    },

    filterArealeList({ response }) {
      const ANTRAG_PK = this.module.regel.afr_aid;
      forEach(response, (areal, index) => {
        if (areal.weitere_objekte &&
          areal.weitere_objekte[0] &&
          areal.weitere_objekte[0].arj_objektid &&
          areal.weitere_objekte[0].arj_objektid === ANTRAG_PK) {
          response.splice(index, 1);
          return false;
        }
      });
      return response;
    },

    checkAllAreale() {
      const MODEL_AREALE = [];
      forEach(this.arealeList, areal => {
        MODEL_AREALE.push(areal.pk);
      });
      this.modelAreale = MODEL_AREALE;
    },

    changeAreale() {
      this.loadWeitereOrteFromAreale({ antragPks: this.getAntragPks() });
    },

    getAntragPks() {
      if (!this.modelAreale.length) {
        return [];
      }
      const ANTRAG_PKS = [];
      forEach(this.arealeList, areal => {
        if (areal.weitere_objekte &&
            areal.weitere_objekte[0] &&
            areal.weitere_objekte[0].arj_objektid &&
            this.modelAreale.indexOf(areal.pk) !== -1) {
          ANTRAG_PKS.push(areal.weitere_objekte[0].arj_objektid);
        }
      });
      return ANTRAG_PKS;
    },

    loadWeitereOrteFromAreale({ antragPks }) {
      this.loadingOrteFromAreale = true;
      if (!antragPks.length) {
        this.loadingOrteFromAreale = false;
        this.weitereOrteFromArealeList = [];
        return;
      }
      this.getListHttp({
        urlParams: {
          objekt_id: antragPks,
          // typ: this.parameter.adresstypen_editierbar,
        },
        url: "adressen/",
      }).then(
        response => {
          this.weitereOrteFromArealeList = response;
          this.loadingOrteFromAreale = false;
        },
        () => {
          this.loadingOrteFromAreale = false;
        }
      );
    },
  },
};
