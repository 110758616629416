import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import InstrumentBasisdaten from "./InstrumentBasisdaten/InstrumentBasisdaten.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";

// @vue/component
export default {
  name: "InstrumentDetails",
  components: {
    ContextPanel,
    InstrumentBasisdaten,
    PuxPageDetails,
    PuxTranslate,
  },
  setup() {
    const {
      getHttp,
    } = HttpAPI();

    return {
      getHttp,
    };
  },
  data() {
    return {
      loading: true,
      instrument: {},
      panelOptions: {
        label: "_LBL_INSTRUMENT_",
        icon: "glyphicon-file",
        active: true,
      },
    };
  },
  computed: {
    instrumenteUrl() {
      return `foerderinstrumente/${ this.$stateParams.id }/`;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getHttp({
        url: this.instrumenteUrl,
      }).then(
        response => {
          this.instrument = response;
        }
      ).then(
        () => this.loading = false
      );
    },

    updateInstrument({ instrument }) {
      this.instrument = instrument;
    },
  },
};
