import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "ProgrammeBasisdaten",
  components: {
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    programm: {
      type: Object,
      required: true,
    },
    programmUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "updateProgramm",
  ],
  setup() {
    const {
      putHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      putHttp,
    };
  },
  data() {
    return {
      options: {
        label: "_LBL_PROGRAMME_BASISDATEN_",
        list: [
          {
            id: "fpr_kbez",
            type: "text",
            label: "_LBL_PROGRAMME_KURZBEZEICHNUNG_",
            showLabel: true,
            view: "v",
            maxlength: 120,
            required: true,
          },
          {
            id: "fpr_astbez",
            type: "richtext",
            label: "_LBL_PROGRAMME_BASISDATEN_ANTRAGSTELLER_INFO_",
            showLabel: true,
            view: "v-alt",
            required: false,
          },
          {
            id: "fpr_foerderfinder_kurz",
            type: "richtext",
            label: "_LBL_PROGRAMME_BASISDATEN_FOERDERFINDER_KURZTEXT_",
            showLabel: true,
            view: "v-alt",
            required: false,
          },
          {
            id: "fpr_foerderfinder_lang",
            type: "richtext",
            label: "_LBL_PROGRAMME_BASISDATEN_FOERDERFINDER_LANGTEXT_",
            showLabel: true,
            view: "v-alt",
            required: false,
          },
          {
            id: "fpr_foerderidee",
            type: "richtext",
            label: "_LBL_PROGRAMME_BASISDATEN_FOERDERIDEE_",
            showLabel: true,
            view: "v-alt",
            required: false,
          },
          {
            id: "fpr_zielgruppen",
            type: "richtext",
            label: "_LBL_PROGRAMME_BASISDATEN_ZIELGRUPPEN_",
            showLabel: true,
            view: "v-alt",
            required: false,
          },
          {
            id: "fpr_nicht_gefoerdert",
            type: "richtext",
            label: "_LBL_PROGRAMME_BASISDATEN_NICHT_GEFOERDERT_",
            showLabel: true,
            view: "v-alt",
            required: false,
          },
        ],
        required: true,
        saveCallback: this.save,
      },
    };
  },
  methods: {
    save({ model }) {
      const DATA = cloneDeep(model);
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.programmUrl,
          data: DATA,
        }).then(
          response => {
            this.$emit("updateProgramm", { programm: response });
            this.addNotification({ text: "_MSG_PROGRAMME_BASISDATEN_AENDERUNGEN_GESPEICHERT_" });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },
  },
};
