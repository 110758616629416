"use strict";

import template from "./foerderorg.detail.ansprechpartner.jade";
import ansprechpartnerEditTemplate from "./ansprechpartner.edit.jade";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";

class AnsprechpartnerEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, KKommtyp, i18n) {
    super($q, $scope, DiasNotification);
    this.gettext = i18n.gettext;
    this.loading = true;
    this.KKommtyp = KKommtyp;

    this.komTypsObj = {};
    this.kommdatenModel = {};
    this.kommdatenModelObj = {};
    this.initModel();
    this.loadKommtyps();


    this.errorsLabel = {
      fas_name: "Name: ",
      fas_funktion: "Funktion: ",
      fas_pos: "Reihenfolge: "
    };

    this.save = () => {
      this.prepareModelForSave();

      // return this.model.patch({
      return this.model.customPUT({
        fas_name: this.model.fas_name,
        fas_funktion: this.model.fas_funktion,
        fas_pos: this.model.fas_pos,
        kommunikationsdaten: this.model.kommunikationsdaten,
      }).then(
        response => {
          $scope.model.merge($scope.model, response);
          this.DiasNotification.page.success("Änderungen erfolgreich gespeichert.");
        },
        errors => {
          return this.onErrors(errors, this.errorsLabel);
        }
      );
    };
  }

  initModel() {
    angular.forEach(this.model.kommunikationsdaten, komm => {
      this.kommdatenModel[komm.typ.kot_id] = komm.fak_wert;
      this.kommdatenModelObj[komm.typ.kot_id] = komm;
    });
  }

  loadKommtyps() {
    this.KKommtyp.getList().then(
      response => {
        this.komTyps = response;
        angular.forEach(this.komTyps, komTyp => {
          this.komTypsObj[komTyp.kot_id] = {
            kot_id: komTyp.kot_id,
            kot_kbez: komTyp.kot_kbez,
            kot_bez: komTyp.kot_bez,
            kot_pos: komTyp.kot_pos,
            kot_pflicht: komTyp.kot_pflicht
          };
        });
        this.loading = false;
      }
    );
  }

  prepareModelForSave() {
    this.model.kommunikationsdaten = [];

    angular.forEach(this.kommdatenModel, (komModel, key) => {
      if (komModel) {
        if (this.kommdatenModelObj[key]) {
          this.kommdatenModelObj[key].fak_wert = komModel;
          this.model.kommunikationsdaten.push(this.kommdatenModelObj[key]);
        } else {
          this.model.kommunikationsdaten.push({
            fak_wert: komModel,
            typ: this.komTypsObj[key]
          });
        }
      }
    });
  }
}

class FoerderorgdetailsAnsprechpartnerController {
  /*@ngInject*/
  constructor($q, $stateParams, AuthService, Foerderorganisation, KKommtyp) {
    this.loading = {
      partner: true,
    };
    this.partnerEditable = false;
    this.$stateParams = $stateParams;
    this.AuthService = AuthService;
    this.Foerderorganisation = Foerderorganisation;
    this.KKommtyp = KKommtyp;

    this.ansprechpartnerEditController = AnsprechpartnerEditController;
    this.ansprechpartnerEditTemplate = ansprechpartnerEditTemplate;

    this.init();
  }

  init() {
    this.partnerEditable = this.AuthService.syncHasPerm("foerderorg.basicdata_update");
    this.loadOrg();
  }

  loadOrg() {
    this.Foerderorganisation.one(this.$stateParams.id).get().then(
      response => {
        this.org = response;
        this.loadPartner();
      }
    );
  }

  loadPartner() {
    this.org.ansprechpartner.one(this.$stateParams.ap_id).get().then(
      response => {
        this.partner = response;
        this.loading.partner = false;
      }
    );
  }
}


export default {
  template: template(),
  controller: FoerderorgdetailsAnsprechpartnerController,
  controllerAs: "vm"
};
