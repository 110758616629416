"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import diasLoading from "dias/core/loading";

import models from "../models";
import services from "../services";

import angebotsVersionenComponent from "./angebotsversionen.component";
import regelsatzStatusLabelComponent from "./regelsatzstatuslabel.component";


const versionenModule = angular.module("root.foerderangebote.versionen", [
  uiRouter,
  models.name,
  services.name,
  diasLoading.name,
]);

versionenModule.component("regelsatzStatusLabel", regelsatzStatusLabelComponent);
versionenModule.component("angebotsVersionen", angebotsVersionenComponent);

export default versionenModule;
