// @vue/component
export default {
  name: "StyleguideTableOptionsList",
  data() {
    return {
      list: [
        {
          label: "id",
          type: "String",
          default: "undefined",
          description: "ID des Listelements",
          required: true,
          example: `id: "traeger",`,
          options: "",
        },
        {
          label: "type",
          type: "String",
          default: "undefined",
          description: "Typ des Listelements",
          required: true,
          example: `type: "select",`,
          options: "",
        },
        {
          label: "label",
          type: "String",
          default: "undefined",
          description: "Label des Listelements",
          required: true,
          example: `label: "Trägerschaft",`,
          options: "",
        },
        {
          label: "required",
          type: "Boolean",
          default: "undefined",
          description: "Zeigt an ob erforderlich oder nicht",
          required: false,
          example: `required: true,`,
          options: "",
        },
        {
          label: "view",
          type: "String",
          default: "undefined",
          description: "",
          required: false,
          example: `view: "v",`,
          options: "",
        },
        {
          label: "readonly",
          type: "Boolean",
          default: "undefined",
          description: "",
          required: false,
          example: "readonly: true,",
          options: "",
        },
        {
          label: "maxlength",
          type: "Integer",
          default: "undefined",
          description: "",
          required: false,
          example: "maxlength: 200,",
          options: "",
        },
        {
          label: "format",
          type: "String",
          default: "undefined",
          description: "Format des Listelements",
          required: false,
          example: `format: "DD.MM.YYYY HH:mm [Uhr]",`,
          options: "",
        },
        {
          label: "formatSave",
          type: "String",
          default: "undefined",
          description: "",
          required: false,
          example: `formatSave: "YYYY-MM-DD HH:mm",`,
          options: "",
        },
        {
          label: "trueValue",
          type: "Boolean",
          default: "undefined",
          description: "",
          required: false,
          example: "trueValue: true,",
          options: "",
        },
        {
          label: "falsevalue",
          type: "Boolean",
          default: "undefined",
          description: "",
          required: false,
          example: "falseValue: false,",
          options: "",
        },
        {
          label: "text",
          type: "String",
          default: "undefined",
          description: "",
          required: false,
          example: `text: "Beispieltext"`,
          options: "",
        },
        {
          label: "deselect",
          type: "Boolean",
          default: "undefined",
          description: "",
          required: false,
          example: "deselect: false,",
          options: "",
        },
        {
          label: "url",
          type: "String",
          default: "undefined",
          description: "",
          required: false,
          example: `url: "katalog/?key=kbundesland",`,
          options: "",
        },
        {
          label: "key",
          type: "String",
          default: "undefined",
          description: "",
          required: false,
          example: `key: "extra",`,
          options: "",
        },
        {
          label: "keyLabel",
          type: "String",
          default: "undefined",
          description: "",
          required: false,
          example: `keyLabel: "t_kbez",`,
          options: "",
        },
        {
          label: "keyLabelCallback",
          type: "Funktion",
          default: "undefined",
          description: "",
          required: false,
          example: "keyLabelCallback: ({ item }) => `${ item.angebot_kbez } (Version ${ item.ars_version }: ${ item.ars_kbez })`,",
          options: "",
        },
        {
          label: "KeyId",
          type: "String",
          default: "undefined",
          description: "",
          required: false,
          example: `keyId: "pk",`,
          options: "",
        },
        {
          label: "searchParameter",
          type: "String",
          default: "undefined",
          description: "Parameter der für die Suche verwendet wird.",
          required: false,
          example: `searchParameter: "bez",`,
          options: "",
        },
        {
          label: "searchGlobal",
          type: "Boolean",
          default: "undefined",
          description: "",
          required: false,
          example: "searchGlobal: true,",
          options: "",
        },
        {
          label: "menuStyles",
          type: "String",
          default: "undefined",
          description: "",
          required: false,
          example: `menuStyles: "max-width: none;",`,
          options: "",
        },
        {
          label: "append",
          type: "String",
          default: "undefined",
          description: "",
          required: false,
          example: `append: "hochkomma"<span class="input-group-text">€</span>"hochkomma",`,
          options: "",
        },
        {
          label: "addNew",
          type: "Boolean",
          default: "undefined",
          description: "",
          required: false,
          example: "addNew: true,",
          options: "",
        },
        {
          label: "keyArray",
          type: "Boolean",
          default: "undefined",
          description: "",
          required: false,
          example: "keyArray: true,",
          options: "",
        },
        {
          label: "search",
          type: "Boolean",
          default: "undefined",
          description: "",
          required: false,
          example: "search: true,",
          options: "",
        },
        {
          label: "searchList",
          type: "Array",
          default: "undefined",
          description: "",
          required: false,
          example: `searchList: ["label"],`,
          options: "",
        },
        {
          label: "choices",
          type: "Array",
          default: "undefined",
          description: "",
          required: false,
          example: `choices: ["hs_id", "hs_kbez"],`,
          options: "",
        },
        {
          label: "closeClick",
          type: "Boolean",
          default: "undefined",
          description: "",
          required: false,
          example: "closeClick: true,",
          options: "",
        },
        {
          label: "menuRight",
          type: "Boolean",
          default: "undefined",
          description: "",
          required: false,
          example: "menuRight: true,",
          options: "",
        },
        {
          label: "showCaret",
          type: "Boolean",
          default: "undefined",
          description: "",
          required: false,
          example: "showCaret: true,",
          options: "",
        },
        {
          label: "class",
          type: "String",
          default: "undefined",
          description: "Klasse des Listelements.",
          required: false,
          example: `class: "col-lg-6",`,
          options: "",
        },
      ]
    };
  },
};
