"use strict";

import BaseModel from "dias/core/models/baseModel";

class WorkflowTyp extends BaseModel {
  constructor() {
    super("workflowtypen");
  }
}

export default WorkflowTyp;
