"use strict";
import angular from "angular";

import diasNotification from "dias/core/notification";
import diasConfirm from "dias/core/confirm";
import diasModal from "dias/core/modal";

import models from "../models";
import services from "../services";
import aufgabeBoxComponent from "./aufgabe.box.component"; // TODO: wird gelöscht nach dem Test
import manuelleAufgabeBoxComponent from "./manuelleAufgabe.box.component";
import detailComponent from "./detail.component";

const modul = angular.module("dias.workflows.aufgaben", [
  diasNotification.name,
  diasConfirm.name,
  diasModal.name,
  models.name,
  services.name,
]);

modul.config($stateProvider => {
  $stateProvider

    .state("root.aufgabe", {
      url: "/aufgaben/?aufgabe&status&zustaendig&not_status&aufgaben_status&friststatus&termin_days_min&termin_days_max&termin_after&&termin_before&meine_teams&passiv",
      reloadOnSearch: false,
      views: {
        "main@root": {
            // template: "<aufgabe></aufgabe>",
          template: "<div vueinit><vue-aufgabe-list></vue-aufgabe-list></div>"
        }
      },
      data: {
        permissions: "aufgabe.view"
      }
    })
  ;
})

  .component("aufgabeBoxAngular", aufgabeBoxComponent) // TODO: wird gelöscht nach dem Test
  .component("aufgabeTeil", detailComponent)
  .component("manuelleAufgabeBox", manuelleAufgabeBoxComponent)

;

export default modul;
