"use strict";

class FoerderfinderfrageModalCreateController {
  /*@ngInject*/
  constructor($q, Foerderfinderfragen, DiasNotification, $state) {
    this.DiasNotification = DiasNotification;
    this.frage = {};
    this.errors = {};

    this.create = () => {
      return Foerderfinderfragen.post(this.frage).then(
        response => {
          DiasNotification.page.success("Förderfinderfrage erfolgreich erstellt");
          $state.go("root.foerderfinderfragen.detail", { id: response.pk });
        },
        error => {
          const errorsLabel = {
            ffr_text: "Text: ",
            ffr_name: "Interne Bezeichnung:",
            ffr_ueberschrift: "Überschrift: ",
          };
          DiasNotification.form.clear();
          DiasNotification.form.error(error.data, "Ihre Daten sind fehlerhaft oder unvollständig.", {}, errorsLabel);
          this.errors = error.data;
          return $q.reject(error);
        }
      );
    };
  }
}
export default FoerderfinderfrageModalCreateController;
