"use strict";

import template from "./template.jade";
import moment from "moment";
import {
  cloneDeep,
  forEach,
  round,
} from "lodash-es";

class PersonalkostenrechnerController {
  /*@ngInject*/
  constructor($scope, Personalstelle, i18n, AuthService, diasConfirmDialog) {
    this.gettext = i18n.gettext;
    this.AuthService = AuthService;
    this.Personalstelle = Personalstelle;
    this.diasConfirmDialog = diasConfirmDialog;
    if (!this.kontext) {
      this.kontext = "antrag";
    }
    this.$scope = $scope;
    this._init_values();

    this.perioden = cloneDeep(this.extras.regeldata.perioden);
    this.statusShowWarningJahresbrutto = undefined;
  }

  asPruefer() {
    return this.AuthService.syncHasPerm("vertragskostenpositionsbelege.anerkennen", this.posmodel.user_permissions);
  }

  _init_values() {
    // Die Erfassung der Personalstelle (z.B. aufgestockte Personalstelle)
    // ist nur im Vertragskontext erforderlich:
    if (this.kontext === "beleg") {
      this.Personalstelle.getList().then(result => {
        this.personalstellen = result;
      });
    }
  }

  onJahresbruttoChanged({ statusConfirm } = {}) {
    if (this.statusShowWarningJahresbrutto && !statusConfirm) {
      return;
    }
    forEach(this.extras.regeldata.perioden, (period, idx) => {
      let percent = 100;
      if (period.von && period.bis) {
        const BIS_PLUS_DAY = moment(period.bis).add(1, "days");
        let countDays = Math.abs(BIS_PLUS_DAY.diff(moment(period.von), "days"));
        const COUNT_YEARS = Math.abs(BIS_PLUS_DAY.diff(moment(period.von), "years"));
        if (COUNT_YEARS !== 1) {
          if (countDays === 366) {
            countDays--;
          }
          percent = countDays * 100 / 365;
        }
      }
      this.posmodel.argument.perioden[idx].gehalt = round(this.posmodel.argument.jahresbrutto * percent / 100, 2);
    });
  }

  changeGehalt() {
    this.statusShowWarningJahresbrutto = true;
  }

  blurJahresbrutto() {
    if (!this.statusShowWarningJahresbrutto) {
      return;
    }
    this.diasConfirmDialog({
      titleTemplate: "Neue Gehaltswerte übernehmen",
      contentTemplate: `<p>Sind Sie sicher, dass die Werte in den Perioden mit den geänderten Gehaltsangaben überschrieben werden sollen?</p>`,
      okLabel: "Ja",
      okCallback: () => this.onJahresbruttoChanged({ statusConfirm: true }),
    });
  }
}

export default {
  template: template(),
  controller: PersonalkostenrechnerController,
  controllerAs: "ctx",
  bindings: {
    rechner: "=",
    posmodel: "=",
    errors: "=",
    extras: "=",
    kontext: "=?",
    vorschau: "=",
    prefix: "<?"
  },
};
