import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import FiBuJournalListDetails from "../FiBuJournalListDetails/FiBuJournalListDetails.vue";
import FiBuJournalModalBuchungStornieren from "../FiBuJournalModalBuchungStornieren/FiBuJournalModalBuchungStornieren.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import getTableApi from "../../../const/TableApis";
import { EventBus } from "../../../global/functions/event-bus";

const TABLE_ID = "fibujournalTable";

// @vue/component
export default {
  name: "FiBuJournalList",
  info: "Die Tabelle in der alle FiBu Journale aufgelistet werden",
  tags: [
    "FiBu",
    "Tabelle",
  ],
  components: {
    AngularLink,
    FiBuJournalListDetails,
    FiBuJournalModalBuchungStornieren,
    PageTabTitle,
    PuxIcon,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_TXT_FIBUJOURNAL_TABLE_ROW_ACTIONS_STORNIEREN_",
            isHidden: () => !this.checkPermissionsSync({ perm: "fibu.create" }),
            className: "btn btn-default",
            callback: this.askStorno,
          },
        ],
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
          details: "right",
        },
      },
      modal: {
        buchungStornieren: undefined,
      },
      currentBuchung: undefined,
    };
  },
  computed: {
    getHref() {
      return ({ row, herkunftField, pkField }) => {
        const MAP = {
          foerderantrag: {
            root: "root.foerderantrag.details",
            parameter: {
              id: row[pkField],
            },
          },
          sitzung: {
            root: "root.sitzungen.details",
            parameter: {
              id: row[pkField],
            },
          },
          vertrag: {
            root: "root.vertrag.details",
            parameter: {
              id: row[pkField],
            },
          },
          auszahlung: {
            root: "root.vertrag.details.auszahlung",
            parameter: {
              id: row.vertrag_id,
              aus_id: row[pkField],
            },
          },
        };
        return MAP[row[herkunftField]];
      };
    },

    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },

    getSlotNameTableDetailsHeader() {
      return `${ this.options.id }_table_details_header`;
    },
  },
  methods: {
    askStorno({ item }) {
      this.currentBuchung = item;
      this.modal.buchungStornieren = true;
    },

    closeModalBuchungStornieren({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.modal.buchungStornieren = false;
    },
  },
};
