import {
  computed,
  ref,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import {
  EventBus,
} from "../../../../global/functions/event-bus";

export default function GutachtenAPI({ 
  obj = ref({}), 
  baseUrl = computed(() => ""),
}) {
  const {
    checkPermissionsSync,
  } = PermissionAPI();
  const {
    getHttp,
    getListHttp,
  } = HttpAPI();

  const hasWFPermissionGutachtenCreate = computed(() => {
    return checkPermissionsSync({
      perm: "gutachten.create",
      permArray: obj.value.user_permissions,
    });
  });
  const gutachtenTypen = ref([]);
  const loadKGutachtentypen = () => {
    return getHttp({
      url: "katalog/",
      urlParams: {
        key: "kgutachtentypen",
      },
      apiSaveId: "kgutachtentypen",
    }).then(
      response => {
        gutachtenTypen.value = response;
      }
    );
  };
  const hasWFPermissionGutachtenViewOverview = computed(() => {
    return checkPermissionsSync({
      perm: "gutachten.view.overview",
      permArray: obj.value.user_permissions,
    });
  });
  const gutachtenUrl = computed(() => {
    return `${ baseUrl.value }gutachten/`;
  });
  const gutachten = ref([]);
  const loadGutachten = () => {
    if (!hasWFPermissionGutachtenViewOverview.value) {
      gutachten.value = [];
      EventBus.$emit("updateObjGutachten");
      return;
    }
    getListHttp({
      url: gutachtenUrl.value,
    }).then(
      response => {
        gutachten.value = response;
        EventBus.$emit("updateObjGutachten");
      }
    );
  };

  return {
    gutachten,
    gutachtenUrl,
    gutachtenTypen,
    hasWFPermissionGutachtenCreate,
    loadKGutachtentypen,
    loadGutachten,
  };
}
