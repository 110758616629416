import AnkuendigungModalCreate from "../AnkuendigungModalCreate/AnkuendigungModalCreate.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "ankuendigung";

// @vue/component
export default {
  name: "AnkuendigungList",
  components: {
    AnkuendigungModalCreate,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_TXT_ANKUENDIGUNG_TABLE_ACTIONS_SHOW_DETAILS_",
            route: "root.news.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [
          {
            permission: "news.create",
            label: "_TXT_ANKUENDIGUNG_TABLE_ACTIONS_CREATE_",
            callback: () => this.modal.create = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "news_create",
          },
        ],
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      modal: {
        create: undefined,
      },
    };
  },
  methods: {
    closeModalCreate() {
      this.modal.create = false;
    },
  },
};
