"use strict";

export default /*@ngInject*/ (smoothScroll, $timeout, $document) => {
  return {
    restrict: "A",
    link: (scope, el) => {
      el.bind("click", () => {
        const parentFormStep = el.parent();
        if (parentFormStep.hasClass("active")) {
          parentFormStep.removeClass("active");
        } else {
          parentFormStep.addClass("active");

          // Aktion Mensch
          const navbarFixedTop = $document[0].getElementById("navbar-fixed-top");
          const navbarFixedTopHeight = navbarFixedTop ? navbarFixedTop.offsetHeight : 0;

          // Proandi
          const navbarStaticTop = $document[0].querySelector(".navbar-static-top");
          const navbarStaticTopHeight = navbarStaticTop ? navbarStaticTop.offsetHeight : 0;

          const alwaysVisible = $document[0].getElementById("always-visible");
          const alwaysVisibleHeight = alwaysVisible ? alwaysVisible.offsetHeight : 0;
          const alwaysVisibleAngular = alwaysVisible ? angular.element(alwaysVisible) : null;

          const messageBox = $document[0].getElementById("message-box-fixed");
          const messageBoxHeight = messageBox ? messageBox.offsetHeight : 0;

          let offset = navbarFixedTopHeight + navbarStaticTopHeight + alwaysVisibleHeight + messageBoxHeight;
          if (alwaysVisibleAngular && !alwaysVisibleAngular.hasClass("fixed")) {
            offset += alwaysVisibleHeight;
          }
          $timeout(() => {
            smoothScroll(el[0], { offset: offset });
          }, 100);
        }
      });
    }
  };
};

