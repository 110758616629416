import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragMessageBox",
  components: {
    AsideRightBox,
    PuxTranslate,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    headerText: {
      type: String,
      required: true,
    },
    vertrag: {
      type: Object,
      required: false,
      default: undefined,
    },
    bodyTextDefault: {
      type: String,
      required: false,
      default: undefined,
    },
    bodyTextKey: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  computed: {
    optionsAsideRightBox() {
      return {
        icon: "warning",
        toggle: this.bodyTextKey !== undefined,
      };
    },

    bodyText() {
      return get(this.vertrag, this.bodyTextKey);
    },
  },
};
