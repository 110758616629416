import FormElement from "../../FormElement/FormElement.vue";
import FormElementReadOnly from "../../FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import Permission from "../../Permission/Permission.vue";
import {
  cloneDeep,
  // forEach,
  get,
  filter,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "FormstepDetailEdit",
  components: {
    FormElement,
    FormElementReadOnly,
    Permission,
  },
  props: {
    errors: {
      type: Object,
      default: () => ({}),
    },
    objects: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    modelParent: {
      type: Object,
      default: () => ({}),
    },
    save: {
      type: Function,
      required: true,
    },
  },
  emits: [
    "changeModelInChild",
  ],
  data() {
    return {
      model: {},
    };
  },
  computed: {
    getClassParent() {
      return this.options.viewEdit === "v" ? "row mr-0 ml-0" : "";
    },

    getClassFormElement() {
      return this.options.viewEdit === "v" ? "col-12" : "";
    },

    getFilterList() {
      if (this.options.showReadonlyInEdit) {
        return this.list;
      }
      return filter(this.list, item => !item.readonly);
    },

    formElementComponentMap() {
      const MAP = {};
      forEach(this.list, el => {
        MAP[el.id] = el.readonly ? "FormElementReadOnly" : "FormElement";
      });
      return MAP;
    },

    formElementClassMap() {
      const MAP = {};
      forEach(this.list, el => {
        MAP[el.id] = `${ this.getClassFormElement } ${ el.readonly ? "mb-3" : "" }`;
      });
      return MAP;
    },

    dependencyValues() {
      const DEPS = {};
      forEach(this.getFilterList, item => {
        if (item.dependency) {
          DEPS[item.id] = get(this.model, item.dependency);
        }
      });
      return DEPS;
    },

    showValue() {
      return item => {
        const value = get(this.objects[item.object], item.id);
        if (item.type === "multiselect") {
          return value || [];
        }
        return value;
      };
    },
  },
  created() {
    this.initModelDefault();
  },
  methods: {
    initModelDefault() {
      this.model = cloneDeep(this.modelParent);
    },

    onChange({ id, model } = {}) {
      const MODEL = cloneDeep(this.model);
      MODEL[id] = model;
      this.model = MODEL;
      this.$emit("changeModelInChild", { model: this.model, id });
    },

    changeModel({ model }) {
      this.model = cloneDeep(model);
      this.onChange();
    },
  },
};
