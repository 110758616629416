"use strict";

class AmpelController {
  /*@ngInject*/
  constructor() {
    if (this.parameter[this.key].wert === undefined) {
      this.parameter[this.key].wert = {};
    }
  }
}

export default AmpelController;
