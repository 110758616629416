"use strict";

import template from "./dokumentSitzung.jade";

class DokumentSitzungController {
  /*@ngInject*/
  constructor(AuthService) {
    AuthService.hasPerm("sitzungdokument.view").then(
      has_perm => {
        this.permissionView = has_perm;
        if (has_perm) {
          this.loadData();
        }
      }
    );
  }

  loadData() {
    this.loading = true;
    this.docObj.dokumente.getList().then(
      result => {
        this.dokumente = result;
        this.dokumenteClone = result;
      }
    ).finally(() => this.loading = false);
  }

  filter() {
    this.dokumente = this.dokumenteClone.clone();
    for (let i = 0; i < this.dokumente.length; i++) {
      if (this.dokumente[i].dokument.dok_titel.toLowerCase().indexOf(this.model.toLowerCase()) === -1) {
        this.dokumente.splice(i, 1);
        i--;
      }
    }
  }

  showCurrentDok() {

  }
}

export default {
  template: template(),
  controller: DokumentSitzungController,
  controllerAs: "vm",
  bindings: {
    docObj: "<"
  }
};
