"use strict";

import BaseModel from "dias/core/models/baseModel";

class Gutachter extends BaseModel {
  constructor() {
    super("gutachter");
  }
}

export default Gutachter;
