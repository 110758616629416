"use strict";

import BaseController from "../standard_foerdermodule.base.controller";

class AuszahlungsController extends BaseController {
  /*@ngInject*/
  constructor(ParameterUtils) {
    super();
    this.readonly = true;
    this.konfig_keys = ParameterUtils.get_ordered_keys(this.parameter.auszahlungen.konfig);
  }
}

export default AuszahlungsController;
