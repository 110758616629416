import {
  computed,
  toRef,
} from "vue";

import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  sortBy,
} from "lodash-es";

// @vue/component
export default {
  name: "GeschaeftsregelAngebote",
  components: {
    AngularLink,
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    regelsaetze: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props) {
    const regelsaetze = toRef(props, "regelsaetze");
    const regelsaetzeSorted = computed(() => {
      return sortBy(regelsaetze.value, ["kbez"]);
    });

    const optionsFormstepDetail = {
      label: "_TXT_GR_DETAILS_FOERDERANGEBOTE_",
    };

    return {
      optionsFormstepDetail,
      regelsaetzeSorted,
    };
  },
};
