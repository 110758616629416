"use strict";

import "restangular";

import Textbaustein from "./textbaustein.service";

const modelsModule = angular.module("dias.textbausteine.models", [
  "restangular",
])

  .provider("Textbaustein", Textbaustein)
;

export default modelsModule;
