"use strict";

import angular from "angular";

import models from "./models";
import services from "./services";

import datenschutz from "./datenschutz";

import fgruppen from "./funktionsgruppen";
import nutzer from "./nutzer";
import rollen from "./rollen";
import profil from "./profil";
import permission from "./permissions";
import anonymisiert from "./anonymisiert";


const authModule = angular.module("auth", [
  models.name,
  services.name,
  datenschutz.name,
  fgruppen.name,
  nutzer.name,
  rollen.name,
  profil.name,
  permission.name,
  anonymisiert.name,
]);

export default authModule;
