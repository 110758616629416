"use strict";

import BaseModel from "dias/core/models/baseModel";

class Systemmodul extends BaseModel {
  constructor() {
    super("system_module");
  }
}

export default Systemmodul;
