var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;







buf.push("<section dias-cloak=\"vm.loading\"><div class=\"row\"><div ng-class=\"vm.regClass\"><div ng-if=\"vm.successStatus\" class=\"card\"><div ng-bind-html=\"vm.regHeader.successLabel\" ng-class=\"vm.regHeader.class\"></div><div class=\"card-body\"><div class=\"row\"><div ng-bind-html=\"vm.regBody.success\" ng-class=\"vm.regBody.class\"></div></div><form><div class=\"form-group row\"><label class=\"col-form-label col-sm-4 text-right font-weight-bold\">Neue E-Mail-Adresse:</label><div class=\"col-sm-6\"><p class=\"form-control-static\">{{ vm.email }}</p></div></div><div class=\"form-group row\"><div class=\"offset-sm-4 col-sm-6\"><button ng-click=\"vm.aktivierenEmail()\" type=\"submit\" class=\"btn btn-primary\">E-Mail-Adresse aktivieren</button></div></div></form></div></div><div ng-if=\"!vm.successStatus\" class=\"card\"><div ng-bind-html=\"vm.regHeader.unsuccessLabel\" ng-class=\"vm.regHeader.class\"></div><div class=\"card-body\"><div class=\"row\"><div ng-bind-html=\"vm.regBody.unsuccess\" ng-class=\"vm.regBody.class\"><p><a ui-sref=\"root.login\" style=\"white-space:nowrap;\"><i class=\"glyphicon glyphicon-chevron-right\"></i>&nbsp;Zur Anmeldeseite</a></p></div></div></div></div></div></div></section>");;return buf.join("");
}