"use strict";

import coreModels from "dias/core/models";

import parameterServices from "dias/foerderregeln/parameter/services";
import auszahlungsmodiConfig from "./auszahlungsmodi.config.component";
import auszahlungsmodiRo from "./auszahlungsmodi.readonly.component";


const parametermoduleAuszahlungsmodi = angular.module("dias.vertrag.parameter.auszahlungsmodi", [
  coreModels.name,
  parameterServices.name,
]);

parametermoduleAuszahlungsmodi.config(ParametermoduleRegistryProvider => {
  ParametermoduleRegistryProvider.register("auszahlungsmodi", "parametermodul-auszahlungsmodi");
})

  .component("parametermodulAuszahlungsmodi", auszahlungsmodiConfig)
  .component("parametermodulAuszahlungsmodiRo", auszahlungsmodiRo);


export default parametermoduleAuszahlungsmodi;
