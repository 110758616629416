"use strict";

import BaseModel from "dias/core/models/baseModel";

class RegelsatzVorlagen extends BaseModel {
  constructor() {
    super("regelsatz_vorlagen");
  }
}

export default RegelsatzVorlagen;
