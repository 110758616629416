import Alert from "../../../global/components/Alert/Alert.vue";
import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import AntragslisteModalCreate from "../AntragslisteModalCreate/AntragslisteModalCreate.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "cortexantragsliste";

// @vue/component
export default {
  name: "AntragslisteList",
  components: {
    Alert,
    AngularLink,
    AntragslisteModalCreate,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_LBL_ANTRAGSLISTE_TABLE_ROW_ACTIONS_DETAILS_",
            route: "root.antragslisten.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [],
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      modal: {
        create: undefined,
      },
      errors: undefined,
      status: {
        creating: false
      }
    };
  },
  created() {
    this.initActions();
  },
  methods: {
    initActions() {
      this.getHttp({
        url: `antragslisten/verfuegbare_vorlagenversionen/`
      }).then(
        response => {
          const action = {
            permission: "foerderantrag.cortex.antragslisten.create",
            label: "_LBL_ANTRAGSLISTE_TABLE_ACTIONS_CREATE_",
            callback: () => this.modal.create = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            isDisabled: false,
            htmlId: "antragsliste_create",
          };
          if (response.length === 1) {
            action.callback = () => {
              action.label = "_LBL_ANTRAGSLISTE_TABLE_ACTIONS_CREATE_LOADING_";
              action.isDisabled = true;
              this.createAntragsliste(response[0].pk).finally(
                () => {
                  action.label = "_LBL_ANTRAGSLISTE_TABLE_ACTIONS_CREATE_";
                  action.isDisabled = false;
                }
              );
            };
          }
          if (response.length > 0) {
            this.options.actions.push(action);
          }
        }
      );
    },

    createAntragsliste(versions_id) {
      const data = { versions_id: versions_id };
      this.status.creating = true;
      return this.postHttp({
        url: "antragslisten/",
        data: data,
        showError: true,
      }).then(
        response => {
          this.addNotification({ text: "_MSG_ANTRAGSLISTE_CREATE_SUCCESS_" });
          this.$goTo("root.antragslisten.details", { id: response.pk });
        },
        error => {
          if (error.status !== 429) {
            this.errors = error.data;
          }
        }
      ).finally(
        () => this.status.creating = false
      );
    },

    closeModalCreate() {
      this.modal.create = false;
    },
  },
};
