"use strict";

import BetragsrechnerRegistry from "../services";

import BetragsrechnerRolliplaetze from "./betragsrechner.js";
import BetragsrechnerRolliplaetzeController from "./controller";

const BetragsrechnerRolliplaetzeModul = angular.module("dias.betragsrechner.BetragsrechnerRolliplaetzeModul", [
  BetragsrechnerRegistry.name,
]);

BetragsrechnerRolliplaetzeModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("rolliplaetzerechner", BetragsrechnerRolliplaetze);
})
  .component("rolliplaetze", BetragsrechnerRolliplaetzeController);

export default BetragsrechnerRolliplaetzeModul;
