"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import "angular-chart.js";
import "angular-drag-and-drop-lists";

import diasAuthService from "dias/auth/services";
import diasConfirm from "dias/core/confirm";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";

import foerdermodule from "dias/foerderantrag/foerdermodule";
import foerderregeln from "dias/foerderregeln/models";

import models from "./models";
import regeln from "./regel";
import detailsComponent from "./details.component";


const sachberichtvorlagenModule = angular.module("root.sachberichtvorlagen", [
  uiRouter,
  models.name,
  regeln.name,
  foerdermodule.name,
  foerderregeln.name,
  diasAuthService.name,
  diasConfirm.name,
  diasTable.name,
  diasLoading.name,
  diasNotification.name,
]);

sachberichtvorlagenModule.config($stateProvider => {
  $stateProvider

    .state("root.sachberichtvorlagen", {
      url: `/sachberichtvorlagen/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-sachbericht-list></vue-sachbericht-list></div>"
        }
      },
      data: {
        permissions: ["sachberichtvorlage.view"]
      },
    })

    .state("root.sachberichtvorlagen1", {
      url: `/sachberichtvorlagen1/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-sachbericht-list></vue-sachbericht-list></div>"
        }
      },
      data: {
        permissions: ["sachberichtvorlage.view"]
      },
    })

    .state("root.sachberichtvorlagen1.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<sachberichtvorlagen-details></sachberichtvorlagen-details>"
        }
      },
      data: {
        permissions: ["sachberichtvorlage.view"]
      },
    })

    .state("root.sachberichtvorlagen.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><sachberichtvorlage-details></sachberichtvorlage-details></div>"
        }
      },
      data: {
        permissions: ["sachberichtvorlage.view"]
      },
    })

  ;
})

  .component("sachberichtvorlagenDetails", detailsComponent)
;

export default sachberichtvorlagenModule;
