import KofiErfassungModul from "./KofiErfassungModul/KofiErfassungModul.vue";
import KofiErfassungModulWizard from "./KofiErfassungModulWizard/KofiErfassungModulWizard.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotItem from "../../Snapshot/SnapshotItem/SnapshotItem.vue";

import translate from "../../../global/directives/translate";
import loading from "../../../global/directives/loading";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";
import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import KofiErfassungMixin from "./Mixins/KofiErfassungMixin";
import KVertragskostenbelegStatusUUIDs from "../../../const/KVertragskostenbelegStatusUUIDs";
import KBelegPruefmethodeUUIDs from "../../../const/KBelegPruefmethodeUUIDs";
import {
  FilterCurrencyMixin,
} from "../../../global/mixins/FiltersMixin";
import {
  forEach,
  get,
  sortBy,
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "KofiErfassung",
  components: {
    KofiErfassungModul,
    KofiErfassungModulWizard,
    Modal,
    PuxButton,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
    SnapshotIcon,
    SnapshotItem,
  },
  directives: {
    translate,
    loading,
  },
  mixins: [
    SyConfigMixin,
    HttpMixin,
    PermissionMixin,
    NotificationMixin,
    KofiErfassungMixin,
    FilterCurrencyMixin,
  ],
  props: {
    vertrag: {
      type: Object,
      required: true,
    },
    auszahlung: {
      type: Object,
      required: true,
    },
    auszahlungsplan: {
      type: Object,
      required: false,
      default: undefined,
    },
    antrag: {
      type: Object,
      required: true,
    },
    infoprovider: {
      type: Object,
      required: false,
      default: undefined,
    },
    module: {
      type: Array,
      required: true,
    },
    labelKoFi: {
      type: String,
      required: true,
      validator: value => ["kosten", "finanzierung"].indexOf(value) !== -1,
    },
    reloadStatus: {
      type: Boolean,
      required: false,
    },
    saveCallback: {
      type: Function,
      required: true,
    },
    relevanteAuszahlungen: {
      type: Array,
      required: true,
    },
    relevantePerioden: {
      type: Array,
      required: false,
      default: undefined,
    },
    koFi: {
      type: Array,
      required: true,
    },
    positionen: {
      type: Array,
      required: true,
    },
    koFiStatuses: {
      type: Object,
      required: true,
    },
    gesamtKoFi: {
      type: Object,
      required: false,
      default: undefined,
    },
    belegDokumente: {
      type: Array,
      required: true,
    },
    belegDokumenteAnzahl: {
      type: Object,
      required: true,
      info: "Mapping dok_id zur Anzahl der Belege, in der dieses Dokument benutzt wird",
      default: (() => {}),
    },
    prefixCurrent: {
      type: Object,
      required: true,
    },
    statusReloading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isWizard: {
      type: Boolean,
      required: false,
    },
    diffKofi: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    snapshot: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    snapshotKoFiStatuses: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    modulNumberDifference: {
      type: Number,
      required: false,
      default: 0,
    },
    showHiddenKoFi: {
      type: Boolean,
      required: false,
      default: false,
    },
    showToggleButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    "toggleFilteredKoFi"
  ],
  data() {
    return {
      syConfigsAuszahlung: undefined,
      belegnummerAnzeigen: undefined,
      personalfunktionen: [],
      contextNumber: 4, // TODO:
      KVertragskostenbelegStatusUUIDs: KVertragskostenbelegStatusUUIDs,
      KBelegPruefmethodeUUIDs: KBelegPruefmethodeUUIDs,
      koFiArten: [],
      koFiArtenObj: {},
      statusPositionenShow: true,
    };
  },
  computed: {
    htmlId() {
      return `${ this.labelKoFi }_erfassung`;
    },

    apiBase() {
      if (this.auszahlung) {
        return `${ this.apiVertrag }${ this.apiAuszahlung }`;
      }
      return this.apiVertrag;
    },

    apiKoFi() {
      return `${ this.apiBase }${ this.labelKoFi }/`;
    },

    apiAuszahlung() {
      return `auszahlungen/${ this.auszahlung.pk }/`;
    },

    apiVertrag() {
      return `vertraege/${ this.vertrag.pk }/`;
    },

    apiAntrag() {
      return `foerderantraege/${ this.antrag.pk }/`;
    },

    apiAntragDokumente() {
      return `${ this.apiAntrag }dokumente/?dokart=${ this.dokartCurrent }`;
    },

    apiKoFiArten() {
      if (this.labelKoFi === "kosten") {
        return {
          api: "kostenarten/",
          apiSaveId: "kostenarten"
        };
      } else if (this.labelKoFi === "finanzierung") {
        return {
          api: "finanzierungsarten/",
          apiSaveId: "finanzierungsarten"
        };
      }
      return {};
    },

    keys() {
      return {
        keyPositionBetragBewilligt: this.keyPositionBetragBewilligt,
        keyKoFiBetragBewilligt: this.keyKoFiBetragBewilligt,
        keyBelegBetragErfasst: this.keyBelegBetragErfasst,
        keyBelegBetragAnerkannt: this.keyBelegBetragAnerkannt,
      };
    },

    keyKoFiBetragBewilligt() {
      return `${ this.prefixCurrent.vkofi }_betrag_bewilligt`;
    },

    keyBelegBetragErfasst() {
      return `${ this.prefixCurrent.beleg }_betrag_erfasst`;
    },

    keyBelegBetragAnerkannt() {
      return `${ this.prefixCurrent.beleg }_betrag_anerkannt`;
    },

    keyPositionBetragBewilligt() {
      return `${ this.prefixCurrent.position }_betrag_bewilligt`;
    },

    dokartCurrent() {
      return this.prefixCurrent.dokart;
    },

    headerTranslate() {
      if (this.labelKoFi === "kosten") {
        return "_TXT_KOFI_ERFASSUNG_KOSTEN_HEADER_";
      } else if (this.labelKoFi === "finanzierung") {
        return "_TXT_KOFI_ERFASSUNG_FINANZIERUNG_HEADER_";
      }
      return "";
    },

    kofiArtTranslateText() {
      if (this.labelKoFi === "kosten") {
        return "_TXT_KOFI_ERFASSUNG_WIZARD_KOSTEN_HEADER_";
      } else if (this.labelKoFi === "finanzierung") {
        return "_TXT_KOFI_ERFASSUNG_WIZARD_FINANZIERUNG_HEADER_";
      }
      return "";
    },

    buttonTogglePositionenId() {
      return `${ this.labelKoFi }_positionen_toggle`;
    },

    buttonToggleFilteredKoFiId() {
      return `${ this.labelKoFi }_wizard_toggle`;
    },

    buttonTogglePositionenIcon() {
      return this.statusPositionenShow ?
        "chevron-up" :
        "chevron-down";
    },

    buttonTogglePositionenTranslate() {
      return this.statusPositionenShow ?
        "_BTN_POSITIONEN_VERBERGEN_" :
        "_BTN_POSITIONEN_ZEIGEN_";
    },

    KoFiSorted() {
      return sortBy(this.koFi, [this.keyKoFiPos]);
    },

    moduleFilteredByKofiart() {
      const MODULE_FILTERED_BY_KOFIART = {};
      forEach(this.module, modul => {
        const PK = get(modul, `regel.argument.${ this.prefixCurrent.art }.wert`);
        if (PK) {
          MODULE_FILTERED_BY_KOFIART[PK] = modul;
        }
      });
      return MODULE_FILTERED_BY_KOFIART;
    },

    footerTextTranslate() {
      if (this.labelKoFi === "kosten") {
        return "_TXT_KOFI_ERFASSUNG_SUMME_DER_KOSTENBELEGE_";
      } else if (this.labelKoFi === "finanzierung") {
        return "_TXT_KOFI_ERFASSUNG_SUMME_DER_FINANZIERUNGSBELEGE_";
      }
      return "";
    },

    footerWizardTextTranslate() {
      if (this.labelKoFi === "kosten") {
        return "_TXT_KOFI_ERFASSUNG_WIZARD_SUMME_DER_KOSTENBELEGE_";
      } else if (this.labelKoFi === "finanzierung") {
        return "_TXT_KOFI_ERFASSUNG_WIZARD_SUMME_DER_FINANZIERUNGSBELEGE_";
      }
      return "";
    },

    kofiErfassungModulComponentName() {
      return this.isWizard ?
        "KofiErfassungModulWizard" :
        "KofiErfassungModul";
    },

    snapshotKoFiKeyByPk() {
      return this.snapshot || {};
    },

    idForBewilligt() {
      return `${ this.htmlId }_bewilligt`;
    },

    idForErfasst() {
      return `${ this.htmlId }_erfasst`;
    },

    idForAnerkannt() {
      return `${ this.htmlId }_anerkannt`;
    },

    isSnapshotIconSumVisible() {
      return this.diffKofi.changes.bewilligt ||
        this.diffKofi.changes.erfasst ||
        this.diffKofi.changes.anerkannt;
    },

    showHiddenKoFiIcon() {
      return this.showHiddenKoFi ? "chevron-up" : "chevron-down";
    }
  },
  created() {
    this.checkSyConfig();
    this.loadPersonalfunktionen();
    this.loadKoFiArten();
  },
  methods: {
    checkSyConfig() {
      this.syConfigsAuszahlung = this.getSyConfigsValue("auszahlung");
      const SY_CONFIG_BELEGE_NUMMER = this.getSyConfigsValue("belege_nummer", {});
      this.belegnummerAnzeigen = SY_CONFIG_BELEGE_NUMMER.anzeigen;
    },

    loadPersonalfunktionen() {
      forEach(this.module, modul => {
        if (modul.modulname === "personalkosten_lohnjournal") {
          this.getListHttp({
            url: "katalog/",
            urlParams: {
              key: "personalfunktionen",
              pk: modul.regel.afr_argument.personalfunktionen.wert,
            },
          }).then(
            response => {
              this.personalfunktionen = response;
            }
          );
        }
      });
    },

    loadKoFiArten() {
      this.getListHttp({
        url: this.apiKoFiArten.api,
        apiSaveId: this.apiKoFiArten.apiSaveId,
      }).then(
        response => {
          this.koFiArten = response;
          this.koFiArtenObj = keyBy(response, "pk");
        }
      );
    },

    togglePositionen() {
      this.statusPositionenShow = !this.statusPositionenShow;
    },

    toggleFilteredKoFi() {
      this.$emit("toggleFilteredKoFi");
    },
  },
};
