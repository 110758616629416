import PuxButton from "../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";
import TimelineElement from "./TimelineElement/TimelineElement.vue";

import HttpMixin from "../../global/mixins/HttpMixin";
import PermissionMixin from "../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../global/mixins/SyConfigMixin";

import TimelineOptions from "./TimelineOptions";
import {
  EventBus,
} from "../../global/functions/event-bus";
import {
  cloneDeep,
  filter,
  forEach,
  isEqual,
  isUndefined,
  sortBy,
} from "lodash-es";

// @vue/component
export default {
  name: "Timeline",
  components: {
    PuxButton,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
    TimelineElement,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    workflowTypeDefault: {
      type: String,
      required: false,
      default: undefined,
      info: "Workflow-Typ von Objekt",
    },
  },
  data() {
    return {
      config: {},
      classList: TimelineOptions.options.classList,
      optionsClone: {},
      timelinedata: [],
      timer: undefined,
      statusLoading: true,
      statusLoadingFirst: true,
      statusShowFiltersWorkflowType: false,
      statusHide: {},
      statusHideWorkflowType: {},
      objects: [
        "foerderantrag",
        "antragstellerorganisation",
        "vertrag",
        "auszahlung",
      ],
      filterObjectsList: [],
    };
  },
  computed: {
    statusTermine() {
      return !!this.options.termine;
    },

    filterList() {
      let filterList = cloneDeep(this.options.config.filter) || TimelineOptions.options.filterDefault;
      if (!this.checkPermissionsSync({ perm: ["timeline.ui.notizen"] })) {
        filterList = filter(filterList, el => el.key !== "notizen");
      }
      if (this.options.termine) {
        this.objects.forEach(herkunft => {
          const fromAst = element => element.herkunft === herkunft;
          if (!this.timelinedata.some(fromAst)) {
            filterList = filter(filterList, el => el.key !== herkunft);
          }
        });
      }
      return filterList;
    },
  },
  created() {
    this.initConfig();
    this.checkSyConfig();
    this.loadData();
    this.setIntervalUpdateData();
    this.initEventBus();
  },
  beforeUnmount() {
    clearInterval(this.timer);
    this.destroyEventBus();
  },
  methods: {
    initConfig() {
      const conf = cloneDeep(this.options.config) || {};
      conf.kontexte = conf.kontexte || [];
      conf.emptyText = conf.emptyText || TimelineOptions.options.emptyText;
      this.config = conf;
    },

    checkSyConfig() {
      if (this.statusTermine) {
        return;
      }
      this.statusShowFiltersWorkflowType = this.getSyConfigsValue("timeline", {}).nur_betroffenes_objekt === false;
    },

    setFilterObjectDefault() {
      if (!this.statusShowFiltersWorkflowType || isUndefined(this.workflowTypeDefault)) {
        return;
      }
      forEach(this.filterObjectsList, filter => {
        if (filter.type !== this.workflowTypeDefault) {
          this.statusHideWorkflowType[filter.type] = true;
        }
      });
    },

    loadData() {
      this.statusLoading = true;
      this.getHttp({ url: this.config.url }).then(
        response => {
          this.timelinedata = response;
          this.setFilterObjectsList(response);
          this.setFilterObjectDefault();
        }
      ).finally(
        () => {
          this.statusLoading = false;
          this.statusLoadingFirst = false;
        }
      );
    },

    setIntervalUpdateData() {
      if (!this.config.benachrichtigungen || !this.config.benachrichtigungen.autorefresh) {
        return;
      }
      this.timer = setInterval(this.updateData, TimelineOptions.options.interval);
    },

    updateData() {
      this.getHttp({ url: this.config.url }).then(
        response => {
          const diff = isEqual(this.timelinedata, response);
          if (!diff) {
            this.timelinedata = response;
            this.setFilterObjectsList(response);
          }
        }
      );
    },

    setFilterObjectsList(response) {
      if (!this.statusShowFiltersWorkflowType) {
        return;
      }
      const FILTER_OBJECTS_MAP = {};
      const FILTER_OBJECTS_LIST = [];
      forEach(response, item => {
        const WORKFLOW_TYP_ID = item.workflowtyp.pk;
        if (!FILTER_OBJECTS_MAP[WORKFLOW_TYP_ID]) {
          FILTER_OBJECTS_MAP[WORKFLOW_TYP_ID] = true;
          FILTER_OBJECTS_LIST.push({
            type: WORKFLOW_TYP_ID,
            label: item.workflowtyp.wft_kbez,
            icon: item.workflowtyp.wft_anzeige_icon,
            pos: item.workflowtyp.wft_pos,
          });
        }
      });
      if (FILTER_OBJECTS_LIST.length > 1) { // anzeigen Filter, wenn mehr als ein Filter kommt
        this.filterObjectsList = sortBy(FILTER_OBJECTS_LIST, ["pos"]);
      } else {
        this.filterObjectsList = [];
      }
    },

    initEventBus() {
      EventBus.$on("updateNotizen", this.updateNotizen);
    },

    destroyEventBus() {
      EventBus.$off("updateNotizen", this.updateNotizen);
    },

    toggleFilter(name) {
      this.statusHide[name] = !this.statusHide[name];
    },

    toggleFilterContentType(content_type) {
      this.statusHideWorkflowType[content_type] = !this.statusHideWorkflowType[content_type];
    },

    updateNotizen({ no_key } = {}) {
      if (no_key === "verlauf") {
        this.updateData();
      }
    },
  },
};
