"use strict";

class DiasErrorsController {
  /*@ngInject*/
  constructor($title, $message, $state) {
    this.$title = $title;
    this.$message = $message;
    $state.go("root.home");
  }
}

export default DiasErrorsController;
