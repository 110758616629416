import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import AuswahlKatalogCheckboxEdit from "./AuswahlKatalogCheckboxEdit/AuswahlKatalogCheckboxEdit.vue";
import AuswahlKatalogCheckboxReadonly from "./AuswahlKatalogCheckboxReadonly/AuswahlKatalogCheckboxReadonly.vue";
import AuswahlKatalogSelectEdit from "./AuswahlKatalogSelectEdit/AuswahlKatalogSelectEdit.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import {
  assign,
  cloneDeep,
  find,
  forEach,
  get,
  groupBy,
  isNil,
  keyBy,
  size,
} from "lodash-es";

const SNAPSHOT_CHANGES_LIST = [
  "auswahl",
];

// @vue/component
export default {
  name: "AuswahlKatalog",
  components: {
    SnapshotModule,
    SnapshotIcon,
    FormElement,
    AuswahlKatalogCheckboxEdit,
    AuswahlKatalogCheckboxReadonly,
    AuswahlKatalogSelectEdit,
    PuxButton,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      statusShowUnselected: false,
      status: {
        init: false,
        loading: false
      },
      elements: [],
      groups: {},
      labels: {},
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.open;
      OPTIONS.labels = this.labels;
      return OPTIONS;
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(SNAPSHOT_CHANGES_LIST, key => {
          if (key === "auswahl") {
            const SNAPSHOT = get(this.snapshotModule, key, []);
            const MODEL = get(this.model, key, []) || {};
            forEach(SNAPSHOT, (el, pk) => {
              if (!MODEL[pk] || MODEL[pk].status !== el.status || MODEL[pk].freitext !== el.freitext) {
                DIFF.auswahl = DIFF.auswahl || {};
                DIFF.auswahl[pk] = true;
                DIFF.status = true;
              }
            });
            forEach(MODEL, (el, pk) => {
              if (!SNAPSHOT[pk] || SNAPSHOT[pk].freitext !== el.freitext) {
                DIFF.auswahl = DIFF.auswahl || {};
                DIFF.auswahl[pk] = true;
                DIFF.status = true;
              }
            });
          } else if (get(this.model, key) !== get(this.snapshotModule, key)) {
            DIFF[key] = true;
          }
        });
      }
      return DIFF;
    },

    isStyleCheckboxRadio() {
      return isNil(this.modelParameter.style) || this.modelParameter.style === "checkbox_radio";
    },

    isStyleSelection() {
      return this.modelParameter.style === "select";
    },

    statusDiff() {
      return !!(this.snapshotModule && size(this.diff));
    },

    buttonShowUnselectedId() {
      return `${ this.htmlRegelNummer }_show_unselected`;
    },

    buttonShowUnselectedTooltip() {
      return this.statusShowUnselected ?
        "_BTN_STANDARD_FOERDERMODULE_AUSWAHL_KATALOG_NICHT_AUSGEWAEHLTE_OPTIONEN_AUSBLENDEN_TITLE_" :
        "_BTN_STANDARD_FOERDERMODULE_AUSWAHL_KATALOG_NICHT_AUSGEWAEHLTE_OPTIONEN_EINBLENDEN_TITLE_";
    },

    buttonShowUnselectedIcon() {
      return this.statusShowUnselected ? "eye-close" : "eye";
    },
  },
  methods: {
    prepareModel() {
      const MODEL = cloneDeep(this.module.data) || {};
      MODEL.auswahl = MODEL.auswahl || {};
      this.model = MODEL;
    },

    open() {
      this.modelEdit = cloneDeep(get(this.model, "auswahl")) || {};
    },

    prepareToSave({ data }) {
      const DATA = { auswahl: {} };
      forEach(data, (el, key) => {
        if (el && el.status) {
          DATA.auswahl[key] = {
            status: el.status,
          };
          if (!isNil(el.freitext) && size(el.freitext) > 0) {
            DATA.auswahl[key].freitext = el.freitext;
          }
        }
      });
      return DATA;
    },

    toggleShowUnselected() {
      this.statusShowUnselected = !this.statusShowUnselected;
    },

    initDataLocal({ statusFirstLoad }) {
      this.modelEdit = cloneDeep(get(this.model, "auswahl")) || {};
      if (this.modelParameter.katalog && statusFirstLoad) {
        this.loadData({ katalog: this.modelParameter.katalog });
      }
    },

    auswahlTyp() {
      return this.modelParameter.style === "select" ? (this.modelParameter.mehrfachauswahl === true ? "multiselect" : "select") : (this.modelParameter.mehrfachauswahl === true ? "checkbox" : "radio");
    },

    anySelected(data) {
      return !isNil(data) && !isNil(data.auswahl) && !isNil(find(Object.values(data.auswahl), ["status", true]));
    },

    loadData({ katalog }) {
      this.status.init = true;
      // ohne neue Daten initialisieren
      this.processKatalogData({ response: [] });
      this.getHttp({ url: `katalog/?key=${ katalog }` }).then(
        response => {
          // mit ggf. neuen Daten aktualisieren
          this.processKatalogData({ response });
        }
      ).finally(() => this.status.init = false);
    },

    processKatalogData({ response }) {
      const DATA = [];
      const LABELS = {};
      const MAPPED_KATALOG = keyBy(response, "pk");
      forEach(this.modelParameter.eintraege, el => {
        if (!el.aktiv) {
          return;
        }
        const DATA_EL = assign({}, el, MAPPED_KATALOG[el.pk] || {});
        DATA_EL.uebergeordneter_wert = DATA_EL.uebergeordneter_wert || null;
        if (DATA_EL.kaw_bez) {
          DATA_EL.bez = DATA_EL.kaw_bez;
        }
        LABELS[DATA_EL.pk] = DATA_EL.bez;
        DATA.push(DATA_EL);
        this.getElementModelEdit(DATA_EL);
      });
      this.labels = LABELS;
      this.elements = DATA;
      this.groups = groupBy(DATA, el => el.uebergeordneter_wert);
      forEach(this.elements, el => el.group = LABELS[el.uebergeordneter_wert] || null);
    },

    getElementModelEdit(el, model) {
      if (!model) {
        model = this.modelEdit;
      }
      model[el.pk] = model[el.pk] || { status: false, freitext: undefined };
      return model[el.pk];
    },

    removeElementError(el) {
      if (this.errors && this.errors[el.pk]) {
        delete this.errors[el.pk];
      }
    },

    setElementStatusMultiselect(el, status, model) {
      const MODEL_EL = this.getElementModelEdit(el, model);
      MODEL_EL.status = status;
      if (MODEL_EL.status) {
        // -> Unterelemente aktivieren
        forEach(this.groups[el.pk], ul => {
          const MODEL_UEL = this.getElementModelEdit(ul, model);
          if (MODEL_UEL.status) {
            return;
          }
          MODEL_UEL.status = true;
          this.setElementStatusMultiselect(ul, MODEL_UEL.status, model);
        });
        // -> Oberelemente aktivieren, wenn noch nicht aktiv
        let curUbergeordnet = el.uebergeordneter_wert;
        while (curUbergeordnet) {
          const OL = find(this.elements, ["pk", curUbergeordnet]);
          if (OL) {
            curUbergeordnet = OL.uebergeordneter_wert;
            const MODEL_OEL = this.getElementModelEdit(OL, model);
            MODEL_OEL.status = true;
          } else {
            break;
          }
        }
      // Element Inaktiv gesetzt
      } else {
        // -> Unterlemente deaktivieren
        forEach(this.groups[el.pk], ul => {
          const MODEL_UEL = this.getElementModelEdit(ul, model);
          if (!MODEL_UEL.status) {
            return;
          }
          MODEL_UEL.status = false;
          this.removeElementError(ul);
          this.setElementStatusMultiselect(ul, MODEL_UEL.status, model);
        });
        // -> Wenn kandere aktiven geschwister, Ober-Element deaktivieren
        if (el.uebergeordneter_wert) {
          const OL = find(this.elements, ["pk", el.uebergeordneter_wert]);
          let anyOtherSelected = false;
          forEach(this.groups[el.uebergeordneter_wert], other => {
            if (other === el) {
              return;
            }
            const MODEL_OTHER = this.getElementModelEdit(other, model);
            anyOtherSelected = anyOtherSelected || MODEL_OTHER.status;
          });
          if (!anyOtherSelected) {
            this.setElementStatusMultiselect(OL, false, model);
          }
        }
      }
    },

    setElementStatusSingleselect(el, status, model) {
      const MODEL_EL = this.getElementModelEdit(el, model);
      MODEL_EL.status = status;
      if (MODEL_EL.status) {
        // -> Alle anderen deaktivieren
        forEach(this.elements, other => {
          if (other.pk === el.pk) {
            return;
          }
          const MODEL_OTHER = this.getElementModelEdit(other, model);
          MODEL_OTHER.status = false;
          this.removeElementError(other);
        });
        // -> Oberelemente aktivieren, wenn noch nicht aktiv
        let curUbergeordnet = el.uebergeordneter_wert;
        while (curUbergeordnet) {
          const OL = find(this.elements, ["pk", curUbergeordnet]);
          if (OL) {
            curUbergeordnet = OL.uebergeordneter_wert;
            const MODEL_OEL = this.getElementModelEdit(OL, model);
            MODEL_OEL.status = true;
          } else {
            break;
          }
        }
      } else {
        // -> Unterlemente deaktivieren
        forEach(this.groups[el.pk], ul => {
          const MODEL_UEL = this.getElementModelEdit(ul, model);
          MODEL_UEL.status = false;
          this.removeElementError(ul);
          this.setElementStatusSingleselect(ul, MODEL_UEL.status, model);
        });
      }
    },

    onToggle(el) {
      const NEW_MODEL = cloneDeep(this.modelEdit);
      const MODEL_EL = this.getElementModelEdit(el, NEW_MODEL);
      if (this.modelParameter.mehrfachauswahl) {
        this.setElementStatusMultiselect(el, !MODEL_EL.status, NEW_MODEL);
      } else {
        this.setElementStatusSingleselect(el, !MODEL_EL.status, NEW_MODEL);
      }
      this.modelEdit = NEW_MODEL;
      this.removeElementError(el);
    },

    onFreitext(el, $event) {
      const MODEL = this.getElementModelEdit(el);
      MODEL.freitext = $event.target.value;
      this.removeElementError(el);
    },

  },
};
