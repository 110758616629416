"use strict";

import angular from "angular";

import parameterBewertung from "./bewertung";
import parameterRating from "./rating";

const parameter = angular.module("dias.bewertungen.parameter", [
  parameterBewertung.name,
  parameterRating.name,
])
;

export default parameter;
