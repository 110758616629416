import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";

import { get } from "lodash-es";

// @vue/component
export default {
  name: "AufgabenvorlagenDetailsBerechtigungenTableRowEdit",
  components: {
    FormElement,
    PuxIcon,
  },
  props: {
    funktionsgruppenKey: {
      type: String,
      required: true,
    },
    funktionsgruppenMap: {
      type: Object,
      required: true,
    },
    selectedRollen: {
      type: Array,
      required: true,
    },
    beobachterRolle: {
      type: Object,
      required: false,
      default: () => {},
    },
    bearbeiterRolle: {
      type: Object,
      required: false,
      default: () => {},
    },
    formstepHtmlId: {
      type: String,
      required: true,
    }
  },
  emits: [
    "removeFunktionsgruppe",
    "changeRollenBerechtigung",
  ],
  data() {
    return {
      confirmOptions: {},
      showModalConfirmRemove: false,
    };
  },
  computed: {
    beobachterRollePk() {
      return this.beobachterRolle.pk;
    },

    bearbeiterRollePk() {
      return this.bearbeiterRolle.pk;
    },

    buttonRemoveId() {
      return `button_remove_${ this.funktionsgruppenKey }`;
    },

    testClass() {
      return `test_button_remove_${ this.funktionsgruppenKey }`;
    },

    selectorClose() {
      return [
        `#${ this.buttonRemoveId }`,
        `#${ this.formstepHtmlId }`
      ];
    },

    idCheckboxBearbeiter() {
      return `checkbox_bearbeiter_${ this.funktionsgruppenKey }`;
    },

    idCheckboxBeobachter() {
      return `checkbox_beobachter_${ this.funktionsgruppenKey }`;
    },

    hasFunktionsgruppeBearbeiter() {
      return get(this.funktionsgruppenMap, `${ this.funktionsgruppenKey }.${ this.bearbeiterRollePk }.bearbeiter`, false);
    },

    hasFunktionsgruppeBeobachter() {
      return get(this.funktionsgruppenMap, `${ this.funktionsgruppenKey }.${ this.bearbeiterRollePk }.beobachter`, false);
    },
  },
  methods: {
    changeBearbeiterBerechtigung() {
      this.$emit("changeRollenBerechtigung", { key: this.funktionsgruppenKey, type: "bearbeiter" });
    },

    changeBeobachterBerechtigung() {
      this.$emit("changeRollenBerechtigung", { key: this.funktionsgruppenKey, type: "beobachter" });
    },

    openModalConfirmRemove() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_AUFGABENVORLAGENDETAILS_CONFIRM_REMOVE_FUNKTIONSGRUPPE_{{name}}_",
        msg: "_MSG_AUFGABENVORLAGENDETAILS_CONFIRM_REMOVE_FUNKTIONSGRUPPE_{{name}}_",
        extra: {
          name: this.funktionsgruppenMap[this.funktionsgruppenKey].obj.name,
        },
        okLabel: "_TXT_AUFGABENVORLAGENDETAILS_BUTTON_TOOLTIP_REMOVE_",
        okCallback: this.removeFunktionsgruppe,
        cancelCallback: this.closeModalConfirmRemove,
        loading: false,
      };
      this.showModalConfirmRemove = true;
    },

    closeModalConfirmRemove() {
      this.showModalConfirmRemove = false;
    },

    removeFunktionsgruppe() {
      this.$emit("removeFunktionsgruppe", { key: this.funktionsgruppenKey });
      this.closeModalConfirmRemove();
    },
  },
};
