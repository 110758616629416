// @vue/component
export default {
  name: "StyleguideTableOptionsCols",
  data() {
    return {
      cols: [
        {
          label: "label",
          type: "String",
          default: "undefined",
          description: "Anzeigetext der Spalte.",
          required: true,
          example: `label: "Titel",`,
          options: "",
        },
        {
          label: "id",
          type: "String",
          default: "undefined",
          description: "Id des Inhalts der Spalte, 'Sortable'-Parameter.",
          required: true,
          example: `id: "nws_titel",`,
          options: "",
        },
        {
          label: "sortable",
          type: "Boolean",
          default: "undefined",
          description: "Angabe ob die Spalte sortierbar ist.",
          required: false,
          example: `sortable: true,`,
          options: `"false", "true"`,
        },
        {
          label: "sortingDefaultDirection",
          type: "String",
          default: "undefined",
          description: "Maximal einmal in Cols.",
          required: false,
          example: `sortingDefaultDirection: "desc",`,
          options: `"desc", "asc"`,
        },
        {
          label: "templateCallback",
          type: "Funktion",
          default: "undefined",
          description: "Zum Einfügen von Variablen in den Anzeigetext.",
          required: false,
          example: `templateCallback: ({ row }) => row.instrumentvariante_obj ? row.instrumentvariante_obj.fiv_kbez : "",`,
          options: "",
        },
        {
          label: "filter",
          type: "String",
          default: "undefined",
          description: "Nutzung eines Filters.",
          required: false,
          example: `filter: "date"`,
          options: `"date", "currency", "boolean", "trunc", "iban", "file-size", "key-value", "limit-to"`,
        },
        {
          label: "filterParameter",
          type: "String",
          default: "undefined",
          description: "Nutzung eines Filterparameters.",
          required: false,
          example: `filterParameter: "DD. MMMM YYYY, HH:mm [Uhr]"`,
          options: "",
        },
        {
          label: "hide",
          type: "Boolean",
          default: "undefined",
          description: "Gibt an ob Spalte sichtbar ist oder nicht.",
          required: false,
          example: `hide: true,`,
          options: "",
        },
        {
          label: "className",
          type: "String",
          default: "undefined",
          description: "Klasse für das TD-Element einer Tabelle.",
          required: false,
          example: `className: "text-right",`,
          options: "",
        },
        {
          label: "headerClassName",
          type: "String",
          default: "undefined",
          description: "Klasse für das TH-Element einer Tabelle.",
          required: false,
          example: `headerClassName: "text-right",`,
          options: "",
        },
        {
          label: "slot",
          type: "String",
          default: "undefined",
          description: "Dynamisches Template für TD Elemente.",
          required: false,
          example: `<div>slot: "for_kbez"</div>
                    <br>
                    <div>template(<br>
                    &nbsp;&nbsp;v-slot:for_kbez="slotProps"<br>
                    )<br>
                    &nbsp;&nbsp;a(<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;:href="'/foerderangebote/' + slotProps.data.pk +'/'"<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;@click.stop<br>
                    &nbsp;&nbsp;) {{ slotProps.data.foe_kbez }}</div>`,
          options: "",
        },
      ],
    };
  },
};
