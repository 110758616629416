import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import Alert from "../../../../global/components/Alert/Alert.vue";
import {
  cloneDeep,
  forEach,
  toNumber,
} from "lodash-es";

const SNAPSHOT_CHANGES_LIST = [
  "zahl1",
  "zahl2",
];

// @vue/component
export default {
  name: "ZahlenVerhaeltnis",
  components: {
    Alert,
    SnapshotModule,
    SnapshotIcon,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      modelLocal: undefined,
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.openCallbackLocal;
      OPTIONS.list = [
        {
          id: "zahl1",
          htmlId: `${ this.htmlRegelNummer }_zahl1`,
          type: "integer",
          label: this.modelParameter.label_zahl1,
          required: false,
          view: "v",
          editonly: true,
          startupFocus: true,
          change: this.changeInput,
        },
        {
          id: "zahl2",
          htmlId: `${ this.htmlRegelNummer }_zahl2`,
          type: "integer",
          label: this.modelParameter.label_zahl2,
          required: false,
          view: "v",
          editonly: true,
          change: this.changeInput,
        },
        {
          id: "hinweis",
          htmlId: `${ this.htmlRegelNummer }_hinweis`,
          type: "template",
          required: false,
          slot: "hinweis",
          editonly: true,
        },
      ];
      return OPTIONS;
    },

    labelHeader() {
      return this.modelParameter.frage;
    },

    diff() {
      const SNAPSHOT = {
        changes: {},
        status: false,
      };
      if (this.statusSnapshot) {
        const MODEL = this.model || {};
        const SNAPSHOT_MODULE = this.snapshotModule || {};
        forEach(SNAPSHOT_CHANGES_LIST, key => {
          if (MODEL[key] !== SNAPSHOT_MODULE[key]) {
            SNAPSHOT.changes[key] = true;
            SNAPSHOT.status = true;
          }
        });
      }
      return SNAPSHOT;
    },

    statusDiff() {
      return this.diff.status;
    },

    hinweis() {
      return this.getHinweis();
    },

    hinweisEdit() {
      return this.getHinweis({ model: this.modelLocal });
    },
  },
  methods: {
    openCallbackLocal() {
      this.modelLocal = cloneDeep(this.model) || {};
    },

    getHinweis({ model } = {}) {
      const MODEL = model || this.model || {};
      if (!MODEL.zahl2 || !MODEL.zahl1) {
        return;
      }
      const PERCENT = toNumber(MODEL.zahl2) / toNumber(MODEL.zahl1) * 100;
      if (PERCENT < this.modelParameter.prozent_min) {
        return this.modelParameter.hinweis_min;
      } else if (PERCENT > this.modelParameter.prozent_max) {
        return this.modelParameter.hinweis_max;
      }
    },

    changeInput({ id, model }) {
      this.modelLocal[id] = model;
    },
  },
};
