import BetragsrechnerMixin from "../Mixins/BetragsrechnerMixin";

// @vue/component
export default {
  name: "FinanzierungMitLandBetragsrechner",
  mixins: [
    BetragsrechnerMixin,
  ],
  emits: [
    "isBezeichnung",
  ],
  data() {
    return {
      labelLand: "_LBL_FINANZIERUNG_MIT_LAND_LAND_",
      labelBezeichnung: "_LBL_FINANZIERUNG_MIT_LAND_BEZEICHNUNG_",
      labelWert: "_LBL_FINANZIERUNG_MIT_LAND_WERT_",
    };
  },
  computed: {
    optionsLand() {
      return {
        id: "land",
        htmlId: this.htmlIdLand,
        type: "select",
        required: true,
        view: this.view,
        label: this.labelLand,
        classesHorizontal: this.classesHorizontal,
        keyId: "pk",
        keyLabel: "lkz_bez",
        search: true,
        searchList: ["lkz_bez"],
        placeholder: "_TXT_FINANZIERUNG_DETAILLIERT_LAND_PLACEHOLDER_",
        url: "katalog/",
        urlParams: {
          key: "klaenderkuerzel",
        },
        apiSaveId: "klaenderkuerzel",
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdLand() {
      return `${ this.htmlId }_land`;
    },

    optionsBezeichnung() {
      return {
        id: "bezeichnung",
        htmlId: this.htmlIdBezeichnung,
        label: this.labelBezeichnung,
        view: this.view,
        maxlength: 300,
        type: "text",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdBezeichnung() {
      return `${ this.htmlId }_bezeichnung`;
    },

    optionsWert() {
      return {
        id: "wert",
        htmlId: this.htmlIdWert,
        label: this.labelWert,
        view: this.view,
        maxlength: 14,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdWert() {
      return `${ this.htmlId }_wert`;
    },

    errorLabels() {
      return {
        land: {
          label: this.labelLand,
          link: true,
          id: this.htmlIdLand,
        },
        bezeichnung: {
          label: this.labelBezeichnung,
          link: true,
          id: this.htmlIdBezeichnung,
        },
        wert: {
          label: this.labelWert,
          link: true,
          id: this.htmlIdWert,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
    this.$emit("isBezeichnung");
  },
};
