import Modal from "../../../../global/components/Modal/Modal.vue";

import WorkflowModulBaseMixin from "../WorkflowModulBaseMixin";

// @vue/component
export default {
  components: {
    Modal,
  },
  mixins: [
    WorkflowModulBaseMixin,
  ],
  data() {
    return {
      parameterIn: undefined,
      confirmOptions: undefined,
    };
  },
  computed: {
    buttonTypeClass() {
      const TYPE = this.workflowfolge.ausgabe_typ || "primary";
      return `btn btn-${ TYPE }`;
    },
  },
  created() {
    this.initData();
    this.startModul();
    this.setConfirmOptions();
  },
  methods: {
    initData() {
      this.parameterIn = this.prepareParameterIn();
    },

    setConfirmOptions() {
      this.confirmOptions = {
        okClass: this.buttonTypeClass,
        title: this.parameterIn.title,
        msg: this.parameterIn.msg,
        okLabel: this.workflowfolge.wfo_kbez,
        okCallback: this.save,
        cancelCallback: this.cancelWorkflowfolge,
        loading: false,
      };
    },

    save() {
      this.confirmOptions.loading = true;
      this.finish().then(
        response => {
          this.startWorkflowfolge(response);
        },
        errors => {
          this.startWorkflowfolge(errors);
        }
      ).finally(
        () => this.confirmOptions.loading = false
      );
    },
  },
};
