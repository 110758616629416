import Modal from "../../Modal/Modal.vue";
import TaskLog from "../../TaskLog/TaskLog.vue";
import HttpMixin from "../../../mixins/HttpMixin";

// @vue/component
export default {
  name: "TableModalWorkflowMassActionsTaskLog",
  components: {
    Modal,
    TaskLog,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    titel: {
      type: String,
      default: undefined,
    },
    url: {
      type: String,
      default: "foerderantraege/",
    },
    data: {
      type: Object,
      default: () => ({
        objects: [],
      }),
    },
  },
  data() {
    return {
      options: {
        showCloseButton: false,
      },
      status: {
        loading: undefined,
      },
      taskId: undefined,
      result: undefined,
      resultType: undefined,
    };
  },
  computed: {
    getAlertClass() {
      return `alert-${ this.resultType }`;
    },
  },
  created() {
    this.onChangeMassenStatus();
  },
  methods: {
    onChangeMassenStatus() {
      this.postHttp({ url: `${ this.url }massenstatuswechsel/`, data: this.data }).then(
        response => {
          this.taskId = response;
        }
      );
    },

    onSuccess({ result }) {
      if (result.fail === 0) {
        this.resultType = "success";
      } else {
        this.resultType = "warning";
      }
      this.result = result;
    },

    onFailure({ result }) {
      this.resultType = "danger";
      this.result = result.data;
    },

    onClose() {
      this.close();
    },
  },
};
