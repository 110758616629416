import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import FunktionsgruppenAssignment from "../../Funktionsgruppen/FunktionsgruppenAssignment/FunktionsgruppenAssignment.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import RollenBasisdaten from "./RollenBasisdaten/RollenBasisdaten.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../global/compositionAPI/NotificationAPI";

// @vue/component
export default {
  name: "RollenDetails",
  components: {
    ContextPanel,
    FunktionsgruppenAssignment,
    Modal,
    Permission,
    PuxButton,
    PuxPageDetails,
    PuxTranslate,
    RollenBasisdaten,
  },
  setup() {
    const {
      deleteHttp,
      getHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      deleteHttp,
      getHttp,
    };
  },
  data() {
    return {
      loading: true,
      rolle: {},
      funktionsgruppen: undefined,
      confirmShow: undefined,
      confirmOptions: undefined,
      btnDeleteId: "rollen_details_modal_delete",
      panelBasisdatenOptions: {
        label: "_TXT_ROLLE_DETAILS_ROLLE_KONTEXT_",
        icon: "glyphicon-file",
        active: true,
        pk: "rolle",
      },
      panelFunktionsgruppenOptions: {
        label: "_TXT_ROLLE_DETAILS_FNKTNSGRP_KONTEXT_",
        icon: "glyphicon-file",
        active: false,
        pk: "rollen",
      },
    };
  },
  computed: {
    rollenUrl() {
      return `rollen/${ this.$stateParams.id }/`;
    },

    funktionsgruppenUrl() {
      return `${ this.rollenUrl }funktionsgruppen/`;
    },

    selectorClose() {
      return `#${ this.btnDeleteId }`;
    },
  },
  created() {
    this.loadRolle();
  },
  methods: {
    loadRolle() {
      this.getHttp({
        url: this.rollenUrl,
      }).then(
        response => {
          this.rolle = response;
        }
      ).then(
        () => {
          this.loadFunktionsgruppen();
        }
      );
    },

    loadFunktionsgruppen() {
      this.getHttp({
        url: this.funktionsgruppenUrl,
      }).then(
        response => {
          this.funktionsgruppen = response;
        }
      ).then(
        () => this.loading = false
      );
    },

    updateFunktionsgruppen({ funktionsgruppenLocal }) {
      this.funktionsgruppen = funktionsgruppenLocal;
    },

    confirmDeleteRolle() {
      if (this.rolle.num_users) {
        this.confirmOptions = {
          okClass: "btn btn-primary",
          title: "_TXT_ROLLE_DETAILS_TITLE_",
          msg: "_TXT_ROLLE_DETAILS_DELETE_MODAL_WITH_USERS_BODY_{{numUsers}}_{{rolle}}_",
          cancelCallback: this.closeConfirm,
          cancelLabel: "_BTN_ROLLE_DETAILS_CLOSE_",
          extra: {
            numUsers: this.rolle.num_users,
            rolle: this.rolle.kbez,
          },
          loading: false,
        };
      } else {
        this.confirmOptions = {
          okClass: "btn btn-primary",
          title: "_TXT_ROLLE_DETAILS_DELETE_MODAL_WITHOUT_USERS_TITLE_{{kbez}}_",
          msg: `_TXT_ROLLE_DETAILS_DELETE_MODAL_WITHOUT_USERS_BODY_{{kbez}}_`,
          okLabel: "_BTN_ROLLE_DETAILS_OK_LABEL_",
          okCallback: this.deleteRolle,
          cancelCallback: this.closeConfirm,
          extra: {
            kbez: this.rolle.kbez,
          },
          loading: false,
        };
      }
      this.confirmShow = true;
    },

    deleteRolle() {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: this.rollenUrl,
      }).then(
        () => {
          this.addNotification({ text: "_MSG_ROLLE_DETAILS_DELETE_SUCCESS_" });
          this.closeConfirm();
          this.$goTo("root.rollenverwaltung");
        },
        () => {
          this.addNotification({ text: "_MSG_ROLLE_DETAILS_DELETE_ERROR_", type: "error" });
          this.closeConfirm();
        },
      ).then(
        () => this.confirmOptions.loading = false
      );
    },

    closeConfirm() {
      this.confirmShow = false;
    },
  },
};
