import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import getTableApi from "../../../const/TableApis";
import { gettext } from "../../../global/functions/utils";

const TABLE_ID = "SitzungsartenTable";

// @vue/component
export default {
  name: "SitzungsartenList",
  components: {
    AngularLink,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [
          {
            label: "_LBL_SITZUNGSARTEN_LIST_NAME_",
            id: "sia_kbez",
            sortable: true,
            sortId: "sia_kbez",
            sortingDefault: "asc",
            slot: "sia_kbez",
          },
          {
            label: "_LBL_SITZUNGSARTEN_LIST_BESCHREIBUNG_",
            id: "sia_bez",
            sortable: true,
            sortId: "sia_bez",
          },
          {
            label: "_LBL_SITZUNGSARTEN_LIST_TURNUS_IN_TAGEN_",
            id: "sia_turnus",
            sortable: true,
            sortId: "sia_turnus",
          },
          {
            label: "_LBL_SITZUNGSARTEN_LIST_NUMMER_NAECHSTEN_SITZUNG_",
            id: "sia_nrsitz",
            sortable: true,
            sortId: "sia_nrsitz",
          },
        ],
        rowActions: [
          {
            label: "_LBL_SITZUNGSARTEN_LIST_ACTIONS_ROW_DETAILS_",
            route: "root.sitzungsarten.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        label: "_LBL_SITZUNGSARTEN_LIST_SITZUNGSARTEN_",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      filters: [
        {
          type: "text",
          id: "sia_kbez",
          label: gettext("_LBL_SITZUNGSARTEN_LIST_NAME_"),
          main: true,
        },
        {
          type: "text",
          id: "sia_kbez",
          label: gettext("_LBL_SITZUNGSARTEN_LIST_NAME_"),
          alwaysVisible: true,
        },
        {
          type: "text",
          id: "sia_bez",
          label: gettext("_LBL_SITZUNGSARTEN_LIST_BESCHREIBUNG_"),
        },
      ],
    };
  },
};
