var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["schaltflaechen"] = jade_interp = function(visible){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div id=\"always-visible\" class=\"actions right panel_always_visible\"><button type=\"button\" ng-if=\"vm.formular.herkunft === 'foerderantrag'\" class=\"btn btn-icon float-left\"><span class=\"glyphicon glyphicon-chevron-left\"></span><a ui-sref=\"root.foerderantrag.details({id: vm.objekt.pk})\" ng-attr-title=\"{{vm.gettext('Antrag') }} Nr. {{ vm.objekt.a_nr | spaced:true }}\"><span>Zum {{ vm.gettext(\"Antrag\") }} Nr.  <spaced ng-model=\"vm.objekt.a_nr\"></spaced></span></a></button><fieldset ng-disabled=\"vm.reload\"><div vueinit props=\"{ baseUrl: vm.formularUrl, aufgabe: vm.formular.aktuelle_aufgabe, onStarted: vm.onWorkflowStarted, onCanceled: vm.onWorkflowCanceled, onInit: vm.onWorkflowInit, disabled: vm.reload, updateAufgabe: vm.updateAufgabeFromVue }\" class=\"d-inline-block\"><workflow-folgen v-bind:base-url=\"propsAngular.baseUrl\" v-bind:aufgabe=\"propsAngular.aufgabe\" v-bind:on-started=\"propsAngular.onStarted\" v-bind:on-canceled=\"propsAngular.onCanceled\" v-bind:on-init=\"propsAngular.onInit\" v-bind:disabled=\"propsAngular.disabled\" v-on:update-aufgabe=\"propsAngular.updateAufgabe\"></workflow-folgen></div><div ng-if=\"vm.viewName === 'details'\" class=\"d-inline-block position-relative\"><button type=\"button\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\" ng-if=\"vm.permissions[vm.editPerm]\" class=\"btn btn-default\"><span class=\"glyphicon glyphicon-option-vertical\"></span><span> Weitere Aktionen</span></button><ul class=\"dropdown-menu dropdown_menu_spacing\"><li ng-if=\"vm.permissions[vm.editPerm]\"><a role=\"button\" tabindex=\"0\" ng-click=\"vm.onValidate()\"><span>Vollständigkeit prüfen</span></a></li></ul><span vueinit props=\"{objUrl: 'formulare/' + vm.formular.pk}\"><template-rendering v-bind:obj-url=\"propsAngular.objUrl\"></template-rendering></span></div></fieldset></div>");
};
buf.push("<div dias-cloak=\"vm.loading.formular\" cloak-size=\"'lg'\" cloak-msg=\"vm.gettext('Formular') + ' wird geladen'\"><div dias-loading=\"vm.loading.deleting\" loading-msg=\"vm.gettext('Formular') + ' wird gelöscht'\"><section class=\"main row no-margin\"><section class=\"main-dias application-form\"><header class=\"page-title header-detail\"><div vueinit props=\"{ name: vm.formular.name }\"><page-tab-title v-bind:placeholder=\"'_PAGE_TITLE_UMFRAGEFORMULAR_{{name}}_'\" v-bind:extra=\"propsAngular\"></page-tab-title></div><h1><div>{{ vm.formular.name }}</div><small ng-if=\"vm.formular.name\">{{ vm.formular.label }}{{vm.formular.infix }} <spaced ng-model=\"vm.formular.for_objektname\"></spaced></small></h1></header>");
jade_mixins["schaltflaechen"]();
buf.push("<div ng-if=\"vm.viewName === 'details'\" class=\"main-details\"><div vueinit props=\"{ formular: vm.formular, saveCallback: vm.saveCallback, setPanelsForNotizen: vm.setPanelsForNotizen, dokBaseUrl: vm.dokBaseUrl, validationErrors: vm.validationErrors, validationErrorsLoading: vm.validationErrorsLoading, resetValidation: vm.resetValidation, canViewFormularobjekt: vm.canViewFormularobjekt, vertragId: vm.v_id }\"><formular-details-body v-bind:formular=\"propsAngular.formular\" v-bind:status-rubrik-fixed=\"true\" v-bind:dok-base-url=\"propsAngular.dokBaseUrl\" v-bind:set-panels-for-notizen=\"propsAngular.setPanelsForNotizen\" v-bind:validation-errors=\"propsAngular.validationErrors\" v-bind:validation-errors-loading=\"propsAngular.validationErrorsLoading\" v-bind:reset-validation=\"propsAngular.resetValidation\" v-bind:can-view-formularobjekt=\"propsAngular.canViewFormularobjekt\" v-bind:vertrag-id=\"propsAngular.vertragId\" v-bind:save-callback=\"propsAngular.saveCallback\"></formular-details-body></div></div></section><aside id=\"aside-focus\" sticky tabindex=\"-1\" aside-init class=\"fixed fixed-scroll fixed_vertical\"><button id=\"btn-aside\" type=\"button\" data-toggle=\"aside\" title=\"Seitenleiste ausblenden\" class=\"btn btn-default btn-aside\"><span class=\"glyphicon glyphicon-einreichen\"></span></button><div class=\"aside-overflow\"><div vueinit props=\"{ relevantObj: vm.formular.aktuelle_aufgabe, relevantObjName: vm.formular.formulartyp_bez }\"><w-f-status-gruppen v-bind:relevant-obj=\"propsAngular.relevantObj\" v-bind:relevant-obj-name=\"propsAngular.relevantObjName\"></w-f-status-gruppen></div><div ng-if=\"vm.formular.aktuelle_aufgabe.task_id\" class=\"box\"><dias-task-log task=\"vm.formular.aktuelle_aufgabe.task_id\" on-success=\"vm.onWorkflowSuccess\" on-failure=\"vm.onWorkflowFailure\" on-finish=\"vm.onWorkflowFinish\"></dias-task-log></div></div></aside></section></div></div>");;return buf.join("");
}