"use strict";
// import moment from "moment";
import BaseModel from "dias/core/models/baseModel";

class Profil extends BaseModel {
  constructor() {
    super("profil", {
      subPaths: { antragstellerorganisationen: "antragstellerorganisationen",
                  organisationen: "organisationen",
                  vertretungen: "vertretungen",
                  organisationsnutzer: "organisationsnutzer"
      }
    });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.fetch = () => collection.one("").get().then(
      profil => {
        collection.restangularizeElement(profil);
        return profil;
      }
    );

    return collection;
  }

  extendModel(model) {
    model = super.extendModel(model);
    model.getFullName = this.getFullName.bind(model);
    model.getFullNameWithTitle = this.getFullNameWithTitle.bind(model);
    model.antragstellerorganisationen_freigegeben = model.all(
      "antragstellerorganisationen/?freigegeben="
    );

    return model;
  }

  getFullName() {
    const parts = [];
    if (this.n_vorname) {
      parts.push(this.n_vorname); 
    }
    if (this.n_nachname) {
      parts.push(this.n_nachname); 
    }
    if (parts.length === 0) {
      parts.push(this.n_loginname); 
    }
    return parts.join(" ");
  }
  getFullNameWithTitle() {
    const parts = [];
    if (this.n_titel) {
      parts.push(this.n_titel); 
    }
    if (this.n_vorname) {
      parts.push(this.n_vorname); 
    }
    if (this.n_nachname) {
      parts.push(this.n_nachname); 
    }
    if (parts.length === 0) {
      parts.push(this.n_loginname); 
    }
    return parts.join(" ");
  }
}

export default Profil;
