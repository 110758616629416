import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import ModuleMixin from "../ModuleMixin";

import {
  toFormElementFromParameter,
} from "../../../../global/functions/mappingForParameterToFormElement";

import {
  cloneDeep,
  findIndex,
  forEach,
  get,
  isEmpty,
  isEqual,
  isNil,
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "Bewertung",
  components: {
    FormElementReadOnly,
    SnapshotModule,
    SnapshotIcon,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      formFields: undefined,
      formFieldsMap: undefined,
    };
  },
  computed: {
    formstepOptions() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.list = this.formFields;
      OPTIONS.showReadonlyInEdit = true;
      return OPTIONS;
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(this.formFields, item => {
          const KEY = item.key || item.id;
          if (!isEqual(get(this.model, KEY), get(this.snapshotModule, KEY))) {
            DIFF[KEY] = true;
          }
        });
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },

    statusReadonlyModelSerializer() {
      return this.statusReadonly || findIndex(this.formFields, el => !el.readonly) === -1;
    },

    isModuleEditable() {
      return !this.statusReadonlyModelSerializer;
    },
  },
  watch: {
    "module.form_fields"() {
      this.initFormFields();
    },
  },
  created() {
    this.initFormFields();
  },
  methods: {
    initFormFields() {
      this.buildFormFields();
      this.initFormFieldsLocal();
    },
    initFormFieldsLocal() {},
    buildFormFields() {
      if (isNil(this.module.form_fields)) {
        return;
      }
      const FORM_ELEMENT_OPTIONS = toFormElementFromParameter({
        obj: this.module.form_fields,
        editonly: true,
        showLabel: true,
        htmlIdPrefix: this.htmlRegelNummer,
        staticOptions: {
          showEmpty: "_TXT_KEINE_ANGABE_",
        },
        staticOptionsForType: {
          select: {
            showEmpty: "_LBL_UI_SELECT_ELEMENT_EMPTY_",
          },
          multiselect: {
            showEmpty: "_LBL_UI_SELECT_ELEMENT_EMPTY_",
          },
        }
      }).list;
      FORM_ELEMENT_OPTIONS[0].startupFocus = true;
      forEach(FORM_ELEMENT_OPTIONS, el => {
        el.editComponent = el.readonly ? "FormElementReadOnly" : "FormElement";
      });
      this.formFields = FORM_ELEMENT_OPTIONS;
      this.formFieldsMap = keyBy(this.formFields, field => field.id);
    }
  },
};
