"use strict";

import ElementverzweigungReadOnlyController from "./readonly.controller";

class ElementverzweigungController extends ElementverzweigungReadOnlyController {
  /*@ngInject*/
  constructor(DiasNotification, Katalog) {
    super(Katalog);
    this.DiasNotification = DiasNotification;
    this.initData();
    this.regelAddSelection = undefined;
    this.currentChoices = [];
  }

  initData() {
    if (this.parameter[this.key].wert === undefined || this.parameter[this.key].wert === null) {
      this.parameter[this.key].wert = [];
    }
  }

  loadRegeln() {
    super.loadRegeln().then(() => {
      this.updateChoices();
    });
  }

  updateChoices() {
    this.currentChoices.splice(
      0,
      this.currentChoices.length,
      ...this.regelChoices.filter(v => this.parameter[this.key].wert.indexOf(v.value) === -1)
    );
  }

  removeRegel(index) {
    this.parameter[this.key].wert.splice(index, 1);
  }

  moveUpRegel(index) {
    if (index === 0) {
      return;
    }
    const elem = this.parameter[this.key].wert[index];
    const previous = this.parameter[this.key].wert[index - 1];
    this.parameter[this.key].wert[index - 1] = elem;
    this.parameter[this.key].wert[index] = previous;
  }

  moveDownRegel(index) {
    if (index >= this.parameter[this.key].wert.length - 1) {
      return;
    }
    const elem = this.parameter[this.key].wert[index];
    const next = this.parameter[this.key].wert[index + 1];
    this.parameter[this.key].wert[index + 1] = elem;
    this.parameter[this.key].wert[index] = next;
  }

  isDisabled() {
    return !this.regelAddSelection;
  }

  addRegel() {
    if (!this.regelAddSelection[0]) {
      return;
    }
    this.addToList(this.parameter[this.key].wert, this.regelAddSelection[0]);
    this.regelAddSelection = [];
    this.updateChoices();
  }

  addToList(list, el) {
    const alreadyIn = list.find(v => v === el.value);
    if (alreadyIn) {
      this.DiasNotification.page.error(`Regel "${ el.label }" ist bereits in der Liste.`);
      return false;
    }
    list.push(el.value);
    return true;
  }
}

export default ElementverzweigungController;
