"use strict";

import BaseModel from "dias/core/models/baseModel";

class FoerderorgTyp extends BaseModel {
  constructor() {
    super("foerderorgtypen");
  }

  configRestangular(RestangularConfigurer) {
    RestangularConfigurer.setDefaultHttpFields({ cache: true });
  }
}

export default FoerderorgTyp;
