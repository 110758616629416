import AngularLink from "../../../../../../libs/vue/client/vue/global/components/AngularLink/AngularLink.vue";
import ContextPanel from "../../../../../../libs/vue/client/vue/global/components/ContextPanel/ContextPanel.vue";
import DeleteStudiengangModal from "./DeleteStudiengangModal/DeleteStudiengangModal.vue";
import DokumentDownload from "../../../../../../libs/vue/client/vue/components/Dokumente/DokumentDownload/DokumentDownload.vue";
import Permission from "../../../../../../libs/vue/client/vue/global/components/Permission/Permission.vue";
import PuxDropdown from "../../../../../../libs/vue/client/vue/global/components/PuxDropdown/PuxDropdown.vue";
import PuxButton from "../../../../../../libs/vue/client/vue/global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../../../libs/vue/client/vue/global/components/PuxIcon/PuxIcon.vue";
import PuxPageDetails from "../../../../../../libs/vue/client/vue/global/components/PuxPageDetails/PuxPageDetails.vue";
import StudiengangDetailsKopieErstellen from "./StudiengangDetailsKopieErstellen/StudiengangDetailsKopieErstellen.vue";
import StudiengangDetailsHochschule from "./StudiengangDetailsHochschule/StudiengangDetailsHochschule.vue";
import StudiengangDetailsOverview from "./StudiengangDetailsOverview/StudiengangDetailsOverview.vue";
import StudiengangDetailsTeilstudiengaenge from "./StudiengangDetailsTeilstudiengaenge/StudiengangDetailsTeilstudiengaenge.vue";
import TeilStudiengangModalHinzufuegen from "./TeilStudiengangModalHinzufuegen/TeilStudiengangModalHinzufuegen.vue";
import TeilstudiengaengeZuordnungModal from "./TeilstudiengaengeZuordnungModal/TeilstudiengaengeZuordnungModal.vue";

import HttpMixin from "../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import PermissionMixin from "../../../../../../libs/vue/client/vue/global/mixins/PermissionMixin";

import KStudiengangartUUIDs from "../../../../../../libs/vue/client/vue/const/KStudiengangartUUIDs";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "StudiengangDetails",
  components: {
    AngularLink,
    ContextPanel,
    DeleteStudiengangModal,
    DokumentDownload,
    Permission,
    PuxButton,
    PuxIcon,
    PuxDropdown,
    PuxPageDetails,
    StudiengangDetailsHochschule,
    StudiengangDetailsKopieErstellen,
    StudiengangDetailsOverview,
    StudiengangDetailsTeilstudiengaenge,
    TeilStudiengangModalHinzufuegen,
    TeilstudiengaengeZuordnungModal,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  data() {
    return {
      loading: false,
      loadingShortcut: false,
      studiengang: {},
      antragShortcuts: [],
      mantelstudiengang: undefined,
      showStudiengangCopy: false,
      modal: {
        TeilstudiengangZuordnenModal: false,
        TeilstudiengangHinzufuegenModal: false,
        DeleteStudiengangModal: false,
      },
    };
  },
  computed: {
    studiengangUrl() {
      return `studiengaenge/${ this.$stateParams.id }/`;
    },

    hasLaufendeVerfahren() {
      return this.studiengang.laufende_verfahren.length > 0;
    },

    isTeilstudiengang() {
      return (this.studiengang.art === KStudiengangartUUIDs.TEIL);
    },

    rasterApiCallUrl() {
      return `${ this.studiengangUrl }raster/`;
    },

    extraForPageTitleTranslate() {
      return {
        sg_bez: this.studiengang.sg_bez,
      };
    },

    showTeilstudiengaenge() {
      return (this.studiengang && this.studiengang.art === KStudiengangartUUIDs.KOMBI);
    },

    isStudiengangDeletable() {
      return (!this.isTeilstudiengang && this.studiengang.deletable);
    },

    showKombinationsstudiengangLink() {
      return (this.hasPermissionStudiengangView && this.isTeilstudiengang && this.mantelstudiengaenge);
    },

    hasPermissionStudiengangUpdate() {
      return this.checkPermissionsSync({
        perm: "studiengang.update",
      });
    },

    hasPermissionStudiengangView() {
      return this.checkPermissionsSync({
        perm: "studiengang.view",
      });
    },

    lehramtstypKbez() {
      return this.studiengang.lehramtstyp_kbez ? `(${ this.studiengang.lehramtstyp_kbez }), ` : null;
    },

    hasKopiervorlage() {
      // "hasKopiervorlage" means this is already a copy. Copy of a copy raise 500er
      return !!this.studiengang.kopiervorlage_bez;
    },

    weitereAktionenDropdownMenuButtonHtmlId() {
      return "weitere_aktionen_dropdown_menu_button";
    },

    selectorCloseModal() {
      return `#${ this.weitereAktionenDropdownMenuButtonHtmlId }`;
    }
  },
  created() {
    this.loadStudiengang();
  },
  methods: {
    loadStudiengang() {
      this.loading = true;
      this.getHttp({
        url: this.studiengangUrl,
      }).then(
        response => {
          this.studiengang = response;
          this.loadFoerderantragErstellenOptionen();
          if (this.studiengang.art === KStudiengangartUUIDs.TEIL) {
            this.loadMantelstudiengaenge();
          } else {
            this.loading = false;
          }
        }
      );
    },

    loadMantelstudiengaenge() {
      this.getHttp({
        url: `${ this.studiengangUrl }mantelstudiengaenge/`,
      }).then(
        response => {
          if (response[0]) {
            this.mantelstudiengang = response[0].pk;
          }
          this.loading = false;
        },
      );
    },

    loadFoerderantragErstellenOptionen() {
      this.getHttp({
        url: `${ this.studiengangUrl }foerderantrag_erstellen_optionen/`
      }).then(
        response => {
          this.antragShortcuts = response;
        }
      );
    },

    executeAntragErstellenAktionen({ antragShortcut }) {
      this.loadingShortcut = true;
      this.postHttp({
        url: `${ this.studiengangUrl }foerderantrag_erstellen/`,
        data: antragShortcut,
      }).then(
        response => {
          this.$goTo("root.foerderantrag.details", { id: response.a_id });
          this.addNotification({ text: "Aktion erfolgreich ausgeführt" });
        },
        error => {
          this.addNotification({
            text: `Fehler beim Ausführen der Aktion. ${ error.data || "" }`,
            type: "error",
          });
        },
      ).finally(
        () => this.loadingShortcut = false,
      );
    },

    updateStudiengang(response) {
      this.studiengang = Object.assign({}, this.studiengang, response);
    },

    openModalTeilstudiengangZuordnen() {
      this.modal.TeilstudiengangZuordnenModal = true;
    },

    closeModalTeilstudiengangZuordnen() {
      this.modal.TeilstudiengangZuordnenModal = false;
    },

    openModalTeilstudiengangHinzufuegen() {
      this.modal.TeilstudiengangHinzufuegenModal = true;
    },

    closeModalTeilstudiengangHinzufuegen() {
      this.modal.TeilstudiengangHinzufuegenModal = false;
    },

    openDeleteStudiengangModal() {
      this.modal.DeleteStudiengangModal = true;
    },

    closeDeleteStudiengangModal() {
      this.modal.DeleteStudiengangModal = false;
    },

    updateVersionen() {
      const data = cloneDeep(this.studiengang);
      this.putHttp({ url: `studiengaenge/${ this.studiengang.pk }/update_versionen/`, data }).then(
        response => {
          this.addNotification({ text: `${ response.count } Studiengangsversionen aktualisiert` });
        },
        () => {
          this.addNotification({ text: "Fehler beim Aktualisieren der Studiengangsversionen" });
        }
      );
    },

    toggleShowCopy() {
      this.showStudiengangCopy = !this.showStudiengangCopy;
    },
  }
};
