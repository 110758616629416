"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import "restangular";
import "ng-infinite-scroll";
import sucheComponent from "./suche.component.js";
import ngAnimate from "angular-animate";
import models from "./models";

const sucheModule = angular.module("dias.suche", [
  ngAnimate,
  uiRouter,
  models.name,
  "restangular",
  "infinite-scroll"
]);

sucheModule.config($stateProvider => {
  $stateProvider

    .state("root.suche", {
      url: "/suche/",
      views: {
        "main@root": {
          template: "<dias-suche></dias-suche>",
        }
      },
      data: {
        permissions: "suche.view"
      }
    });
})

  .component("diasSuche", sucheComponent);

export default sucheModule;
