import moment from "moment";
import translate from "../../../../../global/directives/translate";
import {
  FilterDateMixin,
} from "../../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "DurchfuehrungszeitraumLine",
  directives: {
    translate,
  },
  mixins: [
    FilterDateMixin,
  ],
  props: {
    period: {
      type: Object,
      required: true,
    },
    durchfuehrung: {
      type: Object,
      required: true,
    },
    statusAntrag: {
      type: Boolean,
      required: true,
    },
    statusVertrag: {
      type: Boolean,
      required: true,
    },
    start: {
      type: Boolean,
      required: true,
    },
    up: {
      type: Boolean,
      required: true,
    },
    down: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    stylesAntrag() {
      return this.getStyles({ objectName: "antrag" });
    },

    stylesVertrag() {
      return this.getStyles({ objectName: "vertrag" });
    },

    beginPeriodMin() {
      return moment(this.period.pe_von);
    },

    endPeriodMax() {
      return moment(this.period.pe_bis);
    },

    countDays() {
      return this.endPeriodMax.diff(this.beginPeriodMin, "days");
    },

    titleTranslateAntrag() {
      return {
        title: this.titlePlaceholderAntrag,
        extra: {
          begin: this.getBeginWithFilter({ objectName: "antrag" }),
          end: this.getEndWithFilter({ objectName: "antrag" }),
        },
      };
    },

    titleTranslateVertrag() {
      return {
        title: this.titlePlaceholderVertrag,
        extra: {
          begin: this.getBeginWithFilter({ objectName: "vertrag" }),
          end: this.getEndWithFilter({ objectName: "vertrag" }),
        },
      };
    },

    titlePlaceholderAntrag() {
      if (this.start) {
        return "_TXT_PERSONAL_KOSTEN_PERIODE_D_ZEITRAUM_LINE_TITLE_BEGIN_{{begin}}_";
      }
      return "_TXT_PERSONAL_KOSTEN_PERIODE_D_ZEITRAUM_LINE_TITLE_ENDE_{{end}}_";
    },

    titlePlaceholderVertrag() {
      if (this.start) {
        return "_TXT_PERSONAL_KOSTEN_PERIODE_D_ZEITRAUM_LINE_VERTRAG_TITLE_BEGIN_{{begin}}_";
      }
      return "_TXT_PERSONAL_KOSTEN_PERIODE_D_ZEITRAUM_LINE_VERTRAG_TITLE_ENDE_{{end}}_";
    },

    statusVertragLocal() {
      if (!this.statusVertrag) {
        return false;
      }
      if (!this.statusAntrag) {
        return true;
      }
      if (this.start) {
        if (this.getBegin({ objectName: "antrag" }) !== this.getBegin({ objectName: "vertrag" })) {
          return true;
        }
      } else {
        if (this.getEnd({ objectName: "antrag" }) !== this.getEnd({ objectName: "vertrag" })) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    getStyles({ objectName }) {
      if (this.start) {
        const BEGIN_PERIOD = moment(this.getBegin({ objectName }));
        const BEGIN_COUNT_DAYS = BEGIN_PERIOD.diff(this.beginPeriodMin, "days");
        const LEFT = BEGIN_COUNT_DAYS * 100 / this.countDays;
        return {
          left: `${ LEFT }%`,
          right: "auto",
        };
      }
      const END_PERIOD = moment(this.getEnd({ objectName }));
      const END_COUNT_DAYS = this.endPeriodMax.diff(END_PERIOD, "days");
      const RIGHT = END_COUNT_DAYS * 100 / this.countDays;
      return {
        left: "auto",
        right: `${ RIGHT }%`,
      };
    },

    getBegin({ objectName }) {
      return this.durchfuehrung[objectName].von;
    },

    getBeginWithFilter({ objectName }) {
      return this.filterDate(this.getBegin({ objectName }));
    },

    getEnd({ objectName }) {
      return this.durchfuehrung[objectName].bis;
    },

    getEndWithFilter({ objectName }) {
      return this.filterDate(this.getEnd({ objectName }));
    },
  },
};
