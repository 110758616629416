var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :ctx.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold with-border\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "ctx.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in ctx.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<div class=\"modal-body height-270\"><div class=\"row\"><section class=\"main col-10 offset-1\"><div ng-attr-id=\"{{ ctx.DiasNotification.form.containerId }}\"></div><span ng-if=\"ctx.users.length === 0\" class=\"help-block\"><ul class=\"errorlist\"><li>Es stehen keine weiteren Benutzer zur Auswahl zur Verfügung.</li></ul></span><form><fieldset>");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<ui-select name=\"nutzer\" multiple ng-model=\"ctx.modelUsers\" class=\"select-max\"><ui-select-match placeholder=\"Bitte wählen Sie einen Benutzer\" allow-clear=\"true\"><span class=\"max-span-relativ\"><span style=\"white-space: normal;\" class=\"width-auto\">{{$item.nutzer_obj.name}} ({{ $item.foerderorg_obj.o_name }})</span></span></ui-select-match><ui-select-choices repeat=\"user.pk as user in (ctx.users | filter: $select.search)\"><span>{{:: user.nutzer_obj.name }} ({{:: user.foerderorg_obj.o_name }})</span></ui-select-choices></ui-select>");
}
}, "nutzer", "Benutzer *");
buf.push("</fieldset></form></section></div></div><div class=\"modal-footer\"><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button type=\"submit\" ng-click=\"exitWithCallback(ctx.saveTeilnehmer)\" ng-disabled=\"loading || !ctx.modelUsers || !ctx.modelUsers.length\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><a ng-click=\"exit()\" ng-disabled=\"loading\" class=\"btn btn-default\"><span>Abbrechen</span></a></div></div></div>");;return buf.join("");
}