import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragWarningBoxAntragstellerGesperrt",
  components: {
    AsideRightBox,
    PuxTranslate,
  },
  props: {
    antrag: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optionsAsideRightBoxWarning: {
        icon: "warning",
        toggle: false,
      },
    };
  },
  computed: {
    isAntragstellerGesperrt() {
      return get(this.antrag, "antragstellerorg_obj.ast_gesperrt") === true;
    },

    astSperrbemerkung() {
      return get(this.antrag, "antragstellerorg_obj.ast_sperrbemerkung");
    },
  },
};
