import {
  computed,
  toRef,
} from "vue";

import Permission from "../../../../../global/components/Permission/Permission.vue";
import PuxGet from "../../../../../global/components/PuxGet/PuxGet.vue";
import SitzungsartTagesordnungEditButtonModal from "../SitzungsartTagesordnungEditButtonModal/SitzungsartTagesordnungEditButtonModal.vue";

// @vue/component
export default {
  name: "SitzungsartTagesordnungItem",
  components: {
    Permission,
    PuxGet,
    SitzungsartTagesordnungEditButtonModal,
  },
  props: {
    tagesordnungspunkt: {
      type: Object,
      required: true,
    },
    tagesordnungspunktIndex: {
      type: Number,
      required: true,
    },
    tagesordnungspunktParentIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
    tagesordnungspunktParent: {
      type: Object,
      required: false,
      default: undefined,
    },
    tagesordnungspunkteUrl: {
      type: String,
      required: true,
    },
    sitzungsmoduleKeyByPk: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const tagesordnungspunkt = toRef(props, "tagesordnungspunkt");
    const tagesordnungspunktParent = toRef(props, "tagesordnungspunktParent");
    const number = computed(() => {
      if (tagesordnungspunktParent.value) {
        return `${ tagesordnungspunktParent.value.sto_pos }.${ tagesordnungspunkt.value.sto_pos }`;
      }
      return `${ tagesordnungspunkt.value.sto_pos }`;
    });

    const optionsFormstepDetail = {
      hideHeader: true,
    };

    return {
      number,
      optionsFormstepDetail,
    };
  },
};
