import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";

import WorkflowModulBaseMixin from "../WorkflowModulBaseMixin";

import {
  assignIn,
  get,
  trim,
} from "lodash-es";

// @vue/component
export default {
  name: "WorkflowModulEinreichenBestaetigung",
  components: {
    FormElement,
    Modal,
    PuxCloak,
  },
  mixins: [
    WorkflowModulBaseMixin,
  ],
  data() {
    return {
      loading: undefined,
      loadingErklaerung: true,
      error: undefined,
      parameterIn: undefined,
      checked: false,
    };
  },
  computed: {
    options() {
      return {
        showCloseButton: true,
        required: true,
      };
    },

    optionsForCheckbox() {
      return {
        id: "chk_accept",
        type: "one_checkbox",
        view: "v-alt",
        autoFocus: true,
        label: this.parameterIn.checkbox_label,
      };
    },

    urlForErklaerung() {
      return `foerderantraege/${ this.aufgabe.obj_id }/module/`;
    },

    disabledButton() {
      return this.loading || this.loadingErklaerung || this.error || !this.checked;
    },

    testClassForButtonSave() {
      return `test_modal_${ trim(this.workflowfolge.wfo_kbez) }`;
    },

    ariaChecked() {
      return this.checked ? "true" : "false";
    },
  },
  created() {
    this.startModul();
    this.initData();
    this.loadErklaerung();
  },
  methods: {
    initData() {
      this.parameterIn = this.prepareParameterIn();
    },

    loadErklaerung() {
      this.getListHttp({
        url: this.urlForErklaerung,
        urlParams: {
          gruppe: "erklaerung",
        },
      }).then(
        response => {
          if (response.length >= 1) {
            this.extendParameterInWithResponse(response[0]);
            this.error = false;
          } else {
            this.addNotification({
              text: "_MSG_WF_FOLGEN_ERKLAERUNG_NICHT_GELADEN_ERROR_",
              type: "error",
            });
            this.error = true;
          }
        },
        error => {
          this.DiasNotification.form.error(error);
          this.error = true;
        }
      ).finally(
        () => this.loadingErklaerung = false
      );
    },

    extendParameterInWithResponse(responseItem) {
      const ARGUMENT = get(responseItem, "regel.argument", {});
      const NEW_PARAMETER = this.prepareParameterIn(ARGUMENT);
      this.parameterIn = assignIn({}, this.parameterIn, NEW_PARAMETER);
    },

    save() {
      this.loading = true;
      this.finish().then(
        response => {
          this.startWorkflowfolge(response);
        },
        () => {
          this.error = true;
        }
      ).finally(
        () => this.loading = false
      );
    },
  },
};
