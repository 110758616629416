"use strict";

import BaseModel from "dias/core/models/baseModel";

class Aspekte extends BaseModel {
  constructor() {
    super("aspekte");
  }
}

export default Aspekte;
