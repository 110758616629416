import AuszahlungWizardMoneyInfo from "../AuszahlungWizardMoneyInfo/AuszahlungWizardMoneyInfo.vue";
import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../../global/compositionAPI/PermissionAPI";

import {
  forEach,
  get,
  keyBy,
  orderBy,
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungUebersichtStep",
  components: {
    AuszahlungWizardMoneyInfo,
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    auszahlung: {
      type: Object,
      required: true,
    },
    auszahlungsplan: {
      type: Object,
      required: true,
    },
    urlAuszahlung: {
      type: String,
      required: true,
    },
    updateAuszahlungAfterModalitaetChange: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const {
      getHttp,
      postHttp,
    } = HttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      checkPermissionsSync,
      getHttp,
      postHttp,
    };
  },
  data() {
    return {
      auszahlungModalitaetChoices: [],
      auszahlungModalitaetKeyByPk: {},
      modelAuszahlungModalitaet: undefined,
      modelAuszahlungModalitaetDefault: undefined,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        hideHeader: true,
      };
    },

    htmlId() {
      return `auszahlung_uebersicht_${ this.auszahlung.pk }`;
    },

    extraForTranslate() {
      return {
        az_referenznr: this.auszahlung.az_referenznr,
        regelsatz_kbez: this.auszahlung.regelsatz_kbez,
      };
    },

    isAuszahlungModalitaetChanged() {
      return this.modelAuszahlungModalitaet !== this.modelAuszahlungModalitaetDefault;
    },

    urlAuszahlungModalitaeten() {
      return `${ this.urlAuszahlung }modalitaet_wechsel_choices/`;
    },

    urlAuszahlungModalitaetenChange() {
      return `${ this.urlAuszahlung }modalitaet_wechsel_execute/`;
    },

    canChangeModalitaet() {
      return this.checkPermissionsSync({
        perm: "auszahlung.modalitaet.wechsel",
        permArray: this.auszahlung.user_permissions
      });
    },

    auszahlungModalitaetChoicesSorted() {
      return orderBy(this.auszahlungModalitaetChoices, ["zp_nummer"]);
    },

    currentModalitaetFrist() {
      return this.currentModalitaet.auszahlungsfrist;
    },

    currentModalitaetDisplay() {
      return this.currentModalitaet.display;
    },

    currentModalitaet() {
      return this.auszahlungModalitaetKeyByPk[this.modelAuszahlungModalitaet] || {};
    },
  },
  created() {
    this.initAuszahlungModalitaetChoices();
    this.setAuszahlungModalitaetKeyByPk();
    this.loadAuszahlungModalitaeten();
    this.initModelWFType();
  },
  methods: {
    initAuszahlungModalitaetChoices() {
      this.auszahlungModalitaetChoices = [
        {
          pk: this.auszahlungsplan.pk,
          name: this.auszahlungsplan.name,
          display: this.auszahlungsplan.display,
          zp_nummer: this.auszahlungsplan.zp_nummer,
          auszahlungsfrist: this.auszahlungsplan.auszahlungsfrist,
        },
      ];
    },

    setAuszahlungModalitaetKeyByPk() {
      this.auszahlungModalitaetKeyByPk = keyBy(this.auszahlungModalitaetChoices, "pk");
    },

    loadAuszahlungModalitaeten() {
      if (this.canChangeModalitaet) {
        this.getHttp({
          url: this.urlAuszahlungModalitaeten,
        }).then(
          response => {
            this.addToAuszahlungModalitaetChoices(response);
            this.setAuszahlungModalitaetKeyByPk();
          }
        );
      }
    },

    addToAuszahlungModalitaetChoices(response) {
      forEach(response, item => {
        this.auszahlungModalitaetChoices.push({
          pk: item.pk,
          name: item.name,
          display: item.display,
          zp_nummer: item.zp_nummer,
          auszahlungsfrist: item.auszahlungsfrist,
        });
      });
    },

    initModelWFType() {
      this.modelAuszahlungModalitaet = get(this.auszahlungsplan, "pk");
      this.modelAuszahlungModalitaetDefault = this.modelAuszahlungModalitaet;
    },

    leavingStep() {
      return new Promise((resolve, reject) => {
        if (this.isAuszahlungModalitaetChanged) {
          this.postHttp({
            url: this.urlAuszahlungModalitaetenChange,
            data: {
              auszahlungsplan: this.modelAuszahlungModalitaet,
            },
          }).then(
            () => {
              this.modelAuszahlungModalitaetDefault = this.modelAuszahlungModalitaet;
              this.updateAuszahlungAfterModalitaetChange().then(
                () => resolve()
              );
            },
            () => reject()
          );
        } else {
          resolve();
        }
      });
    },
  },
};
