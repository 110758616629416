import FaqDetailsItemAudio from "./FaqDetailsItemAudio/FaqDetailsItemAudio.vue";
import FaqDetailsItemChart from "./FaqDetailsItemChart/FaqDetailsItemChart.vue";
import FaqDetailsItemHtml from "./FaqDetailsItemHtml/FaqDetailsItemHtml.vue";
import FaqDetailsItemImage from "./FaqDetailsItemImage/FaqDetailsItemImage.vue";
import FaqDetailsItemVideo from "./FaqDetailsItemVideo/FaqDetailsItemVideo.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";

import {
  assign
} from "lodash-es";

// @vue/component
export default {
  name: "FaqDetailsItem",
  components: {
    FaqDetailsItemAudio,
    FaqDetailsItemChart,
    FaqDetailsItemHtml,
    FaqDetailsItemImage,
    FaqDetailsItemVideo,
    FormstepDetail,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      componentsMapping: {
        audio: "FaqDetailsItemAudio",
        chart: "FaqDetailsItemChart",
        html: "FaqDetailsItemHtml",
        image: "FaqDetailsItemImage",
        video: "FaqDetailsItemVideo",
        undefined: "FaqDetailsItemHtml",
      },
    };
  },
  computed: {
    optionForFormstepDetail() {
      return assign({}, this.options, {
        label: this.data.fqe_frage,
      });
    },

    idForFormstepDetail() {
      return `faq_item_${ this.data.pk }`;
    },
  },
};
