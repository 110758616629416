import {
  computed,
  ref,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";
import SyConfigAPI from "../../../../global/compositionAPI/SyConfigAPI";

import FoerderangebotAnzeigekontextUUIDs from "../../../../const/FoerderangebotAnzeigekontextUUIDs";
import {
  findIndex,
  forEach,
  get,
} from "lodash-es";

export default function AntragKofiAPI({
  isAntragEntschieden,
  hasPerioden,
  antrag,
  antragUrl,
  reloadAntrag = () => {},
}) {
  const zuschussinfo = ref({});
  const antragZuschussUrl = computed(() => {
    return `${ antragUrl.value }zuschuss/`;
  });
  const {
    getHttp,
  } = HttpAPI();
  const updateZuschussinfo = () => {
    // Die Zuschussinformationen müssen und dürfen nur ermittelt werden,
    // wenn der Antrag noch nicht entschieden ist:
    if (!isAntragEntschieden.value ||
      (hasPerioden.value && !antrag.value.a_zuschussbeantragt_data)) {
      getHttp({
        url: antragZuschussUrl.value,
      }).then(
        response => {
          zuschussinfo.value = response;
        }
      );
    } else if (antrag.value && hasPerioden.value) {
      zuschussinfo.value = antrag.value.a_zuschussbeantragt_data;
    }
  };
  const updateinfoprovider = ({ response }) => {
    zuschussinfo.value = Object.assign({}, zuschussinfo.value, response);
  };
  const reloadKofi = ref(false);
  const reloadAntragKofi = () => {
    reloadKofi.value = true;
    reloadAntrag().finally(() => reloadKofi.value = false);
  };

  const kostenModule = ref([]);
  const kostenModuleNotKf = ref([]);
  const finanzierungModule = ref([]);
  const finanzierungModuleNotKf = ref([]);
  const loadedModuleForRubric_step_finanz = ({ response }) => {
    const KOSTEN_MODULE = [];
    const KOSTEN_MODULE_NOT_KF = [];
    const FINANZIERUNG_MODULE = [];
    const FINANZIERUNG_MODULE_NOT_KF = [];

    forEach(response, module => {
      const ANZEIGEKONTEXT = module.regel.anzeigekontext;
      if (ANZEIGEKONTEXT === FoerderangebotAnzeigekontextUUIDs.kosten) {
        if (module.modulgruppen.indexOf("kosten") !== -1) {
          KOSTEN_MODULE.push(module);
        } else {
          KOSTEN_MODULE_NOT_KF.push(module);
        }
      } else if (ANZEIGEKONTEXT === FoerderangebotAnzeigekontextUUIDs.finanzierung) {
        if (module.modulgruppen.indexOf("finanzierung") !== -1) {
          FINANZIERUNG_MODULE.push(module);
        } else {
          FINANZIERUNG_MODULE_NOT_KF.push(module);
        }
      }
    });

    kostenModule.value = KOSTEN_MODULE;
    kostenModuleNotKf.value = KOSTEN_MODULE_NOT_KF;
    finanzierungModule.value = FINANZIERUNG_MODULE;
    finanzierungModuleNotKf.value = FINANZIERUNG_MODULE_NOT_KF;
    updateZuschussinfo();
  };

  const {
    getSyConfigsValue,
  } = SyConfigAPI();
  const kofiConfig = computed(() => {
    return getSyConfigsValue("kofi_config", {});
  });
  const kofiUebersichtAnzeigen = computed(() => {
    return kofiConfig.value.uebersicht_anbieten;
  });

  const {
    checkPermissionsSync,
  } = PermissionAPI();
  const hasWFPermissionKoFiUpdate = computed(() => {
    return checkPermissionsSync({
      perm: [
        "foerderantrag.update",
        "foerderantragkosten.update",
        "foerderantragfinanzierung.update",
      ],
      permArray: antrag.value.user_permissions,
    });
  });

  const isRubricKostenFinanzierung = ({ rubric }) => {
    const RUBRIC_MODULE = rubric.axr_config.module;
    return RUBRIC_MODULE.indexOf("kosten-finanzierung") !== -1;
  };

  const updateKoFiModule = ({ response }) => {
    const REGEL_PK = get(response, "regel.pk");
    forEach([
      kostenModule,
      kostenModuleNotKf,
      finanzierungModule,
      finanzierungModuleNotKf,
    ], modules => {
      const CURRENT_MODULES = modules.value;
      const INDEX = findIndex(CURRENT_MODULES, ["regel.pk", REGEL_PK]);
      if (INDEX !== -1) {
        modules.value.splice(INDEX, 1, response);
        return false;
      }
    });
  };


  return {
    finanzierungModule,
    finanzierungModuleNotKf,
    hasWFPermissionKoFiUpdate,
    isRubricKostenFinanzierung,
    kofiUebersichtAnzeigen,
    kostenModule,
    kostenModuleNotKf,
    loadedModuleForRubric_step_finanz,
    reloadAntragKofi,
    updateinfoprovider,
    updateKoFiModule,
    zuschussinfo,
  };
}
