"use strict";

import template from "./kategorie.button.jade";

class CategoriesButtonController {
  /*@ngInject*/
  constructor($state, $sce, i18n) {
    this.gettext = i18n.gettext;
    this.$sce = $sce;
    this.$state = $state;
    this.hoverStatus = undefined;
  }

  hoverButton(hoverStatus, fkw_id) {
    this.hoverStatus = hoverStatus;
    if (this.hover) {
      this.hover(hoverStatus, fkw_id);
    }
  }

  onClick() {
    if (this.readonly || this.disabled || !this.click) {
      return;
    }
    this.click();
  }

  getClasses() {
    return {
      active: this.selected,
      hover: this.hoverStatus,
      "button-svg-img": this.kategoriewert.fkw_image || this.kategoriewert.fkw_svg,
      "no-image": !this.kategoriewert.fkw_image && !this.kategoriewert.fkw_svg,
      disabled: this.disabled
    };
  }

  getSvg(svg) {
    if (!svg) {
      return "";
    }
    return this.$sce.trustAsHtml(svg);
  }
}

export default {
  template: template(),
  controller: CategoriesButtonController,
  controllerAs: "vm",
  bindings: {
    kategoriewert: "<",
    selected: "<",
    readonly: "<",
    disabled: "<",
    title: "@",
    click: "&",
    hover: "&"
  }
};
