import PuxDropdown from "../../PuxDropdown/PuxDropdown.vue";
import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";
import TableViewTableCellButton from "./TableViewTableCellButton/TableViewTableCellButton.vue";
import TableViewTableCellLink from "./TableViewTableCellLink/TableViewTableCellLink.vue";
import TableViewTableCellLinkAngular from "./TableViewTableCellLinkAngular/TableViewTableCellLinkAngular.vue";

import PermissionMixin from "../../../mixins/PermissionMixin";

import {
  getIdForTableRowAction,
  getIdForTableRowDropdown,
} from "../../../functions/utils";
import {
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "TableViewTableCellActions",
  components: {
    PuxDropdown,
    PuxTranslate,
    TableViewTableCellButton,
    TableViewTableCellLink,
    TableViewTableCellLinkAngular,
  },
  mixins: [
    PermissionMixin,
  ],
  props: {
    indexRow: {
      type: Number,
      default: undefined,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
    disabledObj: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    actionId() {
      return getIdForTableRowAction({
        tableId: this.options.id,
        index: this.indexRow,
      });
    },

    dropdownId() {
      return getIdForTableRowDropdown({
        tableId: this.options.id,
        index: this.indexRow,
      });
    },

    getList() {
      return filter(this.options.rowActions, i => {
        let isPermission = true;
        let isHidden = false;
        if (i.permission) {
          isPermission = this.checkPermissionsSync({ perm: i.permission });
        }
        if (i.isHidden) {
          isHidden = i.isHidden({ item: this.row });
        }
        return isPermission && !isHidden;
      });
    },

    isDisabled() {
      return this.disabledObj[this.row[this.options.rowId]];
    },
  },
};
