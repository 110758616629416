import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import { CalendarObjectTypesObj } from "../CalendarObjectTypes";
import createEventList from "./createEventList";
import moment from "moment";
import {
  cloneDeep,
  findIndex,
  forEach,
  reject,
} from "lodash-es";

// @vue/component
export default {
  name: "CalendarModalCreateOrEditEvent",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
      required: true,
    },
    meeting: {
      type: Object,
      default: undefined,
    },
    filteredCalendarObjectTypes: {
      type: Array,
      required: true,
    },
    offentlichAllowed: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      model: {
        private: true,
        allDay: false,
        objekt_id: undefined,
        ter_von: undefined,
        ter_bis: undefined,
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    headerTextTranslate() {
      return this.meeting ? "_TXT_CALENDAR_MODAL_EDIT_HEADER_{{titel}}_" : "_TXT_CALENDAR_MODAL_CREATE_HEADER_";
    },

    headerExtraTranslate() {
      return this.meeting ?
        {
          titel: this.meeting.ter_titel,
        } :
        {};
    },
  },
  created() {
    this.prepareOptionsList();
    this.initModel();
  },
  methods: {
    prepareOptionsList() {
      let EVENT_LIST_FILTERED = cloneDeep(createEventList);
      if (this.offentlichAllowed !== true) {
        EVENT_LIST_FILTERED = reject(EVENT_LIST_FILTERED, { id: "offentlich" });
      }
      const CONTENT_TYPE_ID_INDEX = findIndex(EVENT_LIST_FILTERED, ["id", "content_type_id"]);
      EVENT_LIST_FILTERED[CONTENT_TYPE_ID_INDEX].data = this.filteredCalendarObjectTypes;
      EVENT_LIST_FILTERED[CONTENT_TYPE_ID_INDEX].change = this.changeContentType;

      const ALL_DAY_ID_INDEX = findIndex(EVENT_LIST_FILTERED, ["id", "allDay"]);
      EVENT_LIST_FILTERED[ALL_DAY_ID_INDEX].change = this.changeAllDay;

      this.options.list = EVENT_LIST_FILTERED;
    },

    initModel() {
      if (!this.meeting) {
        return;
      }
      const MODEL = {};
      forEach(this.options.list, item => {
        MODEL[item.id] = cloneDeep(this.meeting[item.id]) || undefined;
      });
      if (this.meeting.objekt) {
        MODEL.content_type_id = this.meeting.objekt.content_type_id;
        MODEL.objekt_id = this.meeting.objekt.objekt_id;
        this.changeContentType({
          item: {
            label: CalendarObjectTypesObj[MODEL.content_type_id].label
          },
          model: MODEL.content_type_id,
          statusModelObjektId: true,
        });
      }
      if (this.meeting.private_termin === false) {
        MODEL.private = this.meeting.private_termin;
      } else {
        MODEL.private = true;
      }
      if (MODEL.allDay) {
        this.changeAllDay({ statusInit: true });
      } else {
        MODEL.allDay = false;
      }
      this.model = MODEL;
    },

    changeContentType({ item, model, statusModelObjektId }) {
      if (!statusModelObjektId) {
        this.model.objekt_id = undefined;
      }
      forEach(this.options.list, (i, index) => {
        if (i.id === "objekt_id") {
          this.options.list[index].hideIf = true;
        }
      });
      const PRIVATE_FLAG_INDEX = findIndex(this.options.list, ["id", "private"]);
      this.options.list[PRIVATE_FLAG_INDEX].disabled = true;
      this.model.private = true;
      if (!model) {
        return;
      }
      const CURRENT_OBJECT_INDEX = findIndex(this.options.list, ["label", item.label]);
      this.options.list[CURRENT_OBJECT_INDEX].hideIf = false;
      this.model.private = false;
      this.options.list[PRIVATE_FLAG_INDEX].disabled = false;
    },

    changeAllDay({ model, statusInit }) {
      const TER_VON_INDEX = findIndex(this.options.list, ["id", "ter_von"]);
      this.options.list[TER_VON_INDEX].disabledTime = statusInit || model;
      const TER_BIS_INDEX = findIndex(this.options.list, ["id", "ter_bis"]);
      this.options.list[TER_BIS_INDEX].disabledTime = statusInit || model;
      if (model) {
        this.setModelForDateStartDay();
      }
    },

    setModelForDateStartDay() {
      if (this.model.ter_von) {
        this.model.ter_von = moment(this.model.ter_von).startOf("day").format("YYYY-MM-DD HH:mm");
        if (this.model.ter_bis) {
          this.model.ter_bis = moment(this.model.ter_bis).endOf("day").format("YYYY-MM-DD HH:mm");
        } else {
          this.model.ter_bis = moment().endOf("day").format("YYYY-MM-DD HH:mm");
        }
      } else if (this.model.ter_bis) {
        this.model.ter_bis = moment(this.model.ter_bis).endOf("day").format("YYYY-MM-DD HH:mm");
        this.model.ter_von = moment(this.model.ter_bis).startOf("day").format("YYYY-MM-DD HH:mm");
      } else {
        this.model.ter_von = moment().startOf("day").format("YYYY-MM-DD HH:mm");
        this.model.ter_bis = moment().endOf("day").format("YYYY-MM-DD HH:mm");
      }
    },

    onSave() {
      if (this.meeting) {
        this.update();
      } else {
        this.save();
      }
    },

    save() {
      this.status.loading = true;
      const DATA = this.prepareModelToSave();
      this.postHttp({ url: "termine/", data: DATA }).then(
        () => {
          this.addNotification({ text: "_MSG_CALENDAR_TERMIN_CREATE_SUCCESS_" });
          this.close({ status: true });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },

    update() {
      this.status.loading = true;
      const DATA = this.prepareModelToSave();
      this.putHttp({ url: `termine/${ this.meeting.pk }/`, data: DATA }).then(
        () => {
          this.addNotification({
            text: "_MSG_CALENDAR_TERMIN_EDIT_SUCCESS_{{titel}}_",
            extra: {
              titel: this.meeting.ter_titel,
            }
          });
          this.close({ status: true, statusCloseDetails: true });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },

    prepareModelToSave() {
      const DATA = cloneDeep(this.model);
      if (DATA.allDay) {
        delete DATA.allDay;
      }
      return DATA;
    },
  },
};
