"use strict";

import "restangular";
import angular from "angular";

import Foerdermittel from "./foerdermittel.service.js";

const modelsModule = angular.module("sitzungen.mittel.models", [
  "restangular",
])

  .provider("Foerdermittel", Foerdermittel)
;

export default modelsModule;
