import FinanzierugMixin from "../Mixins/FinanzierugMixin";
import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import ZuschussMixin from "../Mixins/ZuschussMixin";

// @vue/component
export default {
  name: "ZuschussZeitraum",
  components: {
    KFStandard,
  },
  mixins: [
    FinanzierugMixin,
    ZuschussMixin,
  ],
  computed: {
    sumLocal() {
      return this.antragBetragCurrency;
    },
  },
  methods: {
    onReceiveSignal(signal_kennung) {
      if (signal_kennung === "durchfuehrungszeitraum.update") {
        this.reloadPositionen_KFBaseMixin({ statusSaveCallback: false });
        this.updateZuschuss_KFBaseMixin();
      }
    },

    loadVertragFinanzierungRest_FinanzierugMixin() {
      this.loading = false;
      this.reloading = false;
    },
  },
};
