"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";
import diasTable from "dias/core/table";

import foerderorganisationKontaktBoxComponent from "./foerderorganisation.kontakt.box.component";

const modul = angular.module("info.foerderorganisation", [
  uiRouter,
  diasTable.name,
]);

modul.config($stateProvider => {
  $stateProvider

    .state("root.foerderorganisationen_kennenlernen", {
      url: `/foerderorganisationen_kennenlernen/?
            user_cookie
      `,
      reloadOnSearch: false,
      params: { },
      views: {
        "main@root": {
          template: "<div vueinit><vue-foerderorganisation-list></vue-foerderorganisation-list></div>"
        }
      },
      data: {
        instance_pages: "foerderorganisation"
      }
    })

    .state("root.foerderorganisationen_kennenlernen.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><foerderorganisation-details></divfoerderorganisation-details></div>"
        }
      },
    })
  ;
})

  .component("foerderorganisationKontaktBox", foerderorganisationKontaktBoxComponent)
;

export default modul;
