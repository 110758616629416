"use strict";

import template from "./categories.box.jade";

class CategoriesBoxnController {
  /*@ngInject*/
  constructor($scope, $sce, Katalog) {
    this.Katalog = Katalog;
    this.$sce = $sce;

    this.categoriesAll = {};
    this.relevantKategoriewerte = this.relevantKategoriewerte || [];

    $scope.$watch("vm.relevantKategoriewerte", () => {
      this.loading = true;
      if (this.relevantKategoriewerte && this.relevantKategoriewerte.length) {
        this.loadFoerderfinderkategorien();
      } else {
        this.loading = false;
      }
    });
  }

  loadFoerderfinderkategorien() {
    if (this.categories) {
      this.filterCategories();
      this.loading = false;
      return;
    }
    this.Katalog.getKatalog("foerderfinderkategorien").then(
      response => {
        this.categories = response;
        this.filterCategories();
        this.loading = false;
      }
    );
  }

  filterCategories() {
    this.kategoriewerte = {};
    angular.forEach(this.categories, cat => {
      this.kategoriewerte[cat.pk] = cat;
      this.kategoriewerte[cat.pk].kategoriewerte = [];
      angular.forEach(this.relevantKategoriewerte, word => {
        if (cat.pk === word.kategorie) {
          this.kategoriewerte[cat.pk].kategoriewerte.push(word);
        }
      });

      if (this.kategoriewerte[cat.pk] && this.kategoriewerte[cat.pk].kategoriewerte && cat.werte.length === this.kategoriewerte[cat.pk].kategoriewerte.length) {
        this.categoriesAll[cat.pk] = _.cloneDeep(cat) || {};
        this.categoriesAll[cat.pk].fkw_kbez = "alle";
        this.categoriesAll[cat.pk].fkw_style = this.kategoriewerte[cat.pk].kategoriewerte[0].fkw_style;
      } else {
        this.categoriesAll[cat.pk] = undefined;
      }
    });
  }

  getSvg(svg) {
    if (!svg) {
      return "";
    }
    return this.$sce.trustAsHtml(svg);
  }
}

export default {
  template: template(),
  controller: CategoriesBoxnController,
  controllerAs: "vm",
  bindings: {
    relevantKategoriewerte: "<?"
  }
};
