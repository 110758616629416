import Modal from "../../../global/components/Modal/Modal.vue";
import FormElement from "../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";


// @vue/component
export default {
  name: "AufgabeModalKorrektur",
  components: {
    Modal,
    FormElement,
    PuxButton,
    PuxCloak,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    url: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      cleanedUrl: undefined,
      model: {
        wf_id: undefined,
        au_id: undefined,
        kommentar: undefined,
      },
      options: {
        required: true,
        showCloseButton: true
      },
      fields: {
        workflow: undefined,
        aufgaben: undefined,
        kommentar: {
          id: "kommentar",
          type: "richtext",
          label: "_LBL_AUFGABE_MODAL_KORREKTUR_KOMMENTAR_",
          view: "v",
          required: false,
          keyLabel: "bez",
          keyId: "pk",
          translate: true,
        },
      },
      status: {
        loadingWf: undefined,
        loadingAu: undefined,
        loading: undefined,
      },
      confirmed: false,
      errors: undefined,
      errorsClone: {},
      msgs: {
        step1: "_TXT_AUFGABE_MODAL_KORREKTUR_STEP_1_",
        step2: "_TXT_AUFGABE_MODAL_KORREKTUR_STEP_2_",
        step3: "_TXT_AUFGABE_MODAL_KORREKTUR_STEP_3_",
        ausfuehrungInfo: "_TXT_AUFGABE_MODAL_KORREKTUR_AUSFUEHRUNG_INFO_",
        ausfuehrungReload: "_TXT_AUFGABE_MODAL_KORREKTUR_AUSFUEHRUNG_RELOAD_",
        confirmLabel: "_LBL_AUFGABE_MODAL_KORREKTUR_CONFIRM_",
      }
    };
  },
  created() {
    if (this.url) {
      this.cleanedUrl = this.url.replace(/^\/api\//, "");
    }
    this.init();
  },
  methods: {
    onChange() { },

    onError({ error }) {
      this.errors = error;
      this.errorsClone = error;
    },

    onInput({ id }) {
      if (this.errorsClone[id]) {
        delete this.errorsClone[id];
      }
    },

    init() {
      this.updateWfs();
    },

    abort() {
      this.close();
    },

    updateWfs() {
      this.fields.workflow = undefined;
      this.model.wf_id = undefined;
      this.status.loadingWf = true;
      this.confirmed = false;
      this.getHttp({ url: `${ this.cleanedUrl }korrektur_workflows/` }).then(
        response => {
          this.fields.workflow = {
            id: "wf_id",
            type: "select",
            label: "_LBL_AUFGABE_MODAL_KORREKTUR_WORKFLOW_",
            view: "v",
            required: true,
            data: response,
            keyLabel: "bez",
            keyId: "pk",
            translate: true,
          };
        },
        this.onError
      ).finally(() => this.status.loadingWf = false);
    },

    updateAus() {
      this.fields.aufgaben = undefined;
      this.model.au_id = undefined;
      this.confirmed = false;
      if (this.model.wf_id) {
        this.status.loadingAu = true;
        this.getHttp({ url: `${ this.cleanedUrl }korrektur_aufgaben/?wf_id=${ this.model.wf_id }` }).then(
          response => {
            this.fields.aufgaben = {
              id: "au_id",
              type: "select",
              label: "_LBL_AUFGABE_MODAL_KORREKTUR_STATUS_",
              view: "v",
              required: true,
              data: response,
              keyLabel: "bez",
              keyId: "pk",
              translate: true,
            };
          },
          this.onError
        ).finally(() => this.status.loadingAu = false);
      }
    },

    getSelectedAufgabe() {
      if (this.model.au_id && this.fields.aufgaben) {
        return this.fields.aufgaben.data.find(au => au.pk === this.model.au_id);
      }
    },

    getSelectedWorkflow() {
      if (this.model.wf_id && this.fields.workflow) {
        return this.fields.workflow.data.find(wf => wf.pk === this.model.wf_id);
      }
    },

    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);

      this.postHttp({ url: `${ this.cleanedUrl }korrektur/`, data }).then(
        () => {
          this.addNotification({ text: "_MSG_AUFGABE_MODAL_KORREKTUR_SUCCESS_" });
          this.close();
          this.$stateReload();
        },
        error => {
          this.onError({ error: error.data });
        }
      ).finally(() => this.status.loading = false);
    },
  },
};
