var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-element"] = jade_interp = function(label, fieldName, errorsMap, prefix){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-class", "{'has-error': " + (errorsMap) + "['" + (fieldName) + "']}", true, true)) + (jade.cls(['form-group','row',attributes.class], [null,null,false])) + "><label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"col-form-label col-sm-3 text-right font-weight-bold required\"><span" + (jade.attr("ng-class", "{'required':vm.isRequired('" + (fieldName) + "')}", true, true)) + " class=\"titel\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span></label><div class=\"col-sm-9 col-xl-6\">");
block && block();
buf.push("<label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"help-block\"><ul" + (jade.attr("ng-if", "" + (errorsMap) + "['" + (fieldName) + "']", true, true)) + " class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errorsMap) + "['" + (fieldName) + "']", true, true)) + ">{{ err }}</li></ul></label></div></div>");
};


























jade_mixins["vorschau-daten"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-repeat", "(key, val) in " + (vorschau) + "", true, true)) + "><span ng-if=\"val\">{{key}}: {{ val }}</span></div>");
};
jade_mixins["vorschau-container"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div>");
jade_mixins["vorschau-daten"](vorschau);
buf.push("</div>");
};
jade_mixins["vorschau-daten"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<hr><div><div" + (jade.cls(['form-group','row',attributes.class], [null,null,false])) + "><label ng-attr-for=\"{{ctx.prefix}}_bildung_betragsrechner_kostenposition\" class=\"col-form-label col-sm-3 text-right font-weight-bold\"><span class=\"titel\">{{ ctx.gettext(\"Kostenposition (berechnet)\") }}</span></label><div class=\"col-sm-8\"><input type=\"text\"" + (jade.attr("value", "{{" + (vorschau) + ".betrag ? (" + (vorschau) + ".betrag | currency) : 'noch nicht ermittelbar'}} ", true, true)) + " disabled ng-attr-id=\"{{ctx.prefix}}_bildung_betragsrechner_kostenposition_berechnet\" class=\"form-control\"><span class=\"help-block\">{{ " + (jade.escape((jade_interp = vorschau) == null ? '' : jade_interp)) + ".bezeichnung ? " + (jade.escape((jade_interp = vorschau) == null ? '' : jade_interp)) + ".bezeichnung : '-' }}</span></div></div></div>");
};
buf.push("<div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input type=\"text\" ng-model=\"ctx.posmodel.argument.anzahl_tage\" name=\"anzahl_tage\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_anzahl_tage\" ng-attr-aria-invalid=\"{{ctx.errors['anzahl_tage'] ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "Veranstaltungstage", "anzahl_tage", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input type=\"text\" ng-model=\"ctx.posmodel.argument.anzahl_personen\" name=\"anzahl_personen\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_anzahl_personen\" ng-attr-aria-invalid=\"{{ctx.errors['anzahl_personen'] ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "Personenanzahl (Teilnehmer, Referenten und Betreuer)", "anzahl_personen", "ctx.errors", "ctx.prefix");
buf.push("</div>");
jade_mixins["vorschau-container"]("ctx.vorschau");;return buf.join("");
}