import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import KostenMixin from "../Mixins/KostenMixin";

import {
  get,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "AbhaengigeKostenpauschale",
  components: {
    KFStandard,
  },
  mixins: [
    KostenMixin,
  ],
  data() {
    return {
      allowsInput: true,
    };
  },
  computed: {
    betragsrechner() {
      return this.modelParameter.betragsrechner || "abhaengige_kostenpauschale_rechner";
    },

    statusHideButtonAdd() {
      return this.positionen.length > 0;
    },

    sumLocal() {
      // Im Zweifelsfalle immer den Wert aus der aktuellen Berechnung
      // zurückgeben:
      if (!isNil(get(this.infoprovider, `details[${ this.regel.afr_id }].betrag`))
        && this.infoprovider.gespeichert) {
        return this.filterCurrency(this.infoprovider.details[this.regel.afr_id].betrag || 0);
      }
      return this.filterCurrency(this.antragKoFiArt.summe || 0);
    },
  },
  watch: {
    infoprovider: {
      handler() {
        if (!this.loading && !this.infoproviderIsUpdating) {
          this.reloadPositionen_KFBaseMixin();
        }
      },
      deep: true,
    },
  },
};
