import translate from "../../../../../../../global/directives/translate";
import PuxButton from "../../../../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "UiProjektberichteReadOnlyElement",
  components: {
    PuxButton,
  },
  directives: {
    translate,
  },
  props: {
    modelCurrent: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    vorlagenObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusShowDetails: false,
    };
  },
  computed: {
    getButtonText() {
      return this.statusShowDetails ?
        "(Konfiguration ausblenden)" :
        "(Konfiguration anzeigen)";
    },
  },
  methods: {
    toggleDetails() {
      this.statusShowDetails = !this.statusShowDetails;
    },
  },
};
