"use strict";

import BetragsrechnerBase from "../betragsrechner.base";
import moment from "moment";
import {
  cloneDeep,
} from "lodash-es";

class PersonalkostenPerioden extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext, antragregel, regeldata) {
    super($scope, kontext, antragregel, regeldata);
    this.name = "personalkosten_perioden";
    this.component_name = "personalkostenperioden";
    this.fields = {
      antrag: { stellenbezeichnung: "",
                personalfunktion: "",
                stellenumfang: "",
                stellenumfang_projekt: "",
                jahresbrutto: "",
                perioden: [] },
      beleg: { name: "",
               einwilligung: "",
               beschaeftigt_von: "",
               beschaeftigt_bis: "",
               stellenumfang: "",
               stellenumfang_projekt: "",
               jahresbrutto: "",
               personalfunktion: "",
               personalstelle: "",
               dokument_geschwaerzt: "",
               perioden: [] }
    };
    this.kontext = kontext;
    this.labels = {
      antrag: {
        kostenartdetail: "Typ / Kategorie",
        stellenbezeichnung: "Stellenbezeichnung",
        personalfunktion: "Funktion im Projekt",
        vollzeitstelle: "Umfang einer Vollzeitstelle in der Organisation (in Stunden pro Woche)",
        stellenumfang: "Gesamter Umfang dieser Stelle in Ihrer Organisation (in Stunden pro Woche)",
        stellenumfang_projekt: "davon im Projekt (in Stunden pro Woche)",
        jahresbrutto: "Jährliches Bruttogehalt",
        perioden: [],
        gehalt: "Gehalt",
        von: "Beginn",
        bis: "Ende",
      },
      beleg: {
        kostenartdetail: "Typ / Kategorie",
        name: "Name",
        einwilligung: "Einwilligungserklärung",
        personalfunktion: "Funktion im Projekt",
        vollzeitstelle: "Umfang einer Vollzeitstelle in der Organisation (in Stunden pro Woche)",
        stellenumfang: "Gesamter Umfang dieser Stelle in Ihrer Organisation (in Stunden pro Woche)",
        stellenumfang_projekt: "davon im Projekt (in Stunden pro Woche)",
        jahresbrutto: "Jährliches Bruttogehalt",
        personalstelle: "Art der Personalstelle",
        beschaeftigt_von: "Beschäftigt_seit",
        dokument_geschwaerzt: "Bestätigung",
        perioden: [],
        gehalt: "Gehalt",
        von: "Beginn",
        bis: "Ende",
      }
    };
    this.initRegeldataPerioden();
  }

  initRegeldataPerioden() {
    this.fields.antrag.perioden = [];
    this.fields.beleg.perioden = [];
    angular.forEach(this.regeldata.perioden, periode => {
      this.fields.antrag.perioden.push({
        von: periode.von,
        bis: periode.bis,
        gehalt: "",
        periode: cloneDeep(periode),
      });
      this.fields.beleg.perioden.push({
        von: periode.von,
        bis: periode.bis,
        gehalt: "",
        periode: cloneDeep(periode),
      });
      const label = periode.kbez + " " + moment(periode.von).format("DD.MM.YYYY") + "-" + moment(periode.bis).format("DD.MM.YYYY");
      this.labels.antrag.perioden.push(label);
      this.labels.beleg.perioden.push(label);
    });
  }

  get_labelmapping() {
    return this.labels[this.kontext];
  }
}
export default PersonalkostenPerioden;
