"use strict";

import KostenController from "../../kosten.controller";

class KostenSummenzeileController extends KostenController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KKostenart, BetragsrechnerRegistry, i18n) {
    super($scope, $q, Foerdermodule, Foerderantrag, KKostenart, BetragsrechnerRegistry, i18n);
    this.readonly = true;
    this.artReMap = {};
    this.summe = 0;
    this.snapshotKosMap = {};
    this.snapshotSumme = 0;
    $scope.$watch(() => this.infoprovider.details, () => {
      this.artReMap = {};
      _.forEach(this.infoprovider.details, (detail, reId) => {
        this.artReMap[detail.art] = reId;
      });
      this.summe = this.getSumme();
    });
    $scope.$watch(() => this.snapshot, () => {
      this.snapshotKosMap = {};
      if (this.snapshot && this.snapshot.asn_snapshot && this.snapshot.asn_snapshot.kostenarten) {
        _.forEach(this.snapshot.asn_snapshot.kostenarten, aka => {
          this.snapshotKosMap[aka.kostenart.kos_id] = aka.aka_summe || 0;
        });
      }
      this.snapshotSumme = this.getSummeSnapshot();
    });
  }

  getSumme() {
    let summe = 0;
    if (!this.infoprovider || !this.infoprovider.details) {
      return summe;
    }
    _.forEach(this.parameter.kostenarten, art => {
      if (this.infoprovider.details[this.artReMap[art]]) {
        summe += this.infoprovider.details[this.artReMap[art]].betrag || 0;
      }
    });
    return summe;
  }

  getSummeSnapshot() {
    let summe = 0;
    _.forEach(this.parameter.kostenarten, art => {
      summe += this.snapshotKosMap[art] || 0;
    });
    return summe;
  }
}

export default KostenSummenzeileController;
