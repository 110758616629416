import KFStandard from "../../../KFDetail/KFStandard/KFStandard.vue";
import KostenMixin from "../../Mixins/KostenMixin";

// @vue/component
export default {
  name: "TagespauschaleKostenSignal",
  components: {
    KFStandard,
  },
  mixins: [
    KostenMixin,
  ],
  computed: {
    sumLocal() {
      return this.summeCurrency;
    },
  },
  methods: {
    onReceiveSignal(signal_kennung) {
      if (signal_kennung === "betreuungsverhaeltnis.update" ||
        signal_kennung === "durchfuehrungszeitraum.update") {
        // this.reloadModule({ statusUpdate: true });
        this.reloadPositionen_KFBaseMixin({ statusSaveCallback: false });
        this.updateZuschuss_KFBaseMixin();
      }
    },

    updateInfoproviderLocal_KFBaseMixin({ response }) {
      response.gespeichert = true;
      this.updateInfoprovider({ response });
    },
  },
};
