export default [
  {
    id: "antragsdaten",
    type: "template",
    html: "_HTML_BERICHT_ANTRAGSCONTROLLING_DETAILS_ASIDE_FILTERS_ANTRAGSDATEN_",
    translate: true,
  },
  {
    id: "a_antragsdatum_after",
    label: "_LBL_BERICHT_ANTRAGSCONTROLLING_DETAILS_ASIDE_FILTERS_VON_",
    type: "date",
    view: "v",
    translate: true,
  },
  {
    id: "a_antragsdatum_before",
    label: "_LBL_BERICHT_ANTRAGSCONTROLLING_DETAILS_ASIDE_FILTERS_BIS_",
    type: "date",
    view: "v",
    translate: true,
  },
  {
    id: "regelsatz",
    label: "_LBL_BERICHT_ANTRAGSCONTROLLING_DETAILS_ASIDE_FILTERS_REGELSATZ_",
    type: "multiselect",
    url: "foerderantraege/regelsaetze/",
    keyLabel: "label",
    keyId: "value",
    search: true,
    searchList: ["label"],
    combineCopies: true,
    view: "v",
    translate: true,
  },
  {
    id: "status_detail",
    label: "_LBL_BERICHT_ANTRAGSCONTROLLING_DETAILS_ASIDE_FILTERS_STATUS_DETAIL_",
    type: "multiselect",
    url: "katalog/?fields=pk&fields=au_kbezdetail&key=antragsaufgabe",
    keyLabel: "au_kbezdetail",
    keyId: "pk",
    search: true,
    searchList: ["au_kbezdetail"],
    combineCopies: true,
    view: "v",
    translate: true,
  },
  {
    id: "akkrdaten",
    type: "template",
    html: "_HTML_BERICHT_ANTRAGSCONTROLLING_DETAILS_ASIDE_FILTERS_AKKRDATEN_",
    translate: true,
  },
  {
    id: "abschluss",
    label: "_LBL_BERICHT_ANTRAGSCONTROLLING_DETAILS_ASIDE_FILTERS_ABSCHLUSSGRAD_",
    type: "multiselect",
    many: "true",
    url: "katalog/?fields=pk&fields=ag_kbez&key=abschlussgrad",
    keyLabel: "ag_kbez",
    keyId: "pk",
    search: true,
    searchList: ["ag_kbez"],
    view: "v",
    translate: true,
  },
  {
    id: "studiengangtyp",
    label: "_LBL_BERICHT_ANTRAGSCONTROLLING_DETAILS_ASIDE_FILTERS_STUDIENGANGTYP_",
    type: "multiselect",
    many: "true",
    url: "katalog/?fields=pk&fields=kst_kbez&key=studientyp",
    keyLabel: "kst_kbez",
    keyId: "pk",
    search: true,
    searchList: ["kst_kbez"],
    view: "v",
    translate: true,
  },
  {
    id: "studienform",
    label: "_LBL_BERICHT_ANTRAGSCONTROLLING_DETAILS_ASIDE_FILTERS_STUDIENFORM_",
    type: "multiselect",
    many: "true",
    url: "katalog/?fields=pk&fields=sdf_kbez&key=studienform",
    keyLabel: "sdf_kbez",
    keyId: "pk",
    search: true,
    searchList: ["sdf_kbez"],
    view: "v",
    translate: true,
  },
  {
    id: "studiengang_hochschultyp",
    label: "_LBL_BERICHT_ANTRAGSCONTROLLING_DETAILS_ASIDE_FILTERS_HOCHSCHULTYP_",
    type: "multiselect",
    many: "true",
    url: "katalog/?fields=pk&fields=hst_kbez&key=hochschultyp",
    keyLabel: "hst_kbez",
    keyId: "pk",
    search: true,
    searchList: ["hst_kbez"],
    view: "v",
    translate: true,
  },
  {
    id: "sysakkrdaten",
    type: "template",
    html: "_HTML_BERICHT_ANTRAGSCONTROLLING_DETAILS_ASIDE_FILTERS_SYSAKKRDATEN_",
    translate: true,
  },
  {
    id: "hochschultyp",
    label: "_LBL_BERICHT_ANTRAGSCONTROLLING_DETAILS_ASIDE_FILTERS_HOCHSCHULTYP_",
    type: "multiselect",
    many: "true",
    url: "katalog/?fields=pk&fields=hst_kbez&key=hochschultyp",
    keyLabel: "hst_kbez",
    keyId: "pk",
    search: true,
    searchList: ["hst_kbez"],
    view: "v",
    translate: true,
  },
];
