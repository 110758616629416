"use strict";

import BaseModel from "dias/core/models/baseModel";

class antragstellerorgPruefstatus extends BaseModel {
  constructor() {
    super("pruefungen");
  }
}

export default antragstellerorgPruefstatus;
