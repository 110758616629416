"use strict";


class SitzungsantragEntscheidungAnmerkungViewController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification) {
    this.$scope = $scope;
    this.DiasNotification = DiasNotification;
    this.sla = $scope.extras.sla;

    this.sla_entscheidung_anmerkung = this.sla.sla_entscheidung_anmerkung;

    const errorsLabel = {
      sla_entscheidung_anmerkung: "Anmerkung zur Entscheidung: "
    };

    this.save = () => {
      return this.sla.updateEntscheidungAnmerkung(this.sla_entscheidung_anmerkung).then(
        response => {
          this.sla.sla_entscheidung_anmerkung = response.sla_entscheidung_anmerkung;
          this.DiasNotification.page.success("Anmerkung zur Entscheidung erfolgreich aktualisiert");
          return response;
        },
        error => {
          this.DiasNotification.form.clear();
          this.DiasNotification.form.error(error.data, "Ihre Daten sind fehlerhaft oder unvollständig.", {}, errorsLabel);
          this.errors = error.data;
          return $q.reject(error);
        }
      );
    };
  }
}

export default SitzungsantragEntscheidungAnmerkungViewController;
