import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import DynamischeZahlenReadonlyElement from "./DynamischeZahlenReadonlyElement/DynamischeZahlenReadonlyElement.vue";
import DynamischeZahlenEditElement from "./DynamischeZahlenEditElement/DynamischeZahlenEditElement.vue";
import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "DynamischeZahlen",
  components: {
    SnapshotModule,
    SnapshotIcon,
    DynamischeZahlenReadonlyElement,
    DynamischeZahlenEditElement,
  },
  mixins: [
    ModuleMixin,
  ],
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.open;
      return OPTIONS;
    },

    labelHeader() {
      return this.modelParameter.frage;
    },

    snapshotFiltered() {
      const SNAPSHOT = {
        changes: {},
        status: false,
      };
      if (this.statusSnapshot) {
        const SNAPSHOT_MODULE_OBJ = this.snapshotModule || {};
        forEach(this.model, (item, key) => {
          if (item !== SNAPSHOT_MODULE_OBJ[key]) {
            SNAPSHOT.changes[key] = true;
            SNAPSHOT.status = true;
          }
        });
      }
      return SNAPSHOT;
    },

    statusDiff() {
      return this.snapshotFiltered.status;
    },

    errorsLabelsOptions() {
      const ERRORS_LABELS = [];
      forEach(this.modelParameter.dyn_zahlen_params, (item, index) => {
        ERRORS_LABELS.push({
          id: index + 1,
          htmlId: `${ this.htmlRegelNummer }_${ item.id }`,
          label: item.label,
        });
      });
      return ERRORS_LABELS;
    },
  },
  methods: {
    prepareModel() {
      this.model = cloneDeep(this.module.data) || {};
    },

    open() {
      this.modelEdit = cloneDeep(this.model);
    },

    updateModel({ model }) {
      this.modelEdit = cloneDeep(model);
    },

    onReceiveSignal(signal_kennung) {
      if (signal_kennung === "dynamische_tabelle.update" ||
        signal_kennung === "dynamische_zahlen.update" ||
        signal_kennung === "dynamische_mehrfachauswahl.update") {
        this.reloadModule();
      }
    },

    setLocalErrorsFromGlobal({ errors }) {
      this.errors = errors;
    },
  },
};
