import AuszahlungModalitaetChangeButtonModalData from "./AuszahlungModalitaetChangeButtonModalData/AuszahlungModalitaetChangeButtonModalData.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

import SaveCallbackActions from "../../../../const/SaveCallbackActions";
import {
  cloneDeep,
  get,
  isNil,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungModalitaetChangeButtonModal",
  components: {
    AuszahlungModalitaetChangeButtonModalData,
    FormElement,
    Modal,
    PuxAlert,
    PuxButton,
    PuxCloak,
    PuxTranslate,
  },
  props: {
    auszahlungUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "saveCallback",
  ],
  setup() {
    const {
      getHttp,
      postHttp,
    } = HttpAPI();

    return {
      getHttp,
      postHttp,
    };
  },
  data() {
    return {
      isModalVisible: undefined,
      buttonId: "auszahlung_change_modalitaet",
      modalActionLoading: false,
      optionsForModal: {
        showCloseButton: true,
        required: true,
        cancelLabel: "_BTN_CLOSE_",
      },
      model: {
        auszahlungsplan: undefined,
      },
      auszahlungsplanData: undefined,
      initLoading: true,
      current: undefined,
      preview: {
        modalitaet: undefined,
        title: "_TXT_AUSZAHLUNG_WECHSEL_MODALITAET_RESULT_PREVIEW_TITLE_",
        result: undefined,
        loading: undefined,
        loadingMsg: "_MSG_AUSZAHLUNG_WECHSEL_MODALITAET_PREVIEW_LOADING_",
        errors: undefined
      },
      execute: {
        modalitaet: undefined,
        title: "_TXT_AUSZAHLUNG_WECHSEL_MODALITAET_RESULT_EXECUTE_TITLE_",
        result: undefined,
        loading: undefined,
        loadingMsg: "_MSG_AUSZAHLUNG_WECHSEL_MODALITAET_EXECUTE_LOADING_",
        errors: undefined
      },
    };
  },
  computed: {
    selectorClose() {
      return [
        `#${ this.buttonId }`,
      ];
    },

    auszahlungModalitaetWechselChoicesUrl() {
      return `${ this.auszahlungUrl }modalitaet_wechsel_choices/`;
    },

    auszahlungModalitaetWechselPreviewUrl() {
      return `${ this.auszahlungUrl }modalitaet_wechsel_preview/`;
    },

    auszahlungModalitaetWechselExecuteUrl() {
      return `${ this.auszahlungUrl }modalitaet_wechsel_execute/`;
    },

    isGrundRequired() {
      return !!get(this.auszahlungsplan, "zp_modalitaet.anpassung_begruenden");
    },

    auszahlungsplanOptions() {
      return {
        id: "auszahlungsplan",
        type: "select",
        label: "_LBL_AUSZAHLUNG_WECHSEL_MODALITAET_SELECT_AUSZAHLUNGSPLAN_",
        required: true,
        keyLabelCallback: ({ item }) => `${ item.zp_nummer } - ${ item.name }: ${ item.display }`,
        keyId: "pk",
        search: true,
        searchList: ["zp_nummer", "name", "display"],
        view: "v",
        data: this.auszahlungsplanData,
        translate: true,
        showLabel: true,
        change: this.onChangeSelection
      };
    },

    hasChoices() {
      return size(this.auszahlungsplanData) > 0;
    },

    auszahlungsplanFieldDisabled() {
      return this.loading || !isNil(this.execute.result);
    },

    loading() {
      return this.execute.loading || this.preview.loading || this.modalActionLoading;
    },

    showPreviewBtn() {
      return !this.preview.result;
    },

    previewBtnDisabled() {
      return this.loading || !this.model.auszahlungsplan || this.model.auszahlungsplan === this.preview.modalitaet;
    },

    showData() {
      return this.model.auszahlungsplan && this.model.auszahlungsplan === this.preview.modalitaet && !this.execute.result;
    },

    showSuccessAlert() {
      return !isNil(this.execute.result);
    },

    showExecuteBtn() {
      return !this.showPreviewBtn && isNil(this.execute.result);
    },

    executeBtnDisabled() {
      return this.loading || !this.preview.modalitaet || size(get(this.preview, "result.errors", [])) > 0;
    },
  },
  methods: {
    openModal() {
      this.initData();
      this.isModalVisible = true;
    },

    initData() {
      this.initLoading = true;
      this.getHttp({
        url: this.auszahlungModalitaetWechselChoicesUrl,
      }).then(
        response => this.auszahlungsplanData = response
      ).finally(() => this.initLoading = false);
    },

    onChangeSelection() {
      this.preview.result = undefined;
      this.preview.errors = undefined;
      this.execute.result = undefined;
      this.execute.errors = undefined;
    },

    doPreview() {
      this.doAction(
        this.preview,
        this.auszahlungModalitaetWechselPreviewUrl,
      );
    },

    doExecute() {
      this.doAction(
        this.execute,
        this.auszahlungModalitaetWechselExecuteUrl,
      );
    },

    setLoading(obj, status) {
      obj.loading = status;
      this.onLoading(this.loading);
    },

    doAction(obj, url) {
      this.current = obj;
      this.modalActionLoading = true;
      const data = cloneDeep(this.model);
      obj.modalitaet = this.model.auszahlungsplan;
      this.postHttp({
        url: url,
        data
      }).then(
        response => {
          obj.result = response;
        },
        error => {
          obj.errors = error.data;
        }
      ).finally(
        () => this.modalActionLoading = false
      );
    },

    closeModal() {
      this.isModalVisible = false;
      this.$emit("saveCallback", { statusSaveCallback: SaveCallbackActions.RELOAD_ALL });
    },
  },
};
