import Alert from "../../../../../global/components/Alert/Alert.vue";
import AnlagenAllgemeinOneDocument from "../AnlagenAllgemeinOneDocument/AnlagenAllgemeinOneDocument.vue";
import AnlagenAllgemeinOneDocumentDetails from "../AnlagenAllgemeinOneDocumentDetails/AnlagenAllgemeinOneDocumentDetails.vue";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import Permission from "../../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTooltip from "../../../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import translate from "../../../../../global/directives/translate";

import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";
import SyConfigAPI from "../../../../../global/compositionAPI/SyConfigAPI";

import {
  findIndex,
  forEach,
  indexOf,
  cloneDeep,
  isUndefined,
  isNull,
  get,
  values,
  isArray,
  startsWith,
  orderBy,
  isNil,
  size,
} from "lodash-es";
import {
  EventBus,
} from "../../../../../global/functions/event-bus";
import moment from "moment";
import { createNamespacedHelpers } from "vuex";
const { mapMutations, mapGetters } = createNamespacedHelpers("table");

// @vue/component
export default {
  name: "AnlagenAllgemeinDocuments",
  components: {
    Alert,
    PuxCloak,
    PuxIcon,
    Permission,
    FormstepDetail,
    FormElement,
    Modal,
    AnlagenAllgemeinOneDocument,
    AnlagenAllgemeinOneDocumentDetails,
    PuxButton,
    PuxTranslate,
    PuxTooltip,
  },
  directives: {
    translate,
  },
  props: {
    headerName: {
      type: String,
      required: false,
      default: undefined,
    },
    headerInfo: {
      type: String,
      required: false,
      default: undefined,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    dokumentartenPkList: {
      type: Array,
      required: true,
    },
    dokumentarten: {
      type: Array,
      required: true,
    },
    dokumentartenAll: {
      type: Array,
      required: true,
    },
    dokumentartenAllByPk: {
      type: Object,
      required: true,
    },
    dokumentePermissionsLocal: {
      type: Object,
      required: true,
    },
    userPermissions: {
      type: Array,
      required: true,
    },
    htmlId: {
      type: String,
      required: true,
    },
    documentPruefStatuses: {
      type: Array,
      required: true,
    },
    notizenOptions: {
      type: Object,
      required: false,
      default: undefined,
    },
    obj: {
      type: Object,
      required: true,
    },
    canCreate: {
      type: Boolean,
      required: false,
    },
    canDelete: {
      type: Boolean,
      required: false
    },
    canUpdate: {
      type: Boolean,
      required: false,
    },
    mehrfachauswahl: {
      type: Boolean,
      required: false,
    },
    allowedMimeType: {
      type: Array,
      required: false,
      default: undefined,
    },
    gueltigFuerDokumentHochladen: {
      type: Boolean,
      required: false,
    },
    gueltigPflichtFelder: {
      type: Array,
      required: false,
      default: () => [],
    },
    regel: {
      type: String,
      required: false,
      default: undefined,
    },
    statusSnapshot: {
      type: Boolean,
      required: false,
    },
    snapshotDokumente: {
      type: Object,
      required: true,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    anzeigeDetailsObject: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    anzeigeDetails: {
      type: Array,
      required: false,
      default: () => [],
    },
    colsForTableFiltered: {
      type: Array,
      required: true,
    },
    snapshotKeys: {
      type: Array,
      required: true,
    },
    defaultSort: {
      type: String,
      required: false,
      default: undefined,
    },
    fromAst: {
      type: Boolean,
      required: false,
    },
    keyRequired: {
      type: String,
      required: true,
      validator: value => ["all", "required", "optional"].indexOf(value) !== -1,
    },
  },
  emits: [
    "changeDiff",
  ],
  setup() {
    const {
      addNotification,
      deleteHttp,
      getListHttp,
      postHttp,
    } = HttpAPI();

    const {
      getSyConfigsValue,
    } = SyConfigAPI();

    return {
      addNotification,
      deleteHttp,
      getListHttp,
      getSyConfigsValue,
      postHttp,
    };
  },
  data() {
    return {
      statusLoading: true,
      statusOpenUpload: false,
      statusDetailsInit: {},
      statusDetailsShow: {},
      documents: [],
      documentsInSync: [],
      modelEditDokart: null,
      confirmOptions: undefined,
      confirmShow: undefined,
      timer: undefined,
      modelGueltigVon: null,
      modelGueltigBis: null,
      errors: undefined,
      sortBy: undefined,
      infoTextObj: {},
      isInfoTextVisible: false,
      sitzungsantragconfig: undefined,
    };
  },
  computed: {
    tableId() {
      return `anlagen_allgemein_${ this.htmlId }`;
    },

    prefixUrlSave() {
      return `api/${ this.urlDocuments }`;
    },

    urlDocuments() {
      return `${ this.baseUrl }dokumente/`;
    },

    htmlIdFormstep() {
      return `${ this.htmlId }_new`;
    },

    optionsDocumentsUpload() {
      return {
        cancelLabel: "Fertig",
        closeCallback: this.closeUpload,
      };
    },

    infoIText() {
      const PATH = `${ this.modelEditDokart }.dokartInfo`;
      return get(this.dokumentartenAllByPk, PATH);
    },

    optionsDokart() {
      return {
        type: "select",
        id: "dokart",
        label: "_LBL_GR_DOCUMENTS_DOKUMENTTYP_",
        translate: true,
        required: true,
        view: "v",
        keyLabel: "name",
        keyId: "pk",
        search: true,
        searchList: ["name"],
        htmlId: `${ this.htmlId }_dokart`,
        change: this.changeDokart,
      };
    },

    optionsUpload() {
      return {
        htmlId: `${ this.htmlId }_document`,
        multiple: !!this.mehrfachauswahl,
        drop: true,
        type: "document",
        progressHideTime: 0,
        saveUrl: this.saveUrl,
        saveStart: this.uploadStart,
        saveError: this.uploadError,
        allowedMimeType: this.allowedMimeType,
      };
    },

    saveUrl() {
      let saveUrl = `${ this.urlDocuments }?dokart=${ this.modelEditDokart }`;
      if (this.modelGueltigVon) {
        saveUrl = `${ saveUrl }&gueltig_ab=${ this.modelGueltigVon }`;
      }
      if (this.modelGueltigBis) {
        saveUrl = `${ saveUrl }&gueltig_bis=${ this.modelGueltigBis }`;
      }
      return saveUrl;
    },

    optionsGueltigVon() {
      if (!isNull(this.modelEditDokart)) {
        const LABEL_GUELTIG_AB = this.dokumentartenAllByPk[this.modelEditDokart].label_gueltig_ab;
        return {
          type: "date",
          id: "gueltig_von",
          label: LABEL_GUELTIG_AB,
          translate: true,
          required: this.statusRequiredGueltigVon,
          view: "v",
          htmlId: `${ this.htmlId }_gueltig_von`,
          notAfter: this.modelGueltigBis,
        };
      }
      return undefined;
    },

    optionsGueltigBis() {
      let notBefore = moment().format("YYYY-MM-DD");
      if (this.modelGueltigVon && this.modelGueltigVon > notBefore) {
        notBefore = this.modelGueltigVon;
      }
      if (!isNull(this.modelEditDokart)) {
        const LABEL_GUELTIG_BIS = this.dokumentartenAllByPk[this.modelEditDokart].label_gueltig_bis;

        return {
          type: "date",
          id: "gueltig_bis",
          label: LABEL_GUELTIG_BIS,
          translate: true,
          required: this.statusRequiredGueltigBis,
          view: "v",
          htmlId: `${ this.htmlId }_gueltig_bis`,
          notBefore: notBefore,
        };
      }
      return undefined;
    },

    statusRequiredGueltigVon() {
      return this.gueltigPflichtFelder.indexOf("von") !== -1;
    },

    statusRequiredGueltigBis() {
      return this.gueltigPflichtFelder.indexOf("bis") !== -1;
    },

    statusCheckGueltigkeit() {
      if (this.gueltigFuerDokumentHochladen) {
        if ((this.statusRequiredGueltigVon && !this.modelGueltigVon) ||
          (this.statusRequiredGueltigBis && !this.modelGueltigBis)) {
          return false;
        }
      }
      return true;
    },

    statusCheckDokart() {
      return !isNull(this.modelEditDokart);
    },

    statusHasDokumente() {
      return size(this.documents) > 0 || size(this.snapshotDocumentsFiltered.removed) > 0;
    },

    snapshotDocumentsFiltered() {
      const DOCUMENTS = {
        added: {},
        removed: [],
        changes: {},
        status: false,
      };
      if (this.statusSnapshot) {
        const SNAPSHOT_DOCUMENTS_OBJ = cloneDeep(this.snapshotDokumente.obj);
        forEach(this.sortedDocs, document => {
          const DOCUMENT_PK = document.dokument.pk;
          if (!SNAPSHOT_DOCUMENTS_OBJ[DOCUMENT_PK]) { // added
            DOCUMENTS.added[DOCUMENT_PK] = true;
            DOCUMENTS.status = true;
          } else {
            const CURRENT_SNAPSHOT_DOCUMENT = SNAPSHOT_DOCUMENTS_OBJ[document.dokument.pk];
            forEach(this.snapshotKeys, keyName => {
              if (keyName === "dokument.user" && !get(document, keyName)) { // Workaround
                return;
              }
              if (get(document, keyName) !== get(CURRENT_SNAPSHOT_DOCUMENT, keyName)) {
                DOCUMENTS.changes[DOCUMENT_PK] = DOCUMENTS.changes[DOCUMENT_PK] || {};
                DOCUMENTS.changes[DOCUMENT_PK][keyName] = true;
                DOCUMENTS.status = true;
              }
            });
            delete SNAPSHOT_DOCUMENTS_OBJ[document.dokument.pk];
          }
        });
        DOCUMENTS.removed = values(SNAPSHOT_DOCUMENTS_OBJ);
        if (DOCUMENTS.removed.length) {
          DOCUMENTS.status = true;
        }
      }
      return DOCUMENTS;
    },

    isDiff() {
      return this.snapshotDocumentsFiltered.status;
    },

    colSortInfo() {
      const CLS_MAP = {};
      const SORT_MODEL = this.GET_SORT_MODEL(this.tableId);

      forEach(this.colsForTableFiltered, col => {
        const COL_SORT = this.GET_SORT_TH({ tableId: this.tableId, colId: col.keySort });
        CLS_MAP[col.key] = {
          class: { table_smart__table__th_sort_active: COL_SORT !== "none" },
          sort: COL_SORT,
          asc: col.keySort === SORT_MODEL,
          desc: `-${ col.keySort }` === SORT_MODEL,
        };
      });

      return CLS_MAP;
    },

    sortedDocs() {
      if (isNil(this.sortBy)) {
        return this.documents;
      } else if (startsWith(this.sortBy, "-")) {
        return orderBy(this.documents, [this.sortBy.substring(1)], ["desc"]);
      }
      return orderBy(this.documents, [this.sortBy], ["asc"]);
    },

    computedObj() {
      return Object.assign({}, this.obj);
    },

    ...mapGetters([
      "GET_SORT_MODEL",
      "GET_SORT_TH",
    ]),
  },

  watch: {
    computedObj: {
      deep: true,
      handler(value, oldValue) {
        if (this.fromAst && value.antragstellerorg && (!oldValue.antragstellerorg || value.antragstellerorg !== oldValue.antragstellerorg)) {
          this.loadDocuments();
        }
      }
    },
    isDiff: {
      immediate: true,
      handler(newVal) {
        this.$emit("changeDiff", {
          isDiff: newVal,
          keyRequired: this.keyRequired,
        });
      },
    },
  },

  created() {
    this.initEventBusses();
    this.loadDocuments();
    this.MUT_CREATE_TABLE_BY_ID(this.tableId);
    const SORT = this.GET_SORT_MODEL(this.tableId) || this.defaultSort;
    this.changeSort(SORT);
    this.sitzungsantragconfig = this.getSyConfigsValue("sitzungsantraege") || {};
  },
  beforeUnmount() {
    this.destroyEventBusses();
    this.MUT_REMOVE_TABLE_BY_ID(this.tableId);
  },
  methods: {
    initEventBusses() {
      EventBus.$on("changeDocument", this.changeDocumentFromEventBus);
      EventBus.$on("reloadAnlagenAllgemein", this.loadDocuments);
    },

    destroyEventBusses() {
      EventBus.$off("changeDocument", this.changeDocumentFromEventBus);
      EventBus.$off("reloadAnlagenAllgemein", this.loadDocuments);
    },

    uploadStart() {
      this.errors = undefined;
    },

    uploadError(errors) {
      if (isArray(errors)) {
        this.errors = { document: errors };
      } else {
        this.errors = errors;
      }
    },

    loadDocuments() {
      this.statusLoading = true;
      this.getListHttp({
        url: this.urlDocuments,
        urlParams: {
          dokart: this.dokumentartenPkList,
        },
      }).then(
        response => {
          this.documents = response;
          this.setInfotexte();
          this.initDocumentsInSync();
          this.statusLoading = false;
        }
      );
    },

    setInfotexte() {
      const INFO_TEXT_OBJ = {};
      forEach(this.documents, document => {
        const PATH = `${ document.dokument.dokart_id }.dokartInfo`;
        INFO_TEXT_OBJ[document.dokument.pk] = get(this.dokumentartenAllByPk, PATH);
      });
      this.infoTextObj = INFO_TEXT_OBJ;
    },

    initDocumentsInSync() {
      forEach(this.documents, document => {
        if (document.is_sync) {
          this.documentsInSync.push(document.pk);
        }
      });
      if (this.documentsInSync.length) {
        this.checkSynchronization();
      }
    },

    openUpload() {
      this.statusOpenUpload = true;
      this.modelEditDokart = null;

      this.modelGueltigVon = null;
      this.modelGueltigBis = null;
      this.closeInfoText();
    },

    closeUpload() {
      this.statusOpenUpload = false;
    },

    openModalDelete(document) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_GR_DOCUMENTS_MODAL_DELETE_HEADER_",
        msg: "_HTML_GR_DOCUMENTS_MODAL_DELETE_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteDocument(document),
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteDocument(document) {
      this.confirmOptions.loading = true;
      this.deleteHttp({ url: `${ this.urlDocuments }${ document.pk }/` }).then(
        () => {
          this.addNotification({ text: "_MSG_GR_DOCUMENTS_MODAL_DELETE_SUCCESS_" });
          const INDEX_IN_DOCUMENTS = findIndex(this.documents, ["pk", document.pk]);
          if (INDEX_IN_DOCUMENTS !== -1) {
            this.documents.splice(INDEX_IN_DOCUMENTS, 1);
          }
          EventBus.$emit("changeDocument", {
            document,
            dokartPk: document.dokument && document.dokument.dokart_id,
            componentId: this.htmlId,
            type: "delete",
            regel: this.regel,
          });
          this.closeConfirm();
        },
        () => {
          this.addNotification({ text: "_MSG_GR_DOCUMENTS_MODAL_DELETE_ERROR_", type: "error" });
        }
      ).then(
        () => {
          this.confirmOptions.loading = false;
        }
      );
    },

    closeConfirm() {
      this.confirmShow = false;
    },

    addDocument({ currentModel }) {
      this.documents.unshift(currentModel);
      this.documentsInSync.push(currentModel.pk);
      this.setInfotexte();
      this.checkSynchronization({ time: 0 });
      EventBus.$emit("changeDocument", {
        document: currentModel,
        dokartPk: currentModel.dokument && currentModel.dokument.dokart_id,
        componentId: this.htmlId,
        type: "create",
        regel: this.regel,
      });
    },

    checkSynchronization({ time = 3000 } = {}) {
      if (this.timer) {
        return;
      }
      this.timer = true;
      setTimeout(() => {
        if (!this.documentsInSync.length) {
          this.timer = undefined;
          return;
        }
        this.getListHttp({
          url: "dokument_sync/",
          urlParams: {
            dok_id: this.documentsInSync,
          },
        }).then(
          response => {
            this.checkDocumentsInSync({ response });
            this.timer = undefined;
            this.checkSynchronization();
          }
        );
      }, time);
    },

    checkDocumentsInSync({ response }) {
      forEach(response, document => {
        if (!document.is_sync) {
          const INDEX_IN_DOK_SYNC = indexOf(this.documentsInSync, document.pk);
          if (INDEX_IN_DOK_SYNC !== -1) {
            this.documentsInSync.splice(INDEX_IN_DOK_SYNC, 1);
          }
          const INDEX_IN_DOCUMENTS = findIndex(this.documents, ["pk", document.pk]);
          if (INDEX_IN_DOCUMENTS !== -1) {
            const DOCUMENT_CLONE = cloneDeep(this.documents[INDEX_IN_DOCUMENTS]);
            DOCUMENT_CLONE.is_sync = false;
            this.documents.splice(INDEX_IN_DOCUMENTS, 1, DOCUMENT_CLONE);
          }
        }
      });
    },

    toggleDetails({ documentPk }) {
      if (isUndefined(this.statusDetailsInit[documentPk])) {
        this.statusDetailsInit[documentPk] = true;
      }
      this.statusDetailsShow[documentPk] = !this.statusDetailsShow[documentPk];
    },

    updateDocument({ document, statusUpdate }) {
      const INDEX_IN_DOCUMENTS = findIndex(this.documents, ["pk", document.pk]);
      if (INDEX_IN_DOCUMENTS !== -1) {
        const DOCUMENT_ALT = cloneDeep(this.documents[INDEX_IN_DOCUMENTS]);
        const DOKART_PK = document.dokument && document.dokument.dokart_id;
        const DOKART_PK_ALT = DOCUMENT_ALT.dokument && DOCUMENT_ALT.dokument.dokart_id;
        if (DOKART_PK !== DOKART_PK_ALT &&
            this.dokumentartenPkList.indexOf(DOKART_PK) === -1) { // dokart gehört zu anderer Komponente
          this.documents.splice(INDEX_IN_DOCUMENTS, 1);
        } else {
          this.documents.splice(INDEX_IN_DOCUMENTS, 1, document);
        }
        if (statusUpdate) {
          EventBus.$emit("changeDocument", {
            document,
            dokartPk: DOKART_PK,
            dokartPkAlt: DOKART_PK_ALT,
            componentId: this.htmlId,
            type: "update",
            regel: this.regel,
          });
        }
      }
    },

    vorsehenDocumentInSitzung({ document }) {
      const DATA = {
        src_dokid: document.pk
      };
      this.postHttp({
        url: `${ this.baseUrl }dokument_in_sitzung_uebernehmen/`,
        data: DATA,
      }).then(
        response => {
          this.addNotification({
            text: "_MSG_GR_DOCUMENTS_IN_SITZUNG_UEBERNEHMEN_SUCCESS_{{dok_titel}}_",
            extra: {
              dok_titel: document.dokument && document.dokument.dok_titel,
            },
          });
          this.updateDocument({ document: response, statusUpdate: true });
          const DEST_DOKART = this.sitzungsantragconfig.default_dokart;
          if (DEST_DOKART) {
            EventBus.$emit("changeDocument", {
              dokartPk: DEST_DOKART,
              componentId: this.htmlId,
              type: "create",
              regel: this.regel,
            });
          }
        },
        error => {
          if (error.status === 400) {
            if (error.data) {
              this.addNotification({ text: error.data, type: "error" });
            } else {
              this.addNotification({ text: "_MSG_GR_DOCUMENTS_VORSEHEN_IN_SITZUNG_ERROR_", type: "error" });
            }
          }
        }
      );
    },

    changeDocumentFromEventBus({ dokartPk, dokartPkAlt, componentId }) {
      if (componentId === this.htmlId) { // Event gehört zu dieser Komponente
        return;
      }
      if (this.dokumentartenPkList.indexOf(dokartPk) === -1 &&
          this.dokumentartenPkList.indexOf(dokartPkAlt) === -1) { // Dokart vom Dokument gehört nicht zu dieser Komponente
        return;
      }
      this.loadDocuments();
    },

    changeSort(id) {
      const modelSort = this.GET_SORT_MODEL(this.tableId);
      let tempId;
      if (modelSort === id) {
        tempId = `-${ id }`;
      } else if (modelSort === `-${ id }`) {
        tempId = id.replace("-", "");
      } else {
        tempId = id;
      }

      this.MUT_SET_SORT_MODEL({
        tableId: this.tableId,
        sortModel: tempId,
      });
      EventBus.$emit(`changeSort${ this.tableId }`, { id: tempId });
      this.sortBy = tempId;
    },

    changeDokart() {
      if (this.modelEditDokart && this.infoIText) {
        this.isInfoTextVisible = true;
      } else {
        this.isInfoTextVisible = false;
      }
    },

    closeInfoText() {
      this.isInfoTextVisible = false;
    },

    ...mapMutations([
      "MUT_SET_SORT_MODEL",
      "MUT_CREATE_TABLE_BY_ID",
      "MUT_REMOVE_TABLE_BY_ID",
    ]),
  },
};
