import Alert from "../../../../../global/components/Alert/Alert.vue";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FieldsMixin from "./FieldsMixin";

import {
  cloneDeep,
  forEach,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "ImportauftragJsonWizardStepVorbereiten",
  components: {
    Alert,
    FormElement,
    PuxTranslate,
  },
  mixins: [
    FieldsMixin
  ],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    updateModel: {
      type: Function,
      default: undefined,
    },
  },
  data() {
    return {
      editModel: {
        file: undefined,
      }
    };
  },
  created() {
    this.initModel();
    this.initErrorLabels();
  },
  methods: {
    initModel() {
      this.onUpdateModel();
    },

    initErrorLabels() {
      const ERROR_LABELS = {
        non_field_errors: ""
      };
      forEach(this.fields, field => {
        ERROR_LABELS[field.id] = field.label;
      });
      this.errorLabels = ERROR_LABELS;
    },

    showErrors() {
      return size(this.errors);
    },

    onUpdateModel() {
      const MODEL_CLONE = cloneDeep(this.editModel);
      this.updateModel({ model: MODEL_CLONE });
    },
  },
};
