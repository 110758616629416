"use strict";

import angular from "angular";

import BaseComponents from "dias/core/components";
import MdmKostenFinanzierungModule from "../../libs/mdm_kosten_finanzierung/client/mdm_kosten_finanzierung";
import MdmFoerdermodule from "../../libs/mdm_foerdermodule/client/mdm_foerdermodule";

import template from "./app.base.jade";


const componentModule = angular.module("app.components", BaseComponents.DIAS().concat([
  MdmKostenFinanzierungModule.name,
  MdmFoerdermodule.name,
]));

componentModule.appConfig = {
  baseTemplate: template,
  client: "mdm"
};

export default componentModule;
