"use strict";

import angular from "angular";
import diasLoading from "dias/core/loading";
import models from "./models/";
import parametermodul from "./parameter";

const zielgruppen_modul = angular.module("dias.zielgruppen", [
  diasLoading.name,
  parametermodul.name,
  models.name,
]);

export default zielgruppen_modul;
