"use strict";

class DokumenteBaseController {
	/*@ngInject*/
  constructor($window, Dokumente, Foerderantrag, Antragstellerorganisationen) {
    this.window = $window;
    this.Dokumente = Dokumente;
    this.Foerderantrag = Foerderantrag;
    this.Antragstellerorganisationen = Antragstellerorganisationen;
    this.loading = {};
    this.moduleObj = {};
    this.enterTr = {};
    this.showTrDetail = {};
  }

  saveDok(dok, index) {
    this.loading[index] = true;
    this.moduleObj.dokumente.getSaveBlob(dok.dokument.pk).then(response => {
      if (this.window.navigator.msSaveBlob) {
        this.window.navigator.msSaveBlob(response, dok.dokument.dok_dokumentname);
      } else {
        const fileURL = (this.window.URL || this.window.webkitURL).createObjectURL(response);
        const aLink = this.window.document.createElement("a");
        aLink.download = dok.dokument.dok_dokumentname;
        aLink.href = fileURL;
        aLink.target = "_self";

        const eventClick = this.window.document.createEvent("MouseEvents");
        eventClick.initEvent(
          "click", 
          true, 
          true, 
          this.window,
          0, 
          0, 
          0, 
          0, 
          0, 
          false, 
          false, 
          false, 
          false, 
          0, 
          null
        );
        aLink.dispatchEvent(eventClick);
      }
      this.loading[index] = false;
    }, () => {
      this.loading[index] = false;
    });
    return false;
  }

  toggleTrDetails(index) {
    if (this.showTrDetail[index]) {
      this.showTrDetail[index] = false;
    } else {
      this.showTrDetail[index] = true;
    }
  }
  enterRow(index) {
    this.enterTr[index] = true;
  }
  leaveRow(index) {
    this.enterTr[index] = false;
  }
}

export default DokumenteBaseController;
