import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import VertragErrorsDd from "./VertragErrorsDd/VertragErrorsDd.vue";
import VertragErrorsDt from "./VertragErrorsDt/VertragErrorsDt.vue";

import {
  EventBus,
} from "../../../../global/functions/event-bus";
import {
  scrollToGlobal,
} from "../../../../global/functions/help";
import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragErrors",
  components: {
    PuxAlert,
    PuxTranslate,
    VertragErrorsDd,
    VertragErrorsDt,
  },
  props: {
    validationErrors: {
      type: Array,
      required: false,
      default: () => [],
    },
    showErrorModulName: {
      type: Function,
      required: true,
    },
  },
  computed: {
    isVisible() {
      return this.validationErrors.length > 0;
    },
  },
  methods: {
    goTo({ error, onRegel = false }) {
      const RUBRIC_ID = error.context ||
        (error.anzeigekontext && error.anzeigekontext.pk) ||
        error.pk;
      EventBus.$emit("toggleContextPanel", {
        statusOpen: true,
        id: RUBRIC_ID,
      });
      let elementId;
      if (onRegel) {
        elementId = get(error, "modul.regelnummer");
      } else {
        elementId = (error.anzeigekontext && error.anzeigekontext.key ? `vertrag_${ error.anzeigekontext.key }` : null);
      }
      if (!elementId) {
        elementId = RUBRIC_ID;
      }
      scrollToGlobal(`#${ elementId }`);
    },
  },
};
