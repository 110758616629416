import ModulesWizardMixin from "../ModulesWizardMixin";
import PersonalkostenPeriodenFormElementsMixin from "../../../Betragsrechner/PersonalkostenPerioden/PersonalkostenPeriodenFormElementsMixin";

import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenPeriodenWizard",
  mixins: [
    ModulesWizardMixin,
    PersonalkostenPeriodenFormElementsMixin,
  ],
  data() {
    return {
      stepsCount: 5,
    };
  },
  computed: {
    step0() {
      return {
        label: "_LBL_PERSONAL_KOSTEN_PERIODEN_STEP0_",
        id: "step0",
        slotContent: "step0",
        disabled: this.tabsDisabled[0],
        index: 0,
        error: this.tabsErrors[0],
      };
    },

    step1() {
      return {
        label: "_LBL_PERSONAL_KOSTEN_PERIODEN_STEP1_",
        id: "step1",
        slotContent: "step1",
        disabled: this.tabsDisabled[1],
        index: 1,
        error: this.tabsErrors[1],
      };
    },

    step2() {
      return {
        label: "_LBL_PERSONAL_KOSTEN_PERIODEN_STEP2_",
        id: "step2",
        slotContent: "step2",
        disabled: this.tabsDisabled[2],
        index: 2,
        error: this.tabsErrors[2],
      };
    },

    step3() {
      return {
        label: "_LBL_PERSONAL_KOSTEN_PERIODEN_STEP3_",
        id: "step3",
        slotContent: "step3",
        disabled: this.tabsDisabled[3],
        index: 3,
        error: this.tabsErrors[3],
      };
    },

    step4() {
      return {
        label: "_LBL_PERSONAL_KOSTEN_PERIODEN_STEP4_",
        id: "step4",
        slotContent: "step4",
        disabled: this.tabsDisabled[4],
        index: 4,
        error: this.tabsErrors[4],
      };
    },

    stepsFields0() {
      const STEPS_FIELDS = cloneDeep(this.stepsFieldsMain0);
      STEPS_FIELDS.push(
        {
          options: "optionsForPersonalstellenperson",
          modelPath: "argument",
        },
      );
      forEach(this.standardFieldConfigs, (item, index) => {
        STEPS_FIELDS.push({
          options: `standardFieldConfigs.${ index }`,
          modelPath: `standardfelder`,
        });
      });
      return STEPS_FIELDS;
    },

    stepsFields1() {
      const STEPS_FIELDS = [];
      forEach(this.extraFields, (item, index) => {
        STEPS_FIELDS.push({
          options: `extraFields.${ index }`,
          modelPath: `extra`,
        });
      });
      return STEPS_FIELDS;
    },

    stepsFields2() {
      return [
        {
          options: "optionsForPosperiode",
          modelPath: "argument",
          visible: "isPeriodsVertrag",
        },
        {
          options: "optionsForVon",
          modelPath: "argument",
        },
        {
          options: "optionsForBis",
          modelPath: "argument",
        },
      ];
    },

    stepsFields3() {
      return [
        {
          options: "optionsForBelegbetrag",
          modelPath: "argument",
        },
        {
          options: "optionsForWert",
          modelPath: "argument",
        },
      ];
    },

    stepsFields4() {
      return [
        {
          options: "optionsForDokumentGeschwaerzt",
          modelPath: "argument",
        },
        {
          options: "optionsForVpbKommentar",
          visible: "statusHasPermVertragskostenpositionsbelegeAnerkennen",
        },
      ];
    },
  },
};
