import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import Permission from "../../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxGet from "../../../../../global/components/PuxGet/PuxGet.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spaced from "../../../../../global/components/Spaced/Spaced.vue";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "VerbundeneAntraegeAntrag",
  components: {
    AngularLink,
    Permission,
    PuxButton,
    PuxGet,
    PuxTranslate,
    Spaced,
  },
  props: {
    verbundenerAntrag: {
      type: Object,
      required: true,
    },
    verbundenerAntragIndex: {
      type: Number,
      required: true,
    },
    parentIndex: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    antragPk: {
      type: String,
      required: true,
    },
    isVerbundartAufloesbar: {
      type: Boolean,
      required: true,
    },
    isExterneNummerVisible: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    "openConfirmDeleteVerknuepfung",
  ],
  computed: {
    idLocal() {
      return `${ this.id }_${ this.parentIndex }_${ this.verbundenerAntragIndex }`;
    },

    idForButtonDelete() {
      return `${ this.idLocal }_btn_delete`;
    },

    verbundenerAntragNummerExtern() {
      return get(this.verbundenerAntrag, "verbundener_antrag.a_nr_extern");
    },

    isLinkVisible() {
      return this.verbundenerAntragPk !== this.antragPk &&
        this.verbundenerAntrag.can_link;
    },

    verbundenerAntragPk() {
      return get(this.verbundenerAntrag, "verbundener_antrag.pk");
    },
  },
  methods: {
    openConfirmDeleteVerknuepfungLocal() {
      this.$emit("openConfirmDeleteVerknuepfung", {
        verbundenerAntrag: this.verbundenerAntrag,
        idForButtonDelete: this.idForButtonDelete,
        verbundenerAntragNummerExtern: this.isExterneNummerVisible && this.verbundenerAntragNummerExtern ?
          ` ${ this.verbundenerAntragNummerExtern }` :
          "",
      });
    },
  },
};
