"use strict";

import template from "./auszahlung.aktualisierter_zuschuss.jade";

import createEditModalTemplate from "./zuschussanpassung.modal.create.jade";
import createEditModalController from "./zuschussanpassung.modal.create.controller";


class AktualisierterZuschussController {
  /*@ngInject*/
  constructor($filter, AuthService, DiasNotification, diasModalDialog, i18n) {
    this.gettext = i18n.gettext;
    this.DiasNotification = DiasNotification;
    this.diasModalDialog = diasModalDialog;
    this.name = "AktualisierterZuschussController";
    this.zuschussModule = [];
    this.currency = $filter("currency");
    for (let i = 0; i < this.module.length; i++) {
      if (this.module[i].modulgruppen.indexOf("zuschuss") !== -1 ||
         this.module[i].modulgruppen.indexOf("pauschale") !== -1 ||
         this.module[i].modulgruppen.indexOf("deckelung") !== -1) {
        this.zuschussModule.push(this.module[i]);
      }
    }
    this.perms = AuthService.syncHasPerms([
      "vertrag.zuschussanpassung.create",
      "vertrag.zuschussanpassung.update",
      "vertrag.zuschussanpassung.delete",
    ], this.vertrag.user_permissions);
  }

  getZuschussanpassungSumme() {
    if (!this.vertrag.zuschussanpassungen) {
      return 0;
    }
    let sum = 0;
    this.vertrag.zuschussanpassungen.forEach(vza => sum += Number(vza.vza_betrag || 0));
    return sum;
  }

  getZuschussangepasst() {
    if (this.vertrag.v_zuschussangepasst === null) {
      return this.antrag.a_zuschussbewilligt;
    }
    return this.vertrag.v_zuschussangepasst;
  }

  get_vertrag_betrag() {
    if (this.infoprovider && this.infoprovider.status) {
      if (this.infoprovider.status === 1) {
        return this.currency(this.infoprovider.gesamtzuschuss);
      }
      return "-";
    }
    if (this.vertrag.v_zuschussaktualisiert) {
      return this.currency(this.vertrag.v_zuschussaktualisiert);
    }
    return this.currency(0);
  }

  deleteAnpassung(vza) {
    return this.vertrag.one("zuschussanpassungen", vza.pk).remove().then(
      () => {
        const idx = this.vertrag.zuschussanpassungen.find(el => el.pk === vza.pk);
        this.vertrag.zuschussanpassungen.splice(idx, 1);
        this.vertrag.v_zuschussangepasst = Number(this.getZuschussangepasst()) - Number(vza.vza_betrag);
        this.DiasNotification.page.success(
          this.gettext("Zuschussanpassung erfolgreich gelöscht")
        );
        if (this.savecallback) {
          this.savecallback();
        }
      },
      () => this.DiasNotification.page.error(
        this.gettext("Fehler beim Löschen der Zuschussanpassung")
      )
    );
  }

  updateAnpassung(vza) {
    this.diasModalDialog({
      title: `Zuschussanpassung "${ vza.vza_kbez }" ändern`,
      template: createEditModalTemplate,
      controller: createEditModalController,
      extras: {
        vertrag: this.vertrag,
        zuschussanpassung: vza,
      }
    }).then(response => {
      this.vertrag.v_zuschussangepasst = Number(this.getZuschussangepasst()) + (Number(response.vza_betrag) - Number(vza.vza_betrag));
      Object.assign(vza, response.plain());
      if (this.savecallback) {
        this.savecallback();
      }
    });
  }

  createAnpassung() {
    this.diasModalDialog({
      title: `Zuschussanpassung erstellen`,
      template: createEditModalTemplate,
      controller: createEditModalController,
      extras: {
        vertrag: this.vertrag,
      }
    }).then(response => {
      this.vertrag.v_zuschussangepasst = Number(this.getZuschussangepasst()) + Number(response.vza_betrag);
      this.vertrag.zuschussanpassungen.push(response);
      if (this.savecallback) {
        this.savecallback();
      }
    });
  }
}

export default {
  template: template(),
  controller: AktualisierterZuschussController,
  controllerAs: "vm",
  bindings: {
    auszahlung: "<",
    vertrag: "<",
    module: "<",
    antrag: "<",
    infoprovider: "=",
    loading: "<",
    showmessages: "<",
    savecallback: "&?",
    readonly: "<",
    zuschussRelevant: "<?"
  }
};
