"use strict";
import Parameters from "../../../../../../vue/client/vue/const/StandardFoerdermoduleDynamischeTabelleParameters";


class DynamischeTabelleConfigController {
  /*@ngInject*/
  constructor(Katalog, KDokart) {
    this.Parameters = Parameters;
    this.Katalog = Katalog;
    this.KDokart = KDokart;

    this.status = {
      katalogLoading: true,
      dokartLoading: true,
      objektkatalogLoading: true,
    };
    this.loadDokumentarten();
    this.loadKatalogeList();
    this.loadobjektkatalogeList();
  }

  loadKatalogeList() {
    this.Katalog.one().get({ key: "katalog" }).then(
      response => {
        this.katalogListObj = _.keyBy(response.plain(), "pk");
        this.status.katalogLoading = false;
      }
    );
  }

  loadobjektkatalogeList() {
    this.Katalog.one().get({ key: "kobjektkatalog" }).then(
      response => {
        this.objektkatalogListObj = _.keyBy(response.plain(), "pk");
      }
    ).finally(() => this.status.objektkatalogLoading = false);
  }

  loadDokumentarten() {
    this.KDokart.getList().then(
      response => {
        this.dokartListObj = _.keyBy(response.plain(), "pk");
        this.status.dokartLoading = false;
      }
    );
  }

  preventFurtherAction($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  showAntworten() {
    if (!this.parameter[this.key].wert) {
      return [];
    }
    return _.values(this.parameter[this.key].wert);
  }
}

export default DynamischeTabelleConfigController;
