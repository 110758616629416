"use strict";

import angular from "angular";
import anchorOffset from "./anchorOffset.directive";

const diasAnchorOffset = angular.module("dias.anchorscroll", [
])

// Element wird als Offset für $anchorScroll verwendet
  .directive("anchorOffset", anchorOffset);

export default diasAnchorOffset;
