"use strict";

import BaseNavbarController from "./navbar.base.controller";

class NavbarController extends BaseNavbarController {
  /*@ngInject*/
  constructor(AuthService, $rootScope, $templateCache, $timeout, i18n, $state, SyConfigs) {
    super(AuthService, $rootScope, $templateCache, $timeout, i18n, $state, SyConfigs);
  }
}

export default NavbarController;
