import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import GutachtenVorlagenModalCreate from "../GutachtenVorlagenModalCreate/GutachtenVorlagenModalCreate.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "GutachtenVorlagen";

// @vue/component
export default {
  name: "GutachtenVorlagenList",
  components: {
    AngularLink,
    GutachtenVorlagenModalCreate,
    PageTabTitle,
    PuxPageDetails,
    PuxTranslate,
    SmartTable,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [
          {
            label: "_LBL_GUTACHTEN_VORLAGEN_LIST_NAME_",
            id: "gav_kbez",
            sortable: true,
            sortId: "gav_kbez",
            slot: "gav_kbez",
          },
          {
            label: "_LBL_GUTACHTEN_VORLAGEN_LIST_BESCHREIBUNG_",
            id: "gav_bez",
            sortable: false,
          },
          {
            label: "_LBL_GUTACHTEN_VORLAGEN_LIST_TYP_",
            id: "typ_bez",
            sortable: false,
            sortId: "typ_bez",
          },
          {
            label: "_LBL_GUTACHTEN_VORLAGEN_LIST_ERGEBNIS_",
            id: "gruppe_bez",
            sortable: false,
          },
          {
            label: "_LBL_GUTACHTEN_VORLAGEN_LIST_ERLAUTERUNG_ERZWINGEN_",
            id: "gav_erlaeuterung_pflicht",
            sortable: false,
            templateCallback: ({ row }) => this.$gettext(row.gav_erlaeuterung_pflicht ? "Ja" : "Nein"),
          },
          {
            label: "_LBL_GUTACHTEN_VORLAGEN_LIST_WORKFLOW_",
            id: "workflow_bez",
            sortable: false,
          },
          {
            label: "_LBL_GUTACHTEN_VORLAGEN_LIST_GUTACHTENOBJEKTE_",
            id: "content_types_bez",
            sortable: false,
            templateCallback: ({ row }) => `<span>${ this.$gettext(row.content_types_bez) }</span>`,
          }
        ],
        rowActions: [
          {
            label: "_LBL_GUTACHTEN_VORLAGEN_LIST_TABLE_ROW_ACTIONS_DETAILS_",
            route: "root.gutachtenvorlagen.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [
          {
            label: "_LBL_GUTACHTEN_VORLAGEN_LIST_NEUE_",
            callback: () => this.modal.create = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "button_gutachtenvorlage_create",
          },
        ],
        label: "_LBL_GUTACHTEN_VORLAGEN_LIST_",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      filters: [
        {
          type: "text",
          id: "gav_kbez",
          label: "_LBL_GUTACHTEN_VORLAGEN_LIST_NAME_",
          main: true,
        },
        {
          id: "typ",
          type: "select",
          label: "_LBL_GUTACHTEN_VORLAGEN_LIST_TYP_",
          keyId: "id",
          keyLabel: "bez",
          url: "katalog/?key=kgutachtentypen",
          apiSaveId: "typ",
          view: "v",
        },
        {
          id: "gruppe",
          type: "select",
          label: "_LBL_GUTACHTEN_VORLAGEN_LIST_ERGEBNIS_",
          keyId: "id",
          keyLabel: "bez",
          url: "katalog/?key=kgutachtenergebnisgruppen",
          apiSaveId: "gruppe",
          view: "v",
        },
        {
          id: "workflow",
          type: "select",
          label: "_LBL_GUTACHTEN_VORLAGEN_LIST_WORKFLOW_",
          keyId: "id",
          keyLabel: "bez",
          url: "katalog/?key=workflow_gutachten",
          apiSaveId: "workflow",
          view: "v",
        },
        {
          id: "content_types",
          type: "multiselect",
          label: "_LBL_GUTACHTEN_VORLAGEN_LIST_GUTACHTENOBJEKTE_",
          url: "katalog/?key=gutachtentypen",
          keyId: "id",
          keyLabel: "bez",
          search: true,
          searchList: ["bez"],
          searchParameter: "bez",
          apiSaveId: "content_types",
          view: "v",
        },
      ],
      modal: {
        create: undefined,
      },
    };
  },
  methods: {
    closeModalCreate() {
      this.modal.create = false;
    },
  },
};
