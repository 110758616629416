"use strict";

import BaseModel from "dias/core/models/baseModel";

class KAufgabe extends BaseModel {
  constructor() {
    super("aufgaben", {
      subPaths: {
        fristen: "fristen",
      }
    });
  }

  extendModel(model) {
    model = super.extendModel(model);

    model.getBerechtigungen = () => model.customGET("berechtigungen");
    model.create_copy = (newAuKbez, newKbezAdmin, newKbezBeobachter, newKbezDetail) => model.customPOST({ au_kbez: newAuKbez, au_kbez_admin: newKbezAdmin, au_kbezbeobachter: newKbezBeobachter, au_kbezdetail: newKbezDetail }, "kopieren");

    return model;
  }
}

export default KAufgabe;
