import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxDate from "../../../../global/components/PuxDate/PuxDate.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "SitzungDetailsUebersicht",
  components: {
    FormstepDetail,
    PuxDate,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    sitzung: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      optionsForFormstepDetails: {
        hideHeader: true,
      },
    };
  },
};
