import translate from "../../../../../global/directives/translate";
import GeschaeftsregelModulChild from "../../../GeschaeftsregelModulChild/GeschaeftsregelModulChild.vue";
import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import {
  forEach,
} from "lodash-es";
import { createNamespacedHelpers } from "vuex";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
const {
  mapState,
} = createNamespacedHelpers("snapshot");

const SNAPSHOT_MAPPING = {
  delete: {
    text: "_TXT_GR_ELEMENTVERZWEIGUNG_GELOESCHT_",
    icon: "delete",
  },
  add: {
    text: "_TXT_GR_ELEMENTVERZWEIGUNG_NEU_",
    icon: "add",
  },
  change: {
    text: "_TXT_GR_ELEMENTVERZWEIGUNG_GEAENDERT_",
    icon: "changes",
  },
};

// @vue/component
export default {
  name: "ElementverzweigungElement",
  components: {
    GeschaeftsregelModulChild,
    SnapshotIcon,
    PuxIcon,
  },
  directives: {
    translate,
  },
  props: {
    model: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    element: {
      type: Object,
      required: true,
    },
    elementIndex: {
      type: Number,
      required: true,
    },
    htmlRegelNummer: {
      type: String,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    unterregelnObj: {
      type: Object,
      required: true,
    },
    obj: {
      type: Object,
      required: true,
    },
    mehrfachauswahl: {
      type: Boolean,
      required: false,
    },
    childPrefix: {
      type: String,
      required: false,
      default: undefined,
    },
    statusLoadingCheckbox: {
      type: Boolean,
      required: false,
    },
    statusSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    statusSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    regelIdWithParentsRegelId: {
      type: String,
      required: true,
    },
    onSave: {
      type: Function,
      default: undefined,
    },
    updateModule: {
      type: Function,
      required: false,
      default: undefined,
    },
  },
  emits: [
    "openConfirmDeleteElement",
  ],
  computed: {
    htmlId() {
      return `${ this.htmlRegelNummer }_${ this.elementIndex }`;
    },

    optionsFormstepDetail() {
      return {
        deleteCallback: this.openConfirmDeleteElementLocal,
        headerClass: this.headerClass,
        classBody: this.classBody,
        collapse: true,
      };
    },

    headerClass() {
      if (this.statusSnapshotDelete || this.statusSnapshotAdd) {
        return "version-bg";
      }
      return "";
    },

    classBody() {
      if (this.statusSnapshotDelete || this.statusSnapshotAdd) {
        return "version_bg_light";
      }
      return "";
    },

    childSuffix() {
      return `_${ this.element.label }`;
    },

    snapshotStatusKey() {
      if (this.statusSnapshotDelete) {
        return "delete";
      }
      if (this.statusSnapshotAdd) {
        return "add";
      }
      if (this.statusSnapshotChange) {
        return "change";
      }
      return "";
    },

    snapshotHeaderText() {
      if (this.snapshotStatusKey) {
        return SNAPSHOT_MAPPING[this.snapshotStatusKey].text;
      }
      return "";
    },

    snapshotIconType() {
      if (this.snapshotStatusKey) {
        return SNAPSHOT_MAPPING[this.snapshotStatusKey].icon;
      }
      return undefined;
    },

    statusSnapshotChange() {
      let statusChange = false;
      forEach(this.element.children, unterregelPk => {
        if (this.STATUS_DIFF_FOR_REGELS[`${ unterregelPk }${ this.childSuffix }`]) {
          statusChange = true;
          return false;
        }
      });
      return statusChange;
    },

    idInput() {
      return `${ this.htmlRegelNummer }_${ this.elementIndex }`;
    },

    typeInput() {
      return this.mehrfachauswahl ? "checkbox" : "radio";
    },

    nameInput() {
      return `${ this.htmlRegelNummer }_name`;
    },

    statusChecked() {
      return false;
    },

    classInputParent() {
      return `custom-${ this.typeInput }`;
    },

    modelChildren() {
      return this.model.children || {};
    },

    checkedInput() {
      return this.model.status;
    },

    snapshotChildrenLocal() {
      if (this.statusSnapshotAdd || this.statusSnapshotDelete) {
        return {};
      }
      if (this.snapshot && this.snapshot.children) {
        return this.snapshot.children;
      }
      return {};
    },

    classElement() {
      if (this.statusSnapshotAdd || this.statusSnapshotDelete) {
        return "version_bg_light";
      }
      return "";
    },

    ...mapState([
      "STATUS_DIFF_FOR_REGELS",
    ]),
  },
  methods: {
    openConfirmDeleteElementLocal() {
      this.$emit("openConfirmDeleteElement", {
        element: this.element,
        elementIndex: this.elementIndex,
      });
    },

    childSaveLocal({ data, regel }) {
      return this.onSave({
        data,
        label: this.element.label,
        regel,
      });
    },

    clickElementLocal() {
      if (this.statusLoadingCheckbox ||
        (!this.mehrfachauswahl && this.model.status)) {
        return;
      }
      this.onSave({
        label: this.element.label,
        elementStatus: !this.model.status,
      });
    },
  },
};
