import AsideRight from "../../../global/components/AsideRight/AsideRight.vue";
import FoerderlandkarteFilterRight from "../FoerderlandkarteFilterRight/FoerderlandkarteFilterRight.vue";
import FoerderlandkarteKarte from "../FoerderlandkarteKarte/FoerderlandkarteKarte.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spinner from "../../../global/components/Spinner/Spinner.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import FoerderlandkarteKarteOptions from "../FoerderlandkarteKarte/FoerderlandkarteKarteOptions";
import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "FoerderlandkartePage",
  components: {
    AsideRight,
    FoerderlandkarteFilterRight,
    FoerderlandkarteKarte,
    PageTabTitle,
    PuxCloak,
    PuxTranslate,
    Spinner,
  },
  mixins: [
    HttpMixin,
  ],
  data() {
    return {
      loading: {
        global: true,
        map: undefined,
      },
      globalCount: undefined,
      mapParameters: undefined,
      clusters: [],
      mapOptions: cloneDeep(FoerderlandkarteKarteOptions),
      markerTypes: {},
      bounds: FoerderlandkarteKarteOptions.coordinates.de.bounds,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      Promise.all([
        this.getHttp({
          url: "foerderlandkarte/",
          urlParams: {
            usage: "proandi",
            nocontent: "1",
          },
        }),
      ]).then(
        responses => {
          this.initGlobalData({ response: responses[0] });
          this.loading.global = false;
        }
      );
    },

    initGlobalData({ response }) {
      this.mapParameters = response.general_data;
      this.setMarkerTypes();
      this.globalCount = response.unfiltered_count;
    },

    setMarkerTypes() {
      const MARKER_TYPES = {};
      forEach(this.mapParameters.typen, type => {
        MARKER_TYPES[type.key] = cloneDeep(type);
      });
      this.markerTypes = MARKER_TYPES;
    },

    onSearch({ model }) {
      this.loading.map = true;
      this.getHttp({
        url: "foerderlandkarte/?minimal=1&usage=proandi",
        urlParams: model,
      }).then(
        response => {
          this.$refs.karte.rerenderClusters({ response: response });
          this.loading.map = false;
        }
      );
    },

    changeCenter({ center }) {
      this.$refs.karte.changeCenter({ center });
    },
  },
};
