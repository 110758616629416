"use strict";

import diasLoading from "dias/core/loading";

import DropdownMultiselectComponent from "./dropdown.multiselect.component";

const diasDropdownMultiselect = angular.module("dias.dropdownMultiselect", [
  diasLoading.name
])

  .component("diasDropdownMultiselect", DropdownMultiselectComponent);

export default diasDropdownMultiselect;
