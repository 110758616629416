"use strict";

import "restangular";
import angular from "angular";
import CoreModels from "dias/core/models";

import Search from "./search.service.js";

const modelsModule = angular.module("suche.models", [
  "restangular",
  CoreModels.name
])

  .provider("Search", Search)
  .factory("SearchFactory", function() {
  // private
    let value = {};
  // public
    return {
      getValue: function() {
        return value;
      },
      setValue: function(val, status) {
        value = {
          value: val,
          status: status
        };
      }
    };
  })
;

export default modelsModule;
