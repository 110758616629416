import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../../../global/mixins/NotificationMixin";

import { initModelFromList } from "../../../../../../global/functions/mappingForParameterToFormElement";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    PuxButton,
    PuxTranslate
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      default: undefined,
    },
    model: {
      type: Object,
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      default: () => { },
    },
    selectorClose: {
      type: String,
      required: true,
    },
    objektUrlName: {
      type: String,
      default: "nutzer",
    }
  },
  data() {
    return {
      loading: false,
      modelLocal: undefined,
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    getHeader() {
      return this.model
        ? "_TXT_ABWESENHEIT_MODAL_UPDATE_TITLE_"
        : "_TXT_ABWESENHEIT_MODAL_CREATE_TITLE_";
    },
    options() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "na_gueltig_ab",
            label: "_LBL_ABWESENHEIT_AB_",
            showLabel: true,
            type: "datetime",
            view: "v",
            prio: 1,
            required: true,
          },
          {
            id: "na_gueltig_bis",
            label: "_LBL_ABWESENHEIT_BIS_",
            showLabel: true,
            type: "datetime",
            view: "v",
            prio: 2,
            required: false,
          },
          {
            id: "vertreter",
            type: "select",
            label: "_LBL_ABWESENHEIT_VERTRETER_",
            required: false,
            editonly: true,
            view: "v",
            searchGlobal: true,
            url: `${ this.objektUrlName }/${ this.nutzer.pk }/organisationsnutzer/`,
            keyLabelCallback: ({ item }) => `${ item.name || "" }${ item.abwesenheit ? ` (${ item.abwesenheit })` : "" }`,
            keyId: "pk",
            searchParameter: "suche",
          },
        ]
      };
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.model) {
        this.modelLocal = cloneDeep(this.model);
      } else {
        this.modelLocal = initModelFromList({ list: this.options.list });
      }
    },

    save() {
      if (this.isDisabledSaveButton) {
        return;
      }
      this.loading = true;
      const DATA = cloneDeep(this.modelLocal) || {};

      let httpMethod = null;
      let msg = null;
      httpMethod = this.postHttp;
      let url = `${ this.objektUrlName }/${ this.nutzer.pk }/abwesenheiten/`;
      if (this.model) {
        httpMethod = this.putHttp;
        url = `${ url }${ this.model.pk }/`;
        msg = "_MSG_ABWESENHEIT_UPDATE_SUCCESS_";
      } else {
        httpMethod = this.postHttp;
        msg = "_MSG_ABWESENHEIT_CREATE_SUCCESS_";
      }

      httpMethod({
        url: url,
        data: DATA,
      }).then(
        () => {
          this.addNotification({ text: msg });
          this.close(true);
        },
        err => {
          this.errors = err.data;
          this.errorsClone = cloneDeep(this.errors);
        }
      ).finally(() => this.loading = false);
    },
  },
};
