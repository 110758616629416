import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import { cloneDeep } from "lodash-es";

// @vue/component
export default {
  name: "AufgabenvorlagenDetailsBeschreibung",
  components: {
    FormElement,
    FormElementReadOnly,
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    kAufgabe: {
      type: Object,
      required: true,
    },
    infoTexts: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "updateAufgabenvorlage",
  ],
  setup() {
    const {
      putHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      putHttp,
    };
  },
  data() {
    return {
      loading: false,
      htmlId: "aufgabenvorlagen_details_beschreibung",
      view: "v-alt",
      model: {},
      errors: {},
      formstepOptions: {
        label: "_LBL_AUFGABENVORLAGENDETAILS_BESCHREIBUNG_",
        required: true,
        showCloseButton: true,
        editablePermission: "kaufgabe.update",
        saveCallback: this.save,
        openCallback: this.open,
        closeCallback: this.close,
      },
    };
  },
  computed: {
    optionsForAuKbez() {
      const ID = "au_kbez";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        required: true,
        showLabel: true,
        label: "_LBL_AUFGABENVORLAGENDETAILS_KURZBESCHREIBUNG_",
        view: this.view,
      };
    },

    optionsForAuKbez_admin() {
      const ID = "au_kbez_admin";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        required: true,
        showLabel: true,
        label: "_LBL_AUFGABENVORLAGENDETAILS_KURZBESCHREIBUNG_ADMINISTRATION_",
        view: this.view,
      };
    },

    optionsForAuKbezbeobachter() {
      const ID = "au_kbezbeobachter";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        required: true,
        showLabel: true,
        label: "_LBL_AUFGABENVORLAGENDETAILS_KURZBESCHREIBUNG_BEOBACHTER_",
        view: this.view,
      };
    },

    optionsForAuKbezdetail() {
      const ID = "au_kbezdetail";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        required: true,
        showLabel: true,
        label: "_LBL_AUFGABENVORLAGENDETAILS_KURZBESCHREIBUNG_DETAILLIERT_",
        view: this.view,
      };
    },

    optionsForAuBez() {
      const ID = "au_bez";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "richtext",
        required: true,
        showLabel: true,
        label: "_LBL_AUFGABENVORLAGENDETAILS_LANGBEZEICHNUNG_",
        view: this.view,
      };
    },

    optionsForAuTerminSichtbar() {
      const ID = "au_termin_sichtbar";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "boolean",
        required: true,
        showLabel: true,
        label: "_LBL_AUFGABENVORLAGENDETAILS_TERMIN_SICHTBAR_",
        view: this.view,
      };
    },

    optionsForAuPassiv() {
      const ID = "au_passiv";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "boolean",
        required: true,
        showLabel: true,
        label: "_LBL_AUFGABENVORLAGENDETAILS_PASSIV_",
        tooltip: this.infoTexts.passiv,
        view: this.view,
      };
    },

    optionsForAuEnde() {
      const ID = "au_ende";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "boolean",
        required: true,
        showLabel: true,
        label: "_LBL_AUFGABENVORLAGENDETAILS_BEARBEITUNGSENDE_",
        tooltip: this.infoTexts.ende,
        view: this.view,
      };
    },

    optionsForAuKontextConfig() {
      const ID = "au_kontext_config";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "json",
        required: false,
        showLabel: true,
        label: "_LBL_AUFGABENVORLAGENDETAILS_KONFIGURATION_ANZEIGEKONTEXTE_",
        view: this.view,
      };
    },

    fieldsColumnLeft() {
      return [
        this.optionsForAuKbez,
        this.optionsForAuKbez_admin,
        this.optionsForAuKbezbeobachter,
        this.optionsForAuKbezdetail,
        this.optionsForAuBez,
      ];
    },

    fieldsColumnRight() {
      return [
        this.optionsForAuTerminSichtbar,
        this.optionsForAuPassiv,
        this.optionsForAuEnde,
      ];
    },

    errorsLabelsOptions() {
      return [
        this.optionsForAuKbez,
        this.optionsForAuKbez_admin,
        this.optionsForAuKbezbeobachter,
        this.optionsForAuKbezdetail,
        this.optionsForAuBez,
        this.optionsForAuTerminSichtbar,
        this.optionsForAuPassiv,
        this.optionsForAuEnde,
        this.optionsForAuKontextConfig,
      ];
    },
  },
  methods: {
    save() {
      return this.putHttp({
        url: this.baseUrl,
        data: this.model,
      }).then(
        response => {
          this.addNotification({
            text: "_MSG_AUFGABENVORLAGENDETAILS_UPDATE_SUCCESS_"
          });
          this.$emit("updateAufgabenvorlage", { data: response });
          return Promise.resolve();
        },
        errors => {
          this.errors = errors.data;
          return Promise.reject(errors.data);
        },
      );
    },

    open() {
      this.model = cloneDeep(this.kAufgabe);
    },

    close() {
      this.errors = {};
    },
  },
};
