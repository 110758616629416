import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";
import Permission from "../../../../global/components/Permission/Permission.vue";
import AntragsverbundTr from "./AntragsverbundTr/AntragsverbundTr.vue";
import AntragsverbundModalCreate from "./AntragsverbundModalCreate/AntragsverbundModalCreate.vue";
import translate from "../../../../global/directives/translate";
import loading from "../../../../global/directives/loading";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import {
  cloneDeep,
  find,
  forEach,
  keyBy,
  isEqual,
  values,
} from "lodash-es";

// @vue/component
export default {
  name: "Antragsverbund",
  components: {
    SnapshotModule,
    Permission,
    AntragsverbundTr,
    AntragsverbundModalCreate,
    PuxButton,
  },
  directives: {
    translate,
    loading,
  },
  mixins: [
    ModuleMixin,
    SyConfigMixin,
  ],
  data() {
    return {
      loading: true,
      reloading: undefined,
      verbundart: {},
      antragConfig: {},
      modalCreate: false,
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      return OPTIONS;
    },

    labelHeader() {
      return this.modelParameter.titel;
    },

    modelAntraegeLength() {
      return this.model && this.model.antraege ? this.model.antraege.length : 0;
    },

    buttonErstellenTitleTranslateOptions() {
      return {
        title: "_BTN_GR_ANTRAGSVERBUND_ERSTELLEN_{{count_antraege}}_{{verbuntart}}_{{min}}_{{max}}_",
        extra: {
          count_antraege: this.modelAntraegeLength - 1,
          verbuntart: this.verbundart ? this.verbundart.vba_verbunden_bez : "",
          min: this.modelParameter.min,
          max: this.modelParameter.max,
        },
      };
    },

    maxReached() {
      return (this.modelAntraegeLength - 1) >= this.modelParameter.max;
    },

    urlSave() {
      return `${ this.baseUrl }antragsverbund/${ this.regel.pk }/`;
    },

    buttonIdErstellen() {
      return `${ this.htmlRegelNummer }_erstellen`;
    },

    buttonIdRefresh() {
      return `${ this.htmlRegelNummer }_refresh`;
    },

    selectorClose() {
      return `#${ this.buttonIdErstellen }`;
    },

    snapshotAntraege() {
      if (this.statusSnapshot &&
        this.snapshotModule &&
        this.snapshotModule.antraege &&
        this.snapshotModule.antraege.length) {
        return keyBy(this.snapshotModule.antraege, "pk");
      }
      return {};
    },

    snapshotFiltered() {
      const SNAPSHOT = {
        add: {},
        delete: [],
        changes: {},
        status: false,
      };
      if (this.statusSnapshot) {
        const SNAPSHOT_ANTRAEGE_OBJ = cloneDeep(this.snapshotAntraege);
        if (this.model.antraege && this.model.antraege.length) {
          forEach(this.model.antraege, item => {
            if (!SNAPSHOT_ANTRAEGE_OBJ[item.pk]) {
              SNAPSHOT.add[item.pk] = true;
              SNAPSHOT.status = true;
            } else {
              if (!isEqual(item, SNAPSHOT_ANTRAEGE_OBJ[item.pk])) {
                SNAPSHOT.changes[item.pk] = cloneDeep(SNAPSHOT_ANTRAEGE_OBJ[item.pk]);
                SNAPSHOT.status = true;
              }
              delete SNAPSHOT_ANTRAEGE_OBJ[item.pk];
            }
          });
        }
        SNAPSHOT.delete = values(SNAPSHOT_ANTRAEGE_OBJ);
        if (SNAPSHOT.delete.length) {
          SNAPSHOT.status = true;
        }
      }
      return SNAPSHOT;
    },

    statusDiff() {
      return this.snapshotFiltered.status;
    },

    statusNoData() {
      return !(this.snapshotFiltered.delete.length || (this.model.antraege && this.model.antraege.length));
    },

    isModuleEditable() {
      return false;
    },
  },
  created() {
    this.initSyConfig();
    this.loadData();
  },
  methods: {
    initSyConfig() {
      this.antragConfig = this.getSyConfigsValue("foerderantrag") || {};
    },

    loadData() {
      this.getHttp({
        url: "katalog/?key=kverbundart",
        apiSaveId: "kverbundart"
      }).then(
        response => {
          this.verbundart = find(response, ["pk", this.modelParameter.verbundart]) || {};
        }
      ).then(
        () => this.loading = false
      );
    },

    createAntrag() {
      this.modalCreate = true;
    },

    refresh() {
      this.reloading = true;
      this.getHttp({ url: this.moduleUrl }).then(
        response => {
          this.onRestFunctions({ response });
        }
      ).then(
        () => this.reloading = false
      );
    },

    closeModalCreate({ response } = {}) {
      if (response) {
        this.onRestFunctions({ response });
      }
      this.modalCreate = false;
    },
  },
};
