// Modul zur Zurordnung einer Terminreihe zum Antrag
"use strict";

import AuthServices from "dias/auth/services";
import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import FoerderantragModels from "dias/foerderantrag/models";
import controller from "./controller";
import template from "./template.jade";

import builder from "dias/standard_foerdermodule/utils/builder";


const mdm_terminreihe = angular.module("dias.mdm_foerdermodule.mdm_terminreihe", [
  AuthServices.name,
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name
]);

mdm_terminreihe.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("mdm_terminreihe", "modul-mdm_terminreihe");
})

  .directive("modulMdmTerminreihe", builder.directive(template, controller));

export default mdm_terminreihe;
