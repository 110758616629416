import {
  computed,
} from "vue";

import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

export default function SitzungsartNotGroupedAPI() {
  const {
    checkPermissionsSync,
  } = PermissionAPI();
  const hasPermissionSitzungsartenAdminAnonymisierungsplanUpdate = computed(() => {
    return checkPermissionsSync({
      perm: "sitzungsarten.admin.anonymisierungsplan.update",
    });
  });

  return {
    hasPermissionSitzungsartenAdminAnonymisierungsplanUpdate,
  };
}
