import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "StyleguideModalConfirm",
  components: {
    Modal,
    PuxButton,
  },
  data() {
    return {
      confirmShow: undefined,
      confirmOptions: undefined,

      propsPossible: [
        {
          name: "confirmOptions",
          description: "Einstellungen",
          type: "Object",
        },
      ],
      confirmOptionsTable: [
        {
          name: "okClass",
          type: "String",
          description: "Klasse für die Schaltfläche 'Ja'",
          default: "undefined",
          example: "btn btn-primary",
        },
        {
          name: "title",
          type: "String",
          description: "Überschrift",
          default: "undefined",
          example: "Das ist die Überschrift",
        },
        {
          name: "msg",
          type: "String",
          description: "Html für 'modal-body'",
          default: "undefined",
          example: "<p>Sind Sie sicher?</p>",
        },
        {
          name: "okLabel",
          type: "String",
          description: "der Name für die Schaltfläche 'Ja'",
          default: "Speichern",
          example: "Ja",
        },
        {
          name: "cancelLabel",
          type: "String",
          description: "der Name für die Schaltfläche 'Nein'",
          default: "Abbrechen",
          example: "Nein",
        },
        {
          name: "okCallback",
          type: "String",
          description: "Die Funktion für die Schaltfläche 'Ja'",
          default: "undefined",
          example: "() => { console.log('Ja'); this.modalShow = false; }",
        },
        {
          name: "cancelCallback",
          type: "String",
          description: "Die Funktion für die Schaltfläche 'Nein'",
          default: "undefined",
          example: "() => this.modalShow = false",
        },
        {
          name: "loading",
          type: "String",
          description: "Spinner im Footer",
          default: "undefined",
          example: "true",
        },
      ],
    };
  },
  methods: {
    openConfirm() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "Dokument löschen",
        msg: "<p>Sind Sie sicher?</p>",
        okLabel: "Löschen",
        cancelLabel: "Abbrechen",
        okCallback: () => {
          this.closeConfirm();
        },
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.confirmShow = true;
    },

    closeConfirm() {
      this.confirmShow = false;
    },
  },
};
