var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<fieldset><div ng-attr-id=\"{{ vm.errorContainerId }}\"></div><div class=\"row\"><div class=\"col-12 col-sm-6\">");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"si_sitzungsdatum\" ng-model=\"vm.model.si_sitzungsdatum\" type=\"text\" bs-datepicker=\"bs-datepicker\" name=\"si_sitzungsdatum\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.si_sitzungsdatum ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "si_sitzungsdatum", "Termin");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"si_buchungsdatum\" ng-model=\"vm.model.si_buchungsdatum\" type=\"text\" bs-datepicker=\"bs-datepicker\" name=\"si_buchungsdatum\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.si_buchungsdatum ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "si_buchungsdatum", "Buchungsdatum");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"si_redaktionsschluss\" ng-model=\"vm.model.si_redaktionsschluss\" type=\"text\" bs-datepicker=\"bs-datepicker\" name=\"si_redaktionsschluss\" ng-attr-aria-invalid=\"{{vm.errors.si_redaktionsschluss ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "si_redaktionsschluss", "Redaktionsschluss");
buf.push("</div><div class=\"col-12 col-sm-6\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"si_durchfuehrungsort\" ng-model=\"vm.model.si_durchfuehrungsort\" type=\"text\" name=\"si_durchfuehrungsort\" ng-attr-aria-invalid=\"{{vm.errors.si_durchfuehgungsort ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "si_durchfuehgungsort", "Durchführungsort");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"si_strasse\" ng-model=\"vm.model.si_strasse\" type=\"text\" name=\"si_strasse\" ng-attr-aria-invalid=\"{{vm.errors.si_strasse ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "si_strasse", "Straße");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"si_hsnr\" ng-model=\"vm.model.si_hsnr\" type=\"text\" name=\"si_hsnr\" ng-attr-aria-invalid=\"{{vm.errors.si_hsnr ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "si_hsnr", "Hausnummer");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"si_plz\" ng-model=\"vm.model.si_plz\" type=\"text\" name=\"si_plz\" ng-attr-aria-invalid=\"{{vm.errors.si_plz ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "si_plz", "Postleitzahl");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"si_ort\" ng-model=\"vm.model.si_ort\" type=\"text\" name=\"si_ort\" ng-attr-aria-invalid=\"{{vm.errors.si_ort ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "si_ort", "Ort");
buf.push("</div><div class=\"col-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<textarea id=\"si_info\" ng-model=\"vm.model.si_info\" dias-richtext type=\"text\" name=\"si_info\" ng-attr-aria-invalid=\"{{vm.errors.si_info ? 'true' : 'false'}}\" class=\"form-control\"></textarea>");
}
}, "si_info", "{{:: vm.gettext('_LBL_SITZUNG_SI_INFO_')}}");
buf.push("</div></div></fieldset><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Abbrechen</span></button></div></div>");;return buf.join("");
}