import FormstepDetail from "../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";
import HochschuleDetailsStandort from "./HochschuleDetailsStandort/HochschuleDetailsStandort.vue";
import HochschuleDetailsStandorteModalCreate from "./HochschuleDetailsStandorteModalCreate/HochschuleDetailsStandorteModalCreate.vue";
import PuxButton from "../../../../../../../libs/vue/client/vue/global/components/PuxButton/PuxButton.vue";

import HttpMixin from "../../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import PermissionMixin from "../../../../../../../libs/vue/client/vue/global/mixins/PermissionMixin";

// @vue/component
export default {
  name: "HochschuleDetailsStandorte",
  components: {
    FormstepDetail,
    HochschuleDetailsStandort,
    HochschuleDetailsStandorteModalCreate,
    PuxButton,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    hochschule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      standorte: [],
      loading: false,
      isModalCreateVisible: undefined,
      confirmDeleteShow: undefined,
      confirmDeleteOptions: undefined,
    };
  },
  computed: {
    htmlId() {
      return "hochschule_details_standorte_formstep";
    },

    hasPermissionHochschuleUpdate() {
      return this.checkPermissionsSync({
        perm: "hochschule.update",
        permArray: this.hochschule.user_permissions,
      });
    },

    formstepDetailOptions() {
      return {
        hideHeader: true,
      };
    },

    standorteUrl() {
      return `hochschulen/${ this.hochschule.pk }/standorte/`;
    },

  },
  created() {
    this.loadStandorte();
  },
  methods: {
    loadStandorte() {
      this.loading = true;
      this.getListHttp({
        url: this.standorteUrl,
      }).then(
        response => {
          this.standorte = response;
          this.loading = false;
        }
      );
    },

    openModalCreate() {
      this.isModalCreateVisible = true;
    },

    closeModalCreate() {
      this.isModalCreateVisible = false;
    },
  },
};
