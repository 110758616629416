"use strict";

export default /*@ngInject*/ $timeout => {
  return {
    restrict: "A",
    link: function(scope, element, attrs) {
      $timeout(function() {
        element[0].firstChild.firstChild.title = attrs.titleForDiasSelect;
      });
    }
  };
};
