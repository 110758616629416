import {
  EventBus,
} from "../../functions/event-bus";
import {
  createNamespacedHelpers,
} from "vuex";
import {
  cloneDeep,
  concat,
  find,
  findIndex,
  forEach,
  get,
  isArray,
  isNil,
  isPlainObject,
  isUndefined,
  size,
  uniq,
} from "lodash-es";

const { mapMutations, mapGetters } = createNamespacedHelpers("table");

export default {
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    startSearch: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      model: {},
    };
  },
  computed: {
    idLocal() {
      return this.options.id;
    },

    ...mapGetters([
      "GET_TABLE_MODEL_BY_ID",
    ]),
  },
  created() {
    this.tf_initEventBus();
    this.tf_initModel();
  },
  beforeUnmount() {
    EventBus.$off(`removeFilter_${ this.options.id }`, this.tf_removeModel);
  },
  methods: {
    tf_initEventBus() {
      EventBus.$on(`removeFilter_${ this.options.id }`, this.tf_removeModel);
    },

    tf_removeModel({ filterId, value, all, filterKey, statusUpdate = true, allForCurrentFilter }) {
      if (all) {
        const model = cloneDeep(this.model);
        forEach(model, (item, key) => {
          if (isArray(item)) {
            model[key] = [];
          } else if (isPlainObject(item)) {
            forEach(item, (value, keyValue) => {
              model[key][keyValue] = undefined;
            });
          } else {
            model[key] = undefined;
          }
        });
        this.model = model;
      } else {
        if (allForCurrentFilter) { // Wenn eine Fehler gibt
          const MODEL = cloneDeep(this.model);
          if (isArray(MODEL[filterId])) {
            MODEL[filterId] = [];
          } else if (isPlainObject(MODEL[filterId])) {
            forEach(MODEL[filterId], (item, key) => {
              MODEL[filterId][key] = undefined;
            });
          } else {
            MODEL[filterId] = undefined;
          }
          this.model = MODEL;
          this.MUT_CHANGE_MODEL_TABLE_BY_ID({
            id: this.options.id,
            model: this.tf_removeEmptyValues(),
          });
        } else if (value && isArray(this.model[filterId])) {
          const index = findIndex(this.model[filterId], item => item === value);
          if (index !== -1) {
            this.model[filterId].splice(index, 1);
          }
        } else if (filterKey) {
          const model = cloneDeep(this.model);
          model[filterId][filterKey] = undefined;
          this.model = model;
        } else {
          const model = cloneDeep(this.model);
          model[filterId] = undefined;
          this.model = model;
        }
      }
      if (statusUpdate) {
        this.onSearch();
      }
    },

    tf_initModel() {
      const model = cloneDeep(this.GET_TABLE_MODEL_BY_ID(this.options.id));
      this.filters.forEach(filter => {
        if (!model[filter.id]) {
          model[filter.id] = this.tf_setModelDefault({ filter });
        } else {
          // TODO: Ilia
        }
      });

      this.model = model;
      this.tf_initModelLabels();
      this.MUT_CHANGE_MODEL_TABLE_BY_ID({ id: this.options.id, model: model });
      this.status.loading = false;
    },

    tf_setModelDefault({ filter }) {
      // if (filter.default) {
      //   return filter.default;
      // }

      if (filter.type === "multiselect") {
        return [];
      }

      if (filter.type === "checkbox") {
        return [];
      }

      if (filter.type === "radio") {
        return [];
      }
      if (filter.type === "daterange") {
        return {
          [`${ filter.id }_after`]: undefined,
          [`${ filter.id }_before`]: undefined,
        };
      }

      if (filter.type === "numberrange") {
        return {
          [`${ filter.id }_min`]: undefined,
          [`${ filter.id }_max`]: undefined,
        };
      }

      return undefined;
    },

    tf_initModelLabels() {
      const labels = {};
      this.filters.forEach(filter => {
        if (isArray(this.model[filter.id]) && this.model[filter.id].length) {
          forEach(this.model[filter.id], item => {
            let childLabel;
            if (filter.type === "multiselect") {
              if (filter.data) {
                if (filter.keyArray) {
                  const index = filter.data.indexOf(item);
                  if (index === -1) {
                    return;
                  }
                  childLabel = filter.data[index];
                } else {
                  childLabel = get(find(filter.data, [filter.keyId || "id", item]), filter.keyLabel || "label");
                }
              }
            } else {
              childLabel = get(find(filter.data, ["value", item]), "label");
            }

            if (childLabel) {
              labels[`${ filter.id }_${ item }`] = {
                child: childLabel,
                parent: filter.label,
              };
            }
          });
        } else if (filter.type === "daterange") {
          labels[`${ filter.id }_after`] = {
            child: filter.label,
            suffix: " von",
          };
          labels[`${ filter.id }_before`] = {
            child: filter.label,
            suffix: " bis",
          };
        } else if (filter.type === "numberrange") {
          labels[`${ filter.id }_min`] = {
            child: filter.label,
            suffix: " von",
          };
          labels[`${ filter.id }_max`] = {
            child: filter.label,
            suffix: " bis",
          };
        } else if (filter.type === "boolean") {
          labels[filter.id] = {
            child: filter.label
          };
          const TRUE_LABEL = filter.trueLabel || "Ja";
          if (isNil(filter.trueValue) || RegExp(/true/i).test(filter.trueValue)) {
            labels[filter.id].True = TRUE_LABEL;
            labels[filter.id].true = TRUE_LABEL;
          } else {
            labels[filter.id][filter.trueValue] = TRUE_LABEL;
          }
          const FALSE_LABEL = filter.falseLabel || "Nein";
          if (isNil(filter.falseValue) || RegExp(/false/i).test(filter.falseValue)) {
            labels[filter.id].False = FALSE_LABEL;
            labels[filter.id].false = FALSE_LABEL;
          } else {
            labels[filter.id][filter.falseValue] = FALSE_LABEL;
          }
        } else if (!isNil(this.model[filter.id])) {
          if (filter.type === "select") {
            if (filter.data) {
              let childLabel;
              if (filter.keyArray) {
                const index = filter.data.indexOf(this.model[filter.id]);
                if (index === -1) {
                  return;
                }
                childLabel = filter.data[index];
              } else {
                childLabel = get(find(filter.data, [filter.keyId || "id", this.model[filter.id]]), filter.keyLabel || "label");
              }
              labels[`${ filter.id }_${ this.model[filter.id] }`] = {
                child: childLabel,
                parent: filter.label,
              };
            }
          } else {
            labels[filter.id] = { child: filter.label };
          }
        } else if (
          filter.type !== "select" &&
          filter.type !== "multiselect" &&
          filter.type !== "radio" &&
          filter.type !== "checkbox"
        ) {
          labels[filter.id] = { child: filter.label };
        }
        if (filter.emptyValue && filter.emptyLabel) {
          labels[`${ filter.id }_${ filter.emptyValue }`] = {
            child: filter.emptyLabel,
            parent: filter.label,
          };
        }
        if (filter.extraValue && filter.extraLabel) {
          labels[`${ filter.id }_${ filter.extraValue }`] = {
            child: filter.extraLabel,
            parent: filter.label,
          };
        }
      });
      this.MUT_INIT_LABEL_BY_ID({ id: this.options.id, labels: labels });
    },

    tf_removeEmptyValues({ statusNotUrlParameter } = {}) {
      const model = cloneDeep(this.model);
      forEach(model, (item, index) => {
        if (isUndefined(item) || (isArray(item) && !item.length) || item === "") {
          delete model[index];
        } else if (isPlainObject(item)) {
          forEach(item, (i, k) => {
            if (isNil(i)) {
              delete item[k];
            }
          });
          if (!size(item)) {
            delete model[index];
          }
        }
      });
      return statusNotUrlParameter ? model : this.tf_changeModelValuesUrlParameter(model);
    },

    tf_changeModelValuesUrlParameter(model) {
      const MODEL_CONST = model;
      forEach(this.filters, filter => {
        if (!filter.urlParameter || !MODEL_CONST[filter.id]) {
          return;
        }
        if (filter.type === "daterange") {
          MODEL_CONST[`${ filter.urlParameter }_after`] = MODEL_CONST[filter.id][`${ filter.id }_after`];
          MODEL_CONST[`${ filter.urlParameter }_before`] = MODEL_CONST[filter.id][`${ filter.id }_before`];
          delete MODEL_CONST[filter.id];
        } else if (filter.type === "numberrange") {
          if (MODEL_CONST[`${ filter.id }_min`]) {
            MODEL_CONST[`${ filter.urlParameter }_min`] = MODEL_CONST[filter.id][`${ filter.id }_min`];
            MODEL_CONST[`${ filter.urlParameter }_max`] = MODEL_CONST[filter.id][`${ filter.id }_max`];
            delete MODEL_CONST[filter.id];
          }
        } else {
          if (isArray(MODEL_CONST[filter.urlParameter])) {
            if (isArray(MODEL_CONST[filter.id])) {
              MODEL_CONST[filter.urlParameter] = concat(MODEL_CONST[filter.urlParameter], MODEL_CONST[filter.id]);
            } else {
              MODEL_CONST[filter.urlParameter].push(MODEL_CONST[filter.id]);
            }
            MODEL_CONST[filter.urlParameter] = uniq(MODEL_CONST[filter.urlParameter]);
          } else {
            MODEL_CONST[filter.urlParameter] = MODEL_CONST[filter.id];
          }
          delete MODEL_CONST[filter.id];
        }
      });
      return MODEL_CONST;
    },

    ...mapMutations([
      "MUT_CHANGE_MODEL_TABLE_BY_ID",
      "MUT_ADD_LABEL_BY_ID",
      "MUT_INIT_LABEL_BY_ID",
    ]),
  },

};
