import ModuleMixin from "../ModuleMixin";

// @vue/component
export default {
  name: "EinfacheFoerderregel",
  mixins: [
    ModuleMixin,
  ],
  computed: {
    labelHeader() {
      return this.regel.afr_kbez;
    },

    isModuleEditable() {
      return false;
    },
  },
};
