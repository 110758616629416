var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-element"] = jade_interp = function(label, fieldName){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div><label" + (jade.attr("ng-class", "{'has-error': vm.errors['" + (fieldName) + "']}", true, true)) + " style=\"display: block;\" class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span><div class=\"font-weight-inside-normal\">");
block && block();
buf.push("<div" + (jade.attr("ng-if", "vm.errors['" + (fieldName) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (fieldName) + "']", true, true)) + ">{{ err }}</li></ul></div></div></label></div>");
};
buf.push("<div class=\"form-step active form-step-box\"><div ng-if=\"!vm.passwortAbgelaufenStatus\" class=\"form-step-detail\"><h4>Anmelden</h4><div ng-attr-id=\"{{ vm.DiasNotification.form.containerId }}\"></div><alert ng-if=\"vm.error\" type=\"'danger'\" dismissable=\"false\"><strong>Bitte überprüfen Sie Ihre Eingaben</strong><p ng-bind-html=\"vm.error\"></p></alert><form>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input id=\"id_username\" name=\"username\" ng-change=\"vm.deleteErrors()\" ng-model=\"vm.model.username\" type=\"text\" placeholder=\"E-Mail-Adresse\" ng-disabled=\"vm.authenticating\" ng-attr-aria-invalid=\"{{vm.errors.username ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "E-Mail-Adresse*", "login");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input id=\"id_password\" name=\"password\" ng-change=\"vm.deleteErrors()\" ng-model=\"vm.model.password\" type=\"password\" aria-describedby=\"id_password_help\" placeholder=\"Passwort\" ng-disabled=\"vm.authenticating\" ng-attr-aria-invalid=\"{{vm.errors.passwort ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "Passwort*", "passwort");
buf.push("<div><dias-requirement></dias-requirement></div><div class=\"text-right margin-bottom-sm\"><span ng-if=\"vm.authenticating\"><i class=\"spinner inline\"><div class=\"bounce1\"></div><div class=\"bounce2\"></div><div class=\"bounce3\"></div></i><span>&nbsp;</span></span><button type=\"submit\" ng-click=\"vm.login()\" ng-disabled=\"vm.authenticating\" class=\"btn btn-primary\"><span>Anmelden</span></button></div><div class=\"margin-bottom-sm\"><strong>Passwort vergessen?</strong><a ui-sref=\"root.reset_password\" class=\"margin-left-xs\">Passwort zurücksetzen</a></div><div ng-if=\"!vm.regLinkHide\"><strong>Haben Sie noch keinen Zugang?</strong><a ui-sref=\"root.registration\" class=\"margin-left-xs\">Registrieren Sie sich</a></div><div ng-if=\"vm.regFooter\" ng-bind-html=\"vm.regFooter\"></div></form></div><div class=\"form-step-detail\"><dias-password-change ng-if=\"vm.passwortAbgelaufenStatus\" reg-header=\"{labelChangePassword: '&lt;h4&gt;Passwort aktualisieren&lt;/h4&gt;'}\"></dias-password-change></div></div>");;return buf.join("");
}