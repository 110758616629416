import PuxButton from "../../PuxButton/PuxButton.vue";

import HttpMixin from "../../../mixins/HttpMixin";
import UIComponentMixin from "../UIComponentMixin";

import {
  cloneDeep,
  isEqual,
  size
} from "lodash-es";

// @vue/component
export default {
  name: "UiTextbaustein",
  components: {
    PuxButton,
  },
  mixins: [
    HttpMixin,
    UIComponentMixin,
  ],
  data() {
    return {
      textbaustein: {
        loading: false,
        loadedGruppen: undefined,
        selected: undefined,
        selectedItem: undefined,
        data: [],
      },
    };
  },
  computed: {
    textbausteingruppen() {
      return this.options.textbausteingruppen;
    },

    showTextbausteinSelect() {
      return size(this.options.textbausteingruppen) > 0;
    },

    textbausteinSelectOptions() {
      return {
        id: "textbaustein",
        htmlId: `${ this.htmlIdLocal }_textbaustein_auswahl`,
        type: "select",
        translate: true,
        view: "v-alt",
        keyLabel: "bez",
        keyId: "pk",
        search: true,
        searchList: ["bez"],
        keyGroup: "group",
        placeholder: "_LBL_UI_CKEDITOR_EDIT_TEXTBAUSTEIN_SELECT_PLACEHOLDER_",
        loading: this.textbaustein.loading,
      };
    },

    insertTextbausteinBtnId() {
      return `${ this.htmlIdLocal }_textbaustein_auswahl_insert`;
    },

    optionsCkeditor() {
      const ID = this.getId;
      return {
        id: ID,
        htmlId: this.htmlIdLocal,
        type: "richtext",
      };
    },
  },
  watch: {
    options() {
      if (!isEqual(this.textbaustein.loadedGruppen, this.textbausteingruppen)) {
        this.loadTextbausteine();
      }
    },
  },
  beforeCreate() {
    this.$options.components.FormElement = require("../../FormElement/FormElement.vue").default;
  },
  created() {
    this.loadTextbausteine();
  },
  methods: {
    loadTextbausteine() {
      if (size(this.textbausteingruppen) === 0) {
        this.textbaustein.data = undefined;
        return;
      }
      this.textbaustein.loading = true;
      this.getListHttp({
        url: "katalog/?key=textbausteine",
        urlParams: {
          gruppe: this.textbausteingruppen,
        },
      }).then(
        response => {
          this.textbaustein.data = response;
          this.textbaustein.loadedGruppen = cloneDeep(this.textbausteingruppen);
        }
      ).finally(() => this.textbaustein.loading = false);
    },

    setTextbaustein({ model, item }) {
      this.textbaustein.selected = model;
      this.textbaustein.selectedItem = item;
    },

    onInsertBaustein() {
      if (!this.textbaustein.selectedItem) {
        return;
      }
      const instance = CKEDITOR.instances[this.optionsCkeditor.htmlId];
      instance.insertHtml(this.textbaustein.selectedItem.txb_text);
      this.changeModelLocal({ model: instance.getData() });
      this.textbaustein.selected = undefined;
      this.textbaustein.selectedItem = undefined;
    },

    changeModelLocal({ model }) {
      this.onInput_mixin({ value: model });
    },
  },
};
