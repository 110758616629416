// @vue/component
export default {
  data() {
    return {
      fields: [
        {
          id: "files",
          type: "document",
          label: "_LBL_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_WEITERE_ANLAGEN_",
          required: false,
          multiple: true,
          drop: "true",
          view: "v-alt",
          translate: true,
        },
      ]
    };
  },
};
