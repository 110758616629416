"use strict";

class DefaultWizardController {
  constructor() {
    this.visitedSteps = {
      0: true,
    };
    this.activeStep = 0;
    this.forwardStepDisabled = false;
    this.backStepDisabled = false;
    this.closeWizardStatus = false;
  }

  forwardStep() {
    this.activeStep += 1;
    this.visitedSteps[this.activeStep] = true;
  }

  backStep() {
    this.activeStep -= 1;
  }

  backTargetStep(currentIndex, targetIndex) {
    this.activeStep = targetIndex;
  }
}

export default DefaultWizardController;
