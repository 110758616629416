import TableCellMixin from "../TableCellMixin";
import {
  forEach,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "TableViewTableCellLink",
  mixins: [
    TableCellMixin
  ],
  props: {
    text: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    toForLink() {
      const LINK_TO = this.options.linkTo;
      const TO_OBJ = {
        name: LINK_TO.name,
      };
      if (LINK_TO.params) {
        TO_OBJ.params = {};
        forEach(LINK_TO.params, (param, key) => {
          TO_OBJ.params[key] = get(this.row, param, param);
        });
      }
      return TO_OBJ;
    },
  },
};
