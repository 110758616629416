import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import PuxChart from "../../../../global/components/PuxChart/PuxChart.vue";

import DashboardIconMixin from "../DashboardIconMixin";
import DashboardModelFilterMixin from "../DashboardModelFilterMixin";
import DashboardModuleMixin from "../DashboardModuleMixin";
import DashboardStylesMixin from "../DashboardStylesMixin";
import HttpMixin from "../../../../global/mixins/HttpMixin";

import {
  isArray,
  sortBy,
  forEach,
  cloneDeep,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "DashboardAufgabenFrist",
  components: {
    AngularLink,
    PuxChart,
  },
  mixins: [
    DashboardIconMixin,
    DashboardModelFilterMixin,
    DashboardModuleMixin,
    DashboardStylesMixin,
    HttpMixin,
  ],
  data() {
    return {
      relevantList: [],
      overallObj: {},
    };
  },
  computed: {
    parameter() {
      return this.argument.parameter || {};
    },

    hoverTitle() {
      return {
        prefixTitle: this.parameter.hover_prefix_mehrzahl,
        suffixTitle: this.parameter.hover_suffix_mehrzahl,
        prefixTitleOne: this.parameter.hover_prefix_einzahl,
        suffixTitleOne: this.parameter.hover_suffix_einzahl,
      };
    },

    total() {
      if (!this.data || !this.data.stats) {
        return 0;
      }
      let total = 0;
      forEach(this.data.stats, stat => {
        total += stat.count;
      });
      return total;
    },

    attributesLocal() {
      if (this.total > 0) {
        return this.linkAttributesLocal;
      }
      return {};
    },

    linkAttributesLocal() {
      const ATTRIBUTES = {
        "aria-labelledby": this.ariaLabelledbyForBadgeLink,
      };
      if (!size(this.overallObj)) {
        ATTRIBUTES.href = "#";
        return ATTRIBUTES;
      }
      if (this.overallObj.value === 1 && this.overallObj.goToRelevantesObjekt && this.overallObj.relevantesObjektPk) {
        ATTRIBUTES.sref = this.overallObj.goToRelevantesObjekt;
        if (isArray(this.overallObj.relevantesObjektPk)) {
          ATTRIBUTES.parameter = {
            id: this.overallObj.relevantesObjektPk[0],
            aus_id: this.overallObj.relevantesObjektPk[1]
          };
        } else {
          ATTRIBUTES.parameter = {
            id: this.overallObj.relevantesObjektPk,
          };
        }
        return ATTRIBUTES;
      }
      ATTRIBUTES.sref = this.overallObj.goTo;
      ATTRIBUTES.options = this.overallObj.goToParams;
      return ATTRIBUTES;
    },

    componentLocal() {
      return this.total === 0 ? "div" : "angular-link";
    },
  },
  watch: {
    parameter() {
      this.initParams();
    },

    data() {
      this.init();
    },
  },
  created() {
    this.initParams();
    this.init();
  },
  methods: {
    init() {
      if (!this.data || !this.data.stats) {
        this.relevantList = [];
        return;
      }
      const RELEVANT_LIST = [];
      this.getHttp({
        url: "katalog/",
        apiSaveId: "friststatus_dashboard",
        urlParams: {
          key: "friststatus",
          fields: ["bez", "pk", "fst_cssklasse", "fst_pos"],
        },
      }).then(
        response => {
          if (!isArray(response)) {
            return;
          }
          const OVERALL_OBJ = cloneDeep(this.overallObj) || {};
          OVERALL_OBJ.value = 0;
          forEach(sortBy(response, ["fst_pos"]), status => {
            const GO_TO_PARAMS = Object.assign(
              { status: this.parameter.au_ids, friststatus: status.id },
              this.additionalFilters
            );

            OVERALL_OBJ.value += this.data.stats[status.pk].count;
            OVERALL_OBJ.relevantesObjektPk = OVERALL_OBJ.relevantesObjektPk || this.data.stats[status.pk].relevantes_objekt_pk;
            RELEVANT_LIST.push({
              value: this.data.stats[status.pk].count,
              signature: status.bez,
              title: this.hoverTitle,
              class: status.fst_cssklasse,
              goTo: this.parameter.goto_list,
              goToParams: GO_TO_PARAMS,
              relevantesObjektPk: this.data.stats[status.pk].relevantes_objekt_pk,
              goToRelevantesObjekt: this.parameter.goto_details,
            });
          });
          this.relevantList = RELEVANT_LIST;
          this.overallObj = OVERALL_OBJ;
        }
      );
    },

    initParams() {
      if (!size(this.parameter)) {
        return;
      }
      this.overallObj = {
        value: 0,
        goTo: this.parameter.goto_list,
        goToParams: Object.assign(
          { status: this.parameter.au_ids },
          this.additionalFilters
        ),
        relevantesObjektPk: undefined,
        goToRelevantesObjekt: this.parameter.goto_details,
      };
    },
  },
};
