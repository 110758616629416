"use strict";

import "angular-smart-table/smart-table.min.js";
import "angularjs-dropdown-multiselect";
import "ngstorage";
import diasLoading from "dias/core/loading";
import diasCsv from "dias/core/csv";
import diasModal from "dias/core/modal";
import diasConfirm from "dias/core/confirm";

import paginationTemplate from "./pagination.jade";
import InitDirective from "./table.init.directive";
import TableComponent from "./table.component";
import SimpleTableComponent from "./table.simple.component";
import DndTableComponent from "./table.dnd.component";
import CellComponent from "./cell.component.js";

import services from "./services";


const paginationTemplateName = "templates/dias.table.pagination.html";


const diasTable = angular.module("dias.table", [
  "smart-table",
  "angularjs-dropdown-multiselect",
  "ngStorage",
  diasLoading.name,
  diasCsv.name,
  diasModal.name,
  diasConfirm.name,
  services.name
])

// Setzen des Templates für die Paginierung.
// Hinweis: $templateCache ist nicht in der config-Phase verfügbar.
  .config(stConfig => {
    stConfig.pagination.template = paginationTemplateName;
  })
  .run($templateCache => {
    $templateCache.put(paginationTemplateName, paginationTemplate());
  })

// Direktiven
  .directive("diasTable", TableComponent)
  .directive("diasSimpleTable", SimpleTableComponent)
  .directive("diasDndTable", DndTableComponent)
  .directive("stDiasTableCell", CellComponent)

  .directive("diasTableInit", InitDirective);

export default diasTable;
