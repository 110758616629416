import ModalCreateOrUpdate from "./ModalCreateOrUpdate/ModalCreateOrUpdate.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import ShowMore from "../../../global/components/ui/ShowMore/ShowMore.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import { FilterDateMixin } from "../../../global/mixins/FiltersMixin";

import getTableApi from "../../../const/TableApis";

import { EventBus } from "../../../global/functions/event-bus";

import moment from "moment";
import {
  assign,
  isNil,
  isString,
} from "lodash";

const TABLE_ID = "rollenfreigabeListInFoerderorg";

// @vue/component
export default {
  name: "FoerderorganisationRollenfreigabenList",
  components: {
    ModalCreateOrUpdate,
    Modal,
    PuxIcon,
    ShowMore,
    SmartTable,
  },
  mixins: [
    FilterDateMixin,
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    organisation: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      modal: {
        create: undefined,
        update: undefined,
      },
      confirmOptions: undefined,
    };
  },
  computed: {
    rollenfreigabenTableOptions() {
      const OPTIONS = {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID, params: { pk: this.organisation.pk } }),
        cols: [
          {
            label: "_LBL_FOERDERORGROLLEN_FREIGABE_AKTIV_",
            id: "is_active",
            className: "table_smart__table__col_icon_50px",
            slot: "aktiv",
            sortable: true,
            sortId: "_is_active",
            permissions: ["foerderorgrollen.freigabe.view.inactive"],
          },
          {
            label: "_LBL_FOERDERORGROLLEN_FREIGABE_ROLLE_",
            id: "rolle_bez",
            sortId: "rolle__fro_kbez",
            sortable: true,
          },
          {
            label: "_LBL_FOERDERORGROLLEN_FREIGABE_GUELTIG_AB_",
            id: "frf_gueltig_ab",
            sortable: true,
            filter: "date",
            titleCallback: ({ item }) => this.filterDate(item.frf_gueltig_ab, "DD.MM.YY HH:mm [Uhr]")
          },
          {
            label: "_LBL_FOERDERORGROLLEN_FREIGABE_GUELTIG_BIS_",
            id: "frf_gueltig_bis",
            sortable: true,
            filter: "date",
            titleCallback: ({ item }) => this.filterDate(item.frf_gueltig_bis, "DD.MM.YY HH:mm [Uhr]")
          },
          {
            label: "_LBL_FOERDERORGROLLEN_FREIGABE_VERWENDEN_",
            id: "frf_verwenden",
            sortable: true,
            filter: "boolean",
          },
          {
            label: "_LBL_FOERDERORGROLLEN_FREIGABE_WEITERREICHEN_",
            id: "frf_weiterreichen",
            sortable: true,
            filter: "boolean",
          },
        ],
        rowActions: [
          {
            label: "_BTN_EDIT_",
            isHidden: ({ item }) => this.isPast(item.frf_gueltig_bis) || !this.canUpdate({ item }),
            callback: ({ item, index }) => this.openModalUpdate({ item, index }),
          }, {
            label: "_BTN_DEACTIVATE_",
            isHidden: ({ item }) => !item.is_active || !this.canDelete({ item }),
            callback: ({ item, index }) => this.openModalDeactivate({ item, index }),
          }, {
            label: "_BTN_DELETE_",
            isHidden: ({ item }) => this.isPast(item.frf_gueltig_ab) || !this.canDelete({ item }),
            callback: ({ item, index }) => this.openModalDelete({ item, index }),
          },
        ],
        actions: [
          {
            label: "_BTN_FOERDERORGROLLEN_FREIGABE_CREATE_",
            callback: () => this.openModalCreate(),
            isHidden: () => !this.canCreate,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "foerderorg_rollenfreigabe_create",
          },
        ],
        label: "_LBL_FOERDERORGROLLEN_FREIGABE_TABLE_",
        sortable: true,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
        csvHide: true,
      };
      return OPTIONS;
    },

    canCreate() {
      return this.checkPermissionsSync({
        perm: "foerderorgrollen.freigabe.create",
        permArray: this.organisation.user_permissions
      });
    },

    canView() {
      return this.checkPermissionsSync({
        perm: "foerderorgrollen.freigabe.view",
        permArray: this.organisation.user_permissions
      });
    },

    currentItemActionBtnId() {
      return `#${ TABLE_ID }_action_dropdown_${ this.currentIndex }`;
    },

    baseUrl() {
      return `foerderorganisationen/${ this.organisation.pk }/rollenfreigaben/`;
    }
  },
  methods: {
    canUpdate({ item }) {
      return this.checkPermissionsSync({
        perm: "foerderorgrollen.freigabe.update",
        permArray: item.user_permissions
      });
    },

    canDelete({ item }) {
      return this.checkPermissionsSync({
        perm: "foerderorgrollen.freigabe.delete",
        permArray: item.user_permissions
      });
    },

    isPast(datetime) {
      return moment().isSameOrAfter(datetime, "second");
    },

    getAktivIconProps({ aktiv }) {
      const ICON = {};
      if (aktiv) {
        ICON.icon = "glyphicon-ok";
        ICON.iconClass = "text-success";
        ICON.iconTooltip = "_TXT_JA_";
      } else {
        ICON.icon = "glyphicon-close";
        ICON.iconClass = "text-danger";
        ICON.iconTooltip = "_TXT_NEIN_";
      }
      return ICON;
    },

    getConfirmTranslationExtra() {
      const EXTRA = {
        organisation: this.organisation.o_name,
      };
      if (!isNil(this.currentItem)) {
        EXTRA.rolle = this.currentItem.rolle_bez || "_TXT_KEINE_ANGABE_";
      }
      return EXTRA;
    },

    openModalDeactivate({ item, index }) {
      this.currentItem = item;
      this.currentIndex = index;
      this.confirmOptions = {
        title: "_HTML_FOERDERORGROLLEN_FREIGABE_MODAL_DEACTIVATE_HEADER_{{organisation}}_{{rolle}}_",
        msg: "_HTML_FOERDERORGROLLEN_FREIGABE_MODAL_DEACTIVATE_BODY_{{organisation}}_{{rolle}}_",
        extra: this.getConfirmTranslationExtra(),
        okLabel: "_BTN_DEACTIVATE_",
        okClass: "btn btn-danger",
        okCallback: this.doDeactivate,
        cancelCallback: this.closeModalDeactivate,
        loading: false
      };
    },

    doDeactivate() {
      this.confirmOptions.loading = true;
      return this.postHttp({
        url: `${ this.baseUrl }/${ this.currentItem.pk }/deactivate/`,
      }).then(
        response => {
          this.addNotification({ text: "_MSG_FOERDERORGROLLEN_FREIGABE_DEACTIVATE_SUCCESS_" });
          this.closeModalDeactivate({ response });
        },
        error => {
          let msg = "_ERR_FOERDERORGROLLEN_FREIGABE_DEACTIVATE_";
          this.confirmOptions.loading = false;
          if (isString(error)) {
            msg = error;
          }
          this.addNotification({ text: msg, type: "error" });
        }
      );
    },

    closeModalDeactivate({ response } = {}) {
      this.confirmOptions = undefined;
      this.updateRow({ response });
    },

    openModalDelete({ item, index }) {
      this.currentItem = item;
      this.currentIndex = index;
      this.confirmOptions = {
        title: "_HTML_FOERDERORGROLLEN_FREIGABE_MODAL_DELETE_HEADER_{{organisation}}_{{rolle}}_",
        msg: "_HTML_FOERDERORGROLLEN_FREIGABE_MODAL_DELETE_BODY_{{organisation}}_{{rolle}}_",
        extra: this.getConfirmTranslationExtra(),
        okLabel: "_BTN_DELETE_",
        okClass: "btn btn-danger",
        okCallback: this.doDelete,
        cancelCallback: this.closeModalDelete,
        loading: false
      };
    },

    doDelete() {
      this.confirmOptions.loading = true;
      return this.deleteHttp({
        url: `${ this.baseUrl }/${ this.currentItem.pk }/`,
      }).then(
        () => {
          this.addNotification({ text: "_MSG_FOERDERORGROLLEN_FREIGABE_DELETE_SUCCESS_" });
          this.closeModalDelete({ response: true });
        },
        error => {
          let msg = "_ERR_FOERDERORGROLLEN_FREIGABE_DELETE_";
          if (isString(error.data)) {
            msg = error.data;
          }
          this.addNotification({ text: msg, type: "error" });
          this.confirmOptions.loading = false;
        }
      );
    },

    closeModalDelete({ response } = {}) {
      this.modal.delete = false;
      this.confirmOptions = undefined;
      this.updateTable({ response });
    },

    openModalUpdate({ item, index }) {
      this.currentItem = item;
      this.currentIndex = index;
      this.modal.update = true;
    },

    closeModalUpdate({ response } = {}) {
      this.modal.update = false;
      this.updateTable({ response });
    },

    openModalCreate() {
      this.modal.create = true;
    },

    closeModalCreate({ response } = {}) {
      this.modal.create = false;
      this.updateTable({ response });
    },

    updateTable({ response } = {}) {
      if (response) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
    },

    updateRow({ response } = {}) {
      if (response) {
        const row = assign({}, this.currentItem, response);
        EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row, index: this.currentIndex }));
      }
    },
  }
};
