import translate from "../../../../../../global/directives/translate";
import NotificationMixin from "../../../../../../global/mixins/NotificationMixin";
import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";
import {
  assign,
  toLower,
  forEach,
  cloneDeep,
  isNil,
  get,
  filter,
  indexOf,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    NotificationMixin,
  ],
  props: {
    model: {
      type: Array,
      required: true,
    },
    headerTranslation: {
      type: Object,
      required: true,
    },
    katalogData: {
      type: Object,
      required: true,
    },
    currentIndex: {
      type: Number,
      default: undefined,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modelLocal: {
        label: null,
        id: null,
        rechtsformen: [],
        regeln: [],
      },
      idPlaceholder: undefined,
      options: {
        showCloseButton: true,
        required: true,
      },
    };
  },
  computed: {
    currentModel() {
      return get(this.model, this.currentIndex, undefined);
    },
    currentOptions() {
      const OTHERS_SELECTED_RECHTSFORMEN = [];
      const OTHERS_SELECTED_REGELN = [];
      forEach(this.model, el => {
        if (!isNil(this.currentModel) && el.id === this.currentModel.id) {
          return;
        }
        OTHERS_SELECTED_RECHTSFORMEN.push(...get(el, "rechtsformen", []));
        OTHERS_SELECTED_REGELN.push(...get(el, "regeln", []));
      });
      return [
        {
          label: "_LBL_PARAM_RECHTSFORM_ZUSATZ_FIELD_LABEL_",
          showLabel: true,
          id: "label",
          type: "text",
          required: false,
          view: "v",
          change: this.changeLabel
        }, {
          label: "_LBL_PARAM_RECHTSFORM_ZUSATZ_FIELD_ID_",
          showLabel: true,
          id: "id",
          type: "text",
          required: true,
          view: "v",
          placeholder: this.idPlaceholder,
        }, {
          label: "_LBL_PARAM_RECHTSFORM_ZUSATZ_FIELD_RECHTSFORMEN_",
          showLabel: true,
          id: "rechtsformen",
          type: "multiselect",
          keyId: "pk",
          keyLabel: "bez",
          search: true,
          searchList: ["bez"],
          data: filter(this.katalogData.krechtsform, el => {
            return indexOf(OTHERS_SELECTED_RECHTSFORMEN, el.pk) === -1;
          }),
          required: true,
          view: "v",
        }, {
          label: "_LBL_PARAM_RECHTSFORM_ZUSATZ_FIELD_REGELN_",
          showLabel: true,
          id: "regeln",
          type: "selectordered",
          keyId: "pk",
          keyLabel: "bez",
          search: true,
          searchList: ["bez"],
          data: this.katalogData.elementfoerderregel,
          required: true,
          view: "v-alt",
        },
      ];
    },
    statusDisabledSaveButton() {
      return (!this.modelLocal.label && !this.modelLocal.id)
        || size(this.modelLocal.rechtsformen) === 0
        || size(this.modelLocal.regeln) === 0;
    },
  },
  beforeCreate() {
    this.$options.components.FormElement = require("../../../../../../global/components/FormElement/FormElement.vue").default;
    this.$options.components.Modal = require("../../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.currentModel) {
        const MODEL_LOCAL = assign({}, this.modelLocal, this.currentModel);
        this.modelLocal = MODEL_LOCAL;
        this.idPlaceholder = this.tokenize(get(this.modelLocal, "label"));
      }
    },
    changeLabel({ model }) {
      this.idPlaceholder = this.tokenize(model);
    },
    tokenize(str) {
      if (isNil(str)) {
        return;
      }
      return toLower(str).replace(/[^a-zA-Z0-9]/g, "");
    },
    save() {
      if (this.statusDisabledSaveButton) {
        return;
      }
      const DATA = cloneDeep(this.modelLocal);
      if (!DATA.id) {
        DATA.id = this.tokenize(DATA.label);
      }
      if (this.currentModel) {
        this.update({ data: DATA });
      } else {
        this.create({ data: DATA });
      }
    },
    create({ data }) {
      const MODEL = cloneDeep(this.model) || [];
      MODEL.push(data);
      this.close({ model: MODEL });
    },
    update({ data }) {
      const MODEL = cloneDeep(this.model) || [];
      MODEL.splice(this.currentIndex, 1, data);
      this.close({ model: MODEL });
    },
  },
};
