import PuxButton from "../PuxButton/PuxButton.vue";
import PuxIcon from "../PuxIcon/PuxIcon.vue";
import PuxTranslate from "../PuxTranslate/PuxTranslate.vue";
import Spinner from "../Spinner/Spinner.vue";

import {
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "AsideRightBox",
  components: {
    PuxButton,
    PuxIcon,
    PuxTranslate,
    Spinner,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
      info: "HTML-Attribut 'id'",
    },
  },
  data() {
    return {
      statusOpen: false,
    };
  },
  computed: {
    classBox() {
      return this.options.class || "";
    },

    classBody() {
      return this.options.classBody || "";
    },

    classSubheader() {
      return this.options.classSubheader || "";
    },

    nameHtml() {
      return this.options.name;
    },

    icon() {
      return this.options.icon;
    },

    iconClass() {
      return this.options.iconClass;
    },

    classIconToggle() {
      return this.statusOpen ? "glyphicon-chevron-up" : "glyphicon-chevron-down";
    },

    classHeader() {
      return this.options.classHeader || "";
    },

    isBodyVisible() {
      return (!this.isBoxToggleable || this.statusOpen || !this.isHeaderVisible) && !this.options.hideBody;
    },

    isBoxToggleable() {
      return isUndefined(this.options.toggle) || this.options.toggle;
    },

    isHeaderVisible() {
      return !this.options.hideHeader;
    },

    idToggleButton() {
      return `${ this.id }_button_toggle`;
    },

    buttonTestClass() {
      return `test_${ this.id }`;
    },

    tooltipButton() {
      return this.statusOpen ? "_BTN_ASIDE_RIGHT_BOX_TOGGLE_CLOSE_" : "_BTN_ASIDE_RIGHT_BOX_TOGGLE_OPEN_";
    },

    idForHeader() {
      return `${ this.id }_header`;
    },

    idForBody() {
      return `${ this.id }_body`;
    },

    eventsForHeader() {
      if (this.isBoxToggleable) {
        return {
          click: this.toggle,
        };
      }
      return {};
    },
  },
  created() {
    this.checkActive();
  },
  methods: {
    checkActive() {
      this.statusOpen = this.options.active || false;
    },

    toggle() {
      if (!this.isBoxToggleable) {
        return;
      }
      this.statusOpen = !this.statusOpen;
    },
  },
};
