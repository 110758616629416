import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import TextbausteinEdit from "./TextbausteinEdit/TextbausteinEdit.vue";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "Textbaustein",
  components: {
    SnapshotModule,
    SnapshotIcon,
    TextbausteinEdit,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      modelEdit: {
        text: "",
      },
    };
  },
  computed: {
    textbausteinId() {
      return `${ this.htmlRegelNummer }_textbaustein-input`;
    },

    labelHeader() {
      return this.modelParameter.frage;
    },

    statusDiff() {
      if (!this.snapshotModule) {
        return false;
      }
      return this.snapshotModule.text !== this.model.text;
    },

    errorsLabelsOptions() {
      return [
        this.textOptions,
        this.bausteinOptions,
      ];
    },

    textOptions() {
      return {
        id: "text",
        htmlId: `${ this.textbausteinId }_text`,
        type: "richtext",
        label: this.modelParameter.frage,
        required: this.modelParameter.pflichtfeld,
        view: "v-alt",
        editonly: true,
        max: this.modelParameter.max_antwort,
        min: this.modelParameter.min_antwort,
      };
    },

    bausteinOptions() {
      return {
        id: "baustein",
        htmlId: `${ this.textbausteinId }_baustein`,
        type: "select",
        label: "_LBL_TEXTBAUSTEIN_EDIT_AUSWAEHLEN_",
        translate: true,
        view: "v",
        keyLabel: "bez",
        keyId: "pk",
        search: true,
        searchList: ["bez"],
        keyGroup: "group",
      };
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.model && this.model.text !== undefined) {
        this.modelEdit = cloneDeep(this.model);
      }
    },

    prepareModel() {
      this.model = cloneDeep(this.module.data) || {};
    },

    setLocalErrorsFromGlobal({ errors }) {
      this.errors = errors;
    },
  },
};
