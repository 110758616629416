"use strict";

import BetragsrechnerRegistry from "../services";

import BetragsrechnerHonorarOhneQuali from "./betragsrechner.js";
import BetragsrechnerHonorarOhneQualiController from "./controller";

const BetragsrechnerHonorarOhneQualiModul = angular.module("dias.betragsrechner.BetragsrechnerHonorarOhneQualiModul", [
  BetragsrechnerRegistry.name,
]);

BetragsrechnerHonorarOhneQualiModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("honorarkosten_ohne_quali", BetragsrechnerHonorarOhneQuali);
})
  .component("honorarkostenOhneQuali", BetragsrechnerHonorarOhneQualiController);

export default BetragsrechnerHonorarOhneQualiModul;
