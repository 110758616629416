import PuxCloak from "../../../../../../global/components/PuxCloak/PuxCloak.vue";
import AngularLink from "../../../../../../global/components/AngularLink/AngularLink.vue";
import HttpMixin from "../../../../../../global/mixins/HttpMixin";
import {
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "UiElementverzweigungReadOnly",
  components: {
    AngularLink,
    PuxCloak,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
  },
  data() {
    return {
      loading: true,
      regelnByPK: {},
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getListHttp({
        url: `katalog/`,
        urlParams: {
          fields: ["pk", "re_nummer", "re_kbez", "bez"],
          key: "elementfoerderregel",
        },
      }).then(
        response => {
          this.regelnByPK = keyBy(response, "pk");
          this.loading = false;
        }
      );
    },
  },
};
