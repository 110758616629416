import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import SitzungsantraegeListInTageordnungspunktMixin from "../SitzungsantraegeListInTageordnungspunkt.mixin";

const TABLE_ID = "sitzungslisteantrag";

// @vue/component
export default {
  name: "SitzungsantraegeListInTageordnungspunktMittellos",
  components: {
    AngularLink,
    PuxTranslate,
  },
  mixins: [
    SitzungsantraegeListInTageordnungspunktMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: "",
        cols: [],
        rowActions: [],
        actions: [
          {
            isHidden: () => !(this.canDecide_mixin && this.userInput.festgeschrieben),
            label: "_LBL_SITZUNGSANTRAEGE_LIST_IN_TAGEORDNUNGSPUNKT_RESTLICHE_ANTRAEGE_ENTSCHEIDEN_",
            callback: this.openModalEntscheiden_mixin,
            type: "button",
            className: "btn btn-primary test_restliche_antraege_entscheiden",
            icon: "glyphicon-bearbeiten-2",
            htmlId: "restlichte_antraege_entscheiden",
          },
        ],
        label: "_LBL_SITZUNGSANTRAEGE_LIST_IN_TAGEORDNUNGSPUNKT_ANTRAEGE_",
        sortable: false,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
      },
    };
  },
  created() {
    this.loadData();
    this.initUrl_mixin();
  },
  methods: {
    loadData() {
      const PROMISE_ALL = [
        this.getHttp({ url: `sitzungen/${ this.sitzungPk }/tops/${ this.top.pk }/sitzungslisten/${ this.userInput.sl_id }/` }),
        this.getHttp({ url: `katalog/?gruppe=${ this.parameter.entscheidungsstatusgruppe }&key=entscheidungsstatus` }),
      ];
      Promise.all(PROMISE_ALL).then(
        responses => {
          this.list = responses[0];
          this.entscheidungsstatus = responses[1];
        }
      ).then(
        () => {
          this.initColumns();
          this.initRowActions();
          this.hasViewPermission = this.canView_mixin;
          this.status.loading = false;
        }
      );
    },

    initColumns() {
      this.initStatusColumn();
      this.initAnmerkungColumn();
    },

    initStatusColumn() {
      if (!this.userInput.festgeschrieben) {
        return;
      }
      this.options.cols.push(
        {
          label: "_LBL_SITZUNGSANTRAEGE_LIST_IN_TAGEORDNUNGSPUNKT_STATUS_",
          id: "status",
          slot: "status",
          className: "text-center",
          classNameChild: "overflow-hidden",
          priority: 10,
        }
      );
    },

    initAnmerkungColumn() {
      if (!this.showAnmerkungColumn_mixin) {
        return;
      }
      this.options.cols.push({
        label: "_LBL_SITZUNGSANTRAEGE_LIST_IN_TAGEORDNUNGSPUNKT_ANMERKUNG_",
        id: "anmerkung",
        slot: "anmerkung",
        classNameChild: "overflow-hidden",
        priority: 150,
      });
    },

    initRowActions() {
      this.initEntscheidungsAktionen_mixin();
      this.initProtokollierungAktionen_mixin();
    },
  },
};
