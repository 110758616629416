"use strict";

import StandardFinanzierungController from "../../finanzierung.controller";

class EhrenamtspauschaleController extends StandardFinanzierungController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService) {
    super($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService);
    this.name = "EhrenamtspauschaleController";
    this.allowsInput = true;
  }

  hasValue() {
    return ((this.vertragFinanzierungsart && this.vertragFinanzierungsart.vf_betrag_aktualisiert > 0) ||
            (this.antragKoFiArt && this.antragKoFiArt.summe > 0));
  }

  hasSnapshotDiff() {
    return (this.snapshotdiff && this.snapshotdiff.modified && this.snapshotdiff.modified[this.antragKoFiArt.pk]);
  }
}

export default EhrenamtspauschaleController;
