import translate from "../../../../../global/directives/translate";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import {
  assign,
  debounce,
  filter,
  isNil,
  keys,
  mapValues,
  pickBy,
  size,
  find,
} from "lodash-es";

// @vue/component
export default {
  name: "StandardFoerdermoduleAuswahlKatalogAuswahl",
  components: {
    FormElement,
  },
  directives: {
    translate
  },
  props: {
    data: {
      type: Object,
      default: undefined,
    },
    groups: {
      type: Object,
      default: undefined,
    },
    elements: {
      type: Array,
      default: undefined,
    },
    config: {
      type: Object,
      default: undefined,
    },
    errors: {
      type: Object,
      default: undefined
    },
    onSelection: {
      type: Function,
      default: () => {},
    },
    onFreitext: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selectModel: undefined,
      freitexte: undefined,
      fretexteShow: undefined,
      freitextOptions: {
        type: "text",
        placeholder: "_STANDARD_FOERDERMODUL_AUSWAHL_KATALOG_AUSWAHL_FREITEXT_PLACEHOLDER_",
        translate: true,
        view: "v",
      },
      selectOptions: {
        id: "auswahl",
        type: undefined,
        keyId: "pk",
        keyLabel: "bez",
        keyGroup: "group",
        label: null,
        search: true,
        searchList: ["group", "bez"]
      }
    };
  },
  computed: {
    selectionId() {
      return `${ this.idPrefix }_selection`;
    },

    elementsWithoutParents() {
      if (size(this.groups) > 1) {
        return filter(this.elements, el => !isNil(el.uebergeordneter_wert));
      }
      return this.elements;
    },

    isAnySelectedWithFreitext() {
      return !isNil(find(this.data, ["status", true]));
    },

    freitextEls() {
      return filter(this.elements, el => this.getSelectionStatus(el) && el.freitext);
    },

    showAnyFreitext() {
      return size(this.freitextEls) > 0;
    },

    translationFreitextHeader() {
      return {
        text: "_TXT_STANDARD_FOERDERMODUL_AUSWAHL_KATALOG_AUSWAHL_SELECT_FREITEXT_HEADER_"
      };
    },

    nonFieldErrors() {
      if (this.errors) {
        return this.errors.non_field_errors;
      }
      return undefined;
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.selectModel = filter(keys(this.data), key => {
        return this.data[key].status && isNil(this.groups[key]);
      });
      if (!this.config.mehrfachauswahl) {
        this.selectModel = size(this.selectModel) ? this.selectModel[0] : null;
      }
      this.freitexte = mapValues(pickBy(this.data, el => !isNil(el.freitext)), "freitext");
      this.selectOptions.type = this.config.mehrfachauswahl ? "multiselect" : "select";
    },

    getSelectionStatus(el) {
      if (this.data[el.pk]) {
        return this.data[el.pk].status;
      }
    },

    getFreitextId(el) {
      return `${ this.idPrefix }_${ el.pk }_freitext`;
    },

    getFreitextOptions(el) {
      return assign({}, this.freitextOptions, { label: el.bez });
    },

    getError(el) {
      if (this.errors) {
        return this.errors[el.pk];
      }
    },

    onSelectInput({ item }) {
      this.onSelection(item);
    },

    getDebouncedOnFreitext(el) {
      return debounce(({ $event }) => {
        this.onFreitext(el, $event);
      }, 250);
    },
  },
};
