"use strict";

import BaseModel from "dias/core/models/baseModel";
import FoerderfinderAngebot from "./foerderfinderangebot.service";

class FoerderfinderAntwort extends BaseModel {
  constructor() {
    super("antworten", {
      subPaths: {
        angebote: new FoerderfinderAngebot(),
      }
    });
  }
}

export default FoerderfinderAntwort;
