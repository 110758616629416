import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "modulobjektlisteelementlist";

// @vue/component
export default {
  name: "ModulObjektlisteElementList",
  components: {
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    modul: {
      type: Object,
      default: () => ({}),
    },
    objekt: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: "",
        cols: [{
          id: "ole_pos",
          label: "_LBL_MODUL_OBJEKTLISTE_ELEMENT_LIST_POSITION_",
          sort_id: "ole_pos",
          sortable: true,
          sorting_default: "asc",
          priority: 10,
        }, {
          id: "ole_objektname",
          label: "_LBL_MODUL_OBJEKTLISTE_ELEMENT_LIST_NAME_",
          sort_id: "ole_objektname",
          sortable: true,
          priority: 20,
        }, {
          id: "content_type_bez",
          label: "_LBL_MODUL_OBJEKTLISTE_ELEMENT_LIST_TYP_",
          sort_id: "content_type__name",
          sortable: true,
          hide: true,
          priority: 40,
        }],
        rowActions: [],
        actions: [],
        label: "_LBL_MODUL_OBJEKTLISTE_ELEMENT_LIST_AUSZAHLUNGSLISTEN_",
        sortable: true,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        view: {
          filter: "top",
        },
      },
      filters: [{
        id: "ole_objektname",
        type: "text",
        label: "_LBL_MODUL_OBJEKTLISTE_ELEMENT_LIST_NAME_",
        main: true,
      }],
    };
  },
  created() {
    this.initUrl();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { olId: this.modul.data.ol_id } });
    },
  },
};
