import AntragCreateExternZuschuss from "./AntragCreateExternZuschuss/AntragCreateExternZuschuss.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import FoerderantragquelleUUIDs from "../../../const/FoerderantragquelleUUIDs";
import {
  gettext,
} from "../../../global/functions/utils";
import {
  onUtag,
} from "../../../global/functions/utag";
import {
  get,
} from "lodash-es";

// @vue/component
export default {
  components: {
    AntragCreateExternZuschuss,
    PuxButton,
    PuxPageDetails,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  data() {
    return {
      loading: false,
      isMoreLoading: false,
      isMoreLoaded: false,
      isAntragCreating: false,
      regelsatz: undefined,
      loadingMsg: undefined,
      urlRegelsaetze: "aktive_regelsaetze/",
      reloadingMsg: "",
    };
  },
  computed: {
    regelsatzDurchfuehrungszeitraumMaxDurchfuehrungsdauer() {
      return get(this.regelsatz, "durchfuehrungszeitraum.max_durchfuehrungsdauer");
    },

    urlRegelsatzDetails() {
      return `${ this.urlRegelsaetze }${ this.regelsatz.pk }/`;
    },

    isReloading() {
      return this.isMoreLoading || this.isAntragCreating;
    },

    utagLinkObject() {
      const LINK_OBJ = {
        event_id: "DIAS_Antrag_erstellt",
        etracker_event_action: "click",
      };
      if (this.regelsatz) {
        LINK_OBJ.antrag_angebot = this.regelsatz.ars_kbez;
        LINK_OBJ.antrag_angebot_id = this.$stateParams.id;
      }
      return LINK_OBJ;
    },
  },
  methods: {
    loadAktiveRegelsatz() {
      if (!this.$stateParams.id) {
        this.addNotification({
          text: "_MSG_ANTRAG_EXTERN_REGELSATZ_ERROR_",
          type: "error",
        });
        return Promise.resolve();
      }
      this.loading = true;
      this.loadingMsg = "_TXT_ANTRAG_EXTERN_ANGEBOTSDATEN_WERDEN_GELADEN_";
      return this.getListHttp({
        url: this.urlRegelsaetze,
        urlParams: {
          angebot: this.$stateParams.id,
          offset: 0,
          limit: 1,
        },
      }).then(
        response => {
          if (response.length === 1) {
            this.regelsatz = response[0];
          } else {
            this.addNotification({
              text: "_MSG_ANTRAG_EXTERN_LOADING_KEINE_ANGEBOTE_ERROR_",
              type: "error",
            });
          }
        },
        () => {
          this.addNotification({
            text: "_MSG_ANTRAG_EXTERN_LOADING_ANGEBOT_ERROR_",
            type: "error",
          });
        }
      ).finally(() => this.loading = false);
    },

    showMore() {
      if (this.regelsatz) {
        this.isMoreLoading = true;
        this.reloadingMsg = gettext("_TXT_ANTRAG_EXTERN_ANGEBOTSINFORMATION_LOADING_");
        this.getHttp({
          url: this.urlRegelsatzDetails,
        }).then(
          response => {
            this.regelsatz = response;
          }
        ).finally(() => {
          this.isMoreLoading = false;
          this.isMoreLoaded = true;
        });
      }
    },

    createAntrag() {
      if (this.regelsatz && this.regelsatz.pk) {
        this.isAntragCreating = true;
        this.reloadingMsg = gettext("_TXT_ANTRAG_EXTERN_WIRD_ERSTELLT_");
        return this.postHttp({
          url: "foerderantraege/",
          data: {
            regelsatz: this.regelsatz.pk,
            quelle: FoerderantragquelleUUIDs.foerderfinder,
          },
        }).then(
          response => {
            onUtag(this.utagLinkObject);
            this.addNotification({
              text: "_MSG_ANTRAG_EXTERN_ERSTELLEN_SUCCESS_{{a_nr}}_{{angebot_kbez}}_",
              extra: {
                a_nr: this.filterSpaced(response.a_nr, true),
              },
            });
            this.$goTo("root.foerderantrag.details", { id: response.pk });
          },
          () => {
            this.addNotification({
              text: "_MSG_ANTRAG_EXTERN_ERSTELLEN_ERROR_",
              type: "error",
            });
          }
        ).finally(() => this.isAntragCreating = false);
      }
      return Promise.resolve();
    },
  },
};
