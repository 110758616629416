import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "AnkuendigungCards",
  components: {
    PuxCloak,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      news: [],
    };
  },
  created() {
    this.loadNews();
  },
  methods: {
    loadNews() {
      this.getListHttp({
        url: this.url,
      }).then(
        response => {
          this.news = response;
          this.loading = false;
        }
      );
    },
  },
};
