"use strict";
import angular from "angular";

import models from "../models";
import services from "../services";
import detailComponent from "./detail.component";

const modul = angular.module("dias.workflows.workflowtypen", [
  models.name,
  services.name,
]);

modul.config($stateProvider => {
  $stateProvider

  // Liste der KAufgaben
    .state("root.workflowtypen", {
      url: `/workflowtypen/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-workflowtypen-list></vue-workflowtypen-list></div>"
        }
      },
      data: {
        permissions: "workflowtyp.ui"
      }
    })

    .state("root.workflowtypen1", {
      url: `/workflowtypen1/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-workflowtypen-list></vue-workflowtypen-list></div>"
        }
      },
      data: {
        permissions: "workflowtyp.ui"
      }
    })

    .state("root.workflowtypen.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><workflowtyp-details></workflowtyp-details></div>"
        }
      },
    })

    .state("root.workflowtypen1.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<workflowtypen-detail></workflowtypen-detail>"
        }
      },
    })
  ;
})

  // .component("kaufgabeList", listComponent)
  .component("workflowtypenDetail", detailComponent)

;

export default modul;
