export default {
  6: 0.05,
  7: 0.02,
  8: 0.015,
  9: 0.005,
  10: 0.0025,
  11: 0.0015,
  12: 0.0007,
  13: 0.0005,
  14: 0.0002,
  15: 0.00009,
  16: 0.00005,
  17: 0.000025,
  18: 0.000012,
};
