import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

const errorNameLeerFeld = "_ERR_EMPTY_FIELD_";

// @vue/component
export default {
  name: "AufgabeModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
  ],
  props: {
    close: {
      type: Function,
      required: true,
    },
    urlCreate: {
      type: String,
      required: false,
      default: undefined,
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        prioritaet: false,
        Vertrag: undefined,
        Antrag: undefined,
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    options() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "verObjtyp",
            type: "select",
            label: "_LBL_AUFGABE_MODAL_CREATE_OBJEKT_TYP_",
            required: true,
            keyId: "id",
            keyLabel: "label",
            deselect: false,
            view: "v-alt",
            menuWidthAll: true,
            dataTranslate: true,
            hideIf: !this.areObjectsVisible,
            data: this.objectTypeData
          },
          {
            id: "kbez",
            type: "text",
            label: "_LBL_AUFGABE_MODAL_CREATE_TITEL_",
            required: true,
            maxlength: 60,
            view: "v-alt",
            translate: true,
          },
          {
            id: "bez",
            type: "textarea",
            label: "_LBL_AUFGABE_MODAL_CREATE_BESCHREIBUNG_",
            maxlength: 200,
            view: "v-alt",
            translate: true,
          },
          {
            id: "termin",
            type: "date",
            label: "_LBL_AUFGABE_MODAL_CREATE_TERMIN_",
            required: true,
            view: "v-alt",
            translate: true,
          },
          {
            id: "wiedervorlage",
            type: "date",
            label: "_LBL_AUFGABE_MODAL_CREATE_WIEDERVORLAGE_",
            view: "v-alt",
            translate: true,
          },
          {
            id: "prioritaet",
            type: "boolean",
            label: "_LBL_AUFGABE_MODAL_CREATE_PRIORITAET_",
            view: "v-alt",
            translate: true,
          },
          {
            id: "Antrag",
            type: "select",
            label: "_LBL_AUFGABE_MODAL_CREATE_VERBUNDENES_OBJEKT_",
            required: true,
            keyLabel: "o_name",
            keyId: "pk",
            view: "v-alt",
            url: "foerderantraege/?serializer=minimal",
            searchParameter: "a_nr",
            searchGlobal: true,
            menuWidthAll: true,
            keyLabelCallback: ({ item }) => `${ item.a_nr } ${ item.a_titel ? " - " + item.a_titel : "" }`,
            hideIf: !this.areObjectsVisible || this.model.verObjtyp !== "foerderantraege",
          },
          {
            id: "Vertrag",
            type: "select",
            label: "_LBL_AUFGABE_MODAL_CREATE_VERBUNDENES_OBJEKT_",
            required: true,
            keyLabel: "o_name",
            keyId: "pk",
            view: "v-alt",
            url: "vertraege/",
            searchParameter: "v_nr",
            searchGlobal: true,
            menuWidthAll: true,
            keyLabelCallback: ({ item }) => `${ item.v_nr } ${ item.a_titel ? " - " + item.a_titel : "" }`,
            hideIf: !this.areObjectsVisible || this.model.verObjtyp !== "vertraege",
          },
        ],
      };
    },

    areObjectsVisible() {
      return !this.urlCreate;
    },

    hasPermissionFoerderantragView() {
      return this.checkPermissionsSync({ perm: "foerderantrag.view" });
    },

    hasPermissionVertragView() {
      return this.checkPermissionsSync({ perm: "vertrag.view" });
    },

    objectTypeData() {
      const LIST = [];
      if (this.hasPermissionFoerderantragView) {
        LIST.push({
          label: "_LBL_AUFGABE_MODAL_CREATE_OBJEKT_TYP_ANTRAG_",
          id: "foerderantraege",
        });
      }
      if (this.hasPermissionVertragView) {
        LIST.push({
          label: "_LBL_AUFGABE_MODAL_CREATE_OBJEKT_TYP_VERTRAG_",
          id: "vertraege",
        });
      }
      return LIST;
    },
  },
  methods: {
    onSave() {
      const errorStatus = this.validate();
      if (errorStatus) {
        return;
      }
      this.save();
    },

    validate() {
      const errors = {};
      let errorStatus = false;
      if (!this.model.kbez) {
        errors.kbez = [];
        errors.kbez.push(errorNameLeerFeld);
        errorStatus = true;
      }
      if (typeof(this.model.termin) === "undefined") {
        errors.termin = [];
        errors.termin.push(errorNameLeerFeld);
        errorStatus = true;
      }
      if (this.areObjectsVisible) {
        if (!this.model.verObjtyp) {
          errors.verObjtyp = [];
          errors.verObjtyp.push("_ERR_AUFGABE_MODAL_CREATE_OBJEKT_TYP_AUSWAHL_");
          errorStatus = true;
        } else if (!this.model.Antrag && !this.model.Vertrag) {
          if (this.model.verObjtyp === "foerderantraege") {
            errors.Antrag = [];
            errors.Antrag.push("_ERR_AUFGABE_MODAL_CREATE_OBJEKT_AUSWAHL_");
          } else {
            errors.Vertrag = [];
            errors.Vertrag.push("_ERR_AUFGABE_MODAL_CREATE_OBJEKT_AUSWAHL_");
          }
          errorStatus = true;
        }
      }
      if (errorStatus) {
        this.onError({ error: errors });
      }

      return errorStatus;
    },

    onError({ error }) {
      this.errors = error;
      this.errorsClone = error;
    },

    save() {
      this.status.loading = true;
      let url = undefined;
      if (this.areObjectsVisible) {
        const PK = this.model.verObjtyp === "foerderantraege" ? this.model.Antrag : this.model.Vertrag;
        url = `${ this.model.verObjtyp }/${ PK }/aufgaben/manuelle_aufgabe_erstellen/`;
      } else {
        url = this.urlCreate;
      }

      this.postHttp({
        url,
        data: {
          kbez: this.model.kbez,
          bez: this.model.bez,
          termin: this.model.termin,
          wiedervorlage: this.model.wiedervorlage,
          prioritaet: this.model.prioritaet,
        },
      }).then(
        () => {
          this.addNotification({ text: "_MSG_AUFGABE_MODAL_CREATE_SUCCESS_" });
          this.close({ status: true });
        },
        error => {
          this.onError({ error: error.data });
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
