"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import modules from "./modules";

const mod = angular.module("root.pruefkriterien", [
  uiRouter,
  modules.name,
]);

export default mod;
