import { computed } from "vue";

import ModulePreviewIcon from "../ModulePreviewIcon/ModulePreviewIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import SitzungModulePreviewPropsMixin from "../SitzungModulePreviewPropsMixin";

import SitzungModulePreviewAPI from "../compositionAPI/SitzungModulePreviewAPI";

// @vue/component
export default {
  name: "ModuleManuelleAntragslistePreview",
  components: {
    ModulePreviewIcon,
    PuxTranslate,
  },
  mixins: [
    SitzungModulePreviewPropsMixin,
  ],
  setup(props) {
    const { count } = SitzungModulePreviewAPI(props);

    const tooltip = computed(() => {
      return `_TXT_SITZUNG_MODULE_PREVIEW_ANTRAEGE_{{count}}_`;
    });

    const tooltipExtra = computed(() => {
      return {
        count: count.value,
      };
    });

    return {
      count,
      tooltip,
      tooltipExtra,
    };
  },
};
