"use strict";

import diasLoading from "dias/core/loading";

import ButtonPrintComponent from "./button-print.component";

const diasPrint = angular.module("dias.print", [
  diasLoading.name
])

  .component("buttonPrint", ButtonPrintComponent);

export default diasPrint;
