import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import GeschaeftsregelModul from "../../../../Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";
import ModalBelegdokumentDelete from "../../../../KF/KofiErfassung/ModalBelegdokumentDelete/ModalBelegdokumentDelete.vue";
import Permission from "../../../../../global/components/Permission/Permission.vue";
import PuxDate from "../../../../../global/components/PuxDate/PuxDate.vue";
import PuxDropdown from "../../../../../global/components/PuxDropdown/PuxDropdown.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  EventBus,
} from "../../../../../global/functions/event-bus";
import {
  getTranslatedText,
} from "../../../../../global/functions/utils";

import {
  get
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungBelegdokumenteStep",
  components: {
    FormstepDetail,
    GeschaeftsregelModul,
    ModalBelegdokumentDelete,
    Permission,
    PuxDate,
    PuxDropdown,
    PuxTranslate,
  },
  props: {
    auszahlung: {
      type: Object,
      required: true,
    },
    antragPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dokumentePermissionsFromObj: {
        view: "dokument.view",
        create: "dokument.create",
        // TODO: PA-3372
        delete: "dokument.delete",
        update: "dokument.update",
        update_gueltigkeit: "dokument.update_gueltigkeit",
      },
      currentDok: undefined,
      showModalDeleteCurrentDok: false,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        hideHeader: true,
      };
    },

    htmlId() {
      return `auszahlung_belegdokumente_${ this.auszahlung.pk }`;
    },

    baseUrl() {
      return `foerderantraege/${ this.antragPk }/`;
    },

    extraStatic() {
      return {
        hideHeader: true,
      };
    },

    config() {
      return {
        module: {
          modulname: "anlagen_allgemein",
          regel: {
            regel: null,
            regel_nummer: "auszahlung_belegdokumente",
            _argument: this.parameter,
          },
        },
        readonly: false,
      };
    },

    parameter() {
      return {
        frage: {
          typ: "string",
          wert: null,
        },
        header_all: {
          typ: "string",
          wert: "_TXT_AUSZAHLUNG_WIZARD_STEP_BELEGDOKUMENTE_HEADER_",
        },
        header_info: {
          typ: "string",
          wert: getTranslatedText("_HTML_AUSZAHLUNG_WIZARD_STEP_BELEGDOKUMENTE_INFO_"),
        },
        readonly: {
          typ: "boolean",
          wert: false,
        },
        anzeigeart: {
          typ: "single_choice",
          wert: "d8bf9cc3-acf0-4c23-a92b-0a9174856b0b",
          katalog: "anzeigeart",
        },
        pflichtfeld: {
          typ: "boolean",
          wert: false,
        },
        default_sort: {
          typ: "single_choice",
          wert: "-dokument.crdate",
        },
        antragsteller: {
          typ: "boolean",
          wert: false,
        },
        anzeige_details: {
          typ: "multi_select",
          wert: [
            "dokument.dok_titel",
            "dokument.dokumentart.name",
            "dokument.dok_posteingang",
            "pruefstatus.status",
            "pruefstatus_gesetzt_am",
            "geprueft_von",
            "dokument.crdate",
            "dokument.user",
            "dokument.dok_loeschdatum",
            "dokument.dok_dokumentname",
            "dokument.dok_typ",
            "dokument.dok_groesse",
            "dokument.dok_unterschriftsdatum",
          ],
        },
        header_optional: {
          typ: "string",
          wert: null
        },
        header_required: {
          typ: "string",
          wert: null,
        },
        mehrfachauswahl: {
          typ: "boolean",
          wert: false,
        },
        allowed_mime_type: {
          typ: "json",
          wert: [],
        },
        header_optional_info: {
          typ: "text",
          wert: null,
        },
        header_required_info: {
          typ: "text",
          wert: null,
        },
        validate_pflichtdoks: {
          typ: "boolean",
          wert: false,
        },
        gueltig_pflicht_felder: {
          typ: "multi_select",
          wert: [
            "von"
          ],
        },
        gueltig_fuer_dokument_hochladen: {
          typ: "boolean",
          wert: false,
        },
      };
    },
  },
  methods: {
    leavingStep() {
      return new Promise(resolve => {
        resolve({ isPreviousStepNotVisited: true });
      });
    },

    getDokAktionDropdown({ dok }) {
      return `${ this.htmlId }_${ dok.pk }_dropdown`;
    },

    getDokUrl({ dok }) {
      return `/api/vertraege/${ this.auszahlung.vertrag }/dokumente/${ dok.pk }/`;
    },

    showDeleteDok({ dok }) {
      this.currentDok = dok;
      this.showModalDeleteCurrentDok = true;
    },

    closeShowDeleteDok(params) {
      this.currentDok = undefined;
      this.showModalDeleteCurrentDok = false;
      if (get(params, "statusReload", false)) {
        EventBus.$emit("reloadAnlagenAllgemein");
        EventBus.$emit("reloadAuszahlungWizardKoFiStep", { statusReloadBelegDokumente: true });
      }
    },
  },
};
