import Modal from "../../../../global/components/Modal/Modal.vue";
import ParameterFormular from "../../../../global/components/ParameterFormular/ParameterFormular.vue";

import WorkflowModulBaseMixin from "../WorkflowModulBaseMixin";

import {
  forEach,
  get,
  isArray,
  isEmpty,
  isNil,
  isObject,
  reduce,
} from "lodash-es";

// @vue/component
export default {
  components: {
    Modal,
    ParameterFormular,
  },
  mixins: [
    WorkflowModulBaseMixin,
  ],
  data() {
    return {
      loading: undefined,
      errors: undefined,
      errorLabels: {},
      parameterIn: undefined,
      parameterOut: undefined,
      localParameters: undefined,
    };
  },
  computed: {
    options() {
      return {
        showCloseButton: true,
        required: true,
      };
    },

    missingRequired() {
      return reduce(this.localParameters, (result, value) => {
        return result || (this.isModelEmpty(value.wert) && (this.isParameterRequired(value)));
      }, false);
    },
  },
  methods: {
    isModelEmpty(model) {
      if (isArray(model)) {
        return !model.length;
      }
      return isNil(model);
    },

    isParameterRequired(item) {
      if (!isNil(item.required)) {
        return item.required;
      }
      return get(item, "options.required", true);
    },

    setErrorLabels() {
      const ERROR_LABELS = {};
      forEach(this.parameterOut, (item, key) => {
        ERROR_LABELS[key] = item.bez;
      });
      this.errorLabels = ERROR_LABELS;
    },

    onChange({ currentModel }) {
      forEach(currentModel, (value, key) => {
        if (this.localParameters[key].typ === "document" && isObject(value)) {
          this.localParameters[key].wert = value.pk;
        } else {
          this.localParameters[key].wert = value;
        }
      });
    },

    save() {
      this.loading = true;
      this.finish({ parameterOut: this.localParameters }).then(
        response => {
          this.startWorkflowfolge(response);
        },
        errors => {
          if (this.localParameters && !isEmpty(this.localParameters)) {
            if (errors.data && errors.data.modul) {
              return this.startWorkflowfolge(errors);
            }
            this.errors = errors.data;
          }
          this.startWorkflowfolge(errors);
        }
      ).finally(
        () => this.loading = false
      );
    },
  },
};
