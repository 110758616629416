import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotItem from "../../../Snapshot/SnapshotItem/SnapshotItem.vue";

import {
  gettext,
} from "../../../../global/functions/utils";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "VertretungsberechtigteTr",
  components: {
    PuxButton,
    PuxTranslate,
    SnapshotIcon,
    SnapshotItem,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    vertreter: {
      type: Object,
      required: true,
    },
    vertreterPk: {
      type: String,
      required: false,
      default: undefined,
    },
    index: {
      type: Number,
      required: true,
    },
    isVertreterEditable: {
      type: Boolean,
      required: true,
    },
    statusSnapshot: {
      type: Boolean,
      default: false,
    },
    statusSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    statusSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    statusSnapshotChanges: {
      type: Boolean,
      required: false,
    },
    statusSnapshotChangesIcon: {
      type: Boolean,
      required: false,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    snapshotChangesDiff: {
      type: Object,
      required: false,
      default: undefined,
    },
    vertreterSnapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  emits: [
    "openConfirmDeleteVertreter",
  ],
  computed: {
    idLocal() {
      return `${ this.id }_${ this.index }`;
    },

    vertreterPosition() {
      return `${ this.index + 1 }.`;
    },

    idButtonDelete() {
      return `delete_vertreter_${ this.index + 1 }`;
    },

    statusReadonly() {
      return !this.isVertreterEditable;
    },

    snapshotIconFirstTdType() {
      if (this.statusSnapshotAdd) {
        return "add";
      }
      if (this.statusSnapshotDelete) {
        return "delete";
      }
      if (this.statusSnapshotChangesIcon) {
        return "changes";
      }
      return "";
    },

    snapshot() {
      return get(this.vertreterSnapshot, "vertreter_obj", {});
    },

    classVersionFirst() {
      if (this.statusSnapshotDelete || this.statusSnapshotChanges) {
        return "version-bg";
      }
      return "";
    },

    diffLocal() {
      return this.snapshotChangesDiff || {};
    },

    defaultValueTranslated() {
      return gettext("_TXT_KEINE_ANGABE_");
    },
  },
  methods: {
    openConfirmDeleteVertreterLocal() {
      this.$emit("openConfirmDeleteVertreter", {
        vertreter: {
          pk: this.vertreterPk,
          vertreter_obj: this.vertreter,
        },
        selectorClose: `#${ this.idButtonDelete }`,
      });
    },
  },
};
