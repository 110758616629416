"use strict";
import angular from "angular";

import DiasNotification from "dias/core/notification";
import DiasCoreServices from "dias/core/services";

import parameter from "./parameter";

import confirm from "./confirm";
import download_after_confirm from "./download_after_confirm";
import einreichen_bestaetigung from "./einreichen_bestaetigung";
import form from "./form";
import simple from "./simple";
import sv_aktualisieren from "./sammelvertrag/aktualisieren";

const modul = angular.module("standard_workflowmodule", [
  DiasNotification.name,
  DiasCoreServices.name,
  parameter.name,
  confirm.name,
  download_after_confirm.name,
  einreichen_bestaetigung.name,
  form.name,
  simple.name,
  sv_aktualisieren.name,
]);


export default modul;
