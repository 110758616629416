"use strict";

const diasConfirmComponent = /*@ngInject*/ function(diasConfirmDialog) {
  return {
    restrict: "A",
    scope: {
      confirmType: "@",
      confirmMsg: "@",
      confirmTitle: "@",
      confirmOkLabel: "@",
      confirmOkIcon: "@",
      confirmCancelLabel: "@",
      confirmOk: "&",
      confirmCancel: "&",
      confirmSwitchBtnPos: "@"
    },
    link: function(scope, element) {
      element.on("click", function() {
        diasConfirmDialog({
          type: scope.confirmType,
          content: scope.confirmMsg,
          title: scope.confirmTitle,
          okLabel: scope.confirmOkLabel,
          okIcon: scope.confirmOkIcon,
          okCallback: scope.confirmOk,
          cancelLabel: scope.confirmCancelLabel,
          cancelCallback: scope.confirmCancel,
        });
      });
    }
  };
};

export default diasConfirmComponent;
