import PuxTranslate from "../PuxTranslate/PuxTranslate.vue";

import {
  size
} from "lodash-es";

// @vue/component
export default {
  name: "Spinner",
  components: {
    PuxTranslate,
  },
  props: {
    msg: {
      type: String,
      default: "_TXT_LOADING_",
    },
  },
  computed: {
    showMsg() {
      return size(this.msg) > 0;
    }
  }
};

