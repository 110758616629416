import SnapshotItem from "../../Snapshot/SnapshotItem/SnapshotItem.vue";

import { createNamespacedHelpers } from "vuex";
import {
  get,
} from "lodash-es";

const {
  mapMutations,
} = createNamespacedHelpers("snapshot");

// @vue/component
export default {
  components: {
    SnapshotItem,
  },
  props: {
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  computed: {
    isSnapshot() {
      return !!this.snapshot;
    },

    snapshotObject() {
      return get(this.snapshot, "osn_snapshot");
    },

    snapshotDate() {
      return get(this.snapshot, "osn_snapshot_datum");
    },
  },
  watch: {
    isDiff: {
      immediate: true,
      handler(newVal) {
        this.MUT_SET_STATUS_DIFF_FOR_REGEL({
          regelId: this.id,
          statusDiff: newVal,
        });
      },
    },
  },
  beforeUnmount() {
    this.MUT_DELETE_STATUS_DIFF_FOR_REGEL({ regelId: this.id });
  },
  methods: {
    ...mapMutations([
      "MUT_SET_STATUS_DIFF_FOR_REGEL",
      "MUT_DELETE_STATUS_DIFF_FOR_REGEL",
    ]),
  },
};
