"use strict";

import DiasNotification from "./baseNotification";

import pageNotificationTemplate from "./notification.page.jade";
import formNotificationTemplate from "./notification.form.jade";

import {
  EventBus,
} from "../../../../../vue/client/vue/global/functions/event-bus";

class DiasNotificationService {
  /*@ngInject*/
  constructor($alert, $templateCache) {
    this.name = "DiasNotificationService";
    this.$alert = $alert;

    // default configurations
    this.configs = {
      page: {
        duration: 5,
        dismissable: true,
        prefixEvent: "notification.page",
        container: "#page-notifications",
        templateUrl: "pageNotificationTemplate.html"
      },
      form: {
        duration: false,
        dismissable: false,
        animation: false,
        prefixEvent: "notification.form",
        container: "#form-notifications",
        templateUrl: "formNotificationTemplate.html"
      }
    };

    // Cache templates
    $templateCache.put(this.configs.page.templateUrl, pageNotificationTemplate());
    $templateCache.put(this.configs.form.templateUrl, formNotificationTemplate());

    this.page = new DiasNotification($alert, this.configs.page);
    this.form = new DiasNotification($alert, this.configs.form);
    this.updatePopUpMessageTimeout = this.updatePopUpMessageTimeout.bind(this);
    this.setEventBus();
  }

  setEventBus() {
    EventBus.$on("updatePopUpMessageTimeoutInAngular", this.updatePopUpMessageTimeout);
  }

  updatePopUpMessageTimeout(timeout) {
    this.configs.page.duration = timeout;
    this.page = new DiasNotification(this.$alert, this.configs.page);
  }
}

export default DiasNotificationService;
