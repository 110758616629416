import ModuleMixin from "../ModuleMixin";
import Alert from "../../../../global/components/Alert/Alert.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import {
  cloneDeep,
  forEach,
  get,
  isEmpty,
  size,
} from "lodash-es";
import {
  gettext,
  replaceText,
} from "../../../../global/functions/utils";

const SNAPSHOT_CHANGES_LIST = [
  "teilnehmer",
  "teilnehmer_mit_betreuer",
  "betreuer",
  "grund",
];

// @vue/component
export default {
  name: "PruefungBetreuungsverhaeltnis",
  components: {
    SnapshotModule,
    SnapshotIcon,
    Alert,
    FormElement,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      operators: {
        le: "_LE_",
        // ge: "_QE_",
        ge: "_GE_",
        eq: "_EQ_",
      },
      modelEdit: {},
      errors: {},
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      const LIST = [
        {
          id: "teilnehmer",
          htmlId: `${ this.htmlRegelNummer }_teilnehmer`,
          type: "text",
          label: this.modelParameter.label_teilnehmer,
          required: true,
          view: "v",
          editonly: true,
        },
        {
          id: "teilnehmer_mit_betreuer",
          htmlId: `${ this.htmlRegelNummer }_teilnehmer_mit_betreuer`,
          type: "text",
          label: this.modelParameter.label_teilnehmer_mit_betreuer,
          required: true,
          view: "v",
          editonly: true,
        },
        {
          id: "betreuer",
          htmlId: `${ this.htmlRegelNummer }_betreuer`,
          type: "text",
          label: this.modelParameter.label_betreuer,
          required: true,
          view: "v",
          editonly: true
        }
      ];
      if (this.status_grund_verhaeltnis_betreuer) {
        LIST.push({
          id: "grund",
          htmlId: `${ this.htmlRegelNummer }_grund`,
          type: "textarea",
          label: this.labelGrund,
          view: "v-alt",
          required: true,
          editonly: true,
        });
      }
      OPTIONS.list = LIST;
      return OPTIONS;
    },

    labelHeader() {
      return this.modelParameter.frage;
    },

    showErrors() {
      return size(this.errors);
    },

    labelGrund() {
      const TEXT = `_LBL_GR_PRUEFUNG_BETREUUNG_GRUND${ this.operators[this.modelParameter.vergleichsoperator] }{{label_betreuer}}_{{label_teilnehmer_mit_betreuer}}_{{verhaeltnis}}_`;
      return replaceText({
        text: gettext(TEXT),
        object: {
          label_betreuer: this.modelParameter.label_betreuer,
          label_teilnehmer_mit_betreuer: this.modelParameter.label_teilnehmer_mit_betreuer,
          verhaeltnis: this.modelParameter.verhaeltnis,
        },
      });
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(SNAPSHOT_CHANGES_LIST, key => {
          if (this.model[key] !== this.snapshotModule[key]) {
            DIFF[key] = true;
          }
        });
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },

    status_grund_verhaeltnis_betreuer() {
      const MODEL_GRUND = get(this.model, "grund");
      if (MODEL_GRUND) {
        return true;
      }
      const ERRORS_GRUND = get(this.errors, "grund");
      return !!ERRORS_GRUND;
    },
  },
  methods: {
    prepareModel() {
      this.model = cloneDeep(this.module.data);
    },

    save({ model }) {
      const DATA = this.modelEdit && size(this.modelEdit) ? cloneDeep(this.modelEdit) : cloneDeep(model);
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.moduleUrl,
          data: DATA,
        }).then(
          response => {
            this.onRestFunctions({ response });
            this.errors = undefined;
            resolve();
          },
          error => {
            this.errors = error.data;
            reject(error);
          }
        );
      });
    },

    setLocalErrorsFromGlobal({ errors }) {
      this.errors = errors;
    },
  },
};
