import {
  useStore,
} from "vuex";

import {
  isNil,
} from "lodash-es";

export default function SyConfigAPI() {
  const store = useStore();

  function getSyConfigsValue(value, defaultValue) {
    const SY_CONFIG_VALUE = store.getters["syConfigs/GET_VALUE"](value);
    return isNil(SY_CONFIG_VALUE) ? defaultValue : SY_CONFIG_VALUE;
  }

  return {
    getSyConfigsValue,
  };
}
