"use strict";

import template from "./gutachten.ergebnis.jade";
import { cloneDeep } from "lodash-es";

class DiasGutachtenErgebnisController {
  /*@ngInject*/
  constructor($scope, $q, $filter, $state, i18n, Katalog, AuthService) {
    this.Katalog = Katalog;
    this.gettext = i18n.gettext;
    this.$scope = $scope;
    this.$q = $q;
    this.$filter = $filter;
    this.$state = $state;
    this.AuthService = AuthService;
    this.selectedGae = {};
    this.grouped = {};
    this.readonly = (this.readonly === undefined) || this.readonly;
    this.canLink = AuthService.syncHasPerm("gutachten.list");
    $scope.$watch("vm.model.gutachten", (oldVal, newVal) => {
      if (oldVal !== newVal) {
        this.gutachten = cloneDeep(this.model.gutachten) || [];
        this.init();
      }
    });
  }

  init() {
    this.loadingLocal = true;
    this.canViewList = this.AuthService.syncHasPerm("gutachten.view", this.model.user_permissions);
    this.listBaseFilter = { ga_objektid: [this.model.pk] };
    this.$q.all([
      this.Katalog.getKatalog("kgutachtenergebnisse").then(
        response => this.ergebnisse = response
      ),
      this.Katalog.getKatalog("kgutachtenergebnisauswahl").then(
        response => this.auswahl = response
      ),
    ]).then(() => this.prepareData()).finally(() => this.loadingLocal = false);
  }

  prepareData() {
    if (!this.gutachten || !this.auswahl) {
      return;
    }
    this.grouped = {};
    this.ergebnisse.push({
      pk: null,
      gae_bez: this.gettext("In Bearbeitung"),
      gae_cssklasse: "grey-4",
      gae_icon: "glyphicon-bearbeiten-2"
    });
    this.gutachten.forEach(m => {
      let key = this.gettext("Gutachten");
      let label = key;
      if (this.groupByTyp) {
        key = m.typ;
        label = m.typ_bez;
      }
      this.grouped[key] = this.grouped[key] || {
        key: key,
        label: label,
        selected: undefined,
        length: 0,
        linkedLength: 0
      };
      this.grouped[key].length++;
      const gaa = this.auswahl.find(gaa => m.ergebnis === gaa.pk) || { ergebnis: "null" };
      this.grouped[key][gaa.ergebnis] = this.grouped[key][gaa.ergebnis] || {};
      this.grouped[key][gaa.ergebnis].gutachten = this.grouped[key][gaa.ergebnis].gutachten || [];
      this.grouped[key][gaa.ergebnis].gutachten.push(m);
      m.canLink = m.pk && m.can_link && this.canLink;
      if (m.canLink) {
        this.grouped[key].linkedLength++;
      }
    });
    Object.keys(this.grouped).forEach(key => {
      this.ergebnisse.forEach(gae => {
        const group = this.grouped[key];
        let percentage = 0;
        if (group[gae.pk]) {
          percentage = (group[gae.pk].gutachten.length / group.length) * 100;
        } else {
          group[gae.pk] = {
            gutachten: [],
          };
        }
        group[gae.pk].percentage = `${ this.$filter("number")(percentage, 2) } %`;
        group[gae.pk].lineStyle = { "flex-grow": `${ percentage.toFixed(2) }` };
      });
    });
  }

  selectErgebnis(group, gae) {
    if (group.selected === gae) {
      group.selected = undefined;
    } else {
      group.selected = gae;
    }
  }

  getGaeTitle(group, gae) {
    return `${ gae.gae_bez } x ${ group[gae.pk].gutachten.length } (${ group[gae.pk].percentage })`;
  }

  getGaeCssClass(group, gae) {
    return `${ gae.gae_cssklasse } ${ (group.selected === gae) ? "active" : "" }`;
  }

  getGroupLink(group) {
    let filter = this.listBaseFilter;
    if (this.groupByTyp) {
      filter = Object.assign({}, this.listBaseFilter, { typ: group.key });
    }
    return this.$state.href("root.gutachten", filter);
  }

  getLinkTitle(group) {
    if (group.length !== group.linkedLength) {
      return `${ group.length } (${ group.linkedLength } einsehbar) ${ group.label }`;
    }
    return `${ group.length } ${ group.label }`;
  }
}

export default {
  template: template(),
  controller: DiasGutachtenErgebnisController,
  controllerAs: "vm",
  bindings: {
    model: "<ngModel",
    minimal: "<?",
    loading: "<?",
    groupByTyp: "<?"
  }
};
