"use strict";
import {
  cloneDeep,
  forEach,
  keyBy,
  size,
} from "lodash-es";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";

class RegelsatzVersionEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification) {
    super($q, $scope, DiasNotification);

    this.possible_sender = {};
    this.loading = true;
    this.onEnterEditMode = this.onEnterEditMode.bind(this);
    this.errorsLabel = {
      signalempfang: "Signal",
      empfaenger_regel: "Empfänger",
      sender_regeln: "Sender"
    };
  }

  onEnterEditMode() {
    super.onEnterEditMode();
    this.regelsatz = cloneDeep(this.$scope.extra.regelsatz);
    this.orderedSignalEmpfaenger = cloneDeep(this.$scope.extra.orderedSignalEmpfaenger);
    this.groupedSignalEmpfaenger = cloneDeep(this.$scope.extra.groupedSignalEmpfaenger);
    if (!this.regelsatz) {
      this.possible_sender = {};
      this.loading = false;
      return;
    }
    this.regelsatz.regelsignale.getKonfigurierbareSender().then(
      response => {
        response = _.sortBy(response, ["sender_far_nummer"]);
        this.model.forEach(empfang => {
          this.possible_sender[empfang.repeaterTrackId] = response.filter(sender => sender.signal === empfang.signal && empfang.empfaenger_re_id !== sender.sender_re_id);
        });
        this.prepareVueFormElements();
        this.loading = false;
      }
    );
  }

  prepareVueFormElements() {
    const FAR_MAP = {};
    const MODELS = {};
    forEach(this.groupedSignalEmpfaenger, (mses, key) => {
      FAR_MAP[key] = [];
      forEach(mses, mse => {
        mse.senderPKs = cloneDeep(mse.senderPKs || []);
        MODELS[mse.repeaterTrackId] = cloneDeep(mse.senderPKs || []);
        FAR_MAP[key].push({
          data: this.possible_sender[mse.repeaterTrackId],
          field: {
            id: mse.repeaterTrackId,
            type: "multi_select",
            label: mse.mse_kbez,
            tooltip: mse.mse_bez,
            required: mse.mse_pflicht,
            disabled: size(this.possible_sender[mse.repeaterTrackId]) === 0,
            view: "v-alt",
            keyId: "sender_far_id",
            keyLabelCallback: ({ item }) => `[${ item.sender_far_nummer }] ${ item.sender_far_kbez_admin }`,
            search: true,
            deselect: true,
            searchList: ["sender_far_nummer", "sender_far_kbez_admin"],
          },
          change: ({ model }) => {
            this.$scope.$applyAsync(() => {
              mse.senderPKs = cloneDeep(model || []);
              this.vueModels = cloneDeep(this.vueModels);
              this.vueModels[mse.repeaterTrackId] = mse.senderPKs;
            });
          },
        });
      });
    });
    this.vueConfigs = FAR_MAP;
    this.vueModels = MODELS;
  }

  _save(errorsLabel) {
    const tmpModel = [];
    forEach(this.groupedSignalEmpfaenger, v => tmpModel.push(...v));
    const pushModel = tmpModel.map(signalkonfig => {
      const data = {
        signalempfang: signalkonfig.mse_id,
        empfaenger_regel: signalkonfig.empfaenger_far_id,
        sender_regeln: signalkonfig.senderPKs,
        pk: signalkonfig.asv_id
      };
      return data;
    });
    return this.regelsatz.regelsignale.setKonfig(pushModel).then(
      () => {
        this.$scope.$applyAsync(() => {
          this.$scope.extra.refreshData();
        });
        this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
      },
      errors => {
        const tmpErrors = {};
        forEach(errors, (err, idx) => {
          tmpErrors[tmpModel[idx].mse_id] = err;
        });
        return this.onErrors(tmpErrors, errorsLabel);
      }
    );
  }

  setModelMapping() {
    this.modelMapping = keyBy(this.model, "signal");
  }
}

export default RegelsatzVersionEditController;
