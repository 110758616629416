
"use strict";


class SitzungsantragEntscheidungsController {
  /*@ngInject*/
  constructor($q, $scope, $filter, DiasNotification) {
    this.$scope = $scope;
    this.DiasNotification = DiasNotification;
    this.sl = $scope.extras.sl;
    this.entscheidungstati = $filter("orderBy")($scope.extras.entscheidungsstatus, "pos");
    for (let i = 0; i < this.entscheidungstati.length; i++) {
      if (this.entscheidungstati[i].initialer_status) {
        this.initial = this.entscheidungstati[i];
        break;
      }
    }

    const errorsLabel = {
      entscheidungsstatus: "Entscheidung: "
    };

    this.save = () => {
      return this.sl.setRestlicheEntscheidungen(this.entscheidungsstatus).then(
        response => {
          return response;
        },
        error => {
          this.DiasNotification.form.clear();
          this.DiasNotification.form.error(error.data, "Ihre Daten sind fehlerhaft oder unvollständig.", {}, errorsLabel);
          this.errors = error.data;
          return $q.reject(error);
        }
      );
    };
  }
}

export default SitzungsantragEntscheidungsController;
