import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import translate from "../../../../../global/directives/translate";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

import DynamischeZahlenElementMixin from "../DynamischeZahlenElementMixin";

// @vue/component
export default {
  name: "DynamischeZahlenReadonlyElement",
  components: {
    SnapshotIcon,
  },
  directives: {
    translate,
  },
  mixins: [
    DynamischeZahlenElementMixin,
  ],
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: [String, Number],
      default: undefined,
    },
    statusDiff: {
      type: Boolean,
      required: false,
    },
    modelParameter: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      filterCurrency,
    };
  },
};
