"use strict";

import BaseModel from "dias/core/models/baseModel";

class Dokumentarten extends BaseModel {
  constructor() {
    super("dokumentarten");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.searchAntragTyp = dokPk => {
      return collection.getList({ klasse: dokPk });
    };

    return collection;
  }
}

export default Dokumentarten;
