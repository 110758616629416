var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-element"] = jade_interp = function(label, fieldName, errorsMap, prefix){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-class", "{'has-error': " + (errorsMap) + "['" + (fieldName) + "']}", true, true)) + (jade.cls(['form-group','row',attributes.class], [null,null,false])) + "><label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"col-form-label col-sm-3 text-right font-weight-bold required\"><span" + (jade.attr("ng-class", "{'required':vm.isRequired('" + (fieldName) + "')}", true, true)) + " class=\"titel\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span></label><div class=\"col-sm-9 col-xl-6\">");
block && block();
buf.push("<label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"help-block\"><ul" + (jade.attr("ng-if", "" + (errorsMap) + "['" + (fieldName) + "']", true, true)) + " class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errorsMap) + "['" + (fieldName) + "']", true, true)) + ">{{ err }}</li></ul></label></div></div>");
};


























jade_mixins["vorschau-daten"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-repeat", "(key, val) in " + (vorschau) + "", true, true)) + "><span ng-if=\"val\">{{key}}: {{ val }}</span></div>");
};
jade_mixins["vorschau-container"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div>");
jade_mixins["vorschau-daten"](vorschau);
buf.push("</div>");
};
jade_mixins["vorschau-daten"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<hr><div><div" + (jade.cls(['form-group','row',attributes.class], [null,null,false])) + "><label ng-attr-for=\"{{ctx.prefix}}_stueckkostenrechner_kostenposition\" class=\"col-form-label col-sm-3 text-right font-weight-bold\"><span class=\"titel\">{{ ctx.extras.antragsregel.afr_argument.label_kostenposition.wert }}</span></label><div class=\"col-sm-8\"><input type=\"text\"" + (jade.attr("value", "{{" + (vorschau) + ".betrag ? (" + (vorschau) + ".betrag | currency) : 'noch nicht ermittelbar'}} ", true, true)) + " disabled ng-attr-id=\"{{ctx.prefix}}_stueckkostenrechner_kostenposition\" class=\"form-control\"><span class=\"help-block\">{{ " + (jade.escape((jade_interp = vorschau) == null ? '' : jade_interp)) + ".bezeichnung ? " + (jade.escape((jade_interp = vorschau) == null ? '' : jade_interp)) + ".bezeichnung : '-' }}</span></div></div></div>");
};
buf.push("<div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input type=\"text\" maxlength=\"300\" ng-model=\"ctx.posmodel.argument.textfeld_1\" name=\"textfeld_1\" auto-focus=\"true\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_textfeld_1\" ng-attr-aria-invalid=\"{{ctx.errors['textfeld_1'] ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "{{ ctx.extras.antragsregel.afr_argument.label_textfeld_1.wert }}", "textfeld_1", "ctx.errors", "ctx.prefix");
buf.push("</div><div ng-if=\"ctx.extras.antragsregel.afr_argument.label_textfeld_2.wert\">");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input type=\"text\" maxlength=\"300\" ng-model=\"ctx.posmodel.argument.textfeld_2\" name=\"textfeld_2\" auto-focus=\"true\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_textfeld_2\" ng-attr-aria-invalid=\"{{ctx.errors['textfeld_2'] ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "{{ ctx.extras.antragsregel.afr_argument.label_textfeld_2.wert }}", "textfeld_2", "ctx.errors", "ctx.prefix");
buf.push("</div><div ng-if=\"ctx.extras.antragsregel.afr_argument.label_textfeld_3.wert\">");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input type=\"text\" maxlength=\"300\" ng-model=\"ctx.posmodel.argument.textfeld_3\" name=\"textfeld_3\" auto-focus=\"true\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_textfeld_3\" ng-attr-aria-invalid=\"{{ctx.errors['textfeld_3'] ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "{{ ctx.extras.antragsregel.afr_argument.label_textfeld_3.wert }}", "textfeld_3", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<select ng-model=\"ctx.posmodel.argument.einheit\" ng-disabled=\"ctx.einheitChoices.length === 1\" name=\"einheit\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_einheit\" role=\"listbox\" ng-attr-aria-invalid=\"{{ctx.errors['einheit'] ? 'true' : 'false'}}\" class=\"form-control\"><option value=\"\">--- Bitte wählen Sie ---</option><option ng-repeat=\"ein in ctx.einheitChoices\" value=\"{{ein.pk}}\">{{ ein.ein_kbez }}</option></select>");
}
}, "{{ ctx.extras.antragsregel.afr_argument.label_einheit.wert }}", "einheit", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<input type=\"text\" ng-model=\"ctx.posmodel.argument.anzahl_einheiten\" name=\"anzahl_einheiten\" dias-decimal-field aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_anzahl_einheiten\" ng-attr-aria-invalid=\"{{ctx.errors['anzahl_einheiten'] ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "{{ ctx.extras.antragsregel.afr_argument.label_anzahl_einheiten.wert }}", "anzahl_einheiten", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" maxlength=\"9\" ng-model=\"ctx.posmodel.argument.stueckkosten\" name=\"stueckkosten\" dias-decimal-field aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_stueckkosten\" ng-attr-aria-invalid=\"{{ctx.errors['stueckkosten'] ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">&euro;</span></div></div>");
}
}, "{{ ctx.extras.antragsregel.afr_argument.label_stueckkosten.wert }}", "stueckkosten", "ctx.errors", "ctx.prefix");
buf.push("</div>");
jade_mixins["vorschau-container"]("ctx.vorschau");;return buf.join("");
}