import FinanzierugMixin from "../Mixins/FinanzierugMixin";
import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import ZuschussMixin from "../Mixins/ZuschussMixin";

import { indexOf } from "lodash-es";

const SIGNALE = [
  "dynamische_mehrfachauswahl.update",
  "dynamische_zahlen.update",
  "dynamische_tabelle.update",
];

// @vue/component
export default {
  name: "StandardZuschuss",
  components: {
    KFStandard,
  },
  mixins: [
    FinanzierugMixin,
    ZuschussMixin,
  ],
  computed: {
    sumLocal() {
      return this.antragBetragCurrency;
    },
  },
  methods: {
    onReceiveSignal(signal_kennung) {
      if (indexOf(SIGNALE, signal_kennung) !== -1) {
        this.reloadPositionen_KFBaseMixin({ statusSaveCallback: false });
        this.updateZuschuss_KFBaseMixin();
      }
    },

    updateInfoproviderLocal_KFBaseMixin({ response }) {
      response.gespeichert = true;
      this.updateInfoprovider({ response });
    },
  },
};
