import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import AutoFocus from "../../../../global/directives/AutoFocus";
import {
  cloneDeep,
  isEqual,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "Ampel",
  components: {
    SnapshotModule,
  },
  directives: {
    AutoFocus,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      modelEdit: {},
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.open;
      return OPTIONS;
    },

    labelHeader() {
      return this.modelParameter.frage;
    },

    nameRadio() {
      return `${ this.htmlRegelNummer }_radio`;
    },

    idRot() {
      return `${ this.nameRadio }_rot`;
    },

    idGelb() {
      return `${ this.nameRadio }_gelb`;
    },

    idGruen() {
      return `${ this.nameRadio }_gruen`;
    },

    labelRot() {
      return get(this.modelParameter, "antwort.rot", "");
    },

    labelGelb() {
      return get(this.modelParameter, "antwort.gelb", "");
    },

    labelGruen() {
      return get(this.modelParameter, "antwort.gruen", "");
    },

    statusCheckedRot() {
      return this.isChecked("rot");
    },

    statusCheckedGelb() {
      return this.isChecked("gelb");
    },

    statusCheckedGruen() {
      return this.isChecked("gruen");
    },

    statusDiff() {
      if (!this.snapshotModule) {
        return;
      }
      const SNAPSHOT_ANTWORTEN = this.snapshotModule.antworten || {};
      const MODEL_ANTWORTEN = this.model.antworten || {};
      return !isEqual(SNAPSHOT_ANTWORTEN, MODEL_ANTWORTEN);
    },

    errorsLabelsOptions() {
      return [
        {
          id: "rot",
          htmlId: this.idRot,
          label: this.labelRot,
        },
        {
          id: "gelb",
          htmlId: this.idGelb,
          label: this.labelGelb,
        },
        {
          id: "gruen",
          htmlId: this.idGruen,
          label: this.labelGruen,
        },
      ];
    },
  },
  methods: {
    prepareModel() {
      const MODEL = cloneDeep(this.module.data) || {};
      MODEL.antworten = MODEL.antworten || {};
      MODEL.antworten.rot = MODEL.antworten.rot || {};
      MODEL.antworten.rot.wert = MODEL.antworten.rot.wert || false;
      MODEL.antworten.rot.label = MODEL.antworten.rot.label || this.labelRot;
      MODEL.antworten.gelb = MODEL.antworten.gelb || {};
      MODEL.antworten.gelb.wert = MODEL.antworten.gelb.wert || false;
      MODEL.antworten.gelb.label = MODEL.antworten.gelb.label || this.labelGelb;
      MODEL.antworten.gruen = MODEL.antworten.gruen || {};
      MODEL.antworten.gruen.wert = MODEL.antworten.gruen.wert || false;
      MODEL.antworten.gruen.label = MODEL.antworten.gruen.label || this.labelGruen;
      this.model = MODEL;
    },

    isChecked(color) {
      return this.modelEdit.antworten[color] && this.modelEdit.antworten[color].wert;
    },

    open() {
      this.modelEdit = cloneDeep(this.model);
    },

    toggleModel(color) {
      this.modelEdit.antworten.rot.wert = false;
      this.modelEdit.antworten.gelb.wert = false;
      this.modelEdit.antworten.gruen.wert = false;
      this.modelEdit.antworten[color].wert = true;
    },
  },
};
