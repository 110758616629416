"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";

const modul = angular.module("dias.login", [
  uiRouter,
]);

modul.config($stateProvider => {
  $stateProvider

    .state("root.email_change", {
      url: "/email_aendern/:uuid/:tok/",
      views: {
        "main@root": {
          template: "<div vueinit><email-change-page></email-change-page></div>"
        }
      },
    })

    .state("root.login", {
      url: "/login/?m&next",
      views: {
        "main@root": {
          template: "<div vueinit><login-page></login-page></div>"
        }
      },
    })
    .state("root.registration", {
      url: "/registrieren/",
      views: {
        "main@root": {
          template: "<div vueinit><registration-page></registration-page></div>"
        }
      },
    })
    .state("root.registration.details", {
      url: ":uuid/:tok/",
      views: {
        "main@root": {
          template: "<div vueinit><registration-finish-page></registration-finish-page></div>"
        }
      },
    })

    .state("root.reset_password", {
      url: "/zuruecksetzen/",
      views: {
        "main@root": {
          template: "<div vueinit><reset-password-page></reset-password-page></div>"
        }
      },
    })
    .state("root.reset_password.details", {
      url: ":uuid/:tok/",
      views: {
        "main@root": {
          template: "<div vueinit><reset-password-finish-page></reset-password-finish-page></div>"
        }
      },
    })
  ;
})
;

export default modul;
