"use strict";

import StandardFinanzierungController from "../../finanzierung.controller";
import {
  EventBus,
} from "../../../../../vue/client/vue/global/functions/event-bus";


class FinanzierungSignalPeriodeController extends StandardFinanzierungController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService) {
    super($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService);
    this.readonly = true;
    this.showPerioden = {};
    this.updateOnSignal = () => {
      this._reloadPositionen();
      this._doSavecallback();
      this.Foerderantrag.one(this.modul.regel.afr_aid).customGET("zuschuss").then(
        response => {
          Object.assign(this.infoprovider, response.plain());
          this.infoprovider.gespeichert = true;
        }
      );
    };
    this.registerOrDeactiveSignalReceiver();
  }

  registerOrDeactiveSignalReceiver({ eventBusAction = "$on" } = {}) {
    const SIGNAL_EMPFANG = this.modul.regel.signal_empfang || [];
    if (!SIGNAL_EMPFANG.length) {
      return;
    }

    SIGNAL_EMPFANG.forEach(item => {
      if (item.signal_kennung && item.sender_reid) {
        EventBus[eventBusAction](`${ item.signal_kennung }_${ item.sender_reid }`, arg => this.onReceiveSignal(item.signal_kennung, arg));
      }
    });
  }

  onReceiveSignal(signal_kennung) {
    if (signal_kennung === "dynamische_tabelle.update" ||
      signal_kennung === "dynamische_zahlen.update") {
      this.updateOnSignal();
    }
  }
}

export default FinanzierungSignalPeriodeController;
