import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import AntraegeListDetails from "../AntraegeListDetails/AntraegeListDetails.vue";
import AufgabeModalAendernZustaendigkeit from "../../Aufgabe/AufgabeModalAendernZustaendigkeit/AufgabeModalAendernZustaendigkeit.vue";
import AufgabeModalAnnehmen from "../../Aufgabe/AufgabeModalAnnehmen/AufgabeModalAnnehmen.vue";
import Favoriten from "../../Favoriten/Favoriten.vue";
import NutzerschlagwortBadge from "../../Nutzerschlagwort/NutzerschlagwortBadge/NutzerschlagwortBadge.vue";
import NutzerschlagwortObjektModalAdd from "../../Nutzerschlagwort/NutzerschlagwortObjektModalAdd/NutzerschlagwortObjektModalAdd.vue";
import NutzerschlagwortObjektModalEdit from "../../Nutzerschlagwort/NutzerschlagwortObjektModalEdit/NutzerschlagwortObjektModalEdit.vue";
import NutzerschlagwortObjektModalRemove from "../../Nutzerschlagwort/NutzerschlagwortObjektModalRemove/NutzerschlagwortObjektModalRemove.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Sichtbarkeit from "../../Sichtbarkeit/Sichtbarkeit.vue";
import SmartTable from "../../../global/components/table/table.vue";
import Spaced from "../../../global/components/Spaced/Spaced.vue";

import DynamicRootForComponentsMixin from "../../DynamicRoot/DynamicRootForComponentsMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";
import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../global/compositionAPI/PermissionAPI";
import SyConfigAPI from "../../../global/compositionAPI/SyConfigAPI";

import {
  render_ffk_columns,
} from "../../../global/components/table/TableColsUndFiltersFromSyConfig";
import {
  EventBus,
} from "../../../global/functions/event-bus";
import {
  getSelectorCloseForTableRowFunction,
  replaceText,
} from "../../../global/functions/utils";
import {
  cloneDeep,
  forEach,
  get,
  isNil,
  isUndefined,
  size,
  uniqBy,
} from "lodash-es";

const TABLE_ID = "foerderantrag";

// @vue/component
export default {
  name: "AntraegeList",
  components: {
    AngularLink,
    AntraegeListDetails,
    AufgabeModalAendernZustaendigkeit,
    AufgabeModalAnnehmen,
    Favoriten,
    NutzerschlagwortBadge,
    NutzerschlagwortObjektModalAdd,
    NutzerschlagwortObjektModalEdit,
    NutzerschlagwortObjektModalRemove,
    PuxPageDetails,
    PuxTranslate,
    Sichtbarkeit,
    SmartTable,
    Spaced,
  },
  mixins: [
    DynamicRootForComponentsMixin,
  ],
  props: {
    header: {
      type: String,
      required: false,
      default: "_TXT_ANTRAEGE_TABLE_HEADER_",
      info: "Text für Tabelle-Header",
    },
    filter: {
      type: Object,
      required: false,
      default: undefined,
      info: "Zusätzliches Get-Parameter für API",
    },
  },
  setup() {
    const {
      filterCurrency,
      filterDate,
      filterLimitTo,
      filterSpaced,
    } = FiltersAPI();

    const {
      getSyConfigsValue,
    } = SyConfigAPI();

    const {
      deleteHttp,
      postHttp
    } = HttpAPI();

    const {
      checkPermissionsSync,
      me,
    } = PermissionAPI();

    return {
      checkPermissionsSync,
      deleteHttp,
      filterCurrency,
      filterDate,
      filterLimitTo,
      filterSpaced,
      getSyConfigsValue,
      me,
      postHttp,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      tableNameAlt: "foerderantrag",
      options: {
        id: TABLE_ID,
        url: this.initUrl({ tableId: TABLE_ID, filter: this.filter }),
        baseUrl: this.initUrl({ tableId: TABLE_ID, filter: undefined }),
        rowActions: [
          {
            label: "_LBL_ANTRAEGE_TABLE_ROW_ACTIONS_DETAILS_ANZEIGEN_",
            route: "root.foerderantrag.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
          {
            label: "_LBL_ANTRAEGE_TABLE_ROW_ACTIONS_ANTRAG_MARKIEREN_",
            isHidden: ({ item }) => !this.checkPermissionsSync({ perm: "favoriten.toggle" }) || item.favorit,
            callback: this.toggleFavorit,
          },
          {
            label: "_LBL_ANTRAEGE_TABLE_ROW_ACTIONS_ANTRAG_NCIHT_MARKIEREN_",
            isHidden: ({ item }) => !this.checkPermissionsSync({ perm: "favoriten.toggle" }) || !item.favorit,
            callback: this.toggleFavorit,
          },
          {
            label: "_LBL_ANTRAEGE_TABLE_ROW_ACTIONS_AUFGABE_ANNEHMEN_",
            isHidden: () => !this.checkPermissionsSync({ perm: "foerderantrag.list.action.annehmen" }) || !this.checkPermissionsSync({ perm: "workflow.ausfuehren" }),
            callback: this.openModalAufgabeAnnehmen,
          },
          {
            label: "_LBL_ANTRAEGE_TABLE_ROW_ACTIONS_VERTRAG_ANZEIGEN_",
            labelCallback: () => "_LBL_ANTRAEGE_TABLE_ROW_ACTIONS_VERTRAG_ANZEIGEN_CALLBACK_{{v_nr}}_",
            isHidden: ({ item }) => !item.vertrag || !this.checkPermissionsSync({ perm: "vertrag.view" }),
            extraCallback: ({ item }) => this.getVertragNr({ item }),
            route: "root.vertrag.details",
            routeParameters: [{ routeId: "id", rowPath: "vertrag.pk" }],
          },
          {
            label: "_LBL_ANTRAEGE_TABLE_ROW_ACTIONS_ANTRAGSTELLER_ANZEIGEN_",
            isHidden: ({ item }) => !item.antragstellerorg_obj,
            route: "root.astorganisationen.details",
            routeParameters: [{ routeId: "id", rowPath: "antragstellerorg_obj.pk" }],
          },
          {
            label: "_TXT_ANTRAEGE_TABLE_NUTZERSCHLAGWORTOBJEKT_UPDATE_BTN_",
            callback: this.openModalNutzerschlagwortobjektEdit,
            isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          },
        ],
        actions: [
          {
            permission: "foerderantrag.create",
            label: "_TXT_ANTRAEGE_TABLE_ACTIONS_CREATE_",
            route: "root.foerderantrag-add",
            type: "link",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
          },
        ],
        massActions: [],
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
          details: "right",
        },
      },
      filters: [
      ],
      modal: {
        aendernZustaendigkeit: undefined,
        aufgabeAnnehmen: undefined,
        nutzerschlagwortobjektEdit: undefined,
        nutzerschlagwortobjektMassActionAdd: undefined,
        nutzerschlagwortobjektMassActionRemove: undefined,
      },
      rows: undefined,
      user: undefined,
      render_ffk_columns: render_ffk_columns,
      currentAntrag: undefined,
      selectedRows: undefined,
      currentAufgabe: undefined,
      selectorClose: undefined,
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },

    getSlotNameTableDetailsHeader() {
      return `${ this.options.id }_table_details_header`;
    },

    gettextStatusgruppe() {
      return row => this.$gettext(row.statusgruppe);
    },

    nutzerschlagwortModalEditTitleTranslation() {
      return {
        text: "_TXT_ANTRAEGELISTE_NUTZERSCHLAGWORT_MODAL_EDIT_TITLE_"
      };
    },

    nutzerschlagwortModalEditMsgTranslation() {
      return {
        html: "_HTML_ANTRAEGELISTE_NUTZERSCHLAGWORT_MODAL_EDIT_INFO_"
      };
    },

    nutzerschlagwortModalMassActionAddTitleTranslation() {
      return {
        text: "_TXT_ANTRAEGELISTE_NUTZERSCHLAGWORT_MODAL_MANY_ADD_TITLE_"
      };
    },

    nutzerschlagwortModalMassActionAddMsgTranslation() {
      return {
        html: "_HTML_ANTRAEGELISTE_NUTZERSCHLAGWORT_MODAL_MANY_ADD_INFO_{{anz}}_",
        extra: {
          anz: size(this.selectedRows) || 0,
        }
      };
    },

    nutzerschlagwortModalMassActionRemoveTitleTranslation() {
      return {
        text: "_TXT_ANTRAEGELISTE_NUTZERSCHLAGWORT_MODAL_MANY_REMOVE_TITLE_"
      };
    },

    nutzerschlagwortModalMassActionRemoveMsgTranslation() {
      return {
        html: "_HTML_ANTRAEGELISTE_NUTZERSCHLAGWORT_MODAL_MANY_REMOVE_INFO_{{anz}}_",
        extra: {
          anz: size(this.selectedRows) || 0,
        }
      };
    },

    antragScoreConfig() {
      return this.getSyConfigsValue("antrag_score") || {};
    },
  },
  created() {
    this.initRestFunctions();
    this.getUser();
  },
  methods: {
    renderAntragsteller({ row }) {
      if (!row.antragstellerorg_obj) {
        return "";
      }
      const antragstellerorg_obj = row.antragstellerorg_obj || {};
      return `<span title="${ antragstellerorg_obj.ast_name }">${ this.filterLimitTo(antragstellerorg_obj.ast_name) }</span>`;
    },

    renderDufo({ row }) {
      return `<span title="${ row.dufo_kbez }">${ this.filterLimitTo(row.dufo_kbez) }</span>`;
    },

    renderFoerderorg({ row, typ, field }) {
      let result = "";
      if (row[field] && row[field].length > 0) {
        result = row[field].filter(item => item.o_otid === typ).map(item => item.o_kbez).join(", ");
      }
      return result;
    },

    renderAntragsbearbeiter({ row }) {
      let foerderorg_ansprechpartner = "";
      forEach(row.foerderorg_ansprechpartner, item => {
        foerderorg_ansprechpartner = `${ foerderorg_ansprechpartner }<li>${ item.nutzer && item.nutzer.name } (${ item.typ.apt_kbez })</li>`;
      });
      return `<ul class="list-unstyled">${ foerderorg_ansprechpartner }</ul>`;
    },

    initFilters() {
      if (this.$stateParams.dashboard) {
        this.filters.push({
          type: "select",
          id: "dashboard",
          label: "_LBL_ANTRAEGE_TABLE_FILTER_NUR_PRUEFENDE_ANTRAEGE_",
          data: [
            {
              value: this.$stateParams.dashboard,
              label: "_LBL_YES_"
            },
            {
              value: "",
              label: "_LBL_NO_"
            }
          ],
          keyLabel: "label",
          keyId: "value",
          dataTranslate: true,
          group: "_TXT_ANTRAEGE_TABLE_FILTER_GROUP_BEARBEITUNG_",
        });
      }
    },

    initMassActions() {
      if (this.checkPermissionsSync({ perm: "aufgabe.zuweisen.batch" })) {
        this.options.massActions.push({
          label: "_TXT_ANTRAEGE_TABLE_MASS_ACTIONS_ZUSTAENDIGKEIT_AENDERN_",
          callback: ({ rows }) => this.batchAufgabeZuweisen({ rows }),
          modal: true,
        });
      }
      if (this.checkPermissionsSync({ perm: "nutzerschlagworte.update" })) {
        this.options.massActions.push({
          label: "_TXT_ANTRAEGE_TABLE_MASS_ACTIONS_NUTZERSCHLAGWORT_ADD_BTN_",
          callback: ({ rows }) => this.openModalNutzerschlagwortobjektMassActionAdd({ rows }),
          isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          modal: true,
        });
        this.options.massActions.push({
          label: "_TXT_ANTRAEGE_TABLE_MASS_ACTIONS_NUTZERSCHLAGWORT_REMOVE_BTN_",
          callback: ({ rows }) => this.openModalNutzerschlagwortobjektMassActionRemove({ rows }),
          isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          modal: true,
        });
      }
    },

    batchAufgabeZuweisen({ rows }) {
      this.selectorClose = `#${ TABLE_ID }`;
      this.rows = rows;
      this.modal.aendernZustaendigkeit = true;
    },

    closeModalAendernZustaendigkeit({ status } = {}) {
      this.modal.aendernZustaendigkeit = false;
      EventBus.$emit(`cancelMassActions${ TABLE_ID }`, { status });
    },

    closeModalNutzerschlagwortobjektEdit({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektEdit = false;
    },

    openModalNutzerschlagwortobjektEdit({ item, index }) {
      this.selectorClose = getSelectorCloseForTableRowFunction({
        tableId: TABLE_ID,
        index,
      });
      this.currentAntrag = item;
      this.modal.nutzerschlagwortobjektEdit = true;
    },

    closeModalNutzerschlagwortobjektMassActionAdd({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektMassActionAdd = false;
      EventBus.$emit(`cancelMassActions${ this.tableId }`, { status });
    },

    openModalNutzerschlagwortobjektMassActionAdd({ rows }) {
      this.selectorClose = `#${ TABLE_ID }`;
      this.selectedRows = rows;
      this.modal.nutzerschlagwortobjektMassActionAdd = true;
    },

    closeModalNutzerschlagwortobjektMassActionRemove({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektMassActionRemove = false;
      EventBus.$emit(`cancelMassActions${ this.tableId }`, { status });
    },

    openModalNutzerschlagwortobjektMassActionRemove({ rows }) {
      this.selectorClose = `#${ TABLE_ID }`;
      this.selectedRows = rows;
      this.modal.nutzerschlagwortobjektMassActionRemove = true;
    },

    openModalAufgabeAnnehmen({ item, index }) {
      this.selectorClose = getSelectorCloseForTableRowFunction({
        tableId: TABLE_ID,
        index,
      });
      this.currentAntrag = item;
      this.currentAufgabe = {
        pk: item.aktuelle_aufgabe,
        kbez: replaceText(
          {
            text: window.gettext("_TXT_ANTRAEGE_TABLE_ROW_ACTIONS_AUFGABE_ANNEHMEN_AUFGABE_LABEL_{{aufgabe}}_{{antragsnummer}}_"),
            object: {
              aufgabe: item.status_detail || item.status,
              antragsnummer: this.filterSpaced(item.a_nr, true),
            }
          },
        )
      };
      this.modal.aufgabeAnnehmen = true;
    },

    closeModalAufgabeAnnehmen({ status } = {}) {
      if (status) {
        this.$goTo("root.foerderantrag.details", { id: this.currentAntrag.pk });
      }
      this.modal.aufgabeAnnehmen = false;
    },

    getUser() {
      this.user = cloneDeep(this.me);
    },

    toggleFavorit({ item, index }) {
      if (isUndefined(item) || item._blockedToggleFavorit) {
        return;
      }
      const row = cloneDeep(item);
      row._blockedToggleFavorit = true;
      row.favorit = !row.favorit;
      EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row, index }));

      const oldValue = item.favorit;
      if (oldValue) {
        this.deleteHttp({ url: `foerderantraege/${ item.pk }/favorisieren/` }).then(
          () => {
            row._blockedToggleFavorit = false;
            EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row, index }));
          }
        );
      } else {
        this.postHttp({ url: `foerderantraege/${ item.pk }/favorisieren/` }).then(
          () => {
            row._blockedToggleFavorit = false;
            EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row, index }));
          }
        );
      }
    },

    getVertragNr({ item }) {
      return {
        v_nr: this.filterSpaced(item.vertrag.v_nr),
      };
    },

    onCallbackFavoriten({ rowIndex, data }) {
      EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: data, index: rowIndex }));
    },

    onCallbackSichtbarkeit({ rowIndex, data }) {
      EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: data, index: rowIndex }));
    },

    initRestFunctions() {
      this.initFilters();
      this.initMassActions();
    },

    getValue(obj, field, def) {
      return get(obj, field ? field : def);
    },

    getList(props) {
      let data = get(props.data, props.col.id);
      if (!isNil(props.col.extra)) {
        forEach(props.col.extra, (val, key) => {
          if (key !== "field") {
            data = data.filter(el => get(el, key) === val);
          }
        });
      }
      data = uniqBy(data, "pk");
      return data;
    },
  },
};
