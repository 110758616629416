"use strict";

class AuszahlungsmodiBaseController {
  constructor($scope, ParameterUtils) {
    this.loading = true;
    this.ParameterUtils = ParameterUtils;
    this.$scope = $scope;
    this.konfig_keys = ParameterUtils.get_ordered_keys(this.parameter[this.key].konfig);
    this.updateParamWert(this.parameter);
  }

  updateParamWert(params) {
    if (angular.isUndefined(params[this.key].wert) || params[this.key].wert === null) {
      params[this.key].wert = [];
    } else {
      const konfigs = [];
      for (let i = 0; i < params[this.key].wert.length; i++) {
        konfigs[i] = this.ParameterUtils.params_from_template(
          params[this.key].konfig,
          params[this.key].wert[i]
        );
      }
      params[this.key].wert.splice(0, params[this.key].wert.length);
      params[this.key].wert.splice(0, 0, ...konfigs);
    }
  }

  preventFurtherAction($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }
}


export default AuszahlungsmodiBaseController;
