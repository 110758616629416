import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

import BetragsrechnerMixin from "../../Mixins/BetragsrechnerMixin";
import DhwPersonalkostenPeriodenFormElementsMixin from "./DhwPersonalkostenPeriodenFormElementsMixin";

// @vue/component
export default {
  name: "DhwPersonalkostenPerioden",
  components: {
    PuxButton,
  },
  mixins: [
    BetragsrechnerMixin,
    DhwPersonalkostenPeriodenFormElementsMixin,
  ],
  emits: [
    "updateOptionsFromChild",
  ],
  computed: {
    errorLabels() {
      return {
        [this.optionsForPersonalstellenperson.id]: {
          label: this.optionsForPersonalstellenperson.label,
          link: true,
          id: this.optionsForPersonalstellenperson.htmlId,
        },
        [this.optionsForPosperiode.id]: {
          label: this.optionsForPosperiode.label,
          link: true,
          id: this.optionsForPosperiode.htmlId,
        },
        [this.optionsForVon.id]: {
          label: this.optionsForVon.label,
          link: true,
          id: this.optionsForVon.htmlId,
        },
        [this.optionsForBis.id]: {
          label: this.optionsForBis.label,
          link: true,
          id: this.optionsForBis.htmlId,
        },
        [this.optionsForBelegbetrag.id]: {
          label: this.optionsForBelegbetrag.label,
          link: true,
          id: this.optionsForBelegbetrag.htmlId,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
    this.updateTooltipsInParentOptions();
  },
  methods: {
    updateTooltipsInParentOptions() {
      this.$emit("updateOptionsFromChild", {
        tooltipBelegNummer: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_BELEGNUMMER_INFO_I_",
        tooltipBelegBez: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_BEZEICHNUNG_INFO_I_",
        tooltipKommentar: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_KOMMENTAR_INFO_I_",
      });
    },
  },
};
