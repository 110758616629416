"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import models from "dias/auth/models";
import astModels from "dias/antragsteller/models";


const nutzerverwaltungModule = angular.module("nutzerverwaltung", [
  uiRouter,
  models.name,
  diasTable.name,
  diasNotification.name,
  astModels.name,
]);

nutzerverwaltungModule.config($stateProvider => {
  $stateProvider

    .state("root.nutzerverwaltung", {
      url: `/nutzerverwaltung/?
            user_cookie&
            aktiv&
            astorganisation&
            rel_astoid&
            rel_oid&
            rolle&
            status`,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-nutzer-list></vue-nutzer-list></div>"
        }
      },
      data: {
        permissions: "nutzer.ui"
      }
    })

    // Detail-Seite eines Nutzers
    // Sollte immer als letztes eingefügt werden, sonst werden Sub-URLs
    // as ID aufgelöst

    .state("root.nutzerverwaltung.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><nutzer-details></nutzer-details></div>",
        },
      },
    });
});

export default nutzerverwaltungModule;
