import HttpMixin from "../../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../../global/mixins/NotificationMixin";
import SyConfigMixin from "../../../../../global/mixins/SyConfigMixin";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import {
  cloneDeep,
  forEach,
  find,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "AstVertretungsberechtigteModalCreateOrUpdate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    SyConfigMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    currentVertreter: {
      type: Object,
      required: false,
      default: undefined,
    },
    url: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      default: -1,
    },
    modelFromModul: {
      type: Object,
      required: true,
    },
    selectorClose: {
      type: [String, Array],
      required: true,
    },
    infoIText: {
      type: String,
      required: false,
      default: undefined,
    },
    save: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
      vertreterFieldsRequired: {},
    };
  },
  computed: {
    options() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "anrede",
            type: "select",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_ANREDE_",
            required: !!this.vertreterFieldsRequired.anrede,
            view: "v",
            keyId: "id",
            keyLabel: "ank_kbez",
            url: "katalog/?fields=id&fields=ank_kbez&key=kanrede",
            translate: true,
          },
          {
            id: "av_titel",
            type: "text",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_TITEL_",
            maxlength: 200,
            view: "v",
            translate: true,
            required: !!this.vertreterFieldsRequired.av_titel,
            hideIf: false,
          },
          {
            id: "titel",
            type: "select",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_TITEL_",
            view: "v",
            keyId: "pk",
            keyLabel: "kti_kbez",
            url: "katalog/?key=titel",
            translate: true,
            required: !!this.vertreterFieldsRequired.av_titel,
            hideIf: false,
            deselect: true,
          },
          {
            id: "av_vorname",
            type: "text",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_VORNAME_",
            maxlength: 200,
            view: "v",
            required: !!this.vertreterFieldsRequired.av_vorname,
            translate: true,
          },
          {
            id: "av_name",
            type: "text",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_NACHNAME_",
            maxlength: 200,
            view: "v",
            required: !!this.vertreterFieldsRequired.av_name,
            translate: true,
          },
          {
            id: "av_email",
            type: "text",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_EMAIL_",
            maxlength: 200,
            view: "v",
            required: !!this.vertreterFieldsRequired.av_email,
            translate: true,
          },
          {
            id: "av_funktion",
            type: "text",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_FUNKTION_",
            maxlength: 100,
            view: "v",
            required: !!this.vertreterFieldsRequired.av_funktion,
            translate: true,
          },
          {
            id: "berechtigung",
            type: "select",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_BERECHTIGUNG_",
            keyId: "id",
            keyLabel: "kvb_kbez",
            url: "katalog/?fields=id&fields=kvb_kbez&key=vertretungsberechtigung",
            view: "v",
            required: !!this.vertreterFieldsRequired.berechtigung,
            translate: true,
          },
          {
            id: "av_gueltig_von",
            type: "date",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_GUELTIG_VON_",
            view: "v",
            required: !!this.vertreterFieldsRequired.av_gueltig_von,
            translate: true,
          },
          {
            id: "av_gueltig_bis",
            type: "date",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_GUELTIG_BIS_",
            view: "v",
            required: !!this.vertreterFieldsRequired.av_gueltig_bis,
            translate: true,
          },
        ],
      };
    },

    headerText() {
      return this.currentVertreter ?
        "_TXT_GR_AST_VERTRETUNGSBERECHTIGTE_MODAL_UPDATE_HEADER_" :
        "_TXT_GR_AST_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_HEADER_";
    },

    msgSuccess() {
      return this.currentVertreter ?
        "_MSG_GR_AST_VERTRETUNGSBERECHTIGTE_MODAL_UPDATE_SUCCESS_" :
        "_MSG_GR_AST_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_SUCCESS_";
    },

    msgError() {
      return this.currentVertreter ?
        "_MSG_GR_AST_VERTRETUNGSBERECHTIGTE_MODAL_UPDATE_ERROR_" :
        "_MSG_GR_AST_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_ERROR_";
    },

    vertreterIndex() {
      if (isUndefined(this.currentVertreter)) {
        return (this.modelFromModul.vertretungsberechtigte.length);
      }
      let idx = undefined;
      forEach(this.modelFromModul.vertretungsberechtigte, (item, index) => {
        if (this.currentVertreter.pk === item.pk) {
          idx = index;
          return false;
        }
      });
      return idx;
    },
  },
  created() {
    this.setVertreterFields();
    this.initModel();
  },
  methods: {

    initModel() {
      let MODEL = {};
      if (this.currentVertreter) {
        MODEL = cloneDeep(this.currentVertreter);
      } else {
        MODEL = {};
      }
      MODEL.validierung_erforderlich = true;
      this.model = MODEL;
    },

    setVertreterFields() {
      const CONFIG = this.getSyConfigsValue("vertretungsberechtigte") || {};
      const PFLICHTFELDER = CONFIG.pflichtfelder || [];
      const VERTRETER_FIELDS_REQUIRED = {};
      forEach(PFLICHTFELDER, item => {
        VERTRETER_FIELDS_REQUIRED[item] = true;
      });
      this.vertreterFieldsRequired = VERTRETER_FIELDS_REQUIRED;
      find(this.options.list, ["id", "av_titel"]).hideIf = CONFIG.titel_katalog || CONFIG.hide_titel || false ;
      find(this.options.list, ["id", "titel"]).hideIf = !CONFIG.titel_katalog || CONFIG.hide_titel || false ;
    },

    prepareDataForSave() {
      const DATA = cloneDeep(this.modelFromModul);
      if (this.model.av_allein_berechtigt) {
        this.model.av_allein_berechtigt = this.model.av_allein_berechtigt === "true";
      }
      DATA.vertretungsberechtigte.splice(this.vertreterIndex, 1, this.model);
      return DATA;
    },

    saveLocal() {
      this.status.loading = true;
      this.clearErrors();
      const DATA = this.prepareDataForSave();
      this.save({
        model: DATA
      }).then(
        () => {
          this.addNotification({
            text: this.msgSuccess,
          });
          this.close();
        },
        error => {
          this.onError({ error: error.data });
          this.addNotification({
            text: this.msgError,
            type: "error",
          });
        }
      ).then(
        () => this.status.loading = false
      );
    },

    onError({ error }) {
      if (error.vertretungsberechtigte && error.vertretungsberechtigte[this.vertreterIndex]) {
        this.errors = error.vertretungsberechtigte[this.vertreterIndex];
        this.errorsClone = cloneDeep(error.vertretungsberechtigte[this.vertreterIndex]);
      }
    },

    clearErrors() {
      this.errors = undefined;
      this.errorsClone = undefined;
    },
  },
};
