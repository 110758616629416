import BelegeModalCreateOrEdit from "../BelegeModalCreateOrEdit/BelegeModalCreateOrEdit.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "BelegCreateButton",
  components: {
    BelegeModalCreateOrEdit,
    PuxButton,
    Permission,
  },
  props: {
    statusShow: {
      type: Boolean,
      required: false,
      info: "Button anzeigen oder nicht(Vertrag-Permission 'vertragskostenpositionsbelege.create')",
    },
    vertrag: {
      type: Object,
      required: true,
      info: "Objekt Vertrag",
    },
    auszahlung: {
      type: Object,
      required: false,
      default: undefined,
      info: "Objekt Auszahlung",
    },
  },
  data() {
    return {
      statusModal: false,
      buttonId: "beleg_create_button",
    };
  },
  computed: {
    selectorClose() {
      return `#${ this.buttonId }`;
    },
  },
  methods: {
    open() {
      this.statusModal = true;
    },

    close() {
      this.statusModal = false;
    },
  },
};
