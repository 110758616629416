import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "SnapshotIcon",
  components: {
    PuxIcon,
    PuxTranslate,
  },
  props: {
    type: {
      type: String,
      default: "changes",
      validator: value => ["changes", "add", "delete"].indexOf(value) !== -1,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titles: {
        changes: "_TXT_SNAPSHOT_ICON_CHANGES_",
        add: "_TXT_SNAPSHOT_ICON_ADD_",
        delete: "_TXT_SNAPSHOT_ICON_DELETE_",
      },
    };
  },
  computed: {
    iconClass() {
      const SUFFIX = this.type === "changes" ? "" : `-${ this.type }`;
      return `glyphicon-changes${ SUFFIX }`;
    },

    iconTitle() {
      return this.titles[this.type];
    },
  },
};
