"use strict";

export default () => num => {
  if (num === undefined || num === null) {
    return num;
  }
  if (!angular.isString()) {
    return Number(num).toLocaleString();
  } 
  num += "";
    
  const arr = num.split(",");
  const arrIntPart = arr[0].split("");

  let fValue = [];
  let j = 1;
  for (let i = arrIntPart.length - 1; i >= 0; i--) {
    fValue.unshift(arrIntPart[i]);
    if (j % 3 === 0 && i !== 0) {
      fValue.unshift(".");
    }
    j++;
  }
  fValue = fValue.join("");
  if (arr.length > 1) {
    fValue += arr[1];
  }
  return fValue;
};
