import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import FaqBadge from "./FaqBadge/FaqBadge.vue";

import DashboardFaqMixin from "../DashboardFaqMixin";
import DashboardIconMixin from "../DashboardIconMixin";
import DashboardModuleMixin from "../DashboardModuleMixin";
import DashboardStylesMixin from "../DashboardStylesMixin";

// @vue/component
export default {
  name: "DashboardFaqAuswahl",
  components: {
    AngularLink,
    FaqBadge,
    FormElement,
    PuxTranslate,
    PuxButton,
    PuxIcon,
  },
  mixins: [
    DashboardFaqMixin,
    DashboardIconMixin,
    DashboardModuleMixin,
    DashboardStylesMixin,
  ],
  props: {
    argument: {
      type: Object,
      required: true,
      info: "Hauptinformation",
    },
  },
  computed: {
    parameter() {
      return this.argument.parameter || {};
    },

    classBox() {
      return this.parameter.css_klasse;
    },

    footerText() {
      return this.parameter.text;
    },

    rubricPk() {
      return this.parameter.rubrik;
    },

    modelKeywords() {
      return this.parameter.schlagworte || [];
    },

    linkSref() {
      return this.parameter.goto || "root.faqs";
    },
  },
};
