import Modal from "../../../global/components/Modal/Modal.vue";
import NotizCreateOrEditModal from "./NotizCreateOrEditModal/NotizCreateOrEditModal.vue";
import NotizModalReadonly from "./NotizModalReadonly/NotizModalReadonly.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import NotizenMixin from "../NotizenMixin";

// @vue/component
export default {
  name: "NotizenModal",
  components: {
    Modal,
    NotizCreateOrEditModal,
    NotizModalReadonly,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    NotizenMixin,
  ],
  props: {
    labelTranslate: {
      type: Object,
      required: true,
    },
    singlenotiz: {
      type: Boolean,
      required: false,
    },
    singlenotizIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
    selectorClose: {
      type: [String, Array],
      required: true,
    },
    rubrics: {
      type: Array,
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      user: {},
      loading: true,
      options: {
        showCloseButton: true,
        cancelLabel: "_BTN_CLOSE_",
      },
      statusNotizCreate: undefined,
      currentKeyNotiz: undefined,
      buttonIdAddNotizenModal: "add_notizen_modal",
    };
  },
  computed: {
    statusNoOrg() {
      return !(this.GET_NOTIZEN_OPTIONS.orgList && this.GET_NOTIZEN_OPTIONS.orgList.length || this.GET_NOTIZEN_OPTIONS.antragstellerorg) &&
        this.noOrgText && !this.singlenotiz;
    },

    noOrgText() {
      return this.GET_NOTIZEN_OPTIONS.noOrgText || "_TXT_NOTIZEN_NO_ORG_";
    },

    statusShowButtonNotizHinzufuegen() {
      return !this.singlenotiz &&
        (this.GET_NOTIZEN_OPTIONS.orgList &&
        this.GET_NOTIZEN_OPTIONS.orgList.length || this.GET_NOTIZEN_OPTIONS.antragstellerorg);
    },

    selectorCloseAddNotizenModal() {
      return `#${ this.buttonIdAddNotizenModal }`;
    },
  },
  created() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      this.$AuthService.getUser().then(
        response => {
          this.user = response;
          this.loading = false;
        }
      );
    },

    copyNotiz({ notiz } = {}) {
      let str = "";
      if (notiz) {
        str = notiz.no_text;
      } else if (this.notizenList.length) {
        str = this.notizenList.map(notiz => notiz.no_text).join(
          "<p>--</p>"
        );
      }
      const fakeElem = document.createElement("div");
      fakeElem.style.position = "absolute";
      fakeElem.style.left = "-9999px";
      document.body.appendChild(fakeElem);
      fakeElem.innerHTML = str;
      this.selectText(fakeElem);
      document.execCommand("copy");
      document.body.removeChild(fakeElem);
      this.addNotification({ text: "_MSG_NOTIZEN_MODAL_COPY_", type: "info" });
    },

    selectText(el) {
      if (document.selection) {
        const range = document.body.createTextRange();
        range.moveToElementText(el);
        range.select();
      } else if (window.getSelection) {
        const range = document.createRange();
        range.selectNodeContents(el);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }
    },

    openCreateNotiz() {
      if ((!this.rubrics || !this.rubrics.length) && this.notizenForKey.length === 1) {
        this.currentKeyNotiz = this.notizenForKey[0].key;
      }
      this.statusNotizCreate = true;
    },

    closeCreateNotiz() {
      this.currentKeyNotiz = undefined;
      this.statusNotizCreate = false;
    },
  },
};
