import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import FinanzierugMixin from "../Mixins/FinanzierugMixin";

// @vue/component
export default {
  name: "DeckungslueckeFinanzierung",
  components: {
    KFStandard,
  },
  mixins: [
    FinanzierugMixin,
  ],
  computed: {
    sumLocal() {
      return this.summeCurrency;
    },
  },
};
