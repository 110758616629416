"use strict";

import BetragsrechnerRegistry from "dias/kosten_finanzierung/betragsrechner/services";

import FinanzierungDetailliertNationalBetragsrechner from "./betragsrechner.js";
import FinanzierungDetailliertComponent from "./component";

const FinanzierungDetailliertNationalBetragsrechnerModul = angular.module("dias.betragsrechner.FinanzierungDetailliertNationalBetragsrechnerModul", [
  BetragsrechnerRegistry.name,
]);

FinanzierungDetailliertNationalBetragsrechnerModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("finanzierung_detailliert_national_betragsrechner", FinanzierungDetailliertNationalBetragsrechner);
})
  .component("finanzierungdetailliertnational", FinanzierungDetailliertComponent);

export default FinanzierungDetailliertNationalBetragsrechnerModul;
