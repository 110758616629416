import PuxPageDetails from "../../../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import SmartTable from "../../../../../global/components/table/table.vue";

import getTableApi from "../../../../../const/TableApis";

const TABLE_ID = "BerichtAkkreditierungsantraege";

// @vue/component
export default {
  name: "BerichtAkkreditierungsantraege",
  components: {
    PuxPageDetails,
    SmartTable,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        label: "_TXT_BERICHT_AKKREDITIERUNGSANTRAEGE_LABEL_",
        sortable: true,
        pagination: true,
        details: false,
        showHeader: false,
        view: {
          filter: "top",
        },
      },
    };
  },
};
