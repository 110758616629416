import AbwesenheitenList from "../../Nutzer/NutzerDetails/PanelComponents/AbwesenheitenList/AbwesenheitenList.vue";
import AsideRightBox from "../../../global/components/AsideRightBox/AsideRightBox.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import EMailAdresse from "../../Nutzer/NutzerDetails/PanelComponents/EMailAdresse/EMailAdresse.vue";
import ProfilDetailsDatenschutz from "./ProfilDetailsDatenschutz/ProfilDetailsDatenschutz.vue";
import ProfilDetailsDokumente from "./ProfilDetailsDokumente/ProfilDetailsDokumente.vue";
import ProfilDetailsKontakt from "./ProfilDetailsKontakt/ProfilDetailsKontakt.vue";
import ProfilDetailsNachrichten from "./ProfilDetailsNachrichten/ProfilDetailsNachrichten.vue";
import ProfilDetailsName from "./ProfilDetailsName/ProfilDetailsName.vue";
import ProfilDetailsOrganisationenList from "./ProfilDetailsOrganisationenList/ProfilDetailsOrganisationenList.vue";
import ProfilDetailsPassword from "./ProfilDetailsPassword/ProfilDetailsPassword.vue";
import ProfilDetailsPersonal from "./ProfilDetailsPersonal/ProfilDetailsPersonal.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import VertretungenList from "../../Vertretungsberechtigte/VertretungenList/VertretungenList.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NutzerMixin from "../../../mixins/NutzerMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import {
  assign
} from "lodash-es";


// @vue/component
export default {
  name: "ProfilDetails",
  components: {
    AbwesenheitenList,
    AsideRightBox,
    ContextPanel,
    EMailAdresse,
    ProfilDetailsDatenschutz,
    ProfilDetailsDokumente,
    ProfilDetailsKontakt,
    ProfilDetailsNachrichten,
    ProfilDetailsName,
    ProfilDetailsOrganisationenList,
    ProfilDetailsPassword,
    ProfilDetailsPersonal,
    PuxPageDetails,
    PuxTranslate,
    VertretungenList,
  },
  mixins: [
    HttpMixin,
    NutzerMixin,
    PermissionMixin,
  ],
  data() {
    return {
      loading: true,
      reloading: false,
      nutzer: undefined,
      panelPermissions: {
        anmeldedaten: {
          update: false
        },
      },
      optionsAsideBox: {
        name: "_TXT_PROFIL_DETAILS_ASIDE_HEADER_",
        active: true,
        icon: "glyphicon-info-sign",
      },
    };
  },
  computed: {
    canViewPersonal() {
      return this.checkPermissionsSync({
        perm: "personal.profil.view",
        permArray: this.nutzer.permissions
      });
    },
  },
  created() {
    this.loadProfil();
  },
  methods: {
    loadProfil({ reload } = {}) {
      this.reloading = reload;
      this.getHttp({
        url: "profil/",
      }).then(
        response => {
          this.nutzer = response;
          this.setPermissions();
        }
      ).then(
        () => {
          this.loading = false;
          this.reloading = false;
        }
      );
    },

    canAccountUpdate() {
      return this.checkPermissionsSync({ perm: "profil.update" });
    },

    setPermissions() {
      this.panelPermissions.anmeldedaten.update = this.canAccountUpdate();
    },

    updateNutzer({ nutzer }) {
      this.nutzer = assign(this.nutzer, nutzer);
    },
  },
};
