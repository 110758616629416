import PuxTab from "./PuxTab/PuxTab.vue";
import PuxTabContent from "./PuxTabContent/PuxTabContent.vue";

import {
  forEach,
  isNil,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "PuxTabs",
  components: {
    PuxTab,
    PuxTabContent,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    statusHideContent: {
      type: Boolean,
      required: false,
      info: "Status, wenn man Tab-Inhalt nicht in 'slot' oder in 'item.content' benutzt",
    },
    idForActiveTab: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    isChangeOutside: {
      type: Boolean,
      required: false,
    },
    change: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      idForActiveTabLocal: undefined,
    };
  },
  watch: {
    idForActiveTab() {
      this.idForActiveTabLocal = this.idForActiveTab;
    },
  },
  created() {
    this.initTabActiveId();
  },
  methods: {
    initTabActiveId() {
      if (!isNil(this.idForActiveTab)) {
        this.idForActiveTabLocal = this.idForActiveTab;
      }

      forEach(this.list, item => {
        if (item.active) {
          this.idForActiveTabLocal = item.id;
          return false;
        }
      });
      if (isUndefined(this.idForActiveTabLocal)) {
        this.idForActiveTabLocal = this.list[0].id;
      }
    },

    onChangeTab(event, item) {
      if (this.idForActiveTabLocal === item.id) {
        return;
      }
      if (!this.isChangeOutside) {
        this.idForActiveTabLocal = item.id;
      }
      this.change({ event, tab: item });
    },
  },
};
