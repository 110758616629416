import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import getTableApi from "../../../const/TableApis";
import { gettext } from "../../../global/functions/utils";

const TABLE_ID = "rollen";

// @vue/component
export default {
  name: "RollenList",
  components: {
    AngularLink,
    PageTabTitle,
    PuxIcon,
    PuxTranslate,
    SmartTable,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [
          {
            label: "_LBL_ROLLEN_LIST_BEZEICHNUNG_",
            id: "kbez",
            sortable: true,
            sortId: "kbez",
            slot: "kbez",
          },
          {
            label: "_LBL_ROLLEN_LIST_KONTEXT_",
            id: "kontexttyp.kbez",
          },
          {
            label: "_LBL_ROLLEN_LIST_AKTIV_",
            id: "aktiv",
            templateCallback: ({ row }) => `<i class="glyphicon glyphicon-${ row.aktiv ? "check" : "unchecked" }">`,
            sortable: true,
            sortId: "aktiv",
          },
        ],
        rowActions: [
          {
            label: "_LBL_ROLLEN_LIST_ACTIONS_ROW_DETAILS_",
            route: "root.rollenverwaltung.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        label: "_LBL_ROLLEN_LIST_ROLLEN_",
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
        },
      },
      filters: [
        {
          type: "text",
          id: "kbez",
          label: "_LBL_ROLLEN_LIST_BEZEICHNUNG_",
          main: true,
        },
        {
          type: "select",
          id: "kontexttyp",
          url: "katalog/?key=rollenkontexte&fields=pk&fields=bez",
          label: gettext("_LBL_ROLLEN_LIST_KONTEXT_"),
          keyLabel: "bez",
          keyId: "pk",
          search: true,
          searchList: ["bez"],
        },
        {
          type: "boolean",
          id: "aktiv",
          label: gettext("_LBL_ROLLEN_LIST_AKTIV_"),
          bool: true,
          trueValue: gettext("_LBL_ROLLEN_LIST_TRUE_"),
          falseValue: gettext("_LBL_ROLLEN_LIST_FALSE_"),
          defaultLabel: "_LBL_ROLLEN_LIST_NICHT_AUSGEWAEHLT_",
        }
      ],
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },
  },
};
