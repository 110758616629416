"use strict";

import BaseModel from "dias/core/models/baseModel";


class FoerderprogrammFoerderangebote extends BaseModel {
  constructor() {
    super("angebote");
  }
}

export default FoerderprogrammFoerderangebote;
