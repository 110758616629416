"use strict";
import angular from "angular";

import coreModels from "dias/core/models";
import CSVDownload from "./csv.service";

const modul = angular.module("dias.csv.models", [
  "restangular",
  coreModels.name,
])

  .provider("CsvDownload", CSVDownload)
;


export default modul;
