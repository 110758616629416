"use strict";

import "dias/antragsteller/models";


class NutzerverwaltungAddNutzerTempController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, KKommtyp) {
    this.loading = true;

    this.DiasNotification = DiasNotification;
    this.KKommtyp = KKommtyp;

    this.komTypsObj = {};
    this.model = {};
    this.kommdatenModel = {};
    this.org = $scope.extras;
    this.errorsLabel = {
      fas_name: "Name: ",
      fas_funktion: "Funktion: ",
      fas_pos: "Reihenfolge: "
    };

    this.init();


    this.saveNutzer = () => {
      this.prepareModelForSave();
      const deferred = $q.defer();

      this.org.customPOST(this.model, "ansprechpartner").then(
        () => {
          DiasNotification.page.success("Ansprechpartner angelegt.");
          deferred.resolve();
        },
        error => {
          deferred.reject();
          this.onError(error);
        }
      );
      return deferred.promise;
    };

    this.onError = error => {
      this.DiasNotification.form.clear();
      this.DiasNotification.form.error(
        error.data,
        "Bitte überprüfen Sie Ihre Eingaben", 
        {}, 
        this.errorsLabel
      );
      this.errors = error.data;
    };
  }

  init() {
    this.KKommtyp.getList().then(
      response => {
        this.komTyps = response;
        angular.forEach(this.komTyps, komTyp => {
          this.komTypsObj[komTyp.kot_id] = {
            kot_id: komTyp.kot_id,
            kot_kbez: komTyp.kot_kbez,
            kot_bez: komTyp.kot_bez,
            kot_pos: komTyp.kot_pos,
            kot_pflicht: komTyp.kot_pflicht
          };
        });
        this.loading = false;
      }
    );
  }

  prepareModelForSave() {
    this.model.kommunikationsdaten = [];

    angular.forEach(this.kommdatenModel, (komModel, key) => {
      if (komModel) {
        this.model.kommunikationsdaten.push({
          fak_wert: komModel,
          typ: this.komTypsObj[key],
	  typ_id: key,
        });
      }
    });
  }
}

export default NutzerverwaltungAddNutzerTempController;
