"use strict";

import BaseModel from "dias/core/models/baseModel";

class KStatusfolge extends BaseModel {
  constructor() {
    super("statusfolgen");
  }

  extendModel(model) {
    model = super.extendModel(model);

    model.hasTransition = this.hasTransition.bind(model);

    return model;
  }

  hasTransition(statusId) {
    return this.sf_uebergang[statusId] && (this.sf_uebergang[statusId].vor.length > 0 || this.sf_uebergang[statusId].zurueck.length > 0);
  }
}

export default KStatusfolge;
