"use strict";

class WorkflowfunktionRegistryProvider {
  constructor() {
    this.registeredFunctions = {};
  }

  register(key, cls) {
    if (this.registeredFunctions[key] !== undefined) {
      console.warn("Workflowfunktion " + key + " wurde mehrmals registriert:", [this.registeredFunctions[key], cls]);
      return;
    }
    this.registeredFunctions[key] = cls;
  }

  unregister(key) {
    delete this.registeredFunctions[key];
    console.info("Workflowfunktion " + key + " deregistriert.");
  }

  /*@ngInject*/
  $get($injector) {
    return {
      get: (obj, workflowfolge, extra) => {
        const key = workflowfolge.getClientKey();
        if (!this.registeredFunctions[key]) {
          console.error("Workflowfunktion " + key + " unbekannt");
          return;
        }
        return $injector.instantiate(this.registeredFunctions[key], {
          workflowfolge: workflowfolge,
          obj: obj,
          extra: extra,
        });
      }
    };
  }
}

export default WorkflowfunktionRegistryProvider;
