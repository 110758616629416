import ModalCreateOrUpdate from "./ModalCreateOrUpdate/ModalCreateOrUpdate.vue";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxCloak from "../../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import ShowMore from "../../../../../global/components/ui/ShowMore/ShowMore.vue";
import SmartTable from "../../../../../global/components/table/table.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../../../global/mixins/PermissionMixin";
import PersonalPanelComponentMixin from "../PersonalPanelComponentMixin";
import { FilterDateMixin } from "../../../../../global/mixins/FiltersMixin";

import getTableApi from "../../../../../const/TableApis";

import { EventBus } from "../../../../../global/functions/event-bus";

import moment from "moment";
import {
  assign,
  isNil,
  isString,
  join,
  map,
  size,
} from "lodash";

const TABLE_ID = "KompetenzenListInPersonal";

// @vue/component
export default {
  name: "Kompetenzen",
  components: {
    ModalCreateOrUpdate,
    Modal,
    PuxCloak,
    PuxIcon,
    ShowMore,
    SmartTable,
  },
  mixins: [
    FilterDateMixin,
    HttpMixin,
    PermissionMixin,
    PersonalPanelComponentMixin,
  ],
  data: () => {
    return {
      loading: true,
      objekttypen: undefined,
      modal: {
        create: undefined,
        update: undefined,
      },
      confirmOptions: undefined,
    };
  },
  computed: {
    options() {
      return assign({}, this.optionsMixin, {
        label: "_LBL_PERSONAL_DETAIL_KOMPETENZEN_PANEL_",
        editable: false,
      });
    },

    kompetenzenTableOptions() {
      const OPTIONS = {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID, params: { personalPk: this.model.pk } }),
        cols: [
          {
            label: "_LBL_PERSONAL_KOMPETENZ_AKTIV_",
            id: "aktiv",
            className: "table_smart__table__col_icon_50px",
            slot: "aktiv",
            sortable: true,
            sortId: "_is_active",
          },
          {
            label: "_LBL_FOERDERANGEBOT_PLURAL_",
            id: "angebote",
            slot: "angebote"
          },
          {
            label: "_LBL_PERSONAL_KOMPETENZ_GUELTIG_AB_",
            id: "pk_gueltig_ab",
            sortable: true,
            sortingDefault: "desc",
            filter: "date",
            titleCallback: ({ item }) => this.filterDate(item.pk_gueltig_ab, "DD.MM.YY HH:mm [Uhr]")
          },
          {
            label: "_LBL_PERSONAL_KOMPETENZ_GUELTIG_BIS_",
            id: "pk_gueltig_bis",
            sortable: true,
            filter: "date",
            titleCallback: ({ item }) => this.filterDate(item.pk_gueltig_bis, "DD.MM.YY HH:mm [Uhr]")
          },
          {
            label: "_LBL_PERSONAL_KOMPETENZ_ANMERKUNG_",
            id: "pk_anmerkung",
            slot: "more",
          },
        ],
        rowActions: [
          {
            label: "_BTN_EDIT_",
            isHidden: () => !this.canUpdate,
            callback: ({ item, index }) => this.openModalUpdate({ item, index }),
          }, {
            label: "_BTN_DEACTIVATE_",
            isHidden: ({ item }) => !item.aktiv || !this.canDeactivate,
            callback: ({ item, index }) => this.openModalDeactivate({ item, index }),
          }, {
            label: "_BTN_DELETE_",
            isHidden: ({ item }) => this.isPast(item.pk_gueltig_ab) || !this.canDelete,
            callback: ({ item, index }) => this.openModalDelete({ item, index }),
          },
        ],
        actions: [
          {
            label: "_BTN_PERSONAL_KOMPETENZ_CREATE_",
            callback: () => this.openModalCreate(),
            isHidden: () => !this.canCreate,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "personal_kompetenz_create",
          },
        ],
        label: "_LBL_PERSONAL_DETAIL_KOMPETENZEN_TABLE_",
        sortable: true,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
        csvHide: true,
      };
      if (size(this.objekttypen) > 1) {
        OPTIONS.cols.splice(1, 0, {
          label: "_LBL_PERSONAL_KOMPETENZ_CONTENT_TYPE_",
          id: "content_type_bez",
          sortable: false,
        });
      }
      return OPTIONS;
    },

    canCreate() {
      return this.checkPermissionsSync({
        perm: "personal.kompetenzen.create",
        permArray: this.model.user_permissions
      });
    },

    canUpdate() {
      return this.checkPermissionsSync({
        perm: "personal.kompetenzen.update",
        permArray: this.model.user_permissions
      });
    },

    canDeactivate() {
      return this.checkPermissionsSync({
        perm: "personal.kompetenzen.deactivate",
        permArray: this.model.user_permissions
      });
    },

    canDelete() {
      return this.checkPermissionsSync({
        perm: "personal.kompetenzen.delete",
        permArray: this.model.user_permissions
      });
    },

    currentItemActionBtnId() {
      return `#${ TABLE_ID }_action_dropdown_${ this.currentIndex }`;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.getHttp({
        url: "katalog/",
        urlParams: {
          key: "personal_kompetenzen_organisationen_contenttypen",
          organisation: this.model.o_id
        },
        apiSaveId: `personal_kompetenzen_organisationen_contenttypen__${ this.model.o_id }`
      }).then(
        response => this.objekttypen = map(response, ook => {
          return {
            pk: ook.content_type,
            bez: ook.ct_name
          };
        })
      ).finally(() => this.loading = false);
    },

    isPast(datetime) {
      return moment().isSameOrAfter(datetime, "second");
    },

    getAktivIconProps({ aktiv }) {
      const ICON = {};
      if (aktiv) {
        ICON.icon = "glyphicon-ok";
        ICON.iconClass = "text-success";
        ICON.iconTooltip = "_TXT_JA_";
      } else {
        ICON.icon = "glyphicon-close";
        ICON.iconClass = "text-danger";
        ICON.iconTooltip = "_TXT_NEIN_";
      }
      return ICON;
    },

    getConfirmTranslationExtra() {
      const EXTRA = {
        name: this.model.name,
        o_name: this.model.o_name,
      };
      if (!isNil(this.currentItem)) {
        EXTRA.angebote_list = join(map(this.currentItem.angebote, foe => foe.foe_kbez), ", ");
        EXTRA.objekttyp = this.currentItem.content_type_bez || "_TXT_KEINE_ANGABE_";
      }
      return EXTRA;
    },

    openModalDeactivate({ item, index }) {
      this.currentItem = item;
      this.currentIndex = index;
      this.confirmOptions = {
        title: "_HTML_PERSONAL_KOMPETENZ_MODAL_DEACTIVATE_HEADER_{{name}}_{{o_name}}_{{angebote_list}}_{{objekttyp}}_",
        msg: "_HTML_PERSONAL_KOMPETENZ_MODAL_DEACTIVATE_BODY_{{name}}_{{o_name}}_{{angebote_list}}_{{objekttyp}}_",
        extra: this.getConfirmTranslationExtra(),
        okLabel: "_BTN_DEACTIVATE_",
        okClass: "btn btn-danger",
        okCallback: this.doDeactivate,
        cancelCallback: this.closeModalDeactivate,
        loading: false
      };
    },

    doDeactivate() {
      this.confirmOptions.loading = true;
      return this.postHttp({
        url: `personal/${ this.model.pk }/kompetenzen/${ this.currentItem.pk }/deactivate/`,
      }).then(
        response => {
          this.addNotification({ text: "_MSG_PERSONAL_KOMPETENZ_DEACTIVATE_SUCCESS_" });
          this.closeModalDeactivate({ response });
        },
        error => {
          let msg = "_ERR_PERSONAL_KOMPETENZ_DEACTIVATE_";
          this.confirmOptions.loading = false;
          if (isString(error)) {
            msg = error;
          }
          this.addNotification({ text: msg, type: "error" });
        }
      );
    },

    closeModalDeactivate({ response } = {}) {
      this.confirmOptions = undefined;
      this.updateRow({ response });
    },

    openModalDelete({ item, index }) {
      this.currentItem = item;
      this.currentIndex = index;
      this.confirmOptions = {
        title: "_HTML_PERSONAL_KOMPETENZ_MODAL_DELETE_HEADER_{{name}}_{{o_name}}_{{angebote_list}}_{{objekttyp}}_",
        msg: "_HTML_PERSONAL_KOMPETENZ_MODAL_DELETE_BODY_{{name}}_{{o_name}}_{{angebote_list}}_{{objekttyp}}_",
        extra: this.getConfirmTranslationExtra(),
        okLabel: "_BTN_DELETE_",
        okClass: "btn btn-danger",
        okCallback: this.doDelete,
        cancelCallback: this.closeModalDelete,
        loading: false
      };
    },

    doDelete() {
      this.confirmOptions.loading = true;
      return this.deleteHttp({
        url: `personal/${ this.model.pk }/kompetenzen/${ this.currentItem.pk }/`,
      }).then(
        () => {
          this.addNotification({ text: "_MSG_PERSONAL_KOMPETENZ_DELETE_SUCCESS_" });
          this.closeModalDelete({ response: true });
        },
        error => {
          let msg = "_ERR_PERSONAL_KOMPETENZ_DELETE_";
          if (isString(error.data)) {
            msg = error.data;
          }
          this.addNotification({ text: msg, type: "error" });
          this.confirmOptions.loading = false;
        }
      );
    },

    closeModalDelete({ response } = {}) {
      this.modal.delete = false;
      this.confirmOptions = undefined;
      this.updateTable({ response });
    },

    openModalUpdate({ item, index }) {
      this.currentItem = item;
      this.currentIndex = index;
      this.modal.update = true;
    },

    closeModalUpdate({ response } = {}) {
      this.modal.update = false;
      this.updateRow({ response });
    },

    openModalCreate() {
      this.modal.create = true;
    },

    closeModalCreate({ response } = {}) {
      this.modal.create = false;
      this.updateTable({ response });
    },

    updateTable({ response } = {}) {
      if (response) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
    },

    updateRow({ response } = {}) {
      if (response) {
        const row = assign({}, this.currentItem, response);
        EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row, index: this.currentIndex }));
      }
    },
  }
};
