"use strict";

import parameterServices from "../services";
import template from "./config.jade";
import controller from "./controller";


const parametermoduleDecimal = angular.module("dias.foerderregeln.parameter.decimal", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("decimal", "parametermodul-decimal");
  })
  .component("parametermodulDecimal", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulDecimalRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: "{{ vm.parameter[vm.key].wert | number:2 }}",
    controllerAs: "vm",
  });
export default parametermoduleDecimal;
