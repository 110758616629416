"use strict";

import BaseModel from "dias/core/models/baseModel";
import Dokumente from "dias/dokumente/models/dokumente.service";
import Preview from "dias/dokumente/models/preview.service";


class Sitzungtagesordnung extends BaseModel {
  constructor() {
    super("sitzungtagesordnungen", {
      subPaths: {
        dokumente: new Dokumente(),
        preview: new Preview()
      }
    });
  }
}

export default Sitzungtagesordnung;
