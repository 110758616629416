"use strict";

class DynamischeZahlenController {
  /*@ngInject*/
  constructor(DiasNotification) {
    this.DiasNotification = DiasNotification;

    this.einheiten = ["%", "€", "km", "Fahrzeuge", "Personen"];
    if (!this.parameter[this.key].wert) {
      this.parameter[this.key].wert = [];
    }
    this.initialModel();
    this.initConfig();
  }

  addAntwort() {
    if (!this.parameter[this.key].wert) {
      this.parameter[this.key].wert = [];
    }
    this.currentAntwort.pos = (this.parameter[this.key].wert.length + 1) + "";

    this.parameter[this.key].wert.push(this.currentAntwort);
    this.initialModel();
  }

  removeAntwort(index) {
    this.parameter[this.key].wert.splice(index, 1);
    _.forEach(this.parameter[this.key].wert, (v, idx) => v.pos = (idx + 1) + "");
  }

  moveUpAntwort(index) {
    if (index === 0) {
      return;
    }
    const elem = this.parameter[this.key].wert[index];
    elem.pos--;
    const previous = this.parameter[this.key].wert[index - 1];
    previous.pos++;
    this.parameter[this.key].wert[index - 1] = elem;
    this.parameter[this.key].wert[index - 1].pos += "";
    this.parameter[this.key].wert[index] = previous;
    this.parameter[this.key].wert[index].pos += "";
  }

  moveDownAntwort(index) {
    if (index >= this.parameter[this.key].wert.length - 1) {
      return;
    }
    const elem = this.parameter[this.key].wert[index];
    elem.pos++;
    const next = this.parameter[this.key].wert[index + 1];
    next.pos--;
    this.parameter[this.key].wert[index + 1] = elem;
    this.parameter[this.key].wert[index + 1].pos += "";
    this.parameter[this.key].wert[index] = next;
    this.parameter[this.key].wert[index].pos += "";
  }

  initialModel() {
    this.currentAntwort = {
      pos: this.parameter[this.key].wert.length + 1,
      value: null,
      min: null,
      max: null,
      expression: null,
      signal_regex: null,
      nachkommastellen: null,
      label: null,
      id: null,
      einheit: null,
      hilfetext: null,
      placeholder: null,
      pflichtfeld: true
    };
  }

  initConfig() {
    _.forEach(this.parameter[this.key].wert, conf => {
      _.assignInWith(conf, this.currentAntwort, (objVal, srcVal) => _.isUndefined(objVal) ? srcVal : objVal);
    });
  }

  changeLabel({ model }) {
    if (!model._statusChangeId) {
      model.id = _.toLower(model.label).replace(/[^a-zA-Z0-9]/g, "");
    }
  }

  changeId({ model }) {
    model.id = _.toLower(model.id).replace(/[^a-zA-Z0-9]/g, "");
    model._statusChangeId = true;
  }
}

export default DynamischeZahlenController;
