"use strict";

import template from "./details.jade";

import BasisformularDetailsController from "../basisformular/details.controller";


export default {
  template: template(),
  controller: BasisformularDetailsController,
  controllerAs: "vm"
};
