"use strict";

import BaseModel from "dias/core/models/baseModel";

class Foerderantragbenachrichtigung extends BaseModel {
  constructor() {
    super("benachrichtigungen");
  }
}

export default Foerderantragbenachrichtigung;
