import ModuleMixin from "../ModuleMixin";
import Modal from "../../../../global/components/Modal/Modal.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import AstVertretungsberechtigteModalCreateOrUpdate
  from "./AstVertretungsberechtigteModalCreateOrUpdate/AstVertretungsberechtigteModalCreateOrUpdate.vue";
import AstVertretungsberechtigteTr from "./AstVertretungsberechtigteTr/AstVertretungsberechtigteTr.vue";

import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import {
  cloneDeep,
  forEach,
  get,
  keyBy,
  values,
} from "lodash-es";

const SNAPSHOT_CHANGES_LIST = [
  "anrede",
  "av_email",
  "av_funktion",
  "av_gueltig_von",
  "av_gueltig_bis",
  "av_name",
  "av_vorname",
  "berechtigung",
  "titel",
  "av_titel",
];

// @vue/component
export default {
  name: "AstVertretungsberechtigte",
  components: {
    Modal,
    Permission,
    PuxButton,
    PuxTranslate,
    AstVertretungsberechtigteModalCreateOrUpdate,
    AstVertretungsberechtigteTr,
  },
  mixins: [
    ModuleMixin,
    SyConfigMixin,
  ],
  data() {
    return {
      currentVertreter: undefined,
      statusModalCreateOrUpdate: undefined,
      statusModalDelete: undefined,
      confirmDeleteOptions: undefined,
    };
  },
  computed: {
    labelHeader() {
      if (this.modelParameter.frage_text) {
        return this.modelParameter.frage_text;
      }
      return "_TXT_GR_AST_VERTRETUNGSBERECHTIGTE_HEADER_";
    },

    vertreterLocal() {
      return get(this.model, "vertretungsberechtigte", []);
    },

    vertreterConfig() {
      return this.getSyConfigsValue("vertretungsberechtigte") || {};
    },

    snapshotFiltered() {
      const SNAPSHOT = {
        add: {},
        delete: [],
        changes: {},
        changesDiff: {},
        status: false,
      };
      if (this.statusSnapshot) {
        const SNAPSHOT_VERTRETER_OBJ = cloneDeep(this.snapshotVertreterObj);
        forEach(this.vertreterLocal, item => {
          const CURRENT_PK = item.pk;
          const CURRENT_SNAPSHOT = cloneDeep(SNAPSHOT_VERTRETER_OBJ[CURRENT_PK]);
          if (!CURRENT_SNAPSHOT) {
            SNAPSHOT.add[CURRENT_PK] = true;
            SNAPSHOT.status = true;
          } else {
            forEach(SNAPSHOT_CHANGES_LIST, key => {
              const VALUE_IN_MODEL = item[key];
              const VALUE_IN_SNAPSHOT = CURRENT_SNAPSHOT[key];
              if (VALUE_IN_MODEL !== VALUE_IN_SNAPSHOT) {
                SNAPSHOT.changes[CURRENT_PK] = CURRENT_SNAPSHOT;
                SNAPSHOT.changesDiff[CURRENT_PK] = SNAPSHOT.changesDiff[CURRENT_PK] || {};
                SNAPSHOT.changesDiff[CURRENT_PK][key] = true;
                SNAPSHOT.status = true;
              }
            });
            delete SNAPSHOT_VERTRETER_OBJ[CURRENT_PK];
          }
        });
        SNAPSHOT.delete = values(SNAPSHOT_VERTRETER_OBJ);
        if (SNAPSHOT.delete.length) {
          SNAPSHOT.status = true;
        }
      }
      return SNAPSHOT;
    },

    snapshotVertreterObj() {
      if (!get(this.snapshotModule, "vertretungsberechtigte")) {
        return {};
      }
      return keyBy(this.snapshotModule.vertretungsberechtigte, "pk");
    },

    statusDiff() {
      return this.snapshotFiltered.status;
    },

    statusNoData() {
      return !(this.snapshotFiltered.delete.length ||
        this.vertreterLocal.length);
    },

    permissions() {
      return this.obj.user_permissions;
    },

    isModuleEditable() {
      return false;
    },
  },
  methods: {
    openModalCreateOrUpdate({ av } = {}) {
      if (av) {
        this.currentVertreter = av;
        this.selectorClose = `#${ this.htmlRegelNummer }_edit_${ av.pk }`;
      } else {
        this.selectorClose = `#${ this.htmlRegelNummer }_add`;
      }
      this.statusModalCreateOrUpdate = true;
    },

    closeModalCreateOrUpdate() {
      this.currentVertreter = undefined;
      this.statusModalCreateOrUpdate = false;
    },

    openModalDelete({ av, index }) {
      this.selectorClose = [
        `#${ this.htmlRegelNummer }_edit_${ av.pk }`,
        this.selectorHtmlRegelNummer,
      ];
      this.confirmDeleteOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_GR_AST_VERTRETUNGSBERECHTIGTE_MODAL_DELETE_HEADER_",
        msg: "_HTML_GR_VERTRETUNGSBERECHTIGTE_MODAL_DELETE_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteVertreter({ index }),
        cancelCallback: this.closeModalDelete,
        loading: false,
      };
      this.statusModalDelete = true;
    },

    closeModalDelete() {
      this.currentVertreter = undefined;
      this.statusModalDelete = false;
    },

    deleteVertreter({ index }) {
      this.confirmDeleteOptions.loading = true;
      const LIST = cloneDeep(this.model.vertretungsberechtigte);
      LIST.splice(index, 1);

      this.save({
        model: { vertretungsberechtigte: LIST },
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_GR_AST_VERTRETUNGSBERECHTIGTE_MODAL_DELETE_SUCCESS_",
          });
          this.closeModalDelete();
        },
        () => {
          this.addNotification({
            text: "_MSG_GR_AST_VERTRETUNGSBERECHTIGTE_MODAL_DELETE_ERROR_",
            type: "error",
          });
        }
      ).then(
        () => this.confirmDeleteOptions.loading = false
      );
    },
  },
};
