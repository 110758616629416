import {
  FilterCurrencyMixin,
} from "../../../../../global/mixins/FiltersMixin";
import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "FooterTd",
  mixins: [
    FilterCurrencyMixin,
  ],
  props: {
    positionenPeriodenObj: {
      type: Object,
      required: true,
    },
    periodPk: {
      type: String,
      required: true,
    },
  },
  computed: {
    sumFooter() {
      let sum = 0;
      forEach(this.positionenPeriodenObj, pos => {
        if (pos[this.periodPk]) {
          forEach(pos[this.periodPk], period => {
            sum += +period.gehalt;
          });
        }
      });
      return sum;
    },
  },
};
