"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";
import template from "dias/foerderregeln/parameter/json/config.jade";
import templateRo from "dias/foerderregeln/parameter/json/readonly.jade";
import controller from "dias/foerderregeln/parameter/json/controller";


const ampel = angular.module("dias.standard_foerdermodule.kennzahlen.parameter", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("kennzahlen_kontext", "parametermodul-kennzahlen-kontext");
    ParametermoduleRegistryProvider.register("kennzahlen_berechnung", "parametermodul-kennzahlen-berechnung");
  })
  .component("parametermodulKennzahlenKontext", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })
  .component("parametermodulKennzahlenKontextRo", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: templateRo(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulKennzahlenBerechnung", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })
  .component("parametermodulKennzahlenBerechnungRo", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: templateRo(),
    controller: controller,
    controllerAs: "vm",
  })
;
export default ampel;
