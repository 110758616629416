import AuszahlungListDetails from "../AuszahlungListDetails/AuszahlungListDetails.vue";
import AuszahlungModalCreate from "./AuszahlungModalCreate/AuszahlungModalCreate.vue";
import NutzerschlagwortBadge from "../../Nutzerschlagwort/NutzerschlagwortBadge/NutzerschlagwortBadge.vue";
import NutzerschlagwortObjektModalAdd from "../../Nutzerschlagwort/NutzerschlagwortObjektModalAdd/NutzerschlagwortObjektModalAdd.vue";
import NutzerschlagwortObjektModalEdit from "../../Nutzerschlagwort/NutzerschlagwortObjektModalEdit/NutzerschlagwortObjektModalEdit.vue";
import NutzerschlagwortObjektModalRemove from "../../Nutzerschlagwort/NutzerschlagwortObjektModalRemove/NutzerschlagwortObjektModalRemove.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import DynamicRootForComponentsMixin from "../../DynamicRoot/DynamicRootForComponentsMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import { render_ffk_columns } from "../../../global/components/table/TableColsUndFiltersFromSyConfig";

import { EventBus } from "../../../global/functions/event-bus";

import { size } from "lodash-es";

const TABLE_ID = "auszahlung";

// @vue/component
export default {
  name: "AuszahlungList",
  components: {
    AuszahlungListDetails,
    AuszahlungModalCreate,
    NutzerschlagwortBadge,
    NutzerschlagwortObjektModalAdd,
    NutzerschlagwortObjektModalEdit,
    NutzerschlagwortObjektModalRemove,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    DynamicRootForComponentsMixin,
    PermissionMixin,
    DynamicRootForComponentsMixin,
  ],
  props: {
    header: {
      type: String,
      required: false,
      default: "_TXT_AUSZAHLUNG_TABLE_HEADER_",
      info: "Text für Tabelle-Header",
    },
    filter: {
      type: Object,
      required: false,
      default: undefined,
      info: "Zusätzliches Get-Parameter für API",
    },
  },
  setup() {
    const {
      filterLimitTo,
    } = FiltersAPI();

    return {
      filterLimitTo,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: this.initUrl({ tableId: TABLE_ID, filter: this.filter }),
        baseUrl: this.initUrl({ tableId: TABLE_ID, filter: undefined }),
        rowActions: [
          {
            label: "_TXT_AUSZAHLUNG_TABLE_ROW_ACTIONS_DETAILS_",
            route: "root.vertrag.details.auszahlung",
            routeParameters: [
              { routeId: "id", rowPath: "vertrag" },
              { routeId: "aus_id", rowPath: "pk" },
            ],
          },
          {
            label: "_TXT_AUSZAHLUNG_TABLE_NUTZERSCHLAGWORTOBJEKT_UPDATE_BTN_",
            callback: this.openModalNutzerschlagwortobjektEdit,
            isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          },
        ],
        massActions: [],
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
          details: "right",
        },
        actions: [
          {
            permission: "auszahlung.create",
            label: "_TXT_AUSZAHLUNG_TABLE_CREATE_BTN_",
            callback: () => this.openModalCreateAuszahlung(),
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            isDisabled: false,
            htmlId: "auszahlung_create",
          }
        ],
      },
      render_ffk_columns: render_ffk_columns,
      modal: {
        create: undefined,
        nutzerschlagwortobjektEdit: undefined,
        nutzerschlagwortobjektMassActionAdd: undefined,
        nutzerschlagwortobjektMassActionRemove: undefined,
      },
      currentAuszahlung: undefined,
      selectedRows: undefined,
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },

    getSlotNameTableDetailsHeader() {
      return `${ this.options.id }_table_details_header`;
    },

    nutzerschlagwortModalEditTitleTranslation() {
      return {
        text: "_TXT_AUSZAHLUNGLISTE_NUTZERSCHLAGWORT_MODAL_EDIT_TITLE_"
      };
    },

    nutzerschlagwortModalEditMsgTranslation() {
      return {
        html: "_HTML_AUSZAHLUNGLISTE_NUTZERSCHLAGWORT_MODAL_EDIT_INFO_"
      };
    },

    nutzerschlagwortModalMassActionAddTitleTranslation() {
      return {
        text: "_TXT_AUSZAHLUNGLISTE_NUTZERSCHLAGWORT_MODAL_MANY_ADD_TITLE_"
      };
    },

    nutzerschlagwortModalMassActionAddMsgTranslation() {
      return {
        html: "_HTML_AUSZAHLUNGLISTE_NUTZERSCHLAGWORT_MODAL_MANY_ADD_INFO_{{anz}}_",
        extra: {
          anz: size(this.selectedRows) || 0,
        }
      };
    },

    nutzerschlagwortModalMassActionRemoveTitleTranslation() {
      return {
        text: "_TXT_AUSZAHLUNGLISTE_NUTZERSCHLAGWORT_MODAL_MANY_REMOVE_TITLE_"
      };
    },

    nutzerschlagwortModalMassActionRemoveMsgTranslation() {
      return {
        html: "_HTML_AUSZAHLUNGLISTE_NUTZERSCHLAGWORT_MODAL_MANY_REMOVE_INFO_{{anz}}_",
        extra: {
          anz: size(this.selectedRows) || 0,
        }
      };
    },
  },
  created() {
    this.initRestFunctions();
  },
  methods: {
    initMassActions() {
      if (this.checkPermissionsSync({ perm: "nutzerschlagworte.update" })) {
        this.options.massActions.push({
          label: "_TXT_AUSZAHLUNG_TABLE_MASS_ACTIONS_NUTZERSCHLAGWORT_ADD_BTN_",
          callback: ({ rows }) => this.openModalNutzerschlagwortobjektMassActionAdd({ rows }),
          isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          modal: true,
        });
        this.options.massActions.push({
          label: "_TXT_AUSZAHLUNG_TABLE_MASS_ACTIONS_NUTZERSCHLAGWORT_REMOVE_BTN_",
          callback: ({ rows }) => this.openModalNutzerschlagwortobjektMassActionRemove({ rows }),
          isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          modal: true,
        });
      }
    },

    initRestFunctions() {
      this.initMassActions();
    },

    closeModalNutzerschlagwortobjektEdit({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektEdit = false;
    },

    openModalNutzerschlagwortobjektEdit({ item }) {
      this.currentAuszahlung = item;
      this.modal.nutzerschlagwortobjektEdit = true;
    },

    closeModalNutzerschlagwortobjektMassActionAdd({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektMassActionAdd = false;
      EventBus.$emit(`cancelMassActions${ this.tableId }`, { status });
    },

    openModalNutzerschlagwortobjektMassActionAdd({ rows }) {
      this.selectedRows = rows;
      this.modal.nutzerschlagwortobjektMassActionAdd = true;
    },

    closeModalNutzerschlagwortobjektMassActionRemove({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektMassActionRemove = false;
      EventBus.$emit(`cancelMassActions${ this.tableId }`, { status });
    },

    openModalNutzerschlagwortobjektMassActionRemove({ rows }) {
      this.selectedRows = rows;
      this.modal.nutzerschlagwortobjektMassActionRemove = true;
    },

    openModalCreateAuszahlung() {
      this.modal.create = true;
    },

    closeModalCreateAuszahlung() {
      this.modal.create = false;
    },

  },
};
