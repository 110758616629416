import DokumentDownload from "../../Dokumente/DokumentDownload/DokumentDownload.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "TableauTable";
const TYP_TABLEAU = "15934399-5356-49ef-857f-7d1eca80cbe9";
const TYP_TEMPLATE = "13e661d2-4663-48a3-954c-377e51f77319";

// @vue/component
export default {
  name: "BerichteList",
  components: {
    DokumentDownload,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_LBL_BERICHTE_TABLE_ROW_ACTIONS_DETAILS_",
            route: ({ item }) => item.typ === TYP_TABLEAU ? "root.tableau.detail" : "root.berichte.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
            isHidden: ({ item }) => item.typ === TYP_TEMPLATE,
          },
        ],
        actions: [],
        sortable: false,
        pagination: true,
        details: false,
        showColsConfig: false,
      },
    };
  },
  computed: {
    getHrefBerichte() {
      return row => `/${ row.typ === TYP_TABLEAU ? "tableau" : "berichte" }/${ row.pk }/`;
    },

    showDetailLink() {
      return row => row.typ !== TYP_TEMPLATE;
    },

    downloadLink() {
      return row => `tableau/${ row.pk }/render/`;
    },
  }
};
