import {
  computed,
  ref,
} from "vue";

import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

export default function AntragBusinessRulesAPI({ antrag }) {
  const {
    checkPermissionsSync,
  } = PermissionAPI();
  const hasWFPermissionFoerderantragViewBusinessrules = computed(() => {
    return checkPermissionsSync({
      perm: "foerderantrag.view_businessrules",
      permArray: antrag.value.user_permissions,
    });
  });
  const statusRules = ref(false);
  const buttonToggleRulesText = computed(() => {
    return statusRules.value ?
      "_BTN_ANTRAG_RULES_HIDE_" :
      "_BTN_ANTRAG_RULES_SHOW_";
  });
  const toggleRules = () => {
    statusRules.value = !statusRules.value;
  };

  return {
    buttonToggleRulesText,
    hasWFPermissionFoerderantragViewBusinessrules,
    statusRules,
    toggleRules,
  };
}
