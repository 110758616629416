"use strict";

import BetragsrechnerRegistry from "dias/kosten_finanzierung/betragsrechner/services";

import FinanzierungDetailliertBetragsrechner from "./betragsrechner.js";
import FinanzierungDetailliertComponent from "./component";

const FinanzierungDetailliertBetragsrechnerModul = angular.module("dias.betragsrechner.FinanzierungDetailliertBetragsrechnerModul", [
  BetragsrechnerRegistry.name,
]);

FinanzierungDetailliertBetragsrechnerModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("finanzierung_detailliert_betragsrechner", FinanzierungDetailliertBetragsrechner);
})
  .component("finanzierungdetailliert", FinanzierungDetailliertComponent);

export default FinanzierungDetailliertBetragsrechnerModul;
