"use strict";

import BaseModel from "dias/core/models/baseModel";

class Empfaenger extends BaseModel {
  constructor() {
    super("nachrichten_empfaenger");
  }
}

export default Empfaenger;
