"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";
import template from "dias/foerderregeln/parameter/json/config.jade";
import templateRo from "dias/foerderregeln/parameter/json/readonly.jade";
import controller from "dias/foerderregeln/parameter/json/controller";


const elementlisteRegeldata = angular.module("dias.elementliste.parameter.elementliste_regeldata", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("elementliste_regeldata", "parametermodul-elementliste-regeldata");
  })
  .component("parametermodulElementlisteRegeldata", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })
  .component("parametermodulElementlisteRegeldataRo", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: templateRo(),
    controller: controller,
    controllerAs: "vm",
  })
;

export default elementlisteRegeldata;
