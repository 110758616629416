import translate from "../../../directives/translate";

import UIComponentMixin from "../UIComponentMixin";
import UiInputClearButtonMixin from "../UiInputClearButtonMixin";
import UiInputMaxValueMixin from "../UiInputMaxValueMixin";
import UiInputMinValueMixin from "../UiInputMinValueMixin";

import GlobalOptions from "../../../const/GlobalOptions";

// @vue/component
export default {
  name: "UiInput",
  directives: {
    translate,
  },
  mixins: [
    UIComponentMixin,
    UiInputClearButtonMixin,
    UiInputMaxValueMixin,
    UiInputMinValueMixin,
  ],
  data() {
    return {
      UiInputIcon: GlobalOptions.UiInputIcon,
    };
  },
  computed: {
    translateOptionsPlaceholder() {
      if (this.options.placeholder) {
        return {
          placeholder: this.options.placeholder,
        };
      }
      return {};
    },

    maxlength() {
      return this.options.maxlength || this.options.max_length || this.options.max_digits;
    },

    minValue() {
      if (this.options.minValue === undefined && this.options.min_value === undefined) {
        // Standardverhalten wenn kein Wert gesetzt: keine negativen Werte (rückwärtskompabilität)
        return 0;
      }
      return this.options.minValue || this.options.min_value;
    },

    type() {
      if (this.options.type === "integer") {
        return "text";
      }
      return this.options.type;
    },
  },
  methods: {
    onInput($event) {
      let value = $event.target.value;
      if (this.options.type === "integer") {
        const negative = value[0] == "-";
        value = value.replace(/\D/g, "");
        if (negative) {
          value = "-" + value;
        }
        if (value !== "" && value !== "-") {
          value = +value;
          value = this.checkMaxValue({ value });
          value = this.checkMinValue({ value });
        }
        this.$refs.input.value = value;
      } else if (this.options.type === "regex_config") {
        if (value === "") {
          value = null;
        }
      }
      // else if (this.options.type === "decimal") { // ToDo: ILIA

      const VALUE_LOCAL = this.checkUndefinedValue({ value });
      if (VALUE_LOCAL === this.model) { // Workaround für ie11
        return;
      }
      this.input({
        currentModel: VALUE_LOCAL,
        model: VALUE_LOCAL,
        id: this.options.id,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
      this.change({
        currentModel: VALUE_LOCAL,
        model: VALUE_LOCAL,
        id: this.options.id,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
    },
  },
};
