import {
  computed,
  ref,
  toRef,
} from "vue";

import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "AntragStepsItem",
  components: {
    PuxButton,
  },
  props: {
    antragStep: {
      type: Object,
      required: true,
      info: "Antrag-Schritt aus SyConfig 'antrag_schritte'",
    },
    antragStepIndex: {
      type: Number,
      required: true,
      info: "Index in Array Antrag-Schritte",
    },
  },
  setup(props) {
    const isShow = ref(false);
    const toggleVisibility = () => {
      isShow.value = !isShow.value;
    };

    const antragStepIndex = toRef(props, "antragStepIndex");
    const buttonToggleId = computed(() => {
      return `antrag_steps_${ antragStepIndex.value }`;
    });
    const isAntragStepFirst = computed(() => {
      return antragStepIndex.value === 0;
    });
    const headerId = computed(() => {
      return `antrag_steps_heading_${ antragStepIndex.value }`;
    });
    const antragStepNumber = computed(() => {
      return antragStepIndex.value + 1;
    });
    const cardBodyId = computed(() => {
      return `antrag_steps_body_${ antragStepIndex.value }`;
    });

    return {
      antragStepNumber,
      buttonToggleId,
      cardBodyId,
      headerId,
      isAntragStepFirst,
      isShow,
      toggleVisibility,
    };
  },
};
