import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";


// @vue/component
export default {
  name: "NachrichtenModalComment",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    nachricht: {
      type: Object,
      default: () => ({}),
    },
    baseUrl: {
      type: String,
      default: undefined,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
        list: [
          {
            id: "kommentar",
            label: "_LBL_NACHRICHTEN_LISTE_MODAL_KOMMENTAR_",
            translate: true,
            type: "richtext",
            view: "v-alt",
          }
        ]
      },
      model: {
        kommentar: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
      status: {
        loading: undefined,
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.model.kommentar = this.nachricht.bnn_kommentar;
    },

    onError({ error }) {
      this.errors = error;
      this.errorsClone = error;
    },

    comment() {
      this.status.loading = true;
      const DATA = cloneDeep(this.model);
      this.postHttp({
        url: `${ this.baseUrl }${ this.nachricht.pk }/comment/`,
        data: DATA
      }).then(
        () => {
          this.addNotification({ text: "_MSG_NACHRICHTEN_LISTE_MODAL_KOMMENTAR_UPDATE_SUCCESS_" });
          this.close({ status: true });
        },
        error => {
          this.onError({ error: error.data });
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
