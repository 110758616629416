"use strict";

import BaseModel from "dias/core/models/baseModel";

class KatalogAdminEintrag extends BaseModel {
  constructor() {
    super("eintraege");
  }
}

export default KatalogAdminEintrag;
