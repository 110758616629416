import BankverbindungModalCreate from "../../../Geschaeftsregel/Module/Zahlungsverkehr/BankverbindungModalCreate/BankverbindungModalCreate.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";
import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";
import SyConfigAPI from "../../../../global/compositionAPI/SyConfigAPI";

import {
  gettext,
} from "../../../../global/functions/utils";

import { createNamespacedHelpers } from "vuex";

import {
  get,
  forEach,
  size,
} from "lodash-es";

const {
  mapMutations,
} = createNamespacedHelpers("snapshot");

const SNAPSHOT_CHANGES_LIST = [
  "inhaber",
  "iban",
  "bic",
];

// @vue/component
export default {
  name: "AuszahlungBankverbindung",
  components: {
    BankverbindungModalCreate,
    FormElement,
    FormstepDetail,
    PuxAlert,
    PuxButton,
    PuxTranslate,
    SnapshotIcon,
  },
  props: {
    auszahlung: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  emits: [
    "updateAuszahlung",
  ],
  setup() {
    const {
      filterIban,
    } = FiltersAPI();

    const {
      addNotification,
      getListHttp,
      putHttp,
    } = HttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    const {
      getSyConfigsValue,
    } = SyConfigAPI();

    return {
      addNotification,
      checkPermissionsSync,
      filterIban,
      getListHttp,
      getSyConfigsValue,
      putHttp,
    };
  },
  data() {
    return {
      auszahlungConfig: undefined,
      bankverbindungStatusHinweis: undefined,
      loadingBankverbindungen: false,
      bankverbindungen: [],
      modelBankverbindung: undefined,
      errors: undefined,
      isModalCreateOpen: undefined,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        label: "_TXT_BANKVERBINDUNG_",
        info: "_TXT_AUSZAHLUNG_BANKVERBINDUNG_INFO_",
        required: true,
        openCallback: this.open,
        saveCallback: this.save,
      };
    },

    isBankverbindungStatusVisible() {
      return this.checkPermissionsSync({
        perm: "auszahlung.bv_status.view",
        permArray: this.userPermissions,
      });
    },

    isBankverbindungEditable() {
      return this.checkPermissionsSync({
        perm: "auszahlung.update",
        permArray: this.userPermissions,
      });
    },

    userPermissions() {
      return this.auszahlung.user_permissions;
    },

    bankverbindungNote() {
      if (this.isBankverbindungStatusVisible && this.auszahlung.az_bv_geaendert !== null) {
        if (this.bankverbindungStatusHinweis[`${ this.auszahlung.az_bv_geaendert }`] &&
          size(this.bankverbindungStatusHinweis[`${ this.auszahlung.az_bv_geaendert }`].text) > 0) {
          return this.bankverbindungStatusHinweis[`${ this.auszahlung.az_bv_geaendert }`];
        }
      }
      return "";
    },

    bankverbindungNoteVisible() {
      return !!this.bankverbindungNote;
    },

    urlBankverbindungen() {
      return `${ this.baseUrl }${ this.auszahlung.pk }/bankverbindungen/`;
    },

    urlForSave() {
      return `${ this.urlBankverbindungen }${ this.modelBankverbindung }/uebernehmen/`;
    },

    optionsForFormElementBankverbindung() {
      const ID = "bankverbindung";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "select",
        required: true,
        label: "_LBL_VORHANDENE_BANKVERBINDUNG_",
        view: "v-alt",
        keyId: "pk",
        keyLabel: "label",
        search: true,
        searchList: ["label"],
        loading: this.loadingBankverbindungen,
      };
    },

    errorsLabelsOptions() {
      return [
        this.optionsForFormElementBankverbindung,
      ];
    },

    idForModalButton() {
      return `${ this.id }_btn_modal`;
    },

    selectorClose() {
      return `#${ this.idForModalButton }`;
    },

    isSnapshot() {
      return !!this.snapshot;
    },

    snapshotObject() {
      return get(this.snapshot, "osn_snapshot.auszahlung");
    },

    snapshotDate() {
      return get(this.snapshot, "osn_snapshot_datum");
    },

    extraTranslatedVersionSnapshotDate() {
      return {
        date: this.snapshotDate,
      };
    },

    ibanValue() {
      return this.auszahlung.az_aoviban;
    },

    ibanValueFromSnapshot() {
      return get(this.snapshotObject, "az_aoviban");
    },

    inhaberValue() {
      return this.auszahlung.az_aovinhaber;
    },

    inhaberValueFromSnapshot() {
      return get(this.snapshotObject, "az_aovinhaber");
    },

    bicValue() {
      return this.auszahlung.az_aovbic;
    },

    bicValueFromSnapshot() {
      return get(this.snapshotObject, "az_aovbic");
    },

    objectData() {
      return {
        bic: this.bicValue,
        iban: this.filterIban(this.ibanValue),
        inhaber: this.inhaberValue,
      };
    },

    snapshotData() {
      return {
        bic: this.bicValueFromSnapshot,
        iban: this.filterIban(this.ibanValueFromSnapshot),
        inhaber: this.inhaberValueFromSnapshot,
      };
    },

    snapshotChanges() {
      const SNAPSHOT_CHANGES = {
        changes: {},
        status: false,
      };
      if (this.isSnapshot) {
        forEach(SNAPSHOT_CHANGES_LIST, key => {
          const CURRENT_VALUE = this.objectData[key] || null;
          const CURRENT_SNAPSHOT_VALUE = this.snapshotData[key] || null;
          if (CURRENT_SNAPSHOT_VALUE !== CURRENT_VALUE) {
            SNAPSHOT_CHANGES.changes[key] = true;
            SNAPSHOT_CHANGES.status = true;
          }
        });
      }
      return SNAPSHOT_CHANGES;
    },

    isDiff() {
      return this.snapshotChanges.status;
    },
  },
  watch: {
    isDiff: {
      immediate: true,
      handler(newVal) {
        this.MUT_SET_STATUS_DIFF_FOR_REGEL({
          regelId: this.id,
          statusDiff: newVal,
        });
      },
    },
  },
  beforeUnmount() {
    this.MUT_DELETE_STATUS_DIFF_FOR_REGEL({ regelId: this.id });
  },
  created() {
    this.checkSyConfig();
    this.setBankverbindungStatusHinweisWithSyConfig();
  },
  methods: {
    checkSyConfig() {
      this.auszahlungConfig = this.getSyConfigsValue("auszahlung", {});
    },

    setBankverbindungStatusHinweisWithSyConfig() {
      this.bankverbindungStatusHinweis = {
        null: {
          class: "info",
          text: this.auszahlungConfig.hinweis_bv_leer,
        },
        false: {
          class: "success",
          text: this.auszahlungConfig.hinweis_bv_gleich,
        },
        true: {
          class: "warning",
          text: this.auszahlungConfig.hinweis_bv_geaendert,
        },
      };
    },

    open() {
      this.modelBankverbindung = this.auszahlung.aktuell_bv ? this.auszahlung.aktuell_bv : undefined;
      this.loadBankverbindungen();
    },

    save() {
      return new Promise((resolve, reject) => {
        this.validate();
        if (this.errors) {
          return reject({ data: this.errors });
        }

        this.putHttp({
          url: this.urlForSave,
          data: {},
        }).then(
          response => {
            this.updateAuszahlungLocal(response);
            this.addNotification({ text: "_MSG_BANKVERBINDUNG_CHANGE_SUCCESS_" });
            resolve();
          },
          error => {
            this.errors = error.data;
            reject(error);
          },
        );
      });
    },

    validate() {
      if (!this.modelBankverbindung) {
        this.errors = {
          bankverbindung: [gettext("_TXT_BANKVERBINDUNG_REQUIRED_ERROR_")],
        };
      } else {
        this.errors = undefined;
      }
    },

    updateAuszahlungLocal(response) {
      const OBJECT_FOR_UPDATE = {
        az_aoviban: response.iban,
        az_aovbank: response.bank,
        az_aovinhaber: response.inhaber,
        az_aovbic: response.bic,
        az_bv_geaendert: response.geaendert,
        aktuell_bv: response.pk
      };
      this.$emit("updateAuszahlung", OBJECT_FOR_UPDATE, true);
    },

    loadBankverbindungen() {
      this.loadingBankverbindungen = true;
      this.getListHttp({
        url: this.urlBankverbindungen,
      }).then(
        response => {
          this.bankverbindungen = this.setLabelsForBankverbindungen(response);
          this.loadingBankverbindungen = false;
        },
      );
    },

    setLabelsForBankverbindungen(response) {
      const LIST = [];
      forEach(response, item => {
        LIST.push({
          pk: item.pk,
          label: this.setLabelForBankverbindung(item),
        });
      });
      return LIST;
    },

    setLabelForBankverbindung(item) {
      const BIC = item.bic ? `, ${ item.bic }` : "";
      return `${ item.inhaber }, ${ this.filterIban(item.iban, true) }${ BIC }`;
    },

    openModal() {
      this.isModalCreateOpen = true;
    },

    closeModalCreate({ response } = {}) {
      if (response) {
        this.addNewBankverbindung(response);
        this.setModelBankverbindung(response);
      }
      this.isModalCreateOpen = false;
    },

    addNewBankverbindung(response) {
      this.bankverbindungen.push({
        pk: response.pk,
        label: this.setLabelForBankverbindung(response),
      });
    },

    setModelBankverbindung(response) {
      this.modelBankverbindung = response.pk;
    },

    ...mapMutations([
      "MUT_SET_STATUS_DIFF_FOR_REGEL",
      "MUT_DELETE_STATUS_DIFF_FOR_REGEL",
    ]),
  },
};
