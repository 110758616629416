import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PersonalModalCreate from "../PersonalModalCreate/PersonalModalCreate.vue";
import PersonalModalDeactivate from "../PersonalModalDeactivate/PersonalModalDeactivate.vue";
import PersonalModalReactivate from "../PersonalModalReactivate/PersonalModalReactivate.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../global/components/ui/ShowMore/ShowMore.vue";
import SlotKompetenzen from "./SlotKompetenzen/SlotKompetenzen.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";
import { FilterDateMixin } from "../../../global/mixins/FiltersMixin";

import getTableApi from "../../../const/TableApis";

import { EventBus } from "../../../global/functions/event-bus";

import {
  assign,
  get,
  isNil,
} from "lodash-es";

const TABLE_ID = "personal";

// @vue/component
export default {
  name: "PersonalList",
  components: {
    PageTabTitle,
    PersonalModalCreate,
    PersonalModalDeactivate,
    PersonalModalReactivate,
    PuxTranslate,
    ShowMore,
    SlotKompetenzen,
    SmartTable,
  },
  mixins: [
    FilterDateMixin,
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  props: {
    header: {
      type: String,
      required: false,
      info: "Text für Tabelle-Header",
      default: "_TXT_PERSONAL_TABLE_HEADER_"
    },
    filter: {
      type: Object,
      required: false,
      default: undefined,
      info: "Zusätzliches Get-Parameter für API",
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [],
        rowActions: [
          {
            label: "_TXT_DETAILS_ANZEIGEN_",
            route: "root.personal.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
          {
            label: "_BTN_DEACTIVATE_",
            isHidden: ({ item }) => !item.can_deactivate,
            callback: ({ item, index }) => this.openModalDeactivate({ item, index }),
          },
          {
            label: "_BTN_REACTIVATE_",
            isHidden: ({ item }) => !item.can_reactivate,
            callback: ({ item, index }) => this.openModalReactivate({ item, index }),
          },
        ],
        actions: [
          {
            label: "_BTN_PERSONAL_LIST_CREATE_",
            callback: () => this.modal.create = true,
            permission: "personal.create",
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "personal_create",
          },
        ],
        label: "_LBL_PERSONAL_LIST_TABLE_",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      filters: [],
      status: {
        loading: true,
      },
      modal: {
        create: undefined,
        deactivate: undefined,
        reactivate: undefined,
      },
      currentItem: undefined,
      currentIndex: undefined,
    };
  },
  computed: {
    currentItemActionBtnId() {
      return `#${ this.tableId }_action_dropdown_${ this.currentIndex }`;
    }
  },
  methods: {
    translationAbwesendText({ item }) {
      if (isNil(item.aktuelle_abwesenheit_ab)) {
        return "_TXT_PERSONAL_LIST_ABWESENHEIT_TEXT_EMPTY_";
      } else if (isNil(item.aktuelle_abwesenheit_bis)) {
        return "_TXT_PERSONAL_LIST_ABWESENHEIT_TEXT_BIS_NULL_{{ab_date}}_{{ab_datetime}}_";
      }
      return "_TXT_PERSONAL_LIST_ABWESENHEIT_TEXT_{{ab_date}}_{{ab_datetime}}_{{bis_date}}_{{bis_datetime}}_";
    },

    translationAbwesendTitle({ item }) {
      if (isNil(item.aktuelle_abwesenheit_ab)) {
        return "_TXT_PERSONAL_LIST_ABWESENHEIT_TITLE_EMPTY_";
      } else if (isNil(item.aktuelle_abwesenheit_bis)) {
        return "_TXT_PERSONAL_LIST_ABWESENHEIT_TITLE_BIS_NULL_{{ab_date}}_{{ab_datetime}}_";
      }
      return "_TXT_PERSONAL_LIST_ABWESENHEIT_TITLE_{{ab_date}}_{{ab_datetime}}_{{bis_date}}_{{bis_datetime}}_";
    },

    translationAbwesendExtra({ item }) {
      return {
        ab_date: this.filterDate(item.aktuelle_abwesenheit_ab, "DD.MM.YY"),
        ab_datetime: this.filterDate(item.aktuelle_abwesenheit_ab, "DD.MM.YY HH:mm [Uhr]"),
        bis_date: this.filterDate(item.aktuelle_abwesenheit_bis, "DD.MM.YY"),
        bis_datetime: this.filterDate(item.aktuelle_abwesenheit_bis, "DD.MM.YY HH:mm [Uhr]"),
      };
    },

    openModalDeactivate({ item, index }) {
      this.currentItem = item;
      this.currentIndex = index;
      this.modal.deactivate = true;
    },

    closeModalDeactivate({ response } = {}) {
      if (get(response, "status") == 200) {
        this.updateRow({ response: response.data });
      } else if (get(response, "status") == 204) {
        this.removeRow();
      }
      this.modal.deactivate = false;
    },

    openModalReactivate({ item, index }) {
      this.currentItem = item;
      this.currentIndex = index;
      this.modal.reactivate = true;
    },

    closeModalReactivate({ response } = {}) {
      if (response) {
        this.updateRow({ response: response.data });
      }
      this.modal.reactivate = false;
    },

    closeModalCreate() {
      this.modal.create = false;
    },

    updateRow({ response } = {}) {
      if (response) {
        const row = assign({}, this.currentItem, response);
        EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row, index: this.currentIndex }));
      }
    },

    removeRow() {
      EventBus.$emit(`deleteRow${ TABLE_ID }`, ({ index: this.currentIndex }));
    },

    getShowMoreOptions({ col }) {
      return {
        maxHeight: get(col, "extra.maxHeight", 200)
      };
    }
  },
};
