"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import auszahlungslisten from "./auszahlungslisten";

const modul = angular.module("dias.vertrag.cortexe", [
  uiRouter,
  auszahlungslisten.name
])
;

export default modul;
