import {
  computed,
  ref,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import {
  EventBus,
} from "../../../../global/functions/event-bus";
import {
  cloneDeep,
  get,
  isString,
} from "lodash-es";

const GEGENPRUEFEN_IDS = [
  "2db26051-14c5-5ed5-e050-007f01013822",
  "7c0e901d-3ec6-4b16-b64a-b8e6289804f5",
  "8af3d8dc-a051-bd39-7692-3069d37febf3",
  "c7c53887-7305-4411-be28-d8a213ea9d23",
  "640faf21-ed9b-433a-ac23-ee9c4b1e4e2a",
];
const EINREICHEN_PK = "003ed184-2c7e-4d30-a5f7-7b8aec3b47c6";

export default function AntragWorkflowAPI({
  antrag,
  antragUrl,
  scrollTop,
  reloadAntrag,
  openAufgabenRubrics,
}) {
  const clientFunktion = ref(undefined);
  const currentWorkflowfolge = ref(undefined);
  const lastWfExecution = ref(undefined);
  const workflowfolgeUpdateKey = computed(() => {
    return `${ antrag.value.antragstellerorg }__${ lastWfExecution.value }`;
  });

  const oldAufgabePk = ref(undefined);
  const validationErrors = ref(undefined);
  const validationErrorsLoading = ref(undefined);

  const gegenpruefenInaktive = ref(false);
  const checkGegenpruefenStatus = workflowfolgen => {
    gegenpruefenInaktive.value = false;
    for (let i = 0; i < workflowfolgen.length; i++) {
      if (workflowfolgen[i].status === 1 && GEGENPRUEFEN_IDS.indexOf(workflowfolgen[i].pk) !== -1) {
        gegenpruefenInaktive.value = true;
        break;
      }
    }
  };
  const einreichenInaktive = ref(undefined);
  const checkEinreichenStatus = workflowfolgen => {
    einreichenInaktive.value = false;
    for (let i = 0; i < workflowfolgen.length; i++) {
      if (workflowfolgen[i].pk === EINREICHEN_PK && workflowfolgen[i].status !== 0) {
        einreichenInaktive.value = true;
        break;
      }
    }
  };
  const checkStatus = workflowfolgen => {
    checkGegenpruefenStatus(workflowfolgen);
    checkEinreichenStatus(workflowfolgen);
  };

  const updateAufgabe = newAufgabe => {
    const ANTRAG = cloneDeep(antrag.value);
    ANTRAG.aktuelle_aufgabe = Object.assign({}, ANTRAG.aktuelle_aufgabe, newAufgabe);
    antrag.value = ANTRAG;
  };
  const updateSnapshots = () => {
    EventBus.$emit("updateSnapshots");
  };
  const reloadWorkflowfolgen = () => {
    lastWfExecution.value = new Date().toISOString();
  };

  const onWorkflowInit = clientFunktionLocal => {
    clientFunktion.value = clientFunktionLocal;
  };
  const onWorkflowStarted = (aufgabe, currentWorkflowfolgeLocal) => {
    updateAufgabe(aufgabe);
    oldAufgabePk.value = antrag.value.aktuelle_aufgabe.pk;
    validationErrorsLoading.value = true;
    currentWorkflowfolge.value = currentWorkflowfolgeLocal;
  };
  const onWorkflowFinish = () => {
    clientFunktion.value = undefined;
    validationErrorsLoading.value = false;
    oldAufgabePk.value = undefined;
    currentWorkflowfolge.value = undefined;
  };
  const onWorkflowCanceled = () => {
    clientFunktion.value = undefined;
    validationErrorsLoading.value = false;
  };
  const onWorkflowSuccess = ({ result } = {}) => {
    if (clientFunktion.value) {
      clientFunktion.value.onServerSuccess(result);
    }
    EventBus.$emit("workflow.aufgabe.changed");
    if (result.pk === oldAufgabePk.value) {
      reloadWorkflowfolgen();
    }
    updateAufgabe(result);
    validationErrors.value = undefined;
    scrollTop();
    updateSnapshots();
    const wfoReload = get(currentWorkflowfolge.value, "wfo_reload") || false;
    reloadAntrag(true, wfoReload).then(
      () => openAufgabenRubrics()
    );
  };
  const onWorkflowFailure = ({ result } = {}) => {
    if (clientFunktion.value) {
      clientFunktion.value.onServerError(result);
    }
    if (result.data && result.data.error_data) {
      validationErrors.value = result.data.error_data;
    } else if (result.data && !isString(result.data)) {
      validationErrors.value = result.data;
    } else {
      validationErrors.value = undefined;
    }
    scrollTop();
    return reloadAntrag(true);
  };
  const onWorkflowfolgenUpdated = folgen => {
    checkStatus(folgen);
  };

  const {
    addNotification,
    getHttp,
  } = HttpAPI();
  const antragValidierenUrl = computed(() => {
    return `${ antragUrl.value }validieren/`;
  });
  const onValidate = () => {
    validationErrorsLoading.value = true;
    getHttp({
      url: antragValidierenUrl.value,
    }).then(
      response => {
        validationErrors.value = response;
      },
      () => {
        addNotification({
          text: "_MSG_ANTRAG_VALIDIERUNG_FEHLER_",
          type: "error",
        });
      }
    ).finally(() => {
      validationErrorsLoading.value = false;
      scrollTop();
    });
  };
  const {
    checkPermissionsSync,
  } = PermissionAPI();
  const hasWFPermissionFoerderantragValidieren = computed(() => {
    return checkPermissionsSync({
      perm: "foerderantrag.validieren",
      permArray: antrag.value.user_permissions,
    });
  });
  const workflowTypeDefault = computed(() => {
    return get(antrag.value, "aktuelle_aufgabe.workflowstatusgruppe.workflowtyp");
  });
  const resetValidation = () => {
    validationErrorsLoading.value = undefined;
    validationErrors.value = undefined;
  };

  return {
    gegenpruefenInaktive,
    einreichenInaktive,

    hasWFPermissionFoerderantragValidieren,
    onValidate,
    onWorkflowInit,
    onWorkflowStarted,
    onWorkflowFinish,
    onWorkflowCanceled,
    onWorkflowSuccess,
    onWorkflowFailure,
    onWorkflowfolgenUpdated,
    reloadWorkflowfolgen,
    resetValidation,
    updateAufgabe,
    validationErrors,
    validationErrorsLoading,
    workflowTypeDefault,
    workflowfolgeUpdateKey,
  };
}
