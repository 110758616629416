"use strict";

import template from "./alert.jade";

class AlertController {
  /*@ngInject*/
  constructor() {
    if (angular.isUndefined(this.onDismiss)) {
      this.onDismiss = () => {};
    }
  }
}

export default {
  template: template(),
  controller: AlertController,
  controllerAs: "vm",
  transclude: true,
  bindings: {
    type: "<",
    dismissable: "<",
    onDismiss: "&?"
  }
};
