import FoerderfinderListFoerderangebotDetails from "../FoerderfinderListFoerderangebotDetails/FoerderfinderListFoerderangebotDetails.vue";
import FoerderfinderListFoerderprogrammeDetails from "../FoerderfinderListFoerderprogrammeDetails/FoerderfinderListFoerderprogrammeDetails.vue";
import FoerderfinderModalAntragCreate from "../FoerderfinderModalAntragCreate/FoerderfinderModalAntragCreate.vue";
import FoerderfinderSymbolDynamicChildDetails
  from "../FoerderfinderSymbolDynamicChildDetails/FoerderfinderSymbolDynamicChildDetails.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import {
  forEach,
} from "lodash-es";

const TABLE_ID = "FoerderfinderTable";

// @vue/component
export default {
  name: "FoerderfinderList",
  components: {
    FoerderfinderListFoerderangebotDetails,
    FoerderfinderListFoerderprogrammeDetails,
    FoerderfinderModalAntragCreate,
    FoerderfinderSymbolDynamicChildDetails,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
    PermissionMixin,
  ],
  props: {
    extern: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        view: {
          default: "symbol",
          table: true,
          symbol: true,
          filter: "right",
          filterShow: true,
        },
        label: "_TXT_FOERDERFINDER_TABLE_LABEL_",
        pagination: false,
        sortable: true,
        tableName: "foerderfinderangebote",
        details: true,
        cols: [
          {
            label: "_LBL_FOERDERFINDER_LIST_TITEL_",
            id: "kbez",
            sortable: true,
            sortId: "kbez",
            sortableDefault: "asc",
          },
          {
            label: "_LBL_FOERDERFINDER_LIST_TYP_",
            id: "type",
            // sortable: true,
            templateCallback: ({ row }) => `<span>${ row.anz_angebote === undefined ? this.$gettext("Förderangebot") : this.$gettext("Förderprogramme") }</span>`,
          },
          {
            label: "_LBL_FOERDERFINDER_LIST_SUCHBEGRIFFE_",
            id: "suchbegriffe",
          },
        ],
        url: ["ff/v3/foerderprogramme/?hat_angebote=true", "ff/v3/foerderangebote/?hat_programme=false"],
      },

      filters: [
        {
          label: "_LBL_FOERDERFINDER_LIST_SUCHE_",
          id: "suche",
          type: "text",
          show: true,
          view: "v",
        },
      ],

      categories: [],
      currentAngebot: undefined,
      status: {
        angebotDetails: undefined,
        loading: true,
        foerderantragCreate: undefined,
        user: undefined,
      },
      modal: {
        create: undefined,
      },
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },

    getSlotNameSymbol() {
      return `${ this.tableId }_symbol`;
    },

    getSlotNameSymbolDetails() {
      return `${ this.tableId }_symbol_details`;
    },
  },
  created() {
    this.checkSyConfigs();
    this.checkUser();
  },
  methods: {
    checkSyConfigs() {
      const instance_pages = this.getSyConfigsValue("instance_pages");
      this.status.angebotDetails = instance_pages && instance_pages.foerderfinderangeboteDetails;
      const KONFIG = this.getSyConfigsValue("tabellenkonfig") || {};
      if (KONFIG.tabellenkonfig &&
        KONFIG.tabellenkonfig[TABLE_ID] &&
        KONFIG.tabellenkonfig[TABLE_ID].view) {
        this.options.view.default = KONFIG.tabellenkonfig[TABLE_ID].view.default;
        this.options.view.table = KONFIG.tabellenkonfig[TABLE_ID].view.table;
        this.options.view.symbol = KONFIG.tabellenkonfig[TABLE_ID].view.symbol;
      }
    },

    checkPermission() {
      this.checkPermissionCallback(this.checkPermissionsSync({ perm: "foerderantrag.create" }));
    },

    checkPermissionCallback(hasPerm) {
      this.status.foerderantragCreate = hasPerm;
      this.loadCategories();
    },

    checkUser() {
      this.user = this.me;
      this.checkUserCallback();
    },

    checkUserCallback() {
      if (this.user && this.user.pk) {
        this.status.user = true;
        this.checkPermission();
      } else {
        this.loadCategories();
      }
    },

    createFoerderantragExtern({ item }) {
      const antrag = {
        regelsatz: item.regelsatz,
        quelle: "58d94b0e-2d5b-44f1-afe2-b2c817877304",
      };
      this.postHttp({ url: "foerderantraege/", data: antrag }).then(
        response => {
          if (typeof window.utag !== "undefined") {
            try {
              const LINK_OBJ = {
                event_id: "DIAS_antrag_erstellen",
                etracker_event_action: "click",
              };
              if (item) {
                LINK_OBJ.antrag_angebot = item.kbez;
                LINK_OBJ.antrag_angebot_id = item.pk;
              }
              window.utag.link(LINK_OBJ);
            } catch (err) {
              console.error(err);
            }
          }
          window.top.location.href = `/foerderantrag/${ response.pk }/`;
        },
        () => {}
      );
    },

    createFoerderantrag({ item }) {
      if (this.extern) {
        this.createFoerderantragExtern({ item });
        return;
      }
      this.currentAngebot = item;
      this.modal.create = true;
    },

    closeModalCreate() {
      this.modal.create = false;
    },

    initFilters() {
      const filters = [];
      forEach(this.categories, cat => {
        const filter = {
          label: cat.kbez,
          key: cat.pk, // Wenn "id" gleiche ist (für :key="filter.key || filter.id" und data[filter.key || filter.id])
          id: "kategoriewert", // Das ist immer Model-Filter (this.model.kategoriewert)
          type: cat.multivalue ? "checkbox" : "radio",
          collapse: true,
          show: cat.sichtbar_filter === "Filter zugeklappt",
          data: [],
          view: "v-alt",
        };
        forEach(cat.werte, wert => {
          filter.data.push({
            labelHtml: `${ wert.svg ?
                            wert.svg :
                            wert.image ?
                              `<img src="/api/assets/${ wert.image }.png" class="custom-control-label__img ${ wert.style }"/><span>${ wert.kbez }</span>` :
                              `<span>${ wert.kbez }</span>` }`,
            label: wert.kbez,
            info: wert.bez,
            value: wert.pk,
            group: wert.gruppe ? {
              id: wert.gruppe.pk,
              label: wert.gruppe.kbez,
              pos: wert.gruppe.pos,
            } : undefined,
          });
        });
        filters.push(filter);
      });

      this.filters = [...this.filters, ...filters];
      this.status.loading = false;
    },

    loadCategories() {
      this.getListHttp({ url: "ff/v3/foerderfinderkategorien/?sichtbar_filter" }).then(
        response => {
          this.categories = response;
          this.initFilters();
        }
      );
    },
  },
};
