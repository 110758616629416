import Alert from "../../../../global/components/Alert/Alert.vue";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import {
  forEach,
  size,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  components: {
    Alert,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    position: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      errors: {},
      errorLabels: {},
    };
  },
  computed: {
    showErrors_mixin() {
      return size(this.errors);
    },
  },
  methods: {
    initErrorLabels_mixin() {
      const ERROR_LABELS = {};
      forEach(this.fields, (field, key) => {
        ERROR_LABELS[key] = field.label;
      });
      this.errorLabels = ERROR_LABELS;
    },

    validateStep({ kostenartId, antragId }) {
      let data = {
        kostenartdetail: this.model.kostenartdetail,
        argument: this.model,
        akp_id: this.position ? this.position.pk : null,
      };
      if (isFunction(this.prepareDataForValidateAndSave)) {
        data = this.prepareDataForValidateAndSave({ data });
      }
      return new Promise((resolve, reject) => {
        this.postHttp({
          url: `foerderantraege/${ antragId }/kostenarten/${ kostenartId }/positionen/validieren/`,
          data: data,
        }).then(
          () => {
            this.deleteErrors();
            resolve();
          },
          error => {
            this.errors = this.setErrorsForCurrentStep({ errors: error.data });
            if (size(this.errors)) {
              reject(this.errors);
            } else {
              this.errors = {};
              resolve();
            }
          }
        );
      });
    },

    deleteErrors() {
      this.errors = {};
    },

    setErrorsForCurrentStep({ errors }) {
      const ERRORS = {};
      forEach(errors, (error, key) => {
        if (this.fields[key]) {
          ERRORS[key] = error;
        }
      });
      return ERRORS;
    },
  },
};
