"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";
import multiSelect from "dias/foerderregeln/parameter/selectMulti";
import template from "./config.jade";
import templateRo from "./readonly.jade";
import controller from "./controller";


const dynTabelle = angular.module("dias.dynamische_tabelle.parameter.dyn_tabelle_objektkatalog_data", [
  parameterServices.name,
  multiSelect.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("dyn_tabelle_objektkatalog_data", "parametermodul-dyn-tabelle-objektkatalog-data");
  })
  .component("parametermodulDynTabelleObjektkatalogData", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulDynTabelleObjektkatalogDataRo", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: templateRo(),
    controller: controller,
    controllerAs: "vm",
  });
export default dynTabelle;
