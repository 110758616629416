import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import {
  cloneDeep,
  forEach,
  size,
  isEmpty,
} from "lodash-es";

// @vue/component
export default {
  name: "AnzahlBetreuerFerien",
  components: {
    SnapshotModule,
    SnapshotIcon,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      antwortStatus: undefined,
      labelGrundVerhaeltnisBetreuer: "_LBL_GR_ANZAHL_BETREUER_FERIEN_GRUND_",
      status_grund_verhaeltnis_betreuer: undefined,
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      const LIST = [];
      forEach(this.modelParameter.dyn_zahlen_params, (item, index) => {
        LIST.push({
          id: item.pos,
          htmlId: `${ this.htmlRegelNummer }_${ item.pos }`,
          type: "text",
          label: item.label,
          required: true,
          view: "v",
          editonly: true,
          startupFocus: index === 0,
        });
      });
      if (this.status_grund_verhaeltnis_betreuer) {
        LIST.push({
          id: "grund_verhaeltnis_betreuer",
          htmlId: `${ this.htmlRegelNummer }_grund_verhaeltnis_betreuer`,
          type: "textarea",
          label: this.labelGrundVerhaeltnisBetreuer,
          translate: true,
          required: true,
          view: "v-alt",
          editonly: true,
        });
      }
      OPTIONS.list = LIST;
      return OPTIONS;
    },

    labelHeader() {
      return this.modelParameter.frage;
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(this.modelParameter.dyn_zahlen_params, item => {
          const KEY = item.pos;
          if (this.model[KEY] !== this.snapshotModule[KEY]) {
            DIFF[KEY] = true;
          }
        });
        if (this.model.grund_verhaeltnis_betreuer !== this.snapshotModule.grund_verhaeltnis_betreuer) {
          DIFF.grund_verhaeltnis_betreuer = true;
        }
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },

    statusAntwortSnapshot() {
      let status = false;
      if (this.snapshotModule) {
        forEach(this.snapshotModule, item => {
          if (item) {
            status = true;
            return false;
          }
        });
      }
      return status;
    },
  },
  methods: {
    prepareModel() {
      this.model = cloneDeep(this.module.data);
      this.antwortStatus = false;
      this.status_grund_verhaeltnis_betreuer = false;
      if (this.model) {
        forEach(this.model, item => {
          if (item) {
            this.antwortStatus = true;
            return false;
          }
        });
        if (this.model.grund_verhaeltnis_betreuer) {
          this.status_grund_verhaeltnis_betreuer = true;
        }
      }
    },

    save({ model }) {
      const DATA = this.modelEdit && size(this.modelEdit) ? cloneDeep(this.modelEdit) : cloneDeep(model);
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.moduleUrl,
          data: DATA,
        }).then(
          response => {
            this.onRestFunctions({ response });
            this.errors = undefined;
            resolve();
          },
          error => {
            this.errors = error.data;
            if (this.errors.grund_verhaeltnis_betreuer) {
              this.status_grund_verhaeltnis_betreuer = true;
            }
            reject(error);
          }
        );
      });
    },
  },
};
