import {
  computed,
  ref,
} from "vue";

import HttpAPI from "../../../../../libs/vue/client/vue/global/compositionAPI/HttpAPI";

import KAkkreditierungsstatusUUIDs from "../../../../../libs/vue/client/vue/const/KAkkreditierungsstatusUUIDs";

import {
  cloneDeep,
  includes,
} from "lodash-es";

export default function AkkreditierungDetailsAPI({
  emit,
  akkreditierung,
  baseUrl = "programmakkreditierungen",
}) {
  const {
    putHttp,
  } = HttpAPI();

  const isProgrammakkreditierung = baseUrl === "programmakkreditierungen";
  const errors = ref({});
  const akkreditierungLocal = ref({});
  const akkreditierungProJointDegree = computed(() => {
    return akkreditierung.value.pro_joint_degree ? "Ja" : "Nein";
  });
  const zeitraumLabel = computed(() => {
    return akkreditierung.value.kombiakkr_ursprung_id ? "Begutachtungszeitraum" : "Akkreditierung";
  });
  const saveUrl = computed(() => {
    return `${ baseUrl }/${ akkreditierung.value.pk }/akkrdaten_aendern/`;
  });
  const htmlId = computed(() => {
    return `akkreditierung_${ akkreditierung.value.pk }`;
  });

  const initModel = () => {
    akkreditierungLocal.value = cloneDeep(akkreditierung.value);
  };
  const onErrors = errorsLocal => {
    if (errorsLocal.data) {
      errors.value = errorsLocal.data;
    } else {
      errors.value = errorsLocal;
    }
  };
  const clearErrors = () => {
    errors.value = {};
  };
  const save = () => {
    clearErrors();
    return new Promise((resolve, reject) => {
      putHttp({
        url: saveUrl.value,
        data: akkreditierungLocal.value,
      }).then(
        response => {
          emit("onSave", response);
          resolve();
          setTimeout(() => {
            initModel();
          });
        },
        errors => {
          onErrors(errors);
          reject(errors);
        }
      );
    });
  };
  const close = () => {
    clearErrors();
    initModel();
  };
  const formstepDetailOptions = computed(() => {
    return {
      saveCallback: save,
      closeCallback: close,
      label: "Akkreditierung",
    };
  });
  const optionsList = computed(() => {
    return [
      {
        id: "typ",
        htmlId: `akkreditierung_typ_${ akkreditierung.value.pk }`,
        type: "select",
        view: "v",
        label: "Art",
        required: true,
        keyLabel: "akt_kbez",
        keyId: "akt_id",
        url: "akkreditierungstypen/",
      },
      {
        id: "status",
        htmlId: `akkreditierung_status_${ akkreditierung.value.pk }`,
        type: "select",
        view: "v",
        label: "Status",
        required: true,
        keyLabel: "kas_kbez",
        keyId: "kas_id",
        url: "akkreditierungsstatus/",
      },
      {
        id: "verfahren",
        htmlId: `akkreditierung_verfahren_${ akkreditierung.value.pk }`,
        type: "select",
        view: "v",
        label: "Verfahren",
        required: true,
        keyLabel: "kav_bez",
        keyId: "kav_id",
        url: "akkreditierungsverfahren/",
        hideIf: !isProgrammakkreditierung,
      },
      {
        id: "agentur",
        htmlId: `akkreditierung_agentur_${ akkreditierung.value.pk }`,
        type: "select",
        view: "v",
        label: "Begutachtende Agentur",
        required: true,
        keyLabel: "kbez",
        keyId: "pk",
        url: "begutachtende_organisationen/",
        emptyLabel: "Ohne Agenturbeteiligung",
        emptyValue: null,
      },
      {
        id: "akkr_organisation",
        htmlId: `akkreditierung_akkr_organisation_${ akkreditierung.value.pk }`,
        type: "select",
        view: "v",
        label: "Akkreditiert durch",
        required: true,
        keyLabel: "kbez",
        keyId: "pk",
        url: isProgrammakkreditierung ? "programmakkreditierende_organisationen/" : "systemakkreditierende_organisationen/",
      },
      {
        id: isProgrammakkreditierung ? "pro_von" : "aks_von",
        htmlId: `akkreditierung_pro_von_${ akkreditierung.value.pk }`,
        type: "date",
        view: "v",
        label: `Beginn ${ isProgrammakkreditierung ? zeitraumLabel.value : "Akkreditierung" }`,
        required: true,
      },
      {
        id: isProgrammakkreditierung ? "pro_bis" : "aks_bis",
        htmlId: `akkreditierung_pro_bis_${ akkreditierung.value.pk }`,
        type: "date",
        view: "v",
        label: `Ende ${ isProgrammakkreditierung ? zeitraumLabel.value : "Akkreditierung" }`,
        required: true,
      },
      {
        id: isProgrammakkreditierung ? "pro_auflagen" : "aks_auflagen",
        htmlId: `akkreditierung_pro_auflagen_${ akkreditierung.value.pk }`,
        type: "richtext",
        view: "v",
        label: "Auflagen",
        required: false,
      },
      {
        id: isProgrammakkreditierung ? "pro_auflagen_frist" : "aks_auflagen_frist",
        htmlId: `akkreditierung_pro_auflagen_frist_${ akkreditierung.value.pk }`,
        type: "date",
        view: "v",
        label: "Auflagenfrist",
        required: false,
      },
      {
        id: "pro_beschluss",
        htmlId: `akkreditierung_pro_beschluss_${ akkreditierung.value.pk }`,
        type: "richtext",
        view: "v",
        label: "Beschluss",
        required: false,
        hideIf: !isProgrammakkreditierung,
      },
      {
        id: "pro_gutachter",
        htmlId: `akkreditierung_pro_gutachter_${ akkreditierung.value.pk }`,
        type: "richtext",
        view: "v",
        label: "Gutachtergruppe",
        required: false,
        hideIf: !isProgrammakkreditierung,
      },
      {
        id: "pro_bewertung",
        htmlId: `akkreditierung_pro_bewertung_${ akkreditierung.value.pk }`,
        type: "richtext",
        view: "v",
        label: "Zusammenfassende Bewertung",
        required: false,
        hideIf: !isProgrammakkreditierung,
      },
      {
        id: "aks_bewertung",
        htmlId: `akkreditierung_pro_bewertung_${ akkreditierung.value.pk }`,
        type: "richtext",
        view: "v",
        label: "Bewertung",
        required: false,
        hideIf: isProgrammakkreditierung,
      },
      {
        id: "aks_gutachter",
        htmlId: `akkreditierung_pro_gutachter_${ akkreditierung.value.pk }`,
        type: "richtext",
        view: "v",
        label: "Gutachtergruppe",
        required: false,
        hideIf: isProgrammakkreditierung,
      },
      {
        id: "pro_profil",
        htmlId: `akkreditierung_pro_profil_${ akkreditierung.value.pk }`,
        type: "richtext",
        view: "v",
        label: "Profil",
        required: false,
        hideIf: !isProgrammakkreditierung,
      },
      {
        id: "pro_turnus_info",
        htmlId: `akkreditierung_pro_turnus_info_${ akkreditierung.value.pk }`,
        type: "richtext",
        view: "v",
        label: "Informationen zum Turnus der internen Evaluation/Akkreditierung",
        required: false,
        hideIf: !isProgrammakkreditierung,
      },
      {
        id: "pro_joint_degree",
        type: "radio",
        data: [
          {
            value: true,
            label: "Ja"
          },
          {
            value: false,
            label: "Nein"
          },
        ],
        label: "Joint Degree:",
        view: "v-alt",
        required: false,
        hideIf: !isProgrammakkreditierung,
      },
      {
        id: "aks_gutachten_extern",
        htmlId: `akkreditierung_aks_gutachten_extern_${ akkreditierung.value.pk }`,
        type: "text",
        view: "v",
        label: "Link zu extern abgelegtem Gutachten",
        required: false,
        hideIf: isProgrammakkreditierung,
      },
      {
        id: "aks_kurzportrait",
        htmlId: `akkreditierung_aks_kurzportrait_${ akkreditierung.value.pk }`,
        type: "richtext",
        view: "v",
        label: "Kurzportrait",
        required: false,
        hideIf: isProgrammakkreditierung,
      },
      {
        id: "aks_qmsystem",
        htmlId: `akkreditierung_aks_qmsystem_${ akkreditierung.value.pk }`,
        type: "richtext",
        view: "v",
        label: "Überblick über das QM-System",
        required: false,
        hideIf: isProgrammakkreditierung,
      },
    ];
  });

  const showAuflagen = computed(() => {
    const statuses = [
      KAkkreditierungsstatusUUIDs.AKKREDITIERT_MIT_AUFLAGEN,
      KAkkreditierungsstatusUUIDs.AKKREDITIERT_AUFLAGEN_ERFUELLT,
      KAkkreditierungsstatusUUIDs.AKKREDITIERT_AUFLAGEN_TEILW_ERFUELLT
    ];

    return includes(statuses, akkreditierung.value.status);
  });

  return {
    akkreditierungLocal,
    akkreditierungProJointDegree,
    errors,
    formstepDetailOptions,
    htmlId,
    initModel,
    optionsList,
    save,
    showAuflagen,
    zeitraumLabel,
  };
}
