import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import ProgrammeBasisdaten from "./ProgrammeBasisdaten/ProgrammeBasisdaten.vue";
import ProgrammeFoerderangeboteList from "./ProgrammeFoerderangeboteList/ProgrammeFoerderangeboteList.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";

// @vue/component
export default {
  name: "ProgrammeDetails",
  components: {
    ContextPanel,
    ProgrammeBasisdaten,
    ProgrammeFoerderangeboteList,
    PuxPageDetails,
    PuxTranslate,
  },
  setup() {
    const {
      getHttp,
    } = HttpAPI();

    return {
      getHttp,
    };
  },
  data() {
    return {
      loading: true,
      programm: {},
      panelOptions: {
        label: "_LBL_PROGRAMME_DETAILS_FOERDERPROGRAMM_",
        icon: "glyphicon-file",
        active: true,
      },
    };
  },
  computed: {
    programmUrl() {
      return `foerderprogramme/${ this.$stateParams.id }/`;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getHttp({
        url: this.programmUrl,
      }).then(
        response => {
          this.programm = response;
        }
      ).then(
        () => this.loading = false
      );
    },

    updateProgramm({ programm }) {
      this.programm = programm;
    },
  },
};
