import Modal from "../../../../../global/components/Modal/Modal.vue";
import WizardStepsErrorsMixin from "../WizardStepsErrorsMixin";
import WizardStepsRechnerMixin from "../WizardStepsRechnerMixin";
import {
  FilterCurrencyMixin,
  FilterDateMixin,
} from "../../../../../global/mixins/FiltersMixin";
import {
  cloneDeep,
  filter,
  forEach,
  isString,
  isPlainObject,
} from "lodash-es";

// @vue/component
export default {
  components: {
    Modal,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterDateMixin,
    WizardStepsErrorsMixin,
    WizardStepsRechnerMixin,
  ],
  props: {
    perioden: {
      type: Array,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    statusPeriodenAusgefuellt: {
      type: Boolean,
      required: true,
    },
    initModelInParent: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isDisabledButtons: undefined,
      currentPeriod: undefined,
      currentPeriodGroup: undefined,
      currentIndex: undefined,
      confirmOptions: undefined,
      modalUpdate: undefined,
      modalTeilen: undefined,
      modalConfirm: undefined,
      errorLabels: {
        gehalt: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_FOERDERFAEHIGES_",
        von: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_BEGINN_",
        bis: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_ENDE_",
      },
    };
  },
  computed: {
    getErrorLabel() {
      return errorKey => {
        const DATE = this.filterDate;
        const INFO = this.model.perioden[errorKey].info;
        return `${ DATE(INFO.von_aktuell) } - ${ DATE(INFO.bis_aktuell) }`;
      };
    },

    getErrorLabelKey() {
      return errorKey => this.errorLabels[errorKey];
    },

    isString() {
      return error => isString(error);
    },

    isPlainObject() {
      return error => isPlainObject(error);
    },

    getInfoForBruttoGehalt() {
      return {
        PKT_PAUSCHALE: this.getPktPauschaleNumber,
        STELLENUMFANG_PROJEKT: this.model.stellenumfang_projekt || 0,
        VOLLZEITSTELLE: this.model.vollzeitstelle,
      };
    },
  },
  methods: {
    openEditMode({ period, index }) {
      this.currentPeriod = period;
      this.currentPeriodGroup = this.getCurrentPeriodGroup({ period });
      this.currentIndex = index;
      this.modalUpdate = true;
    },

    getCurrentPeriodGroup({ period }) {
      return filter(cloneDeep(this.model.perioden), per => per.periode === period.periode);
    },

    confirmDelete({ period, index }) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_CONFIRM_DELETE_HEADER_{{von}}_{{bis}}_",
        msg: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_CONFIRM_DELETE_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => {
          this.deletePeriod({ period, index });
        },
        cancelCallback: this.closeConfirm,
        loading: false,
        extra: {
          von: this.filterDate(period.von),
          bis: this.filterDate(period.bis),
        },
      };
      this.modalConfirm = true;
    },

    deletePeriod({ period, index }) {
      const PERIODE_TEIL_WITH_CURRENT = [];
      forEach(this.model.perioden, per => {
        if (per.info.von_aktuell === period.info.von_aktuell) {
          PERIODE_TEIL_WITH_CURRENT.push(per);
        }
      });

      if (PERIODE_TEIL_WITH_CURRENT.length === 2) {
        forEach(this.model.perioden, per => {
          if (per.info.von_aktuell === period.info.von_aktuell) {
            per.info.teil = false;
          }
        });
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.model.perioden.splice(index, 1);
      this.closeConfirm();
    },

    closeConfirm() {
      this.modalConfirm = false;
    },

    sharePeriod({ period, index }) {
      this.currentPeriod = period;
      this.currentPeriodGroup = this.getCurrentPeriodGroup({ period });
      this.currentIndex = index;
      this.modalTeilen = true;
    },

    setErrorsForCurrentStep({ errors }) {
      const ERRORS = {};
      forEach(errors, (error, key) => {
        if (key === "perioden" || key === "__all__") {
          ERRORS[key] = error;
        }
      });
      return ERRORS;
    },
  },
};
