import Wizard from "../../../global/components/Wizard/Wizard.vue";
import WizardStep1 from "./WizardSteps/WizardStep1/WizardStep1.vue";
import WizardStep2 from "./WizardSteps/WizardStep2/WizardStep2.vue";
import WizardStep3 from "./WizardSteps/WizardStep3/WizardStep3.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";
import WizardSteps from "./WizardSteps";
import {
  toRequestBody,
  toFormElementFromParameter,
} from "../../../global/functions/mappingForParameterToFormElement";

import { gettext } from "../../../global/functions/utils";
import {
  cloneDeep,
  filter,
  forEach,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "DokumenteSuchen",
  components: {
    PuxPageDetails,
    PuxTranslate,
    Wizard,
    WizardStep1,
    WizardStep2,
    WizardStep3,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
  ],
  data() {
    return {
      wizardSteps: cloneDeep(WizardSteps),
      stepActive: 0,
      stepsVisited: {
        0: true,
        1: false,
        2: false,
      },
      booleanPropsWizard: {
        backTargetStepDisabled: undefined,
        backStepHide: undefined,
        backStepDisabled: undefined,
        forwardStepHide: undefined,
        forwardStepDisabled: true,
        buttonsLoading: false,
        closeButtonShow: true,
      },
      wizardOptions: {
        forwardStepButtonSuffix: undefined,
      },
      loading: true,
      model: {
        kontext: undefined,
      },
      modelStep2: {

      },
      syConfig: {
        sitzungsPDF: undefined,
        dokartSuche: undefined,
      },

      dokumentPruefStatus: undefined,
      dokarten: undefined,
      kontextInfo: {},
      kontexte: [],
      filteredDokarten: [],
      timer: undefined,
      urlParamsStep3: undefined,
      step2FormElementOptions: undefined,
    };
  },
  computed: {
    sucheFormularUrl() {
      return `dokumente/${ this.model.kontext }/suchformular/`;
    },

    countUrl() {
      return `dokumente/${ this.model.kontext }/count/`;
    },

    getKontext() {
      if (this.model.kontext) {
        return this.kontextInfo[this.model.kontext];
      }
      return undefined;
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.iniSyConfigs();
      this.loadData();
    },

    loadData() {
      Promise.all([
        this.getHttp({
          url: "dokumentpruefstatus/",
        }),
        this.getHttp({
          url: "katalog/",
          urlParams: {
            key: "dokarten",
          },
        }),
        this.getHttp({
          url: "katalog/",
          urlParams: {
            key: "aktive_dokumentmodule",
          },
        }),
      ]).then(
        responses => {
          this.dokumentPruefStatus = responses[0];
          this.dokarten = responses[1];
          this.initKontexte({ response: responses[2] });
        }
      ).then(
        () => {
          this.loading = false;
        }
      );
    },

    initKontexte({ response }) {
      const KONTEXT_INFO = {};
      this.kontexte = filter(response, kontext => kontext.searchable);
      forEach(this.kontexte, kontext => {
        KONTEXT_INFO[kontext.pk] = kontext;
      });
      this.kontextInfo = KONTEXT_INFO;
    },

    iniSyConfigs() {
      const SY_CONFIGS_SITZUNGSANTRAEGE = this.getSyConfigsValue("sitzungsantraege");
      if (SY_CONFIGS_SITZUNGSANTRAEGE) {
        this.syConfig.sitzungsPDF = SY_CONFIGS_SITZUNGSANTRAEGE.default_dokart;
      }
      this.syConfig.dokartSuche = this.getSyConfigsValue("dok_suche");
    },

    goOneStepForward() {
      const NUMBER_STEP_TO_GO = this.stepActive + 2;
      if (isFunction(this[`goForwardToStep${ NUMBER_STEP_TO_GO }`])) {
        this[`goForwardToStep${ NUMBER_STEP_TO_GO }`]();
      }
    },

    goForwardToStep2() {
      this.booleanPropsWizard.buttonsLoading = true;
      this.getHttp({
        url: this.sucheFormularUrl,
      }).then(
        response => {
          this.filteredDokarten = this.dokarten.filter(dar => this.syConfig.dokartSuche[`${ this.model.kontext }_dokarten`].indexOf(dar.pk) !== -1);
          this.step2FormElementOptions = toFormElementFromParameter({ obj: response }).list;
          this.modelStep2 = {};
          this.goNextStep();
          this.booleanPropsWizard.buttonsLoading = false;
        }
      );
    },

    goForwardToStep3() {
      const DATA = toRequestBody({ model: this.modelStep2 });
      if (DATA.antrags_nummer) {
        DATA.antrags_nummer += "";
        DATA.antrags_nummer = DATA.antrags_nummer.replace(/\s+/g, "");
      }
      if (DATA.dok_angefuegt_am_after) {
        DATA.dok_angefuegt_am_von = DATA.dok_angefuegt_am_after;
        DATA.dok_angefuegt_am_after = undefined;
      }
      if (DATA.dok_angefuegt_am_before) {
        DATA.dok_angefuegt_am_bis = DATA.dok_angefuegt_am_before;
        DATA.dok_angefuegt_am_before = undefined;
      }
      this.urlParamsStep3 = DATA;
      this.goNextStep();
    },

    goNextStep() {
      this.stepActive += 1;
      this.stepsVisited[this.stepActive] = true;
    },

    goOneStepBack() {
      if (isFunction(this[`goBackToStep${ this.stepActive }`])) {
        this[`goBackToStep${ this.stepActive }`]();
      }
    },

    goBackToStep2() {
      this.stepActive -= 1;
      this.stepsVisited[2] = false;
    },

    goBackToStep1() {
      this.wizardOptions.forwardStepButtonSuffix = undefined;
      this.stepActive -= 1;
      this.stepsVisited[1] = false;
    },

    updateModelKontext({ model }) {
      this.model.kontext = model;
      this.booleanPropsWizard.forwardStepDisabled = false;
    },

    initModelFromChild({ model }) {
      this.modelStep2 = model;
    },

    changeModelFromStep2() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getCountDokumente();
      }, 1000);
    },

    getCountDokumente() {
      this.booleanPropsWizard.buttonsLoading = true;
      const DATA = toRequestBody({ model: this.modelStep2 });
      if (DATA.antrags_nummer) {
        DATA.antrags_nummer += "";
        DATA.antrags_nummer = DATA.antrags_nummer.replace(/\s+/g, "");
      }
      if (DATA.dok_angefuegt_am_after) {
        DATA.dok_angefuegt_am_von = DATA.dok_angefuegt_am_after;
        DATA.dok_angefuegt_am_after = undefined;
      }
      if (DATA.dok_angefuegt_am_before) {
        DATA.dok_angefuegt_am_bis = DATA.dok_angefuegt_am_before;
        DATA.dok_angefuegt_am_before = undefined;
      }

      this.getHttp({
        url: this.countUrl,
        urlParams: DATA,
      }).then(
        response => {
          this.wizardOptions.forwardStepButtonSuffix = `(${ response } ${ gettext("_TXT_TREFFER_") })`;
          this.booleanPropsWizard.buttonsLoading = false;
        }
      );
    },
  },
};
