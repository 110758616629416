"use strict";
import angular from "angular";

import coreModels from "dias/core/models";
import TaskLog from "./tasklog.service";

const modul = angular.module("dias.tasklog.models", [
  "restangular",
  coreModels.name,
])

  .provider("TaskLog", TaskLog)
;


export default modul;
