"use strict";

import angular from "angular";

import DiasConfirm from "dias/core/confirm";
import DiasLoading from "dias/core/loading";
import DiasNotification from "dias/core/notification";

import services from "dias/workflow/services";
import functionCls from "./function";

const modul = angular.module("standard_workflowmodule.einreichen_bestaetigung", [
  DiasConfirm.name,
  DiasLoading.name,
  DiasNotification.name,
  services.name
])

  .config(/*@ngInject*/WorkflowfunktionRegistryProvider => {
    WorkflowfunktionRegistryProvider.register("einreichen_bestaetigung", functionCls);
  });


export default modul;
