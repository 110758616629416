import KAufgabeModalCreate from "../KAufgabeModalCreate/KAufgabeModalCreate.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "kaufgabe";

// @vue/component
export default {
  name: "KAufgabeList",
  components: {
    KAufgabeModalCreate,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [
          {
            label: "_LBL_KAUFGABE_LIST_KURZBEZEICHNUNG_",
            id: "au_kbez",
            sortable: true,
            sortId: "au_kbez",
            linkTo: {
              url: "/aufgabenvorlagen/{{pk}}/",
            },
            translate: true,
          },
          {
            label: "_LBL_KAUFGABE_LIST_KURZBEZEICHNUNG_ADMINISTRATION_",
            id: "au_kbez_admin",
            sortable: true,
            sortId: "au_kbez_admin",
            linkTo: {
              url: "/aufgabenvorlagen/{{pk}}/",
            },
            translate: true,
          },
          {
            label: "_LBL_KAUFGABE_LIST_KURZBEZEICHNUNG_BEOBACHTER_",
            id: "au_kbezbeobachter",
            sortable: true,
            sortId: "au_kbezbeobachter",
            translate: true,
          },
          {
            label: "_LBL_KAUFGABE_LIST_KURZBEZEICHNUNG_DETAILIERT_",
            id: "au_kbezdetail",
            sortable: true,
            sortId: "au_kbezdetail",
            translate: true,
          },
          {
            label: "_LBL_KAUFGABE_LIST_ERSTE_FRIST_",
            id: "frist",
            slot: "frist",
            translate: true,
          },
          {
            label: "_LBL_KAUFGABE_LIST_TERMIN_SICHTBAR_",
            id: "au_termin_sichtbar",
            hide: true,
            filter: "boolean",
            translate: true,
          },
          {
            label: "_LBL_KAUFGABE_LIST_BEARBEITUNGSENDE_",
            id: "au_ende",
            hide: true,
            filter: "boolean",
            translate: true,
          },
          {
            label: "_LBL_KAUFGABE_LIST_PASSIV_",
            id: "au_passiv",
            hide: true,
            filter: "boolean",
            translate: true,
          },
          {
            label: "_LBL_KAUFGABE_LIST_ART_TERMINBERECHNUNG_",
            id: "terminberechnung_bez",
            hide: true,
            translate: true,
          },
        ],
        rowActions: [
          {
            label: "_LBL_K_AUFGABE_TABLE_ROW_ACTIONS_SHOW_DETAILS_",
            route: "root.kaufgaben.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [
          {
            permission: "kaufgabe.create",
            label: "_LBL_K_AUFGABE_TABLE_ACTIONS_CREATE_",
            callback: () => this.modal.create = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "kaufgabe_create",
          },
        ],
        label: "_LBL_KAUFGABE_LIST_AUFGABEN_VORLAGEN_",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
        translate: true,
      },
      filters: [
        {
          type: "text",
          id: "suche",
          label: "_LBL_KAUFGABE_LIST_KURZBEZEICHNUNG_IN_ADMINISTRATION_",
          translate: true,
          main: true,
        },
        {
          type: "text",
          id: "au_kbezbeobachter",
          label: "_LBL_KAUFGABE_LIST_KURZBEZEICHNUNG_BEOBACHTER_",
          translate: true,
        },
        {
          type: "text",
          id: "au_kbezdetail",
          label: "_LBL_KAUFGABE_LIST_KURZBEZEICHNUNG_DETAILIERT_",
          translate: true,
        },
        {
          type: "boolean",
          id: "au_termin_sichtbar",
          label: "_LBL_KAUFGABE_LIST_TERMIN_SICHTBAR_",
          translate: true,
        },
        {
          type: "boolean",
          id: "au_ende",
          label: "_LBL_KAUFGABE_LIST_BEARBEITUNGSENDE_",
          translate: true,
        },
        {
          type: "boolean",
          id: "au_passiv",
          label: "_LBL_KAUFGABE_LIST_PASSIV_",
          translate: true,
        },
        {
          type: "multiselect",
          id: "terminberechnung",
          label: "_LBL_KAUFGABE_LIST_ART_TERMINBERECHNUNG_",
          url: "katalog/?fields=id&fields=bez&key=syterminberechnung",
          keyLabel: "bez",
          keyId: "id",
          search: true,
          searchList: ["bez"],
          translate: true,
        },
      ],
      modal: {
        create: undefined,
      },
    };
  },
  methods: {
    closeModalCreate() {
      this.modal.create = false;
    },
  },
};
