import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin.js";

import {
  cloneDeep,
  orderBy,
  forEach,
  find,
  findIndex,
  mapValues,
} from "lodash-es";

// @vue/component
export default {
  name: "ProfilDetailsKontakt",
  components: {
    FormstepDetail,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
    updateNutzer: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      kommtypen: [],
      loading: false,
      listForFormstepDetail: [],
    };
  },
  computed: {
    optionsForFormstepDetail() {
      return {
        label: "_TXT_PROFIL_DETAILS_KONTAKT_FORMSTEP_NAME_",
        required: true,
        saveCallback: this.save,
        list: this.listForFormstepDetail,
      };
    },

    saveUrl() {
      return `profil/${ this.nutzer.pk }/profil_kommdaten_aendern/`;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.getListHttp({
        url: "kommunikationstypen/",
      }).then(
        response => {
          this.kommtypen = orderBy(cloneDeep(response), ["kot_pos"]);
        }
      ).then(
        () => {
          this.initOptionsList();
          this.initModel();
          this.loading = false;
        }
      );
    },

    initOptionsList() {
      const LIST_FOR_FORMSTEP_DETAIL = [];
      forEach(this.kommtypen, item => {
        LIST_FOR_FORMSTEP_DETAIL.push({
          type: "text",
          label: item.kot_kbez,
          view: "v",
          id: item.kot_id,
          showLabel: true,
          required: item.kot_pflicht,
          showEmpty: "-",
          helpText: item.kot_hilfetext,
          hideHelpTextReadOnly: true,
          autocomplete: item.kot_autocomplete,
        });
      });
      LIST_FOR_FORMSTEP_DETAIL[0].autoFocus = true;
      this.listForFormstepDetail = LIST_FOR_FORMSTEP_DETAIL;
    },

    initModel() {
      const MODEL = {};
      forEach(this.kommtypen, item => {
        const MATCH = find(this.nutzer.kommunikationsdaten, data => data.kommtyp.kot_id === item.kot_id);
        MODEL[item.kot_id] = MATCH && MATCH.nkm_wert ? MATCH.nkm_wert : undefined;
      });
      this.model = MODEL;
    },

    save({ model }) {
      const NUTZER = this.prepareDataForSave({ model });
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.saveUrl,
          data: NUTZER,
        }).then(
          response => {
            NUTZER.kommunikationsdaten = response.kommunikationsdaten;
            this.updateNutzer({ nutzer: NUTZER });
            this.addNotification({
              text: "_MSG_PROFIL_DETAILS_UPDATE_SUCCESS_",
            });
            setTimeout(() => {
              this.setModel();
            });
            resolve();
          },
          error => {
            if (error && error.data && error.data.kommunikationsdaten) {
              forEach(error.data.kommunikationsdaten, item => {
                error.data = { ...error.data, ...item };
              });
              error.data.kommunikationsdaten = undefined;
            }
            reject(error);
          }
        );
      });
    },

    prepareDataForSave({ model }) {
      const NUTZER = cloneDeep(this.nutzer);
      const MODEL = cloneDeep(model);
      forEach(this.kommtypen, item => {
        const INDEX = findIndex(NUTZER.kommunikationsdaten, data => data.kommtyp.kot_id === item.kot_id);
        if (INDEX !== -1) {
          NUTZER.kommunikationsdaten[INDEX].nkm_wert = MODEL[item.kot_id];
        } else {
          NUTZER.kommunikationsdaten.push({
            nkm_id: "",
            nkm_wert: MODEL[item.kot_id] || null,
            kommtyp: item,
          });
        }
      });
      return NUTZER;
    },

    setModel() {
      const MODEL = mapValues(this.model, () => undefined);
      forEach(this.nutzer.kommunikationsdaten, item => {
        MODEL[item.kommtyp.kot_id] = item.nkm_wert;
      });
      this.model = MODEL;
    },
  },
};
