"use strict";

import angular from "angular";
import AutoFocusDirective from "./autofocus.directive";
import AutoFocusForDiasSelectDirective from "./autofocusForDiasSelect.directive";
import TitleForDiasSelectDirective from "./titleForDiasSelect.directive";

const diasAutoFocus = angular.module("dias.autofocus", [])

  .directive("autoFocus", AutoFocusDirective)
  .directive("autoFocusForDiasSelect", AutoFocusForDiasSelectDirective)
  .directive("titleForDiasSelect", TitleForDiasSelectDirective);

export default diasAutoFocus;
