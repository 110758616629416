"use strict";

import BaseModel from "dias/core/models/baseModel";


class BackgroundBenachrichtigungen extends BaseModel {
  constructor() {
    super("inbox");
  }

  onError(response) {
    console.warn("Error " + response.status + " on get Benachrichtigungen");
  }
}

export default BackgroundBenachrichtigungen;
