import AntragCreateExternZuschuss from "../../AntragCreateExtern/AntragCreateExternZuschuss/AntragCreateExternZuschuss.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxGet from "../../../../global/components/PuxGet/PuxGet.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import Wizard from "../../../../global/components/Wizard/Wizard.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragAngebotChangeWizard",
  components: {
    AntragCreateExternZuschuss,
    FormElement,
    FormstepDetail,
    PuxAlert,
    PuxButton,
    PuxCloak,
    PuxGet,
    PuxTranslate,
    Wizard,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
  ],
  props: {
    antragUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "closeWizard",
  ],
  data() {
    return {
      booleanPropsWizard: {
        backTargetStepDisabled: undefined,
        backStepHide: undefined,
        backStepDisabled: undefined,
        forwardStepHide: undefined,
        forwardStepDisabled: true,
        buttonsLoading: false,
        closeButtonShow: true,
      },
      loadingRSListe: true,
      loadingRSWechselResult: undefined,
      loadingWechselPreview: undefined,
      optionsWizard: {
        closeButtonLabel: "_BTN_CLOSE_",
        closeButtonTitle: "_BTN_CLOSE_",
      },
      regelsaetze: [],
      regelsatzWechselPreview: undefined,
      regelsatzWechselResult: {},
      stepActive: 0,
      stepsVisited: {
        0: true,
        1: true,
        2: true,
      },
      wizardSteps: [
        {
          label: "_TXT_ANTRAG_ANGEBOT_WAEHLEN_",
          slotId: "step1",
          title: "_TXT_ANTRAG_ANGEBOT_WAEHLEN_TITLE_",
        },
        {
          label: "_TXT_ANTRAG_WECHSEL_BESTAETIGEN_",
          slotId: "step2",
          title: "_TXT_ANTRAG_WECHSEL_BESTAETIGEN_TITLE_",
        },
        {
          label: "_TXT_ANTRAG_ERGEBNIS_",
          slotId: "step3",
          title: "_TXT_ANTRAG_ERGEBNIS_TITLE_",
        },
      ],
      zielregelsatz: undefined,
    };
  },
  computed: {
    monetaere_foerderung() {
      return this.getSyConfigsValue("monetaere_foerderung");
    },

    rswechselRegelsaetzeUrl() {
      return `${ this.antragUrl }rswechsel_regelsaetze/`;
    },

    rswechselVorschauUrl() {
      return `${ this.antragUrl }rswechsel_vorschau/`;
    },

    rswechselDurchfuehrenUrl() {
      return `${ this.antragUrl }rswechsel_durchfuehren/`;
    },

    regelsatzOptions() {
      return {
        type: "select",
        label: "_TXT_NEUES_FOERDERANGEBOT_",
        required: true,
        view: "v-alt",
        keyId: "pk",
        keyLabel: "bez",
        search: true,
        searchList: ["bez"],
        data: this.regelsaetze,
        change: this.onChangeZielregelsatz,
        placeholder: "_TXT_WAEHLEN_ANGEBOT_PLACEHOLDER_",
      };
    },

    zielregelsatzZuschuss() {
      return get(this.regelsatzWechselPreview, "zielregelsatz_obj.zuschuss");
    },

    zielregelsatzKosten() {
      return get(this.regelsatzWechselPreview, "zielregelsatz_obj.kosten");
    },

    maxDurchfuehrungsdauer() {
      return get(this.regelsatzWechselPreview, "zielregelsatz_obj.durchfuehrungszeitraum.max_durchfuehrungsdauer");
    },

    durchfuehrungsdauerTextTranslate() {
      return this.maxDurchfuehrungsdauer ?
        "_TXT_MAX_DURCHFUEHRUNGSDAUER_{{max_durchfuehrungsdauer}}_" :
        "_TXT_OHNE_EINSCHRAENKUNG_";
    },

    durchfuehrungsdauerExtraTranslate() {
      return {
        max_durchfuehrungsdauer: this.maxDurchfuehrungsdauer,
      };
    },

    arsKbezExtraTranslate() {
      return {
        ars_kbez: get(this.regelsatzWechselPreview, "zielregelsatz_obj.ars_kbez"),
      };
    },

    typeAlert() {
      return this.isAngebotChangedSuccess ?
        "success" :
        "danger";
    },

    textAlert() {
      return this.isAngebotChangedSuccess ?
        "_TXT_WECHSELN_ANGEBOT_DURCHGEFUEHRT_SUCCESS_" :
        "_TXT_WECHSELN_ANGEBOT_DURCHGEFUEHRT_ERROR_";
    },

    isAngebotChangedSuccess() {
      return this.regelsatzWechselResult.status === 1;
    },
  },
  created() {
    this.loadRegelsaetze();
  },
  methods: {
    loadRegelsaetze() {
      this.getHttp({
        url: this.rswechselRegelsaetzeUrl,
      }).then(
        response => {
          this.regelsaetze = response;
          this.loadingRSListe = false;
        }
      );
    },

    goOneStepForward() {
      if (this.stepActive === 1) {
        this.performRSWechsel();
      }
      this.stepActive++;
    },

    goOneStepBack() {
      this.stepActive--;
    },

    performRSWechsel() {
      this.loadingRSWechselResult = true;
      this.booleanPropsWizard.backStepDisabled = true;
      this.booleanPropsWizard.backTargetStepDisabled = true;
      this.postHttp({
        url: this.rswechselDurchfuehrenUrl,
        data: {
          zielregelsatz: this.zielregelsatz,
        },
      }).then(
        response => {
          this.regelsatzWechselResult = response;
          this.zielregelsatz = undefined;
          if (this.regelsatzWechselResult.status !== 1) {
            this.booleanPropsWizard.backStepDisabled = false;
            this.booleanPropsWizard.backTargetStepDisabled = false;
          }
        }
      ).finally(() => this.loadingRSWechselResult = false);
    },

    onChangeZielregelsatz() {
      if (this.zielregelsatz) {
        this.loadingWechselPreview = true;
        this.getHttp({
          url: this.rswechselVorschauUrl,
          urlParams: {
            zielregelsatz: this.zielregelsatz,
          },
        }).then(
          response => {
            this.regelsatzWechselPreview = response;
            this.booleanPropsWizard.forwardStepDisabled = false;
          }
        ).finally(() => {
          this.loadingWechselPreview = false;
        });
      } else {
        this.booleanPropsWizard.forwardStepDisabled = true;
      }
    },

    closeWizard() {
      this.$emit("closeWizard", {
        statusReload: this.isAngebotChangedSuccess,
      });
    },
  },
};
