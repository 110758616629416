import AngularLink from "../../../../../../../libs/vue/client/vue/global/components/AngularLink/AngularLink.vue";
import FormstepDetail from "../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";
import PuxGet from "../../../../../../../libs/vue/client/vue/global/components/PuxGet/PuxGet.vue";

import {
  FilterDateMixin,
} from "../../../../../../../libs/vue/client/vue/global/mixins/FiltersMixin";

import KAkkreditierungsstatusUUIDs from "../../../../../../../libs/vue/client/vue/const/KAkkreditierungsstatusUUIDs";
import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "AkkreditierteStudiengangDetailsOverview",
  components: {
    AngularLink,
    FormstepDetail,
    PuxGet,
  },
  mixins: [
    FilterDateMixin,
  ],
  props: {
    studiengang: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optionsForFormstepDetail: {
        hideHeader: true,
      },
    };
  },
  computed: {
    isStatusAktuelleAkkreditierungAkkreditiertMitAuflagen() {
      return [
        KAkkreditierungsstatusUUIDs.AKKREDITIERT_MIT_AUFLAGEN,
        KAkkreditierungsstatusUUIDs.AKKREDITIERT_AUFLAGEN_ERFUELLT,
        KAkkreditierungsstatusUUIDs.AKKREDITIERT_AUFLAGEN_TEILW_ERFUELLT
      ].indexOf(this.statusAktuelleAkkreditierung) !== -1;
    },

    isAuflagenerfuellungKommentarAndStatusTeilweiseErfuellt() {
      return this.auflagenerfuellungKommentarAktuelleAkkreditierung &&
        this.statusAktuelleAkkreditierung === KAkkreditierungsstatusUUIDs.AKKREDITIERT_AUFLAGEN_TEILW_ERFUELLT;
    },

    isStatusAktuelleAkkreditierungNegativ() {
      return this.statusAktuelleAkkreditierung === KAkkreditierungsstatusUUIDs.NICHT_AKKREDITIERT;
    },

    statusAktuelleAkkreditierung() {
      return get(this.studiengang, "aktuelle_akkr.status");
    },

    auflagenerfuellungKommentarAktuelleAkkreditierung() {
      return get(this.studiengang, "aktuelle_akkr.pro_auflagenerfuellung_kommentar");
    },

    bewertungAktuelleAkkreditierung() {
      return get(this.studiengang, "aktuelle_akkr.pro_bewertung");
    },

    profilAktuelleAkkreditierung() {
      return get(this.studiengang, "aktuelle_akkr.pro_profil");
    },

    turnusInfoAktuelleAkkreditierung() {
      return get(this.studiengang, "aktuelle_akkr.pro_turnus_info");
    },

    jointDegreeAktuelleAkkreditierung() {
      return get(this.studiengang, "aktuelle_akkr.pro_joint_degree");
    },

    pkAktuelleAkkreditierung() {
      return get(this.studiengang, "aktuelle_akkr.pk");
    },
  },
  methods: {
    getDateForAkkr(akkr) {
      return akkr.status === KAkkreditierungsstatusUUIDs.NICHT_AKKREDITIERT
        ? `${ this.filterDate(akkr.pro_entscheidungsdatum) }`
        : `${ this.filterDate(akkr.pro_von) } - ${ this.filterDate(akkr.pro_bis) }`;
    }
  },
};
