import PuxTranslate from "../PuxTranslate/PuxTranslate.vue";
import TableDetailsRight from "./TableDetailsRight/TableDetailsRight.vue";
import TableFilterCenter from "./TableFilterCenter/TableFilterCenter.vue";
import TableFilterRight from "./TableFilterRight/TableFilterRight.vue";
import TableFilterTop from "./TableFilterTop/TableFilterTop.vue";
import TableMassActionComponents from "./TableMassActionComponents";
import TablePagination from "./TablePagination/TablePagination.vue";
import TablePanelActions from "./TablePanelActions/TablePanelActions.vue";
import TableViewTable from "./TableViewTable/TableViewTable.vue";
import TableViewSymbol from "./TableViewSymbol/TableViewSymbol.vue";

import translate from "../../directives/translate";

import HttpMixin from "../../mixins/HttpMixin";
import NotificationMixin from "../../mixins/NotificationMixin";
import PermissionMixin from "../../mixins/PermissionMixin";
import SyConfigMixin from "../../mixins/SyConfigMixin";


import GlobalOptions from "../../const/GlobalOptions";
import {
  getUrlParams,
  setUrlWithParams
} from "../../functions/help";
import {
  EventBus,
} from "../../functions/event-bus";

// import {
//   initColsFromSyConfig,
//   initFiltersFromSyConfig
// } from "./TableColsUndFiltersFromSyConfig";  // todo : Verzeichnis muss noch gelöscht werden.
import moment from "moment";
import {
  assign,
  assignIn,
  camelCase,
  cloneDeep,
  concat,
  filter,
  find,
  findIndex,
  forEach,
  get,
  isArray,
  isFunction,
  isNil,
  isNull,
  isObject,
  isString,
  isUndefined,
  map,
  max,
  min,
  orderBy,
  sortBy,
  toString,
  values,
} from "lodash-es";

import { createNamespacedHelpers } from "vuex";
const {
  mapMutations,
  mapGetters
} = createNamespacedHelpers("table");

// @vue/component
export default {
  name: "SmartTable",
  components: {
    PuxTranslate,
    TableDetailsRight,
    TableFilterCenter,
    TableFilterRight,
    TableFilterTop,
    TablePagination,
    TablePanelActions,
    TableViewTable,
    TableViewSymbol,
    ...TableMassActionComponents,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    objects: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    disabledObj: {
      type: Object,
      default: () => ({}),
    },
    labelTag: {
      type: String,
      required: false,
      default: "h2",
    },
    labelClass: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: [
    "loadData",
  ],
  data() {
    return {
      idLabel: GlobalOptions.tableOptions.idLabel,
      optionsClone: {},
      blockFirstLoad: undefined,
      dataLoaded: false,
      colsInitModel: [],
      colsInitModelDefault: [],
      model: {
        cols: [],
      },
      colsClone: undefined,
      counter: {
        current: undefined,
        total: undefined,
      },

      filter: {
        ordering: null,
      },
      filterModel: {},
      filtersClone: [],
      hideFilters: undefined,
      massActionConfig: undefined,
      massActionConfigActive: undefined,
      massActionSelectorClose: undefined,
      rows: [],
      sortOptions: {
        id: undefined,
        asc: undefined,
        list: [],
      },
      urlParams: {},
      status: {
        firstLoad: true,
        init: true,
        configuratorInit: undefined,
        configuratorLoading: undefined,
        loading: true,
        paginationLoading: true,
        user: undefined,
        tableCollapsed: undefined,
        filtersInit: undefined,
        filtersInitAsync: undefined,
        loadKonfigurationCollsAndFilters: true,
      },
      statusShowInfoHeader: undefined,
      csv: {
        url: "",
        status: undefined,
        csv_max_entries: undefined,
        title: "",
      },
      workflowfolgen: [],
      urlMassAktionsParameter: undefined,
      filterPrioritization: undefined, // 1) Url 2) Session 3) Default
      // Symbole
      currentIndexShow: undefined,
      currentRowIndex: undefined,
      currentRowPk: undefined,

      // Details
      detailsObj: {},
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.optionsClone.id }_table_details`;
    },

    getSlotNameTableDetailsHeader() {
      return `${ this.optionsClone.id }_table_details_header`;
    },

    getSlotNameSymbol() {
      return `${ this.optionsClone.id }_symbol`;
    },

    getSlotNameSymbolDetails() {
      return `${ this.optionsClone.id }_symbol_details`;
    },

    getSlotsColls() {
      return filter(this.colsClone, col => col.slot);
    },

    ...mapGetters([
      "GET_CONFIG",
    ]),
  },
  created() {
    setTimeout( // TODO Ilia. Das ist nur Workaround für Angular-routing
      () => {
        this.initOptions();
        this.MUT_CREATE_TABLE_BY_ID(this.optionsClone.id);
        this.status.filtersInit = true;
        this.initEventBus();
        this.loadKonfigurationCollsAndFilters();
      }
    );
  },
  beforeUnmount() {
    this.MUT_REMOVE_TABLE_BY_ID(this.optionsClone.id);
    EventBus.$off(`updateTable${ this.optionsClone.id }`, this.updateData);
    EventBus.$off(`updateRow${ this.optionsClone.id }`, this.updateRow);
    EventBus.$off(`addRow${ this.optionsClone.id }`, this.addRow);
    EventBus.$off(`deleteRow${ this.optionsClone.id }`, this.deleteRow);
  },
  methods: {
    toggleTableCollapse(status) {
      if (isUndefined(status)) {
        this.status.tableCollapsed = !this.status.tableCollapsed;
        return;
      }
      if (status) {
        this.status.tableCollapsed = false;
        setTimeout(() => {
          this.status.tableCollapsed = true;
        });
      } else {
        this.status.tableCollapsed = false;
      }
    },

    initEventBus() {
      EventBus.$on(`updateTable${ this.optionsClone.id }`, this.updateData);
      EventBus.$on(`updateRow${ this.optionsClone.id }`, this.updateRow);
      EventBus.$on(`addRow${ this.optionsClone.id }`, this.addRow);
      EventBus.$on(`deleteRow${ this.optionsClone.id }`, this.deleteRow);
    },

    updateRow({ row, index }) {
      if (this.rows[index]) {
        this.rows.splice(index, 1, row);
        this.replaceDetailsObj(row);
      }
    },

    addRow({ row }) {
      this.rows.unshift(row);
      this.counter.total++;
    },

    deleteRow({ index }) {
      this.rows.splice(index, 1);
      this.counter.total--;
    },

    changeUrlParams(model) {
      if (!this.optionsClone.urlUpdate) {
        return;
      }
      // const { pathname } = window.location;
      const url = setUrlWithParams({ params: model });

      this.MUT_CHANGE_URL_GET_PARAMS({ tableId: this.optionsClone.id, url });
      if (this.$locationReplace) {
        this.$locationReplace(url);
      } else if (this.$statusNotAngular) {
        let currentPath = this.$router.currentRoute.value.href;
        if (currentPath.indexOf("/") === 0) {
          currentPath = currentPath.slice(1);
        }
        currentPath = currentPath.split("?")[0];
        history.replaceState({}, "", `${ this.$router.options.base || "/" }${ currentPath }?${ url }`);
        // this.$router.replace(`${ this.$router.currentRoute.path }?${ url }`);
      }
    },

    changeView(name) {
      this.optionsClone.view.default = name;
    },

    checkUser() {
      this.checkUserCallback(this.isAuthenticatedSinc());
    },

    checkUserCallback(isAuthenticated) {
      if (isAuthenticated) {
        this.status.user = true;
        this.optionsClone.user = true;
        this.loadTableConfig();
      } else {
        this.initColsModel();
        this.updateData();
      }
    },

    replaceStringParameterToObject({ urlParams = {}, model }) {
      const PARAMS = {};
      forEach(cloneDeep(urlParams), (item, key) => {
        if (isObject(model[key]) && isString(item)) {
          PARAMS[key] = cloneDeep(model[key]);
        } else {
          PARAMS[key] = item;
        }
      });
      return PARAMS;
    },

    getFilterAndPagination(model) {
      let params;
      if (this.status.firstLoad && this.filterPrioritization === "Url") {
        params = this.replaceStringParameterToObject({ urlParams: this.urlParams, model });
        this.status.firstLoad = false;
      } else {
        params = cloneDeep(model) || {};
      }

      // GlobalOptions.tableOptions. Wenn nicht offset und limit
      let limit;
      let offset;
      forEach(this.filter, (item, key) => {
        if (key === "limit") {
          limit = item;
        } else if (key === "offset") {
          offset = item;
        } else if (key === "ordering") {
          params.ordering = item;
          if (!this.optionsClone.sortable || !item) {
            delete params.ordering;
          }
        }
      });
      if (!isNil(limit)) {
        delete params.limit;
        params[GlobalOptions.tableOptions.pagination.limitName] = limit;
      }
      if (!isNil(offset)) {
        delete params.offset;
        params[GlobalOptions.tableOptions.pagination.offsetName] = GlobalOptions.tableOptions.pagination.offsetCallback(offset);
      }
      return params;
    },

    initColsModel() {
      const colsModel = [];
      forEach(this.colsClone, col => {
        if (!col.hide) {
          colsModel.push(col.id);
        }
      });
      if (!this.colsInitModel || !this.colsInitModel.length) {
        this.colsInitModel = colsModel;
      }
      this.colsInitModelDefault = cloneDeep(colsModel);
      this.status.configuratorInit = true;
    },

    checkBlockFirstLoad({ urlParamsObj }) {
      if (urlParamsObj && urlParamsObj.blockFirstLoad) {
        this.blockFirstLoad = true;
      }
    },

    initSorting(value) {
      if (!this.optionsClone.sortable || !value) {
        return;
      }
      const valueString = toString(value);
      const currentSortObj = find(this.sortOptions.list, ["id", valueString]);
      if (currentSortObj) {
        this.filter.ordering = value;
        this.sortOptions.id = value;
        this.sortOptions.asc = valueString.indexOf("-") === -1;
      }
    },

    initOptions() {
      const optionsClone = cloneDeep(this.options);
      optionsClone.cols = optionsClone.cols || [];
      optionsClone.id = optionsClone.id || "vue-table";
      optionsClone.rowId = optionsClone.rowId || GlobalOptions.tableOptions.idLabel;
      optionsClone.view = optionsClone.view || {};
      optionsClone.view.default = optionsClone.view.default || "table";
      optionsClone.view.table = isUndefined(optionsClone.view.table) ? true : optionsClone.view.table;
      optionsClone.view.symbol = isUndefined(optionsClone.view.symbol) ? false : optionsClone.view.symbol;
      optionsClone.view.filter = isUndefined(optionsClone.view.filter) ? "top" : optionsClone.view.filter;
      optionsClone.view.filterShow = isUndefined(optionsClone.view.filterShow) ? true : optionsClone.view.filterShow;
      optionsClone.view.details = optionsClone.view.details || "bottom";
      optionsClone.sortable = isUndefined(optionsClone.sortable) ? true : optionsClone.sortable;
      optionsClone.urlUpdate = isUndefined(optionsClone.urlUpdate) ? true : optionsClone.urlUpdate;
      optionsClone.pagination = isUndefined(optionsClone.pagination) ? true : optionsClone.pagination;
      optionsClone.showColsConfig = isUndefined(optionsClone.showColsConfig) ? true : optionsClone.showColsConfig;
      optionsClone.showHeader = isUndefined(optionsClone.showHeader) ? true : optionsClone.showHeader;
      // optionsClone.actions = optionsClone.actions || [];
      optionsClone.rowActions = optionsClone.rowActions || [];
      optionsClone.massActions = optionsClone.massActions || [];

      if (optionsClone.pagination) {
        optionsClone.paginationTop = isUndefined(optionsClone.paginationTop) ? false : optionsClone.paginationTop;
        optionsClone.paginationBottom = isUndefined(optionsClone.paginationBottom) ? true : optionsClone.paginationBottom;
        this.filter = assign({}, this.filter, {
          limit: this.getSyConfigsValue("default_ntc_anzahl") || 10,
          offset: 0,
          ordering: null,
        });
      }

      this.optionsClone = assign({}, this.optionsClone, optionsClone);
      this.status.init = false;
    },

    initSortable() {
      if (!this.optionsClone.sortable) {
        return;
      }
      const list = [];
      forEach(this.colsClone, col => {
        if (col.sortable) {
          const colMinus = {
            id: `-${ col.sortId }`,
            label: `${ col.label } aufsteigend`,
          };
          const colPlus = {
            id: col.sortId,
            label: `${ col.label } absteigend`,
          };
          list.push(colPlus);
          list.push(colMinus);
          if (col.sortingDefault) {
            this.sortOptions.id = col.sortId;
            this.sortOptions.asc = col.sortInvert ? col.sortingDefault !== "asc" : col.sortingDefault === "asc";
          }
        }
      });
      this.sortOptions.list = list;
      if (this.sortOptions.id) {
        this.filter.ordering = `${ this.sortOptions.asc ? "" : "-" }${ this.sortOptions.id }`;
      }
    },

    loadKonfigurationCollsAndFilters() {
      if (GlobalOptions.tableOptions.dynamicConfigUrl) {
        this.getListHttp({ url: GlobalOptions.tableOptions.dynamicConfigUrl(this.options.id), headerParams: { detail: true } }).then(
          response => {
            this.updateTableRest({ response });
            this.updateFiltersCloneWithApi({ response });
            this.updateColsCloneWithApi({ response });
            this.updateMassActionsWithApi({ response });

            // this.updateFilterUndColsFromSyConfig();
            this.loadRestFunctions();
            this.status.loadKonfigurationCollsAndFilters = false;
          },
          () => this.loadRestFunctions()
        );
      } else {
        this.status.loadKonfigurationCollsAndFilters = false;
        this.filtersClone = this.setParameterDeselectForAllSelectFilters(cloneDeep(this.filters));
        this.colsClone = cloneDeep(this.optionsClone.cols);
        // this.updateFilterUndColsFromSyConfig();
        this.loadRestFunctions();
      }
    },

    updateFiltersCloneWithApi({ response }) {
      let filtersResponse = [];
      if (response && response[0] && response[0].filters) {
        filtersResponse = response[0].filters;
      }
      const FILTERS_OBJ = {};
      forEach(concat(cloneDeep(this.filters), filtersResponse), filter => {
        const CURRENT_FILTER = this.addTranslateForLabel({ item: this.changeSnaKeCaseToCamelCase(filter) });
        FILTERS_OBJ[filter.key || filter.id] = CURRENT_FILTER;
      });

      this.filtersClone = this.setParameterDeselectForAllSelectFilters(values(FILTERS_OBJ));
    },

    setParameterDeselectForAllSelectFilters(filters) {
      forEach(filters, filter => {
        if (filter.type === "select") {
          filter.deselect = true;
        }
      });
      return filters;
    },

    updateColsCloneWithApi({ response }) {
      let colsResponse = [];
      if (response && response[0] && response[0].columns) {
        colsResponse = response[0].columns;
      }
      const COLUMNS_OBJ = {};
      forEach(concat(cloneDeep(this.optionsClone.cols), colsResponse), column => {
        const CURRENT_COLUMN = this.addTranslateForLabel({ item: this.changeSnaKeCaseToCamelCase(column) });
        COLUMNS_OBJ[column.id] = CURRENT_COLUMN;
      });
      this.colsClone = values(COLUMNS_OBJ);
    },

    updateMassActionsWithApi({ response }) {
      if (response && response[0] && response[0].massactions) {
        this.massActionConfig = orderBy(cloneDeep(response[0].massactions), ["tcma_prio"]);
      }
      this.massActionConfigSelectorClose = [
        `#${ this.optionsClone.id }_mehrfachaktion_dropdown`,
        "#table_modal_workflow_mass_actions"
      ];
      forEach(this.massActionConfig, item => {
        if (!isNil(item.tcma_permission) && !this.checkPermissionsSync({ perm: item.tcma_permission })) {
          return;
        }
        item.modal = true;
        item.callback = ({ rows }) => {
          this.massActionConfigActive = cloneDeep(item);
          this.massActionConfigActive.rows = rows;
          this.massActionConfigActive.close = ({ status = false }) => {
            if (status && this.massActionConfigActive.tcma_reload_on_finish) {
              this.updateData();
              EventBus.$emit(`cancelMassActions${ this.optionsClone.id }`, { status });
            }
            this.massActionConfigActive = undefined;
          };
        };
        const INDEX = findIndex(this.optionsClone.massActions, ["tcma_id", item.tcma_id]);
        const itemTemp = cloneDeep(item);
        itemTemp.label = item.tcma_label;
        itemTemp.title = item.tcma_title;
        itemTemp.icon = item.tcma_icon;
        itemTemp.css_klassen = item.tcma_css;
        if (INDEX !== -1) {
          this.optionsClone.massActions.splice(INDEX, 1, itemTemp);
        } else {
          this.optionsClone.massActions.push(itemTemp);
        }
      });
    },

    changeSnaKeCaseToCamelCase(obj) {
      const NEW_OBJ = {};
      forEach(cloneDeep(obj), (item, key) => {
        NEW_OBJ[camelCase(key)] = isNull(item) ? undefined : item;
      });
      return NEW_OBJ;
    },

    addTranslateForLabel({ item }) {
      const ITEM = cloneDeep(item);
      // if (ITEM.label) {
      //   ITEM.label = this.gettext(ITEM.label);
      // }
      // if (ITEM.group) {
      //   ITEM.group = this.gettext(ITEM.group);
      // }
      return ITEM;
    },

    updateTableRest({ response }) {
      if (!response || !response[0] || !response[0].rest) {
        return;
      }
      this.updateTableLabel({ rest: response[0].rest });
      this.setVarHideFilters({ rest: response[0].rest });
    },

    updateTableLabel({ rest }) {
      if (rest.label) {
        this.optionsClone.label = rest.label;
      }
    },

    setVarHideFilters({ rest }) {
      this.hideFilters = rest.hideFilters;
      if (this.hideFilters) {
        this.status.tableCollapsed = false;
      }
    },

    loadRestFunctions() {
      this.updateFiltersWithPermissionAndContextType();
      this.updateColumnsWithPermissionAndContextType();
      this.updateColumnsWithPriority();
      this.initSortable();

      if (this.optionsClone.urlUpdate) {
        const urlParamsObj = getUrlParams() || {};
        this.urlParams = urlParamsObj;
        this.checkBlockFirstLoad({ urlParamsObj });
        this.initFilterModelFromUrl({ urlParamsObj });
        this.initPaginationFromUrl({ urlParamsObj });
        this.initSorting(urlParamsObj.ordering);
        this.initFilterModelFromLocalStorage();
        this.initFilterModelFromDefaultConfig();
      }
      this.MUT_CHANGE_MODEL_TABLE_BY_ID({ id: this.optionsClone.id, model: this.filterModel });
      this.status.paginationLoading = false;

      this.checkUser();
      this.status.filtersInitAsync = true;
    },

    updateFiltersWithPermissionAndContextType() {
      for (let i = 0; i < this.filtersClone.length; i++) {
        let deleted = false;
        const CURRENT_FILTER = this.filtersClone[i];
        if (CURRENT_FILTER.permission) {
          let statusHasPermission = this.checkPermissionsSync({ perm: CURRENT_FILTER.permission });
          statusHasPermission = CURRENT_FILTER.permissionMissing ? !statusHasPermission : statusHasPermission;
          if (!statusHasPermission) {
            this.filtersClone.splice(i, 1);
            i--;
            deleted = true;
          }
        }
        if (CURRENT_FILTER.permissionContextType &&
          !this.checkContextTypeSync({
            contextType: CURRENT_FILTER.permissionContextType
          }) && !deleted) {
          this.filtersClone.splice(i, 1);
          i--;
        }
      }
    },

    updateColumnsWithPermissionAndContextType() {
      for (let i = 0; i < this.colsClone.length; i++) {
        let deleted = false;
        const CURRENT_COLUMN = this.colsClone[i];
        if (CURRENT_COLUMN.permission) {
          let statusHasPermission = this.checkPermissionsSync({ perm: CURRENT_COLUMN.permission });
          statusHasPermission = CURRENT_COLUMN.permissionMissing ? !statusHasPermission : statusHasPermission;
          if (!statusHasPermission) {
            this.colsClone.splice(i, 1);
            i--;
            deleted = true;
          }
        }
        if (CURRENT_COLUMN.permissionContextType &&
          !this.checkContextTypeSync({
            contextType: CURRENT_COLUMN.permissionContextType
          }) && !deleted) {
          this.colsClone.splice(i, 1);
          i--;
        }
      }
    },

    updateColumnsWithPriority() {
      this.colsClone = sortBy(cloneDeep(this.colsClone), ["priority"]);
    },

    initFilterModelFromUrl({ urlParamsObj }) {
      const FILTER_MODEL = {};
      forEach(this.filtersClone, filter => {
        const URL_ID = filter.urlParameter || filter.id;
        if (urlParamsObj[URL_ID]) {
          this.filterPrioritization = "Url";
          FILTER_MODEL[filter.id] = this.getCurrentFilterModelFromUrl({ value: urlParamsObj[URL_ID], filter });
        } else if (urlParamsObj[`${ URL_ID }_after`] || urlParamsObj[`${ URL_ID }_before`]) { // Daterange
          this.filterPrioritization = "Url";
          FILTER_MODEL[filter.id] = FILTER_MODEL[filter.id] || {};
          FILTER_MODEL[filter.id][`${ filter.id }_after`] = urlParamsObj[`${ URL_ID }_after`] || undefined;
          FILTER_MODEL[filter.id][`${ filter.id }_before`] = urlParamsObj[`${ URL_ID }_before`] || undefined;
        } else if (urlParamsObj[`${ URL_ID }_min`] || urlParamsObj[`${ URL_ID }_max`]) { // Numberrange
          FILTER_MODEL[filter.id] = FILTER_MODEL[filter.id] || {};
          this.filterPrioritization = "Url";
          FILTER_MODEL[filter.id][`${ filter.id }_min`] = urlParamsObj[`${ URL_ID }_min`] || undefined;
          FILTER_MODEL[filter.id][`${ filter.id }_max`] = urlParamsObj[`${ URL_ID }_max`] || undefined;
        }
      });

      if (this.filterPrioritization === "Url") {
        this.filterModel = FILTER_MODEL;
      }
    },

    getCurrentFilterModelFromUrl({ value, filter }) {
      if ((filter.type === "multiselect"
        || filter.type === "checkbox"
        || filter.type === "radio") && !isArray(value)) {
        return [value];
      } else if (isObject(value)) {
        return cloneDeep(value);
      }
      return value;
    },

    initPaginationFromUrl({ urlParamsObj }) {
      if (!isUndefined(urlParamsObj.limit)) {
        this.filter.limit = min([max([1, urlParamsObj.limit]), 100]);
      }
      if (!isUndefined(urlParamsObj.offset)) {
        this.filter.offset = urlParamsObj.offset;
      }
    },

    initFilterModelFromLocalStorage() {
      if (!this.optionsClone.id || this.filterPrioritization === "Url") {
        return;
      }
      let filterLocalStorage = localStorage.getItem(`${ this.optionsClone.id }_filter`);
      if (!filterLocalStorage || filterLocalStorage === "undefined") {
        return;
      }
      filterLocalStorage = JSON.parse(filterLocalStorage);

      if (!filterLocalStorage) {
        return;
      }
      const filterModel = {};
      forEach(cloneDeep(this.filtersClone), filter => {
        if (filterLocalStorage[filter.urlParameter || filter.id]) {
          this.filterPrioritization = "LocalStorage";
          filterModel[filter.id] = filterLocalStorage[filter.urlParameter || filter.id];
        }
      });

      if (filterLocalStorage.ordering) { // Sortierung
        this.initSorting(filterLocalStorage.ordering);
      }

      if (this.filterPrioritization === "LocalStorage") {
        this.filterModel = filterModel;
      }
    },

    initFilterModelFromDefaultConfig() {
      if (this.filterPrioritization === "Url" || this.filterPrioritization === "LocalStorage") {
        return;
      }
      const filterModel = {};
      forEach(this.filtersClone, filter => {
        if (filter.default || filter.defaultJson) {
          this.filterPrioritization = "Default";
          filterModel[filter.id] = this.setDefaultCurrentFilterModel({ filter });
        }
      });

      if (this.filterPrioritization === "Default") {
        this.filterModel = filterModel;
      }
    },

    setDefaultCurrentFilterModel({ filter }) {
      let model = "";
      if (filter.default) {
        model = cloneDeep(filter.default);
      } else if (filter.defaultJson) {
        if (filter.type === "daterange") {
          const TEMP_MODEL = {};
          forEach(filter.defaultJson, (item, key) => {
            if (item === "day" || item === "month" || item === "year") {
              TEMP_MODEL[key] = moment().startOf(item).format("YYYY-MM-DD");
            } else {
              TEMP_MODEL[key] = item;
            }
          });
          model = TEMP_MODEL;
        } else {
          model = cloneDeep(filter.defaultJson);
        }
      }
      return model;
    },

    loadTableConfig() {
      if (!this.optionsClone.id || !GlobalOptions.tableOptions.configurationSave) {
        this.initColsModel();
        this.updateData();
        return;
      }
      this.getHttp({ url: `nutzertabellenconfigs/?ntc_kennung=${ this.optionsClone.id }` }).then(response => {
        if (response && response.length) {
          this.MUT_SET_CONFIG({
            tableId: this.optionsClone.id,
            configId: response[0][this.idLabel],
            ntc_conf: response[0].ntc_conf,
            ntc_anzahl: response[0].ntc_anzahl,
            ntc_kennung: response[0].ntc_kennung,
          });
          this.colsInitModel = response[0].ntc_conf;
          if (this.optionsClone.pagination && isNil(this.urlParams.limit)) {
            this.filter.limit = response[0].ntc_anzahl;
          }
          this.initColsModel();
          this.status.configuratorInit = true;
        } else {
          this.initColsModel();
        }
        this.updateData();
      }, () => {
        this.initColsModel();
        this.updateData();
      });
    },

    onError(error) {
      if (isNil(error)) {
        this.status.loading = false;
        this.addNotification({ type: "error", text: "_MSG_UNERWARTETER_FEHLER_" });
        return;
      }
      if (error.status !== 400 || !error.data) {
        return;
      }
      let statusUpdate = false;
      forEach(error.data, (item, key) => {
        if (this.filterModel[key]) {
          delete this.filterModel[key];
          statusUpdate = true;
          const CURRENT_FILTER = find(this.filtersClone, ["id", key]) || {};
          this.addNotification({ type: "error", text: `${ CURRENT_FILTER.label }: ${ item.join(", ") }` });
          this.removeCurrentFilter({ filterId: key });
        }
      });
      if (statusUpdate) {
        this.updateData();
      }
      this.status.loading = false;
    },

    removeCurrentFilter({ filterId }) {
      EventBus.$emit(`removeFilter_${ this.optionsClone.id }`, { filterId, allForCurrentFilter: true, statusUpdate: false });
    },

    setData({ data, params }) {
      this.dataLoaded = true;
      this.counter.total = data[GlobalOptions.tableOptions.keyCount];
      this.rows = data[GlobalOptions.getListHttpKeyList];
      if (GlobalOptions.tableOptions.csv) {
        const CSV_OPTIONS = {
          url: this.optionsClone.url,
          status: data.csv_available,
          csv_max_entries: data.csv_max_entries,
          title: `Suchergebnis als CSV-Datei herunterladen ${ data.csv_max_entries ? ` (maximal ${ data.csv_max_entries } Datensätze)` : "" }`,
          params: params,
          label: "",
        };
        this.csv = this.options.csv ? this.options.csv : CSV_OPTIONS;
      }
      if (isFunction(this.options.onRefreshCallback)) {
        this.options.onRefreshCallback({ params, rows: this.rows });
      }
      this.initMassActionsFromResponse({ data, params });
    },

    initMassActionsFromResponse({ data }) {
      if (data.massenstatuswechsel) {
        forEach(data.massenstatuswechsel, item => {
          const INDEX = findIndex(this.optionsClone.massActions, ["pk", item.pk]);
          const itemTemp = cloneDeep(item);
          itemTemp.label = item.msw_kbez;
          if (INDEX !== -1) {
            this.optionsClone.massActions.splice(INDEX, 1, itemTemp);
          } else {
            this.optionsClone.massActions.push(itemTemp);
          }
        });
      }
      if (data.workflowfolgen) {
        this.workflowfolgen = data.workflowfolgen;
      } else {
        this.workflowfolgen = [];
      }
    },

    setLocalStorage(params) {
      if (this.optionsClone.id) {
        localStorage.setItem(`${ this.optionsClone.id }_filter`, JSON.stringify(params));
      }
    },

    sortTable(id) {
      this.sortOptions.id = id;
      this.filter.ordering = id;
      this.updateData();
    },

    startSearch(model) {
      this.filterModel = cloneDeep(model);
      if (this.filter.offset) {
        this.filter.offset = 0;
      }
      return this.updateData();
    },

    updateData() {
      EventBus.$emit(`${ this.optionsClone.id }updateData`);
      let params = this.getFilterAndPagination(this.filterModel);

      this.changeUrlParams(params);
      this.setLocalStorage(params);

      if (this.urlMassAktionsParameter) {
        params = assign({}, params, this.urlMassAktionsParameter);
      }

      if (this.blockFirstLoad) {
        this.blockFirstLoad = false;
        this.status.loading = false;
        return;
      }
      this.status.loading = true;

      // this.setDefaultIndex();

      if (isArray(this.optionsClone.url)) {
        return Promise.all(map(this.optionsClone.url, item => this.getHttp({ url: item, urlParams: params }))).then(
          response => {
            if (isArray(response)) {
              const DATA = { count: 0, results: [] };
              forEach(response, item => {
                if (isArray(item)) {
                  DATA.count += item.length;
                  DATA.results = [...DATA.results, ...item];
                } else {
                  DATA.count += item.count;
                  DATA.results = [...DATA.results, ...item.results];
                }
              });
              this.setData({ data: DATA, params });
            } else {
              this.setData({ data: response, params });
            }
            this.$emit("loadData", { response });
            this.status.loading = false;
          },
          error => {
            this.onError(error);
          }
        );
      } else if (this.options.data) {
        if (isString(this.options.data)) {
          const dataArr = get(this.objects, this.options.data) || [];
          const data = {
            results: dataArr,
            count: dataArr.length,
          };
          this.setData({ data, params });
          this.$emit("loadData", { response: data });
          this.status.loading = false;
        } else {
          const DATA = {
            count: this.options.data.length,
            results: this.options.data,
          };
          this.setData({ data: DATA, params });
          this.$emit("loadData", { response: DATA });
          this.status.loading = false;
        }
      } else if (this.options.url) {
        return this.getHttp({ url: this.options.url, urlParams: params }).then(
          response => {
            this.setData({ data: response, params });
            this.$emit("loadData", { response });
            this.status.loading = false;
          },
          error => {
            this.onError(error);
          }
        );
      }
    },

    updateLimit({ limit, disabled }) {
      if (disabled) {
        return;
      }
      this.changeConfiguratorLimit({ limit });
      this.filter.limit = limit;
      this.filter.offset = 0;
      this.updateData();
    },

    changeConfiguratorLimit({ limit }) {
      if (!this.optionsClone.id || !this.optionsClone.user || !GlobalOptions.tableOptions.configurationSave) {
        return;
      }

      const CONFIG = this.GET_CONFIG(this.options.id);

      const data = {
        ntc_kennung: CONFIG.ntc_kennung || this.optionsClone.id,
        ntc_conf: CONFIG.ntc_conf,
        ntc_anzahl: limit,
      };

      if (CONFIG.id) {
        this.putHttp({ url: `nutzertabellenconfigs/${ CONFIG.id }/`, data }).then(
          () => {
          }
        );
      } else {
        this.postHttp({ url: "nutzertabellenconfigs/", data }).then(
          response => {
            this.MUT_SET_CONFIG({
              tableId: this.options.id,
              configId: response[this.idLabel],
            });
          }
        );
      }
    },

    updateOffset({ offset }) {
      this.filter.offset = offset;
      this.updateData();
    },

    changeModelConfigurator(model) {
      this.model.cols = model;
    },

    reloadTableWithWorkflow({ item } = {}) {
      this.urlMassAktionsParameter = item ? { msw: item.aufgabe } : undefined;
      this.updateData();
    },

    toggleInfoHeader(status) {
      this.statusShowInfoHeader = !status;
    },
    // Details
    replaceDetailsObj(data, status) {
      if (status) {
        this.detailsObj[data[this.optionsClone.rowId]] = assignIn({}, this.detailsObj[data[this.optionsClone.rowId]], data);
      } else {
        this.detailsObj = assign({}, this.detailsObj, { [data[this.optionsClone.rowId]]: data });
      }
    },

    ...mapMutations([
      "MUT_CREATE_TABLE_BY_ID",
      "MUT_REMOVE_TABLE_BY_ID",
      "MUT_CHANGE_MODEL_TABLE_BY_ID",
      "MUT_SET_CONFIG",
      "MUT_CHANGE_URL_GET_PARAMS",
    ]),
  },
};
