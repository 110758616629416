"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller";

class FoerderregelBasedataEditController extends BaseEditController {
  /*@ngInject*/
  constructor(
    $q,
    $rootScope,
    $scope,
    ParameterUtils,
    AuthService,
    DiasNotification,
    Foerderanzeigekontext,
    Systemmodul,
    i18n
  ) {
    super($q, $scope, DiasNotification);

    this.gettext = i18n.gettext;
    this.loadingEditData = true;

    const anzPromise = Foerderanzeigekontext.getList();
    this.anzeigekontextChoices = anzPromise.$object;
    const modulPromise = Systemmodul.getList();
    this.modulChoices = modulPromise.$object;

    $q.all([anzPromise, modulPromise]).finally(
      () => this.loadingEditData = false
    );

    this.save = () => {
      let data = {
        modul: this.model.modul,
      };
      if (this.model.re_argument) {
        data = angular.merge(data, ParameterUtils.to_request_body(this.model.re_argument));
      }
      return this.edit(data);
    };

    this.edit = data => {
      return this.model.customPUT(data).then(
        response => {
          DiasNotification.page.success("Geschäftsregel erfolgreich gespeichert.");
          if (this.model.is_used) {
            DiasNotification.page.success(
              "Zugehörige Regeln an Förderangeboten wurden ebenfalls aktualisiert"
            );
          }

          response.param_keys = ParameterUtils.get_ordered_keys(response.re_argument);
          Object.assign($scope.model, response);
          $rootScope.$broadcast(
            ParameterUtils.events.saved,
            $scope.model.param_keys,
            $scope.model.re_argument
          );
          return $scope.model;
        },
        // this.onError
        errors => {
          $rootScope.$broadcast(
            ParameterUtils.events.error,
            errors.data
          );
          return this.onErrors(errors, this.getErrorLabels());
        }
      );
    };

    this.getErrorLabels = () => {
      let labels = {
        re_kbez: "Name: ",
        re_bezeichnung: "Beschreibung: ",
        re_astbez: "Hilfetext für den " + this.gettext("Antragsteller") + ": ",
        anzeigekontext: "Anzeigekontext: ",
        modul: "Modul: ",
      };
      labels = Object.assign(labels, ParameterUtils.getErrorLabels(this.model.re_argument));
      return labels;
    };

    this.onModulChange = modul_pk => {
      angular.forEach(this.modulChoices, modul => {
        if (modul.pk === modul_pk) {
          this.model.re_argument = modul.sm_parameterin;
          this.model.modul_obj = modul;
          this.model.param_keys = ParameterUtils.get_ordered_keys(this.model.re_argument);
        }
      });
    };


    if (this.model && this.model.re_argument) {
      this.model.param_keys = ParameterUtils.get_ordered_keys(this.model.re_argument);
      this.model.param_keys.forEach(key => {
        if (this.model.re_argument[key].wert === undefined) {
          this.model.re_argument[key].wert = null;
        }
      });
    }
  }
}

export default FoerderregelBasedataEditController;
