"use strict";

import template from "./simpleList.jade";
import DokumenteBaseController from "./base.controller";

class DokumentSimpleListController extends DokumenteBaseController {
  /*@ngInject*/
  constructor($scope, $window, Antragstellerorganisationen, AuthService, Dokumente, Foerderantrag) {
    super($window, Dokumente, Foerderantrag, Antragstellerorganisationen);
    this.doks = [];
    this.docEmptyMsg = this.docEmptyMsg || "Noch kein Dokument vorhanden";
    this.docUpdateEvent = this.docUpdateEvent || "dokumente.update";

    this.canView = AuthService.syncHasPerm("dokument.view") || this.docDokumente;
    if (this.canView) {
      this.loadData();
      $scope.$on(this.docUpdateEvent, () => {
        this.loadData();
      });
    }
  }

  loadData() {
    if (this.docDokumente) {
      this.doks.push(...this.docDokumente);
      this.loading = false;
      return;
    }
    this.loading = true;
    this.docObj.dokumente.getLastDokList(this.docAnzeige, this.docLimit).then(
      result => {
        this.doks = result;
      }
    ).finally(() => this.loading = false);
  }
}

export default {
  template: template(),
  controller: DokumentSimpleListController,
  controllerAs: "vm",
  bindings: {
    docDokumente: "<?",
    docObj: "<",
    docAnzeige: "<",
    docUpdateEvent: "@?",
    docLimit: "@?",
    docEmptyMsg: "@?",
    docRender: "<?"
  }
};
