import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Wizard from "../../../global/components/Wizard/Wizard.vue";
import WizardStep1 from "./WizardSteps/WizardStepVorbereiten/WizardStepVorbereiten.vue";
import WizardStep2 from "./WizardSteps/WizardStepAusfuehren/WizardStepAusfuehren.vue";
import WizardStep3 from "./WizardSteps/WizardStepAbschluss/WizardStepAbschluss.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";
import WizardStepsMixin from "./WizardStepsMixin";

import {
  cloneDeep,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "ImportauftragJsonWizard",
  components: {
    PageTabTitle,
    PuxCloak,
    PuxTranslate,
    Wizard,
    WizardStep1,
    WizardStep2,
    WizardStep3,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
    WizardStepsMixin,
  ],
  data() {
    return {
      stepActive: 0,
      stepsVisited: {
        0: true,
        1: false,
        2: false,
      },
      booleanPropsWizard: {
        backTargetStepDisabled: true,
        backStepHide: true,
        backStepDisabled: true,
        forwardStepHide: undefined,
        forwardStepDisabled: true,
        buttonsLoading: false,
        closeButtonShow: true,
      },
      wizardOptions: {
        forwardStepButtonSuffix: undefined,
      },
      loading: {
        init: true,
        step1: false,
        step2: false,
      },
      dataStep1: {
        errors: {},
      },
      modelStep1: {
        file: undefined
      },
      modelStep2: undefined,
      dataStep2: {
        auftrag: undefined,
        task: undefined,
        taskFailure: false,
        taskError: undefined,
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.loadData();
    },

    loadData() {
      this.loading.init = false;
    },

    updateModelStep1({ model }) {
      this.modelStep1 = cloneDeep(model);
      if (this.modelStep1.file) {
        this.booleanPropsWizard.forwardStepDisabled = false;
      }
    },

    updateModelStep2({ model }) {
      this.modelStep2 = cloneDeep(model);
    },

    goOneStepForward() {
      const NUMBER_STEP_TO_GO = this.stepActive + 2;
      if (isFunction(this[`goForwardToStep${ NUMBER_STEP_TO_GO }`])) {
        this[`goForwardToStep${ NUMBER_STEP_TO_GO }`]();
      } else {
        this.goNextStep();
      }
    },

    goForwardToStep2() {
      this.dataStep1.errors = {};
      this.loading.step1 = true;
      this.booleanPropsWizard.buttonsLoading = true;
      this.booleanPropsWizard.forwardStepDisabled = true;
      const FORM_DATA = new FormData();
      FORM_DATA.append("file", this.modelStep1.file.file);
      FORM_DATA.append("iea_impexpdatei_name", this.modelStep1.file.name);
      this.postHttp({
        url: `importauftraege_json/`, data: FORM_DATA }).then(
        iea => {
          this.dataStep2.auftrag = iea;
          this.booleanPropsWizard.buttonsLoading = false;
          this.booleanPropsWizard.forwardStepDisabled = true;
          this.loading.step1 = false;
          this.goNextStep();
        },
        errors => {
          this.dataStep1.errors = cloneDeep(errors.data);
          this.booleanPropsWizard.buttonsLoading = false;
          this.booleanPropsWizard.forwardStepDisabled = false;
          this.loading.step1 = false;
        }
      );
    },

    goNextStep() {
      this.stepActive += 1;
      this.stepsVisited[this.stepActive] = true;
    },

    goOneStepBack() {
      if (isFunction(this[`goBackToStep${ this.stepActive }`])) {
        this[`goBackToStep${ this.stepActive }`]();
      }
    },

    goBackToStep2() {
      this.stepActive -= 1;
      this.stepsVisited[2] = false;
    },

    goBackToStep1() {
      this.stepActive -= 1;
      this.stepsVisited[1] = false;
    },

    updateModelKontext({ model }) {
      this.model.kontext = model;
      this.booleanPropsWizard.forwardStepDisabled = false;
    },

    taskStart() {
      this.loading.step2 = true;
      this.dataStep2.taskFailure = false;
      this.postHttp({
        url: `importauftraege/${ this.dataStep2.auftrag.pk }/ausfuehren/`,
        data: this.modelStep2
      }).then(
        response => {
          this.dataStep2.task = response.task_id;
        },
        errors => {
          this.dataStep2.taskErrors = errors;
        }
      ).finally(() => this.loading.step2 = false);
    },

    onTaskSuccess() {
      this.getHttp({ url: `importauftraege/${ this.dataStep2.auftrag.pk }/` }).then(
        response => {
          this.dataStep2.auftrag = cloneDeep(response);
          this.booleanPropsWizard.forwardStepDisabled = false;
        }
      );
    },

    onTaskFailure({ result }) {
      this.dataStep2.taskError = result.data || true;
      this.getHttp({ url: `importauftraege/${ this.dataStep2.auftrag.pk }/` }).then(
        response => this.dataStep2.auftrag = cloneDeep(response)
      );
    },

    onTaskFinish() {
      this.loading.step2 = false;
    },

    resetWizard() {
      // save initial Data
      const SYCONF = cloneDeep(this.syConfig);
      // Internen Zustand auf initialen Zustand setzen
      Object.assign(this.$data, this.$options.data.apply(this));
      // restore initial Data
      this.syConfig = SYCONF;
      this.loading.init = false;
    }
  },
};
