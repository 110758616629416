import template from "./plan.formstep.jade";

import anonUpdateController from "./plan.edit.controller";
import anonUpdateTemplate from "./plan.edit.jade";


class AnonymisierungsplanFormstepController {
  /*@ngInject*/
  constructor($scope, Katalog, i18n) {
    this.Katalog = Katalog;
    this.gettext = i18n.gettext;
    this.loadedPk = false;

    $scope.$watch("vm.obj", () => {
      if (this.obj && this.obj.pk === this.loadedPk) {
        return;
      }
      this.loadAnonymisierungsplan();
    });

    this.anonUpdateController = anonUpdateController;
    this.anonUpdateTemplate = anonUpdateTemplate;
    this.getAnonymisierungsplanLabel = this.getAnonymisierungsplanLabel.bind(this);
    this.init();
  }

  init() {
    this.Katalog.getKatalog(this.planKatalog).then(
      response => this.anon_cts = response
    );
  }

  loadAnonymisierungsplan() {
    return this.obj.anonymisierungsplaene.getList().then(
      response => this.anonymisierungsplan = response
    ).finally(() => this.loadedPk = this.obj.pk);
  }

  getAnonymisierungsplanLabel(stufe, ct) {
    let anp;
    if (this.anonymisierungsplan) {
      anp = this.anonymisierungsplan.find(
        anp => Number(anp.anp_stufe) === Number(stufe.pk) && Number(anp.anon_content_type) === Number(ct.pk)
      );
    }
    if (!anp) {
      return this.gettext("Nicht geplant");
    } else if (anp.anp_frist === 1) {
      return `${ anp.anp_frist } Monat`;
    } 
    return `${ anp.anp_frist } Monate`;
  }
}

export default {
  template: template(),
  controller: AnonymisierungsplanFormstepController,
  controllerAs: "vm",
  bindings: {
    obj: "<",
    editable: "<",
    planKatalog: "@",
    planTitle: "@",
  }
};
