import ActionsPageMain from "../../../global/components/ActionsPageMain/ActionsPageMain.vue";
import AsideRightBox from "../../../global/components/AsideRightBox/AsideRightBox.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import FoerderorganisationKontaktBox from "../FoerderorganisationKontaktBox/FoerderorganisationKontaktBox.vue";
import PanelComponents from "./PanelComponents";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "FoerderorganisationDetails",
  components: {
    ActionsPageMain,
    AsideRightBox,
    ContextPanel,
    FoerderorganisationKontaktBox,
    PanelComponents,
    PuxPageDetails,
    ...PanelComponents,
  },
  mixins: [
    HttpMixin,
  ],
  data() {
    return {
      loading: true,
      organisation: {},
      categories: undefined,
      kategoriewerte: {},
      categoriesAll: {},
      kontaktLabel: "_TXT_FOERDERORGANISATION_DETAILS_KONTAKT_BOX_HEADER_",
    };
  },
  computed: {
    panels() {
      return [
        {
          label: "_TXT_FOERDERORGANISATION_DETAILS_BESCHREIBUNG_PANEL_NAME_",
          icon: "glyphicon-list",
          active: true,
          pk: "beschreibung",
          components: [
            {
              name: "Beschreibung",
              pk: "Beschreibung",
            },
          ],
        },
        {
          label: "_TXT_FOERDERORGANISATION_DETAILS_KONZEPT_PANEL_NAME_",
          icon: "glyphicon-info-sign",
          active: true,
          pk: "konzept",
          components: [
            {
              name: "Konzept",
              pk: "Konzept",
            },
          ],
          hide: !(this.organisation && (this.organisation.o_konzept_titel || this.organisation.o_konzept_kbez || this.organisation.o_konzept_bez)),
        },
        {
          label: "_TXT_FOERDERORGANISATION_DETAILS_FOERDERANGEBOTE_PANEL_NAME_",
          icon: "glyphicon-foerderangebot",
          active: true,
          pk: "foerderangebote",
          components: [
            {
              name: "Foerderangebote",
              pk: "Foerderangebote",
            },
          ],
        },
      ];
    },
  },
  created() {
    this.loadFoerderorganisation();
  },
  methods: {
    loadFoerderorganisation() {
      this.getHttp({ url: `info/foerderorganisationen/${ this.$stateParams.id }/` }).then(
        response => {
          this.organisation = response;
          this.loadFoerderfinderKategorien();
        }
      ).then(
        () => this.loading = false
      );
    },

    loadFoerderfinderKategorien() {
      this.getHttp({ url: "foerderfinderkategorien/" }).then(
        response => {
          this.categories = response.results;
          this.filterCategories();
        }
      );
    },

    filterCategories() {
      const KATEGORIEWERTE = {};
      const CATEGORIES_ALL = {};
      forEach(this.categories, cat => {
        forEach(this.organisation.regelsaetze, regel => {
          KATEGORIEWERTE[regel.pk] = KATEGORIEWERTE[regel.pk] || {};
          KATEGORIEWERTE[regel.pk][cat.pk] = { ffk_kbez: cat.ffk_kbez };
          KATEGORIEWERTE[regel.pk][cat.pk].kategoriewerte = [];

          forEach(regel.kategoriewerte, word => {
            if (cat.pk === word.kategorie) {
              KATEGORIEWERTE[regel.pk][cat.pk].kategoriewerte.push(word);
            }
          });

          if (cat.werte.length === KATEGORIEWERTE[regel.pk][cat.pk].kategoriewerte.length) {
            CATEGORIES_ALL[regel.pk] = CATEGORIES_ALL[regel.pk] || {};
            CATEGORIES_ALL[regel.pk][cat.pk] = cloneDeep(cat) || {};
            CATEGORIES_ALL[regel.pk][cat.pk].fkw_kbez = "alle";
            CATEGORIES_ALL[regel.pk][cat.pk].fkw_style = KATEGORIEWERTE[regel.pk][cat.pk].kategoriewerte[0].fkw_style;
          }
        });
      });

      this.kategoriewerte = KATEGORIEWERTE;
      this.categoriesAll = CATEGORIES_ALL;
    },
  },
};
