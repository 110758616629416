"use strict";

import BaseEditController from "dias/standard_foerdermodule/standard_foerdermodule.edit.controller";

class TerminreiheController extends BaseEditController {
  /*@ngInject*/
  constructor(
    $scope,
    $q,
    Foerdermodule,
    Foerderantrag,
    $filter,
    AuthService,
    Katalog
  ) {
    super($scope, $q, Foerdermodule);
    this.$filter = $filter;
    this.AuthService = AuthService;
    this.Foerderantrag = Foerderantrag;
    this.Katalog = Katalog;
    this.globalBearbeiten = true;
    this.loading = false;
    this.einreichbar = false;
    this.loadTerminreihen();
  }

  isReadonly() {
    return this.readonly || !this.AuthService.syncHasPerm("foerderantrag.terminreihe.update", this.obj.user_permissions);
  }

  setEinreichbar() {
    this.einreichbar = false;
    if (!this.modul.data.terminreihe) {
      this.einreichbar = true;
    } else {
      angular.forEach(this.reihenChoices, reihe => {
        if (this.modul.data.terminreihe.pk == reihe.pk && reihe.einreichbar) {
          this.einreichbar = true;
        }
      });
    }
  }

  prepareUserInput() {
    this.userInput = { einreichfrist: this.modul.data.einreichfrist };
    if (this.modul.data.terminreihe) {
      this.userInput.terminreihe = this.modul.data.terminreihe.pk;
    } else {
      this.userInput.terminreihe = null;
    }
  }

  loadTerminreihen() {
    this.loading = true;
    this.reihenChoices = [];
    this.Katalog.getKatalog("terminreihe", { typ: this.parameter.terminreihentyp }).then(
      result => {
        this.reihenChoices = [];
         // activate_field_einreichfrist wurde sich erstmal weggewünscht,
         // aber falls es wieder relevant wird:
        if (!this.parameter.activate_field_einreichfrist) {
           // Nur Reihen anbieten, deren Einreichfrist noch nicht überschritten wurde:
          angular.forEach(result, reihe => {
            if (reihe.einreichbar) {
              this.reihenChoices.push(reihe);
            }
          });
        } else {
          this.reihenChoices = result;
        }
        this.reihenChoices.push({ trr_titel: "[Keine Angabe]", trr_id: null });
        this.setEinreichbar();
        this.loading = false;
      }
    );
  }

  onSave() {
    return super.onSave().then(() => {
      this.modul.data = this.saved;
      this.setEinreichbar();
      this.prepareUserInput();
    });
  }
}

export default TerminreiheController;
