import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import RegelvergleichKommentarPopover from "../RegelvergleichKommentarPopover/RegelvergleichKommentarPopover.vue";

import { gettext } from "../../../../../global/functions/utils";

import {
  assign,
  find,
  get,
  isFunction,
  isNil,
  startsWith,
} from "lodash-es";

// @vue/component
export default {
  name: "RegelvergleichBox",
  components: {
    FormElement,
    PuxButton,
    PuxIcon,
    RegelvergleichKommentarPopover,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    side: {
      type: String,
      required: true,
    },
    otherSide: {
      type: String,
      required: false,
      default: undefined,
    },
    showDiff: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Object,
      required: false,
      default: undefined,
    },
    kommentar: {
      type: Object,
      required: false,
      default: undefined,
    },
    kommentarExtraSave: {
      type: Function,
      required: false,
      default: undefined,
    },
    parameter: {
      type: Object,
      required: false,
      default: undefined,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    obj: {
      type: Object,
      required: false,
      default: undefined,
    },
    scrollParallel: {
      type: Boolean,
      default: false
    },
    scrollEvent: {
      type: Event,
      required: false,
      default: undefined,
    },
    scrollUpdate: {
      type: Function,
      required: false,
      default: undefined,
    },
    scrollStatusUpdate: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      skipNextEvent: false,
      prevContainer: undefined,
      resizeObserver: undefined,
      kommentarShow: undefined,
      kommentarChanged: undefined,
    };
  },
  computed: {
    kommentarVisible() {
      return !isNil(this.kommentar);
    },
    selectedObj() {
      return get(this.selected, this.side);
    },

    selectedObjOther() {
      return get(this.selected, this.otherSide);
    },

    boxStyle() {
      return {
        "max-height": !isNil(get(this.parameter, "max_hoehe")) ? `${ this.parameter.max_hoehe }px` : "unset",
        display: !isNil(this.selected) ? "block" : "hidden"
      };
    },

    snapshotData() {
      if (!this.showDiff || !this.selectedObjOther) {
        return;
      }
      const MODULENAME = get(this.selectedObj, "modulname");
      const PK = get(this.selectedObj, "regel.pk");
      const REGEL_DATA = get(this.selectedObjOther, "data");
      return {
        data: {
          regelvergleich: {
            [MODULENAME]: {
              [PK]: REGEL_DATA
            }
          }
        }
      };
    },
  },
  watch: {
    scrollEvent() {
      const SCROLL_SRC = get(this.scrollEvent, "target");
      const SCROLL_CONTAINER = get(this.$refs, "scrollContainer");
      if (!this.scrollParallel || isNil(SCROLL_SRC) || isNil(SCROLL_CONTAINER) || SCROLL_CONTAINER === SCROLL_SRC) {
        return;
      }
      this.skipNextEvent = true;
      const RELATIVE_SCROLL = SCROLL_SRC.scrollTop / (SCROLL_SRC.scrollHeight - SCROLL_SRC.offsetHeight);
      const NEW_TOP = (SCROLL_CONTAINER.scrollHeight - SCROLL_CONTAINER.offsetHeight) * RELATIVE_SCROLL;
      SCROLL_CONTAINER.scroll({
        top: NEW_TOP
      });
    }
  },
  beforeCreate() {
    this.$options.components.GeschaeftsregelModul = require("../../../GeschaeftsregelModul/GeschaeftsregelModul.vue").default;
  },
  created() {
    this.resizeObserver = new ResizeObserver(() => {
      const SCROLL_CONTAINER = get(this.$refs, "scrollContainer");
      if (!isNil(SCROLL_CONTAINER)) {
        this.scrollStatusUpdate({ key: this.side, status: SCROLL_CONTAINER.scrollHeight > SCROLL_CONTAINER.offsetHeight });
      } else {
        this.scrollStatusUpdate({ key: this.side, status: false });
      }
    });
  },
  updated() {
    const SCROLL_CONTAINER = get(this.$refs, "scrollContainer");
    if (!isNil(this.prevContainer) && SCROLL_CONTAINER !== this.prevContainer) {
      this.resizeObserver.unobserve(this.prevContainer);
    }
    if (!isNil(SCROLL_CONTAINER)) {
      this.resizeObserver.observe(SCROLL_CONTAINER);
      this.prevContainer = SCROLL_CONTAINER;
    }
  },
  beforeUnmount() {
    if (!isNil(this.prevContainer)) {
      this.resizeObserver.unobserve(this.prevContainer);
    }
  },
  methods: {
    handleScroll($event) {
      if (this.skipNextEvent) {
        this.skipNextEvent = false;
        return;
      }
      if (isFunction(this.scrollUpdate)) {
        this.scrollUpdate($event);
      }
    },
    hasSubKommentar({ key }) {
      return !isNil(find(get(this.kommentar, "extra", {}), (obj, subkey) => startsWith(subkey, key) && subkey !== key));
    },
    getKommentar({ key }) {
      return get(this.kommentar, `extra[${ key }]`);
    },
    getKommentarExtraSave({ key }) {
      return ({ model }) => {
        if (isFunction(this.kommentarExtraSave)) {
          return this.kommentarExtraSave({ key, model });
        }
        return new Promise(resolve => resolve());
      };
    },
    getKommentarChange({ key }) {
      return ({ model }) => {
        const CHANGED = assign({}, this.kommentarChanged);
        const NEW = model || undefined;
        const OLD = this.getKommentar({ key }) || undefined;
        CHANGED[key] = OLD !== NEW;
        this.kommentarChanged = CHANGED;
      };
    },
    getKommentarToggle({ key }) {
      return () => this.toggleKommentar({ key });
    },
    toggleKommentar({ $event, key }) {
      const SHOW = assign({}, this.kommentarShow);
      SHOW[key] = !SHOW[key];
      this.kommentarShow = SHOW;
      if (!isNil($event) && !isNil($event.$event)) {
        $event.$event.stopPropagation();
      }
    },
    isKommentarOpen({ key }) {
      return get(this.kommentarShow, key);
    },
    isKommentarChanged({ key }) {
      return get(this.kommentarChanged, key);
    },
    getKommentarLabel({ parameter, idx }) {
      return `${ parameter.element_bezeichnung } ${ idx + 1 }`;
    },
    getKommentarToggleClass({ key }) {
      if (this.isKommentarChanged({ key })) {
        return "btn-warning";
      } else if (!isNil(this.getKommentar({ key }))) {
        return "btn-primary";
      }
      return "btn-default";
    },
    getKommentarToggleTitleElement({ key }) {
      if (this.isKommentarChanged({ key })) {
        return "_BTN_GR_REGELVERGLEICH_VGL_KOMMENTAR_ELEMENTLISTE_ELEMENT_CHANGED_TOGGLE_TITLE_";
      } else if (!isNil(this.getKommentar({ key }))) {
        return "_BTN_GR_REGELVERGLEICH_VGL_KOMMENTAR_ELEMENTLISTE_ELEMENT_TOGGLE_TITLE_";
      }
      return "_BTN_GR_REGELVERGLEICH_VGL_KOMMENTAR_ELEMENTLISTE_ELEMENT_EMPTY_TOGGLE_TITLE_";
    },
    getKommentarToggleTitleRegel({ key }) {
      if (this.isKommentarChanged({ key })) {
        return "_BTN_GR_REGELVERGLEICH_VGL_KOMMENTAR_ELEMENTLISTE_REGEL_CHANGED_TOGGLE_TITLE_";
      } else if (!isNil(this.getKommentar({ key }))) {
        return "_BTN_GR_REGELVERGLEICH_VGL_KOMMENTAR_ELEMENTLISTE_REGEL_TOGGLE_TITLE_";
      }
      return "_BTN_GR_REGELVERGLEICH_VGL_KOMMENTAR_ELEMENTLISTE_REGEL_EMPTY_TOGGLE_TITLE_";
    },
    getSubKommentarIconClass({ key }) {
      if (this.hasSubKommentar({ key })) {
        return "primary";
      }
      return "default";
    },
    getSubKommentarIconTitle({ key }) {
      if (this.hasSubKommentar({ key })) {
        return gettext("_TXT_GR_REGELVERGLEICH_VGL_KOMMENTAR_ELEMENTLISTE_ELEMENT_HAS_SUBKOMMENTAR_ICON_TITLE_");
      }
      return gettext("_TXT_GR_REGELVERGLEICH_VGL_KOMMENTAR_ELEMENTLISTE_ELEMENT_HAS_NOT_SUBKOMMENTAR_ICON_TITLE_");
    },
    getSubKommentarLabel({ parameter, idx, subIdx }) {
      return `${ parameter.element_bezeichnung } ${ idx + 1 } - ${ subIdx + 1 }`;
    },
  },
};
