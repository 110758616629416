import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Betragsrechner from "../../Betragsrechner/Betragsrechner.vue";
import PuxErrors from "../../../../global/components/PuxErrors/PuxErrors.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import translate from "../../../../global/directives/translate";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import {
  assign,
  cloneDeep,
  isObject,
  isArray,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "KFPositionForm",
  components: {
    FormstepDetail,
    Betragsrechner,
    PuxErrors,
    FormElement,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    htmlId: {
      type: String,
      required: true,
    },
    urlSave: {
      type: String,
      required: true,
    },
    betragsrechner: {
      type: String,
      required: true,
    },
    statusNew: {
      type: Boolean,
      required: false,
    },
    position: {
      type: Object,
      required: false,
      default: undefined,
    },
    extraTranslate: {
      type: Object,
      required: true,
    },
    extra: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    extraForVorschau: {
      type: Object,
      required: false,
      default: undefined,
    },
    katalogDetails: {
      type: Array,
      required: true,
    },
    katalogDetailsObj: {
      type: Object,
      required: false,
      default: undefined,
    },
    typeKoFi: {
      type: String,
      required: true,
      validator: value => ["kosten", "finanzierung"].indexOf(value) !== -1,
    },
    kontext: {
      type: String,
      required: true,
    },
    keyModelDetail: {
      type: String,
      required: false,
      default: undefined,
    },
    labelsPosition: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    httpMethod: {
      type: String,
      required: false,
      default: undefined,
      info: "",
    },
    prepareModel: {
      type: Function,
      required: true,
    },
    defaultBez: {
      type: String,
      required: false,
      default: undefined,
      info: "Das ist für KOFI ohne Positionen"
    },
  },
  data() {
    return {
      errors: {},
      model: {
        argument: {},
      },
      errorLabels: {},
      vorschau: {},
      statusChangeBezeichnung: false,
      statusKatalogDetailsInArgument: false,
      labelDetail: "_LBL_KOFI_DETAIL_",
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        saveCallback: this.saveLocal,
        closeCallback: this.closeLocal,
        required: true,
      };
    },

    headerTranslateOptions() {
      return {
        text: this.headerText,
        extra: this.extraTranslate,
      };
    },

    headerText() {
      return this.statusNew ?
        this.headerTextAdd :
        this.headerTextEdit;
    },

    headerTextAdd() {
      if (this.labelsPosition && this.labelsPosition.labelAdd) {
        return this.labelsPosition.labelAdd;
      }
      return "_TXT_POSITION_ADD_HEADER_{{kofiart}}_";
    },

    headerTextEdit() {
      if (this.labelsPosition && this.labelsPosition.labelEdit) {
        return this.labelsPosition.labelEdit;
      }
      return "_TXT_POSITION_EDIT_HEADER_{{kofiart}}_{{position}}_";
    },

    actionSave() {
      if (this.httpMethod) {
        return this.httpMethod;
      }
      return this.statusNew ? "postHttp" : "putHttp";
    },

    prefixKoFi() {
      if (this.typeKoFi === "kosten") {
        return "kod";
      }
      if (this.typeKoFi === "finanzierung") {
        return "fid";
      }
      return "";
    },

    standardtextKey() {
      return `${ this.prefixKoFi }_standardtext`;
    },

    optionsDetail() {
      const KEY_ID = `${ this.prefixKoFi }_id`;
      return {
        id: "detail",
        htmlId: this.htmlIdDetail,
        type: "select",
        required: true,
        view: "h",
        label: this.labelDetail,
        classesHorizontal: [
          "col-sm-3 text-left text-sm-right",
          "col-sm-6",
          "offset-sm-3 col-sm-6",
        ],
        placeholder: "_TXT_KOFI_DETAIL_PLACEHOLDER_",
        keyId: KEY_ID,
        keyLabel: this.optionsDetailKeyLabel,
        search: true,
        searchList: [this.optionsDetailKeyLabel],
        withoutGroup: true,
        disabled: this.optionsDetailDisabled,
        autoFocus: !this.optionsDetailDisabled,
        change: this.changeKoFiDetail,
      };
    },

    htmlIdDetail() {
      return `${ this.htmlId }_detail`;
    },

    optionsDetailKeyLabel() {
      return `${ this.prefixKoFi }_kbez`;
    },

    optionsDetailDisabled() {
      return !!this.katalogDetailsObj;
    },

    statusDetailHideOderDisabled() {
      return !this.katalogDetails.length || this.optionsDetailDisabled;
    },

    modelForVorschau() {
      const MODEL_FOR_VORSCHAU = cloneDeep(this.model.argument) || {};
      MODEL_FOR_VORSCHAU[this.keyModelDetail] = this.model[this.keyModelDetail] || "";
      if (this.extraForVorschau) {
        return assign({}, MODEL_FOR_VORSCHAU, this.extraForVorschau);
      }
      return MODEL_FOR_VORSCHAU;
    },
  },
  created() {
    this.initModel();
    this.changeVorschau();
  },
  methods: {
    initModel() {
      if (this.statusNew) {
        this.model = {
          argument: {},
          [this.keyModelDetail]: this.katalogDetailsObj ? this.katalogDetailsObj.pk : "",
        };
        if (this.defaultBez) {
          this.model.argument.bezeichnung = this.defaultBez;
        }
      } else {
        this.model = cloneDeep(this.position);
      }
    },

    saveLocal() {
      this.deleteErrors();
      const DATA = this.prepareModelLocal({ model: this.model });
      return new Promise((resolve, reject) => {
        this[this.actionSave]({
          url: this.urlSave,
          data: DATA,
        }).then(
          response => {
            this.$attrs.onSave({ response });
            resolve();
          },
          errors => {
            this.errors = errors.data || {};
            this.focusToErrors();
            reject();
          }
        );
      });
    },

    prepareModelLocal({ model }) {
      const MODEL = cloneDeep(model);
      if (this.statusKatalogDetailsInArgument && !MODEL.argument[this.keyModelDetail]) {
        MODEL.argument[this.keyModelDetail] = MODEL[this.keyModelDetail];
      }
      return this.prepareModel({ model: MODEL });
    },

    focusToErrors() {
      setTimeout(() => {
        if (this.$refs.errors_component && this.$refs.errors_component.$el) {
          this.$refs.errors_component.$el.focus();
        }
      });
    },

    closeLocal(arg) {
      this.$attrs.onClose(arg);
    },

    deleteErrors() {
      this.errors = {};
    },

    initErrorLabelsFromChild({ labels }) {
      this.errorLabels = assign({
        [this.keyModelDetail]: {
          label: this.labelDetail,
          link: true,
          id: this.htmlIdDetail,
        },
      }, this.errorLabels, labels);
    },

    changeModelFromChild({ model }) {
      this.model = model;
    },

    changeKoFiDetail({ item, model }) {
      if (model) {
        this.setBezeichnung({ item });
      }
      this.changeVorschau();
    },

    setBezeichnung({ item }) {
      if (this.statusChangeBezeichnung &&
        item[this.standardtextKey] &&
        !this.model.argument.bezeichnung) {
        this.model.argument.bezeichnung = item[this.standardtextKey];
      }
    },

    changeVorschau() {
      this.postHttp({
        url: `betragsrechner/${ this.betragsrechner }/vorschau/`,
        urlParams: {
          kontext: this.kontext,
          afr_id: this.extra.regel.pk,
        },
        data: this.modelForVorschau,
      }).then(
        response => {
          this.vorschau = response.vorschau;
          const ERRORS = response.validation_errors;

          if (!this.extra.showErrorsHowInApi && isObject(ERRORS)) {
            forEach(ERRORS, (error, key) => {
              if (!isArray(error)) {
                ERRORS[key] = [error];
              }
            });
          }
          this.errors = ERRORS || {};
        }
      );
    },

    isBezeichnung() {
      this.statusChangeBezeichnung = true;
      if (this.katalogDetailsObj) {
        this.setBezeichnung({ item: this.katalogDetailsObj });
      }
    },

    isKatalogDetailsInArgument() {
      this.statusKatalogDetailsInArgument = true;
    },

    mock() {},
  },
};
