import Permission from "../../Permission/Permission";
import TableActionsMapping from "../TableActionsMapping";

// @vue/component
export default {
  name: "TablePanelActionsList",
  components: {
    Permission,
  },
  props: {
    loading: {
      type: Boolean,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      TableActionsMapping: TableActionsMapping,
    };
  },
};
