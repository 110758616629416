import {
  forEach,
  isFunction,
} from "lodash-es";

const TABLE_APIS = {
  // Proandi
  teilnehmer: {
    getApi: ({ sitzungPk }) => `sitzungen/${ sitzungPk }/teilnehmer/`,
  },
  mittelverteilung: {
    getApi: ({ sitzungPk }) => `sitzungen/${ sitzungPk }/mittelverteilung/`,
  },
  GutachtenVorlagen: {
    getApi: () => "gutachtenvorlagen/",
  },
  förderangebot: {
    getApi: () => "foerderangebote/",
  },
  ankuendigung: {
    getApi: () => "news/",
  },
  versionsinformationen: {
    getApi: () => "releasenotes/"
  },
  anonymisierungsauftraege: {
    getApi: () => "anonymisierungsauftraege/",
  },
  geloeschte_antraege: {
    getApi: () => "geloeschte_antraege/",
  },
  foerderantrag: {
    getApi: () => "foerderantraege/",
  },
  cortexantragsliste: {
    getApi: () => "antragslisten/"
  },
  antragsteller: {
    getApi: () => "antragstellerorganisationen/",
  },
  aufgabe: {
    getApi: () => "aktuelle_aufgaben/",
  },
  kaufgabe: {
    getApi: () => "aufgaben/",
  },
  workflowfristInKaufgabe: {
    getApi: ({ auPk }) => `aufgaben/${ auPk }/fristen/`,
  },
  cortexauszahlungsliste: {
    getApi: () => "auszahlungslisten/"
  },
  auszahlung: {
    getApi: () => "auszahlungen/",
  },
  AuszahlungListInVertragTable: {
    getApi: ({ vertragPk }) => `vertraege/${ vertragPk }/auszahlungen/`,
    getApiOptions: () => "auszahlungen/",
  },
  AuszahlungPlanListInVertragTable: {
    getApi: ({ vertragPk }) => `vertraege/${ vertragPk }/auszahlungsplaene/`,
    getApiOptions: () => "auszahlungsplaene/",
  },
  vertragsbelege: {
    getApi: () => "vertragsbelege/",
  },
  BelegeListInVertragTable: {
    getApi: ({ vertragPk }) => `vertraege/${ vertragPk }/belege/`,
    getApiOptions: () => "vertragsbelege/",
  },
  budgets: {
    getApi: () => "budgets/",
  },
  codes_all: {
    getApi: () => "codes/",
  },
  deckblaetter: {
    getApi: () => "deckblaetter/",
  },
  foerderdokumente: {
    getApi: () => "foerderdokumente/",
  },
  RechtsformdokumenteListTable: {
    getApi: ({ dokarts, astPk }) => {
      const URL = [];
      forEach(dokarts, dokart => {
        URL.push(`antragstellerorganisationen/${ astPk }/dokumente/?dokart=${ dokart }`);
      });
      return URL;
    },
    getApiOptions: () => undefined,
  },
  faqs: {
    getApi: () => "faqs/",
  },
  fibujournalTable: {
    getApi: () => "fibujournals/",
  },
  foerdermittel: {
    getApi: () => "foerdermittel/",
  },
  FoerderorganisationBearbeitenTable: {
    getApi: () => "foerderorganisationen/",
  },
  rollenfreigabeListInFoerderorg: {
    getApi: ({ pk }) => `foerderorganisationen/${ pk }/rollenfreigaben/`,
  },
  FoerderorganisationTable: {
    getApi: () => "info/foerderorganisationen/?fields=pk&fields=o_name&fields=typ_name&fields=o_konzept_titel&fields=kategoriewerte",
  },
  basisformular: {
    getApi: () => "formulare/",
  },
  funktionsgruppen: {
    getApi: () => "funktionsgruppen/",
  },
  foerderregel: {
    getApi: () => "foerderregeln/",
  },
  gutachten: {
    getApi: () => "gutachten/",
  },
  instrument: {
    getApi: () => "foerderinstrumente/",
  },
  instrumentvariante: {
    getApi: () => "foerderinstrumentvarianten/",
  },
  impexpauftrag: {
    getApi: () => "importauftraege/"
  },
  adminKataloge: {
    getApi: () => "katalogadmin/",
  },
  KatalogDetailsList: {
    getApi: ({ id }) => `katalogadmin/${ id }/eintraege/`,
  },
  servicelogTable: {
    getApi: () => "servicelogs/",
  },
  benachrichtigungen: {
    getApi: () => "inbox/",
  },
  benachrichtigungen_outbox: {
    getApi: () => "outbox/",
  },
  anonymisierte_nutzer: {
    getApi: () => "anonymisierte_nutzer/",
  },
  modulobjektlisteelementlist: {
    getApi: ({ olId }) => `objektlisten/${ olId }/elemente/`
  },
  modulobjektlisteentscheidungelementlist: {
    getApi: ({ olId }) => `objektlisten/${ olId }/elemente/`
  },
  modulauszahlungslisteobjektlisteelementlist: {
    getApi: ({ olId }) => `objektlisten/${ olId }/auszahlungslistenelemente/`
  },
  modulantragslisteobjektlisteelementlist: {
    getApi: ({ olId }) => `objektlisten/${ olId }/antragslistenelemente/`
  },
  NutzerDatenSucheTable: {
    getApi: () => "nutzerdatensuche/",
  },
  nutzer: {
    getApi: () => "nutzer/",
  },
  NutzerListInAntragsteller: {
    getApi: ({ antragstellerPk }) => `antragstellerorganisationen/${ antragstellerPk }/nutzer/`,
    getApiOptions: () => "nutzer/",
  },
  foerderprogramm: {
    getApi: () => "foerderprogramme/",
  },
  FoerderangeboteListInFoerderprogramm: {
    getApi: ({ programmPk }) => `foerderprogramme/${ programmPk }/angebote/`,
    getApiOptions: () => undefined,
  },
  projektbericht: {
    getApi: () => "projektberichte/",
  },
  projektberichtvorlage: {
    getApi: () => "projektberichtvorlagen/",
  },
  personal: {
    getApi: () => "personal/",
  },
  AbwesenheitenListInPersonal: {
    getApi: ({ nutzerPk }) => `personal/${ nutzerPk }/abwesenheiten/`,
    getApiOptions: () => undefined,
  },
  KompetenzenListInPersonal: {
    getApi: ({ personalPk }) => `personal/${ personalPk }/kompetenzen/`,
    getApiOptions: () => undefined,
  },
  StellenumfangListInPersonal: {
    getApi: ({ personalPk }) => `personal/${ personalPk }/am_umfang/`,
    getApiOptions: () => undefined,
  },
  RolenAntragstellerListInNutzer: {
    getApi: ({ nutzerPk }) => `nutzer/${ nutzerPk }/antragstellerorgrollen/`,
    getApiOptions: () => "antragstellerorgrollen/", // ??oder undefined
  },
  RolenFoerderorganisationenListInNutzer: {
    getApi: ({ nutzerPk }) => `nutzer/${ nutzerPk }/foerderorgrollen/`,
    getApiOptions: () => "foerderorgrollen/", // ??oder undefined
  },
  RollenSystemorganisationenListInNutzer: {
    getApi: ({ nutzerPk }) => `nutzer/${ nutzerPk }/systemorgrollen/`,
    getApiOptions: () => "systemorgrollen/",
  },
  VertretungenListInNutzer: {
    getApi: ({ nutzerPk }) => `nutzer/${ nutzerPk }/vertretungen/`,
    getApiOptions: () => undefined,
  },
  AbwesenheitenListInNutzer: {
    getApi: ({ nutzerPk }) => `nutzer/${ nutzerPk }/abwesenheiten/`,
    getApiOptions: () => undefined,
  },
  rollen: {
    getApi: () => "rollen/",
  },
  sachbericht: {
    getApi: () => "sachberichtvorlagen/",
  },
  sammelvertraege: {
    getApi: () => "sammelvertraege/",
  },
  sitzung: {
    getApi: () => "sitzungen/?fields=pk&fields=si_sitzungsnr&fields=sitzungsart_kbez&fields=si_sitzungsdatum&fields=si_buchungsdatum&fields=si_veroeffentlichungsdatum&fields=status&fields=status_detail&fields=statusgruppe&fields=aufgabentermin",
  },
  SitzungsartenTable: {
    getApi: () => "sitzungsarten/",
  },
  sitzungsantraege: {
    getApi: () => "all_sitzungsantraege/",
  },
  sitzungslisteantrag: {
    getApi: ({ sitzungPk, topPk, sl_id, urlSuffix }) => `sitzungen/${ sitzungPk }/tops/${ topPk }/sitzungslisten/${ sl_id }/${ urlSuffix }/`,
    getApiOptions: () => "all_sitzungsantraege/", // ??oder undefined
  },
  syconfigadmin: {
    getApi: () => "syconfigadmin/",
  },
  berichte: {
    getApi: () => "tableau/",
  },
  TableauTable: {
    getApi: () => "tableau/?tab_sichtbar=True",
    getApiOptions: () => "tableau/",
  },
  tasklog: {
    getApi: () => "tasklogs/",
  },
  team: {
    getApi: () => "teams/",
  },
  templateadmin: {
    getApi: () => "templates/",
  },
  UmfrageList: {
    getApi: () => "formulare/",
  },
  vertrag: {
    getApi: () => "vertraege/",
  },
  workflowfolgeclientfunktion: {
    getApi: () => "clientfunktionen/",
  },
  workflowfolgen: {
    getApi: () => "workflowfolgen/",
  },
  workflowfolgeserverfunktion: {
    getApi: () => "serverfunktionen/",
  },
  VerwendungListInServerfunktion: {
    getApi: ({ serverfunktionPk }) => `workflowfolgen/?serverfunktionen=${ serverfunktionPk }`
  },
  workflowfolgestatusfunktion: {
    getApi: () => "statusfunktionen/",
  },
  VerwendungListInStatusfunktion: {
    getApi: ({ statusfunktionPk }) => `workflowfolgen/?statusfunktionen=${ statusfunktionPk }`
  },
  workflow: {
    getApi: () => "workflows/",
  },
  workflowtypen: {
    getApi: () => "workflowtypen/",
  },
  TableConfigList: {
    getApi: () => "tableconfigs/",
  },
  BerichtRueckfoerderungen: {
    getApi: () => "berichte/rueckforderungen/",
  },
  BerichtNutzerDatenschutzfragen: {
    getApi: () => "berichte/nutzer_datenschutzfragen/",
  },
  BerichtCoronaAntraege: {
    getApi: () => "berichte/corona_antraege/",
  },
  BerichtKuraQM: {
    getApi: () => "berichte/kura_qm/",
  },
  BerichtSchoeneProjekte: {
    getApi: () => "berichte/schoene_projekte/",
  },
  BerichtAstorgAM: {
    getApi: () => "berichte/astorg_am/",
  },
  BerichtLiegezeiten: {
    getApi: () => "berichte/liegezeiten_antraege/",
  },
  BerichtVertraegeSchlagworte: {
    getApi: () => "berichte/vertraege_schlagworte/",
  },
  BerichtVertraegeAltfaelle: {
    getApi: ({ tabId }) => `berichte/vertraege_altfaelle/?tab_id=${ tabId }`,
  },
  CalendarTable: {
    getApi: () => "termine/",
  },
  DokumenteSuche: {
    getApi: ({ modelKontext }) => `dokumente/${ modelKontext }/`,
    getApiOptions: () => undefined,
  },
  mitglieder: {
    getApi: ({ teamPk }) => `teams/${ teamPk }/mitglieder/`,
    getApiOptions: () => undefined,
  },
  DokumenteZurFoerderungTable: {
    getApi: () => "aktive_foerderdokumente/",
  },
  Projektberichte: {
    getApi: ({ objId, objName } = {}) => `projektberichte/?${ objName }=${ objId }`,
    getApiOptions: () => "projektberichte/",
  },
  OrganisationenListInProfil: {
    getApi: ({ nutzerPk }) => `profil/${ nutzerPk }/organisationen/`,
    getApiOptions: () => undefined,
  },
  VertretungenListInProfil: {
    getApi: ({ nutzerPk }) => `profil/${ nutzerPk }/vertretungen/`,
    getApiOptions: () => undefined,
  },
  AbwesenheitenListInProfil: {
    getApi: ({ nutzerPk }) => `profil/${ nutzerPk }/abwesenheiten/`,
    getApiOptions: () => undefined,
  },
  KompetenzenListInProfil: {
    getApi: ({ nutzerPk, personalPk }) => `profil/${ nutzerPk }/personal/${ personalPk }/kompetenzen/`,
    getApiOptions: () => undefined,
  },
  datenexport: {
    getApi: () => "datenexport/",
  },
  // Proandi /
  // AM
  AMZielListInPersonal: {
    getApi: ({ personalPk }) => `personal/${ personalPk }/am_zielsetzung/`,
    getApiOptions: () => undefined,
  },
  // AM /
  // AR
  akkreditierungsantrag: {
    getApi: () => "akkreditierungsantraege/",
  },
  akkrhochschule: {
    getApi: () => "akkrhochschulen/",
  },
  akkrstudiengang: {
    getApi: () => "akkrstudiengaenge/",
  },
  hochschule: {
    getApi: () => "hochschulen/",
  },
  programmakkreditierung: {
    getApi: () => "programmakkreditierungen/",
  },
  studiengang: {
    getApi: () => "studiengaenge/",
  },
  systemakkreditierung: {
    getApi: () => "systemakkreditierungen/",
  },
  BerichtAkkreditierungsantraege: {
    getApi: () => "berichte/akkreditierungsantraege/",
  },
  BerichtLexware: {
    getApi: () => "berichte/ar_lexware/?servicelog_exists=False",
  },
  // AR /
  // MDM
  BerichtRohschnitt: {
    getApi: () => "berichte/mdm_rohschnitt/",
  },
  BerichtFoerderantragMdm: {
    getApi: () => "berichte/mdm_antraege/",
  },
  BerichtProduktionsspiegelMdm: {
    getApi: () => "berichte/mdm_produktionsspiegel/",
  },
  // MDM /
  // KUMASTA
  BerichtFoerderantragKumasta: {
    getApi: () => "berichte/kumasta_antraege/",
  },
  BerichtFoerderungKumasta: {
    getApi: () => "berichte/kumasta_foerderungen/",
  },
  // KUMASTA /
  // DVV
  BerichtErhebungsbogen: {
    getApi: () => "berichte/erhebungsbogen/",
  },
  BerichtFoerderantragDvv: {
    getApi: () => "berichte/dvv_antraege/",
  },
  BerichtFormblattKurse: {
    getApi: () => "berichte/formblatt_kurse/",
  },
  BerichtFormblattSonstige: {
    getApi: () => "berichte/formblatt_sonstige/",
  },
  BerichtHorizontourZ: {
    getApi: () => "berichte/horizontour-z/",
  },
  BerichtHorizontourFb: {
    getApi: () => "berichte/horizontour-fb/",
  },
  BerichtMeKuDvv: {
    getApi: () => "berichte/bericht_me_ku_dvv/",
  },
  BerichtMeKuErhebungsbogen: {
    getApi: () => "berichte/bericht_me_ku_erhebungsbogen/",
  },
  BerichtMeKuFormblatt: {
    getApi: () => "berichte/bericht_me_ku_formblatt/",
  },
  Applikationskontext: {
    getApi: () => "applikationskontexte/",
  },
  Struktur: {
    getApi: () => "strukturen/",
  },
  ApplikationskontextRubrikenzuordnung: {
    getApi: ({ applikationskontext_id }) => `applikationskontexte/${ applikationskontext_id }/rubriken/`,
  },
  // DVV /
  // EG
  EntscheidungslisteEpIB: {
    getApi: () => "berichte/eg_entscheidungsliste_epib/",
  },
  EntscheidungslisteAPNRW: {
    getApi: () => "berichte/eg_entscheidungsliste_apnrw/",
  },
  FinanzuebersichtEpIB: {
    getApi: () => "berichte/eg_finanzuebersicht_epib/",
  },
  FinanzuebersichtAPNRW: {
    getApi: () => "berichte/eg_finzanzuebersicht_ap_nrw/",
  },
  PortfolioskizzeZFD: {
    getApi: () => "berichte/eg_portfolioskizze_zfd/",
  },
  ProjektanträgeZFD: {
    getApi: () => "berichte/eg_projektantraege_zfd/",
  },

  BuchungenTableEG: {
    getApi: ({ url, objektPk }) => `${ url }/${ objektPk }/buchungen/`,
  },
  // EG /
  // DSEE
  BerichtMinisterien: {
    getApi: () => "berichte/ministerien/",
  },
  // DSEE /
};

export default function getTableApi({ id, options = false, params }) {
  const TABLE_API = TABLE_APIS[id];
  if (!TABLE_API) {
    console.error(`ID '${ id }' nicht in 'TABLE_APIS'`);
    return undefined;
  }
  if (options) {
    if (isFunction(TABLE_API.getApiOptions)) {
      return TABLE_API.getApiOptions(params);
    }
  }
  return TABLE_API.getApi(params);
}
