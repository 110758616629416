import AngebotModalCreate from "../AngebotModalCreate/AngebotModalCreate.vue";
import AngebotModalSortConfirm from "../AngebotModalSortConfirm/AngebotModalSortConfirm.vue";
import AngebotVersionen from "../AngebotVersionen/AngebotVersionen.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import RegelsatzStatusLabel from "../../Regelsatz/RegelsatzStatusLabel/RegelsatzStatusLabel.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import { EventBus } from "../../../global/functions/event-bus";
import getTableApi from "../../../const/TableApis";

const TABLE_ID = "förderangebot";

// @vue/component
export default {
  name: "AngebotList",
  components: {
    AngebotModalCreate,
    AngebotModalSortConfirm,
    AngebotVersionen,
    PageTabTitle,
    PuxTranslate,
    RegelsatzStatusLabel,
    SmartTable,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_TXT_ANGEBOT_TABLE_ACTIONS_SHOW_DETAILS_",
            route: "root.foerderangebote.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [
          {
            permission: "foerderangebot.update",
            label: "_TXT_ANGEBOT_TABLE_ACTIONS_RESET_SORT_",
            callback: () => this.modal.sort = true,
            type: "button",
            title: "_TXT_ANGEBOT_TABLE_ACTIONS_RESET_SORT_TITLE_",
            className: "btn btn-default",
            icon: "glyphicon-sorting",
            htmlId: "angebot_sort",
          },
          {
            permission: "foerderangebot.create",
            label: "_TXT_ANGEBOT_TABLE_ACTIONS_CREATE_",
            callback: () => this.modal.create = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "angebot_create",
          },
        ],
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
        },
      },
      modal: {
        create: undefined,
        sort: undefined,
      },
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },
  },
  methods: {
    sort() {
      return this.postHttp({ url: `foerderangebote/reorder/` }).then(
        () => {
          this.addNotification({ text: "_MSG_ANGEBOT_TABLE_RESET_SORT_SUCCESS_" });
          EventBus.$emit(`updateTable${ TABLE_ID }`);
          this.closeModalSort();
        },
        () => {
          this.addNotification({ text: "_MSG_ANGEBOT_TABLE_RESET_SORT_ERROR_", type: "error" });
        }
      );
    },

    closeModalSort() {
      this.modal.sort = false;
    },

    closeModalCreate() {
      this.modal.create = false;
    },
  },
};
