import BankverbindungModalCreate from "../../../Geschaeftsregel/Module/Zahlungsverkehr/BankverbindungModalCreate/BankverbindungModalCreate.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import VertragSnapshotMixin from "../VertragSnapshotMixin";
import {
  CheckPermissionsSyncMixin,
} from "../../../../global/mixins/PermissionMixin";
import {
  FilterIbanMixin,
} from "../../../../global/mixins/FiltersMixin";

import {
  cloneDeep,
  get,
  forEach,
  isEmpty,
} from "lodash-es";

const DIFF_KEYS = [
  "aov_iban",
  "aov_bic",
  "aov_inhaber",
];

// @vue/component
export default {
  name: "VertragBankverbindung",
  components: {
    BankverbindungModalCreate,
    FormElement,
    FormstepDetail,
    PuxButton,
    PuxTranslate,
    SnapshotIcon,
  },
  mixins: [
    CheckPermissionsSyncMixin,
    FilterIbanMixin,
    HttpMixin,
    VertragSnapshotMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    vertrag: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "onSave",
    "updateVertrag",
  ],
  data() {
    return {
      loadingBankverbindungen: false,
      bankverbindungen: [],
      modelBankverbindung: undefined,
      errors: undefined,
      isModalCreateOpen: undefined,
      idPrefixForModalCreate: "aov_",
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        label: "_TXT_BANKVERBINDUNG_",
        info: "_TXT_VERTRAG_BANKVERBINDUNG_INFO_",
        openCallback: this.open,
        saveCallback: this.save,
      };
    },

    antragstellerPk() {
      return get(this.vertrag, "antragstellerorg.pk");
    },

    userPermissions() {
      return this.vertrag.user_permissions;
    },

    isBankverbindungEditable() {
      return this.checkPermissionsSync({
        perm: "vertrag.fvb.update",
        permArray: this.userPermissions,
      });
    },

    baseUrl() {
      return `vertraege/${ this.vertrag.pk }/`;
    },

    urlBankverbindungen() {
      return `antragstellerorganisationen/${ this.antragstellerPk }/bankverbindungen/`;
    },

    urlForSave() {
      return `${ this.baseUrl }fvb_bankverbindung/`;
    },

    optionsForFormElementBankverbindung() {
      const ID = "bankverbindung";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "select",
        label: "_LBL_VORHANDENE_BANKVERBINDUNG_",
        view: "v-alt",
        keyId: "pk",
        keyLabel: "label",
        search: true,
        searchList: ["label"],
        loading: this.loadingBankverbindungen,
        deselect: true,
      };
    },

    foerdervertragsbestaetigungLocal() {
      return this.vertrag.foerdervertragsbestaetigung || {};
    },

    currentBankverbindung() {
      if (this.foerdervertragsbestaetigungLocal.bankverbindung_obj) {
        return {
          aov_iban: this.filterIban(this.foerdervertragsbestaetigungLocal.bankverbindung_obj.aov_iban),
          aov_bic: this.foerdervertragsbestaetigungLocal.bankverbindung_obj.aov_bic,
          aov_inhaber: this.foerdervertragsbestaetigungLocal.bankverbindung_obj.aov_inhaber,
        };
      }
      return null;
    },

    currentBankverbindungSnapshot() {
      if (this.isSnapshot && this.vertragSnapshot.aov_iban) {
        return {
          aov_iban: this.filterIban(this.vertragSnapshot.aov_iban),
          aov_bic: this.vertragSnapshot.aov_bic,
          aov_inhaber: this.vertragSnapshot.aov_inhaber,
        };
      }
      return undefined;
    },

    vertragSnapshot() {
      return get(this.snapshotObject, "vertrag.bankverbindung_obj") || {};
    },

    diff() {
      const DIFF = {
        add: false,
        remove: false,
        changes: {},
      };
      if (this.isSnapshot) {
        if (!this.currentBankverbindung && this.currentBankverbindungSnapshot) {
          DIFF.remove = true;
        } else if (this.currentBankverbindung && !this.currentBankverbindungSnapshot) {
          DIFF.add = true;
        } else if (this.currentBankverbindung && this.currentBankverbindungSnapshot) {
          DIFF_KEYS.forEach(key => {
            if (this.currentBankverbindung[key] !== this.currentBankverbindungSnapshot[key]) {
              DIFF.changes[key] = true;
            }
          });
        }
      }
      return DIFF;
    },

    snapshotIconType() {
      if (this.diff.add) {
        return "add";
      }
      if (this.diff.remove) {
        return "delete";
      }
      if (!isEmpty(this.diff.changes)) {
        return "changes";
      }
      return undefined;
    },

    isDiff() {
      return this.diff.add ||
        this.diff.remove ||
        !isEmpty(this.diff.changes);
    },

    errorsLabelsOptions() {
      return [
        this.optionsForFormElementBankverbindung,
      ];
    },

    idForModalButton() {
      return `${ this.id }_btn_modal`;
    },

    selectorClose() {
      return `#${ this.idForModalButton }`;
    },
  },
  methods: {
    open() {
      this.modelBankverbindung = this.foerdervertragsbestaetigungLocal.bankverbindung || undefined;
      this.deleteErrors();
      this.loadBankverbindungen();
    },

    loadBankverbindungen() {
      this.loadingBankverbindungen = true;
      this.getListHttp({
        url: this.urlBankverbindungen,
      }).then(
        response => {
          this.bankverbindungen = this.setLabelsForBankverbindungen(response);
          this.loadingBankverbindungen = false;
        },
      );
    },

    setLabelsForBankverbindungen(response) {
      const LIST = [];
      forEach(response, item => {
        LIST.push({
          pk: item.pk,
          label: this.setLabelForBankverbindung(item),
        });
      });
      return LIST;
    },

    save() {
      return new Promise((resolve, reject) => {
        this.deleteErrors();
        this.putHttp({
          url: this.urlForSave,
          data: {
            bankverbindung: this.modelBankverbindung,
          },
        }).then(
          response => {
            this.updateVertragLocal(response);
            this.addNotification({ text: "_MSG_BANKVERBINDUNG_CHANGE_SUCCESS_" });
            this.$emit("onSave");
            resolve();
          },
          error => {
            this.errors = error.data;
            reject(error);
          },
        );
      });
    },

    updateVertragLocal(response) {
      const VERTRAGSBESTAETIGUNG = cloneDeep(this.foerdervertragsbestaetigungLocal);
      VERTRAGSBESTAETIGUNG.bankverbindung = response.bankverbindung;
      VERTRAGSBESTAETIGUNG.bankverbindung_obj = response.bankverbindung_obj;
      this.$emit("updateVertrag", {
        vertrag: {
          foerdervertragsbestaetigung: VERTRAGSBESTAETIGUNG,
        },
      });
    },

    setLabelForBankverbindung(item) {
      const BIC = item.aov_bic ? `, ${ item.aov_bic }` : "";
      return `${ item.aov_inhaber }, ${ this.filterIban(item.aov_iban, true) }${ BIC }`;
    },

    openModal() {
      this.isModalCreateOpen = true;
    },

    closeModalCreate({ response } = {}) {
      if (response) {
        this.addNewBankverbindung(response);
        this.setModelBankverbindung(response);
      }
      this.isModalCreateOpen = false;
    },

    addNewBankverbindung(response) {
      this.bankverbindungen.push({
        pk: response.pk,
        label: this.setLabelForBankverbindung(response),
      });
    },

    setModelBankverbindung(response) {
      this.modelBankverbindung = response.pk;
    },

    deleteErrors() {
      this.errors = undefined;
    },
  },
};
