// @vue/component
export default {
  name: "PruefkriteriumIcon",
  props: {
    value: {
      type: Boolean,
      default: () => null
    },
  },
  computed: {
    iconCls() {
      switch (this.value) {
      case true:
        return "glyphicon-ok text-success";
      case false:
        return "glyphicon-close text-danger";
      default:
        return "glyphicon-nicht-entschieden text-dark";
      }
    }
  }
};
