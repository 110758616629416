import {
  ref,
} from "vue";

export default function SitzungAntraegeNotGroupedAPI({ sitzungListeAntrag }) {
  const notizOptions = ref({
    fuerSitzung: true,
    fuerSitzungDefault: true,
    orgChoices: [],
    noOrgText: "Um Notizen zum Antrag erstellen zu können, wählen Sie bitte zunächst eine Antragstellerorganisation für diesen Antrag aus."
  });

  const updateSitzungListeAntrag = sitzungListeAntragLocal => {
    sitzungListeAntrag.value = sitzungListeAntragLocal;
  };

  return {
    notizOptions,
    updateSitzungListeAntrag,
  };
}
