import {
  LPolyline,
} from "../../../../global/components/PuxLeaflet";

import coordinatesInPxForZoom from "./coordinatesInPxForZoom";
import {
  cloneDeep,
  forEach,
  isEqual,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  components: {
    LPolyline,
  },
  data() {
    return {
      statusChangePolyline: undefined,
      statusPolylineMarkersDraggable: undefined,
      currentMarkerIndex: undefined,
      polylineLatlngs: [[]],
      polylineLatlngsClone: undefined,
      currentPolylineIndex: 0,
      currentPolylineIndexClone: 0,
      polyline: {
        color: "#f49900",
        colorActive: "red",
        colorAreale: "green",
      },
      polylineActiveIndex: undefined,
      markerPolyline: {
        url: "/api/assets/map-icon-polyline.png",
        size: [20, 20],
        anchor: [10, 10]
      },
    };
  },
  computed: {
    getTextTogglePolylineButton() {
      return this.statusChangePolyline ?
        "Quartier einzeichnen beenden" :
        "Quartier einzeichnen";
    },

    getKeyMarkersForPolyline() {
      return ({ iconIndex, lineIndex }) => `polyline_${ lineIndex }_${ iconIndex }`;
    },

    getPolylineColor() {
      return lineIndex => {
        if (this.polylineActiveIndex === lineIndex) {
          return this.polyline.colorActive;
        }
        return this.polyline.color;
      };
    },

    isShowPolylineMarkers() {
      return this.statusEditMode_mixin;
    },

    isShowDeleteButtonCurrentPolyline() {
      return !this.statusChangePolyline && !isUndefined(this.polylineActiveIndex);
    },
  },
  methods: {
    initPolyline() {
      if (this.model.punkte) {
        this.polylineLatlngs = cloneDeep(this.model.punkte);
        this.currentPolylineIndex = this.polylineLatlngs.length;
      } else {
        this.polylineLatlngs = [[]];
        this.currentPolylineIndex = 0;
      }
    },

    toggleActivatePolyline({ status } = {}) {
      const STATUS_LOCAL = isUndefined(status) ? this.statusChangePolyline : status;
      if (STATUS_LOCAL) {
        this.connectPolyline();
        this.setStatusPolylineMarkersDraggable({ status: true });
      } else {
        this.setStatusPolylineMarkersDraggable({ status: false });
        this.deselectPolyline();
        this.setClonePolyline();
      }
      this.statusChangePolyline = !STATUS_LOCAL;
    },

    setClonePolyline() {
      this.polylineLatlngsClone = cloneDeep(this.polylineLatlngs);
      this.currentPolylineIndexClone = this.currentPolylineIndex;
    },

    closeActivatePolyline() {
      this.polylineLatlngs = cloneDeep(this.polylineLatlngsClone);
      this.currentPolylineIndex = this.currentPolylineIndexClone;
      this.setStatusPolylineMarkersDraggable({ status: true });
      this.deselectPolyline();
      this.statusChangePolyline = false;
    },

    clickMapPolyline(arg) {
      this.deselectPolyline();
      this.setActiveMarkerEditableIndexUndefined();
      if (!this.statusChangePolyline) {
        return;
      }
      if (!this.polylineLatlngs[this.currentPolylineIndex]) {
        this.polylineLatlngs.push([]);
      }
      this.polylineLatlngs[this.currentPolylineIndex].push([
        arg.latlng.lat,
        arg.latlng.lng,
      ]);
    },

    onClickMarkerPolyline({ lineIndex, iconIndex }) {
      if (this.statusChangePolyline) {
        this.onClickMarkerPolylineEditable({ lineIndex, iconIndex });
      } else {
        this.selectPolyline({ lineIndex, iconIndex });
      }
    },

    selectPolyline({ lineIndex }) {
      this.polylineActiveIndex = lineIndex;
    },

    deselectPolyline() {
      this.polylineActiveIndex = undefined;
    },

    onClickMarkerPolylineEditable({ lineIndex, iconIndex }) {
      if (this.currentPolylineIndex === lineIndex && iconIndex === 0) {
        this.connectPolyline();
      }
    },

    connectPolyline() {
      if (!this.polylineLatlngs[this.currentPolylineIndex]) {
        return;
      }
      const POLYLINE_LENGTH = this.polylineLatlngs[this.currentPolylineIndex].length;
      if (POLYLINE_LENGTH > 2) {
        if (!isEqual(this.polylineLatlngs[this.currentPolylineIndex][0], this.polylineLatlngs[this.currentPolylineIndex][POLYLINE_LENGTH - 1])) {
          this.polylineLatlngs[this.currentPolylineIndex].push(cloneDeep(this.polylineLatlngs[this.currentPolylineIndex][0]));
        }
        this.currentPolylineIndex++;
      } else {
        this.polylineLatlngs.splice(this.currentPolylineIndex, 1);
      }
    },

    deleteCurrentPolyline() {
      this.polylineLatlngs.splice(this.polylineActiveIndex, 1);
      this.currentPolylineIndex--;
      if (this.currentPolylineIndex === 0) {
        this.polylineLatlngs = [[]];
      }
      this.deselectPolyline();
    },

    setStatusPolylineMarkersDraggable({ status }) {
      this.statusPolylineMarkersDraggable = status;
    },

    mouseDownPolylineMarker({ lineIndex, iconIndex }) {
      if (!this.statusPolylineMarkersDraggable) {
        return;
      }
      this.currentMarkerIndex = {
        lineIndex,
        iconIndex,
      };
    },

    mouseMovePolylineMarker(arg) {
      if (!this.statusPolylineMarkersDraggable || isUndefined(this.currentMarkerIndex)) {
        return;
      }
      this.updateCurrentPointInPolyline(arg);
    },

    mouseUpPolylineMarker({ arg }) {
      if (!this.statusPolylineMarkersDraggable) {
        return;
      }
      this.updateCurrentPointInPolyline(arg);
      this.currentMarkerIndex = undefined;
    },

    updateCurrentPointInPolyline(arg) {
      this.updateCurrentPointInPolylineWithIndex({ arg, lineIndex: this.currentMarkerIndex.lineIndex, iconIndex: this.currentMarkerIndex.iconIndex });
      if (this.currentMarkerIndex.iconIndex === 0) {
        this.updateCurrentPointInPolylineWithIndex({ arg, lineIndex: this.currentMarkerIndex.lineIndex, iconIndex: this.polylineLatlngs[this.currentMarkerIndex.lineIndex].length - 1 });
      } else if (this.currentMarkerIndex.iconIndex === this.polylineLatlngs[this.currentMarkerIndex.lineIndex].length - 1) {
        this.updateCurrentPointInPolylineWithIndex({ arg, lineIndex: this.currentMarkerIndex.lineIndex, iconIndex: 0 });
      }
    },

    updateCurrentPointInPolylineWithIndex({ arg, lineIndex, iconIndex }) {
      this.polylineLatlngs[lineIndex].splice(iconIndex, 1, [
        arg.latlng.lat,
        arg.latlng.lng,
      ]);
    },

    onClickPolyline({ arg, lineIndex }) {
      if (this.statusChangePolyline || !this.isShowPolylineMarkers) {
        return;
      }
      this.findIndexInPolyline({ lineIndex, latlng: arg.latlng, currentZoom: this.currentZoom });
    },

    findIndexInPolyline({ lineIndex, latlng, currentZoom }) {
      const POINT_INDEX = this.checkLineBigSquare({ lineIndex, latlng, currentZoom });
      if (!isUndefined(POINT_INDEX)) {
        this.setMarkerInPolyline({ lineIndex, pointIndex: POINT_INDEX, latlng });
      }
    },

    checkLineBigSquare({ lineIndex, latlng, currentZoom }) {
      const CURRENT_POLYLINE = this.polylineLatlngs[lineIndex];
      let diff = 1000000000000;
      let pointIndex;

      forEach(CURRENT_POLYLINE, (point, index) => {
        if (index === CURRENT_POLYLINE.length - 1) {
          return false;
        }
        const NEXT_POINT = CURRENT_POLYLINE[index + 1];
        const MIN_MAX_SQUARE = this.setMinAndMaxLatLng({ currentPoint: point, nextPoint: NEXT_POINT, currentZoom });

        if (this.isPointInCurrentSquare({ latlng, minMaxSquare: MIN_MAX_SQUARE })) {
          const DIFF_LOCAL = this.checkDistanceFromPointToLine({
            x: latlng.lat,
            x0: point[0],
            x1: NEXT_POINT[0],
            y: latlng.lng,
            y0: point[1],
            y1: NEXT_POINT[1]
          });

          if (DIFF_LOCAL <= diff) {
            pointIndex = index;
            diff = DIFF_LOCAL;
          }
        }
      });
      return pointIndex;
    },

    checkDistanceFromPointToLine({ x, x0, x1, y, y0, y1 }) {
      const Y_0_MINUS_Y_1 = y0 - y1;
      const Y_1_MINUS_Y_0 = y1 - y0;
      const X_1_MINUS_X_0 = x1 - x0;
      const NUMERATOR = Y_0_MINUS_Y_1 * x + X_1_MINUS_X_0 * y + (x0 * y1 - x1 * y0);
      const DENOMINATOR = Math.sqrt(Math.pow(X_1_MINUS_X_0, 2) + Math.pow(Y_1_MINUS_Y_0, 2));
      return Math.abs(NUMERATOR / DENOMINATOR);
    },

    setMinAndMaxLatLng({ currentPoint, nextPoint, currentZoom }) {
      let maxLat;
      let minLat;
      let maxLng;
      let minLng;
      if (+currentPoint[0] > +nextPoint[0]) {
        maxLat = +currentPoint[0] + coordinatesInPxForZoom[currentZoom];
        minLat = +nextPoint[0] - coordinatesInPxForZoom[currentZoom];
      } else {
        maxLat = +nextPoint[0] + coordinatesInPxForZoom[currentZoom];
        minLat = +currentPoint[0] - coordinatesInPxForZoom[currentZoom];
      }
      if (+currentPoint[1] > +nextPoint[1]) {
        maxLng = +currentPoint[1] + coordinatesInPxForZoom[currentZoom];
        minLng = +nextPoint[1] - coordinatesInPxForZoom[currentZoom];
      } else {
        maxLng = +nextPoint[1] + coordinatesInPxForZoom[currentZoom];
        minLng = +currentPoint[1] - coordinatesInPxForZoom[currentZoom];
      }
      return {
        maxLat,
        minLat,
        maxLng,
        minLng,
      };
    },

    isPointInCurrentSquare({ latlng, minMaxSquare }) {
      const LAT = +latlng.lat;
      const LNG = +latlng.lng;
      return !(LAT > minMaxSquare.maxLat || LAT < minMaxSquare.minLat || LNG > minMaxSquare.maxLng || LNG < minMaxSquare.minLng);
    },

    setMarkerInPolyline({ lineIndex, pointIndex, latlng }) {
      this.polylineLatlngs[lineIndex].splice(pointIndex + 1, 0, [latlng.lat, latlng.lng]);
    },
  },
};
