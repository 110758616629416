import AngularLink from "../../../../../../global/components/AngularLink/AngularLink.vue";
import PuxIcon from "../../../../../../global/components/PuxIcon/PuxIcon.vue";
import RollenModalAdd from "../RollenModalAdd/RollenModalAdd.vue";

import CheckPermissionsSyncMixin from "../../../../../../global/mixins/PermissionMixin";
import RolenListInNutzerMixin from "../RolenListInNutzer.mixin";

import getTableApi from "../../../../../../const/TableApis";

const TABLE_ID = "RollenSystemorganisationenListInNutzer";

// @vue/component
export default {
  name: "RollenSystemorganisationenList",
  components: {
    AngularLink,
    PuxIcon,
    RollenModalAdd,
  },
  mixins: [
    RolenListInNutzerMixin,
    CheckPermissionsSyncMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: "",
        cols: [
          {
            label: "_LBL_ROLLEN_LIST_STATUS_",
            id: "aktiv",
            slot: "aktiv",
          },
          {
            label: "_LBL_ROLLEN_LIST_ORGANISATION_",
            id: "org_name",
          },
          {
            label: "_LBL_ROLLEN_LIST_ROLLE_",
            id: "rolle_name",
            slot: "rolle_name",
          },
          {
            label: "_LBL_ROLLEN_LIST_GUELTIG_AB_",
            id: "snr_gueltig_ab",
            slot: "snr_gueltig_ab",
          },
          {
            label: "_LBL_ROLLEN_LIST_GUELTIG_BIS_",
            id: "snr_gueltig_bis",
            slot: "snr_gueltig_bis",
          },
        ],
        rowActions: [
          {
            label: "_LBL_ROLLEN_LIST_ACTION_ROW_DETAILS_",
            route: "root.rollenverwaltung.detail",
            routeParameters: [{ routeId: "id", rowPath: "rolle" }],
            isHidden: () => !this.canViewRoles
          },
          {
            label: "_LBL_ROLLEN_LIST_ROLLENZUORDNUNG_DEAKTIVIEREN_",
            callback: this.confirmDeactivateNutzerRole_mixin,
            isHidden: ({ item }) => !this.checkPermissionsSync({ perm: "nutzer.systemorgrollen.delete" }) || !item.aktiv,
          },
          {
            label: "_LBL_ROLLEN_LIST_GUELTIGKEITSZEITRAUM_ANPASSEN_",
            callback: this.openModalGueltigkeitszeitraumAnpassen_mixin,
            isHidden: ({ item }) => !this.checkPermissionsSync({ perm: "nutzer.systemorgrollen.update" }) || !item.aktiv,
          },
        ],
        actions: [
          {
            permission: "nutzer.systemorgrollen.create",
            label: "_LBL_ROLLEN_LIST_ROLLE_HINZUFUEGEN_",
            callback: this.addRolle_mixin,
            type: "button",
            className: "btn btn-default",
            icon: "glyphicon-plus",
            htmlId: "nutzer_systemorgrollen_create",
          },
        ],
        label: "_LBL_ROLLEN_LIST_SYSTEMORG_HEADER_",
        sortable: false,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
      },
      prefix: "snr",
      apiSufix: "systemorgrollen",
      modalAddOptionsList: [
        {
          id: "rolle",
          type: "select",
          required: true,
          url: "sysorgrolle/?fields=sor_id&fields=sor_kbez&fields=sor_bez",
          keyId: "sor_id",
          keyLabelCallback: ({ item }) => `${ item.sor_kbez } - ${ item.sor_bez }`,
          search: true,
          searchList: ["sor_kbez", "sor_bez"],
          label: "_LBL_ROLLEN_LIST_ROLLE_",
          view: "v",
        },
        {
          id: "systemorg",
          type: "select",
          required: true,
          label: "_LBL_ROLLEN_LIST_SYSTEMORG_",
          url: "katalog/?key=sysorganisation",
          keyId: "pk",
          keyLabel: "so_name",
          view: "v",
        },
        {
          id: "gueltigkeit",
          type: "daterange",
          label: "_LBL_ROLLEN_LIST_GUELTIGKEITSZEITRAUM_",
          view: "v-alt",
          placeholderMin: "_LBL_ROLLEN_LIST_AB_HEUTE_",
          placeholderMax: "_LBL_ROLLEN_LIST_UNEINGESCHRAENKT_",
        },
      ],
    };
  },
  computed: {
    canViewRoles() {
      return this.checkPermissionsSync({ perm: "rollen.view" });
    },
  },
  created() {
    this.initUrl();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { nutzerPk: this.nutzer.pk } });
    },
  },
};
