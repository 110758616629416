import NameUndModul from "./NameUndModul/NameUndModul.vue";
import Konfiguration from "./Konfiguration/Konfiguration.vue";
import Ausfuehrung from "./Ausfuehrung/Ausfuehrung.vue";
import VerwendungList from "./VerwendungList/VerwendungList.vue";

export default {
  NameUndModul,
  Konfiguration,
  Ausfuehrung,
  VerwendungList,
};
