import HttpMixin from "../../../../../global/mixins/HttpMixin";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import PuxCloak from "../../../../../global/components/PuxCloak/PuxCloak.vue";
import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";

// @vue/component
export default {
  name: "WorkflowsDetailsVisualisierungStateModal",
  components: {
    Modal,
    PuxTranslate,
    PuxCloak,
    AngularLink,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    aufgabePk: {
      type: String,
      required: true,
      info: "Aufgabe UUID",
    },
    selectorClose: {
      type: [String, Array],
      required: true,
      info: "CSS-Selector für Fokus, wenn man Modal-Fenster schließt",
    },
    close: {
      type: Function,
      required: true,
      info: "Schießen Modal-Fenster",
    },
  },
  data() {
    return {
      options: {
        showCloseButton: true,
        required: true,
      },
      statusLoading: true,
      aufgabe: {},
      berechtigungen: {},
    };
  },
  computed: {
    extraForTranslate() {
      return {
        au_kbez: this.aufgabe.au_kbez,
      };
    },

    berechtigungenRollen() {
      return this.berechtigungen.rollen || [];
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      Promise.all([
        this.getHttp({
          url: `aufgaben/${ this.aufgabePk }/`
        }),
        this.getHttp({
          url: `aufgaben/${ this.aufgabePk }/berechtigungen/`
        }),
      ]).then(
        responses => {
          this.aufgabe = responses[0];
          this.berechtigungen = responses[1];
          this.statusLoading = false;
        }
      );
    },
  },
};
