"use strict";

import BaseModel from "dias/core/models/baseModel";

class CSVDownload extends BaseModel {
  constructor() {
    super(undefined);
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.requestCsvAsync = (baseUrl, params, action) => collection.oneUrl("csv", this.combinePaths(baseUrl, action || "csv_async")).get(params);
    collection.getCsvLink = (baseUrl, params) => this.combinePaths(baseUrl, "csv") + "?" + this.$httpParamSerializer(params);
    collection.getAsyncCsvLink = (baseUrl, taskId, action) => this.combinePaths(baseUrl, action || "csv_download") + "?" + this.$httpParamSerializer({ task_id: taskId });

    return collection;
  }

  onServiceBuild() {
    this.$httpParamSerializer = this.$injector.get("$httpParamSerializer");
  }

  combinePaths(path1, path2) {
    let trenner1 = "";
    let trenner2 = "";
    if (path1.length > 0 && path1[path1.length - 1] !== "/" &&
       path2.length > 0 && path2[0] !== "/") {
      trenner1 = "/";
    }
    if (path2.length > 0 && path1[path2.length - 1] !== "/") {
      trenner2 = "/";
    }
    return path1 + trenner1 + path2 + trenner2;
  }
}

export default CSVDownload;
