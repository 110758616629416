"use strict";

import richtext from "dias/core/richtext";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import parameter from "./parameter";

import "angular-input-masks";

const dynamische_zahlen = angular.module("dias.standard_foerdermodule.dynamische_zahlen", [
  "ui.utils.masks",
  richtext.name,
  foerdermoduleRegistry.name,
  parameter.name,
]);

export default dynamische_zahlen;
