"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";
import diasTable from "dias/core/table";
import models from "./models";

const modul = angular.module("dias.budget", [
  uiRouter,
  diasTable.name,
  models.name,
]);

modul.config($stateProvider => {
  $stateProvider

    // Budget-Liste
    .state("root.budget", {
      url: "/budgets/",
      views: {
        "main@root": {
          template: "<div vueinit><vue-budget-list></vue-budget-list></div>"
        }
      },
      data: {
        permissions: ["budget.view"]
      }
    })

    .state("root.budget.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><budget-details></budget-details></div>"
        }
      },
    });
});

export default modul;
