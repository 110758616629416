var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :ctx.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "ctx.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in ctx.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<div class=\"modal-body\"><div class=\"row\"><section class=\"main col-10 offset-1\"><div ng-attr-id=\"{{ ctx.DiasNotification.form.containerId }}\"></div><form><fieldset><div>");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"az_betrag\" ng-model=\"ctx.az_betrag\" type=\"text\" name=\"az_betrag\" dias-decimal-field aria-required=\"false\" ng-attr-aria-invalid=\"{{ctx.errors.az_betrag ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "az_betrag", "{{ ctx.gettext('Auszahlungsbetrag') }}");
buf.push("</div><div>");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"az_datum_ausgezahlt\" ng-model=\"ctx.az_datum_ausgezahlt\" type=\"text\" name=\"az_datum_ausgezahlt\" bs-datepicker aria-required=\"false\" ng-attr-aria-invalid=\"{{ctx.errors.az_datum_ausgezahlt ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "az_datum_ausgezahlt", "{{ ctx.gettext('Auszahlungsdatum') }}");
buf.push("</div></fieldset></form></section></div></div><div class=\"modal-footer\"><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"exitWithCallback(ctx.saveAuszahlungsbetrag)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"exit()\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-default\"><span>Abbrechen</span></button></div></div></div>");;return buf.join("");
}