import PuxTabs from "../../../global/components/PuxTabs/PuxTabs.vue";

import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "PeriodsTabs",
  components: {
    PuxTabs,
  },
  props: {
    periods: {
      type: Array,
      required: true,
    },
    idForActiveTab: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      tabList: [],
      tabAll: {
        label: "_TXT_KF_PERIODEN_UEBERBLICK_",
        id: "all",
        periodKey: "all",
        title: "_LINK_KF_PERIODEN_TAB_UEBERBLICK_TITLE_",
      },
      tabSum: {
        label: "_TXT_KF_PERIODEN_GESAMT_",
        id: "sum",
        periodKey: "sum",
        title: "_LINK_KF_PERIODEN_TAB_GESAMT_TITLE_",
      },
    };
  },
  computed: {
    periodsLocal() {
      return this.periods;
    },
  },
  watch: {
    periodsLocal: {
      immediate: true,
      handler() {
        this.initTabList();
      },
    },
  },
  created() {
    this.initTabList();
  },
  methods: {
    initTabList() {
      const TAB_LIST = [];
      TAB_LIST.push(this.tabAll);
      forEach(this.periodsLocal, (period, index) => {
        TAB_LIST.push({
          label: period.pe_kbez,
          id: index,
          period: period,
          periodKey: period.pk,
          title: "_LINK_KF_PERIODEN_TAB_PERIOD_TITLE_{{pe_kbez}}_",
          extra: {
            pe_kbez: period.pe_kbez,
          },
        });
      });
      TAB_LIST.push(this.tabSum);

      this.tabList = TAB_LIST;
    },
  },
};
