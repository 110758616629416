import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";

import FinanzierugMixin from "../Mixins/FinanzierugMixin";

import { EventBus } from "../../../../global/functions/event-bus";
import {
  concat,
  get,
  indexOf,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "KostenabhaengigeFinanzierung",
  components: {
    KFStandard,
  },
  mixins: [
    FinanzierugMixin,
  ],
  data() {
    return {
      allowsInput: true,
    };
  },
  computed: {
    betragsrechner() {
      return this.modelParameter.betragsrechner || "kostenabhaengige_finanzierung_rechner";
    },

    statusHideButtonAdd() {
      return this.positionen.length > 0;
    },

    sumLocal() {
      // Im Zweifelsfalle immer den Wert aus der aktuellen Berechnung
      // zurückgeben:
      if (!isNil(get(this.infoprovider, `details[${ this.regel.afr_id }].betrag`))
        && this.infoprovider.gespeichert) {
        return this.filterCurrency(this.infoprovider.details[this.regel.afr_id].betrag || 0);
      }
      return this.filterCurrency(this.antragKoFiArt.summe || 0);
    },
  },
  created() {
    EventBus.$on("updateZuschuss", this.onUpdateZuschuss);
  },
  beforeUnmount() {
    EventBus.$off("updateZuschuss", this.onUpdateZuschuss);
  },
  methods: {
    updateInfoproviderLocal_KFBaseMixin({ response }) {
      this.reloadPositionen_KFBaseMixin({ statusFirst: false });
      this.updateInfoprovider({ response });
    },
    onUpdateZuschuss({ antragKoFiArt }) {
      const ARTEN = concat(get(this.modelParameter, "kostenarten_summe", []), get(this.modelParameter, "kostenarten_ausschluss", []));
      if (!isNil(antragKoFiArt) && indexOf(ARTEN, antragKoFiArt.artPk) !== -1) {
        this.reloadPositionen_KFBaseMixin({ statusFirst: false });
      }
    }
  }
};
