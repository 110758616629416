"use strict";

import StandardFinanzierungController from "../../finanzierung.controller";

class EhrenamtspauschaleController extends StandardFinanzierungController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService) {
    super($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService);
    this.name = "EhrenamtspauschaleController";
    this.allowsInput = true;
  }

  isEhrenamtMoeglich() {
    // Die Erfassung von Ehrenamt ist nur dann möglich, wenn der Rechner
    // gesagt hat, dass es ok ist.
    if (!this.infoprovider || !this.infoprovider.status || !this.modul.regel.pk) {
      return false;
    } 
    const detailinfo = this.infoprovider.details[this.modul.regel.pk];
    if (detailinfo) {
      return detailinfo.status === 1;
    }
  }

  hasValue() {
    return ((this.vertragFinanzierungsart && this.vertragFinanzierungsart.vf_betrag_aktualisiert > 0) ||
            (this.antragKoFiArt && this.antragKoFiArt.summe > 0));
  }

  hasSnapshotDiff() {
    return (this.snapshotdiff && this.snapshotdiff.modified && this.snapshotdiff.modified[this.antragKoFiArt.pk]);
  }

  isEhrenamtVisible() {
    return (this.isEhrenamtMoeglich() || this.hasValue() || this.hasSnapshotDiff());
  }

  isEhrenamtEditable() {
    return !this.readonly && this.isEhrenamtMoeglich();
  }
}

export default EhrenamtspauschaleController;
