"use strict";

import BaseModel from "dias/core/models/baseModel";


class Antraege extends BaseModel {
  constructor() {
    super("all_sitzungsantraege");
  }

  extendModel(model) {
    model = super.extendModel(model);

    // Ist kein Sitzungsantrag-Objekt
    if (angular.isUndefined(model.antrag)) {
      return model;
    }
    if (model.antrag) {
      model.antrag = angular.extend(this.sitzungfoerderantrag.one(model.antrag.pk), model.antrag);
      model.antrag.a_zuschussbeantragt = Number(model.antrag.a_zuschussbeantragt);
    }
    return model;
  }

  onServiceBuild() {
    this.sitzungfoerderantrag = this.$injector.get("SitzungsFoerderantrag");
  }
}

export default Antraege;
