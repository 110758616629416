"use strict";

import BaseModel from "dias/core/models/baseModel";
import FoerderfinderAntwort from "./foerderfinderantwort.service";


class FoerderfinderFrage extends BaseModel {
  constructor() {
    super("foerderfinderfragen", {
      subPaths: {
        antworten: new FoerderfinderAntwort(),
      }
    });
  }
}

export default FoerderfinderFrage;
