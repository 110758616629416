"use strict";

import foerderorgselectTemplate from "./foerderorg.element.select.jade";
import {
  forEach,
} from "lodash-es";

const KEIN_VERBAND_PK = "371ce058-9188-03f9-e053-d8d5a8c0c467";

class FoerderorgSelectController {
  /*@ngInject*/
  constructor($scope, $q, $compile, $element, FoerderorgTyp, Foerderorganisation, SyConfigs, i18n) {
    this.SyConfigs = SyConfigs;
    this.gettext = i18n.gettext;
    const promises = [];
    this.foerderorgFieldFilter = {
      fields: ["pk", "o_id", "typ", "o_otid", "o_name"]
    };
    this.mode = "pk";

    // Förderorganisationsstruktur laden
    this.foerderorgTypen = [];
    promises.push(FoerderorgTyp.getList());

    // Initiale Daten laden
    if (angular.isString(this.model)) {
      // mit PK initialisiert
      this.mode = "pk";
      promises.push(Foerderorganisation.one(this.model).get());
    } else if (angular.isObject(this.model)) {
      // mit Förderorg-Objekt initialisiert
      this.mode = "obj";
      const defer = $q.defer();
      defer.resolve(this.model);
      promises.push(defer.promise);
    }

    this.loading = 1;
    $q.all(promises).then(
      responses => {
        const tmp = {};
        angular.forEach(responses[0], item => {
          tmp[item.ot_pos] = tmp[item.ot_pos] || { pos: item.ot_pos, typen: [], bez: "", data: null, selected: [] };
          tmp[item.ot_pos].typen.push(item);
        });
        angular.forEach(tmp, item => {
          item.typen.sort((a, b) => a.ot_kbez.localeCompare(b.ot_kbez));
          item.bez = item.typen[0].ot_kbez;
          item.filter = { typ: [item.typen[0].pk] };
          for (let i = 1; i < item.typen.length; i++) {
            item.bez = `${ item.bez } / ${ item.typen[i].ot_kbez }`;
            item.filter.typ.push(item.typen[i].pk);
          }
          this.foerderorgTypen.push(item);
        });
        this.foerderorgTypen.sort((a, b) => a.ot_pos - b.ot_pos);
        // Daten zu oberstem Typ holen.
        this.loading++;
        Foerderorganisation.search(angular.merge(
          {},
          this.foerderorgTypen[0].filter,
          this.foerderorgFieldFilter
        )).then(
          this.receiveFoerderorgTypData(0)
        ).finally(() => this.loading--);

        // Mit Selektion initialisiert
        if (responses.length > 1) {
          const orgs = responses[1].foerderorghierarchie.slice().reverse();
          angular.forEach(orgs, item => {
            angular.forEach(this.foerderorgTypen, fitem => {
              if (fitem.typen.findIndex(v => v.pk === item.o_otid) !== -1) {
                fitem.selected = [{ id: item.o_id }];
                if (!fitem.data) {
                  this.updateFoerderorgData(fitem, [item]);
                }
              }
            });
          });
          for (let i = 1; i < this.foerderorgTypen.length; i++) {
            if (this.foerderorgTypen[i - 1].selected.length === 0) {
              break;
            }
            this.loading++;
            Foerderorganisation.search(angular.merge(
              {},
              this.foerderorgTypen[i].filter,
              { uebergeordnete_org: this.foerderorgTypen[i - 1].selected[0].id },
              this.foerderorgFieldFilter
            )).then(
              this.receiveFoerderorgTypData(i)
            ).finally(() => this.loading--);
          }
          this.changeChildFoerderorg(this.foerderorgTypen.length - 1);
        }
        this.selectSettings = [];
        this.emptyLabels = [];
        forEach(this.foerderorgTypen, typ => {
          this.selectSettings.push({
            smartButtonTextConverter: text => text,
            enableSearch: typ.pos === 1 ?
              this.SyConfigs.wert("ast_default") && this.SyConfigs.wert("ast_default").org_suche :
              this.SyConfigs.wert("ast_default") && this.SyConfigs.wert("ast_default").untergeordnete_org_suche,
          });
          this.emptyLabels.push(
            typ.pos === 1 || this.SyConfigs.wert("ast_default") && !this.SyConfigs.wert("ast_default").untergeordnete_keine_auswahl ?
              undefined :
              this.gettext("_LBL_SELECT_FOERDERORGANISATION_UNTERGEORDNETE_EMPTY_")
          );
        });
      }
    ).finally(() => this.loading--);

    // Abhängige FörderOrg-Dropdowns aktualisieren
    // idx ist die Position in foerderorgTypen, die sich geändert hat.
    // Es muss also idx + 1 aktualisiert werden.
    this.changeChildFoerderorg = idx => {
      for (let i = this.foerderorgTypen.length - 1; i > idx; i--) {
        this.foerderorgTypen[i].selected.splice(0);
      }
      const findOrg = i => v => v.pk === this.foerderorgTypen[i].selected[0].id;
      for (let i = idx; i >= 0; i--) {
        if (this.foerderorgTypen[i].selected.length > 0 && this.foerderorgTypen[i].selected[0].id && this.foerderorgTypen[i].selected[0].id !== "null") {
          if (this.mode === "pk") {
            this.model = this.foerderorgTypen[i].selected[0].id;
          } else {
            this.model = this.foerderorgTypen[i].rawData.find(findOrg(i));
          }
          break;
        }
      }

      // Prüfen, ob idx deselektiert wurde
      if (this.foerderorgTypen[idx].selected.length === 0 || this.foerderorgTypen[idx].selected[0].id === "null") {
        return;
      }
      // Prüfen, ob idx ist letzter Eintrag ist:
      if (this.foerderorgTypen[idx + 1] === undefined) {
        return;
      }
      this.foerderorgTypen[idx + 1].data = undefined;
      // Daten holen und aktualisieren
      this.loading++;
      Foerderorganisation.search(angular.merge(
        {},
        this.foerderorgTypen[idx + 1].filter,
        { uebergeordnete_org: this.foerderorgTypen[idx].selected[0].id },
        this.foerderorgFieldFilter
      )).then(
        this.receiveFoerderorgTypData(idx + 1)
      ).finally(() => this.loading--);
    };

    this.receiveFoerderorgTypData = i => data => {
      this.updateFoerderorgData(this.foerderorgTypen[i], data);
    };
  }

  updateFoerderorgData(typEbene, orgs) {
    orgs.sort((a, b) => {
      if (a.pk === KEIN_VERBAND_PK) {
        return 1;
      }
      return a.o_name.localeCompare(b.o_name);
    });
    typEbene.rawData = orgs;
    typEbene.data = orgs.map(v => {
      return { label: v.o_name, value: v.pk }; 
    });
    const filtered = typEbene.typen.filter(
      t => orgs.findIndex(o => t.pk === (o.typ || o.o_otid)) !== -1
    ).map(t => t.ot_kbez);
    if (filtered.length > 0) {
      typEbene.bez = filtered.join(" / ");
    } else {
      typEbene.bez = "Unbekannt";
    }
  }

  getLandesverbandHilfeText() {
    return this.SyConfigs.wert("ast_default") && !this.SyConfigs.wert("ast_default").untergeordnete_orgs_pflicht ?
      this.gettext("_TXT_SELECT_FOERDERORGANISATION_2_HELPTEXT_") : undefined ;
  }
}

export default {
  template: foerderorgselectTemplate(),
  controller: FoerderorgSelectController,
  controllerAs: "vm",
  bindings: {
    model: "=ngModel",
    errors: "<errors",
    startTabindex: "<tabindexStart"
  }
};
