"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";
import template from "./config.jade";
import templateRo from "./readonly.jade";
import controller from "./controller";
import controllerRo from "./readonly.controller";


const dynTabelle = angular.module("dias.dynamische_tabelle.parameter.dynamische_tabelle", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("dynamische_tabelle", "parametermodul-dyn-tabelle");
  })
  .component("parametermodulDynTabelle", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulDynTabelleRo", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: templateRo(),
    controller: controllerRo,
    controllerAs: "vm",
  });
export default dynTabelle;
