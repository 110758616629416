"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";

export default class EditFrageController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification) {
    super($q, $scope, DiasNotification);

    this.save = () => {
      return this.model.put(this.model).then(
        () => {
          $scope.model.merge($scope.model, this.model);
          DiasNotification.page.success("Änderungen erfolgreich gespeichert");
        },
        errors => {
          const errorsLabel = {
            ffr_name: "Interne Bezeichnung",
            ffr_text: "Text",
            ffr_ueberschrift_: "Überschrift",
          };
          return this.onErrors(errors, errorsLabel);
        }
      );
    };
  }
}
