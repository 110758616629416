import {
  ref,
  computed,
} from "vue";

import FiltersAPI from "../../../../../libs/vue/client/vue/global/compositionAPI/FiltersAPI";
import HttpAPI from "../../../../../libs/vue/client/vue/global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../../libs/vue/client/vue/global/compositionAPI/PermissionAPI";

import KAkkreditierungsstatusUUIDs from "../../../../../libs/vue/client/vue/const/KAkkreditierungsstatusUUIDs";
import VersionStatus from "../const/VersionStatus";

import {
  find,
} from "lodash-es";

export default function AkkreditierungVersionoptionsAPI({
  emit,
  akkreditierung,
  akkreditierungDisplayVersion,
  gutachtenTauschenAkkr,
  versions,
  basePerm = "programmakkreditierung",
  baseUrl = "programmakkreditierungen",
}) {
  const {
    filterDate,
  } = FiltersAPI();
  const {
    checkPermissionsSync,
  } = PermissionAPI();
  const {
    addNotification,
    putHttp,
  } = HttpAPI();

  const loading = ref(false);
  const isProgrammakkreditierung = basePerm === "programmakkreditierung";

  const bearbeitungsVersion = computed(() => {
    return find(versions.value, { versionstatus: VersionStatus.BEARBEITUNG });
  });

  const modalSaveCommentUngueltigId = computed(() => {
    return `modal_save_comment_ungueltig_${ akkreditierungDisplayVersion.value.pk }`;
  });
  const akkreditierungGutachtenAustauschenId = computed(() => {
    return `akkreditierung_gutachten_austauschen_${ akkreditierungDisplayVersion.value.pk }`;
  });
  const akkreditierungBearbeitenId = computed(() => {
    return `akkreditierung_bearbeiten_${ akkreditierungDisplayVersion.value.pk }`;
  });
  const akkreditierungDeaktivierenId = computed(() => {
    return `akkreditierung_deaktivieren_${ akkreditierungDisplayVersion.value.pk }`;
  });
  const akkreditierungAktivierenId = computed(() => {
    return `akkreditierung_aktivieren_${ akkreditierungDisplayVersion.value.pk }`;
  });
  const akkreditierungBearbeitungAbbrechenId = computed(() => {
    return `akkreditierung_bearbeitung_abbrechen_${ akkreditierungDisplayVersion.value.pk }`;
  });
  const akkreditierungÄnderungenVeröffentlichenId = computed(() => {
    return `akkreditierung_änderungen_veröffentlichen_${ akkreditierungDisplayVersion.value.pk }`;
  });
  const hasPermissionProgramakkreditierungUpdate = computed(() => {
    return checkPermissionsSync({
      perm: `${ basePerm }.update`,
      permArray: akkreditierung.value.user_permissions
    });
  });
  const hasPermissionAuflagenerfuellungEintragen = computed(() => {
    return checkPermissionsSync({
      perm: `${ basePerm }.auflagenerfuellung.eintragen`,
      permArray: akkreditierung.value.user_permissions
    });
  });
  const hasPermissionGutachtenAustauschen = computed(() => {
    return checkPermissionsSync({
      perm: `${ basePerm }.gutachten.austauschen`,
      permArray: akkreditierung.value.user_permissions
    });
  });
  const hasPermissionUpdate = computed(() => {
    return checkPermissionsSync({
      perm: `${ basePerm }.update`,
      permArray: akkreditierung.value.user_permissions
    });
  });

  const canCreateVersion = computed(() => {
    if (!(akkreditierungDisplayVersion.value && versions.value)) {
      return false;
    }

    return hasPermissionProgramakkreditierungUpdate.value
      && !bearbeitungsVersion.value
      && (akkreditierungDisplayVersion.value.versionstatus === VersionStatus.AKTUELL
          || akkreditierungDisplayVersion.value.versionstatus === VersionStatus.ZUKUENFTIG);
  });
  const canDeactivate = computed(() => {
    if (!(akkreditierungDisplayVersion.value && versions.value)) {
      return false;
    }

    return hasPermissionProgramakkreditierungUpdate.value
      && !bearbeitungsVersion.value
      && akkreditierungDisplayVersion.value.versionstatus === VersionStatus.AKTUELL;
  });
  const canActivate = computed(() => {
    const gueltigBisField = isProgrammakkreditierung ? "pro_gueltig_bis" : "aks_gueltig_bis";
    if (!(akkreditierungDisplayVersion.value && akkreditierungDisplayVersion.value[gueltigBisField] && versions.value)) {
      return false;
    }

    return hasPermissionUpdate.value
      && !bearbeitungsVersion.value
      && akkreditierungDisplayVersion.value.versionstatus === VersionStatus.UNGUELTIG;
  });
  const canChangeGutachten = computed(() => {
    return akkreditierungDisplayVersion.value
      && (gutachtenTauschenAkkr.value === null
        && hasPermissionGutachtenAustauschen.value
        && akkreditierungDisplayVersion.value.versionstatus === VersionStatus.AKTUELL);
  });
  const canEnterAufgabenerfuellung = computed(() => {
    const versionStatus = akkreditierungDisplayVersion.value && (akkreditierungDisplayVersion.value.versionstatus === VersionStatus.AKTUELL);
    const akkreditierungStatus = akkreditierungDisplayVersion.value.status === KAkkreditierungsstatusUUIDs.AKKREDITIERT_MIT_AUFLAGEN
      || akkreditierungDisplayVersion.value.status === KAkkreditierungsstatusUUIDs.AKKREDITIERT_AUFLAGEN_TEILW_ERFUELLT;

    return hasPermissionAuflagenerfuellungEintragen.value
        && versionStatus
        && akkreditierungStatus;
  });
  const canUpdateVersion = computed(() => {
    return akkreditierungDisplayVersion.value.versionstatus === VersionStatus.BEARBEITUNG;
  });
  const programmakkreditierungAkkrUngueltigSetzenUrl = computed(() => {
    return `${ baseUrl }/${ akkreditierungDisplayVersion.value.pk }/akkr_ungueltig_setzen/`;
  });
  const getItemStyle = item => {
    return {
      "font-weight": item.versionstatus === VersionStatus.AKTUELL ? 600 : 400,
    };
  };

  const confirmOptions = ref(undefined);
  const akkreditierungDeaktivierenModalOptions = ref(undefined);
  const versionSelectOptionsHtmlId = computed(() => {
    return `${ akkreditierungDisplayVersion.value.pk }_version_select`;
  });
  const selectorClose = computed(() => {
    return `#${ versionSelectOptionsHtmlId.value }`;
  });
  const versionSelectOptions = computed(() => {
    return {
      id: "versionSelect",
      htmlId: versionSelectOptionsHtmlId.value,
      type: "select",
      label: "Version wählen",
      required: false,
      view: "v",
      keyId: "pk",
      slot: "versionProgram",
      menuWidthAll: true,
      placeholder: "Wählen Sie die Version aus, die Sie ansehen wollen.",
      data: versions.value,
      hideIf: versions.value.length < 2,
    };
  });
  const AKKREDITIERUNG_DEAKTIVIEREN_MODAL_OPTIONS = {
    showCloseButton: true,
    header: "Akkreditierung deaktivieren",
    list: [
      {
        id: "pro_ungueltig_kommentar",
        type: "richtext",
        label: "Begründung zur Deaktivierung der Akkreditierung",
        view: "v",
        required: true,
        disabled: false,
      },
    ],
  };
  const closeModal = () => {
    confirmOptions.value = undefined;
    akkreditierungDeaktivierenModalOptions.value = undefined;
  };
  const deleteVersion = () => {
    closeModal();
    emit("deleteVersion", akkreditierungDisplayVersion.value);
  };
  const publishVersion = () => {
    closeModal();
    emit("publishVersion", akkreditierungDisplayVersion.value);
  };
  const CONFIRM_OPTIONS_BEARBEITUNG_ABBRECHEN = {
    title: "Bearbeitung abbrechen?",
    msg: "Die von Ihnen eingebenen Daten werden gelöscht und die ursprüngliche Version wieder hergestellt.",
    okLabel: "Fortfahren",
    okClass: "btn btn-danger",
    okCallback: deleteVersion,
    cancelCallback: closeModal,
    loading: false,
  };
  const CONFIRM_OPTIONS_ANDERUNGEN_VEROFFENTLICHEN = {
    title: "Änderungen veröffentlichen?",
    msg: "Die Daten der Akkreditierung werden der Öffentlichkeit zur Verfügung gestellt.",
    okLabel: "Fortfahren",
    okClass: "btn btn-danger",
    okCallback: publishVersion,
    cancelCallback: closeModal,
    loading: false,
  };
  const openModalBearbeitungAbbrechen = () => {
    confirmOptions.value = CONFIRM_OPTIONS_BEARBEITUNG_ABBRECHEN;
  };
  const openModalAnderungenVeroffentlichen = () => {
    confirmOptions.value = CONFIRM_OPTIONS_ANDERUNGEN_VEROFFENTLICHEN;
  };
  const openCommentUngueltigModal = () => {
    akkreditierungDeaktivierenModalOptions.value = AKKREDITIERUNG_DEAKTIVIEREN_MODAL_OPTIONS;
  };

  const changeVersion = ({ model }) => {
    emit("changeVersion", model);
  };
  const createVersion = () => {
    emit("createVersion", akkreditierungDisplayVersion.value);
  };
  const auflagenerfuellungBearbeiten = () => {
    emit("auflagenerfuellungBearbeiten", akkreditierungDisplayVersion.value);
  };
  const gutachtenAustauschenDialogoeffnen = () => {
    emit("gutachtenAustauschenDialogoeffnen", akkreditierungDisplayVersion.value);
  };
  const activateAkkr = () => {
    emit("activateAkkr", akkreditierungDisplayVersion.value);
  };
  const commentUngueltig = async() => {
    try {
      closeModal();
      loading.value = true;
      const response = await putHttp({
        url: programmakkreditierungAkkrUngueltigSetzenUrl.value,
        data: akkreditierungDisplayVersion.value,
      });
      loading.value = false;
      emit("commentUngueltig", response);
    } catch (e) {
      console.error(e);
      addNotification({
        text: "Fehler beim Senden des Kommentar",
        type: "error",
      });
    }
  };

  const addPositionToSecondaryHeader = field => {
    if (field === "pro_entscheidungsdatum" || field === "aks_entscheidungsdatum") {
      return akkreditierungDisplayVersion.value.status === KAkkreditierungsstatusUUIDs.NICHT_AKKREDITIERT && akkreditierungDisplayVersion.value[field];
    }

    return akkreditierungDisplayVersion.value.status !== KAkkreditierungsstatusUUIDs.NICHT_AKKREDITIERT && akkreditierungDisplayVersion.value[field];
  };
  const secondaryHeader = computed(() => {
    const label = [];
    const fieldsForHeader = isProgrammakkreditierung ?
    {
      von: "pro_von",
      bis: "pro_bis",
      entscheidungsdatum: "pro_entscheidungsdatum",
    } :
    {
      von: "aks_von",
      bis: "aks_bis",
      entscheidungsdatum: "aks_entscheidungsdatum",
    };
    if (addPositionToSecondaryHeader(fieldsForHeader.von)) {
      label.push(`von ${ filterDate(akkreditierungDisplayVersion.value[fieldsForHeader.von], "DD.MM.YYYY") }`);
    }
    if (addPositionToSecondaryHeader(fieldsForHeader.bis)) {
      label.push(`bis ${ filterDate(akkreditierungDisplayVersion.value[fieldsForHeader.bis], "DD.MM.YYYY") }`);
    }
    if (addPositionToSecondaryHeader(fieldsForHeader.entscheidungsdatum)) {
      label.push(`bis ${ filterDate(akkreditierungDisplayVersion.value[fieldsForHeader.entscheidungsdatum], "DD.MM.YYYY") }`);
    }
    label.push(`(${ akkreditierungDisplayVersion.value.versionstatustext })`);

    return label.join(" ");
  });

  return {
    activateAkkr,
    akkreditierungAktivierenId,
    akkreditierungÄnderungenVeröffentlichenId,
    akkreditierungBearbeitenId,
    akkreditierungBearbeitungAbbrechenId,
    akkreditierungDeaktivierenId,
    akkreditierungDeaktivierenModalOptions,
    akkreditierungGutachtenAustauschenId,
    auflagenerfuellungBearbeiten,
    bearbeitungsVersion,
    canActivate,
    canChangeGutachten,
    canCreateVersion,
    canDeactivate,
    canEnterAufgabenerfuellung,
    canUpdateVersion,
    changeVersion,
    closeModal,
    commentUngueltig,
    confirmOptions,
    createVersion,
    getItemStyle,
    gutachtenAustauschenDialogoeffnen,
    hasPermissionAuflagenerfuellungEintragen,
    hasPermissionGutachtenAustauschen,
    hasPermissionProgramakkreditierungUpdate,
    hasPermissionUpdate,
    loading,
    modalSaveCommentUngueltigId,
    openCommentUngueltigModal,
    openModalAnderungenVeroffentlichen,
    openModalBearbeitungAbbrechen,
    secondaryHeader,
    selectorClose,
    versionSelectOptions,
  };
}
