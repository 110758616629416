import PuxAlert from "../../../../../libs/vue/client/vue/global/components/PuxAlert/PuxAlert.vue";
import PuxCloak from "../../../../../libs/vue/client/vue/global/components/PuxCloak/PuxCloak.vue";
import PuxGet from "../../../../../libs/vue/client/vue/global/components/PuxGet/PuxGet.vue";
import PuxIcon from "../../../../../libs/vue/client/vue/global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../libs/vue/client/vue/global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import SyConfigMixin from "../../../../../libs/vue/client/vue/global/mixins/SyConfigMixin";
import {
  FilterCurrencyMixin,
} from "../../../../../libs/vue/client/vue/global/mixins/FiltersMixin";

import {
  forEach,
  get,
  keyBy,
  sortBy,
} from "lodash-es";

// @vue/component
export default {
  name: "KofiUebersicht",
  components: {
    PuxAlert,
    PuxCloak,
    PuxGet,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    FilterCurrencyMixin,
    HttpMixin,
    SyConfigMixin,
  ],
  props: {
    antrag: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      kategorieMap: {},
      kosten: [],
      kostenResults: {},
      kostenartdetailMap: {},
      finanzierung: [],
      finanzierungResults: {},
      laender: [],
      landMap: {},
      loadingKosten: true,
      loadingFinanzierung: true,
      zuschuss: undefined,
    };
  },
  computed: {
    antragUrl() {
      return `foerderantraege/${ this.antrag.pk }/`;
    },

    kostenUrl() {
      return `${ this.antragUrl }kostenarten/uebersicht/`;
    },

    finanzierungUrl() {
      return `${ this.antragUrl }finanzierungsarten/uebersicht/`;
    },

    regelnKostenvalidierungSyConfig() {
      return this.getSyConfigsValue("regeln_kostenvalidierung", {});
    },
  },
  created() {
    this.setZuschuss();
    this.loadData();
  },
  methods: {
    setZuschuss() {
      if (this.antrag.a_zuschussbewilligt) {
        this.zuschuss = Number(this.antrag.a_zuschussbewilligt);
      } else if (this.antrag.a_zuschussbeantragt) {
        this.zuschuss = Number(this.antrag.a_zuschussbeantragt);
      } else if (this.antrag.a_zuschusseingereicht) {
        this.zuschuss = Number(this.antrag.a_zuschusseingereicht);
      }
    },

    loadData() {
      this.loadKosten();
      Promise.all([
        this.loadLaender(),
        this.loadFinanzierungsartdetail(),
      ]).then(
        () => {
          this.loadFinanzierung();
        }
      );
    },

    loadKosten() {
      this.getHttp({
        url: this.kostenUrl,
      }).then(
        response => {
          this.prepareKosten(response);
          this.loadingKosten = false;
        }
      );
    },

    loadFinanzierung() {
      this.getHttp({
        url: this.finanzierungUrl,
      }).then(
        response => {
          this.prepareFinanzierung(response);
          this.loadingFinanzierung = false;
        }
      );
    },

    loadLaender() {
      return this.getHttp({
        url: "katalog/",
        urlParams: {
          key: "klaenderkuerzel",
        },
        apiSaveId: "klaenderkuerzel",
      }).then(
        response => {
          this.landMap = keyBy(response, "pk");
          this.laender = response;
        }
      );
    },

    loadFinanzierungsartdetail() {
      return this.getHttp({
        url: "katalog/",
        urlParams: {
          key: "finanzierungsartdetail",
        },
        apiSaveId: "finanzierungsartdetail",
      }).then(
        response => {
          this.kategorieMap = keyBy(response, "pk");
        }
      );
    },

    prepareKosten(response) {
      const KOSTEN_SORTED = sortBy(response, ["antragregel_pos"]);
      const KOSTENART_DETAIL_MAP = {};
      const KOSTEN_RESULTS = {
        gesamt: 0,
        part_de: 0,
        part_de_mtl: 0,
        part_de_sn: 0,
        part_de_st: 0,
        part_de_th: 0,
      };

      forEach(KOSTEN_SORTED, kostenItem => {
        KOSTEN_RESULTS[kostenItem.pk] = {
          gesamt: 0,
          part_de: 0,
          part_de_mtl: 0,
          part_de_sn: 0,
          part_de_st: 0,
          part_de_th: 0,
        };

        kostenItem.ohne_positionen = false;
        if (kostenItem.antragregel_argument && kostenItem.antragregel_argument.ohne_positionen) {
          kostenItem.ohne_positionen = kostenItem.antragregel_argument.ohne_positionen.wert;
        }
        kostenItem.pos.forEach(position => {
          KOSTEN_RESULTS[kostenItem.pk].gesamt += Number(position.akp_wert) || 0;
          KOSTEN_RESULTS[kostenItem.pk].part_de += Number(position.akp_argument.part_de) || 0;
          KOSTEN_RESULTS[kostenItem.pk].part_de_mtl += Number(position.akp_argument.part_de_mtl) || 0;
          KOSTEN_RESULTS[kostenItem.pk].part_de_sn += Number(position.akp_argument.part_de_sn) || 0;
          KOSTEN_RESULTS[kostenItem.pk].part_de_st += Number(position.akp_argument.part_de_st) || 0;
          KOSTEN_RESULTS[kostenItem.pk].part_de_th += Number(position.akp_argument.part_de_th) || 0;

          KOSTENART_DETAIL_MAP[position.kostenartdetail] = position;
        });

        KOSTEN_RESULTS[kostenItem.pk].percent_part_de_mtl = this.getPercent(KOSTEN_RESULTS[kostenItem.pk].part_de_mtl);
        KOSTEN_RESULTS[kostenItem.pk].percent_part_de_sn = this.getPercent(KOSTEN_RESULTS[kostenItem.pk].part_de_sn);
        KOSTEN_RESULTS[kostenItem.pk].percent_part_de_st = this.getPercent(KOSTEN_RESULTS[kostenItem.pk].part_de_st);
        KOSTEN_RESULTS[kostenItem.pk].percent_part_de_th = this.getPercent(KOSTEN_RESULTS[kostenItem.pk].part_de_th);

        KOSTEN_RESULTS.gesamt += KOSTEN_RESULTS[kostenItem.pk].gesamt;
        KOSTEN_RESULTS.part_de += KOSTEN_RESULTS[kostenItem.pk].part_de;
        KOSTEN_RESULTS.part_de_mtl += KOSTEN_RESULTS[kostenItem.pk].part_de_mtl;
        KOSTEN_RESULTS.part_de_sn += KOSTEN_RESULTS[kostenItem.pk].part_de_sn;
        KOSTEN_RESULTS.part_de_st += KOSTEN_RESULTS[kostenItem.pk].part_de_st;
        KOSTEN_RESULTS.part_de_th += KOSTEN_RESULTS[kostenItem.pk].part_de_th;
      });

      KOSTEN_RESULTS.percent_part_de_mtl = this.getPercent(KOSTEN_RESULTS.part_de_mtl);
      KOSTEN_RESULTS.percent_part_de_sn = this.getPercent(KOSTEN_RESULTS.part_de_sn);
      KOSTEN_RESULTS.percent_part_de_st = this.getPercent(KOSTEN_RESULTS.part_de_st);
      KOSTEN_RESULTS.percent_part_de_th = this.getPercent(KOSTEN_RESULTS.part_de_th);

      this.kosten = KOSTEN_SORTED;
      this.kostenResults = KOSTEN_RESULTS;
      this.kostenartdetailMap = KOSTENART_DETAIL_MAP;
    },

    prepareFinanzierung(response) {
      const DE_FINANZIERUNG = this.regelnKostenvalidierungSyConfig.deutsche_finanzierungen || [];
      const INTERNATIONALE_FINANZIERUNG = this.regelnKostenvalidierungSyConfig.internationale_finanzierungen || [];
      const FINANZIERUNG_SORTED = sortBy(response, ["antragregel_pos"]);
      const FINANZIERUNG_RESULTS = {
        gesamt: 0,
        prozent: 0,
        ohneland: {
          gesamt: 0,
          finanzierungsarten: [],
        },
        mitland: {},
        de: {
          gesamt: 0,
          prozent: 0,
          land: {},
        },
        int: {
          gesamt: 0,
          prozent: 0,
          land: {},
        },
      };

      forEach(FINANZIERUNG_SORTED, fi => {
        FINANZIERUNG_RESULTS.gesamt += Number(fi.afa_summe) || 0;
        FINANZIERUNG_RESULTS.prozent += Number(fi.afa_prozent) || 0;
        const rechner = this.getRechner(fi);
        const relevante_rechner = [
          "finanzierung_detailliert_betragsrechner",
          "finanzierung_detailliert_national_betragsrechner",
        ];
        const IS_DE = DE_FINANZIERUNG.indexOf(fi.regel_id) !== -1;
        const IS_INTERNATIONAL = INTERNATIONALE_FINANZIERUNG.indexOf(fi.regel_id) !== -1;

        let prefix = "";
        if (IS_DE) {
          FINANZIERUNG_RESULTS.de.gesamt += Number(fi.afa_summe) || 0;
          FINANZIERUNG_RESULTS.de.prozent += Number(fi.afa_prozent) || 0;
          prefix = "de";
        }
        if (IS_INTERNATIONAL) {
          FINANZIERUNG_RESULTS.int.gesamt += Number(fi.afa_summe) || 0;
          FINANZIERUNG_RESULTS.int.prozent += Number(fi.afa_prozent) || 0;
          prefix = "int";
        }
        if (relevante_rechner.indexOf(rechner) > -1) {
          fi.pos.forEach(pos => {
            const land = get(pos, "argument.land");
            const kategorie = get(pos, "argument.kategorie");

            FINANZIERUNG_RESULTS[prefix].land[land] = FINANZIERUNG_RESULTS[prefix].land[land] ||
              {
                gesamt: 0,
                finanzierungsarten: {},
                landlabel: this.landMap[land] && this.landMap[land].lkz_bez
              };
            FINANZIERUNG_RESULTS[prefix].land[land].finanzierungsarten[fi.finanzierungsart.fin_id] =
              FINANZIERUNG_RESULTS[prefix].land[land].finanzierungsarten[fi.finanzierungsart.fin_id] ||
              {
                pos: [],
                finlabel: fi.finanzierungsart.fin_bez,
                prozent: 0,
                gesamt: 0
              };
            pos.kategorielabel = this.kategorieMap[kategorie] && this.kategorieMap[kategorie].fid_kbez;
            pos.restbezeichnung = this.getBezeichnungOhneKategorie(pos.afp_bez, pos.kategorielabel);
            FINANZIERUNG_RESULTS[prefix].land[land].finanzierungsarten[fi.finanzierungsart.fin_id].pos.push(pos);
            FINANZIERUNG_RESULTS[prefix].land[land].gesamt += Number(pos.afp_wert) || 0;
            FINANZIERUNG_RESULTS[prefix].land[land].finanzierungsarten[fi.finanzierungsart.fin_id].gesamt += Number(pos.afp_wert) || 0;
          });
        } else {
          FINANZIERUNG_RESULTS.ohneland.finanzierungsarten.push(fi);
          FINANZIERUNG_RESULTS.ohneland.gesamt += Number(fi.afa_summe) || 0;
        }
      });

      this.finanzierung = FINANZIERUNG_SORTED;
      this.finanzierungResults = FINANZIERUNG_RESULTS;
    },

    getRechner(afa) {
      return get(afa, "antragregel_argument.betragsrechner.wert");
    },

    getBezeichnungOhneKategorie(bez, kategorielabel) {
      return bez.replace(kategorielabel + ": ", "");
    },

    getPercent(value) {
      if (!this.zuschuss) {
        return "-";
      }
      return this.filterCurrency(value / this.zuschuss * 100, "%");
    },

    getPercentFinanzierung(value) {
      if (!this.finanzierungResults.gesamt) {
        return "-";
      }
      return this.filterCurrency(value / this.finanzierungResults.gesamt * 100, "%");
    },

    getPercentKosten(value) {
      if (!this.kostenResults.gesamt) {
        return "-";
      }
      return this.filterCurrency(value / this.kostenResults.gesamt * 100, "%");
    },
  },
};
