"use strict";

import template from "./list.jade";
import angular from "angular";
import on429 from "./documentErrors";
import {
  EventBus,
} from "../../../vue/client/vue/global/functions/event-bus";
import {
  findIndex,
} from "lodash-es";

const mesageDownload = "Dokument wird heruntergeladen...";
const mesageDelete = "Dokument wird gelöscht...";
const dokumentPruefStatusNeu = "30d847af-74b6-1dcc-e050-007f01012f99";
const dokumentKlasse = "2ece5b25-e866-33c8-e050-007f01013bb8";
const ERROR_DEFAULT_TEXT = "Bei der Verarbeitung ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunk erneut!";

class DokumenteListController {
  /*@ngInject*/
  constructor(
    Dokumentarten,
    Dokumentpruefstatus,
    Upload,
    $scope,
    $window,
    diasConfirmDialog,
    AuthService,
    $filter,
    DiasNotification,
    $timeout,
    ListenConstantDokumente,
    $interval,
    SyConfigs,
    $rootScope,
    DokumentEvents
  ) {
    this.allowedMimeType = SyConfigs.wert("allowed_mime_type").join(",");
    // Notizen
    this.notizenStatus = {
      load: false,
    };
    this.notizenWatchStatus = false;
    this.notizenStatus.load = false;
    $scope.$watch("vm.dokumentnotizen", () => {
      if (this.notizenStatus.load) {
        this.filterNotizen();
      }
      this.notizenStatus.load = true;
    }, true);
    this.$rootScope = $rootScope;
    this.DokumentEvents = DokumentEvents;
    this.$timeout = $timeout;
    this.$interval = $interval;
    this.DiasNotification = DiasNotification;
    this.ListenConstantDokumente = ListenConstantDokumente;
    this.window = $window;
    this.Dokumentarten = Dokumentarten;
    this.scope = $scope;
    this.diasConfirmDialog = diasConfirmDialog;
    this.upload = Upload;
    this.showUpload = false;
    this.showUploadOpen = false;
    this.filter = $filter;
    this.AuthService = AuthService;
    this.Dokumentpruefstatus = Dokumentpruefstatus;
    this.loading = [];
    this.loadingSaveChanges = [];
    this.loadingMesage = [];
    this.createPreviewLoading = {};
    this.docAnzeige = $scope.vm.docAnzeige;
    this.moduleObj = $scope.vm.docpk;
    this.uploadDokart = [];

    this.moduleNameObj = {};
    this.downloadFiles = [];
    this.anzahlFile = 0;
    this.statusButtonFertig = false;
    this.statusEditButton = {};
    this.statusFile = false;
    this.showTrDetail = {};
    this.detailLoaded = {};
    this.editStatus = {};
    this.enterTr = {};
    this.enterBut = {};
    this.errFiles = [];
    this.errorServer = [];
    this.errorEdit = {};
    this.urlUpload = {};
    this.model = {};
    this.notizen = {};

    this.allDocuments = {};
    this.dokumenteByPK = {};
    this.dokumente = [];
    this.dokumenteIsSinc = {};
    this.AuthService = AuthService;
    this.statusSynchronization = true;

    $scope.$on("$destroy", () => {
      this.$interval.cancel(this.timer);
    });

    // Dokumenten-Struktur aus dem Snapshot und Snapshot-Diff für das Template nachbauen:
    this.snapshotDokumente = {};
    $scope.$watch("vm.snapshot", snapshot => {
      this.snapshotDokumente = this._initSnapshotDokumente(snapshot);
    });

    this.snapshotDiffModifiedDokumente = {};
    this.snapshotDiffRemovedDokumenteByDokart = {};
    this.snapshotDiffChangesIcons = {};
    $scope.$watch("vm.snapshotdiff", snapshotdiff => {
      this.snapshotDiffModifiedDokumente = this._getDokumenteFromSnapshotDiff(snapshotdiff, "modified");
      this.snapshotDiffRemovedDokumente = this._getDokumenteFromSnapshotDiff(snapshotdiff, "removed");
      this.snapshotDiffChangesIcons = this._initSnapshotDiffChangesIcons(snapshotdiff);
    });
    $scope.$watchGroup(["vm.docPermissions", "vm.docUpload"], () => this.updatePermissions());

    $scope.$on(this.DokumentEvents.createdDok, () => {
      this.updateObject();
    });
    $scope.$on(this.DokumentEvents.deletedDok, (event, dokPk) => {
      this.updateObject();
      this._updateSnapshotDiff();
      this.deleteNotizen(dokPk);
    });

    this.changeDocumentFromEventBus = this.changeDocumentFromEventBus.bind(this);
    this.initEventBusses();
    $scope.$on("$destroy", this.destroyEventBusses);
  }

  updatePermissions() {
    if (angular.isUndefined(this.docUpload)) {
      this.showUpload = this.AuthService.syncHasPerm(this.dokumentePermissions.create, this.docPermissions);
    } else {
      this.showUpload = this.docUpload;
    }
    this.dokumentUpdateState = this.AuthService.syncHasPerm("dokument.update_state", this.docPermissions);
    this.dokumentViewState = this.AuthService.hasPerm("dokument.view_state", this.docPermissions);
    const viewPerm = this.AuthService.syncHasPerm(this.dokumentePermissions.view);
    if (viewPerm && !this.anzeigeobj) {
      this.initialPage();
      this.Dokumentpruefstatus.getList().then(
        result => {
          this.dokumentPruefStatus = result;
        }
      );
    } else if (!viewPerm && this.anzeigeobj) {
      this.anzeigeobj = undefined;
    }
  }

  reloadPage() {
    if (this.anzeigeobj && this.anzeigeobj.length) {
      this.loadDokuments(this.anzeigeobj);
    }
  }

  initialPage() {
    if (!this.AuthService.syncHasPerm(this.dokumentePermissions.view, this.docPermissions)) {
      return;
    }
    this.loadDokuments();

    this.Dokumentarten.getList({ klasse: dokumentKlasse }).then(
      result => {
        this.dokartChoices = [];
        angular.forEach(
          result,
          dokart => this.dokartChoices.push({ value: dokart.pk,
                                              label: dokart.name })
        );
      }
    );
  }

  loadDokuments() {
    if (!this.AuthService.syncHasPerm(this.dokumentePermissions.view, this.docPermissions)) {
      return;
    }

    this.moduleObj.dokumente.getList().then(
      result => {
        this.dokumente = result;
        if (this.dokumente.length > 0) {
          this.checkSynchronization();
        }
        this.dokumenteByPK = {};
        angular.forEach(
          this.dokumente,
          doc => this.dokumenteByPK[doc.pk] = doc
        );
        this.loadNotizen(this.dokumente);
      }
    );
  }

  loadNotizen(documents) {
    // Lädt die Notizen der übergebenen Dokumente in den Notiz-Container:
    for (let k = 0; k < documents.length; k++) {
      this.notizen[documents[k].dokument.pk] = [];
      angular.extend(this.notizen[documents[k].dokument.pk], documents[k].dokument.notizen);
      if (documents[k].is_sync) {
        this.dokumenteIsSinc[documents[k].pk] = documents[k];
      }
    }
  }

  newObjectFiles(filesIn, filesOut) {
    const anzahlFiles = filesOut.length;
    if (anzahlFiles) {
      for (let i = 0; i < filesIn.length; i++) {
        const anzahlFilesIndex = anzahlFiles + i;
        filesOut[anzahlFilesIndex] = filesIn[i];
      }
    } else {
      for (let j = 0; j < filesIn.length; j++) {
        filesOut[j] = filesIn[j];
      }
    }
    return filesOut;
  }

  uploadDoc(files, errFiles) {
    if (this.statusButtonFertig) {
      this.statusButtonFertig = false;
    }
    this.newObjectFiles(files, this.downloadFiles);
    this.newObjectFiles(errFiles, this.errFiles);
    if (this.errFiles && this.errFiles.length) {
      this.statusFile = true;
    }
    if (!this.downloadFiles.length) {
      this.statusButtonFertig = true;
    }
    if (this.anzahlFile === this.downloadFiles.length) {
      this.statusButtonFertig = true;
    }
    if (files && files.length) {
      angular.forEach(files, function(file) {
        file.upload = this.upload.upload({
          url: this.moduleObj.dokumente.getUploadUrl(this.uploadDokart[0].value),
          data: { file: file },
          this: this
        });
        file.upload.then(
          response => {
            // file is uploaded successfully
            if (response.status === 200) {
              file.progress = 100;
            }
            this.statusFile = false;
            this.addDokument(response.data);
            this.changeStatusButtonFertig();
            this._updateSnapshotDiff();
            this.$rootScope.$broadcast(this.DokumentEvents.createdDok, response.data);
            EventBus.$emit("changeDocument", {
              dokartPk: response.data.dokument && response.data.dokument.dokart_id,
              componentId: this.htmlId,
            });
          },
          response => {
            // handle error
            if (on429({ response, DiasNotification: this.DiasNotification })) {
              this.workServerErrors(response);
            }
            this.changeStatusButtonFertig();
          },
          evt => {
            // progress notify
            file.progress = Math.min(50, parseInt(100.0 * evt.loaded / evt.total));
          }
        );
      }, this);
    }
  }


  workServerErrors(errorMsg) {
    this.statusFile = true;
    const ZahlErrServer = this.errorServer.length;
    if (ZahlErrServer) {
      this.errorServer[ZahlErrServer] = errorMsg;
    } else {
      this.errorServer[0] = errorMsg;
    }
  }

  changeStatusButtonFertig() {
    this.anzahlFile++;
    if (this.downloadFiles.length) {
      const anzahlFiles = this.downloadFiles.length;
      if (this.anzahlFile === anzahlFiles) {
        this.statusButtonFertig = true;
      }
    }
  }

  toggleButton(status) {
    if (status) {
      const anzahlFiles = this.downloadFiles.length;
      if (anzahlFiles) {
        for (let i = 0; i < anzahlFiles; i++) {
          delete this.downloadFiles[i];
        }
      }
      const anzahlError = this.errFiles.length;
      if (anzahlError) {
        for (let j = 0; j < anzahlError; j++) {
          delete this.errFiles[j];
        }
      }
      const anzahlErrorServer = this.errorServer.length;
      if (anzahlErrorServer) {
        for (let k = 0; k < anzahlErrorServer; k++) {
          delete this.errorServer[k];
        }
      }
      this.anzahlFile = 0;
      this.statusFile = false;
      this.uploadDokart = [];
    }

    this.showUploadOpen = !this.showUploadOpen;
  }

  toggleTrDetails(index) {
    this.showTrDetail[index] = !this.showTrDetail[index];
    if (!this.detailLoaded[index] && this.showTrDetail[index]) {
      const dok = this.dokumente[index];
      this.moduleObj.dokumente.one(dok.pk).loadDetails().then(
        details => {
          dok.dokument = angular.merge(dok.dokument, details.plain());
          this.loadNotizen([dok]);
        },
        err => this.DiasNotification.page.error("Fehler beim Laden der Details des Dokuments", err)
      ).finally(() => this.detailLoaded[index] = true);
    }
  }

  enterRow(index) {
    this.enterTr[index] = true;
  }

  leaveRow(index) {
    this.enterTr[index] = false;
  }

  enterButton(index) {
    this.enterBut[index] = true;
  }

  leaveButton(index) {
    this.enterBut[index] = false;
  }

  setEditMode(index, filePk) {
    if (this.editStatus[index]) {
      this.editStatus[index] = false;
      if (this.errorEdit[index]) {
        delete this.errorEdit[index];
      }
    } else {
      if (filePk) {
        this.model[filePk] = {};
        this.model[filePk].dok_titel = this.dokumenteByPK[filePk].dokument.dok_titel || null;
        this.model[filePk].dokart = this.dokumenteByPK[filePk].dokument.dokart_id || null;
        this.model[filePk].pruefstatus = this.dokumenteByPK[filePk] || dokumentPruefStatusNeu;
        this.model[filePk].gueltig_ab = this.dokumenteByPK[filePk].dokument.gueltig_ab || null;
        this.model[filePk].gueltig_bis = this.dokumenteByPK[filePk].dokument.gueltig_bis || null;
        this.editStatus[index] = true;
      }
    }
  }

  saveChange(index, file) {
    this.loadingSaveChanges[index] = true;
    file.customPUT(this.model[file.pk]).then(
      response => {
        const DOKART_PK_ALT = file.dokument && file.dokument.dokart_id;
        this.changeStatusAfterDel(index);
        this.updateObject(index);
        this.loadingSaveChanges[index] = false;
        this._updateSnapshotDiff();
        EventBus.$emit("changeDocument", {
          dokartPk: response.dokument && response.dokument.dokart_id,
          dokartPkAlt: DOKART_PK_ALT,
          componentId: this.htmlId,
        });
      },
      error => {
        if (error.status === 400) {
          if (error.data) {
            if (_.isString(error.data)) {
              this.DiasNotification.page.error(error.data);
              if (this.errorEdit[index]) {
                delete this.errorEdit[index];
              }
            } else {
              this.errorEdit[index] = error.data;
            }
          } else {
            this.DiasNotification.page.error(ERROR_DEFAULT_TEXT);
          }
        }
        this.loadingSaveChanges[index] = false;
      }
    );
  }

  confirmDeleteDoc(dokument, index) {
    return this.diasConfirmDialog({
      titleTemplate: "Dokument löschen",
      contentTemplate: `<p>Sind Sie sicher, dass Sie dieses Dokument löschen wollen?</p>`,
      okLabel: "Löschen",
      okCallback: () => this.deleteDoc(dokument, index)
    });
  }

  deleteDoc(dokument, index) {
    const FILE_PK = dokument.pk;
    this.loadingMesage[index] = mesageDelete;
    this.loading[index] = true;

    this.moduleObj.dokumente.one(FILE_PK).remove().then(() => {
      if (this.allDocuments[FILE_PK]) {
        delete this.allDocuments[FILE_PK];
      }
      this.updateObject(index, true);
      this._updateSnapshotDiff();
      this.deleteNotizen(FILE_PK);
      this.$rootScope.$broadcast(this.DokumentEvents.deletedDok, FILE_PK);
      EventBus.$emit("changeDocument", {
        dokartPk: dokument.dokument && dokument.dokument.dokart_id,
        componentId: this.htmlId,
      });
    }, error => {
      if (error.status === 400) {
        if (error.data) {
          this.DiasNotification.page.error(error.data);
        } else {
          this.DiasNotification.page.error(ERROR_DEFAULT_TEXT);
        }
      }
      this.loading[index] = false;
    });
  }

  deleteNotizen(dokumentPk) {
    if (this.notizen[dokumentPk]) {
      delete this.notizen[dokumentPk];
    }
  }

  updateObject(index, loading) {
    this.loadDokuments();
    if (loading) {
      this.loading[index] = false;
      this.changeStatusAfterDel(index);
    }
  }

  changeStatusAfterDel(index) {
    if (this.showTrDetail[index]) {
      this.showTrDetail[index] = false;
    }
    if (this.editStatus[index]) {
      this.editStatus[index] = false;
    }
    this.statusEditButton[index] = false;

    if (this.errorEdit[index]) {
      delete this.errorEdit[index];
    }
  }

  savePdf(fileName, filePk, index) {
    this.loadingMesage[index] = mesageDownload;
    this.loading[index] = true;

    // this.moduleObj.dokument.one(filePk).withHttpConfig({responseType: "blob"}).get().then(
    this.moduleObj.dokumente.getSaveBlob(filePk).then(response => {
      if (this.window.navigator.msSaveBlob) {
        this.window.navigator.msSaveBlob(response, fileName);
      } else {
        const fileURL = (this.window.URL || this.window.webkitURL).createObjectURL(response);
        const aLink = this.window.document.createElement("a");
        aLink.download = fileName;
        aLink.href = fileURL;
        aLink.target = "_self";

        const eventClick = this.window.document.createEvent("MouseEvents");
        eventClick.initEvent(
          "click",
          true,
          true,
          this.window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        aLink.dispatchEvent(eventClick);
      }

      this.loading[index] = false;
    }, () => {
      this.loading[index] = false;
    });
  }

  hasSnapshotChanges() {
    return (this.snapshot && this.snapshotdiff &&
            (this.snapshotdiff.diff_result.added.dokumente ||
             this.snapshotdiff.diff_result.removed.dokumente ||
             this.snapshotdiff.diff_result.modified.dokumente));
  }

  _getSnapshotData(snapshot) {
    let snapshotData;
    angular.forEach(["asn_snapshot", "ats_snapshot"], field => {
      if (snapshot[field] !== undefined) {
        snapshotData = snapshot[field];
        return;
      }
    });
    return snapshotData;
  }

  _initSnapshotDokumente(snapshot) {
    if (snapshot) {
      const docsByIds = {};
      const snapshotData = this._getSnapshotData(snapshot);
      if (snapshotData.dokumente) {
        for (let i = 0; i < snapshotData.dokumente.length; i++) {
          const d = snapshotData.dokumente[i];
          if (d.dokument && d.dokument.pk) {
            docsByIds[d.dokument.pk] = d;
          }
        }
      }
      return docsByIds;
    }
  }

  _hasSnapshotDiffDocOperation(snapshotdiff, diffField) {
    return (snapshotdiff && snapshotdiff.diff_result[diffField] &&
            snapshotdiff.diff_result[diffField].dokumente);
  }

  _getDokumenteFromSnapshotDiff(snapshotdiff, diffField) {
    const docsByIds = {};
    if (this._hasSnapshotDiffDocOperation(snapshotdiff, diffField)) {
      const docs = snapshotdiff.diff_result[diffField].dokumente;
      for (let i = 0; i < docs.length; i++) {
        const d = docs[i];
        if (d.dokument && d.dokument.pk) {
          docsByIds[d.dokument.pk] = d;
        }
      }
    }
    return docsByIds;
  }

  _initSnapshotDiffChangesIcons(snapshotdiff) {
    const iconsByDocIds = {};
    // modified icons:
    if (snapshotdiff && snapshotdiff.diff_result.modified.dokumente) {
      const modifiedDoks = snapshotdiff.diff_result.modified.dokumente;
      for (let i = 0; i < modifiedDoks.length; i++) {
        const d = modifiedDoks[i];
        if (d.dokument && d.dokument.pk) {
          iconsByDocIds[d.dokument.pk] = "glyphicon-changes";
        }
      }
    }
    // added icons:
    if (snapshotdiff && snapshotdiff.diff_result.added.dokumente) {
      const addedDoks = snapshotdiff.diff_result.added.dokumente;
      for (let i = 0; i < addedDoks.length; i++) {
        const d = addedDoks[i];
        if (d.dokument && d.dokument.pk) {
          iconsByDocIds[d.dokument.pk] = "glyphicon-changes-add";
        }
      }
    }
    // removed icons:
    if (snapshotdiff && snapshotdiff.diff_result.removed.dokumente) {
      const removedDoks = snapshotdiff.diff_result.removed.dokumente;
      for (let i = 0; i < removedDoks.length; i++) {
        const d = removedDoks[i];
        if (d.dokument && d.dokument.pk) {
          iconsByDocIds[d.dokument.pk] = "glyphicon-changes-delete";
        }
      }
    }
    return iconsByDocIds;
  }

  _updateSnapshotDiff() {
    if (this.snapshot) {
      this.moduleObj.snapshots.one(this.snapshot.pk).one("diff").get().then(response => {
        this.snapshotdiff = {
          diff_result: {
            modified: response.diff_result.modified.anlagen || {},
            added: response.diff_result.added.anlagen || {},
            removed: response.diff_result.removed.anlagen || {}
          }
        };
      }, errors => {
        console.error(errors);
      });
    }
  }

  showErrorLabel(field) {
    if (field === "dok_titel") {
      return "Titel: ";
    } else if (field === "dokart") {
      return "Dokumenttyp: ";
    } else if (field === "gueltig_ab") {
      return "Dokument gültig ab: ";
    } else if (field === "gueltig_bis") {
      return "Dokument gültig bis: ";
    } else if (field === "pruefstatus") {
      return "Status: ";
    }
  }

  antragDokInSitzung(doc, index) {
    this.loading[index] = true;
    const data = {
      src_dokid: doc.dokument.pk
    };
    this.moduleObj.customPOST(data, "dokument_in_sitzung_uebernehmen").then(
      () => {
        this.DiasNotification.page.success("Dokument " + doc.dokument.dok_titel + " wird für der Sitzung vorgesehen!");
        this.loading[index] = false;
        EventBus.$emit("changeDocument", {
          dokartPk: doc.dokument && doc.dokument.dokart_id,
          componentId: this.htmlId,
        });
      },
      error => {
        if (error.status === 400) {
          if (error.data) {
            this.DiasNotification.page.error(error.data);
          } else {
            this.DiasNotification.page.error(ERROR_DEFAULT_TEXT);
          }
        }
        this.loading[index] = false;
      }
    );
  }

  filterNotizen() {
    this.reloadingNotizen = true;
    angular.forEach(this.moduleNameObj, dokumenteArr => {
      angular.forEach(dokumenteArr, dokument => {
        this.notizen[dokument.pk] = [];
        angular.forEach(this.dokumentnotizen, notiz => {
          if (notiz.no_key.indexOf(dokument.pk) !== -1) {
            this.notizen[dokument.pk].push(notiz);
          }
        });
      });
    });
    this.$timeout(() => {
      this.reloadingNotizen = false;
    }, 1);
  }

  filterNotizenBack() {
    this.notizenStatus.load = false;
    let tempArr = [];
    tempArr = angular.copy(this.dokumentnotizen);
    angular.forEach(this.moduleNameObj, dokumenteArr => {
      angular.forEach(dokumenteArr, dokument => {
        for (let i = 0; i < tempArr.length; i++) {
          if (tempArr[i].no_key.indexOf(dokument.pk) !== -1) {
            tempArr.splice(i, 1);
            i--;
          }
        }
      });
    });
    angular.forEach(this.notizen, notizArr => {
      angular.forEach(notizArr, notiz => {
        tempArr.push(notiz);
      });
    });
    this.dokumentnotizen = angular.copy(tempArr);

    this.notizenStatus.load = true;
  }

  checkSynchronization() {
    if (this.statusSynchronization) {
      this.statusSynchronization = false;
      this.timer = this.$interval(() => {
        this.moduleObj.dokumente.one("syncing").get().then(
          response => {
            this.sincDokObj = response;
            this.filterSincDokumente();
            if (!response || !response.data) {
              this.$interval.cancel(this.timer);
              this.statusSynchronization = true;
              this.checkDokumenteSynch();
            }
          }
        );
      }, 3000);
    }
  }

  checkDokumenteSynch() {
    angular.forEach(this.moduleNameObj, kontext => {
      angular.forEach(kontext, dok => {
        if (dok.is_sync) {
          this.dokumenteIsSinc[dok.pk] = dok;
        }
      });
    });
  }

  filterSincDokumente() {
    angular.forEach(this.dokumenteIsSinc, (dok, key) => {
      if (!(this.sincDokObj && this.sincDokObj.data && this.sincDokObj.data[key])) {
        this.reloadDokument(dok);
        delete this.dokumenteIsSinc[key];
      }
    });
  }

  reloadDokument(dok) {
    if (!this.allDocuments[dok.pk]) {
      return;
    }
    this.moduleObj.dokumente.one(dok.pk).customGET("meta_info").then(
      response => {
        this.replaceDokList(response);
      }
    );
  }

  addDokument(dok) {
    this.moduleNameObj[dok.dokument.dokart_id] = this.moduleNameObj[dok.dokument.dokart_id] || [];
    this.moduleNameObj[dok.dokument.dokart_id].push(dok);
    if (dok.is_sync) {
      this.dokumenteIsSinc[dok.pk] = dok;
      this.checkSynchronization();
    }
  }

  createPreview(dok) {
    this.createPreviewLoading[dok.pk] = true;
    this.moduleObj.dokumente.one(dok.pk).one("create_preview").post().then(
      response => {
        this.replaceDokList(response);
        this.createPreviewLoading[dok.pk] = false;
      }
    );
  }

  replaceDokList(dok) {
    if (this.moduleNameObj[dok.dokument.dokart_id] && this.moduleNameObj[dok.dokument.dokart_id].length) {
      for (let i = 0; i < this.moduleNameObj[dok.dokument.dokart_id].length; i++) {
        if (this.moduleNameObj[dok.dokument.dokart_id][i].pk === dok.pk) {
          this.moduleNameObj[dok.dokument.dokart_id][i] = angular.extend(this.moduleNameObj[dok.dokument.dokart_id][i], dok);
          this.notizen[dok.pk] = [];
          break;
        }
      }
    }
  }

  changeDocumentFromEventBus({ dokartPk, dokartPkAlt, componentId }) {
    // this.updateStatus
    if (componentId === this.htmlId) { // Event gehört zu dieser Komponente
      return;
    }
    if (findIndex(this.dokartChoices, ["value", dokartPk]) === -1 &&
      findIndex(this.dokartChoices, ["value", dokartPkAlt]) === -1) { // Dokart vom Dokument gehört nicht zu dieser Komponente
      return;
    }
    this.loadDokuments();
  }

  initEventBusses() {
    EventBus.$on("changeDocument", this.changeDocumentFromEventBus);
  }

  destroyEventBusses() {
    if (this && this.changeDocumentFromEventBus) {
      EventBus.$off("changeDocument", this.changeDocumentFromEventBus);
    } else {
      EventBus.$off("changeDocument");
    }
  }
}

export default {
  template: template(),
  controller: DokumenteListController,
  controllerAs: "vm",
  bindings: {
    docpk: "<",
    docAnzeige: "<",
    docUpload: "<",
    docPermissions: "<?",
    dokumentePermissions: "<?",
    snapshot: "=",
    snapshotdiff: "=",
    updateStatus: "=?",
    obj: "=?",
    dokumentnotizen: "=?",
    notizOptions: "<?",
    notizkey: "<?",
    pflichtdokumenteInfoText: "<?"
  }
};
