// Modul zur Erfassung eines Durchführungszeitraums
"use strict";

import richtext from "dias/core/richtext";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import parameter from "./parameter";


const ampel = angular.module("dias.standard_foerdermodule.ampel", [
  richtext.name,
  foerdermoduleRegistry.name,
  parameter.name,
]);

export default ampel;
