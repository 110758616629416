"use strict";

import BaseModel from "dias/core/models/baseModel";

class Belegdokumente extends BaseModel {
  constructor() {
    super("belegdokumente");
  }

  extendModel(model) {
    model = super.extendModel(model);

    model.fetchBelege = () => this.Vertragsbeleg.getList({ pk: model.belege });

    return model;
  }

  onServiceBuild() {
    this.Vertragsbeleg = this.$injector.get("Vertragsbeleg");
  }
}

export default Belegdokumente;
