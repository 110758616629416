// @vue/component
export default {
  props: {
    statusShowMessageObergrenze: {
      type: Boolean,
      required: true,
      info: "Obergrenze-Kappung-Meldung anzeigen",
    },
  },
};
