"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasModal from "dias/core/modal";

import models from "../models";

const foerderprogrammeModule = angular.module("root.foerderprogramme", [
  uiRouter,
  models.name,
  diasModal.name,
  diasTable.name,
  diasNotification.name,
]);

foerderprogrammeModule.config($stateProvider => {
  $stateProvider

    // Förderprogramm-Liste
    .state("root.foerderprogramme", {
      url: `/foerderprogramme/?
            user_cookie
      `,
      views: {
        "main@root": {
          template: "<div vueinit><vue-programme-list></vue-programme-list></div>"
        }
      },
      data: {
        permissions: "foerderprogramm.update"
      }
    })
    // Detail-Seite eines Förderprogramms
    .state("root.foerderprogramme.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><programme-details></programme-details></div>"
        }
      },
    })
  ;
})
;

export default foerderprogrammeModule;
