import {
  isPlainObject,
  isUndefined,
} from "lodash-es";

const KVertragskostenbelegStatusUUIDs = {
  NEU: "d1aff898-8bf6-46b0-9864-08eec1ee5573",
  EINGEREICHT: "95c6acaf-fffa-4389-a960-1fb80f14501a",
  GEPRUEFT: "09e98cbc-92ed-424a-9a9f-0c8d25428382",
  UNVOLLSTAENDIG: "8f57b60c-a286-4f6d-9f78-2b4b0a8c3d7f",
};

export default KVertragskostenbelegStatusUUIDs;

const VOLLSTAENDIG_ANERKANNT = 4;
const TEILWEISE_ANERKANNT = 3;
const ABGELEHENT = 2;
const EINGEREICHT = 1;
const NEU = 0;

export function getIconBetragAnerkannt({ beleg }) {
  switch (_getBelegStatus({ beleg: beleg })) {
  case NEU:
  case EINGEREICHT:
    return "";
  case ABGELEHENT:
    return "glyphicon-remove mr-1";
  case TEILWEISE_ANERKANNT:
  case VOLLSTAENDIG_ANERKANNT:
    return "glyphicon-success mr-1";
  }
}

export function getClassBetragAnerkannt({ beleg }) {
  switch (_getBelegStatus({ beleg: beleg })) {
  case NEU:
  case EINGEREICHT:
    return "";
  case ABGELEHENT:
    return "text-danger";
  case TEILWEISE_ANERKANNT:
  case VOLLSTAENDIG_ANERKANNT:
    return "text-success";
  }
}

export function getClassBetragAnerkanntInPersonalbaum({ beleg, light }) {
  const CLASS_SUFFIX = light ? "_light" : "";
  switch (_getBelegStatus({ beleg: beleg })) {
  case NEU:
  case EINGEREICHT:
    return "";
  case ABGELEHENT:
    return `beleg_abgelehnt${ CLASS_SUFFIX }`;
  case TEILWEISE_ANERKANNT:
  case VOLLSTAENDIG_ANERKANNT:
    return `beleg_annerkant${ CLASS_SUFFIX }`;
  }
}

export function getTitleBetragAnerkannt({ beleg }) {
  switch (_getBelegStatus({ beleg: beleg })) {
  case NEU:
    return "_BELEG_STATUS_NEU_";
  case EINGEREICHT:
    return "_BELEG_STATUS_EINGEREICHT_";
  case ABGELEHENT:
    return "_BELEG_STATUS_ABGELEHNT_";
  case TEILWEISE_ANERKANNT:
    return "_BELEG_STATUS_TEILWEISE_ANERKANNT_";
  case VOLLSTAENDIG_ANERKANNT:
    return "_BELEG_STATUS_VOLLSTAENDIG_ANERKANNT_";
  }
}

export function showTeilsAnerkannt({ beleg }) {
  return _getBelegStatus({ beleg: beleg }) === TEILWEISE_ANERKANNT;
}

function _getBelegStatus({ beleg }) {
  const BELEG_STATUS = isPlainObject(beleg.status) ? beleg.status.pk : beleg.status;
  if (BELEG_STATUS === KVertragskostenbelegStatusUUIDs.NEU) {
    return NEU;
  } else if (BELEG_STATUS === KVertragskostenbelegStatusUUIDs.EINGEREICHT) {
    return EINGEREICHT;
  } else if (BELEG_STATUS === KVertragskostenbelegStatusUUIDs.GEPRUEFT) {
    const ANERKANNT = isUndefined(beleg.vpb_betrag_anerkannt) ? +beleg.betrag_anerkannt : +beleg.vpb_betrag_anerkannt;
    const ERFASST = isUndefined(beleg.vpb_betrag_erfasst) ? +beleg.betrag_erfasst : +beleg.vpb_betrag_erfasst;
    if (ANERKANNT >= ERFASST) {
      return VOLLSTAENDIG_ANERKANNT;
    } else if (ANERKANNT > 0 && ANERKANNT < ERFASST) {
      return TEILWEISE_ANERKANNT;
    } else if (ANERKANNT === 0) {
      return ABGELEHENT;
    }
  }
}
