"use strict";

import angular from "angular";

import Registry from "./registry.service";

const modelsModule = angular.module("sitzungen.sitzungsmodule.services", [
])

  .provider("SitzungsmodulRegistry", Registry)
;

export default modelsModule;
