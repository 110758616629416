"use strict";

import template from "./config.jade";

class ZielgruppenAuswahlController {
  /*@ngInject*/
  constructor(KZielgruppe) {
    this.loading = true;
    this.zielgruppenChoices = [];
    KZielgruppe.getList().then(
      result => this.zielgruppenChoices = result
    ).finally(() => this.loading = false);

    this.zielgruppenSelection = {};
    angular.forEach(
      this.parameter[this.key].wert,
      pk => this.zielgruppenSelection[pk] = true
    );

    this.updateSelection = (gruppe, master) => {
      // also select master when a subgroup is selected
      if (this.zielgruppenSelection[gruppe.pk] === true && master !== undefined) {
        this.zielgruppenSelection[master.pk] = true;
      }

      // keep argument.wert in synch
      this.parameter[this.key].wert = [];
      angular.forEach(this.zielgruppenSelection, (selected, pk) => {
        if (selected === true) {
          this.parameter[this.key].wert.push(pk);
        }
      });
    };
  }
}

export default {
  bindings: {
    parameter: "=",
    key: "@"
  },
  template: template(),
  controller: ZielgruppenAuswahlController,
  controllerAs: "vm"
};
