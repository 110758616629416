"use strict";

import parameterServices from "../services";


const parametermoduleMathExpression = angular.module("dias.foerderregeln.parameter.math_expression", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("math_expression", "parametermodul-math_expression");
  })
  .component("parametermodulMathExpression", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
      change: "&?",
    },
    template: "{{ vm.parameter[vm.key].wert }}",
    controllerAs: "vm",
  })

  .component("parametermodulMathExpressionRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: "{{ vm.parameter[vm.key].wert }}",
    controllerAs: "vm",
  });
export default parametermoduleMathExpression;
