import HttpMixin from "../../../mixins/HttpMixin";
import translate from "../../../directives/translate";
import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "UiRatingMixin",
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
  ],
  data() {
    return {
      hoverIndex: -1,
      loadedKatalog: undefined,
      statusListLoading: false,
    };
  },
  computed: {
    displayModeRadio() {
      return this.options.displaymode === "radio";
    },

    displayModeRating() {
      return this.options.displaymode === "rating";
    },

    dataList() {
      return this.loadedKatalog || this.options.data || [];
    },

    dataObj() {
      const DATA_OBJ = {};
      forEach(this.dataList, (item, index) => {
        DATA_OBJ[index + 1] = item;
      });

      return DATA_OBJ;
    },

    indexMapping() {
      const INDEX_MAPPING = {};
      forEach(this.dataList, (item, index) => {
        INDEX_MAPPING[index + 1] = item.id;
      });
      return INDEX_MAPPING;
    },

    iconsClasses() {
      const ICONS_CLASSES = {};
      forEach(this.dataList, (item, index) => {
        const HOVER_INDEX_LOCAL = this.hoverIndex - 1;
        const MODEL_LOCAL = this.modelLocal - 1;
        if (index <= HOVER_INDEX_LOCAL && HOVER_INDEX_LOCAL !== MODEL_LOCAL) {
          ICONS_CLASSES[index] = "glyphicon-star text-orange-light";
        } else if (index > MODEL_LOCAL ||
          (HOVER_INDEX_LOCAL >= 0 && HOVER_INDEX_LOCAL !== MODEL_LOCAL)) {
          ICONS_CLASSES[index] = "glyphicon-star-empty text-dark";
        } else if (index <= MODEL_LOCAL) {
          ICONS_CLASSES[index] = "glyphicon-star text-orange";
        } else {
          ICONS_CLASSES[index] = "";
        }
      });
      return ICONS_CLASSES;
    },

    modelLocal() {
      return this.indexMapping[this.model] || -1;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.options.url) {
        this.statusListLoading = true;
        this.getListHttp({ url: this.options.url, apiSaveId: this.options.apiSaveId, urlParams: this.options.urlParams }).then(
          response => {
            this.loadedKatalog = response;
          }
        ).then(
          () => {
            this.statusListLoading = false;
          }
        );
      }
    },
  },
};
