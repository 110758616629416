import FormstepDetail from "../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";
import HochschuleDetailsStudienOrgTeileinheit from "./HochschuleDetailsStudienOrgTeileinheit/HochschuleDetailsStudienOrgTeileinheit.vue";
import HochschuleDetailsStudienOrgTeileinheitenModalCreate from "./HochschuleDetailsStudienOrgTeileinheitenModalCreate/HochschuleDetailsStudienOrgTeileinheitenModalCreate.vue";
import PuxButton from "../../../../../../../libs/vue/client/vue/global/components/PuxButton/PuxButton.vue";

import HttpMixin from "../../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import PermissionMixin from "../../../../../../../libs/vue/client/vue/global/mixins/PermissionMixin";

// @vue/component
export default {
  name: "HochschuleDetailsStudienOrgTeileinheiten",
  components: {
    FormstepDetail,
    HochschuleDetailsStudienOrgTeileinheit,
    HochschuleDetailsStudienOrgTeileinheitenModalCreate,
    PuxButton,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    hochschule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      teileinheiten: [],
      isModalCreateVisible: undefined,
    };
  },
  computed: {
    formstepDetailOptions() {
      return {
        hideHeader: true,
      };
    },

    teileinheitenUrl() {
      return `hochschulen/${ this.hochschule.pk }/teileinheiten/`;
    },

    hasPermissionTeileinheitenCreate() {
      return this.checkPermissionsSync({
        perm: "teileinheiten.create",
        permArray: this.hochschule.user_permissions,
      });
    },
  },
  created() {
    this.loadTeileinheiten();
  },
  methods: {
    loadTeileinheiten() {
      this.loading = true;
      this.getListHttp({
        url: this.teileinheitenUrl,
      }).then(
        response => {
          this.teileinheiten = response;
          this.loading = false;
        }
      );
    },

    openModalCreate() {
      this.isModalCreateVisible = true;
    },

    closeModalCreate() {
      this.isModalCreateVisible = false;
    },
  },
};
