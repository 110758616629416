import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import TaskLog from "../../../../../global/components/TaskLog/TaskLog.vue";
import Spaced from "../../../../../global/components/Spaced/Spaced.vue";

import NotificationMixin from "../../../../../global/mixins/NotificationMixin";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

import { copyToClipboard } from "../../../../../global/functions/utils";
import {
  find,
  forEach,
  isArray,
  isString,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "ImportauftragAntragWizardStepAusfuehren",
  components: {
    FormElement,
    PuxButton,
    PuxIcon,
    PuxTranslate,
    TaskLog,
    Spaced,
  },
  mixins: [
    NotificationMixin,
  ],
  props: {
    auftrag: {
      type: Object,
      default: undefined,
    },
    regelsatz: {
      type: Object,
      default: undefined,
    },
    antragsteller: {
      type: Object,
      default: undefined,
    },
    antragsdatum: {
      type: String,
      default: undefined,
    },
    antragsnummer: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    task: {
      type: String,
      default: undefined,
    },
    taskStart: {
      type: Function,
      default: undefined,
    },
    onTaskProgress: {
      type: Function,
      default: undefined,
    },
    onTaskError: {
      type: Function,
      default: undefined,
    },
    onTaskFailure: {
      type: Function,
      default: undefined,
    },
    onTaskSuccess: {
      type: Function,
      default: undefined,
    },
    onTaskFinish: {
      type: Function,
      default: undefined,
    },
    taskError: {
      type: String,
      default: undefined,
    },
    reset: {
      type: Function,
      default: undefined,
    },
  },
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    return {
      filterDate,
    };
  },
  data() {
    return {
      groupOpen: {
        success: false,
        info: false,
        warning: true,
        danger: true,
      }
    };
  },
  computed: {
    protokollGroups() {
      const ORDER = [
        "danger",
        "warning",
        "info",
        "success",
      ];
      const ICONS = {
        danger: "glyphicon-warning",
        warning: "glyphicon-warning",
        info: "glyphicon-info-sign",
        success: "glyphicon-ok",
      };
      const GRPS = [];
      if (!this.auftrag.iea_protokoll || !this.auftrag.iea_protokoll.msgs) {
        return GRPS;
      }
      const _GRPS = {};
      forEach(this.auftrag.iea_protokoll.msgs, msg => {
        if (msg.type === "error") {
          msg.type = "danger";
        }
        _GRPS[msg.type] = _GRPS[msg.type] || {
          icon: ICONS[msg.type],
          btnCls: `btn-outline-${ msg.type } no-border p-1`,
          alertCls: `alert-${ msg.type }`,
          msgs: [],
          type: msg.type
        };
        _GRPS[msg.type].msgs.push(msg);
      });
      forEach(ORDER, type => {
        if (_GRPS[type]) {
          GRPS.push(_GRPS[type]);
        }
      });
      return GRPS;
    },

    translationMsgCopyAllSuccess() {
      return { text: "_MSG_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_AUSFUEHREN_AUFTRAG_MSG_KOPIEREN_ALL_DONE_" };
    },

    translationMsgCopySuccess() {
      return { text: "_MSG_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_AUSFUEHREN_AUFTRAG_MSG_KOPIEREN_DONE_" };
    },

    translationMsgHeaderInfo() {
      return "_TXT_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_AUSFUEHREN_AUFTRAG_MSG_HEADER_INFO_{{anz}}_";
    },

    translationMsgHeaderSuccess() {
      return "_TXT_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_AUSFUEHREN_AUFTRAG_MSG_HEADER_SUCCESS_{{anz}}_";
    },

    translationMsgHeaderWarn() {
      return "_TXT_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_AUSFUEHREN_AUFTRAG_MSG_HEADER_WARNING_{{anz}}_";
    },

    translationMsgHeaderDanger() {
      return "_TXT_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_AUSFUEHREN_AUFTRAG_MSG_HEADER_DANGER_{{anz}}_";
    },

    translationMsgHeadersExtra(type) {
      const EL = find(this.protokollGroups, el => el.type === `${ type }`);
      return { anz: EL ? size(EL.msgs) : 0 };
    },

    translationMsgHeadersText() {
      return {
        danger: this.translationMsgHeaderDanger,
        warning: this.translationMsgHeaderWarn,
        info: this.translationMsgHeaderInfo,
        success: this.translationMsgHeaderSuccess,
      };
    },
  },
  methods: {
    isNotEmpty(obj) {
      return size(obj) > 0;
    },

    isString(obj) {
      return isString(obj);
    },

    getAuftragMsgClass(msg) {
      let type = msg.type;
      if (msg.type === "error") {
        type = "danger";
      }
      return `alert-${ type }`;
    },

    copyAuftragMsg(msgs) {
      const STRS = [];
      let notificationMsg = this.translationMsgCopyAllSuccess;
      if (!isArray(msgs)) {
        notificationMsg = this.translationMsgCopySuccess;
        msgs = [msgs];
      }
      forEach(msgs, msg => {
        if (msg.text) {
          STRS.push(`<p>${ msg.text }</p>`);
        } else if (msg.html) {
          STRS.push(msg.html);
        }
      });
      const STR = STRS.join("");
      copyToClipboard({ text: STR });
      this.addNotification({
        text: notificationMsg.text,
        type: "info",
        translate: true
      });
    },

    showCopyAll() {
      return this.auftrag.iea_protokoll && this.auftrag.iea_protokoll.msgs && this.auftrag.iea_protokoll.msgs.length > 1;
    },

    isGroupOpen(type) {
      return this.groupOpen[type];
    },

    toggleGroupOpen(type) {
      this.groupOpen[type] = !this.groupOpen[type];
    },
  },
};
