import BelegModalMixin from "../BelegModalMixin";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import {
  EventBus,
} from "../../../../../global/functions/event-bus";
import {
  forEach,
  sortBy,
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "BelegModalDelete",
  components: {
    PuxButton,
  },
  mixins: [
    BelegModalMixin,
  ],
  created() {
    this.setBelegeList();
    this.setBelegeForOptionsList();
  },

  methods: {
    setBelegeList() {
      const BELEGE = [];
      forEach(cloneDeep(this.belege), belegPeriod => {
        forEach(belegPeriod, beleg => {
          if (beleg.user_permissions.indexOf("vertragskostenpositionsbelege.delete") !== -1) {
            BELEGE.push(beleg);
          }
        });
      });
      this.belegeList = sortBy(BELEGE, [o => o.argument_erfasst.von]);
    },

    deleteBeleg() {
      this.status.loading = true;
      this.deleteHttp({
        url: `vertraege/${ this.vertragPk }/kosten/${ this.vertragkosten }/positionen/${ this.position.position }/belege/${ this.model.beleg }/`,
      }).then(
        () => {
          this.addNotification({ text: "_MSG_VERTRAG_DETAILS_BELEG_DELETE_MODAL_SUCCESS_" });
          EventBus.$emit(`updateTable${ "BelegeListInVertragTable" }`);
          this.close({ beleg: this.currentBeleg, status: true });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
