"use strict";

import "angular-animate/angular-animate";
import "angular-strap";
import "angular-strap/dist/angular-strap.tpl";
import "angular-motion/dist/angular-motion.css";

import ConfirmDirective from "./confirm.directive";
import Services from "./services";

const diasConfirm = angular.module("dias.confirm", [
  "mgcrea.ngStrap.modal",
  Services.name,
])
  .directive("diasConfirm", ConfirmDirective);

export default diasConfirm;
