import AngularLink from "../../../../../../../libs/vue/client/vue/global/components/AngularLink/AngularLink.vue";
import FormstepDetail from "../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";

// @vue/component
export default {
  name: "AkkreditierteStudiengangTeilstudiengaenge",
  components: {
    AngularLink,
    FormstepDetail,
  },
  props: {
    teilstudiengaenge: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      optionsForFormstepDetail: {
        hideHeader: true,
      },
    };
  },
};
