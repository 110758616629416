import GeschaeftsregelModul from "../../../Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";

import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";
import {
  CheckPermissionsSyncMixin,
} from "../../../../global/mixins/PermissionMixin";

import { createNamespacedHelpers } from "vuex";
import {
  forEach,
  get, isEmpty,
} from "lodash-es";

const {
  mapMutations,
  mapState,
} = createNamespacedHelpers("snapshot");

// @vue/component
export default {
  name: "SachPruefBericht",
  components: {
    GeschaeftsregelModul,
  },
  mixins: [
    CheckPermissionsSyncMixin,
    SyConfigMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    auszahlung: {
      type: Object,
      required: true,
    },
    modules: {
      type: Array,
      required: false,
      default: undefined,
    },
    permissionForEdit: {
      type: String,
      required: true,
    },
    updateModule: {
      type: Function,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    notizenKey: {
      type: String,
      required: true,
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
    snapshotPrefix: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      isNummerierung: false,
    };
  },
  computed: {
    isEditable() {
      return this.checkPermissionsSync({
        perm: this.permissionForEdit,
        permArray: this.userPermissions,
      });
    },

    extraStatic() {
      return {
        snapshotAll: this.snapshot,
      };
    },

    userPermissions() {
      return this.auszahlung.user_permissions;
    },

    documentsBaseUrl() {
      return `auszahlungen/${ this.auszahlung.pk }/`;
    },

    indexesMapping() {
      const INDEXES = {};
      if (this.isNummerierung) {
        forEach(this.modules, (item, index) => {
          INDEXES[index] = `${ index + 1 }.`;
        });
      }
      return INDEXES;
    },

    isSnapshot() {
      return !!this.snapshot;
    },

    modulesLocal() {
      return this.modules || [];
    },

    snapshotObject() {
      return get(this.snapshot, `osn_snapshot.${ this.snapshotPrefix }.regeln`) || {};
    },

    snapshotDate() {
      return get(this.snapshot, "osn_snapshot_datum");
    },

    snapshotLocal() {
      const SNAPSHOT = {};
      if (this.isSnapshot) {
        forEach(this.modulesLocal, modul => {
          const MODULNAME = modul.modulname;
          const REGEL_PK = modul.regel.pk;
          if (this.snapshotObject[MODULNAME]) {
            SNAPSHOT[REGEL_PK] = {
              osn_snapshot_datum: this.snapshotDate,
              data: {
                [this.snapshotPrefix]: {
                  [MODULNAME]: {
                    [REGEL_PK]: this.snapshotObject[MODULNAME][REGEL_PK],
                  },
                },
              },
            };
          }
        });
      }
      return SNAPSHOT;
    },

    isDiff() {
      let diff = false;
      if (this.isSnapshot && !isEmpty(this.STATUS_DIFF_FOR_REGELS)) {
        forEach(this.modulesLocal, modul => {
          if (this.STATUS_DIFF_FOR_REGELS[modul.regel.regel]) {
            diff = true;
          }
        });
      }
      return diff;
    },

    ...mapState([
      "STATUS_DIFF_FOR_REGELS",
    ]),
  },
  watch: {
    isDiff: {
      immediate: true,
      handler(newVal) {
        this.MUT_SET_STATUS_DIFF_FOR_REGEL({
          regelId: this.id,
          statusDiff: newVal,
        });
      },
    },
  },
  created() {
    this.checkSyConfig();
  },
  beforeUnmount() {
    this.MUT_DELETE_STATUS_DIFF_FOR_REGEL({ regelId: this.id });
    this.MUT_RESET_STATUS_DIFF();
  },
  methods: {
    checkSyConfig() {
      this.isNummerierung = this.getSyConfigsValue("sachbericht_enum", false);
    },

    ...mapMutations([
      "MUT_SET_STATUS_DIFF_FOR_REGEL",
      "MUT_DELETE_STATUS_DIFF_FOR_REGEL",
      "MUT_RESET_STATUS_DIFF",
    ]),
  }
};
