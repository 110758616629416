"use strict";


class SitzungsantragProtokollViewController {
  /*@ngInject*/
  constructor($scope) {
    this.$scope = $scope;
    this.sla = $scope.extras.sla;

    this.loading = !this.sla.sla_protokoll;
    this.sla.loadProtokoll().then(
      response => this.sla.sla_protokoll = response.sla_protokoll
    ).finally(() => this.loading = false);
  }
}

export default SitzungsantragProtokollViewController;
