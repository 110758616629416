// Modul zur Erfassung von Kosten mit Pauschale
"use strict";

import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";
import VertragModels from "dias/vertrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const finanz_periode = angular.module("dias.kosten_finanzierung.modul.perioden_signal_finanzierung", [
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
  VertragModels.name,
]);

finanz_periode.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("perioden_signal_finanzierung", "modul-perioden-signal-finanzierung");
})

  .directive("modulPeriodenSignalFinanzierung", builder.directive(template, controller));

export default finanz_periode;
