import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "AntragstellerWizardAccess",
  components: {
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
};
