"use strict";

import BaseModel from "dias/core/models/baseModel";

class Foerderorgrolle extends BaseModel {
  constructor() {
    super("foerderorgrollen");
  }

  configRestangular(RestangularConfigurer) {
    RestangularConfigurer.setDefaultHttpFields({ cache: true });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.getChoices = params => {
      const deferred = this.$q.defer();
      const data = [];
      params = params || {};
      params.fields = ["fro_id", "fro_kbez", "fro_bez"];
      collection.getList(params).then(result => {
        angular.forEach(result, item => {
          data.push({ value: item.fro_id, label: `${ item.fro_kbez } - ${ item.fro_bez }` });
        });
        deferred.resolve(data);
      });
      return deferred.promise;
    };

    return collection;
  }
}

export default Foerderorgrolle;
