import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import ZuschussStandard from "../../KFDetail/ZuschussStandard/ZuschussStandard.vue";

import FinanzierugMixin from "../Mixins/FinanzierugMixin";

import {
  cloneDeep,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "RollstuhlplatzPauschale",
  components: {
    KFStandard,
    ZuschussStandard,
  },
  mixins: [
    FinanzierugMixin,
  ],
  data() {
    return {
      allowsInput: true,
    };
  },
  computed: {
    statusNotLoad() {
      return this.kontext !== "zuschuss" && this.kontext !== "zuschuss_vertrag";
    },

    betragsrechner() {
      return this.modelParameter.betragsrechner || "rolliplaetzerechner";
    },

    sumLocal() {
      return this.antragBetragCurrency;
    },

    meldungen() {
      return get(this.infoprovider, `details.${ this.regel.afr_id }.meldungen`, []);
    },

    statusHideButtonAdd() {
      return this.positionen.length > 0;
    },

    labelsPosition() {
      return {
        buttonAddText: "_BTN_ROLLSTUHLPLATZ_PAUSCHALE_ADD_POSITION_",
        labelAdd: "_TXT_ROLLSTUHLPLATZ_PAUSCHALE_POSITION_ADD_HEADER_{{kofiart}}_",
        labelEdit: "_TXT_ROLLSTUHLPLATZ_PAUSCHALE_POSITION_EDIT_HEADER_",
        confirmDeleteHeader: "_TXT_ROLLSTUHLPLATZ_PAUSCHALE_KF_DELETE_CONFIRM_HEADER_{{position}}_{{kofiart}}_",
      };
    },

    labelsPositionZuschussVertrag() {
      const LABELS = cloneDeep(this.labelsPosition);
      LABELS.confirmDeleteHeader = "_TXT_ROLLSTUHLPLATZ_PAUSCHALE_KF_DELETE_CONFIRM_HEADER_";
      return LABELS;
    },
  },
};
