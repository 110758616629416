"use strict";

import BetragsrechnerRegistry from "../services";

import BetragsrechnerHonorar from "./betragsrechner.js";
import BetragsrechnerHonorarController from "./controller";

const BetragsrechnerHonorarModul = angular.module("dias.betragsrechner.BetragsrechnerHonorarModul", [
  BetragsrechnerRegistry.name,
]);

BetragsrechnerHonorarModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("honorarkostenrechner", BetragsrechnerHonorar);
})
  .component("honorarkosten", BetragsrechnerHonorarController);

export default BetragsrechnerHonorarModul;
