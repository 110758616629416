import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  FilterSpacedMixin,
} from "../../../../global/mixins/FiltersMixin";

import {
  gettext,
} from "../../../../global/functions/utils";

// @vue/component
export default {
  name: "AntragEinreichenMessage",
  components: {
    PuxAlert,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    FilterSpacedMixin,
  ],
  props: {
    antrag: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: false,
    },
  },
  emits: [
    "closeEinreichenMessage",
  ],
  computed: {
    extraForTranslate() {
      return {
        antragsnummer: this.filterSpaced(this.antrag.a_nr, true),
        antragsid: this.antrag.pk,
      };
    },

    statusShowEinreichenMessage() {
      return !!gettext("_HTML_ANTRAG_DETAILS_EINREICHEN_MSG_{{antragsnummer}}_{{antragsid}}_");
    },

    isVisibleLocal() {
      return this.statusShowEinreichenMessage && this.isVisible;
    },
  },
  methods: {
    closeEinreichenMessage() {
      this.$emit("closeEinreichenMessage");
    },
  },
};
