"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";


class AstorgEditDurchfuehrendeOrgController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, Katalog, KBundesland, i18n) {
    super($q, $scope, DiasNotification);
    this.lkzChoices = [];
    this.gettext = i18n.gettext;
    this.errorsLabel = {
      do_orgname: this.gettext("_DUFO_MODAL_CREATE_ERROR_LABEL_ORGNAME_"),
      do_strasse: "Straße: ",
      do_hsnr: "Hausnummer: ",
      do_plz: "PLZ: ",
      do_ort: "Ort: ",
      lkz: "Land: ",
      bundesland: "Bundesland: ",
      do_beschreibung: "Beschreibung: "
    };
    Katalog.getKatalog("klaenderkuerzel").then(
      response => this.lkzChoices = response
    );
    this.remove = this.remove.bind(this);

    KBundesland.getList().then(
      result => {
        this.bundeslandChoices = result;
        this.bundeslandChoicesObj = _.keyBy(result, "bun_id");
      }
    ); 
  }

  remove() {
    return this.model.remove().then(
      () => {
        this.DiasNotification.page.success(this.gettext("_LBL_DUFO_EDIT_DELETE_SUCCESS_") + this.model.do_orgname + " erfolgreich gelöscht.");
        const collection = this.$scope.model.getParentList();
        collection.splice(collection.indexOf(this.$scope.model), 1);
      },
      this.onError
    );
  }

  onChangeBundesland() {
    this.model.bundesland = this.bundeslandChoicesObj[this.model.bundesland_id] || null;
    if (this.model.bundesland_id === "") {
      this.model.bundesland_id = null;
    }
  }
}

export default AstorgEditDurchfuehrendeOrgController;
