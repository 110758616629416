import { isArray, } from "lodash-es";

// @vue/component
export default {
  name: "ObjektanmerkungenAnzeigenTreeList",
  props: {
    element: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    isElementArray() {
      return isArray(this.element);
    },
  },
};
