"use strict";

import BaseModel from "dias/core/models/baseModel";

class Auszahlungslisten extends BaseModel {
  constructor() {
    super("auszahlungslisten");
  }
}

export default Auszahlungslisten;
