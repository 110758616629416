import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  assign,
} from "lodash-es";

// @vue/component
export default {
  name: "AnonymisierungModalAbbrechen",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    auftrag: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      status: {
        loading: undefined,
      },
    };
  },
  computed: {
    extraTranslate() {
      return {
        objektname: this.auftrag.ano_objektname,
        termin: this.auftrag.ano_termin,
      };
    },
  },
  methods: {
    abbrechen() {
      this.status.loading = true;
      this.postHttp({ url: `anonymisierungsauftraege/${ this.auftrag.pk }/abbrechen/` }).then(
        response => {
          this.addNotification({ text: "_MSG_ANONYMISIERUNG_MODAL_ABBRECHEN_SUCCESS_" });
          const newRow = assign({}, this.auftrag, response);
          this.close({ newRow });
        },
        () => this.addNotification({ text: "_MSG_ANONYMISIERUNG_MODAL_ABBRECHEN_ERROR_", type: "error" })
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
