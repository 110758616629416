import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import BerichteVerwaltungModalCreate from "../BerichteVerwaltungModalCreate/BerichteVerwaltungModalCreate.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "berichte";

// @vue/component
export default {
  name: "BerichteVerwaltungList",
  components: {
    AngularLink,
    BerichteVerwaltungModalCreate,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [],
        rowActions: [
          {
            label: "_TXT_BERICHTE_VERWALTUNG_TABLE_ROW_ACTIONS_DETAILS_",
            route: "root.tableau.admin.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [
          {
            permission: "berichte.create",
            label: "_TXT_BERICHTE_VERWALTUNG_TABLE_ACTIONS_CREATE_",
            callback: () => this.modal.create = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "berichte_create",
          },
        ],
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
        },
      },
      filters: [],
      modal: {
        create: undefined,
      },
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },

    getTranslateSichtbarkeit() {
      return row => row.tab_sichtbar === false ? "_LBL_NO_" : "_LBL_YES_";
    },
  },
  methods: {
    closeModalCreate() {
      this.modal.create = false;
    },
  },
};
