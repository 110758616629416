import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import getTableApi from "../../../const/TableApis";
import {
  forEach,
} from "lodash-es";

const TABLE_ID = "FoerderorganisationTable";

// @vue/component
export default {
  name: "FoerderorganisationList",
  components: {
    AngularLink,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [
          {
            label: "_LBL_FOERDERORGANISATION_LIST_NAME_",
            id: "o_name",
            sortable: true,
            sortId: "o_name",
            slot: "o_name",
          },
          {
            label: "_LBL_FOERDERORGANISATION_LIST_FOERDERER_INITIATIVE_",
            id: "typ_name",
            sortable: true,
            sortId: "typ__ot_kbez",
          },
          {
            label: "_LBL_FOERDERORGANISATION_LIST_KONZEPTS_TITEL_",
            id: "o_konzept_titel",
            sortable: true,
            sortId: "o_konzept_titel",
          },
        ],
        rowActions: [
          {
            label: "_LBL_FOERDERORGANISATION_TABLE_ROW_ACTIONS_DETAILS_",
            route: "root.foerderorganisationen_kennenlernen.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [],
        label: "_LBL_FOERDERORGANISATION_LIST_",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      kategorien: undefined,
      statusLoading: true,
    };
  },
  created() {
    this.loadFoerderfinderkategorien();
  },
  methods: {
    loadFoerderfinderkategorien() {
      this.getListHttp({ url: "foerderfinderkategorien/" }).then(
        response => {
          this.kategorien = response;
          this.extendTable();
          this.statusLoading = false;
        }
      );
    },

    extendTable() {
      forEach(this.kategorien, kat => {
        this.options.cols.push({
          id: kat.ffk_id,
          label: kat.ffk_kbez,
          templateCallback: ({ row }) => this.renderKategoriewerte({ row, kat }),
        });
      });
    },

    renderKategoriewerte({ row, kat }) {
      const werte = row.kategoriewerte;
      const typ = kat.ffk_id;
      const werteTypLength = kat.werte.length;

      let result = "";
      let arr = [];
      angular.forEach(werte, item => {
        if (item.kategorie === typ) {
          arr.push(item);
        }
      });

      if (arr.length === werteTypLength) {
        arr = [];
        arr.push({
          fkw_kbez: "alle"
        });
      }
      angular.forEach(arr, (item, key) => {
        result += `<div>${ item.fkw_kbez }${ key !== arr.length - 1 ? "" : "" }</div>`;
      });
      return result;
    },
  },
};
