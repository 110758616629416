var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-element"] = jade_interp = function(label, fieldName, errorsMap, prefix){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-class", "{'has-error': " + (errorsMap) + "['" + (fieldName) + "']}", true, true)) + (jade.cls(['form-group','row',attributes.class], [null,null,false])) + "><label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"col-form-label col-sm-3 text-right font-weight-bold required\"><span" + (jade.attr("ng-class", "{'required':vm.isRequired('" + (fieldName) + "')}", true, true)) + " class=\"titel\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span></label><div class=\"col-sm-9 col-xl-6\">");
block && block();
buf.push("<label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"help-block\"><ul" + (jade.attr("ng-if", "" + (errorsMap) + "['" + (fieldName) + "']", true, true)) + " class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errorsMap) + "['" + (fieldName) + "']", true, true)) + ">{{ err }}</li></ul></label></div></div>");
};


























jade_mixins["vorschau-daten"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-repeat", "(key, val) in " + (vorschau) + "", true, true)) + "><span ng-if=\"val\">{{key}}: {{ val }}</span></div>");
};






buf.push("<div ng-if=\"ctx.kontext !== 'beleg'\"><div><div ng-class=\"{'has-error': ctx.errors['wert']}\" class=\"form-group row\"><label ng-attr-for=\"{{ctx.prefix}}_wert\" class=\"col-form-label col-sm-3 text-right font-weight-bold required\"><span ng-class=\"{'required':vm.isRequired('wert')}\" class=\"titel\">Betrag in EUR<span class=\"alert-danger\"></span></span></label><div class=\"col-sm-9 col-xl-6\"><div class=\"input-group\"><input type=\"text\" maxlength=\"14\" ng-model=\"ctx.posmodel.argument.wert\" name=\"wert\" dias-decimal-field aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_wert\" ng-attr-aria-invalid=\"{{ctx.errors['wert'] ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">&euro;</span></div></div><label ng-attr-for=\"{{ctx.prefix}}_wert\" class=\"help-block\"><div>{{ ctx.posmodel.argument.bezeichnung }}</div><ul ng-if=\"ctx.errors['wert']\" class=\"errorlist\"><li ng-repeat=\"err in ctx.errors['wert']\">{{ err }}</li></ul></label></div></div></div></div><div ng-if=\"ctx.kontext === 'beleg'\">");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" maxlength=\"14\" ng-model=\"ctx.posmodel.argument.wert\" name=\"wert\" dias-decimal-field aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_wert\" ng-attr-aria-invalid=\"{{ctx.errors['wert'] ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">&euro;</span></div></div>");
}
}, "Betrag in EUR", "wert", "ctx.errors", "ctx.prefix");
buf.push("</div>");;return buf.join("");
}