"use strict";

import angular from "angular";
import AsideInitDirective from "./asideinit.directive";

const diasAsideInit = angular.module("dias.asideinit", [])

  .directive("asideInit", AsideInitDirective);

export default diasAsideInit;
