import NotizenModal from "../NotizenModal/NotizenModal.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  gettext,
} from "../../../global/functions/utils";
import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "NotizenList",
  components: {
    NotizenModal,
    PuxTranslate,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    notizenKey: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      statusModal: false,
      singlenotiz: undefined,
      singlenotizIndex: undefined,
      singlenotizPk: undefined,
    };
  },
  computed: {
    label() {
      return this.options.titel;
    },

    labelModalTranslate() {
      return {
        text: this.singlenotiz ? "_TXT_NOTIZEN_HEADER_SINGLE_{{label}}_" : "_TXT_NOTIZEN_HEADER_{{label}}_",
        extra: {
          label: this.labelTranslate,
        }
      };
    },

    labelTranslate() {
      return gettext(this.label);
    },

    htmlId() {
      return `${ this.parentId }_${ this.notizenKey }`;
    },

    htmlIds() {
      const IDS = {};
      forEach(this.options.list, notiz => {
        IDS[notiz.pk] = `${ this.htmlId }_${ notiz.pk }`;
      });
      return IDS;
    },

    selectorClose() {
      const SELECTORS_CLOSE = [
        `#${ this.htmlId }`,
        `#${ this.parentId }`,
      ];

      if (this.singlenotiz) {
        SELECTORS_CLOSE.unshift(`#${ this.htmlIds[this.singlenotizPk] }`);
      }
      return SELECTORS_CLOSE;
    },
  },
  methods: {
    showNotizenLocal({ notiz, index } = {}) {
      if (notiz) {
        this.singlenotiz = true;
        this.singlenotizIndex = index;
        this.singlenotizPk = notiz.pk;
      }
      this.statusModal = true;
    },

    closeModal() {
      this.statusModal = false;
      this.singlenotiz = undefined;
      this.singlenotizIndex = undefined;
      this.singlenotizPk = undefined;
    },
  },
};
