import {
  computed,
  provide,
  ref,
  toRefs,
} from "vue";

import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import SitzungDetailsTagesordnungItem from "./SitzungDetailsTagesordnungItem/SitzungDetailsTagesordnungItem.vue";
import SitzungDetailsTagesordnungModalCreate from "./SitzungDetailsTagesordnungModalCreate/SitzungDetailsTagesordnungModalCreate.vue";
import SitzungDetailsTagesordnungSorting from "./SitzungDetailsTagesordnungSorting/SitzungDetailsTagesordnungSorting.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

// @vue/component
export default {
  name: "SitzungDetailsTagesordnung",
  components: {
    AngularLink,
    FormstepDetail,
    Modal,
    PuxButton,
    PuxIcon,
    SitzungDetailsTagesordnungItem,
    SitzungDetailsTagesordnungModalCreate,
    SitzungDetailsTagesordnungSorting,
  },
  props: {
    sitzung: {
      type: Object,
      required: true,
    },
    sitzungUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      sitzung,
      sitzungUrl,
    } = toRefs(props);

    const {
      addNotification,
      getHttp,
      getListHttp,
      postHttp,
    } = HttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    const hasWFPermissionTagesordnungCreate = computed(() => {
      return checkPermissionsSync({
        perm: "tops.create",
        permArray: sitzung.value.user_permissions,
      });
    });

    const hasWFPermissionTagesordnungUpdate = computed(() => {
      return checkPermissionsSync({
        perm: "tops.update",
        permArray: sitzung.value.user_permissions,
      });
    });

    const hasWFPermissionTagesordnungDelete = computed(() => {
      return checkPermissionsSync({
        perm: "tops.delete",
        permArray: sitzung.value.user_permissions,
      });
    });

    const tagesordnung = ref([]);
    const modulesPreview = ref({});
    const canReset = ref(true);
    const lastEditTop = ref(undefined);
    const loadingTagesordnung = ref(false);
    const loadingModulesPreview = ref(false);
    const sortingStatus = ref(false);
    const showModalResetTagesordnung = ref(false);
    const showModalTopCreate = ref(false);
    const confirmResetTagesordnungOptions = ref({});
    const errors = ref({});

    const optionsForFormstepDetails = {
      hideHeader: true,
    };

    const topsUrl = computed(() => {
      return `${ sitzungUrl.value }tops/`;
    });

    const toggleSortModeButtonText = computed(() => {
      return sortingStatus.value ? "_LBL_SITZUNGSDETAILS_TOGGLE_SORT_MODE_OFF_" : "_LBL_SITZUNGSDETAILS_TOGGLE_SORT_MODE_ON_";
    });

    const buttonTooltipResetTagesordnung = computed(() => {
      return canReset.value ?
        "_TXT_SITZUNGSDETAILS_RESET_ALL_TOPS_TO_DEFAULTS_"
        : "_TXT_SITZUNGSDETAILS_UNABLE_RESET_ALL_TOPS_TO_DEFAULTS_";
    });

    const showLastEditTop = () => {
      getHttp({
        url: `${ topsUrl.value }last_edit/`,
      }).then(
        response => lastEditTop.value = response.date,
      );
    };

    const getModulesPreview = () => {
      loadingModulesPreview.value = true;
      getHttp({
        url: `${ topsUrl.value }modulvorschau/`,
      }).then(
        response => modulesPreview.value = response,
      ).finally(
        () => loadingModulesPreview.value = false,
      );
    };

    function prepareTagesordnung() {
      canReset.value = true;
      tagesordnung.value.forEach(top => {
        canReset.value = canReset.value && top.deletable;
        top.unterpunkte.forEach(utop => {
          canReset.value = canReset.value && utop.deletable;
        });
      });
    }

    const reloadTagesordnung = () => {
      loadingTagesordnung.value = true;
      return getListHttp({
        url: topsUrl.value,
      }).then(
        response => {
          tagesordnung.value = response;
          prepareTagesordnung();
          showLastEditTop();
          getModulesPreview();
          loadingTagesordnung.value = false;
          return Promise.resolve(response);
        },
        errors => {
          errors.value = errors;
          return Promise.reject(errors);
        },
      );
    };

    const toggleSortTagesordnung = () => {
      if (!sortingStatus.value) {
        showLastEditTop();
      } else {
        reloadTagesordnung();
      }
      sortingStatus.value = !sortingStatus.value;
    };

    const cancelResetTagesordnung = () => {
      showModalResetTagesordnung.value = false;
    };

    const resetTagesordnung = () => {
      confirmResetTagesordnungOptions.value.loading = true;
      loadingTagesordnung.value = true;
      return postHttp({
        url: `${ topsUrl.value }reset/`,
      }).then(
        response => {
          tagesordnung.value = response;
          prepareTagesordnung();
          showLastEditTop();
          getModulesPreview();
          showModalResetTagesordnung.value = false;
          return Promise.resolve(response);
        },
        errors => {
          errors.value = errors;
          addNotification({
            text: "_MSG_SITZUNGSDETAILS_RESET_ALL_TOPS_TO_DEFAULTS_ERROR_",
            type: "error",
          });
          return Promise.resolve(errors);
        },
      ).finally(
        () => loadingTagesordnung.value = false,
      );
    };

    const confirmResetTagesordnung = () => {
      confirmResetTagesordnungOptions.value = {
        okClass: "btn btn-primary",
        title: "_TXT_SITZUNG_DETAILS_TOPS_ZURUECKSETZEN_MODAL_TITLE_",
        msg: "_HTML_SITZUNG_DETAILS_TOPS_ZURUECKSETZEN_MODAL_BODY_",
        okLabel: "_BTN_SITZUNG_DETAILS_TOPS_ZURUECKSETZEN_MODAL_",
        okCallback: resetTagesordnung,
        cancelCallback: cancelResetTagesordnung,
        loading: false,
      };
      showModalResetTagesordnung.value = true;
    };

    const createTagesordnungspunkt = () => {
      showModalTopCreate.value = true;
    };

    const closeModalTopCreate = () => {
      showModalTopCreate.value = false;
    };

    const toggleLoadingTagesordnung = () => {
      loadingTagesordnung.value = !loadingTagesordnung.value;
    };

    const onErrors = ({ errorsData }) => {
      errors.value = errorsData.data;
    };

    const deleteErrors = () => {
      errors.value = undefined;
    };

    provide("toggleLoadingTagesordnung", toggleLoadingTagesordnung);
    provide("reloadTagesordnung", reloadTagesordnung);

    reloadTagesordnung();

    return {
      closeModalTopCreate,
      confirmResetTagesordnung,
      createTagesordnungspunkt,
      deleteErrors,
      onErrors,
      reloadTagesordnung,
      toggleSortTagesordnung,
      toggleLoadingTagesordnung,

      buttonTooltipResetTagesordnung,
      canReset,
      confirmResetTagesordnungOptions,
      errors,
      hasWFPermissionTagesordnungCreate,
      hasWFPermissionTagesordnungDelete,
      hasWFPermissionTagesordnungUpdate,
      loadingModulesPreview,
      loadingTagesordnung,
      optionsForFormstepDetails,
      showModalResetTagesordnung,
      showModalTopCreate,
      modulesPreview,
      sortingStatus,
      tagesordnung,
      toggleSortModeButtonText,
      topsUrl,
    };
  },
};
