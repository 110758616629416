import FormElement from "../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../global/components/ui/ShowMore/ShowMore.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import {
  cloneDeep,
  forEach,
  sortBy,
} from "lodash-es";

// @vue/component
export default {
  name: "BelegeModalDeleteDocument",
  components: {
    FormElement,
    Modal,
    PuxButton,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
    ShowMore,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    vertragId: {
      type: String,
      default: undefined,
    },
    documentId: {
      type: String,
      default: undefined,
    },
  },
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      filterCurrency,
    };
  },
  data() {
    return {
      model: {
        confirmed: false,
      },
      optionsModal: {
        showCloseButton: true,
      },
      optionsBoolean: {
        id: "confirmed",
        type: "boolean",
        label: "_LBL_BELEGE_MODAL_DELETE_DOCUMENT_CONFIRMED_",
        view: "v-alt",
        translate: true,
      },
      belegDoc: undefined,
      belegList: undefined,
      belegePerms: {},
      countBelegePerms: undefined,
      status: {
        loading: undefined,
        loadingBelegDoc: true,
        loadingBelege: true,
      },
    };
  },
  computed: {
    canDelete() {
      if (this.countBelegePerms > 0) {
        return false;
      }
      for (const key in this.belegePerms) {
        if (!this.belegePerms[key]) {
          return false;
        }
      }
      return true;
    },

    getBelegeListSortByNummer() {
      return sortBy(this.belegList, ["nummer"]);
    },

    getHerkunftTranslate() {
      return ({ herkunft }) => {
        return herkunft === "vertragsfinanzierung_positionsbeleg" ? "_TXT_BELEGE_MODAL_DELETE_FINANZIERUNGSPOSITION_" : "_TXT_BELEGE_MODAL_DELETE_KOSTENPOSITION_";
      };
    },
  },
  created() {
    this.loadDocument();
  },
  methods: {
    loadDocument() {
      this.getHttp({ url: `vertraege/${ this.vertragId }/belegdokumente/${ this.documentId }/` }).then(
        response => {
          this.belegDoc = response;
          this.loadBelege();
        },
        () => this.addNotification({ text: "_MSG_BELEGE_MODAL_DELETE_DOCUMENT_LOAD_ERROR_", type: "error" })
      ).then(
        () => this.status.loadingBelegDoc = false
      );
    },

    loadBelege() {
      this.getListHttp({
        url: `vertragsbelege/`,
        urlParams: {
          pk: this.belegDoc.belege,
        }
      }).then(
        response => {
          this.belegList = response;
          this.countBelegePerms = response.length;
          forEach(this.belegList, beleg => {
            this.checkPermission({ beleg });
          });
        },
        () => this.addNotification({ text: "_MSG_BELEGE_MODAL_DELETE_DOCUMENT_LOAD_BELEGE_ERROR_", type: "error" })
      ).then(
        () => this.status.loadingBelege = false
      );
    },

    checkPermission({ beleg }) {
      this.getHttp({ url: `vertragsbelege/${ beleg.pk }/check_my_permissions/?permissions=vertragskostenpositionsbelege.update` }).then(
        perms => {
          // TODO; Ilia
          const BELEGE_PERMS = cloneDeep(this.belegePerms);
          BELEGE_PERMS[beleg.pk] = this.checkPermissionsSync({ perm: "vertragskostenpositionsbelege.update", permArray: perms });
          this.belegePerms = BELEGE_PERMS;
          this.countBelegePerms--;
        }
      );
    },

    deleteDocument() {
      this.status.loading = true;
      this.deleteHttp({ url: `vertraege/${ this.vertragId }/belegdokumente/${ this.documentId }/` }).then(
        () => {
          this.addNotification({ text: "_MSG_BELEGE_MODAL_DELETE_DOCUMENT_DELETE_SUCCESS_" });
          this.close({ status: true });
        },
        () => {
          this.addNotification({ text: "_MSG_BELEGE_MODAL_DELETE_DOCUMENT_DELETE_ERROR_", type: "error" });
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
