import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotItem from "../../../../Snapshot/SnapshotItem/SnapshotItem.vue";

import {
  FilterFileSizeMixin,
} from "../../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "VertragDocumentsItem",
  components: {
    PuxIcon,
    SnapshotIcon,
    SnapshotItem,
  },
  mixins: [
    FilterFileSizeMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    documentIndex: {
      type: Number,
      required: true,
    },
    documentSnapshot: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    isSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    isSnapshotChanges: {
      type: Boolean,
      required: false,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    idLocal() {
      return `${ this.id }_${ this.documentIndex }`;
    },

    dokumentTitle() {
      return `${ this.document.datei } (${ this.dokumentSize })`;
    },

    dokumentSize() {
      return this.filterFileSize(this.document.groesse, "KB");
    },

    dokumentTitleSnapshot() {
      return `${ this.documentSnapshot.datei } (${ this.dokumentSizeSnapshot })`;
    },

    dokumentSizeSnapshot() {
      return this.filterFileSize(this.documentSnapshot.groesse, "KB");
    },

    snapshotIconType() {
      if (this.isSnapshotAdd) {
        return "add";
      }
      if (this.isSnapshotDelete) {
        return "delete";
      }
      if (this.isSnapshotChanges) {
        return "changes";
      }
      return undefined;
    },

    componentSnapshot() {
      if (this.isSnapshotDelete) {
        return "span";
      }
      return "a";
    },

    attributesSnapshot() {
      if (!this.isSnapshotDelete) {
        return {
          href: this.documentSnapshot.url,
          target: "_self",
        };
      }
      return {};
    },
  },
};
