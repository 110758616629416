"use strict";
import angular from "angular";

import sitzungsmodule from "dias/sitzungen/sitzungsmodule";

import detail from "./detail.component";
import preview from "./preview.component";

const modul = angular.module("dias.standard_sitzungsmodule.automatische_antragsliste_mittellos", [
  sitzungsmodule.name,
])

  .config(/*@ngInject*/SitzungsmodulRegistryProvider => {
    SitzungsmodulRegistryProvider.register("automatische_antragsliste_mittellos", "modul-automatische-antragsliste-mittellos", "modul-automatische-antragsliste-mittellos-preview");
  })

  .component("modulAutomatischeAntragslisteMittellos", detail)
  .component("modulAutomatischeAntragslisteMittellosPreview", preview)
;

export default modul;
