import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import AuszahlungBetragBoxBetragAndDifferenz from "./AuszahlungBetragBoxBetragAndDifferenz/AuszahlungBetragBoxBetragAndDifferenz.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import AuszahlungBetragBoxMixin from "./AuszahlungBetragBoxMixin";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "AuszahlungBetragBox",
  components: {
    AsideRightBox,
    AuszahlungBetragBoxBetragAndDifferenz,
    PuxTranslate,
  },
  mixins: [
    AuszahlungBetragBoxMixin,
  ],
  props: {
    auszahlung: {
      type: Object,
      required: true,
    },
    isModalitaetLast: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      filterCurrency,
    };
  },
  data() {
    return {
      optionsAsideRightBox: {
        icon: "salden",
        toggle: false,
      },
    };
  },
};
