import AngularLink from "../../AngularLink/AngularLink.vue";
import Permission from "../../Permission/Permission.vue";

import translate from "../../../directives/translate";

import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("table");

// @vue/component
export default {
  name: "TablePanelActionsLink",
  components: {
    AngularLink,
    Permission
  },
  directives: {
    translate,
  },
  props: {
    loading: {
      type: Boolean,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    tableOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    urlParamsString() {
      let urlParamsString = "";
      if (this.options.urlParams) {
        urlParamsString = `${ this.GET_URL_PARAMS({ tableId: this.tableOptions.id }) }`;
      }
      return urlParamsString;
    },

    ...mapGetters([
      "GET_URL_PARAMS",
    ]),
  },
};
