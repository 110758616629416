import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import NutzerModalAktivieren from "../NutzerModalAktivieren/NutzerModalAktivieren.vue";
import NutzerModalAnonymisieren from "../NutzerModalAnonymisieren/NutzerModalAnonymisieren.vue";
import NutzerModalAnonymisierenAll from "../NutzerModalAnonymisierenAll/NutzerModalAnonymisierenAll.vue";
import NutzerModalAuthAendern from "../NutzerModalAuthAendern/NutzerModalAuthAendern.vue";
import NutzerModalCreate from "../NutzerModalCreate/NutzerModalCreate.vue";
import NutzerModalSendRegMail from "../NutzerModalSendRegMail/NutzerModalSendRegMail.vue";
import NutzerModalSperren from "../NutzerModalSperren/NutzerModalSperren.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import getTableApi from "../../../const/TableApis";
import NutzerStatusUUIDs from "../../../const/NutzerStatusUUIDs";
import { EventBus } from "../../../global/functions/event-bus";
import {
  assign,
  isArray,
  size,
} from "lodash-es";

const TABLE_ID = "nutzer";

// @vue/component
export default {
  name: "NutzerList",
  components: {
    AngularLink,
    NutzerModalAktivieren,
    NutzerModalAnonymisieren,
    NutzerModalAnonymisierenAll,
    NutzerModalAuthAendern,
    NutzerModalCreate,
    NutzerModalSendRegMail,
    NutzerModalSperren,
    PageTabTitle,
    PuxCloak,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [

        ],
        rowActions: [
          {
            label: "_LBL_NUTZER_LIST_ACTION_ROW_DETAILS_",
            route: "root.nutzerverwaltung.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
          {
            label: "_LBL_NUTZER_LIST_NUTZER_SPERREN_",
            isHidden: ({ item }) => !(this.checkPermissionsSync({ perm: "nutzer.sperrung.update", permArray: item.user_permissions }) && NutzerStatusUUIDs.isActive(item.status.ns_id)),
            callback: ({ item, index }) => this.sperrenNutzer({ item, index }),
          },
          {
            label: "_LBL_NUTZER_LIST_NUTZER_AKTIVIEREN_",
            isHidden: ({ item }) => !(this.checkPermissionsSync({ perm: "nutzer.update", permArray: item.user_permissions }) && item.status.ns_id === NutzerStatusUUIDs.GESPERRT),
            callback: ({ item, index }) => this.aktivierenNutzer({ item, index })
          },
          {
            label: "_LBL_NUTZER_LIST_AUTHENTIFIZIERUNGSMETHODE_",
            isHidden: ({ item }) => !this.showAuthSwitch || !(this.checkPermissionsSync({ perm: "nutzer.update", permArray: item.user_permissions }) && NutzerStatusUUIDs.isActive(item.status.ns_id)),
            callback: ({ item, index }) => this.authAendern({ item, index })
          },
          {
            label: "_LBL_NUTZER_LIST_REGISTRIERUNGSMAIL_VERSCHICKEN_",
            isHidden: ({ item }) => !(this.checkPermissionsSync({ perm: "nutzer.update", permArray: item.user_permissions }) && item.status.ns_id === NutzerStatusUUIDs.ANGELEGT),
            callback: ({ item }) => this.registrierungsmailVerschicken({ item })
          },
          {
            label: "_LBL_NUTZER_LIST_NUTZER_ANONYMISIEREN_",
            isHidden: ({ item }) => item.n_systemnutzer || !this.checkPermissionsSync({ perm: "nutzer.anonymise" }),
            callback: ({ item }) => this.anonymisierenNutzer({ item }),
          },
        ],
        actions: [
          {
            permission: "nutzer.anonymise",
            label: "_LBL_NUTZER_LIST_NUTZER_ANONYMISIEREN_",
            callback: () => this.modal.anonymisierenAll = true,
            type: "button",
            className: "btn btn-default",
            icon: "glyphicon-trash",
            htmlId: "nutzer_anonymise_all",
          },
          {
            label: "_LBL_NUTZER_LIST_NUTZER_HINZUFUEGEN_",
            callback: () => this.modal.create = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "nutzer_create",
            isHidden: () => !this.canCreate
          },
        ],
        label: "_LBL_NUTZER_LIST_NUTZER_",
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
        },
      },
      filters: [

      ],
      status: {
        loading: true,
      },
      modal: {
        nutzerSperren: undefined,
        nutzerAktivieren: undefined,
        registrierungsmailVerschicken: undefined,
        anonymisieren: undefined,
        authAendern: undefined,
        create: undefined,
        anonymisierenAll: undefined,
      },
      currentNutzer: undefined,
      currentIndex: undefined,
      defaultAuthmethod: undefined,
      hideAuthmethod: undefined,
      showTitle: undefined,
      showAuthSwitch: false,
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },

    canCreate() {
      return this.hasOnePermissions({ perm: [
        "nutzer.create",
        "nutzer.all.create",
        "nutzer.untergeordnet.create",
      ] });
    },
  },
  created() {
    this.initFilters();
    this.initDefaultValues();
    this.initTitle();
  },
  methods: {
    initFilters() {
      if (this.$stateParams && this.$stateParams.aktiv) {
        this.filters.push({
          type: "boolean",
          id: "aktiv",
          label: "_LBL_NUTZER_LIST_AKTIV_",
          bool: true,
          trueValue: "_LBL_NUTZER_LIST_TRUE_",
          falseValue: "_LBL_NUTZER_LIST_FALSE_",
          defaultLabel: "_LBL_NUTZER_LIST_NICHT_AUSGEWAEHLT_",
        });
      }
      this.status.loading = false;
    },

    initDefaultValues() {
      const conf = this.getSyConfigsValue("auth_defaults");
      this.defaultAuthmethod = conf.default_nutzer;
      this.hideAuthmethod = !conf.show_auth_nutzer;
      this.getHttp({
        url: "katalog/",
        urlParams: { key: "authmethods" }
      }).then(
        response => {
          if (isArray(response) && size(response) > 1) {
            this.showAuthSwitch = true;
          }
        }
      );
    },

    initTitle() {
      this.showTitle = this.getSyConfigsValue("nutzer_titel");
    },

    sperrenNutzer({ item, index }) {
      this.currentNutzer = item;
      this.currentIndex = index;
      this.modal.nutzerSperren = true;
    },

    closeModalSperrenNutzer({ response } = {}) {
      this.updateRow({ response });
      this.modal.nutzerSperren = false;
    },

    authAendern({ item, index }) {
      this.currentNutzer = item;
      this.currentIndex = index;
      this.modal.authAendern = true;
    },

    closeModalAuthAendern({ response } = {}) {
      this.updateRow({ response });
      this.modal.authAendern = false;
    },

    aktivierenNutzer({ item, index }) {
      this.currentNutzer = item;
      this.currentIndex = index;
      this.modal.nutzerAktivieren = true;
    },

    closeModalAktivierenNutzer({ response } = {}) {
      this.updateRow({ response });
      this.modal.nutzerAktivieren = false;
    },

    updateRow({ response }) {
      if (response) {
        const row = assign({}, this.currentNutzer, response);
        EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row, index: this.currentIndex }));
      }
    },

    registrierungsmailVerschicken({ item }) {
      this.currentNutzer = item;
      this.modal.registrierungsmailVerschicken = true;
    },

    closeModalRegistrierungsmailVerschicken() {
      this.modal.registrierungsmailVerschicken = false;
    },

    anonymisierenNutzer({ item }) {
      this.currentNutzer = item;
      this.modal.anonymisieren = true;
    },

    closeModalAnonymisieren({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.modal.anonymisieren = false;
    },

    closeModalCreate() {
      this.modal.create = false;
    },

    closeModalAnonymisierenAll({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.modal.anonymisierenAll = false;
    },
  },
};
