import {
  computed,
  toRef,
} from "vue";

import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "SitzungsartInfo",
  components: {
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    sitzungsart: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const sitzungsart = toRef(props, "sitzungsart");
    const {
      filterDate,
    } = FiltersAPI();
    const naechstesDatumFormatted = computed(() => {
      return filterDate(sitzungsart.value.naechstes_datum);
    });
    const translateExtraForTerminvorschlag = computed(() => {
      return {
        sia_turnus: sitzungsart.value.sia_turnus,
        naechstes_datum: naechstesDatumFormatted.value,
      };
    });

    const optionsFormstepDetail = {
      hideHeader: true,
    };

    return {
      optionsFormstepDetail,
      translateExtraForTerminvorschlag,
    };
  },
};
