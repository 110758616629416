import FormElement from "../../../../../../../../libs/vue/client/vue/global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";
import Modal from "../../../../../../../../libs/vue/client/vue/global/components/Modal/Modal.vue";

import HttpMixin from "../../../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import PermissionMixin from "../../../../../../../../libs/vue/client/vue/global/mixins/PermissionMixin";
import NotificationMixin from "../../../../../../../../libs/vue/client/vue/global/mixins/NotificationMixin";

import KStudiengangartUUIDs from "../../../../../../../../libs/vue/client/vue/const/KStudiengangartUUIDs";

import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "HochschuleDetailsStudienOrgTeileinheit",
  components: {
    FormElement,
    FormstepDetail,
    Modal,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
  ],
  props: {
    hochschule: {
      type: Object,
      required: true,
    },
    teileinheit: {
      type: Object,
      required: true,
    },
    teileinheitIndex: {
      type: Number,
      required: true,
    },
    parentHtmlId: {
      type: String,
      required: true,
    },
  },
  emits: [
    "loadTeileinheiten",
  ],
  data() {
    return {
      model: {
        sot_bez: undefined,
        sot_kbez: undefined,
        deletable: undefined,
        studiengaenge: [],
      },
      errors: {},
      confirmDeleteShow: false,
      confirmDeleteOptions: {},
      modalLoading: false,
    };
  },
  computed: {
    label() {
      return `${ this.teileinheitIndex + 1 }. Teileinheit`;
    },

    htmlId() {
      return `hochschuledetails_teileinheiten_org_teileinheit_${ this.teileinheitIndex }`;
    },

    selectorClose() {
      return [
        `#${ this.htmlId }`,
        `#${ this.parentHtmlId }`
      ];
    },

    teileinheitenUrl() {
      return `hochschulen/${ this.hochschule.pk }/teileinheiten/`;
    },

    optionsList() {
      return [
        {
          id: "sot_kbez",
          htmlId: `sot_kbez_${ this.htmlId }`,
          type: "text",
          view: "v-alt",
          label: "Name",
          required: true,
        },
        {
          id: "sot_bez",
          htmlId: `sot_bez_${ this.htmlId }`,
          type: "richtext",
          view: "v-alt",
          label: "Beschreibung",
        },
        {
          id: "studiengaenge",
          htmlId: `studiengaenge_${ this.htmlId }`,
          type: "multiselect",
          view: "v-alt",
          label: "Studiengänge",
          url: "katalog/",
          urlParams: {
            art: [KStudiengangartUUIDs.EINZEL, KStudiengangartUUIDs.KOMBI],
            key: "studiengaenge",
            hochschule: this.hochschule.pk,
            studienorganisatorische_teileinheit: ["none", this.teileinheit.pk],
          },
          apiSaveId: "studiengaenge_hochschule_pk_teileinheiten",
          keyId: "sg_id",
          keyLabel: "sg_bez",
          search: true,
          searchList: ["sg_bez", "sg_kbez"],
        },
      ];
    },

    options() {
      return {
        label: this.label,
        required: true,
        deletable: this.hasPermissionTeileinheitenDelete && this.teileinheit.deletable,
        openCallback: this.open,
        saveCallback: this.save,
        deleteCallback: () => this.openModalConfirmDelete(),
      };
    },

    hasPermissionTeileinheitenDelete() {
      return this.checkPermissionsSync({
        perm: "teileinheiten.delete",
        permArray: this.hochschule.user_permissions,
      });
    },

    hasPermissionTeileinheitenUpdate() {
      return this.checkPermissionsSync({
        perm: "teileinheiten.update",
        permArray: this.hochschule.user_permissions,
      });
    },
  },
  created() {
    this.setModel();
  },
  methods: {
    open() {
      this.errors = {};
    },

    setModel() {
      this.model = cloneDeep(this.teileinheit);
      const STUDIENGAENGE_IDS = [];
      forEach(this.teileinheit.studiengaenge, item => {
        STUDIENGAENGE_IDS.push(item.pk);
      });
      this.model.studiengaenge = STUDIENGAENGE_IDS;
    },

    openModalConfirmDelete() {
      this.confirmDeleteOptions = {
        okClass: "btn btn-primary",
        title: "Studienorganisatorische Teileinheit löschen?",
        msg: `Wollen Sie die studienorganisatorische Teileinheit ${ this.model.sot_bez } wirklich löschen?`,
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: () => this.delete(),
        cancelCallback: this.closeModalConfirmDelete,
      };
      this.confirmDeleteShow = true;
    },

    closeModalConfirmDelete() {
      this.confirmDeleteShow = false;
    },

    save() {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: `${ this.teileinheitenUrl }${ this.teileinheit.pk }/`,
          data: {
            sot_bez: this.model.sot_bez,
            sot_kbez: this.model.sot_kbez,
            studiengaenge_ids: this.model.studiengaenge,
          },
        }).then(
          () => {
            this.addNotification({ text: "Änderungen erfolgreich gespeichert" });
            this.$emit("loadTeileinheiten");
            resolve();
          },
          errors => {
            this.onErrors(errors);
            reject(errors);
          }
        );
      });
    },

    delete() {
      this.modalLoading = true;
      return new Promise((resolve, reject) => {
        this.deleteHttp({
          url: `${ this.teileinheitenUrl }${ this.model.pk }/`,
          data: this.model,
        }).then(
          () => {
            this.$emit("loadTeileinheiten");
            this.closeModalConfirmDelete();
            this.addNotification({ text: `Studienorganisatorische Teileinheiten ${ this.model.sot_bez } erfolgreich gelöscht` });
            resolve();
          },
          errors => {
            this.onErrors(errors);
            reject(errors);
          }
        ).finally(
          () => {
            this.modalLoading = false;
          }
        );
      });
    },

    onErrors(errors) {
      this.errors = errors.data;
    },
  },
};
