import ProfilDetailsPersonalItem from "./ProfilDetailsPersonalItem/ProfilDetailsPersonalItem.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";
import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

import {
  isEmpty,
} from "lodash";

// @vue/component
export default {
  name: "ProfilDetailsPersonal",
  components: {
    ProfilDetailsPersonalItem,
    PuxCloak,
    PuxTranslate,
  },
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    const {
      getHttp,
    } = HttpAPI();

    return {
      filterDate,
      getHttp,
    };
  },
  data() {
    return {
      loading: false,
      personal: [],
    };
  },
  computed: {
    hasPersonalData() {
      return !isEmpty(this.personal);
    },

    urlPersonal() {
      return `profil/${ this.nutzer.pk }/personal/`;
    },
  },
  created() {
    this.loadPersonal();
  },
  methods: {
    loadPersonal() {
      this.loading = true;
      this.getHttp({
        url: this.urlPersonal,
      }).then(
        response => {
          this.personal = response.results;
          this.loading = false;
        }
      );
    },
  },
};
