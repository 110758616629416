import {
  computed,
} from "vue";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";
import SyConfigAPI from "../../../../global/compositionAPI/SyConfigAPI";

import {
  cloneDeep,
  get,
} from "lodash-es";

export default function AntragNotGroupedAPI({ antrag, antragUrl }) {
  const updateTeam = ({ team } = {}) => {
    antrag.value.team = cloneDeep(team);
  };

  const onCallbackFavoritenAndSichtbarkeit = ({ data }) => {
    antrag.value = Object.assign({}, antrag.value, data);
  };

  const {
    checkPermissionsSync,
  } = PermissionAPI();
  const hasPermissionFoerderantragVersionenManuellErstellen = computed(() => {
    return checkPermissionsSync({
      perm: "foerderantrag.versionen_manuell_erstellen",
    });
  });
  const hasPermissionFoerderantragAkteErstellen = computed(() => {
    return checkPermissionsSync({
      perm: "foerderantrag.akte.erstellen",
    });
  });
  const hasPermissionKoFiDownload = computed(() => {
    return checkPermissionsSync({
      perm: "foerderantrag.kofi_download",
      permArray: antrag.value.user_permissions,
    });
  });
  const hasWFPermissionFoerderantragKopieren = computed(() => {
    return checkPermissionsSync({
      perm: "foerderantrag.kopieren",
      permArray: antrag.value.user_permissions,
    });
  });
  const hasWFPermissionFoerderantragDelete = computed(() => {
    return checkPermissionsSync({
      perm: "foerderantrag.delete",
      permArray: antrag.value.user_permissions,
    });
  });
  const hasWFPermissionFoerderantragUpdate = computed(() => {
    return checkPermissionsSync({
      perm: "foerderantrag.update",
      permArray: antrag.value.user_permissions,
    });
  });

  const aktuelleAufgabeTaskId = computed(() => {
    return get(antrag.value, "aktuelle_aufgabe.task_id");
  });

  const {
    getSyConfigsValue,
  } = SyConfigAPI();
  const astAlsButtonConfig = computed(() => {
    return getSyConfigsValue("ast_als_button", {});
  });
  const antragConfig = computed(() => {
    return getSyConfigsValue("foerderantrag", {});
  });
  const {
    filterSpaced,
  } = FiltersAPI();
  const antragExternSuffix = computed(() => {
    let extern = "";
    if (antragConfig.value.externe_nummern && antrag.value.a_nr_extern) {
      extern = ` (${ antrag.value.a_nr_extern })`;
    }
    return extern;
  });
  const antragLabel = computed(() => {
    return `${ filterSpaced(antrag.value.a_nr) }${ antragExternSuffix.value }`;
  });
  const antragLabelWithoutHtml = computed(() => {
    return `${ filterSpaced(antrag.value.a_nr, true) }${ antragExternSuffix.value }`;
  });

  const antragAkteUrl = computed(() => {
    return `${ antragUrl.value }akte/`;
  });

  const antragKofiDokumentUrl = computed(() => {
    return `${ antragUrl.value }kofi_dokument/`;
  });

  const antragstellerName = computed(() => {
    return get(antrag.value, "antragstellerorg_obj.ast_name");
  });

  const anzeigekontexteAntrag = computed(() => {
    return get(antrag.value, "aktuelle_aufgabe.au_kontext_config.markiert.antrag") || {};
  });

  const anzeigekontexteAntragOpen = computed(() => {
    return get(antrag.value, "aktuelle_aufgabe.au_kontext_config.offen.antrag") || {};
  });

  const antragAktuelleAufgabeBez = computed(() => {
    return get(antrag.value, "aktuelle_aufgabe.bez");
  });

  const regelsatzKbez = computed(() => {
    return get(antrag.value, "regelsatz_obj.ars_kbez");
  });

  const notizOptionsVue = {
    noOrgText: "_TXT_NOTIZEN_FOERDERANTRAG_NO_ORG_",
  };

  const statusWFStatusGruppenInHeader = computed(() => {
    const WF_STATUS_GRUPPEN_IN_HEADER = getSyConfigsValue("wf_statusgruppen_in_header", {});
    WF_STATUS_GRUPPEN_IN_HEADER.objects = WF_STATUS_GRUPPEN_IN_HEADER.objects || [];
    return WF_STATUS_GRUPPEN_IN_HEADER.objects.indexOf("antrag") !== -1;
  });

  const anzeigekontextPks = computed(() => {
    return antrag.value.kontexte;
  });

  const isAntragEntschieden = computed(() => {
    return !!(antrag.value.a_bewilligungsdatum || antrag.value.a_ablehnungsdatum);
  });

  const hasPerioden = computed(() => {
    return !!get(antrag.value, "regelsatz_obj.perioden");
  });

  return {
    aktuelleAufgabeTaskId,
    antragAkteUrl,
    antragAktuelleAufgabeBez,
    antragConfig,
    antragKofiDokumentUrl,
    antragLabel,
    antragLabelWithoutHtml,
    antragstellerName,
    anzeigekontextPks,
    anzeigekontexteAntrag,
    anzeigekontexteAntragOpen,
    astAlsButtonConfig,
    hasPerioden,
    hasPermissionFoerderantragAkteErstellen,
    hasPermissionKoFiDownload,
    hasWFPermissionFoerderantragDelete,
    hasWFPermissionFoerderantragUpdate,
    hasWFPermissionFoerderantragKopieren,
    hasPermissionFoerderantragVersionenManuellErstellen,
    isAntragEntschieden,
    notizOptionsVue,
    onCallbackFavoritenAndSichtbarkeit,
    regelsatzKbez,
    statusWFStatusGruppenInHeader,
    updateTeam,
  };
}
