import BelegButtonVersionsvergleichBetragsrechnerTableRow
  from "../../BelegButtonVersionsvergleichBetragsrechnerTableRow/BelegButtonVersionsvergleichBetragsrechnerTableRow.vue";
import FormElementReadOnly from "../../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import HonorarkostenOhneQuali from "../../../../../KF/Betragsrechner/HonorarkostenOhneQuali/HonorarkostenOhneQuali";

// @vue/component
export default {
  name: "HonorarkostenOhneQualiBelegVergleich",
  components: {
    BelegButtonVersionsvergleichBetragsrechnerTableRow,
    FormElementReadOnly,
    PuxTranslate,
    SnapshotIcon,
  },
  mixins: [
    HonorarkostenOhneQuali,
  ],
  props: {
    modelErfasst: {
      type: Object,
      required: true,
    },
    modelAnerkannt: {
      type: Object,
      required: true,
    },
  },
};
