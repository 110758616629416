import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import {
  EventBus,
} from "../../../../global/functions/event-bus";
import {
  setModelWithNoCache,
  setModelWithoutNoCache,
  setSrcWithNoCacheForAllImagesInPage,
} from "../../../../global/const/NoCache";
import {
  cloneDeep,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "FaqAdminInfo",
  components: {
    FormstepDetail,
  },
  props: {
    faq: {
      type: Object,
      required: true,
    },
    updateFaq: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const {
      getListHttp,
      putHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      getListHttp,
      putHttp,
    };
  },
  data() {
    return {
      noCacheObj: {},
      keywordsData: [],
    };
  },
  computed: {
    options() {
      return {
        label: "_TXT_FAQ_ADMIN_DETAILS_FAQ_PANEL_FORMSTEP_NAME_",
        list: [
          {
            id: "fqe_frage",
            type: "text",
            label: "_LBL_FAQ_ADMIN_DETAILS_FRAGE_",
            showLabel: true,
            view: "v",
            required: true,
          },
          {
            id: "fqe_antwort",
            type: "richtext",
            label: "_LBL_FAQ_ADMIN_DETAILS_ANTWORT_",
            showLabel: true,
            view: "v-alt",
            required: false,
            dnd: true,
          },
          {
            id: "fqe_prio",
            type: "number",
            label: "_LBL_FAQ_ADMIN_DETAILS_PRIORITAET_",
            maxlength: 200,
            showLabel: true,
            view: "v",
            required: true,
          },
          {
            id: "rubrik",
            type: "select",
            label: "_LBL_FAQ_ADMIN_DETAILS_RUBRICK_",
            url: "faqrubriken/",
            showLabel: true,
            view: "v-alt",
            keyId: "pk",
            keyLabel: "fqr_kbez",
            required: true,
            search: true,
            searchList: ["fqr_kbez"],
          },
          {
            id: "schlagworte",
            type: "multiselect",
            label: "_LBL_FAQ_ADMIN_DETAILS_SCHLAGWORTE_",
            showLabel: true,
            view: "v",
            data: this.keywordsData,
            keyId: "pk",
            keyLabel: "sw_kbez",
            search: true,
            searchList: ["sw_kbez"],
            keyGroup: "gruppen_bez",
          },
          {
            id: "medieneintraege",
            type: "selectordered",
            label: "_LBL_FAQ_ADMIN_DETAILS_MEDIENEINTRAEGE_",
            showLabel: true,
            view: "v-alt",
            url: "medieneintrag/",
            keyId: "pk",
            keyLabel: "kme_kbez",
            search: true,
            searchList: ["kme_kbez"],
          },
          {
            id: "fqe_oeffentlich",
            type: "boolean",
            label: "_LBL_FAQ_ADMIN_DETAILS_OEFFENTLICH_",
            showLabel: true,
            view: "v-alt",
          },
        ],
        required: true,
        saveCallback: this.save,
        openCallback: this.open,
      };
    },

    baseUrl() {
      return `faqs/${ this.faq.pk }/`;
    },
  },
  created() {
    this.initEventBus();
    this.loadKeywords();
  },
  beforeUnmount() {
    this.destroyEventBus();
  },
  methods: {
    initEventBus() {
      EventBus.$on("updateNoCacheImages", this.updateNoCacheImages);
    },

    loadKeywords() {
      this.getListHttp({
        url: "schlagworte/?for_model=faq.faqeintrag",
      }).then(
        response => {
          this.keywordsData = response;
        }
      );
    },

    destroyEventBus() {
      EventBus.$off("updateNoCacheImages", this.updateNoCacheImages);
    },

    updateNoCacheImages({ pk, src, suffix }) {
      const NO_CACHE_OBJ = cloneDeep(this.noCacheObj);
      NO_CACHE_OBJ[pk] = {
        suffix,
        src,
      };
      this.noCacheObj = NO_CACHE_OBJ;
    },

    save({ model }) {
      const DATA = this.replaceSrcForImagesInModel({ model });
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.baseUrl,
          data: DATA,
        }).then(
          response => {
            this.updateFaq({ faq: response });
            this.addNotification({
              text: "_MSG_FAQ_ADMIN_DETAILS_UPDATE_{{frage}}_",
              extra: {
                frage: this.faq.fqe_frage,
              },
            });
            setTimeout(() => {
              setSrcWithNoCacheForAllImagesInPage({ noCacheObj: this.noCacheObj });
            });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },

    replaceSrcForImagesInModel({ model }) {
      if (!size(this.noCacheObj)) {
        return model;
      }
      const MODEL = cloneDeep(model);
      MODEL.fqe_antwort = setModelWithoutNoCache({
        model: MODEL.fqe_antwort,
        noCacheObj: this.noCacheObj,
      });
      return MODEL;
    },

    open() {
      const MODEL = cloneDeep(this.faq);
      MODEL.fqe_antwort = setModelWithNoCache({
        model: MODEL.fqe_antwort,
        noCacheObj: this.noCacheObj,
      });
      return { model: MODEL };
    },
  },
};
