"use strict";

import BaseReadonlyController from "dias/ui/formsteps/formstepBaseReadonly.controller";

class FoerderregelUserdefaultReadonlyController extends BaseReadonlyController {
  /*@ngInject*/
  constructor($scope) {
    super($scope);
    this.showUserdefault = false;
  }

  toggleUserdefault() {
    this.showUserdefault = !this.showUserdefault;
  }
}

export default FoerderregelUserdefaultReadonlyController;
