"use strict";

import template from "./template.jade";

class PersonalkostenrechnerController {
  /*@ngInject*/
  constructor($scope, Personalkostentyp, SyConfigs, Personalkostentabelle, Personalstelle, i18n, Client, AuthService) {
    this.gettext = i18n.gettext;
    this.AuthService = AuthService;
    this.Personalkostentyp = Personalkostentyp;
    this.Personalkostentabelle = Personalkostentabelle;
    this.Personalstelle = Personalstelle;
    this.Client = Client;
    this.SyConfigs = SyConfigs;
    this.personalConfig = this.SyConfigs.wert("personalkosten");
    this.personalfunktionen = [];
    if (!this.kontext) {
      this.kontext = "antrag";
    }
    this.$scope = $scope;
    this._init_values();
  }

  asPruefer() {
    return this.AuthService.syncHasPerm("vertragskostenpositionsbelege.anerkennen", this.posmodel.user_permissions);
  }

  get_pkt() {
    // Wenn das Objekt (Antragskostenpos oder Beleg zu Vertragskostenpos)
    // bereits existiert, sollte die PKT_ID bereits da sein,
    // da sie beim Anlegen mit abgelegt wird:
    let pkt_id = null;
    if (this.posmodel.argument) {
      pkt_id = this.posmodel.argument.pkt_id;
    }
    // Wenn das Objekt neu ist, dann kommt die PKT-ID bei Belegen aus den Daten
    // der Antragskosten:
    if (!pkt_id && this.extras.argument && this.extras.argument.pkt_id) {
      pkt_id = this.extras.argument.pkt_id;
    }
    if (pkt_id) {
      return this.Personalkostentabelle.one(pkt_id).get();
    }
      // Wenn die Personalkostentabelle bis jetzt nicht ermittelt werden konnte
      // z.B. bei neuen Antragskosten oder bei neuen Belegen zu neuen
      // Vertragspositionen, dann muss die für den Antrag gültigte Pkt
      // ermittelt werden:
    const antragId = this.extras.antragsregel.afr_aid;
    const personalkostentypId = this.extras.antragsregel.afr_argument.personalkostentyp.wert;
    return this.Personalkostentyp.one(personalkostentypId).customGET("get_gueltige_pkt", { a_id: antragId });
  }

  _init_values() {
    this.get_pkt().then(result => {
      this.personalkostentabelle = result;
      this.personalfunktionen = this.personalkostentabelle.personalfunktionen;
      if (!this.posmodel.argument) {
        this.posmodel.argument = {};
      }

      if (!this.posmodel.pk && this.extras.akpargument) {
        if (this.extras.akpargument.stellenbezeichnung) {
          this.posmodel.argument.name = this.extras.akpargument.stellenbezeichnung;
        }
        if (this.extras.akpargument.vollzeitstelle) {
          this.posmodel.argument.vollzeitstelle = this.extras.akpargument.vollzeitstelle;
        }
        if (this.extras.akpargument.stellenumfang) {
          this.posmodel.argument.stellenumfang = this.extras.akpargument.stellenumfang;
        }
        if (this.extras.akpargument.stellenumfang_projekt) {
          this.posmodel.argument.stellenumfang_projekt = this.extras.akpargument.stellenumfang_projekt;
        }
      }

      this.posmodel.argument.pkt_id = this.personalkostentabelle.pkt_id;
      this.extras.pkt_id = this.personalkostentabelle.pkt_id;
      // Die Erfassung der Personalstelle (z.B. aufgestockte Personalstelle)
      // ist nur im Vertragskontext erforderlich:
      if (this.kontext === "beleg" || this.Client === "dias-sdb") {
        this.Personalstelle.getList().then(
          result => {
            this.personalstellen = result;
          }
        );
      }
    });
  }
}

export default {
  template: template(),
  controller: PersonalkostenrechnerController,
  controllerAs: "ctx",
  bindings: {
    rechner: "=",
    posmodel: "=",
    errors: "=",
    extras: "=",
    kontext: "=?",
    vorschau: "=",
    prefix: "<?"
  },
};
