import PuxTranslate from "../../../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FiltersAPI from "../../../../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "AntragsuebersichtTable",
  components: {
    PuxTranslate,
  },
  props: {
    handlungsfelder: {
      type: Array,
      default: () => [],
    },
    filteredAntragListUrl: {
      type: Object,
      default: () => ({}),
    },
    gesamt: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      filterCurrency,
    };
  },
};
