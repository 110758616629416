"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import diasLoading from "dias/core/loading";

import cortextAuszahlungslisteObjektliste from "./cortext_auszahlungsliste_objektliste";

const modul = angular.module("dias.vertrag.cortexe.auszahlungslisten.foerdermodule", [
  uiRouter,
  diasLoading.name,
  cortextAuszahlungslisteObjektliste.name
])
;

export default modul;
