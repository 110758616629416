"use strict";

import BaseModel from "dias/core/models/baseModel";

class Foerderanzeigekontext extends BaseModel {
  constructor() {
    super("foerderanzeigekontexte");
  }
}

export default Foerderanzeigekontext;
