import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxErrors from "../../../../global/components/PuxErrors/PuxErrors.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

// @vue/component
export default {
  name: "ProfilDetailsPassword",
  components: {
    FormElement,
    FormstepDetail,
    PuxAlert,
    PuxErrors,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    SyConfigMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {
        password_old: undefined,
        password_new1: undefined,
        password_new2: undefined,
      },
      options: {
        label: "_TXT_PROFIL_DETAILS_PASSWORD_FORMSTEP_NAME_",
        required: true,
        saveCallback: this.save,
        closeCallback: this.close,
      },
      optionsSave: {
        password_old: {
          type: "password",
          id: "password_old",
          view: "v-alt",
          label: "_LBL_PROFIL_DETAILS_PASSWORD_OLD_",
          autoFocus: true,
          required: true,
          translate: true,
        },
        password_new1: {
          type: "password",
          id: "password_new1",
          view: "v-alt",
          label: "_LBL_PROFIL_DETAILS_PASSWORD_NEW1_",
          required: true,
          translate: true,
        },
        password_new2: {
          type: "password",
          id: "password_new2",
          view: "v-alt",
          label: "_LBL_PROFIL_DETAILS_PASSWORD_NEW2_",
          required: true,
          translate: true,
        },
      },
      errors: undefined,
      alertLabels: {
        password_old: "_LBL_PROFIL_DETAILS_PASSWORD_OLD_",
        password_new1: "_LBL_PROFIL_DETAILS_PASSWORD_NEW1_",
        password_new2: "_LBL_PROFIL_DETAILS_PASSWORD_NEW2_",
      },
      passwordConfig: {},
    };
  },
  computed: {
    helpTextTranslate() {
      return {
        html: "_HTML_PROFILE_DETAILS_PASSWORD_HELPTEXT_{{min}}_{{max}}_{{allowed}}_",
        extra: {
          min: this.passwordConfig.min_length,
          max: this.passwordConfig.max_length,
          allowed: this.passwordConfig.allowed_chars,
        }
      };
    },

    optionsListForPuxErrors() {
      return [
        this.optionsSave.password_old,
        this.optionsSave.password_new1,
        this.optionsSave.password_new2,
      ];
    },
  },
  created() {
    this.initConfig();
  },
  methods: {
    initConfig() {
      this.passwordConfig = this.getSyConfigsValue("password_rules", {});
    },

    save() {
      return new Promise((resolve, reject) => {
        this.postHttp({
          url: `profil/${ this.nutzer.pk }/passwort_aendern/`,
          data: this.model,
        }).then(
          () => {
            this.addNotification({ text: "_MSG_PROFIL_DETAILS_PASSWORD_CHANGE_SUCCESS_" });
            this.setDefaultModel();
            resolve();
          },
          error => {
            this.errors = error.data;
            reject();
          }
        );
      });
    },
    close() {
      this.resetErrors();
      this.setDefaultModel();
    },

    resetErrors() {
      this.errors = undefined;
    },

    setDefaultModel() {
      this.model = {
        password_old: undefined,
        password_new1: undefined,
        password_new2: undefined,
      };
    },
  },
};
