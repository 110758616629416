import AnlagenAllgemeinDocuments from "./AnlagenAllgemeinDocuments/AnlagenAllgemeinDocuments.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";

import ModuleMixin from "../ModuleMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import {
  forEach,
  sortBy,
  keyBy,
  cloneDeep,
  get,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "AnlagenAllgemein",
  components: {
    AnlagenAllgemeinDocuments,
    Permission,
    PuxCloak,
  },
  mixins: [
    ModuleMixin,
    PermissionMixin,
  ],
  props: {
    dokumentePermissionsFromObj: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      dokumentePermissions: {
        view: "dokument.view",
        create: "dokument.create",
        delete: "dokument.delete",
        update: "dokument.update",
        update_gueltigkeit: "dokument.update_gueltigkeit",
      },
      modelEditDokart: {},
      dokumentartenObj: {},
      dokumentartenPkList: {},
      dokumentartenAll: [],
      dokumentartenAllByPk: {},
      dokumentartenObjForKey: {}, // Z.B. { "dokart-UUID": "optional" } für snapshot
      documentPruefStatuses: [],
      statusDokartLoading: true,
      isDiffMap: {},
      colsForTable: [
        {
          key: "dokument.dokumentart.name",
          key2: "dokument.dokumentart",
          keySort: "dokument.dokumentart.name",
          keySnapshot: "dokument.dokart_id",
          label: "_LBL_GR_DOCUMENTS_DOKUMENTTYP_",
        },
        {
          key: "dokument.dok_titel",
          keySort: "dokument.dok_titel",
          label: "_LBL_GR_DOCUMENTS_TITEL_",
        },
        {
          key: "dokument.crdate",
          keySort: "dokument.crdate",
          filter: "date",
          filterParameter: "DD.MM.YYYY HH:mm:ss",
          label: "_LBL_GR_DOCUMENTS_ANGEFUEGT_AM_",
        },
        {
          key: "pruefstatus.status",
          keySort: "pruefstatus.status",
          keySnapshot: "pruefstatus.pk",
          label: "_LBL_GR_DOCUMENTS_STATUS_",
        },
        {
          key: "sync",
          keySort: "is_sync",
          label: "_LBL_GR_DOCUMENTS_SYNC_",
        },
      ],
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.hideHeader = this.extraStatic.hideHeader;
      return OPTIONS;
    },

    dokumenteBaseUrlExp() {
      if (this.modelParameter.antragsteller && this.obj.antragstellerorg) {
        return `antragstellerorganisationen/${ this.obj.antragstellerorg }/`;
      }
      return this.dokumenteBaseUrl || this.baseUrl;
    },

    defaultSort() {
      return this.modelParameter.default_sort || this.colsForTableFiltered[1].keySort;
    },

    dokumentePermissionsLocal() {
      return this.dokumentePermissionsFromObj || this.dokumentePermissions;
    },

    user_permissions() {
      return this.obj && this.obj.user_permissions ? this.obj.user_permissions : [];
    },

    labelHeader() {
      return this.modelParameter.frage;
    },

    headerName() {
      return {
        required: this.headerNameRequired,
        optional: this.headerNameOptional,
        all: this.headerNameAll,
      };
    },

    headerNameRequired() {
      return this.modelParameter.header_required;
    },

    headerNameOptional() {
      return this.modelParameter.header_optional;
    },

    headerNameAll() {
      return this.modelParameter.header_all || "";
    },

    headerInfo() {
      return {
        required: this.headerInfoRequired,
        optional: this.headerInfoOptional,
        all: "",
      };
    },

    headerInfoRequired() {
      return this.modelParameter.header_required_info;
    },

    headerInfoOptional() {
      return this.modelParameter.header_optional_info;
    },

    anzeigeTableFieldsDict() {
      return keyBy(this.modelParameter.anzeige_details, e => {
        return e;
      });
    },

    colsForTableFiltered() {
      const COLS_FOR_TABLE = [];
      forEach(this.colsForTable, item => {
        if (this.anzeigeTableFieldsDict[item.key]) {
          COLS_FOR_TABLE.push(item);
        }
      });
      return COLS_FOR_TABLE;
    },

    doksReadonly() {
      return this.readonly || this.modelParameter.readonly;
    },

    canCreateDoks() {
      return !this.doksReadonly && get(this.regel, "rechte.create", true);
    },

    canDeleteDoks() {
      return !this.doksReadonly && get(this.regel, "rechte.delete", true);
    },

    canUpdateDoks() {
      return !this.doksReadonly && get(this.regel, "rechte.update", true);
    },

    snapshotKeys() {
      const SNAPSHOT_KEYS = cloneDeep(this.modelParameter.anzeige_details) || [];
      const INDEX_PRUEFSTATUS = SNAPSHOT_KEYS.indexOf("pruefstatus.status");
      const INDEX_DOKART = SNAPSHOT_KEYS.indexOf("dokument.dokumentart.name");
      if (INDEX_PRUEFSTATUS !== -1) {
        SNAPSHOT_KEYS.splice(INDEX_PRUEFSTATUS, 1, "pruefstatus.pk");
      }
      if (INDEX_DOKART !== -1) {
        SNAPSHOT_KEYS.splice(INDEX_DOKART, 1, "dokument.dokart_id");
      }
      return SNAPSHOT_KEYS;
    },

    snapshotDokumenteAll() {
      if (!this.statusSnapshot) {
        return;
      }
      const SNAPSHOT_ALL = cloneDeep(this.extraStatic.snapshotAll) || cloneDeep(this.snapshot);
      const SNAPSHOT = SNAPSHOT_ALL.asn_snapshot || SNAPSHOT_ALL.ats_snapshot || SNAPSHOT_ALL.osn_snapshot;
      if (SNAPSHOT && // Für Antrag
        SNAPSHOT.anlagen &&
        SNAPSHOT.anlagen.dokumente &&
        SNAPSHOT.anlagen.dokumente.length) {
        return SNAPSHOT.anlagen.dokumente;
      }
      if (SNAPSHOT && // Für Antragsteller
        SNAPSHOT.dokumente &&
        SNAPSHOT.dokumente.length) {
        return SNAPSHOT.dokumente;
      }
      return undefined;
    },

    snapshotDokumente() { // required, optional, all
      const DOCUMENTS = {
        required: {
          list: [],
          obj: {},
        },
        optional: {
          list: [],
          obj: {},
        },
        all: {
          list: [],
          obj: {},
        },
      };
      if (this.snapshotDokumenteAll) {
        forEach(this.snapshotDokumenteAll, document => {
          const CURRENT_KEY = this.dokumentartenObjForKey[document.dokument.dokart_id];
          if (CURRENT_KEY) {
            DOCUMENTS[CURRENT_KEY].list.push(document);
            DOCUMENTS[CURRENT_KEY].obj[document.dokument.pk] = document;
          }
        });
      }
      return DOCUMENTS;
    },

    statusDiff() {
      let statusDiff = false;
      if (this.statusSnapshot) {
        forEach(this.isDiffMap, item => {
          if (item) {
            statusDiff = true;
            return false;
          }
        });
      }
      return statusDiff;
    },

    isModuleEditable() {
      return false;
    },
  },
  created() {
    this.initPage();
  },
  methods: {
    initPage() {
      if (!this.checkPermissionsSync({
        perm: this.dokumentePermissionsLocal.view,
        permArray: this.user_permissions
      })) {
        return;
      }
      this.loadDokumentarten();
      this.loadDokumentPruefStatuses();
    },

    loadDokumentarten() {
      this.getListHttp({
        url: "dokumentarten/",
        urlParams: {
          anzeige: this.modelParameter.anzeigeart,
        },
      }).then(
        response => {
          if (isFunction(this.extraStatic.onLoadDokumentarten)) {
            this.extraStatic.onLoadDokumentarten(response);
          }
          this.groupDokumentartenByPflicht({ response });
          this.statusDokartLoading = false;
        },
      );
    },

    groupDokumentartenByPflicht({ response }) {
      const DOKUMENTARTEN_SORTED = sortBy(response, ["position"]);
      const DOKUMENTARTEN_OBJ = {
        required: [],
        optional: [],
        all: [],
      };
      const DOKUMENTARTEN_PK_LIST = {};
      const DOKUMENTARTEN_ALL = [];
      const DOKUMENTARTEN_OBJ_FOR_KEY = {};
      if (!this.modelParameter.pflichtfeld) { // Eine Tabelle
        DOKUMENTARTEN_PK_LIST.all = [];
        forEach(DOKUMENTARTEN_SORTED, item => {
          const DOKUMENTART = item.dokumentart || {};
          DOKUMENTART.dokartInfo = item.astbez;
          DOKUMENTARTEN_OBJ.all.push(DOKUMENTART);
          DOKUMENTARTEN_PK_LIST.all.push(item.dokumentart.pk);
          DOKUMENTARTEN_ALL.push(DOKUMENTART);
          DOKUMENTARTEN_OBJ_FOR_KEY[item.dokumentart.pk] = "all";
        });
      } else { // Zwei Tabelle (required und optional)
        forEach(DOKUMENTARTEN_SORTED, item => {
          const DOKUMENTART = item.dokumentart || {};
          DOKUMENTART.dokartInfo = item.astbez;
          if (item.dokumentart && item.dokumentart.pflicht) {
            DOKUMENTARTEN_PK_LIST.required = DOKUMENTARTEN_PK_LIST.required || [];
            DOKUMENTARTEN_OBJ.required.push(DOKUMENTART);
            DOKUMENTARTEN_PK_LIST.required.push(item.dokumentart.pk);
            DOKUMENTARTEN_OBJ_FOR_KEY[item.dokumentart.pk] = "required";
          } else {
            DOKUMENTARTEN_PK_LIST.optional = DOKUMENTARTEN_PK_LIST.optional || [];
            DOKUMENTARTEN_OBJ.optional.push(DOKUMENTART);
            DOKUMENTARTEN_PK_LIST.optional.push(item.dokumentart.pk);
            DOKUMENTARTEN_OBJ_FOR_KEY[item.dokumentart.pk] = "optional";
          }
          DOKUMENTARTEN_ALL.push(DOKUMENTART);
        });
      }

      forEach(DOKUMENTARTEN_OBJ, (list, key) => {
        if (!list.length) {
          delete DOKUMENTARTEN_OBJ[key];
        }
      });

      this.dokumentartenObj = DOKUMENTARTEN_OBJ;
      this.dokumentartenPkList = DOKUMENTARTEN_PK_LIST;
      this.dokumentartenAll = DOKUMENTARTEN_ALL;
      this.dokumentartenObjForKey = DOKUMENTARTEN_OBJ_FOR_KEY;
      this.dokumentartenAllByPk = keyBy(DOKUMENTARTEN_ALL, "pk");
    },


    loadDokumentPruefStatuses() {
      this.getListHttp({
        url: `dokumentpruefstatus/`,
        apiSaveId: "dokumentpruefstatus",
      }).then(
        response => {
          this.documentPruefStatuses = response;
        }
      );
    },

    changeDiffFromChild({ isDiff, keyRequired }) {
      this.isDiffMap[keyRequired] = isDiff;
    },
  },
};
