import KFPeriodenPosition from "../KFPeriodenPosition/KFPeriodenPosition.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import Alert from "../../../../global/components/Alert/Alert.vue";
import PuxTooltip from "../../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import loading from "../../../../global/directives/loading";
import translate from "../../../../global/directives/translate";
import {
  FilterCurrencyMixin,
  FilterDateMixin,
} from "../../../../global/mixins/FiltersMixin";

import {
  forEach,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "KFPeriodenModulStandard",
  components: {
    Alert,
    KFPeriodenPosition,
    PuxCloak,
    SnapshotIcon,
    PuxButton,
    PuxTooltip,
    PuxIcon,
  },
  directives: {
    loading,
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterDateMixin,
  ],
  props: {
    htmlId: {
      type: String,
      required: true,
      info: "HTML-ID",
    },
    perioden: {
      type: Array,
      required: true,
      info: "Perioden-Liste",
    },
    positionen: {
      type: Array,
      required: true,
      info: "Positionen-Liste",
    },
    periodKeyShow: {
      type: String,
      required: true,
      info: "Welche Perioden-Teil werden angezeigt",
    },
    idx: {
      type: String,
      required: true,
      info: "Nummer von KoFi-Art",
    },
    sum: {
      type: String,
      required: true,
      info: "KoFi-Art Summe",
    },
    typeKoFi: {
      type: String,
      required: true,
      validator: value => ["kosten", "finanzierung"].indexOf(value) !== -1,
      info: "Kosten oder Finanzierung",
    },
    modelWithPositionenForPeriode: {
      type: Object,
      required: true,
      info: "Geld für Modul und Positionen mit Perioden",
    },
    antragKoFiArt: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      info: "Status-Loading",
    },
    reloading: {
      type: Boolean,
      required: false,
      info: "Status-Reloading",
    },
    modelWithPositionenForPeriodeSnapshot: {
      type: Object,
      required: false,
      default: () => ({}),
      info: "Geld für Modul und Positionen mit Perioden (Snapshot)",
    },
    diff: {
      type: Object,
      required: true,
      info: "Diff für Snapshot",
    },
    statusKFDiff: {
      type: Boolean,
      required: false,
      info: "StatusDiff from parent",
    },
    statusDiff: {
      type: Boolean,
      required: false,
      info: "StatusDiff from Modul",
    },
    screenreaderLabel: {
      type: String,
      required: true,
      info: "Text für screen-reader",
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
      info: "Snapshot-Datum",
    },
    infoText: {
      type: String,
      required: false,
      default: undefined,
      info: "Info-I Text",
    },
    zuschussMeldungen: {
      type: Array,
      required: false,
      default: undefined,
      info: "Zuschuss-Meldungen",
    },
    kontext: {
      type: String,
      required: true,
      validator: value => ["antrag", "zuschuss"].indexOf(value) !== -1,
      info: "Kontext",
    },
    statusZuschussValid: {
      type: Boolean,
      required: false,
      info: "Wenn Zuschuss gültig ist",
    },
    infoprovider: {
      type: Object,
      required: false,
      default: undefined,
    },
    regelAfrId: {
      type: String,
      required: true,
    },
    statusShowProzente: {
      type: Boolean,
      required: false,
    },
    prozent: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      statusInfo: false,
      statusInfoButton: false,
    };
  },
  computed: {
    labelLocal() {
      return this.antragKoFiArt.bez;
    },

    snapshotPositionen() {
      return this.modelWithPositionenForPeriodeSnapshot.positionen || {};
    },

    snapshotDateWithFilter() {
      return this.filterDate(this.snapshotDate, "DD.MM.YYYY HH:mm");
    },

    buttonInfoId() {
      return `${ this.htmlId }_info`;
    },

    blockInfoId() {
      return `${ this.htmlId }_info_block`;
    },

    buttonInfoCloseId() {
      return `${ this.htmlId }_info_close`;
    },

    buttonInfoTooltip() {
      return this.statusInfoButton ?
        "_TXT_FORMSTEP_DETAILS_INFO_I_TITLE_AUS_" :
        "_TXT_FORMSTEP_DETAILS_INFO_I_TITLE_EIN_";
    },

    rowspan() {
      let rowspan = this.positionen.length + 1;
      if (this.statusInfo) {
        rowspan++;
      }
      if (this.statusKFDiff) {
        rowspan += this.diff.delete.length;
      }
      if (this.statusShowMeldungen) {
        rowspan++;
      }
      return rowspan;
    },

    statusShowMeldungen() {
      return !!(this.zuschussMeldungen && this.zuschussMeldungen.length && this.kontext === "zuschuss");
    },

    periodenProzent() {
      const PERIODEN_PROZENT = {};
      if (this.statusShowProzente) {
        forEach(this.perioden, period => {
          const PERIOD_PK = period.pk;
          const PROZENT = get(this.infoprovider, `perioden.${ PERIOD_PK }.details.${ this.regelAfrId }.prozent`);
          if (PROZENT) {
            PERIODEN_PROZENT[period.pk] = this.filterCurrency(PROZENT, "%");
          }
        });
      }
      return PERIODEN_PROZENT;
    },
  },
  methods: {
    toggleStatusInfo() {
      if (this.statusInfoButton) {
        this.statusInfo = false;
        this.statusInfoButton = false;
      } else {
        this.statusInfo = true;
        setTimeout(() => {
          if (this.$refs.alert_info && this.$refs.alert_info.$el) {
            this.$refs.alert_info.$el.focus();
          }
          setTimeout(() => {
            this.statusInfoButton = true;
          });
        });
      }
    },

    closeInfoBlock() {
      this.statusInfo = false;
      setTimeout(() => {
        if (this.$refs.button_info && this.$refs.button_info.$el) {
          this.$refs.button_info.$el.focus();
        }
        this.statusInfoButton = false;
      });
    },
  },
};
