import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";
import {
  FilterDefaultForEmptyMixin,
} from "../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "ProfilDetailsName",
  components: {
    FormstepDetail,
    PuxTranslate,
  },
  mixins: [
    FilterDefaultForEmptyMixin,
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
    updateNutzer: {
      type: Function,
      required: true,
    },
    isTitelSelect: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    optionsForFormstepDetail() {
      return {
        label: "_TXT_PROFIL_DETAILS_NAME_FORMSTEP_NAME_",
        required: true,
        saveCallback: this.save,
        list: this.listForFormstepDetail,
      };
    },

    listForFormstepDetail() {
      const LIST = [
        {
          id: "n_ankid",
          label: "_LBL_PROFIL_DETAILS_NAME_ANREDE_",
          showLabel: true,
          type: "select",
          required: true,
          view: "v",
          keyId: "id",
          keyLabel: "ank_kbez",
          autoFocus: true,
          url: "katalog/?fields=id&fields=ank_kbez&key=kanrede",
          apiSaveId: "kanrede",
          editonly: true,
        },
        {
          id: "n_ktiid",
          label: "_LBL_PROFIL_DETAILS_NAME_TITEL_",
          showLabel: true,
          type: "select",
          required: false,
          view: "v",
          keyId: "id",
          keyLabel: "kti_kbez",
          url: "katalog/?fields=id&fields=kti_kbez&key=titel",
          apiSaveId: "titel",
          editonly: true,
          deselect: true,
        },
        {
          id: "n_titel",
          label: "_LBL_PROFIL_DETAILS_NAME_TITEL_",
          showLabel: true,
          type: "text",
          required: false,
          view: "v",
          editonly: true,
          autocomplete: "honorific-prefix",
        },
        {
          id: "n_vorname",
          label: "_LBL_PROFIL_DETAILS_NAME_VORNAME_",
          showLabel: true,
          type: "text",
          required: true,
          view: "v",
          editonly: true,
          autocomplete: "given-name",
        },
        {
          id: "n_nachname",
          label: "_LBL_PROFIL_DETAILS_NAME_NACHNAME_",
          showLabel: true,
          type: "text",
          required: true,
          view: "v",
          editonly: true,
          autocomplete: "family-name",
        },
      ];
      if (this.isTitelSelect) {
        LIST.splice(2, 1);
      } else {
        LIST.splice(1, 1);
      }
      return LIST;
    },

    nutzerTitel() {
      return this.filterDefaultForEmpty(this.isTitelSelect ?
        this.nutzer.titel :
        this.nutzer.n_titel);
    },

    urlSave() {
      return `profil/${ this.nutzer.pk }/aendern/`;
    },

    nutzerAnrede() {
      return this.filterDefaultForEmpty(this.nutzer.anrede);
    },

    nutzerVorname() {
      return this.filterDefaultForEmpty(this.nutzer.n_vorname);
    },

    nutzerNachname() {
      return this.filterDefaultForEmpty(this.nutzer.n_nachname);
    },
  },
  methods: {
    save({ model }) {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.urlSave,
          data: model,
        }).then(
          response => {
            this.updateNutzer({
              nutzer: response,
            });
            this.addNotification({
              text: "_MSG_PROFIL_DETAILS_UPDATE_SUCCESS_",
            });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },
  },
};
