"use strict";

import template from "./overview.jade";
import ansprechpartnerZuweisenTemplate from "../../../foerderantrag/client/foerderantrag/ansprechpartner.zuweisen.modal.jade";
import ansprechpartnerZuweisenController from "../../../foerderantrag/client/foerderantrag/ansprechpartner.zuweisen.modal.controller";
import changeTeamTemplate from "../../../foerderantrag/client/foerderantrag/team.change.modal.jade";
import changeTeamController from "../../../foerderantrag/client/foerderantrag/team.change.modal.controller";


class VertragOverviewController {
  /*@ngInject*/
  constructor(
    $filter,
    Vertrag,
    i18n,
    Foerderantrag,
    KommunikationsdatenUUIDs,
    diasConfirmDialog,
    DiasNotification,
    diasModalDialog,
    SyConfigs,
    Client,
  ) {
    this.KommunikationsdatenUUIDs = KommunikationsdatenUUIDs;
    this.diasConfirmDialog = diasConfirmDialog;
    this.DiasNotification = DiasNotification;
    this.diasModalDialog = diasModalDialog;
    this.Foerderantrag = Foerderantrag;
    this.filter = $filter;
    this.gettext = i18n.gettext;
    this.SyConfigs = SyConfigs;
    this.allBetrag = 0;
    this.Client = Client;
    this.showFristen = this.SyConfigs.wert("v_termine_in_uebersicht");
  }

  loadAuszahlungen() {
    this.vertrag.auszahlungen.getList().then(
      response => {
        this.auszahlungen = response;
      }
    );
  }

  getAllBetrag() {
    let allBetrag = 0;
    angular.forEach(this.auszahlungen, item => {
      if (item.az_datum_ausgezahlt) {
        allBetrag += +item.az_betrag;
      }
    });
    return allBetrag;
  }

  assignAnsprechpartnerMe(asp) {
    this.diasConfirmDialog({
      title: "Ansprechpartner mir zuweisen",
      content: "Wollen Sie Ansprechpartner des Antrags für die Organisation \"" + asp.org.o_name + "\" werden?",
      okCallback: () => {
        return this.Foerderantrag.one(this.vertrag.antrag_id).customPOST(
          { foerderorg: asp.org.pk, nutzer: this.user.pk },
          "ansprechpartner_aendern"
        ).then(
          response => {
            this.DiasNotification.page.success("Ansprechpartner zugewiesen.");
            this.vertrag.foerderorg_ansprechpartner = response.foerderorg_ansprechpartner;
          },
          () => this.DiasNotification.page.error("Fehler beim Zuweisen des Antragstellers.")
        );
      }
    });
  }

  assignAnsprechpartner(asp) {
    return this.diasModalDialog({
      title: "Ansprechpartner zuweisen",
      template: ansprechpartnerZuweisenTemplate,
      controller: ansprechpartnerZuweisenController,
      extras: {
        antrag_id: this.vertrag.antrag_id,
        vertrag_id: this.vertrag.pk,
        vertrag: this.vertrag,
        ansprechpartner: asp,
      },
    });
  }

  changeTeam() {
    return this.diasModalDialog({
      title: "Team ändern",
      template: changeTeamTemplate,
      controller: changeTeamController,
      extras: {
        antrag_id: this.vertrag.antrag_id,
        vertrag_id: this.vertrag.pk,
        vertrag: this.vertrag,
      },
    });
  }

  showHours(termin) {
    return Math.ceil(termin.duration / 3600) % 24 !== 0 && termin.duration / 3600 / 24 < 1;
  }
}

export default {
  template: template(),
  controller: VertragOverviewController,
  controllerAs: "vm",
  bindings: {
    vertrag: "=",
    auszahlungen: "<?",
    canViewBelege: "<?",
    user: "<",
  }
};
