import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import FormElement from "../../../global/components/FormElement/FormElement.vue";
import Spinner from "../../../global/components/Spinner/Spinner.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import filterList from "./filterList";
import {
  cloneDeep,
  findIndex,
} from "lodash-es";

// @vue/component
export default {
  name: "CalendarFilters",
  components: {
    PuxButton,
    FormElement,
    Spinner,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    loadingSearch: {
      type: Boolean,
      required: true,
    },
    search: {
      type: Function,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    filteredCalendarObjectTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filterList: undefined,
    };
  },
  created() {
    this.initFilterList();
  },
  methods: {
    initFilterList() {
      let filterListClone = cloneDeep(filterList);
      const OBJEKT_TYP_INDEX = findIndex(filterListClone, ["id", "objekt_typ"]);
      filterListClone[OBJEKT_TYP_INDEX].data = this.filteredCalendarObjectTypes;
      filterListClone = this.initChangeFunctionsForCheckboxes({ list: filterListClone, objectTypeIndex: OBJEKT_TYP_INDEX });
      this.filterList = filterListClone;
    },

    initChangeFunctionsForCheckboxes({ list, objectTypeIndex }) {
      const TYP_INDEX = findIndex(list, ["id", "typ"]);
      const PRIVATE_INDEX = findIndex(list, ["id", "private"]);
      list[objectTypeIndex].change = this.onSearch;
      list[TYP_INDEX].change = this.onSearch;
      list[PRIVATE_INDEX].change = this.onSearch;
      return list;
    },

    onSearch() {
      if (this.loadingSearch) {
        return;
      }
      this.search();
    },
  },
};
