"use strict";
import angular from "angular";

import models from "../models";
import services from "../services";
import detailComponent from "./detail.component";

const modul = angular.module("dias.workflows.clientfunktionen", [
  models.name,
  services.name,
]);

modul.config($stateProvider => {
  $stateProvider

  // Liste der KAufgaben
    .state("root.workflowfolgeclientfunktionen", {
      url: `/workflowfolgeclientfunktionen/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-workflowfolgeclientfunktionen-list></vue-workflowfolgeclientfunktionen-list></div>"
        }
      },
      data: {
        permissions: "clientfunktion.ui"
      }
    })

    .state("root.workflowfolgeclientfunktionen1", {
      url: `/workflowfolgeclientfunktionen1/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-workflowfolgeclientfunktionen-list></vue-workflowfolgeclientfunktionen-list></div>"
        }
      },
      data: {
        permissions: "clientfunktion.ui"
      }
    })

    .state("root.workflowfolgeclientfunktionen1.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<workflowfolgeclientfunktionen-detail></workflowfolgeclientfunktionen-detail>"
        }
      },
    })

    .state("root.workflowfolgeclientfunktionen.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><workflowfolgeclientfunktionen-details></workflowfolgeclientfunktionen-details></div>"
        }
      },
    })
  ;
})

  // .component("kaufgabeList", listComponent)
  .component("workflowfolgeclientfunktionenDetail", detailComponent)

;

export default modul;
