"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";


class AstorgEditBeschreibungController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, i18n) {
    super($q, $scope, DiasNotification);
    this.extra = this.$scope.extra;
    this.gettext = i18n.gettext;
    this.onEnterEditMode = this.onEnterEditMode.bind(this);
    this.save = () => {
      this.model.typen = Object.keys(this.atSelection).filter(
        key => this.atSelection[key]
      );
      return $scope.model.updateAstorgTypen(this.model.typen).then(
        () => {
          $scope.model.merge($scope.model, this.model);
        },
        // this.onError
        errors => {
          const errorsLabel = {
            typen: `${ this.gettext("_LBL_ANTRAGSTELLER_ANTRAGSTELLERORGTYPEN_TYPEN_") }: `
          };
          return this.onErrors(errors, errorsLabel);
        }
      );
    };
  }


  onEnterEditMode() {
    super.onEnterEditMode();
    this.atSelection = {};
    if (this.$scope.extra.antragstellerorgtypen) {
      this.$scope.extra.antragstellerorgtypen.forEach(
        v => this.atSelection[v.pk] = this.model.typen.indexOf(v.pk) !== -1
      );
    }
  }
}

export default AstorgEditBeschreibungController;
