"use strict";

import angular from "angular";
import i18nServices from "./services";

const i18nModul = angular.module("dias.i18n", [
  i18nServices.name
]);

export default i18nModul;
