import translate from "../../../directives/translate";

export default {
  directives: {
    translate,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    className: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    title_TableCellMixin() {
      return this.options.titleCallback ?
        this.options.titleCallback({ item: this.row }) :
        this.options.title || this.label_TableCellMixin;
    },

    label_TableCellMixin() {
      return this.options.labelCallback ?
        this.options.labelCallback({ item: this.row }) :
        this.options.label;
    },

    extra_TableCellMixin() {
      return this.options.extraCallback ?
        this.options.extraCallback({ item: this.row }) :
        this.options.extra;
    },

    classNameLocal_TableCellMixin() {
      let classLocal = this.className || "";
      if (this.options.className) {
        classLocal = `${ classLocal } ${ this.options.className }`;
      }
      return classLocal;
    },

    optionsTranslate_TableCellMixin() {
      return {
        html: this.label_TableCellMixin,
        title: this.title_TableCellMixin,
        extra: this.extra_TableCellMixin,
      };
    },
  },
};
