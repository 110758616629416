import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  filter,
  forEach,
  get,
  isNil,
  map,
  size,
  sortBy,
  uniq,
} from "lodash-es";

// @vue/component
export default {
  name: "SlotKompetenzen",
  components: {
    PuxTranslate,
  },
  props: {
    personal: {
      type: Object,
      required: true,
    },
  },
  computed: {
    kompetenzen() {
      return filter(get(this.personal, "kompetenzen", []), pk => pk.aktiv);
    },

    kompetenzenUngrouped() {
      const ANGEBOTE = [];
      forEach(this.kompetenzen, pk => {
        if (!isNil(pk.content_type_bez)) {
          return;
        }
        ANGEBOTE.push(...pk.angebote);
      });
      return this.sortedUniqueAngebote(ANGEBOTE);
    },

    kompetenzenGrouped() {
      const GROUPED = {};
      forEach(this.kompetenzen, pk => {
        if (isNil(pk.content_type_bez)) {
          return;
        }
        GROUPED[pk.content_type_bez] = GROUPED[pk.content_type_bez] || [];
        GROUPED[pk.content_type_bez].push(...pk.angebote);
      });
      const GROUPED_SORTED = {};
      forEach(GROUPED, (v, k) => {
        GROUPED_SORTED[k] = this.sortedUniqueAngebote(v);
      });
      return GROUPED_SORTED;
    },

    hasKompetenzenUngrouped() {
      return size(this.kompetenzenUngrouped) > 0;
    },

    hasKompetenzenGrouped() {
      return size(this.kompetenzenGrouped) > 0;
    },

    hasNoKompetenzen() {
      return !this.hasKompetenzenUngrouped && !this.hasKompetenzenGrouped;
    },
  },
  methods: {
    sortedUniqueAngebote(angebote) {
      return sortBy(uniq(map(angebote, foe => foe.foe_kbez)), el => el);
    },
  },
};
