"use strict";

import BaseModel from "dias/core/models/baseModel";


class EmailAendern extends BaseModel {
  constructor() {
    super("auth/email_aendern/");
  }
}

export default EmailAendern;
