// @vue/component
export default {
  data() {
    return {
      fields: {
        personalfunktion: {
          id: "personalfunktion",
          type: "select",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_FUNKTION_IM_PROJEKT_",
          data: this.personalfunktionen,
          required: true,
          keyId: "pk",
          keyLabel: "bez",
          search: true,
          searchList: ["bez"],
          view: "v-alt",
          hideIf: !this.personalfunktionen.length,
          translate: true,
          deselect: false,
          statusChangePeriod: true,
          slot: "personalfunktion",
          tooltip: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_FUNKTION_IM_PROJEKT_INFO_I_",
        },
        stellenbezeichnung: {
          id: "stellenbezeichnung",
          type: "text",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_STELLENBEZEICHNUNG_",
          required: true,
          view: "v-alt",
          translate: true,
          tooltip: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_STELLENBEZEICHNUNG_INFO_I_",
        },
        kostenartdetail: {
          id: "kostenartdetail",
          type: "select",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_TYP_KATEGORIE_",
          required: true,
          data: this.katalogDetails,
          keyId: "pk",
          keyLabel: "bez",
          search: true,
          searchList: ["bez"],
          view: "v-alt",
          hideIf: !this.katalogDetails.length,
          translate: true,
          tooltip: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_TYP_KATEGORIE_INFO_I_",
        },
        anzeige_personalstelle: {
          id: "anzeige_personalstelle",
          type: "select",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_ART_DER_PERSONALSTELLE_",
          required: false,
          data: this.personalstellenart,
          keyId: "pk",
          keyLabel: "bez",
          view: "v-alt",
          hideIf: !this.personalstellenart.length,
          translate: true,
          tooltip: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_ART_DER_PERSONALSTELLE_INFO_I_",
        },
        vollzeitstelle: {
          id: "vollzeitstelle",
          type: "float",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_VOLLZEITSTELLE_",
          required: true,
          addonBack: "Stunden pro Woche",
          view: "v-alt",
          translate: true,
          statusChangePeriod: true,
          tooltip: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_VOLLZEITSTELLE_INFO_I_",
        },
        stellenumfang: {
          id: "stellenumfang",
          type: "float",
          label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_STELLENUMFANG_",
          required: true,
          addonBack: "Stunden pro Woche",
          view: "v-alt",
          translate: true,
          statusChangePeriod: true,
          maxValue: undefined,
          tooltip: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_STELLENUMFANG_INFO_I_",
        },
        an_jahresbrutto: {
          id: "an_jahresbrutto",
          type: "currency",
          required: true,
          translate: true,
          statusChangePeriod: true,
          maxValue: undefined,
        },
      },
    };
  },
};
