import {
  forEach,
} from "lodash-es";

export function setModelWithoutNoCache({ model, noCacheObj }) {
  forEach(noCacheObj, item => {
    let index = model.indexOf(item.suffix);
    while (index !== -1) {
      model = `${ model.slice(0, index) }${ model.slice(index + item.suffix.length) }`;
      index = model.indexOf(item.suffix);
    }
  });
  return model;
}

export function setModelWithNoCache({ model, noCacheObj }) {
  forEach(noCacheObj, item => {
    let indexSrc = model.indexOf(item.src);
    let indexSrcWithSuffix = model.indexOf(`${ item.src }${ item.suffix }`);
    while (indexSrc !== -1) {
      if (indexSrc !== indexSrcWithSuffix) {
        model = `${ model.slice(0, indexSrc + item.src.length) }${ item.suffix }${ model.slice(indexSrc + item.src.length) }`;
      }
      indexSrc = model.indexOf(item.src, indexSrc + 1);
      indexSrcWithSuffix = model.indexOf(`${ item.src }${ item.suffix }`, indexSrc + 1);
    }
  });
  return model;
}

export function setSrcWithNoCacheForAllImagesInPage({ noCacheObj }) {
  forEach(noCacheObj, item => {
    const ALL_IMGAGES = document.querySelectorAll(`[src^="${ item.src }"]`) || [];
    forEach(ALL_IMGAGES, image => {
      image.src = `${ item.src }${ item.suffix }`;
    });
  });
}

export const NO_CACHE_PARAMETER = "?no_cache=";
