import AnsprechpartnerModalEdit from "../../Antraege/AntragDetails/AnsprechpartnerModalEdit/AnsprechpartnerModalEdit.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "AnsprechpartnerEditButtonModal",
  components: {
    AnsprechpartnerModalEdit,
    PuxButton,
  },
  props: {
    antrag: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  computed: {
    antragstellerPk() {
      return get(this.antrag, "antragstellerorg_obj.pk");
    },
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },

    closeModal() {
      this.isModalOpen = false;
    },
  },
};
