"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import models from "./models";


const katalogAdminModule = angular.module("root.kataloge", [
  uiRouter,
  models.name,
]);

katalogAdminModule.config($stateProvider => {
  $stateProvider

    .state("root.katalogeadmin", {
      url: `/kataloge/?
            user_cookie&
            pk&
            bez&
            editable
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-kataloge-admin-list></vue-kataloge-admin-list></div>"
        }
      },
      data: {
        permissions: ["kataloge.admin.view"]
      },
    })
    .state("root.katalogeadmin.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><kataloge-admin-details></kataloge-admin-details></div>"
        }
      },
      data: {
        permissions: ["kataloge.admin.view"]
      },
    })
  ;
})
;

export default katalogAdminModule;
