"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";


class KAufgabeEditBeschreibungController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, Funktionsgruppen, KontextTypen, DiasNotification, i18n) {
    super($q, $scope, DiasNotification);
    this.Funktionsgruppen = Funktionsgruppen;
    this.KontextTypen = KontextTypen;
    this.gettext = i18n.gettext;

    this.removeRolle = this.removeRolle.bind(this);
    this.params = {};
    this.init();
  }

  _save() {
    const data = {
      aro_ids: this.selectedRollen.filter(
        r => r.kontexttyp.id === this.KontextTypen.ANTRAGSTELLUNG
      ).map(r => r.pk),
      fro_ids: this.selectedRollen.filter(
        r => r.kontexttyp.id === this.KontextTypen.FOERDERORG
      ).map(r => r.pk),
      sor_ids: this.selectedRollen.filter(
        r => r.kontexttyp.id === this.KontextTypen.SYSTEM
      ).map(r => r.pk),
      bearbeiter_funktionsgruppen: this.orderedFgKeys.filter(
        key => this.fgMap[key].bearbeiter
      ),
      beobachter_funktionsgruppen: this.orderedFgKeys.filter(
        key => this.fgMap[key].beobachter
      )
    };

    const promise = this.model.customPUT(data, "berechtigungen_bearbeiten").then(
      response => {
        Object.assign(this.$scope.extra.berechtigungen, response.plain());
        this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
      },
      errors => {
        return this.onErrors(errors);
      }
    );
    return promise;
  }

  init() {
    if (!this.fgPromise) {
      this.fgPromise = this.Funktionsgruppen.getList().then(
        response => response.plain(),
        err => this.DiasNotification.page.error("Fehler beim Laden der Funktionsgruppen", err)
      );
      this.initFgs();
    }
    return this.fgPromise;
  }

  initFgs() {
    this.fgPromise.then(
      fgs => {
        this.fgs = fgs;
        this.fgs.forEach(fg => {
          fg.id = fg.key;
          fg.bez = fg.name;
        });
        const fgChoices = this.fgs.filter(fg => this.orderedFgKeys.indexOf(fg.key) === -1);
        this.params.fg = {
          bez: this.gettext("Berechtigung"),
          typ: "single_choice",
          choices: fgChoices,
          wert: null,
          options: {
            required: false
          },
          localSearch: true
        };
      }
    );
  }

  initRollen() {
    this.rollen = angular.copy(this.$scope.extra.rollen);
    this.selectedRollen = angular.copy(this.$scope.extra.berechtigungen.rollen);
    this.rollen.forEach(ro => {
      ro.id = ro.pk;
      ro.bez = ro.kbez;
      ro.group = ro.kontexttyp.kbez;
    });
    this.rollenChoices = this.rollen.filter(
      ro => !this.selectedRollen.find(sro => sro.pk === ro.pk)
    );
    this.$scope.$applyAsync(() => {
      this.params.bearbeiter = {
        bez: this.gettext("Bearbeiter-Rollen"),
        typ: "single_choice",
        choices: this.rollenChoices,
        wert: null,
        options: {
          required: false
        },
        localSearch: true
      };
      this.params.beobachter = {
        bez: this.gettext("Beobachter-Rollen"),
        typ: "single_choice",
        choices: this.rollenChoices,
        wert: null,
        options: {
          required: false
        },
        localSearch: true
      };
    });
  }

  onEnterEditMode() {
    super.onEnterEditMode();
    this.fgMap = angular.copy(this.$scope.extra.fgMap);
    this.initRollen();
    this.orderedFgKeys = angular.copy(this.$scope.extra.orderedFgKeys);
    if (this.fgPromise) {
      this.initFgs();
    }
  }

  addFunktionsgruppe(fgKey) {
    const fgIdx = this.params.fg.choices.findIndex(fg => fg.key === fgKey);
    if (fgIdx === -1) {
      return;
    }
    this.fgMap[fgKey] = this.fgMap[fgKey] || { bearbeiter: false, beobachter: false, obj: this.params.fg.choices[fgIdx] };
    this.orderedFgKeys.push(fgKey);
    this.fgMap[fgKey]._added = this.$scope.extra.orderedFgKeys.indexOf(fgKey) === -1;
    this.params.fg.choices = this.fgs.filter(fg => this.orderedFgKeys.indexOf(fg.key) === -1);
    this.params.fg.wert = null;
    this.updateRollenFgs();
  }

  removeFunktionsgruppe(fgKey) {
    const fgIdx = this.orderedFgKeys.findIndex(fg => fg === fgKey);
    if (fgIdx === -1) {
      return;
    }
    this.params.fg.choices = this.fgs.filter(fg => this.orderedFgKeys.indexOf(fg.key) === -1);
    delete this.fgMap[fgKey];
    this.orderedFgKeys.splice(fgIdx, 1);
  }

  addRolle(roPk) {
    const roIdx = this.rollen.findIndex(ro => ro.pk === roPk);
    if (roIdx === -1) {
      return;
    }
    const ro = angular.copy(this.rollen[roIdx]);
    this.selectedRollen.push(ro);
    ro.added = !this.$scope.extra.berechtigungen.rollen.find(r => r.pk === ro.pk);
    this.rollenChoices = this.rollen.filter(
      ro => !this.selectedRollen.find(sro => sro.pk === ro.pk)
    );
    this.params.bearbeiter.choices = this.rollenChoices;
    this.params.beobachter.choices = this.rollenChoices;
    this.params.bearbeiter.wert = null;
    if (this.params.beobachter.wert === ro.pk) {
      this.params.beobachter.wert = null;
    }
  }

  removeRolle(roPk) {
    const roIdx = this.selectedRollen.findIndex(ro => ro.pk === roPk);
    if (roIdx === -1) {
      return;
    }
    this.selectedRollen.splice(roIdx, 1);
    this.rollenChoices = this.rollen.filter(
      ro => !this.selectedRollen.find(sro => sro.pk === ro.pk)
    );
    this.params.bearbeiter.choices = this.rollenChoices;
    this.params.beobachter.choices = this.rollenChoices;
  }

  loadRolle(vs) {
    return () => {
      const roPk = this.params[vs].wert;
      const promise = this.$scope.extra.loadRolle(roPk, vs, this.fgMap);
      if (promise) {
        promise.then(
          rolle => {
            const ro = this.rollenChoices.find(v => v.pk === rolle.pk);
            Object.assign(ro, rolle.plain());
            ro.id = ro.pk;
            ro.bez = ro.kbez;
            ro.group = ro.kontexttyp.kbez;
          }
        );
      }
    };
  }

  updateRollenFgs() {
    this.selectedRollen.forEach(ro => this.$scope.extra.checkRolle(ro, "bearbeiter", this.fgMap));
    if (this.params.bearbeiter.wert) {
      const ro = this.rollenChoices.find(v => this.params.bearbeiter.wert === v.pk);
      this.$scope.extra.checkRolle(ro, "bearbeiter", this.fgMap);
    }
    if (this.params.beobachter.wert) {
      const ro = this.rollenChoices.find(v => this.params.beobachter.wert === v.pk);
      this.$scope.extra.checkRolle(ro, "beobachter", this.fgMap);
    }
  }
}

export default KAufgabeEditBeschreibungController;
