"use strict";


class SitzungsantragProtokollViewController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification) {
    this.$scope = $scope;
    this.DiasNotification = DiasNotification;
    this.sla = $scope.extras.sla;

    this.loading = true;
    this.sla.loadProtokoll().then(
      response => this.sla_protokoll = response.sla_protokoll
    ).finally(() => this.loading = false);

    const errorsLabel = {
      sla_protokoll: "Protokoll: "
    };

    this.save = () => {
      return this.sla.updateProtokoll(this.sla_protokoll).then(
        response => {
          this.sla.sla_protokoll = response.sla_protokoll;
          this.DiasNotification.page.success("Protokoll erfolgreich aktualisiert");
          return response;
        },
        error => {
          this.DiasNotification.form.clear();
          this.DiasNotification.form.error(error.data, "Ihre Daten sind fehlerhaft oder unvollständig.", {}, errorsLabel);
          this.errors = error.data;
          return $q.reject(error);
        }
      );
    };
  }
}

export default SitzungsantragProtokollViewController;
