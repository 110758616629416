import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  forEach,
  get,
  isArray,
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungErrorsBlockItem",
  components: {
    PuxTranslate,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "goTo",
  ],
  computed: {
    extraNameForTranslate() {
      return {
        name: this.name,
      };
    },

    name() {
      return get(this.error, "modul.kbez");
    },

    errorsTexts() {
      const ERRORS_TEXTS = [];
      if (this.error.errors) {
        forEach(this.error.errors, err => {
          if (isArray(err)) {
            forEach(err, errorText => {
              ERRORS_TEXTS.push(errorText);
            });
          }
        });
      }
      return ERRORS_TEXTS;
    },

    regelnummer() {
      return get(this.error, "modul.regelnummer");
    },
  },
  methods: {
    goToLocal() {
      this.$emit("goTo", { regelnummer: this.regelnummer });
    },
  },
};
