"use strict";
import angular from "angular";

import models from "../models";
import services from "../services";
import detailComponent from "./detail.component";

const modul = angular.module("dias.workflows.kaufgaben", [
  models.name,
  services.name,
]);

modul.config($stateProvider => {
  $stateProvider

  // Liste der KAufgaben
    .state("root.kaufgaben", {
      url: `/aufgabenvorlagen/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-k-aufgabe-list></vue-k-aufgabe-list></div>"
        }
      },
      data: {
        permissions: "kaufgabe.ui"
      }
    })

    .state("root.kaufgaben1", {
      url: `/aufgabenvorlagen1/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-k-aufgabe-list></vue-k-aufgabe-list></div>"
        }
      },
      data: {
        permissions: "kaufgabe.ui"
      }
    })

  // Detail-Seite einer KAufgabe
  // Sollte immer als letztes eingefügt werden, sonst werden Sub-URLs
  // as ID aufgelöst

    .state("root.kaufgaben1.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<kaufgabe-detail></kaufgabe-detail>"
        }
      },
    })

    .state("root.kaufgaben.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><aufgabenvorlagen-details/></div>"
        }
      },
    });
})

  .component("kaufgabeDetail", detailComponent)

;

export default modul;
