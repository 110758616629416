import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import FaqBadge from "../../DashboardFaqAuswahl/FaqBadge/FaqBadge.vue";

import DashboardFaqMixin from "../../DashboardFaqMixin";
import DashboardIconMixin from "../../DashboardIconMixin";
import DashboardStylesMixin from "../../DashboardStylesMixin";
import HttpMixin from "../../../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "FaqStation",
  components: {
    AngularLink,
    FaqBadge,
    PuxTranslate,
    PuxButton,
    PuxIcon,
  },
  mixins: [
    DashboardFaqMixin,
    DashboardIconMixin,
    DashboardStylesMixin,
    HttpMixin,
  ],
  props: {
    station: {
      type: Object,
      required: true,
    },
    stationIndex: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    isLast: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    idLocal() {
      return `${ this.id }_${ this.stationIndex }`;
    },

    classBox() {
      return this.station.css_klasse || "help";
    },

    footerText() {
      return this.station.text;
    },

    modelKeywords() {
      return this.station.schlagworte || [];
    },

    rubricPk() {
      return this.station.rubrik;
    },

    stationTitel() {
      return this.station.titel || (this.rubrikObj && this.rubrikObj.fqr_kbez) || "[Keine Angaben]";
    },

    linkSref() {
      return this.station.goto || "root.faqs";
    },
  },
};
