import PeriodModalUpdateMixin from "../../PeriodModalUpdateMixin";
import translate from "../../../../../../../global/directives/translate";
import PuxButton from "../../../../../../../global/components/PuxButton/PuxButton.vue";
import {
  round,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "PeriodModalUpdate",
  directives: {
    translate,
  },
  components: {
    PuxButton,
  },
  mixins: [
    PeriodModalUpdateMixin,
  ],
  data() {
    return {
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "von",
            type: "date",
            label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_UPDATE_MODAL_BEGINN_",
            required: true,
            view: "v",
            notBefore: undefined,
            notAfter: undefined,
            defaultValue: undefined,
            change: this.changeDate,
            translate: true,
          },
          {
            id: "bis",
            type: "date",
            label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_UPDATE_MODAL_ENDE_",
            required: true,
            view: "v",
            notBefore: undefined,
            notAfter: undefined,
            defaultValue: undefined,
            change: this.changeDate,
            translate: true,
          },
          {
            id: "an_bruttogehalt",
            type: "currency",
            label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_UPDATE_MODAL_AN_GEHALT_",
            required: true,
            view: "v",
            maxValue: undefined,
            translate: true,
          },
          {
            id: "ag_bruttogehalt",
            type: "currency",
            label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_UPDATE_MODAL_AG_GEHALT_",
            required: true,
            view: "v",
            disabled: true,
            translate: true,
          },
          {
            id: "gehalt",
            type: "currency",
            label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_UPDATE_MODAL_FOERDERFAEHIGE_",
            required: true,
            view: "v",
            disabled: true,
            translate: true,
          },
        ],
      },
      anBruttogehaltIndex: 2,
      obergrenzeLocal: undefined,
      obergrenzeFoerderfaehige: undefined,
    };
  },
  created() {
    this.initModel();
    this.setDateRange();
    this.setObergrenzeLocalWithPeriodeTeile();
  },
  methods: {
    setObergrenzeLocalWithPeriodeTeile() {
      const {
        OBERGRENZE_LOCAL,
        OBERGRENZE_FOERDERFAEHIGE,
      } = this.infoForBruttoGehalt;
      if (!OBERGRENZE_LOCAL) {
        return;
      }
      let an_bruttogehalt = 0;
      let gehalt = 0;
      forEach(this.periodGroup, item => {
        if (item.von !== this.period.von) {
          an_bruttogehalt += +item.an_bruttogehalt;
          gehalt += +item.gehalt;
        }
      });
      this.obergrenzeLocal = OBERGRENZE_LOCAL - an_bruttogehalt;
      this.obergrenzeFoerderfaehige = OBERGRENZE_FOERDERFAEHIGE - gehalt;
    },

    initModel() {
      this.model = {
        von: this.period.von,
        bis: this.period.bis,
        an_bruttogehalt: this.period.an_bruttogehalt,
        ag_bruttogehalt: this.period.ag_bruttogehalt,
        gehalt: this.period.gehalt,
      };
    },

    changeModel() {
      const {
        PKT_PAUSCHALE,
        STELLENUMFANG_PROJEKT,
        STELLENUMFANG,
      } = this.infoForBruttoGehalt;
      const A_N_BRUTTO = this.model.an_bruttogehalt;
      const GEHALT = +round((A_N_BRUTTO * (1 + PKT_PAUSCHALE / 100)) * STELLENUMFANG_PROJEKT / STELLENUMFANG, 2);

      const A_G_BRUTTO = +round(A_N_BRUTTO * (1 + PKT_PAUSCHALE / 100), 2);
      this.model.ag_bruttogehalt = round(this.obergrenzeLocal && A_G_BRUTTO > this.obergrenzeLocal ? this.obergrenzeLocal : A_G_BRUTTO, 2);
      this.model.gehalt = round(this.obergrenzeFoerderfaehige && GEHALT > this.obergrenzeFoerderfaehige ? this.obergrenzeFoerderfaehige : GEHALT, 2);
    },
  },
};
