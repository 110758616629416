"use strict";

class DiasTableConfig {
  fromService($q, service, opts) {
    opts = opts || {};
    const deferred = $q.defer();
    const config = {
      dataSource: service.getList,
        // TODO(andi) getRowActions, getActions entfernen
      getRowActions: undefined,
      getActions: () => [],
    };
    service.one().options().then(meta => {
      config.columnDefs = [];
      const columns = {};
      angular.forEach(meta.columns, item => {
        columns[item.field] = item;
        if (item.field === "pk") {
          columns[item.field].label = "Schlüssel";
        }
      });
      let colOrder = [];
      if (angular.isDefined(opts.columnOrder)) {
        colOrder = opts.columnOrder;
      } else {
        angular.forEach(columns, (idx, key) => colOrder.push(key));
      }
      if (angular.isDefined(opts.updateColumnDefs)) {
        opts.updateColumnDefs(columns);
      }
      angular.forEach(colOrder, key => {
        if (angular.isDefined(columns[key])) {
          const item = {
            field: key, name: columns[key].label
          };
          angular.extend(item, columns[key]);
          config.columnDefs.push(item);
        }
      });
      config.extra = opts.extra || {};
      config.actions = opts.actions || [];
      config.actionDefault = opts.actionDefault || null;
      config.tableActions = opts.tableActions || [];
      config.massActions = opts.massActions || [];
      config.emptyText = opts.emptyText;
      config.detailTemplate = opts.detailTemplate;
      config.getDetailRoute = opts.getDetailRoute || null;
      config.tableTitle = opts.tableTitle;
      config.docAnzeige = opts.docAnzeige || null;
      config.customArgs = opts.customArgs || null;
      config.filter = opts.filter || null;
      config.isDirty = opts.isDirty || null;
      config.renderSingleActions = opts.renderSingleActions || false;
      config.disabledDefaultRowAction = opts.disabledDefaultRowAction || false;
      deferred.resolve(config);
    });
    return deferred.promise;
  }

  /*@ngInject*/
  $get($q) {
    return {
      fromService: (service, opts) => this.fromService($q, service, opts)
    };
  }
}

export default DiasTableConfig;
