import ModuleMixin from "../ModuleMixin";
import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";

// @vue/component
export default {
  name: "Foerderdokumente",
  components: {
    AngularLink,
    PuxIcon,
  },
  mixins: [
    ModuleMixin,
  ],
  computed: {
    labelHeader() {
      return this.regel.afr_kbez;
    },

    isModuleEditable() {
      return false;
    },
  },
};
