import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import loading from "../../../../global/directives/loading";

import HttpMixin from "../../../../global/mixins/HttpMixin";

import {
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragstellerWizardSearch",
  components: {
    FormElement,
    FormstepDetail,
    Permission,
    PuxButton,
    PuxTranslate,
  },
  directives: {
    loading,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    selectOrganisation: {
      type: Function,
      default: undefined,
    },
    wizardConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      optionsFormElement: {
        type: "text",
        id: "suche",
        view: "v",
      },
      modelSearchMinInputLength: 3,
      modelSelectedOrganisation: undefined,
      maxOrganisationsLength: 10,
      modelSearchOrganisation: "",
      newOrganisation: "newOrganisation",
      statusLoadingSearchOrganisations: undefined,
      statusSearchedOrganisations: undefined,
      statusSearchFirst: undefined,
      organisations: [],
      organisationsCount: undefined,
      showAntragstellerNr: false,
    };
  },
  computed: {
    getIdOrganisation() {
      return organisation => `radio_org_${ organisation.pk }`;
    },

    isDisabledSearchOrganisationButton() {
      return this.statusLoadingSearchOrganisations || this.isNoInput;
    },

    isNoSearchedOrganisations() {
      return (!isUndefined(this.organisationsCount) && this.organisationsCount === 0);
    },

    isNoInput() {
      return this.modelSearchOrganisation === null || this.modelSearchOrganisation.length < this.modelSearchMinInputLength;
    },

    isTooManyOrganisations() {
      return (this.organisationsCount && this.organisationsCount > this.maxOrganisationsLength);
    },

    isOkOrganisations() {
      return (this.organisations && this.organisations.length > 1 && this.organisations.length <= this.maxOrganisationsLength);
    },
  },
  created() {
    this.checkSyConfig();
  },
  methods: {
    checkSyConfig() {
      if (!this.wizardConfig.suche_vor_ast_anlegen_notwendig) {
        this.statusSearchFirst = true;
      }
      if (this.wizardConfig.anzeige_antragstellernr) {
        this.showAntragstellerNr = true;
      }
    },

    onSearchOrganisation() {
      if (this.isNoInput) {
        return;
      }
      this.modelSelectedOrganisation = undefined;
      this.selectOrganisation({ forwardStepDisabled: true });
      this.statusSearchedOrganisations = true;
      this.statusLoadingSearchOrganisations = true;
      this.getHttp({ url: "antragstellerorganisationen/suche/", urlParams: { suche: this.modelSearchOrganisation } }).then(
        response => {
          this.organisationsCount = response.count;
          this.organisations = response.results;
        }
      ).then(
        () => {
          this.statusSearchFirst = true;
          this.statusLoadingSearchOrganisations = false;
        }
      );
    },

    onSelectOrganisation({ organisation, statusNewOrganisation } = {}) {
      this.selectOrganisation({ organisation, statusNewOrganisation });
    },
  },
};
