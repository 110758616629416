"use strict";

import "restangular";
import angular from "angular";

import CoreModels from "dias/core/models";
import Foerderorgrolle from "./foerderorgrolle.service";
import Astorgrolle from "./astorgrolle.service";
import Funktionsgruppen from "./funktionsgruppen.service";
import Nutzer from "./nutzer.service";
import Profil from "./profil.service";
import KNutzerstatus from "./nutzerstatus.service";
import KNutzerstatusUUIDs from "./nutzerstatusUUIDs.service";
import Rolle from "./rolle.service";
import Antragstellerorganisationen from "dias/antragsteller/models";
import AnonymisierteNutzer from "./anonymisierteNutzer.service";

const modelsModule = angular.module("auth.models", [
  "restangular",
  CoreModels.name,
  Antragstellerorganisationen.name
])

  .provider("Foerderorgrolle", Foerderorgrolle)
  .provider("Funktionsgruppen", Funktionsgruppen)
  .provider("KNutzerstatus", KNutzerstatus)
  .constant("KNutzerstatusUUIDs", KNutzerstatusUUIDs)
  .provider("Nutzer", Nutzer)
  .provider("Profil", Profil)
  .provider("Rolle", Rolle)
  .provider("Astorgrolle", Astorgrolle)
  .provider("AnonymisierteNutzer", AnonymisierteNutzer)
;

export default modelsModule;
