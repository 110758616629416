import {
  computed,
  toRefs,
} from "vue";

import { get } from "lodash-es";

export default function SitzungModulePreviewAPI(props) {
  const { module } = toRefs(props);

  const count = computed(() => {
    return get(module.value, "data.count");
  });

  return {
    count,
  };
}
