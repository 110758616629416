import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import PruefkriteriumIcon from "../PruefkriteriumIcon/PruefkriteriumIcon.vue";
import FormElementReadOnly from "../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import translate from "../../../../../global/directives/translate";
import {
  get,
  isNil,
  cloneDeep,
  isEmpty,
} from "lodash-es";

// @vue/component
export default {
  name: "PruefkriterienReadonlyElement",
  components: {
    FormElementReadOnly,
    PruefkriteriumIcon,
    SnapshotIcon,
    PuxButton,
    PuxTranslate,
  },
  directives: {
    translate,
  },
  props: {
    currentModel: {
      type: Object,
      required: false,
      default: undefined,
    },
    prfId: {
      type: String,
      required: false,
      default: undefined,
    },
    htmlId: {
      type: String,
      required: true,
    },
    optionsGueltigBis: {
      type: Object,
      required: true,
    },
    optionsKommentar: {
      type: Object,
      required: true,
    },
    statusDetailpruefungAktiv: {
      type: Boolean,
      required: true,
    },
    statusDetailpruefungAlwaysShow: {
      type: Boolean,
      required: true,
    },
    kommentarAktiv: {
      type: Boolean,
      required: true,
    },
    detailsTranslateMap: {
      type: Object,
      required: true,
    },
    diffChanges: {
      type: Object,
      required: false,
      default: undefined,
    },
    diffCriteria: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      statusToggleBtn: false,
    };
  },
  computed: {
    currentModelLocal() {
      return this.currentModel || {};
    },

    label() {
      return get(this.currentModelLocal, "pruefkriterium.prf_kbez", "");
    },

    pruefkriteriumTextTranslation() {
      switch (this.currentModelLocal.opr_geprueft) {
      case true:
        return "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_VALUE_TRUE_";
      case false:
        return "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_VALUE_FALSE_";
      default:
        if (this.currentModelLocal.opr_pruefung_nicht_zutreffend) {
          return `_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_VALUE_NICHT_ZUTREFFEND_`;
        }
        return "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_VALUE_NULL_";
      }
    },

    statusShowPruefkriteriumGueltigBis() {
      return !isNil(this.currentModelLocal.opr_gueltig_bis) && this.currentModel !== null;
    },

    optionsGueltigBisLocal() {
      const OPTIONS = cloneDeep(this.optionsGueltigBis);
      OPTIONS.htmlId = `${ this.htmlId }_${ OPTIONS.id }`;
      return OPTIONS;
    },

    optionsKommentarLocal() {
      const OPTIONS = cloneDeep(this.optionsKommentar);
      OPTIONS.htmlId = `${ this.htmlId }_${ OPTIONS.id }`;
      return OPTIONS;
    },

    statusShowDetailpruefungToggleBtn() {
      if (!this.pruefkriteriumDetails.length || !this.statusDetailpruefungAktiv) {
        return false;
      }
      if (this.statusDetailpruefungAlwaysShow) {
        return true;
      }
      return !isEmpty(this.subkriterien);
    },

    idToggleBtn() {
      return `${ this.htmlId }_toggle_detail`;
    },

    labelToggleBtn() {
      return this.statusToggleBtn ?
        "_BTN_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_TOGGLE_CLOSE_TITLE_" :
        "_BTN_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_TOGGLE_OPEN_TITLE_";
    },

    textToggleBtn() {
      return this.statusToggleBtn ?
        "_BTN_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_TOGGLE_CLOSE_LABEL_" :
        "_BTN_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_TOGGLE_OPEN_LABEL_";
    },

    pruefkriteriumDetails() {
      return get(this.currentModelLocal, "pruefkriterium.details", []);
    },

    subkriterien() {
      return this.currentModelLocal.opr_subkriterien || {};
    },

    statusShowComment() {
      if (!this.kommentarAktiv) {
        return false;
      }
      return !isNil(this.currentModelLocal.opr_kommentar);
    },

    diffChangesLocal() {
      return this.diffChanges || {};
    },
  },
  methods: {
    toggleDetailpruefung() {
      this.statusToggleBtn = !this.statusToggleBtn;
    },
  },
};
