"use strict";

import angular from "angular";
import FavoritenUtils from "./favoritenUtils.service";

const favoritenServices = angular.module("dias.favoriten.services", [
])

  .service("FavoritenUtils", FavoritenUtils)

;

export default favoritenServices;
