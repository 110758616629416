import Alert from "../../../../global/components/Alert/Alert.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import ModuleMixin from "../ModuleMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import {
  cloneDeep,
  forEach,
  get,
  indexOf,
  isNil,
  min,
  values,
} from "lodash-es";

const SNAPSHOT_CHANGES_LIST = [
  "a_scoretext",
  "a_scorenum",
];

// @vue/component
export default {
  name: "AntragScore",
  components: {
    Alert,
    FormElement,
    FormElementReadOnly,
    SnapshotModule,
    SnapshotIcon,
  },
  mixins: [
    ModuleMixin,
    PermissionMixin,
  ],
  data() {
    return {
      modelEdit: {
        a_scoretext: undefined,
        a_scorenum: undefined
      }
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.open;
      return OPTIONS;
    },
    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        const MODEL = this.model || {};
        forEach(SNAPSHOT_CHANGES_LIST, key => {
          if (get(MODEL, key) !== get(this.snapshotModule, key)) {
            DIFF[key] = true;
          }
        });
      }
      return DIFF;
    },
    statusDiff() {
      return indexOf(values(this.diff), true) !== -1;
    },
    labelHeader() {
      if (this.modelParameter.frage_text) {
        return this.modelParameter.frage_text;
      }
      return "_TXT_GR_ANTRAG_SCORE_HEADER_";
    },
    isModuleEditable() {
      return !this.readonly && this.checkPermissionsSync({ perm: "foerderantrag.score.update", permArray: this.obj.user_permissions || [] });
    },
    hasViewPermission() {
      return this.checkPermissionsSync({ perm: "foerderantrag.score.view" });
    },
    translationNoViewPermission() {
      return { text: "_MSG_STANDARD_FOERDERMODUL_ANTRAG_SCORE_VIEW_NOT_ALLOWED_WARNING_" };
    },
    scoreTextActive() {
      return this.modelParameter.konfig.text_aktiv && !isNil(this.scoreTextFormelement);
    },
    scoreTextFormelement() {
      const CONF = {
        id: "a_scoretext",
        htmlId: `${ this.htmlRegelNummer }_a_scoretext`,
        label: this.modelParameter.konfig.text_label,
        showLabel: true,
        required: this.modelParameter.pflichtfeld,
        view: "v-alt"
      };
      switch (this.modelParameter.konfig.text_variant) {
      case 0:
        // Manuelle Eingabe
        CONF.type = "text";
        CONF.max_length = this.modelParameter.konfig.text_max;
        break;
      case 1:
        // Auswahlliste
        CONF.type = "single_choice";
        CONF.data = this.modelParameter.konfig.text_options || [];
        CONF.keyId = "value";
        CONF.keyLabel = "label";
        break;
      default:
        return;
      }
      return CONF;
    },
    scoreNumActive() {
      return this.modelParameter.konfig.num_aktiv;
    },
    scoreNumFormelement() {
      const CONF = {
        id: "a_scorenum",
        htmlId: `${ this.htmlRegelNummer }_a_scorenum`,
        label: this.modelParameter.konfig.num_label,
        showLabel: true,
        required: this.modelParameter.pflichtfeld,
        view: "v-alt"
      };
      switch (this.modelParameter.konfig.num_variant) {
      case 0:
        // Manuelle Eingabe
        CONF.type = "float";
        CONF.min_value = this.modelParameter.konfig.num_min;
        CONF.max_value = this.modelParameter.konfig.num_max;
        CONF.decimal_places = this.modelParameter.konfig.num_decimal;
        CONF.max_digits = min(14, 8 + this.modelParameter.konfig.num_decimal);
        break;
      case 1:
        // Auswahlliste
        CONF.type = "single_choice";
        CONF.data = this.modelParameter.konfig.num_options || [];
        CONF.keyId = "value";
        CONF.keyLabel = "label";
        break;
      case 2:
        // Automatische Ermittlung
        CONF.type = "math_expression";
        break;
      default:
        return;
      }
      return CONF;
    },

    errorsLabelsOptions() {
      const ERRORS_LABELS = [];
      if (this.scoreNumActive) {
        ERRORS_LABELS.push(this.scoreNumFormelement);
      }
      if (this.scoreTextActive) {
        ERRORS_LABELS.push(this.scoreTextFormelement);
      }
      return ERRORS_LABELS;
    },
  },
  methods: {
    onReceiveSignal(signal_kennung) {
      if (signal_kennung === "dynamische_tabelle.update" ||
        signal_kennung === "dynamische_zahlen.update" ||
        signal_kennung === "dynamische_mehrfachauswahl.update") {
        this.reloadModule({ statusUpdate: true });
      }
    },

    open() {
      this.modelEdit = cloneDeep(this.model);
    },

    setLocalErrorsFromGlobal({ errors }) {
      this.errors = errors;
    },
  }
};
