import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import VertragWizardFoerdervertragsbestaetigungErrors from "./VertragWizardFoerdervertragsbestaetigungErrors/VertragWizardFoerdervertragsbestaetigungErrors.vue";
import Wizard from "../../../../global/components/Wizard/Wizard.vue";

import {
  getTranslatedText,
} from "../../../../global/functions/utils";
import {
  findIndex,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragWizardFoerdervertragsbestaetigung",
  components: {
    FormstepDetail,
    PuxTranslate,
    VertragWizardFoerdervertragsbestaetigungErrors,
    Wizard,
  },
  props: {
    vertrag: {
      type: Object,
      required: true,
    },
    isBankverbindungVisible: {
      type: Boolean,
      required: true,
    },
    isAbschlagVisible: {
      type: Boolean,
      required: true,
    },
    validationErrors: {
      type: Array,
      required: false,
      default: () => [],
    },
    showErrorModulName: {
      type: Function,
      required: true,
    },
  },
  emits: [
    "close",
  ],
  data() {
    return {
      stepActive: 0,
      stepsVisited: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
      },
      booleanPropsWizard: {
        backTargetStepDisabled: undefined,
        backStepHide: undefined,
        backStepDisabled: undefined,
        forwardStepHide: undefined,
        forwardStepDisabled: false,
        buttonsLoading: false,
        closeButtonShow: true,
      },
      wizardOptions: {
        closeButtonLabel: "_BTN_CLOSE_",
      },
      formstepDetailOptionsStep2: {
        hideHeader: true,
      },
    };
  },
  computed: {
    wizardSteps() {
      const WIZARD_STEPS = [
        {
          label: "_TXT_VERTRAG_WIZARD_FV_BESTAETIGUNG_STEP_DOKUMENTE_",
          slotId: "stepDokumente",
          title: "Dokumente und Hinweise",
          icon: "euro",
        },
        {
          label: "_TXT_VERTRAG_WIZARD_FV_BESTAETIGUNG_STEP_DURCHFUERUNGSZEITRAUM_",
          slotId: "stepZeitraum",
          title: "_TXT_VERTRAG_WIZARD_FV_BESTAETIGUNG_STEP_DURCHFUERUNGSZEITRAUM_TITLE_",
          icon: "euro",
        },
      ];
      if (this.isBankverbindungVisible) {
        WIZARD_STEPS.push({
          label: "_TXT_VERTRAG_WIZARD_FV_BESTAETIGUNG_STEP_BANKVERBINDUNG_",
          slotId: "stepBankverbindung",
          title: "_TXT_VERTRAG_WIZARD_FV_BESTAETIGUNG_STEP_BANKVERBINDUNG_TITLE_",
          icon: "euro",
          key: "bankverbindung",
        });
      }
      if (this.isAbschlagVisible) {
        WIZARD_STEPS.push({
          label: "_TXT_VERTRAG_WIZARD_FV_BESTAETIGUNG_STEP_ABSCHLAG_",
          slotId: "stepAbschlag",
          title: "_TXT_VERTRAG_WIZARD_FV_BESTAETIGUNG_STEP_ABSCHLAG_TITLE_",
          icon: "euro",
          key: "abschlag",
        });
      }
      WIZARD_STEPS.push(
        {
          label: "_TXT_VERTRAG_WIZARD_FV_BESTAETIGUNG_STEP_VERTRETUNGSBERECHTIGTER_",
          slotId: "stepVertretungsberechtigte",
          title: "_TXT_VERTRAG_WIZARD_FV_BESTAETIGUNG_STEP_VERTRETUNGSBERECHTIGTER_TITLE_",
          icon: "euro",
          key: "vertretungsberechtigte",
        },
        {
          label: "_TXT_VERTRAG_WIZARD_FV_BESTAETIGUNG_STEP_ZUSAMMENFASSUNG_",
          slotId: "stepZusammenfassung",
          title: "_TXT_VERTRAG_WIZARD_FV_BESTAETIGUNG_STEP_ZUSAMMENFASSUNG_TITLE_",
          icon: "euro",
        },
      );
      return WIZARD_STEPS;
    },

    isStepDokumenteInfoBoxVisible() {
      return !!getTranslatedText("_HTML_VERTRAG_WIZARD_STEP1_INFO_BOX_");
    },

    isStepZeitraumInfoBoxVisible() {
      return !!getTranslatedText("_HTML_VERTRAG_WIZARD_STEP2_INFO_BOX_");
    },

    isStepBankverbindungInfoBoxVisible() {
      return !!getTranslatedText("_HTML_VERTRAG_WIZARD_STEP3_INFO_BOX_");
    },

    isStepAbschlagInfoBoxVisible() {
      return !!getTranslatedText("_HTML_VERTRAG_WIZARD_STEP4_INFO_BOX_");
    },

    isStepVertretungsberechtigteInfoBoxVisible() {
      return !!getTranslatedText("_HTML_VERTRAG_WIZARD_STEP5_INFO_BOX_");
    },

    isStepZusammenfassungInfoBoxVisible() {
      return !!getTranslatedText("_HTML_VERTRAG_WIZARD_STEP6_INFO_BOX_");
    },
  },
  methods: {
    goOneStepForward() {
      this.stepActive++;
    },

    goOneStepBack() {
      this.stepActive--;
    },

    closeLocal() {
      this.$emit("close");
    },

    goToWizardStep(error) {
      if (error && error.anzeigekontext && error.anzeigekontext.key) {
        const WIZARD_STEPS_INDEX = findIndex(this.wizardSteps, ["key", error.anzeigekontext.key]);
        if (WIZARD_STEPS_INDEX !== -1) {
          this.stepActive = WIZARD_STEPS_INDEX;
        }
      }
    },
  },
};
