import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";

import DashboardModuleMixin from "../DashboardModuleMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import AntragWfStatusgruppenUUIDs from "../../../../const/AntragWfStatusgruppenUUIDs";

import {
  forEach,
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "DashboardUnterlagenNachreichen",
  components: {
    AngularLink,
  },
  mixins: [
    DashboardModuleMixin,
    PermissionMixin,
  ],
  data() {
    return {
      user: undefined,
      astOrgs: [],
    };
  },
  computed: {
    data() {
      return this.argument.data || {};
    },

    antragWfStatusgruppen() {
      const ANTRAG_WF_STATUSGRUPPEN = {};
      if (this.data.statusgruppen) {
        forEach(this.data.statusgruppen, value => {
          ANTRAG_WF_STATUSGRUPPEN[value.workflowstatusgruppe.pk] = value;
        });
      }
      return ANTRAG_WF_STATUSGRUPPEN;
    },

    countUnNachreichen() {
      if (!this.data.statusgruppen) {
        return;
      }
      return this.antragWfStatusgruppen[AntragWfStatusgruppenUUIDs.A_PRUEFEN].anzahl_objekte +
        this.antragWfStatusgruppen[AntragWfStatusgruppenUUIDs.A_ENTSCHEIDEN].anzahl_objekte +
        this.antragWfStatusgruppen[AntragWfStatusgruppenUUIDs.A_BEWILLIGT].anzahl_objekte;
    },

    titleBoxTranslate() {
      const TRANSLATE = {};
      if (this.countUnNachreichen === 1) {
        TRANSLATE.title = "_TXT_DASHBOARD_UNTERLAGEN_NACHREICHEN_TITLE_EIN_";
      } else if (!this.countUnNachreichen) {
        TRANSLATE.title = "_TXT_DASHBOARD_UNTERLAGEN_NACHREICHEN_TITLE_KEIN_";
      } else {
        TRANSLATE.title = "_TXT_DASHBOARD_UNTERLAGEN_NACHREICHEN_TITLE_MEHRERE_{{count}}_";
        TRANSLATE.extra = {
          count: this.countUnNachreichen,
        };
      }
      return TRANSLATE;
    },

    localAttributes() {
      if (!this.countUnNachreichen) {
        return;
      }
      const ATTRIBUTES = {
        "aria-labelledby": this.ariaLabelledbyForBadgeLink,
      };
      const PARAMS = {};
      if (this.astOrgs.length > 0) {
        PARAMS.antragstellerorg = this.astOrgs;
      }
      if (this.countUnNachreichen === 1) {
        const pk = this.antragWfStatusgruppen[AntragWfStatusgruppenUUIDs.A_PRUEFEN].relevantes_objekt_pk ||
          this.antragWfStatusgruppen[AntragWfStatusgruppenUUIDs.A_ENTSCHEIDEN].relevantes_objekt_pk ||
          this.antragWfStatusgruppen[AntragWfStatusgruppenUUIDs.A_BEWILLIGT].relevantes_objekt_pk;
        ATTRIBUTES.sref = "root.foerderantrag.details";
        ATTRIBUTES.parameter = { id: pk };
        return ATTRIBUTES;
      }
      PARAMS.workflowstatusgruppe = [
        AntragWfStatusgruppenUUIDs.A_PRUEFEN,
        AntragWfStatusgruppenUUIDs.A_ENTSCHEIDEN,
        AntragWfStatusgruppenUUIDs.A_BEWILLIGT
      ];
      ATTRIBUTES.sref = "root.foerderantrag";
      ATTRIBUTES.options = PARAMS;
      return ATTRIBUTES;
    },

    componentLocal() {
      return this.countUnNachreichen ? "angular-link" : "div";
    },
  },
  created() {
    this.getUser();
    this.astOrgs = this.getAstOrgs();
  },
  methods: {
    getUser() {
      this.user = cloneDeep(this.me);
    },

    getAstOrgs() {
      const AST_ORGS = [];
      if (this.user && this.user.kontexte) {
        forEach(this.user.kontexte, kontext => {
          if (kontext.kontext_typ === 1) {
            AST_ORGS.push(kontext.org_id);
          }
        });
      }
      return AST_ORGS;
    },
  },
};
