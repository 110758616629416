import KofiErfassungPosition from "../KofiErfassungPosition/KofiErfassungPosition.vue";
import {
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "KofiErfassungPersonalkostenLohnjurnalFunktion",
  components: {
    KofiErfassungPosition,
  },
  props: {
    funktion: {
      type: Object,
      required: true,
      info: "Hauptinformation",
    },
    funktionIndex: {
      type: Number,
      required: true,
      info: "Index in Array-Funktionen",
    },
    parentNumber: {
      type: String,
      required: true,
      info: "Applikationskontextnummer.Modulnummer",
    },
    prefixCurrent: {
      type: Object,
      required: true,
      info: "Information vom Kofiart(kosten oder Finanzierung)",
    },
    auszahlung: {
      type: Object,
      required: true,
      info: "Auszahlung",
    },
    vertrag: {
      type: Object,
      required: true,
      info: "Vertrag",
    },
    htmlId: {
      type: String,
      required: true,
      info: "modul.regel_nummer",
    },
    urlSave: {
      type: String,
      required: true,
      info: "URL für speichern",
    },
    extraTranslate: {
      type: Object,
      required: true,
      info: "Die zusätzliche Information für Übersetzung",
    },
    katalogDetails: {
      type: Array,
      required: true,
      info: "Die Liste von 'Typ / Kategorie'",
    },
    relevanteAuszahlungen: {
      type: Array,
      required: true,
      info: "Alle vorhergehenden Auszahlungen (und die eigene)",
    },
    apiAntrag: {
      type: String,
      required: true,
      info: "API foerderantraege/{{ AntragPk }}/",
    },
    belegDokumente: {
      type: Array,
      required: true,
      info: "Belegdokumente",
    },
    apiAntragDokumente: {
      type: String,
      required: true,
      info: "API foerderantraege/{{ AntragPk }}/dokumente/?dokart={{ dokart }}",
    },
    extraKF: {
      type: Object,
      required: true,
      info: "{ regel, regeldata, modelParameter }",
    },
    positionen: {
      type: Array,
      required: true,
      info: "Alle Positionen",
    },
    contextNumber: {
      type: Number,
      required: true,
      info: "Applikationskontextnummer",
    },
    relevantePerioden: {
      type: Array,
      required: false,
      default: undefined,
      info: "Alle für die Auszahlung relevanten Positionen",
    },
    statusHideNumber: {
      type: Boolean,
      required: true,
      info: "Positionsnummer ausblenden",
    },
    statusSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    statusSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    diffKofi: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    kofiPk: {
      type: String,
      required: false,
      default: undefined,
    },
    snapshot: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    htmlIdLocal() {
      return `${ this.htmlId }_${ this.funktionIndex }`;
    },

    funktionNumber() {
      return `${ this.parentNumber }.${ this.funktionIndex + 1 }`;
    },

    positionenForFunktion() {
      return filter(this.positionen, position => {
        return position.vkp_argument &&
          position.vkp_argument.personalfunktion === this.funktion.pk;
      });
    },
  },
};
