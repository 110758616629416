import WorkflowModulConfirmMixin from "../WorkflowModulConfirm/WorkflowModulConfirmMixin";

import fileDownload from "js-file-download";
import {
  WorkflowModulBase,
} from "../WorkflowModulBaseMixin";
import {
  cloneDeep,
} from "lodash-es";

class WorkflowModulDownloadAfterConfirm extends WorkflowModulBase {
  constructor({ workflowfolge, extra, parameterInUrl, aufgabeObjId, aufgabeObjParentId, getHttp }) {
    super({ workflowfolge, extra });
    this.parameterInUrl = cloneDeep(parameterInUrl);
    this.aufgabeObjId = aufgabeObjId;
    this.aufgabeObjParentId = aufgabeObjParentId;
    this.getHttp = getHttp;
  }

  onServerSuccess() {
    let fileURL = this.parameterInUrl.replace("{UUID}", this.aufgabeObjId);
    fileURL = fileURL.replace("{parentUUID}", this.aufgabeObjParentId).replace("/api/", "").replace("api/", "");
    this.getHttp({
      url: fileURL,
      responseType: "blob",
      fullResponse: true,
    }).then(
      response => {
        const disposition = response.headers["content-disposition"];
        let filename = "";
        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i;
          const matches = filenameRegex.exec(disposition);
          if (matches !== null && matches[2]) {
            filename = matches[2];
          }
        }
        fileDownload(response.data, filename);
        super.onServerSuccess();
      }
    );
  }
}

// @vue/component
export default {
  name: "WorkflowModulDownloadAfterConfirm",
  mixins: [
    WorkflowModulConfirmMixin,
  ],
  methods: {
    getModuleInstance() {
      return new WorkflowModulDownloadAfterConfirm({
        workflowfolge: this.workflowfolge,
        extra: this.extra,
        parameterInUrl: this.parameterIn.url,
        aufgabeObjId: this.aufgabe.obj_id,
        aufgabeObjParentId: this.aufgabe.obj_parent_id,
        getHttp: this.getHttp,
      });
    },
  },
};
