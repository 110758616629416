"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import "restangular";

import errors from "dias/core/errors";
import notification from "dias/core/notification";

import News from "./news.service";
import NewsExtern from "./news-extern.service";

const modelsModule = angular.module("core.news.models", [
  "restangular",
  uiRouter,
  errors.name,
  notification.name
])

  .provider("News", News)
  .provider("NewsExtern", NewsExtern)
;

export default modelsModule;
