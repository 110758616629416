"use strict";

import { isNil } from "lodash";
import StandardFinanzierungController from "../../finanzierung.controller";

class ZuschussMitFinanzierungsEingabeController extends StandardFinanzierungController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService) {
    super($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService);
    this.name = "ZuschussMitEingabeController";
    this.Foerderantrag = Foerderantrag;
    this.allowsInput = true;
    if (this.kontext === "zuschuss") {
      this.readonly = true;
    }
  }


  get_prozent() {
    // Im Zweifelsfalle immer den Wert aus der aktuellen Berechnung zurückgeben:
    if (this.infoprovider && this.infoprovider.status === 1 && this.infoprovider.details && this.infoprovider.details[this.modul.regel.afr_id]) {
      return this.infoprovider.details[this.modul.regel.afr_id].prozent;
    } else if (this.antragFinanzierungsart) {
      return this.antragFinanzierungsart.afa_prozent;
    }
  }

  getSumme() {
    if (this.infoprovider && this.infoprovider.status === 1 && this.infoprovider.details && this.infoprovider.details[this.modul.regel.afr_id] && !_.isNil(this.infoprovider.details[this.modul.regel.afr_id].betrag)) {
      return this.infoprovider.details[this.modul.regel.afr_id].betrag;
    }
    return this.antragFinanzierungsart.afa_summe;
  }

  show_prozente() {
    // Im Zuschusskontext werden keine Prozente angezeigt
    if (this.kontext === "zuschuss") {
      return false;
    }
    // Wenn eine Zuschussberechnung durchgeführt werden konnte:
    if (this.infoprovider && this.infoprovider.status === 1) {
      return !isNil(this.get_prozent());
    }
    // Wenn keine Zuschussberechnung durchgeführt wurde:
    if (!this.infoprovider) {
      return this.antragFinanzierungsart.afa_prozent !== null;
    }
  }
}

export default ZuschussMitFinanzierungsEingabeController;
