"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller";

class FoerderregelBasedataEditController extends BaseEditController {
  /*@ngInject*/
  constructor(
    $q,
    $rootScope,
    $scope,
    ParameterUtils,
    AuthService,
    DiasNotification,
    Foerderanzeigekontext,
    Systemmodul,
    i18n,
    Katalog
  ) {
    super($q, $scope, DiasNotification);

    if (this.model && this.model.re_savecallback) {
      this.model.re_savecallback = String(this.model.re_savecallback);
    }
    this.loadingEditData = true;
    this.gettext = i18n.gettext;
    const anzPromise = Foerderanzeigekontext.getList();
    this.anzeigekontextChoices = anzPromise.$object;
    const modulPromise = Systemmodul.getList();
    this.modulChoices = modulPromise.$object;
    const regelkeysPromise = Katalog.getKatalog("regelkeys");
    this.keyChoices = regelkeysPromise.$object;
    $q.all([anzPromise, modulPromise, regelkeysPromise]).finally(
      () => this.loadingEditData = false
    );

    this.save = () => {
      const data = {
        re_kbez: this.model.re_kbez,
        re_kbez_admin: this.model.re_kbez_admin,
        re_bezeichnung: this.model.re_bezeichnung,
        re_astbez: this.model.re_astbez,
        re_savecallback: this.model.re_savecallback ? this.model.re_savecallback : null,
        key: this.model.key,
        anzeigekontext: this.model.anzeigekontext,
      };
      return this.edit(data);
    };

    this.edit = data => {
      return this.model.customPUT(data).then(
        response => {
          DiasNotification.page.success("Geschäftsregel erfolgreich gespeichert.");
          if (this.model.is_used) {
            DiasNotification.page.success(
              "Zugehörige Regeln an Förderangeboten wurden ebenfalls aktualisiert"
            );
          }

          response.param_keys = ParameterUtils.get_ordered_keys(response.re_argument);
          response.key_bez = response.key_bez || null;
          $scope.model = $scope.model.merge($scope.model, response);
          $rootScope.$broadcast(
            ParameterUtils.events.saved,
            $scope.model.param_keys,
            $scope.model.re_argument
          );
          return $scope.model;
        },
        // this.onError
        errors => {
          $rootScope.$broadcast(
            ParameterUtils.events.error,
            errors.data
          );
          return this.onErrors(errors, this.getErrorLabels());
        }
      );
    };

    this.getErrorLabels = () => {
      let labels = {
        re_kbez: "Meldungstext bei Validierungsmeldungen: ",
        re_kbez_admin: "Kurzbezeichnung für Administration: ",
        re_bezeichnung: "Beschreibung: ",
        re_astbez: "Hilfetext für den " + this.gettext("Antragsteller") + " : ",
        re_savecallback: this.gettext("_LBL_FOERDERREGEL_DETAILS_SAVECALLBACK_") + " : ",
        anzeigekontext: "Anzeigekontext: ",
        modul: "Modul: ",
        key: "Schlüssel: ",
      };
      labels = Object.assign(labels, ParameterUtils.getErrorLabels(this.model.re_argument));
      return labels;
    };

    this.onModulChange = modul_pk => {
      angular.forEach(this.modulChoices, modul => {
        if (modul.pk === modul_pk) {
          this.model.re_argument = modul.sm_parameterin;
          this.model.modul_obj = modul;
          this.model.param_keys = ParameterUtils.get_ordered_keys(this.model.re_argument);
        }
      });
    };


    if (this.model && this.model.re_argument) {
      this.model.param_keys = ParameterUtils.get_ordered_keys(this.model.re_argument);
    }
  }
}

export default FoerderregelBasedataEditController;
