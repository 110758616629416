"use strict";

import BaseModel from "dias/core/models/baseModel";

class Belege extends BaseModel {
  constructor() {
    super("vertragsbelege");
  }

  extendModel(model) {
    model = super.extendModel(model);

    model.checkPermissions = permissions => {
      const params = {
        permissions: permissions,
      };
      return model.customGET("check_my_permissions", params);
    };

    return model;
  }
}

export default Belege;
