import PuxButton from "../../PuxButton/PuxButton.vue";
import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";
import UiTreeGroup from "./UiTreeGroup/UiTreeGroup.vue";

import UIComponentMixin from "../UIComponentMixin";

import UiTreeAPI from "./compositionAPI/UiTreeAPI";

import {
  getTextFromPlaceholder,
} from "../../../functions/utils";
import {
  initModelFromList,
} from "../../../functions/mappingForParameterToFormElement";
import {
  cloneDeep,
  isUndefined,
  min,
} from "lodash-es";


// @vue/component
export default {
  name: "UiTree",
  components: {
    PuxButton,
    PuxTranslate,
    UiTreeGroup,
  },
  mixins: [
    UIComponentMixin,
  ],
  props: {
    model: {
      type: [Object, Array],
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    const {
      fieldsLocal,
      isArrayLocal,
      modelLocal,
    } = UiTreeAPI(props);

    return {
      fieldsLocal,
      isArrayLocal,
      modelLocal,
    };
  },
  data() {
    return {
      indexHighlight: undefined,
      timer: undefined,
    };
  },
  computed: {
    groupsCountLocal() {
      return this.options.groupsCount;
    },

    idForButtonAddGroup() {
      return `${ this.getId }_add_group`;
    },

    idForButtonDuplicateLast() {
      return `${ this.getId }_duplicate_last`;
    },

    isButtonAddGroupVisible() {
      if (this.isArrayLocal) {
        if (!isUndefined(this.groupsCountLocal)) {
          return this.modelLocal.length < this.groupsCountLocal;
        }
        return true;
      }
      return false;
    },

    isButtonDuplicateLastVisible() {
      return this.isButtonAddGroupVisible && this.lastGroupIndex > -1;
    },

    extraTranslateForAddButtons() {
      return {
        label: getTextFromPlaceholder(this.options.label),
        labelGroup: getTextFromPlaceholder(this.options.labelGroup),
      };
    },

    lastGroupIndex() {
      return this.modelLocal.length - 1;
    },

    defaultModelForChildrenFields() {
      return initModelFromList({
        list: this.options.fields || [],
      });
    },
  },
  beforeCreate() {
    this.$options.components.FormElement = require("../../FormElement/FormElement.vue").default;
  },
  methods: {
    onAddGroup() {
      if (!this.isButtonAddGroupVisible) {
        return;
      }
      const MODEL = cloneDeep(this.modelLocal);
      const CURRENT_INDEX = this.lastGroupIndex + 1;
      MODEL.push({
        index: CURRENT_INDEX,
        value: this.defaultModelForChildrenFields,
      });
      this.onInput({ model: MODEL });
      this.setIndexHighlight({ index: CURRENT_INDEX });
    },

    duplicateLastGroup() {
      if (!this.isButtonDuplicateLastVisible) {
        return;
      }
      const MODEL = cloneDeep(this.modelLocal);
      const MODEL_ITEM_LAST = cloneDeep(MODEL[this.lastGroupIndex]);
      const CURRENT_INDEX = MODEL_ITEM_LAST.index + 1;
      MODEL_ITEM_LAST.index = CURRENT_INDEX;
      MODEL.push(MODEL_ITEM_LAST);
      this.onInput({ model: MODEL });
      this.setIndexHighlight({ index: CURRENT_INDEX });
    },

    moveDown({ modelItemIndex }) {
      this.moveFromTo({
        startIndex: modelItemIndex,
        targetIndex: modelItemIndex + 1,
      });
    },

    moveUp({ modelItemIndex }) {
      this.moveFromTo({
        startIndex: modelItemIndex,
        targetIndex: modelItemIndex - 1,
      });
    },

    moveFromTo({ startIndex, targetIndex }) {
      let model = cloneDeep(this.modelLocal);
      const CURRENT_MODEL = cloneDeep(model[startIndex]);
      model.splice(startIndex, 1);
      model.splice(targetIndex, 0, CURRENT_MODEL);
      model = this.correctIndexInModel({
        minIndex: min([startIndex, targetIndex]),
        model,
      });
      this.onInput({ model });
      this.setIndexHighlight({ index: targetIndex });
    },

    correctIndexInModel({ minIndex, model }) {
      for (let i = minIndex; i < model.length; i++) {
        model[i].index = i;
      }
      return model;
    },

    changeModelInItem({ modelItemIndex, modelItem }) {
      const MODEL = cloneDeep(this.modelLocal);
      MODEL.splice(modelItemIndex, 1, cloneDeep(modelItem));
      this.onInput({ model: MODEL });
    },

    deleteModelItem({ modelItemIndex }) {
      let model = cloneDeep(this.modelLocal);
      model.splice(modelItemIndex, 1);
      model = this.correctIndexInModel({
        minIndex: modelItemIndex,
        model,
      });
      this.onInput({ model });
    },

    changeModel({ model, id }) {
      const MODEL = cloneDeep(this.modelLocal);
      MODEL[id] = model;
      this.onInput({ model: MODEL });
    },

    onInput({ model, $event }) {
      this.input({
        currentModel: model,
        model: model,
        id: this.options.id,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
      this.change({
        currentModel: model,
        model: model,
        id: this.options.id,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
    },

    setIndexHighlight({ index }) {
      clearTimeout(this.timer);
      this.indexHighlight = index;
      this.timer = setTimeout(() => {
        this.indexHighlight = undefined;
      }, 3000);
    },
  },
};
