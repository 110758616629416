"use strict";
import angular from "angular";

import models from "../models";
import services from "../services";
// import listComponent from "./list.component";
import detailComponent from "./detail.component";

const modul = angular.module("dias.workflows.workflowfolgen", [
  models.name,
  services.name,
]);

modul.config($stateProvider => {
  $stateProvider

  // Liste der KAufgaben
    .state("root.workflowfolgen", {
      url: `/workflowfolgen/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-workflowfolgen-list></vue-workflowfolgen-list></div>"
        }
      },
      data: {
        permissions: "workflowfolge.ui"
      }
    })

    .state("root.workflowfolgen1", {
      url: `/workflowfolgen1/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-workflowfolgen-list></vue-workflowfolgen-list></div>"
        }
      },
      data: {
        permissions: "workflowfolge.ui"
      }
    })

    .state("root.workflowfolgen.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><vue-workflowfolgen-details></vue-workflowfolgen-details></div>"
        }
      },
    })

    .state("root.workflowfolgen1.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<workflowfolge-detail></workflowfolge-detail>"
        }
      },
    })

  ;
})

  // .component("kaufgabeList", listComponent)
  .component("workflowfolgeDetail", detailComponent)

;

export default modul;
