import AnalysenStatus from "../../Analysen/AnalysenStatus/AnalysenStatus.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import FunktionsgruppenAssignment from "../../Funktionsgruppen/FunktionsgruppenAssignment/FunktionsgruppenAssignment.vue";
import GeschaeftsregelAngebote from "./GeschaeftsregelAngebote/GeschaeftsregelAngebote.vue";
import GeschaeftsregelCopyButtonConfirm from "./GeschaeftsregelCopyButtonConfirm/GeschaeftsregelCopyButtonConfirm.vue";
import GeschaeftsregelDeleteButtonConfirm from "./GeschaeftsregelDeleteButtonConfirm/GeschaeftsregelDeleteButtonConfirm.vue";
import GeschaeftsregelDetailsModul from "./GeschaeftsregelDetailsModul/GeschaeftsregelDetailsModul.vue";
import GeschaeftsregelFunktionsgruppenBearbeitung from "./GeschaeftsregelFunktionsgruppenBearbeitung/GeschaeftsregelFunktionsgruppenBearbeitung.vue";
import GeschaeftsregelInfo from "./GeschaeftsregelInfo/GeschaeftsregelInfo.vue";
import GeschaeftsregelVorbelegung from "./GeschaeftsregelVorbelegung/GeschaeftsregelVorbelegung.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import GeschaeftsregelDetailsAPI from "./compositionAPI/GeschaeftsregelDetailsAPI";
import GeschaeftsregelModuleAPI from "./compositionAPI/GeschaeftsregelModuleAPI";

// @vue/component
export default {
  name: "GeschaeftsregelDetails",
  components: {
    AnalysenStatus,
    ContextPanel,
    FunktionsgruppenAssignment,
    GeschaeftsregelAngebote,
    GeschaeftsregelCopyButtonConfirm,
    GeschaeftsregelDeleteButtonConfirm,
    GeschaeftsregelDetailsModul,
    GeschaeftsregelFunktionsgruppenBearbeitung,
    GeschaeftsregelInfo,
    GeschaeftsregelVorbelegung,
    Permission,
    PuxButton,
    PuxPageDetails,
    PuxTranslate,
  },
  setup() {
    const {
      funktionsgruppen,
      loading,
      loadRegel,
      regel,
      regelUrl,
      updateFunktionsgruppen,
      updateRegel,
    } = GeschaeftsregelDetailsAPI();

    const {
      listConfig,
      modelConfig,
      moduleVorbelegungUrl,
      setModelConfig,
      setListConfig,
      updateRegelModule,
      updateVorbelegungModule,
    } = GeschaeftsregelModuleAPI({
      regel,
      regelUrl,
    });


    loadRegel().then(
      () => {
        setListConfig({
          regel: regel.value,
        });
        setModelConfig();
      }
    );

    return {
      funktionsgruppen,
      loading,
      regel,
      regelUrl,
      updateFunktionsgruppen,
      updateRegel,

      listConfig,
      modelConfig,
      moduleVorbelegungUrl,
      updateRegelModule,
      updateVorbelegungModule,
    };
  },
};
