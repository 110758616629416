import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";

// @vue/component
export default {
  name: "ImportauftragCreateModal",
  components: {
    Modal,
    PuxTranslate,
  },
  emits: [
    "closeModal",
  ],
  setup() {
    const {
      addNotification,
      postHttp,
    } = HttpAPI();

    return {
      addNotification,
      postHttp,
    };
  },
  data() {
    return {
      options: {
        showCloseButton: true,
        list: [
          {
            label: "_TXT_IJF_TEMPLATE_LIST_LABEL_NAME_",
            id: "ijv_kbez",
            type: "text",
            required: false,
            view: "v",
          },
        ],
      },
      model: {
        ijv_kbez: "",
      },
      promise: null,
    };
  },
  methods: {
    async save() {
      try {
        this.promise = this.postHttp({
          url: "ijf_vorlage/",
          data: this.model,
        });

        const createdVorlage = await this.promise;

        this.$goTo("root.ijf_template_ver.detail", { id: createdVorlage.versionen[0].pk });
      } catch (e) {
        this.addNotification({ text: "_TXT_IJF_TEMPLATE_LIST_CREATE_ERROR_MSG_", type: "error" });
        console.error(e);
      }
    },

    close() {
      this.$emit("closeModal");
    },
  },
};
