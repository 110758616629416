"use strict";

import BaseModel from "dias/core/models/baseModel";
import Objektaufgabe from "dias/workflow/models/objektaufgabe.service";

class Auszahlungsplan extends BaseModel {
  constructor() {
    super("auszahlungsplaene", {
      subPaths: {
        aufgaben: new Objektaufgabe(),
      }
    });
  }
  extendModel(model) {
    model = super.extendModel(model);
    model.createAuszahlung = () => model.customPOST({}, "auszahlung_erstellen");
    model.restangularizeAufgabe = aufgabe => this.Restangular.restangularizeElement(model, aufgabe, "aufgaben", {});
    return model;
  }
}

export default Auszahlungsplan;
