"use strict";

import BaseModel from "dias/core/models/baseModel";

class BaseRegelModel extends BaseModel {
  constructor(path, conf) {
    super(path, conf);
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.groupedByAnzeigekontext = kontexte => {
      const grouped = {};
      for (let i = 0; angular.isArray(kontexte) && i < kontexte.length; i++) {
        grouped[kontexte[i].anzeigekontext.pk] = [];
      }
      for (let i = 0; i < collection.length; i++) {
        const faz_id = angular.isObject(collection[i].anzeigekontext) ? collection[i].anzeigekontext.pk : collection[i].anzeigekontext;
        if (grouped[faz_id] === undefined) {
          if (angular.isArray(kontexte)) {
            continue;
          } else {
            grouped[faz_id] = [];
          }
        }
        grouped[faz_id].push(collection[i]);
      }
      return grouped;
    };
    return collection;
  }
}

export default BaseRegelModel;
