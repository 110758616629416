import EditMixin from "../EditMixin";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "SchaltflaecheEditChildren",
  mixins: [
    EditMixin,
  ],
  props: {
    antwort: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      default: () => ({}),
    },
    mehrfachauswahl: {
      type: Boolean,
      required: true,
    },
    labelCallback: {
      type: Function,
      required: true,
      info: "returns label mit oder ohne eine Sterne"
    },
    changeModel: {
      type: Function,
      required: true,
    },
  },
  computed: {
    isActiveObj() {
      if (!this.model.antworten ||
        !this.model.antworten[this.antwort.label] ||
        !this.model.antworten[this.antwort.label].children) {
        return {};
      }
      return this.model.antworten[this.antwort.label].children;
    },

    getRole() {
      return this.mehrfachauswahl ? "checkbox" : "radio";
    },
  },
  methods: {
    onClick(child) {
      let model = cloneDeep(this.model);
      model.antworten = model.antworten || {};
      model.antworten[this.antwort.label] = model.antworten[this.antwort.label] || {};
      if (!this.mehrfachauswahl) {
        model = this.setModelFalseForAll({ model });
      }
      model.antworten[this.antwort.label].children = model.antworten[this.antwort.label].children || {};
      model.antworten[this.antwort.label].children[child.label] = model.antworten[this.antwort.label].children[child.label] || {};
      model.antworten[this.antwort.label].children[child.label].status = !model.antworten[this.antwort.label].children[child.label].status;
      let activeChildrenCount = 0;
      for (const child in model.antworten[this.antwort.label].children) {
        if (model.antworten[this.antwort.label].children[child] && model.antworten[this.antwort.label].children[child].status) {
          activeChildrenCount += 1;
        }
      }
      if (!model.antworten[this.antwort.label].status || (activeChildrenCount == 0 && model.antworten[this.antwort.label].status)) {
        model.antworten[this.antwort.label].status = !model.antworten[this.antwort.label].status;
      }
      this.changeModel({ model: model });
    },
  },
};
