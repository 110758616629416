"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

const trackingModule = angular.module("core.tracking", [
  uiRouter,
]);

trackingModule.config($stateProvider => {
  $stateProvider

    .state("root.tracking", {
      url: "/tracking/?object&number&objects",
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><tracking-details></tracking-details></div>"
        }
      },
      data: {
        permissions: ["foerderantrag.view"]
      }
    });
});


export default trackingModule;
