import Modal from "../../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../../global/mixins/HttpMixin";

import {
  concat,
  filter,
  find,
  findIndex,
  forEach,
  keyBy,
  some,
} from "lodash-es";

const MITGLIED_UUID = "c429cf3a-62da-4103-b048-6f24b0437ceb";
const KONTEXT_FOERDERORG = 2;

// @vue/component
export default {
  name: "MitgliederModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    teamPk: {
      type: String,
      default: undefined,
    },
    organisationPk: {
      type: String,
      default: undefined,
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        foerderorgnutzer: []
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "foerderorgnutzer",
            type: "multiselect",
            label: "_LBL_TEAMS_DETAILS_MITGLIEDER_MODAL_CREATE_FOERDERORGNUTZER_",
            required: true,
            search: true,
            searchList: ["n_vorname", "n_nachname"],
            keyId: "pk",
            keyLabelCallback: ({ item }) => `${ item.n_vorname } ${ item.n_nachname }`,
            view: "v-alt",
            data: [],
            hideIf: true,
            helpText: undefined,
            disabled: undefined,
            translate: true,
          },
        ],
      },
      status: {
        loading: true,
      },
      errors: undefined,
      errorsClone: undefined,
      foerderorgnutzerObj: undefined,
      promisesAll: [],
    };
  },
  computed: {
    isDisabled() {
      return this.status.loading || !this.model.foerderorgnutzer.length;
    }
  },
  created() {
    this.loadDataForFoerderorgnutzer();
  },
  methods: {
    loadDataForFoerderorgnutzer() {
      this.getListHttp({
        url: `nutzer/?funktionsgruppe=teammitglied_werden&mit_kontext=true&organisation=${ this.organisationPk }&nicht_im_team=${ this.teamPk }`
      }).then(
        response => {
          const INDEX_FOERDERORGNUTZER = findIndex(this.options.list, ["id", "foerderorgnutzer"]);
          this.options.list[INDEX_FOERDERORGNUTZER].data = filter(response, item => some(item.kontexte, { kontext_typ: 2 }));
          this.options.list[INDEX_FOERDERORGNUTZER].hideIf = false;
          if (this.options.list[INDEX_FOERDERORGNUTZER].data && this.options.list[INDEX_FOERDERORGNUTZER].data.length === 0) {
            this.options.list[INDEX_FOERDERORGNUTZER].helpText = "_TXT_TEAMS_DETAILS_MITGLIEDER_MODAL_CREATE_FOERDERORGNUTZER_HELPTEXT_";
            this.options.list[INDEX_FOERDERORGNUTZER].disabled = true;
          }
          this.foerderorgnutzerObj = keyBy(response, "pk");
          this.status.loading = false;
        }
      );
    },

    onError({ error, nachname, vorname } = {}) {
      forEach(error, (item, key) => {
        error[key] = error[key] + ` (${ vorname } ${ nachname })`;
        if (this.errors) {
          this.errors[key] = this.errors[key] ? concat(this.errors[key], item) : item;
        } else {
          this.errors = error;
        }
      });
      this.errorsClone = error;
    },

    save() {
      this.errors = undefined;
      this.errorsClone = undefined;
      const INDEX_FOERDERORGNUTZER = findIndex(this.options.list, ["id", "foerderorgnutzer"]);
      this.options.list[INDEX_FOERDERORGNUTZER].disabled = true;
      this.status.loading = true;
      forEach(this.model.foerderorgnutzer, (foerderorgnutzer, index) => {
        const DATA = {
          rolle: MITGLIED_UUID,
          team: this.teamPk,
        };
        const NUTZER = find(this.foerderorgnutzerObj, function(foenutzer) {
          return foenutzer.pk === foerderorgnutzer;
        });
        forEach(NUTZER.kontexte, kontext => {
          if (kontext.kontext_typ === KONTEXT_FOERDERORG) {
            DATA.foerderorgnutzer = kontext.pk;
          }
        });
        const POST = this.postHttp({ url: `teams/${ this.teamPk }/mitglieder/`, data: DATA
        });
        this.promisesAll.push(POST);
        POST.then(
          () => {
            this.addNotification({ text: "_MSG_TEAMS_DETAILS_MITGLIEDER_MODAL_CREATE_SUCCESS_" });
            this.model.foerderorgnutzer.splice(index, 1);
          },
          error => {
            this.addNotification({ text: "_MSG_TEAMS_DETAILS_MITGLIEDER_MODAL_CREATE_ERROR_", type: "error" });
            this.onError({ error: error.data, vorname: NUTZER.n_vorname, nachname: NUTZER.n_nachname });
          }
        );
      });
      this.waitToClose();
    },
    waitToClose() {
      Promise.allSettled(this.promisesAll).then(
        () => {
          if (!this.errors) {
            this.close({ status: true });
          }
          this.status.loading = false;
          const INDEX_FOERDERORGNUTZER = findIndex(this.options.list, ["id", "foerderorgnutzer"]);
          this.options.list[INDEX_FOERDERORGNUTZER].disabled = false;
        }
      );
    }
  },
};
