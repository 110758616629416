"use strict";

import angular from "angular";

import ListenConstantDokumente from "./listenConstantDokumente.service";
import dokEvents from "./dokumentEvents.service";

const modelsModule = angular.module("dokumente.services", [
])

  .constant("ListenConstantDokumente", ListenConstantDokumente)
  .constant("DokumentEvents", dokEvents)
;

export default modelsModule;
