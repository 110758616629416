"use strict";

import BetragsrechnerBase from "dias/kosten_finanzierung/betragsrechner/betragsrechner.base";


class BetragsrechnerLand extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "finanzierung_mit_land_betragsrechner";
    this.component_name = "finanzierungmitland";
    this.betrag_editable = true;
    this.fields = {
      antrag: { wert: null,
                bezeichnung: "",
                land: "" },
      beleg: { wert: null }
    };
  }

  get_labelmapping() {
    return {
      wert: "Betrag in EUR",
      land: "Land",
      bezeichnung: "Bezeichnung"
    };
  }
}

export default BetragsrechnerLand;
