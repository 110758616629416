"use strict";

import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";

import builder from "dias/standard_foerdermodule/utils/builder";

const ast_registernummer = angular.module("dias.standard_foerdermodule.ast_registernummer", [
  foerdermoduleRegistry.name,
]);

ast_registernummer.config(FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("ast_registernummer", "modul-ast-registernummer");
})
  .directive("modulAstRegisternummer", builder.directive(template, controller));

export default ast_registernummer;
