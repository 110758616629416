import FormElementReadOnly from "../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import translate from "../../../../../global/directives/translate";
import {
  forEach,
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "DynamischeTabelleFooter",
  components: {
    FormElementReadOnly,
    SnapshotIcon,
  },
  directives: {
    translate,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    statusReadonly: {
      type: Boolean,
      required: true,
    },
    statusShowRowNumber: {
      type: Boolean,
      required: true,
    },
    labelRowNumber: {
      type: String,
      required: true,
    },
    spalten: {
      type: Array,
      required: true,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    statusSnapshot: {
      type: Boolean,
      required: false,
    },
    snapshotModule: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  computed: {
    footerSpalten() {
      const SPALTEN_FOOTER = cloneDeep(this.spalten);
      forEach(SPALTEN_FOOTER, spalte => {
        spalte.hideWarning = true;
      });
      return SPALTEN_FOOTER;
    },

    modelSum() {
      const MODEL_SUM = {};
      forEach(this.spalten, spalte => {
        if (spalte.sum) {
          const ID = spalte.id;
          MODEL_SUM[ID] = 0;
          forEach(this.model.elemente, item => {
            const SUM_LOCAL = +item[ID] || 0;
            MODEL_SUM[ID] += SUM_LOCAL;
          });
        }
      });
      return MODEL_SUM;
    },

    snapshotSumObj() {
      const SNAPSHOT_SUM_OBJ = {
        status: false,
        sum: {},
      };
      if (this.statusSnapshot &&
        this.snapshotModule &&
        this.snapshotModule.elemente) {
        forEach(this.spalten, spalte => {
          if (spalte.sum) {
            const ID = spalte.id;
            SNAPSHOT_SUM_OBJ.sum[ID] = 0;
            forEach(this.snapshotModule.elemente, item => {
              const SUM_LOCAL = +item[ID] || 0;
              SNAPSHOT_SUM_OBJ.sum[ID] += SUM_LOCAL;
            });

            if (this.modelSum[ID] !== SNAPSHOT_SUM_OBJ.sum[ID]) {
              SNAPSHOT_SUM_OBJ.status = true;
            }
          }
        });
      }
      return SNAPSHOT_SUM_OBJ;
    },

    rowspanFirstTd() {
      return this.snapshotSumObj.status ? 2 : 1;
    },

    tdSnapshotTranslate() {
      return {
        text: "_TXT_VERSION_MODIFIED_COLUMN_{{date}}_",
        extra: {
          date: this.snapshotDate,
        },
      };
    },
  },
};
