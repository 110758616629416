"use strict";

import BaseModel from "dias/core/models/baseModel";


class Registrieren extends BaseModel {
  constructor() {
    super("/auth/registrieren/");
  }
}

export default Registrieren;
