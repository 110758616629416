"use strict";

import template from "./details.jade";

import basedataEditTemplate from "./basisdaten.edit.jade";
import basedataEditController from "./basisdaten.edit.controller";

import ergebnisEditTemplate from "./ergebnis.edit.jade";
import ergebnisEditController from "./ergebnis.edit.controller";

import SaveCallbackActions from "../../../../vue/client/vue/const/SaveCallbackActions";

import {
  MAPPING,
} from "../../../../vue/client/vue/components/Geschaeftsregel/Module/Module";
import {
  cloneDeep,
  findIndex,
  forEach,
  get,
  isArray,
  isObject,
  isString,
} from "lodash-es";


class GutachtenDetailsController {
  /*@ngInject*/
  constructor(
    $q,
    $filter,
    $rootScope,
    $state,
    $stateParams,
    $timeout,
    $anchorScroll,
    $window,
    i18n,
    diasConfirmDialog,
    diasModalDialog,
    AuthService,
    Gutachten,
    Foerderantrag,
    DiasNotification,
    SyConfigs,
  ) {
    this.MAPPING = MAPPING;
    this.$q = $q;
    this.$filter = $filter;
    this.$state = $state;
    this.$timeout = $timeout;
    this.$anchorScroll = $anchorScroll;
    this.$window = $window;
    this.gettext = i18n.gettext;
    this.$stateParams = $stateParams;
    this.$rootScope = $rootScope;
    this.AuthService = AuthService;
    this.DiasNotification = DiasNotification;
    this.Gutachten = Gutachten;
    this.Foerderantrag = Foerderantrag;
    this.diasModalDialog = diasModalDialog;
    this.diasConfirmDialog = diasConfirmDialog;

    this.basedataEditTemplate = basedataEditTemplate;
    this.basedataEditController = basedataEditController;
    this.ergebnisEditTemplate = ergebnisEditTemplate;
    this.ergebnisEditController = ergebnisEditController;
    this.astAlsButtonConfig = SyConfigs.wert("ast_als_button") || {};

    this.initNotizenData();
    this.init();

    // Verlauf
    this.viewName = "details";
    this.timelineConfig = {
      kontexte: [
        {
          titel: this.gettext("Verwaltung"),
          key: "A",
        },
        {
          titel: this.gettext("Durchführung"),
          key: "F",
        },
      ],
      benachrichtigungen: {
        bezug: "gutachten",
        bezugpk: $stateParams.id,
        autorefresh: true,
      },
      url: `gutachten/${ $stateParams.id }/timeline/`,
    };

    // Workflow
    this.onAufgabeAngenommen = () => {
      this.$timeout(() => {
        this.loadGutachten();
      });
    };

    this.lastWfExecution = new Date().toISOString();

    this.reloadWorkflowfolgen = () => {
      this.lastWfExecution = new Date().toISOString();
    };

    this.updateModuleFromVue = this.updateModuleFromVue.bind(this);
    this.updateAufgabeFromVue = this.updateAufgabeFromVue.bind(this);
    this.onWorkflowInit = this.onWorkflowInit.bind(this);
    this.onWorkflowStarted = this.onWorkflowStarted.bind(this);
    this.onWorkflowFinish = this.onWorkflowFinish.bind(this);
    this.onWorkflowCanceled = this.onWorkflowCanceled.bind(this);
    this.onWorkflowSuccess = this.onWorkflowSuccess.bind(this);
    this.onWorkflowFailure = this.onWorkflowFailure.bind(this);
    this.saveCallback = this.saveCallback.bind(this);
  }

  initNotizenData() {
    this.notizOptionsVue = {
      fuerSitzung: false,
    };


    this.stepMap = [
      "uebersicht",
      "gutachten",
      "form"
    ];
    this.steps = [
      {
        key: "uebersicht",
        kbez: this.gettext("_LBL_GUTACHTEN_DETAILS_NOTIZEN_UEBERSICHT_")
      },
      {
        key: "gutachten",
        kbez: this.gettext("_LBL_GUTACHTEN_DETAILS_NOTIZEN_GUTACHTEN_")
      },
      {
        key: "form",
        kbez: this.gettext("_LBL_GUTACHTEN_DETAILS_NOTIZEN_FORMULAR_")
      }
    ];
  }

  init() {
    this.loading = {
      gutachten: true,
      module: true,
      objekt: true,
    };
    this.openGutachten();
    this.loadGutachten().then(
      () => this.loadGutachtenobjekt()
    ).finally(
      () => this.loading.gutachten = false
    );
    this.loadModule().finally(
      () => this.loading.module = false
    );
  }

  loadGutachtenobjekt() {
    let promise;
    this.loading.objekt = true;
    switch (this.ga.herkunft) {
    case "foerderantrag": {
      promise = this.Foerderantrag.one(this.ga.ga_objektid).get();
      break;
    }
    default: {
      const defer = this.$q.defer();
      promise = defer.promise;
      defer.resolve();
      break;
    }
    }
    return promise.then(
      response => this.objekt = response
    ).finally(() => this.loading.objekt = false);
  }

  updatePermissions() {
    this.permissions = this.AuthService.syncHasPerms([
      "gutachten.update",
      "gutachten.create",
      "gutachten.durchfuehren",
      "gutachten.pruefen",
    ], this.ga.user_permissions);
  }

  onValidate() {
    this.validationErrorsLoading = true;
    this.ga.customGET("validieren").then(
      result => {
        if (result && isArray(result)) {
          const errData = [];
          forEach(result, (modulErr, key) => {
            errData[key] = cloneDeep(modulErr);
            forEach(errData[key][1], _err => {
              forEach(_err.errors, (_errors, field) => {
                if (isObject(_errors) && !isArray(_errors)) {
                  _err.errors[field] = _errors;
                } else if (!isArray(_errors) || !isString(_errors[0])) {
                  _err.errors[field] = [this.gettext("Bitte prüfen Sie die Eingaben.")];
                }
              });
            });
          });
          this.validationErrors = errData;
        } else {
          this.validationErrors = result;
        }
      },
      () => this.DiasNotification.page.error("Fehler beim Prüfen des Gutachtens")
    ).finally(() => {
      this.validationErrorsLoading = false;
      this.scrollTop();
    });
  }

  resetValidation() {
    this.validationErrorsLoading = undefined;
    this.validationErrors = undefined;
  }

  saveCallback({ statusSaveCallback } = {}) {
    if (statusSaveCallback === SaveCallbackActions.RELOAD_WF) {
      this.reloadWorkflowfolgen();
    } else if (statusSaveCallback === SaveCallbackActions.RELOAD_ALL) {
      this.loadGutachten();
      this.reloadWorkflowfolgen();
    }
  }

  scrollTop() {
    $(window).scrollTop(0);
  }

  goToModul(modul) {
    this.openGutachten();
    this.$timeout(() => {
      this.$anchorScroll.yOffset = $(window).height() / 2;
      if (modul && modul.regelnummer) {
        this.$anchorScroll(modul.regelnummer);
      } else {
        this.$anchorScroll("gutachten-container");
      }
    }, 1000);
  }

  openGutachten() {
    this.gutachtenOpen = true;
  }

  loadGutachten() {
    return this.Gutachten.one(this.$stateParams.id).get().then(
      response => {
        this.ga = response;
        this.dokBaseUrl = `foerderantraege/${ this.ga.ga_objektid }/`;
        this.updatePermissions();
        this.setUrl();
      },
      error => this.DiasNotification.page.error(error, "Fehler beim Laden des Gutachtens")
    );
  }

  setUrl() {
    this.gaUrl = `gutachten/${ this.ga.pk }/`;
  }

  deleteGutachten() {
    this.diasConfirmDialog({
      titleTemplate: `${ this.gettext("Gutachten") } "${ this.ga.ga_nr } ${ this.ga.ga_kbez }" löschen`,
      contentTemplate: `<p>Sind Sie sicher, dass Sie {{ context.gettext("das Gutachten") }} "${ this.ga.ga_nr } ${ this.ga.ga_kbez }" löschen wollen?</p>`,
      context: { gutachten: this.ga, gettext: this.gettext },
      okLabel: `${ this.gettext("Gutachten") } "${ this.ga.ga_kbez }" löschen`,
      okCallback: () => this.ga.remove().then(
        () => {
          this.DiasNotification.page.success(`${ this.ga.ga_nr } ${ this.ga.ga_kbez } wurde gelöscht.`);
          this.$state.go("root.gutachten");
        },
        err => this.DiasNotification.page.error(err, `${ this.gettext("Gutachten") } konnte nicht gelöscht werden.`)
      ),
    });
  }

  savePdf() {
    this.Gutachten.one(this.$stateParams.id).withHttpConfig({ responseType: "blob" }).customGET("pdf").then(
      response => {
        const fileName = `gutachten_${ this.ga.ga_nr }_${ this.ga.ga_kbez }_entwurf.pdf`;
        if (this.$window.navigator.msSaveBlob) {
          this.$window.navigator.msSaveBlob(response, fileName);
        } else {
          const fileURL = (this.$window.URL || this.$window.webkitURL).createObjectURL(response);
          const aLink = this.$window.document.createElement("a");
          aLink.download = fileName;
          aLink.href = fileURL;
          aLink.target = "_self";
          const eventClick = this.$window.document.createEvent("MouseEvents");
          eventClick.initEvent(
            "click",
            true,
            true,
            this.$window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
          );
          aLink.dispatchEvent(eventClick);
        }
      },
      err => this.DiasNotification.page.error(err, "Fehler beim erstellen des Dokuments")
    );
  }


  loadModule() {
    return this.Gutachten.one(this.$stateParams.id).module.getList().then(
      response => {
        this.module = response;
      }
    );
  }

  getPdfUrl() {
    if (this.ga) {
      const url = this.ga.getRequestedUrl();
      return `${ url }${ (url.endsWith("/") ? "" : "/") }pdf/`;
    }
  }

  changeView() {
    this.viewName = this.viewName === "details" ? "timeline" : "details";
  }

  updateModuleFromVue({ response }) {
    this.$timeout(() => {
      const INDEX = findIndex(this.module, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.module.splice(INDEX, 1, response);
      }
    });
  }

  updateAufgabeFromVue(newAufgabe) {
    this.$timeout(() => {
      this.updateAufgabe(newAufgabe);
    });
  }

  updateAufgabe(newAufgabe) {
    Object.assign(this.ga.aktuelle_aufgabe, newAufgabe);
  }

  getWorkflowfolgeUpdateKey() {
    if (this.ga) {
      return `${ this.lastWfExecution }`;
    }
  }

  onWorkflowInit(clientFunktion) {
    this.$timeout(() => {
      this.clientFunktion = clientFunktion;
    });
  }

  onWorkflowStarted(newAufgabe, currentWorkflowfolge) {
    this.$timeout(() => {
      this.updateAufgabe(newAufgabe);
      this.currentWorkflowfolge = currentWorkflowfolge;
    });
  }

  onWorkflowFinish() {
    this.$timeout(() => {
      this.clientFunktion = undefined;
      this.currentWorkflowfolge = undefined;
    });
  }

  onWorkflowCanceled() {
    this.$timeout(() => {
      this.reload = false;
    });
  }

  onWorkflowSuccess(aufgabe) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerSuccess(aufgabe);
      }
      this.resetValidation();
      this.scrollTop();
      if (get(this.currentWorkflowfolge, "wfo_reload")) {
        return this.loadGutachten();
      }
    });
  }

  onWorkflowFailure(err) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerError(err);
      }
      if (err.data && err.data.error_data) {
        this.validationErrors = err.data.error_data;
        this.validationErrorsLoading = false;
      } else if (err.data && !angular.isString(err.data)) {
        this.validationErrors = err.data;
        this.validationErrorsLoading = false;
      } else {
        this.resetValidation();
      }
      this.scrollTop();
      return this.loadGutachten();
    });
  }
}

export default {
  template: template(),
  controller: GutachtenDetailsController,
  controllerAs: "vm"
};
