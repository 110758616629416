"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import antragslisten from "./antragslisten";

const modul = angular.module("dias.foerderantrag.cortexe", [
  uiRouter,
  antragslisten.name
])
;

export default modul;
