import {
  setPositionForActionsInDetails,
} from "../../global/functions/utilsDOM";

// @vue/component
export default {
  name: "TheGlobal",
  setup() {
    const createContainerById = id => {
      let container = document.getElementById(id);
      if (!container) {
        container = document.createElement("div");
        container.id = id;
        container.className = "popup_container";
        container.ariaHidden = "true";
        document.body.appendChild(container);
      }
    };

    const setScrollEvent = () => {
      // TODO: Das muss gelöscht werden, wenn alle Details-Seiten in vue.js werden
      $(document).scroll(function() {
        setPositionForActionsInDetails($(this));
      });
    };

    createContainerById("tooltip_container");
    createContainerById("select_container");
    setScrollEvent();
    return {};
  },
  render() {
    return "";
  },
};
