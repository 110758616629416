"use strict";
import angular from "angular";

import registry from "./registry.service";
import editor from "./editor.service";
import KWorkflowtypUUIDs from "./kworkflowtypUUIDs.service";
import KWorkflowstatusgrupppenUUIDs from "./kworkflowstatusgruppentypUUIDs.service";
import KAufgabenstatusUUIDs from "./kaufgabenstatusUUIDs.service";
import AufgabenherkunftIcons from "./aufgabenherkunftIcons.service";

const modul = angular.module("workflow.functions.services", [
])

  .provider("WorkflowfunktionRegistry", registry)
  .service("workflowService", editor)

  .constant("KWorkflowtypUUIDs", KWorkflowtypUUIDs)
  .constant("KWorkflowstatusgrupppenUUIDs", KWorkflowstatusgrupppenUUIDs)
  .constant("KAufgabenstatusUUIDs", KAufgabenstatusUUIDs)
  .constant("AufgabenherkunftIcons", AufgabenherkunftIcons)

;

export default modul;
