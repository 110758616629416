import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

// @vue/component
import { getTextFromPlaceholder } from "../../../../global/functions/utils";
import {
  forEach,
  toString
} from "lodash-es";

// @vue/component
export default {
  name: "AufgabenvorlagenModalCopy",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  props: {
    kAufgabe: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      postHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      postHttp,
    };
  },
  data() {
    return {
      model: {
        au_kbez: undefined,
        au_kbez_admin: undefined,
        au_kbezbeobachter: undefined,
        au_kbezdetail: undefined,
      },
      loading: false,
      errors: undefined,
      errorsClone: undefined,
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "au_kbez",
            type: "text",
            label: "_LBL_AUFGABENVORLAGENDETAILS_KURZBESCHREIBUNG_",
            required: true,
            view: "v-alt",
          },
          {
            id: "au_kbez_admin",
            type: "text",
            label: "_LBL_AUFGABENVORLAGENDETAILS_KURZBESCHREIBUNG_ADMINISTRATION_",
            required: true,
            view: "v-alt",
          },
          {
            id: "au_kbezbeobachter",
            type: "text",
            label: "_LBL_AUFGABENVORLAGENDETAILS_KURZBESCHREIBUNG_BEOBACHTER_",
            required: true,
            view: "v-alt",
          },
          {
            id: "au_kbezdetail",
            type: "text",
            label: "_LBL_AUFGABENVORLAGENDETAILS_KURZBESCHREIBUNG_DETAILLIERT_",
            required: true,
            view: "v-alt",
          },
        ],
      },
    };
  },
  computed: {

  },
  created() {
    this.setModel();
  },
  methods: {
    setModel() {
      this.model.au_kbez = `${ this.kAufgabe.au_kbez } ${ getTextFromPlaceholder("_TXT_AUFGABENVORLAGENDETAILS_COPY_SUFFIX_") }`;
      this.model.au_kbez_admin = `${ this.kAufgabe.au_kbez_admin } ${ getTextFromPlaceholder("_TXT_AUFGABENVORLAGENDETAILS_COPY_SUFFIX_") }`;
      this.model.au_kbezbeobachter = `${ this.kAufgabe.au_kbezbeobachter } ${ getTextFromPlaceholder("_TXT_AUFGABENVORLAGENDETAILS_COPY_SUFFIX_") }`;
      this.model.au_kbezdetail = `${ this.kAufgabe.au_kbezdetail } ${ getTextFromPlaceholder("_TXT_AUFGABENVORLAGENDETAILS_COPY_SUFFIX_") }`;
    },

    save() {
      this.loading = true;
      const DATA = {};
      forEach(this.model, (item, key) => {
        DATA[key] = toString(item).trim();
      });
      return this.postHttp({
        url: `${ this.baseUrl }kopieren/`,
        data: DATA,
      }).then(
        response => {
          this.addNotification({ text: "_MSG_AUFGABENVORLAGENDETAILS_CREATE_COPY_SUCCESS_" });
          this.close();
          this.$goTo("root.kaufgaben.detail", { id: response.pk });
        },
        errors => {
          this.onError({ error: errors.data });
        },
      ).finally(
        () => this.loading = false,
      );
    },

    onError({ error }) {
      this.errors = error;
      this.errorsClone = error;
    },
  },
};
