import BelegeTd from "../BelegeTd/BelegeTd.vue";
import BelegModalDelete from "../BelegModalDelete/BelegModalDelete.vue";
import BelegModalEdit from "../BelegModalEdit/BelegModalEdit.vue";
import BelegModalAnerkennenAblehnenZuruecksetzen
  from "../BelegModalAnerkennenAblehnenZuruecksetzen/BelegModalAnerkennenAblehnenZuruecksetzen.vue";
import translate from "../../../../../global/directives/translate";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "BelegeTr",
  components: {
    BelegeTd,
    BelegModalDelete,
    BelegModalEdit,
    BelegModalAnerkennenAblehnenZuruecksetzen,
    PuxButton,
  },
  directives: {
    translate,
  },
  props: {
    position: {
      type: Object,
      required: true,
    },
    person: {
      type: Object,
      required: true,
    },
    belegeObj: {
      type: Object,
      required: true,
    },
    periodenAll: {
      type: Array,
      required: true,
    },
    periodenColsWidth: {
      type: Object,
      required: true,
    },
    canAnerkennenBeleg: {
      type: Boolean,
      required: true,
    },
    canAblehnenBeleg: {
      type: Boolean,
      required: true,
    },
    canUpdateBeleg: {
      type: Boolean,
      required: true,
    },
    canDeleteBeleg: {
      type: Boolean,
      required: true,
    },
    vertrag: {
      type: Object,
      required: true,
    },
    vertragkosten: {
      type: String,
      required: true,
    },
    periodeTeilForPeriode: {
      type: Object,
      required: true,
    },
    positionenPeriodenObj: {
      type: Object,
      required: true,
    },
    documentsForBelege: {
      type: Object,
      required: true,
    },
    durchfuehrung: {
      type: Object,
      required: true,
    },
    deleteBelegForPosition: {
      type: Function,
      required: true,
    },
    updateBelegForPosition: {
      type: Function,
      required: true,
    },
    updateDokumentForBelege: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modalDeleteBeleg: undefined,
      functionNameAnerkennenAblehnenZuruecksetzen: undefined,
      modalAnerkennenAblehnenZuruecksetzenBeleg: undefined,
      modalEditBeleg: undefined,
    };
  },
  computed: {
    belegeForPositionForPerson() {
      if (this.belegeObj[this.position.position] && this.belegeObj[this.position.position][this.person.pk]) {
        return this.belegeObj[this.position.position][this.person.pk];
      }
      return undefined;
    },
  },
  methods: {
    openModalDeleteBeleg() {
      this.modalDeleteBeleg = true;
    },

    closeModalDeleteBeleg({ status, beleg } = {}) {
      if (status) {
        this.deleteBelegForPosition({ beleg });
      }
      this.modalDeleteBeleg = false;
    },

    openModalAnerkennenAblehnenZuruecksetzenBeleg({ functionsName }) {
      this.functionNameAnerkennenAblehnenZuruecksetzen = functionsName;
      this.modalAnerkennenAblehnenZuruecksetzenBeleg = true;
    },

    closeModalAnerkennenAblehnenZuruecksetzenBeleg({ status, beleg } = {}) {
      if (status) {
        this.updateBelegForPosition({ beleg });
      }
      this.modalAnerkennenAblehnenZuruecksetzenBeleg = false;
    },

    openModalEditBeleg() {
      this.modalEditBeleg = true;
    },

    closeModalEditBeleg({ status, beleg, dokument } = {}) {
      if (status) {
        this.updateBelegForPosition({ beleg });
        this.updateDokumentForBelege({ dokument });
      }
      this.modalEditBeleg = false;
    },
  },
};
