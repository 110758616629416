"use strict";

import BaseModel from "./baseModel";

class SysInfo extends BaseModel {
  constructor() {
    super("/meta/sysinfo");
  }
}

export default SysInfo;
