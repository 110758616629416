import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungBelegSummenBox",
  components: {
    AsideRightBox,
    PuxTranslate,
  },
  props: {
    auszahlung: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      filterCurrency,
    };
  },
  data() {
    return {
      optionsAsideRightBox: {
        icon: "salden",
        toggle: false,
      },
    };
  },
  computed: {
    betragGeprueftClass() {
      return this.betragGeprueft > 0 ? "text-color-success" : "text-color-remove";
    },

    betragGeprueftCurrency() {
      return this.filterCurrency(this.betragGeprueft);
    },

    betragGeprueft() {
      return get(this.auszahlung, "uebersicht.betrag_geprueft");
    },

    betragUngeprueftClass() {
      return this.betragUngeprueft > 0 ? "text-color-success" : "text-color-remove";
    },

    betragUngeprueftCurrency() {
      return this.filterCurrency(this.betragUngeprueft);
    },

    betragUngeprueft() {
      return get(this.auszahlung, "uebersicht.betrag_ungeprueft");
    },

    betragSumClass() {
      return this.betragSum > 0 ? "text-color-success" : "text-color-remove";
    },

    betragSumCurrency() {
      return this.filterCurrency(this.betragSum);
    },

    betragSum() {
      return parseFloat(this.betragGeprueft || 0) + parseFloat(this.betragUngeprueft || 0);
    },
  },
};
