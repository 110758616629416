var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["required-form-group"] = jade_interp = function(field, labelText, pos){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors1['" + ( field ) + "' + " + ( pos ) + "]}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors1['" + ( field ) + "' + " + ( pos ) + "]", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors1['" + ( field ) + "' + " + ( pos ) + "]", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<div dias-cloak=\"vm.foerderorgTypen.length &lt; 1\" cloak-msg=\"' '\"><div ng-repeat=\"typ in vm.foerderorgTypen\"><div ng-if=\"$index === 0 || vm.foerderorgTypen[$index -1].selected.length &gt; 0 &amp;&amp; vm.foerderorgTypen[$index -1].selected[0].id !== 'null'\"><div ng-if=\"typ.data.length &gt; 0\">");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<dias-select ng-attr-name=\"foerderorganisation_ebene_{{$index}}\" id=\"foerderorganisation_ebene_{{$index}}\" selected=\"vm.foerderorgTypen[$index].selected\" select-title=\"vm.foerderorgTypen[$index].selected[0].label\" options=\"vm.foerderorgTypen[$index].data\" data-disabled=\"vm.loading\" settings=\"vm.selectSettings[$index]\" on-change=\"vm.changeChildFoerderorg($index)\" aria-required=\"true\" role=\"listbox\" empty-label=\"{{ vm.emptyLabels[$index] }}\" empty-value=\"\"></dias-select><div ng-if=\"$index === 1\"><span class=\"form-text text-muted\">{{vm.getLandesverbandHilfeText()}}</span></div>");
}
}, "foerderorganisation_", "{{ typ.bez }}", "typ.pos");
buf.push("</div></div></div><label ng-if=\"vm.loading\" class=\"font-weight-bold\">Organisationsdaten werden geladen ...</label></div>");;return buf.join("");
}