"use strict";

import "restangular";
import angular from "angular";

import Dokumentation from "./dokumentation.service";

const modelsModule = angular.module("hilfe.models", [
  "restangular",
])

  .provider("Dokumentation", Dokumentation)
;

export default modelsModule;
