import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import ModuleMixin from "../ModuleMixin";

import {
  cloneDeep,
  forEach,
  get,
  isEmpty,
  isEqual,
  isNil,
  map,
  size
} from "lodash-es";


// @vue/component
export default {
  name: "AntragBudgetkonto",
  components: {
    FormElement,
    FormElementReadOnly,
    PuxCloak,
    PuxTranslate,
    SnapshotModule,
    SnapshotIcon,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      bdiMap: {},
      bdiLabels: {},
      status: {
        init: false
      }
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.list = this.formFields;
      OPTIONS.showReadonlyInEdit = true;
      return OPTIONS;
    },

    formFields() {
      const K_PARAM = this.modelParameter.konto || {};
      const D_PARAM = this.modelParameter.dimensionen || [];
      const OPTIONS = [
        {
          id: "konto",
          htmlId: `${ this.htmlRegelNummer }_konto`,
          type: "select",
          required: true,
          editonly: true,
          label: "_LBL_ANTRAG_BUDGETKONTO_KONTO_",
          showLabel: true,
          translate: true,
          url: `katalog/?key=fibu_konto`,
          apiSaveId: "fibu_konto",
          keyId: "id",
          keyLabel: "bez",
          search: true,
          searchList: ["bez"],
          readonly: !K_PARAM.bearbeitbar,
          view: "v"
        }
      ];
      forEach(D_PARAM, config => {
        if (isNil(this.bdiMap[config.dimension])) {
          return;
        }
        const BDI = this.bdiMap[config.dimension];
        OPTIONS.push({
          id: `dimension_${ BDI.pk }`,
          htmlId: `${ this.htmlRegelNummer }_dimension_${ BDI.pk }`,
          type: "select",
          required: config.pflichtfeld,
          editonly: true,
          label: BDI.bez,
          showLabel: true,
          translate: true,
          url: `katalog/?key=${ BDI.bdi_katalog }`,
          apiSaveId: BDI.bdi_katalog,
          keyId: "id",
          keyLabel: "bez",
          search: true,
          searchList: ["bez"],
          readonly: !config.bearbeitbar,
          view: "v"
        });
      });
      return OPTIONS;
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(this.formFields, item => {
          const KEY = item.id;
          if (!isEqual(get(this.model, KEY), get(this.snapshotModule, KEY))) {
            DIFF[KEY] = true;
          }
        });
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },
  },
  methods: {
    initDataLocal({ statusFirstLoad }) {
      if (statusFirstLoad) {
        this.loadData();
      }
    },

    loadData() {
      const BDI_IDS = map(this.modelParameter.dimensionen || [], "dimension");
      if (size(BDI_IDS) === 0) {
        return;
      }
      this.status.init = true;
      this.getHttp({
        url: "katalog/?key=fibu_buchungsdimension",
        urlParams: {
          pk: BDI_IDS,
        },
        apiSaveId: "fibu_buchungsdimension"
      }).then(
        response => {
          this.bdiMap = {};
          forEach(response, bdi => {
            this.bdiMap[bdi.pk] = bdi;
            this.bdiLabels[`dimension_${ bdi.pk }`] = bdi.bez;
          });
        }
      ).finally(() => this.status.init = false);
    },
  },
};
