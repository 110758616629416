import HttpMixin from "../../../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../../../global/mixins/PermissionMixin";
import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import {
  map,
  pick,
} from "lodash-es";

// @vue/component
export default {
  name: "NameUndModul",
  components: {
    FormstepDetail,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    serverfunktion: {
      type: Object,
      required: true,
    },
    updateServerfunktion: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      options: {
        label: "_TXT_SERVERFUNKTION_DETAILS_NAME_UND_MODUL_PANEL_FORMSTEP_NAME_",
        info: "_HTML_SERVERFUNKTION_DETAILS_NAME_UND_MODUL_PANEL_FORMSTEP_INFO_",
        list: [
          {
            id: "wfs_bez",
            type: "text",
            label: "_LBL_SERVERFUNKTION_DETAILS_BEZEICHNUNG_",
            showLabel: true,
            view: "v",
            required: true,
            translate: true,
          },
          {
            id: "serverfunktion",
            type: "select",
            label: "_LBL_SERVERFUNKTION_DETAILS_MODUL_",
            url: "katalog/?key=serverfunktion_module",
            showLabel: true,
            view: "v",
            keyId: "pk",
            keyLabel: "bez",
            required: true,
            search: true,
            searchList: ["bez"],
            translate: true,
          },
        ],
        required: true,
        saveCallback: this.save,
      },
      canEdit: undefined,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.canEdit = this.checkPermissionsSync({ perm: "serverfunktion.update" });
    },
    save({ model }) {
      const DATA = pick(model, map(this.options.list, option => option.id));
      return this.patchHttp({
        url: `serverfunktionen/${ this.serverfunktion.pk }/`,
        data: DATA,
      }).then(
        response => {
          this.updateServerfunktion({ serverfunktion: response });
          this.addNotification({
            text: "_MSG_SERVERFUNKTION_DETAILS_NAME_UND_MODUL_UPDATE_{{bez}}_",
            extra: {
              frage: this.serverfunktion.wfs_bez,
            },
          });
        },
      );
    },
  },
};
