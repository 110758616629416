import WorkflowModulFormMixin from "./WorkflowModulFormMixin";

import {
  cloneDeep,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "WorkflowModulForm",
  mixins: [
    WorkflowModulFormMixin,
  ],
  created() {
    this.startModul();
    this.initData();
    this.setErrorLabels();
  },
  methods: {
    initData() {
      this.parameterIn = this.prepareParameterIn();
      this.parameterOut = get(this.workflowfolge, "clientfunktion.wfc_parameterout.formular.wert", {});
      this.localParameters = cloneDeep(this.parameterOut);
    },
  },
};
