"use strict";

import models from "./models";

import diasRegistrationComponent from "./registration.component";
import diasRegistrierungAbschliessenComponent from "./registrierung-abschliessen.component";
import diasResetPasswordComponent from "./reset-password.component";
import diasResetPasswordAbschliessenComponent from "./reset-password-abschliessen.component";
import diasEmailChangeComponent from "./email-change.component";
import diasPasswordChangeComponent from "./change-password.component";
import loginTemplate from "./login.jade";
import loginController from "./login.controller";
import loginBoxTemplate from "./login.box.jade";

const diasRegistration = angular.module("dias.registration", [
  models.name
])

  .component("diasLogin", {
    bindings: {
      passwortAbgelaufenStatus: "=?",
      regBody: "<?",
      regClass: "<?",
      regHeader: "<?",
      regFooter: "<?",
      regLinkHide: "<?",
      rightBox: "<?", // Die Infobox ist rechts neben der Anmeldebox (Objekt)
      extra: "<?",
      templateDown: "<?",
      templateRightClass: "<?",
      isPage: "<?",
    },
    template: loginTemplate(),
    controller: loginController,
    controllerAs: "vm",
  })
  .component("diasLoginBox", {
    bindings: {
      regFooter: "<?",
      regLinkHide: "<?"
    },
    template: loginBoxTemplate(),
    controller: loginController,
    controllerAs: "vm",
  })
  .component("diasRegistration", diasRegistrationComponent)
  .component("diasRegistrierungAbschliessen", diasRegistrierungAbschliessenComponent)
  .component("diasResetPassword", diasResetPasswordComponent)
  .component("diasResetPasswordAbschliessen", diasResetPasswordAbschliessenComponent)
  .component("diasEmailChange", diasEmailChangeComponent)
  .component("diasPasswordChange", diasPasswordChangeComponent);

export default diasRegistration;
