"use strict";

import BaseModel from "dias/core/models/baseModel";

class FAQs extends BaseModel {
  constructor() {
    super("faqs");
  }
}

export default FAQs;
