import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import ModelMapping from "../../../const/ModelMapping";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "AufgabeListDetails",
  components: {
    AngularLink,
    PuxCloak,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const {
      filterDate,
      filterCurrency,
      filterDefaultForEmpty,
      filterSpaced,
    } = FiltersAPI();

    return {
      filterDate,
      filterCurrency,
      filterDefaultForEmpty,
      filterSpaced,
    };
  },
  data() {
    return {
      object: undefined,
      statusLoading: true,
      statusError: undefined,
    };
  },
  computed: {
    getHrefSimple() {
      return (name, pk) => {
        return `/${ name }/${ pk }/`;
      };
    },

    getAntragNr() {
      return this.filterSpaced(this.object.a_nr);
    },

    getVertragNr() {
      return this.filterSpaced(this.object.v_nr);
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getHttp({ url: `${ ModelMapping[this.row.herkunft] }/${ this.row.obj_id }/` }).then(
        response => {
          this.object = response;
        },
        () => {
          this.addNotification({ text: "_MSG_AUFGABE_TABLE_DETAILS_LOAD_ERROR_", type: "error" });
          this.statusError = true;
        }
      ).then(
        () => this.statusLoading = false
      );
    },
  },
};
