var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :ctx.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "ctx.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in ctx.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
jade_mixins["form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "ctx.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in ctx.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<div class=\"modal-body\"><div class=\"row\"><section class=\"main col-10 offset-1\"><div ng-attr-id=\"{{ ctx.DiasNotification.form.containerId }}\"></div><form><fieldset>");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"ffa_text\" ng-model=\"ctx.antwort.ffa_text\" type=\"text\" name=\"ffa_text\" maxlength=\"250\" aria-required=\"true\" ng-attr-aria-invalid=\"{{ctx.errors.ffa_text ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "ffa_text", "Text");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"ffa_info\" ng-model=\"ctx.antwort.ffa_info\" type=\"text\" name=\"ffa_info\" ng-attr-aria-invalid=\"{{ctx.errors.ffa_info ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "ffa_info", "Info");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"ffa_ueberschrift\" ng-model=\"ctx.antwort.ffa_ueberschrift\" type=\"text\" name=\"ffa_ueberschrift\" maxlength=\"250\" ng-attr-aria-invalid=\"{{ctx.errors.ffa_ueberschrift ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "ffa_ueberschrift", "Überschrift");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"ffa_erklaerung\" ng-model=\"ctx.antwort.ffa_erklaerung\" type=\"text\" name=\"ffa_erklaerung\" ng-attr-aria-invalid=\"{{ctx.errors.ffa_erklaerung ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "ffa_erklaerung", "Erklärung");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<ui-select ng-model=\"ctx.antwort.typ\" aria-required=\"true\"><ui-select-match placeholder=\"Wählen Sie den Typ der Antwort aus.\"><span ng-bind=\"$select.selected.fft_bez\"></span></ui-select-match><ui-select-choices repeat=\"item.pk as item in (ctx.antworttypen | filter: $select.search)\"><span ng-bind=\"item.fft_bez\"></span></ui-select-choices></ui-select>");
}
}, "typ", "Antworttyp");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<ui-select ng-model=\"ctx.antwort.folgefrage\" aria-required=\"true\"><ui-select-match placeholder=\"Wählen Sie die Folgefrage aus.\"><span ng-bind=\"$select.selected.ffr_text\"></span></ui-select-match><ui-select-choices repeat=\"item.pk as item in (ctx.folgefragen | filter: $select.search)\"><span ng-bind=\"item.ffr_text\"></span></ui-select-choices></ui-select>");
}
}, "folgefrage", "Folgefrage");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"ffa_pos\" ng-model=\"ctx.antwort.ffa_pos\" type=\"text\" name=\"ffa_pos\" ng-attr-aria-invalid=\"{{ctx.errors.ffa_pos ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "ffa_pos", "Position");
buf.push("</fieldset></form></section></div></div><div class=\"modal-footer\"><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"exitWithCallback(ctx.create)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"exit()\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-default\"><span>Abbrechen</span></button></div></div></div>");;return buf.join("");
}