import AuszahlungErrorsBlockItem from "../AuszahlungErrorsBlockItem/AuszahlungErrorsBlockItem.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  scrollToGlobal,
} from "../../../../../global/functions/help";
import {
  EventBus,
} from "../../../../../global/functions/event-bus";
import {
  get,
} from "lodash-es";
import {
  getTextFromPlaceholder,
} from "../../../../../global/functions/utils";

// @vue/component
export default {
  name: "AuszahlungErrorsBlock",
  components: {
    AuszahlungErrorsBlockItem,
    PuxTranslate,
  },
  props: {
    entry: {
      type: Array,
      required: true,
    },
    rubricsMapping: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  computed: {
    extraNameForTranslate() {
      return {
        name: this.rubricName,
      };
    },

    rubricName() {
      const RUBRIC_NAME = get(this.rubricsMapping, `[${ this.entry[0].key }].kbez`);
      return getTextFromPlaceholder(RUBRIC_NAME);
    },
  },
  methods: {
    goTo({ regelnummer } = {}) {
      const RUBRIC_ID = this.entry[0].key;
      EventBus.$emit("toggleContextPanel", {
        statusOpen: true,
        id: RUBRIC_ID,
      });
      if (regelnummer) {
        scrollToGlobal(`#${ regelnummer }`);
      } else {
        scrollToGlobal(`#${ RUBRIC_ID }`);
      }
    },
  },
};
