"use strict";

import BaseModel from "dias/core/models/baseModel";


class Logout extends BaseModel {
  constructor() {
    super("/auth/logout/");
  }
  extendCollection(collection) {
    collection = super.extendCollection(collection);
    const POST = collection.post;
    collection.post = () => POST().then(response => {
      window.statusShowPlaceholdersTranslate = false;
      return response;
    });
    return collection;
  }
}

export default Logout;
