import {
  LIcon,
  LMarker,
} from "../../../../global/components/PuxLeaflet";

import {
  cloneDeep
} from "lodash-es";

// @vue/component
export default {
  components: {
    LIcon,
    LMarker,
  },
  data() {
    return {
      markerLatLng: [50, 10],
      markerIcon: {
        size: [30, 30],
        anchor: [15, 15]
      },
      markersEditable: [],
      statusMarkersEditableDrugStart: undefined,
      currentAddressTypeInDrug: undefined,
      grabbingPk: undefined,
      activeMarkerEditableIndex: undefined,
    };
  },
  computed: {
    getAssetUrl() {
      return img => {
        if (img) {
          return `/api/assets/${ img }`;
        }
        return `/api/assets/map-icon-default.png`;
      };
    },

    getKeyMarkersEditable() {
      return ({ markerIndex }) => `marker_editable_${ markerIndex }`;
    },

    getKeyMarkers() {
      return ({ markerIndex }) => `marker_${ markerIndex }`;
    },

    getUrlForMarkerIconEditable() {
      return type => {
        if (!this.adresstypObj[type] || !this.adresstypObj[type].adt_bild) {
          return this.getAssetUrl("map-icon-default.png");
        }
        return this.getAssetUrl(this.adresstypObj[type].adt_bild);
      };
    },
  },
  methods: {
    initMarkersEditable() {
      if (this.model.adressen) {
        this.markersEditable = cloneDeep(this.model.adressen);
      }
    },

    mouseupMap(arg) {
      if (this.statusMarkersEditableDrugStart) {
        this.markersEditable.push({
          adr_geokoord: [
            arg.latlng.lat,
            arg.latlng.lng,
          ],
          typ: this.currentAddressTypeInDrug.pk,
        });
      }
    },

    updateLatLngMarkerEditable(arg, markerIndex, type) {
      this.markersEditable.splice(markerIndex, 1, {
        adr_geokoord: [
          arg.lat,
          arg.lng,
        ],
        typ: type,
      });
    },

    dragstart(addressType) {
      this.statusMarkersEditableDrugStart = true;
      this.grabbingPk = addressType.pk;
      this.currentAddressTypeInDrug = addressType;
    },

    ondrop($event) {
      this.simulateClick($event);
      this.statusMarkersEditableDrugStart = false;
      this.grabbingPk = undefined;
    },

    dragend() {
      this.statusMarkersEditableDrugStart = false;
      this.grabbingPk = undefined;
    },

    simulateClick(event) {
      const EVT = new MouseEvent("mouseup", {
        clientX: event.clientX,
        clientY: event.clientY,
      });
      this.$refs.map.$el.dispatchEvent(EVT);
    },

    deleteMarkersEditable() {
      this.markersEditable = [];
    },

    deleteCurrentMarkerEditable() {
      this.markersEditable.splice(this.activeMarkerEditableIndex, 1);
      this.setActiveMarkerEditableIndexUndefined();
    },

    onClickMarkerEditable(arg, index) {
      this.activeMarkerEditableIndex = index;
    },

    setActiveMarkerEditableIndexUndefined() {
      this.activeMarkerEditableIndex = undefined;
    },

    onContextmenu(arg) {
      arg.originalEvent.preventDefault();
    },
  },
};
