var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["required-form-group"] = jade_interp = function(errors, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :" + (errors) + "}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "" + (errors) + "", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errors) + "", true, true)) + ">{{ err }}</li></ul></span></label>");
};
jade_mixins["form-group"] = jade_interp = function(errors, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :" + (errors) + "}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "" + (errors) + "", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errors) + "", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<div class=\"modal-body\"><p>Bitte geben Sie das neue Ende-Datum des Gültigkeitszeitraums an:</p><div class=\"row\"><div class=\"col-12 col-sm-6\">");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input ng-model=\"ctx.model.ars_gueltig_ab\" name=\"ars_gueltig_ab\" type=\"text\" bs-datepicker disabled aria-required=\"true\" ng-attr-aria-invalid=\"{{ctx.errors.ars_gueltig_ab ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "ctx.errors[ars_gueltig_ab]", "Gültig ab");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input ng-model=\"ctx.model.ars_gueltig_bis\" name=\"ars_gueltig_bis\" placeholder=\"unbegrenzt\" type=\"text\" bs-datepicker ng-attr-aria-invalid=\"{{ctx.errors.ars_gueltig_bis ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "ctx.errors.ars_gueltig_bis", "Gültig bis");
buf.push("</div></div></div><div class=\"modal-footer\"><div class=\"form-footer\"><div class=\"required\"></div><div class=\"form-actions\"><button ng-click=\"exitWithCallback(ctx.ok)\" ng-disabled=\"loading\" class=\"btn btn-success\"><span>Ende-Datum ändern</span></button><button ng-click=\"exitWithCallback(ctx.cancel)\" ng-disabled=\"loading\" class=\"btn btn-default\"><span>Abbrechen</span></button></div></div></div>");;return buf.join("");
}