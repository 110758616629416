import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";

import PermissionAPI from "../../../../../global/compositionAPI/PermissionAPI";

// @vue/component
export default {
  name: "AufgabenvorlagenDetailsStatusgruppenTableRow",
  components: {
    AngularLink,
    Modal,
    PuxIcon,
  },
  props: {
    workflowtypId: {
      type: String,
      required: true,
    },
    workflowTypenMap: {
      type: Object,
      required: true,
    },
    statusgruppen: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      checkPermissionsSync,
    };
  },
  computed: {
    hasPermissionWorkflowtypUi() {
      return this.checkPermissionsSync({ perm: "workflowtyp.ui" });
    },
  },
};
