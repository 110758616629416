import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import ModelSerializerModulMixin from "../ModelSerializerModulMixin/ModelSerializerModulMixin.js";

import {
  cloneDeep,
  filter,
  find,
  forEach,
  indexOf,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "AstOrganisationsdaten",
  components: {
    FormElement,
    FormElementReadOnly,
    PuxTranslate,
  },
  mixins: [
    ModelSerializerModulMixin,
  ],
  data: () => {
    return {
      modelEdit: {},
      anschriftFields: [
        "ast_name",
        "ast_namezusatz",
        "ast_strasse",
        "ast_hsnr",
        "ast_plz",
        "ast_ort",
        "bundesland",
        "lkz",
      ],
      anschriftFormFieldDict: undefined,
      anschriftReadonlyFormFieldDict: undefined,
      otherFormFieldList: undefined,
      crmnrLocal: undefined,
      errorsClone: {},
    };
  },
  computed: {
    formstepOptions() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.openEditMode;
      return OPTIONS;
    },

    modelReadonly() {
      const MODEL = cloneDeep(this.model);
      MODEL.ast_crmnr = this.obj.ast_crmnr || MODEL.ast_crmnr;
      return MODEL;
    },
  },
  methods: {
    openEditMode() {
      this.modelEdit = cloneDeep(this.modelReadonly);
    },

    initFormFieldsLocal() {
      this.initAnschriftFormFieldDict();
      this.initAnschriftReadonlyFormFieldDict();
      this.initOtherFormFieldList();
    },

    initAnschriftFormFieldDict() {
      const FORM = {};
      forEach(this.formFields, el => {
        if (indexOf(this.anschriftFields, el.id) === -1) {
          return;
        }
        FORM[el.id] = cloneDeep(el);
      });
      if (!isNil(FORM.bundesland)) {
        FORM.bundesland.keyLabel = "bun_bez";
      }
      this.anschriftFormFieldDict = FORM;
    },

    initAnschriftReadonlyFormFieldDict() {
      const FORM = cloneDeep(this.anschriftFormFieldDict);
      forEach(FORM, el => {
        el.showLabel = false;
      });
      this.anschriftReadonlyFormFieldDict = FORM;
    },

    initOtherFormFieldList() {
      this.otherFormFieldList = filter(this.formFields, el => this.anschriftFields.indexOf(el.id) === -1);
      const URL_FIELD = find(this.otherFormFieldList, el => el.id === "ast_url");
      if (!isNil(URL_FIELD)) {
        URL_FIELD.asLink = true;
        URL_FIELD.absoluteLink = true;
      }
    },

    onError({ errors }) {
      this.errors = errors.data;
      this.errorsClone = cloneDeep(this.errors) || {};
      return errors;
    },

    close() {
      this.errors = undefined;
      this.errorsClone = {};
    },

    setLocalErrorsFromGlobal({ errors }) {
      this.errorsClone = errors;
    },
  },
};
