"use strict";

import angular from "angular";
import "restangular";

import BetragsrechnerPersonalkostenPKT from "./betragsrechner/personalkosten_mit_pkt";
import BetragsrechnerPersonalkostenLohnjournal from "./betragsrechner/personalkosten_lohnjournal";
import BetragsrechnerPersonalkostenPerioden from "./betragsrechner/personalkosten_perioden";
import BetragsrechnerPersonalkosten from "./betragsrechner/personalkosten";
import BetragsrechnerStandard from "./betragsrechner/standard_betragsrechner";
import BetragsrechnerFerien from "./betragsrechner/ferien_betragsrechner";
import BetragsrechnerFerienMitReisetagen from "./betragsrechner/ferien_mit_reisetagen_betragsrechner";
import BetragsrechnerHonorar from "./betragsrechner/honorarkosten_betragsrechner";
import BetragsrechnerHonorarOhneQuali from "./betragsrechner/honorarkosten_ohne_quali";
import BetragsrechnerKategorieMitPauschale from "./betragsrechner/kategorie_mit_pauschale_betragsrechner";
import BetragsrechnerBildung from "./betragsrechner/bildung_betragsrechner";
import BetragsrechnerUebernachtung from "./betragsrechner/uebernachtung_betragsrechner";
import BetragsrechnerRolliplaetze from "./betragsrechner/rolliplaetze_betragsrechner";
import BetragsrechnerManuelleRueckforderung from "./betragsrechner/manuelle_rueckforderung_betragsrechner";
import BetragsrechnerRegistry from "./betragsrechner/services";
import BetragsrechnerComponent from "./betragsrechner/betragsrechner.component.js";
import BetragsrechnerLand from "./betragsrechner/finanzierung_mit_land_betragsrechner";
import BetragsrechnerDetailliert from "./betragsrechner/finanzierung_detailliert_betragsrechner";
import BetragsrechnerDetailliertNational from "./betragsrechner/finanzierung_detailliert_national_betragsrechner";
import BetragsrechnerKostenOhneBezeichnung from "./betragsrechner/kosten_ohne_bezeichnung_betragsrechner";
import BetragsrechnerAbhaengigeKostenpauschale from "./betragsrechner/abhaengige_kostenpauschale_rechner";
import BetragsrechnerStueckkosten from "./betragsrechner/stueckkostenrechner";

import AbhaengigeKostenpauschaleModul from "dias/kosten_finanzierung/modul/abhaengige_kostenpauschale";
import AbzugNichtFoerderfaehigeKostenModul from "./modul/abzug_nicht_foerderfaehige_kosten";
import AllgemeinkostenpauschaleModul from "dias/kosten_finanzierung/modul/allgemeinkosten_pauschale";
import DeckungslueckeFinanzierungModul from "./modul/deckungsluecke_finanzierung";
import EhrenamtpauschaleModul from "./modul/ehrenamt_pauschale";
import BearbeitbarePauschaleModul from "./modul/bearbeitbare_pauschale";
import KostenSummenzeileModul from "./modul/kosten_summenzeile";
import KostenMitPauschaleModul from "./modul/kosten_mit_pauschale";
import ManuelleRueckforderungModul from "./modul/manuelle_rueckforderung";
import HonorarkostenModul from "dias/kosten_finanzierung/modul/honorar_kosten";
import PersonalkostenPktModul from "./modul/personalkosten_pkt";
import PersonalkostenPeriodenModul from "./modul/personalkosten_perioden";
import PersonalkostenLohnjournalModul from "./modul/personalkosten_lohnjournal";
import RollstuhlplatzPauschaleModul from "./modul/rollstuhlplatz_pauschale";
import Rueckfoerderungsverzicht from "./modul/rueckforderungsverzicht";
import PeriodenSignalFinanzierung from "./modul/perioden_signal_finanzierung";
import PeriodenSignalKosten from "./modul/perioden_signal_kosten";
import SignalKosten from "./modul/signal_kosten";
import SignalZuschuss from "./modul/signal_zuschuss";
import StueckkostenModul from "dias/kosten_finanzierung/modul/stueckkosten";
import StandardFinanzierungModul from "dias/kosten_finanzierung/modul/standard_finanzierung";
import StandardkostenModul from "dias/kosten_finanzierung/modul/standard_kosten";
import StandardpauschaleModul from "./modul/standard_pauschale";
import ZuschussModul from "./modul/standard_zuschuss";
import ZuschussMitEingabeModul from "./modul/zuschuss_mit_eingabe";
import ZuschussMitFinanzierungseingabe from "./modul/zuschuss_mit_finanzierungseingabe";
import ZuschussZeitraumModul from "./modul/zuschuss_zeitraum";
import ZuschussPeriodenModul from "./modul/zuschuss_perioden";


const kostenFinanzierungModelsModule = angular.module("dias.kosten_finanzierung.models", [
  "restangular",
  BetragsrechnerPersonalkostenPerioden.name,
  BetragsrechnerPersonalkostenLohnjournal.name,
  BetragsrechnerPersonalkostenPKT.name,
  BetragsrechnerPersonalkosten.name,
  BetragsrechnerStandard.name,
  BetragsrechnerFerien.name,
  BetragsrechnerFerienMitReisetagen.name,
  BetragsrechnerHonorar.name,
  BetragsrechnerHonorarOhneQuali.name,
  BetragsrechnerKategorieMitPauschale.name,
  BetragsrechnerBildung.name,
  BetragsrechnerUebernachtung.name,
  BetragsrechnerRolliplaetze.name,
  BetragsrechnerManuelleRueckforderung.name,
  BetragsrechnerRegistry.name,
  BetragsrechnerLand.name,
  BetragsrechnerDetailliert.name,
  BetragsrechnerDetailliertNational.name,
  BetragsrechnerKostenOhneBezeichnung.name,
  BetragsrechnerAbhaengigeKostenpauschale.name,
  BetragsrechnerStueckkosten.name,
  AbhaengigeKostenpauschaleModul.name,
  AllgemeinkostenpauschaleModul.name,
  AbzugNichtFoerderfaehigeKostenModul.name,
  DeckungslueckeFinanzierungModul.name,
  EhrenamtpauschaleModul.name,
  BearbeitbarePauschaleModul.name,
  KostenMitPauschaleModul.name,
  ManuelleRueckforderungModul.name,
  HonorarkostenModul.name,
  PersonalkostenLohnjournalModul.name,
  PersonalkostenPktModul.name,
  PersonalkostenPeriodenModul.name,
  PeriodenSignalFinanzierung.name,
  PeriodenSignalKosten.name,
  RollstuhlplatzPauschaleModul.name,
  Rueckfoerderungsverzicht.name,
  SignalKosten.name,
  SignalZuschuss.name,
  StandardFinanzierungModul.name,
  StandardkostenModul.name,
  StandardpauschaleModul.name,
  StueckkostenModul.name,
  KostenSummenzeileModul.name,
  ZuschussModul.name,
  ZuschussMitEingabeModul.name,
  ZuschussZeitraumModul.name,
  ZuschussPeriodenModul.name,
  ZuschussMitFinanzierungseingabe.name,
])
  .component("betragsrechner", BetragsrechnerComponent);

// Kostenarten
kostenFinanzierungModelsModule.factory("KKostenart", Restangular => {
  return Restangular.withConfig(RestangularConfigurer => {
    RestangularConfigurer.setRestangularFields({ id: "pk" });
    RestangularConfigurer.setDefaultHttpFields({ cache: true });
  }).service("kostenarten");
});

kostenFinanzierungModelsModule.factory("KFinanzierungsart", Restangular => {
  return Restangular.withConfig(RestangularConfigurer => {
    RestangularConfigurer.setRestangularFields({ id: "pk" });
    RestangularConfigurer.setDefaultHttpFields({ cache: true });
  }).service("finanzierungsarten");
});

kostenFinanzierungModelsModule.factory("Personalkostentabelle", Restangular => {
  return Restangular.service("personalkostentabellen");
});

kostenFinanzierungModelsModule.factory("Personalkostentyp", Restangular => {
  return Restangular.service("personalkostentypen");
});

kostenFinanzierungModelsModule.factory("Personalstelle", Restangular => {
  return Restangular.service("personalstellen");
});

kostenFinanzierungModelsModule.factory("Betragsrechner", Restangular => {
  return Restangular.service("betragsrechner");
});


export default kostenFinanzierungModelsModule;
