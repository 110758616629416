"use strict";
import {
  setStrInFormat
} from "../../../../../vue/client/vue/global/filters/spaced";

export default ["SyConfigs", SyConfigs => {
  const FORMAT = SyConfigs.wert("antragsnummerformat");
  return (value, statusNotHtml) => {
    return setStrInFormat({ format: FORMAT, value, statusNotHtml });
  };
}];
