import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "DokumenteZurFoerderungTable";

// @vue/component
export default {
  name: "DokumenteZurFoerderungList",
  components: {
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
  ],
  setup() {
    const {
      filterDefaultForEmpty,
      filterFileSize,
    } = FiltersAPI();

    return {
      filterDefaultForEmpty,
      filterFileSize,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        actions: [],
        pagination: true,
        sortable: true,
        details: false,
        view: {
          filter: "top",
        },
      },
    };
  },
  computed: {
    getHrefDokument() {
      return row => `${ row.letzte_version.url }`;
    }
  },
};
