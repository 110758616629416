var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["sitzungsmodul-preview-icon"] = jade_interp = function(icon, count, hoverText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("title", "" + (hoverText) + "", true, true)) + " class=\"preview-icon\"><i" + (jade.cls(['glyphicon',"" + (icon) + ""], [null,true])) + "></i>");
if ( count)
{
buf.push("<span class=\"badge\">" + (jade.escape((jade_interp = count) == null ? '' : jade_interp)) + "</span>");
}
buf.push("</div>");
};





























buf.push("<span>Anträge</span>");
jade_mixins["sitzungsmodul-preview-icon"]("glyphicon-antrag", "{{vm.userInput.count}}", "{{vm.userInput.count}} Anträge");;return buf.join("");
}