import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import translate from "../../../../../global/directives/translate";
import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "VertretungsberechtigteTr",
  components: {
    SnapshotIcon,
    AngularLink,
    PuxButton,
  },
  directives: {
    translate,
  },
  props: {
    vertreter: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: undefined,
    },
    isButtonDeleteDisabled: {
      type: Boolean,
      required: false,
    },
    htmlId: {
      type: String,
      required: false,
      default: undefined,
    },
    antragstellerorgPk: {
      type: String,
      required: false,
      default: undefined,
    },
    statusSnapshot: {
      type: Boolean,
      required: false,
    },
    statusSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    statusSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    statusSnapshotChanges: {
      type: Boolean,
      required: false,
    },
    statusSnapshotChangesIcon: {
      type: Boolean,
      required: false,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    statusReadonly: {
      type: Boolean,
      required: false,
    },
    showLink: {
      type: Boolean,
      default: () => true
    },
  },
  emits: [
    "confirmDeleteVertreter",
  ],
  computed: {
    inaktivText() {
      if (this.vertreter.av_deaktiviert === true) {
        return "_TXT_DEACTIVIERT_IN_KLAMMERN_";
      }
      if (this.vertreter.gueltig_antrag === false) {
        return "_TXT_NICHT_MEHR_GUELTIG_IN_KLAMMERN_";
      }
      return "";
    },

    snapshotIconFirstTdType() {
      if (this.statusSnapshotAdd) {
        return "add";
      }
      if (this.statusSnapshotDelete) {
        return "delete";
      }
      if (this.statusSnapshotChangesIcon) {
        return "changes";
      }
      return "";
    },

    translateVersionTdOptions() {
      return {
        text: this.translateVersionTdText,
        extra: {
          date: this.snapshotDate,
        },
      };
    },

    translateVersionTdText() {
      if (this.statusSnapshotAdd) {
        return "_TXT_AKTUELLE_VERSION_ADDED_COLUMN_{{date}}_";
      }
      if (this.statusSnapshotDelete) {
        return "_TXT_VERSION_REMOVED_COLUMN_{{date}}_";
      }
      if (this.statusSnapshotChanges) {
        return "_TXT_VERSION_MODIFIED_COLUMN_{{date}}_";
      }
      return "_TXT_AKTUELLE_VERSION_";
    },

    classVersion() {
      if (this.statusSnapshotComponent) {
        return "version-bg";
      }
      return "";
    },

    rowspanFirstTd() {
      return this.statusSnapshotChangesIcon ? 2 : 1;
    },

    statusShowFirstTd() {
      return !(this.statusSnapshotChanges && !this.statusSnapshotChangesIcon);
    },

    statusSnapshotComponent() {
      return this.statusSnapshotDelete || this.statusSnapshotChanges;
    },

    statusShowButtonDelete() {
      return !this.statusSnapshotComponent && !this.statusReadonly;
    },

    buttonDeleteId() {
      return `${ this.htmlId }_delete_${ this.vertreter.pk }`;
    },
  },
  methods: {
    deleteVertreter() {
      if (this.isButtonDeleteDisabled) {
        return;
      }
      this.$emit("confirmDeleteVertreter", { vertreter: this.vertreter });
    },
  },
};
