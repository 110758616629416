"use strict";

import template from "./template.jade";
import modalTemplate from "./modal.template.jade";

class TempAntraegeEigeneController {
  /*@ngInject*/
  constructor(TempAntraegeEigene, diasConfirmDialog, AuthService, DiasNotification, $filter, $state) {
    this.name = "TempAntraegeEigeneController";
    this.$filter = $filter;
    this.$state = $state;
    this.TempAntraegeEigene = TempAntraegeEigene;
    this.AuthService = AuthService;
    this.diasConfirmDialog = diasConfirmDialog;
    this.DiasNotification = DiasNotification;
    this.tempAntraege = [];

    this.checkUser();
  }

  checkUser() {
    this.AuthService.getUser().then(
      user => {
        if (user && user.pk) {
          this.deleteCookie("diasTempEmail");
          this.checkPermission();
        }
      }
    );
  }

  checkPermission() {
    this.AuthService.hasPerm("foerderantrag.view").then(
      hasPerm => {
        if (hasPerm) {
          this.checkTempAntraege();
        }
      }
    );
  }

  checkTempAntraege() {
    this.TempAntraegeEigene.getList().then(
      response => {
        if (response.length) {
          this.tempAntraege = response;
          this.diasConfirmDialog({
            titleTemplate: "Sie haben <strong>{{ vm.tempAntraege.length }} {{ vm.tempAntraege.length === 1 ? 'Antrag' : 'Anträge' }}</strong> vorbereitet",
            contentTemplate: modalTemplate(),
            context: {
              tempAntraege: this.tempAntraege,
            },
            okLabel: "Vorbereitete Anträge übernehmen",
            cancelLabel: "Verwerfen",
            okCallback: () => this.bildAntraege(),
            cancelCallback: () => this.removeAntraege()
          });
        }
      }
    );
  }

  bildAntraege() {
    _.forEach(this.tempAntraege, (antrag, index) => {
      antrag.antrag_erstellen.post().then(
        response => {
          this.DiasNotification.page.success(`Antrag Nr. ${ response.a_nr } erfolgreich erstellt`);
          if (index === 0) {
            this.$state.go("root.foerderantrag.details", { id: response.pk });
          }
        }
      );
    });
  }

  removeAntraege() {
    _.forEach(this.tempAntraege, (antrag, index) => {
      antrag.remove().then(
        () => {
          this.DiasNotification.page.success(`${ index + 1 }) ${ antrag.regelsatz_name } von ${ this.$filter("date")(antrag.crdate, "dd.MM.yyyy HH:mm") } erfolgreich gelöscht`);
        }
      );
    });
  }

  deleteCookie(name) {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }
}

export default {
  template: template(),
  controller: TempAntraegeEigeneController,
  controllerAs: "vm"
};
