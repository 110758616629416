import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "VertragValidate",
  components: {
    PuxAlert,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    validationErrors: {
      type: Array,
      default: () => [],
    },
    validationErrorsLoading: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
  },
  emits: [
    "resetValidation",
  ],
  methods: {
    resetValidationLocal() {
      this.$emit("resetValidation");
    },
  },
};
