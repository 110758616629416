import DashboardModuleMixin from "../DashboardModuleMixin";

import VertragWfStatusgruppenUUIDs from "../../../../const/VertragWfStatusgruppenUUIDs";
import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "DashboardAntragBewilligt",
  mixins: [
    DashboardModuleMixin,
  ],
  computed: {
    statusShow() {
      return this.data &&
        this.data.data &&
        this.vertragWfStatusgruppen[VertragWfStatusgruppenUUIDs.V_ABSCHLIESSEN] &&
        this.vertragWfStatusgruppen[VertragWfStatusgruppenUUIDs.V_ABSCHLIESSEN].anzahl_objekte;
    },

    vertragWfStatusgruppen() {
      const VERTRAG_WF_STATUSGRUPPEN = {};
      if (!this.data || !this.data.data || !this.data.data.statusgruppen) {
        return VERTRAG_WF_STATUSGRUPPEN;
      }
      forEach(this.data.data.statusgruppen, value => {
        VERTRAG_WF_STATUSGRUPPEN[value.workflowstatusgruppe.pk] = value;
      });
      return VERTRAG_WF_STATUSGRUPPEN;
    },

    boxTranslate() {
      return {
        html: "_HTML_DASHBOARD_ANTRAG_BEWILLIGT_{{anrede}}_{{nachname}}_",
        extra: {
          anrede: this.anrede,
          nachname: this.nachname,
        },
      };
    },

    anrede() {
      return this.data && this.data.user && this.data.user.anrede;
    },

    nachname() {
      return this.data && this.data.user && this.data.user.n_nachname;
    }
  },
};
