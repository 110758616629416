"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";


class KAufgabeEditBeschreibungController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, ParameterUtils, DiasNotification, i18n) {
    super($q, $scope, DiasNotification);
    this.ParameterUtils = ParameterUtils;
    this.gettext = i18n.gettext;
    this.initForm();
  }

  _save() {
    const data = this.ParameterUtils.to_request_body(this.formModel, null, "");
    angular.forEach(
      this.ParameterUtils.to_request_body(this.formModelBottom, null, ""),
      (value, key) => {
        data[key] = value;
      }
    );

    Object.assign(this.model, data, {});
    return this.model.save().then(
      response => {
        this.$scope.model.merge(this.$scope.model, response.plain());
        this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
      },
      errors => {
        return this.onErrors(errors, this.errorLabels);
      }
    );
  }

  onEnterEditMode() {
    super.onEnterEditMode();
    this.updateFormModel();
  }

  updateFormModel() {
    if (this.ParameterUtils && this.formModelTemplate && this.model) {
      this.formModel = this.ParameterUtils.params_from_template(this.formModelTemplate, this.model);
      this.formModelBottom = this.ParameterUtils.params_from_template(this.formModelTemplateBottom, this.model);
    }
  }

  initForm() {
    this.formModelTemplate = this.ParameterUtils.init_parameter_in({
      au_kbez: {
        bez: this.gettext("Kurzbezeichnung"),
        typ: "string",
        prio: 1,
        options: {
          required: true
        }
      },
      au_kbez_admin: {
        bez: this.gettext("Kurzbezeichnung in Administration"),
        typ: "string",
        prio: 1,
        options: {
          required: true
        }
      },
      au_kbezbeobachter: {
        bez: this.gettext("Kurzbezeichnung für Beobachter"),
        typ: "string",
        prio: 2,
        options: {
          required: true
        }
      },
      au_kbezdetail: {
        bez: this.gettext("Kurzbezeichnung (detailliert)"),
        typ: "string",
        prio: 3,
        options: {
          required: true
        }
      },
      au_bez: {
        bez: this.gettext("Langbezeichnung"),
        typ: "richtext",
        prio: 4,
        options: {
          required: false
        }
      },
      au_termin_sichtbar: {
        bez: this.gettext("Termin sichtbar"),
        typ: "boolean",
        prio: 5,
        options: {
          required: false
        }
      },
      au_passiv: {
        bez: this.gettext("Passiv"),
        typ: "boolean",
        prio: 6,
        options: {
          required: false
        }
      },
      au_ende: {
        bez: this.gettext("Bearbeitungsende"),
        typ: "boolean",
        prio: 7,
        options: {
          required: false
        }
      },
    });
    this.form_keys = this.ParameterUtils.get_ordered_keys(this.formModelTemplate);
    this.errorLabels = {};
    this.form_keys.forEach(
      k => {
        this.errorLabels[k] = `${ this.formModelTemplate[k].bez }: `;
      }
    );
    this.form_keys_left = this.form_keys.slice(0, 5);
    this.form_keys_right = this.form_keys.slice(5);

    this.formModelTemplateBottom = {
      au_kontext_config: {
        bez: this.gettext("Konfiguration der Anzeigekontexte"),
        typ: "json",
        prio: 10,
        options: {
          required: false
        }
      },
    };
    this.form_keys_bottom = this.ParameterUtils.get_ordered_keys(this.formModelTemplateBottom);
    this.form_keys_bottom.forEach(
      k => {
        this.errorLabels[k] = `${ this.formModelTemplateBottom[k].bez }: `;
      }
    );
    this.updateFormModel();
  }
}

export default KAufgabeEditBeschreibungController;
