import {
  forEach,
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "QuartierskarteLegende",
  props: {
    addresses: {
      type: Array,
      default: () => [],
    },
    addressesEditierbar: {
      type: Array,
      default: () => [],
    },
    adresstypObj: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showAddressesTypes() {
      const ADDRESSES_OBJ = {};
      forEach(this.addresses, address => {
        if (!ADDRESSES_OBJ[address.typ]) {
          ADDRESSES_OBJ[address.typ] = {
            pk: address.typ,
            count: 1,
          };
        } else {
          ADDRESSES_OBJ[address.typ].count++;
        }
      });

      forEach(this.addressesEditierbar, address => {
        if (!ADDRESSES_OBJ[address.typ]) {
          ADDRESSES_OBJ[address.typ] = {
            pk: address.typ,
            count: 1,
          };
        } else {
          ADDRESSES_OBJ[address.typ].count++;
        }
      });
      return keyBy(ADDRESSES_OBJ, "pk");
    },

    getSrc() {
      return pk => `/api/assets/${ this.adresstypObj[pk].adt_bild }`;
    },

    getTitle() {
      return pk => this.adresstypObj[pk].adt_bez;
    },

    getName() {
      return pk => this.adresstypObj[pk].adt_kbez;
    },
  },
};
