import Alert from "../../../global/components/Alert/Alert.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import FoerderdokumenteBenennung from "./FoerderdokumenteBenennung/FoerderdokumenteBenennung.vue";
import FoerderdokumenteDokumentversionen from "./FoerderdokumenteDokumentversionen/FoerderdokumenteDokumentversionen.vue";
import FoerderdokumenteFoerderangebote from "./FoerderdokumenteFoerderangebote/FoerderdokumenteFoerderangebote.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import TaskLog from "../../../global/components/TaskLog/TaskLog.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../global/compositionAPI/NotificationAPI";

// @vue/component
export default {
  name: "FoerderdokumenteDetails",
  components: {
    Alert,
    ContextPanel,
    FoerderdokumenteBenennung,
    FoerderdokumenteDokumentversionen,
    FoerderdokumenteFoerderangebote,
    Modal,
    Permission,
    PuxButton,
    PuxCloak,
    PuxPageDetails,
    TaskLog,
  },
  setup() {
    const {
      deleteHttp,
      getHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      deleteHttp,
      getHttp,
    };
  },
  data() {
    return {
      loading: true,
      foerderdokument: {},
      confirmShow: undefined,
      confirmOptions: undefined,
      taskId: undefined,
      deletingErrors: [],
      alertType: undefined,
      showAlert: undefined,
      panelFoerderdokumentOptions: {
        label: "_TXT_FOERDERDOKUMENTE_DETAILS_FOERDERDOKUMENT_PANEL_NAME_",
        icon: "glyphicon-file",
        active: true,
      },
      panelDokumentversionenOptions: {
        label: "_TXT_FOERDERDOKUMENTE_DETAILS_DOKUMENTVERSIONEN_PANEL_NAME_",
        icon: "glyphicon-documents",
        active: false,
      },
    };
  },
  computed: {
    foerderdokumenteUrl() {
      return `foerderdokumente/${ this.$stateParams.id }/`;
    },
  },
  created() {
    this.loadFoerderdokument();
  },
  methods: {
    loadFoerderdokument() {
      this.getHttp({
        url: this.foerderdokumenteUrl,
      }).then(
        response => {
          this.foerderdokument = response;
        }
      ).then(
        () => this.loading = false
      );
    },

    updateFoerderdokument({ foerderdokument }) {
      this.foerderdokument = foerderdokument;
    },

    deleteFoerderdokument() {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: this.foerderdokumenteUrl,
      }).then(
        response => {
          this.closeConfirmFoerderdokument();
          this.showAlert = true;
          this.alertType = "info";
          this.taskId = response;
        },
        () => this.addNotification({ text: "_MSG_FOERDERDOKUMENTE_DETAILS_DELETE_ERROR_", type: "error" })
      ).then(
        this.confirmOptions.loading = false
      );
    },

    openModalDeleteFoerderdokument() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_FOERDERDOKUMENTE_DETAILS_DELETE_MODAL_HEADER_",
        msg: "_TXT_FOERDERDOKUMENTE_DETAILS_DELETE_MODAL_BODY_",
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: () => this.deleteFoerderdokument(),
        cancelCallback: this.closeConfirmFoerderdokument,
      };
      this.confirmShow = true;
    },

    closeConfirmFoerderdokument() {
      this.confirmShow = false;
    },

    onSuccess({ result }) {
      if (!result) {
        this.addNotification({ text: "_MSG_FOERDERDOKUMENTE_DETAILS_DELETE_SUCCESS_" });
        this.$goTo("root.foerderdokumente");
      } else {
        this.alertType = "danger";
        this.addNotification({ text: "_MSG_FOERDERDOKUMENTE_DETAILS_DELETE_ERROR_", type: "error" });
        this.deletingErrors = result.errors;
      }
      this.taskId = null;
    },

    onFailure({ result }) {
      this.alertType = "danger";
      this.addNotification({ text: "_MSG_FOERDERDOKUMENTE_DETAILS_DELETE_ERROR_", type: "error" });
      this.deletingErrors = result.errors;
      this.taskId = null;
    },

    onDismiss() {
      this.showAlert = false;
      this.deletingErrors = undefined;
    }
  },
};
