// @vue/component
export default {
  name: "StyleguideTableOptionsActions",
  data() {
    return {
      actions: [
        {
          label: "permission",
          type: "String",
          default: "undefined",
          description: "",
          required: false,
          example: `permission: "foerderangebot.create",`,
          options: "",
        },
        {
          label: "label",
          type: "String",
          default: "undefined",
          description: "",
          required: true,
          example: "label: `${ this.$gettext(GänsefüßchenFörderangebotGänsefüßchen) } erstellen`,",
          options: "",
        },
        {
          label: "callback",
          type: "Funktion",
          default: "undefined",
          description: "",
          required: false,
          example: "callback: () => this.modal.create = true,",
          options: "",
        },
        {
          label: "type",
          type: "String",
          default: "undefined",
          description: "",
          required: true,
          example: `type: "button",`,
          options: "",
        },
        {
          label: "className",
          type: "String",
          default: "undefined",
          description: "",
          required: false,
          example: `className: "btn btn-primary",`,
          options: "",
        },
        {
          label: "icon",
          type: "String",
          default: "undefined",
          description: "",
          required: false,
          example: `icon: "glyphicon-plus",`,
          options: "",
        },
      ]
    };
  },
};
