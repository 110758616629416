"use strict";


import dokumentationTemplate from "./dokumentation.jade";

class DokumentationComponent {
  /*@ngInject*/
  constructor(Dokumentation) {
    this.loading = true;

    Dokumentation.getList().then(
      result => this.doku = result
    ).finally(this.loading = false);

    this.renderNumber = dom => {
      let number = "";
      angular.forEach(dom.absolut_pos, pos => number += pos + ".");
      return number;
    };
  }
}


export default {
  template: dokumentationTemplate(),
  controller: DokumentationComponent,
  controllerAs: "vm"
};
