import Alert from "../../global/components/Alert/Alert.vue";
import Modal from "../../global/components/Modal/Modal.vue";
import NutzerschlagwortAuswahl from "./NutzerschlagwortAuswahl/NutzerschlagwortAuswahl.vue";
import PuxButton from "../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../global/mixins/HttpMixin";
import NotificationMixin from "../../global/mixins/NotificationMixin";
import SyConfigMixin from "../../global/mixins/SyConfigMixin";

import {
  cloneDeep,
  size,
} from "lodash-es";

// @vue/component
export default {
  components: {
    Alert,
    Modal,
    NutzerschlagwortAuswahl,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    SyConfigMixin,
  ],
  props: {
    objekte: {
      type: Array,
      default: () => [],
    },
    modalMsgTranslation: {
      type: Object,
      required: false,
      default: undefined,
    },
    modalTitleTranslation: {
      type: Object,
      required: false,
      default: undefined,
    },
    modalButtonSaveText: {
      type: String,
      default: "_BTN_SAVE_",
    },
    selectorClose: {
      type: [String, Array],
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selection: [],
      modalOptions: {
        showCloseButton: true,
      },
      status: {
        loading: false
      },
      errors: undefined,
      errorsClone: undefined,
      defaultMsg: undefined,
      defaultTitle: undefined,
    };
  },
  computed: {
    translationModalMsg() {
      return this.modalMsgTranslation || {
        html: this.defaultMsg,
        extra: {
          anz: size(this.objekte),
        }
      };
    },

    translationModalTitel() {
      return this.modalTitleTranslation || {
        html: this.defaultTitle,
        extra: {
          anz: size(this.objekte),
        }
      };
    },

    showErrors() {
      return size(this.errors) > 0;
    },
  },
  methods: {
    onSelectionChanged({ model }) {
      this.selection = cloneDeep(model);
    },

    save() {
      return;
    },

    onError({ error }) {
      if (error.status !== 429) {
        const ERROR = error.data;
        this.errors = ERROR;
        this.errorsClone = ERROR;
      }
    },
  },
};
