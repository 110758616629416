"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import "angular-chart.js";
import "angular-drag-and-drop-lists";

import diasAuthService from "dias/auth/services";
import diasConfirm from "dias/core/confirm";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";

import foerdermodule from "dias/foerderantrag/foerdermodule";
import foerderregeln from "dias/foerderregeln/models";

import models from "../../models";
import detailsComponent from "./details.component";

const basisformularModule = angular.module("root.basisformular.xxx", [
  uiRouter,
  models.name,
  foerdermodule.name,
  foerderregeln.name,
  diasAuthService.name,
  diasConfirm.name,
  diasTable.name,
  diasLoading.name,
  diasNotification.name,
]);

basisformularModule.config($stateProvider => {
  $stateProvider

    // BasisFormular-Liste
    .state("root.basisformularcortex", {
      url: `/formulare/?
            user_cookie&
            dashboard&
            friststatus&
            status&
            status_detail&
            workflowstatusgruppe&
            formulartyp
      `,
      views: {
        "main@root": {
          template: "<div vueinit><vue-formulare-list></vue-formulare-list></div>"
        }
      },
      data: {
        permissions: ["basisformularcortex.list"]
      },
    })

    // Detail-Seite eines Formulars
    .state("root.basisformularcortex.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<basisformularcortex-details></basisformularcortex-details>"
        }
      },
      data: {
        permissions: ["basisformularcortex.view"]
      },
    })
  ;
})
  .component("basisformularcortexDetails", detailsComponent)
;

export default basisformularModule;
