import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import VertragNachweiseModalUpdate from "./VertragNachweiseModalUpdate/VertragNachweiseModalUpdate.vue";
import VertragNachweisTr from "./VertragNachweisTr/VertragNachweisTr.vue";

import ModuleMixin from "../ModuleMixin";

import {
  cloneDeep,
  forEach,
  get,
  isEqual,
  isObject,
  keyBy,
  values,
} from "lodash-es";

// @vue/component
export default {
  name: "StandardFoerdermoduleVertragNachweise",
  components: {
    Modal,
    PuxButton,
    VertragNachweiseModalUpdate,
    VertragNachweisTr,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      statusModalUpdate: undefined,
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      return this.optionsFormstepDetail;
    },

    selectorClose() {
      return "vertrag_nachweise_selector_close";
    },

    labelHeader() {
      if (this.modelParameter.ueberschrift) {
        return this.modelParameter.ueberschrift;
      }
      return "_TXT_GR_VERTRAG_NACHWEISE_HEADER_";
    },

    extraForTranslate() {
      return {
        periodentyp_bez: this.model.periodentyp_bez,
      };
    },

    statusDiff() {
      return this.snapshotFiltered.status;
    },

    snapshotElementeObj() {
      if (this.statusSnapshot &&
        this.snapshotModule &&
        this.snapshotModule.elemente &&
        this.snapshotModule.elemente.length) {
        return keyBy(this.snapshotModule.elemente, "periodenkennung");
      }
      return {};
    },

    snapshotFiltered() {
      const SNAPSHOT = {
        add: {},
        delete: [],
        changes: {},
        changesDiff: {},
        status: false,
      };
      if (this.statusSnapshot) {
        const SNAPSHOT_ELEMENTE_OBJ = cloneDeep(this.snapshotElementeObj);
        if (this.model.elemente && this.model.elemente.length) {
          forEach(this.model.elemente, item => {
            const PERIODENKENNUNG = item.periodenkennung;
            if (!SNAPSHOT_ELEMENTE_OBJ[PERIODENKENNUNG]) {
              SNAPSHOT.add[PERIODENKENNUNG] = true;
              SNAPSHOT.status = true;
            } else {
              const CURRENT_SNAPSHOT = SNAPSHOT_ELEMENTE_OBJ[PERIODENKENNUNG];
              forEach(this.model.spalten, spalte => {
                const ID = spalte.id;
                const VALUE_IN_MODEL = get(item, ID);
                const VALUE_IN_SNAPSHOT = get(CURRENT_SNAPSHOT, ID);
                if (!this.isModelAndSnapshotEqual({
                  valueInModel: VALUE_IN_MODEL,
                  valueInSnapshot: VALUE_IN_SNAPSHOT,
                })) {
                  SNAPSHOT.changes[item.periodenkennung] = SNAPSHOT_ELEMENTE_OBJ[item.periodenkennung];
                  SNAPSHOT.changesDiff[item.periodenkennung] = SNAPSHOT.changesDiff[item.periodenkennung] || {};
                  SNAPSHOT.changesDiff[item.periodenkennung][ID] = true;
                  SNAPSHOT.status = true;
                }
              });
              delete SNAPSHOT_ELEMENTE_OBJ[item.periodenkennung];
            }
          });
        }
        SNAPSHOT.delete = values(SNAPSHOT_ELEMENTE_OBJ);
        if (SNAPSHOT.delete.length) {
          SNAPSHOT.status = true;
        }
      }
      return SNAPSHOT;
    },

    auszahlungKeyByPk() {
      return keyBy(this.extraStatic.auszahlungen, "pk");
    },

    isModuleEditable() {
      return false;
    },
    hasModulPermissionUpdate() {
      return get(this.regel, "rechte.update", false);
    },

    isButtonVisible() {
      return this.hasModulPermissionUpdate;
    },

    buttonId() {
      return `${ this.htmlRegelNummer }_update`;
    },
    urlSave() {
      return `${ this.baseUrl }module/${ this.regel.pk }/`;
    },
  },
  methods: {
    isModelAndSnapshotEqual({ valueInModel, valueInSnapshot }) {
      if (isObject(valueInModel) || isObject(valueInSnapshot)) {
        return isEqual(valueInModel, valueInSnapshot);
      }
      return valueInModel === valueInSnapshot;
    },

    openModalUpdate() {
      this.statusModalUpdate = true;
    },

    closeModalUpdate() {
      this.statusModalUpdate = false;
    },

    updateElemente(elemente) {
      this.model.elemente = elemente;
    },

    updateManuelleFristen(fristen) {
      this.model.manuelle_fristen = fristen;
    }
  },
};
