import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "InstrumentvariantenBasisdaten",
  components: {
    FormstepDetail,
  },
  props: {
    variante: {
      type: Object,
      required: true,
    },
    varianteUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "updateVariante",
  ],
  setup() {
    const {
      putHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      putHttp,
    };
  },
  data() {
    return {
      options: {
        label: "_LBL_INSTRUMENT_BASISDATEN_",
        list: [
          {
            id: "instrument",
            type: "select",
            label: "_LBL_INSTRUMENT_",
            showLabel: true,
            view: "v",
            required: true,
            url: "foerderinstrumente/",
            apiSaveId: "foerderinstrumente",
            keyId: "foi_id",
            keyLabel: "foi_kbez",
          },
          {
            id: "fiv_kbez",
            type: "text",
            label: "_LBL_INSTRUMENT_KURZBEZEICHNUNG_",
            showLabel: true,
            view: "v",
            maxlength: 60,
            required: true,
          },
          {
            id: "fiv_bez",
            type: "textarea",
            label: "_LBL_INSTRUMENT_BEZEICHNUNG_",
            showLabel: true,
            view: "v-alt",
            maxlength: 200,
            required: true,
          },
        ],
        required: true,
        saveCallback: this.save,
      },
    };
  },
  methods: {
    save({ model }) {
      const DATA = cloneDeep(model);
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.varianteUrl,
          data: DATA,
        }).then(
          response => {
            this.$emit("updateVariante", { variante: response });
            this.addNotification({ text: "_MSG_INSTUMENT_BASISDATEN_AENDERUNG_GESCHPEICHERT_" });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },
  },
};
