import FormElement from "../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";

// @vue/component
export default {
  name: "TerminChange",
  components: {
    FormElement,
    Modal,
    PuxButton,
    PuxTranslate,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    aufgabe: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    httpRequestMethod: {
      type: String,
      required: true,
      validator: value => ["post", "put"].indexOf(value) !== -1,
    },
  },
  emits: [
    "updateTermin",
  ],
  setup() {
    const {
      addNotification,
      postHttp,
      putHttp,
    } = HttpAPI();

    return {
      addNotification,
      postHttp,
      putHttp,
    };
  },
  data() {
    return {
      isModalVisible: false,
      loading: false,
      termin: null,
      errors: undefined,
    };
  },
  computed: {
    idForButtonOpenModal() {
      return `${ this.id }_termin_change`;
    },

    idForButtonModalSave() {
      return `${ this.id }_termin_change_save`;
    },

    selectorClose() {
      return `#${ this.idForButtonOpenModal }`;
    },

    optionsForFormElement() {
      const ID = "termin";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "date",
        required: true,
        view: "v",
        label: "_LBL_AUFGABE_BOX_TERMIN_CHANGE_MODAL_NEW_TERMIN_",
      };
    },

    urlSave() {
      return `${ this.baseUrl }${ this.aufgabe.pk }/termin_aendern/`;
    },

    alertLabels() {
      return {
        [this.optionsForFormElement.id]: this.optionsForFormElement.label,
      };
    },

    requestFunction() {
      return `${ this.httpRequestMethod }Http`;
    },
  },
  methods: {
    openModal() {
      this.termin = this.aufgabe.termin;
      this.isModalVisible = true;
      this.errors = undefined;
    },

    closeModal() {
      this.isModalVisible = false;
    },

    save() {
      this.loading = true;
      this[this.requestFunction]({
        url: this.urlSave,
        data: {
          termin: this.termin,
        },
      }).then(
        response => {
          this.$emit("updateTermin", response);
          this.addNotification({
            text: "_MSG_AUFGABE_BOX_TERMIN_CHANGE_MODAL_SUCCESS_"
          });
          this.closeModal();
        },
        errors => {
          this.errors = errors.data;
        }
      ).finally(
        () => this.loading = false
      );
    },
  },
};
