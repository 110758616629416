import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import VertragErrorsDdGroup from "../VertragErrorsDdGroup/VertragErrorsDdGroup.vue";

import {
  isArray,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragErrorsDd",
  components: {
    PuxButton,
    PuxTranslate,
    VertragErrorsDdGroup,
  },
  props: {
    error: {
      type: [Object, Array],
      required: true,
    },
    errorIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    hasErrorsLocalSize() {
      return !!(this.errorsLocal && size(this.errorsLocal));
    },

    errorsLocal() {
      return this.isErrorInGeschaeftsregel ?
        this.error[1] :
        this.error.errors;
    },

    isErrorInGeschaeftsregel() {
      return isArray(this.error);
    },
  },
};
