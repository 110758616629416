"use strict";

import BaseController from "../standard_foerdermodule.base.controller";

class EinfacheFoerderregelController extends BaseController {
  /*@ngInject*/
  constructor() {
    super();
    this.readonly = true;
  }
}

export default EinfacheFoerderregelController;
