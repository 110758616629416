"use strict";
import angular from "angular";

import DiasNotification from "dias/core/notification";

import services from "dias/workflow/services";
import functionCls from "./function";

const modul = angular.module("standard_workflowmodule.simple", [
  DiasNotification.name,
  services.name
])

  .config(/*@ngInject*/WorkflowfunktionRegistryProvider => {
    WorkflowfunktionRegistryProvider.register("simple", functionCls);
  });


export default modul;
