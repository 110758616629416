"use strict";
import {
  isNil,
} from "lodash-es";

class ParametermoduleBaseController {
  constructor() {
    if (isNil(this.parameter[this.key].wert)) {
      this.parameter[this.key].wert = this.parameter[this.key].default || null;
    }
    if (this.controlId) {
      this.param_id = this.controlId;
    } else {
      this.param_id = this.key;
    }
  }
}

export default ParametermoduleBaseController;
