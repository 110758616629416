import NutzerschlagwortModalBase from "../NutzerschlagwortObjektModalBase.js";

import {
  EventBus,
} from "../../../global/functions/event-bus";
import {
  cloneDeep,
  map,
  pick,
} from "lodash-es";

// @vue/component
export default {
  name: "NutzerschlagwortObjektModalAdd",
  mixins: [
    NutzerschlagwortModalBase
  ],
  data() {
    return {
      defaultMsg: "_HTML_NUTZERSCHLAGWORTOBJEKT_MODAL_ADD_INFO_{{anz}}_",
      defaultTitle: "_TXT_NUTZERSCHLAGWORTOBJEKT_MODAL_ADD_TITLE_{{anz}}_",
    };
  },
  methods: {
    save() {
      this.status.loading = true;
      const DATA = {
        objekte: map(this.objekte, el => pick(el, ["content_type", "pk"])),
        schlagworte: cloneDeep(this.selection)
      };
      this.postHttp({
        url: "nutzerschlagwortobjekte/batch_add/",
        data: DATA,
        showError: true,
      }).then(
        () => {
          this.addNotification({ text: "_MSG_NUTZERSCHLAGWORTOBJEKT_MODAL_ADD_SUCCESS_" });
          this.close({ status: true });
          EventBus.$emit("updateNurzerschlagworte");
        },
        error => {
          this.onError({ error });
        }
      ).finally(
        () => this.status.loading = false
      );
    },
  },
};
