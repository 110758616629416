import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxCloak from "../../../../../global/components/PuxCloak/PuxCloak.vue";
import HttpMixin from "../../../../../global/mixins/HttpMixin";
import WizardStepsRechnerMixin from "../WizardStepsRechnerMixin";
import translate from "../../../../../global/directives/translate";
import {
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenPeriodenWizardStep4",
  components: {
    FormElement,
    PuxCloak,
    FormstepDetail,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    WizardStepsRechnerMixin,
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
    afrId: {
      type: String,
      required: true,
    },
    betragsrechner: {
      type: String,
      required: true,
    },
    position: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      modelPosition: "",
      formElementOptions: {
        id: "position",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_KOSTENPOSITION_",
        type: "currency",
        view: "v-alt",
        disabled: true,
        helpText: "",
        translate: true,
        tooltip: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_KOSTENPOSITION_INFO_I_",
      },
      loadingBetrag: true,
      loadingSave: undefined,
    };
  },
  computed: {
    getTranslateOptionsInfo() {
      return {
        html: this.getTranslateOptionsInfoPlaceholder,
        extra: {
          pkt_pauschale: this.model.pkt_pauschale,
          pkt_lohnsteigerung: this.getPktLohnsteigerung,
        },
      };
    },
    getTranslateOptionsInfoPlaceholder() {
      return this.getPktLohnsteigerung ?
        "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_LAST_STEP_INFO_WITH_LOHNSTEIGERUNG_{{pkt_pauschale}}_{{pkt_lohnsteigerung}}_" :
        "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_LAST_STEP_INFO_WITHOUT_LOHNSTEIGERUNG_{{pkt_pauschale}}_";
    },
  },
  created() {
    this.startBetragrechner();
  },
  methods: {
    startBetragrechner() {
      let data = this.model;
      if (isFunction(this.setDataForVorschau)) {
        data = this.setDataForVorschau({ data });
      }
      this.postHttp({
        url: `betragsrechner/${ this.betragsrechner }/vorschau/`,
        urlParams: {
          afr_id: this.afrId,
          kontext: "antrag",
          // akp_id: this.position ? this.position.pk : null,
        },
        data,
      }).then(
        response => {
          this.setVorschau({ response });
          this.loadingBetrag = false;
        }
      );
    },

    setVorschau({ response }) {
      if (response.vorschau) {
        this.formElementOptions.helpText = response.vorschau.bezeichnung;
        this.modelPosition = response.vorschau.betrag;
      }
    },
  },
};
