"use strict";

import "restangular";
import FilterSet from "dias/core/table/filterSet";
import template from "./detail.jade";

class TasklogFilterSet extends FilterSet {
  constructor(Katalog) {
    super();
    this.addFilter("tl_kbez", {
      label: "Kurzbezeichnung",
      textInput: true,
      param: "tl_kbez",
      initialStatus: true
    });
    this.addFilter("tl_status", {
      label: "Status",
      multiSelect: false,
      param: "tl_status",
      choices: Katalog.getChoices("filter_tasklogstatus", "pk", "bez"),
    });
  }
}

class DetailController {
  /*@ngInject*/
  constructor($rootScope, $stateParams, $q, TaskLog, Katalog, AuthService) {
    this.loading = true;
    this.showTable = false;
    this.showDetails = true;
    this.Katalog = Katalog;
    this.AuthService = AuthService;

    TaskLog.one($stateParams.id).get().then(
      result => {
        this.taskLog = result;
        this.parseDetails();
        this.prepareSubTaskTable($stateParams, $q, TaskLog);
      }
    ).finally(() => this.loading = false);

    this.perms = this.AuthService.syncHasPerms(["tasklog.view.queue"]);
  }

  doShowTable() {
    this.showTable = true;
    this.showDetails = false;
  }

  parseDetails() {
    this.taskLog.wfs_list = [];
    if (!this.taskLog.tl_data || !this.taskLog.tl_data.details) {
      return;
    }
    for (const key of Object.keys(this.taskLog.tl_data.details)) {
      this.taskLog.wfs_list.push(this.taskLog.tl_data.details[key]);
    }
    this.taskLog.wfs_list.sort((o1, o2) => o1.pos - o2.pos);
  }

  prepareSubTaskTable($stateParams, $q, TaskLog) {
    const getDetailRoute = row => {
      return `root.tasklog.detail({id: '${ row.pk }'})`;
    };

    const tasklogTableDeferred = $q.defer();
    this.tasklogTable = tasklogTableDeferred.promise;

    const cols = [
      {
        label: "Status",
        sortField: "tl_status",
        disableDefaultAction: true,
        cellClass: "icon",
        cellTemplate: `<dias-task-log-status status="row.tl_status"></dias-task-log-status>`,
        sortable: true
      }, {
        label: "Bezeichnung",
        field: "tl_kbez",
        sortField: "tl_kbez",
        linkToDetail: true,
        sortable: true
      }, {
        label: "Unteraufgaben",
        field: "count_unteraufgaben",
        disableDefaultAction: true,
      }, {
        label: "Erstellt am",
        disableDefaultAction: true,
        sortingDefault: true,
        sortingDefaultDirection: "desc",
        sortField: "crdate",
        cellTemplate: `{{:: row.crdate | date:"short"}}`,
        sortable: true,
      }, {
        label: "Abgeschlossen am",
        disableDefaultAction: true,
        sortField: "tl_date_done",
        cellTemplate: `{{:: row.tl_date_done | date:"short"}}`,
        sortable: true,
      }, {
        label: "Objekt",
        disableDefaultAction: true,
        sortField: "tl_objektname",
        field: "tl_objektname",
        sortable: true,
      }, {
        label: "Objekttyp",
        disableDefaultAction: true,
        sortField: "objekt_typ_bez",
        field: "objekt_typ_bez",
        sortable: true,
      }
    ];

    tasklogTableDeferred.resolve({
      dataSource: TaskLog.getUnteraufgaben($stateParams.id),
      // Template für Detailansicht
      tableTitle: "Unteraufgaben",
      // detailTemplate: "antragDetailRow.html",
      getDetailRoute: getDetailRoute,
      columnDefs: cols,
      // Aktionen je Zeile
      filter: new TasklogFilterSet(this.Katalog),
      filterName: "subtasklog",
    });
  }
}

export default {
  template: template(),
  controller: DetailController,
  controllerAs: "vm",
};
