"use strict";

import template from "./details.jade";
import controller from "./details.controller.js";

export default {
  template: template(),
  controller: controller,
  controllerAs: "vm"
};
