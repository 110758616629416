"use strict";

import angular from "angular";
import plzDirective from "./plz.directive";


const plzModule = angular.module("plz", [])

  .directive("diasPlz", plzDirective)
;

export default plzModule;
