import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import KofiErfassungBelegFormExtraField from "../../KofiErfassung/KofiErfassungBelegForm/ExtraField/ExtraField.vue";
import KofiErfassungBelegFormStandardField from "../../KofiErfassung/KofiErfassungBelegForm/StandardField/StandardField.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxErrors from "../../../../global/components/PuxErrors/PuxErrors.vue";
import PuxTabs from "../../../../global/components/PuxTabs/PuxTabs.vue";

import BelegWizardModulePropsMixin from "../BelegWizardModulePropsMixin";
import HttpMixin from "../../../../global/mixins/HttpMixin";

import KVertragskostenbelegStatusUUIDs from "../../../../const/KVertragskostenbelegStatusUUIDs";
import StandardFields from "../../ui/UiBelegeField/Fields";

import {
  assign,
  cloneDeep,
  find,
  forEach,
  get,
  isNull,
  isPlainObject,
  merge,
  omit,
  pickBy,
} from "lodash-es";

// @vue/component
export default {
  components: {
    FormElement,
    KofiErfassungBelegFormExtraField,
    KofiErfassungBelegFormStandardField,
    PuxButton,
    PuxErrors,
    PuxTabs,
  },
  mixins: [
    BelegWizardModulePropsMixin,
    HttpMixin,
  ],
  data() {
    return {
      belegPk: undefined,
      errors: undefined,
      htmlId: "pk_wizard",
      isSaving: false,
      model: cloneDeep(this.modelInitial),
      stepActiveNumber: 0,
      stepsCount: 0,
      stepsFieldsMain0: [
        {
          options: "optionsBelegPeriode",
          visible: "statusShowPeriode",
        },
        {
          options: "optionsBelegNummer",
          visible: "statusShowBelegnummer",
        },
        {
          options: "optionsBelegBez",
        },
        {
          options: "optionsBelegBetragErfasst",
          visible: "statusShowBelegBetragErfasst",
        },
        {
          options: "optionsPosition",
          visible: "statusShowOptionsPosition",
        },
      ],
      view: "v",
    };
  },
  computed: {
    idForTabs() {
      return `${ this.htmlId }_tabs`;
    },

    idForActiveTab() {
      return `step${ this.stepActiveNumber }`;
    },

    tabList() {
      const TAB_LIST = [];
      for (let i = 0; i < this.stepsCount; i++) {
        const STEP_NAME = `step${ i }`;
        if (this[STEP_NAME]) {
          TAB_LIST.push(this[STEP_NAME]);
        } else {
          console.error(`this.step${ i } ist nicht definiert`);
        }
      }
      return TAB_LIST;
    },

    modelForVorschau() {
      return cloneDeep(this.model.argument) || {};
    },

    idsForPreviousButton() {
      return `${ this.htmlId }_btn_previous`;
    },

    idsForNextButton() {
      return `${ this.htmlId }_btn_next`;
    },

    isButtonsDisabled() {
      return false;
    },

    isPreviousButtonVisible() {
      return this.stepActiveNumber > 0;
    },

    textForNextButton() {
      return this.isStepLast ? "_BTN_SAVE_" : "_BTN_WIZARD_NEXT_";
    },

    isStepLast() {
      return this.stepActiveNumber >= this.stepsCount - 1;
    },

    isBelegStatusUnvollstandig() {
      const STATUS = get(this.beleg, "status.pk");
      return !STATUS || STATUS === KVertragskostenbelegStatusUUIDs.UNVOLLSTAENDIG;
    },

    belegPkLocal() {
      if (this.beleg) {
        return this.beleg.pk;
      }
      return this.belegPk;
    },

    isCurrentStepValid() {
      return this.stepsValid[this.stepActiveNumber];
    },

    stepsValid() {
      const STEPS_VALID = {};
      for (let i = 0; i < this.stepsCount; i++) {
        STEPS_VALID[i] = this.isStepValid({ stepIndex: i });
      }
      return STEPS_VALID;
    },

    tabsDisabled() {
      const TABS_DISABLED = {};
      for (let i = 0; i < this.stepsCount; i++) {
        TABS_DISABLED[i] = this.isTabDisabled({ stepIndex: i });
      }
      return TABS_DISABLED;
    },

    optionsListForPuxErrors() {
      const OPTIONS_LIST = [];
      for (let i = 0; i < this.stepsCount; i++) {
        OPTIONS_LIST.push(...this.getOptionsForStep({ stepIndex: i }));
      }
      return OPTIONS_LIST;
    },

    tabsErrors() {
      if (!this.errors) {
        return {};
      }
      const TABS_ERRORS = {};
      for (let i = 0; i < this.stepsCount; i++) {
        TABS_ERRORS[i] = this.hasTabError({ stepIndex: i });
      }
      return TABS_ERRORS;
    },

    standardFields() {
      return cloneDeep(StandardFields);
    },
  },
  watch: {
    modelInitial: {
      handler() {
        this.model = merge({}, this.model, this.modelInitial);
      },
      deep: true,
    },
  },
  methods: {
    changeTab({ tab }) {
      this.saveStepData({
        stepActiveNumber: tab.index,
      });
    },

    onNextStep() {
      const STEP_ACTIVE_NUMBER = this.stepActiveNumber + 1;
      this.saveStepData({
        stepActiveNumber: STEP_ACTIVE_NUMBER,
        isNext: true,
      });
    },

    onPreviousStep() {
      const STEP_ACTIVE_NUMBER = this.stepActiveNumber - 1;
      this.saveStepData({
        stepActiveNumber: STEP_ACTIVE_NUMBER,
      });
    },

    httpFunctionForSaveStep({ isNext }) {
      return this.belegPkLocal ?
        ((this.isStepLast && isNext) ? "putHttp" : "patchHttp") :
        "postHttp";
    },

    saveStepData({ stepActiveNumber, isNext }) {
      this.isSaving = true;
      const DATA = this.isStepLast ? this.model : this.prepareDataForSaveStep();
      this.deleteErrors();
      this[this.httpFunctionForSaveStep({ isNext })]({
        url: this.getUrlSave({ isNext }),
        data: DATA,
      }).then(
        response => {
          this.setBelegPk(response);
          if (this.isStepLast && isNext) {
            this.$attrs.onReloadKofi({ statusReloadBelegDokumente: false, statusCallback: true });
            this.$attrs.onClose();
          } else {
            this.stepActiveNumber = stepActiveNumber;
            this.$attrs.onSetKofiShouldReloaded();
          }
        },
        errors => {
          this.onErrors(errors);
        }
      ).finally(
        () => {
          this.isSaving = false;
        }
      );
    },

    getUrlSave({ isNext }) {
      if (this.isBelegStatusUnvollstandig) {
        if (isNext && this.isStepLast) {
          if (!this.beleg) {
            return `${ this.urlSave }${ this.belegPk }/`;
          }
          return this.urlSave;
        }
        const URL_SAVE = this.urlSave.replace("belege", "unvollstandige_belege");
        if (this.belegPk) {
          return `${ URL_SAVE }${ this.belegPk }/`;
        }
        return URL_SAVE;
      }
      return this.urlSave;
    },

    setBelegPk(response) {
      if (!this.belegPkLocal) {
        this.belegPk = response.pk;
      }
    },

    onErrors(errors) {
      let errors_local = errors.data || {};
      if (isPlainObject(errors_local.extra)) {
        errors_local = assign({}, omit(errors_local, ["extra"]), errors_local.extra);
      }
      if (isPlainObject(errors_local.standardfelder)) {
        errors_local = assign({}, omit(errors_local, ["standardfelder"]), errors_local.standardfelder);
      }
      this.errors = errors_local;
    },

    deleteErrors() {
      this.errors = undefined;
    },

    onChangeFormElementLocal() {
      this.$attrs.onOnChange({
        modelForVorschau: this.modelForVorschau,
      });
    },

    isStepValid({ stepIndex }) {
      const CURRENT_STEPS_FIELDS = `stepsFields${ stepIndex }`;
      let valid = true;
      forEach(this[CURRENT_STEPS_FIELDS], item => {
        if (!this.isFieldInvisibleOrValid(item)) {
          valid = false;
          return false;
        }
      });
      return valid;
    },

    isFieldInvisibleOrValid({ options, visible, modelPath }) {
      const OPTIONS = get(this, options);
      if ((visible && !get(this, visible)) ||
        !OPTIONS.required) {
        return true;
      }
      return !!get(this.model, this.getPathToModel({
        options: OPTIONS,
        modelPath,
      }));
    },

    getPathToModel({ options, modelPath }) {
      let path = options.id;
      if (modelPath) {
        path = `${ modelPath }.${ path }`;
      }
      return path;
    },

    isTabDisabled({ stepIndex }) {
      if (!this.isCurrentStepValid || this.isSaving) {
        return true;
      }
      let tabDisabled = false;
      for (let i = 0; i < stepIndex; i++) {
        if (!this.stepsValid[i]) {
          tabDisabled = true;
          break;
        }
      }
      return tabDisabled;
    },

    changeModelExtraField({ model, id }) {
      this.model.extra = this.model.extra || {};
      this.model.extra[id] = model;
      this.onChangeFormElementLocal();
    },

    changeModelStandardField({ model, id }) {
      this.model.standardfelder = this.model.standardfelder || {};
      this.model.standardfelder[id] = model;
      this.onChangeFormElementLocal();
    },

    prepareDataForSaveStep() {
      const MODEL = cloneDeep(this.model);
      if (!this.isBelegStatusUnvollstandig) {
        if (!this.isStepLast) {
          const CURRENT_STEPS_FIELDS = `stepsFields${ this.stepActiveNumber }`;
          return pickBy(MODEL, (v, k) => find(this[CURRENT_STEPS_FIELDS], item => k === item.modelPath || k === item.options.id));
        }
        return MODEL;
      }
      for (let i = this.stepsCount; i > this.stepActiveNumber; i--) {
        const CURRENT_STEPS_FIELDS = `stepsFields${ i }`;
        forEach(this[CURRENT_STEPS_FIELDS], item => {
          this.deleteCurrentModelForStep({
            options: item.options,
            visible: item.visible,
            modelPath: item.modelPath,
            model: MODEL,
          });
        });
      }
      return MODEL;
    },

    deleteCurrentModelForStep({ options, visible, modelPath, model }) {
      if (visible && !get(this, visible)) {
        return;
      }
      const OPTIONS = get(this, options);
      const ID = OPTIONS.id;
      if (modelPath) {
        if (isNull(model[modelPath][ID])) {
          model[modelPath][ID] = undefined;
        }
      } else if (isNull(model[ID])) {
        model[ID] = undefined;
      }
    },

    getOptionsForStep({ stepIndex }) {
      const OPTIONS_FOR_STEP = [];
      const CURRENT_STEPS_FIELDS = `stepsFields${ stepIndex }`;
      forEach(this[CURRENT_STEPS_FIELDS], item => {
        const OPTIONS = get(this, item.options);
        OPTIONS_FOR_STEP.push(OPTIONS);
      });
      return OPTIONS_FOR_STEP;
    },

    hasTabError({ stepIndex }) {
      const OPTIONS = this.getOptionsForStep({ stepIndex });
      let hasError = false;
      forEach(OPTIONS, opt => {
        const ID = opt.id;
        if (this.errors[ID]) {
          hasError = true;
          return false;
        }
      });
      return hasError;
    },
  },
};
