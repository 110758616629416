import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SitzungDetailsMittelTableCellCurrency from "./SitzungDetailsMittelTableCellCurrency/SitzungDetailsMittelTableCellCurrency.vue";
import SmartTable from "../../../../global/components/table/table.vue";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";
import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

import { gettext } from "../../../../global/functions/utils";

import {
  cloneDeep,
  forEach
} from "lodash-es";

const TABLE_ID = "mittelverteilung";

// @vue/component
export default {
  name: "SitzungDetailsMittel",
  components: {
    PuxCloak,
    PuxTranslate,
    SitzungDetailsMittelTableCellCurrency,
    SmartTable,
  },
  props: {
    sitzungPk: {
      type: String,
      required: true,
    },
    sitzungUrl: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    const {
      getHttp,
    } = HttpAPI();

    return {
      filterCurrency,
      getHttp,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        data: [],
        rowActions: [],
        cols: [
          { label: "Tagesordnungspunkt",
            id: "tos_bez",
            templateCallback: ({ row }) => {
              if (row.isSum) {
                return `<strong>${ row.tos_bez }</strong>`;
              }
              return `<a href="${ this.sitzungUrl }top/${ row.pk }/" class="text-nwrap"><span>${ row.tos_bez }</span></a>`;
            },
          },
          { label: "_LBL_SITZUNGSDETAILS_MITTEL_BUDGET_",
            id: "tos_bereitgestellt",
            slot: "tos_bereitgestellt",
            className: "text-right",
            headerClassName: "text-right",
          },
          { label: "_LBL_SITZUNGSDETAILS_MITTEL_ANZAHL_ANTRAEGE_",
            id: "antraege",
            slot: "antraege",
            className: "text-right",
            headerClassName: "text-right",
          },
          { label: "_LBL_SITZUNGSDETAILS_MITTEL_BEANTRAGTE_SUMME_",
            id: "tos_bedarf",
            slot: "tos_bedarf",
            className: "text-right",
            headerClassName: "text-right",
          },
          { label: "_LBL_SITZUNGSDETAILS_MITTEL_BEWILLIGTE_SUMME_",
            id: "tos_bewilligt",
            slot: "tos_bewilligt",
            className: "text-right",
            headerClassName: "text-right",
          },
          { label: "_LBL_SITZUNGSDETAILS_MITTEL_PRIORITIZATION_",
            id: "priorisierungsstatus",
            slot: "priorisierungsstatus",
            className: "text-right",
            headerClassName: "text-right",
          },
        ],
        sortable: false,
        pagination: false,
        details: false,
        showHeader: false,
        hideTitle: true,
        showColsConfig: false,
        csv: {
          url: `${ this.sitzungUrl }mittelverteilung/`,
          status: true,
          title: "",
          params: {},
          label: "_LBL_SITZUNGSDETAILS_MITTEL_CSV_",
          csv_max_entries: "",
        },
      },
      status: {
        loading: true,
      },
      responseData: {},
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      return this.getHttp({
        url: `${ this.sitzungUrl }mittelverteilung/`,
      }).then(
        response => {
          this.responseData = cloneDeep(response);
          this.options.data = this.responseData.results;
          this.options.csv.status = this.responseData.csv_available;
          this.options.csv.csv_max_entries = this.responseData.csv_max_entries;
          this.options.csv.title = gettext("_TXT_SITZUNGSDETAILS_MITTEL_DOWNLOAD_CSV_");
          this.buildSumRow();
        }
      ).finally(
        () => this.status.loading = false,
      );
    },

    buildSumRow() {
      const SUM_ROW = {
        isSum: true,
        tos_bez: gettext("_TXT_SITZUNGSDETAILS_MITTEL_IN_TOTAL_"),
        tos_bereitgestellt: 0,
        antraege: 0,
        tos_bedarf: 0,
        tos_bewilligt: 0
      };

      forEach(this.responseData.results, result => {
        SUM_ROW.tos_bereitgestellt += Number(result.tos_bereitgestellt);
        SUM_ROW.antraege += Number(result.antraege);
        SUM_ROW.tos_bedarf += Number(result.tos_bedarf);
        SUM_ROW.tos_bewilligt += Number(result.tos_bewilligt);
      });

      this.options.data.push(SUM_ROW);
    },
  },
};
