"use strict";

import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";

const antragbasisdaten = angular.module("dias.standard_foerdermodule.antragbasisdaten", [
  foerdermoduleRegistry.name,
]);

antragbasisdaten.config(FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("antragbasisdaten", null);
})

;

export default antragbasisdaten;
