import BetragsrechnerMixin from "../Mixins/BetragsrechnerMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import {
  FilterCurrencyMixin,
} from "../../../../global/mixins/FiltersMixin";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenLohnjournal",
  mixins: [
    BetragsrechnerMixin,
    SyConfigMixin,
    HttpMixin,
    FilterCurrencyMixin,
  ],
  emits: [
    "changeModel",
    "updateValue",
  ],
  data() {
    return {
      personalConfig: undefined,
      labelStellenbezeichnung: "_LBL_PERSONALKOSTEN_LOHNJOURNAL_STELLENBEZEICHNUNG_",
      labelName: "_LBL_PERSONALKOSTEN_LOHNJOURNAL_NAME_",
      labelZeitraumVon: "_LBL_PERSONALKOSTEN_LOHNJOURNAL_ZEITRAUM_VON_",
      labelZeitraumBis: "_LBL_PERSONALKOSTEN_LOHNJOURNAL_ZEITRAUM_BIS_",
      labelVollzeitstelle: "_LBL_PERSONALKOSTEN_LOHNJOURNAL_VOLLZEITSTELLE_",
      labelStellenumfang: "_LBL_PERSONALKOSTEN_LOHNJOURNAL_STELLENUMFANG_",
      labelStellenumfangProjekt: "_LBL_PERSONALKOSTEN_LOHNJOURNAL_STELLENUMFANG_PROJEKT_",
      labelDokumentGeschwaerzt: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_CONFIRMATION_",
    };
  },
  computed: {
    optionsStellenbezeichnung() {
      return {
        id: "stellenbezeichnung",
        htmlId: this.htmlIdStellenbezeichnung,
        label: this.labelStellenbezeichnung,
        view: this.view,
        type: "text",
        maxlength: 300,
        required: true,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdStellenbezeichnung() {
      return `${ this.htmlId }_stellenbezeichnung`;
    },

    optionsName() {
      return {
        id: "name",
        htmlId: this.htmlIdName,
        label: this.labelName,
        view: this.view,
        type: "text",
        maxlength: 300,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdName() {
      return `${ this.htmlId }_name`;
    },

    optionsZeitraumVon() {
      return {
        id: "zeitraum_von",
        htmlId: this.htmlIdZeitraumVon,
        label: this.labelZeitraumVon,
        view: this.view,
        type: "date",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdZeitraumVon() {
      return `${ this.htmlId }_zeitraum_von`;
    },

    optionsZeitraumBis() {
      return {
        id: "zeitraum_bis",
        htmlId: this.htmlIdZeitraumBis,
        label: this.labelZeitraumBis,
        view: this.view,
        type: "date",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdZeitraumBis() {
      return `${ this.htmlId }_zeitraum_bis`;
    },

    optionsVollzeitstelle() {
      return {
        id: "vollzeitstelle",
        htmlId: this.htmlIdVollzeitstelle,
        label: this.labelVollzeitstelle,
        view: this.view,
        type: "float",
        addonBack: "_TXT_HOURS_PER_WEEK_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdVollzeitstelle() {
      return `${ this.htmlId }_vollzeitstelle`;
    },

    optionsStellenumfang() {
      return {
        id: "stellenumfang",
        htmlId: this.htmlIdStellenumfang,
        label: this.labelStellenumfang,
        view: this.view,
        type: "float",
        addonBack: "_TXT_HOURS_PER_WEEK_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdStellenumfang() {
      return `${ this.htmlId }_stellenumfang`;
    },

    optionsStellenumfangProjekt() {
      return {
        id: "stellenumfang_projekt",
        htmlId: this.htmlIdStellenumfangProjekt,
        label: this.labelStellenumfangProjekt,
        view: this.view,
        type: "float",
        addonBack: "_TXT_HOURS_PER_WEEK_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdStellenumfangProjekt() {
      return `${ this.htmlId }_stellenumfang_projekt`;
    },

    optionsJahresbrutto() {
      return {
        id: "jahresbrutto",
        htmlId: this.htmlIdJahresbrutto,
        label: this.labelJahresbrutto,
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    labelJahresbrutto() {
      if (this.kontext === "antrag") {
        return "_LBL_PERSONALKOSTEN_LOHNJOURNAL_JAHRESBRUTTO_";
      }
      if (this.kontext === "beleg") {
        return "_LBL_PERSONALKOSTEN_LOHNJOURNAL_JAHRESBRUTTO_BELEG_";
      }
      return "";
    },

    htmlIdJahresbrutto() {
      return `${ this.htmlId }_jahresbrutto`;
    },

    optionsVerguetungsgruppeBez() {
      return {
        id: "verguetungsgruppe_bez",
        htmlId: `${ this.htmlId }_verguetungsgruppe_bez`,
        label: "_LBL_PERSONALKOSTEN_LOHNJOURNAL_VERGUETUNGSGRUPPE_",
        view: this.view,
        type: "text",
        required: false,
        disabled: true,
        helpText: this.helpTextForVerguetungsgruppeBez,
        extra: this.extraForVerguetungsgruppeBez,
        classesHorizontal: this.classesHorizontal,
      };
    },

    helpTextForVerguetungsgruppeBez() {
      let helpText = "_TXT_PK_LJ_HELP_TEXT_";
      let suffix = "";

      if (this.vorschau.jahresbrutto) {
        helpText += "JAHRESBRUTTO_";
        suffix = "{{jahresbrutto}}_";
        if (this.vorschau.arbeitgeberpauschale) {
          helpText += "APAUSCHALE_";
          suffix += "{{apauschale}}_";
        } else {
          helpText += "NOT_APAUSCHALE_";
        }
        if (this.vorschau.lohnsteigerung) {
          helpText += "LOHNSTEIGERUNG_";
          suffix += "{{lohnsteigerung}}_";
        } else {
          helpText += "NOT_LOHNSTEIGERUNG_";
        }
      } else {
        helpText = "-";
      }
      if (suffix) {
        helpText += suffix;
      }
      return helpText;
      // _TXT_PK_LJ_HELP_TEXT_JAHRESBRUTTO_APAUSCHALE_LOHNSTEIGERUNG_{{jahresbrutto}}_{{apauschale}}_{{lohnsteigerung}}_
      // _TXT_PK_LJ_HELP_TEXT_JAHRESBRUTTO_NOT_APAUSCHALE_NOT_LOHNSTEIGERUNG_{{jahresbrutto}}_
      // _TXT_PK_LJ_HELP_TEXT_JAHRESBRUTTO_NOT_APAUSCHALE_LOHNSTEIGERUNG_{{jahresbrutto}}_{{lohnsteigerung}}_
      // _TXT_PK_LJ_HELP_TEXT_JAHRESBRUTTO_APAUSCHALE_NOT_LOHNSTEIGERUNG_{{jahresbrutto}}_{{apauschale}}_
    },

    extraForVerguetungsgruppeBez() {
      return {
        jahresbrutto: this.filterCurrency(this.vorschau.jahresbrutto || 0),
        apauschale: this.filterCurrency(this.vorschau.arbeitgeberpauschale || 0, "%"),
        lohnsteigerung: this.filterCurrency(this.vorschau.lohnsteigerung || 0, "%"),
      };
    },

    optionsDokumentGeschwaerzt() {
      return {
        id: "dokument_geschwaerzt",
        htmlId: this.htmlIdDokumentGeschwaerzt,
        label: this.labelDokumentGeschwaerzt,
        view: this.view,
        type: "one_checkbox",
        required: false,
        disabled: this.statusPruefer,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdDokumentGeschwaerzt() {
      return `${ this.htmlId }_dokument_geschwaerzt`;
    },

    errorLabels() {
      return {
        stellenbezeichnung: {
          label: this.labelStellenbezeichnung,
          link: true,
          id: this.htmlIdStellenbezeichnung,
        },
        name: {
          label: this.labelName,
          link: true,
          id: this.htmlIdName,
        },
        zeitraum_von: {
          label: this.labelZeitraumVon,
          link: true,
          id: this.htmlIdZeitraumVon,
        },
        zeitraum_bis: {
          label: this.labelZeitraumBis,
          link: true,
          id: this.htmlIdZeitraumBis,
        },
        vollzeitstelle: {
          label: this.labelVollzeitstelle,
          link: true,
          id: this.htmlIdVollzeitstelle,
        },
        stellenumfang: {
          label: this.labelStellenumfang,
          link: true,
          id: this.htmlIdStellenumfang,
        },
        stellenumfang_projekt: {
          label: this.labelStellenumfangProjekt,
          link: true,
          id: this.htmlIdStellenumfangProjekt,
        },
        jahresbrutto: {
          label: this.labelJahresbrutto,
          link: true,
          id: this.htmlIdJahresbrutto,
        },
        dokument_geschwaerzt: {
          label: this.labelDokumentGeschwaerzt,
          link: true,
          id: this.htmlIdDokumentGeschwaerzt,
        },
      };
    },
  },
  created() {
    this.setStellenbezeichnungForModel();
    this.initErrorLabels();
    this.checkSyConfig();
    this.initValues();
  },
  methods: {
    setStellenbezeichnungForModel() {
      if (this.model.argument.stellenbezeichnung) {
        return;
      }
      const MODEL = cloneDeep(this.model);
      MODEL.argument.stellenbezeichnung = "N.N.";
      this.$emit("changeModel", { model: MODEL });
    },

    checkSyConfig() {
      this.personalConfig = this.getSyConfigsValue("personalkosten") || {};
    },

    initValues() {
      this.getHttp({
        url: this.getUrlPkt(),
      }).then(
        response => {
          if (!this.extra.pkt_id) {
            this.$emit("updateValue", { pkt_id: response.pkt_id });
          }
        }
      );
    },

    getUrlPkt() {
      // Wenn das Objekt (Antragskostenpos oder Beleg zu Vertragskostenpos)
      // bereits existiert, sollte die PKT_ID bereits da sein,
      // da sie beim Anlegen mit abgelegt wird:
      let pkt_id = null;
      if (this.model.argument) {
        pkt_id = this.model.argument.pkt_id;
      }
      // Wenn das Objekt neu ist, dann kommt die PKT-ID bei Belegen aus den Daten
      // der Antragskosten:
      if (!pkt_id && this.extra.argument && this.extra.argument.pkt_id) {
        pkt_id = this.extra.argument.pkt_id;
      }
      if (pkt_id) {
        return `personalkostentabellen/${ pkt_id }/`;
      }
      // Wenn die Personalkostentabelle bis jetzt nicht ermittelt werden konnte
      // z.B. bei neuen Antragskosten oder bei neuen Belegen zu neuen
      // Vertragspositionen, dann muss die für den Antrag gültigte Pkt
      // ermittelt werden:

      const ANTRAG_ID = this.extra.regel.afr_aid;
      const PERSONALKOSTETYP_ID = this.extra.modelParameter.personalkostentyp;
      return `personalkostentypen/${ PERSONALKOSTETYP_ID }/get_gueltige_pkt/?a_id=${ ANTRAG_ID }`;
    }
  },
};
