"use strict";

import template from "./details.jade";
import BaseWizardController from "dias/foerderantrag/base.wizard.controller";

import EntscheidungEditTemplate from "./entscheidung.edit.jade";
import EntscheidungEditController from "./entscheidung.edit.controller";
import ProtokollEditTemplate from "./protokoll.edit.jade";
import ProtokollEditController from "./protokoll.edit.controller";
import {
  MAPPING,
} from "../../../../vue/client/vue/components/Geschaeftsregel/Module/Module";
import {
  findIndex,
} from "lodash-es";

class SitzungsantragDetailController extends BaseWizardController {
  /*@ngInject*/
  constructor(
    $rootScope,
    $scope,
    $state,
    $q,
    $stateParams,
    $filter,
    diasConfirmDialog,
    diasModalDialog,
    KAufgabe,
    Sitzungsantrag,
    SitzungsFoerderantrag,
    DiasNotification,
    AuthService,
    Foerdermodule,
    FoerdermoduleRegistry,
    FoerderangebotAnzeigekontextUUIDs,
    $window,
    moment,
    SyConfigs,
    FavoritenUtils,
    FoerderregelAppkontexte,
    $anchorScroll,
    $timeout,
    i18n,
    RegelAppkontextUUIDs,
  ) {
    super(
      $q,
      Foerdermodule,
      FoerdermoduleRegistry,
      FoerderangebotAnzeigekontextUUIDs,
      AuthService,
      i18n
    );
    this.MAPPING = MAPPING;
    this.FoerderregelAppkontexte = FoerderregelAppkontexte;
    this.Sitzungsantrag = Sitzungsantrag;
    this.SitzungsFoerderantrag = SitzungsFoerderantrag;
    this.DiasNotification = DiasNotification;
    this.notification = DiasNotification.page;
    this.AuthService = AuthService;
    this.FavoritenUtils = FavoritenUtils;
    this.SyConfigs = SyConfigs;
    this.$state = $state;
    this.$filter = $filter;
    this.$rootScope = $rootScope;
    $anchorScroll.yOffset = 210;
    this.$anchorScroll = $anchorScroll;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.window = $window;
    this.$state = $state;
    this.RegelAppkontextUUIDs = RegelAppkontextUUIDs;
    moment.locale("de");

    this.entscheidungEditTemplate = EntscheidungEditTemplate;
    this.entscheidungEditController = EntscheidungEditController;
    this.protokollEditTemplate = ProtokollEditTemplate;
    this.protokollEditController = ProtokollEditController;

    this.antragConfig = this.SyConfigs.wert("foerderantrag") || {};
    this.sitzungsantragConfig = this.SyConfigs.wert("sitzungsantraege") || {};
    this.updateModuleFromVue = this.updateModuleFromVue.bind(this);

    this.initData();
    this.loadData();
    this.getUser();
  }

  initData() {
    this.loading = true;

    this.statusHide = {};
    this.snapshotActive = {};
    this.currentSnapshotStatus = {};

    this.docAnzeige = this.sitzungsantragConfig.anzeige;
    this.docPermissions = ["dokument.view", "notizen.view"];
    this.dokumentePermissions = {
      view: "dokument.view",
      create: "dokument.create",
      delete: "dokument.delete",
      update: "dokument.update",
      update_gueltigkeit: "",
    };

    this.foerderdokumenteModel = [];
    this.steps.currentStep = 0;
    this.zuschussinfo = {};

    this.anmerkungLabel = this.gettext("Auflage");
    this.protokollLabel = this.gettext("Protokoll");

    this.initNotizenData();
  }

  initNotizenData() {
    this.notizOptions = {
      fuerSitzung: true,
      fuerSitzungDefault: true,
      orgChoices: [],
      noOrgText: "Um Notizen zum Antrag erstellen zu können, wählen Sie bitte zunächst eine Antragstellerorganisation für diesen Antrag aus."
    };
  }

  loadData() {
    this.Sitzungsantrag.one(this.$stateParams.id).get().then(
      result => {
        this.sla = result;
        this.perms = this.AuthService.syncHasPerms([
          "sitzungen.protokoll.view",
          "sitzungen.protokoll.update",
          "tops.antrag.decide",
          "sitzungen.antrag.view",
        ], this.sla.user_permissions);
        this.initialStepMap(result.antrag);
        this.setAntrag(result.antrag);
      },
      () => {
        this.DiasNotification.page.error("Fehler beim Laden der Antragsdaten");
        this.$state.go("root.sitzungsantraege");
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }


  initialStepMap() {
    // this.stepMap[this.UEBERSICHT].template = "uebersicht";
    delete this.stepMap[this.UEBERSICHT];
    this.stepMap[this.FOERDERANGEBOT].template = "angebot";
    this.stepMap[this.FOERDERANGEBOT].kbez = this.gettext("_LBL_SITZUNG_ANTRAG_RUBRIK_FOERDERANGEBOT_KURZBEZEICHNUNG_");
    this.stepMap[this.ORGANISATION].template = "module";
    this.stepMap[this.AUFLAGEN].template = "module";
    this.stepMap[this.BESCHEIDE].template = "module";
    this.stepMap[this.ORGANISATION].kbez = this.gettext("_LBL_SITZUNG_ANTRAG_RUBRIK_ORGANISATION_KURZBEZEICHNUNG_");
    this.stepMap[this.KOSTEN_FINANZIERUNG].template = "kofi";
    this.stepMap[this.KOSTEN_FINANZIERUNG].setUp = () => {
      const setup = this.defaultSetUp(this.KOSTEN_FINANZIERUNG);
      if (setup) {
        setup.then(
          () => {
            // Kostenregeln aufsplitten, so dass die Regeln für den Kostenplan separat sind:
            const split_kopl = { kopl_rules: [], non_kopl_rules: [] };
            if (this.module[this.anzeigekontexte.kosten] !== undefined) {
              for (let i = 0; i < this.module[this.anzeigekontexte.kosten].length; i++) {
                const kosten_rule = this.module[this.anzeigekontexte.kosten][i];
                if (kosten_rule.modulgruppen.indexOf("kosten") > -1) {
                  split_kopl.kopl_rules.push(kosten_rule);
                } else {
                  split_kopl.non_kopl_rules.push(kosten_rule);
                }
              }
              this.module[this.anzeigekontexte.kosten] = split_kopl;
            }

            // Finanzierungsregeln aufsplitten, so dass die Regeln für den Finanzierungsplan separat sind:
            const split_fipl = { fipl_rules: [], non_fipl_rules: [] };
            if (this.module[this.anzeigekontexte.finanzierung] !== undefined) {
              for (let f = 0; f < this.module[this.anzeigekontexte.finanzierung].length; f++) {
                const finanzierung_rule = this.module[this.anzeigekontexte.finanzierung][f];
                if (finanzierung_rule.modulgruppen.indexOf("finanzierung") > -1) {
                  split_fipl.fipl_rules.push(finanzierung_rule);
                } else {
                  split_fipl.non_fipl_rules.push(finanzierung_rule);
                }
              }
              this.module[this.anzeigekontexte.finanzierung] = split_fipl;
            }
            this.update_zuschussinfo();
          }
        );
      }
    };
    this.stepMap[this.VORHABEN].template = "module";

    // Keine Förderdokumente, nur Sitzungsdokumente
    delete this.stepMap[this.ANLAGEN];
    this.stepMap[this.SACHBEARBEITER_DOKUMENTE].template = "sitzungsdoks";
    this.stepMap[this.SACHBEARBEITER_DOKUMENTE].kbez = "Dokumente";
    this.stepMap[this.SACHBEARBEITER_DOKUMENTE].updateVisible = (...args) => this.updateImmerVisible(...args);
    this.stepMap[this.SACHBEARBEITER_DOKUMENTE].setUp = () => {
      this.stepMap[this.SACHBEARBEITER_DOKUMENTE].loaded = true;
    };

    this.stepMap[this.PRUEFUNG].template = "module";
    this.stepMap[this.PRUEFUNG].priority = 0;
    this.stepMap[this.PRUEFUNG].kbez = this.gettext("_LBL_SITZUNG_ANTRAG_RUBRIK_PRUEFUNG_KURZBEZEICHNUNG_");
    this.stepMap[this.PRUEFUNG].display_number = false;

    // //notizen
    // this.stepMap[this.VORHABEN].notizen = this.notiz;
    // this.stepMap[this.KOSTEN_FINANZIERUNG].notizen = this.notiz;

    this.stepMap.gutachten = {
      bez: this.gettext("Gutachten"),
      kbez: this.gettext("Gutachten"),
      key: "gutachten",
      priority: 99,
      data: { errors: null },
      moduleContexts: [],
      template: "gutachten",
      setUp: () => {
        this.stepMap.gutachten.loaded = true;
      },
      saveCallback: () => {},
      disabled: () => false,
      updateReadonly: () => {},
      updateHasBaseEditPerm: () => {},
      done: () => false,
      updateVisible: () => {
        this.stepMap.gutachten.visible = this.AuthService.syncHasPerms([
          "gutachten.view",
        ])["gutachten.view"];
      },
      updateSnapshot: () => {},
      updateSnapshotDiff: () => {},
    };

    this.stepMap.sitzung = {
      bez: "Sitzungsprotokoll",
      kbez: this.gettext("_TXT_SITZUNG_ANTRAG_PROTOKOLL_ZUR_SITZUNG_"),
      key: "sitzung",
      priority: 100,
      data: { errors: null },
      moduleContexts: [],
      template: "sitzung",
      setUp: () => {
        this.stepMap.sitzung.data.perms = this.perms;
        this.stepMap.sitzung.loaded = true;
      },
      saveCallback: () => {},
      disabled: () => false,
      updateReadonly: () => {},
      updateHasBaseEditPerm: () => {},
      done: () => false,
      updateVisible: () => {
        this.stepMap.sitzung.visible = true;
      },
      updateSnapshot: () => {},
      updateSnapshotDiff: () => {},
    };
    this.stepMap[this.ERKLAERUNGEN].template = "module";
    for (let i = 1; i < 31; i++) {
      this.stepMap["step_rubrik_" + i].template = "module";
    }
    for (let i = 1; i < 11; i++) {
      this.stepMap["step_rubrik_10_" + i].template = "module";
    }
  }


  getUser() {
    this.AuthService.getUser().then(
      user => this.user = user
    );
  }

  update_zuschussinfo() {
    // Die Zuschussinformationen müssen und dürfen nur ermittelt werden,
    // wenn der Antrag noch nicht entschieden ist:
    if (this.antrag && !this.antrag.isEntschieden()) {
      this.SitzungsFoerderantrag.one(this.antrag.pk).customGET("zuschuss").then(
        result => {
          this.zuschussinfo = result;
        }
      );
    }
  }


  showAktuelleAufgabeDetail() {
    this.aktuelleAufgabeDetailStatus = !this.aktuelleAufgabeDetailStatus;
  }

  setFoerderdokumenteModel() {
    this.foerderdokumenteModel = [];
    angular.forEach(this.module[this.anzeigekontexte.foerderangebot], value => {
      if (value.data && value.data.dokument) {
        this.foerderdokumenteModel.push(value);
      }
    });
  }


  defaultUpdateReadOnly(step) {
    step.data.readonly = true;
  }

  defaultUpdateHasBaseEditPerm(step) {
    step.data.hasBaseEditPerm = false;
  }

  groupModules(modules) {
    return this.FoerderregelAppkontexte.getList(
      { sitzungsart: this.sla.sitzungsart }
    ).then(
      response => {
        /* DAM-4607
        Aktuell wird keine Unterscheidung zwischen "initial ausblenden"
        und "nicht laden" gemacht, bis die Option mit eigener Berechtigung
        wieder implementiert wird. */
        const relevant = _.filter(
          response,
          rea => rea.appkontext === this.RegelAppkontextUUIDs.moduleSitzungSekundaer
                || rea.appkontext === this.RegelAppkontextUUIDs.moduleSitzungNichtLaden
        );
        const filteredModules = modules.filter(
          v => relevant.findIndex(rea => rea.regel === v.regel.regel) === -1
        );
        modules.splice(0, modules.length, ...filteredModules);
        super.groupModules(modules);
      }
    );
  }

  setAntrag(antrag) {
    super.setAntrag(antrag);
    antrag.block_link_ast = !this.sla.can_link_ast;
    this.antragLabel = this.$filter("spaced")(this.antrag.a_nr);
  }

  updateModuleFromVue({ group, response }) {
    this.$timeout(() => {
      if (group) {
        const INDEX = findIndex(this.module[group], ["regel.pk", response.regel.pk]);
        if (INDEX !== -1) {
          this.module[group].splice(INDEX, 1, response);
        }
      }
    });
  }
}

export default {
  template: template(),
  controller: SitzungsantragDetailController,
  controllerAs: "vm"
};
