import FormElement from "../../../../../../../libs/vue/client/vue/global/components/FormElement/FormElement.vue";
import Modal from "../../../../../../../libs/vue/client/vue/global/components/Modal/Modal.vue";
import PuxButton from "../../../../../../../libs/vue/client/vue/global/components/PuxButton/PuxButton.vue";

import HttpMixin from "../../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import NotificationMixin from "../../../../../../../libs/vue/client/vue/global/mixins/NotificationMixin";

import {
  cloneDeep,
  findIndex,
  forEach,
  isFunction,
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "TeilStudiengangModalHinzufügen",
  components: {
    FormElement,
    Modal,
    PuxButton,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    studiengang: {
      type: Object,
      required: true,
    },
    hochschuleObj: {
      type: Object,
      required: true,
    },
    urlSave: {
      type: String,
      default: "createteilstudiengaenge/",
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mantelStudiengaenge: [],
      optionsAddStudienfeld: [
        {
          type: "select",
          required: true,
          id: "faechergruppe",
          view: "v-alt",
          keyId: "fae_id",
          keyLabel: "fae_kbez",
          menuWidthAll: true,
          label: "Fächergruppe",
        },
        {
          type: "select",
          required: true,
          id: "studienbereiche",
          view: "v-alt",
          keyId: "sdb_id",
          keyLabel: "sdb_kbez",
          menuWidthAll: true,
          label: "Studienbereiche",
        },
        {
          type: "select",
          required: true,
          id: "studienfelder",
          view: "v-alt",
          keyId: "stf_id",
          keyLabel: "stf_kbez",
          menuWidthAll: true,
          label: "Studienfelder",
        }
      ],
      modelAddStudienfeld: {
        faechergruppe: undefined,
        studienbereiche: undefined,
        studienfelder: undefined,
      },
      dataAddStudienfeld: {
        faechergruppe: [],
        studienbereiche: [],
        studienfelder: [],
      },
      dataAddStudienfeldCopy: {
        faechergruppe: [],
        studienbereiche: [],
        studienfelder: [],
      },
      dataAddStudienfeldObj: {
        faechergruppe: {},
        studienbereiche: {},
        studienfelder: {},
      },
      statusOpenAddStudienfeld: true,
      statusAddStudienfeld: undefined,
      disabledStatusAddStudienfeld: true,
      model: {
        studienformen: [],
        standorte: [],
        studienfelder: [],
        art: undefined,
        hochschule: this.hochschuleObj.pk,
      },
      data: {
        standorte: [],
      },
      selectedStudienfelder: [],
      master: "e47fbd04-90ec-4f11-af4c-5e3be408aaf9",
      abschlussbez_freitext: "03ed4220-8b11-4254-865d-74ff717ceeb9",
      einzelstudiengang: "b3cbc90e-5687-46ff-867b-19f6b29e1559",
      options: {
        showCloseButton: true,
        list: [
          {
            id: "sg_bez",
            type: "text",
            label: "Name",
            required: true,
            maxlength: 200,
            view: "v-alt",
          },
          {
            id: "abschlussgrad",
            type: "select",
            deselect: true,
            label: "Abschlussgrad",
            view: "v-alt",
            keyId: "ag_id",
            keyLabel: "ag_kbez",
            menuWidthAll: true,
            url: "abschlussgrade/?fields=ag_id&fields=ag_kbez",
          },
          {
            id: "mastertyp",
            type: "select",
            deselect: true,
            label: "Mastertyp",
            view: "v-alt",
            keyId: "mt_id",
            keyLabel: "mt_kbez",
            menuWidthAll: true,
            url: "mastertypen/?fields=mt_id&fields=mt_kbez",
          },
          {
            id: "abschlussbezeichnung",
            type: "select",
            deselect: true,
            label: "Abschlussbezeichnung",
            view: "v-alt",
            keyId: "abs_id",
            keyLabel: "abs_kbez",
            menuWidthAll: true,
            url: "abschlussbezeichnungen/?fields=lat_id&fields=lat_kbez",
            helpText: "Wenn eine freie Bezeichnung bzw. Eingabe mehrerer Bezeichnungen gewünscht ist, wählen Sie bitte „sonstiges“ aus. Es wird dann ein zusätzliches Eingabefeld zur Verfügung gestellt."
          },
          {
            id: "sg_abschlussbez_freitext",
            type: "text",
            label: "weitere Abschlussbezeichnung",
            maxlength: 200,
            view: "v-alt",
            hide: true,
          },
          {
            id: "typ",
            type: "select",
            deselect: true,
            label: "Studiengangtyp",
            view: "v-alt",
            keyId: "kst_id",
            keyLabel: "kst_kbez",
            menuWidthAll: true,
            url: "studientypen/?fields=kst_id&fields=kst_kbez",
          },
          {
            id: "lehramtstyp",
            type: "select",
            deselect: true,
            label: "Lehramtstyp",
            view: "v-alt",
            keyId: "lat_id",
            keyLabel: "lat_kbez",
            menuWidthAll: true,
            url: "lehramtstypen/?fields=lat_id&fields=lat_kbez",
          },
          {
            id: "studienformen",
            type: "multiselect",
            label: "Studienformen",
            view: "v-alt",
            keyId: "sdf_id",
            keyLabel: "sdf_kbez",
            menuWidthAll: true,
            url: "studienformen/?fields=sdf_id&fields=sdf_kbez",
          },
          {
            id: "sg_regelstudienzeit",
            type: "number",
            label: "Regelstudienzeit",
            maxlength: 100,
            view: "v-alt",
          },
          {
            id: "regelstudieneinheit",
            type: "select",
            deselect: true,
            label: "Regelstudieneinheit",
            view: "v-alt",
            keyId: "szg_id",
            keyLabel: "szg_kbez",
            menuWidthAll: true,
            url: "regelstudieneinheiten/?fields=szg_id&fields=szg_kbez",
          },
          {
            id: "sg_laufnr",
            type: "text",
            label: "HSK Laufnummer",
            maxlength: 60,
            view: "v-alt",
            helpText: "Dieses Feld muss nicht ausgefüllt werden."
          },
          {
            id: "standorte",
            type: "multiselect",
            required: true,
            label: "Standorte",
            view: "v-alt",
            keyId: "ort_id",
            keyLabel: "ort_kbez",
            menuWidthAll: true,
          },
          {
            id: "sg_beginn_studienbetrieb",
            type: "date",
            label: "Beginn Studienbetrieb",
            view: "v-alt",
          },
          {
            id: "sg_aufnahmekapazitaet",
            type: "number",
            label: "Aufnahmekapazität pro Semester oder Jahr",
            view: "v-alt",
          },
          {
            id: "sg_studienanfaenger",
            type: "number",
            label: "Durchschnittliche Anzahl von StudienanfängerInnen pro Semester oder Jahr",
            view: "v-alt",
          },
          {
            id: "sg_absolventen",
            type: "number",
            label: "Durchschnittliche Anzahl von AbsolventInnen pro Semester oder Jahr",
            view: "v-alt",
          },
          {
            id: "sg_ects_punkte",
            type: "number",
            label: "ECTS Punkte",
            view: "v-alt",
          },
          {
            id: "sg_erfolgsquote",
            type: "number",
            label: "Erfolgsquote",
            view: "v-alt",
          },
          {
            id: "sg_studiendauer",
            type: "number",
            label: "Durchschnittliche Studiendauer (in Semestern/Trimestern/Quartalen)",
            view: "v-alt",
          },
          {
            id: "sg_studierende_w",
            type: "number",
            label: "Anzahl der weiblichen Studierenden",
            view: "v-alt",
          },
          {
            id: "sg_studierende_m",
            type: "number",
            label: "Anzahl der männlichen Studierenden",
            view: "v-alt",
          },
          {
            id: "sg_studierende_s",
            type: "number",
            label: "Anzahl der Studierenden (Geschlecht divers/keine Angabe)",
            view: "v-alt",
          },
          {
            id: "template",
            type: "template",
            slot: "template",
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  created() {
    this.initData();
    this.loadMantelStudiengaenge();
    this.createStandort();
  },
  methods: {
    onError({ error }) {
      this.errors = error;
      this.errorsClone = error;
    },

    initData() {
      this.loadDates({ url: "studienbereiche/", label: "studienbereiche" });
      this.loadDates({ url: "faechergruppen/", label: "faechergruppe" });
      this.loadDates({ url: "studienfelder/", label: "studienfelder" });
    },

    loadDates({ url, label }) {
      this.getListHttp({ url }).then(
        response => {
          this.dataAddStudienfeld[label] = response;
          this.dataAddStudienfeldCopy[label] = response;
          this.dataAddStudienfeldObj[label] = keyBy(response, "pk");
        }
      );
    },

    deleteStudienFeld(feld) {
      const indexList = findIndex(this.selectedStudienfelder, ["stf_id", feld.stf_id]);
      this.selectedStudienfelder.splice(indexList, 1);
      const index = this.model.studienfelder.indexOf(feld.stf_id);
      this.model.studienfelder.splice(index, 1);
      this.initStFelderData();
    },

    createStandort() {
      this.getListHttp({ url: `hochschulen/${ this.hochschuleObj.pk }/standorte/` }).then(
        response => {
          this.data.standorte = response;
        }
      );
    },

    onChangeModal({ id, model }) {
      if (isFunction(this[`onChangeModal_${ id }`])) {
        this[`onChangeModal_${ id }`]({ model });
      }
    },

    onChangeModal_abschlussbezeichnung({ model }) {
      const SG_ABSCHLUSSBEZ_FREITEXT_INDEX_IN_OPTIONS = findIndex(this.options.list, ["id", "sg_abschlussbez_freitext"]);
      if (model !== this.abschlussbez_freitext) {
        this.options.list[SG_ABSCHLUSSBEZ_FREITEXT_INDEX_IN_OPTIONS].hide = true;
      } else {
        this.options.list[SG_ABSCHLUSSBEZ_FREITEXT_INDEX_IN_OPTIONS].hide = false;
      }
    },

    onChangeModal_abschlussgrad({ model }) {
      const SG_ABSCHLUSSGRAD_INDEX_IN_OPTIONS = findIndex(this.options.list, ["id", "mastertyp"]);
      if (model !== this.master) {
        this.options.list[SG_ABSCHLUSSGRAD_INDEX_IN_OPTIONS].hide = true;
      } else {
        this.options.list[SG_ABSCHLUSSGRAD_INDEX_IN_OPTIONS].hide = false;
      }
    },

    showAddStudienfeld() {
      this.initStFelderData();
      this.statusAddStudienfeld = true;
      this.statusOpenAddStudienfeld = false;
    },

    closeAddStudienfeld() {
      this.statusAddStudienfeld = false;
      this.modelAddStudienfeld = {
        faechergruppe: undefined,
        studienbereiche: undefined,
        studienfelder: undefined,
      };
      this.statusOpenAddStudienfeld = true;
    },

    onSelectAddStudienfeld({ id, item }) {
      this[`onSelect_${ id }`]({ item });
    },

    onSelect_faechergruppe({ item }) {
      this.dataAddStudienfeldCopy.studienfelder = this.dataAddStudienfeld.studienfelder.filter(val => val.faechergruppe === item.pk);
      if (this.selectedStudienfelder.length) {
        forEach(this.model.studienfelder, feld => {
          const index = findIndex(this.dataAddStudienfeldCopy.studienfelder, ["stf_id", feld]);
          if (index !== -1) {
            this.dataAddStudienfeldCopy.studienfelder.splice(index, 1);
          }
        });
      }
      this.dataAddStudienfeldCopy.studienbereiche = this.dataAddStudienfeld.studienbereiche.filter(val => val.faechergruppe === item.pk);
      this.modelAddStudienfeld.studienbereiche = undefined;
      this.modelAddStudienfeld.studienfelder = undefined;
    },

    onSelect_studienbereiche({ item }) {
      this.dataAddStudienfeldCopy.studienfelder = this.dataAddStudienfeld.studienfelder.filter(val => {
        if (this.modelAddStudienfeld.faechergruppe) {
          return (val.faechergruppe === this.modelAddStudienfeld.faechergruppe) && (val.studienbereich === item.pk);
        }
        return (val.studienbereich === item.pk);
      });

      if (this.selectedStudienfelder.length) {
        forEach(this.model.studienfelder, feld => {
          const index = findIndex(this.dataAddStudienfeldCopy.studienfelder, ["stf_id", feld]);
          if (index !== -1) {
            this.dataAddStudienfeldCopy.studienfelder.splice(index, 1);
          }
        });
      }
      if (!this.modelAddStudienfeld.faechergruppe) {
        this.modelAddStudienfeld.faechergruppe = item.faechergruppe;
        this.modelAddStudienfeld.studienfelder = undefined;
      }
    },

    onSelect_studienfelder({ item }) {
      this.modelAddStudienfeld.faechergruppe = item.faechergruppe;
      this.modelAddStudienfeld.studienbereiche = item.studienbereich;
      this.disabledStatusAddStudienfeld = false;
    },

    saveStudienfeld() {
      this.model.studienfelder.push(this.modelAddStudienfeld.studienfelder);
      this.selectedStudienfelder.push(cloneDeep(this.modelAddStudienfeld));
      this.closeAddStudienfeld();
    },

    initStFelderData() {
      this.dataAddStudienfeldCopy = cloneDeep(this.dataAddStudienfeld);
      forEach(this.model.studienfelder, feld => {
        const index = findIndex(this.dataAddStudienfeld.studienfelder, ["stf_id", feld]);
        if (index !== -1) {
          this.dataAddStudienfeldCopy.studienfelder.splice(index, 1);
        }
      });
      this.modelAddStudienfeld.studienfelder = undefined;
      this.modelAddStudienfeld.studienbereiche = undefined;
      this.modelAddStudienfeld.faechergruppe = undefined;
    },

    loadMantelStudiengaenge() {
      this.loading = true;
      const mantelStudiengang = cloneDeep(this.studiengang);
      this.getListHttp({
        url: `studiengaenge/${ this.studiengang.pk }/mantelstudiengaenge/`,
        data: mantelStudiengang,
      }).then(
        response => {
          this.mantelStudiengaenge = response;
        }
      ).finally(
        () => {
          this.loading = false;
        }
      );
    },

    save() {
      this.model.art = this.einzelstudiengang;
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.postHttp({ url: this.urlSave, data }).then(
        response => {
          this.loadMantelStudiengaenge();
          this.addNotification({ text: "Studiengang angelegt." });
          this.close({ response, status: true });
          this.$goTo("root.studiengang.detail", { id: response.sg_id });
        },
        error => {
          this.onError({ error: error.data });
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
