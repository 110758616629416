"use strict";

import BaseModel from "dias/core/models/baseModel";
import Funktionsgruppen from "./funktionsgruppen.service";

class Rolle extends BaseModel {
  constructor() {
    super("rollen", {
      subPaths: { fgruppenPath: new Funktionsgruppen() }
    });
  }
}

export default Rolle;
