"use strict";

class KaufgabenKopierenController {
  /*@ngInject*/
  constructor($scope, $q, $state, DiasNotification) {
    this.DiasNotification = DiasNotification;
    this.$q = $q;
    this.$state = $state;
    this.KAufgabe = $scope.extras.KAufgabe;
    this.kaufgabe = $scope.extras.kaufgabe;
    this.model = {
      newAuKbez: this.kaufgabe.au_kbez + " (Kopie)",
      newAuKbezAdmin: this.kaufgabe.au_kbez_admin + " (Kopie)",
      newKbezBeobachter: this.kaufgabe.au_kbezbeobachter + " (Kopie)",
      newKbezDetail: this.kaufgabe.au_kbezdetail + " (Kopie)"
    };

    this.save = () => {
      return this.KAufgabe.one(this.kaufgabe.pk).create_copy(this.model.newAuKbez.trim(), this.model.newAuKbezAdmin.trim(), this.model.newKbezBeobachter.trim(), this.model.newKbezDetail.trim()).then(
        response => {
          this.DiasNotification.page.success("Kopie die Aufgabe erfolgreich erstellt");
          this.$state.go("root.kaufgaben.detail", { id: response.pk });
        },
        error => {
          this.onError(error);
          return this.$q.reject(error);
        }
      );
    };

    this.onError = error => {
      const errorsLabel = {
        au_kbez: "Kurzbeschreibung: ",
        au_kbez_admin: "Kurzbeschreibung in Administration: ",
        au_kbezbeobachter: "Kurzbeschreibung Beobachter: ",
        au_kbezdetail: "Kurzbeschreibung Detail: "
      };
      this.DiasNotification.form.clear();
      this.DiasNotification.form.error(error.data, "Bitte überprüfen Sie Ihre Eingaben.", {}, errorsLabel);
      this.errors = error.data;
    };
  }
}

export default KaufgabenKopierenController;
