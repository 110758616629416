"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";


class SitzungProtokollController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification) {
    super($q, $scope, DiasNotification);

    this.save = () => {
      return $scope.model.updateProtokoll(this.model.si_protokoll).then(
        () => {
          $scope.model.merge($scope.model, this.model);
        },
        // this.onError
        errors => {
          const errorsLabel = {
            ast_beschreibung: "Protokoll: "
          };
          return this.onErrors(errors, errorsLabel);
        }
      );
    };
  }
}

export default SitzungProtokollController;
