"use strict";

import template from "./csv.download.jade";

import modalTemplate from "./csv.async.modal.jade";
import modalController from "./csv.async.modal.controller";

class CsvDownloadController {
  /*@ngInject*/
  constructor(diasModalDialog, CsvDownload) {
    this.diasModalDialog = diasModalDialog;
    this.CsvDownload = CsvDownload;
    this.btn = this.btn === undefined ? true : this.btn;
    this.btnType = this.btnType || "default";
    this.btnIcon = this.btnIcon || "glyphicon-download";
    this.btnLabel = this.btnLabel || "CSV generieren";

    this.btnCls = undefined;
    if (this.btn) {
      this.btnCls = `btn btn-${ this.btnType }`;
    }
  }

  downloadAsyncCsv() {
    const extras = {
      baseUrl: this.baseUrl,
      action: this.action || "csv_async",
      downloadAction: this.downloadAction || "csv_download",
      urlParams: this.urlParams,
      btnType: this.btnType,
      btnIcon: this.btnIcon,
      btnLabel: this.btnLabel
    };

    this.btnDisabled = true;
    this.diasModalDialog({
      title: "CSV-Datei erstellen",
      template: modalTemplate,
      controller: modalController,
      extras: extras
    }).finally(
      () => this.btnDisabled = false
    );
  }
}

export default {
  template: template(),
  controller: CsvDownloadController,
  controllerAs: "vm",
  bindings: {
    async: "<csvAsync",
    baseUrl: "<csvBaseUrl",
    urlParams: "<csvUrlParams",
    btn: "<?csvStyleAsBtn",
    action: "@?csvAction",
    downloadAction: "@?csvDownloadAction",
    btnType: "@?csvBtnType",
    btnIcon: "@?csvBtnIcon",
    btnLabel: "@?csvBtnLabel",
    buttonClass: "<?",
  }
};
