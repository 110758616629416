import DashboardModule from "../../Dashboard/Module";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

// @vue/component
export default {
  name: "Hilfe",
  components: {
    ...DashboardModule,
    PageTabTitle,
    PuxCloak,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
  ],
  data() {
    return {
      hilfePk: undefined,
      dashboard: [],
      loading: true,
      classGrid: {},
    };
  },
  created() {
    this.setClassGrid();
    this.setSyConfig();
    this.initDashboard();
  },
  methods: {
    setClassGrid() {
      const CLASS_GRID = {};
      for (let i = 1; i <= 8; i++) {
        CLASS_GRID[i] = `flex-col col-dias-md-${ i } col-dias-sm-${ i * 2 }`;
      }
      this.classGrid = CLASS_GRID;
    },

    setSyConfig() {
      this.hilfePk = this.getSyConfigsValue("hilfe_pk");
    },

    initDashboard() {
      if (this.hilfePk) {
        this.getHttp({
          url: `hilfedashboard/${ this.hilfePk }/`
        }).then(
          response => {
            this.dashboard = response.current.config;
            this.loading = false;
          }
        );
      }
    }
  },
};
