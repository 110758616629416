"use strict";

import BaseModel from "dias/core/models/baseModel";

class Foerdermodule extends BaseModel {
  constructor() {
    super("module");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.defaultGroup = "default_grp";

    function getGroupName(groupName) {
      return groupName !== undefined ? groupName : collection.defaultGroup;
    }

    collection.getRequestEventName = groupName => {
      return "foerdermodule.save.request." + getGroupName(groupName);
    };

    collection.getResponseEventName = groupName => {
      return "foerdermodule.save.response." + getGroupName(groupName);
    };

    collection.getSignalEventName = (signal, reId) => {
      return `foerdermodule.signal.${ signal }.${ reId }`;
    };

    collection.getSignalEventNameSimple = signal => { // TODO: Workaround für DHW Präsentation
      return `foerdermodule.signal.${ signal }`;
    };

    collection.saveInputForRegel = (antragregel, input) => {
      return collection.one(antragregel.pk).customPUT(input);
    };

    collection.sendSignal = ($scope, signal, modul) => {
      let re_id = modul;
      while (re_id !== undefined && _.isObject(re_id)) {
        re_id = re_id.regel;
      }
      $scope.$root.$broadcast(collection.getSignalEventName(signal, re_id));
    };

    collection.sendSignalSimple = ($scope, signal) => { // TODO: Workaround für DHW Präsentation
      $scope.$root.$broadcast(collection.getSignalEventNameSimple(signal));
    };

    collection.registerSignalReceiver = ($scope, signal_empfang, cbMap) => {
      if (signal_empfang && signal_empfang.length) {
        signal_empfang.forEach(mse => {
          if (cbMap[mse.signal_kennung]) {
            if (mse.sender_reid) {
              $scope.$on(collection.getSignalEventName(mse.signal_kennung, mse.sender_reid), cbMap[mse.signal_kennung]);
            } else {
              $scope.$on(collection.getSignalEventNameSimple(mse.signal_kennung), cbMap[mse.signal_kennung]); // TODO: Workaround für DHW Präsentation
            }
          }
        });
      }
    };

    collection.respondToSave = ($scope, regelId, promise, groupName) => {
      $scope.$emit(collection.getResponseEventName(groupName), regelId, promise);
    };

    collection.listenToSaveRequest = ($scope, groupName, cb) => {
      $scope.$on(collection.getRequestEventName(groupName), cb);
    };

    collection.requestSave = ($scope, modules, groupName) => {
      const allDefer = this.$q.defer();
      const promises = [];
      const cb = (event, regelId, promise) => {
        promises.push(promise);
        if (modules.length === promises.length) {
          this.$q.all(promises).then(
            response => allDefer.resolve(response),
            errors => allDefer.reject(errors)
          );
        }
      };
      const deregister = $scope.$on(collection.getResponseEventName(groupName), cb);
      $scope.$broadcast(collection.getRequestEventName(groupName));
      return allDefer.promise.finally(deregister);
    };

    collection.groupedByAnzeigekontext = () => {
      const grouped = {};
      for (let i = 0; i < collection.length; i++) {
        if (grouped[collection[i].regel.anzeigekontext] === undefined) {
          grouped[collection[i].regel.anzeigekontext] = [];
        }
        grouped[collection[i].regel.anzeigekontext].push(collection[i]);
      }
      return grouped;
    };
    return collection;
  }

  extendModel(model) {
    model = super.extendModel(model);

    // Guess rules prefix by getting most used one
    const prefixes = {};
    for (const key in model.regel) {
      if (key.indexOf("_") !== -1) {
        const tmp = key.split("_", 1)[0];
        model.prefix = tmp;
        prefixes[tmp] = angular.isUndefined(prefixes[tmp]) ? 0 : prefixes[tmp] + 1;
      }
    }
    for (const prefix in prefixes) {
      if (prefixes[model.prefix] < prefixes[prefix]) {
        model.prefix = prefix;
      }
    }

    model.getParameter = () => {
      const argument = model.prefix.concat("_argument");
      const parameter = {};
      for (const key in model.regel[argument]) {
        parameter[key] = model.regel[argument][key].wert;
      }
      return parameter;
    };

    const saveFkt = userInput => {
      return model.customPUT(userInput, model.regel.pk);
    };
    model.save = saveFkt;
    model.put = saveFkt;

    return model;
  }
}

export default Foerdermodule;
