import BetragsrechnerMixin from "../Mixins/BetragsrechnerMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import {
  gettext,
} from "../../../../global/functions/utils";

// @vue/component
export default {
  name: "Honorarkostenrechner",
  mixins: [
    BetragsrechnerMixin,
    SyConfigMixin,
    HttpMixin,
  ],
  data() {
    return {
      labelTaetigkeitBez: "_LBL_HONORARKOSTENRECHNER_TAETIGKEIT_BEZ_",
      labelQualifikationBez: "_LBL_HONORARKOSTENRECHNER_QUALIFIKATION_BEZ_",
      labelZeiteinheit: "_LBL_HONORARKOSTENRECHNER_ZEITEINHEIT_",
      labelAnzahlZeiteinheiten: "_LBL_HONORARKOSTENRECHNER_ANZAHL_ZEITEINHEITEN_",
      labelKostenProZeiteinheit: "_LBL_HONORARKOSTENRECHNER_KOSTEN_PRO_ZEITEINHEIT_",
      labelDokumentGeschwaerzt: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_CONFIRMATION_",
    };
  },
  computed: {
    optionsTaetigkeitBez() {
      return {
        id: "taetigkeit_bez",
        htmlId: this.htmlIdTaetigkeitBez,
        label: this.labelTaetigkeitBez,
        view: this.view,
        type: "text",
        maxlength: 300,
        required: true,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdTaetigkeitBez() {
      return `${ this.htmlId }_taetigkeit_bez`;
    },

    optionsQualifikationBez() {
      return {
        id: "qualifikation_bez",
        htmlId: this.htmlIdQualifikationBez,
        label: this.labelQualifikationBez,
        view: this.view,
        type: "text",
        maxlength: 300,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdQualifikationBez() {
      return `${ this.htmlId }_qualifikation_bez`;
    },

    optionsZeiteinheit() {
      return {
        id: "zeiteinheit",
        htmlId: this.htmlIdZeiteinheit,
        label: this.labelZeiteinheit,
        view: this.view,
        type: "select",
        required: true,
        data: this.optionsZeiteinheitData,
        keyLabel: "label",
        keyId: "value",
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdZeiteinheit() {
      return `${ this.htmlId }_zeiteinheit`;
    },

    optionsZeiteinheitData() {
      return [
        {
          label: gettext("_TXT_HOUR(S)_"),
          value: "h",
        },
        {
          label: gettext("_TXT_DAY(S)_"),
          value: "d",
        },
      ];
    },

    optionsAnzahlZeiteinheiten() {
      return {
        id: "anzahl_zeiteinheiten",
        htmlId: this.htmlIdAnzahlZeiteinheiten,
        label: this.labelAnzahlZeiteinheiten,
        view: this.view,
        type: "float",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdAnzahlZeiteinheiten() {
      return `${ this.htmlId }_anzahl_zeiteinheiten`;
    },

    optionsKostenProZeiteinheit() {
      return {
        id: "kosten_pro_zeiteinheit",
        htmlId: this.htmlIdKostenProZeiteinheit,
        label: this.labelKostenProZeiteinheit,
        view: this.view,
        type: "float",
        required: true,
        maxlength: 9,
        addonBack: "_HTML_SUFFIX_EURO_",
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdKostenProZeiteinheit() {
      return `${ this.htmlId }_kosten_pro_zeiteinheit`;
    },

    optionsDokumentGeschwaerzt() {
      return {
        id: "dokument_geschwaerzt",
        htmlId: this.htmlIdDokumentGeschwaerzt,
        label: this.labelDokumentGeschwaerzt,
        view: this.view,
        type: "one_checkbox",
        required: false,
        disabled: this.statusPruefer,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdDokumentGeschwaerzt() {
      return `${ this.htmlId }_dokument_geschwaerzt`;
    },

    errorLabels() {
      return {
        taetigkeit_bez: {
          label: this.labelTaetigkeitBez,
          link: true,
          id: this.htmlIdTaetigkeitBez,
        },
        qualifikation_bez: {
          label: this.labelQualifikationBez,
          link: true,
          id: this.htmlIdQualifikationBez,
        },
        zeiteinheit: {
          label: this.labelZeiteinheit,
          link: true,
          id: this.htmlIdZeiteinheit,
        },
        anzahl_zeiteinheiten: {
          label: this.labelAnzahlZeiteinheiten,
          link: true,
          id: this.htmlIdAnzahlZeiteinheiten,
        },
        kosten_pro_zeiteinheit: {
          label: this.labelKostenProZeiteinheit,
          link: true,
          id: this.htmlIdKostenProZeiteinheit,
        },
        dokument_geschwaerzt: {
          label: this.labelDokumentGeschwaerzt,
          link: true,
          id: this.htmlIdDokumentGeschwaerzt,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
  },
};
