import ContextPanel from "../../../../../global/components/ContextPanel/ContextPanel.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "IjfTemplateTreeTr",
  components: {
    ContextPanel,
    PuxTranslate,
    PuxButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    spalten: {
      type: Array,
      required: true,
    },
    getPaddingByLevel: {
      type: Function,
      required: false,
      default: undefined,
    },
    getColWidthByLevel: {
      type: Function,
      required: false,
      default: undefined,
    },
    openModalCreateOrEdit: {
      type: Function,
      required: false,
      default: () => {},
    },
    openModalDeleteItem: {
      type: Function,
      required: false,
      default: () => {},
    },
    onExpand: {
      type: Function,
      required: false,
      default: undefined,
    },
    expanded: {
      type: Boolean,
      required: false,
    },
    showMoveUpButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMoveDownButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    moveUp: {
      type: Function,
      required: false,
      default: () => {},
    },
    moveDown: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  setup() {
    const {
      filterBoolean,
    } = FiltersAPI();

    return {
      filterBoolean,
    };
  },
  computed: {
    getPadding() {
      return this.getPaddingByLevel({ level: this.level });
    },

    getColWidth() {
      return this.getColWidthByLevel({ level: this.level });
    },

    idButtonEdit() {
      return `#${ this.item.pk }_edit`;
    },

    idButtonDelete() {
      return `#${ this.item.pk }_delete`;
    },

    idButtonUp() {
      return `#${ this.item.pk }_move_up`;
    },

    idButtonDown() {
      return `#${ this.item.pk }_move_down`;
    },

    expandButtonId() {
      return `#btnexpand_${ this.item.pk }`;
    },

    expandIcon() {
      return !this.expanded ? "chevron-down" : "chevron-up";
    },

    nameColumnStyle() {
      const widthForOneLevel = 24;

      return {
        "padding-left": !this.item.children || this.item.children.length === 0 
          ? `${ widthForOneLevel * (this.level + 1) + 8 }px` 
          : `${ widthForOneLevel * (this.level) + 4 }px`
      };
    },

    moveUpButtonStyle() {
      return { visibility: this.showMoveUpButton ? "visible" : "hidden" };
    },

    moveDownButtonStyle() {
      return { visibility: this.showMoveDownButton ? "visible" : "hidden" };
    },

    editDeleteButtonsStyle() {
      return { visibility: !this.item.children.length ? "visible" : "hidden" };
    },
  },
  methods: {
    onExpandClick() {
      this.onExpand();
    },

    openModalCreateOrEditLocal() {
      this.openModalCreateOrEdit({
        element: this.item,
        parent: undefined,
      });
    },

    openModalDeleteItemLocal() {
      this.openModalDeleteItem({
        element: this.item,
      });
    },

    moveUpLocal() {
      this.moveUp(this.item);
    },

    moveDownLocal() {
      this.moveDown(this.item);
    },
  },
};
