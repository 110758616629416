"use strict";

import BetragsrechnerRegistry from "../services";

import BetragsrechnerFerien from "./betragsrechner.js";
import BetragsrechnerFerienController from "./controller";

const BetragsrechnerFerienModul = angular.module("dias.betragsrechner.BetragsrechnerFerienModul", [
  BetragsrechnerRegistry.name,
]);

BetragsrechnerFerienModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("ferienkostenrechner", BetragsrechnerFerien);
})
  .component("ferienkosten", BetragsrechnerFerienController);

export default BetragsrechnerFerienModul;
