"use strict";

import BaseModel from "dias/core/models/baseModel";
import BaseRegelModel from "./baseRegelModel";

class Foerderangebotinstrumente extends BaseModel {
  constructor() {
    super("foerderinstrumente", {
      subPaths: { regeln: new BaseRegelModel("instrumentregeln") }
    });
  }
}

export default Foerderangebotinstrumente;
