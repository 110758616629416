import {
  getCurrentInstance,
  ref,
} from "vue";

import AngularLink from "../../../../../../libs/vue/client/vue/global/components/AngularLink/AngularLink.vue";
import SystemakkreditierungDetailsItem from "./SystemakkreditierungDetailsItem/SystemakkreditierungDetailsItem.vue";
import PuxButton from "../../../../../../libs/vue/client/vue/global/components/PuxButton/PuxButton.vue";
import PuxDropdown from "../../../../../../libs/vue/client/vue/global/components/PuxDropdown/PuxDropdown.vue";
import PuxIcon from "../../../../../../libs/vue/client/vue/global/components/PuxIcon/PuxIcon.vue";
import PuxPageDetails from "../../../../../../libs/vue/client/vue/global/components/PuxPageDetails/PuxPageDetails.vue";
import Spaced from "../../../../../../libs/vue/client/vue/global/components/Spaced/Spaced.vue";

import AkkreditierungenAPI from "../../compositionAPI/AkkreditierungenAPI";

// @vue/component
export default {
  name: "SystemakkreditierungDetails",
  components: {
    AngularLink,
    SystemakkreditierungDetailsItem,
    PuxButton,
    PuxDropdown,
    PuxIcon,
    PuxPageDetails,
    Spaced,
  },
  setup() {
    const APP = getCurrentInstance();
    const $stateParams = APP.appContext.config.globalProperties.$stateParams;
    const akkreditierungHistoriePk = $stateParams.id;
    const baseUrl = "systemakkreditierungen";
    const loading = ref({
      global: true,
      shortcut: true,
    });

    const {
      akkreditierungen,
      assembleVersionen,
      loadAkkrhistorie,
      pageTitleExtra,
      programmakkreditierungTitleSecondary,
    } = AkkreditierungenAPI({
      baseUrl,
      akkreditierungHistoriePk,
    });

    const initData = async() => {
      await loadAkkrhistorie();

      loading.value.global = false;
      loading.value.shortcut = false;
    };

    initData();

    return {
      akkreditierungen,
      assembleVersionen,
      loadAkkrhistorie,
      pageTitleExtra,
      programmakkreditierungTitleSecondary,

      loading,
    };
  },
};
