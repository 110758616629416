"use strict";

import BaseModel from "dias/core/models/baseModel";


class Mitglieder extends BaseModel {
  constructor() {
    super("mitglieder");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.addNew = data => {
      return collection.customPOST(data);
    };

    return collection;
  }

  extendModel(model) {
    model = super.extendModel(model);

    model.updateLeiter = data => {
      return model.customPUT(data, "update_leiter");
    };
   

    return model;
  }
}

export default Mitglieder;
