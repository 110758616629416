"use strict";

import parameterServices from "../services";
import template from "./config.jade";
import rotemplate from "./readonly.jade";
import controller from "./controller";

const parametermoduleMultiSelect = angular.module("dias.foerderregeln.parameter.multi_select", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("multi_select", "parametermodul-multi-select");
  })
  .component("parametermodulMultiSelect", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulMultiSelectRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: rotemplate(),
    controller: controller,
    controllerAs: "vm",
  });
export default parametermoduleMultiSelect;
