import ZuschussMeldung from "../../KFDetail/ZuschussMeldung/ZuschussMeldung.vue";

import InfoproviderStatuses from "../../../../const/InfoproviderStatuses";
import {
  createNamespacedHelpers,
} from "vuex";
import {
  get,
} from "lodash-es";
const {
  mapGetters,
} = createNamespacedHelpers("snapshotKF");

// @vue/component
export default {
  components: {
    ZuschussMeldung,
  },
  computed: {
    meldungen() {
      return get(this.infoprovider, `details.${ this.regel.afr_id }.detail_meldungen`, []);
    },

    meldungenNotDetails() {
      return get(this.infoprovider, `details.${ this.regel.afr_id }.meldungen`, []);
    },

    statusZuschuss() {
      return get(this.infoprovider, `details.${ this.regel.afr_id }.status`);
    },

    statusShowProzente() {
      // Im Zuschusskontext werden keine Prozente angezeigt
      if (this.kontext === "zuschuss") {
        return false;
      }
      // Wenn eine Zuschussberechnung durchgeführt werden konnte:
      if (this.infoprovider && this.infoprovider.status === InfoproviderStatuses.PROCESSOR_STATUS_OK) {
        return true;
      }
      // Wenn keine Zuschussberechnung durchgeführt wurde:
      if (this.infoprovider && !this.infoprovider.status) {
        return this.antragFinanzierungsart.afa_prozent !== null;
      }
      return true;
    },

    snapshotZuschuss() {
      return this.SNAPSHOT_ZUSCHUSS_GET || {};
    },

    snapshotZuschussDetailMeldungen() {
      return get(this.snapshotZuschuss, `details[${ this.regel.afr_id }].detail_meldungen`, []);
    },

    ...mapGetters([
      "SNAPSHOT_ZUSCHUSS_GET",
    ]),
  },
};
