import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import { getTranslatedText } from "../../../../global/functions/utils";

// @vue/component
export default {
  name: "WorkflowfolgenDetailsCopyModal",
  components: {
    Modal,
    PuxTranslate,
  },
  props: {
    workflowfolge: {
      type: Object,
      required: true,
    },
    workflowfolgeUrl: {
      type: String,
      required: true,
    },
    selectorClose: {
      type: String,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const {
      postHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      postHttp,
    };
  },
  data() {
    return {
      model: {
        wfo_kbez_admin: undefined,
        wfo_kbez: undefined,
      },
      options: {
        required: true,
        list: [
          {
            id: "wfo_kbez_admin",
            type: "text",
            label: "_TXT_WORKFLOWFOLGENDETAILS_NAME_IN_ADMINISTRATION_",
            maxlength: 100,
            required: true,
            view: "v-alt",
          },
          {
            id: "wfo_kbez",
            type: "text",
            label: "_TXT_WORKFLOWFOLGENDETAILS_NAME_",
            maxlength: 60,
            required: true,
            view: "v-alt",
          },
        ],
      },
      loading: false,
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    confirmOptions() {
      return {
        okClass: "btn btn-primary",
        okLabel: "_MSG_WORKFLOWFOLGENDETAILS_BTN_COPY_",
        title: "_TITLE_WORKFLOWFOLGENDETAILS_WORKFLOWFOLGE_KOPIEREN_",
        okCallback: this.createCopy,
        cancelCallback: this.cancelCreateCopy,
        loading: false,
      };
    },

    translatedPlaceholderSuffixCopy() {
      return `(${ getTranslatedText("_TXT_WORKFLOWFOLGENDETAILS_SUFFIX_COPY_") })`;
    },
  },
  created() {
    this.setModel();
  },
  methods: {
    setModel() {
      this.model.wfo_kbez_admin = `${ this.workflowfolge.wfo_kbez_admin } ${ this.translatedPlaceholderSuffixCopy }`;
      this.model.wfo_kbez = `${ this.workflowfolge.wfo_kbez } ${ this.translatedPlaceholderSuffixCopy }`;
    },

    createCopy() {
      this.loading = true;
      this.postHttp({
        url: `${ this.workflowfolgeUrl }kopieren/`,
        data: this.model,
      }).then(
        response => {
          this.addNotification({
            text: "_MSG_WORKFLOWFOLGENDETAILS_WORKFLOWFOLGE_COPY_SUCCESS_"
          });
          this.$goTo("root.workflowfolgen.detail", { id: response.pk });
        },
        errors => {
          this.errors = errors.data;
          this.errorsClone = errors.data;
        },
      ).finally(
        () => this.loading = false,
      );
    },

    cancelCreateCopy() {
      this.close();
    },
  },
};
