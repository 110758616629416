"use strict";

class DynamischeFeldwertlisteReadOnlyController {
  /*@ngInject*/
  constructor(Katalog) {
    this.Katalog = Katalog;
    this.feldChoices = [];
    this.felderByPK = {};
    this.loadFelder();
    this.loading = true;
  }

  loadFelder() {
    this.Katalog.getKatalog(this.parameter[this.key].katalog, { fields: ["id", "bez"] }).then(
      result => {
        this.feldChoices = result;
        this.loading = false;
      }
    );
  }
}

export default DynamischeFeldwertlisteReadOnlyController;
