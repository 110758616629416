import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";

import translate from "../../../../global/directives/translate";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

import {
  cloneDeep,
  compact,
  find,
  get,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "IjfTemplateFeldModalCreateOrUpdate",
  components: {
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  props: {
    spalten: {
      type: Array,
      required: true,
    },
    element: {
      type: Object,
      required: false,
      default: undefined,
    },
    parent: {
      type: Object,
      required: false,
      default: undefined,
    },
    elementIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
    selectorClose: {
      type: [String, Array],
      required: true,
    },
    modelDefault: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
    feldtypen: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      addNotification,
      postHttp,
    } = HttpAPI();

    return {
      addNotification,
      postHttp,
    };
  },
  data() {
    return {
      saveIndex: undefined,
      model: {},
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
      selectedType: undefined,
    };
  },
  computed: {
    headerTranslateOptions() {
      return {
        text: this.headerText,
        extra: this.extraForTranslate,
      };
    },

    extraForTranslate() {
      return {
        element_bezeichnung: this.element ? this.element.ivf_name : (this.model.ivf_name || ""),
      };
    },

    headerText() {
      return this.element ?
        "_TXT_IJF_DETAILS_MODAL_EDIT_HEADER_{{element_bezeichnung}}_" :
        "_TXT_IJF_DETAILS_MODAL_CREATE_HEADER_";
    },

    msgSuccess() {
      return this.element ?
        "_MSG_IJF_DETAILS_MODAL_EDIT_SUCCESS_{{element_bezeichnung}}_" :
        "_MSG_IJF_DETAILS_MODAL_CREATE_SUCCESS_{{element_bezeichnung}}_";
    },

    msgError() {
      return this.element ?
        "_MSG_IJF_DETAILS_MODAL_EDIT_ERROR_{{element_bezeichnung}}_" :
        "_MSG_IJF_DETAILS_MODAL_CREATE_ERROR_{{element_bezeichnung}}_";
    },

    currentOptionsList() {
      const selectedTypeFields = get(this.selectedType, "ift_argument", null);
      const typeField = find(this.spalten, { id: "feldtyp" });
      typeField.change = this.changeType;

      return compact([
        ...this.spalten,
        selectedTypeFields,
      ]);
    },

    optionsModal() {
      return {
        showCloseButton: true,
        required: true,
        list: this.currentOptionsList,
      };
    },

    infoIText() {
      return "";
    },

    preparedDataForSave() {
      const modelLocal = cloneDeep(this.model);
      delete modelLocal.children;
      modelLocal.ivf_pos = this.elementIndex;

      return {
        ijfvorlagefields: [
          modelLocal,
        ],
      };
    },
  },
  created() {
    this.initModel();
    this.initSaveIndex();
  },
  methods: {
    initModel() {
      if (this.element) {
        this.model = cloneDeep(this.element);
        this.selectedType = find(this.feldtypen, { pk: this.model.feldtyp });
      } else {
        this.model = cloneDeep(this.modelDefault);
        this.model.parent = get(this.parent, "pk", null);
      }
      this.model.validierung_erforderlich = true;
    },

    initSaveIndex() {
      if (!isUndefined(this.elementIndex)) {
        this.saveIndex = this.elementIndex;
      }
    },

    changeType({ item }) {
      this.selectedType = find(this.feldtypen, { pk: item.pk });
    },

    saveLocal() {
      this.status.loading = true;
      this.clearErrors();
      this.save({
        model: this.preparedDataForSave,
      }).then(
        () => {
          this.addNotification({
            text: this.msgSuccess,
            extra: this.extraForTranslate,
          });
          this.close();
        },
        error => {
          this.onError({ error: error.data });
          this.addNotification({
            text: this.msgError,
            type: "error",
            extra: this.extraForTranslate,
          });
        }
      ).then(
        () => this.status.loading = false
      );
    },

    onError({ error }) {
      if (error) {
        this.errors = error;
        this.errorsClone = error;
      }
    },

    clearErrors() {
      this.errors = undefined;
      this.errorsClone = undefined;
    },
  },
};
