"use strict";

import BaseReadonlyController from "dias/ui/formsteps/formstepBaseReadonly.controller";

class SammelvertraegeReadonlyController extends BaseReadonlyController {
  /*@ngInject*/
  constructor($scope, $q) {
    super($scope);

    const getDetailRoute = row => "root.sammelvertrag.details({id: '" + row.pk + "'})";

    const svTableDeferred = $q.defer();
    this.svTable = svTableDeferred.promise;
    svTableDeferred.resolve({
      dataSource: $scope.model.sammelvertraege.getList,
      columnDefs: [
        { label: "Nummer", field: "sv_nr", linkToDetail: true },
        { label: "Budget", field: "budget.bud_kbez" },
        { label: "Zugewiesener Betrag",
          field: "sv_budget",
          cellFilter: "zeroNumber | currency",
          cellClass: "text-right",
        },
        { label: "Bewilligt",
          field: "bewilligt",
          cellFilter: "zeroNumber | currency",
          cellClass: "text-right",
        },
        { label: "Ausgezahlt",
          field: "ausgezahlt",
          cellFilter: "zeroNumber | currency",
          cellClass: "text-right",
        },
      ],
      emptyText: "Zu diesem Antragsteller existieren noch keine Weiterleitungsverträge",
      getDetailRoute: getDetailRoute,
      isDirty: scope => {
        return $scope.extra.lastChange > scope.vm.lastRefresh;
      }
    });
  }
}

export default SammelvertraegeReadonlyController;
