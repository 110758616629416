import Alert from "../../../../global/components/Alert/Alert.vue";
import KFPositionForm from "../KFPositionForm/KFPositionForm.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotItem from "../../../Snapshot/SnapshotItem/SnapshotItem.vue";
import ZuschussStandardPosition from "../ZuschussStandardPosition/ZuschussStandardPosition.vue";

import { FilterCurrencyMixin } from "../../../../global/mixins/FiltersMixin";

import {
  get,
  size,
  startsWith
} from "lodash-es";

// @vue/component
export default {
  name: "ZuschussStandard",
  components: {
    Alert,
    KFPositionForm,
    PuxButton,
    PuxIcon,
    PuxTranslate,
    SnapshotItem,
    ZuschussStandardPosition,
  },
  mixins: [
    FilterCurrencyMixin
  ],
  props: {
    htmlId: {
      type: String,
      required: true,
      info: "GR-Nummer",
    },
    regel: {
      type: Object,
      required: true,
      info: "this.modul.regel",
    },
    infoprovider: {
      type: Object,
      required: false,
      default: undefined,
      info: "Zuschuss",
    },
    readonly: {
      type: Boolean,
      required: false,
      info: "",
    },
    positionen: {
      type: Array,
      required: true,
      info: "Positionen",
    },
    singlePosition: {
      type: Boolean,
      required: false,
      default: () => true,
      info: "Von dieser Pauschale wird im Hintergrund eine einzelne Position verwaltet",
    },
    labelsPosition: {
      type: Object,
      required: false,
      default: () => ({}),
      info: "",
    },
    urlPositionen: {
      type: String,
      required: true,
      info: "vertraege/UUID/finanzierung/UUID/",
    },
    urlAddonAdd: {
      type: String,
      default: () => "einzelposition_erzeugen/",
      info: "URL-Part für Position erstellen.",
    },
    urlAddonUpdate: {
      type: String,
      default: () => "einzelposition_speichern/",
      info: "URL-Part für Position bearbeiten.",
    },
    urlAddonDelete: {
      type: String,
      default: () => "einzelposition_loeschen/",
      info: "URL-Part für Position löschen.",
    },
    betragsrechner: {
      type: String,
      required: true,
      info: "Betragsrechner",
    },
    katalogDetails: {
      type: Array,
      required: true,
      info: "",
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
      info: "",
    },
    extraForVorschau: {
      type: Object,
      required: false,
      default: undefined,
      info: "",
    },
    kontext: {
      type: String,
      required: true,
      info: "",
    },
    vertragFinanzierungsartFinanzierungsartKbez: {
      type: String,
      required: true,
      info: "this.vertragFinanzierungsart.finanzierungsart.fin_kbez",
    },
    vertragFinanzierungsartBetragBewilligtCurrency: {
      type: String,
      required: true,
      info: "this.filterCurrency(this.vertragFinanzierungsart.vf_betrag_bewilligt || 0)"
    },
    vertragProzentBewilligt: {
      type: String,
      required: true,
      info: "this.get_vertrag_prozent(\"bewilligt\")",
    },
    vertragProzentAktualisiert: {
      type: String,
      required: true,
      info: "this.get_vertrag_prozent(\"aktualisiert\")",
    },
    vertragBetragValueCurrency: {
      type: String,
      required: true,
      info: "this.filterCurrency(this.vertragBetragValue)",
    },
    vertragBetragValue: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    diffZuschuss: {
      type: Object,
      required: false,
      default: undefined,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    prepareModel: {
      type: Function,
      required: true,
    },
  },
  emits: [
    "updatePositionen",
    "updateValue",
  ],
  data() {
    return {
      statusShowPositionen: true,
      statusAddPosition: false,
      statusInfo: false
    };
  },
  computed: {
    buttonTogglePositionId() {
      return `${ this.htmlId }_position_toggle`;
    },

    ariaDescribedbyForButtons() {
      // TODO: Barrierefreiheit
      return "";
    },

    positionDetailsId() {
      return `${ this.htmlId }_position_details`;
    },

    buttonTogglePositionIcon() {
      return this.statusShowPositionen ?
        "chevron-up" :
        "chevron-down";
    },

    buttonTogglePositionTranslate() {
      return this.statusShowPositionen ?
        this.buttonTogglePositionTranslateHide :
        this.buttonTogglePositionTranslateShow;
    },

    buttonTogglePositionTranslateShow() {
      if (this.labelsPosition.buttonTogglePositionTextShow) {
        return this.labelsPosition.buttonTogglePositionTextShow;
      }
      return "_BTN_POSITIONEN_ZEIGEN_";
    },

    buttonTogglePositionTranslateHide() {
      if (this.labelsPosition.buttonTogglePositionTextHide) {
        return this.labelsPosition.buttonTogglePositionTextHide;
      }
      return "_BTN_POSITIONEN_VERBERGEN_";
    },

    infoproviderDetailsMeldungen() {
      return get(this.infoprovider, `details[${ this.regel.afr_id }].meldungen`, []);
    },

    statusShowButtonAddLocal() {
      return !this.statusAddPosition && !this.readonly && (
        !this.singlePosition || (this.singlePosition && !this.positionen.length)
      );
    },

    buttonAddId() {
      return `${ this.htmlId }_add`;
    },

    buttonAddTextLocal() {
      if (this.labelsPosition.buttonAddText) {
        return this.labelsPosition.buttonAddText;
      }
      return "_BTN_ADD_POSITION_";
    },

    buttonAddShow() {
      return !this.readonly || size(this.positionen) > 0;
    },

    htmlIdAddPosition() {
      return `${ this.htmlId }_new_position`;
    },

    urlSaveNew() {
      return `${ this.urlPositionen }${ this.urlAddonAdd }`;
    },

    buttonInfoId() {
      return `${ this.htmlId }_info_toggle`;
    },

    buttonInfoCloseId() {
      return `${ this.htmlId }_info_close`;
    },

    infoText() {
      return this.regel.afr_astbez;
    },

    buttonInfoTooltip() {
      return this.statusInfo ?
        "_TXT_FORMSTEP_DETAILS_INFO_I_TITLE_AUS_" :
        "_TXT_FORMSTEP_DETAILS_INFO_I_TITLE_EIN_";
    },

    extraTranslate() {
      return {
        kofiart: this.vertragFinanzierungsartFinanzierungsartKbez,
      };
    },

    isDiffBetrag() {
      return !!this.currentDiffZuschuss.betrag;
    },

    snapshotBetrag() {
      return this.currentDiffZuschuss.betragSnapshot;
    },

    currentDiffZuschuss() {
      return get(this.diffZuschuss, `details.${ this.regel.pk }`) || {};
    },

    diffPositionen() {
      return this.currentDiffZuschuss.positionen || {};
    },

    deletePositionen() {
      return this.diffPositionen.delete || [];
    },
  },
  methods: {
    togglePositionen() {
      this.statusShowPositionen = !this.statusShowPositionen;
    },

    addPositionLocal() {
      this.statusAddPosition = true;
    },

    closeAddPosition() {
      this.statusAddPosition = false;
    },

    saveLocal({ response }) {
      this.statusAddPosition = false;
      this.$emit("updatePositionen", { response });
    },

    updateValueLocal(arg) {
      this.$emit("updateValue", arg);
    },

    getValueCls(valueStr) {
      return (startsWith(valueStr, "-") && size(valueStr) > 1) ? "text-danger" : "";
    },

    getBewilligtCurrency({ position }) {
      if (this.singlePosition) {
        return this.vertragFinanzierungsartBetragBewilligtCurrency;
      }
      return this.filterCurrency(position.vfp_betrag_bewilligt || position.betrag_bewilligt);
    },

    getUrlAddonUpdate({ position }) {
      if (this.singlePosition) {
        return this.urlAddonUpdate;
      }
      return `${ position.pk }/${ this.urlAddonUpdate }`;
    },

    getUrlAddonDelete({ position }) {
      if (this.singlePosition) {
        return this.urlAddonDelete;
      }
      return `${ position.pk }/${ this.urlAddonDelete }`;
    },

    closeInfoBlock() {
      this.statusInfo = false;
    },

    toggleStatusInfo() {
      this.statusInfo = !this.statusInfo;
    },
  },
};
