"use strict";

import BaseModel from "dias/core/models/baseModel";


class Registrierungsfragen extends BaseModel {
  constructor() {
    super("registrierungsfragen");
  }
}

export default Registrierungsfragen;
