"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import models from "./models";

const newsModule = angular.module("core.news", [
  uiRouter,
  models.name
]);


newsModule.config($stateProvider => {
  $stateProvider

    .state("root.news", {
      url: "/ankuendigung/",
      params: { },
      views: {
        "main@root": {
          template: "<div vueinit><vue-ankuendigung-list></vue-ankuendigung-list></div>"
        }
      },
      data: {
        permissions: ["news.create"]
      }
    })
    .state("root.news.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><ankuendigung-details></ankuendigung-details></div>"
        }
      },
    })
  ;
})
;


export default newsModule;
