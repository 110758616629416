import {
  gettext,
  replaceText,
} from "../../functions/utils";

// @vue/component
export default {
  name: "PageTabTitle",
  props: {
    placeholder: {
      type: String,
      required: true,
      info: "Übersetzung-Placeholder für <title>",
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
      info: "Extra-Objekt für Übersetzung",
    },
  },
  watch: {
    placeholder() {
      this.setPageTabTitle();
    },

    extra() {
      this.setPageTabTitle();
    },
  },
  created() {
    this.setPageTabTitle();
  },
  methods: {
    setPageTabTitle() {
      const TEXT = replaceText({ text: gettext(this.placeholder), object: this.extra });
      let SUFFIX = gettext("_PAGE_TITLE_SUFFIX_GLOBAL_");
      if (SUFFIX) {
        SUFFIX = this.checkEmptyTitle(TEXT, SUFFIX);
      }
      document.title = `${ TEXT }${ SUFFIX || "" }`;
    },

    checkEmptyTitle(text, suffix) {
      if (text === "") {
        const INDEX = suffix.indexOf("-");
        if (INDEX !== -1) {
          if (INDEX <= 3) {
            return suffix.slice(INDEX + 1);
          }
        }
      } else {
        return suffix;
      }
    }
  },
  render() {
    return "";
  },
};
