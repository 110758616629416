"use strict";

import BaseController from "dias/foerderregeln/parameter/config.base.controller";

class EintraegeController extends BaseController {
  /*@ngInject*/
  constructor($scope, Katalog, i18n) {
    super();
    this.Katalog = Katalog;
    this.$scope = $scope;
    this.gettext = i18n.gettext;
    this.grouped = {};
    $scope.$watch("vm.parameter", () => this.init());
    $scope.$watch("vm.parameter['" + this.parameter[this.key].dependency + "'].wert", () => this.updateChoices());
  }

  init() {
    this.initKatalog = this.parameter[this.parameter[this.key].dependency].wert;
    this.initWert = this.parameter[this.key].wert || [];
    this.setWert(this.initWert);
  }

  updateChoices() {
    this.loading = true;
    this.selectedKatalog = this.parameter[this.parameter[this.key].dependency].wert;
    this.parameter[this.key].wert = [];
    if (this.selectedKatalog && (this.selectedKatalog !== this.initKatalog || this.initWert.length === 0)) {
      this.Katalog.getKatalog(this.selectedKatalog).then(
        response => this.setWert(response.plain())
      ).finally(() => this.loading = false);
    } else if (this.selectedKatalog && this.selectedKatalog === this.initKatalog) {
      this.setWert(this.initWert);
      this.$scope.$applyAsync(() => {
        this.loading = false;
      });
    } else {
      this.$scope.$applyAsync(() => {
        this.loading = false;
      });
    }
  }

  setWert(wert) {
    _.forEach(wert, el => {
      el.freitext = _.isNil(el.freitext) ? false : el.freitext;
      el.aktiv = _.isNil(el.aktiv) ? true : el.aktiv;
      el.uebergeordneter_wert = el.uebergeordneter_wert || null;
      if (el.uebergeordneter_wert) {
        el.bez = el.kaw_kbez;
      }
    });
    this.parameter[this.key].wert = _.map(wert, val => _.pick(val, ["pk", "bez", "aktiv", "freitext", "uebergeordneter_wert"]));
    this.grouped = _.groupBy(this.parameter[this.key].wert, el => el.uebergeordneter_wert);
  }

  onElementChanged(el) {
    // Element Aktiv gesetzt
    if (el.aktiv) {
      // -> Unterelemente aktivieren
      _.forEach(this.grouped[el.pk], ul => {
        ul.aktiv = true;
        this.onElementChanged(ul);
      });
      // -> Oberelemente aktivieren, wenn noch nicht aktiv
      let curUbergeordnet = el.uebergeordneter_wert;
      while (curUbergeordnet) {
        const oberEl = _.find(this.parameter[this.key].wert, ["pk", curUbergeordnet]);
        if (oberEl) {
          oberEl.aktiv = true;
          curUbergeordnet = oberEl.uebergeordneter_wert;
        } else {
          break;
        }
      }
    // Element Inaktiv gesetzt
    } else {
      // -> Unterlemente deaktivieren
      _.forEach(this.grouped[el.pk], ul => {
        ul.aktiv = false;
        this.onElementChanged(ul);
      });
    }
  }
}

export default EintraegeController;
