import translate from "../../../../../../../global/directives/translate";
import PuxButton from "../../../../../../../global/components/PuxButton/PuxButton.vue";
import HttpMixin from "../../../../../../../global/mixins/HttpMixin";
import Spinner from "../../../../../../../global/components/Spinner/Spinner.vue";
import {
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeTabelleReadOnlyElement",
  components: {
    PuxButton,
    Spinner,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    modelCurrent: {
      type: Object,
      required: true,
    },
    katalogeByPk: {
      type: Object,
      required: true,
    },
    objektkatalogeByPk: {
      type: Object,
      required: true,
    },
    kdokartByPk: {
      type: Object,
      required: true,
    },
    typeByKey: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusShowDetails: false,
      statusLoadingKatalog: false,
      katalogMap: {},
    };
  },
  computed: {
    getButtonText() {
      return this.statusShowDetails ?
        "(Konfiguration ausblenden)" :
        "(Konfiguration anzeigen)";
    },

    objektkatalogModel() {
      const MODEL_OBJEKTKATALOG = this.modelCurrent.objektkatalog;
      return this.objektkatalogeByPk[MODEL_OBJEKTKATALOG] && this.objektkatalogeByPk[MODEL_OBJEKTKATALOG].bez;
    },

    modelKatalog() {
      const MODEL_KATALOG = this.modelCurrent.katalog;
      return this.katalogeByPk[MODEL_KATALOG] && this.katalogeByPk[MODEL_KATALOG].bez;
    },

    modelDokart() {
      const MODEL_DOKART = this.modelCurrent.dokart;
      return this.kdokartByPk[MODEL_DOKART] && this.kdokartByPk[MODEL_DOKART].name;
    },

    modelSum() {
      return this.modelCurrent.sum ? "_TXT_YES_" : "_TXT_NO_";
    },

    modelHide() {
      return this.modelCurrent.hide ? "_TXT_YES_" : "_TXT_NO_";
    },

    modelRequired() {
      return this.modelCurrent.required ? "_TXT_YES_" : "_TXT_NO_";
    },
  },
  created() {
    this.loadKatalog();
  },
  methods: {
    loadKatalog() {
      const KATALOG = this.modelCurrent.katalog;
      if (this.modelCurrent.komplett !== false || !KATALOG) {
        return;
      }
      this.statusLoadingKatalog = true;
      this.getHttp({
        url: "katalog/?key=kanrede",
        urlParams: {
          key: KATALOG,
        },
        apiSaveId: KATALOG,
      }).then(
        response => {
          this.katalogMap = keyBy(response, "pk");
          this.statusLoadingKatalog = false;
        }
      );
    },

    toggleDetails() {
      this.statusShowDetails = !this.statusShowDetails;
    },
  },
};
