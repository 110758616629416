"use strict";

import "restangular";
import angular from "angular";
import CoreModels from "dias/core/models";

import FoerderangebotInfo from "./foerderangebotinfo.service";
import FfV2Foerderangebot from "./ff_v2_foerderangebot.service";
import FoerderorgInfo from "./foerderorginfo.service";

const modelsModule = angular.module("info.models", [
  "restangular",
  CoreModels.name
])

  .provider("FoerderangebotInfo", FoerderangebotInfo)
  .provider("FfV2Foerderangebot", FfV2Foerderangebot)
  .provider("FoerderorgInfo", FoerderorgInfo)
;

export default modelsModule;
