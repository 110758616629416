// Modul zur Erfassung von einfachen Kosten
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "dias/kosten_finanzierung/modul/standard_zuschuss/template.jade";
import FoerderantragModels from "dias/foerderantrag/models";
import VertragModels from "dias/vertrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const signal_zuschuss = angular.module("dias.kosten_finanzierung.modul.signal_zuschuss", [
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
  VertragModels.name,
]);

signal_zuschuss.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("signal_zuschuss", "modul-signal-zuschuss");
})

  .directive("modulSignalZuschuss", builder.directive(template, controller));

export default signal_zuschuss;
