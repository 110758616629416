"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";
import template from "./config.jade";
import templateRo from "./readonly.jade";
import controller from "./controller";
import controllerRo from "./readonly.controller.js";


const elementverzweigung = angular.module("dias.elementliste.parameter.elementliste", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("elementliste", "parametermodul-elementliste");
  })
  .component("parametermodulElementliste", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulElementlisteRo", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: templateRo(),
    controller: controllerRo,
    controllerAs: "vm",
  });
export default elementverzweigung;
