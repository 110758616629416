import SyConfigAPI from "../../global/compositionAPI/SyConfigAPI";

import Routing from "../../const/Routing";
import {
  setHeightAsideAndMenu,
} from "../../global/functions/utilsDOM";
import {
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "TheProandiFooter",
  props: {
    extraClass: {
      type: String,
      default: "",
    },
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      getSyConfigsValue,
    } = SyConfigAPI();

    return {
      getSyConfigsValue,
    };
  },
  data() {
    return {
      user: undefined,
      footer: {
        text: "&#169; ProUnix 2018",
        links: [
          {
            label: "Impressum",
            internal: true,
            url: "impressum"
          },
          {
            label: "Datenschutz",
            internal: true,
            url: "datenschutz"
          },
          {
            label: "Kontakt",
            internal: true,
            url: "kontakt"
          },
          {
            label: "Hilfen",
            internal: true,
            url: "hilfen",
            after_registration: true
          }
        ]
      },
      fixedVerticalHeightDiff: 0,
      footerTopDiffAbs: 0,
      version: undefined,
    };
  },
  computed: {
    filteredLinks() {
      return filter(this.footer.links, item => !item.after_registration || this.showLink);
    },

    footerText() {
      let footerText = this.footer.text;
      if (this.version) {
        footerText += ` Version: ${ this.version }`;
      }
      return footerText;
    },

    showLink() {
      return !!this.isAuthenticated;
    },
  },
  mounted() {
    this.setHeightForFixedVertical();
    if ("IntersectionObserver" in window) {
      this.initToggleFooter();
    } else {
      this.initToggleFooterIE();
    }
    window.addEventListener("resize", this.setHeightForFixedVertical);
  },
  created() {
    this.initData();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.setHeightForFixedVertical);
    window.removeEventListener("resize", this.setHeightForFixedVertical);
  },
  methods: {
    initData() {
      this.version = this.getSyConfigsValue("version");
      const footer = this.getSyConfigsValue("footer");
      if (footer) {
        this.footer = footer;
      }
    },

    initToggleFooterIE() {
      window.addEventListener("scroll", this.setHeightForFixedVertical);
    },

    initToggleFooter() {
      const options = {
        rootMargin: "0px",
        threshold: 0,
      };
      const observer = new IntersectionObserver(this.toggleFooter, options);
      observer.observe(this.$el);
    },

    setHeightForFixedVertical() {
      setHeightAsideAndMenu();
    },

    toggleFooter(entries) {
      if (entries[0].isIntersecting) {
        this.setHeightForFixedVertical(true);
      } else {
        this.setHeightForFixedVertical();
      }
    },

    getLink(url) {
      return Routing[url];
    },
  },
};
