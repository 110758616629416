"use strict";

class SitzungsmodulRegistryProvider {
  constructor() {
    this.registeredModules = {};
  }

  register(key, mainComponent, previewComponent) {
    if (this.registeredModules[key] !== undefined) {
      console.warn("Sitzungsmodul " + key + " wurde mehrmals registriert:", [this.registeredModules[key], mainComponent, previewComponent]);
      return;
    }
    this.registeredModules[key] = {
      main: mainComponent,
      preview: previewComponent
    };
  }

  unregister(key) {
    delete this.registeredModules[key];
    console.info("Sitzungsmodul " + key + " deregistriert.");
  }

  isRegisted(key) {
    if (angular.isUndefined(this.registeredModules[key])) {
      console.error("Sitzungsmodul " + key + " wurde abgefragt, ist jedoch nicht registriert!");
      return false;
    }
    return true;
  }

  /*@ngInject*/
  $get() {
    return {
      get: key => {
        if (this.isRegisted(key)) {
          return this.registeredModules[key];
        }
      },
      getMain: key => {
        if (this.isRegisted(key)) {
          return this.registeredModules[key].main;
        }
      },
      getPreview: key => {
        if (this.isRegisted(key)) {
          return this.registeredModules[key].preview;
        }
      },
      getList: () => {
        const list = [];
        for (const key in this.registeredModules) {
          list.push({ key: key, components: this.registeredModules[key] });
        }
        return list;
      }
    };
  }
}

export default SitzungsmodulRegistryProvider;
