"use strict";

import angular from "angular";
import HeightWatchDirective from "./heightWatch.directive";

const diasHeightWatch = angular.module("dias.heightWatch", [])

  .directive("heightWatch", HeightWatchDirective);

export default diasHeightWatch;
