"use strict";

import parameterServices from "../services";
import controller from "./controller";
import template from "./config.jade";
import templateRo from "./readonly.jade";


const parametermoduleBoolean = angular.module("dias.foerderregeln.parameter.boolean", [
  parameterServices.name,
]);

parametermoduleBoolean.config(ParametermoduleRegistryProvider => {
  ParametermoduleRegistryProvider.register("boolean", "parametermodul-boolean");
})
  .component("parametermodulBoolean", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
    key: "@",
  })

  .component("parametermodulBooleanRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: templateRo(),
    controller: controller,
    controllerAs: "vm",
  });


export default parametermoduleBoolean;
