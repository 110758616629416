import GeschaeftsregelModul from "../../../Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";

import { gettext } from "../../../../global/functions/utils";

export default {
  name: "SitzungDetailsDokumente",
  components: {
    GeschaeftsregelModul,
  },
  props: {
    sitzung: {
      type: Object,
      required: true,
    },
    sitzungUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      id: "SitzungAnlagen",
      dokumentePermissionsFromObj: {
        view: "sitzungdokument.view",
        create: "sitzungdokument.create",
        delete: "sitzungdokument.delete",
        update: "sitzungdokument.update",
        update_gueltigkeit: "",
      },
    };
  },
  computed: {
    parameter() {
      const PARAMETER = {
        frage: {
          typ: "string",
          wert: null,
        },
        header_info: {
          typ: "string",
          wert: null,
        },
        readonly: {
          typ: "boolean",
          wert: false,
        },
        anzeigeart: {
          typ: "single_choice",
          wert: "4014cc4c-2042-421f-a21b-d83b14520c7e",
          katalog: "anzeigeart"
        },
        pflichtfeld: {
          typ: "boolean",
          wert: true,
        },
        default_sort: {
          typ: "single_choice",
          wert: "-dokument.crdate",
        },
        antragsteller: {
          typ: "boolean",
          wert: false,
        },
        anzeige_details: {
          typ: "multi_select",
          wert: [
            "dokument.dok_titel",
            "dokument.dokumentart.name",
            "dokument.dok_posteingang",
            "pruefstatus.status",
            "pruefstatus_gesetzt_am",
            "geprueft_von",
            "dokument.crdate",
            "dokument.user",
            "dokument.dok_loeschdatum",
            "dokument.dok_dokumentname",
            "dokument.dok_typ",
            "dokument.dok_groesse",
            "dokument.dok_unterschriftsdatum",
          ],
        },
        header_optional: {
          typ: "string",
          wert: "_TXT_SITZUNGSDETAILS_ANLAGEN_SONSTIGE_DOKUMENTE_",
        },
        header_required: {
          typ: "string",
          wert: "_TXT_SITZUNGSDETAILS_ANLAGEN_SONSTIGE_PFLICHTDOKUMENTE_",
        },
        mehrfachauswahl: {
          typ: "boolean",
          wert: false,
        },
        allowed_mime_type: {
          typ: "json",
          wert: [],
        },
        header_required_info: {
          typ: "text",
          wert: "_TXT_SITZUNGSDETAILS_ANLAGEN_PFLICHTDOKUMENTE_INFO_",
        },
        validate_pflichtdoks: {
          typ: "boolean",
          wert: false,
          default: true,
        },
        gueltig_pflicht_felder: {
          typ: "multi_select",
          wert: [
            "von"
          ],
        },
        gueltig_fuer_dokument_hochladen: {
          typ: "boolean",
          wert: false,
        },
      };
      const HEADER_OPTIONAL_TEXT_TRANSLATE = gettext("_TXT_SITZUNGSDETAILS_ANLAGEN_SONSTIGE_DOKUMENTE_INFO_");
      if (HEADER_OPTIONAL_TEXT_TRANSLATE &&
        HEADER_OPTIONAL_TEXT_TRANSLATE !== "_TXT_SITZUNGSDETAILS_ANLAGEN_SONSTIGE_DOKUMENTE_INFO_") {
        PARAMETER.header_optional_info = {
          typ: "text",
          wert: "_TXT_SITZUNGSDETAILS_ANLAGEN_SONSTIGE_DOKUMENTE_INFO_",
        };
      }
      return PARAMETER;
    },

    extraStatic() {
      return {
        hideHeader: true,
      };
    },

    config() {
      return {
        module: {
          modulname: "anlagen_allgemein",
          regel: {
            regel: this.id,
            regel_nummer: `${ this.id }_1`,
            _argument: this.parameter,
          },
        },
        readonly: false,
      };
    },
  },
};
