"use strict";

import BaseModel from "dias/core/models/baseModel";
import Objektaufgabe from "dias/workflow/models/objektaufgabe.service";
import Kosten from "./kosten.service";
import Finanzierung from "./finanzierung.service";
import Vertreter from "dias/antragsteller/models/vertretungsberechtigter.service";
import Bankverbindung from "./bankverbindung.service";
import Foerdermodule from "dias/foerderantrag/foerdermodule/models/foerdermodule.service";
import ObjektBenachrichtigung from "dias/benachrichtigungen/models/objektbenachrichtigung.service";
import Dokumente from "dias/dokumente/models/dokumente.service";

class Auszahlungen extends BaseModel {
  constructor() {
    super("auszahlungen", {
      subPaths: {
        aufgaben: new Objektaufgabe(),
        kosten: new Kosten(),
        finanzierung: new Finanzierung(),
        vertretungsberechtigte: new Vertreter(),
        bankverbindungen: new Bankverbindung(),
        module: new Foerdermodule(),
        benachrichtigungen: new ObjektBenachrichtigung(),
        dokumente: new Dokumente()
      }
    });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.getKAufgaben = () => collection.customGET("kaufgaben");
    return collection;
  }


  extendModel(model) {
    model = super.extendModel(model);

    if (!angular.isObject(model)) {
      return model;
    }

    model.getPreview = () => model.customGET("vorschau");

    model.einreichenAuszahlung = () => model.customPOST({}, "einreichen");

    model.restangularizeAufgabe = aufgabe => this.Restangular.restangularizeElement(model, aufgabe, "aufgaben", {});

    if (model.aktuelle_aufgabe) {
      model.aktuelle_aufgabe = model.restangularizeAufgabe(model.aktuelle_aufgabe);
    }

    model.putVerwendungszweck = verwendungszweck => {
      return model.customPUT({ az_verwendungszweck: verwendungszweck }, "verwendungszweck_aendern");
    };

    model.putHinweis = hinweis => {
      return model.customPUT({ az_hinweis: hinweis }, "hinweis_aendern");
    };

    model.putZeitraum = (von, bis) => {
      return model.customPUT({ az_zeitraum_von: von, az_zeitraum_bis: bis }, "zeitraum_aendern");
    };

    model.saveAuszahlungsbetrag = (betrag, datum) => {
      return model.customPUT({ az_betrag: betrag, az_datum_ausgezahlt: datum }, "auszahlung_eintragen");
    };

    model.resetAuszahlungsbetrag = () => {
      return model.customPUT({}, "auszahlung_zuruecksetzen");
    };

    model.auszahlungsbetragAnpassen = (betrag, text) => {
      return model.customPOST({
        az_betrag_geaendert: betrag,
        az_betrag_geaendert_grund: text
      }, "auszahlungsbetrag_anpassen");
    };

    return model;
  }
}

export default Auszahlungen;
