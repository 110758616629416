import PuxButton from "../../../../../../../global/components/PuxButton/PuxButton.vue";
import translate from "../../../../../../../global/directives/translate";
import {
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeMehrfachauswahlReadOnlyElement",
  components: {
    PuxButton,
  },
  directives: {
    translate,
  },
  props: {
    currentModel: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    parentIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      statusShowMore: false,
    };
  },
  computed: {
    currentIndex() {
      return this.index + 1;
    },

    currentParentIndex() {
      return this.parentIndex + 1;
    },

    currentIndexText() {
      return `${ !isUndefined(this.parentIndex) ? `${ this.currentParentIndex }.` : "" }${ this.currentIndex }`;
    },

    textShowMore() {
      return this.statusShowMore ? "weniger anzeigen" : "mehr anzeigen";
    },

    idShowMore() {
      return `show_more_${ this.currentIndex }`;
    },

    statusFreitext() {
      return this.currentModel.freitext ? "_TXT_YES_" : "_TXT_NO_";
    },

    statusPflicht() {
      return this.currentModel.pflicht ? "_TXT_YES_" : "_TXT_NO_";
    }
  },
  methods: {
    showMore() {
      this.statusShowMore = !this.statusShowMore;
    },
  },
};
