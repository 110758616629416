import Modal from "../../../../../global/components/Modal/Modal.vue";
import moment from "moment";
import {
  findIndex,
  size,
  cloneDeep,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  components: {
    Modal,
  },
  props: {
    period: {
      type: Object,
      required: true,
    },
    periodGroup: {
      type: Array,
      required: true,
    },
    infoForBruttoGehalt: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      model: {},
      von1Index: undefined,
      von2Index: undefined,
      bis1Index: undefined,
      bis2Index: undefined,
      bisNotAfterDate: undefined,
      vonNotBeforeDate: undefined,
      errors: undefined,
      errorsClone: undefined,
      errorTextRequired: "_MSG_ERROR_REQUIRED_",
    };
  },
  methods: {
    setDateRange() {
      this.von1Index = findIndex(this.options.list, ["id", "von1"]);
      this.von2Index = findIndex(this.options.list, ["id", "von2"]);
      this.bis1Index = findIndex(this.options.list, ["id", "bis1"]);
      this.bis2Index = findIndex(this.options.list, ["id", "bis2"]);
      const CURRENT_PERIOD_INDEX = findIndex(this.periodGroup, ["von", this.period.von]);
      const PREVIOUS_PERIOD = this.periodGroup[CURRENT_PERIOD_INDEX - 1];
      const NEXT_PERIOD = this.periodGroup[CURRENT_PERIOD_INDEX + 1];

      if (PREVIOUS_PERIOD) {
        this.vonNotBeforeDate = moment(PREVIOUS_PERIOD.bis).add(1, "days").format("YYYY-MM-DD");
      } else {
        this.vonNotBeforeDate = this.period.info.von_aktuell;
      }

      if (NEXT_PERIOD) {
        this.bisNotAfterDate = moment(NEXT_PERIOD.von).subtract(1, "days").format("YYYY-MM-DD");
      } else {
        this.bisNotAfterDate = this.period.info.bis_aktuell;
      }

      this.setOptionsForDate({
        date: moment(this.period.bis).subtract(1, "days").format("YYYY-MM-DD"),
        optionName: "notAfter",
        index: this.von1Index,
      });
      this.setOptionsForDate({
        date: this.vonNotBeforeDate,
        optionName: "notBefore",
        index: this.von1Index,
      });
      this.setOptionsForDate({
        date: this.vonNotBeforeDate,
        optionName: "defaultValue",
        index: this.von1Index,
      });

      this.setOptionsForDate({
        date: this.period.bis,
        optionName: "notAfter",
        index: this.von2Index,
      });
      this.setOptionsForDate({
        date: moment(this.period.von).add(1, "days").format("YYYY-MM-DD"),
        optionName: "notBefore",
        index: this.von2Index,
      });
      this.setOptionsForDate({
        date: moment(this.period.von).add(1, "days").format("YYYY-MM-DD"),
        optionName: "defaultValue",
        index: this.von2Index,
      });

      this.setOptionsForDate({
        date: moment(this.period.bis).subtract(1, "days").format("YYYY-MM-DD"),
        optionName: "notAfter",
        index: this.bis1Index,
      });
      this.setOptionsForDate({
        date: this.period.von,
        optionName: "notBefore",
        index: this.bis1Index,
      });
      this.setOptionsForDate({
        date: this.period.von,
        optionName: "defaultValue",
        index: this.bis1Index,
      });

      this.setOptionsForDate({
        date: this.bisNotAfterDate,
        optionName: "notAfter",
        index: this.bis2Index,
      });
      this.setOptionsForDate({
        date: moment(this.period.von).add(1, "days").format("YYYY-MM-DD"),
        optionName: "notBefore",
        index: this.bis2Index,
      });
      this.setOptionsForDate({
        date: moment(this.period.von).add(1, "days").format("YYYY-MM-DD"),
        optionName: "defaultValue",
        index: this.bis2Index,
      });
    },

    setOptionsForDate({ date, optionName, index }) {
      this.options.list[index][optionName] = date;
    },

    changeDate({ id }) {
      this[`changeDate_${ id }`]();
    },

    changeDate_bis1() {
      let dateNotAfter = this.model.bis1;
      let dateNotBefore = this.model.bis1;
      if (!this.model.bis1) {
        if (this.model.von2) {
          dateNotAfter = this.model.von2;
        } else if (this.model.bis2) {
          dateNotAfter = this.model.bis2;
        } else {
          dateNotAfter = this.bisNotAfterDate;
        }

        if (this.model.von1) {
          dateNotBefore = this.model.von1;
        } else {
          dateNotBefore = this.vonNotBeforeDate;
        }
      } else if (!this.model.von2) {
        this.model.von2 = moment(this.model.bis1).add(1, "days").format("YYYY-MM-DD");
        this.changeDate_von2();
      }
      this.setOptionsForDate({
        date: dateNotAfter,
        optionName: "notAfter",
        index: this.von1Index,
      });

      this.setOptionsForDate({
        date: moment(dateNotBefore).add(1, "days").format("YYYY-MM-DD"),
        optionName: "notBefore",
        index: this.von2Index,
      });

      if (!this.model.von2) {
        this.setOptionsForDate({
          date: moment(dateNotBefore).add(1, "days").format("YYYY-MM-DD"),
          optionName: "notBefore",
          index: this.bis2Index,
        });
      }
    },

    changeDate_bis2() {
      let dateNotAfter = this.model.bis2;
      if (!this.model.bis2) {
        dateNotAfter = this.bisNotAfterDate;
      }

      this.setOptionsForDate({
        date: dateNotAfter,
        optionName: "notAfter",
        index: this.von2Index,
      });
      if (!this.model.von2) {
        this.setOptionsForDate({
          date: moment(dateNotAfter).subtract(1, "days").format("YYYY-MM-DD"),
          optionName: "notAfter",
          index: this.bis1Index,
        });
      }
      if (!this.model.bis1) {
        this.setOptionsForDate({
          date: moment(dateNotAfter).subtract(1, "days").format("YYYY-MM-DD"),
          optionName: "notAfter",
          index: this.von1Index,
        });
      }
    },

    changeDate_von1() {
      let dateNotBefore = this.model.von1;
      if (!this.model.von1) {
        dateNotBefore = this.vonNotBeforeDate;
      }

      this.setOptionsForDate({
        date: dateNotBefore,
        optionName: "notBefore",
        index: this.bis1Index,
      });
      if (!this.model.bis1) {
        this.setOptionsForDate({
          date: moment(dateNotBefore).add(1, "days").format("YYYY-MM-DD"),
          optionName: "notBefore",
          index: this.von2Index,
        });
      }
      if (!this.model.von2) {
        this.setOptionsForDate({
          date: moment(dateNotBefore).add(1, "days").format("YYYY-MM-DD"),
          optionName: "notBefore",
          index: this.bis2Index,
        });
      }
    },

    changeDate_von2() {
      let dateNotAfter = this.model.von2;
      let dateNotBefore = this.model.von2;
      if (!this.model.von2) {
        if (this.model.bis2) {
          dateNotAfter = this.model.bis2;
        } else {
          dateNotAfter = this.bisNotAfterDate;
        }

        if (this.model.bis1) {
          dateNotBefore = this.model.bis1;
        } else if (this.model.von1) {
          dateNotBefore = this.model.von1;
        } else {
          dateNotBefore = this.vonNotBeforeDate;
        }
      } else if (!this.model.bis1) {
        this.model.bis1 = moment(this.model.von2).subtract(1, "days").format("YYYY-MM-DD");
        this.changeDate_bis1();
      }

      this.setOptionsForDate({
        date: moment(dateNotAfter).subtract(1, "days").format("YYYY-MM-DD"),
        optionName: "notAfter",
        index: this.bis1Index,
      });
      if (!this.model.bis1) {
        this.setOptionsForDate({
          date: moment(dateNotAfter).subtract(1, "days").format("YYYY-MM-DD"),
          optionName: "notAfter",
          index: this.von1Index,
        });
      }

      this.setOptionsForDate({
        date: dateNotBefore,
        optionName: "notBefore",
        index: this.bis2Index,
      });
    },

    save() {
      if (!this.validate()) {
        return;
      }
      this.close({ model: this.model });
    },

    validate() {
      let error = {};
      if (!this.model.von1) {
        error.von1 = [this.errorTextRequired];
      }
      if (!this.model.bis1) {
        error.bis1 = [this.errorTextRequired];
      }
      if (!this.model.von2) {
        error.von2 = [this.errorTextRequired];
      }
      if (!this.model.bis2) {
        error.bis2 = [this.errorTextRequired];
      }
      if (isFunction(this.validateLocal)) {
        error = this.validateLocal({ error });
      }
      this.errors = cloneDeep(error);
      this.errorsClone = cloneDeep(error);
      return !size(error);
    },
  },

};
