"use strict";

import angular from "angular";

import {
  isInteger,
  toNumber
} from "lodash-es";

class DiasNotification {
  constructor($alert, config) {
    this.name = "DiasNotification";
    this._$alert = $alert;

    this.alerts = [];

    // default configurations
    this.baseConfig = config;

    this.containerId = config.container.substring(1);
  }

  show(type, msg, title, options, label) {
    const config = {
      type: type,
      content: this._parseResponse(msg, label),
      title: title
    };
    const notification = angular.extend({}, this.baseConfig, options, config);
    this.alerts.push(this._$alert(notification));
  }

  clear() {
    for (let i = 0; i < this.alerts.length; i++) {
      (this.alerts[i].$promise.duration === false ? this.alerts[i].destroy : this.alerts[i].hide)();
    }
    this.alerts.splice(0, this.alerts.length);
  }

  success(msg, title, options) {
    this.show("success", msg, title, options);
  }
  warning(msg, title, options) {
    this.show("warning", msg, title, options);
  }
  error(msg, title, options, label) {
    this.show("danger", msg, title, options, label);
  }
  info(msg, title, options) {
    this.show("info", msg, title, options);
  }

  _parseResponse(response, label) {
    let data = response;
    if (angular.isObject(data) && response.data !== undefined) {
      data = response.data;
    }
    if (angular.isString(data)) {
      try {
        data = JSON.parse(data);
      } catch (SyntaxError) {
        // ignore
      }
    }
    let msg = "";
    let dataLabel = "";
    let dataLabelObj = "";
    if (angular.isString(data)) {
      return data;
    } else if (angular.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        // msg += this._parseResponse(data[i]) + "<br>";
        if (angular.isString(label)) {
          dataLabel = "<strong>" + label + "</strong>";
        }
        msg += dataLabel + this._parseResponse(data[i]);
      }
      return msg;
    } else if (angular.isObject(data)) {
      for (const property in data) {
        dataLabel = "";
        dataLabelObj = "";
        if (label && !isInteger(toNumber(property)) && label[property]) {
          dataLabelObj = label[property];
        } else if (angular.isString(label)) {
          dataLabel = "<strong>" + label + "</strong>";
        }
        msg += dataLabel + this._parseResponse(data[property], dataLabelObj) + "<br>";
      }
      return msg;
    }
    return "";
  }
}

export default DiasNotification;
