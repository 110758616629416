import AntragstellerMixin from "../AntragstellerMixin";
import {
  get,
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragstellerAuswahlDufo",
  mixins: [
    AntragstellerMixin,
  ],
  data() {
    return {
      statusDufo: true,
      dufoList: [],
      dufoObj: {},
    };
  },
  computed: {
    optionsDufo() {
      return {
        type: "select",
        label: "_LBL_GR_ANTRAGSTELLER_AUSWAHL_DUFO_IN_EINRICHTUNG_",
        id: "dufo",
        view: "v",
        htmlId: `${ this.htmlRegelNummer }_dufo`,
        keyId: "pk",
        keyLabel: "do_orgname",
        sortList: ["do_orgname"],
        search: true,
        searchList: ["do_orgname"],
      };
    },

    snapshotChangesDufoList() {
      const SNAPSHOT = this.snapshotModule || {};
      if (this.model.ast_is_dufo && SNAPSHOT.ast_is_dufo) {
        return [];
      }
      return [
        "dufo_obj.do_orgname",
        "dufo_obj.do_strasse",
        "dufo_obj.do_hsnr",
        "dufo_obj.do_plz",
        "dufo_obj.do_ort",
        "dufo_obj.land.lkz_bez",
        "dufo_obj.do_beschreibung",
      ];
    },

    selectorCloseDufo() {
      return `#${ this.htmlRegelNummer }_additional_org`;
    },
  },
  methods: {
    loadDurchfuehrendeorgeinheiten({ astObj, statusFirst }) {
      if (this.kontext === "sitzungsantrag") {
        if (this.obj.dufo_obj) {
          this.dufoObj = {
            [this.obj.dufo_obj.pk]: this.obj.dufo_obj,
          };
        }
        return;
      }
      this.canViewDufo = this.checkPermissionsSync({ perm: "durchfuehrendeorg.view", permArray: astObj.user_permissions });
      if (!this.canViewDufo) {
        this.dufoList = [];
        this.dufoObj = {};
        if (statusFirst) {
          this.extendModelWithDufoObj();
        }
        return;
      }
      const AST_PK = get(astObj, "pk");
      if (!AST_PK) {
        return;
      }
      this.statusDufoLoading = true;
      this.getHttp({
        url: `antragstellerorganisationen/${ AST_PK }/durchfuehrendeorgeinheiten/`
      }).then(
        response => {
          this.dufoList = response.results;
          this.dufoObj = keyBy(response.results, "pk");
          if (statusFirst) {
            this.extendModelWithDufoObj();
          }
          this.statusDufoLoading = false;
        }
      );
    },

    createDurchfuehrendeOrgeinheit() {
      this.statusDufoModalCreate = true;
    },

    closeDufoModalCreate({ dufo } = {}) {
      if (dufo) {
        this.dufoList.push(dufo);
        this.dufoObj = keyBy(this.dufoList, "pk");
        this.modelEdit.dufo = dufo.pk;
      }
      this.statusDufoModalCreate = false;
    },

    setModelForDurchfuehrendeorgeinheiten({ modelEdit }) {
      modelEdit.dufo = null;
      return modelEdit;
    },

    prepareToSave() {
      const DATA = {
        ast: this.modelEdit.ast,
        ast_is_dufo: this.modelEdit.ast_is_dufo,
      };
      if (!DATA.ast_is_dufo) {
        DATA.dufo = this.modelEdit.dufo;
      }
      return this.prepareToSaveAnuAndKommentar({ data: DATA });
    },
  },
};
