"use strict";

import "restangular";
import angular from "angular";

import CoreModels from "dias/core/models";
import Antragstellerorganisationen from "./antragstellerorganisationen.service";
import Durchfuehrendeorgeinheiten from "./durchfuehrendeorgeinheiten.service";
import FoerderorgTyp from "./foerderorgtyp.service";
import Foerderorganisation from "./foerderorganisation.service";
import Foerderorgnutzer from "./foerderorgnutzer.service";
import Vertreter from "./vertretungsberechtigter.service";
import KBuchfuehrungsmethode from "./kbuchfuehrungsmethode.service";

const modelsModule = angular.module("antragsteller.models", [
  "restangular",
  CoreModels.name
])

  .provider("Antragstellerorganisationen", Antragstellerorganisationen)
  .provider("Durchfuehrendeorgeinheiten", Durchfuehrendeorgeinheiten)
  .provider("FoerderorgTyp", FoerderorgTyp)
  .provider("Foerderorganisation", Foerderorganisation)
  .provider("Vertreter", Vertreter)
  .provider("KBuchfuehrungsmethode", KBuchfuehrungsmethode)
  .provider("Foerderorgnutzer", Foerderorgnutzer)
;

export default modelsModule;
