var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["antragstellermodul"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
var rulesKbez = "vm.modul.regel[vm.modul.prefix + '_kbez']"
var rulesBez = "vm.modul.regel[vm.modul.prefix + '_bezeichnung']"
{
if ( (rulesKbez || rulesBez))
{
buf.push("<div class=\"form-step-detail\"><fieldset><h5" + (jade.attr("ng-bind-html", "" + (rulesKbez) + "", true, true)) + "></h5><div" + (jade.attr("ng-bind-html", "" + (rulesBez) + "", true, true)) + "></div></fieldset></div>");
}
}
};
jade_mixins["antragstellermodul"]();;return buf.join("");
}