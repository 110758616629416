import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";

import {
  cloneDeep,
  forEach,
  isPlainObject,
} from "lodash-es";

// @vue/component
export default {
  name: "AufgabenvorlagenDetailsStatusgruppenTableRowEdit",
  components: {
    FormElement,
    Modal,
    PuxButton,
    PuxIcon,
  },
  props: {
    workflowtypId: {
      type: String,
      required: true,
    },
    workflowTypenMapModel: {
      type: Object,
      required: true,
    },
    selectedStatusgruppen: {
      type: Object,
      required: true,
    },
    formstepHtmlId: {
      type: String,
      required: true,
    },
  },
  emits: [
    "removeWorkflowtyp",
  ],
  data() {
    return {
      showModalConfirmRemove: false,
      confirmOptions: {},
    };
  },
  computed: {
    buttonRemoveId() {
      return `button_remove_${ this.workflowtypId }`;
    },

    testClass() {
      return `test_button_remove_${ this.workflowtypId }`;
    },

    selectorCloseRemove() {
      return [
        `#button_remove_${ this.workflowtypId }`,
        `#${ this.formstepHtmlId }`
      ];
    },

    zuordnungElementsOptions() {
      const ZUORDNUNG_ELEMENTS = {};
      forEach([1, 2, 3, 4, 5], position => {
        const WORKFLOW_TYP = cloneDeep(this.workflowTypenMapModel[this.workflowtypId].wsgPosMap[position]);
        if (isPlainObject(WORKFLOW_TYP)) {
          const ID = `${ this.workflowtypId }`;
          ZUORDNUNG_ELEMENTS[position] = {
            id: ID,
            type: "radio",
            data: {
              [position]: {
                label: WORKFLOW_TYP.wsg_kbez,
                value: WORKFLOW_TYP.pk,
              },
            },
          };
        }
      });
      return ZUORDNUNG_ELEMENTS;
    },

  },
  methods: {
    openModalConfirmRemove() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_AUFGABENVORLAGENDETAILS_CONFIRM_REMOVE_WORKFLOWTYP_ZUORDNUNG_{{name}}_",
        msg: "_MSG_AUFGABENVORLAGENDETAILS_CONFIRM_REMOVE_WORKFLOWTYP_ZUORDNUNG_{{name}}_",
        extra: {
          name: this.workflowTypenMapModel[this.workflowtypId].wft_bez,
        },
        okLabel: "_TXT_AUFGABENVORLAGENDETAILS_BUTTON_TOOLTIP_REMOVE_",
        okCallback: this.remove,
        cancelCallback: this.closeModalConfirmRemove,
        loading: false,
      };
      this.showModalConfirmRemove = true;
    },

    closeModalConfirmRemove() {
      this.showModalConfirmRemove = false;
    },

    remove() {
      this.$emit("removeWorkflowtyp", { workflowtypId: this.workflowtypId });
    },
  },
};
