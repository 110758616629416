import DashboardBalkendiagrammStatisticRow from "./DashboardBalkendiagrammStatisticRow/DashboardBalkendiagrammStatisticRow.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  size
} from "lodash-es";


// @vue/component
export default {
  name: "DashboardBalkendiagrammStatistic",
  components: {
    DashboardBalkendiagrammStatisticRow,
    PuxTranslate,
  },
  props: {
    statistic: {
      type: Object,
      required: true
    },
  },
  computed: {
    show() {
      return size(this.statistic.rows) > 0;
    },

    showTitleRow() {
      return this.statistic.titel || this.statistic.untertitel;
    },
  },
};
