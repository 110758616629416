import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";

import KDatenschutzfrageaktionUUIDs from "../../../../const/KDatenschutzfrageaktionUUIDs";
import KDatenschutzfragetypUUIDs from "../../../../const/KDatenschutzfragetypUUIDs";
import {
  FilterBooleanMixin,
} from "../../../../global/mixins/FiltersMixin";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "ProfilDetailsDatenschutzFrage",
  components: {
    FormElement,
    FormstepDetail,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    FilterBooleanMixin,
    HttpMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
    frage: {
      type: Object,
      required: true,
    },
    frageIndex: {
      type: Number,
      required: true,
    },
    updateFrage: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modelEinwilligung: false,
      modelEditEinwilligung: false,
    };
  },
  computed: {
    optionsForFormstepDetail() {
      return {
        label: this.frage.def_titel,
        required: true,
        saveCallback: this.save,
        openCallback: this.open,
      };
    },

    aktuelleEinwilligungAktionLabel() {
      if (this.frage.aktuelle_einwilligung.aktion === KDatenschutzfrageaktionUUIDs.EINWILLIGUNG) {
        return "_LBL_YES_";
      }
      if (this.frage.aktuelle_einwilligung.aktion === KDatenschutzfrageaktionUUIDs.WIDERRUF) {
        return "_LBL_NO_";
      }
      return "";
    },

    isEditableFrage() {
      return this.isCheckbox && !this.frage.def_pflicht;
    },

    isCheckbox() {
      return this.frage.typ === KDatenschutzfragetypUUIDs.CHECKBOX;
    },

    isInfoText() {
      return this.frage.typ === KDatenschutzfragetypUUIDs.INFOTEXT;
    },

    dokumentUrl() {
      return `/api/datenschutz/dokument/${ this.frage.pk }/`;
    },

    idForFormstepDetail() {
      return `datenschutz_frage_${ this.frageIndex }`;
    },

    optionsForFormElement() {
      return {
        id: "profil_datenschutzfrage_checkbox",
        htmlId: `${ this.idForFormstepDetail }_checkbox`,
        type: "one_checkbox",
        view: "v-alt",
        autoFocus: true,
        label: this.frage.def_einwilligungstext,
      };
    },
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.frage.aktuelle_einwilligung) {
        this.modelEinwilligung = this.frage.aktuelle_einwilligung.aktion === KDatenschutzfrageaktionUUIDs.EINWILLIGUNG;
      } else {
        this.modelEinwilligung = this.frage.def_default;
      }
    },

    save() {
      return new Promise((resolve, reject) => {
        this.postHttp({
          url: "datenschutzeinwilligungen/",
          data: this.setDataForSave(),
        }).then(
          response => {
            this.updateFrageLocal({ response });
            this.addNotification({
              text: "_MSG_PROFIL_DETAILS_UPDATE_SUCCESS_",
            });
            this.setModelFromEditModel();
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },

    setDataForSave() {
      return {
        frage: this.frage.pk,
        nutzer: this.nutzer.n_id,
        de_kontext: "Profil",
        einwilligung: this.modelEditEinwilligung,
        aktion: this.modelEditEinwilligung ? KDatenschutzfrageaktionUUIDs.EINWILLIGUNG : KDatenschutzfrageaktionUUIDs.WIDERRUF,
      };
    },

    updateFrageLocal({ response }) {
      const FRAGE = cloneDeep(this.frage);
      FRAGE.aktuelle_einwilligung = response;
      this.updateFrage({
        frage: FRAGE,
        frageIndex: this.frageIndex,
      });
    },

    open() {
      this.modelEditEinwilligung = this.modelEinwilligung;
    },

    setModelFromEditModel() {
      this.modelEinwilligung = this.modelEditEinwilligung;
    },
  },
};
