import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "BerichteVerwaltungModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        tab_sichtbar: false,
        rollen_ids: [],
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "tab_kbez",
            type: "text",
            label: "_LBL_BERICHTE_VERWALTUNG_MODAL_CREATE_KURZBEZEICHNUNG_",
            required: true,
            view: "v",
            translate: true,
          },
          {
            id: "tab_bez",
            type: "richtext",
            label: "_LBL_BERICHTE_VERWALTUNG_MODAL_CREATE_LANGBEZEICHNUNG_",
            view: "v-alt",
            translate: true,
          },
          {
            id: "tab_pos",
            type: "number",
            label: "_LBL_BERICHTE_VERWALTUNG_MODAL_CREATE_POSITION_",
            required: true,
            view: "v",
            translate: true,
          },
          {
            id: "tab_sichtbar",
            type: "boolean",
            label: "_LBL_BERICHTE_VERWALTUNG_MODAL_CREATE_SICHTBAR_",
            view: "v-alt",
            translate: true,
          },
          {
            id: "rollen_ids",
            type: "multiselect",
            label: "_LBL_BERICHTE_VERWALTUNG_DETAILS_ROLLEN_",
            showLabel: true,
            url: "rollen/",
            keyLabel: "bez",
            keyId: "id",
            search: true,
            searchList: ["bez", "kbez"],
            view: "v-alt",
            translate: true
          },
          {
            id: "tab_bericht",
            type: "text",
            label: "_LBL_BERICHTE_VERWALTUNG_MODAL_CREATE_KENNUNG_",
            required: true,
            view: "v",
            translate: true,
          },
          {
            id: "typ",
            type: "select",
            label: "_LBL_BERICHTE_VERWALTUNG_MODAL_CREATE_TYP_",
            required: true,
            view: "v",
            keyId: "pk",
            keyLabel: "bt_kbez",
            menuWidthAll: true,
            url: "berichttypen/",
            translate: true,
          },
          {
            id: "tab_hoehe",
            type: "number",
            label: "_LBL_BERICHTE_VERWALTUNG_MODAL_CREATE_HOEHE_",
            required: true,
            view: "v",
            translate: true,
          },
          {
            id: "tab_argument",
            type: "json",
            label: "_LBL_BERICHTE_VERWALTUNG_MODAL_CREATE_ARGUMENT_",
            showLabel: true,
            view: "v-alt",
            required: false,
            translate: true,
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  methods: {
    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.postHttp({ url: `tableau/`, data }).then(
        response => {
          this.addNotification({ text: "_MSG_BERICHTE_VERWALTUNG_MODAL_CREATE_SUCCESS_" });
          this.close();
          this.$goTo("root.tableau.admin.detail", { id: response.pk });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
