import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";

import DashboardModuleMixin from "../DashboardModuleMixin";

import {
  EventBus,
} from "../../../../global/functions/event-bus";

// @vue/component
export default {
  name: "DashboardNeuestenNachrichten",
  components: {
    AngularLink,
  },
  mixins: [
    DashboardModuleMixin,
  ],
  data() {
    return {
      ungelesenLocal: undefined,
    };
  },
  computed: {
    tooltipBoxTranslate() {
      const TRANSLATE_OBJ = {};
      if (!this.ungelesen) {
        TRANSLATE_OBJ.title = "_TXT_DASHBOARD_NACHRICHTEN_KEINE_NACHRICHTEN_";
      } else if (this.ungelesen === 1) {
        TRANSLATE_OBJ.title = "_TXT_DASHBOARD_NACHRICHTEN_EIN_NACHRICHT_";
      } else {
        TRANSLATE_OBJ.title = "_TXT_DASHBOARD_NACHRICHTEN_NACHRICHTEN_{{anzahl}}_";
        TRANSLATE_OBJ.extra = {
          anzahl: this.ungelesen,
        };
      }
      return TRANSLATE_OBJ;
    },

    ungelesen() {
      if (this.ungelesenLocal) {
        return this.ungelesenLocal;
      }
      return this.data && this.data.ungelesen;
    },

    componentLocal() {
      return this.ungelesen ?
        "angular-link" :
        "div";
    },

    attributesLocal() {
      if (this.ungelesen) {
        return {
          sref: "root.benachrichtigungen",
          options: {
            ben_gelesen: false,
          },
          "aria-labelledby": this.ariaLabelledbyForBadgeLink,
        };
      }
      return {};
    },
  },
  created() {
    this.setEventListener();
  },
  beforeUnmount() {
    this.destroyEventListener();
  },
  methods: {
    setEventListener() {
      EventBus.$on("updateNachrichten", this.updateNachrichtenLocal);
    },

    destroyEventListener() {
      EventBus.$off("updateNachrichten", this.updateNachrichtenLocal);
    },

    updateNachrichtenLocal({ numUnread } = {}) {
      this.ungelesenLocal = numUnread;
    },
  },
};
