"use strict";

import DefaultEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";

class GutachtenCreateController extends DefaultEditController {
  /*@ngInject*/
  constructor($q, $scope, Gutachten, ParameterUtils, DiasNotification, i18n) {
    super($q, $scope, DiasNotification);
    this.ParameterUtils = ParameterUtils;
    this.Gutachten = Gutachten;
    this.gettext = i18n.gettext;
    this.errorLabels = {
      ergebnis: `${ this.gettext("Ergebnis") }: `,
      ga_ergebnis_erlaeuterung: `${ this.gettext("Erläuterung zum Ergebnis") }: `
    };
  }

  _save() {
    const promise = this.Gutachten.one(this.model.pk).customPUT({
      ergebnis: this.model.ergebnis,
      ga_ergebnis_erlaeuterung: this.model.ga_ergebnis_erlaeuterung
    }, "ergebnis_eintragen").then(
      response => {
        this.$scope.model.merge(this.$scope.model, response.plain());
        this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
      },
      errors => {
        return this.onErrors(errors, this.errorLabels);
      }
    );
    return promise;
  }
}

export default GutachtenCreateController;
