import AkkreditierteStudiengangDetailsAkkr from "./AkkreditierteStudiengangDetailsAkkr/AkkreditierteStudiengangDetailsAkkr.vue";
import AkkreditierteStudiengangDetailsOverview from "./AkkreditierteStudiengangDetailsOverview/AkkreditierteStudiengangDetailsOverview.vue";
import AkkreditierteStudiengangTeilstudiengaenge from "./AkkreditierteStudiengangTeilstudiengaenge/AkkreditierteStudiengangTeilstudiengaenge.vue";
import ContextPanel from "../../../../../../libs/vue/client/vue/global/components/ContextPanel/ContextPanel.vue";
import PuxButton from "../../../../../../libs/vue/client/vue/global/components/PuxButton/PuxButton.vue";
import PuxPageDetails from "../../../../../../libs/vue/client/vue/global/components/PuxPageDetails/PuxPageDetails.vue";

import HttpMixin from "../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import SyConfigMixin from "../../../../../../libs/vue/client/vue/global/mixins/SyConfigMixin";

import KStudiengangartUUIDs from "../../../../../../libs/vue/client/vue/const/KStudiengangartUUIDs";

import {
  EventBus,
} from "../../../../../../libs/vue/client/vue/global/functions/event-bus";
import {
  cloneDeep,
  filter,
  get,
  isNil,
  orderBy,
  sortedUniqBy,
  uniq,
} from "lodash-es";

// @vue/component
export default {
  name: "AkkreditierteStudiengangDetails",
  components: {
    AkkreditierteStudiengangDetailsAkkr,
    AkkreditierteStudiengangDetailsOverview,
    AkkreditierteStudiengangTeilstudiengaenge,
    ContextPanel,
    PuxButton,
    PuxPageDetails,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
  ],
  data() {
    return {
      loading: true,
      studiengang: {},
      teilstudiengaenge: [],
      akkrs: [],
    };
  },
  computed: {
    extraForPageTitleTranslate() {
      return {
        name: this.studiengang.name,
        hs_bez: this.studiengang.hs_bez,
      };
    },

    isStudiengangArtKombi() {
      return this.studiengang.art === KStudiengangartUUIDs.KOMBI;
    },

    studiengangUrl() {
      return `akkrstudiengaenge/${ this.$stateParams.id }/`;
    },

    teilstudiengaengeUrl() {
      return `${ this.studiengangUrl }akkrteilstudiengaenge/`;
    },

    lerhamtstypKbez() {
      return this.studiengang.lehramtstyp_kbez ? `(${ this.studiengang.lehramtstyp_kbez }), ` : null;
    }
  },
  created() {
    this.loadStudiengang();
  },
  methods: {
    loadStudiengang() {
      this.getHttp({
        url: this.studiengangUrl,
      }).then(
        response => {
          this.setStudiengangFromResponse(response);
          this.loadTeilstudiengaenge();
        }
      );
    },

    setStudiengangFromResponse(response) {
      response.studienbereiche = uniq(response.studienbereiche);
      response.faechergruppen = uniq(response.faechergruppen);
      this.studiengang = response;
      const STUDIENGANG = cloneDeep(this.studiengang);
      if (get(STUDIENGANG, "reakkreditierungen", [])) {
        STUDIENGANG.reakkreditierungen = orderBy(STUDIENGANG.reakkreditierungen, ["pro_von", "pro_bis"], ["desc", "desc"]);
      }
      this.studiengang = STUDIENGANG;
      this.akkrs = sortedUniqBy(orderBy(filter([
        get(this.studiengang, "aktuelle_akkr"),
        ...get(this.studiengang, "reakkreditierungen", []),
        get(this.studiengang, "erstakkreditierung"),
      ], akkr => !isNil(akkr)), [a => a.pro_von || a.pro_entscheidungsdatum, "pro_bis"], ["desc", "desc"]), "pk");
    },

    loadTeilstudiengaenge() {
      if (!this.isStudiengangArtKombi) {
        this.loading = false;
        return;
      }
      this.getListHttp({
        url: this.teilstudiengaengeUrl,
      }).then(
        response => this.teilstudiengaenge = response,
        () => this.addNotification({
          text: "Fehler beim Laden der Teilstudiengaenge.",
          type: "error",
        })
      ).finally(
        () => this.loading = false
      );
    },
    openPrint() {
      EventBus.$emit("toggleContexPanel", { statusOpen: true });
      setTimeout(() => {
        window.print();
      }, 250);
    }
  },
};
