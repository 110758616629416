import FormstepDetail from "../../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import FormElement from "../../../../../../global/components/FormElement/FormElement.vue";
import FieldsMixin from "./FieldsMixin";
import WizardStepsErrorsMixin from "../../WizardStepsErrorsMixin";
import WizardStepsRechnerMixin from "../../WizardStepsRechnerMixin";
import WizardStepsFieldsChangeWarningMixin from "../../WizardStepsFieldsChangeWarningMixin";
import WizardStepDHWMixin from "../../WizardStepDHWMixin";
import WizardStepsPeriodenMixin from "../../WizardStepsPeriodenMixin";
import translate from "../../../../../../global/directives/translate";
import {
  FilterCurrencyMixin,
} from "../../../../../../global/mixins/FiltersMixin";
import {
  isUndefined,
  cloneDeep,
  forEach,
  round,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenPeriodenWizardStep1DHW",
  components: {
    FormElement,
    FormstepDetail,
  },
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
    WizardStepsErrorsMixin,
    WizardStepsRechnerMixin,
    WizardStepsFieldsChangeWarningMixin,
    FieldsMixin,
    WizardStepDHWMixin,
    WizardStepsPeriodenMixin,
  ],
  props: {
    katalogDetails: {
      type: Array,
      default: () => [],
    },
    personalstellenart: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Object,
      default: undefined,
    },
    position: {
      type: Object,
      default: undefined,
    },
    perioden: {
      type: Array,
      required: true,
    },
    pktId: {
      type: String,
      required: true,
    },
    statusInitModel: {
      type: Boolean,
      required: true,
    },
    initModelInParent: {
      type: Function,
      required: true,
    },
  },
  computed: {
    getTranslateOptionsANJahresbrutto() {
      return {
        text: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_AN_JAHRESBRUTTO_{{stellenanteil}}_",
        extra: {
          stellenanteil: this.model.stellenanteil,
        },
      };
    },

    getTranslateOptionsAGBruttogehaltStelle() {
      return {
        html: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_AG_JAHRESBRUTTO_STELLE_{{stellenanteil}}_{{pkt_pauschale}}_",
        extra: {
          stellenanteil: this.model.stellenanteil,
          pkt_pauschale: this.filterCurrency(this.model.pauschale, ""),
        },
      };
    },
    getTranslateOptionsAGBruttogehaltVollzeitstelestelle() {
      return {
        html: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_AG_JAHRESBRUTTO_VOLLZEITSTELLE_{{pkt_pauschale}}_",
        extra: {
          pkt_pauschale: this.filterCurrency(this.model.pauschale, ""),
        },
      };
    },
  },
  created() {
    this.initModel();
    this.setStellenumfangMaxValue();
    this.initErrorLabels_mixin();
  },
  methods: {
    initModel() {
      if (!this.statusInitModel) {
        return;
      }

      if (this.position) {
        this.initModelWithPosition();
      } else {
        this.initModelWithoutPosition();
      }
    },

    initModelWithPosition() {
      const MODEL = cloneDeep(this.position.argument);
      if (this.position.kostenartdetail) {
        MODEL.kostenartdetail = this.position.kostenartdetail;
      }
      MODEL.ag_anteil_altersvorsorge = MODEL.ag_anteil_altersvorsorge || 0;
      MODEL.pkt_pauschale = this.getNumber({ item: this.personalkostentabelleObj.pkt_pauschale }) || 0;
      MODEL.pauschale = +MODEL.pkt_pauschale + +MODEL.ag_anteil_altersvorsorge;
      MODEL.stellenanteil = this.setStellenanteil({ model: MODEL });
      MODEL.jahresbrutto = this.setJahresbrutto({ model: MODEL });
      MODEL.an_jahresbrutto = this.setANJahresbrutto({ model: MODEL });
      MODEL.ag_bruttogehalt_vollzeitstelestelle = this.setAGBruttogehaltVollzeitstelle({ model: MODEL });
      MODEL.ag_bruttogehalt_stelle = this.setAGBruttogehaltStelle({ model: MODEL });
      MODEL.perioden = this.getModePeriodenWithPosition({ perioden: MODEL.perioden });
      // MODEL.bestandspersonal = !MODEL.bestandspersonal;

      this.initModelInParent({ model: MODEL, statusInitModel: false });
    },

    getModePeriodenWithPosition({ perioden }) {
      const MODEL_PERIODEN = [];
      const PERIODE_TEMP = {};
      let statusPeriodenTeil = false;

      forEach(perioden, period => {
        if (!PERIODE_TEMP[period.periode]) {
          PERIODE_TEMP[period.periode] = 1;
        } else {
          PERIODE_TEMP[period.periode]++;
          statusPeriodenTeil = true;
        }
        const INFO = cloneDeep(period.periode_obj);
        INFO.teil = undefined;
        const PERIOD_CLONE = cloneDeep(period);
        const ARGUMENT = PERIOD_CLONE.argument || {};
        PERIOD_CLONE.period_obj = undefined;
        PERIOD_CLONE.brutto_monat = ARGUMENT.brutto_monat;
        PERIOD_CLONE.einmalzahlung = ARGUMENT.einmalzahlung;
        PERIOD_CLONE.info = INFO;
        MODEL_PERIODEN.push(PERIOD_CLONE);
      });
      if (statusPeriodenTeil) {
        forEach(MODEL_PERIODEN, (period, index) => {
          if (PERIODE_TEMP[period.periode] > 1) {
            MODEL_PERIODEN[index].info.teil = true;
          }
        });
      }

      return MODEL_PERIODEN;
    },

    initModelWithoutPosition() {
      const PKT_PAUSCHALE = this.getNumber({ item: this.personalkostentabelleObj.pkt_pauschale }) || 0;
      const MODEL = {
        perioden: this.getModePerioden(),
        pkt_id: this.pktId,
        name: "N.N.",
        stellenbezeichnung: undefined,
        kostenartdetail: undefined,
        anzeige_personalstelle: undefined,
        an_brutto_monat: undefined,
        einmalzahlung: undefined,
        finanzierung_bestandspersonal: undefined,
        tarifvertrag: undefined,
        vorherige_taetigkeit: undefined,
        vergl_tarifvertrag: undefined,
        bestandspersonal: false,
        stellenumfang: undefined,
        vollzeitstelle: undefined,
        jahresbrutto: undefined,
        pkt_pauschale: PKT_PAUSCHALE,
        ag_anteil_altersvorsorge: 0,
        pauschale: PKT_PAUSCHALE,
        stellenumfang_projekt: undefined,

        zeitraum_von: this.durchfuehrungszeitraumVon,
        zeitraum_bis: this.durchfuehrungszeitraumBis,
        an_jahresbrutto: undefined,
        stellenanteil: undefined,
        ag_bruttogehalt_vollzeitstelestelle: undefined,
        ag_bruttogehalt_stelle: undefined,
      };
      this.initModelInParent({ model: MODEL, statusInitModel: false });
    },

    onInput({ id, options }) {
      if (isFunction(this[`changeInput__${ id }`])) {
        this[`changeInput__${ id }`]();
      }

      if (options.statusChangePeriod) {
        this.onInputChangePeriod_mixin({ id });
      }
    },

    changeInput__vollzeitstelle() {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.stellenanteil = this.setStellenanteil();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.an_jahresbrutto = this.setANJahresbrutto();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_stelle = this.setAGBruttogehaltStelle();

      this.setStellenumfangMaxValue();
      this.setStellenumfangModel();
    },

    changeInput__stellenumfang() {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.stellenanteil = this.setStellenanteil();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.an_jahresbrutto = this.setANJahresbrutto();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_stelle = this.setAGBruttogehaltStelle();

      this.setStellenumfangProjekt();
    },

    changeInput__ag_anteil_altersvorsorge() {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.pauschale = +this.model.pkt_pauschale + +this.model.ag_anteil_altersvorsorge;
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_vollzeitstelestelle = this.setAGBruttogehaltVollzeitstelle();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_stelle = this.setAGBruttogehaltStelle();
    },

    changeInput__an_brutto_monat() {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.jahresbrutto = this.setJahresbrutto();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_vollzeitstelestelle = this.setAGBruttogehaltVollzeitstelle();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.an_jahresbrutto = this.setANJahresbrutto();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_stelle = this.setAGBruttogehaltStelle();
    },

    changeInput__einmalzahlung() {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.jahresbrutto = this.setJahresbrutto();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_vollzeitstelestelle = this.setAGBruttogehaltVollzeitstelle();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.an_jahresbrutto = this.setANJahresbrutto();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_stelle = this.setAGBruttogehaltStelle();
    },

    setStellenumfangMaxValue() {
      const VOLLZEITSTELLE = this.getNumber({ item: this.model.vollzeitstelle });
      if (isUndefined(VOLLZEITSTELLE)) {
        this.fields.stellenumfang.maxValue = 50;
        return;
      }
      this.fields.stellenumfang.maxValue = VOLLZEITSTELLE;
    },

    setStellenumfangModel() {
      if (!this.model.vollzeitstelle ||
        !this.model.stellenumfang ||
        this.fields.stellenumfang.maxValue > this.model.stellenumfang) {
        return;
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.model.stellenumfang = this.fields.stellenumfang.maxValue;
    },

    setStellenumfangProjekt() {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.stellenumfang_projekt = this.model.stellenumfang;
    },

    setStellenanteil({ model } = {}) {
      const MODEL_LOCAL = model ? model : this.model;
      const STELLENUMFANG = MODEL_LOCAL.stellenumfang || 0;

      const VOLLZEITSTELLE = MODEL_LOCAL.vollzeitstelle || 0;
      if (VOLLZEITSTELLE === 0) {
        return 0;
      }
      return round(+STELLENUMFANG * 100 / VOLLZEITSTELLE, 2);
    },

    setJahresbrutto({ model } = {}) {
      const MODEL_LOCAL = model ? model : this.model;
      const AN_BRUTTO_MONAT = +this.getNumber({ item: MODEL_LOCAL.an_brutto_monat }) || 0;
      const EINMALZAHLUNG = +this.getNumber({ item: MODEL_LOCAL.einmalzahlung }) || 0;

      return +round(12 * AN_BRUTTO_MONAT + EINMALZAHLUNG, 2);
    },

    setAGBruttogehaltVollzeitstelle({ model } = {}) {
      const MODEL_LOCAL = model ? model : this.model;
      const JAHRESBRUTTO = this.getNumber({ item: MODEL_LOCAL.jahresbrutto }) || 0;

      const PAUSCHALE = MODEL_LOCAL.pauschale;
      return +round(JAHRESBRUTTO * (1 + PAUSCHALE / 100), 2);
    },

    setANJahresbrutto({ model } = {}) {
      const MODEL_LOCAL = model ? model : this.model;
      const JAHRESBRUTTO = MODEL_LOCAL.jahresbrutto || 0;
      const STELLENANTEIL = MODEL_LOCAL.stellenanteil || 0;
      return +round(JAHRESBRUTTO * STELLENANTEIL / 100, 2);
    },

    setAGBruttogehaltStelle({ model } = {}) {
      const MODEL_LOCAL = model ? model : this.model;
      const AN_JAHRESBRUTTO = MODEL_LOCAL.an_jahresbrutto || 0;
      const PAUSCHALE = MODEL_LOCAL.pauschale || 0;
      return +round(AN_JAHRESBRUTTO * (1 + PAUSCHALE / 100), 2);
    },
  },
};
