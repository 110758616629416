"use strict";

import template from "./details.jade";

import basedataEditTemplate from "./basisdaten.edit.jade";
import basedataEditController from "./basisdaten.edit.controller";

class GutachtenvorlageDetailsController {
  /*@ngInject*/
  constructor(
    $q,
    $filter,
    $state,
    $stateParams,
    i18n,
    DiasNotification,
    ParameterUtils,
    Gutachtenvorlagen,
    Foerderregeln
  ) {
    this.$q = $q;
    this.$filter = $filter;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.DiasNotification = DiasNotification;
    this.Gutachtenvorlagen = Gutachtenvorlagen;
    this.Foerderregeln = Foerderregeln;
    this.ParameterUtils = ParameterUtils;
    this.gettext = i18n.gettext;

    this.baseEditTemplate = basedataEditTemplate;
    this.baseEditController = basedataEditController;

    this.init();
  }

  init() {
    this.loading = {
      gutachtenvorlage: true,
      gutachtenregeln: true,
      regeln: true
    };
    this.loadGutachtenvorlage().finally(
      () => this.loading.gutachtenvorlage = false
    );
    const existingRules = this.loadGutachtenregeln().finally(
      () => this.loading.gutachtenregeln = false
    );
    const allRules = this.loadRegeln().finally(
      () => this.loading.regeln = false
    );
    this.$q.all(
      [existingRules, allRules]
    ).then(() => this.initVerwendbareRegeln());
  }

  loadGutachtenvorlage() {
    return this.Gutachtenvorlagen.one(this.$stateParams.id).get().then(
      response => this.gav = response,
      error => this.DiasNotification.page.error(error, "Fehler beim Laden der Gutachtenvorlage")
    );
  }

  loadGutachtenregeln() {
    return this.Gutachtenvorlagen.one(this.$stateParams.id).regeln.getList().then(
      response => this.gavRegeln = response,
      error => this.DiasNotification.page.error(error, "Fehler beim Laden der Gutachtenvorlage")
    );
  }

  loadRegeln() {
    return this.Foerderregeln.getGutachtenregeln().then(
      response => this.regeln = angular.forEach(response, item => {
        item.titel = (item.re_nummer ? ("[" + item.re_nummer + "] ") : "") + item.re_kbez;
      }),
      error => this.DiasNotification.page.error(error, "Fehler beim Laden der Geschäftsregeln")
    );
  }

  initVerwendbareRegeln() {
    if (!angular.isUndefined(this.regeln)) {
      this.updateVerwendbareRegeln(this.$filter("filter")(this.regeln, value => this.filterVerwendbareRegeln(value)));
    }
  }

  filterVerwendbareRegeln(value) {
    for (let i = 0; i < this.gavRegeln.length; i++) {
      if (this.gavRegeln[i].regel === value.pk) {
        return false;
      }
    }
    return true;
  }

  updateVerwendbareRegeln(newRegeln) {
    this.verwendbareRegeln = this.$filter("orderBy")(newRegeln, "re_nummer");
  }

  addGutachtenregel() {
    return regel => {
      this.loading.gutachtenregeln = true;
      return this.gavRegeln.post({ regel: regel.pk }).then(
        response => {
          this.gavRegeln.push(response);
          this.updateVerwendbareRegeln(this.$filter("filter")(this.verwendbareRegeln, { pk: "!".concat(response.regel) }));
          this.DiasNotification.page.success("Geschäftsregel erfolgreich hinzugefügt");
        },
        error => this.DiasNotification.page.error(error, "Fehler beim Hinzufügen der Geschäftsregel")
      ).finally(() => this.loading.gutachtenregeln = false);
    };
  }

  deleteGutachtenregel() {
    if (this.loading.gutachtenregeln) {
      return;
    }
    return gavRegel => {
      this.loading.gutachtenregeln = true;
      return gavRegel.remove().then(
        () => {
          // remove from our list
          this.gavRegeln.splice(this.gavRegeln.indexOf(gavRegel), 1);
          // update remaining positions
          this.gavRegeln.forEach(gar => {
            if (gar.gar_pos > gavRegel.gar_pos) {
              gar.gar_pos -= 1;
            }
          });
          const rule = this.$filter("filter")(this.regeln, { pk: gavRegel.regel })[0];
          this.verwendbareRegeln.push(rule);
          this.updateVerwendbareRegeln(this.verwendbareRegeln);
          this.DiasNotification.page.success("Geschäftsregel erfolgreich enfernt");
        },
        error => this.DiasNotification.page.error(error, "Fehler beim Entfernen der Geschäftsregel")
      ).finally(() => this.loading.gutachtenregeln = false);
    };
  }

  moveUpGutachtenregel() {
    return gavRegel => {
      if (gavRegel.gar_pos === 1 || this.loading.gutachtenregeln) {
        // Can't move first further up
        return;
      }
      this.loading.gutachtenregeln = true;
      return gavRegel.moveUp().then(
        () => {
          const previous = this.gavRegeln[gavRegel.gar_pos - 2];
          previous.gar_pos += 1;
          gavRegel.gar_pos -= 1;
          this.gavRegeln[previous.gar_pos - 1] = previous;
          this.gavRegeln[gavRegel.gar_pos - 1] = gavRegel;
          this.DiasNotification.page.success("Geschäftsregel erfolgreich verschoben");
        },
        error => this.DiasNotification.page.error(error, "Fehler beim Verschieben der Geschäftsregel")
      ).finally(() => this.loading.gutachtenregeln = false);
    };
  }

  moveDownGutachtenregel() {
    return gavRegel => {
      if (gavRegel.gar_pos === this.gavRegeln.length || this.loading.gutachtenregeln) {
        // Can't move last further down
        return;
      }
      this.loading.gutachtenregeln = true;
      return gavRegel.moveDown().then(
        () => {
          const next = this.gavRegeln[gavRegel.gar_pos];
          next.gar_pos -= 1;
          this.gavRegeln[next.gar_pos - 1] = next;
          gavRegel.gar_pos += 1;
          this.gavRegeln[gavRegel.gar_pos - 1] = gavRegel;
          this.DiasNotification.page.success("Geschäftsregel erfolgreich verschoben");
        },
        error => this.DiasNotification.page.error(error, "Fehler beim Verschieben der Geschäftsregel")
      ).finally(() => this.loading.gutachtenregeln = false);
    };
  }

  deleteGutachtenvorlage() {
    if (this.gav.deletable) {
      this.diasConfirmDialog({
        titleTemplate: `Gutachtenvorlage löschen`,
        contentTemplate: `<p>Sind Sie sicher, dass Sie die Gutachtenvorlage {{ context.gav.gav_kbez }} löschen wollen?</p>`,
        context: { gav: this.gav },
        okLabel: "Gutachtenvorlage löschen",
        okCallback: () => this.doDelete()
      });
    }
  }

  doDelete() {
    this.loading.deleting = true;
    return this.gav.remove().then(
      () => {
        this.DiasNotification.page.success(
          "Gutachtenvorlage <strong>" + this.gav.gav_kbez + "' erfolgreich gelöscht."
        );
        this.$state.go("root.gutachtenvorlagen");
      },
      error => {
        this.DiasNotification.page.error(error, "Fehler beim löschen der Gutachtenvorlage");
        this.loading.deleting = false;
      }
    );
  }
}

export default {
  template: template(),
  controller: GutachtenvorlageDetailsController,
  controllerAs: "vm"
};
