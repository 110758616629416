import PuxIcon from "../PuxIcon/PuxIcon.vue";

import translate from "../../directives/translate";

import { createNamespacedHelpers } from "vuex";
const {
  mapMutations,
  mapState
} = createNamespacedHelpers("notification");

// @vue/component
export default {
  name: "TheNotification",
  components: {
    PuxIcon,
  },
  directives: {
    translate,
  },
  computed: {
    ...mapState([
      "NOTIFICATION",
    ]),
  },
  methods: {
    closeMessageByIndex(index) {
      this.REMOVE_NOTIFICATION_MUT(index);
    },

    getClass(type) {
      return `alert-${ type }`;
    },

    ...mapMutations([
      "REMOVE_NOTIFICATION_MUT",
    ]),
  },
};
