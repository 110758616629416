"use strict";

import DefaultEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";

class GutachtenvorlageCreateController extends DefaultEditController {
  /*@ngInject*/
  constructor($q, $scope, Gutachtenvorlagen, ParameterUtils, DiasNotification, i18n) {
    super($q, $scope, DiasNotification);
    this.ParameterUtils = ParameterUtils;
    this.Gutachtenvorlagen = Gutachtenvorlagen;
    this.gettext = i18n.gettext;
    this.initForm();
  }

  onEnterEditMode() {
    super.onEnterEditMode();
    this.updateFormModel();
  }

  _save() {
    const data = this.ParameterUtils.to_request_body(this.formModel, null, "");
    Object.assign(this.model, data);
    const promise = this.Gutachtenvorlagen.one(this.model.pk).customPUT(data).then(
      response => {
        this.$scope.model.merge(this.$scope.model, response.plain());
        this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
      },
      errors => {
        return this.onErrors(errors, this.errorLabels);
      }
    );
    return promise;
  }

  updateFormModel() {
    if (this.ParameterUtils && this.formModelTemplate && this.model) {
      this.formModel = this.ParameterUtils.params_from_template(this.formModelTemplate, this.model);
      this.formModel.content_types.wert = this.formModel.content_types.wert.map(v => {
        return `${ v }`;
      });
    }
  }

  initForm() {
    this.formModelTemplate = this.ParameterUtils.init_parameter_in({
      gav_kbez: {
        bez: this.gettext("Name"),
        typ: "string",
        prio: 1,
        options: {
          required: true
        }
      },
      gav_bez: {
        bez: this.gettext("Beschreibung"),
        typ: "richtext",
        prio: 2,
        options: {
          required: false
        }
      },
      typ: {
        bez: this.gettext("Typ"),
        typ: "single_choice",
        prio: 3,
        katalog: "kgutachtentypen",
        options: {
          required: true
        }
      },
      gruppe: {
        bez: this.gettext("Ergebnis"),
        typ: "single_choice",
        prio: 4,
        katalog: "kgutachtenergebnisgruppen",
        options: {
          required: true
        }
      },
      gav_erlaeuterung_pflicht: {
        bez: this.gettext("Erläuterung zum Ergebnis erzwingen"),
        typ: "boolean",
        prio: 5,
        options: {
          default: true
        }
      },
      workflow: {
        bez: this.gettext("Workflow"),
        typ: "single_choice",
        prio: 6,
        katalog: "workflow_gutachten",
        options: {
          required: true
        }
      },
      content_types: {
        bez: this.gettext("Gutachtenobjekte"),
        typ: "multi_select",
        prio: 7,
        katalog: "gutachtentypen",
        options: {
          required: true,
          displaymode: "select2"
        }
      },
    });
    this.form_keys = this.ParameterUtils.get_ordered_keys(this.formModelTemplate);
    this.errorLabels = {};
    this.form_keys.forEach(
      k => {
        this.errorLabels[k] = `${ this.formModelTemplate[k].bez }: `;
      }
    );
    this.updateFormModel();
  }
}

export default GutachtenvorlageCreateController;
