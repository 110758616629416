import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../../global/compositionAPI/NotificationAPI";

import {
  cloneDeep,
} from "lodash-es";

const FAQ_UUID_IN_KATALOG = "eeb6c0af-2eb0-405c-a4d9-61438837a35e";

// @vue/component
export default {
  name: "ImagesDNDModalCreateOrEdit",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  props: {
    img: {
      type: Object,
      default: undefined,
    },
    allowedMimeType: {
      type: Array,
      required: true,
    },
    apiPrefix: {
      type: String,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const {
      getHttp,
      postHttp,
      putHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      getHttp,
      postHttp,
      putHttp,
    };
  },
  data() {
    return {
      model: {
        pk: undefined,
        ass_dateiname: undefined,
        ass_titel: undefined,
        ass_data: {},
        ass_typ: FAQ_UUID_IN_KATALOG,
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "ass_dateiname",
            type: "text",
            label: "_LBL_FAQ_ADMIN_DETAILS_CREATE_IMAGE_MODAL_DATEINAME_",
            view: "v",
            required: true,
            disabled: false,
            translate: true,
          },
          {
            id: "ass_titel",
            type: "text",
            label: "_LBL_FAQ_ADMIN_DETAILS_CREATE_IMAGE_MODAL_TITEL_",
            view: "v",
            required: true,
            disabled: false,
            translate: true,
          },
          {
            id: "ass_data",
            type: "document",
            label: "_LBL_FAQ_ADMIN_DETAILS_CREATE_IMAGE_MODAL_DOKUMENT_",
            view: "v-alt",
            required: true,
            allowed_mime_type: this.allowedMimeType,
            keyLabel: name,
            translate: true,
            change: this.changeDocument,
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
      statusUpdateSrc: undefined,
    };
  },
  computed: {
    getHeader() {
      return this.img ? `_TXT_FAQ_ADMIN_DETAILS_UPDATE_IMAGE_MODAL_HEADER_` : `_TXT_FAQ_ADMIN_DETAILS_CREATE_IMAGE_MODAL_HEADER_`;
    },
  },
  created() {
    this.initOptionsAndModel();
    this.loadImg();
  },
  methods: {
    initOptionsAndModel() {
      if (!this.img) {
        return;
      }
      const MODEL = cloneDeep(this.img);
      MODEL.ass_data = {};
      this.model = MODEL;
    },

    loadImg() {
      if (!this.img) {
        return;
      }
      this.getHttp({
        url: `${ this.apiPrefix }/assets/${ this.img.pk }/data/`,
        responseType: "blob"
      }).then(
        response => {
          this.model.ass_data = {
            file: response,
            name: this.model.ass_dateiname,
          };
        }
      );
    },

    save() {
      if (this.img) {
        this.update();
      } else {
        this.create();
      }
    },

    update() {
      this.status.loading = true;
      this.putHttp(
        {
          url: `${ this.apiPrefix }/assets/${ this.img.pk }/`,
          data: this.setDataForSave(),
        }
      ).then(
        response => {
          this.addNotification({ text: `_MSG_FAQ_ADMIN_DETAILS_UPDATE_IMAGE_MODAL_SUCCESS_` });
          this.close({ statusUpdate: true, response, statusUpdateSrc: this.statusUpdateSrc });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },

    create() {
      this.status.loading = true;
      this.postHttp(
        {
          url: `faqs/assets/`,
          data: this.setDataForSave(),
        }
      ).then(
        response => {
          this.addNotification({ text: `_MSG_FAQ_ADMIN_DETAILS_CREATE_IMAGE_MODAL_SUCCESS_` });
          this.close({ create: true, response });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },

    setDataForSave() {
      const FORM_DATA = new FormData();
      if (this.model.pk) {
        FORM_DATA.append("ass_id", this.model.pk);
      }
      if (this.model.ass_data && this.model.ass_data.file) {
        FORM_DATA.append("ass_data", this.model.ass_data.file);
      }
      if (this.model.ass_dateiname) {
        FORM_DATA.append("ass_dateiname", this.model.ass_dateiname);
      }
      if (this.model.ass_titel) {
        FORM_DATA.append("ass_titel", this.model.ass_titel);
      }
      FORM_DATA.append("ass_typ", this.model.ass_typ);
      return FORM_DATA;
    },

    changeDocument() {
      if (this.img) {
        this.statusUpdateSrc = true;
      }
    },
  },
};
