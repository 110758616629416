"use strict";

import template from "./notification.jade";

class NotificationController {
  /*@ngInject*/
  constructor(DiasNotification) {
    this.name = "NotificationController";
    this.containerId = DiasNotification.page.containerId;
  }
}

export default {
  template: template(),
  controller: NotificationController,
  controllerAs: "vm"
};
