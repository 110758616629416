"use strict";


class BaseNavbarController {
  constructor(AuthService, $rootScope, $templateCache, $timeout, i18n, $state, SyConfigs) {
    this.name = "NavbarController";
    this.gettext = i18n.gettext;
    this.$state = $state;
    this.SyConfigs = SyConfigs;

    AuthService.getUser().then(
      user => {
        if (user && user.pk) {
          $templateCache.put("builtin/navbarbenachrichtigungen.html", "<navbarbenachrichtigungen />");
        } else {
          $templateCache.put("builtin/navbarbenachrichtigungen.html", "");
        }
        this.user = user;
        this.logout_url = "/abmelden/";
        this.logout_text = "Abmelden";
        if (this.user.n_sociallogin === "CAS_AM") {
          this.logout_url = user.logout_url;
          this.logout_text = "Zu aktion-mensch.de";
        }
      }
    );
    $rootScope.$on("AuthService.userRefreshed", (event, user) => {
      this.user = null;
      $timeout(() => {
        this.user = user;
        if (this.user && this.user.pk) {
          $templateCache.put("builtin/navbarbenachrichtigungen.html", "<navbarbenachrichtigungen />");
        } else {
          $templateCache.put("builtin/navbarbenachrichtigungen.html", "");
        }
      }, 1);
    });
    this.menuEntries = [
      { label: i18n.gettext("Förderung"), title: i18n.gettext("Förderung"), icon: "glyphicon-foerderung", key: "foerderung", instance_pages: true,
        items: [
          { state: "root.foerderfinderangebote", label: this.gettext("Förderangebote") + " auswählen", key: "foerderfinderangebote", instance_pages: true },
          { state: "root.foerderorganisationen_kennenlernen", label: this.gettext("Förderorganisationen") + " kennenlernen", key: "foerderorganisationen_kennenlernen", instance_pages: true }
        ],
      },
      { label: "Förderlandkarte", title: "Förderlandkarte", icon: "glyphicon-map", key: "foerderlandkarte", instance_pages: true, state: "root.karte" },
      { label: i18n.gettext("Anträge"), title: i18n.gettext("Antragsverwaltung"), icon: "glyphicon-antraege", key: "antraege", instance_pages: true,
        items: [
          { state: "root.foerderantrag-add", label: i18n.gettext("Neuen Antrag stellen"), key: "foerderantrag-add" },
          { state: "root.foerderantrag", label: i18n.gettext("Anträge bearbeiten"), key: "foerderantrag-edit" },
          { state: "root.vertrag", label: i18n.gettext("Bewilligte Anträge bearbeiten"), key: "vertrag" },
          { state: "root.auszahlung", label: i18n.gettext("Auszahlungen bearbeiten"), key: "auszahlung" },
          { state: "root.vertragsbeleg", label: i18n.gettext("Belege bearbeiten"), key: "vertragsbeleg", instance_pages: true },
          { state: "root.projektberichte", label: i18n.gettext("Projektberichte bearbeiten"), key: "projektberichte" },
          { state: "root.gutachten", label: i18n.gettext("Gutachten verwalten"), key: "gutachten" },
        ],
      },
      { label: i18n.gettext("Formulare"), title: i18n.gettext("Formulare"), icon: "glyphicon-empty-document", key: "formulare",
        items: [{ state: "root.basisformularcortex", label: i18n.gettext("Formulare bearbeiten"), key: "formularbearbeitung" }]
      },
      { label: i18n.gettext("FiBu"), title: i18n.gettext("Finanzbuchhaltung"), icon: "glyphicon-auszahlung-anfordern", key: "fibu",
        items: [
          { state: "root.auszahlungslisten", label: i18n.gettext("Auszahlungslisten verwalten"), key: "auszahlungslisten" },
          { state: "root.fibujournal", label: i18n.gettext("Buchungsjournal ansehen"), key: "fibujournal", options: { blockFirstLoad: "true" } },
        ]
      },
      { label: i18n.gettext("Meine Organisation"), title: i18n.gettext("Meine Organisation"), icon: "glyphicon-organisation", key: "meine_organisation",
        items: [
          { state: "root.astorganisationen", label: i18n.gettext("Antragsteller bearbeiten"), key: "astorganisationen" },
          { state: "root.foerderorganisationen", label: i18n.gettext("Förderorganisationen") + " bearbeiten", key: "foerderorganisationen" },
          { state: "root.sammelvertrag", label: "Weiterleitungsverträge bearbeiten", key: "sammelvertrag" },
        ],
      },

      { label: i18n.gettext("Import"), title: i18n.gettext("Datenimport"), icon: "glyphicon-import", key: "importauftraege",
        items: [
          { state: "root.importauftraege", label: i18n.gettext("Importaufträge verwalten"), key: "importauftraegelist" },
          { state: "root.importauftraege_wizard_antrag", label: i18n.gettext("Antrag importieren"), key: "importauftraegelist_wizard_antrag" },
          { state: "root.importauftraege_wizard_json", label: i18n.gettext("JSON-Datei importieren"), key: "importauftraegelist_wizard_json" },
          { state: "root.exportauftraege_wizard_json", label: i18n.gettext("JSON-Datei exportieren"), key: "exportauftraegelist_wizard_json" },
        ]
      },

      { label: "Admin. Förderung", title: "Admin. Förderverwaltung", icon: "glyphicon-foerderung", key: "admin_foerderung",
        items: [
          { state: "root.foerderregeln", label: "Geschäftsregeln verwalten", key: "foerderregeln" },
          { state: "root.foerderinstrumente", label: "Instrumente verwalten", key: "foerderinstrumente" },
          { state: "root.foerderinstrumentvarianten", label: "Instrumentvarianten verwalten", key: "foerderinstrumentvarianten" },
          { state: "root.foerderangebote", label: i18n.gettext("Förderangebote") + " verwalten", key: "foerderangebote" },
          { state: "root.foerderprogramme", label: i18n.gettext("Förderprogramme") + " verwalten", key: "foerderprogramme" },
          { state: "root.sachberichtvorlagen", label: "Sachberichtvorlagen verwalten", key: "sachberichtvorlagen" },
          { state: "root.projektberichtvorlagen", label: "Projektberichtvorlagen verwalten", key: "projektberichtvorlagen" },
          { state: "root.sitzungsarten", label: "Sitzungsarten verwalten", key: "sitzungsarten" },
          { state: "root.workflowtypen", label: "Workflowtypen verwalten", key: "workflowtypen" },
          { state: "root.workflows", label: "Workflows verwalten", key: "workflows" },
          { state: "root.kaufgaben", label: "Aufgabenvorlagen verwalten", key: "kaufgaben" },
          { state: "root.workflowfolgen", label: "Workflowfolgen verwalten", key: "workflowfolgen" },
          { state: "root.workflowfolgeclientfunktionen", label: "Clientfunktionen verwalten", key: "workflowclientfunktionen" },
          { state: "root.workflowfolgeserverfunktionen", label: "Serverfunktionen verwalten", key: "workflowserverfunktionen" },
          { state: "root.workflowfolgestatusfunktionen", label: "Statusfunktionen verwalten", key: "workflowstatusfunktionen" },
          { state: "root.teams", label: "Team-Zuordnung verwalten", key: "teams" },
          { state: "root.routen", label: "Team-Steuerung verwalten", key: "routen" },
          { state: "root.foerderdokumente", label: "Förderdokumente verwalten", key: "foerderdokumente" },
          { state: "root.budget", label: "Budgets verwalten", key: "budget" },
          { state: "root.gutachtenvorlagen", label: i18n.gettext("Gutachtenvorlagen verwalten"), key: "gutachtenvorlage" },
        ],
      },

      { label: "Nutzer & Rollen",
        title: "Nutzer- und Rollenverwaltung", icon: "glyphicon-partner", key: "nutzer_rollen",
        items: [
          { state: "root.nutzerverwaltung", label: "Nutzer verwalten", key: "nutzerverwaltung" },
          { state: "root.rollenverwaltung", label: "Rollen verwalten", key: "rollenverwaltung" },
          { state: "root.fgruppen", label: "Funktionsgruppen anzeigen", key: "fgruppen" },
          { state: "root.nutzerdatensuche", label: "Personenbezogene Daten suchen", key: "nutzerdatensuche", options: { blockFirstLoad: "true" } },
        ],
      },

      { label: i18n.gettext("Berichte"), title: i18n.gettext("Berichte"), icon: "glyphicon-berichte", key: "berichte",
        items: [
         // {state: "root.berichte_foerderantraege", label: "Eingegangene Anträge"},
          { state: "root.tableau", label: i18n.gettext("Berichte ansehen"), key: "tableau" },
          { state: "root.tableau.admin", label: i18n.gettext("Berichte verwalten"), key: "tableau_admin" },
        ],
      },

      { label: "Dokumente", title: "Dokumentverwaltung", icon: "glyphicon-dokumente", key: "dokumente",
        items: [
          { state: "root.dokumentenupload", label: "Dokumente hinzufügen", key: "dokumentenupload" },
          { state: "root.dokumentensuchen", label: "Dokumente suchen", key: "dokumentensuchen" },
          { state: "root.deckblatt_generieren", label: "Deckblätter erstellen", key: "deckblattgenerieren" },
          { state: "root.deckblatt", label: "Deckblätter verwalten", key: "deckblatt" },
        ],
      },

      { templateUrl: "builtin/navbarbenachrichtigungen.html",
        title: i18n.gettext("Benachrichtigungen"), icon: "glyphicon-envelope", key: "benachrichtigungen",
        items: [
          { state: "root.benachrichtigungen", label: i18n.gettext("Posteingang"), title: i18n.gettext("Empfangene Nachrichten"), key: "nachrichten" },
          { state: "root.benachrichtigungen_out", label: i18n.gettext("Postausgang"), title: i18n.gettext("Gesendete Nachrichten"), key: "postausgang" },
          { state: "root.calendar", label: "Kalender", title: i18n.gettext("Kalender"), key: "calendar" },
        ]
      },

      { label: "Aufgaben", title: "Aufgabenverwaltung", icon: "glyphicon-aufgaben", key: "aufgabenverwaltung",
        items: [
          { state: "root.aufgabe", label: "Aufgaben verwalten", key: "aufgabe" },
          { state: "root.tasklog", label: "Hintergrundaufgaben einsehen", key: "tasklog" }
        ]
      },

      { label: "Sitzungen", title: "Sitzungsverwaltung", icon: "glyphicon-tagesordnung", key: "sitzungsverwaltung",
        items: [
          { state: "root.sitzungen", label: "Sitzungen verwalten", key: "sitzungen" },
          { state: "root.sitzungsantraege", label: "Anträge der Sitzungen einsehen", key: "sitzungsantraege" },
          { state: "root.mittel", label: "Fördermittel verwalten", key: "mittel" },
        ],
      },

      { label: "Admin-Tools", title: "Administrationswerkzeuge", icon: "glyphicon-konfiguration", key: "admin_tools",
        items: [
          { state: "root.adminfaqs", label: "FAQs verwalten", key: "adminfaqs" },
          { state: "root.katalogeadmin", label: "Kataloge verwalten", key: "katalogeadmin" },
          { state: "root.geloeschte_antraege", label: "Gelöschte Anträge ansehen", key: "geloeschte_antraege" },
          { state: "root.anonymisierte_nutzer", label: "Anonymisierte Nutzer ansehen", key: "anonymisierte_nutzer" },
          { state: "root.syconfigadmin", label: "System-Konfiguration", key: "syconfigadmin" },
          { state: "root.news", label: "Ankündigungen", key: "ankuendigungen" },
          { state: "root.codes", label: i18n.gettext("Codes verwalten"), key: "codes" },
          { state: "root.dokumentation", label: "Dokumentation", key: "dokumentation" },
          { state: "root.servicelog", label: "Schnittstellenprotokoll ansehen", key: "servicelog", options: { blockFirstLoad: "true" } },
          { state: "root.anonymisierung", label: i18n.gettext("Löschaufträge ansehen"), key: "anonymisierungsauftraege" },
        ],
      },
    ];

    this.checkPublicPages();
  }

  checkPublicPages() {
    const instance_pages = this.SyConfigs.wert("instance_pages");
    for (let i = 0; i < this.menuEntries.length; i++) {
      const parent = this.menuEntries[i];
      if (parent.instance_pages) {
        if (parent.items && parent.items.length) {
          for (let j = 0; j < parent.items.length; j++) {
            const child = parent.items[j];
            if (child.instance_pages) {
              const state = this.$state.get(child.state);
              if (state && state.data.instance_pages && (!instance_pages || !instance_pages[state.data.instance_pages])) {
                parent.items.splice(j, 1);
                j--;
              }
            }
          }
          if (!parent.items.length) {
            this.menuEntries.splice(i, 1);
            i--;
          }
        } else if (!parent.items) {
          const state = this.$state.get(parent.state);
          if (state && state.data.instance_pages && (!instance_pages || !instance_pages[state.data.instance_pages])) {
            this.menuEntries.splice(i, 1);
            i--;
          }
        }
      }
    }
  }

  pagesDateForJson() {
    const MENU = [];
    _.forEach(_.cloneDeep(this.menuEntries), (item, index) => {
      MENU.push(this.prepareObject(item, index));
      if (item.items) {
        _.forEach(item.items, (child, childIndex) => {
          MENU.push(this.prepareObject(child, childIndex, item));
        });
      }
    });
    this.menu = JSON.stringify(MENU);
  }

  prepareObject(item, index, parent) {
    const OBJ = {
      label: this.gettext(item.label),
      title: this.gettext(item.title) || this.gettext(item.label),
      icon: item.icon,
      key: item.key,
      state: item.state,
      priority: (index + 1) * 10,
    };
    if (item.instance_pages) {
      OBJ.instance_pages = item.instance_pages;
    }
    if (item.templateUrl) {
      OBJ.slot = "benachrichtigungen";
    }
    if (item.state) {
      const STATE = this.$state.get(item.state);
      if (STATE) {
        if (STATE.resolve && _.isFunction(STATE.resolve.$title)) {
          OBJ.page_titel = STATE.resolve.$title();
        }
        if (STATE.data && STATE.data.permissions) {
          OBJ.permission = STATE.data.permissions;
        }
      }
    }
    if (item.options && (item.options.blockFirstLoad === "true" || item.options.blockFirstLoad === true)) {
      OBJ.block_first_load = true;
    }
    if (parent) {
      OBJ.parent = parent.key;
    }
    return OBJ;
  }
}

export default BaseNavbarController;
