import ObjektanmerkungenAnzeigenTreeList from "./ObjektanmerkungenAnzeigenTreeList/ObjektanmerkungenAnzeigenTreeList.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";

import ModuleMixin from "../ModuleMixin";

import {
  size
} from "lodash-es";


// @vue/component
export default {
  name: "ObjektanmerkungenAnzeigen",
  components: {
    ObjektanmerkungenAnzeigenTreeList,
    PuxTranslate,
    SnapshotModule,
  },
  mixins: [
    ModuleMixin,
  ],
  methods: {
    isEmpty(data) {
      return size(data) === 0;
    },
  }
};
