"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";

class BankverbindungEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, i18n, DiasNotification, diasModalDialog) {
    super($q, $scope, DiasNotification);
    this.diasModalDialog = diasModalDialog;
    this.saveAbschlag = this.saveAbschlag.bind(this);
    this.i18n = i18n;
  }

  saveAbschlag() {
    const data = {
      abschlag: this.model.foerdervertragsbestaetigung.abschlag,
    };
    return this.model.saveAbschlag(data).then(
      response => {
        const tmpModel = this.$scope.model.clone();
        tmpModel.foerdervertragsbestaetigung = response;
        this.$scope.model = this.$scope.model.merge(this.$scope.model, tmpModel);

        if (this.model.foerdervertragsbestaetigung.abschlag) {
          this.DiasNotification.page.success(
            `${ this.i18n.gettext("Abschlag") } erfolgreich ausgewählt`
          );
        } else {
          this.DiasNotification.page.success(
            `${ this.i18n.gettext("Abschlag") } erfolgreich abgewählt`
          );
        }
      }
    );
  }
}

export default BankverbindungEditController;
