import {
  filter,
  groupBy,
  orderBy,
} from "lodash-es";

export default {
  data() {
    return {
      statusLoading: true,
      dataLocal: undefined,
    };
  },
  computed: {
    getData() {
      return this.dataLocal || this.options.data || this.data;
    },

    getDataWithoutGroup() {
      return filter(this.getData, item => !item.group);
    },

    getDataWithGroup() {
      return groupBy(orderBy(filter(this.getData, i => i.group), item => item.group.pos, ["asc"]), itemGroup => itemGroup.group.pos);
    },

    columnCountClass() {
      if (this.options.columnCount === 2) {
        return "column-count-lg-2";
      }
      return "";
    },
  },
};
