import ContextPanel from "../../../../../../libs/vue/client/vue/global/components/ContextPanel/ContextPanel.vue";
import HochschuleDetailsAdressInformationen from "./HochschuleDetailsAdressInformationen/HochschuleDetailsAdressInformationen.vue";
import HochschuleDetailsHochschule from "./HochschuleDetailsHochschule/HochschuleDetailsHochschule.vue";
import HochschuleDetailsStandorte from "./HochschuleDetailsStandorte/HochschuleDetailsStandorte.vue";
import HochschuleDetailsStudienOrgTeileinheiten from "./HochschuleDetailsStudienOrgTeileinheiten/HochschuleDetailsStudienOrgTeileinheiten.vue";
import PuxPageDetails from "../../../../../../libs/vue/client/vue/global/components/PuxPageDetails/PuxPageDetails.vue";

import HttpMixin from "../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import PermissionMixin from "../../../../../../libs/vue/client/vue/global/mixins/PermissionMixin";

// @vue/component
export default {
  name: "HochschuleDetails",
  components: {
    HochschuleDetailsAdressInformationen,
    HochschuleDetailsHochschule,
    HochschuleDetailsStandorte,
    HochschuleDetailsStudienOrgTeileinheiten,
    ContextPanel,
    PuxPageDetails,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  data() {
    return {
      loading: true,
      hochschule: {},
      hochschuleModel: {
        bundesland: undefined,
        gebuehrengruppe: undefined,
        hs_bez: undefined,
        hs_gericht: undefined,
        hs_hsnr: undefined,
        hs_int_bemerkung: undefined,
        hs_kbez: undefined,
        hs_laufnr: undefined,
        hs_ort: undefined,
        hs_plz: undefined,
        hs_strasse: undefined,
        hs_typ_freitext: undefined,
        standorte_bez: undefined,
        hs_website: undefined,
        traeger: undefined,
        typ: undefined,
      },
    };
  },
  computed: {
    extraForPageTitleTranslate() {
      return {
        hs_bez: this.hochschule.hs_bez,
      };
    },

    hochschuleUrl() {
      return `hochschulen/${ this.$stateParams.id }/`;
    },

    hasPermissionTeileinheitenView() {
      return this.checkPermissionsSync({
        perm: "teileinheiten.view",
        permArray: this.hochschule.user_permissions,
      });
    },
  },
  created() {
    this.loadHochschule();
  },
  methods: {
    loadHochschule() {
      this.getHttp({
        url: this.hochschuleUrl,
      }).then(
        response => {
          this.hochschule = response;
          this.loading = false;
        }
      );
    },

    updateHochschule(response) {
      this.hochschule = Object.assign({}, this.hochschule, response);
    },
  },
};
