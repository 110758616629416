import HttpMixin from "../../global/mixins/HttpMixin";
import NotificationMixin from "../../global/mixins/NotificationMixin";

import fileDownload from "js-file-download";
import {
  cloneDeep
} from "lodash-es";

// @vue/component
export default {
  mixins: [
    HttpMixin,
    NotificationMixin
  ],
  computed: {
    getUrl() {
      return "termine/";
    },
  },
  methods: {
    downloadMeeting({ meeting }) {
      this.getHttp({ url: `${ this.getUrl }${ meeting.pk }/ics_download_termin/`, responseType: "blob", fullResponse: true }).then(
        response => {
          const disposition = response.headers["content-disposition"];
          let filename = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i;
            const matches = filenameRegex.exec(disposition);
            if (matches !== null && matches[2]) {
              filename = matches[2];
            }
          }
          fileDownload(response.data, filename);
        },
        () => {
          this.addNotification({ text: "_MSG_CALENDAR_TERMIN_ICS_EXPORT_ERROR_{{titel}}_", type: "error",
                                 extra: {
                                   titel: meeting.ter_titel,
                                 }
          });
        }
      );
    },

    deleteEvent({ meeting }) {
      this.deleteHttp({ url: `${ this.getUrl }${ meeting.pk }/` }).then(
        () => {
          this.addNotification({ text: "_MSG_CALENDAR_TERMIN_DELETE_SUCCESS_{{titel}}_",
                                 extra: {
                                   titel: meeting.ter_titel,
                                 }
          });
          this.closeConfirm({ status: true });
        },
        () => {
          this.addNotification({ text: "_MSG_CALENDAR_TERMIN_DELETE_ERROR_{{titel}}_",
                                 type: "error",
                                 extra: {
                                   titel: meeting.ter_titel,
                                 }
          });
        }
      );
    },

    openModalEdit({ meeting }) {
      this.currentEventEdit = cloneDeep(meeting);
      this.modal.createOrEdit = true;
    },
  },
};
