import getTableApi from "../../../../const/TableApis";
import SmartTable from "../../../../global/components/table/table.vue";
import Spaced from "../../../../global/components/Spaced/Spaced.vue";

const TABLE_ID = "OrganisationenListInProfil";

// @vue/component
export default {
  name: "ProfilDetailsOrganisationenList",
  components: {
    SmartTable,
    Spaced,
  },
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: "",
        cols: [],
        rowActions: [],
        actions: [],
        sortable: false,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
        csvHide: true,
        hideTitle: true
      },
    };
  },
  created() {
    this.initUrl();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { nutzerPk: this.nutzer.pk } });
    },
  },
};
