import Modal from "../../../../../../../../libs/vue/client/vue/global/components/Modal/Modal.vue";
import PuxButton from "../../../../../../../../libs/vue/client/vue/global/components/PuxButton/PuxButton.vue";

import HttpMixin from "../../../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import NotificationMixin from "../../../../../../../../libs/vue/client/vue/global/mixins/NotificationMixin";

import KStudiengangartUUIDs from "../../../../../../../../libs/vue/client/vue/const/KStudiengangartUUIDs";

// @vue/component
export default {
  name: "HochschuleDetailsStudienOrgTeileinheitenModalCreate",
  components: {
    Modal,
    PuxButton,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    teileinheitenUrl: {
      type: String,
      required: true,
    },
    hochschule: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    selectorClose: {
      type: String,
      required: true
    },
  },
  emits: [
    "loadTeileinheiten",
  ],
  data() {
    return {
      model: {
        sot_bez: undefined,
        sot_kbez: undefined,
        studiengaenge: [],
      },
      loading: false,
      isModalCreateVisible: undefined,
      errors: undefined,
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "sot_kbez",
            type: "text",
            label: "Name",
            required: true,
            view: "v-alt",
          },
          {
            id: "sot_bez",
            type: "richtext",
            keyId: "sot_bez",
            label: "Beschreibung",
            view: "v-alt",
          },
          {
            id: "studiengaenge",
            type: "multiselect",
            label: "Studiengänge",
            view: "v-alt",
            url: "katalog/",
            urlParams: {
              hochschule: this.hochschule.pk,
              art: [KStudiengangartUUIDs.EINZEL, KStudiengangartUUIDs.KOMBI],
              key: "studiengaenge",
              studienorganisatorische_teileinheit: "none",
            },
            apiSaveId: "studiengaenge_hochschule_pk_teileinheiten_none",
            keyId: "sg_id",
            keyLabel: "sg_bez",
            search: true,
            searchList: ["sg_bez", "sg_kbez"],
          },
        ]
      }
    };
  },
  methods: {
    save() {
      this.loading = true;
      this.postHttp({
        url: this.teileinheitenUrl,
        data: {
          sot_bez: this.model.sot_bez,
          sot_kbez: this.model.sot_kbez,
          studiengaenge_ids: this.model.studiengaenge,
        },
      }).then(
        () => {
          this.$emit("loadTeileinheiten");
          this.close();
          this.addNotification({ text: "Teileinheit erfolgreich erstellt" });
        },
        errors => {
          this.onErrors(errors);
        }
      ).finally(
        () => {
          this.loading = false;
        }
      );
    },

    onErrors(errors) {
      this.errors = errors.data;
    },
  },
};
