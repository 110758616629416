import KFPlanMixin from "../Mixins/KFPlanMixin";

import InfoproviderStatuses from "../../../../const/InfoproviderStatuses";
import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "FinanzierungsPlan",
  mixins: [
    KFPlanMixin,
  ],
  props: {
    finanzierungsplan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      kofiPlanHtmlId: "finanzierung_plan",
    };
  },
  computed: {
    gesamtfinanzierungCurrency() {
      let gesamtfinanzierung = +this.finanzierungsplan.gesamtfinanzierung;
      if (this.infoprovider.status === InfoproviderStatuses.PROCESSOR_STATUS_IMPERFECT) {
        gesamtfinanzierung += +this.infoprovider.zuschuss;
        // Die Deckungsluecke taucht als Pseudo-Position in der Rechnung
        // auf, wenn sie positiv ist, daher muss sie dann
        // hier mit eingerechnet gerechnet werden:
        if (this.infoprovider.deckungsluecke > 0) {
          gesamtfinanzierung += +this.infoprovider.deckungsluecke;
        }
        // Die Überfinanzierung (kommt hier negativ an) muss vom
        // Gesamtergebnis abgezogen werden da sie als Pseudo-Position
        // ausgegeben wird, damit die Rechnung stimmig ist.
        if (this.infoprovider.ueberfinanzierung < 0) {
          gesamtfinanzierung += +this.infoprovider.ueberfinanzierung;
        }
      }
      return this.filterCurrency(gesamtfinanzierung);
    },

    snapshotGesamtfinanzierungCurrency() {
      const GESAMTFINANZIERUNG = get(this.snapshot, "asn_snapshot.finanzierungsplan.gesamtfinanzierung");
      return this.filterCurrency(GESAMTFINANZIERUNG);
    },
  },
};
