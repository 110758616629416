import FormElement from "../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import FunktionsgruppenAssignmentItem from "./FunktionsgruppenAssignmentItem/FunktionsgruppenAssignmentItem.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../global/compositionAPI/NotificationAPI";

import {
  chunk,
  cloneDeep,
  debounce,
  escapeRegExp,
  filter,
  find,
  forEach,
  round,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "FunktionsgruppenAssignment",
  components: {
    FormElement,
    FormstepDetail,
    PuxCloak,
    FunktionsgruppenAssignmentItem,
  },
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
    funktionsgruppen: {
      type: Array,
      required: true,
    },
    textHeader: {
      type: String,
      required: true,
    },
    textNotFunktionsgruppen: {
      type: String,
      required: false,
      default: undefined,
    },
    editablePermission: {
      type: String,
      required: false,
      default: undefined,
    },
    msgSaveSuccess: {
      type: String,
      required: true,
    },
  },
  emits: [
    "updateFunktionsgruppen",
  ],
  setup() {
    const {
      getListHttp,
      putHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      getListHttp,
      putHttp,
    };
  },
  data() {
    return {
      funktionsgruppenChoices: [],
      funktionsgruppenModel: [],
      loadingEdit: true,
      searchOptions: {
        type: "text",
        label: "_LBL_ROLLEN_FUNKTIONSGRUPPEN_SUCHE_",
        view: "v",
        id: "search_funktionsgruppen",
        nullValue: "",
      },
      searchModel: "",
      activeSearchModel: "",
      timer: undefined,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        label: this.textHeader,
        required: true,
        saveCallback: this.save,
        editablePermission: this.editablePermission,
        openCallback: this.openEditMode,
      };
    },

    funktionsgruppenGrouped() {
      const CHUNK_PARAMETER = round(this.funktionsgruppen.length / 2);
      return chunk(this.funktionsgruppen, CHUNK_PARAMETER);
    },

    funktionsgruppenChoicesGrouped() {
      const CHUNK_PARAMETER = round(this.funktionsgruppenChoicesFiltered.length / 2);
      return chunk(this.funktionsgruppenChoicesFiltered, CHUNK_PARAMETER);
    },

    funktionsgruppenChoicesFiltered() {
      if (size(this.activeSearchModel) === 0) {
        return this.funktionsgruppenChoices;
      }
      const RE = new RegExp(escapeRegExp(this.activeSearchModel), "gi");
      return filter(this.funktionsgruppenChoices, item => {
        return RE.test(item.name) || RE.test(item.beschreibung);
      });
    },

    urlSave() {
      return `${ this.baseUrl }funktionsgruppen/`;
    },

    debouncedSetActiveSearchModel() {
      return debounce(this.setActiveSearchModel, 500);
    }
  },
  methods: {
    openEditMode() {
      this.setSearchModel();
      this.setActiveSearchModel();
      this.setFunktionsgruppenModel();
      this.loadFunktionsgruppen();
    },

    setSearchModel() {
      this.searchModel = "";
    },

    setActiveSearchModel() {
      this.activeSearchModel = this.searchModel;
    },

    setFunktionsgruppenModel() {
      const MODEL = [];
      forEach(this.funktionsgruppen, item => {
        MODEL.push(item.key);
      });
      this.funktionsgruppenModel = MODEL;
    },

    loadFunktionsgruppen() {
      if (this.funktionsgruppenChoices.length) {
        return;
      }
      this.loadingEdit = true;
      this.getListHttp({
        url: "funktionsgruppen/",
        apiSaveId: "funktionsgruppen",
      }).then(
        response => {
          this.funktionsgruppenChoices = response;
          this.loadingEdit = false;
        }
      );
    },

    save() {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.urlSave,
          data: {
            funktionsgruppen: this.funktionsgruppenModel,
          },
        }).then(
          () => {
            this.updateFunktionsgruppenLocal();
            this.addNotification({ text: this.msgSaveSuccess });
            resolve();
          },
          () => {
            reject();
          }
        );
      });
    },

    updateFunktionsgruppenLocal() {
      const FUNKTIONSGRUPPEN = [];
      forEach(this.funktionsgruppenModel, item => {
        const CURRENT_CHOICE = find(this.funktionsgruppenChoices, ["key", item]);
        FUNKTIONSGRUPPEN.push(cloneDeep(CURRENT_CHOICE));
      });
      this.$emit("updateFunktionsgruppen", { funktionsgruppenLocal: FUNKTIONSGRUPPEN });
    },
  },
};
