import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";

import DashboardIconMixin from "../DashboardIconMixin";
import DashboardModelFilterMixin from "../DashboardModelFilterMixin";
import DashboardModuleMixin from "../DashboardModuleMixin";
import DashboardPopoverMixin from "../DashboardPopoverMixin";
import DashboardStylesMixin from "../DashboardStylesMixin";

import {
  isArray,
  get,
  isNil,
  assign,
} from "lodash-es";

// @vue/component
export default {
  name: "DashboardModelZaehler",
  components: {
    PuxButton,
  },
  mixins: [
    DashboardIconMixin,
    DashboardModelFilterMixin,
    DashboardModuleMixin,
    DashboardPopoverMixin,
    DashboardStylesMixin,
  ],
  computed: {
    htmlId() {
      return get(this.argument, "pk");
    },

    parameter() {
      return get(this.argument, "parameter", {});
    },

    count() {
      return get(this.data, "count");
    },

    titleBox() {
      return this.count === 1 ?
        `${ this.parameter.hover_prefix_einzahl } ${ this.parameter.hover_suffix_einzahl }` :
        `${ this.parameter.hover_prefix_mehrzahl } ${ !this.count ? "keine" : this.count } ${ this.parameter.hover_suffix_mehrzahl }`;
    },

    goToLink() {
      if (this.count === 0 || isNil(this.data)) {
        return "#";
      }
      let goto_params;
      if (isArray(get(this.data, "pk"))) {
        goto_params = { id: this.data.pk[0], aus_id: this.data.pk[1] };
      } else {
        goto_params = { id: this.data.pk };
      }
      let goto_target;
      const GOTO_DETAIL = get(this.parameter, "goto_details");
      const GOTO_LIST = get(this.parameter, "goto_list");
      if (this.count === 1 && GOTO_DETAIL) {
        goto_target = GOTO_DETAIL;
      } else if (this.count >= 1 && GOTO_LIST) {
        goto_target = GOTO_LIST;
        goto_params = assign(goto_params, this.additionalFilters);
      }
      if (goto_target) {
        return this.$stateHref(goto_target, goto_params);
      }
      return "#";
    },

    attributesLocal() {
      if (this.count) {
        return {
          href: this.goToLink,
          "aria-labelledby": this.ariaLabelledbyForBadgeLink,
        };
      }
      return {};
    },

    titelClass() {
      return this.parameter.css_klasse === "info" ? "h5" : "h4";
    },

    componentLocal() {
      return this.count ? "a" : "div";
    },
  },
};
