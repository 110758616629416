import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import {
  sortBy,
} from "lodash-es";

// @vue/component
export default {
  name: "SitzungsantraegeModalEntscheiden",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    baseSaveUrl: {
      type: String,
      default: undefined,
    },
    entscheidungsstatus: {
      type: Array,
      default: () => [],
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "info",
            type: "readonly",
            class: "alert alert-info",
            text: ``,
          },
          {
            id: "entscheidungsstatus",
            type: "select",
            required: true,
            label: "_LBL_SITZUNGSANTRAEGE_MODAL_ENTSCHEIDEN_ENTSCHEIDUNG_",
            view: "v",
            keyId: "pk",
            keyLabel: "bez",
            search: true,
            searchList: ["bez"],
          },
        ],
      },
      data: {
        entscheidungsstatus: [],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
      initialStatusObj: {},
    };
  },
  created() {
    this.initDataForEntscheidungsstatus();
    this.initReadonlyText();
  },
  methods: {
    initDataForEntscheidungsstatus() {
      this.data.entscheidungsstatus = sortBy(this.entscheidungsstatus, ["pos"]);
      for (let i = 0; i < this.data.entscheidungsstatus.length; i++) {
        if (this.data.entscheidungsstatus[i].initialer_status) {
          this.initialStatusObj = this.data.entscheidungsstatus[i];
          break;
        }
      }
    },

    initReadonlyText() {
      this.options.list[0].text = `
        <p>Für <strong>alle</strong> Anträge dieser Liste mit dem Status
        <strong>${ this.initialStatusObj.bez }</strong> wird die ausgewählte Entscheidung
        übernommen.</p>
        <p><i class="glyphicon glyphicon-warning"></i> Dies kann nicht rückgängig gemacht werden.</p>
      `;
    },

    save() {
      this.status.loading = true;
      const DATA = {
        entscheidungsstatus: this.model.entscheidungsstatus,
      };
      this.postHttp({
        url: `${ this.baseSaveUrl }setze_entscheidungen/`,
        data: DATA,
      }).then(
        () => {
          this.close({ status: true });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
