"use strict";

import builder from "../utils/builder.js";

import template from "./detail.jade";
import BaseController from "../standard_sitzungsmodule.base.controller";

class AntragsuebersichtDetailController extends BaseController {
  /*@ngInject*/
  constructor($scope, $q, AuthService, Katalog) {
    super($scope);
    this.$q = $q;
    this.Katalog = Katalog;
    this.AuthService = AuthService;
    this.hasViewPermission = this.canView();
  }

  canView() {
    return this.AuthService.syncHasPerm("tops.mittel.view", this.permissionSrc);
  }

  onEvent(data) {
    let anyInGroup = false;
    for (let i = 0; i < this.modul.modulgruppen.length; i++) {
      if (data.groups.indexOf(this.modul.modulgruppen[i]) >= 0) {
        anyInGroup = true;
        break;
      }
    }
    if (anyInGroup) {
      this.modul.get().then(
        response => {
          this.modul.merge(this.modul, response);
          this.prepareParameter();
          this.prepareUserInput();
        }
      );
    }
  }

  getValueClass(value, compareValue) {
    if (value > 0 && value <= compareValue) {
      return "value-ok";
    }
    if (value > compareValue) {
      return "value-warning";
    }
  }
}

export default builder.detail({
  template: template(),
  controller: AntragsuebersichtDetailController,
  controllerAs: "vm"
});
