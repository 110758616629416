import RechenhilfeButton from "./RechenhilfeButton/RechenhilfeButton.vue";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  components: {
    RechenhilfeButton
  },
  data() {
    return {
      statusUse: false,
    };
  },
  methods: {
    saveRechenhilfeButton({ betrag, von, bis }) {
      const MODEL = cloneDeep(this.model);
      MODEL.wert = betrag;
      MODEL.von = von;
      MODEL.bis = bis;
      this.model = MODEL;
      this.statusUse = true;
    },
  },
};
