import translate from "../../../../../global/directives/translate";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PruefkriteriumIcon from "../PruefkriteriumIcon/PruefkriteriumIcon.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import {
  cloneDeep,
  map,
  size,
  isBoolean,
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "PruefkriteriumDetailEditModal",
  components: {
    PruefkriteriumIcon,
    FormElement,
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  props: {
    obj: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      model: undefined,
      modalOptions: {
        showCloseButton: true,
        required: true,
        list: undefined,
        style: {
          padding: 0,
          overflowY: "hidden",
        }
      },
      formOptions: undefined,
      booleanFeldTemplate: {
        type: "boolean",
        view: "v-alt",
        translate: true,
        required: false,
        triState: true,
        trueLabel: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_VALUE_TRUE_",
        falseLabel: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_VALUE_FALSE_",
        defaultLabel: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_VALUE_NULL_",
        defaultValue: null,
      },
    };
  },
  computed: {
    pruefrelevant() {
      return filter(this.obj.pruefkriterium.details, prfd => prfd.prfd_pruefrelevant);
    },

    pruefkriteriumValue() {
      if (!this.model || size(this.pruefrelevant) === 0) {
        return null;
      }

      for (let i = 0; i < size(this.pruefrelevant); i++) {
        const PRFD_ID = this.pruefrelevant[i].pk;
        if (this.model[PRFD_ID] === false) {
          return false;
        } else if (this.model[PRFD_ID] !== true) {
          return null;
        }
      }
      return true;
    },

    translationTxtHeader() {
      return {
        text: (
          size(this.obj.opr_subkriterien) > 0 ?
          "_TXT_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_MODAL_EDIT_HEADER_{{pruefkriterium}}_" :
          "_TXT_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_MODAL_CREATE_HEADER_{{pruefkriterium}}_"
        ),
        extra: { pruefkriterium: this.obj.pruefkriterium.prf_kbez }
      };
    },

    translationPruefkriteriumValuePositive() {
      return { text: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_VALUE_TRUE_" };
    },

    translationPruefkriteriumValueNegative() {
      return { text: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_VALUE_FALSE_" };
    },

    translationPruefkriteriumValueNull() {
      return { text: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_VALUE_NULL_" };
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.model = cloneDeep(this.obj.opr_subkriterien) || {};
      const FIELDS = map(this.obj.pruefkriterium.details, prfd => {
        const PRFD_AUSWAHL = cloneDeep(this.booleanFeldTemplate);
        PRFD_AUSWAHL.label = prfd.prfd_kbez;
        PRFD_AUSWAHL.helpText = prfd.prfd_anweisung;
        PRFD_AUSWAHL.id = prfd.pk;
        PRFD_AUSWAHL.required = prfd.prfd_pruefrelevant;
        PRFD_AUSWAHL.htmlId = `${ this.obj.pruefkriterium.pk }-${ prfd.pk }`;
        this.model[prfd.pk] = isBoolean(this.model[prfd.pk]) ? this.model[prfd.pk] : null;
        return PRFD_AUSWAHL;
      });
      this.formOptions = FIELDS;
    },

    apply() {
      const DATA = {
        opr_geprueft: this.pruefkriteriumValue,
        opr_subkriterien: cloneDeep(this.model)
      };
      this.close(DATA);
    },

    getPruefkriteriumValueTranslation() {
      switch (this.pruefkriteriumValue) {
      case true:
        return this.translationPruefkriteriumValuePositive;
      case false:
        return this.translationPruefkriteriumValueNegative;
      default:
        return this.translationPruefkriteriumValueNull;
      }
    },
  },
};
