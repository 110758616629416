import {
  cloneDeep,
  forEach,
  size,
} from "lodash-es";

const UI_VALIDATED_JSON_MODE_LIST = "list";
const UI_VALIDATED_JSON_MODE_JSON = "json";
const UI_VALIDATED_JSON_MODE_SINGLE = "single";

export default {
  computed: {
    mode() {
      if (this.options.many && !this.options.as_json) {
        return UI_VALIDATED_JSON_MODE_LIST;
      } else if (this.options.many) {
        return UI_VALIDATED_JSON_MODE_JSON;
      }
      return UI_VALIDATED_JSON_MODE_SINGLE;
    },
    isModeList() {
      return this.mode === UI_VALIDATED_JSON_MODE_LIST;
    },
    isModeJson() {
      return this.mode === UI_VALIDATED_JSON_MODE_JSON;
    },
    isModeSingle() {
      return this.mode === UI_VALIDATED_JSON_MODE_SINGLE;
    },
    localModel() {
      return this.model || (this.isModeList ? [] : {});
    },
    modelSize() {
      return size(this.localModel);
    },
    keyField() {
      return this.options.key_field;
    },
    posField() {
      return this.options.pos_field;
    },
    minLength() {
      return this.options.min_length;
    },
    maxLength() {
      return this.options.max_length;
    },
    elementLabel() {
      return this.options.element_label;
    },
    elementTemplate() {
      return this.options.element_template;
    },
    fields() {
      return this.options.fields;
    },
    fieldsReadonly() {
      const READONLY_FIELDS = cloneDeep(this.fields);
      forEach(READONLY_FIELDS, el => el.readonly = true);
      return READONLY_FIELDS;
    },
    translateExtra() {
      return { elementLabel: this.elementLabel };
    },
  }
};
