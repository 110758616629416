import moment from "moment";

// @vue/component
export default {
  methods: {
    getANBrutto_mixin({ period }) {
      const MONTH_VON_BEGIN = moment(period.von).startOf("month");
      if (!MONTH_VON_BEGIN) {
        return 0;
      }
      const MONTH_BIS_ENDE = moment(period.bis).endOf("month");
      if (!MONTH_BIS_ENDE) {
        return 0;
      }
      const MONTHS = 1 + MONTH_BIS_ENDE.diff(MONTH_VON_BEGIN, "months");
      const GEHALT = period.brutto_monat || 0;
      const EINMALZAHLUNG = period.einmalzahlung || 0;
      return MONTHS * GEHALT + +EINMALZAHLUNG;
    },
  },
};
