"use strict";

import BaseController from "../config.base.controller";

class ParametermoduleChoiceController extends BaseController {
  /*@ngInject*/
  constructor() {
    super();
    if (this.parameter[this.key].wert === "true" ||
        this.parameter[this.key].wert === "True" ||
        this.parameter[this.key].wert === true) {
      this.parameter[this.key].wert = true;
    } else {
      this.parameter[this.key].wert = false;
    }
    this.trueLabel = "Ja";
    if (this.parameter[this.key].options && this.parameter[this.key].options.true_label) {
      this.trueLabel = this.parameter[this.key].options.true_label;
    }

    this.falseLabel = "Nein";
    if (this.parameter[this.key].options && this.parameter[this.key].options.false_label) {
      this.falseLabel = this.parameter[this.key].options.false_label;
    }
  }
}

export default ParametermoduleChoiceController;
