import PuxAlert from "../../../global/components/PuxAlert/PuxAlert.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";

import icons, {
  StandardIcons,
} from "../../../../../../../frontend/client/icons";
import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "IconsList",
  components: {
    PuxAlert,
    PuxIcon,
    PuxPageDetails,
  },
  setup() {
    const getDifference = (obj1, obj2) => {
      const DIFF = {};
      forEach(obj1, (item, key) => {
        if (!obj2[key]) {
          DIFF[key] = item;
        }
      });
      forEach(obj2, (item, key) => {
        if (!obj1[key]) {
          DIFF[key] = item;
        }
      });
      return DIFF;
    };

    const restIcons = getDifference(icons, StandardIcons);

    return {
      restIcons,
      StandardIcons,
    };
  },
};
