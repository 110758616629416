"use strict";

import angular from "angular";

import ModalFactory from "./factory.service";

const services = angular.module("dias.modal.services", [
  "mgcrea.ngStrap.modal",
])

  .provider("diasModalDialog", ModalFactory)
;

export default services;
