"use strict";

import angular from "angular";

import spacedFilter from "./spaced.pipe";


const filterModule = angular.module("dias.ui.spaced.pipes", [])

  .filter("spaced", spacedFilter);

export default filterModule;
