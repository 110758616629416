import {
  isUndefined,
} from "lodash-es";

export function onUtag(arg) {
  if (isUndefined(window.utag)) {
    return;
  }
  try {
    window.utag.link(arg);
  } catch (err) {
    console.error(err);
  }
}
