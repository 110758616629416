import SmartTable from "../../../global/components/table/table.vue";
import getTableApi from "../../../const/TableApis";
import WorkflowfolgeserverfunktionenModalCreate from "../WorkflowfolgeserverfunktionenModalCreate/WorkflowfolgeserverfunktionenModalCreate.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";

const TABLE_ID = "workflowfolgeserverfunktion";

// @vue/component
export default {
  name: "WorkflowfolgeserverfunktionenList",
  components: {
    SmartTable,
    WorkflowfolgeserverfunktionenModalCreate,
    PageTabTitle,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: this.$gettext("Details anzeigen"),
            route: "root.workflowfolgeserverfunktionen.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [
          {
            label: this.$gettext("Neue Serverfunktion erstellen"),
            callback: () => this.modal.create = true,
            permission: "serverfunktion.create",
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "serverfunktion_create",
          },
        ],
        label: "Serverfunktionen",
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      modal: {
        create: undefined,
      },
    };
  },
  methods: {
    closeModalCreate() {
      this.modal.create = false;
    },
  },
};
