"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import "angular-chart.js";
import "angular-drag-and-drop-lists";

import diasAuthService from "dias/auth/services";
import diasConfirm from "dias/core/confirm";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";

import foerdermodule from "dias/foerderantrag/foerdermodule";
import foerderregeln from "dias/foerderregeln/models";

import models from "../models";
import detailsComponent from "./details.component";
import overviewComponent from "./overview.component";
import objektgutachtenComponent from "./objektgutachten.readonly.component";

const gutachtenModule = angular.module("root.gutachten.berichte", [
  uiRouter,
  models.name,
  foerdermodule.name,
  foerderregeln.name,
  diasAuthService.name,
  diasConfirm.name,
  diasTable.name,
  diasLoading.name,
  diasNotification.name,
]);

gutachtenModule.config($stateProvider => {
  $stateProvider

    // Sitzungs-Liste
    .state("root.gutachten", {
      url: `/gutachten/?typ
        &ga_kbez
        &ga_nr
        &ga_objektname
        &ga_objektid
        &ga_termin
        &ga_gutachtendatum
        &nutzer
        &pruefer
        &ersteller
        &ergebnis
        &vorlage
        &workflowstatusgruppe
        &friststatus
        &status
        &status_detail
        &zustaendig
        &meine_gutachten`,
      views: {
        "main@root": {
          template: "<div vueinit><vue-gutachten-list></vue-gutachten-list>"
        }
      },
      data: {
        permissions: ["gutachten.list"]
      },
    })

    // Detail-Seite einer Sitzung
    .state("root.gutachten.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<gutachten-details></gutachten-details>"
        }
      },
      data: {
        permissions: ["gutachten.list"]
      },
    })

  ;
})

  .component("gutachtenDetails", detailsComponent)
  .component("gutachtenOverview", overviewComponent)
  .component("objektGutachten", objektgutachtenComponent)
;

export default gutachtenModule;
