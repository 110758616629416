"use strict";
import angular from "angular";

import DiasNotification from "dias/core/notification";

import services from "./services";

const diasFavoriten = angular.module("dias.favoriten", [
  DiasNotification.name,
  services.name
]);

export default diasFavoriten;
