import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import NachrichtenLink from "../../../../Nachrichten/NachrichtenLink/NachrichtenLink.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragOverviewVertragspartner",
  components: {
    AngularLink,
    NachrichtenLink,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    vertrag: {
      type: Object,
      required: true,
    },
  },
  computed: {
    vertragAstFirstMaster() {
      return get(this.vertrag, "antragstellerorg.master[0]");
    },

    vertragAstFoerderorganisation() {
      return get(this.vertrag, "antragstellerorg.foerderorganisation");
    },

    extraForTranslate() {
      return {
        name: this.vertragFoerderorganisationName,
        ast_name: this.vertragAntragstellerorganisationName,
      };
    },

    vertragAntragstellerorganisationName() {
      return get(this.vertrag, "antragstellerorg.ast_name");
    },

    vertragFoerderorganisationName() {
      return get(this.vertrag, "antragstellerorg.foerderorganisation_obj.o_name");
    },
  },
};
