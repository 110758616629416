import PersonThFirst from "../PersonThFirst/PersonThFirst.vue";
import PersonTd from "../PersonTd/PersonTd.vue";
import Permission from "../../../../../global/components/Permission/Permission.vue";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import PersonModalCreateOrEdit from "../PersonModalCreateOrEdit/PersonModalCreateOrEdit.vue";
import BelegModalCreate from "../BelegModalCreate/BelegModalCreate.vue";
import HttpMixin from "../../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../../global/mixins/NotificationMixin";
import translate from "../../../../../global/directives/translate";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import { get } from "lodash-es";

// @vue/component
export default {
  name: "PersonTr",
  components: {
    PersonThFirst,
    PersonTd,
    Permission,
    Modal,
    PersonModalCreateOrEdit,
    BelegModalCreate,
    PuxButton,
    PuxIcon,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    position: {
      type: Object,
      required: true,
    },
    person: {
      type: Object,
      required: true,
    },
    personIndex: {
      type: Number,
      required: true,
    },
    periodenAll: {
      type: Array,
      required: true,
    },
    periodenWithTeile: {
      type: Array,
      required: true,
    },
    periodenColsWidth: {
      type: Object,
      required: true,
    },
    canCreateBeleg: {
      type: Boolean,
      required: true,
    },
    vertrag: {
      type: Object,
      required: true,
    },
    vertragkosten: {
      type: String,
      required: true,
    },
    durchfuehrung: {
      type: Object,
      required: true,
    },
    deletePerson: {
      type: Function,
      required: true,
    },
    updatePerson: {
      type: Function,
      required: true,
    },
    updateBelegeForPosition: {
      type: Function,
      required: true,
    },
    updateDokumentForBelege: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      confirmDeletePersonShow: false,
      confirmOptionsDeletePerson: undefined,
      statusPruefenModal: undefined,
      modalEditPerson: undefined,
      modalCreateBeleg: undefined,
    };
  },
  computed: {
    titleForButtonDeletePerson() {
      return {
        title: this.person.vpsp_pruefstatus ?
          "_BTN_VERTRAG_DETAILS_PERSONALBAUM_PERSON_DELETE_TITLE_DISABLED_" :
          "_BTN_VERTRAG_DETAILS_PERSONALBAUM_PERSON_DELETE_TITLE_",
      };
    },
    titleForButtonEditPerson() {
      return this.person.vpsp_pruefstatus ?
        "_BTN_VERTRAG_DETAILS_PERSONALBAUM_PERSON_EDIT_TITLE_DISABLED_" :
        "_BTN_VERTRAG_DETAILS_PERSONALBAUM_PERSON_EDIT_TITLE_";
    },
  },
  methods: {
    openModalCreateBeleg() {
      this.modalCreateBeleg = true;
    },

    closeModalCreateBeleg({ beleg, dokument } = {}) {
      if (beleg) {
        this.updateBelegeForPosition({
          position: this.position.position,
          beleg,
          statusCreate: true,
        });
        this.updateDokumentForBelege({
          dokument,
        });
      }
      this.modalCreateBeleg = false;
    },

    openModalEditPerson({ statusPruefen } = {}) {
      this.statusPruefenModal = statusPruefen;
      this.modalEditPerson = true;
    },

    closeModalEditPerson({ response } = {}) {
      if (response) {
        this.updatePerson({ person: response, personIndex: this.personIndex });
      }
      this.modalEditPerson = undefined;
      this.statusPruefenModal = undefined;
    },

    openConfirmDeletePerson() {
      this.confirmOptionsDeletePerson = {
        okClass: "btn btn-primary",
        title: "_TXT_VERTRAG_DETAILS_PERSONALBAUM_PERSON_MODAL_DELETE_HEADER_",
        msg: `_MSG_VERTRAG_DETAILS_PERSONALBAUM_PERSON_MODAL_DELETE_SUCCESS_{{vorname}}_{{nachname}}_`,
        okLabel: "_BTN_DELETE_",
        okCallback: () => {
          this.deletePersonLocal();
        },
        extra: {
          vorname: this.person.vpsp_vorname,
          nachname: this.person.vpsp_nachname,
        },
        cancelCallback: this.closeConfirmDeletePerson,
        loading: false,
      };
      this.confirmDeletePersonShow = true;
    },

    deletePersonLocal() {
      this.confirmOptionsDeletePerson.loading = true;
      this.deleteHttp({
        url: `vertraege/${ this.vertrag.pk }/vertragspersonalstellenpersonen/${ this.person.pk }/`
      }).then(
        () => {
          this.addNotification({ text: "_MSG_VERTRAG_DETAILS_PERSONALBAUM_PERSON_MODAL_DELETE_SUCCESS_" });
          this.deletePerson({ person: this.person, personIndex: this.personIndex });
          this.closeConfirmDeletePerson();
        },
        err => this.addNotification({ text: get(err, "data"), type: "danger" })
      ).finally(
        () => this.confirmOptionsDeletePerson.loading = false
      );
    },

    closeConfirmDeletePerson() {
      this.confirmDeletePersonShow = false;
    },
  },
};
