import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import translate from "../../../../global/directives/translate";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";
import {
  initModelFromList,
  toFormElementFromParameter,
} from "../../../../global/functions/mappingForParameterToFormElement";
import {
  cloneDeep,
  forEach,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "GeschaeftsregelDetailsModul",
  components: {
    FormstepDetail,
    FormElementReadOnly,
    FormElement,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    regel: {
      type: Object,
      required: true,
    },
    updateRegel: {
      type: Function,
      required: true,
    },
    listConfig: {
      type: Array,
      required: true,
    },
    modelConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modelLocal: {},
      errors: {},
      errorsClone: {},
      modul_obj: undefined,
      modulOptions: {
        id: "modul",
        type: "select",
        label: "Modul",
        view: "v",
        required: true,
        url: "system_module/",
        apiSaveId: "system_module/",
        keyId: "sm_id",
        keyLabel: "sm_bez",
        change: this.onModulChange,
        search: true,
        searchList: ["sm_bez"],
        deselect: false,
      },
      listParameter: [],
      labels: {},
    };
  },
  computed: {
    optionsFormstepDetail() {
      const OPTIONS = {
        label: "Modul",
        saveCallback: this.save,
        openCallback: this.open,
      };
      if (this.regel.is_used) {
        OPTIONS.info = "Es sollten nur noch redaktionelle Änderungen an dieser Geschäftsregel durchgeführt werden, da sie bereits in mindestens einem Förderangebot verwendet wird.";
      }
      return OPTIONS;
    },

    statusEditable() {
      return this.checkPermissionsSync({
        perm: "foerderregel.update",
        permArray: this.regel.user_permissions,
      });
    },

    htmlId() {
      return "gr_modul";
    },

    dependencyValuesEdit() {
      const DEPS = {};
      forEach(this.listParameter, item => {
        if (item.dependency) {
          DEPS[item.id] = get(this.modelLocal, item.dependency);
        }
      });
      return DEPS;
    },

    dependencyValuesReadOnly() {
      const DEPS = {};
      forEach(this.listConfig, item => {
        if (item.dependency) {
          DEPS[item.id] = get(this.modelConfig, item.dependency);
        }
      });
      return DEPS;
    },
  },
  methods: {
    open() {
      this.removeErrors();
      const MODEL_LOCAL = cloneDeep(this.modelConfig) || {};
      MODEL_LOCAL.modul = this.regel.modul;
      this.modelLocal = MODEL_LOCAL;
      this.modul_obj = this.regel.modul_obj;
      this.listParameter = cloneDeep(this.listConfig);
      this.setLabels();
    },

    save() {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: `foerderregeln/${ this.regel.pk }/`,
          data: this.modelLocal,
        }).then(
          response => {
            this.updateRegel({ regelLocal: response });
            this.addNotification({
              text: "Geschäftsregel erfolgreich gespeichert.",
            });
            if (response.is_used) {
              this.addNotification({
                text: "Zugehörige Regeln an Förderangeboten wurden ebenfalls aktualisiert"
              });
            }
            this.removeErrors();
            resolve();
          },
          error => {
            this.errors = error.data || {};
            this.errorsClone = cloneDeep(error.data) || {};
            reject(error);
          }
        );
      });
    },

    removeErrors() {
      this.errors = {};
      this.errorsClone = {};
    },

    onModulChange({ item }) {
      this.modul_obj = cloneDeep(item) || {};
      this.setList();
      this.setModelFromList();
      this.setLabels();
    },

    setList() {
      this.listParameter = toFormElementFromParameter({ obj: this.modul_obj.sm_parameterin, showLabel: true }).list || [];
    },

    setModelFromList() {
      const MODEL_LOCAL = initModelFromList({ list: this.listParameter }) || {};
      MODEL_LOCAL.modul = this.modul_obj.pk;
      this.modelLocal = MODEL_LOCAL;
    },

    setLabels() {
      const LABELS = {
        modul: "Modul",
      };
      forEach(this.listParameter, item => {
        LABELS[item.id] = item.label;
      });
      this.labels = LABELS;
    },

    updateErrors({ errors, id }) {
      this.errors[id] = errors;
    },
  },
};
