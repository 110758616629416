import UiRatingMixin from "../UiRatingMixin";
import UIReadOnlyMixin from "../../UIReadOnlyMixin";

// @vue/component
export default {
  name: "UiRatingReadOnly",
  mixins: [
    UiRatingMixin,
    UIReadOnlyMixin,
  ],
};
