"use strict";

import template from "./details.jade";

import basedataEditController from "./basisdaten.edit.controller";
import basedataEditTemplate from "./basisdaten.edit.jade";
import regelsatzVersionEditController from "./regelsatzversion.edit.controller";
import regelsatzVersionEditTemplate from "./regelsatzversion.edit.jade";
import regelsatzInfoEditController from "./regelsatzinfo.edit.controller";
import regelsatzInfoEditTemplate from "./regelsatzinfo.edit.jade";
import foerderdokumenteEditController from "./foerderdokumente.edit.controller";
import foerderdokumenteEditTemplate from "./foerderdokumente.edit.jade";
import changeGueltigBisController from "./changegueltigbis.controller";
import changeGueltigBisTemplate from "./changegueltigbis.jade";
import regelsignalUpdateController from "./regelsatzversion.signal.edit.controller";
import regelsignalUpdateTemplate from "./regelsatzversion.signal.edit.jade";

import foerderorganisationUpdateController from "./modal.foerderorganisation.update.controller";
import foerderorganisationUpdateTemplate from "./modal.foerderorganisation.update.jade";
import kategorieUpdateController from "./modal.kategorie.update.controller";
import kategorieUpdateTemplate from "./modal.kategorie.update.jade";

class FoerderangeboteDetailsController {
  /*@ngInject*/
  constructor(
    $q,
    $state,
    $rootScope,
    $stateParams,
    DiasNotification,
    diasConfirmDialog,
    diasModalDialog,
    Foerderangebote,
    Foerderregeln,
    Anzeigekontextstatus,
    Foerderangebotstatus,
    AnzeigekontextstatusUUIDs,
    FoerderangebotAnzeigekontextUUIDs,
    Katalog,
    FoerderangebotstatusUUIDs,
    i18n,
    AuthService,
    KZielgruppe,
    Workflow,
    KWorkflowtypUUIDs,
  ) {
    this.name = "FoerderangeboteDetailsController";
    this.gettext = i18n.gettext;
    this.$q = $q;
    this.diasModalDialog = diasModalDialog;
    this.Katalog = Katalog;
    this.notification = DiasNotification.page;
    this.AnzeigekontextstatusUUIDs = AnzeigekontextstatusUUIDs;
    this.FoerderangebotAnzeigekontextUUIDs = FoerderangebotAnzeigekontextUUIDs;
    this.FoerderangebotstatusUUIDs = FoerderangebotstatusUUIDs;
    this.AuthService = AuthService;
    this.KZielgruppe = KZielgruppe;
    this.Workflow = Workflow;
    this.KWorkflowtypUUIDs = KWorkflowtypUUIDs;

    this.baseEditController = basedataEditController;
    this.baseEditTemplate = basedataEditTemplate;
    this.regelsatzVersionEditTemplate = regelsatzVersionEditTemplate;
    this.regelsatzVersionEditController = regelsatzVersionEditController;
    this.regelsatzInfoEditTemplate = regelsatzInfoEditTemplate;
    this.regelsatzInfoEditController = regelsatzInfoEditController;
    this.foerderdokumenteEditController = foerderdokumenteEditController;
    this.foerderdokumenteEditTemplate = foerderdokumenteEditTemplate;
    this.regelsignalUpdateController = regelsignalUpdateController;
    this.regelsignalUpdateTemplate = regelsignalUpdateTemplate;

    this.$state = $state;

    this.regelsatz = {};
    // this.foerderdokumente = [];

    // Alle loading-flags:
    this.loading = {
      ready: false,
      deletable: true,
      editable: true,
      angebot: true,
      foerderdokumente: true,
      oeffentlich: false,
    };
    this.permViewRegeln = this.AuthService.syncHasPerm("foerderangebot.view.regeln");
    this.canEditInfo = this.AuthService.syncHasPerm("foerderangebot.update_info");

    this._loadAngebotsDaten = currentRegelsatzId => {
      // reset der loading-flags nur von den daten, die hier in der methode (neu-)geladen werden:
      this.loading.angebot = true;
      this.loading.ready = false;

      // Angebot-Basisdaten und anschließend Regelsatz-Daten laden
      Foerderangebote.one($stateParams.id).get().then(response => {
        this.loading.angebot = false;
        this.angebot = response;
        if (!currentRegelsatzId || this.angebot.regelsatz_obj.pk === currentRegelsatzId) {
          this.regelsatz = this.angebot.regelsatz_obj;
          this.angebot.restangularizeRegelsatz(this.regelsatz);
          if (this.regelsaetze) {
            this._putCurrentRegelsatzInListe();
          }
          this._loadRegelsatzDaten();
        } else {
          this.showRegelsatzVersion({ pk: currentRegelsatzId });
        }
        this._loadRegelsatzListe();
        this.loadFoerderdokumente();
        this.checkFoerderdokumentePerm();
      });
    };

    // Angebot, Regelsatzdaten, etc. initial laden:
    // Der Parameter '$stateParams.regelsatzId' ist optional. Wenn gesetzt, wird damit
    // die initial angezeigte Regelsatz-Version bestimmt.
    this._loadAngebotsDaten($stateParams.regelsatzId);
    this.loadKataloge();

    // verfügbare Angebot-Status laden
    this.editableAngebotStatus = [];
    const statusPromise = Foerderangebotstatus.getList().then(
      response => this.angebotstatus = response
    );
    const anzeigePromise = Anzeigekontextstatus.getList().then(
      response => this.Anzeigekontextstatus = response
    );
    const statusPromiseAfter = statusPromise.then(
      () => {
        // deletable Status
        this.angebotstatus.fetchDeletable().then(
          response => this.deletableAngebotStatus = response
        ).finally(
          () => {
            this.loading.deletable = false;
            // Titel wird vom ui-resolver nach Initialisierung
            // des Controllers überschrieben, deswegen müssen wir
            // ihn später überschreiben, z.B. hier.
          }
        );
        // editable Status
        this.angebotstatus.fetchEditable().then(
          response => this.editableAngebotStatus = response
        ).finally(
          () => this.loading.editable = false
        );
      }
    );

    this.updateRegelsatzStatus = transition => {
      diasConfirmDialog({
        titleTemplate: this.insertFoerderangbotIntoActionString(transition.bez),
        contentTemplate: `<p>Sind Sie sicher, dass Sie die Version <strong>{{ context.regelsatz.ars_version }}</strong> des Förderangebots <strong>{{ context.angebot.foe_kbez }}</strong> {{ context.transition.kbez|lowercase }} wollen?</p>` +
          (this._checkAutofixEnddateMessage(transition) ? `<p><strong>Bitte beachten Sie:</strong> Mit dieser Aktion wird das Ende-Datum der aktuell gültigen Version ({{ context.angebot.aktiver_regelsatz.ars_version }}) automatisch auf den Tag vor dem Gültig-Ab-Datum der neuen Version gesetzt.</p>` : ``),
        context: { angebot: this.angebot, transition: transition, regelsatz: this.regelsatz },
        okLabel: transition.kbez,
        okCallback: () => this.angebot.regelsaetze.one(this.regelsatz.pk).updateStatus(transition.status).then(
          () => {
            DiasNotification.page.success(transition.kbez + " erfolgreich ausgeführt.");
            this._loadAngebotsDaten(this.regelsatz.pk);
          },
          error => DiasNotification.page.error(error)
        )
      });
    };

    this._checkAutofixEnddateMessage = transition => {
      return (transition.status === FoerderangebotstatusUUIDs.freigegeben && this.angebot.aktiver_regelsatz &&
              this.angebot.aktiver_regelsatz.pk !== this.regelsatz.pk && !this.angebot.aktiver_regelsatz.ars_gueltig_bis);
    };

    this.getStatusActionTitle = transition => {
      return transition.active_reason || this.insertFoerderangbotIntoActionString(transition.bez);
    };

    this.insertFoerderangbotIntoActionString = str => {
      return str.replace("{name}", this.angebot.foe_kbez).replace("{version}", this.regelsatz.ars_version);
    };

    this.angebotEditable = () => {
      return this.angebot.editable;
    };

    this.anonymisieurngsplanEditable = () => {
      return this.regelsatzVersionEditable() && this.AuthService.syncHasPerm("foerderangebot.anonymisierungsplan.update");
    };

    this.regelsatzVersionEditable = () => {
      return this.editableAngebotStatus && this.editableAngebotStatus.indexOf(this.regelsatz.status) !== -1;
    };

    this.regelsatzVersionFreigegeben = () => {
      return this.regelsatz && this.regelsatz.status === this.FoerderangebotstatusUUIDs.freigegeben;
    };

    this.regelsatzDeletable = () => {
      return (this.deletableAngebotStatus && this.deletableAngebotStatus.indexOf(this.regelsatz.status) !== -1 &&
              this.AuthService.syncHasPerm("foerderangebot.delete"));
    };

    this.regelsatzEndeDatumEditable = () => {
      return (!this.regelsatzVersionEditable() && this.regelsatzVersionFreigegeben() &&
              this.AuthService.syncHasPerm("foerderangebot.freigeben"));
    };

    this.deleteRegelsatzVersion = () => {
      const isLastVersion = this.regelsaetze.length === 1;
      diasConfirmDialog({
        titleTemplate: `Version {{ context.regelsatz.ars_version }} löschen`,
        contentTemplate: `<p>Sind Sie sicher, dass Sie die Version {{ context.regelsatz.ars_version }} löschen wollen?</p>` +
          (isLastVersion ? `<p><strong>Bitte beachten Sie:</strong> Mit dem Löschen der letzten Version wird das Förderangebot mitgelöscht.</p>` : ``),
        context: { angebot: this.angebot, regelsatz: this.regelsatz },
        okLabel: "Version " + this.regelsatz.ars_version + " löschen",
        okCallback: () => {
          if (isLastVersion) {
            // Angebot löschen
            this.angebot.remove().then(
              () => {
                this.notification.success(this.gettext("Förderangebot") + " " + this.angebot.foe_kbez + " erfolgreich gelöscht");
                $state.go("root.foerderangebote");
              },
              () => this.notification.error("Fehler beim Löschen des " + this.gettext("Förderangebot") + "s.")
            );
          } else {
            // Version löschen
            this.regelsatz.remove().then(
              () => {
                this.notification.success("Version " + this.regelsatz.ars_version + " erfolgreich gelöscht.");
                this._loadAngebotsDaten(null);
              },
              () => this.notification.error("Fehler beim Löschen der Version.")
            );
          }
        }
      });
    };

    this._loadRegelsatzListe = () => {
      this.angebot.getRegelsaetze("-ars_version").then(response => {
        this.regelsaetze = response;
        this._putCurrentRegelsatzInListe();
      });
    };

    this._putCurrentRegelsatzInListe = () => {
      if (!this.regelsaetze && this.regelsatz) {
        this.regelsaetze = [this.regelsatz];
        return;
      }
      for (let i = 0; i < this.regelsaetze.length; i++) {
        if (this.regelsatz.pk === this.regelsaetze[i].pk) {
          this.regelsaetze[i] = this.regelsatz;
          break;
        }
      }
    };

    this._loadRegelsatzDaten = () => {
      const foerderkontexteDefer = $q.defer();

      // reset der loading-flags nur von den daten, die hier in der methode (neu-)geladen werden:
      this.loading.ready = false;
      if (!this.permViewRegeln) {
        foerderkontexteDefer.resolve([]);
      } else {
        const kPromise = this.angebot.regelsaetze.one(this.regelsatz.pk).anzeigekontexte.getList().then(
          response => {
            this.createElementInObj(response);
          }
        );
        kPromise.then(foerderkontexteDefer.resolve, foerderkontexteDefer.reject);
      }

      $q.all([
        statusPromiseAfter,
        anzeigePromise,
        foerderkontexteDefer.promise,
        this.onRegelUpdate()
      ]).then(
        () => {},
        error => this.notification.error(error, "Angebotskonfiguration konnte nicht geladen werden.")
      ).finally(
        () => {
          this.loading.ready = true;
        }
      );
    };

    this._getRegelsatzById = id => {
      for (let i = 0; i < this.regelsaetze.length; i++) {
        const rs = this.regelsaetze[i];
        if (rs.pk === id) {
          return rs;
        }
      }
      return null;
    };

    this.createNewRegelsatzVersion = () => {
      const promise = this.angebot.createNewRegelsatzVersion(this.regelsatz.pk);
      promise.then(
        response => {
          this.notification.success("Eine neue Version wurde erfolgreich erstellt.");
          this._loadAngebotsDaten(response.pk);
        },
        () => this.notification.error("Fehler beim Erstellen der neuen Version.")
      );
      return promise;
    };

    this.showRegelsatzVersion = regelsatz => {
      this.$state.go(".", { id: this.angebot.pk, regelsatzId: regelsatz.pk }, { notify: false });
      this.angebot.regelsaetze.one(regelsatz.pk).get().then(
        response => {
          this.regelsatz = response;
          this._putCurrentRegelsatzInListe();
          this._loadRegelsatzDaten();
        }
      );
    };

    this.changeGueltigBis = () => {
      this.diasModalDialog({
        title: "Ende-Datum des Gültigkeitszeitraums ändern",
        template: changeGueltigBisTemplate,
        controller: changeGueltigBisController,
        extras: {
          regelsatz: this.regelsatz,
          angebot: this.angebot
        }
      }).then(
        () => {
          for (let i = 0; i < this.regelsaetze.length; i++) {
            if (this.regelsatz.pk === this.regelsaetze[i].pk) {
              this.regelsaetze[i] = this.regelsaetze[i].merge(this.regelsaetze[i], this.regelsatz);
              break;
            }
          }
        }
      );
    };
    this._reloadRegelSignale = () => this.reloadSignale();
    this.onRegelUpdate = this.onRegelUpdate.bind(this);
    this.showModalVorversionAntraegeWorkflowfolge = false;
  }

  toggleKontext(kontext) {
    kontext.show = !kontext.show;
  }

  createElementInObj(obj) {
    for (let i = 0; i < obj.length; i++) {
      let stringTitle = obj[i].anzeigekontext.faz_kbez;
      stringTitle = stringTitle.replace(/ä/g, "ae");
      stringTitle = stringTitle.replace(/ö/g, "oe");
      stringTitle = stringTitle.replace(/ü/g, "ue");
      stringTitle = stringTitle.replace(/ß/g, "ss");
      obj[i].anzeigekontext.style = stringTitle;
    }
    this.foerderkontexte = obj;
  }

  checkFoerderdokumentePerm() {
    this.AuthService.hasPerm("foerderangebot.update_basisdaten").then(
      hasPerm => {
        this.permUpdateBasisdaten = hasPerm;
      }
    );
  }

  isFoerderorganisationUpdatable() {
    if (this.regelsaetze && this.regelsaetze.length) {
      for (let i = 0; i < this.regelsaetze.length; i++) {
        if (this.regelsaetze[i].status !== this.FoerderangebotstatusUUIDs.inBearbeitung) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  updateFoerderorganisation() {
    this.diasModalDialog({
      title: this.gettext("Förderorganisation") + " ändern",
      template: foerderorganisationUpdateTemplate,
      controller: foerderorganisationUpdateController,
      extras: {
        angebot: this.angebot
      }
    }).then(
      response => this.angebot = angular.merge(this.angebot, response)
    );
  }

  updateKategorie() {
    this.diasModalDialog({
      title: "Kategorie ändern",
      template: kategorieUpdateTemplate,
      controller: kategorieUpdateController,
      extras: {
        angebot: this.angebot
      }
    }).then(
      response => {
        if (response !== undefined) {
          this.angebot.kategoriewerte = response;
        }
      }
    );
  }

  onRegelUpdate() {
    if (!this.regelsatz) {
      return;
    }
    this.reloadSignale();
  }

  reloadSignale() {
    this.regelsatz.regelsignale.getList().then(response => {
      this.konfigurierteSignale = {};
      response.forEach(asv => {
        if (this.konfigurierteSignale[asv.empfaenger_regel] === undefined) {
          this.konfigurierteSignale[asv.empfaenger_regel] = [];
        }
        this.konfigurierteSignale[asv.empfaenger_regel].push(asv);
      });
      this.regelsatz.regelsignale.getKonfigurierbareEmpfaenger().then(
        response => {
          this.signalEmpfaenger = response;
          this.signalEmpfaenger.forEach(v => {
            v.repeaterTrackId = `${ v.mse_id }__${ v.empfaenger_far_id }`;
            v.sender = _.orderBy((this.konfigurierteSignale[v.empfaenger_far_id] || []).filter(asv => asv.signalempfang === v.mse_id), asv => asv.sender_nummer);
            v.senderPKs = v.sender.map(asv => asv.sender_regel);
          });
          const filteredEmpfaenger = _.filter(response, obj => obj.signal_relevant);
          this.groupedSignalEmpfaenger = _.groupBy(filteredEmpfaenger, v => v.empfaenger_far_id);
          this.orderedSignalEmpfaenger = _.orderBy(Object.keys(this.groupedSignalEmpfaenger), pk => this.groupedSignalEmpfaenger[pk][0].empfaenger_far_nummer);
        }
      );
    });
  }

  loadFoerderdokumente() {
    this.loading.foerderdokumente = true;
    this.angebot.foerderdokumente.getList().then(
      response => {
        this.foerderdokumente = response;

        this.loading.foerderdokumente = false;
      }
    );
  }

  loadKataloge() {
    this.loading.kataloge = true;
    this.$q.all(
      [
        this.Katalog.getKatalog("antragstellerorgtyp"),
        this.Katalog.getKatalog("konkrete_perioden"),
        this.Katalog.getKatalog("gutachtenvorlagen"),
        this.KZielgruppe.getList(),
        this.Workflow.getList({ workflowtyp: this.KWorkflowtypUUIDs.antrag }),
      ]
    ).then(
      responses => {
        this.antragstellerorgtypen = responses[0];
        this.perioden = responses[1];
        this.gutachtenvorlagen = responses[2];
        this.zielgruppen = responses[3];
        this.workflows = responses[4];
      }
    ).finally(() => this.loading.kataloge = false);
  }

  changeOeffentlich() {
    this.loading.oeffentlich = true;
    const data = { ars_oeffentlich: !this.regelsatz.ars_oeffentlich };
    this.regelsatz.customPUT(data, "oeffentlich_aendern").then(
      response => {
        const data = { ars_oeffentlich: response.ars_oeffentlich };
        const index = _.findIndex(this.regelsaetze, ["pk", this.regelsatz.pk]);
        this.regelsatz = Object.assign({}, this.regelsatz, data);
        this.regelsaetze[index] = Object.assign({}, this.regelsaetze[index], data);
        this.loading.oeffentlich = false;
      }
    );
  }
}

export default {
  template: template(),
  controller: FoerderangeboteDetailsController,
  controllerAs: "vm"
};
