import {
  computed,
  inject,
  toRefs,
} from "vue";

import FormstepDetail from "../../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxCloak from "../../../../../../global/components/PuxCloak/PuxCloak.vue";
import SitzungDetailsTagesordnungSelectedModul from "../../SitzungDetailsTagesordnungSelectedModul/SitzungDetailsTagesordnungSelectedModul.vue";

import HttpAPI from "../../../../../../global/compositionAPI/HttpAPI";
import SitzungDetailsTagesordnungItemAPI from "../../compositionAPI/SitzungDetailsTagesordnungItemAPI";

// @vue/component
export default {
  name: "SitzungDetailsTagesordnungItemEdit",
  components: {
    FormstepDetail,
    PuxCloak,
    SitzungDetailsTagesordnungSelectedModul,
  },
  props: {
    tagesordnung: {
      type: Array,
      required: true,
    },
    tagesordnungItem: {
      type: Object,
      required: true,
    },
    itemModulesPreview: {
      type: Object,
      default: () => {},
    },
    tagesordnungOberItem: {
      type: Object,
      default: undefined,
    },
    topsUrl: {
      type: String,
      required: true,
    },
    toggleEdit: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      tagesordnung,
      tagesordnungItem,
      tagesordnungOberItem,
      topsUrl,
    } = toRefs(props);

    const reloadTagesordnung = inject("reloadTagesordnung");

    const isUnterpunkt = computed(() => {
      return tagesordnungOberItem.value !== undefined;
    });

    const punkteChoices = computed(() => {
      const PUNKTE = [];
      if (isUnterpunkt.value) {
        const OBERPUNKT_POSITION = tagesordnungOberItem.value.tos_pos;
        for (let index = 0; index < tagesordnungOberItem.value.unterpunkte.length; index++) {
          const POSITION = index + 1;
          PUNKTE.push({
            label: OBERPUNKT_POSITION + "." + POSITION,
            value: POSITION,
          });
        }
      } else {
        for (let index = 0; index < tagesordnung.value.length; index++) {
          const POSITION = index + 1;
          PUNKTE.push({
            label: POSITION,
            value: POSITION,
          });
        }
      }
      return PUNKTE;
    });

    const {
      putHttp,
      addNotification,
    } = HttpAPI();

    const {
      addModule,
      loadKonfigurierbareModuleKatalog,
      loadSitzungTagesordnungModules,
      moveModuleDown,
      moveModuleUp,
      removeModule,
      setModel,

      buttonAddModuleDisabled,
      loadingModuleKatalog,
      loadingTagesordnungItemModules,
      model,
      moduleChoicesAvailable,
      modelOptionsModule,
      moduleKatalog,
      optionsModule,
      optionsTosBez,
      optionsTosInfo,
      optionsTosPos,
    } = SitzungDetailsTagesordnungItemAPI({
      topsUrl,
      punkteChoices,
      tagesordnungItem,
    });

    const errorsLabelsOptions = computed(() => {
      return [
        optionsTosBez.value,
        optionsTosInfo.value,
        optionsTosPos.value,
      ];
    });

    loadKonfigurierbareModuleKatalog();
    loadSitzungTagesordnungModules().then(
      () => setModel(),
    );

    return {
      addModule,
      loadSitzungTagesordnungModules,
      moveModuleDown,
      moveModuleUp,
      removeModule,
      setModel,

      addNotification,
      putHttp,
      reloadTagesordnung,

      buttonAddModuleDisabled,
      errorsLabelsOptions,
      isUnterpunkt,
      loadingModuleKatalog,
      loadingTagesordnungItemModules,
      model,
      moduleChoicesAvailable,
      modelOptionsModule,
      moduleKatalog,
      optionsModule,
      optionsTosBez,
      optionsTosInfo,
      optionsTosPos,
    };
  },
  data() {
    return {
      loading: false,
      errors: undefined,
      modalRemoveConfirmOptions: {},
    };
  },
  computed: {
    formstepId() {
      return `top_edit_form_${ this.isUnterpunkt ? this.tagesordnungOberItem.tos_pos + "." : "" }${ this.tagesordnungItem.tos_pos }`;
    },

    itemPosition() {
      if (this.tagesordnungOberItem) {
        return `${ this.tagesordnungOberItem.tos_pos }.${ this.tagesordnungItem.tos_pos }`;
      }
      return `${ this.tagesordnungItem.tos_pos }`;
    },

    formstepOptions() {
      return {
        label: "_LBL_SITZUNGSDETAILS_TAGESORDNUNGSPUNKT_EDIT_TOP_{{position}}_",
        extra: {
          position: this.itemPosition,
        },
        required: true,
        saveCallback: this.save,
        closeCallback: this.toggleEdit,
      };
    },
  },
  methods: {
    save() {
      this.deleteErrors();
      this.loading = true;
      const DATA = {};

      DATA.pk = this.tagesordnungItem.pk;
      DATA.top = this.tagesordnungItem.top;
      DATA.tos_pos = this.model.tos_pos;
      DATA.tos_bez = this.model.tos_bez;
      DATA.tos_info = this.model.tos_info;
      DATA.oberpunkt = this.model.oberpunkt;

      DATA.module = this.model.modules.map((value, index) => {
        value.stm_pos = index;
        value.sitzungstop = this.tagesordnungItem.pk;
        return value;
      });

      return this.putHttp({
        url: `${ this.topsUrl }${ this.tagesordnungItem.pk }/`,
        data: DATA,
      }).then(
        () => {
          this.reloadTagesordnung();
          return Promise.resolve();
        },
        errors => {
          this.onErrors(errors);
          return Promise.reject(errors);
        },
      ).finally(
        () => this.loading = false
      );
    },

    onErrors(errors) {
      this.errors = errors.data;
    },

    deleteErrors() {
      this.errors = undefined;
    },
  },
};
