"use strict";

import template from "./template.jade";

class AbhaengigeKostenpauschaleController {
  /*@ngInject*/
  constructor($scope, Katalog, i18n) {
    this.gettext = i18n.gettext;
    this.Katalog = Katalog;
    if (!this.kontext || this.kontext === "undefined") {
      this.kontext = "antrag";
    }
    this.$scope = $scope;
  }
}

export default {
  template: template(),
  controller: AbhaengigeKostenpauschaleController,
  controllerAs: "ctx",
  bindings: {
    rechner: "=",
    posmodel: "=",
    errors: "=",
    extras: "=",
    kontext: "=?",
    vorschau: "=",
    prefix: "<?"
  },
};
