import FoerderangebotstatusUUIDs from "../../../const/FoerderangebotstatusUUIDs";

// @vue/component
export default {
  name: "RegelsatzStatusLabel",
  props: {
    regelsatz: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getClass() {
      return {
        "label-success": this.isRegelsatzActive,
        "label-default": this.regelsatz.status_obj.kf_id === FoerderangebotstatusUUIDs.freigegeben && !this.isRegelsatzActive,
        "label-danger": this.regelsatz.status_obj.kf_id === FoerderangebotstatusUUIDs.deaktiviert,
        "label-info": this.regelsatz.status_obj.kf_id === FoerderangebotstatusUUIDs.inBearbeitung,
        "label-warning": this.regelsatz.status_obj.kf_id === FoerderangebotstatusUUIDs.abgeschlossen,
      };
    },

    isRegelsatzActive() {
      return this.regelsatz.is_active;
    },

    getRegelsatzStatusLabelKbez() {
      let statusKbez = this.regelsatz.status_obj.kf_kbez;
      if (this.isRegelsatzActive) {
        statusKbez += " - aktiv";
      } else if (this.regelsatz.status_obj.kf_id === FoerderangebotstatusUUIDs.freigegeben) {
        statusKbez += " - inaktiv";
      }
      return statusKbez;
    },
  },
};
