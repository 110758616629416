import AntragstellerVertretungsberechtigter from "../../../Vertretungsberechtigte/AntragstellerVertretungsberechtigter/AntragstellerVertretungsberechtigter.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import VertretungsberechtigteModalCreate from "../../../Vertretungsberechtigte/VertretungsberechtigteModalCreate/VertretungsberechtigteModalCreate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";

import {
  findIndex,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragstellerWizardVertretunsberechtigte",
  components: {
    AntragstellerVertretungsberechtigter,
    FormstepDetail,
    PuxButton,
    PuxTranslate,
    VertretungsberechtigteModalCreate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    organisation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modal: {
        create: undefined,
      },
      url: undefined,
      vertreters: [],
    };
  },
  computed: {
    statusButtonHide() {
      return this.vertreters && this.vertreters.length >= 4;
    },
  },
  created() {
    this.setUrl();
  },
  methods: {
    openModalCreate() {
      this.modal.create = true;
    },

    closeModalCreate({ response } = {}) {
      if (response) {
        this.vertreters.push(response);
      }
      this.modal.create = false;
    },

    setUrl() {
      this.url = `antragstellerorganisationen/${ this.organisation.pk }/vertretungsberechtigte/`;
    },

    updateVertreter({ vertreter }) {
      const INDEX = findIndex(this.vertreters, ["pk", vertreter.pk]);
      if (INDEX === -1) {
        return;
      }
      this.vertreters.splice(INDEX, 1, vertreter);
    },

    deleteVertreter({ vertreter }) {
      const INDEX = findIndex(this.vertreters, ["pk", vertreter.pk]);
      if (INDEX === -1) {
        return;
      }
      this.vertreters.splice(INDEX, 1);
    },
  },
};
