import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";

import FinanzierugMixin from "../Mixins/FinanzierugMixin";

import InfoproviderStatuses from "../../../../const/InfoproviderStatuses";
import {
  gettext,
} from "../../../../global/functions/utils";
import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "StandardPauschale",
  components: {
    KFStandard,
  },
  mixins: [
    FinanzierugMixin,
  ],
  data() {
    return {
      allowsInput: true,
    };
  },
  computed: {
    statusNotLoad() {
      return this.kontext !== "zuschuss" && this.kontext !== "zuschuss_vertrag";
    },

    sumLocal() {
      if (this.statusNochNichtErmittelbar) {
        return gettext("_TXT_NOCH_NICHT_ERMITTELBAR_");
      }
      return this.summeCurrency;
    },

    statusNochNichtErmittelbar() {
      return this.infoprovider &&
        this.infoprovider.status &&
        this.infoprovider.status !== InfoproviderStatuses.PROCESSOR_STATUS_OK;
    },

    meldungen() {
      return get(this.infoprovider, `details.${ this.regel.afr_id }.meldungen`, []);
    },

    statusShowProzente() {
      // Im Zuschusskontext werden keine Prozente angezeigt
      if (this.kontext === "zuschuss") {
        return false;
      }
      // Wenn eine Zuschussberechnung durchgeführt werden konnte:
      if (this.infoprovider && this.infoprovider.status === InfoproviderStatuses.PROCESSOR_STATUS_OK) {
        return true;
      }
      // Wenn keine Zuschussberechnung durchgeführt wurde:
      if (this.infoprovider && !this.infoprovider.status) {
        return this.antragFinanzierungsart.afa_prozent !== null;
      }
      return false;
    },
  },
};
