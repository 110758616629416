import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import translate from "../../../../global/directives/translate";
import {
  forEach,
  cloneDeep,
  filter,
  keyBy,
  get,
  isEmpty,
} from "lodash-es";

const SNAPSHOT_CHANGES_LIST_FIELDS_ADRESSE = [
  "o_strasse",
  "o_plz",
  "o_ort",
  "lkz.lkz_bez",
];

const KOMMDATA_FIELDS = [
  "fko_wert",
  "typ.kot_kbez",
];

// @vue/component
export default {
  name: "ZuordnungOrganisation",
  components: {
    SnapshotModule,
    SnapshotIcon,
  },
  directives: {
    translate,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      loading: false,
      antragorgs: undefined,
      organisationen: undefined,
      orgas: undefined,
      objList: undefined,
      orgByPK: {},
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      if (this.loading === false) {
        const OPTIONS = cloneDeep(this.optionsFormstepDetail);
        OPTIONS.list = [
          {
            id: "organisationen",
            htmlId: `${ this.htmlRegelNummer }_organisationen`,
            type: "multiselect",
            required: true,
            label: this.labelOrganisationen,
            translate: true,
            view: "v",
            editonly: true,
            keyLabel: "o_name",
            keyId: "pk",
            menuWidthAll: true,
            data: this.orgas,
            startupFocus: true,
          },
        ];
        return OPTIONS;
      }
      return {};
    },

    labelOrganisationen() {
      if (this.modelParameter.label_text) {
        return this.modelParameter.label_text;
      }
      return "_LBL_GR_ZUORDNUNG_ORGA_ORGAS_";
    },

    txtNoOrganisation() {
      if (this.modelParameter.empty_text) {
        return { text: this.modelParameter.empty_text };
      }
      return { text: "_TXT_NO_ANSWER_" };
    },

    labelHeader() {
      if (this.modelParameter.bez) {
        return this.modelParameter.bez;
      }
      return "_LBL_GR_ZUORDNUNG_ORGA_DEFAULT_FRAGE_";
    },

    statusDiff() {
      return !!(this.snapshotModule && this.snapshotFiltered.status);
    },

    statusNoOrgas() {
      if (isEmpty(this.model.organisationen)) {
        return true;
      }
      return undefined;
    },

    snapshotFiltered() {
      const SNAPSHOT = {
        add: {},
        delete: {},
        changes: {},
        status: false,
      };
      if (this.statusSnapshot) {
        const SNAPSHOT_ORGANISATIONEN = cloneDeep(this.snapshotModule.organisationen) || [];
        forEach(this.model.organisationen, org => {
          const INDEX_IN_SNAPSHOT = SNAPSHOT_ORGANISATIONEN.indexOf(org);
          if (INDEX_IN_SNAPSHOT === -1) {
            SNAPSHOT.add[org] = true;
            SNAPSHOT.status = true;
          } else {
            // Changes
            const CHANGES_DICT = {};
            const ORG = this.orgByPK[org] || {};
            const SNAPSHOT_ORGANISATON_DATA = get(this.snapshotOrgByPk, org, false);
            if (SNAPSHOT_ORGANISATON_DATA && SNAPSHOT_ORGANISATON_DATA.organisation_obj && !isEmpty(ORG)) {
              if (ORG.organisation_obj.o_name !== SNAPSHOT_ORGANISATON_DATA.organisation_obj.o_name) {
                CHANGES_DICT.o_name = true;
                SNAPSHOT.status = true;
              }
              if (this.modelParameter.anzeige_adresse) {
                forEach(SNAPSHOT_CHANGES_LIST_FIELDS_ADRESSE, key => {
                  if (get(ORG.organisation_obj, key) !== get(SNAPSHOT_ORGANISATON_DATA.organisation_obj, key)) {
                    if (key === "lkz.lkz_bez") {
                      CHANGES_DICT.lkz = true;
                    } else {
                      CHANGES_DICT[key] = true;
                    }
                    SNAPSHOT.status = true;
                  }
                });
              }
              if (this.modelParameter.anzeige_kommdaten) {
                const CHANGES_DICT_KEY = "kommunikationsdaten";
                const CHANGES_DICT_KOMMUNIKATIONSDATEN = {};
                const SNAPSHOT_KOMMDATA_BY_TYP_PK = {};
                if (SNAPSHOT_ORGANISATON_DATA.organisation_obj.kommunikationsdaten) {
                  forEach(SNAPSHOT_ORGANISATON_DATA.organisation_obj.kommunikationsdaten, kommdata => {
                    SNAPSHOT_KOMMDATA_BY_TYP_PK[kommdata.typ.kot_id] = kommdata;
                  });
                }
                forEach(this.modelParameter.anzeige_kommdaten, typPK => {
                  const KOMMDATA_OBJ = this.kommdataByTypPk[org] || {};
                  const CURRENT_KOMMDATA_OBJ = KOMMDATA_OBJ[typPK];
                  const CURRENT_SNAPSHOT = SNAPSHOT_KOMMDATA_BY_TYP_PK[typPK];
                  if (!isEmpty(KOMMDATA_OBJ)) {
                    forEach(KOMMDATA_FIELDS, item => {
                      if (get(CURRENT_KOMMDATA_OBJ, item) !== get(CURRENT_SNAPSHOT, item)) {
                        CHANGES_DICT_KOMMUNIKATIONSDATEN[typPK] = true;
                        SNAPSHOT.status = true;
                      }
                    });
                  }
                });
                CHANGES_DICT[CHANGES_DICT_KEY] = CHANGES_DICT_KOMMUNIKATIONSDATEN;
              }
            }
            if (!isEmpty(CHANGES_DICT)) {
              SNAPSHOT.changes[org] = CHANGES_DICT;
            }
            SNAPSHOT_ORGANISATIONEN.splice(INDEX_IN_SNAPSHOT, 1);
          }
        });
        // DELETED
        forEach(SNAPSHOT_ORGANISATIONEN, organisationen => {
          SNAPSHOT.delete[organisationen] = true;
          SNAPSHOT.status = true;
        });
      }
      return SNAPSHOT;
    },


    snapshotOrgByPk() {
      let SNAPSHOT_ORG_BY_PK = {};
      if (this.snapshotModule) {
        SNAPSHOT_ORG_BY_PK = keyBy(this.snapshotModule.foeorgs, "pk");
      }
      return SNAPSHOT_ORG_BY_PK;
    },

    kommdataByTypPk() {
      const KOMMDATA_BY_PK_ORG_TYP_PK = {};
      const ANZEIGE_KOMMDATEN = this.modelParameter.anzeige_kommdaten || [];
      if (!isEmpty(ANZEIGE_KOMMDATEN)) {
        if (this.orgByPK) {
          forEach(this.orgByPK, (item, key) => {
            const KOMMDATA_DICT = {};
            forEach(item.organisation_obj.kommunikationsdaten, kommdata => {
              if (ANZEIGE_KOMMDATEN.indexOf(kommdata.typ.kot_id) > -1) {
                KOMMDATA_DICT[kommdata.typ.kot_id] = kommdata;
              }
            });
            KOMMDATA_BY_PK_ORG_TYP_PK[key] = KOMMDATA_DICT;
          });
        }
      }
      return KOMMDATA_BY_PK_ORG_TYP_PK;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadAstFoeorgs();
    },

    loadAstFoeorgs() {
      const orgs = [];
      this.loading = true;
      this.getListHttp({ url: `foerderantraege/${ this.obj.pk }/foerderantragorganisationen/` }).then(
        response => {
          this.antragorgs = response;
          forEach(response, ao => {
            orgs.push(ao.organisation_obj.pk);
          });
          this.organisationen = orgs;
          this.getListHttp({ url: "info/foerderorganisationen/", urlParams: { typ: this.modelParameter.orgtypes } }).then(
            result => {
              this.orgas = result;
              this.objList = filter(this.orgas, o => this.organisationen.indexOf(o.pk) !== -1).map(o => {
                return { organisation_obj: o };
              });
              this.orgByPK = keyBy(this.objList, "organisation_obj.pk");
            }
          );
        }
      ).finally(
        () => this.loading = false
      );
    },

    snapshotIconType(org) {
      if (this.snapshotFiltered.add[org]) {
        return "add";
      }
      if (this.snapshotFiltered.delete[org]) {
        return "delete";
      }
      return "changes";
    },

    initDataLocal({ statusFirstLoad }) {
      if (statusFirstLoad) {
        return;
      }
      this.loadAstFoeorgs();
    },
  },
};
