"use strict";

import BetragsrechnerRegistry from "dias/kosten_finanzierung/betragsrechner/services";

import RegionaleEffekteMitBegruendungBetragsrechner from "./betragsrechner.js";
import RegionaleEffekteMitBegruendungBetragsrechnerController from "./controller";

const MdmRegionaleEffekteMitBegruendungModul = angular.module("dias.mdm_kosten_finanzierung.betragsrechner.regionale_effekte_mit_begruendung", [
  BetragsrechnerRegistry.name,
]);

MdmRegionaleEffekteMitBegruendungModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("mdm_regionale_effekte_mit_begruendung", RegionaleEffekteMitBegruendungBetragsrechner);
})
  .component("mdmRegionaleEffekteMitBegruendungBetragsrechner", RegionaleEffekteMitBegruendungBetragsrechnerController);

export default MdmRegionaleEffekteMitBegruendungModul;
