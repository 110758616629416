import CalendarModalCreateOrEditEvent from "../CalendarModalCreateOrEditEvent/CalendarModalCreateOrEditEvent.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import NutzerschlagwortBadge from "../../Nutzerschlagwort/NutzerschlagwortBadge/NutzerschlagwortBadge.vue";
import NutzerschlagwortObjektModalAdd from "../../Nutzerschlagwort/NutzerschlagwortObjektModalAdd/NutzerschlagwortObjektModalAdd.vue";
import NutzerschlagwortObjektModalEdit from "../../Nutzerschlagwort/NutzerschlagwortObjektModalEdit/NutzerschlagwortObjektModalEdit.vue";
import NutzerschlagwortObjektModalRemove from "../../Nutzerschlagwort/NutzerschlagwortObjektModalRemove/NutzerschlagwortObjektModalRemove.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import CalendarModalMixin from "../CalendarModalMixin";
import EventActionMixin from "../EventActionMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import getTableApi from "../../../const/TableApis";
import fileDownload from "js-file-download";
import { EventBus } from "../../../global/functions/event-bus";
import { size } from "lodash-es";
import { gettext } from "../../../global/functions/utils";

const TABLE_ID = "CalendarTable";

// @vue/component
export default {
  name: "CalendarList",
  components: {
    CalendarModalCreateOrEditEvent,
    Modal,
    NutzerschlagwortBadge,
    NutzerschlagwortObjektModalAdd,
    NutzerschlagwortObjektModalEdit,
    NutzerschlagwortObjektModalRemove,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    CalendarModalMixin,
    EventActionMixin,
    NotificationMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_TXT_CALENDAR_TABLE_ACTIONS_TERMIN_DOWNLOAD_",
            type: "button",
            callback: ({ item }) => this.downloadMeeting({ meeting: item }),
          },
          {
            isHidden: ({ item }) => !this.isAuthor({ meeting: item }),
            label: "_TXT_CALENDAR_TABLE_ACTIONS_TERMIN_EDIT_",
            type: "button",
            callback: ({ item }) => this.openModalEdit({ meeting: item }),
          },
          {
            isHidden: ({ item }) => !this.isAuthor({ meeting: item }),
            label: "_TXT_CALENDAR_TABLE_ACTIONS_TERMIN_DELETE_",
            type: "button",
            callback: ({ item }) => this.openModalDelete({ meeting: item }),
          },
          {
            label: "_TXT_CALENDAR_TABLE_NUTZERSCHLAGWORTOBJEKT_UPDATE_BTN_",
            callback: this.openModalNutzerschlagwortobjektEdit,
            isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          },
        ],
        actions: [
          {
            permission: "termin.view",
            label: "_TXT_CALENDAR_TABLE_ACTIONS_FILTER_TERMIN_DOWNLOAD_",
            callback: ({ urlParams }) => this.downloadCalendar({ urlParams }),
            type: "button",
            className: "btn btn-primary",
            title: "_TXT_CALENDAR_TABLE_ACTIONS_FILTER_TERMIN_DOWNLOAD_TITLE_",
          },
          {
            permission: "termin.view",
            label: "_TXT_CALENDAR_TABLE_ACTIONS_GO_TO_CALENDAR_",
            route: "root.calendar",
            type: "link",
            className: "btn btn-default",
          },
        ],
        massActions: [],
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      filters: [
        {
          type: "boolean",
          id: "private",
          label: "_LBL_CALENDAR_LIST_SICHTBARKEIT_",
          trueValue: "True",
          falseValue: "False",
          trueLabel: "_LBL_CALENDAR_LIST_MICH_SICHTBAR_",
          falseLabel: "_LBL_CALENDAR_LISTE_ALLE_SICHTBAR_",
          defaultLabel: "_LBL_CALENDAR_LIST_NICHT_AUSGEWAEHLT_",
          group: "_TXT_CALENDAR_LIST_TERMIN_",
        },
        {
          permission: "foerderantrag.view",
          label: "_LBL_CALENDAR_LISTE_FOERDERANTRAG_",
          type: "multiselect",
          id: "foerderantrag_foerderantrag_id",
          url: "foerderantraege/?serializer=minimal",
          searchGlobal: true,
          searchParameter: "a_nr",
          keyId: "pk",
          keyLabel: "a_nr",
          keyLabelCallback: ({ item }) => `${ item.a_nr } ${ item.a_titel ? " - " + item.a_titel : "" }`,
          group: gettext("_TXT_CALENDAR_LIST_OBJEKT_"),
        },
        {
          permission: "auszahlung.view",
          label: "_LBL_CALENDAR_LIST_AUSZAHLUNG_",
          type: "multiselect",
          id: "vertrag_auszahlung_id",
          url: "auszahlungen/",
          keyId: "pk",
          searchGlobal: true,
          searchParameter: "suche",
          keyLabelCallback: ({ item }) => `${ item.az_nummer } - ${ item.vertrag_nr }`,
          group: gettext("_TXT_CALENDAR_LIST_OBJEKT_"),
        },
      ],
      mePk: undefined,
      filteredCalendarObjectTypes: [],
      modal: {
        createOrEdit: false,
        delete: undefined,
        nutzerschlagwortobjektEdit: undefined,
        nutzerschlagwortobjektMassActionAdd: undefined,
        nutzerschlagwortobjektMassActionRemove: undefined,
      },
      confirmOptions: undefined,
      currentEventEdit: undefined,
      currentCalendar: undefined,
      selectedRows: undefined,
    };
  },
  computed: {
    getIconSrc() {
      return tmt_bez => `/api/assets/${ tmt_bez }`;
    },
    nutzerschlagwortModalEditTitleTranslation() {
      return {
        text: "_TXT_CALENDARLIST_NUTZERSCHLAGWORT_MODAL_EDIT_TITLE_"
      };
    },
    nutzerschlagwortModalEditMsgTranslation() {
      return {
        html: "_HTML_CALENDARLIST_NUTZERSCHLAGWORT_MODAL_EDIT_INFO_"
      };
    },
    nutzerschlagwortModalMassActionAddTitleTranslation() {
      return {
        text: "_TXT_CALENDARLIST_NUTZERSCHLAGWORT_MODAL_MANY_ADD_TITLE_"
      };
    },
    nutzerschlagwortModalMassActionAddMsgTranslation() {
      return {
        html: "_HTML_CALENDARLIST_NUTZERSCHLAGWORT_MODAL_MANY_ADD_INFO_{{anz}}_",
        extra: {
          anz: size(this.selectedRows) || 0,
        }
      };
    },
    nutzerschlagwortModalMassActionRemoveTitleTranslation() {
      return {
        text: "_TXT_CALENDARLIST_NUTZERSCHLAGWORT_MODAL_MANY_REMOVE_TITLE_"
      };
    },
    nutzerschlagwortModalMassActionRemoveMsgTranslation() {
      return {
        html: "_HTML_CALENDARLIST_NUTZERSCHLAGWORT_MODAL_MANY_REMOVE_INFO_{{anz}}_",
        extra: {
          anz: size(this.selectedRows) || 0,
        }
      };
    },
  },
  created() {
    this.getMe();
    this.filterCalendarObjectTypes();
    this.getOffentlich();
    this.initRestFunctions();
  },
  methods: {
    initRestFunctions() {
      this.initMassActions();
    },

    initMassActions() {
      if (this.checkPermissionsSync({ perm: "nutzerschlagworte.update" })) {
        this.options.massActions.push({
          label: "_TXT_CALENDAR_TABLE_MASS_ACTIONS_NUTZERSCHLAGWORT_ADD_BTN_",
          callback: ({ rows }) => this.openModalNutzerschlagwortobjektMassActionAdd({ rows }),
          isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          modal: true,
        });
        this.options.massActions.push({
          label: "_TXT_CALENDAR_TABLE_MASS_ACTIONS_NUTZERSCHLAGWORT_REMOVE_BTN_",
          callback: ({ rows }) => this.openModalNutzerschlagwortobjektMassActionRemove({ rows }),
          isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          modal: true,
        });
      }
    },

    downloadCalendar({ urlParams }) {
      this.getHttp({ url: `${ this.getUrl }ics_download_kalender/?${ urlParams }`, urlParams: {}, responseType: "blob", fullResponse: true }).then(
        response => {
          const disposition = response.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i;
            const matches = filenameRegex.exec(disposition);
            if (matches !== null && matches[2]) {
              this.filename = matches[2];
            }
          }
          fileDownload(response.data, this.filename);
        },
        () => {
          this.addNotification({ text: "_MSG_CALENDAR_ICS_EXPORT_ERROR_", type: "error" });
        }
      );
    },

    isAuthor({ meeting }) {
      return meeting && meeting.author ? meeting.author.pk === this.mePk : false;
    },

    closeConfirm({ status }) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.modal.delete = false;
    },

    closeModalCreateOrEdit({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.currentEventEdit = undefined;
      this.modal.createOrEdit = false;
    },

    closeModalNutzerschlagwortobjektEdit({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektEdit = false;
    },

    openModalNutzerschlagwortobjektEdit({ item }) {
      this.currentCalendar = item;
      this.modal.nutzerschlagwortobjektEdit = true;
    },

    closeModalNutzerschlagwortobjektMassActionAdd({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektMassActionAdd = false;
      EventBus.$emit(`cancelMassActions${ this.tableId }`, { status });
    },

    openModalNutzerschlagwortobjektMassActionAdd({ rows }) {
      this.selectedRows = rows;
      this.modal.nutzerschlagwortobjektMassActionAdd = true;
    },

    closeModalNutzerschlagwortobjektMassActionRemove({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektMassActionRemove = false;
      EventBus.$emit(`cancelMassActions${ this.tableId }`, { status });
    },

    openModalNutzerschlagwortobjektMassActionRemove({ rows }) {
      this.selectedRows = rows;
      this.modal.nutzerschlagwortobjektMassActionRemove = true;
    },
  },
};
