"use strict";

import angular from "angular";

import yesNoFilter from "./yesNo.pipe";


const filterModule = angular.module("astorganisationen.pipes", [])

  .filter("yesNo", yesNoFilter);

export default filterModule;
