"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";


const nutzerprofilModule = angular.module("nutzerprofil", [
  uiRouter,
]);

nutzerprofilModule.config($stateProvider => {
  $stateProvider
    .state("root.nutzerprofil", {
      url: "/profil/",
      views: {
        "main@root": {
          template: "<div vueinit><profil-details></profil-details></div>"
        }
      },
    });
});

export default nutzerprofilModule;
