"use strict";

import angular from "angular";
import expandableListDirective from "./expandableList.directive";

const diasExpandableListModule = angular.module("dias.expandableList", [
]);

// Globale Konfigurieruation von AngularStraps bs-datepicker
diasExpandableListModule.directive("expandable", expandableListDirective);

export default diasExpandableListModule;
