"use strict";

class DownloadWithOptionsController {
  /*@ngInject*/
  constructor($scope, $q, DiasNotification, ParameterUtils, SyConfigs, moment, $interval) {
    this.DiasNotification = DiasNotification;
    this.ParameterUtils = ParameterUtils;
    this.SyConfigs = SyConfigs;
    this.errorLabel = {};
    this.options = $scope.extras.options;
    this.source = $scope.extras.source;
    this.apicall = $scope.extras.apicall;
    this.infotext = $scope.extras.infotext;
    this.callparams = $scope.extras.callparams;
    this.saveDocument = $scope.extras.saveDocument;
    this.noconfig = $scope.extras.noconfig;
    this.downloadmode = $scope.extras.downloadmode;
    this.dateiName = null;
    this.prepareDokumentGeneration();

    this.checkOptions = () => {
      const deferred = $q.defer();
      let data = this.ParameterUtils.to_request_body(this.formElements, null, "");
      data.validate_options = true;
      if (this.callparams) {
        data = angular.merge(data, this.callparams);
      }
      this.source.one(this.apicall).get(data).then(
        () => {
          deferred.resolve(this.ParameterUtils.to_request_body(this.formElements, null, ""));
        },
        error => {
          deferred.reject();
          this.onError(error);
        }
      );
      return deferred.promise;
    };

    this.onError = error => {
      this.DiasNotification.form.clear();
      this.DiasNotification.form.error(
        error.data,
        "Bitte überprüfen Sie Ihre Eingaben",
        {},
        this.errorLabel
      );
      this.errors = error.data;
    };


    this.onSuccess = result => {
      this.dateiName = result.name;
      this.gueltigBis = moment(result.cached_until);
      this.calcTimeToInvalid();
      this.toInvalidPromise = $interval(this.calcTimeToInvalid, 1000);
    };

    this.onFailure = err => {
      console.error(err);
      this.DiasNotification.form.clear();
      this.DiasNotification.form.error(err.data, "Fehler beim Erstellen des Dokuments");
    };

    this.calcTimeToInvalid = () => {
      this.dateiGueltig = moment().isBefore(this.gueltigBis);
      this.timeToInvalid = this.gueltigBis.diff(moment(), "minutes");
      if (!this.dateiGueltig && this.toInvalidPromise) {
        $interval.cancel(this.toInvalidPromise);
        this.toInvalidPromise = undefined;
      }
    };
  }

  initFormData() {
    this.formKeys = this.ParameterUtils.get_ordered_keys(this.options);
    this.formElements = this.ParameterUtils.init_parameter_in(this.options);
    angular.forEach(this.options, (value, key) => {
      this.errorLabel[key] = value.bez;
    });
  }

  startDokumentGeneration() {
    this.dateiName = null;
    this.configuration = this.ParameterUtils.to_request_body(this.formElements, null, "");
    this.loading = true;
    const config_options = angular.merge(this.callparams || {}, this.configuration || {});
    this.source.one(this.apicall).get(config_options).then(
      response => {
        this.taskId = response.task_id;
      },
      error => {
        this.onError(error);
      }
    ).finally(() => this.loading = false);
  }

  prepareDokumentGeneration() {
    this.initFormData();
    if (this.downloadmode === "asynchron") {
      this.configTimeToInvalid = this.SyConfigs.wert("cache_time"); // in Seconds
      this.configTimeToInvalid = Math.floor(this.configTimeToInvalid / 60); // in Minutes
      // Wenn es keine Konfigurationsmöglichkeiten gibt, Dokumentengenerierung
      // direkt anfangen:
      if (!this.options || this.noconfig) {
        this.startDokumentGeneration();
      }
    }
  }
}

export default DownloadWithOptionsController;
