"use strict";

import BaseModel from "dias/core/models/baseModel";
import BaseRegelModel from "./baseRegelModel";

class Foerderangebotinstrumentvarianten extends BaseModel {
  constructor() {
    super("foerderinstrumentvarianten", {
      subPaths: { regeln: new BaseRegelModel("instrumentvarianteregeln") }
    });
  }
}

export default Foerderangebotinstrumentvarianten;
