import Modal from "../../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../../global/mixins/HttpMixin";

import {
  cloneDeep,
  size,
} from "lodash-es";
import { gettext } from "../../../../../../global/functions/utils";

import moment from "moment";

// @vue/component
export default {
  name: "RollenModalGueltigkeitszeitraumAnpassen",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    baseUrl: {
      type: String,
      default: undefined,
    },
    rolle: {
      type: Object,
      default: undefined,
    },
    prefix: {
      type: String,
      default: undefined,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        list: [],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  created() {
    this.initModel();
    this.initListForModal();
  },
  methods: {
    initListForModal() {
      this.options.list.push(
        {
          id: "org_name",
          type: "readonly",
          text: this.rolle.org_name,
          label: "_LBL_ROLLEN_LIST_ORGANISATION_",
          view: "v-alt",
        },
        {
          id: "rolle_name",
          type: "readonly",
          text: this.rolle.rolle_name,
          label: "_LBL_ROLLEN_LIST_ROLLE_",
          view: "v-alt",
        },
        {
          id: "gueltigkeit",
          type: "daterange",
          label: gettext("_LBL_ROLLEN_LIST_GUELTIGKEITSZEITRAUM_"),
          view: "v-alt",
          placeholderMin: gettext("_LBL_ROLLEN_LIST_AB_HEUTE_"),
          placeholderMax: gettext("_LBL_ROLLEN_LIST_UNEINGESCHRAENKT_"),
          disabledMin: moment().isAfter(this.model.gueltigkeit.gueltigkeit_after),
        },
      );
    },

    initModel() {
      this.model = {
        gueltigkeit: {
          gueltigkeit_after: this.rolle[`${ this.prefix }_gueltig_ab`],
          gueltigkeit_before: this.rolle[`${ this.prefix }_gueltig_bis`] || undefined,
        },
      };
    },

    save() {
      this.status.loading = true;
      const DATA = {
        [`${ this.prefix }_gueltig_ab`]: this.model.gueltigkeit.gueltigkeit_after,
        [`${ this.prefix }_gueltig_bis`]: this.model.gueltigkeit.gueltigkeit_before,
      };
      this.putHttp({ url: `${ this.baseUrl }${ this.rolle.pk }/`, data: DATA }).then(
        response => {
          this.close({ response });
        },
        error => {
          const GUELTIGKEIT_ERRORS = [];
          if (error.data[`${ this.prefix }_gueltig_ab`]) {
            GUELTIGKEIT_ERRORS.push(error.data[`${ this.prefix }_gueltig_ab`]);
          }
          if (error.data[`${ this.prefix }_gueltig_bis`]) {
            GUELTIGKEIT_ERRORS.push(error.data[`${ this.prefix }_gueltig_bis`]);
          }
          if (size(GUELTIGKEIT_ERRORS) > 0) {
            const ERROR = {
              gueltigkeit: GUELTIGKEIT_ERRORS,
            };
            this.errors = cloneDeep(ERROR);
            this.errorsClone = cloneDeep(ERROR);
          }
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
