"use strict";

import template from "./uebersicht.jade";

class KoFiUebersichtController {
  /*@ngInject*/
  constructor($q, $scope, $filter, Foerderantrag, i18n, Katalog, SyConfigs) {
    this.$q = $q;
    this.$filter = $filter;
    this.gettext = i18n.gettext;
    this.Foerderantrag = Foerderantrag;
    this.Katalog = Katalog;
    this.SyConfigs = SyConfigs;
    this.loading = {};
    this.results = {
      kosten: {},
      finanzierung: {}
    };
    this.landMap = {};
    this.kategorieMap = {};
    this.kostenartdetailMap = {};
    this.finanzierungsartdetailMap = {};
    if (this.antrag) {
      if (this.antrag.a_zuschussbewilligt) {
        this.zuschuss = Number(this.antrag.a_zuschussbewilligt);
      } else if (this.antrag.a_zuschussbeantragt) {
        this.zuschuss = Number(this.antrag.a_zuschussbeantragt);
      } else if (this.antrag.a_zuschusseingereicht) {
        this.zuschuss = Number(this.antrag.a_zuschusseingereicht);
      }
      this.loadKosten();
      this.loadFinanzierung();
    }
  }

  prozente(wert) {
    if (!this.zuschuss) {
      return "-";
    }
    return this.$filter("number")((wert / this.zuschuss * 100), 2);
  }

  loadKosten() {
    this.loading.kosten = true;
    this.Foerderantrag.one(this.antrag.pk).all("kostenarten").customGET("uebersicht").then(
      kosten => {
        this.kosten = kosten.plain();
        this.prepareKosten();
      }
    ).finally(() => this.loading.kosten = false);
  }

  loadFinanzierung() {
    this.loading.finanzierung = true;
    const loadingDataPromises = [];
    const landDeferred = this.$q.defer();
    const kategorieDeferred = this.$q.defer();
    loadingDataPromises.push(landDeferred.promise, kategorieDeferred.promise);

    this.Katalog.getKatalog("klaenderkuerzel").then(
      response => {
        landDeferred.resolve();
        response.forEach(land => {
          this.landMap[land.pk] = land.lkz_bez;
        });
        this.laender = response;
      },
      landDeferred.reject
    );

    this.Katalog.getKatalog("finanzierungsartdetail").then(
      response => {
        kategorieDeferred.resolve();
        response.forEach(kategorie => {
          this.kategorieMap[kategorie.pk] = kategorie.fid_kbez;
        });
      },
      kategorieDeferred.reject
    );


    this.$q.all(loadingDataPromises).then(
      () => {
        this.Foerderantrag.one(this.antrag.pk).all("finanzierungsarten").customGET("uebersicht").then(
          finanzierung => {
            this.finanzierung = finanzierung.plain();
            this.prepareFinanzierung();
          }
        ).finally(() => this.loading.finanzierung = false);
      }
    );
  }

  prepareKosten() {
    this.kosten.sort((a, b) => a.antragregel_pos - b.antragregel_pos);
    this.results.kosten = {
      gesamt: 0,
      part_de: 0,
      part_de_mtl: 0,
      part_de_sn: 0,
      part_de_st: 0,
      part_de_th: 0
    };
    this.kosten.forEach(ko => {
      this.results[ko.pk] = {
        gesamt: 0,
        part_de: 0,
        part_de_mtl: 0,
        part_de_sn: 0,
        part_de_st: 0,
        part_de_th: 0
      };
      ko.ohne_positionen = false;
      if (ko.antragregel_argument.ohne_positionen) {
        ko.ohne_positionen = ko.antragregel_argument.ohne_positionen.wert;
      }
      ko.pos.forEach(pos => {
        this.results[ko.pk].gesamt += Number(pos.akp_wert) || 0;
        this.results[ko.pk].part_de += Number(pos.akp_argument.part_de) || 0;
        this.results[ko.pk].part_de_mtl += Number(pos.akp_argument.part_de_mtl) || 0;
        this.results[ko.pk].part_de_sn += Number(pos.akp_argument.part_de_sn) || 0;
        this.results[ko.pk].part_de_st += Number(pos.akp_argument.part_de_st) || 0;
        this.results[ko.pk].part_de_th += Number(pos.akp_argument.part_de_th) || 0;

        this.kostenartdetailMap[pos.kostenartdetail] = pos;
      });
      this.results.kosten.gesamt += this.results[ko.pk].gesamt;
      this.results.kosten.part_de += this.results[ko.pk].part_de;
      this.results.kosten.part_de_mtl += this.results[ko.pk].part_de_mtl;
      this.results.kosten.part_de_sn += this.results[ko.pk].part_de_sn;
      this.results.kosten.part_de_st += this.results[ko.pk].part_de_st;
      this.results.kosten.part_de_th += this.results[ko.pk].part_de_th;
    });
  }

  finProzente(wert) {
    if (!this.results.finanzierung.gesamt) {
      return "-";
    }
    return this.$filter("number")((wert / this.results.finanzierung.gesamt * 100), 2);
  }

  kostenProzente(wert) {
    if (!this.results.kosten.gesamt) {
      return "-";
    }
    return this.$filter("number")((wert / this.results.kosten.gesamt * 100), 2);
  }

  getLand(afp) {
    return (afp.argument || {}).land;
  }

  getKategorie(afp) {
    return (afp.argument || {}).kategorie;
  }

  getBezeichnungOhneKategorie(bez, kategorielabel) {
    return bez.replace(kategorielabel + ": ", "");
  }

  getRechner(afa) {
    if ((afa.antragregel_argument || {}).betragsrechner) {
      return afa.antragregel_argument.betragsrechner.wert;
    }
  }

  prepareFinanzierung() {
    const conf = this.SyConfigs.wert("regeln_kostenvalidierung") || {};
    const de_finanz = conf.deutsche_finanzierungen || [];
    const int_finanz = conf.internationale_finanzierungen || [];
    this.finanzierung.sort((a, b) => a.antragregel_pos - b.antragregel_pos);
    this.results.finanzierung = {
      gesamt: 0,
      prozent: 0,
      ohneland: { gesamt: 0,
                  finanzierungsarten: [] },
      mitland: {},
      de: {
        gesamt: 0,
        prozent: 0,
        land: {}
      },
      int: {
        gesamt: 0,
        prozent: 0,
        land: {}
      }
    };

    this.finanzierung.forEach(fi => {
      this.results.finanzierung.gesamt += Number(fi.afa_summe) || 0;
      this.results.finanzierung.prozent += Number(fi.afa_prozent) || 0;
      const rechner = this.getRechner(fi);
      const relevante_rechner = ["finanzierung_detailliert_betragsrechner", "finanzierung_detailliert_national_betragsrechner"];
      const isDe = de_finanz.indexOf(fi.regel_id) !== -1;
      const isInt = int_finanz.indexOf(fi.regel_id) !== -1;
      let prefix = "";
      if (isDe) {
        this.results.finanzierung.de.gesamt += Number(fi.afa_summe) || 0;
        this.results.finanzierung.de.prozent += Number(fi.afa_prozent) || 0;
        prefix = "de";
      }
      if (isInt) {
        this.results.finanzierung.int.gesamt += Number(fi.afa_summe) || 0;
        this.results.finanzierung.int.prozent += Number(fi.afa_prozent) || 0;
        prefix = "int";
      }
      if (relevante_rechner.indexOf(rechner) > -1) {
        fi.pos.forEach(pos => {
          const land = this.getLand(pos);
          const kategorie = this.getKategorie(pos);
          this.results.finanzierung[prefix].land[land] = this.results.finanzierung[prefix].land[land] || { gesamt: 0,
                                                                                                           finanzierungsarten: {},
                                                                                                           landlabel: this.landMap[land] };
          this.results.finanzierung[prefix].land[land].finanzierungsarten[fi.finanzierungsart.fin_id] =
            this.results.finanzierung[prefix].land[land].finanzierungsarten[fi.finanzierungsart.fin_id] || { pos: [],
                                                                                                             finlabel: fi.finanzierungsart.fin_bez,
                                                                                                             prozent: 0,
                                                                                                             gesamt: 0 };
          pos.kategorielabel = this.kategorieMap[kategorie];
          pos.restbezeichnung = this.getBezeichnungOhneKategorie(pos.afp_bez, pos.kategorielabel);
          this.results.finanzierung[prefix].land[land].finanzierungsarten[fi.finanzierungsart.fin_id].pos.push(pos);
          this.results.finanzierung[prefix].land[land].gesamt += Number(pos.afp_wert) || 0;
          this.results.finanzierung[prefix].land[land].finanzierungsarten[fi.finanzierungsart.fin_id].gesamt += Number(pos.afp_wert) || 0;
        });
      } else {
        this.results.finanzierung.ohneland.finanzierungsarten.push(fi);
        this.results.finanzierung.ohneland.gesamt += Number(fi.afa_summe) || 0;
      }
    });
  }
}


export default {
  template: template(),
  controller: KoFiUebersichtController,
  controllerAs: "vm",
  bindings: {
    antrag: "<"
  }
};
