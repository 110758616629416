"use strict";

import BaseController from "../config.base.controller";

class ParametermoduleChoiceController extends BaseController {
  /*@ngInject*/
  constructor($scope, Katalog, DiasNotification, Restangular) {
    super();
    this.Restangular = Restangular;
    this.groups = [];
    this.choices = [];
    this.grouped = false;
    this.modelSearch = "";
    this.optionsGlobalSearch = {};
    this.initGlobalSearch();
    if (angular.isUndefined(this.parameter[this.key].wert)) {
      this.parameter[this.key].wert = null;
    }
    if (this.parameter[this.key].katalog) {
      Katalog.getKatalog(this.parameter[this.key].katalog, this.parameter[this.key].katalog_filter).then(
        result => {
          this.setChoices(result.plain());
          this.groupChoices(this.choices);
          this.ensureEmptyResultIfNotRequired(this.choices);
        },
        error => {
          DiasNotification.form.error(error.data.detail, "Katalog nicht gefunden.");
        }
      );
    } else if (this.parameter[this.key].choices) {
      $scope.$watch(() => this.parameter[this.key].choices, () => this.prepareLocalChoices());
    }
    this.get_displayvalue = function(id) {
      let x = "";
      if (!id) {
        return x;
      }
      const el = _.find(this.choices, c => {
        if (this.parameter[this.key].globalSearch) {
          return String(c[this.optionsGlobalSearch.keyId]) === String(id);
        }
        return String(c.id) === String(id);
      });
      if (el) {
        if (this.parameter[this.key].globalSearch) {
          x = String(el[this.optionsGlobalSearch.keyLabel]);
        } else {
          x = el.bez;
        }
      }
      return x;
    };
  }

  prepareLocalChoices() {
    const choices = this.parameter[this.key].choices.map(v => {
      if (angular.isArray(v)) {
        return { id: v[0], bez: v[1], group: v[2] };
      }
      return v;
    });
    this.setChoices(choices);
    this.groupChoices(this.choices);
    this.ensureEmptyResultIfNotRequired(this.choices);
  }

  setChoices(choices) {
    this.choices = choices;
  }

  initGlobalSearch() {
    if (!this.parameter[this.key].globalSearch) {
      return;
    }
    this.optionsGlobalSearch = this.parameter[this.key].globalSearch;
    this.optionsGlobalSearch.searchParameter = this.optionsGlobalSearch.searchParameter || "name";
    this.optionsGlobalSearch.keyId = this.optionsGlobalSearch.keyId || "id";
    this.optionsGlobalSearch.keyLabel = this.optionsGlobalSearch.keyLabel || "bez";
    this.optionsGlobalSearch.urlParams = this.optionsGlobalSearch.urlParams || {};
    this.loadExistingValue();
  }

  loadExistingValue() {
    const CURRENT_MODEL = this.parameter[this.key].wert;
    if (!CURRENT_MODEL || !_.isString(CURRENT_MODEL)) {
      return [];
    }
    this.loadingList = true;
    this.Restangular.one(this.optionsGlobalSearch.url).one(CURRENT_MODEL).customGET(undefined, this.optionsGlobalSearch.urlParams).then(
      response => {
        this.choices = [response];
      }
    ).then(
      () => this.loadingList = false
    );
  }

  groupChoices(choices) {
    const groups = {};
    this.groups = [];
    choices.forEach(v => {
      if (angular.isArray(groups[v.group])) {
        groups[v.group].push(v);
      } else {
        groups[v.group] = [v];
      }
    });
    Object.keys(groups).sort().forEach(key => {
      this.groups.push({ label: key, choices: groups[key] });
    });
    this.grouped = this.groups.length > 1;
  }

  ensureEmptyResultIfNotRequired() {
    this.showEmpty = this.parameter[this.key].options && !this.parameter[this.key].options.required;
  }

  onChange() {
    if (this.change) {
      this.change();
    }
  }

  searchObj() {
    this.loadingList = true;
    const term = this.modelSearch;
    if (term === "") {
      // Suche zurücksetzen.
      this.choices = [];
      this.loadingList = false;
      return;
    } else if (term.length < 3) {
      this.loadingList = false;
      return;
    }
    if (this.parameter[this.key].globalSearch) {
      const URL_PARAMS = this.optionsGlobalSearch.urlParams;
      URL_PARAMS[this.optionsGlobalSearch.searchParameter] = term;
      this.Restangular.one(this.optionsGlobalSearch.url).getList(undefined, URL_PARAMS).then(
        response => {
          this.existingValueList = this.checkExistingValue();
          this.choices = _.concat(this.existingValueList, response);
        }
      ).then(
        () => this.loadingList = false
      );
    }
  }

  checkExistingValue() {
    const CURRENT_MODEL = this.parameter[this.key].wert;
    if (!CURRENT_MODEL) {
      return [];
    }
    const CURRENT_MODEL_OBJECT = _.find(this.choices, [this.optionsGlobalSearch.keyId, CURRENT_MODEL]);
    return CURRENT_MODEL_OBJECT ? [CURRENT_MODEL_OBJECT] : [];
  }
}

export default ParametermoduleChoiceController;
