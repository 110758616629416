"use strict";

import BaseModel from "dias/core/models/baseModel";

class Foerderorgnutzer extends BaseModel {
  constructor() {
    super("foerderorgnutzer");
  }
}

export default Foerderorgnutzer;
