export default {
  list: [
    {
      id: "text",
      type: "text",
      label: "_LBL_FOERDERLANDKARTE_FILTER_RIGHT_OPTIONS_SUCHBEGRIFF_",
      view: "v",
    },
    {
      id: "plz",
      type: "text",
      label: "_LBL_FOERDERLANDKARTE_FILTER_RIGHT_OPTIONS_PLZ_ORT_",
      view: "v",
    },
    {
      id: "template",
      type: "template",
      slot: "template",
    },
    {
      id: "umkreis",
      type: "range",
      view: "v-alt",
      scale: true,
      min: 0,
      max: 10,
      disabled: true,
    },
  ],
};
