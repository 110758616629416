// @vue/component
export default {
  name: "WFStatusGruppenIcon",
  props: {
    position: {
      type: Number,
      default: undefined,
    },
    iconClass: {
      type: String,
      default: undefined,
    },
    bezeichnung: {
      type: String,
      default: undefined,
    },
    active: {
      type: Boolean,
      required: true,
    },
    onlyLine: {
      type: Boolean,
    },
  },
};
