import DokumentDownload from "../../../Dokumente/DokumentDownload/DokumentDownload.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import {
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "TemplateAdminVorlage",
  components: {
    DokumentDownload,
    FormstepDetail,
    Modal,
    PuxButton,
    PuxTranslate,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "updateTemplate",
  ],
  setup() {
    const {
      addNotification,
      deleteHttp,
      putHttp,
    } = HttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      addNotification,
      checkPermissionsSync,
      deleteHttp,
      putHttp,
    };
  },
  data() {
    return {
      model: {
        tpl_vorlage: {},
      },
      htmlId: "vorlage",
      confirmRemoveVorlageOptions: undefined,
      confirmReplaceVorlageOptions: undefined,
      modal: {
        confirmRemoveVorlage: undefined,
        confirmUpdateVorlage: undefined,
      },
    };
  },
  computed: {
    canUpdateVorlage() {
      return this.checkPermissionsSync({ perm: "templateadmin.content.update" });
    },

    formstepOptions() {
      return {
        required: true,
        list: [
          {
            id: "tpl_vorlage",
            type: "document",
            label: "_LBL_TEMPLATEADMIN_VORLAGE_AUSWAEHLEN_MODAL_DOKUMENT_",
            multiple: false,
            drop: true,
            view: "v-alt",
            required: true,
            allowedMimeType: [
              this.template.vorlage_mimetype
            ],
          }
        ],
        deleteCallback: this.confirmRemoveVorlage,
        editablePermission: "templateadmin.content.update",
        saveCallback: this.save,
      };
    },

    urlVorlage() {
      return `templates/${ this.template.tpl_id }/vorlage/`;
    },

    urlRemoveVorlage() {
      return `templates/${ this.template.pk }/vorlage_entfernen/`;
    },

    urlUpdateVorlage() {
      return `templates/${ this.template.pk }/vorlage_aendern/`;
    },

    idForButtonUploadVorlage() {
      return `${ this.htmlId }_upload_vorlage`;
    },

    textForButtonUploadVorlage() {
      return this.template.has_vorlage ?
        "_LBL_TEMPLATEADMIN_VORLAGE_REPLACE_" :
        "_LBL_TEMPLATEADMIN_VORLAGE_ADD_";
    },
  },
  methods: {
    closeModalRemoveVorlage() {
      this.modal.confirmRemoveVorlage = false;
    },

    removeVorlage() {
      this.deleteHttp({
        url: this.urlRemoveVorlage,
      }).then(
        response => {
          this.addNotification({ text: "_TXT_TEMPLATEADMIN_REMOVE_VORLAGE_SUCCESS_" });
          this.closeModalRemoveVorlage();
          this.$emit("updateTemplate", { template: response });
        }
      );
    },

    confirmRemoveVorlage() {
      this.confirmRemoveVorlageOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_TEMPLATEADMIN_REMOVE_VORLAGE_MODAL_HEADER_",
        msg: "_TXT_TEMPLATEADMIN_REMOVE_VORLAGE_MODAL_{{bez}}_",
        extra: { bez: this.template.tpl_kbez },
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: this.removeVorlage,
        cancelCallback: this.closeModalRemoveVorlage,
        loading: false,
      };
      this.modal.confirmRemoveVorlage = true;
    },

    closeModalConfirmUpdateVorlage(resolve) {
      if (isFunction(resolve)) {
        resolve();
      }
      this.modal.confirmUpdateVorlage = false;
    },

    save({ model }) {
      if (this.template.has_vorlage) {
        return new Promise(resolve => {
          this.confirmUpdateVorlageOptions = {
            okClass: "btn btn-primary",
            title: "_TXT_TEMPLATEADMIN_UPDATE_VORLAGE_MODAL_HEADER_",
            msg: "_TXT_TEMPLATEADMIN_UPDATE_VORLAGE_MODAL_{{bez}}_",
            extra: { bez: this.template.tpl_kbez },
            okLabel: "_BTN_SAVE_",
            cancelLabel: "_BTN_CANCEL_",
            okCallback: () => this.uploadVorlage({ model, resolve }),
            cancelCallback: () => this.closeModalConfirmUpdateVorlage(resolve),
            loading: false,
          };
          this.modal.confirmUpdateVorlage = true;
        });
      }
      return this.uploadVorlage({ model });
    },

    setDataForSave({ model }) {
      const FORM_DATA = new FormData();
      FORM_DATA.append("tpl_vorlage", model.tpl_vorlage.file);
      return FORM_DATA;
    },

    uploadVorlage({ model, resolve }) {
      return this.putHttp({
        url: this.urlUpdateVorlage,
        data: this.setDataForSave({ model })
      }).then(
        response => {
          this.addNotification({ text: "_TXT_TEMPLATEADMIN_VORLAGE_UPDATE_SUCCESS_" });
          this.$emit("updateTemplate", { template: response });
          this.closeModalConfirmUpdateVorlage(resolve);
        }
      );
    },
  },
};
