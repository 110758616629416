"use strict";

import BaseModel from "dias/core/models/baseModel";

class Foerderfinderkategorietypen extends BaseModel {
  constructor() {
    super("foerderfinderkategorietypen");
  }
}

export default Foerderfinderkategorietypen;
