import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

import {
  forEach,
  trim,
} from "lodash-es";

// @vue/component
export default {
  name: "WorkflowFolgenButton",
  components: {
    PuxButton,
  },
  props: {
    folge: {
      type: Object,
      required: true,
    },
    folgeIndex: {
      type: Number,
      required: true,
    },
    testClass: {
      type: String,
      required: false,
      default: undefined,
    },
    typeClass: {
      type: String,
      required: false,
      default: undefined,
    },
    isFolgeInDropdown: {
      type: Boolean,
      required: true,
    },
    isIconVisible: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  emits: [
    "onWorkflowfolge",
  ],
  computed: {
    htmlId() {
      return `wf_button_${ this.folgeIndexLocal }`;
    },

    folgeIndexLocal() {
      return this.isFolgeInDropdown ?
        this.folgeIndex + 1 :
        this.folgeIndex;
    },

    testClassLocal() {
      if (this.testClass) {
        return this.testClass;
      }
      return `test_${ trim(this.folge.wfo_kbez) }`;
    },

    typeClassLocal() {
      if (this.isFolgeInDropdown) {
        return "dropdown";
      }
      if (this.typeClass) {
        return this.typeClass;
      }
      return this.folge.wfo_anzeige_typ || "primary";
    },

    buttonDisabled() {
      return this.folge.status !== 0 || this.loading || this.disabled;
    },

    buttonText() {
      return this.folge.wfo_kbez;
    },

    buttonTitle() {
      if (this.folge.status === 0 ||
        !this.folge.status_messages ||
        !this.folge.status_messages.length) {
        return;
      }
      let title = "";
      forEach(this.folge.status_messages, (msg, index) => {
        title += `${ index !== 0 ? " " : "" }${ msg }`;
      });
      return title;
    },

    buttonIcon() {
      if (this.isIconVisible) {
        return this.folge.wfo_anzeige_icon;
      }
      return "";
    },

    buttonClassLocal() {
      if (this.isFolgeInDropdown) {
        return "ml-0 p-0";
      }
      return "";
    },
  },
  methods: {
    onWorkflowfolgeLocal() {
      this.$emit("onWorkflowfolge", { folge: this.folge, buttonId: this.htmlId });
    },
  },
};
