"use strict";

import KostenController from "../../kosten.controller";

class AbhaengigeKostenpauschaleController extends KostenController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KKostenart, BetragsrechnerRegistry, i18n) {
    super($scope, $q, Foerdermodule, Foerderantrag, KKostenart, BetragsrechnerRegistry, i18n);
    this.name = "AbhaengigeKostenpauschaleController";
    this.allowsInput = true;
  }

  getBetrag() {
    // Im Zweifelsfalle immer den Wert aus der aktuellen Berechnung
    // zurückgeben:
    if (this.infoprovider.details
        && this.infoprovider.details[this.modul.regel.afr_id]
        && this.infoprovider.gespeichert) {
      return this.infoprovider.details[this.modul.regel.afr_id].wert;
    }
    return this.antragKoFiArt.summe;
  }

  getPosBezeichnung(pos) {
    // Im Zweifelsfalle immer die Bezeichnung aus der aktuellen
    // Berechnung zurückgeben:
    if (this.infoprovider.details
        && this.infoprovider.details[this.modul.regel.afr_id]
        && this.infoprovider.gespeichert) {
      return this.infoprovider.details[this.modul.regel.afr_id].bezeichnung;
    }
    return pos.akp_bez;
  }

  getErrors() {
    if (this.infoprovider.details
        && this.infoprovider.details[this.modul.regel.afr_id]
        && this.infoprovider.details[this.modul.regel.afr_id].errors) {
      return this.infoprovider.details[this.modul.regel.afr_id].errors.wert;
    }
  }

  getDefaultBetragsrechner() {
    return "abhaengige_kostenpauschale_rechner";
  }
}

export default AbhaengigeKostenpauschaleController;
