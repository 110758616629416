import AsideComponents from "../../BerichtFoerderantrag/AsideComponents";
import AsideRightBox from "../../../../../global/components/AsideRightBox/AsideRightBox.vue";
import ContextPanel from "../../../../../global/components/ContextPanel/ContextPanel.vue";
import PanelComponents from "./PanelComponents";
import PuxPageDetails from "../../../../../global/components/PuxPageDetails/PuxPageDetails.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";

import moment from "moment";
import {
  setUrlWithParams,
} from "../../../../../global/functions/help";
import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "BerichtHandlungsfelder",
  components: {
    AsideRightBox,
    ContextPanel,
    PuxPageDetails,
    ...PanelComponents,
    ...AsideComponents,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingAntraege: undefined,
      loadingKategorie: true,
      gesamt: {},
      labels: [],
      dataGeld: [],
      dataAnzahl: [],
      filteredAntragListUrl: {},
      defaultModel: {
        a_bewilligungsdatum_after: moment().subtract(1, "month").startOf("day").format("YYYY-MM-DD"),
      },

      fkws: undefined,
      initialHandlungsfelder: undefined,
      handlungsfelder: [],
      panelUebersicht: {
        label: "_TXT_BERICHT_ANTRAG_DETAILS_ANTRAGSUEBERSICHT_PANEL_NAME_",
        icon: "glyphicon-list",
        active: true,
      },
      panelGrafik: {
        label: "_TXT_BERICHT_ANTRAG_DETAILS_ANTRAGSUEBERSICHT_GRAFIK_PANEL_NAME_",
        icon: "glyphicon-list",
        active: true,
      },
      asideComponents: [
        {
          name: "BerichtFoerderantragAsideFilters",
          pk: "BerichtFoerderantragAsideFilters",
          options: {
            name: "Filter",
            icon: "glyphicon-filter",
            active: true,
          },
        },
      ],
    };
  },
  created() {
    this.loadKategorie();
  },
  methods: {
    loadKategorie() {
      this.getHttp({
        url: "katalog/?kategorie=2e0d8c86-c4f6-5631-8b96-9f0e2ff5819c&key=foerderfinderkategoriewerte",
      }).then(
        response => {
          this.fkws = response;
          this.initialHandlungsfelder = this.getInitialHandlungsfelder();
          this.loadingKategorie = false;
        }
      );
    },

    getInitialHandlungsfelder() {
      return this.fkws.map(fkw => {
        return {
          handlungsfeld: fkw.fkw_bez,
          sum_beantragt: 0,
          sum_bewilligt: 0,
          num_antraege: 0,
          num_antraege_bewilligt: 0,
          pk: fkw.pk,
        };
      });
    },

    updateAntraege({ model, linkParameters } = {}) {
      this.loadingAntraege = true;
      this.getHttp({
        url: "berichte/handlungsfelder/",
        urlParams: model,
      }).then(
        response => {
          this.setHandlungsfelder({ response });
          this.prepareData({ linkParameters });
          this.rerenderChart();
          this.loadingAntraege = false;
        }
      );
    },

    setHandlungsfelder({ response }) {
      const HANDLUNGSFELDER = cloneDeep(this.initialHandlungsfelder);
      forEach(response, r => {
        const h = HANDLUNGSFELDER.find(v => v.handlungsfeld === r.handlungsfeld);
        if (h) {
          Object.assign(h, r);
        } else {
          HANDLUNGSFELDER.push(r);
        }
      });
      this.handlungsfelder = HANDLUNGSFELDER;
    },

    prepareData({ linkParameters }) {
      const GESAMT = {
        beantragt: 0,
        bewilligt: 0,
        countAntraege: 0
      };
      this.labels = [];
      this.dataGeld = [[], []];
      this.dataAnzahl = [[], []];
      this.filteredAntragListUrl = {};

      const URL = setUrlWithParams({ url: "foerderantrag/", params: linkParameters });
      const FILTERED_ANTRAG_LIST_URL = {
        gesamt: URL,
      };

      forEach(this.fkws, fkw => {
        FILTERED_ANTRAG_LIST_URL[fkw.pk] = setUrlWithParams({ url: URL, params: { ffk_2e0d8c86c4f656318b969f0e2ff5819c: fkw.pk } });
      });
      forEach(this.handlungsfelder, handlungsfeld => {
        this.labels.push(handlungsfeld.handlungsfeld);
        this.dataGeld[0].push(handlungsfeld.sum_beantragt);
        this.dataGeld[1].push(handlungsfeld.sum_bewilligt);
        this.dataAnzahl[0].push(handlungsfeld.num_antraege);
        this.dataAnzahl[1].push(handlungsfeld.num_antraege_bewilligt);
        GESAMT.beantragt += +handlungsfeld.sum_beantragt;
        GESAMT.bewilligt += +handlungsfeld.sum_bewilligt;
        GESAMT.countAntraege += +handlungsfeld.num_antraege;
      });
      this.filteredAntragListUrl = FILTERED_ANTRAG_LIST_URL;
      this.gesamt = GESAMT;
    },

    rerenderChart() {
      const CHART_REF = this.$refs.chart;
      if (!CHART_REF) {
        return;
      }
      const DATA_COUNT_ANTRAEGE = this.prepareDataChartCountAntraege();
      const DATA_COUNT_BETRAEGE = this.prepareDataChartCountBetraege();
      CHART_REF.rerenderCharts_fromParent({
        dataCountAntraege: DATA_COUNT_ANTRAEGE,
        dataCountBetraege: DATA_COUNT_BETRAEGE,
      });
    },

    prepareDataChartCountAntraege() {
      if (!this.labels.length) {
        return;
      }
      const DATA = {
        colors: ["#a0c648", "#f39600"],
        chart: {
          height: this.getHighchartHeight({ labels: this.labels }),
          type: "bar",
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: this.labels,
          title: {
            text: null
          },
          labels: {
            style: {
              fontSize: "14px",
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: this.$gettext("_TXT_BERICHT_HANDLUNGSFELDER_DETAILS_ANZAHL_"),
            align: "high",
          },
          labels: {
            overflow: "justify",
            style: {
              fontSize: "14px",
            }
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "14px",
              },
            },
          }
        },
        tooltip: {
          style: {
            fontSize: "14px",
          }
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          backgroundColor: "#FFFFFF",
          shadow: true,
          style: {
            fontSize: "14px",
          },
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: this.$gettext("_TXT_BERICHT_HANDLUNGSFELDER_DETAILS_ANZAHL_BEANTRAGT_"),
            data: this.dataAnzahl[0],
          },
          {
            name: this.$gettext("_TXT_BERICHT_HANDLUNGSFELDER_DETAILS_ANZAHL_BEWILLIGT_"),
            data: this.dataAnzahl[1],
          },
        ]
      };
      return DATA;
    },

    prepareDataChartCountBetraege() {
      if (!this.labels.length) {
        return;
      }
      const DATA = {
        colors: ["#a0c648", "#f39600"],
        chart: {
          height: this.getHighchartHeight({ labels: this.labels }),
          type: "bar",
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: this.labels,
          title: {
            text: null
          },
          labels: {
            style: {
              fontSize: "14px",
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: this.$gettext("_TXT_BERICHT_HANDLUNGSFELDER_DETAILS_SUMME_"),
            align: "high",
          },
          labels: {
            overflow: "justify",
            format: "{value:,.0f} €",
            style: {
              fontSize: "14px",
            }
          }
        },
        tooltip: {
          valueSuffix: " €",
          style: {
            fontSize: "14px",
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              format: "{point.y:,.2f} €",
              style: {
                fontSize: "14px",
              }
            }
          },
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          backgroundColor: "#FFFFFF",
          shadow: true,
          style: {
            fontSize: "14px",
          },
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: this.$gettext("_TXT_BERICHT_HANDLUNGSFELDER_DETAILS_SUMME_BEANTRAGT_"),
            data: this.dataGeld[0],
          },
          {
            name: this.$gettext("_TXT_BERICHT_HANDLUNGSFELDER_DETAILS_SUMME_BEWILLIGT_"),
            data: this.dataGeld[1],
          },
        ]
      };
      return DATA;
    },

    getHighchartHeight({ labels }) {
      return (labels.length * 40) + 150;
    },
  },
};
