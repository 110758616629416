"use strict";
import angular from "angular";

import DiasNotification from "dias/core/notification";

import Niederschrift from "./niederschrift";
import MuendlicherBericht from "./muendlicher_bericht";
import Beratungsvorlage from "./beratungsvorlage";
import ManuelleAntragsliste from "./manuelle_antragsliste";
import AutomatischeAntragsliste from "./automatische_antragsliste";
import AutomatischeAntragslisteMittellos from "./automatische_antragsliste_mittellos";
import AntragslistenUebersicht from "./antragslisten_uebersicht";
import TopProtokoll from "./top_protokoll";

const modul = angular.module("standard_sitzungsmodule", [
  DiasNotification.name,
  Niederschrift.name,
  MuendlicherBericht.name,
  Beratungsvorlage.name,
  ManuelleAntragsliste.name,
  AutomatischeAntragsliste.name,
  AutomatischeAntragslisteMittellos.name,
  AntragslistenUebersicht.name,
  TopProtokoll.name,
]);


export default modul;
