"use strict";

class GutachtenCreateController {
  /*@ngInject*/
  constructor($q, $scope, ParameterUtils, DiasNotification, i18n, AuthService, Katalog, moment) {
    this.$scope = $scope;
    this.$q = $q;
    this.DiasNotification = DiasNotification;
    this.ParameterUtils = ParameterUtils;
    this.gettext = i18n.gettext;
    this.refObj = $scope.extras.obj;
    this.typId = $scope.extras.typ ? $scope.extras.typ.pk : undefined;
    this.AuthService = AuthService;
    this.Katalog = Katalog;
    this.moment = moment;
    this.init();
    this.loadGutachtenvorlage();
    this.save = () => this.create();
  }

  init() {
    this.formModel = this.ParameterUtils.init_parameter_in({
      ga_kbez: {
        bez: "Bezeichnung",
        typ: "string",
        prio: 1,
        options: {
          required: true
        },
      },
      ga_bez: {
        bez: "Beschreibung",
        typ: "richtext",
        prio: 2,
        options: {
          required: false
        }
      },
      ga_termin: {
        bez: this.gettext("Zieltermin"),
        typ: "date",
        prio: 3,
        options: {
          required: true
        }
      },
      nutzer: {
        bez: this.gettext("GutachterIn"),
        typ: "single_choice",
        prio: 4,
        katalog: "gutachter",
        localSearch: true,
        options: {
          required: true,
        }
      },
      pruefer: {
        bez: this.gettext("PrüferIn"),
        typ: "single_choice",
        prio: 5,
        katalog: "gutachten_pruefer",
        options: {
          required: false,
        }
      },
      vorlage: {
        bez: "Vorlage",
        typ: "single_choice",
        prio: 6,
        katalog: "gutachtenvorlagen",
        katalog_filter: { typ: this.typId, regelsatz: this.refObj.regelsatz },
        options: {
          required: true,
        }
      },
    });
    this.form_keys = this.ParameterUtils.get_ordered_keys(this.formModel);
    this.errorLabels = {};
    this.AuthService.getUser().then(user => {
      if (this.AuthService.syncHasPerm("gutachten.pruefen")) {
        this.formModel.pruefer.wert = user.pk;
      }
      if (typeof this.refObj.a_titel !== "undefined") {
        this.formModel.ga_kbez.wert = this.refObj.a_titel;
      }
    });
    this.form_keys.forEach(
      k => {
        this.errorLabels[k] = `${ this.formModel[k].bez }: `;
      }
    );
  }

  loadGutachtenvorlage() {
    this.Katalog.getKatalog("gutachtenvorlagen", this.formModel.vorlage.katalog_filter).then(
      response => {
        if (response.length === 1) {
          this.gutachtenvorlage = response.plain()[0];
          this.formModel.vorlage.wert = this.gutachtenvorlage.pk;
        }
      }
    );
  }

  create() {
    if (this.formModel.ga_termin.wert) {
      const TERMIN = this.moment(this.formModel.ga_termin.wert).format("DD.MM.YYYY");
      this.formModel.ga_kbez.wert = this.formModel.ga_kbez.wert.concat("  (Abgabe: ", TERMIN.toString(), ")");
    }
    const data = this.ParameterUtils.to_request_body(this.formModel, null, "");
    const promise = this.refObj.customPOST(data, "gutachten").then(
      () => {
        this.DiasNotification.page.success(`${ this.gettext("Gutachten") } erfolgreich erstellt.`);
      },
      errors => {
        return this.onErrors(errors, this.errorLabels);
      }
    );
    return promise;
  }

  onErrors(errors, labelErrors) {
    this.DiasNotification.form.clear();
    this.DiasNotification.form.error(errors.data, "Ihre Daten sind fehlerhaft oder unvollständig.", { container: "#gutachten-error-box" }, labelErrors);
    if (errors.data) {
      this.errors = errors.data;
    } else {
      this.errors = errors;
    }
    return this.$q.reject(errors);
  }
}

export default GutachtenCreateController;
