import AntragstellerWizard from "../../Antragsteller/AntragstellerWizard/AntragstellerWizard.vue";

// @vue/component
export default {
  name: "DashboardWizardAntragsteller",
  components: {
    AntragstellerWizard,
  },
  data() {
    return {
      closeButtonHideInSteps: ["zugang", "suche", "speichern"],
    };
  },
  methods: {
    closeWizard() {
      this.$AuthService.refreshUser().then(() => this.$goTo("root.home", {}, { reload: "root.home" }));
    },
  },
};
