import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "AufgabeModalAendernZustaendigkeit",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    rows: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    url: {
      type: String,
      default: undefined,
    },
    selectorClose: {
      type: [String, Array],
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "readonly",
            type: "readonly",
            text: "_TXT_AUFGABE_MODAL_ZUSTAENDIGKEIT_AENDERN_NUTZER_AUSWAHL_{{nummer}}_",
            extra: {
              nummer: this.rows.length
            },
          },
          {
            id: "nutzer",
            type: "select",
            label: "_LBL_AUFGABE_MODAL_ZUSTAENDIGKEIT_AENDERN_NUTZER_",
            required: true,
            view: "v-alt",
            url: `profil/${ this.user.pk }/organisationsnutzer/`,
            keyLabelCallback: ({ item }) => `${ item.name }${ item.abwesenheit ? ` (${ item.abwesenheit })` : "" }`,
            keyId: "pk",
            searchParameter: "suche",
            searchGlobal: true,
            menuWidthAll: true,
            translate: true,
          },
        ],
      },
      status: {
        loading: undefined,
      },
      pks: [],
      errors: undefined,
      errorsClone: undefined,
    };
  },
  created() {
    this.initPks();
  },
  methods: {
    initPks() {
      forEach(this.rows, item => {
        this.pks.push(item.pk);
      });
    },

    save() {
      this.status.loading = true;
      const data = {
        pks: this.pks,
        nutzer: this.model.nutzer,
      };
      this.postHttp({ url: `${ this.url }batch_zuweisen/`, data }).then(
        () => {
          this.addNotification({ text: "_MSG_AUFGABE_MODAL_ZUSTAENDIGKEIT_AENDERN_SUCCESS_" });
          this.close({ status: true });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
