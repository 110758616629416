"use strict";

import angular from "angular";

import filesizeFilter from "./filesize.pipe";


const filterModule = angular.module("dokumente.pipes", [])

  .filter("filesize", filesizeFilter);

export default filterModule;
