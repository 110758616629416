import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "TemplateAdminBasisdaten",
  components: {
    FormstepDetail,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "updateTemplate",
  ],
  setup() {
    const {
      addNotification,
      putHttp,
    } = HttpAPI();

    return {
      addNotification,
      putHttp,
    };
  },
  data() {
    return {
      options: {
        list: [
          {
            id: "tpl_kbez",
            type: "text",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_KURZ_BEZEICHNUNG_",
            showLabel: true,
            view: "v",
            required: true,
          },
          {
            id: "tpl_bez",
            type: "text",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_BEZEICHNUNG_",
            showLabel: true,
            view: "v",
          },
          {
            id: "tpl_keyname",
            type: "select",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_KEYNAME_",
            url: `katalog/?key=reg_templates`,
            showLabel: true,
            view: "v",
            keyLabel: "bez",
            keyId: "id",
            required: true,
            helpText: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_KEYNAME_HELPTEXT_"
          },
          {
            id: "typ",
            type: "select",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_TYP_",
            url: `katalog/?key=templatetyp`,
            showLabel: true,
            keyLabel: "bez",
            keyId: "id",
            view: "v",
            required: true,
            helpText: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_TYP_HELPTEXT_"
          },
          {
            id: "objcls",
            type: "text",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_MODEL_",
            showLabel: true,
            view: "v",
            readonly: true,
            helpText: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_MODEL_HELPTEXT_"
          },
          {
            id: "app",
            type: "text",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_APP_",
            showLabel: true,
            view: "v",
            readonly: true,
            helpText: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_APP_HELPTEXT_"
          },
          {
            id: "has_vorlage",
            type: "boolean",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_VORLAGE_",
            showLabel: true,
            view: "v-alt",
            readonly: true,
            helpText: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_VORLAGE_HELPTEXT_"
          },
          {
            id: "content_editable",
            type: "boolean",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_CONTENT_EDITABLE_",
            showLabel: true,
            view: "v-alt",
            readonly: true,
            helpText: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_CONTENT_EDITABLE_HELPTEXT_"
          },
          {
            id: "tpl_permission_entwurf",
            type: "select",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_PERMISSION_ENTWURF_",
            showLabel: true,
            view: "v",
            url: `katalog/?key=permissions`,
            search: true,
            deselect: true,
            keyLabel: "bez",
            keyId: "id",
            searchList: ["bez"],
            required: false,
            helpText: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_PERMISSION_ENTWURF_HELPTEXT_"
          },
          /* {
            id: "tpl_permission_final",
            type: "select",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_PERMISSION_FINAL_",
            url: `katalog/?key=permissions`,
            search: true,
            deselect: true,
            showLabel: true,
            keyLabel: "bez",
            keyId: "id",
            searchList: ["bez"],
            view: "v",
            required: false,
            helpText: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_PERMISSION_FINAL_HELPTEXT_"
          },
          */
          {
            id: "tpl_veroeffentlicht",
            type: "boolean",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_FREIGEGEBEN_",
            showLabel: true,
            view: "v-alt",
            helpText: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_FREIGEGEBEN_HELPTEXT_"
          },
        ],
        required: true,
        saveCallback: this.save,
        editablePermission: "templateadmin.update",
      },
    };
  },
  computed: {
    urlSave() {
      return `templates/${ this.template.pk }/template_daten_aendern/`;
    },
  },
  methods: {
    save({ model }) {
      const DATA = cloneDeep(model);
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.urlSave,
          data: DATA,
        }).then(
          response => {
            this.$emit("updateTemplate", { template: response });
            this.addNotification({ text: "_MSG_TEMPLATEADMIN_DETAILS_UPDATE_DATA_SUCCESS_" });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },
  },
};
