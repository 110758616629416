import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";
import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import { getTextFromPlaceholder } from "../../../../global/functions/utils";

import {
  concat,
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "WorkflowfolgenDetailsBeschreibung",
  components: {
    AngularLink,
    FormElement,
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    workflowfolge: {
      type: Object,
      required: true,
    },
    workflowfolgeUrl: {
      type: String,
      required: true,
    },
    infoTexts: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "updateWorkflowfolge",
  ],
  setup() {
    const {
      patchHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    const {
      filterJson,
    } = FiltersAPI();

    return {
      addNotification,
      checkPermissionsSync,
      filterJson,
      patchHttp,
    };
  },
  data() {
    return {
      htmlId: "workflowfolgen_details_beschreibung_formstep",
      loading: false,
      errors: {},
      model: {},
      modelFields: {
        wfo_kbez: undefined,
        wfo_kbez_admin: undefined,
        wfo_pos: undefined,
        wfo_anzeige_typ: undefined,
        wfo_anzeige_icon: undefined,
        wfo_tracking_info: undefined,
        queue: undefined,
        aufgabe: undefined,
        nachfolge_aufgabe: undefined,
        workflow: undefined,
        nachfolge_workflow: undefined,
        wfo_reload: undefined,
      },
    };
  },
  computed: {
    formstepDetailOptions() {
      return {
        label: "_TXT_WORKFLOWFOLGENDETAILS_DESCRIPTION_",
        required: true,
        showCloseButton: true,
        saveCallback: this.save,
        openCallback: this.open,
      };
    },

    optionWfoKbezAdmin() {
      const ID = "wfo_kbez_admin";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        required: true,
        maxlength: 100,
        view: "v-alt",
        label: "_TXT_WORKFLOWFOLGENDETAILS_NAME_IN_ADMINISTRATION_",
      };
    },

    optionWfoKbez() {
      const ID = "wfo_kbez";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        required: true,
        maxlength: 60,
        view: "v-alt",
        label: "_TXT_WORKFLOWFOLGENDETAILS_NAME_",
      };
    },

    optionWfoPos() {
      const ID = "wfo_pos";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "number",
        required: true,
        view: "v-alt",
        label: "_TXT_WORKFLOWFOLGENDETAILS_POSITION_",
      };
    },

    optionAnzeigeTyp() {
      const ID = "wfo_anzeige_typ";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        view: "v-alt",
        label: "_TXT_WORKFLOWFOLGENDETAILS_ANZEIGE_STYLING_",
      };
    },

    optionAnzeigeIcon() {
      const ID = "wfo_anzeige_icon";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        view: "v-alt",
        label: "_TXT_WORKFLOWFOLGENDETAILS_ICON_",
      };
    },

    optionTrackingInfo() {
      const ID = "wfo_tracking_info";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "json",
        view: "v-alt",
        label: "_TXT_WORKFLOWFOLGENDETAILS_TRACKING_INFO_",
      };
    },

    optionQueue() {
      const ID = "queue";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "select",
        view: "v-alt",
        label: "_TXT_WORKFLOWFOLGENDETAILS_INFOTEXT_QUEUE_",
        keyId: "pk",
        keyLabel: "bez",
        url: "katalog/?key=taskqueues",
        apiSaveId: "taskqueues",
        search: true,
        deselect: true,
        searchList: ["bez"],
        hideIf: !this.hasPermissionUpdateQueue,
      };
    },

    optionAufgabe() {
      const ID = "aufgabe";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "select",
        view: "v-alt",
        label: "_TXT_WORKFLOWFOLGENDETAILS_AUSGANGSAUFGABE_",
        keyId: "pk",
        keyLabel: "bez",
        url: "katalog/?key=kaufgabe",
        apiSaveId: "kaufgabe",
        search: true,
        deselect: true,
        searchList: ["bez"],
      };
    },

    optionNachfolgeAufgabe() {
      const ID = "nachfolge_aufgabe";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "select",
        view: "v-alt",
        label: "_TXT_WORKFLOWFOLGENDETAILS_NACHFOLGEAUFGABE_",
        keyId: "pk",
        keyLabel: "bez",
        url: "katalog/?key=kaufgabe",
        apiSaveId: "kaufgabe",
        search: true,
        deselect: true,
        searchList: ["bez"],
      };
    },

    optionWorkflow() {
      const ID = "workflow";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "select",
        view: "v-alt",
        label: "_TXT_WORKFLOWFOLGENDETAILS_WORKFLOW_",
        keyId: "pk",
        keyLabel: "bez",
        url: "katalog/?key=workflow",
        apiSaveId: "workflow",
        search: true,
        deselect: true,
        searchList: ["bez"],
      };
    },

    optionNachfolgeWorkflow() {
      const ID = "nachfolge_workflow";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "select",
        view: "v-alt",
        label: "_TXT_WORKFLOWFOLGENDETAILS_NACHFOLGEWORKFLOW_",
        keyId: "pk",
        keyLabel: "bez",
        url: "katalog/?key=workflow",
        apiSaveId: "workflow",
        search: true,
        deselect: true,
        searchList: ["bez"],
      };
    },

    optionWfoReload() {
      const ID = "wfo_reload";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "boolean",
        view: "v-alt",
        label: "_TXT_WORKFLOWFOLGENDETAILS_WFO_RELOAD_",
      };
    },

    hasPermissionViewQueue() {
      return this.checkPermissionsSync({
        perm: "workflowfolge.view.queue",
      });
    },

    hasPermissionUpdateQueue() {
      return this.checkPermissionsSync({
        perm: "workflowfolge.update.queue",
      });
    },

    hasPermissionKaufgabeUi() {
      return this.checkPermissionsSync({
        perm: "kaufgabe.ui",
      });
    },

    hasPermissionWorkflowUi() {
      return this.checkPermissionsSync({
        perm: "workflow.ui",
      });
    },

    hasPermissionUpdateBeschreibung() {
      return this.checkPermissionsSync({
        perm: "workflowfolge.update",
      });
    },

    labelNoInformation() {
      return `[${ getTextFromPlaceholder("_TXT_WORKFLOWFOLGENDETAILS_NO_INFORMATION_") }]`;
    },

    labelNoDescription() {
      return `[${ getTextFromPlaceholder("_TXT_WORKFLOWFOLGENDETAILS_NO_DESCRIPTION_") }]`;
    },

    labelNo() {
      return getTextFromPlaceholder("_LBL_NO_");
    },

    labelYes() {
      return getTextFromPlaceholder("_LBL_YES_");
    },

    trackingInfo() {
      return this.workflowfolge.wfo_tracking_info ? this.filterJson(this.workflowfolge.wfo_tracking_info) : this.labelNoInformation;
    },

    formstepOptionsLeft() {
      return [
        this.optionWfoKbezAdmin,
        this.optionWfoKbez,
        this.optionWfoPos,
        this.optionAnzeigeTyp,
        this.optionAnzeigeIcon,
        this.optionTrackingInfo,
      ];
    },

    formstepOptionsRight() {
      return [
        this.optionQueue,
        this.optionAufgabe,
        this.optionNachfolgeAufgabe,
        this.optionWorkflow,
        this.optionNachfolgeWorkflow,
        this.optionWfoReload,
      ];
    },

    optionsList() {
      return concat(this.formstepOptionsLeft, this.formstepOptionsRight);
    },
  },
  methods: {
    open() {
      this.errors = {};
      const WORKFLOWFOLGE = cloneDeep(this.workflowfolge);
      const MODEL = {};
      forEach(this.modelFields, (item, key) => {
        MODEL[key] = WORKFLOWFOLGE[key];
      });
      this.model = MODEL;
    },

    save() {
      return new Promise((resolve, reject) => {
        this.patchHttp({
          url: this.workflowfolgeUrl,
          data: this.model,
        }).then(
          response => {
            this.addNotification({ text: "_MSG_WORKFLOWFOLGENDETAILS_SAVE_SUCCESS_" });
            this.$emit("updateWorkflowfolge", { data: response });
            resolve(response);
          },
          errors => {
            this.onErrors(errors);
            reject(errors);
          },
        );
      });
    },

    onErrors(errors) {
      this.errors = errors.data;
    },
  },
};
