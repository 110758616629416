"use strict";

import template from "./reset-password.jade";

class ResetPasswordController {
  /*@ngInject*/
  constructor(PasswortZuruecksetzen, DiasNotification) {
    this.loading = true;
    this.model = {};
    this.errorsLabel = {
      email: "E-Mail-Adresse: ",
    };

    this.DiasNotification = DiasNotification;
    this.PasswortZuruecksetzen = PasswortZuruecksetzen;

    this.initDefaultValues();
  }

  initDefaultValues() {
    this.regBody = this.regBody || {};
    this.regBody.class = this.regBody.class || "col-12";
    this.regBody.default = this.regBody.default || `<p>
                                                      Geben Sie Ihre E-Mail-Adresse ein, mit der Sie sich im Antragssystem registriert haben.
                                                      Sie erhalten eine E-Mail mit einem Link, über welchen Sie ein neues Passwort setzen können.
                                                    </p>`;
    this.regBody.success = this.regBody.success || `<p>
                                                      Sie werden in Kürze eine E-Mail erhalten, mit der Sie Ihr Passwort zurücksetzen können.
                                                      Sollte die E-Mail nicht ankommen, vergewissern Sie sich, dass Sie die E-Mail-Adresse verwenden,
                                                      mit der Sie sich bei Antragssystem registriert haben, und diese richtig geschrieben haben.
                                                    </p>`;
    if (!this.regHeader) {
      this.regHeader = {
        class: "card-header",
        label: `<h4 class="card-title">Passwort zurücksetzen</h4>`,
        successLabel: `<h4 class="card-title">E-Mail verschickt</h4>`
      };
    }

    if (!this.regClass) {
      this.regClass = "col-12 col-sm-10 col-lg-7 no-padding-right";
    }

    this.loading = false;
  }

  sendMail() {
    this.loading = true;
    this.PasswortZuruecksetzen.post(this.model).then(
      () => {
        this.success = true;
        this.loading = false;
      },
      errors => {
        this.onError(errors);
        this.loading = false;
      }
    );
  }

  onError(errors) {
    this.DiasNotification.form.clear();
    this.DiasNotification.form.error(
      errors,
      "Bitte überprüfen Sie Ihre Eingaben",
      {},
      this.errorsLabel
    );
    this.errors = errors.data;
  }

  deleteErrors() {
    this.DiasNotification.form.clear();
    this.errors = [];
  }
}

export default {
  template: template(),
  controller: ResetPasswordController,
  controllerAs: "vm",
  bindings: {
    regBody: "<?",
    regClass: "<?",
    regHeader: "<?"
  }
};
