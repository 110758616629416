import Betragsrechner from "../../Betragsrechner/Betragsrechner.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import KofiErfassungBelegFormExtraField from "./ExtraField/ExtraField.vue";
import KofiErfassungBelegFormStandardField from "./StandardField/StandardField.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxErrors from "../../../../global/components/PuxErrors/PuxErrors.vue";
import PuxRequired from "../../../../global/components/PuxRequired/PuxRequired.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import translate from "../../../../global/directives/translate";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";
import {
  FilterDateMixin,
} from "../../../../global/mixins/FiltersMixin";

import KVertragskostenbelegStatusUUIDs from "../../../../const/KVertragskostenbelegStatusUUIDs";
import PRUEFMETHODE from "../../../../const/KBelegPruefmethodeUUIDs";

import {
  dynFormToFormElements
} from "../../../../global/components/ui/UiDynamischeFormDefinition/utils";
import {
  initModelFromList
} from "../../../../global/functions/mappingForParameterToFormElement";

import StandardFields from "../../ui/UiBelegeField/Fields";

import {
  assign,
  cloneDeep,
  filter,
  forEach,
  get,
  intersection,
  isNil,
  isObject,
  omit,
  orderBy,
  size,
} from "lodash-es";

// @vue/component
export default {
  components: {
    FormstepDetail,
    PuxErrors,
    FormElement,
    Permission,
    KofiErfassungBelegFormExtraField,
    KofiErfassungBelegFormStandardField,
    Betragsrechner,
    PuxRequired,
    PuxTranslate,
  },
  directives: {
    translate,
  },
  mixins: [
    FilterDateMixin,
    HttpMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  props: {
    htmlId: {
      type: String,
      required: true,
      info: "Parent HtmlId",
    },
    urlSave: {
      type: String,
      required: true,
      info: "URL für speichern",
    },
    statusNew: {
      type: Boolean,
      required: true,
      info: "Ist der Beleg neu?",
    },
    position: {
      type: Object,
      required: true,
      info: "Hauptinformation von der Position",
    },
    beleg: {
      type: Object,
      required: false,
      default: undefined,
      info: "Hauptinformation von den Beleg",
    },
    extraTranslate: {
      type: Object,
      required: true,
      info: "Die zusätzliche Information für Übersetzung",
    },
    prefixCurrent: {
      type: Object,
      required: true,
      info: "Information vom Kofiart(kosten oder Finanzierung)",
    },
    vertrag: {
      type: Object,
      required: true,
      info: "Vertrag",
    },
    belegnummerAnzeigen: {
      type: Boolean,
      required: true,
      info: "Anzeigen Belegnummer",
    },
    betragsrechner: {
      type: String,
      required: false,
      default: undefined,
      info: "Betragsrechner",
    },
    belegDokumente: {
      type: Array,
      required: true,
      info: "Belegdokumente",
    },
    belegDokumenteAnzahl: {
      type: Object,
      required: false,
      info: "Mapping dok_id zur Anzahl der Belege, in der dieses Dokument benutzt wird",
      default: () => ({}),
    },
    apiAntragDokumente: {
      type: String,
      required: true,
      info: "API foerderantraege/{{ AntragPk }}/dokumente/?dokart={{ dokart }}",
    },
    extraKF: {
      type: Object,
      required: true,
      info: "{ regel, regeldata, modelParameter, positionArgument }",
    },
    auszahlung: {
      type: Object,
      required: false,
      default: undefined,
      info: "Auszahlung zu der der betrachtete Beleg gehört",
    },
    positionen: {
      type: Array,
      required: true,
      info: "Alle Positionen",
    },
    relevantePerioden: {
      type: Array,
      required: false,
      default: undefined,
      info: "Alle für die Auszahlung relevanten Positionen",
    },
    contextNumber: {
      type: Number,
      required: true,
      info: "Applikationskontextnummer",
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    isDiffChanges: {
      type: Boolean,
      required: false,
    },
    diffChangesBeleg: {
      type: Object,
      required: false,
      default: undefined,
    },
    isSnapshotPart: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      classesHorizontal: [
        "col-sm-3 text-left text-sm-right",
        "col-sm-6",
        "offset-sm-3 col-sm-6",
      ],
      errors: {},
      model: {
        argument: {},
        extra: {},
      },
      vorschau: {},
      timer: undefined,
      nextBelegNummer: undefined,
      statusLoadingBelegnummer: false,
      errorLabelsFromBetragsrechner: {},
      isSaving: false,
      tooltipBelegNummer: undefined,
      tooltipBelegBez: undefined,
      tooltipKommentar: undefined,
      standardFields: undefined,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        saveCallback: this.readonly ? undefined : this.saveLocal,
        closeCallback: this.closeLocal,
        cancelLabel: this.readonly ? "_BTN_CLOSE_" : "_BTN_CANCEL_",
        hideHeader: this.isSnapshotPart,
        hideFooter: this.isSnapshotPart,
        classBodyChildEditOnly: this.isSnapshotPart ? "version_position p-0" : "",
        info: this.belegHinweis,
        infoDefault: this.hasBelegHinweis,
      };
    },

    headerTranslateOptions() {
      return {
        text: this.headerText,
        extra: this.extraTranslate,
      };
    },

    headerText() {
      if (this.statusNew) {
        return "_TXT_BELEG_ADD_HEADER_{{position_name}}_{{position_nummer}}_";
      }
      if (this.readonly) {
        return "_TXT_BELEG_SHOW_HEADER_{{position_name}}_{{position_nummer}}_{{beleg_name}}_";
      }
      return "_TXT_BELEG_EDIT_HEADER_{{position_name}}_{{position_nummer}}_{{beleg_name}}_";
    },

    actionSave() {
      return this.statusNew ? "postHttp" : "putHttp";
    },

    optionsBelegPeriode() {
      const ID = "periode";
      return {
        id: "periode",
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_AUSZAHLUNG_BELEGE_FORM_BELEGE_TITLE_PERIODE_",
        type: "select",
        required: true,
        view: "h",
        keyId: "pk",
        keyLabel: "pe_kbez",
        disabled: !!get(this.auszahlung, "periode.pk"),
        classesHorizontal: this.classesHorizontal,
        data: this.relevantePerioden,
      };
    },

    optionsBelegNummer() {
      const ID = `${ this.prefixCurrent.beleg }_nummer`;
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        maxlength: 100,
        view: "h",
        required: true,
        label: "_LBL_AUSZAHLUNG_BELEGE_FORM_BELEGE_TITLE_BELEGNUMMER_",
        disabled: this.statusDisabledBelegNummer,
        autoFocus: true,
        placeholder: "_LBL_AUSZAHLUNG_BELEGE_FORM_BELEGE_PLACEHOLDER_BELEGNUMMER_",
        classesHorizontal: this.classesHorizontal,
        tooltip: this.tooltipBelegNummer,
      };
    },

    statusShowBelegnummer() {
      return this.belegnummerAnzeigen;
    },

    statusDisabledBelegNummer() {
      return this.statusDisableStammdatenEdit || this.vertrag.v_belege_fortlaufend;
    },

    belegPermissions() {
      const AZ_PERMS = get(this.auszahlung, "user_permissions");
      const BELEG_PERMS = get(this.beleg, "user_permissions");
      if (isNil(AZ_PERMS)) {
        return BELEG_PERMS;
      }
      return intersection(BELEG_PERMS, AZ_PERMS);
    },

    statusDisableStammdatenEdit() {
      if (!this.beleg) {
        return false;
      }
      return this.checkPermissionsSync({ perm: "vertragskostenpositionsbelege.update.intern", permArray: this.belegPermissions }) &&
        this.beleg.pruefmethode && this.beleg.pruefmethode.pk !== PRUEFMETHODE.INTERN_ERGAENZT;
    },

    statusShowPeriode() {
      return !!(this.relevantePerioden && this.relevantePerioden.length);
    },

    optionsBelegBez() {
      const ID = `${ this.prefixCurrent.beleg }_bez`;
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        maxlength: 250,
        view: "h",
        required: true,
        label: "_LBL_AUSZAHLUNG_BELEGE_FORM_BELEGE_TITLE_BEZEICHNUNG_",
        disabled: this.statusDisableStammdatenEdit,
        placeholder: "_LBL_AUSZAHLUNG_BELEGE_FORM_BELEGE_PLACEHOLDER_BEZEICHNUNG_",
        classesHorizontal: this.classesHorizontal,
        tooltip: this.tooltipBelegBez,
      };
    },

    optionsBelegBetragErfasst() {
      const ID = `${ this.prefixCurrent.beleg }_betrag_erfasst`;
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "float",
        maxlength: 14,
        view: "h",
        required: true,
        label: "_LBL_AUSZAHLUNG_BELEGE_FORM_BELEGE_TITLE_BELEGBERTRAG_",
        addonBack: "_HTML_SUFFIX_EURO_",
        disabled: this.statusDisableStammdatenEdit,
        autoFocus: true, // TODO:
        placeholder: "_TXT_BELEGBETRAG_PLACEHOLDER_",
        classesHorizontal: this.classesHorizontal,
      };
    },

    statusShowBelegBetragErfasst() {
      return this.statusShowBetrag && !this.betragsrechner;
    },

    statusShowBetrag() {
      return !((this.beleg === undefined || this.beleg.pk === undefined) && this.checkPermissionsSync({ perm: "vertragskostenpositionsbelege.create.intern" })) &&
        (this.beleg === undefined || !this.beleg.pruefmethode || this.beleg.pruefmethode.pk !== PRUEFMETHODE.INTERN_ERGAENZT);
    },

    optionsBelegAnerkanntWert() {
      const ID = "wert";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "float",
        maxlength: 14,
        view: "h",
        required: true,
        label: "_LBL_ANERKANNTER_BETRAG_",
        addonBack: "_HTML_SUFFIX_EURO_",
        placeholder: "_TXT_ANERKANNTER_BETRAG_PLACEHOLDER_",
        classesHorizontal: this.classesHorizontal,
      };
    },

    optionsPosition() {
      const ID = `vertrags${ this.prefixCurrent.key }position`;
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "select",
        required: true,
        view: "h",
        label: "_LBL_POSITION_",
        classesHorizontal: this.classesHorizontal,
        placeholder: "_TXT_KOFI_DETAIL_PLACEHOLDER_",
        keyId: "pk",
        slot: "position",
        data: this.positionen,
      };
    },

    statusShowOptionsPosition() {
      return this.hasBelegWFPermissionVertragskostenpositionsbelegeAnerkennen &&
        !this.statusNew;
    },

    hasBelegWFPermissionVertragskostenpositionsbelegeAnerkennen() {
      return !!(this.beleg && this.checkPermissionsSync({
        perm: "vertragskostenpositionsbelege.anerkennen",
        permArray: this.belegPermissions,
      }));
    },

    keyPositionBez() {
      return `${ this.prefixCurrent.position }_bez`;
    },

    keyPositionPos() {
      return `${ this.prefixCurrent.position }_pos`;
    },

    optionsBelegKommentar() {
      const ID = `${ this.prefixCurrent.beleg }_kommentar`;
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "textarea",
        maxlength: 200,
        view: "h",
        required: false,
        label: "_LBL_AUSZAHLUNG_BELEGE_FORM_BELEGE_TITLE_KOMMENTAR_",
        placeholder: "_LBL_AUSZAHLUNG_BELEGE_FORM_BELEGE_PLACEHOLDER_KOMMENTAR_",
        classesHorizontal: this.classesHorizontal,
        tooltip: this.tooltipKommentar,
      };
    },

    statusShowOptionsBelegKommentar() {
      if (this.readonly) {
        return true;
      }
      return this.checkPermissionsSync({
        perm: "vertragskostenpositionsbelege.anerkennen",
      });
    },

    extraFields() {
      const OBJ = this.auszahlung || this.vertrag;
      const FORM_DEF = get(this, "extraKF.modelParameter.beleg_extra_fields", []);
      return dynFormToFormElements({
        formDef: FORM_DEF,
        object: OBJ,
        htmlId: this.htmlId
      });
    },

    hasExtraFields() {
      return size(this.extraFields) > 0;
    },

    standardFieldConfigs() {
      const CONFIG = get(this, "extraKF.modelParameter.beleg_fields", {});
      const FIELDS = orderBy(
        filter(CONFIG, conf => conf.show),
        conf => get(conf, "pos", get(this.standardFields, [conf.field, "prio"], 999))
      );
      forEach(FIELDS, el => {
        el.id = el.field;
        el.htmlId = `${ this.htmlId }_${ el.field }`;
        el.label = get(this.standardFields, [el.field, "label"]);
      });
      return FIELDS;
    },

    hasStandardFieldConfigs() {
      return size(this.standardFieldConfigs) > 0;
    },

    belegHinweis() {
      return get(this, "extraKF.modelParameter.beleg_hinweis", undefined);
    },

    hasBelegHinweis() {
      return size(this.belegHinweis) > 0;
    },

    errorLabels() {
      const FIELDS = [
        this.optionsBelegPeriode,
        this.optionsBelegNummer,
        this.optionsBelegBez,
        this.optionsBelegBetragErfasst,
        this.optionsBelegAnerkanntWert,
        this.optionsPosition,
        this.optionsBelegKommentar,
        ...(this.extraFields || []),
        ...(this.standardFieldConfigs || []),
      ];
      const ERROR_LABELS = cloneDeep(this.errorLabelsFromBetragsrechner);
      forEach(FIELDS, el => {
        ERROR_LABELS[el.id] = {
          label: el.label,
          link: true,
          id: el.htmlId,
        };
      });
      // ERROR_LABELS.periode = {
      //   label: this.optionsBelegPeriode.label,
      //   link: true,
      //   id: this.optionsBelegPeriode.htmlId,
      // };
      // ERROR_LABELS[this.optionsBelegNummer.id] = {
      //   label: this.optionsBelegNummer.label,
      //   link: true,
      //   id: this.optionsBelegNummer.htmlId,
      // };
      // ERROR_LABELS[this.optionsBelegBez.id] = {
      //   label: this.optionsBelegBez.label,
      //   link: true,
      //   id: this.optionsBelegBez.htmlId,
      // };
      // ERROR_LABELS[this.optionsBelegBetragErfasst.id] = {
      //   label: this.optionsBelegBetragErfasst.label,
      //   link: true,
      //   id: this.optionsBelegBetragErfasst.htmlId,
      // };
      // if (!this.betragsrechner) {
      //   ERROR_LABELS.wert = {
      //     label: this.optionsBelegAnerkanntWert.label,
      //     link: true,
      //     id: this.optionsBelegAnerkanntWert.htmlId,
      //   };
      // }
      // ERROR_LABELS[this.optionsPosition.id] = {
      //   label: this.optionsPosition.label,
      //   link: true,
      //   id: this.optionsPosition.htmlId,
      // };
      // ERROR_LABELS[this.optionsBelegKommentar.id] = {
      //   label: this.optionsBelegKommentar.label,
      //   link: true,
      //   id: this.optionsBelegKommentar.htmlId,
      // };
      // forEach(this.extraFields, item => {
      //   ERROR_LABELS[item.id] = {
      //     label: item.label,
      //     link: true,
      //     id: item.htmlId,
      //   };
      // });
      // forEach(this.standardFieldConfigs, item => {
      //   ERROR_LABELS[item.field] = {
      //     label: this.standardFields[item.field].label,
      //     link: true,
      //     id: item.htmlId,
      //   };
      // });
      return ERROR_LABELS;
    },

    keyBelegNummer() {
      return `${ this.prefixCurrent.beleg }_nummer`;
    },

    keyBelegArgumentErfasst() {
      return `${ this.prefixCurrent.beleg }_argument_erfasst`;
    },

    keyBelegBetragAnerkannt() {
      return `${ this.prefixCurrent.beleg }_betrag_anerkannt`;
    },

    keyBelegArgumentAnerkannt() {
      return `${ this.prefixCurrent.beleg }_argument_anerkannt`;
    },

    vorschauUrl() {
      return `betragsrechner/${ this.betragsrechner }/vorschau/`;
    },

    modelForVorschau() {
      return cloneDeep(this.model.argument);
    },

    regelPk() {
      return this.extraKF.regel.pk;
    },

    auszahlungSyConfig() {
      return this.getSyConfigsValue("auszahlung");
    },

    isCurrentComponentWithSnapshotVisible() {
      return this.readonly && this.isDiffChanges && !this.isSnapshotPart;
    },

    extraForSnapshotTranslate() {
      return {
        date: this.filterDate(this.snapshotDate, "datetime"),
      };
    },

    htmlIdSnapshot() {
      return `${ this.htmlId }_snapshot`;
    },

    apiObjektkatalog() {
      return `/generics/${ this.vertrag.content_type }/objekte/${ this.vertrag.pk }/katalogwerte/`;
    },

    kofiArtDetailKey() {
      return `${ this.prefixCurrent.art }detail`;
    },

    attributesAndProps() {
      return {
        ...this.$props,
        ...this.$attrs,
      };
    },
  },
  watch: {
    snapshot() {
      if (this.isSnapshotPart && !this.statusNew) {
        this.initModelWithBeleg();
        this.changeVorschau();
      }
    },
  },
  created() {
    this.standardFields = cloneDeep(StandardFields);
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.statusNew) {
        this.model = {
          [this.optionsBelegNummer.id]: null,
          argument: {
            [this.kofiArtDetailKey]: this.position[this.kofiArtDetailKey],
          },
          auszahlung: get(this.auszahlung, "pk"),
          [this.optionsPosition.id]: this.position.pk,
          extra: this.initModelExtraFields({}),
          standardfelder: {},
          periode: get(this.auszahlung, "periode.pk")
        };
        this.loadNextBelegnummer();
      } else {
        this.initModelWithBeleg();
      }
    },

    initModelWithBeleg() {
      const MODEL = cloneDeep(this.beleg);
      if (this.betragsrechner) {
        let argument_betrag;
        let argument_params;
        let standardfelder;
        let extra;
        if (this.beleg.status.pk === KVertragskostenbelegStatusUUIDs.NEU ||
          this.beleg.status.pk === KVertragskostenbelegStatusUUIDs.UNVOLLSTAENDIG) {
          /* Wenn der Beleg neu oder unvollständig ist, dann müssen die Werte aus den "erfasst"
             Werten entnommen werden: */
          argument_params = this.beleg.argument_erfasst;
          argument_betrag = this.beleg[this.optionsBelegBetragErfasst.id];
          extra = MODEL.extra_input_erfasst;
          standardfelder = MODEL.standardfelder_erfasst;
        } else if (this.beleg.status.pk === KVertragskostenbelegStatusUUIDs.GEPRUEFT) {
          /* Wenn der Beleg durch den Safo angelegt wurde, dann müssen die Werte aus den "anerkannt"
             Werten entnommen werden: */
          argument_betrag = this.beleg[this.keyBelegBetragAnerkannt];
          argument_params = this.beleg.argument_anerkannt;
          extra = MODEL.extra_input_anerkannt;
          standardfelder = MODEL.standardfelder_anerkannt;
        } else if (this.beleg.status.pk === KVertragskostenbelegStatusUUIDs.EINGEREICHT) {
          /* Wenn der Beleg eingereicht ist, dann muss differenziert werden: */
          if (this.beleg[this.keyBelegBetragAnerkannt] === null) {
            // Der Beleg wurde noch nicht durch den Sachbearbeiter angefasst:
            argument_betrag = this.beleg[this.optionsBelegBetragErfasst.id];
            argument_params = this.beleg.argument_erfasst;
            extra = MODEL.extra_input_erfasst;
            standardfelder = MODEL.standardfelder_erfasst;
          } else if (this.beleg[this.keyBelegBetragAnerkannt] === "0.00") {
            // Der Beleg wurde schon mal abgelehnt:
            argument_betrag = "0.00";
            argument_params = {};
            extra = MODEL.extra_input_anerkannt;
            standardfelder = MODEL.standardfelder_anerkannt;
          } else {
            // Der Beleg wurde schon mal vom Sachbearbeiter bearbeitet:
            argument_betrag = this.beleg[this.keyBelegBetragAnerkannt];
            argument_params = this.beleg.argument_anerkannt;
            extra = MODEL.extra_input_anerkannt;
            standardfelder = MODEL.standardfelder_anerkannt;
          }
        }
        MODEL.argument = argument_params || {};
        MODEL.argument.wert = argument_betrag;
        MODEL.standardfelder = standardfelder || {};
        MODEL.extra = this.initModelExtraFields(extra);
        this.model = cloneDeep(MODEL);
      }
    },

    loadNextBelegnummer() {
      if (this.auszahlungSyConfig.belegdaten_vorbelegen && !get(this.model, this.keyBelegNummer)) {
        const BELEGNUMMER =
          `${ this.contextNumber }.${ this.position.oberpunkt }.${ this.position[this.keyPositionPos] }-${ this.position.belege.length + 1 }`;
        this.model[this.keyBelegNummer] = BELEGNUMMER;
      } else {
        this.statusLoadingBelegnummer = true;
        this.getHttp({
          url: `vertraege/${ this.vertrag.pk }/belegnummer/`,
        }).then(
          response => {
            this.nextBelegNummer = get(response, "next");
            if (this.nextBelegNummer && !get(this.model, this.keyBelegNummer)) {
              this.model[this.keyBelegNummer] = this.nextBelegNummer;
            }
          },
          () => {
            this.addNotification({
              text: "_MSG_GET_BELEG_NUMMER_ERROR_",
              type: "error",
            });
          }
        ).finally(
          () => {
            this.statusLoadingBelegnummer = false;
          }
        );
      }
    },

    initModelExtraFields(extra) {
      return assign(initModelFromList({ list: this.extraFields }), extra || {});
    },

    changeModelExtraField({ model, id }) {
      this.model.extra[id] = model;
      this.onChange();
    },

    changeModelStandardField({ model, id }) {
      this.model.standardfelder[id] = model;
      this.onChange();
    },

    saveLocal() {
      this.isSaving = true;
      this.deleteErrors();
      const DATA = Object.assign({}, this.model, this.extraSave);
      return new Promise((resolve, reject) => {
        this[this.actionSave]({
          url: this.urlSave,
          data: DATA,
        }).then(
          () => {
            this.$attrs.onClose();
            this.$attrs.onReloadKofi({ statusReloadBelegDokumente: true, statusCallback: true });
            resolve();
          },
          errors => {
            this.errors = errors.data || {};
            if (isObject(this.errors.extra)) {
              this.errors = assign({}, omit(this.errors, ["extra"]), this.errors.extra);
            }
            if (isObject(this.errors.standardfelder)) {
              this.errors = assign({}, omit(this.errors, ["standardfelder"]), this.errors.standardfelder);
            }
            this.focusToErrors();
            reject();
          }
        ).finally(
          () => {
            this.isSaving = false;
          }
        );
      });
    },

    focusToErrors() {
      setTimeout(() => {
        if (this.$refs.errors_component && this.$refs.errors_component.$el) {
          this.$refs.errors_component.$el.focus();
        }
      });
    },

    closeLocal(arg) {
      this.$attrs.onClose(arg);
    },

    deleteErrors() {
      this.errors = {};
    },

    changeModelFromChild({ model }) {
      this.model = model;
    },

    initErrorLabelsFromChild({ labels }) {
      this.errorLabelsFromBetragsrechner = labels;
    },

    mock() {

    },

    onChange({ modelForVorschau } = {}) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.changeVorschau({ modelForVorschau });
      }, 500);
    },

    changeVorschau({ modelForVorschau } = {}) {
      this.postHttp({
        url: this.vorschauUrl,
        urlParams: {
          kontext: "beleg",
          afr_id: this.regelPk,
        },
        data: modelForVorschau || this.modelForVorschau,
      }).then(
        response => {
          this.vorschau = response.vorschau || {};
        }
      );
    },

    updateOptionsFromChild(arg) {
      forEach(arg, (item, key) => {
        this[key] = item;
      });
    },
  },
};
