import AnsprechpartnerAssignButtonModal from "../../../../Ansprechpartner/AnsprechpartnerAssignButtonModal/AnsprechpartnerAssignButtonModal.vue";
import AnsprechpartnerAssignMeButtonModal from "../../../../Ansprechpartner/AnsprechpartnerAssignMeButtonModal/AnsprechpartnerAssignMeButtonModal.vue";
import AntragOverviewVertragAnsprechpartner from "./AntragOverviewVertragAnsprechpartner/AntragOverviewVertragAnsprechpartner.vue";
import NachrichtenLink from "../../../../Nachrichten/NachrichtenLink/NachrichtenLink.vue";
import Permission from "../../../../../global/components/Permission/Permission.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import TeamChangeButtonModal from "../../../../Teams/TeamChangeButtonModal/TeamChangeButtonModal.vue";

import PermissionMixin from "../../../../../global/mixins/PermissionMixin";

import KommunikationsdatenUUIDs from "../../../../../const/KommunikationsdatenUUIDs";

// @vue/component
export default {
  name: "AntragOverviewAnsprechpartner",
  components: {
    AnsprechpartnerAssignButtonModal,
    AnsprechpartnerAssignMeButtonModal,
    AntragOverviewVertragAnsprechpartner,
    NachrichtenLink,
    Permission,
    PuxIcon,
    PuxTranslate,
    TeamChangeButtonModal,
  },
  mixins: [
    PermissionMixin,
  ],
  props: {
    antrag: {
      type: Object,
      required: true,
    },
    antragUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      KommunikationsdatenUUIDs: KommunikationsdatenUUIDs,
    };
  },
};
