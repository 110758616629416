"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";


class WorkflowfolgenEditBeschreibungController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, KWorkflowstatusgrupppenUUIDs, DiasNotification, ParameterUtils, i18n) {
    super($q, $scope, DiasNotification);
    this.gettext = i18n.gettext;
    this.KWorkflowstatusgrupppenUUIDs = KWorkflowstatusgrupppenUUIDs;
    this.wtgs = $scope.extra.wtgs;
    this.ParameterUtils = ParameterUtils;
    this.formModels = {};
    this.initForm();
    this.changed = this.changed.bind(this);
  }

  _save() {
    Object.keys(this.formModels).forEach(
      key => this.formModels[key].forEach(
        (formModel, idx) => {
          this.tempModels[key][idx].wsg_kbez = formModel.wsg_kbez.wert;
          this.tempModels[key][idx].au_ids = formModel.au_ids.wert;
        }
      )
    );
    const data = [
      ...this.tempModels[this.KWorkflowstatusgrupppenUUIDs.bearbeiten],
      ...this.tempModels[this.KWorkflowstatusgrupppenUUIDs.erfolg],
      ...this.tempModels[this.KWorkflowstatusgrupppenUUIDs.misserfolg],
    ].filter(wsg => this.isActive(wsg.wsg_pos, wsg.workflowstatusgruppentyp));
    return this.model.customPOST(data, "statusgruppen_bearbeiten").then(
      response => {
        const new_wsgs = response.plain();
        Object.keys(this.KWorkflowstatusgrupppenUUIDs).map(
          key => this.KWorkflowstatusgrupppenUUIDs[key]
        ).forEach(
          pk => {
            this.wtgs[pk].wsgs = new_wsgs
              .filter(wsg => wsg.workflowstatusgruppentyp === pk)
              .sort((a, b) => a.wsg_pos - b.wsg_pos);
          }
        );
        this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
      },
      errors => {
        return this.onErrors(errors, this.errorLabels);
      }
    );
  }

  onEnterEditMode() {
    super.onEnterEditMode();
    this.updateFormModel();
  }

  isActive(wsg_pos, wtg_id) {
    for (let i = 0; i < this.tempModels[wtg_id].length; i++) {
      if (this.tempModels[wtg_id][i].wsg_pos === wsg_pos && this.tempModels[wtg_id][i].wsg_kbez && this.tempModels[wtg_id][i].wsg_kbez.length > 0) {
        return true;
      } else if (!this.tempModels[wtg_id][i].wsg_kbez || this.tempModels[wtg_id][i].wsg_kbez.length === 0) {
        return false;
      }
    }
    return false;
  }

  getStatus(wsg_pos, wtg_id) {
    if (this.isAdded(wsg_pos, wtg_id)) {
      return "1";
    }
    if (this.isRemoved(wsg_pos, wtg_id)) {
      return "2";
    }
    if (this.isChanged(wsg_pos, wtg_id)) {
      return "3";
    }
    return "0";
  }

  isAdded(wsg_pos, wtg_id) {
    const wsg = this.tempModels[wtg_id].find(wsg => wsg.wsg_pos === wsg_pos);
    return this.isActive(wsg_pos, wtg_id) && !wsg.pk;
  }

  isRemoved(wsg_pos, wtg_id) {
    const wsg = this.tempModels[wtg_id].find(wsg => wsg.wsg_pos === wsg_pos);
    return !this.isActive(wsg_pos, wtg_id) && wsg.pk;
  }

  isChanged(wsg_pos, wtg_id) {
    const twsg = this.tempModels[wtg_id].find(wsg => wsg.wsg_pos === wsg_pos);
    if (!this.isActive(wsg_pos, wtg_id)) {
      return false;
    }
    const wsg = this.wtgs[wtg_id].wsgs.find(wsg => twsg.wsg_pos === wsg.wsg_pos);
    if (twsg.wsg_kbez !== wsg.wsg_kbez || twsg.au_ids.length !== wsg.aufgaben.length) {
      return true;
    }
    return wsg.aufgaben.find(au => twsg.au_ids.indexOf(au.pk) === -1) !== undefined;
  }

  updateFormModel() {
    // Formular-Daten initialisieren
    if (this.ParameterUtils && this.model && this.KWorkflowstatusgrupppenUUIDs) {
      this.tempModels = {
        [this.KWorkflowstatusgrupppenUUIDs.bearbeiten]: [
          {
            wsg_pos: 1,
            au_ids: [],
            workflowtyp: this.model.pk,
            workflowstatusgruppentyp: this.KWorkflowstatusgrupppenUUIDs.bearbeiten
          },
          {
            wsg_pos: 2,
            au_ids: [],
            workflowtyp: this.model.pk,
            workflowstatusgruppentyp: this.KWorkflowstatusgrupppenUUIDs.bearbeiten
          },
          {
            wsg_pos: 3,
            au_ids: [],
            workflowtyp: this.model.pk,
            workflowstatusgruppentyp: this.KWorkflowstatusgrupppenUUIDs.bearbeiten
          },
        ],
        [this.KWorkflowstatusgrupppenUUIDs.erfolg]: [
          {
            wsg_pos: 4,
            au_ids: [],
            workflowtyp: this.model.pk,
            workflowstatusgruppentyp: this.KWorkflowstatusgrupppenUUIDs.erfolg
          },
          {
            wsg_pos: 6,
            au_ids: [],
            workflowtyp: this.model.pk,
            workflowstatusgruppentyp: this.KWorkflowstatusgrupppenUUIDs.erfolg
          }
        ],
        [this.KWorkflowstatusgrupppenUUIDs.misserfolg]: [
          {
            wsg_pos: 5,
            au_ids: [],
            workflowtyp: this.model.pk,
            workflowstatusgruppentyp: this.KWorkflowstatusgrupppenUUIDs.misserfolg
          },
        ],
      };
      Object.keys(this.KWorkflowstatusgrupppenUUIDs).map(
        key => this.KWorkflowstatusgrupppenUUIDs[key]
      ).forEach(
        pk => this.wtgs[pk].wsgs.forEach(
          wsg => {
            const item = this.tempModels[pk].find(fWsg => fWsg.wsg_pos === wsg.wsg_pos);
            if (item) {
              Object.assign(item, wsg);
              item.au_ids.push(...wsg.aufgaben.map(au => au.pk));
            }
          }
        )
      );
      Object.keys(this.KWorkflowstatusgrupppenUUIDs).map(
        key => this.KWorkflowstatusgrupppenUUIDs[key]
      ).forEach(
        pk => this.tempModels[pk].forEach(
          model => {
            this.formModels[pk] = this.formModels[pk] || [];
            const formModel = this.ParameterUtils.params_from_template(this.formModelTemplate, model);
            formModel.wsg_pos = model.wsg_pos;
            this.formModels[pk].push(formModel);
          }
        )
      );
    }
  }

  changed() {
    Object.keys(this.formModels).forEach(
      key => this.formModels[key].forEach(
        (formModel, idx) => {
          this.tempModels[key][idx].wsg_kbez = formModel.wsg_kbez.wert;
          this.tempModels[key][idx].au_ids = formModel.au_ids.wert;
        }
      )
    );
  }

  initForm() {
    // TODO Formular nutzen für Aufgaben-Select
    this.formModelTemplate = this.ParameterUtils.init_parameter_in({
      wsg_kbez: {
        bez: this.gettext("Bezeichnung"),
        typ: "string",
        prio: 1,
        options: {
          required: false
        }
      },
      au_ids: {
        bez: this.gettext("Aufgaben"),
        typ: "multi_select",
        katalog: "kaufgabe",
        prio: 2,
        options: {
          required: false,
          displaymode: "select2"
        }
      },
    });
    this.form_keys = this.ParameterUtils.get_ordered_keys(this.formModelTemplate);
    this.errorLabels = {};
    this.form_keys.forEach(
      k => {
        this.errorLabels[k] = `${ this.formModelTemplate[k].bez }: `;
      }
    );
    this.updateFormModel();
  }
}

export default WorkflowfolgenEditBeschreibungController;
