import KFBaseMixin from "./KFBaseMixin";
import {
  cloneDeep,
  get,
  keyBy,
} from "lodash-es";
import { createNamespacedHelpers } from "vuex";
import InfoproviderStatuses from "../../../../const/InfoproviderStatuses";
const {
  mapGetters,
} = createNamespacedHelpers("snapshotKF");

// @vue/component
export default {
  mixins: [
    KFBaseMixin,
  ],
  data() {
    return {
      antragKostenart: {},
      positionenOrderingKey: "akp_pos",
      urlPositionen: "",
      typeKoFi: "kosten",
      positionBezKey: "akp_bez",
      positionWertKey: "akp_wert",
      summeKey: "aka_summe",
    };
  },
  computed: {
    urlArten() {
      return `${ this.baseUrl }kostenarten/`;
    },

    currentSnapshot() {
      if (this.antragKoFiArt.pk && this.SNAPSHOT_KOSTENARTEN_BY_AKA_ID_GET) {
        const CURRENT_SNAPSHOT = cloneDeep(this.SNAPSHOT_KOSTENARTEN_BY_AKA_ID_GET[this.antragKoFiArt.pk]) || {};
        if (CURRENT_SNAPSHOT.positionen) {
          CURRENT_SNAPSHOT.positionenObj = keyBy(CURRENT_SNAPSHOT.positionen, "pk");
        } else {
          CURRENT_SNAPSHOT.positionenObj = {};
        }
        return CURRENT_SNAPSHOT;
      }
      return undefined;
    },

    statusShowProzente() {
      if (!this.koFiConfig.kosten_prozente_anzeigen) {
        return false;
      }
      // Wenn eine Zuschussberechnung durchgeführt werden konnte:
      if (this.infoprovider && this.infoprovider.status === InfoproviderStatuses.PROCESSOR_STATUS_OK) {
        return this.infoproviderProzent !== null;
      }
      // Wenn keine Zuschussberechnung durchgeführt wurde:
      if (!this.infoprovider) {
        return this.antragKostenart.aka_prozent !== null;
      }
      return false;
    },

    prozent() {
      const PROZENT = this.infoproviderProzent || this.antragKostenart.aka_prozent;
      return this.filterCurrency(PROZENT, "%");
    },

    ...mapGetters([
      "SNAPSHOT_KOSTENARTEN_BY_AKA_ID_GET",
    ]),
  },
  created() {
    this.loadKostenart_KostenMixin();
  },
  methods: {
    loadKostenart_KostenMixin() {
      this.getListHttp({
        url: "kostenarten/",
        apiSaveId: "kostenarten",
      }).then(
        response => {
          this.initKatalogDetails_KostenMixin(response, this.modelParameter.kostenart, this.modelParameter.kostendetails);
        }
      );
    },

    initKatalogDetails_KostenMixin(katalogResults, regelKatalogId, regelKatalogDetails) {
      this.initKatalogDetails_KFBaseMixin(katalogResults, regelKatalogId, regelKatalogDetails);
      if (this.modelParameter.ohne_positionen && this.katalogDetails.length) {
        this.editType = "no-position-details";
      } else if (this.modelParameter.ohne_positionen) {
        this.editType = "no-position";
      }
    },

    setAntragKoFiArt_KFBaseMixin({ obj }) {
      this.antragKostenart = obj;
      this.setUrlPositionen();
      const ANTRAG_KO_FI_ART = cloneDeep(this.antragKoFiArt) || {};
      ANTRAG_KO_FI_ART.bez = get(this.antragKostenart, "kostenart.kos_kbez");
      ANTRAG_KO_FI_ART.summe = this.antragKostenart.aka_summe;
      ANTRAG_KO_FI_ART.gekappt = this.modelParameter.max_betrag && this.antragKostenart.aka_summe === this.modelParameter.max_betrag;
      ANTRAG_KO_FI_ART.artPk = get(this.antragKostenart, "kostenart.pk");
      ANTRAG_KO_FI_ART.diffKey = "kostenarten";
      ANTRAG_KO_FI_ART.pk = this.antragKostenart.pk;
      this.antragKoFiArt = ANTRAG_KO_FI_ART;
    },

    setUrlPositionen() {
      this.urlPositionen = `${ this.urlArten }${ this.antragKostenart.pk }/positionen/`;
    },
  },
};
