"use strict";

export default /*@ngInject*/ () => {
  return {
    restrict: "A",
    link: function(scope, element) {
      $(element).tooltip({ html: true, container: "body" });
    }
  };
};
