"use strict";

import BetragsrechnerFerien from "../ferien_betragsrechner/betragsrechner";

class FerienkostenMitReisetagenRechner extends BetragsrechnerFerien {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "ferienkostenrechner_mit_reisetagen";
    this.component_name = "ferienkosten-mit-reisetagen";
  }
}
export default FerienkostenMitReisetagenRechner;
