var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;







jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :ctx.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "ctx.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in ctx.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<div class=\"modal-body\"><div class=\"row\"><section class=\"main col-10 offset-1\"><div ng-attr-id=\"{{ ctx.DiasNotification.form.containerId }}\"></div><form><fieldset>");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<dias-select id=\"empfaenger\" label-field=\"name_mit_abwesenheit\" search-field=\"suche\" min-search=\"3\" selected=\"ctx.selected_empfaenger\" data-disabled=\"ctx.disableEmpfaengerSelect\" options=\"ctx.Empfaenger\" async-block-initial=\"true\" aria-required=\"true\"></dias-select>");
}
}, "empfaenger", "Empfänger");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"titel\" ng-model=\"ctx.titel\" type=\"text\" name=\"titel\" auto-focus=\"true\" aria-required=\"true\" ng-attr-aria-invalid=\"{{ctx.errors.titel ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "titel", "Betreff");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<textarea id=\"text\" ng-model=\"ctx.text\" dias-richtext name=\"text\" aria-required=\"true\" ng-attr-aria-invalid=\"{{ctx.errors.text ? 'true' : 'false'}}\" class=\"form-control\"></textarea>");
}
}, "text", "Text");
buf.push("<div>{{ ctx.bezugtext }}</div></fieldset></form></section></div></div><div class=\"modal-footer\"><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"exitWithCallback(ctx.save)\" ng-disabled=\"loading || !ctx.isReady()\" type=\"button\" class=\"btn btn-primary\"><span>Senden</span></button><button ng-click=\"exit()\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-default\"><span>Abbrechen</span></button></div></div></div>");;return buf.join("");
}