"use strict";

import BaseModel from "dias/core/models/baseModel";
import Dokumente from "dias/dokumente/models/dokumente.service";
import Objektaufgabe from "dias/workflow/models/objektaufgabe.service";
import Foerdermodule from "dias/foerderantrag/foerdermodule/models/foerdermodule.service";
import ObjektBenachrichtigung from "dias/benachrichtigungen/models/objektbenachrichtigung.service";
import Applikationskontextrubriken from "dias/foerderregeln/models/applikationskontextrubriken.service";


class Formular extends BaseModel {
  constructor() {
    super("formulare", {
      subPaths: {
        aufgaben: new Objektaufgabe(),
        module: new Foerdermodule(),
        rubriken: new Applikationskontextrubriken(),
        benachrichtigungen: new ObjektBenachrichtigung(),
        dokumente: new Dokumente(),
      }
    });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.getKAufgaben = () => collection.customGET("kaufgaben");
    collection.getReferenzierteObjekte = () => collection.customGETLIST("referenzierte_objekte");
    return collection;
  }

  extendModel(model) {
    model = super.extendModel(model);
    model.restangularizeAufgabe = aufgabe => this.Restangular.restangularizeElement(model, aufgabe, "aufgaben", {});

    if (model.aktuelle_aufgabe) {
      model.aktuelle_aufgabe = model.restangularizeAufgabe(model.aktuelle_aufgabe);
    }
    if (model.for_objektid) {
      if (model.herkunft === "foerderantrag") {
        model.objekt = Object.assign(this.foerderantrag.one(model.for_objektid), { pk: model.for_objektid });
      }
      if (model.dokcontainermodus && model.dokcontainermodus == "objekt") {
        model.dokumente = model.objekt.dokumente;
      }
    }

    return model;
  }

  onServiceBuild() {
    this.foerderantrag = this.$injector.get("Foerderantrag");
  }
}

export default Formular;
