import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import Modal from "../../../global/components/Modal/Modal.vue";
import translate from "../../../global/directives/translate";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "AnsprechpartnerModalZuweisenMe",
  components: {
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    antragId: {
      type: String,
      default: undefined,
    },
    ansprechpartner: {
      type: Object,
      default: () => ({
        org: {},
      }),
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        list: [],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  created() {
    this.initList();
  },
  methods: {
    initList() {
      this.options.list.push({
        id: "temp",
        type: "readonly",
        text: "_TXT_MODAL_ANSPRECHPARTNER_ZUWEISEN_ME_ANSPRECHPARTNER_{{name}}_",
        extra: {
          name: this.ansprechpartner.org.o_name,
        }
      });
    },

    save() {
      this.status.loading = true;
      const data = {
        foerderorg: this.ansprechpartner.org.pk,
        nutzer: this.user.pk,
      };

      this.postHttp({ url: `foerderantraege/${ this.antragId }/ansprechpartner_aendern/`, data }).then(
        response => {
          this.addNotification({ text: "_MSG_MODAL_ANSPRECHPARTNER_ZUWEISEN_ME_SUCCESS_" });
          this.close({ response: response, status: true });
        },
        () => {
          this.addNotification({ text: "_MSG_MODAL_ANSPRECHPARTNER_ZUWEISEN_ME_ERROR_", type: "error" });
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
