import accessibility from "./accessibility.svg";
import amphitheater from "./amphitheater.svg";
import answer from "./answer.svg";
import arrowRight from "./arrow-right.svg";
import attention from "./attention.svg";
import bagEuro from "./bag-euro.svg";
import bankDetails from "./bank-details.svg";
import calendarAttention from "./calendar-attention.svg";
import calendarTime from "./calendar-time.svg";
import certificate from "./certificate.svg";
import chain from "./chain.svg";
import changes from "./changes.svg";
import check from "./check.svg";
import chevronDoubleDown from "./chevron-double-down.svg";
import chevronDoubleLeft from "./chevron-double-left.svg";
import chevronDoubleRight from "./chevron-double-right.svg";
import chevronDoubleUp from "./chevron-double-up.svg";
import chevronDown from "./chevron-down.svg";
import chevronLeft from "./chevron-left.svg";
import chevronRight from "./chevron-right.svg";
import chevronUp from "./chevron-up.svg";
import circle from "./circle.svg";
import close from "./close.svg";
import cog from "./cog.svg";
import comment from "./comment.svg";
import commentAttention from "./comment-attention.svg";
import commentText from "./comment-text.svg";
import communication from "./communication.svg";
import connected from "./connected.svg";
import contract from "./contract.svg";
import contractOk from "./contract-ok.svg";
import contracts from "./contracts.svg";
import contrast from "./contrast.svg";
import copy from "./copy.svg";
import details from "./details.svg";
import document from "./document.svg";
import documentEmpty from "./document-empty.svg";
import documentEuro from "./document-euro.svg";
import documentNew from "./document-new.svg";
import documentOk from "./document-ok.svg";
import documents from "./documents.svg";
import download from "./download.svg";
import drug from "./drug.svg";
import duplicate from "./duplicate.svg";
import earphone from "./earphone.svg";
import edit from "./edit.svg";
import education from "./education.svg";
import envelope from "./envelope.svg";
import envelopeBlack from "./envelope-black.svg";
import envelopeLetter from "./envelope-letter.svg";
import envelopeOk from "./envelope-ok.svg";
import envelopeOpen from "./envelope-open.svg";
import euro from "./euro.svg";
import euroCircle from "./euro-circle.svg";
import exportIcon from "./export.svg";
import eyeClose from "./eye-close.svg";
import eyeOpen from "./eye-open.svg";
import film from "./film.svg";
import filter from "./filter.svg";
import floppyDisk from "./floppy-disk.svg";
import heart from "./heart.svg";
import home from "./home.svg";
import importIcon from "./import.svg";
import infoCircle from "./info-circle.svg";
import infoSign from "./info-sign.svg";
import lock from "./lock.svg";
import lockOpen from "./lock-open.svg";
import login from "./login.svg";
import logout from "./logout.svg";
import map from "./map.svg";
import mapGroup from "./map-group.svg";
import meeting from "./meeting.svg";
import menuHamburger from "./menu-hamburger.svg";
import minus from "./minus.svg";
import minusCircle from "./minus-circle.svg";
import minusSign from "./minus-sign.svg";
import moreActions from "./more-actions.svg";
import ok from "./ok.svg";
import okWithComment from "./ok-with-comment.svg";
import open from "./open.svg";
import optionHorizontal from "./option-horizontal.svg";
import optionVertical from "./option-vertical.svg";
import payout from "./payout.svg";
import pdf from "./pdf.svg";
import pencil from "./pencil.svg";
import pencil2 from "./pencil-2.svg";
import people from "./people.svg";
import plus from "./plus.svg";
import plusCircle from "./plus-circle.svg";
import print from "./print.svg";
import pushpin from "./pushpin.svg";
import question from "./question.svg";
import receipt from "./receipt.svg";
import refresh from "./refresh.svg";
import repeat from "./repeat.svg";
import report from "./report.svg";
import request from "./request.svg";
import requests from "./requests.svg";
import reset from "./reset.svg";
import resizeFull from "./resize-full.svg";
import search from "./search.svg";
import share from "./share.svg";
import sortAsc from "./sort-asc.svg";
import split from "./split.svg";
import star from "./star.svg";
import starEmpty from "./star-empty.svg";
import start from "./start.svg";
import table from "./table.svg";
import thLarge from "./th-large.svg";
import time from "./time.svg";
import trash from "./trash.svg";
import unchecked from "./unchecked.svg";
import user from "./user.svg";

import iconsClientSpecific from "./__icons__";

const ICONS_CLIENT_SPECIFIC = iconsClientSpecific || {};

const ICONS = {
  accessibility,
  amphitheater,
  answer,
  "arrow-right": arrowRight,
  attention,
  "bag-euro": bagEuro,
  "bank-details": bankDetails,
  "calendar-attention": calendarAttention,
  "calendar-time": calendarTime,
  certificate,
  chain,
  changes,
  check,
  "chevron-double-down": chevronDoubleDown,
  "chevron-double-left": chevronDoubleLeft,
  "chevron-double-right": chevronDoubleRight,
  "chevron-double-up": chevronDoubleUp,
  "chevron-down": chevronDown,
  "chevron-left": chevronLeft,
  "chevron-right": chevronRight,
  "chevron-up": chevronUp,
  circle,
  close,
  cog,
  comment,
  "comment-attention": commentAttention,
  "comment-text": commentText,
  communication,
  connected,
  contract,
  "contract-ok": contractOk,
  contracts,
  contrast,
  copy,
  details,
  document,
  "document-empty": documentEmpty,
  "document-euro": documentEuro,
  "document-new": documentNew,
  "document-ok": documentOk,
  documents,
  download,
  drug,
  duplicate,
  earphone,
  edit,
  education,
  envelope,
  "envelope-black": envelopeBlack,
  "envelope-letter": envelopeLetter,
  "envelope-ok": envelopeOk,
  "envelope-open": envelopeOpen,
  euro,
  "euro-circle": euroCircle,
  export: exportIcon,
  "eye-close": eyeClose,
  "eye-open": eyeOpen,
  film,
  filter,
  "floppy-disk": floppyDisk,
  heart,
  home,
  import: importIcon,
  "info-circle": infoCircle,
  "info-sign": infoSign,
  lock,
  "lock-open": lockOpen,
  login,
  logout,
  map,
  "map-group": mapGroup,
  meeting,
  "menu-hamburger": menuHamburger,
  minus,
  "minus-circle": minusCircle,
  "minus-sign": minusSign,
  "more-actions": moreActions,
  ok,
  "ok-with-comment": okWithComment,
  open,
  "option-horizontal": optionHorizontal,
  "option-vertical": optionVertical,
  payout,
  pdf,
  pencil,
  "pencil-2": pencil2,
  people,
  plus,
  "plus-circle": plusCircle,
  print,
  pushpin,
  question,
  receipt,
  refresh,
  repeat,
  report,
  request,
  requests,
  reset,
  "resize-full": resizeFull,
  search,
  share,
  "sort-asc": sortAsc,
  split,
  star,
  "star-empty": starEmpty,
  start,
  table,
  "th-large": thLarge,
  time,
  trash,
  unchecked,
  user,
  ...ICONS_CLIENT_SPECIFIC,
};

export const StandardIcons = {
  ...ICONS,
};

export default {
  ...StandardIcons,
  organisation: ICONS.amphitheater,
  "semicircular-arrow-left": ICONS.answer,
  weiterleiten: ICONS["arrow-right"],
  warning: ICONS.attention,
  "neu-auszahlung": ICONS["bag-euro"],
  bankverbindung: ICONS["bank-details"],
  deadline: ICONS["calendar-attention"],
  "bald-zu-erledigen": ICONS["calendar-time"],
  sitzungsdokumente: ICONS.chain,
  "double-bottom": ICONS["chevron-double-down"],
  "double-left": ICONS["chevron-double-left"],
  zurueckreichen: ICONS["chevron-double-left"],
  send: ICONS["chevron-double-right"],
  einreichen: ICONS["chevron-double-right"],
  "double-up": ICONS["chevron-double-up"],
  "nicht-entschieden": ICONS.circle,
  konfiguration: ICONS.cog,
  kommentar: ICONS.comment,
  file: ICONS["comment-text"],
  regel: ICONS["comment-attention"],
  verbunden: ICONS.connected,
  vertrag: ICONS.contract,
  "foerdervertrag-bestaetigen": ICONS["contract-ok"],
  vertraege: ICONS.contracts,
  datei: ICONS.document,
  "empty-document": ICONS["document-empty"],
  foerderangebot: ICONS["document-euro"],
  "neuen-antrag": ICONS["document-new"],
  todos: ICONS["document-ok"],
  eingegangen: ICONS["envelope-black"],
  entwurf: ICONS["envelope-letter"],
  gesendet: ICONS["envelope-ok"],
  "message-read": ICONS["envelope-open"],
  salden: ICONS.euro,
  euro: ICONS["euro-circle"],
  "info-sign": ICONS["info-circle"],
  "info-black": ICONS["info-sign"],
  tagesordnung: ICONS.meeting,
  "changes-delete": ICONS["minus-circle"],
  abgelehnt: ICONS["minus-sign"],
  "option-vertical": ICONS["more-actions"],
  ok: ICONS.ok,
  bewilligung: ICONS.ok,
  "bewilligt-mit-auflage": ICONS["ok-with-comment"],
  "unterlagen-nachreichen": ICONS.open,
  "ellipsis-h": ICONS["option-horizontal"],
  "ellipsis-v": ICONS["option-vertical"],
  "auszahlung-anfordern": ICONS.payout,
  partner: ICONS.people,
  "changes-add": ICONS["plus-circle"],
  note: ICONS.pushpin,
  ersatzantrag: ICONS.question,
  "question-sign": ICONS.question,
  belege: ICONS.receipt,
  berichte: ICONS.report,
  antrag: ICONS.request,
  antraege: ICONS.requests,
  sorting: ICONS["sort-asc"],
  list: ICONS["th-large"],
  eye: ICONS["eye-open"],
  calendar: ICONS["calendar-time"],
};
