"use strict";

import BaseReadonlyController from "dias/ui/formsteps/formstepBaseReadonly.controller";

class AntraegeReadonlyController extends BaseReadonlyController {
  /*@ngInject*/
  constructor($scope, $q) {
    super($scope);

    const antragTableDeferred = $q.defer();
    this.antragTable = antragTableDeferred.promise;
    antragTableDeferred.resolve({
      dataSource: this.$scope.model.antraege.getList,
      columnDefs: [
        { label: "Nummer", field: "a_nr", linkToDetail: true },
        { label: "Titel", field: "a_titel" },
        { label: "Bewilligt",
          field: "a_zuschussbewilligt",
          cellFilter: "zeroNumber | currency",
          cellClass: "text-right",
        },
        { label: "Ausgezahlt",
          field: "ausgezahlt",
          cellFilter: "zeroNumber | currency",
          cellClass: "text-right",
        },
      ],
      emptyText: "Zu diesem Weiterleitungsvertrag existieren noch keine Anträge",
      getDetailRoute: row => "root.foerderantrag.details({id: '" + row.pk + "'})"

    });
  }
}

export default AntraegeReadonlyController;
