import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "ExportauftragJsonWizardAbschluss",
  components: {
    PuxButton,
    FormElement,
    PuxTranslate,
  },
  props: {
    reset: {
      type: Function,
      default: undefined,
    },
    downloadFile: {
      type: Function,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    updateFileName: {
      type: Function,
      default: undefined,
    },
  },
  setup() {
    const options = {
      id: "wizardstepabschluss_filename",
      type: "text",
      label: "_LBL_EXPORTAUFTRAG_JSON_WIZARD_STEP_ABSCHLUSS_WIZARD_FILENAME_",
      view: "v-alt",
      translate: "true",
    };
    return {
      options,
    };
  },
  methods: {
    onUpdateFileName({ model }) {
      this.updateFileName({ fileName: model });
    },
  },
};
