"use strict";


class FoerderorganisationUpdateController {
  /*@ngInject*/
  constructor($q, $scope, CategoriesTypUUIDs, DiasNotification, Katalog) {
    this.$q = $q;
    this.$scope = $scope;
    this.CategoriesTypUUIDs = CategoriesTypUUIDs;
    this.DiasNotification = DiasNotification;
    this.Katalog = Katalog;

    this.angebot = $scope.extras.angebot;
    this.model = {};
    this.categoriesObj = {};

    this.loading = true;

    this.loadFoerderfinderkategorien();

    this.save = () => {
      const data = { kategoriewerte: [] };
      angular.forEach(this.model, model => {
        if (angular.isObject(model)) {
          angular.forEach(model, (mod, key) => {
            if (mod === true) {
              data.kategoriewerte.push(key);
            }
          });
        } else if (model) {
          data.kategoriewerte.push(model);
        }
      });
      const defer = $q.defer();

      const promise = this.angebot.customPOST(data, "kategorien");
      promise.then(
        response => {
          this.DiasNotification.page.success("Kategorien erfolgreich aktualisiert");
          defer.resolve(response);
        },
        error => {
          defer.reject();
          this.errorsLabel = {
            foerderangebot: "Förderangebot: ",
            kategoriewerte: "Kategorien: ",
          };
          this.DiasNotification.form.clear();
          this.DiasNotification.form.error(error.data, "Bitte überprüfen Sie Ihre Eingaben", {}, this.errorsLabel);
          this.errors = error.data;
        }
      );
      return defer.promise;
    };
  }

  loadFoerderfinderkategorien() {
    this.$q.all([
      this.Katalog.getKatalog("foerderfinderkategorien", { ffk_deaktiviert: false }),
      this.Katalog.getKatalog("foerderfinderkategoriewerte", { fkw_deaktiviert: false })
    ]).then(
      responses => {
        this.categories = responses[0];
        this.categories.forEach(cat => {
          cat.werte = cat.werte.map(pk => responses[1].find(fkw => fkw.pk === pk)).filter(value => typeof(value) !== "undefined");
          cat.werte.sort((a, b) => a.fkw_pos - b.fkw_pos);
        });
        this.categories.sort((a, b) => a.ffk_pos - b.ffk_pos);
        this.initModel();
      }
    );
  }

  initModel() {
    angular.forEach(this.categories, cat => {
      this.categoriesObj[cat.pk] = cat;
      this.model[cat.pk] = {};
    });

    angular.forEach(this.angebot.kategoriewerte, cat => {
      if (!cat.fkw_deaktiviert && this.categoriesObj[cat.kategorie]) {
        if (this.model[cat.kategorie]) {
          this.model[cat.kategorie][cat.pk] = true;
        } else {
          this.model[cat.kategorie] = cat.pk;
        }
      }
    });

    this.loading = false;
  }
}

export default FoerderorganisationUpdateController;
