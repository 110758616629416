"use strict";

import BaseModel from "dias/core/models/baseModel";

class KBuchfuehrungsmethode extends BaseModel {
  constructor() {
    super("kbuchfuehrungsmethoden");
  }
}

export default KBuchfuehrungsmethode;
