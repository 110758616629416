"use strict";

import template from "./foerderorganisation.kennenlernen.jade";

class FoerderorganisationKennenlernenController {
  /*@ngInject*/
  constructor() { }
}

export default {
  template: template(),
  controller: FoerderorganisationKennenlernenController,
  controllerAs: "vm"
};
