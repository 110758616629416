"use strict";

import BetragsrechnerRegistry from "../services";

import BetragsrechnerBildung from "./betragsrechner.js";
import BetragsrechnerBildungController from "./controller";

const BetragsrechnerBildungModul = angular.module("dias.betragsrechner.BetragsrechnerBildungModul", [
  BetragsrechnerRegistry.name,
]);

BetragsrechnerBildungModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("bildungskostenrechner", BetragsrechnerBildung);
})
  .component("bildungkosten", BetragsrechnerBildungController);

export default BetragsrechnerBildungModul;
