"use strict";
import angular from "angular";

import coreModels from "dias/core/models";
import KAufgabe from "./kaufgabe.service";
import Workflow from "./workflow.service";
import WorkflowUI from "./workflow.ui.service";
import WorkflowTyp from "./workflowtyp.service";
import Workflowfolgen from "./workflowfolge.detail.service";
import Workflowfolgeclientfunktion from "./workflowfolgeclientfunktion.service";
import Workflowfolgeserverfunktion from "./workflowfolgeserverfunktion.service";
import Workflowfolgestatusfunktion from "./workflowfolgestatusfunktion.service";
import KAufgabenstatus from "./kaufgabenstatus.service";
import NutzerAufgabe from "./nutzeraufgabe.service";
import Workflowstatusgruppen from "./workflowstatusgruppen.service";

const modul = angular.module("workflow.models", [
  "restangular",
  coreModels.name,
])

  .provider("KAufgabe", KAufgabe)
  .provider("Workflow", Workflow)
  .provider("WorkflowUI", WorkflowUI)
  .provider("WorkflowTyp", WorkflowTyp)
  .provider("Workflowfolge", Workflowfolgen)
  .provider("KAufgabenstatus", KAufgabenstatus)
  .provider("Nutzeraufgabe", NutzerAufgabe)
  .provider("Workflowstatusgruppen", Workflowstatusgruppen)
  .provider("Workflowfolgeclientfunktion", Workflowfolgeclientfunktion)
  .provider("Workflowfolgeserverfunktion", Workflowfolgeserverfunktion)
  .provider("Workflowfolgestatusfunktion", Workflowfolgestatusfunktion)
;


export default modul;
