import HttpMixin from "../../../../global/mixins/HttpMixin";
import Modal from "../../../../global/components/Modal/Modal.vue";
import BelegeElementInListe from "./BelegeElementInListe/BelegeElementInListe.vue";
import translate from "../../../../global/directives/translate";
import {
  FilterCurrencyMixin,
  FilterDateMixin,
} from "../../../../global/mixins/FiltersMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";
import KVertragskostenbelegStatusUUIDs from "../../../../const/KVertragskostenbelegStatusUUIDs";
import {
  cloneDeep,
  forEach,
  filter,
  findIndex,
  sortBy,
  get,
} from "lodash-es";

// @vue/component
export default {
  components: {
    Modal,
    BelegeElementInListe,
  },
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterDateMixin,
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    position: {
      type: Object,
      required: true,
    },
    person: {
      type: Object,
      required: true,
    },
    vertragPk: {
      type: String,
      required: true,
    },
    vertragkosten: {
      type: String,
      required: true,
    },
    belege: {
      type: Object,
      default: undefined,
    },
    pruefer: {
      type: Boolean,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      model: {
        beleg: undefined,
      },
      options: {
        showCloseButton: true,
        required: true,
        autocomplete: "off",
        list: [],
      },
      status: {
        loading: undefined,
        loadingBelegnummer: false,
      },
      errors: undefined,
      errorsClone: undefined,
      currentBeleg: undefined,
      belegeList: undefined,
      zeitgrenzen: undefined,
      periodeListFiltered: undefined,
      notBefore: undefined,
      notAfter: undefined,
      hasNextBelegnummer: false,
    };
  },
  methods: {
    setBelegeList() {
      const BELEGE = [];
      forEach(cloneDeep(this.belege), belegPeriod => {
        forEach(belegPeriod, beleg => {
          if (this.pruefer || beleg.status.pk !== KVertragskostenbelegStatusUUIDs.GEPRUEFT) {
            BELEGE.push(beleg);
          }
        });
      });
      this.belegeList = sortBy(BELEGE, o => {
        if (!get(o, "argument_erfasst.von")) {
          return;
        }
        return new Date(o.argument_erfasst.von);
      });
    },

    setBelegeForOptionsList() {
      this.options.list.push({
        id: "beleg",
        type: "select",
        label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_BELEGE_",
        view: "v",
        keyId: "pk",
        data: this.belegeList,
        change: this.changeBeleg,
        deselect: false,
        slot: "beleg",
        translate: true,
      });
    },

    changeBeleg({ item }) {
      this.currentBeleg = cloneDeep(item);
    },

    filterPeriodeList() {
      this.periodeListFiltered = filter(this.periodeList, item => {
        return !(item.bis < this.zeitgrenzen.von || item.von > this.zeitgrenzen.bis);
      });
    },

    setZeitgrenzen() {
      let zeitgrenzen = this.getZeitgrenzenWithPeriod();
      zeitgrenzen = this.getZeitgrenzenWithPerson(zeitgrenzen);
      this.zeitgrenzen = zeitgrenzen;
    },

    getZeitgrenzenWithPeriod() {
      return ({
        von: this.period.von_aktuell,
        bis: this.period.bis_aktuell,
      });
    },

    getZeitgrenzenWithPerson({ von, bis }) {
      const VON_LOCAL = von > this.person.vpsp_beschaeftigt_von ? von : this.person.vpsp_beschaeftigt_von;
      let bisLocal = bis;
      if (this.person.von_aktuell) {
        bisLocal = bis < this.person.vpsp_beschaeftigt_bis ? bis : this.person.vpsp_beschaeftigt_bis;
      }
      return ({
        von: VON_LOCAL,
        bis: bisLocal,
      });
    },

    setParametersForDatepicker() {
      this.indexVon = findIndex(this.options.list, ["id", "von"]);
      this.indexBis = findIndex(this.options.list, ["id", "bis"]);

      this.setNotBeforeAfterAndDefaultValueForVonAndBis({
        von: this.zeitgrenzen.von,
        bis: this.zeitgrenzen.bis,
      });
    },

    setNotBeforeAfterAndDefaultValueForVonAndBis({ von, bis }) {
      this.setNotBeforeAfterAndDefaultValue({ index: this.indexVon, von, bis });
      this.setNotBeforeAfterAndDefaultValue({ index: this.indexBis, von, bis });
      this.notBefore = von;
      this.notAfter = bis;
    },

    setNotBeforeAfterAndDefaultValue({ index, von, bis }) {
      this.options.list[index].notBefore = von;
      this.options.list[index].defaultValue = von;
      this.options.list[index].notAfter = bis;
    },

    changePeriodeTeil({ item }) {
      const VON_LOCAL = item.von > this.zeitgrenzen.von ? item.von : this.zeitgrenzen.von;
      const BIS_LOCAL = !this.zeitgrenzen.bis || item.bis < this.zeitgrenzen.bis ? item.bis : this.zeitgrenzen.bis;
      this.setModelVonAndBis({
        von: VON_LOCAL,
        bis: BIS_LOCAL,
      });

      this.setNotBeforeAfterAndDefaultValueForVonAndBis({
        von: VON_LOCAL,
        bis: BIS_LOCAL,
      });
    },

    setModelVonAndBis({ von, bis }) {
      if (this.model.von) {
        if (this.model.von < von || this.model.von > bis) {
          this.model.von = undefined;
        }
      }
      if (this.model.bis) {
        if (this.model.bis < von || this.model.bis > bis) {
          this.model.bis = undefined;
        }
      }
    },
  },
};
