"use strict";

import angular from "angular";
import diasLoading from "dias/core/loading";

import mdm_terminreihe from "./mdm_terminreihe";


const mdm_foerdermodule = angular.module("dias.mdm_foerdermodule", [
  diasLoading.name,
  mdm_terminreihe.name,
]);


export default mdm_foerdermodule;
