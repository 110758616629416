"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import models from "./models";

import detailsComponent from "./details.component";

const modul = angular.module("dias.foerderantrag.cortexe.antragslisten", [
  uiRouter,
  models.name,
])

  .config(/*@ngInject*/$stateProvider => {
    $stateProvider

    // Liste
      .state("root.antragslisten", {
        url: "/antragslisten/",
        reloadOnSearch: false,
        views: {
          "main@root": {
            template: "<div vueinit><vue-antragsliste-list></vue-antragsliste-list></div>"
          }
        },
        data: {
          permissions: ["foerderantrag.cortex.antragslisten.ui"]
        }
      })
      .state("root.antragslisten.details", {
        url: ":id/",
        views: {
          "main@root": {
            template: "<antragslisten-details></antragslisten-details>"
          }
        },
        data: {
          permissions: ["foerderantrag.cortex.antragslisten.ui"]
        }
      })

    ;
  })

  .component("antragslistenDetails", detailsComponent)
;

export default modul;
