import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTooltip from "../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import { changeTextToId } from "../../../global/functions/utils";
import {
  EventBus,
} from "../../../global/functions/event-bus";
import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  components: {
    PuxIcon,
    PuxTooltip,
    PuxTranslate,
  },
  props: {
    argument: {
      type: Object,
      required: true,
    },
    pk: {
      type: String,
      required: true,
    },
    dashboard: {
      type: String,
      required: false,
      default: undefined,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    dashboardModuleIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      attributesAccessibility: {
        role: "alert",
        "aria-live": "polite",
        "aria-atomic": true,
      },
      dataLocal: undefined,
    };
  },
  computed: {
    idLocal() {
      return `dashboard_module_${ this.dashboardModuleIndex }`;
    },

    titel() {
      return this.argument.titel;
    },

    parameter() {
      return this.argument.parameter;
    },

    data() {
      return this.dataLocal || this.argument.data;
    },

    classForRobot() {
      return `test_dashboard_${ changeTextToId(this.titel) }`;
    },

    argumentClass() {
      return this.argument.class;
    },

    classFontSizeForHeading() {
      if (this.argumentClass === "1") {
        return "h5";
      }
      if (this.argumentClass === "2") {
        return "h4";
      }
      return "";
    },

    reloadeventReceivers() {
      return this.parameter && this.parameter.reloadevent_receivers || [];
    },

    idForBadge() {
      return `${ this.idLocal }_badge`;
    },

    idForHeader() {
      return `${ this.idLocal }_header`;
    },

    ariaLabelledbyForBadgeLink() {
      return `${ this.idForHeader } ${ this.idForBadge }`;
    },
  },
  created() {
    EventBus.$on(`reloadDashboard${ this.pk }`, this.reloadDashboardElement);
  },
  beforeUnmount() {
    EventBus.$off(`reloadDashboard${ this.pk }`, this.reloadDashboardElement);
  },
  methods: {
    sendReloadEvent({ payload = {} }) {
      forEach(this.reloadeventReceivers, pk => {
        EventBus.$emit(`reloadDashboard${ pk }`, payload);
      });
    },

    reloadDashboardElement(params = {}) {
      if (!this.dashboard) {
        return;
      }
      this.getHttp({
        url: `dashboard/${ this.dashboard }/elemente/${ this.pk }/`,
        urlParams: params
      }).then(
        response => {
          this.dataLocal = Object.assign({}, this.argument.data, response.data || {});
        },
        error => {
          this.dataLocal = undefined;
          console.error("Fehler beim Laden des Dashboardelements", this.pk, error);
        }
      );
    }
  },
};
