import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";

import moment from "moment";
import {
  cloneDeep,
  isNil,
  isNumber,
  parseInt,
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungPlanModalFristverlaengerung",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    vertragPk: {
      type: String,
      default: undefined,
    },
    auszahlungsplan: {
      type: Object,
      default: undefined,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      model: {
        aktuelle_frist: undefined,
        monate: undefined,
        neue_frist: undefined,
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "aktuelle_frist",
            type: "date",
            label: "_LBL_AUSZAHLUNGPLAN_IN_VERTRAG_FRISTVERLAENGERUNG_MODAL_AKTUELLE_FRIST_",
            helpText: "_TXT_AUSZAHLUNGPLAN_IN_VERTRAG_FRISTVERLAENGERUNG_MODAL_AKTUELLE_FRIST_HELP_TEXT_",
            required: false,
            view: "v",
            disabled: true,
            translate: true,
          },
          {
            id: "monate",
            type: "integer",
            label: "_LBL_AUSZAHLUNGPLAN_IN_VERTRAG_FRISTVERLAENGERUNG_MODAL_MONATE_",
            helpText: "_TXT_AUSZAHLUNGPLAN_IN_VERTRAG_FRISTVERLAENGERUNG_MODAL_MONATE_HELP_TEXT_",
            required: false,
            view: "v",
            translate: true,
            change: this.monateChanged,
          },
          {
            id: "neue_frist",
            type: "date",
            label: "_LBL_AUSZAHLUNGPLAN_IN_VERTRAG_FRISTVERLAENGERUNG_MODAL_NEUE_FRIST_",
            helpText: "_TXT_AUSZAHLUNGPLAN_IN_VERTRAG_FRISTVERLAENGERUNG_MODAL_NEUE_FRIST_HELP_TEXT_",
            required: true,
            view: "v",
            translate: true,
            change: this.neueFristChanged,
          }
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  created() {
    this.model.aktuelle_frist = this.auszahlungsplan.auszahlungsfrist;
  },
  methods: {
    onClose(status) {
      this.close({ status });
    },
    monateChanged({ model }) {
      const INT_MONATE = parseInt(model);
      if (isNumber(INT_MONATE)) {
        this.model.neue_frist = moment().add(INT_MONATE, "M").format("YYYY-MM-DD");
      } else {
        this.model.neue_frist = undefined;
      }
    },
    neueFristChanged({ model }) {
      this.model.neue_frist = model;
      if (!isNil(this.model.neue_frist)) {
        const DIFF = moment.duration(moment(model) - moment());
        this.model.monate = DIFF.months() + (DIFF.years() * 12);
      } else {
        this.model.monate = undefined;
      }
    },
    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      delete data.aktuelle_frist;
      this.putHttp({ url: `vertraege/${ this.vertragPk }/auszahlungsplaene/${ this.auszahlungsplan.pk }/frist_verlaengerung/`, data }).then(
        response => {
          Object.assign(this.auszahlungsplan, response);
          this.addNotification({ text: "_MSG_AUSZAHLUNGPLAN_IN_VERTRAG_FRISTVERLAENGERUNG_MODAL_SUCCESS_" });
          this.onClose(true);
        },
        error => {
          if (error && error.data) {
            this.errors = error.data;
            this.errorsClone = error.data;
          } else {
            this.addNotification({ text: "_MSG_AUSZAHLUNGPLAN_IN_VERTRAG_FRISTVERLAENGERUNG_MODAL_ERROR_", type: "error" });
          }
        }
      ).finally(
        () => this.status.loading = false
      );
    },
  },
};
