import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import translate from "../../../../../global/directives/translate";
import DynamischeZahlenElementMixin from "../DynamischeZahlenElementMixin";
import {
  cloneDeep, isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "DynamischeZahlenEditElement",
  components: {
    FormElement,
  },
  directives: {
    translate,
  },
  mixins: [
    DynamischeZahlenElementMixin,
  ],
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: [Number, String],
      default: undefined,
    },
    modelEdit: {
      type: Object,
      required: true,
    },
    modelParameter: {
      type: Object,
      required: true,
    },
    errors: {
      type: Array,
      default: undefined,
    },
    parentId: {
      type: String,
      required: true,
    },
    updateModel: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modelLocal: null,
    };
  },
  computed: {
    optionsFormElement() {
      if (this.data.signal_regex || this.data.expression) {
        return this.optionsFormElementSignalAndExpression;
      }
      return this.optionsFormElementFloat;
    },

    optionsFormElementSignalAndExpression() {
      return {
        id: this.data.id,
        htmlId: `${ this.parentId }_${ this.data.id }`,
        type: "template",
        view: "v-alt",
        label: this.data.label,
        tooltip: this.data.hilfetext,
        slot: "slot"
      };
    },

    optionsFormElementFloat() {
      return {
        id: this.data.id,
        htmlId: `${ this.parentId }_${ this.data.id }`,
        type: "float",
        view: "v-alt",
        placeholder: this.data.placeholder,
        label: this.data.label,
        max: this.data.max,
        min: this.data.min,
        digits: this.data.nachkommastellen || 0,
        required: isNil(this.data.pflichtfeld) ? true : this.data.pflichtfeld,
        addonBack: this.data.einheit,
        tooltip: this.data.hilfetext,
        clearModel: true
      };
    },
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      this.modelLocal = this.model || null;
    },

    changeModel() {
      const MODEL = cloneDeep(this.modelEdit);
      MODEL[this.data.pos] = this.modelLocal;
      this.updateModel({ model: MODEL });
    },
  },
};
