import AsideRightBox from "../../../global/components/AsideRightBox/AsideRightBox.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxDropdown from "../../../global/components/PuxDropdown/PuxDropdown.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SitzungModule from "../../Sitzung/SitzungModule/SitzungModule.vue";
import TagesordnungspunktAnlagen from "./TagesordnungspunktAnlagen/TagesordnungspunktAnlagen.vue";

import TagesordnungspunktAPI from "./compositionAPI/TagesordnungspunktAPI";
import TagesordnungspunktNotGroupedAPI from "./compositionAPI/TagesordnungspunktNotGroupedAPI";

// @vue/component
export default {
  name: "TagesordnungspunktDetails",
  components: {
    AsideRightBox,
    ContextPanel,
    Permission,
    PuxButton,
    PuxDropdown,
    PuxPageDetails,
    PuxTranslate,
    SitzungModule,
    TagesordnungspunktAnlagen,
  },
  setup() {
    const {
      loadData,
      loading,
      notizenWeitereObjekte,
      rubrics,
      sitzung,
      sitzungTagesordnungenUrl,
      tagesordnungspunkt,
      tagesordnungspunktModule,
      tagesordnungspunktModuleUrl,
      tagesordnungspunktUrl,
      tagesordnungspunktWithSitzungPermissions,
      updateModule,
    } = TagesordnungspunktAPI();

    const {
      extraHeaderH2Translate,
      pageTitleExtra,
      savePdfUebersicht,
    } = TagesordnungspunktNotGroupedAPI({
      sitzung,
      tagesordnungspunkt,
      tagesordnungspunktUrl,
    });

    loadData();

    return {
      loading,
      notizenWeitereObjekte,
      rubrics,
      sitzung,
      sitzungTagesordnungenUrl,
      tagesordnungspunkt,
      tagesordnungspunktModule,
      tagesordnungspunktModuleUrl,
      tagesordnungspunktWithSitzungPermissions,
      updateModule,

      extraHeaderH2Translate,
      pageTitleExtra,
      savePdfUebersicht,
    };
  },
  computed: {
    optionsAsideRightBoxTosInfo() {
      return {
        icon: "info-sign",
        active: true,
        toggle: false,
      };
    },

  }
};
