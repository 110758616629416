import {
  gettext,
} from "../functions/utils";
import {
  forEach,
} from "lodash-es";

const CLASS_OVERLAY = "loading_container__overlay";

function insertLoading({ el, value }) {
  const MESSAGE = gettext(value.msg || "_TXT_LOADING_");
  const STATUS_FOCUS = value.focus;
  const div = document.createElement("div");
  div.className = CLASS_OVERLAY;
  div.setAttribute("role", "status");
  div.setAttribute("tabindex", "-1");
  div.innerHTML = `
    <span class="loading_container__spinner_container ${ value.size || "xlg" } ${ value.align || "center" }">
      <span class="loading_container__msg_container ${ value.left ? "left" : "right" }">
        <i class="loading_container__msg_container__spinner spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </i>
        <span class="loading_container__msg_container__msg">${ MESSAGE }</span>
      </span>
    </span>`;
  el.appendChild(div);
  if (STATUS_FOCUS) {
    const LOADING_ELEMENT = el.querySelector(`.${ CLASS_OVERLAY }`);
    if (LOADING_ELEMENT) {
      LOADING_ELEMENT.focus();
    }
  }
}

export default {
  beforeMount(el, binding) {
    el.style.position = "relative";
    const status = binding.value.status;
    el.status = status;
    if (status) {
      insertLoading({ el, value: binding.value });
    }
  },
  updated(el, binding) {
    const status = binding.value.status;
    if (!el.status && status) {
      insertLoading({ el, value: binding.value });
      el.status = status;
    } else if (el.status && !status) {
      const CHILDREN = el.childNodes || [];
      forEach(CHILDREN, child => {
        if (child.className === CLASS_OVERLAY) {
          el.removeChild(child);
          return false;
        }
      });
    }
    el.status = status;
  },
};
