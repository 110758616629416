"use strict";

import BaseModel from "dias/core/models/baseModel";
import KTagesordnungspunkt from "./ktagesordnungspunkt";
import Anonymisierungsplaene from "dias/core/anonymisierung/models/anonymisierungsplaene.service";

class Sitzungsarten extends BaseModel {
  constructor() {
    super("sitzungsarten", {
      subPaths: {
        anonymisierungsplaene: new Anonymisierungsplaene(),
        tops: new KTagesordnungspunkt(),
      } });
  }
}

export default Sitzungsarten;
