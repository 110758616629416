"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";

export default class EditAntwortController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, Foerderfinderantworttypen, Foerderfinderfragen) {
    super($q, $scope, DiasNotification);
    Foerderfinderantworttypen.getList().then(result => {
      this.antworttypen = result;
    });
    Foerderfinderfragen.getList().then(result => {
      this.folgefragen = result;
    });


    this.save = () => {
      if (!this.model.folgefrage) {
        this.model.folgefrage = null;
      }
      return this.model.put(this.model).then(
        result => {
          $scope.model.merge($scope.model, result);
          DiasNotification.page.success("Änderungen erfolgreich gespeichert");
        },
        errors => {
          const errorsLabel = {
            ffa_text: "Text",
            ffa_ueberschrift: "Überschrift",
            ffa_info: "Info",
            ffa_erklaerung: "Erklärung",
            typ: "Antworttyp",
          };
          return this.onErrors(errors, errorsLabel);
        }
      );
    };
  }
}
