"use strict";
import angular from "angular";
import models from "./models";
import authService from "dias/auth/services";
import diasLoading from "dias/core/loading";
import dokumenteModels from "dias/dokumente/models";
import foerderantragModels from "dias/foerderantrag/models";
import teamModels from "dias/teams/models";
import foerderangeboteModels from "dias/foerderangebote/models";
import antragstellerModels from "dias/antragsteller/models";
import diasNotification from "dias/core/notification";
import diasConfirm from "dias/core/confirm";

import deckblattGenerieren from "dias/deckblatt/generieren";

const deckblattModule = angular.module("deckblatt", [
  models.name,
  diasLoading.name,
  authService.name,
  dokumenteModels.name,
  foerderantragModels.name,
  foerderangeboteModels.name,
  antragstellerModels.name,
  diasNotification.name,
  diasConfirm.name,
  teamModels.name,
  deckblattGenerieren.name,
]);

deckblattModule.config($stateProvider => {
  $stateProvider

    // Liste
    .state("root.deckblatt", {
      url: "/deckblatt/",
      params: {},
      views: {
        "main@root": {
          template: "<div vueinit><vue-deckblatt-list></vue-deckblatt-list></div>"
        }
      },
      data: {
        permissions: ["deckblatt.view"]
      }
    });
})
;
export default deckblattModule;
