"use strict";

import template from "./send.jade";
import detailTemplate from "./send.details.jade";
import detailController from "./send.details.controller";

class BenachrichtigungSendController {
  /*@ngInject*/
  constructor(diasModalDialog, Benachrichtigungen, KNutzerstatusUUIDs) {
    this.diasModalDialog = diasModalDialog;
    this.Benachrichtigungen = Benachrichtigungen;
    this.KNutzerstatusUUIDs = KNutzerstatusUUIDs;
    this.empfaenger = this.empfaenger || [];
    this.inactiveMsg = "";
    this.abwesendMsg = "";
    this.hasActive = this.hasActiveEmpfaenger();
    this.hasInactive = this.hasInActiveEmpfaenger();
    this.hasAbwesend = this.hasAbwesendEmpfaenger();
    this.notLink = this.notLink || !this.hasActive;
  }

  hasAbwesendEmpfaenger() {
    // Abwesenheitsinfo (nur bei einzelnen Empfängern anzeigen)
    if (this.empfaenger.length === 1 && this.empfaenger[0].abwesenheit) {
      this.abwesendMsg = this.empfaenger[0].abwesenheit;
      return true;
    }
    return false;
  }

  hasInActiveEmpfaenger() {
    let inactive = 0;
    for (let i = 0; i < this.empfaenger.length; i++) {
      if ((this.empfaenger[i].aktiv !== undefined && !this.empfaenger[i].aktiv) || (this.empfaenger[i].status && !this.KNutzerstatusUUIDs.isActive(this.empfaenger[i].status.ns_id))) {
        inactive++;
      }
    }
    if (inactive === 1 && this.empfaenger.length === 1) {
      this.inactiveMsg = "Nutzer gesperrt";
    } else if (inactive > 0 && inactive === this.empfaenger.length) {
      this.inactiveMsg = "Alle Nutzer gesperrt";
    } else if (inactive > 0) {
      this.inactiveMsg = `${ inactive } der ${ this.empfaenger.length } Nutzer gesperrt - Nachrichtenversand nur an aktive nutzer möglich`;
    }
    return inactive > 0;
  }

  hasActiveEmpfaenger() {
    for (let i = 0; i < this.empfaenger.length; i++) {
      if (this.empfaenger[i].aktiv || (this.empfaenger[i].status && this.KNutzerstatusUUIDs.isActive(this.empfaenger[i].status.ns_id))) {
        return true;
      }
    }
    return false;
  }

  getLinkTitel() {
    let linktitel = this.linktitel;
    if (!linktitel) {
      linktitel = "Eine Nachricht versenden";
      if (this.empfaenger.length === 1 && this.empfaenger[0]) {
        linktitel = linktitel + " an " + this.empfaenger[0].name;
      }
    }
    return linktitel;
  }

  getLinkLabel() {
    let linklabel = this.linklabel;
    if (!linklabel) {
      linklabel = "Nachricht senden";
      if (this.empfaenger.length === 1 && this.empfaenger[0]) {
        linklabel = this.empfaenger[0].name;
      }
    }
    return linklabel;
  }

  showSend() {
    const extras = {
      feste_empfaenger: this.empfaenger,
      bezug: this.bezug,
      bezugpk: this.bezugpk,
      bezugtext: this.bezugtext,
      savecallback: this.savecallback,
    };
    let title = "Nachricht versenden";
    if (this.empfaenger.length === 1) {
      title = title + " an " + this.empfaenger[0].name;
    }
    return this.diasModalDialog({
      title: title,
      template: detailTemplate,
      controller: detailController,
      extras: extras,
    });
  }
}

export default {
  template: template(),
  controller: BenachrichtigungSendController,
  controllerAs: "vm",
  bindings: {
    empfaenger: "=",
    bezug: "=?",
    bezugpk: "=?",
    bezugtext: "=?",
    savecallback: "&?",
    linklabel: "=?",
    linktitel: "=?",
    linkclass: "=?",
    notLink: "<?",
  },
};
