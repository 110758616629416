"use strict";

import template from "./table.simple.jade";
import baseController from "./table.controller";


const nutzerverwaltungComponent = () => {
  return {
    restrict: "EA",
    scope: {
      config: "=",
      hidePagination: "<?"
    },
    template,
    controller: baseController,
    controllerAs: "vm",
    bindToController: true
  };
};

export default nutzerverwaltungComponent;
