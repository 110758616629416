import BelegDokumentSelectItem from "../BelegDokumentSelectItem/BelegDokumentSelectItem.vue";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";

import {
  assign,
  cloneDeep,
  get,
  isNil,
  isNumber,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "KofiErfassungBelegFormExtraField",
  components: {
    BelegDokumentSelectItem,
    FormElement,
  },
  props: {
    field: {
      type: Object,
      required: true,
      info: "Hauptinformation",
    },
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
      info: "Model",
    },
    errors: {
      type: Array,
      required: false,
      default: undefined,
      info: "Fehlerinformation",
    },
    classesHorizontal: {
      type: Array,
      required: false,
      default: undefined,
      info: "CSS-Klasse für form-element",
    },
    belegDokumente: {
      type: Array,
      required: true,
      info: "Belegdokumente",
    },
    belegDokumenteAnzahl: {
      type: Object,
      required: false,
      info: "Mapping dok_id zur Anzahl der Belege, in der dieses Dokument benutzt wird",
      default: () => ({}),
    },
    apiAntragDokumente: {
      type: String,
      required: true,
      info: "API foerderantraege/{{ AntragPk }}/dokumente/?dokart={{ dokart }}",
    },
    view: {
      type: String,
      required: false,
      default: "h",
      info: "view für form-element"
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  emits: [
    "changeModel",
    "reloadBelegDokumente",
  ],
  data() {
    return {
      statusButtonDokumentDisabled: false,
      dokumentSelectWidth: undefined,
      dokumentContainerWidth: undefined,
    };
  },
  computed: {
    transformedField() {
      const FIELD = cloneDeep(this.field);
      FIELD.helpText = FIELD.help_text;
      FIELD.addonBack = FIELD.suffix;
      FIELD.trueLabel = FIELD.true_label;
      FIELD.falseLabel = FIELD.false_label;
      delete FIELD.suffix;
      return FIELD;
    },

    isDocument() {
      return this.field.type === "document";
    },

    optionsNotDokument() {
      return assign({}, {
        type: "text",
        view: this.view,
        classesHorizontal: this.classesHorizontal,
        required: false,
        change: this.changeModel,
      }, this.transformedField);
    },

    optionsDokumentTemplate() {
      return assign({
        required: false,
      }, this.transformedField, {
        type: "template",
        view: this.view,
        classesHorizontal: this.classesHorizontal,
        slot: "dokument",
      });
    },

    optionsDokumentSelect() {
      return assign({
        required: false
      }, this.transformedField, {
        type: "select",
        keyId: "pk",
        label: undefined,
        helpText: undefined,
        change: this.changeModel,
        deselect: true,
        placeholder: this.belegDokumente.length ? "_TXT_DATEI_AUSWAEHLEN_" : "_TXT_BITTE_DATEI_HOCHLADEN_",
        statusHideErrorsBottom: true,
        slot: "dokumente-select",
        menuWidthAll: false,
        menuStyles: `max-width: ${ this.dokumentContainerWidth }; min-width: ${ this.dokumentSelectWidth };`,
        elementItemClass: "mw-100",
        placement: "bottom-start",
        open: this.setDokumentWidth,
      });
    },

    optionsDokument() {
      return {
        id: `${ this.field.id }_button`,
        htmlId: `${ this.field.htmlId }_button`,
        type: "document",
        saveUrl: this.apiAntragDokumente,
        saveStart: this.saveStartDokument,
        saveDone: this.saveDoneDokument,
        change: this.loadDocument,
        progressTeleport: this.dokumentProgressId,
        buttonText: "_BTN_DATEI_HOCHLADEN_",
      };
    },

    dokumentProgressId() {
      return `${ this.field.htmlId }_progress`;
    },
  },
  created() {
    this.initValue();
  },
  methods: {
    saveStartDokument() {
      this.statusButtonDokumentDisabled = true;
    },

    saveDoneDokument() {
      this.statusButtonDokumentDisabled = false;
    },

    loadDocument({ model }) {
      if (model && model.pk) {
        this.changeModel({ model: model.pk, id: this.field.id });
      }
      this.$emit("reloadBelegDokumente");
    },

    initValue() {
      if (!isNil(this.field.default_value) && isUndefined(this.model)) {
        this.$emit("changeModel", { model: this.field.default_value, id: this.field.id });
      }
    },

    changeModel({ model, id }) {
      this.$emit("changeModel", { model, id });
    },

    setDokumentWidth() {
      this.dokumentContainerWidth = this.getPixelsOrAuto(get(this.$refs, "dokument_container.clientWidth"));
      this.dokumentSelectWidth = this.getPixelsOrAuto(get(this.$refs, "dokument_select.clientWidth"));
    },

    getPixelsOrAuto(val) {
      return isNumber(val) ? `${ val }px` : "auto";
    },
  },
};
