"use strict";

import BaseModel from "dias/core/models/baseModel";

import Workflowfolgen from "dias/workflow/models/workflowfolge.service";

class Objektaufgabe extends BaseModel {
  constructor() {
    super("aufgaben", {
      subPaths: {
        workflowfolgen: new Workflowfolgen(),
      }
    });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.manuelleAufgabeErstellen = model => {
      return collection.customPOST(model, "manuelle_aufgabe_erstellen");
    };
    return collection;
  }
}

export default Objektaufgabe;
