
"use strict";

import RichtextDirective from "./richtext.directive";
import Services from "./services";

const diasRichtext = angular.module("dias.richtext", [
  Services.name
])

  .directive("diasRichtext", RichtextDirective)
;

export default diasRichtext;
