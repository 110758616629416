import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  assign,
} from "lodash-es";

// @vue/component
export default {
  name: "AnonymisierungModalBlockierenUndFreigeben",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    auftrag: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
    statusBlockieren: {
      type: Boolean,
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      status: {
        loading: undefined,
      },
    };
  },
  computed: {
    header() {
      return this.statusBlockieren ? "_TXT_ANONYMISIERUNG_MODAL_BLOCKIEREN_HEADER_" : "_TXT_ANONYMISIERUNG_MODAL_FREIGEBEN_HEADER_";
    },

    modalBodyTranslate() {
      return this.statusBlockieren ? "_HTML_ANONYMISIERUNG_MODAL_BLOCKIEREN_BODY_{{objektname}}_{{termin}}_" : "_HTML_ANONYMISIERUNG_MODAL_FREIGEBEN_BODY_{{objektname}}_{{termin}}_";
    },

    extraTranslate() {
      return {
        objektname: this.auftrag.ano_objektname,
        termin: this.auftrag.ano_termin,
      };
    },

    buttonSaveTranslate() {
      return this.statusBlockieren ? "_BTN_ANONYMISIERUNG_MODAL_BLOCKIEREN_" : "_BTN_ANONYMISIERUNG_MODAL_FREIGEBEN_";
    },
  },
  methods: {
    blockierenUndFreigeben() {
      this.status.loading = true;
      this.putHttp({ url: `anonymisierungsauftraege/${ this.auftrag.pk }/`, data: { ano_blockieren: this.statusBlockieren } }).then(
        response => {
          this.addNotification({
            text: this.statusBlockieren ? "_MSG_ANONYMISIERUNG_MODAL_BLOCKIEREN_SUCCESS_" : "_MSG_ANONYMISIERUNG_MODAL_FREIGEBEN_SUCCESS_",
          });
          const newRow = assign({}, this.auftrag, response);
          this.close({ newRow });
        },
        () => this.addNotification({ text: "_MSG_ANONYMISIERUNG_MODAL_BLOCKIEREN_FREIGEBEN_ERROR_", type: "error" })
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
