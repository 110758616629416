import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  getAllStringsFromJson,
} from "../../../../global/functions/utils";
import {
  isEmpty,
} from "lodash-es";

// @vue/component
export default {
  name: "ObjectErrorModule",
  components: {
    PuxTranslate,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "goToModule",
  ],
  computed: {
    allErrorsString() {
      return getAllStringsFromJson(this.errorsLocal);
    },

    hasErrors() {
      return !isEmpty(this.errorsLocal);
    },

    errorsLocal() {
      return this.error.errors || {};
    },
  },
  methods: {
    goToModuleLocal() {
      this.$emit("goToModule", {
        error: this.error,
      });
    },
  },
};
