import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";
import Modal from "../../../global/components/Modal/Modal.vue";
import translate from "../../../global/directives/translate";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import {
  cloneDeep,
  forEach,
  find,
} from "lodash-es";

// @vue/component
export default {
  name: "VertretungsberechtigteModalCreate",
  components: {
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    SyConfigMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    url: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      default: -1,
    },
    isVertrag: {
      type: Boolean,
    },
  },
  data() {
    return {
      model: {},
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
      vertreterFieldsRequired: {},
    };
  },
  computed: {
    options() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "anrede",
            type: "select",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_ANREDE_",
            required: !!this.vertreterFieldsRequired.anrede,
            view: "v",
            keyId: "id",
            keyLabel: "ank_kbez",
            url: "katalog/?fields=id&fields=ank_kbez&key=kanrede",
            translate: true,
          },
          {
            id: "av_titel",
            type: "text",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_TITEL_",
            maxlength: 200,
            view: "v",
            translate: true,
            required: !!this.vertreterFieldsRequired.av_titel,
            hideIf: false,
          },
          {
            id: "titel",
            type: "select",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_TITEL_",
            view: "v",
            keyId: "pk",
            keyLabel: "kti_kbez",
            url: "katalog/?key=titel",
            translate: true,
            required: !!this.vertreterFieldsRequired.av_titel,
            hideIf: false,
            deselect: true,
          },
          {
            id: "av_vorname",
            type: "text",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_VORNAME_",
            maxlength: 200,
            view: "v",
            required: !!this.vertreterFieldsRequired.av_vorname,
            translate: true,
          },
          {
            id: "av_name",
            type: "text",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_NACHNAME_",
            maxlength: 200,
            view: "v",
            required: !!this.vertreterFieldsRequired.av_name,
            translate: true,
          },
          {
            id: "av_email",
            type: "text",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_EMAIL_",
            maxlength: 200,
            view: "v",
            required: !!this.vertreterFieldsRequired.av_email,
            translate: true,
          },
          {
            id: "av_funktion",
            type: "text",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_FUNKTION_",
            maxlength: 100,
            view: "v",
            required: !!this.vertreterFieldsRequired.av_funktion,
            translate: true,
          },
          {
            id: "berechtigung",
            type: "select",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_BERECHTIGUNG_",
            keyId: "id",
            keyLabel: "kvb_kbez",
            url: "katalog/?fields=id&fields=kvb_kbez&key=vertretungsberechtigung",
            view: "v",
            required: !!this.vertreterFieldsRequired.berechtigung,
            translate: true,
          },
          {
            id: "av_gueltig_von",
            type: "date",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_GUELTIG_VON_",
            view: "v",
            required: !!this.vertreterFieldsRequired.av_gueltig_von,
            translate: true,
          },
          {
            id: "av_gueltig_bis",
            type: "date",
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_GUELTIG_BIS_",
            view: "v",
            required: !!this.vertreterFieldsRequired.av_gueltig_bis,
            translate: true,
          },
        ],
      };
    },
  },
  created() {
    this.setVertreterFields();
  },
  methods: {
    setVertreterFields() {
      const CONFIG = this.getSyConfigsValue("vertretungsberechtigte") || {};
      const PFLICHTFELDER = CONFIG.pflichtfelder || [];
      const VERTRETER_FIELDS_REQUIRED = {};
      forEach(PFLICHTFELDER, item => {
        VERTRETER_FIELDS_REQUIRED[item] = true;
      });
      this.vertreterFieldsRequired = VERTRETER_FIELDS_REQUIRED;
      find(this.options.list, ["id", "av_titel"]).hideIf = CONFIG.titel_katalog || CONFIG.hide_titel || false ;
      find(this.options.list, ["id", "titel"]).hideIf = !CONFIG.titel_katalog || CONFIG.hide_titel || false ;
    },

    save() {
      this.status.loading = true;
      const DATA = cloneDeep(this.model);
      if (DATA.av_allein_berechtigt) {
        DATA.av_allein_berechtigt = DATA.av_allein_berechtigt === "true";
      }
      if (this.isVertrag) {
        DATA.isvertrag = true;
      }
      this.postHttp({ url: this.url, data: DATA }).then(
        response => {
          this.addNotification({ text: "_MSG_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_SUCCESS_" });
          if (this.index !== -1) {
            const INDEX = this.index;
            this.close({ response: response, index: INDEX });
          } else {
            this.close({ response: response });
          }
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
