import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragsnummerExtern",
  components: {
    SnapshotModule,
    SnapshotIcon,
  },
  mixins: [
    ModuleMixin,
  ],
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.editablePermission = "foerderantrag.nr_extern.update";
      OPTIONS.list = [
        this.aNrExternOptions,
      ];
      return OPTIONS;
    },

    aNrExternOptions() {
      const ID = "a_nr_extern";
      return {
        id: ID,
        htmlId: `${ this.htmlRegelNummer }_${ ID }`,
        type: "text",
        label: "_LBL_GR_ANTRAGSNUMMER_EXTERN_",
        required: true,
        view: "v",
        editonly: true,
        translate: true,
        startupFocus: true,
      };
    },

    labelHeader() {
      if (this.modelParameter.titel) {
        return this.modelParameter.titel;
      }
      return "_LBL_GR_ANTRAGSNUMMER_EXTERN_";
    },

    statusDiff() {
      if (!this.snapshotModule) {
        return;
      }
      return this.snapshotModule.a_nr_extern !== this.model.a_nr_extern;
    },
  },
};
