import PersonalkostenMitPktWizard from "./Modules/PersonalkostenMitPktWizard/PersonalkostenMitPktWizard.vue";
import PersonalkostenPeriodenWizard from "./Modules/PersonalkostenPeriodenWizard/PersonalkostenPeriodenWizard.vue";

import BelegWizardModuleMappingMixin from "./BelegWizardModuleMappingMixin";
import BelegWizardModulePropsMixin from "./BelegWizardModulePropsMixin";

// @vue/component
export default {
  name: "WizardModule",
  components: {
    PersonalkostenMitPktWizard,
    PersonalkostenPeriodenWizard,
  },
  mixins: [
    BelegWizardModuleMappingMixin,
    BelegWizardModulePropsMixin,
  ],
  props: {
    betragsrechner: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    currentModule() {
      return this.wizardModules[this.betragsrechner];
    },
  },
};
