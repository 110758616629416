import BerichtStandard from "./BerichtStandard/BerichtStandard.vue";
import BerichtFoerderantrag from "./BerichtFoerderantrag/BerichtFoerderantrag.vue";
import BerichtLiegezeiten from "./BerichtLiegezeiten/BerichtLiegezeiten.vue";
import BerichtNutzerDatenschutzfragen from "./BerichtNutzerDatenschutzfragen/BerichtNutzerDatenschutzfragen.vue";
// AM
import BerichtCoronaAntraege from "./AM/BerichtCoronaAntraege/BerichtCoronaAntraege.vue";
import BerichtHandlungsfelder from "./AM/BerichtHandlungsfelder/BerichtHandlungsfelder.vue";
import BerichtRueckforderungen from "./AM/BerichtRueckforderungen/BerichtRueckforderungen.vue";
import BerichtVertraegeSchlagworte from "./AM/BerichtVertraegeSchlagworte/BerichtVertraegeSchlagworte.vue";
import BerichtKuraQM from "./AM/BerichtKuraQM/BerichtKuraQM.vue";
import BerichtAstorg from "./AM/BerichtAstorg/BerichtAstorg.vue";
import BerichtSchoeneProjekte from "./AM/BerichtSchoeneProjekte/BerichtSchoeneProjekte.vue";
import BerichtVertraegeAltfaelle from "./AM/BerichtVertraegeAltfaelle/BerichtVertraegeAltfaelle.vue";
// AM /
// AR
import BerichtAkkreditierungsantraege from "./AR/BerichtAkkreditierungsantraege/BerichtAkkreditierungsantraege.vue";
import BerichtAntragscontrolling from "./AR/BerichtAntragscontrolling/BerichtAntragscontrolling.vue";
import BerichtLexware from "./AR/BerichtLexware/BerichtLexware.vue";
// AR /
// MDM
import BerichtRohschnitt from "./MDM/BerichtRohschnitt/BerichtRohschnitt.vue";
import BerichtFoerderantragMdm from "./MDM/BerichtFoerderantragMdm/BerichtFoerderantragMdm.vue";
import BerichtProduktionsspiegelMdm from "./MDM/BerichtProduktionsspiegelMdm/BerichtProduktionsspiegelMdm.vue";
// MDM /
// KUMASTA
import BerichtFoerderantragKumasta from "./KUMASTA/BerichtFoerderantragKumasta/BerichtFoerderantragKumasta.vue";
import BerichtFoerderungKumasta from "./KUMASTA/BerichtFoerderungKumasta/BerichtFoerderungKumasta.vue";
// KUMASTA /
// DVV
import BerichtErhebungsbogen from "./DVV/BerichtErhebungsbogen/BerichtErhebungsbogen.vue";
import BerichtFoerderantragDvv from "./DVV/BerichtFoerderantragDvv/BerichtFoerderantragDvv.vue";
import BerichtFormblattKurse from "./DVV/BerichtFormblattKurse/BerichtFormblattKurse.vue";
import BerichtFormblattSonstige from "./DVV/BerichtFormblattSonstige/BerichtFormblattSonstige.vue";
import BerichtHorizonTourZ from "./DVV/BerichtHorizonTourZ/BerichtHorizonTourZ.vue";
import BerichtHorizonTourFb from "./DVV/BerichtHorizonTourFb/BerichtHorizonTourFb.vue";
import BerichtMeKuDvv from "./DVV/BerichtMeKuDvv/BerichtMeKuDvv.vue";
import BerichtMeKuErhebungsbogen from "./DVV/BerichtMeKuErhebungsbogen/BerichtMeKuErhebungsbogen.vue";
import BerichtMeKuFormblatt from "./DVV/BerichtMeKuFormblatt/BerichtMeKuFormblatt.vue";
// DVV /
// EG
import BerichtEntscheidungslisteEpIB from "./EG/BerichtEntscheidungslisteEpIB/BerichtEntscheidungslisteEpIB.vue";
import BerichtEntscheidungslisteAPNRW from "./EG/BerichtEntscheidungslisteAPNRW/BerichtEntscheidungslisteAPNRW.vue";
import BerichtFinanzuebersichtEpIB from "./EG/BerichtFinanzuebersichtEpIB/BerichtFinanzuebersichtEpIB.vue";
import BerichtFinanzuebersichtAPNRW from "./EG/BerichtFinanzuebersichtAPNRW/BerichtFinanzuebersichtAPNRW.vue";
import BerichtGesamtfinanzplanZFD from "./EG/BerichtGesamtfinanzplanZFD/BerichtGesamtfinanzplanZFD.vue";
import BerichtPortfolioskizzeZFD from "./EG/BerichtPortfolioskizzeZFD/BerichtPortfolioskizzeZFD.vue";
import BerichtProjektantraegeZFD from "./EG/BerichtProjektantraegeZFD/BerichtProjektantraegeZFD.vue";
// EG /
// DSEE
import BerichtMinisterien from "./DSEE/BerichtMinisterien/BerichtMinisterien.vue";
// DSEE /

export default {
  bericht_standard: BerichtStandard,
  bericht_foerderantrag: BerichtFoerderantrag,
  bericht_nutzer_datenschutzfragen: BerichtNutzerDatenschutzfragen,
  // AM
  bericht_corona_antraege: BerichtCoronaAntraege,
  bericht_handlungsfelder: BerichtHandlungsfelder,
  bericht_rueckforderungen: BerichtRueckforderungen,
  bericht_vertraege_schlagworte: BerichtVertraegeSchlagworte,
  bericht_kura_qm: BerichtKuraQM,
  bericht_astorg_am: BerichtAstorg,
  bericht_liegezeiten_antraege: BerichtLiegezeiten,
  bericht_schoene_projekte: BerichtSchoeneProjekte,
  bericht_vertraege_altfaelle: BerichtVertraegeAltfaelle,
  // AM /
  // AR
  bericht_akkreditierungsantraege: BerichtAkkreditierungsantraege,
  bericht_antragscontrolling_ar: BerichtAntragscontrolling,
  bericht_lexware_ar: BerichtLexware,
  // AR /
  // MDM
  bericht_rohschnitt_mdm: BerichtRohschnitt,
  bericht_foerderantrag_mdm: BerichtFoerderantragMdm,
  bericht_produktionsspiegel_mdm: BerichtProduktionsspiegelMdm,
  // MDM /
  // KUMASTA
  bericht_foerderantrag_kumasta: BerichtFoerderantragKumasta,
  bericht_foerderung_kumasta: BerichtFoerderungKumasta,
  // KUMASTA /
  // DVV
  bericht_erhebungsbogen: BerichtErhebungsbogen,
  bericht_foerderantrag_dvv: BerichtFoerderantragDvv,
  bericht_formblatt_kurse: BerichtFormblattKurse,
  bericht_formblatt_sonstige: BerichtFormblattSonstige,
  bericht_horizontour_z: BerichtHorizonTourZ,
  bericht_horizontour_fb: BerichtHorizonTourFb,
  bericht_me_ku_dvv: BerichtMeKuDvv,
  bericht_me_ku_erhebungsbogen: BerichtMeKuErhebungsbogen,
  bericht_me_ku_formblatt: BerichtMeKuFormblatt,
  // DVV /
  // EG
  eg_entscheidungsliste_epib: BerichtEntscheidungslisteEpIB,
  eg_entscheidungsliste_apnrw: BerichtEntscheidungslisteAPNRW,
  eg_finanzuebersicht_epib: BerichtFinanzuebersichtEpIB,
  eg_finzanzuebersicht_ap_nrw: BerichtFinanzuebersichtAPNRW,
  eg_gesamtfinanzplan_zfd: BerichtGesamtfinanzplanZFD,
  eg_fportfolioskizze_zfd: BerichtPortfolioskizzeZFD,
  eg_projektantraege_zfd: BerichtProjektantraegeZFD,
  // EG /
  // DSEE
  bericht_ministerien: BerichtMinisterien,
  // DSEE /

};
