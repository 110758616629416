import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import CodeModalAusfuehren from "../CodeModalAusfuehren/CodeModalAusfuehren.vue";
import CodeModalDeaktivieren from "../CodeModalDeaktivieren/CodeModalDeaktivieren.vue";
import CodeModalGueltigkeit from "../CodeModalGueltigkeit/CodeModalGueltigkeit.vue";
import CodeModalReaktivieren from "../CodeModalReaktivieren/CodeModalReaktivieren.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import NotificationMixin from "../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import { EventBus } from "../../../global/functions/event-bus";
import getTableApi from "../../../const/TableApis";
import {
  assign,
} from "lodash-es";

const TABLE_ID = "codes_all";

// @vue/component
export default {
  name: "CodeList",
  components: {
    AngularLink,
    CodeModalAusfuehren,
    CodeModalDeaktivieren,
    CodeModalGueltigkeit,
    CodeModalReaktivieren,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    NotificationMixin,
    PermissionMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_TXT_CODE_TABLE_ACTIONS_VALID_DATE_",
            callback: this.askCodeValidDate,
            isHidden: ({ item }) => !this.canUpdateGueltigkeit({ item }),
          },
          {
            label: "_TXT_CODE_TABLE_ACTIONS_EXECUTE_",
            callback: this.askCodeExecute,
            isHidden: ({ item }) => !this.canExecute({ item }),
          },
          {
            label: "_TXT_CODE_TABLE_ACTIONS_DEACTIVATE_",
            callback: this.askCodeDeactivate,
            isHidden: ({ item }) => !this.canUpdateDeaktivieren({ item }),
          },
          {
            label: "_TXT_CODE_TABLE_ACTIONS_REACTIVATE_",
            callback: this.askCodeReactivate,
            isHidden: ({ item }) => !this.canUpdateReaktivieren({ item }),
          },
        ],
        actions: [],
        label: "Codes",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      modal: {
        deactivate: undefined,
        reactivate: undefined,
        validDate: undefined,
        execute: undefined
      },
    };
  },
  computed: {
    translationTxtHeader() {
      return {
        text: "_TXT_CODE_TABLE_HEADER_"
      };
    },
  },
  methods: {
    canUpdate({ item }) {
      return this.checkPermissionsSync({ perm: "codes.update", permArray: item.user_permissions });
    },

    canExecute({ item }) {
      return !item.co_ausgefuehrt_am && item.co_eingeloest_am && !item.co_deaktiviert && this.checkPermissionsSync({ perm: "codes.manual.ausfuehren", permArray: item.user_permissions });
    },

    canUpdateGueltigkeit({ item }) {
      return !item.co_eingeloest_am && !item.co_deaktiviert && this.canUpdate({ item });
    },

    canUpdateDeaktivieren({ item }) {
      return !item.co_ausgefuehrt_am && !item.co_deaktiviert && this.canUpdate({ item });
    },

    canUpdateReaktivieren({ item }) {
      return !item.co_ausgefuehrt_am && item.co_deaktiviert && this.canUpdate({ item });
    },

    askCodeValidDate({ item, index }) {
      this.currentRow = item;
      this.currentIndex = index;
      this.modal.validDate = true;
    },

    askCodeExecute({ item, index }) {
      this.currentRow = item;
      this.currentIndex = index;
      this.modal.execute = true;
    },

    askCodeDeactivate({ item, index }) {
      this.currentRow = item;
      this.currentIndex = index;
      this.modal.deactivate = true;
    },

    askCodeReactivate({ item, index }) {
      this.currentRow = item;
      this.currentIndex = index;
      this.modal.reactivate = true;
    },

    updateRow({ response }) {
      response = response || this.currentRow;
      if (response) {
        const row = assign({}, this.currentRow, response);
        EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row, index: this.currentIndex }));
      }
    },

    closeModalValidDate({ response } = {}) {
      this.modal.validDate = false;
      this.updateRow({ response });
    },

    closeModalExecute({ response } = {}) {
      this.modal.execute = false;
      this.updateRow({ response });
    },

    closeModalDeactivate({ response } = {}) {
      this.modal.deactivate = false;
      this.updateRow({ response });
    },

    closeModalReactivate({ response } = {}) {
      this.modal.reactivate = false;
      this.updateRow({ response });
    },

    getHref({ obj, herkunftField }) {
      const MAP = {
        foerderantrag: "root.foerderantrag.details",
        antragstellerorganisation: "root.astorganisationen.details",
      };
      return MAP[obj[herkunftField]];
    },
  },
};
