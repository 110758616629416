"use strict";
import moment from "moment";
import BaseModel from "dias/core/models/baseModel";

class Nutzer extends BaseModel {
  constructor() {
    super("nutzer", {
      subPaths: { antragstellerorganisationen: "antragstellerorganisationen",
                  organisationen: "organisationen",
                  antragstellerorgrollen: "antragstellerorgrollen",
                  foerderorgrollen: "foerderorgrollen",
                  organisationsnutzer: "organisationsnutzer"
      }
    });
  }

  extendModel(model) {
    model = super.extendModel(model);
    model.getFullName = this.getFullName.bind(model);
    model.getFullNameWithTitle = this.getFullNameWithTitle.bind(model);
    // TODO (eddy): Provisorische Lösung für das Sprint-Review 4 (Teil von DAM-2169).
    // Generell ist diese Lösung hier und der API-Endpunkt
    // "/nutzer/<n_id>/antragstellerorganisationen/" suboptimal.
    // Der API-Endpunkt kann z.B. keine Paginierung (wegen MultipleModelMixin??).
    model.antragstellerorganisationen_freigegeben = model.all(
      "antragstellerorganisationen/?freigegeben="
    );
    // model.addRestangularMethod("antragstellerorganisationen_freigegeben",
    //  "get", "antragstellerorganisationen", {freigegeben:""});
    model.sperren = () => {
      return model.customPOST({}, "sperren");
    };
    model.aktivieren = () => {
      return model.customPOST({}, "aktivieren");
    };
    model.registrierungsmailVerschicken = () => {
      return model.customPOST({}, "registrierungsmail_verschicken");
    };
    model.anonymise = () => {
      return model.customPOST({}, "anonymisieren");
    };

    return model;
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.anonymiseAll = () => {
      return collection.customPOST({}, "alle_anonymisieren");
    };

    return collection;
  }

  extendRoleModel(model, prefix) {
    model = super.extendModel(model);
    model.deactivate = () => model.customPOST({}, "deactivate");
    model.getDaterange = () => {
      return {
        von: model[`${ prefix }_gueltig_ab`] ? moment(model[`${ prefix }_gueltig_ab`]).toDate() : undefined,
        bis: model[`${ prefix }_gueltig_bis`] ? moment(model[`${ prefix }_gueltig_bis`]).toDate() : undefined
      };
    };
    model.saveDaterange = daterange => {
      const data = {};
      data[`${ prefix }_gueltig_bis`] = daterange.bis;
      return model.customPUT(data);
    };
    return model;
  }

  antragstellerorgrollenExtendModel(model) {
    return this.extendRoleModel(model, "anr");
  }

  foerderorgrollenExtendModel(model) {
    return this.extendRoleModel(model, "fnr");
  }

  antragstellerorganisationenExtendCollection(collection) {
    collection = super.getDefaultExtendCollection("pk")(collection);
    collection.getByAntragstellerId = astId => {
      for (let i = 0; i < collection.length; i++) {
        if (collection[i].antragstellerorg.pk === astId) {
          return collection[i];
        }
      }
      return undefined;
    };
    return collection;
  }

  getFullName() {
    const parts = [];
    if (this.n_vorname) {
      parts.push(this.n_vorname); 
    }
    if (this.n_nachname) {
      parts.push(this.n_nachname); 
    }
    if (parts.length === 0) {
      parts.push(this.n_loginname); 
    }
    return parts.join(" ");
  }
  getFullNameWithTitle() {
    const parts = [];
    if (this.n_titel) {
      parts.push(this.n_titel); 
    }
    if (this.n_vorname) {
      parts.push(this.n_vorname); 
    }
    if (this.n_nachname) {
      parts.push(this.n_nachname); 
    }
    if (parts.length === 0) {
      parts.push(this.n_loginname); 
    }
    return parts.join(" ");
  }
}

export default Nutzer;
