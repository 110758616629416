"use strict";

class AntragaufgabeEditWiedervorlageController {
	/*@ngInject*/
  constructor($scope, $q, DiasNotification, Nutzeraufgabe) {
    this.DiasNotification = DiasNotification;

    this.aufgabe = $scope.extras.aufgabe;

    this.model = {
      wiedervorlage: this.aufgabe.wiedervorlage
    };

    this.save = () => {
      const defer = $q.defer();

      Nutzeraufgabe.one(this.aufgabe.pk).customPUT(this.model, "wiedervorlage_aendern").then(
        response => {
          this.aufgabe.wiedervorlage = response.wiedervorlage;
          defer.resolve(response);
        },
        error => {
          defer.reject(error);
          this.onError(error);
        }
      );

      return defer.promise;
    };

    this.onError = error => {
      this.DiasNotification.form.clear();
      this.DiasNotification.form.error(
        error.data,
        "Bitte überprüfen Sie Ihre Eingaben"
      );
      this.errors = error.data;
    };
  }
}

export default AntragaufgabeEditWiedervorlageController;
