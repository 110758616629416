import BetragsrechnerMixin from "../Mixins/BetragsrechnerMixin";

// @vue/component
export default {
  name: "StandardKostenrechner",
  mixins: [
    BetragsrechnerMixin,
  ],
  emits: [
    "isBezeichnung",
  ],
  data() {
    return {
      labelBezeichnung: "_LBL_STANDARD_KOSTENRECHNER_BEZEICHNUNG_",
      labelWert: "_LBL_STANDARD_KOSTENRECHNER_WERT_",
    };
  },
  computed: {
    optionsBezeichnung() {
      return {
        id: "bezeichnung",
        htmlId: this.htmlIdBezeichnung,
        label: this.labelBezeichnung,
        view: this.view,
        type: "text",
        maxlength: 300,
        required: true,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdBezeichnung() {
      return `${ this.htmlId }_bezeichnung`;
    },

    optionsWert() {
      return {
        id: "wert",
        htmlId: this.htmlIdWert,
        label: this.labelWert,
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdWert() {
      return `${ this.htmlId }_wert`;
    },

    errorLabels() {
      return {
        bezeichnung: {
          label: this.labelBezeichnung,
          link: true,
          id: this.htmlIdBezeichnung,
        },
        wert: {
          label: this.labelWert,
          link: true,
          id: this.htmlIdWert,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
    this.$emit("isBezeichnung");
  },
};
