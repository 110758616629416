import {
  ref,
} from "vue";

import {
  cloneDeep,
} from "lodash-es";

export default function SitzungsartTagesordnungModulFunctionsAPI({
  module = ref([]),
}) {
  const isDetailsVisible = ref({});
  const toggleDetails = modulPk => {
    isDetailsVisible.value[modulPk] = !isDetailsVisible.value[modulPk];
  };

  const buttonUpPrefixId = "sitzungsart_tagesordnung_up_";
  const moveUpModul = index => {
    if (index === 0) {
      return;
    }
    const currentModul = cloneDeep(module.value[index]);
    module.value.splice(index, 1);
    module.value.splice(index - 1, 0, currentModul);
    setTimeout(() => {
      $(`#${ buttonUpPrefixId }${ index - 1 }`).focus();
    });
  };

  const buttonDownPrefixId = "sitzungsart_tagesordnung_down_";
  const moveDownModul = index => {
    if (index >= module.value.length - 1) {
      return;
    }
    const currentModul = cloneDeep(module.value[index]);
    module.value.splice(index, 1);
    module.value.splice(index + 1, 0, currentModul);
    setTimeout(() => {
      $(`#${ buttonDownPrefixId }${ index + 1 }`).focus();
    });
  };

  const confirmShow = ref(undefined);
  const confirmOptions = ref(undefined);
  const selectorCloseDelete = ref(undefined);
  const buttonDeletePrefixId = "sitzungsart_tagesordnung_delete_";
  const closeConfirmDelete = () => {
    confirmShow.value = false;
    confirmOptions.value = undefined;
  };

  const removeModul = index => {
    module.value.splice(index, 1);
    closeConfirmDelete();
  };
  const openConfirmRemoveModul = (index, modul) => {
    selectorCloseDelete.value = `#${ buttonDeletePrefixId }${ index }`;
    confirmOptions.value = {
      title: "_TXT_SITZUNGSART_TAGESORDNUNGSPUNKT_MODUL_ENTFERNEN_",
      msg: "_TXT_SITZUNGSART_TAGESORDNUNGSPUNKT_MODUL_ENTFERNEN_MSG_{{tom_kbez}}_",
      okLabel: "_BTN_FORTFAHREN_",
      okCallback: () => removeModul(index),
      cancelCallback: closeConfirmDelete,
      okClass: "btn btn-primary",
      extra: {
        tom_kbez: modul.tom_kbez,
      },
    };
    confirmShow.value = true;
  };

  const buttonAddId = "sitzungsart_tagesordnung_add";
  const updateModul = ({ modul, modulIndex }) => {
    module.value.splice(modulIndex, 1, modul);
  };
  const createModul = ({ modul }) => {
    module.value.push(cloneDeep(modul));
  };

  return {
    buttonAddId,
    buttonDeletePrefixId,
    buttonDownPrefixId,
    buttonUpPrefixId,
    confirmOptions,
    confirmShow,
    createModul,
    isDetailsVisible,
    moveDownModul,
    moveUpModul,
    openConfirmRemoveModul,
    selectorCloseDelete,
    toggleDetails,
    updateModul,
  };
}
