import {
  isNil,
} from "lodash-es";

import { createNamespacedHelpers } from "vuex";
const {
  mapGetters,
} = createNamespacedHelpers("syConfigs");

export default {
  computed: {
    ...mapGetters([
      "GET_VALUE",
    ]),
  },
  methods: {
    getSyConfigsValue(value, defaultValue) {
      const SY_CONFIG_VALUE = this.GET_VALUE(value);
      return isNil(SY_CONFIG_VALUE) ? defaultValue : SY_CONFIG_VALUE;
    },
  },
};
