import {
  cloneDeep,
  keyBy,
} from "lodash-es";

export default {
  namespaced: true,
  state() {
    return {
      SNAPSHOT_KF: undefined,
      SNAPSHOT_FINANZIERUNGSARTEN_BY_AFA_ID: {},
      SNAPSHOT_KOSTENARTEN_BY_AKA_ID: {},
      SNAPSHOT_ZUSCHUSS: {},
    };
  },
  getters: {
    SNAPSHOT_FINANZIERUNGSARTEN_BY_AFA_ID_GET: state => {
      return state.SNAPSHOT_FINANZIERUNGSARTEN_BY_AFA_ID;
    },

    SNAPSHOT_KOSTENARTEN_BY_AKA_ID_GET: state => {
      return state.SNAPSHOT_KOSTENARTEN_BY_AKA_ID;
    },

    SNAPSHOT_ZUSCHUSS_GET: state => {
      return state.SNAPSHOT_ZUSCHUSS;
    },
  },
  mutations: {
    ADD_SNAPSHOT_KF_MUT(state, { snapshot }) {
      if (state.SNAPSHOT_KF && state.SNAPSHOT_KF.pk === snapshot.pk) {
        return;
      }
      const SNAPSHOT_LOCAL = snapshot.asn_snapshot || snapshot.ats_snapshot || {};
      const SNAPSHOT_LOCAL_KOFI = cloneDeep(SNAPSHOT_LOCAL.kofi) || {};
      state.SNAPSHOT_KF = {
        pk: SNAPSHOT_LOCAL_KOFI.pk,
        finanzierungsarten: SNAPSHOT_LOCAL_KOFI.finanzierungsarten,
        kostenarten: SNAPSHOT_LOCAL_KOFI.kostenarten,
        zuschuss: SNAPSHOT_LOCAL_KOFI.zuschuss,
      };
      state.SNAPSHOT_FINANZIERUNGSARTEN_BY_AFA_ID = keyBy(SNAPSHOT_LOCAL_KOFI.finanzierungsarten, "afa_id");
      state.SNAPSHOT_KOSTENARTEN_BY_AKA_ID = keyBy(SNAPSHOT_LOCAL_KOFI.kostenarten, "aka_id");
      state.SNAPSHOT_ZUSCHUSS = SNAPSHOT_LOCAL_KOFI.zuschuss;
    },

    REMOVE_SNAPSHOT_KF_MUT(state) {
      state.SNAPSHOT_KF = undefined;
      state.SNAPSHOT_FINANZIERUNGSARTEN_BY_AFA_ID = {};
      state.SNAPSHOT_KOSTENARTEN_BY_AKA_ID = {};
      state.SNAPSHOT_ZUSCHUSS = {};
    },
  },
};
