import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";

// @vue/component
export default {
  name: "PersonalPanelComponentMixin",
  components: {
    FormstepDetail,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    optionsMixin() {
      return {
        editable: this.editable,
      };
    },
  },
  methods: {
    getAktivIconProps({ aktiv }) {
      const ICON = {};
      if (aktiv) {
        ICON.icon = "glyphicon-ok";
        ICON.iconClass = "text-success";
        ICON.iconTooltip = "_TXT_JA_";
      } else {
        ICON.icon = "glyphicon-close";
        ICON.iconClass = "text-danger";
        ICON.iconTooltip = "_TXT_NEIN_";
      }
      return ICON;
    },
  }
};
