import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import SmartTable from "../../../global/components/table/table.vue";
import getTableApi from "../../../const/TableApis";
import WorkflowfolgenModalCreate from "../WorkflowfolgenModalCreate/WorkflowfolgenModalCreate.vue";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";

const TABLE_ID = "workflowfolgen";

// @vue/component
export default {
  name: "WorkflowfolgenList",
  components: {
    AngularLink,
    SmartTable,
    WorkflowfolgenModalCreate,
    PageTabTitle,
  },
  mixins: [
    PermissionMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [
          {
            label: this.$gettext("Name"),
            id: "wfo_kbez",
            sortable: true,
            sortId: "wfo_kbez",
            slot: "wfo_kbez",
          },
          {
            label: this.$gettext("Name in Administration"),
            id: "wfo_kbez_admin",
            sortable: true,
            sortId: "wfo_kbez_admin",
            slot: "wfo_kbez_admin",
          },
          {
            label: this.$gettext("Ausgangsaufgabe"),
            id: "aufgabe__au_kbezdetail",
            sortable: true,
            sortId: "aufgabe__au_kbezdetail",
            slot: "aufgabe__au_kbezdetail",
          },
          {
            label: this.$gettext("Nachfolgeaufgabe"),
            id: "nachfolge_aufgabe__au_kbezdetail",
            sortable: true,
            sortId: "nachfolge_aufgabe__au_kbezdetail",
            slot: "nachfolge_aufgabe__au_kbezdetail",
          },
          {
            label: this.$gettext("Workflow"),
            id: "workflow__wf_kbez",
            sortable: true,
            sortId: "workflow__wf_kbez",
            slot: "workflow__wf_kbez",
          },
          {
            label: this.$gettext("Nachfolgeworkflow"),
            id: "nachfolge_workflow__wf_kbez",
            sortable: true,
            sortId: "nachfolge_workflow__wf_kbez",
            slot: "nachfolge_workflow__wf_kbez",
          },
          {
            label: this.$gettext("Clientfunktion"),
            id: "clientfunktion",
            hide: true,
            slot: "clientfunktion",
          },
          {
            label: this.$gettext("Clientfunktion-Modul"),
            id: "clientfunktion__clientfunktion",
            hide: true,
            templateCallback: ({ row }) => row.clientfunktion_obj && row.clientfunktion_obj.modul_bez
          },
          {
            label: this.$gettext("Statusfunktionen"),
            id: "statusfunktionen",
            hide: true,
            templateCallback: ({ row }) => {
              if (!row.statusfunktionen_objs || row.statusfunktionen_objs.length === 0) {
                return "-";
              }
              let str = "";
              if (this.perms["statusfunktion.ui"]) {
                row.statusfunktionen_objs.forEach(
                  wts => str = `${ str }<li><a href="/workflowfolgestatusfunktionen/${ wts.pk }/">${ wts.wts_bez }</a></li>`
                );
              } else {
                row.statusfunktionen_objs.forEach(
                  wts => str = `${ str }<li>${ wts.wts_bez }</li>`
                );
              }
              return `<ul>${ str }</ul>`;
            }
          },
          {
            label: this.$gettext("Statusfunktionen-Module"),
            id: "statusfunktionen__statusfunktionen",
            hide: true,
            templateCallback: ({ row }) => {
              if (!row.statusfunktionen_objs || row.statusfunktionen_objs.length === 0) {
                return "-";
              }
              let str = "";
              row.statusfunktionen_objs.forEach(
                wts => str = `${ str }<li>${ wts.modul_bez }</li>`
              );
              return `<ul>${ str }</ul>`;
            }
          },
          {
            label: this.$gettext("Serverfunktionen"),
            id: "serverfunktion",
            hide: true,
            templateCallback: ({ row }) => {
              if (!row.serverfunktionen_objs || row.serverfunktionen_objs.length === 0) {
                return "-";
              }
              let str = "";
              if (this.perms["serverfunktion.ui"]) {
                row.serverfunktionen_objs.forEach(
                  wfs => str = `${ str }<li><a href="/workflowfolgeserverfunktionen/${ wfs.pk }/">${ wfs.wfs_bez }</a></li>`
                );
              } else {
                row.serverfunktionen_objs.forEach(
                  wfs => str = `${ str }<li>${ wfs.wfs_bez }</li>`
                );
              }
              return `<ol>${ str }</ol>`;
            }
          },
          {
            label: this.$gettext("Serverfunktionen-Module"),
            id: "serverfunktion__serverfunktion",
            hide: true,
            templateCallback: ({ row }) => {
              if (!row.serverfunktionen_objs || row.serverfunktionen_objs.length === 0) {
                return "-";
              }
              let str = "";
              row.serverfunktionen_objs.forEach(
                wfs => str = `${ str }<li>${ wfs.modul_bez }</li>`
              );
              return `<ol>${ str }</ol>`;
            }
          },
          {
            label: this.$gettext("Abarbeitungsschlange"),
            id: "queue_bez",
            sortable: true,
            sortId: "queue__tq_kbez",
            permission: ["workflowfolge.view.queue"],
            hide: true
          },
          {
            label: this.$gettext("Position"),
            id: "wfo_pos",
            sortable: true,
            sortId: "wfo_pos",
            hide: true
          },
          {
            label: this.$gettext("Anzeige-Styling"),
            id: "wfo_anzeige_typ",
            sortable: true,
            sortId: "wfo_anzeige_typ",
            hide: true
          },
          {
            label: this.$gettext("Icon"),
            id: "wfo_anzeige_icon",
            sortable: true,
            sortId: "wfo_anzeige_icon",
            hide: true
          },
        ],
        rowActions: [
          {
            label: this.$gettext("Details anzeigen"),
            route: "root.workflowfolgen.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [
          {
            label: this.$gettext("Neue Workflowfolge erstellen"),
            callback: () => this.modal.create = true,
            permission: "workflowfolge.create",
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "workflowfolge_create",
          },
        ],
        label: "Workflowfolgen",
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
        filterKeyGroupList: [
          this.$gettext("Allgemein"),
          this.$gettext("Ausführungskonfiguration"),
        ],
      },
      filters: [
        {
          type: "text",
          id: "suche",
          label: this.$gettext("Name"),
          main: true,
          group: this.$gettext("Allgemein"),
        },
        {
          type: "multiselect",
          id: "aufgabe",
          url: "katalog/?key=kaufgabe",
          label: this.$gettext("Ausgangsaufgabe"),
          keyLabel: "bez",
          keyId: "pk",
          search: true,
          searchList: ["bez"],
          group: this.$gettext("Allgemein"),
        },
        {
          type: "multiselect",
          id: "nachfolge_aufgabe",
          url: "katalog/?key=kaufgabe",
          label: this.$gettext("Nachfolgeaufgabe"),
          keyLabel: "bez",
          keyId: "pk",
          search: true,
          searchList: ["bez"],
          group: this.$gettext("Allgemein"),
        },
        {
          type: "multiselect",
          id: "workflow",
          url: "katalog/?key=workflow",
          label: this.$gettext("Workflow"),
          keyLabel: "bez",
          keyId: "pk",
          keyGroup: "group",
          search: true,
          searchList: ["bez"],
          group: this.$gettext("Allgemein"),
        },
        {
          type: "multiselect",
          id: "nachfolge_workflow",
          url: "katalog/?key=workflow",
          label: this.$gettext("Nachfolgeworkflow"),
          keyLabel: "bez",
          keyId: "pk",
          keyGroup: "group",
          search: true,
          searchList: ["bez"],
          group: this.$gettext("Allgemein"),
        },
        {
          type: "multiselect",
          id: "queue",
          url: "katalog/?key=taskqueues",
          label: this.$gettext("Abarbeitungsschlange"),
          keyLabel: "tq_kbez",
          keyId: "pk",
          search: true,
          searchList: ["tq_kbez"],
          permission: ["workflowfolge.view.queue"],
          group: this.$gettext("Ausführungskonfiguration"),
        },
        {
          type: "multiselect",
          id: "clientfunktion",
          url: "katalog/?key=clientfunktionen",
          label: this.$gettext("Clientfunktion"),
          keyLabel: "bez",
          keyId: "pk",
          keyGroup: "group",
          search: true,
          searchList: ["bez"],
          group: this.$gettext("Ausführungskonfiguration"),
        },
        {
          type: "multiselect",
          id: "sy_clientfunktion",
          url: "katalog/?key=clientfunktion_module",
          label: this.$gettext("Clientfunktion-Modul"),
          keyLabel: "bez",
          keyId: "pk",
          search: true,
          searchList: ["bez"],
          group: this.$gettext("Ausführungskonfiguration"),
        },
        {
          type: "multiselect",
          id: "statusfunktionen",
          url: "katalog/?key=statusfunktionen",
          label: this.$gettext("Statusfunktion"),
          keyLabel: "bez",
          keyId: "pk",
          keyGroup: "group",
          search: true,
          searchList: ["bez"],
          group: this.$gettext("Ausführungskonfiguration"),
        },
        {
          type: "multiselect",
          id: "sy_statusfunktionen",
          url: "katalog/?key=statusfunktion_module",
          label: this.$gettext("Statusfunktionen-Module"),
          keyLabel: "bez",
          keyId: "pk",
          search: true,
          searchList: ["bez"],
          group: this.$gettext("Ausführungskonfiguration"),
        },
        {
          type: "multiselect",
          id: "serverfunktionen",
          url: "katalog/?key=serverfunktionen",
          label: this.$gettext("Serverfunktion"),
          keyLabel: "bez",
          keyId: "pk",
          keyGroup: "group",
          search: true,
          searchList: ["bez"],
          group: this.$gettext("Ausführungskonfiguration"),
        },
        {
          type: "multiselect",
          id: "sy_serverfunktionen",
          url: "katalog/?key=serverfunktion_module",
          label: this.$gettext("Serverfunktionen-Module"),
          keyLabel: "bez",
          keyId: "pk",
          search: true,
          searchList: ["bez"],
          group: this.$gettext("Ausführungskonfiguration"),
        },
      ],
      modal: {
        create: undefined,
      },
      perms: {},
    };
  },
  created() {
    this.perms = {
      "workflow.ui": this.checkPermissionsSync({ perm: "workflow.ui" }),
      "kaufgabe.ui": this.checkPermissionsSync({ perm: "kaufgabe.ui" }),
      "clientfunktion.ui": this.checkPermissionsSync({ perm: "clientfunktion.ui" }),
      "statusfunktion.ui": this.checkPermissionsSync({ perm: "statusfunktion.ui" }),
      "serverfunktion.ui": this.checkPermissionsSync({ perm: "serverfunktion.ui" }),
    };
  },
  methods: {
    closeModalCreate() {
      this.modal.create = false;
    },
  },
};
