import HttpMixin from "../../../global/mixins/HttpMixin";
import Spinner from "../../../global/components/Spinner/Spinner.vue";
import FormElement from "../../../global/components/FormElement/FormElement.vue";
import translate from "../../../global/directives/translate";

import {
  forEach,
  cloneDeep,
  isArray,
  isObject,
  size,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "AnsprechpartnerModalZuweisen",
  components: {
    FormElement,
    Spinner
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    antragId: {
      type: String,
      default: undefined,
    },
    ansprechpartner: {
      type: [Object, Array],
      default: () => [],
    },
    errors: {
      type: Object,
      default: undefined,
    },
    changed: {
      type: Function,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: {
        init: true,
      },
      types: [],
      orgs: [],
      fields: {},
      model: {},
      errorsClone: {},
      errorMap: {}
    };
  },
  computed: {
    translationLoadingMsg() {
      return { html: "_MSG_ANSPRECHPARTNER_ZUWEISEN_INITIALES_LADEN_" };
    }
  },
  watch: {
    ansprechpartner: {
      handler() {
        this.initAnsprechpartner();
      },
      deep: true
    },
    errors() {
      this.errorsClone = {};
      forEach(this.errors, (err, idx) => {
        const mapped = this.errorMap[idx];
        if (mapped && size(err)) {
          this.errorsClone[mapped.org][mapped.typ] = err;
        }
      });
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loading.init = true;
      Promise.all([
        this.getHttp({
          url: `foerderantraege/${ this.antragId }/ansprechpartner_orgs/`
        }).then(
          response => {
            this.orgs = cloneDeep(response);
          }
        ),
        this.getHttp({
          url: `katalog/?key=antragansprechpartnertypen`
        }).then(
          response => {
            this.types = cloneDeep(response);
          }
        ),
      ]).then(
        () => {
          this.initModels();
          this.initFields();
        }
      ).finally(
        () => this.loading.init = false
      );
    },

    onUpdateModel() {
      if (!this.changed) {
        return;
      }
      const ansprechpartner = [];
      forEach(this.model, org_group => {
        forEach(org_group, fsp => {
          if (fsp.n_id) {
            ansprechpartner.push(cloneDeep(fsp));
            this.errorMap[ansprechpartner.length - 1] = {
              org: fsp.o_id,
              typ: fsp.adt_id
            };
          }
        });
      });
      this.changed(ansprechpartner);
    },

    initAnsprechpartner() {
      let fsps = this.ansprechpartner;
      if (!isArray(fsps) && isObject(fsps)) {
        fsps = [fsps];
      }
      forEach(fsps, fsp => {
        this.model[fsp.org.pk] = this.model[fsp.org.pk] || {};
        this.model[fsp.org.pk][fsp.typ.pk] = this.model[fsp.org.pk][fsp.typ.pk] || {};
        this.model[fsp.org.pk][fsp.typ.pk].fsp_kommentar = fsp.fsp_kommentar;
        this.model[fsp.org.pk][fsp.typ.pk].adt_id = fsp.typ.pk;
        this.model[fsp.org.pk][fsp.typ.pk].o_id = fsp.org.pk;
        this.model[fsp.org.pk][fsp.typ.pk].n_id = fsp.nutzer.pk;
        this.model[fsp.org.pk][fsp.typ.pk].fsp_pos = fsp.fsp_pos;
        this.model[fsp.org.pk][fsp.typ.pk].antrag = fsp.antrag;
        this.model[fsp.org.pk][fsp.typ.pk].pk = fsp.pk;
      });
      this.onUpdateModel();
    },

    initModels() {
      const MODEL = {};
      forEach(this.orgs, org => {
        MODEL[org.pk] = MODEL[org.pk] || {};
        forEach(this.types, typ => {
          MODEL[org.pk][typ.pk] = MODEL[org.pk][typ.pk] || {
            fsp_kommentar: null,
            n_id: null,
            adt_id: typ.pk,
            o_id: org.pk,
            fsp_pos: 0,
            antrag: this.antragId,
          };
        });
      });
      this.model = MODEL;
      this.initAnsprechpartner();
    },

    getErrors(org, typ) {
      if (isNil(this.errorsClone) || isNil(this.errorsClone[org])) {
        return;
      }
      return this.errorsClone[org][typ];
    },

    initFields() {
      const FIELDS = {};
      forEach(this.orgs, org => {
        FIELDS[org.pk] = FIELDS[org.pk] || {};
        forEach(this.types, typ => {
          FIELDS[org.pk][typ.pk] = {
            n_id: {
              id: `${ org.pk }_${ typ.pk }_n_id`,
              type: "select",
              label: "_LBL_ANSPRECHPARTNER_ZUWEISEN_NUTZER_",
              required: false,
              view: "v",
              keyId: "pk",
              keyLabelCallback: ({ item }) => {
                if (item.abwesenheit) {
                  return `${ item.name } - ${ item.abwesenheit ? `(${ item.abwesenheit })` : "" }`;
                }
                return item.name;
              },
              menuWidthAll: true,
              url: `foerderantraege/${ this.antragId }/ansprechpartner_auswahl/?aspsuche_foerderorg=${ org.pk }`,
              search: true,
              searchList: ["item.name", "item.abwesenheit"],
              // searchParameter: "aspsuche_suche",
              // searchGlobal: true,
              translate: true,
            },
            fsp_kommentar: {
              id: `${ org.pk }_${ typ.pk }_fsp_kommentar`,
              type: "text",
              label: "_LBL_ANSPRECHPARTNER_ZUWEISEN_KOMMENTAR_",
              required: false,
              view: "v",
              translate: true,
            },
          };
        });
      });
      this.fields = FIELDS;
    },
  },
};
