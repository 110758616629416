import DashboardAntragErstellenModalCreate from "./DashboardAntragErstellenModalCreate/DashboardAntragErstellenModalCreate.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";

import DashboardIconMixin from "../DashboardIconMixin";
import DashboardModuleMixin from "../DashboardModuleMixin";
import DashboardStylesMixin from "../DashboardStylesMixin";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "DashboardAntragErstellen",
  components: {
    DashboardAntragErstellenModalCreate,
    Modal,
    PuxButton,
  },
  mixins: [
    DashboardIconMixin,
    DashboardModuleMixin,
    DashboardStylesMixin,
  ],
  data() {
    return {
      modalConfirmShow: false,
      modalCreateShow: false,
      modalConfirmOptions: {
        okClass: "btn btn-primary",
        title: "_TXT_DASHBOARD_ANTRAG_ERSTELLEN_MODAL_CONFIRM_HEADER_{{angebot_bez}}_",
        msg: "_HTML_DASHBOARD_ANTRAG_ERSTELLEN_MODAL_CONFIRM_MSG_{{angebot_bez}}_",
        okLabel: "_BTN_ERSTELLEN_",
        cancelLabel: "_BTN_CANCEL_",
        extra: undefined,
        okCallback: () => {
          this.modalConfirmShow = false;
          this.modalCreateShow = true;
        },
        cancelCallback: () => this.modalConfirmShow = false,
        loading: false,
      },
    };
  },
  computed: {
    canCreateAntrag() {
      return get(this, "data.can_create", false);
    },

    classDisabled() {
      if (!this.canCreateAntrag) {
        return "disabled";
      }
      return "";
    },

    tagLocal() {
      return this.canCreateAntrag ? "a" : "div";
    },

    hoverText() {
      if (this.canCreateAntrag) {
        return get(this, "parameter.hover");
      }
      return get(this, "parameter.hover_error");
    },

    attributesLocal() {
      if (this.canCreateAntrag) {
        return {
          role: "button",
          tabindex: 0,
        };
      }
      return {};
    },
  },
  methods: {
    createAntrag() {
      if (!this.canCreateAntrag) {
        return;
      }
      this.modalConfirmOptions.extra = {
        angebot_bez: this.data.ars_kbez,
      };
      if (this.parameter.confirm) {
        this.modalConfirmShow = true;
      } else {
        this.modalCreateShow = true;
      }
    },
  },
};
