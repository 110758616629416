import Teleport from "../../../../global/directives/Teleport";

import KofiErfassungBelegMixin from "../../KofiErfassung/Mixins/KofiErfassungBelegMixin";

// @vue/component
export default {
  name: "KFAuszahlungPeriodenBeleg",
  directives: {
    Teleport,
  },
  mixins: [
    KofiErfassungBelegMixin,
  ],
  props: {
    isDiffPerioden: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    idForEditForm() {
      return `${ this.htmlIdLocal }_edit`;
    },
  },
};
