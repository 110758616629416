"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";
import template from "./config.jade";
import ro_template from "./readonly.jade";
import controller from "./controller";


const parametermoduleString = angular.module("dias.standard_workflowmodule.parameter.str_mapping", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("str_mapping", "parametermodul-str-mapping");
  })
  .component("parametermodulStrMapping", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
      change: "&?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulStrMappingRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: ro_template(),
    controller: controller,
    controllerAs: "vm",
  });
export default parametermoduleString;
