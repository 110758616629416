import KofiErfassungPositionForm from "../KofiErfassungPositionForm/KofiErfassungPositionForm.vue";
import KofiErfassungPositionWizard from "../KofiErfassungPositionWizard/KofiErfassungPositionWizard.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxDropdown from "../../../../global/components/PuxDropdown/PuxDropdown.vue";

import KofiErfassungModulBaseMixin from "../KofiErfassungModul/KofiErfassungModulBaseMixin";

import {
  forEach,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "KofiErfassungModulWizard",
  components: {
    KofiErfassungPositionForm,
    KofiErfassungPositionWizard,
    Modal,
    PuxButton,
    PuxDropdown,
  },
  mixins: [
    KofiErfassungModulBaseMixin,
  ],
  computed: {
    buttonAddCostTypeId() {
      return `${ this.labelKoFi }_add_cost_type`;
    },

    isComponentVisible() {
      return this.showHiddenKoFi ? true : this.kofiPositionenLength;
    },

    extraForTranslatePositionen() {
      return {
        all_pos: this.kofiPositionenLength,
        pos_mit_beleg: this.positionenWithOneBeleg,
      };
    },

    kofiPositionenLength() {
      return get(this.kofi, "positionen.length", 0);
    },

    positionenWithOneBeleg() {
      let count = 0;
      forEach(this.kofi.positionen, position => {
        if (position.belege.length) {
          count++;
        }
      });
      return count;
    },

    idForDropdown() {
      return `${ this.htmlIdLocal }_dropdown`;
    },
  },
  methods: {
    closeDropdown() {
      this.$refs.dropdown.onClose();
    },
  }
};
