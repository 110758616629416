import Modal from "../../../global/components/Modal/Modal.vue";
import PuxAlert from "../../../global/components/PuxAlert/PuxAlert.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import TaskLog from "../../../global/components/TaskLog/TaskLog.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import moment from "moment";
import {
  initModelFromList,
  toFormElementFromParameter,
} from "../../../global/functions/mappingForParameterToFormElement";
import {
  assign,
  cloneDeep,
  merge,
} from "lodash-es";

// @vue/component
export default {
  name: "DokumentDownloadConfigurationModal",
  components: {
    Modal,
    PuxAlert,
    PuxButton,
    PuxTranslate,
    TaskLog,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    apiCall: {
      type: String,
      required: true,
    },
    infoText: {
      type: String,
      required: false,
      default: undefined,
    },
    callParams: {
      type: Object,
      required: true,
    },
    isAsynchronous: {
      type: Boolean,
      required: true,
    },
    allowConfig: {
      type: Boolean,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      optionsModal: {
        showCloseButton: true,
        required: true,
        list: []
      },
      choicesOption: {
        type: Object,
      },
      choicesObject: {
        type: Object,
      },
      modelOptions: {},
      status: {
        loading: false,
      },
      loading: false,
      data: {},
      response: {},
      errors: undefined,
      errorsClone: undefined,
      fileName: undefined,
      // formKeys: undefined,
      // showHelp: undefined,
      fileValid: undefined,
      generationInProgress: false,
      configTimeToInvalid: undefined,
      taskId: undefined,
      validUntil: undefined,
      toInvalidPromise: undefined,
    };
  },
  computed: {
    extraForAsynchronousTranslate() {
      return {
        minutes: this.configTimeToInvalid,
      };
    },

    textForAsynchronousTranslate() {
      if (this.configTimeToInvalid === 1) {
        return "_MSG_DOCUMENT_DOWNLOAD_ASYNCHRONOUS_SINGULAR_";
      }
      return "_MSG_DOCUMENT_DOWNLOAD_ASYNCHRONOUS_PLURAL_{{minutes}}_";
    },

    idForButtonDocumentCreate() {
      return `${ this.id }_btn_create`;
    },

    idForButtonDocumentGenerate() {
      return `${ this.id }_btn_generate`;
    },

    idForButtonDocumentDownload() {
      return `${ this.id }_btn_download`;
    },

    idForButtonNewDocumentDownload() {
      return `${ this.id }_btn_new_download`;
    },

    extraForButtonDocumentDownloadTranslate() {
      return {
        fileName: this.fileName,
      };
    },
  },
  created() {
    this.initData();
    this.initModel();
    this.prepareDataForAsynchronous();
  },
  methods: {
    initData() {
      const OPTIONS_MODAL = cloneDeep(this.optionsModal);
      OPTIONS_MODAL.list = toFormElementFromParameter({ obj: this.options, showLabel: true }).list;
      this.optionsModal = OPTIONS_MODAL;
    },

    initModel() {
      this.modelOptions = initModelFromList({ list: this.optionsModal.list });
    },

    prepareDataForAsynchronous() {
      if (this.isAsynchronous) {
        const CACHE_TIME_IN_SECONDS = this.getSyConfigsValue("cache_time");
        this.configTimeToInvalid = Math.floor(CACHE_TIME_IN_SECONDS / 60); // in Minutes
        // Wenn es keine Konfigurationsmöglichkeiten gibt, Dokumentengenerierung
        // direkt anfangen:
        if (Object.keys(this.options).length === 0 || !this.allowConfig) {
          this.startDocumentGeneration();
        }
      }
    },

    createDocument() {
      let data = cloneDeep(this.modelOptions);
      data.validate_options = true;
      if (this.callParams) {
        data = assign(data, this.callParams);
      }
      this.getHttp({
        url: this.apiCall,
        urlParams: data,
      }).then(
        () => {
          this.close({ configOptions: this.modelOptions });
        },
        error => {
          this.onError({ error: error.data });
        }
      ).then(
        () => this.status.loading = false
      );
    },

    startDocumentGeneration() {
      this.generationInProgress = true;
      this.loading = true;
      this.fileName = null;
      const DATA = merge(cloneDeep(this.modelOptions), this.callParams);
      this.getHttp({
        url: this.apiCall,
        urlParams: DATA,
      }).then(
        response => {
          this.taskId = response.task_id;
        },
        error => {
          this.onError({ error: error.data });
        }
      ).finally(() => this.loading = false);
    },

    saveDocumentLocal() {
      this.close({
        configOptions: this.modelOptions,
        taskId: this.taskId,
      });
    },

    onError({ error }) {
      this.errors = error;
      this.errorsClone = error;
    },

    onSuccess({ result }) {
      this.fileName = result.name;
      this.validUntil = moment(result.cached_until);
      this.calcTimeToInvalid();
      this.toInvalidPromise = setInterval(this.calcTimeToInvalid, 1000);
      this.generationInProgress = false;
    },

    onFailure() {
      this.addNotification({
        text: "Fehler beim Erstellen des Dokuments",
        type: "error",
      });
      this.taskId = undefined;
      this.generationInProgress = false;
    },

    calcTimeToInvalid() {
      this.fileValid = moment().isBefore(this.validUntil);
      this.timeToInvalid = this.validUntil.diff(moment(), "minutes");
      if (!this.fileValid && this.toInvalidPromise) {
        clearInterval(this.toInvalidPromise);
      }
    },
  },
};
