"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import "angular-chart.js";
import "angular-drag-and-drop-lists";

import diasAuthService from "dias/auth/services";
import diasConfirm from "dias/core/confirm";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";

import foerdermodule from "dias/foerderantrag/foerdermodule";
import foerderregeln from "dias/foerderregeln/models";

import models from "../../models";
import detailsComponent from "./details.component";

const umfragecortexModule = angular.module("root.umfragecortex", [
  uiRouter,
  models.name,
  foerdermodule.name,
  foerderregeln.name,
  diasAuthService.name,
  diasConfirm.name,
  diasTable.name,
  diasLoading.name,
  diasNotification.name,
]);

umfragecortexModule.config($stateProvider => {
  $stateProvider

    // UmfrageFormular-Liste
    .state("root.umfragecortex", {
      url: "/umfrage/",
      views: {
        "main@root": {
          template: "<div vueinit><vue-umfrage-list></vue-umfrage-list></div>"
        }
      },
      data: {
      },
    })
    // Detail-Seite eines Formulars
    .state("root.umfragecortex.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<umfragecortex-details></umfragecortex-details>"
        }
      },
      data: {
      },
    });
})

  .component("umfragecortexDetails", detailsComponent)
;

export default umfragecortexModule;
