"use strict";

import BaseModel from "dias/core/models/baseModel";

class Ansprechpartner extends BaseModel {
  constructor() {
    super("ansprechpartner");
  }
}

export default Ansprechpartner;
