"use strict";

import diasLoading from "dias/core/loading";

import diasInfoBoxComponent from "./infoBox.component";

const diasInfoBox = angular.module("dias.infobox", [
  diasLoading.name
])

  .component("diasInfoBox", diasInfoBoxComponent);

export default diasInfoBox;
