var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group"] = jade_interp = function(field, labelText, req){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :ctx.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " " + (jade.escape((jade_interp = req) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "ctx.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in ctx.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :ctx.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "ctx.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in ctx.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<div class=\"modal-body\"><div class=\"row\"><section class=\"main col-10 offset-1\"><div ng-attr-id=\"{{ ctx.DiasNotification.form.containerId }}\"></div><form><fieldset>");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"aov_inhaber\" ng-model=\"ctx.bv.aov_inhaber\" type=\"text\" name=\"aov_inhaber\" maxlength=\"60\" auto-focus=\"true\" aria-required=\"true\" ng-attr-aria-invalid=\"{{ctx.errors.aov_inhaber ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "aov_inhaber", "Kontoinhaber");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"aov_iban\" ng-model=\"ctx.bv.aov_iban\" type=\"text\" name=\"aov_iban\" maxlength=\"34\" aria-required=\"true\" ng-attr-aria-invalid=\"{{ctx.errors.aov_iban ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "aov_iban", "IBAN");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"aov_bic\" ng-model=\"ctx.bv.aov_bic\" type=\"text\" name=\"aov_bic\" maxlength=\"11\" ng-attr-aria-invalid=\"{{ctx.errors.aov_iban ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "aov_bic", "BIC", "{{ctx.conf.bic ? '*' : ''}}");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"aov_bank\" ng-model=\"ctx.bv.aov_bank\" type=\"text\" name=\"aov_bank\" maxlength=\"60\" ng-attr-aria-invalid=\"{{ctx.errors.aov_bank ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "aov_bank", "Geldinstitut", "{{ctx.conf.bank ? '*' : ''}}");
buf.push("</fieldset></form></section></div></div><div class=\"modal-footer\"><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"exitWithCallback(ctx.save)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"exit()\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-default\"><span>Abbrechen</span></button></div></div></div>");;return buf.join("");
}