var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["schaltflaechen"] = jade_interp = function(visible){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div id=\"always-visible\" class=\"actions right panel_always_visible\"><a ng-if=\"!vm.timelineStatus\" ui-sref=\"root.sammelvertrag\"><button type=\"button\" class=\"btn btn-icon float-left\"><span class=\"glyphicon glyphicon-chevron-left\"></span><span> Zur Übersicht der Weiterleitungsverträge</span></button></a><fieldset ng-disabled=\"vm.loading.aufgabe || vm.loading.sv\"><div vueinit props=\"{ baseUrl: vm.svUrl, aufgabe: vm.sv.aktuelle_aufgabe, onStarted: vm.onWorkflowStarted, onCanceled: vm.onWorkflowCanceled, onInit: vm.onWorkflowInit, disabled: vm.loading.aufgabe || vm.loading.sv, updateAufgabe: vm.updateAufgabeFromVue }\" class=\"d-inline-block\"><workflow-folgen v-bind:base-url=\"propsAngular.baseUrl\" v-bind:aufgabe=\"propsAngular.aufgabe\" v-bind:on-started=\"propsAngular.onStarted\" v-bind:on-canceled=\"propsAngular.onCanceled\" v-bind:on-init=\"propsAngular.onInit\" v-bind:disabled=\"propsAngular.disabled\" v-on:update-aufgabe=\"propsAngular.updateAufgabe\"></workflow-folgen></div><div ng-if=\"vm.timelineStatus\" permission=\"timeline.ui.notizen\" class=\"d-inline-block\"><div vueinit class=\"d-inline-block\"><notizen-icon v-bind:label=\"'Verlauf'\" v-bind:notizen-key=\"'verlauf'\" v-bind:button-id=\"'notizen_verlauf'\" button-class=\"btn btn-switch\"></notizen-icon></div></div><a href=\"#\" ng-click=\"vm.changeTimelineStatus()\" class=\"btn btn-default\"><span ng-class=\"{ 'glyphicon-vertrag' : vm.timelineStatus, 'glyphicon-time' : !vm.timelineStatus }\" class=\"glyphicon\"></span><span> {{vm.timelineStatus ? \"Weiterleitungsvertrag\" : \"Verlauf\"}} anzeigen</span></a></fieldset></div>");
};
jade_mixins["dropdownAll"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div class=\"dropdown-all\"><div data-toggle=\"inactive-all\" title=\"alle schließen\"><span class=\"glyphicon-double-up\"></span></div><div data-toggle=\"active-all\" title=\"alle öffnen\"><span class=\"glyphicon-double-bottom\"></span></div></div>");
};
buf.push("<div dias-cloak=\"vm.loading.sv\" cloak-msg=\"'Weiterleitungsvertragsdaten werden geladen'\" on-unblock=\"vm.initUi()\"><section class=\"main row no-margin\"><section class=\"main-dias application-form\"><header class=\"page-title header-detail\"><div vueinit props=\"{ ast_nr: vm.sv.antragstellerorg.ast_nr, sv_nr: vm.sv.sv_nr }\"><page-tab-title v-bind:placeholder=\"'_PAGE_TITLE_WEITERLEITUNGSVERTRAG_{{ast_nr}}_{{sv_nr}}_'\" v-bind:extra=\"propsAngular\"></page-tab-title></div><h1>Weiterleitungsvertrag</h1><h1><small>{{ vm.sv.antragstellerorg.ast_nr }}-{{ vm.sv.sv_nr }}</small></h1>");
jade_mixins["schaltflaechen"]();
buf.push("</header><div ng-show=\"!vm.timelineStatus\" class=\"main-details\"><div vueinit props=\"{ text: vm.sv.aktuelle_aufgabe.bez }\"><vue-details-info-box :text=\"propsAngular.text\"></vue-details-info-box></div><div formsteps><div class=\"form-step active\"><header formstep><h3><span class=\"glyphicon glyphicon-uebersicht\"></span><span>Übersicht</span></h3>");
jade_mixins["dropdownAll"]();
buf.push("</header><div class=\"form-step-detail\"><h4>Basisdaten</h4><fieldset><dl class=\"dl-horizontal\"><dt>{{ vm.gettext('Antragstellerorganisation') }}</dt><dd>{{ vm.sv.antragstellerorg.ast_name }}</dd><dt>{{ vm.gettext('Antragstellernummer') }}</dt><dd>{{ vm.sv.antragstellerorg.ast_nr }}</dd><dt>Nummer des Vertrags</dt><dd>{{ vm.sv.sv_nr }}</dd><dt>Budget</dt><dd>{{ vm.sv.budget.bud_kbez }}</dd><dt>Zugewiesener Betrag</dt><dd>{{ vm.sv.sv_budget | currency}}</dd><dt>Bewilligt</dt><dd>{{ vm.sv.bewilligt | currency}}</dd><dt>Ausgezahlt</dt><dd>{{ vm.sv.ausgezahlt | currency}}</dd><dt>Verfügbar</dt><dd>{{ vm.sv.verfuegbar | currency}}</dd><dt>Anzahl Anträge</dt><dd>{{ vm.sv.num_antraege }}</dd></dl></fieldset></div></div><div ng-click=\"vm.antraegeOpened = true\" class=\"form-step\"><header formstep><h3><span class=\"glyphicon glyphicon-antraege\"></span><span>Anträge</span></h3>");
jade_mixins["dropdownAll"]();
buf.push("</header><formstep-detail ng-if=\"vm.antraegeOpened\" ng-model=\"vm.sv\" formstep-editable=\"false\" formstep-readonly-template=\"vm.antraegeReadonlyTemplate()\" formstep-readonly-controller=\"vm.antraegeReadonlyController\" class=\"table-scrollable\"></formstep-detail></div><div class=\"form-step\"><header formstep><h3><span class=\"glyphicon glyphicon-file\"></span><span> Dokumente</span></h3>");
jade_mixins["dropdownAll"]();
buf.push("</header><dokumente doc-anzeige=\"vm.docAnzeige\" docpk=\"vm.sv\" doc-upload=\"false\" dokumente-permissions=\"vm.dokumentePermissions\"></dokumente></div></div></div><div vueinit props=\"{ config: vm.timelineConfig, bisection: false, viewName: vm.timelineStatus ? 'timeline' : 'details' }\"><div v-if=\"propsAngular.viewName === 'timeline'\" class=\"main-details\"><timeline :options=\"propsAngular\"></timeline></div></div></section><aside id=\"aside-focus\" sticky tabindex=\"-1\" aside-init class=\"fixed fixed-scroll fixed_vertical\"><button id=\"btn-aside\" type=\"button\" data-toggle=\"aside\" title=\"Seitenleiste ausblenden\" class=\"btn btn-default btn-aside\"><span class=\"glyphicon glyphicon-einreichen\"></span></button><div class=\"aside-overflow\"><div vueinit props=\"{ relevantObj: vm.sv.aktuelle_aufgabe, relevantObjName: vm.svLabel }\"><w-f-status-gruppen v-bind:relevant-obj=\"propsAngular.relevantObj\" v-bind:relevant-obj-name=\"propsAngular.relevantObjName\"></w-f-status-gruppen></div><div ng-if=\"vm.sv.aktuelle_aufgabe.task_id\" class=\"box\"><dias-task-log task=\"vm.sv.aktuelle_aufgabe.task_id\" on-success=\"vm.onWorkflowSuccess\" on-failure=\"vm.onWorkflowFailure\" on-finish=\"vm.onWorkflowFinish\"></dias-task-log></div><div ng-if=\"vm.sv\"><div vueinit props=\"{ referenceObj: vm.sv, onTaskAccepted: vm.onAufgabeAngenommen, updateAufgabe: vm.updateAufgabeFromVue }\"><aufgabe-box v-bind:reference-obj=\"propsAngular.referenceObj\" reference-name=\"sammelvertrag\" base-url=\"sammelvertraege\" v-on:on-task-accepted=\"propsAngular.onTaskAccepted\" v-on:update-aufgabe=\"propsAngular.updateAufgabe\"></aufgabe-box></div></div></div></aside></section></div>");;return buf.join("");
}