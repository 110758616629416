"use strict";

import parameterServices from "../services";
import template from "./config.jade";
import rotemplate from "./readonly.jade";
import controller from "./controller";

const parametermoduleSingleChoice = angular.module("dias.foerderregeln.parameter.select", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("single_choice", "parametermodul-single-choice");
  })
  .component("parametermodulSingleChoice", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
      change: "&?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulSingleChoiceRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: rotemplate(),
    controller: controller,
    controllerAs: "vm",
  });
export default parametermoduleSingleChoice;
