"use strict";

const PASSWORT_ABGELAUFEN_STATUS_ID = "2d4d03f5-6c2e-4ebe-b612-bdc544582739";

import template from "./start.jade";

class StartController {
  /*@ngInject*/
  constructor($state, $stateParams, Antragstellerorganisationen, AuthService, SyConfigs) {
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.Antragstellerorganisationen = Antragstellerorganisationen;
    this.AuthService = AuthService;
    this.SyConfigs = SyConfigs;

    this.loading = true;
    this.dashboardShowStatus = false;
    this.dashboardPublicShowStatus = false;
    this.wizardShowStatus = false;

    this.initPage();
  }

  initPage() {
    this.parameter = this.SyConfigs.wert("start_page") || {};
    this.checkUser();
  }

  checkUser() {
    this.AuthService.getUser().then(
      user => {
        this.user = user;
        if (!this.user || !this.user.pk || this.user.status.ns_id === PASSWORT_ABGELAUFEN_STATUS_ID) {
          if (this.parameter && this.parameter.dashboard_public) {
            this.dashboardPublicShowStatus = true;
            this.loading = false;
          } else {
            this.$state.go("root.login", this.$stateParams);
          }
        } else {
          this.checkWizardParameter();
        }
      }
    );
  }

  checkWizardParameter() {
    if (this.parameter && this.parameter.ast_wizard) {
      this.istPast();
    } else {
      this.dashboardShowStatus = true;
      this.loading = false;
    }
  }

  istPast() {
    this.AuthService.hasPerm("antragstellerorg.create").then(
      response => {
        if (!response) {
          this.dashboardShowStatus = true;
          this.loading = false;
        } else {
          this.checkCountOrganisationen();
          this.loading = false;
        }
      }
    );
  }

  checkCountOrganisationen() {
    let has_foeorg_rolle = false;
    for (let i = 0; i < this.user.kontexte.length; i++) {
      if (this.user.kontexte[i].kontext_typ === 2) {
        has_foeorg_rolle = true;
      }
    }
    if (this.user.has_ast_org || has_foeorg_rolle) {
      this.dashboardShowStatus = true;
    } else {
      this.wizardShowStatus = true;
    }
  }
}

export default {
  template: template(),
  controller: StartController,
  controllerAs: "vm"
};
