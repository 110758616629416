import SNAPSHOT_MODES from "../../Snapshot/SnapshotModule/modes";
import {
  MAPPING,
} from "../Module/Module";

// @vue/component
export default {
  name: "GeschaeftsregelModulChild",
  props: {
    regel: {
      type: Object,
      required: true,
    },
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    obj: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: undefined,
    },
    kontext: {
      type: String,
      default: undefined,
    },
    group: {
      type: [String, Number],
      default: undefined,
    },
    idx: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    idPrefix: {
      type: String,
      default: () => ""
    },
    saveCallback: {
      type: Function,
      default: undefined,
    },
    infoprovider: {
      type: Object, // TODO: ILIA ???
      default: undefined,
    },
    astdiffstatsus: {
      type: Boolean,
    },
    notizenKey: {
      type: String,
      default: undefined,
    },
    extra: {
      type: Object,
      default: undefined,
    },
    updateSnapshotDiff: {
      type: Function,
      default: () => {},
    },
    updateModule: {
      type: Function,
      default: undefined,
    },
    anzeigekontext: {
      type: String,
      required: false,
      default: undefined,
    },
    dokumentePermissionsFromObj: {
      type: Object,
      required: false,
      default: undefined,
    },
    snapshot: {
      type: Object,
      default: undefined,
    },
    snapshotMode: {
      type: String,
      default: "snapshot",
      validator: value => SNAPSHOT_MODES.indexOf(value) !== -1,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    childSuffix: {
      type: String,
      required: true,
    },
    childSave: {
      type: Function,
      required: true,
    },
    fullSnapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
    dokumenteBaseUrl: {
      type: String,
      required: false,
      default: undefined,
    },
    parentsRegelId: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      mapping: MAPPING,
    };
  },
  computed: {
    module() {
      return {
        regel: this.regel,
        data: this.model,
        modulname: this.regel.modulname,
      };
    },
  },
  beforeCreate() {
    this.$options.components = this.$options.components || {};
    const MODULE = require("../Module/Module").default;
    this.$options.components = MODULE;
  },
};
