import {
  getCurrentInstance,
  ref,
  toRef,
} from "vue";

import Modal from "../../../../global/components/Modal/Modal.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

// @vue/component
export default {
  name: "GeschaeftsregelDeleteButtonConfirm",
  components: {
    Modal,
    Permission,
    PuxButton,
  },
  props: {
    regel: {
      type: Object,
      required: true,
    },
    regelUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const APP = getCurrentInstance();
    const $goTo = APP.appContext.config.globalProperties.$goTo;

    const regel = toRef(props, "regel");
    const regelUrl = toRef(props, "regelUrl");

    const isModalOpen = ref(false);
    const confirmOptions = ref(undefined);
    const buttonId = "regel_btn_delete";
    const selectorClose = `#${ buttonId }`;

    const closeModal = () => {
      isModalOpen.value = false;
    };

    const {
      deleteHttp,
    } = HttpAPI();
    const {
      addNotification,
    } = NotificationAPI();
    const deleteRegel = () => {
      confirmOptions.value.loading = true;
      deleteHttp({
        url: regelUrl.value,
      }).then(
        () => {
          addNotification({
            text: "_MSG_GR_DETAILS_DELETE_SUCCESS_{{re_bez}}_",
            extra: {
              re_bez: regel.value.re_bez,
            },
          });
          closeModal();
          $goTo("root.foerderregeln");
        },
        () => {
          addNotification({
            text: "_MSG_GR_DETAILS_DELETE_ERROR_",
            type: "error",
          });
        }
      ).finally(
        () => {
          confirmOptions.value.loading = false;
        }
      );
    };

    const openModal = () => {
      confirmOptions.value = {
        okClass: "btn btn-primary",
        title: "_TXT_GR_DETAILS_DELETE_HEADER_",
        msg: "_TXT_GR_DETAILS_DELETE_BODY_{{re_bez}}_",
        okLabel: "_BTN_GR_DETAILS_DELETE_OK_LABEL_",
        okCallback: deleteRegel,
        cancelCallback: closeModal,
        extra: {
          re_bez: regel.value.re_bez,
        },
        loading: false,
      };

      isModalOpen.value = true;
    };

    return {
      buttonId,
      closeModal,
      confirmOptions,
      isModalOpen,
      openModal,
      selectorClose,
    };
  },
};
