import AsideRightBox from "../../../global/components/AsideRightBox/AsideRightBox.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxDate from "../../../global/components/PuxDate/PuxDate.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spinner from "../../../global/components/Spinner/Spinner.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import {
  CheckPermissionsSyncMixin,
} from "../../../global/mixins/PermissionMixin";

import {
  createNamespacedHelpers,
} from "vuex";
import {
  EventBus,
} from "../../../global/functions/event-bus";

const {
  mapMutations,
} = createNamespacedHelpers("snapshotKF");

// @vue/component
export default {
  name: "SnapshotsBox",
  components: {
    AsideRightBox,
    PuxButton,
    PuxDate,
    PuxIcon,
    PuxTranslate,
    Spinner,
  },
  mixins: [
    CheckPermissionsSyncMixin,
    HttpMixin,
  ],
  props: {
    permission: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    objectPk: {
      type: String,
      required: true,
    },
    snapshotActivePk: {
      type: String,
      required: false,
      default: undefined,
    },
    snapshotPrefix: {
      type: String,
      required: true,
    },
    loadingDiffSnapshotPk: {
      type: String,
      required: false,
      default: undefined,
    },
    httpFunctionCreateSnapshot: {
      type: String,
      required: false,
      default: "getHttp"
    },
  },
  emits: [
    "changeActiveSnapshot",
  ],
  data() {
    return {
      optionsAsideRightBox: {
        icon: "time",
      },
      id: "versions_aside_right_box",
      snapshots: [],
      loading: true,
    };
  },
  computed: {
    isBoxVisible() {
      return this.checkPermissionsSync({
        perm: this.permission,
      });
    },

    url() {
      return `${ this.baseUrl }/${ this.objectPk }/snapshots/`;
    },

    urlCreate() {
      return `${ this.url }create_snapshot/`;
    },

    snapshotDateKey() {
      return `${ this.snapshotPrefix }_snapshot_datum`;
    }
  },
  created() {
    this.loadSnapshots();
    this.setEventBuses();
  },
  beforeUnmount() {
    this.destroyEventBuses();
  },
  methods: {
    loadSnapshots() {
      if (!this.isBoxVisible) {
        return;
      }
      this.getListHttp({
        url: this.url,
      }).then(
        response => {
          this.snapshots = response;
          this.loading = false;
        }
      );
    },

    setEventBuses() {
      EventBus.$on("updateSnapshots", this.loadSnapshots);
      EventBus.$on("createSnapshot", this.createSnapshot);
    },

    destroyEventBuses() {
      EventBus.$off("updateSnapshots", this.loadSnapshots);
      EventBus.$off("createSnapshot", this.createSnapshot);
    },

    createSnapshot() {
      this[this.httpFunctionCreateSnapshot]({
        url: this.urlCreate,
      }).then(
        () => {
          this.loadSnapshots();
          this.addNotification({
            text: "_MSG_SNAPSHOTSBOX_CREATE_SUCCESS_",
          });
        },
        () => this.addNotification({
          text: "_MSG_SNAPSHOTSBOX_CREATE_ERROR_",
          type: "error",
        })
      );
    },

    removeActiveSnapshot() {
      this.$emit("changeActiveSnapshot");
      this.REMOVE_SNAPSHOT_KF_MUT();
    },

    showSnapshot(snapshot) {
      this.$emit("changeActiveSnapshot", { snapshotLocal: snapshot });
      this.ADD_SNAPSHOT_KF_MUT({ snapshot });
    },

    ...mapMutations([
      "ADD_SNAPSHOT_KF_MUT",
      "REMOVE_SNAPSHOT_KF_MUT",
    ]),
  },
};
