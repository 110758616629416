import EditMixin from "../EditMixin";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import {
  cloneDeep,
  forEach,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "CheckboxEdit",
  components: {
    FormElement,
  },
  mixins: [
    EditMixin,
  ],
  props: {
    antwort: {
      type: Object,
      required: true,
    },
    antwortIndex: {
      type: Number,
      required: true,
    },
    model: {
      type: Object,
      default: () => ({}),
    },
    mehrfachauswahl: {
      type: Boolean,
      required: true,
    },
    labelCallback: {
      type: Function,
      required: true,
      info: "returns label mit oder ohne eine Sterne"
    },
    regelPk: {
      type: String,
      required: true,
    },
    changeModel: {
      type: Function,
      default: undefined,
    },
  },
  computed: {
    isDisabled() {
      return !this.mehrfachauswahl && this.antwort.children && this.antwort.children.length > 0;
    },

    getId() {
      return `${ this.regelPk }_${ this.antwortIndex }`;
    },

    getType() {
      return this.mehrfachauswahl ? "checkbox" : "radio";
    },

    getClass() {
      return `custom-${ this.getType }`;
    },

    getAriaChecked() {
      return `${ this.getChecked }`;
    },

    getChecked() {
      return this.getIconName === "ok" || this.getIconName === "partly";
    },

    getModelChildren() {
      if (this.model.antworten &&
        this.model.antworten[this.antwort.label] &&
        this.model.antworten[this.antwort.label].children) {
        return this.model.antworten[this.antwort.label].children;
      }
      return {};
    },

    getModelAntwort() {
      if (this.model.antworten &&
        this.model.antworten[this.antwort.label]) {
        return this.model.antworten[this.antwort.label];
      }
      return {};
    },

    getIconName() {
      if (!this.antwort.children || !this.antwort.children.length) {
        return this.getModelAntwort.status ? "ok" : undefined;
      }
      const MODEL_CHILDREN = this.getModelChildren;
      let countOk = 0;
      forEach(this.antwort.children, child => {
        if (MODEL_CHILDREN[child.label] &&
          MODEL_CHILDREN[child.label].status) {
          countOk++;
        }
      });
      if (countOk === 0) {
        return undefined;
      } else if (countOk === this.antwort.children.length) {
        return "ok";
      } else if (countOk === 1 && !this.mehrfachauswahl) {
        return "ok";
      }
      return "partly";
    },

    getOptionsFreitext() {
      return {
        id: `${ this.getId }_freitext`,
        type: "textarea",
        view: "v-alt",
        label: this.antwort.freitext,
        isScalable: true,
      };
    },

    getOptionsFreitextChild() {
      const OPTIONS = {};
      forEach(this.antwort.children, (child, index) => {
        if (child.freitext) {
          OPTIONS[child.label] = {
            id: `${ this.regelPk }_${ this.antwortIndex }_${ index }_freitext`,
            type: "textarea",
            view: "v-alt",
            label: child.freitext,
            isScalable: true,
          };
        }
      });
      return OPTIONS;
    },
  },
  methods: {
    onClick() {
      if (this.isDisabled) {
        return;
      }
      let model = cloneDeep(this.model);
      model.antworten = model.antworten || {};
      model.antworten[this.antwort.label] = model.antworten[this.antwort.label] || {};
      if (!this.mehrfachauswahl) {
        model = this.setModelFalseForAll({ model });
      }
      model.antworten[this.antwort.label].status = !this.getChecked;
      if (this.antwort.children && this.antwort.children.length) {
        model = this.setModelForAllChildren({ model, status: model.antworten[this.antwort.label].status });
      }
      this.changeModel({ model: model });
    },

    setModelForAllChildren({ model, status }) {
      const MODEL = cloneDeep(model);
      if (MODEL.antworten[this.antwort.label].children && size(MODEL.antworten[this.antwort.label].children)) {
        forEach(MODEL.antworten[this.antwort.label].children, child => {
          child.status = status;
        });
      }
      return MODEL;
    },

    onClickChild(child) {
      let model = cloneDeep(this.model);
      model.antworten = model.antworten || {};
      model.antworten[this.antwort.label] = model.antworten[this.antwort.label] || {};
      if (!this.mehrfachauswahl) {
        model = this.setModelFalseForAll({ model });
      }
      model.antworten[this.antwort.label].children = model.antworten[this.antwort.label].children || {};
      model.antworten[this.antwort.label].children[child.label] = model.antworten[this.antwort.label].children[child.label] || {};
      model.antworten[this.antwort.label].children[child.label].status = !model.antworten[this.antwort.label].children[child.label].status;
      let activeChildrenCount = 0;
      for (const child in model.antworten[this.antwort.label].children) {
        if (model.antworten[this.antwort.label].children[child] && model.antworten[this.antwort.label].children[child].status) {
          activeChildrenCount += 1;
        }
      }
      if (!model.antworten[this.antwort.label].status || (activeChildrenCount == 0 && model.antworten[this.antwort.label].status)) {
        model.antworten[this.antwort.label].status = !model.antworten[this.antwort.label].status;
      }
      this.changeModel({ model: model });
    },
  },
};
