import FormElement from "../../../../../../../global/components/FormElement/FormElement.vue";
import PuxCloak from "../../../../../../../global/components/PuxCloak/PuxCloak.vue";

import HttpMixin from "../../../../../../../global/mixins/HttpMixin";

import OptionList from "./OptionList";
import {
  cloneDeep,
  debounce,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "BerichtAntragscontrollingAsideFilters",
  components: {
    FormElement,
    PuxCloak,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    disabled: {
      type: Boolean,
    },
    updateAntraege: {
      type: Function,
      required: true,
    },
    defaultModel: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      optionList: OptionList,
      model: {
        a_antragsdatum: undefined,
        eingereicht: undefined,
        show_gesperrte_ast: undefined,
        regelsatz: [],
        status_detail: [],
        abschluss: [],
        studiengangtyp: [],
        studienform: [],
        studiengang_hochschultyp: [],
        hochschultyp: [],
      },
    };
  },
  computed: {
    change() {
      return debounce(this.onChange, 500);
    },
  },
  created() {
    this.setDefaultModel();
    this.change();
  },
  methods: {
    setDefaultModel() {
      const MODEL = cloneDeep(this.model);
      forEach(this.defaultModel, (model, key) => {
        MODEL[key] = model;
      });
      this.model = MODEL;
    },

    onChange() {
      const MODEL = cloneDeep(this.model);
      const LINK_PARAMETERS = this.prepareLinkParameters();
      this.updateAntraege({ model: MODEL, linkParameters: LINK_PARAMETERS });
    },

    prepareModel() {
      const MODEL = {
        foerderorganisation: [],
      };
      forEach(this.model, (item, key) => {
        if (key.indexOf("foerderorganisation") !== -1 && item) {
          MODEL.foerderorganisation.push(item);
        } else if (item !== "") {
          MODEL[key] = cloneDeep(item);
        }
      });
      return MODEL;
    },

    prepareLinkParameters() {
      return this.model;
    },
  },
};
