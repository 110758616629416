"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";
import diasTable from "dias/core/table";
import detailsComponent from "./details.component";
import models from "./models";

const modul = angular.module("dias.sammelvertrag", [
  uiRouter,
  diasTable.name,
  models.name,
]);


modul.config($stateProvider => {
  $stateProvider

    // Weiterleitunsvertrag-Liste
    .state("root.sammelvertrag", {
      url: "/sammelvertraege/?antragstellerorg&status&status_detail&workflowstatusgruppe&budget",
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-sammelvertrag-list></vue-sammelvertrag-list></div>"
        }
      },
      data: {
        permissions: ["sammelvertrag.view"]
      }
    })

    // Detail-Seite eines Sammelvertrags
    // Sollte immer als letztes eingefügt werden, sonst werden Sub-URLs
    // as ID aufgelöst
    .state("root.sammelvertrag.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<sammelvertrag-details></sammelvertrag-details>"
        }
      },
    });
})

  .component("sammelvertragDetails", detailsComponent);

export default modul;
