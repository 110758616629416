"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";


class WorkflowfolgenEditBeschreibungController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, ParameterUtils, Katalog, DiasNotification, i18n) {
    super($q, $scope, DiasNotification);
    this.ParameterUtils = ParameterUtils;
    this.gettext = i18n.gettext;
    this.Katalog = Katalog;
    this.module = [];
    this.paramForms = {
      in: {
        keys: []
      },
      out: {
        keys: []
      }
    };
    this.initForm = this.initForm.bind(this);
    this.initParameterForm = this.initParameterForm.bind(this);
    this.loadingEditData = true;
    this.initData().then(
      () => {
        this.initForm();
        $scope.$watch(() => this.formModel.clientfunktion.wert, () => {
          this.initParameterForm($scope.extra.params);
        });
      }
    ).finally(() => this.loadingEditData = false);
  }

  _save() {
    const data = Object.assign(
      {},
      this.ParameterUtils.to_request_body(this.formModel, null, ""),
      this.ParameterUtils.to_request_body(this.paramForms.in.form, null, ""),
      this.ParameterUtils.to_request_body(this.paramForms.out.form, null, ""),
    );
    return this.model.patch(data).then(
      response => {
        Object.assign(this.$scope.model, response.plain());
        this.$scope.extra.updateParams();
        this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
      },
      errors => this.onErrors(errors, this.errorLabels)
    );
  }

  onEnterEditMode() {
    super.onEnterEditMode();
    this.updateFormModel();
  }

  updateFormModel() {
    if (this.ParameterUtils && this.formModelTemplate && this.model) {
      this.formModel = this.ParameterUtils.params_from_template(this.formModelTemplate, this.model);
    }
  }

  initForm() {
    this.formModelTemplate = this.ParameterUtils.init_parameter_in({
      wfc_bez: {
        bez: this.gettext("Name"),
        typ: "string",
        prio: 1,
        options: {
          required: true
        }
      },
      clientfunktion: {
        bez: this.gettext("Modul"),
        typ: "single_choice",
        choices: this.module,
        prio: 2,
        options: {
          required: true
        }
      },
    });
    this.form_keys = this.ParameterUtils.get_ordered_keys(this.formModelTemplate);
    this.errorLabels = {};
    this.form_keys.forEach(
      k => {
        this.errorLabels[k] = `${ this.formModelTemplate[k].bez }: `;
      }
    );
    this.form_keys_left = this.form_keys.slice(0, 4);
    this.form_keys_right = this.form_keys.slice(4);
    this.updateFormModel();
  }

  initParameterForm(modelParams) {
    const selectedModul = this.module.find(v => v.pk === this.formModel.clientfunktion.wert);
    if (!selectedModul) {
      this.paramForms.in = { keys: [], form: {} };
      this.paramForms.out = { keys: [], form: {} };
      return;
    }
    const paramIn = this.ParameterUtils.init_parameter_in(selectedModul.wcf_parameterin);
    const paramOut = this.ParameterUtils.init_parameter_in(selectedModul.wcf_parameterout);
    this.paramForms.in = {
      keys: this.ParameterUtils.get_ordered_keys(paramIn),
      form: this.ParameterUtils.params_from_template(paramIn, modelParams.in.config) || {}
    };
    this.paramForms.out = {
      keys: this.ParameterUtils.get_ordered_keys(paramOut),
      form: this.ParameterUtils.params_from_template(paramOut, modelParams.out.config) || {}
    };
  }

  initData() {
    return this.Katalog.getKatalog("clientfunktion_module").then(
      response => {
        this.module.push(...response);
        return response;
      }
    );
  }
}

export default WorkflowfolgenEditBeschreibungController;
