var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["schaltflaechen"] = jade_interp = function(visible){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div id=\"always-visible\" class=\"actions right panel_always_visible\"><a ui-sref=\"root.projektberichtvorlagen\"><button type=\"button\" class=\"btn btn-icon float-left\"><span class=\"glyphicon glyphicon-chevron-left\"></span><span> Zur Übersicht der Projektberichtvorlagen</span></button></a><button ng-if=\"vm.pbv.deletable\" title=\"Projektberichtvorlage {{ vm.pbv.pbv_kbez }} löschen\" ng-click=\"vm.deleteProjektberichtvorlage()\" class=\"btn btn-default\"><i class=\"glyphicon glyphicon-trash\"></i><span>Löschen</span></button></div>");
};
buf.push("<script type=\"text/ng-template\" id=\"basedataReadonly.html\"><fieldset><dl class=\"dl-horizontal\"><dt>Name</dt><dd>{{ model.pbv_kbez }}</dd><dt>Beschreibung</dt><dd ng-bind-html=\"model.pbv_bez\"></dd></dl></fieldset></script><div dias-cloak=\"vm.loading.projektberichtvorlage\" cloak-size=\"'lg'\" cloak-msg=\"'Daten zur Projektberichtvorlage werden geladen'\" on-unblock=\"vm.initUi()\"><section dias-loading=\"vm.loading.deleting\" loading-msg=\"'Projektberichtvorlage wird gelöscht'\" class=\"main row no-margin\"><section class=\"main-dias application-form full-screen\"><header class=\"page-title header-detail\"><div vueinit props=\"{ pbv_kbez: vm.pbv.pbv_kbez }\"><page-tab-title v-bind:placeholder=\"'_PAGE_TITLE_PROJEKTBERICHTVORLAGE_{{pbv_kbez}}_'\" v-bind:extra=\"propsAngular\"></page-tab-title></div><h1>Projektberichtvorlage</h1><h2>{{ vm.pbv.pbv_kbez }}</h2>");
jade_mixins["schaltflaechen"]();
buf.push("</header><div class=\"main-details\"><form formsteps><div class=\"form-step\"><header formstep><h3><span class=\"glyphicon glyphicon-antrag\"></span><span>Projektberichtvorlage</span></h3></header><formstep-detail formstep-title=\"Basisdaten\" ng-model=\"vm.pbv\" formstep-edit-template=\"vm.baseEditTemplate()\" formstep-readonly-template-url=\"basedataReadonly.html\"></formstep-detail></div><div class=\"form-step\"><header formstep><h3><span class=\"glyphicon glyphicon-regel\"></span><span>Konfiguration</span></h3></header><div class=\"form-step-detail\"><h4>Geschäftsregeln</h4><fieldset><div dias-loading=\"vm.loading.projektberichtregeln\" loading-msg=\"'Aktuelle Konfiguration wird geladen'\"><div ng-repeat=\"rule in vm.pbvRegeln\"><projektbericht-regel-config regel=\"rule\" editable=\"true\" on-delete=\"vm.deleteProjektberichtregel()\" on-move-up=\"vm.moveUpProjektberichtregel()\" on-move-down=\"vm.moveDownProjektberichtregel()\"></projektbericht-regel-config></div><alert type=\"'info'\" ng-if=\"vm.pbvRegeln.length === 0\">Es wurden noch keine Regeln für diese Projektberichtvorlage hinterlegt.</alert></div><div dias-cloak=\"vm.loading.regeln\" cloak-msg=\"'Geschäftsregeln werden geladen'\"><projektbericht-regel-add ng-if=\"vm.verwendbareRegeln.length &gt; 0\" regeln=\"vm.verwendbareRegeln\" on-add=\"vm.addProjektberichtregel()\"></projektbericht-regel-add><div ng-if=\"vm.verwendbareRegeln.length === 0\" class=\"alert alert-info\">Es konnten keine weiteren verwendbaren Geschäftsregeln gefunden werden.</div></div></fieldset></div></div></form></div></section></section></div>");;return buf.join("");
}