import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "AnonymisierungPlanMessage",
  components: {
    PuxAlert,
    PuxTranslate,
  },
};
