import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";
import TableViewTableCellLink from "../TableViewTableCellActions/TableViewTableCellLink/TableViewTableCellLink.vue";
import TableViewTableCellLinkAngular from "../TableViewTableCellActions/TableViewTableCellLinkAngular/TableViewTableCellLinkAngular.vue";

import TableCellMixin from "../TableCell.mixin";

import IsFilter from "../../../filters/IsFilter";

import {
  get,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "TableViewTableCell",
  components: {
    TableViewTableCellLink,
    TableViewTableCellLinkAngular,
    PuxTranslate,
  },
  mixins: [
    TableCellMixin,
  ],
  props: {
    col: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
    indexRow: {
      type: Number,
      default: undefined,
    },
    isDetails: {
      type: Boolean,
      default: false,
    },
    onClickDetails: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    statusIsAngular() {
      return !this.$statusNotAngular;
    },

    getValue() {
      if (this.col.templateCallback) {
        return this.col.templateCallback({ row: this.row });
      }
      if (this.col.filter) {
        return IsFilter(get(this.row, this.col.id), this.col.filter, this.col.filterParameter);
      }
      return get(this.row, this.col.id);
    },

    getValueAsString() {
      return isNil(this.getValue) ? "" : `${ this.getValue }`;
    },

    title() {
      return this.col.titleCallback ?
        this.col.titleCallback({ item: this.row }) :
        this.col.title;
    },

    extra() {
      return this.col.extraCallback ?
        this.col.extraCallback({ item: this.row }) :
        this.col.extra;
    },

    getToForLink() {
      const LINK_TO = this.col.linkTo;
      return this.getToForLink_mixin(LINK_TO);
    },
  },
};
