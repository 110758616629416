// @vue/component
export default {
  props: {
    apiAntragDokumente: {
      type: String,
      required: true,
      info: "API foerderantraege/{{ AntragPk }}/dokumente/?dokart={{ dokart }}",
    },
    beleg: {
      type: Object,
      required: false,
      default: undefined,
    },
    belegDokumente: {
      type: Array,
      required: true,
    },
    belegDokumenteAnzahl: {
      type: Object,
      required: false,
      info: "Mapping dok_id zur Anzahl der Belege, in der dieses Dokument benutzt wird",
      default: () => ({}),
    },
    betragsrechner: {
      type: String,
      required: false,
      default: undefined,
    },
    extra: {
      type: Object,
      required: true,
    },
    extraFields: {
      type: Array,
      required: false,
      default: undefined,
    },
    hasExtraFields: {
      type: Boolean,
      required: false,
    },
    standardFieldConfigs: {
      type: Array,
      required: false,
      default: undefined,
    },
    hasStandardFieldConfigs: {
      type: Boolean,
      required: false,
    },
    keyPositionPos: {
      type: String,
      required: true,
    },
    keyPositionBez: {
      type: String,
      required: true,
    },
    modelInitial: {
      type: Object,
      required: true,
    },
    optionsBelegBetragErfasst: {
      type: Object,
      required: true,
    },
    optionsBelegBez: {
      type: Object,
      required: true,
    },
    optionsBelegKommentar: {
      type: Object,
      required: true,
    },
    optionsBelegNummer: {
      type: Object,
      required: true,
    },
    optionsBelegPeriode: {
      type: Object,
      required: true,
    },
    optionsPosition: {
      type: Object,
      required: true,
    },
    position: {
      type: Object,
      required: false,
      default: undefined,
    },
    statusNew: {
      type: Boolean,
      required: true,
    },
    statusShowBelegBetragErfasst: {
      type: Boolean,
      required: true,
    },
    statusShowBelegnummer: {
      type: Boolean,
      required: true,
    },
    statusShowOptionsBelegKommentar: {
      type: Boolean,
      required: true,
    },
    statusShowOptionsPosition: {
      type: Boolean,
      required: true,
    },
    statusShowPeriode: {
      type: Boolean,
      required: true,
    },
    vertrag: {
      type: Object,
      required: true,
    },
    vorschau: {
      type: Object,
      required: true,
    },
    urlSave: {
      type: String,
      required: true,
    },
  },
};
