"use strict";

import ParamElementliste from "./elementliste";
import ParamElementlisteRegeldata from "./elementliste_regeldata";

const elementliste = angular.module("dias.elementliste.parameter", [
  ParamElementliste.name,
  ParamElementlisteRegeldata.name,
])
;
export default elementliste;
