"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";
import template from "./config.jade";
import controller from "./controller";
import controllerRo from "./readonly.controller.js";


const antragsteller_rechtsform_zusatz_zweige = angular.module("dias.antragsteller.parameter.antragsteller_rechtsform_zusatz_zweige", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("antragsteller_rechtsform_zusatz_zweige", "parametermodul-antragsteller-rechtsform-zusatz-zweige");
  })
  .component("parametermodulAntragstellerRechtsformZusatzZweige", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulAntragstellerRechtsformZusatzZweigeRo", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: template(),
    controller: controllerRo,
    controllerAs: "vm",
  });
export default antragsteller_rechtsform_zusatz_zweige;
