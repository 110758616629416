import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "DokumenteUploadAllDetails",
  components: {
    PuxIcon,
    PuxTranslate,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterDate,
      filterDefaultForEmpty,
      filterFileSize,
    } = FiltersAPI();

    return {
      filterDate,
      filterDefaultForEmpty,
      filterFileSize,
    };
  },
};
