"use strict";

import angular from "angular";
import pipes from "./pipes";
import spacedComponent from "./spaced.component";


const mod = angular.module("dias.ui.spaced", [pipes.name])

  .component("spaced", spacedComponent)
;

export default mod;
