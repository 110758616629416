import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import GeschaeftsregelModulChild from "../../../GeschaeftsregelModulChild/GeschaeftsregelModulChild.vue";
import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import translate from "../../../../../global/directives/translate";

import SNAPSHOT_MODES from "../../../../Snapshot/SnapshotModule/modes";

import { createNamespacedHelpers } from "vuex";

const {
  mapGetters,
} = createNamespacedHelpers("snapshot");

const SNAPSHOT_MAPPING = {
  delete: {
    text: "_TXT_GR_ELEMENTLISTE_GELOESCHT_",
    icon: "delete",
  },
  add: {
    text: "_TXT_GR_ELEMENTLISTE_NEU_",
    icon: "add",
  },
  change: {
    text: "_TXT_GR_ELEMENTLISTE_GEAENDERT_",
    icon: "changes",
  },
};

// @vue/component
export default {
  name: "ElementlisteElement",
  components: {
    FormstepDetail,
    GeschaeftsregelModulChild,
    SnapshotIcon,
  },
  directives: {
    translate,
  },
  props: {
    modelParameter: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    element: {
      type: Object,
      required: true,
    },
    elementIndex: {
      type: Number,
      required: true,
    },
    idPrefix: {
      type: String,
      default: "",
    },
    htmlRegelNummer: {
      type: String,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    deletable: {
      type: Boolean,
      required: true,
    },
    unterregelnObj: {
      type: Object,
      required: true,
    },
    obj: {
      type: Object,
      required: true,
    },
    statusSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    statusSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    snapshotMode: {
      type: String,
      default: "snapshot",
      validator: value => SNAPSHOT_MODES.indexOf(value) !== -1,
    },
    collapsedDefault: {
      type: Boolean,
      required: false,
      default: true,
    },
    regelIdWithParentsRegelId: {
      type: String,
      required: false,
      default: undefined,
    },
    onSave: {
      type: Function,
      default: undefined,
    },
  },
  emits: [
    "openConfirmDeleteElement",
  ],
  computed: {
    htmlId() {
      return `${ this.htmlRegelNummer }_${ this.elementIndex }`;
    },

    optionsFormstepDetail() {
      return {
        deleteCallback: this.openConfirmDeleteElementLocal,
        headerClass: this.headerClass,
        classBody: this.classBody,
        collapse: true,
        collapsedDefault: this.collapsedDefault,
      };
    },

    headerClass() {
      if (this.statusSnapshotDelete || this.statusSnapshotAdd) {
        return "version-bg";
      }
      return "bg_grey";
    },

    classBody() {
      if (this.statusSnapshotDelete || this.statusSnapshotAdd) {
        return "version_bg_light";
      }
      return "bg_grey";
    },

    modelLocal() {
      return this.model.element_regeln || {};
    },

    childSuffix() {
      const SUFFIX_DELETE = this.statusSnapshotDelete ? "_delete" : "";
      return `_${ this.elementIndex }${ SUFFIX_DELETE }`;
    },

    snapshotStatusKey() {
      if (this.statusSnapshotDelete) {
        return "delete";
      }
      if (this.statusSnapshotAdd) {
        return "add";
      }
      if (this.statusSnapshotChange) {
        return "change";
      }
      return "";
    },

    snapshotHeaderText() {
      if (this.snapshotStatusKey) {
        return SNAPSHOT_MAPPING[this.snapshotStatusKey].text;
      }
      return undefined;
    },

    snapshotIconType() {
      if (this.snapshotStatusKey) {
        return SNAPSHOT_MAPPING[this.snapshotStatusKey].icon;
      }
      return undefined;
    },

    statusSnapshotChange() {
      return this.GET_STATUS_DIFF_FOR_CHILD_SUFFIX(this.childSuffix);
    },

    snapshotChildrenLocal() {
      if (this.statusSnapshotAdd || this.statusSnapshotDelete) {
        return {};
      }
      if (this.snapshot && this.snapshot.element_regeln) {
        return this.snapshot.element_regeln;
      }
      return {};
    },

    elementHeaderLabel() {
      if (this.element.label) {
        return this.element.label;
      }
      return `${ this.modelParameter.element_bezeichnung } ${ this.elementIndex + 1 }`;
    },

    ...mapGetters([
      "GET_STATUS_DIFF_FOR_CHILD_SUFFIX",
    ]),
  },
  methods: {
    openConfirmDeleteElementLocal() {
      this.$emit("openConfirmDeleteElement", {
        element: this.element,
        elementIndex: this.elementIndex,
        elementHeaderLabel: this.elementHeaderLabel,
      });
    },

    childSaveLocal({ data, regel }) {
      return this.onSave({
        data,
        elementIndex: this.elementIndex,
        regel,
      });
    },

    openElement() {
      if (this.$refs.element) {
        this.$refs.element.openCollapse();
      }
    },
  },
};
