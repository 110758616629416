import {
  ref,
} from "vue";

import {
  EventBus,
} from "../../../../global/functions/event-bus";

export default function AntragWizardsAPI({ scrollTop }) {
  const statusAntragstellerWizard = ref(false);
  const openAntragstellerWizard = () => {
    statusAntragstellerWizard.value = true;
    scrollTop();
  };
  const closeWizardOrganisation = organisation => {
    statusAntragstellerWizard.value = false;
    EventBus.$emit("closeWizardOrganisation", { organisation });
  };

  const htmlIdForCloseLocal = ref(undefined);

  const statusShowPersonalkostenPerioden = ref(false);
  const personalkostenPerioden = ref({
    positionen: [],
    perioden: [],
  });
  const personalfunktionen = ref(undefined);
  const openPersonalKostenPerioden = ({ positionen, perioden, personalfunktionen, htmlIdForClose }) => {
    personalkostenPerioden.value = {
      positionen,
      perioden,
    };
    personalfunktionen.value = personalfunktionen;
    htmlIdForCloseLocal.value = htmlIdForClose;
    statusShowPersonalkostenPerioden.value = true;
  };
  const setFocusToElement = () => {
    const ELEMENT = $(`#${ htmlIdForCloseLocal.value }`);
    if (ELEMENT) {
      ELEMENT.focus();
    }
    htmlIdForCloseLocal.value = undefined;
  };
  const closePersonalKostenPerioden = () => {
    statusShowPersonalkostenPerioden.value = false;
    setFocusToElement();
  };

  const statusShowPersonalkostenPeriodenWizard = ref(false);
  const personalkostenPeriodenWizard = ref(undefined);
  const openPersonalKostenPeriodenWizard = arg => {
    personalkostenPeriodenWizard.value = arg;
    htmlIdForCloseLocal.value = arg.htmlIdForClose;
    statusShowPersonalkostenPeriodenWizard.value = true;
  };
  const closePersonalKostenPeriodenWizard = ({ statusReload, response } = {}) => {
    statusShowPersonalkostenPeriodenWizard.value = false;
    personalkostenPeriodenWizard.value = undefined;
    EventBus.$emit("closePersonalkostenPeriodenWizard", {
      statusReload,
      response,
      htmlIdForClose: htmlIdForCloseLocal.value,
    });
    setTimeout(() => {
      htmlIdForCloseLocal.value = undefined;
    });
  };


  return {
    statusAntragstellerWizard,
    openAntragstellerWizard,
    closeWizardOrganisation,

    statusShowPersonalkostenPerioden,
    personalkostenPerioden,
    personalfunktionen,
    openPersonalKostenPerioden,
    closePersonalKostenPerioden,

    statusShowPersonalkostenPeriodenWizard,
    personalkostenPeriodenWizard,
    openPersonalKostenPeriodenWizard,
    closePersonalKostenPeriodenWizard,
  };
}
