import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import ZuschussStandard from "../../KFDetail/ZuschussStandard/ZuschussStandard.vue";

import FinanzierugMixin from "../Mixins/FinanzierugMixin";
import ZuschussMixin from "../Mixins/ZuschussMixin";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "EhrenamtPauschale",
  components: {
    KFStandard,
    ZuschussStandard,
  },
  mixins: [
    FinanzierugMixin,
    ZuschussMixin,
  ],
  data() {
    return {
      allowsInput: true,
    };
  },
  computed: {
    statusNotLoad() {
      return this.kontext !== "zuschuss" && this.kontext !== "zuschuss_vertrag";
    },

    sumLocal() {
      return this.antragBetragCurrency;
    },

    statusReadonly() {
      return !this.hasModulPermissionUpdate || !this.statusEhrenamtMoeglich;
    },

    meldungen() {
      return get(this.infoprovider, `details.${ this.regel.afr_id }.meldungen`, []);
    },

    statusEhrenamtMoeglich() {
      // Die Erfassung von Ehrenamt ist nur dann möglich, wenn der Rechner
      // gesagt hat, dass es ok ist.
      if (!this.infoprovider || !this.infoprovider.status || !this.regel.pk) {
        return false;
      }
      const DETAIL_INFO = this.infoprovider.details[this.regel.pk];
      if (DETAIL_INFO) {
        return DETAIL_INFO.status === 1;
      }
      return false;
    },

    statusEhrenamtVisible() {
      return (this.statusEhrenamtMoeglich || this.statusHasValue || this.statusDiff);
    },

    statusHasValue() {
      return ((this.vertragFinanzierungsart && this.vertragFinanzierungsart.vf_betrag_aktualisiert > 0) ||
        (this.antragKoFiArt && this.antragKoFiArt.summe > 0));
    },
  },
};
