var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (diff) {
jade_mixins["foerdermodul"] = jade_interp = function(idx, title, info, diffBlock){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if ( info === undefined)
{
var info = "vm.modul.regel[vm.modul.prefix + '_astbez']"
}
var rulesKbez = "vm.modul.regel[vm.modul.prefix + '_kbez']"
var rulesBez = "vm.modul.regel[vm.modul.prefix + '_bezeichnung']"
buf.push("<div" + (jade.attr("ng-class", "{'dirty': !vm.isClean(), 'edit': vm.editMode, 'version-detail': " + (diffBlock) + ", 'version-loading': vm.loadingDiff}", true, true)) + " class=\"form-step-detail\"><dias-info-box" + (jade.attr("idx", "" + (idx) + "", true, true)) + (jade.attr("name", "" + (title) + "", true, true)) + (jade.attr("info-text", "" + (info) + "", true, true)) + (jade.attr("rules-kbez", "" + (rulesKbez) + "", true, true)) + (jade.attr("rules-bez", "" + (rulesBez) + "", true, true)) + " disabled=\"vm.loading\" is-readonly=\"vm.isReadonly()\" edit-mode=\"vm.editMode\" toggle-edit-mode=\"vm.toggleEditMode()\" is-missing-input=\"vm.isMissingInput()\" notizen=\"vm.notizen\" notiz-obj=\"vm.obj\" notizoptions=\"vm.notizoptions\" notizkey=\"vm.notizkey\" notiz-weitere-obj=\"[vm.modul.regel]\" tealium-track-info=\"vm.modul.regel.regel_nummer\" regel=\"vm.modul.regel\"></dias-info-box><form ng-submit=\"vm.saveChanges()\" name=\"{{:: vm.getFormName()}}\" novalidate dias-loading=\"vm.loading\" loading-msg=\"'Daten werden gespeichert'\" class=\"form-horizontal\">");
block && block();
buf.push("</form></div>");
};













jade_mixins["readonly-block"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div ng-if=\"vm.isReadonly() || !vm.globalBearbeiten\" ng-show=\"!vm.editMode\" ng-class=\"{'clearfix-hover' : vm.hoverPosition}\" class=\"clearfix box_hover\">");
block && block();
buf.push("</div><div ng-if=\"!vm.isReadonly() &amp;&amp; vm.globalBearbeiten\" ng-show=\"!vm.editMode\" ng-click=\"!vm.isReadonly() &amp;&amp; vm.globalBearbeiten ? vm.toggleEditMode() : null\" data-toggle=\"{{!vm.isReadonly() &amp;&amp; vm.globalBearbeiten ? 'edit' : ''}}\" ng-class=\"{ 'editable' : !vm.isReadonly() &amp;&amp; vm.globalBearbeiten }\" tabindex=\"-1\" class=\"clearfix box_hover\">");
block && block();
buf.push("</div>");
};































jade_mixins["edit-block-new"] = jade_interp = function(title){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<!--div(ng-if=\"vm.editEnteredOnce\" ng-show=\"vm.editMode\")--><div ng-if=\"vm.editEnteredOnce &amp;&amp; vm.editMode\"><fieldset><div ng-if=\"vm.hasErrors()\" role=\"alert\" class=\"alert alert-danger\"><p><strong>Bitte prüfen Sie Ihre Eingaben.</strong></p><ul ng-if=\"vm.errors\" class=\"list-unstyled no-padding\"><li ng-repeat=\"(key, err) in vm.errors\" class=\"no-padding no-margin\"><strong ng-if=\"vm.errorsLabel[key]\">{{vm.errorsLabel[key]}}</strong><span>{{ err[0] }}</span></li></ul></div>");
block && block();
buf.push("</fieldset><div class=\"form-actions edit-actions row\"><div class=\"col-lg-6\"><dias-requirement></dias-requirement></div><div class=\"col-lg-6 right\"><div class=\"form-actions\"><button type=\"submit\"" + (jade.attr("ng-attr-title", "Formular " + (title) + " speichern", true, true)) + " class=\"btn btn-primary primary-action test-save\">Speichern</button><button type=\"button\" ng-click=\"vm.setEditMode(false);\"" + (jade.attr("ng-attr-title", "Formular " + (title) + " abbrechen", true, true)) + " class=\"btn btn-icon secondary-action\">Abbrechen</button></div></div></div></div>");
};
jade_mixins["form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
jade_mixins["readonly-data"] = jade_interp = function(obj, label, snapshotStatus, date){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div class=\"box-left\"><div class=\"title-left\"><div>" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "</div><div" + (jade.attr("ng-if", "!" + (snapshotStatus) + "", true, true)) + " class=\"version-date\">({{ " + (jade.escape((jade_interp = date) == null ? '' : jade_interp)) + " }})</div></div></div><div class=\"box-right\"><dl class=\"dl-horizontal\"><dt>Vereins- oder Handelsregisternummer:</dt><dd><span" + (jade.attr("ng-if", "vm.snapshot && vm.showDiff() && " + (diff) + ".ast_registernummer", true, true)) + " class=\"glyphicon glyphicon-changes version-color version-left\"></span><span>{{ " + (jade.escape((jade_interp = obj) == null ? '' : jade_interp)) + ".ast_registernummer || '[Keine Angabe]' }}</span></dd></dl><dl class=\"dl-horizontal\"><dt>Zuständiges Gericht:</dt><dd><span" + (jade.attr("ng-if", "vm.snapshot && vm.showDiff() && " + (diff) + ".ast_gericht", true, true)) + " class=\"glyphicon glyphicon-changes version-color version-left\"></span><span>{{ " + (jade.escape((jade_interp = obj) == null ? '' : jade_interp)) + ".ast_gericht || '[Keine Angabe]' }}</span></dd></dl></div>");
};
jade_mixins["foerdermodul"].call({
block: function(){
jade_mixins["edit-block-new"].call({
block: function(){
buf.push("<div class=\"row\"><div class=\"col-sm-12 col-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input ng-model=\"vm.userInput.ast_registernummer\" ng-attr-name=\"ast_registernummer\" auto-focus=\"true\" aria-required=\"true\" class=\"form-control\">");
}
}, "ast_registernummer", "Vereins- oder Handelsregisternummer");
buf.push("</div></div><div class=\"row\"><div class=\"col-sm-12 col-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input ng-model=\"vm.userInput.ast_gericht\" ng-attr-name=\"ast_gericht\" auto-focus=\"false\" aria-required=\"true\" class=\"form-control\">");
}
}, "ast_gericht", "Zuständiges Gericht");
buf.push("</div></div>");
}
});
jade_mixins["readonly-block"].call({
block: function(){
buf.push("<fieldset class=\"fieldset-version\">");
jade_mixins["readonly-data"]("vm.userInput", "Aktuelle Version", "true");
buf.push("</fieldset><fieldset class=\"fieldset-version version\">");
jade_mixins["readonly-data"]("vm.getSnapshot()", "Vergleichsversion", "false", "vm.snapshot.ats_snapshot_datum | date:'dd.MM.yyyy HH:mm'");
buf.push("</fieldset>");
}
});
}
}, "vm.idx", "vm.parameter['frage_text']", undefined, "vm.showDiff()");}.call(this,"diff" in locals_for_with?locals_for_with.diff:typeof diff!=="undefined"?diff:undefined));;return buf.join("");
}