import {
  computed,
  ref,
  toRef,
} from "vue";

export default function SitzungsartenNotGroupedAPI(props) {
  const buttonId = toRef(props, "buttonId");
  const selectorClose = computed(() => {
    return `#${ buttonId.value }`;
  });

  const model = ref({});

  const optionsKbez = {
    type: "text",
    label: "_TXT_SITZUNGSART_INFO_NAME_",
    required: true,
    view: "v",
    id: "tom_kbez",
  };

  return {
    model,
    optionsKbez,
    selectorClose,
  };
}
