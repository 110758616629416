"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";
import template from "./config.jade";
import templateRo from "./readonly.jade";
import controller from "./controller";
import controllerRo from "./readonly.controller.js";


const dynMehrfachAuswahl = angular.module("dias.dynamische_mehrfachauswahl.parameter", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("dynamische_mehrfachauswahl", "parametermodul-dyn-antwort-liste");
  })
  .component("parametermodulDynAntwortListe", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulDynAntwortListeRo", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: templateRo(),
    controller: controllerRo,
    controllerAs: "vm",
  });
export default dynMehrfachAuswahl;
