import {
  getCurrentInstance,
  ref,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

import {
  cloneDeep,
} from "lodash-es";

export default function GeschaeftsregelDetailsAPI() {
  const APP = getCurrentInstance();
  const $stateParams = APP.appContext.config.globalProperties.$stateParams;

  const loading = ref(true);
  const regel = ref({});
  const funktionsgruppen = ref([]);

  const regelUrl = `foerderregeln/${ $stateParams.id }/`;
  const funktionsgruppenUrl = `${ regelUrl }funktionsgruppen/`;

  const {
    getHttp,
  } = HttpAPI();

  const loadFunktionsgruppen = () => {
    return getHttp({
      url: funktionsgruppenUrl,
    }).then(
      response => {
        funktionsgruppen.value = response;
        loading.value = false;
      }
    );
  };

  const loadRegel = () => {
    return getHttp({
      url: regelUrl,
    }).then(
      response => {
        regel.value = response;
        loadFunktionsgruppen();
      }
    );
  };

  const updateRegel = regelLocal => {
    regel.value = cloneDeep(regelLocal);
  };

  const updateFunktionsgruppen = ({ funktionsgruppenLocal }) => {
    funktionsgruppen.value = funktionsgruppenLocal;
  };


  return {
    funktionsgruppen,
    loading,
    loadRegel,
    regel,
    regelUrl,
    updateFunktionsgruppen,
    updateRegel,
  };
}
