import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "FoerderdokumenteBenennung",
  components: {
    FormstepDetail,
  },
  props: {
    foerderdokument: {
      type: Object,
      required: true,
    },
    foerderdokumenteUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "updateFoerderdokument",
  ],
  setup() {
    const {
      putHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      putHttp,
    };
  },
  data() {
    return {
      options: {
        label: "_TXT_FOERDERDOKUMENTE_DETAILS_FOERDERDOKUMENT_PANEL_BENNENUNG_FORMSTEP_NAME_",
        list: [
          {
            id: "fod_name",
            type: "text",
            label: "_LBL_FOERDERDOKUMENTE_DETAILS_BENENNUNG_NAME_",
            showLabel: true,
            view: "v-alt",
            required: true,
            maxlength: 60,
            translate: true,
          },
        ],
        required: true,
        saveCallback: this.save,
      }
    };
  },
  methods: {
    save({ model }) {
      const DATA = cloneDeep(model);
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.foerderdokumenteUrl,
          data: DATA,
        }).then(
          response => {
            this.$emit("updateFoerderdokument", { foerderdokument: response });
            this.addNotification({ text: "_MSG_FOERDERDOKUMENTE_DETAILS_BENENNUNG_UPDATE_" });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },
  },
};
