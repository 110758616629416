"use strict";

class FinanzierungsplanController {
  /*@ngInject*/
  constructor(i18n) {
    this.gettext = i18n.gettext;
    this.name = "FinanzierungsplanController";
    this.getGesamtfinanzierung = () => {
      if (this.infoprovider.status === 2) {
        let gesamt = this.finanzierungsplan.gesamtfinanzierung;
        gesamt = gesamt + this.infoprovider.zuschuss;
        // Die Deckungsluecke taucht als Pseudo-Position in der Rechnung
        // auf, wenn sie positiv ist, daher muss sie dann
        // hier mit eingerechnet gerechnet werden:
        if (this.infoprovider.deckungsluecke > 0) {
          gesamt = gesamt + this.infoprovider.deckungsluecke;
        }
        // Die Überfinanzierung (kommt hier negativ an) muss vom
        // Gesamtergebnis abgezogen werden da sie als Pseudo-Position
        // ausgegeben wird, damit die Rechnung stimmig ist.
        if (this.infoprovider.ueberfinanzierung < 0) {
          gesamt = gesamt + this.infoprovider.ueberfinanzierung;
        }
        return gesamt;
      }
      return this.finanzierungsplan.gesamtfinanzierung;
    };
  }
}

export default FinanzierungsplanController;
