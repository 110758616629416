import FinanzierugMixin from "../Mixins/FinanzierugMixin";
import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import ZuschussMixin from "../Mixins/ZuschussMixin";

// @vue/component
export default {
  name: "StandardZuschuss",
  components: {
    KFStandard,
  },
  mixins: [
    FinanzierugMixin,
    ZuschussMixin,
  ],
  computed: {
    sumLocal() {
      return this.antragBetragCurrency;
    },
  },
  methods: {
    loadVertragFinanzierungRest_FinanzierugMixin() {
      this.loading = false;
      this.reloading = false;
    },
  },
};
