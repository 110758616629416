import AngularLink from "../../global/components/AngularLink/AngularLink.vue";
import FormstepDetail from "../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "TheFoerderorganisationKennenlernen",
  components: {
    AngularLink,
    FormstepDetail,
    PuxTranslate,
  },
};
