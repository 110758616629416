import KFPositionForm from "../KFPositionForm/KFPositionForm.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotItem from "../../../Snapshot/SnapshotItem/SnapshotItem.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";

import {
  isNil,
  size,
  startsWith,
} from "lodash-es";

// @vue/component
export default {
  name: "ZuschussStandardPosition",
  components: {
    KFPositionForm,
    Modal,
    PuxButton,
    SnapshotIcon,
    SnapshotItem,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    position: {
      type: Object,
      required: true,
      info: "Zuschuss-Position",
    },
    positionIndex: {
      type: Number,
      required: true,
      info: "Index in Array",
    },
    htmlId: {
      type: String,
      required: true,
      info: "Parent-HTML-ID",
    },
    readonly: {
      type: Boolean,
      required: false,
      info: "",
    },
    singlePosition: {
      type: Boolean,
      required: false,
      default: () => true,
      info: "Von dieser Pauschale wird im Hintergrund eine einzelne Position verwaltet",
    },
    labelsPosition: {
      type: Object,
      required: false,
      default: () => ({}),
      info: "buttonTogglePositionTextHide, buttonTogglePositionTextShow, buttonAddText, buttonAddIcon, labelAdd, labelEdit, buttonEditTooltip, buttonDeleteTooltip, confirmDeleteHeader",
    },
    urlPositionen: {
      type: String,
      required: true,
      info: "vertraege/UUID/finanzierung/UUID/positionen/",
    },
    urlAddonUpdate: {
      type: String,
      default: () => "einzelposition_speichern/",
      info: "URL-Part für Position bearbeiten.",
    },
    urlAddonDelete: {
      type: String,
      default: () => "einzelposition_loeschen/",
      info: "URL-Part für Position löschen.",
    },
    betragsrechner: {
      type: String,
      required: true,
      info: "Betragsrechner",
    },
    katalogDetails: {
      type: Array,
      required: true,
      info: "",
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
      info: "",
    },
    extraForVorschau: {
      type: Object,
      required: false,
      default: undefined,
      info: "",
    },
    kontext: {
      type: String,
      required: true,
      info: "",
    },
    vertragFinanzierungsartFinanzierungsartKbez: {
      type: String,
      required: true,
      info: "this.vertragFinanzierungsart.finanzierungsart.fin_kbez",
    },
    vertragFinanzierungsartBetragBewilligtCurrency: {
      type: String,
      required: true,
      info: "this.filterCurrency(this.vertragFinanzierungsart.vf_betrag_bewilligt || 0)"
    },
    vertragBetragValue: {
      type: [String, Number],
      required: true,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    diffPosition: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    prepareModel: {
      type: Function,
      required: true,
    },
  },
  emits: [
    "update",
  ],
  data() {
    return {
      statusEdit: false,
      confirmShow: false,
      confirmOptions: undefined,
    };
  },
  computed: {
    htmlIdLocal() {
      return `${ this.htmlId }_${ this.positionIndex }`;
    },

    snapshotPositionCurrency() {
      // TODO: Snapshot
      return "";
    },

    buttonDeleteId() {
      return `${ this.htmlIdLocal }_delete`;
    },

    buttonEditId() {
      return `${ this.htmlIdLocal }_edit`;
    },

    ariaDescribedbyLocal() {
      // TODO: Barrierefreiheit
      return `${ this.ariaDescribedby }`;
    },

    buttonEditTooltip() {
      if (this.labelsPosition.buttonEditTooltip) {
        return this.labelsPosition.buttonEditTooltip;
      }
      return "_BTN_KF_POSITION_EDIT_TITLE_";
    },

    buttonDeleteTooltip() {
      if (this.labelsPosition.buttonDeleteTooltip) {
        return this.labelsPosition.buttonDeleteTooltip;
      }
      return "_BTN_KF_POSITION_DELETE_TITLE_";
    },

    canDelete() {
      return this.singlePosition || isNil(this.position.foerderantragfinanzierungsartpos);
    },

    confirmDeleteHeader() {
      if (this.labelsPosition.confirmDeleteHeader) {
        return this.labelsPosition.confirmDeleteHeader;
      }
      return "_TXT_KF_DELETE_CONFIRM_HEADER_";
    },

    confirmDeleteBody() {
      if (this.labelsPosition.confirmDeleteBody) {
        return this.labelsPosition.confirmDeleteBody;
      }
      return "_HTML_KF_DELETE_CONFIRM_BODY_";
    },

    urlSaveLocal() {
      return `${ this.urlPositionen }${ this.urlAddonUpdate }`;
    },

    extraTranslateLocal() {
      return {
        position: this.singlePosition ? "" : this.position.afp_bez,
        kofiart: this.singlePosition ? this.position.afp_bez : this.vertragFinanzierungsartFinanzierungsartKbez,
      };
    },

    positionValue() {
      if (this.singlePosition) {
        return this.vertragBetragValue;
      }
      return this.position.vfp_betrag_aktualisiert || this.position.afp_wert;
    },

    snapshotValue() {
      if (this.isSnapshotDelete) {
        return this.position.betragSnapshot;
      }
      return this.diffPosition.betragSnapshot;
    },

    isSnapshotAdd() {
      return !!this.diffPosition.add;
    },

    snapshotIconType() {
      if (this.isSnapshotAdd) {
        return "add";
      }
      if (this.isSnapshotDelete) {
        return "delete";
      }
      if (this.diffPosition.changes) {
        return "changes";
      }
      return "";
    },

    positionBez() {
      return this.position.afp_bez || this.position.vfp_bez;
    },
  },
  methods: {
    toggleEditPosition() {
      this.statusEdit = !this.statusEdit;
    },

    openDeletePosition() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: this.confirmDeleteHeader,
        msg: this.confirmDeleteBody,
        okLabel: "_BTN_DELETE_",
        okCallback: this.deletePosition,
        cancelCallback: this.closeConfirm,
        loading: false,
        extra: this.extraTranslateLocal,
      };
      this.confirmShow = true;
    },

    closeConfirm() {
      this.confirmShow = false;
    },

    deletePosition() {
      this.confirmOptions.loading = true;
      this.postHttp({
        url: `${ this.urlPositionen }${ this.urlAddonDelete }`,
      }).then(
        response => {
          this.closeConfirm();
          this.closeEditPosition();
          this.$emit("update", {
            response,
          });
        }
      ).then(
        () => {
          this.confirmOptions.loading = false;
        }
      );
    },

    updateLocal({ response }) {
      this.$emit("update", { response });
    },

    closeEditPosition() {
      this.statusEdit = false;
    },

    getValueCls(valueStr) {
      valueStr = `${ valueStr }`;
      return (startsWith(valueStr, "-") && size(valueStr) > 1) ? "text-danger" : "";
    },
  },
};
