import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";

import PermissionMixin from "../../global/mixins/PermissionMixin";

import { createNamespacedHelpers } from "vuex";
import {
  forEach,
} from "lodash-es";

const {
  mapGetters
} = createNamespacedHelpers("notizen");

// @vue/component
export default {
  components: {
    PuxTranslate,
  },
  mixins: [
    PermissionMixin,
  ],
  props: {
    notizenKey: {
      type: String,
      required: false,
      default: undefined,
    },
    notizenObjektid: {
      type: String,
      required: false,
      default: undefined,
    },
    notizenObjektContentType: {
      type: Number,
      required: false,
      default: undefined,
    },
    mainObjektid: {
      type: String,
      required: false,
      default: undefined,
      info: "Für Notizen in Dokumente",
    },
    mainContentType: {
      type: Number,
      required: false,
      default: undefined,
      info: "Für Notizen in Dokumente",
    },
  },
  data() {
    return {
      fuerSitzung: false,
      fuerAntragstellerorg: false,
      fuerFoerderorg: false
    };
  },
  computed: {
    notizenForKey() {
      if (this.notizenObjektid) {
        if (this.mainObjektid) {
          return this.notizenForDocuments;
        }
        return this.notizenForObjektid;
      }

      if (this.notizenKey) {
        if (this.GET_NOTIZEN_OBJ[this.notizenKey]) {
          if (this.singlenotiz) {
            return [{
              list: [this.GET_NOTIZEN_OBJ[this.notizenKey][this.singlenotizIndex]],
              key: this.notizenKey,
            }];
          }
          return [{
            list: this.GET_NOTIZEN_OBJ[this.notizenKey],
            key: this.notizenKey,
          }];
        }
        return [{
          list: [],
          key: this.notizenKey,
        }];
      }
      return this.GET_NOTIZEN_ALL;
    },

    notizenList() {
      const LIST = [];
      forEach(this.notizenForKey, item => {
        if (item.list && item.list.length) {
          LIST.push(...item.list);
        }
      });
      return LIST;
    },

    notizenForDocuments() {
      const LIST = this.GET_NOTIZEN_DOCUMENTS[this.notizenObjektid] &&
      this.GET_NOTIZEN_DOCUMENTS[this.notizenObjektid][this.mainObjektid];
      return [{
        list: LIST || [],
        key: this.notizenKey,
      }];
    },

    notizenForObjektid() {
      return [{
        list: this.GET_NOTIZEN_WEITERE_OBJEKTE[this.notizenObjektid] || [],
        key: this.notizenKey,
      }];
    },

    ...mapGetters([
      "GET_NOTIZEN_ALL",
      "GET_NOTIZEN_OBJ",
      "GET_NOTIZEN_OPTIONS",
      "GET_NOTIZEN_WEITERE_OBJEKTE",
      "GET_NOTIZEN_DOCUMENTS",
    ]),
  },
  created() {
    this.setFuerSitzung();
    this.setFuerAntragstellerorg();
    this.setFuerFoerderorg();
  },
  methods: {
    setFuerSitzung() {
      this.fuerSitzung = this.GET_NOTIZEN_OPTIONS.fuerSitzung && this.checkPermissionsSync({ perm: "sitzungen.sitzungsnotizen" });
    },

    setFuerAntragstellerorg() {
      this.fuerAntragstellerorg = !!(this.GET_NOTIZEN_OPTIONS.antragstellerorg) && this.GET_NOTIZEN_OPTIONS.fuerAntragstellerorg;
    },

    setFuerFoerderorg() {
      this.fuerFoerderorg = !!(this.GET_NOTIZEN_OPTIONS.orgList && this.GET_NOTIZEN_OPTIONS.orgList.length);
    },
  },
};
