"use strict";

import template from "./template.jade";

class RueckforderungBetragsrechnerController {
  /*@ngInject*/
  constructor(i18n, Katalog) {
    this.gettext = i18n.gettext;
    this.Katalog = Katalog;
    if (!this.kontext) {
      this.kontext = "antrag";
    }
    this.gruende = this.Katalog.getChoices("krueckforderungsgrund", "pk", "rg_kbez");
    this.selectedGrund = [];
    if (this.posmodel.argument && this.posmodel.argument.grund) {
      this.selectedGrund = [{ id: this.posmodel.argument.grund }];
    }
  }

  selectionChanged() {
    if (this.selectedGrund.length > 0) {
      this.posmodel.argument.grund = this.selectedGrund[0].id;
    } else {
      this.posmodel.argument.grund = null;
    }
  }
}

export default {
  template: template(),
  controller: RueckforderungBetragsrechnerController,
  controllerAs: "ctx",
  bindings: {
    rechner: "=",
    posmodel: "=",
    errors: "=",
    extras: "=",
    kontext: "=?",
    vorschau: "=",
    prefix: "<?"
  },
};
