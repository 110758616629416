import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

// @vue/component
export default {
  name: "NutzerModalAnonymisierenAll",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
      },
      status: {
        loading: undefined,
      },
    };
  },
  methods: {
    anonymisieren() {
      this.status.loading = true;

      this.postHttp({ url: `nutzer/alle_anonymisieren/` }).then(
        response => {
          this.addNotification({
            text: "_MSG_NUTZER_MODAL_ANONYMISIEREN_ALL_SUCCESS_{{count}}_",
            extra: {
              count: response.anon_count
            } });
          this.close({ status: true });
        },
        () => {
          this.addNotification({ text: "_MSG_NUTZER_MODAL_ANONYMISIEREN_ALL_ERROR_", type: "error" });
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
