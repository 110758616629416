import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";

import DashboardModuleMixin from "../DashboardModuleMixin";
import HttpMixin from "../../../../global/mixins/HttpMixin";

import {
  createNamespacedHelpers,
} from "vuex";
import {
  cloneDeep,
  filter,
  forEach,
  keyBy,
  some,
} from "lodash-es";

const {
  mapMutations,
} = createNamespacedHelpers("faq");

// @vue/component
export default {
  name: "DashboardFaqFilter",
  components: {
    PuxTranslate,
    FormElement,
    PuxButton,
  },
  mixins: [
    DashboardModuleMixin,
    HttpMixin,
  ],
  props: {
    argument: {
      type: Object,
      required: true,
      info: "Hauptinformation",
    },
  },
  data() {
    return {
      modelSearch: undefined,
      modelSearchActive: undefined,
      modelMediaTypes: [],
      mediaTypes: [],
      mediaTypesMapByPk: {},
      classesForBox: {
        edit: "edit-box",
        success: "success-box",
        info: "info help",
      },
      optionsForFuzzySearch: {
        id: "faq_fuzzy_search",
        type: "text",
        view: "v",
        label: "_LBL_DASHBOARD_FAQ_FILTER_SUCHE_",
      },
      optionsForMediaTypes: {
        id: "faq_media_types",
        type: "select",
        view: "v",
        label: "_LBL_DASHBOARD_FAQ_FILTER_MEDIENFORMATE_",
        change: this.changeMediaType,
        keyId: "pk",
        keyLabel: "kmd_kbez",
        search: true,
        searchList: ["kmd_kbez"],
      },
      loadingData: false,
      reloading: false,
      rubricsAll: [],
      rubrics: [],
      mediaTypesInRubric: {},
    };
  },
  computed: {
    filterList() {
      const LIST = [];
      if (this.modelSearchActive) {
        LIST.push({
          key: "search",
          label: this.modelSearchActive,
        });
      }
      forEach(this.modelMediaTypes, type => {
        LIST.push({
          key: "mediaType",
          value: type,
          label: this.mediaTypesMapByPk[type].bez,
        });
      });
      return LIST;
    },

    classBox() {
      if (!this.parameter) {
        return;
      }
      return this.classesForBox[this.parameter.css_klasse || "info"];
    },

    mediaTypesFiltered() {
      return filter(this.mediaTypes, mediaType => this.modelMediaTypes.indexOf(mediaType.pk) === -1);
    },

    searchButtonStyle() {
      return this.reloading || this.loadingData ? "" : "width: 30px;padding-left: 7px;";
    },

    rubricsMapByPkFilteredWithMediaTypes() {
      const RUBRICS_MAP_BY_PK = {};
      const RUBRICS = cloneDeep(this.rubrics);
      forEach(RUBRICS, rubric => {
        RUBRICS_MAP_BY_PK[rubric.pk] = rubric;
        RUBRICS_MAP_BY_PK[rubric.pk].faq_eintraege = this.getFilteredFaqEintraegeWithMediaTypes(rubric.faq_eintraege);
      });
      return RUBRICS_MAP_BY_PK;
    },
  },
  created() {
    this.loadData();
  },
  beforeUnmount() {
    this.MUT_RESET();
  },
  methods: {
    loadData() {
      this.loadingData = true;
      Promise.all([
        this.getListHttp({
          url: "faqrubrikliste/",
        }),
        this.getListHttp({
          url: "katalog/",
          urlParams: {
            key: "medientyp",
          },
          apiSaveId: "medientyp",
        }),
        this.getListHttp({
          url: `schlagworte/`,
          urlParams: {
            for_model: "faq.faqeintrag",
          },
        }),
      ]).then(
        responses => {
          this.rubricsAll = responses[0];
          this.rubrics = cloneDeep(this.rubricsAll);
          this.mediaTypes = responses[1];
          this.mediaTypesMapByPk = keyBy(this.mediaTypes, "pk");
          this.MUT_SET_KEYWORDS_MAP_BY_PK(keyBy(responses[2], "pk"));
          this.MUT_SET_RUBRICS(this.rubricsMapByPkFilteredWithMediaTypes);
        }
      ).then(
        () => this.loadingData = false
      );
    },

    reloadRubrikData() {
      if (!this.modelSearchActive) {
        this.rubrics = cloneDeep(this.rubricsAll);
        this.MUT_SET_RUBRICS(this.rubricsMapByPkFilteredWithMediaTypes);
        return;
      }
      this.reloading = true;
      this.getListHttp({
        url: "faqrubrikliste/",
        urlParams: {
          unscharfe_suche: this.modelSearchActive,
        },
      }).then(
        response => {
          this.rubrics = response;
          this.MUT_SET_RUBRICS(this.rubricsMapByPkFilteredWithMediaTypes);
        }
      ).then(
        () => this.reloading = false
      );
    },

    onSearch() {
      this.modelSearchActive = this.modelSearch;
      this.reloadRubrikData();
      this.MUT_SET_MODEL_SEARCH(this.modelSearchActive);
    },

    removeFilter({ filter }) {
      if (filter.key === "search") {
        this.removeFilterSearch();
      } else if (filter.key === "mediaType") {
        this.removeFilterMediaType(filter);
      }
    },

    removeFilterSearch() {
      this.modelSearch = undefined;
      this.onSearch();
    },

    removeFilterMediaType(filter) {
      this.modelMediaTypes.splice(this.modelMediaTypes.indexOf(filter.value), 1);
      this.MUT_SET_MODEL_MEDIA_TYPES(this.modelMediaTypes);
      this.MUT_SET_RUBRICS(this.rubricsMapByPkFilteredWithMediaTypes);
    },

    changeMediaType({ currentModel }) {
      this.modelMediaTypes.push(currentModel);
      this.MUT_SET_MODEL_MEDIA_TYPES(this.modelMediaTypes);
      this.MUT_SET_RUBRICS(this.rubricsMapByPkFilteredWithMediaTypes);
    },

    getFilteredFaqEintraegeWithMediaTypes(eintraege) {
      if (!this.modelMediaTypes.length) {
        return eintraege;
      }
      const EINTRAEGE = [];
      forEach(eintraege, eintrag => {
        if (this.hasCurrentFaqEintragOneMediaTypeFromModel(eintrag)) {
          EINTRAEGE.push(eintrag);
        }
      });
      return EINTRAEGE;
    },

    hasCurrentFaqEintragOneMediaTypeFromModel(eintrag) {
      return some(eintrag.typen_ids, mediaType => this.modelMediaTypes.indexOf(mediaType) !== -1);
    },

    ...mapMutations([
      "MUT_SET_RUBRICS",
      "MUT_SET_MODEL_MEDIA_TYPES",
      "MUT_SET_MODEL_SEARCH",
      "MUT_SET_KEYWORDS_MAP_BY_PK",
      "MUT_RESET",
    ]),
  },
};
