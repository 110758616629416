import SyConfigMixin from "../global/mixins/SyConfigMixin";

export default {
  mixins: [
    SyConfigMixin,
  ],
  data() {
    return {
      showTitel: undefined,
    };
  },
  methods: {
    initTitel() {
      this.showTitel = this.getSyConfigsValue("nutzer_titel");
    }
  },
  computed: {
    getFullName() {
      return _getFullName({ nutzer: this.nutzer });
    },

    getFullNameWithTitle() {
      return _getFullName({ nutzer: this.nutzer, title: true, conf: this.showTitel });
    },

    getFullNameWithParams() {
      return ({ nutzer }) => {
        return _getFullName({ nutzer });
      };
    },

    getFullNameWithTitleWithParams() {
      return ({ nutzer }) => {
        return _getFullName({ nutzer, title: true, conf: this.showTitel });
      };
    },
  },
  created() {
    this.initTitel();
  },
};

function _getFullName({ nutzer, title, conf }) {
  if (!nutzer) {
    return "";
  }
  const parts = [];
  const TITEL = conf ? nutzer.titel : nutzer.n_titel;
  if (title && TITEL) {
    parts.push(TITEL);
  }
  if (nutzer.n_vorname) {
    parts.push(nutzer.n_vorname);
  }
  if (nutzer.n_nachname) {
    parts.push(nutzer.n_nachname);
  }
  if (parts.length === 0) {
    parts.push(nutzer.n_loginname);
  }
  return parts.join(" ");
}
