"use strict";

import angular from "angular";
import AutosizeDirective from "./autosize.directive";

const Autosize = angular.module("dias.autosize", [])

  .directive("autosize", AutosizeDirective);

export default Autosize;
