import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import WorkflowFolgen from "../../../../Workflowfolgen/WorkflowFolgen/WorkflowFolgen.vue";

// @vue/component
export default {
  name: "AuszahlungEinreichenStep",
  components: {
    FormstepDetail,
    PuxButton,
    PuxTranslate,
    WorkflowFolgen,
  },
  props: {
    urlAuszahlung: {
      type: String,
      required: true,
    },
    aufgabe: {
      type: Object,
      required: false,
      default: undefined,
    },
    updateOnChangeWf: {
      type: String,
      required: false,
      default: undefined,
    },
    onStartedWf: {
      type: Function,
      required: true,
    },
    onCanceledWf: {
      type: Function,
      required: true,
    },
    onInitWf: {
      type: Function,
      required: true,
    },
    extraWf: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "closeWizard",
  ],
  data() {
    return {
      optionsFormstepDetail: {
        hideHeader: true,
      },
    };
  },
  methods: {
    closeWizardLocal() {
      this.$emit("closeWizard");
    },
  },
};
