"use strict";


/**
 * Basisklasse für alle Models der REST-API.
 */
class BaseModel {
  /**
   * @param  {string} path Pfad zur REST-API für das Model
   * @param  {object} conf Konfigurations-Objekt für Model. Kann aktuell
   * 2 Konfigurationsparameter enthalten: pk und subPaths:
   * - pk: Name des Model-Attributs, das als Primärschlüssel verwendet werden soll
   * - subPaths: Objekt von Definition für Unterlemente in der Form
   * {name: definition}. Schlüssel des Objekts werden als Namen der Unterelemente
   * verwendet. Unter diesem Namen wird ein vorkonfiguriertes Restangular-Objekt
   * für das Unterlement bereitstehen. Die Definition kann entweder ein Pfad
   * zum Unterlement, oder eine Instanz eines anderen Model-Objekts sein.
   */
  constructor(path, conf) {
    this.defaultPk = "pk";
    this.path = path;
    this.subPaths = this.isConfigured(conf, "subPaths") ? conf.subPaths : {};
    this.pk = this.isConfigured(conf, "pk") ? conf.pk : this.defaultPk;
  }

  isConfigured(conf, attr) {
    return !angular.isUndefined(conf) && !angular.isUndefined(conf[attr]);
  }

  /**
   * Transformiert entsprechend der getDefaultExtendCollection Methode
   * mit dem am Model definierten PK-Attribut
   */
  extendCollection(collection) {
    collection.restangularizeElement = element => {
      this.Restangular.restangularizeElement(undefined, element, this.path, {});
    };
    collection.restangularizeCollection = collection => {
      this.Restangular.restangularizeCollection(undefined, collection, this.path, {});
    };
    return this.getDefaultExtendCollection(this.pk)(collection);
  }

  /**
   * Transformiert entsprechend der defaultExtendModel Methode und
   * fügt ein Attribut für alle konfigurierten Unterelemente hinzu
   */
  extendModel(model) {
    if (angular.isObject(model)) {
      model = this.getDefaultExtendModel()(model);
      for (const key in this.subPaths) {
        const data = this._getSubPathData(this.subPaths[key]);
        model[key] = this.configure(this.Restangular).service(data.path, model);
      }
    }
    return model;
  }

  /**
   * Fügt eine getById und getChoices Method an Collections hinzu.
   * Dafür werden die Methoden createGetByAttribute und createGetChoices
   * verwendet.
   *
   * @param {string}  pk  Name des Attributs, dass als Primärschlüssel verwendet werden soll.
   */
  getDefaultExtendCollection(pk) {
    return collection => {
      collection.getById = this.createGetByAttribute(collection, pk);
      collection.getChoices = this.createGetChoices(collection);
      collection.merge = this.createMerge();
      return collection;
    };
  }

  /**
   * Fügt eine merge-Methode zu Instanzen dieses models hinzu.
   * Dafür wird die Methode createMerge verwendet
   */
  getDefaultExtendModel() {
    return model => {
      model.getRestangularService = () => this.restangularService;
      model.merge = this.createMerge();
      return model;
    };
  }

  onServiceBuild() {}

  /**
   * Baut ein Datenpaket für das Anfügen eines Unterlementes zusammen.
   *
   * Ist das Unterelement als String definiert, werden die Standard-Methoden
   * zum definieren der Erweiterungen der Instanzen und Listen verwendet.
   *
   * Ist das Unterelement als Model definiert, werden die Methoden dieses
   * Models zum definieren der Erweiterungen für Instanzen und Listen verwendet.
   *
   * @param  {string|object} Definition eines Unterelements
   * @return {object}        Objekt mit allen benötigten Daten zum Konfigurieren
   *                         eines Unterelements.
   */
  _getSubPathData(subPath) {
    const data = {
      path: undefined,
      extendModels: [],
      extendCollections: [],
    };
    if (angular.isString(subPath)) {
      data.path = subPath;
      data.extendModels.push(this.getDefaultExtendModel().bind(this));
      data.extendCollections.push(this.getDefaultExtendCollection(this.defaultPk).bind(this));
    } else {
      data.path = subPath.path;
      subPath.$q = this.$q;
      subPath.Restangular = this.Restangular;
      subPath.$injector = this.$injector;
      subPath.$window = this.$window;
      subPath.$state = this.$state;
      subPath.DiasNotification = this.DiasNotification;
      subPath.onServiceBuild();
      data.extendModels.push(subPath.extendModel.bind(subPath));
      data.extendCollections.push(subPath.extendCollection.bind(subPath));
    }
    return data;
  }


  /**
   * Konfiguriert ein Unterelement für das aktuelle Model.
   * Sind am aktuellen Unterlement weitere Unterelemente definiert, werden diese
   * rekursiv konfiguriert.
   *
   * @param  {type} Restangular Restangular-Objekt zum Konfigurieren des Models
   * @param  {type} name        Name des Unterelements
   * @param  {type} subPath     Konfiguration des Unterelements
   */
  buildSubPath(Restangular, name, subPath) {
    const data = this._getSubPathData(subPath);

    if (angular.isFunction(this[name + "ExtendModel"])) {
      data.extendModels.push(this[name + "ExtendModel"].bind(this));
    }
    if (angular.isFunction(this[name + "ExtendCollection"])) {
      data.extendCollections.push(this[name + "ExtendCollection"].bind(this));
    }
    for (let i = 0; i < data.extendModels.length; i++) {
      Restangular.extendModel(data.path, data.extendModels[i]);
    }
    for (let i = 0; i < data.extendCollections.length; i++) {
      Restangular.extendCollection(data.path, data.extendCollections[i]);
    }
    // build subPaths of subPath recursivly
    if (angular.isObject(subPath.subPaths)) {
      for (const key in subPath.subPaths) {
        subPath.buildSubPath(Restangular, key, subPath.subPaths[key]);
      }
    }
  }

  onError(response, deferred, responseHandler) {
    const ERROR_HANDLER = () => {
      const handler = this["on" + response.status];
      // if Handler is defined for this Status call it
      if (angular.isFunction(handler)) {
        return handler.bind(this)(response);
      }
    };
    if (response.status > 500 && response.status != 503) { // Try to send Server Error to Sentry
      const do_retry = retry => {
        if (retry > 3) {
          try {
            this.Raven.captureException(new Error("Server Response " + response.status + " on Restangular-Request"), response);
          } catch (err) {
            console.error(err);
          }
          ERROR_HANDLER();
          return;
        }
        const RANDOM_DELAY = Math.round(Math.random() * 600) + 150;
        console.warn(`Error ${ response.status }. Retry ${ retry } in ${ RANDOM_DELAY }ms...`);
        setTimeout(() => {
          this.$http(response.config).then(responseHandler, () => do_retry(retry + 1));
        }, RANDOM_DELAY);
      };
      do_retry(1);
      return false;
    }
    ERROR_HANDLER();
    // Let the promise handle the error
    return true;
  }

  configRestangular() {}

  /**
   * Baut einen Restangular Service für das aktuelle Model.
   */
  /*@ngInject*/
  $get($injector, $window, $q, $state, DiasNotification, Raven, Restangular) {
    this.$q = $q;
    this.$window = $window;
    this.$injector = $injector;
    this.$state = $state;
    this.$http = $injector.get("$http");
    this.DiasNotification = DiasNotification;
    this.Raven = Raven;
    this.Restangular = this.configure(Restangular);

    // extend restangularized elements
    this.Restangular.extendCollection(this.path, this.extendCollection.bind(this));
    this.Restangular.extendModel(this.path, this.extendModel.bind(this));

    // Set ErrorHandler
    // Restangular.setErrorInterceptor(this.onError.bind(this));

    // custom Service Build
    this.onServiceBuild();

    // configure subPaths
    for (const key in this.subPaths) {
      this.buildSubPath(this.Restangular, key, this.subPaths[key]);
    }

    this.restangularService = this.Restangular.service(this.path);

    return this.restangularService;
  }

  /**
   * Gibt eine Konfigurierte Instanz des Restangular Services zurück.
   *
   * @param  {object}  Restangular Service
   */
  configure(Restangular) {
    return Restangular.withConfig(RestangularConfigurer => {
      RestangularConfigurer.setRestangularFields({ id: this.pk });
      RestangularConfigurer.setErrorInterceptor((response, deferred, responseHandler) => this.onError(response, deferred, responseHandler));
      this.configRestangular(RestangularConfigurer);
    });
  }

  /**
   * Default Merge-Implementation für Restangular-Objekte
   *
   * Merged das zweite Objekt in das erste
   *
   * @param  {object}  model an das kopiert werden können soll
   */
  createMerge() {
    return (me, other) => {
      const merge = (me, other) => {
        if (angular.isFunction(other.plain)) {
          other = other.plain();
        }
        // plain() behält funktionen aus extend, daher müssen die attribute einzeln
        // zusammengeführt werden
        for (const key in other) {
          // Funktionen nicht kopieren um scopes beizubehalten
          if (angular.isFunction(other[key])) {
            continue;
          } else if (angular.isArray(other[key])) {
            if (angular.isArray(me[key])) {
              me[key].splice(0);
            } else {
              me[key] = [];
            }
            me[key] = merge(me[key], other[key]);
          } else if (angular.isObject(other[key])) {
            if (angular.isUndefined(me[key]) || me[key] === null) {
              me[key] = {};
            }
            merge(me[key], other[key]);
          } else {
            me[key] = other[key];
          }
        }
        return me;
      };
      return merge(me, other);
    };
  }

  /**
   * Erstellt eine Methode zum durchsuchen einer Liste nach einem Objekt anhand
   * vom angegebenen Attribut.
   *
   * @param  {array}  collection Zu durchsuchende Liste
   * @param  {string} attrName   Name des Attributs, das zum ermitteln der
   *                             Gleichheit verwendet werden soll.
   * @return {function}          Funktion zum durchsuchen der Liste nach dem Attribut
   */
  createGetByAttribute(collection, attrName) {
    return attrValue => {
      for (let i = 0; i < collection.length; i++) {
        if (collection[i][attrName] === attrValue) {
          return collection[i];
        }
      }
      return undefined;
    };
  }

  /**
   * Erstellt eine Methode zum abrufen einer Optionsliste für Select-Elemente
   *
   * @param  {array} collection Restangular-Listenobjekt für das die Optionen
   *                            erstellt werden können
   * @return {function}         Funktion zum Abrufen einer Optionsliste anhand
   *                            von gegebenen value- und label-Feldern
   */
  createGetChoices(collection) {
    return (valueField, labelField, params) => {
      const deferred = this.$q.defer();
      const data = [];
      params = params || {};
      params.fields = params.fields || [];
      params.fields.push(valueField, labelField);
      collection.getList(params).then(result => {
        angular.forEach(result, item => {
          data.push(Object.assign({}, item.plain(), { value: item[valueField], label: item[labelField] }));
        });
        deferred.resolve(data);
      });
      return deferred.promise;
    };
  }

  on401() {
    // this.$state.go("root.login");
    this.$window.location.assign("/login/?m=session_expired&next=" + this.$window.location.pathname + this.$window.location.search);
    return false;
  }

  on403(response) {
    if (response && angular.isObject(response.data) &&
       angular.isString(response.data.detail) && response.data.detail.startsWith("CSRF Failed")) {
      // entferne gespeicherte Tokens um clientseitig auszuloggen
      this.Restangular.all("auth").customPOST({}, "logout").then(
        () => {
          this.$window.location.assign("/login/?m=session_expired&next=" + this.$window.location.pathname + this.$window.location.search);
        },
        () => {
          this.DiasNotification.page.clear();
          this.DiasNotification.page.error(
            "Bitte melden Sie sich einmal ab und wieder an." +
            " Um das Problem zu lösen.",
            "Es ist ein Problem mit Ihrer Anmeldung aufgetreten"
          );
        }
      );
      // Fehler muss nicht mehr von Aufrufer verarbeitet werden
      return false;
    }
    this.DiasNotification.page.clear();
    this.DiasNotification.page.error(
      "Sie haben nicht die notwendigen Berechtigungen, um diese Aktion auszuführen",
      "Unzureichende Berechtigung"
    );
    return true;
  }

  on404() {
    this.$state.go("root.errors.404", {}, { location: false });
    return false;
  }

  on500(response) {
    const params = {};
    params.sentryId = response.headers("X-Sentry-ID");
    this.$state.go("root.errors.500", params, { location: false });
    return false;
  }

  on503(response) {
    const params = {};
    params.sentryId = response.headers("X-Sentry-ID");
    this.$state.go("root.errors.503", params, { location: false });
    return false;
  }

  on502(response) {
    const params = {};
    params.sentryId = response.headers("X-Sentry-ID");
    this.$state.go("root.errors.504", params, { location: false });
    return false;
  }

  on504(response) {
    const params = {};
    params.sentryId = response.headers("X-Sentry-ID");
    this.$state.go("root.errors.504", params, { location: false });
    return false;
  }

  on429(response) {
    this.DiasNotification.page.clear();
    if (response && angular.isObject(response.data) && angular.isString(response.data.detail)) {
      this.DiasNotification.page.error(response.data.detail);
    } else {
      this.DiasNotification.page.error("Sie haben die maximale Anzahl von Anfragen überschritten. Sie können später erneut versuchen");
    }
    return false;
  }
}

export default BaseModel;
