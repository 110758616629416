import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import HttpMixin from "../../../global/mixins/HttpMixin";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import WorkflowsDetailsStammdaten from "./Stammdaten/Stammdaten.vue";
import WorkflowsDetailsVisualisierung from "./Visualisierung/Visualisierung.vue";

// @vue/component
export default {
  name: "WorkflowsDetails",
  components: {
    PuxPageDetails,
    ContextPanel,
    Modal,
    Permission,
    PuxButton,
    PuxTranslate,
    WorkflowsDetailsStammdaten,
    WorkflowsDetailsVisualisierung,
  },
  mixins: [
    HttpMixin,
  ],
  data() {
    return {
      loading: true,
      confirmShow: undefined,
      confirmOptions: undefined,
      workflow: {},
      buttonDeleteId: "wf_details_modal_delete",
      panelStammdatenOptions: {
        label: "_TXT_WF_DETAILS_PANEL_STAMMDATEN_",
        icon: "glyphicon-file",
        active: true,
        pk: "stammdaten",
      },
      panelVisualisierungOptions: {
        label: "_TXT_WF_DETAILS_PANEL_VISUALISIERUNG_",
        icon: "glyphicon-file",
        active: true,
        pk: "visualisierung",
      },
    };
  },
  computed: {
    selectorClose() {
      return `#${ this.buttonDeleteId }`;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getHttp({
        url: `workflows/${ this.$stateParams.id }/`,
      }).then(
        response => {
          this.workflow = response;
          this.loading = false;
        }
      );
    },

    confirmDeleteRolle() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_WF_DETAILS_DELETE_MODAL_TITLE_{{kbez}}_",
        msg: "_TXT_WF_DETAILS_DELETE_MODAL_BODY_{{kbez}}_",
        okLabel: "_TXT_WF_DETAILS_DELETE_",
        okCallback: this.deleteWorkflow,
        cancelCallback: this.closeConfirm,
        extra: {
          kbez: this.workflow.wf_kbez,
        },
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteWorkflow() {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: `workflows/${ this.workflow.pk }/`,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_WF_DETAILS_DELETE_SUCCESS_",
          });
          this.closeConfirm();
          this.$goTo("root.workflows");
        },
        () => {
          this.addNotification({
            text: "_MSG_WF_DETAILS_DELETE_ERROR_",
            type: "error",
          });
        }
      ).then(
        () => this.confirmOptions.loading = false
      );
    },

    closeConfirm() {
      this.confirmShow = false;
    },

    updateWorkflowFromChild({ workflow }) {
      this.workflow = workflow;
    },
  },
};
