"use strict";

import template from "./documentdownload.jade";
import downloadModalController from "./download.modal.controller";
import downloadModalTemplate from "./download.modal.template.jade";

class DocumentDownloadController {
  /*@ngInject*/
  constructor($window, DiasNotification, diasModalDialog) {
    this.$window = $window;
    this.DiasNotification = DiasNotification;
    this.diasModalDialog = diasModalDialog;
    this.options = []; // Zur Verfügung stehende Konfigurationsoptionen
    this.loading = false;
    this.saveDocument = this.saveDocument.bind(this);
  }

  getDocument() {
    let params = {};
    if (this.callparams) {
      params = angular.copy(this.callparams);
    }
    if (this.noconfig && this.downloadmode === "synchron") {
      this.saveDocument();
    } else {
      params.get_config_info = true;
      this.loading = true;
      this.source.one(this.apicall).get(params).then(
        response => {
          this.loading = false;
          this.options = response.config_options;
          this.filename = response.filename;
          this.infotext = response.infotext;
          this.diasModalDialog({
            title: "Dokument erstellen",
            template: downloadModalTemplate,
            controller: downloadModalController,
            extras: { options: this.options,
                      apicall: this.apicall,
                      source: this.source,
                      infotext: this.infotext,
                      saveDocument: this.saveDocument,
                      noconfig: this.noconfig,
                      downloadmode: this.downloadmode,
                      callparams: this.callparams }
          }).then(
            config_options => {
              if (config_options) {
                this.saveDocument(config_options);
              }
            }
          );
        }
      );
    }
  }

  saveDocument(config_options = {}, task_id) {
    if (this.callparams && config_options) {
      config_options = angular.merge(config_options, this.callparams);
    }
    if (task_id) {
      config_options.task_id = task_id;
    }
    this.loading = true;
    this.source.one(this.apicall).withHttpConfig({ responseType: "blob" }).customGET("", config_options, { fullResponse: true }).then(
      response => {
         // wenn der Filename nicht via Config bestimmt wird (z.B. wenn keine Config vorgesehen ist), dann wird
         // versucht, den filename aus der Response zu entnehmen:
        if (!this.filename) {
          const disposition = response.headers()["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i;
            const matches = filenameRegex.exec(disposition);
            if (matches !== null && matches[2]) {
              this.filename = matches[2];
            }
          }
        }
        if (!this.filename) {
          this.filename = "unbenanntes_dokument";
        }
        if (this.$window.navigator.msSaveBlob) {
          this.$window.navigator.msSaveBlob(response.data, this.filename);
        } else {
          const fileURL = (this.$window.URL || this.$window.webkitURL).createObjectURL(response.data);
          const aLink = this.$window.document.createElement("a");
          aLink.download = this.filename;
          aLink.href = fileURL;
          aLink.target = "_self";
          const eventClick = this.$window.document.createEvent("MouseEvents");
          eventClick.initEvent(
            "click", 
            true, 
            true, 
            this.$window,
            0, 
            0, 
            0, 
            0, 
            0, 
            false, 
            false, 
            false, 
            false, 
            0, 
            null
          );
          aLink.dispatchEvent(eventClick);
        }
      },
      () => {
        this.DiasNotification.page.error("Fehler beim Erzeugen des Dokuments.");
      }
    ).finally(() => this.loading = false);
  }
}

export default {
  template: template(),
  controller: DocumentDownloadController,
  controllerAs: "vm",
  bindings: {
    source: "=",
    apicall: "=",
    label: "=",
    linkclass: "=",
    callparams: "<?",
    noconfig: "=", // Auch wenn die Api eine Konfiguration bereitstellt, kann diese ausgeblendet werden (wenn für alle Werte Defaultwerte definiert sind.
    downloadmode: "=" // Gibt an, ob der Download synchron oder asynchron passieren soll
  }
};
