"use strict";

class ThemenAuswahlController {
  /*@ngInject*/
  constructor(Katalog) {
    this.themaChoices = [];
    Katalog.getKatalog("kthema").then(
      result => this.themaChoices = result
    );

    this.themaInputMissing = () => {
      return this.themaAuswahl === null;
    };

    this.themaAuswahl = null;
    this.themaFreitext = false;

    this.addThema = () => {
      if (!this.parameter[this.key].wert) {
        this.parameter[this.key].wert = [];
      }

      if (!this.themaInputMissing()) {
        this.parameter[this.key].wert.push(
          { pk: this.themaAuswahl.pk,
            freitext: this.themaFreitext,
            kbez: this.themaAuswahl.the_kbez,
            bez: this.themaAuswahl.the_bez,
          }
        );
        this.themaAuswahl = null;
        this.themaFreitext = false;
      }
    };

    this.removeThema = index => {
      this.parameter[this.key].wert.splice(index, 1);
    };

    this.moveUpThema = index => {
      if (index === 0) {
        return;
      }
      const elem = this.parameter[this.key].wert[index];
      const previous = this.parameter[this.key].wert[index - 1];
      this.parameter[this.key].wert[index - 1] = elem;
      this.parameter[this.key].wert[index] = previous;
    };

    this.moveDownThema = index => {
      if (index >= this.parameter[this.key].wert.length - 1) {
        return;
      }
      const elem = this.parameter[this.key].wert[index];
      const next = this.parameter[this.key].wert[index + 1];
      this.parameter[this.key].wert[index + 1] = elem;
      this.parameter[this.key].wert[index] = next;
    };
  }
}

export default ThemenAuswahlController;
