"use strict";

import parameterServices from "../services";
import template from "./config.jade";
import controller from "./controller";


const parametermoduleInteger = angular.module("dias.foerderregeln.parameter.regexconfig", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("regex_config", "parametermodul-regex-config");
  })
  .component("parametermodulRegexConfig", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulRegexConfigRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: "<span class='code-content'>{{ vm.parameter[vm.key].wert }}</span>",
    controllerAs: "vm",
  });
export default parametermoduleInteger;
