import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "GutachtenVorlagenModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        content_types: [],
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "gav_kbez",
            type: "text",
            label: "_TXT_GUTACHTEN_VORLAGEN_MODAL_CREATE_TABLE_NAME_",
            required: true,
            view: "v-alt",
          },
          {
            id: "gav_bez",
            type: "richtext",
            label: "_TXT_GUTACHTEN_VORLAGEN_MODAL_CREATE_TABLE_BESCHREIBUNG_",
            required: true,
            view: "v-alt",
          },
          {
            id: "typ",
            type: "select",
            url: "katalog/?key=kgutachtentypen",
            keyId: "id",
            keyLabel: "bez",
            label: "_TXT_GUTACHTEN_VORLAGEN_MODAL_CREATE_TABLE_TYP_",
            required: true,
            view: "v-alt",
          },
          {
            id: "gruppe",
            type: "select",
            url: "katalog/?key=kgutachtenergebnisgruppen",
            keyId: "id",
            keyLabel: "bez",
            label: "_TXT_GUTACHTEN_VORLAGEN_MODAL_CREATE_TABLE_ERGEBNIS_",
            required: true,
            view: "v-alt",
          },
          {
            id: "gav_erlaeuterung_pflicht",
            type: "boolean",
            label: "_TXT_GUTACHTEN_VORLAGEN_MODAL_CREATE_TABLE_ERGEBNIS_ERZWINGEN_",
            required: false,
            view: "v-alt",
          },
          {
            id: "workflow",
            type: "select",
            url: "katalog/?key=workflow_gutachten",
            keyId: "id",
            keyLabel: "bez",
            label: "_TXT_GUTACHTEN_VORLAGEN_MODAL_CREATE_TABLE_WORKFLOW_",
            required: true,
            view: "v-alt",
          },
          {
            id: "content_types",
            type: "multiselect",
            url: "katalog/?key=gutachtentypen",
            keyId: "id",
            keyLabel: "bez",
            search: true,
            searchList: ["bez"],
            searchParameter: "bez",
            label: "_TXT_GUTACHTEN_VORLAGEN_MODAL_CREATE_TABLE_GUTACHTENOBJEKTE_",
            required: true,
            view: "v-alt",
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  methods: {
    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.postHttp({ url: `gutachtenvorlagen/`, data }).then(
        response => {
          this.addNotification({ text: "_MSG_GUTACHTEN_VORLAGEN_MODAL_CREATE_SUCCESS_" });
          this.close();
          this.$goTo("root.gutachtenvorlagen.detail", { id: response.pk });
        },
        error => {
          this.addNotification({ text: "_MSG_GUTACHTEN_VORLAGEN_MODAL_CREATE_FAILED_", type: error });
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
