import {
  getCurrentInstance,
  ref,
} from "vue";

import AngularLink from "../../global/components/AngularLink/AngularLink.vue";
import PuxButton from "../../global/components/PuxButton/PuxButton.vue";
import PuxDropdown from "../../global/components/PuxDropdown/PuxDropdown.vue";
import PuxIcon from "../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";
import Spinner from "../../global/components/Spinner/Spinner.vue";
import TheReleaseNotesModal from "../TheReleaseNotesModal/TheReleaseNotesModal.vue";

import HttpAPI from "../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../global/compositionAPI/PermissionAPI";

// @vue/component
export default {
  name: "TheMenuUser",
  components: {
    AngularLink,
    PuxButton,
    PuxDropdown,
    PuxIcon,
    PuxTranslate,
    Spinner,
    TheReleaseNotesModal,
  },
  setup() {
    const {
      postHttp,
    } = HttpAPI();
    const {
      fullName,
    } = PermissionAPI();

    const idForVersionButton = "btn_versions";
    const selectorClose = `#${ idForVersionButton }`;

    const isModalOpen = ref(false);
    const openModal = () => {
      isModalOpen.value = true;
    };
    const closeModal = () => {
      isModalOpen.value = false;
    };

    const APP = getCurrentInstance();
    const $goTo = APP.appContext.config.globalProperties.$goTo;
    const $AuthService = APP.appContext.config.globalProperties.$AuthService;
    const isLogout = ref(false);
    const logout = () => {
      if (isLogout.value) {
        return;
      }
      isLogout.value = true;
      postHttp({
        url: "auth/logout/",
      }).then(
        () => {
          $AuthService.refreshUser().then(
            () => {
              $goTo("root.home", {}, { reload: "root.home" });
              isLogout.value = false;
            }
          );
        },
        () => {
          isLogout.value = false;
        }
      );
    };

    return {
      closeModal,
      fullName,
      idForVersionButton,
      isLogout,
      isModalOpen,
      logout,
      openModal,
      selectorClose,
    };
  },
};
