import KFPeriodenModulStandard from "../../KFPerioden/KFPeriodenModulStandard/KFPeriodenModulStandard.vue";
import {
  forEach,
  keyBy,
  toNumber,
  isFunction,
  cloneDeep,
  isEmpty,
} from "lodash-es";

// @vue/component
export default {
  components: {
    KFPeriodenModulStandard,
  },
  props: {
    periodKeyShow: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    perioden() {
      return this.extraStatic.perioden;
    },

    modelWithPositionenForPeriode() {
      return this.setModelWithPositionenForPeriode({
        positionen: this.positionen,
        statusSnapshot: false,
      });
    },

    posWertKey() {
      return `${ this.prefixKoFi }_wert`;
    },

    prefixKoFi() {
      if (this.typeKoFi === "kosten") {
        return "akp";
      }
      if (this.typeKoFi === "finanzierung") {
        return "afp";
      }
      return "";
    },

    prefixPositionPeriodWertSnapshot() {
      if (this.typeKoFi === "kosten") {
        return "app_wert";
      }
      if (this.typeKoFi === "finanzierung") {
        return "afpp_wert";
      }
      return "";
    },

    diff() {
      const DIFF = {
        sum: undefined,
        sumPerioden: {},
        delete: [],
        add: {},
        changes: {},
        status: false,
      };
      if (this.statusSnapshot && this.modelWithPositionenForPeriodeSnapshot) {
        const MODEL = this.modelWithPositionenForPeriode;
        const SNAPSHOT = cloneDeep(this.modelWithPositionenForPeriodeSnapshot);
        if (MODEL.all.wertCurrency !== SNAPSHOT.all.wertCurrency) {
          DIFF.sum = true;
        }
        forEach(this.perioden, period => {
          const PERIOD_PK = period.pk;
          if (MODEL[PERIOD_PK].wertCurrency !== SNAPSHOT[PERIOD_PK].wertCurrency) {
            DIFF.sumPerioden[PERIOD_PK] = true;
          }
        });
        forEach(MODEL.positionen, (position, positionPk) => {
          const CURRENT_POSITION_SNAPSHOT = SNAPSHOT.positionen[positionPk];
          if (CURRENT_POSITION_SNAPSHOT) {
            forEach(position, (item, key) => {
              const POSITION_SNAPSHOT_ITEM = CURRENT_POSITION_SNAPSHOT[key] || {};
              if (item.wertCurrency !== POSITION_SNAPSHOT_ITEM.wertCurrency) {
                DIFF.changes[positionPk] = DIFF.changes[positionPk] || {};
                DIFF.changes[positionPk][key] = true;
              }
            });
            if (position.all.wertCurrency !== CURRENT_POSITION_SNAPSHOT.all.wertCurrency) {
              DIFF.changes[positionPk].all = true;
            }
            delete SNAPSHOT.positionen[positionPk];
          } else {
            DIFF.add[positionPk] = true;
          }
        });
        if (!isEmpty(SNAPSHOT.positionen)) {
          DIFF.delete = [];
          forEach(SNAPSHOT.positionen, (item, key) => {
            if (this.currentSnapshot.positionenObj[key]) {
              DIFF.delete.push(this.currentSnapshot.positionenObj[key]);
            }
          });
        }

        if (!isEmpty(DIFF.add) ||
            !isEmpty(DIFF.changes) ||
            !isEmpty(DIFF.sumPerioden) ||
            DIFF.delete.length ||
            DIFF.sum) {
          DIFF.status = true;
        }
      }
      return DIFF;
    },

    modelWithPositionenForPeriodeSnapshot() {
      if (!this.statusSnapshot || !this.currentSnapshot || !this.currentSnapshot.positionen) {
        return;
      }
      return this.setModelWithPositionenForPeriode({
        positionen: this.currentSnapshot.positionen,
        statusSnapshot: true,
        keyPosPeriodWert: this.prefixPositionPeriodWertSnapshot,
      });
    },
  },
  methods: {
    setModelWithPositionenForPeriode({ positionen, statusSnapshot, keyPosPeriodWert = "wert" }) {
      const MODEL = {
        positionen: {},
        all: {
          wert: 0,
          wertCurrency: this.filterCurrency(0),
        },
      };
      forEach(this.perioden, period => {
        const PERIOD_PK = period.pk;
        MODEL[PERIOD_PK] = {
          wert: 0,
          wertCurrency: this.filterCurrency(0),
        };
      });
      forEach(positionen, position => {
        const POSITION_PK = position.pk;
        const POSITION_PERIODEN_OBJ = keyBy(position.posperioden, "periode");
        MODEL.positionen[POSITION_PK] = {
          all: {
            wert: toNumber(position[this.posWertKey]) || 0,
            wertCurrency: this.filterCurrency(position[this.posWertKey] || 0)
          },
        };
        forEach(this.perioden, period => {
          const PERIOD_PK = period.pk;
          MODEL.positionen[POSITION_PK][PERIOD_PK] = {
            wert: 0,
            wertCurrency: this.filterCurrency(0),
          };
          if (POSITION_PERIODEN_OBJ[PERIOD_PK]) {
            MODEL.positionen[POSITION_PK][PERIOD_PK].wert = toNumber(POSITION_PERIODEN_OBJ[PERIOD_PK][keyPosPeriodWert]) || 0;
            MODEL.positionen[POSITION_PK][PERIOD_PK].wertCurrency = this.filterCurrency(POSITION_PERIODEN_OBJ[PERIOD_PK][keyPosPeriodWert] || 0);
          }
          MODEL[PERIOD_PK].wert += MODEL.positionen[POSITION_PK][PERIOD_PK].wert;
          MODEL[PERIOD_PK].wertCurrency = this.filterCurrency(MODEL[PERIOD_PK].wert);
        });
        MODEL.all.wert += MODEL.positionen[POSITION_PK].all.wert;
      });

      MODEL.all.wertCurrency = this.filterCurrency(MODEL.all.wert);
      if (isFunction(this.extraStatic.setPeriodenWert)) {
        this.extraStatic.setPeriodenWert({
          model: MODEL,
          regel: this.regel.regel,
          statusSnapshot,
          typeKoFi: this.typeKoFi,
        });
      }
      return MODEL;
    },
  },
};
