import Alert from "../../../../../global/components/Alert/Alert.vue";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import UiSelect from "../../../../../global/components/ui/UiSelect/UiSelect.vue";

import AutoFocus from "../../../../../global/directives/AutoFocus";

import {
  cloneDeep,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "ExportauftragJsonWizardStepEintraegeWaehlen",
  components: {
    Alert,
    FormElement,
    PuxTranslate,
    UiSelect,
  },
  directives: {
    AutoFocus,
  },
  props: {
    errors: {
      type: Object,
      default: () => ({}),
    },
    updateModel: {
      type: Function,
      default: undefined,
    },
    vorlage: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      editModel: {
        eintraege: [],
        obj: {},
        eintraegetyp: 1,
      }
    };
  },
  computed: {
    elementEintraege() {
      return {
        id: "vorlageexpvalues",
        keyId: "kid",
        keyLabel: "bez",
        buttonDelete: false,
        searchGlobal: true,
        searchParameter: "bez",
        url: `impexpauftraege_vorlageexpvalues/?pk=${ this.vorlage }`,
        type: "multiselect",
        view: "v-alt",
      };
    },

    isErrors() {
      return size(this.errors);
    },
  },
  created() {
    this.initErrorLabels();
  },
  methods: {
    initErrorLabels() {
      const ERROR_LABELS = {
        non_field_errors: ""
      };
      this.errorLabels = ERROR_LABELS;
    },

    onUpdateModel({ item }) {
      this.editModel.obj[item.kid] = item.bez;
      const MODEL_CLONE = cloneDeep(this.editModel);
      this.updateModel({ model: MODEL_CLONE });
    },
  },
};
