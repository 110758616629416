"use strict";

import KofiUebersichtComponent from "./uebersicht.component";

const MdmKofiUebersichtModul = angular.module("dias.mdm_kosten_finanzierung.uebersicht", [
])

  .component("kofiUebersicht", KofiUebersichtComponent);

export default MdmKofiUebersichtModul;
