import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import translate from "../../../../../global/directives/translate";
import init from "../../../../../global/directives/init";

// @vue/component
export default {
  name: "ContactUserEdit",
  components: {
    FormElement,
  },
  directives: {
    init,
    translate,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      required: true,
    },
    htmlRegelNummer: {
      type: String,
      required: true,
    },
    modelContactUser: {
      type: String,
      default: undefined,
    },
    modelComment: {
      type: String,
      default: undefined,
    },
    hide: {
      type: Boolean,
      required: true,
    },
    deselect: {
      type: Boolean,
      required: true,
    },
    changeModelContactUser: {
      type: Function,
      required: true,
    },
    changeModelComment: {
      type: Function,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      modelContactUserLocal: this.modelContactUser,
      modelCommentLocal: this.modelComment,
    };
  },
  computed: {
    optionsContactUser() {
      return {
        label: `_LBL_GR_ANTRAGSTELLER_AUSWAHL_ANSPRECHPARTNER_${ +this.index + 1 }_`,
        type: "select",
        view: "v-alt",
        id: `asp_anu_${ this.index }`,
        htmlId: `${ this.htmlRegelNummer }_asp_anu_${ this.index }`,
        keyId: "pk",
        keyLabel: "name",
        search: true,
        searchList: ["name"],
        translate: true,
        change: this.changeContactUser,
        required: this.index === 0,
        deselect: this.deselect,
      };
    },

    optionsComment() {
      return {
        label: "_LBL_GR_ANTRAGSTELLER_AUSWAHL_KOMMENTAR_{{index}}_",
        type: "text",
        view: "v-alt",
        id: `asp_kommentar_${ this.index }`,
        htmlId: `${ this.htmlRegelNummer }_asp_kommentar_${ this.index }`,
        translate: true,
        required: this.index === 0,
        change: this.changeComment,
        extra: {
          index: this.index,
        },
      };
    },

    errorsUser() {
      return this.errors && this.errors[`asp_anu_${ this.index }`];
    },

    errorsComment() {
      return this.errors && this.errors[`asp_kommentar_${ this.index }`];
    },
  },
  watch: {
    modelContactUser() {
      if (this.modelContactUser !== this.modelContactUserLocal) {
        this.modelContactUserLocal = this.modelContactUser;
      }
    },

    modelComment() {
      if (this.modelComment !== this.modelContactUserLocal) {
        this.modelCommentLocal = this.modelComment;
      }
    },
  },
  methods: {
    changeContactUser({ model }) {
      this.changeModelContactUser({ contactUser: model, index: this.index });
    },

    changeComment({ model }) {
      this.changeModelComment({ comment: model, index: this.index });
    },

    showComment() {
      this.modelCommentLocal = this.modelComment;
      this.changeComment({ model: this.modelCommentLocal });
    },
  },
};
