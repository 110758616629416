"use strict";
import angular from "angular";

import sitzungsmodule from "dias/sitzungen/sitzungsmodule";

import detail from "./detail.component";

const modul = angular.module("standard_sitzungsmodule.antragsuebersicht", [
  sitzungsmodule.name,
])

  .config(/*@ngInject*/SitzungsmodulRegistryProvider => {
    SitzungsmodulRegistryProvider.register("antragslisten_uebersicht", "modul-antragslisten-uebersicht");
  })

  .component("modulAntragslistenUebersicht", detail)
;

export default modul;
