import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import IconListenstatus from "../../../global/components/ui/IconListenstatus/IconListenstatus.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import NotificationMixin from "../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import SitzungsantraegeListInTageordnungspunktMixin from "../SitzungsantraegeListInTageordnungspunkt.mixin";

import ListenstatusUUIDs from "../../../const/ListenstatusUUIDs";
import { EventBus } from "../../../global/functions/event-bus";
import {
  cloneDeep,
  forEach,
  isUndefined,
  keyBy,
} from "lodash-es";

const TABLE_ID = "sitzungslisteantrag";

// @vue/component
export default {
  name: "SitzungsantraegeListInTageordnungspunkt",
  components: {
    AngularLink,
    IconListenstatus,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    NotificationMixin,
    PermissionMixin,
    SitzungsantraegeListInTageordnungspunktMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: "",
        cols: [],
        rowActions: [],
        actions: [
          {
            type: "button",
            label: "_LBL_SITZUNGSANTRAEGE_LIST_IN_TAGEORDNUNGSPUNKT_RESTLICHE_ANTRAEGE_ENTSCHEIDEN_",
            icon: "glyphicon-bearbeiten-2",
            callback: this.openModalEntscheiden_mixin,
            isHidden: () => {
              return !(this.canDecide_mixin && this.userInput.festgeschrieben);
            },
            className: "btn btn-primary test_restliche_antraege_entscheiden",
            htmlId: "sitzungsantraege_entscheiden",
          },
        ],
        label: "_LBL_SITZUNGSANTRAEGE_LIST_IN_TAGEORDNUNGSPUNKT_ANTRAEGE_",
        sortable: false,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
        trClassCallback: this.trClassCallback,
        onRefreshCallback: this.onRefreshCallback,
      },
      listenstatus: undefined,
      listeninfo: undefined,
    };
  },
  computed: {
    canRank() {
      return this.checkPermissionsSync({ perm: "tops.antrag.rank", permArray: this.list.user_permissions });
    },

    isPriorisierungActive() {
      return this.canRank && this.userInput.festgeschrieben && !this.userInput.priorisiert;
    },
  },
  created() {
    this.loadData();
    this.initUrl_mixin();
  },
  methods: {
    loadData() {
      const PROMISE_ALL = [
        this.getHttp({ url: `sitzungen/${ this.sitzungPk }/tops/${ this.top.pk }/sitzungslisten/${ this.userInput.sl_id }/` }),
        this.getHttp({ url: "katalog/?key=listenstatus", apiSaveId: "listenstatus" }),
        this.getHttp({ url: `katalog/?gruppe=${ this.parameter.entscheidungsstatusgruppe }&key=entscheidungsstatus` }),
      ];
      Promise.all(PROMISE_ALL).then(
        responses => {
          this.list = responses[0];
          this.listenstatus = keyBy(responses[1], "pk");
          this.entscheidungsstatus = responses[2];
        }
      ).then(
        () => {
          this.initColumns();
          this.initRowActions();
          this.hasViewPermission = this.canView_mixin;
          this.status.loading = false;
        }
      );
    },

    initColumns() {
      this.initStatusColumn();
      this.initAnmerkungColumn();
    },

    initStatusColumn() {
      if (!this.userInput.festgeschrieben) {
        return;
      }
      if (this.canRank) {
        this.options.cols.push({
          label: "_LBL_SITZUNGSANTRAEGE_LIST_IN_TAGEORDNUNGSPUNKT_STATUS_",
          id: "status_can_rank",
          slot: "status_can_rank",
          className: "text-center",
          priority: 10,
        });
        return;
      }
      this.options.cols.push({
        label: "",
        id: "listenstatus_border",
        slot: "listenstatus_border",
        cellTemplate: "",
        headerClassName: "status table_smart__table__td_listenstatus_border",
        className: "status table_smart__table__td_listenstatus_border",
        priority: 20,
      }, {
        label: "_LBL_SITZUNGSANTRAEGE_LIST_IN_TAGEORDNUNGSPUNKT_STATUS_",
        id: "status",
        slot: "status",
        className: "text-center",
        classNameChild: "overflow-hidden",
        priority: 30,
      });
    },

    initAnmerkungColumn() {
      if (!this.showAnmerkungColumn_mixin) {
        return;
      }
      this.options.cols.push({
        label: "_LBL_SITZUNGSANTRAEGE_LIST_IN_TAGEORDNUNGSPUNKT_ANMERKUNG_",
        id: "anmerkung",
        slot: "anmerkung",
        classNameChild: "overflow-hidden",
        priority: 150,
      });
    },

    initRowActions() {
      this.initEntscheidungsAktionen_mixin();
      // this.initPriorisierungsAktionen();
      this.initProtokollierungAktionen_mixin();
    },

    initPriorisierungsAktionen() {
      this.options.rowActions.push(
        {
          label: "_LBL_SITZUNGSANTRAEGE_LIST_IN_TAGEORDNUNGSPUNKT_ERSTEN_VORZULEGENDEN_",
          callback: this.moveToFirstVorgesehen,
          isHidden: () => !this.isPriorisierungActive,
        },
        {
          label: "_LBL_SITZUNGSANTRAEGE_LIST_IN_TAGEORDNUNGSPUNKT_LETZTEN_VORZULEGENDEN_",
          callback: this.setLastVorgesehen,
          isHidden: () => !this.isPriorisierungActive,
        },
        {
          label: "_LBL_SITZUNGSANTRAEGE_LIST_IN_TAGEORDNUNGSPUNKT_ERSTEN_ERSATZANTRAG_",
          callback: this.setFirstErsatz,
          isHidden: () => !this.isPriorisierungActive,
        },
        {
          label: "_LBL_SITZUNGSANTRAEGE_LIST_IN_TAGEORDNUNGSPUNKT_LETZTEN_ERSATZANTRAG_",
          callback: this.moveToLastErsatz,
          isHidden: () => !this.isPriorisierungActive,
        },
        {
          label: "_LBL_SITZUNGSANTRAEGE_LIST_IN_TAGEORDNUNGSPUNKT_NICHT_VORZULEGEN_",
          callback: this.setNichtVorgesehen,
          isHidden: () => !this.isPriorisierungActive,
        },
      );
    },

    moveToFirstVorgesehen({ item }) {
      this.setzeAnPos({ item, pos: 1 });
    },

    setLastVorgesehen({ item }) {
      this.handleMovePromise({ promise: this.sendSetzeVorgesehen({ item }) });
    },

    setFirstErsatz({ item }) {
      this.handleMovePromise({ promise: item.sendSetzeErsatz() });
    },

    moveToLastErsatz({ item }) {
      const pos = item.calcPosAsLast();
      return this.setzeAnPos({ item, pos });
    },

    setNichtVorgesehen() {
      this.status.entscheidung = true;
      this.putHttp({ url: `${ this.getTableUrl_mixin }setze_nicht_vorgesehen/` }).then(
        () => {
          this.addNotification({ text: "_MSG_SITZUNGSANTRAEGE_LIST_IN_TAGESORDNUNG_SET_NICHT_VORGESEHEN_SUCCESS_" });
          this.emitEventAngular();
        },
        () => this.addNotification({ text: "_MSG_SITZUNGSANTRAEGE_LIST_IN_TAGESORDNUNG_SET_NICHT_VORGESEHEN_ERROR_", type: "error" })
      ).then(
        () => this.status.entscheidung = true
      );
      //     return row.sendSetzeNichtVorgesehen().then(
      //       () => {
      //         this.DiasNotification.page.success("Antrag erfolgreich ausgeschlossen.");
      //         this.emitEvent(this.EVENT_ACTION_UPDATED);
      //       },
      //       () => this.DiasNotification.page.error("Beim Ausschließen des Antrags ist ein Fehler aufgetreten.")
      //     ).finally(() => this.loading = false);
    },

    setzeAnPos({ item, pos }) {
      this.handleMovePromise({ promise: this.sendMove({ item, pos }) });
    },

    sendSetzeVorgesehen({ item }) {
      this.putHttp({ url: `${ this.getTableUrl_mixin }${ item.pk }/setze_vorgesehen/` }).then(
        () => {}
      );
    },

    handleMovePromise({ promise }) {
      this.status.entscheidung = true;
      promise.then(
        response => {
          this.addNotification({
            text: "_MSG_SITZUNGSANTRAEGE_LIST_IN_TAGESORDNUNG_MOVE_SUCCESS_{{sla_pos}}_",
            extra: {
              sla_pos: response.sla_pos
            }
          });
          this.emitEventAngular();
        },
        () => this.addNotification({ text: "_MSG_SITZUNGSANTRAEGE_LIST_IN_TAGESORDNUNG_MOVE_ERROR_", type: "error" })
      ).then(
        () => this.status.entscheidung = false
      );
    },

    sendMove({ item, pos }) {
      return this.putHttp({ url: `${ this.getTableUrl_mixin }${ item.pk }/`, data: { sla_pos: pos } });
    },

    onRefreshCallback({ params, rows }) {
      this.getListeninfo({ params, rows });
    },

    refreshDataRows({ rows }) {
      forEach(rows, (row, rowIndex) => {
        this.refreshDataCurrentRow({ rows, row, rowIndex });
      });
    },

    refreshDataCurrentRow({ rows, row, rowIndex }) {
      const ROW_CLONE = cloneDeep(row);
      if (ROW_CLONE.listenstatus === ListenstatusUUIDs.nichtVorgesehen) {
        ROW_CLONE.listenstatus_obj = this.listenstatus[ListenstatusUUIDs.nichtVorgesehen];
      } else if (this.getSumUpTo({ rows, rowIndex }) > this.top.tos_bereitgestellt) {
        ROW_CLONE.listenstatus_obj = this.listenstatus[ListenstatusUUIDs.ersatz];
      } else {
        ROW_CLONE.listenstatus_obj = this.listenstatus[ListenstatusUUIDs.vorgesehen];
      }
      EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: ROW_CLONE, index: rowIndex }));
    },

    getSumUpTo({ rows, rowIndex }) {
      let sum = this.getOffPageBeantragt();
      for (let i = 0; i <= rowIndex; i++) {
        sum += rows[i].antrag.a_zuschussbeantragt;
      }
      return sum;
    },

    getOffPageBeantragt() {
      let sum = 0;
      if (!isUndefined(this.listeninfo) && !isUndefined(this.listeninfo.bedarf)) {
        sum = Number(this.listeninfo.bedarf);
      }
      return sum;
    },

    getListeninfo({ params, rows }) {
      this.getHttp({ url: `${ this.getTableUrl_mixin }listeninfo/`, urlParams: params }).then(
        response => {
          this.listeninfo = response;
        }
      ).then(
        () => this.refreshDataRows({ rows })
      );
    },

    trClassCallback({ row }) {
      if (row.listenstatus === ListenstatusUUIDs.nichtVorgesehen) {
        return "kreuz";
      }
      if (this.getSumUpTo(row) > this.top.tos_bereitgestellt) {
        return "frage";
      }
      return "";
    },
  },
};
