import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import SmartTable from "../../../global/components/table/table.vue";
import getTableApi from "../../../const/TableApis";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

const TABLE_ID = "UmfrageList";

// @vue/component
export default {
  name: "UmfrageList",
  components: {
    AngularLink,
    SmartTable,
    PageTabTitle,
    PuxTranslate,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_LBL_UMFRAGE_LIST_ACTION_ROW_DETAILS_",
            route: "root.umfragecortex.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        label: "_LBL_UMFRAGE_LIST_FORMULARE_",
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
        },
      },
    };
  },
};
