"use strict";

import BaseModel from "dias/core/models/baseModel";


class GeloeschterAntrag extends BaseModel {
  constructor() {
    super("geloeschte_antraege", {});
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.bereinigen = () => collection.customPOST({}, "bereinigen");
    return collection;
  }

  extendModel(model) {
    model = super.extendModel(model);
    model.getPreview = () => model.customGET("vorschau");
    return model;
  }
}

export default GeloeschterAntrag;
