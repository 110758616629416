"use strict";

const permissionComponent = /*@ngInject*/ function($compile, $state, AuthService) {
  return {
    restrict: "A",
    scope: {
      permission: "@",
      permissionSrc: "<?",
      permissionOnChange: "&?",
      permissionMissing: "<?"
    },
    link: function(scope, element) {
      element.addClass("ng-hide");
      if (scope.permission === "@children") {
        scope.childPermissions = {}; // map of childs permission
        element.on("permission.changed", (e, data) => {
          scope.childPermissions[data.permission + data.id] = data.value;
          let anyTrue = false;
          for (const key in scope.childPermissions) {
            if (scope.childPermissions[key]) {
              anyTrue = true;
              element.removeClass("ng-hide");
              break;
            }
          }
          if (!anyTrue) {
            element.addClass("ng-hide");
          }
          if (angular.isFunction(scope.permissionOnChange)) {
            scope.permissionOnChange();
          }
        });
      } else {
        let parent = element.parent();
        while (parent[0] && parent.attr("permission") !== "@children") {
          parent = parent.parent();
        }
        const setPermission = result => {
          let statusResult = !!result;
          if (scope.permissionMissing) {
            statusResult = !result;
          }

          if (statusResult) {
            element.removeClass("ng-hide");
          } else {
            element.addClass("ng-hide");
          }
          if (parent) {
            parent.triggerHandler("permission.changed", {
              id: scope.$id,
              permission: scope.permission,
              value: result
            });
          }
          if (angular.isFunction(scope.permissionOnChange)) {
            scope.permissionOnChange();
          }
        };
        const updatePermissions = () => {
          let perms = scope.permission;
          if (scope.permission.indexOf("@state:") === 0) {
            const stateStr = scope.permission.substring(7).replace(/\(.*\)/, "");
            const state = $state.get(stateStr);
            if (!state) {
              perms = "";
            } else {
              perms = state.data.permissions;
            }
          }
          if (angular.isArray(perms)) {
            AuthService.hasPerms(perms, scope.permissionSrc).then(
              perms_map => {
                let result = true;
                for (const perm in perms_map) {
                  if (!perms_map[perm]) {
                    result = perms_map[perm];
                    break;
                  }
                }
                setPermission(result);
              }
            );
          } else {
            AuthService.hasPerm(perms, scope.permissionSrc).then(
              setPermission
            );
          }
        };
        scope.$watch("permission", updatePermissions);
        scope.$watchCollection("permissionSrc", updatePermissions);
      }
    }
  };
};

export default permissionComponent;
