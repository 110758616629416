import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxErrors from "../../../../global/components/PuxErrors/PuxErrors.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import translate from "../../../../global/directives/translate";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import {
  cloneDeep,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "KofiErfassungPositionForm",
  components: {
    FormstepDetail,
    PuxErrors,
    FormElement,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    htmlId: {
      type: String,
      required: true,
      info: "Parent HtmlId",
    },
    urlSave: {
      type: String,
      required: true,
      info: "URL für speichern",
    },
    statusNew: {
      type: Boolean,
      required: true,
      info: "Ist die Position neu?",
    },
    position: {
      type: Object,
      required: false,
      default: undefined,
      info: "Hauptinformation von der Position",
    },
    extraTranslate: {
      type: Object,
      required: true,
      info: "Die zusätzliche Information für Übersetzung",
    },
    katalogDetails: {
      type: Array,
      required: false,
      default: () => [],
      info: "Die Liste von 'Typ / Kategorie'",
    },
    prefixCurrent: {
      type: Object,
      required: true,
      info: "Information vom Kofiart(kosten oder Finanzierung)",
    },
    extraSave: {
      type: Object,
      required: false,
      default: () => ({}),
      info: "Erweiterung für Speichern-Request",
    },
  },
  emits: [
    "close",
    "save",
  ],
  data() {
    return {
      errors: {},
      model: {
        argument: {},
      },
      labelDetail: "_LBL_KOFI_DETAIL_",
      labelBez: "_LBL_KOFI_ERFASSUNG_BEZ_",
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        saveCallback: this.saveLocal,
        closeCallback: this.closeLocal,
        required: true,
      };
    },

    headerTranslateOptions() {
      return {
        text: this.headerText,
        extra: this.extraTranslate,
      };
    },

    headerText() {
      return this.statusNew ?
        this.headerTextAdd :
        this.headerTextEdit;
    },

    headerTextAdd() {
      return "_TXT_POSITION_ADD_HEADER_{{kofiart}}_";
    },

    headerTextEdit() {
      return "_TXT_POSITION_EDIT_HEADER_{{kofiart}}_{{position}}_";
    },

    actionSave() {
      return this.statusNew ? "postHttp" : "putHttp";
    },

    statusShowKDetails() {
      return this.katalogDetails.length > 0;
      // ng-if="vm.positionKDetails[KoFi.pk].length > 0 && (!#{posModel}[vm.prefix[vm.labelKoFi].position + '_betrag_bewilligt'] || #{posModel}[vm.prefix[vm.labelKoFi].position + '_betrag_bewilligt'] ==='0.00')"
    },

    keyArtDetail() {
      return `${ this.prefixCurrent.art }detail`;
    },

    keyArtdetailKbez() {
      return `${ this.prefixCurrent.artdetail }_kbez`;
    },

    keyArtdetailId() {
      return `${ this.prefixCurrent.artdetail }_id`;
    },

    keyPositionsBez() {
      return `${ this.prefixCurrent.position }_bez`;
    },

    errorLabels() {
      return {
        [this.keyArtDetail]: {
          label: this.labelDetail,
          link: true,
          id: this.htmlIdDetail,
        },
        [this.keyPositionsBez]: {
          label: this.labelBez,
          link: true,
          id: this.htmlIdBez,
        },
      };
    },

    optionsDetail() {
      return {
        id: this.keyArtDetail,
        htmlId: this.htmlIdDetail,
        type: "select",
        required: true,
        view: "h",
        label: this.labelDetail,
        classesHorizontal: [
          "col-sm-3 text-left text-sm-right",
          "col-sm-6",
          "offset-sm-3 col-sm-6",
        ],
        placeholder: "_TXT_KOFI_DETAIL_PLACEHOLDER_",
        keyId: this.keyArtdetailId,
        keyLabel: this.keyArtdetailKbez,
        search: true,
        searchList: [this.keyArtdetailKbez],
        withoutGroup: true,
        autoFocus: true,
        disabled: size(this.katalogDetails) === 1,
      };
    },

    htmlIdDetail() {
      return `${ this.htmlId }_${ this.keyArtDetail }`;
    },

    optionsBez() {
      return {
        id: this.keyPositionsBez,
        htmlId: this.htmlIdBez,
        type: "text",
        maxlength: 300,
        view: "h",
        required: true,
        label: this.labelBez,
        classesHorizontal: [
          "col-sm-3 text-left text-sm-right",
          "col-sm-6",
          "offset-sm-3 col-sm-6",
        ],
      };
    },

    htmlIdBez() {
      return `${ this.htmlId }_${ this.keyPositionsBez }`;
    },
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.statusNew) {
        this.model = {};
        if (size(this.katalogDetails) === 1) {
          this.model[this.keyArtDetail] = this.katalogDetails[0][this.keyArtdetailId];
        }
      } else {
        this.model = cloneDeep(this.position);
      }
    },

    saveLocal() {
      this.deleteErrors();
      const DATA = Object.assign({}, this.model, this.extraSave);
      if (this.model.kostenartdetail) {
        DATA.kostenartdetail = this.model.kostenartdetail;
      }
      if (this.model.finanzierungsartdetail) {
        DATA.finanzierungsartdetail = this.model.finanzierungsartdetail;
      }
      return new Promise((resolve, reject) => {
        this[this.actionSave]({
          url: this.urlSave,
          data: DATA,
        }).then(
          response => {
            this.$emit("save", { response });
            resolve();
          },
          errors => {
            this.errors = errors.data || {};
            this.focusToErrors();
            reject();
          }
        );
      });
    },

    focusToErrors() {
      setTimeout(() => {
        if (this.$refs.errors_component && this.$refs.errors_component.$el) {
          this.$refs.errors_component.$el.focus();
        }
      });
    },

    closeLocal(arg) {
      this.$emit("close", arg);
    },

    deleteErrors() {
      this.errors = {};
    },
  },
};
