"use strict";

import detailTemplate from "./detail.jade";
import astEditTypenTemplate from "./typzuordnung.edit.jade";
import astEditTypenController from "./typzuordnung.edit.controller";
import sperrungEditTemplate from "./sperrung.edit.jade";
import sperrungEditController from "./sperrung.edit.controller";
import dorgEditTemplate from "./durchfuehrendeorgeinheit.edit.jade";
import dorgEditController from "./durchfuehrendeorgeinheit.edit.controller";
import dorgCreateController from "./durchfuehrendeorgeinheit.modal.create.controller";
import dorgCreateTemplate from "./durchfuehrendeorgeinheit.modal.create.jade";
import svReadonlyTemplate from "./sammelvertraege.readonly.jade";
import svReadonlyController from "./sammelvertraege.readonly.controller";
import svCreateController from "./sammelvertraege.modal.create.controller";
import svCreateTemplate from "./sammelvertraege.modal.create.jade";

import SaveCallbackActions from "../../../vue/client/vue/const/SaveCallbackActions";

import {
  EventBus,
} from "../../../vue/client/vue/global/functions/event-bus";
import {
  MAPPING,
} from "../../../vue/client/vue/components/Geschaeftsregel/Module/Module";
import {
  cloneDeep,
  findIndex,
  forEach,
  get,
  isNil,
  size,
} from "lodash-es";

const GEPRUEFT = "137dc61b-249a-4f2c-82e8-dcc4c89a095d";
const UNGEPRUEFT = "a703e15e-61e2-421b-b25d-16e383fad85d";


class AstorgdetailsController {
  /*@ngInject*/
  constructor(
    $q,
    $rootScope,
    $state,
    $stateParams,
    $window,
    Antragstellerorganisationen,
    Upload,
    DiasNotification,
    diasConfirmDialog,
    diasModalDialog,
    SyConfigs,
    AuthService,
    FavoritenUtils,
    KAufgabe,
    Workflow,
    Katalog,
    WorkflowfunktionRegistry,
    Nutzeraufgabe,
    Client,
    i18n,
    $timeout,
    FoerderangebotAnzeigekontextUUIDs,
    $anchorScroll,
  ) {
    this.loading = {
      ast: true,
      dorg: false,
      aufgabe: false,
      module: false,
      pruefungen: false,
    };
    this.MAPPING = MAPPING;
    this.viewName = "details";
    this.gettext = i18n.gettext;
    this.$q = $q;
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;
    this.antragstellerPk = $stateParams.id;
    this.$window = $window;
    this.Antragstellerorganisationen = Antragstellerorganisationen;
    this.AuthService = AuthService;
    this.diasConfirmDialog = diasConfirmDialog;
    this.diasModalDialog = diasModalDialog;
    this.SyConfigs = SyConfigs;
    this.Upload = Upload;
    this.DiasNotification = DiasNotification;
    this.FavoritenUtils = FavoritenUtils;
    this.KAufgabe = KAufgabe;
    this.Workflow = Workflow;
    this.WorkflowfunktionRegistry = WorkflowfunktionRegistry;
    this.Nutzeraufgabe = Nutzeraufgabe;
    this.Client = Client;
    this.Katalog = Katalog;
    this.$timeout = $timeout;
    this.FoerderangebotAnzeigekontextUUIDs = FoerderangebotAnzeigekontextUUIDs;
    this.$anchorScroll = $anchorScroll;
    this.currentSnapshotStatus = {};
    this.snapshotActivePk = undefined;
    this.loadingDiff = undefined;
    // this.notizen = {};
    this.stepMap = [
      "antragstellerorg",
      "bankverbindungen",
      "nachweise",
      "nutzer",
      "vertreter",
      "dufos",
      "pruefungen",
      "sachbearbeiterunterlagen"
    ];
    this.steps = [
      {
        key: "antragstellerorg",
        kbez: this.gettext("Antragstellerorganisation")
      },
      {
        key: "bankverbindungen",
        kbez: "Bankverbindungen"
      },
      {
        key: "vertreter",
        kbez: "Vertretungsberechtigte"
      },
      {
        key: "nachweise",
        kbez: this.gettext("_LBL_AST_DETAILS_STEPS_NACHWEISE_KBEZ_")
      },
      {
        key: "dufos",
        kbez: "Durchführende Organisationseinheiten"
      },
      {
        key: "nutzer",
        kbez: "Nutzer"
      },
      {
        key: "pruefungen",
        kbez: "Prüfstatus"
      },
      {
        key: "sachbearbeiterunterlagen",
        kbez: "Sachbearbeiterunterlagen"
      },
      {
        key: "weiterleitungsvertraege",
        kbez: "Weiterleitungsverträge"
      }
    ];
    this.stepsOpen = { antragstellerorg: true };

    this.notizOptions = {
      fuerSitzung: false,
      orgChoices: [],
    };

    this.dorgEditTemplate = dorgEditTemplate;
    this.dorgEditController = dorgEditController;
    this.sperrungEditTemplate = sperrungEditTemplate;
    this.sperrungEditController = sperrungEditController;
    this.astEditTypenController = astEditTypenController;
    this.astEditTypenTemplate = astEditTypenTemplate;

    this.svReadonlyTemplate = svReadonlyTemplate;
    this.svReadonlyController = svReadonlyController;
    this.svCreateTemplate = svCreateTemplate;
    this.svCreateController = svCreateController;
    this.svExtra = { lastChange: null };
    this.lastWfExecution = null;
    this.astEditable = false;
    this.astVerwaltenAlle = false;
    this.nachweisModule = [];
    this.vertreterModule = [];
    // Timeline
    this.timelineConfig = {
      benachrichtigungen: {
        bezug: "antragsteller",
        bezugpk: $stateParams.id,
        autorefresh: true,
      },
      url: `antragstellerorganisationen/${ this.$stateParams.id }/timeline/`,
    };

    this.docSachbearbeiter = "2d591cec-a50c-4669-a332-4dc327aa8ad9";
    this.dokumentePermissions = {
      view: "dokument.view",
      create: "dokument.create",
      delete: "antragstellerdokument.delete",
      update: "dokument.update",
      update_gueltigkeit: "dokument.update_gueltigkeit",
    };
    this.sachbearbeiterDocAnzeigen = this.AuthService.syncHasPerm("ast_sachbearbeiter_dokumente.view");
    this.GEPRUEFT = GEPRUEFT;
    this.UNGEPRUEFT = UNGEPRUEFT;

    // Soll der Dufo-Abschnitt angezeigt werden?
    const astConfig = this.SyConfigs.wert("antragsteller") || {};
    this.hideBV = astConfig.hide_bv;
    this.hideDufo = astConfig.hide_dufo;
    this.hideNachweise = astConfig.hide_nachweise;
    this.hideBeschreibung = astConfig.hide_beschreibung;
    const WF_STATUS_GRUPPEN_IN_HEADER = this.SyConfigs.wert("wf_statusgruppen_in_header") || {};
    WF_STATUS_GRUPPEN_IN_HEADER.objects = WF_STATUS_GRUPPEN_IN_HEADER.objects || [];
    this.statusWFStatusGruppenInHeader = WF_STATUS_GRUPPEN_IN_HEADER.objects.indexOf("antragsteller") !== -1;
    this.reloadAstOrg(true).finally(() => {
      this.loading.ast = false;
      this.setUrl();
      this.restrictSteps();
      this.openAufgabenKontextPanel();
    });

    // Workflow callbacks
    this.onAufgabeAngenommen = () => {
      this.$timeout(() => {
        this.reloadAntragsteller();
      });
    };

    this.reloadWorkflowfolgen = () => this.lastWfExecution = new Date().toISOString();
    this.loadKataloge();
    this.updateModuleFromVue = this.updateModuleFromVue.bind(this);
    this.updateBankverbindungModuleFromVue = this.updateBankverbindungModuleFromVue.bind(this);
    this.updateVertreterModuleFromVue = this.updateVertreterModuleFromVue.bind(this);
    this.updatePruefModuleFromVue = this.updatePruefModuleFromVue.bind(this);
    this.updateNachweisModuleFromVue = this.updateNachweisModuleFromVue.bind(this);
    this.closeModalCreate = this.closeModalCreate.bind(this);
    this.updateAufgabeFromVue = this.updateAufgabeFromVue.bind(this);
    this.changeActiveSnapshot = this.changeActiveSnapshot.bind(this);
    this.reloadAstOrg = this.reloadAstOrg.bind(this);
    this.onWorkflowInit = this.onWorkflowInit.bind(this);
    this.onWorkflowStarted = this.onWorkflowStarted.bind(this);
    this.onWorkflowFinish = this.onWorkflowFinish.bind(this);
    this.onWorkflowCanceled = this.onWorkflowCanceled.bind(this);
    this.onWorkflowSuccess = this.onWorkflowSuccess.bind(this);
    this.onWorkflowFailure = this.onWorkflowFailure.bind(this);
    this.saveCallback = this.saveCallback.bind(this);
  }

  updateBerechtigungen() {
    this.pruefungViewable = (this.AuthService.syncHasPerm("antragstellerorgpruefungen.view", this.ast.user_permissions) ||
                             this.AuthService.syncHasPerm("antragstellerorgsperrung.view", this.ast.user_permissions) ||
                             this.amIFachAdmin);
    this.astEditable = this.AuthService.syncHasPerm("antragstellerorg.update", this.ast.user_permissions);
    this.astVerwaltenAlle = this.AuthService.syncHasPerm("antragstellerorg.verwalten_alle", this.ast.user_permissions);
    this.sperrungEditable = this.AuthService.syncHasPerm("antragstellerorgsperrung.update", this.ast.user_permissions);
    this.canDeactivate = (this.AuthService.syncHasPerm("antragstellerorg.deaktivieren", this.ast.user_permissions) && this.ast.can_deactivate && !this.ast.ast_deaktiviert);
    this.canDelete = (this.AuthService.syncHasPerm("antragstellerorg.delete", this.ast.user_permissions) && this.ast.can_delete);
    this.canTraegerwechsel = this.AuthService.syncHasPerm("antragstellerorg.traegerwechsel", this.ast.user_permissions);
    this.typenPerms = this.AuthService.syncHasPerms(["antragstellerorgtypen.view", "antragstellerorgtypen.update"], this.ast.user_permissions);
    this.canViewDufo = this.AuthService.syncHasPerm("durchfuehrendeorg.view", this.ast.user_permissions);
  }

  reloadAstOrg(initLoad = false) {
    const astPromise = this.Antragstellerorganisationen.one(this.$stateParams.id).get().then(
      response => {
        this.ast = response;
        const conf = this.ast.aktuelle_aufgabe.au_kontext_config;
        this.anzeigekontexteAntragsteller = conf && conf.markiert && conf.markiert.antragsteller ?
          conf.markiert.antragsteller : {};
        this.anzeigekontexteAntragstellerOffen = get(conf, "offen.antragsteller");
        this.timelineCsvUrl = response.getRestangularUrl() + "/timeline/";
        this.updateBerechtigungen();

        this.$rootScope.$title = response.ast_name;
        // this.initDocuments(Anzeige);
        this.initNutzerTable();
        this.refreshDurchfuehrendeOrgeinheiten();
        this.refreshVertretungsberechtigte();
        this.loadModule();

        if (!initLoad) {
          this.showSnapshots();
        } else if (!size(this.anzeigekontexteAntragstellerOffen) > 0) {
          this.stepsOpen = {};
        }

        this.loadKonto();
        this.reloadWorkflowfolgen();
      }
    );
    return astPromise;
  }

  loadKonto() {
    this.konto = null;
    if (this.ast) { // TODO: Berechtigungen
      this.ast.getKonto().then(
        response => {
          this.konto = response;
          this.kontoParams = {
            ast: "vm.ast",
            konto: "vm.konto",
          };
          if (this.konto && this.konto.mit_buchungen) {
            this.kontoParams["on-saldo-click"] = "vm.showBuchungen()";
          }
        }
      );
    }
  }

  saveCallback({ statusSaveCallback } = {}) {
    if (statusSaveCallback === SaveCallbackActions.RELOAD_WF) {
      this.reloadWorkflowfolgen();
    } else if (statusSaveCallback === SaveCallbackActions.RELOAD_ALL) {
      this.reloadAstOrg();
    }
  }

  showBuchungen() {
    this.viewName = "buchungen";
  }

  changeActiveSnapshot({ snapshotLocal: snapshot } = {}) {
    this.$timeout(() => {
      if (snapshot) {
        this.snapshotActivePk = snapshot.pk;
        this.snapshot = cloneDeep(snapshot);

        // FIXME: Hier sollte nach Anzeigekontexten sortiert werden; im Moment beachten wir nur den Antragstellerkontext
        const astkontext = "fadc32e7-d467-47b2-9928-a7bdf9830463";
        this.snapshotModule = Object.assign({}, this.snapshot);
        // angular.merge(this.snapshotModule, result);
        this.snapshotModule.ats_snapshot = this.snapshot.ats_snapshot[astkontext];
        if (this.snapshotDiff) {
          delete this.snapshotDiff;
          delete this.snapshotDiffModule;
        }
        this.showDiffSnapshot(this.snapshotActivePk);
      } else {
        if (this.snapshot) {
          delete this.snapshot;
          delete this.snapshotModule;
        }
        if (this.snapshotDiff) {
          delete this.snapshotDiff;
          delete this.snapshotDiffModule;
        }
        this.snapshotActivePk = undefined;
      }
    });
  }

  showDiffSnapshot(pk) {
    this.loadingDiff = pk;
    this.ast.snapshots.one(pk).one("diff").get().then(
      result => {
        this.snapshotDiff = result;
        // FIXME: Hier sollte nach Anzeigekontexten sortiert werden;
        // im Moment beachten wir nur den Antragstellerkontext
        const astkontext = "e8f5741f-e5eb-447a-b84f-742c51cbebc4";
        this.snapshotDiffModule = Object.assign({}, result);
        this.snapshotDiffModule.diff_result = {};
        this.snapshotDiffModule.diff_result.added = result.diff_result.added[astkontext] || {};
        this.snapshotDiffModule.diff_result.modified = result.diff_result.modified[astkontext] || {};
        this.snapshotDiffModule.diff_result.removed = result.diff_result.removed[astkontext] || {};
      },
      () => {
        this.DiasNotification.page.error("Beim Abrufen der Vergleichsdaten ist ein Fehler aufgetreten");
      }
    ).finally(() => this.loadingDiff = undefined);
  }

  showSnapshots() {
    EventBus.$emit("updateSnapshots");
  }

  showSnapshotIcons(step) {
    if (!(this.snapshotDiff && this.snapshot)) {
      return false;
    }

    if (step === "nachweise" || step === "sachbearbeiterunterlagen") {
      step = "dokumente";
      // TODO: Es müssten hier noch eine Fallunterscheidung anhand der
      // SyAnzeige geben.
    }

    let snapDiffMod = this.snapshotDiff.diff_result.modified[step];
    let snapDiffAdd = this.snapshotDiff.diff_result.added[step];
    let snapDiffRemove = this.snapshotDiff.diff_result.removed[step];

    let module = undefined;
    if (step === "antragstellerorg") {
      module = this.module;
    } else if (step === "pruefstatus") {
      module = this.pruefModule;
    } else if (step === "nachweise" || step === "sachbearbeiterunterlagen") {
      module = this.nachweisModule;
    } else if (step === "bankverbindungen") {
      module = this.bankverbindungModule;
    }

    if (!isNil(module)) {
      // FIXME: Mit Umstellung auf Kontexte sollten die Module hier
      // nicht mehr auftreten
      forEach(module, modul => {
        const FAZ = modul.regel.anzeigekontext;
        snapDiffMod = snapDiffMod || this.snapshotDiff.diff_result.modified[FAZ];
        snapDiffAdd = snapDiffAdd || this.snapshotDiff.diff_result.added[FAZ];
        snapDiffRemove = snapDiffRemove || this.snapshotDiff.diff_result.removed[FAZ];
      });
    }
    return (snapDiffMod || snapDiffAdd || snapDiffRemove);
  }

  getByPK(array, pk) {
    let result;
    angular.forEach(array, item => {
      if (item.pk === pk) {
        result = item;
        return;
      }
    });
    return result;
  }

  snapshotChangeClass(diff, added, removed, model, key) {
    let cls = "";

    angular.forEach(added, item => {
      if (item.pk === model.pk) {
        cls = "glyphicon glyphicon-changes-add version-color";
        return;
      }
    });

    angular.forEach(removed, item => {
      if (item.pk === model.pk) {
        cls = "glyphicon glyphicon-changes-delete version-color";
        return;
      }
    });

    if (!key) {
      return cls;
    }

    angular.forEach(diff, item => {
      if (item.pk === model.pk && item[key] !== undefined) {
        cls = "glyphicon glyphicon-changes version-color";
        return;
      }
    });

    return cls;
  }

  goToModulAfterLoad() {
    if (this.$stateParams.asr_nummer) {
      this.$timeout(() => {
        const REGEL_EL = document.getElementById(this.$stateParams.asr_nummer) || {};
        $(window).scrollTop(+REGEL_EL.offsetTop || 0);
      }, 100);
    }
  }

  loadModule() {
    this.loading.module = true;
    this.ast.module.getList().then(
      result => {
        this.module = [];
        this.bankverbindungModule = [];
        this.pruefModule = [];
        this.nachweisModule = [];
        this.vertreterModule = [];
        this.notizen = this.notizen || {};
        result.forEach(modul => {
          this.notizen[modul.regel.pk] = this.notizen[modul.regel.pk] || [];
          if (modul.regel.anzeigekontext === this.FoerderangebotAnzeigekontextUUIDs.pruefung) {
            this.pruefModule.push(modul);
          } else if (modul.regel.anzeigekontext === this.FoerderangebotAnzeigekontextUUIDs.nachweise) {
            this.nachweisModule.push(modul);
          } else if (modul.regel.anzeigekontext === this.FoerderangebotAnzeigekontextUUIDs.bankverbindung) {
            this.bankverbindungModule.push(modul);
          } else if (modul.regel.anzeigekontext === this.FoerderangebotAnzeigekontextUUIDs.vertretungsberechtigte) {
            this.vertreterModule.push(modul);
          } else {
            this.module.push(modul);
          }
        });
        this.hideNachweise = this.hideNachweise || this.nachweisModule.length === 0;
        this.goToModulAfterLoad();
      }
    ).finally(
      () => this.loading.module = false
    );
  }

  scrollTop() {
    $(window).scrollTop(0);
  }

  setView(viewName) {
    this.viewName = viewName;
  }

  createDurchfuehrendeOrgeinheit() {
    this.diasModalDialog({
      title: this.gettext("_LBL_ANTRAGSTELLER_DETAIL_MODAL_CREATE_DUFO_"),
      template: dorgCreateTemplate,
      controller: dorgCreateController,
      extras: { ast: this.ast }
    }).then(
      () => this.refreshDurchfuehrendeOrgeinheiten()
    );
  }

  refreshDurchfuehrendeOrgeinheiten() {
    if (!this.canViewDufo) {
      return;
    }
    this.loading.dorg = true;
    this.ast.durchfuehrendeorgeinheiten.getList().then(
      response => {
        this.durchfuehrendeorgeinheiten = response;
      }
    ).finally(() => this.loading.dorg = false);
  }

  createVertretungsberechtigten() {
    this.modalCreateVertreter = true;
  }

  setUrl() {
    this.urlVertreter = `antragstellerorganisationen/${ this.ast.pk }/vertretungsberechtigte/`;
    this.astUrl = `antragstellerorganisationen/${ this.ast.pk }/`;
  }

  closeModalCreate({ response } = {}) {
    this.$timeout(() => {
      if (response) {
        this.refreshVertretungsberechtigte();
      }
      this.modalCreateVertreter = false;
    });
  }

  refreshVertretungsberechtigte() {
    if (!this.AuthService.syncHasPerm("astorgvertreter.view", this.ast.user_permissions)) {
      return;
    }
    this.loading.vertreter = true;
    this.ast.vertretungsberechtigte.getList().then(
      response => {
        this.vertretungsberechtigte = response;
      }
    ).finally(() => this.loading.vertreter = false);
  }

  createSammelvertrag() {
    this.diasModalDialog({
      title: "Weiterleitungsvertrag erstellen",
      template: svCreateTemplate,
      controller: svCreateController,
      extras: { ast: this.ast }
    }).then(
      () => this.svExtra.lastChange = new Date().getTime()
    );
  }

  deaktivieren() {
    return this.diasConfirmDialog({
      titleTemplate: this.gettext("Antragstellerorganisation") + " deaktivieren",
      contentTemplate: `<p>Sie sind dabei, die {{ this.gettext("Antragstellerorganisation") }} {{ context.astorg.ast_name }} zu deaktivieren. Dadurch ist die Organisation in den Antragsteller-Listen nicht mehr zu finden. Sie können diese Aktion nicht rückgängig machen. Möchten Sie dies wirklich tun?</p>`,
      context: { astorg: this.ast },
      okLabel: "Deaktivieren",
      okCallback: () => this.ast.deaktivieren().then(
        () => {
          this.DiasNotification.page.success(this.gettext("Antragstellerorganisation") + " deaktiviert");
          this.$state.go("root.astorganisationen");
        },
        error => this.DiasNotification.page.error(error)
      )
    });
  }

  delete() {
    return this.diasConfirmDialog({
      titleTemplate: this.gettext("Antragstellerorganisation") + " löschen",
      contentTemplate: `<p>Sie sind dabei, die {{ this.gettext("Antragstellerorganisation") }} {{ context.astorg.ast_name }} endgültig zu löschen. Sie können diese Aktion nicht rückgängig machen. Möchten Sie dies wirklich tun?</p>`,
      context: { astorg: this.ast },
      okLabel: "Löschen",
      okCallback: () => this.ast.delete().then(
        () => {
          this.DiasNotification.page.success(this.gettext("Antragstellerorganisation") + " löschen");
          this.$state.go("root.astorganisationen");
        },
        error => this.DiasNotification.page.error(error)
      )
    });
  }

  initNutzerTable() {
    this.AuthService.getUser().then(user => {
      this.user = user;
      this.updatePerms();
    });
  }

  updatePerms() {
    this.one_astadmin_only = this.SyConfigs.wert("astorgroles_default").one_astadmin_only;
    this.perms = {};
    const req_perms = [
      "antragsteller.zuordnen.update",
      "antragstellerorg.verwalten_alle",
      "astorgnutzer.view",
      "astorgnutzer.ui",
      "astorgnutzer.deactivate",
      "antragsteller.astadminrolle_zuweisen"
    ];
    this.amIFachAdmin = false;
    this.amIMasterOfCurrentAstOrg = false;
    this.perms = this.AuthService.syncHasPerms(req_perms, this.ast.user_permissions);
    this.canViewNutzerTable = this.perms["astorgnutzer.ui"];
    if (!this.canViewNutzerTable) {
      this.removeFromSteps({ key: "nutzer" });
    }
    this.amIFachAdmin = this.perms["antragstellerorg.verwalten_alle"];
    this.amIMasterOfCurrentAstOrg = (this.ast.master.map(
      function(e) {
        return e.n_id;
      }
    ).indexOf(this.user.n_id) !== -1);
  }

  setGeprueft() {
    if (this.amIFachAdmin) {
      return this.diasConfirmDialog({
        titleTemplate: "Als geprüft markieren",
        contentTemplate: `<p>Sind Sie sicher, dass Sie die {{ this.gettext("antragstellende Organisation") }}
        {{ context.astorg.ast_name }} als geprüft markieren wollen?</p>`,
        context: { astorg: this.ast },
        okLabel: "Als geprüft markieren",
        okCallback: () => this.ast.setGeprueft().then(
          response => {
            this.DiasNotification.page.success("Als geprüft markiert");
            this.ast = response;
          },
          error => this.DiasNotification.page.error(error)
        )
      });
    }
  }

  setUngeprueft() {
    if (this.amIFachAdmin) {
      return this.diasConfirmDialog({
        titleTemplate: "Als ungeprüft markieren",
        contentTemplate: `<p>Sind Sie sicher, dass Sie die {{ this.gettext("antragstellende Organisation") }}
        {{ context.astorg.ast_name }} als ungeprüft markieren wollen?</p>`,
        context: { astorg: this.ast },
        okLabel: "Als ungeprüft markieren",
        okCallback: () => this.ast.setUngeprueft().then(
          response => {
            this.DiasNotification.page.success("Als ungeprüft markiert");
            this.ast = response;
          },
          error => this.DiasNotification.page.error(error)
        )
      });
    }
  }

  reloadAntragsteller() {
    return this.Antragstellerorganisationen.one(this.$stateParams.id).get().then(
      response => {
        // only copy server received properties into existing antragstelle object
        angular.extend(this.ast, response.plain());
        this.reloadWorkflowfolgen();
      }
    );
  }

  openAufgabenKontextPanel() {
    if (isNil(this.anzeigekontexteAntragstellerOffen)) {
      return;
    }
    let scrolled = false;
    forEach(this.steps, step => {
      if (this.anzeigekontexteAntragstellerOffen[step.key]) {
        this.toggleStep(step.key, true);
        if (!scrolled) {
          scrolled = true;
          this.$timeout(() => this.$anchorScroll(`step_${ step.key }`), 1000);
        }
      }
    });
    return scrolled;
  }

  toggleStep(key, forceState) {
    if (!isNil(forceState)) {
      this.stepsOpen[key] = forceState;
    } else {
      this.stepsOpen[key] = !this.stepsOpen[key];
    }
  }

  loadKataloge() {
    this.loading.kataloge = true;
    this.Katalog.getKatalog("antragstellerorgtyp").then(
      response => this.antragstellerorgtypen = response
    ).finally(() => this.loading.kataloge = false);
  }

  resetValidation() {
    this.validationErrorsLoading = undefined;
    this.validationErrors = undefined;
  }

  updateModuleFromVue({ response }) {
    this.$timeout(() => {
      const INDEX = findIndex(this.module, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.module.splice(INDEX, 1, response);
      }
      this.reloadAntragsteller();
    });
  }

  updatePruefModuleFromVue({ response }) {
    this.$timeout(() => {
      const INDEX = findIndex(this.pruefModule, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.pruefModule.splice(INDEX, 1, response);
      }
    });
  }

  updateNachweisModuleFromVue({ response }) {
    this.$timeout(() => {
      const INDEX = findIndex(this.nachweisModule, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.nachweisModule.splice(INDEX, 1, response);
      }
    });
  }
  updateBankverbindungModuleFromVue({ response }) {
    this.$timeout(() => {
      const INDEX = findIndex(this.bankverbindungModule, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.bankverbindungModule.splice(INDEX, 1, response);
      }
    });
  }

  updateVertreterModuleFromVue({ response }) {
    this.$timeout(() => {
      const INDEX = findIndex(this.vertreterModule, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.vertreterModule.splice(INDEX, 1, response);
      }
    });
  }

  updateAufgabeFromVue(newAufgabe) {
    this.$timeout(() => {
      this.updateAufgabe(newAufgabe);
    });
  }

  updateAufgabe(newAufgabe) {
    Object.assign(this.ast.aktuelle_aufgabe, newAufgabe);
  }

  removeFromSteps({ key = "" }) {
    const INDEX = findIndex(this.steps, ["key", key]);
    if (INDEX !== -1) {
      this.steps.splice(INDEX, 1);
    }
  }

  restrictSteps() {
    if (!this.sachbearbeiterDocAnzeigen || !this.AuthService.syncHasPerms("dokument.view", this.ast.user_permissions)) {
      this.removeFromSteps({ key: "sachbearbeiterunterlagen" });
    }
    if (!this.pruefungViewable) {
      this.removeFromSteps({ key: "pruefungen" });
    }
    if (this.hideDufo || !this.canViewDufo) {
      this.removeFromSteps({ key: "dufos" });
    }
    if (this.hideNachweise) {
      this.removeFromSteps({ key: "nachweise" });
    }
    if (this.hideBV) {
      this.removeFromSteps({ key: "bankverbindungen" });
    }
  }

  onWorkflowInit(clientFunktion) {
    this.$timeout(() => {
      this.loading.aufgabe = true;
      this.clientFunktion = clientFunktion;
    });
  }

  onWorkflowStarted(newAufgabe, currentWorkflowfolge) {
    this.$timeout(() => {
      this.updateAufgabe(newAufgabe);
      this.validationErrorsLoading = true;
      this.oldAufgabePk = this.ast.aktuelle_aufgabe.pk;
      this.currentWorkflowfolge = currentWorkflowfolge;
    });
  }

  onWorkflowFinish() {
    this.$timeout(() => {
      this.loading.aufgabe = false;
      this.clientFunktion = undefined;
      this.validationErrorsLoading = false;
      this.oldAufgabePk = undefined;
      this.currentWorkflowfolge = undefined;
    });
  }

  onWorkflowCanceled() {
    this.$timeout(() => {
      this.loading.aufgabe = false;
      this.clientFunktion = undefined;
      this.validationErrorsLoading = false;
    });
  }

  onWorkflowSuccess(aufgabe) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerSuccess(aufgabe);
      }
      if (aufgabe.pk === this.oldAufgabePk) {
        this.reloadWorkflowfolgen();
      }
      this.validationErrors = undefined;
      if (get(this.currentWorkflowfolge, "wfo_reload")) {
        return this.reloadAstOrg().finally(() => {
          if (!this.openAufgabenKontextPanel()) {
            this.scrollTop();
          }
        });
      }
      if (!this.openAufgabenKontextPanel()) {
        this.scrollTop();
      }
    });
  }

  onWorkflowFailure(err) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerError(err);
      }
      if (err.data && err.data.error_data) {
        this.validationErrors = err.data.error_data;
      } else if (err.data && !angular.isString(err.data)) {
        this.validationErrors = err.data;
      } else {
        this.validationErrors = undefined;
      }
      this.scrollTop();
      return this.reloadAstOrg();
    });
  }
}

export default {
  template: detailTemplate(),
  controller: AstorgdetailsController,
  controllerAs: "vm"
};
