import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import { CalendarObjectTypesObj } from "../CalendarObjectTypes";

// @vue/component
export default {
  name: "CalendarEventDetails",
  components: {
    AngularLink,
    Permission,
    PuxButton,
    PuxCloak,
    PuxTranslate,
  },
  props: {
    meeting: {
      type: Object,
      default: () => ({}),
    },
    meetingDetails: {
      type: Object,
      default: undefined,
    },
    mePk: {
      type: String,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    openModalEdit: {
      type: Function,
      required: true,
    },
    openModalDelete: {
      type: Function,
      required: true,
    },
    downloadMeeting: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    return {
      filterDate,
    };
  },
  data() {
    return {
      calendarObjectTypesObj: CalendarObjectTypesObj,
    };
  },
  computed: {
    getObjectName() {
      return this.calendarObjectTypesObj[this.meetingDetails.objekt.content_type_id].label;
    },

    getObjectHrefOptions() {
      return this.calendarObjectTypesObj[this.meetingDetails.objekt.content_type_id].getUrl({
        id: this.meetingDetails.objekt.objekt_id,
        parentId: this.meetingDetails.objekt.parent_id
      }) || {};
    },

    isMyEvent() {
      return (this.meetingDetails.author && this.meetingDetails.author.pk === this.mePk);
    },

    getSichtbarkeitText() {
      return this.meetingDetails.private_termin === false ? "_TXT_CALENDAR_EVENT_DETAILS_SICHTBARKEIT_PUBLIC_" : "_TXT_CALENDAR_EVENT_DETAILS_SICHTBARKEIT_PRIVATE_";
    },

    getOffentlichkeitText() {
      return this.meeting.offentlich === false ? "_TXT_CALENDAR_EVENT_DETAILS_OFFENTLICHKEIT_FALSE_" : "_TXT_CALENDAR_EVENT_DETAILS_OFFENTLICHKEIT_TRUE_";
    },
  },
  methods: {
    openModalMeetingChange() {
      this.openModalEdit({ meeting: this.meetingDetails });
    },

    onDeleteMeeting() {
      this.openModalDelete({ meeting: this.meeting });
    },

    onDownloadMeeting() {
      this.downloadMeeting({ meeting: this.meeting });
    },
  },
};
