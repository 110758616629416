import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../../../global/components/PuxIcon/PuxIcon.vue";

import {
  isArray,
} from "lodash-es";

// @vue/component
export default {
  name: "Step",
  components: {
    PuxButton,
    PuxIcon,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    parameter: {
      type: Object,
      required: true,
    },
    additionalFilters: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getLink() {
      const params = Object.assign({}, this.additionalFilters);
      if (!this.data && this.parameter.goto_list) {
        return this.$stateHref(this.parameter.goto_list, params);
      } else if (this.data.relevantes_objekt_pk) {
        const pk = this.data.relevantes_objekt_pk;
        if (isArray(this.data.relevantes_objekt_pk)) {
          return this.$stateHref(this.parameter.goto_details, { id: pk[0], aus_id: pk[1] });
        }
        return this.$stateHref(this.parameter.goto_details, { id: pk });
      } else if (this.data.workflowstatusgruppe && this.data.workflowstatusgruppe.pk) {
        params.workflowstatusgruppe = this.data.workflowstatusgruppe.pk;
        return this.$stateHref(this.parameter.goto_list, params);
      }
      return undefined;
    },

    componentLocal() {
      if (!this.parameter || !this.parameter.goto_list || !this.data.anzahl_objekte) {
        return "div";
      }
      return "a";
    },

    localStepParameter() {
      if (this.data.anzahl_objekte) {
        return {
          href: this.getLink
        };
      }
      return {};
    },
  },
};
