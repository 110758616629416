// @vue/component
export default {
  name: "StyleguideTableOptionsMassActions",
  data() {
    return {
      massActions: [
        {
          label: "label",
          type: "String",
          default: "undefined",
          description: "",
          required: true,
          example: `label: "Nachrichten löschen",`,
          options: "",
        },
        {
          label: "callback",
          type: "String",
          default: "undefined",
          description: "",
          required: false,
          example: "callback: ({ pks }) => this.deleteNachrichten({ pks }),",
          options: "",
        },
        {
          label: "modal",
          type: "Funktion",
          default: "undefined",
          description: "",
          required: false,
          example: "modal: true,",
          options: "",
        },
        {
          label: "downloadFilename",
          type: "String",
          default: "undefined",
          description: "",
          required: false,
          example: `downloadFilename: "personenbezogene_daten.json",`,
          options: "",
        },
      ]
    };
  },
};
