import HttpMixin from "../../../../../global/mixins/HttpMixin";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import translate from "../../../../../global/directives/translate";
import {
  initModelFromList,
} from "../../../../../global/functions/mappingForParameterToFormElement";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import {
  cloneDeep,
  isNil,
  findIndex,
  isFunction,
  isUndefined,
  assign,
} from "lodash-es";

// @vue/component
export default {
  name: "AdressenModalCreateOrEdit",
  components: {
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    addressList: {
      type: Array,
      required: true,
    },
    address: {
      type: Object,
      required: false,
      default: undefined,
    },
    selectorClose: {
      type: [String, Array],
      required: true,
    },
    defaultPlz: {
      type: String,
      required: false,
      default: undefined,
    },
    defaultTyp: {
      type: String,
      required: false,
      default: undefined,
    },
    urlSave: {
      type: String,
      required: true,
    },
    plzCenterCoordinates: {
      type: Object,
      required: true,
    },
    infoIText: {
      type: String,
      required: false,
      default: undefined,
    },
    setPlzCenterCoordinates: {
      type: Function,
      required: true,
    },
    statusShowCoordinates: {
      type: Boolean,
      required: true,
    },
    statusShowKontinent: {
      type: Boolean,
      required: true,
    },
    childSave: {
      type: Function,
      required: false,
      default: undefined,
    },
    regel: {
      type: Object,
      required: false,
      default: undefined,
      info: "Das Objekt der Foerderantragregels",
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      addressListLocal: cloneDeep(this.addressList),
      laenderoptionenLocal: cloneDeep(this.laenderoptionen),
      model: {},
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
      timerPLZ: undefined,
    };
  },
  computed: {
    headerText() {
      return this.address ? "_TXT_ADDRESSE_MODAL_EDIT_HEADER_" : "_TXT_ADDRESSE_MODAL_ADD_HEADER_";
    },

    optionsModal() {
      return {
        showCloseButton: true,
        required: true,
        list: this.addressListLocal,
      };
    },
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      const MODEL = initModelFromList({ list: this.addressListLocal });
      if (this.address) {
        this.model = assign(MODEL, cloneDeep(this.address));
        if (this.model.adr_plz && this.statusShowCoordinates) {
          this.loadCoordinatesForPlz({ model: this.model.adr_plz, initDefault: true });
        }
      } else {
        if (this.defaultTyp) {
          MODEL.typ = this.defaultTyp;
        }
        this.model = MODEL;
        if (this.defaultPlz && this.statusShowCoordinates) {
          this.loadCoordinatesForPlz({ model: this.defaultPlz, initDefault: true });
        }
      }
    },

    save() {
      this.status.loading = true;
      this.clearErrors();
      const DATA = {
        adresse: this.filterModelCoordinates(),
      };
      if (isFunction(this.childSave)) {
        return new Promise(() => {
          this.childSave({ data: DATA, regel: this.regel.regel }).then(
            response => {
              const TEXT = this.address ?
                "_MSG_ADDRESSE_EDIT_SUCCESS_" :
                "_MSG_ADDRESSE_ADD_SUCCESS_";
              this.addNotification({ text: TEXT });
              this.close({ response });
            },
            errors => {
              this.onError({ error: errors.data });
              const TEXT = this.address ?
                "_MSG_ADDRESSE_EDIT_ERROR_" :
                "_MSG_ADDRESSE_ADD_ERROR_";
              this.addNotification({ text: TEXT, type: "error" });
              this.status.loading = false;
            }
          );
        });
      }
      this.putHttp({
        url: this.urlSave,
        data: DATA,
      }).then(
        response => {
          const TEXT = this.address ?
            "_MSG_ADDRESSE_EDIT_SUCCESS_" :
            "_MSG_ADDRESSE_ADD_SUCCESS_";
          this.addNotification({ text: TEXT });
          this.close({ response });
        },
        error => {
          this.onError({ error: error.data });
          const TEXT = this.address ?
            "_MSG_ADDRESSE_EDIT_ERROR_" :
            "_MSG_ADDRESSE_ADD_ERROR_";
          this.addNotification({ text: TEXT, type: "error" });
        }
      ).then(
        () => this.status.loading = false
      );
    },

    onError({ error }) {
      if (error) {
        this.errors = error.adresse;
        this.errorsClone = error.adresse;
      }
    },

    clearErrors() {
      this.errors = undefined;
      this.errorsClone = undefined;
    },

    changeModel({ id, model, item }) {
      if (id === "adr_plz") {
        this.changePlz({ model });
      } else if (id === "land" && this.statusShowKontinent && model) {
        this.model.kontinent = item.kontinent || null;
      }
    },

    changePlz({ model }) {
      clearTimeout(this.timerPLZ);
      if (!this.statusShowCoordinates) {
        return;
      }
      this.timerPLZ = setTimeout(() => {
        this.loadCoordinatesForPlz({ model });
      }, 500);
    },

    loadCoordinatesForPlz({ model, initDefault }) {
      if (isNil(model) || model === "" || model.length < 4) {
        return;
      }
      if (this.plzCenterCoordinates[model]) {
        this.setCenterForUiCoordinates({ plz: model });
        if (!initDefault) {
          this.setModelForOrtAndBundesland({ plz: model });
        }
        return;
      }
      this.getHttp({
        url: "katalog/?key=plz_bundesland",
        urlParams: {
          pbu_plz: model,
        }
      }).then(
        response => {
          if (response.length === 1) {
            this.setPlzCenterCoordinates({ plz: model, coordinates: response[0] });
            this.setCenterForUiCoordinates({ plz: model });
            if (!initDefault) {
              this.setModelForOrtAndBundesland({ plz: model });
            }
          }
        }
      );
    },

    setCenterForUiCoordinates({ plz } = {}) {
      const CURRENT_PLZ_COORDINATES = this.plzCenterCoordinates[plz];
      if (isNil(CURRENT_PLZ_COORDINATES.pbu_latitude) ||
        isNil(CURRENT_PLZ_COORDINATES.pbu_longitude)) {
        if (plz !== this.defaultPlz) {
          this.setCenterForUiCoordinates({ plz: this.defaultPlz });
        }
        return;
      }
      const INDEX_GEOKOORD = findIndex(this.addressListLocal, ["id", "adr_geokoord"]);
      this.addressListLocal[INDEX_GEOKOORD].center = [CURRENT_PLZ_COORDINATES.pbu_latitude, CURRENT_PLZ_COORDINATES.pbu_longitude];
    },

    setModelForOrtAndBundesland({ plz } = {}) {
      const CURRENT_PLZ_OBJ = this.plzCenterCoordinates[plz];
      if (!isUndefined(this.model.adr_ort)) { // Wenn Ort konfiguriert
        this.model.adr_ort = CURRENT_PLZ_OBJ.pbu_ort || this.model.adr_ort;
      }
      if (!isUndefined(this.model.bundesland)) { // Wenn Bundesland konfiguriert
        this.model.bundesland = CURRENT_PLZ_OBJ.bundesland || this.model.bundesland;
      }
    },

    filterModelCoordinates() {
      const MODEL = cloneDeep(this.model);
      if (!MODEL.adr_geokoord) {
        return MODEL;
      }
      for (let i = 0; i < MODEL.adr_geokoord.length; i++) {
        if (isNil(MODEL.adr_geokoord[i]) || MODEL.adr_geokoord[i] === "") {
          MODEL.adr_geokoord.splice(i, 1);
          i--;
        }
      }
      if (!MODEL.adr_geokoord.length || MODEL.adr_geokoord.length === 1) {
        delete MODEL.adr_geokoord;
      }
      if (MODEL.weitere_objekte) {
        delete MODEL.weitere_objekte;
      }
      return MODEL;
    },
  },
};
