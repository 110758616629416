"use strict";


class EditorStateModalController {
  /* @ngInject */
  constructor($scope, KAufgabe) {
    const au_id = $scope.extras.au_id;
    if (!angular.isUndefined(au_id)) {
      this.loading = {
        aufgabe: true,
        berechtigungen: true
      };
      KAufgabe.one(au_id).get().then(
        response => {
          this.aufgabe = response;
          $scope.setTitle("<span title='" + this.aufgabe.au_kbez + "'>Aufgabe \"" + this.aufgabe.au_kbez + "\"</span>");
        },
        () => this.error = true
      ).finally(() => this.loading.aufgabe = false);
      KAufgabe.one(au_id).getBerechtigungen().then(
        response => this.berechtigungen = response,
        () => this.error = true
      ).finally(() => this.loading.berechtigungen = false);
    } else {
      this.error = true;
    }
  }
}

export default EditorStateModalController;
