"use strict";

import template from "./manuelleAufgabe.box.jade";
import editWiedervorlageTemplate from "./manuelleAufgabe.edit.wiedervorlage.jade";
import editWiedervorlageController from "./manuelleAufgabe.edit.wiedervorlage.controller";
import editTerminTemplate from "./manuelleAufgabe.edit.termin.jade";
import editTerminController from "./manuelleAufgabe.edit.termin.controller";

import aufgabeZuweisenTemplate from "./aufgabe.zuweisen.modal.jade";
import aufgabeZuweisenController from "./aufgabe.zuweisen.modal.controller";


class ManuelleAufgabeBoxController {
  /*@ngInject*/
  constructor(
    Nutzeraufgabe, 
    DiasNotification, 
    diasModalDialog, 
    diasConfirmDialog,
    KAufgabenstatusUUIDs
  ) {
    this.Nutzeraufgabe = Nutzeraufgabe;
    this.notification = DiasNotification.page;
    this.diasConfirmDialog = diasConfirmDialog;
    this.diasModalDialog = diasModalDialog;
    this.KAufgabenstatusUUIDs = KAufgabenstatusUUIDs;
    this.editWiedervorlageTemplate = editWiedervorlageTemplate;
    this.editWiedervorlageController = editWiedervorlageController;
    this.editTerminTemplate = editTerminTemplate;
    this.editTerminController = editTerminController;

    this.onAufgabenChanged()();
  }

  markErledigt(aufgabe) {
    return this.diasConfirmDialog({
      title: "Aufgabe als erledigt markieren",
      content: "Wollen Sie die Aufgabe \"" + aufgabe.kbez + "\" als erledigt markieren?",
      okCallback: () => {
        return this.Nutzeraufgabe.one(aufgabe.pk).customPUT({ status: this.KAufgabenstatusUUIDs.abgeschlossen }, "status_aendern").then(
          () => {
            this.notification.success("Die Aufgabe wurde als erledigt markiert.");
            this.onAufgabenChanged()();
          },
          () => this.notification.error("Fehler beim Ändern des Aufgabenstatus.")
        );
      }
    });
  }

  wiedervorlageTerminAendern(aufgabe) {
    return this.diasModalDialog({
      title: "Wiedervorlage-Termin wählen",
      template: this.editWiedervorlageTemplate,
      controller: this.editWiedervorlageController,
      extras: { aufgabe: aufgabe }
    });
  }

  terminAendern(aufgabe) {
    return this.diasModalDialog({
      title: "Termin wählen",
      template: this.editTerminTemplate,
      controller: this.editTerminController,
      extras: { aufgabe: aufgabe }
    });
  }

  assignAufgabe(aufgabe) {
    if (angular.isUndefined(aufgabe)) {
      return;
    }
    return this.diasModalDialog({
      title: "Aufgabe zuweisen",
      template: aufgabeZuweisenTemplate,
      controller: aufgabeZuweisenController,
      extras: {
        reloadPage: () => this.onAufgabenChanged()(),
        aufgabe: aufgabe
      },
    });
  }
}

export default {
  template: template(),
  controller: ManuelleAufgabeBoxController,
  controllerAs: "vm",
  bindings: {
    obj: "<",
    aufgaben: "=",
    onAufgabenChanged: "&"
  },
};
