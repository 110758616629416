"use strict";

import template from "./template.jade";

class KostenOhneBezeichnungBetragsrechnerController {
  /*@ngInject*/
  constructor(i18n) {
    this.gettext = i18n.gettext;
    if (!this.kontext) {
      this.kontext = "antrag";
    }
  }
}

export default {
  template: template(),
  controller: KostenOhneBezeichnungBetragsrechnerController,
  controllerAs: "ctx",
  bindings: {
    rechner: "=",
    posmodel: "=",
    errors: "=",
    extras: "=",
    kontext: "=?",
    prefix: "<?"
  },
};
