"use strict";

import angular from "angular";

import coreModels from "dias/core/models";
import Gutachtenvorlagen from "./gutachtenvorlagen.service";
import Gutachten from "./gutachten.service";
import Gutachter from "./gutachter.service";
import GutachtenPruefer from "./gutachtenPruefer.service";
import GutachtenErsteller from "./gutachtenErsteller.service";

const modelsModule = angular.module("gutachtenvorlage.models", [
  coreModels.name,
])

  .provider("Gutachtenvorlagen", Gutachtenvorlagen)
  .provider("Gutachten", Gutachten)
  .provider("Gutachter", Gutachter)
  .provider("GutachtenPruefer", GutachtenPruefer)
  .provider("GutachtenErsteller", GutachtenErsteller)
;

export default modelsModule;
