"use strict";

import template from "./tasklog.status.jade";

class TaskLogStatusController {
  /*@ngInject*/
  constructor() {
    this.statusLabel = {
      PENDING: "in Warteschlange",
      STARTED: "gestartet",
      RUNNING: "in Bearbeitung",
      SUCCESS: "erfolgreich beendet",
      PARTIAL_SUCCESS: "teilweise erfolgreich beendet",
      FAILURE: "mit Fehler beendet",
    };
    this.statusMap = {
      "glyphicon-time text-dark": this.status === "PENDING",
      "glyphicon-ellipsis-h text-dark": this.status === "RUNNING" || this.status === "STARTED",
      "glyphicon-ok text-success": this.status === "SUCCESS",
      "glyphicon-ok text-warning": this.status === "PARTIAL_SUCCESS",
      "glyphicon-remove text-danger": this.status === "FAILURE"
    };
  }
}

export default {
  template: template(),
  controller: TaskLogStatusController,
  controllerAs: "vm",
  bindings: {
    status: "<"
  },
};
