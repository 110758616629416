"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";

const modul = angular.module("dias.codes", [
  uiRouter,
]);

modul.config($stateProvider => {
  $stateProvider
    .state("root.codes", {
      url: "/codes/",
      views: {
        "main@root": {
          template: "<div vueinit><code-list></code-list></div>"
        }
      },
      data: {
        permissions: ["codes.ui"]
      }
    });
})
;


export default modul;
