import UIComponentMixin from "../UIComponentMixin";
import UiRatingMixin from "./UiRatingMixin";

// @vue/component
export default {
  name: "UiRating",
  mixins: [
    UIComponentMixin,
    UiRatingMixin,
  ],
  methods: {
    setSelectedModel(id) {
      this.onInput_mixin({ value: id });
    },

    setHoverIndex(index) {
      this.hoverIndex = index + 1;
    },
  },
};
