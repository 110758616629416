"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";

class FoerderangebotBasisdatenEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, i18n) {
    super($q, $scope, DiasNotification);
    this.errorsLabel = {
      si_durchfuehrungsort: "Veranstaltungsort",
      si_strasse: "Straße: ",
      si_hsnr: "Hausnummer: ",
      si_plz: "PLZ: ",
      si_ort: "Ort: ",
      si_sitzungsdatum: "Termin: ",
      si_buchungsdatum: "Buchungsdatum: "
    };
    this.gettext = i18n.gettext;
  }
}

export default FoerderangebotBasisdatenEditController;
