"use strict";

import BetragsrechnerDetailliert from "dias/kosten_finanzierung/betragsrechner/finanzierung_detailliert_betragsrechner/betragsrechner";


class BetragsrechnerDetailliertNational extends BetragsrechnerDetailliert {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "finanzierung_detailliert_national_betragsrechner";
    this.component_name = "finanzierungdetailliertnational";
    this.fields.antrag.land = "2545f35d-754b-1e33-e050-007f010106dc";
  }
}

export default BetragsrechnerDetailliertNational;
