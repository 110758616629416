"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";

import traegerwechselWizardComponent from "./traegerwechsel.wizard.component";
import traegerwechselComponent from "./traegerwechsel.component";


const modul = angular.module("dias.antragsteller.traegerwechsel", [
  "ngSanitize",
  uiRouter,
]);

modul.config($stateProvider => {
  $stateProvider

    .state("root.astorganisationen.details.traegerwechsel", {
      url: "traegerwechsel/",
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<traegerwechsel></traegerwechsel>"
        }
      },
      data: {
        permissions: "antragstellerorg.traegerwechsel"
      }
    });
});

modul.component("traegerwechsel", traegerwechselComponent);
modul.component("astTraegerwechsel", traegerwechselWizardComponent);

export default modul;
