"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";
import bvCreateController from "dias/antragsteller/bankverbindung.modal.create.controller";
import bvCreateTemplate from "dias/antragsteller/bankverbindung.modal.create.jade";


class BankverbindungEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, diasModalDialog) {
    super($q, $scope, DiasNotification);
    this.diasModalDialog = diasModalDialog;
    this.saveBV = this.saveBV.bind(this);
    this.ast = this.$scope.extra.ast;
    this.loadBankverbindungen();
  }

  onEnterEditMode() {
    super.onEnterEditMode();
    this.modelBankverbindungen = this.getBankverbindung();
  }

  getBankverbindung() {
    return this.model.foerdervertragsbestaetigung.bankverbindung;
  }

  loadBankverbindungen() {
    this.loadingBv = true;
    this.ast.bankverbindungen.getList().then(
      response => {
        this.bankverbindungenChoices = response;
      }
    );
  }

  saveBV() {
    const data = {
      bankverbindung: this.modelBankverbindungen,
    };
    let promise;
    if (this.errors) {
      const deferred = this.$q.defer();
      deferred.reject();
      promise = deferred.promise;
    } else {
      promise = this.model.saveBankverbindung(data).then(
        response => {
          this.$scope.model.foerdervertragsbestaetigung.bankverbindung_obj = this.$scope.model.foerdervertragsbestaetigung.bankverbindung_obj || {};
          const tmpModel = this.$scope.model.clone();
          tmpModel.foerdervertragsbestaetigung = response;
          this.$scope.model = this.$scope.model.merge(this.$scope.model, tmpModel);
          this.DiasNotification.page.success("Bankverbindung erfolgreich ausgewählt");
        }
      );
    }
    return promise;
  }

  createBankverbindung() {
    this.diasModalDialog({
      title: "Bankverbindung erstellen",
      template: bvCreateTemplate,
      controller: bvCreateController,
      extras: { ast: this.ast }
    }).then(
      bankverbindung => {
        if (bankverbindung && bankverbindung.pk) {
          this.modelBankverbindungen = bankverbindung.pk;
          this.loadBankverbindungen();
        }
      }
    );
  }
}

export default BankverbindungEditController;
