"use strict";

import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";

import builder from "../utils/builder";


const einfache_foerderregel = angular.module("dias.standard_foerdermodule.einfache_foerderregel", [
  foerdermoduleRegistry.name,
]);

einfache_foerderregel.config(FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("einfache_foerderregel", "modul-einfache-foerderregel");
})

  .directive("modulEinfacheFoerderregel", builder.directive(template, controller));

export default einfache_foerderregel;
