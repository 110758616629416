import FoerderorgAnsprechpartnerAddModal from "../FoerderorgAnsprechpartnerAddModal/FoerderorgAnsprechpartnerAddModal.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../global/compositionAPI/PermissionAPI";

// @vue/component
export default {
  name: "FoerderorgAnsprechpartnerAddBtn",
  components: {
    FoerderorgAnsprechpartnerAddModal,
  },
  props: {
    objekt: {
      type: Object,
      required: true,
      info: "Objekt an dem die Ansprechpartner hinterlegt werden",
    },
    baseUrl: {
      type: String,
      required: true,
      info: "URL für den Ansprechpartner-Endpunkt",
    },
  },
  setup() {
    const { getHttp, postHttp } = HttpAPI();
    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return { getHttp, checkPermissionsSync, postHttp };
  },
  data() {
    return {
      orgsLokal: [],
      isModalVisible: undefined,
      loading: undefined,
    };
  },
  computed: {
    isComponentVisible() {
      return this.orgsLokal.length > 0;
    },
    id() {
      return `ap_add_ansprechpartner`;
    },
    selectorClose() {
      return `#${ this.id }`;
    },

    hasUserCreatePermissions() {
      return this.checkPermissionsSync({
        perm: "ansprechpartner.create",
        permArray: this.objekt.user_permissions,
      });
    },
  },
  created() {
    this.loadOrgs();
  },
  methods: {
    loadOrgs() {
      if (this.hasUserCreatePermissions) {
        this.loading = true;
        this.getHttp({ url: `${ this.baseUrl }/ansprechpartner/foerderorgs_for_create/` })
          .then(response => this.orgsLokal = response)
          .finally(() => this.loading = false);
      }
    },
    openModal() {
      this.isModalVisible = true;
    },

    closeModal() {
      this.isModalVisible = false;
    },
  },
};
