import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "adminKataloge";

// @vue/component
export default {
  name: "KatalogeAdminList",
  components: {
    PageTabTitle,
    PuxIcon,
    PuxTranslate,
    SmartTable,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_LBL_KATALOGE_ADMIN_LIST_ACTIONS_ROW_DETAILS_",
            route: "root.katalogeadmin.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        label: "_LBL_KATALOGE_ADMIN_LIST_ADMINISTRIERBARE_KATALOGE_",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
    };
  },
};
