"use strict";

import template from "./template.jade";

class FinanzierungMitLandController {
  /*@ngInject*/
  constructor($scope, Katalog, i18n) {
    this.gettext = i18n.gettext;
    this.Katalog = Katalog;
    if (!this.kontext || this.kontext === "undefined") {
      this.kontext = "antrag";
    }
    this.$scope = $scope;
    this.laender = [];
    this.loadLaender();
  }

  loadLaender() {
    this.Katalog.getKatalog("klaenderkuerzel").then(
      response => this.lkzChoices = response
    );
  }
}

export default {
  template: template(),
  controller: FinanzierungMitLandController,
  controllerAs: "ctx",
  bindings: {
    rechner: "=",
    posmodel: "=",
    errors: "=",
    extras: "=",
    kontext: "=?",
    vorschau: "=",
    prefix: "<?"
  },
};
