import {
  toRef,
  watch,
} from "vue";

import AnonymisierungPlanMessage from "./AnonymisierungPlanMessage/AnonymisierungPlanMessage.vue";
import AnonymisierungPlanStufe from "./AnonymisierungPlanStufe/AnonymisierungPlanStufe.vue";
import AnonymisierungPlanStufeEdit from "./AnonymisierungPlanStufeEdit/AnonymisierungPlanStufeEdit.vue";
import FormstepDetail from "../../../global/components/FormstepDetail/FormstepDetail.vue";

import AnonymisierungPlanFormstepDetailAPI from "./compositionAPI/AnonymisierungPlanFormstepDetailAPI";
import AnonymisierungPlanLoadingAPI from "./compositionAPI/AnonymisierungPlanLoadingAPI";

// @vue/component
export default {
  name: "AnonymisierungPlan",
  components: {
    AnonymisierungPlanMessage,
    AnonymisierungPlanStufe,
    AnonymisierungPlanStufeEdit,
    FormstepDetail,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    planKatalogKey: {
      type: String,
      required: true,
    },
    planHeaderText: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      anonymisierungsplan,
      anonymisierungsplanUrl,
      katalogList,
      loadAllData,
      loading,
      reloading,
      updateAnonymisierungsplan,
    } = AnonymisierungPlanLoadingAPI(props);

    const {
      errors,
      errorsLabelsOptions,
      model,
      optionsFormstepDetail,
    } = AnonymisierungPlanFormstepDetailAPI(props, {
      anonymisierungsplan,
      anonymisierungsplanUrl,
      katalogList,
    });

    const object = toRef(props, "object");
    watch(
      () => object,
      updateAnonymisierungsplan,
      { deep: true }
    );

    loadAllData();

    return {
      anonymisierungsplan,
      katalogList,
      loading,
      reloading,

      errors,
      errorsLabelsOptions,
      model,
      optionsFormstepDetail,
    };
  },
};
