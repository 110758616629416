"use strict";

import angular from "angular";

/**
 * Erweitert eine gegebene Componente um Konfigurationen, die für Sitzungsmodule
 * erforderlich sind.
 */
export default {
  detail: baseComp => angular.merge(baseComp, {
    bindings: {
      idx: "@",
      modul: "=",
      top: "=",
      readonly: "=?",
      onSave: "&?",
      permissionSrc: "=?"
    }
  }),
  preview: baseComp => angular.merge(baseComp, {
    bindings: {
      modul: "=",
      top: "="
    }
  })
};

