"use strict";

import BaseModel from "dias/core/models/baseModel";

class Projektberichtvorlagen extends BaseModel {
  constructor() {
    super("projektberichtvorlagen", {
      subPaths: {
        regeln: "regeln",
      }
    });
  }

  regelnExtendModel(model) {
    model.moveUp = () => model.customPOST({}, "nach_oben");
    model.moveDown = () => model.customPOST({}, "nach_unten");
    model.titel = (model.pbr_nummer ? ("[" + model.pbr_nummer + "] ") : "") + model.pbr_kbez;
    return model;
  }
}

export default Projektberichtvorlagen;
