import FormstepDetail from "../../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import FormElement from "../../../../../../global/components/FormElement/FormElement.vue";
import PersonalfunktionElementInListe from "../PersonalfunktionElementInListe/PersonalfunktionElementInListe.vue";
import FieldsMixin from "./FieldsMixin";
import WizardStepsErrorsMixin from "../../WizardStepsErrorsMixin";
import WizardStepsRechnerMixin from "../../WizardStepsRechnerMixin";
import WizardStepsFieldsChangeWarningMixin from "../../WizardStepsFieldsChangeWarningMixin";
import WizardStepsPeriodenMixin from "../../WizardStepsPeriodenMixin";
import PuxTooltip from "../../../../../../global/components/PuxTooltip/PuxTooltip.vue";
import translate from "../../../../../../global/directives/translate";
import PuxIcon from "../../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import WizardStepsMessageObergrenzeMixin from "../../WizardStepsMessageObergrenzeMixin";
import {
  FilterCurrencyMixin,
} from "../../../../../../global/mixins/FiltersMixin";
import {
  isUndefined,
  round,
  isFunction,
  forEach,
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenPeriodenWizardStep1",
  components: {
    FormElement,
    PersonalfunktionElementInListe,
    FormstepDetail,
    PuxTooltip,
    PuxIcon,
    PuxTranslate,
  },
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
    WizardStepsErrorsMixin,
    WizardStepsRechnerMixin,
    WizardStepsFieldsChangeWarningMixin,
    FieldsMixin,
    WizardStepsPeriodenMixin,
    WizardStepsMessageObergrenzeMixin,
  ],
  props: {
    katalogDetails: {
      type: Array,
      default: () => [],
    },
    personalfunktionen: {
      type: Array,
      default: () => [],
    },
    katalogPersonalkostenObj: {
      type: Object,
      required: true,
    },
    personalstellenart: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Object,
      default: undefined,
    },
    position: {
      type: Object,
      default: undefined,
    },
    pktId: {
      type: String,
      required: true,
    },
    statusInitModel: {
      type: Boolean,
      required: true,
    },
    initModelInParent: {
      type: Function,
      required: true,
    },
  },
  emits: [
    "changeStatusShowMessageObergrenze",
  ],
  computed: {
    getTranslateOptionsANJahresbrutto() {
      return {
        text: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_AN_JAHRESBRUTTO_{{stellenanteil}}_",
        extra: {
          stellenanteil: this.model.stellenanteil,
        },
      };
    },

    getTranslateOptionsAGBruttogehaltStelle() {
      return {
        html: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_AG_JAHRESBRUTTO_STELLE_{{stellenanteil}}_{{pkt_pauschale}}_",
        extra: {
          stellenanteil: this.model.stellenanteil,
          pkt_pauschale: this.model.pkt_pauschale,
        },
      };
    },
    getTranslateOptionsAGBruttogehaltVollzeitstelestelle() {
      return {
        html: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_AG_JAHRESBRUTTO_VOLLZEITSTELLE_{{pkt_pauschale}}_",
        extra: {
          pkt_pauschale: this.model.pkt_pauschale,
        },
      };
    },
  },
  created() {
    this.initModel();
    this.initErrorLabels_mixin();
    setTimeout(() => {
      this.setStellenumfangMaxValue();
    });
  },
  methods: {
    initModel() {
      if (!this.statusInitModel) {
        return;
      }
      if (this.position) {
        this.initModelWithPosition();
      } else {
        this.initModelWithoutPosition();
      }
    },

    initModelWithPosition() {
      const MODEL = cloneDeep(this.position.argument);
      if (this.position.kostenartdetail) {
        MODEL.kostenartdetail = this.position.kostenartdetail;
      }
      MODEL.obergrenze = this.setObergrenze({ model: MODEL });
      MODEL.pkt_pauschale = this.getNumber({ item: this.personalkostentabelleObj.pkt_pauschale }) || 0;
      MODEL.stellenanteil = this.setStellenanteil({ model: MODEL });
      MODEL.an_jahresbrutto = this.setANJahresbrutto({ model: MODEL });
      MODEL.ag_bruttogehalt_vollzeitstelle = this.setAGBruttogehaltVollzeitstelle({ model: MODEL });
      MODEL.ag_bruttogehalt_stelle = this.setAGBruttogehaltStelle({ model: MODEL });
      MODEL.perioden = this.getModePeriodenWithPosition({ perioden: MODEL.perioden, model: MODEL });
      MODEL.zeitraum_von = MODEL.zeitraum_von || this.durchfuehrungszeitraumVon; // Für alte Anträge, wenn zeitraum_von === null
      MODEL.zeitraum_bis = MODEL.zeitraum_bis || this.durchfuehrungszeitraumBis; // Für alte Anträge, wenn zeitraum_bis === null

      this.initModelInParent({ model: MODEL, statusInitModel: false });
    },

    getModePeriodenWithPosition({ perioden, model }) {
      const MODEL_PERIODEN = [];
      const PERIODE_TEMP = {};
      let statusPeriodenTeil = false;
      const PKT_PAUSCHALE = model.pkt_pauschale;

      forEach(perioden, period => {
        if (!PERIODE_TEMP[period.periode]) {
          PERIODE_TEMP[period.periode] = 1;
        } else {
          PERIODE_TEMP[period.periode]++;
          statusPeriodenTeil = true;
        }
        const INFO = cloneDeep(period.periode_obj);
        INFO.teil = undefined;
        const PERIOD_CLONE = cloneDeep(period);
        PERIOD_CLONE.period_obj = undefined;
        PERIOD_CLONE.info = INFO;
        if (PERIOD_CLONE.argument && PERIOD_CLONE.argument.an_bruttogehalt) {
          PERIOD_CLONE.an_bruttogehalt = PERIOD_CLONE.argument.an_bruttogehalt;
        } else {
          PERIOD_CLONE.an_bruttogehalt = 0;
        }
        PERIOD_CLONE.ag_bruttogehalt = round(PERIOD_CLONE.an_bruttogehalt * (1 + PKT_PAUSCHALE / 100), 2);
        MODEL_PERIODEN.push(PERIOD_CLONE);
      });
      if (statusPeriodenTeil) {
        forEach(MODEL_PERIODEN, (period, index) => {
          if (PERIODE_TEMP[period.periode] > 1) {
            MODEL_PERIODEN[index].info.teil = true;
          }
        });
      }

      return MODEL_PERIODEN;
    },

    initModelWithoutPosition() {
      const MODEL = {
        perioden: this.getModePerioden(),
        pkt_id: this.pktId,
        name: "N.N.",
        personalfunktion: undefined,
        stellenbezeichnung: undefined,
        kostenartdetail: undefined,
        anzeige_personalstelle: undefined,
        stellenumfang: undefined,
        vollzeitstelle: undefined,
        jahresbrutto: undefined,
        pkt_pauschale: this.getNumber({ item: this.personalkostentabelleObj.pkt_pauschale }) || 0,
        stellenumfang_projekt: undefined,

        zeitraum_von: this.durchfuehrungszeitraumVon,
        zeitraum_bis: this.durchfuehrungszeitraumBis,
        lohnsteigerung: 0,
        startdatum: null,

        an_jahresbrutto: undefined,
        stellenanteil: undefined,
        ag_bruttogehalt_vollzeitstelle: undefined,
        ag_bruttogehalt_stelle: undefined,
        obergrenze: undefined,
      };
      this.initModelInParent({ model: MODEL, statusInitModel: false });
    },

    onInput({ id, options }) {
      if (isFunction(this[`changeInput__${ id }`])) {
        this[`changeInput__${ id }`]();
      }

      if (options.statusChangePeriod) {
        this.onInputChangePeriod_mixin({ id });
      }
    },

    changeInput__personalfunktion() {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.obergrenze = this.setObergrenze();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.jahresbrutto = this.setJahresbrutto();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_stelle = this.setAGBruttogehaltStelle();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_vollzeitstelle = this.setAGBruttogehaltVollzeitstelle();
    },

    changeInput__vollzeitstelle() {
      this.setStellenumfangMaxValue();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.stellenanteil = this.setStellenanteil();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.jahresbrutto = this.setJahresbrutto();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_stelle = this.setAGBruttogehaltStelle();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_vollzeitstelle = this.setAGBruttogehaltVollzeitstelle();
    },

    changeInput__stellenumfang() {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.stellenanteil = this.setStellenanteil();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.jahresbrutto = this.setJahresbrutto();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_stelle = this.setAGBruttogehaltStelle();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_vollzeitstelle = this.setAGBruttogehaltVollzeitstelle();
    },

    changeInput__an_jahresbrutto() {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.jahresbrutto = this.setJahresbrutto();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_stelle = this.setAGBruttogehaltStelle();
      // eslint-disable-next-line vue/no-mutating-props
      this.model.ag_bruttogehalt_vollzeitstelle = this.setAGBruttogehaltVollzeitstelle();
    },

    setStellenanteil({ model } = {}) {
      const MODEL_LOCAL = model ? model : this.model;
      const STELLENUMFANG = this.getNumber({ item: MODEL_LOCAL.stellenumfang });
      if (isUndefined(STELLENUMFANG)) {
        return undefined;
      }
      const VOLLZEITSTELLE = this.getNumber({ item: MODEL_LOCAL.vollzeitstelle });
      if (isUndefined(VOLLZEITSTELLE) ||
        VOLLZEITSTELLE === 0) {
        return undefined;
      }
      const STELLENANTEIL = +STELLENUMFANG * 100 / VOLLZEITSTELLE;
      if (isNaN(STELLENANTEIL)) {
        return undefined;
      }
      return round(STELLENANTEIL, 2);
    },

    setJahresbrutto({ model } = {}) {
      const MODEL_LOCAL = model ? model : this.model;
      const AN_JAHRESBRUTTO = this.getNumber({ item: MODEL_LOCAL.an_jahresbrutto });
      if (isUndefined(AN_JAHRESBRUTTO)) {
        return undefined;
      }

      const STELLENANTEIL = this.getNumber({ item: MODEL_LOCAL.stellenanteil });
      if (isUndefined(STELLENANTEIL)) {
        return undefined;
      }
      const GEHALT = +round(AN_JAHRESBRUTTO / STELLENANTEIL * 100, 2);
      if (MODEL_LOCAL.obergrenze) {
        if (GEHALT <= +MODEL_LOCAL.obergrenze) {
          return GEHALT;
        }
        return +MODEL_LOCAL.obergrenze;
      }
      return GEHALT;
    },

    setANJahresbrutto({ model } = {}) {
      if (!isUndefined(model.an_jahresbrutto)) {
        return model.an_jahresbrutto;
      }
      const JAHRESBRUTTO = this.getNumber({ item: model.jahresbrutto });
      if (isUndefined(JAHRESBRUTTO)) {
        return undefined;
      }
      const STELLENANTEIL = this.getNumber({ item: model.stellenanteil });
      if (isUndefined(STELLENANTEIL)) {
        return undefined;
      }

      return round(JAHRESBRUTTO * STELLENANTEIL / 100, 2);
    },

    setAGBruttogehaltVollzeitstelle({ model } = {}) {
      const MODEL_LOCAL = model ? model : this.model;
      const JAHRESBRUTTO = this.getNumber({ item: MODEL_LOCAL.jahresbrutto });
      if (isUndefined(JAHRESBRUTTO)) {
        return undefined;
      }
      const PKT_PAUSCHALE = MODEL_LOCAL.pkt_pauschale;
      const GEHALT = +round(JAHRESBRUTTO * (1 + PKT_PAUSCHALE / 100), 2);
      if (MODEL_LOCAL.obergrenze) {
        if (GEHALT <= +MODEL_LOCAL.obergrenze) {
          this.$emit("changeStatusShowMessageObergrenze", {
            status: false,
            wizardNummer: 1,
          });
          return GEHALT;
        }
        this.$emit("changeStatusShowMessageObergrenze", {
          status: true,
          wizardNummer: 1,
        });
        return +MODEL_LOCAL.obergrenze;
      }
      return GEHALT;
    },

    setAGBruttogehaltStelle({ model } = {}) {
      const MODEL_LOCAL = model ? model : this.model;
      const AN_JAHRESBRUTTO = this.getNumber({ item: MODEL_LOCAL.an_jahresbrutto });
      if (isUndefined(AN_JAHRESBRUTTO)) {
        return undefined;
      }
      const PKT_PAUSCHALE = MODEL_LOCAL.pkt_pauschale;
      const GEHALT = +round(AN_JAHRESBRUTTO * (1 + PKT_PAUSCHALE / 100), 2);
      if (MODEL_LOCAL.obergrenze) {
        const STELLENANTEIL = this.getNumber({ item: MODEL_LOCAL.stellenanteil });
        if (isUndefined(STELLENANTEIL)) {
          return undefined;
        }
        if (GEHALT < (+MODEL_LOCAL.obergrenze * (STELLENANTEIL / 100))) {
          return GEHALT;
        }
        return +MODEL_LOCAL.obergrenze * (STELLENANTEIL / 100);
      }
      return GEHALT;
    },

    setObergrenze({ model } = {}) {
      const MODEL_LOCAL = model ? model : this.model;
      if (!this.katalogPersonalkostenObj[MODEL_LOCAL.personalfunktion]) {
        return undefined;
      }
      return this.katalogPersonalkostenObj[MODEL_LOCAL.personalfunktion].spk_kosten;
    },

    setStellenumfangMaxValue() {
      const VOLLZEITSTELLE = this.getNumber({ item: this.model.vollzeitstelle });
      if (isUndefined(VOLLZEITSTELLE)) {
        this.fields.stellenumfang.maxValue = 50;
        return;
      }
      this.fields.stellenumfang.maxValue = VOLLZEITSTELLE;
    },
  },
};
