import PermissionMixin from "../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../global/mixins/SyConfigMixin";

import CalendarObjectTypes, {
  CalendarObjectTypesObj
} from "./CalendarObjectTypes";
import {
  cloneDeep,
  filter,
  includes
} from "lodash-es";

// @vue/component
export default {
  mixins: [
    PermissionMixin,
    SyConfigMixin,
  ],
  methods: {
    getMe() {
      this.mePk = this.me.pk;
      this.statusReady = true;
    },

    filterCalendarObjectTypes() {
      const ALLOWED_OBJECTS = this.getSyConfigsValue("erlaubte_objekte") || [];
      this.filteredCalendarObjectTypes = filter(cloneDeep(CalendarObjectTypes), item => {
        return this.checkPermissionsSync({ perm: CalendarObjectTypesObj[item.value].permission }) && includes(ALLOWED_OBJECTS, item.value);
      });
    },

    openModalCreateOrEdit() {
      this.modal.createOrEdit = true;
    },

    openModalDelete({ meeting }) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_CALENDAR_TERMIN_DELETE_MODAL_HEADER_{{titel}}_",
        msg: "_TXT_CALENDAR_TERMIN_DELETE_MODAL_BODY_",
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: () => this.deleteEvent({ meeting }),
        cancelCallback: this.closeConfirm,
        extra: {
          titel: meeting.ter_titel,
        },
      };
      this.modal.delete = true;
    },

    getOffentlich() {
      this.offentlich_allowed = this.getSyConfigsValue("termin_offentlichkeit") || false;
    },
  },
};
