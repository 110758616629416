import FoerderfinderCategoryButton from "../FoerderfinderCategoryButton/FoerderfinderCategoryButton.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "FoerderfinderCategory",
  components: {
    FoerderfinderCategoryButton,
    PuxButton,
  },
  props: {
    activeButtons: {
      type: Object,
      required: true,
      info: "Ausgewählte Buttons",
    },
    buttonStyles: {
      type: Object,
      required: true,
      validator: buttonStyles => {
        if ("kbez" in buttonStyles &&
          "bez" in buttonStyles &&
          "parent" in buttonStyles) {
          return true;
        }
      },
      info: "Styles für 'kbez', 'bez' und 'parent'",
    },
    category: {
      type: Object,
      required: true,
      info: "Kategorie des Förderfinders",
    },
    categoryIndex: {
      type: Number,
      required: true,
      info: "Index von Kategorie in Array",
    },
    isVisibleKbez: {
      type: Boolean,
      required: true,
      info: "Kurzbezeichnung vom Förderfinderkategoriewert anzeigen",
    },
    isVisibleBez: {
      type: Boolean,
      required: true,
      info: "Bezeichnung vom Förderfinderkategoriewert anzeigen",
    },
    isPictureVisibleByDefault: {
      type: Boolean,
      required: true,
      info: "Standardmäßig Bild anzeigen.",
    },
  },
  emits: [
    "resetFilter"
  ],
  data() {
    return {
      isBoxVisible: true,
    };
  },
  computed: {
    idForButtonSetDefault() {
      return `set_default_categories_${ this.categoryIndex }`;
    },

    idForCollapseButton() {
      return `toggle_categories_${ this.categoryIndex }`;
    },

    idForBox() {
      return `toggle_box_${ this.categoryIndex }`;
    },

    iconForCollapseButton() {
      return this.isBoxVisible ?
        "chevron-up" :
        "chevron-down";
    },

    titleForCollapseButton() {
      return this.isBoxVisible ?
        "_BTN_BEREICH_VERBERGEN_{{bez}}_" :
        "_BTN_BEREICH_ANZEIGEN_{{bez}}_";
    },

    extraForButtons() {
      return {
        bez: this.category.kbez,
      };
    },
  },
  methods: {
    resetFilterLocal() {
      this.$emit("resetFilter", this.category);
    },

    toggleBox() {
      this.isBoxVisible = !this.isBoxVisible;
    },
  },
};
