"use strict";

import BetragsrechnerRegistry from "../services";

import Betragsrechner from "./betragsrechner.js";
import BetragsrechnerController from "./controller";

const BetragsrechnerManuelleRueckforderung = angular.module("dias.betragsrechner.BetragsrechnerManuelleRueckforderung", [
  BetragsrechnerRegistry.name,
]);

BetragsrechnerManuelleRueckforderung.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("manuelle_rueckforderungen", Betragsrechner);
})
  .component("manuelleRueckforderungen", BetragsrechnerController);

export default BetragsrechnerManuelleRueckforderung;
