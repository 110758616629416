import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import FaqDetailsFilterBox from "./FaqDetailsFilterBox/FaqDetailsFilterBox.vue";
import FaqDetailsItem from "./FaqDetailsItem/FaqDetailsItem.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import FaqKeywordsFilterMixin from "./FaqKeywordsFilterMixin";
import HttpMixin from "../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import {
  cloneDeep,
  filter,
  forEach,
  isArray,
  isNil,
  isString,
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "FaqDetails",
  components: {
    ContextPanel,
    FaqDetailsFilterBox,
    FaqDetailsItem,
    PuxPageDetails,
    PuxTranslate,
  },
  mixins: [
    FaqKeywordsFilterMixin,
    HttpMixin,
    SyConfigMixin
  ],
  data() {
    return {
      loading: true,
      reloading: false,
      modelKeywords: [],
      modelMediaTypes: [],
      modelRubric: [],
      modelSearch: undefined,
      keywords: [],
      keywordsMapByPk: {},
      optionsAsideBox: {
        name: "_TXT_FAQ_DETAILS_ASIDE_FILTER_HEADER_",
        active: true,
        icon: "glyphicon-filter",
      },
      mediaTypes: [],
      mediaTypesMapByPk: {},
      faqRubricsAll: [],
      faqRubricsWithSearch: undefined,
      rubricsMapByPk: {},
    };
  },
  computed: {
    faqAnzeigeConfig() {
      return this.getSyConfigsValue("faq_anzeige", {});
    },

    optionsForPanels() {
      const OPTIONS_FOR_PANELS = [];
      forEach(this.faqRubricsFiltered, rubric => {
        const RUBRIC_LOCAL = cloneDeep(rubric);
        RUBRIC_LOCAL.label = rubric.fqr_kbez;
        RUBRIC_LOCAL.icon = "glyphicon-kommentar";
        OPTIONS_FOR_PANELS.push(RUBRIC_LOCAL);
      });
      return OPTIONS_FOR_PANELS;
    },

    faqRubricsFiltered() {
      const FAQ_RUBRICS = [];
      forEach(cloneDeep(this.faqRubrics), rubric => {
        let isRubricVisible = false;
        let countFilteredEintraege = 0;
        forEach(rubric.faq_eintraege, item => {
          if (this.isFaqItemVisible(item)) {
            isRubricVisible = true;
            item.show = true;
            countFilteredEintraege++;
          } else {
            item.show = false;
          }
        });
        rubric.show = isRubricVisible;
        rubric.count = countFilteredEintraege;
        rubric.eintragOptions = {
          collapse: this.faqAnzeigeConfig.faqeintrag_collapsable,
          collapsedDefault: this.isFaqItemInitiallyCollapsed(rubric.count)
        };
        FAQ_RUBRICS.push(rubric);
      });
      return FAQ_RUBRICS;
    },

    faqRubrics() {
      if (this.faqRubricsWithSearch && this.faqRubricsWithSearch.length) {
        return this.faqRubricsWithSearch;
      }
      return this.faqRubricsAll;
    },

    keywordsFiltered() {
      return filter(this.keywords, keyword => this.modelKeywords.indexOf(keyword.pk) === -1);
    },

    filterActive() {
      return this.modelKeywords.length ||
        this.modelMediaTypes.length ||
        this.modelRubric.length ||
        this.modelSearch;
    },
  },
  created() {
    this.initModelFromUrl();
    this.loadData();
  },
  methods: {
    initModelFromUrl() {
      this.initModelArrayFromUrl({
        urlKey: "keyword",
        modelKey: "modelKeywords",
      });
      this.initModelArrayFromUrl({
        urlKey: "media_type",
        modelKey: "modelMediaTypes",
      });
      this.initModelArrayFromUrl({
        urlKey: "rubric",
        modelKey: "modelRubric",
      });
      this.modelSearch = this.$stateParams.search;
    },

    initModelArrayFromUrl({ urlKey, modelKey }) {
      if (this.$stateParams && this.$stateParams[urlKey]) {
        if (isArray(this.$stateParams[urlKey])) {
          this[modelKey] = cloneDeep(this.$stateParams[urlKey]);
        } else if (isString(this.$stateParams[urlKey])) {
          this[modelKey].push(this.$stateParams[urlKey]);
        }
      }
    },


    loadData() {
      const REQUESTS = [
        this.getListHttp({
          url: `schlagworte/`,
          urlParams: {
            for_model: "faq.faqeintrag",
            verwendet: true,
          },
        }),
        this.getListHttp({
          url: "faqrubrikliste/",
        }),
        this.getListHttp({
          url: "katalog/",
          urlParams: {
            key: "medientyp",
          },
          apiSaveId: "medientyp",
        }),
      ];
      if (this.modelSearch) {
        REQUESTS.push(
          this.getListHttp({
            url: "faqrubrikliste/",
            urlParams: {
              unscharfe_suche: this.modelSearch,
            },
          })
        );
      }
      Promise.all(REQUESTS).then(
        responses => {
          this.keywords = responses[0];
          this.keywordsMapByPk = keyBy(this.keywords, "pk");
          this.faqRubricsAll = responses[1];
          this.rubricsMapByPk = keyBy(this.faqRubricsAll, "pk");
          this.mediaTypes = responses[2];
          this.mediaTypesMapByPk = keyBy(responses[2], "pk");
          if (this.modelSearch) {
            this.faqRubricsWithSearch = responses[3];
          }
        }
      ).then(
        () => this.loading = false
      );
    },

    isFaqItemVisible(faqItem) {
      return this.hasFaqItemKeywords(faqItem) &&
        this.hasFaqItemMediaTypes(faqItem) &&
        this.isFaqItemInRubric(faqItem);
    },

    isFaqItemInitiallyCollapsed(countFaqItems) {
      const MIN = this.faqAnzeigeConfig.faqeintrag_collapsed_min;
      return this.faqAnzeigeConfig.faqeintrag_collapsable && !isNil(MIN) && countFaqItems >= MIN;
    },

    hasFaqItemMediaTypes(faqItem) {
      if (!this.modelMediaTypes.length) {
        return true;
      }
      if (!faqItem.typen_ids.length) {
        return false;
      }
      let hasMediaTypesStatus = false;
      forEach(this.modelMediaTypes, mediaType => {
        if (faqItem.typen_ids.indexOf(mediaType) !== -1) {
          hasMediaTypesStatus = true;
          return false;
        }
      });
      return hasMediaTypesStatus;
    },

    isFaqItemInRubric(faqItem) {
      if (!this.modelRubric.length) {
        return true;
      }
      return this.modelRubric.indexOf(faqItem.rubrik) !== -1;
    },

    changeFilter({ filterKey, model, action }) {
      if (filterKey === "all") {
        this.resetAllModel();
      } else if (filterKey === "keywords") {
        this.changeKeywordsModel({ model, action });
      } else if (filterKey === "mediaTypes") {
        this.changeMediaTypesModel({ model, action });
      } else if (filterKey === "search") {
        this.changeSearchModel({ model });
      } else if (filterKey === "rubric") {
        this.changeRubricModel({ model, action });
      }
    },

    resetAllModel() {
      this.modelKeywords = [];
      this.modelMediaTypes = [];
      this.modelRubric = [];
      this.modelSearch = undefined;
      this.onFuzzySearch();
    },

    changeKeywordsModel({ model, action }) {
      if (action === "add") {
        this.modelKeywords.push(model);
      } else {
        this.modelKeywords.splice(this.modelKeywords.indexOf(model), 1);
      }
    },

    changeMediaTypesModel({ model, action }) {
      if (action === "add") {
        this.modelMediaTypes.push(model);
      } else {
        this.modelMediaTypes.splice(this.modelMediaTypes.indexOf(model), 1);
      }
    },

    changeSearchModel({ model }) {
      this.modelSearch = model;
    },

    changeRubricModel({ model, action }) {
      if (action === "add") {
        this.modelRubric.push(model);
      } else {
        this.modelRubric.splice(this.modelRubric.indexOf(model), 1);
      }
    },

    onFuzzySearch() {
      this.reloadRubrikData();
    },

    reloadRubrikData() {
      if (!this.modelSearch) {
        this.faqRubricsWithSearch = undefined;
        return;
      }
      this.reloading = true;
      this.getListHttp({
        url: "faqrubrikliste/",
        urlParams: {
          unscharfe_suche: this.modelSearch,
        },
      }).then(
        response => {
          this.faqRubricsWithSearch = response;
        }
      ).then(
        () => this.reloading = false
      );
    },
  },
};
