import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import SmartTable from "../../../../../global/components/table/table.vue";

import PersonalPanelComponentMixin from "../PersonalPanelComponentMixin";
import { FilterDateMixin } from "../../../../../global/mixins/FiltersMixin";

import {
  assign,
  cloneDeep,
  get,
  map
} from "lodash";

// @vue/component
export default {
  name: "Gueltigkeiten",
  components: {
    PuxIcon,
    SmartTable,
  },
  mixins: [
    FilterDateMixin,
    PersonalPanelComponentMixin,
  ],
  computed: {
    options() {
      return assign({}, this.optionsMixin, {
        label: "_LBL_PERSONAL_DETAIL_GUELTIGKEITEN_PANEL_",
        editable: false,
      });
    },
    gueltigkeiten() {
      return map(get(this.model, "gueltigkeiten", []), pg => {
        const PG = cloneDeep(pg);
        if (pg.is_active) {
          PG.icon = "glyphicon-ok";
          PG.iconClass = "success";
          PG.iconTooltip = "_TXT_JA_";
        } else {
          PG.icon = "glyphicon-close";
          PG.iconClass = "danger";
          PG.iconTooltip = "_TXT_NEIN_";
        }
        return PG;
      });
    },
    gueltigkeitenTableOptions() {
      return {
        id: "personal_detail_allgemein_gueltigkeiten",
        data: this.gueltigkeiten,
        cols: [
          {
            label: "_LBL_PERSONAL_GUELTIGKEIT_AKTIV_",
            id: "is_aktiv",
            className: "table_smart__table__col_icon_50px",
            slot: "icon",
          },
          {
            label: "_LBL_PERSONAL_GUELTIGKEIT_AB_",
            id: "pg_gueltig_ab",
            filter: "date",
            titleCallback: ({ item }) => this.filterDate(item.pg_gueltig_ab, "DD.MM.YY HH:mm [Uhr]")
          },
          {
            label: "_LBL_PERSONAL_GUELTIGKEIT_BIS_",
            id: "pg_gueltig_bis",
            filter: "date",
            titleCallback: ({ item }) => this.filterDate(item.pg_gueltig_bis, "DD.MM.YY HH:mm [Uhr]")
          },
        ],
        label: "_LBL_PERSONAL_DETAIL_GUELTIGKEITEN_TABLE_",
        sortable: false,
        pagination: false,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
        csvHide: true,
      };
    },
  },
};
