// Stellt einen Service bereit, um programmatisch einen Bestätigungsdialog
// zu erzeugen.

"use strict";

import template from "../confirm.jade";
import controller from "../confirm.controller";

class ConfirmFactory {
  /*@ngInject*/
  $get($rootScope, $q, $modal) {
    return params => {
      const scope = $rootScope.$new();
      scope.context = params.context;
      scope.confirmTitle = params.title;
      scope.confirmTitleTemplate = params.titleTemplate;
      scope.confirmMsg = params.content;
      scope.confirmContentTemplate = params.contentTemplate;
      scope.confirmType = params.type;
      scope.confirmOk = params.okCallback;
      scope.confirmOkLabel = params.okLabel;
      scope.confirmOkIcon = params.okIcon;
      scope.confirmCancel = params.cancelCallback;
      scope.confirmCancelLabel = params.cancelLabel;
      scope.responseScope = scope;
      const modalInstance = $modal({
        scope: scope,
        template: template(),
        controller: controller,
        controllerAs: "vm",
        bindToController: true,
      });
      const modalDefer = $q.defer();
      scope.modalInstance = modalInstance;
      scope.$on("modal.hide.before", () => {
        modalDefer.resolve(scope.response);
      });
      return modalDefer.promise;
    };
  }
}


export default ConfirmFactory;
