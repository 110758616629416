"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller";

class RollenverwaltungFGruppenController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, Funktionsgruppen) {
    super($q, $scope, DiasNotification);
    this.loadingEditData = true;
    this.formErrors = null;
    this.fgruppenChoices = [];
    this.fgruppenSelection = {};
    Funktionsgruppen.getList().then(
      response => {
        this.fgruppenChoices = response;
        this.groups = this.fgruppenChoices.splitIntoGroups(2);
      }
    ).finally(() => this.loadingEditData = false);

    angular.forEach(this.model, fg => {
      this.fgruppenSelection[fg.key] = true;
    });

    this.save = () => {
      const requestData = [];
      Object.keys(this.fgruppenSelection).map(key => {
        if (this.fgruppenSelection[key] === true) {
          requestData.push(key);
        }
      });
      return $scope.model.customPUT({ funktionsgruppen: requestData }).then(
        () => {
          $scope.model.splice(0, $scope.model.length);
          for (let i = 0; i < requestData.length; i++) {
            $scope.model.push(this.fgruppenChoices.getByKey(requestData[i]));
          }
          $scope.model.gruppierung = $scope.model.splitIntoGroups(2);
          DiasNotification.page.success("Änderung der Sichtbarkeit erfolgreich gespeichert.");
        },
        this.onError
      );
    };
  }
}


export default RollenverwaltungFGruppenController;
