"use strict";

class DiasErrorsController {
  /*@ngInject*/
  constructor($title, $message, $stateParams) {
    this.$title = $title;
    this.$message = $message;
    this.$sentryId = $stateParams.sentryId;
  }
}

export default DiasErrorsController;
