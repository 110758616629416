"use strict";

import BaseModel from "dias/core/models/baseModel";

class Workflowfolge extends BaseModel {
  constructor() {
    super("workflowfolgen");
  }

  extendModel(model) {
    super.extendModel(model);
    model.getFunktionen = () => model.customGET("funktionen");
    model.create_copy = (newWfoKbez, newKfoKbezAdmin) => model.customPOST({ wfo_kbez: newWfoKbez, wfo_kbez_admin: newKfoKbezAdmin }, "kopieren");
    return model;
  }
}

export default Workflowfolge;
