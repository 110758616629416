import FormElementReadOnly from "../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

// @vue/component
export default {
  name: "BelegButtonVersionsvergleichBetragsrechnerTableRow",
  components: {
    FormElementReadOnly,
    PuxTranslate,
    SnapshotIcon,
  },
  props: {
    modelErfasst: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: true,
      default: undefined,
    },
    modelAnerkannt: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: true,
      default: undefined,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isDiff() {
      return this.modelAnerkannt !== this.modelErfasst;
    },

    classDiff() {
      return this.isDiff ? "version-bg" : "";
    },
  }
};
