"use strict";

import template from "./wizard.jade";

class WizardController {
  /*@ngInject*/
  constructor(i18n) {
    this.gettext = i18n.gettext;
  }
}

export default {
  template: template(),
  controller: WizardController,
  controllerAs: "vm",
  bindings: {
    data: "<",
    wizardController: "=?",
    closeWizard: "&?",
    extra: "=?",
  }
};
