// @vue/component
export default {
  name: "FoerderfinderCategoriesButton",
  props: {
    buttonStyles: {
      type: Object,
      required: true,
      validator: buttonStyles => {
        if ("kbez" in buttonStyles &&
          "bez" in buttonStyles &&
          "parent" in buttonStyles) {
          return true;
        }
      },
      info: "Styles für 'kbez', 'bez' und 'parent'",
    },
    category: {
      type: Object,
      required: true,
    },
    isVisibleKbez: {
      type: Boolean,
      required: true,
      info: "Anzeigen 'kbez'?",
    },
    isVisibleBez: {
      type: Boolean,
      required: true,
      info: "Anzeigen 'bez'?",
    },
    isPictureVisibleByDefault: {
      type: Boolean,
      required: true,
      info: "Standardmäßig Bild anzeigen.",
    },
    statusButtonActive: {
      type: Boolean,
      required: false,
    },
    word: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "checkButton",
  ],
  data() {
    return {
      statusButtonHover: undefined,
    };
  },
  computed: {
    buttonClass() {
      return {
        inverted: !this.isPictureVisibleByDefault,
        active: this.statusButtonActive,
        hover: this.statusButtonHover,
        "button-svg-img": this.word.image || this.word.svg,
        "no-image": !this.word.image && !this.word.svg,
      };
    },

    ariaChecked() {
      return this.statusButtonActive ? "true" : "false";
    },

    role() {
      return this.category.multivalue ? "checkbox" : "radio";
    },

    containerImgStyles() {
      return {
        "background-image": this.statusButtonActive ?
          `url(/api/assets/${ this.word.image }-active.png)` :
          `url(/api/assets/${ this.word.image }.png)`,
      };
    },

    statusShowText() {
      if (!this.word.svg && !this.word.image) {
        return true;
      }
      let statusButtonHover = this.statusButtonHover;
      if (!this.isPictureVisibleByDefault) {
        statusButtonHover = !statusButtonHover;
      }
      return statusButtonHover;
    },
  },
  methods: {
    hoverButton(statusButtonHover) {
      this.statusButtonHover = statusButtonHover;
    },

    onClick() {
      this.$emit("checkButton", {
        category: this.category,
        wordPk: this.word.pk,
      });
    },
  },
};
