import Alert from "../../../../global/components/Alert/Alert.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import translate from "../../../../global/directives/translate";

import {
  toFormElementFromParameter
} from "../../../../global/functions/mappingForParameterToFormElement";

import {
  find,
  get,
  isNil,
  map,
  pick,
  size,
} from "lodash-es";


// @vue/component
export default {
  name: "TemplateAdminKonfiguration",
  components: {
    Alert,
    FormstepDetail,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "updateTemplate",
  ],
  computed: {
    canEdit() {
      return this.hasConfig && this.checkPermissionsSync({ perm: "templateadmin.update" });
    },

    options() {
      const OPTIONS = {
        label: "_TXT_TEMPLATE_DETAILS_KONFIGURATION_PANEL_FORMSTEP_NAME_",
        info: "_HTML_TEMPLATE_DETAILS_KONFIGURATION_PANEL_FORMSTEP_INFO_",
        list: toFormElementFromParameter({ obj: this.template.config_in || {}, showLabel: true }).list,
        saveCallback: this.save,
      };
      OPTIONS.required = !isNil(find(OPTIONS.list, el => get(el, "required") || get(el, "options.required")));
      return OPTIONS;
    },

    hasConfig() {
      return size(this.options.list) > 0;
    },

    translationNoConfig() {
      return {
        text: "_MSG_TEMPLATE_DETAILS_KONFIGURATION_NO_CONFIG_INFO_"
      };
    },
  },
  methods: {
    save({ model }) {
      const DATA = {
        tpl_parameterin: pick(model, map(this.options.list, option => option.id))
      };
      return this.putHttp({
        url: `templates/${ this.template.pk }/template_konfig_aendern/`,
        data: DATA,
      }).then(
        response => {
          this.$emit("updateTemplate", { template: response });
          this.addNotification({
            text: "_MSG_TEMPLATE_DETAILS_KONFIGURATION_UPDATE_SUCCESS_{{bez}}_",
            extra: {
              bez: this.template.tpl_kbez,
            },
          });
        },
        errors => {
          const CONF_ERROR = {
            data: get(errors, "data.tpl_parameterin", get(errors, "data", errors))
          };
          return Promise.reject(CONF_ERROR);
        }
      );
    },
  },
};
