"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";


const sitzungenModule = angular.module("root.personal", [
  uiRouter,
]);

sitzungenModule.config($stateProvider => {
  $stateProvider

    // Sitzungs-Liste
    .state("root.personal", {
      url: `/personal/?
            name&
            org&
            aktiv&
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><personal-list></personal-list></div>"
        }
      },
      data: {
        permissions: ["personal.ui"]
      },
    })

    // Detail-Seite einer Sitzung
    .state("root.personal.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><personal-details></personal-details></div>"
        }
      },
    })
  ;
})

;

export default sitzungenModule;
