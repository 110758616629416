"use strict";

import angular from "angular";
import "restangular";

import BetragsrechnerRegionaleEffekte from "./regionale_effekte";
import BetragsrehnerKostenMitBegruendung from "./kosten_mit_begruendung";
import BetragsrechnerRegionaleEffekteMitBegruendung from "./regionale_effekte_mit_begruendung";

const kostenFinanzierungBetragsrechnerModule = angular.module("dias.mdm_kosten_finanzierung.betragsrechner", [
  BetragsrechnerRegionaleEffekte.name,
  BetragsrehnerKostenMitBegruendung.name,
  BetragsrechnerRegionaleEffekteMitBegruendung.name,
]);

export default kostenFinanzierungBetragsrechnerModule;
