import {
  getCurrentInstance,
  toRef,
} from "vue";

import SitzungsantraegeListInTageordnungspunktMittellos from "../../../../Sitzungsantraege/SitzungsantraegeListInTageordnungspunktMittellos/SitzungsantraegeListInTageordnungspunktMittellos.vue";

import SitzungModulePropsMixin from "../SitzungModulePropsMixin";

import SitzungModuleAPI from "../compositionAPI/SitzungModuleAPI";

// @vue/component
export default {
  name: "ModuleAutomatischeAntragslisteMittellosMain",
  components: {
    SitzungsantraegeListInTageordnungspunktMittellos,
  },
  mixins: [
    SitzungModulePropsMixin,
  ],
  setup(props, context) {
    const {
      htmlId,
      model,
      modulname,
      modelParameter,
      sendSitzungModuleEvent,
    } = SitzungModuleAPI(props, context);

    const APP = getCurrentInstance();
    const $stateParams = APP.appContext.config.globalProperties.$stateParams;
    const sitzungPk = $stateParams.id;

    const module = toRef(props, "module");
    const emitEventLocal = () => {
      sendSitzungModuleEvent({
        src: module.value.modul.pk,
        groups: module.value.modulgruppen,
        action: "update",
      });
    };

    return {
      htmlId,
      model,
      modulname,
      modelParameter,

      sitzungPk,
      emitEventLocal,
    };
  },
};
