"use strict";

import "../models";

const maxResultLength = 10;

class DeckblattSearchObjectController {
  /*@ngInject*/
  constructor(
    $q, 
    $scope, 
    Restangular, 
    Antragstellerorganisationen,
    Foerderantrag, 
    Foerderangebote, 
    $state, 
    DiasNotification,
    BtypUUIDs, 
    Teams
  ) {
    this.DiasNotification = DiasNotification;
    this.$q = $q;
    this.$scope = $scope;
    this.maxResultLength = maxResultLength;
    this.BtypUUIDs = BtypUUIDs;
    this.Antragstellerorganisationen = Antragstellerorganisationen;
    this.Foerderantrag = Foerderantrag;
    this.Foerderangebote = Foerderangebote;
    this.Teams = Teams;
    this.searchValues = {};
    this.deckblatt = $scope.extras.deckblatt;

    this.btyp = $scope.extras.deckblatt.btyp;
    this.aktennummer = null;
    this.panelShow = false;
    this.selected = { btyp: this.btyp,
                      aktennummer: null };
    this.searches = {};
    this.searchResults = {};
    this.angebote = [];
    this.selectedEl = null;
    this.Foerderangebote.one("alle").customGET("").then(response => {
      this.angebote = response;
    });
    this.teams = [];
    this.Teams.one("alle").customGET("").then(response => {
      this.teams = response;
    });
    this.searches[this.BtypUUIDs.ANTRAGSTELLER] = {
      search: "suche_alle",
      model: this.Antragstellerorganisationen };
    this.searches[this.BtypUUIDs.ANTRAG] = { model: this.Foerderantrag,
                                             search: "suche" };
    if (this.btyp === this.BtypUUIDs.ANTRAG) {
      $scope.setTitle("Antragsakte suchen");
    } else {
      $scope.setTitle("Antragstellerakte suchen");
    }
    this.searched = false;
    this.setChoice = () => {
      this.$scope.extras.deckblatt.btyp = this.btyp;
      this.$scope.extras.selectedEl = this.selectedEl;
      if (this.btyp === this.BtypUUIDs.ANTRAG) {
        this.$scope.extras.deckblatt.aktennummer = this.selectedEl.a_nr;
      } else {
        this.$scope.extras.deckblatt.aktennummer = this.selectedEl.ast_dmsakte;
      }
    };

    this.noInput = function() {
      return false;
    };

    this.searchChange = function() {
      this.validateAktennummer();
      this.searchPossible = !this.noInput();
      if (this.searchPossible) {
        this.searched = true;
        this.searching = true;
        this.selectedEl = null;
        this.searches[this.btyp].model.one(this.searches[this.btyp].search).customGET("", this.searchValues).then(
          result => {
            this.searchResults = result;
          }
        ).finally(() => this.searching = false);
      }
    };

    this.tooManyResults = function() {
      return (!this.noInput() && this.searchResults.count > maxResultLength);
    };

    this.noResults = function() {
      return (!this.noInput() && this.searchResults.count === 0);
    };

    this.displayResults = function() {
      return !(this.noResults() || this.tooManyResults() || this.noInput());
    };

    this.selectAktiveModel = function() {
      if (this.deckblatt.btyp === this.BtypUUIDs.ANTRAG) {
        this.searchValues.a_nr = this.deckblatt.aktennummer;
      } else {
        this.searchValues.ast_dmsakte = this.deckblatt.aktennummer;
      }
      if (this.deckblatt.aktennummer) {
        this.searchChange();
      }
    };

    this.selectAktiveModel();
  }

  togglePanel() {
    this.panelShow = !this.panelShow;
  }

  validateAktennummer() {
    if (this.searchValues) {
      if (this.searchValues.a_nr) {
        this.searchValues.a_nr = this.searchValues.a_nr.replace(/\s+/g, "");
      }
      if (this.searchValues.ast_dmsakte) {
        this.searchValues.ast_dmsakte = this.searchValues.ast_dmsakte.replace(/\s+/g, "");
      }
      if (this.searchValues.v_nr) {
        this.searchValues.v_nr = this.searchValues.v_nr.replace(/\s+/g, "");
      }
    }
  }
}

export default DeckblattSearchObjectController;
