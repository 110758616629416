import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spaced from "../../../../../global/components/Spaced/Spaced.vue";

import {
  FilterDateMixin,
} from "../../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "VertragOverviewAntrag",
  components: {
    AngularLink,
    PuxIcon,
    PuxTranslate,
    Spaced,
  },
  mixins: [
    FilterDateMixin,
  ],
  props: {
    vertrag: {
      type: Object,
      required: true,
    },
  },
  computed: {
    extraAntragBewilligungsDatumForTranslate() {
      return {
        date: this.filterDate(this.vertrag.antrag_bewilligungsdatum),
      };
    },

    extraAntragDurchfuehrungszeitraumForTranslate() {
      return {
        antrag_durchfuehrungszeitraum: this.vertrag.antrag_durchfuehrungszeitraum || "",
      };
    },

    extraVertragDurchfuehrungszeitraumForTranslate() {
      return {
        vertrag_durchfuehrungszeitraum: this.vertrag.durchfuehrungszeitraum || "",
      };
    },

    extraAntragEingereichtDatumForTranslate() {
      return {
        date: this.filterDate(this.vertrag.antrag_datum),
      };
    },
  },
};
