import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "InstrumentModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "foi_kbez",
            type: "text",
            label: "_LBL_INSTRUMENT_KURZBEZEICHNUNG_",
            required: true,
            maxlength: 60,
            view: "v",
          },
          {
            id: "foi_bez",
            type: "textarea",
            label: "_LBL_INSTRUMENT_BEZEICHNUNG_",
            required: true,
            maxlength: 200,
            view: "v-alt",
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  methods: {
    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.postHttp({ url: `foerderinstrumente/`, data }).then(
        response => {
          this.addNotification({ text: "_MSG_INSTRUMENT_MODAL_CREATE_SUCCESS_" });
          this.close();
          this.$goTo("root.foerderinstrumente.detail", { id: response.pk });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
