"use strict";

import BaseController from "dias/foerderregeln/parameter/config.base.controller";

const KEY_NEW = "_new";

class Controller extends BaseController {
  /*@ngInject*/
  constructor(DiasNotification, ParameterUtils, i18n) {
    super();
    this.DiasNotification = DiasNotification;
    this.ParameterUtils = ParameterUtils;
    this.gettext = i18n.gettext;
    this.formTemplate = {};
    this.formModels = {};
    this.editModes = {};
    this.showDetails = {};
    this.KEY_NEW = KEY_NEW;
    this.readonly = false;
    this.defaultModel = {
      label: undefined,
      id: undefined,
      regeln: [],
      rechtsformen: []
    };
    this.errors = {};
    this.initFormTemplate();
    this.initData();
  }

  initData() {
    if (this.parameter[this.key].wert === undefined || this.parameter[this.key].wert === null) {
      this.parameter[this.key].wert = [];
    }
    this.initForm(this.KEY_NEW);
    _.forEach(this.parameter[this.key].wert, (el, idx) => this.initForm(idx));
  }

  initFormTemplate() {
    this.formTemplate = this.ParameterUtils.init_parameter_in({
      label: {
        bez: this.gettext("_LBL_PARAM_RECHTSFORM_ZUSATZ_FIELD_LABEL_"),
        typ: "string",
        prio: 1,
        options: {
          required: false
        },
      },
      id: {
        bez: this.gettext("_LBL_PARAM_RECHTSFORM_ZUSATZ_FIELD_ID_"),
        typ: "string",
        prio: 2,
        options: {
          required: true
        }
      },
      rechtsformen: {
        bez: this.gettext("_LBL_PARAM_RECHTSFORM_ZUSATZ_FIELD_RECHTSFORMEN_"),
        typ: "multi_select",
        prio: 4,
        katalog: "krechtsform",
        options: {
          required: true,
          displaymode: "select2"
        }
      },
      regeln: {
        bez: this.gettext("_LBL_PARAM_RECHTSFORM_ZUSATZ_FIELD_REGELN_"),
        typ: "multi_select",
        prio: 5,
        katalog: "elementfoerderregel",
        options: {
          required: true,
          displaymode: "ordered"
        }
      },
    });
    this.form_keys = this.ParameterUtils.get_ordered_keys(this.formTemplate);
    this.errorLabels = {};
    this.form_keys.forEach(
      k => {
        this.errorLabels[k] = `${ this.formTemplate[k].bez }: `;
      }
    );
  }

  initForm(key) {
    this.formModels[key] = _.cloneDeep(this.formTemplate);
    const el = this.parameter[this.key].wert[key] || this.defaultModel;
    this.formModels[key].label.wert = el.label || "";
    this.formModels[key].label.controlId = `${ key }_label`;
    this.formModels[key].id.wert = el.id || "";
    this.formModels[key].id.controlId = `${ key }_id`;
    this.formModels[key].rechtsformen.wert = el.rechtsformen || [];
    this.formModels[key].rechtsformen.controlId = `${ key }_rechtsformen`;
    this.formModels[key].regeln.wert = el.regeln || [];
    this.formModels[key].regeln.controlId = `${ key }_regeln`;
  }

  abortEditAntwort(key) {
    this.editModes[key] = false;
  }

  editAntwort(key) {
    this.editModes[key] = true;
    this.initForm(key);
  }

  toggleDetails(key, $event) {
    this.showDetails[key] = !this.showDetails[key]; $event.stopPropagation(); $event.preventDefault();
    this.initForm(key);
  }

  removeAntwort(index) {
    this.parameter[this.key].wert.splice(index, 1);
  }

  moveUpAntwort(index) {
    if (index === 0) {
      return;
    }
    const elem = this.parameter[this.key].wert[index];
    const previous = this.parameter[this.key].wert[index - 1];
    this.parameter[this.key].wert[index - 1] = elem;
    this.parameter[this.key].wert[index] = previous;
    const elem_detail = this.showDetails[index];
    const elem_bearbeiten = this.editModes[index];
    const elem_form = this.formModels[index];
    const prev_detail = this.showDetails[index - 1];
    const prev_bearbeiten = this.editModes[index - 1];
    const prev_form = this.formModels[index - 1];
    this.showDetails[index] = prev_detail;
    this.editModes[index] = prev_bearbeiten;
    this.formModels[index] = prev_form;
    this.showDetails[index - 1] = elem_detail;
    this.editModes[index - 1] = elem_bearbeiten;
    this.formModels[index - 1] = elem_form;
  }

  moveDownAntwort(index) {
    if (index >= this.parameter[this.key].wert.length - 1) {
      return;
    }
    const elem = this.parameter[this.key].wert[index];
    const next = this.parameter[this.key].wert[index + 1];
    this.parameter[this.key].wert[index + 1] = elem;
    this.parameter[this.key].wert[index] = next;
    const elem_detail = this.showDetails[index];
    const elem_bearbeiten = this.editModes[index];
    const elem_form = this.formModels[index];
    const next_detail = this.showDetails[index + 1];
    const next_bearbeiten = this.editModes[index + 1];
    const next_form = this.formModels[index + 1];
    this.showDetails[index] = next_detail;
    this.editModes[index] = next_bearbeiten;
    this.formModels[index] = next_form;
    this.showDetails[index + 1] = elem_detail;
    this.editModes[index + 1] = elem_bearbeiten;
    this.formModels[index + 1] = elem_form;
  }

  addChildDisabled(index) {
    const selection = this.regelAddSelections[index];
    return (selection.length === 0);
  }

  onUpdateLabel(key, field) {
    if (field === "label") {
      this.formModels[key].id.options.placeholder = _.lowerCase(this.formModels[key].label.wert).replace(/[^a-zA-Z0-9]/g, "");
    } else if (field === "id") {
      this.formModels[key].id.options.placeholder = _.lowerCase(this.formModels[key].label.wert).replace(/[^a-zA-Z0-9]/g, "");
      this.formModels[key].id.wert = _.lowerCase(this.formModels[key].id.wert).replace(/[^a-zA-Z0-9]/g, "");
    }
  }

  setAntwort(key) {
    const el = {
      label: this.formModels[key].label.wert,
      id: _.lowerCase(this.formModels[key].id.wert || this.formModels[key].label.wert).replace(/[^a-zA-Z0-9]/g, ""),
      rechtsformen: this.formModels[key].rechtsformen.wert,
      regeln: this.formModels[key].regeln.wert,
    };
    const unique = this.checkUnique(this.parameter[this.key].wert, key, el);

    if (unique && key === KEY_NEW) {
      this.parameter[this.key].wert.push(el);
      this.initForm(KEY_NEW);
      this.initForm(this.parameter[this.key].wert.length - 1);
    } else if (unique) {
      this.parameter[this.key].wert[key] = el;
      this.editModes[key] = false;
      this.initForm(key);
    }
  }

  checkUnique(list, key, el) {
    const alreadyIn = list.find((v, idx) => v.id === el.id && idx !== key);
    if (alreadyIn) {
      this.DiasNotification.page.error(
        this.gettext("_MSG_PARAM_RECHTSFORM_ZUSATZ_ID_ALREADY_USED_{{ID}}_").replace("{{ID}}", el.id)
      );
      return false;
    }
    return true;
  }
}

export default Controller;
