import FormElement from "../../../../../../global/components/FormElement/FormElement.vue";
import PuxCloak from "../../../../../../global/components/PuxCloak/PuxCloak.vue";

import HttpMixin from "../../../../../../global/mixins/HttpMixin";

import OptionList from "./OptionList";
import {
  cloneDeep,
  forEach,
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "BerichtFoerderantragAsideFilters",
  components: {
    FormElement,
    PuxCloak,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    disabled: {
      type: Boolean,
    },
    updateAntraege: {
      type: Function,
      required: true,
    },
    defaultModel: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      optionList: [],
      model: {
        a_bundesland: [],
        a_antragsdatum_after: "",
        a_antragsdatum_before: "",
        a_bewilligungsdatum_after: "",
        a_bewilligungsdatum_before: "",
      },
      foerderorgtypen: [],
      foerderorgtypenObj: {},
      loadingFoerderorgtypen: true,
    };
  },
  created() {
    this.loadFoerderorgtypen();
  },
  methods: {
    loadFoerderorgtypen() {
      this.getListHttp({
        url: "foerderorgtypen/",
        apiSaveId: "foerderorgtypen",
      }).then(
        response => {
          this.foerderorgtypen = response;
          this.setDefaultModel();
          this.setFoerderorgtypenObj();
          this.addFoerderorgtypenInOptionsList();
          this.change();
          this.loadingFoerderorgtypen = false;
        }
      );
    },

    setDefaultModel() {
      const MODEL = this.model;
      forEach(this.defaultModel, (model, key) => {
        MODEL[key] = model;
      });
      this.model = MODEL;
    },

    setFoerderorgtypenObj() {
      this.foerderorgtypenObj = keyBy(this.foerderorgtypen, "pk");
    },

    addFoerderorgtypenInOptionsList() {
      const OPTIONS_LIST = cloneDeep(OptionList);
      forEach(this.foerderorgtypen, type => {
        OPTIONS_LIST.unshift({
          id: `foerderorganisation_${ type.pk }`,
          label: type.ot_kbez,
          type: "select",
          url: `foerderorganisationen/?eigene=True&serializer=minimal&typ=${ type.pk }`,
          keyId: "pk",
          keyLabel: "o_name",
          search: true,
          searchList: ["o_name"],
          view: "v",
        });
      });
      this.optionList = OPTIONS_LIST;
    },

    change() {
      const MODEL = this.prepareModel();
      const LINK_PARAMETERS = this.prepareLinkParameters();
      this.updateAntraege({ model: MODEL, linkParameters: LINK_PARAMETERS });
    },

    prepareModel() {
      const MODEL = {
        foerderorganisation: [],
      };
      forEach(this.model, (item, key) => {
        if (key.indexOf("foerderorganisation") !== -1 && item) {
          MODEL.foerderorganisation.push(item);
        } else if (item !== "") {
          MODEL[key] = cloneDeep(item);
        }
      });
      return MODEL;
    },

    prepareLinkParameters() {
      const LINK_PARAMETERS = {
        eingereicht: true,
        show_gesperrte_ast: true
      };
      forEach(this.model, (item, key) => {
        if (key.indexOf("foerderorganisation") !== -1 && item) {
          const CURRENT_PK = key.replace("foerderorganisation_", "");
          const OID_KEY = `oid_${ this.foerderorgtypenObj[CURRENT_PK].ot_pos }`;
          if (LINK_PARAMETERS[OID_KEY]) {
            LINK_PARAMETERS[OID_KEY].push(item);
          } else {
            LINK_PARAMETERS[OID_KEY] = [item];
          }
        } else if (key === "a_bundesland" && item.length) {
          LINK_PARAMETERS.ast_bundesland = cloneDeep(item);
        } else if (item !== "") {
          LINK_PARAMETERS[key] = item;
        }
      });
      return LINK_PARAMETERS;
    },
  },
};
