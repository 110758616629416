import PuxButton from "../../global/components/PuxButton/PuxButton.vue";

import HttpAPI from "../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../global/compositionAPI/PermissionAPI";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "Favoriten",
  components: {
    PuxButton,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    baseUrl: {
      type: String,
      required: true,
    },
    rowIndex: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    "callback",
  ],
  setup() {
    const {
      deleteHttp,
      postHttp,
    } = HttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      checkPermissionsSync,
      deleteHttp,
      postHttp,
    };
  },
  data() {
    return {
      loading: undefined,
    };
  },
  computed: {
    titleTranslate() {
      return this.data.favorit ? "_TXT_FAVORITEN_AKTUELL_MARKIERT_TITLE_" : "_TXT_FAVORITEN_AKTUELL_NICHT_MARKIERT_TITLE_";
    },

    urlFavorisieren() {
      return `${ this.baseUrl }/${ this.data.pk }/favorisieren/`;
    },

    iconLocal() {
      return this.data.favorit ? "star" : "star-empty";
    },

    canToggle() {
      return this.checkPermissionsSync({
        perm: "favoriten.toggle",
        permArray: this.data.user_permissions,
      });
    },

    httpFunction() {
      return this.data.favorit ? "deleteHttp" : "postHttp";
    },
  },
  methods: {
    toggleFavorit() {
      if (this.loading || this.readOnly) {
        return;
      }
      this.loading = true;
      const dataTemp = cloneDeep(this.data);
      dataTemp.favorit = !dataTemp.favorit;
      this[this.httpFunction]({
        url: this.urlFavorisieren,
      }).then(
        () => {
          this.$emit("callback", {
            favorit: !this.data.favorit,
            rowIndex: this.rowIndex,
            data: dataTemp,
          });
          this.loading = false;
        }
      );
    },
  },
};
