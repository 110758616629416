import HttpMixin from "../../mixins/HttpMixin";
import PermissionMixin from "../../mixins/PermissionMixin";
import NotizenIcon from "../../../components/Notizen/NotizenIcon/NotizenIcon.vue";
import NotizenInline from "../../../components/Notizen/NotizenInline/NotizenInline.vue";
import Permission from "../Permission/Permission.vue";
import PuxButton from "../PuxButton/PuxButton.vue";
import Alert from "../Alert/Alert.vue";
import FormstepDetailRead from "./FormstepDetailRead/FormstepDetailRead.vue";
import FormstepDetailEdit from "./FormstepDetailEdit/FormstepDetailEdit.vue";
import PuxCloak from "../PuxCloak/PuxCloak.vue";
import translate from "../../directives/translate";
import loading from "../../directives/loading";
import PuxErrors from "../PuxErrors/PuxErrors.vue";
import PuxRequired from "../PuxRequired/PuxRequired.vue";
import PuxTranslate from "../PuxTranslate/PuxTranslate.vue";

import SyConfigMixin from "../../mixins/SyConfigMixin";

import {
  gettext,
  isTranslatePlaceholder,
} from "../../functions/utils";
import {
  scrollToGlobal,
} from "../../functions/help";
import {
  cloneDeep,
  filter,
  has,
  isFunction,
  isUndefined,
  size,
  uniqueId,
  get,
  isPlainObject,
} from "lodash-es";
import { isClickOnLink } from "../../functions/utilsDOM";

// @vue/component
export default {
  name: "FormstepDetail",
  components: {
    Alert,
    FormstepDetailRead,
    FormstepDetailEdit,
    PuxCloak,
    NotizenIcon,
    NotizenInline,
    Permission,
    PuxButton,
    PuxErrors,
    PuxRequired,
    PuxTranslate,
  },
  directives: {
    translate,
    loading,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  props: {
    htmlId: {
      type: String,
      default: undefined,
      // required: true,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    editable: {
      type: Boolean,
    },
    deletable: {
      type: Boolean,
    },
    editonly: {
      type: Boolean,
    },
    extra: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    labels: {
      type: Object,
      default: undefined,
    },
    model: {
      type: Object,
      default: undefined,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      required: false,
    },
    reloading: {
      type: Boolean,
      required: false,
    },
    notizenOptions: {
      type: Object,
      required: false,
      default: undefined,
    },
    statusOpenEditMode: {
      type: Boolean,
      default: true,
    },
    formAttributesHtml: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    tagHeader: {
      type: String,
      required: false,
      default: "h4",
      info: "HTML-Tag für Header '<h4>'",
      validator: tag => ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(tag) !== -1,
    },
    errors: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
    errorsLabelsOptions: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  emits: [
    "removeErrors",
  ],
  data() {
    return {
      statusHover: undefined,
      statusEdit: undefined,
      status: {
        loading: undefined,
        info: undefined,
        collapsed: false,
      },
      errorsLocal: undefined,
      errorsClone: undefined,
      modelEdit: undefined,
    };
  },
  computed: {
    errorsLocalAndProps() {
      return this.errorsLocal || this.errors;
    },

    labelTranslateOptions() {
      return {
        text: this.labelLocal,
        extra: this.extraLocal,
      };
    },

    labelLocal() {
      return this.label || this.options.label;
    },

    extraLocal() {
      return this.extra || this.options.extra;
    },

    htmlIdLocal() {
      return this.htmlId || this.htmlidLocalUnique;
    },

    htmlidLocalUnique() {
      return uniqueId("formstep_detail_");
    },

    classFormstepDetail() {
      return {
        formstep_detail_edit: this.statusEdit || this.editonly,
        formstep_detail_hover: this.statusHover,
        formstep_detail_collapse: this.options.collapse,
        formstep_detail_collapse_show: this.options.collapse && !this.status.collapsed,
      };
    },

    statusEditable() {
      let hasPerm = true;
      if (this.options.editablePermission) {
        hasPerm = this.checkPermissionsSync({ perm: this.options.editablePermission, permArray: this.options.permissionList });
      }
      return (this.editable || this.options.editable) && hasPerm;
    },

    statusDeletable() {
      let hasPerm = true;
      if (this.options.deletablePermission) {
        hasPerm = this.checkPermissionsSync({ perm: this.options.deletablePermission });
      }
      return (this.deletable || this.options.deletable) && hasPerm;
    },

    slotsList() {
      if (!this.options.list || !this.options.list.length) {
        return [];
      }
      return filter(this.options.list, item => item.slot);
    },

    slotsReadonlyList() {
      if (!this.options.list || !this.options.list.length) {
        return [];
      }
      return filter(this.options.list, item => item.slotReadonly);
    },

    titleInfoButton() {
      return this.status.info ? "_TXT_FORMSTEP_DETAILS_INFO_I_TITLE_AUS_" : "_TXT_FORMSTEP_DETAILS_INFO_I_TITLE_EIN_";
    },

    okLabel() {
      return this.options.okLabel || "_BTN_SAVE_";
    },

    cancelLabel() {
      return this.options.cancelLabel || "_BTN_CANCEL_";
    },

    classBodyChild() {
      if (this.options.classBodyChildReadonly && !this.statusEdit && !this.editonly) {
        return this.options.classBodyChildReadonly;
      }
      if (this.options.classBodyChildEditOnly && (this.statusEdit || this.editonly)) {
        return this.options.classBodyChildEditOnly;
      }
      return "";
    },

    notizenButtonId() {
      return `notizen_${ this.htmlIdLocal }`;
    },

    idForDeleteButton() {
      return `${ this.htmlIdLocal }_delete`;
    },

    idForInfoButton() {
      return `${ this.htmlIdLocal }_info`;
    },

    idForCloseButton() {
      return `${ this.htmlIdLocal }_close`;
    },

    idForCloseButtonUp() {
      return `${ this.htmlIdLocal }_close_up`;
    },

    idForEditButton() {
      return `${ this.htmlIdLocal }_edit`;
    },

    idForSaveButton() {
      return `${ this.htmlIdLocal }_save`;
    },

    cssSelectorForEditButton() {
      return `#${ this.idForEditButton }`;
    },

    headerId() {
      return `${ this.htmlIdLocal }_header`;
    },

    statusErrors() {
      return this.errorsLocalAndProps && size(this.errorsLocalAndProps);
    },

    iconCollapse() {
      return this.status.collapsed ? "glyphicon-chevron-down" : "glyphicon-chevron-up";
    },

    idForBody() {
      return `${ this.htmlIdLocal }_body`;
    },

    headerAttributes() {
      if (this.options.collapse) {
        return {
          role: "button",
          "aria-expanded": `${ !this.status.collapsed }`,
          "aria-controls": this.idForBody,
        };
      }
      return {};
    },

    headerEvents() {
      if (this.options.collapse) {
        return {
          click: this.toggleCollapse,
        };
      }
      return {};
    },

    statusNotCloseEditModeIfModelChange() {
      return get(this.options, "statusNotCloseEditModeIfModelChange", false);
    },

    statusLoadingLocal() {
      return this.status.loading || this.loading;
    },

    isNotizenInlineVisible() {
      return this.isNotizenInlineVisibleFromSyconfig && this.notizenOptions;
    },

    isNotizenInlineVisibleFromSyconfig() {
      return this.getSyConfigsValue("notizen", {}).show_inline_in_gr;
    },

    isOptionsInfo() {
      if (!this.options.info) {
        return false;
      }
      if (isTranslatePlaceholder(this.options.info)) {
        const INFOTEXT_TRANSLATE = gettext(this.options.info);
        return INFOTEXT_TRANSLATE && INFOTEXT_TRANSLATE !== this.options.info;
      }
      return true;
    },

    isEditModeVisible() {
      return !!(this.statusEdit || this.editonly);
    },
  },
  watch: {
    model() {
      if (!this.statusNotCloseEditModeIfModelChange && this.statusEdit) {
        this.closeEditMode();
      }
      if (!this.statusEdit) {
        this.modelEdit = cloneDeep(this.model);
      }
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.status.collapsed = this.options.collapsedDefault || false;
      this.status.info = this.options.infoDefault || false;
    },

    changeModelInChild({ model, id }) {
      if (id &&
        this.errorsClone &&
        isPlainObject(this.errorsClone) &&
        has(this.errorsClone, "id")) {
        delete this.errorsClone[id];
      }
      this.modelEdit = cloneDeep(model);
    },

    changeModel({ model }) {
      this.$refs.formstepDetailEdit.changeModel({ model });
    },

    mouseEnterToggle({ statusEnter, body }) {
      if (body && (!this.statusEditable || this.statusEdit)) {
        return;
      }
      this.statusHover = statusEnter;
    },

    onClickBody($event) {
      if (this.statusEdit || !this.statusEditable) {
        return false;
      }
      if (isClickOnLink($event)) { // Öffnen keine Details, wenn wir auf den Link geklickt haben
        return;
      }
      this.mouseEnterToggle({ statusEnter: false });
      if (this.statusOpenEditMode) {
        this.openEditMode();
      }
    },

    openEditMode() {
      if (this.statusEdit) {
        return;
      }
      let objCallback = undefined;
      if (isFunction(this.options.openCallback)) {
        objCallback = this.options.openCallback();
      }
      if (objCallback && objCallback.model) {
        this.modelEdit = cloneDeep(objCallback.model);
      } else {
        this.modelEdit = cloneDeep(this.model);
      }
      this.statusEdit = true;
      this.toggleStatusInfo({ status: true });
      this.setFocusToFormstepDetailBody();
    },

    setFocusToFormstepDetailBody() {
      if (this.$refs.body) {
        this.$refs.body.focus();
      }
    },

    closeEditMode({ statusCloseAfterSave } = {}) {
      if (isFunction(this.options.closeCallback)) {
        this.options.closeCallback({ statusCloseAfterSave });
      }
      this.setErrors();
      if (this.statusEdit) {
        this.toggleStatusInfo({ status: false });
      }
      this.statusEdit = false;
      this.setFocusToEditButton();
      this.scrollComponentToPageHeader();
    },

    setFocusToEditButton() {
      setTimeout(() => {
        $(this.cssSelectorForEditButton).focus();
      });
    },

    scrollComponentToPageHeader() {
      setTimeout(() => {
        scrollToGlobal(`#${ this.htmlIdLocal }`, 0, 300);
      });
    },

    save() {
      this.status.loading = true;
      if (isFunction(this.options.saveCallback)) {
        this.options.saveCallback({
          model: this.modelEdit,
          options: this.options,
        }).then(
          () => {
            if (!this.options.notCloseCallbackAfterSave) {
              this.closeEditMode({ statusCloseAfterSave: true });
            }
          },
          error => {
            if (!error || !error.data) {
              return;
            }
            this.setErrors({ errors: error.data });
          }
        ).then(
          () => this.status.loading = false
        );
      }
    },

    saveLocal() {
      console.log("saveLocal");
    },

    setErrors({ errors = undefined } = {}) {
      this.errorsLocal = errors;
      this.errorsClone = errors;
    },

    onDelete() {
      this.options.deleteCallback();
    },

    toggleStatusInfo({ status } = {}) {
      if (!isUndefined(status)) {
        this.status.info = status;
      } else {
        this.status.info = !this.status.info;
      }
    },

    toggleCollapse() {
      this.status.collapsed = !this.status.collapsed;
    },

    openCollapse() {
      this.status.collapsed = false;
    },

    removeErrors() {
      this.setErrors();
      this.$emit("removeErrors");
    },
  },
};
