import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import {
  toFormElementFromParameter,
  initModelFromList,
} from "../../../../global/functions/mappingForParameterToFormElement";

// @vue/component
export default {
  name: "FoerderangebotRegelConfigController",
  components: {
    AngularLink,
    FormElementReadOnly,
    Modal,
    Permission,
    PuxButton,
    PuxCloak,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    SyConfigMixin,
  ],
  props: {
    idx: {
      type: Number,
      required: true,
    },
    indexRegel: {
      type: Number,
      required: true,
    },
    regelsatzPk: {
      type: String,
      required: true,
    },
    regel: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    doDelete: {
      type: Function,
      required: true,
    },
    moveUp: {
      type: Function,
      required: true,
    },
    moveDown: {
      type: Function,
      required: true,
    },
    updateRegel: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      listConfig: undefined,
      modelConfig: undefined,
      dokConfig: undefined,
      showDetail: false,
      loading: {
        oeffentlich: false,
      },
      modal: {
        delete: false,
      },
    };
  },
  computed: {
    confirmDeleteOptions() {
      return {
        title: "_TXT_ANGEBOT_DETAILS_CONFIG_GESCHAEFTSREGEL_ENTFERNEN_",
        msg: "_MSG_ANGEBOT_DETAILS_CONFIG_DELETE_CONFIRM_",
        type: "danger",
        okClass: "btn btn-primary",
        okLabel: "_BTN_FORTFAHREN_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: this.delete,
        cancelCallback: this.closeDeleteConfirm,
      };
    },

    idChangeOeffentlichButton() {
      return `${ this.regel.pk }_button_change_oeffentlich`;
    },

    idGeschaeftsregelDeleteButton() {
      return `${ this.regel.pk }_button_geschaeftsregel_delete`;
    },

    idObenSchiebenButton() {
      return `${ this.regel.pk }_button_oben_schieben`;
    },

    idUntenSchiebenButton() {
      return `${ this.regel.pk }_button_unten_schieben`;
    },

    canOeffentlichIcon() {
      return this.regel.far_oeffentlich ? "eye-close" : "eye";
    },

    showOeffentlichDescreption() {
      return this.regel.far_oeffentlich ? "_TXT_YES_" : "_TXT_NO_";
    },

    showDetailLabel() {
      return !this.showDetail ? "_TXT_ANGEBOT_DETAILS_CONFIG_DETAILS_ANZEIGEN_" : "_TXT_ANGEBOT_DETAILS_CONFIG_DETAILS_AUSBLENDEN_";
    },

    canOeffentlichTitle() {
      return this.regel.far_oeffentlich ? "_TXT_ANGEBOT_DETAILS_CONFIG_NICHT_VEROEFFENTLICHEN_" : "_TXT_ANGEBOT_DETAILS_CONFIG_VEROEFFENTLICHEN_";
    },

    getSelectorClose() {
      return `#${ this.regel.pk }_geschaeftsregel_delete_modal_open`;
    },

    changeOeffentlicUrl() {
      return `foerderangebote/${ this.regel.far_id }/regelsaetze/${ this.regelsatzPk }/regeln/${ this.regel.far_id }/oeffentlich_aendern/`;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.dokConfig = this.getSyConfigsValue("akkr_doks") || {};
      this.setList();
      this.initModel();
    },

    delete() {
      this.doDelete(this.regel).then(
        () => this.closeDeleteConfirm(),
      );
    },

    closeDeleteConfirm() {
      this.modal.delete = false;
    },

    updateShowDetails() {
      this.showDetail = !this.showDetail;
    },

    openModalDelete() {
      this.modal.delete = true;
    },

    initModel() {
      this.modelConfig = initModelFromList({ list: this.listConfig });
    },

    changeOeffentlich() {
      const DATA = { far_oeffentlich: !this.regel.far_oeffentlich };
      this.putHttp({
        url: this.changeOeffentlicUrl,
        data: DATA,
      }).then(
        response => {
          const data = { far_oeffentlich: response.far_oeffentlich };
          const REGEL = Object.assign({}, this.regel, data);
          this.updateRegel({
            regel: REGEL,
            indexRegel: this.indexRegel,
          });
          this.loading.oeffentlich = false;
        }
      );
    },

    setList() {
      this.listConfig = toFormElementFromParameter({
        obj: this.regel.far_argument,
        showLabel: true,
        staticOptionsForType: {
          dyn_tabelle_objektkatalog_data: {
            dependency: "spalten",
          },
          dyn_tabelle_spaltenauswahl_radio: {
            dependency: "spalten",
          },
        },
      }).list;
    },
  },
};
