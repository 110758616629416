// @vue/component
import {
  forEach,
} from "lodash-es";

export default {
  computed: {
    additionalFilters() {
      const ADDITIONAL_FILTERS = {};
      const MODEL_FILTER = this.parameter.model_filter || [];
      forEach(MODEL_FILTER, v => {
        if (v.type === "daterange") {
          ADDITIONAL_FILTERS[v.modulname + "_after"] = v.wert[0];
          ADDITIONAL_FILTERS[v.modulname + "_before"] = v.wert[1];
        } else if (v.type === "numberrange") {
          ADDITIONAL_FILTERS[v.modulname + "_min"] = v.wert[0];
          ADDITIONAL_FILTERS[v.modulname + "_max"] = v.wert[1];
        } else {
          let wert;
          if (v.wert === true) {
            wert = "True";
          } else if (v.wert === false) {
            wert = "False";
          } else {
            wert = v.wert;
          }
          ADDITIONAL_FILTERS[v.modulname] = wert;
        }
      });
      return ADDITIONAL_FILTERS;
    },
  },
};
