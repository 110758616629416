import FormElement from "../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import loading from "../../../global/directives/loading";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  assign,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragstellerModalMarkierenDublette",
  components: {
    FormElement,
    Modal,
    PuxButton,
    PuxTranslate,
  },
  directives: {
    loading,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    dublette: {
      type: Object,
      default: () => ({}),
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalOptions: {
        required: true,
      },
      optionsText: {
        type: "text",
        id: "kopfdublette_astnr",
        view: "v",
        required: true,
        label: "_LBL_ANTRAGSTELLER_TABLE_MODAL_DUBLETTE_MARKIEREN_AST_NUMMER_",
        translate: true,
      },
      loading: undefined,
      searching: undefined,
      kopfdublette_astnr: undefined,
      selectedElId: undefined,
      errors: undefined,
      searched: undefined,
      maxResultLength: 5,
      searchResults: [],
    };
  },
  computed: {
    tooManyResults() {
      return (this.searchResults.length > this.maxResultLength);
    },

    noResults() {
      return (this.searched && this.searchResults.length === 0);
    },

    displayResults() {
      return !(this.noResults || this.tooManyResults);
    },

    canSearch() {
      return this.kopfdublette_astnr;
    },

    getIdRadio() {
      return index => `radio_${ index }`;
    },
  },
  methods: {
    searchChange() {
      if (this.canSearch) {
        this.searched = true;
        this.searching = true;
        this.selectedElId = null;
        this.errors = null;
        this.getListHttp({
          url: `antragstellerorganisationen/?for_user=${ this.user.n_id }&limit=${ this.maxResultLength + 1 }&prefer_nr_exact=${ this.kopfdublette_astnr }&show_dubletten=False`
        }).then(
          response => {
            const SEARCH_RESULTS = [];
            forEach(response, item => {
              if (item.ast_nr !== this.dublette.ast_nr) {
                SEARCH_RESULTS.push(item);
              }
            });
            this.searchResults = SEARCH_RESULTS;
          }
        ).then(
          () => {
            this.errors = undefined;
            this.searching = false;
          }
        );
      }
    },

    save() {
      this.loading = true;
      this.putHttp({ url: `antragstellerorganisationen/${ this.dublette.pk }/als_dublette_markieren/`, data: { kopfdublette: this.selectedElId } }).then(
        response => {
          this.addNotification({ text: "_MSG_ANTRAGSTELLER_TABLE_MODAL_DUBLETTE_MARKIEREN_SUCCESS_" });
          const newRow = assign({}, this.auftrag, response);
          this.close({ newRow });
        },
        error => {
          this.errors = error.data;
        }
      ).then(
        () => this.loading = false
      );
    },

    changeRadio() {
      this.errors = undefined;
    },
  },
};
