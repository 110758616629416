"use strict";

import BaseModel from "dias/core/models/baseModel";

class WorkflowUI extends BaseModel {
  constructor() {
    super("workflow_editor");
  }

  extendModel(model) {
    model = super.extendModel(model);

    model.anordnen = () => model.customPOST({}, "anordnen");

    return model;
  }
}

export default WorkflowUI;
