import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../../global/components/ui/ShowMore/ShowMore.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import {
  cloneDeep,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "SitzungAntraegeEntscheidung",
  components: {
    FormElement,
    FormstepDetail,
    PuxIcon,
    PuxTranslate,
    ShowMore,
  },
  props: {
    sitzungListeAntrag: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "updateSitzungListeAntrag",
  ],
  setup() {
    const {
      checkPermissionsSync,
    } = PermissionAPI();

    const {
      putHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      checkPermissionsSync,
      putHttp,
    };
  },
  data() {
    return {
      htmlId: "sitzung_entscheidung",
      model: {},
      errors: undefined,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        openCallback: this.open,
        saveCallback: this.save,
      };
    },

    hasPermissionSitzungenAntragView() {
      return this.checkPermissionsSync({
        perm: "sitzungen.antrag.view",
        permArray: this.sitzungListeAntrag.user_permissions,
      });
    },

    isEditable() {
      return this.checkPermissionsSync({
        perm: "tops.antrag.decide",
        permArray: this.sitzungListeAntrag.user_permissions,
      });
    },

    entscheidungsstatusObj() {
      return this.sitzungListeAntrag.entscheidungsstatus_obj || {};
    },

    entscheidungsstatusIconClass() {
      return `text-${ this.entscheidungsstatusObj.est_cssklasse }`;
    },

    optionsEntscheidungsstatus() {
      const ID = "entscheidungsstatus";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_TXT_SITZUNG_ANTRAG_ENTSCHEIDUNG_",
        type: "select",
        required: false,
        url: "katalog/",
        urlParams: {
          key: "entscheidungsstatus",
          gruppe: this.sitzungListeAntrag.entscheidungsstatusgruppe,
        },
        keyId: "pk",
        keyLabel: "bez",
        search: true,
        searchList: ["bez"],
        view: "v-alt",
      };
    },

    optionsentscheidungAnmerkung() {
      const ID = "sla_entscheidung_anmerkung";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_TXT_SITZUNG_ANTRAG_AUFLAGE_",
        type: "richtext",
        required: false,
        view: "v-alt",
      };
    },

    saveUrl() {
      const SITZUNG_PK = get(this.sitzungListeAntrag, "sitzung.pk");
      const TOP_PK = get(this.sitzungListeAntrag, "top.pk");
      return `sitzungen/${ SITZUNG_PK }/tops/${ TOP_PK }/sitzungslisten/${ this.sitzungListeAntrag.sitzungsliste }/festgeschrieben/${ this.sitzungListeAntrag.pk }/setze_entscheidung/`;
    },

    errorsLabelsOptions() {
      return [
        this.optionsEntscheidungsstatus,
        this.optionsentscheidungAnmerkung,
      ];
    },
  },
  methods: {
    open() {
      this.model = {
        entscheidungsstatus: this.sitzungListeAntrag.entscheidungsstatus,
        sla_entscheidung_anmerkung: this.sitzungListeAntrag.sla_entscheidung_anmerkung,
      };
    },

    save() {
      this.removeErrors();
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.saveUrl,
          data: this.model,
        }).then(
          response => {
            this.updateSitzungListeAntragLocal(response);
            this.addNotification({ text: "_MSG_UPDATE_SUCCESS_" });
            resolve();
          },
          error => {
            this.errors = error.data;
            reject(error);
          }
        );
      });
    },

    updateSitzungListeAntragLocal(response) {
      const SITZUNG_LISTE_ANTRAG = cloneDeep(this.sitzungListeAntrag);
      Object.assign(SITZUNG_LISTE_ANTRAG.entscheidungsstatus_obj, response.entscheidungsstatus_obj);
      SITZUNG_LISTE_ANTRAG.sla_entscheidung_anmerkung = response.sla_entscheidung_anmerkung;
      SITZUNG_LISTE_ANTRAG.entscheidungsstatus = response.entscheidungsstatus;
      this.$emit("updateSitzungListeAntrag", SITZUNG_LISTE_ANTRAG);
    },

    removeErrors() {
      this.errors = undefined;
    },
  },
};
