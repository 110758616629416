import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SchaltflaecheReadonly from "./SchaltflaecheReadonly/SchaltflaecheReadonly.vue";
import CheckboxReadonly from "./CheckboxReadonly/CheckboxReadonly.vue";
import CheckboxEdit from "./CheckboxEdit/CheckboxEdit.vue";
import SchaltflaecheEdit from "./SchaltflaecheEdit/SchaltflaecheEdit.vue";
import SchaltflaecheEditChildren from "./SchaltflaecheEditChildren/SchaltflaecheEditChildren.vue";
import {
  cloneDeep,
  forEach,
  isUndefined,
  isArray,
} from "lodash-es";

const WIDTH_MAP = {
  lg: {
    width: 1200,
    count: 4,
  },
  md: {
    width: 992,
    count: 3,
  },
  sm: {
    width: 0,
    count: 1,
  },
};

// @vue/component
export default {
  name: "DynamischeMehrfachauswahl",
  components: {
    SnapshotModule,
    SnapshotIcon,
    SchaltflaecheReadonly,
    SchaltflaecheEdit,
    SchaltflaecheEditChildren,
    CheckboxReadonly,
    CheckboxEdit,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      arrowShowIndex: undefined,
      currentAntwort: undefined,
      childrenCount: undefined,
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.openCallback;
      OPTIONS.closeCallback = this.removeEventListener;
      return OPTIONS;
    },

    labelHeader() {
      return this.modelParameter.frage;
    },

    statusSplit() {
      let split = true;
      forEach(this.modelParameter.antwort, item => {
        if (item.children && item.children.length || this.modelParameter.anzeigenmodus !== "block") {
          split = false;
          return false;
        }
      });
      return split;
    },

    classSplit() {
      if (this.statusSplit) {
        return "column-count-lg-2";
      } else if (this.modelParameter.anzeigenmodus === "row") {
        return "d-flex flex-wrap";
      }
      return "";
    },

    classForShowSchaltflaeche() {
      if (this.modelParameter.anzeigenmodus === "block") {
        return "d-flex flex-wrap";
      } else if (this.modelParameter.anzeigenmodus === "column") {
        return "flex-column flex-wrap";
      }
      return "";
    },

    currentAntwortLength() {
      if (this.modelParameter.antwort && this.modelParameter.antwort.length) {
        return this.modelParameter.antwort.length;
      }
      return 0;
    },

    showChildrenIndex() {
      if (isUndefined(this.arrowShowIndex)) {
        return -1;
      }
      let currentIndexShowTemp;
      const countTemp = this.childrenCount;
      const currentIndexShow = Math.floor((this.arrowShowIndex) / countTemp) * countTemp + (countTemp - 1);
      if (currentIndexShow > this.currentAntwortLength - 1) {
        currentIndexShowTemp = this.currentAntwortLength - 1;
      } else {
        currentIndexShowTemp = currentIndexShow;
      }
      return currentIndexShowTemp;
    },

    headerEdit() {
      return this.modelParameter.mehrfachauswahl ?
        "_TXT_GR_DYNAMISCHE_MEHRFACHAUSWAHL_HEADER_EDIT_MEHRFACHAUSWAHL_" :
        "_TXT_GR_DYNAMISCHE_MEHRFACHAUSWAHL_HEADER_EDIT_";
    },

    statusAnzeigeAuswahl() {
      return this.modelParameter.anzeige === "auswahl";
    },

    snapshotFiltered() {
      const SNAPSHOT = {
        changes: {},
        status: false,
      };
      if (this.statusSnapshot) {
        const MODEL_ANTWORTEN = this.model.antworten || {};
        const SNAPSHOT_ANTWORTEN = this.snapshotModule.antworten || {};
        forEach(this.modelParameter.antwort, item => {
          const LABEL = item.label;
          const CURRENT_MODEL = MODEL_ANTWORTEN[LABEL] || {};
          const CURRENT_MODEL_STATUS = CURRENT_MODEL.status || false;
          const CURRENT_MODEL_FREITEXT = CURRENT_MODEL.freitext || "";
          const CURRENT_SNAPSHOT = SNAPSHOT_ANTWORTEN[LABEL] || {};
          const CURRENT_SNAPSHOT_STATUS = CURRENT_SNAPSHOT.status || false;
          const CURRENT_SNAPSHOT_FREITEXT = CURRENT_SNAPSHOT.freitext || "";
          if (CURRENT_MODEL_STATUS !== CURRENT_SNAPSHOT_STATUS ||
            CURRENT_MODEL_FREITEXT !== CURRENT_SNAPSHOT_FREITEXT) {
            SNAPSHOT.changes[LABEL] = true;
            SNAPSHOT.status = true;
          }
          if (item.children && item.children.length) {
            const CURRENT_MODEL_CHILDREN = CURRENT_MODEL.children || {};
            const CURRENT_SNAPSHOT_CHILDREN = CURRENT_SNAPSHOT.children || {};
            forEach(item.children, child => {
              const CHILD_LABEL = child.label;
              const CURRENT_MODEL_CHILDREN_OBJ = CURRENT_MODEL_CHILDREN[CHILD_LABEL] || {};
              const CURRENT_MODEL_CHILDREN_OBJ_STATUS = CURRENT_MODEL_CHILDREN_OBJ.status || false;
              const CURRENT_MODEL_CHILDREN_OBJ_FREITEXT = CURRENT_MODEL_CHILDREN_OBJ.freitext || "";
              const CURRENT_SNAPSHOT_CHILDREN_OBJ = CURRENT_SNAPSHOT_CHILDREN[CHILD_LABEL] || {};
              const CURRENT_SNAPSHOT_CHILDREN_OBJ_STATUS = CURRENT_SNAPSHOT_CHILDREN_OBJ.status || false;
              const CURRENT_SNAPSHOT_CHILDREN_OBJ_FREITEXT = CURRENT_SNAPSHOT_CHILDREN_OBJ.freitext || "";
              if (CURRENT_MODEL_CHILDREN_OBJ_STATUS !== CURRENT_SNAPSHOT_CHILDREN_OBJ_STATUS ||
                CURRENT_MODEL_CHILDREN_OBJ_FREITEXT !== CURRENT_SNAPSHOT_CHILDREN_OBJ_FREITEXT) {
                SNAPSHOT.changes[CHILD_LABEL] = true;
                SNAPSHOT.status = true;
              }
            });
          }
        });
      }
      return SNAPSHOT;
    },

    statusDiff() {
      return this.snapshotFiltered.status;
    },

    labels() {
      return {
        antworten: this.headerEdit,
      };
    },
  },
  beforeUnmount() {
    this.removeEventListener();
  },
  methods: {
    prepareModel() {
      if (this.module.data &&
        this.module.data.antworten &&
        !isArray(this.module.data.antworten)) {
        this.model = cloneDeep(this.module.data);
        return;
      }
      const MODEL = {
        antworten: {},
      };
      forEach(this.modelParameter.antwort, antwort => {
        MODEL.antworten[antwort.label] = {
          children: {},
          status: false,
        };
        if (antwort.freitext) {
          MODEL.antworten[antwort.label].freitext = "";
        }
        if (antwort.children && antwort.children.length) {
          forEach(antwort.children, child => {
            MODEL.antworten[antwort.label].children[child.label] = {
              status: false,
            };
            if (child.freitext) {
              MODEL.antworten[antwort.label].children[child.label].freitext = "";
            }
          });
        }
      });
      this.model = MODEL;
    },

    openCallback() {
      this.arrowShowIndex = undefined;
      this.currentAntwort = undefined;
      this.resizeWindow();
      this.modelEdit = cloneDeep(this.model);
      this.setEventListener();
    },

    setEventListener() {
      if (this.modelParameter.style === "schaltflaechen") {
        window.addEventListener("resize", this.resizeWindow);
      }
    },

    removeEventListener() {
      window.removeEventListener("resize", this.resizeWindow);
    },

    resizeWindow() {
      const WINDOW_WIDTH = window.innerWidth;
      forEach(WIDTH_MAP, item => {
        if (WINDOW_WIDTH >= item.width) {
          this.childrenCount = item.count;
          return false;
        }
      });
    },

    changeModel({ model }) {
      this.modelEdit = model;
    },

    openChildren({ index }) {
      if (this.arrowShowIndex === index) {
        this.arrowShowIndex = undefined;
        this.currentAntwort = undefined;
      } else {
        this.arrowShowIndex = index;
        this.currentAntwort = this.modelParameter.antwort[this.arrowShowIndex];
      }
    },

    getAntwortLabel(antwort) {
      return antwort.pflicht ? `${ antwort.label }*` : antwort.label;
    },

    prepareToSave({ data }) {
      const DATA = cloneDeep(data.antworten);
      forEach(DATA, antwort => {
        if (!antwort.status && antwort.freitext) {
          antwort.freitext = "";
        }
        if (antwort.children) {
          forEach(antwort.children, child => {
            if (!child.status && child.freitext) {
              child.freitext = "";
            }
          });
        }
      });
      return { antworten: DATA };
    },
  },
};
