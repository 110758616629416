var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["required-form-group"] = jade_interp = function(idx, id, label, fieldName){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-class", "{'has-error has-feedback': vm.modulErrors[" + (idx) + "]." + (fieldName) + "}", true, true)) + " class=\"form-group\"><label" + (jade.attr("ng-class", "{'has-error has-feedback':vm.modulErrors[" + (idx) + "]." + (fieldName) + "}", true, true)) + (jade.attr("ng-attr-for", "" + (id) + "", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span></label>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.modulErrors[" + (idx) + "]." + (fieldName) + "", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.modulErrors[" + (idx) + "]." + (fieldName) + "", true, true)) + ">{{ err }}</li></ul></span></div>");
};
jade_mixins["form-group"] = jade_interp = function(idx, id, label, fieldName){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-class", "{'has-error has-feedback': vm.modulErrors[" + (idx) + "]." + (fieldName) + "}", true, true)) + " class=\"form-group\"><label" + (jade.attr("ng-class", "{'has-error has-feedback':vm.modulErrors[" + (idx) + "]." + (fieldName) + "}", true, true)) + (jade.attr("ng-attr-for", "" + (id) + "", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span></label>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.modulErrors[" + (idx) + "]." + (fieldName) + "", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.modulErrors[" + (idx) + "]." + (fieldName) + "", true, true)) + ">{{ err }}</li></ul></span></div>");
};
buf.push("<div class=\"no-error\"><br><div ng-repeat=\"bericht in vm.parameter[vm.key].wert\"><span class=\"clearfix\"><label ng-class=\"{'has-error': vm.errors[$index]}\" class=\"font-normal inline\"><span class=\"titel\">{{ $index + 1 }}. {{ bericht.label?bericht.label:'[keine Angabe]' }}<small>&nbsp;<a href=\"#\" ng-click=\"showDetail = !showDetail\" style=\"white-space:nowrap;\">(Konfiguration {{showDetail?'ausblenden':'anzeigen'}})</a></small><span class=\"alert-danger\"></span></span></label><div class=\"btn-group float-right\"><button title=\"Nach oben schieben\" ng-if=\"!$first\" ng-click=\"vm.up($index)\" class=\"btn-xs btn btn-default\"><i class=\"glyphicon glyphicon-chevron-up\">&nbsp;</i><span class=\"sr-only\">Nach oben schieben</span></button><button title=\"Nach unten schieben\" ng-if=\"!$last\" ng-click=\"vm.down($index)\" class=\"btn-xs btn btn-default\"><i class=\"glyphicon glyphicon-chevron-down\">&nbsp;</i><span class=\"sr-only\">Nach unten schieben</span></button><button title=\"Projektbericht entfernen\" dias-confirm confirm-title=\"Projektbericht entfernen\" confirm-msg=\"Sind Sie sicher, dass sie den Projektbericht aus der Konfiguration entfernen wollen?\" confirm-type=\"danger\" confirm-ok=\"vm.remove($index)\" class=\"btn-xs btn btn-primary\"><i class=\"glyphicon glyphicon-trash\">&nbsp;</i><span class=\"sr-only\">Projektbericht löschen</span></button></div><div ng-show=\"showDetail\">");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input ng-attr-id=\"param_{{::$index }}_label\" ng-attr-name=\"param_{{::$index }}_label\" ng-model=\"bericht.label\" type=\"text\" class=\"form-control\">");
}
}, "$index", "param_{{::$index }}_label", "Bezeichnung", "label");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<select ng-attr-id=\"param_{{::$index }}_vorlage\" ng-attr-name=\"param_{{::$index }}_vorlage\" ng-model=\"bericht.vorlage\" ng-change=\"vm.updateVorlagen()\" role=\"listbox\" class=\"form-control\"><option ng-repeat=\"c in vm.vorlagen\" ng-attr-value=\"{{:: c.id }}\">{{:: c.bez }}</option></select>");
}
}, "$index", "param_{{::$index }}_vorlage", "Vorlage", "vorlage");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input ng-attr-id=\"param_{{::$index }}_minimum\" ng-attr-name=\"param_{{::$index }}_minimum\" ng-model=\"bericht.minimum\" type=\"number\" class=\"form-control\">");
}
}, "$index", "param_{{::$index }}_minimum", "Mindestanzahl", "minimum");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input ng-attr-id=\"param_{{::$index }}_maximum\" ng-attr-name=\"param_{{::$index }}_maximum\" ng-model=\"bericht.maximum\" type=\"number\" class=\"form-control\">");
}
}, "$index", "param_{{::$index }}_maximum", "Maximalanzahl", "maximum");
buf.push("</div></span><hr></div></div><button type=\"submit\" ng-click=\"vm.add()\" class=\"btn btn-default btn-block\"><i class=\"glyphicon glyphicon-plus\">&nbsp;</i><span>Projektbericht hinzufügen</span></button>");;return buf.join("");
}