"use strict";

import BetragsrechnerRegistry from "dias/kosten_finanzierung/betragsrechner/services";

import FinanzierungMitLandBetragsrechner from "./betragsrechner.js";
import FinanzierungMitLandController from "./controller";

const FinanzierungMitLandBetragsrechnerModul = angular.module("dias.betragsrechner.FinanzierungMitLandBetragsrechnerModul", [
  BetragsrechnerRegistry.name,
]);

FinanzierungMitLandBetragsrechnerModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("finanzierung_mit_land_betragsrechner", FinanzierungMitLandBetragsrechner);
})
  .component("finanzierungmitland", FinanzierungMitLandController);

export default FinanzierungMitLandBetragsrechnerModul;
