import Kommunikationsdaten from "../Kommunikationsdaten/Kommunikationsdaten.vue";

// @vue/component
export default {
  components: {
    Kommunikationsdaten,
  },
  props: {
    ansprechpartner: {
      type: Object,
      required: true,
    },
    urlTypId: {
      type: String,
      required: true,
    },
    emailTypId: {
      type: String,
      required: true,
    },
  },
};
