import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import Alert from "../../../../global/components/Alert/Alert.vue";

import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import {
  EventBus
} from "../../../../global/functions/event-bus";
import moment from "moment";
import {
  cloneDeep,
  lowerCase,
  forEach,
  get,
  isEmpty,
} from "lodash-es";

const SNAPSHOT_CHANGES_LIST = [
  "von",
  "bis",
];

// @vue/component
export default {
  name: "StandardDurchfuehrungszeitraum",
  components: {
    FormElement,
    SnapshotModule,
    SnapshotIcon,
    Alert,
  },
  mixins: [
    ModuleMixin,
    SyConfigMixin,
  ],
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    return {
      filterDate,
    };
  },
  data() {
    return {
      isConfirmVisible: false,
      loading: false,
      modelEdit: {
        von: null,
        bis: null,
      },
      statusFoerderzeitraumAusblenden: undefined,
    };
  },
  computed: {
    confirmOptions() {
      return {
        okClass: "btn btn-primary",
        title: this.modelParameter.confirm_dialog_header,
        msg: this.modelParameter.confirm_dialog_body,
        okLabel: "_BTN_SAVE_",
        okCallback: this.saveLocal,
        cancelCallback: () => this.isConfirmVisible = false,
        loading: this.loading,
      };
    },

    idForSaveButton() {
      return `${ this.regelId }_save`;
    },

    headerId() {
      return `${ this.regelId }_header`;
    },

    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.openEditMode;
      OPTIONS.statusSaveHide = this.useConfirmDialog;
      return OPTIONS;
    },

    labelHeader() {
      if (this.modelParameter.frage_text) {
        return this.modelParameter.frage_text;
      }
      return "_TXT_GR_DURCHFUEHRUNGSZEITRAUM_HEADER_";
    },

    errorsLabelsOptions() {
      return [
        this.optionsVon,
        this.optionsBis,
      ];
    },

    optionsVon() {
      return {
        id: "von",
        htmlId: `${ this.htmlRegelNummer }_von`,
        type: "date",
        label: "_LBL_GR_DURCHFUEHRUNGSZEITRAUM_BEGINN_",
        translate: true,
        required: true,
        view: "v",
        change: this.changeVon,
        startupFocus: true,
      };
    },

    optionsBis() {
      const NOT_BEFORE = this.modelEdit.von || moment().format("YYYY-MM-DD");
      return {
        id: "bis",
        htmlId: `${ this.htmlRegelNummer }_bis`,
        type: "date",
        label: "_LBL_GR_DURCHFUEHRUNGSZEITRAUM_ENDE_",
        translate: true,
        required: true,
        view: "v",
        notBefore: NOT_BEFORE,
        defaultValue: this.modelEdit.von,
      };
    },

    optionsTranslateMax() {
      return {
        text: "_TXT_GR_DURCHFUEHRUNGSZEITRAUM_EDIT_HEADER_MAX_{{max}}_{{zeiteinheit}}_",
        extra: {
          max: this.modelParameter.max_durchfuehrungsdauer,
          zeiteinheit: this.zeiteinheitMax,
        },
      };
    },

    optionsTranslateMin() {
      return {
        text: "_TXT_GR_DURCHFUEHRUNGSZEITRAUM_EDIT_HEADER_MIN_{{min}}_{{zeiteinheit}}_",
        extra: {
          min: this.modelParameter.min_durchfuehrungsdauer,
          zeiteinheit: this.zeiteinheitMin,
        },
      };
    },

    optionsTranslateMinMax() {
      return {
        text: "_TXT_GR_DURCHFUEHRUNGSZEITRAUM_EDIT_HEADER_MIN_MAX_{{min}}_{{max}}_{{zeiteinheit}}_",
        extra: {
          min: this.modelParameter.min_durchfuehrungsdauer,
          max: this.modelParameter.max_durchfuehrungsdauer,
          zeiteinheit: this.zeiteinheit,
        },
      };
    },

    useConfirmDialog() {
      return !!this.modelParameter.confirm_dialog_body;
    },

    zeiteinheitMax() {
      return this.getZeiteinheitLocal("max_durchfuehrungsdauer");
    },

    zeiteinheitMin() {
      return this.getZeiteinheitLocal("min_durchfuehrungsdauer");
    },

    zeiteinheit() {
      return this.getZeiteinheitLocal();
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(SNAPSHOT_CHANGES_LIST, key => {
          if (get(this.model, key) !== get(this.snapshotModule, key)) {
            DIFF[key] = true;
          }
        });
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },
  },
  created() {
    this.checkSyConfig();
  },
  methods: {
    prepareModel() {
      this.model = cloneDeep(this.module.data) || {};
    },

    checkSyConfig() {
      this.statusFoerderzeitraumAusblenden = this.getSyConfigsValue("foerderzeitraum_ausblenden");
    },

    getZeiteinheitLocal(durchfuehrungsdauer) { // _TXT_DAY_ || _TXT_DAYS_ || _TXT_MONTH_ || _TXT_MONTHS_
      let zeiteinheit = lowerCase(this.modelParameter.zeiteinheit) === "tage" ? "_TXT_DAY" : "_TXT_MONTH";
      if (durchfuehrungsdauer) {
        zeiteinheit += this.modelParameter[durchfuehrungsdauer] === 1 ? "_" : "S_";
      } else {
        zeiteinheit += "S_";
      }
      return window.gettext(zeiteinheit);
    },

    changeVon() {
      if (!this.modelEdit.von) {
        return;
      }
      if (!this.modelEdit.bis || moment(this.modelEdit.von) > moment(this.modelEdit.bis)) {
        this.modelEdit.bis = this.modelEdit.von;
      }
    },

    openEditMode() {
      this.modelEdit = {
        von: this.model.von || null,
        bis: this.model.bis || null,
      };
    },

    saveLocal() {
      this.loading = true;
      return this.save({ model: this.model }).finally(
        () => {
          this.loading = false;
          this.isConfirmVisible = false;
        }
      );
    },

    sendSignalLocal() {
      EventBus.$emit(`durchfuehrungszeitraum.update`, this.signalArguments);
    },

    setLocalErrorsFromGlobal({ errors }) {
      this.errors = errors;
    },
  },
};
