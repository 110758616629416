import translate from "../../../../../../global/directives/translate";
import AngularLink from "../../../../../../global/components/AngularLink/AngularLink.vue";
import SnapshotIcon from "../../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import PuxCloak from "../../../../../../global/components/PuxCloak/PuxCloak.vue";
import ShowMore from "../../../../../../global/components/ui/ShowMore/ShowMore.vue";
import MissingAntragstellerBeschreibung from "../../MissingAntragstellerBeschreibung/MissingAntragstellerBeschreibung.vue";

// @vue/component
export default {
  name: "AntragstellerAuswahlReadonlyDufo",
  components: {
    AngularLink,
    SnapshotIcon,
    PuxCloak,
    ShowMore,
    MissingAntragstellerBeschreibung,
  },
  directives: {
    translate,
  },
  props: {
    astIsDufo: {
      type: Boolean,
      required: false,
    },
    dufoObj: {
      type: Object,
      required: false,
      default: undefined,
    },
    astPk: {
      type: String,
      required: true,
    },
    astName: {
      type: String,
      required: true,
    },
    statusDufoLoading: {
      type: Boolean,
      required: true,
    },
    diff: {
      type: Object,
      required: true,
    },
  },
  computed: {
    statusDiffAnschrift() {
      return this.diff["dufo_obj.do_strasse"] ||
        this.diff["dufo_obj.do_hsnr"] ||
        this.diff["dufo_obj.do_plz"] ||
        this.diff["dufo_obj.do_ort"] ||
        this.diff["dufo_obj.land.lkz_bez"];
    },
  },
};
