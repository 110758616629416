import BelegModalMixin from "../BelegModalMixin";
import {
  EventBus,
} from "../../../../../global/functions/event-bus";

import {
  cloneDeep,
} from "lodash-es";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "BelegModalAnerkennenAblehnenZuruecksetzen",
  components: {
    PuxButton,
  },
  mixins: [
    BelegModalMixin,
  ],
  props: {
    functionName: {
      type: String,
      required: true,
      validator: value => ["anerkennen", "ablehnen", "zuruecksetzen"].indexOf(value) !== -1,
    },
  },
  data() {
    return {
      functionNameMapping: {
        anerkennen: {
          header: "_TXT_VERTRAG_DETAILS_BELEG_MODAL_ANERKENNEN_HEADER_",
          labelSave: "_BTN_VERTRAG_DETAILS_BELEG_MODAL_ANERKENNEN_SAVE_",
          apiParameter: "anerkennen",
          msgSuccess: "_MSG_VERTRAG_DETAILS_BELEG_MODAL_ANERKENNEN_SUCCESS_",
        },
        ablehnen: {
          header: "_TXT_VERTRAG_DETAILS_BELEG_MODAL_ABLEHNEN_HEADER_",
          labelSave: "_BTN_VERTRAG_DETAILS_BELEG_MODAL_ABLEHNEN_SAVE_",
          apiParameter: "ablehnen",
          msgSuccess: "_MSG_VERTRAG_DETAILS_BELEG_MODAL_ABLEHNEN_SUCCESS_",
        },
        zuruecksetzen: {
          header: "_TXT_VERTRAG_DETAILS_BELEG_MODAL_ZURUECKSETZEN_HEADER_",
          labelSave: "_BTN_VERTRAG_DETAILS_BELEG_MODAL_ZURUECKSETZEN_SAVE_",
          apiParameter: "zuruecksetzen",
          msgSuccess: "_MSG_VERTRAG_DETAILS_BELEG_MODAL_ZURUECKSETZEN_SUCCESS_",
        },
      },
    };
  },
  created() {
    this.setBelegeList();
    this.setBelegeForOptionsList();
  },
  methods: {
    changeBeleg({ item }) {
      this.currentBeleg = cloneDeep(item);
    },

    save() {
      this.status.loading = true;
      this.postHttp({
        url: `vertraege/${ this.vertragPk }/kosten/${ this.vertragkosten }/positionen/${ this.position.position }/belege/${ this.model.beleg }/${ this.functionNameMapping[this.functionName].apiParameter }/`,
      }).then(
        response => {
          this.addNotification({ text: this.functionNameMapping[this.functionName].msgSuccess });
          EventBus.$emit(`updateTable${ "BelegeListInVertragTable" }`);
          this.close({ beleg: response, status: true });
        },
        error => {
          this.addNotification({ text: error.data && error.data[0] ? error.data && error.data[0] : "_MSG_STANDARD_ERROR_", type: "error" });
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
