import AsideRightBox from "../../../global/components/AsideRightBox/AsideRightBox.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "FormularCreate",
  components: {
    AsideRightBox,
    PuxButton,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    objektContentType: {
      type: Number,
      required: true,
    },
    objektPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      optionsAsideRightBox: {
        icon: "empty-document",
        hideHeader: true,
      },
      id: "formular_create_aside_right_box",
      loading: true,
      templates: [],
    };
  },
  computed: {
    idForButtonCreate() {
      return `${ this.id }_create_`;
    },

    baseUrl() {
      return `genericformulare/${ this.objektContentType }/object/${ this.objektPk }/formulare/`;
    },

    urlGet() {
      return `${ this.baseUrl }verfuegbare_vorlagenversionen/`;
    },

    urlPut() {
      return `${ this.baseUrl }erstellen_von_vorlage/`;
    },
  },
  created() {
    this.loadTemplates();
  },
  methods: {
    loadTemplates() {
      this.getHttp({
        url: this.urlGet,
      }).then(
        response => this.templates = response
      ).finally(
        () => this.loading = false
      );
    },

    createFormular(template) {
      this.putHttp({
        url: this.urlPut,
        data: {
          versions_id: template.pk,
        },
      }).then(
        response => {
          const SREF = `root.${ response.cortex }.detail`;
          this.addNotification({
            text: "_MSG_FORMULAR_CREATE_BOX_SUCCESS_{{fvv_kbez}}_",
            extra: {
              fvv_kbez: template.fvv_kbez,
            },
          });
          this.$goTo(SREF, { id: response.pk });
        },
        () => {
          this.addNotification({
            text: "_MSG_FORMULAR_CREATE_BOX_ERROR_",
            type: "error",
          });
        }
      );
    },
  },
};
