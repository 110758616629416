import AntragstellerWizard from "../../Antragsteller/AntragstellerWizard/AntragstellerWizard.vue";

// @vue/component
export default {
  name: "AntragstellerWizardInAntrag",
  components: {
    AntragstellerWizard,
  },
  props: {
    closeWizardAngular: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      hideSteps: ["zugang"],
    };
  },
  methods: {
    closeWizard({ organisation }) {
      if (organisation) {
        this.$AuthService.refreshUser().then(
          () => this.closeWizardAngular(organisation)
        );
      } else {
        this.closeWizardAngular();
      }
    },
  },
};
