import DynamischeTabellePeriodenModalCreateOrUpdate from "./DynamischeTabellePeriodenModalCreateOrUpdate/DynamischeTabellePeriodenModalCreateOrUpdate.vue";

import DynamischeTabelle from "../DynamischeTabelle/DynamischeTabelle.js";

// @vue/component
export default {
  name: "DynamischeTabellePerioden",
  components: {
    DynamischeTabellePeriodenModalCreateOrUpdate,
  },
  mixins: [
    DynamischeTabelle,
  ],
  computed: {
    modalComponentName() {
      return DynamischeTabellePeriodenModalCreateOrUpdate.name;
    },
  },
};
