export default {
  type: {
    foerderantrag: {
      state: "root.foerderantrag.details",
      getStateParameter: item => ({
        id: item.obj_id,
      }),
      text: "_LNK_TIMELINE_ZUM_ANTRAG_",
      icon: "glyphicon-antrag",
    },
    vertrag: {
      state: "root.vertrag.details",
      getStateParameter: item => ({
        id: item.obj_id,
      }),
      text: "_LNK_TIMELINE_ZUM_VERTRAG_",
      icon: "glyphicon-vertrag",
    },
    antragstellerorganisation: {
      state: "root.astorganisationen.details",
      getStateParameter: item => ({
        id: item.obj_id,
      }),
      text: "_LNK_TIMELINE_ZUM_ANTRAGSTELLER_",
      icon: "glyphicon-organisation",
    },
    auszahlungsplan: {
      state: "root.vertrag.details",
      getStateParameter: item => ({
        id: item.obj_id,
      }),
      text: "_LNK_TIMELINE_ZUM_VERTRAG_",
      icon: "glyphicon-euro",
    },
    auszahlung: {
      state: "root.vertrag.details.auszahlung",
      getStateParameter: item => ({
        id: item.obj_parent_id,
        aus_id: item.obj_id,
      }),
      text: "_LNK_TIMELINE_ZUR_AUSZAHLUNG_",
      icon: "glyphicon-euro",
    },
    sitzung: {
      state: "root.sitzungen.details",
      getStateParameter: item => ({
        id: item.obj_id,
      }),
      text: "_LNK_TIMELINE_ZUR_SITZUNG_",
      icon: "glyphicon-tagesordnung",
    },
    formular: {
      state: "root.basisformularcortex.detail",
      getStateParameter: item => ({
        id: item.obj_id,
      }),
      text: "_LNK_TIMELINE_ZUM_FORMULAR_",
      icon: "glyphicon-empty-document",
    },
    projektbericht: {
      state: "root.projektberichte.detail",
      getStateParameter: item => ({
        id: item.obj_id,
      }),
      text: "_LNK_TIMELINE_ZUM_PROJEKTBERICHT_",
      icon: "glyphicon-berichte",
    },

    benachrichtigung: {
      icon: "glyphicon-communication",
    },
    aufgabe: {
      icon: "glyphicon-send"
    },
    aufgabe_offen: {
      icon: "glyphicon-todos"
    },
    timelineobjekt: {
      icon: "glyphicon-time"
    },
    notizen: {
      icon: "glyphicon-note",
    },
  },
  options: {
    classList: ["timeline_vertical__content_left", "timeline_vertical__content_right"],
    filterDefault: [
      {
        titel: "_TXT_TIMELINE_FILTER_WORKFLOW_AKTIVITAETEN_",
        key: "aufgabe",
        icon: "send"
      },
      {
        titel: "_TXT_TIMELINE_FILTER_BENACHRICHTIGUNGEN_",
        key: "benachrichtigung",
        icon: "communication"
      },
      {
        titel: "_TXT_TIMELINE_FILTER_AKTIONEN_",
        key: "timelineobjekt",
        icon: "time"
      },
      {
        titel: "_TXT_TIMELINE_FILTER_NOTIZEN_",
        key: "notizen",
        icon: "note"
      },
    ],
    interval: 1000 * 60,
    emptyText: "_TXT_TIMELINE_EMPTY_TEXT_",
  },
};
