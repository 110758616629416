import PuxTranslate from "../../../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  clamp,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "DashboardBalkendiagrammStatisticRowBar",
  components: {
    PuxTranslate,
  },
  props: {
    bar: {
      type: Object,
      required: true
    },
  },
  computed: {
    meterId() {
      return `${ this.bar.id }__meter`;
    },

    barClass() {
      if (!isNil(this.bar.class_foreground)) {
        return `dashboard__balkendiagramm__bar__${ this.bar.class_foreground }`;
      }
      return null;
    },

    barStyle() {
      const STYLE = {
        width: `${ clamp(this.bar.percentage, 0, 100) }%`,
        height: this.bar.height,
      };
      if (isNil(this.barClass)) {
        STYLE.backgroundColor = this.bar.color_foreground;
        STYLE.color = this.bar.color_text;
      }
      return STYLE;
    },

    barContainerStyle() {
      return {
        height: this.bar.height,
        backgroundColor: this.bar.color_background,
      };
    },

    rootStyle() {
      return {
        // 1px, damit sr-only-Element nicht zum Scrollen führt
        marginBottom: this.bar.isLast ? "1px" : this.bar.spacing
      };
    },
  },
};
