import AktualisierterZuschussMixin from "./AktualisierterZuschussMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

// @vue/component
export default {
  name: "AktualisierterZuschuss",
  mixins: [
    AktualisierterZuschussMixin,
    SyConfigMixin,
  ],
  data() {
    return {
      zushussanpassungConfig: {},
    };
  },
  computed: {
    isAnpassungRelevant() {
      return this.zushussanpassungConfig.anpassung_relevant;
    },
  },
  created() {
    this.setSyConfig();
  },
  methods: {
    setSyConfig() {
      this.zushussanpassungConfig = this.getSyConfigsValue("zuschussanpassung_zuschussberechnung", {});
    },
  },
};
