"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import parameter from "./parameter";

const mod = angular.module("root.pruefkriterien.module", [
  uiRouter,
  parameter.name,
]);

export default mod;
