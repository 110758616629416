"use strict";

import BaseModel from "dias/core/models/baseModel";
import Dokumente from "./dokumente.service";
import Preview from "./preview.service";

class Foerderdokument extends BaseModel {
  constructor() {
    super("foerderdokumente", {
      subPaths: {
        dokumente: new Dokumente(),
        preview: new Preview()
      }
    });
  }
}

export default Foerderdokument;
