import PuxPopover from "../../../global/components/PuxPopover/PuxPopover.vue";

import {
  size,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  components: {
    PuxPopover,
  },
  data() {
    return {
      isMessageVisible: true,
    };
  },
  computed: {
    isMessageVisibleLocal() {
      return !this.loading && this.isMessageVisible && !isNil(this.message);
    },

    message() {
      if (size(this.parameter.cond_msgs) === 0) {
        return;
      }
      let message;
      for (let i = 0; i < this.parameter.cond_msgs.length; i++) {
        const cond_msg = this.parameter.cond_msgs[i];
        if ((!cond_msg.min || cond_msg.min <= this.count) && (!cond_msg.max || cond_msg.max >= this.count)) {
          message = cond_msg;
          break;
        }
      }
      return message;
    },

    messagePlacement() {
      if (this.message) {
        return this.message.placement;
      }
      return undefined;
    },

    messageContent() {
      if (this.message) {
        return this.message.msg;
      }
      return "";
    },

    messageClass() {
      if (this.message) {
        return this.message.css_klasse;
      }
      return "";
    },

    idForPopover() {
      return `${ this.idLocal }_popover`;
    },

    idForButtonClosePopover() {
      return `${ this.idLocal }_close_popover`;
    },

    idForIconBox() {
      return `${ this.idLocal }_icon_box`;
    },
  },
  methods: {
    closeMessage() {
      this.isMessageVisible = false;
    },
  },
};
