"use strict";

import BetragsrechnerRegistry from "../services";

import BetragsrechnerPersonalkosten from "./betragsrechner.js";
import BetragsrechnerPersonalkostenController from "./controller";

const BetragsrechnerPersonalkostenModul = angular.module("dias.betragsrechner.personalkosten_mit_pkt", [
  BetragsrechnerRegistry.name,
]);

BetragsrechnerPersonalkostenModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("personalkosten_mit_pkt", BetragsrechnerPersonalkosten);
})
  .component("personalkostenmitpkt", BetragsrechnerPersonalkostenController);

export default BetragsrechnerPersonalkostenModul;
