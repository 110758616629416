import translate from "../../directives/translate";

// @vue/component
export default {
  name: "PuxCloak",
  directives: {
    translate,
  },
  props: {
    msg: {
      type: String,
      required: false,
      default: "Wird geladen",
      info: "Text für Loading",
    },
    size: {
      type: String,
      required: false,
      default: "xl",
      validator: value => ["xs", "sm", "md", "lg", "xl"].indexOf(value) !== -1,
      info: "Schriftgröße",
    },
    align: {
      type: String,
      required: false,
      default: "center",
      validator: value => ["center", "left", "right"].indexOf(value) !== -1,
      info: "Ausrichtung in Komponente",
    },
    left: {
      type: Boolean,
      required: false,
      info: "Richtung, wo Text angezeigt wird",
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
      info: "Extra-Objekt für Übersetzung",
    },
  },
  computed: {
    alignLocal() {
      return `text-${ this.align }`;
    },

    textTranslateOptions() {
      const OPTIONS = {
        text: this.messageLocal,
      };
      if (this.extra) {
        OPTIONS.extra = this.extra;
      }
      return OPTIONS;
    },

    messageLocal() {
      return this.msg === undefined ? "_TXT_LOADING_" : this.msg;
    },
  },
};
