"use strict";

import template from "./angebotkontext.jade";


class AngebotfoerderanzeigekontextRegelController {
  /*@ngInject*/
  constructor($q, $scope, $timeout, DiasNotification, Foerderregeln) {
    this.$q = $q;
    this.$timeout = $timeout;
    this.Foerderregeln = Foerderregeln;
    this.notification = DiasNotification.page;
    this.initializing = true;
    this.regeln = [];
    $scope.$watch("vm.show", () => {
      if (this.show) {
        this.init();
      }
    });
    this.updateRegel = this.updateRegel.bind(this);

    this.addFoerderangebotregel = foerderregel => {
      this.regelsatzregeln.post({ regel: foerderregel.pk }).then(
        response => {
          this.regelsatzregeln.push(response);
          this.updateUsedFoerderregeln();
          if (this.onRegelUpdate) {
            this.onRegelUpdate();
          }
          this.notification.success("Geschäftsregel erfolgreich hinzugefügt");
        },
        error => this.notification.error(error, "Fehler beim Hinzufügen der Geschäftsregel")
      );
    };

    this.deleteFoerderangebotregel = foerderregel => {
      this.loading = true;
      const promise = foerderregel.remove();
      promise.then(
        () => {
          // remove from our list
          let i;
          for (i = this.regelsatzregeln.length - 1; i >= 0; i--) {
            if (this.regelsatzregeln[i].pk === foerderregel.pk) {
              this.regelsatzregeln.splice(i, 1);
              break;
            }
          }
          // update remainig positions
          for (i; i < this.regelsatzregeln.length ; i++) {
            this.regelsatzregeln[i].far_pos = i + 1;
          }
          this.updateUsedFoerderregeln();
          if (this.onRegelUpdate) {
            this.onRegelUpdate();
          }
          this.notification.success("Geschäftsregel enfernt");
        },
        error => this.notification.error(error, "Fehler beim Entfernen der Geschäftsregel")
      ).finally(() => this.loading = false);
      return promise;
    };

    this.moveUpFoerderangebotregel = foerderregel => {
      const oldIndex = this.regelsatzregeln.findIndex(v => v.pk === foerderregel.pk);
      if (oldIndex === 0) {
        // Can't move first further up
        return;
      }
      this.loading = true;
      foerderregel.moveUp().then(
        () => {
          const previous = this.regelsatzregeln[oldIndex - 1];
          previous.far_pos += 1;
          foerderregel.far_pos -= 1;
          this.regelsatzregeln[oldIndex] = previous;
          this.regelsatzregeln[oldIndex - 1] = foerderregel;
          this.notification.success("Geschäftsregel verschoben");
        },
        error => this.notification.error(error, "Fehler beim Verschieben der Geschäftsregel")
      ).finally(() => this.loading = false);
    };

    this.moveDownFoerderangebotregel = foerderregel => {
      const oldIndex = this.regelsatzregeln.findIndex(v => v.pk === foerderregel.pk);
      if (oldIndex === (this.regelsatzregeln.length - 1)) {
        // Can't move last further down
        return;
      }
      this.loading = true;
      foerderregel.moveDown().then(
        () => {
          const next = this.regelsatzregeln[oldIndex + 1];
          next.far_pos -= 1;
          foerderregel.far_pos += 1;
          this.regelsatzregeln[oldIndex] = next;
          this.regelsatzregeln[oldIndex + 1] = foerderregel;
          this.notification.success("Geschäftsregel verschoben");
        },
        error => this.notification.error(error, "Fehler beim Verschieben der Geschäftsregel")
      ).finally(() => this.loading = false);
    };

    this.updateUsedFoerderregeln = () => {
      if (this.regelsatzregeln && this.regeln) {
        this.regeln.usable = this.regeln.length;
        for (let i = 0; i < this.regeln.length; i++) {
          this.regeln[i].used = false;
          this.regeln[i].titel = (this.regeln[i].re_nummer ? ("[" + this.regeln[i].re_nummer + "] ") : "") + this.regeln[i].re_kbez;
          for (let j = 0; j < this.regelsatzregeln.length; j++) {
            if (this.regelsatzregeln[j].regel === this.regeln[i].pk) {
              this.regeln[i].used = true;
              this.regeln.usable--;
              break;
            }
          }
        }
      }
    };
  }

  init() {
    if (!this.startedInitializing && this.regelsatz && this.foerderanzeigekontext) {
      this.startedInitializing = true;
      this.angebot.regelsaetze.one(this.regelsatz.pk).regeln.getList({ anzeigekontext: this.foerderanzeigekontext.anzeigekontext.pk })
        .then(
          response => {
            response.forEach((v, idx) => v.far_pos = idx + 1);
            this.regelsatzregeln = response;
          },
          err => this.notification.error("Fehler beim Laden der vorhandenen Regeln", err)
        ).finally(() => this.initializing = false);
      if (this.editable) {
        this.loadingEditData = true;
        this.Foerderregeln.getAngebotsregeln({ anzeigekontext: this.foerderanzeigekontext.anzeigekontext.pk })
          .then(
            response => {
              this.regeln = response;
              this.updateUsedFoerderregeln();
            },
            err => this.notification.error("Fehler beim Laden der Regeln", err)
          ).finally(() => this.loadingEditData = false);
      }
    }
  }

  updateRegel({ regel, indexRegel }) {
    this.$timeout(() => {
      this.regelsatzregeln.splice(indexRegel, 1, regel);
    });
  }
}

export default {
  template: template(),
  controller: AngebotfoerderanzeigekontextRegelController,
  controllerAs: "vm",
  bindings: {
    foerderanzeigekontext: "<",
    angebot: "<",
    regelsatz: "<",
    index: "<",
    editable: "=",
    show: "=",
    onRegelUpdate: "&?",
  }
};
