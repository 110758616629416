"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

const mod = angular.module("dias.foerderlandkarte", [
  uiRouter,
]);

mod.config($stateProvider => {
  $stateProvider
    .state("root.karte", {
      url: "/foerderlandkarte/",
      views: {
        "main@root": {
          template: "<div vueinit><foerderlandkarte-page></foerderlandkarte-page></div>"
        }
      },
      data: {
        instance_pages: "foerderlandkarte"
      }
    })
    .state("root.karte_iframe", {
      url: "/foerderlandkarte_iframe/",
      views: {
        "main@root": {
          template: "<div vueinit><foerderlandkarte-page></foerderlandkarte-page></div>"
        }
      },
      data: {
        iframe: true
      }
    })
  ;
});

export default mod;
