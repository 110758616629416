"use strict";

import BaseModel from "dias/core/models/baseModel";

class GutachtenErsteller extends BaseModel {
  constructor() {
    super("gutachten_ersteller");
  }
}

export default GutachtenErsteller;
