"use strict";

import foerderorgviewTemplate from "./foerderorg.element.view.jade";


class FoerderorgViewController {
  /*@ngInject*/
  constructor($scope, $q, $compile, $element, FoerderorgTyp, Foerderorganisation) {
    const promises = [];
    this.selectSettings = {
      smartButtonTextConverter: text => text
    };

    // Förderorganisationsstruktur laden
    this.foerderorgTypen = [];
    promises.push(FoerderorgTyp.getList());
    this.initializing = true;

    if (angular.isString(this.model)) {
      // mit PK initialisiert
      promises.push(Foerderorganisation.one(this.model).get());
    } else if (angular.isArray(this.model) && this.model.length > 0) {
      // mit Förderorg-Hierarchie initialisiert
      const model = angular.copy(this.model[0]);
      model.foerderorghierarchie = this.model;
      const defer = $q.defer();
      defer.resolve(model);
      promises.push(defer.promise);
    } else if (angular.isObject(this.model)) {
      // mit Förderorg-Objekt initialisiert
      const defer = $q.defer();
      defer.resolve(this.model);
      promises.push(defer.promise);
    }
    $scope.$watch(() => {
      if (angular.isObject(this.model)) {
        return this.model.pk;
      }
      return this.model;
    }, () => {
      if (this.initializing) {
        return;
      }
      if (angular.isString(this.model)) {
        this.loading = true;
        Foerderorganisation.one(this.model).get().then(
          response => this.updateSelection(response)
        ).finally(() => this.loading = false);
      } else if (angular.isArray(this.model) && this.model.length > 0) {
        const model = angular.copy(this.model[0]);
        model.foerderorghierarchie = this.model;
        this.updateSelection(model);
      } else if (angular.isObject(this.model)) {
        this.updateSelection(this.model);
      }
    });

    this.loading = true;
    $q.all(promises).then(
      responses => {
        const tmp = {};
        angular.forEach(responses[0], item => {
          tmp[item.ot_pos] = tmp[item.ot_pos] || { pos: item.ot_pos, typen: [], bez: "", data: null, selected: [] };
          tmp[item.ot_pos].typen.push(item);
        });
        angular.forEach(tmp, item => {
          item.typen.sort((a, b) => a.ot_kbez.localeCompare(b.ot_kbez));
          item.bez = item.typen[0].ot_kbez;
          item.filter = { typ: [item.typen[0].pk] };
          for (let i = 1; i < item.typen.length; i++) {
            item.bez = `${ item.bez } / ${ item.typen[i].ot_kbez }`;
            item.filter.typ.push(item.typen[i].pk);
          }
          this.foerderorgTypen.push(item);
        });
        this.foerderorgTypen.sort((a, b) => a.ot_pos - b.ot_pos);
        // Mit Selektion initialisiert
        if (responses.length > 1) {
          this.updateSelection(responses[1]);
        }
        this.initializing = false;
      }
    ).finally(() => this.loading = false);
  }

  updateSelection(org) {
    let orgs = [];
    if (org.foerderorghierarchie) {
      orgs = org.foerderorghierarchie.slice().reverse();
    }
    angular.forEach(this.foerderorgTypen, fitem => {
      const org = orgs.find(o => fitem.typen.findIndex(v => v.pk === o.o_otid) !== -1);
      if (org) {
        fitem.selected = org;
        const filtered = fitem.typen.filter(
          t => orgs.findIndex(o => t.pk === (o.typ || o.o_otid)) !== -1
        ).map(t => t.ot_kbez);
        if (filtered.length > 0) {
          fitem.bez = filtered.join(" / ");
        } else {
          fitem.bez = "Unbekannt";
        }
      } else {
        fitem.selected = undefined;
      }
    });
  }
}

export default {
  template: foerderorgviewTemplate(),
  controller: FoerderorgViewController,
  controllerAs: "vm",
  bindings: {
    model: "=ngModel",
    startTabindex: "<tabindexStart"
  }
};
