"use strict";
import {
  cloneDeep,
} from "lodash-es";

class BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext, antragregel, regeldata) {
    this.antragregel = antragregel;
    this.regeldata = regeldata;
    this.fields = {};
    this.kontext = kontext;
    if (!this.kontext) {
      this.kontext = "antrag";
    }
    this.component_name = undefined;
    this.name = undefined;
  }

  get_template() {
    return this.templates[this.kontext];
  }

  get_fields() {
    return cloneDeep(this.fields[this.kontext]);
  }

  get_labelmapping() {
    return {};
  }
}

export default BetragsrechnerBase;
