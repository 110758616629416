import AnalysenStatus from "../../Analysen/AnalysenStatus/AnalysenStatus.vue";
import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import AufgabeBox from "../../Aufgabe/AufgabeBox/AufgabeBox.vue";
import AuszahlungListInVertrag from "../../Auszahlung/AuszahlungListInVertrag/AuszahlungListInVertrag.vue";
import AuszahlungPlanListInVertrag from "../../Auszahlung/AuszahlungPlanListInVertrag/AuszahlungPlanListInVertrag.vue";
import BelegeListInVertrag from "../../Belege/BelegeListInVertrag/BelegeListInVertrag.vue";
import BuchungenList from "../../Buchungen/BuchungenList/BuchungenList.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import CsvDownload from "../../../global/components/CsvDownload/CsvDownload.vue";
import DetailsInfoBox from "../../DetailsInfoBox/DetailsInfoBox.vue";
import DokumentDownload from "../../Dokumente/DokumentDownload/DokumentDownload.vue";
import FoerderorgAnsprechpartnerAddBtn from "../../Foerderorganisation/FoerderorgAnsprechpartnerAddBtn/FoerderorgAnsprechpartnerAddBtn.vue";
import FormstepDetail from "../../../global/components/FormstepDetail/FormstepDetail.vue";
import FormularCreate from "../../Formulare/FormularCreate/FormularCreate.vue";
import GeschaeftsregelModul from "../../Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";
import ManuelleAufgabenBox from "../../Aufgabe/ManuelleAufgaben/ManuelleAufgabenBox/ManuelleAufgabenBox.vue";
import ManuelleAufgabeButtonModalCreate from "../../Aufgabe/ManuelleAufgaben/ManuelleAufgabeButtonModalCreate/ManuelleAufgabeButtonModalCreate.vue";
import NachrichtenObjectListBox from "../../Nachrichten/NachrichtenObjectListBox/NachrichtenObjectListBox.vue";
import NotizenBox from "../../Notizen/NotizenBox/NotizenBox.vue";
import NotizenIcon from "../../Notizen/NotizenIcon/NotizenIcon.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PersonalkostenPeriodenTableInVertrag from "../../KF/PersonalkostenPerioden/PersonalkostenPeriodenTableInVertrag/PersonalkostenPeriodenTableInVertrag.vue";
import PuxAlert from "../../../global/components/PuxAlert/PuxAlert.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxDropdown from "../../../global/components/PuxDropdown/PuxDropdown.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import RechtsformdokumenteList from "../../Dokumente/RechtsformdokumenteList/RechtsformdokumenteList.vue";
import SnapshotsBox from "../../Snapshot/SnapshotsBox/SnapshotsBox.vue";
import TaskLogBox from "../../../global/components/TaskLogBox/TaskLogBox.vue";
import TemplateRendering from "../../Templates/TemplateRendering/TemplateRendering.vue";
import Timeline from "../../Timeline/Timeline.vue";
import VertragAbschlag from "./VertragAbschlag/VertragAbschlag.vue";
import VertragAuflagen from "./VertragAuflagen/VertragAuflagen.vue";
import VertragAuszahlungsplanAufgabe from "./VertragAuszahlungsplanAufgabe/VertragAuszahlungsplanAufgabe.vue";
import VertragBankverbindung from "./VertragBankverbindung/VertragBankverbindung.vue";
import VertragDocuments from "./VertragDocuments/VertragDocuments.vue";
import VertragDurchfuehrungszeitraum from "./VertragDurchfuehrungszeitraum/VertragDurchfuehrungszeitraum.vue";
import VertragEingereichtMeldung from "./VertragEingereichtMeldung/VertragEingereichtMeldung.vue";
import VertragErrors from "./VertragErrors/VertragErrors.vue";
import VertragGoToBox from "./VertragGoToBox/VertragGoToBox.vue";
import VertragMessageBox from "./VertragMessageBox/VertragMessageBox.vue";
import VertragOverview from "./VertragOverview/VertragOverview.vue";
import VertragsKonto from "../VertragsKonto/VertragsKonto.vue";
import VertragValidate from "./VertragValidate/VertragValidate.vue";
import VertragWizardEinreichen from "./VertragWizardEinreichen/VertragWizardEinreichen.vue";
import VertragWizardFoerdervertragsbestaetigung from "./VertragWizardFoerdervertragsbestaetigung/VertragWizardFoerdervertragsbestaetigung.vue";
import Vertretungsberechtigte from "../../Vertretungsberechtigte/ObjectVertretungsberechtigte/Vertretungsberechtigte.vue";
import WFStatusGruppen from "../../Workflows/StatusGruppen/StatusGruppen.vue";
import WorkflowFolgen from "../../Workflowfolgen/WorkflowFolgen/WorkflowFolgen.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";
import {
  CheckPermissionsSyncMixin,
  HasOneOfPermissions,
  CurrentUserMixin,
} from "../../../global/mixins/PermissionMixin";
import {
  FilterSpacedMixin,
} from "../../../global/mixins/FiltersMixin";

import SaveCallbackActions from "../../../const/SaveCallbackActions";
import {
  EventBus,
} from "../../../global/functions/event-bus";
import { createNamespacedHelpers } from "vuex";
import {
  cloneDeep,
  concat,
  filter,
  find,
  findIndex,
  forEach,
  get,
  isEmpty,
  isString,
  minBy,
  orderBy,
  size,
  sortBy,
  trim,
} from "lodash-es";


const {
  mapMutations,
  mapState,
} = createNamespacedHelpers("snapshot");
const AKTUELLE_AUFGABE = {
  vertrag_in_erstellung: "3e5ce8f4-b44d-45d1-b0a5-95f0b4250389",
  vertrag_auf_postweg: "62ff76b4-9f6b-4301-b7f7-4e526aa257b9",
};

// @vue/component
export default {
  name: "VertragDetails",
  components: {
    AnalysenStatus,
    AngularLink,
    AufgabeBox,
    AuszahlungListInVertrag,
    AuszahlungPlanListInVertrag,
    BelegeListInVertrag,
    BuchungenList,
    ContextPanel,
    CsvDownload,
    DetailsInfoBox,
    DokumentDownload,
    FoerderorgAnsprechpartnerAddBtn,
    FormstepDetail,
    FormularCreate,
    GeschaeftsregelModul,
    ManuelleAufgabenBox,
    ManuelleAufgabeButtonModalCreate,
    NachrichtenObjectListBox,
    NotizenBox,
    NotizenIcon,
    Permission,
    PersonalkostenPeriodenTableInVertrag,
    PuxAlert,
    PuxButton,
    PuxDropdown,
    PuxPageDetails,
    PuxTranslate,
    RechtsformdokumenteList,
    SnapshotsBox,
    TaskLogBox,
    TemplateRendering,
    Timeline,
    VertragAbschlag,
    VertragAuflagen,
    VertragAuszahlungsplanAufgabe,
    VertragBankverbindung,
    VertragDocuments,
    VertragDurchfuehrungszeitraum,
    VertragEingereichtMeldung,
    VertragErrors,
    VertragGoToBox,
    VertragMessageBox,
    VertragOverview,
    VertragsKonto,
    VertragValidate,
    VertragWizardEinreichen,
    VertragWizardFoerdervertragsbestaetigung,
    Vertretungsberechtigte,
    WFStatusGruppen,
    WorkflowFolgen,
  },
  mixins: [
    CheckPermissionsSyncMixin,
    FilterSpacedMixin,
    HasOneOfPermissions,
    HttpMixin,
    CurrentUserMixin,
    SyConfigMixin,
  ],
  provide() {
    return {
      updateAnsprechpartner: this.reloadVertrag,
    };
  },
  data() {
    return {
      ast: undefined,
      auszahlungen: [],
      auszahlungenLastChanged: undefined,
      auszahlungsplan: [],
      auszahlungsplanLastChanged: undefined,
      canCreateBeleg: false,
      canCreateBelegListe: false,
      canUpdateBeleg: false,
      canDeleteBeleg: false,
      canAblehnenBeleg: false,
      canAnerkennenBeleg: false,
      canAntragAnsprechpartnerAendern: false,
      clientFunktion: undefined,
      currentWorkflowfolge: undefined,
      einreichenStatus: false,
      einreichenWizardModule: [],
      isLoading: true,
      konto: undefined,
      lastWfExecution: new Date().toISOString(),
      loadAuszahlungsplanWorkflow: false,
      modules: [],
      manuelleAufgaben: [],
      reload: false,
      rubricsMappingWithModules: [],
      primaryAuszahlungsplan: undefined,
      snapshot: undefined,
      snapshotActivePk: undefined,
      validationErrors: undefined,
      validationErrorsLoading: undefined,
      vertrag: {},
      vertragspersonalstellen: [],
      viewName: "details",
      vertraegeUrl: "vertraege/",
      antraegeUrl: "foerderantraege/",
    };
  },
  computed: {
    vertragUrl() {
      return `${ this.vertraegeUrl }${ this.$stateParams.id }/`;
    },

    antragUrl() {
      return `${ this.antraegeUrl }${ this.vertrag.antrag }/`;
    },

    auszahlungenUrl() {
      return `${ this.vertragUrl }auszahlungen/`;
    },

    auszahlungsplanUrl() {
      return `${ this.vertragUrl }auszahlungsplaene/`;
    },

    firstAuszahlungsplanUrl() {
      return `${ this.auszahlungsplanUrl }${ this.firstAuszahlungsplan.pk }/`;
    },

    vertragspersonalstellenUrl() {
      return `${ this.vertragUrl }vertragspersonalstellen/`;
    },

    vertragModuleUrl() {
      return `${ this.vertragUrl }module/`;
    },

    vertragManuelleAufgabenUrl() {
      return `${ this.vertragUrl }manuelle_aufgaben/`;
    },

    vertragValidierenUrl() {
      return `${ this.vertragUrl }validieren/`;
    },

    antragstellerorganisationUrl() {
      return `antragstellerorganisationen/${ this.antragstellerPk }/`;
    },

    antragstellerPk() {
      return get(this.vertrag, "antragstellerorg.pk");
    },

    foerdervertragsbestaetigungLocal() {
      return cloneDeep(this.vertrag.foerdervertragsbestaetigung);
    },

    timelineCsvUrl() {
      return `${ this.vertragUrl }timeline/`;
    },

    timelineFullCsvUrl() {
      return `${ this.vertragUrl }timeline_full/`;
    },

    checkMyPermissionsUrl() {
      return `${ this.vertragUrl }check_my_permissions/`;
    },

    checkFibuPermissionUrl() {
      return `${ this.vertragUrl }check_fibu_permission/`;
    },

    checkMyPermissionsAntragUrl() {
      return `${ this.antragUrl }check_my_permissions/`;
    },

    vertragKontoUrl() {
      return `${ this.vertragUrl }konto/`;
    },

    vertragDhwVerwendungsnachweisDokumentUrl() {
      return `${ this.vertragUrl }dhw_verwendungsnachweisdokument/`;
    },

    pageTitleExtra() {
      return {
        vertragsnr: this.filterSpaced(this.vertrag.v_nr, true),
      };
    },

    extraTranslateVertragsnummer() {
      return {
        vertragsnr: this.vertragNummerFiltered,
      };
    },

    vertragNummerFiltered() {
      return this.filterSpaced(this.vertrag.v_nr);
    },

    anzeigekontexteVertrag() {
      return get(this.vertrag, "aktuelle_aufgabe.au_kontext_config.markiert.vertrag") || {};
    },

    anzeigekontexteVertragOffen() {
      return get(this.vertrag, "aktuelle_aufgabe.au_kontext_config.offen.vertrag") || {};
    },

    hasWFPermissionVertragModuleUpdate() {
      return this.checkPermissionsSync({
        perm: "vertrag.module.update",
        permArray: this.vertrag.user_permissions,
      });
    },

    hasPermissionAufgabeCreate() {
      return this.checkPermissionsSync({
        perm: "aufgabe.create",
      });
    },

    hasPermissionAuszahlungView() {
      return this.checkPermissionsSync({
        perm: "auszahlung.view",
      });
    },

    hasWFPermissionVertragskostenpersonalstellenView() {
      return this.checkPermissionsSync({
        perm: "vertragskostenpersonalstellen.view",
        permArray: this.vertrag.user_permissions,
      });
    },

    hasWFPermissionVertragModuleView() {
      return this.checkPermissionsSync({
        perm: "vertrag.module.view",
        permArray: this.vertrag.user_permissions,
      });
    },

    hasWFPermissionVertragskostenpositionsbelegeView() {
      return this.checkPermissionsSync({
        perm: "vertragskostenpositionsbelege.view",
        permArray: this.vertrag.user_permissions,
      });
    },

    hasWFPermissionDhwVerwendungsnachweisdokumentView() {
      return this.checkPermissionsSync({
        perm: "dhw_verwendungsnachweisdokument.view",
        permArray: this.vertrag.user_permissions,
      });
    },

    hasWFPermissionVertragValidieren() {
      return this.checkPermissionsSync({
        perm: "vertrag.validieren",
        permArray: this.vertrag.user_permissions,
      });
    },

    hasWFPermissionVertragDokumentView() {
      return this.checkPermissionsSync({
        perm: "vertrag.dokument.view",
        permArray: this.vertrag.user_permissions,
      });
    },

    hasWFPermissionDokumentView() {
      return this.checkPermissionsSync({
        perm: "dokument.view",
        permArray: this.vertrag.user_permissions,
      });
    },

    canViewVertretungsberechtigte() {
      return this.checkPermissionsSync({
        perm: "astorgvertreter.view",
        permArray: this.ast.user_permissions,
      });
    },

    hasPermissionVertragVersionenManuellErstellen() {
      return this.checkPermissionsSync({
        perm: "vertrag.versionen_manuell_erstellen",
      });
    },

    wizardPks() {
      return this.wizardConfig.ausfuellen_wizard_auto || ["3e5ce8f4-b44d-45d1-b0a5-95f0b4250389"];
    },

    wizardPksButton() {
      return this.wizardConfig.ausfuellen_wizard_button || ["3e5ce8f4-b44d-45d1-b0a5-95f0b4250389", "648bfbb3-a479-4296-9cde-2d3af0f16824"];
    },

    wizardEinreichenPks() {
      return this.wizardConfig.einreichen_wizard_auto || ["43a8e87b-9bb6-4848-874b-a2359eb2842e"];
    },

    wizardEinreichenPksButton() {
      return this.wizardConfig.einreichen_wizard_button || ["43a8e87b-9bb6-4848-874b-a2359eb2842e"];
    },

    wizardEinreichenRegeln() {
      return this.wizardConfig.einreichen_wizard_regeln || [];
    },

    wizardConfig() {
      return this.getSyConfigsValue("vertrag_wizards", {});
    },

    vertragAktuelleAufgabe() {
      return this.vertrag.aktuelle_aufgabe || {};
    },

    firstAuszahlungsplan() {
      return minBy(this.auszahlungsplan, "zp_nummer");
    },

    canAdminAuszahlungsplan() {
      return !!(this.primaryAuszahlungsplan && this.hasOnePermissions({
        perm: [
          "auszahlungsplan.berechnungsmodus",
          "auszahlungsplan.fristen.update",
          "auszahlungsplan.oeffnen",
        ],
        permArray: this.primaryAuszahlungsplan.user_permissions,
      }));
    },

    belegSichtbarkeitSyConfig() {
      return this.getSyConfigsValue("belege_sichtbarkeit", {});
    },

    canViewBelege() {
      if (this.hasWFPermissionVertragskostenpositionsbelegeView &&
        this.belegSichtbarkeitSyConfig.astorgrollen &&
        this.belegSichtbarkeitSyConfig.astorgangebote &&
        this.astorgKontext) {
        return this.isAstorgrollenInAstorgKontextRollenNotFound || this.vertragAstorgAngeboteNotFound;
      }
      return false;
    },

    canViewBetrag() {
      return this.showVertragAusgezahlt && this.canViewBelege;
    },

    isAstorgrollenInAstorgKontextRollenNotFound() {
      const ASTORGROLLEN_INDEX = findIndex(this.belegSichtbarkeitSyConfig.astorgrollen, r => this.astorgKontextRollen.indexOf(r) !== -1);
      return ASTORGROLLEN_INDEX === -1;
    },

    vertragAstorgAngeboteNotFound() {
      const ASTORGANGEBOTE_INDEX = this.belegSichtbarkeitSyConfig.astorgangebote.findIndex(foe_id => foe_id === this.vertrag.foe_id);
      return ASTORGANGEBOTE_INDEX === -1;
    },

    astorgKontextRollen() {
      return this.astorgKontext.rollen || [];
    },

    astorgKontext() {
      const KONTEXTE = get(this.me, "kontexte") || [];
      return find(KONTEXTE, k => k.org_id === this.antragstellerPk) || {};
    },

    showVertragsbeleg() {
      return this.instancePagesSyConfig.vertragsbeleg;
    },

    showVertragskonto() {
      return this.instancePagesSyConfig.vertragskonto;
    },

    showVertragAusgezahlt() {
      return this.instancePagesSyConfig.vertrag_ausgezahlt;
    },

    instancePagesSyConfig() {
      return this.getSyConfigsValue("instance_pages", {});
    },

    isAntragstellerGesperrt() {
      return get(this.vertrag, "antragstellerorg.ast_gesperrt") === true;
    },

    isWFStatusGruppenInHeader() {
      const WF_STATUS_GRUPPEN_IN_HEADER = this.getSyConfigsValue("wf_statusgruppen_in_header", {});
      WF_STATUS_GRUPPEN_IN_HEADER.objects = WF_STATUS_GRUPPEN_IN_HEADER.objects || [];
      return WF_STATUS_GRUPPEN_IN_HEADER.objects.indexOf("vertrag") !== -1;
    },

    aktuelleAufgabeTaskId() {
      return get(this.vertrag, "aktuelle_aufgabe.task_id");
    },

    stepsFiltered() {
      return filter(this.stepsForNotizen, item => {
        return !(item.visible && !this[item.visible]);
      });
    },

    stepsForNotizen() {
      return [
        this.rubricsMapping.ubersicht,
        this.rubricsMapping.foerdervertrag,
        this.rubricsMapping.personalbaum,
        this.rubricsMapping.auszahlung,
        this.rubricsMapping.belegliste,
        ...this.rubricsMappingWithModules,
      ];
    },

    rubricsMapping() {
      return {
        ubersicht: {
          id: "step_ubersicht",
          kbez: "_TXT_VERTRAG_DETAILS_RUBRIK_UEBERSICHT_",
          key: "step_ubersicht",
          prio: 0,
        },
        foerdervertrag: {
          id: "fvb",
          key: "step_foerdervertrag",
          kbez: "_TXT_VERTRAG_DETAILS_FOERDERVERTRAG_BESTAETIGUNG_",
          visible: "isFoerdervertragRubrikVisible",
          prio: 1,
        },
        personalbaum: {
          id: "personalbaum",
          key: "step_personalbaum",
          kbez: "_TXT_VERTRAG_DETAILS_PERSONALBAUM_HEADER_CONTEXT_",
          visible: "isPersonalbaumRubrikVisible",
          prio: 2,
        },
        auszahlung: {
          id: "step_auszahlung",
          key: "step_auszahlung",
          kbez: "_TXT_VERTRAG_DETAILS_RUBRIK_AUSZAHLUNG_",
          visible: "isAuszahlungRubrikVisible",
          prio: 3,
        },
        belegliste: {
          id: "step_belegliste",
          key: "step_belegliste",
          kbez: "_TXT_VERTRAG_DETAILS_RUBRIK_BELEGLISTE_",
          visible: "isBeleglisteRubrikVisible",
          prio: 4,
        },
      };
    },

    rubricsMappingAll() {
      return concat(this.rubricsMapping, this.rubricsMappingWithModules);
    },

    workflowfolgeUpdateKey() {
      return `${ this.lastWfExecution }`;
    },

    isWeitereAktionenVisible() {
      return this.vertragFoerdervertragsbestaetigungDokUrl ||
        this.hasPermissionAufgabeCreate ||
        this.isShowWizardVertragsbestaetigungButton ||
        this.isShowWizardEinreichenButton ||
        this.hasWFPermissionDhwVerwendungsnachweisdokumentView ||
        this.hasWFPermissionVertragValidieren ||
        this.hasPermissionVertragVersionenManuellErstellen;
    },

    vertragFoerdervertragsbestaetigungDokUrl() {
      return get(this.vertrag, "foerdervertragsbestaetigung.dok_url");
    },

    isAbschlagVisible() {
      return !!get(this.vertrag, "foerdervertragsbestaetigung.abschlag_moeglich");
    },

    isShowWizardVertragsbestaetigungButton() {
      return this.vertragAktuelleAufgabeAufgabe && this.wizardPksButton.indexOf(this.vertragAktuelleAufgabeAufgabe) !== -1;
    },

    vertragAktuelleAufgabeAufgabe() {
      return get(this.vertrag, "aktuelle_aufgabe.aufgabe");
    },

    isShowWizardEinreichenButton() {
      return this.vertragAktuelleAufgabeAufgabe && this.wizardEinreichenPksButton.indexOf(this.vertragAktuelleAufgabeAufgabe) !== -1;
    },

    astAlsButtonConfig() {
      return this.getSyConfigsValue("ast_als_button", {});
    },

    isBtnChangeViewNameVisible() {
      return this.isBtnChangeViewNameDetailsVisible ||
        this.isBtnChangeViewNameTimelineVisible;
    },

    isBtnChangeViewNameDetailsVisible() {
      return this.viewName === "buchungen" ||
        this.viewName === "timeline";
    },

    isBtnChangeViewNameTimelineVisible() {
      return this.viewName === "details";
    },

    iconBtnChangeViewName() {
      return this.isBtnChangeViewNameDetailsVisible ?
        "vertrag" :
        "time";
    },

    textBtnChangeViewName() {
      return this.isBtnChangeViewNameDetailsVisible ?
        "_BTN_VERTRAG_ANZEIGEN_" :
        "_BTN_VERLAUF_ANZEIGEN_";
    },

    vertragAktuelleAufgabeBez() {
      return get(this.vertrag, "aktuelle_aufgabe.bez");
    },

    isFoerdervertragRubrikVisible() {
      return this.$Client !== "dias-kumasta";
    },

    isPersonalbaumRubrikVisible() {
      return this.vertragspersonalstellen.length > 0;
    },

    isAuszahlungRubrikVisible() {
      return this.auszahlungsplan.length > 0;
    },

    isAuszahlungPlanListInVertrag() {
      return this.vertrag.auszahlungserstellungsmodus === "modalitaet" || this.canAdminAuszahlungsplan;
    },

    isBeleglisteRubrikVisible() {
      return this.showVertragsbeleg && this.canViewBelege;
    },

    isBankverbindungVisible() {
      return !!(this.foerdervertragsbestaetigungLocal.bv_gefordert || this.foerdervertragsbestaetigungLocal.abschlag_moeglich);
    },

    rubricsWithModules() {
      if (!this.modules.length) {
        return {};
      }
      const RUBRICS = {};
      let _modules = this.modules;
      if (!this.hasWFPermissionDokumentView) {
        _modules = filter(this.modules, module => module.modulname !== "anlagen_allgemein");
      }
      forEach(cloneDeep(_modules), module => {
        const ANZEIGEKONTEXT = get(module, "regel.anzeigekontext_obj");
        const ANZEIGEKONTEXT_PK = ANZEIGEKONTEXT.pk;
        RUBRICS[ANZEIGEKONTEXT_PK] = RUBRICS[ANZEIGEKONTEXT_PK] || {
          anzeigekontext: ANZEIGEKONTEXT,
          modules: [],
        };
        RUBRICS[ANZEIGEKONTEXT_PK].modules.push(module);
      });
      forEach(RUBRICS, rubric => {
        rubric.modules = sortBy(rubric.modules, modul => modul.regel.afr_pos);
      });
      return RUBRICS;
    },

    timelineConfig() {
      return {
        config: {
          kontexte: [
            {
              titel: "_TXT_VERTRAG_VERTRAGSERTELLUNG_",
              key: "A",
            },
            {
              titel: "_TXT_VERTRAG_VERTRAGSPRUEFUNG_",
              key: "F",
            },
          ],
          benachrichtigungen: {
            bezug: "vertrag",
            bezugpk: this.vertrag.pk,
            autorefresh: true,
          },
          url: this.timelineCsvUrl,
        },
        bisection: true,
      };
    },

    aktuelleAufgabeWorkflowtyp() {
      return get(this.vertrag, "aktuelle_aufgabe.workflowstatusgruppe.workflowtyp");
    },

    errorModules() {
      return {
        vertretungsberechtigte: "_TXT_VERTRETUNGSBERECHTIGTE_",
        bankverbindung: "_TXT_BANKVERBINDUNG_",
      };
    },

    rubricsIdsMapping() {
      const RUBRICS_IDS_MAPPING = {
        ubersicht: [],
        fvb: [
          "vertrag_auflagen",
          "vertrag_documents",
          "vertrag_durchfuehrungszeitraum",
          "vertrag_abschlag",
          "vertrag_bankverbindung",
          "vertrag_vertretungsberechtigte",
        ],
        personalbaum: [],
        auszahlung: [],
        belegliste: [],
      };
      forEach(this.rubricsWithModules, rubric => {
        RUBRICS_IDS_MAPPING[rubric.anzeigekontext.pk] = [];
        forEach(rubric.modules, module => {
          RUBRICS_IDS_MAPPING[rubric.anzeigekontext.pk].push(module.regel.regel);
        });
      });
      return RUBRICS_IDS_MAPPING;
    },

    isSnapshot() {
      return !!this.snapshot;
    },

    diffsForRubrics() {
      const DIFFS = {};
      if (this.isSnapshot && !isEmpty(this.STATUS_DIFF_FOR_REGELS)) {
        forEach(this.stepsFiltered, step => {
          if (this.rubricsIdsMapping[step.id]) {
            forEach(this.rubricsIdsMapping[step.id], id => {
              if (this.STATUS_DIFF_FOR_REGELS[id]) {
                DIFFS[step.key] = true;
                return false;
              }
            });
          }
        });
      }
      return DIFFS;
    },

    moduleSnapshot() {
      if (this.isSnapshot) {
        return {
          osn_snapshot_datum: this.snapshot.osn_snapshot_datum,
          data: get(this.snapshot, "osn_snapshot.regeln") || {},
          osn_snapshot: get(this.snapshot, "osn_snapshot") || {},
        };
      }
      return undefined;
    },

    ...mapState([
      "STATUS_DIFF_FOR_REGELS",
    ]),
  },
  created() {
    this.reloadVertrag({ statusCheckWizard: true }).then(
      () => this.openAufgabenKontextPanel()
    );
  },
  beforeUnmount() {
    this.MUT_RESET_STATUS_DIFF();
  },
  methods: {
    reloadVertrag({ setEinreichenStatus, statusCheckWizard, wfo_reload } = {}) {
      this.scrollTop();
      this.isLoading = true;
      this.reload = true;
      this.canCreateBeleg = false;
      const PROMISE = new Promise((resolve, reject) => {
        this.getHttp({
          url: this.vertragUrl,
        }).then(
          response => {
            this.setEinreichenStatus({ setEinreichenStatus, vertragAlt: this.vertrag, vertragNew: response });
            this.vertrag = response;
            this.checkPermissionCreateBeleg();
            this.checkPermissionAntragAnsprechpartnerAendern();
            if (statusCheckWizard) {
              this.checkVertragWizard();
              this.checkVertragEinreichenWizard();
            }
            const PROMISES = [];
            if (this.hasPermissionAuszahlungView) {
              PROMISES.push(this.loadAuszahlungen());
              PROMISES.push(this.loadAuszahlungsplan());
            }
            if (this.hasWFPermissionVertragskostenpersonalstellenView) {
              PROMISES.push(this.loadVertragspersonalstellen());
            }
            PROMISES.push(this.loadAntragstellerorg());
            PROMISES.push(this.loadVertragsmodule({ wfo_reload }));
            this.loadKonto();
            this.loadAllPromises({ promises: PROMISES }).then(
              () => {},
              () => reject()
            ).finally(() => {
              this.isLoading = false;
              resolve;
            });
          }
        );
      });
      return PROMISE;
    },

    setEinreichenStatus({ setEinreichenStatus, vertragAlt, vertragNew }) {
      if (!setEinreichenStatus) {
        return;
      }
      const AKTUELLE_AUFGABE_ALT = get(vertragAlt, "aktuelle_aufgabe.aufgabe");
      const AKTUELLE_AUFGABE_NEW = get(vertragNew, "aktuelle_aufgabe.aufgabe");
      if (AKTUELLE_AUFGABE_ALT === AKTUELLE_AUFGABE.vertrag_in_erstellung &&
        AKTUELLE_AUFGABE_NEW === AKTUELLE_AUFGABE.vertrag_auf_postweg) {
        this.einreichenStatus = true;
      }
    },

    checkPermissionCreateBeleg() {
      this.canCreateBeleg = false;
      this.canCreateBelegListe = false;
      this.canUpdateBeleg = false;
      this.canDeleteBeleg = false;
      this.canAblehnenBeleg = false;
      this.canAnerkennenBeleg = false;
      this.getHttp({
        url: this.checkMyPermissionsUrl,
        urlParams: {
          permissions: [
            "vertragskostenpositionsbelege.create",
            "vertragskostenpositionsbelege.create.liste",
            "vertragskostenpositionsbelege.update",
            "vertragskostenpositionsbelege.delete",
            "vertragskostenpositionsbelege.ablehnen",
            "vertragskostenpositionsbelege.anerkennen",
          ],
        },
      }).then(
        response => {
          this.canCreateBeleg = response.indexOf("vertragskostenpositionsbelege.create") !== -1;
          this.canCreateBelegListe = response.indexOf("vertragskostenpositionsbelege.create.liste") !== -1;
          this.canUpdateBeleg = response.indexOf("vertragskostenpositionsbelege.update") !== -1;
          this.canDeleteBeleg = response.indexOf("vertragskostenpositionsbelege.delete") !== -1;
          this.canAblehnenBeleg = response.indexOf("vertragskostenpositionsbelege.ablehnen") !== -1;
          this.canAnerkennenBeleg = response.indexOf("vertragskostenpositionsbelege.anerkennen") !== -1;
        }
      );
    },

    checkPermissionAntragAnsprechpartnerAendern() {
      this.canAntragAnsprechpartnerAendern = false;
      this.getHttp({
        url: this.checkMyPermissionsAntragUrl,
        urlParams: {
          permissions: [
            "foerderantrag.ansprechpartner.update"
          ],
        },
      }).then(
        response => {
          this.canAntragAnsprechpartnerAendern = response.length > 0;
        }
      );
    },

    checkVertragWizard() {
      if (this.vertragAktuelleAufgabe.aufgabe && this.wizardPks.indexOf(this.vertragAktuelleAufgabe.aufgabe) !== -1) {
        this.viewName = "wizard";
      }
    },

    checkVertragEinreichenWizard() {
      if (this.vertragAktuelleAufgabe.aufgabe && this.wizardEinreichenPks.indexOf(this.vertragAktuelleAufgabe.aufgabe) !== -1) {
        this.viewName = "wizard-einreichen";
      }
    },

    loadAuszahlungen() {
      return this.getListHttp({
        url: this.auszahlungenUrl,
      }).then(
        response => {
          this.auszahlungen = response;
          this.auszahlungenLastChanged = new Date().getTime();
        }
      );
    },

    loadAuszahlungsplan() {
      return this.getListHttp({
        url: this.auszahlungsplanUrl,
      }).then(
        response => {
          this.auszahlungsplan = response;
          this.auszahlungsplanLastChanged = new Date().getTime();
          this.loadCurrentAuszahlungsplanWorkflow();
        }
      );
    },

    loadCurrentAuszahlungsplanWorkflow() {
      if (this.firstAuszahlungsplan) {
        this.loadAuszahlungsplanWorkflow = true;
        this.getHttp({
          url: this.firstAuszahlungsplanUrl,
        }).then(
          response => {
            this.primaryAuszahlungsplan = response;
          },
          () => {
            this.addNotification({
              text: "_MSG_VERTRAG_AUSZAHLUNGSPLAN_WORKFLOW_ERROR_",
              type: "error",
            });
          }
        ).finally(() => this.loadAuszahlungsplanWorkflow = false);
      }
    },

    loadVertragspersonalstellen() {
      return this.getListHttp({
        url: this.vertragspersonalstellenUrl,
      }).then(
        response => {
          this.vertragspersonalstellen = response;
        }
      );
    },

    loadAntragstellerorg() {
      return this.getHttp({
        url: this.antragstellerorganisationUrl,
      }).then(
        response => {
          this.ast = response;
        }
      );
    },

    loadVertragsmodule({ wfo_reload } = {}) {
      if (wfo_reload) {
        EventBus.$emit("reloadAnlagenAllgemein");
      }
      if (!this.hasWFPermissionVertragModuleView) {
        return Promise.resolve();
      }
      return this.getListHttp({
        url: this.vertragModuleUrl,
        urlParams: {
          ordering: "regel__anzeigekontext__faz_pos",
        },
      }).then(
        response => {
          this.modules = response;
          this.rubricsMappingWithModules = this.getRubricsMappingWithModules();
          this.einreichenWizardModule = filter(response, modul => this.wizardEinreichenRegeln.indexOf(modul.regel.regel) !== -1);
        }
      );
    },

    getRubricsMappingWithModules() {
      const RUBRICS_MAPPING_WITH_MODULES = [];
      forEach(this.rubricsWithModules, rubric => {
        const KEY = trim(rubric.anzeigekontext.faz_kbez);
        RUBRICS_MAPPING_WITH_MODULES.push({
          id: rubric.anzeigekontext.pk,
          kbez: rubric.anzeigekontext.faz_bez,
          key: KEY,
          pk: rubric.anzeigekontext.pk,
          prio: rubric.anzeigekontext.faz_pos + 10
        });
      });
      return RUBRICS_MAPPING_WITH_MODULES;
    },

    loadKonto() {
      this.konto = undefined;
      if (this.canViewBelege && this.showVertragsbeleg) {
        this.getHttp({
          url: this.checkFibuPermissionUrl,
        }).then(
          response => {
            if (response.status) {
              this.getHttp({
                url: this.vertragKontoUrl,
              }).then(
                response => {
                  this.konto = response;
                }
              );
            }
          }
        );
      }
    },

    loadAllPromises({ promises }) {
      return Promise.all(promises).then(
        () => {
          this.reload = false;
          this.isLoading = false;
          this.goToPersonalBaum();
        }
      );
    },

    goToPersonalBaum() {
      if (this.$stateParams.panel !== "step_personalbaum") {
        return;
      }
      setTimeout(() => {
        EventBus.$emit("scrollToContextPanel", { id: "personalbaum" });
        this.$locationSearch({});
      });
    },

    showBuchungen() {
      if (this.konto && this.konto.mit_buchungen) {
        this.viewName = "buchungen";
      }
    },

    resetValidation() {
      this.validationErrorsLoading = undefined;
      this.validationErrors = undefined;
    },

    onAufgabeAngenommen() {
      this.reloadVertrag();
    },

    onManuelleAufgabenChanged() {
      this.getListHttp({
        url: this.vertragManuelleAufgabenUrl,
      }).then(
        response => {
          this.manuelleAufgaben = response;
        }
      );
    },

    updateAufgabe(newAufgabe) {
      const VERTRAG = cloneDeep(this.vertrag);
      VERTRAG.aktuelle_aufgabe = Object.assign({}, VERTRAG.aktuelle_aufgabe, newAufgabe);
      this.vertrag = VERTRAG;
    },

    onWorkflowInit(clientFunktion) {
      this.reload = true;
      this.clientFunktion = clientFunktion;
    },

    onWorkflowStarted(newAufgabe, currentWorkflowfolge) {
      this.updateAufgabe(newAufgabe);
      this.currentWorkflowfolge = currentWorkflowfolge;
    },

    onWorkflowFinish() {
      this.reload = false;
      this.clientFunktion = undefined;
      this.currentWorkflowfolge = undefined;
    },

    onWorkflowCanceled() {
      this.reload = false;
      this.clientFunktion = undefined;
    },

    onWorkflowSuccess({ result } = {}) {
      if (this.clientFunktion) {
        this.clientFunktion.onServerSuccess(result);
      }
      this.validationErrors = undefined;
      if (this.viewName === "wizard-einreichen") {
        this.viewName = "details";
      }
      if (!this.openAufgabenKontextPanel()) {
        this.scrollTop();
      }
      if (get(this.currentWorkflowfolge, "wfo_reload")) {
        return this.reloadVertrag({
          setEinreichenStatus: true,
          statusCheckWizard: true,
          wfo_reload: true,
        });
      }
    },

    onWorkflowFailure({ result } = {}) {
      if (this.clientFunktion) {
        this.clientFunktion.onServerError(result);
      }
      if (result.data && result.data.error_data) {
        this.validationErrors = result.data.error_data;
      } else if (result.data && !isString(result.data)) {
        this.validationErrors = result.data;
      } else {
        this.validationErrors = undefined;
      }
      this.scrollTop();
      return this.reloadVertrag();
    },

    scrollTop() {
      $(window).scrollTop(0);
    },

    openWizardVertragsbestaetigung() {
      this.viewName = "wizard";
    },

    openWizardEinreichen() {
      this.viewName = "wizard-einreichen";
    },

    onValidate() {
      this.validationErrorsLoading = true;
      this.getHttp({
        url: this.vertragValidierenUrl,
      }).then(
        response => {
          this.validationErrors = response;
        },
        () => {
          this.addNotification({
            text: "_ERR_VERTRAG_DETAILS_VALIDIEREN_",
            type: "error",
          });
        }
      ).finally(
        () => {
          this.validationErrorsLoading = false;
          this.scrollTop();
        }
      );
    },

    changeViewName() {
      if (this.viewName === "details") {
        this.viewName = "timeline";
      } else {
        this.viewName = "details";
      }
    },

    updateVertrag({ vertrag }) {
      this.vertrag = Object.assign({}, this.vertrag, vertrag);
    },

    saveCallback({ statusSaveCallback } = {}) {
      if (statusSaveCallback === SaveCallbackActions.reloadWf) {
        this.reloadWorkflowfolgen();
      } else if (statusSaveCallback === SaveCallbackActions.reloadAll) {
        this.reloadVertrag();
        this.reloadWorkflowfolgen();
      }
    },

    reloadWorkflowfolgen() {
      this.lastWfExecution = new Date().toISOString();
    },

    updateModule({ response }) {
      const INDEX = findIndex(this.modules, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.modules.splice(INDEX, 1, response);
      }
    },

    closeEinreichenWizard() {
      this.viewName = "details";
      this.openAufgabenKontextPanel();
    },

    updateEinreichenWizardModule({ response }) {
      const INDEX = findIndex(this.einreichenWizardModule, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.einreichenWizardModule.splice(INDEX, 1, response);
      }
    },

    closeWizard() {
      this.viewName = "details";
      this.checkVertragEinreichenWizard();
      this.openAufgabenKontextPanel();
    },

    showErrorModulName(error) {
      if (error && error.anzeigekontext && error.anzeigekontext.key) {
        return this.errorModules[error.anzeigekontext.key];
      }
      if (error && error.pk) {
        return get(this.rubricsWithModules, `${ error.pk }.anzeigekontext.faz_kbez`, "");
      }
    },

    createSnapshot() {
      EventBus.$emit("createSnapshot");
    },

    changeActiveSnapshot({ snapshotLocal } = {}) {
      if (snapshotLocal) {
        this.snapshotActivePk = snapshotLocal.pk;
        this.snapshot = cloneDeep(snapshotLocal);
      } else {
        if (this.snapshot) {
          this.snapshot = undefined;
        }
        this.snapshotActivePk = undefined;
      }
    },

    openAufgabenKontextPanel() {
      const PANELS = orderBy(filter(this.rubricsMapping, rubrik => {
        return this.anzeigekontexteVertragOffen[rubrik.key];
      }), ["prio"]);
      if (size(PANELS) === 0 || this.viewName !== "details") {
        return false;
      }
      setTimeout(() => {
        forEach(PANELS, (panel, idx) => {
          EventBus.$emit("toggleContextPanel", { statusOpen: true, id: panel.id, withoutScroll: true, forceScroll: idx === 0 });
        });
      }, 250);
      return true;
    },

    ...mapMutations([
      "MUT_RESET_STATUS_DIFF",
    ]),
  },
};
