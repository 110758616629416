"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller";

class FoerderregelEditrechteEditController extends BaseEditController {
  /*@ngInject*/
  constructor(
    $q,
    $rootScope,
    $scope,
    ParameterUtils,
    AuthService,
    DiasNotification,
  ) {
    super($q, $scope, DiasNotification);
    this.selected = {};

    angular.forEach(this.model.re_edit_rechte, recht => {
      this.selected[recht] = true;
    });

    this.save = () => {
      const data = [];
      angular.forEach(this.selected, (val, key) => {
        if (val) {
          data.push(key);
        }
      });
      return this.edit({ re_edit_rechte: data });
    };

    this.edit = data => {
      return this.model.customPUT(data).then(
        response => {
          DiasNotification.page.success("Funktionsgruppen erfolgreich gespeichert.");
          response.param_keys = ParameterUtils.get_ordered_keys(response.re_argument);
          $scope.model = $scope.model.merge($scope.model, response);
          return $scope.model;
        },
        // this.onError
        errors => {
          $rootScope.$broadcast(
            ParameterUtils.events.error,
            errors.data
          );
          return this.onErrors(errors, this.getErrorLabels());
        }
      );
    };

    this.getErrorLabels = () => {
      return {
        re_edit_rechte: "Bearbeitungsrechte: ",
      };
    };
  }
}

export default FoerderregelEditrechteEditController;
