"use strict";

import BaseModel from "dias/core/models/baseModel";

class Dokumentpruefstatus extends BaseModel {
  constructor() {
    super("dokumentpruefstatus");
  }
}

export default Dokumentpruefstatus;
