"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";

import models from "../models";

const foerderangeboteModule = angular.module("root.foerderinstrumentvarianten", [
  uiRouter,
  models.name,
  diasTable.name,
  diasNotification.name,
]);

foerderangeboteModule.config($stateProvider => {
  $stateProvider

    // Förderangebot-Liste
    .state("root.foerderinstrumentvarianten", {
      url: `/foerderinstrumentvarianten/?
            user_cookie&
            instrument
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-instrumentvarianten-list></vue-instrumentvarianten-list></div>"
        }
      },
      data: {
        permissions: "foerderinstrument.update"
      }
    })
    // Detail-Seite eines Förderangebots
    .state("root.foerderinstrumentvarianten.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><instrumentvarianten-details></instrumentvarianten-details></div>"
        }
      },
    })
  ;
})
;

export default foerderangeboteModule;
