"use strict";

import template from "./dropdown.multiselect.jade";

class StatusgruppenController {
  /*@ngInject*/
  constructor($filter, $state) {
    this.$filter = $filter;
    this.$state = $state;
    if (!this.settings.smartButtonTextConverter) {
      this.settings.smartButtonTextConverter = angular.noop;
    }
  }

  setSelectedItem(option) {
    if (this.selectedModel && this.selectedModel.pk === option.pk) {
      this.selectedModel = null;
    } else {
      this.selectedModel = option;
    }
    if (this.events && this.events.onSelectionChanged) {
      this.events.onSelectionChanged();
    }
  }

  showButtonName() {
    if (!this.selectedModel) {
      return "Bitte auswählen";
    } 
    return this.settings.smartButtonTextConverter(this.selectedModel);
  }
}

export default {
  template: template(),
  controller: StatusgruppenController,
  controllerAs: "vm",
  bindings: {
    settings: "<",
    selectedModel: "=",
    data: "=",
    events: "="
  }
};
