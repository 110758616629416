"use strict";

import BaseModel from "dias/core/models/baseModel";

class NewsExtern extends BaseModel {
  constructor() {
    super("news_extern");
  }
}

export default NewsExtern;
