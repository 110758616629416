import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "GutachtenErgebnisseBoxLine",
  props: {
    gutachtenErgebnis: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    idForActiveErgebnis: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    classLocal() {
      return [
        this.gutachtenErgebnis.gae_cssklasse,
        {
          active: this.gutachtenErgebnis.pk === this.idForActiveErgebnis,
        },
      ];
    },

    styleLocal() {
      return get(this.group, `[${ this.gutachtenErgebnis.pk }].lineStyle`);
    },
  },
};
