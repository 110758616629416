import AntragCreateExternMixin from "./AntragCreateExternMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "AntragCreateExtern",
  mixins: [
    AntragCreateExternMixin,
  ],
  setup() {
    const {
      filterCurrency,
      filterSpaced,
    } = FiltersAPI();

    return {
      filterCurrency,
      filterSpaced,
    };
  },
  created() {
    this.loadAktiveRegelsatz();
  },
};
