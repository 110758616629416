import ActionsPageMain from "../../../global/components/ActionsPageMain/ActionsPageMain.vue";
import NachrichtenLink from "../NachrichtenLink/NachrichtenLink.vue";
import NutzerschlagwortBadge from "../../Nutzerschlagwort/NutzerschlagwortBadge/NutzerschlagwortBadge.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import {
  FilterDateMixin,
  FilterSpacedMixin,
} from "../../../global/mixins/FiltersMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import {
  find,
  get,
  isArray,
  isNil,
  join,
  map,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "NachrichtenDetails",
  components: {
    ActionsPageMain,
    NachrichtenLink,
    NutzerschlagwortBadge,
    PageTabTitle,
    PuxButton,
    PuxCloak,
    PuxIcon,
    PuxPageDetails,
    PuxTranslate,
  },

  mixins: [
    FilterDateMixin,
    FilterSpacedMixin,
    PermissionMixin,
    HttpMixin,
  ],
  props: {
    baseUrl: {
      type: String,
      default: undefined,
    },
    listUrl: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      data: null,
      loading: true,
      selectedEmpfaenger: null
    };
  },
  computed: {
    hasNutzerschlagworte() {
      return size(get(this.data, "nutzerschlagwortobjekte")) > 0;
    },

    currentNachricht() {
      if (!isNil(this.selectedEmpfaenger)) {
        return find(this.data.empfaenger, el => el.nutzer.pk === this.selectedEmpfaenger);
      } else if (this.multipleEmpfaenger || !isArray(this.data.empfaenger)) {
        return this.data;
      }
      return get(this.data.empfaenger, ["0"]);
    },

    canCreateContextfree() {
      return this.checkPermissionsSync({ perm: "benachrichtigungen.contextfree.create" });
    },

    multipleEmpfaenger() {
      return isArray(this.data.empfaenger) && size(this.data.empfaenger) > 1;
    },

    empfaengerList() {
      if (!isArray(this.data.empfaenger)) {
        return [this.data.empfaenger];
      }
      return map(this.data.empfaenger, el => el.nutzer) || [];
    },

    empfaengerListStr() {
      return join(map(this.empfaengerList, el => el.name), ", ");
    },

    empfaengerObject() {
      if (!isArray(this.data.empfaenger)) {
        return this.data.empfaenger || {};
      }
      return get(this.data.empfaenger, ["0", "nutzer"], {});
    },

    getBezug() {
      if (this.data.objekt.type === "antrag") {
        return { bezug: "antrag", bezugpk: this.data.objekt.a_id };
      }
      if (this.data.objekt.type === "vertrag") {
        return { bezug: "vertrag", bezugpk: this.data.objekt.v_id };
      }
      if (this.data.objekt.type === "antragsteller") {
        return { bezug: "antragsteller", bezugpk: this.data.objekt.ast_id };
      }
      if (this.data.objekt.type === "auszahlung") {
        return { bezug: "auszahlung", bezugpk: this.data.objekt.az_id };
      }
      if (this.data.objekt.type === "sitzung") {
        return { bezug: "sitzung", bezugpk: this.data.objekt.si_id };
      }
      if (this.data.objekt.type === "top") {
        return { bezug: "top", bezugpk: this.data.objekt.tos_id };
      }
      if (this.data.objekt.type === "sammelvertrag") {
        return { bezug: "sammelvertrag", bezugpk: this.data.objekt.sv_id };
      }
      if (this.data.objekt.type === "formular") {
        return { bezug: "formular", bezugpk: this.data.objekt.for_id };
      }
      return {};
    },

    getHrefAntrag() {
      return `/foerderantrag/${ this.data.objekt.a_id }/`;
    },

    getHrefAntragsteller() {
      return `/astorganisationen/${ this.data.objekt.ast_id }/`;
    },

    getHrefSitzung() {
      return `/sitzungen/${ this.data.objekt.si_id }/`;
    },

    getHrefTop() {
      return `/sitzungen/${ this.data.objekt.si_id }/top/${ this.data.objekt.tos_id }/`;
    },

    getHrefVertrag() {
      return `/vertrag/${ this.data.objekt.v_id }/`;
    },

    getHrefAuszahlung() {
      return `/vertrag/${ this.data.objekt.v_id }/auszahlung/${ this.data.objekt.az_id }/`;
    },

    getHrefSammelvertrag() {
      return `/sammelvertraege/${ this.data.objekt.sv_id }/`;
    },

    getHrefGutachten() {
      return `/gutachten/${ this.data.objekt.ga_id }/`;
    },

    getHrefBasisformular() {
      return `/formulare/${ this.data.objekt.for_id }/`;
    },

    getHrefAuszahlungsliste() {
      return `/auszahlungslisten/${ this.data.objekt.for_id }/`;
    },

    getAntragNr() {
      return this.filterSpaced(this.data.objekt.a_nr);
    },

    getVertragNr() {
      return this.filterSpaced(this.data.objekt.v_nr);
    },

    getAuszahlungsNr() {
      return `${ this.filterSpaced(this.data.objekt.v_nr) }-${ this.data.objekt.az_nummer }`;
    },
  },
  created() {
    this.loadNachricht();
  },
  methods: {
    loadNachricht() {
      this.loading = true;
      this.getHttp({
        url: `${ this.baseUrl }${ this.$stateParams.id }/`,
      }).then(
        response => {
          this.data = response;
          setTimeout(() => window.print(), 1);
        }
      ).finally(
        () => {
          this.loading = false;
        }
      );
    },

    print() {
      window.print();
    },

    prefixId(id) {
      return `${ this.data.pk }_${ id }`;
    },
  },
};
