import mock from "../mock.json";
import SmartTable from "../../../../global/components/table/table.vue";
import {
  FilterDateMixin,
} from "../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "StyleguideTableEasy",
  components: {
    SmartTable,
  },
  mixins: [
    FilterDateMixin,
  ],
  data() {
    return {
      currentItem: "demo",
      options: {
        id: "StyleguideTableEasy",
        cols: [
          {
            label: "Titel",
            id: "kbez",
          },
          {
            label: "Gültig ab",
            id: "gueltig_ab",
            templateCallback: ({ row }) => `<span>${ this.filterDate(row.gueltig_ab, "DD.MM.YYYY") }</span>`,
          },
          {
            label: "Gültig bis",
            id: "gueltig_bis",
            templateCallback: ({ row }) => `<span>${ this.filterDate(row.gueltig_bis, "DD.MM.YYYY") }</span>`,
            hide: true,
          },
        ],
        rowActions: [
          {
            label: "Etwas schaffen1",
            callback: row => console.log("row", row),
            titleCallback: row => `${ row.kbez } schaffen`,
          },
          {
            label: "Etwas schaffen2",
            href: row => `/vuestyleguide/${ row.pk }/`,
            titleCallback: row => `${ row.kbez } schaffen`,
          },
        ],
        data: mock,
        label: "Mock Tabelle1",
        sortable: false,
        pagination: false,
      },
    };
  },
};
