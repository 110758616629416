import KFPositionForm from "../KFPositionForm/KFPositionForm.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import translate from "../../../../global/directives/translate";
import {
  FilterCurrencyMixin,
} from "../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "KFPosition",
  components: {
    KFPositionForm,
    PuxButton,
    Modal,
    SnapshotIcon,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    FilterCurrencyMixin,
  ],
  props: {
    idx: {
      type: String,
      required: false,
      default: undefined,
      info: "Die erste Zahl in der Positionsnummer",
    },
    index: {
      type: Number,
      required: true,
      info: "Index in array",
    },
    htmlId: {
      type: String,
      required: true,
      info: "Parent id",
    },
    ariaDescribedbyActual: {
      type: String,
      required: true,
    },
    ariaDescribedbyVersion: {
      type: String,
      required: true,
    },
    position: {
      type: Object,
      required: false,
      default: undefined,
      info: "Object mit Positionsdaten",
    },
    katalogDetailsObj: {
      type: Object,
      required: false,
      default: undefined,
      info: "Object, wenn this.editType === 'no-position-details'",
    },
    readonly: {
      type: Boolean,
      required: true,
      info: "Status, mit dem man nichts bearbeiten darf",
    },
    urlPositionen: {
      type: String,
      required: true,
      info: "save-url für positionen z.B.'finanzierungsarten/.../positionen/'",
    },
    betragsrechner: {
      type: String,
      required: true,
      info: "Name vom Betragsrechner",
    },
    katalogDetails: {
      type: Array,
      required: true,
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
      info: "{ regel, regeldata, modelParameter }",
    },
    extraForVorschau: {
      type: Object,
      required: false,
      default: undefined,
      info: "Object, wenn man etwas zusätzliches in Vorschau request braucht",
    },
    kontext: {
      type: String,
      required: true,
      info: "antrag, zuschuss oder zuschuss_vertrag",
    },
    statusHideButtonDelete: {
      type: Boolean,
      required: false,
      info: "Löschen-button nicht anzeigen",
    },
    statusNoPositionDetails: {
      type: Boolean,
      required: true,
      info: "wenn this.editType === 'no-position-details'",
    },
    statusPositionenNummerHide: {
      type: Boolean,
      required: false,
      info: "Positionsnummer nicht anzeigen",
    },
    statusShowProzente: {
      type: Boolean,
      required: false,
      info: "Prozente anzeigen",
    },
    keyModelDetail: {
      type: String,
      required: false,
      validator: value => ["kostenartdetail", "finanzierungsartdetail"].indexOf(value) !== -1,
      default: undefined,
      info: "kostenartdetail oder finanzierungsartdetail",
    },
    buttonAddNoPositionText: {
      type: String,
      required: false,
      default: undefined,
      info: "Text für hinzufügen-button, wenn this.editType === 'no-position-details'",
    },
    labelsPosition: {
      type: Object,
      required: false,
      default: () => ({}),
      info: "buttonTogglePositionTextHide, buttonTogglePositionTextShow, buttonAddText, buttonAddIcon, labelAdd, labelEdit, buttonEditTooltip, buttonDeleteTooltip, confirmDeleteHeader",
    },
    labelMain: {
      type: String,
      required: true,
      info: "kofiart",
    },
    ariaDescribedby: {
      type: String,
      required: true,
      info: "aria-describedby für buttons",
    },
    snapshotPosition: {
      type: Object,
      required: false,
      default: () => ({}),
      info: "snapshot für Position",
    },
    statusSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    snapshotChanges: {
      type: Object,
      required: false,
      default: undefined,
    },
    statusSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    statusKFDiff: {
      type: Boolean,
      required: true,
      info: "Status aus KFPlan",
    },
    typeKoFi: {
      type: String,
      required: true,
      validator: value => ["kosten", "finanzierung"].indexOf(value) !== -1,
    },
    prepareModel: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      confirmShow: undefined,
      confirmOptions: undefined,
      statusEdit: undefined,
    };
  },
  computed: {
    htmlIdLocal() {
      return `${ this.htmlId }_${ this.index }`;
    },

    ariaDescribedbyLocal() {
      return `${ this.ariaDescribedby } ${ this.positionNumberIdLocal }`;
    },

    positionNumberIdLocal() {
      return `${ this.htmlIdLocal }_number`;
    },

    extraTranslateLocal() {
      return {
        position: this.positionNumber,
        kofiart: this.labelMain,
      };
    },

    positionNumber() {
      if (this.idx) {
        return `${ this.idx }.${ this.posNr }`;
      }
      return "";
    },

    prefixKoFi() {
      if (this.typeKoFi === "kosten") {
        return "akp";
      }
      if (this.typeKoFi === "finanzierung") {
        return "afp";
      }
      return "";
    },

    prefixKatalogDetails() {
      if (this.typeKoFi === "kosten") {
        return "kod";
      }
      if (this.typeKoFi === "finanzierung") {
        return "fid";
      }
      return "";
    },

    posNr() {
      if (this.statusNoPositionDetails) {
        return this.index + 1;
      }
      return this.positionLocal[this.posNrKey];
    },

    posNrKey() {
      return `${ this.prefixKoFi }_pos`;
    },

    posBezeichnungKey() {
      return `${ this.prefixKoFi }_bez`;
    },

    positionLocal() {
      return this.position || {};
    },

    posArtdetailKbezKey() {
      let PREFIX = "";
      if (this.typeKoFi === "kosten") {
        PREFIX = "kostenartdetail";
      } else if (this.typeKoFi === "finanzierung") {
        PREFIX = "finanzierungsartdetail";
      }
      return `${ PREFIX }_kbez`;
    },

    prefixPosBezeichnung() {
      return this.positionLocal[this.posArtdetailKbezKey] ?
        this.positionLocal[this.posArtdetailKbezKey] + ": " : "";
    },

    posBezeichnung() {
      if (this.statusNoPositionDetails) {
        return this.katalogDetailsObj[`${ this.prefixKatalogDetails }_kbez`];
      }
      return this.prefixPosBezeichnung + this.positionLocal[this.posBezeichnungKey];
    },

    posWertLocal() {
      if (this.statusSnapshotDelete) {
        return this.snapshotWert;
      }
      return this.posWert;
    },

    posWert() {
      return this.filterCurrency(this.positionLocal[this.posWertKey] || 0);
    },

    posWertKey() {
      return `${ this.prefixKoFi }_wert`;
    },

    buttonDeleteId() {
      return `${ this.htmlIdLocal }_delete`;
    },

    buttonEditId() {
      return `${ this.htmlIdLocal }_edit`;
    },

    buttonAddId() {
      return `${ this.htmlIdLocal }_add`;
    },

    selectorClose() {
      return [
        `#${ this.buttonDeleteId }`,
        this.htmlId,
      ];
    },

    urlCurrentPosition() {
      return `${ this.urlPositionen }${ this.position.pk }/`;
    },

    statusShowButtons() {
      return !this.statusNoPositionDetails || this.position;
    },

    urlSaveLocal() {
      if (this.position) {
        return this.urlCurrentPosition;
      }
      return this.urlPositionen;
    },

    statusNewForPositionForm() {
      return !this.position;
    },

    buttonEditTooltip() {
      if (this.statusEdit) {
        return `_BTN_KF_POSITION_EDIT_OPEN_TITLE_`;
      }
      if (this.labelsPosition.buttonEditTooltip) {
        return this.labelsPosition.buttonEditTooltip;
      }
      return "_BTN_KF_POSITION_EDIT_TITLE_";
    },

    buttonDeleteTooltip() {
      if (this.labelsPosition.buttonDeleteTooltip) {
        return this.labelsPosition.buttonDeleteTooltip;
      }
      return "_BTN_KF_POSITION_DELETE_TITLE_";
    },

    confirmDeleteHeader() {
      if (this.labelsPosition.confirmDeleteHeader) {
        return this.labelsPosition.confirmDeleteHeader;
      }
      return "_TXT_KF_DELETE_CONFIRM_HEADER_{{position}}_{{kofiart}}_";
    },

    percentLocal() {
      // return this.filterCurrency(0, "%");
      return "";
    },

    snapshotWertLocal() {
      if (this.statusSnapshotDelete) {
        return this.posWert;
      }
      return this.snapshotWert;
    },

    snapshotWert() {
      return this.filterCurrency(this.snapshotPosition[this.posWertKey] || 0);
    },

    snapshotBezeichnung() {
      if (this.snapshotChanges && this.snapshotChanges.bez) {
        return this.snapshotPosition[this.posBezeichnungKey];
      }
      return undefined;
    },

    snapshotBezeichnungTranslate() {
      return {
        text: "_KF_POSITION_VERGLEICHSVERSION_{{bez}}_",
        extra: {
          bez: this.snapshotBezeichnung,
        },
      };
    },

    snapshotIcon() {
      if (this.statusSnapshotAdd) {
        return "add";
      }
      if (this.snapshotChanges) {
        return "changes";
      }
      if (this.statusSnapshotDelete) {
        return "delete";
      }
      return "";
    },

    statusSnapshotBezeichnung() {
      return !!(this.statusSnapshotAdd ||
        this.statusSnapshotDelete ||
        (this.snapshotChanges && this.snapshotChanges.bez));
    },

    statusSnapshotWert() {
      return !!(this.statusSnapshotAdd ||
        this.statusSnapshotDelete ||
        (this.snapshotChanges && this.snapshotChanges.wert));
    },
  },
  methods: {
    openDeletePosition() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: this.confirmDeleteHeader,
        msg: "_HTML_KF_DELETE_CONFIRM_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: this.deletePosition,
        cancelCallback: this.closeConfirm,
        loading: false,
        extra: this.extraTranslateLocal,
      };
      this.confirmShow = true;
    },

    closeConfirm() {
      this.confirmShow = false;
      setTimeout(() => {
        if (this.$refs.button_delete && this.$refs.button_delete.$el) {
          this.$refs.button_delete.$el.focus();
        }
      });
    },

    deletePosition() {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: `${ this.urlCurrentPosition }entfernen/`,
      }).then(
        response => {
          this.closeConfirm();
          this.closeEditPosition();
          this.$attrs.onUpdate({
            response,
            refFocus: [
              `position_${ this.index }`,
              "button_add",
            ],
          });
        }
      ).then(
        () => {
          this.confirmOptions.loading = false;
        }
      );
    },

    toggleEditPosition() {
      if (!this.statusEdit) {
        if (this.$attrs.onOpenEditPosition) {
          this.$attrs.onOpenEditPosition({ position: this.position, positionIndex: this.index });
          return;
        }
      }
      this.statusEdit = !this.statusEdit;
    },

    openEditPositionLocal() {
      if (this.$attrs.onOpenEditPosition) {
        this.$attrs.onOpenEditPosition({ position: this.position, positionIndex: this.index });
        return;
      }
      this.statusEdit = true;
    },

    openAddPositionLocal() {
      this.openEditPositionLocal();
    },

    closeEditPosition({ statusCloseAfterSave } = {}) {
      this.statusEdit = false;
      if (!statusCloseAfterSave) {
        this.setFocusByCloseEditMode();
      }
    },

    setFocusByCloseEditMode() {
      setTimeout(() => {
        if (this.$refs.button_edit && this.$refs.button_edit.$el) { // button Position hinzufügen
          this.$refs.button_edit.$el.focus();
        } else if (this.$refs.button_add && this.$refs.button_add.$el) {
          this.$refs.button_add.$el.focus();
        } else { // default
          this.$el.focus();
        }
      });
    },

    updateLocal({ response }) {
      const REF_FOCUS = `position_${ this.index }`;
      this.$attrs.onUpdate({ response, refFocus: REF_FOCUS });
    },
  },
};
