import {
  forEach,
} from "lodash-es";

const ID_FOR_FOOTER_PARENT = "panel_footer__parent";
const ID_FOR_FOOTER = "panel_footer";
const MOBILE_WIDTH = 991;
const CLASS_VERTICAL_FIXED = ".fixed_vertical";
const ID_FOR_NAVBAR = "#navbar-fixed-top";

export function setStyleTopForFixedVertical() {
  $(CLASS_VERTICAL_FIXED).css("top", getHeaderHeight());
}

export function ensureContentVisible() {
  const ASIDE_SELECTOR = ".page-wrapper aside.sticky";
  $("#printableArea").css("padding-top", $(ID_FOR_NAVBAR).outerHeight(true));
  if ($(ASIDE_SELECTOR).css("position") === "fixed") {
    $(ASIDE_SELECTOR).css("top", $(ID_FOR_NAVBAR).outerHeight(false));
  } else {
    $(ASIDE_SELECTOR).css("top", "initial");
  }
  $(CLASS_VERTICAL_FIXED).css("top", $(ID_FOR_NAVBAR).outerHeight(false));
  setHeightAsideAndMenu();
}

export function setHeightAsideAndMenu() {
  const fixedScroll = $(CLASS_VERTICAL_FIXED);
  if (!fixedScroll.length || window.innerWidth <= MOBILE_WIDTH) {
    return;
  }
  const FOOTER_ELEMENT = document.getElementById(ID_FOR_FOOTER_PARENT);
  const FOOTER_HEIGHT = getFooterHeight(FOOTER_ELEMENT);
  toggleClassFixedForFooter(FOOTER_ELEMENT);
  const NAVBAR_HEIGHT = getHeaderHeight();

  const heightGroup = window.innerHeight - NAVBAR_HEIGHT - FOOTER_HEIGHT;
  fixedScroll.css("height", heightGroup + "px");
}

function getFooterHeight(footerElement) {
  if (!footerElement) {
    return 0;
  }
  const FOOTER_TOP = footerElement.getBoundingClientRect().top;
  const WINDOW_HEIGHT = window.innerHeight;
  if (FOOTER_TOP > WINDOW_HEIGHT) {
    return 0;
  }
  return $(`#${ ID_FOR_FOOTER_PARENT }`).outerHeight(true) || 0;
}

export function toggleClassFixedForFooterByRedirect() {
  const FOOTER_ELEMENT = document.getElementById(ID_FOR_FOOTER_PARENT);
  toggleClassFixedForFooter(FOOTER_ELEMENT);
}

function toggleClassFixedForFooter(footerElement) {
  if (!footerElement) {
    return;
  }
  const FOOTER_TOP = footerElement.getBoundingClientRect().top;
  const WINDOW_HEIGHT = window.innerHeight;
  const FOOTER_CLASS_FIXED = "panel_footer_fixed";
  if (FOOTER_TOP > WINDOW_HEIGHT) {
    $(`#${ ID_FOR_FOOTER }`).removeClass(FOOTER_CLASS_FIXED);
  } else {
    $(`#${ ID_FOR_FOOTER }`).addClass(FOOTER_CLASS_FIXED);
  }
}

export function setPositionForActionsInDetails(e) {
  const alwaysVisible = $("#always-visible");
  if (!alwaysVisible.length) {
    return;
  }
  const scrollTop = e.scrollTop();
  const header = $("header.page-title.header-detail");
  const headerHeight = getHeaderHeight();
  if (scrollTop > headerHeight) {
    if (window.innerWidth > MOBILE_WIDTH) {
      alwaysVisible.addClass("fixed").css({ top: ($(ID_FOR_NAVBAR).outerHeight(false) || $("nav.navbar.navbar-static-top").outerHeight(false)), display: "block" });
      header.css({ "min-height": alwaysVisible.outerHeight(false) });
    }
  } else {
    alwaysVisible.removeClass("fixed").css({ display: "block" });
    header.css({ "min-height": "auto" });
  }
}

function getHeaderHeight() {
  return $(ID_FOR_NAVBAR).outerHeight(true) || 0;
}

export function isClickOnLink($event) {
  if ($event && $event.path) {
    let isLink = false;
    forEach($event.path, item => {
      if (item.tagName === "A") {
        isLink = true;
        return false;
      } else if (item.tagName === "TD") {
        return false;
      }
    });

    return isLink;
  }
}
