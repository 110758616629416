"use strict";

import template from "./termine.jade";

class TermineController {
  /* @ngInject */
  constructor(
    i18n
  ) {
    this.gettext = i18n.gettext;
    // Timeline
    this.timelineConfig = {
      filter: [
        {
          titel: "Noch nicht gemahnt",
          key: "dok",
          icon: "bald-zu-erledigen",
        },
        {
          titel: "Bereits gemahnt",
          key: "benachrichtigung",
          icon: "bald-zu-erledigen",
        },
        {
          titel: this.gettext("Antragsteller"),
          key: "antragstellerorganisation",
          icon: "antragsteller",
        },
        {
          titel: this.gettext("Förderantrag"),
          key: "foerderantrag",
          icon: "antrag",
        },
        {
          titel: this.gettext("Vertrag"),
          key: "vertrag",
          icon: "vertrag",
        },
        {
          titel: this.gettext("Auszahlung"),
          key: "auszahlung",
          icon: "auszahlung",
        },
      ],
      url: `aktuelle_aufgaben/bald_zu_erledigen/`,
    };
  }
}

export default {
  template: template(),
  controller: TermineController,
  controllerAs: "vm"
};
