"use strict";

import "jsplumb";

import template from "./editor.jade";

import stateModalTemplate from "./editor.state.modal.jade";
import stateModalController from "./editor.state.modal.controller";
import connectionModalTemplate from "./editor.connection.modal.jade";
import connectionModalController from "./editor.connection.modal.controller";

class WorkflowEditorController {
  /* @ngInject */
  constructor(DiasNotification, diasModalDialog, WorkflowUI) {
    this.WorkflowUI = WorkflowUI;
    this.DiasNotification = DiasNotification;
    this.diasModalDialog = diasModalDialog;

    this.loading = true;
    if (!angular.isUndefined(this.pk)) {
      this.loadWorkflow().finally(() => this.loading = false);
    } else {
      this.loading = false;
      this.error = true;
    }
    this.instance = null;
  }

  loadWorkflow() {
    return this.WorkflowUI.one(this.pk).get().then(
      response => {
        this.workflow = response;
        this.setupJsPlumbInstance(response);
      },
      error => this.error = error
    );
  }

  workflowAnordnen() {
    this.loading = true;
    return this.workflow.anordnen().then(
      response => {
        this.DiasNotification.page.success("Workflow erfolgreich neu angordnet.");
        this.anordnen_errors = response;
        this.loadWorkflow().finally(() => this.loading = false);
      },
      error => {
        this.DiasNotification.page.error("Fehler beim Anordnen des Workflows.");
        this.error = error;
        this.loading = false;
      }
    );
  }

  saveWorkflow() {
    this.loading = true;
    const workflow = {
      pk: this.pk,
      name: this.workflow.name,
      states: [],
      transitions: []
    };
    const states = angular.element(document.getElementsByClassName("state"));
    angular.forEach(states, function(state) {
      workflow.states.push({
        id: state.id.replace("state", ""),
        top: state.style.top.replace("px", ""),
        left: state.style.left.replace("px", ""),
        title: state.textContent
      });
    });
    const transitions = this.instance.getConnections();
    angular.forEach(transitions, function(transition) {
      workflow.transitions.push({
        id: transition.getParameter("wfo_id"),
        from_state: transition.source.id.replace("state", ""),
        to_state: transition.target.id.replace("state", ""),
        from_workflow: transition.getParameter("wf_id"),
        to_workflow: transition.getParameter("wf_id"),
        from_anchor: transition.endpoints[0].anchor.cssClass.replace("state", ""),
        to_anchor: transition.endpoints[1].anchor.cssClass.replace("state", ""),
        title: transition.getOverlays("Label").Label.label
      });
    });
    this.workflow.customPUT(workflow).then(
      () => {
        this.DiasNotification.page.success("Visualisierung erfolgreich aktualisiert");
      },
      error => this.DiasNotification.page.error(error, "Fehler beim Speichern der Visualisierung")
    ).finally(() => this.loading = false);
  }

  setupJsPlumbInstance(workflow) {
    const workflowEditor = angular.element(
      document.getElementById("workflow-editor")
    );
    workflowEditor.empty();
    workflow.states.forEach(function(state) {
      workflowEditor.append(
        angular.element(
          "<div id='state" + state.id + "'" +
          "     title='Details anzeigen'" +
          "     class='state'" +
          "'    style='top: " + state.top + "px; left: " + state.left + "px'>" +
          state.title + "<span class='handle glyphicon glyphicon-drug' title='Hier ziehen um die Aufgabe zu verschieben'></span></div>"
        )
      );
    });
    let draggedConnection = {};
    const jsPlumbInstanceOptions = {
      DragOptions: {
        cursor: "pointer",
        zIndex: 2000
      },
      ConnectionOverlays: [
        ["Arrow", { location: 0.99 }],
      ],
      Container: "workflow-editor"
    };
    const jsPlumbEndpointOptions = {
      endpoint: ["Dot", { radius: 4 }],
      isSource: false, // we set those dynamically on assignment
      isTarget: false, // we set those dynamically on assignment
      maxConnections: 1, // this is important to make drag & drop of connections work
      connector: [
        "Flowchart",
        {
          stub: [40, 60],
          gap: 10,
          cornerRadius: 5
        }
      ],
      connectorStyle: {
        strokeWidth: 2,
        stroke: "#333",
        joinstyle: "round",
        outlineStroke: "white",
        outlineWidth: 2
      },
      connectorHoverStyle: {
        strokeWidth: 3,
        stroke: "#333",
        outlineWidth: 5,
        outlineStroke: "white"
      },
      hoverPaintStyle: {
        fill: "#333",
        stroke: "#333"
      },
      dragOptions: {}
    };
    const anchors = [
      // [x, y, anchorOrientationX, anchorOrientationY, x offset, y offset]
      [0.20, 0, 0, -1, 0, 0, "TopLeft"],
      [0.40, 0, 0, -1, 0, 0, "TopMiddleLeft"],
      [0.60, 0, 0, -1, 0, 0, "TopMiddleRight"],
      [0.80, 0, 0, -1, 0, 0, "TopRight"],
      [0.80, 1, 0, 1, 0, 0, "BottomRight"],
      [0.60, 1, 0, 1, 0, 0, "BottomMiddleRight"],
      [0.40, 1, 0, 1, 0, 0, "BottomMiddleLeft"],
      [0.20, 1, 0, 1, 0, 0, "BottomLeft"],
      [0, 0.80, -1, 0, 0, 0, "LeftLower"],
      [0, 0.60, -1, 0, 0, 0, "LeftMiddleLower"],
      [0, 0.40, -1, 0, 0, 0, "LeftMiddleUpper"],
      [0, 0.20, -1, 0, 0, 0, "LeftUpper"],
      [1, 0.20, 1, 0, 0, 0, "RightUpper"],
      [1, 0.40, 1, 0, 0, 0, "RightMiddleUpper"],
      [1, 0.60, 1, 0, 0, 0, "RightMiddleLower"],
      [1, 0.80, 1, 0, 0, 0, "RightLower"],
    ];
    /* global jsPlumb*/
    const instance = jsPlumb.getInstance(jsPlumbInstanceOptions);
    const _addEndpoints = function(toId) {
      for (let i = 0; i < anchors.length; i++) {
        const sourceUUID = toId + anchors[i][6];
        const options = Object.create(jsPlumbEndpointOptions);
        options.anchor = anchors[i];
        options.uuid = sourceUUID;
        if (i % 2 === 0) {
          // target endpoint
          options.isSource = false;
          options.isTarget = true;
          options.endpoint = ["Dot", { radius: 5 }];
          options.paintStyle = {
            stroke: "#ccc",
            fill: "transparent",
            radius: 4,
            strokeWidth: 1
          };
        } else {
          // source endpoint
          options.isSource = true;
          options.isTarget = false;
          options.paintStyle = {
            stroke: "#ccc",
            fill: "#ccc",
            radius: 4,
            strokeWidth: 1
          };
        }
        instance.addEndpoint(
          toId,
          options
        );
      }
    };
    const _addTransition = function(transition) {
      /*
       * {
       *   "title": "Retract",
       *   "from": "0",
       *   "to": "1",
       *   "fromAnchor": "RightUpper",
       *   "toAnchor": "LeftUpper"
       * }
       *
       */
      // we use the uuids approach here so we don"t override the connection styles
      const from = "state" + transition.from_state + transition.from_anchor;
      const to = "state" + transition.to_state + transition.to_anchor;
      // check if a connection already exists
      const connections = instance.getConnections();
      let skip = false;
      connections.forEach(function(connection) {
        const existing_from = connection.source.id + connection.endpoints[0]._jsPlumb.currentAnchorClass;
        const existing_to = connection.target.id + connection.endpoints[1]._jsPlumb.currentAnchorClass;
        // connection.wf_id = transition.from_workflow;
        // connection.wfo_id = transition.id;
        // connection.id = transition.pk;
        // console.log(from + " === " + existing_from + " && " + to + " == " + existing_to);
        if (from === existing_from || to === existing_to) {
          skip = true;
          console.warn(
            "Skip " + transition.title + ": " +
            connection.source.id + connection.endpoints[0]._jsPlumb.currentAnchorClass + " -> " +
            connection.target.id + connection.endpoints[1]._jsPlumb.currentAnchorClass
          );
        }
      });
      if (skip === false) {
        instance.connect({
          id: transition.pk,
          uuids: [from, to],
          parameters: {
            pk: transition.pk,
            wf_id: transition.from_workflow,
            wfo_id: transition.id,
          },
          overlays: [
            [
              "Label",
              {
                id: "Label",
                label: transition.title,
                location: 0.55,
                cssClass: "transitionLabel"
              }
            ]
          ],
          editable: true
        });
      }
    };

    jsPlumb.ready(() => {
      instance.batch(() => {
        // endpoints
        workflow.states.forEach(function(node) {
          _addEndpoints("state" + node.id);
        });

        // listen for new connections; initialise them the same way we initialise the connections at startup.
        instance.bind("connection", function() {
          // init(connInfo.connection);
        });

        // draggable connections
        // (it is important that we do that before we add connections)
        instance.draggable(
          jsPlumb.getSelector("#workflow-editor .state"),
          {
            grid: [20, 20],
            handle: ".handle"
          }
        );

        // connections
        workflow.transitions.forEach(function(transition) {
          _addTransition(transition);
        });

        // temporarily store a connection that is being dragged
        instance.bind("connectionDrag", function(connection) {
          draggedConnection = {
            id: connection.getParameter("wfo_id"),
            title: connection.getOverlays("Label").Label.label,
            from_workflow: connection.getParameter("wf_id"),
            to_workflow: connection.getParameter("wf_id"),
            from_state: connection.source.id.replace("state", ""),
            to_state: connection.target.id.replace("state", ""),
            from_anchor: connection.endpoints[0]._jsPlumb.currentAnchorClass,
            to_anchor: connection.endpoints[1]._jsPlumb.currentAnchorClass
          };
        });

        // re-add a connection that has not been properly re-added to an endpoint
        instance.bind("connectionDragStop", function(connection) {
          if (connection.source === null || connection.target === null) {
            _addTransition(draggedConnection);
          } else {
            if (connection.source.id.replace("state", "") !== draggedConnection.from_state || connection.target.id.replace("state", "") !== draggedConnection.to_state) {
              instance.detach(connection);
              _addTransition(draggedConnection);
            }
          }
        });

        // event click on workflow transition
        instance.bind("click", connection => {
          // if (event.target.nodeName !== "P") {
          if (!this.aufgabendetails) {
            this.aufgabendetails = true;
            this.diasModalDialog({
              title: "Aufgabendetails",
              template: connectionModalTemplate,
              controller: connectionModalController,
              extras: {
                wfo_id: connection.getParameter("wfo_id")
              }
            }).then(
              () => {
                this.aufgabendetails = false;
              }
            );
          }
        });

        // event click on workflow state
        $("div.state").on("click", e => {
          const id = $(e.target).attr("id");
          if (angular.isString(id)) {
            this.diasModalDialog({
              title: "Aufgabendetails",
              template: stateModalTemplate,
              controller: stateModalController,
              extras: {
                au_id: id.replace("state", "")
              }
            });
          }
        });
      });
    });
    this.instance = instance;
  }
}

export default {
  template: template(),
  controller: WorkflowEditorController,
  controllerAs: "vm",
  bindings: {
    pk: "<"
  }
};
