"use strict";

import BeschreibungController from "./beschreibung.edit.controller";
import BeschreibungTemplate from "./beschreibung.edit.jade";
import AusfuehrungkonfigController from "./ausfuehrungkonfig.edit.controller";
import AusfuehrungkonfigTemplate from "./ausfuehrungkonfig.edit.jade";
import template from "./detail.jade";
import WorkflowfolgeKopierenController from "./workflowfolgekopieren.modal";
import WorkflowfolgeKopierenTemplate from "./workflowfolgekopieren.modal.jade";

class DetailController {
  /*@ngInject*/
  constructor($rootScope, $state, $stateParams, AuthService, Workflowfolge, DiasNotification, diasModalDialog, diasConfirmDialog, i18n) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.Workflowfolge = Workflowfolge;
    this.AuthService = AuthService;
    this.DiasNotification = DiasNotification;
    this.gettext = i18n.gettext;
    this.diasConfirmDialog = diasConfirmDialog;
    this.diasModalDialog = diasModalDialog;
    this.editDescriptionController = BeschreibungController;
    this.editDescriptionTemplate = BeschreibungTemplate;
    this.editAusfuehrungController = AusfuehrungkonfigController;
    this.editAusfuehrungTemplate = AusfuehrungkonfigTemplate;

    this.loading = {
      wfo: true,
    };
    this.validationLabels = {
      aufgabe: this.gettext("Ausgangsaufgabe: "),
      workflow: this.gettext("Workflow: "),
      clientfunktion: this.gettext("Clientfunktion: "),
      serverfunktionen: this.gettext("Serverfunktionen: "),
      statusfunktionen: this.gettext("Statusfunktionen: "),
    };
    this.infoTexts = {
      aufgabe: this.gettext("Aufgabe an der diese Workflowfolge als Aktion zur Verfügung steht."),
      nachfolge_aufgabe: this.gettext("Aufgabe in die das Objekt überführt wird, nachdem diese Workflowfolge erfolgreich ausgeführt wurde."),
      workflow: this.gettext("Diese Workflowfolge wird nur an der ausgewählten Ausgangsaufgabe, wenn diese in diesem Workflow verwendet wird."),
      nachfolge_workflow: this.gettext("Workflow in den das Objekt überführt wird, nachdem diese Workflowfolge erfolgreich ausgeführt wurde."),
      statusfunktionen: this.gettext("Prüfungen die bestimmen, ob diese Workflowfolge im aktuellen Zustand des Objekts und für den angemeldeten Nutzer zur Verfügung steht."),
      clientfunktion: this.gettext("Funktionalität die ausgeführt werden soll bevor, der Server über die Ausführung der Workflowfolge gestartet wird."),
      serverfunktionen: this.gettext("Funktionalitäten die in der angegebenen Reihenfolge ausgeführt werden sollen, wenn die Workflowfolge gestartet wurde."),
      wfo_kbez: this.gettext("Anzeigename der Aktion am Objekt, mit der diese Workflowfolge gestartet werden kann."),
      wfo_pos: this.gettext("Position der Aktion in der Liste aller verfügbaren Aktionen am Objekt, mit der diese Workflowfolge gestartet werden kann."),
      wfo_anzeige_typ: this.gettext("Bestimmt zum Beispiel die Anzeigefarbe der Aktion, wenn sie als erste am Objekt angeboten wird."),
      wfo_anzeige_icon: this.gettext("Bestimmt das Icon der Aktion, wenn sie als erste am Objekt angeboten wird."),
      wfo_reload: this.gettext("Lädt die Workflowfolge nach der Ausführung die Seite neu"),
    };
    this.perms = this.AuthService.syncHasPerms([
      "workflowfolge.update", "workflowfolge.delete",
      "workflowfolge.create", "workflowfolge.update.funktionen",
      "workflow.ui", "kaufgabe.ui",
      "clientfunktion.ui", "statusfunktion.ui",
      "serverfunktion.ui", "workflowfolge.view.queue",
    ]);
    this.init();
  }

  remove() {
    this.loading.delete = true;
    this.wfo.remove().then(
      () => {
        this.DiasNotification.page.success("Workflowfolge erfolgreich gelöscht");
        this.$state.go("root.workflowfolgen");
      },
      err => this.DiasNotification.page.error(`${ err.data }`, "Fehler beim Löschen der Aufgabenvorlage")
    ).finally(() => this.loading.delete = false);
  }

  init() {
    this.Workflowfolge.one(this.$stateParams.id).get().then(
      result => {
        this.wfo = result;
      }
    ).finally(() => this.loading.wfo = false);
  }

  validate() {
    this.loading.validation = true;
    this.wfo.customGET("validieren").then(
      response => {
        this.validation = {};
        Object.keys(response.plain()).forEach(
          k => {
            if (angular.isArray(response[k]) || angular.isString(response[k])) {
              this.validation[k] = response[k];
            }
          }
        );
        this.validationType = (Object.keys(this.validation).length > 0) ? "warning" : "success";
      }
    ).finally(() => this.loading.validation = false);
  }

  assignWorkflowfolge(Workflowfolge) {
    if (angular.isUndefined(Workflowfolge)) {
      return;
    }
    return this.diasModalDialog({
      title: "Workflowfolge kopieren",
      template: WorkflowfolgeKopierenTemplate,
      controller: WorkflowfolgeKopierenController,
      extras: {
        Workflowfolge: Workflowfolge,
        wfo: this.wfo
      },
    });
  }
}

export default {
  template: template(),
  controller: DetailController,
  controllerAs: "vm",
};
