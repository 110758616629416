import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  size
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungMeldungVollstaendigkeit",
  components: {
    PuxAlert,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    auszahlungName: {
      type: String,
      required: true,
    },
    validationErrors: {
      type: Array,
      default: () => [],
    },
    validationErrorsLoading: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
  },
  emits: [
    "resetValidation",
  ],
  computed: {
    extraForTranslate() {
      return {
        name: this.auszahlungName,
      };
    },
    isSuccessMsgVisible() {
      return size(this.validationErrors) === 0 && !this.validationErrorsLoading;
    },
  },
  methods: {
    resetValidation() {
      this.$emit("resetValidation");
    },
  },
};
