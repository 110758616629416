import Alert from "../../../global/components/Alert/Alert.vue";
import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import Betragsrechner from "../Betragsrechner/Betragsrechner.vue";
import FormElement from "../../../global/components/FormElement/FormElement.vue";
import KofiErfassungBelegFormExtraField from "../KofiErfassung/KofiErfassungBelegForm/ExtraField/ExtraField.vue";
import KofiErfassungBelegFormStandardField from "../KofiErfassung/KofiErfassungBelegForm/StandardField/StandardField.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxErrors from "../../../global/components/PuxErrors/PuxErrors.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spaced from "../../../global/components/Spaced/Spaced.vue";
import Spinner from "../../../global/components/Spinner/Spinner.vue";

import translate from "../../../global/directives/translate";

import HttpMixin from "../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import {
  FilterCurrencyMixin,
} from "../../../global/mixins/FiltersMixin";

import KBelegPruefmethodeUUIDs from "../../../const/KBelegPruefmethodeUUIDs";
import KVertragskostenbelegStatusUUIDs from "../../../const/KVertragskostenbelegStatusUUIDs";

import {
  dynFormToFormElements
} from "../../../global/components/ui/UiDynamischeFormDefinition/utils";
import {
  EventBus,
} from "../../../global/functions/event-bus";
import {
  initModelFromList,
  toFormElementFromParameter,
} from "../../../global/functions/mappingForParameterToFormElement";

import StandardFields from "../ui/UiBelegeField/Fields";

import {
  assign,
  cloneDeep,
  filter,
  forEach,
  get,
  isEmpty,
  isNil,
  isObject,
  isPlainObject,
  isUndefined,
  omit,
  orderBy,
  pick,
  size,
} from "lodash-es";

const RECHNER_MODES = {
  ERFASST: 1,
  AKTUALISIERT: 2,
  UNBEKANNT: -1
};

const RELEVATE_VERTRAGSFELDER = [
  "pk",
  "v_nr",
  "antrag_id",
  "content_type",
  "v_belege_fortlaufend",
];

// @vue/component
export default {
  name: "BelegeModalCreateOrEdit",
  components: {
    Alert,
    AngularLink,
    Betragsrechner,
    FormElement,
    KofiErfassungBelegFormExtraField,
    KofiErfassungBelegFormStandardField,
    Modal,
    Permission,
    PuxButton,
    PuxCloak,
    PuxErrors,
    PuxIcon,
    PuxTranslate,
    Spinner,
    Spaced,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
    PermissionMixin,
    FilterCurrencyMixin,
  ],
  props: {
    vertrag: {
      type: Object,
      required: false,
      default: undefined,
      info: "Objekt Vertrag",
    },
    vertragId: {
      type: String,
      required: false,
      default: undefined,
      info: "Vertrag UUID",
    },
    auszahlung: {
      type: Object,
      required: false,
      default: undefined,
      info: "Objekt Auszahlung",
    },
    selectorClose: {
      type: [String, Array],
      required: true,
      info: "CSS-Selector für Fokus, wenn man Modal-Fenster schließt",
    },
    close: {
      type: Function,
      require: true,
      default: undefined,
      info: "Schießen Modal-Fenster",
    },
    beleg: {
      type: Object,
      require: false,
      default: () => ({
        herkunft: "vertragskosten_positionsbeleg",
      }),
      info: "Beleg-Model",
    },
    kofiPos: {
      type: Number,
      required: false,
      default: 4,
      info: "Position-Nummer für Kosten und Finanzierung",
    },
  },
  data() {
    return {
      optionsModal: {
        showCloseButton: false,
      },
      statusSaveLoading: false,
      statusLoadingVertrag: false,
      statusDisabledVertrag: false,
      statusShowHelp: false,
      belegnummerAnzeigen: undefined,
      rechnerMode: RECHNER_MODES.UNBEKANNT,
      model: cloneDeep(this.beleg),
      errorsLabel: {},
      vertragSelection: [],
      positionen: [],
      positionByPk: {},
      loadedAntrag: undefined,
      relevantePerioden: [],
      fullPositionen: [],
      canCreate: true,
      loading: false,
      loadingGlobal: true,
      loadingPerms: false,
      dokart: undefined,
      nextBelegNummer: undefined,
      prefix: undefined,
      posPrefix: undefined,
      typ: undefined,
      art: undefined,
      dokument_required: false,
      curPosition: undefined,
      curPosInfo: undefined,
      editModel: {},
      disableStammdatenEdit: undefined,
      disableEdit: undefined,
      fullBelege: [],
      belegDokumente: [],
      errors: {},
      selectedVertragNr: undefined,
      selectedVertrag: undefined,
      showPosDetails: false,
      rechnerLoading: false,
      kofiArtByPosition: {},
      dokLoading: false,
      statusBelegUpdateKosten: false,
      statusBelegUpdateFinanzierung: false,
      extraKF: {},
      extraFields: undefined,
      standardFieldConfigs: undefined,
      showBelegHinweis: true,
      // TODO: siehe KFAuszahlungPerioden.js:110.
      // Wenn dort dynamisch gemacht wird, muss hier auch
      contextNumber: 4,
      auszahlungSyConfig: undefined,
    };
  },
  computed: {
    headerTranslate() {
      if (!this.model.pk) {
        return {
          text: "_TXT_AUSZAHLUNG_BELEGE_MODAL_CREATE_HEADER_"
        };
      }
      return {
        text: "_TXT_AUSZAHLUNG_BELEGE_MODAL_EDIT_HEADER_{{nummer}}_",
        extra: {
          nummer: this.model.nummer,
        },
      };
    },

    vertragIdLocal() {
      if (this.vertrag) {
        return this.vertrag.pk;
      }
      if (this.vertragId) {
        return this.vertragId;
      }
      return this.model.vertrag;
    },

    keyNummer() {
      return `${ this.prefix }_nummer`;
    },

    belegType() {
      return this.model.herkunft === "vertragsfinanzierung_positionsbeleg" ?
        "_TXT_BELEG_MODAL_FINANZIERUNGSBELEG_" :
        "_TXT_BELEG_MODAL_KOSTENBELEG_";
    },

    firstLineClasses() {
      const classes = [];
      const canCreate = !this.model.pk && this.canCreate;
      const canEdit = this.model.pk && !this.disableEdit;
      const canMoveOn = this.model.vertrag && (canCreate || canEdit);
      if (canMoveOn && !this.loadingPerms) {
        classes.push("glyphicon-chevron-down");
      } else {
        classes.push("glyphicon-close");
      }
      if (canMoveOn && !this.model.pk && !this.loadingPerms) {
        classes.push("success");
      } else if (this.model.vertrag && !canMoveOn && !this.loadingPerms) {
        classes.push("danger");
      }
      return classes;
    },

    optionsVertrag() {
      return {
        id: "vertrag",
        htmlId: "beleg_modal_vertrag",
        type: "select",
        label: "_LBL_BELEG_MODAL_VERTRAG_",
        disabled: this.loading || this.statusDisabledVertrag,
        change: this.onVertragChanged,
        data: this.vertragSelection,
        required: true,
        keyId: "pk",
        keyLabel: "v_nr",
        view: "v-alt",
        searchGlobal: true,
        url: "vertraege/",
        urlParams: {
          fields: RELEVATE_VERTRAGSFELDER
        },
        searchParameter: "v_nr",
      };
    },

    optionsPositionen() {
      return {
        id: this.keyPosition,
        htmlId: "beleg_modal_positionen",
        type: "select",
        label: "Position",
        disabled: this.disableEdit,
        keyGroup: "art",
        keyLabel: "label",
        keyId: "value",
        required: true,
        change: this.onPositionChanged,
        view: "v-alt",
        data: this.positionen,
      };
    },

    optionsBelegPeriode() {
      return {
        id: "periode",
        htmlId: "beleg_modal_periode",
        label: "_LBL_AUSZAHLUNG_BELEGE_MODAL_CREATE_TITLE_PERIODE_",
        type: "select",
        required: true,
        view: "v-alt",
        keyId: "pk",
        keyLabel: "pe_kbez",
        classesHorizontal: this.classesHorizontal,
        data: this.relevantePerioden,
      };
    },

    optionsNummer() {
      return {
        id: this.keyNummer,
        htmlId: "beleg_modal_nummer",
        type: "text",
        label: "_LBL_AUSZAHLUNG_BELEGE_MODAL_CREATE_TITLE_BELEGNUMMER_",
        view: "v-alt",
        required: true,
        placeholder: "_LBL_AUSZAHLUNG_BELEGE_MODAL_CREATE_PLACEHOLDER_BELEGNUMMER_",
        maxlength: 100,
      };
    },

    optionsBez() {
      return {
        id: this.keyBez,
        htmlId: "beleg_modal_bez",
        type: "text",
        label: "_LBL_AUSZAHLUNG_BELEGE_MODAL_CREATE_TITLE_BEZEICHNUNG_",
        view: "v-alt",
        required: true,
        placeholder: "_LBL_AUSZAHLUNG_BELEGE_MODAL_CREATE_PLACEHOLDER_BEZEICHNUNG_",
        maxlength: 250,
      };
    },

    optionsBetragErfasst() {
      return {
        id: this.keyBetragErfasst,
        htmlId: "beleg_modal_betrag_erfasst",
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        label: "_LBL_AUSZAHLUNG_BELEGE_MODAL_CREATE_TITLE_BELEGBERTRAG_",
        view: "v-alt",
        required: true,
        disabled: true,
      };
    },

    optionsBetragAnerkannt() {
      return {
        id: this.keyBetragAnerkannt,
        htmlId: "beleg_modal_betrag_anerkannt",
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        label: "Anerkannter Betrag",
        placeholder: "Anerkannter Betrag in Euro angeben",
        view: "v-alt",
        required: true,
        maxlength: 60,
      };
    },

    optionsKommentar() {
      return {
        id: this.keyKommentar,
        htmlId: "beleg_modal_kommentar",
        type: "textarea",
        label: "Kommentar",
        placeholder: "Kommentar",
        view: "v-alt",
        required: false,
        maxlength: 200,
      };
    },

    keyPosition() {
      return `vertrags${ this.typ }position`;
    },

    keyInfo() {
      return `antrag${ this.art }info`;
    },

    keyBez() {
      return `${ this.prefix }_bez`;
    },

    keyPos() {
      return `${ this.prefix }_pos`;
    },

    keyBetragErfasst() {
      return `${ this.prefix }_betrag_erfasst`;
    },

    keyBetragAnerkannt() {
      return `${ this.prefix }_betrag_anerkannt`;
    },

    keyKommentar() {
      return `${ this.prefix }_kommentar`;
    },

    buttonPosDetailText() {
      return this.showPosDetails ?
        "Details ausblenden" :
        "Details anzeigen";
    },

    buttonPosDetailIcon() {
      return this.showPosDetails ?
        "minus" :
        "plus";
    },

    showBetrag() {
      // wenn nicht neu erstellt wird und der aktualisierte Betrag bearbeitet wird => Zeige den erfassten Betrag als readonly
      return (this.model !== undefined &&
        this.model.pk !== undefined &&
        this.rechnerMode === RECHNER_MODES.AKTUALISIERT);
    },

    showPeriode() {
      return !!size(this.relevantePerioden);
    },

    buttonCreateText() {
      return this.model.pk ?
        "_BTN_SAVE_" :
        "Erstellen";
    },

    statusDisabledButtonAddBelegNotValid() {
      if (this.curPosition && !isEmpty(this.curPosition.belege)) {
        return this.curPosition.belege.some(beleg => !this.isGeprueft({ beleg }) && !this.hasBetragErfasst({ beleg }));
      }
      return false;
    },

    statusDisabledButtonAddBelegCreate() {
      return this.loading || this.dokLoading || !this.curPosition || (!this.canCreate && !this.loadingPerms && this.model.vertrag);
    },

    statusDisabledButtonAddBelegSave() {
      return this.statusDisabledButtonAddBelegCreate || (this.disableEdit && this.disableStammdatenEdit);
    },

    hasExtraFields() {
      return size(this.extraFields) > 0;
    },

    hasStandardFieldConfigs() {
      return size(this.standardFieldConfigs) > 0;
    },

    isBelegnummerDisabled() {
      return this.disableStammdatenEdit || get(this, "selectedVertrag.v_belege_fortlaufend", false);
    },

    htmlId() {
      return "beleg_modal";
    },

    apiAntragDokumente() {
      return `foerderantraege/${ this.model.antrag }/dokumente/?dokart=${ this.dokart }`;
    },

    statusErrors() {
      return !isEmpty(this.errors);
    },

    regel() {
      return get(this.curPosition, `${ this.keyInfo }.antragsregel`, {});
    },

    modelParameter() {
      const PARAMETER = toFormElementFromParameter({ obj: this.regel.argument });
      return initModelFromList({ list: PARAMETER.list }) || {};
    },

    belegHinweis() {
      return this.modelParameter.beleg_hinweis;
    },

    hasBelegHinweis() {
      return size(this.belegHinweis) > 0;
    },

    showBelegHinweisAlert() {
      return this.showBelegHinweis && this.hasBelegHinweis;
    },

    showBelegHinweisButton() {
      return !this.showBelegHinweis && this.hasBelegHinweis;
    },
  },
  created() {
    this.checkSyConfig();
    this.buildErrorLabels();
    this.init();
    this.initData();
  },
  methods: {
    checkSyConfig() {
      this.belegnummerAnzeigen = this.getSyConfigsValue("belege_nummer", {}).anzeigen;
    },

    buildErrorLabels() {
      const FIELDS = [
        this.optionsBelegPeriode,
        this.optionsNummer,
        this.optionsBez,
        this.optionsBetragErfasst,
        this.optionsBetragAnerkannt,
        this.optionsKommentar,
        ...(this.extraFields || []),
        ...(this.standardFieldConfigs || []),
      ];
      const ERRORS_LABEL = {};
      forEach(FIELDS, el => {
        ERRORS_LABEL[el.id] = {
          label: el.label,
          link: true,
          id: el.htmlId,
        };
      });
      // ERRORS_LABEL[this.keyPos] = "Position";
      // ERRORS_LABEL[this.keyNummer] = "_LBL_AUSZAHLUNG_BELEGE_MODAL_CREATE_TITLE_BELEGNUMMER_";
      // ERRORS_LABEL[this.keyBez] = "_LBL_AUSZAHLUNG_BELEGE_MODAL_CREATE_TITLE_BEZEICHNUNG_";
      // ERRORS_LABEL[this.keyBetragErfasst] = "_LBL_AUSZAHLUNG_BELEGE_MODAL_CREATE_TITLE_BELEGBERTRAG_";
      // ERRORS_LABEL[this.keyBetragAnerkannt] = "Anerkannter Betrag";
      // ERRORS_LABEL.periode = "_LBL_AUSZAHLUNG_BELEGE_MODAL_CREATE_TITLE_PERIODE_";
      // ERRORS_LABEL.dokument = "Datei";
      // if (this.extraFields && this.extraFields.length) {
      //   forEach(this.extraFields, item => {
      //     ERRORS_LABEL[item.id] = item.label;
      //   });
      // }
      // forEach(this.standardFieldConfigs, item => {
      //   ERRORS_LABEL[item.field] = this.standardFields[item.field].label;
      // });
      this.errorsLabel = ERRORS_LABEL;
    },

    init() {
      this.standardFields = cloneDeep(StandardFields);
      this.auszahlungSyConfig = this.getSyConfigsValue("auszahlung");
    },

    loadEditModelData() {
      this.loading = true;
      let getLabel = () => {};
      let getArt = () => {};
      let getBetrag = () => {};
      let getArtInfo = () => {};
      const isFinanz = this.model.herkunft === "vertragsfinanzierung_positionsbeleg";
      if (isFinanz) {
        this.prefix = "vfb";
        this.posPrefix = "vfp";
        this.typ = "finanzierung";
        this.art = "finanzierungsart";
        this.dokart = "5f681393-a2d3-4423-ba35-54d09156651b";
        this.dokument_required = false;
        getLabel = (art, pos) => `${ this.kofiPos }.${ art.vf_pos }.${ pos.vfp_pos } ${ pos.vfp_bez }`;
        getArt = art => `${ this.kofiPos }.${ art.vf_pos } ${ art.finanzierungsart.fin_kbez }`;
        getBetrag = (art, pos) => `${ this.filterCurrency(pos.vfp_betrag_bewilligt) }`;
        getArtInfo = art => {
          return {
            kofi_id: art.pk,
            kofi_pos: art.vf_pos,
            kofiart_bez: art.finanzierungsart.fin_kbez
          };
        };
      } else {
        this.prefix = "vpb";
        this.posPrefix = "vkp";
        this.typ = "kosten";
        this.art = "kostenart";
        this.dokart = "2f413911-a2dc-c179-e050-007f0101705e";
        this.dokument_required = true;
        getLabel = (art, pos) => `${ this.kofiPos }.${ art.vk_pos }.${ pos.vkp_pos } ${ pos.vkp_bez }`;
        getArt = art => `${ this.kofiPos }.${ art.vk_pos } ${ art.kostenart.kos_kbez }`;
        getBetrag = (art, pos) => `${ this.filterCurrency(pos.vkp_betrag_bewilligt) }`;
        getArtInfo = art => {
          return {
            kofi_id: art.pk,
            kofi_pos: art.vk_pos,
            kofiart_bez: art.kostenart.kos_kbez
          };
        };
      }
      this.resetEditData();
      this.loadData({ getLabel, getArt, getArtInfo, getBetrag });
    },

    loadData({ getLabel, getArt, getArtInfo, getBetrag }) {
      Promise.all([
        this.loadBelegDokumente(),
        this.loadPosition({ getLabel, getArt, getArtInfo, getBetrag }),
        this.loadRelevantePerioden(),
      ]).then(
        () => {
          this.loadingGlobal = false;
          this.loading = false;
        }
      );
    },

    loadRelevantePerioden() {
      if (this.loadedAntrag && this.loadedAntrag === this.model.antrag) {
        return;
      }
      return this.getHttp({
        url: `foerderantraege/${ this.model.antrag }/`
      }).then(
        response => {
          this.loadedAntrag = this.model.antrag;
          this.relevantePerioden = get(response, "regelsatz_obj.perioden");
        },
        () => this.addNotification({
          text: "Fehler beim Abrufen der relevanten Perioden.",
          type: "error",
        })
      );
    },

    loadNextBelegnummer() {
      if (this.auszahlungSyConfig.belegdaten_vorbelegen) {
        const BELEGNUMMER =
        `${ this.contextNumber }.${ this.curPosition.artInfo.kofi_pos }.${ this.curPosition[`${ this.posPrefix }_pos`] }-${ this.curPosition.belege.length + 1 }`;
        this.editModel[this.keyNummer] = BELEGNUMMER;
        return Promise.resolve();
      }
      return this.getHttp({
        url: `vertraege/${ this.vertragIdLocal }/belegnummer/`,
      }).then(
        response => {
          this.nextBelegNummer = get(response, "next");
          if (this.nextBelegNummer && !this.editModel[this.keyNummer]) {
            this.editModel[this.keyNummer] = this.nextBelegNummer;
          }
        },
        () => this.addNotification({
          text: "Fehler beim Abrufen der nächsten Belegnummer.",
          type: "error",
        })
      );
    },

    loadBelegDokumente() {
      if (!this.model.antrag) {
        return;
      }
      return this.getListHttp({
        url: this.apiAntragDokumente,
      }).then(
        response => {
          this.fullBelege = response;
          this.belegDokumente = response;
        },
        () => this.addNotification({
          text: "Fehler beim Laden der Dokumente",
          type: "error",
        })
      );
    },

    loadPosition({ getLabel, getArt, getArtInfo, getBetrag }) {
      if (!this.model.vertrag) {
        return;
      }
      return this.getListHttp({
        url: `vertraege/${ this.vertragIdLocal }/${ this.typ }/`,
      }).then(
        response => {
          const POSITIONEN = [];
          const POSITIONEN_BY_PK = {};
          const FULL_POSITIONEN = [];
          const KOFI_ART_BY_POSITION = {};
          let beleg;
          forEach(response, art => {
            forEach(art.positionen, pos => {
              pos.artInfo = getArtInfo(art);
              FULL_POSITIONEN.push(pos);
              if (this.model.pk && !beleg) {
                beleg = pos.belege.find(beleg => this.model.pk === beleg.pk);
                if (beleg) {
                  this.editModel = cloneDeep(beleg);
                }
              }
              POSITIONEN_BY_PK[pos.pk] = pos;
              POSITIONEN.push({
                value: pos.pk,
                label: getLabel(art, pos),
                art: getArt(art, pos),
                betrag: getBetrag(art, pos)
              });
              KOFI_ART_BY_POSITION[pos.pk] = art;
            });
          });
          this.fullPositionen = FULL_POSITIONEN;
          this.positionen = POSITIONEN;
          this.positionByPk = POSITIONEN_BY_PK;
          this.kofiArtByPosition = KOFI_ART_BY_POSITION;
          if (beleg) {
            this.onPositionChanged();
            this.updatePerms();
          }
        },
        () => this.addNotification({
          text: "Fehler beim Laden der Positionen",
          type: "error",
        })
      );
    },

    initData() {
      if (this.vertrag) {
        this.initDataForVertrag();
      } else if (this.vertragId) {
        this.loadVertrag();
      }
    },

    initDataForVertrag() {
      this.statusDisabledVertrag = true;
      this.vertragSelection = [pick(this.vertrag, RELEVATE_VERTRAGSFELDER)];
      this.model.vertrag = this.vertrag.pk;
      this.model.antrag = this.vertrag.antrag_id;
      this.selectedVertragNr = this.vertrag.v_nr;
      this.selectedVertrag = this.vertrag;
      this.canCreate = true;
      this.loadingPerms = false;
      this.loadEditModelData();
    },

    loadVertrag() {
      this.statusLoadingVertrag = true;
      this.getHttp({
        url: `vertraege/${ this.vertragId }/`,
      }).then(
        response => {
          const ITEM = pick(response, RELEVATE_VERTRAGSFELDER);
          this.vertragSelection = [ITEM];
          this.statusLoadingVertrag = false;
          this.onVertragChanged({
            item: ITEM,
          });
        }
      );
    },

    resetEditData() {
      this.curPosition = undefined;
      this.curPosInfo = undefined;
      this.editModel = {
        pk: this.model.pk,
        auszahlung: get(this.auszahlung, "pk"),
        argument: {},
        extra: this.initModelExtraFields({}),
        standardfelder: {},
      };
    },

    updatePerms() {
      const PERMISSION_VERTRAGSKOSTENPOSITIONSBELEGE_UPDATE = this.checkPermissionsSync({
        perm: "vertragskostenpositionsbelege.update", permArray: this.editModel.user_permissions,
      });
      const PERMISSION_VERTRAGSKOSTENPOSITIONSBELEGE_UPDATE_INTERN = this.checkPermissionsSync({
        perm: "vertragskostenpositionsbelege.update.intern", permArray: this.editModel.user_permissions,
      });

      this.disableStammdatenEdit = this.model.pk &&
        ((this.rechnerMode === RECHNER_MODES.AKTUALISIERT &&
        (!PERMISSION_VERTRAGSKOSTENPOSITIONSBELEGE_UPDATE_INTERN ||
        this.model.pruefmethode !== KBelegPruefmethodeUUIDs.INTERN_ERGAENZT)) ||
        (this.rechnerMode === RECHNER_MODES.ERFASST && (
          !PERMISSION_VERTRAGSKOSTENPOSITIONSBELEGE_UPDATE ||
          this.model.status !== KVertragskostenbelegStatusUUIDs.NEU)));
      this.disableEdit = this.model.pk &&
        !(PERMISSION_VERTRAGSKOSTENPOSITIONSBELEGE_UPDATE ||
          PERMISSION_VERTRAGSKOSTENPOSITIONSBELEGE_UPDATE_INTERN);
    },

    toggleHelp() {
      this.statusShowHelp = !this.statusShowHelp;
    },

    onVertragChanged({ item }) {
      this.deleteErrors();
      this.selectedVertragNr = item.v_nr;
      this.selectedVertrag = item;
      this.model.antrag = item.antrag_id;
      this.model.vertrag = item.pk;
      if (this.model.vertrag && !this.model.pk) {
        this.canCreate = true;
        this.loadingPerms = true;
        this.getHttp({
          url: `vertraege/${ this.model.vertrag }/check_my_permissions/`,
          urlParams: {
            permissions: ["vertragskostenpositionsbelege.create"],
          },
        }).then(
          response => {
            this.canCreate = response.length > 0;
            if (this.canCreate && this.model.herkunft) {
              this.loadEditModelData();
            }
          },
          err => this.onError(err)
        ).then(() => this.loadingPerms = false);
      } else if (this.model.herkunft) {
        this.loadEditModelData();
      }
    },

    onPositionChanged() {
      this.deleteErrors();
      if (!this.editModel[this.keyPosition]) {
        this.resetEditData();
        return;
      }
      this.curPosition = this.positionByPk[this.editModel[this.keyPosition]];
      this.setExtraFields();
      this.setStandardFields();
      if (this.curPosition.vkp_argument && this.curPosition.vkp_argument.personalfunktion) {
        // personalkosten_lohnjournal
        this.editModel.personalfunktion = this.curPosition.vkp_argument.personalfunktion;
      }
      if (!this.editModel.extra) {
        this.editModel.extra = this.initModelExtraFields({});
      } else {
        this.editModel.extra = this.initModelExtraFields(this.editModel.extra);
      }
      this.curPosInfo = {
        bez: this.curPosition[`${ this.posPrefix }_bez`],
        bewilligt: this.filterCurrency(this.curPosition[`${ this.posPrefix }_betrag_bewilligt`]),
        eingereicht: this.filterCurrency(this.curPosition[`${ this.posPrefix }_betrag_eingereicht`]),
        erfasst: this.filterCurrency(this.curPosition[`${ this.posPrefix }_betrag_erfasst`]),
        anerkannt: this.filterCurrency(this.curPosition[`${ this.posPrefix }_betrag_anerkannt`]),
      };
      this.initModelWithBeleg();
      this.buildErrorLabels();
      this.loadNextBelegnummer();
    },

    tooltipDisabledButtonAddBeleg(canEdit) {
      if (canEdit && this.statusDisabledButtonAddBelegNotValid) {
        return "_BTN_AUSZAHLUNG_BELEGE_POSITIONAL_DETAIL_BELEGE_HINZUFÜGEN_TOOLTIP_DISABLED_";
      }
    },

    initModelWithBeleg() {
      this.rechnerLoading = true;
      const betragsrechner = this.getBelegrechner();
      if (betragsrechner) {
        let argument_betrag;
        let argument_params;
        let standardfelder;
        let extra;
        const STATUS = this.model.status;

        if (STATUS === KVertragskostenbelegStatusUUIDs.NEU) {
          /* Wenn der Beleg neu ist, dann müssen die Werte aus den "erfasst"
             Werten entnommen werden: */
          argument_params = this.editModel[`${ this.prefix }_argument_erfasst`];
          argument_betrag = this.editModel[this.keyBetragErfasst];
          extra = this.editModel.extra_input_erfasst;
          standardfelder = this.editModel.standardfelder_erfasst;
          this.rechnerMode = RECHNER_MODES.ERFASST;
        } else if (STATUS === KVertragskostenbelegStatusUUIDs.GEPRUEFT) {
          /* Wenn der Beleg durch den Safo angelegt wurde, dann müssen die Werte aus den "anerkannt"
             Werten entnommen werden: */
          argument_betrag = this.editModel[this.keyBetragAnerkannt];
          argument_params = this.editModel[`${ this.prefix }_argument_anerkannt`];
          extra = this.editModel.extra_input_anerkannt;
          standardfelder = this.editModel.standardfelder_anerkannt;
          this.rechnerMode = RECHNER_MODES.AKTUALISIERT;
        } else if (STATUS === KVertragskostenbelegStatusUUIDs.EINGEREICHT) {
          /* Wenn der Beleg eingereicht ist, dann muss differenziert werden: */
          if (this.editModel[this.keyBetragAnerkannt] === null) {
            // Der Beleg wurde noch nicht durch den Sachbearbeiter angefasst:
            argument_betrag = this.editModel[this.keyBetragErfasst];
            argument_params = this.editModel[`${ this.prefix }_argument_erfasst`];
            this.editModel.extra_input_erfasst;
            standardfelder = this.editModel.standardfelder_erfasst;
            this.rechnerMode = RECHNER_MODES.ERFASST;
          } else if (this.editModel[this.keyBetragAnerkannt] === "0.00") {
            // Der Beleg wurde schon mal abgelehnt:
            argument_betrag = "0.00";
            this.rechnerMode = RECHNER_MODES.AKTUALISIERT;
            extra = this.editModel.extra_input_anerkannt;
            standardfelder = this.editModel.standardfelder_anerkannt;
          } else {
            // Der Beleg wurde schon mal vom Sachbearbeiter bearbeitet:
            argument_betrag = this.editModel[this.keyBetragAnerkannt];
            argument_params = this.editModel[`${ this.prefix }_argument_anerkannt`];
            extra = this.editModel.extra_input_anerkannt;
            standardfelder = this.editModel.standardfelder_anerkannt;
            this.rechnerMode = RECHNER_MODES.AKTUALISIERT;
          }
        }

        if (betragsrechner === "standard_kostenrechner" || betragsrechner === "standard_finanzierungsrechner") {
          this.editModel.argument = { wert: argument_betrag };
        } else {
          this.editModel.argument = Object.assign({}, argument_params);
        }
        this.editModel.rechner = betragsrechner;
        this.editModel.extra = this.initModelExtraFields(extra || {});
        this.editModel.standardfelder = standardfelder || {};
        this.extraKF = {
          regel: this.regel,
          antragsregel: this.regel,
          argument: this.curPosition[this.keyInfo].argument
        };
        if (this.art === "kostenart") {
          this.extraKF.akpargument = this.curPosition[`foerderantrag${ this.art }pos`].argument;
        }
        setTimeout(() => this.rechnerLoading = false, 500);
        this.updatePerms();
      }
    },

    getBelegrechner() {
      if (!this.curPosition || !this.curPosition[this.keyInfo]) {
        return;
      }
      const betragsrechnername = this.curPosition[this.keyInfo].betragsrechner;
      return betragsrechnername;
    },

    onTypChanged() {
      if (this.model.vertrag && this.model.antrag && this.canCreate) {
        this.loadEditModelData();
      }
      this.deleteErrors();
    },

    togglePosDetails() {
      this.showPosDetails = !this.showPosDetails;
    },

    createAndContinue() {
      this.create({ statusContinue: true }).then(
        () => {
          const EDIT_MODEL = cloneDeep(this.editModel);
          EDIT_MODEL.argument = {};
          EDIT_MODEL.extra = {};
          EDIT_MODEL.standardfelder = {};
          EDIT_MODEL[this.keyBez] = undefined;
          EDIT_MODEL[this.keyNummer] = undefined;
          this.editModel = EDIT_MODEL;
          this.onPositionChanged();
        }
      );
    },

    save() {
      this.deleteErrors();
      if (this.model.pk) {
        this.update();
      } else {
        this.create();
      }
    },

    create({ statusContinue } = {}) {
      this.statusSaveLoading = true;
      const data = Object.assign({}, this.editModel);
      if (this.model.status === KVertragskostenbelegStatusUUIDs.EINGEREICHT &&
          data[this.keyBetragErfasst]) {
        delete data[this.keyBetragErfasst];
      }
      if (!data[this.keyBetragAnerkannt] || data[this.keyBetragAnerkannt].trim() === "") {
        data[this.keyBetragAnerkannt] = null;
      }
      const KOFI_ART_PK = this.kofiArtByPosition[data[this.keyPosition]].pk;
      return this.postHttp({
        url: `vertraege/${ this.vertragIdLocal }/${ this.typ }/${ KOFI_ART_PK }/positionen/${ data[this.keyPosition] }/belege/`,
        data: data,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_BELEG_MODAL_CREATE_SUCCESS_",
          });
          this.setStatusBelegUpdate();
          if (!statusContinue) {
            this.onEventBus();
            this.close({ statusReload: true });
          }
        },
        err => {
          this.onError(err);
        }
      ).then(
        () => {
          this.statusSaveLoading = false;
        }
      );
    },

    update() {
      const data = cloneDeep(this.editModel) || {};
      if (this.model.status === KVertragskostenbelegStatusUUIDs.EINGEREICHT &&
          data[this.keyBetragErfasst]) {
        delete data[this.keyBetragErfasst];
      }
      if (!data[this.keyBetragAnerkannt] || data[this.keyBetragAnerkannt].trim() === "") {
        data[this.keyBetragAnerkannt] = null;
      }
      const KOFI_ART_PK = this.kofiArtByPosition[data[this.keyPosition]].pk;
      return this.putHttp({
        url: `vertraege/${ this.vertragIdLocal }/${ this.typ }/${ KOFI_ART_PK }/positionen/${ data[this.keyPosition] }/belege/${ this.model.pk }/`,
        data: data,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_BELEG_MODAL_CREATE_SUCCESS_",
          });
          this.setStatusBelegUpdate();
          this.onEventBus();
          this.close();
        },
        err => {
          this.onError(err);
        }
      );
    },

    onError(error) {
      let errors = error.data || {};
      if (isObject(errors.extra)) {
        errors = assign({}, omit(errors, ["extra"]), errors.extra);
      }
      if (isObject(errors.standardfelder)) {
        errors = assign({}, omit(errors, ["standardfelder"]), errors.standardfelder);
      }
      this.errors = errors;
    },

    deleteErrors() {
      this.errors = {};
    },

    reloadBelegDokumente() {
      this.loadBelegDokumente();
    },

    changeModelExtraField({ model, id }) {
      this.editModel.extra[id] = model;
    },

    changeModelStandardField({ model, id }) {
      this.editModel.standardfelder[id] = model;
    },

    initErrorLabelsFromChild({ labels }) {
      forEach(labels, (item, key) => {
        this.errorsLabel[key] = item.label;
      });
    },

    changeModelFromChild({ model }) {
      this.editModel = model;
    },

    mock() {

    },

    cancel() {
      this.close();
      this.onEventBus();
    },

    onEventBus() {
      if (this.statusBelegUpdateKosten || this.statusBelegUpdateFinanzierung) {
        EventBus.$emit("updateBeleg", {
          kosten: this.statusBelegUpdateKosten,
          finanzierung: this.statusBelegUpdateFinanzierung,
        });
      }
    },

    setStatusBelegUpdate() {
      if (this.typ === "finanzierung") {
        this.statusBelegUpdateFinanzierung = true;
      } else if (this.typ === "kosten") {
        this.statusBelegUpdateKosten = true;
      }
    },

    setExtraFields() {
      const OBJ = this.auszahlung || this.selectedVertrag;
      this.extraFields = dynFormToFormElements({
        formDef: this.modelParameter.beleg_extra_fields || {},
        object: OBJ,
        htmlId: this.htmlId
      });
    },

    setStandardFields() {
      const CONFIG = this.modelParameter.beleg_fields || {};
      const FIELDS = orderBy(
        filter(CONFIG, conf => conf.show),
        conf => get(conf, "pos", get(this.standardFields, [conf.field, "prio"], 999))
      );
      forEach(FIELDS, el => {
        el.id = el.field;
        el.htmlId = `${ this.htmlId }_${ el.field }`;
        el.label = get(this.standardFields, [el.field, "label"]);
      });
      this.standardFieldConfigs = FIELDS;
    },

    toggleShowBelegHinweis({ status }) {
      if (isNil(status)) {
        status = !this.showBelegHinweis;
      }
      this.showBelegHinweis = status;
    },

    initModelExtraFields(extra) {
      return assign(initModelFromList({ list: this.extraFields }), extra || {});
    },

    isGeprueft({ beleg }) {
      const BELEG_STATUS = isPlainObject(beleg.status) ? beleg.status.pk : beleg.status;
      return BELEG_STATUS === KVertragskostenbelegStatusUUIDs.GEPRUEFT;
    },

    hasBetragErfasst({ beleg }) {
      return isUndefined(beleg[this.keyBetragErfasst]) ? +beleg.betrag_erfasst : +beleg[this.keyBetragErfasst];
    },
  },
};
