import {
  computed,
  toRef,
} from "vue";

import {
  isEmpty,
} from "lodash-es";

export default function SitzungsartTagesordnungNotGroupedAPI(props) {
  const number = toRef(props, "number");
  const extraForTranslateHeader = computed(() => {
    return {
      number: number.value,
    };
  });

  const isObjectEmpty = object => {
    return isEmpty(object);
  };

  return {
    extraForTranslateHeader,
    isObjectEmpty,
  };
}
