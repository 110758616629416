"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import diasAuthService from "dias/auth/services";
import diasNotification from "dias/core/notification";
import diasRichtext from "dias/core/richtext";
import diasTable from "dias/core/table";

import models from "./models";
import report from "./report";
import detailsComponent from "./details.component";


const tableauModule = angular.module("root.tableau", [
  uiRouter,
  models.name,
  report.name,
  diasAuthService.name,
  diasNotification.name,
  diasRichtext.name,
  diasTable.name,
]);

tableauModule.config($stateProvider => {
  $stateProvider

    // Tableau-Verwaltung
    .state("root.tableau.admin", {
      url: "verwaltung/",
      views: {
        "main@root": {
          template: "<div vueinit><vue-berichte-verwaltung-list></vue-berichte-verwaltung-list></div>"
        }
      },
      data: {
        permissions: ["berichte.update"]
      },
    })
    // Detail-Seite einer Tableau-Verwaltung
    .state("root.tableau.admin.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><berichte-verwaltung-details></berichte-verwaltung-details></div>"
        }
      },
      data: {
        permissions: ["berichte.update"]
      },
    })

    // Tableau-Ansicht
    .state("root.tableau", {
      url: `/tableau/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-berichte-list></vue-berichte-list></div>"
        }
      },
      data: {
        permissions: ["berichte.view"]
      },
    })

    // Detail-Seite einese Tableau-Berichts
    .state("root.tableau.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<tableau-details></tableau-details>"
        }
      },
      data: {
        permissions: ["tableau.view"]
      },
    })
  ;
})

  .component("tableauDetails", detailsComponent)
;

export default tableauModule;
