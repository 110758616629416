import Alert from "../../../../global/components/Alert/Alert.vue";
import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import AntragstellerAuswahlReadonly from "./AntragstellerAuswahlReadonly/AntragstellerAuswahlReadonly.vue";
import ContactUserEdit from "./ContactUserEdit/ContactUserEdit.vue";
import DufoModalCreate from "../../../Antragsteller/Dufo/DufoModalCreate/DufoModalCreate.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import MissingAntragstellerBeschreibung from "./MissingAntragstellerBeschreibung/MissingAntragstellerBeschreibung.vue";
import NutzerGesperrtIcon from "../../../Nutzer/NutzerGesperrtIcon/NutzerGesperrtIcon.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../../global/components/ui/ShowMore/ShowMore.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";

import loading from "../../../../global/directives/loading";

import ModuleMixin from "../ModuleMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import {
  cloneDeep,
  keyBy,
  orderBy,
  findIndex,
  isEmpty,
  merge,
  toNumber,
  forEach,
  get,
  isNil,
  replace,
  size,
} from "lodash-es";

import {
  EventBus,
} from "../../../../global/functions/event-bus";


import {
  createNamespacedHelpers,
} from "vuex";


const {
  mapMutations,
  mapGetters,
} = createNamespacedHelpers("notizen");

// @vue/component
export default {
  components: {
    FormElement,
    SnapshotModule,
    SnapshotIcon,
    Alert,
    PuxCloak,
    AngularLink,
    ShowMore,
    NutzerGesperrtIcon,
    Permission,
    ContactUserEdit,
    DufoModalCreate,
    AntragstellerAuswahlReadonly,
    MissingAntragstellerBeschreibung,
    PuxButton,
    PuxTranslate,
  },
  directives: {
    loading,
  },
  mixins: [
    ModuleMixin,
    SyConfigMixin,
    PermissionMixin,
  ],
  data() {
    return {
      hideBeschreibung: undefined,
      expectBeschreibung: undefined,
      prevSelectedAst: undefined,
      canViewContacts: false,
      canViewDufo: false,
      contactUsersCount: undefined,
      contactUsers: [],
      contactUsersEdit: [],
      contactUsersObj: {},
      contactUsersEditObj: {},
      contactUsersForEdit: {},
      limitAntragstellerList: 50,
      searchGlobal: undefined,
      statusLoadingEdit: undefined,
      statusLoadingOptionsAst: true,
      loading: true,
      labels: {
        dufo: "_LBL_GR_ANTRAGSTELLER_AUSWAHL_DUFO_IN_EINRICHTUNG_",
        ast: "_LBL_GR_ANTRAGSTELLER_AUSWAHL_HEADER_",
      },
      antragstellerList: [],
      statusDufo: false,
      statusDufoLoading: false,
      statusDufoModalCreate: false,
      dufoObj: {},
      foerderorgTypen: [],
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.openEditMode;
      return OPTIONS;
    },

    labelHeader() {
      return "_LBL_GR_ANTRAGSTELLER_AUSWAHL_HEADER_";
    },

    foerderorgArr() {
      const FOERDERORG_ARR = [];
      if (this.obj && this.obj.antragstellerorg_obj && this.obj.antragstellerorg_obj.foerderorganisation_obj &&
        this.obj.antragstellerorg_obj.foerderorganisation_obj.foerderorghierarchie &&
        this.obj.antragstellerorg_obj.foerderorganisation_obj.foerderorghierarchie.length) {
        const foerderorghierarchie = this.obj.antragstellerorg_obj.foerderorganisation_obj.foerderorghierarchie;
        forEach(this.foerderorgTypen, item => {
          for (let i = 0; i < foerderorghierarchie.length; i++) {
            if (item.pk === foerderorghierarchie[i].o_otid) {
              FOERDERORG_ARR.push(merge({}, foerderorghierarchie[i], item));
              break;
            }
          }
        });
      }
      return FOERDERORG_ARR;
    },

    orderedFoerderorgArr() {
      return orderBy(this.foerderorgArr, ["ot_pos"], ["asc"]);
    },

    optionsAst() {
      const OPTIONS = {
        type: "select",
        label: "_LBL_GR_ANTRAGSTELLER_AUSWAHL_HEADER_",
        id: "ast",
        view: "v",
        htmlId: `${ this.htmlRegelNummer }_ast`,
        keyId: "pk",
        slot: "ast",
        change: this.changeAst,
        translate: true,
        loadingMenu: this.statusLoadingOptionsAst,
        keyGroup: "org",
        sortList: ["ast_name"],
        required: true,
      };
      if (!this.searchGlobal) {
        OPTIONS.search = true;
        OPTIONS.searchList = ["ast_name"];
      } else {
        OPTIONS.searchOutside = this.onSearchOutside;
      }
      return OPTIONS;
    },

    statusPermission_ast_crmnr_view() {
      return this.checkPermissionsSync({ perm: "ast_crmnr.view" });
    },

    statusPermission_ast_nr_view() {
      return this.checkPermissionsSync({ perm: "ast_nr.view" });
    },

    snapshotChangesList() {
      const SNAPSHOT_CHANGES_LIST = [
        "ast_obj.ast_name",
        "ast_obj.ast_namezusatz",
        "ast_obj.is_beantragt",
        "ast_obj.kopfdublette",
        "ast_obj.ast_strasse",
        "ast_obj.ast_hsnr",
        "ast_obj.ast_plz",
        "ast_obj.ast_ort",
        "ast_obj.rechtsform_obj.ref_bez",
        "ast_obj.ast_email",
        "ast_obj.ast_crmnr",
        "ast_obj.ast_url",
        "ast_obj.ast_beschreibung",
        "ast_obj.bundesland.bun_bez",
      ];
      SNAPSHOT_CHANGES_LIST.push(...this.snapshotChangesDufoList);
      return SNAPSHOT_CHANGES_LIST;
    },

    snapshotChangesDufoList() {
      return [];
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(this.snapshotChangesList, key => {
          if (get(this.model, key) !== get(this.snapshotModule, key)) {
            DIFF[key] = true;
          }
        });
        for (let i = 0; i < this.contactUsersCount; i++) {
          const KEY_ANU = `asp_anu_${ i }`;
          const KEY_KOMMENTAR = `asp_kommentar_${ i }`;
          if (this.model[KEY_ANU] !== this.snapshotModule[KEY_ANU] ||
            this.model[KEY_KOMMENTAR] !== this.snapshotModule[KEY_KOMMENTAR]) {
            DIFF[KEY_ANU] = true;
          }
          if (isNil(this.model[KEY_ANU]) && isNil(this.snapshotModule[KEY_ANU])) {
            break;
          }
        }
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },

    statusCanChangeAst() {
      return this.checkPermissionsSync({
        perm: "foerderantrag.astorg_zuordnen",
        permArray: this.obj.user_permissions,
      });
    },

    isModuleEditable() {
      return !this.statusReadonly && this.statusCanChangeAst;
    },

    showAstLink() {
      return !get(this.obj, "block_link_ast", false);
    },

    errorsLabelsOptions() {
      const ERROR_LABELS = [
        this.optionsAst,
      ];
      if (this.optionsDufo) {
        ERROR_LABELS.push(this.optionsDufo);
      }
      if (this.contactUsersCount) {
        for (let i = 0; i < this.contactUsersCount; i++) {
          const CURRENT_USER_ID = `asp_anu_${ i }`;
          const CURRENT_KOMMENTAR_ID = `asp_anu_${ i }`;
          ERROR_LABELS.push({
            label: `_LBL_GR_ANTRAGSTELLER_AUSWAHL_ANSPRECHPARTNER_${ i + 1 }_`,
            id: CURRENT_USER_ID,
            htmlId: `${ this.htmlRegelNummer }_${ CURRENT_USER_ID }`,
          });
          ERROR_LABELS.push({
            label: `_LBL_GR_ANTRAGSTELLER_AUSWAHL_KOMMENTAR_${ i }_`,
            id: CURRENT_KOMMENTAR_ID,
            htmlId: `${ this.htmlRegelNummer }_${ CURRENT_KOMMENTAR_ID }`,
          });
        }
      }
      return ERROR_LABELS;
    },

    optionsDufo() {
      return {};
    },
    ...mapGetters([
      "GET_NOTIZEN_OPTIONS",
    ]),
  },
  created() {
    this.checkSyConfig();
    this.loadData();
    this.loadAntragstellerChoices({ statusFirst: true });
    this.loadFoerderorgTyp();
  },
  beforeUnmount() {
    this.destroyEventBusAntragstellerWizard();
  },
  methods: {
    checkSyConfig() {
      const CONFIG = this.getSyConfigsValue("antragsteller") || {};
      const MAX_ANSPRECHPARTNER = this.getSyConfigsValue("max_ansprechpartner") || {};
      this.contactUsersCount = !isNil(MAX_ANSPRECHPARTNER.astorg) ? toNumber(MAX_ANSPRECHPARTNER.astorg) : 99;
      this.hideBeschreibung = CONFIG.hide_beschreibung;
    },

    initAntragsteller() {
      this.getHttp({
        url: `antragstellerorganisationen/${ this.obj.antragstellerorg_obj.pk }/`,
      }).then(
        response => {
          const MODEL = cloneDeep(this.model);
          MODEL.ast_obj = response;
          this.model = MODEL;
        }
      );
    },

    loadData() {
      if (this.kontext === "sitzungsantrag") {
        this.canViewContacts = true;
        this.canViewDufo = true;
        this.extendModelWithAstObj({ astObj: get(this.obj, "antragstellerorg_obj", {}) });
        this.contactUsers = get(this.obj, "astorg_ansprechpartner", []);
        const CONTACT_USERS_OBJ = {};
        forEach(this.contactUsers, user => {
          CONTACT_USERS_OBJ[user.astorg_nutzer] = user.nutzer;
        });
        this.contactUsersObj = CONTACT_USERS_OBJ;
        this.loading = false;
        return;
      }

      if (!this.model.ast) {
        this.loading = false;
        return;
      }
      const astorg_typen = get(this.obj, "regelsatz_obj.antragstellerorgtypen", []);
      this.getHttp({
        url: `antragstellerorganisationen/${ this.model.ast }/`,
        urlParams: {
          typen: astorg_typen
        }
      }).then(
        response => {
          this.extendModelWithAstObj({ astObj: response });
          this.loadDurchfuehrendeorgeinheiten({ astObj: response, statusFirst: true });
          this.loadContactUsers({ astObj: response }).then(
            usersResponse => {
              this.contactUsers = usersResponse;
              this.contactUsersEdit = cloneDeep(usersResponse.filter(user => user.is_aktiv || (this.model.asps && this.model.asps.some(el => el.pk === user.pk))));
              this.contactUsersObj = keyBy(usersResponse, "pk");
              this.contactUsersEditObj = cloneDeep(this.contactUsersObj);
            }
          ).finally(() => this.loading = false);
        },
        () => this.loading = false
      );
    },

    extendModelWithAstObj({ astObj }) {
      const MODEL = cloneDeep(this.model);
      MODEL.ast_obj = astObj;
      this.model = MODEL;
      this.expectBeschreibung = this.model.ast_obj.expect_beschreibung;
    },

    loadContactUsers({ astObj }) {
      this.canViewContacts = this.checkPermissionsSync({ perm: "astorgnutzer.view", permArray: astObj.user_permissions });
      return new Promise((resolve, reject) => {
        if (!this.canViewContacts) {
          resolve([]);
          return;
        }
        this.getListHttp({
          url: `antragstellerorganisationen/${ astObj.pk }/nutzer/`,
          urlParams: {
            aktiv: true,
            fields: ["status", "nutzer_status", "pk", "name"],
          },
        }).then(
          resolve,
          reject
        );
      });
    },

    loadAntragstellerChoices({ statusFirst, modelSearch = null } = {}) {
      if (this.kontext === "sitzungsantrag") {
        this.antragstellerList = [this.model.ast_obj];
        return;
      }
      this.statusLoadingOptionsAst = true;
      const astorg_typen = get(this.obj, "regelsatz_obj.antragstellerorgtypen", []);
      this.getHttp({
        url: `antragstellerorganisationen/`,
        urlParams: {
          fields: ["pk", "ast_name", "ast_nr", "ast_crmnr", "is_beantragt", "kopfdublette", "zugehoerig_name", "zugehoerig_typ", "user_permissions"],
          for_user: this.obj.autor.n_id, // nur für den autor mögliche ASTOrgs
          limit: this.limitAntragstellerList,
          suche: modelSearch,
          typen: astorg_typen,
        },
      }).then(
        response => {
          if (statusFirst && response.count > this.limitAntragstellerList) {
            this.searchGlobal = true;
          }
          this.antragstellerList = this.prepareAntragstellerList({ response });
          this.statusLoadingOptionsAst = false;
        }
      );
    },

    prepareAntragstellerList({ response }) {
      const ANTRAGSTELLER_LIST = [];
      forEach(response.results, item => {
        ANTRAGSTELLER_LIST.push(this.prepareAstItem({ item }));
      });
      return ANTRAGSTELLER_LIST;
    },

    prepareAstItem({ item }) {
      const ITEM = item;
      if (ITEM.zugehoerig_name) {
        const TYPE = ITEM.zugehoerig_typ ? ` (${ ITEM.zugehoerig_typ })` : "";
        ITEM.org = `${ ITEM.zugehoerig_name }${ TYPE }`;
      } else {
        ITEM.org = "_TXT_GR_ANTRAGSTELLER_AUSWAHL_KEINER_FOERDERORG_";
      }
      return item;
    },

    openEditMode() {
      this.modelEdit = cloneDeep(this.model);
      this.initEventBusAntragstellerWizard();
      this.setContactUsersForEdit();
    },

    initEventBusAntragstellerWizard() {
      EventBus.$on("closeWizardOrganisation", this.closeWizardOrganisation);
    },

    destroyEventBusAntragstellerWizard() {
      EventBus.$off("closeWizardOrganisation", this.closeWizardOrganisation);
    },

    closeWizardOrganisation({ organisation } = {}) {
      if (!organisation) {
        return;
      }
      this.antragstellerList.push(this.prepareAstItem({ item: organisation }));
      const MODEL_EDIT = cloneDeep(this.modelEdit);
      MODEL_EDIT.ast = organisation.pk;
      this.modelEdit = MODEL_EDIT;
      this.changeAst({ model: organisation.pk });
    },

    setContactUsersForEdit() {
      const SELECTED_USERS = [];
      for (let i = 0; i < this.contactUsersCount; i++) {
        SELECTED_USERS[i] = cloneDeep(this.contactUsersEdit);
        for (let j = 0; j < this.contactUsersCount; j++) {
          if (i === j) {
            continue;
          }
          const CURRENT_USER_KEY = `asp_anu_${ j }`;
          const CURRENT_MODEL = this.modelEdit[CURRENT_USER_KEY];
          if (CURRENT_MODEL) {
            const INDEX = findIndex(SELECTED_USERS[i], ["pk", CURRENT_MODEL]);
            if (INDEX !== -1) {
              SELECTED_USERS[i].splice(INDEX, 1);
            }
          }
        }
      }
      this.contactUsersForEdit = SELECTED_USERS;
    },

    changeModelContactUser({ contactUser, index }) {
      const MODEL_EDIT = cloneDeep(this.modelEdit);
      MODEL_EDIT[`asp_anu_${ index }`] = contactUser;
      this.modelEdit = MODEL_EDIT;
      this.setContactUsersForEdit();
    },

    changeModelComment({ comment, index }) {
      const MODEL_EDIT = cloneDeep(this.modelEdit);
      MODEL_EDIT[`asp_kommentar_${ index }`] = comment;
      this.modelEdit = MODEL_EDIT;
    },

    changeAst({ model }) {
      this.statusLoadingEdit = true;
      this.getHttp({
        url: `antragstellerorganisationen/${ model }/`,
      }).then(
        response => {
          let modelEdit = cloneDeep(this.modelEdit);
          modelEdit.ast_obj = response;
          this.loadContactUsers({ astObj: response }).then(
            usersResponse => {
              this.contactUsersEdit = usersResponse.filter(user => user.is_aktiv || (this.model.asps && this.model.asps.some(el => el.pk === user.pk)));
              this.contactUsersEditObj = keyBy(usersResponse, "pk");
              modelEdit = this.setModelForContactUsersAndComments({ users: usersResponse, modelEdit });
              this.setContactUsersForEdit();
            }
          ).finally(() => this.statusLoadingEdit = false);
          this.loadDurchfuehrendeorgeinheiten({ astObj: response });
          this.modelEdit = this.setModelForDurchfuehrendeorgeinheiten({ modelEdit });
        },
        () => this.statusLoadingEdit = false
      );
    },

    setModelForContactUsersAndComments({ users, modelEdit }) {
      for (let i = 0; i < this.contactUsersCount; i++) {
        const CURRENT_USER_KEY = `asp_anu_${ i }`;
        const CURRENT_KOMMENTAR_KEY = `asp_kommentar_${ i }`;
        let user_config;
        let user_translate;
        // null/undefined muss sich wie vor Einführung des Parameters verhalten
        if (this.modelParameter.ansprechpartner_vorbelegen !== false) {
          user_config = size(this.modelParameter.ansprechpartner_template) > 0 ? replace(this.modelParameter.ansprechpartner_template, "{nr}", i + 1) : null;
          user_translate = window.gettext(`_LBL_GR_ANTRAGSTELLER_AUSWAHL_ANSPRECHPARTNER_${ i + 1 }_`);
        }
        if (i === 0 && users.length === 1) {
          modelEdit[CURRENT_USER_KEY] = users[0].pk;
          modelEdit[CURRENT_KOMMENTAR_KEY] = modelEdit[CURRENT_KOMMENTAR_KEY] || user_config || user_translate;
          continue;
        }
        modelEdit[CURRENT_USER_KEY] = null;
        modelEdit[CURRENT_KOMMENTAR_KEY] = modelEdit[CURRENT_KOMMENTAR_KEY] || user_config || user_translate;
      }
      return modelEdit;
    },

    setModelForDurchfuehrendeorgeinheiten({ modelEdit }) {
      return modelEdit;
    },

    save() {
      const DATA = this.prepareToSave();
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.moduleUrl,
          data: DATA,
        }).then(
          response => {
            this.onRestFunctions({ response });
            this.errors = undefined;
            this.setContactUsersUndModelAfterSave();
            const OPTIONS = cloneDeep(this.GET_NOTIZEN_OPTIONS);
            OPTIONS.antragstellerorg = response.data.ast;
            this.SET_NOTIZEN_OPTIONS({ options: OPTIONS });
            resolve();
          },
          error => {
            this.errors = error.data;
            reject(error);
          }
        );
      });
    },

    prepareToSave() {
      const DATA = {
        ast: this.modelEdit.ast,
      };
      return this.prepareToSaveAnuAndKommentar({ data: DATA });
    },

    prepareToSaveAnuAndKommentar({ data }) {
      for (let i = 0; i < this.contactUsersCount; i++) {
        const CURRENT_USER_KEY = `asp_anu_${ i }`;
        if (this.modelEdit[CURRENT_USER_KEY]) {
          const CURRENT_KOMMENT = `asp_kommentar_${ i }`;
          data[CURRENT_USER_KEY] = this.modelEdit[CURRENT_USER_KEY];
          data[CURRENT_KOMMENT] = this.modelEdit[CURRENT_KOMMENT];
        }
      }
      return data;
    },

    setContactUsersUndModelAfterSave() {
      setTimeout(() => {
        this.contactUsers = cloneDeep(this.contactUsersEdit);
        this.contactUsersObj = cloneDeep(this.contactUsersEditObj);
        this.extendModelWithAstObj({ astObj: cloneDeep(this.modelEdit.ast_obj) });
        this.extendModelWithDufoObj();
      });
    },

    onSearchOutside(modelSearch) {
      this.loadAntragstellerChoices({ modelSearch });
    },

    createAntragsteller() {
      EventBus.$emit("openAntragstellerWizard");
    },

    sendSignalLocal() {
      EventBus.$emit("antragsteller.update");
    },

    extendModelWithDufoObj() {
      if (!this.statusDufo || !this.model.dufo || !this.dufoObj[this.model.dufo]) {
        return;
      }
      const MODEL = cloneDeep(this.model);
      MODEL.dufo_obj = this.dufoObj[this.model.dufo];
      this.model = MODEL;
    },

    loadFoerderorgTyp() {
      this.getListHttp({ url: "foerderorgtypen/" }).then(
        response => {
          this.foerderorgTypen = response;
        }
      );
    },

    loadDurchfuehrendeorgeinheiten() {

    },

    close() {
      this.errors = undefined;
      this.destroyEventBusAntragstellerWizard();
    },

    ...mapMutations([
      "SET_NOTIZEN_OPTIONS",
    ]),
  },
};
