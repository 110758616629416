import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import FormularListeBox from "../../../Formulare/FormularListeBox/FormularListeBox.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "AntragGoToBox",
  components: {
    AngularLink,
    AsideRightBox,
    FormularListeBox,
    PuxTranslate,
  },
  mixins: [
    PermissionMixin,
  ],
  props: {
    antrag: {
      type: Object,
      required: true,
    },
    reloadAntrag: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const {
      filterSpaced,
    } = FiltersAPI();

    return {
      filterSpaced,
    };
  },
  data() {
    return {
      optionsAsideRightBox: {
        icon: "verbunden",
        active: true,
      },
      isFormularListeVisible: undefined,
    };
  },
  computed: {
    extraForAntragstellerTranslate() {
      return {
        ast_name: this.antrag.antragstellerorg_obj.ast_name,
      };
    },

    canViewVertrag() {
      return this.antrag.vertrag &&
        this.checkPermissionsSync({
          perm: "vertrag.view",
        });
    },

    extraForVertragTranslate() {
      return {
        v_nr: this.filterSpaced(this.antrag.vertrag.v_nr),
      };
    },

    extraForVertragTitleTranslate() {
      return {
        v_nr: this.filterSpaced(this.antrag.vertrag.v_nr, true),
      };
    },
  },
};
