"use strict";

import "restangular";
import angular from "angular";

import Tableau from "./tableau.service";
import KBerichttyp from "./kberichttyp.service";


const modelsModule = angular.module("tableau.models", [
  "restangular",
])

  .provider("Tableau", Tableau)
  .provider("KBerichttyp", KBerichttyp)
;

export default modelsModule;
