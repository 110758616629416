"use strict";

import "restangular";
import angular from "angular";

import CoreModels from "dias/core/models";
import DatenschutzeinwilligungService from "./datenschutzeinwilligungen.service";


const modelsModule = angular.module("auth.datenschutz.models", [
  "restangular",
  CoreModels.name,
])

  .provider("Datenschutzeinwilligung", DatenschutzeinwilligungService)
;

export default modelsModule;
