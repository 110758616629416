import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "ImportauftragJsonWizardAbschluss",
  components: {
    PuxButton,
    PuxTranslate,
  },
  props: {
    reset: {
      type: Function,
      default: undefined,
    }
  },
};
