"use strict";

import BaseModel from "dias/core/models/baseModel";

class Dokumente extends BaseModel {
  constructor() {
    super("dokumente");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.getUploadUrl = dokartId => {
      return collection.getRestangularUrl() + "/?dokart=" + dokartId;
    };
    collection.searchAntrag = (modelAntrag, antragNummerLast) => {
      return collection.one("dmsakte").one(modelAntrag, antragNummerLast).get();
    };
    collection.getDokList = modelAntragType => {
      return collection.getList({ dokart: modelAntragType });
    };
    collection.getLastDokList = (docAnzeige, limitDok) => {
      return collection.getList({ anzeige: docAnzeige, last: limitDok });
    };
    collection.getSearchDokumentForm = doktyp => {
      return collection.all(doktyp).one("suchformular").get();
    };
    collection.searchDokument = (doktyp, modelObj) => {
      return collection.all(doktyp).getList(modelObj);
    };
    collection.searchCountDokumente = (doktyp, modelObj) => {
      return collection.one(doktyp).one("count").get(modelObj);
    };
    collection.getSaveBlob = dokPk => {
      return collection.one(dokPk).withHttpConfig({ responseType: "blob" }).get();
    };
    collection.copyDoc = (dokPk, data) => {
      return collection.one(dokPk).customPUT(data, "copy_doc");
    };

    return collection;
  }

  extendModel(model) {
    model = super.extendCollection(model);

    model.loadDetails = () => model.customGET("details");

    return model;
  }
}

export default Dokumente;
