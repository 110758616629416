import {
  computed,
  ref,
} from "vue";

import HttpAPI from "../../../../../libs/vue/client/vue/global/compositionAPI/HttpAPI";

import {
  cloneDeep,
  get,
  map,
} from "lodash-es";

export default function AkkreditierungHochschuleAPI({
  akkreditierung,
  emit,
}) {
  const {
    putHttp,
  } = HttpAPI();

  const hochschultypSonstiges = "72e831d3-230b-483f-9f34-0bf2d5d49dce";
  const errors = ref({});
  const saveUrl = computed(() => {
    return `systemakkreditierungen/${ akkreditierung.value.pk }/hochschuldaten_aendern/`;
  });
  const hochschuleModelBackup = ref({});
  const hochschuleModel = ref({});
  const clearErrors = () => {
    errors.value = {};
  };
  const onErrors = errorsLocal => {
    if (errorsLocal.data) {
      errors.value = errorsLocal.data;
    } else {
      errors.value = errorsLocal;
    }
  };
  const initModel = () => {
    const hochschuleObj = get(akkreditierung.value, "hochschule_obj", {});
    hochschuleObj.typ = hochschuleObj.typ_id;
    hochschuleObj.traeger = hochschuleObj.traeger_id;
    hochschuleObj.bundesland = hochschuleObj.bundesland_id;
    hochschuleObj.standorte_update = map(hochschuleObj.standorte_kbez || [], ort_kbez => {
      return { bez: ort_kbez };
    });
    hochschuleModel.value = hochschuleObj;
    hochschuleModelBackup.value = cloneDeep(hochschuleModel.value);
  };
  const save = () => {
    clearErrors();
    const saveData = {
      hs_id: hochschuleModel.value.hs_id,
      hs_kbez: hochschuleModel.value.hs_kbez,
      hs_bez: hochschuleModel.value.hs_bez,
      hs_laufnr: hochschuleModel.value.hs_laufnr,
      typ: hochschuleModel.value.typ,
      hs_typ_freitext: hochschuleModel.value.hs_typ_freitext,
      traeger: hochschuleModel.value.traeger,
      hs_gericht: hochschuleModel.value.hs_gericht,
      hs_strasse: hochschuleModel.value.hs_strasse,
      hs_hsnr: hochschuleModel.value.hs_hsnr,
      hs_plz: hochschuleModel.value.hs_plz,
      hs_ort: hochschuleModel.value.hs_ort,
      bundesland: hochschuleModel.value.bundesland,
      hs_website: hochschuleModel.value.hs_website,
      standorte_update: map(hochschuleModel.value.standorte_update, standort => standort.bez),
    };

    return new Promise((resolve, reject) => {
      putHttp({
        url: saveUrl.value,
        data: saveData,
      }).then(
        response => {
          emit("onSave", response);
          resolve();
          setTimeout(() => {
            initModel();
          });
        },
        errors => {
          onErrors(errors);
          reject(errors);
        }
      );
    });
  };
  const close = () => {
    clearErrors();
    hochschuleModel.value = hochschuleModelBackup.value;
  };
  const formstepDetailOptions = computed(() => {
    return {
      saveCallback: save,
      closeCallback: close,
      label: "Hochschule",
    };
  });
  const htmlId = computed(() => {
    return `hochschule_${ get(hochschuleModel.value, "pk") }`;
  });
  const hskNummerLinkTitle = computed(() => {
    return `Zum Hochschulkompass wechseln für Hochschule Nr. ${ hochschuleModel.value.hs_laufnr }`;
  });
  const optionsList = computed(() => {
    return [
      {
        id: "hs_bez",
        htmlId: `akkreditierung_hochschule_hs_bez_${ hochschuleModel.value.pk }`,
        type: "text",
        view: "v",
        label: "Name",
        maxLength: 200,
        required: true,
      },
      {
        id: "hs_kbez",
        htmlId: `akkreditierung_hochschule_hs_kbez_${ hochschuleModel.value.pk }`,
        type: "text",
        view: "v",
        label: "Kurzbezeichnung",
        maxLength: 60,
        required: true,
      },
      {
        id: "hs_strasse",
        htmlId: `akkreditierung_hochschule_hs_strasse_${ hochschuleModel.value.pk }`,
        type: "text",
        view: "v",
        label: "Straße",
        maxLength: 100,
        required: true,
      },
      {
        id: "hs_hsnr",
        htmlId: `akkreditierung_hochschule_hs_hsnr_${ hochschuleModel.value.pk }`,
        type: "text",
        view: "v",
        label: "Hausnummer",
        maxLength: 10,
        required: true,
      },
      {
        id: "hs_plz",
        htmlId: `akkreditierung_hochschule_hs_plz_${ hochschuleModel.value.pk }`,
        type: "text",
        view: "v",
        label: "Postleitzahl",
        maxLength: 10,
        required: true,
      },
      {
        id: "hs_ort",
        htmlId: `akkreditierung_hochschule_hs_ort_${ hochschuleModel.value.pk }`,
        type: "text",
        view: "v",
        label: "Ort",
        maxLength: 100,
        required: true,
      },
      {
        id: "bundesland",
        htmlId: `akkreditierung_hochschule_bundesland_${ akkreditierung.value.pk }`,
        type: "select",
        view: "v",
        label: "Bundesland",
        required: true,
        keyLabel: "bun_bez",
        keyId: "bun_id",
        url: "bundeslaender/",
        emptyLabel: "---------",
        emptyValue: null,
        urlParams: {
          fields: ["pk", "bun_bez"],
        },
      },
      {
        id: "hs_website",
        htmlId: `akkreditierung_hochschule_hs_website_${ hochschuleModel.value.pk }`,
        type: "text",
        view: "v",
        label: "Webseite",
        maxLength: 100,
        required: true,
      },
      {
        id: "typ",
        htmlId: `akkreditierung_hochschule_typ_${ akkreditierung.value.pk }`,
        type: "select",
        view: "v",
        label: "Hochschultyp",
        required: true,
        keyLabel: "hst_kbez",
        keyId: "hst_id",
        url: "hochschultypen/",
        emptyLabel: "---------",
        emptyValue: null,
        urlParams: {
          fields: ["pk", "hst_kbez"],
        },
      },
      {
        id: "hs_typ_freitext",
        htmlId: `akkreditierung_hochschule_hs_typ_freitext_${ hochschuleModel.value.pk }`,
        type: "text",
        view: "v",
        label: "eigene Bezeichnung für den Hochschultyp",
        maxLength: 200,
        required: true,
        hideIf: hochschuleModel.value.typ !== hochschultypSonstiges,
      },
      {
        id: "traeger",
        htmlId: `akkreditierung_hochschule_traeger_${ akkreditierung.value.pk }`,
        type: "select",
        view: "v",
        label: "Träger",
        required: true,
        keyLabel: "tra_kbez",
        keyId: "tra_id",
        url: "traegerschaften/",
        emptyLabel: "---------",
        emptyValue: null,
        urlParams: {
          fields: ["pk", "tra_kbez"],
        },
      },
      {
        id: "hs_gericht",
        htmlId: `akkreditierung_hochschule_hs_gericht_${ hochschuleModel.value.pk }`,
        type: "text",
        view: "v",
        label: "Gerichtsstandort",
        maxLength: 250,
        required: true,
      },
      {
        id: "hs_laufnr",
        htmlId: `akkreditierung_hochschule_hs_laufnr_${ hochschuleModel.value.pk }`,
        type: "text",
        view: "v",
        label: "HSK Laufnummer",
        maxLength: 60,
        required: true,
      },
      {
        id: "standorte_update",
        type: "validated_json",
        label: "Standorte",
        view: "v-alt",
        many: true,
        as_json: false,
        required: false,
        element_label: "Standort",
        element_template: "{{bez}}",
        deactivate_ordering: true,
        deactivate_preview: true,
        hide_pos: true,
        fields: {
          bez: {
            prio: 0,
            bez: "Bezeichnung",
            help_text: null,
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "string"
          }
        }
      },
    ];
  });

  return {
    errors,
    formstepDetailOptions,
    hochschuleModel,
    hskNummerLinkTitle,
    htmlId,
    initModel,
    optionsList,
  };
}
