"use strict";

class DefaultEditController {
  constructor($q, $scope, DiasNotification) {
    this.$q = $q;
    this.$scope = $scope;
    this.DiasNotification = DiasNotification;
    this.onEnterEditMode();
    this.editId = this.getEditId();
    this.errorContainerId = "error_container_" + this.editId;
    $scope.$watch("editMode", () => {
      if (!$scope.editMode) {
        this.onEnterEditMode();
        this.errors = null;
        this.DiasNotification.form.clear();
      }
    });
    // _save notwendig damit unterklassen save überschreiben können und dennoch die Funktionalität aufrufen können
    this.save = errorsLabel => {
      return this._save(errorsLabel);
    };
    this.onError = this.onError.bind(this);
    this.onErrors = this.onErrors.bind(this);
  }

  onEnterEditMode() {
    this.model = this.$scope.model.clone();
  }

  _save(errorsLabel) {
    const promise = this.model.save().then(
      () => {
        this.$scope.model.merge(this.$scope.model, this.model.plain());
        this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
      },
      // this.onError
      errors => {
        return this.onErrors(errors, errorsLabel);
      }
    );
    return promise;
  }

  clone() {
    this.model = this.$scope.model.clone();
  }

  onError(error) {
    this.DiasNotification.form.clear();
    this.DiasNotification.form.error(error.data, "Ihre Daten sind fehlerhaft oder unvollständig.", { container: "#" + this.errorContainerId });
    this.errors = error.data;
    return this.$q.reject(error);
  }

  onErrors(errors, labelErrors) {
    this.DiasNotification.form.clear();
    this.DiasNotification.form.error(errors, "Ihre Daten sind fehlerhaft oder unvollständig.", { container: "#" + this.errorContainerId }, labelErrors);
    if (errors.data) {
      this.errors = errors.data;
    } else {
      this.errors = errors;
    }
    return this.$q.reject(errors);
  }


  getEditId() {
    return "edit_" + Math.random().toString(36).slice(2);
  }
}

export default DefaultEditController;
