"use strict";

import BaseModel from "dias/core/models/baseModel";

class FoerderfinderAngebot extends BaseModel {
  constructor() {
    super("angebote", {
      subPaths: {
      }
    });
  }
}

export default FoerderfinderAngebot;
