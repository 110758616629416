"use strict";

import angular from "angular";
import diasLoading from "dias/core/loading";

import terminreihe from "./terminreihe";


const foerdermodule = angular.module("dias.kalender.foerdermodule", [
  diasLoading.name,
  terminreihe.name,
]);

export default foerdermodule;
