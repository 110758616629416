import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "AntragCreateExternZuschuss",
  components: {
    PuxTranslate,
  },
  props: {
    zuschuss: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      filterCurrency,
    };
  },
  computed: {
    textZuschussTranslate() {
      if (this.zuschuss.max_foerdersatz && this.zuschuss.max_foerdersumme) {
        return "_TXT_ANTRAG_EXTERN_GESAMT_MAX_FOERDERSATZ_AND_MAX_FOERDERSUMME_{{max_foerdersatz}}_{{max_foerdersumme}}_";
      }
      if (this.zuschuss.max_foerdersatz && !this.zuschuss.max_foerdersumme) {
        return "_TXT_ANTRAG_EXTERN_GESAMT_MAX_FOERDERSATZ_AND_NOT_MAX_FOERDERSUMME_{{max_foerdersatz}}_";
      }
      return "_TXT_ANTRAG_EXTERN_GESAMT_NOT_MAX_FOERDERSATZ_AND_MAX_FOERDERSUMME_{{max_foerdersumme}}_";
    },

    extraForTranslate() {
      return {
        max_foerdersatz: this.zuschuss.max_foerdersatz,
        max_foerdersumme: this.filterCurrency(this.zuschuss.max_foerdersumme),
      };
    },
  },
};
