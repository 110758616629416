"use strict";


class DurchfuehrendeorgeinheitEditController {
  /*@ngInject*/
  constructor($q, $scope, Katalog, DiasNotification, i18n) {
    this.$q = $q;
    this.$scope = $scope;
    this.DiasNotification = DiasNotification;
    this.ast = $scope.extras.ast;
    this.dorg = {};
    this.gettext = i18n.gettext;
    Katalog.getKatalog("klaenderkuerzel").then(
      response => {
        this.lkzChoices = response;
        if (!this.dorg.lkz) {
          // Default-Auswahl setzen
          angular.forEach(this.lkzChoices, item => {
            if (item.lkz_default === true) {
              this.dorg.lkz = item.pk;
              return;
            }
          });
        }
      }
    );

    this.save = this.save.bind(this);
  }

  save() {
    return this.ast.durchfuehrendeorgeinheiten.post(this.dorg).then(
      response => {
        this.DiasNotification.page.success(this.gettext("_MSG_DUFO_CREATED_SUCCESS_"));
        return response;
      },
      error => {
        const errorsLabel = {
          do_orgname: this.gettext("_DUFO_MODAL_CREATE_ERROR_LABEL_ORGNAME_"),
          do_strasse: "Straße: ",
          do_hsnr: "Hausnummer: ",
          do_plz: "PLZ: ",
          do_ort: "Ort: ",
          lkz: "Land: ",
          do_beschreibung: "Beschreibung: "
        };

        this.DiasNotification.form.clear();
        this.DiasNotification.form.error(error.data, "Ihre Daten sind fehlerhaft oder unvollständig.", {}, errorsLabel);
        this.errors = error.data;
        return this.$q.reject(error);
      }
    );
  }
}

export default DurchfuehrendeorgeinheitEditController;
