import AnsprechpartnerZuweisen from "../AnsprechpartnerZuweisen/AnsprechpartnerZuweisen.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import {
  cloneDeep,
  forEach,
  isArray,
} from "lodash-es";

// @vue/component
export default {
  name: "AnsprechpartnerZuweisenButtonModal",
  components: {
    AnsprechpartnerZuweisen,
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    antrag: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "updateAnsprechpartner",
  ],
  data() {
    return {
      isModalOpen: false,
      loading: false,
      changedAnsprechpartner: undefined,
      errors: undefined,
      alertLabels: {
        n_id: "_LBL_ANSPRECHPARTNER_ZUWEISEN_NUTZER_",
        fsp_kommentar: "_LBL_ANSPRECHPARTNER_ZUWEISEN_KOMMENTAR_",
      },
    };
  },
  computed: {
    ansprechpartner() {
      return this.antrag.foerderorg_ansprechpartner || [];
    },

    optionsModal() {
      return {
        showCloseButton: true,
      };
    },

    urlSave() {
      return `foerderantraege/${ this.antrag.pk }/ansprechpartner_setzen/`;
    },
  },
  methods: {
    openModal() {
      this.setModel();
      this.isModalOpen = true;
    },

    setModel() {
      this.changedAnsprechpartner = cloneDeep(this.ansprechpartner);
    },

    save() {
      this.deleteErrors();
      this.loading = true;
      this.postHttp({
        url: this.urlSave,
        data: this.changedAnsprechpartner,
      }).then(
        response => {
          this.addNotification({
            text: "_MSG_ANSPRECHPARTNER_ZUGEWIESEN_SUCCESS_",
          });
          this.$emit("updateAnsprechpartner", {
            ansprechpartner: response.foerderorg_ansprechpartner,
          });
          this.closeModal();
        },
        errors => {
          this.onErrors(errors.data);
        }
      ).finally(
        () => this.loading = false
      );
    },

    onErrors(errors) {
      if (isArray(errors)) {
        let errors_local = {};
        forEach(errors, error => {
          errors_local = Object.assign({}, errors_local, error);
        });
        this.errors = errors_local;
      } else {
        this.errors = errors;
      }
    },

    deleteErrors() {
      this.errors = undefined;
      this.errorsFormElement = undefined;
    },

    closeModal() {
      this.isModalOpen = false;
    },

    onChanged(ansprechpartner) {
      this.changedAnsprechpartner = ansprechpartner;
    },
  },
};
