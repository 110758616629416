import template from "./details.jade";
import BaseController from "dias/formular/cortex/basisformular/details.controller.js";
import { findIndex } from "lodash";

import SaveCallbackActions from "../../../../../vue/client/vue/const/SaveCallbackActions";

import {
  MAPPING,
} from "../../../../../vue/client/vue/components/Geschaeftsregel/Module/Module";

import {
  get,
} from "lodash-es";

class AuszahlungslistenDetailController extends BaseController {
  /*@ngInject*/
  constructor(
    $q,
    $filter,
    $state,
    $stateParams,
    $timeout,
    $anchorScroll,
    $window,
    i18n,
    diasConfirmDialog,
    diasModalDialog,
    AuthService,
    DiasNotification,
    Formular,
    Foerderantrag,
    Vertrag,
    Antragstellerorganisationen,
    Sitzungen,
    Auszahlung,
    Auszahlungslisten,
  ) {
    super(
      $q,
      $filter,
      $state,
      $stateParams,
      $timeout,
      $anchorScroll,
      $window,
      i18n,
      diasConfirmDialog,
      diasModalDialog,
      AuthService,
      DiasNotification,
      Formular,
      Foerderantrag,
      Vertrag,
      Antragstellerorganisationen,
      Sitzungen,
      Auszahlung
    );
    this.$q = $q;
    this.MAPPING = MAPPING;
    this.loading.rubriken = true;
    this.gettext = i18n.gettext;
    this.Auszahlungslisten = Auszahlungslisten;

    this.notizen = {};
    this.stepMap = [
      "uebersicht",
      "formular"
    ];
    this.steps = [
      {
        key: "uebersicht",
        kbez: "Allgemeine Informationen"
      },
      {
        key: "formular",
        kbez: "Daten der Auszahlungsliste"
      },
    ];
    this.lastWfExecution = new Date().toISOString();
    this.updateWorkflowfolgen = () => this.lastWfExecution = new Date().toISOString();
    this.updateAuszahlungslisteModuleFromVue = this.updateAuszahlungslisteModuleFromVue.bind(this);
    this.kontextName = this.gettext("Auszahlungsliste");
    this.initRubriken().finally(
      () => this.loading.rubriken = false
    );
    this.updateModuleFromVue = this.updateModuleFromVue.bind(this);
    this.onWorkflowStarted = this.onWorkflowStarted.bind(this);
    this.onWorkflowFinish = this.onWorkflowFinish.bind(this);
    this.onWorkflowSuccess = this.onWorkflowSuccess.bind(this);
    this.saveCallback = this.saveCallback.bind(this);
  }

  saveCallback({ statusSaveCallback } = {}) {
    if (statusSaveCallback === SaveCallbackActions.RELOAD_WF) {
      this.updateWorkflowfolgen();
    } else if (statusSaveCallback === SaveCallbackActions.RELOAD_ALL) {
      this.loadFormular();
      this.updateWorkflowfolgen();
    }
  }

  loadFormular() {
    const defer = this.$q.defer();
    const getData = () => {
      if (!this.Auszahlungslisten) {
        setTimeout(getData, 50);
      }
      this.Auszahlungslisten.one(this.$stateParams.id).get().then(
        response => {
          if (this.formular && !this.formular.for_formulardatum && response.for_formulardatum) {
            this.eingereichtStatus = true;
          }
          this.auszahlungsliste = response;
          this.formular = response.plain();
          this.Formular.restangularizeElement(this.formular);
          this.updatePermissions();
          this.setUrl();
          defer.resolve(response);
        },
        error => {
          this.DiasNotification.page.error(error, "Fehler beim Laden der Auszahlungsliste");
          defer.reject(error);
        }
      );
    };
    setTimeout(getData);
    return defer.promise;
  }

  savePdf() {
    this.Auszahlungslisten.one(this.$stateParams.id).withHttpConfig({ responseType: "blob" }).customGET("pdf").then(
      response => {
        const fileName = "auszahlungsliste_" + this.formular.for_titel + ".pdf";
        if (this.$window.navigator.msSaveBlob) {
          this.$window.navigator.msSaveBlob(response, fileName);
        } else {
          const fileURL = (this.$window.URL || this.$window.webkitURL).createObjectURL(response);
          const aLink = this.$window.document.createElement("a");
          aLink.download = fileName;
          aLink.href = fileURL;
          aLink.target = "_self";
          const eventClick = this.$window.document.createEvent("MouseEvents");
          eventClick.initEvent(
            "click",
            true,
            true,
            this.$window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
          );
          aLink.dispatchEvent(eventClick);
        }
      }
    );
  }

  updateAuszahlungslisteModuleFromVue({ response }) {
    this.$timeout(() => {
      const INDEX = findIndex(this.module, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.module.splice(INDEX, 1, response);
      }
    });
  }

  groupModuleByRubrik(modules) {
    if (!modules || modules.length === 0) {
      return [];
    }
    const grouped = [];
    angular.forEach(modules, mod => {
      if (grouped[mod.regel.rubrik] === undefined) {
        grouped[mod.regel.rubrik] = [];
      }
      grouped[mod.regel.rubrik].push(mod);
    });
    return grouped;
  }

  initFixedRubriken() {
    this.stepMap.uebersicht = {
      kbez: this.gettext("Allgemeine Informationen"),
      bez: this.gettext("Allgemeine Informationen"),
      icon: "glyphicon-step_ubersicht",
      key: "uebersicht",
      template: "uebersicht",
      priority: 10,
      // number: 1,
      open: false
    };
    this.steps.push(this.stepMap.uebersicht);
  }

  initRubriken() {
    return this.Formular.one(this.$stateParams.id).rubriken.getList().then(
      response => {
        this.initFixedRubriken();
        this.anzeigekontextrubriken = response.plain();
        this.loading.module = true;
        this.Formular.one(this.$stateParams.id).module.getList().then(
          response => {
            this.module = response;
            let counter = this.steps.length + 1;
            this.grouped_module = this.groupModuleByRubrik(this.module);
            angular.forEach(this.anzeigekontextrubriken, axr => {
              if (this.grouped_module[axr.rubrik]) {
                this.stepMap[axr.axr_config.key] = {
                  kbez: axr.axr_config.label,
                  bez: axr.axr_config.label,
                  key: axr.axr_config.key,
                  rubrik: axr.rubrik,
                  template: "module",
                  icon: axr.axr_config.icon,
                  priority: counter * 10,
                  // number: counter
                  open: false
                };
                this.steps.push(this.stepMap[axr.axr_config.key]);
                counter ++;
              }
            });
          }
        ).finally(() => this.loading.module = false);
      }
    );
  }

  updateModuleFromVue({ group, response }) {
    this.$timeout(() => {
      if (group) {
        const INDEX = findIndex(this.grouped_module[group], ["regel.pk", response.regel.pk]);
        if (INDEX !== -1) {
          this.grouped_module[group].splice(INDEX, 1, response);
        }
      } else {
        const INDEX = findIndex(this.module[group], ["regel.pk", response.regel.pk]);
        if (INDEX !== -1) {
          this.module[group].splice(INDEX, 1, response);
        }
      }
    });
  }

  onWorkflowStarted(aufgabe, currentWorkflowfolge) {
    this.$timeout(() => {
      this.currentWorkflowfolge = currentWorkflowfolge;
    });
  }

  onWorkflowFinish() {
    this.$timeout(() => {
      this.currentWorkflowfolge = undefined;
    });
  }

  onWorkflowSuccess(aufgabe) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerSuccess(aufgabe);
      }
      this.resetValidation();
      this.scrollTop();
      if (get(this.currentWorkflowfolge, "wfo_reload")) {
        this.loading.module = true;
        const promise = this.$q.all([
          this.loadFormular(),
          this.initRubriken(),
        ]);
        promise.finally(() => {
          this.loading.module = false;
        });
        return promise;
      }
      return this.loadFormular();
    });
  }
}

export default {
  template: template(),
  controller: AuszahlungslistenDetailController,
  controllerAs: "vm"
};
