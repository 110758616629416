import WizardStep4Mixin from "../WizardStep4Mixin";
import FormElementReadOnly
  from "../../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenPeriodenWizardStep4DHW",
  components: {
    FormElementReadOnly,
  },
  mixins: [
    WizardStep4Mixin
  ],
  props: {
    parameter: { // TODO: ILIA. Das soll gelöscht werden.
      type: Object,
      required: false,
      default: undefined,
    },
    modelParameter: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      vorschau: {},
    };
  },
  computed: {
    betragOptions() {
      return {
        id: "betrag",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_KOSTENPOSITION_",
        type: "currency",
        view: "v-alt",
        helpText: this.vorschau.bezeichnung,
        translate: true,
        disabled: true,
      };
    },

    betragOptionsWithOriginal() {
      const OPTIONS = cloneDeep(this.betragOptions);
      OPTIONS.helpText = undefined;
      OPTIONS.label = "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_BETRAG_";
      return OPTIONS;
    },

    betragOriginalOptions() {
      return {
        id: "betrag_original",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_BETRAG_ORIGINAL_",
        type: "currency",
        view: "v-alt",
        helpText: this.vorschau.bezeichnung,
        translate: true,
        disabled: true,
      };
    },

    optionsTranslateFoerdersatz() {
      return {
        text: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_DAVON_FOERDERFAEHIG_{{foerdersatz}}_",
        extra: {
          foerdersatz: this.vorschau.foerdersatz,
        },
      };
    },

    zusaetzlicheValidierung() {
      if (this.parameter) {
        return this.parameter.zusaetzliche_validierung;
      }
      return this.modelParameter.zusaetzliche_validierung;
    },

    statusZusaetzlicheValidierung() {
      return this.zusaetzlicheValidierung && this.zusaetzlicheValidierung === "reduzierung_betrag";
    },
  },
  methods: {
    setVorschau({ response }) {
      this.vorschau = response.vorschau || {};
    },

    setDataForVorschau({ data }) {
      const DATA = cloneDeep(data);
      // DATA.bestandspersonal = !DATA.bestandspersonal;
      return DATA;
    },
  },
};
