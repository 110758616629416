"use strict";

import BaseModel from "dias/core/models/baseModel";

const grundlagendokklasse = "7f8dbeeb-6f67-483d-acf8-9d325a8643d3";

class KDokart extends BaseModel {
  constructor() {
    super("kdokart");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.getGrundlagenChoices = () => {
      const deferred = this.$q.defer();
      const data = [];
      collection.getList({ klasse: grundlagendokklasse }).then(result => {
        angular.forEach(result, item => {
          data.push({ value: item.pk, label: item.name });
        });
        deferred.resolve(data);
      });
      return deferred.promise;
    };
    return collection;
  }
}

export default KDokart;
