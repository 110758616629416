"use strict";

import BaseModel from "dias/core/models/baseModel";

class Belege extends BaseModel {
  constructor() {
    super("belege");
  }

  extendModel(model) {
    model = super.extendModel(model);

    model.anerkennenBeleg = data => model.customPOST(data, "anerkennen");

    model.zuruecksetzenBeleg = data => model.customPOST(data, "zuruecksetzen");

    model.ablehnenBeleg = () => model.customPOST({}, "ablehnen");

    return model;
  }
}

export default Belege;
