import {
  computed,
  ref,
  toRef,
} from "vue";

import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";

import {
  EventBus,
} from "../../../../../global/functions/event-bus";
import {
  initModelFromList,
  toFormElementFromParameter
} from "../../../../../global/functions/mappingForParameterToFormElement";
import {
  assign,
  get,
} from "lodash-es";

export default function SitzungModuleAPI(props, { emit }) {
  const module = toRef(props, "module");
  const moduleUrl = toRef(props, "moduleUrl");

  const model = ref({});
  const modelParameter = ref({});

  const htmlId = computed(() => {
    return `sitzung_module_${ get(module, "value.modul.pk") }`;
  });

  const modulname = computed(() => {
    return module.value.modulname;
  });

  const parameterObj = computed(() => {
    return toFormElementFromParameter({
      obj: module.value.modul.stm_parameterin,
    }).object;
  });

  const setModelParameter = () => {
    modelParameter.value = initModelFromList({ list: parameterObj.value });
  };

  const setModel = () => {
    model.value = assign({}, module.value.data);
  };

  const {
    getHttp,
    putHttp,
  } = HttpAPI();
  const saveModule = ({ model }) => {
    return new Promise((resolve, reject) => {
      putHttp({
        url: moduleUrl.value,
        data: model,
      }).then(
        response => {
          emit("updateModule", { response });
          setTimeout(() => {
            setModelParameter();
            setModel();
          });
          resolve(response);
        },
        errors => {
          reject(errors);
        }
      );
    });
  };

  const reloadModule = () => {
    getHttp({
      url: moduleUrl.value,
    }).then(
      response => {
        emit("updateModule", { response });
        setTimeout(() => {
          setModelParameter();
          setModel();
        });
      }
    );
  };

  const sitzungModuleEventName = "sendSitzungModuleEvent";
  const sendSitzungModuleEvent = arg => {
    EventBus.$emit(sitzungModuleEventName, arg);
  };

  setModelParameter();
  setModel();

  return {
    htmlId,
    model,
    modelParameter,
    modulname,
    parameterObj,
    reloadModule,
    saveModule,
    sendSitzungModuleEvent,
    sitzungModuleEventName,
  };
}
