import UiSelect from "../../../global/components/ui/UiSelect/UiSelect.vue";

// @vue/component
export default {
  name: "vue-styleguide-ui-select",
  components: {
    UiSelect,
  },
  data() {
    return {
      dataList: [
        {
          pk: "0001",
          label: "Aloha 0001",
          group: "Gruppe 1"
        },
        {
          pk: "0002",
          label: "Aloha 0002",
        },
        {
          pk: "0003",
          label: "Aloha 0003",
          group: "Gruppe 1"
        },
        {
          pk: "0004",
          label: "Aloha 0004",
          group: "Gruppe 1"
        },
        {
          pk: "0005",
          label: "Aloha 0005",
        },
        {
          pk: "0006",
          label: "Aloha 0006",
          group: "Gruppe 2"
        },
        {
          pk: "0007",
          label: "Aloha 0007",
        },
        {
          pk: "0008",
          label: "Aloha 0008",
          group: "Gruppe 2"
        },
        {
          pk: "0009",
          label: "Aloha 0009",
          group: "Gruppe 2"
        },
        {
          pk: "0010",
          label: "Aloha 0010",
        },
      ],
      model: {
        demo1: undefined,
        demo2: "0003",
        demo3: ["0002", "0005"],
        demo4: undefined,
      },
      props: [
        {
          label: "data",
          type: "Array",
          default: "[ ]",
          description: "Liste der Werte",
          required: true,
        },

        {
          label: "disabled",
          type: "Boolean",
          default: "false",
          description: "Der Parameter deaktiviert alle Knöpfe.",
          required: false,
        },

        {
          label: "loading",
          type: "Boolean",
          default: "false",
          description: `Der Parameter deaktiviert alle Knöpfe und zeigt einen Platzhalter für 'loading'.`,
          required: false,
        },

        {
          label: "model",
          type: "Array",
          default: `[ ]`,
          description: `Das Modell für 'multiselect'.`,
          required: false,
        },
        {
          label: "v-model",
          type: "String",
          default: "",
          description: `Das Modell für 'singleselect'.`,
          required: false,
        },


        {
          label: "FUNCTIONS",
          type: "",
          default: "",
          description: "",
          required: false,
        },
        {
          label: "on-change",
          type: "Function",
          default: "() => {}",
          description: `Die Funktion wird aufgerufen, wenn sich etwas geändert hat.`,
          required: false,
        },

        {
          label: "options",
          type: "Object",
          default: "{}",
        },

      ],
      options: [
        {
          label: "button-class",
          type: "String",
          default: `""`,
          description: "Zusätzliche Klassen für den Knopf.",
          required: false,
        },

        {
          label: "button-delete",
          type: "Boolean",
          default: "false",
          description: "Das Flag zeigt einen Knopf 'X' und ist nur für 'Singleselect' relevant. Dieser Knopf macht das Modell leer.",
          required: false,
        },
        {
          label: "close-click",
          type: "Boolean",
          default: "true",
          description: "Das Kennzeichen, ob die Liste geschlossen wird, wenn ich einen Wert ausgewählt habe.",
          required: false,
        },
        {
          label: "component-id",
          type: "String",
          default: "ui-select",
          description: "Eine eindeutige Kennung, die wir in Funktionen zurückerhalten. Und das ist die id für den Button.",
          required: true,
        },
        {
          label: "count-multi",
          type: "Number",
          default: "3",
          description: "Der Parameter ist nur für 'Multiselect' relevant. Wenn 'model.length > countMulti', zeigt man '{{ model.length }} ausgewählt'.",
          required: false,
        },
        {
          label: "deselect-all",
          type: "Boolean",
          default: "false",
          description: "Der Parameter ist nur für 'Multiselect' relevant. Alle Werte demarkieren.",
          required: false,
        },
        {
          label: "fixed-placeholder",
          type: "Boolean",
          default: "false",
          description: `Html für den Knopf, der immer sichtbar bleibt. Man muss <template slot="fixed-placeholder"></template> dazu benutzen.`,
          required: false,
        },
        {
          label: "key-id",
          type: "String",
          default: "id",
          description: `Key für einen Wert in der Liste.`,
          required: false,
        },
        {
          label: "key-label",
          type: "String",
          default: "name",
          description: `Key für einen Namen in der Liste.`,
          required: false,
        },
        {
          label: "key-group",
          type: "String",
          default: "",
          description: `Key für die Gruppierung in der Liste.`,
          required: false,
        },
        {
          label: "max-height",
          type: "String",
          default: "300px",
          description: `'max-width' für die Dropdown-Liste.`,
          required: false,
        },
        {
          label: "menu-right",
          type: "Boolean",
          default: "false",
          description: `Der Parameter macht die Liste rechtsbündig.`,
          required: false,
        },
        {
          label: "menu-styles",
          type: "String",
          default: `""`,
          description: `Styles für die Liste.`,
          required: false,
        },
        {
          label: "typ",
          type: "Boolean",
          default: "false",
          description: `Das Flag für 'multiselect'.`,
          required: false,
        },
        // #nicht gefunden
        {
          label: "required",
          type: "Boolean",
          default: "false",
          description: `Pflicht oder nicht.`,
          required: false,
        },
        {
          label: "search",
          type: "Boolean",
          default: "false",
          description: `Die Suche initialisieren.`,
          required: false,
        },
        {
          label: "search-list",
          type: "Array",
          default: `["name"]`,
          description: `Liste der zu durchsuchenden Felder.`,
          required: false,
        },
        {
          label: "select-all",
          type: "Boolean",
          default: "false",
          description: `Der Parameter ist nur für 'Multiselect' relevant. Alle Werte markieren.`,
          required: false,
        },
        {
          label: "show-caret",
          type: "Boolean",
          default: "true",
          description: `Pfeil nach unten zeigen.`,
          required: false,
        },
        {
          label: "texts",
          type: "Object",
          default: `{
            button: "Wählen Sie bitte",
            loading: "Loading",
            search: "Suche",
          }`,
          description: `Die Texte`,
          required: false,
        },
        {
          label: "FUNCTIONS",
          type: "",
          default: "",
          description: "",
          required: false,
        },
        {
          label: "search-outside",
          type: "Function",
          default: "",
          description: `Funktion zum Suchen außerhalb dieser Komponente.`,
          required: false,
        },
      ],

    };
  },
  methods: {
    changeSelect1(model, obj, componentId) {
      console.info(model, obj, componentId);
    },
  },
};
