import {
  computed,
} from "vue";

import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import SitzungsartenModalAPI from "./compositionAPI/SitzungsartenModalAPI";
import SitzungsartenModuleAPI from "./compositionAPI/SitzungsartenModuleAPI";
import SitzungsartenNotGroupedAPI from "./compositionAPI/SitzungsartenNotGroupedAPI";
import SitzungsartenTextsAPI from "./compositionAPI/SitzungsartenTextsAPI";

const UNIX_TIME = new Date().getTime();

// @vue/component
export default {
  name: "SitzungsartTagesordnungModulCreateEditButtonModal",
  components: {
    FormElement,
    Modal,
    PuxButton,
    PuxTranslate,
  },
  props: {
    tagesordnungspunktModuleUrl: {
      type: String,
      required: true,
    },
    buttonId: {
      type: String,
      required: false,
      default: () => `sitzungsart_btn_modal_${ UNIX_TIME }`,
    },
    isCreate: {
      type: Boolean,
      required: true,
    },
    toId: {
      type: String,
      required: true,
    },
    modul: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    modulIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  emits: [
    "updateModul",
  ],
  setup(props, context) {
    const {
      model,
      optionsKbez,
      selectorClose,
    } = SitzungsartenNotGroupedAPI(props);

    const {
      buttonIcon,
      buttonText,
      buttonTitle,
      buttonTypeClass,
      modalHeaderText,
    } = SitzungsartenTextsAPI(props);

    const {
      currentSitzungModul,
      loadSitzungsmodulKatalog,
      modelParameter,
      optionsModul,
      parameterList,
    } = SitzungsartenModuleAPI(props, {
      model,
    });

    const {
      closeModal,
      errors,
      isModalVisible,
      loading,
      openModal,
      optionsModal,
      save,
    } = SitzungsartenModalAPI(props, context, {
      currentSitzungModul,
      loadSitzungsmodulKatalog,
      model,
      modelParameter,
      parameterList,
    });

    const errorsLabelsOptions = computed(() => {
      return [
        optionsKbez,
        optionsModul.value,
        ...parameterList.value,
      ];
    });

    return {
      errorsLabelsOptions,

      model,
      optionsKbez,
      selectorClose,

      buttonIcon,
      buttonText,
      buttonTitle,
      buttonTypeClass,
      modalHeaderText,

      currentSitzungModul,
      modelParameter,
      optionsModul,
      parameterList,

      closeModal,
      errors,
      isModalVisible,
      loading,
      openModal,
      optionsModal,
      save,
    };
  },
};
