import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import {
  forEach,
  keyBy,
} from "lodash-es";

const SNAPSHOT_CHANGES_LIST = [
  "gesperrt",
  "sperrbemerkung",
];

// @vue/component
export default {
  name: "AntragstellerPruefung",
  components: {
    SnapshotModule,
    SnapshotIcon,
  },
  mixins: [
    ModuleMixin,
  ],
  computed: {
    labelHeader() {
      return "_TXT_GR_ANTRAGSTELLER_PRUEFUNG_HEADER_";
    },

    snapshotModulePruefungenObj() {
      let pruefungenObj = {};
      if (this.snapshotModule && this.snapshotModule.pruefungen) {
        pruefungenObj = keyBy(this.snapshotModule.pruefungen, "pk");
      }
      return pruefungenObj;
    },

    diff() {
      const SNAPSHOT = {
        changes: {},
        status: false,
      };
      if (this.statusSnapshot) {
        const SNAPSHOT_MODULE = this.snapshotModule || {};
        const MODEL = this.model || {};
        forEach(SNAPSHOT_CHANGES_LIST, item => {
          if (MODEL[item] !== SNAPSHOT_MODULE[item]) {
            SNAPSHOT.changes[item] = true;
            SNAPSHOT.status = true;
          }
        });
        if (MODEL.pruefungen) {
          forEach(MODEL.pruefungen, item => {
            const CURRENT_SNAPSHOT = this.snapshotModulePruefungenObj[item.pk] || {};
            if (item.opr_geprueft !== CURRENT_SNAPSHOT.opr_geprueft ||
              item.opr_gueltig_bis !== CURRENT_SNAPSHOT.opr_gueltig_bis) {
              SNAPSHOT.changes[item.pk] = true;
              SNAPSHOT.status = true;
            }
          });
        }
      }
      return SNAPSHOT;
    },

    statusDiff() {
      return this.diff.status;
    },

    isModuleEditable() {
      return false;
    },
  },
};
