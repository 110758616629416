"use strict";

class BenachrichtigungSendDetailController {
  /*@ngInject*/
  constructor($q, $scope, $filter, Empfaenger, DiasNotification, Benachrichtigungen, KNutzerstatusUUIDs) {
    this.Empfaenger = Empfaenger;
    this.DiasNotification = DiasNotification;
    this.Benachrichtigungen = Benachrichtigungen;
    this.KNutzerstatusUUIDs = KNutzerstatusUUIDs;
    this.selected_empfaenger = [];
    this.titel = $scope.extras.titel;
    this.text = $scope.extras.text;
    this.feste_empfaenger = $scope.extras.feste_empfaenger;
    this.bezug = $scope.extras.bezug;
    this.bezugpk = $scope.extras.bezugpk;
    this.bezugtext = $scope.extras.bezugtext;
    this.weiterleitung = $scope.extras.weiterleitung;
    this.antwort = $scope.extras.antwort;
    this.savecallback = $scope.extras.savecallback;
    this.disableEmpfaengerSelect = false;

    // Bereits eindeutig vorgegebener Empfänger: vorbelegen:
    if (this.feste_empfaenger) {
      const inaktiv = [];
      this.disableEmpfaengerSelect = this.feste_empfaenger.length > 0;
      for (let i = 0; i < this.feste_empfaenger.length; i++) {
        if (angular.isString(this.feste_empfaenger[i])) {
          this.selected_empfaenger.push({ id: this.feste_empfaenger[i] });
        } else if (angular.isObject(this.feste_empfaenger[i]) && this.isUserAktiv(this.feste_empfaenger[i])) {
          this.selected_empfaenger.push({
            id: this.feste_empfaenger[i].pk,
            name: this.feste_empfaenger[i].name });
        } else {
          inaktiv.push(this.feste_empfaenger[i]);
        }
      }
      if (inaktiv.length > 0) {
        let usrList = "";
        for (let i = 0; i < inaktiv.length; i++) {
          usrList += `<li>${ inaktiv[i].name }</li>`;
        }
        const errorStr = `Folgende vorausgewählte Empfänger sind nicht mehr aktiv und wurden aus der Empfängerliste entfernt:<ul>${ usrList }</ul>`;
        this.onError({
          data: { non_field_error: [errorStr] }
        });
      }
    }

    this.save = () => {
      const ben_nids = [];
      for (let i = 0; i < this.selected_empfaenger.length; i++) {
        ben_nids.push(this.selected_empfaenger[i].id);
      }
      const defer = $q.defer();
      const data = {
        titel: this.titel,
        text: this.text,
        bezug: this.bezug,
        bezugpk: this.bezugpk,
        empfaenger: ben_nids,
        weiterleitung: this.weiterleitung,
        antwort: this.antwort,
      };
      const promise = this.Benachrichtigungen.send(data);
      promise.then(
        () => {
          this.DiasNotification.page.success("Nachricht wurde versendet.");
          if (this.savecallback) {
            this.savecallback()();
          }
          defer.resolve();
        },
        error => {
          defer.reject();
          this.onError(error);
        }
      );
      return defer.promise;
    };
  }

  isUserAktiv(usr) {
    return usr.aktiv || (usr.status && this.KNutzerstatusUUIDs.isActive(usr.status.ns_id));
  }

  isReady() {
    return this.text && this.text.length > 0 &&
           this.titel && this.titel.length > 0 &&
           this.selected_empfaenger.length > 0;
  }

  onError(error) {
    const errorsLabel = {
      empfaenger: "Empfänger: ",
      titel: "Betreff: ",
      text: "Text: "
    };
    this.DiasNotification.form.clear();
    this.DiasNotification.form.error(
      error.data,
      "Bitte überprüfen Sie Ihre Eingaben",
      {},
      errorsLabel
    );
    this.errors = error.data;
  }
}

export default BenachrichtigungSendDetailController;
