import ModuleMixin from "../ModuleMixin";

// @vue/component
export default {
  name: "Antragstellerregel",
  mixins: [
    ModuleMixin,
  ],
  computed: {
    labelHeader() {
      return this.regel[`${ this.modulePrefix }_kbez`];
    },

    bezeichnung() {
      return this.regel[`${ this.modulePrefix }_bezeichnung`];
    },

    isModuleEditable() {
      return false;
    },
  },
};
