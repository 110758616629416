import {
  FilterCurrencyMixin,
} from "../../../../../global/mixins/FiltersMixin";
import SyConfigMixin from "../../../../../global/mixins/SyConfigMixin";
import InfoproviderStatuses from "../../../../../const/InfoproviderStatuses";
import {
  gettext,
} from "../../../../../global/functions/utils";
import {
  get,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "KofiErfassungModulProzent",
  mixins: [
    FilterCurrencyMixin,
    SyConfigMixin,
  ],
  props: {
    betragKey: {
      type: String,
      required: true,
      info: "der Schlüssel des Betrags, kann 'wert', 'erfasst' oder 'anerkannt' sein",
    },
    kofi: {
      type: Object,
      required: true,
      info: "Hauptinfo",
    },
    labelKoFi: {
      type: String,
      required: true,
      info: "KoFi Label",
    },
    koFiStatuses: {
      type: Object,
      required: true,
      info: "bewilligt, erfasst, anerkannt",
    },
    modul: {
      type: Object,
      required: true,
      default: () => ({}),
      info: "Geschaeftsregel-Modul",
    },
    infoprovider: {
      type: Object,
      required: false,
      default: undefined,
      info: "Infoprovider",
    },
    obj: {
      type: Object,
      required: true,
      info: "Objekt",
    },
  },
  computed: {
    regelPk() {
      return get(this.modul, "regel.pk");
    },

    infoproviderProzent() {
      return get(this.infoprovider, `details[${ this.regelPk }].prozent`);
    },

    infoproviderKennzahlenSummeKosten() {
      return get(this.infoprovider, "kennzahlen.summe_foerderfaehige_kosten");
    },

    objUebersichtVerschlagteKosten() {
      return get(this.obj, "uebersicht.veranschlagte_kosten");
    },

    percent() {
      if (this.betragKey.includes("anerkannt")) {
        return this.getPercent("anerkannt");
      } else if (this.betragKey.includes("erfasst")) {
        return this.getPercent("erfasst");
      }
      return this.getPercent("bewilligt");
    },

    koFiConfig() {
      return this.getSyConfigsValue("kofi_config", {});
    },

    statusShowProzente() {
      if (get(this.koFiConfig, `auszahlung_${ this.labelKoFi }_prozente_anzeigen`)) {
        return true;
      }
      // Wenn eine Zuschussberechnung durchgeführt werden konnte:
      if (this.infoprovider && this.infoprovider.status === InfoproviderStatuses.PROCESSOR_STATUS_OK) {
        return this.infoproviderProzent !== null;
      }
      return false;
    },
  },
  methods: {
    getPercent(type) {
      let p;
      let title;
      let base;
      if (this.labelKoFi === "finanzierung") {
        const EXTERNE_MITTEL_WERT = get(this.modul, "regel.argument.externe_mittel.wert");
        if (EXTERNE_MITTEL_WERT) {
          title = gettext("_TXT_KOFI_ERFASSUNG_EXTERNE_MITTEL_TITLE_");
          return `<br><small class="procent" title="${ title }">-</small>`;
        }
        p = this.getPercentKostenFinanzierung(type, "vf");
        title = gettext("_TXT_KOFI_ERFASSUNG_VON_DEN_FOERDERFAEHIGEN_KOSTEN_TITLE_");
        if (this.infoprovider && type === "anerkannt") {
          base = this.infoproviderKennzahlenSummeKosten;
        } else {
          base = this.objUebersichtVerschlagteKosten;
        }
      } else {
        p = this.getPercentKostenFinanzierung(type, "vk");
        title = gettext("_TXT_KOFI_ERFASSUNG_VON_DEN_GESAMTKOSTEN_TITLE_");
        base = this.koFiStatuses[type];
      }
      if (!isUndefined(p) && !isNaN(p)) {
        const pStr = this.filterCurrency(Number(p), null);
        let kostenStr = "";
        if (base) {
          kostenStr = ` (${ this.filterCurrency(base) })`;
        }
        return `<br><small class="procent" title="${ pStr }% ${ title }${ kostenStr }">${ pStr }&nbsp;%</small>`;
      }
      return "";
    },

    getPercentKostenFinanzierung(type, prefix) {
      if (type === "erfasst" && prefix === "vf") {
        return undefined;
      }
      if (type === "anerkannt" &&
        this.regelPk &&
        this.infoproviderProzent !== undefined) {
        return this.infoproviderProzent;
      } else if (type === "anerkannt" &&
        this.kofi[`${ prefix }_prozent_aktualisiert`]) {
        return this.kofi[`${ prefix }_prozent_aktualisiert`];
      } else if (type === "anerkannt" &&
        this.infoproviderKennzahlenSummeKosten &&
        this.kofi[`${ prefix }_betrag_anerkannt`]) {
        return 100 * Number(this.kofi[`${ prefix }_betrag_${ type }`]) / this.infoproviderKennzahlenSummeKosten;
      } else if (type === "bewilligt" &&
        this.kofi[`${ prefix }_prozent_bewilligt`]) {
        return this.kofi[`${ prefix }_prozent_bewilligt`];
      } else if (type === "bewilligt" &&
        this.objUebersichtVerschlagteKosten &&
        this.kofi[`${ prefix }_betrag_${ type }`]) {
        return 100 * Number(this.kofi[`${ prefix }_betrag_${ type }`]) / this.objUebersichtVerschlagteKosten;
      } else if (get(this.koFiStatuses, type) !== undefined &&
        this.kofi[`${ prefix }_betrag_${ type }`] !== undefined) {
        return 100 * Number(this.kofi[`${ prefix }_betrag_${ type }`]) / this.koFiStatuses[type];
      }
      return undefined;
    },
  },
};
