import PeriodModalTeilenMixin from "../../PeriodModalTeilenMixin";
import translate from "../../../../../../../global/directives/translate";
import PuxButton from "../../../../../../../global/components/PuxButton/PuxButton.vue";
import {
  round,
} from "lodash-es";


// @vue/component
export default {
  name: "PeriodModalTeilen",
  directives: {
    translate,
  },
  components: {
    PuxButton,
  },
  mixins: [
    PeriodModalTeilenMixin,
  ],
  data() {
    return {
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "period1",
            type: "template",
            html: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_PERIODE_TEILEN_MODAL_PERIODE1_",
            translate: true,
          },
          {
            id: "von1",
            type: "date",
            label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_PERIODE_TEILEN_MODAL_BEGINN1_",
            required: true,
            view: "v",
            notBefore: undefined,
            notAfter: undefined,
            defaultValue: undefined,
            change: this.changeDate,
            translate: true,
          },
          {
            id: "bis1",
            type: "date",
            label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_PERIODE_TEILEN_MODAL_ENDE1_",
            required: true,
            view: "v",
            notBefore: undefined,
            notAfter: undefined,
            defaultValue: undefined,
            change: this.changeDate,
            translate: true,
          },
          {
            id: "an_bruttogehalt1",
            type: "currency",
            label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_PERIODE_TEILEN_MODAL_AN_GEHALT1_",
            required: true,
            view: "v",
            translate: true,
          },
          {
            id: "ag_bruttogehalt1",
            type: "currency",
            label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_PERIODE_TEILEN_MODAL_AG_GEHALT1_",
            required: true,
            view: "v",
            disabled: true,
            translate: true,
          },
          {
            id: "gehalt1",
            type: "currency",
            label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_PERIODE_TEILEN_MODAL_FOERDERFAEHIGE1_",
            required: true,
            view: "v",
            disabled: true,
            translate: true,
          },
          {
            id: "period2",
            type: "template",
            html: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_PERIODE_TEILEN_MODAL_PERIODE1_",
            translate: true,
          },
          {
            id: "von2",
            type: "date",
            label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_PERIODE_TEILEN_MODAL_BEGINN2_",
            required: true,
            view: "v",
            notBefore: undefined,
            notAfter: undefined,
            defaultValue: undefined,
            change: this.changeDate,
            translate: true,
          },
          {
            id: "bis2",
            type: "date",
            label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_PERIODE_TEILEN_MODAL_ENDE2_",
            required: true,
            view: "v",
            notBefore: undefined,
            notAfter: undefined,
            defaultValue: undefined,
            change: this.changeDate,
            translate: true,
          },
          {
            id: "an_bruttogehalt2",
            type: "currency",
            label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_PERIODE_TEILEN_MODAL_AN_GEHALT2_",
            required: true,
            view: "v",
            translate: true,
          },
          {
            id: "ag_bruttogehalt2",
            type: "currency",
            label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_PERIODE_TEILEN_MODAL_AG_GEHALT2_",
            required: true,
            view: "v",
            disabled: true,
            translate: true,
          },
          {
            id: "gehalt2",
            type: "currency",
            label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_PERIODE_TEILEN_MODAL_FOERDERFAEHIGE2_",
            required: true,
            view: "v",
            disabled: true,
            translate: true,
          },
        ],
      },
    };
  },
  created() {
    this.initModel();
    this.setDateRange();
  },
  methods: {
    initModel() {
      this.model = {
        von1: this.period.von,
        von2: undefined,
        bis1: undefined,
        bis2: this.period.bis,
        an_bruttogehalt1: this.period.an_bruttogehalt,
        ag_bruttogehalt1: this.period.ag_bruttogehalt,
        an_bruttogehalt2: this.period.an_bruttogehalt,
        ag_bruttogehalt2: this.period.ag_bruttogehalt,
        gehalt1: this.period.gehalt,
        gehalt2: this.period.gehalt,
      };
    },

    changeModel({ id }) {
      const SUFFIX = id.indexOf("1") !== -1 ? "1" : "2";
      const {
        PKT_PAUSCHALE,
        STELLENUMFANG_PROJEKT,
        VOLLZEITSTELLE,
      } = this.infoForBruttoGehalt;
      const A_N_BRUTTO = this.model[`an_bruttogehalt${ SUFFIX }`];
      this.model[`gehalt${ SUFFIX }`] = round((A_N_BRUTTO * (1 + PKT_PAUSCHALE / 100)) * STELLENUMFANG_PROJEKT / VOLLZEITSTELLE, 2);
    },
  },
};
