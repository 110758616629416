import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

const TABLE_ID = "ObjectGutachtenReadonly";

// @vue/component
export default {
  name: "ObjectGutachtenReadonly",
  components: {
    PuxIcon,
    PuxTranslate,
    SmartTable,
  },
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    tableOptions() {
      return {
        id: TABLE_ID,
        url: this.gutachtenUrl,
        cols: [
          {
            label: "_TXT_GUTACHTEN_TYP_",
            id: "typ_bez",
          },
          {
            label: "_TXT_GUTACHTEN_BEZEICHNUNG_",
            id: "ga_kbez",
          },
          {
            label: "_TXT_GUTACHTEN_ERGEBNIS_",
            id: "ergebnis",
            slot: "ergebnis",
          },
        ],
        hideTitle: true,
        sortable: false,
        pagination: false,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
        csvHide: true,
        trClassCallback: ({ row }) => `border-${ row.css_klasse }`,
      };
    },

    gutachtenUrl() {
      return `${ this.baseUrl }gutachten/`;
    },
  },
};
