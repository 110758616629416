import FormElement from "../FormElement/FormElement.vue";
import PuxCloak from "../PuxCloak/PuxCloak.vue";
import HttpMixin from "../../mixins/HttpMixin";
import SyConfigMixin from "../../mixins/SyConfigMixin";

import {
  cloneDeep,
  forEach,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "FoerderorgSelect",
  components: {
    FormElement,
    PuxCloak,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
  ],
  props: {
    errors: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    changeModel: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      data: {},
      foerderorgTypen: [],
      model: {},
      loadingFoerderorgTypen: true,
    };
  },
  computed: {
    getOptions() {
      return ({ type, indexType }) => {
        const OPTIONS = {
          type: "select",
          id: `foerderorganisation_${ type.pos }`,
          label: type.bez,
          keyId: "pk",
          keyLabel: "o_name",
          view: "v",
          index: indexType,
          search: true,
          searchList: ["o_name"],
          required: true,
          deselect: false,
          helpText: `_TXT_SELECT_FOERDERORGANISATION_${ type.pos }_HELPTEXT_`,
          translate: true,
        };
        return this.checkAstDefault({ options: OPTIONS, type: type });
      };
    },

    getErrors() {
      return indexType => {
        if (this.errors && this.errors.foerderorganisation && !this.data[indexType + 1]) {
          return this.errors.foerderorganisation;
        }
      };
    },

    checkAstDefault() {
      return ({ options, type }) => {
        const conf = this.getSyConfigsValue("ast_default");
        if (!conf) {
          return options;
        }
        options.deselect = !conf.untergeordnete_orgs_pflicht && type.pos === this.foerderorgTypen.length;
        options.search = type.pos === 1 ? conf.org_suche : conf.untergeordnete_org_suche;
        if (conf.untergeordnete_keine_auswahl && type.pos > 1) {
          options.emptyLabel = "_LBL_SELECT_FOERDERORGANISATION_UNTERGEORDNETE_EMPTY_";
          options.emptyValue = "null";
        }
        return options;
      };
    },
  },
  created() {
    this.loadFoerderorgTypen();
  },
  methods: {
    loadFoerderorgTypen() {
      this.getListHttp({ url: "foerderorgtypen/", apiSaveId: "foerderorgtypen" }).then(
        response => {
          this.filterFoerderorgTypen({ response });
          this.loadingFoerderorgTypen = false;
          this.loadForderorganisation({ type: this.foerderorgTypen[0].filter.typ, typeIndex: 0 });
        }
      );
    },

    loadForderorganisation({ type, typeIndex, uebergeordnete_org }) {
      const URL_PARAMS = {
        fields: ["pk", "o_id", "typ", "o_otid", "o_name"],
        typ: type,
        uebergeordnete_org,
      };
      this.getListHttp({ url: "info/foerderorganisationen/", urlParams: URL_PARAMS }).then(
        response => {
          const DATA = cloneDeep(this.data);
          DATA[typeIndex] = response;
          this.data = DATA;
        }
      );
    },

    filterFoerderorgTypen({ response }) {
      const tmp = {};
      forEach(response, item => {
        tmp[item.ot_pos] = tmp[item.ot_pos] || { pos: item.ot_pos, typen: [], bez: "", data: null, selected: [] };
        tmp[item.ot_pos].typen.push(item);
      });
      forEach(tmp, item => {
        item.typen.sort((a, b) => a.ot_kbez.localeCompare(b.ot_kbez));
        item.bez = item.typen[0].ot_kbez;
        item.filter = { typ: [item.typen[0].pk] };
        for (let i = 1; i < item.typen.length; i++) {
          item.bez = `${ item.bez } / ${ item.typen[i].ot_kbez }`;
          item.filter.typ.push(item.typen[i].pk);
        }
        this.foerderorgTypen.push(item);
      });
      this.foerderorgTypen.sort((a, b) => a.ot_pos - b.ot_pos);
    },

    onChangeModel({ options }) {
      const CURRENT_INDEX = options.index;
      this.changeModel({ model: this.model[CURRENT_INDEX] });
      if (isUndefined(this.model[CURRENT_INDEX]) || this.model[CURRENT_INDEX] === "null") {
        if (CURRENT_INDEX > 0) {
          this.changeModel({ model: this.model[CURRENT_INDEX - 1] });
        } else {
          this.changeModel({ model: undefined });
        }
        return;
      }
      if (CURRENT_INDEX >= this.foerderorgTypen.length - 1) {
        return;
      }
      const DATA = cloneDeep(this.data);
      DATA[CURRENT_INDEX + 1] = undefined;
      this.data = DATA;
      if (!this.model[CURRENT_INDEX]) {
        return;
      }
      const UEBERGEORGNETE_ORG = this.model[CURRENT_INDEX];
      this.loadForderorganisation({ type: this.foerderorgTypen[CURRENT_INDEX + 1].filter.typ, typeIndex: CURRENT_INDEX + 1, uebergeordnete_org: UEBERGEORGNETE_ORG });
    },
  },
};
