"use strict";

import angular from "angular";

class DiasQComponent {
  /*@ngInject*/
  constructor($q) {
    this.$q = $q;
  }

  requestAll(requestPromises) {
    const defer = this.$q.defer();

    this.$q.allSettled(requestPromises).then(
      results => {
        defer.resolve(results);
      },
      error_results => {
        // collect error data from results:
        const collectedErrorData = [];
        angular.forEach(error_results, result => {
          if (result) {
            collectedErrorData.push(result.data);
          }
        });
        // merge error data to one errors object and reject
        const errors = angular.extend({}, ...collectedErrorData);
        defer.reject(errors);
      }
    );

    return defer.promise;
  }
}

export default DiasQComponent;
