import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import VertragSnapshotMixin from "../VertragSnapshotMixin";
import {
  CheckPermissionsSyncMixin,
} from "../../../../global/mixins/PermissionMixin";

import {
  cloneDeep,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragAbschlag",
  components: {
    FormElement,
    FormstepDetail,
    PuxAlert,
    PuxTranslate,
    SnapshotIcon,
    SnapshotModule,
  },
  mixins: [
    CheckPermissionsSyncMixin,
    HttpMixin,
    VertragSnapshotMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    vertrag: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "updateVertrag",
  ],
  data() {
    return {
      modelLocal: undefined,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        label: "_TXT_VERTRAG_ABSCHLAG_",
        openCallback: this.openAbschlag,
        saveCallback: this.saveAbschlag,
      };
    },

    isAbschlagEditable() {
      return this.checkPermissionsSync({
        perm: "vertrag.fvb.update",
        permArray: this.vertrag.user_permissions,
      });
    },

    optionsForFormElement() {
      const ID = "abschlag";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "boolean",
        view: "v-alt",
        required: false,
        trueLabel: "_HTML_VERTRAG_ABSCHLAG_TRUE_",
        falseLabel: "_HTML_VERTRAG_ABSCHLAG_FALSE_",
      };
    },

    alertTypeAbschlag() {
      return this.vertragAbschlag ?
        "success" :
        "info";
    },

    alertTypeAbschlagSnapshot() {
      return this.vertragAbschlagSnapshot ?
        "success" :
        "info";
    },

    alertTextAbschlag() {
      return this.vertragAbschlag ?
        "_HTML_VERTRAG_ABSCHLAG_FOUND_" :
        "_HTML_VERTRAG_ABSCHLAG_NOT_FOUND_";
    },

    alertTextAbschlagSnapshot() {
      return this.vertragAbschlagSnapshot ?
        "_HTML_VERTRAG_ABSCHLAG_FOUND_" :
        "_HTML_VERTRAG_ABSCHLAG_NOT_FOUND_";
    },

    vertragAbschlag() {
      return get(this.vertrag, "foerdervertragsbestaetigung.abschlag") || false;
    },

    vertragAbschlagSnapshot() {
      return get(this.snapshotObject, "vertrag.v_abschlag") || false;
    },

    urlAbschlag() {
      return `vertraege/${ this.vertrag.pk }/fvb_abschlag/`;
    },

    isDiff() {
      return !!(this.isSnapshot && this.vertragAbschlag !== this.vertragAbschlagSnapshot);
    },

    data() {
      return {
        alertTextAbschlag: this.alertTextAbschlag,
        alertTypeAbschlag: this.alertTypeAbschlag,
      };
    },

    dataSnapshot() {
      return {
        alertTextAbschlag: this.alertTextAbschlagSnapshot,
        alertTypeAbschlag: this.alertTypeAbschlagSnapshot,
      };
    },
  },
  methods: {
    openAbschlag() {
      this.modelLocal = cloneDeep(this.vertragAbschlag);
    },

    saveAbschlag() {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.urlAbschlag,
          data: {
            abschlag: this.modelLocal,
          },
        }).then(
          response => {
            this.updateVertragLocal(response);
            this.addNotification({
              text: response.abschlag ? "_MSG_VERTRAG_ABSCHLAG_SELECTED_" : "_MSG_VERTRAG_ABSCHLAG_DESELECTED_",
            });
            resolve();
          },
          () => reject()
        );
      });
    },

    updateVertragLocal(response) {
      this.$emit("updateVertrag", {
        vertrag: {
          foerdervertragsbestaetigung: response,
        },
      });
    },
  },
};
