import translate from "../../../../../../../global/directives/translate";
import PuxButton from "../../../../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "UiAuszahlungsmodiReadOnlyElement",
  components: {
    PuxButton,
  },
  directives: {
    translate,
  },
  props: {
    modelCurrent: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      requred: true,
      default: undefined,
    },
    listConfig: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      statusShowDetails: false,
    };
  },
  computed: {
    label() {
      return `Abschlag Nr. ${ this.index + 1 }`;
    },

    buttonText() {
      return this.statusShowDetails ?
        "(Konfiguration ausblenden)" :
        "(Konfiguration anzeigen)";
    },
  },
  beforeCreate() {
    this.$options.components.FormElementReadOnly = require("../../../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue").default;
  },
  methods: {
    toggleDetails() {
      this.statusShowDetails = !this.statusShowDetails;
    },
  },
};
