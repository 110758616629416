import {
  FilterCurrencyMixin,
} from "../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  mixins: [
    FilterCurrencyMixin,
  ],
  computed: {
    keyKoFiPos() {
      return `${ this.prefixCurrent.vkofi }_pos`;
    },

    keyPositionPos() {
      return `${ this.prefixCurrent.position }_pos`;
    },

    permissionPositionPrefix() {
      if (this.prefixCurrent.vertragsPos === "vertragsfinanzierungposition") {
        return "vertragsfinanzierungspositionen";
      }
      return "vertragskostenpositionen";
    },

    permissionPositionDelete() {
      return this.permissionPositionPrefix + ".delete";
    },

    permissionPositionUpdate() {
      return this.permissionPositionPrefix + ".update";
    },

    permissionPositionCreate() {
      return this.permissionPositionPrefix + ".create";
    },

    permissionPositionNachreichen() {
      return this.permissionPositionPrefix + ".nachreichen";
    },
  },
  methods: {
    filterView(firstItem, secondItem, condition) {
      let tempItem = condition ? firstItem : secondItem;
      tempItem = tempItem ? this.filterCurrency(tempItem) : "-";
      return tempItem;
    },
  },
};
