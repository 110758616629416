"use strict";
// - ACHTUNG, Das ist schon in Vue.js umgeschrieben!!!!!!!!!!!!!!
// - ACHTUNG, Das ist schon in Vue.js umgeschrieben!!!!!!!!!!!!!!
// - ACHTUNG, Das ist schon in Vue.js umgeschrieben!!!!!!!!!!!!!!
import "restangular";
import FilterSet from "dias/core/table/filterSet";
import template from "./list.jade";


class AnonymisierungsauftragFilterSet extends FilterSet {
  constructor($stateParams, i18n, Katalog) {
    super();
    this.addFilter("suche", {
      label: "Suche",
      textInput: true,
      param: "suche",
      initialStatus: true
    });
    this.addFilter("ano_objektname", {
      label: "Objektname",
      textInput: true,
      param: "ano_objektname",
    });
    this.addFilter("content_type", {
      label: i18n.gettext("Objekt-Typ"),
      choices: Katalog.getChoices("ano_possible_ct", "id", "bez"),
      param: "content_type",
      selected: $stateParams.content_type,
      multiSelect: true,
    });
    this.addFilter("quelle", {
      label: i18n.gettext("Beauftragt durch"),
      choices: Katalog.getChoices("anonymisierungsquellen", "id", "bez"),
      param: "quelle",
      selected: $stateParams.quelle,
      multiSelect: true,
    });
    this.addFilter("ano_termin", {
      label: "Ausführungsdatum",
      dateRange: true,
      dateRangeMinPlaceholder: "Beginn beliebig",
      dateRangeMaxPlaceholder: "Ende beliebig",
      param: "ano_termin"
    });
    this.addFilter("ano_blockieren", {
      label: "Blockiert",
      bool: true,
      param: "edit"
    });
    this.addFilter("ano_stufe", {
      label: i18n.gettext("Stufe"),
      choices: Katalog.getChoices("ano_stufe", "id", "bez"),
      param: "ano_stufe",
      selected: $stateParams.ano_stufe,
      multiSelect: true,
    });
    this.addFilter("ano_status", {
      label: i18n.gettext("Status"),
      choices: Katalog.getChoices("ano_status", "id", "bez"),
      param: "ano_status",
      selected: $stateParams.ano_status,
      multiSelect: true,
    });
    this.addFilter("ano_error", {
      label: "Fehlermeldung",
      textInput: true,
      param: "ano_error",
    });
  }
}


class AnonymisierungsauftragListController {
  /*@ngInject*/
  constructor(
    $stateParams, 
    $q, 
    $filter, 
    i18n, 
    AuthService, 
    DiasNotification,
    diasConfirmDialog, 
    Katalog, 
    Anonymisierungsauftrag,
    AnonymisierungsstatusUUIDs
  ) {
    this.i18n = i18n;

    const anonTableDefer = $q.defer();
    this.anonTable = anonTableDefer.promise;

    const cols = [
      {
        label: i18n.gettext("Objektname"),
        field: "ano_objektname",
        cellClass: "text-left",
        cellTemplate: `
          <a ng-if="vm.extra.getObjektRoute(row)" ui-sref="{{vm.extra.getObjektRoute(row)}}">
            {{:: row.ano_objektname}}
          </a>
          <span ng-if="!vm.extra.getObjektRoute(row)">
            {{:: row.ano_objektname}}
          </span>`,
        sortable: true
      }, {
        label: i18n.gettext("Objekttyp"),
        field: "objekt_typ_bez",
        sortable: true,
        sortField: "content_type__model"
      }, {
        label: i18n.gettext("Beauftragt durch"),
        field: "quelle_bez",
        sortable: true,
        sortField: "quelle__anq_kbez",
        cellTemplate: `
          <span ng-attr-title="{{::row.quelle_bez}}">{{:: row.quelle_kbez}}</span>`
      }, {
        label: i18n.gettext("Ausführungsdatum"),
        field: "ano_termin",
        cellTemplate: `{{ row.ano_termin | date:"shortDate"}}`,
        sortable: true,
        sortingDefault: true,
        sortingDefaultDirection: "desc",
      }, {
        label: i18n.gettext("Status"),
        sortField: "ano_status",
        field: "status_bez",
        sortable: true
      }, {
        label: i18n.gettext("Stufe"),
        sortField: "ano_stufe",
        field: "stufe_bez",
        sortable: true
      }, {
        label: i18n.gettext("Blockiert"),
        sortField: "ano_blockieren",
        field: "ano_blockieren",
        cellTemplate: `
          <span ng-if="row.ano_blockieren"
                title="Dieser Auftrag wird nicht durchgeführt, auch wenn der Ausführungsdatum erreicht ist."
                class="glyphicon glyphicon-abgelehnt text-danger"></span>
          `
      }, {
        label: i18n.gettext("Fehlermeldung"),
        sortField: "ano_error",
        field: "ano_error",
        invisible: true
      },
    ];

    const doBlockieren = row => {
      return Anonymisierungsauftrag.one(row.pk).customPUT({ ano_blockieren: true }).then(
        response => {
          DiasNotification.page.success("Auftrag erfolgreich blockiert.");
          Object.assign(row, response.plain());
          row.updateActions();
        },
        () => DiasNotification.page.error("Fehler beim Speichern der Änderung.")
      );
    };
    const blockieren = row => {
      diasConfirmDialog({
        titleTemplate: i18n.gettext(`Auftrag blockieren`),
        contentTemplate: `<p>Sind Sie sicher, dass Sie den Anonymisierungsauftrag
        für <strong>${ row.ano_objektname }</strong> zum ${ $filter("date")(row.ano_termin, "shortDate") } blockieren wollen?</p>`,
        okLabel: i18n.gettext("Blockieren"),
        okCallback: () => doBlockieren(row) });
    };
    const doFreigeben = row => {
      return Anonymisierungsauftrag.one(row.pk).customPUT({ ano_blockieren: false }).then(
        response => {
          DiasNotification.page.success("Auftrag erfolgreich blockiert.");
          Object.assign(row, response.plain());
          row.updateActions();
        },
        () => DiasNotification.page.error("Fehler beim Speichern der Änderung.")
      );
    };
    const freigeben = row => {
      diasConfirmDialog({
        titleTemplate: i18n.gettext(`Auftrag freigeben`),
        contentTemplate: `<p>Sind Sie sicher, dass Sie den Anonymisierungsauftrag
        für <strong>${ row.ano_objektname }</strong> zum ${ $filter("date")(row.ano_termin, "shortDate") } freigeben wollen?</p>`,
        okLabel: i18n.gettext("Freigeben"),
        okCallback: () => doFreigeben(row) });
    };
    const doErneutPlanen = row => {
      return row.customPOST({}, "neu_planen").then(
        response => {
          DiasNotification.page.success("Neuen Auftrag erfolgreich erstellt.");
          Object.assign(row, response.plain());
          row.updateActions();
        },
        () => DiasNotification.page.error("Fehler beim Erstellen des Auftrags.")
      );
    };
    const erneutPlanen = row => {
      diasConfirmDialog({
        titleTemplate: i18n.gettext(`Erneute Ausführung planen`),
        contentTemplate: `<p>Sind Sie sicher, dass Sie die Ausführung des Anonymisierungsauftrag
        für <strong>${ row.ano_objektname }</strong> zum nächstmöglichen Zeitpunk planen wollen?</p>`,
        okLabel: i18n.gettext("Erneut Planen"),
        okCallback: () => doErneutPlanen(row) });
    };
    const doStarten = row => {
      return Anonymisierungsauftrag.one(row.pk).customPOST({}, "starten").then(
        response => {
          DiasNotification.page.success("Auftrag erfolgreich durchgeführt.");
          Object.assign(row, response.plain());
          row.updateActions();
        },
        () => DiasNotification.page.error("Fehler beim Durchführen des Auftrags.")
      );
    };
    const starten = row => {
      diasConfirmDialog({
        titleTemplate: i18n.gettext(`Auftrag jetzt starten`),
        contentTemplate: `<p>Sind Sie sicher, dass Sie den Anonymisierungsauftrag
        für <strong>${ row.ano_objektname }</strong> jetzt starten wollen?</p>`,
        okLabel: i18n.gettext("Starten"),
        okCallback: () => doStarten(row) });
    };
    const doAbbrechen = row => {
      return Anonymisierungsauftrag.one(row.pk).customPOST({}, "abbrechen").then(
        response => {
          DiasNotification.page.success("Auftrag erfolgreich abgebrochen.");
          Object.assign(row, response.plain());
          row.updateActions();
        },
        () => DiasNotification.page.error("Fehler beim Durchführen des Auftrags.")
      );
    };
    const abbrechen = row => {
      diasConfirmDialog({
        titleTemplate: i18n.gettext(`Auftrag abbrechen`),
        contentTemplate: `<p>Sind Sie sicher, dass Sie den Anonymisierungsauftrag
        für <strong>${ row.ano_objektname }</strong> jetzt abbrechen wollen?</p>`,
        okLabel: i18n.gettext("Abbrechen"),
        okCallback: () => doAbbrechen(row) });
    };

    const perms = AuthService.syncHasPerms(["anonymisierung.update", "anonymisierung.create"]);

    anonTableDefer.resolve({
      dataSource: Anonymisierungsauftrag.getList,
      // Template für Detailansicht
      tableTitle: i18n.gettext("Anonymisierungsaufträge"),
      columnDefs: cols,
      filter: new AnonymisierungsauftragFilterSet($stateParams, i18n, Katalog),
      filterName: "anonymisierungsauftraege",
      actions: [{
        label: i18n.gettext("Jetzt starten"),
        isVisible: row => perms["anonymisierung.create"] &&
          row.ano_status === AnonymisierungsstatusUUIDs.PLANNED &&
          !row.ano_blockieren,
        callback: starten
      }, {
        label: i18n.gettext("Ausführung blockieren"),
        isVisible: row => perms["anonymisierung.update"] &&
          row.ano_status === AnonymisierungsstatusUUIDs.PLANNED &&
          !row.ano_blockieren,
        callback: blockieren,
      }, {
        label: i18n.gettext("Ausführung freigeben"),
        isVisible: row => perms["anonymisierung.update"] &&
          row.ano_status === AnonymisierungsstatusUUIDs.PLANNED &&
          row.ano_blockieren,
        callback: freigeben,
      }, {
        label: i18n.gettext("Erneute Ausführung planen"),
        isVisible: row => {
          return perms["anonymisierung.create"] &&
          row.ano_status === AnonymisierungsstatusUUIDs.FAILURE;
        },
        callback: erneutPlanen,
      }, {
        label: i18n.gettext("Ausführung abbrechen"),
        isVisible: row => {
          return perms["anonymisierung.update"] &&
          row.ano_status === AnonymisierungsstatusUUIDs.PLANNED;
        },
        callback: abbrechen,
      }],
      extra: {
        getObjektRoute: row => {
          if (row.ano_status !== AnonymisierungsstatusUUIDs.PLANNED) {
            return null;
          }
          const map = {
            foerderantrag: `root.foerderantrag.details({id: '${ row.ano_objektid }'})`,
            vertrag: `root.vertrag.details({id: '${ row.ano_objektid }'})`,
            nutzer: `root.nutzerverwaltung.details({id: '${ row.ano_objektid }'})`,
          };
          return map[row.objekt];
        }
      }
    });
  }
}

export default {
  template: template(),
  controller: AnonymisierungsauftragListController,
  controllerAs: "vm"
};
