import PuxButton from "../../PuxButton/PuxButton.vue";
import PuxIcon from "../../PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";

import {
  gettext,
} from "../../../functions/utils";

// @vue/component
export default {
  name: "TableViewTableTh",
  components: {
    PuxButton,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    col: {
      type: Object,
      required: true,
    },
    colIndex: {
      type: Number,
      required: true,
    },
    currentSortingModel: {
      type: String,
      required: false,
      default: undefined,
    },
    tableOptions: {
      type: Object,
      required: true,
    },
    modelCols: {
      type: Array,
      required: true,
    },
    changeSorting: {
      type: Function,
      required: true,
    },
  },
  computed: {
    idLocal() {
      return `${ this.tableOptions.id }_${ this.colIndex }_th`;
    },

    idForSortingButton() {
      return `${ this.idLocal }_sorting`;
    },

    titleForSortingButton() {
      if (this.isCurrentColSortedAscending) {
        return "_BTN_TABLE_SORTING_ASC_{{columnName}}_";
      }
      if (this.isCurrentColSortedDescending) {
        return "_BTN_TABLE_SORTING_DESC_{{columnName}}_";
      }
      return "_BTN_TABLE_SORTING_NONE_{{columnName}}_";
    },

    extraForSortingButton() {
      return {
        columnName: gettext(this.col.label),
      };
    },

    classForTh() {
      return [
        this.col.headerClassName,
        {
          table_smart__table__th_sort_active: this.ariaSort
        },
      ];
    },

    currentSortId() {
      return this.col.sortId || this.col.id;
    },

    attributesForTh() {
      if (this.ariaSort) {
        return {
          "aria-sort": this.ariaSort,
        };
      }
      return {};
    },

    ariaSort() {
      if (this.isCurrentColSortedAscending) {
        return "ascending";
      }
      if (this.isCurrentColSortedDescending) {
        return "descending";
      }
      return "";
    },

    isCurrentColSortedAscending() {
      return this.col.sortInvert ? this.currentSortingModel === `-${ this.currentSortId }` : this.currentSortingModel === this.currentSortId;
    },

    isCurrentColSortedDescending() {
      return this.col.sortInvert ? this.currentSortingModel === this.currentSortId : this.currentSortingModel === `-${ this.currentSortId }`;
    },

    isColVisible() {
      return !this.tableOptions.showColsConfig || this.modelCols.indexOf(this.col.id) !== -1;
    },

    isIconArrowUpVisible() {
      return !this.isCurrentColSortedDescending;
    },

    isIconArrowDownVisible() {
      return !this.isCurrentColSortedAscending;
    },
  },
};
