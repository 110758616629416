"use strict";

class BewertungBaseController {
  constructor(Katalog, ParameterUtils) {
    this.loading = true;
    if (angular.isUndefined(this.parameter[this.key].wert)) {
      Katalog.getKatalog("bewertungstyp").then(
        response => {
          this.typ = response.getById(this.parameter[this.key].bewertungstyp);
          this.parameter[this.key].wert = angular.copy(this.typ.bet_parameterin);
          this.param_keys = ParameterUtils.get_ordered_keys(this.parameter[this.key].wert);
        }
      ).finally(() => this.loading = false);
    } else {
      this.param_keys = ParameterUtils.get_ordered_keys(this.parameter[this.key].wert);
      this.loading = false;
    }
  }
}


export default BewertungBaseController;
