import Alert from "../../../../global/components/Alert/Alert.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import OptionsSave from "./OptionsSave";
import {
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragstellerWizardRegisternummer",
  components: {
    Alert,
    FormElement,
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    statusNewOrganisation: {
      type: Boolean,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    changeModel: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      optionsSave: OptionsSave,
      model: {},
      errorLabels: {
        ast_registernummer: "_LBL_ANTRAGSTELLER_WIZARD_STEP_REGISTERNUMMER_REGISTERNUMMER_",
        ast_gericht: "_LBL_ANTRAGSTELLER_WIZARD_STEP_REGISTERNUMMER_GERICHT_",
      },
    };
  },
  computed: {
    isErrors() {
      return !!size(this.errors);
    },
  },
  methods: {
    onChangeModel() {
      this.changeModel({ model: this.model });
    },
  },
};
