import PuxButton from "../PuxButton/PuxButton.vue";

import init from "../../directives/init";

import {
  setHeightAsideAndMenu,
  setStyleTopForFixedVertical,
} from "../../functions/utilsDOM";

// @vue/component
export default {
  name: "AsideRight",
  components: {
    PuxButton,
  },
  directives: {
    init,
  },
  data() {
    return {
      statusOpen: true,
      idForAsideBoxChild: "aside_right_box",
    };
  },
  computed: {
    classIcon() {
      return this.statusOpen ?
        "send" :
        "double-left";
    },

    buttonToggleTitleTranslate() {
      return this.statusOpen ?
        "_BTN_ASIDE_RIGHT_TOGGLE_CLOSE_" :
        "_BTN_ASIDE_RIGHT_TOGGLE_OPEN_";
    },

    tooltipPlacement() {
      return this.statusOpen ? "top" : "left";
    },
  },
  methods: {
    setAsideHeight() {
      setTimeout(() => {
        setStyleTopForFixedVertical();
        setHeightAsideAndMenu();
      });
    },

    open() { // From parent
      if (!this.statusOpen) {
        this.toggleButton();
      }
    },

    toggleButton() {
      this.statusOpen = !this.statusOpen;
      if (this.statusOpen) {
        document.querySelector(".page_main").classList.remove("page_main_closed_aside");
      } else {
        document.querySelector(".page_main").classList.add("page_main_closed_aside");
      }
    },
  },
};
