import Modal from "../../../../../global/components/Modal/Modal.vue";
import ModalCreateOrEdit from "../ModalCreateOrEdit/ModalCreateOrEdit.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../../global/compositionAPI/NotificationAPI";

import {
  EventBus,
} from "../../../../../global/functions/event-bus";
import {
  NO_CACHE_PARAMETER,
} from "../../../../../global/const/NoCache";
import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "ImageDragAndDrop",
  components: {
    Modal,
    ModalCreateOrEdit,
    PuxButton,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
    imageIndex: {
      type: Number,
      required: true,
    },
    allowedMimeType: {
      type: Array,
      required: true,
    },
    apiPrefix: {
      type: String,
      required: true,
    },
    updateImages: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const {
      deleteHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      deleteHttp,
    };
  },
  data() {
    return {
      statusModal: undefined,
      statusLoading: true,
      statusReloading: undefined,
      confirmShow: undefined,
      confirmOptions: undefined,
      indexUpdate: 0,
    };
  },
  computed: {
    getSrc() {
      return `api/${ this.apiPrefix }/assets/${ this.image.pk }/data/`;
    },

    getNotCacheSuffix() {
      return this.indexUpdate ? `${ NO_CACHE_PARAMETER }${ this.indexUpdate }` : "";
    },
  },
  methods: {
    dragStart() {
      const SRC_SUFFIX = this.getNotCacheSuffix;
      const HTML = `<img src="api/${ this.apiPrefix }/assets/${ this.image.pk }/data/${ SRC_SUFFIX }" alt="${ this.image.ass_titel }" class="img-fluid"/>`;
      EventBus.$emit("dragStartCKEditor", { html: HTML });
    },

    dragEnd() {
      EventBus.$emit("dragStartCKEditor", { data: undefined });
    },

    openConfirmDelete() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_FAQ_ADMIN_DETAILS_DELETE_IMAGE_MODAL_HEADER_",
        msg: "_TXT_FAQ_ADMIN_DETAILS_DELETE_IMAGE_MODAL_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteImg(),
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteImg() {
      this.confirmOptions.loading = true;
      this.deleteHttp({ url: `${ this.apiPrefix }/assets/${ this.image.pk }/` }).then(
        () => {
          this.addNotification({ text: "_MSG_FAQ_ADMIN_DETAILS_DELETE_IMAGE_SUCCESS_" });
          this.closeConfirm();
          this.updateImages({ imageIndex: this.imageIndex, status: "delete" });
        },
        error => {
          console.log("error", error.data);
        }
      ).then(
        () => {
          this.confirmOptions.loading = false;
        }
      );
    },

    closeConfirm() {
      this.confirmShow = false;
    },

    openModalUpdate() {
      this.statusModal = true;
    },

    closeModalUpdate({ statusUpdate, response, statusUpdateSrc } = {}) {
      if (statusUpdate) {
        this.updateImages({ image: response, imageIndex: this.imageIndex, status: "update" });
      }
      if (statusUpdateSrc) {
        this.updateSrcForAllImagesInPage({ pk: response.pk });
      }
      this.statusModal = false;
    },

    updateSrcForAllImagesInPage({ pk }) {
      const ALL_IMGAGES = document.querySelectorAll(`[src^="${ this.getSrc }"]`) || [];
      this.indexUpdate++;

      const SRC_SUFFIX_NEW = this.getNotCacheSuffix;
      forEach(ALL_IMGAGES, image => {
        image.src = `${ this.getSrc }${ SRC_SUFFIX_NEW }`;
      });
      EventBus.$emit("updateNoCacheImages", {
        src: this.getSrc,
        suffix: this.getNotCacheSuffix,
        pk,
      });
    },
  },
};
