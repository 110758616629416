"use strict";

import template from "./icon.jade";

class ListenstatusIcon {
  /*@ngInject*/
  constructor(ListenstatusUUIDs) {
    this.ListenstatusUUIDs = ListenstatusUUIDs;
  }

  getIconClass() {
    switch (this.uuid) {
    case this.ListenstatusUUIDs.ersatz:
      return "glyphicon-ersatzantrag listenstatus";
    case this.ListenstatusUUIDs.vorgesehen:
      return "glyphicon-ok listenstatus";
    case this.ListenstatusUUIDs.nichtVorgesehen:
      return "glyphicon-close listenstatus";
    }
  }
}

export default {
  template: template(),
  controller: ListenstatusIcon,
  controllerAs: "vm",
  bindings: {
    uuid: "="
  }
};
