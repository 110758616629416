import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import getTableApi from "../../../const/TableApis";
import {
  forEach,
  findIndex,
} from "lodash-es";

const TABLE_ID = "AuszahlungListInVertragTable";

// @vue/component
export default {
  name: "AuszahlungListInVertrag",
  components: {
    AngularLink,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
  ],
  props: {
    vertragPk: {
      type: String,
      default: undefined,
    },
    auszahlungserstellungsmodus: {
      type: String,
      default: undefined,
    },
    auszahlungsplan: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const {
      filterCurrency,
      filterDefaultForEmpty,
    } = FiltersAPI();

    return {
      filterCurrency,
      filterDefaultForEmpty,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: undefined,
        cols: [
          {
            label: "_LBL_AUSZAHLUNG_IN_VERTRAG_NR_",
            id: "az_nummer",
            sortable: true,
            sortId: "az_nummer",
            sortingDefault: "asc",
          },
          {
            label: "_LBL_AUSZAHLUNG_IN_VERTRAG_STUFE_",
            id: "zp_nummer",
            sortable: true,
            sortId: "zp_nummer",
          },
          {
            label: "_LBL_AUSZAHLUNG_IN_VERTRAG_EINGEREICHT_AM_",
            id: "az_datum_eingereicht",
            filter: "date",
          },
          {
            label: "_LBL_AUSZAHLUNG_IN_VERTRAG_BETRAG_BEANTRAGT_",
            id: "betrag_beantragt",
            slot: "betrag_beantragt",
            className: "text-right",
            headerClassName: "text-right",
          },
          {
            label: "_LBL_AUSZAHLUNG_IN_VERTRAG_BETRAG_AUSZAHLEN_",
            id: "betrag_auszuzahlen",
            slot: "betrag_auszuzahlen",
            className: "text-right",
            headerClassName: "text-right",
          },
          {
            label: "_LBL_AUSZAHLUNG_IN_VERTRAG_BETRAG_",
            id: "az_betrag",
            slot: "az_betrag",
            className: "text-right",
            headerClassName: "text-right",
          },
          {
            label: "_LBL_AUSZAHLUNG_IN_VERTRAG_AUSZAHLUNG_FREIGEGEBEN_",
            id: "az_datum_ausgezahlt",
            filter: "date",
          },
          {
            label: "_LBL_AUSZAHLUNG_IN_VERTRAG_STATUS_",
            id: "status",
          },
          {
            label: "_LBL_AUSZAHLUNG_IN_VERTRAG_AKTION_",
            id: "aktion",
            slot: "aktion",
            className: "width-150",
            headerClassName: "width-150",
          },
        ],
        actions: [],
        label: "_LBL_AUSZAHLUNG_IN_VERTRAG_AUSZAHLUNG_",
        sortable: true,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        infoHeaderSlot: "auszahlungInfoHeader",
        showColsConfig: false,
      },
      statusCreateAuszahlung: undefined,
    };
  },
  created() {
    this.initUrl();
    this.initActions();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { vertragPk: this.vertragPk } });
    },

    initActions() {
      let nichtletzte;
      let nichtletzte_disabled;
      let letzte;
      let letzte_disabled;
      const BUTTON_CLASS_NAME = "btn btn-default";
      const ACTIONS = [];
      if (this.auszahlungserstellungsmodus === "einfach") {
        forEach(this.auszahlungsplan, plan => {
          if (this.checkPermissionsSync({ perm: "auszahlung.create", permArray: plan.user_permissions })) {
            if (plan.is_last === true) {
              if (plan.can_create_auszahlung) {
                letzte = plan;
              } else {
                letzte_disabled = plan;
              }
            } else {
              if (plan.can_create_auszahlung) {
                nichtletzte = plan;
              } else {
                nichtletzte_disabled = plan;
              }
            }
          }
        });
      }
      if (nichtletzte) {
        ACTIONS.push({
          type: "button",
          label: "_LBL_AUSZAHLUNG_IN_VERTRAG_TABLE_ACTIONS_CREATE_{{name}}_",
          className: `${ BUTTON_CLASS_NAME } test_${ nichtletzte.name }_erstellen`,
          title: nichtletzte.display,
          callback: () => this.createAuszahlung({ plan: nichtletzte }),
          isDisabled: false,
          isLoading: false,
          id: nichtletzte.pk,
          extra: {
            name: nichtletzte.name
          },
        });
      } else if (nichtletzte_disabled) {
        ACTIONS.push({
          type: "button",
          label: "_LBL_AUSZAHLUNG_IN_VERTRAG_TABLE_ACTIONS_CREATE_{{name}}_",
          className: `${ BUTTON_CLASS_NAME } test_${ nichtletzte_disabled.name }_erstellen`,
          title: "_TXT_AUSZAHLUNG_IN_VERTRAG_TABLE_AKTION_DISABLED_TITLE_",
          callback: () => {},
          isDisabled: true,
          id: nichtletzte_disabled.pk,
          extra: {
            name: nichtletzte_disabled.name
          },
        });
      }
      if (letzte) {
        ACTIONS.push({
          type: "button",
          label: "_LBL_AUSZAHLUNG_IN_VERTRAG_TABLE_ACTIONS_CREATE_{{name}}_",
          className: `${ BUTTON_CLASS_NAME } test_${ letzte.name }_erstellen`,
          title: letzte.display,
          callback: () => this.createAuszahlung({ plan: letzte }),
          isDisabled: false,
          isLoading: false,
          id: letzte.pk,
          extra: {
            name: letzte.name
          },
        });
      } else if (letzte_disabled) {
        ACTIONS.push({
          type: "button",
          label: "_LBL_AUSZAHLUNG_IN_VERTRAG_TABLE_ACTIONS_CREATE_{{name}}_",
          className: `${ BUTTON_CLASS_NAME } test_${ letzte_disabled.name }_erstellen`,
          title: "_TXT_AUSZAHLUNG_IN_VERTRAG_TABLE_AKTION_DISABLED_TITLE_",
          callback: () => {},
          isDisabled: true,
          id: letzte_disabled.pk,
          extra: {
            name: letzte_disabled.name
          },
        });
      }
      this.options.actions.push(...ACTIONS);
    },

    setLoadingAndDisabledForActions({ plan, statusDisabled }) {
      this.statusCreateAuszahlung = statusDisabled;
      const INDEX = findIndex(this.options.actions, ["id", plan.pk]);
      if (INDEX === -1) {
        return;
      }
      const CURRENT_ACTION = this.options.actions[INDEX];
      CURRENT_ACTION.isDisabled = true;
      CURRENT_ACTION.isLoading = true;
      this.options.actions.splice(INDEX, 1, CURRENT_ACTION);
    },

    createAuszahlung({ plan } = {}) {
      if (this.statusCreateAuszahlung) {
        return;
      }
      this.setLoadingAndDisabledForActions({ plan, statusDisabled: true });

      this.postHttp({ url: `vertraege/${ this.vertragPk }/auszahlungsplaene/${ plan.pk }/auszahlung_erstellen/` }).then(
        response => {
          this.$goTo("root.vertrag.details.auszahlung", { id: this.vertragPk, aus_id: response.pk });
        },
        () => {
          this.addNotification({ text: "_MSG_AUSZAHLUNG_IN_VERTRAG_TABLE_CREATE_ERROR_", type: "error" });
        }
      ).then(
        () => this.setLoadingAndDisabledForActions({ plan, statusDisabled: false })
      );
    },
  },
};
