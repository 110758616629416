<script>
import { optionsMerger, propsBinder } from "../utils/utils.js";
import ControlMixin from "../mixins/Control.js";
import Options from "../mixins/Options.js";
import { control } from "leaflet";

/**
 * Add any custom component as a leaflet control-layers
 */
export default {
  name: "LControlLayers",
  mixins: [ControlMixin, Options],
  props: {
    collapsed: {
      type: Boolean,
      default: true,
    },
    autoZIndex: {
      type: Boolean,
      default: true,
    },
    hideSingleBase: {
      type: Boolean,
      default: false,
    },
    sortLayers: {
      type: Boolean,
      default: false,
    },
    sortFunction: {
      type: Function,
      default: undefined,
    },
  },
  emits: [
    "ready",
  ],
  mounted() {
    const options = optionsMerger(
      {
        ...this.controlOptions,
        collapsed: this.collapsed,
        autoZIndex: this.autoZIndex,
        hideSingleBase: this.hideSingleBase,
        sortLayers: this.sortLayers,
        sortFunction: this.sortFunction,
      },
      this
    );
    this.mapObject = control.layers(null, null, options);
    propsBinder(this, this.mapObject, this.$options.props);
    this.$parent.registerLayerControl(this);
    this.$nextTick(() => {
      /**
       * Triggers when the component is ready
       * @type {object}
       * @property {object} mapObject - reference to leaflet map object
       */
      this.$emit("ready", this.mapObject);
    });
  },
  methods: {
    addLayer(layer) {
      if (layer.layerType === "base") {
        this.mapObject.addBaseLayer(layer.mapObject, layer.name);
      } else if (layer.layerType === "overlay") {
        this.mapObject.addOverlay(layer.mapObject, layer.name);
      }
    },
    removeLayer(layer) {
      this.mapObject.removeLayer(layer.mapObject);
    },
  },
  render() {
    return null;
  },
};
</script>
