import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import FoerderfinderListFoerderangebotDetails from "../FoerderfinderListFoerderangebotDetails/FoerderfinderListFoerderangebotDetails.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import FoerderfinderListDetailsMixin from "../FoerderfinderListDetails.mixin";

import {
  cloneDeep,
  filter,
  findIndex,
  forEach,
  orderBy,
  some,
} from "lodash-es";

// @vue/component
export default {
  name: "FoerderfinderListFoerderprogrammeDetails",
  components: {
    FoerderfinderListFoerderangebotDetails,
    AngularLink,
    PuxButton,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    FoerderfinderListDetailsMixin,
    HttpMixin,
  ],
  data() {
    return {
      status: {
        angebotDetails: {},
        extraAngebote: undefined,
        hover: {},
        loading: true,
      },
    };
  },
  computed: {
    getAngeboteWithFilter() {
      return orderBy(filter(this.data.angeboteClone, item => some(this.data.angebote, ["pk", item.pk])), item => item.zuschuss && item.zuschuss[0] && item.zuschuss[0].max_betrag, ["desc"]);
    },

    getAngeboteWithoutFilter() {
      return orderBy(filter(this.data.angeboteClone, item => !some(this.data.angebote, ["pk", item.pk])), item => item.zuschuss && item.zuschuss[0] && item.zuschuss[0].max_betrag, ["desc"]);
    },

    getTitleInfo() {
      return angebot => {
        return this.status.angebotDetails[angebot.pk] ? "_TXT_FOERDERFINDER_LIST_FOERDERPROGRAMME_DETAILS_AUSBLENDEN_{{ kbez }}_" : "_TXT_FOERDERFINDER_LIST_FOERDERPROGRAMME_DETAILS_EINBLENDEN_{{ kbez }}_";
      };
    },
  },
  created() {
    this.initData();
  },
  methods: {
    enterMouse(angebotPk) {
      this.status.hover[angebotPk] = true;
    },

    getPks(angebote) {
      const arr = [];
      forEach(angebote, item => {
        arr.push(item.pk);
      });
      return { pk: arr };
    },

    initData() {
      this.status.loading = true;
      if (!this.data || !this.data.angeboteClone) {
        this.loadAngebote();
      } else {
        this.status.loading = false;
      }
    },

    leaveMouse(angebotPk) {
      this.status.hover[angebotPk] = false;
    },

    loadAngebote() {
      this.getListHttp({ url: `ff/v3/foerderprogramme/${ this.data.pk }/angebote/` }).then(
        response => {
          this.loadAngeboteDetails(response);
        }
      );
    },

    loadAngeboteDetails(angebote) {
      const urlParams = this.getPks(angebote);
      this.getListHttp({ url: `ff/v3/foerderangebote/`, urlParams }).then(
        response => {
          this.replaceData(response);
          this.status.loading = false;
        }
      );
    },

    replaceAngebotInData(angebot) {
      const data = cloneDeep(this.data);
      const index = findIndex(this.data.angeboteClone, ["pk", angebot.pk]);
      if (index !== -1) {
        data.angeboteClone[index] = cloneDeep(angebot);
      }
      this.replace(data);
    },

    replaceData(angebote) {
      const data = cloneDeep(this.data);
      data.angeboteClone = cloneDeep(angebote);
      this.replace(data);
    },

    showAngebotDetails(angebot) {
      this.status.angebotDetails[angebot.pk] = !this.status.angebotDetails[angebot.pk];
    },

    toggleExtraAngebote() {
      this.status.extraAngebote = !this.status.extraAngebote;
    },
  },
};
