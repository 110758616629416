"use strict";
import autosize from "autosize";

export default /*@ngInject*/ $timeout => {
  return {
    restrict: "A",
    link(scope, element) {
      $timeout(() => {
        autosize(element);
      });
      scope.$on("$destroy", () => {
        autosize.destroy(element);
      });
    }
  };
};
