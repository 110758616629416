import {
  computed,
  onBeforeUnmount,
  ref,
  watch,
} from "vue";
import {
  useStore,
} from "vuex";

import HttpAPI from "../../../compositionAPI/HttpAPI";

import {
  MAPPING,
} from "../../../../components/Geschaeftsregel/Module/Module";
import {
  isAnythingButValueInArray,
} from "../../../functions/utils";
import {
  EventBus,
} from "../../../functions/event-bus";
import {
  every,
  filter,
  findIndex,
  forEach,
  isEmpty,
  isFunction,
  keyBy,
  size,
  snakeCase,
} from "lodash-es";

export default function PuxRubricsAPI({
  baseUrl = computed(() => ""),
  isSnapshot = computed(() => false),
  hasRubricDiffCallbacks = {},
  loadedModuleForRubricCallbacks = {},
  anzeigekontextPks = computed(() => []),
  updateReadonlyModuleByRubricKey = () => {},
  updateHasBaseEditPermModuleByRubricKey = () => {},
  objectModuleUrl = computed(() => ""),
  objectType = "",
  prepareModuleForRubric = ({ module }) => module,
  // Im Antrag umschreiben wir diese Funktion, als workaround für KoFi
  getRubricAnzeigekontextForUrl = ({ rubric }) => rubric.rubrik_obj.anzeigekontext,
  isRubricVisible = ({ rubric = {}, anzeigekontextPks = [] }) => {
    return isRubricVisibleDefault({ rubric, anzeigekontextPks });
  },
}) {
  const store = useStore();
  const STATUS_DIFF_FOR_REGELS = computed(() => {
    return store.state.snapshot.STATUS_DIFF_FOR_REGELS;
  });

  const hasBaseEditPermModuleByRubricKey = ref({});
  const readonlyModuleByRubricKey = ref({});

  const rubrics = ref([]);
  const moduleByRubricKey = ref({});
  const rubricsFiltered = ref([]);
  const rubricsSnapshotConfig = ref({});
  const rubricsFilteredByKey = computed(() => {
    return keyBy(rubricsFiltered.value, "axr_config.key");
  });
  const rubricsFilteredByAnzeigekontext = computed(() => {
    return keyBy(rubricsFiltered.value, "rubrik_obj.anzeigekontext");
  });
  const rubricsUrl = computed(() => {
    return `${ baseUrl.value }rubriken/`;
  });
  const rubricsSnapshotConfigUrl = computed(() => {
    return `${ baseUrl.value }rubriken_snapshot/`;
  });
  const checkForMissingConfigForRubric = rubric => {
    const SNAPSHOT_CONFIG_INSTANCE = rubricsSnapshotConfig.value[rubric.rubrik];
    return !SNAPSHOT_CONFIG_INSTANCE;
  };
  const disableSnapshots = computed(() => {
    if (!size(rubricsFiltered.value)) {
      return true;
    }
    return every(rubricsFiltered.value, rubric => {
      return checkForMissingConfigForRubric(rubric);
    });
  });

  const hasRubricDiffGeschaeftsregelModul = ({ rubric }) => {
    const RUBRIC_KEY = rubric.key;
    const MODULES = moduleByRubricKey.value[RUBRIC_KEY] || [];
    let hasDiff = false;
    forEach(MODULES, module => {
      const REGEL_ID = module.regel.regel;
      hasDiff = STATUS_DIFF_FOR_REGELS.value[REGEL_ID];
      if (hasDiff) {
        return false;
      }
    });
    return hasDiff;
  };
  const hasRubricDiffCallbacksLocal = Object.assign({}, {
    geschaeftsregel_modul: hasRubricDiffGeschaeftsregelModul,
  }, hasRubricDiffCallbacks);
  const hasRubricDiff = ({ rubric }) => {
    if (rubric.axr_config.hide_diff ||
      !rubric.axr_config.module ||
      !rubric.axr_config.module.length) {
      return false;
    }
    let hasDiff = false;
    forEach(rubric.axr_config.module, moduleName => {
      const FUNCTION_NAME = snakeCase(moduleName);
      if (isFunction(hasRubricDiffCallbacksLocal[FUNCTION_NAME])) {
        hasDiff = hasRubricDiffCallbacksLocal[FUNCTION_NAME]({ rubric });
        if (hasDiff) {
          return false;
        }
      }
    });
    return hasDiff;
  };
  const rubricsDiff = computed(() => {
    const DIFFS = {};
    if (isSnapshot.value && !isEmpty(STATUS_DIFF_FOR_REGELS.value)) {
      forEach(rubricsFiltered.value, rubric => {
        if (hasRubricDiff({ rubric })) {
          DIFFS[rubric.key] = true;
        }
      });
    }
    return DIFFS;
  });
  const prepareRubricsForNotizen = ({ rubrics = [] }) => {
    forEach(rubrics, rubric => {
      rubric.key = rubric.axr_config.key;
      rubric.label = rubric.axr_config.label;
    });
    return rubrics;
  };
  const setNumberForRubrics = ({ rubrics = [] }) => {
    let currentNumber = 1;
    forEach(rubrics, rubric => {
      if (rubric.axr_config.display_number) {
        rubric.number = currentNumber;
        currentNumber++;
      }
    });
    return rubrics;
  };
  const filterRubrics = ({ rubrics = [], anzeigekontextPks = [] }) => {
    const RUBRICS = filter(rubrics, rubric => {
      return isRubricVisible({ rubric, anzeigekontextPks });
    });

    return prepareRubricsForNotizen({
      rubrics: setNumberForRubrics({ rubrics: RUBRICS }),
    });
  };
  const updateReadonlyModule = () => {
    forEach(rubricsFiltered.value, rubric => {
      updateReadonlyModuleByRubricKey({ rubric });
    });
  };
  const updateHasBaseEditPerm = () => {
    forEach(rubricsFiltered.value, rubric => {
      updateHasBaseEditPermModuleByRubricKey({ rubric });
    });
  };
  const buildRubrics = () => {
    rubricsFiltered.value = filterRubrics({
      rubrics: rubrics.value,
      anzeigekontextPks: anzeigekontextPks.value,
    });
    updateReadonlyModule();
    updateHasBaseEditPerm();
  };

  const loadingModuleByRubricKey = ref({});
  const isModuleForCurrentRubricLoaded = ({ rubric, statusReload }) => {
    if (statusReload) {
      return false;
    }
    return !!moduleByRubricKey.value[rubric.axr_config.key];
  };

  const {
    getHttp,
    getListHttp,
  } = HttpAPI();
  const loadRubrics = () => {
    return getListHttp({
      url: rubricsUrl.value,
    }).then(
      response => {
        rubrics.value = response;
      }
    );
  };

  const loadRubricsSnapshotConfig = () => {
    return getListHttp({
      url: rubricsSnapshotConfigUrl.value,
    }).then(
      response => {
        rubricsSnapshotConfig.value = keyBy(response, "rubrik");
      }
    );
  };

  const filterDefinedModules = module => {
    return filter(module, item => MAPPING[item.modulname]);
  };

  const loadModuleForRubric = ({ rubric, statusReload }) => {
    const RUBRIC_KEY = rubric.axr_config.key;
    if (!rubric.rubrik_obj.anzeigekontext ||
      isModuleForCurrentRubricLoaded({ rubric, statusReload }) ||
      loadingModuleByRubricKey.value[RUBRIC_KEY]) {
      return;
    }
    loadingModuleByRubricKey.value[RUBRIC_KEY] = true;
    getHttp({
      url: objectModuleUrl.value,
      urlParams: {
        objekttyp: objectType,
        anzeigekontext: getRubricAnzeigekontextForUrl({ rubric }),
      },
    }).then(
      response => {
        response = filterDefinedModules(response);
        response = prepareModuleForRubric({ module: response, rubric });
        if (loadedModuleForRubricCallbacks[RUBRIC_KEY]) {
          loadedModuleForRubricCallbacks[RUBRIC_KEY]({ rubric, response });
          moduleByRubricKey.value[RUBRIC_KEY] = response;
        } else {
          moduleByRubricKey.value[RUBRIC_KEY] = response;
        }
      }
    ).finally(
      () => loadingModuleByRubricKey.value[RUBRIC_KEY] = false
    );
  };
  const loadAllModule = () => {
    forEach(rubricsFiltered.value, rubric => {
      loadModuleForRubric({ rubric });
    });
  };
  const reloadAllModule = () => {
    forEach(rubricsFiltered.value, rubric => {
      loadModuleForRubric({ rubric, statusReload: true });
    });
    EventBus.$emit("reloadAnlagenAllgemein");
  };
  const updateModule = ({ group, response }) => {
    if (group && moduleByRubricKey.value[group]) {
      const INDEX = findIndex(moduleByRubricKey.value[group], ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        moduleByRubricKey.value[group].splice(INDEX, 1, response);
      }
    }
  };

  const openRubric = ({ rubric }) => {
    const RUBRIC_KEY = rubric.key;
    EventBus.$emit("scrollToContextPanel", { id: RUBRIC_KEY });
  };


  watch(rubricsFiltered, () => {
    loadAllModule();
  });

  onBeforeUnmount(() => {
    store.commit("snapshot/MUT_RESET_STATUS_DIFF");
  });

  return {
    buildRubrics,
    disableSnapshots,
    hasBaseEditPermModuleByRubricKey,
    loadAllModule,
    loadRubrics,
    loadRubricsSnapshotConfig,
    loadingModuleByRubricKey,
    moduleByRubricKey,
    openRubric,
    readonlyModuleByRubricKey,
    reloadAllModule,
    rubricsDiff,
    rubricsFiltered,
    rubricsFilteredByAnzeigekontext,
    rubricsFilteredByKey,
    rubricsSnapshotConfig,
    updateModule,
  };
}

export function isRubricVisibleDefault({ rubric = {}, anzeigekontextPks = [] }) {
  const MODULE = rubric.axr_config.module;
  if (isAnythingButValueInArray(MODULE, ["geschaeftsregel-modul", "kosten-finanzierung"])) {
    return true;
  }
  if (MODULE.indexOf("geschaeftsregel-modul") === -1 &&
    MODULE.indexOf("kosten-finanzierung") === -1) {
    return false;
  }
  const ANZEIGEKONTEXT = rubric.rubrik_obj.anzeigekontext;
  if (ANZEIGEKONTEXT) {
    return anzeigekontextPks.indexOf(ANZEIGEKONTEXT) !== -1;
  }
  return false;
}
