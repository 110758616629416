"use strict";

import StandardFinanzierungController from "../../finanzierung.controller";

class DeckungslueckeFinanzierungController extends StandardFinanzierungController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService) {
    super($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService);
    this.readonly = true;
    this.editType = "no-position";
  }
}

export default DeckungslueckeFinanzierungController;
