"use strict";

class CreateManuelleAufgabeController {
  /*@ngInject*/
  constructor(Foerderantrag, Vertrag, $scope, $q, DiasNotification, $timeout) {
    this.DiasNotification = DiasNotification;
    this.$timeout = $timeout;
    const errorNameLeerFeld = "Dieses Feld darf nicht leer sein.";
    const errorNameUngueltigFeld = "Die eingegebenen Daten sind ungültig.";
    this.modelVerObj = "";
    this.model = {
      termin: null,
      wiedervorlage: null
    };
    let reloadPage = "";
    this.obj = $scope.extras.obj;
    this.verObjSearch = [];
    this.verObjtypChoices = [
      { key: "ANTRAG", label: "Antrag", model: Foerderantrag },
      { key: "VERTRAG", label: "Vertrag", model: Vertrag },
    ];

    if ($scope.extras.reloadPage) {
      reloadPage = $scope.extras.reloadPage;
    } else {
      reloadPage = () => {};
    }

    this.validate = () => {
      const errors = {};
      errors.data = {};
      let errorStatus = false;
      if (!this.model.kbez) {
        errors.data.kbez = [];
        errors.data.kbez.push(errorNameLeerFeld);
        errorStatus = true;
      }
      if (typeof(this.model.termin) === "undefined") {
        errors.data.termin = [];
        errors.data.termin.push(errorNameUngueltigFeld);
        errorStatus = true;
      } else if (this.model.termin === null) {
        errors.data.termin = [];
        errors.data.termin.push(errorNameLeerFeld);
        errorStatus = true;
      }
      if (typeof(this.model.wiedervorlage) === "undefined") {
        errors.data.wiedervorlage = [];
        errors.data.wiedervorlage.push(errorNameUngueltigFeld);
        errorStatus = true;
      }
      if (!this.modelVerObj && !this.obj) {
        errors.data.ver_obj = [];
        errors.data.ver_obj.push("Bitte wählen Sie ein Objekt für diese Aufgabe aus.");
        errorStatus = true;
      }
      if (!this.verObjtyp && !this.obj) {
        errors.data.verObjtyp = [];
                		errors.data.verObjtyp.push("Bitte wählen Sie aus, für welches Objekt die Aufgabe angelegt werden soll.");
        errorStatus = true;
      }

      if (errorStatus) {
        this.onError(errors);
      }

      return errorStatus;
    };

    this.save = () => {
		        const defer = $q.defer();
      let obj;
      if (this.validate()) {
        defer.reject();
      } else {
        if (!this.obj) {
          obj = this.getverObjtypModel().one(this.modelVerObj);
        } else {
          obj = this.obj;
        }

        const promise = obj.aufgaben.manuelleAufgabeErstellen(this.model);
        promise.then(
          () => {
            this.DiasNotification.page.success("Aufgabe erfolgreich angelegt");
            reloadPage();
            defer.resolve();
          },
          error => {
            defer.reject();
            this.onError(error);
          }
        );
      }

      return defer.promise;
    };

    this.onError = error => {
      const errorsLabel = {
        kbez: "Titel: ",
        bez: "Beschreibung: ",
        termin: "Frist bis: ",
        wiedervorlage: "Wiedervorlage: ",
			        ver_obj: "Verbundenes Objekt: ",
        verObjtyp: "Aufgabe für: ",
      };
      this.DiasNotification.form.clear();
      this.DiasNotification.form.error(
        error.data,
        "Bitte überprüfen Sie Ihre Eingaben", 
        {}, 
        errorsLabel
      );
      this.errors = error.data;
    };

    this.searchObj = t => {
    	this.loadingVerObj = true;
      this.scrollModalWindow();
    	let term = t || this.modelSearch;
      term = term.replace(/\s/g, "");
      if (term === "") {
        // Suche zurücksetzen.
        this.verObjSearch = [];
        this.loadingVerObj = false;
        return;
      }

      let promise;
      const model = this.getverObjtypModel();
      if (this.verObjtyp === "ANTRAG") {
        promise = model.getList({ a_nr: term, serializer: "minimal" });
      } else if (this.verObjtyp === "VERTRAG") {
        promise = model.getList({ v_nr: term });
      }

      promise.then(
        result => {
        	this.verObjSearch = result.plain();
          this.loadingVerObj = false;
          this.scrollModalWindow();
        }
      );
    };
  }

  onVerObjtypChanged() {
    this.ver_obj = null;
  }

  getverObjtypModel() {
    let model;
    angular.forEach(this.verObjtypChoices, data => {
      if (data.key === this.verObjtyp) {
        model = data.model;
        return;
      }
    });
    return model;
  }

  scrollModalWindow() {
    this.$timeout(() => {
      $(".modal-body").scrollTop(600);
    }, 500);
  }
}

export default CreateManuelleAufgabeController;
