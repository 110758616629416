import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import KostenMixin from "../Mixins/KostenMixin";

// @vue/component
export default {
  name: "SignalKosten",
  components: {
    KFStandard,
  },
  mixins: [
    KostenMixin,
  ],
  computed: {
    sumLocal() {
      return this.summeCurrency;
    },
  },
  methods: {
    onReceiveSignal(signal_kennung) {
      if (signal_kennung === "dynamische_tabelle.update" ||
        signal_kennung === "dynamische_zahlen.update") {
        this.reloadPositionen_KFBaseMixin({ statusSaveCallback: false });
        this.updateZuschuss_KFBaseMixin();
      }
    },

    updateInfoproviderLocal_KFBaseMixin({ response }) {
      response.gespeichert = true;
      this.updateInfoprovider({ response });
    },
  },
};
