"use strict";

import angular from "angular";


class i18n {
  /*@ngInject*/
  constructor($window) {
    this.$window = $window;
  }

  gettext(msg) {
    if (angular.isUndefined(window.gettext)) {
      return msg;
    }
    return window.gettext(msg);
  }
}

export default i18n;
