"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";
import diasTable from "dias/core/table";

import services from "./services";

import foerderfinderangeboteDetails from "./foerderangebote.details.component";
import categoriesBoxComponent from "./categories.box.component";

const modul = angular.module("info.foerderfinderangebote", [
  uiRouter,
  diasTable.name,
  services.name,
]);

modul.config($stateProvider => {
  $stateProvider

    .state("root.foerderfinderangebote", {
      url: "/foerderfinderangebote/?kategoriewert",
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: `<div vueinit>
                      <vue-foerderfinderprogramme-list></vue-foerderfinderprogramme-list>
                    </div>`,
        }
      },
      data: {
        instance_pages: "foerderfinderangebote"
      }
    })
    .state("root.foerderfinderangebote.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<foerderfinderangebote-details></foerderfinderangebote-details>"
        }
      },
      data: {
        instance_pages: "foerderfinderangeboteDetails"
      }
    })
  ;
})

  .component("foerderfinderangeboteDetails", foerderfinderangeboteDetails)
  .component("categoriesBox", categoriesBoxComponent)

;

export default modul;
