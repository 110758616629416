"use strict";

import template from "./foerderangebote.details.jade";

class FoerderfinderAngebotController {
  /*@ngInject*/
  constructor(
    $q, 
    $state, 
    $stateParams, 
    $filter, 
    AuthService, 
    FoerderangebotInfo, 
    FoerderorgInfo, 
    FoerderantragquelleUUIDs,
    DiasNotification, 
    diasModalDialog, 
    Foerderantrag, 
    i18n, 
    SyConfigs
  ) {
    this.gettext = i18n.gettext;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.AuthService = AuthService;

    this.FoerderangebotInfo = FoerderangebotInfo;
    this.FoerderorgInfo = FoerderorgInfo;

    this.uebersichtStatus = SyConfigs.wert("f_finder_uebersicht");

    this.loading = true;
    this.regelsatz = {};
    this.kategoriewerte = {};
    this.categoriesAll = {};
    this.statusShowMore = {};

    this.loadRegelsatz();

    this.createAntrag = () => {
      const antrag = {
        regelsatz: this.regelsatz.pk,
        quelle: FoerderantragquelleUUIDs.webapp
      };
      const promise = Foerderantrag.post(antrag).then(
        response => {
          DiasNotification.page.success("Antrag <spaced ng-model='response.a_nr'></spaced> wurde erfolgreich erstellt.");
          $state.go("root.foerderantrag.details", { id: response.pk });
        },
        error => DiasNotification.page.error(error, "Fehler beim Erstellen des Antrags")
      );
      return diasModalDialog({
        title: "Bitte warten Sie",
        template: () => `
          <div class="modal-body">
            <h4 class="text-center">
              Der Antrag für ` + this.regelsatz.ars_kbez + ` wird erstellt.<br>
              Sie werden nach Erstellung automatisch zur Antragseite weitergeleitet.
            </h4>
          </div>`,
        controller: /*@ngInject*/$scope => {
          $scope.exitWithCallback(() => promise);
        }
      });
    };

    this.checkStatusCreateButton();
  }

  checkStatusCreateButton() {
    this.AuthService.getUser().then(
      response => {
        if (response.pk) {
          this.user = response;
          this.AuthService.hasPerm("foerderantrag.create").then(
            hasPerm => {
              this.statusCreateButton = hasPerm;
            }
          );
        } else {
          this.statusCreateButton = true;
        }
      }
    );
  }


  loadRegelsatz() {
    this.FoerderangebotInfo.one(this.$stateParams.id).get().then(
      response => {
        this.regelsatz = response;
        if (this.regelsatz && this.regelsatz.organisation_obj && this.regelsatz.organisation_obj.pk) {
          this.loadFoerderorgInfo();
        }
        this.loading = false;
      }
    );
  }

  loadFoerderorgInfo() {
    this.FoerderorgInfo.one(this.regelsatz.organisation_obj.pk).get().then(
      response => {
        this.org = response;
      }
    );
  }

  checkCreateAntrag() {
    if (this.user) {
      this.createAntrag();
    } else {
      this.$state.go("root.login", { next: `/foerderantrag/neu_mit_foerderangebot/?id=${ this.regelsatz.angebot }` });
    }
  }

  showMore(obj) {
    this.statusShowMore[obj.pk] = true;
  }
}

export default {
  template: template(),
  controller: FoerderfinderAngebotController,
  controllerAs: "vm"
};
