"use strict";

class FilterCreateController {
  /*@ngInject*/
  constructor($q, DiasNotification, $scope, Nutzerfilter) {
    this.DiasNotification = DiasNotification;
    this.Nutzerfilter = Nutzerfilter;
    this.$q = $q;

    this.filterName = $scope.extras.filterName;
    this.sortFilter = $scope.extras.sortFilter;
    this.filter = $scope.extras.filter;
    this.filterConf = {};

    this.collectObj();

    this.save = this.save.bind(this);
  }

  save() {
    const data = {
      nfi_kennung: this.filterName,
      nfi_kbez: this.modelName,
      nfi_conf: this.filterConf
    };
    return this.Nutzerfilter.post(data).then(
      response => {
        this.DiasNotification.page.success("Suchformular ist erstellt");
        return response;
      },
      error => {
        this.DiasNotification.form.clear();
        this.DiasNotification.form.error(error.data, "Ihre Daten sind fehlerhaft oder unvollständig.", {});
        this.errors = error.data;
        return this.$q.reject(error);
      }
    );
  }


  collectObj() {
    angular.forEach(this.sortFilter, key => {
      this.filterConf[key] = this.filter[key].selected;
    });
  }
}

export default FilterCreateController;
