import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import KategoriewertButton from "../../../Foerderfinder/KategoriewertButton/KategoriewertButton.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  assign,
  cloneDeep,
  filter,
  forEach,
  sortBy,
  keyBy,
  isEqual,
} from "lodash-es";

// @vue/component
export default {
  name: "FoerderfinderkategorieAuswahl",
  components: {
    SnapshotModule,
    KategoriewertButton,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      loading: true,
      kategoriewerte: [],
      kategoriewerteByPk: {},
      kategoriewerteAngebotPks: {},
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.open;
      return OPTIONS;
    },

    statusDiff() {
      if (!this.statusSnapshot) {
        return;
      }
      return !isEqual(sortBy(this.model.kategoriewerte), sortBy(this.snapshotKategoriewerte));
    },

    snapshotKategoriewerte() {
      if (this.snapshotModule && this.snapshotModule.kategoriewerte) {
        return this.snapshotModule && this.snapshotModule.kategoriewerte;
      }
      return [];
    },

    kategoriewerteFiltered() {
      return filter(this.kategoriewerte, item => {
        return (this.kategoriewerteAngebotPks[item.pk] && !item.fkw_deaktiviert) || this.model.kategoriewerte.indexOf(item.pk) !== -1;
      });
    },

    kategoriewerteFilteredSnapshot() {
      const SNAPSHOT_KATEGORIEWERTE = this.snapshotKategoriewerte;
      return filter(this.kategoriewerte, item => {
        return (this.kategoriewerteAngebotPks[item.pk] && !item.fkw_deaktiviert) ||
          SNAPSHOT_KATEGORIEWERTE.indexOf(item.pk) !== -1;
      });
    },

    angebotId() {
      return this.obj.angebot || this.obj.foe_id;
    },

    textHeaderEdit() {
      return this.modelParameter.mehrfachauswahl ?
        "_TXT_WAEHLEN_ANTWORTEN_" :
        "_TXT_WAEHLEN_ANTWORT_";
    },

    errorsLabelsOptions() {
      return [
        {
          isErrorLink: false,
          id: "kategoriewerte",
          label: this.textHeaderEdit,
        },
      ];
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    prepareModel() {
      this.model = cloneDeep(this.module.data) || {};
      if (!this.model.kategoriewerte) {
        this.model = assign(this.model, { kategoriewerte: [] });
      }
    },

    loadData() {
      Promise.all([
        this.getListHttp({
          url: "katalog/",
          urlParams: {
            key: "foerderfinderkategoriewerte",
            kategorie: this.modelParameter.kategorie,
          },
        }),
        this.getListHttp({
          url: `foerderangebote/${ this.angebotId }/kategorien/`,
          urlParams: {
            kategorie: this.modelParameter.kategorie,
          },
        })
      ]).then(
        responses => {
          this.kategoriewerte = sortBy(responses[0], ["fkw_pos"]);
          this.kategoriewerteByPk = keyBy(responses[0], "pk");
          this.setKategoriewerteAngebot({ response: responses[1] });
          this.loading = false;
        }
      );
    },

    setKategoriewerteAngebot({ response }) {
      const KATEGORIEWERTE = {};
      forEach(response, item => {
        KATEGORIEWERTE[item.pk] = true;
      });
      this.kategoriewerteAngebotPks = KATEGORIEWERTE;
    },

    open() {
      this.modelEdit = cloneDeep(this.model);
      this.kategoriewerte.forEach(wert => {
        if (wert.deaktiviert && this.modelEdit.kategoriewerte.indexOf(wert.pk) !== -1) {
          this.modelEdit.kategoriewerte.splice(this.modelEdit.kategoriewerte.indexOf(wert.pk), 1);
        }
      });
    },

    onClickKategoriewertButton({ status, pk }) {
      if (this.modelParameter.mehrfachauswahl) {
        if (status) {
          const INDEX = this.modelEdit.kategoriewerte.indexOf(pk);
          this.modelEdit.kategoriewerte.splice(INDEX, 1);
        } else {
          this.modelEdit.kategoriewerte.push(pk);
        }
      } else {
        this.modelEdit.kategoriewerte = [];
        if (!status) {
          this.modelEdit.kategoriewerte.push(pk);
        }
      }
    },

    clickCheckbox(pk) {
      const STATUS = this.modelEdit.kategoriewerte.indexOf(pk) !== -1;
      this.onClickKategoriewertButton({ status: STATUS, pk });
    },
  },
};
