var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;







jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<fieldset dias-cloak=\"vm.loadingEditData\"><alert type=\"'info'\" ng-if=\"!vm.hide &amp;&amp; vm.model.is_used\" dismissable=\"true\" on-dismiss=\"vm.hide = !vm.hide\"><span class=\"glyphicon-information\"></span>\nEs sollten nur noch redaktionelle Änderungen an dieser Geschäftsregel\ndurchgeführt werden,\nda sie bereits in mindestens einem Förderangebot verwendet wird.</alert><div ng-attr-id=\"{{ vm.errorContainerId }}\"></div><div class=\"row\"><div class=\"col-12 col-sm-6\"><h4>Beschreibung</h4>");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<select id=\"modul\" name=\"modul\" ng-disabled=\"vm.model.is_used\" ng-model=\"vm.model.modul\" ng-change=\"vm.onModulChange(vm.model.modul)\" role=\"listbox\" class=\"form-control\"><option value=\"\">---------</option><option ng-repeat=\"sm in vm.modulChoices\" value=\"{{ sm.sm_id }}\">{{ sm.sm_bez }}</option></select>");
}
}, "modul", "Modul");
buf.push("<div class=\"well\"><label>Modulbeschreibung</label><div ng-bind-html=\"vm.model.modul_obj.sm_beschreibung\"></div></div></div><div class=\"col-12 col-sm-6\"><h4 ng-if=\"vm.model.re_argument &amp;&amp; vm.model.param_keys.length &gt; 0\">Parameter</h4><div ng-repeat=\"key in vm.model.param_keys\"><div ng-class=\"{'has-error has-feedback':vm.errors[key]}\" class=\"form-group\"><label ng-attr-for=\"{{:: key }}\" ng-class=\"{'has-error has-feedback':vm.errors[key]}\" class=\"font-weight-bold\"><span class=\"titel\">{{:: vm.model.re_argument[key].bez }} {{:: vm.model.re_argument[key].options.required?'*':''}}<span class=\"alert-danger\"></span></span></label><parametermodule parameter=\"vm.model.re_argument\" key=\"key\"></parametermodule><span ng-if=\"vm.errors[key]\" class=\"help-block\"><ul class=\"errorlist\"><li ng-repeat=\"err in vm.errors[key]\">{{ err }}</li></ul></span></div></div></div></div></fieldset><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Abbrechen</span></button></div></div>");;return buf.join("");
}