import ProfilDetailsDatenschutzFrage from "../ProfilDetailsDatenschutzFrage/ProfilDetailsDatenschutzFrage.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "ProfilDetailsDatenschutz",
  components: {
    ProfilDetailsDatenschutzFrage,
    PuxCloak,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      fragen: [],
    };
  },
  computed: {
    urlFragen() {
      return `profil/${ this.nutzer.pk }/datenschutzfragen/`;
    },
  },
  created() {
    this.loadDatenSchutzFragen();
  },
  methods: {
    loadDatenSchutzFragen() {
      this.loading = true;
      this.getHttp({
        url: this.urlFragen,
      }).then(
        response => {
          this.fragen = response;
          this.loading = false;
        }
      );
    },

    updateFrage({ frage, frageIndex }) {
      this.fragen.splice(frageIndex, 1, frage);
    },
  },
};
