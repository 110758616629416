"use strict";

import BaseModel from "dias/core/models/baseModel";
import Positionen from "./positionen.service";

class Kosten extends BaseModel {
  constructor() {
    super("kosten", {
      subPaths: {
        kostenPositionen: new Positionen()
      }
    });
  }

  extendModel(model) {
    model = super.extendModel(model);

    model.createPosition = data => model.customPOST(data, "positionen");

    model.restangularizePositionen = positionen => this.Restangular.restangularizeCollection(model, positionen, "positionen", {});

    if (model.positionen) {
      model.positionen = model.restangularizePositionen(model.positionen);
    }

    return model;
  }
}

export default Kosten;
