"use strict";

import angular from "angular";

import Config from "./config.service";

const services = angular.module("dias.richtext.services", [
])

  .constant("DefaultRichtextConfig", Config)
;

export default services;
