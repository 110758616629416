"use strict";

import BetragsrechnerBase from "../betragsrechner.base";

class RolliplaetzeRechner extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "manuelle_rueckforderungen";
    this.component_name = "manuelle-rueckforderungen";
    this.labels = {
      kommentar: "Kommentar",
      grund: "Begründung",
      wert: "Betrag in EUR"
    };
    this.disablePreview = true;
  }

  get_labelmapping() {
    return this.labels;
  }

  get_fields() {
    return {
      kommentar: "",
      grund: "",
      wert: null
    };
  }
}
export default RolliplaetzeRechner;
