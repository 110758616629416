import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "AnkuendigungBasicInformation",
  components: {
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    ankuendigung: {
      type: Object,
      required: true,
      info: "Hauptinformation Ankündigung",
    },
    newsUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "updateAnkuendigung",
  ],
  setup() {
    const {
      addNotification,
      putHttp,
    } = HttpAPI();

    return {
      addNotification,
      putHttp,
    };
  },

  data() {
    return {
      sichtbarkeitDisabled: false,
      helpTextRollenId: undefined,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        label: "_TXT_ANKUENDIGUNG_DETAILS_ANKUENDIGUNG_PANEL_FORMSTEP_NAME_",
        list: [
          {
            id: "nws_titel",
            type: "text",
            label: "_LBL_ANKUENDIGUNG_DETAILS_TITEL_",
            showLabel: true,
            view: "v-alt",
            required: true,
            translate: true,
          },
          {
            id: "nws_inhalt",
            type: "richtext",
            label: "_LBL_ANKUENDIGUNG_DETAILS_INHALT_",
            showLabel: true,
            view: "v-alt",
            required: true,
            translate: true,
          },
          {
            id: "nws_gueltig_ab",
            type: "datetime",
            label: "_LBL_ANKUENDIGUNG_DETAILS_GUELTIG_AB_",
            showLabel: true,
            view: "v-alt",
            required: true,
            translate: true,
          },
          {
            id: "nws_gueltig_bis",
            type: "datetime",
            label: "_LBL_ANKUENDIGUNG_DETAILS_GUELTIG_BIS_",
            showLabel: true,
            view: "v-alt",
            translate: true,
          },
          {
            id: "rollen_ids",
            type: "multiselect",
            label: "_LBL_ANKUENDIGUNG_DETAILS_SICHTBAR_FUR_",
            showLabel: true,
            view: "v-alt",
            required: true,
            keyId: "pk",
            url: "rollen/",
            keyLabelCallback: ({ item }) => `${ item.bez } (${ item.kbez })`,
            search: true,
            searchList: ["bez", "kbez"],
            apiSaveId: "rollen",
            helpText: this.helpTextRollenId,
            permission: ["rollen.view"],
            change: this.modifyDisabled,
            placeholder: "Alle",
            editonly: true,
            translate: true,
          },
          {
            id: "rollen",
            type: "template",
            label: "_LBL_ANKUENDIGUNG_DETAILS_SICHTBAR_FUR_",
            showLabel: true,
            view: "v-alt",
            keyId: "pk",
            slot: "rollen",
            permission: ["rollen.view"],
            readonly: true,
            translate: true,
          },
          {
            id: "sichtbarkeit",
            type: "multiselect",
            label: "_LBL_ANKUENDIGUNG_MODAL_CREATE_SICHTBARKEIT_",
            required: false,
            data: [
              { id: "nws_intern", label: "_LBL_ANKUENDIGUNG_DETAILS_INTERN_" },
              { id: "nws_extern", label: "_LBL_ANKUENDIGUNG_DETAILS_EXTERN_" },
            ],
            dataTranslate: true,
            view: "v-alt",
            disabled: this.sichtbarkeitDisabled,
            translate: true,
            editonly: true,
          },
          {
            id: "nws_intern",
            type: "boolean",
            label: "_LBL_ANKUENDIGUNG_DETAILS_INTERN_",
            showLabel: true,
            view: "v-alt",
            required: true,
            disabled: false,
            translate: true,
            readonly: true,
          },
          {
            id: "nws_extern",
            type: "boolean",
            label: "_LBL_ANKUENDIGUNG_DETAILS_EXTERN_",
            showLabel: true,
            view: "v-alt",
            required: true,
            disabled: false,
            translate: true,
            readonly: true,
          },
        ],
        required: true,
        saveCallback: this.save,
        openCallback: this.open,
      };
    },
  },
  methods: {
    save({ model }) {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.newsUrl,
          data: this.setDataForSave({ model }),
        }).then(
          response => {
            this.updateAnkuendigungLocal({ response, rollen_ids: model.rollen_ids });
            this.addNotification({
              text: "_MSG_ANKUENDIGUNG_DETAILS_UPDATE_{{titel}}_",
              extra: {
                titel: this.ankuendigung.nws_titel,
              },
            });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },

    setDataForSave({ model }) {
      const DATA = cloneDeep(model);
      DATA.nws_intern = DATA.sichtbarkeit.indexOf("nws_intern") !== -1;
      DATA.nws_extern = DATA.sichtbarkeit.indexOf("nws_extern") !== -1;
      DATA.sichtbarkeit = undefined;
      return DATA;
    },

    updateAnkuendigungLocal({ response, rollen_ids }) {
      const ANKUENDIGUNG = response;
      ANKUENDIGUNG.rollen_ids = cloneDeep(rollen_ids);

      this.$emit("updateAnkuendigung", { ankuendigung: ANKUENDIGUNG });
    },

    modifyDisabled({ model, statusInit }) {
      setTimeout(() => {
        if (model && model.length) {
          this.sichtbarkeitDisabled = true;
          this.helpTextRollenId = "_LBL_ANKUENDIGUNG_DETAILS_SICHTBAR_FUR_HELP_TEXT_";
          if (!statusInit) {
            this.setModelSichtbarkeitIntern();
          }
        } else {
          this.sichtbarkeitDisabled = false;
          this.helpTextRollenId = undefined;
        }
      });
    },

    setModelSichtbarkeitIntern() {
      if (!this.$refs.formstepDetail) {
        return;
      }
      const MODEL = cloneDeep(this.$refs.formstepDetail.modelEdit);
      MODEL.sichtbarkeit = ["nws_intern"];
      this.$refs.formstepDetail.changeModel({ model: MODEL });
    },

    open() {
      this.modifyDisabled({ model: this.ankuendigung.rollen_ids, statusInit: true });
      return { model: this.setModel() };
    },

    setModel() {
      const MODEL = cloneDeep(this.ankuendigung);
      MODEL.sichtbarkeit = [];
      if (this.ankuendigung.nws_intern) {
        MODEL.sichtbarkeit.push("nws_intern");
      }
      if (this.ankuendigung.nws_extern) {
        MODEL.sichtbarkeit.push("nws_extern");
      }
      return MODEL;
    },
  },
};
