"use strict";

import angular from "angular";


import parameterSitzungstopauswahl from "./sitzungstopauswahl";

const parameter = angular.module("dias.sitzungen.parameter", [
  parameterSitzungstopauswahl.name,
])
;

export default parameter;
