import IsFilter from "../../../global/filters/IsFilter";
import {
  filter,
  sortBy,
  forEach,
  isNil,
  isArray,
  get,
} from "lodash-es";

// @vue/component
export default {
  methods: {
    getPopupHtml({ pk, clusterKey }) {
      const MARKER_INFO = this.markerAllInfo[pk];
      const TOOLTIP = this.markerTypes[clusterKey].tooltip;

      return `<div>
                <h4>${ MARKER_INFO.titel }</h4>
                <dl>${ this.getDtAndDd({ tooltip: TOOLTIP, markerInfo: MARKER_INFO }) }
                </dl>
              </div>`;
    },

    getDtAndDd({ tooltip, markerInfo }) {
      let dtAndDdHtml = "";
      forEach(this.getSortedUndFilteredOptions({ tooltip, markerInfo }), item => {
        dtAndDdHtml += `<dt>${ item.label }</dt><dd>${ this.getDdHtml({ item, markerInfo }) }</dd>`;
      });
      return dtAndDdHtml;
    },

    getSortedUndFilteredOptions({ tooltip, markerInfo }) {
      return sortBy(filter(tooltip, item => {
        let showItem = false;
        forEach(item.values, i => {
          const CURRENT_VALUE = get(markerInfo, i.value);
          if (isArray(CURRENT_VALUE) && CURRENT_VALUE.length) {
            showItem = true;
          }
          if (!isNil(CURRENT_VALUE)) {
            showItem = true;
          }

          if (showItem) {
            return false; // Lodash break
          }
        });
        return showItem;
      }), ["priority"]);
    },

    getDdHtml({ item, markerInfo }) {
      let ddHtml = "";
      forEach(item.values, value => {
        ddHtml += ` <span>${ this.getValue({ item: value, markerInfo }) }</span>`;
      });
      return ddHtml;
    },

    getValue({ item, markerInfo }) {
      if (item.text) {
        return item.text;
      }
      if (item.value === "client_alter") {
        return this.getValueAlter({ markerInfo });
      }
      const DATA = get(markerInfo, item.value);
      if (isArray(DATA)) {
        return DATA.join(", ");
      }
      return IsFilter(DATA, item.filter);
    },

    getValueAlter({ markerInfo }) {
      const MIN = markerInfo.mindestalter;
      const MAX = markerInfo.hoechstalter;
      return `${ MIN || "" }${ MIN && MAX ? " bis " : "" }${ MAX || "" }${ MIN || MAX ? " Jahre" : "" }`;
    },
  },
};
