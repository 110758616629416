"use strict";

import BaseModel from "dias/core/models/baseModel";

class Funktionsgruppen extends BaseModel {
  constructor() {
    super("funktionsgruppen", {
      pk: "key"
    });
  }

  configRestangular(RestangularConfigurer) {
    RestangularConfigurer.setDefaultHttpFields({ cache: true });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.getByKey = super.createGetByAttribute(collection, "key");
    collection.splitIntoGroups = parts => {
      const partSize = Math.floor(collection.length / parts);
      const rest = collection.length % parts;
      const groups = [];
      let start = 0;
      for (let i = 0; i < parts; i++) {
        const length = partSize + ((rest - i) > 0 ? 1 : 0);
        groups.push({
          start: start,
          length: length
        });
        start = start + length;
      }
      return groups;
    };
    return collection;
  }
}

export default Funktionsgruppen;
