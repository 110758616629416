"use strict";

import angular from "angular";

import models from "dias/auth/models";


const funktionsgruppenModule = angular.module("dias.auth.funktionsgruppen", [
  models.name,
]);

/*@ngInject*/
funktionsgruppenModule.config($stateProvider => {
  $stateProvider
    .state("root.fgruppen", {
      url: "/funktionsgruppen/?name",
      views: {
        "main@root": {
          template: "<div vueinit><vue-funktionsgruppen-list></vue-funktionsgruppen-list></div>"
        }
      },
      data: {
        permissions: "funktionsgruppen.ui"
      }
    })
  ;
});

export default funktionsgruppenModule;
