export default [
  {
    name: "_TXT_CALENDAR_NAVIGATION_CONST_YEAR_",
    key: "year",
  },
  {
    name: "_TXT_CALENDAR_NAVIGATION_CONST_MONTH_",
    key: "month",
  },
  {
    name: "_TXT_CALENDAR_NAVIGATION_CONST_WEEK_",
    key: "week",
  },
  {
    name: "_TXT_CALENDAR_NAVIGATION_CONST_DAY_",
    key: "day",
  },
];
