import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  emits: [
    "changeModel",
  ],
  data() {
    return {
      labelKategorie: "_LBL_FINANZIERUNG_DETAILLIERT_KATEGORIE_",
      labelFinanzierer: "_LBL_FINANZIERUNG_DETAILLIERT_FINANZIERER_",
      labelFinanziererFreitext: "_LBL_FINANZIERUNG_DETAILLIERT_FINANZIERER_FREITEXT_",
      labelWert: "_LBL_FINANZIERUNG_DETAILLIERT_WERT_",
      labelStatus: "_LBL_FINANZIERUNG_DETAILLIERT_STATUS_",
      labelStatusdatum: "_LBL_FINANZIERUNG_DETAILLIERT_STATUSDATUM_",
      labelBemerkung: "_LBL_FINANZIERUNG_DETAILLIERT_BEMERKUNG_",
    };
  },
  computed: {
    optionsKategorie() {
      const FINANZIERUNGSART = this.extra.modelParameter.finanzierungsart;
      return {
        id: "kategorie",
        htmlId: this.htmlIdKategorie,
        type: "select",
        required: true,
        view: this.view,
        label: this.labelKategorie,
        classesHorizontal: this.classesHorizontal,
        keyId: "pk",
        keyLabel: "fid_kbez",
        search: true,
        searchList: ["fid_kbez"],
        placeholder: "_TXT_FINANZIERUNG_DETAILLIERT_KATEGORIE_PLACEHOLDER_",
        withoutGroup: true,
        url: "katalog/",
        urlParams: {
          finanzierungsart: FINANZIERUNGSART,
          key: "finanzierungsartdetail",
        },
        apiSaveId: `finanzierungsartdetail_${ FINANZIERUNGSART }`,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdKategorie() {
      return `${ this.htmlId }_kategorie`;
    },

    optionsFinanzierer() {
      return {
        id: "finanzierer",
        htmlId: this.htmlIdFinanzierer,
        type: "select",
        required: true,
        view: this.view,
        label: this.labelFinanzierer,
        classesHorizontal: this.classesHorizontal,
        keyId: "pk",
        keyLabel: "kfi_bez",
        search: true,
        searchList: ["kfi_bez"],
        placeholder: "_TXT_FINANZIERUNG_DETAILLIERT_FINANZIERER_PLACEHOLDER_",
        url: "katalog/",
        urlParams: {
          key: "kfinanzierer",
        },
        apiSaveId: "kfinanzierer",
        change: this.changeVorschauLocal,
      };
    },

    htmlIdFinanzierer() {
      return `${ this.htmlId }_finanzierer`;
    },

    optionsFinanziererFreitext() {
      return {
        id: "finanzierer_freitext",
        htmlId: this.htmlIdFinanziererFreitext,
        label: this.labelFinanziererFreitext,
        placeholder: "_TXT_FINANZIERUNG_DETAILLIERT_FINANZIERER_FREITEXT_PLACEHOLDER_",
        view: this.view,
        type: "text",
        maxlength: 100,
        required: false,
        classesHorizontal: this.classesHorizontal,
        change: this.onChangeFinanziererFreitext,
      };
    },

    htmlIdFinanziererFreitext() {
      return `${ this.htmlId }_finanzierer_freitext`;
    },

    optionsWert() {
      return {
        id: "wert",
        htmlId: this.htmlIdWert,
        label: this.labelWert,
        view: this.view,
        maxlength: 14,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdWert() {
      return `${ this.htmlId }_wert`;
    },

    optionsStatus() {
      return {
        id: "status",
        htmlId: this.htmlIdStatus,
        type: "select",
        required: true,
        view: this.view,
        label: this.labelStatus,
        classesHorizontal: this.classesHorizontal,
        keyId: "pk",
        keyLabel: "fis_bez",
        search: true,
        searchList: ["fis_bez"],
        placeholder: "_TXT_FINANZIERUNG_DETAILLIERT_STATUS_PLACEHOLDER_",
        url: "katalog/",
        urlParams: {
          key: "kfinanzierungsstatus",
        },
        apiSaveId: "kfinanzierungsstatus",
        change: this.changeVorschauLocal,
      };
    },

    htmlIdStatus() {
      return `${ this.htmlId }_status`;
    },

    optionsStatusdatum() {
      return {
        id: "statusdatum",
        htmlId: this.htmlIdStatusdatum,
        label: this.labelStatusdatum,
        placeholder: "_TXT_FINANZIERUNG_DETAILLIERT_STATUSDATUM_PLACEHOLDER_",
        view: this.view,
        type: "date",
        required: false,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdStatusdatum() {
      return `${ this.htmlId }_statusdatum`;
    },

    optionsBemerkung() {
      return {
        id: "bemerkung",
        htmlId: this.htmlIdBemerkung,
        label: this.labelBemerkung,
        view: this.view,
        type: "text",
        required: false,
        maxlength: 100,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdBemerkung() {
      return `${ this.htmlId }_bemerkung`;
    },

    errorLabels() {
      return {
        kategorie: {
          label: this.labelKategorie,
          link: true,
          id: this.htmlIdKategorie,
        },
        finanzierer: {
          label: this.labelFinanzierer,
          link: true,
          id: this.htmlIdFinanzierer,
        },
        finanzierer_freitext: {
          label: this.labelFinanziererFreitext,
          link: true,
          id: this.htmlIdFinanziererFreitext,
        },
        wert: {
          label: this.labelWert,
          link: true,
          id: this.htmlIdWert,
        },
        status: {
          label: this.labelStatus,
          link: true,
          id: this.htmlIdStatus,
        },
        statusdatum: {
          label: this.labelStatusdatum,
          link: true,
          id: this.htmlIdStatusdatum,
        },
        bemerkung: {
          label: this.labelBemerkung,
          link: true,
          id: this.htmlIdBemerkung,
        },
      };
    },
  },
  methods: {
    onChangeFinanziererFreitext() {
      if (this.model.argument.finanzierer_freitext) {
        const MODEL = cloneDeep(this.model);
        MODEL.argument.finanzierer = "d62ac6f2-32d0-4c9c-b1d2-92615a3905c8";
        this.$emit("changeModel", { model: MODEL });
      }
      this.changeVorschauLocal();
    },
  },
};
