import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";
import UiAdressenMixin from "./UiAdressenMixin";
import translate from "../../../../../global/directives/translate";
import UiAdressenTr from "./UiAdressenTr/UiAdressenTr.vue";
import {
  cloneDeep,
  forEach,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "UiAdressen",
  components: {
    UiAdressenTr,
  },
  directives: {
    translate,
  },
  mixins: [
    UIComponentMixin,
    UiAdressenMixin,
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
    errors: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
  },
  computed: {
    errorsLocal() {
      return this.errors || {};
    },
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      const MODEL = cloneDeep(this.model) || {};
      forEach(this.fields, field => {
        MODEL[field.key] = MODEL[field.key] || {
          required: true,
          show: true
        };
        if (isUndefined(MODEL[field.key].label_editonly)) {
          MODEL[field.key].label_editonly = window.gettext(field.label);
        }
        if (isUndefined(MODEL[field.key].label_readonly)) {
          MODEL[field.key].label_readonly = window.gettext(field.label);
        }
        if (isUndefined(MODEL[field.key].required)) {
          MODEL[field.key].required = true;
        }
        if (isUndefined(MODEL[field.key].show)) {
          MODEL[field.key].show = true;
        }
      });
      this.onInput_mixin({ value: MODEL });
    },

    setModelFromChild({ key, id, model }) {
      const MODEL = cloneDeep(this.model);
      MODEL[key][id] = model;
      this.onInput_mixin({ value: MODEL });
    },
  },
};
