import GeschaeftsregelModul from "../../../Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";
import translate from "../../../../global/directives/translate";
import {
  FilterCurrencyMixin,
  FilterDateMixin,
} from "../../../../global/mixins/FiltersMixin";
import {
  forEach,
} from "lodash-es";
import { createNamespacedHelpers } from "vuex";
const {
  mapState,
} = createNamespacedHelpers("snapshot");

// @vue/component
export default {
  components: {
    GeschaeftsregelModul,
  },
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterDateMixin,
  ],
  props: {
    module: {
      type: Array,
      required: true,
    },
    idx: {
      type: Number,
      required: true,
    },
    infoprovider: {
      type: Object,
      required: false,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
    obj: {
      type: Object,
      required: true,
    },
    nummerierungausblenden: {
      type: Boolean,
      required: false,
    },
    saveCallback: {
      type: Function,
      required: true,
    },
    updateinfoprovider: {
      type: Function,
      required: true,
    },
    updateModule: {
      type: Function,
      required: false,
      default: undefined,
    },
    startNumber: {
      type: Number,
      required: true,
    },
    baseUrl: {
      type: String,
      required: false, // TODO: soll true sein, wenn wir Antrag in angular wegschmeissen
      default: undefined,
    },
  },
  computed: {
    moduleLocal() {
      return this.module || [];
    },

    headerActualId() {
      return `${ this.kofiPlanHtmlId }_header_actual`;
    },

    headerVersionId() {
      return `${ this.kofiPlanHtmlId }_header_version`;
    },

    extraForModule() {
      return {
        headerActualId: this.headerActualId,
        headerVersionId: this.headerVersionId,
      };
    },

    snapshotDatum() {
      return this.filterDate(this.snapshot.asn_snapshot_datum, "DD.MM.YYYY HH:mm");
    },

    statusDiff() {
      if (!this.snapshot) {
        return false;
      }
      let statusChange = false;
      forEach(this.moduleLocal, modul => {
        const REGEL_ID = modul.regel.regel;
        if (this.STATUS_DIFF_FOR_REGELS[REGEL_ID]) {
          statusChange = true;
          return false;
        }
      });
      return statusChange;
    },

    ...mapState([
      "STATUS_DIFF_FOR_REGELS",
    ]),
  },
  methods: {
    updateinfoproviderLocal(arg) {
      this.updateinfoprovider(arg);
    },

    saveCallbackLocal(arg) {
      this.saveCallback(arg);
    },
  },
};
