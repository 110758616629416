import ModalCreateOrUpdate from "./ModalCreateOrUpdate/ModalCreateOrUpdate.vue";
import UIComponentMixin from "../UIComponentMixin";
import PuxButton from "../../PuxButton/PuxButton.vue";
import translate from "../../../directives/translate";

import {
  cloneDeep,
  findKey,
  forEach,
  orderBy,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeFormDefinition",
  components: {
    ModalCreateOrUpdate,
    PuxButton,
  },
  directives: {
    translate
  },
  mixins: [
    UIComponentMixin,
  ],
  props: {},
  data() {
    return {
      statusModal: undefined,
      confirmShow: undefined,
      confirmOptions: undefined,
      currentModel: undefined,
    };
  },
  computed: {
    localModel() {
      return this.model || {};
    },
    modelSize() {
      return size(this.localModel);
    },
    modelList() {
      return orderBy(this.localModel, ["pos"]);
    },
    formFields() {
      return this.options.formFields || {
        base: {},
        typed: {}
      };
    },
    translationNoEntrey() {
      return { text: "_TXT_UI_DYNAMISCHE_FORMDEFINITION_EMPTY_" };
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../Modal/Modal.vue").default;
  },
  methods: {
    openModalCreate() {
      this.statusModal = true;
    },

    openModalUpdate({ item }) {
      this.currentModel = item;
      this.statusModal = true;
    },

    closeModal({ model } = {}) {
      if (model) {
        this.onInput_mixin({ value: model });
      }
      this.currentModel = undefined;
      this.statusModal = false;
    },

    moveUpFeld({ id }) {
      if (this.localModel[id].pos < 1) {
        return;
      }
      const MODEL = cloneDeep(this.localModel);
      const POS = MODEL[id].pos - 1;
      const KEY = findKey(MODEL, ["pos", POS]);
      MODEL[id].pos--;
      MODEL[KEY].pos++;
      this.onInput_mixin({ value: MODEL });
    },

    moveDownFeld({ id }) {
      if (this.localModel[id].pos >= this.getModelSize - 1) {
        return;
      }
      const MODEL = cloneDeep(this.localModel);
      const POS = MODEL[id].pos + 1;
      const KEY = findKey(MODEL, ["pos", POS]);
      MODEL[id].pos++;
      MODEL[KEY].pos--;
      this.onInput_mixin({ value: MODEL });
    },

    openConfirmDelete({ item }) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_UI_DYNAMISCHE_FORMDEFINITION_FIELD_REMOVE_DIALOG_TITLE_{{field_label}}_{{field_id}}_",
        msg: "_HTML_UI_DYNAMISCHE_FORMDEFINITION_FIELD_REMOVE_DIALOG_BODY_{{field_label}}_{{field_id}}_",
        okLabel: "_BTN_UI_DYNAMISCHE_FORMDEFINITION_FIELD_REMOVE_DIALOG_OK_",
        okCallback: () => this.deleteFeld({ id: item.id }),
        cancelCallback: this.closeConfirmDelete,
        loading: false,
        extra: {
          field_label: item.label,
          field_id: item.id
        }
      };
      this.confirmShow = true;
    },

    deleteFeld({ id }) {
      const MODEL = cloneDeep(this.localModel);
      const POS = MODEL[id].pos;
      if (MODEL[id]) {
        delete MODEL[id];
      }
      forEach(MODEL, (item, key) => {
        if (item.pos > POS) {
          MODEL[key].pos--;
        }
      });
      this.onInput_mixin({ value: MODEL });
      this.closeConfirmDelete();
    },

    closeConfirmDelete() {
      this.confirmShow = false;
    },
  },
};
