import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "TeamChangeButtonModal",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "updateTeam",
  ],
  data() {
    return {
      isModalOpen: false,
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        style: "min-height: 420px",
        list: [
          {
            id: "team",
            type: "select",
            label: "_TXT_TEAM_",
            required: true,
            view: "v",
            keyId: "pk",
            keyLabel: "t_kbez",
            url: "teams/alle/",
            search: true,
            searchList: ["t_kbez"],
          },
        ],
      },
      statusLoading: undefined,
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    urlSave() {
      return `${ this.baseUrl }team_aendern/`;
    },

    idSaveButton() {
      return `${ this.id }_save`;
    },

    selectorClose() {
      return `#${ this.id }`;
    },
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },

    closeModal() {
      this.isModalOpen = false;
    },

    save() {
      this.statusLoading = true;
      this.postHttp({
        url: this.urlSave,
        data: this.model,
      }).then(
        response => {
          this.addNotification({ text: "_MSG_TEAM_ZUGEWIESEN_SUCCESS_" });
          this.$emit("updateTeam", { team: response.team_obj });
          this.closeModal();
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.statusLoading = false
      );
    },
  },
};
