"use strict";

import angular from "angular";

import NotificationService from "./notification.service";

const services = angular.module("dias.notification.services", [
])

  .service("DiasNotification", NotificationService)
;

export default services;
