const MAX_NORTH_COORDINATE_DE = 55.0591667;
const MAX_SOUTH_COORDINATE_DE = 47.2708333;
const MAX_WEST_COORDINATE_DE = 5.866944444444445;
const MAX_EAST_COORDINATE_DE = 15.043611111111112;

export default {
  coordinates: {
    de: {
      bounds: [
        [MAX_NORTH_COORDINATE_DE, MAX_WEST_COORDINATE_DE],
        [MAX_SOUTH_COORDINATE_DE, MAX_EAST_COORDINATE_DE],
      ],
      maxBounds: [
        [MAX_NORTH_COORDINATE_DE + 4, MAX_WEST_COORDINATE_DE - 1],
        [MAX_SOUTH_COORDINATE_DE, MAX_EAST_COORDINATE_DE + 1],
      ],
    },
  },
  layer: {
    url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
    attribution: `&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`,
  },
  mapOptions: {
    zoomSnap: 1,
  },
  coefficientZoom: {
    18: 4200,
    17: 2100,
    16: 1000,
    15: 500,
    14: 270,
    13: 120,
    12: 40,
    11: 20,
  },
};
