import {
  cloneDeep,
  find,
  findIndex,
  forEach,
  get,
  split,
} from "lodash-es";

export default {
  namespaced: true,
  state() {
    return {
      NOTIZEN_LIST_ALL: [],
      NOTIZEN_OBJ: {},
      NOTIZEN_WEITERE_OBJEKTE: {},
      NOTIZEN_DOCUMENTS: {},
      NOTIZEN: [],
      NOTIZEN_COUNT: 0,
      NOTIZEN_OPTIONS: {},
      NOTIZEN_READY: false,
      NOTIZEN_OPTIONS_READY: false,
    };
  },
  getters: {
    GET_NOTIZEN_ALL: state => {
      return state.NOTIZEN;
    },

    GET_NOTIZEN_OBJ: state => {
      return state.NOTIZEN_OBJ;
    },

    GET_NOTIZEN_LIST: state => {
      const NOTIZEN_LIST_ALL = cloneDeep(state.NOTIZEN_LIST_ALL);
      const NOTIZEN_LIST = [];
      forEach(NOTIZEN_LIST_ALL, rubrik => {
        if (rubrik.list && rubrik.list.length) {
          forEach(rubrik.list, notiz => {
            NOTIZEN_LIST.push(notiz);
          });
        }
      });
      return NOTIZEN_LIST;
    },

    GET_NOTIZEN_COUNT: state => {
      return state.NOTIZEN_COUNT;
    },

    GET_NOTIZEN_OPTIONS: state => {
      return state.NOTIZEN_OPTIONS;
    },

    GET_NOTIZEN_READY: state => {
      return state.NOTIZEN_READY && state.NOTIZEN_OPTIONS_READY;
    },

    GET_NOTIZEN_WEITERE_OBJEKTE: state => {
      return state.NOTIZEN_WEITERE_OBJEKTE;
    },

    GET_NOTIZEN_DOCUMENTS: state => {
      return state.NOTIZEN_DOCUMENTS;
    },
  },
  mutations: {
    SET_NOTIZEN_LIST_ALL(state, { notizen }) {
      state.NOTIZEN_LIST_ALL = cloneDeep(notizen);
    },

    UPDATE_NOTIZEN(state, { rubrics = [], rubricsByKey = {} }) {
      const NOTIZEN_LIST_ALL = cloneDeep(state.NOTIZEN_LIST_ALL);
      const NOTIZEN = [];
      const NOTIZEN_OBJ = {
        null: [],
        dokument: [],
        verlauf: [],
      };
      const NOTIZEN_WEITERE_OBJEKTE = {};
      const NOTIZEN_DOCUMENTS = {};
      let notizenCount = 0;
      forEach(NOTIZEN_LIST_ALL, notiz => {
        const STEP_KONTEXT = find(split(notiz.no_key, "__"), kontext => get(rubricsByKey, kontext));
        if (notiz.weitere_objekte && notiz.weitere_objekte.length > 0) {
          const PKS = {};
          notiz.weitere_objekte.forEach(obj => {
            const PK = obj.noo_objektid;
            if (!PKS[PK]) {
              PKS[PK] = true;
              NOTIZEN_WEITERE_OBJEKTE[PK] = NOTIZEN_WEITERE_OBJEKTE[PK] || [];
              NOTIZEN_WEITERE_OBJEKTE[PK].push(notiz);

              NOTIZEN_DOCUMENTS[PK] = NOTIZEN_DOCUMENTS[PK] || {};
              NOTIZEN_DOCUMENTS[PK][notiz.no_objektid] = NOTIZEN_DOCUMENTS[PK][notiz.no_objektid] || [];
              NOTIZEN_DOCUMENTS[PK][notiz.no_objektid].push(notiz);
            }
          });
        }
        if (STEP_KONTEXT && rubricsByKey[STEP_KONTEXT]) {
          NOTIZEN_OBJ[STEP_KONTEXT] = NOTIZEN_OBJ[STEP_KONTEXT] || [];
          NOTIZEN_OBJ[STEP_KONTEXT].push(notiz);
        } else if (STEP_KONTEXT && STEP_KONTEXT.indexOf("dokument") !== -1) {
          NOTIZEN_OBJ.dokument.push(notiz);
        } else if (STEP_KONTEXT === "verlauf") {
          NOTIZEN_OBJ.verlauf.push(notiz);
        } else {
          NOTIZEN_OBJ.null.push(notiz);
        }
      });

      NOTIZEN.push({
        titel: "_TXT_NOTIZEN_ALLGEMEIN_",
        key: "null",
        list: NOTIZEN_OBJ.null,
      });
      notizenCount += NOTIZEN_OBJ.null.length;
      forEach(rubrics, rubric => {
        const RUBRIC_KEY = rubric.key;
        if (RUBRIC_KEY &&
          NOTIZEN_OBJ[RUBRIC_KEY] &&
          NOTIZEN_OBJ[RUBRIC_KEY].length) {
          NOTIZEN.push({
            titel: rubric.label || rubric.kbez, // TODO: rubric.kbez für Angular
            key: RUBRIC_KEY,
            list: NOTIZEN_OBJ[RUBRIC_KEY],
          });
          notizenCount += NOTIZEN_OBJ[RUBRIC_KEY].length;
        }
      });
      if (NOTIZEN_OBJ.dokument.length) {
        NOTIZEN.push({
          titel: "Zu einzelnen Dokumenten",
          label: "Dokumente",
          key: "dokument",
          list: NOTIZEN_OBJ.dokument,
        });
        notizenCount += NOTIZEN_OBJ.dokument.length;
      }
      if (NOTIZEN_OBJ.verlauf.length) {
        NOTIZEN.push({
          titel: "_TXT_NOTIZEN_VERLAUF_",
          label: "_TXT_NOTIZEN_VERLAUF_",
          key: "verlauf",
          list: NOTIZEN_OBJ.verlauf,
        });
        notizenCount += NOTIZEN_OBJ.verlauf.length;
      }
      state.NOTIZEN_COUNT = notizenCount;
      state.NOTIZEN = NOTIZEN;
      state.NOTIZEN_OBJ = NOTIZEN_OBJ;
      state.NOTIZEN_WEITERE_OBJEKTE = NOTIZEN_WEITERE_OBJEKTE;
      state.NOTIZEN_DOCUMENTS = NOTIZEN_DOCUMENTS;
    },

    SET_NOTIZEN_OPTIONS(state, { options }) {
      state.NOTIZEN_OPTIONS = cloneDeep(options);
      state.NOTIZEN_OPTIONS_READY = true;
    },

    DELETE_NOTIZ(state, { notiz }) {
      const NOTIZEN_LIST_ALL = cloneDeep(state.NOTIZEN_LIST_ALL);
      const INDEX = findIndex(NOTIZEN_LIST_ALL, ["pk", notiz.pk]);
      if (INDEX !== -1) {
        NOTIZEN_LIST_ALL.splice(INDEX, 1);
      }
      state.NOTIZEN_LIST_ALL = NOTIZEN_LIST_ALL;
    },

    CREATE_NOTIZ(state, { notiz }) {
      const NOTIZEN_LIST_ALL = cloneDeep(state.NOTIZEN_LIST_ALL);
      NOTIZEN_LIST_ALL.unshift(notiz);
      state.NOTIZEN_LIST_ALL = NOTIZEN_LIST_ALL;
    },

    UPDATE_NOTIZ(state, { notiz }) {
      const NOTIZEN_LIST_ALL = cloneDeep(state.NOTIZEN_LIST_ALL);
      const INDEX = findIndex(NOTIZEN_LIST_ALL, ["pk", notiz.pk]);
      if (INDEX !== -1) {
        NOTIZEN_LIST_ALL.splice(INDEX, 1, notiz);
      }
      state.NOTIZEN_LIST_ALL = NOTIZEN_LIST_ALL;
    },

    SET_NOTIZEN_READY(state) {
      state.NOTIZEN_READY = true;
    },

    DESTROY_NOTIZEN(state) {
      state.NOTIZEN_LIST_ALL = [];
      state.NOTIZEN_OBJ = {};
      state.NOTIZEN_WEITERE_OBJEKTE = {};
      state.NOTIZEN_DOCUMENTS = {};
      state.NOTIZEN = [];
      state.NOTIZEN_COUNT = 0;
      state.NOTIZEN_OPTIONS = {};
      state.NOTIZEN_READY = false;
      state.NOTIZEN_OPTIONS_READY = false;
    },
  },
};
