import {
  getCurrentInstance,
} from "vue";

import SitzungsantraegeListInTageordnungspunkt from "../../../../Sitzungsantraege/SitzungsantraegeListInTageordnungspunkt/SitzungsantraegeListInTageordnungspunkt.vue";

import SitzungModulePropsMixin from "../SitzungModulePropsMixin";

import SitzungModuleAPI from "../compositionAPI/SitzungModuleAPI";

// @vue/component
export default {
  name: "ModuleAutomatischeAntragslisteMain",
  components: {
    SitzungsantraegeListInTageordnungspunkt,
  },
  mixins: [
    SitzungModulePropsMixin,
  ],
  setup(props, context) {
    const {
      htmlId,
      model,
      modulname,
      modelParameter,
    } = SitzungModuleAPI(props, context);

    const APP = getCurrentInstance();
    const $stateParams = APP.appContext.config.globalProperties.$stateParams;
    const sitzungPk = $stateParams.id;

    return {
      htmlId,
      model,
      modulname,
      modelParameter,

      sitzungPk,
    };
  },
};
