"use strict";

import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import diasParameter from "dias/foerderregeln/parameter";
import controller from "./controller";
import template from "./template.jade";

import builder from "../utils/builder";


const auszahlungsplan = angular.module("dias.standard_foerdermodule.auszahlungsplan", [
  foerdermoduleRegistry.name,
  diasParameter.name,
]);

auszahlungsplan.config(FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("auszahlungsplan", "modul-auszahlungsplan");
})

  .directive("modulAuszahlungsplan", builder.directive(template, controller));

export default auszahlungsplan;
