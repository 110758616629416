import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import TaskLog from "../../../../../global/components/TaskLog/TaskLog.vue";
import Spaced from "../../../../../global/components/Spaced/Spaced.vue";

import loading from "../../../../../global/directives/loading";

import HttpMixin from "../../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../../global/mixins/NotificationMixin";
import FieldsMixin from "./FieldsMixin";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

import {
  forEach,
  isObject,
  isUndefined,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "ImportauftragAntragWizardStepAntragsteller",
  components: {
    FormElement,
    PuxButton,
    PuxTranslate,
    TaskLog,
    Spaced,
  },
  directives: {
    loading,
  },
  mixins: [
    FieldsMixin,
    NotificationMixin,
    HttpMixin,
  ],
  props: {
    auftrag: {
      type: Object,
      default: undefined,
    },
    regelsatz: {
      type: Object,
      default: undefined,
    },
    antragsdatum: {
      type: String,
      default: undefined,
    },
    antragsnummer: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    updateModel: {
      type: Function,
      default: undefined,
    },
    errors: {
      type: Object,
      default: () => {}
    },
  },
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    return {
      filterDate,
    };
  },
  data() {
    return {
      modelSearchMinInputLength: 3,
      modelSelectedOrganisation: undefined,
      maxOrganisationsLength: 10,
      modelSearchOrganisation: "",
      newOrganisation: undefined,
      statusLoadingSearchOrganisations: undefined,
      statusSearchedOrganisations: undefined,
      statusSearchFirst: undefined,
      organisations: [],
      organisationsCount: undefined,
    };
  },
  computed: {
    isDisabledSearchOrganisationButton() {
      return this.statusLoadingSearchOrganisations || this.isNoInput;
    },

    isNoSearchedOrganisations() {
      return (!isUndefined(this.organisationsCount) && this.organisationsCount === 0);
    },

    isNoInput() {
      return this.modelSearchOrganisation === null || this.modelSearchOrganisation.length < this.modelSearchMinInputLength;
    },

    isTooManyOrganisations() {
      return (this.organisationsCount && this.organisationsCount > this.maxOrganisationsLength);
    },

    isOkOrganisations() {
      return (this.organisations && this.organisations.length > 1 && this.organisations.length <= this.maxOrganisationsLength);
    },

    msgAntragstellerSucheInProgress() {
      return window.gettext(this.translationMsgAntragstellerSucheInProgress.text);
    },

    translationMsgAntragstellerSucheInProgress() {
      return { text: "_TXT_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_ANTRAGSTELLER_SEARCH_IN_PROGRESS_" };
    },
  },
  created() {
    this.initErrorLabels();
  },
  methods: {
    showErrors() {
      return size(this.errors);
    },

    initErrorLabels() {
      const ERROR_LABELS = {
        non_field_errors: "",
      };
      forEach(this.fields, field => {
        ERROR_LABELS[field.id] = field.label;
      });
      this.errorLabels = ERROR_LABELS;
    },

    getRadioOrgId(org) {
      return `radio_org_${ org.pk }`;
    },

    onSearchOrganisation() {
      if (this.isNoInput) {
        return;
      }
      this.modelSelectedOrganisation = undefined;
      this.updateModel({ antragsteller: undefined });
      this.statusSearchedOrganisations = true;
      this.statusLoadingSearchOrganisations = true;
      this.getHttp({ url: "antragstellerorganisationen/", urlParams: { suche: this.modelSearchOrganisation, limit: this.maxOrganisationsLength } }).then(
        response => {
          this.organisationsCount = response.count;
          if (this.organisationsCount <= 10) {
            this.organisations = response.results;
          } else {
            this.organisations = [];
          }
        }
      ).then(
        () => {
          this.statusSearchFirst = true;
          this.statusLoadingSearchOrganisations = false;
        }
      );
    },

    onSelectOrganisation({ organisation }) {
      const _model = { antragsteller: organisation };
      if (isObject(organisation)) {
        _model.antragsteller = organisation.pk;
      }
      this.updateModel({ model: _model });
    },
  },
};
