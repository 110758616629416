"use strict";

import template from "./template.jade";

class RolliplaetzeBetragsrechnerController {
  /*@ngInject*/
  constructor(i18n) {
    this.gettext = i18n.gettext;
    if (!this.kontext) {
      this.kontext = "antrag";
    }
    this.setPauschale();
  }

  setPauschale() {
    let pauschale = this.extras.argument.pauschale;
    if (!pauschale && this.extras.antragsregel.afr_argument.pauschale) {
      pauschale = this.extras.antragsregel.afr_argument.pauschale.wert;
    } else {
      pauschale = 20000;
    }
    if (this.posmodel) {
      this.posmodel.argument = this.posmodel.argument || {};
      this.posmodel.argument.pauschale = pauschale;
      this.extras = this.extras || {};
      this.extras.pauschale = pauschale;
    }
  }
}

export default {
  template: template(),
  controller: RolliplaetzeBetragsrechnerController,
  controllerAs: "ctx",
  bindings: {
    rechner: "=",
    posmodel: "=",
    errors: "=",
    extras: "=",
    kontext: "=?",
    vorschau: "=",
    prefix: "<?"
  },
};
