import {
  ref,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

import {
  EventBus,
} from "../../../../global/functions/event-bus";
import {
  forEach,
  get,
} from "lodash-es";

export default function AntragModuleScoreAPI({ antragModuleUrl, reloadAntrag }) {
  const eventsAntragScoreInitialized = ref(false);
  const {
    getListHttp,
  } = HttpAPI();
  const eventNamesModuleAntragScore = ref([]);

  const setEventNamesModuleAntragScore = modules => {
    const EVENT_NAMES_MODULE_ANTRAG_SCORE = [];
    forEach(modules, module => {
      const SIGNAL_EMPFANG = get(module, "regel.signal_empfang") || [];
      forEach(SIGNAL_EMPFANG, signal => {
        const EVENT_NAME = `${ signal.signal_kennung }_${ signal.sender_reid }`;
        EVENT_NAMES_MODULE_ANTRAG_SCORE.push(EVENT_NAME);
      });
    });
    eventNamesModuleAntragScore.value = EVENT_NAMES_MODULE_ANTRAG_SCORE;
  };
  const initEventBusesModuleAntragScore = () => {
    forEach(eventNamesModuleAntragScore.value, eventName => {
      EventBus.$on(eventName, reloadAntrag);
    });
  };
  const destroyEventBusesModuleAntragScore = () => {
    forEach(eventNamesModuleAntragScore.value, eventName => {
      EventBus.$off(eventName, reloadAntrag);
    });
  };

  const loadModuleAntragScore = () => {
    if (eventsAntragScoreInitialized.value) {
      return;
    }
    eventsAntragScoreInitialized.value = true;
    getListHttp({
      url: antragModuleUrl.value,
      urlParams: {
        objekttyp: "antrag",
        modulname: "antrag_score",
      },
    }).then(
      response => {
        setEventNamesModuleAntragScore(response);
        initEventBusesModuleAntragScore();
      }
    );
  };

  return {
    destroyEventBusesModuleAntragScore,
    loadModuleAntragScore,
  };
}
