"use strict";

import BetragsrechnerRegistry from "dias/kosten_finanzierung/betragsrechner/services";

import RegionaleEffekteBetragsrechner from "./betragsrechner.js";
import RegionaleEffekteBetragsrechnerController from "./controller";

const MdmRegionaleEffekteModul = angular.module("dias.mdm_kosten_finanzierung.betragsrechner.regionale_effekte", [
  BetragsrechnerRegistry.name,
]);

MdmRegionaleEffekteModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("mdm_regionale_effekte", RegionaleEffekteBetragsrechner);
})
  .component("mdmRegionaleEffekteBetragsrechner", RegionaleEffekteBetragsrechnerController);

export default MdmRegionaleEffekteModul;
