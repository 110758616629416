import AngebotDetailsBenachrichtigungenElement from "./AngebotDetailsBenachrichtigungenElement/AngebotDetailsBenachrichtigungenElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import ModalCreateOrUpdate from "./ModalCreateOrUpdate/ModalCreateOrUpdate.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";

import {
  keyBy,
} from "lodash-es";

const KATALOG_MAP_SELECT = [
  {
    url: "katalog/?key=kfoerderangebotbenachrichtigungsart",
    apiSaveId: "kfoerderangebotbenachrichtigungsart",
  },
  {
    url: "katalog/?key=benachrichtigungstypen",
    apiSaveId: "benachrichtigungstypen",
  },
];

// @vue/component
export default {
  name: "AngebotDetailsBenachrichtigungen",
  components: {
    AngebotDetailsBenachrichtigungenElement,
    FormstepDetail,
    Modal,
    ModalCreateOrUpdate,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {

    angebotPk: {
      type: String,
      required: true,
      info: "Angebot UUID",
    },
    regelsatzPk: {
      type: String,
      required: true,
      info: "Regelsatz UUID",
    },
    statusEditable: {
      type: Boolean,
      required: true,
      info: "EditMode oder ReadOnlyMode"
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modelLocal: {},
      benachrichtigungen: [],
      benachrichtigungenArt: {},
      benachrichtigungenTyp: {},
      htmlId: "angebotdetail_benachrichtigungen",
      id: "ui_angebotsdetails_benachrichtigungen_",
      statusModal: undefined,
      currentModel: undefined,
      statusLoading: true,
      statusKatalogLoading: true,
      selectorClose: undefined,
    };
  },
  computed: {
    headerTranslate() {
      return "_TXT_ANGEBOTDETAILS_BENACHRICHTIGUNGEN_HEADER_";
    },

    buildUrl() {
      return `foerderangebote/${ this.angebotPk }/regelsaetze/${ this.regelsatzPk }/benachrichtigungen/`;
    },

    benachrichtigungenLength() {
      return this.benachrichtigungen.length;
    },

    loading() {
      return this.statusLoading || this.statusKatalogLoading;
    },

    selectorCloseCreate() {
      return [
        `${ this.id }_list_item_${ this.benachrichtigungenLength }`,
        `ui_angebotdetails_benachrichtigungen_modalcreateorupdate_create_`
      ];
    },
  },

  created() {
    this.loadKatalogData();
    this.loadData();
  },
  methods: {
    loadData() {
      this.getListHttp({
        url: this.buildUrl,
      }).then(
        response => {
          this.benachrichtigungen = response;
          this.statusLoading = false;
        }
      );
    },

    loadKatalogData() {
      KATALOG_MAP_SELECT.forEach(item => {
        this.getListHttp({
          url: item.url,
          apiSaveid: item.apiSaveid,
        }).then(
          response => {
            if (item.apiSaveId === "kfoerderangebotbenachrichtigungsart") {
              this.benachrichtigungenArt = keyBy(response, "pk");
            } else {
              this.benachrichtigungenTyp = keyBy(response, "pk");
            }
          }
        );
      });
      this.statusKatalogLoading = false;
    },

    openModalCreate() {
      this.statusModal = true;
    },

    closeModalCreate() {
      this.statusModal = undefined;
      this.currentModel = undefined;
    },

    updateBenachrichtigungen({ item, index, statusDelete }) {
      if (statusDelete) {
        this.benachrichtigungen.splice(index, 1);
      } else {
        if (index >= 0 && item) {
          this.benachrichtigungen.splice(index, 1, item);
        } else if (!index && item) {
          this.benachrichtigungen.push(item);
        }
      }
    },
  },
};
