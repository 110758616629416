import {
  computed,
  ref,
} from "vue";

import {
  isFunction,
} from "lodash-es";

const TAB_ELEMENTS = `#main_menu a:visible, #main_menu button:not([disabled])`;
const KEY_CODE_TAB = 9;
const KEY_CODE_ESCAPE = 27;

export default function MobileMenuAPI({ closeMobileMenuCallback } = {}) {
  const statusMobileMenuOpen = ref(false);
  const refH1 = ref(null);

  const classParent = computed(() => {
    return statusMobileMenuOpen.value ?
      "parent__main_menu_mobile" :
      "d-none d-lg-block parent__main_menu";
  });

  const setFocusToH1 = EVENT => {
    if (refH1.value) {
      refH1.value.focus();
    }
    if (EVENT) {
      EVENT.preventDefault();
    }
  };

  const openMobileMenu = ({ openMobileMenuCallback } = {}) => {
    statusMobileMenuOpen.value = true;
    if (isFunction(openMobileMenuCallback)) {
      openMobileMenuCallback();
    }
    $("body").addClass("modal-open pr-3");
    setListenerMobileMenu();
    setTimeout(() => {
      setFocusToH1();
    });
  };

  const closeMobileMenu = () => {
    statusMobileMenuOpen.value = false;
    if (isFunction(closeMobileMenuCallback)) {
      closeMobileMenuCallback();
    }
    $("body").removeClass("modal-open pr-3");
    removeListenerMobileMenu();
  };

  function setListenerMobileMenu() {
    document.addEventListener("keydown", pressButton);
    window.addEventListener("resize", resize);
  }

  function removeListenerMobileMenu() {
    document.removeEventListener("keydown", pressButton);
    window.removeEventListener("resize", resize);
  }

  function pressButton($event) {
    const EVENT = $event || window.$event;
    if (EVENT.key === "Escape" || EVENT.keyCode === KEY_CODE_ESCAPE) { // Escape
      pressEscape();
    } else if (EVENT.key === "Tab" || EVENT.keyCode === KEY_CODE_TAB) { // Tab
      trapFocus(EVENT);
    }
  }

  function pressEscape() {
    closeMobileMenu();
  }

  function trapFocus(EVENT) {
    const FOCUSABLE_ELEMENTS = $(TAB_ELEMENTS);
    if (FOCUSABLE_ELEMENTS.length === 0) {
      EVENT.preventDefault();
      return;
    }
    const FIRST_FOCUSABLE_ELEMENT = FOCUSABLE_ELEMENTS[0];
    const LAST_FOCUSABLE_ELEMENT = FOCUSABLE_ELEMENTS[FOCUSABLE_ELEMENTS.length - 1];
    if (EVENT.shiftKey) { // Shift + Tab
      if (document.activeElement === FIRST_FOCUSABLE_ELEMENT) {
        setFocusToH1(EVENT);
        EVENT.preventDefault();
      } else if (document.activeElement === refH1.value) { // Focus in h1
        LAST_FOCUSABLE_ELEMENT.focus();
        EVENT.preventDefault();
      }
    } else { // Tab
      if (document.activeElement === LAST_FOCUSABLE_ELEMENT) {
        setFocusToH1(EVENT);
        EVENT.preventDefault();
      }
    }
  }

  function resize(event) {
    const WIDTH = event.target.innerWidth;
    if (WIDTH > 991) {
      closeMobileMenu();
    }
  }

  return {
    classParent,
    closeMobileMenu,
    openMobileMenu,
    refH1,
    statusMobileMenuOpen,
  };
}

