import {
  FilterDateMixin,
} from "../../mixins/FiltersMixin";

// @vue/component
export default {
  name: "PuxDate",
  mixins: [
    FilterDateMixin,
  ],
  props: {
    date: {
      type: String,
      required: false,
      default: undefined,
    },
    filterParameter: {
      type: String,
      required: false,
      default: undefined,
    },
    tag: {
      type: String,
      required: false,
      default: "span",
    },
  },
  computed: {
    dateFiltered() {
      return this.filterDate(this.date, this.filterParameter);
    },
  },
};
