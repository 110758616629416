import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

// @vue/component
export default {
  name: "AufgabeModalUpdateStatus",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    aufgabe: {
      type: Object,
      default: () => ({}),
    },
    parameter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      options: {
        showCloseButton: true,
      },
      status: {
        loading: undefined,
      },
    };
  },
  computed: {
    extraTranslateHeader() {
      return {
        status: this.$gettext(this.parameter.text),
      };
    },
    extraTranslateBody() {
      return {
        status: this.$gettext(this.parameter.text),
        kbez: this.aufgabe.kbez,
      };
    },
  },
  methods: {
    save() {
      this.status.loading = true;

      this.putHttp({ url: `aktuelle_aufgaben/${ this.aufgabe.pk }/status_aendern/`, data: { status: this.parameter.status } }).then(
        () => {
          this.addNotification({ text: "_MSG_AUFGABE_MODAL_UPDATE_STATUS_SUCCESS_" });
          this.close({ status: true });
        },
        () => {
          this.addNotification({ text: "_MSG_AUFGABE_MODAL_UPDATE_STATUS_ERROR_", type: "error" });
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
