"use strict";

import BaseModel from "dias/core/models/baseModel";

class News extends BaseModel {
  constructor() {
    super("news");
  }
}

export default News;
