import ModuleMixin from "../../ModuleMixin";
import PermissionMixin from "../../../../../global/mixins/PermissionMixin";
import UiDatepicker from "../../../../../global/components/ui/UiDatepicker/UiDatepicker.vue";
import UiDatepickerReadOnly from "../../../../../global/components/ui/UiDatepicker/UiDatepickerReadOnly/UiDatepickerReadOnly.vue";
import UiCkeditor from "../../../../../global/components/ui/UiCkeditor/UiCkeditor.vue";
import UiCkeditorReadOnly from "../../../../../global/components/ui/UiCkeditor/UiCkeditorReadOnly/UiCkeditorReadOnly.vue";
import SnapshotModule from "../../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import PruefkriteriumIcon from "../PruefkriteriumIcon/PruefkriteriumIcon.vue";
import PruefkriteriumDetailEditModal from "../PruefkriteriumDetailEditModal/PruefkriteriumDetailEditModal.vue";
import PruefkriteriumDetailRemoveModal from "../PruefkriteriumDetailRemoveModal/PruefkriteriumDetailRemoveModal.vue";
import PruefkriterienReadonlyElement from "../PruefkriterienReadonlyElement/PruefkriterienReadonlyElement.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../../../global/components/ui/ShowMore/ShowMore.vue";
import PruefkriterienEditDetails from "../PruefkriterienEditDetails/PruefkriterienEditDetails.vue";
import {
  cloneDeep,
  get,
  sortBy,
  isNil,
  assign,
  size,
  uniq,
  keys,
  forEach,
  isEmpty,
} from "lodash-es";

const SNAPSHOT_CHANGES_LIST = [
  "opr_kommentar",
  "opr_gueltig_bis",
  "opr_geprueft",
];

// @vue/component
export default {
  name: "Pruefkriterien",
  components: {
    SnapshotModule,
    SnapshotIcon,
    UiDatepicker,
    UiDatepickerReadOnly,
    UiCkeditor,
    UiCkeditorReadOnly,
    PruefkriteriumIcon,
    PruefkriteriumDetailRemoveModal,
    PruefkriteriumDetailEditModal,
    PruefkriterienReadonlyElement,
    PuxButton,
    PuxTranslate,
    ShowMore,
    PruefkriterienEditDetails,
  },
  mixins: [
    ModuleMixin,
    PermissionMixin,
  ],
  data() {
    return {
      status: {
        init: false,
        loading: false,
      },
      fieldOptions: {
        opr_gueltig_bis: {
          id: "opr_gueltig_bis",
          label: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_GUELTIG_BIS_",
          type: "date",
          required: false,
          notBefore: new Date().toISOString(),
          view: "v-alt",
        },
        opr_kommentar: {
          id: "opr_kommentar",
          label: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_KOMMENTAR_",
          type: "richtext",
          view: "v-alt",
          required: false,
        },
        opr_subkriterien: [],
      },
      showDetailpruefungMap: {},
      modal: {
        detail: undefined,
        remove: undefined,
      },
      errors: undefined,
      errorLabels: {},
      modulPruefkriterienWennAuflage: false,
      detailsTranslateMap: {
        true: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_VALUE_TRUE_",
        false: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_VALUE_FALSE_",
        undefined: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_VALUE_NULL_",
      },
      statusShowEditDetails: {},
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.open;
      return OPTIONS;
    },

    // Computed for Modul: pruefkriterien_wenn_auflage

    isModuleEditable() {
      if (this.modulPruefkriterienWennAuflage) {
        return this.hasModulPermissionUpdate && this.hasAuflage;
      }
      return this.hasModulPermissionUpdate;
    },

    hasAuflage() {
      return !!this.obj.v_entscheidung_anmerkung;
    },

    showNoAuflageInfo() {
      return !this.hasAuflage && this.modelParameter.keine_auflage_text;
    },

    warningNoAuflage() {
      return !this.obj.v_entscheidung_anmerkung && !isNil(this.modelParameter.keine_auflage_text);
    },

    labelHeader() {
      return this.modelParameter.frage_text;
    },

    iconClsRefreshBtn() {
      return "glyphicon-refresh" + (this.status.loading ? " rotate" : "");
    },

    datumEditierbar() {
      return this.checkPermissionsSync({ perm: "objektpruefkriterium.datum.update", permArray: this.obj.user_permissions || [] });
    },

    detailpruefungAktiv() {
      return this.modelParameter.activate_detailpruefung;
    },

    auswahlNichtRelevantAktiv() {
      return this.modelParameter.auswahl_nicht_zutreffend_ermoeglichen;
    },

    statusDetailpruefungAktiv() {
      return !!this.modelParameter.activate_detailpruefung;
    },

    statusDetailpruefungAlwaysShow() {
      return !!this.modelParameter.always_show_detailpruefung;
    },

    kommentarAktiv() {
      return !!this.modelParameter.kommentar_anzeigen;
    },

    statusDiff() {
      if (!this.snapshotModule) {
        return false;
      }
      return !!this.diff.status;
    },

    diff() {
      const SNAPSHOT = {
        changes: {},
        status: false,
        diffCriteria: {},
      };
      if (this.statusSnapshot) {
        const SNAPSHOT_OBJ = cloneDeep(this.snapshotModule) || {};
        const MODEL_OBJ = cloneDeep(this.model) || {};
        const KEYS = uniq([
          ...keys(this.model),
          ...keys(this.snapshotModule),
        ]);
        forEach(KEYS, key => {
          SNAPSHOT.changes[key] = {};
          const SNAPSHOT_OBJ_CRITERIA = SNAPSHOT_OBJ[key];
          const MODEL_OBJ_CRITERIA = MODEL_OBJ[key];
          forEach(SNAPSHOT_CHANGES_LIST, field => {
            if (!isEmpty(SNAPSHOT_OBJ_CRITERIA) && !isEmpty(MODEL_OBJ_CRITERIA)) {
              if (SNAPSHOT_OBJ_CRITERIA[field] !== MODEL_OBJ_CRITERIA[field]) {
                SNAPSHOT.changes[key][field] = true;
                SNAPSHOT.status = true;
              }
            } else {
              SNAPSHOT.diffCriteria[key] = true;
              SNAPSHOT.status = true;
            }
          });
          if (this.detailpruefungAktiv) {
            const MODEL_OBJ_SUBCRITERIA = MODEL_OBJ_CRITERIA.opr_subkriterien || {};
            const SNAPSHOT_OBJ_SUBCRITERIA = SNAPSHOT_OBJ_CRITERIA.opr_subkriterien || {};
            const DETAIL_KEYS = uniq([
              ...keys(MODEL_OBJ_SUBCRITERIA),
              ...keys(SNAPSHOT_OBJ_SUBCRITERIA),
            ]);
            SNAPSHOT.changes[key].opr_subkriterien = {};
            forEach(DETAIL_KEYS, prfId => {
              if (MODEL_OBJ_SUBCRITERIA[prfId] !== SNAPSHOT_OBJ_SUBCRITERIA[prfId]) {
                SNAPSHOT.changes[key].opr_subkriterien[prfId] = true;
                SNAPSHOT.status = true;
              }
            });
          }
        });
      }
      return SNAPSHOT;
    },

    pruefkriterienSortiert() {
      if (this.modelParameter.pruefkriterien) {
        return sortBy(this.modelParameter.pruefkriterien, item => {
          return get(this.model, `${ item }.pruefkriterium.prf_prio`);
        });
      }
      return undefined;
    },

    translationPruefkriteriumValuePositive() {
      return { text: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_VALUE_TRUE_" };
    },

    translationPruefkriteriumValueNegative() {
      return { text: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_VALUE_FALSE_" };
    },

    translationPruefkriteriumValueNull() {
      return { text: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_VALUE_NULL_" };
    },

    translationGueltigBisLabel() {
      return { text: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_GUELTIG_BIS_" };
    },

    translationKommentarLabel() {
      return { text: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_KOMMENTAR_" };
    },

    translationDetailpruefungValuePositive() {
      return { title: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_VALUE_TRUE_" };
    },

    translationDetailpruefungValueNegative() {
      return { title: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_VALUE_FALSE_" };
    },

    translationDetailpruefungValueNull() {
      return { title: "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_VALUE_NULL_" };
    },

    translationAuswahlNichtRelevantLabel() {
      return "_LBL_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_AUSWAHL_NICHT_RELEVANT_";
    },

    statusShowDetailpruefungToggleBtnMap() {
      const MAP = {};
      forEach(this.modelParameter.pruefkriterien, prfId => {
        const DETAILS = get(this.modelEdit, `${ prfId }.pruefkriterium.details`, []);
        if (!DETAILS.length || !this.statusDetailpruefungAktiv) {
          MAP[prfId] = false;
        } else if (this.statusDetailpruefungAlwaysShow) {
          MAP[prfId] = true;
        } else {
          const SUBKRITERIEN = get(this.modelEdit, `${ prfId }.opr_subkriterien`, {});
          MAP[prfId] = !isEmpty(SUBKRITERIEN);
        }
      });
      return MAP;
    },

    titlesAndTextsToggleBtn() {
      const TEXTS = {};
      forEach(this.modelParameter.pruefkriterien, prfId => {
        TEXTS[prfId] = {
          text: this.statusShowEditDetails[prfId] ?
            "_BTN_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_TOGGLE_CLOSE_LABEL_" :
            "_BTN_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_TOGGLE_OPEN_LABEL_",
          title: this.statusShowEditDetails[prfId] ?
            "_BTN_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_TOGGLE_CLOSE_TITLE_" :
            "_BTN_STANDARD_FOERDERMODULE_PRUEFKRITERIUM_DETAILPRUEFUNG_TOGGLE_OPEN_TITLE_",
        };
      });
      return TEXTS;
    },
  },
  created() {
    this.init();
  },
  methods: {
    prepareModel() {
      this.model = cloneDeep(this.module.data) || {};
    },

    init() {
      if (this.model) {
        this.modelEdit = cloneDeep(this.model);
      }
      if (this.module.modulname === "pruefkriterien_wenn_auflage") {
        this.modulPruefkriterienWennAuflage = true;
      }
    },

    showPruefkriterium(prfId, model) {
      return !!model[prfId];
    },

    getPruefkriteriumLabel(prfId, model) {
      if (!model[prfId]) {
        return;
      }
      return model[prfId].pruefkriterium.prf_kbez;
    },

    getPruefkriteriumValue(prfId, model) {
      if (!model[prfId]) {
        return;
      }
      return model[prfId].opr_geprueft;
    },

    isPruefkriteriumValueDeaktiviert(prfId) {
      return this.status.loading || this.hasDetailpruefung(prfId) || this.modelEdit[prfId].opr_pruefung_nicht_zutreffend;
    },

    onPruefkriteriumValueTrue(prfId) {
      if (!this.modelEdit || !this.modelEdit[prfId] || !this.datumEditierbar || !isNil(this.modelEdit[prfId].opr_gueltig_bis)) {
        return;
      }
      const PRF_MODEL = cloneDeep(this.modelEdit);
      PRF_MODEL[prfId].opr_gueltig_bis = this.modelEdit[prfId].pruefkriterium.gueltig_bis;
      this.modelEdit = PRF_MODEL;
    },

    showPruefkriteriumGueltigBis(prfId, model) {
      if (!model[prfId]) {
        return;
      }
      return !isNil(model[prfId].opr_gueltig_bis) && model[prfId] !== null;
    },

    showPruefkriteriumGueltigBisEdit(prfId) {
      if (!this.modelEdit[prfId]) {
        return;
      }
      return this.datumEditierbar && this.modelEdit[prfId].opr_geprueft === true;
    },

    getPruefkriteriumGueltigBisId(prfId) {
      return `pruefung-${ prfId }opr_gueltig_bis`;
    },

    onUpdateGueltigBis(prfId) {
      return ({ model } = {}) => {
        const PRF_MODEL = cloneDeep(this.modelEdit);
        PRF_MODEL[prfId].opr_gueltig_bis = model;
        this.modelEdit = PRF_MODEL;
      };
    },

    getPruefkriteriumInputName(prfId) {
      return `pruefung-${ prfId }`;
    },

    getPruefkriteriumInputId(prfId, value) {
      return `pruefung-${ prfId }-${ value }`;
    },

    getPruefkriteriumKommentarId(prfId) {
      return `pruefung-${ prfId }opr_kommentar`;
    },

    onUpdateKommentar(prfId) {
      return $event => {
        const PRF_MODEL = cloneDeep(this.modelEdit);
        PRF_MODEL[prfId].opr_kommentar = $event.model;
        this.modelEdit = PRF_MODEL;
      };
    },

    getDetailpruefungId(prfId, prfdId) {
      return `${ this.getPruefkriteriumInputName(prfId) }detail-${ prfdId }`;
    },

    hasDetailpruefung(prfId) {
      if (!this.modelEdit[prfId]) {
        return;
      }
      return !isNil(this.modelEdit[prfId].opr_subkriterien) && this.detailpruefungAktiv;
    },

    hasDetailpruefungConfigured(prfId) {
      if (!this.modelEdit[prfId] || !this.modelEdit[prfId].pruefkriterium) {
        return;
      }
      return size(this.modelEdit[prfId].pruefkriterium.details) > 0 && this.detailpruefungAktiv;
    },

    openDetailpruefungModal(prfId, model, typ) {
      this.modalOpr = cloneDeep(model[prfId]);
      this.modal[typ] = true;
    },

    closeDetailpruefungModal(response) {
      if (!isNil(response)) {
        const MODEL = cloneDeep(this.modelEdit[this.modalOpr.pruefkriterium.pk]);
        assign(MODEL, response);
        this.modelEdit[this.modalOpr.pruefkriterium.pk] = MODEL;
        this.onPruefkriteriumValueTrue(this.modalOpr.pruefkriterium.pk);
        this.modalOpr = undefined;
      } else {
        this.modalOpr = undefined;
      }
      this.modal.detail = false;
      this.modal.remove = false;
    },

    toggleDetailpruefung(prfId) {
      this.statusShowEditDetails[prfId] = !this.statusShowEditDetails[prfId];
    },

    getStatusCheckedForShow(prfId) {
      return this.modelEdit[prfId].opr_pruefung_nicht_zutreffend;
    },


    getAriaCheckedForShowCheckbox(prfId) {
      return `${ this.getStatusCheckedForShow(prfId) }`;
    },

    pruefungNichtRelevant(prfId) {
      const PRF_MODEL = cloneDeep(this.modelEdit);
      PRF_MODEL[prfId].opr_geprueft = null;
      PRF_MODEL[prfId].opr_subkriterien = null;
      this.modelEdit = PRF_MODEL;
    },

    getStatusCheckbox(prfId) {
      const PRF_MODEL = cloneDeep(this.modelEdit);
      PRF_MODEL[prfId].opr_pruefung_nicht_zutreffend = !PRF_MODEL[prfId].opr_pruefung_nicht_zutreffend;
      this.modelEdit = PRF_MODEL;
      if (this.modelEdit[prfId].opr_pruefung_nicht_zutreffend) {
        this.pruefungNichtRelevant(prfId);
      }
    },
  },
};
