"use strict";

import diasLoading from "dias/core/loading";

import diasChartComponent from "./chart.component.js";

const diasChart = angular.module("dias.chart", [
  diasLoading.name
])

  .component("diasChart", diasChartComponent);

export default diasChart;
