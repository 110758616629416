"use strict";

export default /*@ngInject*/ $timeout => {
  return {
    restrict: "A",
    link: function(scope, element, attrs) {
      scope.$watch(attrs.autoFocusForDiasSelect, function(value) {
        if (value === true) {
          $timeout(function() {
            element[0].firstChild.firstChild.focus();
            scope[attrs.autoFocusForDiasSelect] = false;
          });
        }
      });
    }
  };
};
