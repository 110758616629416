import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import AuszahlungUebersichtAnsprechpartner from "./AuszahlungUebersichtAnsprechpartner/AuszahlungUebersichtAnsprechpartner.vue";
import AuszahlungUebersichtAstAnsprechpartner from "./AuszahlungUebersichtAstAnsprechpartner/AuszahlungUebersichtAstAnsprechpartner.vue";
import AuszahlungUebersichtAuszahlungsplan from "./AuszahlungUebersichtAuszahlungsplan/AuszahlungUebersichtAuszahlungsplan.vue";
import AuszahlungUebersichtFoerderorgAnsprechpartner from "./AuszahlungUebersichtFoerderorgAnsprechpartner/AuszahlungUebersichtFoerderorgAnsprechpartner.vue";
import DokumenteSimpleList from "../../../Dokumente/DokumenteSimpleList/DokumenteSimpleList.vue";
import FoerderorgAnsprechpartnerList from "../../../Foerderorganisation/FoerderorgAnsprechpartnerList/FoerderorgAnsprechpartnerList.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import NachrichtenLink from "../../../Nachrichten/NachrichtenLink/NachrichtenLink.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxDate from "../../../../global/components/PuxDate/PuxDate.vue";
import PuxGet from "../../../../global/components/PuxGet/PuxGet.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../../global/components/ui/ShowMore/ShowMore.vue";
import Spaced from "../../../../global/components/Spaced/Spaced.vue";

import AuszahlungBetragBoxMixin from "../AuszahlungBetragBox/AuszahlungBetragBoxMixin";
import AuszahlungBetragBoxBetragAndDifferenzMixin from "../AuszahlungBetragBox/AuszahlungBetragBoxBetragAndDifferenz/AuszahlungBetragBoxBetragAndDifferenzMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungUebersicht",
  components: {
    AngularLink,
    AuszahlungUebersichtAnsprechpartner,
    AuszahlungUebersichtAstAnsprechpartner,
    AuszahlungUebersichtAuszahlungsplan,
    AuszahlungUebersichtFoerderorgAnsprechpartner,
    DokumenteSimpleList,
    FoerderorgAnsprechpartnerList,
    FormstepDetail,
    NachrichtenLink,
    Permission,
    PuxCloak,
    PuxDate,
    PuxGet,
    PuxIcon,
    PuxTranslate,
    ShowMore,
    Spaced,
  },
  mixins: [
    AuszahlungBetragBoxMixin,
    AuszahlungBetragBoxBetragAndDifferenzMixin,
    PermissionMixin,
  ],
  props: {
    antrag: {
      type: Object,
      required: true,
    },
    auszahlung: {
      type: Object,
      required: false,
      default: undefined,
    },
    auszahlungsplan: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    urlAuszahlungen: {
      type: String,
      required: true,
    },
    vertrag: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    return {
      filterDate,
    };
  },
  data() {
    return {
      dokAnzeigeAuszahlungGeneriert: "908fe2dd-73b8-42b9-ad55-56b2af2fa0b6",
      optionsForFormstepDetails: {
        hideHeader: true,
      },
    };
  },
  computed: {
    extraTranslateForPeriode() {
      return {
        pe_kbez: get(this.auszahlung, "periode.pe_kbez"),
      };
    },

    betragGeaendertGrund() {
      return get(this.auszahlung, "uebersicht.betrag_geaendert_grund");
    },

    isModalitaetLast() {
      return !!get(this.auszahlungsplan, "zp_modalitaet.letzte");
    },

    extraTranslateForBetragProzent() {
      return {
        betrag_prozent: get(this.auszahlung, "uebersicht.betrag_prozent"),
      };
    },

    currencyZero() {
      return this.filterCurrency(0);
    },

    urlAuszahlung() {
      return `${ this.urlAuszahlungen }${ this.auszahlung.pk }/`;
    },

    urlAuszahlungWithoutVertrag() {
      return `auszahlungen/${ this.auszahlung.pk }/`;
    },

    astorgAnsprechpartner() {
      return get(this.vertrag, "astorg_ansprechpartner", []);
    },

    foerderorgAnsprechpartner() {
      return get(this.vertrag, "foerderorg_ansprechpartner", []);
    },

    ansprechpartner() {
      return get(this.auszahlung, "ansprechpartner", []);
    },

    extraAntragEingereichtDatumForTranslate() {
      return {
        date: this.filterDate(this.vertrag.antrag_datum),
      };
    },

    extraAntragBewilligungsDatumForTranslate() {
      return {
        date: this.filterDate(this.vertrag.antrag_bewilligungsdatum),
      };
    },

    extraAntragDurchfuehrungszeitraumForTranslate() {
      return {
        antrag_durchfuehrungszeitraum: this.vertrag.antrag_durchfuehrungszeitraum,
      };
    },

    extraVertragDurchfuehrungszeitraumForTranslate() {
      return {
        vertrag_durchfuehrungszeitraum: this.vertrag.durchfuehrungszeitraum,
      };
    },

    hasTeams() {
      return this.auszahlung.teams.length > 0;
    },

    auszahlungTeamBezeichnung() {
      if (this.hasTeams) {
        return this.auszahlung.teams.map(el => el.t_kbez).join(", ");
      }
      return "";
    },

    ansprechpartnerUrl() {
      return `${ this.urlAuszahlungen }${ this.auszahlung.pk }/ansprechpartner/`;
    },
  },
};
