import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../../global/components/ui/ShowMore/ShowMore.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import VertragSnapshotMixin from "../VertragSnapshotMixin";
import {
  CheckPermissionsSyncMixin,
} from "../../../../global/mixins/PermissionMixin";

import {
  cloneDeep,
  get,
  isEmpty,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragAuflagen",
  components: {
    FormElement,
    FormstepDetail,
    PuxTranslate,
    ShowMore,
  },
  mixins: [
    CheckPermissionsSyncMixin,
    HttpMixin,
    VertragSnapshotMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    vertrag: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "updateVertrag",
  ],
  data() {
    return {
      model: {},
      errors: undefined,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        label: "_TXT_VERTRAG_DETAILS_HINWEISE_ZUM_FOERDERVERTRAG_",
        required: true,
        openCallback: this.open,
        saveCallback: this.save,
      };
    },

    hasWFPermissionVertragAuflagenPruefen() {
      return this.checkPermissionsSync({
        perm: "vertrag.auflagen.pruefen",
        permArray: this.vertrag.user_permissions,
      });
    },

    textTranslateAnmerkungGeprueft() {
      return this.vertrag.v_entscheidung_anmerk_geprueft ?
        "_TXT_GEPRUEFT_" :
        "_TXT_NICHT_GEPRUEFT_";
    },

    textTranslateAnmerkungGeprueftSnapshot() {
      return this.snapshotVertrag.v_entscheidung_anmerk_geprueft ?
        "_TXT_GEPRUEFT_" :
        "_TXT_NICHT_GEPRUEFT_";
    },

    errorsLabelsOptions() {
      return [
        this.optionsAnmerkungGeprueft,
      ];
    },

    optionsAnmerkungGeprueft() {
      const ID = "v_entscheidung_anmerk_geprueft";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "one_checkbox",
        label: "_TXT_VERTRAG_DETAILS_AUFLAGE_ZUR_BEWILLIGUNG_GEPRUEFT_",
        view: "v-alt",
        required: false,
      };
    },

    urlSave() {
      return `vertraege/${ this.vertrag.pk }/auflagenpruefung/`;
    },

    snapshotVertrag() {
      return get(this.snapshotObject, "vertrag") || {};
    },

    isDiff() {
      return !isEmpty(this.diff);
    },

    diff() {
      const DIFF = {};
      if (this.isSnapshot) {
        if (this.vertrag.v_entscheidung_anmerk_geprueft !== this.snapshotVertrag.v_entscheidung_anmerk_geprueft) {
          DIFF.v_entscheidung_anmerk_geprueft = true;
        }
      }
      return DIFF;
    },
  },
  methods: {
    open() {
      this.model = {
        v_entscheidung_anmerk_geprueft: this.vertrag.v_entscheidung_anmerk_geprueft,
      };
    },

    save() {
      return new Promise((resolve, reject) => {
        this.deleteErrors();
        this.putHttp({
          url: this.urlSave,
          data: {
            v_entscheidung_anmerk_geprueft: this.model.v_entscheidung_anmerk_geprueft,
          },
        }).then(
          () => {
            this.updateVertragLocal();
            this.addNotification({ text: "_MSG_UPDATE_SUCCESS_" });
            resolve();
          },
          error => {
            this.errors = error.data;
            reject(error);
          },
        );
      });
    },

    updateVertragLocal() {
      const VERTRAG = cloneDeep(this.vertrag);
      VERTRAG.v_entscheidung_anmerk_geprueft = this.model.v_entscheidung_anmerk_geprueft;

      this.$emit("updateVertrag", {
        vertrag: VERTRAG,
      });
    },

    deleteErrors() {
      this.errors = undefined;
    },
  },
};
