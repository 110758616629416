import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import Step from "./Step/Step.vue";

import DashboardModelFilterMixin from "../../DashboardModelFilterMixin";
import DashboardModuleMixin from "../../DashboardModuleMixin";
import DashboardWFInfoMixin from "../../DashboardWFInfoMixin";

import {
  changeTextToId,
} from "../../../../../global/functions/utils";
import {
  forEach,
  cloneDeep,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "DashboardWorkflowInfoWorkflow",
  components: {
    PuxButton,
    PuxIcon,
    Step,
  },
  mixins: [
    DashboardModelFilterMixin,
    DashboardModuleMixin,
    DashboardWFInfoMixin
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      statusShow: undefined,
      wfStatusgruppen: {},
      steps: [],
      lastStep: undefined,
      model_filter: [],
    };
  },
  computed: {
    parameter() {
      return this.argument.parameter || {};
    },

    classForRobot() {
      const NAME = this.parameter.css_klasse || "antrag";
      return `test_dashboard_${ changeTextToId(NAME) }_info`;
    }
  },
  watch: {
    parameter() {
      this.initParams();
    },

    data() {
      this.init();
    },
  },
  mounted() {
    this.renderEl();
  },
  created() {
    this.init();
    this.initParams();
  },
  unmounted() {
    if (this.$el && isFunction(this.$el.remove)) {
      this.$el.remove();
    }
  },
  methods: {
    renderEl() {
      setTimeout(() => {
        const DASHBOARD_PRIVATE = document.getElementById("dashboard_private");
        let count = 0;
        forEach(DASHBOARD_PRIVATE.children, child => {
          if (count >= 8) {
            DASHBOARD_PRIVATE.insertBefore(this.$el, child);
            return false;
          }
          count += +child.dataset.count;
        });
        this.statusShow = true;
      }, 100);
    },

    init() {
      this.wfStatusgruppen = {};
      this.filterWfStatusgruppen();
    },

    initParams() {
      if (!this.parameter) {
        return;
      }
      const WSG_IDS = cloneDeep(this.parameter.wsg_ids);
      this.steps = WSG_IDS.slice(0, WSG_IDS.length - 1);
      this.lastStep = WSG_IDS[WSG_IDS.length - 1];
      this.model_filter = this.parameter.model_filter || [];
    },

    filterWfStatusgruppen() {
      if (!this.data) {
        return;
      }
      const WF_STATUSGRUPPEN = {};
      forEach(this.data.statusgruppen, value => {
        WF_STATUSGRUPPEN[value.workflowstatusgruppe.pk] = value;
      });
      this.wfStatusgruppen = WF_STATUSGRUPPEN;
    },

    closeBlockDetail() {
      this.SET_DASHBOARD_OPEN_INFO({ pk: this.pk });
    },
  },
};
