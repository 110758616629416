"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import diasAuthService from "dias/auth/services";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";

import models from "./models";
import detailComponent from "./details.component";


const sitzungsantragModule = angular.module("root.sitzungen.antraege", [
  uiRouter,
  models.name,
  diasAuthService.name,
  diasTable.name,
  diasLoading.name,
  diasNotification.name
]);

sitzungsantragModule.config($stateProvider => {
  $stateProvider

    // Sitzungsantrag-Liste
    .state("root.sitzungsantraege", {
      url: `/sitzungsantraege/?
            user_cookie&
            antragstellerorg&
            sitzung&
            regelsatz`,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-sitzung-antraege-list></vue-sitzung-antraege-list></div>"
        }
      },
      data: {
        permissions: ["sitzungen.antrag.view"]
      },
    })
    .state("root.sitzungsantraege1", {
      url: `/sitzungsantraege1/?
            user_cookie&
            antragstellerorg&
            regelsatz`,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-sitzung-antraege-list></vue-sitzung-antraege-list></div>"
        }
      },
      data: {
        permissions: ["sitzungen.antrag.view"]
      },
    })
    .state("root.sitzungsantraege.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><sitzung-antraege-details></sitzung-antraege-details></div>",
        },
      },
      data: {
        permissions: ["sitzungen.antrag.view"]
      },
    })
    .state("root.sitzungsantraege1.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<sitzungsantraege-details></sitzungsantraege-details>"
        }
      },
      data: {
        permissions: ["sitzungen.antrag.view"]
      },
    })
  ;
})

  .component("sitzungsantraegeDetails", detailComponent)
;

export default sitzungsantragModule;
