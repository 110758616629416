"use strict";

import parameterServices from "../services";
import template from "./config.jade";
import controller from "./controller";


const parametermoduleDatetime = angular.module("dias.foerderregeln.parameter.datetime", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("datetime", "parametermodul-datetime");
  })
  .component("parametermodulDatetime", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulDatetimeRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: "{{ vm.parameter[vm.key].wert | date:'short' }}",
    controllerAs: "vm",
  });
export default parametermoduleDatetime;
