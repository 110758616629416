"use strict";

export default {
  PLANNED: 1,
  RUNNING: 2,
  SUCCESS: 3,
  FAILURE: 4,
  RESCHEDULED: 5,
  ABORTED: 6
};
