import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "Team",
  components: {
    FormstepDetail,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    team: {
      type: Object,
      required: true,
    },
    updateTeam: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      options: {
        label: "_TXT_TEAMS_DETAILS_TEAM_PANEL_FORMSTEP_NAME_",
        list: [
          {
            id: "t_bez",
            type: "text",
            label: "_LBL_TEAMS_DETAILS_BEZEICHNUNG_",
            showLabel: true,
            view: "v-alt",
            required: true,
            translate: true,
          },
          {
            id: "t_kbez",
            type: "text",
            label: "_LBL_TEAMS_DETAILS_KURZ_BEZEICHNUNG_",
            showLabel: true,
            view: "v-alt",
            required: true,
            translate: true,
          },
        ],
        required: true,
        saveCallback: this.save,
      },
    };
  },
  methods: {
    save({ model }) {
      const DATA = cloneDeep(model);
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: `teams/${ this.team.pk }/`,
          data: DATA,
        }).then(
          response => {
            this.updateTeam({ team: response });
            this.addNotification({ text: "_MSG_TEAMS_DETAILS_UPDATE_SUCCESS_" });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },
  },
};
