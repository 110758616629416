import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import TaskLog from "../../../../global/components/TaskLog/TaskLog.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import {
  gettext,
  replaceText
} from "../../../../global/functions/utils";
import {
  get,
  join,
  map,
  size,
} from "lodash-es";
import {
  scrollToRelative
} from "../../../../global/functions/help";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    FormElement,
    Modal,
    PuxButton,
    PuxTranslate,
    TaskLog,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    SyConfigMixin,
  ],
  props: {
    angebotPk: {
      type: String,
      required: true,
      info: "Angebot UUID",
    },
    regelsatzPk: {
      type: String,
      required: true,
      info: "Regelsatz UUID",
    },
    close: {
      type: Function,
      default: () => {},
      required: false,
    },
    selectorClose: {
      type: [String, Array],
      required: true,
    },
  },
  data() {
    return {
      msg: undefined,
      errors: undefined,
      taskId: undefined,
      taskResult: undefined,
      status: {
        loading: false,
        started: false,
        success: false,
        error: false,
      },
      msgOptions: {
        type: "richtext",
        id: "msg",
        label: "_LBL_REGELSATZ_VORVERSION_ANTRAEGE_WFO_MODAL_MSG_",
        required: false,
        view: "v-alt",
      },
    };
  },
  computed: {
    header() {
      return "_LBL_REGELSATZ_VORVERSION_ANTRAEGE_WFO_MODAL_HEADER_";
    },

    infoMsg() {
      return "_HTML_REGELSATZ_VORVERSION_ANTRAEGE_WFO_MODAL_INFO_";
    },

    successMsg() {
      return "_MSG_REGELSATZ_VORVERSION_ANTRAEGE_WFO_MODAL_TASK_SUCCESS_{{anz}}_{{anz_success}}_{{anz_error}}_";
    },

    successExtra() {
      return {
        anz: get(this.taskResult, "anz_antraege", 0),
        anz_success: get(this.taskResult, "success_antraege", 0),
        anz_error: get(this.taskResult, "error_antraege", 0),
      };
    },

    errorMsg() {
      return "_MSG_REGELSATZ_VORVERSION_ANTRAEGE_WFO_MODAL_TASK_ERROR_";
    },

    modalOptions() {
      return {
        showCloseButton: true,
        required: false,
      };
    },

    baseUrl() {
      return `/foerderangebote/${ this.angebotPk }/regelsaetze/${ this.regelsatzPk }/`;
    },

    diffUrl() {
      return `${ this.baseUrl }diff/`;
    },

    migrateUrl() {
      return `${ this.baseUrl }vorversion_antraege_wfo/`;
    },

    msgTemplate() {
      return gettext(get(this.getSyConfigsValue("regelsatz_vorversion_antraege_wfo"), "meldung_template", ""));
    },

    msgDisabled() {
      return this.status.loading || this.status.success;
    },

    startButtonDisabled() {
      return this.status.loading || this.status.success;
    },
  },

  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.status.loading = true;
      this.getHttp({
        url: this.diffUrl
      }).then(
        diff => this.initMsg(diff),
        err => this.errors = err
      ).finally(() => this.status.loading = false);
    },

    initMsg(diff) {
      const ADDED = get(diff, "added");
      const REMOVED = get(diff, "removed");
      const REGEL_DIFF = `
        ${ this.getRegelList(ADDED, "_HTML_REGELSATZ_VORVERSION_ANTRAEGE_WFO_ADDED_LABEL_") }
        ${ this.getRegelList(REMOVED, "_HTML_REGELSATZ_VORVERSION_ANTRAEGE_WFO_REMOVED_LABEL_") }
      `;
      this.msg = replaceText({
        text: this.msgTemplate,
        object: {
          regel_aenderungen: REGEL_DIFF
        }
      });
    },

    getRegelList(regeln, label) {
      if (size(regeln) > 0) {
        const LABEL = gettext(label);
        return `${ LABEL }
          <ul>
          ${ join(map(regeln, far => `<li>${ far.far_kbez }</li>`), "") }
          </ul>
        `;
      }
      return "";
    },

    start() {
      this.status.loading = true;
      this.status.error = false;
      this.status.success = false;
      this.status.started = false;
      this.taskId = undefined;
      this.taskResult = undefined;
      const DATA = {
        msg: this.msg
      };
      this.postHttp({
        url: this.migrateUrl,
        data: DATA
      }).then(
        response => {
          this.taskId = response.task_id;
          this.status.started = true;
          scrollToRelative("#task_container", 0, 500, "#modal_antraege_migrieren_container .modal-body.modal_body", -500);
        },
        error => {
          this.errors = error.data;
          this.status.loading = false;
        }
      );
    },

    onSuccess({ result }) {
      this.taskId = null;
      this.taskResult = result;
      this.status.success = true;
      this.status.loading = false;
      scrollToRelative("#task_success_container", 150, 500, "#modal_antraege_migrieren_container .modal-body.modal_body", -500);
    },

    onFailure() {
      this.taskId = null;
      this.status.error = true;
      this.status.loading = false;
      scrollToRelative("#task_error_container", 150, 500, "#modal_antraege_migrieren_container .modal-body.modal_body", -500);
    },
  },
};
