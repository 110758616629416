import moment from "moment";
import translate from "../../../../../global/directives/translate";
import {
  FilterCurrencyMixin,
} from "../../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "PositionInTd",
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
  ],
  props: {
    data: {
      type: Object,
      required: true,
    },
    period: {
      type: Object,
      required: true,
    },
    position: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      styles: {
        left: 0,
        right: 0
      },
    };
  },
  computed: {
    translateOptions() {
      return {
        title: "_TXT_VERTRAG_DETAILS_PERSONALBAUM_POSITION_TITLE_{{ALL_API}}_",
        extra: {
          period: this.data,
          position: this.position,
        },
      };
    },

    statusGehaltNull() {
      return this.filterCurrency(this.data.gehalt) === "0,00 €";
    },
  },
  created() {
    this.initStyles();
  },
  methods: {
    initStyles() {
      const BEGIN_PERIOD = moment(this.period.pe_von);
      const END_PERIOD = moment(this.period.pe_bis);
      const BEGIN_ELEMENT = moment(this.data.von);
      const END_ELEMENT = moment(this.data.bis);
      const COUNT_DAYS = END_PERIOD.diff(BEGIN_PERIOD, "days");
      const BEGIN_COUNT_DAYS = BEGIN_ELEMENT.diff(BEGIN_PERIOD, "days");
      const END_COUNT_DAYS = END_PERIOD.diff(END_ELEMENT, "days");
      const LEFT = BEGIN_COUNT_DAYS * 100 / COUNT_DAYS;
      const RIGHT = END_COUNT_DAYS * 100 / COUNT_DAYS;
      this.styles = {
        left: `${ LEFT }%`,
        right: `${ RIGHT }%`,
      };
    },
  },
};
