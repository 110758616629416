"use strict";

import BaseController from "dias/foerderregeln/parameter/config.base.controller";

class ParametermoduleController extends BaseController {
  /*@ngInject*/
  constructor(Sitzungen, i18n) {
    super();
    this.gettext = i18n.gettext;
    this.Sitzungen = Sitzungen;
    this.parameter[this.key].wert = this.parameter[this.key].wert || [];
    this.loading = false;
    this.selection = {};
    this.initSelection();
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.Sitzungen.one(this.parameter[this.key].sitzung).tops.getList().then(
      response => this.tops = response
    ).finally(() => this.loading = false);
  }

  initSelection() {
    this.parameter[this.key].wert.forEach(pk => this.selection[pk] = true);
  }

  updateValue() {
    this.parameter[this.key].wert = Object.keys(this.selection).filter(pk => this.selection[pk]);
  }

  setAll(status) {
    this.tops.forEach(top => {
      this.selection[top.pk] = status;
      top.unterpunkte.forEach(utop => this.selection[utop.pk] = status);
    });
    this.updateValue();
  }
}

export default ParametermoduleController;
