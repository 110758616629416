import PuxTooltip from "../../PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";

import translate from "../../../directives/translate";

import PuxTabMixin from "../PuxTabMixin";

// @vue/component
export default {
  name: "PuxTab",
  components: {
    PuxTooltip,
    PuxTranslate,
  },
  directives: {
    translate,
  },
  mixins: [
    PuxTabMixin,
  ],
  emits: [
    "onChangeTab",
  ],
  computed: {
    tooltipId() {
      return `${ this.idLocal }_tooltip`;
    },

    ariaSelected() {
      return `${ this.isActive }`;
    },

    ariaDisabled() {
      return this.tab.disabled ? "true" : "false";
    },
  },
  methods: {
    onChangeTabLocal($event) {
      if (this.tab.disabled) {
        return;
      }
      this.$emit("onChangeTab", $event, this.tab);
      setTimeout(() => {
        this.$refs.tooltip.updateTooltip();
      });
    },
  },
};
