"use strict";

class KostenplanController {
  /*@ngInject*/
  constructor(i18n) {
    this.gettext = i18n.gettext;
    this.name = "KostenplanController";
  }
}

export default KostenplanController;
