import ModulAuszahlungslisteObjektlisteElementList
  from "../../../Auszahlungsliste/ModulAuszahlungslisteObjektlisteElementList/ModulAuszahlungslisteObjektlisteElementList.vue";
import ModuleMixin from "../ModuleMixin";

// @vue/component
export default {
  name: "ObjektlisteAuszahlungsliste",
  components: {
    ModulAuszahlungslisteObjektlisteElementList,
  },
  mixins: [
    ModuleMixin,
  ],
  computed: {
    labelHeader() {
      if (this.modelParameter.titel) {
        return this.modelParameter.titel;
      }
      return "_TXT_GR_OBJEKTLISTE_AUSZAHLUNGSLISTE_";
    },

    isModuleEditable() {
      return false;
    },
  },
};
