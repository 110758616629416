import translate from "../../../../../global/directives/translate";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";

// @vue/component
export default {
  name: "PersonThFirst",
  components: {
    PuxIcon,
  },
  directives: {
    translate,
  },
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return `${ this.person.vpsp_vorname } ${ this.person.vpsp_nachname }`;
    },
  },
};
