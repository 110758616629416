"use strict";

import BetragsrechnerRegistry from "../services";

import BetragsrechnerStandard from "./betragsrechner.js";
import BetragsrechnerStandardController from "./controller";

const BetragsrechnerStandardModul = angular.module("dias.betragsrechner.personalkostenPktModul", [
  BetragsrechnerRegistry.name,
]);

BetragsrechnerStandardModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("standard_kostenrechner", BetragsrechnerStandard);
  BetragsrechnerRegistryProvider.register("standard_finanzierungsrechner", BetragsrechnerStandard);
})
  .component("standardkosten", BetragsrechnerStandardController);

export default BetragsrechnerStandardModul;
