import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";

import {
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "GutachtenErgebnisAuswahl",
  components: {
    PuxCloak,
  },
  props: {
    gutachten: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    minimal: {
      type: Boolean,
      required: false,
    },
    enforceStyling: {
      type: Boolean,
      required: false,
    },
  },
  emits: [
    "selectAuswahl",
  ],
  setup() {
    const {
      getHttp,
    } = HttpAPI();

    return {
      getHttp,
    };
  },
  data() {
    return {
      loading: true,
      ergebnisseKeyPyBk: {},
      gruppe: {},
      auswahl: [],
      auswahlKeyByPk: {},
    };
  },
  computed: {
    selectedAuswahl() {
      return this.auswahlKeyByPk[this.gutachten.ergebnis];
    },

    cssForSelectedAuswahl() {
      return this.getElementCssKlasse(this.selectedAuswahl);
    },
  },
  created() {
    this.loadCatalogData();
  },
  methods: {
    loadCatalogData() {
      let ergebnisgruppe;
      if (this.gutachten) {
        ergebnisgruppe = this.gutachten.ergebnisgruppe;
      }

      Promise.all([
        this.getHttp({
          url: "katalog/",
          urlParams: {
            key: "kgutachtenergebnisse",
          },
          apiSaveId: "kgutachtenergebnisse",
        }),
        this.getHttp({
          url: "katalog/",
          urlParams: {
            key: "kgutachtenergebnisgruppen",
            pk: ergebnisgruppe,
          },
          apiSaveId: ergebnisgruppe ?
            `kgutachtenergebnisgruppen_${ ergebnisgruppe }` :
            "kgutachtenergebnisgruppen",
        }),
        this.getHttp({
          url: "katalog/",
          urlParams: {
            key: "kgutachtenergebnisauswahl",
            gruppe: ergebnisgruppe,
          },
          apiSaveId: ergebnisgruppe ?
            `kgutachtenergebnisauswahl_${ ergebnisgruppe }` :
            "kgutachtenergebnisauswahl",
        }),
      ]).then(
        responses => {
          this.ergebnisseKeyPyBk = keyBy(responses[0], "pk");
          this.gruppe = responses[1][0] || {};
          this.auswahl = responses[2];
          this.auswahlKeyByPk = keyBy(responses[2], "pk");
        }
      ).finally(
        () => this.loading = false
      );
    },

    getElementCssKlasse(gutachtenAuswahl) {
      let styleKlasse = "";
      const aktivKlasse = this.gutachten &&
      this.gutachten.ergebnis === gutachtenAuswahl.pk ?
        "active" :
        "";
      if (this.ergebnisseKeyPyBk[gutachtenAuswahl.ergebnis]) {
        styleKlasse = this.ergebnisseKeyPyBk[gutachtenAuswahl.ergebnis].gae_cssklasse;
      }
      return `${ styleKlasse } ${ aktivKlasse }`;
    },

    selectAuswahl(gutachtenAuswahl) {
      this.$emit("selectAuswahl", gutachtenAuswahl);
    },
  },
};
