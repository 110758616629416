"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";


const parametermodule = angular.module("dias.foerderregeln.parameter.auszahlungFilter", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("auszahlung_filter", "parametermodul-json");
  })
;
export default parametermodule;
