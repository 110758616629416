import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import translate from "../../../../global/directives/translate";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "ZuschussAnpassungModalCreateOrUpdate",
  components: {
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    vertrag: {
      type: Object,
      required: true,
      info: "Vertrag",
    },
    anpassung: {
      type: Object,
      required: false,
      default: undefined,
      info: "Zuschuss-Anpassung",
    },
    selectorClose: {
      type: [String, Array],
      required: true,
      info: "CSS-Selector für Fokus, wenn man Modal-Fenster schließt",
    },
    close: {
      type: Function,
      default: () => {},
      info: "Schließen-Funktion",
    },
  },
  data() {
    return {
      model: {},
      statusLoading: undefined,
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    statusNew() {
      return !this.anpassung;
    },

    headerTranslateOptions() {
      return {
        text: this.headerTranslateText,
        extra: this.headerTranslateExtra,
      };
    },

    headerTranslateText() {
      return this.statusNew ?
        "_TXT_ZUSCHUSS_ANPASSUNG_MODAL_CREATE_HEADER_" :
        "_TXT_ZUSCHUSS_ANPASSUNG_MODAL_UPDATE_HEADER_{{kbez}}_";
    },

    headerTranslateExtra() {
      if (!this.statusNew) {
        return {
          kbez: this.anpassung.vza_kbez,
        };
      }
      return undefined;
    },

    buttonSaveId() {
      return `zuschuss_anpassung_${ this.statusNew ? "create" : "update" }`;
    },

    options() {
      return {
        showCloseButton: true,
        required: true,
        list: this.modalList,
      };
    },

    modalList() {
      return [
        {
          id: "vza_kbez",
          htmlId: `${ this.buttonSaveId }_vza_kbez`,
          type: "text",
          label: "_LBL_ZUSCHUSS_ANPASSUNG_MODAL_BEZEICHNUNG_",
          required: true,
          view: "v",
        },
        {
          id: "vza_betrag",
          htmlId: `${ this.buttonSaveId }_vza_betrag`,
          type: "float",
          label: "_LBL_ZUSCHUSS_ANPASSUNG_MODAL_BETRAG_",
          required: true,
          view: "v",
          maxlength: 14,
        },
        {
          id: "vza_kommentar",
          htmlId: `${ this.buttonSaveId }_vza_kommentar`,
          type: "richtext",
          label: "_LBL_ZUSCHUSS_ANPASSUNG_MODAL_KOMMENTAR_",
          required: true,
          view: "v",
        },
      ];
    },

    buttonSaveText() {
      return this.statusNew ?
        "_BTN_ERSTELLEN_" :
        "_BTN_SAVE_";
    },

    urlSave() {
      return this.statusNew ?
        this.apiZuschussAnpassungen :
        `${ this.apiZuschussAnpassungen }${ this.anpassung.pk }/`;
    },

    apiZuschussAnpassungen() {
      return `vertraege/${ this.vertrag.pk }/zuschussanpassungen/`;
    },

    httpMethod() {
      return this.statusNew ?
        "postHttp" :
        "putHttp";
    },

    notification() {
      return this.statusNew ?
        "_LBL_ZUSCHUSS_ANPASSUNG_MODAL_CREATE_SUCCESS_" :
        "_LBL_ZUSCHUSS_ANPASSUNG_MODAL_UPDATE_SUCCESS_";
    },
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (!this.statusNew) {
        this.model = cloneDeep(this.anpassung);
      }
    },

    save() {
      this.statusLoading = true;
      const DATA = cloneDeep(this.model);
      DATA.vertrag = this.vertrag.pk;
      this[this.httpMethod]({
        url: this.urlSave,
        data: DATA,
      }).then(
        response => {
          this.addNotification({ text: this.notification });
          this.close({ response });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.statusLoading = false
      );
    },
  },
};
