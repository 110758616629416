import getTableApi from "../../const/TableApis";
import {
  setUrlWithParams,
} from "../../global/functions/help";

// @vue/component
export default {
  methods: {
    initUrl({ tableId, filter }) {
      if (filter) {
        return setUrlWithParams({
          url: getTableApi({ id: tableId }),
          params: filter,
        });
      }
      return getTableApi({ id: tableId });
    },
  },
};
