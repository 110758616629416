"use strict";

import template from "./history.jade";
import DokumenteBaseController from "./base.controller";
import {
  EventBus,
} from "../../../vue/client/vue/global/functions/event-bus";

const limitDok = 3;

class HistoryController extends DokumenteBaseController {
  /*@ngInject*/
  constructor($scope, $window, Antragstellerorganisationen, AuthService, Dokumente, Foerderantrag, Client) {
    super($window, Dokumente, Foerderantrag, Antragstellerorganisationen);
    this.lastDoks = [];
    this.$scope = $scope;
    this.Client = Client;
    this.docAnzeige = $scope.vm.docAnzeige;
    if ($scope.vm.docpk.antragstellerorg && $scope.vm.doctype !== "antrag") {
      this.moduleObj = Antragstellerorganisationen.one($scope.vm.docpk.antragstellerorg.pk);
    } else {
      this.moduleObj = $scope.vm.docpk;
    }
    this.loading = true;
    AuthService.hasPerm("dokument.view").then(
      has_perm => {
        this.statusHasPermission = has_perm;
        if (has_perm) {
          this.loadData();
          this.setEventUpdateDokumente();
        }
      }
    );
    this.loadData = this.loadData.bind(this);
  }

  loadData() {
    this.loading = true;
    this.moduleObj.dokumente.getLastDokList(this.docAnzeige, limitDok).then(
      result => {
        this.docpk.lastDoks = result;
        this.loading = false;
      }
    );
  }

  setEventUpdateDokumente() {
    EventBus.$on("changeDocument", this.loadData);
    this.$scope.$on("$destroy", () => {
      EventBus.$on("changeDocument", this.loadData);
    });
  }
}

export default {
  template: template(),
  controller: HistoryController,
  controllerAs: "vm",
  bindings: {
    docpk: "<",
    docAnzeige: "<",
    doctype: "<"
  }
};
