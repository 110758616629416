import {
  FilterCurrencyMixin,
} from "../../../../global/mixins/FiltersMixin";

import {
  gettext,
} from "../../../../global/functions/utils";

// @vue/component
export default {
  mixins: [
    FilterCurrencyMixin
  ],
  data() {
    return {
      vorschauDefaultText: gettext("_TXT_NOCH_NICHT_ERMITTELBAR_"),
    };
  },
  computed: {
    optionsBetrag() {
      return {
        id: "betrag",
        htmlId: `${ this.htmlId }_betrag`,
        label: "_LBL_BETRAGSRECHNER_BETRAG_",
        view: this.view,
        type: "text",
        required: false,
        disabled: true,
        helpText: this.vorschau.bezeichnung || "-",
        classesHorizontal: this.classesHorizontal,
      };
    },

    vorschauBetrag() {
      if (this.vorschau.betrag) {
        return this.filterCurrency(this.vorschau.betrag);
      }
      return this.vorschauDefaultText;
    },
  },
};
