import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import RegistrationHeader from "../RegistrationHeader/RegistrationHeader.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "EmailChangePage",
  components: {
    AngularLink,
    PuxButton,
    PuxPageDetails,
    PuxTranslate,
    RegistrationHeader,
  },
  mixins: [
    HttpMixin,
  ],
  data() {
    return {
      regClass: "col-12 col-sm-10 col-lg-7 mr-0",
      statusLoading: true,
      statusSuccess: undefined,
      statusSubmitting: undefined,
      email: undefined,
    };
  },
  computed: {
    urlEmailChange() {
      return `auth/email_aendern/${ this.$stateParams.uuid }/${ this.$stateParams.tok }/`;
    },
  },
  created() {
    this.checkUrl();
  },
  methods: {
    checkUrl() {
      if (!this.$stateParams.uuid || !this.$stateParams.tok) {
        this.successStatus = false;
        this.loading = false;
        return;
      }
      this.getHttp({
        url: this.urlEmailChange,
      }).then(
        response => {
          this.statusSuccess = response && response.validlink;
          this.email = response.n_email_aenderung;
        },
        error => {
          this.statusSuccess = error.validlink;
        }
      ).then(
        () => this.statusLoading = false
      );
    },

    aktivierenEmail() {
      if (this.statusSubmitting) {
        return;
      }
      this.statusSubmitting = true;
      this.postHttp({
        url: this.urlEmailChange,
        data: this.model,
      }).then(
        () => {
          this.addNotification({ text: "_MSG_EMAIL_CHANGE_SUCCESS_" });
          this.logout();
        },
        error => {
          if (error.data && error.data.validlink === false) {
            this.statusSuccess = false;
          }
        }
      ).then(
        () => this.statusSubmitting = false
      );
    },

    logout() {
      this.postHttp({
        url: "auth/logout/",
      }).then(
        () => {
          this.$AuthService.refreshUser().then(
            () => this.$goTo("root.home", {}, { reload: "root.home" })
          );
        }
      );
    },
  },
};
