"use strict";

class ExceptionHandler {
  /*@ngInject*/
  $get($injector, Raven) {
    return function(exception, cause) {
      Raven.captureException(exception, { extra: { cause: cause } });
      const params = {
        sentryId: Raven.lastEventId()
      };
      const $state = $injector.get("$state");
      console.error(exception, cause);
      $state.go("root.errors.js", params, { location: false });
    };
  }
}

export default ExceptionHandler;
