import KofiUebersicht from "../../../../../../../../apps/dias-mdm/libs/vue/KofiUebersicht/KofiUebersicht.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "AntragKofiUebersichtButtonModal",
  components: {
    KofiUebersicht,
    Modal,
    PuxButton,
    PuxTranslate,
  },
  props: {
    antrag: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  computed: {
    optionsModal() {
      return {
        showCloseButton: true,
        cancelLabel: "_BTN_CLOSE_"
      };
    },
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },

    closeModal() {
      this.isModalOpen = false;
    },
  },
};
