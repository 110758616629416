"use strict";

class FoerderregelEditInstrumentzuordnungController {
  /*@ngInject*/
  constructor(
    $q, 
    $scope, 
    $filter, 
    DiasNotification,
    Foerderangebotinstrumente, 
    Foerderangebotinstrumentvarianten
  ) {
    this.loadingEditData = true;
    this.formErrors = null;
    this.$q = $q;
    this.DiasNotification = DiasNotification;
    this.errorContainerId = "errors_edit_" + Math.random().toString(36).slice(2);

    this.copyModel = () => {
      this.instrumentSelection = {};
      this.variantenSelection = {};
      angular.forEach($scope.model.instrumente, instr => {
        this.instrumentSelection[instr.foi_id] = true;
      });
      angular.forEach($scope.model.varianten, variante => {
        this.variantenSelection[variante.fiv_id] = true;
      });
    };

    this.copyModel();

    $scope.$watch("editMode", () => {
      if (!$scope.editMode) {
        this.copyModel();
      }
    });

    const instrumentPromise = Foerderangebotinstrumente.getList().then(
      response => this.instrumentChoices = response
    );
    const variantenPromise = Foerderangebotinstrumentvarianten.getList().then(
      response => this.variantenChoices = response
    );
    this.splitAt = 0;
    $q.all([instrumentPromise, variantenPromise]).then(
      () => {
        const total = this.variantenChoices.length + this.instrumentChoices.length;
        let cur = 0;
        for (let i = 0 ; i < this.instrumentChoices.length; i++) {
          cur += 1 + $filter("filter")(this.variantenChoices, { instrument: this.instrumentChoices[i].foi_id }).length;
          if (cur > total / 2) {
            this.splitAt = i + 1;
            break;
          }
        }
      }
    ).finally(() => this.loadingEditData = false);

    this._saveZuordnung = () => {
      const instrData = [];
      Object.keys(this.instrumentSelection).map(key => {
        if (this.instrumentSelection[key] === true) {
          instrData.push(key);
        }
      });
      const varData = [];
      Object.keys(this.variantenSelection).map(key => {
        if (this.variantenSelection[key] === true) {
          varData.push(key);
        }
      });
      const defer = this.$q.defer();

      this.$q.all([
        $scope.model.customPUT({ instrumente: instrData }, "foerderinstrumente"),
        $scope.model.customPUT({ varianten: varData }, "foerderinstrumentvarianten")
      ]).then(
        () => {
          $scope.model.instrumente.splice(0, $scope.model.instrumente.length);
          for (let i = 0; i < this.instrumentChoices.length; i++) {
            if (this.instrumentSelection[this.instrumentChoices[i].pk] === true) {
              $scope.model.instrumente.push(this.instrumentChoices[i]);
            }
          }
          $scope.model.varianten.splice(0, $scope.model.varianten.length);
          for (let i = 0; i < this.variantenChoices.length; i++) {
            if (this.variantenSelection[this.variantenChoices[i].pk] === true) {
              $scope.model.varianten.push(this.variantenChoices[i]);
            }
          }
          defer.resolve();
        },
        defer.reject
      );

      return defer.promise;
    };

    this.save = this._save.bind(this);
  }

  _save() {
    return this._saveZuordnung().then(
      () => this.DiasNotification.page.success("Änderungen erfolgreich gespeichert"),
      error => {
        this.DiasNotification.form.clear();
        this.DiasNotification.form.error(error.data, "Ihre Daten sind fehlerhaft oder unvollständig.", { container: "#" + this.errorContainerId });
        this.errors = error.data;
        return this.$q.reject();
      }
    );
  }
}

export default FoerderregelEditInstrumentzuordnungController;
