import {
  isEmpty,
} from "lodash-es";

// @vue/component
export default {
  props: {
    snapshotDocument: {
      type: Object,
      required: false,
      default: undefined,
    },
    snapshotChanges: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    statusDiff: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    statusSnapshotChanges() {
      return !isEmpty(this.snapshotChanges);
    },
  },
};
