import PositionTd from "../PositionTd/PositionTd.vue";
import PersonModalCreateOrEdit from "../PersonModalCreateOrEdit/PersonModalCreateOrEdit.vue";
import Permission from "../../../../../global/components/Permission/Permission.vue";
import HttpMixin from "../../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../../global/mixins/NotificationMixin";
import translate from "../../../../../global/directives/translate";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import {
  FilterCurrencyMixin,
} from "../../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "PositionTr",
  components: {
    PositionTd,
    PersonModalCreateOrEdit,
    Permission,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    position: {
      type: Object,
      required: true,
    },
    positionTeile: {
      type: Object,
      default: () => ({}),
    },
    periodenAll: {
      type: Array,
      required: true,
    },
    positionIndex: {
      type: Number,
      required: true,
    },
    periodenColsWidth: {
      type: Object,
      required: true,
    },
    positionFirst: {
      type: Boolean,
      required: true,
    },
    positionLast: {
      type: Boolean,
      required: true,
    },
    vertrag: {
      type: Object,
      required: true,
    },
    positionenAllGehalt: {
      type: Object,
      required: true,
    },
    durchfuehrung: {
      type: Object,
      required: true,
    },
    addPerson: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modalCreatePerson: undefined,
    };
  },
  methods: {
    openModalCreatePerson() {
      this.modalCreatePerson = true;
    },

    closeModalCreatePerson({ response } = {}) {
      if (response) {
        this.addPerson({ person: response });
      }
      this.modalCreatePerson = false;
    },
  },
};
