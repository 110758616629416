"use strict";

import template from "./config.jade";
import {
  initModelFromList,
  toFormElementFromParameter
} from "../../../../vue/client/vue/global/functions/mappingForParameterToFormElement";

class FoerderangebotRegelConfigController {
  /*@ngInject*/
  constructor(ParameterUtils, i18n) {
    this.name = "FoerderangebotRegelConfigController";
    this.gettext = i18n.gettext;
    this.doDelete = regel => this.onDelete()(regel);
    this.moveUp = regel => this.onMoveUp()(regel);
    this.moveDown = regel => this.onMoveDown()(regel);

    if (angular.isUndefined(this.regel.param_keys)) {
      this.regel.param_keys = ParameterUtils.get_ordered_keys(this.regel.pbr_argument);
    }
    this.listConfig = toFormElementFromParameter({
      obj: this.regel.pbr_argument,
      showLabel: true,
      staticOptionsForType: {
        dyn_tabelle_objektkatalog_data: {
          dependency: "spalten",
        },
        dyn_tabelle_spaltenauswahl_radio: {
          dependency: "spalten",
        },
      },
    }).list;
    this.modelConfig = initModelFromList({ list: this.listConfig });
  }
}

export default {
  template: template(),
  controller: FoerderangebotRegelConfigController,
  controllerAs: "vm",
  bindings: {
    regel: "=",
    editable: "=",
    onDelete: "&",
    onMoveUp: "&",
    onMoveDown: "&",
  }
};
