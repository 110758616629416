"use strict";

import richtext from "dias/core/richtext";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import parameter from "./parameter";

const dynamische_mehrfachauswahl = angular.module("dias.standard_foerdermodule.dynamische_mehrfachauswahl", [
  richtext.name,
  foerdermoduleRegistry.name,
  parameter.name,
]);

export default dynamische_mehrfachauswahl;
