import PuxButton from "../../../PuxButton/PuxButton.vue";

import UiTreeGroupAPI from "../compositionAPI/UiTreeGroupAPI";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "UiTreeGroup",
  components: {
    PuxButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    modelItem: {
      type: Object,
      required: true,
    },
    modelItemIndex: {
      type: Number,
      required: true,
    },
    modelLength: {
      type: Number,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    fields: {
      type: Array,
      required: true,
    },
    indexHighlight: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  emits: [
    "changeModel",
    "deleteModel",
    "moveDown",
    "moveUp",
  ],
  setup(props) {
    const {
      groupNumber,
      labelGroupLocal,
    } = UiTreeGroupAPI(props);

    return {
      groupNumber,
      labelGroupLocal,
    };
  },
  computed: {
    idLocal() {
      return `${ this.id }_${ this.modelItemIndex }`;
    },

    idForButtonMoveUp() {
      return this.getIdForButtonMove({
        index: this.modelItemIndex,
        suffix: "btn_move_up",
      });
    },

    idForButtonMoveDown() {
      return this.getIdForButtonMove({
        index: this.modelItemIndex,
        suffix: "btn_move_down",
      });
    },

    idForButtonDelete() {
      return `${ this.idLocal }_btn_delete`;
    },

    isGroupFirst() {
      return this.modelItemIndex === 0 || this.disabled;
    },

    isGroupLast() {
      return this.modelItemIndex + 1 === this.modelLength || this.disabled;
    },

    isGroupHighlighted() {
      return this.indexHighlight === this.modelItemIndex;
    },
  },
  beforeCreate() {
    this.$options.components.FormElement = require("../../../FormElement/FormElement.vue").default;
  },
  methods: {
    moveUp() {
      if (this.isGroupFirst) {
        return;
      }
      this.$emit("moveUp", {
        modelItemIndex: this.modelItemIndex,
      });
      this.setFocusToButtonMove({
        targetIndex: this.modelItemIndex - 1,
        buttonSuffix: "btn_move_up"
      });
    },

    moveDown() {
      if (this.isGroupLast) {
        return;
      }
      this.$emit("moveDown", {
        modelItemIndex: this.modelItemIndex,
      });
      this.setFocusToButtonMove({
        targetIndex: this.modelItemIndex + 1,
        buttonSuffix: "btn_move_down"
      });
    },

    setFocusToButtonMove({ targetIndex, buttonSuffix }) {
      const FOCUS_ID = this.getIdForButtonMove({
        index: targetIndex,
        suffix: buttonSuffix,
      });
      const BUTTON_MOVE = document.getElementById(FOCUS_ID);
      if (BUTTON_MOVE) {
        BUTTON_MOVE.focus();
      }
    },

    onDelete() {
      this.$emit("deleteModel", { modelItemIndex: this.modelItemIndex });
    },

    getIdForButtonMove({ index, suffix }) {
      return `${ this.id }_${ index }_${ suffix }`;
    },

    changeModelInChild({ id, model }) {
      const MODEL = cloneDeep(this.modelItem);
      MODEL.value[id] = model;
      this.$emit("changeModel", { modelItemIndex: this.modelItemIndex, modelItem: MODEL });
    },
  },
};
