"use strict";

import BetragsrechnerBase from "../betragsrechner.base";

class BetragsrechnerOhneBezeichnung extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "kosten_ohne_bezeichnung";
    this.component_name = "kostenohnebezeichnung";
    this.betrag_editable = true;
    this.fields = {
      antrag: { wert: null },
      beleg: { wert: null }
    };
  }

  get_labelmapping() {
    return { wert: "Betrag in EUR" };
  }
}

export default BetragsrechnerOhneBezeichnung;
