import FormElementReadOnly from "../../FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import Permission from "../../Permission/Permission.vue";

import {
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "FormstepDetailRead",
  components: {
    FormElementReadOnly,
    Permission,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Object,
      default: () => ({}),
    },
    collection: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    listFiltered() {
      return filter(this.list, item => !item.editonly);
    },
  },
};
