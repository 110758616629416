"use strict";

import BaseModel from "dias/core/models/baseModel";
import Sitzungsmodule from "dias/sitzungen/sitzungsmodule/models/sitzungsmodule.service";

class KTagesordnungspunkt extends BaseModel {
  constructor() {
    super("tops", {
      subPaths: {
        module: new Sitzungsmodule(),
      }
    });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.deleteTop = pk => {
      return collection.one(pk).remove();
    };
    collection.updateTop = (pk, data) => {
      return collection.one(pk).customPUT(data);
    };

    return collection;
  }
}

export default KTagesordnungspunkt;
