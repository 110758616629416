import Alert from "../../../../global/components/Alert/Alert.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import OptionsBankverbindung from "./OptionsBankverbindung";
import {
  size,
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragstellerWizardBankverbindung",
  components: {
    Alert,
    FormElement,
    FormstepDetail,
    PuxTranslate,
  },
  mixins: [
    SyConfigMixin,
  ],
  props: {
    statusNewOrganisation: {
      type: Boolean,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    changeModel: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      optionsBankverbindung: OptionsBankverbindung,
      model: {},
    };
  },
  computed: {
    isErrors() {
      return !!size(this.errors);
    },
    bankData() {
      const sy_conf = this.getSyConfigsValue("bankverbindung_weitere_pflichtfelder");
      const BV = cloneDeep(OptionsBankverbindung);
      BV[2].required = sy_conf.bic;
      BV[3].required = sy_conf.bank;
      return BV;
    }
  },
  methods: {
    onChangeModel() {
      this.changeModel({ model: this.model });
    },
  },
};
