"use strict";

import angular from "angular";
import module from "./module.js";
import kostenplanController from "./kosten_finanzierung.kostenplan.controller";
import kostenplanTemplate from "./kosten_finanzierung.kostenplan.jade";
import finanzierungsplanController from "./kosten_finanzierung.finanzierungsplan.controller";
import finanzierungsplanTemplate from "./kosten_finanzierung.finanzierungsplan.jade";
import zuschuesseController from "./kosten_finanzierung.zuschuesse.controller";
import zuschuesseTemplate from "./kosten_finanzierung.zuschuesse.jade";

const kostenFinanzierungModule = angular.module("dias.kosten_finanzierung", [
  module.name,
]);

kostenFinanzierungModule.directive("kostenplan", () => {
  return {
    restrict: "A",
    scope: {
      idx: "@",
      module: "=",
      kostenplan: "=",
      savecallback: "&",
      infoprovider: "=",
      readonly: "=",
      snapshot: "=",
      snapshotdiff: "=",
      obj: "=?",
      nummerierungausblenden: "=",
      updateModuleFromVue: "&",
      updateinfoprovider: "&",
    },
    template: kostenplanTemplate,
    controller: kostenplanController,
    controllerAs: "vm",
    bindToController: true
  };
});

kostenFinanzierungModule.directive("finanzierungsplan", () => {
  return {
    restrict: "A",
    scope: {
      idx: "@",
      module: "=",
      finanzierungsplan: "=",
      savecallback: "&",
      infoprovider: "=",
      readonly: "=",
      snapshot: "=",
      snapshotdiff: "=",
      obj: "=?",
      nummerierungausblenden: "=",
      updateModuleFromVue: "&",
      updateinfoprovider: "&",
    },
    template: finanzierungsplanTemplate,
    controller: finanzierungsplanController,
    controllerAs: "vm",
    bindToController: true
  };
});

kostenFinanzierungModule.directive("zuschuesse", () => {
  return {
    restrict: "A",
    scope: {
      idx: "@",
      module: "=",
      finanzierungsplan: "=",
      savecallback: "&",
      infoprovider: "=",
      readonly: "=",
      snapshot: "=",
      snapshotdiff: "=",
      antrag: "=",
      obj: "=?",
      nummerierungausblenden: "=",
      updateModuleFromVue: "&",
      updateinfoprovider: "&",
    },
    template: zuschuesseTemplate,
    controller: zuschuesseController,
    controllerAs: "vm",
    bindToController: true
  };
});

export default kostenFinanzierungModule;
