import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "NutzerschlagwortBadge",
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    badgeCls() {
      return `badge badge-${ get(this.model, "nsw_cssklassen") || "light" }`;
    },

    badgeLabel() {
      return get(this.model, "nsw_kbez");
    },

    badgeId() {
      return `schlagwort-${ get(this.model, "pk") }`;
    },
  },
};
