import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "gutachten";

// @vue/component
export default {
  name: "GutachtenList",
  components: {
    AngularLink,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  setup() {
    const {
      filterDate,
      filterDefaultForEmpty,
    } = FiltersAPI();

    return {
      filterDate,
      filterDefaultForEmpty,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_LBL_GUTACHTEN_LIST_ROW_ACTION_DETAILS_",
            route: "root.gutachten.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [
        ],
        label: "_LBL_GUTACHTEN_LIST_GUTACHTEN_VERWALTEN_",
        sortable: true,
        pagination: true,
        details: false,
        translate: true,
        view: {
          filter: "top",
        },
      },
    };
  },
  computed: {
    getTitle() {
      return row => `${ this.$gettext("_TXT_GUTACHTEN_LIST_ZUM_ANTRAG_") } ${ row.ga_objektname }`;
    },
  },
};
