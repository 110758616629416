"use strict";

import antragsteller_rechtsform_zusatz_zweige from "./antragsteller_rechtsform_zusatz_zweige";


const antragsteller_params = angular.module("dias.antragsteller.parameter", [
  antragsteller_rechtsform_zusatz_zweige.name,
]);

export default antragsteller_params;
