import translate from "../../../global/directives/translate";
import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import {
  EventBus
} from "../../../global/functions/event-bus";

// @vue/component
export default {
  name: "MenuElementNachrichten",
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  data() {
    return {
      numUnread: 0,
      pullInterval: 10000 * 60, // alle 10 Minuten
      interval: undefined,
    };
  },
  computed: {
    translateTitleOptions() {
      return {
        title: "_TXT_MENU_NACHRICHTEN_BADGE_TITLE_{{numUnread}}_",
        extra: {
          numUnread: this.numUnread,
        },
      };
    },
  },
  created() {
    this.loadNachrichten();
    this.setIntervalUpdateNacrichten();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    setIntervalUpdateNacrichten() {
      this.interval = setInterval(this.loadNachrichten, this.pullInterval);
    },

    loadNachrichten() {
      if (!this.checkPermissionsSync({ perm: "benachrichtigungen.view" })) {
        return;
      }
      this.getHttp({
        url: "inbox/",
        urlParams: {
          bnn_gelesen: false,
          limit: 1,
        },
      }).then(
        response => {
          this.numUnread = response.count;
          EventBus.$emit("updateNachrichten", { numUnread: this.numUnread });
        }
      );
    },
  },
};
