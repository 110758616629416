import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "RollenBasisdaten",
  components: {
    FormstepDetail,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    rolle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        label: "_TXT_ROLLEN_DETAILS_BASEDATE_LABEL_",
      },
    };
  },
  computed: {
    rolleActiveIcon() {
      return this.rolle.aktiv ? "check" : "unchecked";
    },
  },
};
