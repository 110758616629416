import {
  isUndefined,
  isPlainObject,
} from "lodash-es";
import {
  replaceText,
  isTranslatePlaceholder,
} from "../functions/utils";
import {
  EventBus,
} from "../functions/event-bus";

function setTranslate({ el, value, statusShow }) {
  if (!isPlainObject(value)) {
    return;
  }
  const NOT_TRANSLATE = statusShow || window.statusShowPlaceholdersTranslate;
  const TEXT = value.text;
  const HTML = value.html;
  const TITLE = value.title;
  const PLACEHOLDER = value.placeholder;
  const ARIA_LABEL = value["aria-label"];

  if (isUndefined(window.gettext)) {
    if (TEXT) {
      el.textContent = TEXT;
    }
    if (HTML) {
      el.innerHTML = HTML;
    }
    if (TITLE) {
      el.setAttribute("title", TITLE);
    }
    if (PLACEHOLDER) {
      el.setAttribute("placeholder", PLACEHOLDER);
    }
    if (ARIA_LABEL) {
      el.setAttribute("aria-label", ARIA_LABEL);
    }
  } else {
    if (TEXT) {
      const NOT_TRANSLATE_TEXT = NOT_TRANSLATE || !isTranslatePlaceholder(TEXT);
      el.textContent = NOT_TRANSLATE_TEXT ? TEXT : replaceText({ text: window.gettext(TEXT), object: value.extra });
      if (!NOT_TRANSLATE_TEXT) {
        el.setAttribute("data-translate-text", TEXT);
      }
    }
    if (HTML) {
      const NOT_TRANSLATE_HTML = NOT_TRANSLATE || !isTranslatePlaceholder(HTML);
      el.innerHTML = NOT_TRANSLATE_HTML ? HTML : replaceText({ text: window.gettext(HTML), object: value.extra });
      if (!NOT_TRANSLATE_HTML) {
        el.setAttribute("data-translate-html", HTML);
      }
    }
    if (TITLE) {
      const NOT_TRANSLATE_TITLE = NOT_TRANSLATE || !isTranslatePlaceholder(TITLE);
      el.setAttribute("title", NOT_TRANSLATE_TITLE ? TITLE : replaceText({ text: window.gettext(TITLE), object: value.extra }));
      if (!NOT_TRANSLATE_TITLE) {
        el.setAttribute(`data-translate-title`, TITLE);
      }
    }
    if (PLACEHOLDER) {
      const NOT_TRANSLATE_PLACEHOLDER = NOT_TRANSLATE || !isTranslatePlaceholder(PLACEHOLDER);
      el.setAttribute("placeholder", NOT_TRANSLATE_PLACEHOLDER ? PLACEHOLDER : replaceText({ text: window.gettext(PLACEHOLDER), object: value.extra }));
      if (!NOT_TRANSLATE_PLACEHOLDER) {
        el.setAttribute(`data-translate-placeholder`, PLACEHOLDER);
      }
    }
    if (ARIA_LABEL) {
      const NOT_TRANSLATE_ARIA_LABEL = NOT_TRANSLATE || !isTranslatePlaceholder(ARIA_LABEL);
      el.setAttribute("aria-label", NOT_TRANSLATE_ARIA_LABEL ? ARIA_LABEL : replaceText({ text: window.gettext(ARIA_LABEL), object: value.extra }));
      if (!NOT_TRANSLATE_ARIA_LABEL) {
        el.setAttribute(`data-translate-aria-label`, ARIA_LABEL);
      }
    }
  }
}


export default {
  beforeMount(el, binding) {
    EventBus.$on("showPlaceholdersTranslate", ({ statusShow }) => setTranslate({ el, value: binding.value, statusShow }));
    setTranslate({ el, value: binding.value });
    el.addEventListener("click", event => {
      if (!event.ctrlKey || !event.shiftKey) {
        return;
      }
      if (!window.syncHasPerm("i18n.view.inline") || !window.syncHasPerm("kataloge.admin.view")) {
        return;
      }
      event.stopPropagation();
      event.preventDefault();
      EventBus.$emit("setTranslate", ({ value: binding.value }));
    });
  },
  updated(el, binding) {
    setTranslate({ el, value: binding.value });
  },
};
