import AsideRightBox from "../../../global/components/AsideRightBox/AsideRightBox.vue";
import NotizCreateOrEditModal from "../NotizenModal/NotizCreateOrEditModal/NotizCreateOrEditModal.vue";
import NotizenList from "../NotizenList/NotizenList.vue";
import NotizenModal from "../NotizenModal/NotizenModal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../global/compositionAPI/PermissionAPI";

import {
  EventBus,
} from "../../../global/functions/event-bus";
import {
  createNamespacedHelpers,
} from "vuex";
import {
  cloneDeep,
  find,
  keyBy,
  toString,
} from "lodash-es";

const {
  mapMutations,
  mapGetters
} = createNamespacedHelpers("notizen");

// @vue/component
export default {
  name: "NotizenBox",
  components: {
    AsideRightBox,
    NotizCreateOrEditModal,
    NotizenModal,
    NotizenList,
    PuxButton,
    PuxCloak,
    PuxTranslate,
  },
  props: {
    objectId: {
      type: String,
      required: true,
    },
    contentType: {
      type: Number,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    antragstellerorg: {
      type: String,
      default: undefined,
    },
    statusNotLoadNotizOptions: { // Wenn true, load NotizOptions nicht
      type: Boolean,
    },
    rubrics: {
      type: Array,
      required: true,
    },
    userPermissions: {
      type: Array,
      required: true,
    },
    extraKey: {
      type: String,
      required: false,
      default: undefined
    },
    weitereObjekte: {
      type: Array,
      reqired: false,
      default: undefined
    }
  },
  setup() {
    const {
      getHttp,
      getListHttp,
    } = HttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      checkPermissionsSync,
      getHttp,
      getListHttp,
    };
  },
  data() {
    return {
      loading: true,
      statusModal: undefined,
      statusModalCreate: undefined,
      optionsAsideRightBox: {
        icon: "glyphicon-note",
        active: false,
      },
      objs: [],
      idForAsideBox: "notizen_box_aside_right_box",
      htmlId: "notizen_box",
    };
  },
  computed: {
    rubricsByKey() {
      return keyBy(this.rubrics, "key");
    },

    selectorClose() {
      return `#${ this.htmlId }`;
    },

    idForModalCreateButton() {
      return `${ this.htmlId }_btn_modal_create`;
    },

    fuerSitzung() {
      return this.GET_NOTIZEN_OPTIONS.fuerSitzung && this.checkPermissionsSync({ perm: "sitzungen.sitzungsnotizen" });
    },

    fuerAntragstellerorg() {
      return !!(this.GET_NOTIZEN_OPTIONS.antragstellerorg) && this.GET_NOTIZEN_OPTIONS.fuerAntragstellerorg;
    },

    fuerFoerderorg() {
      return !!(this.GET_NOTIZEN_OPTIONS.orgList && this.GET_NOTIZEN_OPTIONS.orgList.length);
    },

    canCreateNotizen() {
      // Notzen darf man erstmal erstellen, wenn Antragsteller ausgewählt ist.
      return !!(this.GET_NOTIZEN_OPTIONS.antragstellerorg);
    },

    hasWFPermissionNotizenView() {
      return this.checkPermissionsSync({
        perm: "notizen.view",
        permArray: this.userPermissions,
      });
    },

    ...mapGetters([
      "GET_NOTIZEN_ALL",
      "GET_NOTIZEN_COUNT",
      "GET_NOTIZEN_OPTIONS",
    ]),
  },
  watch: {
    hasWFPermissionNotizenView: {
      immediate: true,
      handler(hasPermission) {
        if (hasPermission) {
          this.setEventBuses();
          this.loadNotizen();
          this.loadNotizenOrgUndEinstellungen();
        } else {
          this.destroyEventBuses();
          this.DESTROY_NOTIZEN();
        }
      },
    },

    rubrics: {
      handler() {
        this.updateNotizen();
      },
      deep: true
    },

    antragstellerorg: {
      handler() {
        this.reloadNotizen();
      },
    },
  },
  beforeUnmount() {
    this.destroyEventBuses();
    this.DESTROY_NOTIZEN();
  },
  methods: {
    setEventBuses() {
      EventBus.$on("updateNotizen", this.updateNotizen);
      EventBus.$on("reloadNotizen", this.reloadNotizen);
      EventBus.$on("antragsteller.update", this.reloadNotizen);
    },

    destroyEventBuses() {
      EventBus.$off("updateNotizen", this.updateNotizen);
      EventBus.$off("reloadNotizen", this.reloadNotizen);
      EventBus.$off("antragsteller.update", this.reloadNotizen);
    },

    loadNotizen() {
      this.getListHttp({
        url: `notizen/`,
        urlParams: {
          content_type: this.contentType,
          no_objektid: this.objectId,
          exact: false,
        },
      }).then(
        response => {
          this.SET_NOTIZEN_LIST_ALL({ notizen: response });
          this.UPDATE_NOTIZEN({
            rubrics: this.rubrics,
            rubricsByKey: this.rubricsByKey,
          });
          this.SET_NOTIZEN_READY();
          this.loading = false;
        }
      );
    },

    loadNotizenOrgUndEinstellungen() {
      if (this.statusNotLoadNotizOptions) {
        this.setNotizenOrganisation();
        this.setNotizenEinstellungen();
        return;
      }
      const PROMISE_ALL = [
        this.getListHttp({
          url: `notizorgs/`,
          urlParams: {
            content_type: this.contentType,
            no_objektid: this.objectId,
          },
        }),
        this.getHttp({
          url: `notizorgs/antragstellerorg/`,
          urlParams: {
            content_type: this.contentType,
            no_objektid: this.objectId,
          },
        }),
        this.getHttp({
          url: "katalog/?key=notizen_anzeige"
        }),
      ];
      Promise.all(PROMISE_ALL).then(
        responses => {
          this.setNotizenOrganisation({ response: responses[0], antragstellerorg: responses[1] });
          this.setNotizenEinstellungen({ response: responses[2] });
        }
      );
    },

    setNotizenEinstellungen({ response } = {}) {
      const NOTIZ_OPTIONS = cloneDeep(this.GET_NOTIZEN_OPTIONS);
      const EINSTELLUNG = find(response, el => el.content_type === toString(this.contentType)) || {};
      NOTIZ_OPTIONS.fuerAntragstellerorg = EINSTELLUNG.kns_ast_sichtbar;
      NOTIZ_OPTIONS.fuerSitzung = EINSTELLUNG.kns_sitzung_sichtbar;
      NOTIZ_OPTIONS.weitereObjekte = this.weitereObjekte;
      NOTIZ_OPTIONS.extraKey = this.extraKey;
      this.SET_NOTIZEN_OPTIONS({ options: NOTIZ_OPTIONS });
    },

    setNotizenOrganisation({ response, antragstellerorg } = {}) {
      const NOTIZ_OPTIONS = cloneDeep(this.options);
      NOTIZ_OPTIONS.content_type = this.contentType;
      NOTIZ_OPTIONS.no_objektid = this.objectId;
      if (response) {
        NOTIZ_OPTIONS.orgList = response;
        NOTIZ_OPTIONS.antragstellerorg = antragstellerorg && antragstellerorg.pk;
      }
      this.SET_NOTIZEN_OPTIONS({ options: NOTIZ_OPTIONS });
    },

    showNotizen() {
      this.statusModal = true;
    },

    closeModal() {
      this.statusModal = false;
    },

    updateNotizen() {
      if (this.hasWFPermissionNotizenView) {
        this.UPDATE_NOTIZEN({
          rubrics: this.rubrics,
          rubricsByKey: this.rubricsByKey,
        });
      }
    },

    openModalCreate() {
      this.statusModalCreate = true;
    },

    closeModalCreate(isSaved) {
      if (isSaved) {
        $(`#${ this.idForAsideBox }`).focus();
      } else {
        $(`#${ this.idForModalCreateButton }`).focus();
      }
      this.statusModalCreate = false;
    },

    reloadNotizen() {
      this.loadNotizen();
      this.loadNotizenOrgUndEinstellungen();
    },

    ...mapMutations([
      "DESTROY_NOTIZEN",
      "SET_NOTIZEN_LIST_ALL",
      "SET_NOTIZEN_OPTIONS",
      "SET_NOTIZEN_READY",
      "UPDATE_NOTIZEN",
    ]),
  },
};
