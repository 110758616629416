"use strict";

import BaseModel from "dias/core/models/baseModel";

class Nutzertabellenconfigs extends BaseModel {
  constructor() {
    super("nutzertabellenconfigs");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.getCurrentList = filterName => {
      return collection.customGETLIST("", { ntc_kennung: filterName });
    };

    collection.getPageSize = () => {
      return collection.customGETLIST("", { ntc_kennung: "pagesize" });
    };

    return collection;
  }
}

export default Nutzertabellenconfigs;
