import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

// @vue/component
export default {
  name: "FaqAdminModalRubrikVerwalten",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: {
        showCloseButton: true,
        list: [
          {
            id: "rubrik",
            type: "select",
            label: "_LBL_FAQ_ADMIN_MODAL_RUBRIK_VERWALTEN_RUBRIK_",
            required: true,
            view: "v",
            keyId: "pk",
            keyLabel: "fqr_kbez",
            menuWidthAll: true,
            deselect: false,
            change: this.changeRubrik,
            translate: true,
          },
          {
            id: "fqr_kbez",
            type: "text",
            label: "_LBL_FAQ_ADMIN_MODAL_RUBRIK_VERWALTEN_KURZBEZEICHNUNG_",
            required: true,
            maxlength: 200,
            view: "v",
            translate: true,
          },
          {
            id: "fqr_bez",
            type: "text",
            label: "_LBL_FAQ_ADMIN_MODAL_RUBRIK_VERWALTEN_BEZEICHNUNG_",
            required: true,
            maxlength: 200,
            view: "v",
            translate: true,
          },
          {
            id: "fqr_prio",
            type: "number",
            label: "_LBL_FAQ_ADMIN_MODAL_RUBRIK_VERWALTEN_PRIORITAET_",
            maxlength: 200,
            view: "v",
            translate: true,
          },
        ],
      },
      model: {
        rubrik: "neu",
        fqr_kbez: "",
        fqr_bez: "",
        fqr_prio: null,
      },
      data: {
        rubrik: [],
      },
      status: {
        loading: true,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  created() {
    this.loadingRubriken();
  },
  methods: {
    loadingRubriken() {
      this.getListHttp({ url: "faqrubriken/" }).then(
        response => {
          const RUBRIK = response;
          RUBRIK.unshift({
            fqr_kbez: "Neu",
            pk: "neu"
          });
          this.data.rubrik = RUBRIK;
          this.status.loading = false;
        }
      );
    },

    changeRubrik({ item }) {
      const RUBRIK = this.model.rubrik;
      this.model = {
        rubrik: RUBRIK,
        fqr_kbez: item.fqr_kbez || null,
        fqr_bez: item.fqr_bez || null,
        fqr_prio: item.fqr_prio || null,
      };
    },

    changeModel() {
      this.errorsClone = undefined;
    },

    create({ data }) {
      this.postHttp({ url: "faqrubriken/", data }).then(
        response => {
          this.addNotification({
            text: "_MSG_FAQ_ADMIN_MODAL_RUBRIK_VERWALTEN_CREATE_SUCCESS_{{bez}}_",
            extra: { bez: response.fqr_bez }
          });
          this.close({ status: true });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },

    edit({ data }) {
      this.putHttp({ url: `faqrubriken/${ this.model.rubrik }/`, data }).then(
        () => {
          this.addNotification({ text: "_MSG_FAQ_ADMIN_MODAL_RUBRIK_VERWALTEN_UPDATE_SUCCESS_" });
          this.close({ status: true });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },

    save() {
      this.status.loading = true;
      const data = {
        fqr_bez: this.model.fqr_bez,
        fqr_kbez: this.model.fqr_kbez,
        fqr_prio: this.model.fqr_prio,
      };
      if (this.model.rubrik === "neu") {
        this.create({ data });
      } else {
        this.edit({ data });
      }
    },
  },
};
