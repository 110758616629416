import AsideRightBox from "../../../global/components/AsideRightBox/AsideRightBox.vue";
import AufgabeModalKorrektur from "../AufgabeModalKorrektur/AufgabeModalKorrektur.vue";
import AufgabeModalZuweisen from "../AufgabeModalZuweisen/AufgabeModalZuweisen.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import NachrichtenLink from "../../Nachrichten/NachrichtenLink/NachrichtenLink.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxGet from "../../../global/components/PuxGet/PuxGet.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import TerminChange from "../TerminChange/TerminChange.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../global/compositionAPI/PermissionAPI";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "AufgabeBox",
  components: {
    AsideRightBox,
    AufgabeModalKorrektur,
    AufgabeModalZuweisen,
    Modal,
    NachrichtenLink,
    Permission,
    PuxButton,
    PuxGet,
    PuxTranslate,
    TerminChange,
  },
  props: {
    referenceObj: {
      type: Object,
      required: true,
    },
    referenceName: {
      type: String,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "onTaskAccepted",
    "updateAufgabe",
  ],
  setup() {
    const {
      addNotification,
      postHttp,
    } = HttpAPI();

    const {
      checkPermissionsSync,
      me,
    } = PermissionAPI();

    return {
      addNotification,
      checkPermissionsSync,
      me,
      postHttp,
    };
  },
  data() {
    return {
      isModalKorrekturVisible: false,
      isModalZuweisenVisible: false,
      id: "aufgabe_aside_right_box",
      confirmShow: undefined,
      confirmOptions: undefined,
    };
  },
  computed: {
    optionsAsideRightBox() {
      return {
        icon: "glyphicon-todos",
        active: false,
        toggle: !this.aufgabe.beobachter,
      };
    },

    aufgabe() {
      return this.referenceObj.aktuelle_aufgabe;
    },

    urlForCurrentObject() {
      return `${ this.baseUrl }/${ this.referenceObj.pk }/`;
    },

    urlForAufgaben() {
      return `${ this.urlForCurrentObject }aufgaben/`;
    },

    urlForAcceptAufgabe() {
      return `aktuelle_aufgaben/${ this.aufgabe.pk }/annehmen/`;
    },

    empfaenger() {
      return this.aufgabe ? [this.aufgabe.nutzer] : [];
    },

    hasPermissionSeeDetails() {
      return this.checkPermissionsSync({
        perm: "workflow.list.column.status_detail.view",
      });
    },

    hasPermissionWorkflowKorrektur() {
      return this.checkPermissionsSync({
        perm: "workflow.korrektur",
      });
    },

    hasWorkflowPermissionAufgabeTerminAendern() {
      return this.checkPermissionsSync({
        perm: "aufgabe.termin_aendern",
        permArray: this.referenceObj.user_permissions,
      });
    },

    headerForBox() {
      return this.hasPermissionSeeDetails ?
        this.aufgabe.kbezdetail :
        this.aufgabe.kbezbeobachter;
    },

    idForButtonOpenKorrektur() {
      return `${ this.id }_open_korrektur`;
    },

    idForButtonMirZuweisen() {
      return `${ this.id }_mir_zuweisen`;
    },

    idForButtonAnnehmen() {
      return `${ this.id }_annehmen`;
    },

    idForButtonZuweisen() {
      return `${ this.id }_zuweisen`;
    },

    idForNutzerNachrichten() {
      return `${ this.id }_nutzer_nachrichten`;
    },

    isTerminVisible() {
      return (this.aufgabe.beobachter &&
        (this.hasWorkflowPermissionAufgabeTerminAendern ||
          this.aufgabe.termin)) ||
        !this.aufgabe.beobachter;
    },

    selectorCloseForModalZuweisen() {
      return `#${ this.idForButtonZuweisen }`;
    },
  },
  methods: {
    showModalKorrektur() {
      this.isModalKorrekturVisible = true;
    },

    closeModalKorrektur() {
      this.isModalKorrekturVisible = false;
    },

    openConfirmAcceptAufgabe(idForButton) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_AUFGABE_BOX_CONFIRM_HEADER_",
        msg: "_TXT_AUFGABE_BOX_CONFIRM_BODY_{{aufgabeKbez}}_",
        extra: {
          aufgabeKbez: this.aufgabe.kbez,
        },
        okLabel: "_BTN_FORTFAHREN_",
        okCallback: () => this.acceptAufgabe(idForButton),
        cancelCallback: () => this.closeConfirm(idForButton),
        loading: false,
      };
      this.confirmShow = true;
    },

    acceptAufgabe(idForButton) {
      this.postHttp({
        url: this.urlForAcceptAufgabe,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_AUFGABE_BOX_CONFIRM_SUCCESS_",
          });
          this.closeConfirm(idForButton, true);
          this.$emit("onTaskAccepted");
        },
        () => this.addNotification({
          text: "_MSG_AUFGABE_BOX_CONFIRM_ERROR_",
          type: "error",
        })
      );
    },

    closeConfirm(idForButton, isSaved) {
      this.confirmShow = false;
      setTimeout(() => {
        if (isSaved) {
          $(`#${ this.id }`).focus();
        } else {
          $(`#${ idForButton }`).focus();
        }
      });
    },

    assignAufgabe() {
      this.isModalZuweisenVisible = true;
    },

    closeModalZuweisen({ status } = {}) {
      if (status) {
        this.$emit("onTaskAccepted");
      }
      this.isModalZuweisenVisible = false;
    },

    updateTermin({ termin }) {
      const AUFGABE = cloneDeep(this.aufgabe);
      AUFGABE.termin = termin;
      this.$emit("updateAufgabe", AUFGABE);
    },
  },
};
