import translate from "../../../../../../../global/directives/translate";
import FormElement from "../../../../../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "LohnsteigerungBox",
  components: {
    FormElement,
    PuxButton,
  },
  directives: {
    translate,
  },
  props: {
    lohnsteigerung: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    startdatum: {
      type: String,
      required: false,
      default: undefined,
    },
    zeitraumVon: {
      type: String,
      required: true,
    },
    zeitraumBis: {
      type: String,
      required: true,
    },
    accept: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      model: {
        lohnsteigerung: 0,
        startdatum: null,
      },
    };
  },
  computed: {
    optionsLohnsteigerung() {
      return {
        id: "lohnsteigerung",
        type: "float",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_LOHNSTEIGERUNG_",
        required: false,
        view: "v-alt",
        maxValue: 5,
        tooltip: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_LOHNSTEIGERUNG_INFO_I_",
        suffix: "%",
        translate: true,
        nullValue: 0,
      };
    },

    optionsStartdatum() {
      return {
        id: "startdatum",
        type: "date",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_STARTDATUM_",
        required: false,
        view: "v-alt",
        tooltip: "_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_STARTDATUM_INFO_I_",
        translate: true,
        notBefore: this.notBefore,
        notAfter: this.notAfter,
        defaultValue: this.notBefore,
      };
    },

    notBefore() {
      return this.zeitraumVon;
    },

    notAfter() {
      return this.zeitraumBis;
    },
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.lohnsteigerung) {
        this.model.lohnsteigerung = this.lohnsteigerung;
      }
      if (this.startdatum) {
        this.model.startdatum = this.startdatum;
      }
    },

    acceptLocal() {
      this.accept({ model: this.model });
    },
  },
};
