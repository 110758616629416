"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";


class KAufgabeEditBeschreibungController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, ParameterUtils, DiasNotification, Restangular, i18n) {
    super($q, $scope, DiasNotification);
    this.ParameterUtils = ParameterUtils;
    this.gettext = i18n.gettext;
    this.Restangular = Restangular;
    this.initForm();
  }

  _save() {
    const data = this.ParameterUtils.to_request_body(this.formModel, null, "");
    Object.assign(this.model, data, {});
    return this.Restangular
      .one("sitzungen", this.model.sitzung.pk)
      .one("tops", this.model.top.pk)
      .one("sitzungslisten", this.model.sitzungsliste)
      .one("festgeschrieben", this.model.pk)
      .customPUT(
        data,
        "setze_entscheidung"
      ).then(
        response => {
          Object.assign(this.$scope.model.entscheidungsstatus_obj, response.plain().entscheidungsstatus_obj);
          this.$scope.model.sla_entscheidung_anmerkung = response.sla_entscheidung_anmerkung;
          this.$scope.model.entscheidungsstatus = response.entscheidungsstatus;
          this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
        },
        errors => {
          return this.onErrors(errors, this.errorLabels);
        }
      );
  }

  onEnterEditMode() {
    super.onEnterEditMode();
    this.updateFormModel();
  }

  updateFormModel() {
    if (this.ParameterUtils && this.formModelTemplate && this.model) {
      this.formModel = this.ParameterUtils.params_from_template(this.formModelTemplate, this.model);
      this.formModel.entscheidungsstatus.katalog_filter = { gruppe: this.model.entscheidungsstatusgruppe };
    }
  }

  initForm() {
    this.formModelTemplate = this.ParameterUtils.init_parameter_in({
      entscheidungsstatus: {
        bez: this.gettext("_TXT_SITZUNG_ANTRAG_ENTSCHEIDUNG_"),
        typ: "single_choice",
        katalog: "entscheidungsstatus",
        prio: 1,
        options: {
          required: false
        }
      },
      sla_entscheidung_anmerkung: {
        bez: this.gettext("_TXT_SITZUNG_ANTRAG_AUFLAGE_"),
        typ: "richtext",
        prio: 2,
        options: {
          required: false
        }
      },
    });
    this.form_keys = this.ParameterUtils.get_ordered_keys(this.formModelTemplate);
    this.errorLabels = {};
    this.form_keys.forEach(
      k => {
        this.errorLabels[k] = `${ this.formModelTemplate[k].bez }: `;
      }
    );
    this.form_keys_left = this.form_keys.slice(0, 1);
    this.form_keys_right = this.form_keys.slice(1);
    this.updateFormModel();
  }
}

export default KAufgabeEditBeschreibungController;
