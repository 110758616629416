import {
  computed,
  ref,
} from "vue";

import {
  EventBus,
} from "../../../global/functions/event-bus";
import {
  cloneDeep,
} from "lodash-es";

export default function SnapshotObjectAPI() {
  const snapshot = ref(undefined);
  const snapshotActivePk = ref(undefined);
  const isSnapshot = computed(() => {
    return !!snapshot.value;
  });
  const changeActiveSnapshot = ({ snapshotLocal } = {}) => {
    if (snapshotLocal) {
      snapshotActivePk.value = snapshotLocal.pk;
      snapshot.value = cloneDeep(snapshotLocal);
    } else {
      snapshot.value = undefined;
      snapshotActivePk.value = undefined;
    }
  };
  const createSnapshot = () => {
    EventBus.$emit("createSnapshot");
  };

  return {
    changeActiveSnapshot,
    createSnapshot,
    isSnapshot,
    snapshot,
    snapshotActivePk,
  };
}
