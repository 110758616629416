import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import Abwesenheiten from "./PanelComponents/Abwesenheiten/Abwesenheiten.vue";
import Allgemein from "./PanelComponents/Allgemein/Allgemein.vue";
import Gueltigkeiten from "./PanelComponents/Gueltigkeiten/Gueltigkeiten.vue";
import Kompetenzen from "./PanelComponents/Kompetenzen/Kompetenzen.vue";
import PersonalModalDeactivate from "../PersonalModalDeactivate/PersonalModalDeactivate.vue";
import PersonalModalReactivate from "../PersonalModalReactivate/PersonalModalReactivate.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

// AM
import PersonalZiel from "../../../../../../../apps/dias-am/libs/vue/Personal/PersonalZiel/PersonalZiel.vue";
import Stellenumfang from "../../../../../../../apps/dias-am/libs/vue/Personal/Stellenumfang/Stellenumfang.vue";
// AM


import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import {
  cloneDeep,
  get,
} from "lodash-es";
import { EventBus } from "../../../global/functions/event-bus";

// @vue/component
export default {
  name: "PersonalDetails",
  components: {
    Abwesenheiten,
    Allgemein,
    ContextPanel,
    Gueltigkeiten,
    Kompetenzen,
    PersonalModalDeactivate,
    PersonalModalReactivate,
    PersonalZiel,
    PuxButton,
    PuxPageDetails,
    PuxTranslate,
    Stellenumfang,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  data() {
    return {
      loading: true,
      model: undefined,
      modal: {
        deactivate: false,
        reactivate: false,
      }
    };
  },
  computed: {
    url() {
      return `personal/${ this.$stateParams.id }/`;
    },

    pageTitleTranslation() {
      return "_PAGE_TITLE_PERSONAL_DETAILS_{{name}}_";
    },

    headerTranslation() {
      return "_TXT_PERSONAL_DETAILS_HEADER_";
    },

    subHeaderTranslation() {
      return "_TXT_PERSONAL_DETAILS_SUBHEADER_{{name}}_{{o_name}}_";
    },

    translationExtra() {
      return {
        name: this.personalName,
        o_name: this.personalOrgname,
      };
    },

    personalName() {
      return get(this.model, "name", "");
    },

    personalOrgname() {
      return get(this.model, "o_name", "");
    },

    canViewAbwesenheiten() {
      return this.checkPermissionsSync({
        perm: "personal.abwesenheit.view",
        permArray: this.model.user_permissions
      });
    },

    canViewKompetenzen() {
      return this.checkPermissionsSync({
        perm: "personal.kompetenzen.view",
        permArray: this.model.user_permissions
      });
    },

    canViewStellenumfang() {
      return this.checkPermissionsSync({
        perm: "personal.am.umfang.view",
        permArray: this.model.user_permissions
      });
    },

    canViewZiele() {
      return this.checkPermissionsSync({
        perm: "personal.am.zielsetzung.view",
        permArray: this.model.user_permissions
      });
    }
  },
  created() {
    this.loadModel();
  },
  methods: {
    loadModel() {
      this.getHttp({
        url: this.url,
      }).then(
        response => {
          this.updateModel({ model: response });
        }
      ).finally(
        () => {
          this.loading = false;
        }
      );
    },

    updateModel({ model }) {
      this.model = cloneDeep(model);
    },

    openModalDeactivate() {
      this.modal.deactivate = true;
    },

    closeModalDeactivate({ response } = {}) {
      if (get(response, "status") == 200) {
        this.updateModel({ model: response.data });
        setTimeout(() => EventBus.$emit("updateTablepersonal_detail_allgemein_gueltigkeiten"));
      } else if (get(response, "status") == 204) {
        this.$goTo("root.personal");
      }
      this.modal.deactivate = false;
    },

    openModalReactivate() {
      this.modal.reactivate = true;
    },

    closeModalReactivate({ response } = {}) {
      if (response) {
        this.updateModel({ model: response.data });
        setTimeout(() => EventBus.$emit("updateTablepersonal_detail_allgemein_gueltigkeiten"));
      }
      this.modal.reactivate = false;
    },
  },
};
