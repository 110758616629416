import BetragsrechnerMixin from "../Mixins/BetragsrechnerMixin";
import BildungsKostenrechnerMixin from "../Mixins/BildungsKostenrechnerMixin";
import TagespauschaleMixin from "../Mixins/TagespauschaleMixin";

// @vue/component
export default {
  name: "UebernachtungsKostenrechner",
  mixins: [
    BetragsrechnerMixin,
    BildungsKostenrechnerMixin,
    TagespauschaleMixin,
  ],
  data() {
    return {
      labelAnzahlTage: "_LBL_UEBERNACHTUNGS_KOSTENRECHNER_ANZAHL_TAGE_",
      labelAnzahlPersonen: "_LBL_UEBERNACHTUNGS_KOSTENRECHNER_ANZAHL_PERSONEN_",
    };
  },
  created() {
    this.changeModelWithTagespauschale();
  },
};
