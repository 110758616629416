var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;







jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :ctx.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "ctx.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in ctx.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<div class=\"modal-body\"><div class=\"row\"><section class=\"main col-10 offset-1\"><div ng-attr-id=\"{{ ctx.DiasNotification.form.containerId }}\"></div><form><fieldset>");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<select id=\"budget\" auto-focus=\"true\" aria-required=\"true\" name=\"budget\" ng-attr-aria-invalid=\"{{ctx.errors.budget ? 'true' : 'false'}}\" ng-model=\"ctx.sv.budget\" role=\"listbox\" class=\"form-control\"><option value=\"\">---------</option><option ng-repeat=\"budget in ctx.budgetChoices\" value=\"{{ budget.pk }}\">{{ budget.bud_kbez }} (Budget: {{ budget.bud_betrag | currency }}, Verfügbar: {{ budget.verfuegbar | currency }} )</option></select>");
}
}, 'budget', 'Budget');
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<div class=\"input-group\"><input id=\"sv_budget\" aria-required=\"true\" dias-decimal-field name=\"sv_budget\" ng-attr-aria-invalid=\"{{ctx.errors.sv_budget ? 'true' : 'false'}}\" ng-model=\"ctx.sv.sv_budget\" maxlength=\"250\" type=\"text\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">&euro;</span></div></div>");
}
}, "sv_budget", "Zugewiesener Betrag");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<select id=\"regelsatz\" aria-required=\"true\" name=\"regelsatz\" ng-attr-aria-invalid=\"{{ctx.errors.regelsatz ? 'true' : 'false'}}\" ng-change=\"ctx.updateAntraege()\" ng-model=\"ctx.sv.regelsatz\" role=\"listbox\" class=\"form-control\"><option value=\"\">---------</option><option ng-repeat=\"regelsatz in ctx.regelsatzChoices\" value=\"{{ regelsatz.pk }}\">{{ regelsatz.ars_kbez }}</option></select>");
}
}, 'regelsatz', 'Förderangebot');
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<select id=\"haushaltsjahr\" aria-required=\"true\" name=\"haushaltsjahr\" ng-attr-aria-invalid=\"{{ctx.errors.haushaltsjahr ? 'true' : 'false'}}\" ng-change=\"ctx.updateAntraege()\" ng-model=\"ctx.sv.haushaltsjahr\" role=\"listbox\" class=\"form-control\"><option value=\"\">---------</option><option ng-repeat=\"haushaltsjahr in ctx.haushaltsjahrChoices\" value=\"{{ haushaltsjahr }}\">{{ haushaltsjahr }}</option></select>");
}
}, 'haushaltsjahr', 'Haushaltsjahr');
buf.push("<h4>Vorgesehene Anträge</h4><div ng-if=\"ctx.verknuepfbareAntraege.antraege.length === 0 || ctx.verknuepfbareAntraege.antraege === undefined\">Keine Anträge für die aktuelle Auswahl verfügbar</div><table ng-if=\"ctx.verknuepfbareAntraege.antraege.length &gt; 0\" class=\"table table-striped\"><tr><th>Antrag</th><th class=\"text-right\">Bewilligt: {{ ctx.verknuepfbareAntraege.sum_bewilligt | currency }}</th></tr><tr ng-repeat=\"antrag in ctx.verknuepfbareAntraege.antraege\"><td><spaced ng-model=\"antrag.a_nr\"></spaced>: {{:: antrag.a_titel }}</td><td class=\"text-right\">{{:: antrag.a_zuschussbewilligt | currency }}</td></tr></table></fieldset></form></section></div></div><div class=\"modal-footer\"><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"exitWithCallback(ctx.save)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"exit()\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-default\"><span>Abbrechen</span></button></div></div></div>");;return buf.join("");
}