import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  FilterDateMixin,
  FilterDefaultForEmptyMixin,
} from "../../../../../global/mixins/FiltersMixin";
import NutzerMixin from "../../../../../mixins/NutzerMixin";
import SyConfigMixin from "../../../../../global/mixins/SyConfigMixin";

// @vue/component
export default {
  name: "Anmeldedaten",
  components: {
    FormstepDetail,
    PuxTranslate,
  },
  mixins: [
    FilterDateMixin,
    FilterDefaultForEmptyMixin,
    NutzerMixin,
    SyConfigMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        label: "_LBL_NUTZER_DETAILS_ACCOUNT_INFOS_",
      },
    };
  },
};
