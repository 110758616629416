import {
  toRefs,
  inject,
} from "vue";

import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxCloak from "../../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import SitzungDetailsTagesordnungItemActions from "../SitzungDetailsTagesordnungItemActions/SitzungDetailsTagesordnungItemActions.vue";
import SitzungDetailsTagesordnungItemEdit from "./SitzungDetailsTagesordnungItemEdit/SitzungDetailsTagesordnungItemEdit.vue";
import SitzungModulePreview from "../../../SitzungModule/SitzungModulePreview.vue";

import { EventBus } from "../../../../../global/functions/event-bus";
import SitzungDetailsTagesordnungItemAPI from "../compositionAPI/SitzungDetailsTagesordnungItemAPI";

// @vue/component
export default {
  name: "SitzungDetailsTagesordnungItem",
  components: {
    AngularLink,
    PuxCloak,
    PuxIcon,
    SitzungDetailsTagesordnungItemActions,
    SitzungDetailsTagesordnungItemEdit,
    SitzungModulePreview,
  },
  props: {
    sitzung: {
      type: Object,
      required: true,
    },
    tagesordnung: {
      type: Array,
      required: true,
    },
    tagesordnungItem: {
      type: Object,
      required: true,
    },
    tagesordnungOberItem: {
      type: Object,
      default: undefined,
    },
    itemModulesPreview: {
      type: Object,
      default: () => {},
    },
    loadingModulesPreview: {
      type: Boolean,
      required: true,
    },
    topsUrl: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    unterIndex: {
      type: Number,
      default: undefined,
    },
  },
  setup(props) {
    const {
      tagesordnungItem,
      topsUrl,
    } = toRefs(props);

    const reloadTagesordnung = inject("reloadTagesordnung");

    const {
      deleteTagesordnungItem,
      resetTagesordnungItem,
    } = SitzungDetailsTagesordnungItemAPI({ topsUrl, tagesordnungItem });

    const resetItem = () => {
      resetTagesordnungItem().then(
        () => reloadTagesordnung(),
      );
    };

    const deleteItem = () => {
      deleteTagesordnungItem().then(
        () => {
          reloadTagesordnung();
          EventBus.$emit("reloadNotizen");
        },
      );
    };

    return {
      deleteItem,
      resetItem,
    };
  },
  data() {
    return {
      editItemFormFormVisible: false,
    };
  },
  computed: {
    isUnterpunkt() {
      return this.unterIndex !== undefined;
    },

    styleObjectBottom() {
      if (this.isUnterpunkt) {
        return {
          borderBottom: "1px solid rgba(0,0,0,0.125)"
        };
      }
      return {
        borderBottom: "4px solid rgba(0,0,0,0.125)"
      };
    },
  },
  methods: {
    toggleEdit() {
      this.editItemFormFormVisible = !this.editItemFormFormVisible;
    },
  }
};
