import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "ImportauftragModalLoeschen",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    obj: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      status: {
        loading: undefined,
      },
    };
  },
  computed: {
    translationMsgSuccess() {
      return {
        text: "_MSG_IMPORTEXPORTAUFTRAG_TABLE_MODAL_DELETE_SUCCESS_{{kbez}}_",
        extra: { kbez: this.obj.iea_kbez },
      };
    },

    translationMsgError() {
      return {
        text: "_MSG_IMPORTEXPORTAUFTRAG_TABLE_MODAL_DELETE_ERROR_{{kbez}}_",
        extra: { kbez: this.obj.iea_kbez },
        type: "error"
      };
    },
  },
  methods: {
    deleteAuftrag() {
      this.status.loading = true;
      const data = cloneDeep(this.obj);
      data.co_deaktiviert = true;
      this.deleteHttp({ url: `importauftraege/${ this.obj.pk }/` }).then(
        () => {
          this.addNotification(this.translationMsgSuccess);
          this.close({ deleted: true });
        },
        () => this.addNotification(this.translationMsgError)
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
