import Alert from "../../../../global/components/Alert/Alert.vue";
import KofiErfassungModulBetragWarningIcon from "./KofiErfassungModulBetragWarningIcon/KofiErfassungModulBetragWarningIcon.vue";
import KofiErfassungModulProzent from "./KofiErfassungModulProzent/KofiErfassungModulProzent.vue";
import KofiErfassungPosition from "../KofiErfassungPosition/KofiErfassungPosition.vue";
import KofiErfassungPositionForm from "../KofiErfassungPositionForm/KofiErfassungPositionForm.vue";
import KofiErfassungPersonalkostenLohnjurnalFunktion from "../KofiErfassungPersonalkostenLohnjurnalFunktion/KofiErfassungPersonalkostenLohnjurnalFunktion.vue";

import KofiErfassungModulMixin from "../Mixins/KofiErfassungModulMixin";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  components: {
    Alert,
    KofiErfassungModulBetragWarningIcon,
    KofiErfassungModulProzent,
    KofiErfassungPosition,
    KofiErfassungPositionForm,
    KofiErfassungPersonalkostenLohnjurnalFunktion,
  },
  mixins: [
    KofiErfassungModulMixin,
  ],
  props: {
    koFiArtenObj: {
      type: Object,
      required: true,
      info: "Mapping 'koFiArtnen' by 'pk'",
    },
    personalfunktionen: {
      type: Array,
      required: true,
      info: "Personalfunktionen für 'personalkosten_lohnjournal'",
    },
    belegDokumenteAnzahl: {
      type: Object,
      required: false,
      info: "Mapping dok_id zur Anzahl der Belege, in der dieses Dokument benutzt wird",
      default: () => ({}),
    },
  },
  data() {
    return {
      statusAddPosition: false,
    };
  },
  computed: {
    keyBezeichnungNachreichenErlaubt() {
      return `${ this.prefixCurrent.bezeichnung }_nachreichen_erlaubt`;
    },

    statusHasPermissionVertragspositionenCreate() {
      return this.checkPermissionsSync({ perm: this.permissionPositionCreate, permArray: this.auszahlung.user_permissions });
    },

    statusHasPermissionVertragspositionenNachreichen() {
      return this.checkPermissionsSync({ perm: this.permissionPositionNachreichen, permArray: this.auszahlung.user_permissions });
    },

    buttonInfoCloseId() {
      return `${ this.htmlIdLocal }_info_close`;
    },

    buttonAddId() {
      return `${ this.htmlIdLocal }_add_position`;
    },

    modulname() {
      return this.modul.modulname;
    },

    statusShowButtonAdd() {
      return !this.statusAddPosition &&
        (this.statusHasPermissionVertragspositionenCreate ||
          (this.statusNachreichenErlaubt &&
            this.statusHasPermissionVertragspositionenNachreichen));
    },

    statusNachreichenErlaubt() {
      return this.kofi[this.prefixCurrent.art] && this.kofi[this.prefixCurrent.art][this.keyBezeichnungNachreichenErlaubt];
    },

    formId() {
      return `${ this.htmlIdLocal }_new`;
    },

    katalogDetails() {
      const KOFI_ART_PK = get(this.kofi, `${ this.prefixCurrent.art }.pk`);
      const KOFI_ART_OBJECT_CURRENT = this.koFiArtenObj[KOFI_ART_PK];
      if (KOFI_ART_PK && KOFI_ART_OBJECT_CURRENT && KOFI_ART_OBJECT_CURRENT.details) {
        return KOFI_ART_OBJECT_CURRENT.details;
      }
      return [];
    },

    extraSave() {
      return {
        [`vertrags${ this.labelKoFi }`]: this.kofi.pk,
      };
    },
  },
  methods: {
    addPosition() {
      this.statusAddPosition = true;
    },

    saveLocal() {
      this.statusAddPosition = false;
      this.$attrs.onReloadKofi();
    },

    closeLocal() {
      this.statusAddPosition = false;
    },
  },
};
