import translate from "../../../../../global/directives/translate";
import moment from "moment";
import {
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonInTd",
  directives: {
    translate,
  },
  props: {
    person: {
      type: Object,
      required: true,
    },
    period: {
      type: Object,
      required: true,
    },
  },
  computed: {
    titleTranslate() {
      return {
        title: "_TXT_VERTRAG_DETAILS_PERSONALBAUM_PERSON_TITLE_{{ALL_API}}_",
        extra: this.person,
      };
    },

    statusShowPersonInCurrentPeriod() {
      return this.person.vpsp_beschaeftigt_von <= this.period.pe_bis &&
        (!this.person.vpsp_beschaeftigt_bis || this.person.vpsp_beschaeftigt_bis >= this.period.pe_von);
    },

    styles() {
      const BEGIN_PERIOD = moment(this.period.pe_von);
      const END_PERIOD = moment(this.period.pe_bis);
      const BEGIN_ELEMENT = moment(this.person.vpsp_beschaeftigt_von < this.period.pe_von ? this.period.pe_von : this.person.vpsp_beschaeftigt_von);
      const END_ELEMENT = moment(isNil(this.person.vpsp_beschaeftigt_bis) || this.person.vpsp_beschaeftigt_bis > this.period.pe_bis ? this.period.pe_bis : this.person.vpsp_beschaeftigt_bis);
      const COUNT_DAYS = END_PERIOD.diff(BEGIN_PERIOD, "days");
      const BEGIN_COUNT_DAYS = BEGIN_ELEMENT.diff(BEGIN_PERIOD, "days");
      const END_COUNT_DAYS = END_PERIOD.diff(END_ELEMENT, "days");
      const LEFT = BEGIN_COUNT_DAYS * 100 / COUNT_DAYS;
      const RIGHT = END_COUNT_DAYS * 100 / COUNT_DAYS;
      return {
        left: `${ LEFT }%`,
        right: `${ RIGHT }%`,
      };
    },
  },
};
