"use strict";

import BetragsrechnerBase from "dias/kosten_finanzierung/betragsrechner/betragsrechner.base";


class KostenMitBegruendungBetragsrechner extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "mdm_kosten_mit_begruendung";
    this.component_name = "mdm-kosten-mit-begruendung-betragsrechner";
    this.labels = { wert: "Kosten",
                    beguendung: "Begründung" };
  }

  get_labelmapping() {
    return this.labels;
  }

  get_fields(params) {
    return { wert: params.wert,
             begruendung: params.begruendung
    };
  }
}
export default KostenMitBegruendungBetragsrechner;
