import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "ImportauftragAntragWizardStep4",
  components: {
    AngularLink,
    PuxButton,
    PuxTranslate,
  },
  props: {
    antragId: {
      type: String,
      default: undefined,
    },
    reset: {
      type: Function,
      default: undefined,
    },
  },
};
