"use strict";

import {
  cloneDeep,
  forEach,
  get,
  isNil,
  isObject,
  reduce
} from "lodash-es";

class FormDialogController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification) {
    this.DiasNotification = DiasNotification;
    this.errors = {};
    this.extras = $scope.extras;

    this.localParameters = cloneDeep(this.extras.parameterout);
    this.onChange = args => {
      forEach(args.currentModel, (value, key) => {
        if (this.localParameters[key].typ === "document" && isObject(value)) {
          this.localParameters[key].wert = value.pk;
        } else {
          this.localParameters[key].wert = value;
        }
      });
      $scope.$applyAsync(this.updateMissingRequired);
    };
    this.errorsLabel = {};
    Object.keys(this.extras.parameterout).forEach(key => {
      this.errorsLabel[key] = this.extras.parameterout[key].bez;
    });
    this.save = () => {
      return this.extras.finish(this.localParameters).then(
        result => {
          return $q.resolve(result);
        },
        errors => {
          if (this.paramout_keys && this.paramout_keys.length) {
            if (errors.data && errors.data.modul) {
              return $q.resolve(errors);
            }
            this.onError(errors);
            return $q.reject();
          }
          return $q.resolve(errors);
        }
      ).finally();
    };

    this.onError = errors => {
      DiasNotification.form.clear();
      DiasNotification.form.error(
        errors.data,
        "Bitte überprüfen Sie Ihre Eingaben",
        {},
        this.errorsLabel
      );
      this.errors = errors.data;
    };

    this.cancel = () => {
      return $q.resolve(false);
    };

    this.updateMissingRequired = () => {
      this.missingRequired = reduce(this.localParameters, (result, value) => {
        return result || (isNil(value.wert) && (value.required || get(value, "options.required", false)));
      }, false);
    };
    this.updateMissingRequired();
  }
}

export default FormDialogController;
