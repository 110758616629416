import FormElement from "../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTabs from "../../../global/components/PuxTabs/PuxTabs.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spinner from "../../../global/components/Spinner/Spinner.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import FoerderlandkarteFilterRightOptions from "./FoerderlandkarteFilterRightOptions";
import { initModelFromList } from "../../../global/functions/mappingForParameterToFormElement";
import {
  cloneDeep,
  concat,
  findIndex,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "FoerderlandkarteFilterRight",
  components: {
    FormElement,
    PuxButton,
    PuxTabs,
    PuxTranslate,
    Spinner,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    loadingMap: {
      type: Boolean,
    },
    mapParameters: {
      type: Object,
      required: true,
    },
    globalCount: {
      type: Number,
      required: true,
    },
    onSearch: {
      type: Function,
      required: true,
    },
    changeCenter: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      filtersGlobal: [],
      filtersLocal: [],
      countFiltered: undefined,
      loadingCount: undefined,
      timer: undefined,
      tabList: [],
      modelTyp: undefined,
      indexUmkreis: undefined,
    };
  },
  computed: {
    getUmkreis() {
      return this.model.umkreis * 5;
    },
  },
  created() {
    this.initModelTyp();
    this.initTabList();
    this.initFiltersGlobal();
    this.initFiltersLocal();
    this.initModel();
    this.onSearchLocal();
  },
  methods: {
    initFiltersGlobal() {
      const FILTERS = cloneDeep(FoerderlandkarteFilterRightOptions.list);
      this.indexUmkreis = findIndex(FILTERS, ["id", "umkreis"]);
      FILTERS[this.indexUmkreis].mix = this.initUmkreisFilterMaxAndMin("filter_umkreis_min");
      FILTERS[this.indexUmkreis].max = this.initUmkreisFilterMaxAndMin("filter_umkreis_max");
      this.filtersGlobal = FILTERS;
    },

    initFiltersLocal() {
      const FILTER = cloneDeep(this.mapParameters.typen[0].filter);
      if (!FILTER) {
        return;
      }
      const FILTERS_LOCAL = [];
      forEach(FILTER, item => {
        const filterLocal = {
          id: item.param,
          label: item.label,
          type: item.typ,
          view: "v-alt",
          default: item.default,
          data: item.values,
          keyLabel: "label",
          keyId: "key",
          collapse: true,
        };
        FILTERS_LOCAL.push(filterLocal);
      });
      this.filtersLocal = FILTERS_LOCAL;
    },

    initUmkreisFilterMaxAndMin(key) {
      return this.mapParameters[key] / this.mapParameters.filter_umkreis_step;
    },

    initModel() {
      this.model = initModelFromList({ list: concat(this.filtersGlobal, this.filtersLocal) });

      if (this.mapParameters.filter_umkreis_default) {
        this.model.umkreis = this.mapParameters.filter_umkreis_default / this.mapParameters.filter_umkreis_step;
      }
      if (this.mapParameters.filter_plz_default) {
        this.model.plz = this.mapParameters.filter_plz_default;
        this.filtersGlobal[this.indexUmkreis].disabled = false;
      }
    },

    initModelTyp() {
      this.modelTyp = this.mapParameters.typen[0].key;
    },

    initTabList() {
      const TAB_LIST = [];
      forEach(this.mapParameters.typen, (type, index) => {
        TAB_LIST.push({
          label: type.label,
          id: type.key,
          slotContent: type.key,
          active: index === 0,
        });
      });
      this.tabList = TAB_LIST;
    },

    onSearchLocal() {
      const MODEL = cloneDeep(this.model);
      MODEL.umkreis *= 5;
      MODEL.typ = this.modelTyp;
      this.loadCoordinatesByPLZ();
      this.onSearch({ model: MODEL });
    },

    onChange({ options, model }) {
      if (options.id === "plz") {
        this.filtersGlobal[this.indexUmkreis].disabled = !model;
      }
      if (options.type === "range" || options.type === "text") {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.loadCount();
        }, 500);
      } else {
        this.loadCount();
      }
    },

    loadCoordinatesByPLZ() {
      if (!this.model.plz) {
        return;
      }
      this.getHttp({
        url: "katalog/?key=plz_bundesland",
        urlParams: {
          pbu_plz__exact: this.model.plz
        }
      }).then(
        response => {
          if (response.length) {
            this.changeCenter({ center: response[0] });
          }
        }
      );
    },

    loadCount() {
      this.loadingCount = true;
      const URLPARAMS = cloneDeep(this.model);
      URLPARAMS.umkreis *= 5;
      URLPARAMS.typ = this.modelTyp;
      this.getHttp({
        url: "foerderlandkarte/?nocontent=1",
        urlParams: URLPARAMS
      }).then(
        response => {
          this.countFiltered = response.count;
          this.loadingCount = false;
        }
      );
    },

    changeTab({ tab }) {
      this.modelTyp = tab.id;
    },
  },
};
