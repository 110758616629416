import SmartTable from "../../../global/components/table/table.vue";

import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "RechtsformdokumenteListTable";

// @vue/component
export default {
  name: "RechtsformdokumenteList",
  components: {
    SmartTable,
  },
  mixins: [
    SyConfigMixin,
  ],
  props: {
    astPk: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: undefined,
        label: "_TXT_RECHTSFORMDOKUMENTE_LIST_",
        sortable: false,
        pagination: false,
        details: false,
        urlUpdate: false,
        showHeader: false,
        infoHeaderSlot: "rechtsformdokumenteInfoHeader",
        showColsConfig: false,
      },
      dokartConfig: {},
    };
  },
  created() {
    this.initData();
    this.initUrl();
  },
  methods: {
    initData() {
      this.dokartConfig = this.getSyConfigsValue("dokart_rechtsform");
    },

    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { astPk: this.astPk, dokarts: this.dokartConfig.dokarten } });
    },
  },
};
