import PersonalkostenPeriodenElement from "../../PersonalkostenPeriodenElement/PersonalkostenPeriodenElement.vue";
import PersonalkostenPeriodenDurchfuehrungszeitraumLine from "../../PersonalkostenPeriodenDurchfuehrungszeitraumLine/PersonalkostenPeriodenDurchfuehrungszeitraumLine.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import translate from "../../../../../global/directives/translate";
import {
  FilterCurrencyMixin,
} from "../../../../../global/mixins/FiltersMixin";

import {
  reduce,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenPeriodenTableInAntragTr",
  components: {
    PersonalkostenPeriodenElement,
    PersonalkostenPeriodenDurchfuehrungszeitraumLine,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
  ],
  props: {
    position: {
      type: Object,
      required: true,
    },
    positionenLength: {
      type: Number,
      required: true,
    },
    positionIndex: {
      type: Number,
      required: true,
    },
    perioden: {
      type: Array,
      required: true,
    },
    periodenColsWidth: {
      type: Array,
      required: true,
    },
    periodenList: {
      type: Object,
      required: true,
    },
    statusDetailsShow: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    "toggleDetails",
  ],
  computed: {
    sumBody() {
      return reduce(this.position.argument.perioden, (sum, period) => {
        return sum + +period.gehalt;
      }, 0);
    },

    sumBodyFiltered() {
      return this.filterCurrency(this.sumBody);
    },

    tooltipToggle() {
      if (this.statusDetailsShow) {
        return "_TXT_DETAILS_VERBERGEN_";
      }
      return "_TXT_DETAILS_ZEIGEN_";
    },

    iconsToggle() {
      return this.statusDetailsShow ? "chevron-up" : "chevron-down";
    },

    buttonId() {
      return `toggle_details_positionen_${ this.positionIndex }`;
    },

    idDetails() {
      return `details_positionen_${ this.positionIndex }`;
    },
  },
  methods: {
    toggleDetailsLocal() {
      this.$emit("toggleDetails", this.positionIndex);
    },
  },
};
