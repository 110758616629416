"use strict";
import _ from "lodash";

import template from "./details.jade";

import regelEditTemplate from "./regel.edit.jade";
import regelEditController from "./regel.edit.controller";
import modulEditController from "./modul.edit.controller";
import modulEditTemplate from "./modul.edit.jade";
import userdefaultReadonlyController from "./userdefault.readonly.controller";
import regelInstrumentAssignTemplate from "./instrumentzuordnung.edit.jade";
import regelInstrumentAssignController from "./instrumentzuordnung.edit.controller";
import fgruppenEditController from "./fgruppen.edit.controller";
import fgruppenEditTemplate from "./fgruppen.edit.jade";
import editrechteEditController from "./editrechte.edit.controller";
import editrechteEditTemplate from "./editrechte.edit.jade";
import {
  MAPPING,
} from "../../../vue/client/vue/components/Geschaeftsregel/Module/Module";
import {
  assign,
  cloneDeep,
  pick,
} from "lodash-es";
import {
  initModelFromList,
  toFormElementFromParameter,
} from "../../../vue/client/vue/global/functions/mappingForParameterToFormElement";
import {
  EventBus,
} from "../../../vue/client/vue/global/functions/event-bus";

class FoerderregelnDetailsController {
  /*@ngInject*/
  constructor(
    $q,
    $state,
    $scope,
    $stateParams,
    ParameterUtils,
    AuthService,
    Foerderregeln,
    DiasNotification,
    $compile,
    $rootScope,
    $timeout,
    i18n
  ) {
    this.MAPPING = MAPPING;
    this.loading = true;
    this.$state = $state;
    this.$scope = $scope;
    this.$q = $q;
    this.$compile = $compile;
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;
    this.DiasNotification = DiasNotification;
    this.AuthService = AuthService;
    this.Foerderregeln = Foerderregeln;
    this.ParameterUtils = ParameterUtils;
    this.$timeout = $timeout;
    this.gettext = i18n.gettext;

    this.regelEditTemplate = regelEditTemplate;
    this.regelEditController = regelEditController;
    this.modulEditController = modulEditController;
    this.modulEditTemplate = modulEditTemplate;
    this.userdefaultReadonlyController = userdefaultReadonlyController;
    this.regelInstrumentAssignTemplate = regelInstrumentAssignTemplate;
    this.regelInstrumentAssignController = regelInstrumentAssignController;
    this.fgruppenEditController = fgruppenEditController;
    this.fgruppenEditTemplate = fgruppenEditTemplate;
    this.editrechteEditController = editrechteEditController;
    this.editrechteEditTemplate = editrechteEditTemplate;
    this.updateModuleFromVue = this.updateModuleFromVue.bind(this);
    this.updateRegelFromVue = this.updateRegelFromVue.bind(this);
    this.funktionsgruppen = [];
    this.saveCallbackChoices = {};
    this.saveCallbackLabel = undefined;
    this.saveCallbackAction = undefined;


    this.loadRegel = () => {
      this.loading = true;
      const fgruppenDefer = this.$q.defer();
      const regelPromise = this.Foerderregeln.one(this.$stateParams.id).get().then(
        response => {
          this.init(response);
          this.updateRegelFromVue({ regel: response, statusFirst: true });
          this.regel.funktionsgruppen.getList().then(
            response => {
              this.funktionsgruppen = response;
              this.funktionsgruppen.gruppierung = this.funktionsgruppen.splitIntoGroups(2);
              fgruppenDefer.resolve(response);
            },
            fgruppenDefer.reject
          );
        }
      );

      this.$q.all([fgruppenDefer.promise, regelPromise]).finally(
        () => {
          this.baseUrl = this.getBaseUrl();
          this.loading = false;
          this.initParameterTyp();
          this.initWatch();
        }
      );
    };

    this.loadRegel();
  }

  getBaseUrl() {
    return `${ this.regel.getRestangularUrl().replace("/api/", "") }/defaultwerte_aendern/`;
  }

  updateModuleFromVue({ response }) {
    this.$timeout(() => {
      const REGEL = cloneDeep(this.regel);
      REGEL.userdefault = response;
      this.regel = REGEL;
    });
  }

  initParameterTyp() {
    this.parameterTyp = this.regel.re_argument &&
                        this.regel.re_argument.eingabe_felder &&
                        this.regel.re_argument.eingabe_felder.typ ?
                          this.regel.re_argument.eingabe_felder.typ :
                          null;
  }

  initWatch() {
    this.$scope.$watch("vm.regel", () => {
      if ((this.parameterTyp && _.get(this.regel, "re_argument.eingabe_felder.typ") !== this.parameterTyp) ||
          _.get(this.regel, "re_savecallback") !== this.saveCallbackAction
      ) {
        this.loadRegel();
      }
    }, true);
  }


  init(regel) {
    this.regel = regel;
    this.regel.param_keys = this.ParameterUtils.get_ordered_keys(this.regel.re_argument);
    this.regel.re_argument = this.ParameterUtils.init_parameter_in(this.regel.re_argument);
    this.canEdit = this.AuthService.syncHasPerm("foerderregel.update", regel.user_permissions);
    this.saveCallbackChoices = this.regel.savecallback_choices || {};
    this.saveCallbackLabel = _.get(this.saveCallbackChoices, this.regel.re_savecallback, "Keine");
    this.saveCallbackAction = this.regel.re_savecallback || null;
    this.refreshModul();
  }

  copyRegel() {
    return this.regel.create_copy().then(
      response => {
        this.DiasNotification.page.success("Kopie der Geschäftsregel erfolgreich erstellt");
        this.$state.go("root.foerderregeln.detail", { id: response.pk });
      },
      () => this.DiasNotification.page.error("Fehler beim Kopieren der Geschäftsregel")
    );
  }

  deleteRegel() {
    return this.regel.remove().then(
      () => {
        this.DiasNotification.page.success("Geschäftsregel " + this.regel.re_kbez + " erfolgreich gelöscht");
        this.$state.go("root.foerderregeln");
      },
      () => this.DiasNotification.page.error("Fehler beim Löschen der Geschäftsregel")
    );
  }

  reloadRegel() {
    this.loadRegel();
  }

  getRechtBez(regel, recht) {
    let bez = "Unbekannt: " + recht;
    angular.forEach(regel.rechte_choices, item => {
      if (item[0] === recht) {
        bez = item[1];
        return;
      }
    });
    return bez;
  }

  refreshModul() {
    if (!this.regel.modul_obj.sm_userdefault ||
      !this.regel.userdefault.modulname ||
      this.MAPPING[this.regel.userdefault.modulname]) {
      return;
    }
    this.regel.userdefault.prefix = "re";
    this.regel.userdefault.getParameter = () => {
      const parameter = {};
      for (const key in this.regel.re_argument) {
        parameter[key] = this.regel.re_argument[key].wert;
      }
      return parameter;
    };
    this.regel.userdefault.save = userInput => {
      return this.regel.customPUT(userInput, "defaultwerte_aendern");
    };
    const scope = this.$rootScope.$new();
    scope.modul = this.regel.userdefault;

    const tpl = this.$compile(
      "<div foerdermodule module=\"modul\" idx=\"\"></div>"
    )(scope);
    const container = angular.element($("#userdefault-container"));
    container.children().remove();
    container.append(tpl);
  }

  getDeleteTitle() {
    if (!this.regel.is_used) {
      return "Geschäftsregel löschen";
    }
    return this.regel.regelsaetze.length ?
      "Die Geschäftsregel kann nicht gelöscht werden, weil sie in mindesten einem Förderangebot verwendet wird." :
      "Die Geschäftsregel kann nicht gelöscht werden, weil sie verwendet wird";
  }

  updateRegelFromVue({ regelLocal, statusFirst }) {
    this.$timeout(() => {
      this.listConfig = toFormElementFromParameter({
        obj: regelLocal.re_argument,
        showLabel: true,
        staticOptionsForType: {
          dyn_tabelle_objektkatalog_data: {
            dependency: "spalten",
          },
          dyn_tabelle_spaltenauswahl_radio: {
            dependency: "spalten",
          },
        },
      }).list;
      this.modelConfig = initModelFromList({ list: this.listConfig });
      if (!statusFirst) {
        const REGEL = cloneDeep(this.regel);
        assign(REGEL, pick(regelLocal, ["re_argument", "modul", "modul_bez", "modul_obj", "userdefault"]));
        this.regel = REGEL;
        EventBus.$emit("updateVorbelegung");
      }
    });
  }
}

export default {
  template: template(),
  controller: FoerderregelnDetailsController,
  controllerAs: "vm"
};
