"use strict";

import angular from "angular";

class FoerdermoduleController {
  /*@ngInject*/
  constructor($element, $scope, $compile, FoerdermoduleRegistry) {
    this.name = "FoerdermoduleController";
    this.FoerdermoduleRegistry = FoerdermoduleRegistry;
    this.$compile = $compile;
    this.$element = $element;
    this.$scope = $scope;
    this.snapshot = $scope.vm.snapshot;
    this.loading = true;
    this.group = this.group !== undefined ? this.group : "default_grp";
    this.readonly = this.readonly !== undefined ? this.readonly : false;
    this.hasBaseEditPerm = this.hasBaseEditPerm === undefined ? !this.readonly : this.hasBaseEditPerm;
    this.create();
  }

  create() {
    let template = "";
    if (angular.isArray(this.module)) {
      if (this.module.length === 0) {
        return;
      }
      // einfache_foerderregel
      let index = 0;
      let einfachIndex = -1;
      let temp;
      if (this.module.length > 0) {
        for (let j = 0; j < this.module.length; j++) {
          if (this.module[j].modulname === "einfache_foerderregel") {
            einfachIndex++;
            temp = this.buildTemplate(this.module[j], einfachIndex, this.group, "vm.readonly", "vm.hasBaseEditPerm", "vm.module[" + j + "]", "vm.obj", () => {}, this.kontext);
            if (temp === undefined) {
              return;
            }
            template += temp;
          }
        }
        for (let i = 0; i < this.module.length; i++) {
          // einfache_foerderregel
          if (this.module[i].modulname !== "einfache_foerderregel") {
            index++;
            temp = this.buildTemplate(this.module[i], this.buildIndex(this.idx, index, this.nummerierungausblenden), this.group, "vm.readonly", "vm.hasBaseEditPerm", "vm.module[" + i + "]", "vm.obj", () => {}, this.kontext);
            if (temp === undefined) {
              return;
            }
            template += temp;
          }
        }
      }
    } else {
      template = this.buildTemplate(this.module, this.idx, this.group, "vm.readonly", "vm.hasBaseEditPerm", "vm.module", "vm.obj", () => {}, this.kontext);
      if (template === undefined) {
        return;
      }
    }
    const el = this.$compile(template)(this.$scope);
    this.$element.append(el);
  }

  buildTemplateVue(obj, idx, group, readonlyPropertyName, hasBaseEditPermPropertyName, regelPropertyName, objPropertyName, rejectFn, kontext) { // eslint-disable-line
    const BASE_URL = this.setBaseUrl(); // eslint-disable-line
    return `<div>Aloha</div>`;
    // return `
    //   <div
    //     vueinit
    //     props="{ module: ${ regelPropertyName }, updateModule: vm.savecallback, obj: vm.obj, notizkey: vm.notizkey }">
    //     <div id="aloha">
    //       <geschaeftsregel-modul
    //         v-bind:base-url="'${ BASE_URL }'"
    //         v-bind:module="propsAngular.module"
    //         v-bind:update-module="propsAngular.updateModule"
    //         v-bind:obj="propsAngular.obj"
    //         v-bind:idx="'${ idx }'"
    //         v-bind:notizen-key="propsAngular.notizkey"
    //       >
    //     </geschaeftsregel-modul>
    //     </div>
    //
    //   </div>
    // `;
  }

  buildTemplate(obj, idx, group, readonlyPropertyName, hasBaseEditPermPropertyName, regelPropertyName, objPropertyName, rejectFn, kontext) {
    let errMsg;
    if (obj === undefined || obj.regel === undefined) {
      errMsg = "Angegebene Eigenschaft " + regelPropertyName + " am Scope hat keine regel.";
      console.error(errMsg, obj);
      rejectFn(errMsg);
      return;
    }
    const modul = this.FoerdermoduleRegistry.getModule(obj.modulname);
    if (modul === undefined) {
      errMsg = "Modul " + obj.modulname + " für die Regel " + obj.regel[obj.prefix + "_kbez"] + "(" + obj.regel.pk + ") nicht gefunden";
      console.error(errMsg);
      rejectFn(errMsg);
      return;
    }
    return "<div " + modul + " modul='" + regelPropertyName + "' obj='" + objPropertyName + "' idx='" + idx + "' group='" + group + "' readonly='" + readonlyPropertyName + "' has-base-edit-perm='" + hasBaseEditPermPropertyName + "' " + (this.infoprovider ? "infoprovider='vm.infoprovider'" : "") + " " + (this.savecallback ? "savecallback='vm.savecallback()'" : "") + "snapshot='vm.snapshot' snapshotdiff='vm.snapshotdiff' astdiffstatsus='vm.astdiffstatsus' update-status='vm.updateStatus' dokumente-condition='vm.dokumenteCondition' dokumentnotizen='vm.dokumentnotizen' notizoptions='vm.notizoptions' notizen='vm.notizen[\"" + obj.regel.pk + "\"]' notizkey='vm.notizkey' id='" + obj.regel.regel_nummer + "' kontext='" + kontext + "'></div>";
  }

  buildIndex(idx, i, nummerierungausblenden) {
    let idxStr = "";
    if (angular.isUndefined(idx) || nummerierungausblenden) {
      return "";
    } else if (idx.length > 0) {
      idxStr = idx + ".";
    }
    return idxStr + i;
  }

  setBaseUrl() {
    let baseUrl = this.obj.getRestangularUrl().replace("/api", "");
    if (baseUrl[baseUrl.length - 1] !== "/") {
      baseUrl += "/";
    }
    return baseUrl;
  }
}

export default () => {
  return {
    restrict: "A",
    scope: {
      module: "<",
      obj: "<",
      readonly: "<?",
      hasBaseEditPerm: "<?",
      kontext: "@?",
      group: "@",
      idx: "@",
      savecallback: "&?",
      infoprovider: "<",
      snapshot: "<",
      snapshotdiff: "=",
      astdiffstatsus: "<",
      dokumenteCondition: "<?",
      updateStatus: "<?",
      dokumentnotizen: "<?",
      notizen: "<?",
      notizkey: "<?",
      notizoptions: "<?",
      nummerierungausblenden: "="
    },
    template: "",
    controller: FoerdermoduleController,
    controllerAs: "vm",
    bindToController: true
  };
};
