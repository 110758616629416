import FoerderorgOneAnsprechpartner from "./FoerderorgOneAnsprechpartner/FoerderorgOneAnsprechpartner.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";

import { cloneDeep, isNil } from "lodash-es";

// @vue/component
export default {
  name: "FoerderorgAnsprechpartnerList",
  components: {
    FoerderorgOneAnsprechpartner,
  },
  props: {
    objekt: {
      type: Object,
      required: true,
      info: "Objekt an dem die Ansprechpartner hinterlegt werden",
    },
    baseUrl: {
      type: String,
      required: true,
      info: "URL für den Ansprechpartner-Endpunkt",
    },
    ansprechpartner: {
      type: Array,
      required: false,
      default: () => [],
      info: "Optionale initiale Liste der Ansprechpartner",
    }
  },
  setup() {
    const { getHttp } = HttpAPI();

    return { getHttp };
  },
  data() {
    return {
      lokalAnsprechpartner: undefined,
      loading: undefined,
    };
  },
  watch: {
    ansprechpartner: {
      immediate: true,
      handler(val) {
        this.lokalAnsprechpartner = cloneDeep(val);
      },
    },
  },
  created() {
    if (isNil(this.ansprechpartner)) {
      this.loadAnsprechpartner();
    } else {
      this.lokalAnsprechpartner = cloneDeep(this.ansprechpartner);
    }
  },
  methods: {
    loadAnsprechpartner() {
      this.loading = true;
      this.getHttp({ url: `${ this.baseUrl }/ansprechpartner` })
        .then(response => this.lokalAnsprechpartner = response)
        .finally(() => this.loading = false);
    },
  },
};
