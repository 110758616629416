import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import {
  cloneDeep,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragVerknuepfungModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    antragPk: {
      type: String,
      required: true,
    },
    antragNummer: {
      type: String,
      required: true,
    },
    antragTitel: {
      type: String,
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      model: {
        master: undefined,
        verbunden: undefined
      },
      infoIText: undefined,
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    baseUrl() {
      return `foerderantraege/${ this.antragPk }/verbundene_antraege/`;
    },

    options() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "verbunden",
            type: "select",
            label: "_LBL_ANTRAG_VERKNUEPFUNG_MODAL_CREATE_VERBUNDEN_",
            translate: true,
            url: `${ this.baseUrl }verknuepfbare/`,
            urlParams: {
              limit: 1000
            },
            keyLabelCallback: ({ item }) => `${ item.a_nr } - ${ isNil(item.a_titel) ? window.gettext("_TXT_ANTRAG_VERKNUEPFUNG_MODAL_CREATE_A_TITEL_NULL_") : item.a_titel }`,
            searchGlobal: true,
            searchParameter: "suche",
            textsSearch: window.gettext("_TXT_ANTRAG_VERKNUEPFUNG_MODAL_CREATE_VERBUNDEN_SEARCH_PLACEHOLDER_"),
            required: true,
            keyId: "pk",
            view: "v",
          },
        ],
      };
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.model.master = this.antragPk;
      const INFO_TRANSLATION = "_HTML_ANTRAG_VERKNUEPFUNG_MODAL_CREATE_INFO_";
      const INFO_TRANSLATED = window.gettext(INFO_TRANSLATION);
      if (INFO_TRANSLATION !== INFO_TRANSLATED) {
        this.infoIText = INFO_TRANSLATED;
      }
    },

    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.postHttp({ url: this.baseUrl, data }).then(
        response => {
          this.addNotification({ text: "_MSG_ANTRAG_VERKNUEPFUNG_MODAL_CREATE_SUCCESS_" });
          this.close(response);
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
