import angular from "angular";

import Models from "./models";
import Services from "./services";

const statusfolgen = angular.module("dias.statusfolgen", [
  Models.name,
  Services.name
])
;

export default statusfolgen;
