"use strict";

import angular from "angular";
import diasLoading from "dias/core/loading";
import models from "./models/";
import parameters from "./parameters";

import TextbausteinComponent from "./textbaustein.component";

const textbausteine_modul = angular.module("dias.textbausteine", [
  diasLoading.name,
  models.name,
  parameters.name,
])

  .component("diasTextbaustein", TextbausteinComponent)
;

export default textbausteine_modul;
