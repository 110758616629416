import {
  computed,
  ref,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

export default function AntragFoerderdokumenteAPI({ antragUrl }) {
  const foerderdokumente = ref([]);
  const antragFoerderdokumenteUrl = computed(() => {
    return `${ antragUrl.value }foerderdokumente/`;
  });
  const {
    getListHttp,
  } = HttpAPI();
  const loadFoerderdokumente = () => {
    getListHttp({
      url: antragFoerderdokumenteUrl.value,
    }).then(
      response => {
        foerderdokumente.value = response;
      }
    );
  };

  return {
    foerderdokumente,
    loadFoerderdokumente,
  };
}
