"use strict";

import BaseModel from "dias/core/models/baseModel";


class Teamroutingzustaendige extends BaseModel {
  constructor() {
    super("teamroutingzustaendige");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.createZustaendige = data => {
      return collection.customPOST(data);
    };

    collection.updateAdminZustaendige = (zustaendigPk, data) => {
      return collection.one(zustaendigPk).customPUT(data);
    };

    collection.updateZustaendige = data => {
      return collection.one("update_zustaendige").customPUT(data);
    };

    return collection;
  }

  configRestangular(RestangularConfigurer) {
    RestangularConfigurer.setDefaultHttpFields({ cache: true });
  }
}

export default Teamroutingzustaendige;
