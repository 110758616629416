import {
  onBeforeUnmount,
  toRef,
} from "vue";

import {
  EventBus,
} from "../../../../../../global/functions/event-bus";

export default function ModuleEventBusAPI(props, {
  reloadModule,
  sitzungModuleEventName = "",
}) {
  const module = toRef(props, "module");
  const onEvent = ({ groups = [] }) => {
    let anyInGroup = false;
    for (let i = 0; i < module.value.modulgruppen.length; i++) {
      if (groups.indexOf(module.value.modulgruppen[i]) >= 0) {
        anyInGroup = true;
        break;
      }
    }
    if (anyInGroup) {
      reloadModule();
    }
  };

  onBeforeUnmount(() => {
    EventBus.$off(sitzungModuleEventName, onEvent);
  });

  return {
    onEvent,
  };
}
