"use strict";

import BaseModel from "dias/core/models/baseModel";


class PasswortZuruecksetzen extends BaseModel {
  constructor() {
    super("auth/passwort_zuruecksetzen/");
  }
}

export default PasswortZuruecksetzen;
