import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";
import ModalCreateOrUpdate from "./ModalCreateOrUpdate/ModalCreateOrUpdate.vue";
import translate from "../../../../../global/directives/translate";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeMehrfachauswahl",
  components: {
    ModalCreateOrUpdate,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      statusModal: undefined,
      currentModel: undefined,
      currentIndex: undefined,
      currentParentIndex: undefined,
      confirmShow: undefined,
      confirmOptions: undefined,
    };
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../global/components/Modal/Modal.vue").default;
  },
  methods: {
    openModalCreate() {
      this.statusModal = true;
    },
    openModalUpdate({ parentIndex, index, item }) {
      this.currentModel = item;
      this.currentIndex = index;
      this.currentParentIndex = parentIndex;
      this.statusModal = true;
    },
    closeModal({ model } = {}) {
      if (model) {
        this.onInput_mixin({ value: model });
      }
      this.statusModal = undefined;
      this.currentModel = undefined;
      this.currentIndex = undefined;
      this.currentParentIndex = undefined;
    },

    moveUpAntwort(index) {
      if (index === 0) {
        return;
      }
      const MODEL = cloneDeep(this.model);

      const ELEM = MODEL[index];
      MODEL.splice(index, 1);
      MODEL.splice(index - 1, 0, ELEM);
      this.onInput_mixin({ value: MODEL });
    },

    moveUpUnterpunkt(parentIndex, index) {
      if (index === 0) {
        return;
      }
      const MODEL = cloneDeep(this.model);

      const ELEM = MODEL[parentIndex].children[index];
      MODEL[parentIndex].children.splice(index, 1);
      MODEL[parentIndex].children.splice(index - 1, 0, ELEM);
      this.onInput_mixin({ value: MODEL });
    },

    moveDownAntwort(index) {
      if (index >= this.model.length - 1) {
        return;
      }
      const MODEL = cloneDeep(this.model);
      const ELEM = MODEL[index];
      MODEL.splice(index, 1);
      MODEL.splice(index + 1, 0, ELEM);
      this.onInput_mixin({ value: MODEL });
    },

    moveDownUnterpunkt(parentIndex, index) {
      if (index >= this.model[parentIndex].children.length - 1) {
        return;
      }
      const MODEL = cloneDeep(this.model);
      const ELEM = MODEL[parentIndex].children[index];
      MODEL[parentIndex].children.splice(index, 1);
      MODEL[parentIndex].children.splice(index + 1, 0, ELEM);
      this.onInput_mixin({ value: MODEL });
    },

    openConfirmDelete(index) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "Antwortmöglichkeit entfernen",
        msg: "<p>Sind Sie sicher, dass sie die Antwortmöglichkeit von dieser Regel entfernen wollen?</p>",
        okLabel: "Fortfahren",
        okCallback: () => this.deleteAntwort(index),
        cancelCallback: this.closeConfirmDelete,
        loading: false,
      };
      this.confirmShow = true;
    },

    openConfirmDeleteUnterpunkt(parentIndex, index) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "Unter-Antwortmöglichkeit entfernen",
        msg: "<p>Sind Sie sicher, dass sie die Unter-Antwortmöglichkeit von dieser Regel entfernen wollen?</p>",
        okLabel: "Fortfahren",
        okCallback: () => this.deleteUnterpunkt(parentIndex, index),
        cancelCallback: this.closeConfirmDelete,
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteAntwort(index) {
      const MODEL = cloneDeep(this.model);
      MODEL.splice(index, 1);
      this.onInput_mixin({ value: MODEL });
      this.closeConfirmDelete();
    },

    deleteUnterpunkt(parentIndex, index) {
      const MODEL = cloneDeep(this.model);
      MODEL[parentIndex].children.splice(index, 1);
      this.onInput_mixin({ value: MODEL });
      this.closeConfirmDelete();
    },

    closeConfirmDelete() {
      this.confirmShow = false;
    },
  },
};
