"use strict";

import BaseModel from "dias/core/models/baseModel";

class Anonymisierungsplaene extends BaseModel {
  constructor() {
    super("anonymisierungsplaene");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.setKonfig = data => collection.customPOST(data, "set_konfig");

    return collection;
  }
}

export default Anonymisierungsplaene;
