"use strict";

import BaseModel from "./baseModel";

class KRechtsform extends BaseModel {
  constructor() {
    super("rechtsformen");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.getFoerderberechtigt = flag => {
      return collection.getList(
        { foerderberechtigt: flag && "t" || "f" }
      );
    };
    return collection;
  }
}

export default KRechtsform;
