"use strict";

import BaseFunction from "../baseFunction.js";

class DownloadAfterConfirmFunction extends BaseFunction {
  /*@ngInject*/
  constructor(DiasNotification, $q, diasConfirmDialog, workflowfolge, obj, $window, extra) {
    super(DiasNotification, $q, workflowfolge, obj, extra);
    this.diasConfirmDialog = diasConfirmDialog;
    this.window = $window;
  }

  start() {
    const parameterin = this.prepareParameterIn();
    return this.diasConfirmDialog({
      titleTemplate: parameterin.title,
      contentTemplate: parameterin.msg,
      type: this.workflowfolge.ausgabe_typ,
      okIcon: this.workflowfolge.ausgabe_icon,
      okLabel: this.workflowfolge.wfo_kbez,
      okCallback: () => this.finish({}),
      cancelCallback: () => this.cancel()
    });
  }

  onServerSuccess() {
    const parameterin = this.prepareParameterIn();
    let fileURL = parameterin.url.replace("{UUID}", this.obj.obj_id);
    fileURL = fileURL.replace("{parentUUID}", this.obj.obj_parent_id);
    const aLink = this.window.document.createElement("a");
    aLink.download = "fvb_pdf";
    aLink.href = fileURL;
    aLink.target = "_self";
    const eventClick = this.window.document.createEvent("MouseEvents");
    eventClick.initEvent(
      "click",
      true,
      true,
      this.window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    aLink.dispatchEvent(eventClick);
    super.onServerSuccess();
  }
}

export default DownloadAfterConfirmFunction;
