"use strict";

import template from "./detail.jade";

class DetailController {
	/*@ngInject*/
  constructor(
    $scope, 
    $state, 
    Foerderantrag, 
    Sitzungen, 
    Auszahlung,
    Antragstellerorganisationen, 
    DiasNotification, 
    Vertrag,
    Auszahlungsplan, 
    Sammelvertrag, 
    Projektberichte, 
    Gutachten
  ) {
    this.row = $scope.vm.docpk;
    this.editdetail = $scope.vm.editdetail;
    this.statusEdit = false;
    this.model = {};
    this.loading = true;
    this.modelMap = {
      foerderantrag: Foerderantrag,
      sitzung: Sitzungen,
      antragstellerorganisation: Antragstellerorganisationen,
      auszahlung: Auszahlung,
      auszahlungsplan: Auszahlungsplan,
      vertrag: Vertrag,
      sammelvertrag: Sammelvertrag,
      projektbericht: Projektberichte,
      gutachten: Gutachten,
    };

    const onInitError = () => {
      DiasNotification.page.error("Fehler beim Laden der Details");
      $state.go("root.aufgabe");
    };
    this.modelMap[this.row.herkunft].one(this.row.obj_id).get().then(
      result => {
        this.object = result;
        this.loading = false;
      },
      onInitError
    );

		// this.error = {};
  }

  editAufgabe(index) {
    if (index !== undefined) {
      this.rowIndex = index;
    }
    if (this.statusEdit) {
      this.editdetail[this.rowIndex] = false;
      this.statusEdit = false;
      this.deleteErrors();
    } else {
      this.editdetail[this.rowIndex] = true;
      this.statusEdit = true;
      this.model = {
        frist_bis: this.row.frist_bis,
        fristverlaengerung_bis: this.row.fristverlaengerung_bis,
        ausblenden_bis: this.row.ausblenden_bis,
        zustaendig: this.row.zustaendig,
        prioritaet: this.row.prioritaet,
        status: this.row.status,
        text: this.row.text
      };
    }
  }

  validateAufgabe() {
    this.saveAufgabe();
		/* if ((this.model.frist_bis < this.model.fristverlaengerung_bis) && (this.model.fristverlaengerung_bis < this.model.ausblenden_bis)) {
			this.saveAufgabe();
		} else {
			this.error = {};
			if (this.model.frist_bis >= this.model.fristverlaengerung_bis) {
				this.error.fristverlaengerung_bis = "Dieses Feld ist erforderlich.";
			}
			if (this.model.fristverlaengerung_bis >= this.model.ausblenden_bis) {
				this.error.ausblenden_bis = "Dieses Feld ist erforderlich.";
			}
		}*/
  }

  saveAufgabe() {
    this.editAufgabe();
  }

  deleteErrors() {
    if (this.error) {
      delete this.error;
    }
  }
}

export default {
  template: template(),
  controller: DetailController,
  controllerAs: "vm",
  bindings: {
    docpk: "<",
    editdetail: "="
  }
};
