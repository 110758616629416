"use strict";

import diasLoading from "dias/core/loading";

import models from "./models";
import detail from "./detail.component";
import component from "./tasklog.component";
import progressComponent from "./tasklog.progress.component";
import statusComponent from "./tasklog.status.component";

const mod = angular.module("dias.tasklog", [
  diasLoading.name,
  models.name,
])


  .config($stateProvider => {
    $stateProvider
      .state("root.tasklog", {
        url: `/hintergrundaufgaben/?
            user_cookie
      `,
        reloadOnSearch: false,
        views: {
          "main@root": {
            template: "<div vueinit><vue-task-log-list></vue-task-log-list></div>",
          }
        },
        data: {
          permissions: "tasklog.list"
        }
      })

      .state("root.tasklog.detail", {
        url: ":id/",
        views: {
          "main@root": {
            template: "<task-log-detail></task-log-detail>"
          }
        },
      });
  })

  .component("taskLogDetail", detail)
  .component("diasTaskLog", component)
  .component("diasTaskLogProgress", progressComponent)
  .component("diasTaskLogStatus", statusComponent)
;

export default mod;
