"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import diasAuthService from "dias/auth/services";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";
import diasParameter from "dias/foerderregeln/parameter";

import models from "./models";

const fibuModule = angular.module("dias.fibu", [
  uiRouter,
  models.name,
  diasAuthService.name,
  diasParameter.name,
  diasTable.name,
  diasLoading.name,
  diasNotification.name
]);

fibuModule.config($stateProvider => {
  $stateProvider

    // Sitzungsantrag-Liste
    .state("root.fibujournal", {
      url: "/fibujournal/?" +
           "slo_kontext&slo_request&slo_response&slo_fehler",
      views: {
        "main@root": {
          template: "<div vueinit><vue-fi-bu-journal-list></vue-fi-bu-journal-list></div>",
        }
      },
      data: {
        permissions: ["fibu.view"]
      },
    })
  ;
});

export default fibuModule;
