import Alert from "../../global/components/Alert/Alert.vue";

import translate from "../../global/directives/translate";

import UserMixin from "../../global/mixins/UserMixin";

import SyConfigAPI from "../../global/compositionAPI/SyConfigAPI";

import {
  EventBus,
} from "../../global/functions/event-bus";
import {
  setHeightAsideAndMenu,
  setStyleTopForFixedVertical,
} from "../../global/functions/utilsDOM";
import {
  isFunction,
} from "lodash-es";

const COOKIE_NAME_NOTICE = "proandi_notice";
const COOKIE_NAME_COOKIE = "proandi_cookie";

// @vue/component
export default {
  name: "TheMessagePanel",
  components: {
    Alert,
  },
  directives: {
    translate,
  },
  mixins: [
    UserMixin,
  ],
  setup() {
    const {
      getSyConfigsValue,
    } = SyConfigAPI();

    return {
      getSyConfigsValue,
    };
  },
  data() {
    return {
      noticeText: window.PROANDISiteNotice,
      noticeBoxShow: undefined,
      cookieBoxShow: undefined,
      cookieText: undefined,
      statusCookieBoxShow: undefined,
    };
  },
  created() {
    this.setEventListener();
    this.getConfig();
    this.checkCookieForNoticeBox();
    this.checkCookieForCookieBox();
    this.setPaddingTopForSite();
  },
  beforeUnmount() {
    document.removeEventListener("updateProfile", this.updateProfileLocal);
  },
  methods: {
    setEventListener() {
      document.addEventListener("updateProfile", this.updateProfileLocal, false);
    },

    updateProfileLocal({ user }) {
      const USER_LOCAL = isFunction(user.plain) ? user.plain() : user;
      this.MUT_SET_ME(USER_LOCAL);
      EventBus.$emit("updateProfile", { user: USER_LOCAL });
    },

    getConfig() {
      const CONFIG = this.getSyConfigsValue("hinweisen_oben") || {};
      this.cookieText = CONFIG.cookie_text || "Diese Anwendung verwendet Cookies, um Ihnen den bestmöglichen  Service zu bieten. Wenn Sie weitersurfen, stimmen Sie der Nutzung von Cookies zu.";
      this.statusCookieBoxShow = CONFIG.cookie_status_show;
    },

    checkCookieForNoticeBox() {
      this.noticeBoxShow = !this.$cookies.get(COOKIE_NAME_NOTICE);
    },

    checkCookieForCookieBox() {
      this.cookieBoxShow = !this.$cookies.get(COOKIE_NAME_COOKIE);
    },

    setPaddingTopForSite() {
      setTimeout(() => {
        $("#printableArea").css("padding-top", $("#navbar-fixed-top").outerHeight(true));
        $("#wrapper").css("padding-top", $("#message_box_top").outerHeight(true));
        setHeightAsideAndMenu();
        setStyleTopForFixedVertical();
      });
    },

    closeNoticeBox() {
      this.noticeBoxShow = false;
      this.$cookies.set(COOKIE_NAME_NOTICE, true);
      this.setPaddingTopForSite();
    },

    closeCookieBox() {
      this.cookieBoxShow = false;
      this.$cookies.set(COOKIE_NAME_COOKIE, true);
      this.setPaddingTopForSite();
    },
  },
};
