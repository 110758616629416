"use strict";

import template from "./registrierung-abschliessen.jade";

class RegistrationController {
  /*@ngInject*/
  constructor($state, $stateParams, DiasNotification, RegistrierungAbschliessen, PasswortZuruecksetzen) {
    this.loading = true;
    this.model = {};
    this.errorsLabel = {
      new_password1: "Passwort: ",
      new_password2: "Passwort (Wiederholung): ",
    };

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.DiasNotification = DiasNotification;
    this.PasswortZuruecksetzen = PasswortZuruecksetzen;
    this.RegistrierungAbschliessen = RegistrierungAbschliessen;

    this.initDefaultValues();
    this.checkUrl();
  }

  initDefaultValues() {
    this.regBody = this.regBody || {};
    this.regBody.class = this.regBody.class || "col-12";
    this.regBody.success = this.regBody.success || `<p>Bitte geben Sie ein Passwort ein, um Ihre Registrierung abzuschließen.</p>
                                                    <div class="alert alert-info">
                                                      <p>
                                                        <strong>Hinweis zur Passwortvorgabe</strong>
                                                      </p>
                                                      <p>Für Passwörter gelten die folgenden Regeln:</p>
                                                      <ul>
                                                          <li>Ihr Passwort muss mindestens 8 und darf maximal 24 Zeichen lang sein.</li>
                                                          <li>Es muss mindestens einen Buchstaben und eine Zahl enthalten.</li>
                                                          <li>Folgende Sonderzeichen können verwendet werden:<br>
                                                              ! @ _ . , - + : ; # = ( ) [ ] | $ % &gt; &lt; / * </li>
                                                      </ul>
                                                    </div>`;
    this.regBody.unsuccess = this.regBody.unsuccess || `<p>
                                                          Der eingegebene Registrierungslink ist falsch oder abgelaufen. Stellen Sie sicher, dass Sie den Link vollständig aus der E-Mail kopieren.
                                                        </p>
                                                        <p>Sie können sich mit der gleichen E-Mail-Adresse erneut registrieren, um sich einen neuen Link zusenden zu lassen.</p>`;
    if (!this.regHeader) {
      this.regHeader = {
        class: "card-header",
        unsuccessLabel: `<h2 class="card-title">Ungültiger Registrierungslink</h2>`,
        successLabel: `<h2 class="card-title">Passwort eingeben</h2>`
      };
    }

    if (!this.regClass) {
      this.regClass = "col-12 col-sm-10 col-lg-7 no-padding-right";
    }
  }

  checkUrl() {
    if (!this.$stateParams.uuid || !this.$stateParams.tok) {
      this.successStatus = false;
      this.loading = false;
      return;
    }
    this.RegistrierungAbschliessen.one(this.$stateParams.uuid).one(this.$stateParams.tok).get().then(
      response => {
        this.successStatus = response.validlink;
        this.loading = false;
      },
      errors => {
        this.onError(errors);
        this.loading = false;
      }
    );
  }

  absenden() {
    this.RegistrierungAbschliessen.one(this.$stateParams.uuid).one(this.$stateParams.tok).customPOST(this.model).then(
      () => {
        this.$state.go("root.login");
      },
      errors => {
        this.onError(errors);
      }
    );
  }

  onError(errors) {
    this.DiasNotification.form.clear();
    this.DiasNotification.form.error(
      errors,
      "Bitte überprüfen Sie Ihre Eingaben", 
      {}, 
      this.errorsLabel
    );
    this.errors = errors.data;
  }

  deleteErrors() {
    this.DiasNotification.form.clear();
    this.errors = [];
  }
}

export default {
  template: template(),
  controller: RegistrationController,
  controllerAs: "vm",
  bindings: {
    regClass: "<?",
    regHeader: "<?",
    regBody: "<?"
  }
};
