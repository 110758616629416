import ObjectErrorModule from "../ObjectErrorModule/ObjectErrorModule.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FoerderangebotAnzeigekontextUUIDs from "../../../../const/FoerderangebotAnzeigekontextUUIDs";
import {
  scrollToGlobal,
} from "../../../../global/functions/help";
import {
  EventBus,
} from "../../../../global/functions/event-bus";
import {
  cloneDeep,
  get,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "ObjectErrorRubric",
  components: {
    ObjectErrorModule,
    PuxTranslate,
  },
  props: {
    error: {
      type: Array,
      required: true,
    },
    rubricsByAnzeigekontext: {
      type: Object,
      required: true,
    },
    goToModule: {
      type: Function,
      required: false,
      default: undefined,
    },
  },
  computed: {
    errorAnzeigekontextPk() {
      let anzeigekontextPk = get(this.error, "[0].pk");
      // TODO: solange wir "anzeigekontext" verwenden
      if (anzeigekontextPk === FoerderangebotAnzeigekontextUUIDs.kosten) {
        anzeigekontextPk = FoerderangebotAnzeigekontextUUIDs.finanzierung;
      }
      return anzeigekontextPk;
    },

    currentRubric() {
      return this.rubricsByAnzeigekontext[this.errorAnzeigekontextPk] || {};
    },

    moduleErrors() {
      return this.error[1] || [];
    },
  },
  methods: {
    goToModuleLocal({ error }) {
      if (isFunction(this.goToModule)) {
        this.goToModule({ error, rubric: this.currentRubric }).then(
          () => {},
          () => {
            this._goToModuleLocal({ error });
          }
        );
      } else {
        this._goToModuleLocal({ error });
      }
    },

    _goToModuleLocal({ error }) {
      EventBus.$emit("toggleContextPanel", {
        id: this.currentRubric.key,
        withoutScroll: !!error.modul,
        statusOpen: true,
      });
      if (error.modul) {
        const MODUL = error.modul || {};
        setTimeout(() => {
          scrollToGlobal(`#${ MODUL.regelnummer }`);
          EventBus.$emit("showErrorsInModule", {
            regelId: MODUL.re_id,
            errors: cloneDeep(error.errors),
          });
        });
      }
    },
  },
};
