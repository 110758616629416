"use strict";

import angular from "angular";

import diasQComponent from "./q.component";


const diasQModule = angular.module("dias.core.q", []);

diasQModule.config($provide => {
  $provide.decorator("$q", $delegate => {
    const $q = $delegate;

    // allSettled extention for q
    $q.allSettled = promises => {
      if (!angular.isArray(promises)) {
        throw "allSettled can only handle an array of promises.";
      }

		  const deferred = $q.defer();
      const states = [], results = [];
      let promiseFailed = false;

      // Create an array for all promises setting their state to false (not completed)
      angular.forEach(promises, (promise, key) => {
        states[key] = false;
      });

      // Helper method to check if all states are finished
      const checkStates = (states, results, deferred, failed) => {
        let allFinished = true;
        angular.forEach(states, state => {
          if (!state) {
            allFinished = false;
            return;
          }
        });
        if (allFinished) {
          if (failed) {
            deferred.reject(results);
          } else {
            deferred.resolve(results);
          }
        }
      };

      // Loop through the promises
      // a second loop to be sure that checkStates is called when all states are set to false first
      angular.forEach(promises, (promise, key) => {
        $q.when(promise).then(
          result => {
            states[key] = true;
            results[key] = result;
            checkStates(states, results, deferred, promiseFailed);
          },
          reason => {
            states[key] = true;
            results[key] = reason;
            promiseFailed = true;
            checkStates(states, results, deferred, promiseFailed);
          }
        );
      });

      return deferred.promise;
    };

    return $q;
  });
})

  .service("DiasQ", diasQComponent)

;

export default diasQModule;
