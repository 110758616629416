"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import models from "./models";
import foerdermodule from "./foerdermodule";

import detailsComponent from "./details.component";

const modul = angular.module("dias.vertrag.cortexe.auszahlungslisten", [
  uiRouter,
  models.name,
  foerdermodule.name,
])

  .config(/*@ngInject*/$stateProvider => {
    $stateProvider

    // Liste
      .state("root.auszahlungslisten", {
        url: "/auszahlungslisten/?vertrag&status&vpb_nummer" +
        "&vpb_bez&vpb_kommentar&blockFirstLoad&typ",
        reloadOnSearch: false,
        views: {
          "main@root": {
            template: "<div vueinit><vue-auszahlungsliste-list></vue-auszahlungsliste-list></div>"
            // template: "<vertragsbelege-list-new></vertragsbelege-list-new>"
          }
        },
        data: {
          permissions: ["vertrag.cortex.auszahlungslisten.ui"]
        }
      })
      .state("root.auszahlungslisten.details", {
        url: ":id/",
        views: {
          "main@root": {
            template: "<auszahlungslisten-details></auszahlungslisten-details>"
          }
        },
        data: {
          permissions: ["vertrag.cortex.auszahlungslisten.ui"]
        }
      })

    ;
  })

  .component("auszahlungslistenDetails", detailsComponent)
;

export default modul;
