import Alert from "../../../../global/components/Alert/Alert.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";

import {
  toFormElementFromParameter,
  initModelFromList,
} from "../../../../global/functions/mappingForParameterToFormElement";
import {
  cloneDeep,
  forEach,
  get,
  isNil,
  size,
  uniqueId,
} from "lodash-es";

// @vue/component
export default {
  name: "KatalogAdminDetailsModalCreateOrEdit",
  components: {
    Alert,
    FormElement,
    FormElementReadOnly,
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    row: {
      type: Object,
      default: undefined,
    },
    katalog: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: false,
        required: true,
      },
      status: {
        loading: undefined,
      },
      errors: {},
      errorsClone: {},
      list: undefined,
      statusCancelUpdate: undefined,
    };
  },
  computed: {
    headerText() {
      return this.row ? "_TXT_KATALOGE_DETAILS_EDIT_MODAL_HEADER_{{bez}}_" : "_TXT_KATALOGE_DETAILS_CREATE_MODAL_HEADER_";
    },

    statusShowAlert() {
      return this.errors && size(this.errors);
    },

    dependencyValues() {
      const DEPENDENCY_VALUES = {};
      forEach(this.list, item => {
        if (item.dependency) {
          DEPENDENCY_VALUES[item.id] = get(this.model, item.dependency);
        }
      });
      return DEPENDENCY_VALUES;
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.initList();
      if (this.row) {
        this.initModelWithRow();
      } else {
        this.initModelWithoutRow();
      }
    },

    initList() {
      this.list = toFormElementFromParameter({
        obj: this.katalog.form,
        notReadonly: !this.row,
        showLabel: true,
      }).list;
      forEach(this.list, param => {
        if (isNil(param.apiSaveId)) {
          return;
        }
        param.apiSaveId = uniqueId(param.apiSaveId);
      });
    },

    initModelWithRow() {
      this.model = cloneDeep(this.row);
    },

    initModelWithoutRow() {
      this.model = initModelFromList({ list: this.list });
    },

    saveNotClose() {
      this.create();
    },

    save() {
      if (this.row) {
        this.update();
      } else {
        this.create({ statusClose: true });
      }
    },

    create({ statusClose } = {}) {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.postHttp({
        url: `katalogadmin/${ this.katalog.pk }/eintraege/`,
        data: data,
      }).then(
        () => {
          if (statusClose) {
            this.close({ status: true });
          } else {
            this.initData();
            this.statusCancelUpdate = true;
          }
          this.addNotification({ text: "_MSG_KATALOGE_DETAILS_CREATE_SUCCESS_" });
          this.resetErrors();
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },

    update() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.putHttp({
        url: `katalogadmin/${ this.katalog.pk }/eintraege/${ this.row.pk }/`,
        data: data,
      }).then(
        () => {
          this.close({ status: true });
          this.addNotification({ text: "_MSG_KATALOGE_DETAILS_EDIT_SUCCESS_" });
          this.resetErrors();
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },

    cancel() {
      this.close({ status: this.statusCancelUpdate });
    },

    resetErrors() {
      this.errors = {};
      this.errorsClone = {};
    },
  },
};
