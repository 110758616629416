import AngularLink from "../../../../../../../libs/vue/client/vue/global/components/AngularLink/AngularLink.vue";
import DokumenteSimpleList from "../../../../../../../libs/vue/client/vue/components/Dokumente/DokumenteSimpleList/DokumenteSimpleList.vue";
import FormElement from "../../../../../../../libs/vue/client/vue/global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";
import PuxGet from "../../../../../../../libs/vue/client/vue/global/components/PuxGet/PuxGet.vue";

import HttpMixin from "../../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import NotificationMixin from "../../../../../../../libs/vue/client/vue/global/mixins/NotificationMixin";
import PermissionMixin from "../../../../../../../libs/vue/client/vue/global/mixins/PermissionMixin";

import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "HochschuleDetailsHochschule",
  components: {
    AngularLink,
    DokumenteSimpleList,
    FormElement,
    FormstepDetail,
    PuxGet,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
  ],
  props: {
    hochschule: {
      type: Object,
      required: true,
    },
    hochschuleModel: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "updateHochschule",
  ],
  data() {
    return {
      model: {},
      errors: {},
      optionsForKbez: {
        id: "hs_kbez",
        type: "text",
        label: "Kurzbezeichnung",
        maxlength: 60,
        required: true,
        view: "v-alt",
        placeholder: "Kurzbezeichnung",
      },
      optionsForBez: {
        id: "hs_bez",
        type: "text",
        label: "Name",
        maxlength: 200,
        required: true,
        view: "v-alt",
      },
      optionsForTraeger: {
        id: "traeger",
        type: "select",
        label: "Träger",
        required: true,
        view: "v-alt",
        keyId: "tra_id",
        keyLabel: "tra_kbez",
        url: "traegerschaften/",
        urlParams: {
          fields: ["pk", "tra_kbez"],
        },
        apiSaveId: "traeger_pk_tra_kbez",
        placeholder: "---------",
        searchLabel: "Geben Sie einen Träger ein",
        searchList: ["tra_kbez"],
        search: true,
      },
      optionsForLaufnr: {
        id: "hs_laufnr",
        type: "text",
        required: false,
        label: "HSK Laufnummer",
        maxlength: 60,
        view: "v-alt",
      },
    };
  },
  computed: {
    hochschuleUrl() {
      return `hochschulen/${ this.hochschule.pk }/`;
    },

    optionsForGericht() {
      return {
        id: "hs_gericht",
        type: "text",
        label: "Gerichtsstandort",
        required: false,
        maxlength: 250,
        view: "v-alt",
        hideIf: !this.hasPermissionAdministrativeDatenEdit,
      };
    },

    optionsForTypFreitext() {
      const HOCHSCHULTYP_SONSTIGES_UUID = "72e831d3-230b-483f-9f34-0bf2d5d49dce";
      return {
        id: "hs_typ_freitext",
        type: "text",
        label: "Eigene Bezeichnung für den Hochschultyp",
        maxlength: 200,
        required: true,
        view: "v-alt",
        hideIf: this.model.typ !== HOCHSCHULTYP_SONSTIGES_UUID,
      };
    },

    optionsForType() {
      return {
        id: "typ",
        type: "select",
        label: "Hochschultyp",
        required: true,
        url: "hochschultypen/",
        urlParams: {
          fields: ["pk", "hst_kbez"],
        },
        apiSaveId: "hochschultypen_fields pk_hst_kbez",
        placeholder: "---------",
        view: "v-alt",
        keyId: "hst_id",
        keyLabel: "hst_kbez",
        searchLabel: "Geben Sie einen Träger ein",
        searchList: ["hst_kbez"],
        search: true,
      };
    },

    optionsForGebuehrengruppe() {
      return {
        id: "gebuehrengruppe",
        label: "Anzahl Studierende",
        required: false,
        url: "katalog/",
        urlParams: {
          fields: ["pk", "kgg_bez_bescheid"],
          key: "gebuehrengruppe",
        },
        apiSaveId: "gebuehrengruppe_pk_kgg_bez_bescheid",
        placeholder: "---------",
        type: "select",
        deselect: true,
        view: "v-alt",
        keyId: "id",
        keyLabel: "kgg_kbez",
        hideIf: !this.hasPermissionAdministrativeDatenEdit,
      };
    },

    optionsForIntBemerkungen() {
      return {
        id: "hs_int_bemerkung",
        type: "richtext",
        label: "Interne Bemerkungen",
        required: false,
        view: "v-alt",
        hideIf: !this.hasPermissionHochschuleInterneBemerkungenEdit,
      };
    },

    optionsList() {
      return [
        this.optionsForKbez,
        this.optionsForBez,
        this.optionsForType,
        this.optionsForTypFreitext,
        this.optionsForTraeger,
        this.optionsForGericht,
        this.optionsForGebuehrengruppe,
        this.optionsForLaufnr,
        this.optionsForIntBemerkungen,
      ];
    },

    hasPermissionHochschuleInterneBemerkungenEdit() {
      return this.checkPermissionsSync({
        perm: "hs_interne_bemerkungen.update",
        permArray: this.hochschule.user_permissions,
      });
    },

    hasPermissionHochschuleUpdate() {
      return this.checkPermissionsSync({
        perm: "hochschule.update",
        permArray: this.hochschule.user_permissions,
      });
    },

    hasPermissionHochschuleView() {
      return this.checkPermissionsSync({
        perm: "hochschule.view",
        permArray: this.hochschule.user_permissions,
      });
    },

    hasPermissionStudiengangView() {
      return this.checkPermissionsSync({
        perm: "studiengang.view",
      });
    },

    hasPermissionSystemakkreditierungView() {
      return this.checkPermissionsSync({
        perm: "systemakkreditierung.view",
        permArray: this.hochschule.user_permissions,
      });
    },

    hasPermissionHochschuleInterneBemerkungenView() {
      return this.checkPermissionsSync({
        perm: "hs_interne_bemerkungen.view",
        permArray: this.hochschule.user_permissions,
      });
    },

    hasPermissionAdministrativeDatenEdit() {
      return this.checkPermissionsSync({
        perm: "hochschule.administrative_daten.edit",
        permArray: this.hochschule.user_permissions,
      });
    },

    hsExternalLinkTitle() {
      return `Zum Hochschulkompass wechseln für Hochschule Nr. ${ this.hochschule.hs_laufnr }`;
    },

    formstepDetailOptions() {
      return {
        label: "Hochschule",
        showCloseButton: true,
        required: true,
        saveCallback: this.save,
        openCallback: this.open,
      };
    },
  },
  methods: {
    open() {
      const HOCHSCHULE = cloneDeep(this.hochschule);
      const MODEL = {};
      this.errors = {};
      forEach(this.hochschuleModel, (item, key) => {
        MODEL[key] = HOCHSCHULE[key];
      });
      this.model = MODEL;
    },

    save() {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.hochschuleUrl,
          data: this.model,
        }).then(
          response => {
            this.addNotification({ text: "Änderungen erfolgreich gespeichert" });
            this.$emit("updateHochschule", response);
            resolve();
          },
          errors => {
            this.onErrors(errors);
            reject(errors);
          }
        );
      });
    },

    onErrors(errors) {
      this.errors = errors.data;
    },
  },
};
