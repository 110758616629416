import BelegButtonVersionsvergleichBetragsrechnerTableRow
  from "../../BelegButtonVersionsvergleichBetragsrechnerTableRow/BelegButtonVersionsvergleichBetragsrechnerTableRow.vue";
import FormElementReadOnly from "../../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import KumastaStandardkostenrechner from "../../../../../KF/Betragsrechner/Kumasta/KumastaStandardkostenrechner/KumastaStandardkostenrechner";

// @vue/component
export default {
  name: "KumastaStandardkostenrechnerBelegVergleich",
  components: {
    BelegButtonVersionsvergleichBetragsrechnerTableRow,
    FormElementReadOnly,
    PuxTranslate,
    SnapshotIcon,
  },
  mixins: [
    KumastaStandardkostenrechner,
  ],
  props: {
    modelErfasst: {
      type: Object,
      required: true,
    },
    modelAnerkannt: {
      type: Object,
      required: true,
    },
  },
};
