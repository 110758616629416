import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import KFPositionForm from "../../../KFDetail/KFPositionForm/KFPositionForm.vue";
import KFPosition from "../../../KFDetail/KFPosition/KFPosition.vue";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalFunktion",
  components: {
    PuxButton,
    KFPositionForm,
    KFPosition,
  },
  props: {
    funktion: {
      type: Object,
      required: true,
    },
    idx: {
      type: String,
      required: false,
      default: undefined,
    },
    funktionIndex: {
      type: Number,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    htmlId: {
      type: String,
      required: true,
    },
    ariaDescribedbyActual: {
      type: String,
      required: true,
    },
    ariaDescribedbyVersion: {
      type: String,
      required: true,
    },
    antragKoFiArt: {
      type: Object,
      required: true,
    },
    urlPositionen: {
      type: String,
      required: true,
    },
    betragsrechner: {
      type: String,
      required: true,
    },
    katalogDetails: {
      type: Array,
      required: true,
    },
    typeKoFi: {
      type: String,
      required: true,
      validator: value => ["kosten", "finanzierung"].indexOf(value) !== -1,
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
    },
    extraForVorschau: {
      type: Object,
      required: false,
      default: undefined,
    },
    kontext: {
      type: String,
      required: true,
    },
    positionen: {
      type: Array,
      required: false,
      default: () => [],
    },
    diff: {
      type: Object,
      required: true,
    },
    currentSnapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
    statusKFDiff: {
      type: Boolean,
      required: true,
    },
    prepareModel: {
      type: Function,
      required: true,
    },
  },
  emits: [
    "updatePositionen",
    "updateValue",
  ],
  data() {
    return {
      statusAddPosition: false,
    };
  },
  computed: {
    htmlIdLocal() {
      return `${ this.htmlId }_personal_${ this.funktionIndex }`;
    },

    numberPositionId() {
      return `${ this.htmlIdLocal }_number`;
    },

    namePositionId() {
      return `${ this.htmlIdLocal }_name`;
    },

    statusShowButtonAdd() {
      return !this.readonly && !this.statusAddPosition;
    },

    buttonAddId() {
      return `${ this.htmlIdLocal }_add_${ this.funktionIndex }`;
    },

    htmlIdAddPosition() {
      return `${ this.htmlIdLocal }_new_position`;
    },

    keyModelDetail() {
      if (this.typeKoFi === "kosten") {
        return "kostenartdetail";
      }
      if (this.typeKoFi === "finanzierung") {
        return "finanzierungsartdetail";
      }
      return "";
    },

    idxLocal() {
      if (this.idx) {
        return `${ this.idx }.${ this.funktionIndex + 1 }`;
      }
      return "";
    },

    extraForVorschauLocal() {
      const EXTRA_VOR_VORSCHAU = cloneDeep(this.extraForVorschau) || {};
      EXTRA_VOR_VORSCHAU.personalfunktion = this.funktion.pk;
      return EXTRA_VOR_VORSCHAU;
    },

    currentDiffDelete() {
      return this.diff.deleteByPersonalfunktionen[this.funktion.pk] || [];
    },

    snapshotPositionenObj() {
      return this.currentSnapshot.positionenObj || {};
    },

    ariaDescribedby() {
      return this.namePositionId;
    },
  },
  methods: {
    addPositionLocal() {
      this.statusAddPosition = true;
    },

    closeAddPosition() {
      this.statusAddPosition = false;
    },

    saveLocal({ response }) {
      this.$emit("updatePositionen", { response });
    },

    updateValueLocal(arg) {
      this.$emit("updateValue", arg);
    },

    prepareModelLocal({ model }) {
      return this.prepareModel({ model, personalfunktion: this.funktion.pk });
    },
  },
};
