import FaqAdminModalCreate from "../FaqAdminModalCreate/FaqAdminModalCreate.vue";
import FaqAdminModalRemove from "../FaqAdminModalRemove/FaqAdminModalRemove.vue";
import FaqAdminModalRubrikVerwalten from "../FaqAdminModalRubrikVerwalten/FaqAdminModalRubrikVerwalten.vue";
import FormElementReadOnly from "../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import { EventBus } from "../../../global/functions/event-bus";
import getTableApi from "../../../const/TableApis";
import {
  forEach,
  reduce,
} from "lodash-es";

const TABLE_ID = "faqs";

// @vue/component
export default {
  name: "FaqAdminList",
  components: {
    FaqAdminModalCreate,
    FaqAdminModalRemove,
    FaqAdminModalRubrikVerwalten,
    FormElementReadOnly,
    PageTabTitle,
    PuxCloak,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    fixed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_TXT_FAQ_ADMIN_TABLE_ROW_ACTIONS_DETAILS_",
            route: "root.adminfaqs.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
          {
            label: "_TXT_FAQ_ADMIN_TABLE_ROW_ACTIONS_DELETE_",
            callback: this.remove,
          },
        ],
        actions: [
          {
            label: "_TXT_FAQ_ADMIN_TABLE_ACTIONS_RUBRIK_VERWALTEN_",
            callback: () => this.modal.rubrikVerwalten = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-bearbeiten",
            htmlId: "rubrik_verwalten",
          },
          {
            label: "_TXT_FAQ_ADMIN_TABLE_ACTIONS_CREATE_",
            callback: () => this.modal.faqCreate = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "faq_create",
          },
        ],
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      status: {
        loading: true,
      },
      faqsRubriken: {},
      faqsRubrikenArr: [],
      modal: {
        fagRemove: undefined,
        rubrikVerwalten: undefined,
        faqCreate: undefined,
      },
      currentFaq: undefined,
      currentIndex: undefined,

      optionsForKeywords: {
        id: "schlagworte",
        type: "multiselect",
        keyId: "pk",
        keyLabel: "sw_kbez",
        keyGroup: "gruppen_bez",
        dataIsModel: true,
      },
    };
  },
  created() {
    this.loadFAQrubriken();
  },
  methods: {
    loadFAQrubriken() {
      this.getListHttp({ url: "faqrubriken/" }).then(
        response => {
          this.faqsRubrikenArr = response;
          this.createObjFaqsRubriken(response);
          this.status.loading = false;
        }
      );
    },

    createObjFaqsRubriken(response) {
      const faqsRubriken = {};
      forEach(response, item => {
        faqsRubriken[item.pk] = item;
      });
      this.faqsRubriken = faqsRubriken;
    },

    remove({ item, index }) {
      this.currentFaq = item;
      this.currentIndex = index;
      this.modal.fagRemove = true;
    },

    closeModalFagRemove({ status } = {}) {
      this.modal.fagRemove = false;
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
    },

    closeModalRubrikVerwalten({ status } = {}) {
      this.modal.rubrikVerwalten = false;
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
    },

    closeModalFaqCreate({ status } = {}) {
      this.modal.faqCreate = false;
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
    },

    reduceMedieneintraege({ row }) {
      return reduce(row.medieneintraege_obj, (sum, item, index) => sum += `${ index !== 0 ? ", " : "" }${ item.medium.kme_kbez + " (" + item.medium.medientyp.kmd_kbez + ")" }`, "");
    },
  },
};
