"use strict";

class Raven {
  /*@ngInject*/
  constructor($window, $log) {
    this.$window = $window;
    this.$log = $log;
  }

  captureException(exception, extra) {
    this.$log.error("[Raven]", exception, extra);
    if (this.$window.Raven && this.$window.Raven.isSetup()) {
      this.$window.Raven.captureException(exception, { extra: extra });
    }
  }

  setUserContext(context) {
    if (this.$window.Raven) {
      this.$window.Raven.setUserContext(context);
    }
  }

  lastEventId() {
    if (this.$window.Raven && this.$window.Raven.lastEventId) {
      return this.$window.Raven.lastEventId();
    }
  }
}

export default Raven;
