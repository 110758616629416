import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";

import {
  concat,
  findIndex,
  forEach,
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "MitgliederModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    sitzungPk: {
      type: String,
      required: true,
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        foerderorgnutzer: []
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "foerderorgnutzer",
            type: "multiselect",
            label: "_LBL_SITZUNGSDETAILS_BENUTZER_",
            required: true,
            search: false,
            keyId: "pk",
            keyLabelCallback: ({ item }) => `${ item.nutzer_obj.name } (${ item.foerderorg_obj.o_name })`,
            view: "v-alt",
            data: [],
            hideIf: true,
            helpText: undefined,
            disabled: undefined,
            translate: true,
          },
        ],
      },
      status: {
        loading: true,
      },
      errors: undefined,
      errorsClone: undefined,
      foerderorgnutzerObj: undefined,
      promisesAll: [],
    };
  },
  computed: {
    isDisabled() {
      return this.status.loading || !this.model.foerderorgnutzer.length;
    }
  },
  created() {
    this.loadDataForFoerderorgnutzer();
  },
  methods: {
    loadDataForFoerderorgnutzer() {
      this.getListHttp({
        url: `sitzungen/${ this.sitzungPk }/moegliche_teilnehmer/`
      }).then(
        response => {
          const INDEX_FOERDERORGNUTZER = findIndex(this.options.list, ["id", "foerderorgnutzer"]);
          this.options.list[INDEX_FOERDERORGNUTZER].data = response;
          this.options.list[INDEX_FOERDERORGNUTZER].hideIf = false;
          if (this.options.list[INDEX_FOERDERORGNUTZER].data && this.options.list[INDEX_FOERDERORGNUTZER].data.length === 0) {
            this.options.list[INDEX_FOERDERORGNUTZER].helpText = "_MSG_SITZUNGSDETAILS_TEILNEHMER_MODAL_CREATE_HELPTEXT_";
            this.options.list[INDEX_FOERDERORGNUTZER].disabled = true;
          }
          this.foerderorgnutzerObj = keyBy(response, "pk");
          this.status.loading = false;
        }
      );
    },

    onError({ error, nachname, vorname } = {}) {
      forEach(error, (item, key) => {
        error[key] = error[key] + ` (${ vorname } ${ nachname })`;
        if (this.errors) {
          this.errors[key] = this.errors[key] ? concat(this.errors[key], item) : item;
        } else {
          this.errors = error;
        }
      });
      this.errorsClone = error;
    },

    save() {
      this.errors = undefined;
      this.errorsClone = undefined;
      const INDEX_FOERDERORGNUTZER = findIndex(this.options.list, ["id", "foerderorgnutzer"]);
      this.options.list[INDEX_FOERDERORGNUTZER].disabled = true;
      this.status.loading = true;
      forEach(this.model.foerderorgnutzer, value => {
        const DATA = {
          sitzung: this.sitzungPk,
          teilnehmer: value,
        };
        const POST = this.postHttp({ url: `sitzungen/${ this.sitzungPk }/teilnehmer/`, data: DATA
        });
        this.promisesAll.push(POST);
        POST.then(
          () => {
            this.addNotification({ text: "_MSG_SITZUNGSDETAILS_TEILNEHMER_MODAL_CREATE_SAVE_" });
          },
          error => {
            this.onError({ error: error.data, name: this.foerderorgnutzerObj.nutzer_obj.name, o_name: this.foerderorgnutzerObj.foerderorg_obj.o_name });
          }
        );
      });
      this.waitToClose();
    },

    waitToClose() {
      Promise.allSettled(this.promisesAll).then(
        () => {
          if (!this.errors) {
            this.close({ status: true });
          }
          this.status.loading = false;
          const INDEX_FOERDERORGNUTZER = findIndex(this.options.list, ["id", "foerderorgnutzer"]);
          this.options.list[INDEX_FOERDERORGNUTZER].disabled = false;
        }
      );
    }
  },
};
