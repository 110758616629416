"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import authModels from "dias/auth/models";


const rollenverwaltungModule = angular.module("rollenverwaltung", [
  uiRouter,
  authModels.name,
  diasTable.name,
  diasNotification.name,
]);

rollenverwaltungModule.config($stateProvider => {
  $stateProvider
    .state("root.rollenverwaltung", {
      url: `/rollenverwaltung/?
            user_cookie&
            kontexttyp
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-rollen-list></vue-rollen-list></div>"
        }
      },
      data: {
        permissions: "rollen.ui"
      }
    })
    .state("root.rollenverwaltung.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><rollen-details></rollen-details></div>"
        }
      },
    });
});
export default rollenverwaltungModule;
