"use strict";

class StandardFoerdermoduleBaseController {
  constructor($scope) {
    this.EVENT_EMIT_NAME = "sitzungsmodule.distributor.notify";
    this.EVENT_NAME = "sitzungsmodule.notify";
    this.parameter = {};
    this.userInput = {};
    this.requiredFields = [];
    this.hasEdit = false;
    this.$scope = $scope;
    this.prepareParameter();
    this.prepareUserInput();
    this.$scope.$on(this.EVENT_NAME, (event, data) => {
      this._handleEvent(data);
    });
  }

  _handleEvent(data) {
    if (data.src === this.modul.modul.pk) {
      return;
    }
    this.onEvent(data);
  }

  _buildEventExtras(eventAction) {
    return {
      src: this.modul.modul.pk,
      groups: this.modul.modulgruppen,
      action: eventAction
    };
  }

  emitEvent(eventAction, extras) {
    let data = this._buildEventExtras(eventAction);
    data = angular.extend(data, extras);
    this.$scope.$emit(this.EVENT_EMIT_NAME, data);
  }

  onEvent() {}

  setRequiredFields() {
    this.requiredFields.splice(0, this.requiredFields.length);
    this.requiredFields.push(...arguments);
  }

  isFieldRequired(fieldName) {
    return this.requiredFields.indexOf(fieldName) !== -1;
  }

  isMissing(fieldName) {
    return this.isFieldRequired(fieldName) && (this.userInput[fieldName] === null || this.userInput[fieldName] === undefined || this.userInput[fieldName].length === 0);
  }

  isMissingInput() {
    let anyMissing = false;
    for (const key in this.userInput) {
      anyMissing = anyMissing || this.isMissing(key);
    }
    return anyMissing;
  }

  prepareParameter() {
    angular.merge(this.parameter, this.modul.getParameter());
  }

  prepareUserInput() {
    angular.extend(this.userInput, this.modul.data);
    this.setRequiredFields(...Object.keys(this.userInput));
  }
}

export default StandardFoerdermoduleBaseController;
