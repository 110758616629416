import KFAuszahlungPeriodenPosition from "../KFAuszahlungPeriodenPosition/KFAuszahlungPeriodenPosition.vue";
import KofiErfassungModulBetragWarningIcon from "../../KofiErfassung/KofiErfassungModul/KofiErfassungModulBetragWarningIcon/KofiErfassungModulBetragWarningIcon.vue";
import KofiErfassungModulProzent from "../../KofiErfassung/KofiErfassungModul/KofiErfassungModulProzent/KofiErfassungModulProzent.vue";
import KofiErfassungPositionForm from "../../KofiErfassung/KofiErfassungPositionForm/KofiErfassungPositionForm.vue";
import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxGet from "../../../../global/components/PuxGet/PuxGet.vue";
import PuxTooltip from "../../../../global/components/PuxTooltip/PuxTooltip.vue";

import KofiErfassungModulMixin from "../../KofiErfassung/Mixins/KofiErfassungModulMixin";

// @vue/component
export default {
  name: "KFAuszahlungPeriodenModul",
  components: {
    KFAuszahlungPeriodenPosition,
    KofiErfassungModulBetragWarningIcon,
    KofiErfassungModulProzent,
    KofiErfassungPositionForm,
    PuxAlert,
    PuxGet,
    PuxTooltip,
  },
  mixins: [
    KofiErfassungModulMixin,
  ],
  props: {
    periodenFiltered: {
      type: Array,
      required: true,
    },
    periodKeyShow: {
      type: String,
      required: true,
      info: "Welche Perioden-Teil werden angezeigt",
    },
    isDiffPerioden: {
      type: Boolean,
      required: false,
    },
    gesamtSumPerioden: {
      type: Object,
      required: false,
      info: "Gesamte Zahlhen der Perioden",
      default: () => ({}),
    }
  },
  data() {
    return {
      betragKeys: [
        "wert",
        "betrag_erfasst",
        "betrag_anerkannt",
      ],
    };
  },
};
