// Modul zur Erfassung von einfachen Kosten
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";
import VertragModels from "dias/vertrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const zuschuss_zeitraum = angular.module("dias.kosten_finanzierung.modul.zuschuss_zeitraum", [
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
  VertragModels.name,
]);

zuschuss_zeitraum.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("zuschuss_zeitraum", "modul-zuschuss-zeitraum");
})

  .directive("modulZuschussZeitraum", builder.directive(template, controller));

export default zuschuss_zeitraum;
