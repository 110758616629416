import PuxButton from "../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "TheMenuJump",
  components: {
    PuxButton,
  },
  setup() {
    const changeFocusTo = name => {
      $(name).focus();
    };

    return {
      changeFocusTo,
    };
  },
};
