import translate from "../../../../../global/directives/translate";
import Spaced from "../../../../../global/components/Spaced/Spaced.vue";
import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "AntragsverbundTr",
  components: {
    AngularLink,
    Spaced,
    SnapshotIcon,
  },
  directives: {
    translate,
  },
  props: {
    antrag: {
      type: Object,
      required: true,
    },
    obj: {
      type: Object,
      required: true,
    },
    verbundart: {
      type: Object,
      required: true,
    },
    antragConfig: {
      type: Object,
      required: true,
    },
    statusSnapshot: {
      type: Boolean,
      required: false,
    },
    statusSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    statusSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    statusSnapshotChanges: {
      type: Boolean,
      required: false,
    },
    statusSnapshotChangesIcon: {
      type: Boolean,
      required: false,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup() {
    const {
      filterCurrency,
      filterSpaced,
    } = FiltersAPI();

    return {
      filterCurrency,
      filterSpaced,
    };
  },
  computed: {
    titleANr() {
      return this.filterSpaced(this.antrag.a_nr, true);
    },

    translateOptionsDatum() {
      return {
        title: "_TXT_GR_ANTRAGSVERBUND_DATUM_TITLE_{{zuschuss}}_{{gesamtkosten}}_",
        extra: {
          zuschuss: this.antrag.a_bewilligungsdatum ? this.antrag.a_zuschussbewilligt : this.antrag.a_zuschussbeantragt,
          gesamtkosten: this.antrag.gesamtkosten,
        },
      };
    },

    snapshotIconFirstTdType() {
      if (this.statusSnapshotAdd) {
        return "add";
      }
      if (this.statusSnapshotDelete) {
        return "delete";
      }
      if (this.statusSnapshotChangesIcon) {
        return "changes";
      }
      return "";
    },

    translateVersionTdOptions() {
      return {
        text: this.translateVersionTdText,
        extra: {
          date: this.snapshotDate,
        },
      };
    },

    translateVersionTdText() {
      if (this.statusSnapshotAdd) {
        return "_TXT_AKTUELLE_VERSION_ADDED_COLUMN_{{date}}_";
      }
      if (this.statusSnapshotDelete) {
        return "_TXT_VERSION_REMOVED_COLUMN_{{date}}_";
      }
      if (this.statusSnapshotChanges) {
        return "_TXT_VERSION_MODIFIED_COLUMN_{{date}}_";
      }
      return "_TXT_AKTUELLE_VERSION_";
    },

    classVersion() {
      if (this.statusSnapshotDelete || this.statusSnapshotChanges) {
        return "version-bg";
      }
      return "";
    },

    rowspanFirstTd() {
      return this.statusSnapshotChangesIcon ? 2 : 1;
    },

    statusShowFirstTd() {
      return !(this.statusSnapshotChanges && !this.statusSnapshotChangesIcon);
    },

    statusShowLink() {
      return !this.statusSnapshotDelete && !this.statusSnapshotChanges;
    },
  },
};
