"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import "restangular";

import errors from "../errors";
import notification from "../notification";

import KAnrede from "./kanrede.service";
import KKommtyp from "./kkommtyp.service";
import KBundesland from "./kbundesland.service";
import KRechtsform from "./krechtsform.service";
import KTitel from "./ktitel.service";
import SysInfo from "./sysinfo.service";
import ReleaseNotes from "./releasenotes.service";

const modelsModule = angular.module("core.models", [
  "restangular",
  uiRouter,
  errors.name,
  notification.name
])

  .provider("KAnrede", KAnrede)
  .provider("KBundesland", KBundesland)
  .provider("KKommtyp", KKommtyp)
  .provider("KRechtsform", KRechtsform)
  .provider("KTitel", KTitel)
  .provider("SysInfo", SysInfo)
  .provider("ReleaseNotes", ReleaseNotes)
;

export default modelsModule;
