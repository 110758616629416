import AnsprechpartnerAssignButtonModal from "../../../../Ansprechpartner/AnsprechpartnerAssignButtonModal/AnsprechpartnerAssignButtonModal.vue";
import AnsprechpartnerAssignMeButtonModal from "../../../../Ansprechpartner/AnsprechpartnerAssignMeButtonModal/AnsprechpartnerAssignMeButtonModal.vue";
import NachrichtenLink from "../../../../Nachrichten/NachrichtenLink/NachrichtenLink.vue";
import Permission from "../../../../../global/components/Permission/Permission.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";

import KommunikationsdatenUUIDs from "../../../../../const/KommunikationsdatenUUIDs";
import {
  filter,
  get,
} from "lodash-es";
import PermissionMixin from "../../../../../global/mixins/PermissionMixin";

// @vue/component
export default {
  name: "AuszahlungUebersichtFoerderorgAnsprechpartner",
  components: {
    AnsprechpartnerAssignButtonModal,
    AnsprechpartnerAssignMeButtonModal,
    NachrichtenLink,
    Permission,
    PuxIcon,
  },
  mixins: [
    PermissionMixin,
  ],
  props: {
    ansprechpartner: {
      type: Object,
      required: true,
    },
    ansprechpartnerIndex: {
      type: Number,
      required: true,
    },
    vertragPk: {
      type: String,
      required: true,
    },
    antrag: {
      type: Object,
      required: true,
      default: () => ({}),
    }
  },
  computed: {
    phoneNumbers() {
      return filter(this.ansprechpartner.nutzer.kommunikationsdaten, item => {
        return item.kommtyp && item.kommtyp.kot_id === KommunikationsdatenUUIDs.telefonnr1;
      });
    },

    nachrichtenLinkLabel() {
      const TYP_KBEZ = get(this.ansprechpartner, "typ.apt_kbez");
      return `${ this.ansprechpartner.nutzer.name } (${ TYP_KBEZ })`;
    },

    nachrichtenLinkId() {
      return `nachrichten_link_foerderorg_ansprechpartner_${ this.ansprechpartnerIndex }`;
    },

    ansprechpartnerNotCurrentUser() {
      return get(this.ansprechpartner, "nutzer.pk") !== this.me.pk;
    },

    idForButtonMirZuweisen() {
      return `ap_${ this.ansprechpartnerIndex }_mir_zuweisen`;
    },

    idForButtonZuweisen() {
      return `ap_${ this.ansprechpartnerIndex }_zuweisen`;
    },
  },
};
