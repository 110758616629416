import Alert from "../../../../global/components/Alert/Alert.vue";
import GutachtenUebersichtTable from "./GutachtenUebersichtTable/GutachtenUebersichtTable.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import ModuleMixin from "../ModuleMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import {
  cloneDeep,
  filter,
  isNil,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "GutachtenUebersicht",
  components: {
    Alert,
    GutachtenUebersichtTable,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    ModuleMixin,
    PermissionMixin,
  ],
  inject: [
    "gutachten"
  ],
  data() {
    return {
      ergebnisse: undefined,
      ergebnisseAuswahl: undefined
    };
  },
  computed: {
    labelHeader() {
      return this.modelParameter.titel;
    },

    canViewGutachten() {
      return this.checkPermissionsSync({ perm: "gutachten.view.overview", permArray: this.obj.user_permissions });
    },

    canLinkToGutachten() {
      return this.checkPermissionsSync({ perm: ["gutachten.list", "gutachten.view"] });
    },

    bearbeitungHtmlId() {
      return `${ this.htmlRegelNummer }_bearbeitung`;
    },

    bearbeitungGutachten() {
      return filter(this.gutachtenLocal || [], ga => {
        return isNil(ga.ergebnis);
      });
    },

    showBearbeitung() {
      return size(this.bearbeitungGutachten) > 0;
    },

    bearbeitungCols() {
      return this.modelParameter.bearbeitung_spalten || [];
    },

    bearbeitungHeader() {
      return this.modelParameter.bearbeitung_titel;
    },

    abgeschlossenHtmlId() {
      return `${ this.htmlRegelNummer }_abgeschlossen`;
    },

    abgeschlossenGutachten() {
      return filter(this.gutachtenLocal || [], ga => {
        return !isNil(ga.ergebnis);
      });
    },

    showAbgeschlossen() {
      return size(this.abgeschlossenGutachten) > 0;
    },

    abgeschlossenCols() {
      return this.modelParameter.abgeschlossen_spalten || [];
    },

    abgeschlossenHeader() {
      return this.modelParameter.abgeschlossen_titel;
    },

    showNoGutachtenMsg() {
      return !this.showBearbeitung && !this.showAbgeschlossen;
    },

    isModuleEditable() {
      return false;
    },

    gutachtenLocal() {
      return cloneDeep(this.gutachten || []);
    },
  },
};
