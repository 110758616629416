"use strict";
import angular from "angular";
import BaseEditController from "./formstepBaseEdit.controller";
import BaseReadonlyController from "./formstepBaseReadonly.controller";
import formstepDetailTemplate from "./formstepDetail.jade";

class DefaultEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification) {
    super($q, $scope, DiasNotification);
  }
}

class DefaultReadonlyController extends BaseReadonlyController {
  /*@ngInject*/
  constructor($scope) {
    super($scope);
  }
}

class FormstepDetailController {
  /*@ngInject*/
  constructor($scope, $templateCache, Foerderantrag) {
    $scope.isEditable = () => angular.isUndefined(this.editable) ? true : this.editable;
    $scope.isDeletable = () => angular.isUndefined(this.deletable) ? false : this.deletable;
    this.Foerderantrag = Foerderantrag;
    this.form = this.title + "_form";
    if (angular.isUndefined(this.editTemplateUrl) && angular.isFunction(this.editTemplate)) {
      let templ = this.editTemplate();
      if (angular.isFunction(templ)) {
        templ = templ();
      }
      this.editTemplateUrl = this.title + ".editTemplate.html";
      $templateCache.put(this.editTemplateUrl, templ);
    }
    if (!angular.isUndefined(this.editTemplateUrl) && angular.isUndefined(this.editController)) {
      this.editController = DefaultEditController;
    }
    if (angular.isUndefined(this.readonlyTemplateUrl) && angular.isFunction(this.readonlyTemplate)) {
      let templ = this.readonlyTemplate();
      if (angular.isFunction(templ)) {
        templ = templ();
      }
      this.readonlyTemplateUrl = this.title + ".readonlyTemplate.html";
      $templateCache.put(this.readonlyTemplateUrl, templ);
    }
    if (!angular.isUndefined(this.readonlyTemplateUrl) && angular.isUndefined(this.readonlyController)) {
      this.readonlyController = DefaultReadonlyController;
    }
    if (!angular.isFunction(this.done)) {
      this.done = () => true;
    }
    $scope.done = this.done;
    $scope.editMode = false;
    $scope.editModeInitialized = false;
    $scope.setEditMode = (bool, cb, errorsLabel) => {
      this.setEdit(bool, cb, $scope, errorsLabel);

      this.infoStatusShow = bool;
    };
    $scope.toggleEditMode = this.toggleEdit;
    $scope.model = this.model;
    $scope.extra = this.extra;
    $scope._ = _;

    $scope.$watch(() => this.model, () => {
      $scope.model = this.model;
    });
    $scope.$watch(() => this.extra, () => {
      $scope.extra = this.extra;
    });
    $scope.$watch(() => this.editable, () => {
      if (!$scope.isEditable() && $scope.editMode) {
        $scope.editMode = false;
      }
    });
    this.$scope = $scope;

    this.showDetail = () => {
      this.detailsShowStatus = !this.detailsShowStatus;
    };

    // INFO
    this.toggleInfoStatusShow = () => {
      this.infoStatusShow = !this.infoStatusShow;
    };
  }

  setEdit(bool, cb, ctx, errorsLabel) {
    ctx.editModeInitialized = ctx.editModeInitialized || bool;
    if (cb === undefined) {
      ctx.editMode = bool;
      return;
    }
    const defer = cb(errorsLabel);
    if (defer !== undefined && defer.then !== undefined && defer.then instanceof Function) {
      ctx.loading = true;
      defer.then(
        () => ctx.editMode = bool
      ).finally(
        () => {
          ctx.loading = false;
          this.updateSnapshotDiff();
          if (angular.isFunction(this.endFunction)) {
            this.endFunction();
          }
        }
      );
    } else {
      ctx.editMode = bool;
    }
  }

  updateSnapshotDiff() {
    if (this.snapshot) {
      this.Foerderantrag.one(this.snapshot.antrag).snapshots.one(this.snapshot.pk).one("diff").get().then(response => {
        this.snapshotDiff = response;
      });
    }
  }
}

export default () => {
  return {
    restrict: "E",
    require: "ngModel",
    scope: {
      title: "@formstepTitle",
      editable: "=formstepEditable",
      done: "&?formstepDone",
      endFunction: "&?formstepEndFunction",
      editTemplate: "&?formstepEditTemplate",
      editTemplateUrl: "@?formstepEditTemplateUrl",
      editController: "=?formstepEditController",
      info: "=?formstepInfo",
      readonlyTemplate: "&?formstepReadonlyTemplate",
      readonlyTemplateUrl: "@?formstepReadonlyTemplateUrl",
      readonlyController: "=?formstepReadonlyController",
      readonlyShowStatus: "@?formstepReadonlyShowStatus",
      model: "=ngModel",
      extra: "=formstepExtraContext",
      snapshot: "=snapshot",
      snapshotDiff: "=snapshotDiff",
      deletable: "=formstepDeletable",
      deleteFunction: "&?formstepDeleteFunction",
    },
    template: formstepDetailTemplate,
    controller: FormstepDetailController,
    controllerAs: "formstep",
    bindToController: true
  };
};
