import FormElement from "../../../global/components/FormElement/FormElement.vue";
import Spinner from "../../../global/components/Spinner/Spinner.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";

import {
  setUrl,
} from "../../../global/functions/help";
import {
  forEach,
  cloneDeep,
  assignWith,
} from "lodash-es";

// @vue/component
export default {
  name: "SearchStartTabContent",
  components: {
    FormElement,
    Spinner,
    PuxButton,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    statusTabActive: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const {
      getHttp,
    } = HttpAPI();

    return {
      getHttp,
    };
  },
  data() {
    return {
      statusLoadingCount: undefined,
      statusChangesDuringLoadingCount: undefined,
      statusShowDetails: false,
      count: undefined,
      model: {},
      modelDefault: {},
      listMain: [],
      listExtra: [],
      timer: undefined,
    };
  },
  computed: {
    hrefGoTo() {
      const MODEL = cloneDeep(this.model);
      if (this.data.urlParameter) {
        MODEL[this.data.urlParameter] = "Ja";
      }
      return `${ this.data.url }${ setUrl(MODEL, "?") }`;
    },
  },
  watch: {
    statusTabActive() {
      if (this.statusTabActive) {
        this.loadCountFirst();
      } else {
        this.clearTimer();
        this.count = undefined;
      }
    },
  },
  created() {
    this.initData();
    this.loadCountFirst();
  },
  beforeUnmount() {
    this.clearTimer();
  },
  methods: {
    initData() {
      const MODEL = {};
      const MODEL_DEFAULT = {};
      const LIST_MAIN = [];
      const LIST_EXTRA = [];
      forEach(cloneDeep(this.data.list), options => {
        if (options.type === "multiselect") {
          MODEL[options.id] = [];
        } else {
          MODEL[options.id] = null;
        }
        if (options.mainOrExtra === "extra") {
          LIST_EXTRA.push(options);
          MODEL_DEFAULT[options.id] = cloneDeep(MODEL[options.id]);
        } else if (options.mainOrExtra === "main") {
          LIST_MAIN.push(options);
        }
      });
      this.model = MODEL;
      this.modelDefault = MODEL_DEFAULT;
      this.listMain = LIST_MAIN;
      this.listExtra = LIST_EXTRA;
    },

    loadCountFirst() {
      if (this.statusTabActive) {
        this.loadCount();
      }
    },

    loadCount() {
      this.statusLoadingCount = true;
      this.statusChangesDuringLoadingCount = false;
      const model = cloneDeep(this.model);
      if (this.data.urlParameter) {
        model[this.data.urlParameter] = "Ja";
      }
      this.getHttp({
        url: this.data.searchUrl,
        urlParams: model
      }).then(
        response => {
          this.count = response;
          this.statusLoadingCount = false;
          if (this.statusChangesDuringLoadingCount) {
            this.statusLoadingCount = true;
            this.loadCount();
          }
        }
      );
    },

    toggleDetails() {
      this.statusShowDetails = !this.statusShowDetails;
      if (!this.statusShowDetails) {
        this.model = assignWith({}, this.model, this.modelDefault);
      }
    },

    onChange() {
      if (this.statusLoadingCount) {
        this.statusChangesDuringLoadingCount = true;
        return;
      }
      this.clearTimer();
      this.timer = setTimeout(() => {
        this.loadCount();
      }, 1000);
    },

    clearTimer() {
      clearTimeout(this.timer);
    },
  },
};
