"use strict";

import BetragsrechnerRegistry from "../services";

import BetragsrechnerFerien from "./betragsrechner.js";
import BetragsrechnerFerienController from "../ferien_betragsrechner/controller";

const FerienMitReisetagenModul = angular.module("dias.betragsrechner.BetragsrechnerFerienMitReisetagenModul", [
  BetragsrechnerRegistry.name,
]);

FerienMitReisetagenModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("ferienkostenrechner_mit_reisetagen", BetragsrechnerFerien);
})
  .component("ferienkostenMitReisetagen", BetragsrechnerFerienController);

export default FerienMitReisetagenModul;
