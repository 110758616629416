"use strict";

import template from "./add.jade";

class FoerderangebotRegelAddController {
  /*@ngInject*/
  constructor(ParameterUtils, i18n) {
    this.name = "FoerderangebotRegelAddController";
    // this.regeln = $scope.regeln;
    this.regelForm = {};
    this.regel = null;
    this.ParameterUtils = ParameterUtils;
    this.gettext = i18n.gettext;
    // this.onAdd = $scope.onAdd;
    this.add = regel => {
      this.onAdd()(regel);
      this.showAdd = false;
      this.regel = null;
    };
    this.settings = {
      scrollableHeight: "auto",
      scrollable: true,
      maxHeigth: "200px",
      enableSearch: true,
      displayProp: ["bez"],
      // hideProp: {used: false},
      searchProp: "bez",
      buttonClasses: "btn btn-default btn-block",
      displayPropSymbol: " ",
      caseSensitivity: true,
      smartButtonTextConverter: text => {
        return text.bez;
      }
    };
  }

  prepareRegel() {
    if (this.regel && angular.isUndefined(this.regel.param_keys)) {
      this.regel.param_keys = this.ParameterUtils.get_ordered_keys(this.regel.re_argument);
    }
  }
}

export default {
  template: template(),
  controller: FoerderangebotRegelAddController,
  controllerAs: "vm",
  bindings: {
    regeln: "=",
    used: "=",
    onAdd: "&"
  }
};
