"use strict";

class GutachtenCreateController {
  /*@ngInject*/
  constructor($q, $scope, ParameterUtils, DiasNotification, i18n, AuthService) {
    this.$scope = $scope;
    this.$q = $q;
    this.DiasNotification = DiasNotification;
    this.errorContainerId = this.DiasNotification.form.containerId;
    this.ParameterUtils = ParameterUtils;
    this.gettext = i18n.gettext;
    this.vertrag = $scope.extras.vertrag;
    this.model = $scope.extras.zuschussanpassung || {};
    this.AuthService = AuthService;
    this.init();
    this.save = () => {
      if (this.model.pk) {
        return this.update();
      }
      return this.create();
    };
  }

  init() {
    this.formModelTemplate = this.ParameterUtils.init_parameter_in({
      vza_kbez: {
        bez: "Bezeichnung",
        typ: "string",
        prio: 1,
        options: {
          required: true
        }
      },
      vza_betrag: {
        bez: this.gettext("betrag_zuschussanpassung"),
        typ: "decimal",
        prio: 2,
        options: {
          required: true,
          max_digits: 14,
          decimal_places: 2
        }
      },
      vza_kommentar: {
        bez: "Kommentar",
        typ: "richtext",
        prio: 3,
        options: {
          required: true
        }
      },
    });
    this.formModel = this.ParameterUtils.params_from_template(this.formModelTemplate, this.model);
    this.form_keys = this.ParameterUtils.get_ordered_keys(this.formModel);
    this.errorLabels = {};
    this.form_keys.forEach(
      k => {
        this.errorLabels[k] = `${ this.formModel[k].bez }: `;
      }
    );
  }

  create() {
    const data = this.ParameterUtils.to_request_body(this.formModel, null, "");
    data.vertrag = this.vertrag.pk;
    const promise = this.vertrag.customPOST(data, "zuschussanpassungen").then(
      response => {
        this.DiasNotification.page.success(`${ this.gettext("Zuschussanpassung") } erfolgreich erstellt.`);
        return response;
      },
      errors => {
        return this.onErrors(errors, this.errorLabels);
      }
    );
    return promise;
  }

  update() {
    const data = this.ParameterUtils.to_request_body(this.formModel, null, "");
    data.vertrag = this.vertrag.pk;
    const promise = this.vertrag.one("zuschussanpassungen", this.model.pk).customPUT(data).then(
      response => {
        this.DiasNotification.page.success(`${ this.gettext("Zuschussanpassung") } erfolgreich geändert.`);
        return response;
      },
      errors => {
        return this.onErrors(errors, this.errorLabels);
      }
    );
    return promise;
  }

  onErrors(errors, labelErrors) {
    this.DiasNotification.form.clear();
    this.DiasNotification.form.error(errors, "Ihre Daten sind fehlerhaft oder unvollständig.", { container: "#" + this.errorContainerId }, labelErrors);
    if (errors.data) {
      this.errors = errors.data;
    } else {
      this.errors = errors;
    }
    return this.$q.reject(errors);
  }
}

export default GutachtenCreateController;
