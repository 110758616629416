import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";

import {
  get,
  isArray,
  isFunction,
  isNil,
  isPlainObject,
  isString,
  size,
  uniqueId,
} from "lodash-es";

// @vue/component
export default {
  name: "PuxErrorsElement",
  components: {
    PuxTranslate,
  },
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    error: {
      type: [Object, Array, String],
      required: false,
      default: undefined,
    },
    errorKey: {
      type: String,
      required: false,
      default: undefined,
    },
    errorLabels: {
      type: [String, Object],
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
    goToError: {
      type: Function,
      required: false,
      default: undefined,
    },
    isErrorLink: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    currentLabel() {
      if (isString(this.errorLabels)) {
        return this.errorLabels;
      } else if (isPlainObject(this.errorLabels)) {
        return this.errorLabels.label;
      }
      return "";
    },

    hasElementErrors() {
      return get(this.errorLabels, "hasElementErrors");
    },

    isErrorLinkLocal() {
      return isPlainObject(this.errorLabels) &&
        this.errorLabels.link &&
        this.isErrorLink;
    },

    idLocal() {
      return get(this.errorLabels, "id") || this.id;
    },

    ariaDescribedby() {
      if (this.idLocal) {
        return `pux_errors_${ this.idLocal }`;
      }
      return uniqueId("pux_errors_");
    },

    isErrorString() {
      return isString(this.error);
    },

    isErrorArray() {
      return isArray(this.error);
    },

    isErrorObject() {
      return isPlainObject(this.error);
    },

    childLevel() {
      return this.level + 1;
    },

    isErrorLabelsArray() {
      return isArray(this.errorLabels);
    },
  },
  methods: {
    goToErrorLocal() {
      if (isFunction(this.goToError)) {
        return this.goToError({
          id: this.idLocal,
          key: this.errorKey
        });
      }
      let idStr = this.idLocal;
      if (!isNil(this.errorKey)) {
        idStr = `${ idStr }_${ this.errorKey }`;
      }
      if (size(idStr) > 0) {
        const ELEMENT = $(`#${ idStr }`);
        if (ELEMENT) {
          if (this.isCKEditor({ element: ELEMENT })) {
            this.goToCKEditor({ id: idStr });
            return;
          }
          ELEMENT.focus();
        }
      }
    },

    isCKEditor({ element }) {
      return element.prop("tagName") === "TEXTAREA" &&
        element.css("display") === "none";
    },

    goToCKEditor({ id }) {
      const ELEMENT = $(`#${ id } + div .cke_editable`);
      if (ELEMENT) {
        ELEMENT.focus();
      }
    },
  },
};
