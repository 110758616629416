export default {
  data() {
    return {
      moduleMapping: {
        astorganisationen: {
          getHref: ({ id }) => `/astorganisationen/${ id }/`,
          getApi: () => `antragstellerorganisationen/`,
        },
        formulare: {
          getHref: ({ id }) => `/formulare/${ id }/`,
          getApi: () => `formulare/`,
        },
        auszahlungen: {
          getHref: ({ id, parentId }) => `/vertrag/${ parentId }/auszahlung/${ id }/`,
          getApi: () => `auszahlungen/`,
        },
        foerderantrag: {
          getHref: ({ id }) => `/foerderantrag/${ id }/`,
          getApi: () => `foerderantraege/`,
        },
        foerderantraege: {
          getHref: ({ id }) => `/foerderantrag/${ id }/`,
          getApi: () => `foerderantraege/`,
        },
        sitzungsantraege: {
          getHref: ({ id }) => `/sitzungsantraege/${ id }/`,
          getApi: () => `sitzungsantraege/`,
        },
        sammelvertraege: {
          getHref: ({ id }) => `/sammelvertraege/${ id }/`,
          getApi: () => `sammelvertraege/`,
        },
        sitzungen: {
          getHref: ({ id }) => `/sitzungen/${ id }/`,
          getApi: () => `sitzungen/`,
        },
        sitzungtagesordnungen: {
          getHref: ({ id, parentId }) => `/sitzungen/${ parentId }/top/${ id }/`,
          getApi: () => undefined, // TODO ILia
        },
        vertraege: {
          getHref: ({ id }) => `/vertrag/${ id }/`,
          getApi: () => `vertraege/`,
        },
        vertrag: {
          getHref: ({ id }) => `/vertrag/${ id }/`,
          getApi: () => `vertraege/`,
        },
        foerderdokumente: {
          getHref: ({ id }) => `/foerderdokumente/${ id }/`,
          getApi: () => `foerderdokumente/`,
        },
        gutachten: {
          getHref: ({ id }) => `/gutachten/${ id }/`,
          getApi: () => `gutachten/`,
        },
      },
    };
  },
  methods: {
    getAngularRout({ model, id, parentId }) {
      if (!this.moduleMapping[model]) {
        return undefined;
      }
      return this.moduleMapping[model].getHref({ id, parentId });
    },

    getModuleApi({ model }) {
      if (!this.moduleMapping[model]) {
        return undefined;
      }
      return this.moduleMapping[model].getApi();
    },
  },
};
