"use strict";

class GutachtenvorlageCreateController {
  /*@ngInject*/
  constructor($q, Gutachtenvorlagen, ParameterUtils, DiasNotification, $state, i18n) {
    this.ParameterUtils = ParameterUtils;
    this.DiasNotification = DiasNotification;
    this.gettext = i18n.gettext;

    this.save = () => {
      const data = this.ParameterUtils.to_request_body(this.formModel, null, "");
      return Gutachtenvorlagen.post(data).then(
        response => {
          DiasNotification.page.success("Gutachtenvorlage erfolgreich angelegt");
          $state.go("root.gutachtenvorlagen.detail", { id: response.pk });
        },
        error => this.onError(error)
      );
    };

    this.onError = error => {
      DiasNotification.form.error(
        error.data,
        "Bitte überprüfen Sie Ihre Eingaben",
        {},
        this.errorLabels
      );
      this.errors = error.data;
      return $q.reject(error);
    };
    this.initForm();
  }

  initForm() {
    this.formModel = this.ParameterUtils.init_parameter_in({
      gav_kbez: {
        bez: this.gettext("Name"),
        typ: "string",
        prio: 1,
        options: {
          required: true
        }
      },
      gav_bez: {
        bez: this.gettext("Beschreibung"),
        typ: "richtext",
        prio: 2,
        options: {
          required: false
        }
      },
      typ: {
        bez: this.gettext("Typ"),
        typ: "single_choice",
        prio: 3,
        katalog: "kgutachtentypen",
        options: {
          required: true
        }
      },
      gruppe: {
        bez: this.gettext("Ergebnis"),
        typ: "single_choice",
        prio: 4,
        katalog: "kgutachtenergebnisgruppen",
        options: {
          required: true
        }
      },
      gav_erlaeuterung_pflicht: {
        bez: this.gettext("Erläuterung zum Ergebnis erzwingen"),
        typ: "boolean",
        prio: 5,
        options: {
          default: true
        }
      },
      workflow: {
        bez: this.gettext("Workflow"),
        typ: "single_choice",
        prio: 6,
        katalog: "workflow_gutachten",
        options: {
          required: true
        }
      },
      content_types: {
        bez: this.gettext("Gutachtenobjekte"),
        typ: "multi_select",
        prio: 7,
        katalog: "gutachtentypen",
        options: {
          required: true,
          displaymode: "select2"
        },
      },
    });
    this.form_keys = this.ParameterUtils.get_ordered_keys(this.formModel);
    this.errorLabels = {};
    this.form_keys.forEach(
      k => {
        this.errorLabels[k] = `${ this.formModel[k].label }: `;
      }
    );
  }
}

export default GutachtenvorlageCreateController;
