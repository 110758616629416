import AnsprechpartnerModalZuweisenMe from "../AnsprechpartnerModalZuweisenMe/AnsprechpartnerModalZuweisenMe.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "AnsprechpartnerAssignMeButtonModal",
  components: {
    AnsprechpartnerModalZuweisenMe,
    PuxButton,
  },
  inject: [
    "updateAnsprechpartner",
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    ansprechpartner: {
      type: Object,
      required: true,
    },
    antragPk: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModalAssignMeVisible: false,
    };
  },
  computed: {
    selectorClose() {
      return `#${ this.id }`;
    },
  },
  methods: {
    openModalAssignMeAnsprechpartner() {
      this.isModalAssignMeVisible = true;
    },

    closeModalAssignMeAnsprechpartner({ response } = {}) {
      if (response) {
        this.updateAnsprechpartner({
          ansprechpartner: response.foerderorg_ansprechpartner,
        });
      }
      this.isModalAssignMeVisible = false;
    },
  },
};
