// @vue/component
export default {
  props: {
    moduleUrl: {
      type: String,
      required: true,
    },
    module: {
      type: Object,
      required: true,
    },
    tagesordnungspunkt: {
      type: Object,
      required: true,
    },
    permissionSrc: {
      type: Array,
      required: true,
    },
  },
  emits: [
    "updateModule",
  ],
};
