// @vue/component
export default {
  data() {
    return {
      componentsListe: [
        // Antragsteller
        {
          value: "AntragstellerList",
          permissions: [
            "antragstellerorg.view",
          ],
        },
        // Auszahlungen
        {
          value: "AuszahlungList",
          permissions: [
            "auszahlung.view"
          ],
        },
        // Anträge
        {
          value: "AntraegeList",
          permissions: [
            "foerderantrag.view"
          ],
        },
        // Belege
        {
          value: "BelegeList",
          permissions: [
            "vertragskostenpositionsbelege.view"
          ],
        },
        // Verträge
        {
          value: "VertragList",
          permissions: [
            "vertrag.view"
          ],
        },
      ],
    };
  },
};
