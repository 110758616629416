import NachrichtenLink from "../../../../../Nachrichten/NachrichtenLink/NachrichtenLink.vue";
import PuxIcon from "../../../../../../global/components/PuxIcon/PuxIcon.vue";

import KommunikationsdatenUUIDs from "../../../../../../const/KommunikationsdatenUUIDs";

import {
  get,
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragOverviewOneAstAnsprechpartner",
  components: {
    NachrichtenLink,
    PuxIcon,
  },
  props: {
    ansprechpartner: {
      type: Object,
      required: true,
    },
    ansprechpartnerIndex: {
      type: Number,
      required: true,
    },
    vertragPk: {
      type: String,
      required: true,
    },
  },
  computed: {
    ansprechpartnerName() {
      return get(this.ansprechpartner, "nutzer.name");
    },

    ansprechpartnerEmail() {
      return get(this.ansprechpartner, "nutzer.n_email");
    },

    nachrichtenLinkLabel() {
      return `${ this.ansprechpartnerName } (${ this.ansprechpartner.asp_kommentar })`;
    },

    nachrichtenLinkId() {
      return `nachrichten_link_ast_ansprechpartner_${ this.ansprechpartnerIndex }`;
    },

    ansprechpartnerKommdaten() {
      return get(this.ansprechpartner, "nutzer.kommunikationsdaten", []);
    },

    ansprechpartnerKommdatenExist() {
      return !!(this.ansprechpartnerKommdaten.length);
    },

    phoneNumbers() {
      return filter(this.ansprechpartner.nutzer.kommunikationsdaten, item => {
        return item.kommtyp && item.kommtyp.kot_id === KommunikationsdatenUUIDs.telefonnr1;
      });
    },
  },
};
