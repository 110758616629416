import BetragsrechnerMixin from "../../Mixins/BetragsrechnerMixin";

// @vue/component
export default {
  name: "MdmKostenMitBegruendung",
  mixins: [
    BetragsrechnerMixin,
  ],
  data() {
    return {
      labelWert: "_LBL_KOSTEN_MIT_BEGRUENDUNG_WERT_",
      labelBegruendung: "_LBL_KOSTEN_MIT_BEGRUENDUNG_BEGRUENDUNG_",
    };
  },
  computed: {
    optionsWert() {
      return {
        id: "wert",
        htmlId: this.htmlIdWert,
        label: this.labelWert,
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdWert() {
      return `${ this.htmlId }_wert`;
    },

    optionsBegruendung() {
      return {
        id: "begruendung",
        htmlId: this.htmlIdBegruendung,
        label: this.labelBegruendung,
        view: this.view,
        type: "text",
        maxlength: 200,
        required: false,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdBegruendung() {
      return `${ this.htmlId }_begruendung`;
    },

    errorLabels() {
      return {
        wert: {
          label: this.labelWert,
          link: true,
          id: this.htmlIdWert,
        },
        begruendung: {
          label: this.labelBegruendung,
          link: true,
          id: this.htmlIdBegruendung,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
  },
};
