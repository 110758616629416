import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";

import {
  EventBus,
} from "../../../../global/functions/event-bus";
import {
  cloneDeep,
  isUndefined,
} from "lodash-es";

const D_KLASSE = {
  antragstellerorganisationen: "2ece5b25-e867-33c8-e050-007f01013bb8",
  foerderantraege: "2ece5b25-e866-33c8-e050-007f01013bb8",
};

// @vue/component
export default {
  name: "DokumenteUploadNew",
  components: {
    FormElement,
    FormstepDetail,
  },
  data() {
    return {
      optionsFormStep: {
        label: "_TXT_NEUE_DOKUMENTE_HOCHLADEN_",
      },
      model: {
        aktentyp: undefined,
        aktennummer: undefined,
        dokumententyp: undefined,
      },
      uploadAllowed: false,
      options: {
        multiple: true,
        drop: true,
        type: "document",
      },
      optionsUpload: {
        aktentyp: {
          type: "select",
          id: "aktentyp",
          view: "v",
          label: "_LBL_OPTIONS_UPLOAD_AKTENTYP_",
          keyId: "value",
          data: [
            {
              value: "antragstellerorganisationen",
              label: "_LBL_OPTIONS_UPLOAD_ANTRAGSTELLER_"
            },
            {
              value: "foerderantraege",
              label: "_LBL_OPTIONS_UPLOAD_ANTRAG_"
            }
          ],
          dataTranslate: true,
          change: this.checkAktenTyp,
        },
        aktennummer: {
          type: "select",
          id: "aktennummer",
          view: "v",
          label: "_LBL_OPTIONS_UPLOAD_AKTENNUMMER_",
          keyId: "pk",
          keyLabel: "name",
          searchGlobal: true,
          searchParameter: "dmsakte",
          disabled: true,
          hideIf: false,
          change: this.checkUpload,
        },
        dokumententyp: {
          type: "select",
          id: "dokumententyp",
          view: "v",
          label: "_LBL_OPTIONS_UPLOAD_DOKUMENTENTYP_",
          keyLabel: "name",
          keyId: "pk",
          disabled: true,
          hideIf: false,
          change: this.checkUpload,
          search: true,
          searchList: ["name"],
        },
      },
    };
  },
  computed: {
    getUrl() {
      return "dokumente/dmsakte/";
    },
  },
  methods: {
    checkAktenTyp() {
      this.uploadAllowed = false;
      if (isUndefined(this.model.aktentyp)) {
        this.disableAll();
      } else {
        this.enableCorresponding();
      }
    },

    disableAll() {
      this.model.aktennummer = undefined;
      this.model.dokumententyp = undefined;
      this.optionsUpload.aktennummer.disabled = true;
      this.optionsUpload.dokumententyp.disabled = true;
    },

    enableCorresponding() {
      this.model.aktennummer = undefined;
      this.model.dokumententyp = undefined;

      this.optionsUpload.aktennummer.disabled = false;
      this.optionsUpload.aktennummer.url = `dokumente/dmsakte/${ this.model.aktentyp }/`;

      this.optionsUpload.dokumententyp.disabled = false;
      this.optionsUpload.dokumententyp.url = `dokumentarten/?klasse=${ D_KLASSE[this.model.aktentyp] }`;
    },

    checkUpload() {
      this.uploadAllowed = !!(!isUndefined(this.model.aktennummer) && this.model.dokumententyp);
      if (this.uploadAllowed) {
        this.options.saveUrl = `${ this.model.aktentyp }/${ this.model.aktennummer }/dokumente/?dokart=${ this.model.dokumententyp }`;
      }
    },

    addInput({ currentModel }) {
      const CURR_MODEL = cloneDeep(currentModel);
      CURR_MODEL.url = this.getDocumentUrl(this.model.aktentyp, this.model.aktennummer, currentModel.pk);
      EventBus.$emit("addDocument", { document: CURR_MODEL });
    },

    getDocumentUrl(type, objPk, docPk) {
      const DOK_URL = {
        antragstellerorganisationen: `antragstellerorganisationen/${ objPk }/dokumente/${ docPk }/`,
        foerderantraege: `foerderantraege/${ objPk }/dokumente/${ docPk }/`,
      };
      return DOK_URL[type];
    },
  },
};
