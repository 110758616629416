"use strict";

import angular from "angular";
import "restangular";

class NutzerkommdatenService {
  /*@ngInject*/
  constructor($q, $filter, KKommtyp) {
    this.name = "NutzerkommdatenService";
    this.$q = $q;
    this.orderBy = $filter("orderBy");
    this.KKommtyp = KKommtyp;
  }

  initPossibleKommdatenFields(user) {
    const fieldsDeferred = this.$q.defer();

    this.KKommtyp.getList().then(result => {
      const kommtypen = this.orderBy(result, "kot_pos");
      const fields = [];
      angular.forEach(kommtypen, function(item) {
        let userKommData = { nkm_id: "", nkm_wert: null, kommtyp: item };
        user.kommunikationsdaten.map(d => {
          if (d.kommtyp.kot_id === item.kot_id) {
            userKommData = d;
          }
        });
        fields.push(userKommData);
      });
      fieldsDeferred.resolve(fields);
    });

    return fieldsDeferred.promise;
  }
}

export default NutzerkommdatenService;
