"use strict";

import angular from "angular";
import "angular-strap";

const diasDatepickerModule = angular.module("dias.datepicker", [
  "mgcrea.ngStrap.datepicker",
]);

// Globale Konfigurieruation von AngularStraps bs-datepicker
diasDatepickerModule.config($datepickerProvider => {
  angular.extend($datepickerProvider.defaults, {
    dateFormat: "mediumDate",
    startWeek: 1,
    iconLeft: "glyphicon-chevron-left",
    iconRight: "glyphicon-chevron-right",
    autoclose: true,
    container: "body",
    // Nur Datums-Teil des ISO-Zeitstempels für Django-Rest-Framework Kompatibilität
    dateType: "string",
    modelDateFormat: "yyyy-MM-dd"
  });
});

export default diasDatepickerModule;
