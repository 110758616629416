import {
  FilterCurrencyMixin,
} from "../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  mixins: [
    FilterCurrencyMixin,
  ],
  data() {
    return {
      labelTeilnehmerUebernachtung: "Teilnehmer*innen mit Übernachtung",
    };
  },
  computed: {
    optionsTeilnehmerUebernachtung() {
      return {
        id: "teilnehmer_uebernachtung",
        htmlId: this.htmlIdTeilnehmerUebernachtung,
        label: this.labelTeilnehmerUebernachtung,
        view: this.view,
        type: "text",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdTeilnehmerUebernachtung() {
      return `${ this.htmlId }_teilnehmer_uebernachtung`;
    },

    vorschauTageTeilnehmerPauschale() {
      return this.filterCurrency(this.vorschau.tage_teilnehmer_pauschale);
    },

    vorschauTeilnehmerPauschale() {
      const PAUSCHALE = this.vorschau.teilnehmer_pauschale;
      if (PAUSCHALE) {
        return this.filterCurrency(PAUSCHALE);
      }
      return "-";
    },

    vorschauTeilnehmerUebernachtungPauschale() {
      return this.filterCurrency(this.vorschau.teilnehmer_uebernachtung_pauschale);
    },

    vorschauReisekostenPauschale() {
      const PAUSCHALE = this.vorschau.reisekosten_pauschale;
      if (PAUSCHALE) {
        return this.filterCurrency(PAUSCHALE);
      }
      return "-";
    },

    vorschauHonorartagePauschale() {
      return this.filterCurrency(this.vorschau.honorartage_pauschale);
    },

    vorschauHonorarPauschale() {
      const PAUSCHALE = this.vorschau.honorar_pauschale;
      if (PAUSCHALE) {
        return this.filterCurrency(PAUSCHALE);
      }
      return "-";
    },
  },
};
