import {
  FilterSpacedMixin,
} from "../../mixins/FiltersMixin";

// @vue/component
export default {
  name: "Spaced",
  mixins: [
    FilterSpacedMixin,
  ],
  props: {
    model: {
      type: String,
      required: false,
      default: undefined,
      info: "Text, der richtig formatieren sein soll",
    },
  },
  computed: {
    getFilteredModel() {
      return this.filterSpaced(this.model);
    },
  },
};
