"use strict";

class AufgabeZuweisenController {
  /*@ngInject*/
  constructor($scope, $q, DiasNotification, Foerderantrag) {
    this.DiasNotification = DiasNotification;
    this.Foerderantrag = Foerderantrag;
    this.nutzer = null;
    this.antrag = $scope.extras.antrag;
    this.vertrag = $scope.extras.vertrag;
    this.antrag_id = $scope.extras.antrag_id;
    this.vertrag_id = $scope.extras.vertrag_id;
    this.ansprechpartner = $scope.extras.ansprechpartner;
    this.ansprechpartnerSearch = [];

    this.save = () => {
      const deferred = $q.defer();
      this.Foerderantrag.one(this.antrag_id).customPOST(
        { foerderorg: this.ansprechpartner.org.pk, nutzer: this.nutzer },
        "ansprechpartner_aendern"
      ).then(
        response => {
          this.DiasNotification.page.success("Ansprechpartner zugewiesen");
          if (this.antrag) {
            this.antrag.foerderorg_ansprechpartner = response.foerderorg_ansprechpartner;
          }
          if (this.vertrag) {
            this.vertrag.foerderorg_ansprechpartner = response.foerderorg_ansprechpartner;
          }
          deferred.resolve(response);
        },
        error => {
          deferred.reject();
          this.onError(error);
        }
      );
      return deferred.promise;
    };

    this.onError = error => {
      const errorsLabel = {
        nutzer: "Nutzer: ",
      };
      this.DiasNotification.form.clear();
      this.DiasNotification.form.error(
        error.data,
        "Bitte überprüfen Sie Ihre Eingaben",
        {},
        errorsLabel
      );
      this.errors = error.data;
    };

    this.searchAnsprechpartner = term => {
      if (term === "") {
        // Suche zurücksetzen.
        this.ansprechpartnerSearch = [];
        return;
      }
      const params = { aspsuche_suche: term, aspsuche_foerderorg: this.ansprechpartner.org.pk };
      this.Foerderantrag.one(this.antrag_id).customGET("ansprechpartner_auswahl", params).then(result => {
        this.ansprechpartnerSearch = result;
      });
    };
  }
}

export default AufgabeZuweisenController;
