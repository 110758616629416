"use strict";

class DynamischeMehrfachauswahlReadOnlyController {
  /*@ngInject*/
  constructor() {
    this.showDetails = {};
  }

  doShowDetails(antwort, parent) {
    if (!antwort.label) {
      return;
    }
    let key = antwort.label;
    if (parent) {
      key = `${ parent.label }__${ key }`;
    }
    return this.showDetails[key];
  }

  toggleDetails($event, antwort, parent) {
    $event.stopPropagation();
    $event.preventDefault();
    if (!antwort.label) {
      return;
    }
    let key = antwort.label;
    if (parent) {
      key = `${ parent.label }__${ key }`;
    }
    this.showDetails[key] = !this.showDetails[key];
  }
}

export default DynamischeMehrfachauswahlReadOnlyController;
