"use strict";

import projektberichte from "./projektberichte";


const parameter = angular.module("dias.projektberichte.parameter", [
  projektberichte.name,
]);

export default parameter;
