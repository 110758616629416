import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import getTableApi from "../../../../../const/TableApis.js";
import SmartTable from "../../../../../global/components/table/table.vue";
import PermissionMixin from "../../../../../global/mixins/PermissionMixin";

const TABLE_ID = "VerwendungListInServerfunktion";

// @vue/component
export default {
  name: "VerwendungList",
  components: {
    AngularLink,
    SmartTable,
  },
  mixins: [
    PermissionMixin,
  ],
  props: {
    serverfunktion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: "",
        cols: [
          {
            label: "_LBL_SERVERFUNKTION_DETAILS_VERWENDUNG_LIST_TABLE_WORKFLOWFOLGE_",
            id: "pk",
            slot: "workflowfolge",
          },
          {
            label: "_LBL_SERVERFUNKTION_DETAILS_VERWENDUNG_LIST_TABLE_AUFGABE_",
            id: "aufgabe",
            slot: "aufgabe",
          },
          {
            label: "_LBL_SERVERFUNKTION_DETAILS_VERWENDUNG_LIST_TABLE_NACHFOLGE_AUFGABE_",
            id: "nachfolge_aufgabe",
            slot: "nachfolge_aufgabe",
          },
          {
            label: "_LBL_SERVERFUNKTION_DETAILS_VERWENDUNG_LIST_TABLE_WORKFLOW_",
            id: "workflow",
            slot: "workflow",
          },
        ],
        rowActions: [

        ],
        actions: [

        ],
        label: "_TXT_SERVERFUNKTION_DETAILS_VERWENDUNG_LIST_TABLE_HEADER_",
        sortable: false,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
        csvHide: true,
      },
    };
  },
  computed: {
    canViewWorklfowfolge() {
      return this.checkPermissionsSync({ perm: "workflowfolge.ui" });
    },

    canViewAufgabe() {
      return this.checkPermissionsSync({ perm: "kaufgabe.ui" });
    },

    canViewWorklfow() {
      return this.checkPermissionsSync({ perm: "workflow.ui" });
    },
  },
  created() {
    this.initUrl();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { serverfunktionPk: this.serverfunktion.pk } });
    },
  },
};
