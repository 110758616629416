import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "AuszahlungNotifikationBox",
  components: {
    AsideRightBox,
    PuxTranslate,
  },
  props: {
    bodyHtml: {
      type: String,
      required: false,
      default: undefined,
    },
    headerText: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      optionsAsideRightBox: {
        icon: "warning",
      },
    };
  },
};
