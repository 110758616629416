import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import FormElementReadOnly from "../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";

import {
  filter,
  get,
  forEach,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragNachweisTr",
  components: {
    AngularLink,
    FormElementReadOnly,
    PuxButton,
    PuxTranslate,
    SnapshotIcon,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    baseUrl: {
      type: String,
      required: false,
      default: undefined,
    },
    regel: {
      type: Object,
      required: true,
    },
    element: {
      type: Object,
      required: true,
    },
    spalten: {
      type: Array,
      required: true,
    },
    statusSnapshot: {
      type: Boolean,
      required: false,
    },
    statusSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    statusSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    statusSnapshotChanges: {
      type: Boolean,
      required: false,
    },
    statusSnapshotChangesIcon: {
      type: Boolean,
      required: false,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    snapshotChangesDiff: {
      type: Object,
      required: false,
      default: undefined,
    },
    auszahlung: {
      type: Object,
      required: false,
      default: undefined,
    },
    htmlRegelNummer: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      status: {
        creating: false,
      },
    };
  },
  computed: {
    statusShowButtons() {
      return !this.statusSnapshotDelete && !this.statusSnapshotChanges;
    },

    snapshotIconFirstTdType() {
      if (this.statusSnapshotAdd) {
        return "add";
      }
      if (this.statusSnapshotDelete) {
        return "delete";
      }
      if (this.statusSnapshotChangesIcon) {
        return "changes";
      }
      return "";
    },

    translateVersionTdOptions() {
      return {
        text: this.translateVersionTdText,
        extra: {
          date: this.snapshotDate,
        },
      };
    },

    translateVersionTdText() {
      if (this.statusSnapshotAdd) {
        return "_TXT_AKTUELLE_VERSION_ADDED_COLUMN_{{date}}_";
      }
      if (this.statusSnapshotDelete) {
        return "_TXT_VERSION_REMOVED_COLUMN_{{date}}_";
      }
      if (this.statusSnapshotChanges) {
        return "_TXT_VERSION_MODIFIED_COLUMN_{{date}}_";
      }
      return "_TXT_AKTUELLE_VERSION_";
    },

    classVersion() {
      if (this.statusSnapshotDelete || this.statusSnapshotChanges) {
        return "version-bg";
      }
      return "";
    },

    rowspanFirstTd() {
      return this.statusSnapshotChangesIcon ? 2 : 1;
    },

    statusShowFirstTd() {
      return !(this.statusSnapshotChanges && !this.statusSnapshotChangesIcon);
    },

    spaltenFiltered() {
      if (this.statusSnapshotChanges && this.snapshotChangesDiff) {
        return filter(this.spalten, item => {
          return this.snapshotChangesDiff[item.id];
        });
      }
      return this.spalten;
    },

    rowspan() {
      const ROWSPAN = {};
      if (this.statusSnapshotChangesIcon && this.snapshotChangesDiff) {
        forEach(this.spalten, item => {
          const ID = item.id;
          if (!this.snapshotChangesDiff[ID]) {
            ROWSPAN[ID] = 2;
          }
        });
      }
      return ROWSPAN;
    },

    hasAuszahlung() {
      return !isNil(this.element.az_info.az_id);
    },

    hasModulPermissionCreate() {
      return get(this.regel, "rechte.create", false);
    },

    isButtonVisible() {
      return !this.hasAuszahlung && this.hasModulPermissionCreate && !this.isSnapshotRow;
    },

    isSnapshotRow() {
      return this.statusSnapshotChanges || this.statusSnapshotDelete;
    },

    buttonId() {
      return `${ this.htmlRegelNummer }_${ this.element.nr }_create`;
    },

    tagForAzInfo() {
      return this.auszahlung ?
        "angular-link" :
        "span";
    },

    attributesForAzInfo() {
      return this.auszahlung ?
        {
          sref: "root.vertrag.details.auszahlung",
          parameter: {
            id: this.auszahlung.vertrag,
            aus_id: this.element.az_info.az_id,
          },
        } :
        {};
    },
  },
  methods: {
    createAuszahlung() {
      const DATA = {};
      DATA.regel = this.regel.pk;
      DATA.modalitaet_param = this.element.modalitaet_param;
      DATA.periodenkennung = this.element.periodenkennung;
      this.status.creating = true;
      this.postHttp({
        url: `${ this.baseUrl }create_auszahlung_durch_regel/`,
        data: DATA,
      }).then(
        response => {
          this.addNotification({
            text: "_MSG_GR_VERTRAG_NACHWEISE_CREATE_SUCCESS_{{typ}}_",
            extra: { typ: this.element.typ }
          });
          this.$goTo("root.vertrag.details.auszahlung", { id: response.vertrag, aus_id: response.pk });
        },
        () => {
          this.addNotification({
            text: "_MSG_GR_VERTRAG_NACHWEISE_CREATE_ERROR_{{typ}}_",
            extra: { typ: this.element.typ },
            type: "error"
          });
        }
      ).finally(() => this.status.creating = false);
    },
  },
};
