import "mediaelement";
import "mediaelement/build/mediaelementplayer.min.css";
import "mediaelement/build/mediaelement-flash-video.swf";
import "mediaelement-plugins/dist/a11y/a11y";
import "mediaelement-plugins/dist/a11y/a11y.css";

import translate from "../../directives/translate";

import {
  getInternalOrExternalPathToFile,
} from "../../functions/utils";
import {
  forEach,
  isNil,
  isFunction,
  sortBy,
  toString,
} from "lodash-es";

const SUBTITLES_BUTTON_LANG_LABELS = {
  de: "Deutsch",
  en: "English",
};

class Timer {
  constructor(callback, durationList) {
    this.callback = callback;
    this.durationList = durationList;
    this.remainingTime = undefined;
    this.startTime = undefined;
    this.timerId = undefined;

    this.initRemainingTime();
    this.pause = this.pause.bind(this);
    this.resume = this.resume.bind(this);
    this.clear = this.clear.bind(this);
    this.onTimeout = this.onTimeout.bind(this);
  }

  initRemainingTime() {
    if (this.durationList && this.durationList.length) {
      this.remainingTime = this.durationList[0].duration;
    }
  }

  pause() {
    clearTimeout(this.timerId);
    this.remainingTime -= (new Date() - this.startTime);
  }

  resume() {
    this.startTime = new Date();
    clearTimeout(this.timerId);
    if (this.remainingTime && this.remainingTime >= 0) {
      this.timerId = setTimeout(this.onTimeout, this.remainingTime);
    }
  }

  clear() {
    this.remainingTime = -1;
    clearTimeout(this.timerId);
  }

  onTimeout() {
    if (!this.durationList.length) {
      return;
    }
    this.callback({ key: this.durationList[0].key });
    this.setDurationForDurationList();
    this.initRemainingTime();
    this.resume();
  }

  setDurationForDurationList() {
    const DURATION_FIRST = this.durationList.splice(0, 1);
    if (!DURATION_FIRST || !DURATION_FIRST.length) {
      return;
    }
    const { duration } = DURATION_FIRST[0];
    forEach(this.durationList, item => {
      item.duration -= duration;
    });
  }
}

// @vue/component
export default {
  name: "MediaPlayer",
  directives: {
    translate,
  },
  props: {
    mediaType: {
      type: String,
      required: false,
      default: "video",
      validator: value => ["video", "audio"].indexOf(value) !== -1,
    },
    htmlId: {
      type: String,
      required: true,
    },
    width: {
      type: [String, Number],
      required: false,
      default: 640,
    },
    height: {
      type: [String, Number],
      required: false,
      default: 360,
    },
    poster: {
      type: String,
      required: false,
      default: undefined,
    },
    sources: {
      type: Array,
      required: true,
    },
    tracks: {
      type: Array,
      required: false,
      default: undefined,
    },
    preload: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    options: {
      type: Object,
      required: false,
      default: undefined,
    },
    videoDescription: {
      type: Array,
      required: false,
      default: undefined,
    },
    audioDescription: {
      type: Array,
      required: false,
      default: undefined,
    },
    autoPlay: {
      type: Boolean,
      required: false,
    },
    muted: {
      type: Boolean,
      required: false,
    },
    durationList: {
      type: Array,
      default: () => [
        "25%", "50%", "75%", "100%", // kann 2, 5, 10, 20, 30, 40, 50, "5%", "77%",
      ],
    },
    ariaDescribedby: {
      type: String,
      required: false,
      default: undefined,
    },
    onVideoUsage: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  emits: [
    "onVideoUsage",
  ],
  data() {
    return {
      player: undefined,
      timer: undefined,
      timerSeeking: undefined,
      pauseSeekingPlay: undefined,
      duration: undefined,
      durationListLocal: [],
      durationSended: [],
      currentTime: undefined,
      statusStartFirst: undefined,
    };
  },
  computed: {
    mediaBody() {
      return `${ this.sourceTags.join("\n") }${ this.tracksTags.join("\n") }`;
    },

    sourceTags() {
      const SOURCE_TAGS = [];
      if (this.sources && this.sources.length) {
        forEach(this.sources, source => {
          SOURCE_TAGS.push(`<source src="${ source.src }" type="${ source.type }">`);
        });
      }
      return SOURCE_TAGS;
    },

    tracksTags() {
      const TRACKS_TAGS = [];
      if (this.tracks && this.tracks.length) {
        forEach(this.tracks, track => {
          const LABEL = track.label ? track.label : SUBTITLES_BUTTON_LANG_LABELS[track.lang || "de"];
          const SOURCE = getInternalOrExternalPathToFile(track.src);
          TRACKS_TAGS.push(`<track src="${ SOURCE }" kind="${ track.kind || "subtitles" }" srclang="${ track.lang }" label="${ LABEL }">`);
        });
      }
      return TRACKS_TAGS;
    },

    videoDescriptionLocal() {
      if (!this.videoDescription && !this.videoDescription.length) {
        return "";
      }
      return JSON.stringify(this.videoDescription);
    },

    audioDescriptionLocal() {
      if (!this.audioDescription && !this.audioDescription.length) {
        return "";
      }
      return JSON.stringify(this.audioDescription);
    },

    optionsLocal() {
      return {
        features: this.featureslocal,
        success: this.onSuccess,
        stretching: "responsive",
        startLanguage: "de",
        iconSprite: "/static/img/mejs-controls.svg",
      };
    },

    featureslocal() {
      return [
        "playpause",
        "current",
        "progress",
        "duration",
        "volume",
        "fullscreen",
        "a11y",
        "tracks",
      ];
    },

    attributesLocal() {
      const ATTRIBUTES = {};
      if (this.autoPlay) {
        ATTRIBUTES.autoplay = true;
      }
      if (this.muted) {
        ATTRIBUTES.muted = true;
      }
      if (this.ariaDescribedby) {
        ATTRIBUTES["aria-describedby"] = this.ariaDescribedby;
      }
      return ATTRIBUTES;
    },
  },
  mounted() {
    this.initMediaPlayer();
  },
  beforeUnmount() {
    this.deleteTimer();
    this.destroyMediaPlayer();
  },
  methods: {
    initMediaPlayer() {
      this.player = new MediaElementPlayer(this.htmlId, this.optionsLocal);
    },

    onSuccess(mediaElement, node) {
      if (this.autoPlay) {
        const PROMISE = node.play();
        if (!isNil(PROMISE)) { // Workaround https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
          PROMISE.then(
            () => {},
            () => {}
          );
        }
      }

      mediaElement.addEventListener("loadedmetadata", () => { // Meta data (like dimensions and duration) are loaded
        this.setDurationListLocal(mediaElement.duration);
      });

      mediaElement.addEventListener("playing", () => { // The media actually has started playing
        if (this.pauseSeekingPlay === "seeking") {
          this.pauseSeekingPlay = undefined;
          return;
        }
        this.$emit("onVideoUsage", {
          key: this.statusStartFirst ? "weiter" : "play",
        });
        this.statusStartFirst = true;
        this.startTimer();
      });

      mediaElement.addEventListener("pause", () => {
        if (this.timer) {
          this.timer.pause();
        }
        if (mediaElement.currentTime !== mediaElement.duration) { // not end
          this.pauseSeekingPlay = "pause";
          this.timerSeeking = setTimeout(() => {
            this.$emit("onVideoUsage", { key: "pause" });
            this.pauseSeekingPlay = undefined;
          }, 150);
        }
      });

      mediaElement.addEventListener("timeupdate", () => {
        this.currentTime = mediaElement.currentTime;
      });

      mediaElement.addEventListener("seeking", () => {
        if (this.pauseSeekingPlay === "pause") {
          clearTimeout(this.timerSeeking);
          this.pauseSeekingPlay = "seeking";
        }

        if (this.currentTime > mediaElement.currentTime) {
          this.$emit("onVideoUsage", { key: "spulen_rueckwaerts" });
        } else if (this.currentTime < mediaElement.currentTime) {
          this.$emit("onVideoUsage", { key: "spulen_vorwaerts" });
        }
      });

      mediaElement.addEventListener("ended", () => {
        this.$emit("onVideoUsage", { key: "fertig" });
        this.currentTime = 0;
      });
    },

    deleteTimer() {
      if (this.timer) {
        if (isFunction(this.timer.clear)) {
          this.timer.clear();
        }
        this.timer = undefined;
      }
    },

    destroyMediaPlayer() {
      if (this.player && isFunction(this.player.remove)) {
        this.player.remove();
      }
    },

    setDurationListLocal(duration) {
      this.duration = duration * 1000;
      const DURATION_LIST_LOCAL = [];
      forEach(this.durationList, durationKey => {
        let duration = durationKey * 1000;
        const DURATION_KEY_STRING = toString(durationKey);
        if (DURATION_KEY_STRING[DURATION_KEY_STRING.length - 1] === "%") {
          const DURATION_KEY_STRING_WITHOUT_PERCENT = DURATION_KEY_STRING.slice(0, -1);
          duration = this.duration / 100 * DURATION_KEY_STRING_WITHOUT_PERCENT;
          if (DURATION_KEY_STRING === "100%") {
            duration -= 500;
          }
        }
        DURATION_LIST_LOCAL.push({
          duration: duration,
          key: DURATION_KEY_STRING,
        });
      });
      this.durationListLocal = sortBy(DURATION_LIST_LOCAL, ["duration"]);
    },

    startTimer() {
      if (!this.timer) {
        this.timer = new Timer(({ key } = {}) => {
          this.$emit("onVideoUsage", { key });
          this.durationSended.push(key);
        }, this.durationListLocal);
      }
      this.timer.resume();
    },

    pause() {
      if (this.player && this.player.pause) {
        this.player.pause();
      }
    },
  },
};
