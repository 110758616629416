import PuxAlert from "../../../global/components/PuxAlert/PuxAlert.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import KDatenschutzfragetypUUIDs from "../../../const/KDatenschutzfragetypUUIDs";

// @vue/component
export default {
  name: "DatenschutzAbfrage",
  components: {
    PuxAlert,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    modelValue: {
      type: Boolean,
    },
    frage: {
      type: Object,
      required: true,
    },
    htmlId: {
      type: String,
      required: false,
      default: "datenschutz-einwilligung",
    },
  },
  emits: [
    "update:modelValue",
  ],
  data() {
    return {
      KDatenschutzfragetypUUIDs: KDatenschutzfragetypUUIDs,
    };
  },
  computed: {
    frageHref() {
      return `/api/datenschutz/dokument/${ this.frage.pk }/`;
    },

    translateOptionsLink() {
      return {
        text: "_LNK_DATENSCHUTZ_ABFRAGE_{{titel}}_",
        extra: {
          titel: this.frage.def_titel,
        },
      };
    },

    ariaChecked() {
      return this.modelValue ? "true" : "false";
    },
  },
  methods: {
    onChange() {
      this.$emit("update:modelValue", !this.modelValue);
    },
  },
};
