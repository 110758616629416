import HttpMixin from "../../../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../../../global/mixins/PermissionMixin";
import translate from "../../../../../global/directives/translate";
import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Alert from "../../../../../global/components/Alert/Alert.vue";
import {
  toFormElementFromParameter
} from "../../../../../global/functions/mappingForParameterToFormElement";
import {
  find,
  get,
  isNil,
  map,
  pick,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "Konfiguration",
  components: {
    FormstepDetail,
    Alert,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    serverfunktion: {
      type: Object,
      required: true,
    },
    updateServerfunktion: {
      type: Function,
      required: true
    },
  },
  computed: {
    canEdit() {
      return this.hasConfig && this.checkPermissionsSync({ perm: "serverfunktion.update" });
    },

    options() {
      const OPTIONS = {
        label: "_TXT_SERVERFUNKTION_DETAILS_KONFIGURATION_PANEL_FORMSTEP_NAME_",
        info: "_HTML_SERVERFUNKTION_DETAILS_KONFIGURATION_PANEL_FORMSTEP_INFO_",
        list: toFormElementFromParameter({ obj: this.serverfunktion.config_in, showLabel: true }).list,
        saveCallback: this.save,
      };
      OPTIONS.required = !isNil(find(OPTIONS.list, el => get(el, "required") || get(el, "options.required")));
      return OPTIONS;
    },

    hasConfig() {
      return size(this.options.list) > 0;
    },

    translationNoConfig() {
      return {
        text: "_MSG_SERVERFUNKTION_DETAILS_KONFIGURATION_NO_CONFIG_INFO_"
      };
    },
  },
  methods: {
    save({ model }) {
      const DATA = {
        wfs_parameterin: pick(model, map(this.options.list, option => option.id))
      };
      return this.patchHttp({
        url: `serverfunktionen/${ this.serverfunktion.pk }/`,
        data: DATA,
      }).then(
        response => {
          this.updateServerfunktion({ serverfunktion: response });
          this.addNotification({
            text: "_MSG_SERVERFUNKTION_DETAILS_KONFIGURATION_UPDATE_SUCCESS_{{bez}}_",
            extra: {
              bez: this.serverfunktion.wfs_bez,
            },
          });
        },
        errors => {
          const CONF_ERROR = {
            data: get(errors, "data.wfs_parameterin", get(errors, "data", errors))
          };
          return Promise.reject(CONF_ERROR);
        }
      );
    },
  },
};
