import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  FilterLimitToMixin,
  FilterSpacedMixin,
} from "../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "NachrichtenListColumnObject",
  components: {
    PuxTranslate,
  },
  mixins: [
    FilterLimitToMixin,
    FilterSpacedMixin,
  ],
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
  computed: {
    placeholder() {
      switch (this.object.type) {
      case "antragsteller":
        return {
          text: "_TXT_NACHRICHTEN_LIST_ANTRAGSTELLER_{{name}}_",
          extra: { name: this.filterLimitTo(this.object.ast_name, 50) },
        };
      case "antrag":
        return {
          text: "_TXT_NACHRICHTEN_LIST_ANTRAG_{{number}}_",
          extra: { number: this.filterSpaced(this.object.a_nr) },
        };
      case "sitzung":
        return {
          text: "_TXT_NACHRICHTEN_LIST_SITZUNG_{{number}}_",
          extra: { number: this.object.si_sitzungsnr },
        };
      case "top":
        return {
          text: "_TXT_NACHRICHTEN_LIST_TAGESORDNUNGSPUNKT_{{bez}}_",
          extra: { bez: this.object.tos_bez },
        };
      case "vertrag":
        return {
          text: "_TXT_NACHRICHTEN_LIST_VERTRAG_{{number}}_",
          extra: { number: this.filterSpaced(this.object.v_nr) },
        };
      case "auszahlung":
        return {
          text: "_TXT_NACHRICHTEN_LIST_AUSZAHLUNG_{{number}}_",
          extra: { number: `${ this.filterSpaced(this.object.v_nr) }-${ this.object.az_nummer }` },
        };
      case "sammelvertrag":
        return {
          text: "_TXT_NACHRICHTEN_LIST_WEITERLEITUNGSVERTRAG_{{number}}_",
          extra: { number: `${ this.object.ast_nr }-${ this.object.sv_nr }` },
        };
      case "formular":
        return {
          text: `${ this.object.for_titel }`,
          extra: null,
        };
      }
      return null;
    },
  },
};
