import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

// @vue/component
export default {
  name: "VersionsinformationenModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        vin_datum: undefined,
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "vin_versionsnummer",
            type: "text",
            label: "_LBL_VERSIONSINFORMATIONEN_MODAL_CREATE_VERSIONSNUMMER_",
            required: true,
            maxlength: 200,
            view: "v",
            translate: true,
          },
          {
            id: "vin_informationen",
            type: "richtext",
            label: "_LBL_VERSIONSINFORMATIONEN_MODAL_CREATE_INFORMATIONEN_",
            required: true,
            view: "v-alt",
            translate: true,
          },
          {
            id: "vin_datum",
            type: "datetime",
            label: "_LBL_VERSIONSINFORMATIONEN_MODAL_CREATE_DATUM_",
            required: true,
            view: "v-alt",
            translate: true,
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  methods: {
    save() {
      this.status.loading = true;
      this.postHttp({ url: `releasenotes/`, data: this.model }).then(
        response => {
          this.addNotification({
            text: "_MSG_VERSIONSINFORMATIONEN_MODAL_CREATE_SUCCESS_{{titel}}_",
            extra: { titel: this.model.vim_versionsnummer },
          });
          this.close({ response });
          this.$goTo("root.releasenotes.details", { id: response.pk });
        },
        error => {
          const errorTemp = error.data;
          const kommError = errorTemp.kommunikationsdaten;
          if (kommError && kommError[0] && kommError[0].nkm_wert) {
            errorTemp.kommunikationsdaten = kommError[0].nkm_wert;
          }
          this.errors = errorTemp;
          this.errorsClone = errorTemp;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
