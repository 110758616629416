import { EventBus } from "../../../functions/event-bus";
import AutoFocus from "../../../directives/AutoFocus";
import widthMap from "../../../const/WidthMap";
import { scrollToGlobal } from "../../../functions/help";
import PuxCloak from "../../PuxCloak/PuxCloak.vue";

// @vue/component
export default {
  name: "TableViewSymbol",
  components: {
    PuxCloak,
  },
  directives: {
    AutoFocus,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    detailsObj: {
      type: Object,
      default: () => ({}),
    },
    replaceDetailsObj: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      currentIndexShow: undefined,
      currentRowIndex: undefined,
      currentRowPk: undefined,
      windowWidth: 0,
    };
  },
  computed: {
    getButtonId() {
      return index => `${ this.options.id }_${ index }`;
    },

    getSlotNameSymbol() {
      return `${ this.options.id }_symbol`;
    },

    getSlotNameSymbolDetails() {
      return `${ this.options.id }_symbol_details`;
    },
  },
  created() {
    this.initEventBus();
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      this.showCurrentIndex();
    });
  },
  beforeUnmount() {
    EventBus.$off(`${ this.options.id }updateData`, this.setDefaultIndex);
  },
  methods: {
    initEventBus() {
      EventBus.$on(`${ this.options.id }updateData`, this.setDefaultIndex);
    },

    closeDetails() {
      this.setDefaultIndex();
    },

    setDefaultIndex() {
      this.currentRowIndex = undefined;
      this.currentIndexShow = undefined;
      this.currentRowPk = undefined;
    },

    scrollTo(index) {
      scrollToGlobal(`#${ this.getButtonId(index) }`);
    },

    openDetails(row, index) {
      if (this.currentRowIndex === index) {
        this.setDefaultIndex();
        return;
      }

      if (!this.detailsObj[row[this.options.rowId]]) {
        this.replaceDetailsObj(row);
      } else {
        this.replaceDetailsObj(row, true);
      }

      this.currentRowPk = row[this.options.rowId];

      this.currentRowIndex = index;
      this.scrollTo(index);
      this.showCurrentIndex(true);
    },

    showCurrentIndex(statusOpen) {
      let countTemp;
      let currentIndexShowTemp;
      if (this.windowWidth >= widthMap.xl.width) {
        countTemp = widthMap.xl.count;
      } else if (this.windowWidth >= widthMap.lg.width) {
        countTemp = widthMap.lg.count;
      } else if (this.windowWidth >= widthMap.md.width) {
        countTemp = widthMap.md.count;
      } else {
        countTemp = widthMap.sm.count;
      }

      const currentIndexShow = Math.floor((this.currentRowIndex) / countTemp) * countTemp + (countTemp - 1);
      if (currentIndexShow > this.rows.length - 1) {
        currentIndexShowTemp = this.rows.length - 1;
      } else {
        currentIndexShowTemp = currentIndexShow;
      }

      if (currentIndexShowTemp !== this.currentIndexShow || statusOpen) {
        this.currentIndexShow = undefined;
        setTimeout(() => {
          this.currentIndexShow = currentIndexShowTemp;
        }, 1);
      }
    },
  },
};
