import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PanelComponents from "./PanelComponents";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "BerichteVerwaltungDetails",
  components: {
    ContextPanel,
    Modal,
    PuxButton,
    PuxPageDetails,
    PuxTranslate,
    ...PanelComponents,
  },
  mixins: [
    HttpMixin,
  ],
  data() {
    return {
      loading: true,
      tableau: {},
      confirmShow: undefined,
      confirmOptions: undefined,
      buttonDeleteId: "berichte_verwaltung_modal_delete",
      panels: [
        {
          label: "_TXT_BERICHTE_VERWALTUNG_DETAILS_KONFIGURATION_PANEL_NAME_",
          icon: "glyphicon-file",
          active: true,
          pk: "bericht",
          components: [
            {
              name: "BerichteVerwaltungDetailsKonfiguration",
              pk: "konfiguration",
            },
          ],
        },
      ],
    };
  },
  computed: {
    selectorClose() {
      return `#${ this.buttonDeleteId }`;
    },
  },
  created() {
    this.loadTableau();
  },
  methods: {
    loadTableau() {
      this.getHttp({ url: `tableau/${ this.$stateParams.id }/` }).then(
        response => {
          this.tableau = response;
          const rollen_ids = [];
          response.rollen.forEach(item => rollen_ids.push(item.id));
          this.tableau.rollen_ids = rollen_ids;
        }
      ).then(
        () => this.loading = false
      );
    },

    updateTableau({ tableau }) {
      this.tableau = tableau;
      const rollen_ids = [];
      tableau.rollen.forEach(item => rollen_ids.push(item.id));
      this.tableau.rollen_ids = rollen_ids;
    },

    openModalDeleteBericht() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_BERICHT_VRWLTNG_DETAILS_DELETE_",
        msg: "_MSG_BERICHT_VWLTNG_DETAILS_DELETE_",
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: () => this.deleteTableau(),
        cancelCallback: this.closeConfirmBericht,
      };
      this.confirmShow = true;
    },

    deleteTableau() {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: `tableau/${ this.tableau.pk }/`,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_BERICHT_VWLTNG_DETAILS_DELETE_SUCCESS_{{kbez}}_",
            extra: {
              kbez: this.tableau.tab_kbez,
            },
          });
          this.closeConfirmBericht();
          this.$goTo("root.tableau.admin");
        }
      ).then(
        this.confirmOptions.loading = false
      );
    },

    closeConfirmBericht() {
      this.confirmShow = false;
    },
  },
};
