import {
  computed,
} from "vue";

import Foerderdokument from "./Foerderdokument/Foerderdokument.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";
import SyConfigAPI from "../../../../global/compositionAPI/SyConfigAPI";

import {
  createNamespacedHelpers,
} from "vuex";
import {
  get,
  forEach,
  isEmpty,
} from "lodash-es";

const {
  mapMutations,
} = createNamespacedHelpers("snapshot");

const SNAPSHOT_FIELDS_LIST = [
  "ars_kbez",
  "ars_version",
  "ars_gueltig_ab",
  "ars_gueltig_bis",
];

// @vue/component
export default {
  name: "AntragDetailsAngebot",
  components: {
    Foerderdokument,
    FormstepDetail,
    SnapshotIcon,
    SnapshotModule,
    PuxTranslate,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    regelsatzObj: {
      type: Object,
      required: true,
    },
    foerderdokumente: {
      type: Array,
      required: false,
      default: undefined,
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    const {
      getSyConfigsValue,
    } = SyConfigAPI();

    const antragConfig = computed(() => {
      return getSyConfigsValue("foerderantrag", {});
    });

    return {
      antragConfig,
      filterDate,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        info: this.regelsatzObj.ars_astbez,
      };
    },

    labelHeader() {
      return this.antragConfig.angebot_detail ? "_TXT_ANTRAG_DETAILS_HEADER_BASISDATEN_" : "";
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },

    currentSnapshot() {
      return get(this.snapshot, "asn_snapshot.antrag.regelsatz_obj");
    },

    statusSnapshot() {
      return !!this.snapshot;
    },

    snapshotDate() {
      if (this.statusSnapshot) {
        return this.snapshot.asn_snapshot_datum;
      }
      return undefined;
    },

    foerderdokumenteLocal() {
      return this.foerderdokumente || [];
    },

    extra() {
      return {
        foerderprogrammeTranslate: this.getFoerderprogrammeTranslate(this.regelsatzObj),
        gueltigExtraTranslate: this.getExtraGueltigTranslate(this.regelsatzObj),
        gueltigTextTranslate: this.getTextGueltigTranslate(this.regelsatzObj),
      };
    },

    extraSnapshot() {
      if (!this.statusDiff) {
        return;
      }
      return {
        foerderprogrammeTranslate: this.getFoerderprogrammeTranslate(this.currentSnapshot),
        gueltigTranslate: this.getGueltigTranslate(this.currentSnapshot),
      };
    },

    diff() {
      const DIFF = {};
      if (this.statusSnapshot && this.currentSnapshot) {
        forEach(SNAPSHOT_FIELDS_LIST, key => {
          if (this.regelsatzObj[key] !== this.currentSnapshot[key]) {
            DIFF[key] = true;
          }
        });
      }
      return DIFF;
    },
  },
  watch: {
    statusDiff: {
      immediate: true,
      handler(newVal) {
        this.MUT_SET_STATUS_DIFF_FOR_REGEL({
          regelId: this.id,
          statusDiff: newVal,
        });
      },
    },
  },
  beforeUnmount() {
    this.MUT_DELETE_STATUS_DIFF_FOR_REGEL({ regelId: this.id });
  },
  methods: {
    getFoerderprogrammeTranslate(obj) {
      return obj.foerderprogramme && obj.foerderprogramme.length === 1 ?
        "_TXT_FOERDERPROGRAMME_" :
        "_TXT_FOERDERPROGRAMM_";
    },

    getExtraGueltigTranslate(obj) {
      if (obj.ars_gueltig_bis) {
        return {
          gueltig_ab: this.filterDate(obj.ars_gueltig_ab),
          gueltig_bis: this.filterDate(obj.ars_gueltig_bis),
        };
      }
      return { gueltig_ab: this.filterDate(obj.ars_gueltig_ab) };
    },

    getTextGueltigTranslate(obj) {
      if (obj.ars_gueltig_bis) {
        return "_TXT_ANTRAG_DETAILS_GUELTIG_ZEITRAUM_{{gueltig_ab}}_{{gueltig_bis}}_";
      }
      return "_TXT_ANTRAG_DETAILS_GUELTIG_AB_{{gueltig_ab}}_";
    },

    getGueltigTranslate(obj) {
      if (obj.ars_gueltig_bis) {
        return {
          text: "_TXT_ANTRAG_DETAILS_GUELTIG_ZEITRAUM_{{gueltig_ab}}_{{gueltig_bis}}_",
          extra: {
            gueltig_ab: this.filterDate(obj.ars_gueltig_ab),
            gueltig_bis: this.filterDate(obj.ars_gueltig_bis),
          },
        };
      }
      return {
        text: "_TXT_ANTRAG_DETAILS_GUELTIG_AB_{{gueltig_ab}}_",
        extra: {
          gueltig_ab: this.filterDate(obj.ars_gueltig_ab),
        },
      };
    },

    ...mapMutations([
      "MUT_SET_STATUS_DIFF_FOR_REGEL",
      "MUT_DELETE_STATUS_DIFF_FOR_REGEL",
    ]),
  },
};
