import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

const TABLE_ID = "aenderungsanzeige";

// @vue/component
export default {
  name: "AenderungsanzeigeList",
  components: {
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: "aenderungsanzeigen/",
        cols: [
          {
            label: "_LBL_AENDERUNGSANZEIGE_LIST_LAUFNUMMER_",
            id: "aa_laufnr",
          },
          {
            label: "_LBL_AENDERUNGSANZEIGE_LIST_BEZUG_",
            id: "ct_name"
          },
          {
            label: "_LBL_AENDERUNGSANZEIGE_LIST_OBJEKT_",
            id: "aa_objektname",
            sortable: true,
            sortId: "aa_objektname",
          },
          {
            label: "_LBL_AENDERUNGSANZEIGE_LIST_HOCHSCHULE_",
            id: "hochschule",
            sortable: true,
            sortId: "hochschule",
          },
          {
            label: "_LBL_AENDERUNGSANZEIGE_LIST_STATUS_",
            id: "status_bez"
          },
          {
            label: "_LBL_AENDERUNGSANZEIGE_LIST_ERSTELLUNGSDATUM_",
            id: "aa_erstellungsdatum",
            filter: "date",
            filterParameter: "DD.MM.YYYY HH:mm",
          },
          {
            label: "_LBL_AENDERUNGSANZEIGE_LIST_BEMERKUNG_",
            id: "aa_bemerkung",
          },
        ],
        rowActions: [
          {
            label: "_LBL_AENDERUNGSANZEIGE_LIST_ACTION_ROW_DETAILS_",
            route: "root.aenderungsanzeige.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [],
        label: "_TXT_AENDERUNGSANZEIGE_LIST_HOCHSCHULKOMPASS_VERWALTEN_",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
        filterKeyGroupList: [
          "_LBL_AENDERUNGSANZEIGE_LIST_SONSTIGE_",
        ],
      },
      filters: [
        {
          type: "text",
          id: "aa_laufnr",
          label: "_LBL_AENDERUNGSANZEIGE_LIST_LAUFNUMMER_",
          group: "_LBL_AENDERUNGSANZEIGE_LIST_SONSTIGE_",
        },
        {
          type: "text",
          id: "aa_objektname",
          label: "_LBL_AENDERUNGSANZEIGE_LIST_OBJEKTNAME_",
          group: "_LBL_AENDERUNGSANZEIGE_LIST_SONSTIGE_",
          main: true,
        },
        {
          type: "multiselect",
          id: "status",
          url: "katalog/?fields=pk&fields=bez&key=aenderungsanzeigestatus",
          label: "_LBL_AENDERUNGSANZEIGE_LIST_STATUS_",
          keyLabel: "bez",
          keyId: "pk",
          search: false,
          searchList: ["bez"],
          searchParameter: "bez",
          group: "_LBL_AENDERUNGSANZEIGE_LIST_SONSTIGE_",
        },
        {
          type: "select",
          id: "bezug",
          data: ["Studiengang", "Hochschule"],
          label: "_LBL_AENDERUNGSANZEIGE_LIST_BEZUG_",
          keyArray: true,
          group: "_LBL_AENDERUNGSANZEIGE_LIST_SONSTIGE_",
        },
        {
          type: "text",
          id: "aa_bemerkung",
          label: "_LBL_AENDERUNGSANZEIGE_LIST_BEMERKUNG_",
          group: "_LBL_AENDERUNGSANZEIGE_LIST_SONSTIGE_",
        },
        {
          type: "text",
          id: "hs_bez",
          label: "_LBL_AENDERUNGSANZEIGE_LIST_HOCHSCHULE_",
          group: "_LBL_AENDERUNGSANZEIGE_LIST_SONSTIGE_",
        },
      ],
    };
  },
};
