import FormElement from "../../../../../../../libs/vue/client/vue/global/components/FormElement/FormElement.vue";
import Modal from "../../../../../../../libs/vue/client/vue/global/components/Modal/Modal.vue";
import PuxButton from "../../../../../../../libs/vue/client/vue/global/components/PuxButton/PuxButton.vue";

import HttpMixin from "../../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import NotificationMixin from "../../../../../../../libs/vue/client/vue/global/mixins/NotificationMixin";

import { EventBus } from "../../../../../../../libs/vue/client/vue/global/functions/event-bus";
import KStudiengangartUUIDs from "../../../../../../../libs/vue/client/vue/const/KStudiengangartUUIDs";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "HochschuleModalCreate",
  components: {
    FormElement,
    Modal,
    PuxButton,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    studiengang: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        studiengangAuswahl: [],
      },
      status: {
        loading: false,
      },
      errors: {
        studiengangAuswahl: undefined,
      },
    };
  },
  computed: {
    studiengangAuswahlOptions() {
      return {
        id: "studiengangAuswahl",
        type: "multiselect",
        label: "Studiengänge",
        required: true,
        view: "v-alt",
        url: "studiengaenge/",
        slot: "studiengang",
        urlParams: {
          hochschule: this.studiengang.hochschule,
          not_art: [
            KStudiengangartUUIDs.KOMBI,
            KStudiengangartUUIDs.TEIL,
          ],
        },
        keyId: "pk",
        search: true,
        searchList: ["name", "lehramtstyp_kbez"],
      };
    },

    options() {
      return {
        showCloseButton: true,
        required: true,
      };
    },

    teilStudiengangUrl() {
      return `studiengaenge/${ this.studiengang.pk }/teilstudiengaenge/`;
    },

    teilStudiengangZuordnenUrl() {
      return `studiengaenge/${ this.studiengang.pk }/tsg_mantel_zuordnen/`;
    },
  },
  methods: {
    save() {
      this.status.loading = true;
      const DATA = cloneDeep(this.studiengang);
      DATA.studiengangAuswahl = this.model.studiengangAuswahl;
      this.putHttp({
        url: this.teilStudiengangZuordnenUrl,
        data: DATA,
      }).then(
        () => {
          this.close();
          this.addNotification({ text: "Teilstudiengänge zuordnen" });
          EventBus.$emit("loadTeilStudiengange");
        },
        error => {
          this.addNotification({
            text: "Fehler beim Laden der Studiengänge.",
            type: "error",
          });
          this.errors = error.data;
        }
      ).finally(
        () => {
          this.status.loading = false;
        }
      );
    },
  },
};
