var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["wizardIcon"] = jade_interp = function(label, text, className){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div class=\"wizard-icon d-inline-block\"><span" + (jade.attr("ng-class", "" + (className) + "", true, true)) + " class=\"step-number\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "</span><div class=\"step-text\">{{:: " + (jade.escape((jade_interp = text) == null ? '' : jade_interp)) + "}}</div></div>");
};
buf.push("<div dias-cloak=\"vm.loading\" cloak-size=\"'xs'\" ng-if=\"!vm.statusComponentDisable\" class=\"box wizard\"><h5>{{vm.kontextName}} {{vm.relObjLabel}}&nbsp;<span ng-bind-html=\"vm.relObjName\" class=\"nowrap\"></span><br><span ng-if=\"vm.foeangebot.ars_kbez\">{{ vm.foeangebot.ars_kbez }} - Version {{ vm.foeangebot.ars_version }}</span><div ng-if=\"vm.addionalText\"><br></div><span>{{ vm.addionalText }}</span></h5><div ng-if=\"vm.wfStatusGruppen &amp;&amp; vm.wfStatusGruppen.length &gt; 0\" ng-class=\"{'success': vm.relevantObj.workflowstatusgruppe.workflowstatusgruppentyp === vm.WGT_ERFOLG, 'warning': vm.relevantObj.workflowstatusgruppe.workflowstatusgruppentyp ===  vm.WGT_MISSERFOLG}\" class=\"detail opened\"><div class=\"d-flex justify-content-center no-margin\"><div ng-repeat=\"gruppe in vm.wfStatusGruppen | orderBy: 'wsg_pos'\" ng-class=\"{'active': vm.relevantObj.workflowstatusgruppe.pk === gruppe.pk}\" class=\"step flex-fill text-center\">");
jade_mixins["wizardIcon"]("{{:: gruppe.wizard_pos}}", "vm.gettext(gruppe.wsg_kbez)", "gruppe.icon");
buf.push("</div></div></div></div>");;return buf.join("");
}