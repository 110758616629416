"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import models from "./models";
import cortex from "./cortex";

import formularlisteBoxComponent from "./formularliste.box.component";

const formularModule = angular.module("root.formular", [
  uiRouter,
  models.name,
  cortex.name,
])
  .component("formularlisteBox", formularlisteBoxComponent);

export default formularModule;
