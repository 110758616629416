import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import { gettext } from "../../../global/functions/utils";
import {
  cloneDeep,
  findIndex,
  forEach,
  isNil,
  isString,
  map,
  size,
  sortBy,
} from "lodash-es";

// @vue/component
export default {
  name: "NutzerModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    SyConfigMixin,
  ],
  props: {
    hideRolleAndOrganisation: {
      type: Boolean,
    },
    hideAuthentifizierungsmethode: {
      type: Boolean,
    },
    defaultAuthentifizierungsmethode: {
      type: String,
      default: undefined,
    },
    urlSave: {
      type: String,
      default: "nutzer/",
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      status: {
        loading: undefined,
      },
      kotFields: undefined,
      moeglicheRollenUrl: undefined,
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    options() {
      const OPTIONS = {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "n_loginname",
            type: "text",
            label: "_LBL_NUTZER_MODAL_CREATE_E_MAIL_",
            required: true,
            maxlength: 200,
            view: "v",
          },
          {
            id: "anrede",
            type: "select",
            label: "_LBL_NUTZER_MODAL_CREATE_ANREDE_",
            required: true,
            view: "v",
            keyId: "ank_id",
            keyLabel: "ank_kbez",
            menuWidthAll: true,
            url: "anreden/",
            apiSaveId: "anreden",
          },
          {
            id: "n_titel",
            type: "text",
            label: "_LBL_NUTZER_MODAL_CREATE_TITEL_",
            maxlength: 60,
            view: "v",
          },
          {
            id: "n_vorname",
            type: "text",
            label: "_LBL_NUTZER_MODAL_CREATE_VORNAME_",
            maxlength: 100,
            required: true,
            view: "v",
          },
          {
            id: "n_nachname",
            type: "text",
            label: "_LBL_NUTZER_MODAL_CREATE_NACHNAME_",
            maxlength: 100,
            required: true,
            view: "v",
          }
        ],
      };
      if (this.syConfigNutzerTitel) {
        const INDEX_TITEL = findIndex(OPTIONS.list, ["id", "n_titel"]);
        const TITEL_SELECT = {
          label: "_LBL_NUTZER_MODAL_CREATE_TITEL_",
          view: "v",
          type: "select",
          url: "katalog/?fields=pk&fields=bez&key=titel",
          keyId: "pk",
          keyLabel: "bez",
          id: "titel",
        };
        OPTIONS.list.splice(INDEX_TITEL, 1, TITEL_SELECT);
      }
      if (this.hideRolleAndOrganisation) {
        OPTIONS.list.unshift({
          id: "readonly",
          type: "readonly",
          text: gettext("_HTML_NUTZER_CREATE_HINWEIS_"),
          class: "alert alert-info",
        });
      } else {
        OPTIONS.list.unshift(...[
          {
            id: "organisation",
            type: "select",
            label: "_LBL_NUTZER_MODAL_CREATE_ORGANISATION_",
            required: true,
            view: "v",
            keyId: "pk",
            keyLabel: "o_name",
            menuWidthAll: true,
            url: `/nutzer/moegliche_organisationen_create/`,
            keyGroup: "group",
            search: true,
            searchList: ["o_name"],
            change: ({ item }) => this.updataeMoeglicheRollenUrl(item)
          },
          {
            id: "rolle",
            type: "select",
            label: "_LBL_NUTZER_MODAL_CREATE_ROLLE_",
            required: true,
            view: "v",
            keyId: "fro_id",
            keyLabelCallback: ({ item }) => `${ item.fro_kbez } - ${ item.fro_bez }`,
            menuWidthAll: true,
            disabled: isNil(this.moeglicheRollenUrl),
            url: this.moeglicheRollenUrl,
          },
        ]);
      }
      if (!this.hideAuthentifizierungsmethode) {
        OPTIONS.list.unshift(...[
          {
            id: "authmethod",
            type: "select",
            label: "_LBL_NUTZER_MODAL_CREATE_AUTHENTIFIZIERUNGSMETHODE_",
            required: true,
            view: "v",
            keyId: "pk",
            keyLabel: "kbez",
            menuWidthAll: true,
            url: "katalog/?fields=pk&fields=kbez&key=authmethods",
          },
        ]);
      }
      if (size(this.kotFields) > 0) {
        OPTIONS.list.push(...this.kotFields);
      }
      return OPTIONS;
    },

    syConfigNutzerTitel() {
      return this.getSyConfigsValue("nutzer_titel");
    },

  },
  created() {
    this.initKotFields();
    this.setDefaultValues();
  },
  methods: {
    initKotFields() {
      const conf = this.getSyConfigsValue("registration_default") || {};
      if (conf.kot_ids && conf.kot_ids.length > 0) {
        this.getHttp({
          url: "katalog/?key=kkommtyp",
          urlParams: { pk: conf.kot_ids }
        }).then(
          response => {
            this.kotFields = map(
              sortBy(response, kot => conf.kot_ids.indexOf(kot.pk)),
              kot => {
                return {
                  id: kot.pk,
                  type: "text",
                  label: kot.kot_kbez,
                  required: kot.kot_pflicht,
                  view: "v",
                  helpText: kot.kot_hilfetext,
                };
              }
            );
          }
        );
      }
    },

    updataeMoeglicheRollenUrl(org) {
      this.moeglicheRollenUrl = `/nutzer/verwendbare_rollen_create/?organisation=${ org.pk }`;
    },

    setDefaultValues() {
      this.model = {
        authmethod: this.defaultAuthentifizierungsmethode,
      };
    },

    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      const conf = this.getSyConfigsValue("registration_default") || {};
      data.kommunikationsdaten = [];
      if (conf.kot_ids && conf.kot_ids.length > 0) {
        forEach(conf.kot_ids, pk => {
          data.kommunikationsdaten.push({
            kommtyp: { kot_id: pk },
            nkm_wert: data[pk],
          });
          delete data[pk];
        });
      }
      this.postHttp({
        url: this.urlSave,
        data: data,
        showError: true,
      }).then(
        response => {
          this.addNotification({ text: "_MSG_NUTZER_MODAL_CREATE_NUTZER_ANGELEGT_" });
          this.close({ response, status: true });
          if (!this.hideRolleAndOrganisation) {
            this.$goTo("root.nutzerverwaltung.details", { id: response.pk });
          }
        },
        error => {
          this.onError({ error });
        }
      ).then(
        () => this.status.loading = false
      );
    },

    onError({ error }) {
      if (error.status !== 429) {
        const ERROR = error.data;
        if (ERROR.kommunikationsdaten) {
          forEach(ERROR.kommunikationsdaten, item => {
            forEach(item, (subitem, subkey) => {
              if (isString(subitem)) {
                ERROR[subkey] = [subitem];
              } else {
                ERROR[subkey] = subitem;
              }
            });
          });
          delete ERROR.kommunikationsdaten;
        }
        this.errors = ERROR;
        this.errorsClone = ERROR;
      }
    },
  },
};
