"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";


class WorkflowfolgenEditBeschreibungController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, ParameterUtils, DiasNotification, i18n, AuthService) {
    super($q, $scope, DiasNotification);
    this.ParameterUtils = ParameterUtils;
    this.AuthService = AuthService;
    this.gettext = i18n.gettext;
    this.initForm();
  }

  _save() {
    const data = this.ParameterUtils.to_request_body(this.formModel, null, "");
    Object.assign(this.model, data, {});
    return this.model.patch(data).then(
      response => {
        if (response.workflow === null) {
          response.workflow_bez = null;
        }
        if (response.nachfolge_workflow === null) {
          response.nachfolge_workflow_bez = null;
        }
        if (response.aufgabe === null) {
          response.aufgabe_bez = null;
        }
        if (response.nachfolge_aufgabe === null) {
          response.nachfolge_aufgabe_bez = null;
        }
        this.$scope.model.merge(this.$scope.model, response.plain());
        this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
      },
      errors => this.onErrors(errors, this.errorLabels)
    );
  }

  onEnterEditMode() {
    super.onEnterEditMode();
    this.updateFormModel();
  }

  updateFormModel() {
    if (this.ParameterUtils && this.formModelTemplate && this.model) {
      this.formModel = this.ParameterUtils.params_from_template(this.formModelTemplate, this.model);
    }
  }

  initForm() {
    this.queuePerms = this.AuthService.syncHasPerms(["workflowfolge.view.queue", "workflowfolge.update.queue"]);
    this.formModelTemplate = this.ParameterUtils.init_parameter_in({
      wfo_kbez_admin: {
        bez: this.gettext("Name in Administration"),
        typ: "string",
        prio: 0,
        options: {
          required: true
        }
      },
      wfo_kbez: {
        bez: this.gettext("Name"),
        typ: "string",
        prio: 1,
        options: {
          required: true
        }
      },
      wfo_pos: {
        bez: this.gettext("Position"),
        typ: "integer",
        prio: 2,
        options: {
          required: true
        }
      },
      wfo_anzeige_typ: {
        bez: this.gettext("Anzeige-Styling"),
        typ: "string",
        prio: 3,
        options: {
          required: false
        }
      },
      wfo_anzeige_icon: {
        bez: this.gettext("Icon"),
        typ: "string",
        prio: 4,
        options: {
          required: false
        }
      },
      wfo_tracking_info: {
        bez: this.gettext("Tracking Info"),
        typ: "json",
        prio: 5,
        options: {
          required: false
        }
      },
      queue: {
        bez: this.gettext("Abarbeitungsschlange"),
        typ: "single_choice",
        katalog: "taskqueues",
        localSearch: true,
        readonly: !this.queuePerms["workflowfolge.update.queue"],
        prio: 6,
        options: {
          required: false
        }
      },
      aufgabe: {
        bez: this.gettext("Ausgangsaufgabe"),
        typ: "single_choice",
        katalog: "kaufgabe",
        localSearch: true,
        prio: 7,
        options: {
          required: false
        }
      },
      nachfolge_aufgabe: {
        bez: this.gettext("Nachfolgeaufgabe"),
        typ: "single_choice",
        katalog: "kaufgabe",
        localSearch: true,
        prio: 8,
        options: {
          required: false
        }
      },
      workflow: {
        bez: this.gettext("Workflow"),
        typ: "single_choice",
        katalog: "workflow",
        localSearch: true,
        prio: 9,
        options: {
          required: false
        }
      },
      nachfolge_workflow: {
        bez: this.gettext("Nachfolgeworkflow"),
        typ: "single_choice",
        katalog: "workflow",
        localSearch: true,
        prio: 10,
        options: {
          required: false
        }
      },
      wfo_reload: {
        bez: this.gettext("Lädt die Workflowfolge nach der Ausführung die Seite neu"),
        typ: "boolean",
        prio: 11,
        options: {
          required: false
        }
      },
    });
    if (!this.queuePerms["workflowfolge.view.queue"]) {
      delete this.formModelTemplate.queue;
    }
    this.form_keys = this.ParameterUtils.get_ordered_keys(this.formModelTemplate);
    this.errorLabels = {};
    this.form_keys.forEach(
      k => {
        this.errorLabels[k] = `${ this.formModelTemplate[k].bez }: `;
      }
    );
    this.form_keys_left = this.form_keys.slice(0, 6);
    this.form_keys_right = this.form_keys.slice(6);
    this.updateFormModel();
  }
}

export default WorkflowfolgenEditBeschreibungController;
