"use strict";

const DynamicRoot = angular.module("dias.dynamicroot", [])

  .config($stateProvider => {
    $stateProvider
      .state("root.dynamicroot", {
        url: `/roots/:id/`,
        views: {
          "main@root": {
            template: "<div vueinit><vue-dynamic-root></vue-dynamic-root></div>"
          }
        },
      })
    ;
  });

export default DynamicRoot;
