import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import PuxGet from "../../../../global/components/PuxGet/PuxGet.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import PuxErrors from "../../../../global/components/PuxErrors/PuxErrors.vue";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "WorkflowsDetailsStammdaten",
  components: {
    PuxTranslate,
    FormstepDetail,
    AngularLink,
    PuxGet,
    FormElement,
    PuxErrors,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    workflow: {
      type: Object,
      required: true,
      info: "Hauptinformation",
    },
  },
  emits: [
    "updateWorkflow",
  ],
  data() {
    return {
      options: {
        label: "_TXT_WF_DETAILS_PANEL_STAMMDATEN_",
        openCallback: this.open,
        saveCallback: this.save,
      },
      model: {},
      htmlId: "wf_stammdaten",
      labelWfKbez: "_LBL_WF_DETAILS_STAMMDATEN_NAME_",
      labelWfBez: "_LBL_WF_DETAILS_STAMMDATEN_BESCHREIBUNG_",
      labelTyp: "_LBL_WF_DETAILS_STAMMDATEN_WORKFLOWTYP_",
      labelWfStart: "_LBL_WF_DETAILS_STAMMDATEN_EINSTIEGSWORKFLOW_",
      labelStartAufgabe: "_LBL_WF_DETAILS_STAMMDATEN_STARTAUFGABE_",
      labelWifIds: "_LBL_WF_DETAILS_STAMMDATEN_INITIIERUNGSFUNKTIONEN_",
      labelServerfunktionen: "_LBL_WF_DETAILS_STAMMDATEN_SERVERFUNKTIONEN_",
      errors: {},
    };
  },
  computed: {
    statusHasPermissionWorkflowtypUi() {
      return this.checkPermissionsSync({ perm: "workflowtyp.ui" });
    },

    statusHasPermissionKaufgabeUi() {
      return this.checkPermissionsSync({ perm: "kaufgabe.ui" });
    },

    statusHasPermissionServerfunktionUi() {
      return this.checkPermissionsSync({ perm: "serverfunktion.ui" });
    },

    statusEditable() {
      return this.checkPermissionsSync({ perm: "workflow.update" });
    },

    optionsKbez() {
      return {
        id: "wf_kbez",
        htmlId: this.htmlIdKbez,
        label: this.labelWfKbez,
        type: "text",
        required: true,
        view: "v",
      };
    },

    htmlIdKbez() {
      return `${ this.htmlId }_wf_kbez`;
    },

    optionsBez() {
      return {
        id: "wf_bez",
        htmlId: this.htmlIdBez,
        label: this.labelWfBez,
        type: "richtext",
        required: true,
        view: "v-alt",
      };
    },

    htmlIdBez() {
      return `${ this.htmlId }_wf_bez`;
    },

    optionsWorkflowtyp() {
      return {
        id: "workflowtyp",
        htmlId: this.htmlIdWorkflowtyp,
        label: this.labelTyp,
        type: "select",
        required: true,
        view: "v",
        url: "katalog/?key=workflowtyp",
        apiSaveId: "workflowtyp",
        keyLabel: "bez",
        keyId: "pk",
        search: true,
        searchList: ["bez"],
      };
    },

    htmlIdWorkflowtyp() {
      return `${ this.htmlId }_workflowtyp`;
    },

    optionsWfStart() {
      return {
        id: "wf_start",
        htmlId: this.htmlIdWfStart,
        label: this.labelWfStart,
        type: "boolean",
        required: true,
        view: "v-alt",
      };
    },

    htmlIdWfStart() {
      return `${ this.htmlId }_wf_start`;
    },

    optionsStartAufgabe() {
      return {
        id: "start_aufgabe",
        htmlId: this.htmlIdStartAufgabe,
        label: this.labelStartAufgabe,
        type: "select",
        required: true,
        view: "v",
        url: "katalog/?key=kaufgabe",
        apiSaveId: "kaufgabe",
        keyLabel: "bez",
        keyId: "pk",
        search: true,
        searchList: ["bez"],
      };
    },

    htmlIdStartAufgabe() {
      return `${ this.htmlId }_start_aufgabe`;
    },

    optionsWifIds() {
      return {
        id: "wif_ids",
        htmlId: this.htmlIdWifIds,
        label: this.labelWifIds,
        type: "selectordered",
        required: true,
        view: "v-alt",
        url: "katalog/?key=workflowinitfunktionen",
        apiSaveId: "workflowinitfunktionen",
        keyLabel: "bez",
        keyId: "pk",
        search: true,
        searchList: ["bez"],
      };
    },

    htmlIdWifIds() {
      return `${ this.htmlId }_wif_ids`;
    },

    optionsServerfunktionen() {
      return {
        id: "serverfunktionen",
        htmlId: this.htmlIdServerfunktionen,
        label: this.labelServerfunktionen,
        type: "selectordered",
        required: false,
        view: "v-alt",
        url: "katalog/?key=serverfunktionen",
        apiSaveId: "serverfunktionen",
        keyLabel: "bez",
        keyId: "pk",
        search: true,
        searchList: ["bez"],
      };
    },

    htmlIdServerfunktionen() {
      return `${ this.htmlId }_serverfunktionen`;
    },

    errorLabels() {
      return {
        wf_kbez: {
          label: this.labelWfKbez,
          link: true,
          id: this.htmlIdKbez,
        },
        wf_bez: {
          label: this.labelWfBez,
          link: true,
          id: this.htmlIdBez,
        },
        workflowtyp: {
          label: this.labelTyp,
          link: true,
          id: this.htmlIdWorkflowtyp,
        },
        wf_start: {
          label: this.labelWfStart,
          link: true,
          id: this.htmlIdWfStart,
        },
        start_aufgabe: {
          label: this.labelStartAufgabe,
          link: true,
          id: this.htmlIdStartAufgabe,
        },
        wif_ids: {
          label: this.labelWifIds,
          link: true,
          id: this.htmlIdWifIds,
        },
        serverfunktionen: {
          label: this.labelServerfunktionen,
          link: true,
          id: this.htmlIdServerfunktionen,
        },
      };
    },
  },
  methods: {
    open() {
      this.deleteErrors();
      const MODEL = cloneDeep(this.workflow);
      MODEL.wif_ids = MODEL.workflowinitfkts.map(wif => wif.pk) || [];
      MODEL.serverfunktionen = MODEL.serverfunktionen_objs.map(wfs => wfs.pk) || [];
      this.model = MODEL;
    },

    save() {
      this.deleteErrors();
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: `workflows/${ this.workflow.pk }/`,
          data: this.model,
        }).then(
          response => {
            this.addNotification({ text: "_MSG_WF_DETAILS_STAMMDATEN_SUCCESS_" });
            this.$emit("updateWorkflow", { workflow: response });
            resolve();
          },
          error => {
            this.errors = error.data || {};
            reject();
          }
        );
      });
    },

    deleteErrors() {
      this.errors = {};
    },
  },
};
