import GeschaeftsregelModul from "../../../Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";

import {
  getTranslatedText,
} from "../../../../global/functions/utils";

// @vue/component
export default {
  name: "AnlagenAuszahlung",
  components: {
    GeschaeftsregelModul,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    obj: {
      type: Object,
      required: true,
    },
    infoText: {
      type: String,
      required: false,
      default: "",
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  emits: [
    "onLoadDokumentarten",
  ],
  data() {
    return {
      dokumentePermissionsFromObj: {
        view: "auszahlungdokument.view",
        create: "auszahlungdokument.create",
        delete: "auszahlungdokument.delete",
        update: "auszahlungdokument.update",
        update_gueltigkeit: "dokument.update_gueltigkeit",
      },
    };
  },
  computed: {
    parameter() {
      return {
        frage: {
          typ: "string",
          wert: null,
        },
        header_info: {
          typ: "string",
          wert: getTranslatedText(this.infoText),
        },
        readonly: {
          typ: "boolean",
          wert: false,
        },
        anzeigeart: {
          typ: "single_choice",
          wert: "2160705a-96cb-47bf-9124-537671316c22",
          katalog: "anzeigeart",
        },
        pflichtfeld: {
          typ: "boolean",
          wert: true,
        },
        default_sort: {
          typ: "single_choice",
          wert: "-dokument.crdate",
        },
        antragsteller: {
          typ: "boolean",
          wert: false,
        },
        anzeige_details: {
          typ: "multi_select",
          wert: [
            "dokument.dok_titel",
            "dokument.dokumentart.name",
            "dokument.dok_posteingang",
            "pruefstatus.status",
            "pruefstatus_gesetzt_am",
            "geprueft_von",
            "dokument.crdate",
            "dokument.user",
            "dokument.dok_loeschdatum",
            "dokument.dok_dokumentname",
            "dokument.dok_typ",
            "dokument.dok_groesse",
            "dokument.dok_unterschriftsdatum",
          ],
        },
        header_optional: {
          typ: "string",
          wert: "_TXT_ANLAGEN_AUSZAHLUNG_SONSTIGE_DOKUMENTE_",
        },
        header_required: {
          typ: "string",
          wert: "_TXT_ANLAGEN_AUSZAHLUNG_PFLICHTDOKUMENTE_",
        },
        mehrfachauswahl: {
          typ: "boolean",
          wert: false,
        },
        allowed_mime_type: {
          typ: "json",
          wert: [],
        },
        header_optional_info: {
          typ: "text",
          wert: getTranslatedText("_TXT_ANLAGEN_AUSZAHLUNG_SONSTIGE_DOKUMENTE_"),
        },
        header_required_info: {
          typ: "text",
          wert: getTranslatedText("_TXT_ANLAGEN_AUSZAHLUNG_PFLICHTDOKUMENTE_"),
        },
        validate_pflichtdoks: {
          typ: "boolean",
          wert: false,
        },
        gueltig_pflicht_felder: {
          typ: "multi_select",
          wert: [
            "von"
          ],
        },
        gueltig_fuer_dokument_hochladen: {
          typ: "boolean",
          wert: false,
        },
      };
    },

    baseUrl() {
      return `auszahlungen/${ this.obj.pk }/`;
    },

    extraStatic() {
      return {
        onLoadDokumentarten: this.onLoadDokumentarten,
        hideHeader: true,
      };
    },

    config() {
      return {
        module: {
          modulname: "anlagen_allgemein",
          regel: {
            regel: this.id,
            regel_nummer: "anlagen_auszahlung_1",
            _argument: this.parameter,
          },
        },
        readonly: false,
      };
    },
  },
  methods: {
    onLoadDokumentarten(arg) {
      this.$emit("onLoadDokumentarten", arg);
    },
  },
};
