"use strict";

import angular from "angular";
import select from "./select.component";
import UiSelect from "./uiSelect.component";


const mod = angular.module("dias.ui.select", [])

  .component("diasSelect", select)
  .component("diasUiSelect", UiSelect)
;

export default mod;
