import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxGet from "../../../../global/components/PuxGet/PuxGet.vue";
import Spaced from "../../../../global/components/Spaced/Spaced.vue";

import WorkflowModulBaseMixin from "../WorkflowModulBaseMixin";

import {
  toFormElementFromParameter,
  initModelFromList,
} from "../../../../global/functions/mappingForParameterToFormElement";

import {
  cloneDeep,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "WorkflowModulSVAktualisieren",
  components: {
    FormElement,
    Modal,
    PuxCloak,
    PuxGet,
    Spaced,
  },
  mixins: [
    WorkflowModulBaseMixin,
  ],
  data() {
    return {
      loading: undefined,
      loadingVorschau: true,
      errors: {},
      model: undefined,
      parameterIn: undefined,
      parameterObj: {},
      modelParameter: {},
      antragStatusesMapping: {
        NEW: "_TXT_SV_ANTRAG_STATUS_NEW_",
        REMOVED: "_TXT_SV_ANTRAG_STATUS_REMOVED_",
        UNCHANGED: "_TXT_SV_ANTRAG_STATUS_UNCHANGED_",
      },
    };
  },
  computed: {
    options() {
      return {
        showCloseButton: true,
        required: true,
        vorschau: {},
      };
    },

    urlForAktualisierungsvorschau() {
      return `sammelvertraege/${ this.aufgabe.obj_id }/aktualisierungsvorschau/`;
    },

    disabledButton() {
      if (this.isBudgetRequired &&
        !this.modelParameter.budget) {
        return true;
      }
      if (this.isKommentarRequired &&
        !this.modelParameter.kommentar) {
        return true;
      }
      return false;
    },

    isBudgetRequired() {
      return get(this.parameterObj, "budget.required");
    },

    isKommentarRequired() {
      return get(this.parameterObj, "kommentar.required");
    },
  },
  created() {
    this.startModul();
    this.initData();
    this.loadAktualisierungsvorschau();
  },
  methods: {
    initData() {
      this.parameterIn = this.prepareParameterIn();
    },

    loadAktualisierungsvorschau() {
      this.getHttp({
        url: this.urlForAktualisierungsvorschau,
      }).then(
        response => {
          this.vorschau = response;
          this.setOptionsForFormElements();
          this.loadingVorschau = false;
        }
      );
    },

    setOptionsForFormElements() {
      const PARAMETER_OUT = get(this.workflowfolge, "clientfunktion.wfc_parameterout", {});
      if (PARAMETER_OUT.budget) {
        PARAMETER_OUT.budget.wert = this.vorschau.sv_budget;
        PARAMETER_OUT.budget.addonBack = "_HTML_SUFFIX_EURO_";
      }
      const PARAMETER = toFormElementFromParameter({
        obj: PARAMETER_OUT
      });
      this.parameterObj = PARAMETER.object;
      this.modelParameter = initModelFromList({ list: PARAMETER.list });
    },

    save() {
      this.loading = true;
      const MODEL = cloneDeep(this.modelParameter);
      MODEL.obj_id = this.aufgabe.pk;
      this.finish({ model: MODEL }).then(
        response => {
          this.startWorkflowfolge(response);
        },
        errors => {
          this.startWorkflowfolge(errors);
        }
      ).finally(
        () => this.loading = false
      );
    },
  },
};
