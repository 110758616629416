import PuxAlert from "../../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxGet from "../../../../../global/components/PuxGet/PuxGet.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  get,
  filter,
  size
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungModalitaetChangeButtonModalData",
  components: {
    PuxAlert,
    PuxGet,
    PuxTranslate,
  },
  props: {
    data: {
      type: Object,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    show() {
      return this.data && size(this.data) > 0;
    },

    showErrors() {
      return this.show && this.data.errors && size(this.data.errors) > 0;
    },

    showMessages() {
      return this.show && this.data.messages && size(this.data.messages) > 0;
    },

    showZuschuss() {
      return get(this.data, "new.zuschuss") || get(this.data, "old.zuschuss");
    },

    oldZuschussMeldungen() {
      return get(this.data, "old.zuschuss.meldungen", []);
    },

    newZuschussMeldungen() {
      return get(this.data, "new.zuschuss.meldungen", []);
    },

    azFields() {
      const FIELDS = [
        {
          label: "_LBL_AUSZAHLUNG_WECHSEL_MODALITAET_DATA_FIELD_NAME_",
          path: "auszahlung.name",
          filter: undefined,
          css: undefined,
        },
        {
          label: "_LBL_AUSZAHLUNG_WECHSEL_MODALITAET_DATA_FIELD_AZ_NUMMER_",
          path: "auszahlung.az_nummer",
          filter: undefined,
          css: undefined,
        },
        {
          label: "_LBL_AUSZAHLUNG_WECHSEL_MODALITAET_DATA_FIELD_AZ_REFERENZNR_",
          path: "auszahlung.az_referenznr",
          filter: undefined,
          css: undefined,
        },
        {
          label: "_LBL_AUSZAHLUNG_WECHSEL_MODALITAET_DATA_FIELD_AZ_BETRAG_BEANTRAGT_",
          path: "auszahlung.uebersicht.betrag_beantragt",
          filter: "currency",
          css: "text-right",
        },
        {
          label: "_LBL_AUSZAHLUNG_WECHSEL_MODALITAET_DATA_FIELD_AZ_BETRAG_ANERKANNT_",
          path: "auszahlung.uebersicht.betrag_anerkannt",
          filter: "currency",
          css: "text-right",
        },
      ];
      return filter(FIELDS, el => get(this.data, `old.${ el.path }`) !== get(this.data, `new.${ el.path }`));
    },

    showTable() {
      return size(this.azFields) > 0 || this.showZuschuss;
    },

    showNoChanges() {
      return !this.showTable && !this.showMessages && !this.showErrors;
    },
  },
};
