import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragWarningBox",
  components: {
    AngularLink,
    AsideRightBox,
    PuxTranslate,
  },
  props: {
    antrag: {
      type: Object,
      required: true,
    },
    gegenpruefenInaktive: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      optionsAsideRightBoxWarning: {
        icon: "warning",
        toggle: false,
      },
    };
  },
  computed: {
    isBoxNotOrganisationVisible() {
      return !this.antrag.antragstellerorg;
    },

    extraForDubletteTranslate() {
      return {
        ast_nr: this.kopfdubletteAstNummer,
      };
    },

    antragstellerorgKopfdublette() {
      return get(this.antrag, "antragstellerorg_obj.kopfdublette");
    },

    kopfdubletteAstNummer() {
      return get(this.antrag, "antragstellerorg_obj.kopfdublette_obj.ast_nr");
    },

    isAntragstellerGesperrt() {
      return get(this.antrag, "antragstellerorg_obj.ast_gesperrt") === true;
    },

    astSperrbemerkung() {
      return get(this.antrag, "antragstellerorg_obj.ast_sperrbemerkung");
    },

    isBoxGegenpruefenInaktiveVisible() {
      return this.gegenpruefenInaktive && this.antrag.antragstellerorg;
    },

    isAnyWarningVisible() {
      return this.isBoxNotOrganisationVisible ||
        this.isBoxGegenpruefenInaktiveVisible ||
        this.antrag.negativ_geprueft_markierung ||
        this.antragstellerorgKopfdublette ||
        this.isAntragstellerGesperrt;
    },
  },
};
