import mock from "../mock.json";
import StyleguideTableOptionsCols from "../StyleguideTableOptionsCols/StyleguideTableOptionsCols.vue";
import StyleguideTableOptionsFilters from "../StyleguideTableOptionsFilters/StyleguideTableOptionsFilters.vue";
import StyleguideTableOptionsRowActions from "../StyleguideTableOptionsRowActions/StyleguideTableOptionsRowActions.vue";
import StyleguideTableOptionsMassActions from "../StyleguideTableOptionsMassActions/StyleguideTableOptionsMassActions.vue";
import StyleguideTableOptionsActions from "../StyleguideTableOptionsActions/StyleguideTableOptionsActions.vue";
import StyleguideTableOptionsList from "../StyleguideTableOptionsList/StyleguideTableOptionsList.vue";

import SmartTable from "../../../../global/components/table/table.vue";
import { get } from "../../../../global/functions/http";

// @vue/component
export default {
  name: "StyleguideTableFilterTop",
  components: {
    SmartTable,
    StyleguideTableOptionsCols,
    StyleguideTableOptionsFilters,
    StyleguideTableOptionsRowActions,
    StyleguideTableOptionsMassActions,
    StyleguideTableOptionsActions,
    StyleguideTableOptionsList,
  },
  data() {
    return {
      currentItem: "demo",

      options: {
        id: "StyleguideTableFilterTop",
        cols: [
          {
            label: "Titel",
            id: "kbez",
          },
          {
            label: "Gültig ab",
            id: "gueltig_ab",
          },
          {
            label: "Gültig bis",
            id: "gueltig_bis",
            hide: true,
          },
        ],
        rowActions: [
          {
            label: "Etwas schaffen1",
            callback: row => console.log("row", row),
            titleCallback: row => `${ row.kbez } schaffen`,
          },
          {
            label: "Etwas schaffen2",
            href: row => `/vuestyleguide/${ row.pk }/`,
            titleCallback: row => `${ row.kbez } schaffen`,
          },
        ],
        data: mock,
        label: "Mock Tabelle1",
        sortable: false,
        pagination: false,
        details: true,
        view: {
          filter: "top",
          details: "right",
        },
        massActions: [
          {
            label: "Nachrichten löschen",
            callback: this.deleteRows,
          },
        ],
      },
      filters: [
        {
          type: "text",
          id: "kbez",
          label: "Bezeichnung",
          alwaysVisible: true,
        },
        {
          type: "text",
          id: "kbez2",
          label: "Bezöichnung2 ÄÖÜasd",
          initialStatus: true,
        },
        {
          type: "date",
          id: "date1",
          label: "Date1",
          initialStatus: true,
        },
        {
          type: "daterange",
          id: "daterange1",
          label: "Daterange1",
          initialStatus: true,
        },
        {
          type: "boolean",
          id: "boolean1",
          label: "Boolean",
          initialStatus: true,
          trueValue: "True",
          falseValue: "False",
          trueLabel: "Das ist trueLabel",
          falseLabel: "Das ist falseLabel",
        },
        {
          type: "select",
          id: "select1",
          label: "Select1",
          initialStatus: true,
          keyId: "id",
          keyLabel: "label",
          data: [
            {
              label: "label1",
              id: "id1"
            },
            {
              label: "label2",
              id: "id2"
            },
          ]
        },
      ],
    };
  },
  methods: {
    deleteRows() {
      return get({ url: "ff/v3/foerderangebote/" }).then(
        response => {
          return response;
        }
      );
    },
  },
};
