"use strict";

import KostenController from "../../kosten.controller";
import {
  cloneDeep,
  isArray,
  isPlainObject,
  isUndefined,
  forEach,
  filter,
} from "lodash-es";
import {
  EventBus,
} from "../../../../../vue/client/vue/global/functions/event-bus";


class KostenPeriodenController extends KostenController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KKostenart, BetragsrechnerRegistry, i18n, Katalog, Personalkostentyp) {
    super($scope, $q, Foerdermodule, Foerderantrag, KKostenart, BetragsrechnerRegistry, i18n);
    this.Katalog = Katalog;
    this.Personalkostentyp = Personalkostentyp;
    this.isArray = isArray;
    this.isPlainObject = isPlainObject;
    this.closePersonalKostenWizard = this.closePersonalKostenWizard.bind(this);
    this.setPerioden();
    this.periodenForEdit = {};
    this.loadPersonalfunktionen();
    this.loadPersonalkostentabelle();
    this.loadPersonalstellenart();

    // this.extras.showErrorsHowInApi = true;
    this.setEventClosePersonalkostenPeriodenWizard();
    this.registerOrDeactiveSignalReceiver();
  }

  registerOrDeactiveSignalReceiver({ eventBusAction = "$on" } = {}) {
    const SIGNAL_EMPFANG = this.modul.regel.signal_empfang || [];
    if (!SIGNAL_EMPFANG.length) {
      return;
    }

    SIGNAL_EMPFANG.forEach(item => {
      if (item.signal_kennung && item.sender_reid) {
        EventBus[eventBusAction](`${ item.signal_kennung }_${ item.sender_reid }`, arg => this.onReceiveSignal(item.signal_kennung, arg));
      }
    });
  }

  onReceiveSignal(signal_kennung) {
    if (signal_kennung === "durchfuehrungszeitraum.update") {
      this.updateModul();
    }
  }

  loadPersonalfunktionen() {
    this.loadingPersonalfunktionen = true;
    this.personalfunktionen = [];
    this.Katalog.getKatalog("personalfunktionen").then(
      response => {
        this.personalfunktionen = this.filterPersonalfunktionen({ response: response.plain() });
        this.loadingPersonalfunktionen = false;
      }
    );
  }

  loadPersonalkostentabelle() {
    this.loadingPersonalkostentabelle = true;
    const antragId = this.modul.regel.afr_aid;
    const personalkostentypId = this.parameter.personalkostentyp;
    this.Personalkostentyp.one(personalkostentypId).customGET("get_gueltige_pkt", { a_id: antragId }).then(
      response => {
        this.personalkostentyp = response.plain();
        this.Katalog.getKatalog("personalkostentabelle", { pk: this.personalkostentyp.pk }).then(
          response => {
            if (response.plain().length) {
              this.personalkostentabelleObj = response.plain()[0];
            }
            this.loadPersonalkostenWithTabelle();
          }
        );
      }
    );
  }

  loadPersonalkostenWithTabelle() {
    this.Katalog.getKatalog("personalkosten", { tabelle: this.personalkostentabelleObj.pk }).then(
      response => {
        this.katalogPersonalkosten = response;
        this.loadingPersonalkostentabelle = false;
      }
    );
  }

  loadPersonalstellenart() {
    this.personalstellenart = [];
    if (!this.parameter.anzeige_personalstelle) {
      this.loadingPersonalstellenart = false;
      return;
    }
    this.loadingPersonalstellenart = true;
    this.Katalog.getKatalog("personalstellenart").then(
      response => {
        this.personalstellenart = response.plain();
        this.loadingPersonalstellenart = false;
      }
    );
  }

  filterPersonalfunktionen({ response }) {
    if (!this.parameter.personalfunktionen || !this.parameter.personalfunktionen.length) {
      return [];
    }
    const PERSONALFUNKTIONEN = this.parameter.personalfunktionen;
    return filter(response, item => {
      return PERSONALFUNKTIONEN.indexOf(item.pk) !== -1;
    });
  }

  setPerioden() {
    if (!this.modul.data.perioden) {
      this.perioden = [];
      return;
    }
    this.perioden = cloneDeep(this.modul.data.perioden);
  }

  openPersonalKostenWindow() {
    EventBus.$emit("tooglePersonalkostenPerioden", {
      positionen: this.positionen,
      perioden: this.perioden,
      personalfunktionen: this.personalfunktionen,
    });
  }

  showErrorLabel(field, index) {
    if (!isUndefined(index)) {
      return this.betragsrechner.get_labelmapping()[field][index] + ":";
    }
    if (field === "perioden") {
      return "Perioden:";
    }
    return this.betragsrechner.get_labelmapping()[field] + ":";
  }

  updateModul() {
    this.modul.customGET(this.modul.regel.afr_id).then(
      response => {
        Object.assign(this.modul, response);
        this.initBetragsrechnerinfos();
        this.closeAllPositionen();
        this.setPerioden();
        this._reloadPositionen();
      }
    );
  }

  closeAllPositionen() {
    forEach(this.editPositionModes, (pos, key) => {
      this.editPositionModes[key] = false;
    });
    if (this.addPositionMode) {
      this.toggleAddPosition();
    }
  }

  isPersonalKostenWindowDisabled() {
    return this.loading || !this.perioden || !this.perioden.length;
  }

  getTitlePersonalKostenWindowButton() {
    return `"${ this.gettext("_Kostenart_Tabelle_") }" anzeigen`;
  }

  toggleAddPosition() {
    this.openPersonalKostenWizard();
    // this.addPositionMode = !this.addPositionMode;
    // if (this.addPositionMode) {
    //   this.newPosition = this.initNewPosition();
    // } else {
    //   this.createErrors = {};
    // }
  }

  toggleEditPosition(pos) {
    this.openPersonalKostenWizard({ position: pos.plain() });
  }

  openPersonalKostenWizard({ position } = {}) {
    EventBus.$emit("openPersonalkostenPeriodenWizard", {
      katalogDetails: this.katalogDetails,
      perioden: this.perioden,
      personalfunktionen: this.personalfunktionen,
      personalkostentabelleObj: this.personalkostentabelleObj,
      katalogPersonalkosten: this.katalogPersonalkosten,
      personalstellenart: this.personalstellenart,
      parameter: this.parameter,
      kostenartId: this.antragKostenart.aka_id,
      position: position,
      afr_id: this.modul.regel.afr_id,
      regel_nummer: this.modul.regel.regel_nummer,
    });
  }

  setEventClosePersonalkostenPeriodenWizard() {
    EventBus.$on("closePersonalkostenPeriodenWizard", this.closePersonalKostenWizard);
    this.$scope.$on("$destroy", () => {
      EventBus.$off("closePersonalkostenPeriodenWizard");
    });
  }

  closePersonalKostenWizard(e) {
    if (e.statusReload) {
      this.infoprovider = angular.extend(this.infoprovider, e.response.zuschussrechner);
      this._reloadPositionen();
      this._doSavecallback();
      this.updateSnapshotDiff();
    }
  }

  initBetragsrechnerinfos() {
    this.extras = {
      regeldata: this.modul.data,
      antragsregel: this.modul.regel,
      argument: this.modul.regel.afr_argument,
    };
    this.betragsrechner = undefined;
    this.vorschau = {};
    this.newPosition = this.initNewPosition();
  }

  initNewPosition() {
    return {
      argument: {},
    };
  }
}

export default KostenPeriodenController;
