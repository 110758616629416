"use strict";

class DiasModalController {
  /*@ngInject*/
  constructor($scope, $templateCache) {
    this.title = $scope.modalTitle;
    this.controller = $scope.modalController;
    this.template = "dialogBodyTemplate.html";
    this.showClose = $scope.modalShowClose;

    // put template in cache
    $templateCache.put(this.template, $scope.modalTemplate());

    this.modalInstance = $scope.modalInstance;
    this.scope = $scope;

    // handling Esc and click outside of dialog
    const fn = this.closeWithCallback;
    $scope.close = this.modalInstance.hide;
    this.modalInstance.hide = function() {
      fn($scope.$$childHead.ctx.cancel, $scope);
      $scope.$apply();
    };

    // For use in users controller
    $scope.exitWithCallback = function(callback) {
      $scope.modalInstance.hide = $scope.close;
      fn(callback, $scope);
    };
    $scope.exit = $scope.close;
    $scope.setTitle = title => {
      this.title = title;
    };
  }

  closeWithCallback(callback, ctx) {
    if (callback === undefined) {
      ctx.close();
      return;
    }
    const defer = callback();
    if (defer !== undefined && defer.then !== undefined && defer.then instanceof Function) {
      ctx.loading = true;
      defer.then(function(response) {
        if (angular.isObject(ctx.responseScope)) {
          ctx.responseScope.response = response;
        }
        ctx.close();
      }).finally(function() {
        ctx.loading = false;
      });
    } else {
      ctx.close();
    }
  }
}

export default DiasModalController;
