"use strict";

import "restangular";


class DiasReportController {
  /*@ngInject*/
  constructor(Restangular, $element, $document) {
    this.name = "DiasReportController";
    const document = $document[0];
    this.ticket = null;
    this.siteid = null;
    this.error = false;

    Restangular.all("tableau").customGET("ticket")
      .then(
        result => {
          this.ticket = result.ticket;
	  this.siteid = result.site_id;
          this.error = false;
          // Script-Tag im Template einfügen lädt das Script nicht.
          // Deswegen Script manuell ins HTML einfügen.
          const script = document.createElement("script");
          script.src = result.url + "/javascripts/api/viz_v1.js";
          $element.append(script);
        },
        () => {
          this.ticket = null;
          this.error = true;
        }
      );
  }
}

export default DiasReportController;
