import Fields from "./Fields";

import {
  cloneDeep,
  forEach,
  get,
  keys,
  orderBy,
  pickBy,
} from "lodash-es";

// @vue/component
export default {
  data: () => {
    return {
      belegFields: undefined, // {belegField: {label: "foo", show: true, required: true, objektkatalog: false}}
      belegFieldsFormTemplates: undefined,
      belegFieldsForm: [],
    };
  },
  computed: {
    localModel() {
      return this.model || {};
    },
    orderedBelegFieldsForm() {
      return orderBy(this.belegFieldsForm, field => {
        return get(this.localModel, [field.id, "pos"], field.prio + 100);
      });
    },
  },
  created() {
    this.initBelegFields();
    this.initBelegFieldsFormTemplates();
    this.initBelegFieldsForm();
    this.initModel();
  },
  methods: {
    initBelegFields() {
      this.belegFields = cloneDeep(Fields);
    },
    initBelegFieldsFormTemplates() { },
    initBelegFieldsForm() {
      const FORM_FIELDS = {};
      forEach(this.belegFields, (conf, belegField) => {
        FORM_FIELDS[belegField] = cloneDeep(pickBy(this.belegFieldsFormTemplates, (v, confField) => {
          return conf[confField];
        }));
        forEach(FORM_FIELDS[belegField], (field, confField) => {
          field.id = confField;
          field.htmlId = `${ this.htmlId }__${ belegField }__${ field.id }`;
          field.belegField = belegField;
        });
        FORM_FIELDS[belegField].id = belegField;
        FORM_FIELDS[belegField].label = conf.label;
        FORM_FIELDS[belegField].helpText = conf.helpText;
      });
      this.belegFieldsForm = orderBy(FORM_FIELDS, ["prio"]);
    },
    initModel() {
      forEach(keys(this.belegFields), belegField => {
        this.localModel[belegField] = this.localModel[belegField] || {
          field: belegField,
        };
        this.localModel[belegField].pos = get(this.localModel, [belegField, "pos"], this.belegFields[belegField].prio + 100);
        forEach(this.belegFieldsFormTemplates, (conf, k) => {
          this.localModel[belegField][k] = get(this.localModel, [belegField, k], conf.default);
        });
      });
      forEach(orderBy(this.localModel, ["pos"]), (el, idx) => {
        el.pos = idx + 1;
      });
    }
  },
};
