const KEYS_CODE = {
  enter: 13,
  space: 32,
};

// @vue/component
export default {
  name: "KategoriewertButton",
  props: {
    kategoriewert: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    dataTitle: {
      type: String,
      required: false,
      default: undefined,
    },
    click: {
      type: Function,
      default: () => {},
    },
    hover: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      hoverStatus: undefined,
    };
  },
  computed: {
    attributes() {
      if (!this.readonly) {
        return {
          disabled: this.disabled,
          role: "checkbox",
          tabindex: this.disabled ? -1 : 0,
          "aria-checked": this.ariaCheckedLocal,
        };
      }
      return {};
    },

    ariaCheckedLocal() {
      return `${ this.active }`;
    },

    eventsForComponent() {
      if (!this.readonly && !this.disabled) {
        return {
          click: this.clickLocal,
          keyup: this.keyupLocal
        };
      }
      return {};
    },

    classesForComponent() {
      return {
        active: this.active,
        hover: this.hoverStatus,
        "button-svg-img": this.kategoriewert.fkw_image || this.kategoriewert.fkw_svg,
        "no-image": !this.kategoriewert.fkw_image && !this.kategoriewert.fkw_svg,
        disabled: this.disabled,
        "readonly-category": this.readonly,
      };
    },

    titleForComponent() {
      if (this.dataTitle) {
        return this.dataTitle;
      }
      return `${ this.kategoriewert.fkw_kbez }${ this.disabled ? " (nicht mehr auswählbar)" : "" }`;
    },

    styleForImg() {
      return {
        "background-image": this.active ?
          `url(/api/assets/${ this.kategoriewert.fkw_image }-active.png)` :
          `url(/api/assets/${ this.kategoriewert.fkw_image }.png)`,
      };
    },

    text() {
      return !this.kategoriewert.fkw_image || this.hoverStatus ? this.kategoriewert.fkw_kbez : "";
    },
  },
  methods: {
    clickLocal() {
      this.click({ status: this.active, pk: this.kategoriewert.pk });
    },

    keyupLocal($event) {
      const KEY_CODE = $event.keyCode;
      if (KEY_CODE === KEYS_CODE.enter ||
        KEY_CODE === KEYS_CODE.space) {
        this.clickLocal();
      }
    },

    hoverButton(hoverStatus) {
      this.hoverStatus = hoverStatus;
      this.hover(hoverStatus, this.kategoriewert.pk);
    },
  },
};
