import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";

import DashboardIconMixin from "../DashboardIconMixin";
import DashboardModuleMixin from "../DashboardModuleMixin";
import DashboardStylesMixin from "../DashboardStylesMixin";

// @vue/component
export default {
  name: "DashboardLink",
  components: {
    AngularLink,
  },
  mixins: [
    DashboardIconMixin,
    DashboardModuleMixin,
    DashboardStylesMixin,
  ],
  computed: {
    linkAttributesLocal() {
      const ATTRIBUTES = {
        target: this.parameter.link_ziel,
      };
      if (this.parameter && ["relativ", "absolut"].indexOf(this.parameter.link_typ) !== -1) {
        ATTRIBUTES.href = this.parameter.goto;
      } else {
        ATTRIBUTES.sref = this.parameter.goto;
        ATTRIBUTES.parameter = this.parameter.goto_params || {};
      }
      return ATTRIBUTES;
    },
  },
};
