"use strict";

import parameterServices from "../services";
import template from "./config.jade";
import controller from "./controller";


const parametermoduleInteger = angular.module("dias.foerderregeln.parameter.integer", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("integer", "parametermodul-integer");
  })
  .component("parametermodulInteger", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulIntegerRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: "{{ vm.parameter[vm.key].wert }}",
    controllerAs: "vm",
  });
export default parametermoduleInteger;
