"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

const berichtModule = angular.module("root.berichte", [
  uiRouter,
]);

berichtModule.config($stateProvider => {
  $stateProvider
    .state("root.berichte", {
      url: "/berichte/",
      data: {
        permissions: ["berichte.view"]
      }
    })
    .state("root.berichte.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: `<div vueinit=""><berichte-details></berichte-details></div>`
        }
      },
      data: {
        permissions: ["berichte.view"]
      },
    });
})
;

export default berichtModule;
