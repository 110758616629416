"use strict";
import angular from "angular";

import models from "../models";
import services from "../services";

const modul = angular.module("dias.workflows.statusfunktionen", [
  models.name,
  services.name,
]);

modul.config($stateProvider => {
  $stateProvider

  // Liste der KAufgaben
    .state("root.workflowfolgestatusfunktionen", {
      url: `/workflowfolgestatusfunktionen/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-workflowfolgestatusfunktionen-list></vue-workflowfolgestatusfunktionen-list></div>"
        }
      },
      data: {
        permissions: "statusfunktion.ui"
      }
    })

    .state("root.workflowfolgestatusfunktionen.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><vue-workflowfolgestatusfunktionen-details></vue-workflowfolgestatusfunktionen-details></div>"
        }
      },
    })
  ;
});

export default modul;
