"use strict";

import template from "./overview.jade";

class ProjektberichtOverviewController {
  /*@ngInject*/
  constructor(Vertrag, Foerderantrag, i18n) {
    this.Vertrag = Vertrag;
    this.Foerderantrag = Foerderantrag;
    this.gettext = i18n.gettext;
    this.loading = {
      modul: true,
    };
  }
}

export default {
  template: template(),
  controller: ProjektberichtOverviewController,
  controllerAs: "vm",
  bindings: {
    gutachten: "<",
    objekt: "<"
  }
};
