import PuxGet from "../../../../global/components/PuxGet/PuxGet.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTooltip from "../../../../global/components/PuxTooltip/PuxTooltip.vue";

import FinanzierugMixin from "../Mixins/FinanzierugMixin";
import PeriodenMixin from "../Mixins/PeriodenMixin";
import ZuschussMixin from "../Mixins/ZuschussMixin";
import {
  FilterCurrencyMixin,
} from "../../../../global/mixins/FiltersMixin";

import {
  EventBus,
} from "../../../../global/functions/event-bus";
import {
  forEach,
  get,
  indexOf,
  isFunction,
  toNumber,
} from "lodash";

// @vue/component
export default {
  name: "PeriodenSignalZuschussEingabe",
  components: {
    PuxGet,
    PuxIcon,
    PuxTooltip,
  },
  mixins: [
    FilterCurrencyMixin,
    FinanzierugMixin,
    PeriodenMixin,
    ZuschussMixin,
  ],
  computed: {
    htmlId() {
      return `${ this.htmlRegelNummer }_zuschuss`;
    },

    sumLocal() {
      return this.antragBetragCurrency;
    },

    periodenLocalBewilligt() {
      return get(this.extra, "antrag.a_zuschussbewilligt_data.perioden");
    },

    positionenLocal() {
      if (this.isKontextAnyZuschuss) {
        return [];
      }
      return this.positionen;
    },

    isKontextAnyZuschuss() {
      return indexOf(["zuschuss", "zuschuss_vertrag"], this.kontext) !== -1;
    },

    modelWithPositionenForPeriode() {
      if (this.isKontextAnyZuschuss) {
        return this.setModelWithPositionenForPeriodeZuschuss({ zuschussPerioden: get(this.infoprovider, "perioden", {}), statusSnapshot: false });
      }
      return this.setModelWithPositionenForPeriode({ positionen: this.positionen, statusSnapshot: false });
    },

    modelWithPositionenForPeriodeSnapshot() {
      if (!this.statusSnapshot) {
        return;
      }
      if (this.isKontextAnyZuschuss) {
        return this.setModelWithPositionenForPeriodeZuschuss({ zuschussPerioden: this.snapshotZuschuss, statusSnapshot: true });
      }
      return this.setModelWithPositionenForPeriode({ positionen: get(this.currentSnapshot, "positionen", {}), statusSnapshot: true, keyPosPeriodWert: this.prefixPositionPeriodWertSnapshot });
    },

    statusZuschussValid() {
      return this.kontext === "zuschuss" && this.statusZuschuss === 1;
    },

    isDiffPerioden() {
      return get(this.extra, "isDiffPerioden");
    },

    statusShowProzenteZuschuss() {
      return this.kontext !== "zuschuss" && this.statusShowProzente;
    },

    
    sumPeriodenKosten() {
      return get(this.extra, "sumPeriodenKosten");
    },

    sumPeriodenFinanzierung() {
      return get(this.extra, "sumPeriodenFinanzierung");
    },
    
    percentsByPeriods() {
      const periodsWithPercents = {};
      forEach(this.perioden, period => {
        const periodKostenFound = get(this.sumPeriodenKosten, period.pk);
        const periodFinanzierungFound = get(this.sumPeriodenFinanzierung, period.pk);
        if (periodKostenFound && periodFinanzierungFound) {
          const percentValueWert = (periodKostenFound.wert - periodFinanzierungFound.wert) / periodKostenFound.wert * 100;
          const percentValueBetragErfasst = (periodKostenFound.betrag_erfasst - periodFinanzierungFound.betrag_erfasst) / periodKostenFound.betrag_erfasst * 100;
          periodsWithPercents[period.pk] = {
            percentValue: {
              wert: percentValueWert ? this.filterCurrency(percentValueWert, "%") : null,
              betrag_erfasst: percentValueBetragErfasst ? this.filterCurrency(percentValueBetragErfasst, "%") : null,
            },
          };
        }
      });
      return periodsWithPercents;
    },
  },
  methods: {
    registerOrDeactiveSignalReceiver({ eventBusAction }) {
      if (this.kontext === "zuschuss" || !this.signalEmpfang.length) {
        return;
      }

      forEach(this.signalEmpfang, item => {
        if (item.signal_kennung && item.sender_reid) {
          EventBus[eventBusAction](`${ item.signal_kennung }_${ item.sender_reid }`, arg => this.onReceiveSignalLocal(item.signal_kennung, arg));
        }
      });
    },

    onReceiveSignal(signal_kennung) {
      if (signal_kennung === "dynamische_tabelle.update") {
        this.reloadPositionen_KFBaseMixin({ statusSaveCallback: false });
        this.updateZuschuss_KFBaseMixin();
      }
    },

    loadVertragFinanzierungRest_FinanzierugMixin() {
      this.loading = false;
      this.reloading = false;
    },

    toggleTable() {
      this.statusTableShow = !this.statusTableShow;
    },

    setModelWithPositionenForPeriodeZuschuss({ zuschussPerioden = {}, statusSnapshot }) {
      const MODEL = {
        positionen: {},
        all: {
          wert: 0,
          wertCurrency: this.filterCurrency(0),
        },
      };
      forEach(this.perioden, period => {
        const PERIOD_PK = period.pk;
        MODEL[PERIOD_PK] = {
          wert: 0,
          wertCurrency: this.filterCurrency(0),
          meldungen: [],
        };
      });
      forEach(zuschussPerioden, (periodData, periodPk) => {
        MODEL[periodPk] = MODEL[periodPk] || {};
        MODEL[periodPk].wert = toNumber(periodData.zuschuss);
        MODEL[periodPk].wertCurrency = this.filterCurrency(periodData.zuschuss);
        MODEL[periodPk].meldungen = periodData.meldungen || [];
        MODEL.all.wert += MODEL[periodPk].wert;
      });
      MODEL.all.wertCurrency = this.filterCurrency(MODEL.all.wert);
      if (isFunction(this.extraStatic.setPeriodenWert)) {
        this.extraStatic.setPeriodenWert({
          model: MODEL,
          regel: this.regel.regel,
          statusSnapshot,
          typeKoFi: this.kontext === "zuschuss" ? "zuschuss" : this.typeKoFi,
        });
      }
      return MODEL;
    },
  },
};
