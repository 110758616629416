"use strict";

import angular from "angular";

import kdatenschutzaktionService from "./kdatenschutzAktion.service";

const serviceModule = angular.module("dias.auth.datenschutz.service", [
])

  .constant("DatenschutzaktionService", kdatenschutzaktionService);

export default serviceModule;
