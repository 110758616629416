// import mock from "./mock.json";
// import mockConfig from "./mockConfig.json";

import StyleguideTableEasy from "./StyleguideTableEasy/StyleguideTableEasy.vue";
import StyleguideTableFilterTop from "./StyleguideTableFilterTop/StyleguideTableFilterTop.vue";

// @vue/component
export default {
  name: "StyleguideTable",
  components: {
    StyleguideTableEasy,
    StyleguideTableFilterTop,
  },
};
