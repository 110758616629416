"use strict";

import BaseFunction from "../../baseFunction.js";

import modalTemplate from "./dialog.jade";
import modalController from "./dialog.controller";

class AktualisierenFunction extends BaseFunction {
  /*@ngInject*/
  constructor(DiasNotification, $q, diasModalDialog, workflowfolge, obj, extra) {
    super(DiasNotification, $q, workflowfolge, obj, extra);
    this.diasModalDialog = diasModalDialog;
  }

  start() {
    const extras = {
      parameterin: this.prepareParameterIn(),
      // parameterout: this.prepareParameterOut(),
      parameterout: this.workflowfolge.clientfunktion.wfc_parameterout,
      workflowfolge: this.workflowfolge,
      obj: this.obj,
      // finish: this.finish.bind(this),
      finish: parameterout => this.finish(parameterout),
      parent: this
    };

    return this.diasModalDialog({
      title: "Weiterleitungsvertrag aktualisieren",
      template: modalTemplate,
      controller: modalController,
      extras: extras
    });
  }
}

export default AktualisierenFunction;
