import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  methods: {
    prepareDataForValidateAndSave({ data }) {
      const DATA = cloneDeep(data);
      // DATA.argument.bestandspersonal = !DATA.argument.bestandspersonal;
      return DATA;
    },
  },
};
