"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import "angular-chart.js";
import "angular-drag-and-drop-lists";

import diasAuthService from "dias/auth/services";
import diasConfirm from "dias/core/confirm";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";

import foerdermodule from "dias/foerderantrag/foerdermodule";
import foerderregeln from "dias/foerderregeln/models";

import models from "../models";
import listNewComponent from "./listNew.component";
import detailsComponent from "./details.component";
import overviewComponent from "./overview.component";

const projektberichteModule = angular.module("root.projektberichte.berichte", [
  uiRouter,
  models.name,
  foerdermodule.name,
  foerderregeln.name,
  diasAuthService.name,
  diasConfirm.name,
  diasTable.name,
  diasLoading.name,
  diasNotification.name,
]);

projektberichteModule.config($stateProvider => {
  $stateProvider

    // Sitzungs-Liste
    .state("root.projektberichte", {
      url: "/projektberichte/?" +
           "pb_nr&vertrag&antragstellerorg&bezugszeitraum" +
           "einreichdatum&akzeptiertdatum&deaktiviert&" +
           "workflowstatusgruppe&friststatus&status&status_detail&zustaendig",
      views: {
        "main@root": {
          template: "<projektberichte-list-new></projektberichte-list-new>"
        }
      },
      data: {
        permissions: ["projektbericht.view"]
      },
    })

    // Detail-Seite einer Sitzung
    .state("root.projektberichte.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<projektberichte-details></projektberichte-details>"
        }
      },
      data: {
        permissions: ["projektbericht.view"]
      },
    })

  ;
})

  .component("projektberichteListNew", listNewComponent)
  .component("projektberichteDetails", detailsComponent)
  .component("projektberichteOverview", overviewComponent)
;

export default projektberichteModule;
