// @vue/component
export default {
  name: "vue-styleguide-vueinit",
  data() {
    return {
      example1: `<div vueinit><vue-styleguide></vue-styleguide></div>`,
      example2: `<div vueinit class="col-lg-6 no-padding"><vue-impressum></vue-impressum></div>`,
      exampleGlobalobjects: [
        {
          inCode: "Vue.prototype.$gettext = i18n.gettext;",
          useInVueComponent: "this.$gettext",
        },
        {
          inCode: "Vue.prototype.$AuthService = AuthService;",
          useInVueComponent: "this.$AuthService",
        },
        {
          inCode: "Vue.prototype.$goTo = goTo;",
          useInVueComponent: "this.$goTo",
        },
        {
          inCode: "Vue.prototype.$locationReplace = locationReplace;",
          useInVueComponent: "this.$locationReplace",
        },
        {
          inCode: "Vue.prototype.$stateParams = $stateParams;",
          useInVueComponent: "this.$stateParams",
        },
        {
          inCode: "Vue.prototype.$NotificationPage = DiasNotification.page;",
          useInVueComponent: "this.$NotificationPage",
        },
        {
          inCode: "Vue.prototype.$Client = Client;",
          useInVueComponent: "this.$Client",
        },
        {
          inCode: "Vue.prototype.$Raven = Raven;",
          useInVueComponent: "this.$Raven",
        },
      ],
    };
  },
  methods: {

  },
};
