import {
  toNumber,
  isNaN,
  isArray,
} from "lodash-es";
import UiCoordinatesMixin from "./UiCoordinatesMixin";
import translate from "../../../directives/translate";

// @vue/component
export default {
  name: "UiCoordinates",
  directives: {
    translate,
  },
  mixins: [
    UiCoordinatesMixin,
  ],
  props: {
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
  },
  computed: {
    modelLocal() {
      return isArray(this.model) ? this.model : [];
    },

    statusRequired() {
      return `${ !!this.options.required }`;
    },

    statusError() {
      return `${ !!this.isError }`;
    },
  },
  methods: {
    initMapEvents() {
      this.map.on("click", this.onClickInMap);
      this.map.on("zoomend", this.changeZoom);
      this.map.on("movestart", this.movestart);
      this.map.on("moveend", this.moveend);
    },

    changeZoom(event) {
      this.zoom = event.target._zoom;
    },

    changeMarkerCoordinates({ model }) {
      this.marker.setLatLng(model);
    },

    onClickInMap(event) {
      const MODEL = [event.latlng.lat, event.latlng.lng];

      this.addOderChangeMarker({ model: MODEL });

      this.onInput({ model: MODEL });
    },

    onBlurLocal({ $event, key }) {
      this.onInput({ $event, key });
      this.blur({
        id: this.options.id,
        model: $event.target.value,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
    },

    onFocus({ $event }) {
      this.focus({
        id: this.options.id,
        model: $event.target.value,
        $event: $event,
        param: this.options.param,
        options: this.options,
      });
    },

    onInput({ $event, key, model }) {
      let modelLocal = [];
      if (model) {
        modelLocal = model;
      } else {
        const MODEL_0 = this.modelLocal[0];
        const MODEL_1 = this.modelLocal[1];
        let currentModel = $event.target.value;
        if (!isNaN(toNumber(currentModel)) && currentModel.length && currentModel[currentModel.length - 1] !== ".") {
          currentModel = toNumber(currentModel);
        }

        if (key === 0) {
          modelLocal.push(currentModel, MODEL_1);
        } else {
          modelLocal.push(MODEL_0, currentModel);
        }
        this.addOderChangeMarker({ model: modelLocal });
      }

      this.onInput_mixin({ value: modelLocal, $event });
    },
  },
};
