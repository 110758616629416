import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  FilterDateMixin,
} from "../../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "AntragOverviewRegelsatz",
  components: {
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    FilterDateMixin,
  ],
  props: {
    antrag: {
      type: Object,
      required: true,
    },
  },
  computed: {
    regelsatz() {
      return this.antrag.regelsatz_obj || {};
    },

    extraVersionTranslate() {
      return {
        bez: this.regelsatz.ars_kbez,
        version: this.regelsatz.ars_version,
      };
    },

    textVonBisTranslate() {
      if (this.regelsatz.ars_gueltig_bis && this.regelsatz.ars_gueltig_ab) {
        return "_TXT_GUELTIG_VON_BIS_{{von}}_{{bis}}_";
      }
      if (!this.regelsatz.ars_gueltig_bis && this.regelsatz.ars_gueltig_ab) {
        return "_TXT_GUELTIG_VON_{{von}}_";
      }
      return "";
    },

    extraVonBisTranslate() {
      return {
        von: this.filterDate(this.regelsatz.ars_gueltig_ab),
        bis: this.filterDate(this.regelsatz.ars_gueltig_bis),
      };
    },
  },
};
