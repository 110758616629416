"use strict";

class BenachrichtigungSendDetailController {
  /*@ngInject*/
  constructor($scope, $q, AuthService, Vertrag, DiasNotification) {
    this.$scope = $scope;
    this.$q = $q;
    this.AuthService = AuthService;
    this.Vertrag = Vertrag;
    this.DiasNotification = DiasNotification;

    this.vId = $scope.extras.vId;
    this.dokId = $scope.extras.dokId;

    this.deleteDokument = this.deleteDokument.bind(this);
    this.init = this.init.bind(this);
    this.canDelete = this.canDelete.bind(this);
    this.init();
  }

  init() {
    this.loading = {
      belegdok: true,
    };
    this.belegePerms = {};
    this.Vertrag.one(this.vId).belegdokumente.one(this.dokId).get().then(
      response => {
        this.belegdok = response;
        this.loading.belege = true;
        this.belegdok.fetchBelege().then(
          response => {
            this.belege = response;
            this.loading.belegePerms = this.belege.length;
            this.belege.forEach(beleg => {
              this.belegePerms[beleg.pk] = undefined;
              beleg.checkPermissions(["vertragskostenpositionsbelege.update"]).then(
                perms => this.belegePerms[beleg.pk] = this.AuthService.syncHasPerm("vertragskostenpositionsbelege.update", perms)
              ).finally(() => this.loading.belegePerms--);
            });
          },
          err => this.DiasNotification.page.error(err, "Fehler beim Laden der Belege")
        ).finally(() => this.loading.belege = false);
      },
      err => this.DiasNotification.page.error(err, "Fehler beim Laden des Dokuments")
    ).finally(() => this.loading.belegdok = false);
  }

  canDelete() {
    if (this.loading.belegePerms > 0) {
      return false;
    }
    for (const key in this.belegePerms) {
      if (!this.belegePerms[key]) {
        return false;
      }
    }
    return true;
  }

  deleteDokument() {
    const defer = this.$q.defer();
    this.Vertrag.one(this.vId).belegdokumente.one(this.dokId).remove().then(
      () => {
        defer.resolve(true);
        this.DiasNotification.page.success("Dokument erfolgreich gelöscht");
      },
      err => {
        defer.reject(err);
        this.DiasNotification.page.error("Fehler beim Löschen des Dokuments");
      }
    );
    return defer.promise;
  }
}

export default BenachrichtigungSendDetailController;
