import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import translate from "../../../../../global/directives/translate";

import HttpMixin from "../../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../../global/mixins/NotificationMixin";
import SyConfigMixin from "../../../../../global/mixins/SyConfigMixin";

import {
  initModelFromList,
} from "../../../../../global/functions/mappingForParameterToFormElement";

import {
  cloneDeep,
  forEach,
  filter,
  findIndex,
  get,
  indexOf,
  map,
  isPlainObject,
} from "lodash-es";

const MAX_HOURS = 50;

// @vue/component
export default {
  name: "PersonModalCreateOrEdit",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    SyConfigMixin,
  ],
  props: {
    personalstellePk: {
      type: String,
      required: true,
    },
    person: {
      type: Object,
      default: undefined,
    },
    vertragPk: {
      type: String,
      required: true,
    },
    statusPruefen: {
      type: Boolean,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      model: {},
      listDefault: [
        {
          id: "vpsp_vorname",
          type: "text",
          label: "_LBL_VERTRAG_DETAILS_PERSON_MODAL_VORNAME_",
          view: "v",
          disabled: false,
          translate: true,
        },
        {
          id: "vpsp_nachname",
          type: "text",
          label: "_LBL_VERTRAG_DETAILS_PERSON_MODAL_NACHNAME_",
          required: true,
          view: "v",
          disabled: false,
          translate: true,
        },
        {
          id: "vpsp_geburtsdatum",
          type: "date",
          label: "_LBL_VERTRAG_DETAILS_PERSON_MODAL_GEBURTSDATUM_",
          required: true,
          view: "v",
          disabled: false,
          translate: true,
          syconfig: true,
        },
        {
          id: "vpsp_steuerid",
          type: "text",
          label: "_LBL_VERTRAG_DETAILS_PERSON_MODAL_STEUERNUMMER_",
          required: true,
          view: "v",
          disabled: false,
          translate: true,
          syconfig: true,
        },
        {
          id: "vpsp_beschaeftigt_von",
          type: "date",
          label: "_LBL_VERTRAG_DETAILS_PERSON_MODAL_VON_",
          required: true,
          view: "v",
          disabled: false,
          translate: true,
        },
        {
          id: "vpsp_beschaeftigt_bis",
          type: "date",
          label: "_LBL_VERTRAG_DETAILS_PERSON_MODAL_BIS_",
          view: "v",
          disabled: false,
          translate: true,
        },
        {
          id: "vpsp_beschaeftigt_von2",
          type: "date",
          label: "_LBL_VERTRAG_DETAILS_PERSON_MODAL_VON_2_",
          required: true,
          view: "v",
          disabled: false,
          translate: true,
          syconfig: true,
        },
        {
          id: "vpsp_beschaeftigt_bis2",
          type: "date",
          label: "_LBL_VERTRAG_DETAILS_PERSON_MODAL_BIS_2_",
          view: "v",
          disabled: false,
          translate: true,
          syconfig: true,
        },
        {
          id: "vpsp_umfang_vollzeitstelle",
          type: "float",
          label: "_LBL_VERTRAG_DETAILS_PERSON_MODAL_VOLLZEITSTELLE_",
          required: true,
          addonBack: "_LBL_SUFFIX_STUNDEN_PRO_WOCHE_",
          view: "v",
          translate: true,
          statusChangePeriod: true,
          maxValue: MAX_HOURS,
          change: this.changeVollzeitstelle,
        },
        {
          id: "vpsp_umfang_stelle",
          type: "float",
          label: "_LBL_VERTRAG_DETAILS_PERSON_MODAL_STELLENUMFANG_",
          required: true,
          addonBack: "_LBL_SUFFIX_STUNDEN_PRO_WOCHE_",
          view: "v",
          translate: true,
          maxValue: MAX_HOURS,
          change: this.changeUmfangStelle,
        },
        {
          id: "vpsp_umfang_projekt",
          type: "float",
          label: "_LBL_VERTRAG_DETAILS_PERSON_MODAL_STELLENUMFANG_PROJEKT_",
          addonBack: "_LBL_SUFFIX_STUNDEN_PRO_WOCHE_",
          required: true,
          view: "v",
          translate: true,
          maxValue: MAX_HOURS,
        },
        {
          id: "vpsp_kommentar",
          type: "richtext",
          label: "_LBL_VERTRAG_DETAILS_PERSON_MODAL_KOMMENTAR_",
          required: false,
          view: "v-alt",
          translate: true,
          syconfig: true,
        },
        {
          id: "dok_ids",
          type: "document",
          label: "_LBL_VERTRAG_DETAILS_PERSON_MODAL_ARBEITSVERTRAG_",
          view: "v-alt",
          saveUrl: "tempdok/",
          keyLabel: "titel",
          multiple: true,
          translate: true,
          required: true,
          change: this.addInput,
          slot: "dok_ids",
        },
      ],
      list: [],
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
      indexGeburtsdatum: -1,
      indexUmfangStelle: -1,
      indexUmfangProjekt: -1,
    };
  },
  computed: {
    header() {
      if (this.statusPruefen) {
        return "_TXT_VERTRAG_DETAILS_PERSONALBAUM_PERSON_MODAL_CHECK_HEADER_";
      }
      if (this.person) {
        return "_TXT_VERTRAG_DETAILS_PERSONALBAUM_PERSON_MODAL_EDIT_HEADER_";
      }
      return "_TXT_VERTRAG_DETAILS_PERSONALBAUM_PERSON_MODAL_CREATE_HEADER_";
    },

    optionsModal() {
      return {
        showCloseButton: true,
        required: true,
        list: this.list,
      };
    },

    syConfigFelder() {
      const CONFIG = this.getSyConfigsValue("person_im_personalbaum") || {};
      return CONFIG.felder || [];
    },
  },
  created() {
    this.initListWithSyConfig();
    this.setIndexWithId({ id: "vpsp_umfang_stelle", indexName: "indexUmfangStelle" });
    this.setIndexWithId({ id: "vpsp_umfang_projekt", indexName: "indexUmfangProjekt" });
    this.initModelAndDocumentUrl();
    this.setAllFieldsForPruefen();
  },
  methods: {
    addInput({ currentModel }) {
      const DOK = {
        titel: currentModel.titel,
        groesse: currentModel.groesse,
        datei: currentModel.datei,
        url: currentModel.url,
        pk: currentModel.pk,
        is_sync: false,
        readonly: false,
      };
      if (!this.model.dokumente) {
        this.model.dokumente = [];
      }
      const INDEX_IN_DOKS = indexOf(this.model.dokumente, DOK.pk);
      if (INDEX_IN_DOKS !== -1) {
        this.model.dokumente.splice(INDEX_IN_DOKS, 1);
      } else {
        this.model.dokumente.push(DOK);
      }
    },

    initListWithSyConfig() {
      this.list = filter(cloneDeep(this.listDefault), item => {
        if (!item.syconfig) {
          return true;
        }
        return this.syConfigFelder.indexOf(item.id) !== -1;
      });
    },

    setIndexWithId({ id, indexName }) {
      this[indexName] = findIndex(this.list, ["id", id]);
    },

    initModelAndDocumentUrl() {
      if (!this.person) { // Neue Person
        this.model = initModelFromList({ list: this.list });
      } else {
        const MODEL = cloneDeep(this.person);
        if (this.statusPruefen) {
          MODEL.vpsp_pruefstatus = MODEL.vpsp_pruefstatus || false;
        }
        forEach(this.syConfigFelder, feld => {
          if (feld !== "vpsp_steuerid") {
            MODEL[feld] = get(MODEL.vpsp_argument, feld, null);
          }
        });
        if (MODEL.vpsp_argument) {
          delete MODEL.vpsp_argument;
        }
        if (this.person && this.person.dokumente && !MODEL.dok_ids) {
          MODEL.dok_ids = map(this.person.dokumente, dok => dok.pk);
        }
        this.model = MODEL;
      }
    },

    setAllFieldsForPruefen() {
      if (!this.statusPruefen) {
        return;
      }
      forEach(this.list, item => {
        item.disabled = true;
      });
      this.list.push({
        type: "boolean",
        label: "_LBL_VERTRAG_DETAILS_PERSON_MODAL_GEPRUEFT_",
        view: "v-alt",
        id: "vpsp_pruefstatus",
        translate: true,
      });
    },

    save() {
      this.status.loading = true;
      if (this.statusPruefen) {
        this.pruefenPerson();
      } else if (this.person) {
        this.updatePerson();
      } else {
        this.createPerson();
      }
    },

    pruefenPerson() {
      this.postHttp({
        url: `vertraege/${ this.vertragPk }/vertragspersonalstellenpersonen/${ this.person.pk }/pruefen/`,
        data: {
          vpsp_pruefstatus: this.model.vpsp_pruefstatus,
        },
      }).then(
        response => {
          this.addNotification({ text: "_MSG_VERTRAG_DETAILS_PERSONALBAUM_PERSON_MODAL_CHECK_SUCCESS_" });
          this.close({ response, statusUpdate: true });
        },
        error => {
          this.onError({ error });
        }
      ).then(
        () => this.status.loading = false
      );
    },

    prepareModelForSave({ model }) {
      if (model.dokumente) {
        const ID_LIST = map(
          filter(model.dokumente, dok => !dok.remove),
          dok => get(dok, "pk", dok)
        ) || [];
        model.dok_ids = ID_LIST;
      }
      return this.setArgumentForSave({ model });
    },

    updatePerson() {
      const MODEL = cloneDeep(this.model);
      const DATA = this.prepareModelForSave({ model: MODEL });
      this.putHttp({
        url: `vertraege/${ this.vertragPk }/vertragspersonalstellenpersonen/${ this.person.pk }/`,
        data: DATA,
      }).then(
        response => {
          this.addNotification({ text: "_MSG_VERTRAG_DETAILS_PERSONALBAUM_PERSON_MODAL_EDIT_SUCCESS_" });
          this.close({ response, statusUpdate: true });
        },
        error => {
          this.onError({ error });
        }
      ).then(
        () => this.status.loading = false
      );
    },

    createPerson() {
      const MODEL = cloneDeep(this.model);
      MODEL.personalstelle = this.personalstellePk;
      const DATA = this.prepareModelForSave({ model: MODEL });

      this.postHttp({
        url: `vertraege/${ this.vertragPk }/vertragspersonalstellenpersonen/`,
        data: DATA,
      }).then(
        response => {
          this.addNotification({ text: "_MSG_VERTRAG_DETAILS_PERSONALBAUM_PERSON_MODAL_CREATE_SUCCESS_" });
          this.close({ response, statusCreate: true });
        },
        error => {
          this.onError({ error });
        }
      ).then(
        () => this.status.loading = false
      );
    },

    changeVollzeitstelle() {
      if (this.vpsp_umfang_projekt === -1) {
        return;
      }
      if (!this.model.vpsp_umfang_vollzeitstelle) {
        this.list[this.indexUmfangStelle].maxValue = MAX_HOURS;
      } else {
        this.list[this.indexUmfangStelle].maxValue = this.model.vpsp_umfang_vollzeitstelle;
        if (this.model.vpsp_umfang_stelle > this.model.vpsp_umfang_vollzeitstelle) {
          this.model.vpsp_umfang_stelle = this.model.vpsp_umfang_vollzeitstelle;
        }
      }
      this.changeUmfangStelle();
    },

    changeUmfangStelle() {
      if (this.indexUmfangProjekt === -1) {
        return;
      }
      if (!this.model.vpsp_umfang_stelle) {
        this.list[this.indexUmfangProjekt].maxValue = MAX_HOURS;
      } else {
        this.list[this.indexUmfangProjekt].maxValue = this.model.vpsp_umfang_stelle;
        if (this.model.vpsp_umfang_projekt > this.model.vpsp_umfang_stelle) {
          this.model.vpsp_umfang_projekt = this.model.vpsp_umfang_stelle;
        }
      }
    },

    setArgumentForSave({ model }) {
      const ARGUMENT = {};
      forEach(this.syConfigFelder, feld => {
        if (feld !== "vpsp_steuerid" && get(model, feld)) {
          ARGUMENT[feld] = cloneDeep(model[feld]);
          delete model[feld];
        }
      });
      model.vpsp_argument = ARGUMENT;
      return model;
    },

    onError({ error }) {
      const ERROR = error.data;
      if (ERROR.vpsp_argument && isPlainObject(ERROR.vpsp_argument)) {
        forEach(ERROR.vpsp_argument, (item, key) => {
          ERROR[key] = cloneDeep(item);
        });
        delete ERROR.vpsp_argument;
      }
      this.errors = ERROR;
      this.errorsClone = cloneDeep(ERROR);
    },

    showDokRemove(dok) {
      return !dok.remove;
    },

    removeDok(dok) {
      return dok.remove = true;
    },

    showDokRestore(dok) {
      return dok.remove;
    },

    restoreDok(dok) {
      dok.remove = false;
    },

    getDokStyle(dok) {
      if (dok.remove) {
        return {
          "text-decoration": "line-through"
        };
      }
      return {};
    },

    getDokTitle(dok) {
      if (dok.url && dok.remove) {
        return "_TEXT_VERTRAG_DETAILS_PERSONALBAUM_PERSON_MODAL_DOKUMENT_MARKED_FOR_DELETION_{{dok_titel}}_";
      } else if (!dok.url && !dok.remove) {
        return "_TEXT_VERTRAG_DETAILS_PERSONALBAUM_PERSON_MODAL_DOKUMENT_WILL_BE_ADDED_{{dok_titel}}_";
      } else if (!dok.url && dok.remove) {
        return "_TEXT_VERTRAG_DETAILS_PERSONALBAUM_PERSON_MODAL_DOKUMENT_WILL_NOT_BE_ADDED_{{dok_titel}}_";
      }
    }
  },
};
