import BaseController from "dias/standard_foerdermodule/standard_foerdermodule.base.controller";

class AnlagenController extends BaseController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule) {
    super($scope, $q, Foerdermodule);

    this.dokumentePermissions = {
      view: "dokument.view",
      create: "dokument.create",
      delete: "dokument.delete",
      update: "dokument.update",
      update_gueltigkeit: "dokument.update_gueltigkeit",
    };
    this.htmlId = `regel_${ this.modul.regel.regel_nummer }`;
  }
}

export default AnlagenController;
