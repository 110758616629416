import Modal from "../../../../../global/components/Modal/Modal.vue";
import RollenModalGueltigkeitszeitraumAnpassen from "./RollenModalGueltigkeitszeitraumAnpassen/RollenModalGueltigkeitszeitraumAnpassen.vue";
import SmartTable from "../../../../../global/components/table/table.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";
import {
  FilterDateMixin,
  FilterDefaultForEmptyMixin,
} from "../../../../../global/mixins/FiltersMixin";

import { EventBus } from "../../../../../global/functions/event-bus";

import {
  assign,
  isFunction,
} from "lodash-es";
import {
  getSelectorCloseForTableRowFunction
} from "../../../../../global/functions/utils";

// @vue/component
export default {
  components: {
    Modal,
    RollenModalGueltigkeitszeitraumAnpassen,
    SmartTable,
  },
  mixins: [
    FilterDateMixin,
    FilterDefaultForEmptyMixin,
    HttpMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modal: {
        gueltigkeitszeitraumAnpassen: undefined,
        add: undefined,
      },
      modalConfirmShow: undefined,
      modalConfirmOptions: {},
      modalOptions: undefined,
      currentRolle: undefined,
      currentIndex: undefined,
      selectorClose: undefined,
    };
  },
  computed: {
    getTabeleUrl_mixin() {
      return `nutzer/${ this.nutzer.pk }/${ this.apiSufix }/`;
    },
  },
  methods: {
    confirmRemoveNutzerRole_mixin({ item }) {
      this.modalConfirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_ROLEN_LIST_IN_NUTZER_MIXIN_ROLLENZUORDNUNG_ENTFERNEN_",
        msg: "_MSG_ROLEN_LIST_IN_NUTZER_MIXIN_ENTFERNEN_{{rolle_name}}_{{org_name}}_",
        extra: { rolle_name: item.rolle_name, org_name: item.org_name },
        okLabel: "_LBL_ROLEN_LIST_IN_NUTZER_MIXIN_ZUORDNUNG_ENTFERNEN_",
        okCallback: () => this.removeNutzerRole_mixin({ item }),
        cancelCallback: this.closeConfirmModal_mixin,
        loading: false,
      };
      this.showConfirmModal_mixin();
    },

    removeNutzerRole_mixin({ item }) {
      this.modalConfirmOptions.loading = true;
      this.deleteHttp({ url: `${ this.getTabeleUrl_mixin }${ item.pk }/` }).then(
        () => {
          EventBus.$emit(`updateTable${ this.tableId }`);
          this.addNotification({
            text: "_MSG_ROLENLISTINNUTZER_DELETE_SUCCESS_{{rolle_name}}_{{org_name}}_",
            extra: { rolle_name: item.rolle_name, org_name: item.org_name }
          });
          this.closeConfirmModal_mixin();
        },
        error => this.addNotification({
          text: "_MSG_ROLENLISTINNUTZER_DELETE_ERROR_{{error}}_",
          type: "error",
          extra: {
            error: error.data.detail
          } })
      ).then(
        () => this.modalConfirmOptions.loading = false
      );
    },

    confirmDeactivateNutzerRole_mixin({ item, index }) {
      this.modalConfirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_ROLEN_LIST_IN_NUTZER_MIXIN_ROLLENZUORDNUNG_DEAKTIVIEREN_",
        msg: "_MSG_ROLEN_LIST_IN_NUTZER_MIXIN_DEAKTIVIEREN_{{rolle_name}}_{{org_name}}_",
        extra: { rolle_name: item.rolle_name, org_name: item.org_name },
        okLabel: "_LBL_ROLEN_LIST_IN_NUTZER_MIXIN_ZUORDNUNG_DEAKTIVIEREN_",
        okCallback: () => this.deactivateNutzerRole_mixin({ item }),
        cancelCallback: this.closeConfirmModal_mixin,
        loading: false,
      };
      this.selectorClose = getSelectorCloseForTableRowFunction({
        tableId: this.tableId,
        index,
      });
      this.showConfirmModal_mixin();
    },

    deactivateNutzerRole_mixin({ item }) {
      this.modalConfirmOptions.loading = true;
      this.postHttp({ url: `${ this.getTabeleUrl_mixin }${ item.pk }/deactivate/` }).then(
        () => {
          EventBus.$emit(`updateTable${ this.tableId }`);
          this.addNotification({
            text: "_MSG_ROLENLISTINNUTZER_DEACTIVATE_SUCCESS_{{rolle_name}}_{{org_name}}_",
            extra: { rolle_name: item.rolle_name, org_name: item.org_name }
          });
          this.closeConfirmModal_mixin();
        },
        error => this.addNotification({
          text: "_MSG_ROLENLISTINNUTZER_DEACTIVATE_ERROR_{{error}}_",
          type: "error",
          extra: {
            error: error.data.detail
          } })
      ).then(
        () => this.modalConfirmOptions.loading = false
      );
    },

    showConfirmModal_mixin() {
      this.modalConfirmShow = true;
    },

    closeConfirmModal_mixin() {
      this.modalConfirmShow = false;
    },

    openModalGueltigkeitszeitraumAnpassen_mixin({ item, index }) {
      this.currentRolle = item;
      this.currentIndex = index;
      this.modal.gueltigkeitszeitraumAnpassen = true;
      this.selectorClose = getSelectorCloseForTableRowFunction({
        tableId: this.tableId,
        index,
      });
    },

    closeModalGueltigkeitszeitraumAnpassen_mixin({ response } = {}) {
      if (response) {
        const ROW = assign({}, this.currentRolle, response);
        EventBus.$emit(`updateRow${ this.tableId }`, ({ row: ROW, index: this.currentIndex }));
      }
      this.modal.gueltigkeitszeitraumAnpassen = false;
    },

    addRolle_mixin() {
      this.modal.add = true;
    },

    closeModalAdd_mixin({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      if (isFunction(this.onCloseModalAdd)) {
        this.onCloseModalAdd();
      }
      this.modal.add = false;
    },

    getTitleForRowActiveIcon_mixin(row) {
      if (!row.aktiv && row.rolle_aktiv) {
        return "close";
      } else if (!row.aktiv && !row.rolle_aktiv) {
        return "close";
      }
      return "ok";
    },

    getTitleForRowActiveIconClass_mixin(row) {
      if (!row.aktiv && row.rolle_aktiv) {
        return "text-warning";
      } else if (!row.aktiv && !row.rolle_aktiv) {
        return "text-danger";
      }
      return "text-success";
    },

    getTitleForRowActiveIconCls_mixin(row) {
      if (!row.aktiv && row.rolle_aktiv) {
        return "close text-warning";
      } else if (!row.aktiv && !row.rolle_aktiv) {
        return "close text-danger";
      }
      return "ok text-success";
    },

    getTitleForRowActive_mixin(row) {
      if (!row.aktiv && row.rolle_aktiv) {
        return {
          title: "_MSG_ROLLEN_LIST_IN_NUTZER_ROLLENZUORDNUNG_ABGELAUFEN_{{rolle_bez}}_{{org_bez}}_",
          extra: { rolle_bez: row.rolle_name, org_bez: row.org_name }
        };
      } else if (!row.aktiv && !row.rolle_aktiv) {
        return {
          title: "_MSG_ROLLEN_LIST_IN_NUTZER_ROLLE_DEAKTIVIERT_{{rolle_bez}}_",
          extra: { rolle_bez: row.rolle_name }
        };
      }
      return {
        title: "_MSG_ROLLEN_LIST_IN_NUTZER_ROLLENZUORDNUNG_AKTIV_{{rolle_bez}}_{{org_bez}}_",
        extra: { rolle_bez: row.rolle_name, org_bez: row.org_name }
      };
    },
  },
};
