// Components
import VueDetailsInfoBox from "./components/DetailsInfoBox/DetailsInfoBox.vue";
import GutachtenVorlagenList from "./components/Gutachten/GutachtenVorlagenList/GutachtenVorlagenList.vue";
import VueFoerderfinderprogrammeList from "./components/Foerderfinder/FoerderfinderList/FoerderfinderList.vue";
import VueFoerderfinderIcons from "./components/Foerderfinder/FoerderfinderIcons/FoerderfinderIcons.vue";
import VueFoerderangeboteList from "./components/Foerderfinder/FoerderfinderFoerderangeboteList/FoerderfinderFoerderangeboteList.vue";
import VueContact from "./components/Templates/contact/contact.vue";
import VueDatenschutz from "./components/Templates/datenschutz/datenschutz.vue";
import VueImpressum from "./components/Templates/impressum/impressum.vue";
import VueCookieRichtlinien from "./components/Templates/cookie-richtlinien/cookie-richtlinien.vue";
import VueBarrierefreiheit from "./components/Templates/Barrierefreiheit/Barrierefreiheit.vue";
import VueSearchStart from "./SingleUsageComponents/TheSearchStart/TheSearchStart.vue";
import VueStyleguide from "./components/Styleguide/Styleguide.vue";
import ShowMore from "./global/components/ui/ShowMore/ShowMore.vue";
import Timeline from "./components/Timeline/Timeline.vue";
import ProandiFooter from "./SingleUsageComponents/TheProandiFooter/TheProandiFooter.vue";
import TheModalTranslate from "./SingleUsageComponents/TheModalTranslate/TheModalTranslate.vue";
import TheMessagePanel from "./SingleUsageComponents/TheMessagePanel/TheMessagePanel.vue";
import VueRollenList from "./components/Rollen/RollenList/RollenList.vue";
import RollenDetails from "./components/Rollen/RollenDetails/RollenDetails.vue";
import VueFunktionsgruppenList from "./components/Funktionsgruppen/FunktionsgruppenList/FunktionsgruppenList.vue";
import VueNutzerList from "./components/Nutzer/NutzerList/NutzerList.vue";
import NutzerDetails from "./components/Nutzer/NutzerDetails/NutzerDetails.vue";
import VueNutzerAnonymisiertList from "./components/Nutzer/NutzerAnonymisiertList/NutzerAnonymisiertList.vue";
import VueNutzerListInAntragsteller from "./components/Nutzer/NutzerListInAntragsteller/NutzerListInAntragsteller.vue";
import PersonalList from "./components/Personal/PersonalList/PersonalList.vue";
import PersonalDetails from "./components/Personal/PersonalDetails/PersonalDetails.vue";
import VueTaskLogList from "./components/TaskLog/TaskLogList/TaskLogList.vue";
import VueFaqAdminList from "./components/Faq/FaqAdminList/FaqAdminList.vue";
import VueKatalogeAdminList from "./components/Kataloge/KatalogeAdminList/KatalogeAdminList.vue";
import KatalogeAdminDetails from "./components/Kataloge/KatalogeAdminDetails/KatalogeAdminDetails.vue";
import VueAntraegeGeloeschteList from "./components/Antraege/AntraegeGeloeschteList/AntraegeGeloeschteList.vue";
import VueSystemKonfigurationList from "./components/System/SystemKonfigurationList/SystemKonfigurationList.vue";
import SystemKonfigurationDetails from "./components/System/SystemKonfigurationDetails/SystemKonfigurationDetails.vue";
import VueAnkuendigungList from "./components/Ankuendigung/AnkuendigungList/AnkuendigungList.vue";
import VueVersionsinformationenList from "./components/Versionsinformationen/VersionsinformationenList/VersionsinformationenList.vue";
import VueDeckblattList from "./components/Deckblatt/DeckblattList/DeckblattList.vue";
import VueBerichteVerwaltungList from "./components/Berichte/BerichteVerwaltungList/BerichteVerwaltungList.vue";
import VueBerichteList from "./components/Berichte/BerichteList/BerichteList.vue";
import VueFoerderdokumenteList from "./components/Dokumente/FoerderdokumenteList/FoerderdokumenteList.vue";
import VueTeamsList from "./components/Teams/TeamsList/TeamsList.vue";
import VueKAufgabeList from "./components/Aufgabe/KAufgabeList/KAufgabeList.vue";
import VueWorkflowfolgenDetails from "./components/Workflowfolgen/WorkflowfolgenDetails/WorkflowfolgenDetails.vue";
import VueWorkflowfristListInKAufgabe from "./components/Aufgabe/WorkflowfristListInKAufgabe/WorkflowfristListInKAufgabe.vue";
import VueWorkflowsList from "./components/Workflows/WorkflowsList/WorkflowsList.vue";
import VueWorkflowtypenList from "./components/Workflowtypen/WorkflowtypenList/WorkflowtypenList.vue";
import VueWorkflowfolgenList from "./components/Workflowfolgen/WorkflowfolgenList/WorkflowfolgenList.vue";
import VueWorkflowfolgeclientfunktionenList from "./components/Workflowfolgeclientfunktionen/WorkflowfolgeclientfunktionenList/WorkflowfolgeclientfunktionenList.vue";
import VueWorkflowfolgeserverfunktionenList from "./components/Workflowfolgeserverfunktionen/WorkflowfolgeserverfunktionenList/WorkflowfolgeserverfunktionenList.vue";
import VueWorkflowfolgestatusfunktionenList from "./components/Workflowfolgestatusfunktionen/WorkflowfolgestatusfunktionenList/WorkflowfolgestatusfunktionenList.vue";
import VueWorkflowfolgestatusfunktionenDetails from "./components/Workflowfolgestatusfunktionen/WorkflowfolgestatusfunktionenDetails/WorkflowfolgestatusfunktionenDetails.vue";
import VueSitzungsartenList from "./components/Sitzung/SitzungsartenList/SitzungsartenList.vue";
import VueSachberichtList from "./components/Sachbericht/SachberichtList/SachberichtList.vue";
import VueProgrammeList from "./components/Programme/ProgrammeList/ProgrammeList.vue";
import ProgrammeDetails from "./components/Programme/ProgrammeDetails/ProgrammeDetails.vue";
import VueAngebotList from "./components/Angebot/AngebotList/AngebotList.vue";
import VueInstrumentList from "./components/Instrument/InstrumentList/InstrumentList.vue";
import InstrumentDetails from "./components/Instrument/InstrumentDetails/InstrumentDetails.vue";
import VueInstrumentvariantenList from "./components/Instrument/InstrumentvariantenList/InstrumentvariantenList.vue";
import InstrumentvariantenDetails from "./components/Instrument/InstrumentvariantenDetails/InstrumentvariantenDetails.vue";
import VueFoerderorganisationList from "./components/Foerderorganisation/FoerderorganisationList/FoerderorganisationList.vue";
import VueFoerderorganisationBearbeitenList from "./components/Foerderorganisation/FoerderorganisationBearbeitenList/FoerderorganisationBearbeitenList.vue";
import FoerderorganisationRollenfreigabenList from "./components/Foerderorganisation/FoerderorganisationRollenfreigabenList/FoerderorganisationRollenfreigabenList.vue";
import FoerderorganisationBudget from "./components/Foerderorganisation/FoerderorganisationBudget/FoerderorganisationBudget.vue";
import VueAufgabeList from "./components/Aufgabe/AufgabeList/AufgabeList.vue";
import VueAufgabeModalKorrektur from "./components/Aufgabe/AufgabeModalKorrektur/AufgabeModalKorrektur.vue";
import VueFoerdermittelList from "./components/Foerdermittel/FoerdermittelList/FoerdermittelList.vue";
import VueSitzungList from "./components/Sitzung/SitzungList/SitzungList.vue";
import VueSitzungAntraegeList from "./components/Sitzungsantraege/SitzungAntraegeList/SitzungAntraegeList.vue";
import VueNachrichtenInboxList from "./components/Nachrichten/NachrichtenInboxList/NachrichtenInboxList.vue";
import VueNachrichtenOutboxList from "./components/Nachrichten/NachrichtenOutboxList/NachrichtenOutboxList.vue";
import VueNachrichtenDetails from "./components/Nachrichten/NachrichtenDetails/NachrichtenDetails.vue";
import VueBudgetList from "./components/Budget/BudgetList/BudgetList.vue";
import VueProjektberichtVorlagenList from "./components/Projektbericht/ProjektberichtVorlagentList/ProjektberichtVorlagenList.vue";
import VueProjektberichtList from "./components/Projektbericht/ProjektberichtList/ProjektberichtList.vue";
import VueAntraegeList from "./components/Antraege/AntraegeList/AntraegeList.vue";
import VueAntragslisteList from "./components/Antragsliste/AntragslisteList/AntragslisteList.vue";
import VueModulAntragslisteObjektlisteElementList from "./components/Antragsliste/ModulAntragslisteObjektlisteElementList/ModulAntragslisteObjektlisteElementList.vue";
import VueFormulareList from "./components/Formulare/FormulareList/FormulareList.vue";
import VueUmfrageList from "./components/Umfrage/UmfrageList/UmfrageList.vue";
import VueAuszahlungslisteList from "./components/Auszahlungsliste/AuszahlungslisteList/AuszahlungslisteList.vue";
import VueVertragList from "./components/Vertrag/VertragList/VertragList.vue";
import VueAuszahlungList from "./components/Auszahlung/AuszahlungList/AuszahlungList.vue";
import VueAuszahlungListInVertrag from "./components/Auszahlung/AuszahlungListInVertrag/AuszahlungListInVertrag.vue";
import VueAuszahlungPlanListInVertrag from "./components/Auszahlung/AuszahlungPlanListInVertrag/AuszahlungPlanListInVertrag.vue";
import AuszahlungWechselModalitaet from "./components/Auszahlung/AuszahlungWechselModalitaet/AuszahlungWechselModalitaet.vue";
import VueBelegeList from "./components/Belege/BelegeList/BelegeList.vue";
import VueBelegeListInVertrag from "./components/Belege/BelegeListInVertrag/BelegeListInVertrag.vue";
import VueAntragstellerList from "./components/Antragsteller/AntragstellerList/AntragstellerList.vue";
import VueLogList from "./components/Log/LogList/LogList.vue";
import VueAnonymisierungList from "./components/Anonymisierung/AnonymisierungList/AnonymisierungList.vue";
import VueNutzerDatenSucheList from "./components/Nutzer/NutzerDatenSucheList/NutzerDatenSucheList.vue";
import VueGeschaeftsregelList from "./components/Geschaeftsregel/GeschaeftsregelList/GeschaeftsregelList.vue";
import VueGeschaeftsregelListAlt from "./components/Geschaeftsregel/GeschaeftsregelListAlt/GeschaeftsregelList.vue";
import VueSammelvertragList from "./components/Sammelvertrag/SammelvertragList/SammelvertragList.vue";
import VueRechtsformdokumenteList from "./components/Dokumente/RechtsformdokumenteList/RechtsformdokumenteList.vue";
import VueFiBuJournalList from "./components/FiBu/FiBuJournalList/FiBuJournalList.vue";
import VueDashboardWizardAntragsteller from "./components/Dashboard/DashboardWizardAntragsteller/DashboardWizardAntragsteller.vue";
import VueAntragstellerWizardInAntrag from "./components/Antragsteller/AntragstellerWizardInAntrag/AntragstellerWizardInAntrag.vue";
import VueSitzungsantraegeListInTageordnungspunkt from "./components/Sitzungsantraege/SitzungsantraegeListInTageordnungspunkt/SitzungsantraegeListInTageordnungspunkt.vue";
import VueSitzungsantraegeListInTageordnungspunktMittellos from "./components/Sitzungsantraege/SitzungsantraegeListInTageordnungspunktMittellos/SitzungsantraegeListInTageordnungspunktMittellos.vue";
import VueGutachtenList from "./components/Gutachten/GutachtenList/GutachtenList.vue";
import VueCalendar from "./components/Calendar/Calendar/Calendar.vue";
import FoerderlandkartePage from "./components/Foerderlandkarte/FoerderlandkartePage/FoerderlandkartePage.vue";
import PersonalkostenPeriodenTableInAntrag from "./components/KF/PersonalkostenPerioden/PersonalkostenPeriodenTableInAntrag/PersonalkostenPeriodenTableInAntrag.vue";
import PersonalkostenPeriodenTableInVertrag from "./components/KF/PersonalkostenPerioden/PersonalkostenPeriodenTableInVertrag/PersonalkostenPeriodenTableInVertrag.vue";
import PersonalkostenPeriodenWizard from "./components/KF/PersonalkostenPeriodenWizard/PersonalkostenPeriodenWizard.vue";
import VueAenderungsanzeigeList from "./components/HSK/AenderungsanzeigeList/AenderungsanzeigeList.vue";
import BerichteDetails from "./components/Berichte/BerichteDetails/BerichteDetails.vue";
import VueModulObjektlisteElementList from "./components/Generics/ModulObjektlisteElementList/ModulObjektlisteElementList.vue";
import VueModulObjektlisteEntscheidungElementList from "./components/Generics/ModulObjektlisteEntscheidungElementList/ModulObjektlisteEntscheidungElementList.vue";
import VueModulAuszahlungslisteObjektlisteElementList from "./components/Auszahlungsliste/ModulAuszahlungslisteObjektlisteElementList/ModulAuszahlungslisteObjektlisteElementList.vue";
import VueCalendarList from "./components/Calendar/CalendarList/CalendarList.vue";
import VueDokumenteUpload from "./components/Dokumente/DokumenteUpload/DokumenteUpload.vue";
import DokumenteSuchen from "./components/Dokumente/DokumenteSuchen/DokumenteSuchen.vue";
import BerichteVerwaltungDetails from "./components/Berichte/BerichteVerwaltungDetails/BerichteVerwaltungDetails.vue";
import AnkuendigungDetails from "./components/Ankuendigung/AnkuendigungDetails/AnkuendigungDetails.vue";
import VersionsinformationenDetails from "./components/Versionsinformationen/VersionsinformationenDetails/VersionsinformationenDetails.vue";
import TeamsDetails from "./components/Teams/TeamsDetails/TeamsDetails.vue";
import FaqAdminDetails from "./components/Faq/FaqAdminDetails/FaqAdminDetails.vue";
import FoerderorganisationDetails from "./components/Foerderorganisation/FoerderorganisationDetails/FoerderorganisationDetails.vue";
import FoerderdokumenteDetails from "./components/Dokumente/FoerderdokumenteDetails/FoerderdokumenteDetails.vue";
import VueDokumenteZurFoerderungList from "./components/Hilfe/DokumenteZurFoerderungList/DokumenteZurFoerderungList.vue";
import FaqDetails from "./components/Faq/FaqDetails/FaqDetails.vue";
import BudgetDetails from "./components/Budget/BudgetDetails/BudgetDetails.vue";
import CodeList from "./components/Code/CodeList/CodeList.vue";
import ImportauftragList from "./components/Importauftrag/ImportauftragList/ImportauftragList.vue";
import ImportauftragAntragWizard from "./components/Importauftrag/ImportauftragAntragWizard/ImportauftragAntragWizard.vue";
import ImportauftragJsonWizard from "./components/Importauftrag/ImportauftragJsonWizard/ImportauftragJsonWizard.vue";
import ExportauftragJsonWizard from "./components/Importauftrag/ExportauftragJsonWizard/ExportauftragJsonWizard.vue";
import BelegButtonVersionsvergleich from "./components/Belege/BelegButtonVersionsvergleich/BelegButtonVersionsvergleich.vue";
import RegistrationHeader from "./components/Registration/RegistrationHeader/RegistrationHeader.vue";
import DashboardPublic from "./components/Dashboard/DashboardPublic/DashboardPublic.vue";
import AnsprechpartnerZuweisen from "./components/Ansprechpartner/AnsprechpartnerZuweisen/AnsprechpartnerZuweisen.vue";
import AntragsdatumText from "./components/Geschaeftsregel/AntragsdatumText/AntragsdatumText.vue";
import AntragCreateExtern from "./components/Antraege/AntragCreateExtern/AntragCreateExtern.vue";
import PuxButton from "./global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "./global/components/PuxTranslate/PuxTranslate.vue";
import DashboardPrivate from "./components/Dashboard/DashboardPrivate/DashboardPrivate.vue";
import VueHilfe from "./components/Hilfe/Hilfe/Hilfe.vue";
import RegistrationPage from "./components/Registration/RegistrationPage/RegistrationPage.vue";
import RegistrationFinishPage from "./components/Registration/RegistrationFinishPage/RegistrationFinishPage.vue";
import ResetPasswordPage from "./components/Registration/ResetPasswordPage/ResetPasswordPage.vue";
import ResetPasswordFinishPage from "./components/Registration/ResetPasswordFinishPage/ResetPasswordFinishPage.vue";
import EmailChangePage from "./components/Registration/EmailChangePage/EmailChangePage.vue";
import LoginPage from "./components/Registration/Login/LoginPage/LoginPage.vue";
import TemplateRendering from "./components/Templates/TemplateRendering/TemplateRendering.vue";
import TheMenu from "./SingleUsageComponents/TheMenu/TheMenu.vue";
import TheMenuHorizontal from "./SingleUsageComponents/TheMenuHorizontal/TheMenuHorizontal.vue";
import TheMenuJump from "./SingleUsageComponents/TheMenuJump/TheMenuJump.vue";
import MenuDetails from "./SingleUsageComponents/TheMenu/MenuDetails/MenuDetails.vue";
import GeschaeftsregelModul from "./components/Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";
import NotizenBox from "./components/Notizen/NotizenBox/NotizenBox.vue";
import NotizenIcon from "./components/Notizen/NotizenIcon/NotizenIcon.vue";
import RechenhilfeButton from "./components/KF/PersonalkostenPerioden/PersonalkostenPeriodenTableInVertrag/RechenhilfeButton/RechenhilfeButton.vue";
import PageTabTitle from "./global/components/PageTabTitle/PageTabTitle.vue";
import VertretungsberechtigteModalCreate
  from "./components/Vertretungsberechtigte/VertretungsberechtigteModalCreate/VertretungsberechtigteModalCreate.vue";
import AnalysenStatus from "./components/Analysen/AnalysenStatus/AnalysenStatus.vue";
import VideoDetails from "./components/Video/VideoDetails/VideoDetails.vue";
import VueWorkflowfolgeserverfunktionenDetails from "./components/Workflowfolgeserverfunktionen/WorkflowfolgeserverfunktionenDetails/WorkflowfolgeserverfunktionenDetails.vue";
import VueBuchungenList from "./components/Buchungen/BuchungenList/BuchungenList.vue";
import GeschaeftsregelDetailsModul from "./components/Geschaeftsregel/GeschaeftsregelDetails/GeschaeftsregelDetailsModul/GeschaeftsregelDetailsModul.vue";
import ParameterFormular from "./global/components/ParameterFormular/ParameterFormular.vue";
import TheNotification from "./global/components/TheNotification/TheNotification.vue";
import WFStatusGruppen from "./components/Workflows/StatusGruppen/StatusGruppen.vue";
import AntragDetailsAngebot from "./components/Antraege/AntragDetails/AntragDetailsAngebot/AntragDetailsAngebot.vue";
import KostenPlan from "./components/KF/KFPlan/KostenPlan/KostenPlan.vue";
import FinanzierungsPlan from "./components/KF/KFPlan/FinanzierungsPlan/FinanzierungsPlan.vue";
import ZuschussPlan from "./components/KF/KFPlan/ZuschussPlan/ZuschussPlan.vue";
import VueKofiErfassung from "./components/KF/KofiErfassung/KofiErfassung.vue";
import VueAktualisierterZuschuss from "./components/KF/AktualisierterZuschuss/AktualisierterZuschuss.vue";
import TheNavbar from "./SingleUsageComponents/TheNavbar/TheNavbar.vue";
import FormularDetailsBody from "./components/Formulare/FormularDetailsBody/FormularDetailsBody.vue";
import FormElement from "./global/components/FormElement/FormElement.vue";
import FormElementReadOnly from "./global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import AngebotDetailsConfig from "./components/Angebot/AngebotDetails/AngebotDetailsConfig/AngebotDetailsConfig.vue";
import Modal from "./global/components/Modal/Modal.vue";
import KFPerioden from "./components/KF/KFPerioden/KFPerioden.vue";
import ProfilDetails from "./components/Profil/ProfilDetails/ProfilDetails.vue";
import BelegCreateButton from "./components/KF/BelegCreateButton/BelegCreateButton.vue";
import VueDynamicRoot from "./components/DynamicRoot/DynamicRoot.vue";
import WorkflowsDetails from "./components/Workflows/WorkflowsDetails/WorkflowsDetails.vue";
import TrackingDetails from "./components/Tracking/TrackingDetails/TrackingDetails.vue";
import TemplateAdminList from "./components/DynamischeTemplates/TemplateAdminList/TemplateAdminList.vue";
import TemplateAdminDetails from "./components/DynamischeTemplates/TemplateAdminDetails/TemplateAdminDetails.vue";
import FormattedAntragScore from "./components/Antraege/FormattedAntragScore/FormattedAntragScore.vue";
import AngebotDetailsBenachrichtigungen from "./components/Angebot/AngebotDetails/AngebotDetailsBenachrichtigungen/AngebotDetailsBenachrichtigungen.vue";
import AngebotDetailsModalVorversionAntraegeWorkflowfolge from "./components/Angebot/AngebotDetails/ModalVorversionAntraegeWorkflowfolge/ModalVorversionAntraegeWorkflowfolge.vue";
import AnsprechpartnerModalEdit from "./components/Antraege/AntragDetails/AnsprechpartnerModalEdit/AnsprechpartnerModalEdit.vue";
import IjfTemplateDetails from "./components/Importauftrag/IjfTemplate/IjfTemplateDetails.vue";
import IjfTemplateList from "./components/Importauftrag/IjfTemplate/IjfTemplateList/IjfTemplateList.vue";
import KFAuszahlungPerioden from "./components/KF/KFAuszahlungPerioden/KFAuszahlungPerioden.vue";
import VueFavoriten from "./components/Favoriten/Favoriten.vue";
import VueSichtbarkeit from "./components/Sichtbarkeit/Sichtbarkeit.vue";
import VerbundeneAntraege from "./components/Antraege/AntragDetails/VerbundeneAntraege/VerbundeneAntraege.vue";
import AntragWarningBox from "./components/Antraege/AntragDetails/AntragWarningBox/AntragWarningBox.vue";
import FormularCreate from "./components/Formulare/FormularCreate/FormularCreate.vue";
import AntragGoToBox from "./components/Antraege/AntragDetails/AntragGoToBox/AntragGoToBox.vue";
import VertragsKonto from "./components/Vertrag/VertragsKonto/VertragsKonto.vue";
import AufgabeBox from "./components/Aufgabe/AufgabeBox/AufgabeBox.vue";
import ManuelleAufgabenBox from "./components/Aufgabe/ManuelleAufgaben/ManuelleAufgabenBox/ManuelleAufgabenBox.vue";
import GutachtenErgebnisse from "./components/Gutachten/GutachtenErgebnisse/GutachtenErgebnisse.vue";
import NachrichtenObjectListBox from "./components/Nachrichten/NachrichtenObjectListBox/NachrichtenObjectListBox.vue";
import VertragGoToBox from "./components/Vertrag/VertragDetails/VertragGoToBox/VertragGoToBox.vue";
import SnapshotsBox from "./components/Snapshot/SnapshotsBox/SnapshotsBox.vue";
import Verwendungszweck from "./components/Auszahlung/AuszahlungDetails/Verwendungszweck/Verwendungszweck.vue";
import AnlagenAuszahlung from "./components/Auszahlung/AuszahlungDetails/AnlagenAuszahlung/AnlagenAuszahlung.vue";
import AuszahlungBankverbindung from "./components/Auszahlung/AuszahlungDetails/AuszahlungBankverbindung/AuszahlungBankverbindung.vue";
import HinweiseZurAuszahlung from "./components/Auszahlung/AuszahlungDetails/HinweiseZurAuszahlung/HinweiseZurAuszahlung.vue";
import SachPruefBericht from "./components/Auszahlung/AuszahlungDetails/SachPruefBericht/SachPruefBericht.vue";
import AuszahlungZeitraum from "./components/Auszahlung/AuszahlungDetails/AuszahlungZeitraum/AuszahlungZeitraum.vue";
import AuszahlungWizard from "./components/Auszahlung/AuszahlungDetails/AuszahlungWizard/AuszahlungWizard.vue";
import WorkflowFolgen from "./components/Workflowfolgen/WorkflowFolgen/WorkflowFolgen.vue";
import AuszahlungNotifikationBox from "./components/Auszahlung/AuszahlungDetails/AuszahlungNotifikationBox/AuszahlungNotifikationBox.vue";
import AuszahlungGoToBox from "./components/Auszahlung/AuszahlungDetails/AuszahlungGoToBox/AuszahlungGoToBox.vue";
import AuszahlungGoToBoxAuszahlungLink from "./components/Auszahlung/AuszahlungDetails/AuszahlungGoToBox/AuszahlungGoToBoxAuszahlungLink/AuszahlungGoToBoxAuszahlungLink.vue";
import AuszahlungBelegSummenBox from "./components/Auszahlung/AuszahlungDetails/AuszahlungBelegSummenBox/AuszahlungBelegSummenBox.vue";
import AuszahlungBetragBox from "./components/Auszahlung/AuszahlungDetails/AuszahlungBetragBox/AuszahlungBetragBox.vue";
import AuszahlungUebersicht from "./components/Auszahlung/AuszahlungDetails/AuszahlungUebersicht/AuszahlungUebersicht.vue";
import AuszahlungDetails from "./components/Auszahlung/AuszahlungDetails/AuszahlungDetails.vue";
import AntragDetails from "./components/Antraege/AntragDetails/AntragDetails.vue";
import WorkflowtypDetails from "./components/Workflowtypen/WorkflowtypDetails/WorkflowtypDetails.vue";
import VertragDetails from "./components/Vertrag/VertragDetails/VertragDetails.vue";
import SachberichtvorlageDetails from "./components/Sachbericht/SachberichtvorlageDetails/SachberichtvorlageDetails.vue";
import TagesordnungspunktDetails from "./components/Tagesordnungspunkt/TagesordnungspunktDetails/TagesordnungspunktDetails.vue";
import SitzungAntraegeDetails from "./components/Sitzungsantraege/SitzungAntraegeDetails/SitzungAntraegeDetails.vue";
import GeschaeftsregelDetails from "./components/Geschaeftsregel/GeschaeftsregelDetails/GeschaeftsregelDetails.vue";
import WorkflowfolgeclientfunktionenDetails from "./components/Workflowfolgeclientfunktionen/WorkflowfolgeclientfunktionenDetails/WorkflowfolgeclientfunktionenDetails.vue";
import AufgabenvorlagenDetails from "./components/Aufgabe/AufgabenvorlagenDetails/AufgabenvorlagenDetails.vue";
import SitzungsartDetails from "./components/Sitzung/SitzungsartDetails/SitzungsartDetails.vue";
import IconsList from "./components/Icons/IconsList/IconsList.vue";
import SitzungDetails from "./components/Sitzung/SitzungDetails/SitzungDetails.vue";
import DatenexportList from "./components/Datenexport/DatenexportList/DatenexportList.vue";
// AR /
import AkkreditierteHochschuleDetails from "../../../../apps/dias-ar/libs/vue/AkkreditierteHochschule/AkkreditierteHochschuleDetails/AkkreditierteHochschuleDetails.vue";
import AkkreditierteStudiengaengeList from "../../../../apps/dias-ar/libs/vue/AkkreditierteStudiengaenge/AkkreditierteStudiengaengeList/AkkreditierteStudiengaengeList.vue";
import AkkreditierteStudiengangDetails from "../../../../apps/dias-ar/libs/vue/AkkreditierteStudiengaenge/AkkreditierteStudiengangDetails/AkkreditierteStudiengangDetails.vue";
import ArAuflagenerfuellungBearbeiten from "../../../../apps/dias-ar/libs/vue/Programmakkreditierung/AuflagenerfuellungBearbeiten/AuflagenerfuellungBearbeiten.vue";
import ArGutachtenAustauschen from "../../../../apps/dias-ar/libs/vue/Programmakkreditierung/GutachtenAustauschen/GutachtenAustauschen.vue";
import VueAkkreditierungsantraegeList from "../../../../apps/dias-ar/libs/vue/Akkreditierungsantraege/AkkreditierungsantraegeList/AkkreditierungsantraegeList.vue";
import VueAkkreditierungHochschuleList from "../../../../apps/dias-ar/libs/vue/AkkreditierteHochschule/AkkreditierteHochschuleList/AkkreditierteHochschuleList.vue";
import VueHochschuleDetails from "../../../../apps/dias-ar/libs/vue/Hochchule/HochschuleDetails/HochschuleDetails.vue";
import VueHochschuleList from "../../../../apps/dias-ar/libs/vue/Hochchule/HochschuleList/HochschuleList.vue";
import VueStudiengangDetails from "../../../../apps/dias-ar/libs/vue/Studiengang/StudiengangDetails/StudiengangDetails.vue";
import VueProgrammakkreditierungDetails from "../../../../apps/dias-ar/libs/vue/Programmakkreditierung/ProgrammakkreditierungDetails/ProgrammakkreditierungDetails.vue";
import VueProgrammakkreditierungList from "../../../../apps/dias-ar/libs/vue/Programmakkreditierung/ProgrammakkreditierungList/ProgrammakkreditierungList.vue";
import VueStudiengangList from "../../../../apps/dias-ar/libs/vue/Studiengang/StudiengangList/StudiengangList.vue";
import VueSystemakkreditierungList from "../../../../apps/dias-ar/libs/vue/Systemakkreditierung/SystemakkreditierungList/SystemakkreditierungList.vue";
import VueSystemakkreditierungDetails from "../../../../apps/dias-ar/libs/vue/Systemakkreditierung/SystemakkreditierungDetails/SystemakkreditierungDetails.vue";
// AR

export default {
  GutachtenVorlagenList,
  VueDetailsInfoBox,
  VueContact,
  VueDatenschutz,
  VueImpressum,
  VueCookieRichtlinien,
  VueBarrierefreiheit,
  VueFoerderfinderprogrammeList,
  VueFoerderangeboteList,
  VueFoerderfinderIcons,
  VueSearchStart,
  VueStyleguide,
  ShowMore,
  Timeline,
  ProandiFooter,
  TheModalTranslate,
  TheMessagePanel,
  VueRollenList,
  RollenDetails,
  VueFunktionsgruppenList,
  VueNutzerList,
  NutzerDetails,
  VueNutzerAnonymisiertList,
  VueNutzerListInAntragsteller,
  PersonalList,
  PersonalDetails,
  VueTaskLogList,
  VueFaqAdminList,
  VueKatalogeAdminList,
  KatalogeAdminDetails,
  VueAntraegeGeloeschteList,
  VueSystemKonfigurationList,
  SystemKonfigurationDetails,
  VueAnkuendigungList,
  VueVersionsinformationenList,
  VueDeckblattList,
  VueBerichteVerwaltungList,
  VueBerichteList,
  VueFoerderdokumenteList,
  VueTeamsList,
  VueKAufgabeList,
  VueWorkflowfolgenDetails,
  VueWorkflowfristListInKAufgabe,
  VueWorkflowsList,
  VueWorkflowtypenList,
  VueWorkflowfolgenList,
  VueWorkflowfolgeclientfunktionenList,
  VueWorkflowfolgeserverfunktionenList,
  VueWorkflowfolgeserverfunktionenDetails,
  VueWorkflowfolgestatusfunktionenList,
  VueWorkflowfolgestatusfunktionenDetails,
  VueSitzungsartenList,
  VueSachberichtList,
  VueProgrammeList,
  ProgrammeDetails,
  VueAngebotList,
  VueInstrumentList,
  InstrumentDetails,
  VueInstrumentvariantenList,
  InstrumentvariantenDetails,
  VueFoerderorganisationList,
  VueFoerderorganisationBearbeitenList,
  FoerderorganisationRollenfreigabenList,
  FoerderorganisationBudget,
  VueAufgabeList,
  VueAufgabeModalKorrektur,
  VueFoerdermittelList,
  VueSitzungList,
  VueSitzungAntraegeList,
  VueNachrichtenInboxList,
  VueNachrichtenOutboxList,
  VueNachrichtenDetails,
  VueBudgetList,
  VueProjektberichtVorlagenList,
  VueProjektberichtList,
  VueAntraegeList,
  VueAntragslisteList,
  VueModulAntragslisteObjektlisteElementList,
  VueFormulareList,
  VueUmfrageList,
  VueAuszahlungslisteList,
  VueVertragList,
  VueAuszahlungList,
  VueAuszahlungListInVertrag,
  VueAuszahlungPlanListInVertrag,
  AuszahlungWechselModalitaet,
  VueBelegeList,
  VueBelegeListInVertrag,
  VueAntragstellerList,
  VueLogList,
  VueAnonymisierungList,
  VueNutzerDatenSucheList,
  VueGeschaeftsregelList,
  VueGeschaeftsregelListAlt,
  VueSammelvertragList,
  VueRechtsformdokumenteList,
  VueFiBuJournalList,
  VueDashboardWizardAntragsteller,
  VueAntragstellerWizardInAntrag,
  VueSitzungsantraegeListInTageordnungspunkt,
  VueSitzungsantraegeListInTageordnungspunktMittellos,
  VueGutachtenList,
  VueCalendar,
  FoerderlandkartePage,
  PersonalkostenPeriodenTableInAntrag,
  PersonalkostenPeriodenTableInVertrag,
  PersonalkostenPeriodenWizard,
  VueAenderungsanzeigeList,
  BerichteDetails,
  VueModulObjektlisteElementList,
  VueModulObjektlisteEntscheidungElementList,
  VueModulAuszahlungslisteObjektlisteElementList,
  VueCalendarList,
  VueDokumenteUpload,
  DokumenteSuchen,
  BerichteVerwaltungDetails,
  AnkuendigungDetails,
  VersionsinformationenDetails,
  TeamsDetails,
  FaqAdminDetails,
  FoerderorganisationDetails,
  FoerderdokumenteDetails,
  VueDokumenteZurFoerderungList,
  FaqDetails,
  BudgetDetails,
  CodeList,
  ImportauftragList,
  ImportauftragAntragWizard,
  ImportauftragJsonWizard,
  ExportauftragJsonWizard,
  BelegButtonVersionsvergleich,
  RegistrationHeader,
  DashboardPublic,
  AnsprechpartnerZuweisen,
  AntragsdatumText,
  AntragCreateExtern,
  PuxButton,
  PuxTranslate,
  DashboardPrivate,
  VueHilfe,
  RegistrationPage,
  RegistrationFinishPage,
  ResetPasswordPage,
  ResetPasswordFinishPage,
  EmailChangePage,
  LoginPage,
  TemplateRendering,
  TheMenu,
  TheMenuHorizontal,
  TheMenuJump,
  MenuDetails,
  GeschaeftsregelModul,
  NotizenBox,
  NotizenIcon,
  RechenhilfeButton,
  PageTabTitle,
  VertretungsberechtigteModalCreate,
  AnalysenStatus,
  VideoDetails,
  VueBuchungenList,
  GeschaeftsregelDetailsModul,
  ParameterFormular,
  TheNotification,
  WFStatusGruppen,
  AntragDetailsAngebot,
  KostenPlan,
  FinanzierungsPlan,
  ZuschussPlan,
  VueKofiErfassung,
  VueAktualisierterZuschuss,
  TheNavbar,
  FormularDetailsBody,
  FormElement,
  FormElementReadOnly,
  AngebotDetailsConfig,
  Modal,
  KFPerioden,
  ProfilDetails,
  BelegCreateButton,
  VueDynamicRoot,
  WorkflowsDetails,
  TrackingDetails,
  TemplateAdminList,
  TemplateAdminDetails,
  FormattedAntragScore,
  AngebotDetailsBenachrichtigungen,
  AngebotDetailsModalVorversionAntraegeWorkflowfolge,
  AnsprechpartnerModalEdit,
  IjfTemplateDetails,
  IjfTemplateList,
  KFAuszahlungPerioden,
  VueFavoriten,
  VueSichtbarkeit,
  VerbundeneAntraege,
  AntragWarningBox,
  FormularCreate,
  AntragGoToBox,
  VertragsKonto,
  AufgabeBox,
  ManuelleAufgabenBox,
  GutachtenErgebnisse,
  NachrichtenObjectListBox,
  VertragGoToBox,
  SnapshotsBox,
  Verwendungszweck,
  AnlagenAuszahlung,
  AuszahlungBankverbindung,
  HinweiseZurAuszahlung,
  SachPruefBericht,
  AuszahlungZeitraum,
  AuszahlungWizard,
  WorkflowFolgen,
  AuszahlungNotifikationBox,
  AuszahlungGoToBox,
  AuszahlungGoToBoxAuszahlungLink,
  AuszahlungBelegSummenBox,
  AuszahlungBetragBox,
  AuszahlungUebersicht,
  AuszahlungDetails,
  AntragDetails,
  WorkflowtypDetails,
  VertragDetails,
  SachberichtvorlageDetails,
  TagesordnungspunktDetails,
  SitzungAntraegeDetails,
  GeschaeftsregelDetails,
  WorkflowfolgeclientfunktionenDetails,
  AufgabenvorlagenDetails,
  SitzungsartDetails,
  IconsList,
  SitzungDetails,
  DatenexportList,
  // AR /
  AkkreditierteHochschuleDetails,
  AkkreditierteStudiengaengeList,
  AkkreditierteStudiengangDetails,
  ArAuflagenerfuellungBearbeiten,
  ArGutachtenAustauschen,
  VueHochschuleDetails,
  VueAkkreditierungsantraegeList,
  VueAkkreditierungHochschuleList,
  VueHochschuleList,
  VueProgrammakkreditierungList,
  VueProgrammakkreditierungDetails,
  VueStudiengangList,
  VueStudiengangDetails,
  VueSystemakkreditierungList,
  VueSystemakkreditierungDetails,
  // AR
};
