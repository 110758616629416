import { createNamespacedHelpers } from "vuex";
import {
  replaceText,
  gettext,
  isTranslatePlaceholder,
} from "../functions/utils";

const {
  mapActions,
} = createNamespacedHelpers("notification");

export default {
  methods: {
    addNotification({ text, type = "success", timeout, extra }) {
      let textLocal = text;
      const TYPE = type === "error" ? "danger" : type;
      if (isTranslatePlaceholder(text)) {
        textLocal = replaceText({ text: gettext(textLocal), object: extra });
      }
      this.ADD_NOTIFICATION_ACT({ text: textLocal, type: TYPE, timeout });
    },

    ...mapActions([
      "ADD_NOTIFICATION_ACT",
    ]),
  },
};
