import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  getTextFromPlaceholder,
} from "../../../../../global/functions/utils";
import {
  isArray,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragErrorsDt",
  components: {
    PuxButton,
    PuxTranslate,
  },
  props: {
    error: {
      type: [Object, Array],
      required: true,
    },
    errorIndex: {
      type: Number,
      required: true,
    },
    showErrorModulName: {
      type: Function,
      required: true,
    },
  },
  emits: [
    "goTo",
  ],
  computed: {
    errorsLocal() {
      return this.isErrorInGeschaeftsregel ?
        this.error[0] :
        this.error;
    },

    isErrorInGeschaeftsregel() {
      return isArray(this.error);
    },

    kontextName() {
      return this.showErrorModulName(this.errorsLocal);
    },

    kontextNameTranslated() {
      return getTextFromPlaceholder(this.kontextName);
    },

    extraForTranslate() {
      return {
        name: this.kontextNameTranslated,
      };
    },

    buttonId() {
      return `vertrag_goto_error_${ this.errorIndex }`;
    },
  },
  methods: {
    goTo() {
      this.$emit("goTo", {
        error: this.errorsLocal,
      });
    },
  },
};
