import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PanelComponents from "./PanelComponents";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "VersionsinformationenDetails",
  components: {
    ContextPanel,
    Modal,
    PanelComponents,
    PuxButton,
    PuxPageDetails,
    PuxTranslate,
    ...PanelComponents,
  },
  mixins: [
    HttpMixin,
  ],
  data() {
    return {
      loading: true,
      versionsinformationen: undefined,
      confirmShow: undefined,
      confirmOptions: undefined,
      panels: [
        {
          label: "_TXT_VERSIONSINFORMATIONEN_DETAILS_VERSIONSINFORMATIONEN_PANEL_NAME_",
          icon: "glyphicon-star",
          active: true,
          pk: "versionsinformationen",
          components: [
            {
              name: "Versionsinformationen",
              pk: "versionsinformationen",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.loadVersionsinformationen();
  },
  methods: {
    loadVersionsinformationen() {
      this.getHttp({
        url: `releasenotes/${ this.$stateParams.id }/`,
      }).then(
        response => {
          this.versionsinformationen = response;
        }
      ).then(
        () => this.loading = false
      );
    },

    updateVersionsinformationen({ Versionsinformationen }) {
      this.versionsinformationen = Versionsinformationen;
    },

    deleteVersionsinformationen({ versionsinformationen }) {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: `releasenotes/${ versionsinformationen.pk }/`,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_VERSIONSINFORMATIONEN_DETAILS_DELETE_{{titel}}_",
            extra: {
              titel: this.versionsinformationen.vin_versionsnummer,
            },
          });
          this.closeConfirmVersionsinformationen();
          this.$goTo("root.releasenotes");
        }
      ).then(
        () => this.confirmOptions.loading = false
      );
    },

    openModalDeleteVersionsinformationen({ versionsinformationen }) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_VERSIONSINFORMATIONEN_DETAILS_DELETE_MODAL_HEADER_",
        msg: "_TXT_VERSIONSINFORMATIONEN_DELETE_MODAL_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteVersionsinformationen({ versionsinformationen }),
        cancelCallback: this.closeConfirmVersionsinformationen,
      };
      this.confirmShow = true;
    },

    closeConfirmVersionsinformationen() {
      this.confirmShow = false;
    },
  },
};
