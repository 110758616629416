"use strict";

import "restangular";
import angular from "angular";

import Sitzungsantrag from "./sitzungsantraege.service";
import SitzungsFoerderantrag from "./sitzungsfoerderantraege.service";

const modelsModule = angular.module("sitzungen.antraege.models", [
  "restangular",
])

  .provider("SitzungsFoerderantrag", SitzungsFoerderantrag)
  .provider("Sitzungsantrag", Sitzungsantrag)
;

export default modelsModule;
