import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "AntragWarningBoxKeineAntragsteller",
  components: {
    AsideRightBox,
    PuxTranslate,
  },
  props: {
    antragstellerPk: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      optionsAsideRightBoxWarning: {
        icon: "warning",
        toggle: false,
      },
    };
  },
  computed: {
    isBoxVisible() {
      return !this.antragstellerPk;
    },
  },
};
