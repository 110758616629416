"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import "angular-chart.js";
import "angular-drag-and-drop-lists";

import diasAuthService from "dias/auth/services";
import diasConfirm from "dias/core/confirm";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";

import foerdermodule from "dias/foerderantrag/foerdermodule";
import foerderregeln from "dias/foerderregeln/models";

import models from "../models";
import regeln from "../regel";
import listComponent from "./list.component";
import detailsComponent from "./details.component";


const gutachtenvorlagenModule = angular.module("root.gutachten.vorlagen", [
  uiRouter,
  models.name,
  regeln.name,
  foerdermodule.name,
  foerderregeln.name,
  diasAuthService.name,
  diasConfirm.name,
  diasTable.name,
  diasLoading.name,
  diasNotification.name,
]);

gutachtenvorlagenModule.config($stateProvider => {
  $stateProvider

    // Sitzungs-Liste angular // TODO remove this route after testing and approval the Vue.js component "Gutachtenvorlagen"
    .state("root.gutachtenvorlagen1", {
      url: "/gutachtenvorlagen1/",
      views: {
        "main@root": {
          template: "<gutachtenvorlagen-list></gutachtenvorlagen-list>"
        }
      },
      data: {
        permissions: ["gutachtenvorlage.view"]
      },
    })

    // Sitzungs-Liste
    .state("root.gutachtenvorlagen", {
      url: "/gutachtenvorlagen/",
      views: {
        "main@root": {
          template: "<div vueinit><gutachten-vorlagen-list></gutachten-vorlagen-list></div>"
        }
      },
      data: {
        permissions: ["gutachtenvorlage.view"]
      },
    })

    // Detail-Seite einer Sitzung
    .state("root.gutachtenvorlagen.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<gutachtenvorlagen-details></gutachtenvorlagen-details>"
        }
      },
      data: {
        permissions: ["gutachtenvorlage.view"]
      },
    })

  ;
})

  .component("gutachtenvorlagenList", listComponent)
  .component("gutachtenvorlagenDetails", detailsComponent)
;

export default gutachtenvorlagenModule;
