import Alert from "../../../global/components/Alert/Alert.vue";
import FormElement from "../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "NutzerModalAuthAendern",
  components: {
    Alert,
    FormElement,
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
      },
      model: "",
      optionsFormElement: {
        id: "authmethod",
        type: "select",
        label: "_LBL_NUTZER_MODAL_AUTH_AENDERN_AUTHENTIFIZIERUNGSMETHODE_",
        required: true,
        view: "v",
        keyId: "pk",
        keyLabel: "kbez",
        menuWidthAll: true,
        url: "katalog/?fields=pk&fields=kbez&key=authmethods",
      },
      status: {
        loading: undefined,
      },
      errors: {},
      errorLabels: {
        authmethod: "_LBL_NUTZER_MODAL_AUTH_AENDERN_AUTHENTIFIZIERUNGSMETHODE_"
      }
    };
  },
  computed: {
    isErrors() {
      return size(this.errors);
    },
  },
  created() {
    this.initValues();
  },
  methods: {
    initValues() {
      this.model = this.nutzer.authmethod;
    },

    save() {
      this.status.loading = true;
      const data = cloneDeep(this.nutzer);
      data.authmethod = this.model;
      this.postHttp({ url: `nutzer/${ this.nutzer.pk }/set_authmethod/`, data }).then(
        response => {
          this.addNotification({
            text: "_MSG_NUTZER_MODAL_AUTH_AENDERN_SUCCESS_{{email}}_",
            extra: {
              email: this.nutzer.n_email
            }
          });
          this.close({ response });
        },
        error => {
          this.errors = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
