// @vue/component
export default {
  name: "StyleguideHttp",
  data() {
    return {
      allHttpFunctions: [
        {
          name: "this.getHttp",
          example: `this.getHttp({ url: "auszahlungen/", apiSaveId: "auszahlungen1" }).then(response => console.log(response))`,
          parameters: [
            "url", "data", "urlParams", "headerParams", "responseType", "fullResponse", "apiSaveId",
          ],
          description: "Eine get-Anfrage stellen",
        },
        {
          name: "this.getListHttp",
          example: `this.getListHttp({ url: "auszahlungen/", apiSaveId: "auszahlungen1" }).then(response => console.log(response))`,
          parameters: [
            "url", "data", "urlParams", "headerParams", "responseType", "fullResponse", "apiSaveId",
          ],
          description: "Eine get-Anfrage stellen, warten auf Array oder { results: Array }, return immer Array",
        },
        {
          name: "this.postHttp",
          example: `this.postHttp({ url: "auszahlungen/", data: { foo: "bar" } }).then(response => console.log(response))`,
          parameters: [
            "url", "data", "urlParams", "headerParams", "responseType", "fullResponse",
          ],
          description: "Eine post-Anfrage stellen",
        },
        {
          name: "this.putHttp",
          example: `this.putHttp({ url: "auszahlungen/", data: { foo: "bar" } }).then(response => console.log(response))`,
          parameters: [
            "url", "data", "urlParams", "headerParams", "responseType", "fullResponse",
          ],
          description: "Eine put-Anfrage stellen",
        },
        {
          name: "this.patchHttp",
          example: `this.patchHttp({ url: "auszahlungen/", data: { foo: "bar" } }).then(response => console.log(response))`,
          parameters: [
            "url", "data", "urlParams", "headerParams", "responseType", "fullResponse",
          ],
          description: "Eine patch-Anfrage stellen",
        },
        {
          name: "this.deleteHttp",
          example: `this.deleteHttp({ url: "auszahlungen/" }).then(response => console.log(response))`,
          parameters: [
            "url", "data", "urlParams", "headerParams", "responseType", "fullResponse",
          ],
          description: "Eine delete-Anfrage stellen",
        },
      ],
      httpFunctionParameters: [
        {
          name: "url",
          example: `this.deleteHttp({ url: "auszahlungen/" }).then(response => console.log(response))`,
          description: "API-url",
        },
        {
          name: "data",
          example: `this.postHttp({ url: "auszahlungen/", data: { foo: "bar" } }).then(response => console.log(response))`,
          description: "Request-body",
        },
        {
          name: "urlParams",
          example: `this.getHttp({ url: "auszahlungen/", urlParams: { foo: "bar" } }).then(response => console.log(response))`,
          description: "Get-Paremeter für die Anfrage",
        },
        {
          name: "headerParams",
          example: `this.postHttp({ url: "auszahlungen/", headerParams: { foo: "bar" } }).then(response => console.log(response))`,
          description: "Header-Paremeter für die Anfrage",
        },
        {
          name: "responseType",
          example: `this.postHttp({ url: "auszahlungen/", responseType: "blob" }).then(response => console.log(response))`,
          description: "responseType-Paremeter für die Anfrage",
        },
        {
          name: "fullResponse",
          example: `this.getHttp({ url: "auszahlungen/", fullResponse: true }).then(response => { console.log(response.data); console.log(response.headers);})`,
          description: "Die volle Antwort (data, headers...)",
        },
        {
          name: "apiSaveId",
          example: `this.gettHttp({ url: "auszahlungen/", apiSaveId: "auszahlungen1" }).then(response => console.log(response))`,
          description: "API-Identifikator, wenn wir diese API speichern möchten",
        },
      ],
    };
  },
  methods: {

  },
};
