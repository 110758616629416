import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTooltip from "../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import Loading from "../../../global/directives/loading";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import getTableApi from "../../../const/TableApis";
import { EventBus } from "../../../global/functions/event-bus";
import {
  assign,
  forEach,
  sortBy,
  find,
} from "lodash-es";

const TABLE_ID = "modulobjektlisteentscheidungelementlist";

// @vue/component
export default {
  name: "ModulObjektlisteEntscheidungElementList",
  components: {
    PuxCloak,
    PuxTooltip,
    PuxTranslate,
    SmartTable,
  },
  directives: {
    Loading,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
  ],
  props: {
    modul: {
      type: Object,
      default: () => ({}),
    },
    objekt: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: "",
        cols: [{
          id: "ole_pos",
          label: "_LBL_OBJEKTLISTE_ENTSCHEIDUNG_ELEMENT_LIST_POSITION_",
          sort_id: "ole_pos",
          sortable: true,
          sorting_default: "asc",
          priority: 10,
        }, {
          id: "ole_objektname",
          label: "_LBL_OBJEKTLISTE_ENTSCHEIDUNG_ELEMENT_LIST_NAME_",
          sort_id: "ole_objektname",
          sortable: true,
          priority: 20,
        }, {
          id: "entscheidung",
          slot: "status",
          label: "_LBL_OBJEKTLISTE_ENTSCHEIDUNG_ELEMENT_LIST_ENTSCHEIDUNG_",
          sort_id: "entscheidung__est_kbez",
          sortable: true,
          priority: 30,
        }, {
          id: "content_type_bez",
          label: "_LBL_OBJEKTLISTE_ENTSCHEIDUNG_ELEMENT_LIST_TYP_",
          sort_id: "content_type__name",
          sortable: true,
          hide: true,
          priority: 40,
        }],
        rowActions: [],
        actions: [],
        label: "_LBL_OBJEKTLISTE_ENTSCHEIDUNG_ELEMENT_LIST_AUSZAHLUNGSLISTEN_",
        sortable: true,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        view: {
          filter: "top",
        },
      },
      filters: [{
        id: "ole_objektname",
        type: "text",
        label: "Name",
        main: true,
      }, {
        id: "entscheidung",
        type: "multiselect",
        label: "_LBL_OBJEKTLISTE_ENTSCHEIDUNG_ELEMENT_LIST_ENTSCHEIDUNG_",
        url: "katalog/?fields=pk&fields=est_kbez&key=entscheidungsstatus",
        key_label: "est_kbez",
        key_id: "pk",
        search: true,
        search_list: ["est_kbez"],
      }],
      status: {
        loading: true,
        entscheidung: false
      },
      entscheidungsstatus: undefined,
    };
  },
  created() {
    this.initUrl();
    this.initFilter();
    this.loadEntscheidungen();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { olId: this.modul.data.ol_id } });
    },

    initFilter() {
      const est_filter = find(this.filters, filter => filter.id === "entscheidung");
      if (!est_filter) {
        return;
      }
      est_filter.url = `${ est_filter.url }&gruppe=${ this.modul.regel.argument.entscheidungsstatusgruppe.wert }`;
    },

    loadEntscheidungen() {
      this.getHttp({ url: `katalog/?gruppe=${ this.modul.regel.argument.entscheidungsstatusgruppe.wert }&key=entscheidungsstatus` }).then(
        response => {
          this.entscheidungsstatus = {};

          forEach(sortBy(response, ["pos"]), status => {
            this.entscheidungsstatus[status.pk] = status;
            this.options.rowActions.push({
              label: status.est_aktion_bez,
              callback: ({ item, index }) => this.setEntscheidungsstatus({ item, index, status: status }),
              isHidden: () => !this.checkPermissionsSync({ perm: "objektlistenelement.update", permArray: this.objekt.user_permissions }),
            });
          });
        }
      ).finally(() => this.status.loading = false);
    },

    setEntscheidungsstatus({ item, index, status }) {
      if (this.status.entscheidung) {
        return;
      }
      this.status.entscheidung = true;
      this.postHttp({
        url: `${ this.options.url }${ item.pk }/set_entscheidung/`,
        data: { entscheidung: status.pk },
      }).then(
        response => {
          this.addNotification({
            text: "_MSG_OBJEKTLISTE_ENTSCHEIDUNG_ELEMENTLISTE_SET_SUCCESS_{{bez}}_{{objektname}}_",
            extra: {
              bez: status.bez,
              objektname: item.ole_objektname
            } });
          const ROW = assign({}, item, response);
          EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: ROW, index }));
        },
        () => this.addNotification({ text: "_MSG_OBJEKTLISTE_ENTSCHEIDUNG_ELEMENTLISTE_SET_ERROR_", type: "error" })
      ).then(
        () => this.status.entscheidung = false
      );
    }
  },
};
