import translate from "../../../global/directives/translate";

// @vue/component
export default {
  name: "AntragsdatumText",
  directives: {
    translate,
  },
  props: {
    obj: {
      type: Object,
      required: true,
    },
  },
  computed: {
    translateOptionsDatum() {
      return {
        html: "_HTML_ANTRAGSDATUM_EINGEREICHT_{{antragsdatum}}_",
        extra: {
          antragsdatum: this.obj.a_antragsdatum,
        },
      };
    },

    translateOptionsDatumUpdate() {
      return {
        html: "_HTML_ANTRAGSDATUM_UPDATE_{{upddate}}_",
        extra: {
          upddate: this.obj.upddate,
        },
      };
    },
  },
};
