"use strict";

import BaseModel from "./baseModel";

class KKommtyp extends BaseModel {
  constructor() {
    super("kommunikationstypen");
  }
}

export default KKommtyp;
