"use strict";
import angular from "angular";

import KommunikationsdatenUUIDs from "./kommunikationsdatenUUIDs.service";
import AstFoeorgVerbindungUUIDs from "./ast_foeorg_verbindungUUIDs.service";
import SichtbarkeitStatus from "./sichtbarkeit_status.service";

const modul = angular.module("antragsteller.service", [
])

  .constant("KommunikationsdatenUUIDs", KommunikationsdatenUUIDs)
  .constant("AstFoeorgVerbindungUUIDs", AstFoeorgVerbindungUUIDs)
  .constant("SichtbarkeitStatus", SichtbarkeitStatus)
  .factory("AntragstellerWizardFactory", function() {
    // private
    let value = {};
    // public
    return {
      getValue() {
        return value;
      },
      setValue(arg) {
        value = arg;
      },
    };
  })
;

export default modul;
