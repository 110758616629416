import AbwesenheitenList from "../../../../Nutzer/NutzerDetails/PanelComponents/AbwesenheitenList/AbwesenheitenList.vue";

import PersonalPanelComponentMixin from "../PersonalPanelComponentMixin";

import {
  assign,
} from "lodash";

// @vue/component
export default {
  name: "Allgemein",
  components: {
    AbwesenheitenList,
  },
  mixins: [
    PersonalPanelComponentMixin,
  ],
  computed: {
    options() {
      return assign({}, this.optionsMixin, {
        label: "_LBL_PERSONAL_DETAIL_ABWESENHEITEN_PANEL_",
        editable: false,
      });
    },
  },
};
