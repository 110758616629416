"use strict";

import "restangular";
import angular from "angular";

import FAQrubriken from "./faq.rubriken.service";
import FAQs from "./faq.service";

const modelsModule = angular.module("faq.models", [
  "restangular"
])

  .provider("FAQrubriken", FAQrubriken)
  .provider("FAQs", FAQs)
;

export default modelsModule;
