"use strict";

class DiasLoadingController {
  /*@ngInject*/
  constructor($scope) {
    this.name = "DiasLoadingController";
    $scope.$watch("loading", newValue => {
      if (newValue) {
        $scope.$evalAsync($scope.onBlock);
      } else {
        $scope.$evalAsync(() => {
          if (angular.isFunction($scope.onUnblock)) {
            $scope.onUnblock();
          }
        });
      }
    });
  }
}

export default DiasLoadingController;
