import NotizenIcon from "../../../components/Notizen/NotizenIcon/NotizenIcon.vue";
import Permission from "../Permission/Permission.vue";
import PuxButton from "../PuxButton/PuxButton.vue";
import PuxCloak from "../PuxCloak/PuxCloak.vue";
import PuxIcon from "../PuxIcon/PuxIcon.vue";
import PuxTranslate from "../PuxTranslate/PuxTranslate.vue";
import Spinner from "../Spinner/Spinner.vue";

import {
  scrollToGlobal,
} from "../../functions/help";
import {
  EventBus,
} from "../../functions/event-bus";
import {
  isFunction,
  isNil,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "ContextPanel",
  components: {
    NotizenIcon,
    Permission,
    PuxButton,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
    Spinner,
  },
  props: {
    id: {
      type: String,
      required: true,
      info: "HTML-Attribut 'id'",
    },
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    panelIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
    userPermissions: {
      type: Array,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    icon: {
      type: String,
      required: false,
      default: undefined,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    tag: {
      type: String,
      required: false,
      default: "h3",
      validator: tag => ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(tag) !== -1,
    },
    isNotizenVisible: {
      type: Boolean,
      required: false,
    },
    notizenKey: {
      type: String,
      required: false,
      default: undefined,
    },
    isMarked: {
      type: Boolean,
      required: false,
    },
    isSnapshotIcon: {
      type: Boolean,
      required: false,
    },
    isSnapshotIconActive: {
      type: Boolean,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    openPanel: {
      type: Function,
      required: false,
      default: undefined,
    },
    isButtonsAllVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: [
    "togglePanel",
  ],
  data() {
    return {
      status: {
        active: undefined,
        loading: undefined,
      },
    };
  },
  computed: {
    isLoadingLocal() {
      return this.isLoading || this.status.loading;
    },

    panelIndexLocal() {
      return this.panelIndex || this.options.labelIndex;
    },

    buttonTogglePanelTitle() {
      return this.status.active ? "_BTN_CONTEXT_PANEL_TITLE_CLOSE_" : "_BTN_CONTEXT_PANEL_TITLE_OPEN_";
    },

    idPanelHeader() {
      return `${ this.id }_panel_header`;
    },

    idPanelToggleButton() {
      return `${ this.id }_toggle_button`;
    },

    idToggleButtonAllOpen() {
      return `${ this.id }_toggle_all_open`;
    },

    idToggleButtonAllClose() {
      return `${ this.id }_toggle_all_close`;
    },

    iconButtonTogglePanel() {
      return this.status.active ? "chevron-up" : "chevron-down";
    },

    labelLocal() {
      return this.label || this.options.label;
    },

    iconLocal() {
      return this.icon || this.options.icon;
    },

    notizenKeyLocal() {
      return this.notizenKey || this.options.key;
    },

    notizenButtonId() {
      return `notizen_${ this.notizenKeyLocal }`;
    },

    isNotizenVisibleLocal() {
      return this.isNotizenVisible || this.options.showNotizen;
    },
  },
  created() {
    this.initData();
    this.initEventBuses();
  },
  beforeUnmount() {
    this.destroyEventBuses();
  },
  methods: {
    initData() {
      if (isNil(this.isActive)) {
        this.status.active = isNil(this.options.active) ? undefined : this.options.active;
      } else {
        this.status.active = this.isActive || false;
      }
    },

    initEventBuses() {
      EventBus.$on("toggleContextPanel", this.toggleContextPanel);
      EventBus.$on("scrollToContextPanel", this.scrollToContextPanel);
    },

    destroyEventBuses() {
      EventBus.$off("toggleContextPanel", this.toggleContextPanel);
      EventBus.$off("scrollToContextPanel", this.scrollToContextPanel);
    },

    toggleCard({ statusOpen, withoutScroll, forceScroll } = {}) {
      if (isUndefined(this.status.active) && (isUndefined(statusOpen) || statusOpen)) {
        if (isFunction(this.options.openPanel)) {
          this.status.loading = true;
          this.options.openPanel({ options: this.options }).then(
            () => {
              this.status.loading = false;
              this.toggleCardCurrent({ statusOpen, withoutScroll });
            }
          );
          return;
        } else if (isFunction(this.openPanel)) {
          this.status.loading = true;
          this.toggleCardCurrent({ statusOpen, withoutScroll });
          this.openPanel({ options: this.options }).then(
            () => {
              this.status.loading = false;
            }
          );
          return;
        }
      }

      this.toggleCardCurrent({ statusOpen, withoutScroll, forceScroll });
    },

    toggleCardCurrent({ statusOpen, withoutScroll, forceScroll }) {
      const STATUS_ACTIVE_ALT = this.status.active;
      if (isUndefined(statusOpen)) {
        this.status.active = !this.status.active;
      } else {
        this.status.active = statusOpen;
      }
      if (forceScroll || (!STATUS_ACTIVE_ALT && !withoutScroll)) {
        this.$nextTick(() => scrollToGlobal(`#${ this.id }`));
      }
      this.$emit("togglePanel", { statusOpen: this.status.active, notizenKey: this.notizenKey });
    },

    toggleContextPanel({ statusOpen, id, withoutScroll = false, forceScroll = false } = {}) {
      if (id && this.id !== id) {
        return;
      }
      this.toggleCard({ statusOpen, withoutScroll, forceScroll });
    },

    closeAll() {
      EventBus.$emit("toggleContextPanel", { statusOpen: false, withoutScroll: true });
    },

    openAll() {
      EventBus.$emit("toggleContextPanel", { statusOpen: true, withoutScroll: true });
    },

    scrollToContextPanel({ id }) {
      if (id === this.id) {
        this.toggleCard({ statusOpen: true, forceScroll: true });
      }
    },
  },
};
