import KFStandard from "../../../KFDetail/KFStandard/KFStandard.vue";
import FinanzierugMixin from "../../Mixins/FinanzierugMixin";
import DvvVeranstaltungenMixin from "../DvvVeranstaltungenMixin";

// @vue/component
export default {
  name: "DvvVeranstaltungenEinfach",
  components: {
    KFStandard,
  },
  mixins: [
    FinanzierugMixin,
    DvvVeranstaltungenMixin,
  ],
  data() {
    return {
      allowsInput: true,
    };
  },
  computed: {
    betragsrechner() {
      return this.modelParameter.betragsrechner || "dvv_veranstaltungsrechner_einfach";
    },

    sumLocal() {
      return this.filterCurrency(this.antragKoFiArt.summe);
    },

    statusHideButtonAdd() {
      return this.positionen.length > 0;
    },

    statusHideButtonDelete() {
      return this.kontext === "zuschuss_vertrag";
    },
  },
};
