"use strict";

import angular from "angular";
import "ng-smooth-scroll";

import formStepDetailDirective from "./formstepDetail.directive";
import formStepDirective from "./formstep.directive";


const formStepsModule = angular.module("formsteps", [
  "smoothScroll"
])

  .directive("formstepDetail", formStepDetailDirective)
  .directive("formstep", formStepDirective)
;

export default formStepsModule;
