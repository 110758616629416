import AnsprechpartnerAssignButtonModal from "../../../../../Ansprechpartner/AnsprechpartnerAssignButtonModal/AnsprechpartnerAssignButtonModal.vue";
import AnsprechpartnerAssignMeButtonModal from "../../../../../Ansprechpartner/AnsprechpartnerAssignMeButtonModal/AnsprechpartnerAssignMeButtonModal.vue";
import NachrichtenLink from "../../../../../Nachrichten/NachrichtenLink/NachrichtenLink.vue";
import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../../../global/components/PuxIcon/PuxIcon.vue";

import PermissionMixin from "../../../../../../global/mixins/PermissionMixin";

import KommunikationsdatenUUIDs from "../../../../../../const/KommunikationsdatenUUIDs";
import {
  get,
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragOverviewOneAnsprechpartner",
  components: {
    AnsprechpartnerAssignButtonModal,
    AnsprechpartnerAssignMeButtonModal,
    NachrichtenLink,
    PuxButton,
    PuxIcon,
  },
  mixins: [
    PermissionMixin,
  ],
  props: {
    ansprechpartner: {
      type: Object,
      required: true,
    },
    ansprechpartnerIndex: {
      type: Number,
      required: true,
    },
    vertrag: {
      type: Object,
      required: true,
    },
    canAntragAnsprechpartnerAendern: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    ansprechpartnerName() {
      return get(this.ansprechpartner, "nutzer.name");
    },

    ansprechpartnerType() {
      return get(this.ansprechpartner, "typ.apt_kbez");
    },

    ansprechpartnerEmail() {
      return get(this.ansprechpartner, "nutzer.n_email");
    },

    nachrichtenLinkLabel() {
      return `${ this.ansprechpartnerName } (${ this.ansprechpartnerType })`;
    },

    nachrichtenLinkId() {
      return `nachrichten_link_ansprechpartner_${ this.ansprechpartnerIndex }`;
    },

    ansprechpartnerKommdaten() {
      return get(this.ansprechpartner, "nutzer.kommunikationsdaten", []);
    },

    ansprechpartnerKommdatenExist() {
      return !!(this.ansprechpartnerKommdaten.length);
    },

    ansprechparterOrganisation() {
      return get(this.ansprechpartner, "org.o_name");
    },

    phoneNumbers() {
      return filter(this.ansprechpartner.nutzer.kommunikationsdaten, item => {
        return item.kommtyp && item.kommtyp.kot_id === KommunikationsdatenUUIDs.telefonnr1;
      });
    },

    ansprechpartnerNotCurrentUser() {
      return get(this.ansprechpartner, "nutzer.pk") !== this.me.pk;
    },

    idForButtonMirZuweisen() {
      return `ap_${ this.ansprechpartnerIndex }_mir_zuweisen`;
    },

    idForButtonZuweisen() {
      return `ap_${ this.ansprechpartnerIndex }_zuweisen`;
    },
  },
};
