import PuxPageDetails from "../../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PersonalkostenPeriodenElement from "../PersonalkostenPeriodenElement/PersonalkostenPeriodenElement.vue";
import PersonalkostenPeriodenDurchfuehrungszeitraumLine
  from "../PersonalkostenPeriodenDurchfuehrungszeitraumLine/PersonalkostenPeriodenDurchfuehrungszeitraumLine.vue";
import ContextPanel from "../../../../global/components/ContextPanel/ContextPanel.vue";
import Spaced from "../../../../global/components/Spaced/Spaced.vue";
import moment from "moment";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PersonalkostenPeriodenTableInAntragDetails from "./PersonalkostenPeriodenTableInAntragDetails/PersonalkostenPeriodenTableInAntragDetails.vue";
import PersonalkostenPeriodenTableInAntragTr
  from "./PersonalkostenPeriodenTableInAntragTr/PersonalkostenPeriodenTableInAntragTr.vue";
import {
  FilterCurrencyMixin,
  FilterDateMixin,
} from "../../../../global/mixins/FiltersMixin";

import {
  reduce,
  forEach,
  cloneDeep,
} from "lodash-es";

const NAME = "_Kostenart_Tabelle_";

// @vue/component
export default {
  name: "PersonalkostenPeriodenTableInAntrag",
  components: {
    ContextPanel,
    PersonalkostenPeriodenElement,
    PersonalkostenPeriodenDurchfuehrungszeitraumLine,
    Spaced,
    PuxButton,
    PuxIcon,
    PersonalkostenPeriodenTableInAntragDetails,
    PersonalkostenPeriodenTableInAntragTr,
    PuxPageDetails,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterDateMixin,
  ],
  props: {
    close: {
      type: Function,
      required: true,
    },
    positionen: {
      type: Array,
      required: true,
    },
    perioden: {
      type: Array,
      required: true,
    },
    antragNummer: {
      type: String,
      required: true,
    },
    angebotName: {
      type: String,
      default: undefined,
    },
    personalfunktionen: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      periodenColsWidth: [],
      optionsContextPanel: {
        label: this.$gettext(NAME),
        icon: "glyphicon-table",
        active: true,
      },
      positionenPeriodenList: [],
      statusShowDetails: {},
    };
  },
  computed: {
    getSumFooter() {
      return periodPk => {
        let sum = 0;
        forEach(this.positionenPeriodenList, pos => {
          if (pos[periodPk]) {
            forEach(pos[periodPk], period => {
              sum += +period.gehalt;
            });
          }
        });
        return sum;
      };
    },

    getSumAll() {
      return reduce(this.positionen, (sum, pos) => {
        return sum + +pos.akp_wert;
      }, 0);
    },
  },
  created() {
    this.initColsWidth();
    this.preparePositionDatenForPerioden();
  },
  methods: {
    initColsWidth() {
      const PERIODEN_DAYS = [];
      const COUNT_ALL_DAYS = reduce(this.perioden, (sum, period) => {
        const DAYS_DIFF = moment(period.bis_max).diff(moment(period.von_min), "days");
        PERIODEN_DAYS.push(DAYS_DIFF);
        return sum + DAYS_DIFF;
      }, 0);
      const PERIODEN_COLS_WIDTH = [];
      forEach(this.perioden, (period, periodIndex) => {
        const WIDTH = `width: ${ PERIODEN_DAYS[periodIndex] * 100 / COUNT_ALL_DAYS }%`;
        PERIODEN_COLS_WIDTH.push(WIDTH);
      });

      this.periodenColsWidth = PERIODEN_COLS_WIDTH;
    },

    preparePositionDatenForPerioden() {
      const POSITIONEN_PERIODEN_LIST = [];
      forEach(this.positionen, position => {
        const PERIODEN_OBJ = {};
        forEach(position.argument.perioden, period => {
          if (!PERIODEN_OBJ[period.periode]) {
            PERIODEN_OBJ[period.periode] = [];
          }
          PERIODEN_OBJ[period.periode].push(cloneDeep(period));
        });
        POSITIONEN_PERIODEN_LIST.push(PERIODEN_OBJ);
      });
      this.positionenPeriodenList = POSITIONEN_PERIODEN_LIST;
    },

    onClose() {
      this.close();
    },

    toggleDetails(positionIndex) {
      this.statusShowDetails[positionIndex] = !this.statusShowDetails[positionIndex];
    },
  },
};
