"use strict";

// import diasLoading from "dias/core/loading";

import documentDownloadComponent from "./documentdownload.component.js";

const documentDownload = angular.module("dias.documentdownload", [
  // diasLoading.name
])

  .component("documentDownload", documentDownloadComponent);

export default documentDownload;
