import ObjectErrorRubric from "./ObjectErrorRubric/ObjectErrorRubric.vue";
import PuxAlert from "../../../global/components/PuxAlert/PuxAlert.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "ObjectValidation",
  components: {
    ObjectErrorRubric,
    PuxAlert,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    loadingMsg: {
      type: String,
      required: false,
      default: undefined,
    },
    errors: {
      type: Array,
      required: false,
      default: undefined,
    },
    textSuccess: {
      type: String,
      required: true,
    },
    textError: {
      type: String,
      required: true,
    },
    rubricsByAnzeigekontext: {
      type: Object,
      required: true,
    },
    goToModule: {
      type: Function,
      required: false,
      default: undefined,
      info: "Soll ein Promise sein"
    },
  },
  computed: {
    isVisibleAlert() {
      return !!(this.loading === false && this.errors);
    },

    alertType() {
      return this.errors && this.errors.length > 0 ?
        "danger" :
        "success";
    },
  },
};
