import AdressenModalCreateOrEdit from "./AdressenModalCreateOrEdit/AdressenModalCreateOrEdit.vue";
import AdressenTr from "./AdressenTr/AdressenTr.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

import ModuleMixin from "../ModuleMixin";
import UiAdressenMixin from "./UiAdressen/UiAdressenMixin";

import {
  cloneDeep,
  filter,
  findIndex,
  forEach,
  get,
  isFunction,
  isEqual,
  keyBy,
  values,
} from "lodash-es";

// @vue/component
export default {
  name: "Adressen",
  components: {
    AdressenModalCreateOrEdit,
    AdressenTr,
    Modal,
    PuxButton,
    SnapshotIcon,
  },
  mixins: [
    ModuleMixin,
    UiAdressenMixin,
  ],
  data() {
    return {
      addressListDefault: [
        {
          id: "typ",
          type: "select",
          label: "_TXT_ADRESSEN_PARAMETER_TYP_",
          keyId: "pk",
          keyLabel: "adt_kbez",
          view: "v",
          required: true,
          data: [],
          disabled: undefined,
          translate: true,
        },
      ],
      addressList: [],
      laenderoptionen: [],
      currentAddress: undefined,
      selectorClose: undefined,
      statusReady: undefined,
      statusModalCreateOrUpdate: undefined,
      statusModalDelete: undefined,

      confirmDeleteOptions: undefined,
      defaultTyp: undefined,
      plzCenterCoordinates: {},
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      return OPTIONS;
    },

    addressMapDefault() {
      return {
        bundesland: {
          type: "select",
          url: "katalog/?key=kbundesland",
          keyId: "pk",
          keyLabel: "bun_bez",
          view: "v",
          apiSaveId: "kbundesland",
          deselect: true,
        },
        land: {
          type: "select",
          keyId: "pk",
          keyLabel: "lkz_bez",
          search: true,
          searchList: ["lkz_bez"],
          view: "v",
          deselect: true,
          data: [],
        },
        kontinent: {
          type: "select",
          url: "katalog/?key=kkontinent",
          keyId: "pk",
          keyLabel: "kont_bez",
          search: true,
          searchList: ["kont_bez"],
          view: "v",
          apiSaveId: "kkontinent",
          deselect: true,
        },
        adr_geokoord: {
          type: "coordinates",
          labelTable: "Geokoordinaten",
          view: "v-alt",
          center: undefined,
        },
        adr_bemerkung: {
          type: "richtext",
          labelTable: "Bemerkung",
          required: true,
          view: "v-alt",
          translate: true,
        },
      };
    },

    statusShowOpenButton() {
      if (this.modelParameter.max_adressen) {
        const MODEL_LENGTH = this.model && this.model.adresse.length ? this.model.adresse.length : 0;

        return MODEL_LENGTH < this.modelParameter.max_adressen && !this.statusReadonly;
      }
      return !this.statusReadonly;
    },

    defaultPlz() {
      if (this.obj &&
        this.obj.antragstellerorg_obj &&
        this.obj.antragstellerorg_obj.ast_plz) {
        return this.obj.antragstellerorg_obj.ast_plz;
      }
      return undefined;
    },

    statusShowCoordinates() {
      return get(this.modelParameter, "felder.adr_geokoord.show", false);
    },

    statusShowKontinent() {
      return get(this.modelParameter, "felder.kontinent.show", false);
    },

    statusShowAdresstypen() {
      return this.modelParameter.adresstypen_anzeigen || false;
    },

    snapshotModuleObj() {
      if (this.statusSnapshot &&
        this.snapshotModule.adresse &&
        this.snapshotModule.adresse.length) {
        return keyBy(this.snapshotModule.adresse, "pk");
      }
      return {};
    },

    snapshotAdressenFiltered() {
      const SNAPSHOT_ADRESSEN = {
        add: {},
        delete: [],
        changes: {},
        status: false,
      };
      if (this.statusSnapshot) {
        const SNAPSHOT_MODULE_OBJ = cloneDeep(this.snapshotModuleObj);
        if (this.model.adresse && this.model.adresse.length) {
          forEach(this.model.adresse, item => {
            if (!SNAPSHOT_MODULE_OBJ[item.pk]) {
              SNAPSHOT_ADRESSEN.add[item.pk] = true;
              SNAPSHOT_ADRESSEN.status = true;
            } else {
              if (!isEqual(item, SNAPSHOT_MODULE_OBJ[item.pk])) {
                SNAPSHOT_ADRESSEN.changes[item.pk] = cloneDeep(SNAPSHOT_MODULE_OBJ[item.pk]);
                SNAPSHOT_ADRESSEN.status = true;
              }
              delete SNAPSHOT_MODULE_OBJ[item.pk];
            }
          });
        }
        SNAPSHOT_ADRESSEN.delete = values(SNAPSHOT_MODULE_OBJ);
        if (SNAPSHOT_ADRESSEN.delete.length) {
          SNAPSHOT_ADRESSEN.status = true;
        }
      }
      return SNAPSHOT_ADRESSEN;
    },

    statusDiff() {
      return this.snapshotAdressenFiltered.status;
    },

    statusNoData() {
      return !(this.snapshotAdressenFiltered.delete.length || (this.model.adresse && this.model.adresse.length));
    },

    isLandVisible() {
      return get(this.modelParameter, "felder.land.show", false);
    },

    isModuleEditable() {
      return false;
    },
  },
  methods: {
    initDataLocal({ statusFirstLoad }) {
      if (statusFirstLoad) {
        this.initAdressOptions();
        this.initDataForTypes();
        this.loadCountries();
      }
    },

    initAdressOptions() {
      const NEW_LIST = this.statusShowAdresstypen ? cloneDeep(this.addressListDefault) : [];
      const PARAMETER_FELDER = this.modelParameter.felder || {};
      forEach(this.fields, item => {
        const KEY = item.key;
        const CURRENT_PARAMETER = PARAMETER_FELDER[KEY];
        if (CURRENT_PARAMETER && CURRENT_PARAMETER.show) {
          NEW_LIST.push(this.setOptionsForFeld({ feld: CURRENT_PARAMETER, key: KEY }));
        }
      });
      this.addressList = NEW_LIST;
      if (this.modelParameter.laenderoptionen) {
        this.laenderoptionen = this.modelParameter.laenderoptionen;
      }
    },

    setOptionsForFeld({ feld, key }) {
      const OPTIONS_DEFAULT = this.addressMapDefault[key];
      let options = {};
      if (OPTIONS_DEFAULT) {
        options = cloneDeep(OPTIONS_DEFAULT);
      } else {
        options.type = "text";
        options.view = "v";
      }
      options.id = key;
      options.required = feld.required || false;
      options.htmlId = `${ this.htmlRegelNummer }_${ key }`;
      options.label = feld.label_editonly;
      options.labelTable = feld.label_readonly;
      return options;
    },

    initDataForTypes() {
      this.getListHttp({
        url: `katalog/?key=adresstyp`,
        apiSaveId: "adresstyp",
        keyId: "pk",
      }).then(
        response => {
          this.setDataForType({ response });
          this.statusReady = true;
        }
      );
    },

    setDataForType({ response }) {
      const DATA = [];
      forEach(this.modelParameter.adresstypen, type => {
        DATA.push(cloneDeep(response[type]));
      });
      if (this.statusShowAdresstypen) {
        const INDEX_TYPE = findIndex(this.addressList, ["id", "typ"]);
        this.addressList[INDEX_TYPE].data = DATA;
        if (DATA.length === 1) {
          this.addressList[INDEX_TYPE].disabled = true;
          this.defaultTyp = DATA[0].pk;
        }
      } else if (DATA.length === 1) {
        this.defaultTyp = DATA[0].pk;
      }
    },

    loadCountries() {
      if (!this.isLandVisible) {
        return;
      }
      this.getHttp({
        url: "katalog/",
        urlParams: {
          key: "klaenderkuerzel",
        },
      }).then(
        response => {
          const COUNTRIES = this.filterCountries(response);
          this.addCountriesToAdressList(COUNTRIES);
        }
      );
    },

    filterCountries(response) {
      if (!this.laenderoptionen.length) {
        return response;
      }
      return filter(response, country => {
        return this.laenderoptionen.indexOf(country.pk) !== -1;
      });
    },

    addCountriesToAdressList(countries) {
      const COUNTRY_INDEX = findIndex(this.addressList, ["id", "land"]);
      const COUNTRY_OPTIONS = cloneDeep(this.addressList[COUNTRY_INDEX]);
      COUNTRY_OPTIONS.data = countries;
      this.addressList.splice(COUNTRY_INDEX, 1, COUNTRY_OPTIONS);
    },

    confirmDeleteAddress({ address }) {
      this.selectorClose = [
        `#${ this.htmlRegelNummer }_delete_${ address.pk }`,
        `#${ this.htmlRegelNummer }`
      ];
      this.confirmDeleteOptions = {
        okClass: "btn btn-primary",
        title: `_HTML_ADDRESSE_MODAL_DELETE_HEADER_{{name}}_`,
        msg: "_HTML_ADDRESSE_MODAL_DELETE_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteAddress({ address }),
        cancelCallback: this.closeModalDelete,
        loading: false,
        extra: {
          name: address.adr_name,
        },
      };
      this.statusModalDelete = true;
    },

    deleteAddress({ address }) {
      this.confirmDeleteOptions.loading = true;
      const DATA = {
        loeschen: true,
        adresse: cloneDeep(address),
      };
      if (DATA.adresse.weitere_objekte) {
        delete DATA.adresse.weitere_objekte;
      }
      if (isFunction(this.childSave)) {
        return new Promise((resolve, reject) => {
          this.childSave({ data: DATA, regel: this.module.regel.regel }).then(
            response => {
              this.statusModalDelete = false;
              this.onUpdateFunctions({ response });
              this.addNotification({ text: `_MSG_ADDRESSE_DELETE_SUCCESS_` });
              resolve(response);
            },
            errors => {
              reject(this.onError({ error: errors.data }));
              this.addNotification({ text: "_MSG_ADDRESSE_DELETE_ERROR_", type: "error" });
            }
          );
        }).then(
          () => this.confirmDeleteOptions.loading = false
        );
      }
      this.putHttp({
        url: `${ this.baseUrl }module/${ this.regel.pk }/`,
        data: DATA,
      }).then(
        response => {
          this.statusModalDelete = false;
          this.onUpdateFunctions({ response });
          this.addNotification({ text: `_MSG_ADDRESSE_DELETE_SUCCESS_` });
          this.deleteErrors();
        },
        error => {
          this.onError({ error: error.data });
          this.addNotification({ text: "_MSG_ADDRESSE_DELETE_ERROR_", type: "error" });
        }
      ).then(
        () => this.confirmDeleteOptions.loading = false
      );
    },

    onUpdateFunctions({ response }) {
      if (isFunction(this.savecallback)) {
        this.savecallback();
      }
      this.updateModuleLocal({ response, extra: this.extra, group: this.group });
      this.updateSnapshotDiffLocal();
      this.sendSignal();
      setTimeout(() => {
        this.initData();
      });
    },

    closeModalDelete() {
      this.statusModalDelete = false;
    },

    openModalCreateOrEdit({ address } = {}) {
      if (address) {
        this.currentAddress = address;
        this.selectorClose = `#${ this.htmlRegelNummer }_edit_${ address.pk }`;
      } else {
        this.selectorClose = `#${ this.htmlRegelNummer }_add`;
      }
      this.statusModalCreateOrUpdate = true;
    },

    closeModalCreateOrUpdate({ response } = {}) {
      if (response) {
        this.onUpdateFunctions({ response });
        this.deleteErrors();
      }
      this.currentAddress = undefined;
      this.statusModalCreateOrUpdate = false;
    },

    setPlzCenterCoordinates({ plz, coordinates }) {
      this.plzCenterCoordinates[plz] = cloneDeep(coordinates);
    },

    getOptionsForReadonly(options) {
      if (options.id === "typ") {
        const OPTIONS = cloneDeep(options);
        OPTIONS.url = "katalog/?key=adresstyp";
        return OPTIONS;
      }
      return options;
    },
  },
};
