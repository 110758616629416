import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import PermissionAPI from "../../../../../global/compositionAPI/PermissionAPI";

// @vue/component
export default {
  name: "AufgabenvorlagenDetailsBerechtigungenTableHeader",
  components: {
    AngularLink,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    kAufgabe: {
      type: Object,
      required: true,
    },
    infoTexts: {
      type: Object,
      required: true,
    },
    rollenChoices: {
      type: Array,
      default: () => [],
    },
    rollen: {
      type: Array,
      default: () => [],
    },
    selectedRolle: {
      type: Object,
      default: () => {},
    },
    loadingRolle: {
      type: Object,
      required: true,
    },
    hasPermissionRollenUi: {
      type: Boolean,
      required: false,
      default: false,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    formstepHtmlId: {
      type: String,
      required: true,
    },
  },
  emits: [
    "addRolle",
    "loadSelectedRolle",
    "removeRolle",
  ],
  setup() {
    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      checkPermissionsSync,
    };
  },
  data() {
    return {
      htmlId: "aufgabenvorlagen_details_berechtigungen_table_header",
      beobachterRolleModel: undefined,
      bearbeiterRolleModel: undefined,
      rollenModel: {
        bearbeiter: undefined,
        beobachter: undefined,
      },
      removeRollePk: undefined,
      showModalConfirmRemoveRolle: false,
      confirmOptions: false,
    };
  },
  computed: {
    beobachterRollenOptions() {
      const ID = "beobachter_rollen";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        loading: this.loadingRolle.beobachter,
        label: "_TXT_AUFGABENVORLAGENDETAILS_TABLE_COLUMN_SUFFIX_ROLLEN_",
        view: "v",
        type: "select",
        keyId: "pk",
        keyLabel: "kbez",
        search: true,
        searchList: ["bez", "kbez"],
        data: this.rollenChoices,
        deselect: true,
        change: () => this.changeRolle({ type: "beobachter" }),
      };
    },

    bearbeiterRollenOptions() {
      const ID = "bearbeiter_rollen";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        loading: this.loadingRolle.bearbeiter,
        label: "_TXT_AUFGABENVORLAGENDETAILS_TABLE_COLUMN_SUFFIX_ROLLEN_",
        view: "v",
        type: "select",
        keyId: "pk",
        keyLabel: "kbez",
        search: true,
        searchList: ["bez", "kbez"],
        data: this.rollenChoices,
        deselect: true,
        change: () => this.changeRolle({ type: "bearbeiter" }),
      };
    },

    addBearbeiterRolleDisabled() {
      return !this.rollenModel.bearbeiter || this.loadingRolle.bearbeiter;
    },

    selectorClose() {
      return [
        `#button_remove_rolle_${ this.removeRollePk }`,
        `#${ this.formstepHtmlId }`
      ];
    },
  },
  methods: {
    changeRolle({ type: type }) {
      this.$emit("loadSelectedRolle", {
        pk: this.rollenModel[type],
        type: type,
        editMode: this.editMode,
      });
    },

    addRolle() {
      const ROLLE_PK = this.rollenModel.bearbeiter;
      this.rollenModel.bearbeiter = undefined;
      if (this.rollenModel.beobachter === ROLLE_PK) {
        this.rollenModel.beobachter = undefined;
      }
      this.$emit("addRolle", { rollePk: ROLLE_PK });
    },

    openModalConfirmRemove({ rolle }) {
      this.removeRollePk = rolle.pk;
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_AUFGABENVORLAGENDETAILS_CONFIRM_REMOVE_ROLLE_{{kbez}}_",
        msg: "_MSG_AUFGABENVORLAGENDETAILS_CONFIRM_REMOVE_ROLLE_{{kbez}}_{{bez}}_{{au_kbez}}_",
        extra: {
          au_kbez: this.kAufgabe.au_kbez,
          kbez: rolle.kbez,
          bez: rolle.bez,
        },
        okLabel: "_TXT_AUFGABENVORLAGENDETAILS_BUTTON_REMOVE_",
        okCallback: () => this.removeRolle({ rollePk: rolle.pk }),
        cancelCallback: this.closeModalConfirmRemove,
        loading: false,
      };
      this.showModalConfirmRemoveRolle = true;
    },

    closeModalConfirmRemove() {
      this.showModalConfirmRemoveRolle = false;
    },

    removeRolle({ rollePk }) {
      this.$emit("removeRolle", { rollePk: rollePk });
      this.closeModalConfirmRemove();
    },
  },
};
