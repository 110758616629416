"use strict";

import BaseController from "../config.base.controller";

class ParametermoduleMultiSelectController extends BaseController {
  /*@ngInject*/
  constructor() {
    super();
    this.choices = [];
    this.selection = {}; // Das Binding für Checkboxen
    this.requiredSelection = {};
    if (this.parameter[this.key].wert === undefined) {
      this.parameter[this.key].wert = [];
    }
    this.initChoices();
    this.initSelection();
  }

  initChoices() {
    this.choices = [];
    angular.forEach(this.parameter[this.key].choices, choise => {
      this.choices.push({ id: choise[0], bez: choise[1] });
    });
  }

  initSelection() {
    angular.forEach(this.parameter[this.key].wert, wert => {
      if (angular.isObject(wert)) {
        angular.forEach(wert, (w, wKey) => {
          this.selection[wKey] = true;
          if (w.required === true) {
            this.requiredSelection[wKey] = true;
          }
        });
      } else {
        this.selection[wert] = true;
      }
    });
  }

  updateSelection(choicePk) {
    if (choicePk && !this.selection[choicePk] && this.requiredSelection[choicePk]) {
      delete this.requiredSelection[choicePk];
    }
    this.parameter[this.key].wert = [];
    angular.forEach(this.selection, (selected, pk) => {
      if (selected === true) {
        this.parameter[this.key].wert.push({
          typ: "json",
          wert: {
            [pk]: { required: !!this.requiredSelection[pk] },
          } });
      }
    });
  }

  showValue(wert) {
    let value = "";
    let key = "";
    let requared = false;
    if (angular.isObject(wert)) {
      angular.forEach(wert, (w, wKey) => {
        key = wKey;
        requared = w.required;
      });
    } else {
      key = wert;
    }
    for (let i = 0; i < this.choices.length; i++) {
      if (this.choices[i].id === key) {
        value = this.choices[i].bez;
        break;
      }
    }
    return `${ value }, ${ requared ? "Pflicht" : "nicht Pflicht" }`;
  }
}

export default ParametermoduleMultiSelectController;
