"use strict";

import {
  initModelFromList,
  toFormElementFromParameter,
} from "../../../../vue/client/vue/global/functions/mappingForParameterToFormElement";
import {
  cloneDeep,
  differenceWith,
  forEach,
  isEqual,
  isNil,
} from "lodash-es";


class SitzungenEditController {
  /*@ngInject*/
  constructor($q, $scope, ParameterUtils, Sitzungsarten, DiasNotification, Katalog, $timeout) {
    this.$timeout = $timeout;
    this.DiasNotification = DiasNotification;
    this.ParameterUtils = ParameterUtils;
    this.Katalog = Katalog;
    this.Sitzungsarten = Sitzungsarten;

    this.sia_id = $scope.extras.sia_id;
    this.tos_id = $scope.extras.tos_id;
    this.to_id = $scope.extras.to_id;
    this.ssm_id = $scope.extras.ssm_id;
    this.errors = {};
    this.loadSitzungsmodulkatalog();
    this.api = this.Sitzungsarten.one(this.sia_id).tops.one(this.tos_id).module;
    this.model = this.Sitzungsarten.merge({}, $scope.extras.tagesordnungsmodul || {});
    if (this.model.tom_parameterin) {
      this.prepareModel(this.model.tom_parameterin);
    }

    this.save = () => {
      const defer = $q.defer();
      forEach(this.parameter, (v, k) => {
        v.wert = this.vueModel[k];
      });
      const data = Object.assign({}, this.model, {
        parameter: this.ParameterUtils.to_request_body(this.parameter),
        top: this.to_id,
        modul: this.ssm_id
      });
      const promise = this.api.one("validate").customPOST(data);
      promise.then(
        () => {
          defer.resolve(Object.assign({}, this.model, {
            modul: this.ssm_id,
            tom_parameterin: this.parameter,
            parameter: data.parameter }));
        },
        error => {
          defer.reject();
          this.onError(error);
        }
      );
      return defer.promise;
    };

    this.onError = error => {
      let errorsLabel = {
        tom_kbez: "Name: ",
        modul: "Modul: ",
      };
      errorsLabel = Object.assign(errorsLabel, this.ParameterUtils.getErrorLabels(this.parameter || {}));
      this.DiasNotification.form.clear();
      this.DiasNotification.form.error(
        error.data,
        "Bitte überprüfen Sie Ihre Eingaben",
        {},
        errorsLabel
      );
      this.errors = error.data;
    };

    this.vueChange = this.vueChange.bind(this);
  }

  loadSitzungsmodulkatalog() {
    this.loadingModule = true;
    this.Katalog.getKatalog("sitzungsmodule").then(
      response => {
        this.sitzungsmodulkatalog = response;
        this.onSelectSitzungsmodul();
      },
      err => this.DiasNotification.page.error(err, "Fehler beim Laden der auswählbaren Sitzungsmodule")
    ).finally(() => this.loadingModule = false);
  }

  onSelectSitzungsmodul() {
    this.ssm = this.sitzungsmodulkatalog.getById(this.ssm_id);
    if (!this.ssm) {
      return;
    }
    const diff = differenceWith(this.paramKeys, this.ParameterUtils.get_ordered_keys(this.ssm.ssm_parameterin), isEqual);
    if (!this.parameter || diff.length > 0) {
      this.prepareModel(this.ssm.ssm_parameterin);
    }
  }

  prepareModel(parameter) {
    this.paramKeys = this.ParameterUtils.get_ordered_keys(parameter);
    this.parameter = this.ParameterUtils.init_parameter_in(parameter);
    this.vueParameterList = toFormElementFromParameter({
      obj: parameter,
      showLabel: true,
      staticOptions: {
        translate: true,
      },
      staticOptionsForType: {
        multiselect: {
          selectAll: true,
          deselectAll: true,
        }
      }
    }).list;
    this.vueModel = initModelFromList({ list: this.vueParameterList });
    forEach(parameter, (v, k) => {
      if (!isNil(v.wert)) {
        this.vueModel[k] = v.wert;
      }
    });
  }

  vueChange({ id, model }) {
    this.$timeout(() => {
      this.vueModel[id] = cloneDeep(model);
    });
  }
}

export default SitzungenEditController;
