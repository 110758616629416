"use strict";

import template from "./template.jade";

class FerienBetragsrechnerController {
  /*@ngInject*/
  constructor(i18n) {
    this.gettext = i18n.gettext;
    if (!this.kontext) {
      this.kontext = "antrag";
    }
    this.setPauschale();

    let belegVorbelegen = false;
    if (this.extras.antragsregel.afr_argument.beleg_vorbelegen) {
      belegVorbelegen = this.extras.antragsregel.afr_argument.beleg_vorbelegen.wert;
    }

    if (this.kontext == "beleg" && belegVorbelegen && !this.posmodel.pk) {
      // Belegfelder vorbelegen beim Erstellen
      angular.forEach(this.posmodel.argument, (value, key) => {
        this.posmodel.argument[key] = this.posmodel.argument[key] || this.extras.argument[key];
      });
    }
  }

  setPauschale() {
    let tagespauschale = this.extras.argument.tagespauschale;
    if (!tagespauschale) {
      tagespauschale = this.extras.antragsregel.afr_argument.pauschale.wert;
    }
    if (this.posmodel) {
      this.posmodel.argument.tagespauschale = tagespauschale;
      this.extras.tagespauschale = tagespauschale;
    }
  }
}

export default {
  template: template(),
  controller: FerienBetragsrechnerController,
  controllerAs: "ctx",
  bindings: {
    rechner: "=",
    posmodel: "=",
    errors: "=",
    extras: "=",
    kontext: "=?",
    vorschau: "=",
    prefix: "<?"
  },
};
