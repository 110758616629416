import FormstepDetail from "../../../global/components/FormstepDetail/FormstepDetail.vue";
import FormElement from "../../../global/components/FormElement/FormElement.vue";
import translate from "../../../global/directives/translate";
import HttpMixin from "../../../global/mixins/HttpMixin";
import {
  toFormElementFromParameter,
  initModelFromList,
} from "../../../global/functions/mappingForParameterToFormElement";
import {
  EventBus,
} from "../../../global/functions/event-bus";
import {
  cloneDeep,
  forEach,
  get,
  isFunction,
  isNull,
  isUndefined,
  map,
  size,
  uniq,
} from "lodash-es";
import { createNamespacedHelpers } from "vuex";
import SNAPSHOT_MODES from "../../Snapshot/SnapshotModule/modes";
const {
  mapMutations, mapGetters,
} = createNamespacedHelpers("snapshot");

// @vue/component
export default {
  components: {
    FormstepDetail,
    FormElement,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    baseUrl: {
      type: String,
      required: false,
      default: undefined,
    },
    module: {
      type: Object,
      required: true,
    },
    updateModule: {
      type: Function,
      required: false,
      default: undefined,
    },
    obj: {
      type: Object,
      required: false,
      default: undefined,
    },
    readonly: {
      type: Boolean,
    },
    hasBaseEditPerm: {
      type: Boolean,
    },
    kontext: {
      type: String,
      default: undefined,
    },
    group: {
      type: [String, Number],
      default: undefined,
    },
    idPrefix: {
      type: String,
      default: () => ""
    },
    idx: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    saveCallback: {
      type: Function,
      default: undefined,
    },
    infoprovider: {
      type: Object, // TODO: ILIA ???
      default: undefined,
    },
    snapshot: {
      type: Object,
      default: undefined,
    },
    snapshotMode: {
      type: String,
      default: "snapshot",
      validator: value => SNAPSHOT_MODES.indexOf(value) !== -1,
    },
    astdiffstatsus: {
      type: Boolean,
    },
    notizenKey: {
      type: String,
      required: false,
      default: undefined,
    },
    extra: {
      type: Object,
      default: undefined,
    },
    updateSnapshotDiff: {
      type: Function,
      default: undefined,
    },
    anzeigekontext: {
      type: String,
      required: false,
      default: undefined,
    },
    childSnapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
    childSnapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    childSuffix: {
      type: String,
      required: false,
      default: "",
    },
    parentsRegelId: {
      type: String,
      required: false,
      default: "",
    },
    childSave: {
      type: Function,
      required: false,
      default: undefined,
    },
    statusExample: {
      type: Boolean,
      required: false,
    },
    dokumenteBaseUrl: {
      type: String,
      required: false,
      default: undefined,
    },
    extraStatic: {
      type: Object,
      required: false,
      default: () => ({}),
      info: "Objekt mit Daten, die sich nicht ändern",
    },
  },
  data() {
    return {
      parameterObj: {},
      parameterList: [],
      modelParameter: {},
      model: {},
      modelEdit: undefined, // Wenn man "slot:edit" benutzt
      readonlyLocal: false,
      errors: undefined,
      statusReloading: undefined,
      timerSignal: undefined,
      eventsMapping: {},
    };
  },
  computed: {
    regel() {
      return this.module.regel || {};
    },

    regelNummer() {
      return this.regel.regel_nummer;
    },

    statusSaveCallback() {
      return this.regel.savecallback;
    },

    htmlRegelNummer() {
      // return `regel_${ this.regel.regel_nummer }${ this.childSuffix }`; // TODO: Ilia
      const HTML_REGEL_NUMMER = `${ this.regel.regel_nummer }${ this.childSuffix }`;
      return HTML_REGEL_NUMMER.replace(/[^\w\d_-]/g, "");
    },

    selectorHtmlRegelNummer() {
      return `#${ this.htmlRegelNummer }`;
    },

    signalEmpfang() {
      if (this.childSuffix) { // Sie sind im parent z.B. 'Elementliste'
        return [];
      }
      return this.regel.signal_empfang || [];
    },

    signalVersand() {
      if (this.childSuffix) { // Sie sind im parent z.B. 'Elementliste'
        return [];
      }
      return this.regel.signal_versand || [];
    },

    signalVersandKennungen() {
      return uniq(map(this.signalVersand, signal => `${ signal.signal_kennung }_${ this.regel.regel }`));
    },

    unterregeln() {
      return this.regel.unterregeln || [];
    },

    signalVersandUnterregelnKennungen() {
      const UREGEL_MAP = {};
      forEach(this.unterregeln, unterregel => {
        UREGEL_MAP[unterregel.regel] = uniq(map(unterregel.signal_versand, signal => `${ signal.signal_kennung }_${ unterregel.regel }`));
      });
      return UREGEL_MAP;
    },

    optionsFormstepDetail() {
      return {
        info: this.infoIText,
        required: true,
        statusNotCloseEditModeIfModelChange: true,
        saveCallback: this.save,
        closeCallback: this.close,
        hideHeader: false,
      };
    },

    infoIText() {
      return this.module.regel[`${ this.modulePrefix }_astbez`];
    },

    hasModulPermissionUpdate() {
      if (this.readonlyLocal || this.readonly) {
        return false;
      }
      return get(this.regel, "rechte.update", this.hasBaseEditPermLocal);
    },

    hasBaseEditPermLocal() {
      return this.hasBaseEditPerm === undefined ? !(this.readonlyLocal || this.readonly) : this.hasBaseEditPerm;
    },

    statusReadonly() {
      return !this.hasModulPermissionUpdate;
    },

    statusSnapshot() {
      return !!this.childSnapshot || !!this.snapshot;
    },

    snapshotDate() {
      if (this.childSnapshotDate) {
        return this.childSnapshotDate;
      }
      if (this.statusSnapshot) {
        return this.snapshot.asn_snapshot_datum || this.snapshot.ats_snapshot_datum || this.snapshot.osn_snapshot_datum;
      }
      return undefined;
    },

    snapshotModule() {
      if (this.childSnapshot) {
        return this.childSnapshot;
      }
      if (!this.statusSnapshot || !this.anzeigekontextLocal) {
        return undefined;
      }
      const SNAPSHOT = this.snapshot.asn_snapshot || this.snapshot.ats_snapshot || this.snapshot.data;
      if (SNAPSHOT[this.anzeigekontextLocal] &&
        SNAPSHOT[this.anzeigekontextLocal][this.module.modulname] &&
        SNAPSHOT[this.anzeigekontextLocal][this.module.modulname][this.module.regel.pk]) {
        return SNAPSHOT[this.anzeigekontextLocal][this.module.modulname][this.module.regel.pk];
      }
      return undefined;
    },

    anzeigekontextLocal() {
      return this.anzeigekontext || this.regel.anzeigekontext;
    },

    moduleUrl() {
      if (this.statusExample) {
        return this.baseUrl;
      }
      return `${ this.baseUrl }module/${ this.regel.pk }/`;
    },

    modulePrefix() {
      const PREFIXES = {};
      let prefix = "";
      for (const key in this.regel) {
        if (key.indexOf("_") !== -1) {
          const TMP = key.split("_", 1)[0];
          prefix = TMP;
          PREFIXES[TMP] = isUndefined(PREFIXES[TMP]) ? 0 : PREFIXES[TMP] + 1;
        }
      }
      for (const pref in PREFIXES) {
        if (PREFIXES[prefix] < PREFIXES[pref]) {
          prefix = pref;
        }
      }
      return prefix;
    },

    labelHeader() {
      return this.modelParameter.frage_text;
    },

    notizenOptions() {
      if (!this.notizenKey) {
        return;
      }
      return {
        label: this.labelHeader,
        key: this.notizenKey,
        objektid: this.regel.pk,
        content_type: this.regel.content_type,
        user_permissions: this.obj.user_permissions,
      };
    },

    regelId() {
      return `${ this.idPrefix }${ this.regel.regel }${ this.childSuffix }`;
    },

    signalArguments() {
      return {
        regel: this.regel,
      };
    },

    statusDiff() {
      return undefined;
    },

    isModuleEditable() {
      return !this.statusReadonly;
    },

    regelIdWithParentsRegelId() {
      return `${ this.parentsRegelId }${ this.parentsRegelId ? "_" : "" }${ this.regel.regel }${ this.childSuffix }`;
    },

    ...mapGetters([
      "GET_STATUS_DIFF_FOR_REGEL",
    ]),
  },
  watch: {
    statusDiff: {
      immediate: true,
      handler(newVal) {
        this.MUT_SET_STATUS_DIFF_FOR_REGEL({
          regelId: this.regelId,
          statusDiff: newVal,
        });
      },
    },

    "module.data"() {
      this.prepareModel();
    },

    regel() {
      this.prepareParameter();
    },
  },
  created() {
    this.initEventBusses();
    this.initData({ statusFirstLoad: true });
  },
  beforeUnmount() {
    this.destroyEventBusses();
    this.registerOrDeactiveSignalReceiver({ eventBusAction: "$off" });
    this.MUT_DELETE_STATUS_DIFF_FOR_REGEL({ regelId: this.regelId });
  },
  methods: {
    initEventBusses() {
      EventBus.$on("showErrorsInModule", this.showErrorsInModuleGlobal);
    },

    destroyEventBusses() {
      EventBus.$off("showErrorsInModule", this.showErrorsInModuleGlobal);
    },

    initData({ statusFirstLoad, statusUpdate } = {}) {
      if (statusFirstLoad || statusUpdate) {
        this.prepareParameter();
      }
      this.prepareModel();
      if (statusFirstLoad) {
        this.registerOrDeactiveSignalReceiver({ eventBusAction: "$on" });
      }
      this.initDataLocal({ statusFirstLoad, statusUpdate });
    },

    prepareParameter() {
      const PARAMETER = toFormElementFromParameter({ obj: this.regel[`${ this.modulePrefix }_argument`] });
      this.parameterObj = PARAMETER.object;
      this.parameterList = PARAMETER.list;
      this.modelParameter = initModelFromList({ list: this.parameterList });
    },

    prepareModel() {
      this.model = cloneDeep(this.module.data);
    },

    registerOrDeactiveSignalReceiver({ eventBusAction }) {
      if (!this.signalEmpfang.length) {
        return;
      }

      forEach(this.signalEmpfang, item => {
        if (item.signal_kennung && item.sender_reid) {
          const EVENT_NAME = `${ item.signal_kennung }_${ item.sender_reid }`;
          if (eventBusAction === "$off") {
            EventBus.$off(EVENT_NAME, this.eventsMapping[EVENT_NAME]);
          } else {
            this.eventsMapping[EVENT_NAME] = arg => this.onReceiveSignalLocal(item.signal_kennung, arg);
            EventBus[eventBusAction](EVENT_NAME, this.eventsMapping[EVENT_NAME]);
          }
        }
      });
    },

    onReceiveSignalLocal(signal_kennung, arg) {
      clearTimeout(this.timerSignal);
      this.timerSignal = setTimeout(() => { // Workaround, wenn 2 oder mehrere Signale gleichzeitig kommen
        this.onReceiveSignal(signal_kennung, arg);
        this.sendSignal();
      }, 100);
    },

    save({ model, childObj } = {}) {
      let data = this.modelEdit && size(this.modelEdit) ? cloneDeep(this.modelEdit) : cloneDeep(model);
      if (isFunction(this.prepareToSave)) {
        data = this.prepareToSave({ data });
      }
      this.errors = undefined;
      if (isFunction(this.childSave)) {
        return new Promise((resolve, reject) => {
          this.childSave({ data, regel: this.regel.regel }).then(
            response => {
              this.onRestFunctionsChild({ response });
              this.deleteErrors();
              this.onSaved();
              resolve(response);
            },
            errors => {
              reject(this.onError({ errors, childObj }));
            }
          );
        });
      }
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.moduleUrl,
          data,
        }).then(
          response => {
            this.onRestFunctions({ response });
            this.deleteErrors();
            this.onSaved();
            resolve(response);
          },
          errors => {
            reject(this.onError({ errors, childObj }));
          }
        );
      });
    },

    onError({ errors }) {
      this.errors = errors.data;
      return errors;
    },

    close() {
      this.errors = undefined;
    },

    reloadModule({ statusUpdate } = {}) {
      this.statusReloading = true;
      return this.getHttp({
        url: this.moduleUrl,
      }).then(
        response => {
          this.updateModuleLocal({ response, extra: this.extra, group: this.group });
          setTimeout(() => {
            this.initData({ statusUpdate });
            this.closeFormstepDetailEditMode();
            this.statusReloading = false;
          });
        }
      );
    },

    closeFormstepDetailEditMode() {
      if (this.$refs.formstep_detail &&
        isFunction(this.$refs.formstep_detail.closeEditMode)) {
        this.$refs.formstep_detail.closeEditMode();
      }
    },

    onRestFunctions({ response } = {}) {
      if (!isNull(this.statusSaveCallback) && isFunction(this.saveCallback)) {
        this.saveCallback({ statusSaveCallback: this.statusSaveCallback });
      }
      if (response) {
        this.updateModuleLocal({ response, extra: this.extra, group: this.group });
        this.sendEventBusUpdateGR();
      }
      this.updateSnapshotDiffLocal();
      this.sendSignal();
      if (response) {
        setTimeout(() => {
          this.initData();
        });
      }
    },

    onRestFunctionsChild() {
      this.sendSignal();
      setTimeout(() => {
        this.sendEventBusUpdateGR();
        this.initData();
      });
    },

    updateSnapshotDiffLocal() {
      if (this.snapshot && isFunction(this.updateSnapshotDiff)) {
        this.updateSnapshotDiff(this.snapshot.pk);
      }
    },

    onReceiveSignal() {

    },

    sendUnterregelSignals() {
      forEach(this.unterregeln, unterregel => {
        forEach(this.signalVersandUnterregelnKennungen[unterregel.regel], kennung => {
          EventBus.$emit(kennung, { regel: unterregel });
        });
      });
    },

    sendSignal() {
      forEach(this.signalVersandKennungen, kennung => {
        EventBus.$emit(kennung, this.signalArguments);
      });
      this.sendUnterregelSignals();
      this.sendSignalLocal();
    },

    sendSignalLocal() {

    },

    initDataLocal() {

    },

    updateModuleLocal({ response, extra, group }) {
      if (isFunction(this.updateModule)) {
        this.updateModule({ response, extra, group });
      }
    },

    sendEventBusUpdateGR() {
      EventBus.$emit("updateGeschaeftsregel", {
        regelNummer: this.regelNummer,
        modulname: this.module.modulname,
      });
    },

    onSaved() {

    },

    showErrorsInModuleGlobal({ regelId, errors }) {
      if (this.regelIdWithParentsRegelId !== regelId) {
        return;
      }
      this.showErrorsInModule({ errors });
    },

    showErrorsInModule({ errors }) {
      if (this.$refs.formstepDetail) {
        if (this.isModuleEditable) {
          this.$refs.formstepDetail.openEditMode();
        }
        this.$refs.formstepDetail.setErrors({
          errors: errors,
        });
        this.setLocalErrorsFromGlobal({ errors });
      }
    },

    setLocalErrorsFromGlobal() {

    },

    deleteErrors() {
      if (this.$refs.formstepDetail) {
        this.$refs.formstepDetail.setErrors({
          errors: undefined,
        });
      }
    },

    ...mapMutations([
      "MUT_SET_STATUS_DIFF_FOR_REGEL",
      "MUT_DELETE_STATUS_DIFF_FOR_REGEL",
    ]),
  },
};
