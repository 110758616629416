import TableCellMixin from "../TableCellMixin";
import PuxButton from "../../../PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "TableViewTableCellButton",
  components: {
    PuxButton,
  },
  mixins: [
    TableCellMixin
  ],
  props: {
    index: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    classNameLocal() {
      if (this.className) {
        return this.className;
      }
      return `${ this.options.className || "btn btn-default" }`;
    },
  },
  methods: {
    callbackLocal() {
      return this.options.callback({ item: this.row, index: this.index });
    },
  },
};
