import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  FilterDateMixin,
} from "../../global/mixins/FiltersMixin";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  components: {
    PuxTranslate,
  },
  mixins: [
    FilterDateMixin,
  ],
  props: {
    notiz: {
      type: Object,
      required: true,
    },
    fuerSitzung: {
      type: Boolean,
      required: true,
    },
    fuerAntragstellerorg: {
      type: Boolean,
      required: false,
      default: false,
    },
    fuerFoerderorg: {
      type: Boolean,
      required: false,
      default: false,
    },
    mainObjektid: {
      type: String,
      required: false,
      default: undefined,
      info: "Für Notizen in Dokumente",
    },
    mainContentType: {
      type: Number,
      required: false,
      default: undefined,
      info: "Für Notizen in Dokumente",
    },
  },
  computed: {
    idText() {
      return `no_text_${ this.notiz.pk }`;
    },

    hrefAutor() {
      return `mailto:${ this.notiz.autor.n_email }`;
    },

    hrefEditor() {
      return `mailto:${ this.notiz.editor.n_email }`;
    },

    translateAllMitglieder() {
      return {
        html: "_HTML_NOTIZEN_ALL_MEMBERS_VISIBLE_{{orgName}}_",
        extraFoerderorg: {
          orgName: get(this.notiz, "foerderorg.name"),
        },
        extraAntragstellerorg: {
          orgName: get(this.notiz, "antragstellerorg.name"),
        },
      };
    },

    updateDateFiltered() {
      return this.filterDate(this.notiz.upddate);
    },

    createDateFiltered() {
      return this.filterDate(this.notiz.crdate);
    },
  },
};
