import FoerderorgAnsprechpartnerModalZuweisen from "../FoerderorgAnsprechpartnerModalZuweisen/FoerderorgAnsprechpartnerModalZuweisen.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "FoerderorgAnsprechpartnerZuweisenButtonModal",
  components: {
    FoerderorgAnsprechpartnerModalZuweisen,
    PuxButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    ansprechpartner: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isModalZuweisenVisible: false,
    };
  },
  computed: {
    selectorClose() {
      return `#${ this.id }`;
    },
  },
  methods: {
    openModalZuweisenAnsprechpartner() {
      this.isModalZuweisenVisible = true;
    },

    closeModalZuweisenAnsprechpartner() {
      this.isModalZuweisenVisible = false;
    },
  },
};
