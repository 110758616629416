"use strict";

import BaseModel from "dias/core/models/baseModel";

class FibuJournal extends BaseModel {
  constructor() {
    super("fibujournals");
  }


  extendModel(model) {
    model = super.extendModel(model);

    model.getPreview = () => model.customGET("vorschau");

    return model;
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.getBuchungsobjekte = () => {
      return { getChoices: this.createGetChoices(collection.all("buchungsobjekte")) };
    };
    collection.getRefobjekte = () => {
      return { getChoices: this.createGetChoices(collection.all("refobjekte")) };
    };

    return collection;
  }
}

export default FibuJournal;
