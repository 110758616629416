import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import {
  toFormElementFromParameter,
  initModelFromList,
} from "../../../../global/functions/mappingForParameterToFormElement";
import {
  cloneDeep,
  forEach,
  size,
  get,
  isUndefined,
  isEmpty,
} from "lodash-es";

// @vue/component
export default {
  name: "Bewertung",
  components: {
    SnapshotModule,
    SnapshotIcon,
    FormElementReadOnly,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      modelEdit: {},
      loading: true,
      configError: false,
      bewertungstyp: {},
      formElementOptions: [],
      modelDefault: {},
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.open;
      OPTIONS.list = this.formElementOptions;
      return OPTIONS;
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(this.formElementOptions, item => {
          const KEY = item.key || item.id;
          if (get(this.model, KEY) !== get(this.snapshotModule, KEY)) {
            DIFF[KEY] = true;
          }
        });
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getHttp({
        url: "katalog/",
        urlParams: {
          key: "bewertungstyp",
          pk: this.modelParameter.bewertungstyp,
        },
      }).then(
        response => {
          if (response.length < 1) {
            this.configError = true;
          } else {
            this.bewertungstyp = response[0];
            this.initForm();
          }
        }
      ).then(
        () => this.loading = false
      );
    },

    initForm() {
      const FORM_ELEMENT_OPTIONS = toFormElementFromParameter({
        obj: this.bewertungstyp.bet_parameterin,
        editonly: true,
        showLabel: true,
        htmlIdPrefix: this.htmlRegelNummer,
      }).list;
      FORM_ELEMENT_OPTIONS[0].startupFocus = true;
      this.formElementOptions = FORM_ELEMENT_OPTIONS;
      this.modelDefault = initModelFromList({ list: this.formElementOptions });
    },

    open() {
      const MODEL = cloneDeep(this.modelDefault);
      if (this.model && size(this.model)) {
        forEach(this.formElementOptions, item => {
          const ID = item.key || item.id;
          MODEL[ID] = !isUndefined(this.model[ID]) ? this.model[ID] : null;
        });
      }
      return { model: MODEL };
    },
  },
};
