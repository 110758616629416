export default {
  main: {
    antragslisten_uebersicht: "ModuleAntragslistenUebersichtMain",
    automatische_antragsliste: "ModuleAutomatischeAntragslisteMain",
    automatische_antragsliste_mittellos: "ModuleAutomatischeAntragslisteMittellosMain",
    top_protokoll: "ModuleTopProtokollMain",
  },
  preview: {
    automatische_antragsliste: "ModuleAutomatischeAntragslistePreview",
    automatische_antragsliste_mittellos: "ModuleAutomatischeAntragslisteMittellosPreview",
    beratungsvorlage: "ModuleBeratungsvorlagePreview",
    manuelle_antragsliste: "ModuleManuelleAntragslistePreview",
    muendlicher_bericht: "ModuleMuendlicherBerichtPreview",
    niederschrift: "ModuleNiederschriftPreview",
  },
};
