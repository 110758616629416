export default {
  text: {
    label: "Text",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      max_length: {
        required: false,
      },
    },
  },
  // email: {
  //   label: "E-Mail",
  //   options: {
  //     label: {
  //       required: true,
  //     },
  //     required: {
  //       required: true,
  //     },
  //     styles: {
  //       required: false,
  //     },
  //     maxlength: {
  //       required: false,
  //     },
  //   },
  // },
  integer: {
    label: "Ganzzahl",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      max_value: {
        required: false,
      },
      min_value: {
        required: false,
      },
      sum: {
        required: false,
      },
    },
  },
  decimal: {
    label: "Kommazahl",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      max_digits: {
        required: true,
      },
      decimal_places: {
        required: true,
      },
      max_value: {
        required: false,
      },
      min_value: {
        required: false,
      },
      sum: {
        required: false,
      },
    },
  },
  currency: {
    label: "Währung",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      max_digits: {
        required: true,
      },
      decimal_places: {
        required: true,
      },
      max_value: {
        required: false,
      },
      min_value: {
        required: false,
      },
      sum: {
        required: false,
      },
    },
  },
  // textarea: {
  //   label: "Textarea",
  //   options: {
  //     label: {
  //       required: true,
  //     },
  //     required: {
  //       required: true,
  //     },
  //     styles: {
  //       required: false,
  //     },
  //     maxlength: {
  //       required: false,
  //     },
  //   },
  // },
  single_choice: {
    label: "Auswahlliste",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      katalog: {
        required: true,
      },
      komplett: {
        required: true,
      },
      ids: {
        required: true,
      },
    },
  },
  multi_select: {
    label: "Mehrfachauswahl",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      katalog: {
        required: true,
      },
      komplett: {
        required: true,
      },
      ids: {
        required: true,
      },
    },
  },
  single_choice_objektkatalog: {
    label: "Objektbezogene Auswahlliste",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      objektkatalog: {
        required: true,
      },
    },
  },
  date: {
    label: "Datum",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      validation_expression: {
        required: false,
      },
      validation_error_message: {
        required: false,
      },
    },
  },
  date_expression: {
    label: "Berechnetes Datum",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      berechnung_expression: {
        required: false,
      },
      styles: {
        required: false,
      },
    },
  },
  // daterange: {
  //   label: "Zeitraum",
  //   options: {
  //     label: {
  //       required: true,
  //     },
  //     required: {
  //       required: true,
  //     },
  //     styles: {
  //       required: false,
  //     },
  //   },
  // },
  richtext: {
    label: "Text mit Editor",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      max_length: {
        required: false,
      },
    },
  },
  // checkbox: {
  //     label: true,
  //     required: true,
  //     styles: true,
  //   },
  // radio: {
  //     label: true,
  //     required: true,
  //     styles: true,
  //   },
  boolean: {
    label: "Boolean",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      trueLabel: {
        required: false,
      },
      falseLabel: {
        required: false,
      },
      defaultValue: {
        required: true,
      },
    },
  },
  document: {
    label: "Dokument",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      dokart: {
        required: true,
      },
      isPreview: {
        required: false,
      },
      allowed_mime_type: {
        required: true,
      },
    },
  },
  math_expression: {
    label: "Berechnungsformel",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      expression: {
        required: true,
      },
      max_digits: {
        required: true,
      },
      decimal_places: {
        required: true,
      },
      max_value: {
        required: false,
      },
      min_value: {
        required: false,
      },
      sum: {
        required: false,
      },
    },
  },
  decimal_signal: {
    label: "Dezimalzahl durch Signalempfang",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      signal_regex: {
        required: true,
      },
      max_digits: {
        required: true,
      },
      decimal_places: {
        required: true,
      },
      max_value: {
        required: false,
      },
      min_value: {
        required: false,
      },
    },
  },
  decimal_objektkatalog_data: {
    label: "Dezimalzahl aus ausgewähltem objektbezogenen Element",
    options: {
      label: {
        required: true,
      },
      styles: {
        required: false,
      },
      max_digits: {
        required: true,
      },
      decimal_places: {
        required: true,
      },
      objektkatalog_spalte_id: {
        required: true,
      },
      objektkatalog_data_id: {
        required: true
      },
      sum: {
        required: false,
      },
    },
  },
  text_objektkatalog_data: {
    label: "Text aus ausgewähltem objektbezogenen Element",
    options: {
      label: {
        required: true,
      },
      styles: {
        required: false,
      },
      max_length: {
        required: true,
      },
      objektkatalog_spalte_id: {
        required: true,
      },
      objektkatalog_data_id: {
        required: true
      }
    },
  }
};
