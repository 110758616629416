"use strict";

import "restangular";
import angular from "angular";

import Sitzungen from "./sitzungen.service";
import Sitzungsarten from "./sitzungsarten.service";
import Sitzungtagesordnung from "./sitzungtagesordnung.service";

const modelsModule = angular.module("sitzungen.models", [
  "restangular",
])

  .provider("Sitzungen", Sitzungen)
  .provider("Sitzungsarten", Sitzungsarten)
  .provider("Sitzungtagesordnung", Sitzungtagesordnung)
;

export default modelsModule;
