import AufgabeBox from "../../Aufgabe/AufgabeBox/AufgabeBox.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import CsvDownload from "../../../global/components/CsvDownload/CsvDownload.vue";
import DetailsInfoBox from "../../DetailsInfoBox/DetailsInfoBox.vue";
import DokumentDownload from "../../Dokumente/DokumentDownload/DokumentDownload.vue";
import FormularCreate from "../../Formulare/FormularCreate/FormularCreate.vue";
import NotizenBox from "../../Notizen/NotizenBox/NotizenBox.vue";
import NotizenIcon from "../../Notizen/NotizenIcon/NotizenIcon.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxDropdown from "../../../global/components/PuxDropdown/PuxDropdown.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SitzungDetailsDokumente from "./SitzungDetailsDokumente/SitzungDetailsDokumente.vue";
import SitzungDetailsGoToBox from "./SitzungDetailsGoToBox/SitzungDetailsGoToBox.vue";
import SitzungDetailsMittel from "./SitzungDetailsMittel/SitzungDetailsMittel.vue";
import SitzungDetailsProtokoll from "./SitzungDetailsProtokoll/SitzungDetailsProtokoll.vue";
import SitzungDetailsSitzungsdaten from "./SitzungDetailsSitzungsdaten/SitzungDetailsSitzungsdaten.vue";
import SitzungDetailsTagesordnung from "./SitzungDetailsTagesordnung/SitzungDetailsTagesordnung.vue";
import SitzungDetailsUebersicht from "./SitzungDetailsUebersicht/SitzungDetailsUebersicht.vue";
import SitzungDetailsTeilnehmer from "./SitzungDetailsTeilnehmer/SitzungDetailsTeilnehmer.vue";
import TaskLogBox from "../../../global/components/TaskLogBox/TaskLogBox.vue";
import Timeline from "../../Timeline/Timeline.vue";
import WFStatusGruppen from "../../Workflows/StatusGruppen/StatusGruppen.vue";
import WorkflowFolgen from "../../Workflowfolgen/WorkflowFolgen/WorkflowFolgen.vue";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";
import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../global/compositionAPI/PermissionAPI";
import SitzungDetailsAPI from "./compositionAPI/SitzungDetailsAPI";

import fileDownload from "js-file-download";

import {
  forEach,
  get,
  isPlainObject,
  set,
} from "lodash-es";

// @vue/component
export default {
  name: "SitzungDetails",
  components: {
    AufgabeBox,
    ContextPanel,
    CsvDownload,
    DetailsInfoBox,
    DokumentDownload,
    FormularCreate,
    NotizenBox,
    NotizenIcon,
    Permission,
    PuxButton,
    PuxDropdown,
    PuxIcon,
    PuxPageDetails,
    PuxTranslate,
    SitzungDetailsDokumente,
    SitzungDetailsGoToBox,
    SitzungDetailsMittel,
    SitzungDetailsProtokoll,
    SitzungDetailsTagesordnung,
    SitzungDetailsSitzungsdaten,
    SitzungDetailsUebersicht,
    SitzungDetailsTeilnehmer,
    TaskLogBox,
    Timeline,
    WFStatusGruppen,
    WorkflowFolgen,
  },
  setup() {
    const {
      pageLoading,
      reloadSitzung,
      sitzung,
      sitzungDetailsNotizenSteps,
      sitzungDetailsRubricsMapping,
      sitzungLoading,
      sitzungUrl,
      updateAufgabe,
      updateSitzung,
    } = SitzungDetailsAPI();

    const {
      filterDate,
    } = FiltersAPI();

    const {
      addNotification,
      getHttp,
      postHttp,
    } = HttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    reloadSitzung();

    return {
      addNotification,
      checkPermissionsSync,
      filterDate,
      getHttp,
      postHttp,
      reloadSitzung,
      updateAufgabe,
      updateSitzung,

      notizenSteps: sitzungDetailsNotizenSteps,
      pageLoading,
      rubricsMapping: sitzungDetailsRubricsMapping,
      sitzung,
      sitzungLoading,
      sitzungUrl,
    };
  },
  data() {
    return {
      loading: {
        aufgaben: false,
      },
      viewName: "details",
      currentWorkflowfolge: undefined,
      clientFunktion: undefined,
      validationErrors: undefined,
      lastReload: undefined,
      csvUrls: {
        antraege: undefined,
        antraegePresse: undefined,
        antraegeZahlenprotokoll: undefined
      },
      panelUebersichtOptions: {
        label: "_TXT_SITZUNGSDETAILS_CONTEXT_PANEL_TITLE_UEBERSICHT_",
        icon: "list",
        active: false,
      },
      panelSitzungdatenOptions: {
        label: "_TXT_SITZUNGSDETAILS_CONTEXT_PANEL_TITLE_SITZUNGSDATEN_",
        icon: "sitzungsdokumente",
        active: false,
      },
      panelTagesordnungOptions: {
        label: "_TXT_SITZUNGSDETAILS_CONTEXT_PANEL_TITLE_TAGESORDNUNG_",
        icon: "tagesordnung",
        active: true,
      },
      panelZuVerteilendeMittelOptions: {
        label: "_TXT_SITZUNGSDETAILS_CONTEXT_PANEL_TITLE_ZU_VERTEILENDE_MITTEL_",
        icon: "euro",
        active: false,
      },
      panelProtokollOptions: {
        label: "_TXT_SITZUNGSDETAILS_CONTEXT_PANEL_TITLE_PROTOKOLL_",
        icon: "pencil-2",
        active: false,
      },
      panelTeilnehmerOptions: {
        label: "_TXT_SITZUNGSDETAILS_CONTEXT_PANEL_TITLE_TEILNEHMER_",
        icon: "user",
        active: false,
      },
      panelDokumenteZurSitzungOptions: {
        label: "_TXT_SITZUNGSDETAILS_CONTEXT_PANEL_TITLE_DOKUMENTE_ZUR_SITZUNG_",
        icon: "file",
        active: false,
      },
    };
  },
  computed: {
    timelineUrl() {
      return `${ this.sitzungUrl }timeline/`;
    },

    sitzungAkteDownloadUrl() {
      return `${ this.sitzungUrl }akte/`;
    },

    sitzungZahlenreportDownloadUrl() {
      return `${ this.sitzungUrl }zahlenreport/`;
    },

    aktuelleAufgabeTaskId() {
      return get(this.sitzung, "aktuelle_aufgabe.task_id");
    },

    timeLineOptions() {
      return {
        config: {
          benachrichtigungen: {
            bezug: "sitzung",
            bezugpk: this.$stateParams.id,
            autorefresh: true,
          },
          url: this.timelineUrl,
        },
        bisection: false,
        viewName: this.viewName,
      };
    },

    textTranslateForChangeView() {
      return this.viewName === "timeline" ?
        "_BTN_SITZUNG_ANZEIGEN_" :
        "_BTN_VERLAUF_ANZEIGEN_";
    },

    hasPermissionVerlaufView() {
      return this.checkPermissionsSync({
        perm: "sitzungen.verlauf.view",
        permArray: this.sitzung.user_permissions,
      });
    },

    iconForButtonChangeView() {
      return this.viewName === "timeline" ? "tagesordnung" : "time";
    },

    pageHeaderData() {
      return {
        placeholder: "_HTML_SITZUNGSDETAILS_PAGE_HEADER_{{number}}_{{bez}}_{{date}}_",
        extra: {
          number: this.sitzung.si_sitzungsnr,
          bez: isPlainObject(this.sitzung.sitzungsart_obj) ? this.sitzung.sitzungsart_obj.sia_bez : "",
          date: this.filterDate(this.sitzung.si_sitzungsdatum),
        },
      };
    },
  },
  created() {
    this.setAntraegeCsvUrls();
  },
  methods: {

    toggleView() {
      if (this.viewName === "details") {
        this.viewName = "timeline";
      } else {
        this.viewName = "details";
      }
    },

    updateAufgabeDelayed(newAufgabe) {
      setTimeout(() => {
        this.updateAufgabe({ newAufgabe });
      });
    },

    onWorkflowInit(clientFunktion) {
      setTimeout(() => {
        this.loading.aufgabe = true;
        this.clientFunktion = clientFunktion;
      });
    },

    onWorkflowCanceled() {
      this.loading.aufgabe = false;
      this.clientFunktion = undefined;
    },

    onWorkflowStarted(newAufgabe, currentWorkflowfolge) {
      setTimeout(() => {
        this.updateAufgabe({ newAufgabe });
        this.currentWorkflowfolge = currentWorkflowfolge;
      });
    },

    onWorkflowSuccess(aufgabe) {
      setTimeout(() => {
        if (this.clientFunktion) {
          this.clientFunktion.onServerSuccess(aufgabe);
        }
        this.scrollTop();
        if (get(this.currentWorkflowfolge, "wfo_reload")) {
          return this.reloadSitzung();
        }
      });
    },

    onWorkflowFailure(error) {
      setTimeout(() => {
        if (this.clientFunktion) {
          this.clientFunktion.onServerError(error);
        }
        if (error.data && error.data.error_data) {
          this.validationErrors = error.data.error_data;
        } else if (error.data && !angular.isString(error.data)) {
          this.validationErrors = error.data;
        } else {
          this.validationErrors = undefined;
        }
        this.scrollTop();
        return this.reloadSitzung();
      });
    },

    onWorkflowFinish() {
      setTimeout(() => {
        this.loading.aufgabe = false;
        this.clientFunktion = undefined;
        this.currentWorkflowfolge = undefined;
      });
    },

    onAufgabeAngenommen() {
      setTimeout(() => {
        this.reloadSitzung();
      });
    },

    scrollTop() {
      $(window).scrollTop(0);
    },

    resetCache() {
      return this.postHttp({
        url: `${ this.sitzungUrl }caches/reset/`,
      }).then(
        () => this.addNotification({
          text: "_MSG_SITZUNGSDETAILS_CACHE_RESET_SUCCESS_",
          type: "success",
        }),
        () => this.addNotification({
          text: "_MSG_SITZUNGSDETAILS_CACHE_RESET_ERROR_",
          type: "error",
        }),
      );
    },

    savePdf() {
      return this.getHttp({
        url: `${ this.sitzungUrl }pdf/`,
        responseType: "blob",
      }).then(
        response => {
          const FILE_NAME = `sitzung_${ this.sitzung.si_sitzungsnr }.pdf`;
          fileDownload(response, FILE_NAME);
        },
        () => {
          this.addNotification({
            text: "_MSG_DOCUMENT_DOWNLOAD_ERROR_",
            type: "error",
          });
        },
      );
    },

    savePdfUebersicht() {
      return this.getHttp({
        url: `${ this.sitzungUrl }uebersicht_pdf/`,
        responseType: "blob",
      }).then(
        response => {
          const FILE_NAME = `${ this.filterDate(new Date(), "yyyyMMdd") }_sitzung_${ this.sitzung.si_sitzungsnr }_uebersicht.pdf`;
          fileDownload(response, FILE_NAME);
        },
        () => {
          this.addNotification({
            text: "_MSG_DOCUMENT_DOWNLOAD_ERROR_",
            type: "error",
          });
        },
      );
    },

    setAntraegeCsvUrls() {
      const REQUESTS = [];
      const URLS = [
        { key: "antraege", url: `${ this.sitzungUrl }antraege/` },
        { key: "antraegePresse", url: `${ this.sitzungUrl }antraege_presse/` },
        { key: "antraegeZahlenprotokoll", url: `${ this.sitzungUrl }antraege_zahlenprotokoll/` },
      ];
      forEach(URLS, value => {
        REQUESTS.push(
          this.getHttp({
            url: value.url,
            urlParams: {
              limit: 1,
            },
          })
        );
      });
      Promise.all(REQUESTS).then(
        responses => {
          forEach(responses, (response, key) => {
            if (get(response, "csv_available")) {
              set(this.csvUrls, URLS[key].key, URLS[key].url);
            }
          });
        },
      );
    },
  },
};
