import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "Versionsinformationen",
  components: {
    FormstepDetail,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    versionsinformationen: {
      type: Object,
      required: true,
      default: undefined,
    },
    updateVersionsinformationen: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        label: "_TXT_VERSIONSINFORMATIONEN_DETAILS_VERSIONSINFORMATIONEN_PANEL_FORMSTEP_NAME_",
        list: [
          {
            id: "vin_versionsnummer",
            type: "text",
            label: "_LBL_VERSIONSINFORMATIONEN_MODAL_CREATE_VERSIONSNUMMER_",
            required: true,
            maxlength: 200,
            view: "v",
            translate: true,
          },
          {
            id: "vin_informationen",
            type: "richtext",
            label: "_LBL_VERSIONSINFORMATIONEN_MODAL_CREATE_INFORMATIONEN_",
            required: true,
            view: "v-alt",
            translate: true,
          },
          {
            id: "vin_datum",
            type: "datetime",
            label: "_LBL_VERSIONSINFORMATIONEN_MODAL_CREATE_DATUM_",
            required: true,
            view: "v-alt",
            translate: true,
          },
        ],
        required: true,
        saveCallback: this.save,
        openCallback: this.open,
      };
    },
  },
  methods: {
    save({ model }) {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: `releasenotes/${ this.versionsinformationen.pk }/`,
          data: this.setDataForSave({ model }),
        }).then(
          response => {
            this.updateVersionsinformationenLocal({ response });
            this.addNotification({
              text: "_MSG_VERSIONSINFORMATIONEN_DETAILS_UPDATE_{{titel}}_",
              extra: {
                titel: this.versionsinformationen.vin_versionsnummer,
              },
            });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },

    setDataForSave({ model }) {
      const DATA = cloneDeep(model);
      return DATA;
    },

    updateVersionsinformationenLocal({ response }) {
      const VERSIONSINFORMATIONEN = response;
      this.updateVersionsinformationen({ Versionsinformationen: VERSIONSINFORMATIONEN });
    },

    open() {
      return { model: this.setModel() };
    },

    setModel() {
      const MODEL = cloneDeep(this.versionsinformationen);
      return MODEL;
    },
  },
};
