var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<div><fieldset><div ng-attr-id=\"{{vm.errorContainerId}}\"></div><div class=\"row\"><div class=\"col-12\"><div class=\"checkbox-btn\"><input id=\"ast_gesperrt\" ng-model=\"vm.model.ast_gesperrt\" type=\"checkbox\" name=\"ast_gesperrt\" ng-attr-aria-checked=\"{{ vm.model.ast_gesperrt ? 'true' : 'false' }}\" class=\"form-control\"><label for=\"ast_gesperrt\" class=\"font-weight-bold\"><span class=\"label-hidden\">Wählen Sie bitte eine Antwort</span><span class=\"titel\">Gesperrt</span></label></div>");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<textarea id=\"ast_sperrbemerkung\" ng-model=\"vm.model.ast_sperrbemerkung\" name=\"ast_sperrbemerkung\" tabindex=\"2\" dias-richtext ng-attr-aria-invalid=\"{{vm.errors.ast_sperrbemerkung ? 'true' : 'false'}}\" class=\"form-control\"></textarea>");
}
}, "ast_sperrbemerkung", "Sperrbemerkung");
buf.push("</div></div></fieldset></div><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Abbrechen</span></button></div></div>");;return buf.join("");
}