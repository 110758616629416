export default {
  fristen: "0f6939da-2c36-4b6c-8430-f9aa0fed940e",
  auszahlung: "0fb8721e-dd28-4a7d-a36d-525a0a4d2348",
  konfiguration: "164b469f-3e2d-4872-a035-729b4761f2dd",
  vorhaben: "3c60f487-e009-4d8f-9d6d-edcca3e8032c",
  bewilligung: "743a442e-b37e-434e-a4b0-30808b90e856",
  finanzierung: "a45ebf2e-e26c-4c58-a156-4877bfb0c9fd",
  dokumente: "aaf6c4be-d204-47ce-af12-e8c9535cc133",
  bescheide: "4cf55b3b-e6a8-4a47-b8be-e78acb1c62ad",
  kosten: "ae1408e0-c3c3-4422-93a6-baa5b7365581",
  foerderangebot: "e8f5741f-e5eb-447a-b84f-742c51cbebc4",
  antragsteller: "fadc32e7-d467-47b2-9928-a7bdf9830463",
  pruefung: "f77c25a9-923e-4391-bc8d-f7604477e012",
  vertrag: "784f0e5b-1fd8-4690-8f94-edefa4a62622",
  sachbearbeiter_dokumente: "906511c6-546d-4ad3-9cae-11cbd05755a7",
  projekte: "5948018c-09a8-41ef-b4e8-298fb6a58100",
  buendnispartner: "bd9c5a85-291d-437a-950c-0c91366ea265",
  erklaerungen: "445cece7-51e4-46ec-9f60-2040425ada85",
  stellungnahme: "3e1bc4bc-c9e8-4677-9c1c-8e86020557c7",
  auflagen: "e7418fbe-a0f0-4e0e-b794-babb75815b91",
  presse: "1b78bc69-ff2a-425f-8da9-b63dad0e46a3",
  erklaerungen_zum_einreichen: "927cbc66-97a7-46e3-9bfe-e95ac535520f",
  kennzahlen: "73db44f2-9308-4e07-90fa-5c3544dd949f",
  nachweise: "be86aea9-d9ef-4879-a239-45586d7db5e3",
  bankverbindung: "e25f7a07-6a4e-4e77-81a6-845b03fc2d45",
  vertretungsberechtigte: "d706314b-ea75-41e7-a610-776ee0a13519",
  rubrik_0: "274ebfaf-94cc-447b-b014-759c8387e8ac",
  rubrik_1: "844e6d9a-e8fd-41be-94a1-522de7a69515",
  rubrik_2: "a4f8525a-3f93-4cf7-bedf-f448bb054cf3",
  rubrik_3: "addce8f4-fa3b-4aa5-a378-e80deb0efd81",
  rubrik_4: "0ac07d23-ab14-4f6b-899a-693f326305a8",
  rubrik_5: "43c394a0-cf84-438a-aa0c-0ed2292f849e",
  rubrik_6: "3df3d4f2-bc7f-427e-bf30-440d27a9efaa",
  rubrik_7: "c4c483a4-e6b4-4369-be0a-249fc1d64f94",
  rubrik_8: "8fef77f6-37a4-48ab-816e-8f1bdcabbda7",
  rubrik_9: "d58513b4-3961-481d-9bd8-7d4ef4982a5e",
  rubrik_10: "9bdd6b3d-bc79-45b9-a252-5b3e98b425c7",
  rubrik_10_1: "a29b783b-3f73-4704-be1a-885af8e53440",
  rubrik_10_2: "d80bbe96-2397-4058-8766-ec4421588fcc",
  rubrik_10_3: "4c00cc81-c726-426a-8994-65f757ff7983",
  rubrik_10_4: "6e0011fe-e740-43a7-b01e-596841a1892d",
  rubrik_10_5: "401859ad-34cf-40d4-9390-aeff9ed8b6f6",
  rubrik_10_6: "f62f585b-8de5-4c88-b6ac-591c888e4342",
  rubrik_10_7: "8074644c-c8bd-4ae9-85ab-1e8348810d01",
  rubrik_10_8: "b15a690c-7864-4fdd-865a-00750f1c190a",
  rubrik_10_9: "e61210fa-eaee-4d57-8510-b23125ded741",
  rubrik_10_10: "66ac8e4d-3c86-4de1-933a-9ed2fa616c88",
  rubrik_11: "cd2a083b-035b-4c1e-999b-ba773f471508",
  rubrik_12: "ab670693-f2a0-4376-af3f-315096b3e255",
  rubrik_13: "a98bdb8e-ec77-48e8-a537-3744b54d437f",
  rubrik_14: "84bb8cd6-414d-4f41-afe3-eae0765feebd",
  rubrik_15: "8e39b67b-2272-41d0-98a2-f767fd98411d",
  rubrik_16: "f031de2a-6195-419f-a825-4904a7b38d3a",
  rubrik_17: "9ac69d9f-ece1-4965-b5e0-64391446c898",
  rubrik_18: "54559f9c-07fc-4c24-bd34-80c958baab97",
  rubrik_19: "4e500660-be55-4900-a8a4-f6ea9b044788",
  rubrik_20: "4045e945-cb79-48b0-928b-a3eaa32bfd48",
  rubrik_21: "986c767e-228f-4753-b30c-4cce0b14f6c3",
  rubrik_22: "30ba136e-2400-4ff0-a50b-9481abcb5333",
  rubrik_23: "732e583c-e31c-42ab-b3e5-7a0fc1dbf961",
  rubrik_24: "1a77f343-1ba1-458c-a0f1-45ebef75ede3",
  rubrik_25: "147b3eed-eb15-46c2-b5bc-89a115626bdc",
  rubrik_26: "fbefca0f-6da2-43c5-b3ef-d1a5b01633a0",
  rubrik_27: "26234a75-d589-4d02-b05d-b6d0a44004d5",
  rubrik_28: "f749d94c-0771-4606-a0f6-13a0a4d7d046",
  rubrik_29: "b3dc653b-9f0a-46d4-95b7-f57bdac970e0",
  rubrik_30: "fa167bbc-4793-46b5-90c5-f6bc8bf3f5cd",
};
