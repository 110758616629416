"use strict";

// import "core-js-shim";
import "restangular";
import "bootstrap/dist/js/bootstrap.js";
import "./assets/jquery.metisMenu.js";
import "jquery";
// import "jqueryui";
import angular from "angular";
import sanitize from "angular-sanitize";
import "angular-i18n/angular-locale_de";
// import animate from "angular-animate";
import aria from "angular-aria";
import cookies from "angular-cookies";
import uiRouter from "angular-ui-router";
import "angular-ui-select";
import "angular-ui-select-css";
import "angular-ui-router-title";
import AppComponent from "./app.component.js";
import template from "./app.base.jade";
import CommonUi from "dias/ui/";
import Models from "./models";
import Services from "./services";
import {
  EventBus,
} from "../../../vue/client/vue/global/functions/event-bus";
import "../../../die-medialen/client/die-medialen/system";

import {
  get,
} from "lodash-es";

const apiBaseUrl = "/api/";

const defaultAppConfig = {
  baseTemplate: template
};

/* Initialisiert und konfiguriert die Angular-Applikation. */
const buildAngularApp = (ComponentModule, syconfData) => {
  const appConfig = angular.extend({}, defaultAppConfig, ComponentModule.appConfig || {});
  const app = angular.module("diasApp", [
    // animate,
    aria,
    uiRouter,
    sanitize,
    cookies,
    "ui.select",
    "ui.router.title",
    "restangular",
    CommonUi.name,
    ComponentModule.name,
    Models.name,
    Services.name,
  ])
  // SyConfig-Daten in den SyConfigsProvider einspeisen
    .config(["SyConfigsProvider", SyConfigsProvider => {
      SyConfigsProvider.initConfigs(syconfData);
    }])

  // HTML5-Modus (kein # in URLs) aktivieren.
    .config(["$locationProvider", $locationProvider => {
      $locationProvider.html5Mode(true);
    }])

  // Initialen State konfigurieren.
    .config(["$stateProvider", $stateProvider => {
      $stateProvider
        .state("root", {
          url: "",
          abstract: true,
          views: {
            app: {
              template: appConfig.baseTemplate,
              controller: /*@ngInject*/(
                $scope,
                user,
                $document,
                $rootScope,
                $cookies,
                $state,
                Logout,
                AuthService
              ) => {
                const iframeStatus = get($rootScope, "toState.data.iframe");
                if (iframeStatus) {
                  $scope.iframe = true;
                }

                setTimeout(() => {
                  $("#printableArea").css("padding-top", $("#navbar-fixed-top").outerHeight(true));
                });
                $cookies.put("clientcode", "proandi");

                $scope.currentUser = user;
                $scope.initHeader = () => {};

                $rootScope.$on("AuthService.userRefreshed", (event, user) => {
                  $scope.currentUser = user;
                });

                $state.current.reloadOnSearch = false;

                $rootScope.$on("$stateChangeStart", () => {
                  $state.current.reloadOnSearch = false;
                });

                $rootScope.$on("$stateChangeSuccess", () => {
                  $document[0].body.scrollTop = 0;
                  $document[0].documentElement.scrollTop = 0;
                  EventBus.$emit("updateState");
                });

                $scope.logout = () => {
                  Logout.post().then(
                    () => {
                      AuthService.refreshUser().then(
                        () => $state.go("root.home", {}, { reload: "root.home" })
                      );
                    }
                  );
                };
              },
            }
          },
          resolve: {
          // Erstes laden eines States wartet darauf, dass der User aufgelöst
          // wird und führt anschließend die Autorisierung durch
            user: /*@ngInject*/AuthService => AuthService.getUser().then(user => {
              AuthService.authorizeState();
              return user;
            })
          },
          data: {
          // Permissions können in Substates als Liste oder String angegeben werden
            permissions: []
          }
        });
    }])

  // // Run in Production Mode
  // .config(["$compileProvider", ($compileProvider) => {
  //     $compileProvider.debugInfoEnabled(false);
  // }])

  // CSRF-Token für Kommunikation mit Django konfigurieren.
    .config(["$httpProvider", $httpProvider => {
      $httpProvider.defaults.xsrfCookieName = "csrftoken";
      $httpProvider.defaults.xsrfHeaderName = "X-CSRFToken";
      $httpProvider.defaults.headers.common["User-Agent-App"] = "proandi/web";
    }])

  // Grundkonfiguration für Restangular.
    .config(["RestangularProvider", RestangularProvider => {
      RestangularProvider.setBaseUrl(apiBaseUrl);
      RestangularProvider.setRequestSuffix("/");
      RestangularProvider.setRestangularFields({ id: "pk" });

      const mappings = {
        count: ["pagination", "count"],
        count_unfiltered: ["pagination", "countUnfiltered"],
        next: ["pagination", "next"],
        previous: ["paginsation", "previous"],
        massenstatuswechsel: ["massenstatuswechsel", "massenstatuswechsel"],
        workflowfolgen: ["massenstatuswechsel", "workflowfolgen"],
        massenstatuswechsel_url: ["massenstatuswechsel", "url"]
      };
    // Anpassung von Restangular an die Rückgabewerte von Restframework.
      RestangularProvider.addResponseInterceptor(function(data, operation, what, url, response) {
        let extractedData;
        if (operation === "getList" && angular.isDefined(data.results)) {
          extractedData = data.results;
          extractedData.extra = {};
          const dataKeys = Object.keys(data);
          for (let i = 0; i < dataKeys.length; i++) {
            const key = dataKeys[i];
            if (key === "results") {
              continue;
            }
            if (mappings[key] !== undefined) {
              const mapping = mappings[key];
              extractedData[mapping[0]] = extractedData[mapping[0]] || {};
              extractedData[mapping[0]][mapping[1]] = data[key];
            } else {
              const jsKey = key.replace(/[-_]([a-z])/g, function(g) {
                return g[1].toUpperCase();
              });
              extractedData.extra[jsKey] = data[key];
            }
          }
        } else if (response.config && response.config.headers && response.config.headers.fullResponse) {
          extractedData = response;
        } else {
          extractedData = data;
        }
        return extractedData;
      });
    }])

  // App-Direktive hinzufügen.
    .component("app", AppComponent)
    .constant("Client", appConfig.client)
    .constant("spacedOnlyIfMatched", /^\d+$/)
  ;

  return app;
};

const doBootstrap = (ComponentModule, syconfData) => {
  angular.element(document).ready(function() {
    const diasApp = buildAngularApp(ComponentModule, syconfData);
    angular.bootstrap(document, [diasApp.name], {
      // TODO (eddy) strictDi wird für das minified Production Bundle NICHT benötigt.
      // Es hilft eigentich nur beim Entwickeln (webpack --watch).
      strictDi: true
    });
  });
};

const deferredBootstrap = ComponentModule => {
  const $http = angular.injector(["ng"]).get("$http");
  $http.get(apiBaseUrl + "konfigurationen/").then(
    response => {
      doBootstrap(ComponentModule, response.data);
    },
    cause => {
      console.error("SyConfigs konnten nicht geladen werden. Grund/Response = ", cause);
    }
  );
};


const Module = {
  bootstrapAngularApp: deferredBootstrap,
};

export default Module;
