import BetragsrechnerMixin from "../Mixins/BetragsrechnerMixin";
import FinanzierungDetailliertMixin from "../Mixins/FinanzierungDetailliertMixin";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "FinanzierungDetailliertBetragsrechner",
  mixins: [
    BetragsrechnerMixin,
    FinanzierungDetailliertMixin,
  ],
  data() {
    return {
      labelLand: "_LBL_FINANZIERUNG_DETAILLIERT_LAND_",
    };
  },
  computed: {
    optionsLand() {
      return {
        id: "land",
        htmlId: this.htmlIdLand,
        type: "select",
        required: true,
        view: this.view,
        label: this.labelLand,
        classesHorizontal: this.classesHorizontal,
        keyId: "pk",
        keyLabel: "lkz_bez",
        search: true,
        searchList: ["lkz_bez"],
        placeholder: "_TXT_FINANZIERUNG_DETAILLIERT_LAND_PLACEHOLDER_",
        url: "katalog/",
        urlParams: {
          key: "klaenderkuerzel",
        },
        apiSaveId: "klaenderkuerzel",
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdLand() {
      return `${ this.htmlId }_land`;
    },

    errorLabelsLocal() {
      const ERROR_LABELS = cloneDeep(this.errorLabels);
      ERROR_LABELS.land = {
        label: this.labelLand,
        link: true,
        id: this.htmlIdLand,
      };
      return ERROR_LABELS;
    },
  },
  created() {
    this.initErrorLabels(this.errorLabelsLocal);
  },
};
