"use strict";

class FinanzierungDetailliertController {
  /*@ngInject*/
  constructor($scope, Katalog, i18n) {
    this.gettext = i18n.gettext;
    this.Katalog = Katalog;
    if (!this.kontext || this.kontext === "undefined") {
      this.kontext = "antrag";
    }
    this.$scope = $scope;
    this.fin_id = this.$scope.ctx.extras.antragsregel.afr_argument.finanzierungsart.wert;
    this.laender = [];
    this.finanzierer = [];
    this.finanzierungsstatus = [];
    this.finartdetails = [];
    this.loadLaender();
    this.loadFinartdetails();
    this.loadStatus();
    this.loadFinanzierer();
  }

  loadLaender() {
    this.Katalog.getKatalog("klaenderkuerzel").then(
      response => this.lkzChoices = response
    );
  }
  loadFinartdetails() {
    this.Katalog.getKatalog("finanzierungsartdetail", { finanzierungsart: this.fin_id }).then(
      response => this.finartdetails = response
    );
  }
  loadStatus() {
    this.Katalog.getKatalog("kfinanzierungsstatus").then(
      response => this.finanzierungsstatus = response
    );
  }
  loadFinanzierer() {
    this.Katalog.getKatalog("kfinanzierer").then(
      response => this.finanzierer = response
    );
  }

  onChangeFinanziererFreitext() {
    if (this.posmodel.argument.finanzierer_freitext) {
      this.posmodel.argument.finanzierer = "d62ac6f2-32d0-4c9c-b1d2-92615a3905c8"; // Sonstiges
    }
  }
}

export default FinanzierungDetailliertController;
