import KFPlanMixin from "../Mixins/KFPlanMixin";
import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "KostenPlan",
  mixins: [
    KFPlanMixin,
  ],
  props: {
    kostenplan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      kofiPlanHtmlId: "kosten_plan",
    };
  },
  computed: {
    gesamtkostenCurrency() {
      return this.filterCurrency(this.kostenplan.gesamtkosten);
    },

    snapshotGesamtkostenCurrency() {
      const GESAMTKOSTEN = get(this.snapshot, "asn_snapshot.kostenplan.gesamtkosten");
      return this.filterCurrency(GESAMTKOSTEN);
    },

    extraForModule() {
      return {
        headerActualId: this.headerActualId,
        headerVersionId: this.headerVersionId,
        gesamtkosten: this.kostenplan ? this.kostenplan.gesamtkosten : undefined,
      };
    },
  },
};
