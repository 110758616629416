"use strict";

import template from "./vertragskonto.jade";


class VertragskontoController {
  /*@ngInject*/
  constructor($scope, $q, i18n, DiasNotification) {
    this.$q = $q;
    this.DiasNotification = DiasNotification;
    this.gettext = i18n.gettext;
    if (this.konto.mit_saldo) {
      this.get_saldo();
    }
  }

  get_saldo() {
    this.loadingSaldo = true;
    this.vertrag.customGET("saldo").then(
      response => this.saldo = response,
      () => this.DiasNotification.error("Fehler beim Abrufen der Salden.")
    ).finally(() => this.loadingSaldo = false);
  }

  saldoClicked() {
    if (this.onSaldoClick) {
      this.onSaldoClick();
    }
  }
}


export default {
  template: template(),
  controller: VertragskontoController,
  controllerAs: "vm",
  bindings: {
    vertrag: "<",
    konto: "<",
    onSaldoClick: "&?"
  }
};
