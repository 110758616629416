"use strict";

import BaseFunction from "../baseFunction.js";
import dialogTempalte from "./dialog.jade";
import dialogController from "./dialog.controller";

class EinreichenFunction extends BaseFunction {
  /*@ngInject*/
  constructor(DiasNotification, $q, diasModalDialog, workflowfolge, obj, extra) {
    super(DiasNotification, $q, workflowfolge, obj, extra);
    this.diasModalDialog = diasModalDialog;
  }

  start() {
    const extras = {
      parameterin: this.prepareParameterIn(),
      workflowfolge: this.workflowfolge,
      obj: this.obj,
      finish: this.finish.bind(this)
    };
    return this.diasModalDialog({
      title: extras.parameterin.title,
      template: dialogTempalte,
      controller: dialogController,
      extras: extras
    });
  }
}

export default EinreichenFunction;
