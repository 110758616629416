import translate from "../../../../../../../global/directives/translate";

import FiltersAPI from "../../../../../../../global/compositionAPI/FiltersAPI";

import PuxButton from "../../../../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "UiDynamischeZahlenReadOnlyElement",
  components: {
    PuxButton,
  },
  directives: {
    translate,
  },
  props: {
    modelCurrent: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterDefaultForEmpty,
    } = FiltersAPI();

    return {
      filterDefaultForEmpty,
    };
  },
  data() {
    return {
      statusShowDetails: false,
    };
  },
  computed: {
    getButtonText() {
      return this.statusShowDetails ?
        "(Konfiguration ausblenden)" :
        "(Konfiguration anzeigen)";
    },
  },
  methods: {
    toggleDetails() {
      this.statusShowDetails = !this.statusShowDetails;
    },
  },
};
