"use strict";

import StandardFinanzierungsController from "../../finanzierung.controller";

class StandardPauschalenController extends StandardFinanzierungsController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService) {
    super($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService);
    this.name = "StandardPauschalenController";
    // Keine Felder zum Erzeugen, da es ein readonly Finanzierungsmodul ist:
    this.newPosition = {};
    this.allowsInput = true;
  }
}

export default StandardPauschalenController;
