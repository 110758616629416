"use strict";

import BetragsrechnerBase from "dias/kosten_finanzierung/betragsrechner/betragsrechner.base";

class RegionaleEffekteBetragsrechner extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "mdm_regionale_effekte";
    this.component_name = "mdm-regionale-effekte-betragsrechner";
    this.labels = { gesamt: "Gesamtkosten",
                    part_de: "davon Kosten in Deutschland",
                    part_de_mtl: "davon Kosten in Mitteldeutschland",
                    part_de_sn: "davon Kosten in Sachsen",
                    part_de_st: "davon Kosten in Sachsen-Anhalt",
                    part_de_th: "davon Kosten in Thüringen"
    };
  }

  get_labelmapping() {
    return this.labels;
  }

  get_fields(params) {
    return { gesamt: params.gesamt,
             part_de: params.part_de,
             part_de_mtl: params.part_de_mtl,
             part_de_sn: params.part_de_sn,
             part_de_st: params.part_de_st,
             part_de_th: params.part_de_th
    };
  }
}
export default RegionaleEffekteBetragsrechner;
