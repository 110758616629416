import Permission from "../../../../../global/components/Permission/Permission.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import VertragOverviewTermin from "../VertragOverviewTermin/VertragOverviewTermin.vue";

import SyConfigMixin from "../../../../../global/mixins/SyConfigMixin";
import {
  CheckPermissionsSyncMixin,
} from "../../../../../global/mixins/PermissionMixin";
import {
  FilterCurrencyMixin,
} from "../../../../../global/mixins/FiltersMixin";

import {
  forEach,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragOverviewFoerderung",
  components: {
    Permission,
    PuxIcon,
    PuxTranslate,
    VertragOverviewTermin,
  },
  mixins: [
    CheckPermissionsSyncMixin,
    FilterCurrencyMixin,
    SyConfigMixin,
  ],
  props: {
    vertrag: {
      type: Object,
      required: true,
    },
    canViewBetrag: {
      type: Boolean,
      required: true,
    },
    auszahlungen: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      termineConf: undefined,
    };
  },
  computed: {
    zuschussBewilligtCurrency() {
      return this.filterCurrency(this.vertrag.zuschuss_bewilligt);
    },

    hasPermissionAuszahlungView() {
      return this.checkPermissionsSync({
        perm: "auszahlung.view",
      });
    },

    betragAusgezahltSumCurrency() {
      return this.filterCurrency(this.betragAusgezahltSum);
    },

    betragAusgezahltSum() {
      let sum = 0;
      forEach(this.auszahlungen, item => {
        if (item.az_datum_ausgezahlt) {
          sum += +item.az_betrag;
        }
      });
      return sum;
    },

    vertragAuszahlungTermine() {
      return get(this.vertrag, "termine.auszahlung", []);
    },

    vertragHasTermine() {
      return this.vertragAuszahlungTermine && this.vertragAuszahlungTermine.length;
    },
  },
  created() {
    this.setConfig();
  },
  methods: {
    setConfig() {
      this.termineConf = this.getSyConfigsValue("v_termine_in_uebersicht", false);
    },
  },
};
