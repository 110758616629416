import {
  reactive,
} from "vue";

import AntragStepsItem from "./AntragStepsItem/AntragStepsItem.vue";
import FormstepDetail from "../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";

import SyConfigAPI from "../../global/compositionAPI/SyConfigAPI";

// @vue/component
export default {
  name: "TheAntragSteps",
  components: {
    AntragStepsItem,
    FormstepDetail,
    PuxTranslate,
  },
  setup() {
    const {
      getSyConfigsValue,
    } = SyConfigAPI();

    const antragSteps = reactive(getSyConfigsValue("antrag_schritte", []));
    const headerTranslateExtra = reactive({
      number: antragSteps.length,
    });

    return {
      antragSteps,
      headerTranslateExtra,
    };
  },
};
