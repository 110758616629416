import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import AutoFocus from "../../../../global/directives/AutoFocus";

import ModuleMixin from "../ModuleMixin";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import {
  ERWERBART_CHOICES,
  EINRICHTUNGSART_CHOICES,
} from "./InvestitionsfoerderungChoices";
import {
  cloneDeep,
  find,
  isEmpty,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "Investitionsfoerderung",
  components: {
    SnapshotModule,
    SnapshotIcon,
  },
  directives: {
    AutoFocus,
  },
  mixins: [
    ModuleMixin,
  ],
  setup() {
    const {
      filterDate,
      filterDefaultForEmpty,
    } = FiltersAPI();

    return {
      filterDate,
      filterDefaultForEmpty,
    };
  },
  data() {
    return {
      einrichtungsartChoices: EINRICHTUNGSART_CHOICES,
      erwerbartChoices: ERWERBART_CHOICES,
      erwerbartModel: undefined,
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.saveCallback = this.saveLocal;
      OPTIONS.closeCallback = this.closeLocal;
      OPTIONS.list = [
        {
          id: "erwerbart",
          htmlId: `${ this.htmlRegelNummer }_erwerbart`,
          type: "template",
          slot: "erwerbart",
          editonly: true,
          view: "v-alt",
          label: "_LBL_GR_INVESTITIONSFOERDERUNG_ERWERB_ART_",
        },
        {
          id: "erwerb_datum",
          htmlId: `${ this.htmlRegelNummer }_erwerb_datum`,
          type: "date",
          view: "v-alt",
          label: this.erwerbDatumLabel,
          required: true,
          editonly: true,
        },
        {
          id: "erwerb_datum_bis",
          htmlId: `${ this.htmlRegelNummer }_erwerb_datum_bis`,
          type: "date",
          view: "v-alt",
          label: "_LBL_GR_INVESTITIONSFOERDERUNG_ERWERB_ZEITRAUM_BIS_",
          required: false,
          editonly: true,
          hideIf: this.erwerbartDurchKaufvertrag,
        },
        {
          id: "einrichtungsart",
          htmlId: `${ this.htmlRegelNummer }_einrichtungsart`,
          type: "select",
          label: "_LBL_GR_INVESTITIONSFOERDERUNG_EINRICHTUNGSART_",
          required: true,
          view: "v-alt",
          editonly: true,
          data: this.einrichtungsartChoices,
          dataTranslate: true,
        },
      ];
      return OPTIONS;
    },

    labelHeader() {
      return "_TXT_GR_INVESTITIONSFOERDERUNG_HEADER_";
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },

    erwerbartDurchKaufvertrag() {
      return this.erwerbartModel === 1;
    },

    erwerbDatumLabel() {
      return this.erwerbartDurchKaufvertrag ? "_LBL_GR_INVESTITIONSFOERDERUNG_ERWERB_DATUM_" : "_LBL_GR_INVESTITIONSFOERDERUNG_ERWERB_ZEITRAUM_VON_";
    },

    getErwerbartChoices() {
      return ({ choice }) => {
        return choice ? this.erwerbartChoices[choice] : "_TXT_KEINE_ANGABE_";
      };
    },

    erwerbartRadioGroupName() {
      return `${ this.htmlRegelNummer }_radio`;
    },

    erwerbartKaufvertragId() {
      return `${ this.erwerbartRadioGroupName }_1`;
    },

    erwerbartMieteId() {
      return `${ this.erwerbartRadioGroupName }_2`;
    },

    erwerbartErbpachtId() {
      return `${ this.erwerbartRadioGroupName }_3`;
    },

    diffDatumAny() {
      return !(isUndefined(this.diff.erwerb_datum) && isUndefined(this.diff.erwerb_datum_bis));
    }
  },
  created() {
    this.initErwerbart();
  },
  methods: {
    initErwerbart() {
      this.erwerbartModel = this.model.erwerbart;
    },

    getEinrichtungsart({ choice }) {
      const EL = find(this.einrichtungsartChoices, ["id", choice]);
      return EL ? EL.label : "_TXT_KEINE_ANGABE_";
    },

    onErwerbartChange() {
      if (this.erwerbartDurchKaufvertrag) {
        this.model.erwerb_datum_bis = null;
      }
    },

    saveLocal({ model }) {
      const DATA = cloneDeep(model);
      DATA.erwerbart = this.erwerbartModel;
      return this.save({ model: DATA });
    },

    closeLocal() {
      this.initErwerbart();
      this.close();
    }
  }
};
