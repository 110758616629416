"use strict";

import angular from "angular";

import coreModels from "dias/core/models";
import Formular from "./formular.service";
import Formularvorlage from "./formularvorlage.service";

const modelsModule = angular.module("formular.models", [
  coreModels.name,
])

  .provider("Formular", Formular)
  .provider("Formularvorlage", Formularvorlage);

export default modelsModule;
