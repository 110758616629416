"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import "angular-chart.js";
import "angular-drag-and-drop-lists";

import diasAuthService from "dias/auth/services";
import diasConfirm from "dias/core/confirm";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";

import foerdermodule from "dias/foerderantrag/foerdermodule";
import foerderregeln from "dias/foerderregeln/models";

import models from "../models";
import regeln from "../regel";
import detailsComponent from "./details.component";


const projektberichtvorlagenModule = angular.module("root.projektberichte.vorlagen", [
  uiRouter,
  models.name,
  regeln.name,
  foerdermodule.name,
  foerderregeln.name,
  diasAuthService.name,
  diasConfirm.name,
  diasTable.name,
  diasLoading.name,
  diasNotification.name,
]);

projektberichtvorlagenModule.config($stateProvider => {
  $stateProvider

    // Sitzungs-Liste
    .state("root.projektberichtvorlagen", {
      url: "/projektberichtvorlagen/",
      views: {
        "main@root": {
          template: "<div vueinit><vue-projektbericht-vorlagen-list></vue-projektbericht-vorlagen-list></div>"
        }
      },
      data: {
        permissions: ["projektberichtvorlage.view"]
      },
    })

    // Detail-Seite einer Sitzung
    .state("root.projektberichtvorlagen.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<projektberichtvorlagen-details></projektberichtvorlagen-details>"
        }
      },
      data: {
        permissions: ["projektberichtvorlage.view"]
      },
    })

  ;
})

  .component("projektberichtvorlagenDetails", detailsComponent)
;

export default projektberichtvorlagenModule;
