"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import "restangular";

import errors from "dias/core/errors";
import notification from "dias/core/notification";

import AnonymisierungsplanFormstep from "./plan.formstep.component";

const anonPlanModule = angular.module("core.anonymisierung.plan", [
  "restangular",
  uiRouter,
  errors.name,
  notification.name
])

  .component("anonymisierungsplanFormstep", AnonymisierungsplanFormstep)
;

export default anonPlanModule;
