import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import AufgabeModalZuweisen from "../../AufgabeModalZuweisen/AufgabeModalZuweisen.vue";
import ManuelleAufgabenBoxWiedervorlageModal from "../ManuelleAufgabenBoxWiedervorlageModal/ManuelleAufgabenBoxWiedervorlageModal.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxDate from "../../../../global/components/PuxDate/PuxDate.vue";
import PuxGet from "../../../../global/components/PuxGet/PuxGet.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import TerminChange from "../../TerminChange/TerminChange.vue";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";
import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

import KaufgabenstatusUUIDs from "../../../../const/KaufgabenstatusUUIDs";

// @vue/component
export default {
  name: "ManuelleAufgabenBoxItem",
  components: {
    AsideRightBox,
    AufgabeModalZuweisen,
    ManuelleAufgabenBoxWiedervorlageModal,
    Modal,
    PuxButton,
    PuxDate,
    PuxGet,
    PuxTranslate,
    TerminChange,
  },
  props: {
    aufgabe: {
      type: Object,
      required: true,
    },
    aufgabeIndex: {
      type: Number,
      required: true,
    },
    prefixId: {
      type: String,
      required: true,
    },
  },
  emits: [
    "onAufgabenChanged",
  ],
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    const {
      addNotification,
      putHttp,
    } = HttpAPI();

    return {
      addNotification,
      filterDate,
      putHttp,
    };
  },
  data() {
    return {
      optionsAsideRightBox: {
        icon: "todos",
      },
      confirmShow: undefined,
      confirmOptions: undefined,
      isModalWiedervorlageVisible: undefined,
      isModalZuweisenVisible: undefined,
    };
  },
  computed: {
    idForAsideBox() {
      return `${ this.prefixId }_${ this.aufgabeIndex }`;
    },

    idForButtonStatus() {
      return `${ this.idForAsideBox }_btn_status`;
    },

    idForButtonChangeWiedervorlageTermin() {
      return `${ this.idForAsideBox }_btn_wiedervorlage`;
    },

    idForButtonAssignAufgabe() {
      return `${ this.idForAsideBox }_btn_assign_aufgabe`;
    },

    urlForAufgaben() {
      return "aktuelle_aufgaben/";
    },

    urlMarkDone() {
      return `${ this.urlForAufgaben }${ this.aufgabe.pk }/status_aendern/`;
    },

    selectorCloseForMarkDone() {
      return `#${ this.idForButtonStatus }`;
    },

    titleForButtonChangeWiedervorlageTermin() {
      return this.aufgabe.wiedervorlage ?
        "_BTN_MANUELLE_AUFGABEN_WIEDERVORLAGE_CHANGE_TITLE_" :
        "_BTN_MANUELLE_AUFGABEN_WIEDERVORLAGE_CREATE_TITLE_";
    },

    textForButtonChangeWiedervorlageTermin() {
      return this.aufgabe.wiedervorlage ?
        this.filterDate(this.aufgabe.wiedervorlage) :
        "_BTN_MANUELLE_AUFGABEN_WIEDERVORLAGE_CREATE_";
    },

    selectorCloseForWiedervorlage() {
      return `#${ this.idForButtonChangeWiedervorlageTermin }`;
    },

    selectorCloseForModalZuweisen() {
      return `#${ this.idForButtonAssignAufgabe }`;
    },
  },
  methods: {
    updateTermin() {
      this.$emit("onAufgabenChanged");
    },

    openConfirmMarkDone() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_MANUELLE_AUFGABEN_MARK_DONE_CONFIRM_HEADER_",
        msg: "_TXT_MANUELLE_AUFGABEN_MARK_DONE_CONFIRM_BODY_{{aufgabeKbez}}_",
        extra: {
          aufgabeKbez: this.aufgabe.kbez,
        },
        okLabel: "_BTN_FORTFAHREN_",
        okCallback: this.markDone,
        cancelCallback: this.closeConfirmMarkDone,
        loading: false,
      };
      this.confirmShow = true;
    },

    markDone() {
      this.confirmOptions.loading = true;
      this.putHttp({
        url: this.urlMarkDone,
        data: {
          status: KaufgabenstatusUUIDs.abgeschlossen
        },
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_MANUELLE_AUFGABEN_MARK_DONE_CONFIRM_SUCCESS_",
          });
          this.closeConfirmMarkDone();
          this.$emit("onAufgabenChanged");
        },
        () => this.addNotification({
          text: "_MSG_MANUELLE_AUFGABEN_MARK_DONE_CONFIRM_ERROR_",
          type: "error",
        })
      ).finally(
        () => this.confirmOptions.loading = false
      );
    },

    closeConfirmMarkDone() {
      this.confirmShow = false;
    },

    openModalChangeWiedervorlageTermin() {
      this.isModalWiedervorlageVisible = true;
    },

    closeModalChangeWiedervorlageTermin({ isSaved } = {}) {
      if (isSaved) {
        this.$emit("onAufgabenChanged");
      }
      this.isModalWiedervorlageVisible = false;
    },

    openModalZuweisen() {
      this.isModalZuweisenVisible = true;
    },

    closeModalZuweisen({ status } = {}) {
      if (status) {
        this.$emit("onAufgabenChanged");
      }
      this.isModalZuweisenVisible = false;
    },
  },
};
