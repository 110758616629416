import "jquery";

const actionKeys = [
  13, // Enter
  32, // Space
];

export function isActionKey(keyCode) {
  return keyCode === undefined || actionKeys.indexOf(keyCode) > -1;
}

jQuery(document).ready(function($) {
	// $('.chosen-select').chosen({disable_search_threshold: 10});

	// $('body').on('click.detail.data-api keypress.detail.data-api', '[data-toggle=detail]', function(e) {
  $("body").on("click.detail.data-api", "[data-toggle=detail]", function(e) {
    if (!isActionKey(e.keyCode)) {
      return;
    }
    if (e.target.type == "checkbox") {
      return true;
    }
    const $target = $(this).next(".detail");
    if (!$target.length) {
      return true;
    }
    const $glyphicon = $(this).find("span.glyphicon");
    if ($target.is(":visible")) {
      $target.hide();
      $glyphicon.removeClass("glyphicon-chevron-up").addClass("glyphicon-chevron-down");
      $(this).removeClass("opened");
      $target.removeClass("opened");
    } else {
      $target.show();
      $glyphicon.removeClass("glyphicon-chevron-down").addClass("glyphicon-chevron-up");
      $(this).addClass("opened");
      $target.addClass("opened");
    }
  });

	// Hover für data-toggle
  $("body").on("mouseenter.edit.data-api focusin.edit.data-api", "[data-toggle=edit]", function() {
    $(this).closest(".form-step-detail").addClass("edit-hover");
  });
  $("body").on("mouseleave.edit.data-api focusout.edit.data-api", "[data-toggle=edit]", function() {
    $(this).closest(".form-step-detail").removeClass("edit-hover");
  });

	// Hover für Workflow
  $("body").on("mouseenter.edit-dokumente.data-api focusin.edit-dokumente.data-api", "[data-toggle=edit-dokumente]", function() {
    $(this).parents(".detail").addClass("edit-hover");
  });
  $("body").on("mouseleave.edit-dokumente.data-api focusout.edit-dokumente.data-api", "[data-toggle=edit-dokumente]", function() {
    $(this).parents(".detail").removeClass("edit-hover");
  });

	// Hover für Kosten- & Finanzierung
  $("body").on("mouseenter.position-detail.data-api focusin.position-detail.data-api", "[data-toggle=position-detail]", function() {
    $(this).parents(".position").addClass("edit-hover");
  });
  $("body").on("mouseleave.position-detail.data-api focusout.position-detail.data-api", "[data-toggle=position-detail]", function() {
    $(this).parents(".position").removeClass("edit-hover");
  });


  $("body").on("mouseenter.edit.data-api focusin.edit.data-api", "[data-toggle=edit-position]", function() {
    $(this).parents(".position").addClass("edit-hover");
  });

  $("body").on("mouseleave.edit.data-api focusout.edit.data-api", "[data-toggle=edit-position]", function() {
    $(this).parents(".position").removeClass("edit-hover");
  });


  $("body").on("mouseenter.edit.data-api focusint.edit.data-api", "[data-toggle=edit-note]", function() {
    $(this).parents(".note").addClass("edit-hover note-hover");
  });

  $("body").on("mouseleave.edit.data-api focusout.edit.data-api", "[data-toggle=edit-note]", function() {
    $(this).parents(".note").removeClass("edit-hover note-hover");
  });

  $("body").on("click.info.data-api", "[data-toggle=info]", function() {
    const $target = $(this).parents(".form-step-detail").find(".info");
    if ($(this).attr("aria-expanded") === "true") {
      $(this).attr("aria-expanded", false);
    } else {
      $(this).attr("aria-expanded", true);
    }
    $(this).toggleClass("active");
    $target.toggle();
  });

  $("body").on("click.aside-detail.data-api", "[data-toggle=aside-detail]", function() {
    const parent = $(this).closest(".box");
    parent.find(".details-toggle:not(.opened)").each(function() {
      $(this).trigger("click");
    });
  	$("#btn-aside").trigger("click");
  });

  $("body").on("click.aside.data-api", "[data-toggle=aside]", function() {
   	const icon = $(this).find(".glyphicon");
   	if (icon.hasClass("glyphicon-einreichen")) {
      icon.attr("title", "Seitenleiste einblenden");
    } else {
      icon.attr("title", "Seitenleiste ausblenden");
    }
    icon.toggleClass("glyphicon-einreichen glyphicon-zurueckreichen");
   	$("section.main-dias").toggleClass("uncollapsed");
    $("aside.fixed").toggleClass("collapsed");
  });


	// Toggle für die Regel-Infos von Kosten und Finanzierung
	// $('body').on('click.info.data-api keypress.info.data-api', '[data-toggle=kofiinfo]',function(e){
  $("body").on("click.info.data-api", "[data-toggle=kofiinfo]", function(e) {
    if (!isActionKey(e.keyCode)) {
      return;
    }
    const $target = $(this).parents(".position").siblings(".info");
    $target.toggle();
  });


	// Toggle für die Kosten- & Finanzierung-Positionen:
	// $('body').on('click.position-detail.data-api keypress.position-detail.data-api', '[data-toggle=position-detail]',function(e){
  $("body").on("click.position-detail.data-api", "[data-toggle=position-detail]", function(e) {
    if (!isActionKey(e.keyCode)) {
      return;
    }
    const $target = $(this).parents(".position").siblings(".position-detail");
    $target.toggle();
    const $children = $(this).children("span");
    $children.toggleClass("open");
    $children.toggleClass("closed");
  });

	// Toggle für alle die Kosten- & Finanzierung-Positionen in Vertrag:
	// $('body').on('click.all-position-detail.data-api keypress.all-position-detail.data-api', '[data-toggle=all-position-detail]',function(e){
  $("body").on("click.all-position-detail.data-api", "[data-toggle=all-position-detail]", function(e) {
    if (!isActionKey(e.keyCode)) {
      return;
    }
    const $target = $(this).parents(".form-step-detail").find(".position-detail");
    $target.toggle();
    const $children = $(this).children("span");
    $children.toggleClass("open");
    $children.toggleClass("closed");
  });

	// Toggle für den Bericht der eingegangenen Anträge:
	// $('body').on('click.bericht-detail.data-api keypress.bericht-detail.data-api', '[data-toggle=bericht-detail]',function(e){
  $("body").on("click.bericht-detail.data-api", "[data-toggle=bericht-detail]", function(e) {
    if (!isActionKey(e.keyCode)) {
      return;
    }
    const $target = $(this).parents(".position").siblings(".bericht-detail");
    $target.toggle();
    const $children = $(this).children("span");
    $children.toggleClass("open");
    $children.toggleClass("closed");
  });

	// Für form-step

  $("body").on("click.inactive-all.data-api", "[data-toggle=inactive-all]", function() {
    $("body").find(".form-step").removeClass("active");
    $("aside ul.overview").find("li").removeClass("active");
  });
  $("body").on("click.active-all.data-api", "[data-toggle=active-all]", function() {
    $("body").find(".form-step").addClass("active");
    $("aside ul.overview").find("li").addClass("active");
  });

  $("input[type=checkbox].switch-all").on("click keypress", function(e) {
    if (!isActionKey(e.keyCode)) {
      return;
    }
    $(this).parents("table").find("tbody input[type=checkbox]").prop("checked", $(this).prop("checked"));
  });
});
