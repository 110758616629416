import InstrumentvariantenModalCreate from "../InstrumentvariantenModalCreate/InstrumentvariantenModalCreate.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import SmartTable from "../../../global/components/table/table.vue";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "instrumentvariante";

// @vue/component
export default {
  name: "InstrumentvariantenList",
  components: {
    InstrumentvariantenModalCreate,
    PageTabTitle,
    SmartTable,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_LBL_INSTRUMENTVARIANTEN_LIST_ACTION_ROW_DETAILS_",
            route: "root.foerderinstrumentvarianten.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [
          {
            label: "_LBL_INSTRUMENTVARIANTEN_LIST_ERSTELLEN_",
            callback: () => this.modal.create = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "instrumentvariante_create",
          },
        ],
        label: "_LBL_INSTRUMENTVARIANTE_",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      modal: {
        create: undefined,
      },
    };
  },
  methods: {
    closeModalCreate() {
      this.modal.create = false;
    },
  },
};
