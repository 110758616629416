import {
  toRef,
} from "vue";

import AngularLink from "../../../../../../../libs/vue/client/vue/global/components/AngularLink/AngularLink.vue";
import DokumenteSimpleList from "../../../../../../../libs/vue/client/vue/components/Dokumente/DokumenteSimpleList/DokumenteSimpleList.vue";
import FormstepDetail from "../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";
import FormElement from "../../../../../../../libs/vue/client/vue/global/components/FormElement/FormElement.vue";
import Spaced from "../../../../../../../libs/vue/client/vue/global/components/Spaced/Spaced.vue";

import AkkreditierungDetailsAPI from "../../../compositionAPI/AkkreditierungDetailsAPI";
import FiltersAPI from "../../../../../../../libs/vue/client/vue/global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "SystemakkreditierungDetailsAkkreditierung",
  components: {
    AngularLink,
    DokumenteSimpleList,
    FormstepDetail,
    FormElement,
    Spaced,
  },
  props: {
    akkreditierung: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
    canViewComment: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    "onSave",
  ],
  setup(props, { emit }) {
    const {
      filterBoolean,
      filterDate,
    } = FiltersAPI();

    const {
      akkreditierungLocal,
      akkreditierungProJointDegree,
      errors,
      formstepDetailOptions,
      htmlId,
      initModel,
      optionsList,
      save,
      showAuflagen,
    } = AkkreditierungDetailsAPI({
      emit,
      akkreditierung: toRef(props, "akkreditierung"),
      baseUrl: "systemakkreditierungen",
    });

    initModel();

    return {
      akkreditierungLocal,
      akkreditierungProJointDegree,
      errors,
      formstepDetailOptions,
      htmlId,
      initModel,
      optionsList,
      save,
      showAuflagen,

      filterBoolean,
      filterDate,
    };
  },
};
