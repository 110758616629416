"use strict";

import angular from "angular";

import i18n from "./i18n.service";

const services = angular.module("dias.i18n.services", [])

  .service("i18n", i18n)
;

export default services;
