"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";


const parametermodule = angular.module("dias.foerderregeln.parameter.vertragFilter", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("vertrag_filter", "parametermodul-json");
  })
;
export default parametermodule;
