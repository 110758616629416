import AsideRightBox from "../../../global/components/AsideRightBox/AsideRightBox.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import FaqAdminImagesDND from "./FaqAdminImagesDND/FaqAdminImagesDND.vue";
import FaqAdminInfo from "./FaqAdminInfo/FaqAdminInfo.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../global/compositionAPI/NotificationAPI";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "FaqAdminDetails",
  components: {
    AsideRightBox,
    ContextPanel,
    FaqAdminImagesDND,
    FaqAdminInfo,
    Modal,
    Permission,
    PuxButton,
    PuxPageDetails,
    PuxTranslate,
  },
  setup() {
    const {
      deleteHttp,
      getHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      deleteHttp,
      getHttp,
    };
  },
  data() {
    return {
      loading: true,
      faq: undefined,
      confirmShow: undefined,
      confirmOptions: undefined,
      optionsAsideBox: {
        name: "_TXT_FAQ_ADMIN_DETAILS_ASIDE_BILDER_HEADER_",
        active: true,
        icon: "glyphicon-eye",
      },
      panelOptions: {
        label: "_TXT_FAQ_ADMIN_DETAILS_FAQ_PANEL_NAME_",
        icon: "glyphicon-user",
        active: true,
      },
    };
  },
  computed: {
    baseUrl() {
      return `faqs/${ this.$stateParams.id }/`;
    },
  },
  created() {
    this.loadFaq();
  },
  methods: {
    loadFaq() {
      this.getHttp({
        url: this.baseUrl,
      }).then(
        response => {
          this.updateFaq({ faq: response });
        }
      ).then(
        () => this.loading = false
      );
    },

    updateFaq({ faq }) {
      this.faq = cloneDeep(faq);
    },

    deleteFaq({ faq }) {
      this.confirmOptions.loading = true;
      this.deleteHttp({
        url: `faqs/${ faq.pk }/`,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_FAQ_ADMIN_DETAILS_DELETE_{{frage}}_",
            extra: {
              frage: this.faq.fqe_frage,
            },
          });
          this.closeConfirmFaq();
          this.$goTo("root.adminfaqs");
        }
      ).then(
        () => this.confirmOptions.loading = false
      );
    },

    openModalDeleteFaq({ faq }) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_FAQ_ADMIN_DETAILS_DELETE_MODAL_HEADER_",
        msg: "_TXT_FAQ_ADMIN_DETAILS_DELETE_MODAL_BODY_{{frage}}_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteFaq({ faq }),
        cancelCallback: this.closeConfirmFaq,
        extra: {
          frage: this.faq.fqe_frage,
        },
      };
      this.confirmShow = true;
    },

    closeConfirmFaq() {
      this.confirmShow = false;
    },
  },
};
