import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTooltip from "../../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  initModelFromList,
  toFormElementFromParameter
} from "../../../../global/functions/mappingForParameterToFormElement";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

// @vue/component
import {
  concat,
  get,
  forEach,
} from "lodash-es";

export default {
  name: "WorkflowfolgeclientfunktionenDetailsKonfiguration",
  components: {
    FormElement,
    FormElementReadOnly,
    FormstepDetail,
    PuxIcon,
    PuxTooltip,
    PuxTranslate,
  },
  props: {
    clientfunktionen: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    infoTexts: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      getListHttp,
      patchHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      checkPermissionsSync,
      getListHttp,
      patchHttp,
      addNotification,
    };
  },
  data() {
    return {
      htmlId: "workflowfolgeclientfunktionenen_details_konfiguration",
      model: {
        clientfunktion: undefined,
        wfc_bez: undefined,
        wfc_parameterin: {},
        wfc_parameterout: {},
      },
      clientfunktionModule: [],
      selectedClientfunktionModul: {},
      loading: false,
      errors: {},
      formStepOptions: {
        label: "_TXT_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_KONFIGURATION_",
        openCallback: this.open,
        saveCallback: this.save,
        closeCallback: this.close,
      },
    };
  },
  computed: {
    optionsForWfcBez() {
      const ID = "wfc_bez";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        label: "_TXT_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_NAME_",
        tooltip: this.infoTexts.bez,
        showLabel: true,
        required: true,
        view: "v-alt",
      };
    },

    optionsForClientfunktion() {
      const ID = "clientfunktion";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "select",
        label: "_TXT_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_MODUL_",
        tooltip: this.infoTexts.modul,
        showLabel: true,
        keyLabel: "bez",
        keyId: "pk",
        search: true,
        searchList: ["bez, wfc_bez"],
        data: this.clientfunktionModule,
        change: this.onChangeClientfuktionModul,
        required: true,
        view: "v-alt",
      };
    },

    optionsForParameterIn() {
      return toFormElementFromParameter({
        obj: this.selectedClientfunktionModul.wcf_parameterin,
        showLabel: true,
        htmlIdPrefix: this.htmlId,
        staticOptions: {
          view: "v-alt",
        },
      }).list || [];
    },

    optionsForParameterOut() {
      return toFormElementFromParameter({
        obj: this.selectedClientfunktionModul.wcf_parameterout,
        htmlIdPrefix: this.htmlId,
        showLabel: true,
        staticOptions: {
          view: "v-alt",
        },
      }).list || [];
    },

    formElementOptionsList() {
      return [
        this.optionsForWfcBez,
        this.optionsForClientfunktion,
      ];
    },

    errorsLabelsOptions() {
      return concat(this.formElementOptionsList, this.optionsForParameterIn, this.optionsForParameterOut);
    },

    editable() {
      return this.checkPermissionsSync({ perm: "clientfunktion.update" });
    }
  },
  created() {
    this.loadClientfunktionModule();
  },
  methods: {
    open() {
      this.model.clientfunktion = this.clientfunktionen.clientfunktion;
      this.model.wfc_bez = this.clientfunktionen.wfc_bez;
      this.setModel();
    },

    loadClientfunktionModule() {
      this.loading = false;
      this.getListHttp({
        url: "katalog/",
        urlParams: {
          key: "clientfunktion_module",
        },
        apiSaveId: "clientfunktion_module",
      }).then(
        response => {
          this.clientfunktionModule = response;
          this.setSelectedClientfunktionModul({ id: this.clientfunktionen.clientfunktion });
        }
      ).finally(
        () => this.loading = false,
      );
    },

    setSelectedClientfunktionModul({ id }) {
      this.selectedClientfunktionModul = this.clientfunktionModule.find(element => element.id === id);
    },

    setModel() {
      const PARAMETER_IN = {};
      forEach(initModelFromList({ list: this.optionsForParameterIn }), (value, key) => {
        PARAMETER_IN[key] = { wert: get(this.clientfunktionen, `wfc_parameterin[${ key }].wert`, null) };
      });
      this.model.wfc_parameterin = PARAMETER_IN;
      const PARAMETER_OUT = {};
      forEach(initModelFromList({ list: this.optionsForParameterOut }), (value, key) => {
        PARAMETER_OUT[key] = { wert: get(this.clientfunktionen, `wfc_parameterout[${ key }].wert`, null) };
      });
      this.model.wfc_parameterout = PARAMETER_OUT;
    },

    onChangeClientfuktionModul({ item }) {
      this.setSelectedClientfunktionModul({ id: item.pk });
      this.setModel();
    },

    close() {
      this.setSelectedClientfunktionModul({ id: this.clientfunktionen.clientfunktion });
      this.errors = {};
    },

    save() {
      const DATA = {};
      DATA.clientfunktion = this.model.clientfunktion;
      DATA.wfc_bez = this.model.wfc_bez;
      forEach(this.model.wfc_parameterin, (value, key) => {
        DATA[key] = value.wert;
      });
      forEach(this.model.wfc_parameterout, (value, key) => {
        DATA[key] = value.wert;
      });
      return this.patchHttp({
        url: this.baseUrl,
        data: DATA,
      }).then(
        response => {
          this.addNotification({
            text: "_MSG_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_UPDATE_SUCCESSFUL_",
          });
          this.$emit("update-clientfunktionen", { clientfunktionen: response });
          return Promise.resolve();
        },
        errors => {
          this.onErrors(errors);
          return Promise.reject(errors);
        },
      );
    },

    onErrors(errors) {
      if (errors.data) {
        this.errors = errors.data;
      } else {
        this.errors = errors;
      }
    },
  },
};
