import BetragsrechnerMixin from "../../Mixins/BetragsrechnerMixin";
import DvvVeranstaltungsrechnerMixin from "../DvvVeranstaltungsrechnerMixin";

// @vue/component
export default {
  name: "DvvVeranstaltungsrechnerEinfach",
  mixins: [
    BetragsrechnerMixin,
    DvvVeranstaltungsrechnerMixin,
  ],
  data() {
    return {
      labelTeilnehmertage: "Teilnehmer*innentage",
      labelHonorartage: "Tage, an denen Honorare anfallen",
    };
  },
  computed: {
    optionsTeilnehmertage() {
      return {
        id: "teilnehmertage",
        htmlId: this.htmlIdTeilnehmertage,
        label: this.labelTeilnehmertage,
        view: this.view,
        type: "text",
        required: true,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdTeilnehmertage() {
      return `${ this.htmlId }_teilnehmertage`;
    },

    optionsHonorartage() {
      return {
        id: "honorartage",
        htmlId: this.htmlIdHonorartage,
        label: this.labelHonorartage,
        view: this.view,
        type: "text",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdHonorartage() {
      return `${ this.htmlId }_honorartage`;
    },

    errorLabels() {
      return {
        teilnehmertage: {
          label: this.labelTeilnehmertage,
          link: true,
          id: this.htmlIdTeilnehmertage,
        },
        honorartage: {
          label: this.labelHonorartage,
          link: true,
          id: this.htmlIdHonorartage,
        },
        teilnehmer_uebernachtung: {
          label: this.labelTeilnehmerUebernachtung,
          link: true,
          id: this.htmlIdTeilnehmerUebernachtung,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
  },
};
