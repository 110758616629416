"use strict";

import BaseModel from "dias/core/models/baseModel";

class Vertreter extends BaseModel {
  constructor() {
    super("vertretungsberechtigte");
    // todo: em delete
    this.berechtigtChoices = [
      { value: true, label: "Alleinige Vertretungsberechtigung" },
      { value: false, label: "Gemeinsame Vertretungsberechtigung" }
    ];
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    const choicesCopy = angular.copy(this.berechtigtChoices);
    collection.getBerechtigtChoices = () => choicesCopy;

    return collection;
  }

  extendModel(model) {
    model = super.extendModel(model);

    model.berechtigtChoices = angular.copy(this.berechtigtChoices);

    model.getBerechtigungsLabel = () => {
      if (model.berechtigung_bez) {
        return model.berechtigung_bez;
      } else if (model.vertreter_obj && model.vertreter_obj.berechtigung_bez) {
        return model.vertreter_obj.berechtigung_bez;
      }
      return "Berechtigung Unbekannt";
    };

    return model;
  }
}

export default Vertreter;
