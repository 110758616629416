import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import ModelSerializerModulMixin from "../ModelSerializerModulMixin/ModelSerializerModulMixin.js";

import {
  cloneDeep,
  concat,
  filter,
  find,
  forEach,
  forEachRight,
  get,
  groupBy,
  isNil,
  keys,
  keyBy,
  map,
  orderBy,
  pick,
  size,
  uniq,
} from "lodash-es";

// @vue/component
export default {
  name: "AstOrganisationszuordnungen",
  components: {
    FormElement,
    FormElementReadOnly,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    ModelSerializerModulMixin,
  ],
  data: () => {
    return {
      modelEdit: undefined,
      loadingEditData: false,
      loadingReadonlyData: false,
      orgs: undefined,
      orgsMap: undefined,
      orgsOtMap: undefined,
      orgsOtMapFiltered: undefined,
      orgtypen: undefined,
      otFormFields: undefined,
      annehmendeOrgOptions: {
        type: "radio",
        id: "annehmende_foerderorg",
        label: "_LBL_AST_ORGANISATIONSZUORDNUNGEN_FIELD_ANNEHMENDE_",
        helpText: "_TXT_AST_ORGANISATIONSZUORDNUNGEN_FIELD_ANNEHMENDE_HELP_TEXT_",
        keyId: "pk",
        keyLabel: "o_name",
        view: "v-alt"
      },
      annehmendeOrgData: [],
      errorsClone: {},
    };
  },
  computed: {
    formstepOptions() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.openEditMode;
      return OPTIONS;
    },

    modelReadonly() {
      const MODEL = {};
      forEach(this.orgtypen, ot => {
        const OT_ORG = find(get(this.model, "foerderorghierarchie"), o => o.o_otid === ot.pk);
        MODEL[ot.pk] = get(OT_ORG, "pk");
      });
      MODEL.annehmende_foerderorg = get(this.model, "annehmende_foerderorg");
      return MODEL;
    },

    snapshotReadonly() {
      const MODEL = {};
      forEach(this.orgtypen, ot => {
        const OT_ORG = find(get(this.snapshotModule, "foerderorghierarchie"), o => o.o_otid === ot.pk);
        MODEL[ot.pk] = get(OT_ORG, "pk");
      });
      if (this.canViewAnnehmendeOrg) {
        MODEL.annehmende_foerderorg = get(this.snapshotModule, "annehmende_foerderorg");
      }
      return MODEL;
    },

    snapshotModuleExtra() {
      return {
        orgs: get(this.model, "foerderorghierarchie")
      };
    },

    snapshotModuleExtraSnapshot() {
      return {
        orgs: get(this.snapshotModule, "foerderorghierarchie")
      };
    },

    annehmendeOrgField() {
      return find(this.formFields, field => field.id === "annehmende_foerderorg");
    },

    canEditAnnehmendeOrg() {
      return !get(this.annehmendeOrgField, "readonly", true);
    },

    canViewAnnehmendeOrg() {
      return !isNil(this.annehmendeOrgField);
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        const KEYS = uniq(concat(keys(this.modelReadonly), keys(this.snapshotReadonly)));
        forEach(KEYS, key => {
          if (get(this.modelReadonly, key) !== get(this.snapshotReadonly, key)) {
            DIFF[key] = true;
            return;
          }
          const MODEL_ORG = find(get(this.model, "foerderorghierarchie", []), el => el.pk === get(this.modelReadonly, key));
          const SNAPSHOT_ORG = find(get(this.snapshotModule, "foerderorghierarchie", []), el => el.pk === get(this.modelReadonly, key));
          if (get(MODEL_ORG, "o_name") !== get(SNAPSHOT_ORG, "o_name")) {
            DIFF[key] = true;
            return;
          }
        });
      }
      return DIFF;
    },
  },
  created() {
    this.loadReadonlyData();
  },
  methods: {
    openEditMode() {
      this.modelEdit = cloneDeep(this.modelReadonly);
      this.loadEditData();
    },

    loadReadonlyData() {
      this.loadingReadonlyData = true;
      this.getListHttp({
        url: "katalog/",
        urlParams: {
          key: "foerderorgtyp",
        },
        apiSaveId: "katalog/?key=foerderorgtyp"
      }).then(
        response => this.prepareOrgtypen(response)
      ).finally(() => this.loadingReadonlyData = false);
    },

    loadEditData() {
      if (!isNil(this.orgs)) {
        this.updateDataMaps();
        return;
      }
      this.loadingEditData = true;
      this.getListHttp({
        url: "katalog/",
        urlParams: {
          key: "foerderorganisation",
          o_oeffentlich: true,
          typ: map(this.orgtypen, ot => ot.pk),
        },
        apiSaveId: "katalog/?key=foerderorganisation&o_oeffentlich=True"
      }).then(
        response => this.prepareOrgs(response)
      ).finally(() => this.loadingEditData = false);
    },

    prepareOrgs(os) {
      this.orgs = os;
      this.orgsMap = keyBy(os, "pk");
      this.orgsOtMap = groupBy(os, "typ");
      this.updateDataMaps();
    },

    prepareOrgtypen(ots) {
      this.orgtypen = orderBy(ots, ["ot_pos", "bez"]);
      this.otFormFields = map(ots, ot => {
        return {
          type: "select",
          id: ot.pk,
          label: ot.bez,
          showLabel: true,
          view: "v",
          keyId: "pk",
          keyLabel: "o_name",
          prio: ot.ot_pos,
          slot: "selectelement",
          search: true,
          searchList: ["o_name"],
          change: this.updateSelectedOrg
        };
      });
    },

    updateSelectedOrg({ options }) {
      const CHANGED_POS = options.prio;
      forEach(this.otFormFields, field => {
        if (field.prio > CHANGED_POS) {
          if (this.modelEdit[field.id] !== this.modelEdit.annehmende_foerderorg) {
            this.modelEdit.annehmende_foerderorg = undefined;
          }
          this.modelEdit[field.id] = undefined;
        }
      });
      this.updateDataMaps();
    },

    updateDataMaps() {
      this.updateOrgsOtMap();
      this.updateAnnehmendeOrgData();
    },

    updateOrgsOtMap() {
      const ORGS_OT_MAP_FILTERED = {};
      forEach(this.otFormFields, (field, idx) => {
        if (idx === 0) {
          ORGS_OT_MAP_FILTERED[field.id] = this.orgsOtMap[field.id];
        } else {
          ORGS_OT_MAP_FILTERED[field.id] = filter(
            this.orgsOtMap[field.id],
            org => !isNil(find(this.modelEdit, selected => selected === org.uebergeordnete_org))
          );
          if (size(ORGS_OT_MAP_FILTERED[field.id]) === 0) {
            ORGS_OT_MAP_FILTERED[field.id] = undefined;
          }
        }
      });
      this.orgsOtMapFiltered = ORGS_OT_MAP_FILTERED;
    },

    updateAnnehmendeOrgData() {
      const ANNEHMENDE_ORG_DATA = [];
      forEach(this.otFormFields, field => {
        if (isNil(this.modelEdit[field.id]) || !get(this.orgsMap, [this.modelEdit[field.id], "o_antragsannahme"], false)) {
          return;
        }
        ANNEHMENDE_ORG_DATA.push(pick(this.orgsMap[this.modelEdit[field.id]], ["pk", "o_name"]));
      });
      this.annehmendeOrgData = ANNEHMENDE_ORG_DATA;
    },

    prepareToSave({ data }) {
      const SAVE_DATA = {};
      if (!isNil(data.annehmende_foerderorg)) {
        SAVE_DATA.annehmende_foerderorg = data.annehmende_foerderorg;
      }
      forEachRight(this.otFormFields, field => {
        if (!isNil(data[field.id])) {
          SAVE_DATA.zugehoerige_foerderorg = data[field.id];
          return false;
        }
      });
      return SAVE_DATA;
    },

    onError({ errors }) {
      this.errors = errors.data;
      this.errorsClone = cloneDeep(this.errors) || {};
      return errors;
    },

    close() {
      this.errors = undefined;
      this.errorsClone = {};
    },

    setLocalErrorsFromGlobal({ errors }) {
      this.errorsClone = errors;
    },
  },
};
