"use strict";

import "restangular";
import angular from "angular";
import CoreModels from "dias/core/models";

import TempAntraegeEigene from "./tempAntraegeEigene.service";

const modelsModule = angular.module("check-temp-antraege.models", [
  "restangular",
  CoreModels.name
])

  .provider("TempAntraegeEigene", TempAntraegeEigene)
;

export default modelsModule;
