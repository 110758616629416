var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group"] = jade_interp = function(errors, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' : vm.errors['" + (errors) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (errors) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (errors) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<script type=\"text/ng-template\" id=\"aspektReadonly.html\"><fieldset ng-if=\"formstep.detailsShowStatus\"><dl class=\"dl-horizontal\"><dt>Titel</dt><dd>{{ model.ask_titel }}</dd><dt>Kurzbeschreibung</dt><dd ng-bind-html=\"model.ask_kurzbeschreibung\"></dd><dt>Beschreibung</dt><dd ng-bind-html=\"model.ask_beschreibung\"></dd></dl></fieldset></script><div dias-cloak=\"vm.loading.aspekte\"><div ng-if=\"!vm.aspekte.length\" class=\"form-step-detail\"><h4>{{ vm.gettext(\"Aspekt\") }}</h4><fieldset><div class=\"row\"><div class=\"col-12 col-sm-12\">Noch keine {{ vm.gettext(\"Aspekte\") }} hochgeladen</div></div></fieldset></div><div ng-repeat=\"aspekt in vm.aspekte\"><formstep-detail formstep-editable=\"vm.aspekteEditable\" formstep-edit-controller=\"vm.aspektEditController\" formstep-edit-template=\"vm.aspektEditTemplate()\" formstep-readonly-show-status=\"true\" formstep-readonly-template-url=\"aspektReadonly.html\" formstep-title=\"{{aspekt.ask_titel}}\" ng-model=\"aspekt\"></formstep-detail></div></div><div ng-if=\"!vm.newAspektStatus &amp;&amp; vm.aspekteEditable\" class=\"form-step-detail borderless\"><button ng-click=\"vm.toggleNewAspekt()\" ng-attr-title=\"{{ vm.gettext('Aspekt') }} hinzufügen\" class=\"btn btn-default btn-block\">{{ vm.gettext(\"Aspekt\") }} hinzufügen</button></div><div dias-loading=\"vm.loading.newAspekt\" ng-if=\"vm.newAspektStatus\"><div class=\"form-step-detail edit\"><h4>Neuen {{ vm.gettext(\"Aspekt\") }}</h4><div class=\"actions\"><button data-toggle=\"edit\" ng-click=\"vm.toggleNewAspekt()\" class=\"btn btn-icon\"><span><i class=\"glyphicon glyphicon-remove\"></i><span>Schließen</span></span></button></div><div class=\"stretch\"><fieldset><div ng-attr-id=\"{{ vm.DiasNotification.form.containerId }}\"></div><div class=\"row\"><div class=\"col-12 col-sm-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"ask_titel\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.ask_titel ? 'true' : 'false'}}\" name=\"ask_titel\" ng-model=\"vm.modelNew.ask_titel\" type=\"text\" class=\"form-control\">");
}
}, "ask_titel", "Titel*");
buf.push("</div></div><div class=\"row\"><div class=\"col-12 col-sm-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<textarea id=\"ask_kurzbeschreibung\" dias-richtext ng-attr-aria-invalid=\"{{vm.errors.ask_kurzbeschreibung ? 'true' : 'false'}}\" name=\"ask_kurzbeschreibung\" ng-model=\"vm.modelNew.ask_kurzbeschreibung\" type=\"text\" class=\"form-control\"></textarea>");
}
}, "ask_kurzbeschreibung", "Kurzbeschreibung");
buf.push("</div></div><div class=\"row\"><div class=\"col-12 col-sm-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<textarea id=\"ask_beschreibung\" dias-richtext ng-attr-aria-invalid=\"{{vm.errors.ask_beschreibung ? 'true' : 'false'}}\" name=\"ask_beschreibung\" ng-model=\"vm.modelNew.ask_beschreibung\" type=\"text\" class=\"form-control\"></textarea>");
}
}, "ask_beschreibung", "Beschreibung*");
buf.push("</div></div></fieldset><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"vm.createNewAspekt()\" class=\"btn btn-primary test-save\">Speichern</button><button ng-click=\"vm.toggleNewAspekt()\" class=\"btn btn-icon\">Abbrechen</button></div></div></div></div></div>");;return buf.join("");
}