import PuxIcon from "../../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTooltip from "../../../../../../global/components/PuxTooltip/PuxTooltip.vue";
import Spinner from "../../../../../../global/components/Spinner/Spinner.vue";
import SnapshotIcon from "../../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import translate from "../../../../../../global/directives/translate";
import isFilter from "../../../../../../global/filters/IsFilter";
import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "AnlagenAllgemeinOneDocumentTd",
  components: {
    PuxIcon,
    PuxTooltip,
    Spinner,
    SnapshotIcon,
  },
  directives: {
    translate,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    tdClass: {
      type: String,
      required: false,
      default: undefined,
    },
    toggleDetailsLocal: {
      type: Function,
      default: undefined,
    },
    snapshotDocument: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    statusDiff: { // changes
      type: Boolean,
      required: true,
    },
    tooltipId: {
      type: String,
      required: false,
      default: undefined,
    },
    infoTextDokart: {
      type: String,
      required: false,
      default: undefined,
    }
  },
  computed: {
    dataWithKey() {
      return this.getDataWithKey({ object: this.document, key: this.options.key });
    },

    dataWithKey2() {
      if (this.options.key2) {
        return this.getDataWithKey({ object: this.document, key: this.options.key2 });
      }
      return undefined;
    },

    dataWithKeySnapshot() {
      return this.getDataWithKey({ object: this.snapshotDocument, key: this.options.key });
    },

    dataWithKey2Snapshot() {
      return this.getDataWithKey({ object: this.snapshotDocument, key: this.options.key2 });
    },

    tdClassLocal() {
      return [
        this.tdClass,
        {
          "p-0": this.statusDiff,
        },
      ];
    },

    liClass() {
      if (this.statusDiff) {
        return "py-2 px-3";
      }
      return undefined;
    },
  },
  methods: {
    getDataWithKey({ object, key }) {
      const DATA_KEY = get(object, key);
      if (this.options.filter) {
        return isFilter(DATA_KEY, this.options.filter, this.options.filterParameter);
      }
      return DATA_KEY;
    },
  },
};
