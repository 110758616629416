"use strict";

import template from "./overview.jade";
import ansprechpartnerZuweisenTemplate from "./ansprechpartner.zuweisen.modal.jade";
import ansprechpartnerZuweisenController from "./ansprechpartner.zuweisen.modal.controller";
import changeTeamTemplate from "./team.change.modal.jade";
import changeTeamController from "./team.change.modal.controller";

class FoerderantragOverviewController {
  /*@ngInject*/
  constructor(
    Foerderantrag,
    KommunikationsdatenUUIDs,
    KNutzerstatusUUIDs,
    diasConfirmDialog,
    DiasNotification,
    diasModalDialog,
    i18n,
    SyConfigs
  ) {
    this.gettext = i18n.gettext;
    this.KommunikationsdatenUUIDs = KommunikationsdatenUUIDs;
    this.KNutzerstatusUUIDs = KNutzerstatusUUIDs;
    this.diasConfirmDialog = diasConfirmDialog;
    this.DiasNotification = DiasNotification;
    this.diasModalDialog = diasModalDialog;
    this.monetaere_foerderung = SyConfigs.wert("monetaere_foerderung");
    this.docAnzeige = "2f413911-a316-c179-e050-007f0101705e";
    this.dokAnzeigeAblehnungsschreiben = "9807cf97-95a2-4b10-b78a-44b01325f627";
    // Wenn nicht direkt vom Server erhalten: Restanguarlize
    if (angular.isObject(this.antrag) && !this.antrag.restangularized) {
      Foerderantrag.restangularizeElement(this.antrag);
    }
  }

  assignAnsprechpartnerMe(asp) {
    this.diasConfirmDialog({
      title: "Ansprechpartner mir zuweisen",
      content: "Wollen Sie Ansprechpartner des Antrags für die Organisation \"" + asp.org.o_name + "\" werden?",
      okCallback: () => {
        return this.antrag.customPOST(
          { foerderorg: asp.org.pk, nutzer: this.user.pk },
          "ansprechpartner_aendern"
        ).then(
          response => {
            this.DiasNotification.page.success("Ansprechpartner zugewiesen.");
            this.antrag.foerderorg_ansprechpartner = response.foerderorg_ansprechpartner;
          },
          () => this.DiasNotification.page.error("Fehler beim Zuweisen des Antragstellers.")
        );
      }
    });
  }

  assignAnsprechpartner(fsp) {
    return this.diasModalDialog({
      title: "Ansprechpartner zuweisen",
      template: ansprechpartnerZuweisenTemplate,
      controller: ansprechpartnerZuweisenController,
      extras: {
        // reloadPage: (response) => this.antrag.foerderorg_ansprechpartner = response.foerderorg_ansprechpartner,
        antrag_id: this.antrag.pk,
        antrag: this.antrag,
        ansprechpartner: fsp,
      },
    });
  }

  closeAssignAnsprechpartner() {
    this.$scope.$applyAsync(
      () => {
        this.aktueller_fsp = null;
      }
    );
  }

  changeTeam() {
    return this.diasModalDialog({
      title: "Team ändern",
      template: changeTeamTemplate,
      controller: changeTeamController,
      extras: {
        antrag_id: this.antrag.pk,
        antrag: this.antrag,
      },
    });
  }
}

export default {
  template: template(),
  controller: FoerderantragOverviewController,
  controllerAs: "vm",
  bindings: {
    antrag: "=",
    user: "<",
  }
};
