"use strict";

import BaseModel from "dias/core/models/baseModel";

class FAQsRubriken extends BaseModel {
  constructor() {
    super("faqrubriken", {
      subPaths: {
        eintraege: "eintraege"
      }
    });
  }
}

export default FAQsRubriken;
