import ModuleMixin from "../ModuleMixin";
import Alert from "../../../../global/components/Alert/Alert.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import {
  scrollToGlobal,
} from "../../../../global/functions/help";
import DynamischeTabelleModalCreateOrUpdate
  from "./DynamischeTabelleModalCreateOrUpdate/DynamischeTabelleModalCreateOrUpdate.vue";
import DynamischeTabelleTr from "./DynamischeTabelleTr/DynamischeTabelleTr.vue";
import DynamischeTabelleFooter from "./DynamischeTabelleFooter/DynamischeTabelleFooter.vue";
import {
  initModelFromList,
  toFormElementFromParameter,
} from "../../../../global/functions/mappingForParameterToFormElement";


import {
  assign,
  cloneDeep,
  forEach,
  orderBy,
  size,
  find,
  filter,
  keyBy,
  isFunction,
  values,
  get,
  isObject,
  isEqual,
  debounce,
  findIndex,
  omitBy,
} from "lodash-es";

// @vue/component
export default {
  name: "StandardFoerdermoduleDynamischeTabelle",
  components: {
    Alert,
    Modal,
    DynamischeTabelleModalCreateOrUpdate,
    DynamischeTabelleTr,
    DynamischeTabelleFooter,
    PuxButton,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      errorsLocal: undefined,
      statusLoading: true,
      statusReloading: undefined,
      statusModalCreateOrUpdate: undefined,
      modelDefault: {},
      data: {},
      dataCollection: {},
      saveIndex: undefined,
      statusModalDelete: undefined,
      currentElement: undefined,
      currentElementIndex: undefined,
      confirmDeleteOptions: undefined,
      spaltenObjSnapshot: {},
      debouncedSave: undefined,
      isOptionsSearchGlobal: undefined,
    };
  },
  computed: {
    modalComponentName() {
      return DynamischeTabelleModalCreateOrUpdate.name;
    },
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      return OPTIONS;
    },

    labelHeader() {
      return this.modelParameter.frage;
    },

    getOptionsReadOnly() {
      return ({ col, hideLink }) => {
        const options = cloneDeep(col);
        if (col.type === "single_choice" || col.type === "multi_select") {
          options.keyLabel = "bez";
        } else if (col.type === "document" && hideLink) {
          options.hideLink = true;
        }
        return options;
      };
    },

    spaltenOrdered() {
      return orderBy(this.modelParameter.spalten, ["pos"]);
    },

    statusShowSum() {
      return this.modelParameter.spalten && find(this.modelParameter.spalten, spalte => spalte.sum);
    },

    statusShowAddButton() {
      return !this.statusReadonly && this.modelParameter.max_entries > this.model.elemente.length;
    },

    statusMovable() {
      return !this.statusReadonly && this.modelParameter.movable;
    },

    statusShowRowNumber() {
      return this.modelParameter.show_rownum || false;
    },

    labelRowNumber() {
      return this.modelParameter.rownum_title || "";
    },

    snapshotElementeObj() {
      if (this.statusSnapshot &&
        this.snapshotModule &&
        this.snapshotModule.elemente &&
        this.snapshotModule.elemente.length) {
        return keyBy(this.snapshotModule.elemente, "pk");
      }
      return {};
    },

    snapshotFiltered() {
      const SNAPSHOT = {
        add: {},
        delete: [],
        changes: {},
        changesDiff: {},
        status: false,
      };
      if (this.statusSnapshot) {
        const SNAPSHOT_ELEMENTE_OBJ = cloneDeep(this.snapshotElementeObj);
        const SNAPSHOT_ELEMENTE = get(this.snapshotModule, "elemente");
        if (this.model.elemente && this.model.elemente.length) {
          forEach(this.model.elemente, (item, idx) => {
            if (!SNAPSHOT_ELEMENTE_OBJ[item.pk]) {
              SNAPSHOT.add[item.pk] = true;
              SNAPSHOT.status = true;
            } else {
              const SNAPSHOT_IDX = findIndex(SNAPSHOT_ELEMENTE, el => item.pk === el.pk);
              if (idx !== SNAPSHOT_IDX) {
                SNAPSHOT.changes[item.pk] = SNAPSHOT_ELEMENTE_OBJ[item.pk];
                SNAPSHOT.changesDiff[item.pk] = SNAPSHOT.changesDiff[item.pk] || {};
                SNAPSHOT.changesDiff[item.pk].rownum = SNAPSHOT_IDX + 1;
                SNAPSHOT.status = true;
              }
              forEach(this.spaltenOrdered, spalte => {
                const ID = spalte.id;
                const VALUE_IN_MODEL = get(item, ID);
                const VALUE_IN_SNAPSHOT = get(SNAPSHOT_ELEMENTE_OBJ[item.pk], ID);
                if (!this.isModelAndSnapshotEqual({
                  valueInModel: VALUE_IN_MODEL,
                  valueInSnapshot: VALUE_IN_SNAPSHOT,
                })) {
                  SNAPSHOT.changes[item.pk] = SNAPSHOT_ELEMENTE_OBJ[item.pk];
                  SNAPSHOT.changesDiff[item.pk] = SNAPSHOT.changesDiff[item.pk] || {};
                  SNAPSHOT.changesDiff[item.pk][ID] = true;
                  SNAPSHOT.status = true;
                }
              });
              delete SNAPSHOT_ELEMENTE_OBJ[item.pk];
            }
          });
        }
        SNAPSHOT.delete = values(SNAPSHOT_ELEMENTE_OBJ);
        if (SNAPSHOT.delete.length) {
          SNAPSHOT.status = true;
        }
      }
      return SNAPSHOT;
    },

    statusDiff() {
      return this.snapshotFiltered.status;
    },

    extraForTranslate() {
      return {
        element_bezeichnung: this.modelParameter.element_bezeichnung,
      };
    },

    statusNoData() {
      return !(this.snapshotFiltered.delete.length || (this.model.elemente && this.model.elemente.length));
    },

    modelLength() {
      return this.model && this.model.elemente && this.model.elemente.length ? this.model.elemente.length : 0;
    },

    statusShowOpenButton() {
      if (this.modelParameter.max_entries) {
        return this.modelLength < this.modelParameter.max_entries && !this.statusReadonly;
      }
      return !this.statusReadonly;
    },
  },
  created() {
    this.debouncedSave = debounce(() => {
      const list = cloneDeep(this.model);
      this.statusReloading = true;
      this.save({
        model: list,
      }).finally(() => this.statusReloading = false);
    }, 1500);
  },
  methods: {
    prepareParameter() {
      const PARAMETER = toFormElementFromParameter({ obj: this.regel[`${ this.modulePrefix }_argument`] });
      this.parameterObj = PARAMETER.object;
      this.parameterList = PARAMETER.list;
      const MODEL_PARAMETER = initModelFromList({ list: this.parameterList });
      const SPALTEN = omitBy(MODEL_PARAMETER.spalten, col => {
        return col.hide;
      });
      MODEL_PARAMETER.spalten = toFormElementFromParameter({
        obj: SPALTEN,
        htmlIdPrefix: this.htmlRegelNummer,
        staticOptionsForType: {
          document: {
            saveUrl: "tempdok/",
          },
          select: {
            notLoadList: true,
          },
          multiselect: {
            notLoadList: true,
          },
          single_choice_objektkatalog: {
            notFoundLabel: "_LBL_STANDARD_FOERDERMODULE_OBJEKTKATALOG_ELEMENT_NOT_FOUND_",
            notLoadList: true,
          },
          text_objektkatalog_data: {
            showEmpty: "_LBL_STANDARD_FOERDERMODULE_OBJEKTKATALOG_ELEMENT_NOT_SELECTED_DATA_FIELD_",
          },
          decimal_objektkatalog_data: {
            showEmpty: "_LBL_STANDARD_FOERDERMODULE_OBJEKTKATALOG_ELEMENT_NOT_SELECTED_DATA_FIELD_",
          },
          text_katalog_data: {
            showEmpty: "_LBL_STANDARD_FOERDERMODULE_KATALOG_ELEMENT_NOT_SELECTED_DATA_FIELD_",
          },
          decimal_katalog_data: {
            showEmpty: "_LBL_STANDARD_FOERDERMODULE_KATALOG_ELEMENT_NOT_SELECTED_DATA_FIELD_",
          },
        }
      }).object;
      this.modelParameter = MODEL_PARAMETER;
      this.setSpaltenObjSnapshot();
    },

    setSpaltenObjSnapshot() {
      const SPALTEN_OBJ_SNAPSHOT = cloneDeep(this.modelParameter.spalten);
      forEach(SPALTEN_OBJ_SNAPSHOT, item => {
        item.hideWarning = true;
        if (item.type === "document") {
          item.hideLink = true;
        }
      });
      this.spaltenObjSnapshot = SPALTEN_OBJ_SNAPSHOT;
    },

    initDataLocal({ statusFirstLoad, statusUpdate }) {
      if (!statusFirstLoad && !statusUpdate && !this.isOptionsSearchGlobal) {
        return;
      }
      const PROMISES = [];
      forEach(this.modelParameter.spalten, item => {
        if (item.katalog && !statusUpdate) {
          PROMISES.push(this.loadKatalogData({ item }));
        } else if (item.objektkatalog) {
          PROMISES.push(this.loadObjektData({ item }));
        }
      });
      if (!statusUpdate && !this.isOptionsSearchGlobal) {
        this.initDefaultModel();
      }
      Promise.all(PROMISES).then(
        () => {
          this.statusLoading = false;
        }
      );
    },

    loadKatalogData({ item }) {
      const PARAMS = {
        key: item.katalog,
      };
      if (item.searchGlobal) {
        this.isOptionsSearchGlobal = true;
        PARAMS.pk = [];
        forEach(get(this.model, "elemente", []), el => {
          if (el[item.id]) {
            PARAMS.pk.push(el[item.id]);
          }
        });
        if (size(PARAMS.pk) === 0) {
          return;
        }
      }
      return this.getHttp({
        url: `katalog/`,
        urlParams: PARAMS,
        apiSaveId: item.searchGlobal ? undefined : item.katalog,
      }).then(
        response => {
          const data = this.filterLoadData({ item, response });
          this.setDataAndDataCollection({ item, data });
        }
      );
    },

    loadObjektData({ item }) {
      return this.getHttp({
        url: `generics/${ this.obj.content_type }/objekte/${ this.obj.pk }/katalogwerte/`,
        urlParams: {
          katalog: item.objektkatalog,
        },
      }).then(
        response => {
          const data = response || [];
          this.setDataAndDataCollection({ item, data });
        }
      );
    },

    reloadObjektData() {
      this.statusReloading = true;
      const PROMISES = [];
      forEach(this.modelParameter.spalten, item => {
        if (item.objektkatalog) {
          PROMISES.push(this.loadObjektData({ item }));
        }
      });
      Promise.all(PROMISES).then(
        () => {
          this.statusReloading = false;
        }
      );
    },

    setDataAndDataCollection({ item, data }) {
      this.setDataCollection({ item, data });
      this.data = assign({}, this.data, { [item.id]: data });
    },

    filterLoadData({ item, response }) {
      if (item.komplett === false) {
        return filter(response, i => item.ids.indexOf(i.pk) !== -1);
      }
      return response;
    },

    setDataCollection({ item, data }) {
      this.dataCollection[item.id] = keyBy(data, "pk");
    },

    initDefaultModel() {
      const MODEL_DEFAULT = initModelFromList({
        list: this.spaltenOrdered,
        defaultValue: "undefined",
      });
      forEach(this.spaltenOrdered, item => {
        if (item.komplett === false &&
          item.type === "select" &&
          item.ids &&
          item.ids.length === 1 &&
          item.required) {
          MODEL_DEFAULT[item.id] = item.ids[0];
        }
      });
      this.modelDefault = MODEL_DEFAULT;
    },

    openModalCreateOrEdit({ element, index, selectorClose } = {}) {
      this.currentElement = element;
      this.currentElementIndex = index;
      if (element) {
        this.selectorClose = [
          selectorClose,
          this.selectorHtmlRegelNummer,
        ];
      } else {
        this.selectorClose = [
          `#${ this.htmlRegelNummer }_add`,
          this.selectorHtmlRegelNummer,
        ];
      }
      this.statusModalCreateOrUpdate = true;
    },

    closeModalCreateOrUpdate() {
      this.currentElement = undefined;
      this.currentElementIndex = undefined;
      this.statusModalCreateOrUpdate = false;
    },

    closeEditMode() {
      scrollToGlobal(`#${ this.htmlRegelNummer }`);
    },

    onUpdateFunctions({ response }) {
      if (isFunction(this.savecallback)) {
        this.savecallback();
      }
      this.updateModule({
        response,
        extra: this.extra,
        group: this.group
      });
      this.updateSnapshotDiffLocal();
      this.sendSignal();
      setTimeout(() => {
        this.initData();
      });
    },

    onDeleteElement({ index, selectorClose }) {
      this.selectorClose = [
        selectorClose,
        this.selectorHtmlRegelNummer,
      ];
      this.confirmDeleteOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_GR_DYN_TABELLE_MODAL_DELETE_HEADER_{{element_bezeichnung}}_",
        msg: "_HTML_GR_DYN_TABELLE_MODAL_DELETE_BODY_{{element_bezeichnung}}_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteElement({ index }),
        cancelCallback: this.closeDeleteElement,
        loading: false,
        extra: this.extraForTranslate,
      };
      this.statusModalDelete = true;
    },

    deleteElement({ index }) {
      this.confirmDeleteOptions.loading = true;
      const list = cloneDeep(this.model);
      list.elemente.splice(index, 1);
      this.save({
        model: list,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_GR_DYN_TABELLE_MODAL_DELETE_SUCCESS_{{element_bezeichnung}}_",
            extra: this.extraForTranslate,
          });
          this.closeDeleteElement();
        },
        () => {
          this.addNotification({
            text: "_MSG_GR_DYN_TABELLE_MODAL_DELETE_ERROR_{{element_bezeichnung}}_",
            extra: this.extraForTranslate,
            type: "error",
          });
        }
      ).then(
        () => this.confirmDeleteOptions.loading = false
      );
    },

    closeDeleteElement() {
      this.statusModalDelete = false;
    },

    prepareModel() {
      if (get(this.module, "data.elemente")) {
        this.model = {
          elemente: cloneDeep(this.module.data.elemente),
        };
      }
      if (!this.model || !size(this.model)) {
        this.model = {
          elemente: [],
        };
      }
    },

    isModelAndSnapshotEqual({ valueInModel, valueInSnapshot }) {
      if (isObject(valueInModel) || isObject(valueInSnapshot)) {
        return isEqual(valueInModel, valueInSnapshot);
      }
      return valueInModel === valueInSnapshot;
    },

    onReceiveSignal(signal_kennung) {
      if (signal_kennung === "dynamische_tabelle.update" ||
        signal_kennung === "dynamische_zahlen.update" ||
        signal_kennung === "objektkatalog.update") {
        this.reloadModule({ statusUpdate: true });
      }
    },

    isFirst(idx) {
      return idx === 0;
    },

    isLast(idx) {
      return idx >= (size(this.model.elemente) - 1);
    },

    moveUp({ index }) {
      if (this.isFirst(index)) {
        return;
      }
      const ELEMENTE = cloneDeep(this.model.elemente);
      const TEMP = ELEMENTE[index - 1];
      ELEMENTE[index - 1] = ELEMENTE[index];
      ELEMENTE[index] = TEMP;
      this.model.elemente = ELEMENTE;
      this.debouncedSave();
    },

    moveDown({ index }) {
      if (this.isLast(index)) {
        return;
      }
      const ELEMENTE = cloneDeep(this.model.elemente);
      const TEMP = ELEMENTE[index + 1];
      ELEMENTE[index + 1] = ELEMENTE[index];
      ELEMENTE[index] = TEMP;
      this.model.elemente = ELEMENTE;
      this.debouncedSave();
    },

    showErrorsInModule({ errors }) {
      this.errorsLocal = errors;
    },

    onSaved() {
      this.removeErrorsLocal();
    },

    removeErrorsLocal() {
      this.errorsLocal = undefined;
    },
  },
};
