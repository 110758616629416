"use strict";

import template from "./tasklog.progress.jade";

class TaskLogStatusController {
  /*@ngInject*/
  constructor() {
    this.statusMap = {
      info: this.status === "PENDING" || this.status === "RUNNING" || this.status === "STARTED",
      success: this.status === "SUCCESS",
      warning: this.status === "PARTIAL_SUCCESS",
      danger: this.status === "FAILURE"
    };
  }
}

export default {
  template: template(),
  controller: TaskLogStatusController,
  controllerAs: "vm",
  bindings: {
    status: "<",
    progress: "<"
  },
};
