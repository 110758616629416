import translate from "../../../../../global/directives/translate";

import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";

import {
  cloneDeep,
  forEach,
  get,
  isNil,
} from "lodash-es";


// @vue/component
export default {
  name: "VertragNachweiseModalUpdate",
  components: {
    FormElement,
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    elemente: {
      type: Array,
      required: true,
    },
    manuelleFristen: {
      type: Object,
      required: true,
    },
    selectorClose: {
      type: [String, Array],
      required: true,
    },
    urlSave: {
      type: String,
      required: true,
    },
    infoIText: {
      type: String,
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  emits: [
    "updateElemente",
    "updateManuelleFristen"
  ],
  data() {
    return {
      formElementAutomatisch: {
        type: "one_checkbox",
        view: "v-alt",
        id: "automatisch",
        label: "_LBL_VERTRAGNACHWEISE_MODAL_AUTOMATISCHE_FRIST_",
        required: false,
      },
      formElementDate: {
        type: "date",
        view: "v",
        id: "frist",
        label: "_LBL_VERTRAGNACHWEISE_MODAL_FRIST_DATUM_",
        required: false,
      },
      model: {},
      errors: undefined,

    };
  },
  computed: {
    headerText() {
      return "_TXT_VERTRAGNACHWEISE_MODAL_UPADTE_HEADER_";
    },
    optionsModal() {
      return {
        showCloseButton: true,
        required: true,
      };
    },
    znfristen() {
      const znfristen = {};
      forEach(this.elemente, el => {
        if (el.periodenkennung !== "vn") {
          znfristen[el.periodenkennung] = el;
        }
      });
      return znfristen;
    },
    vnfristen() {
      const vnfristen = {};
      forEach(this.elemente, el => {
        if (el.periodenkennung === "vn") {
          vnfristen[el.periodenkennung] = el;
        }
      });
      return vnfristen;
    },
  },
  created() {
    this.initModel();
  },
  methods: {
    save() {
      const fristen = {};
      forEach(this.elemente, el => {
        const MODEL_PERIODE = get(this.model, el.periodenkennung);
        if (!isNil(MODEL_PERIODE)) { // oder !isNil(MODEL_PERIODE)
          if (!MODEL_PERIODE.automatisch || el.periodenkennung === "vn") {
            fristen[el.periodenkennung] = MODEL_PERIODE.frist;
          }
        }
      });
      this.clearErrors();
      const DATA = {
        manuelle_fristen: cloneDeep(fristen)
      };
      this.putHttp({
        url: this.urlSave,
        data: DATA,
      }).then(
        response => {
          this.$emit("updateElemente", response.data.elemente);
          this.$emit("updateManuelleFristen", response.data.manuelle_fristen);
          const TEXT = "_MSG_VERTRAGNACHWEISE_GR_UPDATE_SUCCESS_";
          this.addNotification({ text: TEXT });
          this.close({ response });
        },
        error => {
          const TEXT = error.data.manuelle_fristen;
          this.addNotification({ text: TEXT, type: "error" });
        }
      );
    },
    clearErrors() {
      this.errors = undefined;
    },

    initModel() {
      forEach(this.elemente, el => {
        const FRIST = get(this.manuelleFristen, el.periodenkennung);
        const AUTOMATISCH = isNil(FRIST);
        this.model[el.periodenkennung] = {
          frist: FRIST,
          automatisch: AUTOMATISCH,
        };
      });
    },
  },
};
