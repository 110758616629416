import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTooltip from "../../../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  FilterBooleanMixin,
} from "../../../../../global/mixins/FiltersMixin";
import NotificationMixin from "../../../../../global/mixins/NotificationMixin";

import { copyToClipboard } from "../../../../../global/functions/utils";

import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "TemplateAdminFunktionenBoxItem",
  components: {
    PuxIcon,
    PuxTooltip,
    PuxTranslate,
  },
  mixins: [
    FilterBooleanMixin,
    NotificationMixin,
  ],
  props: {
    funktion: {
      type: Object,
      required: true,
    },
    parentIndex: {
      type: Number,
      required: true,
    },
    funktionIndex: {
      type: Number,
      required: true,
    },
    groupsAndFunktionenNamesHiddenWithSearch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      typesMapping: {
        string: "String",
        boolean: "Boolean",
        float: "Kommazahl",
        integer: "Ganzzahl",
        date: "Datum",
        datetime: "Zeitpunkt",
        unknown: "Unbekannt",
        list: "Liste",
        json: "JSON",
        dict: "Dictionary",
        single_choice: "Wert aus Liste",
        multi_select: "Werte-Liste",
      },
    };
  },
  computed: {
    isVisible() {
      return !this.groupsAndFunktionenNamesHiddenWithSearch.funktionenNames[this.funktion.registered_name];
    },

    id() {
      return `template_funktion_title_${ this.parentIndex }_${ this.funktionIndex }`;
    },

    icon() {
      return this.funktion.used ? "glyphicon-check" : "glyphicon-unchecked";
    },

    textUsed() {
      return this.funktion.used ?
        "_TXT_TEMPLATEADMIN_FUNKTION_IM_TEMPLATE_VERWENDET_" :
        "_TXT_TEMPLATEADMIN_FUNKTION_NICHT_IM_TEMPLATE_VERWENDET_";
    },

    functionDict() {
      const fd = {
        func: this.funktion.registered_name,
      };
      forEach(this.funktion.params, (i, k) => {
        fd[k] = null;
      });
      return fd;
    },

    hasParams() {
      return Object.keys(this.funktion.params).length > 0;
    },

    functionSource() {
      return this.funktion.source || "_TXT_TEMPLATEADMIN_FUNKTION_DEFINIERT_IN_UNBEKANNT_";
    },

  },
  methods: {
    copyFunktionToClipboard() {
      copyToClipboard({ text: JSON.stringify(this.functionDict, undefined, 2) });
      this.addNotification({ text: "_MSG_FUNKTION_TO_CLIPBOARD_SUCCESS_" });
    },

    typeDatatype(type) {
      return this.typesMapping[type] || type;
    },

    requiredIcon(param) {
      if (param.options && param.options.required === true) {
        return " *";
      }
      return "";
    }

  },
};
