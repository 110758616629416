import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTooltip from "../../../global/components/PuxTooltip/PuxTooltip.vue";

import NotizenReadonlyMixin from "../NotizenReadonlyMixin";

// @vue/component
export default {
  name: "NotizenInlineItem",
  components: {
    PuxIcon,
    PuxTooltip,
  },
  mixins: [
    NotizenReadonlyMixin,
  ],
  computed: {
    tooltipId() {
      return `notizen_tooltip_${ this.notiz.pk }`;
    },

    isAutorEditor() {
      return this.notiz.autor && this.notiz.editor && this.notiz.autor.pk === this.notiz.editor.pk;
    },
  },
};
