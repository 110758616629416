import FormElement from "../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../global/components/FormstepDetail/FormstepDetail.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxAlert from "../../../global/components/PuxAlert/PuxAlert.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import UnterzeichnendeHinweis from "./UnterzeichnendeHinweis/UnterzeichnendeHinweis.vue";
import VertretungsberechtigteModalCreate from "../VertretungsberechtigteModalCreate/VertretungsberechtigteModalCreate.vue";
import VertretungsberechtigteTr from "./VertretungsberechtigteTr/VertretungsberechtigteTr.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import {
  CheckPermissionsSyncMixin,
} from "../../../global/mixins/PermissionMixin";

import { createNamespacedHelpers } from "vuex";

import {
  cloneDeep,
  compact,
  concat,
  filter,
  forEach,
  get,
  includes,
  isUndefined,
  keyBy,
  map,
  values,
} from "lodash-es";

const SNAPSHOT_CHANGES_LIST = [
  "display_name",
  "av_funktion",
  "berechtigung_bez",
];

const {
  mapMutations,
} = createNamespacedHelpers("snapshot");

// @vue/component
export default {
  name: "Vertretungsberechtigte",
  components: {
    FormElement,
    FormstepDetail,
    Modal,
    PuxAlert,
    PuxButton,
    PuxTranslate,
    UnterzeichnendeHinweis,
    VertretungsberechtigteModalCreate,
    VertretungsberechtigteTr,
  },
  mixins: [
    CheckPermissionsSyncMixin,
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    ast: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      required: false,
      default: undefined,
    },
    object: {
      type: Object,
      required: true,
    },
    showHinweis: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    snapshot: {
      type: Object,
      required: false,
      default: undefined,
    },
    editPermission: {
      type: String,
      required: true,
      validator: value => ["auszahlung.vertreter.update", "vertrag.fvb.update"].indexOf(value) !== -1,
    },
  },
  emits: [
    "updated",
  ],
  data() {
    return {
      isModalCreateOpen: undefined,
      loadingVertretungsberechtigte: false,
      maxVertreter: 2,
      model: {
        vertreter_0: undefined,
        vertreter_1: undefined,
      },
      previousModel: {
        vertreter_0: undefined,
        vertreter_1: undefined,
      },
      previousVertreter: {
        vertreter_0: undefined,
        vertreter_1: undefined,
      },
      vertretungsberechtigte: [],
      vertretungsberechtigteAst: [],
      errors: {},
      isConfirmVisible: undefined,
      confirmOptions: undefined,
      selectorCloseForConfirmDelete: undefined,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        label: "_TXT_VERTRETUNGSBERECHTIGTE_",
        info: this.info,
        required: true,
        saveCallback: this.save,
        statusSaveDisabled: this.isSaveDisabled,
      };
    },

    optionsForFormElementVertreter() {
      const OPTIONS = [];
      for (let idx = 0; idx < this.maxVertreter; idx++) {
        const ID = `vertreter_${ idx }`;
        OPTIONS.push({
          id: ID,
          htmlId: `${ this.id }_${ ID }`,
          type: "select",
          label: "_TXT_VERTRETUNGSBERECHTIGTE_DETAILS_PICK_OBJ_",
          view: "v-alt",
          keyId: "pk",
          keyLabel: "label",
          search: true,
          searchList: [
            "label",
          ],
          loading: this.loadingVertretungsberechtigte,
          placeholder: `_LBL_VERTRETUNGSBERECHTIGTE_PLACEHOLDER_{{index}}_`,
          extra: {
            index: idx + 1,
          },
          hideIf: idx > 0 && isUndefined(this.model.vertreter_0) && isUndefined(this.model.vertreter_1),
        });
      }
      return OPTIONS;
    },

    idForModalButton() {
      return `${ this.id }_btn_modal`;
    },

    isVertreterEditable() {
      return this.checkPermissionsSync({
        perm: this.editPermission,
        permArray: this.userObjPermissions,
      });
    },

    isAstEditable() {
      return this.checkPermissionsSync({
        perm: "antragstellerorg.update",
        permArray: this.userAstPermissions,
      });
    },

    canCreateVertreter() {
      return this.checkPermissionsSync({
        perm: "astorgvertreter.create",
        permArray: this.userAstPermissions,
      });
    },

    selectorClose() {
      return `#${ this.idForModalButton }`;
    },

    urlForSave() {
      return this.urlVertretungsberechtigte;
    },

    urlVertretungsberechtigte() {
      return `${ this.baseUrl }${ this.object.pk }/vertretungsberechtigte/`;
    },

    urlAstVertretungsberechtigte() {
      return `antragstellerorganisationen/${ this.ast.pk }/vertretungsberechtigte/`;
    },

    userObjPermissions() {
      return this.object.user_permissions;
    },

    userAstPermissions() {
      return this.ast.user_permissions;
    },

    statusNoVertreter() {
      return !this.vertretungsberechtigte.length;
    },

    vertreterChoices() {
      return index => {
        return filter(this.vertretungsberechtigteAst, item => {
          return this.model[`vertreter_${ 1 - index }`] !== item.pk;
        });
      };
    },

    isSaveDisabled() {
      for (let idx = 0; idx < this.maxVertreter; idx++) {
        const KEY = `vertreter_${ idx }`;
        if (this.model[KEY] !== this.previousModel[KEY]) {
          return false;
        }
      }
      return true;
    },

    isSnapshot() {
      return !!this.snapshot;
    },

    snapshotDate() {
      return get(this.snapshot, "osn_snapshot_datum");
    },

    snapshotObject() {
      return get(this.snapshot, "osn_snapshot.vertretungsberechtigte");
    },

    snapshotVertretungsberechtigteObj() {
      if (!this.isSnapshot) {
        return {};
      }
      return keyBy(this.snapshotObject, "pk");
    },

    snapshotFiltered() {
      const SNAPSHOT = {
        add: {},
        delete: [],
        changes: {},
        changesDiff: {},
        status: false,
      };
      if (this.isSnapshot) {
        const SNAPSHOT_VERTRETER_OBJ = cloneDeep(this.snapshotVertretungsberechtigteObj);
        forEach(this.vertretungsberechtigte, item => {
          const CURRENT_PK = item.pk;
          const CURRENT_SNAPSHOT = cloneDeep(SNAPSHOT_VERTRETER_OBJ[CURRENT_PK]);
          if (!CURRENT_SNAPSHOT) {
            SNAPSHOT.add[CURRENT_PK] = true;
            SNAPSHOT.status = true;
          } else {
            forEach(SNAPSHOT_CHANGES_LIST, key => {
              const VAL_IN_MODEL = item.vertreter_obj[key];
              const VAL_IN_SNAPSHOT = CURRENT_SNAPSHOT.vertreter_obj[key];
              if (VAL_IN_MODEL !== VAL_IN_SNAPSHOT) {
                SNAPSHOT.changes[CURRENT_PK] = CURRENT_SNAPSHOT;
                SNAPSHOT.changesDiff[CURRENT_PK] = SNAPSHOT.changesDiff[CURRENT_PK] || {};
                SNAPSHOT.changesDiff[CURRENT_PK][key] = true;
                SNAPSHOT.status = true;
              }
            });
            delete SNAPSHOT_VERTRETER_OBJ[CURRENT_PK];
          }
        });
        SNAPSHOT.delete = values(SNAPSHOT_VERTRETER_OBJ);
        if (SNAPSHOT.delete.length) {
          SNAPSHOT.status = true;
        }
      }
      return SNAPSHOT;
    },

    idForSnapshotDelete() {
      return `${ this.id }_snapshot_delete`;
    },

    isDiff() {
      return this.snapshotFiltered.status;
    },
  },
  watch: {
    isDiff: {
      immediate: true,
      handler(newVal) {
        this.MUT_SET_STATUS_DIFF_FOR_REGEL({
          regelId: this.id,
          statusDiff: newVal,
        });
      },
    },
  },
  created() {
    this.loadVertretungsberechtigte();
  },
  beforeUnmount() {
    this.MUT_DELETE_STATUS_DIFF_FOR_REGEL({ regelId: this.id });
  },
  methods: {
    loadVertretungsberechtigte() {
      this.loadingVertretungsberechtigte = true;
      const PROMISES = [
        this.getListHttp({
          url: this.urlAstVertretungsberechtigte,
          urlParams: {
            valid: true,
          },
        }),
        this.getListHttp({
          url: this.urlVertretungsberechtigte,
        })
      ];
      return Promise.all(PROMISES).then(
        responses => {
          this.setVertretungsberechtigteAst(responses[0]);
          this.vertretungsberechtigte = responses[1];
          this.setModelFromVertreterLists();
          this.setPreviousVertreter();
          this.setPreviousModel();
        }
      ).finally(() => this.loadingVertretungsberechtigte = false);
    },

    setVertretungsberechtigteAst(response) {
      const VERTRETUNGSBERECHTIGTE_AST = [];
      forEach(response, item => {
        item.label = `${ item.display_name }, ${ item.av_funktion }, ${ item.berechtigung_bez }`;
        VERTRETUNGSBERECHTIGTE_AST.push(item);
      });
      this.vertretungsberechtigteAst = VERTRETUNGSBERECHTIGTE_AST;
    },

    setModelFromVertreterLists() {
      const VALID_PKS = compact(map(this.vertretungsberechtigte, "astorg_vertreter"));
      const VALID_AST_PKS = map(this.vertretungsberechtigteAst, "pk");
      this.clearModel();
      forEach(VALID_PKS, (pk_list, index) => {
        forEach(VALID_AST_PKS, pk => {
          if (includes(pk_list, pk)) {
            this.setModelVertreter(pk, index);
          }
        });
      });
    },

    clearModel() {
      this.model.vertreter_0 = undefined;
      this.model.vertreter_1 = undefined;
    },

    loadObjVertretungsberechtigte() {
      this.getListHttp({
        url: this.urlVertretungsberechtigte,
      }).then(
        response => {
          this.vertretungsberechtigte = response;
          this.setModelFromVertreterLists();
          this.setPreviousVertreter();
          this.setPreviousModel();
          this.loadingVertretungsberechtigte = false;
        }
      );
    },

    setPreviousVertreter() {
      const VALID_PKS = compact(map(this.vertretungsberechtigte, "pk"));
      this.previousVertreter = {
        vertreter_0: undefined,
        vertreter_1: undefined,
      };
      forEach(VALID_PKS, (pk, index) => {
        this.previousVertreter[`vertreter_${ index }`] = pk;
      });
    },

    setPreviousModel() {
      this.previousModel = cloneDeep(this.model);
    },

    closeModalCreate({ response } = {}) {
      if (response) {
        this.loadVertretungsberechtigte().then(
          () => {
            const POSITION = this.model.vertreter_0 ? 1 : 0;
            this.setModelVertreter(response.pk, POSITION);
          }
        );
      }
      this.isModalCreateOpen = false;
    },

    openModal() {
      this.isModalCreateOpen = true;
    },

    setModelVertreter(value, index) {
      this.model[`vertreter_${ index }`] = value;
    },

    save() {
      this.errors = {};
      this.loadingVertretungsberechtigte = true;
      const MODEL = cloneDeep(this.model);
      const PROMISES = [];
      for (let idx = 0; idx < this.maxVertreter; idx++) {
        const DATA = {
          vertreter: MODEL[`vertreter_${ idx }`],
        };
        if (this.previousVertreter[`vertreter_${ idx }`]) {
          DATA.prev_vertreter = this.previousVertreter[`vertreter_${ idx }`];
        }
        if (DATA.vertreter !== this.previousModel[`vertreter_${ idx }`]) {
          const POST = this.postHttp({
            url: this.urlForSave,
            data: DATA,
          });
          PROMISES.push(POST);
          POST.then(
            () => {
              this.addNotification({ text: "_MSG_VERTRETUNGSBERECHTIGTE_UPDATE_SUCCESS_{{index}}_", extra: { index: idx + 1 } });
              this.onSave();
            },
            error => {
              this.addNotification({ text: "_MSG_VERTRETUNGSBERECHTIGTE_UPDATE_ERROR_{{index}}_", type: "error", extra: { index: idx + 1 } });
              this.onError({ error: error.data, index: idx });
            }
          );
        }
      }
      return Promise.allSettled(PROMISES).then(
        () => {
          this.loadObjVertretungsberechtigte();
        }
      );
    },

    openConfirmDeleteVertreter({ vertreter, selectorClose }) {
      this.selectorCloseForConfirmDelete = [
        selectorClose,
        `#${ this.id }`,
      ];
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: `_HTML_GR_VERTRETUNGSBERECHTIGTE_MODAL_DELETE_HEADER_{{name}}_`,
        msg: "_HTML_GR_VERTRETUNGSBERECHTIGTE_MODAL_DELETE_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteVertreter({ vertreter }),
        cancelCallback: this.closeConfirmDeleteVertreter,
        loading: false,
        extra: {
          name: vertreter.display_name,
        },
      };
      this.isConfirmVisible = true;
    },

    closeConfirmDeleteVertreter() {
      this.isConfirmVisible = false;
    },

    deleteVertreter({ vertreter }) {
      this.confirmOptions.loading = true;
      this.loadingVertretungsberechtigte = true;
      this.deleteHttp({
        url: `${ this.urlVertretungsberechtigte }${ vertreter.pk }/`
      }).then(
        () => {
          this.loadObjVertretungsberechtigte();
          this.addNotification({ text: "_MSG_VERTRETUNGSBERECHTIGTE_DELETE_SUCCESS_" });
          this.onSave();
        },
        error => {
          if (get(error, "data.detail")) {
            this.addNotification({ text: error.data.detail, type: "error" });
          }
        },
      ).finally(
        () => {
          this.closeConfirmDeleteVertreter();
          this.confirmOptions.loading = false;
          this.loadingVertretungsberechtigte = false;
        }
      );
    },

    onSave() {
      this.$emit("updated");
    },

    onError({ error, index = {} }) {
      if (index && error.vertreter) {
        this.errors[`vertreter_${ index }`] = error.vertreter;
      } else {
        forEach(error, (item, key) => {
          this.errors[key] = this.errors[key] ? concat(this.errors[key], item) : item;
        });
      }
    },

    ...mapMutations([
      "MUT_SET_STATUS_DIFF_FOR_REGEL",
      "MUT_DELETE_STATUS_DIFF_FOR_REGEL",
    ]),
  },
};
