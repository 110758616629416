import {
  getCurrentInstance,
  ref,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import SitzungModuleMapping from "../../../Sitzung/SitzungModule/SitzungModuleMapping";
import {
  cloneDeep,
  filter,
  findIndex,
  forEach,
  get,
} from "lodash-es";

export default function TagesordnungspunktAPI() {
  const APP = getCurrentInstance();
  const $stateParams = APP.appContext.config.globalProperties.$stateParams;

  const sitzungUrl = `sitzungen/${ $stateParams.id }/`;
  const sitzungTagesordnungenUrl = `sitzungtagesordnungen/${ $stateParams.tos_id }/`;
  const tagesordnungspunktUrl = `sitzungen/${ $stateParams.id }/tops/${ $stateParams.tos_id }/`;
  const tagesordnungspunktModuleUrl = `sitzungen/${ $stateParams.id }/tops/${ $stateParams.tos_id }/module/`;

  const loading = ref(true);
  const sitzung = ref({});
  const tagesordnungspunkt = ref({});
  const tagesordnungspunktWithSitzungPermissions = ref({});
  const tagesordnungspunktModule = ref([]);
  const rubrics = ref([]);
  const notizenWeitereObjekte = ref([]);

  const {
    getHttp,
    getListHttp,
  } = HttpAPI();

  const {
    addNotification,
  } = NotificationAPI();

  const setRubrics = () => {
    forEach(tagesordnungspunktModule.value, module => {
      rubrics.value.push({
        key: module.modulname,
        kbez: get(module, "modul.stm_kbez") || "",
      });
    });

    rubrics.value.push({
      key: "dokumente",
      kbez: "_TXT_TOP_DOKUMENTE_",
    });
  };

  const setNotizenWeitereObjekte = () => {
    notizenWeitereObjekte.value = [{
      noo_objektid: sitzung.value.pk,
      content_type: sitzung.value.content_type
    }];
  };

  const getAbsolutPositionString = (absolut_pos = []) => {
    let absolutPositionString = "";
    if (absolut_pos.length) {
      absolutPositionString = `${ absolut_pos[0] }`;
      for (let i = 1; i < absolut_pos.length; i++) {
        absolutPositionString += `.${ absolut_pos[i] }`;
      }
    }
    return absolutPositionString;
  };

  const setTagesordnungspunkt = response => {
    response.absolutPositionString = getAbsolutPositionString(response.absolut_pos);
    response.tos_bereitgestellt = !response.tos_bereitgestellt ? 0 : +response.tos_bereitgestellt;
    tagesordnungspunkt.value = response;
  };
  const setTagesordnungspunktWithSitzungPermissions = ({ response, sitzung }) => {
    const TAGESORDNUNGSPUNKT = cloneDeep(response);
    TAGESORDNUNGSPUNKT.user_permissions = cloneDeep(sitzung.value.user_permissions);
    tagesordnungspunktWithSitzungPermissions.value = TAGESORDNUNGSPUNKT;
  };
  const filterTagesordnungspunktModule = response => {
    tagesordnungspunktModule.value = filter(response, item => {
      return !!SitzungModuleMapping.main[item.modulname];
    });
  };

  const loadData = () => {
    return Promise.all([
      getHttp({
        url: sitzungUrl,
      }),
      getHttp({
        url: tagesordnungspunktUrl,
      }),
      getListHttp({
        url: tagesordnungspunktModuleUrl,
      }),
    ]).then(
      responses => {
        sitzung.value = responses[0];
        setTagesordnungspunkt(responses[1]);
        setTagesordnungspunktWithSitzungPermissions({ response: responses[1], sitzung });
        filterTagesordnungspunktModule(responses[2]);
        setRubrics();
        setNotizenWeitereObjekte();
        loading.value = false;
      },
      () => {
        addNotification({
          text: "_MSG_TOP_LADEN_ERROR_",
          type: "error",
        });
      }
    );
  };

  const updateModule = ({ response }) => {
    const MODULE_PK = get(response, "modul.pk");
    const MODULE_INDEX = findIndex(tagesordnungspunktModule.value, ["modul.pk", MODULE_PK]);
    if (MODULE_INDEX !== -1) {
      tagesordnungspunktModule.value.splice(MODULE_INDEX, 1, response);
    }
  };


  return {
    loadData,
    loading,
    notizenWeitereObjekte,
    rubrics,
    sitzung,
    sitzungTagesordnungenUrl,
    tagesordnungspunkt,
    tagesordnungspunktModule,
    tagesordnungspunktModuleUrl,
    tagesordnungspunktUrl,
    tagesordnungspunktWithSitzungPermissions,
    updateModule,
  };
}
