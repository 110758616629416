"use strict";

import BetragsrechnerBase from "../betragsrechner.base";

class UebernachtungskostenRechner extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "uebernachtungskostenrechner";
    this.component_name = "uebernachtungskosten";
    this.labels = { anzahl_tage: "Übernachtungen",
                    anzahl_personen: "Personenanzahl (Teilnehmer, Referenten und Betreuer)" };
  }

  get_labelmapping() {
    return this.labels;
  }

  get_fields(params) {
    return { anzahl_tage: "",
             anzahl_personen: "",
             tagespauschale: params.tagespauschale,
    };
  }
}
export default UebernachtungskostenRechner;
