import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import BelegeModalCreateOrEdit from "../../KF/BelegeModalCreateOrEdit/BelegeModalCreateOrEdit.vue";
import BelegeModalDeleteDocument from "../BelegeModalDeleteDocument/BelegeModalDeleteDocument.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import getTableApi from "../../../const/TableApis";
import { EventBus } from "../../../global/functions/event-bus";
import {
  getIconBetragAnerkannt,
  getClassBetragAnerkannt,
  getTitleBetragAnerkannt,
  showTeilsAnerkannt,
} from "../../../const/KVertragskostenbelegStatusUUIDs";
import {
  isUndefined,
} from "lodash-es";

const TABLE_ID = "BelegeListInVertragTable";

// @vue/component
export default {
  name: "BelegeListInVertrag",
  components: {
    AngularLink,
    BelegeModalCreateOrEdit,
    BelegeModalDeleteDocument,
    PuxTranslate,
    SmartTable,
  },
  props: {
    createBeleg: {
      type: Function,
      default: () => {},
      info: "Angular-Funktion",
    },
    vertrag: {
      type: Object,
      required: true,
      info: "Objekt-Vertrag",
    },
    canCreateBeleg: {
      type: Boolean,
    },
  },
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      filterCurrency,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: "",
        cols: [
          {
            label: "_LBL_BELEGE_LIST_IN_VERTRAG_AUSZAHLUNG_",
            id: "auszahlung",
            slot: "auszahlung"
          },
          {
            label: "_LBL_BELEGE_LIST_IN_VERTRAG_NR_",
            id: "nummer",
          },
          {
            label: "_LBL_BELEGE_LIST_IN_VERTRAG_BEZEICHNUNG_",
            id: "bez",
          },
          {
            label: "_LBL_BELEGE_LIST_IN_VERTRAG_PRUEFMETHODE_",
            id: "pruefmethode_bez",
            permission: "vertragskostenpositionsbelege.anerkennen"
          },
          {
            label: "_LBL_BELEGE_LIST_IN_VERTRAG_STATUS_",
            id: "status_bez",
          },
          {
            label: "_LBL_BELEGE_LIST_IN_VERTRAG_ERFASST_",
            id: "betrag_erfasst",
            filter: "currency",
            filterParameter: "€",
            className: "text-right text-nowrap",
            headerClassName: "text-right",
          },
          {
            label: "_LBL_BELEGE_LIST_IN_VERTRAG_ANERKANNT_",
            id: "betrag_anerkannt",
            slot: "betrag_anerkannt",
            className: "text-right text-nowrap",
            headerClassName: "text-right",
          },
          {
            label: "_LBL_BELEGE_LIST_IN_VERTRAG_ZUORDNUNG_",
            id: "beleg_zuordnung",
          },
          {
            label: "_LBL_BELEGE_LIST_IN_VERTRAG_POSITION_",
            id: "position",
            slot: "position",
          },
          {
            label: "_LBL_BELEGE_LIST_IN_VERTRAG_DATEINAME_",
            id: "dokument",
            slot: "dokument",
          },
        ],
        rowActions: [
          {
            label: "_TXT_BELEGE_VERTRAG_TABLE_ROW_ACTIONS_DELETE_DOCUMENT_",
            callback: this.deleteDokument,
            isHidden: ({ item }) => !item.dokument,
            className: "btn btn-default",
          },
        ],
        actions: [
          // {
          //   isHidden: () => !this.canCreateBeleg,
          //   label: "_TXT_BELEGE_VERTRAG_TABLE_ACTIONS_CREATE_",
          //   callback: this.onCreateBeleg,
          //   type: "button",
          //   className: "btn btn-primary",
          //   icon: "glyphicon-plus",
          // },
          {
            isHidden: () => !this.canCreateBeleg,
            label: "_TXT_BELEGE_VERTRAG_TABLE_ACTIONS_CREATE_",
            callback: this.openModalBelegCreate,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            id: "beleg_create_button",
          },
        ],
        label: "_LBL_BELEGE_LIST_IN_VERTRAG_BELEGLISTE_",
        sortable: false,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        infoHeader: "_TXT_BELEGE_VERTRAG_TABLE_INFO_HEADER_",
        showColsConfig: false,
      },
      status: {
        loading: true,
      },
      modal: {
        deleteDocument: undefined,
      },
      currentBeleg: undefined,
      statusModalBelegCreate: undefined,
      selectorCloseModalBelegCreate: "#beleg_create_button",
    };
  },
  watch: {
    canCreateBeleg() {
      this.options.actions[0].isHidden = !this.canCreateBeleg;
    },
  },
  created() {
    this.initUrl();
    this.initEventBus();
  },
  beforeUnmount() {
    this.destroyEventBus();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { vertragPk: this.vertrag.pk } });
    },

    onCreateBeleg() {
      this.createBeleg().then(
        response => {
          if (!isUndefined(response)) {
            EventBus.$emit(`updateTable${ TABLE_ID }`);
          }
        }
      );
    },

    deleteDokument({ item }) {
      this.modal.deleteDocument = true;
      this.currentBeleg = item;
    },

    closeModalDeleteDocument({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.modal.deleteDocument = false;
    },

    getIconBetragAnerkannt({ row }) {
      return getIconBetragAnerkannt({ beleg: row });
    },

    getClassBetragAnerkannt({ row }) {
      return getClassBetragAnerkannt({ beleg: row });
    },

    getTitleBetragAnerkannt({ row }) {
      return `_TXT${ getTitleBetragAnerkannt({ beleg: row }) }`;
    },

    showTeilsAnerkannt({ row }) {
      return showTeilsAnerkannt({ beleg: row });
    },

    openModalBelegCreate() {
      this.statusModalBelegCreate = true;
    },

    closeModalBelegCreate() {
      this.statusModalBelegCreate = false;
    },

    initEventBus() {
      EventBus.$on("updateBeleg", this.updateBeleg);
    },

    destroyEventBus() {
      EventBus.$off("updateBeleg", this.updateBeleg);
    },

    updateBeleg() {
      EventBus.$emit(`updateTable${ TABLE_ID }`);
    },
  },
};
