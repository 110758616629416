import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "AntragDetailsAngebotFoerderdokument",
  components: {
    PuxIcon,
    PuxTranslate,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterFileSize,
    } = FiltersAPI();

    return {
      filterFileSize,
    };
  },
  computed: {
    documentSize() {
      return this.filterFileSize(this.document.groesse);
    },
  },
};
