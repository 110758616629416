var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<script type=\"text/ng-template\" id=\"dynTableAuswahlChoicesTemplate.html\"><span ng-bind=\"item.bez\"></span></script>");
jade_mixins["form-group"] = jade_interp = function(labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label><span class=\"titel\">" + (jade.escape((jade_interp =  labelText ) == null ? '' : jade_interp)) + "</span>");
block && block();
buf.push("</label>");
};
jade_mixins["param-form"] = jade_interp = function(model, disabled, position){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div>");
jade_mixins["form-group"].call({
block: function(){
buf.push("<select" + (jade.attr("ng-model", "" + ( model ) + ".type", true, true)) + " name=\"type\" aria-required=\"true\" role=\"listbox\"" + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + (jade.attr("ng-change", "vm.changeType({ position: " + ( position ) + ", model: " + ( model ) + " })", true, true)) + " class=\"form-control\"><option value=\"\">--- Bitte wählen Sie ---</option><option ng-repeat=\"(key, item) in vm.Parameters\" value=\"{{ key }}\">{{ item.label }}</option></select>");
}
}, "Typ*");
buf.push("</div><div" + (jade.attr("ng-if", "" + ( model ) + ".type", true, true)) + "><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.label", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input type=\"text\" name=\"label\"" + (jade.attr("ng-model", "" + ( model ) + ".label", true, true)) + (jade.attr("ng-change", "vm.changeLabel({ position: " + ( position ) + " })", true, true)) + " aria-required=\"true\"" + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control m-0\">");
}
}, "Label*");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.required", true, true)) + "><div class=\"checkbox-btn pb-2\"><input type=\"checkbox\"" + (jade.attr("ng-attr-id", "check_dyn_tabelle_{{ " + ( position ) + " }}", true, true)) + (jade.attr("ng-model", "" + ( model ) + ".required", true, true)) + " name=\"required\"" + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + "><label" + (jade.attr("ng-attr-for", "check_dyn_tabelle_{{ " + ( position ) + " }}", true, true)) + ">Pflicht</label></div></div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.styles", true, true)) + "><label class=\"with-border\"><span class=\"titel\">Styles {{ vm.Parameters[" + (jade.escape((jade_interp =  model ) == null ? '' : jade_interp)) + ".type].options.styles.required ? \"*\" : \"\" }}</span><ui-select multiple tagging tagging-label=\"(neu)\"" + (jade.attr("ng-model", "" + ( model ) + ".styles", true, true)) + " sortable=\"true\" title=\"Schlagwort\"" + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + "><ui-select-match placeholder=\"Style wählen...\">{{$item}}</ui-select-match><ui-select-choices repeat=\"item in vm.stylesList | filter:$select.search\">{{ item }}</ui-select-choices></ui-select></label></div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.objektkatalog", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<select" + (jade.attr("ng-model", "" + ( model ) + ".objektkatalog", true, true)) + " name=\"type\" aria-required=\"true\" role=\"listbox\"" + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control\"><option value=\"\">--- Bitte wählen Sie ---</option><option ng-repeat=\"item in vm.objektkatalogList\" value=\"{{ item.pk }}\">{{ item.bez }}</option></select>");
}
}, "Objektbezogener Katalog*");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.objektkatalog_spalte_id", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<select" + (jade.attr("ng-model", "" + ( model ) + ".objektkatalog_spalte_id", true, true)) + " name=\"objektkatalog_spalte_id\" aria-required=\"true\" role=\"listbox\"" + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control\"><option value=\"\">--- Bitte wählen Sie ---</option><option ng-repeat=\"item in vm.objektkatalogCols\" value=\"{{ item.id }}\">{{ item.label }}</option></select>");
}
}, "Spalte mit objektbezogener Auswahlliste*");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.objektkatalog_data_id", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input type=\"string\" name=\"objektkatalog_data_id\"" + (jade.attr("ng-model", "" + ( model ) + ".objektkatalog_data_id", true, true)) + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control m-0\">");
}
}, "Katalogdaten-ID");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.katalog", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<select" + (jade.attr("ng-model", "" + ( model ) + ".katalog", true, true)) + " name=\"type\" aria-required=\"true\" role=\"listbox\"" + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + (jade.attr("ng-change", "vm.changeSelectKatalog({ position: " + ( position ) + " })", true, true)) + " class=\"form-control\"><option value=\"\">--- Bitte wählen Sie ---</option><option ng-repeat=\"item in vm.katalogList\" value=\"{{ item.pk }}\">{{ item.bez }}</option></select>");
}
}, "Katalog*");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.komplett && " + ( model ) + ".katalog", true, true)) + "><div class=\"checkbox-btn pb-2\"><input type=\"checkbox\"" + (jade.attr("ng-attr-id", "check_dyn_tabelle_{{ " + ( position ) + " }}_komplett", true, true)) + (jade.attr("ng-model", "" + ( model ) + ".komplett", true, true)) + " name=\"komplett\"" + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + (jade.attr("ng-change", "vm.changeSelectKomplet({ position: " + ( position ) + ", model: " + ( model ) + " })", true, true)) + "><label" + (jade.attr("ng-attr-for", "check_dyn_tabelle_{{ " + ( position ) + " }}_komplett", true, true)) + ">Alle Katalogeinträge</label></div></div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.ids && !" + ( model ) + ".komplett", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<dias-ui-select" + (jade.attr("select-list", "vm.katalogListObj[" + ( model ) + ".katalog]", true, true)) + (jade.attr("select-model-multiselect", "" + ( model ) + ".ids", true, true)) + " select-choices-template=\"'dynTableAuswahlChoicesTemplate.html'\" select-placeholder=\"'Bitte wählen Sie'\"" + (jade.attr("select-id", "'komplett' + " + ( position ) + "", true, true)) + " select-label-id=\"'pk'\" multiselect=\"true\"" + (jade.attr("select-disabled", "" + ( disabled ) + "", true, true)) + "></dias-ui-select>");
}
}, "Einzelne Katalogeinträge*");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.max_length", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input type=\"number\" name=\"max_length\"" + (jade.attr("ng-model", "" + ( model ) + ".max_length", true, true)) + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control m-0\">");
}
}, "Maximale Länge");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.expression", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input type=\"string\" name=\"expression\"" + (jade.attr("ng-model", "" + ( model ) + ".expression", true, true)) + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control m-0\">");
}
}, "Berechnungsformel");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.signal_regex", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input type=\"string\" name=\"signal_regex\"" + (jade.attr("ng-model", "" + ( model ) + ".signal_regex", true, true)) + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control m-0\">");
}
}, "Regulärer Ausdruck für Signalempfang");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.decimal_places", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input type=\"number\" name=\"max\"" + (jade.attr("ng-change", "vm.inputDecimalPlaces(" + ( model ) + ")", true, true)) + (jade.attr("ng-model", "" + ( model ) + ".decimal_places", true, true)) + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control m-0\">");
}
}, "Nachkommastellen*");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.max_digits", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input type=\"number\" name=\"max\"" + (jade.attr("ng-model", "" + ( model ) + ".max_digits", true, true)) + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control m-0\">");
}
}, "Max Ziffern*");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.max_value", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input type=\"number\" name=\"max\"" + (jade.attr("ng-model", "" + ( model ) + ".max_value", true, true)) + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control m-0\">");
}
}, "Max");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.min_value", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input type=\"number\" name=\"max\"" + (jade.attr("ng-model", "" + ( model ) + ".min_value", true, true)) + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control m-0\">");
}
}, "Min");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.dokart", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<select" + (jade.attr("ng-model", "" + ( model ) + ".dokart", true, true)) + " name=\"type\" aria-required=\"true\" role=\"listbox\"" + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control\"><option value=\"\">--- Bitte wählen Sie ---</option><option ng-repeat=\"item in vm.dokartList\" value=\"{{ item.pk }}\">{{ item.name }}</option></select>");
}
}, "Dokart*");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.allowed_mime_type", true, true)) + "><label class=\"with-border\"><span class=\"titel\">Dateiformate*</span><ui-select multiple" + (jade.attr("ng-model", "" + ( model ) + ".allowed_mime_type", true, true)) + " sortable=\"true\" title=\"Dokumentformate\"" + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + "><ui-select-match placeholder=\"--- Bitte wählen Sie ---\">{{  $item }}</ui-select-match><ui-select-choices repeat=\"item in vm.allowedMimeTypeList | filter:$select.search\">{{ item }}</ui-select-choices></ui-select></label></div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.isPreview", true, true)) + "><div class=\"checkbox-btn pb-2\"><input type=\"checkbox\"" + (jade.attr("ng-attr-id", "check_dyn_tabelle_{{ " + ( position ) + " }}_is_preview", true, true)) + (jade.attr("ng-model", "" + ( model ) + ".isPreview", true, true)) + " name=\"is_preview\"" + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + "><label" + (jade.attr("ng-attr-for", "check_dyn_tabelle_{{ " + ( position ) + " }}_is_preview", true, true)) + ">Preview anzeigen</label></div></div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.defaultValue", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<select" + (jade.attr("ng-model", "" + ( model ) + ".defaultValue", true, true)) + " name=\"type\" aria-required=\"true\" role=\"listbox\"" + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control\"><option value=\"\">--- Bitte wählen Sie ---</option><option ng-repeat=\"item in vm.defaultValueList\" value=\"{{ item.value }}\">{{ item.label }}</option></select>");
}
}, "Vorbelegung");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.sum", true, true)) + "><div class=\"checkbox-btn pb-2\"><input type=\"checkbox\"" + (jade.attr("ng-attr-id", "check_dyn_tabelle_{{ " + ( position ) + " }}_sum", true, true)) + (jade.attr("ng-model", "" + ( model ) + ".sum", true, true)) + " name=\"sum\"" + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + "><label" + (jade.attr("ng-attr-for", "check_dyn_tabelle_{{ " + ( position ) + " }}_sum", true, true)) + ">Summe anzeigen</label></div></div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.trueLabel", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input type=\"text\" name=\"label\"" + (jade.attr("ng-model", "" + ( model ) + ".trueLabel", true, true)) + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control m-0\">");
}
}, "Label aktiv");
buf.push("</div><div" + (jade.attr("ng-if", "vm.Parameters[" + ( model ) + ".type].options.falseLabel", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input type=\"text\" name=\"label\"" + (jade.attr("ng-model", "" + ( model ) + ".falseLabel", true, true)) + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control m-0\">");
}
}, "Label inaktiv");
buf.push("</div><div" + (jade.attr("ng-class", "{'text-danger': vm.newIdError === " + ( position ) + "}", true, true)) + ">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input type=\"text\" name=\"id\"" + (jade.attr("ng-model", "" + ( model ) + ".id", true, true)) + (jade.attr("ng-change", "vm.changeId({ position: " + ( position ) + " })", true, true)) + " ng-trim=\"false\" aria-required=\"true\"" + (jade.attr("ng-disabled", "" + ( disabled ) + "", true, true)) + " class=\"form-control m-0\"><div" + (jade.attr("ng-if", "vm.newIdError === " + ( position ) + "", true, true)) + " class=\"help-text error-text\">ID bereits vergeben</div>");
}
}, "Technische ID*");
buf.push("</div></div>");
};
buf.push("<div ng-if=\"vm.wertList.length\"><div ng-repeat-start=\"antwort in vm.wertList | orderBy: 'pos' track by antwort.id\" class=\"clearfix\"><strong>{{ $index + 1 }}. {{ antwort.label }}</strong><div class=\"btn-group float-right\"><button type=\"button\" title=\"Nach oben schieben\" ng-click=\"vm.moveUpAntwort(antwort.pos)\" ng-disabled=\"vm.statusEdit[antwort.pos]\" class=\"btn btn-default\"><i class=\"glyphicon glyphicon-chevron-up\"></i><span class=\"sr-only\">Nach oben schieben</span></button><button type=\"button\" title=\"Nach unten schieben\" ng-click=\"vm.moveDownAntwort(antwort.pos)\" ng-disabled=\"vm.statusEdit[antwort.pos]\" class=\"btn btn-default\"><i class=\"glyphicon glyphicon-chevron-down\"></i><span class=\"sr-only\">Nach unten schieben</span></button><button type=\"button\" title=\"Antwortmöglichkeit editieren\" ng-click=\"vm.toggleEditMod({ position: antwort.pos, antwort })\" ng-disabled=\"vm.statusEdit[antwort.pos]\" class=\"btn btn-default\"><i class=\"glyphicon glyphicon-pencil\"></i></button><button type=\"button\" title=\"Antwortmöglichkeit entfernen\" dias-confirm confirm-title=\"Antwortmöglichkeit entfernen\" confirm-msg=\"Sind Sie sicher, dass sie die Antwortmöglichkeit von dieser Regel entfernen wollen?\" confirm-type=\"danger\" confirm-ok=\"vm.removeAntwort(antwort.pos)\" ng-disabled=\"vm.statusEdit[antwort.pos]\" class=\"btn btn-primary\"><i class=\"glyphicon glyphicon-trash\"></i><span class=\"sr-only\">Antwortmöglichkeit löschen</span></button></div><div ng-if=\"vm.statusEdit[antwort.pos]\">");
jade_mixins["param-form"]("vm.modelEdit[antwort.pos]", "undefined", "antwort.pos");
buf.push("<div class=\"text-right\"><button type=\"submit\" ng-disabled=\"vm.isDisabled({ model: vm.modelEdit[antwort.pos] })\" ng-click=\"vm.editColumn({ position: antwort.pos })\" class=\"btn btn-primary mr-1\"><i class=\"glyphicon glyphicon-plus\">&nbsp;</i><span>Speichern</span></button><button type=\"button\" ng-click=\"vm.toggleEditMod({ position: antwort.pos, status: true })\" class=\"btn btn-default\"><i class=\"glyphicon glyphicon-plus\">&nbsp;</i><span>Abbrechen</span></button></div></div></div><hr ng-repeat-end></div><div class=\"no-error\">");
jade_mixins["param-form"]("vm.model", "undefined", "'new'");
buf.push("<div class=\"col-lg-12 no-padding\"><button type=\"button\" ng-disabled=\"vm.isDisabled({ model: vm.model })\" ng-click=\"vm.addColumn()\" class=\"btn btn-primary float-right\"><i class=\"glyphicon glyphicon-plus\">&nbsp;</i><span>Spalte hinzufügen</span></button></div><div class=\"clearfix\"></div><hr></div>");;return buf.join("");
}