import FormstepDetail from "../../../global/components/FormstepDetail/FormstepDetail.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import HttpMixin from "../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import {
  FilterDateMixin,
  FilterDefaultForEmptyMixin,
} from "../../../global/mixins/FiltersMixin";
import translate from "../../../global/directives/translate";
import {
  cloneDeep,
  find,
} from "lodash-es";

// @vue/component
export default {
  name: "Vertretungsberechtigter",
  components: {
    FormstepDetail,
    Modal,
  },
  directives: {
    translate,
  },
  mixins: [
    FilterDateMixin,
    FilterDefaultForEmptyMixin,
    HttpMixin,
    NotificationMixin,
    SyConfigMixin,
  ],
  props: {
    displayGueltigkeit: {
      type: Boolean,
      default: true,
    },
    vertreter: {
      type: Object,
      required: true,
    },
    updateVertreter: {
      type: Function,
      required: true
    },
    deleteVertreter: {
      type: Function,
      required: true
    },
    vertreterIndex: {
      type: Number,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    isDeletable: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      options: {
        label: "_TXT_VERTRETUNGSBERECHTIGTER_FORMSTEP_NAME_{{pos}}_",
        extra: { pos: this.vertreterIndex },
        list: [
          {
            id: "anrede",
            type: "select",
            label: "_LBL_VERTRETUNGSBERECHTIGTER_ANREDE_",
            required: true,
            view: "v",
            keyId: "pk",
            keyLabel: "ank_kbez",
            url: "katalog/?fields=pk&fields=ank_kbez&key=kanrede",
            translate: true,
            showLabel: true,
          },
          {
            id: "av_titel",
            type: "text",
            label: "_LBL_VERTRETUNGSBERECHTIGTER_TITEL_",
            maxlength: 200,
            view: "v",
            translate: true,
            showLabel: true,
            hideIf: false,
          },
          {
            id: "titel",
            type: "select",
            label: "_LBL_VERTRETUNGSBERECHTIGTER_TITEL_",
            view: "v",
            keyId: "pk",
            keyLabel: "kti_kbez",
            url: "katalog/?key=titel",
            hideIf: false,
            deselect: true,
          },
          {
            id: "av_vorname",
            type: "text",
            label: "_LBL_VERTRETUNGSBERECHTIGTER_VORNAME_",
            maxlength: 200,
            view: "v",
            translate: true,
            showLabel: true,
          },
          {
            id: "av_name",
            type: "text",
            label: "_LBL_VERTRETUNGSBERECHTIGTER_NACHNAME_",
            required: true,
            maxlength: 200,
            view: "v",
            translate: true,
            showLabel: true,
          },
          {
            id: "av_email",
            type: "text",
            label: "_LBL_VERTRETUNGSBERECHTIGTER_EMAIL_",
            maxlength: 200,
            view: "v",
            translate: true,
            showLabel: true,
          },
          {
            id: "av_funktion",
            type: "text",
            required: true,
            label: "_LBL_VERTRETUNGSBERECHTIGTER_FUNKTION_",
            maxlength: 100,
            view: "v",
            translate: true,
            showLabel: true,
          },
          {
            id: "berechtigung",
            type: "select",
            required: true,
            label: "_LBL_VERTRETUNGSBERECHTIGTE_MODAL_CREATE_BERECHTIGUNG_",
            keyId: "id",
            keyLabel: "kvb_kbez",
            url: "katalog/?fields=id&fields=kvb_kbez&key=vertretungsberechtigung",
            view: "v",
            translate: true,
            showLabel: true,
          },
          {
            id: "av_gueltig_von",
            type: "date",
            required: true,
            label: "_LBL_VERTRETUNGSBERECHTIGTER_GUELTIG_VON_",
            view: "v",
            translate: true,
            showLabel: true,
            editonly: !this.displayGueltigkeit
          },
          {
            id: "av_gueltig_bis",
            type: "date",
            slotReadonly: "gueltig_bis",
            label: "_LBL_VERTRETUNGSBERECHTIGTER_GUELTIG_BIS_",
            view: "v",
            translate: true,
            showLabel: true,
            editonly: !this.displayGueltigkeit,
          },
        ],
        required: true,
        saveCallback: this.save,
        deleteCallback: this.openModalDeleteVerteter,
      },
      confirmShow: undefined,
      confirmOptions: undefined,
    };
  },
  created() {
    this.setVertreterFields();
  },
  methods: {
    save({ model }) {
      const DATA = cloneDeep(model);
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: `${ this.url }${ this.vertreter.pk }/`,
          data: DATA,
        }).then(
          response => {
            this.updateVertreter({ vertreter: response });
            this.addNotification({ text: "_MSG_VERTRETUNGSBERECHTIGTER_EDIT_SUCCESS_" });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },

    openModalDeleteVerteter() {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_VERTRETUNGSBERECHTIGTER_DELETE_MODAL_HEADER_",
        msg: "_TXT_VERTRETUNGSBERECHTIGTER_DELETE_MODAL_BODY_{{name}}_",
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: () => this.delete(),
        cancelCallback: this.closeConfirmVertreter,
        extra: {
          name: this.vertreter.av_name,
        },
      };
      this.confirmShow = true;
    },

    closeConfirmVertreter() {
      this.confirmShow = false;
    },

    delete() {
      return new Promise((resolve, reject) => {
        this.deleteHttp({
          url: `${ this.url }${ this.vertreter.pk }/`,
        }).then(
          () => {
            this.deleteVertreter({ vertreter: this.vertreter });
            this.addNotification({ text: "_MSG_VERTRETUNGSBERECHTIGTER_DELETE_SUCCESS_" });
            resolve();
          },
          error => {
            reject(error);
          }
        ).then(
          this.closeConfirmVertreter()
        );
      });
    },

    setVertreterFields() {
      const CONFIG = this.getSyConfigsValue("vertretungsberechtigte") || {};
      find(this.options.list, ["id", "av_titel"]).hideIf = CONFIG.titel_katalog || CONFIG.hide_titel || false ;
      find(this.options.list, ["id", "titel"]).hideIf = !CONFIG.titel_katalog || CONFIG.hide_titel || false ;
      find(this.options.list, ["id", "av_titel"]).showLabel = !CONFIG.titel_katalog && !CONFIG.hide_titel || false;
      find(this.options.list, ["id", "titel"]).showLabel = CONFIG.titel_katalog && !CONFIG.hide_titel || false;
    },
  },
};
