import Permission from "../../../global/components/Permission/Permission";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";
import {
  EventBus,
} from "../../../global/functions/event-bus";

// @vue/component
export default {
  name: "DokumenteHistory",
  components: {
    Permission,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    docAnzeige: {
      type: String,
      default: undefined,
    },
    url: {
      type: String,
      required: true,
    },
    docLimit: {
      type: [String, Number],
      default: 3,
    },
    docUpdateEvent: {
      type: String,
      default: "dokumente.update",
    },
  },
  setup() {
    const {
      filterDate,
      filterFileSize,
    } = FiltersAPI();

    return {
      filterDate,
      filterFileSize,
    };
  },
  data() {
    return {
      lastDoks: [],
      loading: true,
      permission: "dokument.view",
    };
  },
  created() {
    this.checkPerm();
    this.initEventBus();
  },
  beforeUnmount() {
    this.destroyEventBus();
  },
  methods: {
    checkPerm() {
      if (this.checkPermissionsSync({ perm: this.permission })) {
        this.initData();
      }
    },

    initData() {
      this.getHttp({
        url: `${ this.url }dokumente/`,
        urlParams: {
          anzeige: this.docAnzeige,
          last: this.docLimit,
        },
      }).then(
        response => {
          this.lastDoks = response;
          this.loading = false;
        }
      );
    },
    initEventBus() {
      EventBus.$on(this.docUpdateEvent, this.checkPerm);
    },

    destroyEventBus() {
      EventBus.$off(this.docUpdateEvent, this.checkPerm);
    },
  },
};
