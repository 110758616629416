"use strict";

import template from "./template.jade";

class PersonalkostenrechnerController {
  /*@ngInject*/
  constructor($scope, Personalstelle, i18n, AuthService) {
    this.gettext = i18n.gettext;
    this.AuthService = AuthService;
    this.Personalstelle = Personalstelle;
    if (!this.kontext) {
      this.kontext = "antrag";
    }
    this.$scope = $scope;
    this._init_values();
  }

  asPruefer() {
    return this.AuthService.syncHasPerm("vertragskostenpositionsbelege.anerkennen", this.posmodel.user_permissions);
  }

  _init_values() {
    // Die Erfassung der Personalstelle (z.B. aufgestockte Personalstelle)
    // ist nur im Vertragskontext erforderlich:
    if (this.kontext === "beleg") {
      this.Personalstelle.getList().then(result => {
        this.personalstellen = result;
      });
    }
  }
}

export default {
  template: template(),
  controller: PersonalkostenrechnerController,
  controllerAs: "ctx",
  bindings: {
    rechner: "=",
    posmodel: "=",
    errors: "=",
    extras: "=",
    kontext: "=?",
    vorschau: "=",
    prefix: "<?"
  },
};
