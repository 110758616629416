import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "AuszahlungGoToBoxAuszahlungLink",
  components: {
    AngularLink,
    PuxTranslate,
  },
  props: {
    auszahlung: {
      type: Object,
      required: true,
    },
    vertragPk: {
      type: String,
      required: true,
    },
  },
  computed: {
    extraForTranslate() {
      return {
        az_name: this.auszahlung.praefix || this.auszahlung.name,
        az_referenznr: this.auszahlung.az_referenznr,
      };
    },
  },
};
