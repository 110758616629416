import PruefkriteriumIcon from "../PruefkriteriumIcon/PruefkriteriumIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import translate from "../../../../../global/directives/translate";
import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "PruefkriterienEditDetails",
  components: {
    PruefkriteriumIcon,
    PuxTranslate,
  },
  directives: {
    translate,
  },
  props: {
    currentModel: {
      type: Object,
      required: true,
    },
    detailsTranslateMap: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentModelLocal() {
      return this.currentModel || {};
    },

    pruefkriteriumDetails() {
      return get(this.currentModelLocal, "pruefkriterium.details", []);
    },

    subkriterien() {
      return this.currentModelLocal.opr_subkriterien || {};
    },
  },
};
