"use strict";

import appInit from "dias/core/appInit";
import Components from "./components";
import {
  initApp,
} from "../../../../libs/vue/client/vue/global/functions/checkBrowser";

initApp(loadApp);

function loadApp() {
  if (window.bootstrapDiasSpa) {
    appInit.bootstrapAngularApp(Components);
  }
}
