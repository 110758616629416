import {
  filter,
  get,
  isUndefined,
  sortBy,
} from "lodash-es";

// @vue/component
export default {
  data() {
    return {
      relevanteAuszahlungen: [],
      prefix: {
        kosten: {
          beleg: "vpb",
          position: "vkp",
          vertragsPos: "vertragskostenposition",
          art: "kostenart",
          bezeichnung: "kos",
          vkofi: "vk",
          artdetail: "kod",
          dokart: "2f413911-a2dc-c179-e050-007f0101705e",
          key: "kosten",
        },
        finanzierung: {
          beleg: "vfb",
          position: "vfp",
          vertragsPos: "vertragsfinanzierungposition",
          art: "finanzierungsart",
          bezeichnung: "fin",
          vkofi: "vf",
          artdetail: "fid",
          dokart: "5f681393-a2d3-4423-ba35-54d09156651b",
          dokument_required: false,
          key: "finanzierung",
        },
      },
      maxBetragPerKofiart: {},
    };
  },
  computed: {
    apiBase() {
      return `${ this.apiVertrag }${ this.apiAuszahlung }`;
    },

    apiAuszahlung() {
      return `auszahlungen/${ this.auszahlung.pk }/`;
    },

    apiVertrag() {
      return `vertraege/${ this.vertrag.pk }/`;
    },

    apiAntrag() {
      return `foerderantraege/${ this.antrag.pk }/`;
    },

    perioden() {
      const PERIODE = get(this.antrag, "regelsatz_obj.perioden", []);
      return sortBy(PERIODE, ["pe_von"]);
    },

    durchfuehrungBis() {
      return get(this.vertrag, "v_durchfuehrung_bis");
    },

    durchfuehrungVon() {
      return get(this.vertrag, "v_durchfuehrung_von");
    },

    isDurchfuehrung() {
      return !!(this.durchfuehrungBis && this.durchfuehrungVon);
    }
  },
  methods: {
    filterKoFi({ response, labelKoFi, filterCollection = true }) {
      let KO_FI = response;
      const POSITIONEN = [];
      const KO_FI_STATUSES = {
        erfasst: 0,
        anerkannt: 0,
        bewilligt: 0,
      };
      let keyKoFiPos = "";
      let keyBelegBetragErfasst = "";
      let keyBelegBetragAnerkannt = "";
      let keyPositionBetragBewilligt = "";
      let keyKoFiBetragBewilligt = "";
      if (labelKoFi === "finanzierung") {
        keyKoFiPos = "vf_pos";
        keyBelegBetragErfasst = "vfb_betrag_erfasst";
        keyBelegBetragAnerkannt = "vfb_betrag_anerkannt";
        keyPositionBetragBewilligt = "vfp_betrag_bewilligt";
        keyKoFiBetragBewilligt = "vf_betrag_bewilligt";
        // Für die Finanzierungssicht die Pauschalen rausfiltern:
        if (filterCollection) {
          KO_FI = this.filterKoFiCollection(KO_FI);
        }
      } else if (labelKoFi === "kosten") {
        keyKoFiPos = "vk_pos";
        keyBelegBetragErfasst = "vpb_betrag_erfasst";
        keyBelegBetragAnerkannt = "vpb_betrag_anerkannt";
        keyPositionBetragBewilligt = "vkp_betrag_bewilligt";
        keyKoFiBetragBewilligt = "vk_betrag_bewilligt";
      }
      for (let i = 0; i < KO_FI.length; i++) {
        const ITEM_I = KO_FI[i];
        const KO_FI_PK = ITEM_I.pk;
        KO_FI[i].erfasst = 0;
        KO_FI[i].anerkannt = 0;
        KO_FI[i].bewilligt = 0;
        let max_betrag = null;
        for (let j = 0; j < ITEM_I.positionen.length; j++) {
          const ITEM_J = ITEM_I.positionen[j];
          if (!isUndefined(this.maxBetragPerKofiart[KO_FI_PK])) {
            max_betrag = this.maxBetragPerKofiart[KO_FI_PK];
          } else if (ITEM_J.antragkostenartinfo) {
            this.maxBetragPerKofiart[KO_FI_PK] = get(ITEM_J.antragkostenartinfo, "antragsregel.afr_argument.max_betrag.wert", null);
            max_betrag = this.maxBetragPerKofiart[KO_FI_PK];
          }
          ITEM_J.oberpunkt = ITEM_I[keyKoFiPos];
          POSITIONEN.push(ITEM_J);
          ITEM_J.erfasst = 0;
          ITEM_J.anerkannt = 0;
          for (let k = 0; k < ITEM_J.belege.length; k++) {
            const ITEM_K = ITEM_J.belege[k];
            ITEM_J.erfasst += +ITEM_K[keyBelegBetragErfasst];
            ITEM_J.anerkannt += +ITEM_K[keyBelegBetragAnerkannt];
          }

          ITEM_I.erfasst += +ITEM_J.erfasst;
          ITEM_I.anerkannt += +ITEM_J.anerkannt;
          ITEM_I.bewilligt += +ITEM_J[keyPositionBetragBewilligt];
          if (max_betrag) {
            ITEM_I.max_betrag = max_betrag;
            ITEM_I.erfasst = Math.min(max_betrag, ITEM_I.erfasst);
            ITEM_I.erfasst_gekappt = max_betrag === ITEM_I.erfasst;
            ITEM_I.anerkannt = Math.min(max_betrag, ITEM_I.anerkannt);
            ITEM_I.anerkannt_gekappt = max_betrag === ITEM_I.anerkannt;
            ITEM_I.bewilligt = Math.min(max_betrag, ITEM_I.bewilligt);
            ITEM_I.bewilligt_gekappt = max_betrag === ITEM_I.bewilligt;
          }
        }

        KO_FI_STATUSES.erfasst += ITEM_I.erfasst;
        KO_FI_STATUSES.anerkannt += ITEM_I.anerkannt;
        KO_FI_STATUSES.bewilligt += +ITEM_I[keyKoFiBetragBewilligt];
      }
      return {
        positionen: POSITIONEN,
        koFi: KO_FI,
        koFiStatuses: KO_FI_STATUSES,
      };
    },

    filterKoFiCollection(KO_FI) {
      return filter(KO_FI, ITEM => {
        return !(ITEM.modulgruppen &&
          (ITEM.modulgruppen.indexOf("pauschale") !== -1 ||
          ITEM.modulgruppen.indexOf("zuschuss") !== -1 ||
          ITEM.modulgruppen.indexOf("deckelung") !== -1));
      });
    },

    loadRelevanteAuszahlungen() {
      // Relevant für die Anzeige sind alle vorhergehenden Auszahlungen (und die eigene)
      if (this.auszahlung) {
        const RELEVANTE_AUSZAHLUNGEN = [];
        for (let i = 0; i < this.auszahlung.vorherige_auszahlungen.length; i++) {
          RELEVANTE_AUSZAHLUNGEN.push(this.auszahlung.vorherige_auszahlungen[i].pk);
        }
        RELEVANTE_AUSZAHLUNGEN.push(this.auszahlung.pk);
        this.relevanteAuszahlungen = RELEVANTE_AUSZAHLUNGEN;
      }
    },
  },
};
