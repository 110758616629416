"use strict";

import paramObject from "./objects";
import paramAuszahlungsmodus from "./auszahlungsmodus";
import paramAstorgFilter from "./astorg_filter";
import paramAuszahlungFilter from "./auszahlung_filter";
import paramAuszahlungsplanFilter from "./auszahlungsplan_filter";
import paramKostenbelegFilter from "./kostenbeleg_filter";
import paramSitzungsantragFilter from "./sitzungsantrag_filter";
import paramVertragFilter from "./vertrag_filter";
import paramStrMapping from "./str_mapping";
import paramStatusAntragBedingterNachfolgeworkflow from "./status_antrag_bedingter_nachfolgeworkflow";

const wf_params = angular.module("dias.standard_workflowmodule.parameter", [
  paramObject.name,
  paramAuszahlungsmodus.name,
  paramAstorgFilter.name,
  paramAuszahlungFilter.name,
  paramAuszahlungsplanFilter.name,
  paramKostenbelegFilter.name,
  paramSitzungsantragFilter.name,
  paramVertragFilter.name,
  paramStrMapping.name,
  paramStatusAntragBedingterNachfolgeworkflow.name,
]);

export default wf_params;
