import FormularListeBoxItem from "../FormularListeBoxItem/FormularListeBoxItem.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";

import {
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "FormularListeBox",
  components: {
    FormularListeBoxItem,
  },
  props: {
    objektContentType: {
      type: Number,
      required: true,
    },
    objektPk: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
    isObjectReloading: {
      type: Boolean,
      required: false,
    },
  },
  emits: [
    "update:modelValue",
  ],
  setup() {
    const {
      getListHttp,
    } = HttpAPI();

    return {
      getListHttp,
    };
  },
  data() {
    return {
      formulare: [],
    };
  },
  computed: {
    baseUrl() {
      return `genericformulare/${ this.objektContentType }/object/${ this.objektPk }/formulare/`;
    },
  },
  watch: {
    isObjectReloading() {
      if (!this.isObjectReloading) {
        this.loadFormulare();
      }
    },
  },
  created() {
    this.loadFormulare();
  },
  methods: {
    loadFormulare() {
      this.getListHttp({
        url: this.baseUrl,
      }).then(
        response => {
          this.filterFormulare(response);
          this.setVisibilityForCurrentComponent();
        }
      );
    },

    filterFormulare(response) {
      this.formulare = filter(response, formular => {
        if (!formular.permissionset || !formular.user_permissions) {
          return true;
        }
        return formular.user_permissions.indexOf(`${ formular.permissionset }.view`) !== -1;
      });
    },

    setVisibilityForCurrentComponent() {
      this.$emit("update:modelValue", this.formulare.length > 0);
    },
  },
};
