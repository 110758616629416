import FormstepDetail from "../../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import FormElement from "../../../../../../global/components/FormElement/FormElement.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import WizardStepsErrorsMixin from "../../WizardStepsErrorsMixin";
import WizardStepsRechnerMixin from "../../WizardStepsRechnerMixin";
import WizardStepsFieldsChangeWarningMixin from "../../WizardStepsFieldsChangeWarningMixin";
import WizardStepsPeriodenMixin from "../../WizardStepsPeriodenMixin";
import FieldsMixin from "./FieldsMixin";
import translate from "../../../../../../global/directives/translate";
import WizardStepsMessageObergrenzeMixin from "../../WizardStepsMessageObergrenzeMixin";
import {
  cloneDeep,
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenPeriodenWizardStep2",
  components: {
    FormElement,
    FormstepDetail,
    PuxTranslate,
  },
  directives: {
    translate,
  },
  mixins: [
    WizardStepsErrorsMixin,
    WizardStepsRechnerMixin,
    WizardStepsFieldsChangeWarningMixin,
    WizardStepsPeriodenMixin,
    FieldsMixin,
    WizardStepsMessageObergrenzeMixin,
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errorLabels: {},
    };
  },
  computed: {
    getStellenumfangProjektNumber() {
      return this.getNumber({ item: this.model.stellenumfang_projekt });
    },

    getANBruttogehaltFuerPersonImProjekt() {
      const JAHRESBRUTTO = this.getNumber({ item: this.model.jahresbrutto });
      if (isUndefined(JAHRESBRUTTO)) {
        return undefined;
      }

      const STELLENUMFANG_PROJEKT = this.getNumber({ item: this.model.stellenumfang_projekt });
      if (isUndefined(STELLENUMFANG_PROJEKT)) {
        return undefined;
      }

      const VOLLZEITSTELLE = this.getNumber({ item: this.model.vollzeitstelle });
      if (isUndefined(VOLLZEITSTELLE)) {
        return undefined;
      }
      return JAHRESBRUTTO * STELLENUMFANG_PROJEKT / VOLLZEITSTELLE;
    },

    getAGBruttogehaltVollzeitstelle() {
      const AG_BRUTTOGEHALT_VOLLZEITSTELLE = this.getNumber({ item: this.model.ag_bruttogehalt_vollzeitstelle });
      if (isUndefined(AG_BRUTTOGEHALT_VOLLZEITSTELLE)) {
        return undefined;
      }

      const STELLENUMFANG_PROJEKT = this.getNumber({ item: this.model.stellenumfang_projekt });
      if (isUndefined(STELLENUMFANG_PROJEKT)) {
        return undefined;
      }

      const VOLLZEITSTELLE = this.getNumber({ item: this.model.vollzeitstelle });
      if (isUndefined(VOLLZEITSTELLE)) {
        return undefined;
      }
      return AG_BRUTTOGEHALT_VOLLZEITSTELLE * STELLENUMFANG_PROJEKT / VOLLZEITSTELLE;
    },

    getTranslateOptionsGeplanteGehaltssteigerung() {
      return {
        text: "_TXT_ANTRAG_DETAILS_PERSONAL_WIZARD_GEPLANTE_GEHALTSSTEIGERUNG_{{pktLohnsteigerung}}_",
        extra: {
          pktLohnsteigerung: this.getPktLohnsteigerung,
        },
      };
    },

    zeitraum_vonLocal() {
      const OPTIONS = cloneDeep(this.fields.zeitraum_von);
      OPTIONS.notAfter = this.model.zeitraum_bis;
      OPTIONS.notBefore = this.durchfuehrungszeitraumVon;
      return OPTIONS;
    },

    zeitraum_bisLocal() {
      const OPTIONS = cloneDeep(this.fields.zeitraum_bis);
      OPTIONS.notAfter = this.durchfuehrungszeitraumBis;
      OPTIONS.notBefore = this.model.zeitraum_von;
      return OPTIONS;
    },
  },
  created() {
    this.setStellenumfangProjektMaxValue();
    this.initErrorLabels_mixin();
  },
  methods: {
    setStellenumfangProjektMaxValue() {
      const STELLENUMFANG = this.getNumber({ item: this.model.stellenumfang });
      if (isUndefined(STELLENUMFANG)) {
        this.fields.stellenumfang_projekt.maxValue = 50;
        return;
      }
      this.fields.stellenumfang_projekt.maxValue = STELLENUMFANG;
    },
  },
};
