"use strict";

import angular from "angular";
import notificationComponent from "./notification.component";
import diasNotification from "dias/core/notification";

const notificationModule = angular.module("notification", [
  diasNotification.name
])

  .component("notification", notificationComponent);

export default notificationModule;
