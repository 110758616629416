import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";

import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "BerichteVerwaltungDetailsKonfiguration",
  components: {
    FormstepDetail,
  },
  props: {
    tableau: {
      type: Object,
      required: true,
      info: "Hauptinformation Tableau",
    },
  },
  emits: [
    "updateTableau",
  ],
  setup() {
    const {
      addNotification,
      putHttp,
    } = HttpAPI();

    return {
      addNotification,
      putHttp,
    };
  },
  data() {
    return {
      options: {
        label: "_TXT_BERICHTE_VERWALTUNG_DETAILS_KONFIGURATION_PANEL_FORMSTEP_NAME_",
        list: [
          {
            id: "tab_kbez",
            type: "text",
            label: "_LBL_BERICHTE_VERWALTUNG_DETAILS_KURZ_BEZEICHNUNG_",
            showLabel: true,
            view: "v-alt",
            required: true,
            translate: true,
          },
          {
            id: "tab_bez",
            type: "richtext",
            label: "_LBL_BERICHTE_VERWALTUNG_DETAILS_LANG_BEZEICHNUNG_",
            showLabel: true,
            view: "v-alt",
            translate: true,
          },
          {
            id: "tab_pos",
            type: "number",
            label: "_LBL_BERICHTE_VERWALTUNG_DETAILS_POSITION_",
            showLabel: true,
            view: "v-alt",
            required: true,
            translate: true,
          },
          {
            id: "tab_sichtbar",
            type: "boolean",
            label: "_LBL_BERICHTE_VERWALTUNG_DETAILS_SICHTBAR_",
            showLabel: true,
            view: "v-alt",
            translate: true,
          },
          {
            id: "rollen_ids",
            type: "multiselect",
            label: "_LBL_BERICHTE_VERWALTUNG_DETAILS_ROLLEN_",
            showLabel: true,
            url: "rollen/",
            keyLabel: "bez",
            keyId: "id",
            placeholder: "Alle",
            search: true,
            searchList: ["bez", "kbez"],
            helpText: undefined,
            view: "v-alt",
            translate: true
          },
          {
            id: "tab_bericht",
            type: "text",
            label: "_LBL_BERICHTE_VERWALTUNG_DETAILS_BERICHT_",
            showLabel: true,
            view: "v-alt",
            required: true,
            translate: true,
          },
          {
            id: "typ",
            type: "select",
            label: "_LBL_BERICHTE_VERWALTUNG_DETAILS_TYP_",
            showLabel: true,
            view: "v-alt",
            required: true,
            keyId: "pk",
            keyLabel: "bt_kbez",
            url: "berichttypen/",

            translate: true,
          },
          {
            id: "tab_hoehe",
            type: "number",
            label: "_LBL_BERICHTE_VERWALTUNG_DETAILS_HOEHE_",
            showLabel: true,
            view: "v-alt",
            required: true,
            translate: true,
          },
          {
            id: "tab_argument",
            type: "json",
            label: "_LBL_BERICHTE_VERWALTUNG_DETAILS_ARGUMENT_",
            showLabel: true,
            view: "v-alt",
            required: false,
            translate: true,
          },
        ],
        required: true,
        saveCallback: this.save,
      },
    };
  },
  methods: {
    save({ model }) {
      const DATA = cloneDeep(model);
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: `tableau/${ this.tableau.pk }/`,
          data: DATA,
        }).then(
          response => {
            this.$emit("updateTableau", { tableau: response });
            this.addNotification({ text: "_MSG_BERICHTE_VERWALTUNG_DETAILS_UPDATE_SUCCESS_" });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },
  },
};
