import WizardStepsANBruttoMixin from "../../../WizardStepsANBruttoMixin";
import PeriodModalUpdateMixin from "../../PeriodModalUpdateMixin";
import PuxButton from "../../../../../../../global/components/PuxButton/PuxButton.vue";
import {
  round,
} from "lodash-es";

// @vue/component
export default {
  name: "PeriodModalUpdateDHW",
  components: {
    PuxButton,
  },
  mixins: [
    PeriodModalUpdateMixin,
    WizardStepsANBruttoMixin,
  ],
  data() {
    return {
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "von",
            type: "date",
            label: "Beginn",
            required: true,
            view: "v",
            notBefore: undefined,
            notAfter: undefined,
            defaultValue: undefined,
            change: this.changeDate
          },
          {
            id: "bis",
            type: "date",
            label: "Ende",
            required: true,
            view: "v",
            notBefore: undefined,
            notAfter: undefined,
            defaultValue: undefined,
            change: this.changeDate
          },
          {
            id: "brutto_monat",
            type: "currency",
            label: "Monatliches Arbeitnehmer-Bruttogehalt",
            required: true,
            view: "v",
          },
          {
            id: "einmalzahlung",
            type: "currency",
            label: "Einmalzahlung",
            view: "v",
          },
          {
            id: "gehalt",
            type: "currency",
            label: "Förderfähiges Arbeitgeber-Bruttogehalt",
            disabled: true,
            view: "v",
          },
        ],
      },
    };
  },
  created() {
    this.initModel();
    this.setDateRange();
  },
  methods: {
    initModel() {
      this.model = {
        von: this.period.von,
        bis: this.period.bis,
        brutto_monat: this.period.brutto_monat,
        gehalt: this.period.gehalt,
        einmalzahlung: this.period.einmalzahlung,
      };
    },

    changeModel() {
      const {
        PKT_PAUSCHALE,
        STELLENUMFANG_PROJEKT,
        VOLLZEITSTELLE,
      } = this.infoForBruttoGehalt;
      const A_N_BRUTTO = this.getANBrutto_mixin({ period: this.model });
      this.model.gehalt = round(A_N_BRUTTO * (1 + PKT_PAUSCHALE / 100) * STELLENUMFANG_PROJEKT / VOLLZEITSTELLE, 2);
    },

    // validateLocal({ error }) {
    //   const ERROR = cloneDeep(error);
    //   if (!(this.model.brutto_monat &&
    //       this.model.brutto_monat !== 0 &&
    //       this.model.brutto_monat !== "0")) {
    //     ERROR.brutto_monat = [this.errorTextRequired];
    //   }
    //   return ERROR;
    // },
  },
};
