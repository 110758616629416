import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import Modal from "../../../global/components/Modal/Modal.vue";
import translate from "../../../global/directives/translate";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "AnsprechpartnerModalZuweisen",
  components: {
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    antragId: {
      type: String,
      default: undefined,
    },
    ansprechpartner: {
      type: Object,
      default: () => ({
        org: {},
      }),
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        list: [],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    translationAnsprechpartnerZuweisenBody() {
      return {
        text: "_TXT_MODAL_ANSPRECHPARTNER_ZUWEISEN_BODY_{{org}}_{{typ}}_",
        extra: {
          org: this.ansprechpartner.org.o_name,
          typ: this.ansprechpartner.typ.apt_kbez
        }
      };
    }
  },
  created() {
    this.initList();
    this.initModel();
  },
  methods: {
    initModel() {
      this.model.fsp_kommentar = this.ansprechpartner.fsp_kommentar;
    },
    initList() {
      this.options.list.push({
        id: "nutzer",
        type: "select",
        label: "_LBL_MODAL_ANSPRECHPARTNER_ZUWEISEN_NUTZER_",
        required: true,
        view: "v",
        keyId: "pk",
        keyLabelCallback: ({ item }) => `${ item.name }${ item.abwesenheit ? ` - (${ item.abwesenheit })` : "" }`,
        menuWidthAll: true,
        url: `foerderantraege/${ this.antragId }/ansprechpartner_auswahl/?aspsuche_foerderorg=${ this.ansprechpartner.org.pk }`,
        searchParameter: "aspsuche_suche",
        searchGlobal: true,
        translate: true,
      }, {
        id: "fsp_kommentar",
        type: "text",
        label: "_LBL_MODAL_ANSPRECHPARTNER_ZUWEISEN_KOMMENTAR_",
        required: false,
        view: "v",
        translate: true,
      });
    },

    save() {
      this.status.loading = true;
      const data = {
        foerderorg: this.ansprechpartner.org.pk,
        nutzer: this.model.nutzer,
        typ: this.ansprechpartner.typ.pk,
        fsp_kommentar: this.model.fsp_kommentar,
      };

      this.postHttp({ url: `foerderantraege/${ this.antragId }/ansprechpartner_aendern/`, data }).then(
        response => {
          this.addNotification({ text: "_MSG_MODAL_ANSPRECHPARTNER_ZUWEISEN_SUCCESS_" });
          this.close({ response: response, status: true });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  }
};
