"use strict";

import angular from "angular";
import BaseController from "./standard_sitzungsmodule.base.controller";

class StandardFoerdermoduleEditController extends BaseController {
  constructor($scope, $q) {
    super($scope);
    this.EVENT_ACTION_UPDATED = "UPDATED";
    this.form = {};
    this.$scope = $scope;
    this.$q = $q;
    this.currentDefer = undefined;
    this.errors = {};
    this.saved = angular.copy(this.userInput);
    this.hasEdit = true;
  }

  // Overwrite to include form validation
  isMissing(fieldName) {
    return super.isMissing(fieldName) && this.form !== undefined && this.form[fieldName] !== undefined && this.form[fieldName].$valid;
  }

  makeSaveRequest() {
    return this.modul.saveUserInput(this.userInput);
  }

  save() {
    // if save not already in progress
    if (this.currentDefer === undefined) {
      this.loading = true;
      this.currentDefer = this.$q.defer();
      // reject save request if form is invalid
      if (this.form.$invalid) {
        this.currentDefer.reject(this.form.$error);
        this.currentDefer = undefined;
        this.loading = false;
        return;
      }
      this.makeSaveRequest().then(
        response => {
          this.saved = response.data;
          this.errors = {};
          this.currentDefer.resolve(response);
          this.setEditMode(false);
          if (angular.isFunction(this.onSave)) {
            this.onSave();
          }
          this.emitEvent(this.EVENT_ACTION_UPDATED);
        },
        errors => {
          this.errors = errors.data;
          this.currentDefer.reject(errors);
        }
      ).finally(() => {
        this.currentDefer = undefined;
        this.loading = false;
      });
    }
    return this.currentDefer.promise;
  }

  hasErrors() {
    if (this.form === undefined || this.form.$invalid) {
      return true;
    }
    for (const key in this.errors) {
      if (!angular.isUndefined(this.errors[key]) && this.errors[key].length) {
        return true;
      }
    }
    return false;
  }

  isClean() {
    return angular.equals(this.saved, this.userInput);
  }

  resetUserInput() {
    for (const key in this.userInput) {
      this.userInput[key] = this.saved[key];
    }
  }

  toggleEditMode() {
    this.setEditMode(!this.editMode);
  }

  setEditMode(bool) {
    this.editMode = bool;
    if (bool) {
      this.editEnteredOnce = true;
    }
    if (!bool) {
      this.resetUserInput();
      this.errors = {};
    }
  }
}

export default StandardFoerdermoduleEditController;
