// Modul zur Erfassung von Kosten mit Pauschale
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const kosten_mit_pauschale = angular.module("dias.kosten_finanzierung.modul.perioden_signal_kosten", [
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
]);

kosten_mit_pauschale.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("perioden_signal_kosten", "modul-perioden-signal-kosten");
})

  .directive("modulPeriodenSignalKosten", builder.directive(template, controller));

export default kosten_mit_pauschale;
