import AbwesenheitenList from "./AbwesenheitenList/AbwesenheitenList.vue";
import Anmeldedaten from "./Anmeldedaten/Anmeldedaten.vue";
import BeiRegistrierungBeantworteteFragen from "./BeiRegistrierungBeantworteteFragen/BeiRegistrierungBeantworteteFragen.vue";
import EMailAdresse from "./EMailAdresse/EMailAdresse.vue";
import Kontakt from "./Kontakt/Kontakt.vue";
import NutzerDetailsName from "./NutzerDetailsName/NutzerDetailsName.vue";
import RollenAntragstellerList from "./Rollen/RollenAntragstellerList/RollenAntragstellerList.vue";
import RollenFoerderorganisationenList from "./Rollen/RollenFoerderorganisationenList/RollenFoerderorganisationenList.vue";
import RollenSystemorganisationenList from "./Rollen/RollenSystemorganisationenList/RollenSystemorganisationenList.vue";
import Sperrbemerkung from "./Sperrbemerkung/Sperrbemerkung.vue";
import Sperrung from "./Sperrung/Sperrung.vue";
import VertretungenList from "../../../Vertretungsberechtigte/VertretungenList/VertretungenList.vue";

export default {
  AbwesenheitenList,
  Anmeldedaten,
  BeiRegistrierungBeantworteteFragen,
  EMailAdresse,
  Kontakt,
  NutzerDetailsName,
  RollenAntragstellerList,
  RollenFoerderorganisationenList,
  RollenSystemorganisationenList,
  Sperrbemerkung,
  Sperrung,
  VertretungenList,
};
