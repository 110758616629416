import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";

import DashboardModuleMixin from "../DashboardModuleMixin";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "DashboardNaechsteSitzung",
  components: {
    PuxButton,
  },
  mixins: [
    DashboardModuleMixin,
  ],
  setup() {
    const {
      filterCurrency,
      filterDate,
    } = FiltersAPI();

    return {
      filterCurrency,
      filterDate,
    };
  },
  data() {
    return {
      active: undefined,
      activeIdx: undefined,
      activeNumDays: undefined,
    };
  },
  computed: {
    isPreviousButtonActive() {
      return this.activeIdx > 0;
    },

    isNextButtonActive() {
      if (!this.data || !this.data.sitzungen) {
        return false;
      }
      return this.activeIdx < this.data.sitzungen.length - 1;
    },

    idForPreviousButton() {
      return `${ this.idLocal }_previous`;
    },

    idForNextButton() {
      return `${ this.idLocal }_next`;
    },
  },
  watch: {
    data() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.active = undefined;
      this.activeIdx = 0;
      this.activeNumDays = 0;
      if (this.data && this.data.sitzungen.length > 0) {
        this.setActive(this.activeIdx);
      }
    },

    setActive(idx) {
      if (!this.data || !this.data.sitzungen) {
        return;
      }
      idx = Math.min(this.data.sitzungen.length - 1, idx);
      idx = Math.max(idx, 0);
      if (this.data.sitzungen.length > 0) {
        this.active = this.data.sitzungen[idx];
      } else {
        this.active = null;
      }
      const date1 = new Date(this.active.si_redaktionsschluss);
      const date2 = new Date();
      date1.setHours(0);
      date2.setHours(0);
      date2.setMinutes(0);
      date2.setSeconds(0);
      date2.setMilliseconds(0);
      const timeDiff = Math.abs(date2.getTime() - date1.getTime());
      this.activeNumDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (date2 > date1) {
        this.activeNumDays = null;
      }
      this.activeIdx = idx;
    },

    setActivePrev() {
      if (!this.isPreviousButtonActive) {
        return;
      }
      this.setActive(this.activeIdx - 1);
    },

    setActiveNext() {
      if (!this.isNextButtonActive) {
        return;
      }
      this.setActive(this.activeIdx + 1);
    },

    hasNext() {
      if (!this.data) {
        return false;
      }
      return this.activeIdx < this.data.sitzungen.length - 1;
    },
  },
};
