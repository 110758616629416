"use strict";

class FormDialogController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, Foerderantrag) {
    this.DiasNotification = DiasNotification;
    this.extras = $scope.extras;
    this.checked = false;

    this.loadingErklaerung = true;

    Foerderantrag.one(this.extras.obj.obj_id).module.getList(
      { gruppe: "erklaerung" }
    ).then(
      response => {
        if (response.length >= 1) {
          angular.extend(this.extras.parameterin, response[0].getParameter());
          this.error = false;
        } else {
          DiasNotification.form.error("Erklärung konnte nicht geladen werden");
          this.error = true;
        }
      },
      error => {
        DiasNotification.form.error(error);
        this.error = true;
      }
    ).finally(() => this.loadingErklaerung = false);

    this.save = () => {
      return this.extras.finish(this.extras.parameterout).then(
        () => {},
        () => {
          this.error = true;
          // return $q.reject(errors);
        }
      ).finally();
    };

    this.cancel = () => {
      return $q.resolve(false);
    };
  }

  getButtonClassName() {
    if (!this.extras || !this.extras.workflowfolge) {
      return "";
    }
    return `test_modal_${ _.trim(this.extras.workflowfolge.wfo_kbez) }`;
  }
}

export default FormDialogController;
