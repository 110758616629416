import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin.js";
import {
  FilterBooleanMixin,
} from "../../../../global/mixins/FiltersMixin";

import {
  cloneDeep,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "ProfilDetailsDokumente",
  components: {
    FormElement,
    FormstepDetail,
    Permission,
    PuxTranslate,
  },
  mixins: [
    FilterBooleanMixin,
    HttpMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
    updateNutzer: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      options: {
        label: "_TXT_PROFIL_DETAILS_DOKUMENTE_FORMSTEP_NAME_",
        required: true,
        saveCallback: this.save,
        openCallback: this.open,
      },
      modelSelected: true,
      modelEditSelected: true,
      htmlId: "profile_documents",
    };
  },
  computed: {
    optionsForFormElement() {
      return {
        id: "profil_dokumente_checkbox",
        htmlId: `${ this.htmlId }_checkbox`,
        type: "one_checkbox",
        view: "v-alt",
        autoFocus: true,
        label: "_LBL_PROFIL_DETAILS_DOKUMENTE_DMS_ANZEIGEN_",
      };
    },

    urlSave() {
      return `profil/${ this.nutzer.pk }/einstellungen_aendern/`;
    },
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      this.modelSelected = get(this.nutzer, "n_einstellungen.dokument__open_in_dms", true);
    },

    save() {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.urlSave,
          data: this.prepareDataForSave(),
        }).then(
          response => {
            this.updateNutzerLocal({
              n_einstellungen: response.n_einstellungen,
            });
            this.addNotification({
              text: "_MSG_PROFIL_DETAILS_UPDATE_SUCCESS_",
            });
            this.setModelFromEditModel();
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },

    prepareDataForSave() {
      const DATA = cloneDeep(this.nutzer);
      DATA.n_einstellungen = DATA.n_einstellungen || {};
      DATA.n_einstellungen.dokument__open_in_dms = this.modelEditSelected;
      return DATA;
    },

    updateNutzerLocal({ n_einstellungen }) {
      const NUTZER = cloneDeep(this.nutzer);
      NUTZER.n_einstellungen = n_einstellungen;
      this.updateNutzer({ nutzer: NUTZER });
    },

    open() {
      this.modelEditSelected = this.modelSelected;
    },

    setModelFromEditModel() {
      this.modelSelected = this.modelEditSelected;
    },
  },
};
