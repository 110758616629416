import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungPlanModalBerechnungsmodus",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    vertragPk: {
      type: String,
      default: undefined,
    },
    auszahlungsplan: {
      type: Object,
      default: undefined,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      model: {
        modus: undefined
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "modus",
            type: "select",
            url: "katalog/?key=berechnungsmodi",
            label: "_LBL_AUSZAHLUNGPLAN_IN_VERTRAG_BERECHNUNGSMODUS_MODAL_MODUS_",
            required: true,
            keyLabel: "azb_kbez",
            keyId: "pk",
            view: "v",
            translate: true,
          }
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  created() {
    this.model.modus = this.auszahlungsplan.zp_modalitaet.modus;
  },
  methods: {
    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.putHttp({ url: `vertraege/${ this.vertragPk }/auszahlungsplaene/${ this.auszahlungsplan.pk }/berechnungsmodus_aendern/`, data }).then(
        response => {
          Object.assign(this.auszahlungsplan, response);
          this.addNotification({ text: "_MSG_AUSZAHLUNGPLAN_IN_VERTRAG_BERECHNUNGSMODUS_MODAL_SUCCESS_" });
          this.close();
        },
        error => {
          if (error && error.data) {
            this.errors = error.data;
            this.errorsClone = error.data;
          } else {
            this.addNotification({ text: "_MSG_AUSZAHLUNGPLAN_IN_VERTRAG_BERECHNUNGSMODUS_MODAL_ERROR_", type: "error" });
          }
        }
      ).finally(
        () => this.status.loading = false
      );
    },
  },
};
