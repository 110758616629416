// @vue/component
export default {
  data() {
    return {
      wizardSteps: [
        {
          label: "_TXT_IMPORTAUFTRAG_JSON_WIZARD_STEP_VORBEREITUNG_HEADER_",
          slotId: "step1",
          title: "_TXT_IMPORTEXPORTAUFTRAG_JSON_WIZARD_STEP_VORBEREITUNG_HEADER_TITLE_",
        },
        {
          label: "_TXT_IMPORTEXPORTAUFTRAG_JSON_WIZARD_STEP_AUSFUEHREN_HEADER_",
          slotId: "step2",
          title: "_TXT_IMPORTEXPORTAUFTRAG_JSON_WIZARD_STEP_AUSFUEHREN_HEADER_TITLE_",
        },
        {
          label: "_TXT_IMPORTEXPORTAUFTRAG_JSON_WIZARD_STEP_ABSCHLUSS_HEADER_",
          slotId: "step3",
          title: "_TXT_IMPORTEXPORTAUFTRAG_JSON_WIZARD_STEP_ABSCHLUSS_HEADER_TITLE_",
        },
      ]
    };
  },
}
;
