import UIComponentMixin from "../UIComponentMixin";

import { JSONEditor } from "vanilla-jsoneditor";
import {
  isString,
} from "lodash-es";

// @vue/component
export default {
  name: "UiJsonEditor",
  mixins: [
    UIComponentMixin,
  ],
  data() {
    return {
      editor: undefined,
    };
  },
  mounted() {
    this.editor = new JSONEditor({
      target: this.$el,
      props: {
        content: {
          text: undefined,
          json: this.model || {}
        },
        onChange: (updatedContent, previousContent, { contentErrors }) => {
          if (!contentErrors.parseError) {
            let data = updatedContent.text || updatedContent.json;
            if (isString(data)) {
              data = JSON.parse(data);
            }
            this.onInput_mixin({ value: data });
          }
        },
        mode: "tree"
      }
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
};
