"use strict";

import angular from "angular";

import ConfirmFactory from "./factory.service";

const services = angular.module("dias.confirm.services", [
  "mgcrea.ngStrap.modal",
])

  .provider("diasConfirmDialog", ConfirmFactory)
;

export default services;
