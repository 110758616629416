import BelegWizardModule from "../../BelegWizardModule/BelegWizardModule.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import KofiErfassungBelegFormMixin from "../KofiErfassungBelegForm/KofiErfassungBelegFormMixin";
import BelegWizardModuleMappingMixin from "../../BelegWizardModule/BelegWizardModuleMappingMixin";

import {
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "KofiErfassungBelegFormModalWizard",
  components: {
    BelegWizardModule,
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    KofiErfassungBelegFormMixin,
    BelegWizardModuleMappingMixin,
  ],
  data: () => {
    return {
      showBelegHinweis: true
    };
  },
  computed: {
    optionsForModal() {
      return {
        showCloseButton: true,
        required: true,
      };
    },

    isWizardModuleVisible() {
      if (!this.betragsrechner) {
        return false;
      }
      return this.wizardModules[this.betragsrechner];
    },

    showBelegHinweisAlert() {
      return this.showBelegHinweis && this.hasBelegHinweis;
    },

    showBelegHinweisButton() {
      return !this.showBelegHinweis && this.hasBelegHinweis;
    },
  },
  methods: {
    toggleShowBelegHinweis({ status }) {
      if (isNil(status)) {
        status = !this.showBelegHinweis;
      }
      this.showBelegHinweis = status;
    },

  },
};
