"use strict";

import StandardBetragsrechner from "dias/kosten_finanzierung/betragsrechner/standard_betragsrechner/betragsrechner";
import {
  isFunction,
} from "lodash-es";

class BenachrichtigungSendDetailController {
  /*@ngInject*/
  constructor(
    $q,
    $scope,
    $filter,
    i18n,
    DiasNotification,
    AuthService,
    Vertrag,
    Foerderantrag,
    Upload,
    KVertragskostenbelegStatusUUIDs,
    KBelegePruefmethodeUUIDs,
    BetragsrechnerRegistry,
    SyConfigs
  ) {
    this.$scope = $scope;
    this.$filter = $filter;
    this.$q = $q;
    this.DiasNotification = DiasNotification;
    this.BetragsrechnerRegistry = BetragsrechnerRegistry;
    this.KVertragskostenbelegStatusUUIDs = KVertragskostenbelegStatusUUIDs;
    this.KBelegePruefmethodeUUIDs = KBelegePruefmethodeUUIDs;
    this.Vertrag = Vertrag;
    this.Foerderantrag = Foerderantrag;
    this.Upload = Upload;
    this.AuthService = AuthService;

    this.gettext = i18n.gettext;

    this.belegnummerAnzeigen = SyConfigs.wert("belege_nummer").anzeigen;

    this.RECHNER_MODES = {
      ERFASST: 1,
      AKTUALISIERT: 2,
      UNBEKANNT: -1
    };
    this.rechnerMode = this.RECHNER_MODES.UNBEKANNT;
    this.model = _.cloneDeep($scope.extras.model) || {};


    this.buildErrorLabels();
    this.init();
    if ($scope.extras.vertrag) {
      this.vertragModel = $scope.extras.vertrag;
      this.initDataForVertrag();
    } else if ($scope.extras.vertragId) {
      this.vertragId = $scope.extras.vertragId;
      this.loadVertrag();
    }

    this.save = () => this.saveBeleg();
  }

  loadVertrag() {
    this.statusLoadingVertrag = true;
    this.Vertrag.one(this.vertragId).get().then(
      response => {
        this.vertragSelection = [{
          id: response.pk,
          v_nr: response.v_nr,
          antrag_id: response.antrag_id,
        }];
        this.statusLoadingVertrag = false;
        this.onVertragChanged();
      }
    );
  }

  buildErrorLabels() {
    this.errorsLabel = {};
    this.errorsLabel[`${ this.prefix }_pos`] = "Position: ";
    this.errorsLabel[`${ this.prefix }_nummer`] = this.gettext("Belegnummer") + ": ";
    this.errorsLabel[`${ this.prefix }_bez`] = "Bezeichnung: ";
    this.errorsLabel[`${ this.prefix }_betrag_erfasst`] = this.gettext("Belegbetrag") + ": ";
    this.errorsLabel[`${ this.prefix }_betrag_anerkannt`] = "Anerkannter Betrag: ";
    this.errorsLabel.dokument = "Datei: ";
    this.errorsLabel.non_field_errors = "";
    this.errorsLabel.detail = "";

    if (this.curPosition && this.curPosition.extra_fields) {
      this.curPosition.extra_fields.forEach(field => {
        if (field[0] === "dokument") {
          return;
        }
        this.errorsLabel[`extra_${ field[0] }`] = `${ field[1].bez }: `;
      });
    }
  }

  init() {
    this.positionen = [];
    this.fullPositionen = [];
    this.typen = [
      { value: "f", label: "Finanzierung" },
      { value: "k", label: this.gettext("Kosten") },
    ];
    this.vertragSelection = [];
    this.vertragExtraFields = ["antrag_id"];
    this.vertragChoices = {
      getChoices: (valueField, labelField, params) => {
        params.fields = params.fields || [];
        params.fields.push(...this.vertragExtraFields);
        return this.Vertrag.getChoices(valueField, labelField, params);
      }
    };

    this.posSelectSettings = {
      groupBy: "art",
      template: `
      <div class="flexbox">
        <div class="main" title="{{:: getPropertyForObject(option, settings.displayProp)}}">
          {{:: getPropertyForObject(option, settings.displayProp)}}
        </div>
        <div class="addon" title="Für die Position bewilligter Betrag: {{:: getPropertyForObject(option, 'betrag')}}" >{{:: getPropertyForObject(option, 'betrag')}}</div>
      </div>`
    };
    this.canCreate = true;
    if (this.model.pk) {
      // edit
      this.loadEditModelData();
    } else {
      // create
      this.model.herkunft = "vertragskosten_positionsbeleg";
    }
  }

  loadEditModelData() {
    this.loading = true;

    this.vertrag = this.Vertrag.one(this.model.vertrag);
    this.antrag = this.Foerderantrag.one(this.model.antrag);

    const kofiPos = 4;

    let getLabel = () => {};
    let getArt = () => {};
    let getBetrag = () => {};
    let getArtInfo = () => {};
    const isFinanz = this.model.herkunft === "vertragsfinanzierung_positionsbeleg";
    if (isFinanz) {
      this.prefix = "vfb";
      this.posPrefix = "vfp";
      this.typ = "finanzierung";
      this.art = "finanzierungsart";
      this.dokart = "5f681393-a2d3-4423-ba35-54d09156651b";
      this.dokument_required = false;
      getLabel = (art, pos) => `${ kofiPos }.${ art.vf_pos }.${ pos.vfp_pos } ${ pos.vfp_bez }`;
      getArt = art => `${ kofiPos }.${ art.vf_pos } ${ art.finanzierungsart.fin_kbez }`;
      getBetrag = (art, pos) => `${ this.$filter("currency")(pos.vfp_betrag_bewilligt) }`;
      getArtInfo = art => {
        return {
          kofi_id: art.pk,
          kofi_pos: art.vf_pos,
          kofiart_bez: art.finanzierungsart.fin_kbez
        };
      };
    } else {
      this.prefix = "vpb";
      this.posPrefix = "vkp";
      this.typ = "kosten";
      this.art = "kostenart";
      this.dokart = "2f413911-a2dc-c179-e050-007f0101705e";
      this.dokument_required = true;
      getLabel = (art, pos) => `${ kofiPos }.${ art.vk_pos }.${ pos.vkp_pos } ${ pos.vkp_bez }`;
      getArt = art => `${ kofiPos }.${ art.vk_pos } ${ art.kostenart.kos_kbez }`;
      getBetrag = (art, pos) => `${ this.$filter("currency")(pos.vkp_betrag_bewilligt) }`;
      getArtInfo = art => {
        return {
          kofi_id: art.pk,
          kofi_pos: art.vk_pos,
          kofiart_bez: art.kostenart.kos_kbez
        };
      };
    }
    this.loadNextBelegnummer();
    // this.modelToEditModel();
    this.resetEditData();
    this.$q.all([
      this.loadPosition(getLabel, getArt, getArtInfo, getBetrag),
      this.loadBelegDokumente()
    ]).finally(() => this.loading = false);
  }

  modelToEditModel() {
    this.editModel = {
      pk: this.model.pk,
      dokument: { id: this.model.dokument },
      status: { pk: this.model.status }
    };
    this.editModel[`vertrags${ this.typ }position`] = { id: this.model.kofipos_id };
    const fields = [
      "pos", "nummer", "bez", "betrag_erfasst", "argument_erfasst",
      "betrag_anerkannt", "argument_anerkannt", "kommentar", "extra_input"
    ];
    fields.forEach(f => {
      this.editModel[`${ this.prefix }_${ f }`] = this.model[f];
    });
  }

  responseToModel(response) {
    this.model.dokument = response.dokument;
    if (this.curDokument) {
      this.model.dokument_bez = this.curDokument.dokument.dok_dokumentname;
      this.model.url = this.curDokument.url;
    } else {
      this.model.dokument_bez = undefined;
      this.model.url = undefined;
    }
    this.model.kofipos_id = this.curPosition.pk;
    this.model.kofipos_bez = this.curPosInfo.bez;
    this.model.kofi_id = this.curPosition.artInfo.kofi_id;
    this.model.kofi_pos = this.curPosition.artInfo.kofi_pos;
    this.model.kofiart_bez = this.curPosition.artInfo.kofiart_bez;
    const fields = [
      "pos", "nummer", "bez", "betrag_erfasst", "argument_erfasst",
      "betrag_anerkannt", "argument_anerkannt", "kommentar", "extra_input"
    ];
    fields.forEach(f => {
      this.model[f] = response[`${ this.prefix }_${ f }`];
    });
  }

  onError(error) {
    const rechner_errors = [];
    Object.keys(error.data).forEach(key => {
      if (angular.isUndefined(this.errorsLabel[key])) {
        rechner_errors.push(key);
      }
    });
    if (rechner_errors.length > 0) {
      this.errorRechner = {};
      rechner_errors.forEach(key => {
        this.errorRechner[key] = error.data[key];
        delete error.data[key];
      });
      error.data.rechner = "Betrag kann nicht berechnet werden.";
      this.errorsLabel.rechner = "Berechnung des Betrags: ";
    }
    this.DiasNotification.form.clear();
    this.DiasNotification.form.error(
      error.data,
      "Bitte überprüfen Sie Ihre Eingaben",
      {},
      this.errorsLabel
    );
    this.errors = error.data;
  }

  showBetrag() {
    // wenn nicht neu erstellt wird und der aktualisierte Betrag bearbeitet wird => Zeige den erfassten Betrag als readonly
    return (this.model !== undefined && this.model.pk !== undefined && this.rechnerMode === this.RECHNER_MODES.AKTUALISIERT);
  }

  updatePerms() {
    const perms = this.AuthService.syncHasPerms([
      "vertragskostenpositionsbelege.update", "vertragskostenpositionsbelege.update.intern"
    ], this.editModel.user_permissions);
    this.disableStammdatenEdit = this.model.pk && (
      (this.rechnerMode === this.RECHNER_MODES.AKTUALISIERT && (
        !perms["vertragskostenpositionsbelege.update.intern"] || this.model.pruefmethode !== this.KBelegePruefmethodeUUIDs.INTERN_ERGAENZT)
      ) ||
      (this.rechnerMode === this.RECHNER_MODES.ERFASST && (
        !perms["vertragskostenpositionsbelege.update"] || this.model.status !== this.KVertragskostenbelegStatusUUIDs.NEU))
    );
    this.disableEdit = this.model.pk && !(perms["vertragskostenpositionsbelege.update"] || perms["vertragskostenpositionsbelege.update.intern"]);
  }

  initDataForVertrag() {
    this.statusDisabledVertrag = true;

    this.vertragSelection[0] = {
      id: this.vertragModel.pk,
      v_nr: this.vertragModel.v_nr,
      antrag_id: this.vertragModel.antrag_id,
    };
    this.model.vertrag = this.vertragSelection[0].id;
    this.selectedVertragNr = this.vertragSelection[0].v_nr;
    this.model.antrag = this.vertragSelection[0].antrag_id;
    this.canCreate = true;
    this.loadingPerms = false;
    this.loadEditModelData();
  }

  onVertragChanged() {
    this.model.vertrag = this.vertragSelection[0].id;
    this.selectedVertragNr = this.vertragSelection[0].v_nr;
    this.model.antrag = this.vertragSelection[0].antrag_id;
    if (this.model.vertrag && !this.model.pk) {
      this.canCreate = true;
      this.loadingPerms = true;
      this.Vertrag.one(this.model.vertrag).checkPermissions(["vertragskostenpositionsbelege.create"], true).then(
        response => {
          this.canCreate = response.length > 0;
          if (this.canCreate && this.model.herkunft) {
            this.loadEditModelData();
          }
        },
        err => this.onError(err)
      ).finally(() => this.loadingPerms = false);
    } else if (this.model.herkunft) {
      this.loadEditModelData();
    }
  }

  onTypChanged() {
    if (this.model.vertrag && this.model.antrag && this.canCreate) {
      this.loadEditModelData();
    }
  }

  getFirstLineClasses() {
    const classes = [];
    const canCreate = !this.model.pk && this.canCreate;
    const canEdit = this.model.pk && !this.disableEdit;
    const canMoveOn = this.model.vertrag && (canCreate || canEdit);
    if (canMoveOn && !this.loadingPerms) {
      classes.push("glyphicon-chevron-down");
    } else {
      classes.push("glyphicon-close");
    }
    if (canMoveOn && !this.model.pk && !this.loadingPerms) {
      classes.push("success");
    } else if (this.model.vertrag && !canMoveOn && !this.loadingPerms) {
      classes.push("danger");
    }
    return classes;
  }

  resetEditData() {
    this.curPosition = undefined;
    this.curPosInfo = undefined;
    this.curDokument = undefined;
    this.editModel = {
      pk: this.model.pk,
      dokument: this.model.dokument,
    };
    this.prepareSelectField(this.editModel, `vertrags${ this.typ }position`);
    this.prepareSelectField(this.editModel, "dokument");
  }

  onPositionChanged() {
    if (!this.editModel[`vertrags${ this.typ }position`] || !this.editModel[`vertrags${ this.typ }position`][0]) {
      this.resetEditData();
      return;
    }
    this.curPosition = this.fullPositionen.find(v => v.pk === this.editModel[`vertrags${ this.typ }position`][0].id);
    if (this.curPosition.vkp_argument && this.curPosition.vkp_argument.personalfunktion) {
      // personalkosten_lohnjournal
      this.editModel.personalfunktion = this.curPosition.vkp_argument.personalfunktion;
    }
    this.curPosInfo = {
      bez: this.curPosition[`${ this.posPrefix }_bez`],
      bewilligt: this.$filter("currency")(this.curPosition[`${ this.posPrefix }_betrag_bewilligt`]),
      eingereicht: this.$filter("currency")(this.curPosition[`${ this.posPrefix }_betrag_eingereicht`]),
      erfasst: this.$filter("currency")(this.curPosition[`${ this.posPrefix }_betrag_erfasst`]),
      anerkannt: this.$filter("currency")(this.curPosition[`${ this.posPrefix }_betrag_anerkannt`]),
    };
    this.updateBelegrechner();
    this.buildErrorLabels();
  }

  onDokChanged() {
    if (!(this.fullBelege && this.editModel && this.editModel.dokument && this.editModel.dokument[0])) {
      return;
    }
    this.curDokument = this.fullBelege.find(v => v.pk === this.editModel.dokument[0].id);
  }

  loadNextBelegnummer() {
    this.loadingBelegnummer = true;
    this.Vertrag.one(this.model.vertrag).customGET("belegnummer").then(
      response => {
        if (response.next) {
          this.nextBelegNummer = response.next;
        } else {
          this.nextBelegNummer = undefined;
        }
        if (this.nextBelegNummer && !this.editModel[`${ this.prefix }_nummer`]) {
          this.editModel[`${ this.prefix }_nummer`] = this.nextBelegNummer;
          this.belegnummerAnzeigen = false;
        }
      },
      () => this.DiasNotification.error("Fehler beim Abrufen der nächsten Belegnummer.")
    ).finally(
      () => this.loadingBelegnummer = false
    );
  }

  getBelegrechner() {
    if (!this.curPosition || !this.curPosition[`antrag${ this.art }info`]) {
      return;
    }
    const betragsrechnername = this.curPosition[`antrag${ this.art }info`].betragsrechner;
    if (betragsrechnername) {
      const BetragsrechnerCls = this.BetragsrechnerRegistry.getBetragsrechner(betragsrechnername);
      return new BetragsrechnerCls(this.$scope, "beleg");
    }
    return new StandardBetragsrechner(this.$scope, "beleg");
  }

  updateBelegrechner() {
    this.rechnerLoading = true;
    const betragsrechner = this.getBelegrechner();
    if (betragsrechner) {
      let argument_betrag;
      let argument_params;

      if (this.model.status === this.KVertragskostenbelegStatusUUIDs.NEU) {
        /* Wenn der Beleg neu ist, dann müssen die Werte aus den "erfasst"
           Werten entnommen werden: */
        argument_params = this.editModel[`${ this.prefix }_argument_erfasst`];
        argument_betrag = this.editModel[`${ this.prefix }_betrag_erfasst`];
        this.rechnerMode = this.RECHNER_MODES.ERFASST;
      } else if (this.model.pruefmethode === this.KBelegePruefmethodeUUIDs.INTERN_ERGAENZT) {
        /* Wenn der Beleg durch den Safo angelegt wurde, dann müssen die Werte aus den "anerkannt"
           Werten entnommen werden: */
        argument_betrag = this.editModel[`${ this.prefix }_betrag_anerkannt`];
        argument_params = this.editModel[`${ this.prefix }_argument_anerkannt`];
        this.rechnerMode = this.RECHNER_MODES.AKTUALISIERT;
      } else if (this.model.status === this.KVertragskostenbelegStatusUUIDs.EINGEREICHT) {
        /* Wenn der Beleg eingereicht ist, dann muss differenziert werden: */
        if (this.editModel[`${ this.prefix }_betrag_anerkannt`] === null) {
          // Der Beleg wurde noch nicht durch den Sachbearbeiter angefasst:
          argument_betrag = this.editModel[`${ this.prefix }_betrag_erfasst`];
          argument_params = this.editModel[`${ this.prefix }_argument_erfasst`];
          this.rechnerMode = this.RECHNER_MODES.ERFASST;
        } else if (this.editModel[`${ this.prefix }_betrag_anerkannt`] === "0.00") {
          // Der Beleg wurde schon mal abgelehnt:
          argument_betrag = "0.00";
          argument_params = betragsrechner.get_fields({});
          this.rechnerMode = this.RECHNER_MODES.AKTUALISIERT;
        } else {
          // Der Beleg wurde schon mal vom Sachbearbeiter bearbeitet:
          argument_betrag = this.editModel[`${ this.prefix }_betrag_anerkannt`];
          argument_params = this.editModel[`${ this.prefix }_argument_anerkannt`];
          this.rechnerMode = this.RECHNER_MODES.AKTUALISIERT;
        }
      }

      if (betragsrechner.component_name === "standardkosten" || betragsrechner.component_name === "standardfinanzierung") {
        this.editModel.argument = { wert: argument_betrag };
      } else {
        this.editModel.argument = Object.assign({}, argument_params);
      }
      this.editModel.rechner = betragsrechner;
      this.editModel.extras = {
        antragsregel: this.curPosition[`antrag${ this.art }info`].antragsregel,
        argument: this.curPosition[`antrag${ this.art }info`].argument };
      if (this.art === "kostenart") {
        this.editModel.extras.akpargument = this.curPosition[`foerderantrag${ this.art }pos`].argument;
      }
      setTimeout(() => this.$scope.$applyAsync(() => this.rechnerLoading = false), 500);
      this.updatePerms();
    }
  }

  prepareSelectField(model, field) {
    if (!model[field]) {
      model[field] = [];
    } else if (!angular.isArray(model[field])) {
      if (!model[field].id) {
        model[field] = [{ id: model[field] }];
      } else {
        model[field] = [model[field]];
      }
    } else if (model[field].length > 0) {
      if (!model[field][0].id) {
        model[field] = [{ id: model[field][0] }];
      }
    }
  }

  interpretSelecField(model, field) {
    if (angular.isArray(model[field])) {
      if (angular.isObject(model[field][0])) {
        model[field] = model[field][0].id;
      } else {
        model[field] = undefined;
      }
    }
  }

  loadBelegDokumente() {
    if (!this.antrag) {
      return;
    }
    return this.antrag.dokumente.getList({ dokart: this.dokart }).then(
      response => {
        this.belegDokumente = [];
        this.fullBelege = response;
        response.forEach(dok => {
          this.belegDokumente.push({
            value: dok.pk,
            label: dok.dokument.dok_dokumentname
          });
        });
        this.onDokChanged();
      },
      err => this.DiasNotification.page.error("Fehler beim Laden der Dokumente", err)
    );
  }

  loadPosition(getLabel, getArt, getArtInfo, getBetrag) {
    if (!this.vertrag) {
      return;
    }
    return this.vertrag[this.typ].getList().then(
      result => {
        this.positionen = [];
        this.fullPositionen = [];
        angular.forEach(result, art => {
          angular.forEach(art.positionen, pos => {
            if (!this.checkBetragRechner(pos)) {
              return;
            }
            pos.artInfo = getArtInfo(art);
            this.fullPositionen.push(pos);
            const beleg = pos.belege.find(beleg => this.model.pk === beleg.pk);
            if (beleg) {
              this.editModel = beleg;
              this.prepareSelectField(this.editModel, `vertrags${ this.typ }position`);
              this.prepareSelectField(this.editModel, "dokument");
              this.onPositionChanged();
              this.onDokChanged();
              this.updatePerms();
            }
            this.positionen.push({
              value: pos.pk,
              label: getLabel(art, pos),
              art: getArt(art, pos),
              betrag: getBetrag(art, pos)
            });
          });
        });
      },
      err => this.DiasNotification.page.error("Fehler beim Laden der Positionen", err)
    );
  }

  checkBetragRechner(position) {
    if (!position || !position.antragkostenartinfo || !position.antragkostenartinfo.betragsrechner) {
      return true;
    }
  }

  uploadDokument(file, invalidFile) {
    if (!this.antrag) {
      return;
    }
    this.dokLoading = true;
    this.deleteErrors();
    this.downloadFiles = file;
    if (file) {
      file.upload = this.Upload.upload({
        url: this.antrag.dokumente.getUploadUrl(this.dokart),
        data: { file: file }
      });
      file.upload.then(
        response => {
          // file is uploaded successfully
          if (response.status === 200) {
            file.progress = 100;
          }
          this.deleteErrors();
          this.belegDokumente.push({
            value: response.data.pk,
            label: file.name
          });
          this.fullBelege.push(response.data);
          this.editModel.dokument = response.data.pk;
          this.dokLoading = false;
          this.prepareSelectField(this.editModel, "dokument");
          this.onDokChanged();
        },
        error => {
          // handle error
          this.errorServer = error.data;
          this.dokLoading = false;
        },
        evt => {
          // progress notify
          file.progress = Math.min(50, parseInt(100.0 * evt.loaded / evt.total));
        }
      );
    } else if (invalidFile) {
      this.errorFile = invalidFile;
      this.dokLoading = false;
    }
  }

  saveBeleg() {
    this.deleteErrors();
    const defer = this.$q.defer();
    if (this.model.pk) {
      this.update(defer);
    } else {
      this.create(defer);
    }
    return defer.promise;
  }

  create(defer) {
    const data = Object.assign({}, this.editModel);
    if (this.model.status === this.KVertragskostenbelegStatusUUIDs.EINGEREICHT &&
       data[`${ this.prefix }_betrag_erfasst`]) {
      delete data[`${ this.prefix }_betrag_erfasst`];
    }
    if (!data[`${ this.prefix }_betrag_anerkannt`] || data[`${ this.prefix }_betrag_anerkannt`].trim() === "") {
      data[`${ this.prefix }_betrag_anerkannt`] = null;
    }
    this.interpretSelecField(data, "dokument");
    this.interpretSelecField(data, `vertrags${ this.typ }position`);
    this.curPosition.createBeleg(data).then(
      () => {
        this.DiasNotification.page.success(this.gettext("Beleg") + " erfolgreich erstellt");
        defer.resolve(this.model);
      },
      err => {
        this.onError(err);
        defer.reject(err);
      }
    );
  }

  createAndContinue() {
    this.$scope.loading = true;
    this.deleteErrors();
    const defer = this.$q.defer();
    this.create(defer);
    defer.promise.then(
      () => {
        const tmp = this.editModel;
        this.editModel = {
          dokument: tmp.dokument,
        };
        this.editModel[`vertrags${ this.typ }position`] = tmp[`vertrags${ this.typ }position`];
        this.prepareSelectField(this.editModel, `vertrags${ this.typ }position`);
        this.prepareSelectField(this.editModel, "dokument");
        this.onPositionChanged();
      }
    ).finally(() => this.$scope.loading = false);
  }

  checkEditModel(editModel) {
    let data;
    if (editModel) {
      if (isFunction(editModel.plain)) {
        data = editModel.plain();
      } else {
        data = editModel;
      }
    } else {
      data = {};
    }
    return data;
  }

  update(defer) {
    const data = this.checkEditModel(this.editModel);
    if (this.model.status === this.KVertragskostenbelegStatusUUIDs.EINGEREICHT &&
       data[`${ this.prefix }_betrag_erfasst`]) {
      delete data[`${ this.prefix }_betrag_erfasst`];
    }
    if (!data[`${ this.prefix }_betrag_anerkannt`] || data[`${ this.prefix }_betrag_anerkannt`].trim() === "") {
      data[`${ this.prefix }_betrag_anerkannt`] = null;
    }
    this.interpretSelecField(data, "dokument");
    this.interpretSelecField(data, `vertrags${ this.typ }position`);
    this.editModel.customPUT(data).then(
      response => {
        this.responseToModel(response);
        this.DiasNotification.page.success(this.gettext("Beleg") + " erfolgreich gespeichert");
        defer.resolve(this.model);
      },
      err => {
        this.onError(err);
        defer.reject(err);
      }
    );
  }

  deleteErrors() {
    this.errors = null;
    this.errorFile = null;
    this.errorServer = null;
    this.errorRechner = null;
  }
}

export default BenachrichtigungSendDetailController;
