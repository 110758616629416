"use strict";

import BaseModel from "dias/core/models/baseModel";


class Teamroutings extends BaseModel {
  constructor() {
    super("teamroutings");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.getAsTree = (params, data) => {
      if (data) {
        return collection.customPOST(data, "tree", params);
      }
      return collection.customGET("tree", params);
    };

    collection.createRoute = data => {
      return collection.customPOST(data);
    };

    collection.deleteRoute = routePk => {
      return collection.one(routePk).remove();
    };

    collection.get_allowed_kriterien = parent_pk => {
      return collection.all("allowed_kriterien").getList({ parent: parent_pk });
    };

    return collection;
  }
}

export default Teamroutings;
