import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";

import DashboardIconMixin from "../DashboardIconMixin";
import DashboardModelFilterMixin from "../DashboardModelFilterMixin";
import DashboardModuleMixin from "../DashboardModuleMixin";
import DashboardPopoverMixin from "../DashboardPopoverMixin";
import DashboardStylesMixin from "../DashboardStylesMixin";

import {
  forEach,
  isObject,
  isArray,
} from "lodash-es";

// @vue/component
export default {
  name: "DashboardWorkflowStatusgruppen",
  components: {
    AngularLink,
    PuxButton,
  },
  mixins: [
    DashboardIconMixin,
    DashboardModelFilterMixin,
    DashboardModuleMixin,
    DashboardPopoverMixin,
    DashboardStylesMixin,
  ],
  data() {
    return {
      count: 0,
      wfStatusgruppen: {},
      relevantObjPK: undefined,
    };
  },
  computed: {
    parameter() {
      return this.argument.parameter || {};
    },

    dataAlle() {
      return this.data.alle || {};
    },

    titleBox() {
      return this.count === 1 ?
        `${ this.parameter.hover_prefix_einzahl } ${ this.parameter.hover_suffix_einzahl }` :
        `${ this.parameter.hover_prefix_mehrzahl } ${ !this.count ? "keine" : this.count } ${ this.parameter.hover_suffix_mehrzahl }`;
    },

    componentLocal() {
      return this.count ?
        "angular-link" :
        "div";
    },

    attributesLocal() {
      if (this.count) {
        return this.linkAttributes;
      }
      return {};
    },

    linkAttributes() {
      if (isObject(this.parameter) && this.count === 1) {
        return this.linkAttributesToPageDetail;
      }
      if (isObject(this.parameter) && this.count > 1) {
        return this.linkAttributesToPageList;
      }
      return {};
    },

    linkAttributesToPageDetail() {
      let parameter;
      if (isArray(this.relevantObjPK)) {
        parameter = {
          id: this.relevantObjPK[0],
          aus_id: this.relevantObjPK[1],
        };
      } else {
        parameter = {
          id: this.relevantObjPK,
        };
      }
      return {
        sref: this.parameter.goto_details,
        parameter,
        "aria-labelledby": this.ariaLabelledbyForBadgeLink,
      };
    },

    linkAttributesToPageList() {
      const OPTIONS = Object.assign(
        {
          workflowstatusgruppe: this.parameter.wsg_ids,
        },
        this.additionalFilters,
      );
      return {
        sref: this.parameter.goto_list,
        options: OPTIONS,
        "aria-labelledby": this.ariaLabelledbyForBadgeLink,
      };
    },
  },
  watch: {
    data() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.wfStatusgruppen = {};
      if (!this.data) {
        return;
      }
      this.filterWfStatusgruppen();
    },

    filterWfStatusgruppen() {
      this.relevantObjPK = this.dataAlle.relevantes_objekt_pk;
      if (!this.data.statusgruppen || this.data.statusgruppen.length === 0) {
        this.count = this.dataAlle.anzahl_objekte;
      } else {
        this.count = 0;
        const WF_STATUSGRUPPEN = {};
        forEach(this.data.statusgruppen, value => {
          WF_STATUSGRUPPEN[value.workflowstatusgruppe.pk] = value;
          this.count += value.anzahl_objekte;
          this.relevantObjPK = this.relevantObjPK || value.relevantes_objekt_pk;
        });
        this.wfStatusgruppen = WF_STATUSGRUPPEN;
      }
    },
  },
};
