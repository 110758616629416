import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "TemplateAdminModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    close: {
      type: Function,
      required: true,
      info: "Schießen Modal-Fenster",
    },
    selectorClose: {
      type: String,
      required: true,
      info: "CSS-Selector für Fokus, wenn man Modal-Fenster schließt",
    },
  },
  data() {
    return {
      model: {
        tpl_kbez: undefined,
        tpl_bez: undefined,
        tpl_keyname: undefined,
        typ: undefined,
        tpl_permission_entwurf: undefined,
        tpl_permission_final: undefined
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    options() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "tpl_kbez",
            type: "text",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_KURZ_BEZEICHNUNG_",
            showLabel: true,
            view: "v",
            required: true,
            translate: true,
          },
          {
            id: "tpl_bez",
            type: "text",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_BEZEICHNUNG_",
            showLabel: true,
            view: "v",
            translate: true,
          },
          {
            id: "tpl_keyname",
            type: "select",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_KEYNAME_",
            url: `katalog/?key=reg_templates`,
            showLabel: true,
            view: "v",
            keyLabel: "bez",
            keyId: "id",
            required: true,
            translate: true,
          },
          {
            id: "typ",
            type: "select",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_TYP_",
            url: `katalog/?key=templatetyp`,
            showLabel: true,
            keyLabel: "bez",
            keyId: "id",
            view: "v",
            required: true,
            translate: true,
          },
          {
            id: "tpl_permission_entwurf",
            type: "select",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_PERMISSION_ENTWURF_",
            showLabel: true,
            view: "v",
            url: `katalog/?key=permissions`,
            search: true,
            deselect: true,
            keyLabel: "bez",
            keyId: "id",
            searchList: ["bez"],
            required: false,
            helpText: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_PERMISSION_ENTWURF_HELPTEXT_"
          },
          /* {
            id: "tpl_permission_final",
            type: "text",
            label: "_LBL_TEMPLATEADMIN_DETAILS_BASISDATEN_PERMISSION_FINAL_",
            showLabel: true,
            view: "v",
            required: false,
            translate: true,
          },
          */
        ],
      };
    },
  },
  methods: {
    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.postHttp({ url: `templates/`, data }).then(
        response => {
          this.addNotification({ text: "_MSG_TEMPLATE_ADMIN_MODAL_CREATE_SUCCESS_" });
          this.close(response);
          this.$goTo("root.templateadmin.details", { id: response.tpl_id });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
