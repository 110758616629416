import ModuleMixin from "../ModuleMixin";
import Modal from "../../../../global/components/Modal/Modal.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import TermineingabeTr from "./TermineingabeTr/TermineingabeTr.vue";
import TermineingabeModalCreateOrEdit from "./TermineingabeModalCreateOrEdit/TermineingabeModalCreateOrEdit.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import {
  cloneDeep,
  reject,
  keyBy,
  isUndefined,
  isEqual,
  forEach,
  values,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "Termineingabe",
  components: {
    Modal,
    SnapshotIcon,
    FormElementReadOnly,
    TermineingabeTr,
    TermineingabeModalCreateOrEdit,
    PuxButton,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      termineList: [],
      currentTermin: undefined,
      statusModalCreateOrUpdate: undefined,
      statusModalDelete: undefined,
      confirmDeleteOptions: undefined,
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      return cloneDeep(this.optionsFormstepDetail);
    },

    termineListAlle() {
      return [
        this.titel,
        {
          id: "typ_pk",
          type: "select",
          label: "_LBL_TERMINEINGABE_GR_TYP_",
          required: true,
          view: "v-alt",
          keyId: "pk",
          keyLabel: "bez",
          apiSaveId: "termintyp",
          disabled: undefined,
          translate: true,
          url: "katalog/?key=termintyp",
        },
        {
          id: "ter_ort",
          type: "text",
          label: "_LBL_TERMINEINGABE_GR_ORT_",
          view: "v-alt",
          translate: true,
        },
        this.bemerkung,
        this.terVon,
        this.terBis,
        this.offentlich,
        {
          id: "private_termin",
          type: "boolean",
          label: "_LBL_TERMINEINGABE_GR_PRIVATE_",
          view: "v-alt",
          trueValue: true,
          falseValue: false,
          trueLabel: "_LBL_TERMINEINGABE_GR_PRIVATE_TRUE_",
          falseLabel: "_LBL_TERMINEINGABE_GR_PRIVATE_FALSE_",
          default: true,
          translate: true,
        },
      ];
    },

    titel() {
      return {
        id: "ter_titel",
        type: "text",
        label: this.modelParameter.titel_label,
        required: true,
        view: "v-alt",
      };
    },

    bemerkung() {
      return {
        id: "ter_bemerkung",
        type: "richtext",
        label: this.modelParameter.bemerkung_label,
        view: "v-alt",
      };
    },

    terVon() {
      return {
        id: "ter_von",
        type: "datetime",
        label: this.modelParameter.von_label,
        required: true,
        view: "v-alt",
      };
    },

    terBis() {
      return {
        id: "ter_bis",
        type: "datetime",
        label: this.modelParameter.bis_label,
        required: true,
        view: "v-alt",
      };
    },

    offentlich() {
      return {
        id: "offentlich",
        type: "boolean",
        label: this.modelParameter.offentlichkeit_label,
        view: "v-alt",
        trueValue: true,
        falseValue: false,
        trueLabel: this.modelParameter.offentlich_label,
        falseLabel: this.modelParameter.nicht_offentlich_label,
        default: false,
      };
    },

    statusShowOpenButton() {
      if (this.modelParameter.max_termine) {
        const MODEL_LENGTH = this.model && this.model.termine ? this.model.termine.length : 0;
        return MODEL_LENGTH < this.modelParameter.max_termine && !this.statusReadonly;
      }
      return !this.statusReadonly;
    },

    urlSave() {
      return `${ this.baseUrl }module/${ this.regel.pk }/`;
    },

    snapshotModuleObj() {
      if (this.statusSnapshot &&
        this.snapshotModule &&
        this.snapshotModule.termine
        && this.snapshotModule.termine.length) {
        return keyBy(this.snapshotModule.termine, "pk");
      }
      return {};
    },

    snapshotTermineFiltered() {
      const SNAPSHOT_TERMINE = {
        add: {},
        delete: [],
        changes: {},
        status: false,
      };
      if (this.statusSnapshot) {
        const SNAPSHOT_MODULE_OBJ = cloneDeep(this.snapshotModuleObj);
        if (this.model && this.model.termine && this.model.termine.length) {
          forEach(this.model.termine, item => {
            if (!SNAPSHOT_MODULE_OBJ[item.pk]) {
              SNAPSHOT_TERMINE.add[item.pk] = true;
              SNAPSHOT_TERMINE.status = true;
            } else {
              delete item.user_permissions;
              if (!isEqual(item, SNAPSHOT_MODULE_OBJ[item.pk])) {
                SNAPSHOT_TERMINE.changes[item.pk] = cloneDeep(SNAPSHOT_MODULE_OBJ[item.pk]);
                SNAPSHOT_TERMINE.status = true;
              }
              delete SNAPSHOT_MODULE_OBJ[item.pk];
            }
          });
        }
        SNAPSHOT_TERMINE.delete = values(SNAPSHOT_MODULE_OBJ);
        if (SNAPSHOT_TERMINE.delete.length) {
          SNAPSHOT_TERMINE.status = true;
        }
      }
      return SNAPSHOT_TERMINE;
    },

    statusDiff() {
      return this.snapshotTermineFiltered.status;
    },

    statusNoData() {
      return !(this.snapshotTermineFiltered.delete.length || (this.model && this.model.termine && this.model.termine.length));
    },

    isModuleEditable() {
      return false;
    },
  },
  created() {
    this.initTermineList();
  },
  methods: {
    initTermineList() {
      this.termineList = reject(cloneDeep(this.termineListAlle), obj => (obj.id === "ter_ort" || obj.id === "private_termin"));
    },

    openModalCreateOrEdit(termin) {
      if (termin) {
        this.currentTermin = termin;
        this.selectorClose = `#${ this.htmlRegelNummer }_edit_${ termin.pk }`;
      } else {
        this.selectorClose = `#${ this.htmlRegelNummer }_add`;
      }
      this.statusModalCreateOrUpdate = true;
    },

    closeModalDelete() {
      this.statusModalDelete = false;
    },

    confirmDeleteTermin({ termin }) {
      this.selectorClose = [
        `#${ this.htmlRegelNummer }_delete_${ termin.pk }`,
        `#${ this.htmlRegelNummer }`
      ];
      this.confirmDeleteOptions = {
        okClass: "btn btn-primary",
        title: `_HTML_TERMINEINGABE_GR_MODAL_DELETE_HEADER_{{name}}_`,
        msg: "_HTML_TERMINEINGABE_GR_MODAL_DELETE_BODY_",
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: () => this.deleteTermin({ termin }),
        cancelCallback: this.closeModalDelete,
        loading: false,
        extra: {
          name: termin.ter_name,
        },
      };
      this.statusModalDelete = true;
    },

    deleteTermin({ termin }) {
      const DATA = {
        termin: cloneDeep(termin),
        loeschen: true
      };
      if (DATA.termin && !isUndefined(DATA.termin.private_termin)) {
        DATA.termin.private = DATA.termin.private_termin;
      }
      if (DATA.typ) {
        DATA.typ_pk = DATA.typ.pk;
      }
      termin.loeschen = true;
      this.putHttp({
        url: `${ this.baseUrl }module/${ this.regel.pk }/`,
        data: DATA
      }).then(
        response => {
          this.deleteErrors();
          this.onUpdateFunctions({ response });
          this.addNotification({ text: `_MSG_TERMINEINGABE_GR_DELETE_SUCCESS_` });
        },
        error => {
          this.onError({ error: error.data });
          this.addNotification({ text: "_MSG_TERMINEINGABE_GR_DELETE_ERROR_", type: "error" });
        }
      ).then(
        () => {
          this.statusModalDelete = false;
        }
      );
    },

    onUpdateFunctions({ response }) {
      if (isFunction(this.savecallback)) {
        this.savecallback();
      }
      this.updateModule({ response, extra: this.extra, group: this.group });
      this.updateSnapshotDiffLocal();
      this.sendSignal();
      setTimeout(() => {
        this.initData();
      });
    },

    closeModalCreateOrUpdate({ response } = {}) {
      if (response) {
        this.onUpdateFunctions({ response });
        this.deleteErrors();
      }
      this.currentTermin = undefined;
      this.statusModalCreateOrUpdate = false;
    },
  },
};
