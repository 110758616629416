"use strict";

import template from "./list.jade";

import createController from "./modal.create.controller";
import createTemplate from "./modal.create.jade";

import FilterSet from "dias/core/table/filterSet";


class GutachtenvorlagenFilterSet extends FilterSet {
  constructor(Katalog, i18n) {
    super();
    this.addFilter("gav_kbez", {
      label: i18n.gettext("Name"),
      textInput: true,
      param: "gav_kbez",
      initialStatus: true
    });
    this.addFilter("typ", {
      label: i18n.gettext("Typ"),
      choices: Katalog.getChoices("kgutachtentypen", "pk", "bez", {}, true),
      param: "typ",
      multiSelect: true,
      forceSelected: true,
    });
    this.addFilter("gruppe", {
      label: i18n.gettext("Ergebnis"),
      choices: Katalog.getChoices("kgutachtenergebnisgruppen", "pk", "bez", {}, true),
      param: "gruppe",
      multiSelect: true,
      forceSelected: true,
    });
    this.addFilter("workflow", {
      label: i18n.gettext("Workflow"),
      choices: Katalog.getChoices("workflow_gutachten", "pk", "bez", {}, true),
      param: "workflow",
      multiSelect: true,
      forceSelected: true,
    });
    this.addFilter("content_types", {
      label: i18n.gettext("Gutachtenobjekte"),
      choices: Katalog.getChoices("gutachtentypen", "pk", "bez", {}, true),
      param: "content_types",
      multiSelect: true,
      forceSelected: true,
    });
  }
}


class GutachtenvorlagenListController {
  /*@ngInject*/
  constructor(
    Gutachtenvorlagen,
    AuthService,
    DiasTableConfig,
    diasModalDialog,
    Katalog,
    i18n
  ) {
    this.gettext = i18n.gettext;
    const getDetailRoute = row => "root.gutachtenvorlagen.detail({id: '" + row.pk + "'})";
    this.gutachtenvorlagenTable = DiasTableConfig.fromService(Gutachtenvorlagen, {
      // Reihenfolge der angezeigten Spalten.
      columnOrder: ["gav_kbez", "gav_bez", "typ", "gruppe", "gav_erlaeuterung_pflicht", "workflow", "content_types"],

      updateColumnDefs: columns => {
        columns.gav_kbez.label = this.gettext("Name");
        columns.gav_kbez.linkToDetail = true;
        columns.gav_kbez.sortable = true;
        columns.gav_bez.label = this.gettext("Beschreibung");
        columns.gav_bez.cellTemplate = "<span ng-bind-html='row.gav_bez'></span>";
        columns.gav_bez.disableDefaultAction = true;
        columns.typ.label = this.gettext("Typ");
        columns.typ.cellTemplate = "{{:: row.typ_bez }}";
        columns.typ.disableDefaultAction = true;
        columns.gruppe.label = this.gettext("Ergebnis");
        columns.gruppe.cellTemplate = "{{:: row.gruppe_bez }}";
        columns.gruppe.disableDefaultAction = true;
        columns.gav_erlaeuterung_pflicht.label = this.gettext("Erläuterung erzwingen");
        columns.gav_erlaeuterung_pflicht.cellTemplate = "{{:: row.gav_erlaeuterung_pflicht?'Ja':'Nein' }}";
        columns.gav_erlaeuterung_pflicht.disableDefaultAction = true;
        columns.workflow.label = this.gettext("Workflow");
        columns.workflow.cellTemplate = "{{:: row.workflow_bez }}";
        columns.workflow.disableDefaultAction = true;
        columns.content_types.label = this.gettext("Gutachtenobjekte");
        columns.content_types.cellTemplate = `<ul class="list-unstyled">
          <li ng-repeat="val in row.content_types_bez">{{:: val}}</li>
        </ul>`;
        columns.content_types.disableDefaultAction = true;
      },

      // Tabellenaktionen
      tableActions: [
        { label: this.gettext("Neue Gutachtenvorlage erstellen"),
          icon: "glyphicon glyphicon-plus", primary: true,
          callback: () => diasModalDialog({
            title: this.gettext("Gutachtenvorlage erstellen"),
            template: createTemplate,
            controller: createController
          }),
          isVisible: () => AuthService.syncHasPerm("gutachtenvorlage.create")
        }
      ],
      tableTitle: this.gettext("Gutachtenvorlagen"),
      getDetailRoute: getDetailRoute,
      // FilterSet
      filter: new GutachtenvorlagenFilterSet(Katalog, i18n),
      filterName: "gutachtenvorlagen"
    });
  }
}

export default {
  template: template(),
  controller: GutachtenvorlagenListController,
  controllerAs: "vm"
};
