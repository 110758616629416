import BetragsrechnerMixin from "../../Mixins/BetragsrechnerMixin";
import MdmRegionaleEffekteMixin from "../MdmRegionaleEffekteMixin";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "MdmRegionaleEffekteMitBegruendung",
  mixins: [
    BetragsrechnerMixin,
    MdmRegionaleEffekteMixin,
  ],
  data() {
    return {
      labelBegruendung: "_LBL_REGIONALE_EFFEKTE_BEGRUENDUNG_",
    };
  },
  computed: {
    optionsBegruendung() {
      return {
        id: "begruendung",
        htmlId: this.htmlIdBegruendung,
        label: this.labelBegruendung,
        view: this.view,
        type: "text",
        maxlength: 200,
        required: false,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdBegruendung() {
      return `${ this.htmlId }_begruendung`;
    },

    errorLabelsLocal() {
      const LABELS = cloneDeep(this.errorLabels);
      LABELS.begruendung = {
        label: this.labelBegruendung,
        link: true,
        id: this.htmlIdBegruendung,
      };
      return LABELS;
    },
  },
  created() {
    this.initErrorLabels(this.errorLabelsLocal);
  },
};
