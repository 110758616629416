import {
  computed,
  ref,
  toRefs,
} from "vue";

import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxDate from "../../../../global/components/PuxDate/PuxDate.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import {
  cloneDeep,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "SitzungDetailsSitzungsdaten",
  components: {
    FormElement,
    FormstepDetail,
    PuxDate,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    sitzung: {
      type: Object,
      required: true,
    },
    sitzungUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "updateSitzung",
  ],
  setup(props, { emit }) {
    const {
      sitzung,
      sitzungUrl,
    } = toRefs(props);

    const model = ref({});
    const errors = ref(undefined);
    const open = () => {
      model.value = cloneDeep(sitzung.value);
    };
    const {
      addNotification,
      putHttp,
    } = HttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    const isSitzungEditable = computed(() => {
      return checkPermissionsSync({
        perm: "sitzungen.update",
        permArray: sitzung.value.user_permissions,
      });
    });

    const getDataForSave = () => {
      return model.value;
    };
    const deleteErrors = () => {
      errors.value = undefined;
    };
    const save = () => {
      deleteErrors();
      return putHttp({
        url: sitzungUrl.value,
        data: getDataForSave(),
      }).then(
        response => {
          emit("updateSitzung", { sitzungLocal: response });
          addNotification({ text: "_MSG_SITZUNG_DETAILS_SITZUNGSDATEN_ERFOLGREICH_GESPEICHERT_" });
          return Promise.resolve();
        },
        error => {
          errors.value = error.data;
          return Promise.reject(error);
        },
      );
    };
    const close = () => {
      deleteErrors();
    };

    const optionsFormstepDetail = {
      label: "_LBL_SITZUNG_DETAILS_SITZUNGSDATEN_SITZUNGSDATUM_UND_ORT_",
      openCallback: open,
      saveCallback: save,
      closeCallback: close,
      required: true,
    };

    const isMessageHide = ref(false);
    const closeMessage = () => {
      isMessageHide.value = true;
    };

    const sitzungsdatumOptions = {
      type: "date",
      label: "_TXT_SITZUNG_DETAILS_SITZUNGSDATEN_TERMIN_",
      view: "v",
      id: "si_sitzungsdatum",
      required: true,
    };

    const buchungsdatumOptions = {
      type: "date",
      label: "_TXT_SITZUNG_DETAILS_SITZUNGSDATEN_BUCHUNGSDATUM_",
      view: "v",
      id: "si_buchungsdatum",
      required: true,
    };

    const redaktionsschlussOptions = {
      type: "date",
      label: "_TXT_SITZUNG_DETAILS_SITZUNGSDATEN_REDAKTIONSSCHLUSS_",
      view: "v",
      id: "si_redaktionsschluss",
      required: true,
    };

    const durchfuehrungsortOptions = {
      type: "text",
      label: "_TXT_SITZUNG_DETAILS_SITZUNGSDATEN_DURCHFUEHRUNGSORT_",
      view: "v",
      id: "si_durchfuehrungsort",
      required: false,
    };

    const strasseOptions = {
      type: "text",
      label: "_TXT_SITZUNG_DETAILS_SITZUNGSDATEN_STRASSE_",
      view: "v",
      id: "si_strasse",
      required: false,
    };

    const hsnrOptions = {
      type: "text",
      label: "_TXT_SITZUNG_DETAILS_SITZUNGSDATEN_HAUSNUMMER_",
      view: "v",
      id: "si_hsnr",
      required: false,
    };

    const plzOptions = {
      type: "text",
      label: "_TXT_SITZUNG_DETAILS_SITZUNGSDATEN_POSTLEITZAHL_",
      view: "v",
      id: "si_plz",
      required: false,
    };

    const ortOptions = {
      type: "text",
      label: "_TXT_SITZUNG_DETAILS_SITZUNGSDATEN_ORT_",
      view: "v",
      id: "si_ort",
      required: false,
    };

    const infoOptions = {
      type: "richtext",
      label: "_LBL_SITZUNG_SI_INFO_",
      view: "v-alt",
      id: "si_info",
      required: false,
    };

    const errorsLabelsOptions = [
      sitzungsdatumOptions,
      buchungsdatumOptions,
      redaktionsschlussOptions,
      durchfuehrungsortOptions,
      strasseOptions,
      hsnrOptions,
      plzOptions,
      ortOptions,
      infoOptions,
    ];

    return {
      sitzungsdatumOptions,
      buchungsdatumOptions,
      redaktionsschlussOptions,
      durchfuehrungsortOptions,
      strasseOptions,
      hsnrOptions,
      plzOptions,
      ortOptions,
      infoOptions,
      isSitzungEditable,
      closeMessage,
      errors,
      errorsLabelsOptions,
      isMessageHide,
      model,
      optionsFormstepDetail,
    };
  },
  computed: {
    getStrasseExtra() {
      return {
        si_strasse: get(this.sitzung, "si_strasse") || "",
        si_hsnr: get(this.sitzung, "si_hsnr") || "",
      };
    },

    getOrtExtra() {
      return {
        si_plz: get(this.sitzung, "si_plz") || "",
        si_ort: get(this.sitzung, "si_ort") || "",
      };
    },

    keinVeranstaltungsort() {
      return !(this.sitzung.si_durchfuehrungsort || this.sitzung.si_strasse || this.sitzung.si_hsnr || this.sitzung.si_plz || this.sitzung.si_ort);
    }
  },
};
