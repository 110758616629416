"use strict";

import angular from "angular";
import TooltipDirective from "./tooltip.directive";

const diasTooltip = angular.module("dias.tooltip", [])

  .directive("tooltip", TooltipDirective);

export default diasTooltip;
