"use strict";

class FoerdermoduleChildrenController {
  /*@ngInject*/
  constructor($element, $scope, $compile, FoerdermoduleRegistry) {
    this.$compile = $compile;
    this.$element = $element;
    this.$scope = $scope;
    this.FoerdermoduleRegistry = FoerdermoduleRegistry;

    this.create();
  }

  create() {
    const modulName = this.FoerdermoduleRegistry.getModule(`${ this.modul.modulname }_child`);
    if (modulName === undefined) {
      const errMsg = `Modul ${ this.modulName } nicht gefunden`;
      console.error(errMsg);
      return;
    }
    const template = `<div ${ modulName } parent-id="vm.parentId" model="vm.model" model-diff="vm.modelDiff" model-snapshot="vm.modelSnapshot" modul="vm.modul" obj="vm.obj" edit-mode="vm.editMode" errors="vm.errors"></div>`;

    const el = this.$compile(template)(this.$scope);
    this.$element.append(el);
  }
}

export default () => {
  return {
    restrict: "A",
    scope: {
      model: "=",
      modelDiff: "<?",
      modelSnapshot: "<?",
      modul: "<",
      obj: "<",
      parentId: "<",
      editMode: "<?",
      errors: "<?",
    },
    template: "",
    controller: FoerdermoduleChildrenController,
    controllerAs: "vm",
    bindToController: true
  };
};
