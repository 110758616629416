"use strict";

import KostenFinanzierungBaseController from "./kosten_finanzierung.base.controller";

class KostenController extends KostenFinanzierungBaseController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KKostenart, BetragsrechnerRegistry, i18n) {
    super($scope, $q, Foerdermodule, Foerderantrag, BetragsrechnerRegistry, i18n);
    this.angular = angular;
    this.$scope = $scope;
    this.kostenArtDetailPos = {};
    this.editPositionModes = {};
    // Katalog-Kostenart + Details laden:
    KKostenart.getList().then(result => {
      this._initKatalogDetails(result, this.parameter.kostenart, this.parameter.kostendetails);
    });
    this.antragKostenart = {};
  }

  _initKatalogDetails(katalogResults, regelKatalogId, regelKatalogDetails) {
    super._initKatalogDetails(katalogResults, regelKatalogId, regelKatalogDetails);
    if (this.parameter.ohne_positionen && this.katalogDetails.length > 0) {
      this.editType = "no-position-details";
      for (let i = 0; i < this.katalogDetails.length; i++) {
        if (!this.kostenArtDetailPos[this.katalogDetails[i].pk]) {
          this.kostenArtDetailPos[this.katalogDetails[i].pk] = this.initNewPosition();
          this.kostenArtDetailPos[this.katalogDetails[i].pk].kostenartdetail = this.katalogDetails[i].pk;
        }
      }
    } else if (this.parameter.ohne_positionen) {
      this.editType = "no-position";
    }
  }


  toggleEditPosition(pos) {
    if (this.editType !== "no-position-details") {
      super.toggleEditPosition(pos);
    } else {
      this.editPositionModes[pos.pk] = !this.editPositionModes[pos.pk];
      if (!this.editPositionModes[pos.pk]) {
        const p = this.positionen.find(p => p.kostenartdetail === pos.pk);
        if (p) {
          this.kostenArtDetailPos[pos.pk] = _.cloneDeep(p);
        }
        this.updateErrors = {};
      }
    }
  }

  getDefaultBetragsrechner() {
    return "standard_kostenrechner";
  }

  getPosBezeichnung(p) {
    return (p.kostenartdetail_kbez ? p.kostenartdetail_kbez + ": " : "") + p.akp_bez;
  }

  showSinglePosBez() {
    const pos = this.get_single_position();
    return pos && pos.akp_bez !== this.antragKoFiArt.bez;
  }

  getPosWert(p) {
    if (p) {
      return p.akp_wert || 0;
    }
    return 0;
  }

  getPosNr(p) {
    return p.akp_pos;
  }

  update_bez(model) {
    const kod = this.getKatalogDetails(model.kostenartdetail);
    if (kod && kod.kod_standardtext) {
      model.akp_bez = kod.kod_standardtext;
    }
  }

  _reloadPositionen() {
    this.loading = true;
    const antragId = this.modul.regel.afr_aid;
    const antragregelId = this.modul.regel.afr_id;
    this.Foerderantrag.one(antragId).getList("kostenarten", { antragregel: antragregelId }).then(result => {
      this.antragKostenart = result[0]; // sollte pro antragregel max. eine geben
      this.antragKoFiArt.bez = this.antragKostenart.kostenart.kos_kbez;
      this.antragKoFiArt.summe = this.antragKostenart.aka_summe;
      this.antragKoFiArt.gekappt = this.parameter.max_betrag && this.antragKostenart.aka_summe === this.parameter.max_betrag;
      this.antragKoFiArt.artPk = this.antragKostenart.kostenart.pk;
      this.antragKoFiArt.diffKey = "kostenarten";
      this.antragKoFiArt.pk = this.antragKostenart.pk;
      this.antragKostenart.getList("positionen", { ordering: "akp_pos" })
        .then(result_pos => this._setPositionen(result_pos))
        .finally(() => {
          this.loading = false;
          this.firstLoad = false;
        });
    });
  }

  _setPositionen(positionen) {
    super._setPositionen(positionen);
    for (let i = 0;this.katalogDetails && i < this.katalogDetails.length; i++) {
      this.kostenArtDetailPos[this.katalogDetails[i].pk] = this.initNewPosition();
      this.kostenArtDetailPos[this.katalogDetails[i].pk].kostenartdetail = this.katalogDetails[i].pk;
    }
    for (let i = 0; i < this.positionen.length; i++) {
      const p = this.positionen[i];
      this.kostenArtDetailPos[p.kostenartdetail] = _.cloneDeep(p);
    }
  }

  updatePosition(pos) {
    let promise;
    if (pos.pk) {
      promise = super.updatePosition(pos);
    } else {
      this.newPosition = pos;
      promise = super.createPosition();
    }
    promise.then(
      response => {
        if (this.updateErrors[pos.kostenartdetail]) {
          delete this.updateErrors[pos.kostenartdetail];
        }
        return response;
      },
      err => {
        if (pos.kostenartdetail) {
          this.updateErrors[pos.kostenartdetail] = err.data;
        }
        return err;
      }
    );
    return promise;
  }

  _doPostPosition() {
    return this.antragKostenart.customPOST(this.newPosition, "positionen");
  }

  initNewPosition() {
    const newPos = super.initNewPosition();
    newPos.kostenartdetail = "";
    return newPos;
  }

  searchInSnapshot(artPk, koFiArt, pk) {
    if (!this.snapshot) {
      return;
    }
    if (this.snapshotDiffPositionen[artPk]) {
      delete this.snapshotDiffPositionen[artPk];
    }
    if (this.snapshotPositionen[artPk]) {
      delete this.snapshotPositionen[artPk];
    }
    if (this.snapshotdiff && this.snapshotdiff.modified && this.snapshotdiff.modified[koFiArt]) {
      for (let i = 0; i < this.snapshotdiff.modified[koFiArt].length; i++) {
        if (this.snapshotdiff.modified[koFiArt][i].pk === pk) {
          this.snapshotDiffPositionen[artPk] = this.snapshotdiff.modified[koFiArt][i].positionen;
        }
      }
    }
    if (this.snapshot.asn_snapshot && this.snapshot.asn_snapshot[koFiArt]) {
      for (let i = 0; i < this.snapshot.asn_snapshot[koFiArt].length; i++) {
        if (this.snapshot.asn_snapshot[koFiArt][i].kostenart.kos_id === artPk) {
          this.snapshotPositionen[artPk] = this.snapshot.asn_snapshot[koFiArt][i].positionen;
          return this.snapshot.asn_snapshot[koFiArt][i].aka_summe;
        }
      }
    }

    return;
  }

  searchInPositionSnapshot(position, artPk) {
    if (!this.snapshot || !position) {
      return;
    }
    if (this.snapshotIcons[position.akp_id]) {
      delete this.snapshotIcons[position.akp_id];
    }
    if (this.snapshotPositionClass[position.akp_id]) {
      delete this.snapshotPositionClass[position.akp_id];
    }
    if (this.snapshotPositionName[position.akp_id]) {
      delete this.snapshotPositionName[position.akp_id];
    }
    for (let i = 0; this.snapshotPositionen[artPk] && i < this.snapshotPositionen[artPk].length; i++) {
      if (this.snapshotPositionen[artPk][i].akp_id === position.akp_id) {
        if (this.snapshotDiffPositionen[artPk] && this.snapshotDiffPositionen[artPk].length) {
          for (let j = 0; j < this.snapshotDiffPositionen[artPk].length; j++) {
            if (this.snapshotDiffPositionen[artPk][j].pk === position.akp_id && (this.snapshotDiffPositionen[artPk][j].akp_wert || this.snapshotDiffPositionen[artPk][j].akp_bez)) {
              this.snapshotIcons[position.akp_id] = "glyphicon-changes";
              this.snapshotPositionClass[position.akp_id] = true;
              if (this.snapshotDiffPositionen[artPk][j].akp_bez) {
                this.snapshotPositionName[position.akp_id] = this.snapshotPositionen[artPk][i].akp_bez;
              }
            }
          }
        }
        return this.snapshotPositionen[artPk][i].akp_wert;
      }
    }
    this.snapshotIcons[position.akp_id] = "glyphicon-changes-add";
    this.snapshotPositionClass[position.akp_id] = true;
    return "0";
  }

  isChanges() {
    if (this.snapshotdiff) {
      if (this.snapshotdiff.modified.finanzierungsarten || this.snapshotdiff.modified.kostenarten) {
        return true;
      } else if (this.snapshotdiff.added.finanzierungsarten || this.snapshotdiff.added.kostenarten) {
        return true;
      } else if (this.snapshotdiff.removed.finanzierungsarten || this.snapshotdiff.removed.kostenarten) {
        return true;
      }
    }
    return false;
  }

  showRemovePos(diffKey, pk) {
    if (this.snapshotdiff && this.snapshotdiff.removed[diffKey]) {
      for (let i = 0; i < this.snapshotdiff.removed[diffKey].length; i++) {
        if (this.snapshotdiff.removed[diffKey][i].pk === pk) {
          return this.snapshotdiff.removed[diffKey][i].positionen;
        }
      }
    }
  }
}

export default KostenController;
