"use strict";

import template from "./traegerwechsel.wizard.jade";
import TraegerwechselWizardController from "./traegerwechsel.wizard.controller";

class TraegerwechselWizardCompController {
  /*@ngInject*/
  constructor($window, i18n) {
    this.gettext = i18n.gettext;
    this.$window = $window;
    this.wizardController = TraegerwechselWizardController;
    this.wizardData = [
      {
        label: "Anträge auswählen",
        template: "wizardStep0.html",
        tooltip: "Anträge auswählen",
      },
      {
        label: "Neuen Antragsteller auswählen",
        template: "wizardStep1.html",
        tooltip: "Neuen Antragsteller auswählen"
      },
      {
        label: "Neue Verantwortliche auswählen",
        template: "wizardStep2.html",
        tooltip: "Neue Verantwortliche auswählen"
      },
      {
        label: "Übersicht",
        template: "wizardStep3.html",
        tooltip: "Übersicht"
      },
      {
        label: "Trägerwechsel durchführen",
        template: "wizardStep4.html",
        tooltip: "Trägerwechsel durchführen"
      }
    ];
    this.wizardData.extra = {
      astId: this.astId,
    };
  }

  closeWizard() {
    this.$window.history.back();
  }
}

export default {
  template: template(),
  controller: TraegerwechselWizardCompController,
  controllerAs: "vm",
  bindings: {
    astId: "<"
  }
};
