import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import AnkuendigungCards from "../../Ankuendigung/AnkuendigungCards/AnkuendigungCards.vue";
import DatenschutzAbfrage from "../../Datenschutz/DatenschutzAbfrage/DatenschutzAbfrage.vue";
import FormElement from "../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxErrors from "../../../global/components/PuxErrors/PuxErrors.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxRequired from "../../../global/components/PuxRequired/PuxRequired.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import RegistrationHeader from "../RegistrationHeader/RegistrationHeader.vue";
import Spinner from "../../../global/components/Spinner/Spinner.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import KDatenschutzfrageanzeigeUUIDs from "../../../const/KDatenschutzfrageanzeigeUUIDs";
import {
  getUrlParams,
} from "../../../global/functions/help";
import {
  deleteCookie,
  getCookie,
} from "../../../global/functions/utils";
import {
  cloneDeep,
  forEach,
  sortBy,
} from "lodash-es";

const CLASSES_HORIZONTAL = [
  "col-sm-4 text-left text-sm-right",
  "col-sm-6",
  "offset-sm-4 col-sm-6",
];

// @vue/component
export default {
  name: "RegistrationPage",
  components: {
    AngularLink,
    AnkuendigungCards,
    DatenschutzAbfrage,
    FormElement,
    PuxButton,
    PuxCloak,
    PuxErrors,
    PuxPageDetails,
    PuxRequired,
    PuxTranslate,
    RegistrationHeader,
    Spinner,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
  ],
  data() {
    return {
      regBodyClass: undefined, // TODO: Ilia
      statusLoading: true,
      statusLoadingDatenschutzfragen: true,
      statusSuccess: undefined,
      statusTitelSelect: undefined,
      statusShowCode: undefined,
      statusSubmitting: undefined,
      datenschutzfragen: [],
      kkommtypes: [],
      errors: {},
      code_conf: {},
      model: {
        n_loginname: null,
        anrede: null,
        n_titel: undefined,
        titel: undefined,
        n_vorname: null,
        n_nachname: null,
        code: undefined,
      },
      optionsLogin: {
        id: "n_loginname",
        type: "email",
        label: "_LBL_REGISTRIERUNG_E_MAIL_",
        translate: true,
        required: true,
        view: "h",
        placeholder: "_LBL_REGISTRIERUNG_E_MAIL_",
        classesHorizontal: CLASSES_HORIZONTAL,
      },
      optionsAnrede: {
        id: "anrede",
        type: "select",
        label: "_LBL_REGISTRIERUNG_ANREDE_",
        translate: true,
        required: true,
        view: "h",
        url: "katalog/?key=kanrede",
        apiSaveId: "kanrede",
        keyId: "pk",
        keyLabel: "ank_kbez",
        classesHorizontal: CLASSES_HORIZONTAL,
      },
      optionsTitel: {
        id: "n_titel",
        type: "text",
        label: "_LBL_REGISTRIERUNG_TITEL_",
        translate: true,
        required: false,
        placeholder: "_LBL_REGISTRIERUNG_TITEL_",
        view: "h",
        classesHorizontal: CLASSES_HORIZONTAL,
      },
      optionsTitelSelect: {
        id: "titel",
        type: "select",
        label: "_LBL_REGISTRIERUNG_TITEL_",
        translate: true,
        required: false,
        url: "katalog/?key=titel",
        apiSaveId: "titel",
        keyId: "pk",
        keyLabel: "kti_kbez",
        view: "h",
        classesHorizontal: CLASSES_HORIZONTAL,
      },
      optionsVorname: {
        id: "n_vorname",
        type: "text",
        label: "_LBL_REGISTRIERUNG_VORNAME_",
        translate: true,
        required: true,
        view: "h",
        placeholder: "_LBL_REGISTRIERUNG_VORNAME_",
        classesHorizontal: CLASSES_HORIZONTAL,
      },
      optionsNachname: {
        id: "n_nachname",
        type: "text",
        label: "_LBL_REGISTRIERUNG_NACHNAME_",
        translate: true,
        required: true,
        view: "h",
        placeholder: "_LBL_REGISTRIERUNG_NACHNAME_",
        classesHorizontal: CLASSES_HORIZONTAL,
      },
      optionsCode: {
        id: "code",
        type: "text",
        label: "_LBL_REGISTRIERUNG_CODE_",
        translate: true,
        required: false,
        view: "h",
        placeholder: "_LBL_REGISTRIERUNG_CODE_",
        classesHorizontal: CLASSES_HORIZONTAL,
      },
      optionsKommtypes: [],
    };
  },
  computed: {
    submitDisabled() {
      return this.statusSubmitting
        || !this.requiredDatenschutzfragenFilledOut
        || !this.requiredFieldsFilledOut
        || !this.requiredKkommdatenFilledOut;
    },

    optionsListForPuxErrors() {
      return [
        this.optionsLogin,
        this.optionsAnrede,
        this.optionsTitel,
        this.optionsTitelSelect,
        this.optionsVorname,
        this.optionsNachname,
        this.optionsCode,
        ...this.optionsKommtypes,
      ];
    },

    requiredFieldsFilledOut() {
      return this.model.n_loginname
        && this.model.anrede
        && this.model.n_vorname
        && this.model.n_nachname;
    },

    requiredDatenschutzfragenFilledOut() {
      let countPflicht = 0;
      let countChecked = 0;
      forEach(this.datenschutzfragen, frage => {
        if (frage.def_pflicht === true) {
          countPflicht += 1;
          if (this.model[`datenschutzfrage_${ frage.pk }`]) {
            countChecked += 1;
          }
        }
      });
      return countPflicht === countChecked;
    },

    requiredKkommdatenFilledOut() {
      let countPflicht = 0;
      let countChecked = 0;
      forEach(this.kkommtypes, kot => {
        if (kot.kot_pflicht === true) {
          countPflicht += 1;
          if (this.model[`kommdaten_${ kot.pk }`]) {
            countChecked += 1;
          }
        }
      });
      return countPflicht === countChecked;
    },
  },
  created() {
    this.initModelLoginnameFromCookie();
    this.loadDatenschutzfragen();
    this.checkSyConfig();
  },
  methods: {
    initModelLoginnameFromCookie() {
      this.model.n_loginname = getCookie("diasTempEmail") || null;
    },

    loadDatenschutzfragen() {
      this.getHttp({
        url: "katalog/?key=kdatenschutzfrage&anzeigen=" + KDatenschutzfrageanzeigeUUIDs.REGISTRIERUNG,
      }).then(
        response => {
          this.datenschutzfragen = response;
          forEach(response, frage => {
            this.model[`datenschutzfrage_${ frage.pk }`] = null;
          });
          this.statusLoadingDatenschutzfragen = false;
        }
      );
    },

    checkSyConfig() {
      this.statusTitelSelect = this.getSyConfigsValue("nutzer_titel");
      this.setModelForTitel();
      this.code_conf = this.getSyConfigsValue("codes_einloesen") || {};
      this.setCode();
      const REGISTRATION_DEFAULT = this.getSyConfigsValue("registration_default") || {};
      this.loadKkommtyp(REGISTRATION_DEFAULT);
    },

    setModelForTitel() {
      if (this.statusTitelSelect) {
        this.model.titel = null;
      } else {
        this.model.n_titel = null;
      }
    },

    loadKkommtyp(conf) {
      if (conf.kot_ids && conf.kot_ids.length) {
        this.getHttp({
          url: "katalog/?key=kkommtyp",
          urlParams: {
            pk: conf.kot_ids,
          }
        }).then(
          response => {
            this.kkommtypes = response;
            this.setOptionsKommtypes(response);
            this.statusLoading = false;
          }
        );
      } else {
        this.statusLoading = false;
      }
    },

    setOptionsKommtypes(response) {
      const RESPONSE_SORTED = sortBy(response, ["kot_pos"]);
      const OPTIONS_KOMMTYPES = [];
      forEach(RESPONSE_SORTED, item => {
        OPTIONS_KOMMTYPES.push({
          id: `kommdaten_${ item.pk }`,
          label: item.kot_kbez,
          translate: false,
          required: item.kot_pflicht,
          type: "text",
          view: "h",
          placeholder: item.kot_placeholder || item.kot_kbez,
          classesHorizontal: CLASSES_HORIZONTAL,
          helpText: item.kot_hilfetext,
        });
        this.model[`kommdaten_${ item.pk }`] = null;
      });
      this.optionsKommtypes = OPTIONS_KOMMTYPES;
    },

    setCode() {
      if (this.code_conf) {
        this.model.code = getUrlParams().code || null;
        this.statusShowCode = this.code_conf.on_register && this.code_conf.show_default ? true : this.model.code && this.code_conf.on_register;
      }
    },

    toggleCode() {
      this.statusShowCode = !this.statusShowCode;
    },

    registrieren() {
      deleteCookie("diasTempEmail");
      this.statusSubmitting = true;
      const DATA = cloneDeep(this.model);
      this.deleteErrors();
      this.postHttp({
        url: "auth/registrieren/",
        data: DATA,
      }).then(
        response => {
          if (response.error) {
            this.onError(response.error);
          } else {
            this.statusSuccess = true;
          }
        },
        errors => {
          this.onError(errors.data);
        }
      ).then(
        () => this.statusSubmitting = false
      );
    },

    onError(errors) {
      this.errors = errors;
    },

    removeCode() {
      this.model.code = undefined;
      this.statusShowCode = false;
    },

    deleteErrors() {
      this.errors = {};
    },
  },
};
