import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "AuszahlungWizardMoneyInfo",
  components: {
    PuxTranslate,
  },
  props: {
    auszahlungsfrist: {
      type: String,
      required: false,
      default: undefined,
    },
    auszahlungsplanDisplay: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup() {
    const {
      filterDate,
      filterDefaultForEmpty,
    } = FiltersAPI();

    return {
      filterDate,
      filterDefaultForEmpty,
    };
  },
  computed: {
    auszahlungsfristLocal() {
      return this.filterDefaultForEmpty(this.filterDate(this.auszahlungsfrist));
    },
  },
};
