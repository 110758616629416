import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import PuxTooltip from "../../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

// @vue/component
export default {
  name: "SitzungAntraegeEntscheidungsstatusBox",
  components: {
    AsideRightBox,
    PuxTooltip,
    PuxTranslate,
  },
  props: {
    sitzungListeAntrag: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      checkPermissionsSync,
    };
  },
  computed: {
    optionsAsideRightBox() {
      return {
        icon: this.entscheidungsstatusObj.est_icon,
        active: true,
        toggle: false,
        iconClass: this.asideRightBoxIconClass,
        hideBody: this.isBodyHide,
      };
    },

    isBodyHide() {
      return !this.isBadgeAntragAuflageVisible && !this.isBadgeAntragAuflageProtokoll;
    },

    asideRightBoxIconClass() {
      return this.entscheidungsstatusObj.est_cssklasse ? `text-${ this.entscheidungsstatusObj.est_cssklasse }` : "";
    },

    entscheidungsstatusObj() {
      return this.sitzungListeAntrag.entscheidungsstatus_obj || {};
    },

    isBadgeAntragAuflageVisible() {
      return this.sitzungListeAntrag.sla_entscheidung_anmerkung && this.hasPermissionSitzungenAntragView;
    },

    isBadgeAntragAuflageProtokoll() {
      return this.sitzungListeAntrag.sla_protokoll && this.hasPermissionSitzungenProtokollView;
    },

    hasPermissionSitzungenAntragView() {
      return this.checkPermissionsSync({
        perm: "sitzungen.antrag.view",
        permArray: this.sitzungListeAntrag.user_permissions,
      });
    },

    hasPermissionSitzungenProtokollView() {
      return this.checkPermissionsSync({
        perm: "sitzungen.protokoll.view",
        permArray: this.sitzungListeAntrag.user_permissions,
      });
    },
  },
};
