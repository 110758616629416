var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;













jade_mixins["einfachfoerdermodul"] = jade_interp = function(idx){
var block = (this && this.block), attributes = (this && this.attributes) || {};
var rulesKbez = "vm.modul.regel[vm.modul.prefix + '_kbez']"
var rulesBez = "vm.modul.regel[vm.modul.prefix + '_bezeichnung']"
{
buf.push("<div class=\"form-step-detail business-rules\">");
if ( (rulesKbez || rulesBez))
{
buf.push("<div class=\"rules\"><h5" + (jade.attr("ng-bind-html", "" + (rulesKbez) + "", true, true)) + "></h5><div" + (jade.attr("ng-bind-html", "" + (rulesBez) + "", true, true)) + "></div></div>");
}
buf.push("</div>");
}
};













































buf.push("<!--+foerdermodul(\"{{vm.idx}}\", \"{{ vm.modul.regel.afr_kbez }}\", null)+readonly-block()\n  fieldset\n    div(ng-bind-html=\"vm.modul.regel.afr_astbez\")-->");
jade_mixins["einfachfoerdermodul"]("{{vm.idx}}");;return buf.join("");
}