import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import WorkflowfolgenDetailsAusfuerungskonfigurationServerfunktion
  from "./WorkflowfolgenDetailsAusfuerungskonfigurationServerfunktion/WorkflowfolgenDetailsAusfuerungskonfigurationServerfunktion.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import { getTextFromPlaceholder } from "../../../../global/functions/utils";

import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "WorkflowfolgenDetailsAusfuerungskonfiguration",
  components: {
    AngularLink,
    FormElement,
    FormstepDetail,
    Permission,
    PuxButton,
    PuxTranslate,
    WorkflowfolgenDetailsAusfuerungskonfigurationServerfunktion,
  },
  props: {
    workflowfolge: {
      type: Object,
      required: true,
    },
    workflowfolgeUrl: {
      type: String,
      required: true,
    },
    infoTexts: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "updateWorkflowfolge",
  ],
  setup() {
    const {
      postHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      addNotification,
      checkPermissionsSync,
      postHttp,
    };
  },
  data() {
    return {
      htmlId: "workflowfolgen_details_ausfuerungskonfiguration_formstep",
      loading: false,
      errors: {},
      model: {
        clientfunktion: undefined,
        statusfunktionen: [],
        serverfunktionen: [],
      },
      serverfunktionenSelectModel: undefined,
      serverfunktionenSelected: undefined,
    };
  },
  computed: {
    formstepDetailOptions() {
      return {
        label: "_TXT_WORKFLOWFOLGENDETAILS_EXECUTION_CONFIGURATION_",
        required: true,
        showCloseButton: true,
        saveCallback: this.save,
        openCallback: this.open,
      };
    },

    hasPermissionUpdateAusfuerungskonfiguration() {
      return this.checkPermissionsSync({
        perm: "workflowfolge.update.funktionen",
      });
    },

    hasPermissionUpdateClientfunktionUi() {
      return this.checkPermissionsSync({
        perm: "clientfunktion.ui",
      });
    },

    hasPermissionUpdateStatusfunktionUi() {
      return this.checkPermissionsSync({
        perm: "statusfunktion.ui",
      });
    },

    labelNoInformation() {
      return `[${ getTextFromPlaceholder("_TXT_WORKFLOWFOLGENDETAILS_NO_INFORMATION_") }]`;
    },

    labelNoDescription() {
      return `[${ getTextFromPlaceholder("_TXT_WORKFLOWFOLGENDETAILS_NO_DESCRIPTION_") }]`;
    },

    optionClientfunktion() {
      const ID = "clientfunktion";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "select",
        view: "v-alt",
        label: "_TXT_WORKFLOWFOLGENDETAILS_CLIENTFUNKTION_",
        keyId: "pk",
        keyLabel: "bez",
        url: "katalog/?key=clientfunktionen",
        apiSaveId: "clientfunktionen",
        search: true,
        searchList: ["bez"],
        required: true,
        deselect: true,
      };
    },

    optionStatusfunktionen() {
      const ID = "statusfunktionen";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "multiselect",
        view: "v-alt",
        label: "_TXT_WORKFLOWFOLGENDETAILS_STATUSFUNKTIONEN_",
        keyId: "pk",
        keyLabel: "bez",
        url: "katalog/?key=statusfunktionen",
        apiSaveId: "statusfunktionen",
        search: true,
        searchList: ["bez"],
        deselectAll: true,
      };
    },

    optionServerfunktionen() {
      const ID = "serverfunktionenSelectModel";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "select",
        view: "v-alt",
        label: "_LBL_WORKFLOWFOLGENDETAILS_ELEMENT_AUSWAEHLEN_",
        keyId: "pk",
        keyLabel: "wfs_bez",
        url: "katalog/?key=serverfunktionen",
        apiSaveId: "serverfunktionen",
        search: true,
        searchList: ["wfs_bez"],
        change: this.onServerfunktionSelectChange,
      };
    },

    optionsList() {
      return [
        this.optionClientfunktion,
        this.optionStatusfunktionen,
      ];
    },

    buttonAddServerfunktionDisabled() {
      return !this.serverfunktionenSelectModel;
    },
  },
  methods: {
    open() {
      this.errors = {};
      this.serverfunktionenSelected = {};
      this.serverfunktionenSelectModel = undefined;
      const WORKFLOWFOLGE = cloneDeep(this.workflowfolge);
      if (WORKFLOWFOLGE.clientfunktion_obj) {
        this.model.clientfunktion = WORKFLOWFOLGE.clientfunktion_obj.pk;
      }
      const STATUSFUNKTIONEN = [];
      forEach(WORKFLOWFOLGE.statusfunktionen_objs, item => {
        STATUSFUNKTIONEN.push(item.pk);
      });
      this.model.statusfunktionen = STATUSFUNKTIONEN;
      this.model.serverfunktionen = WORKFLOWFOLGE.serverfunktionen_objs;
    },

    onServerfunktionSelectChange({ item }) {
      this.serverfunktionenSelected = cloneDeep(item);
    },

    addServerfunktion() {
      this.model.serverfunktionen.push(this.serverfunktionenSelected);
      this.serverfunktionenSelected = {};
      this.serverfunktionenSelectModel = undefined;
    },

    moveServerfunktionUp({ index }) {
      const CURRENT = cloneDeep(this.model.serverfunktionen[index]);
      this.model.serverfunktionen.splice(index, 1);
      this.model.serverfunktionen.splice(index - 1, 0, CURRENT);
    },

    moveServerfunktionDown({ index }) {
      const CURRENT = cloneDeep(this.model.serverfunktionen[index]);
      this.model.serverfunktionen.splice(index, 1);
      this.model.serverfunktionen.splice(index + 1, 0, CURRENT);
    },

    removeServerfunktion({ index }) {
      this.model.serverfunktionen.splice(index, 1);
    },

    save() {
      const DATA = {};
      DATA.clientfunktion = cloneDeep(this.model.clientfunktion);
      DATA.statusfunktionen = cloneDeep(this.model.statusfunktionen);
      const SERVERFUNKTIONEN = [];
      forEach(this.model.serverfunktionen, item => {
        SERVERFUNKTIONEN.push(item.pk);
      });
      DATA.serverfunktionen = SERVERFUNKTIONEN;
      return this.postHttp({
        url: `${ this.workflowfolgeUrl }funktionen_bearbeiten/`,
        data: DATA,
      }).then(
        response => {
          this.addNotification({ text: "_MSG_WORKFLOWFOLGENDETAILS_SAVE_SUCCESS_" });
          this.$emit("updateWorkflowfolge", { data: response });
          return Promise.resolve();
        },
        errors => {
          this.onErrors(errors);
          return Promise.reject(errors);
        },
      );
    },

    onErrors(errors) {
      this.errors = errors.data;
    },
  },
};
