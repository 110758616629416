"use strict";

import angular from "angular";

import coreModels from "dias/core/models";
import Sachberichtvorlagen from "./sachberichtvorlagen.service";

const modelsModule = angular.module("sachberichtvorlage.models", [
  coreModels.name
])

  .provider("Sachberichtvorlagen", Sachberichtvorlagen)
;

export default modelsModule;
