// @vue/component
export default {
  name: "FaqBadge",
  props: {
    badgeId: {
      type: String,
      required: false,
      default: undefined,
      info: "ID der Badge",
    },
    count: {
      type: Number,
      required: true,
      info: "Zahl, die angezeigt wird."
    },
  },
};
