import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../../global/compositionAPI/NotificationAPI";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "AngebotModalZuordnen",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  props: {
    programm: {
      type: Object,
      required: true,
    },
    programmUrl: {
      type: String,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      postHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      postHttp,
    };
  },
  data() {
    return {
      model: {
        angebot: undefined,
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "angebot",
            type: "select",
            label: "_LBL_ANGEBOT_MODAL_ZUORDNEN_FOERDERANGEBOT_",
            view: "v",
            url: `${ this.programmUrl }zuordbare_angebote/`,
            required: true,
            keyId: "foe_id",
            keyLabel: "foe_kbez",
            search: "true",
            searchList: ["foe_kbez"],
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    angeboteUrl() {
      return `${ this.programmUrl }angebote/`;
    },
  },
  methods: {
    save() {
      this.status.loading = true;

      this.postHttp({
        url: this.angeboteUrl,
        data: this.model,
      }).then(
        () => {
          this.addNotification({ text: "_MSG_ANGEBOT_MODAL_ZUORDNEN_SAVE_SUCCESS_" });
          this.close({ status: true });
        },
        error => {
          this.errors = cloneDeep(error.data);
          this.errorsClone = cloneDeep(error.data);
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
