import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import AntragstellerListDetails from "../AntragstellerListDetails/AntragstellerListDetails.vue";
import AntragstellerModalMarkierenDublette from "../AntragstellerModalMarkierenDublette/AntragstellerModalMarkierenDublette.vue";
import AntragstellerModalVacateAstAdmin from "../AntragstellerModalVacateAstAdmin/AntragstellerModalVacateAstAdmin.vue";
import AntragstellerModalZusammenfuehrenDublette from "../AntragstellerModalZusammenfuehrenDublette/AntragstellerModalZusammenfuehrenDublette.vue";
import AntragstellerWizard from "../AntragstellerWizard/AntragstellerWizard.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import ShowMore from "../../../global/components/ui/ShowMore/ShowMore.vue";
import SmartTable from "../../../global/components/table/table.vue";

import DynamicRootForComponentsMixin from "../../DynamicRoot/DynamicRootForComponentsMixin";
import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import {
  EventBus,
} from "../../../global/functions/event-bus";
import {
  assign,
  forEach,
  cloneDeep,
} from "lodash-es";

const TABLE_ID = "antragsteller";

// @vue/component
export default {
  name: "AntragstellerList",
  components: {
    AngularLink,
    AntragstellerListDetails,
    AntragstellerModalMarkierenDublette,
    AntragstellerModalVacateAstAdmin,
    AntragstellerModalZusammenfuehrenDublette,
    AntragstellerWizard,
    PuxTranslate,
    PageTabTitle,
    ShowMore,
    SmartTable,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
    DynamicRootForComponentsMixin,
  ],
  props: {
    header: {
      type: String,
      required: false,
      default: "_TXT_ANTRAGSTELLER_TABLE_HEADER_",
      info: "Text für Tabelle-Header",
    },
    filter: {
      type: Object,
      required: false,
      default: undefined,
      info: "Zusätzliches Get-Parameter für API",
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: this.initUrl({ tableId: TABLE_ID, filter: this.filter }),
        baseUrl: this.initUrl({ tableId: TABLE_ID, filter: undefined }),
        rowActions: [
          {
            label: "_TXT_ANTRAGSTELLER_TABLE_ROW_ACTIONS_DETAILS_",
            route: "root.astorganisationen.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
          {
            label: "_TXT_ANTRAGSTELLER_TABLE_ROW_ACTIONS_ROLLE_ASTADMIN_ABGEBEN_",
            callback: this.askVacateAstAdmin,
            isHidden: ({ item }) => !this.canVacateAstAdmin({ item }),
          },
          {
            label: "_TXT_ANTRAGSTELLER_TABLE_ROW_ACTIONS_ANTRAEGE_ANZEIGEN_",
            isHidden: ({ item }) => !this.canViewAntrag({ item }),
            route: "root.foerderantrag",
            routeParameters: [{ routeId: "antragstellerorg", rowPath: "pk" }],
          },
          {
            label: "_TXT_ANTRAGSTELLER_TABLE_ROW_ACTIONS_TRARGERWECHSEL_DURCHFUEHREN_",
            isHidden: ({ item }) => !this.canDoTraegerwechsel({ item }),
            route: "root.astorganisationen.details.traegerwechsel",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
          {
            label: "_TXT_ANTRAGSTELLER_TABLE_ROW_ACTIONS_DUBLETTE_MARKIEREN_",
            callback: this.markierenDublette,
            isHidden: ({ item }) => !this.canBecomeDublette({ item }),
          },
          {
            label: "_TXT_ANTRAGSTELLER_TABLE_ROW_ACTIONS_DUBLETTE_DELETE_",
            callback: this.deleteDublette,
            isHidden: ({ item }) => !this.canRemoveDublette({ item }),
          },
          {
            label: "_TXT_ANTRAGSTELLER_TABLE_ROW_ACTIONS_ZUSAMMENFUEHREN_",
            callback: this.zusammenfuehrenDublette,
            isHidden: ({ item }) => !this.canJoinKopfdublette({ item }),
          },
        ],
        actions: [],
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
          details: "right",
        },
      },
      as_admin: undefined,
      can_create_astorg: undefined,
      user: undefined,
      currentAntragsteller: undefined,
      currentIndex: undefined,
      promisesAll: [],
      promiseGetUser: undefined,
      statusLoading: true,
      modal: {
        vacateAstAdmin: undefined,
        zusammenfuehrenDublette: undefined,
        markierenDublette: undefined,
      },
      statusWizardShow: undefined,
      hideSteps: ["zugang"],
      orgtypen: [],
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },

    getSlotNameTableDetailsHeader() {
      return `${ this.tableId }_table_details_header`;
    },
  },
  created() {
    this.getUser();
    this.initFoerderorgTypen();
    this.awaitAllPromises();
  },
  methods: {
    getUser() {
      this.user = cloneDeep(this.me);
      this.as_admin = this.checkPermissionsSync({ perm: "antragstellerorg.verwalten_alle" });
      this.can_create_astorg = this.checkPermissionsSync({ perm: "antragstellerorg.create" });
    },

    initFoerderorgTypen() {
      this.promiseFoerderorgtypen = this.getListHttp({ url: "foerderorgtypen/" });
      this.promisesAll.push(this.promiseFoerderorgtypen);
      this.promiseFoerderorgtypen.then(
        response => this.orgtypen = response
      );
    },

    awaitAllPromises() {
      Promise.all(this.promisesAll).then(
        () => {
          this.initActions();
          this.statusLoading = false;
        }
      );
    },

    initActions() {
      if (this.can_create_astorg) {
        this.options.actions.push({
          label: "_TXT_ANTRAGSTELLER_TABLE_ACTIONS_TAETIG_ANTRAGSTELLER_NEW_",
          callback: this.onCreateAst,
          type: "button",
          className: "btn btn-primary test_ast_create",
          icon: "glyphicon-plus",
        });
      }
    },

    onCreateAst() {
      this.statusWizardShow = true;
    },

    closeWizard({ organisation }) {
      if (organisation) {
        this.$goTo("root.astorganisationen.details", { id: organisation.pk });
      }
      this.statusWizardShow = false;
    },

    askVacateAstAdmin({ item }) {
      this.currentAntragsteller = item;
      this.modal.vacateAstAdmin = true;
    },

    canVacateAstAdmin({ item }) {
      if (!this.user) {
        return;
      }
      return ((item.master.length > 1 &&
        item.master.map(
          function(e) {
            return e.n_id;
          }
        ).indexOf(this.user.n_id) !== -1));
    },

    closeModalVacateAstAdmin({ status } = {}) {
      if (status) {
        this.$AuthService.refreshUser().then(user => this.user = user);
      }
      this.modal.vacateAstAdmin = false;
    },

    canViewAntrag({ item }) {
      return this.checkPermissionsSync({ perm: "foerderantrag.view", permArray: item.user_permissions });
    },

    canDoTraegerwechsel({ item }) {
      return this.checkPermissionsSync({ perm: "antragstellerorg.traegerwechsel", permArray: item.user_permissions });
    },

    markierenDublette({ item, index }) {
      this.currentAntragsteller = item;
      this.currentIndex = index;
      this.modal.markierenDublette = true;
    },

    closeModalMarkierenDublette({ newRow } = {}) {
      if (newRow) {
        EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: newRow, index: this.currentIndex }));
      }
      this.modal.markierenDublette = false;
    },

    canBecomeDublette({ item }) {
      return this.checkPermissionsSync({ perm: "antragstellerorg.dubletten_verwalten", permArray: item.user_permissions }) && !item.kopfdublette;
    },

    canRemoveDublette({ item }) {
      return this.checkPermissionsSync({ perm: "antragstellerorg.dubletten_verwalten", permArray: item.user_permissions }) && item.kopfdublette;
    },

    deleteDublette({ item, index }) {
      this.putHttp({ url: `antragstellerorganisationen/${ item.pk }/dubletten_markierung_entfernen/` }).then(
        response => {
          this.addNotification({ text: "_MSG_ANTRAGSTELLER_TABLE_DUBLETTE_DELETE_SUCCESS_" });
          const ROW_NEW = assign({}, item, response);
          EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: ROW_NEW, index }));
        },
        () => this.addNotification({ text: "_MSG_ANTRAGSTELLER_TABLE_DUBLETTE_DELETE_ERROR_", type: "error" })
      );
    },

    zusammenfuehrenDublette({ item }) {
      this.currentAntragsteller = item;
      this.modal.zusammenfuehrenDublette = true;
    },

    closeModalZusammenfuehrenDublette({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.modal.zusammenfuehrenDublette = false;
    },

    canJoinKopfdublette({ item }) {
      return this.checkPermissionsSync({ perm: "antragstellerorg.dubletten_zusammenfuehren", permArray: item.user_permissions }) && item.kopfdublette;
    },

    renderFoerderorg({ row, typ }) {
      let result = "";
      if (row.foerderorganisation_obj) {
        forEach(row.foerderorganisation_obj.foerderorghierarchie, item => {
          if (item.o_otid === typ) {
            result = item.o_kbez;
          }
        });
      }
      return result;
    },
  },
};
