import {
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "SchaltflaecheReadonly",
  props: {
    antwort: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      default: () => ({}),
    },
    mehrfachauswahl: {
      type: Boolean,
      required: true,
    },
    statusAnzeigeAuswahl: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    children() {
      if (!this.antwort.children ||
        !this.antwort.children.length ||
        !this.model[this.antwort.label]) {
        return [];
      }
      if (this.mehrfachauswahl) {
        return filter(this.antwort.children, child => {
          return this.model[this.antwort.label].children &&
            this.model[this.antwort.label].children[child.label] &&
            this.model[this.antwort.label].children[child.label].status;
        });
      }
      return filter(this.antwort.children, child => {
        return this.model[this.antwort.label].children &&
          this.model[this.antwort.label].children[child.label] &&
          this.model[this.antwort.label].children[child.label].status;
      });
    },

    statusActive() {
      if (this.model[this.antwort.label] && this.model[this.antwort.label].status) {
        return true;
      }
      return this.children.length;
    },

    statusShow() {
      if (!this.statusAnzeigeAuswahl) {
        return true;
      }
      return this.statusActive;
    },
  },
};
