"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";

class FoerderangebotBasisdatenEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, Foerderangebotinstrumentvarianten) {
    super($q, $scope, DiasNotification);
    this.errorsLabel = {
      instrumentvariante: "Instrumentvariante: ",
      foe_kbez: "Kurzbezeichnung: ",
      foe_bez: "Bezeichnung: ",
      foe_kuerzel: "Kürzel: "
    };
    this.loadingEditData = true;

    Foerderangebotinstrumentvarianten.getList().then(
      response => this.varianten = response
    ).finally(() => this.loadingEditData = false);

    this.save = errorsLabel => {
      // Überschreiben muss Umweg über an Klasse definierte save-Methode wählen, da sonst die this-Kontexte durcheinander kommen
      return super._save(errorsLabel).then(
        () => {
          $scope.model.instrumentvariante_obj = this.varianten.getById(this.model.instrumentvariante);
        }
      );
    };
  }
}

export default FoerderangebotBasisdatenEditController;
