import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxPageDetails from "../../../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import SmartTable from "../../../../../global/components/table/table.vue";
import Spaced from "../../../../../global/components/Spaced/Spaced.vue";

import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";

import getTableApi from "../../../../../const/TableApis";
import {
  setUrlWithParams,
} from "../../../../../global/functions/help";

import {
  filter,
  map,
} from "lodash-es";


const TABLE_ID = "BerichtVertraegeAltfaelle";
const EXCLUDED_AUFGABEN = [
  "f3affdb0-988d-4270-aede-e1efdecc4eca", // Fördervertrag archiviert
  "11e6b5c5-135a-4a01-82b4-13729d5c833d", // Fördervertrag gekündigt
  "f6c7bd5c-de69-11e7-80c1-9a214cf093ae" // Fördervertrag nicht angenommen
];

// @vue/component
export default {
  name: "BerichtVertraegeAltfaelle",
  components: {
    AngularLink,
    PuxPageDetails,
    SmartTable,
    Spaced,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      getHttp,
    } = HttpAPI();

    return {
      getHttp,
    };
  },
  data() {
    return {
      defaults: [],
      loading: true,
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: undefined,
        rowActions: [
          {
            label: "_LBL_BERICHT_VERTRAEGE_ALTFAELLE_ROW_ACTIONS_DETAILS_",
            route: "root.vertrag.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        label: this.report.tab_kbez,
        sortable: true,
        pagination: true,
        details: false,
        showHeader: false,
        view: {
          filter: "top",
        },
      },
    };
  },
  created() {
    this.initUrl();
  },
  methods: {
    initUrl() {
      this.getHttp({
        url: "katalog/",
        urlParams: {
          key: "vertragsaufgabe",
        },
      }).then(
        response => {
          this.defaults = map(filter(response, item => {
            return EXCLUDED_AUFGABEN.indexOf(item.pk) === -1;
          }), "pk");
          this.options.url = setUrlWithParams({
            url: getTableApi({ id: TABLE_ID, params: { tabId: this.report.pk } }),
            params: { status_detail: this.defaults }
          });
          this.loading = false;
        }
      );
    }
  },
};
