"use strict";
import "../models";
import DefaultWizardController from "dias/core/wizard/defaultWizard.controller.js";


class CreateAstWizardController extends DefaultWizardController {
  /*@ngInject*/
  constructor(
    $q,
    $scope,
    $state,
    $filter,
    $window,
    $sce,
    i18n,
    Client,
    Antragstellerorganisationen,
    Foerderantrag,
    AuthService,
    FoerderorgTyp,
    Foerderorganisation,
    Foerderorgnutzer,
    KNutzerstatusUUIDs,
    DiasNotification,
    SyConfigs,
    Katalog,
    diasConfirmDialog
  ) {
    super();
    this.$q = $q;
    this.$scope = $scope;
    this.$state = $state;
    this.$filter = $filter;
    this.$window = $window;
    this.$sce = $sce;
    this.Client = Client;
    this.KNutzerstatusUUIDs = KNutzerstatusUUIDs;
    this.DiasNotification = DiasNotification;
    this.diasConfirmDialog = diasConfirmDialog;
    this.Antragstellerorganisationen = Antragstellerorganisationen;
    this.AuthService = AuthService;
    this.SyConfigs = SyConfigs;
    this.Katalog = Katalog;
    this.Foerderorgnutzer = Foerderorgnutzer;
    this.Foerderorganisation = Foerderorganisation;
    this.FoerderorgTyp = FoerderorgTyp;
    this.Foerderantrag = Foerderantrag;
    this.closeWizardTitle = "Trägerwechsel schließen";
    this.gettext = i18n.gettext;

    this.errorMap = {
      antraege: "Anträge: ",
      ziel_antragsteller: `Ziel-${ this.gettext("Antragstellerorganisation") }: `,
      astnutzer: `Verantwortlicher der Ziel-${ this.gettext("Antragstellerorganisation") }: `,
      orgzustaendige: `Verantwortlicher der ${ this.gettext("Förderoroganisationen") }: `,
    };

    this.wfData = $scope.$parent.vm.data;
    this.srcAst = this.wfData.extra.astId;
    this.step = [
      {
        cache: true,
        init: () => this.initStep0(),
        enter: () => this.enterStep0(),
        leave: () => true
      }, {
        cache: true,
        init: () => this.initStep1(),
        enter: () => this.enterStep1(),
        leave: () => true
      }, {
        cache: false,
        init: () => this.initStep2(),
        enter: () => this.enterStep2(),
        leave: () => true
      }, {
        cache: false,
        init: () => this.initStep3(),
        enter: () => this.enterStep3(),
        leave: dir => this.leaveStep3(dir)
      }, {
        cache: false,
        init: () => this.initStep4(),
        enter: () => this.enterStep4(),
        leave: dir => this.leaveStep4(dir)
      }
    ];
    this.init();
    this.gotoStep(0);
  }

  updateForederorgSelection(typen, org) {
    let orgs = [];
    if (org.foerderorghierarchie) {
      orgs = org.foerderorghierarchie.slice().reverse();
    }
    let anz = 0;
    angular.forEach(typen, fitem => {
      const org = orgs.find(o => fitem.typen.findIndex(v => v.pk === o.o_otid) !== -1);
      if (org) {
        anz++;
        fitem.selected = org;
        const filtered = fitem.typen.filter(
          t => orgs.findIndex(o => t.pk === (o.typ || o.o_otid)) !== -1
        ).map(t => t.ot_kbez);
        if (filtered.length > 0) {
          fitem.bez = filtered.join(" / ");
        } else {
          fitem.bez = "Unbekannt";
        }
      } else {
        fitem.selected = undefined;
      }
    });
    typen.anzSelected = anz;
  }

  init() {
    this.initLoading = true;
    this.statusIds = this.SyConfigs.wert("traegerwechsel");
    const statusPromise = this.Katalog.getKatalog("kaufgabe", { pk: this.statusIds }).then(
      response => this.allowedStatus = response,
      err => this.error = err
    );
    const astPromise = this.Antragstellerorganisationen.one(this.srcAst).get({
      fields: ["pk", "ast_name", "ast_nr", "ast_dmsakte", "debitor",
               "ast_strasse", "ast_hsnr", "ast_plz", "ast_ort",
               "foerderorganisation_obj"]
    }).then(
      response => {
        this.ast = response;
        this.wfData.extra.ast = this.ast;
      },
      err => this.error = err
    );
    const otyPromise = this.FoerderorgTyp.getList().then(
      response => {
        this.foerderorgTypen = [];
        const tmp = {};
        angular.forEach(response, item => {
          tmp[item.ot_pos] = tmp[item.ot_pos] || { pos: item.ot_pos, typen: [], bez: "", data: null, selected: [] };
          tmp[item.ot_pos].typen.push(item);
        });
        angular.forEach(tmp, item => {
          item.typen.sort((a, b) => a.ot_kbez.localeCompare(b.ot_kbez));
          item.bez = item.typen[0].ot_kbez;
          item.filter = { typ: [item.typen[0].pk] };
          for (let i = 1; i < item.typen.length; i++) {
            item.bez = `${ item.bez } / ${ item.typen[i].ot_kbez }`;
            item.filter.typ.push(item.typen[i].pk);
          }
          this.foerderorgTypen.push(item);
        });
        this.foerderorgTypen.sort((a, b) => a.ot_pos - b.ot_pos);
        this.zielFoerderorgTypen = this.foerderorgTypen.map(v => Object.assign({}, v));
      },
      err => this.error = err
    );
    this.initPromise = this.$q.all([
      statusPromise,
      astPromise,
      otyPromise
    ]).then(() => {
      this.updateForederorgSelection(this.foerderorgTypen, this.ast.foerderorganisation_obj);
    });
    this.initPromise.finally(() => this.initLoading = false);
  }

  gotoStep(idx) {
    if (!this.step[idx].done || !this.step[idx].cache) {
      this.step[idx].init();
      this.step[idx].done = true;
    }
    this.forwardStepDisabled = true;
    this.step[idx].enter();
  }

  initStep0() {
    this.forwardStepDisabled = true;

    this.antraege = [];
    this.antragTranslations = {
      searchPlaceholder: `Nach Antragsnummern suchen (z.B. "60 000 000, 60 000 001")`,
    };
    this.antragOptions = {
      getChoices: (valueField, labelField, params) => {
        const p = Object.assign({}, params, {
          antragstellerorg: this.srcAst,
          status: this.statusIds,
          fields: ["a_nr", "a_antragsdatum", "a_bewilligungsdatum"]
        });
        const defer = this.$q.defer();
        this.Foerderantrag.getChoices(valueField, labelField, p).then(
          response => {
            response.forEach(item => {
              item.label = this.$filter("spaced")(item.a_nr, true);
            });
            defer.resolve(response);
          },
          err => defer.reject(err)
        );
        return defer.promise;
      }
    };
    this.deselectAntrag = antrag => {
      const idx = this.antraege.indexOf(antrag);
      if (idx !== -1) {
        this.antraege.splice(idx, 1);
      }
    };

    this.onAntragSelectionChanged = () => {
      this.forwardStepDisabled = this.antraege.length === 0;
    };
  }

  enterStep0() {
    this.onAntragSelectionChanged();
  }

  initStep1() {
    this.zielAstSelection = [];

    this.astOptions = {
      one: id => {
        return this.Antragstellerorganisationen.one(id);
      },
      getChoices: (valueField, labelField, params) => {
        const p = Object.assign({}, params, {
          fields: [
            "pk", "ast_name", "ast_nr", "ast_dmsakte", "debitor",
            "ast_strasse", "ast_hsnr", "ast_plz", "ast_ort",
            "foerderorganisation"
          ]
        });
        const defer = this.$q.defer();
        this.Antragstellerorganisationen.getChoices(valueField, labelField, p).then(
          response => {
            if (response) {
              response.forEach(item => {
                item.label = `${ this.$filter("spaced")(item.ast_nr, true) } - ${ item.ast_name }`;
              });
            }
            defer.resolve(response);
          },
          err => defer.reject(err)
        );
        return defer.promise;
      }
    };

    this.onZielAstSelectionChanged = () => {
      if (this.zielAstSelection.length === 1) {
        this.zielAst = this.zielAstSelection[0];
      } else {
        this.zielAst = undefined;
      }
      this.forwardStepDisabled = this.zielAst === undefined;
    };
  }

  enterStep1() {
    this.forwardStepDisabled = this.zielAst === undefined;
  }

  initStep2() {
    this.anuSelection = [];
    this.fnuSelection = {};
    if (this.initLoading) {
      this.loading = true;
      this.initPromise.finally(() => this.loading = false);
    }
    const p = {
      aktiv: "True",
      fields: [
        "pk", "name", "is_astadmin", "email"
      ]
    };
    this.anuOptions = this.zielAst.nutzer.getChoices("pk", "name", p);
    this.anuSelectionSettings = {
      template: `<span title='{{:: getPropertyForObject(option, settings.displayProp)}}'>{{::getPropertyForObject(option, settings.displayProp)}}</span>
      <span ng-if="option.is_astadmin" title="${ this.gettext("Nutzerverwalter") }">(${ this.gettext("Nutzerverwalter") })</span>`
    };
    this.onAnuSelectionChanged = () => {
      if (this.anuSelection.length === 1) {
        this.anu = this.anuSelection[0];
      } else {
        this.anu = undefined;
      }
      this.forwardStepDisabled = this.anu === undefined;
    };
    this.zielFoerderorgLoading = true;
    this.fnu = {};
    this.Foerderorganisation.one(this.zielAst.foerderorganisation).get().then(
      response => {
        this.zielAst.foerderorganisation_obj = response;
        this.zielFoerderorg = response;
        this.updateForederorgSelection(this.zielFoerderorgTypen, this.zielFoerderorg);
        this.zielFoerderorgTypen.forEach((typ, idx) => {
          if (typ.selected) {
            if (this.foerderorgTypen[idx] && this.foerderorgTypen[idx].selected &&
                this.foerderorgTypen[idx].selected.pk === typ.selected.pk) {
              typ.emptyLabel = "[Verantwortliche beibehalten]";
              typ.equalsSrc = true;
            } else {
              typ.emptyLabel = "[Keinen neuen Verantwortlichen setzen]";
              typ.equalsSrc = false;
            }
            this.fnuSelection[typ.pos] = [{ id: "null", value: "null", label: typ.emptyLabel }];
            this.fnu[typ.selected.o_otid] = this.fnuSelection[typ.pos][0];
            typ.fnuOptions = {
              getChoices: (valueField, labelField, params) => {
                const p = Object.assign({}, params, {
                  foerderorganisation: typ.selected.pk,
                  status: this.KNutzerstatusUUIDs.AKTIV,
                  fields: [
                    "pk", "nutzer_obj",
                  ]
                });
                const defer = this.$q.defer();
                this.Foerderorgnutzer.getChoices(valueField, labelField, p).then(
                  response => {
                    if (response) {
                      response.forEach(item => {
                        item.label = item.nutzer_obj.name;
                      });
                    }
                    defer.resolve(response);
                  },
                  err => defer.reject(err)
                );
                return defer.promise;
              }
            };

            typ.onFnuSelectionChanged = () => {
              if (this.fnuSelection[typ.pos].length === 1) {
                this.fnu[typ.selected.o_otid] = this.fnuSelection[typ.pos][0];
              } else {
                this.fnu[typ.selected.o_otid] = undefined;
              }
            };
          }
        });
      },
      err => this.error = err
    ).finally(() => this.zielFoerderorgLoading = false);
  }

  getFoerderorgSameText(typ) {
    return `${ this.gettext("Die Förderorganisation auf der Ebene") } ${ typ.bez } ${ this.gettext("bleibt gleich") }`;
  }

  getFoerderorgChangedText(typ, idx) {
    let oldName = this.gettext("[keine Auswahl]");
    if (this.foerderorgTypen[idx] && this.foerderorgTypen[idx].selected) {
      oldName = this.foerderorgTypen[idx].selected.o_name;
    }
    return `${ this.gettext("Die Förderorganisation auf der Ebene") } ${ typ.bez } ${ this.gettext("ändert sich. Ursprüngliche Förderorganisationszugehörigkeit:") } ${ oldName }`;
  }

  enterStep2() {
    this.forwardStepDisabled = this.anu === undefined;
  }

  checkBestaetigung() {
    this.forwardStepDisabled = !this.bestaetigung;
  }

  initStep3() {}

  enterStep3() {
    this.bestaetigung = false;
  }

  leaveStep3(dir) {
    if (dir === "f") {
      const defer = this.$q.defer();
      this.diasConfirmDialog({
        title: "Trägerwechsel starten",
        content: "Sind Sie sicher, dass Sie den Trägerwechsel starten wollen?",
        okLabel: "Trägerwechsel starten",
        okCallback: () => defer.resolve(),
        cancelCallback: () => defer.reject()
      });
      return defer.promise;
    }
    return !this.backStepDisabled;
  }

  initStep4() {
    this.onTaskSuccess = () => {
      this.taskDone = true;
      this.closeWizardStatus = true;
      this.backStepHide = true;
    };

    this.onTaskFailure = err => {
      this.taskError = err;
      this.DiasNotification.form.error(err, "Fehler beim Durchführen des Trägerwechsels", undefined, this.errorMap);
      this.backStepDisabled = false;
      this.backTargetStepDisabled = false;
    };

    const astId = this.zielAst.pk;
    const antraege = this.antraege.map(v => v.pk);
    const anuId = this.anu.pk;
    const fnuKeys = Object.keys(this.fnu);
    const fnuIds = {};
    fnuKeys.forEach(k => {
      if (angular.isUndefined(this.fnu[k])) {
        fnuIds[k] = undefined;
      } else {
        fnuIds[k] = this.fnu[k].pk;
      }
    });

    this.ast.doTraegerwechsel(astId, antraege, anuId, fnuIds).then(
      response => {
        this.taskId = response.task_id;
      },
      err => {
        this.error = err;
        this.DiasNotification.form.error(err, "Fehler beim Übermitteln der Anfrage", undefined, this.errorMap);
        this.backStepDisabled = false;
        this.backTargetStepDisabled = false;
      }
    ).finally(() => this.taskSend = true);
  }

  enterStep4() {
    this.taskDone = false;
    this.taskSend = false;
    this.taskErorr = undefined;
    this.error = undefined;
    this.backStepDisabled = true;
    this.backTargetStepDisabled = true;
    this.forwardStepDisabled = true;
    this.forwardStepHide = true;
    this.DiasNotification.form.clear();
  }

  leaveStep4() {
    this.closeWizardStatus = false;
    this.forwardStepHide = false;
    this.taskId = undefined;
    return !this.backStepDisabled;
  }

  forwardStep() {
    const canLeave = this.step[this.activeStep].leave("f");
    if (angular.isFunction(canLeave.then)) {
      canLeave.then(
        () => {
          super.forwardStep();
          this.gotoStep(this.activeStep);
        },
        err => {
          if (err) {
            this.DiasNotification.page.error(err);
          }
        }
      );
    } else if (canLeave) {
      super.forwardStep();
      this.gotoStep(this.activeStep);
    }
  }

  backStep() {
    const canLeave = this.step[this.activeStep].leave("r");
    if (angular.isFunction(canLeave.then)) {
      canLeave.then(
        () => {
          super.backStep();
          this.gotoStep(this.activeStep);
        },
        err => {
          if (err) {
            this.DiasNotification.page.error(err);
          }
        }
      );
    } else if (canLeave) {
      super.backStep();
      this.gotoStep(this.activeStep);
    }
  }

  backTargetStep(currentIndex, targetIndex) {
    const canLeave = this.step[this.activeStep].leave("r");
    if (angular.isFunction(canLeave.then)) {
      canLeave.then(
        () => {
          super.backTargetStep();
          this.gotoStep(this.activeStep);
        },
        err => {
          if (err) {
            this.DiasNotification.page.error(err);
          }
        }
      );
    } else if (canLeave) {
      super.backTargetStep(currentIndex, targetIndex);
      this.gotoStep(this.activeStep);
    }
  }

  closeWizard() {
    this.$window.history.back();
  }
}

export default CreateAstWizardController;
