"use strict";
import {
  createApp,
  ref,
} from "vue";

import Components from "../../../../vue/client/vue/index";
import ASafeHtml from "aloha-vue/src/directives/ASafeHtml";
import store from "../../../../vue/client/vue/store";
import VueCookies from "vue-cookies";
import AIconPlugin from "aloha-vue/src/plugins/AIconPlugin";
import icons from "../../../../../frontend/client/icons";

import {
  forEach,
} from "lodash-es";

export default /*@ngInject*/ (
  $state,
  AuthService,
  SyConfigs,
  i18n,
  $location,
  $timeout,
  $stateParams,
  DiasNotification,
  Client,
  Raven
) => {
  return {
    restrict: "A",
    scope: {
      props: "<",
    },
    link: (scope, el) => {
      const goTo = (name, params = {}, ...arg) => {
        $timeout(() => {
          $state.go(name, params, ...arg);
        });
      };
      const locationReplace = url => {
        $location.search(url);
        $timeout(() => {
          $location.replace();
        });
      };
      const locationSearch = arg => {
        $timeout(() => {
          $location.search(arg);
        });
      };
      const gettext = i18n.gettext;

      window.syncHasPerm = AuthService.syncHasPerm;

      const VUE_APP = createApp({
        data() {
          return {
            propsAngular: ref(scope.props),
          };
        },
      });
      forEach(Components, (component, key) => {
        VUE_APP.component(key, component);
      });

      VUE_APP.config.unwrapInjectedRef = true;
      VUE_APP.config.globalProperties.$goTo = goTo;
      VUE_APP.config.globalProperties.$stateHref = $state.href;
      VUE_APP.config.globalProperties.$stateName = $state.current.name;
      VUE_APP.config.globalProperties.$stateIncludes = $state.includes;
      VUE_APP.config.globalProperties.$stateIs = $state.is;
      VUE_APP.config.globalProperties.$locationReplace = locationReplace;
      VUE_APP.config.globalProperties.$locationSearch = locationSearch;
      VUE_APP.config.globalProperties.$stateReload = $state.reload;
      VUE_APP.config.globalProperties.$stateGet = $state.get;
      VUE_APP.config.globalProperties.$gettext = gettext;
      VUE_APP.config.globalProperties.$AuthService = AuthService;
      VUE_APP.config.globalProperties.$stateParams = $stateParams;
      VUE_APP.config.globalProperties.$NotificationPage = DiasNotification.page;
      VUE_APP.config.globalProperties.$Client = Client;
      VUE_APP.config.globalProperties.$Raven = Raven;
      VUE_APP.config.globalProperties.$statusNotAngular = false;
      VUE_APP.config.globalProperties.$statusAdmin = false;
      VUE_APP.directive("SafeHtml", ASafeHtml);
      VUE_APP.use(AIconPlugin, icons);

      const VUE_APP_MOUNT = VUE_APP.use(VueCookies).use(store).mount(el[0]);

      scope.$watch("props", () => {
        VUE_APP_MOUNT.propsAngular = scope.props;
      });

      scope.$on("$destroy", () => { // Damit Events: "beforeDestroy" und "destroyed" in vue.js funktionieren
        VUE_APP.unmount();
      });
    }
  };
};
