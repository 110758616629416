import ModuleMixin from "../ModuleMixin";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import TextbausteinEdit from "../Textbaustein/TextbausteinEdit/TextbausteinEdit.vue";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "ObjektanmerkungErfassen",
  components: {
    PuxTranslate,
    SnapshotModule,
    SnapshotIcon,
    TextbausteinEdit,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      modelEdit: {
        text: "",
      },
    };
  },
  computed: {
    errorsLabelsOptions() {
      return [
        this.textOptions,
        this.bausteinOptions,
      ];
    },

    textOptions() {
      return {
        id: "antwort",
        type: "richtext",
        htmlId: `${ this.htmlRegelNummer }_antwort`,
        label: this.modelParameter.frage_text,
        required: true,
        view: "v-alt",
        editonly: true,
        max: this.modelParameter.max_length,
        startupFocus: true,
      };
    },

    statusDiff() {
      if (!this.snapshotModule) {
        return false;
      }
      return this.snapshotModule.text !== this.model.text;
    },

    textbausteinId() {
      return `${ this.htmlRegelNummer }_textbaustein-input`;
    },

    labelHeader() {
      return this.modelParameter.frage_text;
    },

    bausteinGruppen() {
      const gruppen = [];
      if (this.modelParameter.textbausteingruppe) {
        gruppen.push(this.modelParameter.textbausteingruppe);
      }
      return gruppen;
    },

    bausteinOptions() {
      return {
        id: "baustein",
        htmlId: `${ this.textbausteinId }_baustein`,
        type: "select",
        label: "_LBL_TEXTBAUSTEIN_EDIT_AUSWAEHLEN_",
        translate: true,
        view: "v",
        keyLabel: "bez",
        keyId: "pk",
        search: true,
        searchList: ["bez"],
        keyGroup: "group",
      };
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.model && this.model.text !== undefined) {
        this.modelEdit = cloneDeep(this.model);
      }
    },
    prepareModel() {
      this.model = cloneDeep(this.module.data) || {};
    },
  },
};
