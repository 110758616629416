import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";

import NutzerStatusUUUIDs from "../../../const/NutzerStatusUUIDs";

// @vue/component
export default {
  name: "NutzerGesperrtIcon",
  components: {
    PuxIcon,
  },
  props: {
    user: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    statusShow() {
      return !this.isUserActive;
    },

    isUserActive() {
      return this.user && (
        (NutzerStatusUUUIDs.isActive(this.user.nutzer_status)) ||
        (this.user.nutzer_status && NutzerStatusUUUIDs.isActive(this.user.nutzer_status.ns_id)) ||
        (this.user.status && NutzerStatusUUUIDs.isActive(this.user.status.ns_id))
      );
    },
  },
};
