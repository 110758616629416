"use strict";

class ParametermoduleRegistryProvider {
  constructor() {
    this.registeredParametermodule = {};
  }

  register(key, directiveName) {
    if (this.registeredParametermodule[key] !== undefined) {
      console.warn("Parametermodul " + key + " wurde mehrmals registriert:", [this.registeredParametermodule[key], directiveName]);
      return;
    }
    this.registeredParametermodule[key] = directiveName;
  }

  unregister(key) {
    this.registeredParametermodule[key] = undefined;
    console.info("Parametermodul " + key + " deregistriert.");
  }

  /*@ngInject*/
  $get() {
    return {
      getParametermodule: key => {
        return this.registeredParametermodule[key];
      },
      getParametermoduleList: () => {
        const list = [];
        for (const key in this.registeredParametermodule) {
          list.push({ key: key, directive: this.registeredParametermodule[key] });
        }
        return list;
      }
    };
  }
}

export default ParametermoduleRegistryProvider;
