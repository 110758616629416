"use strict";

class ProandiScroll {
  /*@ngInject*/
  constructor($document, $timeout, smoothScroll) {
    this.$document = $document;
    this.$timeout = $timeout;
    this.smoothScroll = smoothScroll;
  }

  to(id, delay) {
    this.$timeout(() => {
      const el = this.$document[0].getElementById(id);
      if (!el) {
        console.warn(`Element mit id "${ id }" nicht gefunden.`);
        return;
      }
      this.smoothScroll(el, { offset: this._getOffset() });
    }, 100 || delay);
  }

  _getOffset() {
    const navbarFixedTop = this.$document[0].getElementById("navbar-fixed-top");
    const navbarFixedTopHeight = navbarFixedTop ? navbarFixedTop.offsetHeight : 0;

    // Proandi
    const navbarStaticTop = this.$document[0].querySelector(".navbar-static-top");
    const navbarStaticTopHeight = navbarStaticTop ? navbarStaticTop.offsetHeight : 0;

    const alwaysVisible = this.$document[0].getElementById("always-visible");
    const alwaysVisibleHeight = alwaysVisible ? alwaysVisible.offsetHeight : 0;
    const alwaysVisibleAngular = alwaysVisible ? angular.element(alwaysVisible) : null;

    const messageBox = this.$document[0].getElementById("message-box-fixed");
    const messageBoxHeight = messageBox ? messageBox.offsetHeight : 0;

    let offset = navbarFixedTopHeight + navbarStaticTopHeight + alwaysVisibleHeight + messageBoxHeight;
    if (alwaysVisibleAngular && !alwaysVisibleAngular.hasClass("fixed")) {
      offset += alwaysVisibleHeight;
    }
    return offset;
  }
}

export default ProandiScroll;
