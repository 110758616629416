<div><code class="font-size-md">singleselect</code><ul class="nav nav-tabs" role="tablist"><li class="active" role="presentation"><a href="#demo1" aria-controls="demo1" role="tab" data-toggle="tab">Demo</a></li><li role="presentation"><a href="#optionen1" aria-controls="optionen1" role="tab" data-toggle="tab">Optionen</a></li></ul><div class="tab-content"><div class="tab-pane active" id="demo1" role="tabpanel"><div class="row margin-top-md"><div class="col-lg-6"><ui-select class="d-block" :data="dataList" v-model="model.demo1" :onChange="changeSelect1" :options="{ id: 'ui-select-demo1', keyId: 'pk', keyLabel: 'label', buttonDelete: true }"></ui-select></div><div class="col-lg-6">model.demo1: {{ model.demo1 }}</div></div></div><div class="tab-pane" id="optionen1" role="tabpanel"><div v-highlight=""><pre>PUG<code>ui-select.d-block(
  :data="dataList"
  v-model="model.demo1"
  :on-change="changeSelect1"
  :options={ id: 'ui-select-demo1',
  keyId: "'pk'",
  keyLabel: 'label',
  buttonDelete: 'true'}
)</code></pre><pre>JS<code>import VueUiSelect from ".../global/components/ui/ui-select/ui-select.vue";

export default {
  name: "vue-styleguide-ui-select",
  components: {
    VueUiSelect,
  },
  data () {
    return {
      model: {
        demo1: undefined,
      },
      dataList: [
        {
          pk: "0001",
          label: "Aloha 0001",
        },
        {
          pk: "0002",
          label: "Aloha 0002",
        },
        {
          pk: "0003",
          label: "Aloha 0003",
        },
        {
          pk: "0004",
          label: "Aloha 0004",
        },
        {
          pk: "0005",
          label: "Aloha 0005",
        },
        {
          pk: "0006",
          label: "Aloha 0006",
        },
        {
          pk: "0007",
          label: "Aloha 0007",
        },
        {
          pk: "0008",
          label: "Aloha 0008",
        },
        {
          pk: "0009",
          label: "Aloha 0009",
        },
        {
          pk: "0010",
          label: "Aloha 0010",
        },
      ],
    }
  },
  methods: {
    changeSelect1(model, obj, componentId) {
      console.log(model, obj, componentId);
    },
  },
}

</code></pre></div></div></div><code class="font-size-md">singleselect mit der Suche</code><ul class="nav nav-tabs" role="tablist"><li class="active" role="presentation"><a href="#demo2" aria-controls="demo2" role="tab" data-toggle="tab">Demo</a></li><li role="presentation"><a href="#optionen2" aria-controls="optionen2" role="tab" data-toggle="tab">Optionen</a></li></ul><div class="tab-content"><div class="tab-pane active" id="demo2" role="tabpanel"><div class="row margin-top-md"><div class="col-lg-6"><ui-select :data="dataList" v-model="model.demo2" :onChange="changeSelect1" :options="{&quot;id&quot;:&quot;ui-select-demo2&quot;,&quot;keyId&quot;:&quot;'pk'&quot;,&quot;keyLabel&quot;:&quot;label&quot;,&quot;buttonDelete&quot;:&quot;true&quot;,&quot;search&quot;:&quot;true&quot;,&quot;searchList&quot;:&quot;[\&quot;label\&quot;]&quot;}"></ui-select></div><div class="col-lg-6">model.demo2: {{ model.demo2 }}</div></div></div><div class="tab-pane" id="optionen2" role="tabpanel"><div v-highlight=""><pre>PUG<code>ui-select(
  :data="dataList"
  v-model="model.demo2"
  :onChange="changeSelect1"
  :options={ id: 'ui-select-demo2',
    keyId: "'pk'",
    keyLabel: 'label',
    buttonDelete: 'true',
    search: 'true',
    searchList: '["label"]',
  }
)</code></pre><pre>JS<code>import VueUiSelect from ".../global/components/ui/ui-select/ui-select.vue";

export default {
  name: "vue-styleguide-ui-select",
  components: {
   VueUiSelect,
  },
  data () {
    return {
      model: {
        demo2: "0003",
      },
      dataList: [
        {
          pk: "0001",
          label: "Aloha 0001",
        },
        {
          pk: "0002",
          label: "Aloha 0002",
        },
        {
          pk: "0003",
          label: "Aloha 0003",
        },
        {
          pk: "0004",
          label: "Aloha 0004",
        },
        {
          pk: "0005",
          label: "Aloha 0005",
        },
        {
          pk: "0006",
          label: "Aloha 0006",
        },
        {
          pk: "0007",
          label: "Aloha 0007",
        },
        {
          pk: "0008",
          label: "Aloha 0008",
        },
        {
          pk: "0009",
          label: "Aloha 0009",
        },
        {
          pk: "0010",
          label: "Aloha 0010",
        },
      ],
    }
  },
  methods: {
    changeSelect1(model, obj, componentId) {
      console.log(model, obj, componentId);
    },
  },
}

</code></pre></div></div></div><code class="font-size-md">multiselect</code><ul class="nav nav-tabs" role="tablist"><li class="active" role="presentation"><a href="#demo3" aria-controls="demo3" role="tab" data-toggle="tab">Demo</a></li><li role="presentation"><a href="#optionen3" aria-controls="optionen3" role="tab" data-toggle="tab">Optionen</a></li></ul><div class="tab-content"><div class="tab-pane active" id="demo3" role="tabpanel"><div class="row margin-top-md"><div class="col-lg-6"><ui-select class="d-block" :data="dataList" :model="model.demo3" :multiselect="true" :on-change="changeSelect1" :select-all="true" :deselect-all="true" :count-multi="2" :close-click="false" :options="{&quot;id&quot;:&quot;ui-select-demo3&quot;,&quot;keyId&quot;:&quot;'pk'&quot;,&quot;keyLabel&quot;:&quot;label&quot;,&quot;buttonDelete&quot;:&quot;true&quot;,&quot;search&quot;:&quot;true&quot;,&quot;searchList&quot;:&quot;[\&quot;label\&quot;]&quot;,&quot;typ&quot;:&quot;multiselect&quot;}"></ui-select></div><div class="col-lg-6">model.demo3: {{ model.demo3 }}</div></div></div><div class="tab-pane" id="optionen3" role="tabpanel"><div v-highlight=""><pre>PUG<code>ui-select.d-block(
  :data="dataList"
  :key-id="'pk'"
  :key-label="'label'"
  :component-id="'ui-select-demo3'"
  :model="model.demo3"
  :multiselect="true"
  :on-change="changeSelect1"
  :select-all="true"
  :deselect-all="true"
  :count-multi="2"
  :close-click="false"
)</code></pre><pre>JS<code>import VueUiSelect from ".../global/components/ui/ui-select/ui-select.vue";

export default {
  name: "vue-styleguide-ui-select",
  components: {
   VueUiSelect,
  },
  data () {
    return {
      model: {
        demo3: ["0002", "0005"],
      },
      dataList: [
        {
          pk: "0001",
          label: "Aloha 0001",
        },
        {
          pk: "0002",
          label: "Aloha 0002",
        },
        {
          pk: "0003",
          label: "Aloha 0003",
        },
        {
          pk: "0004",
          label: "Aloha 0004",
        },
        {
          pk: "0005",
          label: "Aloha 0005",
        },
        {
          pk: "0006",
          label: "Aloha 0006",
        },
        {
          pk: "0007",
          label: "Aloha 0007",
        },
        {
          pk: "0008",
          label: "Aloha 0008",
        },
        {
          pk: "0009",
          label: "Aloha 0009",
        },
        {
          pk: "0010",
          label: "Aloha 0010",
        },
      ],
    }
  },
  methods: {
    changeSelect1(model, obj, componentId) {
      console.log(model, obj, componentId);
    },
  },
}

</code></pre></div></div></div><code class="font-size-md">singleselect mit der Gruppierung</code><ul class="nav nav-tabs" role="tablist"><li class="active" role="presentation"><a href="#demo4" aria-controls="demo4" role="tab" data-toggle="tab">Demo</a></li><li role="presentation"><a href="#optionen4" aria-controls="optionen4" role="tab" data-toggle="tab">Optionen</a></li></ul><div class="tab-content"><div class="tab-pane active" id="demo4" role="tabpanel"><div class="row margin-top-md"><div class="col-lg-6"><ui-select class="d-block" :data="dataList" :key-id="'pk'" :key-label="'label'" :key-group="'group'" :component-id="'ui-select-demo4'" v-model="model.demo4" :on-change="changeSelect1" :button-delete="true"></ui-select></div><div class="col-lg-6">model.demo4: {{ model.demo4 }}</div></div></div><div class="tab-pane" id="optionen4" role="tabpanel"><div v-highlight=""><pre>PUG<code>ui-select.d-block(
  :data="dataList"
  :key-id="'pk'"
  :key-label="'label'"
  :key-group="'group'"
  :component-id="'ui-select-demo4'"
  v-model="model.demo4"
  :on-change="changeSelect1"
  :button-delete="true"
)</code></pre><pre>JS<code>import VueUiSelect from ".../global/components/ui/ui-select/ui-select.vue";

export default {
  name: "vue-styleguide-ui-select",
  components: {
    VueUiSelect,
  },
  data () {
    return {
      model: {
        demo4: undefined,
      },
      dataList: [
        {
          pk: "0001",
          label: "Aloha 0001",
          group: "Gruppe 1"
        },
        {
          pk: "0002",
          label: "Aloha 0002",
        },
        {
          pk: "0003",
          label: "Aloha 0003",
          group: "Gruppe 1"
        },
        {
          pk: "0004",
          label: "Aloha 0004",
          group: "Gruppe 1"
        },
        {
          pk: "0005",
          label: "Aloha 0005",
        },
        {
          pk: "0006",
          label: "Aloha 0006",
          group: "Gruppe 2"
        },
        {
          pk: "0007",
          label: "Aloha 0007",
        },
        {
          pk: "0008",
          label: "Aloha 0008",
          group: "Gruppe 2"
        },
        {
          pk: "0009",
          label: "Aloha 0009",
          group: "Gruppe 2"
        },
        {
          pk: "0010",
          label: "Aloha 0010",
        },
      ],
    }
  },
  methods: {
    changeSelect1(model, obj, componentId) {
      console.log(model, obj, componentId);
    },
  },
}
</code></pre></div></div></div><h4 class="margin-top-lg">Properties</h4><table class="table table-striped table-bordered"><thead><tr><th style="width: 150px;">Name</th><th>Typ</th><th>Default</th><th>Pflicht</th><th>Beschreibung</th></tr></thead><tbody><tr v-for="item in props" :key="item.label"><td>{{ item.label }}</td><td>{{ item.type }}</td><td>{{ item.default }}</td><td>{{ item.required ? "+" : "-" }}</td><td>{{ item.description }}</td></tr></tbody></table><h4 class="margin-top-lg">Options</h4><table class="table table-striped table-bordered"><thead><tr><th style="width: 150px;">Name</th><th>Typ</th><th>Default</th><th>Pflicht</th><th>Beschreibung</th></tr></thead><tbody><tr v-for="item in options" :key="item.label"><td>{{ item.label }}</td><td>{{ item.type }}</td><td>{{ item.default }}</td><td>{{ item.required ? "+" : "-" }}</td><td>{{ item.description }}</td></tr></tbody></table></div>