import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "DufoModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    selectorClose: {
      type: String,
      required: true,
    },
    astPk: {
      type: String,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      model: {

      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "do_orgname",
            htmlId: "modal_do_orgname",
            type: "text",
            label: "_LBL_DUFO_MODAL_CREATE_FORM_",
            required: true,
            view: "v",
            maxlength: 250,
          },
          {
            id: "do_strasse",
            htmlId: "modal_do_strasse",
            type: "text",
            label: "_LBL_DUFO_MODAL_CREATE_STRASSE_",
            required: false,
            view: "v",
            maxlength: 100,
          },
          {
            id: "do_hsnr",
            htmlId: "modal_do_hsnr",
            type: "text",
            label: "_LBL_DUFO_MODAL_CREATE_HAUSNUMMER_",
            required: false,
            view: "v",
            maxlength: 10,
          },
          {
            id: "do_plz",
            htmlId: "modal_do_plz",
            type: "text",
            label: "_LBL_DUFO_MODAL_CREATE_PLZ_",
            required: false,
            view: "v",
            maxlength: 10,
          },
          {
            id: "do_ort",
            htmlId: "modal_do_ort",
            type: "text",
            label: "_LBL_DUFO_MODAL_CREATE_ORT_",
            required: false,
            view: "v",
            maxlength: 100,
          },
          {
            id: "lkz",
            htmlId: "modal_lkz",
            type: "select",
            url: "katalog/?key=klaenderkuerzel",
            apiSaveId: "klaenderkuerzel",
            label: "_LBL_DUFO_MODAL_CREATE_LAND_",
            required: false,
            keyLabel: "lkz_bez",
            keyId: "pk",
            view: "v",
            search: true,
            searchList: ["lkz_bez"],
          },
          {
            id: "do_beschreibung",
            htmlId: "modal_do_beschreibung",
            type: "richtext",
            label: "_LBL_DUFO_MODAL_CREATE_BESCHREIBUNG_",
            required: true,
            max: 4000,
            view: "v-alt",
          },
        ],
      },
      statusLoading: undefined,
      errors: undefined,
      errorsClone: undefined,
    };
  },
  methods: {
    save() {
      this.statusLoading = true;
      const data = cloneDeep(this.model);
      this.postHttp({
        url: `antragstellerorganisationen/${ this.astPk }/durchfuehrendeorgeinheiten/`,
        data,
      }).then(
        response => {
          this.addNotification({ text: "_MSG_DUFO_CREATED_SUCCESS_" });
          this.close({ dufo: response });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.statusLoading = false
      );
    },
  },
};
