import {
  FilterCurrencyMixin,
} from "../../../../global/mixins/FiltersMixin";

import {
  gettext,
} from "../../../../global/functions/utils";
import {
  get,
} from "lodash-es";

// @vue/component
export default {
  mixins: [
    FilterCurrencyMixin,
  ],
  computed: {
    auszahlungNameTranslated() {
      return gettext(this.auszahlung.name);
    },

    textBeantragtOrEingereicht() {
      return this.auszahlung.az_datum_eingereicht ?
        "_LBL_AUSZAHLUNG_DETAILS_AUSZAHLUNG_BETRAG_BEANTRAGT_" :
        "_LBL_AUSZAHLUNG_DETAILS_AUSZAHLUNG_BETRAG_NICHT_EINGEREICHT_";
    },

    betragBeantragtClass() {
      return this.betragBeantragt > 0 ? "text-color-success" : "text-color-remove";
    },

    betragBeantragt() {
      return get(this.auszahlung, "uebersicht.betrag_beantragt", 0);
    },

    betragBeantragtCurrency() {
      return this.filterCurrency(this.betragBeantragt);
    },

    betragAnerkanntClass() {
      return this.betragAnerkannt > 0 ? "text-color-success" : "text-color-remove";
    },

    betragAnerkannt() {
      return get(this.auszahlung, "uebersicht.betrag_anerkannt", 0);
    },

    betragAnerkanntCurrency() {
      return this.filterCurrency(this.betragAnerkannt);
    },

    betragGeaendertClass() {
      return this.betragGeaendert > 0 ? "text-color-success" : "text-color-remove";
    },

    betragGeaendert() {
      return get(this.auszahlung, "uebersicht.betrag_geaendert", 0);
    },

    betragGeaendertCurrency() {
      return this.filterCurrency(this.betragGeaendert);
    },

    betrag() {
      const BETRAG = get(this.auszahlung, "uebersicht.betrag", 0);
      if (this.isModalitaetLast) {
        return BETRAG || 0;
      }
      return BETRAG;
    },
  },
};
