import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import RegelsatzStatusLabel from "../../Regelsatz/RegelsatzStatusLabel/RegelsatzStatusLabel.vue";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";
import HttpAPI from "../../../global/compositionAPI/HttpAPI";

// @vue/component
export default {
  name: "AngebotVersionen",
  components: {
    AngularLink,
    PuxCloak,
    PuxTranslate,
    RegelsatzStatusLabel,
  },
  props: {
    angebot: {
      type: Object,
      required: true,
    },
    regelsaetze: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
  },
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    const {
      getListHttp,
    } = HttpAPI();

    return {
      filterDate,
      getListHttp,
    };
  },
  data() {
    return {
      regelsaetzeClone: undefined,
      loading: true,
    };
  },
  created() {
    this.loadRegelsaetze();
  },
  methods: {
    loadRegelsaetze() {
      if (this.regelsaetze) {
        this.regelsaetzeClone = this.regelsaetze;
        this.loading = false;
        return;
      }
      this.getListHttp({ url: `foerderangebote/${ this.angebot.pk }/regelsaetze/?ordering=-ars_version` }).then(
        response => {
          this.regelsaetzeClone = response;
          this.loading = false;
        }
      );
    },
  },
};
