import {
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "FoerderfinderIcons",
  props: {
    kategoriewerte: {
      type: Object,
      default: () => ({}),
    },
    categoriesAll: {
      type: Object,
      default: () => ({}),
    },
    showList: {
      type: Boolean,
    },
  },
  computed: {
    getFilterKategoriewerte() {
      return filter(this.kategoriewerte, item => item.kategoriewerte && item.kategoriewerte.length);
    },
  },
};
