"use strict";

import BeschreibungController from "./beschreibung.edit.controller";
import BeschreibungTemplate from "./beschreibung.edit.jade";
import StatusgruppenController from "./statusgruppen.edit.controller";
import StatusgruppenTemplate from "./statusgruppen.edit.jade";

import template from "./detail.jade";

class DetailController {
  /*@ngInject*/
  constructor(
    $rootScope,
    $state,
    $stateParams,
    KWorkflowstatusgrupppenUUIDs,
    AuthService,
    WorkflowTyp,
    DiasNotification,
    Katalog,
    i18n
  ) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.KWorkflowstatusgrupppenUUIDs = KWorkflowstatusgrupppenUUIDs;
    this.WorkflowTyp = WorkflowTyp;
    this.AuthService = AuthService;
    this.DiasNotification = DiasNotification;
    this.Katalog = Katalog;
    this.gettext = i18n.gettext;

    this.editDescriptionController = BeschreibungController;
    this.editDescriptionTemplate = BeschreibungTemplate;
    this.editStatusgruppenController = StatusgruppenController;
    this.editStatusgruppenTemplate = StatusgruppenTemplate;

    this.loading = {
      wft: true,
    };
    this.infoTexts = {
    };
    this.wtgs = {};
    Object.keys(this.KWorkflowstatusgrupppenUUIDs).map(
      key => this.KWorkflowstatusgrupppenUUIDs[key]
    ).forEach(
      pk => this.wtgs[pk] = {}
    );
    this.perms = AuthService.syncHasPerms([
      "workflowtyp.update", "workflowtyp.delete",
      "workflowtyp.update.statusgruppen",
      "kaufgabe.ui", "workflow.ui",
    ]);
    this.init();
  }

  init() {
    this.WorkflowTyp.one(this.$stateParams.id).get().then(
      result => {
        this.wft = result;
        Object.keys(this.KWorkflowstatusgrupppenUUIDs).map(
          key => this.KWorkflowstatusgrupppenUUIDs[key]
        ).forEach(
          pk => this.wtgs[pk].wsgs = this.wft.statusgruppen
            .filter(wsg => wsg.workflowstatusgruppentyp === pk)
            .sort((a, b) => a.wsg_pos - b.wsg_pos)
        );
      }
    ).finally(() => this.loading.wft = false);
    this.Katalog.getKatalog("workflowstatusgruppentypen").then(
      response => {
        Object.keys(this.KWorkflowstatusgrupppenUUIDs).map(
          key => this.KWorkflowstatusgrupppenUUIDs[key]
        ).forEach(
          pk => {
            const wtg = response.find(wtg => wtg.pk === pk) || {};
            this.wtgs[pk].bez = wtg.bez;
          }
        );
      }
    );
  }
}

export default {
  template: template(),
  controller: DetailController,
  controllerAs: "vm",
};
