import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import KofiErfassung from "../../../../KF/KofiErfassung/KofiErfassung.vue";
import PuxCloak from "../../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import KFAuszahlungMixin from "../../../../KF/KFAuszahlungPerioden/Mixins/KFAuszahlungMixin";

import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";
import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

import SaveCallbackActions from "../../../../../const/SaveCallbackActions";

import {
  get,
  isFunction,
} from "lodash-es";
import { EventBus } from "../../../../../global/functions/event-bus";

// @vue/component
export default {
  name: "AuszahlungKoFiStep",
  components: {
    FormstepDetail,
    KofiErfassung,
    PuxCloak,
    PuxTranslate,
  },
  mixins: [
    KFAuszahlungMixin,
  ],
  props: {
    vertrag: {
      type: Object,
      required: true,
    },
    auszahlung: {
      type: Object,
      required: true,
    },
    auszahlungsplan: {
      type: Object,
      required: true,
    },
    antrag: {
      type: Object,
      required: true,
    },
    labelKoFi: {
      type: String,
      required: true,
      validator: key => ["kosten", "finanzierung"].indexOf(key) !== -1,
    },
    module: {
      type: Array,
      required: true,
    },
    infoprovider: {
      type: Object,
      required: false,
      default: undefined,
    },
    kofiErfassungReloadStatus: {
      type: Boolean,
      required: false,
    },
    savecallback: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    const {
      getHttp,
      getListHttp,
    } = HttpAPI();

    return {
      filterCurrency,
      getHttp,
      getListHttp,
    };
  },
  data() {
    return {
      loading: true,
      reloading: false,
      gesamtKoFi: {},
      positionen: [],
      KoFi: [],
      KoFiFiltered: [],
      KoFiStatuses: {},
      belegDokumente: [],
      belegDokumenteAnzahl: {},
      showHiddenKoFi: false,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        loading: true,
        reloading: false,
        hideHeader: true,
      };
    },

    htmlId() {
      return `auszahlung_${ this.labelKoFi }_${ this.auszahlung.pk }`;
    },

    apiKoFi() {
      return `${ this.apiBase }${ this.labelKoFi }/`;
    },

    apiGesamtKoFi() {
      return `${ this.apiVertrag }gesamt${ this.labelKoFi }/`;
    },

    apiAntragBelegDokumente() {
      return `${ this.apiAntrag }dokumente/`;
    },

    urlParamsForBelegDokumente() {
      return {
        dokart: this.prefixCurrent.dokart,
      };
    },

    prefixCurrent() {
      return this.prefix[this.labelKoFi];
    },

    infoText() {
      if (this.isKosten) {
        return "_HTML_AUSZAHLUNG_WIZARD_STEP_KOSTEN_INFO_";
      }
      if (this.isFinanzierung) {
        return "_HTML_AUSZAHLUNG_WIZARD_STEP_FINANZIERUNG_INFO_";
      }
      return "";
    },

    isKosten() {
      return this.labelKoFi === "kosten";
    },

    isFinanzierung() {
      return this.labelKoFi === "finanzierung";
    },

    auszahlungStatusText() {
      return this.auszahlung.az_datum_eingereicht ?
        "_TXT_KOFI_ERFASSUNG_WIZARD_BETRAG_BEANTRAGT_" :
        "_TXT_KOFI_ERFASSUNG_WIZARD_BETRAG_NICHT_EINGEREICHT_";
    },

    auszahlungUebersichtBetragBeantragt() {
      return get(this.auszahlung, "uebersicht.betrag_beantragt", 0);
    },

    auszahlungUebersichtBetragBeantragtCurrency() {
      return this.filterCurrency(this.auszahlungUebersichtBetragBeantragt);
    },
  },
  created() {
    this.loadCurrentKF();
    this.loadRelevanteAuszahlungen();
    this.loadBelegDokumente();
    this.loadBelegDokumenteAnzahl();
    EventBus.$on("reloadAuszahlungWizardKoFiStep", this.reloadKoFi);
  },
  beforeUnmount() {
    EventBus.$off("reloadAuszahlungWizardKoFiStep", this.reloadKoFi);
  },
  methods: {
    loadCurrentKF() {
      this.getListHttp({
        url: this.apiKoFi,
      }).then(
        response => {
          const {
            positionen,
            koFi,
            koFiStatuses,
          } = this.filterKoFi({
            response,
            labelKoFi: this.labelKoFi,
            filterCollection: false,
          });
          this.positionen = positionen;
          this.KoFi = koFi;
          this.KoFiFiltered = this.KoFi;
          this.KoFiStatuses = koFiStatuses;
          this.loadGesamtKF();

          this.loading = false;
          this.reloading = false;
        }
      );
    },

    loadGesamtKF() {
      this.getHttp({
        url: this.apiGesamtKoFi,
      }).then(
        response => {
          this.gesamtKoFi = response || {};
        }
      );
    },

    loadBelegDokumente() {
      this.getListHttp({
        url: this.apiAntragBelegDokumente,
        urlParams: this.urlParamsForBelegDokumente,
      }).then(
        response => {
          this.belegDokumente = response;
        }
      );
    },

    loadBelegDokumenteAnzahl() {
      this.getHttp({
        url: `${ this.apiVertrag }belegdokumente/`,
      }).then(
        response => {
          this.belegDokumenteAnzahl = response;
        }
      );
    },

    reloadBelegDokumente() {
      this.loadBelegDokumente();
      this.loadBelegDokumenteAnzahl();
    },

    reloadKoFi({ statusReloadBelegDokumente } = {}) {
      this.reloading = true;
      this.loadCurrentKF();
      if (statusReloadBelegDokumente) {
        this.loadBelegDokumente();
      }
      this.loadBelegDokumenteAnzahl();
      if (isFunction(this.savecallback)) {
        this.savecallback({ statusSaveCallback: SaveCallbackActions.RELOAD_KOFI });
      }
    },

    comingStep() {
      this.reloadBelegDokumente();
    },

    toggleFilteredKoFi() {
      this.showHiddenKoFi = !this.showHiddenKoFi;

      if (this.showHiddenKoFi) {
        this.KoFiFiltered = this.KoFi;
      } else {
        this.KoFiFiltered = this.filterKoFiCollection(this.KoFi);
      }
    }
  },
};
