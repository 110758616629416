import {
  computed,
  toRefs,
} from "vue";

import Modal from "../../../../../global/components/Modal/Modal.vue";
import NotizenIcon from "../../../../Notizen/NotizenIcon/NotizenIcon.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

import PermissionAPI from "../../../../../global/compositionAPI/PermissionAPI";
import SitzungDetailsAPI from "../../compositionAPI/SitzungDetailsAPI";

// @vue/component
export default {
  name: "SitzungDetailsTagesordnungItemActions",
  components: {
    NotizenIcon,
    Modal,
    PuxButton,
  },
  props: {
    sitzung: {
      type: Object,
      required: true,
    },
    tagesordnung: {
      type: Array,
      required: true,
    },
    tagesordnungItem: {
      type: Object,
      required: true,
    },
    resetItem: {
      type: Function,
      required: true,
    },
    deleteItem: {
      type: Function,
      required: true,
    },
    toggleEdit: {
      type: Function,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    unterIndex: {
      type: Number,
      default: undefined,
    },
  },
  setup(props) {
    const { sitzung, tagesordnungItem } = toRefs(props);

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    const {
      sitzungDetailsRubricsMapping,
    } = SitzungDetailsAPI();


    const hasWFPermissionTagesordnungUpdate = computed(() => {
      return checkPermissionsSync({
        perm: "tops.update",
        permArray: sitzung.value.user_permissions,
      });
    });

    const hasWFPermissionTagesordnungDelete = computed(() => {
      return checkPermissionsSync({
        perm: "tops.delete",
        permArray: sitzung.value.user_permissions,
      });
    });

    const hasPermissionSitzungNotizenView = computed(() => {
      return checkPermissionsSync({
        perm: "notizen.view",
        permArray: sitzung.value.user_permissions,
      });
    });

    const notizenButtonId = computed(() => {
      return `notizen_${ sitzungDetailsRubricsMapping.tops.key }_${ tagesordnungItem.value.pk }`;
    });

    return {
      hasWFPermissionTagesordnungDelete,
      hasWFPermissionTagesordnungUpdate,
      hasPermissionSitzungNotizenView,
      notizenButtonId,
      notizenSteps: sitzungDetailsRubricsMapping,
    };
  },
  data() {
    return {
      modalResetConfirmOptions: {},
      modalDeleteConfirmOptions: {},
      showModalConfirmReset: false,
      showModalConfirmDelete: false,
    };
  },
  computed: {
    resetButtonId() {
      return `button_top_reset_${ this.index }_${ this.unterIndex || "" }`;
    },

    selectorCloseResetButtonId() {
      return `#button_top_reset_${ this.index }_${ this.unterIndex || "" }`;
    },

    editButtonId() {
      return `button_top_edit_${ this.index }_${ this.unterIndex || "" }`;
    },

    deleteButtonId() {
      return `button_top_delete_${ this.index }_${ this.unterIndex || "" }`;
    },

    selectorCloseDeleteButtonId() {
      return `#button_top_delete_${ this.index }_${ this.unterIndex || "" }`;
    },
  },
  methods: {
    confirmResetItem() {
      this.modalResetConfirmOptions = {
        okClass: "btn btn-primary",
        title: "_LBL_SITZUNG_CONFIRM_RESET_HEADER_",
        okLabel: "_LBL_SITZUNG_CONFIRM_RESET_OK_",
        msg: "_HTML_SITZUNG_CONFIRM_RESET_INFO_",
        okCallback: this.callResetItem,
        cancelCallback: this.closeResetItem,
        loading: false,
      };
      this.showModalConfirmReset = true;
    },

    callResetItem() {
      this.modalResetConfirmOptions.loading = true;
      this.resetItem();
    },

    closeResetItem() {
      this.showModalConfirmReset = false;
    },

    confirmDeleteItem() {
      this.modalDeleteConfirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_SITZUNG_DETAILS_TOPS_LOESCHEN_MODAL_TITLE_",
        okLabel: "_BTN_DELETE_",
        msg: "_HTML_SITZUNG_DETAILS_TOPS_LOESCHEN_MODAL_BODY_",
        okCallback: this.callDeleteItem,
        cancelCallback: this.closeModalDeleteItem,
        loading: false,
      };
      this.showModalConfirmDelete = true;
    },

    callDeleteItem() {
      this.modalDeleteConfirmOptions.loading = true;
      this.deleteItem();
    },

    closeModalDeleteItem() {
      this.showModalConfirmDelete = false;
    },
  },
};
