"use strict";

import template from "./table.detail.jade";

class StatusgruppenController {
  /*@ngInject*/
  constructor($filter, $state, $scope, i18n) {
    this.gettext = i18n.gettext;
    this.$filter = $filter;
    this.$state = $state;

    this.activeClass = null;
    this.currentRowNummer = undefined;

    $scope.$watch("vm.rowDetails.currentRow", () => {
      if (this.rowDetails && !_.isNil(this.rowDetails.currentRow)) {
        this.currentRowNummer = this.rowDetails.currentRow;
        this.scrollGlobal("#row_" + this.currentRowNummer, 500);
      } else if (this.currentRowNummer) {
        this.scrollGlobal("#row_" + this.currentRowNummer, 500);
      }
    });

    this.setActiveClass();
  }

  loadInclude() {

  }

  closeDetails() {
    if (this.rowDetails) {
      delete this.rowDetails;
    }
  }

  showName() {
    if (this.config.title.filterName) {
      if (this.config.title.filterParameter) {
        return this.$filter(this.config.title.filterName)(this.rowDetails.data[this.config.title.wayName], this.config.title.filterParameter);
      }
      return this.$filter(this.config.title.filterName)(this.rowDetails.data[this.config.title.wayName]);
    }
    return this.rowDetails.data[this.config.title.wayName];
  }

  goTo() {
    this.$state.go(this.config.title.wayLink, { [this.config.title.wayVarName]: this.rowDetails.data[this.config.title.wayVar] });
  }

  showButton(punkt) {
    if (!punkt.condition) {
      return true;
    }
    if (angular.isArray(punkt.condition)) {
      for (let i = 0; i < punkt.condition.length; i++) {
        if (this.rowDetails.data[punkt.condition[i]]) {
          return true;
        }
      }
    }
    return false;
  }

  setActiveClass() {
    if (this.config.menu) {
      for (let i = 0; i < this.config.menu.length; i++) {
        if (this.config.menu[i].active) {
          this.activeClass = this.config.menu[i].id;
          break;
        }
      }
    }
  }

  scrollTo(id) {
    this.activeClass = id;
    $("#right-details .widget").removeClass("active");
    $(`#right-details [data-m-step-id="${ id }"]`).addClass("active");
    const scrolBlock = $("#right-details .form-step-detail");
    scrolBlock.animate({ scrollTop: scrolBlock.find(`[data-m-step-id="${ id }"]`).offset().top - scrolBlock.offset().top + scrolBlock.scrollTop() }, "slow");
  }

  scrollGlobal(selector, timeoutTime, time) {
    timeoutTime = timeoutTime || 0;
    setTimeout(() => {
      const amHeaderHeight = $("#navbar-fixed-top").outerHeight(true);
      const messageBoxHeight = amHeaderHeight ? 0 : $("#message-box-fixed").outerHeight(true);
      const proandiHeaderHeight = $(".navbar-static-top").outerHeight(true);
      time = time || 500;
      $("body, html").stop();
      const destination = $(selector).offset().top;

      $("body,html").animate({ scrollTop: destination - amHeaderHeight - messageBoxHeight - proandiHeaderHeight }, time, "linear");
    }, timeoutTime);
  }
}

export default {
  template: template(),
  controller: StatusgruppenController,
  controllerAs: "vm",
  bindings: {
    rowDetails: "=",
    template: "<",
    config: "<",
    extra: "<?"
  }
};
