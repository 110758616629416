import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import ProgrammeModalAktivieren from "../ProgrammeModalAktivieren/ProgrammeModalAktivieren.vue";
import ProgrammeModalCreate from "../ProgrammeModalCreate/ProgrammeModalCreate.vue";
import ProgrammeModalDeaktivieren from "../ProgrammeModalDeaktivieren/ProgrammeModalDeaktivieren.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import getTableApi from "../../../const/TableApis";
import { EventBus } from "../../../global/functions/event-bus";

const TABLE_ID = "foerderprogramm";

// @vue/component
export default {
  name: "ProgrammeList",
  components: {
    PageTabTitle,
    ProgrammeModalAktivieren,
    ProgrammeModalCreate,
    ProgrammeModalDeaktivieren,
    PuxTranslate,
    SmartTable,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_LBL_PROGRAMME_LIST_ACTION_ROW_DETAILS_",
            route: "root.foerderprogramme.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
          {
            label: "_LBL_PROGRAMME_LIST_DEAKTIVIEREN_",
            isHidden: ({ item }) => !item.fpr_aktiv,
            callback: this.deaktivieren,
          },
          {
            label: "_LBL_PROGRAMME_LIST_AKTIVIEREN_",
            isHidden: ({ item }) => item.fpr_aktiv,
            callback: this.aktivieren,
          },
        ],
        actions: [
          {
            label: "_LBL_PROGRAMME_LIST_FOERDERPROGRAMM_ERSTELLEN_",
            callback: () => this.modal.create = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "foerderprogramm_create",
          },
        ],
        label: "_LBL_PROGRAMME_LIST_PROGRAMME_",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      modal: {
        create: undefined,
        aktivieren: undefined,
        deaktivieren: undefined,
      },
      currentProgramm: undefined,
    };
  },
  methods: {
    closeModalCreate() {
      this.modal.create = false;
    },

    aktivieren({ item }) {
      this.currentProgramm = item;
      this.modal.aktivieren = true;
    },

    closeModalAktivieren({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.modal.aktivieren = false;
    },

    deaktivieren({ item }) {
      this.currentProgramm = item;
      this.modal.deaktivieren = true;
    },

    closeModalDeaktivieren({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.modal.deaktivieren = false;
    },
  },
};
