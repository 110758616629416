import FormstepDetail from "../../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import LohnsteigerungBox from "./LohnsteigerungBox/LohnsteigerungBox.vue";
import PeriodModalUpdate from "./PeriodModalUpdate/PeriodModalUpdate.vue";
import PeriodModalTeilen from "./PeriodModalTeilen/PeriodModalTeilen.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import WizardStep3Mixin from "../WizardStep3Mixin";
import WizardStepsPeriodenMixin from "../../WizardStepsPeriodenMixin";
import translate from "../../../../../../global/directives/translate";
import WizardStepsMessageObergrenzeMixin from "../../WizardStepsMessageObergrenzeMixin";
import moment from "moment";
import {
  cloneDeep,
  isUndefined,
  forEach,
  round,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenPeriodenWizardStep3",
  components: {
    PeriodModalUpdate,
    PeriodModalTeilen,
    FormstepDetail,
    LohnsteigerungBox,
    PuxTranslate,
  },
  directives: {
    translate,
  },
  mixins: [
    WizardStep3Mixin,
    WizardStepsPeriodenMixin,
    WizardStepsMessageObergrenzeMixin,
  ],
  props: {
    statusPeriodChange: {
      type: Boolean,
      required: false,
    },
  },
  emits: [
    "changeStatusShowMessageObergrenze",
  ],
  data() {
    return {
      periodenLocal: [],
    };
  },
  computed: {
    getFirstColumnName() {
      return get(this.model, "perioden[0].info.typ_obj.pet_kbez");
    },

    getAGBruttogehaltColumn() {
      return ({ gehalt }) => {
        const GEHALT_NUMBER = this.getNumber({ item: gehalt });
        if (isUndefined(GEHALT_NUMBER)) {
          return undefined;
        }

        const PKT_PAUSCHALE = this.getPktPauschaleNumber;
        return GEHALT_NUMBER * (1 + PKT_PAUSCHALE / 100);
      };
    },

    getFoerderfaehigeColumn() {
      return ({ period }) => {
        // const AG = this.getAGBruttogehaltColumn({ gehalt: period.gehalt });
        const STELLENUMFANG_PROJEKT = this.model.stellenumfang_projekt || 0;
        const VOLLZEITSTELLE = this.model.vollzeitstelle;
        return period.gehalt * STELLENUMFANG_PROJEKT / VOLLZEITSTELLE;
      };
    },

    getInfoForBruttoGehalt() {
      return {
        PKT_PAUSCHALE: this.model.pkt_pauschale,
        STELLENUMFANG_PROJEKT: this.model.stellenumfang_projekt || 0,
        STELLENUMFANG: this.model.stellenumfang || 0,
        // VOLLZEITSTELLE: this.model.vollzeitstelle,
        OBERGRENZE_LOCAL: this.model.obergrenze ? this.model.obergrenze * (this.model.stellenumfang / this.model.vollzeitstelle) : undefined,
        OBERGRENZE_FOERDERFAEHIGE: this.model.obergrenze ? this.model.obergrenze * (this.model.stellenumfang_projekt / this.model.vollzeitstelle) : undefined,
      };
    },

    getTranslateOptionsANGehalt() {
      return {
        html: "_LBL_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_AN_GEHALT_{{stellenanteil}}_",
        extra: {
          stellenanteil: this.model.stellenanteil,
        },
      };
    },
    getTranslateOptionsAGGehalt() {
      return {
        html: "_LBL_HTML_ANTRAG_DETAILS_PERSONAL_WIZARD_AG_GEHALT_{{pkt_pauschale}}_",
        extra: {
          pkt_pauschale: this.model.pkt_pauschale,
        },
      };
    },
  },
  created() {
    this.splitModelPeriodenWithVonUndBis();
  },
  methods: {
    splitModelPeriodenWithVonUndBis({ model } = {}) {
      const ZEITRAUM_VON = this.model.zeitraum_von;
      const ZEITRAUM_BIS = this.model.zeitraum_bis;

      const MODEL = model || cloneDeep(this.model);
      MODEL.perioden = this.getModePerioden();
      const MODEL_PERIODEN = [];
      forEach(MODEL.perioden, period => {
        const MODEL_FOR_CURRENT_PERIOD = this.splitOnePeriod({
          period,
          von: ZEITRAUM_VON,
          bis: ZEITRAUM_BIS,
        });
        MODEL_PERIODEN.push(...MODEL_FOR_CURRENT_PERIOD);
      });

      MODEL.perioden = MODEL_PERIODEN;
      this.splitPeriodenWithLohnsteigerung({ model: MODEL });
    },

    setGehaltForPerioden({ model } = {}) {
      const MODEL = model || cloneDeep(this.model);
      const ZEITRAUM_VON = MODEL.zeitraum_von;
      const ZEITRAUM_BIS = MODEL.zeitraum_bis;
      const LOHNSTEIGERUNG = MODEL.lohnsteigerung / 100 + 1;
      let startdatum = MODEL.startdatum;
      const {
        OBERGRENZE_LOCAL,
        OBERGRENZE_FOERDERFAEHIGE,
      } = this.getInfoForBruttoGehalt;
      let countLohnsteigrungJahre = 0;
      let statusShowMessageObergrenze = false;

      forEach(MODEL.perioden, (period, index) => {
        if (period.von < ZEITRAUM_VON || period.bis > ZEITRAUM_BIS) {
          MODEL.perioden[index].an_bruttogehalt = 0;
          MODEL.perioden[index].ag_bruttogehalt = 0;
          MODEL.perioden[index].gehalt = 0;
          return;
        }

        // const PKT_PAUSCHALE = MODEL.pkt_pauschale;
        let percent = 100;
        if (period.von && period.bis) {
          const BIS_PLUS_DAY = moment(period.bis).add(1, "days");
          let countDays = Math.abs(BIS_PLUS_DAY.diff(moment(period.von), "days"));
          const COUNT_YEARS = Math.abs(BIS_PLUS_DAY.diff(moment(period.von), "years"));
          if (COUNT_YEARS !== 1) {
            if (countDays === 366) {
              countDays--;
            }
            const DAYS_IN_CURRENT_YEAR = moment(period.von).endOf("year").dayOfYear();
            percent = countDays * 100 / DAYS_IN_CURRENT_YEAR;
          }
        }
        let anBrutto = round(MODEL.an_jahresbrutto * percent / 100, 2);
        let agBrutto = round(MODEL.ag_bruttogehalt_stelle * percent / 100, 2);
        const STELLENUMFANG_PROJEKT = MODEL.stellenumfang_projekt || 0;
        const STELLENUMFANG = MODEL.stellenumfang;
        // const VOLLZEITSTELLE = MODEL.vollzeitstelle;
        let gehalt = round(agBrutto * STELLENUMFANG_PROJEKT / STELLENUMFANG, 2);

        if (LOHNSTEIGERUNG && startdatum) {
          if (period.von === startdatum) {
            countLohnsteigrungJahre++;
            startdatum = moment(startdatum).add(1, "years").format("YYYY-MM-DD");
          }
          if (countLohnsteigrungJahre) {
            anBrutto = round(anBrutto * Math.pow(LOHNSTEIGERUNG, countLohnsteigrungJahre), 2);
            agBrutto = round(agBrutto * Math.pow(LOHNSTEIGERUNG, countLohnsteigrungJahre), 2);
            gehalt = round(gehalt * Math.pow(LOHNSTEIGERUNG, countLohnsteigrungJahre), 2);
          }
        }

        if (OBERGRENZE_LOCAL) {
          const OBERGRENZE_LOCAL_WITH_ZEITRAUM = OBERGRENZE_LOCAL * percent / 100;
          const OBERGRENZE_FOERDERFAEHIGE_WITH_ZEITRAUM = OBERGRENZE_FOERDERFAEHIGE * percent / 100;
          if (agBrutto > OBERGRENZE_LOCAL_WITH_ZEITRAUM) {
            agBrutto = round(OBERGRENZE_LOCAL_WITH_ZEITRAUM, 2);
            statusShowMessageObergrenze = true;
          }
          if (gehalt > OBERGRENZE_FOERDERFAEHIGE_WITH_ZEITRAUM) {
            gehalt = round(OBERGRENZE_FOERDERFAEHIGE_WITH_ZEITRAUM, 2);
            statusShowMessageObergrenze = true;
          }
        }

        MODEL.perioden[index].an_bruttogehalt = anBrutto;
        MODEL.perioden[index].ag_bruttogehalt = agBrutto;
        MODEL.perioden[index].gehalt = gehalt;
      });
      this.$emit("changeStatusShowMessageObergrenze", {
        status: statusShowMessageObergrenze,
        wizardNummer: 3,
      });
      this.initModelInParent({ model: MODEL });
    },

    closeModalUpdate({ model } = {}) {
      if (model) {
        this.model.perioden[this.currentIndex].von = model.von;
        this.model.perioden[this.currentIndex].bis = model.bis;
        this.model.perioden[this.currentIndex].an_bruttogehalt = model.an_bruttogehalt;
        this.model.perioden[this.currentIndex].ag_bruttogehalt = model.ag_bruttogehalt;
        this.model.perioden[this.currentIndex].gehalt = model.gehalt;
        // DAM-8408
        // this.updateNextYears({ model, currentIndex: this.currentIndex });
      }

      this.modalUpdate = false;
    },

    // DAM-8408
    // updateNextYears({ model, currentIndex }) {
    //   if (currentIndex >= this.model.perioden.length - 1) {
    //     return;
    //   }
    //   for (let i = currentIndex + 1; i < this.model.perioden.length; i++) {
    //     this.model.perioden[i].an_bruttogehalt = model.an_bruttogehalt;
    //     this.model.perioden[i].ag_bruttogehalt = model.ag_bruttogehalt;
    //     this.model.perioden[i].gehalt = model.gehalt;
    //     this.model.perioden[i].von = this.getModelVon({
    //       currentVon: this.model.perioden[i].von,
    //       modalVon: model.von,
    //       info: this.model.perioden[i].info,
    //     });
    //     this.model.perioden[i].bis = this.getModelBis({
    //       currentBis: this.model.perioden[i].bis,
    //       modalBis: model.bis,
    //       info: this.model.perioden[i].info,
    //     });
    //   }
    // },
    //
    // getModelVon({ currentVon, modalVon, info }) {
    //   if (!info || !info.bis_aktuell || !info.von_aktuell) {
    //     return currentVon;
    //   }
    //   const MODAL_VON_MM_DD = moment(modalVon).format("MM-DD");
    //   const BIS_AKTUEL_MM_DD = moment(info.bis_aktuell).format("MM-DD");
    //   const VON_AKTUEL_MM_DD = moment(info.von_aktuell).format("MM-DD");
    //   if (MODAL_VON_MM_DD <= VON_AKTUEL_MM_DD) {
    //     return info.von_aktuell;
    //   }
    //   if (MODAL_VON_MM_DD >= BIS_AKTUEL_MM_DD) {
    //     return moment(info.bis_aktuell).add(-1, "days").format("YYYY-MM-DD");
    //   }
    //   const VON_AKTUEL_YYYY = moment(info.von_aktuell).format("YYYY");
    //   return `${ VON_AKTUEL_YYYY }-${ MODAL_VON_MM_DD }`;
    // },
    //
    // getModelBis({ currentBis, modalBis, info }) {
    //   if (!info || !info.bis_aktuell || !info.von_aktuell) {
    //     return currentBis;
    //   }
    //   const MODAL_BIS_MM_DD = moment(modalBis).format("MM-DD");
    //   const BIS_AKTUEL_MM_DD = moment(info.bis_aktuell).format("MM-DD");
    //   const VON_AKTUEL_MM_DD = moment(info.von_aktuell).format("MM-DD");
    //   if (MODAL_BIS_MM_DD <= VON_AKTUEL_MM_DD) {
    //     return moment(info.von_aktuell).add(1, "days").format("YYYY-MM-DD");
    //   }
    //   if (MODAL_BIS_MM_DD >= BIS_AKTUEL_MM_DD) {
    //     return info.bis_aktuell;
    //   }
    //   const BIS_AKTUEL_YYYY = moment(info.bis_aktuell).format("YYYY");
    //   return `${ BIS_AKTUEL_YYYY }-${ MODAL_BIS_MM_DD }`;
    // },

    closeModalTeilen({ model } = {}) {
      if (model) {
        this.model.perioden[this.currentIndex].von = model.von1;
        this.model.perioden[this.currentIndex].bis = model.bis1;
        this.model.perioden[this.currentIndex].an_bruttogehalt = model.an_bruttogehalt1;
        this.model.perioden[this.currentIndex].ag_bruttogehalt = model.ag_bruttogehalt1;
        this.model.perioden[this.currentIndex].gehalt = model.gehalt1;
        this.model.perioden[this.currentIndex].info.teil = true;
        this.model.perioden.splice(this.currentIndex + 1, 0, {
          von: model.von2,
          bis: model.bis2,
          an_bruttogehalt: model.an_bruttogehalt2,
          ag_bruttogehalt: model.ag_bruttogehalt2,
          gehalt: model.gehalt2,
          periode: this.model.perioden[this.currentIndex].periode,
          info: cloneDeep(this.model.perioden[this.currentIndex].info),
        });
      }

      this.modalTeilen = false;
    },

    acceptLohnsteigerung({ model }) {
      const MODEL = cloneDeep(this.model);
      MODEL.lohnsteigerung = model.lohnsteigerung;
      MODEL.startdatum = model.startdatum;

      this.splitModelPeriodenWithVonUndBis({ model: MODEL });
    },

    splitPeriodenWithLohnsteigerung({ model }) {
      if (!model.startdatum || !model.lohnsteigerung) {
        this.setGehaltForPerioden({ model });
        return;
      }

      let startdatum = model.startdatum;
      const MODEL_PERIODEN = [];
      forEach(model.perioden, period => {
        const PERIOD = cloneDeep(period);
        if (PERIOD.von < startdatum &&
          PERIOD.bis >= startdatum) {
          const PERIOD1 = cloneDeep(PERIOD);
          PERIOD.bis = moment(startdatum).add(-1, "days").format("YYYY-MM-DD");
          PERIOD1.von = startdatum;
          MODEL_PERIODEN.push(PERIOD, PERIOD1);
          startdatum = moment(startdatum).add(1, "years").format("YYYY-MM-DD");
        } else if (PERIOD.von === startdatum) {
          MODEL_PERIODEN.push(PERIOD);
          startdatum = moment(startdatum).add(1, "years").format("YYYY-MM-DD");
        } else {
          MODEL_PERIODEN.push(PERIOD);
        }
      });
      model.perioden = MODEL_PERIODEN;
      this.setGehaltForPerioden({ model });
    },
  },
};
