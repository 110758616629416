import UiAuswahlKatalog from "../UiAuswahlKatalog.js";

// @vue/component
export default {
  name: "UiAuswahlKatalogReadOnly",
  mixins: [
    UiAuswahlKatalog,
  ],
  data() {
    return {
      statusReadonly: true,
    };
  },
};
