"use strict";

import BetragsrechnerBase from "../betragsrechner.base";

class PersonalkostenMitPkt extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "personalkosten_mit_pkt";
    this.component_name = "personalkostenmitpkt";
    this.fields = {
      antrag: {
        stellenbezeichnung: "",
        personalfunktion: "",
        vollzeitstelle: "",
        stellendauer: "",
        stellenumfang: "",
        stellenumfang_projekt: "",
        monatsgehalt: "",
        einmalzahlung: "",
        pkt_id: ""
      },
      beleg: {
        name: "",
        einwilligung: "",
        stellendauer: "",
        beschaeftigt_von: "",
        beschaeftigt_bis: "",
        vollzeitstelle: "",
        stellenumfang: "",
        stellenumfang_projekt: "",
        monatsgehalt: "",
        einmalzahlung: "",
        personalfunktion: "",
        personalstelle: "",
        pkt_id: "",
        dokument_geschwaerzt: ""
      }
    };
    this.kontext = kontext;
    this.labels = { antrag: { stellenbezeichnung: "Stellenbezeichnung",
                              personalfunktion: "Funktion im Projekt",
                              stellendauer: "Stellendauer",
                              vollzeitstelle: "Umfang einer Vollzeitstelle in der Organisation (in Stunden pro Woche)",
                              stellenumfang: "Gesamter Umfang dieser Stelle in Ihrer Organisation (in Stunden pro Woche)",
                              stellenumfang_projekt: "davon im Projekt (in Stunden pro Woche)",
                              monatsgehalt: "Monatliches Bruttogehalt",
                              einmalzahlung: "Einmalzahlung",
                              personalstelle: "Art der Personalstelle" },
                    beleg: { name: "Name",
                             einwilligung: "Einwilligungserklärung",
                             personalfunktion: "Funktion im Projekt",
                             stellendauer: "Stellendauer",
                             vollzeitstelle: "Umfang einer Vollzeitstelle in der Organisation (in Stunden pro Woche)",
                             stellenumfang: "Gesamter Umfang dieser Stelle in Ihrer Organisation (in Stunden pro Woche)",
                             stellenumfang_projekt: "davon im Projekt (in Stunden pro Woche)",
                             monatsgehalt: "Monatliches Bruttogehalt",
                             einmalzahlung: "Einmalzahlung",
                             personalstelle: "Art der Personalstelle",
                             beschaeftigt_von: "Beschäftigt seit",
                             dokument_geschwaerzt: "Bestätigung" } };
  }

  get_fields(params) {
    const fields = this.fields[this.kontext];
    if (params) {
      fields.pkt_id = params.pkt_id;
    }
    return fields;
  }

  get_labelmapping() {
    return this.labels[this.kontext];
  }
}
export default PersonalkostenMitPkt;
