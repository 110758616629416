import {
  computed,
  ref,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

export default function ManuelleAufgabenAPI({ baseUrl }) {
  const manuelleAufgaben = ref([]);
  const {
    getListHttp,
  } = HttpAPI();
  const manuelleAufgabenUrl = computed(() => {
    return `${ baseUrl.value }manuelle_aufgaben/`;
  });
  const loadManuelleAufgaben = () => {
    return getListHttp({
      url: manuelleAufgabenUrl.value,
    }).then(
      response => {
        manuelleAufgaben.value = response;
      }
    );
  };
  const {
    checkPermissionsSync,
  } = PermissionAPI();
  const hasPermissionAufgabeCreate = computed(() => {
    return checkPermissionsSync({
      perm: "aufgabe.create",
    });
  });

  return {
    hasPermissionAufgabeCreate,
    loadManuelleAufgaben,
    manuelleAufgaben,
  };
}
