import AsideComponents from "./AsideComponents";
import AsideRightBox from "../../../../../global/components/AsideRightBox/AsideRightBox.vue";
import ContextPanel from "../../../../../global/components/ContextPanel/ContextPanel.vue";
import CsvDownload from "../../../../../global/components/CsvDownload/CsvDownload.vue";
import PuxPageDetails from "../../../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxGet from "../../../../../global/components/PuxGet/PuxGet.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";


// @vue/component
export default {
  name: "BerichtAntragscontrolling",
  components: {
    AsideRightBox,
    ContextPanel,
    CsvDownload,
    PuxPageDetails,
    PuxGet,
    PuxTranslate,
    ...AsideComponents,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingAntraege: undefined,
      result: {},
      defaultModel: {},
      urlParamsFromChild: {},
      panelUebersicht: {
        label: "_TXT_BERICHT_ANTRAG_DETAILS_ANTRAGSUEBERSICHT_PANEL_NAME_",
        icon: "glyphicon-list",
        active: true,
      },
      asideComponents: [
        {
          name: "BerichtAntragscontrollingAsideFilters",
          pk: "BerichtAntragscontrollingAsideFilters",
          options: {
            name: "Filter",
            icon: "glyphicon-filter",
            active: true,
          },
        },
      ],
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData({ model } = {}) {
      this.urlParamsFromChild = model;
      this.loadingAntraege = true;
      this.getHttp({
        url: "berichte/ar_antragscontrolling/",
        urlParams: model,
      }).then(
        response => this.result = response
      ).finally(() => this.loadingAntraege = false);
    },
  },
};
