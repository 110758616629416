"use strict";

import BaseModel from "dias/core/models/baseModel";

class KZielgruppe extends BaseModel {
  constructor() {
    super("zielgruppen");
  }
}

export default KZielgruppe;
