import Alert from "../../../../global/components/Alert/Alert.vue";
import FoerderorgSelect from "../../../../global/components/FoerderorgSelect/FoerderorgSelect.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import OptionsSave from "./OptionsSave";
import AstFoeorgVerbindungUUIDs from "../../../../const/AstFoeorgVerbindungUUIDs";
import {
  cloneDeep,
  findIndex,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragstellerWizardSaveOrganistion",
  components: {
    Alert,
    FoerderorgSelect,
    FormElement,
    FormstepDetail,
    Permission,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
    PermissionMixin,
  ],
  props: {
    organisation: {
      type: Object,
      default: undefined,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    changeModel: {
      type: Function,
      default: undefined,
    },
    changeModelAntragstellerorgtypen: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      optionsSave: OptionsSave,
      model: {
        foerderorganisation: undefined,
        kategorien: [],
      },
      canSkipCreateUser: undefined,
      dataKbuchfuehrungsmethoden: [],
      showFoerderorg: true,
      errorLabels: {
        ast_name: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_NAME_",
        rechtsform: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_RECHTSFORM_",
        ast_namezusatz: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_ZUSATZ_NAME_",
        ast_strasse: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_STRASSE_",
        ast_hsnr: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_HAUSNUMMER_",
        ast_plz: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_PLZ_",
        ast_ort: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_ORT_",
        ast_email: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_EMAIL_",
        buchfuehrungsmethode: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_BUCHFUEHRUNGSMETHODE_",
        foerderorganisation: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_FOERDERORGANISATION_",
        kategorien: "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_KATEGORIEN_",
      },
      antragstellerorgtypen: [],
      modelAntragstellerorgtyp: null,
    };
  },
  computed: {
    isErrors() {
      return !!size(this.errors);
    },

    canEditKateogrien() {
      return this.checkPermissionsSync({ perm: "antragstellerorg.update.kategorie" });
    }
  },
  created() {
    this.setTranslateForOptionsLabels();
    this.setAstEmailFieldRequired();
    this.checkCanSkipCreateUser();
    this.loadDataForKbuchfuehrungsmethoden();
    this.initAstFoeorgVerbindungsModus();
    this.loadAntragstellerorgtyp();
  },
  methods: {
    setTranslateForOptionsLabels() {
      this.optionsSave.create_user.label = "_LBL_ANTRAGSTELLER_WIZARD_STEP_SAVE_ORGANISATION_USER_";
    },

    checkCanSkipCreateUser() {
      this.canSkipCreateUser = this.checkPermissionsSync({ perm: "antragstellerorg.create.skip_user" });
      this.model.create_user = !this.canSkipCreateUser;
    },

    loadDataForKbuchfuehrungsmethoden() {
      this.getListHttp({ url: "kbuchfuehrungsmethoden/" }).then(
        response => this.dataKbuchfuehrungsmethoden = response
      );
    },

    initAstFoeorgVerbindungsModus() {
      const AST_DEFAULT = this.getSyConfigsValue("ast_default");
      if (AST_DEFAULT) {
        this.showFoerderorg = AST_DEFAULT.ast_foeorg_connection !== AstFoeorgVerbindungUUIDs.not_allowed;
      }
    },

    loadAntragstellerorgtyp() {
      if (!this.checkPermissionsSync({ perm: "antragstellerorgtypen.update" })) {
        return;
      }
      this.getListHttp({
        url: "katalog/",
        urlParams: {
          key: "antragstellerorgtyp",
        },
      }).then(
        response => {
          this.antragstellerorgtypen = response || [];
          if (this.antragstellerorgtypen.length > 1) {
            this.changeModelAntragstellerorgtypen({ status: true });
            this.setModelAntragstellerorgtypen();
          }
        }
      );
    },

    setModelAntragstellerorgtypen() {
      const INDEX_DEFAULT = findIndex(this.antragstellerorgtypen, ["at_default", true]);
      if (INDEX_DEFAULT !== -1) {
        this.modelAntragstellerorgtyp = this.antragstellerorgtypen[INDEX_DEFAULT].pk;
        this.onChangeModelAntragstellerorgtypen({ model: this.modelAntragstellerorgtyp });
      }
    },

    onChangeModel() {
      const model = cloneDeep(this.model);
      model.skip_create_user = !model.create_user;
      delete model.create_user;
      this.changeModel({ model: model });
    },

    changeModelForederorganisation({ model }) {
      this.model.foerderorganisation = model;
      this.onChangeModel();
    },

    onChangeModelAntragstellerorgtypen({ model }) {
      this.changeModelAntragstellerorgtypen({
        model: {
          typen: [model],
        },
      });
    },

    setAstEmailFieldRequired() {
      const wizardConfig = this.getSyConfigsValue("wizard_ast") || {};
      if ((typeof wizardConfig.ast_email !== "undefined") && (typeof wizardConfig.ast_email.required !== "undefined")) {
        this.optionsSave.ast_email.required = wizardConfig.ast_email.required;
      }
    },
  },
};
