"use strict";

import angular from "angular";

import ibanFilter from "./iban.pipe";


const filterModule = angular.module("iban.pipes", [])

  .filter("iban", ibanFilter);

export default filterModule;
