import TemplateAdminModalCreate from "../TemplateAdminModalCreate/TemplateAdminModalCreate.vue";

import Modal from "../../../global/components/Modal/Modal.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import { EventBus } from "../../../global/functions/event-bus";
import getTableApi from "../../../const/TableApis";

const TABLE_ID = "templateadmin";

// @vue/component
export default {
  name: "TemplateAdminList",
  components: {
    Modal,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
    TemplateAdminModalCreate,

  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],

  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_TXT_TEMPLATE_ADMIN_TABLE_ROW_ACTIONS_DETAILS_",
            route: "root.templateadmin.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
          {
            label: "_TXT_TEMPLATE_ADMIN_TABLE_ROW_ACTIONS_DELETE_",
            callback: this.showConfirmDelete,
            isHidden: () => !this.checkPermissionsSync({ perm: "templateadmin.delete" })
          },
        ],
        actions: [
          {
            permission: "templateadmin.create",
            label: "_TXT_TEMPLATE_ADMIN_TABLE_ACTIONS_CREATE_",
            callback: () => this.modal.create = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "templateadmin_create",
          },
        ],
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      modal: {
        create: undefined,
        delete: undefined,
      },
      confirmDeleteOptions: undefined,
    };
  },
  methods: {
    closeModalCreate() {
      this.modal.create = false;
    },

    closeModalDelete() {
      this.modal.delete = false;
    },

    showConfirmDelete({ item }) {
      this.confirmDeleteOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_TEMPLATE_ADMIN_CONFIRM_DELETE_HEADER_{{bez}}_",
        msg: "_TXT_TEMPLATE_ADMIN_CONFIRM_DELETE_BODY_{{bez}}_",
        extra: {
          bez: item.tpl_kbez
        },
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: () => this.deleteTemplate(item),
        cancelCallback: this.closeModalDelete,
        loading: false,
      };
      this.modal.delete = true;
    },

    deleteTemplate(tpl) {
      this.confirmDeleteOptions.loading = true;
      this.deleteHttp({
        url: `templates/${ tpl.pk }/`,
      }).then(
        () => {
          this.closeModalDelete();
          EventBus.$emit(`updateTable${ TABLE_ID }`);
        }
      ).then(
        () => {
          this.confirmDeleteOptions.loading = false;
        }
      );
    },
  },
};
