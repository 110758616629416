"use strict";

import BaseModel from "./baseModel";

class KBundesland extends BaseModel {
  constructor() {
    super("bundeslaender", { pk: "bun_id" });
  }
}

export default KBundesland;
