import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import HttpMixin from "../../../../../global/mixins/HttpMixin";
import translate from "../../../../../global/directives/translate";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import {
  cloneDeep,
  findIndex,
} from "lodash-es";

// @vue/component
export default {
  name: "TextbausteinEdit",
  components: {
    FormElement,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
    gruppen: {
      type: Array,
      default: undefined,
    },
    disabled: {
      type: Boolean,
    },
    htmlId: {
      type: String,
      required: true,
    },
    textOptions: {
      type: Object,
      required: true,
    },
    bausteinOptions: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      bausteinChoices: [],
      selectedBaustein: null,
      statusLoadingTextbausteine: false,
    };
  },
  computed: {
    bausteinOptionsLocal() {
      const BAUSTEIN_OPTIONS = cloneDeep(this.bausteinOptions);
      BAUSTEIN_OPTIONS.loading = this.statusLoadingTextbausteine;

      return BAUSTEIN_OPTIONS;
    },
  },
  watch: {
    gruppen: {
      handler() {
        this.loadTextbausteine();
      },
      deep: true
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadTextbausteine();
    },

    loadTextbausteine() {
      if (!this.gruppen || !this.gruppen.length) {
        this.bausteinChoices = [];
        return;
      }
      this.statusLoadingTextbausteine = true;
      this.getListHttp({
        url: "katalog/?key=textbausteine",
        urlParams: {
          gruppe: this.gruppen,
        },
      }).then(
        response => {
          this.bausteinChoices = response;
          this.statusLoadingTextbausteine = false;
        }
      );
    },

    onInsertBaustein() {
      const BAUSTEINIDX = findIndex(this.bausteinChoices, v => v.pk === this.selectedBaustein);
      const BAUSTEIN = this.bausteinChoices[BAUSTEINIDX];
      this.insertHtml(BAUSTEIN.txb_text);
      this.selectedBaustein = null;
    },

    insertHtml(bausteintext) {
      const TEXT_ALT = this.model.text || "";
      // eslint-disable-next-line vue/no-mutating-props
      this.model.text = `${ TEXT_ALT }${ bausteintext }`;
    },
  },
};
