import BetragsrechnerBetragMixin from "../../../Betragsrechner/Mixins/BetragsrechnerBetragMixin";
import ModulesWizardMixin from "../ModulesWizardMixin";
import PersonalkostenMitPktFormElementsMixin from "../../../Betragsrechner/PersonalkostenMitPkt/PersonalkostenMitPktFormElementsMixin";

import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenMitPktWizard",
  mixins: [
    BetragsrechnerBetragMixin,
    ModulesWizardMixin,
    PersonalkostenMitPktFormElementsMixin,
  ],
  data() {
    return {
      stepsCount: 6,
      timer: undefined,
    };
  },
  computed: {
    step0() {
      return {
        label: "_LBL_PERSONAL_KOSTEN_MIT_PKT_STEP0_",
        id: "step0",
        slotContent: "step0",
        disabled: this.tabsDisabled[0],
        index: 0,
        error: this.tabsErrors[0],
      };
    },

    step1() {
      return {
        label: "_LBL_PERSONAL_KOSTEN_MIT_PKT_STEP1_",
        id: "step1",
        slotContent: "step1",
        disabled: this.tabsDisabled[1],
        index: 1,
        error: this.tabsErrors[1],
      };
    },

    step2() {
      return {
        label: "_LBL_PERSONAL_KOSTEN_MIT_PKT_STEP2_",
        id: "step2",
        slotContent: "step2",
        disabled: this.tabsDisabled[2],
        index: 2,
        error: this.tabsErrors[2],
      };
    },

    step3() {
      return {
        label: "_LBL_PERSONAL_KOSTEN_MIT_PKT_STEP3_",
        id: "step3",
        slotContent: "step3",
        disabled: this.tabsDisabled[3],
        index: 3,
        error: this.tabsErrors[3],
      };
    },

    step4() {
      return {
        label: "_LBL_PERSONAL_KOSTEN_MIT_PKT_STEP4_",
        id: "step4",
        slotContent: "step4",
        disabled: this.tabsDisabled[4],
        index: 4,
        error: this.tabsErrors[4],
      };
    },

    step5() {
      return {
        label: "_LBL_PERSONAL_KOSTEN_MIT_PKT_STEP5_",
        id: "step5",
        slotContent: "step5",
        disabled: this.tabsDisabled[5],
        index: 5,
        error: this.tabsErrors[5],
      };
    },

    stepsFields0() {
      const STEPS_FIELDS = cloneDeep(this.stepsFieldsMain0);
      STEPS_FIELDS.push(
        {
          options: "optionsName",
          modelPath: "argument",
        },
        {
          options: "optionsStellendauer",
          modelPath: "argument",
        },
      );
      forEach(this.standardFieldConfigs, (item, index) => {
        STEPS_FIELDS.push({
          options: `standardFieldConfigs.${ index }`,
          modelPath: `standardfelder`,
        });
      });
      return STEPS_FIELDS;
    },

    stepsFields1() {
      const STEPS_FIELDS = [];
      forEach(this.extraFields, (item, index) => {
        STEPS_FIELDS.push({
          options: `extraFields.${ index }`,
          modelPath: `extra`,
        });
      });
      return STEPS_FIELDS;
    },

    stepsFields2() {
      return [
        {
          options: "optionsPersonalstelle",
          modelPath: "argument",
        },
        {
          options: "optionsPersonalfunktion",
          modelPath: "argument",
        },
        {
          options: "optionsBeschaeftigtVon",
          modelPath: "argument",
        },
        {
          options: "optionsBeschaeftigtBis",
          modelPath: "argument",
        },
      ];
    },

    stepsFields3() {
      return [
        {
          options: "optionsVollzeitstelle",
          modelPath: "argument",
        },
        {
          options: "optionsStellenumfang",
          modelPath: "argument",
        },
        {
          options: "optionsStellenumfangProjekt",
          modelPath: "argument",
        },
      ];
    },

    stepsFields4() {
      return [
        {
          options: "optionsMonatsgehalt",
          modelPath: "argument",
        },
        {
          options: "optionsEinmalzahlung",
          modelPath: "argument",
        },
        {
          options: "optionsVerguetungsgruppeBez",
        },
        {
          options: "optionsBetrag",
        },
      ];
    },

    stepsFields5() {
      return [
        {
          options: "optionsDokumentGeschwaerzt",
          modelPath: "argument",
        },
        {
          options: "optionsBelegKommentar",
          visible: "statusShowOptionsBelegKommentar",
        },
        {
          options: "optionsEinwilligung",
          modelPath: "argument",
        },
      ];
    },

    modelForVorschau() {
      return cloneDeep(this.model.argument);
    },
  },
  methods: {
    updateModelWithPktId(pktId) {
      this.model.argument.pkt_id = pktId;
    },

    changeVorschauLocal() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$attrs.onOnChange({ modelForVorschau: this.modelForVorschau });
      }, 500);
    },
  },
};
