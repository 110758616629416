export default {
  foerderantrag: "foerderantraege",
  sitzung: "sitzungen",
  antragstellerorganisation: "antragstellerorganisationen",
  auszahlung: "auszahlungen",
  auszahlungsplan: "auszahlungsplaene",
  vertrag: "vertraege",
  sammelvertrag: "sammelvertraege",
  projektbericht: "projektberichte",
  gutachten: "gutachten",
};
