"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";
import template from "./config.jade";
import templateRo from "./readonly.jade";
import controller from "./controller";
import controllerRo from "./readonly.controller";


const projektberichte = angular.module("dias.projektberichte.parameter.projektberichte", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("projektberichte", "parametermodul-projektberichte");
  })
  .component("parametermodulProjektberichte", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulProjektberichteRo", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: templateRo(),
    controller: controllerRo,
    controllerAs: "vm",
  });
export default projektberichte;
