import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import KostenMixin from "../Mixins/KostenMixin";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import Alert from "../../../../global/components/Alert/Alert.vue";
import translate from "../../../../global/directives/translate";
import {
  EventBus,
} from "../../../../global/functions/event-bus";
import {
  cloneDeep,
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenPerioden",
  components: {
    KFStandard,
    PuxButton,
    Alert,
  },
  directives: {
    translate,
  },
  mixins: [
    KostenMixin,
  ],
  data() {
    return {
      perioden: [],
      personalfunktionen: [],
      personalstellenart: [],
      personalkostentyp: undefined,
      personalkostentabelleObj: undefined,
      katalogPersonalkosten: [],
      loadingLocal: true,
      htmlIdForClose: undefined,
    };
  },
  computed: {
    sumLocal() {
      return this.filterCurrency(this.antragKoFiArt.summe);
    },

    buttonKostenartTabelleId() {
      return `${ this.htmlRegelNummer }_kostenart_tabelle`;
    },

    positionenMaxCount() {
      return this.modelParameter.kostenpos_max_anz || 0;
    },

    statusHideButtonAdd() {
      return !this.perioden.length ||
        !!(this.positionenMaxCount &&
          this.positionenMaxCount <= this.positionen.length);
    },

    statusButtonOpenPersonalKostenWindowDisabled() {
      return !this.perioden.length;
    },

    buttonOpenPersonalKostenWindowAriaDescribedby() {
      return `${ this.htmlRegelNummer }_position_number ${ this.htmlRegelNummer }_position_name`;
    },
  },
  watch: {
    reloading() {
      if (!this.reloading) {
        this.setFocusAfterReloading();
      }
    },
  },
  created() {
    this.setEventListener();
  },
  beforeUnmount() {
    this.destroyEventListener();
  },
  methods: {
    initDataLocal() {
      this.setPerioden();
    },

    initData_KFBaseMixin() {
      this.loadDataLocal();
    },

    loadDataLocal() {
      const ANTRAG_ID = this.regel.afr_aid;
      const PERSONALKOSTENTYP_ID = this.modelParameter.personalkostentyp;
      const PROMISES = [
        this.getHttp({
          url: "katalog/",
          urlParams: {
            key: "personalfunktionen",
          },
          apiSaveId: "personalfunktionen",
        }),
        this.getHttp({
          url: `personalkostentypen/${ PERSONALKOSTENTYP_ID }/get_gueltige_pkt/`,
          urlParams: {
            a_id: ANTRAG_ID,
          },
        })
      ];
      if (this.modelParameter.anzeige_personalstelle) {
        PROMISES.push(
          this.getHttp({
            url: "katalog/",
            urlParams: {
              key: "personalstellenart",
            },
            apiSaveId: "personalstellenart",
          }),
        );
      }
      Promise.all(PROMISES).then(
        responses => {
          this.personalfunktionen = this.filterPersonalfunktionen({ response: responses[0] });
          this.personalkostentyp = responses[1];
          if (this.modelParameter.anzeige_personalstelle) {
            this.personalstellenart = responses[2];
          }
          this.loadPersonalkostentabelle();
        }
      );
    },

    filterPersonalfunktionen({ response }) {
      const PERSONALFUNKTIONEN = this.modelParameter.personalfunktionen;
      if (!PERSONALFUNKTIONEN || !PERSONALFUNKTIONEN.length) {
        return [];
      }
      return filter(response, item => {
        return PERSONALFUNKTIONEN.indexOf(item.pk) !== -1;
      });
    },

    loadPersonalkostentabelle() {
      this.getHttp({
        url: "katalog/",
        urlParams: {
          key: "personalkostentabelle",
          pk: this.personalkostentyp.pk,
        },
      }).then(
        response => {
          if (response.length) {
            this.personalkostentabelleObj = response[0];
          }
          this.loadPersonalkostenWithTabelle();
        }
      );
    },

    loadPersonalkostenWithTabelle() {
      this.getHttp({
        url: "katalog/",
        urlParams: {
          key: "personalkosten",
          tabelle: this.personalkostentabelleObj.pk,
        },
      }).then(
        response => {
          this.katalogPersonalkosten = response;
          this.loadingLocal = false;
        }
      );
    },

    setPerioden() {
      if (!this.model.perioden) {
        return;
      }
      this.perioden = cloneDeep(this.model.perioden);
    },

    openPersonalKostenWindow() {
      EventBus.$emit("tooglePersonalkostenPerioden", {
        positionen: this.positionen,
        perioden: this.perioden,
        personalfunktionen: this.personalfunktionen,
        htmlIdForClose: this.buttonKostenartTabelleId,
      });
    },

    addPosition() {
      const HTML_ID_FOR_CLOSE = {
        reloadId: `${ this.htmlRegelNummer }_${ this.positionen.length }`,
        id: `${ this.htmlRegelNummer }_add`,
      };

      this.openPersonalKostenWizard({ htmlIdForClose: HTML_ID_FOR_CLOSE });
    },

    openEditPosition({ position, positionIndex }) {
      const HTML_ID_FOR_CLOSE = {
        reloadId: `${ this.htmlRegelNummer }_${ positionIndex }`,
        id: `${ this.htmlRegelNummer }_${ positionIndex }_edit`,
      };
      this.openPersonalKostenWizard({ position, htmlIdForClose: HTML_ID_FOR_CLOSE });
    },

    openPersonalKostenWizard({ position, htmlIdForClose } = {}) {
      EventBus.$emit("openPersonalkostenPeriodenWizard", {
        katalogDetails: this.katalogDetails,
        perioden: this.perioden,
        personalfunktionen: this.personalfunktionen,
        personalkostentabelleObj: this.personalkostentabelleObj,
        katalogPersonalkosten: this.katalogPersonalkosten,
        personalstellenart: this.personalstellenart,
        modelParameter: this.modelParameter,
        kostenartId: this.antragKostenart.aka_id,
        position: position,
        afr_id: this.regel.afr_id,
        regel_nummer: this.htmlRegelNummer,
        htmlIdForClose,
      });
    },

    onReceiveSignal(signal_kennung) {
      if (signal_kennung === "durchfuehrungszeitraum.update") {
        this.reloadModule({ statusUpdate: true });
        this.reloadPositionen_KFBaseMixin({ statusSaveCallback: false });
      }
    },

    setEventListener() {
      EventBus.$on("closePersonalkostenPeriodenWizard", this.closePersonalKostenWizard);
    },

    destroyEventListener() {
      EventBus.$off("closePersonalkostenPeriodenWizard", this.closePersonalKostenWizard);
    },

    closePersonalKostenWizard({ response, statusReload, htmlIdForClose } = {}) {
      if (statusReload) {
        this.htmlIdForClose = cloneDeep(htmlIdForClose);
        this.reloadPositionen_KFBaseMixin({ response });
        setTimeout(() => {
          this.setFocusById(this.htmlRegelNummer);
        });
      } else {
        if (htmlIdForClose && htmlIdForClose.id) {
          setTimeout(() => {
            this.setFocusById(htmlIdForClose.id);
          });
        }
      }
    },

    setFocusAfterReloading() {
      if (!this.htmlIdForClose) {
        return;
      }
      setTimeout(() => {
        this.setFocusById(this.htmlIdForClose.reloadId);
        this.htmlIdForClose = undefined;
      });
    },

    setFocusById(id) {
      const ELEMENT = $(`#${ id }`);
      if (ELEMENT) {
        ELEMENT.focus();
      }
    },
  },
};
