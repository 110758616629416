"use strict";

import template from "./aspekte.jade";

import aspektEditTemplate from "./aspekt.edit.jade";
import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";

class AspektEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, i18n) {
    super($q, $scope, DiasNotification);
    this.gettext = i18n.gettext;
    this.$scope = $scope;
    this.errorsLabel = {
      ask_titel: "Titel: ",
      ask_kurzbeschreibung: "Kurzbeschreibung: ",
      ask_beschreibung: "Beschreibung: "
    };
    this.statusShowDetail = {};

    this.remove = this.remove.bind(this);
    this.save = this.save.bind(this);
  }

  save() {
    return this.model.patch({
      ask_titel: this.model.ask_titel,
      ask_kurzbeschreibung: this.model.ask_kurzbeschreibung,
      ask_beschreibung: this.model.ask_beschreibung
    }).then(
      response => {
        this.$scope.model.merge(this.$scope.model, response);
        this.DiasNotification.page.success("Änderungen erfolgreich gespeichert.");
      },
      errors => {
        return this.onErrors(errors, this.errorsLabel);
      }
    );
  }

  remove() {
    return this.model.remove().then(
      () => {
        this.DiasNotification.page.success(`${ this.gettext("Aspekt") } ${ this.model.ask_titel } erfolgreich gelöscht.`);
        const collection = this.$scope.model.getParentList();
        collection.splice(collection.indexOf(this.$scope.model), 1);
      },
      this.onError
    );
  }
}

class AspekteController {
  /*@ngInject*/
  constructor(DiasNotification, i18n) {
    this.gettext = i18n.gettext;
    this.DiasNotification = DiasNotification;

    this.loading = {
      apsekte: true,
      newAspekt: false
    };

    this.errorsLabel = {
      ask_titel: "Titel: ",
      ask_kurzbeschreibung: "Kurzbeschreibung: ",
      ask_beschreibung: "Beschreibung: "
    };
    this.aspekte = [];

    this.aspektEditController = AspektEditController;
    this.aspektEditTemplate = aspektEditTemplate;

    this.aloha = `<strong>Aspekt
                    <smal>
                      <a ng-click="vm.clickMe()">Click me</a>
                    </smal>
                  </strong>`;

    this.loadAspekte();
  }

  clickMe() {
  }


  loadAspekte() {
    this.loading.apsekte = true;

    this.regelsatz.aspekte.getList().then(
      response => {
        this.aspekte = response;
        this.loading.apsekte = false;
      }
    );
  }

  toggleNewAspekt() {
    this.modelNew = {};
    this.newAspektStatus = !this.newAspektStatus;
  }

  createNewAspekt() {
    this.loading.newAspekt = true;
    this.regelsatz.aspekte.post(this.modelNew).then(
      response => {
        this.DiasNotification.page.success(`${ this.gettext("Aspekt") } ${ response.ask_titel } erfolgreich angelegt`);
        this.loading.newAspekt = false;

        this.toggleNewAspekt();
        this.loadAspekte();
      },
      error => this.onErrors(error)
    );
  }

  onErrors(errors) {
    this.loading.newAspekt = false;
    this.DiasNotification.form.clear();
    this.DiasNotification.form.error(errors.data, "Bitte überprüfen Sie Ihre Eingaben", {}, this.errorsLabel);
    this.errors = errors.data;
  }
}

export default {
  template: template(),
  controller: AspekteController,
  controllerAs: "vm",
  bindings: {
    aspekteEditable: "<?",
    regelsatz: "<"
  }
};
