"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import diasTable from "dias/core/table";
import diasNotification from "dias/core/notification";
import diasModal from "dias/core/modal";

import models from "../models";

const foerderangeboteModule = angular.module("root.foerderinstrumente", [
  uiRouter,
  models.name,
  diasModal.name,
  diasTable.name,
  diasNotification.name,
]);

foerderangeboteModule.config($stateProvider => {
  $stateProvider

    // Förderangebot-Liste
    .state("root.foerderinstrumente", {
      url: `/foerderinstrumente/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-instrument-list></vue-instrument-list></div>"
        }
      },
      data: {
        permissions: "foerderinstrument.update"
      }
    })

    // Detail-Seite eines Förderangebots
    .state("root.foerderinstrumente.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><instrument-details></instrument-details></div>"
        }
      },
    })
  ;
})
;

export default foerderangeboteModule;
