import AnalysenStatus from "../../Analysen/AnalysenStatus/AnalysenStatus.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import PanelComponents from "./PanelComponents";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NutzerMixin from "../../../mixins/NutzerMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import NutzerStatusUUIDs from "../../../const/NutzerStatusUUIDs";
import {
  filter,
  forEach,
  size,
  some,
} from "lodash-es";

// @vue/component
export default {
  name: "NutzerDetails",
  components: {
    AnalysenStatus,
    ContextPanel,
    Permission,
    PuxPageDetails,
    PuxTranslate,
    ...PanelComponents,
  },
  mixins: [
    HttpMixin,
    NutzerMixin,
    PermissionMixin,
  ],
  data() {
    return {
      loading: true,
      reloading: false,
      nutzer: undefined,
      registrierungsantworten: [],
      optionDetails: {
        panels: [
          {
            label: "_LBL_NUTZER_DETAILS_BASISDATEN_",
            icon: "glyphicon-user",
            active: false,
            pk: "nutzer",
            visible: true,
            permissions: ["nutzer.basisdaten.view"],
            components: [
              {
                name: "NutzerDetailsName",
                pk: "NutzerDetailsName",
              },
              {
                name: "Kontakt",
                pk: "Kontakt",
              },
            ],
          },
          {
            label: "_LBL_NUTZER_DETAILS_ROLLEN_",
            icon: "glyphicon-file",
            active: false,
            pk: "rollen",
            visible: true,
            components: [
              {
                name: "RollenFoerderorganisationenList",
                pk: "RollenFoerderorganisationenList",
                permissions: ["nutzer.foerderorgrollen.view"],
              },
              {
                name: "RollenAntragstellerList",
                pk: "RollenAntragstellerList",
                permissions: ["nutzer.antragstellerorgrollen.view"],
              },
              {
                name: "RollenSystemorganisationenList",
                pk: "RollenSystemorganisationenList",
                permissions: ["nutzer.systemorgrollen.view"],
              },
            ],
          },
          {
            label: "_LBL_NUTZER_DETAILS_ACCOUNT_",
            icon: "glyphicon-file",
            active: false,
            pk: "anmeldedaten",
            visible: true,
            permissions: ["nutzer.account.view"],
            components: [
              {
                name: "Anmeldedaten",
                pk: "Anmeldedaten",
              },
              {
                name: "BeiRegistrierungBeantworteteFragen",
                pk: "BeiRegistrierungBeantworteteFragen",
              },
              {
                name: "EMailAdresse",
                pk: "EMailAdresse",
              },
            ],
          },
          {
            label: "_LBL_NUTZER_DETAILS_SPERRUNG_",
            icon: "glyphicon-lock",
            active: false,
            pk: "sperrung",
            visible: true,
            permissions: ["nutzer.sperrung.view"],
            components: [
              {
                name: "Sperrung",
                pk: "Sperrung",
              },
              {
                name: "Sperrbemerkung",
                pk: "Sperrbemerkung",
              },
            ],
          },
          {
            label: "_LBL_NUTZER_DETAILS_ABWESENHEIT_UND_VERTRETUNGEN_",
            icon: "glyphicon-calendar",
            active: false,
            pk: "abwesenheit_und_vertretungen",
            visible: true,
            permissions: ["nutzer.abwesenheit.view"],
            components: [
              {
                name: "AbwesenheitenList",
                pk: "AbwesenheitenList",
                tableId: "AbwesenheitenListInNutzer"
              },
              {
                name: "VertretungenList",
                pk: "VertretungenList",
                tableId: "VertretungenListInNutzer"
              },
            ],
          },
        ],
        panelPermissions: {
          anmeldedaten: {
            update: false
          },
          sperrung: {
            update: false
          }
        }
      },
    };
  },
  computed: {
    nutzerUrl() {
      return `nutzer/${ this.$stateParams.id }/`;
    },
    visiblePanels() {
      return filter(this.optionDetails.panels, { visible: true });
    }
  },
  created() {
    this.loadNutzer();
  },
  methods: {
    loadNutzer({ reload } = {}) {
      this.reloading = reload;
      this.getHttp({
        url: this.nutzerUrl,
      }).then(
        response => {
          this.setNutzer({ response });
        }
      ).then(
        () => {
          this.loading = false;
          this.reloading = false;
        }
      );
    },

    canSperrungUpdate() {
      return this.checkPermissionsSync({
        perm: "nutzer.sperrung.update",
        permArray: this.nutzer.user_permissions
      });
    },
    canAccountUpdate() {
      return this.checkPermissionsSync({
        perm: "nutzer.account.update",
        permArray: this.nutzer.user_permissions
      });
    },
    setPermissions() {
      this.optionDetails.panelPermissions.anmeldedaten.update = this.canAccountUpdate();
      this.optionDetails.panelPermissions.sperrung.update = (
        this.nutzer &&
        this.nutzer.status &&
        this.nutzer.status.ns_id === NutzerStatusUUIDs.GESPERRT &&
        this.canSperrungUpdate()
      );
    },

    setNutzer({ response }) {
      this.nutzer = response;
      this.initRegistrierungsfragen();
      this.setPermissions();
      forEach(this.optionDetails.panels, panel => {
        if (size(panel.permissions) > 0) {
          panel.visible = this.hasOnePermissions({ perm: panel.permissions, permArray: this.nutzer.user_permissions });
        } else {
          panel.visible = true;
        }
        if (panel.visible) {
          forEach(panel.components, comp => {
            if (size(comp.permissions) > 0) {
              comp.visible = this.hasOnePermissions({ perm: comp.permissions, permArray: this.nutzer.user_permissions });
            } else {
              comp.visible = true;
            }
          });
          panel.visible = some(panel.components, "visible");
        }
      });
    },

    initRegistrierungsfragen() {
      if (!this.nutzer.n_registrierungsantworten) {
        return;
      }
      forEach(this.nutzer.n_registrierungsantworten, (v, key) => {
        this.registrierungsantworten.push(Object.assign({ pk: key }, this.nutzer.n_registrierungsantworten[key]));
      });
    },

    updateNutzer({ nutzer }) {
      this.nutzer = nutzer;
    },
  },
};
