import ModuleMixin from "../ModuleMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";
import Alert from "../../../../global/components/Alert/Alert.vue";
import SmartTable from "../../../../global/components/table/table.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import getTableApi from "../../../../const/TableApis";
import {
  cloneDeep,
  forEach,
} from "lodash-es";

const TABLE_ID = "Projektberichte";

// @vue/component
export default {
  name: "Projektberichte",
  components: {
    Alert,
    SmartTable,
    Modal,
  },
  mixins: [
    ModuleMixin,
    PermissionMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      confirmShow: undefined,
      confirmOptions: undefined,
    };
  },
  computed: {
    labelHeader() {
      return "_TXT_GR_PROJEKTBERICHTE_HEADER_";
    },

    statusCanView() {
      return this.checkPermissionsSync({
        perm: "projektbericht.view", permArray: this.obj.user_permissions,
      });
    },

    optionsTable() {
      return {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID, params: { objId: this.obj.pk, objName: "vertrag" } }),
        cols: [
          {
            label: "_LBL_GR_PROJEKTBERICHTE_TABLE_BERICHTNR_",
            id: "pb_nr",
            sortable: true,
            sortId: "pb_nr",
            sortingDefault: "asc",
            linkTo: {
              url: "/projektberichte/{{pk}}/",
            },
            priority: 10,
          },
          {
            label: "_LBL_GR_PROJEKTBERICHTE_TABLE_BEZEICHNUNG_",
            id: "pb_kbez",
            sortable: false,
            priority: 20,
          },
          {
            label: "_LBL_GR_PROJEKTBERICHTE_TABLE_STATUS_",
            id: "status",
            sortable: false,
            priority: 30,
          },
          {
            label: "_LBL_GR_PROJEKTBERICHTE_TABLE_BEZUGSZEITRAUM_",
            id: "pb_berichtszeitraum_von",
            sortable: false,
            slot: "pb_berichtszeitraum_von",
            priority: 40,
          },
          {
            label: "_LBL_GR_PROJEKTBERICHTE_TABLE_EINGEREICHT_AM_",
            id: "pb_einreichdatum",
            sortable: false,
            filter: "date",
            filterParameter: "DD.MM.YY",
            priority: 50,
          },
          {
            label: "_LBL_GR_PROJEKTBERICHTE_TABLE_AKZEPTIERT_AM_",
            id: "pb_akzeptiertdatum",
            sortable: false,
            filter: "date",
            filterParameter: "DD.MM.YY",
            priority: 60,
          },
        ],
        rowActions: [
          {
            label: "_TXT_DETAILS_ANZEIGEN_",
            route: "root.projektberichte.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: this.actionsTable,
        sortable: false,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
        hideTitle: true,
      };
    },

    actionsTable() {
      const ACTIONS = [];
      if (this.checkPermissionsSync({
        perm: "projektbericht.create", permArray: this.obj.user_permissions,
      })) {
        forEach(this.modelParameter.projektberichte, (item, index) => {
          ACTIONS.push({
            label: "_BTN_GR_PROJEKTBERICHTE_TABLE_ERSTELLEN_{{label}}_",
            type: "button",
            extra: {
              label: item.label,
              maximum: item.maximum,
              index: (this.modelObj_pb_afr_argument_idx[index] || 0) + 1,
            },
            className: "btn btn-default",
            callback: () => this.confirmCreateProjektbericht({ item, index }),
            title: this.getTitleForActionButton({ item, index }),
            isDisabled: item.maximum && this.modelObj_pb_afr_argument_idx[index] >= item.maximum,
          });
        });
      }
      return ACTIONS;
    },

    modelObj_pb_afr_argument_idx() {
      const MODEL = cloneDeep(this.model) || [];
      const MODEL_OBJ = {};
      forEach(MODEL, item => {
        MODEL_OBJ[item.pb_afr_argument_idx] = item.num;
      });
      return MODEL_OBJ;
    },

    isModuleEditable() {
      return false;
    },
  },
  methods: {
    confirmCreateProjektbericht({ item, index }) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_GR_PROJEKTBERICHTE_CONFIRM_HEADER_",
        msg: "_TXT_GR_PROJEKTBERICHTE_CONFIRM_BODY_{{label}}_",
        okLabel: "_BTN_GR_PROJEKTBERICHTE_CONFIRM_OK_{{label}}_",
        okCallback: () => this.createProjektbericht({ item, index }),
        cancelCallback: this.closeConfirm,
        extra: {
          label: item.label,
        },
        loading: false,
      };
      this.confirmShow = true;
    },

    createProjektbericht({ item, index }) {
      this.confirmOptions.loading = true;
      const DATA = {
        regel: this.regel.pk,
        pb_afr_argument_idx: index,
        pb_kbez: item.label,
        vorlage: item.vorlage,
        vertrag: this.obj.pk
      };
      this.postHttp({
        url: "projektberichte/",
        data: DATA,
      }).then(
        response => {
          this.addNotification({ text: "_MSG_GR_PROJEKTBERICHTE_CONFIRM_SUCCESS_" });
          this.closeConfirm();
          this.$goTo("root.projektberichte.detail", { id: response.pk });
        },
        () => {
          this.addNotification({ text: "_MSG_GR_PROJEKTBERICHTE_CONFIRM_ERROR_", type: "error" });
        }
      ).then(
        () => this.confirmOptions.loading = false
      );
    },

    closeConfirm() {
      this.confirmShow = false;
    },

    getTitleForActionButton({ item, index }) {
      if (item.maximum) {
        if (this.modelObj_pb_afr_argument_idx[index] >= item.maximum) {
          if (item.maximum === 1) {
            return "_BTN_GR_PROJEKTBERICHTE_TABLE_ERSTELLEN_TITLE_BEREITS_EIN_AKTIVER_{{label}}_";
          }
          return "_BTN_GR_PROJEKTBERICHTE_TABLE_ERSTELLEN_TITLE_BEREITS_MAXIMUM_{{label}}_{{maximum}}_";
        }
        if (item.maximum > 1) {
          return "_BTN_GR_PROJEKTBERICHTE_TABLE_ERSTELLEN_TITLE_MAXIMUM_MEHR_ALS_EINS_{{label}}_{{maximum}}_{{index}}_";
        } else if (item.maximum === 1) {
          return "_BTN_GR_PROJEKTBERICHTE_TABLE_ERSTELLEN_TITLE_MAXIMUM_IST_EINS_{{label}}_{{maximum}}_";
        }
      }
      return "_BTN_GR_PROJEKTBERICHTE_TABLE_ERSTELLEN_TITLE_WITHOUT_MAXIMUM_{{label}}_{{index}}_";
    },
  },
};
