import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "ModalBelegAblehnen",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  props: {
    selectorClose: {
      type: [String, Array],
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    ablehnen: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      model: {
        kommentar: undefined,
      },
      optionsModal: {
        showCloseButton: true,
        list: [
          {
            id: "kommentar",
            type: "textarea",
            label: "_TXT_MODAL_BELEG_ABLEHNEN_KOMMENTAR_",
            required: false,
            view: "v-alt",
          },
        ]
      },
    };
  },
  methods: {
    ablehnenLocal() {
      this.loading = true;
      this.ablehnen({
        kommentar: this.model.kommentar
      }).then(
        () => this.close()
      );
    },
  },
};
