import AsideComponents from "./AsideComponents";
import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import ContextPanel from "../../../../global/components/ContextPanel/ContextPanel.vue";
import CsvDownload from "../../../../global/components/CsvDownload/CsvDownload.vue";
import PanelComponents from "./PanelComponents";
import PuxPageDetails from "../../../../global/components/PuxPageDetails/PuxPageDetails.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";

import {
  setUrlWithParams,
} from "../../../../global/functions/help";
import moment from "moment";
import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "BerichtFoerderantrag",
  components: {
    AsideRightBox,
    ContextPanel,
    CsvDownload,
    PuxPageDetails,
    ...PanelComponents,
    ...AsideComponents,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingAntraege: undefined,
      instrumentvarianten: undefined,
      gesamt: {},
      labels: [],
      dataGeld: [],
      dataAnzahl: [],
      filteredAntragListUrl: {},
      defaultModel: {
        a_antragsdatum_after: moment().startOf("year").format("YYYY-MM-DD"),
      },
      urlParamsFromChild: {},
      panelUebersicht: {
        label: "_TXT_BERICHT_ANTRAG_DETAILS_ANTRAGSUEBERSICHT_PANEL_NAME_",
        icon: "glyphicon-list",
        active: true,
      },
      panelGrafik: {
        label: "_TXT_BERICHT_ANTRAG_DETAILS_ANTRAGSUEBERSICHT_GRAFIK_PANEL_NAME_",
        icon: "glyphicon-list",
        active: true,
      },
      asideComponents: [
        {
          name: "BerichtFoerderantragAsideFilters",
          pk: "BerichtFoerderantragAsideFilters",
          options: {
            name: "Filter",
            icon: "glyphicon-filter",
            active: true,
          },
        },
      ],
    };
  },
  methods: {
    updateAntraege({ model, linkParameters } = {}) {
      this.urlParamsFromChild = model;
      this.loadingAntraege = true;
      this.getHttp({
        url: "berichte/antraege/",
        urlParams: model,
      }).then(
        response => {
          this.groupByInstrumentvariante({ response, linkParameters });
          this.rerenderChart();
          this.loadingAntraege = false;
        }
      );
    },

    groupByInstrumentvariante({ response, linkParameters }) {
      const INSTRUMENT_VARIANTEN = {};
      const GESAMT = {
        beantragt: 0,
        bewilligt: 0,
        countAntraege: 0,
      };
      this.labels = [];
      this.dataGeld = [[], []];
      this.dataAnzahl = [[], []];
      const URL = setUrlWithParams({ url: "foerderantrag/", params: linkParameters });
      const FILTERED_ANTRAG_LIST_URL = {
        gesamt: URL,
      };
      forEach(response, angebot => {
        FILTERED_ANTRAG_LIST_URL[angebot.foe_id] = setUrlWithParams({ url: URL, params: { regelsatz: angebot.regelsaetze } });
        if (INSTRUMENT_VARIANTEN[angebot.instrumentvariante_pk] === undefined) {
          INSTRUMENT_VARIANTEN[angebot.instrumentvariante_pk] = {
            kbez: angebot.instrumentvariante_kbez,
            angebote: [angebot],
          };
        } else {
          INSTRUMENT_VARIANTEN[angebot.instrumentvariante_pk].angebote.push(angebot);
        }
        this.labels.push(angebot.foe_kbez);
        this.dataGeld[0].push(angebot.sum_beantragt);
        this.dataGeld[1].push(angebot.sum_bewilligt);
        this.dataAnzahl[0].push(angebot.num_antraege);
        this.dataAnzahl[1].push(angebot.num_antraege_bewilligt);
        GESAMT.beantragt += +angebot.sum_beantragt;
        GESAMT.bewilligt += +angebot.sum_bewilligt;
        GESAMT.countAntraege += +angebot.num_antraege;
      });
      this.filteredAntragListUrl = FILTERED_ANTRAG_LIST_URL;
      this.instrumentvarianten = INSTRUMENT_VARIANTEN;
      this.gesamt = GESAMT;
    },

    rerenderChart() {
      const CHART_REF = this.$refs.chart;
      if (!CHART_REF) {
        return;
      }
      const DATA_COUNT_ANTRAEGE = this.prepareDataChartCountAntraege();
      const DATA_COUNT_BETRAEGE = this.prepareDataChartCountBetraege();
      CHART_REF.rerenderCharts_fromParent({
        dataCountAntraege: DATA_COUNT_ANTRAEGE,
        dataCountBetraege: DATA_COUNT_BETRAEGE,
      });
    },

    prepareDataChartCountAntraege() {
      if (!this.labels.length) {
        return;
      }
      const DATA = {
        colors: ["#a0c648", "#f39600"],
        chart: {
          height: this.getHighchartHeight({ labels: this.labels }),
          type: "bar",
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: this.labels,
          title: {
            text: null
          },
          labels: {
            style: {
              fontSize: "14px",
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: this.$gettext("_TXT_BERICHT_ANTRAG_DETAILS_ANZAHL_"),
            align: "high",
          },
          labels: {
            overflow: "justify",
            style: {
              fontSize: "14px",
            }
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "14px",
              },
            },
          }
        },
        tooltip: {
          style: {
            fontSize: "14px",
          }
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          backgroundColor: "#FFFFFF",
          shadow: true,
          style: {
            fontSize: "14px",
          },
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: this.$gettext("_TXT_BERICHT_ANTRAG_DETAILS_ANZAHL_BEANTRAGT_"),
            data: this.dataAnzahl[0],
          },
          {
            name: this.$gettext("_TXT_BERICHT_ANTRAG_DETAILS_ANZAHL_BEWILLIGT_"),
            data: this.dataAnzahl[1],
          },
        ]
      };
      return DATA;
    },

    prepareDataChartCountBetraege() {
      if (!this.labels.length) {
        return;
      }
      const DATA = {
        colors: ["#a0c648", "#f39600"],
        chart: {
          height: this.getHighchartHeight({ labels: this.labels }),
          type: "bar",
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: this.labels,
          title: {
            text: null
          },
          labels: {
            style: {
              fontSize: "14px",
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: this.$gettext("_TXT_BERICHT_ANTRAG_DETAILS_SUMME_"),
            align: "high",
          },
          labels: {
            overflow: "justify",
            format: "{value:,.0f} €",
            style: {
              fontSize: "14px",
            }
          }
        },
        tooltip: {
          valueSuffix: " €",
          style: {
            fontSize: "14px",
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              format: "{point.y:,.2f} €",
              style: {
                fontSize: "14px",
              }
            }
          },
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          backgroundColor: "#FFFFFF",
          shadow: true,
          style: {
            fontSize: "14px",
          },
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: this.$gettext("_TXT_BERICHT_ANTRAG_DETAILS_SUMME_BEANTRAGT_"),
            data: this.dataGeld[0],
          },
          {
            name: this.$gettext("_TXT_BERICHT_ANTRAG_DETAILS_SUMME_BEWILLIGT_"),
            data: this.dataGeld[1],
          },
        ]
      };
      return DATA;
    },

    getHighchartHeight({ labels }) {
      return (labels.length * 40) + 150;
    },
  },
};
