import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import FormElementReadOnly from "../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxLabel from "../../../../../global/components/FormElement/PuxLabel/PuxLabel.vue";
import PuxPopover from "../../../../../global/components/PuxPopover/PuxPopover.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "RegelvergleichBox",
  components: {
    FormElement,
    FormElementReadOnly,
    PuxButton,
    PuxLabel,
    PuxPopover,
    PuxTranslate,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    elementId: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true,
    },
    model: {
      type: String,
      required: false,
      default: undefined,
    },
    onChange: {
      type: Function,
      required: false,
      default: undefined,
    },
    onSave: {
      type: Function,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      editMode: false,
      editModel: undefined,
      loading: false,
    };
  },
  computed: {
    kommentarOptions() {
      return {
        id: `${ this.id }_kommentar`,
        type: "richtext",
        view: "v-alt",
        required: false,
        change: this.change
      };
    },
    kommentarLabelOptions() {
      return {
        id: `${ this.id }_kommentar`,
        type: "richtext",
        required: false,
        label: this.label,
      };
    }
  },
  watch: {
    id() {
      if (!this.show) {
        this.reset();
      }
    }
  },
  methods: {
    reset() {
      this.editMode = false;
      this.editModel = undefined;
    },
    openEditMode() {
      this.editMode = true;
      this.editModel = this.model;
    },
    closeEditMode() {
      this.editMode = false;
      this.change({ model: this.model });
    },
    change({ model }) {
      this.editModel = model;
      if (isFunction(this.onChange)) {
        this.onChange({ model });
      }
    },
    close() {
      if (isFunction(this.onClose)) {
        this.onClose();
      }
    },
    save() {
      if (isFunction(this.onSave)) {
        this.loading = true;
        this.onSave({ model: this.editModel }).then(
          () => this.closeEditMode()
        ).finally(() => this.loading = false);
      }
    },
  },
};
