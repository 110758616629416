"use strict";

import BaseModel from "dias/core/models/baseModel";
import Sitzungsantraege from "./sitzungsantraege.service";

class Sitzungslisten extends BaseModel {
  constructor() {
    super("sitzungslisten", {
      subPaths: {
        festgeschrieben: new Sitzungsantraege("festgeschrieben"),
        dynamisch: new Sitzungsantraege("dynamisch")
      }
    });
  }

  extendModel(model) {
    model = super.extendModel(model);

    model.restangularizeAufgabe = aufgabe => this.Restangular.restangularizeElement(model, aufgabe, "aufgaben", {});

    if (model.aktuelle_aufgabe) {
      model.aktuelle_aufgabe = model.restangularizeAufgabe(model.aktuelle_aufgabe);
    }

    return model;
  }
}

export default Sitzungslisten;
