import ModuleMixin from "../ModuleMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import translate from "../../../../global/directives/translate";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import {
  cloneDeep,
  forEach,
  get,
  isEmpty,
  isUndefined,
  orderBy,
  size,
} from "lodash-es";

const MODEL_FIELDS_LIST = [
  "terminreihe",
  "einreichfrist",
];

const SNAPSHOT_FIELDS_LIST = [
  "terminreihe.pk",
  "terminreihe.trr_titel",
  "einreichfrist",
];

// @vue/component
export default {
  name: "Terminreihe",
  components: {
    SnapshotModule,
    SnapshotIcon,
  },
  directives: {
    translate,
  },
  mixins: [
    ModuleMixin,
    PermissionMixin,
  ],
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    return {
      filterDate,
    };
  },
  data() {
    return {
      loading: false,
      reihenChoices: [],
      einreichbar: false,
      modelEdit: {},
      labelTerminreihe: "_LBL_GR_TERMINREIHE_TR_",
      labelEinreichfrist: "_LBL_GR_TERMINREIHE_EINREICHFRIST_",
      modelDefault: {},
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      if (this.loading === false) {
        const OPTIONS = cloneDeep(this.optionsFormstepDetail);
        OPTIONS.openCallback = this.open;
        OPTIONS.list = [
          {
            id: "terminreihe",
            htmlId: `${ this.htmlRegelNummer }_terminreihe`,
            type: "select",
            required: true,
            label: this.labelTerminreihe,
            translate: true,
            view: "v",
            editonly: true,
            keyLabel: "trr_titel",
            keyId: "trr_id",
            search: true,
            searchList: ["trr_titel"],
            data: this.reihenChoices,
            startupFocus: true,
          },
        ];
        if (this.modelParameter.activate_field_einreichfrist) {
          OPTIONS.list.push({
            id: "einreichfrist",
            htmlId: `${ this.htmlRegelNummer }_einreichfrist`,
            type: "date",
            label: this.labelEinreichfrist,
            translate: true,
            view: "v",
          });
        }
        return OPTIONS;
      }
      return {};
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(SNAPSHOT_FIELDS_LIST, key => {
          if (get(this.model, key) !== get(this.snapshotModule, key)) {
            DIFF[key] = true;
          }
        });
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },

    statusReadonly() {
      return this.readonly || !this.checkPermissionsSync({ perm: "foerderantrag.terminreihe.update", permArray: this.obj.user_permissions });
    },

    labelHeader() {
      if (this.modelParameter.frage_text) {
        return this.modelParameter.frage_text;
      }
      return "_LBL_GR_TERMINREIHE_DEFAULT_FRAGE_";
    },

  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadTerminreihen();
    },

    loadTerminreihen() {
      this.loading = true;
      const NOT_PAST = this.modelParameter.filter_not_past || [];
      this.getListHttp({
        url: "katalog/?key=terminreihe",
        urlParams: {
          typ: this.modelParameter.terminreihentyp,
          not_past: NOT_PAST,
        },
      }).then(
        result => {
          if (this.modelParameter.sort_desc) {
            this.reihenChoices = orderBy(result, ["trr_titel"], ["desc"]);
          } else {
            this.reihenChoices = result;
          }
          this.reihenChoices.push({ trr_titel: window.gettext("_TXT_KEINE_ANGABE_"), trr_id: null });
          this.setEinreichbar();
          this.loading = false;
        }
      );
    },

    setEinreichbar() {
      this.einreichbar = false;
      if (!this.module.data.terminreihe) {
        this.einreichbar = true;
      } else {
        forEach(this.reihenChoices, reihe => {
          if (this.module.data.terminreihe.pk === reihe.pk && reihe.einreichbar) {
            this.einreichbar = true;
          }
        });
      }
    },

    initDataLocal({ statusFirstLoad }) {
      if (statusFirstLoad) {
        return;
      }
      this.setEinreichbar();
    },

    open() {
      const MODEL = cloneDeep(this.modelDefault);
      if (this.model && size(this.model)) {
        forEach(MODEL_FIELDS_LIST, key => {
          if (key === "terminreihe") {
            MODEL[key] = !isUndefined(this.model[key]) ? this.model[key].pk : null;
          } else {
            MODEL[key] = !isUndefined(this.model[key]) ? this.model[key] : null;
          }
        });
      }
      return { model: MODEL };
    },
  },
};
