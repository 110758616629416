import UiAbschreibungsratenFormEdit from "./../UiAbschreibungsratenFormEdit/UiAbschreibungsratenFormEdit.vue";
import PuxButton from "../../../../../../../global/components/PuxButton/PuxButton.vue";

import FiltersAPI from "../../../../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "UiAbschreibungsratenElement",
  components: {
    PuxButton,
    UiAbschreibungsratenFormEdit
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    editIndex: {
      type: [Number, String],
      required: true
    },
    model: {
      type: Object,
      required: false,
      info: "Daten eines Modelelementes von UiAbschreibungsraten.",
      default: () => ({})
    },
    errors: {
      type: [Object, Array],
      required: false,
      default: () => ({})
    },
    close: {
      type: Function,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
    moveUp: {
      type: Function,
      required: true,
    },
    moveDown: {
      type: Function,
      required: true,
    },
    openEdit: {
      type: Function,
      required: true,
    },
    openDelete: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      filterCurrency,
    };
  },
};
