"use strict";

import BaseModel from "dias/core/models/baseModel";


class Tableau extends BaseModel {
  constructor() {
    super("tableau");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.getTableau = () => params => collection.getList(Object.assign(params, { tab_sichtbar: "True" }));

    return collection;
  }
}

export default Tableau;
