import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spinner from "../../../../../global/components/Spinner/Spinner.vue";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";
import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "FoerderdokumenteDokumentversionenDetails",
  components: {
    FormstepDetail,
    Spinner,
    PuxButton,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    foerderdokument: {
      type: Object,
      required: true,
    },
    dokumentData: {
      type: Object,
      required: true,
    },
    updateDocument: {
      type: Function,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      filterDate,
      filterDefaultForEmpty,
      filterFileSize,
    } = FiltersAPI();

    const {
      postHttp,
      putHttp,
    } = HttpAPI();

    return {
      filterDate,
      filterDefaultForEmpty,
      filterFileSize,
      postHttp,
      putHttp,
    };
  },
  data() {
    return {
      options: {
        hideHeader: false,
        list: [
          {
            id: "dok_titel",
            type: "text",
            view: "v",
            label: "_TXT_FOERDERDOKUMENTE_DETAILS_DOKUMENTVERSIONEN_DETAILS_TITEL_",
            required: true,
            editonly: true,
            translate: true,
          },
          {
            id: "gueltig_ab",
            type: "date",
            view: "v",
            label: "_TXT_FOERDERDOKUMENTE_DETAILS_DOKUMENTVERSIONEN_DETAILS_GUELTIG_AB_",
            required: true,
            editonly: true,
            translate: true,
          },
          {
            id: "gueltig_bis",
            type: "date",
            view: "v",
            label: "_TXT_FOERDERDOKUMENTE_DETAILS_DOKUMENTVERSIONEN_DETAILS_GUELTIG_BIS_",
            required: false,
            editonly: true,
            translate: true,
          },
        ],
        saveCallback: this.save,
        openCallback: this.open,
      },
      previewLoading: false,
    };
  },
  computed: {
    getPreviewUrl() {
      return `/api/foerderdokumente/${ this.foerderdokument.pk }/preview/${ this.dokumentData.pk }/`;
    },
  },
  methods: {
    createPreview() {
      this.previewLoading = true;
      this.postHttp({
        url: `foerderdokumente/${ this.foerderdokument.pk }/dokumente/${ this.dokumentData.pk }/create_preview/`
      }).then(
        response => {
          if (response && response.dokument && response.dokument.dok_preview) {
            const NEW_ROW = cloneDeep(this.dokumentData);
            NEW_ROW.dokument = response.dokument;
            this.updateDocument({ row: NEW_ROW, index: this.index });
          }
          this.previewLoading = false;
        }
      );
    },
    open() {
      const MODEL = {
        dok_titel: this.dokumentData.dokument.dok_titel,
        gueltig_ab: this.dokumentData.gueltig_ab,
        gueltig_bis: this.dokumentData.gueltig_bis,
      };
      return { model: MODEL };
    },
    save({ model }) {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: `foerderdokumente/${ this.foerderdokument.pk }/dokumente/${ this.dokumentData.pk }/`,
          data: model,
        }).then(
          response => {
            const ROW = cloneDeep(this.dokumentData);
            ROW.dokument.dok_titel = response.dok_titel;
            ROW.gueltig_ab = response.gueltig_ab;
            ROW.gueltig_bis = response.gueltig_bis;
            this.updateDocument({ row: ROW, index: this.index });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },
  },
};
