import DatenexportExpirationCountdown from "./DatenexportExpirationCountdown/DatenexportExpirationCountdown.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";
import TaskLog from "../../../global/components/TaskLog/TaskLog.vue";

import {
  FilterDateMixin,
  FilterFileSizeMixin,
} from "../../../global/mixins/FiltersMixin";
import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import getTableApi from "../../../const/TableApis";

import { EventBus } from "../../../global/functions/event-bus";

import {
  assign, isNil
} from "lodash-es";


const TABLE_ID = "datenexport";

// @vue/component
export default {
  name: "DatenexportList",
  components: {
    DatenexportExpirationCountdown,
    PageTabTitle,
    PuxIcon,
    PuxTranslate,
    SmartTable,
    TaskLog,
  },
  mixins: [
    FilterDateMixin,
    FilterFileSizeMixin,
    HttpMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_LBL_DATENEXPORT_TABLE_ROW_ACTION_START_",
            isHidden: ({ item }) => !this.canStartExport || item.is_running,
            className: "btn btn-default",
            callback: this.startExport,
          },
        ],
        sortable: true,
        pagination: true,
        csvHide: true,
        view: {
          filter: "top",
        },
      },
    };
  },
  computed: {
    canStartExport() {
      return this.checkPermissionsSync({ perm: "datenexport.view" });
    },

    translationDownloadTitle() {
      return "_TXT_DATENEXPORT_TABLE_ROW_DOWNLOAD_TITLE_{{kbez}}_{{last_export}}_{{filesize}}_";
    },

    translationDownloadLabel() {
      return "_LBL_DATENEXPORT_TABLE_ROW_DOWNLOAD_{{kbez}}_{{last_export}}_{{filesize}}_";
    },

    datenexportConfig() {
      return this.getSyConfigsValue("datenexport") || {};
    },

    cacheTime() {
      return this.datenexportConfig.cache_time;
    },
  },
  methods: {
    startExport({ item, index }) {
      return this.postHttp({ url: `datenexport/${ item.pk }/export/` }).then(
        response => this.updateRow({ response, row: item, index })
      );
    },

    showTasklog({ row }) {
      return !isNil(row.tasklog);
    },

    showDownload({ row }) {
      return row.has_data;
    },

    getDownloadUrl({ row }) {
      return `/api/datenexport/${ row.pk }/download/`;
    },

    getRowTranslateExtra({ row }) {
      return {
        kbez: row.dex_kbez,
        last_export: this.filterDate(row.dex_last_export, "datetime"),
        filesize: this.filterFileSize(row.data_size, "dynamic"),
      };
    },

    createUpdateRowHandler({ row, index }) {
      return () => {
        this.getHttp({ url: `${ getTableApi({ id: TABLE_ID }) }${ row.pk }/` }).then(
          response => this.updateRow({ response, row, index })
        );
      };
    },

    updateRow({ response, row, index }) {
      const ROW = assign({}, row, response);
      EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: ROW, index }));
    }
  },
};
