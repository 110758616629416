"use strict";

import angular from "angular";

import modul from "./modul.component";

import parameterBoolean from "./boolean";
import parameterDate from "./date";
import parameterDateTime from "./datetime";
import parameterString from "./string";
import parameterInteger from "./integer";
import parameterMathExpression from "./math_expression";
import parameterRegexConfig from "./regex_config";
import parameterRichtext from "./richtext";
import parameterText from "./text";
import parameterSingleChoice from "./select";
import parameterMultiSelect from "./selectMulti";
import parameterDynamischeAdressen from "./dynamischeAdressen";
import parameterDynamischeFeldwertliste from "./dynamischeFeldwertliste";
import parameterDecimal from "./decimal";
import parameterJSON from "./json";
import parameterIBAN from "./iban";
import parameterDayMonth from "./daymonth";

const parameter = angular.module("dias.foerderregeln.parameter", [
  parameterBoolean.name,
  parameterDate.name,
  parameterDateTime.name,
  parameterString.name,
  parameterIBAN.name,
  parameterInteger.name,
  parameterJSON.name,
  parameterMathExpression.name,
  parameterRegexConfig.name,
  parameterRichtext.name,
  parameterText.name,
  parameterSingleChoice.name,
  parameterMultiSelect.name,
  parameterDecimal.name,
  parameterDynamischeAdressen.name,
  parameterDynamischeFeldwertliste.name,
  parameterDayMonth.name
])

  .component("parametermodule", modul)
;

export default parameter;
