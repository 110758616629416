// @vue/component
export default {
  data() {
    return {
      classBoxMapping: {
        edit: "edit-box",
        success: "success-box",
        info: "info",
        info_big: "info help",
        info_edit: "info edit",
        info_success: "info success",
      },
    };
  },
  computed: {
    classBox() {
      if (!this.parameter) {
        return;
      }
      return this.classBoxMapping[this.parameter.css_klasse];
    },
  },
};
