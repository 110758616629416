import NutzerAnonymisiertListPreview from "../NutzerAnonymisiertListPreview/NutzerAnonymisiertListPreview.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import getTableApi from "../../../const/TableApis";
import moment from "moment";
import { gettext } from "../../../global/functions/utils";

const TABLE_ID = "anonymisierte_nutzer";

// @vue/component
export default {
  name: "NutzerAnonymisiertList",
  components: {
    NutzerAnonymisiertListPreview,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [
          {
            label: "_LBL_NUTZER_ANONYMISIERT_LIST_ANONYMISIERT_AM_",
            id: "upddate",
            filter: "date",
            filterParameter: "DD.MM.YYYY HH:mm",
            sortable: true,
            sortId: "upddate",
            sortableDefault: "asc",
          },
          {
            label: "_LBL_NUTZER_ANONYMISIERT_LIST_ANONYMISIERT_DURCH_",
            id: "updnid",
          },
          {
            label: "_LBL_NUTZER_ANONYMISIERT_LIST_STATUS_",
            id: "status",
          },
          {
            label: "_LBL_NUTZER_ANONYMISIERT_LIST_BENUTZERNAME_",
            id: "n_loginname",
          },
          {
            label: "_LBL_NUTZER_ANONYMISIERT_LIST_VORNAME_",
            id: "n_vorname",
          },
          {
            label: "_LBL_NUTZER_ANONYMISIERT_LIST_NACHNAME_",
            id: "n_nachname",
          },
          {
            label: "_LBL_NUTZER_ANONYMISIERT_LIST_E_MAIL_ADRESSE_",
            id: "n_email",
          },
        ],
        rowActions: [],
        actions: [],
        label: "_LBL_NUTZER_ANONYMISIERT_LIST_ANONYMISIERTE_NUTZER_",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      filters: [
        {
          type: "text",
          id: "n_loginname",
          label: "_LBL_NUTZER_ANONYMISIERT_LIST_BENUTZERNAME_",
          main: true,
        },
        {
          type: "daterange",
          id: "upddate",
          label: gettext("_LBL_NUTZER_ANONYMISIERT_LIST_ANONYMISIERT_AM_"),
          placeholderMin: gettext("_LBL_NUTZER_ANONYMISIERT_LIST_BEGINN_BELIEBIG_"),
          placeholderMax: gettext("_LBL_NUTZER_ANONYMISIERT_LIST_ENDE_BELIEBIG_"),
          default: {
            upddate_after: moment().format("YYYY-MM-DD"),
          },
        },
      ],
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },
  },
};
