import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import GutachtenErgebnisAuswahl from "../../GutachtenErgebnisAuswahl/GutachtenErgebnisAuswahl.vue";
import GutachtenErgebnisseBoxItem from "../GutachtenErgebnisseBoxItem/GutachtenErgebnisseBoxItem.vue";
import GutachtenErgebnisseBoxLine from "../GutachtenErgebnisseBoxLine/GutachtenErgebnisseBoxLine.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  cloneDeep,
  filter,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "GutachtenErgebnisseBox",
  components: {
    AngularLink,
    AsideRightBox,
    GutachtenErgebnisAuswahl,
    GutachtenErgebnisseBoxItem,
    GutachtenErgebnisseBoxLine,
    PuxIcon,
    PuxTranslate,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    groupIndex: {
      type: Number,
      required: true,
    },
    hasWorkflowPermissionGutachtenView: {
      type: Boolean,
      required: true,
    },
    antragPk: {
      type: String,
      required: true,
    },
    isGroupByTyp: {
      type: Boolean,
      required: true,
    },
    ergebnisse: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      optionsAsideRightBox: {
        hideHeader: true,
        classBody: "p-0",
      },
      idForActiveErgebnis: undefined,
      ergebnisActive: undefined,
    };
  },
  computed: {
    id() {
      return `gutachten_ergebnisse_box_${ this.groupIndex }`;
    },

    componentForHeader() {
      return this.hasWorkflowPermissionGutachtenView ? "angular-link" : "span";
    },

    attributesForHeader() {
      const ATTRIBUTES = {};
      if (this.hasWorkflowPermissionGutachtenView) {
        ATTRIBUTES.sref = "root.gutachten";
        ATTRIBUTES.parameter = this.listFilter;
      }
      return ATTRIBUTES;
    },

    titleForHeaderTranslate() {
      if (this.group.length !== this.group.linkedLength) {
        return "_TXT_GUTACHTEN_ERGEBNIS_BOX_HEADER_TITLE_EINSEHBAR_{{length}}_{{label}}_{{linkedLength}}_";
      }
      return "_TXT_GUTACHTEN_ERGEBNIS_BOX_HEADER_TITLE_{{length}}_{{label}}_";
    },

    extraForHeaderTranslate() {
      return {
        length: this.group.length,
        linkedLength: this.group.linkedLength,
        label: this.group.label,
      };
    },

    listFilter() {
      const FILTER = {
        ga_objektid: [this.antragPk],
      };
      if (this.isGroupByTyp) {
        FILTER.typ = this.group.key;
      }
      return FILTER;
    },

    ergebnisseFiltered() {
      return filter(this.ergebnisse, ergebnis => get(this.group, `[${ ergebnis.pk }].gutachten.length`));
    },

    groupGutachtenLengthForActiveErgebnis() {
      return get(this.groupGutachtenForActiveErgebnis, "length");
    },

    groupGutachtenForActiveErgebnis() {
      return get(this.group, `[${ this.idForActiveErgebnis }].gutachten`);
    },

    classForActiveErgebnis() {
      return get(this.ergebnisActive, "gae_cssklasse");
    },

    idForDetailsBox() {
      return `${ this.id }_details`;
    },
  },
  methods: {
    selectErgebnis(gutachtenErgebnis) {
      if (this.idForActiveErgebnis === gutachtenErgebnis.pk) {
        this.idForActiveErgebnis = undefined;
        this.ergebnisActive = undefined;
      } else {
        this.idForActiveErgebnis = gutachtenErgebnis.pk;
        this.ergebnisActive = cloneDeep(gutachtenErgebnis);
      }
    },
  },
};
