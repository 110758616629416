"use strict";
import angular from "angular";
import uiRouter from "angular-ui-router";
import ngAnimate from "angular-animate";

import diasRichtext from "dias/core/richtext";
import diasModal from "dias/core/modal";
import diasModel from "dias/core/models";
import diasNotification from "dias/core/notification";
import diasLoading from "dias/core/loading";
import diasFavorit from "dias/favoriten";
import benachrichtigungen from "dias/benachrichtigungen";
import workflow from "dias/workflow";
import foerderangebote from "dias/foerderangebote";

import cortexe from "./cortexe";
import foerdermodule from "./foerdermodule";
import pipes from "./pipes";
import models from "./models";
import services from "./services";
import sichtbarkeit from "./sichtbarkeit";
import geloeschte_antraege from "./geloeschte_antraege";

import detailsComponent from "./details.component";
import createExpertComponent from "./create.expert.component";
import createExternComponent from "./create.extern.component";
import overviewComponent from "./overview.component";

const foerderantragModule = angular.module("foerderantrag", [
  ngAnimate,
  uiRouter,
  "angularMoment",
  pipes.name,
  models.name,
  services.name,
  sichtbarkeit.name,
  geloeschte_antraege.name,
  diasModal.name,
  diasModel.name,
  diasLoading.name,
  diasRichtext.name,
  diasNotification.name,
  diasFavorit.name,
  benachrichtigungen.name,
  workflow.name,
  cortexe.name,
  foerdermodule.name,
  foerderangebote.name,
]);

foerderantragModule.config($stateProvider => {
  $stateProvider

    // Liste
    .state("root.foerderantrag", {
      url: `/foerderantrag/?
            user_cookie&
            limit&
            ordering&
            antragstellerorg&
            dashboard&dufo&
            friststatus&
            handlungsfeld&
            meine_teams&
            instrumentvariante&
            regelsatz&
            status&
            status_detail&
            workflowstatusgruppe&
            zustaendig&
            zustaendig_in_status&
            zu_bearbeiten&
            meine_ast_antraege&
            show_gesperrte_ast&
            ast_bundesland&
            a_bewilligungsdatum_after&
            a_bewilligungsdatum_before&
            a_antragsdatum_after&
            a_antragsdatum_before&
            eingereicht&
            oid_1&
            oid_2&
            ffk_2e0d8c86c4f656318b969f0e2ff5819c&
            laender&
            meine_kompetenz&
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-antraege-list></vue-antraege-list></div>"
        }
      },
      data: {
        permissions: ["foerderantrag.view"]
      }
    })
    .state("root.foerderantrag1", {
      url: `/foerderantrag1/?
            user_cookie&
            limit&
            ordering&
            antragstellerorg&
            dashboard&dufo&
            friststatus&
            handlungsfeld&
            meine_teams&
            instrumentvariante&
            regelsatz&
            status&
            status_detail&
            workflowstatusgruppe&
            zustaendig&
            zustaendig_in_status&
            zu_bearbeiten&
            meine_ast_antraege&
            show_gesperrte_ast&
            ast_bundesland&
            a_bewilligungsdatum_after&
            a_bewilligungsdatum_before&
            a_antragsdatum_after&
            a_antragsdatum_before&
            eingereicht&
            oid_1&
            oid_2&
            ffk_2e0d8c86c4f656318b969f0e2ff5819c&
            laender
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-antraege-list></vue-antraege-list></div>"
        }
      },
      data: {
        permissions: ["foerderantrag.view"]
      }
    })
    // Antrag über Expertenmodus erstellen
    .state("root.foerderantrag-add", {
      url: `/foerderantrag-erstellen/?
            kategoriewert
      `,
      views: {
        "main@root": {
          // template: "<create-expert></create-expert>",
          template: `<div vueinit>
                      <vue-foerderangebote-list></vue-foerderangebote-list>
                     </div>`,
        }
      },
      data: {
        permissions: ["foerderantrag.create"]
      }
    })

    // Antrag über Externen Link erstellen
    .state("root.foerderantrag.add_extern", {
      url: "neu_mit_foerderangebot/?id",
      views: {
        "main@root": {
          template: "<div vueinit><antrag-create-extern></antrag-create-extern></div>"
        }
      },
      data: {
        permissions: ["foerderantrag.create"]
      }
    })
    .state("root.foerderantrag.add_extern1", {
      url: "neu_mit_foerderangebot1/?id",
      views: {
        "main@root": {
          template: "<create-extern></create-extern>"
        }
      },
      data: {
        permissions: ["foerderantrag.create"]
      }
    })


    // Bearbeiten
    .state("root.foerderantrag.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><antrag-details></antrag-details></div>"
        }
      },
    })

    .state("root.foerderantrag1.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<foerderantrag-details></foerderantrag-details>"
        }
      },
    });
})

  .component("createExpert", createExpertComponent)
  .component("createExtern", createExternComponent)
  .component("foerderantragDetails", detailsComponent)
  .component("foerderantragOverview", overviewComponent)

;
export default foerderantragModule;
