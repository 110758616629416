"use strict";

import template from "./create.extern.jade";

class FoerderantragExternCreateController {
  /*@ngInject*/
  constructor(
    $filter,
    $state,
    $stateParams,
    $window,
    Foerderantrag,
    RegelsatzAktiv,
    FoerderantragquelleUUIDs,
    DiasNotification
  ) {
    this.loading = false;
    this.loadingMsg = null;
    this.$filter = $filter;
    this.$state = $state;
    this.$window = $window;
    this.$stateParams = $stateParams;
    this.DiasNotification = DiasNotification;
    this.Foerderantrag = Foerderantrag;
    this.FoerderantragquelleUUIDs = FoerderantragquelleUUIDs;
    this.RegelsatzAktiv = RegelsatzAktiv;

    if (!$stateParams.id) {
      this.error = "Es wurde kein Förderregelsatz ausgewählt.";
      this.DiasNotification.page.error("Es wurde kein Förderregelsatz ausgewählt.");
    } else {
      this.loading = true;
      this.loadingMsg = "Angebotsdaten werden geladen und verifiziert";
      RegelsatzAktiv.getList({ angebot: $stateParams.id, offset: 0, limit: 1 }).then(
        response => {
          if (response.length === 1) {
            this.regelsatz = response[0];
          } else {
            this.DiasNotification.form.error("Das ausgewählte Förderangebot konnte leider nicht gefunden werden.");
          }
        },
        err => {
          this.error = err;
          this.DiasNotification.form.error(err, "Fehler beim laden der Angebotsdaten");
        }
      ).finally(() => this.loading = false);
    }
  }

  showMore() {
    if (this.regelsatz) {
      this.loadingMore = true;
      this.RegelsatzAktiv.one(this.regelsatz.pk).get()
        .then(
          response => {
            this.regelsatz = response;
          }
        ).finally(() => {
          this.loadingMore = false; this.moreLoaded = true;
        });
    }
  }

  createAntrag() {
    if (this.regelsatz.pk) {
      this.loading = true;
      this.creating = true;
      this.loadingMsg = "Antrag wird erstellt";
      this.Foerderantrag.post({
        regelsatz: this.regelsatz.pk,
        quelle: this.FoerderantragquelleUUIDs.foerderfinder
      }).then(
        response => {
          if (typeof this.$window.utag !== "undefined") {
            try {
              const LINK_OBJ = {
                event_id: "DIAS_Antrag_erstellt",
                etracker_event_action: "click",
              };
              if (this.regelsatz) {
                LINK_OBJ.antrag_angebot = this.regelsatz.ars_kbez;
                LINK_OBJ.antrag_angebot_id = this.$stateParams.id;
              }
              this.$window.utag.link(LINK_OBJ);
            } catch (err) {
              console.error(err);
            }
          }
          this.DiasNotification.form.success("Antrag " + this.$filter("spaced")(response.a_nr, true) + " wurde erfolgreich erstellt.");
          this.$state.go("root.foerderantrag.details", { id: response.pk });
        },
        error => this.DiasNotification.page.error(error, "Fehler beim Erstellen des Antrags")
      ).finally(() => this.loading = false);
    }
  }
}

export default {
  template: template(),
  controller: FoerderantragExternCreateController,
  controllerAs: "vm"
};
