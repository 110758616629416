import PersonalPanelComponentMixin from "../PersonalPanelComponentMixin";

import {
  assign,
} from "lodash";

// @vue/component
export default {
  name: "Allgemein",
  mixins: [
    PersonalPanelComponentMixin,
  ],
  computed: {
    options() {
      return assign({}, this.optionsMixin, {
        label: "_LBL_PERSONAL_DETAIL_ALLGEMEIN_PANEL_",
        editable: false,
        list: [
          {
            type: "text",
            view: "v-alt",
            id: `name`,
            label: "_LBL_PERSONAL_NAME_",
            showLabel: true,
            translate: true,
            required: false,
          }, {
            type: "text",
            view: "v-alt",
            id: `o_name`,
            label: "_LBL_PERSONAL_FOERDERORG_NAME_",
            showLabel: true,
            translate: true,
            required: false,
          }, {
            type: "datetime",
            view: "v-alt",
            id: `last_activity`,
            label: "_LBL_PERSONAL_LAST_ACTIVITY_",
            showLabel: true,
            translate: true,
            required: false,
          },
        ]
      });
    },
  },
};
