"use strict";

import BaseFunction from "../baseFunction.js";

class ConfirmFunction extends BaseFunction {
  /*@ngInject*/
  constructor(DiasNotification, $q, diasConfirmDialog, workflowfolge, obj, extra) {
    super(DiasNotification, $q, workflowfolge, obj, extra);
    this.diasConfirmDialog = diasConfirmDialog;
  }

  start() {
    const parameterin = this.prepareParameterIn();
    return this.diasConfirmDialog({
      titleTemplate: parameterin.title,
      contentTemplate: parameterin.msg,
      type: this.workflowfolge.ausgabe_typ,
      okIcon: this.workflowfolge.ausgabe_icon,
      okLabel: this.workflowfolge.wfo_kbez,
      okCallback: () => this.finish({}),
      cancelCallback: () => this.cancel()
    });
  }
}

export default ConfirmFunction;
