// @vue/component
export default {
  data() {
    return {
      fields: [
        {
          id: "antragsdatum",
          type: "date",
          label: "_LBL_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_ANTRAGSDATUM_",
          required: true,
          notAfter: new Date(),
          view: "v-alt",
          translate: true,
        }, {
          id: "angebot",
          type: "select",
          label: "_LBL_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_ANGEBOT_",
          data: this.angebote,
          required: true,
          keyId: "pk",
          keyLabel: "bez",
          search: true,
          searchList: ["bez"],
          view: "v-alt",
          hideIf: !this.angebote.length,
          translate: true,
          deselect: false,
        }, {
          id: "file",
          type: "document",
          label: "_LBL_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_IMPEXPDATEI_",
          required: true,
          allowedMimeType: [
            ".pdf", ".json", ".xml"
          ],
          view: "v-alt",
          translate: true,
        },
      ],
    };
  },
};
