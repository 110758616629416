import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import moment from "moment";

import {
  indexOf
} from "lodash-es";

// @vue/component
export default {
  name: "DatenexportExpirationCountdown",
  components: {
    PuxTranslate,
  },
  props: {
    timestamp: {
      type: [String, Date],
      required: true,
    },
    seconds: {
      type: Number,
      required: true
    },
    onExpired: {
      type: Function,
      required: false,
      default: undefined
    },
    expiredMsg: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      validator: value => indexOf(["m", "s", "dynamic"], value) !== -1,
      default: "dynamic",
      required: false,
    }
  },
  data() {
    return {
      remaining: 0, // ms
      interval: undefined,
    };
  },
  computed: {
    targetTime() {
      return moment(this.timestamp).add(this.seconds, "s").toDate();
    },

    remainingFormatted() {
      const DUR = moment.duration(this.remaining);
      switch (this.mode) {
      case "s": return DUR.seconds();
      case "m": return DUR.minutes();
      default: return DUR.humanize();
      }
    },

    remainingSuffix() {
      const DUR = moment.duration(this.remaining);
      switch (this.mode) {
      case "s": return DUR.seconds() === 1 ? "_TXT_SECOND_" : "_TXT_SECONDS_";
      case "m": return DUR.minutes() === 1 ? "_TXT_MINUTE_" : "_TXT_MINUTES_";
      default: return undefined;
      }
    },

    showRemaining() {
      return this.remaining > 0;
    },
  },
  watch: {
    timestamp() {
      this.calcRemaining();
      this.registerUpdateInterval();
    },

    seconds() {
      this.calcRemaining();
      this.registerUpdateInterval();
    },
  },
  created() {
    this.calcRemaining();
    this.registerUpdateInterval();
  },
  beforeUnmount() {
    this.clearUpdateInterval();
  },
  methods: {
    calcRemaining() {
      const NOW = new Date();
      if (this.targetTime <= NOW) {
        this.remaining = 0;
        if (this.onExpired) {
          setTimeout(this.onExpired, 3000);
        }
        this.clearUpdateInterval();
        return;
      }
      this.remaining = this.targetTime - NOW;
    },

    registerUpdateInterval() {
      if (!this.interval) {
        this.interval = setInterval(this.calcRemaining, 1000);
      }
    },

    clearUpdateInterval() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    }
  }
};
