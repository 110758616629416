import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import translate from "../../../../../global/directives/translate";
import PuxGet from "../../../../../global/components/PuxGet/PuxGet.vue";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "ZahlungsverkehrTr",
  components: {
    SnapshotIcon,
    PuxGet,
  },
  directives: {
    translate,
  },
  props: {
    bankverbindung: {
      type: Object,
      required: true,
    },
    bankverbindungenList: {
      type: Array,
      required: true,
    },
    statusDiff: {
      type: Boolean,
      required: true,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    statusSnapshotTr: {
      type: Boolean,
      required: false,
    },
    snapshotChanges: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterIban,
    } = FiltersAPI();

    return {
      filterIban,
    };
  },
  computed: {
    translateVersionTdOptions() {
      return {
        text: this.translateVersionTdText,
        extra: {
          date: this.snapshotDate,
        },
      };
    },

    translateVersionTdText() {
      if (this.statusSnapshotTr) {
        return "_TXT_VERSION_MODIFIED_COLUMN_{{date}}_";
      }
      return "_TXT_AKTUELLE_VERSION_";
    },

    classVersion() {
      if (this.statusDiff) {
        return "version-bg";
      }
      return "";
    },

    rowspanFirstTd() {
      return !this.statusSnapshotTr ? 2 : 1;
    },

    iban() {
      return this.filterIban(this.bankverbindung.aov_iban, true);
    },
  },
};
