import axios from "axios";
import store from "../../store";
import {
  setUrlWithParams
} from "../functions/http";
import HttpErrorsMixin from "./HttpErrorsMixin";
import {
  forEach,
  isArray,
  keyBy,
  cloneDeep,
} from "lodash-es";
import GlobalOptions from "../const/GlobalOptions";
import {
  createNamespacedHelpers
} from "vuex";

const {
  mapMutations,
  mapGetters
} = createNamespacedHelpers("apis");

const BASE_URL = "/api/";

const API = axios.create({
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});

export default {
  mixins: [
    HttpErrorsMixin,
  ],
  computed: {
    ...mapGetters([
      "GET_SAVED_API",
    ]),
  },
  methods: {
    getHttp({
      url,
      data,
      urlParams = {},
      headerParams,
      responseType,
      apiSaveId,
      keyId,
      fullResponse,
      showError,
    }) {
      return this.callHttpRequestAndCheckSavedApi({
        methodHttp: "get",
        url,
        urlParams,
        data,
        headerParams,
        responseType,
        apiSaveId,
        keyId,
        fullResponse,
        showError,
      });
    },

    getListHttp({
      url,
      data,
      urlParams = {},
      headerParams,
      responseType,
      apiSaveId,
      keyId,
      fullResponse,
      showError,
    }) {
      return this.callHttpRequestAndCheckSavedApi({
        methodHttp: "get",
        url,
        urlParams,
        data,
        headerParams,
        responseType,
        apiSaveId,
        keyId,
        fullResponse,
        showError,
        expectedList: true,
      });
    },

    getOptionsHttp({
      url,
      data,
      urlParams = {},
      headerParams,
      responseType,
      keyId,
      fullResponse,
      showError,
    }) {
      return this.callHttpRequestAndCheckSavedApi({
        methodHttp: "options",
        url,
        urlParams,
        data,
        headerParams,
        responseType,
        keyId,
        fullResponse,
        showError,
      });
    },

    postHttp({
      url,
      data,
      urlParams = {},
      headerParams,
      responseType,
      fullResponse,
      showError,
    }) {
      return this.callHttpRequestAndCheckSavedApi({
        methodHttp: "post",
        url,
        urlParams,
        data,
        headerParams,
        responseType,
        fullResponse,
        showError,
      });
    },

    putHttp({
      url,
      data,
      urlParams = {},
      headerParams,
      responseType,
      fullResponse,
      showError,
    }) {
      return this.callHttpRequestAndCheckSavedApi({
        methodHttp: "put",
        url,
        urlParams,
        data,
        headerParams,
        responseType,
        fullResponse,
        showError,
      });
    },

    patchHttp({
      url,
      data,
      urlParams = {},
      headerParams,
      responseType,
      fullResponse,
      showError,
    }) {
      return this.callHttpRequestAndCheckSavedApi({
        methodHttp: "patch",
        url,
        urlParams,
        data,
        headerParams,
        responseType,
        fullResponse,
        showError,
      });
    },

    deleteHttp({
      url,
      data,
      urlParams = {},
      headerParams,
      responseType,
      fullResponse,
      showError,
    }) {
      return this.callHttpRequestAndCheckSavedApi({
        methodHttp: "delete",
        url, urlParams,
        data,
        headerParams,
        responseType,
        fullResponse,
        showError,
      });
    },

    getChoicesHttp({
      id,
      label,
      url,
      params = {},
    } = {}) {
      const list = [];
      if (id && label) {
        params.fields = [id, label];
      }
      const PROMISE = new Promise((resolve, reject) => {
        this.getHttp({ url, urlParams: params }).then(
          response => {
            const RESULTS = response.results || response;
            if (id && label) {
              forEach(RESULTS, item => {
                list.push({
                  value: item[id],
                  label: item[label],
                });
              });
              return resolve(list);
            }
            return resolve(RESULTS);
          },
          error => {
            return reject(error);
          }
        );
      });
      return PROMISE;
    },

    callHttpRequestAndCheckSavedApi({
      methodHttp,
      url,
      urlParams,
      data,
      headerParams,
      responseType = "json",
      apiSaveId,
      keyId,
      fullResponse,
      showError = false,
      expectedList
    }) {
      let api__data_and_loading = undefined;
      if (apiSaveId) {
        api__data_and_loading = this.GET_SAVED_API({ apiSaveId });
        if (api__data_and_loading) {
          if (api__data_and_loading.loading) {
            return api__data_and_loading.promise;
          }
        }
      }

      const PROMISE = new Promise((resolve, reject) => {
        if (api__data_and_loading) {
          if (!api__data_and_loading.loading) {
            if (keyId) {
              if (api__data_and_loading.keyData) {
                return resolve(api__data_and_loading.keyData);
              }
              const KEY_DATA = this.setKeydata({ data: api__data_and_loading.data, keyId });
              this.MUT_INSERT_API_KEY_DATA({ apiSaveId, keyData: KEY_DATA });
              return resolve(KEY_DATA);
            }
            return resolve(api__data_and_loading.data);
          }
        }
        const URL_NEW = setUrlWithParams({ url, params: urlParams });
        let url_full = `${ BASE_URL }${ URL_NEW }`;
        url_full = url_full.replace(/\/\//g, "/");
        API({
          method: methodHttp,
          url: url_full,
          data,
          headers: headerParams,
          responseType,
        }).then(
          response => {
            if (fullResponse) {
              return resolve(response);
            }
            const DATA = this.checkedExpectedList({ expectedList, response });
            const KEY_DATA = this.setKeydata({ data: DATA, keyId });
            if (apiSaveId) {
              this.MUT_INSERT_API({ apiSaveId, data: DATA, keyData: KEY_DATA });
            }
            if (keyId) {
              return resolve(KEY_DATA);
            }
            return resolve(DATA);
          },
          error => {
            if (this.checkErrorStatus({ error: error.response, showError, client: API, reject, resolve })) {
              return reject(error.response);
            }
          }
        );
      });

      if (!api__data_and_loading) {
        this.MUT_INSERT_PROMISE_API({ apiSaveId, promise: PROMISE });
      }

      return PROMISE;
    },

    checkedExpectedList({ expectedList, response }) {
      if (expectedList) {
        if (isArray(response.data)) {
          return response.data;
        }
        return response.data[GlobalOptions.getListHttpKeyList] ? response.data[GlobalOptions.getListHttpKeyList] : [];
      }
      return response.data;
    },

    setKeydata({ data, keyId }) {
      if (!keyId) {
        return undefined;
      }
      return keyBy(data, keyId);
    },

    ...mapMutations([
      "MUT_INSERT_API",
      "MUT_INSERT_PROMISE_API",
      "MUT_INSERT_API_KEY_DATA",
    ]),
  },
};

export function getProfile() {
  const PROMISE = new Promise((resolve, reject) => {
    const ME = store.state.profile.ME;
    if (ME) {
      return resolve(ME);
    }
    if (window.userPromise) {
      return resolve(window.userPromise);
    }

    API({
      method: "get",
      url: `${ BASE_URL }profil/`,
    }).then(
      response => {
        store.commit("profile/MUT_SET_ME", cloneDeep(response.data));
        return resolve(response.data);
      },
      error => {
        return reject(error);
      }
    );
  });
  return PROMISE;
}

export function getHttp({ url, urlParams }) {
  const URL_NEW = setUrlWithParams({ url, params: urlParams });
  return API({
    method: "get",
    url: `${ BASE_URL }${ URL_NEW }`,
  });
}
