"use strict";

import template from "./create.expert.jade";

import FilterSet from "dias/core/table/filterSet";

class AngebotFilterSet extends FilterSet {
  constructor(Katalog, KZielgruppe, i18n, monetaere_foerderung) {
    super();
    this.gettext = i18n.gettext;
    this.addFilter("ars_kbez", {
      label: this.gettext("Förderangebot"),
      textInput: true,
      param: "ars_kbez",
      initialStatus: true
    });
    /* this.addFilter("thema", {
      label: "Thema",
      choices: Katalog.getChoices("kthema", "pk", "the_kbez"),
      param: "thema",
    });*/
    if (monetaere_foerderung) {
      this.addFilter("zielgruppe", {
        label: "Zielgruppe",
        choices: KZielgruppe.getChoices("pk", "zgr_kbez"),
        param: "zielgruppe"
      });
      this.addFilter("kosten", {
        label: "Mögliche Kostenarten",
        choices: Katalog.getChoices("kostenart", "pk", "kos_kbez"),
        param: "kosten"
      });
    }
  }
}


class FoerderangeboteListController {
  /*@ngInject*/
  constructor(
    $q,
    $state,
    $filter,
    RegelsatzAktiv,
    Foerderantrag,
    FoerderantragquelleUUIDs,
    Foerderangebotstatus,
    Katalog,
    KZielgruppe,
    DiasTableConfig,
    DiasNotification,
    diasModalDialog,
    SyConfigs,
    Client,
    i18n
  ) {
    this.gettext = i18n.gettext;
    const monetaere_foerderung = SyConfigs.wert("monetaere_foerderung");
    if (Client === "dias-am") {
      const foerderfinderconf = SyConfigs.wert("förderfinder_link");
      if (foerderfinderconf && foerderfinderconf.url) {
        this.link = {
          name: foerderfinderconf.name,
          title: foerderfinderconf.titel,
          url: foerderfinderconf.url,
          target: "_blank"
        };
      }
    }

    this.showTitle = row => {
      // return "Antrag zum Förderangebot " + row.ars_kbez + " erstellen";
      return `${ this.gettext("Antrag") } zum ${ this.gettext("Förderangebot") } ${ row.ars_kbez } erstellen`;
    };
    this.createAntrag = row => {
      const antrag = {
        regelsatz: row.pk,
        quelle: FoerderantragquelleUUIDs.webapp
      };
      const promise = Foerderantrag.post(antrag).then(
        response => {
          DiasNotification.page.success(this.gettext("Antrag") + " " + $filter("spaced")(response.a_nr, true) + " wurde erfolgreich erstellt.");
          $state.go("root.foerderantrag.details", { id: response.pk });
        },
        error => DiasNotification.page.error(error, "Fehler beim Erstellen " + this.gettext("des Antrags"))
      );
      return diasModalDialog({
        title: "Bitte warten Sie",
        template: () => `
          <div class="modal-body">
            <h4 class="text-center">
              ` + this.gettext("Der Antrag") + ` für ` + row.ars_kbez + ` wird erstellt.<br>
              Sie werden nach Erstellung automatisch zur Detailseite weitergeleitet.
            </h4>
          </div>`,
        controller: /*@ngInject*/$scope => {
          $scope.exitWithCallback(() => promise);
        }
      });
    };

    const cols = [
      { label: "Name",
        sortable: true,
        field: "ars_kbez",
        cellTemplate: `<strong>{{row.ars_kbez}}</strong>`
      },
    ];
    if (monetaere_foerderung) {
      cols.push({
        label: "Förderhöhe",
        sortable: false,
        field: "zuschuss",
        cellTemplate:
          `<ul class="list-unstyled"><li ng-repeat="z in row.zuschuss">
          <span ng-if="z.max_foerdersatz && z.max_foerdersumme">
            Bis zu {{z.max_foerdersatz | number: 2}}% (max. {{z.max_foerdersumme|currency:'€':0}})
          </span>
          <span ng-if="z.max_foerdersatz && !z.max_foerdersumme">
            Bis zu {{z.max_foerdersatz | number: 2}}%
          </span>
          <span ng-if="z.max_foerdersumme && !z.max_foerdersatz">
            max. {{z.max_foerdersumme|currency:'€':0}}
          </span>
          </li></ul>`,
      });
    }
    const tableDeferred = $q.defer();
    this.angebotTable = tableDeferred.promise;
    tableDeferred.resolve({
      dataSource: RegelsatzAktiv.getList,
      detailTemplate: "angebotDetailRow.html",
      tableTitle: this.gettext("Förderangebote"),
      columnDefs: cols,
      filter: new AngebotFilterSet(Katalog, KZielgruppe, i18n, monetaere_foerderung),
      filterName: "neuerantrag",
      renderSingleActions: true,
      actions: [
        {
          label: this.gettext("Neuen Antrag stellen"),
          type: "primary",
          callback: this.createAntrag,
          titleCallback: this.showTitle,
          class: "test_antrag_stellen", // kjdshfjhgdsfjhg
        },
      ],
    });

    const globalColumnDefs = SyConfigs.wert("tabellenkonfig");
    let hideZuschuss = false;
    if (globalColumnDefs) {
      hideZuschuss = (
        globalColumnDefs.neuerantrag !== undefined &&
        globalColumnDefs.neuerantrag.hide &&
        globalColumnDefs.neuerantrag.hide.indexOf("zuschuss") >= 0);
    }

    const menu = [{ title: "Wir fördern", icon: "glyphicon-antrag", id: 1 }];
    if (hideZuschuss) {
      menu[0].active = true;
    } else {
      menu.unshift({ title: "Förderhöhe", icon: "glyphicon-foerderung", id: 0, active: true });
    }

    this.angebotTableDetail = {
      menu: menu,
      title: {
        label: "Förderangebot",
        wayName: "ars_kbez",
      },
      hideZuschuss: hideZuschuss,
    };
  }
}

export default {
  template: template(),
  controller: FoerderangeboteListController,
  controllerAs: "vm"
};
