import SearchStartTabContent from "./SearchStartTabContent/SearchStartTabContent.vue";
import PageTabTitle from "../../global/components/PageTabTitle/PageTabTitle.vue";

// @vue/component
export default {
  name: "TheSearchStart",
  components: {
    SearchStartTabContent,
    PageTabTitle,
  },
  data() {
    return {
      currentNavId: "studiengang",
      data: [
        {
          id: "studiengang",
          label: "akkreditierte Studiengänge",
          searchUrl: "akkrstudiengaenge/count/",
          url: "/akkrstudiengaenge/",
          urlParameter: "akkreditiert",
          list: [
            {
              id: "sg_bez",
              type: "text",
              label: "Studiengang",
              mainOrExtra: "main",
              class: "col-lg-6",
              view: "v",
            },
            {
              id: "hochschule",
              htmlId: "hochschule_list",
              type: "select",
              label: "Name der Hochschule",
              keyId: "hs_id",
              keyLabel: "hs_kbez",
              menuRight: true,
              showCaret: true,
              menuWidthAll: true,
              closeClick: true,
              mainOrExtra: "main",
              class: "col-lg-6",
              url: "hochschulen_oeffentlich/?fields=hs_id&fields=hs_kbez&fields=hs_bez",
              apiSaveId: "hochschulen_oeffentlich",
              searchList: ["hs_kbez", "hs_bez"],
              search: true,
              deselect: true,
              view: "v",
            },
            {
              id: "abschlussgrad",
              type: "select",
              label: "Abschlussgrad",
              keyId: "ag_id",
              keyLabel: "ag_kbez",
              menuRight: true,
              showCaret: true,
              menuWidthAll: true,
              closeClick: true,
              mainOrExtra: "main",
              class: "col-lg-6",
              url: "abschlussgrade/?fields=ag_id&fields=ag_kbez",
              apiSaveId: "abschlussgrade",
              searchList: ["ag_kbez"],
              search: true,
              deselect: true,
              view: "v",
            },
            {
              id: "studienformen",
              type: "select",
              label: "Studienform",
              keyId: "sdf_id",
              keyLabel: "sdf_kbez",
              menuRight: true,
              showCaret: true,
              menuWidthAll: true,
              closeClick: true,
              mainOrExtra: "main",
              class: "col-lg-6",
              url: "studienformen/?fields=sdf_id&fields=sdf_kbez",
              apiSaveId: "studienformen",
              searchList: ["sdf_kbez"],
              search: true,
              deselect: true,
              view: "v",
            },
            {
              id: "typ",
              htmlId: "studientyp",
              type: "select",
              label: "Studientyp",
              keyId: "kst_id",
              keyLabel: "kst_kbez",
              menuRight: true,
              showCaret: true,
              menuWidthAll: true,
              closeClick: true,
              mainOrExtra: "extra",
              class: "col-lg-6",
              url: "studientypen/?fields=kst_id&fields=kst_kbez",
              apiSaveId: "studientypen",
              deselect: true,
              view: "v",
            },
            {
              id: "faechergruppen",
              type: "select",
              label: "Fächergruppe",
              keyId: "fae_id",
              keyLabel: "fae_kbez",
              menuRight: true,
              showCaret: true,
              menuWidthAll: true,
              closeClick: true,
              mainOrExtra: "extra",
              class: "col-lg-6",
              url: "faechergruppen/?fields=fae_id&fields=fae_kbez",
              apiSaveId: "faechergruppen",
              deselect: true,
              view: "v",
            },
            {
              id: "studienbereiche",
              type: "select",
              label: "Studienbereich",
              keyId: "sdb_id",
              keyLabel: "sdb_kbez",
              menuRight: true,
              showCaret: true,
              menuWidthAll: true,
              closeClick: true,
              mainOrExtra: "extra",
              class: "col-lg-6",
              url: "studienbereiche/?fields=sdb_id&fields=sdb_kbez",
              apiSaveId: "studienbereiche",
              searchList: ["sdb_kbez"],
              search: true,
              deselect: true,
              view: "v",
            },
            {
              id: "mastertyp",
              type: "multiselect",
              label: "Mastertyp",
              keyId: "mt_id",
              keyLabel: "mt_kbez",
              menuRight: true,
              showCaret: true,
              menuWidthAll: true,
              closeClick: false,
              mainOrExtra: "extra",
              class: "col-lg-6",
              url: "mastertypen/?fields=mt_id&fields=mt_kbez",
              apiSaveId: "mastertypen",
              deselect: true,
              view: "v",
            },
            {
              id: "sg_regelstudienzeit",
              type: "integer",
              label: "Regelstudienzeit",
              mainOrExtra: "extra",
              class: "col-lg-6",
              view: "v",
            },
            {
              id: "standorte",
              htmlId: "hochschul-standort",
              type: "text",
              label: "Hochschul-Standort",
              mainOrExtra: "extra",
              class: "col-lg-6",
              view: "v",
            },
          ],
        },
        {
          id: "hochschule",
          label: "systemakkreditierte Hochschulen",
          searchUrl: "akkrhochschulen/count/",
          url: "/akkrhochschulen/",
          urlParameter: "systemakkreditiert",
          list: [
            {
              id: "name",
              type: "text",
              label: "Name der Hochschule",
              mainOrExtra: "main",
              class: "col-lg-12",
              view: "v",
            },
            {
              id: "standorte",
              type: "text",
              label: "Ort",
              mainOrExtra: "main",
              class: "col-lg-6",
              view: "v",
            },
            {
              id: "typ",
              htmlId: "hochschultyp",
              type: "select",
              label: "Hochschultyp",
              keyId: "hst_id",
              keyLabel: "hst_kbez",
              menuRight: true,
              showCaret: true,
              menuWidthAll: true,
              closeClick: true,
              mainOrExtra: "main",
              class: "col-lg-6",
              url: "hochschultypen/?fields=hst_id&fields=hst_kbez",
              apiSaveId: "hochschultypen",
              deselect: true,
              view: "v",
            },
            {
              id: "bun",
              type: "select",
              label: "Bundesland",
              keyId: "bun_id",
              keyLabel: "bun_kbez",
              menuRight: true,
              showCaret: true,
              menuWidthAll: true,
              closeClick: true,
              mainOrExtra: "extra",
              class: "col-lg-6",
              url: "katalog/?fields=bun_id&fields=bun_kbez&key=kbundesland",
              apiSaveId: "kbundesland",
              deselect: true,
              view: "v",
            },
            {
              id: "traeger",
              type: "select",
              label: "Trägerschaft",
              keyId: "tra_id",
              keyLabel: "tra_kbez",
              menuRight: true,
              showCaret: true,
              menuWidthAll: true,
              closeClick: true,
              mainOrExtra: "extra",
              class: "col-lg-6",
              url: "traegerschaften/?fields=tra_id&fields=tra_kbez",
              apiSaveId: "traegerschaften",
              deselect: true,
              view: "v",
            },
          ],
        },
      ],
    };
  },
  methods: {
    changeNav(item) {
      if (this.currentNavId === item.id) {
        return;
      }
      this.currentNavId = item.id;
    },
  },
};
