import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import FormElement from "../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxErrors from "../../../global/components/PuxErrors/PuxErrors.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxRequired from "../../../global/components/PuxRequired/PuxRequired.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import RegistrationHeader from "../RegistrationHeader/RegistrationHeader.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

const CLASSES_HORIZONTAL = [
  "col-sm-4 text-left text-sm-right",
  "col-sm-6",
  "offset-sm-4 col-sm-6",
];

// @vue/component
export default {
  name: "ResetPasswordFinishPage",
  components: {
    AngularLink,
    FormElement,
    PuxButton,
    PuxCloak,
    PuxErrors,
    PuxIcon,
    PuxPageDetails,
    PuxRequired,
    PuxTranslate,
    RegistrationHeader,
  },
  mixins: [
    HttpMixin,
  ],
  data() {
    return {
      regClass: "col-12 col-sm-10 col-lg-7 mr-0",
      statusLoading: true,
      statusSuccess: undefined,
      statusSubmitting: undefined,
      model: {
        new_password1: null,
        new_password2: null,
      },
      errors: {},
      optionsPassword1: {
        type: "password",
        id: "new_password1",
        label: "_LBL_REGISTRIERUNG_FINISH_PASSWORT_",
        translate: true,
        placeholder: "_LBL_REGISTRIERUNG_FINISH_PASSWORT_",
        view: "h",
        required: true,
        classesHorizontal: CLASSES_HORIZONTAL,
      },
      optionsPassword2: {
        type: "password",
        id: "new_password2",
        label: "_LBL_REGISTRIERUNG_FINISH_PASSWORT_WIEDERHOLUNG_",
        translate: true,
        placeholder: "_LBL_REGISTRIERUNG_FINISH_PASSWORT_WIEDERHOLUNG_",
        view: "h",
        required: true,
        classesHorizontal: CLASSES_HORIZONTAL,
      },
    };
  },
  computed: {
    optionsListForPuxErrors() {
      return [
        this.optionsPassword1,
        this.optionsPassword2,
      ];
    },
  },
  created() {
    this.checkUrl();
  },
  methods: {
    checkUrl() {
      if (!this.$stateParams.uuid || !this.$stateParams.tok) {
        this.successStatus = false;
        this.loading = false;
        return;
      }
      this.getHttp({
        url: `auth/passwort_neu/${ this.$stateParams.uuid }/${ this.$stateParams.tok }/`
      }).then(
        response => {
          this.statusSuccess = response && response.validlink;
        },
        error => {
          this.onError(error.data);
        }
      ).then(
        () => this.statusLoading = false
      );
    },

    onError(errors) {
      if (errors && errors.validlink === false) {
        this.statusSuccess = false;
        return;
      }
      this.errors = errors;
    },

    absenden() {
      if (this.statusSubmitting) {
        return;
      }
      this.deleteErrors();
      this.statusSubmitting = true;
      this.postHttp({
        url: `auth/passwort_neu/${ this.$stateParams.uuid }/${ this.$stateParams.tok }/`,
        data: this.model,
      }).then(
        () => {
          this.addNotification({ text: "_MSG_RESET_PASSWORD_FINISH_SUCCESS_" });
          this.$goTo("root.login");
        },
        error => {
          this.onError(error.data);
        }
      ).then(
        () => this.statusSubmitting = false
      );
    },

    deleteErrors() {
      this.errors = {};
    },
  },
};
