"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import diasTable from "dias/core/table";
import diasAnon from "dias/core/anonymisierung";
import diasNotification from "dias/core/notification";
import diasRichtext from "dias/core/richtext";
import workflow from "dias/workflow";
import zielgruppen from "dias/zielgruppen";
import diasLoading from "dias/core/loading";
import FoerderregelnModels from "dias/foerderregeln/models";

import models from "./models";
import services from "./services";
import detailsComponent from "./details.component";
import angebotkontextComponent from "./angebotkontext.component";
import aspekteComponent from "./aspekte.component";

import foerderangebotRegeln from "./regel";

import foerderangebotVersionen from "./versionen";
import foerderprogramme from "./foerderprogramme";


const foerderangeboteModule = angular.module("root.foerderangebote", [
  uiRouter,
  models.name,
  services.name,
  diasTable.name,
  diasRichtext.name,
  diasLoading.name,
  diasAnon.name,
  diasNotification.name,
  foerderangebotRegeln.name,
  FoerderregelnModels.name,
  workflow.name,
  zielgruppen.name,
  foerderangebotVersionen.name,
  foerderprogramme.name,
]);

foerderangeboteModule.config($stateProvider => {
  $stateProvider

    // Förderangebot-Liste
    .state("root.foerderangebote", {
      url: `/foerderangebote/?
            user_cookie&
            instrumentvariante&
            status
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-angebot-list></vue-angebot-list></div>"
        }
      },
      data: {
        permissions: "foerderangebot.view.admin"
      }
    })

    // Detail-Seite eines Förderangebots
    .state("root.foerderangebote.detail", {
      url: ":id/?regelsatzId",
      params: {
        id: null,
        regelsatzId: null
      },
      views: {
        "main@root": {
          template: "<foerderangebote-details></foerderangebote-details>"
        }
      },
    });
})

  .component("foerderangeboteDetails", detailsComponent)
  .component("angebotkontext", angebotkontextComponent)
  .component("aspekte", aspekteComponent)
;

export default foerderangeboteModule;
