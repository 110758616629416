"use strict";

import BaseModel from "dias/core/models/baseModel";
import BaseRegelModel from "./baseRegelModel";
import Aspekte from "./aspekte.service";
import Regelsignale from "./regelsignale.service";
import Anonymisierungsplaene from "dias/core/anonymisierung/models/anonymisierungsplaene.service";

class Regelsatz extends BaseModel {
  constructor() {
    super("regelsaetze", {
      subPaths: {
        anzeigekontexte: "anzeigekontexte",
        aspekte: new Aspekte(),
        regeln: new BaseRegelModel("regeln"),
        regelsignale: new Regelsignale(),
        anonymisierungsplaene: new Anonymisierungsplaene(),
        _zielgruppen: "zielgruppen",
      }
    });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.fetchDeletable = () => collection.customGET("deletable");
    collection.fetchEditable = () => collection.customGET("editable");

    return collection;
  }

  extendModel(model) {
    model = super.extendModel(model);
    model.updateStatus = status => {
      return model.customPUT({ status: status }, "status_aendern").then(
        response => {
          model.transitions = response.transitions;
          model.status = response.status;
          model.upddate = response.upddate;
          model.abgeschlossen_durch = response.abgeschlossen_durch;
        }
      );
    };
    model.updateGueltigkeitszeitraum = bis => {
      return model.customPUT({ ars_gueltig_bis: bis }, "gueltigkeitszeitraum_aendern").then(
        response => {
          model.upddate = response.upddate;
          model.ars_gueltig_bis = response.ars_gueltig_bis;
        }
      );
    };
    return model;
  }

  regelnExtendModel(model) {
    model.moveUp = () => model.customPOST({}, "nach_oben");
    model.moveDown = () => model.customPOST({}, "nach_unten");
    model.far_pos = Number(model.far_pos);

    model.titel = (model.far_nummer ? ("[" + model.far_nummer + "] ") : "") + model.far_kbez;
    return model;
  }

  anzeigekontexteExtendCollection(collection) {
    collection.getByKontextId = id => {
      for (let i = 0; i < collection.length; i++) {
        if (collection[i].anzeigekontext.pk === id) {
          return collection[i];
        }
      }
      return undefined;
    };
    return collection;
  }

  anzeigekontexteExtendModel(model) {
    model.updateStatus = status => {
      const oldStatus = model.status;
      model.status = status;
      model.put().then(
        () => {},
        () => model.status = oldStatus
      );
    };
    return model;
  }
}

export default Regelsatz;
