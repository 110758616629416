// Modul zur Erfassung von Personalkosten mit Personalkostentabelle
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "dias/kosten_finanzierung/kosten.controller";
import template from "dias/kosten_finanzierung/kosten.template.jade";
import FoerderantragModels from "dias/foerderantrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const personalkosten_pkt = angular.module("dias.kosten_finanzierung.modul.personalkosten_pkt", [
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
]);

personalkosten_pkt.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("personalkosten_pkt", "modul-personalkosten-pkt");
})

  .directive("modulPersonalkostenPkt", builder.directive(template, controller));

export default personalkosten_pkt;
