"use strict";

import angular from "angular";
import diasLoading from "dias/core/loading";

import ampel from "./ampel";
import antragbasisdaten from "./antragbasisdaten";
import antragstellerregel from "./antragstellerregel";
import auswahl_katalog from "./auswahl_katalog";
import auszahlungsplan from "./auszahlungsplan";
import dynamische_mehrfachauswahl from "./dynamische_mehrfachauswahl";
import dynamische_zahlen from "./dynamische_zahlen";
import dynamische_tabelle from "./dynamische_tabelle";
import einfache_foerderregel from "./einfache_foerderregel";
import elementliste from "./elementliste";
import elementverzweigung from "./elementverzweigung";
import kennzahlen from "./kennzahlen";
import pruefung_betreuungsverhaeltnis from "./pruefung_betreuungsverhaeltnis";

import registernummer from "./antragsteller/registernummer";

const standard_foerdermodule = angular.module("dias.standard_foerdermodule", [
  ampel.name,
  antragbasisdaten.name,
  antragstellerregel.name,
  auswahl_katalog.name,
  auszahlungsplan.name,
  diasLoading.name,
  dynamische_mehrfachauswahl.name,
  dynamische_zahlen.name,
  dynamische_tabelle.name,
  einfache_foerderregel.name,
  elementliste.name,
  elementverzweigung.name,
  kennzahlen.name,
  pruefung_betreuungsverhaeltnis.name,
  // Antragsteller
  registernummer.name,
]);

export default standard_foerdermodule;
