import ModuleMixin from "../ModuleMixin";
import GeschaeftsregelModulChild from "../../GeschaeftsregelModulChild/GeschaeftsregelModulChild.vue";
import loading from "../../../../global/directives/loading";
import {
  cloneDeep,
  forEach,
  keyBy,
  get,
  findKey,
  find,
  isNil,
} from "lodash-es";
import {
  EventBus,
} from "../../../../global/functions/event-bus";
import { createNamespacedHelpers } from "vuex";
const {
  mapState,
} = createNamespacedHelpers("snapshot");

// @vue/component
export default {
  name: "AntragstellerRechtsformZusatz",
  components: {
    GeschaeftsregelModulChild,
  },
  directives: {
    loading,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      signaleChildrenMap: {},
      unterregelnObj: {},
    };
  },
  computed: {
    activeZweigId() {
      return findKey(this.antworten, el => get(el, "status"));
    },

    activeZweigChildSuffix() {
      return `_${ this.module.regel.pk }_${ this.activeZweigId }`;
    },

    activeZweigData() {
      return this.antworten[this.activeZweigId] || { regeln: {} };
    },

    activeZweigConfig() {
      if (!this.activeZweigId) {
        return {};
      }
      return find(this.modelParameter.zweige, { id: this.activeZweigId }) || {
        regeln: []
      };
    },

    activeZweigSnapshot() {
      return get(this.zweigeSnapshot, this.activeZweigId, { regeln: {} });
    },

    snapshotZweigId() {
      return findKey(this.zweigeSnapshot, el => get(el, "status"));
    },

    snapshotZweigChildSuffix() {
      return `_${ this.module.regel.pk }_${ this.snapshotZweigId }`;
    },

    snapshotZweigData() {
      return this.zweigeSnapshot[this.snapshotZweigId] || { regeln: {} };
    },

    snapshotZweigConfig() {
      if (!this.snapshotZweigId) {
        return {};
      }
      return find(this.modelParameter.zweige, { id: this.snapshotZweigId }) || {
        regeln: []
      };
    },

    showSnapshotRegeln() {
      return this.statusSnapshot && this.activeZweigId !== this.snapshotZweigId;
    },

    antworten() {
      return !isNil(this.model) ? (this.model.antworten || {}) : {};
    },

    editable() {
      return !this.statusReadonly;
    },

    zweigeSnapshot() {
      if (this.statusSnapshot &&
        this.snapshotModule &&
        this.snapshotModule.antworten) {
        return this.snapshotModule.antworten;
      }
      return {};
    },

    statusSnapshotChange() {
      let statusChange = false;
      forEach(this.modelParameter.zweige, (element, elementId) => {
        forEach(element.regeln, unterregelPk => {
          if (this.STATUS_DIFF_FOR_REGELS[`${ unterregelPk }_${ this.module.regel.pk }_${ elementId }`]) {
            statusChange = true;
            return false;
          }
        });
        if (statusChange) {
          return false;
        }
      });

      return statusChange;
    },

    ...mapState([
      "STATUS_DIFF_FOR_REGELS",
    ]),
  },
  watch: {
    "obj.rechtsform"() {
      this.reloadModule();
    }
  },
  created() {
    this.initEventBus();
  },
  beforeUnmount() {
    this.registerOrDeactiveSignalReceiverForChildren({ eventBusAction: "$off" });
    this.destroyEventBus();
  },
  methods: {
    initDataLocal({ statusFirstLoad }) {
      if (statusFirstLoad) {
        this.unterregelnObj = keyBy(this.regel.unterregeln, "regel");
        this.registerOrDeactiveSignalReceiverForChildren({ eventBusAction: "$on" });
      }
    },

    registerOrDeactiveSignalReceiverForChildren({ eventBusAction }) {
      forEach(this.regel.unterregeln, unterregel => {
        forEach(unterregel.signal_empfang, empfang => {
          if (empfang.signal_kennung && empfang.sender_reid) {
            const SIGNAL_NAME = `${ empfang.signal_kennung }_${ empfang.sender_reid }`;
            this.signaleChildrenMap[SIGNAL_NAME] = true;
            EventBus[eventBusAction](SIGNAL_NAME, arg => this.onReceiveSignalForChildren(empfang.signal_kennung, arg));
          }
        });
      });
    },

    onReceiveSignalForChildren(signal_kennung, arg = {}) {
      const REGEL = arg.regel && arg.regel.regel;
      const SIGNAL_NAME = `${ signal_kennung }_${ REGEL }`;
      if (this.signaleChildrenMap[SIGNAL_NAME]) { // Wenn Signal aus child von elementliste kommt
        return;
      }
      clearTimeout(this.timerSignalChildren);
      this.timerSignalChildren = setTimeout(() => { // Workaround, wenn 2 oder mehrere Signale gleichzeitig kommen
        this.reloadModule();
      }, 100);
    },

    perepareDataForSave({ data, regel } = {}) {
      const antwort = cloneDeep(this.activeZweigData);
      antwort.status = true;
      antwort.regeln = {
        [regel]: data,
      };
      const DATA = {
        antworten: {
          [this.activeZweigId]: antwort,
          partial_update: [regel]
        }
      };

      return DATA;
    },

    updateZweig({ data, regel }) {
      const DATA = this.perepareDataForSave({ data, regel });
      return this.save({
        model: DATA,
        childObj: {
          childRegel: regel,
        },
      }).then(
        () => this.statusLoadingCheckbox = false
      );
    },

    onError({ errors, childObj = {} } = {}) {
      const {
        childRegel,
      } = childObj;
      const ERRORS = cloneDeep(errors);
      const CURRENT_DATA = get(ERRORS, `data.antworten[${ this.activeZweigId }].regeln.${ childRegel }`);
      ERRORS.data = CURRENT_DATA;
      return ERRORS;
    },

    updateModuleLocal({ response, extra, group }) {
      const RESPONSE = cloneDeep(response);
      const MODULE = cloneDeep(this.module);
      MODULE.data = RESPONSE.data;
      this.updateModule({ response: MODULE, extra, group });
    },

    initEventBus() {
      EventBus.$on("antragsteller.update", this.reloadModule);
    },

    destroyEventBus() {
      EventBus.$off("antragsteller.update", this.reloadModule);
    },
  },
};
