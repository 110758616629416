import Alert from "../../../../global/components/Alert/Alert.vue";
import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxErrors from "../../../../global/components/PuxErrors/PuxErrors.vue";
import PuxRequired from "../../../../global/components/PuxRequired/PuxRequired.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import {
  size,
} from "lodash-es";

const CLASSES_HORIZONTAL = [
  "col-sm-4 text-left text-sm-right",
  "col-sm-6",
  "offset-sm-4 col-sm-6",
];

// @vue/component
export default {
  name: "ChangePassword",
  components: {
    Alert,
    AngularLink,
    FormElement,
    PuxButton,
    PuxErrors,
    PuxRequired,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
  ],
  data() {
    return {
      statusSubmitting: undefined,
      statusLoading: true,
      model: {
        password_old: null,
        password_new1: null,
        password_new2: null,
      },
      errors: {},
      errorsLabel: {
        password_old: "_LBL_CHANGE_PASSWORD_OLD_",
        password_new1: "_LBL_CHANGE_PASSWORD_NEW1_",
        password_new2: "_LBL_CHANGE_PASSWORD_NEW2_"
      },
      conf: {},
    };
  },
  computed: {
    statusShowErrors() {
      return size(this.errors);
    },

    translateOptionsBodyAlert() {
      return {
        html: "_HTML_CHANGE_PASSWORD_BODY_ALERT_{{min_length}}_{{max_length}}_{{allowed_chars}}_",
        extra: {
          min_length: this.conf.min_length,
          max_length: this.conf.max_length,
          allowed_chars: this.conf.allowed_chars,
        },
      };
    },

    optionsPasswordOld() {
      return {
        type: "password",
        id: "password_old",
        label: "_LBL_CHANGE_PASSWORD_OLD_",
        translate: true,
        placeholder: "_LBL_CHANGE_PASSWORD_OLD_",
        view: "h",
        required: true,
        maxlength: this.conf.max_length,
        classesHorizontal: CLASSES_HORIZONTAL,
      };
    },

    optionsPasswordNew1() {
      return {
        type: "password",
        id: "password_new1",
        label: "_LBL_CHANGE_PASSWORD_NEW1_",
        translate: true,
        placeholder: "_LBL_CHANGE_PASSWORD_NEW1_",
        view: "h",
        required: true,
        maxlength: this.conf.max_length,
        classesHorizontal: CLASSES_HORIZONTAL,
      };
    },

    optionsPasswordNew2() {
      return {
        type: "password",
        id: "password_new2",
        label: "_LBL_CHANGE_PASSWORD_NEW2_",
        translate: true,
        placeholder: "_LBL_CHANGE_PASSWORD_NEW2_",
        view: "h",
        required: true,
        maxlength: this.conf.max_length,
        classesHorizontal: CLASSES_HORIZONTAL,
      };
    },

    urlSave() {
      return `profil/${ this.user.pk }/passwort_aendern/`;
    },
  },
  created() {
    this.checkSyConfig();
    this.refreshUser();
  },
  methods: {
    checkSyConfig() {
      const PASSWORD_RULES = this.getSyConfigsValue("password_rules") || {};
      this.conf = {
        min_length: PASSWORD_RULES.min_length,
        max_length: PASSWORD_RULES.max_length,
        allowed_chars: PASSWORD_RULES.allowed_chars.split("").join(" "),
      };
    },

    refreshUser() {
      this.$AuthService.refreshUser().then(
        response => {
          this.user = response;
          this.statusLoading = false;
        }
      );
    },

    onError(errors) {
      if (errors.data) {
        this.errors = errors.data;
      } else {
        this.errors = errors;
      }
    },

    savePassword() {
      if (this.statusSubmitting) {
        return;
      }
      this.deleteErrors();
      this.statusSubmitting = true;
      this.postHttp({
        url: this.urlSave,
        data: this.model,
      }).then(
        () => {
          this.addNotification({ text: "_MSG_CHANGE_PASSWORD_SUCCESS_" });
          this.$AuthService.refreshUser().then(
            () => {
              window.location.replace("/");
            }
          );
        },
        error => {
          this.onError(error);
        }
      ).then(
        () => this.statusSubmitting = false
      );
    },

    deleteErrors() {
      this.errors = {};
    },
  },
};
