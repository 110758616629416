import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import FormElementReadOnly from "../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  initModelFromList,
  toFormElementFromParameter,
} from "../../../../../global/functions/mappingForParameterToFormElement";

// @vue/component
export default {
  name: "SachberichtvorlageGeschaeftregel",
  components: {
    AngularLink,
    FormElementReadOnly,
    PuxButton,
    PuxTranslate,
  },
  props: {
    regel: {
      type: Object,
      required: true,
    },
    regelCount: {
      type: Number,
      required: true,
    },
    parentHtmlId: {
      type: String,
      required: true,
    },
    regelIndex: {
      type: Number,
      required: true,
    },
    isChanging: {
      type: Boolean,
      required: true,
    },
    regelMoveUpButtonIdPrefix: {
      type: String,
      required: true,
    },
    regelMoveDownButtonIdPrefix: {
      type: String,
      required: true,
    },
  },
  emits: [
    "moveDownSachberichtregel",
    "moveUpSachberichtregel",
    "removeSachberichtregel",
    "toggleRegel",
  ],
  data() {
    return {
      loading: false,
      listConfig: [],
      modelConfig: undefined,
    };
  },
  computed: {
    toggleShowDetailsButtonLabel() {
      return this.regel.showDetails ? "_BTN_LBL_SACHBERICHTVORLAGE_DETAILS_REGEL_DETAIL_HIDE_" : "_BTN_LBL_SACHBERICHTVORLAGE_DETAILS_REGEL_DETAIL_SHOW_";
    },

    moveUpDisabled() {
      return this.regelIndex === 0;
    },

    moveDownDisabled() {
      return this.regelIndex === this.regelCount - 1;
    },

    deleteButtonHtmlId() {
      return `delete_regel_button_${ this.regelIndex }`;
    },

    showDetailsButtonHtmlId() {
      return `toggle_show_regel_details_${ this.regelIndex }`;
    },

    moveUpButtonHtmlId() {
      return `${ this.regelMoveUpButtonIdPrefix }${ this.regelIndex }`;
    },

    moveDownButtonHtmlId() {
      return `${ this.regelMoveDownButtonIdPrefix }${ this.regelIndex }`;
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.setList();
      this.setModelFromList();
    },

    toggleShowDetails() {
      this.$emit("toggleRegel", { regelIndex: this.regelIndex });
    },

    sachberichtregelEntfernen() {
      this.$emit("removeSachberichtregel", {
        regelIndex: this.regelIndex,
        deleteButtonHtmlId: this.deleteButtonHtmlId,
      });
    },

    sachberichtregelMoveUp() {
      if (!this.moveUpDisabled) {
        this.$emit("moveUpSachberichtregel", {
          regelIndex: this.regelIndex,
        });
      }
    },

    sachberichtregelMoveDown() {
      if (!this.moveDownDisabled) {
        this.$emit("moveDownSachberichtregel", {
          regelIndex: this.regelIndex,
        });
      }
    },

    setList() {
      this.listConfig = toFormElementFromParameter({
        obj: this.regel.sbr_argument,
        showLabel: true,
        staticOptionsForType: {
          dyn_tabelle_objektkatalog_data: {
            dependency: "spalten",
          },
          dyn_tabelle_spaltenauswahl_radio: {
            dependency: "spalten",
          },
        },
      }).list;
    },

    setModelFromList() {
      this.modelConfig = initModelFromList({ list: this.listConfig });
    },
  },
};
