import {
  computed,
} from "vue";

import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import {
  EventBus,
} from "../../../../global/functions/event-bus";

export default function AntragDocumentCreateAPI({ antrag, rubricsFilteredByKey }) {
  const isSubsequentFilingPossible = computed(() => {
    return antrag.value.eingereicht && rubricsFilteredByKey.value.step_anlagen;
  });
  const {
    checkPermissionsSync,
  } = PermissionAPI();
  const hasWFPermissionDokumentCreate = computed(() => {
    return checkPermissionsSync({
      perm: "dokument.create",
      permArray: antrag.value.user_permissions,
    });
  });
  const goToModulAnlagen = () => {
    EventBus.$emit("scrollToContextPanel", { id: "step_anlagen" });
  };

  return {
    goToModulAnlagen,
    hasWFPermissionDokumentCreate,
    isSubsequentFilingPossible,
  };
}
