"use strict";

import BaseModel from "dias/core/models/baseModel";

class Astorgrolle extends BaseModel {
  constructor() {
    super("astorgrolle");
  }

  configRestangular(RestangularConfigurer) {
    RestangularConfigurer.setDefaultHttpFields({ cache: true });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.getChoices = params => {
      const deferred = this.$q.defer();
      const data = [];
      params = params || {};
      params.fields = ["aro_id", "aro_kbez", "aro_bez"];
      collection.getList(params).then(result => {
        angular.forEach(result, item => {
          data.push({ value: item.aro_id, label: `${ item.aro_kbez } - ${ item.aro_bez }` });
        });
        deferred.resolve(data);
      });
      return deferred.promise;
    };

    return collection;
  }
}

export default Astorgrolle;
