import FaqKeywordsFilterMixin from "../../Faq/FaqDetails/FaqKeywordsFilterMixin";

import { createNamespacedHelpers } from "vuex";
const {
  mapGetters,
} = createNamespacedHelpers("faq");
import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  mixins: [
    FaqKeywordsFilterMixin,
  ],
  computed: {
    count() {
      if (this.rubricPk) {
        const RUBRIC = this.GET_RUBRICS[this.rubricPk];
        if (!RUBRIC) {
          return 0;
        }
        if (!this.modelKeywords.length) {
          return RUBRIC.faq_eintraege.length;
        }
        return this.getCountEintraegeFilteredWithKeywords(RUBRIC.faq_eintraege);
      }
      if (!this.modelKeywords.length) {
        return this.GET_ALL_FAQ_EINTRAEGE.length;
      }
      return this.getCountEintraegeFilteredWithKeywords(this.GET_ALL_FAQ_EINTRAEGE);
    },

    componentLocal() {
      return this.isLinkActive ?
        "angular-link" :
        "div";
    },

    attributesLocal() {
      if (this.isLinkActive) {
        return this.linkAttributesLocal;
      }
      return {};
    },

    linkAttributesLocal() {
      return {
        "aria-labelledby": this.ariaLabelledbyForBadgeLink,
        sref: this.linkSref,
        options: {
          rubric: this.rubricPk,
          keyword: this.modelKeywords,
          media_type: this.GET_MODEL_MEDIA_TYPES,
          search: this.GET_MODEL_SEARCH,
        },
      };
    },

    isLinkActive() {
      return this.count !== 0;
    },

    modelKeywordsGroups() {
      return this.setModelKeywordsGroups(this.modelKeywords, this.GET_KEYWORDS_MAP_BY_PK);
    },

    ariaLabelledbyForBadgeLink() {
      return undefined;
    },

    ...mapGetters([
      "GET_ALL_FAQ_EINTRAEGE",
      "GET_RUBRICS",
      "GET_MODEL_MEDIA_TYPES",
      "GET_MODEL_SEARCH",
      "GET_KEYWORDS_MAP_BY_PK",
    ]),
  },
  methods: {
    getCountEintraegeFilteredWithKeywords(eintraege) {
      let countFilteredEintraege = 0;
      forEach(eintraege, item => {
        if (this.hasFaqItemKeywords(item)) {
          countFilteredEintraege++;
        }
      });
      return countFilteredEintraege;
    },
  },
};
