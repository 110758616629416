import {
  cloneDeep,
  forEach,
} from "lodash-es";
import moment from "moment";

// @vue/component
export default {
  props: {
    perioden: {
      type: Array,
      required: true,
    },
  },
  computed: {
    durchfuehrungszeitraumVon() {
      return this.perioden[0].von_aktuell;
    },

    durchfuehrungszeitraumBis() {
      const LENGTH = this.perioden.length;
      return this.perioden[LENGTH - 1].bis_aktuell;
    },
  },
  methods: {
    getModePerioden() {
      const MODEL_PERIODEN = [];
      forEach(this.perioden, period => {
        const INFO = cloneDeep(period);
        INFO.teil = undefined;
        MODEL_PERIODEN.push({
          periode: period.pk,
          von: period.von_aktuell,
          bis: period.bis_aktuell,
          gehalt: 0,
          an_bruttogehalt: 0,
          ag_bruttogehalt: 0,
          info: INFO,
        });
      });
      return MODEL_PERIODEN;
    },

    // Bild 1
    // Zeitraum   von1  bis1
    //              O-----O--|----------------------|
    // Period               von                    bis

    // Bild 2
    // Zeitraum   von1                              bis1
    //              O----|----------------------|----O
    // Period           von                    bis

    // Bild 3
    // Zeitraum   von1               bis1
    //              O----|------------X---------|
    // Period           von                    bis

    // Bild 4
    // Zeitraum                                     von1
    //                   |----------------------|----O
    // Period           von                    bis

    // Bild 5
    // Zeitraum              von1                  bis1
    //                   |----O-----------------|---O
    // Period           von                    bis

    // Bild 6
    // Zeitraum              von1        bis1
    //                   |----O-----------X-----|
    // Period           von                    bis

    splitOnePeriod({ period, von, bis }) {
      const PERIOD = cloneDeep(period);
      const PERIOD1 = cloneDeep(period);
      if (period.von >= von) {
        if (period.von >= bis || // Bild 1
          period.bis <= bis) { // Bild 2
          return [PERIOD];
        }
        if (period.bis > bis) { // Bild 3
          PERIOD.bis = bis;
          PERIOD1.von = moment(bis).add(1, "days").format("YYYY-MM-DD");
          return [PERIOD, PERIOD1];
        }
      }
      if (period.bis < von) { // Bild 4
        return [PERIOD];
      }
      if (period.bis <= bis) { // Bild 5
        PERIOD.bis = moment(von).add(-1, "days").format("YYYY-MM-DD");
        PERIOD1.von = von;
        return [PERIOD, PERIOD1];
      }
      if (period.bis > bis) { // Bild 6
        const PERIOD2 = cloneDeep(period);
        PERIOD.bis = moment(von).add(-1, "days").format("YYYY-MM-DD");
        PERIOD1.von = von;
        PERIOD1.bis = bis;
        PERIOD2.von = moment(bis).add(1, "days").format("YYYY-MM-DD");
        return [PERIOD, PERIOD1, PERIOD2];
      }
    },
  },
};
