import Alert from "../../../../global/components/Alert/Alert.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxDropdown from "../../../../global/components/PuxDropdown/PuxDropdown.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxPopover from "../../../../global/components/PuxPopover/PuxPopover.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import PuxTooltip from "../../../../global/components/PuxTooltip/PuxTooltip.vue";
import RegelvergleichBox from "./RegelvergleichBox/RegelvergleichBox.vue";
import RegelvergleichKommentarPopover from "./RegelvergleichKommentarPopover/RegelvergleichKommentarPopover.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";

import ModuleMixin from "../ModuleMixin";

import {
  gettext,
  replaceText,
} from "../../../../global/functions/utils";

import {
  assign,
  cloneDeep,
  filter,
  find,
  forEach,
  get,
  isNil,
  keyBy,
  map,
  orderBy,
  pickBy,
  size,
  split,
} from "lodash-es";

// @vue/component
export default {
  name: "Regelvergleich",
  components: {
    Alert,
    FormElement,
    FormElementReadOnly,
    PuxButton,
    PuxDropdown,
    PuxIcon,
    PuxPopover,
    PuxTooltip,
    PuxTranslate,
    RegelvergleichBox,
    RegelvergleichKommentarPopover,
    SnapshotIcon,
    SnapshotModule,
  },
  mixins: [
    ModuleMixin,
    NotificationMixin,
  ],
  data: () => {
    return {
      loading: false,
      choices: [],
      choicesMap: {},
      selected: {
        left: undefined,
        right: undefined
      },
      scrollStatus: {
        left: false,
        right: false
      },
      gesamtKommentarChanged: false,
      showGesamtKommentar: false,
      currentVglKommentar: undefined,
      currentVglKommentarChanged: false,
      showCurrentVglKommentar: false,
      scrollLinked: false,
      scrollEvent: undefined,
      swapCls: "",
      focusCls: "",
    };
  },
  computed: {
    labelHeader() {
      return this.modelParameter.titel;
    },

    info() {
      return this.modelParameter.info;
    },

    selectedOptions() {
      return {
        left: {
          type: "select",
          id: `${ this.regelId }_left_select`,
          htmlid: `${ this.regelId }_left_regel_select`,
          label: "_LBL_GR_REGELVERGLEICH_SELECT_LEFT_",
          view: "v",
          keyLabel: "label",
          keyId: "key",
          keyGroup: "group",
          deselect: false,
          data: filter(this.choices, el => el.key !== get(this.selected, "right.key")),
          change: ({ item }) => this.onSelected({ key: "left", item })
        },
        right: {
          type: "select",
          id: `${ this.regelId }_right_select`,
          htmlid: `${ this.regelId }_right_regel_select`,
          label: "_LBL_GR_REGELVERGLEICH_SELECT_RIGHT_",
          view: "v",
          keyLabel: "label",
          keyId: "key",
          keyGroup: "group",
          deselect: false,
          data: filter(this.choices, el => el.key !== get(this.selected, "left.key")),
          change: ({ item }) => this.onSelected({ key: "right", item })
        }
      };
    },

    selectedKeys() {
      return {
        left: get(this.selected, "left.key"),
        right: get(this.selected, "right.key"),
      };
    },


    iconClsButtonRefresh() {
      return this.statusReloading ? "d-inline-block rotate" : "";
    },

    gesamtKommentarLabel() {
      return get(this.modelParameter, "gesamt_kommentar_titel");
    },

    vglKommentarLabel() {
      return get(this.modelParameter, "vergleich_kommentar_titel");
    },

    existingVglKommentarDropdownList() {
      return map(this.vglKommentareList, el => {
        return {
          key: el.key,
          label: el.label
        };
      });
    },

    isExistingVglKommentarDropdownDisabled() {
      return size(this.existingVglKommentarDropdownList) === 0;
    },

    existingVglKommentarDropdownTitle() {
      return this.isExistingVglKommentarDropdownDisabled
        ? gettext("_TXT_GR_REGELVERGLEICH_VGL_KOMMENTAR_EXISTING_DROPDOWN_DISABLED_TITLE_")
        : gettext("_TXT_GR_REGELVERGLEICH_VGL_KOMMENTAR_EXISTING_DROPDOWN_TITLE_");
    },

    bothSelected() {
      return !isNil(this.selected.left) && !isNil(this.selected.right);
    },

    showSelectionSwap() {
      return this.bothSelected && size(this.focusCls) === 0;
    },

    showScrollLink() {
      return this.scrollStatus.left && this.scrollStatus.right;
    },

    scrollLinkedTitle() {
      return this.scrollLinked ? "_TXT_GR_REGELVERGLEICH_SCROLL_LINKED_AKTIV_TITLE_" : "_TXT_GR_REGELVERGLEICH_SCROLL_LINKED_INAKTIV_TITLE_";
    },

    statusDiff() {
      return false;
    },

    gesamtKommentarActive() {
      return !isNil(get(this.modelParameter, "gesamt_kommentar_titel"));
    },

    vglKommentareList() {
      return orderBy(
        filter(
          get(this.model, "vergleich_kommentare", []),
          el => !isNil(el.kommentar) || size(el.extra) > 0
        ),
        ["label"]
      );
    },

    vglKommentareActive() {
      return !isNil(get(this.modelParameter, "vergleich_kommentar_titel"));
    },

    showCurrentVglKommentarBtn() {
      return this.vglKommentareActive && !isNil(this.currentVglKommentar);
    },

    hasExistingVglKommentare() {
      return size(this.vglKommentareList) > 0;
    },

    gesamtKommentarToggleClass() {
      if (this.gesamtKommentarChanged) {
        return "btn-warning";
      } else if (!isNil(this.model.gesamt_kommentar)) {
        return "btn-primary";
      }
      return "btn-default";
    },

    gesamtKommentarToggleTitle() {
      if (this.gesamtKommentarChanged) {
        return "_BTN_GR_REGELVERGLEICH_GESAMT_KOMMENTAR_CHANGED_TOGGLE_TITLE_";
      } else if (!isNil(this.model.gesamt_kommentar)) {
        return "_BTN_GR_REGELVERGLEICH_GESAMT_KOMMENTAR_TOGGLE_TITLE_";
      }
      return "_BTN_GR_REGELVERGLEICH_GESAMT_KOMMENTAR_EMPTY_TOGGLE_TITLE_";
    },

    currentVglKommentarToggleClass() {
      if (this.currentVglKommentarChanged) {
        return "btn-warning";
      } else if (!isNil(get(this.currentVglKommentar, "kommentar"))) {
        return "btn-primary";
      }
      return "btn-default";
    },

    currentVglKommentarToggleTitle() {
      if (this.currentVglKommentarChanged) {
        return "_BTN_GR_REGELVERGLEICH_VGL_KOMMENTAR_CHANGED_TOGGLE_TITLE_";
      } else if (!isNil(get(this.currentVglKommentar, "kommentar"))) {
        return "_BTN_GR_REGELVERGLEICH_VGL_KOMMENTAR_TOGGLE_TITLE_";
      }
      return "_BTN_GR_REGELVERGLEICH_VGL_KOMMENTAR_EMPTY_TOGGLE_TITLE_";
    },

    currentVglKommentarKey() {
      return get(this.currentVglKommentar, "key");
    },

    focusLeftActive() {
      return this.focusCls === "focus-left";
    },

    focusLeftTitle() {
      return this.focusLeftActive ? "_TXT_GR_REGELVERGLEICH_FOCUS_LEFT_AKTIV_TITLE_" : "_TXT_GR_REGELVERGLEICH_FOCUS_LEFT_INAKTIV_TITLE_";
    },

    focusRightActive() {
      return this.focusCls === "focus-right";
    },

    focusRightTitle() {
      return this.focusRightActive ? "_TXT_GR_REGELVERGLEICH_FOCUS_RIGHT_AKTIV_TITLE_" : "_TXT_GR_REGELVERGLEICH_FOCUS_RIGHT_INAKTIV_TITLE_";
    },

    isModuleEditable() {
      return false;
    },
  },
  methods: {
    prepareModel() {
      this.model = cloneDeep(this.module.data);
      this.choices = orderBy(get(this.model, "vergleich_objekte", []), ["group", "label", "key"]);
      this.choicesMap = keyBy(this.choices, el => el.key);
      forEach(this.selected, (obj, side) => {
        this.onSelected({ item: this.choicesMap[get(obj, "key")], key: side });
      });
      this.model.vergleich_kommentare = get(this.model, "vergleich_kommentare", []);
      forEach(this.model.vergleich_kommentare, el => this.enrichVglKommentar(el));
    },
    updateCurrentVglKommentar() {
      if (isNil(this.selected.left) || isNil(this.selected.right)) {
        return;
      }
      this.currentVglKommentar = find(this.vglKommentareList, el => {
        return el.keys.indexOf(this.selected.left.key) !== -1 && el.keys.indexOf(this.selected.right.key) !== -1;
      });
      if (isNil(this.currentVglKommentar)) {
        this.currentVglKommentar = {
          keys: [this.selected.left.key, this.selected.right.key],
          kommentar: undefined,
          extra: {}
        };
        this.enrichVglKommentar(this.currentVglKommentar);
      }
    },
    enrichVglKommentar(vglEl) {
      const OBJS = orderBy(map(vglEl.keys, key => this.choicesMap[key]));
      vglEl.label = replaceText({
        text: gettext("_LBL_GR_REGELVERGLEICH_VGL_KOMMENTAR_{{label1}}_{{label2}}_"),
        object: {
          label1: OBJS[0].label,
          label2: OBJS[1].label
        }
      });
      vglEl.key = `${ OBJS[0].key }____${ OBJS[1].key }`;
    },

    getVglKommentarListClass(vglEl) {
      if (vglEl.key == get(this.currentVglKommentar, "key")) {
        return "active";
      }
    },

    onSelected({ key, item }) {
      this.selected[key] = item;
      this.swapCls = "";
      this.updateCurrentVglKommentar();
    },

    updateScrollEvent($event) {
      this.scrollEvent = $event;
    },

    updateScrollStatus({ key, status }) {
      this.scrollStatus[key] = status;
    },

    swapSelected() {
      const LEFT = this.selected.left;
      this.selected.left = this.selected.right;
      this.selected.right = LEFT;
      this.swapCls = this.swapCls === "icon-turn-around" ? "icon-turn-around-reverse" : "icon-turn-around";
    },

    focusLeft() {
      this.focusCls = this.focusCls === "focus-left" ? "" : "focus-left";
    },

    focusRight() {
      this.focusCls = this.focusCls === "focus-right" ? "" : "focus-right";
    },

    toggleScrollLink() {
      this.scrollLinked = !this.scrollLinked;
    },

    toggleShowGesamtKommentar() {
      this.showGesamtKommentar = !this.showGesamtKommentar;
    },

    toggleShowCurrentVglKommentar() {
      this.showCurrentVglKommentar = !this.showCurrentVglKommentar;
    },

    selectVglKommentar({ model }) {
      if (isNil(model)) {
        return;
      }
      const keys = split(model, "____");
      if (size(keys) !== 2) {
        return;
      }
      this.onSelected({ key: "left", item: this.choicesMap[keys[0]] });
      this.onSelected({ key: "right", item: this.choicesMap[keys[1]] });
    },

    biuldSaveData() {
      return {
        vergleich_kommentare: cloneDeep(this.vglKommentareList) || [],
        gesamt_kommentar: this.model.gesamt_kommentar
      };
    },

    changeGesamtKommentar({ model }) {
      const NEW = model || undefined;
      const OLD = get(this.model, "gesamt_kommentar") || undefined;
      this.gesamtKommentarChanged = OLD !== NEW;
    },

    saveGesamtKommentar({ model }) {
      const MODEL = this.biuldSaveData();
      MODEL.gesamt_kommentar = model;
      return this.save({ model: MODEL }).then(
        () => {
          this.toggleShowGesamtKommentar();
          this.model.gesamt_kommentar = model;
          this.gesamtKommentarChanged = false;
          this.addNotification({
            text: "_MSG_GR_REGELVERGLEICH_GESAMT_KOMMENTAR_SAVE_SUCCESS_",
            type: "success"
          });
        },
        () => {
          this.addNotification({
            text: "_ERR_GR_REGELVERGLEICH_GESAMT_KOMMENTAR_SAVE_",
            type: "danger"
          });
        }
      ).finally(() => this.loading = false);
    },

    changeCurrentVglKommentar({ model }) {
      const NEW = model || undefined;
      const OLD = get(this.currentVglKommentar, "kommentar") || undefined;
      this.currentVglKommentarChanged = OLD !== NEW;
    },

    saveCurrentVglKommentarData({ model }) {
      const CURRENT_VGL = cloneDeep(this.currentVglKommentar);
      CURRENT_VGL.kommentar = model;
      return this.saveCurrentVglKommentar(CURRENT_VGL).then(
        () => this.toggleShowCurrentVglKommentar()
      );
    },

    saveCurrentVglKommentarExtra({ key, model }) {
      const CURRENT_VGL = cloneDeep(this.currentVglKommentar);
      CURRENT_VGL.extra = CURRENT_VGL.extra || {};
      CURRENT_VGL.extra[key] = model;
      CURRENT_VGL.extra = pickBy(CURRENT_VGL.extra, el => size(el) > 0);
      return this.saveCurrentVglKommentar(CURRENT_VGL);
    },

    saveCurrentVglKommentar(vglKommentarObj) {
      const MODEL = this.biuldSaveData();
      const CURRENT_VGL = cloneDeep(vglKommentarObj);
      if (!isNil(this.currentVglKommentar)) {
        const FOUND = find(MODEL.vergleich_kommentare, el => el.key === this.currentVglKommentar.key);
        if (isNil(FOUND)) {
          MODEL.vergleich_kommentare.push(CURRENT_VGL);
        } else {
          assign(FOUND, CURRENT_VGL);
        }
      }
      MODEL.vergleich_kommentare = filter(MODEL.vergleich_kommentare, el => el.kommentar || size(el.extra) > 0);
      forEach(MODEL.vergleich_kommentare, el => {
        delete el.key;
        delete el.label;
      });
      return this.save({ model: MODEL }).then(
        () => {
          this.currentVglKommentar = CURRENT_VGL;
          this.currentVglKommentarChanged = false;
          this.addNotification({
            text: "_MSG_GR_REGELVERGLEICH_VGL_KOMMENTAR_SAVE_SUCCESS_",
            type: "success"
          });
        },
        () => {
          this.addNotification({
            text: "_ERR_GR_REGELVERGLEICH_VGL_KOMMENTAR_SAVE_",
            type: "danger"
          });
        }
      ).finally(() => this.loading = false);
    },
  }
};
