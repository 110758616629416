"use strict";

import angular from "angular";

import zeroNumberFilter from "./zeroNumber.pipe";


const filterModule = angular.module("zeroNumber.pipes", [])

  .filter("zeroNumber", zeroNumberFilter);

export default filterModule;
