"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";
import template from "./config.jade";
import templateRo from "./readonly.jade";
import controller from "./controller";
import controllerRo from "./readonly.controller";


const dynZahlen = angular.module("dias.dynamische_zahlen.parameter", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("dynamische_zahlen", "parametermodul-dyn-zahlen");
  })
  .component("parametermodulDynZahlen", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulDynZahlenRo", {
    bindings: {
      parameter: "=",
      key: "@"
    },
    template: templateRo(),
    controller: controllerRo,
    controllerAs: "vm",
  });
export default dynZahlen;
