export function DomEventsAPI(props, { emit }) {
  const domEvents = {
    click: arg => emit("click", arg),
    mouseup: arg => emit("mouseup", arg),
    mousemove: arg => emit("mousemove", arg),
    mousedown: arg => emit("mousedown", arg),
  };

  return {
    domEvents
  };
}
