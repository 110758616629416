import DashboardAktionAntragsliste from "./DashboardAktionAntragsliste/DashboardAktionAntragsliste.vue";
import DashboardAntragBewilligt from "./DashboardAntragBewilligt/DashboardAntragBewilligt.vue";
import DashboardAntragErstellen from "./DashboardAntragErstellen/DashboardAntragErstellen.vue";
import DashboardAufgabenFrist from "./DashboardAufgabenFrist/DashboardAufgabenFrist.vue";
import DashboardBaldZuErledigen from "./DashboardBaldZuErledigen/DashboardBaldZuErledigen.vue";
import DashboardBalkendiagramm from "./DashboardBalkendiagramm/DashboardBalkendiagramm.vue";
import DashboardEinfacherZaehler from "./DashboardEinfacherZaehler/DashboardEinfacherZaehler.vue";
import DashboardFaqFilter from "./DashboardFaqFilter/DashboardFaqFilter.vue";
import DashboardFaqAuswahl from "./DashboardFaqAuswahl/DashboardFaqAuswahl.vue";
import DashboardFaqStationen from "./DashboardFaqStationen/DashboardFaqStationen.vue";
import DashboardLink from "./DashboardLink/DashboardLink.vue";
import DashboardModelZaehler from "./DashboardModelZaehler/DashboardModelZaehler.vue";
import DashboardNaechsteSitzung from "./DashboardNaechsteSitzung/DashboardNaechsteSitzung.vue";
import DashboardNeuestenNachrichten from "./DashboardNeuestenNachrichten/DashboardNeuestenNachrichten.vue";
import DashboardObjectTracking from "./DashboardObjectTracking/DashboardObjectTracking.vue";
import DashboardPlaceholder from "./DashboardPlaceholder/DashboardPlaceholder.vue";
import DashboardSitzungInfo from "./DashboardSitzungInfo/DashboardSitzungInfo.vue";
import DashboardUnterlagenNachreichen from "./DashboardUnterlagenNachreichen/DashboardUnterlagenNachreichen.vue";
import DashboardWorkflowInfo from "./DashboardWorkflowInfo/DashboardWorkflowInfo.vue";
import DashboardWorkflowStatusgruppen from "./DashboardWorkflowStatusgruppen/DashboardWorkflowStatusgruppen.vue";

export default {
  DashboardAktionAntragsliste,
  DashboardAntragBewilligt,
  DashboardAntragErstellen,
  DashboardAufgabenFrist,
  DashboardBaldZuErledigen,
  DashboardBalkendiagramm,
  DashboardEinfacherZaehler,
  DashboardFaqFilter,
  DashboardFaqAuswahl,
  DashboardFaqStationen,
  DashboardLink,
  DashboardModelZaehler,
  DashboardNaechsteSitzung,
  DashboardNeuestenNachrichten,
  DashboardObjectTracking,
  DashboardPlaceholder,
  DashboardSitzungInfo,
  DashboardUnterlagenNachreichen,
  DashboardWorkflowInfo,
  DashboardWorkflowStatusgruppen,
};
