import ActionsPageMain from "../../../global/components/ActionsPageMain/ActionsPageMain.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import PanelComponents from "./PanelComponents";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "BudgetDetails",
  components: {
    ActionsPageMain,
    ContextPanel,
    PuxPageDetails,
    PuxTranslate,
    ...PanelComponents,
  },
  mixins: [
    HttpMixin,
  ],
  data() {
    return {
      loading: true,
      budget: undefined,
      panels: [
        {
          label: "_TXT_BUDGET_DETAILS_UEBERSICHT_PANEL_NAME_",
          icon: "glyphicon-list",
          active: true,
          pk: "uebersicht",
          components: [
            {
              name: "Basisdaten",
              pk: "basisdaten",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.loadBudget();
  },
  methods: {
    loadBudget() {
      this.getHttp({ url: `budgets/${ this.$stateParams.id }/` }).then(
        response => {
          this.budget = response;
        }
      ).then(
        () => this.loading = false
      );
    },

    updateBudget({ budget }) {
      this.budget = budget;
    },
  },
};
