"use strict";

import BaseModel from "dias/core/models/baseModel";


class Sitzungsmodul extends BaseModel {
  constructor() {
    super("module");
  }

  extendModel(model) {
    model = super.extendModel(model);

    // set PK for restangular
    if (angular.isObject(model.modul)) {
      model[this.pk] = model.modul.pk;
    }

    model.getParameter = () => {
      const parameter = {};
      for (const key in model.modul.stm_parameterin) {
        parameter[key] = model.modul.stm_parameterin[key].wert;
      }
      return parameter;
    };

    model.saveUserInput = input => {
      return model.customPUT(input);
    };

    return model;
  }
}

export default Sitzungsmodul;
