"use strict";

import "restangular";
import angular from "angular";
import CoreModels from "dias/core/models";

import Dokumentarten from "./dokumentarten.service.js";
import Dokumente from "./dokumente.service.js";
import Dokumentpruefstatus from "./dokumentpruefstatus.service.js";
import Foerderdokument from "./foerderdokument.service.js";
import FoerderdokumentAktiv from "./public.foerderdokument.service.js";
import KDokart from "./kdokart.service.js";
import TempDokument from "./temp.dokument.service";

const modelsModule = angular.module("dokumente.models", [
  "restangular",
  CoreModels.name
])

  .provider("Dokumentarten", Dokumentarten)
  .provider("Dokumente", Dokumente)
  .provider("Dokumentpruefstatus", Dokumentpruefstatus)
  .provider("Foerderdokument", Foerderdokument)
  .provider("FoerderdokumentAktiv", FoerderdokumentAktiv)
  .provider("KDokart", KDokart)
  .provider("TempDokument", TempDokument)
  .factory("DokumenteFactory", function() {
  // private
    let value = "";
  // public
    return {
      getValue: function() {
        return value;
      },
      setValue: function(val) {
        value = val;
      }
    };
  })
;

export default modelsModule;
