import ModulObjektlisteEntscheidungElementList
  from "../../../Generics/ModulObjektlisteEntscheidungElementList/ModulObjektlisteEntscheidungElementList.vue";
import ModuleMixin from "../ModuleMixin";

// @vue/component
export default {
  name: "ObjektlisteEntscheidung",
  components: {
    ModulObjektlisteEntscheidungElementList,
  },
  mixins: [
    ModuleMixin,
  ],
  computed: {
    labelHeader() {
      if (this.modelParameter.titel) {
        return this.modelParameter.titel;
      }
      return "_TXT_GR_OBJEKTLISTE_ENTSCHEIDUNG_";
    },

    isModuleEditable() {
      return false;
    },
  },
};
