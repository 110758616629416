<div><h3>Modal-Confirm</h3><h4>Beispliel 1</h4><pux-button :id="'styleguidemodalconfirm_openconfirm'" :test-class="'test_open_confirm'" :type-class="'primary'" :text="'Dokument löschen'" type="button" @click="openConfirm"></pux-button><modal v-if="confirmShow" :confirm-options="confirmOptions"></modal><div v-highlight=""><pre>JS<code>import Modal from ".../global/components/Modal/Modal.vue";

export default {
  ...
  components: [
    Modal,
  ],
data() {
  return {
    confirmShow: undefined,
    confirmOptions: undefined,
    ...
  }
},
methods: {
  openConfirm() {
    this.confirmOptions = {
      okClass: "btn btn-primary",
      title: "Dokument löschen",
      msg: "&lt;p&gt;Sind Sie sicher?&lt;/p&gt;",
      okLabel: "Löschen",
      cancelLabel: "Abbrechen",
      okCallback: () => {
        console.log("Dokument wird gelöscht");
        this.closeConfirm();
      },
      cancelCallback: this.closeConfirm,
      loading: false,
    };
    this.confirmShow = true;
  },

  closeConfirm() {
    this.confirmShow = false;
  },
  ...
},
  ...
};</code></pre><pre>PUG<code>pux-button(
  :id="'styleguidemodalconfirm_openconfirm_prepug'"
  :type="'button'"
  :type-class="'primary'"
  :test-class="'test_open'"
  @click="openConfirm"
  :text="'_BTN_DELETE_'"
)
modal(
  v-if="confirmShow"
  :confirm-options="confirmOptions"
)


</code></pre></div><h4>Props</h4><table class="table table-striped table-bordered"><thead><tr><th style="width: 150px;">Name</th><th>Beschreibung</th><th>Typ</th></tr></thead><tbody><tr v-for="prop in propsPossible" :key="prop.name"><td>{{ prop.name }}</td><td>{{ prop.description }}</td><td>{{ prop.type }}</td></tr></tbody></table><h4>confirmOptions</h4><table class="table table-striped table-bordered"><thead><tr><th style="width: 150px;">Name</th><th>Beschreibung</th><th>Standard</th><th>Typ</th><th>Beispiel</th></tr></thead><tbody><tr v-for="option in confirmOptionsTable" :key="option.name"><td>{{ option.name }}</td><td>{{ option.description }}</td><td>{{ option.default }}</td><td>{{ option.type }}</td><td>{{ option.example }}</td></tr></tbody></table></div>