import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";

import { initModelFromList } from "../../../../global/functions/mappingForParameterToFormElement";

import {
  cloneDeep,
} from "lodash-es";


// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    PuxButton,
    PuxTranslate
  },
  mixins: [
    HttpMixin,
    NotificationMixin
  ],
  props: {
    aufgabePk: {
      type: String,
      default: undefined,
    },
    model: {
      type: Object,
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      default: () => { },
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modelLocal: undefined,
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    getHeader() {
      return this.model
        ? "_TXT_WORKFLOWFRIST_LIST_IN_KAUFGABEN_MODAL_UPDATE_TITLE_"
        : "_TXT_WORKFLOWFRIST_LIST_IN_KAUFGABENT_MODAL_CREATE_TITLE_";
    },
    options() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "workflow",
            label: "_LBL_WORKFLOWFRIST_WORKFLOW_",
            showLabel: true,
            prio: 1,
            katalog: "workflow",
            type: "select",
            view: "v",
            required: false,
            displaymode: "select2",
            deselect: true,
            url: "katalog/?key=workflow",
            apiSaveId: "katalog_workflow",
            keyId: "pk",
            keyLabel: "bez",
            search: true,
            searchList: [
              "bez"
            ]
          },
          {
            id: "wfr_mahnstufe",
            label: "_LBL_WORKFLOWFRIST_WFR_MAHNSTUFE_",
            showLabel: true,
            type: "integer",
            view: "v",
            prio: 3,
            required: true,
          },
          {
            id: "wfr_frist",
            label: "_LBL_WORKFLOWFRIST_WFR_FRIST_",
            showLabel: true,
            type: "integer",
            view: "v",
            prio: 4,
            default: 0,
            minValue: null,
            required: true,
          },
          {
            id: "terminberechnung",
            label: "_LBL_WORKFLOWFRIST_TERMINBERECHNUNG_",
            showLabel: true,
            type: "select",
            view: "v",
            prio: 7,
            default: "dfaaa695-ba4e-4e29-8dfb-c9c16766f0eb",
            katalog: "syterminberechnung",
            required: true,
            displaymode: "select2",
            deselect: true,
            url: "katalog/?key=syterminberechnung",
            apiSaveId: "katalog_syterminberechnung4",
            keyId: "pk",
            keyLabel: "bez",
            search: true,
            searchList: [
              "bez"
            ]
          },
          {
            id: "wfr_mahntext",
            label: "_LBL_WORKFLOWFRIST_WFR_MAHNTEXT_",
            showLabel: true,
            type: "textarea",
            view: "v",
            prio: 6,
            required: false,
          },
          {
            id: "workflowfolge",
            label: "_LBL_WORKFLOWFRIST_WORKFLOWFOLGE_",
            showLabel: true,
            katalog: "workflowfolgen",
            prio: 5,
            type: "select",
            view: "v",
            required: false,
            displaymode: "select2",
            deselect: true,
            url: "katalog/?key=workflowfolgen",
            apiSaveId: "katalog_workflowfolgen",
            keyId: "pk",
            keyLabel: "bez",
            search: true,
            searchList: [
              "bez"
            ]
          },
          {
            id: "wfr_betreff",
            label: "_LBL_WORKFLOWFRIST_WFR_BETREFF_",
            showLabel: true,
            type: "text",
            view: "v",
            helpText: "_TXT_WFR_BETREFF_HELP_TEXT_",
            prio: 8,
            required: false,
          },
          {
            id: "wfr_inhalt",
            label: "_LBL_WORKFLOWFRIST_WFR_INHALT_",
            showLabel: true,
            type: "richtext",
            view: "v",
            prio: 9,
            helpText: "_TXT_WFR_INHALT_HELP_TEXT_",
            required: false,
          },
          {
            id: "benachrichtigungstyp",
            label: "_LBL_WORKFLOWFRIST_BENACHRICHTIGUNGSTYP_",
            showLabel: true,
            type: "select",
            view: "v",
            katalog: "benachrichtigungstypen",
            prio: 10,
            required: false,
            displaymode: "select2",
            deselect: true,
            url: "katalog/?key=benachrichtigungstypen",
            apiSaveId: "katalog_benachrichtigungstypen5",
            keyId: "pk",
            keyLabel: "bez",
            search: true,
            searchList: [
              "bez"
            ]
          },
          {
            id: "empfaenger",
            label: "_LBL_WORKFLOWFRIST_EMPFAENGER_",
            showLabel: true,
            type: "select",
            view: "v",
            katalog: "empfaengermodule",
            prio: 11,
            required: false,
            displaymode: "select2",
            deselect: true,
            url: "katalog/?key=empfaengermodule",
            apiSaveId: "katalog_empfaengermodule6",
            keyId: "pk",
            keyLabel: "bez",
            search: true,
            searchList: [
              "bez"
            ]
          }
        ]
      };
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.model) {
        this.modelLocal = cloneDeep(this.model);
      } else {
        this.modelLocal = initModelFromList({ list: this.options.list });
      }
    },

    save() {
      if (this.isDisabledSaveButton) {
        return;
      }
      this.loading = true;
      const DATA = cloneDeep(this.modelLocal) || {};
      DATA.aufgabe = this.aufgabePk;

      let httpMethod = null;
      let url = `aufgaben/${ this.aufgabePk }/fristen/`;
      let msg = null;
      if (this.model) {
        httpMethod = this.putHttp;
        url = `${ url }${ this.model.pk }/`;
        msg = "_MSG_WORKFLOWFRIST_LIST_IN_KAUFGABEN_MODAL_UPDATE_SUCCESS_";
      } else {
        httpMethod = this.postHttp;
        msg = "_MSG_WORKFLOWFRIST_LIST_IN_KAUFGABEN_MODAL_CREATE_SUCCESS_";
      }

      httpMethod({
        url: url,
        data: DATA,
      }).then(
        () => {
          this.addNotification({ text: msg });
          this.close(true);
        },
        err => {
          this.errors = err.data;
          this.errorsClone = cloneDeep(this.errors);
        }
      ).finally(() => this.loading = false);
    },
  },
};
