"use strict";

import angular from "angular";

import DiasTemplateService from "./template.service";

const services = angular.module("dias.template.services", [
])

  .provider("DiasTemplate", DiasTemplateService)
;

export default services;
