import FoerderfinderListFoerderangebotDetails from "../FoerderfinderListFoerderangebotDetails/FoerderfinderListFoerderangebotDetails.vue";
import FoerderfinderModalAntragCreate from "../FoerderfinderModalAntragCreate/FoerderfinderModalAntragCreate.vue";
import FoerderfinderSymbolDynamicChildDetails
  from "../FoerderfinderSymbolDynamicChildDetails/FoerderfinderSymbolDynamicChildDetails.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import {
  forEach,
} from "lodash-es";

const TABLE_ID = "FoerderfinderFoerderangeboteTable";

// @vue/component
export default {
  name: "FoerderfinderFoerderangeboteList",
  components: {
    FoerderfinderListFoerderangebotDetails,
    FoerderfinderModalAntragCreate,
    FoerderfinderSymbolDynamicChildDetails,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  props: {
    extern: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        view: {
          default: "table",
          table: true,
          symbol: true,
          filter: "right",
          filterShow: true,
        },
        label: "_TXT_FOERDERFINDER_ANGEBOTE_TABLE_LABEL_",
        pagination: true,
        sortable: true,
        tableName: "foerderangebote",
        details: true,
        url: "ff/v3/foerderangebote/?mit_login=true",
        rowActions: [
          {
            label: "_LBL_FOERDERFINDER_ANGEBOTE_TABLE_ROW_ACTIONS_CREATE_",
            className: "btn btn-default test_antrag_stellen",
            callback: this.createFoerderantrag,
            title: "_LBL_FOERDERFINDER_ANGEBOTE_TABLE_ROW_ACTIONS_CREATE_TITLE_{{kbez}}_",
            extraCallback: ({ item }) => this.getExtras({ item }),
          },
        ],
      },
      filters: [
        {
          label: "_LBL_FOERDERFINDER_LIST_FOERDERANGEBOTE_SUCHE_",
          id: "suche",
          type: "text",
          show: true,
          view: "v",
        },
      ],

      categories: [],
      currentAngebot: undefined,
      status: {
        angebotDetails: undefined,
        loading: true,
        foerderantragCreate: undefined,
        user: undefined,
      },
      modal: {
        create: undefined,
      },
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },

    getSlotNameSymbol() {
      return `${ this.tableId }_symbol`;
    },

    getSlotNameSymbolDetails() {
      return `${ this.tableId }_symbol_details`;
    },
  },
  created() {
    this.checkSyConfigs();
    this.checkUser();
  },
  methods: {
    checkSyConfigs() {
      const instance_pages = this.getSyConfigsValue("instance_pages");
      this.status.angebotDetails = instance_pages && instance_pages.foerderfinderangeboteDetails;
      const KONFIG = this.getSyConfigsValue("tabellenkonfig") || {};
      if (KONFIG.tabellenkonfig &&
        KONFIG.tabellenkonfig[TABLE_ID] &&
        KONFIG.tabellenkonfig[TABLE_ID].view) {
        this.options.view.default = KONFIG.tabellenkonfig[TABLE_ID].view.default;
        this.options.view.table = KONFIG.tabellenkonfig[TABLE_ID].view.table;
        this.options.view.symbol = KONFIG.tabellenkonfig[TABLE_ID].view.symbol;
      }
    },

    checkPermission() {
      this.status.foerderantragCreate = this.checkPermissionsSync({ perm: "foerderantrag.create" });
      this.loadCategories();
    },

    checkUser() {
      const USER = this.me;
      if (USER && USER.pk) {
        this.status.user = true;
        this.checkPermission();
      } else {
        this.loadCategories();
      }
    },

    createFoerderantrag({ item }) {
      this.currentAngebot = item;
      this.modal.create = true;
    },

    closeModalCreate() {
      this.modal.create = false;
    },

    initFilters() {
      const filters = [];
      forEach(this.categories, (cat, index) => {
        const filter = {
          label: cat.kbez,
          key: cat.pk, // Wenn "id" gleiche ist (für :key="filter.key || filter.id" und data[filter.key || filter.id])
          id: "kategoriewert", // Das ist immer Model-Filter (this.model.kategoriewert)
          type: cat.multivalue ? "checkbox" : "radio",
          collapse: true,
          show: index === 0,
          data: [],
          view: "v-alt",
        };
        forEach(cat.werte, wert => {
          filter.data.push({
            labelHtml: `${ wert.svg ? 
                            wert.svg : 
                            wert.image ? 
                              `<img src="/api/assets/${ wert.image }.png" class="custom-control-label__img ${ wert.style }"/><span>${ wert.kbez }</span>` : 
                              `<span>${ wert.kbez }</span>` }`,
            label: wert.kbez,
            info: wert.bez,
            value: wert.pk,
            group: wert.gruppe ? {
              id: wert.gruppe.pk,
              label: wert.gruppe.kbez,
              pos: wert.gruppe.pos,
            } : undefined,
          });
        });
        filters.push(filter);
      });

      this.filters = [...this.filters, ...filters];
      this.status.loading = false;
    },

    loadCategories() {
      this.getListHttp({ url: "ff/v3/foerderfinderkategorien/" }).then(
        response => {
          this.categories = response;
          this.initFilters();
        }
      );
    },

    getExtras({ item }) {
      return { kbez: item.kbez };
    },
  },
};
