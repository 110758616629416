import UiSelect from "../../../global/components/ui/UiSelect/UiSelect.vue";

// @vue/component
export default {
  name: "vue-styleguide-filters",
  components: {
    UiSelect,
  },
  props: {
    foo: {
      type: Boolean,
      default: true,
    },
    bar: {
      type: [Array, String],
      default: undefined,
    },
  },
  data() {
    return {
      status: undefined,
      text1: `<template lang="pug" src="./styleguide-example.pug"></template>\n<script src="./styleguide-example.js"></script>`,
      text2: `div\n  h1 getSomething: {{ getSomething }}\n  h1 getSomethingWithParameter: {{ getSomethingWithParameter("aloha") }}`,
    };
  },
  computed: {
    getSomething() {
      return `something`;
    },

    getSomethingWithParameter() {
      return parameter => `${ parameter } something`;
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {

    },
  },
};
