import {
  ref,
  toRef,
} from "vue";

import FormElement from "../../../../../../../libs/vue/client/vue/global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";
import Modal from "../../../../../../../libs/vue/client/vue/global/components/Modal/Modal.vue";
import Permission from "../../../../../../../libs/vue/client/vue/global/components/Permission/Permission.vue";
import PuxIcon from "../../../../../../../libs/vue/client/vue/global/components/PuxIcon/PuxIcon.vue";

import AkkreditierungVersionOptionsAPI from "../../../compositionAPI/AkkreditierungVersionOptionsAPI";
import FiltersAPI from "../../../../../../../libs/vue/client/vue/global/compositionAPI/FiltersAPI";

import VersionStatus from "../../../const/VersionStatus";

// @vue/component
export default {
  name: "SystemakkreditierungDetailVersionOptions",
  components: {
    FormElement,
    FormstepDetail,
    Modal,
    Permission,
    PuxIcon,
  },
  props: {
    akkreditierung: {
      type: Object,
      required: true,
    },
    akkreditierungDisplayVersion: {
      type: Object,
      required: true,
    },
    versions: {
      type: Object,
      required: false,
      default: () => {},
    },
    saving: {
      type: Boolean,
      required: true,
      default: false,
    },
    selectedVersion: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: [
    "activateAkkr",
    "changeVersion",
    "commentUngueltig",
    "createVersion",
    "deleteVersion",
    "publishVersion",
  ],
  setup(props, { emit }) {
    const {
      filterDate,
    } = FiltersAPI();

    const {
      activateAkkr,
      akkreditierungAktivierenId,
      akkreditierungÄnderungenVeröffentlichenId,
      akkreditierungBearbeitenId,
      akkreditierungBearbeitungAbbrechenId,
      akkreditierungDeaktivierenId,
      akkreditierungDeaktivierenModalOptions,
      canActivate,
      canCreateVersion,
      canDeactivate,
      canUpdateVersion,
      changeVersion,
      closeModal,
      commentUngueltig,
      confirmOptions,
      createVersion,
      getItemStyle,
      loading,
      modalSaveCommentUngueltigId,
      openCommentUngueltigModal,
      openModalAnderungenVeroffentlichen,
      openModalBearbeitungAbbrechen,
      secondaryHeader,
      selectorClose,
      versionSelectOptions,
    } = AkkreditierungVersionOptionsAPI({
      emit,
      akkreditierung: toRef(props, "akkreditierung"),
      akkreditierungDisplayVersion: toRef(props, "akkreditierungDisplayVersion"),
      gutachtenTauschenAkkr: ref(null),
      versions: toRef(props, "versions"),
      basePerm: "systemakkreditierung",
      baseUrl: "systemakkreditierungen",
    });

    return {
      activateAkkr,
      akkreditierungAktivierenId,
      akkreditierungÄnderungenVeröffentlichenId,
      akkreditierungBearbeitenId,
      akkreditierungBearbeitungAbbrechenId,
      akkreditierungDeaktivierenId,
      akkreditierungDeaktivierenModalOptions,
      canActivate,
      canCreateVersion,
      canDeactivate,
      canUpdateVersion,
      changeVersion,
      closeModal,
      commentUngueltig,
      confirmOptions,
      createVersion,
      getItemStyle,
      loading,
      modalSaveCommentUngueltigId,
      openCommentUngueltigModal,
      openModalAnderungenVeroffentlichen,
      openModalBearbeitungAbbrechen,
      secondaryHeader,
      selectorClose,
      versionSelectOptions,

      filterDate,
    };
  },
  data() {
    return {
      versionStatuses: VersionStatus,
    };
  },
};
