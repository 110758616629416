import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import {
  flatten,
  isArray,
  isEmpty,
  isNil,
  isObject,
  sortBy,
  values,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragstellerWizardFinisch",
  components: {
    FormstepDetail,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  props: {
    bestaetigungText: {
      type: String,
      default: undefined,
    },
    closeWizard: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const {
      filterSpaced,
    } = FiltersAPI();

    return {
      filterSpaced,
    };
  },
  data() {
    return {
      wizardConfig: {},
      canCreateAntrag: this.checkPermissionsSync({ perm: "foerderantrag.create" }),
      loading: false,
      errors: {},
    };
  },
  computed: {
    config() {
      return this.wizardConfig.ast_finish || {};
    },

    closeButtonConfig() {
      return this.config.btn_close_config || {};
    },

    createButtonConfig() {
      return this.config.btn_create_config || {};
    },

    regelsatzId() {
      return this.config.ars_id || "";
    },

    antragsquelleId() {
      return this.config.aq_id || "";
    },

    angebotKategorieListe() {
      return this.config.kategoriewerte || [];
    },

    configList() {
      const LIST = [
        {
          id: "antragstellermwizardfinish_close",
          testClass: "test_close_wizard",
          pos: this.closeButtonConfig.pos || 0,
          show: !isNil(this.closeButtonConfig.show) ? this.closeButtonConfig.show : true,
          typeClass: this.closeButtonConfig.type_class || "primary",
          text: "_BTN_ANTRAGSTELLER_WIZARD_STEP_FINISH_CLOSE_",
          tooltip: "",
          disabled: false,
          loading: false,
          click: this.closeWizard,
        },
        {
          id: "antragstellermwizardfinish_create",
          testClass: "test_create_wizard_antrag",
          pos: this.createButtonConfig.pos || 10,
          show: !isNil(this.createButtonConfig.show) ? this.createButtonConfig.show : false,
          typeClass: this.createButtonConfig.type_class || "default",
          text: "_BTN_ANTRAGSTELLER_WIZARD_STEP_FINISH_CREATE_ANTRAG_",
          tooltip: this.canCreateAntrag ? "_BTN_ANTRAGSTELLER_WIZARD_STEP_FINISH_CREATE_ANTRAG_" : "_TXT_ANTRAGSTELLER_WIZARD_STEP_FINISH_CREATE_ANTRAG_BTN_MISSING_PERM_",
          disabled: !this.canCreateAntrag,
          loading: this.loading,
          click: this.onAnlegen,
        }
      ];
      return sortBy(LIST, ["pos"]);
    },
  },
  created() {
    this.wizardConfig = this.getSyConfigsValue("wizard_ast", {});
  },
  methods: {
    createAntrag() {
      this.loading = true;
      const data = {
        regelsatz: this.regelsatzId,
        quelle: this.antragsquelleId,
      };
      this.postHttp({
        url: "foerderantraege/",
        data: data,
        showError: true,
      }).then(
        response => {
          this.$goTo("root.foerderantrag.details", { id: response.pk });
          this.addNotification({
            text: "_MSG_AST_WIZARD_ANTRAG_ERSTELLEN_CREATE_SUCCESS_{{a_nr}}_",
            extra: {
              a_nr: this.filterSpaced(response.a_nr)
            }
          });
        },
        error => {
          if (error.status === 429) {
            this.closeWizard();
            return;
          }
          if (error && error.data) {
            if (isObject(error.data)) {
              this.errors = flatten(values(error.data));
            } else if (!isArray(error.data)) {
              this.errors = [error.data];
            } else {
              this.errors = error.data;
            }
          }
        },
        () => {}
      ).finally(() => this.loading = false);
    },

    onAnlegen() {
      if (this.regelsatzId && this.antragsquelleId) {
        this.createAntrag();
      } else if (!isEmpty(this.angebotKategorieListe)) {
        this.$goTo("root.foerderantrag-add", { kategoriewert: this.angebotKategorieListe });
      } else {
        this.$goTo("root.foerderfinderangebote", {});
      }
    },
  },
};
