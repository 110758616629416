"use strict";

import BaseModel from "dias/core/models/baseModel";


class Antraege extends BaseModel {
  constructor(path) {
    super(path);
  }

  extendModel(model) {
    model = super.extendModel(model);

    // Ist kein Sitzungsantrag-Objekt
    if (angular.isUndefined(model.antrag)) {
      return model;
    }

    model.antrag.a_zuschussbeantragt = Number(model.antrag.a_zuschussbeantragt);

    model.loadProtokoll = () => model.customGET("protokoll_ansehen");

    model.updateProtokoll = protokoll => model.customPUT({ sla_protokoll: protokoll }, "protokoll_bearbeiten");

    model.updateEntscheidungAnmerkung = anmerkung => {
      return model.customPUT({ sla_entscheidung_anmerkung: anmerkung }, "setze_entscheidung_anmerkung");
    };

    model.sumUpTo = () => {
      const collection = model.getParentList();
      if (collection && collection.length > 0) {
        return collection.sumUpTo(model);
      }
    };

    model.calcPosAsLast = () => {
      const collection = model.getParentList();
      return collection.calcPosAsLast(model);
    };

    model.getMoveParameter = (new_pos, old_pos) => {
      const collection = model.getParentList();
      let offset = 0;
      if (angular.isObject(collection.tableState)) {
        offset = collection.tableState.pagination.start;
      }
      const params = {};
      params.new_pos = new_pos;
      params.old_pos = old_pos;
      params.old_idx = collection.indexOfPos(old_pos);
      if (params.old_idx < 0) {
        // is drag'n'drop
        params.old_idx = (old_pos - 1) - offset;
      }
      params.idx = params.old_idx + (new_pos - old_pos);
      if (new_pos < old_pos) {
        params.reorderStart = Math.max(params.idx + 1, 0);
        params.reorderEnd = Math.min(params.old_idx - (params.idx >= 0 ? 0 : 1), collection.length);
        params.modifier = 1;
      }
      if (new_pos > old_pos) {
        params.reorderStart = Math.max(params.old_idx, 0);
        params.reorderEnd = Math.min(params.idx - 1, collection.length - 1);
        params.modifier = -1;
      }
      return params;
    };

    model.move = pos => {
      const collection = model.getParentList();
      const params = model.getMoveParameter(pos, model.sla_pos);
      model.sla_pos = pos;
      collection.splice(params.old_idx, 1);
      if (params.idx >= 0 && params.idx <= collection.length) {
        collection.splice(params.idx, 0, model);
      } else if (params.idx < 0) {
        collection.listeninfo.bedarf += model.antrag.a_zuschussbeantragt;
      }
      return params;
    };

    model.undoMove = params => {
      const collection = model.getParentList();
      model.sla_pos = params.old_pos;
      if (params.idx >= 0 && params.idx < collection.length) {
        collection.splice(params.idx, 1);
      }
      collection.splice(params.old_idx, 0, model);
      return params;
    };

    model.putPos = pos => model.customPUT({ sla_pos: pos });

    model.sendMove = pos => {
      return model.putPos(pos).then(
        response => {
          const params = model.move(pos);
          model.merge(model, response);
          const collection = model.getParentList();
          collection.updatePos(params.reorderStart, params.reorderEnd, params.modifier);
          collection.refreshListInfoForCurrentPage();
          return model;
        }
      );
    };

    model.sendDrop = pos => {
      const params = model.getMoveParameter(pos, model.sla_pos);
      return model.putPos(params.new_pos).then(
        response => {
          model.sla_pos = params.new_pos;
          model.listenstatus = response.listenstatus;
          const collection = model.getParentList();
          collection.updatePos(params.reorderStart, params.reorderEnd, params.modifier);
          collection.refreshListInfoForCurrentPage();
          return model;
        },
        () => model.undoMove(params)
      );
    };

    model._handleServerMove = response => {
      const params = model.getMoveParameter(response.sla_pos, model.sla_pos);
      const collection = model.getParentList();
      collection.splice(params.old_idx, 1);
      if (params.idx >= 0 && params.idx < collection.length + 1) {
        collection.splice(params.idx, 0, model);
      } else if (params.idx < 0) {
        collection.listeninfo.bedarf += response.antrag.a_zuschussbeantragt;
      }
      collection.updatePos(params.reorderStart, params.reorderEnd, params.modifier);
      model.merge(model, response);
      collection.refreshListInfoForCurrentPage();
      return model;
    };

    model.sendSetzeNichtVorgesehen = () => {
      return model.customPUT({}, "setze_nicht_vorgesehen").then(
        response => model._handleServerMove(response)
      );
    };

    model.sendSetzeVorgesehen = () => {
      return model.customPUT({}, "setze_vorgesehen").then(
        response => model._handleServerMove(response)
      );
    };

    model.sendSetzeErsatz = () => {
      return model.customPUT({}, "setze_ersatz").then(
        response => model._handleServerMove(response)
      );
    };

    model.setEntscheidung = uuid => {
      return model.customPUT({ entscheidungsstatus: uuid }, "setze_entscheidung").then(
        response => model.merge(model, response)
      );
    };

    return model;
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.listenstatusUUIDs = this.$injector.get("ListenstatusUUIDs");

    collection.setRestlicheEntscheidungen = uuid => collection.customPOST({ entscheidungsstatus: uuid }, "setze_entscheidungen");

    collection.refreshListeninfo = (limit, offset) => {
      if (angular.isUndefined(offset)) {
        offset = 0;
      }
      return collection.customGET("listeninfo", { limit: limit, offset: offset }).then(
        response => collection.listeninfo = response
      );
    };

    collection.refreshListInfoForCurrentPage = () => {
      return collection.refreshListeninfo(collection.length, collection[0].sla_pos - 1);
    };

    collection.put_priorisiert = prio => {
      return collection.customPUT({ sl_priorisiert: prio }, "set_priorisiert");
    };

    collection.restangularizeAntrag = element => {
      this.Restangular.restangularizeElement(collection, element, this.path);
      element.getParentList = () => collection;
      element.route = "";
    };

    collection.getOffPageBeantragt = () => {
      let sum = 0;
      if (!angular.isUndefined(collection.listeninfo) && !angular.isUndefined(collection.listeninfo.bedarf)) {
        sum = Number(collection.listeninfo.bedarf);
      }
      return sum;
    };

    collection.sumUpTo = sitzungsantragantrag => {
      const idx = collection.indexOf(sitzungsantragantrag);
      let sum = collection.getOffPageBeantragt();
      for (let i = 0; i <= idx; i++) {
        sum += collection[i].antrag.a_zuschussbeantragt;
      }
      return sum;
    };

    collection.calcPosAsLast = model => {
      let pos = angular.isUndefined(collection.listeninfo) ? undefined : collection.listeninfo.pos_last_in_sitzung;
      if (pos === null) {
        pos = 1;
      } else if (model.listenstatus === collection.listenstatusUUIDs.nichtVorgesehen) {
        pos++;
      }
      return pos;
    };

    collection.calcAngefordert = () => {
      let sum = 0;
      for (let i = 0; i <= collection.length; i++) {
        if (sum + collection[i].antrag.a_zuschussbeantragt < collection.bedarf) {
          sum += collection[i].antrag.a_zuschussbeantragt;
        } else {
          break;
        }
      }
      return sum;
    };

    collection.updatePos = (start, end, direction) => {
      for (let i = start; i <= end && i < collection.length; i++) {
        collection[i].sla_pos += direction;
      }
    };

    collection.indexOfPos = pos => {
      for (let i = 0; i < collection.length; i++) {
        if (collection[i].sla_pos === pos) {
          return i;
        }
      }
      return -1;
    };

    return collection;
  }
}

export default Antraege;
