import { replaceText } from "../../functions/utils";
import {
  forEach,
  get,
  isString,
} from "lodash-es";

export default {
  computed: {
    getToForLink_mixin() {
      return linkTo => {
        if (isString(linkTo)) {
          return replaceText({ text: linkTo, object: this.row });
        }
        if (linkTo.url) {
          return replaceText({ text: linkTo.url, object: this.row });
        }
        const TO = {
          name: linkTo.name,
        };
        if (linkTo.params) {
          if (linkTo.paramsGet && linkTo.paramsGet.length) {
            TO.params = {};
            forEach(linkTo.params, (param, key) => {
              if (linkTo.paramsGet.indexOf(param) === -1) {
                TO.params[key] = param;
              } else {
                TO.params[key] = get(this.row, param);
              }
            });
          } else {
            TO.params = linkTo.params;
          }
        }
        return TO;
      };
    },
  },
};
