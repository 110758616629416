import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import TemplateAdminParameterBoxItem from "../TemplateAdminParameterBoxItem/TemplateAdminParameterBoxItem.vue";

// @vue/component
export default {
  name: "TemplateAdminParameterBoxGroup",
  components: {
    PuxButton,
    PuxTranslate,
    TemplateAdminParameterBoxItem,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    groupIndex: {
      type: Number,
      required: true,
    },
    groupsAndParameterNamesHiddenWithSearch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    isVisible() {
      return !this.groupsAndParameterNamesHiddenWithSearch.groupNames[this.group.name];
    },

    iconToggle() {
      return this.isOpen ?
        "chevron-up" :
        "chevron-down";
    },

    idToggleButton() {
      return `template_parameter_group_btn_${ this.groupIndex }`;
    },

    idContent() {
      return `template_parameter_group_content_${ this.groupIndex }`;
    },

    tooltipToggleButton() {
      return this.isOpen ? "_BTN_ASIDE_RIGHT_BOX_TOGGLE_CLOSE_" : "_BTN_ASIDE_RIGHT_BOX_TOGGLE_OPEN_";
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
