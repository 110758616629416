"use strict";

class FoerderfinderangebotzuordnungModalCreateController {
  /*@ngInject*/
  constructor($q, $scope, $state, $stateParams, Foerderangebote, Foerderfinderfragen, DiasNotification) {
    this.DiasNotification = DiasNotification;
    this.$stateParams = $stateParams;
    this.$scope = $scope;
    this.zuordnung = {};
    this.errors = {};
    Foerderangebote.getList({ aktiv: "True" }).then(result => {
      this.foerderangebote = result;
    });

    this.create = () => {
      return Foerderfinderfragen.one($stateParams.id).antworten.one($stateParams.ffa_id).customPOST(this.zuordnung, "add_zuordnung").then(
        () => {
          DiasNotification.page.success("Angebot wurde erfolgreich zugeordnet.");
          this.$scope.extras.reloadPage();
        },
        error => {
          const errorsLabel = {
            foe_id: "Förderangebot: ",
          };
          DiasNotification.form.clear();
          DiasNotification.form.error(error.data, "Ihre Daten sind fehlerhaft oder unvollständig.", {}, errorsLabel);
          this.errors = error.data;
          return $q.reject(error);
        }
      );
    };
  }
}
export default FoerderfinderangebotzuordnungModalCreateController;
