import {
  toRef,
} from "vue";

import AngularLink from "../../../../../../../libs/vue/client/vue/global/components/AngularLink/AngularLink.vue";
import FormstepDetail from "../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";

import FiltersAPI from "../../../../../../../libs/vue/client/vue/global/compositionAPI/FiltersAPI";
import AkkreditierungHochschuleAPI from "../../../compositionAPI/AkkreditierungHochschuleAPI";

// @vue/component
export default {
  name: "SystemakkreditierungDetailsItem",
  components: {
    AngularLink,
    FormstepDetail,
  },
  props: {
    akkreditierung: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: [
    "onSave",
  ],
  setup(props, { emit }) {
    const {
      errors,
      formstepDetailOptions,
      hochschuleModel,
      hskNummerLinkTitle,
      htmlId,
      initModel,
      optionsList,
    } = AkkreditierungHochschuleAPI({
      akkreditierung: toRef(props, "akkreditierung"),
      emit,
    });

    const {
      filterList,
    } = FiltersAPI();

    initModel();

    return {
      errors,
      formstepDetailOptions,
      hochschuleModel,
      hskNummerLinkTitle,
      htmlId,
      initModel,
      optionsList,

      filterList,
    };
  },
};
