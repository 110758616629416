import AufgabeModalCreate from "../../AufgabeModalCreate/AufgabeModalCreate.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "ManuelleAufgabeButtonModalCreate",
  components: {
    AufgabeModalCreate,
    PuxButton,
  },
  props: {
    buttonLabel: {
      type: String,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    selectorClose: {
      type: [String, Array],
      required: true,
    },
  },
  emits: [
    "onCreated",
  ],
  data() {
    return {
      isModalVisible: false,
    };
  },
  computed: {
    urlCreate() {
      return `${ this.baseUrl }aufgaben/manuelle_aufgabe_erstellen/`;
    },
  },
  methods: {
    createNewAufgabe() {
      this.isModalVisible = true;
    },

    closeModalCreate({ status } = {}) {
      if (status) {
        this.$emit("onCreated");
      }
      this.isModalVisible = false;
    },
  },
};
