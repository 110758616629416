import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";

// @vue/component
export default {
  name: "BelegButtonVersionsvergleichTableRow",
  components: {
    FormElementReadOnly,
    PuxTranslate,
    SnapshotIcon,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    modelErfasst: {
      type: Object,
      required: true,
    },
    modelAnerkannt: {
      type: Object,
      required: true,
    },
  },
};
