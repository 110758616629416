import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import SmartTable from "../../../../global/components/table/table.vue";

import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

// @vue/component
export default {
  name: "WorkflowfolgeclientfunktionenDetailsWorkflowfolgen",
  components: {
    FormstepDetail,
    SmartTable,
  },
  props: {
    workflowfolgenUrl: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      checkPermissionsSync,
    };
  },
  data() {
    return {
      options: {
        hideHeader: true,
      },
      htmlId: "workflowfolgeclientfunktionen_details_workflowfolgen",
      tableOptions: {
        id: "clientWorkflowfolgen",
        url: this.workflowfolgenUrl,
        pagination: true,
        showColsConfig: false,
        label: "_TXT_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_WORKFLOWFOLGEN_",
        hideTitle: false,
        showHeader: false,
        cols: [
          {
            label: "_TXT_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_WORKFLOWFOLGE_",
            id: "wfo_kbez",
            slot: "wfo_kbez",
          },
          {
            label: "_TXT_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_AUSGANGSAUFGABE_",
            id: "aufgabe_bez",
            slot: "aufgabe_bez",
          },
          {
            label: "_TXT_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_NACHFOLGEAUFGABE_",
            id: "nachfolge_aufgabe_bez",
            slot: "nachfolge_aufgabe_bez",
          },
          {
            label: "_TXT_WORKFLOWFOLGEN_CLIENTFUNKTIONEN_DETAILS_WORKFLOW_",
            id: "workflow_bez",
            slot: "workflow_bez",
          },
        ],
      },
    };
  },
  computed: {
    hasPermissionWorkflowfolgeUi() {
      return this.checkPermissionsSync({ perm: "workflowfolge.ui" });
    },

    hasPermissionAufgabeUi() {
      return this.checkPermissionsSync({ perm: "kaufgabe.ui" });
    },

    hasPermissionWorkflowUi() {
      return this.checkPermissionsSync({ perm: "workflow.ui" });
    },
  },
  methods: {
    getHrefWorkflowfolge(row) {
      return `/workflowfolgen/${ row.pk }/`;
    },

    getHrefAufgabe(row) {
      return `/aufgabenvorlagen/${ row.aufgabe }/`;
    },

    getHrefNachfolgeAufgabe(row) {
      return `/aufgabenvorlagen/${ row.nachfolge_aufgabe }/`;
    },

    getHrefWorkflow(row) {
      return `/workflows/${ row.workflow }/`;
    },
  },
};
