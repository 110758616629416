import AsideRightBox from "../AsideRightBox/AsideRightBox.vue";
import TaskLog from "../TaskLog/TaskLog.vue";

// @vue/component
export default {
  name: "TaskLogBox",
  components: {
    AsideRightBox,
    TaskLog,
  },
  props: {
    task: {
      type: String, // Task ID
      default: undefined,
    },
    onError: {
      type: Function,
      default: () => ({}),
    },
    onFailure: {
      type: Function,
      default: undefined,
    },
    onFinish: {
      type: Function,
      default: undefined,
    },
    onProgress: {
      type: Function,
      default: () => ({}),
    },
    onSuccess: {
      type: Function,
      default: undefined,
    },
    blockDefaultNotifications: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      optionsAsideRightBox: {
        hideHeader: true,
        classSubheader: "pl-1",
      },
    };
  },
};
