import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "DokumenteSuchenWizardStep1",
  components: {
    FormElement,
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    kontexte: {
      type: Array,
      required: true,
    },
    updateModelKontext: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modelKontext: undefined,
      formElementOptions: {
        type: "radio",
        id: "modelKontext",
        keyId: "id",
        keyLabel: "bez",
      },
    };
  },
  methods: {
    onChange({ model }) {
      this.updateModelKontext({ model });
    },
  },
};
