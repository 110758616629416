export default {
  namespaced: true,
  state() {
    return {
      DASHBOARD_OPEN_INFO: undefined,
    };
  },
  mutations: {
    SET_DASHBOARD_OPEN_INFO(state, { pk }) {
      if (state.DASHBOARD_OPEN_INFO === pk) {
        state.DASHBOARD_OPEN_INFO = undefined;
      } else {
        state.DASHBOARD_OPEN_INFO = pk;
      }
    },
  },
};
