var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group"] = jade_interp = function(errors, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :" + (errors) + "}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "" + (errors) + "", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errors) + "", true, true)) + ">{{ err }}</li></ul></span></label>");
};
jade_mixins["required-form-group"] = jade_interp = function(errors, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :" + (errors) + "}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "" + (errors) + "", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errors) + "", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<fieldset><div ng-attr-id=\"{{ vm.errorContainerId }}\"></div><div class=\"row\"><div class=\"col-12 col-sm-6\"></div></div><div class=\"row\"><div class=\"col-sm-12\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"ars_prio\" ng-model=\"vm.model.ars_prio\" type=\"number\" name=\"ars_prio\" aria-required=\"false\" ng-attr-aria-invalid=\"{{vm.errors.ars_prio ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "vm.errors.ars_prio", "{{ vm.gettext('Sortierreihenfolge') }}");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"foe_suchbegriffe\" ng-model=\"vm.model.ars_suchbegriffe\" type=\"text\" name=\"ars_suchbegriffe\" aria-required=\"false\" ng-attr-aria-invalid=\"{{vm.errors.ars_suchbegriffe ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "vm.errors.ars_suchbegriffe", "{{ vm.gettext('Suchbegriffe') }}");
jade_mixins["form-group"].call({
block: function(){
buf.push("<textarea id=\"foe_astbez\" ng-model=\"vm.model.ars_astbez\" dias-richtext type=\"text\" name=\"ars_astbez\" aria-required=\"false\" ng-attr-aria-invalid=\"{{vm.errors.ars_astbez ? 'true' : 'false'}}\" class=\"form-control\"></textarea>");
}
}, "vm.errors.ars_astbez", "Info für {{ vm.gettext('Antragsteller') }}");
buf.push("<div class=\"form-group\"><label ng-class=\"{'has-error has-feedback': vm.errors.ars_foerderfinder_sichtbar}\" class=\"font-weight-bold\"><span class=\"titel\">Sichtbar im Förderfinder?<span class=\"alert-danger\"></span></span></label><div class=\"radio-btn line\"><input name=\"foerderfinder_sichtbar\" id=\"foerderfinder_sichtbar-true\" type=\"radio\" ng-model=\"vm.model.ars_foerderfinder_sichtbar\" ng-value=\"true\" ng-attr-aria-checked=\"{{ vm.model.ars_foerderfinder_sichtbar === true ? 'true' : 'false' }}\" ng-attr-aria-invalid=\"{{vm.errors.ars_foerderfinder_sichtbar ? 'true' : 'false'}}\"><label for=\"foerderfinder_sichtbar-true\" class=\"font-weight-bold\"><span class=\"label-hidden\">Sichtbar im Förderfinder?</span><span>Ja</span></label></div><div class=\"radio-btn line\"><input name=\"foerderfinder_sichtbar\" id=\"foerderfinder_sichtbar-false\" type=\"radio\" ng-model=\"vm.model.ars_foerderfinder_sichtbar\" ng-value=\"false\" ng-attr-aria-checked=\"{{ vm.model.ars_foerderfinder_sichtbar === false ? 'true' : 'false' }}\" ng-attr-aria-invalid=\"{{vm.errors.ars_foerderfinder_sichtbar ? 'true' : 'false'}}\"><label for=\"foerderfinder_sichtbar-false\" class=\"font-weight-bold\"><span class=\"label-hidden\">Sichtbar im Förderfinder?</span><span>Nein</span></label></div><span ng-if=\"vm.errors.ars_foerderfinder_sichtbar\" class=\"help-block\"><ul class=\"errorlist\"><li ng-repeat=\"err in vm.errors.ars_foerderfinder_sichtbar\">{{ err }}</li></ul></span></div>");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<textarea id=\"foe_foerderfinder_kurz\" ng-model=\"vm.model.ars_foerderfinder_kurz\" dias-richtext type=\"text\" name=\"ars_foerderfinder_kurz\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.ars_foerderfinder_kurz ? 'true' : 'false'}}\" class=\"form-control\"></textarea>");
}
}, "vm.errors.ars_foerderfinder_kurz", "Kurztext für Förderfinder");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<textarea id=\"foe_foerderfinder_lang\" ng-model=\"vm.model.ars_foerderfinder_lang\" dias-richtext name=\"ars_foerderfinder_lang\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.ars_foerderfinder_lang ? 'true' : 'false'}}\" class=\"form-control\"></textarea>");
}
}, "vm.errors.ars_foerderfinder_lang", "Langtext für Förderfinder");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"ars_ff_foerderfaehigekosten_kbez\" ng-model=\"vm.model.ars_ff_foerderfaehigekosten_kbez\" type=\"text\" name=\"ars_ff_foerderfaehigekosten_kbez\" aria-required=\"false\" ng-attr-aria-invalid=\"{{vm.errors.ars_ff_foerderfaehigekosten_kbez ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "vm.errors.ars_ff_foerderfaehigekosten_kbez", "{{ vm.gettext('_LBL_ANGEBOTS_VERSIONS_INFORMATION_FOERDERFAEHIGE_KOSTEN_KBEZ_') }}");
jade_mixins["form-group"].call({
block: function(){
buf.push("<textarea id=\"ars_ff_foerderfaehigekosten_bez\" ng-model=\"vm.model.ars_ff_foerderfaehigekosten_bez\" type=\"text\" name=\"ars_ff_foerderfaehigekosten_bez\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.ars_ff_foerderfaehigekosten_bez ? 'true' : 'false'}}\" class=\"form-control\"></textarea>");
}
}, "vm.errors.ars_ff_foerderfaehigekosten_bez", "{{ vm.gettext('_LBL_ANGEBOTS_VERSIONS_INFORMATION_FOERDERFAEHIGE_KOSTEN_BEZ_') }}");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"ars_ff_foerderhoehe_kbez\" ng-model=\"vm.model.ars_ff_foerderhoehe_kbez\" type=\"text\" name=\"ars_ff_foerderhoehe_kbez\" aria-required=\"false\" ng-attr-aria-invalid=\"{{vm.errors.ars_ff_foerderhoehe_kbez ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "vm.errors.ars_ff_foerderhoehe_kbez", "{{ vm.gettext('_LBL_ANGEBOTS_VERSIONS_INFORMATION_FOERDERHOEHE_KBEZ_') }}");
jade_mixins["form-group"].call({
block: function(){
buf.push("<textarea id=\"ars_ff_foerderhoehe_bez\" ng-model=\"vm.model.ars_ff_foerderhoehe_bez\" type=\"text\" name=\"ars_ff_foerderhoehe_bez\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.ars_ff_foerderhoehe_bez ? 'true' : 'false'}}\" class=\"form-control\"></textarea>");
}
}, "vm.errors.ars_ff_foerderhoehe_bez", "{{ vm.gettext('_LBL_ANGEBOTS_VERSIONS_INFORMATION_FOERDERHOEHE_BEZ_') }}");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"ars_ff_eigenmittel_kbez\" ng-model=\"vm.model.ars_ff_eigenmittel_kbez\" type=\"text\" name=\"ars_ff_eigenmittel_kbez\" aria-required=\"false\" ng-attr-aria-invalid=\"{{vm.errors.ars_ff_eigenmittel_kbez ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "vm.errors.ars_ff_eigenmittel_kbez", "{{ vm.gettext('_LBL_ANGEBOTS_VERSIONS_INFORMATION_EIGENMITTEL_KBEZ_') }}");
jade_mixins["form-group"].call({
block: function(){
buf.push("<textarea id=\"ars_ff_eigenmittel_bez\" ng-model=\"vm.model.ars_ff_eigenmittel_bez\" type=\"text\" name=\"ars_ff_eigenmittel_bez\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.ars_ff_eigenmittel_bez ? 'true' : 'false'}}\" class=\"form-control\"></textarea>");
}
}, "vm.errors.ars_ff_eigenmittel_bez", "{{ vm.gettext('_LBL_ANGEBOTS_VERSIONS_INFORMATION_EIGENMITTEL_BEZ_') }}");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"ars_ff_zeitraum_kbez\" ng-model=\"vm.model.ars_ff_zeitraum_kbez\" type=\"text\" name=\"ars_ff_zeitraum_kbez\" aria-required=\"false\" ng-attr-aria-invalid=\"{{vm.errors.ars_ff_zeitraum_kbez ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "vm.errors.ars_ff_zeitraum_kbez", "{{ vm.gettext('_LBL_ANGEBOTS_VERSIONS_INFORMATION_ZEITRAUM_KBEZ_') }}");
jade_mixins["form-group"].call({
block: function(){
buf.push("<textarea id=\"ars_ff_zeitraum_bez\" ng-model=\"vm.model.ars_ff_zeitraum_bez\" type=\"text\" name=\"ars_ff_zeitraum_bez\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.ars_ff_zeitraum_bez ? 'true' : 'false'}}\" class=\"form-control\"></textarea>");
}
}, "vm.errors.ars_ff_zeitraum_bez", "{{ vm.gettext('_LBL_ANGEBOTS_VERSIONS_INFORMATION_ZEITRAUM_BEZ_') }}");
buf.push("</div></div></fieldset><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save, vm.errorsLabel)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Abbrechen</span></button></div></div>");;return buf.join("");
}