import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungslisteModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    urlSave: {
      type: String,
      default: "auszahlungslisten/",
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "versions_id",
            type: "select",
            label: "_LBL_AUSZAHLUNGSLISTE_MODAL_CREATE_VORLAGE_",
            required: true,
            view: "v",
            keyId: "pk",
            keyLabelCallback: ({ item }) => `${ item.fvv_kbez }`,
            menuWidthAll: true,
            url: "auszahlungslisten/verfuegbare_vorlagenversionen/",
            translate: true,
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  methods: {
    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.postHttp({
        url: this.urlSave,
        data: data,
        showError: true,
      }).then(
        response => {
          this.addNotification({ text: "_MSG_AUSZAHLUNGSLISTE_CREATE_SUCCESS_" });
          this.close({ response, status: true });
          this.$goTo("root.auszahlungslisten.details", { id: response.pk });
        },
        error => {
          if (error.status !== 429) {
            this.errors = error.data;
            this.errorsClone = error.data;
          }
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
