import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import AntraegeModalChangeTeam from "../../Antraege/AntraegeModalChangeTeam/AntraegeModalChangeTeam.vue";
import AnsprechpartnerModalZuweisen from "../../Ansprechpartner/AnsprechpartnerModalZuweisen/AnsprechpartnerModalZuweisen.vue";
import AnsprechpartnerModalZuweisenMe from "../../Ansprechpartner/AnsprechpartnerModalZuweisenMe/AnsprechpartnerModalZuweisenMe.vue";
import NachrichtenLink from "../../Nachrichten/NachrichtenLink/NachrichtenLink.vue";
import Permission from "../../../global/components/Permission/Permission";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spaced from "../../../global/components/Spaced/Spaced.vue";

import {
  FilterCurrencyMixin,
  FilterDateMixin,
  FilterFileSizeMixin,
} from "../../../global/mixins/FiltersMixin";
import HttpMixin from "../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import KommunikationsdatenUUIDs from "../../../const/KommunikationsdatenUUIDs";
import {
  filter,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragListDetails",
  components: {
    AngularLink,
    AntraegeModalChangeTeam,
    AnsprechpartnerModalZuweisen,
    AnsprechpartnerModalZuweisenMe,
    NachrichtenLink,
    Permission,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
    Spaced,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterDateMixin,
    FilterFileSizeMixin,
    HttpMixin,
    SyConfigMixin,
  ],
  props: {
    vertrag: {
      type: Object,
      default: () => ({}),
    },
    replace: {
      type: Function,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    canViewBelege: {
      type: Boolean,
    },
    auszahlungen: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ansprechpartner: undefined,
      termineConf: undefined,
      statusLoading: true,
      modal: {
        ansprechpartnerZuweisen: undefined,
        ansprechpartnerZuweisenMe: undefined,
        changeTeam: undefined,
      },
    };
  },
  computed: {
    getVertragDocumentTitle() {
      return doc => `${ doc.datei } (${ this.filterFileSize(doc.groesse) })`;
    },

    getAllBetrag() {
      let allBetrag = 0;
      forEach(this.auszahlungen, item => {
        if (item.az_datum_ausgezahlt) {
          allBetrag += +item.az_betrag;
        }
      });
      return allBetrag;
    },

    getFilterKommunikationsdaten() {
      return ({ obj }) => {
        if (!obj.nutzer || !obj.nutzer.kommunikationsdaten) {
          return [];
        }
        return filter(obj.nutzer.kommunikationsdaten, item => item.kommtyp && item.kommtyp.kot_id === KommunikationsdatenUUIDs.telefonnr1);
      };
    },
  },
  created() {
    this.initData();
    this.loadData();
  },
  methods: {
    initData() {
      this.termineConf = this.getSyConfigsValue("v_termine_in_uebersicht");
    },

    loadData({ statusReload } = {}) {
      if (this.vertrag._details && !statusReload) {
        this.statusLoading = false;
        return;
      }
      this.getHttp({ url: `vertraege/${ this.vertrag.pk }/vorschau/` }).then(
        response => {
          response._details = true;
          this.replace(response);
          this.statusLoading = false;
        }
      );
    },

    assignAnsprechpartnerMe(asp) {
      this.ansprechpartner = asp;
      this.modal.ansprechpartnerZuweisenMe = true;
    },

    assignAnsprechpartner(asp) {
      this.ansprechpartner = asp;
      this.modal.ansprechpartnerZuweisen = true;
    },

    closeModalAnsprechpartnerZuweisen({ status } = {}) {
      if (status) {
        this.loadData({ statusReload: true });
      }
      this.modal.ansprechpartnerZuweisen = false;
    },

    closeModalAnsprechpartnerZuweisenMe({ status } = {}) {
      if (status) {
        this.loadData({ statusReload: true });
      }
      this.modal.ansprechpartnerZuweisenMe = false;
    },

    changeTeam() {
      this.modal.changeTeam = true;
    },

    closeModalChangeTeam({ status } = {}) {
      this.modal.changeTeam = false;
      if (status) {
        this.loadData({ statusReload: true });
      }
    },
  },
};
