import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";
import UebermittelnProgress from "./UebermittelnProgress/UebermittelnProgress.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import {
  FilterBooleanMixin,
  FilterDateMixin,
  FilterDefaultForEmptyMixin,
  FilterJsonMixin,
} from "../../../global/mixins/FiltersMixin";

import getTableApi from "../../../const/TableApis";
import { EventBus } from "../../../global/functions/event-bus";
import {
  gettext
} from "../../../global/functions/utils";

const TABLE_ID = "servicelogTable";

// @vue/component
export default {
  name: "LogList",
  components: {
    PageTabTitle,
    PuxTranslate,
    SmartTable,
    UebermittelnProgress,
  },
  mixins: [
    FilterBooleanMixin,
    FilterDateMixin,
    FilterDefaultForEmptyMixin,
    FilterJsonMixin,
    HttpMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_LBL_SERVICE_LOG_TABLE_ROW_ACTIONS_UEBERMITTELN_",
            isHidden: ({ item }) => item.slo_behoben !== false || !this.$AuthService.syncHasPerm("log.service.crmeg.beheben"),
            callback: this.erneutUebermitteln,
            className: "btn btn-primary",
          }
        ],
        actions: [],
        label: "_LBL_LOG_LIST_SCHNITTSTELLENPROTOKOLL_",
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
        },
      },
      taskIds: {},
    };
  },
  computed: {
    getFilterUebersetzung() {
      return gettext("_TXT_LOG_LIST_NICHT_ANWENDBAR_");
    },

    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },
  },
  methods: {
    renderSloRequest({ row }) {
      return `<div
             class="code-content scroll-content"
             style="max-height: 200px; width: 100%;">${ this.filterJson(row.slo_request) }</div>`;
    },

    renderSloResponse({ row }) {
      return `<div
             class="code-content scroll-content"
             style="max-height: 200px; width: 100%;">${ this.filterJson(row.slo_response) }</div>`;
    },

    erneutUebermitteln({ item }) {
      this.postHttp({ url: `crmeg/v1/servicelog/${ item.pk }/erneut_uebermitteln/` }).then(
        response => {
          this.addNotification({ text: "_MSG_SERVICE_LOG_TABLE_UEBERMITTELN_SUCCESS_" });
          this.taskIds[item.pk] = response.task_id;
        },
        () => {
          this.addNotification({ text: "_MSG_SERVICE_LOG_TABLE_UEBERMITTELN_ERROR_", type: "error" });
        }
      );
    },

    updateTable() {
      EventBus.$emit(`updateTable${ TABLE_ID }`);
    },

    resolveLog({ item }) {
      this.taskIds[item.pk] = undefined;
    },
  },
};
