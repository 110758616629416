"use strict";

import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";

import builder from "dias/standard_foerdermodule/utils/builder";


const einfache_foerderregel = angular.module("dias.vertrag.cortexe.auszahlungslisten.foerdermodule.cortext_auszahlungsliste_objektliste", [
  foerdermoduleRegistry.name,
]);

einfache_foerderregel.config(FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("cortext_auszahlungsliste_objektliste", "modul-cortext-auszahlungsliste-objektliste");
})

  .directive("modulCortextAuszahlungslisteObjektliste", builder.directive(template, controller));

export default einfache_foerderregel;
