import DashboardWorkflowInfoWorkflow from "./DashboardWorkflowInfoWorkflow/DashboardWorkflowInfoWorkflow.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";

import DashboardModuleMixin from "../DashboardModuleMixin";
import DashboardWFInfoMixin from "../DashboardWFInfoMixin";

import { changeTextToId } from "../../../../global/functions/utils";

// @vue/component
export default {
  name: "DashboardWorkflowInfo",
  components: {
    DashboardWorkflowInfoWorkflow,
    PuxButton,
  },
  mixins: [
    DashboardModuleMixin,
    DashboardWFInfoMixin,
  ],
  computed: {
    isOpen() {
      return this.DASHBOARD_OPEN_INFO === this.pk;
    },

    classForBox() {
      return [
        { open: this.isOpen },
        `test_dashboard_${ changeTextToId(this.titel) }`,
      ];
    },

    arrowTitle() {
      if (this.DASHBOARD_OPEN_INFO === this.pk) {
        return "_TXT_DASHBOARD_WORKFLOW_INFO_PROZESS_AUSBLENDEN_";
      }
      return "_TXT_DASHBOARD_WORKFLOW_INFO_PROZESS_EINBLENDEN_";
    },

    idForToggleButton() {
      return `${ this.idLocal }_btn_toggle`;
    },

    iconForToggleButton() {
      return this.isOpen ? "chevron-down" : "chevron-down";
    },

    indexForChildComponent() {
      return 100 + this.dashboardModuleIndex;
    },

    idForChildComponent() {
      return `${ this.idLocal }_child`;
    },
  },
  methods: {
    toggleBlockDetail() {
      this.SET_DASHBOARD_OPEN_INFO({ pk: this.pk });
    },
  },
};
