import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxGet from "../../../../global/components/PuxGet/PuxGet.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import WorkflowtypDetailsBeschreibungWorkflows
  from "./WorkflowtypDetailsBeschreibungWorkflows/WorkflowtypDetailsBeschreibungWorkflows.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import { cloneDeep } from "lodash-es";

// @vue/component
export default {
  name: "WorkflowtypDetailsBeschreibung",
  components: {
    AngularLink,
    FormElement,
    FormstepDetail,
    PuxGet,
    PuxTranslate,
    WorkflowtypDetailsBeschreibungWorkflows,
  },
  props: {
    workflowtyp: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "updateWorkflowtyp",
  ],
  setup() {
    const {
      patchHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      addNotification,
      checkPermissionsSync,
      patchHttp,
    };
  },
  data() {
    return {
      loading: false,
      errors: {},
      model: {
        wft_kbez: undefined,
        wft_bez: undefined,
      },
      view: "v-alt",
      formstepOptions: {
        label: "_LBL_WORKFLOWTYP_DETAILS_FORMSTEP_BESCHREIBUNG_",
        openCallback: this.open,
        saveCallback: this.save,
        closeCallback: this.close,
      },
      htmlId: "workflowtypen_beschreibung",
    };
  },
  computed: {
    hasPermissionEditBeschreibung() {
      return this.checkPermissionsSync({ perm: "workflowtyp.update" });
    },

    optionsForWftKbez() {
      const ID = "wft_kbez";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        view: this.view,
        label: "_LBL_WORKFLOWTYP_DETAILS_NAME_",
        required: true,
      };
    },

    optionsForWftBez() {
      const ID = "wft_bez";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        view: this.view,
        label: "_LBL_WORKFLOWTYP_DETAILS_LONG_NAME_",
        required: true,
      };
    },

    errorsLabelsOptions() {
      return [
        this.optionsForWftKbez,
        this.optionsForWftBez,
      ];
    }
  },
  methods: {
    open() {
      this.setModel();
    },

    save() {
      const DATA = cloneDeep(this.model);
      return new Promise((resolve, reject) => {
        this.patchHttp({
          url: this.baseUrl,
          data: DATA,
        }).then(
          response => {
            this.$emit("updateWorkflowtyp", { data: response });
            this.addNotification({ text: "_MSG_WORKFLOWTYP_DETAILS_CHANGES_SUCCESSFUL_SAVED_" });
            resolve();
          },
          errors => reject(this.onErrors({ errors: errors })),
        );
      });
    },

    close() {
      this.errors = {};
    },

    setModel() {
      this.model.wft_kbez = this.workflowtyp.wft_kbez;
      this.model.wft_bez = this.workflowtyp.wft_bez;
    },

    onErrors({ errors }) {
      this.errors = errors.data;
      return errors;
    },
  },
};
