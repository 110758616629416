import AsideRightBox from "../../../global/components/AsideRightBox/AsideRightBox.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import NachrichtenLink from "../NachrichtenLink/NachrichtenLink.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxDate from "../../../global/components/PuxDate/PuxDate.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import {
  EventBus,
} from "../../../global/functions/event-bus";
import {
  orderBy,
} from "lodash-es";

// @vue/component
export default {
  name: "NachrichtenObjectListBox",
  components: {
    AsideRightBox,
    Modal,
    NachrichtenLink,
    PuxButton,
    PuxDate,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
    bezug: {
      type: String,
      required: true,
    },
    bezugPk: {
      type: String,
      required: true,
    },
    objectPermissions: {
      type: Array,
      required: false,
      default: () => [],
    },
    canCreateNachrichten: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      optionsAsideRightBox: {
        icon: "envelope",
      },
      nachrichten: [],
      loading: true,
      htmlId: "nachrichten_object",
      statusModalCreate: undefined,
      statusModalLoading: undefined,
      model: {
        titel: null,
        text: null,
      },
      errors: undefined,
      hasCreatePermission: false,
    };
  },
  computed: {
    isBoxVisible() {
      return this.checkPermissionsSync({
        perm: "benachrichtigungen.objektnachrichten.view",
      });
    },

    url() {
      return `${ this.baseUrl }/${ this.bezugPk }/benachrichtigungen/`;
    },

    urlSave() {
      return `${ this.baseUrl }/${ this.bezugPk }/verantwortliche_benachrichtigen/`;
    },

    nachrichtenOrderByDatumDesc() {
      return orderBy(this.nachrichten, ["datum"], ["desc"]);
    },

    idForModalCreateButton() {
      return `${ this.htmlId }_btn_modal_create`;
    },

    canCreateNachrichtenLocal() {
      return this.canCreateNachrichten && this.hasWFPermissionFoerderantragVerantwortlicheBenachrichtigen;
    },

    hasWFPermissionFoerderantragVerantwortlicheBenachrichtigen() {
      return this.checkPermissionsSync({
        perm: "foerderantrag.verantwortliche_benachrichtigen",
        permArray: this.objectPermissions,
      });
    },

    selectorClose() {
      return `#${ this.idForModalCreateButton }`;
    },

    optionsModal() {
      return {
        header: "_TXT_NACHRICHT_AN_ANSPRECHPARTNER_VERSENDEN_",
        showCloseButton: true,
        required: true,
        list: [
          this.optionsTitel,
          this.optionsText,
        ],
      };
    },

    optionsTitel() {
      const ID = "titel";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        label: "_TXT_NACHRICHT_BETREFF_",
        required: true,
        view: "v",
      };
    },

    optionsText() {
      const ID = "text";
      return {
        id: "text",
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "richtext",
        label: "_TXT_NACHRICHT_TEXT_",
        required: true,
        view: "v-alt",
      };
    },
  },
  created() {
    this.loadNachrichten();
    this.setEventBus();
  },
  beforeUnmount() {
    this.destroyEventBus();
  },
  methods: {
    loadNachrichten() {
      if (!this.isBoxVisible) {
        return;
      }
      this.loading = true;
      this.getListHttp({
        url: this.url,
      }).then(
        response => {
          this.nachrichten = response;
          this.loading = false;
        }
      );
    },

    setEventBus() {
      EventBus.$on("updateNachrichten", this.loadNachrichten);
    },

    destroyEventBus() {
      EventBus.$off("updateNachrichten", this.loadNachrichten);
    },

    openModalCreate() {
      this.statusModalCreate = true;
    },

    closeModalCreate() {
      this.statusModalCreate = false;
      this.errors = undefined;
    },

    save() {
      this.statusModalLoading = true;
      this.postHttp({
        url: this.urlSave,
        data: this.model,
      }).then(
        () => {
          this.closeModalCreate();
        },
        error => {
          this.errors = error.data;
        }
      ).finally(
        () => this.statusModalLoading = false
      );
    },
  },
};
