"use strict";

export default /*@ngInject*/ ($timeout, $document, DefaultRichtextConfig) => {
  return {
    restrict: "A",
    require: "ngModel",
    scope: {
      richtextOptions: "=",
      model: "=ngModel",
      exposedApi: "=",
    },
    link: function(scope, element, attr, model) {
      if (!model) {
        return;
      }

      if (attr.startupFocus) {
        DefaultRichtextConfig.startupFocus = true;
      } else {
        DefaultRichtextConfig.startupFocus = false;
      }

      const instanceConfig = angular.merge({}, DefaultRichtextConfig, scope.richtextOptions);

      let stylePath;
      const linkEls = $document.find("link");
      for (let i = 0; i < linkEls.length; i++) {
       // main-style element has id styles-main
        if (linkEls[i].id === "styles-main") {
          stylePath = linkEls[i].href;
          break;
        }
      }
      instanceConfig.contentsCss = stylePath;
      CKEDITOR.env.isCompatible = true;
      const editor = CKEDITOR.replace(element[0], instanceConfig);


      editor.on("instanceReady", function(event) {
        event.editor.on("beforeCommandExec", function(event) {
          if (event.data.name === "paste") {
            event.editor._.forcePasteDialog = true;
          }
        });
      });

      if (scope.exposedApi !== undefined) {
        scope.exposedApi.insertHtml = html => editor.insertHtml(html);
      }

      function updateModel() {
        scope.$applyAsync(function() {
          model.$setViewValue(editor.getData());
        });
      }

      let ready = false;
     // Update Model on Editor Change
      editor.on("pasteState", updateModel);
      editor.on("change", updateModel);
     // Update Editor Data once when Editor is ready
      editor.on("instanceReady", function() {
        editor.setData(model.$viewValue);
        if (/(MSIE|Edge)/i.test(navigator.userAgent)) {
          const range = editor.createRange();
          range.moveToPosition(range.root, CKEDITOR.POSITION_BEFORE_END);
          editor.getSelection().selectRanges([range]);
        }
        ready = true;
      });

      element.on("$destroy", function() {
        if (!editor) {
          return;
        }
        if (editor.removeAllListeners) {
          editor.removeAllListeners();
        }
        CKEDITOR.remove(editor);
      });

      model.$render = () => {
        if (ready) {
          editor.setData(model.$viewValue || "<p></p>");
        }
      };
    }
  };
};
