import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";
import Modal from "../../../../global/components/Modal/Modal.vue";
import translate from "../../../../global/directives/translate";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import { isNil } from "lodash-es";

// @vue/component
export default {
  name: "FoederorgAnsprechpartnerModalZuweisen",
  components: {
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  inject: [
    "updateAnsprechpartner",
  ],
  props: {
    baseUrl: {
      type: String,
      default: undefined,
    },
    ansprechpartner: {
      type: Object,
      default: () => ({
        org: {},
      }),
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      options: {
        showCloseButton: true,
        required: true,
        list: [],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    isSaveButtonDisabled() {
      return this.status.loading || isNil(this.model.foerderorg_nutzer);
    },
  },
  created() {
    this.initList();
    this.initModel();
  },
  methods: {
    initModel() {
      this.model.ap_kommentar = this.ansprechpartner.ap_kommentar;
    },
    initList() {
      this.options.list.push(
        {
          id: "foerderorg_nutzer",
          type: "single_choice",
          label: "_LBL_MODAL_FOEDERORG_ANSPRECHPARTNER_ZUWEISEN_NUTZER_",
          required: true,
          view: "v",
          keyId: "pk",
          keyLabelCallback: ({ item }) => `${ item.nutzer_obj.name_mit_abwesenheit }`,
          menuWidthAll: true,
          url: `foerderorgnutzer/`,
          urlParams: {
            foerderorganisation: this.ansprechpartner.org
          },
          translate: true,
        },
        {
          id: "ap_kommentar",
          type: "text",
          label: "_LBL_MODAL_ANSPRECHPARTNER_ZUWEISEN_KOMMENTAR_",
          required: false,
          view: "v",
          translate: true,
        }
      );
    },

    save() {
      this.status.loading = true;
      const data = {
        foerderorg_nutzer: this.model.foerderorg_nutzer,
        ap_kommentar: this.model.ap_kommentar,
        ap_objektid: this.ansprechpartner.ap_objektid,
        ap_pos: this.ansprechpartner.ap_pos,
        content_type: this.ansprechpartner.content_type,
      };

      this.putHttp({ url: `${ this.baseUrl }/${ this.ansprechpartner.pk }/`, data }).then(
        response => {
          this.addNotification({ text: "_MSG_MODAL_FOERDERORG_ANSPRECHPARTNER_ZUWEISEN_SUCCESS_" });
          this.close({ response: response, status: true });
          this.updateAnsprechpartner();
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  }
};
