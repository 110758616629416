// @vue/component
export default {
  props: {
    kommunikationsdaten: {
      type: Object,
      required: true,
    },
    urlTypId: {
      type: String,
      required: true,
    },
    emailTypId: {
      type: String,
      required: true,
    },
    wert: {
      type: String,
      required: true,
    }
  },
  computed: {
    getHrefKomUrl() {
      return this.wert;
    },
    getHrefKomEmail() {
      return `mailto:${ this.wert }`;
    },
  },
};
