import Permission from "../../../../global/components/Permission/Permission.vue";
import PersonModalCreateOrEdit from "../../PersonalkostenPerioden/PersonalkostenPeriodenTableInVertrag/PersonModalCreateOrEdit/PersonModalCreateOrEdit.vue";
import RechenhilfeButton from "../../PersonalkostenPerioden/PersonalkostenPeriodenTableInVertrag/RechenhilfeButton/RechenhilfeButton.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import {
  CheckPermissionsSyncMixin,
} from "../../../../global/mixins/PermissionMixin";

import {
  get,
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  components: {
    Permission,
    PersonModalCreateOrEdit,
    RechenhilfeButton,
  },
  mixins: [
    CheckPermissionsSyncMixin,
    HttpMixin,
  ],
  data() {
    return {
      status: {
        loading: false,
        loadingBelegnummer: false,
        periodeLoading: true,
        personenLoading: true,
      },
      personenList: [],
      periodeList: [],
      periodeObj: {},
      isShowModalCreatePerson: undefined,
      idForButtonModalAddPerson: "modal_add_person",
      statusUse: false,
      classesHorizontal: [
        "col-sm-3 text-left text-sm-right",
        "col-sm-6",
        "offset-sm-3 col-sm-6",
      ],
    };
  },
  computed: {
    optionsForPersonalstellenperson() {
      const ID = "personalstellenperson";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "select",
        label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_PERSON_",
        tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_PERSON_INFO_I_",
        keyId: "pk",
        keyLabelJson: [
          {
            teg: "span",
            keyLabel: "vpsp_vorname",
          },
          {
            teg: "span",
            string: " ",
          },
          {
            teg: "span",
            keyLabel: "vpsp_nachname",
          },
        ],
        data: this.personenList,
        loading: this.status.personenLoading,
        required: true,
        view: this.view,
        classesHorizontal: this.classesHorizontal,
        change: this.resetWert,
      };
    },

    optionsForPosperiode() {
      const ID = "posperiode";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "select",
        label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_PERIODE_TEIL_",
        tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_PERIODE_TEIL_INFO_I_",
        required: true,
        view: this.view,
        data: this.periodeList,
        keyId: "pk",
        keyLabelJson: [
          {
            teg: "span",
            keyLabel: "von",
            filter: "date",
          },
          {
            teg: "span",
            string: " - ",
          },
          {
            teg: "span",
            keyLabel: "bis",
            filter: "date",
          },
        ],
        loading: this.status.periodeLoading,
        classesHorizontal: this.classesHorizontal,
        change: this.resetWert,
      };
    },

    optionsForVpbBez() {
      const ID = "vpb_bez";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_BEZEICHNUNG_",
        tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_BEZEICHNUNG_INFO_I_",
        required: true,
        view: this.view,
        disabled: false,
        translate: true,
        classesHorizontal: this.classesHorizontal,
      };
    },

    optionsForVpbNummer() {
      const ID = "vpb_nummer";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "text",
        label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_BELEGNUMMER_",
        tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_BELEGNUMMER_INFO_I_",
        required: true,
        view: this.view,
        disabled: this.hasNextBelegnummer || this.status.loadingBelegnummer,
        classesHorizontal: this.classesHorizontal,
      };
    },

    optionsForVon() {
      const ID = "von";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "date",
        label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_NACHWEIS_VON_",
        tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_NACHWEIS_VON_INFO_I_",
        required: true,
        view: this.view,
        disabled: this.isPeriodsVertrag && !this.model.argument.posperiode,
        notAfter: this.notAfterForVon,
        notBefore: this.notBefore,
        defaultValue: this.defaultValueForVonAndBis,
        classesHorizontal: this.classesHorizontal,
        change: this.resetWert,
      };
    },

    optionsForBis() {
      const ID = "bis";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "date",
        label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_NACHWEIS_BIS_",
        tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_NACHWEIS_BIS_INFO_I_",
        view: this.view,
        disabled: this.isPeriodsVertrag && !this.model.argument.posperiode,
        required: true,
        notAfter: this.notAfter,
        notBefore: this.notBeforeForBis,
        defaultValue: this.defaultValueForVonAndBis,
        classesHorizontal: this.classesHorizontal,
        change: this.resetWert
      };
    },

    optionsForBelegbetrag() {
      const ID = "belegbetrag";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "currency",
        label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_BELEGBETRAG_",
        tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_BELEGBETRAG_INFO_I_",
        view: this.view,
        required: true,
        disabled: false,
        classesHorizontal: this.classesHorizontal,
        change: this.resetWert
      };
    },

    optionsForWert() {
      const ID = "wert";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "currency",
        label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_BETRAG_",
        tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_BETRAG_INFO_I_",
        view: this.view,
        required: true,
        disabled: true,
        classesHorizontal: this.classesHorizontal,
      };
    },

    optionsForDokumentGeschwaerzt() {
      const ID = "dokument_geschwaerzt";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "boolean",
        label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_CONFIRMATION_",
        tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_CONFIRMATION_INFO_I_",
        view: this.view,
        classesHorizontal: this.classesHorizontal,
      };
    },

    optionsForVpbKommentar() {
      const ID = "vpb_kommentar";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        type: "textarea",
        label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_KOMMENTAR_",
        tooltip: "_HTML_VERTRAG_DETAILS_BELEG_MODAL_KOMMENTAR_INFO_I_",
        maxlength: 200,
        view: this.view,
        classesHorizontal: this.classesHorizontal,
      };
    },

    currentPerson() {
      const {
        personalstellenperson,
      } = this.model.argument;
      if (!personalstellenperson || !this.personenObj[personalstellenperson]) {
        return {};
      }
      return this.personenObj[personalstellenperson];
    },

    notAfterForVon() {
      if (this.model.argument.bis) {
        return this.model.argument.bis;
      }
      return this.notAfter;
    },

    notBeforeForBis() {
      if (this.model.argument.von) {
        return this.model.argument.von;
      }
      return this.notBefore;
    },

    modelPosperiode() {
      return this.model.argument.posperiode;
    },

    notBefore() {
      if (!this.modelPosperiode || !this.periodeObj[this.modelPosperiode]) {
        return;
      }
      return this.periodeObj[this.modelPosperiode].von;
    },

    notAfter() {
      if (!this.modelPosperiode || !this.periodeObj[this.modelPosperiode]) {
        return;
      }
      return this.periodeObj[this.modelPosperiode].bis;
    },

    defaultValueForVonAndBis() {
      return this.notBefore;
    },

    statusDiasbledButtonBetragBerechnen() {
      return !!(!this.model.argument.personalstellenperson ||
        !this.modelPosperiode ||
        !this.model.argument.von ||
        !this.model.argument.bis ||
        !this.model.argument.belegbetrag);
    },

    periodePk() {
      return get(this.periodeObj, `${ this.modelPosperiode }.periode_obj.pk`);
    },

    personenObj() {
      return keyBy(this.personenList, "pk");
    },

    personalstellePk() {
      return get(this.position, "personalstellen[0]");
    },

    urlForPerioden() {
      return `vertraege/${ this.vertrag.pk }/vertragspositionperioden/?position=${ this.position.pk }`;
    },

    urlForPersonen() {
      return `vertraege/${ this.vertrag.pk }/vertragspersonalstellenpersonen/?ordering=crdate&position=${ this.position.pk }`;
    },

    vertragPermissions() {
      return this.vertrag.user_permissions;
    },

    isPeriodsVertrag() {
      return get(this.extra, "modelParameter.perioden_vertrag", true);
    },

    statusHasPermVertragskostenpositionsbelegeAnerkennen() {
      return this.checkPermissionsSync({ perm: "vertragskostenpositionsbelege.anerkennen" });
    },
  },
  created() {
    this.loadPeriode();
    this.loadPersonen();
  },
  methods: {
    loadPeriode() {
      this.getListHttp({
        url: this.urlForPerioden,
      }).then(
        response => {
          this.periodeList = response;
          this.periodeObj = keyBy(response, "pk");
          this.setModelPosperiode();
          this.setVertragkosten();
          this.status.periodeLoading = false;
        }
      );
    },

    setModelPosperiode() {
      if (!this.beleg && !this.isPeriodsVertrag && this.periodeList.length) {
        this.model.argument.posperiode = this.periodeList[0].pk;
      }
    },

    setVertragkosten() {
      if (this.periodeList && this.periodeList.length) {
        this.vertragskosten = this.periodeList[0].vertragskosten;
      }
    },

    loadPersonen() {
      this.getListHttp({
        url: this.urlForPersonen,
      }).then(
        response => {
          this.personenList = response;
          this.status.personenLoading = false;
        }
      );
    },

    openModalAddPerson() {
      this.isShowModalCreatePerson = true;
    },

    closeModalCreatePerson({ response } = {}) {
      if (response) {
        this.addPerson({ person: response });
        this.setPersonInModel({ person: response });
      }
      this.isShowModalCreatePerson = false;
      this.setFocusWithTimeout(this.getIdForFocusElement(!!response));
    },

    getIdForFocusElement(statusSaved) {
      return statusSaved ?
        `#${ this.optionsForPersonalstellenperson.htmlId }` :
        `#${ this.idForButtonModalAddPerson }`;
    },

    setFocusWithTimeout(selector) {
      setTimeout(() => {
        const ELEMENT = $(selector);
        if (ELEMENT) {
          ELEMENT.focus();
        }
      });
    },

    addPerson({ person }) {
      this.personenList.push(person);
    },

    setPersonInModel({ person }) {
      this.model.argument[this.optionsForPersonalstellenperson.id] = person.pk;
    },

    resetWert() {
      this.model.argument.wert = undefined;
    },

    saveRechenhilfeButton({ betrag, von, bis }) {
      this.model.argument.wert = betrag;
      this.model.argument.von = von;
      this.model.argument.bis = bis;
      this.statusUse = true;
    },
  },
};
