import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "ZielgruppenAuswahlCheckboxOrRadio",
  props: {
    gruppe: {
      type: Object,
      required: true,
    },
    mehrfachauswahl: {
      type: Boolean,
      required: true,
    },
    htmlRegelNummer: {
      type: String,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    modelObj: {
      type: Object,
      required: true,
    },
    updateModel: {
      type: Function,
      required: true,
    },
  },
  computed: {
    checkboxOrRadio() {
      return this.mehrfachauswahl ? "checkbox" : "radio";
    },
    idCheckbox() {
      return `${ this.htmlRegelNummer }_check_${ this.gruppe.pk }`;
    },
    nameCheckbox() {
      return `${ this.htmlRegelNummer }_check`;
    },
  },
  methods: {
    clickCheckbox() {
      const MODEL = cloneDeep(this.model);
      if (this.modelObj[this.gruppe.pk]) {
        if (!this.mehrfachauswahl) {
          return;
        }
        MODEL.wahl = [];
      } else {
        MODEL.wahl = [this.gruppe.pk];
        if (this.gruppe.untergruppen && this.gruppe.untergruppen.length) {
          forEach(this.gruppe.untergruppen, untergruppe => {
            MODEL.wahl.push(untergruppe.pk);
          });
        }
      }
      this.updateModel({ model: MODEL });
    },

    clickCheckboxUntergruppe(untergruppePk) {
      const MODEL = cloneDeep(this.model);
      if (this.modelObj[untergruppePk]) {
        let statusGruppe = false;
        MODEL.wahl = [];
        const UNTERGRUPPEN = [];
        forEach(this.gruppe.untergruppen, untergruppe => {
          if (untergruppe.pk !== untergruppePk && this.modelObj[untergruppe.pk]) {
            UNTERGRUPPEN.push(untergruppe.pk);
            statusGruppe = true;
          }
        });
        if (statusGruppe) {
          MODEL.wahl.push(this.gruppe.pk);
        }
        MODEL.wahl.push(...UNTERGRUPPEN);
      } else {
        MODEL.wahl = [this.gruppe.pk, untergruppePk];
        forEach(this.gruppe.untergruppen, untergruppe => {
          if (untergruppe.pk !== untergruppePk && this.modelObj[untergruppe.pk]) {
            MODEL.wahl.push(untergruppe.pk);
          }
        });
      }
      this.updateModel({ model: MODEL });
    },
  },
};
