"use strict";

import angular from "angular";
import BaseController from "./standard_foerdermodule.base.controller";
import { forEach, union } from "lodash";

class StandardFoerdermoduleEditController extends BaseController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule) {
    super();
    this.name = "StandardFoerdermoduleEditController";
    this.form = {};
    this.Foerdermodule = Foerdermodule;
    this.$scope = $scope;
    this.$q = $q;
    this.currentDefer = undefined;
    this.models = {};
    this.errors = {};
    this.saved = angular.copy(this.userInput);
    this.group = this.group !== undefined ? this.group : "default_grp";
    this.registerEventListeners();
    this.globalBearbeiten = false;

    this.hasBaseEditPerm = this.hasBaseEditPerm === undefined ? !this.readonly : this.hasBaseEditPerm;
  }

  registerEventListeners() {
    this.Foerdermodule.listenToSaveRequest(this.$scope, this.group, () => this.onSave());
  }

  // Overwrite to include form validation
  isMissing(fieldName) {
    return super.isMissing(fieldName) && this.form !== undefined && this.form[fieldName] !== undefined && this.form[fieldName].$valid;
  }

  hasModulPermission(recht) {
    if (this.readonly) {
      return false;
    }
    if (this.modul.regel.rechte === undefined || this.modul.regel.rechte[recht] === undefined) {
      return this.hasBaseEditPerm;
    }
    return this.modul.regel.rechte[recht];
  }


  isReadonly() {
    return !this.hasModulPermission("update");
  }

  onSave() {
    // if save not already in progress
    if (!this.loading) {
    // if(this.currentDefer === undefined){
      this.loading = true;
      this.currentDefer = this.$q.defer();
      this.Foerdermodule.respondToSave(this.$scope, this.modul.regel.pk, this.currentDefer.promise, this.group);
      // reject save request if form is invalid
      if (this.form.$invalid) {
        this.currentDefer.reject(this.form.$error);
        // this.currentDefer = undefined;
        this.loading = false;
        return this.currentDefer.promise;
      }
      this.modul.save(this.userInput).then(
        response => {
          this.setModulData(response.data);
          this.currentDefer.resolve(response);
          this.updateSnapshotDiff();
          if (angular.isFunction(this.savecallback)) {
            this.savecallback()();
          }
        },
        errors => {
          this.errors = errors.data;
          this.currentDefer.reject(errors);
        }
      ).finally(() => {
        this.currentDefer = undefined;
        this.loading = false;
      });
    }
    return this.currentDefer.promise;
  }

  setModulData(data) {
    this.saved = data;
    this.errors = {};
    this.setEditMode(false);
  }

  hasErrors() {
    if (this.form === undefined || this.form.$invalid) {
      return true;
    }
    for (const key in this.errors) {
      if (!angular.isUndefined(this.errors[key]) && this.errors[key].length) {
        return true;
      }
    }
    return false;
  }

  isClean() {
    return angular.equals(this.saved, this.userInput);
  }

  resetUserInput() {
    const KEYS = union(
      Object.keys(this.userInput),
      Object.keys(this.saved),
    );
    forEach(KEYS, key => {
      this.userInput[key] = angular.copy(this.saved[key]);
    });
  }

  toggleEditMode() {
    this.setEditMode(!this.editMode);
  }

  setEditMode(bool) {
    this.editMode = bool;
    if (bool) {
      this.editEnteredOnce = true;
    }
    if (!bool) {
      this.resetUserInput();
      this.errors = {};
    }
  }

  saveChanges() {
    this.onSave();
  }

  updateSnapshotDiff() {
    if (this.snapshot) {
      this.loadingDiff = true;
      this.obj.snapshots.one(this.snapshot.pk).one("diff").get().then(response => {
        this.snapshotdiff = {
          diff_result: {
            modified: response.diff_result.modified[this.modul.regel.anzeigekontext] || {},
            added: response.diff_result.added[this.modul.regel.anzeigekontext] || {},
            removed: response.diff_result.removed[this.modul.regel.anzeigekontext] || {}
          }
        };
        this.checkSnapshotDiff();
      }).finally(() => this.loadingDiff = false);
    }
  }
}

export default StandardFoerdermoduleEditController;
