import PuxButton from "../../PuxButton/PuxButton.vue";

import init from "../../../directives/init";

import {
  EventBus,
} from "../../../functions/event-bus";
import {
  setHeightAsideAndMenu,
  setStyleTopForFixedVertical,
} from "../../../functions/utilsDOM";

// @vue/component
export default {
  name: "TableDetailsRight",
  components: {
    PuxButton,
  },
  directives: {
    init,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    statusShow: {
      type: Boolean,
    },
    detailsObj: {
      type: Object,
      default: () => ({}),
    },
    replaceDetailsObj: {
      type: Function,
      default: () => {},
    },
    toggleTableCollapse: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      currentRowPk: undefined,
      headerHeight: 50,
    };
  },
  computed: {
    idForButtonClose() {
      return `${ this.options.id }_details_close`;
    },

    slotNameTableDetails() {
      return `${ this.options.id }_table_details`;
    },

    slotNameTableDetailsHeader() {
      return `${ this.options.id }_table_details_header`;
    },

    stylesFilterBody() {
      return { height: `calc(100% - ${ this.headerHeight }px)` };
    },
  },
  watch: {
    statusShow() {
      if (this.statusShow) {
        setTimeout(() => {
          this.headerHeight = this.$refs.fixed_vertical__header && this.$refs.fixed_vertical__header.clientHeight;
        });
      }
    },
  },
  created() {
    this.initEventBus();
  },
  mounted() {
    this.setHeaderHeight();
  },
  beforeUnmount() {
    EventBus.$off(`showDetails${ this.options.id }`, this.showDetails);
  },
  methods: {
    initEventBus() {
      EventBus.$on(`showDetails${ this.options.id }`, this.showDetails);
    },

    setHeaderHeight() {
      setTimeout(() => {
        this.headerHeight = this.$refs.fixed_vertical__header && this.$refs.fixed_vertical__header.clientHeight;
      });
    },

    setAsideHeight() {
      setTimeout(() => {
        setStyleTopForFixedVertical();
        setHeightAsideAndMenu();
      });
    },

    closeDetails() {
      this.toggleTableCollapse(false);
      this.currentRowPk = undefined;
      EventBus.$emit(`closeRightDetails${ this.options.id }`);
    },

    showDetails({ pk } = {}) {
      this.currentRowPk = pk;
      this.setHeaderHeight();
    },
  },
};
