import BelegInTd from "../BelegInTd/BelegInTd.vue";
import DurchfuehrungszeitraumLine from "../DurchfuehrungszeitraumLine/DurchfuehrungszeitraumLine.vue";
import DurchfuehrungszeitraumLineMixin from "../DurchfuehrungszeitraumLine/DurchfuehrungszeitraumLineMixin";

// @vue/component
export default {
  name: "BelegeTd",
  components: {
    BelegInTd,
    DurchfuehrungszeitraumLine,
  },
  mixins: [
    DurchfuehrungszeitraumLineMixin,
  ],
  props: {
    belegeObj: {
      type: Object,
      required: true,
    },
    period: {
      type: Object,
      required: true,
    },
    durchfuehrung: {
      type: Object,
      required: true,
    },
  },
  computed: {
    belegeForPeriod() {
      if (this.belegeObj[this.period.pk] && this.belegeObj[this.period.pk].length) {
        return this.belegeObj[this.period.pk];
      }
      return undefined;
    },
  },
};
