import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxPageDetails from "../../../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import Spaced from "../../../../../global/components/Spaced/Spaced.vue";
import SmartTable from "../../../../../global/components/table/table.vue";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

import getTableApi from "../../../../../const/TableApis";

const TABLE_ID = "BerichtRueckfoerderungen";

// @vue/component
export default {
  name: "BerichtRueckfoerderungen",
  components: {
    AngularLink,
    PuxPageDetails,
    Spaced,
    SmartTable,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      filterCurrency,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_LBL_BERICHT_RUCKFOERDERUNGEN_ROW_ACTIONS_DETAILS_",
            route: "root.vertrag.details",
            routeParameters: [{ routeId: "id", rowPath: "vertrag_id" }],
          },
        ],
        label: this.report.tab_kbez,
        sortable: true,
        pagination: true,
        details: false,
        showHeader: false,
        view: {
          filter: "top",
        },
      },
    };
  },
};
