"use strict";

import BaseModel from "dias/core/models/baseModel";

class Suche extends BaseModel {
  constructor() {
    super("suche");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    
    collection.doSearch = data => {
      return collection.customGET("", data);
    };
    
    return collection;
  }
}

export default Suche;
