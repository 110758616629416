import {
  computed,
  ref,
} from "vue";

import AngularLink from "../../global/components/AngularLink/AngularLink.vue";
import MenuElementLevel1 from "./MenuElementLevel1/MenuElementLevel1.vue";
import PuxButton from "../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";

import init from "../../global/directives/init";
import translate from "../../global/directives/translate";

import HttpAPI from "../../global/compositionAPI/HttpAPI";
import MobileMenuAPI from "./MobileMenuAPI";
import SyConfigAPI from "../../global/compositionAPI/SyConfigAPI";
import TheMenuAPI from "./TheMenuAPI";
import TheMenuOrderByAPI from "./TheMenuOrderByAPI";

import {
  setHeightAsideAndMenu,
} from "../../global/functions/utilsDOM";
import {
  EventBus,
} from "../../global/functions/event-bus";
import {
  isUndefined,
} from "lodash-es";

// @vue/component
export default {
  name: "TheMenu",
  components: {
    AngularLink,
    MenuElementLevel1,
    PuxButton,
    PuxCloak,
    PuxTranslate,
  },
  directives: {
    init,
    translate,
  },
  setup() {
    const {
      checkActiveRouteAndSetPageTitle,
      filterMenuWithInstancePages,
      filterMenuWithPermissions,
      startOptions,
      stateChildActivePk,
      stateParentActivePk,
      updateState,
    } = TheMenuAPI();

    const {
      orderByMenu,
    } = TheMenuOrderByAPI();

    const {
      getListHttp,
    } = HttpAPI();

    const {
      getSyConfigsValue,
    } = SyConfigAPI();

    const {
      classParent,
      closeMobileMenu,
      openMobileMenu,
      refH1,
      statusMobileMenuOpen,
    } = MobileMenuAPI({ closeMobileMenuCallback: setClassForWrapperContent });

    const statusMenuActive = ref(false);
    const buttonToggleIcon = computed(() => {
      return statusMenuActive.value ? "double-left" : "send";
    });
    const buttonToggleTitleTranslate = computed(() => {
      return statusMenuActive.value ? "_BTN_MENU_TOGGLE_TITLE_CLOSE_" : "_BTN_MENU_TOGGLE_TITLE_OPEN_";
    });

    function setClassForWrapperContent() {
      const WRAPPER_CONTENT = document.getElementById("wrapper_content");
      if (!WRAPPER_CONTENT) {
        return;
      }
      if (statusMenuActive.value) {
        WRAPPER_CONTENT.classList.add("active");
      } else {
        WRAPPER_CONTENT.classList.remove("active");
      }
    }

    return {
      buttonToggleIcon,
      buttonToggleTitleTranslate,
      checkActiveRouteAndSetPageTitle,
      classParent,
      closeMobileMenu,
      filterMenuWithInstancePages,
      filterMenuWithPermissions,
      getListHttp,
      getSyConfigsValue,
      openMobileMenu,
      orderByMenu,
      refH1,
      setClassForWrapperContent,
      startOptions,
      stateChildActivePk,
      stateParentActivePk,
      statusMenuActive,
      statusMobileMenuOpen,
      updateState,
    };
  },
  data() {
    return {
      statusLoading: true,
      statusShowButtonToggle: true,
      statusShowMenuDetails: undefined,
      statusLinkDisabled: false,
      menuParentActivePk: undefined,
      user: {},
      menuDefault: [],
      menu: [],
      menuId: "main_menu",
    };
  },
  created() {
    this.checkSyConfig();
    this.loadData();
    this.setEventBuses();
  },
  beforeUnmount() {
    this.deleteEventBuses();
  },
  methods: {
    checkSyConfig() {
      const CONFIG = this.getSyConfigsValue("menue_config") || {};
      if (CONFIG.active) {
        this.toggleMenu({ statusShow: true });
      }
      this.statusShowMenuDetails = CONFIG.menu_details;
    },

    loadData() {
      Promise.all([
        this.getListHttp({
          url: "menu_readonly/",
          urlParams: {
            top: true,
          },
        }),
      ]).then(
        responses => {
          const MENU_DEFAULT = this.filterMenuWithInstancePages({ response: responses[0] });
          this.menuDefault = this.orderByMenu({ menu: MENU_DEFAULT });
          this.filterMenuWithPermissionsLocal();
          this.checkActiveRouteAndSetPageTitle({ menu: this.menu });
        }
      ).then(
        () => {
          this.statusLoading = false;
        }
      );
    },

    setEventBuses() {
      EventBus.$on("updateProfile", this.filterMenuWithPermissionsLocal);
      EventBus.$on("toggleMenuDetails", this.toggleMenuDetails);
      EventBus.$on("updateState", this.updateStateLocal);
      EventBus.$on("openMobileMenu", this.openMobileMenuLocal);
    },

    deleteEventBuses() {
      EventBus.$off("updateProfile", this.filterMenuWithPermissionsLocal);
      EventBus.$off("toggleMenuDetails", this.toggleMenuDetails);
      EventBus.$off("updateState", this.updateStateLocal);
      EventBus.$off("openMobileMenu", this.openMobileMenuLocal);
    },

    toggleMenuDetails({ statusShow }) {
      if (statusShow) {
        this.toggleMenu({ statusShow });
      }
      this.statusShowButtonToggle = !statusShow;
    },

    toggleMenu({ statusShow } = {}) {
      this.setMenuParentActivePk({ pk: undefined });
      if (!isUndefined(statusShow)) {
        if (this.statusMenuActive === statusShow) {
          return;
        }
        this.statusMenuActive = statusShow;
      } else {
        this.statusMenuActive = !this.statusMenuActive;
      }
      if (this.statusMenuActive) {
        EventBus.$emit("openMenu");
      }
      this.setClassForWrapperContent();
      if (this.$refs.main_menu_toggle) {
        this.$refs.main_menu_toggle.closeTooltip();
      }
    },

    setMenuParentActivePk({ pk }) {
      if (this.menuParentActivePk === pk) {
        this.menuParentActivePk = undefined;
      } else {
        this.menuParentActivePk = pk;
      }
    },

    setStatusLinkDisabled() {
      this.statusLinkDisabled = true;
      this.closeMobileMenu();
      setTimeout(() => {
        this.statusLinkDisabled = false;
      }, 1000);
    },

    setMenuHeight() {
      setHeightAsideAndMenu();
    },

    openMobileMenuLocal() {
      this.openMobileMenu({
        openMobileMenuCallback: this.openMobileMenuCallback,
      });
    },

    openMobileMenuCallback() {
      this.statusMenuActive = true;
    },

    filterMenuWithPermissionsLocal() {
      this.menu = this.filterMenuWithPermissions({ menuDefault: this.menuDefault });
    },

    updateStateLocal() {
      this.updateState({ menu: this.menu });
    },
  },
};
