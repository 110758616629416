"use strict";
import angular from "angular";
import listComponent from "./list.component";
import frageDetailComponent from "./frage.details.component";
import antwortDetailComponent from "./antwort.details.component";
import Foerderfinderfragen from "./models/foerderfinderfrage.service";
import Foerderfinderkategorietypen from "./models/foerderfinderkategorietypen.service";
import Foerderfinderkategorien from "./models/foerderfinderkategorien.service";
import antwortTypUUIDs from "./service/kfoerderfinderantworttypUUIDs.service";
import FoerderfinderHierarchie from "./service/foerderfinderhierarchie.service";
import hierarchieFrageComponent from "./hierarchiefrage.component";
import kategoriewertButtonComponent from "./kategorie.button.component";

const foerderfinderModule = angular.module("foerderfinder", [

]);

foerderfinderModule.config($stateProvider => {
  $stateProvider
    // Liste der Fragen (und Treeview?)
    .state("root.foerderfinderfragen", {
      url: "/foerderfinderfragen/",
      views: {
        "main@root": {
          template: "<foerderfinderfragen-list></foerderfinderfragen-list>"
        }
      },
      data: {
        permissions: "foerderfinderfrageantwort.view"
      }
    })

   // Detailseite der Förderfinderfragen
    // Sollte immer als letztes eingefügt werden, sonst werden Sub-URLs
    // as ID aufgelöst
    .state("root.foerderfinderfragen.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<foerderfinderfragen-details></foerderfinderfragen-details>"
        }
      },
    })
    .state("root.foerderfinderfragen.detail.antwort", {
      url: "antworten/:ffa_id/",
      views: {
        "main@root": {
          template: "<foerderfinderantworten-details></foerderfinderantworten-details>"
        }
      },
    });
})

  .component("foerderfinderfragenList", listComponent)
  .component("foerderfinderfragenDetails", frageDetailComponent)
  .component("hierarchiefrage", hierarchieFrageComponent)
  .component("foerderfinderantwortenDetails", antwortDetailComponent)
  .component("kategoriewertButton", kategoriewertButtonComponent)
  .provider("Foerderfinderfragen", Foerderfinderfragen)
  .provider("FoerderfinderHierarchie", FoerderfinderHierarchie)
  .provider("Foerderfinderkategorietypen", Foerderfinderkategorietypen)
  .provider("Foerderfinderkategorien", Foerderfinderkategorien)
  .constant("KFoerderfinderantwortTypUUIDS", antwortTypUUIDs);

foerderfinderModule.factory("Foerderfinderantworttypen", Restangular => {
  return Restangular.service("foerderfinderantworttypen");
});

export default foerderfinderModule;
