import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import Permission from "../../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import SyConfigMixin from "../../../../../global/mixins/SyConfigMixin";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "AstVertretungsberechtigteTr",
  components: {
    SnapshotIcon,
    Permission,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    SyConfigMixin,
  ],
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: undefined,
    },
    statusReadonly: {
      type: Boolean,
      required: false,
    },
    statusSnapshot: {
      type: Boolean,
      required: false,
    },
    statusSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    statusSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    statusSnapshotChanges: {
      type: Boolean,
      required: false,
    },
    statusSnapshotChangesIcon: {
      type: Boolean,
      required: false,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    snapshotChangesDiff: {
      type: Object,
      required: false,
      default: undefined,
    },
    htmlRegelNummer: {
      type: String,
      required: false,
      default: undefined,
    },
    openModalCreateOrUpdate: {
      type: Function,
      required: false,
      default: undefined,
    },
    openModalDelete: {
      type: Function,
      required: false,
      default: undefined,
    },
    permissions: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const {
      filterDate,
      filterIban,
    } = FiltersAPI();

    return {
      filterDate,
      filterIban,
    };
  },
  computed: {
    titel() {
      if (this.vertreterConfig.titel_katalog) {
        return this.data.titel_bez;
      }
      return this.data.av_titel;
    },

    idButtonEdit() {
      return `${ this.htmlRegelNummer }_edit_${ this.data.pk }`;
    },

    idButtonDelete() {
      return `${ this.htmlRegelNummer }_delete_${ this.data.pk }`;
    },

    statusShowButtons() {
      return !this.statusReadonly && !this.statusSnapshotDelete && !this.statusSnapshotChanges;
    },

    snapshotIconFirstTdType() {
      if (this.statusSnapshotAdd) {
        return "add";
      }
      if (this.statusSnapshotDelete) {
        return "delete";
      }
      if (this.statusSnapshotChangesIcon) {
        return "changes";
      }
      return "";
    },

    translateVersionTdOptions() {
      return {
        text: this.translateVersionTdText,
        extra: {
          date: this.snapshotDate,
        },
      };
    },

    translateVersionTdText() {
      if (this.statusSnapshotAdd) {
        return "_TXT_AKTUELLE_VERSION_ADDED_COLUMN_{{date}}_";
      }
      if (this.statusSnapshotDelete) {
        return "_TXT_VERSION_REMOVED_COLUMN_{{date}}_";
      }
      if (this.statusSnapshotChanges) {
        return "_TXT_VERSION_MODIFIED_COLUMN_{{date}}_";
      }
      return "_TXT_AKTUELLE_VERSION_";
    },

    classVersionFirst() {
      if (this.statusSnapshotDelete || this.statusSnapshotChanges) {
        return "version-bg";
      }
      return "";
    },

    rowspanFirstTd() {
      return this.statusSnapshotChangesIcon ? 2 : 1;
    },

    statusShowFirstTd() {
      return !(this.statusSnapshotChanges && !this.statusSnapshotChangesIcon);
    },

    diffLocal() {
      return this.snapshotChangesDiff || {};
    },

    titleChanged() {
      if (this.vertreterConfig.titel_katalog) {
        return this.diffLocal.titel;
      }
      return this.diffLocal.av_titel;
    },

    vertreterConfig() {
      return this.getSyConfigsValue("vertretungsberechtigte") || {};
    },
  },
};
