import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";

import ModuleMixin from "../ModuleMixin";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import {
  isEmpty,
  cloneDeep,
  sortBy,
  isNil
} from "lodash-es";

// @vue/component
export default {
  name: "Kennzahlen",
  components: {
    PuxButton,
    SnapshotIcon,
    SnapshotModule,
  },
  mixins: [
    ModuleMixin,
  ],
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      filterCurrency,
    };
  },
  computed: {
    labelHeader() {
      return this.modelParameter.title;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },

    modelSorted() {
      const MODEL = cloneDeep(this.model);
      MODEL.kennzahlen = sortBy(MODEL.kennzahlen, ["pos", "id"]);
      return MODEL;
    },

    snapshotSorted() {
      if (this.snapshotModule && this.snapshotModule.kennzahlen) {
        const SNAP = cloneDeep(this.snapshotModule);
        SNAP.kennzahlen = sortBy(SNAP.kennzahlen, ["pos", "id"]);
        return SNAP;
      }
      return undefined;
    },

    getKennzahlCls() {
      return kennzahl => !isNil(kennzahl.cssklassen) ? kennzahl.cssklassen : "col-12";
    },

    iconClsButtonRefresh() {
      return this.statusReloading ? " rotate" : "";
    },

    buttonIdRefresh() {
      return `${ this.htmlRegelNummer }_refresh`;
    },

    showKennzahlWert() {
      return kennzahl => !isNil(kennzahl.wert);
    },

    getKennzahlWert() {
      return kennzahl => this.filterCurrency(kennzahl.wert, kennzahl.unit || "", kennzahl.decimal_places || 0, isNil(kennzahl.digit_grouping));
    },

    isModuleEditable() {
      return false;
    },
  },
};
