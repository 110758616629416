"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import models from "./models";
import berichte from "./berichte";
import parameter from "./parameter";
import regeln from "./regel";
import vorlagen from "./vorlagen";

const projektberichte = angular.module("root.projektberichte", [
  uiRouter,
  models.name,
  berichte.name,
  parameter.name,
  regeln.name,
  vorlagen.name,
]);

export default projektberichte;
