import Permission from "../../../../../global/components/Permission/Permission.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import VertragOverviewTermin from "../VertragOverviewTermin/VertragOverviewTermin.vue";

import SyConfigMixin from "../../../../../global/mixins/SyConfigMixin";
import {
  FilterDateMixin,
} from "../../../../../global/mixins/FiltersMixin";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragOverviewVertrag",
  components: {
    Permission,
    PuxIcon,
    PuxTranslate,
    VertragOverviewTermin,
  },
  mixins: [
    FilterDateMixin,
    SyConfigMixin,
  ],
  props: {
    vertrag: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      termineConf: undefined,
    };
  },
  computed: {
    antragsTitle() {
      return this.vertrag.antrag_titel;
    },

    vertragTerminNext() {
      return this.filterDate(this.vertrag.naechster_termin, "DD. MMMM YYYY");
    },

    vertragHasTermine() {
      return this.vertragTermine && this.vertragTermine.length;
    },

    vertragTermine() {
      return get(this.vertrag, "termine.vertrag");
    },

    vertragDocumentsExist() {
      return this.vertrag.dokumenten && this.vertrag.dokumenten.length;
    },
  },
  created() {
    this.setConfig();
  },
  methods: {
    setConfig() {
      this.termineConf = this.getSyConfigsValue("v_termine_in_uebersicht", false);
    },
  },
};
