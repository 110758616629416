"use strict";

import "restangular";
import angular from "angular";

import BenachrichtigungenService from "./benachrichtigungen.service";

const modelsModule = angular.module("benachrichtigungen.services", [
  "restangular",
])

  .service("BenachrichtigungenService", BenachrichtigungenService)
;

export default modelsModule;
