import translate from "../../../../directives/translate";
import NotificationMixin from "../../../../mixins/NotificationMixin";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  directives: {
    translate,
  },
  mixins: [
    NotificationMixin,
  ],
  props: {
    dataForFeld: {
      type: Array,
      required: true,
    },
    model: {
      type: Array,
      required: true,
    },
    currentModel: {
      type: Object,
      default: undefined,
    },
    currentIndex: {
      type: Number,
      default: undefined,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modelLocal: {
        label: null,
        id: null,
        bez: null,
        empty_text: null,
        omit_if_empty: false,
        style_bold: false
      },
    };
  },
  computed: {
    getHeader() {
      return this.currentModel ? "Feld ändern" : "Feld hinzufügen";
    },

    getOptions() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            type: "select",
            id: "id",
            label: "Feld",
            required: true,
            view: "v",
            keyLabel: "bez",
            keyId: "id",
            deselect: false,
            data: this.dataForFeld,
            change: this.onSelectFeld,
          },
          {
            type: "text",
            id: "label",
            label: "Label für die Anzeige",
            required: false,
            view: "v",
          },
          {
            type: "text",
            id: "empty_text",
            label: "Text wenn keine Angabe",
            required: false,
            view: "v",
          },
          {
            type: "boolean",
            id: "omit_if_empty",
            label: "Ausblenden wenn keine Angabe",
            required: false,
            view: "v-alt",
          },
          {
            type: "boolean",
            id: "style_bold",
            label: "Schrifttyp Fett",
            required: false,
            view: "v-alt",
          },
        ],
      };
    },

    isDisabledSaveButton() {
      return !this.modelLocal.id;
    },
  },
  beforeCreate() {
    this.$options.components = this.$options.components || {};
    this.$options.components.Modal = require("../../../Modal/Modal.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.currentModel) {
        this.modelLocal = cloneDeep(this.currentModel);
      }
    },

    save() {
      if (this.isDisabledSaveButton) {
        return;
      }
      let alternative_label = this.modelLocal.label;
      if (!alternative_label) {
        alternative_label = this.modelLocal.bez;
      }
      const DATA = {
        label: alternative_label,
        bez: this.modelLocal.bez,
        id: this.modelLocal.id,
        omit_if_empty: this.modelLocal.omit_if_empty,
        empty_text: this.modelLocal.empty_text,
        style_bold: this.modelLocal.style_bold,
      };
      const MODEL = cloneDeep(this.model) || [];

      if (this.currentModel) {
        MODEL.splice(this.currentIndex, 1, DATA);
      } else {
        MODEL.push(DATA);
      }
      this.close({ model: MODEL });
    },

    onSelectFeld({ item }) {
      if (this.modelLocal.id) {
        this.modelLocal.bez = item.bez;
        this.modelLocal.label = item.bez;
      }
    },
  },
};
