"use strict";

import template from "./angebotsversionen.template.jade";

class AngebotsVersionenController {
  /*@ngInject*/
  constructor($element, $scope, $compile) {
    this.name = "AngebotsVersionenController";

    this.$compile = $compile;
    this.$element = $element;
    this.$scope = $scope;

    this.loading = false;

    if (!this.regelsaetze) {
      // Wenn keine Regelsaetze übergeben wurden, laden:
      this.loading = true;
      this.angebot.getRegelsaetze("-ars_version").then(
        result => this.regelsaetze = result
      ).finally(() => this.loading = false);
    }
  }
}

export default {
  template: template(),
  controller: AngebotsVersionenController,
  restrict: "E",
  controllerAs: "vm",
  bindings: {
    angebot: "=",
    regelsaetze: "=?",
    onVersionClick: "&"
  }
};
