"use strict";

class DiasConfirmController {
  /*@ngInject*/
  constructor($scope, $templateCache) {
    this.name = "DiasConfirmController";
    // Titel
    if ($scope.confirmTitleTemplate) {
      this.confirmTitleTemplate = "confirmDialogTitleTemplate.html";
      $templateCache.put(this.confirmTitleTemplate, $scope.confirmTitleTemplate);
    } else {
      this.confirmTitle = $scope.confirmTitle ? $scope.confirmTitle : "Aktion durchführen?";
      this.confirmTitleTemplate = null;
    }
    // Inhalt
    if ($scope.confirmContentTemplate) {
      this.confirmContentTemplate = "confirmDialogContentTemplate.html";
      $templateCache.put(this.confirmContentTemplate, $scope.confirmContentTemplate);
    } else {
      this.confirmContent = $scope.confirmMsg ? $scope.confirmMsg : "Sind Sie sicher, dass Sie diese Aktion durchführen wollen?";
      this.confirmContentTemplate = null;
    }
    this.confirmType = $scope.confirmType ? $scope.confirmType : "primary";
    this.confirmOkLabel = $scope.confirmOkLabel ? $scope.confirmOkLabel : "Fortfahren";
    this.confirmOkIcon = $scope.confirmOkIcon ? $scope.confirmOkIcon : "glyphicon-ok";
    this.confirmCancelLabel = $scope.confirmCancelLabel ? $scope.confirmCancelLabel : "Abbrechen";
    this.confirmCancel = $scope.confirmCancel;
    this.confirmOk = $scope.confirmOk;
    this.confirmSwitchBtnPos = $scope.confirmSwitchBtnPos;
    // Zusätzliche Kontext-Werte übernehmen
    angular.forEach($scope.context, (value, key) => this[key] = value);

    this.modalInstance = $scope.modalInstance;
    this.scope = $scope;

    const fn = this.closeWithCallback;
    $scope.close = this.modalInstance.hide;
    this.modalInstance.hide = function() {
      fn($scope.confirmCancel, $scope);
      $scope.$apply();
    };
  }

  confirm() {
    this.modalInstance.hide = this.scope.close;
    return this.closeWithCallback(this.confirmOk, this.scope);
  }

  cancel() {
    this.modalInstance.hide = this.scope.close;
    return this.closeWithCallback(this.confirmCancel, this.scope);
  }

  closeWithCallback(callback, ctx) {
    if (callback === undefined) {
      ctx.close();
      return;
    }
    const defer = callback();
    if (defer !== undefined && defer.then !== undefined && defer.then instanceof Function) {
      ctx.loading = true;
      defer.then(function(response) {
        if (angular.isObject(ctx.responseScope)) {
          ctx.responseScope.response = response;
        }
      }).finally(function() {
        ctx.close();
        ctx.loading = false;
      });
    } else {
      ctx.close();
    }
  }
}

export default DiasConfirmController;
