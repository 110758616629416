"use strict";
import angular from "angular";

import sitzungsmodule from "dias/sitzungen/sitzungsmodule";

import detail from "./detail.component";

const modul = angular.module("standard_sitzungsmodule.top_protokoll", [
  sitzungsmodule.name,
])

  .config(/*@ngInject*/SitzungsmodulRegistryProvider => {
    SitzungsmodulRegistryProvider.register("top_protokoll", "modul-top-protokoll");
  })

  .component("modulTopProtokoll", detail)
;

export default modul;
