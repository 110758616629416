"use strict";

import template from "./auszahlungsmodi.config.jade";
import BaseController from "./auszahlungsmodi.base.controller";

class AuszahlungsmodiConfigController extends BaseController {
  /*@ngInject*/
  constructor($scope, ParameterUtils) {
    super($scope, ParameterUtils);
    $scope.$on(
      ParameterUtils.events.error,
      ($event, errors) => {
        if (angular.isUndefined(errors[this.key])) {
          this.modulErrors = null;
          return;
        }
        try {
          this.modulErrors = JSON.parse(errors[this.key]);
          errors[this.key] = ["Fehler in der Konfiguration mindestens einer Auszahlungsmodalität."];
        } catch (SyntaxError) {
          this.errors = errors[this.key][0];
        }
      }
    );
  }

  add() {
    this.parameter[this.key].wert.push(this.ParameterUtils.init_parameter_in(this.parameter[this.key].konfig));
  }

  remove(idx) {
    this.parameter[this.key].wert.splice(idx, 1);
  }

  up(idx) {
    if (idx === 0) {
      return;
    }
    const newIdx = idx - 1;
    const el = this.parameter[this.key].wert[idx];
    this.remove(idx);
    this.parameter[this.key].wert.splice(newIdx, 0, el);
  }

  down(idx) {
    if (idx === this.parameter[this.key].wert.length - 1) {
      return;
    }
    const newIdx = idx + 1;
    const el = this.parameter[this.key].wert[idx];
    this.remove(idx);
    this.parameter[this.key].wert.splice(newIdx, 0, el);
  }
}

export default {
  bindings: {
    parameter: "=",
    key: "@",
    controlId: "@?"
  },
  template: template(),
  controller: AuszahlungsmodiConfigController,
  controllerAs: "vm",
};
