import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragAuszahlungsplanAufgabe",
  components: {
    FormstepDetail,
    PuxAlert,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    primaryAuszahlungsplan: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  computed: {
    isComponentVisible() {
      return this.loading || this.primaryAuszahlungsplan;
    },

    aktuelleAufgabe() {
      return get(this.primaryAuszahlungsplan, "aktuelle_aufgabe", {}) || {};
    },

    optionsFormstepDetail() {
      return {
        hideHeader: true,
      };
    },
  },
};
