"use strict";

import BaseModel from "dias/core/models/baseModel";

class Regelsignale extends BaseModel {
  constructor() {
    super("regelsignale");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.getKonfigurierbareEmpfaenger = () => collection.customGETLIST("konfigurierbare_empfaenger");
    collection.getKonfigurierbareSender = () => collection.customGETLIST("konfigurierbare_sender");

    collection.setKonfig = data => collection.customPOST(data, "set_konfig");

    return collection;
  }
}

export default Regelsignale;
