"use strict";

import BaseModel from "dias/core/models/baseModel";
import Sitzungsmodule from "dias/sitzungen/sitzungsmodule/models/sitzungsmodule.service";
import Sitzungslisten from "./sitzungslisten.service";

class Tops extends BaseModel {
  constructor() {
    super("tops", {
      subPaths: {
        sitzungsmodule: new Sitzungsmodule(),
        sitzungtagesordnungmodul: "sitzungtagesordnungmodule",
        listen: new Sitzungslisten()
      }
    });
  }

  extendModel(model) {
    model = super.extendModel(model);

    // Build Positionsstring
    if (!angular.isUndefined(model.absolut_pos)) {
      model.posStr = String(model.absolut_pos[0]);
      for (let i = 1; i < model.absolut_pos.length; i++) {
        model.posStr += "." + model.absolut_pos[i];
      }
    }
    model.tos_bereitgestellt = !model.tos_bereitgestellt ? 0 : Number(model.tos_bereitgestellt);
    return model;
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.resetTops = () => {
      return collection.one("reset").post();
    };
    collection.resetOneTop = pk => {
      return collection.one(pk).customPOST({}, "reset");
    };
    collection.lastEditTops = () => {
      return collection.one("last_edit").get();
    };
    collection.replaceTops = data => {
      return collection.customPOST(data, "replace");
    };
    collection.deleteTops = pk => {
      return collection.one(pk).remove();
    };
    collection.updateTop = (pk, data) => {
      return collection.one(pk).customPUT(data);
    };
    collection.getModulPreview = () => {
      return collection.one("modulvorschau").get();
    };
    return collection;
  }
}

export default Tops;
