"use strict";

import BaseModel from "dias/core/models/baseModel";
import Tops from "dias/sitzungen/models/tops.service";
import Objektaufgabe from "dias/workflow/models/objektaufgabe.service";
import Sitzungsteilnehmer from "dias/sitzungen/models/sitzungsteilnehmer.service";
import SitzungsCache from "dias/sitzungen/models/sitzungscaches.service";
import Dokumente from "dias/dokumente/models/dokumente.service";
import Preview from "dias/dokumente/models/preview.service";


class Sitzungen extends BaseModel {
  constructor() {
    super("sitzungen", {
      subPaths: {
        tops: new Tops(),
        aufgaben: new Objektaufgabe(),
        cache: new SitzungsCache(),
        mittelverteilung: "mittelverteilung",
        teilnehmer: new Sitzungsteilnehmer(),
        dokumente: new Dokumente(),
        preview: new Preview(),
      }
    });
  }

  extendModel(model) {
    model = super.extendModel(model);

    model.restangularizeAufgabe = aufgabe => this.Restangular.restangularizeElement(model, aufgabe, "aufgaben", {});

    if (model.aktuelle_aufgabe) {
      model.aktuelle_aufgabe = model.restangularizeAufgabe(model.aktuelle_aufgabe);
    }

    model.loadProtokoll = () => model.customGET("protokoll_ansehen");

    model.updateProtokoll = protokoll => model.customPUT({ si_protokoll: protokoll }, "protokoll_bearbeiten");

    return model;
  }
}

export default Sitzungen;
