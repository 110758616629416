import AntragVerknuepfungModalCreate from "../../AntragVerknuepfungModalCreate/AntragVerknuepfungModalCreate.vue";
import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import VerbundeneAntraegeAntrag from "./VerbundeneAntraegeAntrag/VerbundeneAntraegeAntrag.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import {
  cloneDeep,
  filter,
  forEach,
  isEmpty,
} from "lodash-es";

// @vue/component
export default {
  name: "VerbundeneAntraege",
  components: {
    AntragVerknuepfungModalCreate,
    AsideRightBox,
    Modal,
    Permission,
    PuxButton,
    PuxTranslate,
    VerbundeneAntraegeAntrag,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  props: {
    antragPk: {
      type: String,
      required: true,
    },
    antragNummer: {
      type: String,
      required: true,
    },
    antragTitel: {
      type: String,
      default: "",
      required: false,
    },
    antragNummerExtern: {
      type: String,
      required: false,
      default: undefined,
    },
    antragstellerName: {
      type: String,
      required: false,
      default: undefined,
    },
    updateOnChange: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
  },
  setup() {
    const {
      filterSpaced,
    } = FiltersAPI();

    return {
      filterSpaced,
    };
  },
  data() {
    return {
      loading: true,
      optionsAsideRightBox: {
        icon: "glyphicon-verbunden",
        active: true,
      },
      verbundarten: [],
      verbundartAntraegeMap: {},
      verbundeneAntraege: [],
      id: "verbundene_antraege_aside_right_box",
      antragConfig: {},
      isAntragVerknuepfenModalVisible: false,
      confirmShow: undefined,
      confirmOptions: undefined,
      selectorClose: undefined,
    };
  },
  computed: {
    url() {
      return `foerderantraege/${ this.antragPk }/verbundene_antraege/`;
    },

    idForButtonAntragVerknuepfen() {
      return `${ this.id }_verknuepfen`;
    },

    hasPermissionFoerderantragverbundView() {
      return this.checkPermissionsSync({
        perm: "foerderantragverbund.view",
      });
    },

    antragForVerbundartAntraegeMap() {
      return {
        pk: this.antragPk,
        a_nr: this.antragNummer,
        a_nr_extern: this.antragNummerExtern,
        a_titel: this.antragTitel,
        antragstellerorg_obj: {
          ast_name: this.antragstellerName,
        },
      };
    },

    verbundartenWithNotEmptyVerbundartAntraegeMap() {
      return filter(this.verbundarten, verbundart => this.verbundartAntraegeMap[verbundart.pk] && this.verbundartAntraegeMap[verbundart.pk].length);
    },

    isListVisible() {
      return !isEmpty(this.verbundartenWithNotEmptyVerbundartAntraegeMap);
    },
  },
  watch: {
    updateOnChange() {
      this.loadData();
    },
  },
  created() {
    this.setAntragConfig();
    this.loadData();
  },
  methods: {
    setAntragConfig() {
      this.antragConfig = this.getSyConfigsValue("foerderantrag", {});
    },

    loadData() {
      if (!this.hasPermissionFoerderantragverbundView) {
        return;
      }
      this.loading = true,
      Promise.all([
        this.getHttp({
          url: "katalog/",
          urlParams: {
            key: "kverbundart",
          },
          apiSaveId: "kverbundart",
        }),
        this.getHttp({
          url: this.url,
        }),
      ]).then(
        responses => {
          this.verbundarten = responses[0];
          this.verbundeneAntraege = responses[1];
          this.setVerbundartAntraegeMap();
          this.loading = false;
        }
      ).finally(() => this.loading = false);
    },

    setVerbundartAntraegeMap() {
      const VERBUNDART_ANTRAEGE_MAP = {};
      forEach(cloneDeep(this.verbundeneAntraege), verbundenerAntrag => {
        const CURRENT_VERBUNDART = verbundenerAntrag.verbundart;
        VERBUNDART_ANTRAEGE_MAP[CURRENT_VERBUNDART] = VERBUNDART_ANTRAEGE_MAP[CURRENT_VERBUNDART] || [];
        VERBUNDART_ANTRAEGE_MAP[CURRENT_VERBUNDART].push(verbundenerAntrag);
      });

      this.verbundarten.forEach(verbundart => {
        const VERBUNDART_PK = verbundart.pk;
        if (verbundart.vba_nur_direkte_verbindungen
          || (!VERBUNDART_ANTRAEGE_MAP[VERBUNDART_PK] ||
            VERBUNDART_ANTRAEGE_MAP[VERBUNDART_PK].length === 0)) {
          return;
        }
        const HAS_MASTER = VERBUNDART_ANTRAEGE_MAP[VERBUNDART_PK].find(anv => anv.master === anv.verbundener_antrag.pk);
        const VERBUNDART_BEZ = HAS_MASTER ? verbundart.vba_verbunden_bez : verbundart.vba_master_bez;
        VERBUNDART_ANTRAEGE_MAP[VERBUNDART_PK].splice((HAS_MASTER ? 1 : 0), 0, {
          verbundener_antrag: this.antragForVerbundartAntraegeMap,
          verbundart: VERBUNDART_PK,
          verbundart_bez: VERBUNDART_BEZ
        });
      });
      this.verbundartAntraegeMap = VERBUNDART_ANTRAEGE_MAP;
    },

    openModalAntragVerknuepfen() {
      this.isAntragVerknuepfenModalVisible = true;
    },

    closeModalAntragVerknuepfen(response) {
      this.isAntragVerknuepfenModalVisible = false;
      if (response) {
        this.loadData();
      }
      setTimeout(() => {
        if (response) {
          $(`#${ this.id }`).focus();
        } else {
          $(`#${ this.idForButtonAntragVerknuepfen }`).focus();
        }
      });
    },

    openConfirmDeleteVerknuepfung({ verbundenerAntrag, idForButtonDelete, verbundenerAntragNummerExtern }) {
      this.selectorClose = [
        `#${ idForButtonDelete }`,
        `#${ this.id }`,
      ];
      const VERBUNDART_BEZ = verbundenerAntrag.verbundart && verbundenerAntrag.verbundart.length > 0 ?
        `${ verbundenerAntrag.verbundart_bez } ` :
        "";
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_HTML_VERBUNDENE_ANTRAEGE_CONFIRM_DELETE_HEADER_",
        msg: "_HTML_VERBUNDENE_ANTRAEGE_CONFIRM_DELETE_BODY_{{verbundartBez}}_{{a_nr}}_{{a_nr_extern}}_",
        extra: {
          verbundartBez: VERBUNDART_BEZ,
          a_nr: this.filterSpaced(verbundenerAntrag.verbundener_antrag.a_nr),
          a_nr_extern: verbundenerAntragNummerExtern,
        },
        okLabel: "_BTN_VERBUNDENE_ANTRAEGE_LOESEN_",
        okCallback: () => this.deleteVerknuepfung({ verbundenerAntrag }),
        cancelCallback: this.closeConfirm,
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteVerknuepfung({ verbundenerAntrag }) {
      this.deleteHttp({
        url: this.getUrlDelete(verbundenerAntrag.pk),
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_VERBUNDENE_ANTRAEGE_CONFIRM_DELETE_SUCCESS_",
          });
          this.loadData();
        },
        () => {
          this.addNotification({
            text: "_MSG_VERBUNDENE_ANTRAEGE_CONFIRM_DELETE_ERROR_",
            type: "error",
          });
        }
      ).finally(
        () => this.closeConfirm()
      );
    },

    getUrlDelete(verbundenerAntragPk) {
      return `${ this.url }${ verbundenerAntragPk }/`;
    },

    closeConfirm() {
      this.confirmShow = false;
    },
  },
};
