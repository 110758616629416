import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import NachrichtenLink from "../NachrichtenLink/NachrichtenLink.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  FilterDefaultForEmptyMixin,
  FilterSpacedMixin,
} from "../../../global/mixins/FiltersMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import {
  find,
  get,
  isArray,
  isNil,
  map,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "NachrichtenListDetails",
  components: {
    AngularLink,
    NachrichtenLink,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    FilterDefaultForEmptyMixin,
    FilterSpacedMixin,
    PermissionMixin,
  ],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: undefined,
    },
    reloadTable: {
      type: Function,
      default: () => {},
    },
    onOpen: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selectedEmpfaenger: null
    };
  },
  computed: {
    currentNachricht() {
      if (!isNil(this.selectedEmpfaenger)) {
        return find(this.data.empfaenger, el => el.nutzer.pk === this.selectedEmpfaenger);
      } else if (this.multipleEmpfaenger || !isArray(this.data.empfaenger)) {
        return this.data;
      }
      return get(this.data.empfaenger, ["0"]);
    },

    canCreateContextfree() {
      return this.checkPermissionsSync({ perm: "benachrichtigungen.contextfree.create" });
    },

    multipleEmpfaenger() {
      return isArray(this.data.empfaenger) && size(this.data.empfaenger) > 1;
    },

    empfaengerList() {
      if (!isArray(this.data.empfaenger)) {
        return [this.data.empfaenger];
      }
      return map(this.data.empfaenger, el => el.nutzer) || [];
    },

    empfaengerObject() {
      if (!isArray(this.data.empfaenger)) {
        return this.data.empfaenger || {};
      }
      return get(this.data.empfaenger, ["0", "nutzer"], {});
    },

    getBezug() {
      if (this.data.objekt.type === "antrag") {
        return { bezug: "antrag", bezugpk: this.data.objekt.a_id };
      }
      if (this.data.objekt.type === "vertrag") {
        return { bezug: "vertrag", bezugpk: this.data.objekt.v_id };
      }
      if (this.data.objekt.type === "antragsteller") {
        return { bezug: "antragsteller", bezugpk: this.data.objekt.ast_id };
      }
      if (this.data.objekt.type === "auszahlung") {
        return { bezug: "auszahlung", bezugpk: this.data.objekt.az_id };
      }
      if (this.data.objekt.type === "sitzung") {
        return { bezug: "sitzung", bezugpk: this.data.objekt.si_id };
      }
      if (this.data.objekt.type === "top") {
        return { bezug: "top", bezugpk: this.data.objekt.tos_id };
      }
      if (this.data.objekt.type === "sammelvertrag") {
        return { bezug: "sammelvertrag", bezugpk: this.data.objekt.sv_id };
      }
      if (this.data.objekt.type === "formular") {
        return { bezug: "formular", bezugpk: this.data.objekt.for_id };
      }
      return {};
    },

    getAntragNr() {
      return this.filterSpaced(this.data.objekt.a_nr);
    },

    getVertragNr() {
      return this.filterSpaced(this.data.objekt.v_nr);
    },

    getAuszahlungsNr() {
      return `${ this.filterSpaced(this.data.objekt.v_nr) }-${ this.data.objekt.az_nummer }`;
    },

    showKommentarBlock() {
      return size(this.data.bnn_kommentar) > 0;
    },

    mainBlockCls() {
      return this.showKommentarBlock ? "col-12 col-sm-9 col-lg-8" : "col-lg-12";
    },

    kommentarBlockCls() {
      return "border-left col-12 col-sm-3 col-lg-4";
    },
  },
  created() {
    if (this.data && !this.data.ben_gelesen) {
      this.onOpen({ item: this.data, index: this.index });
    }
  },
  methods: {
    prefixId(id) {
      return `${ this.data.pk }_${ id }`;
    },
  },
};
