"use strict";

import template from "./add.jade";

import {
  filter,
  size,
  some
} from "lodash-es";


class FoerderangebotRegelAddController {
  /*@ngInject*/
  constructor($scope, ParameterUtils) {
    this.name = "FoerderangebotRegelAddController";
    // this.regeln = $scope.regeln;
    this.regelForm = {};
    this.regelParams = null;
    this.regelParamsModel = null;
    this.regel = null;
    this.ParameterUtils = ParameterUtils;
    // this.onAdd = $scope.onAdd;
    this.add = regel => {
      this.onAdd()(regel);
      this.showAdd = false;
      this.regel = null;
    };


    $scope.$watch(() => size(this.used), () => {
      this.filteredRegeln = filter(this.regeln, el => !some(this.used, uel => uel.regel === el.pk));
    });

    this.regelOptions = {
      id: "selected_regel",
      type: "select",
      label: "Geschäftsregel",
      required: true,
      view: "v",
      keyId: "pk",
      keyLabelCallback: ({ item }) => `[${ item.re_nummer }] ${ item.re_kbez_admin }`,
      search: true,
      searchList: ["re_nummer", "re_kbez_admin", "group"],
    };
    this.regelChange = ({ item }) => $scope.$applyAsync(() => {
      this.regel = item;
      this.prepareRegel();
    });
  }

  prepareRegel() {
    if (this.regel) {
      this.regelParams = this.regel.re_argument;
      this.regelParamsModel = this.ParameterUtils.to_request_body(this.regelParams);
    } else {
      this.regelparams = null;
      this.regelParamsModel = null;
    }
  }
}

export default {
  template: template(),
  controller: FoerderangebotRegelAddController,
  controllerAs: "vm",
  bindings: {
    regeln: "=",
    used: "=",
    onAdd: "&"
  }
};
