var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error' :ctx.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "ctx.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in ctx.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<div class=\"modal-body\"><div dias-cloak=\"ctx.loading\" class=\"row\"><section class=\"main col-10 offset-1\"><div ng-attr-id=\"{{ ctx.DiasNotification.form.containerId }}\"></div><form><fieldset>");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"o_name\" ng-model=\"ctx.model.fas_name\" type=\"text\" name=\"fas_name\" maxlength=\"400\" auto-focus=\"true\" aria-required=\"true\" ng-attr-aria-invalid=\"{{ctx.errors.fas_name ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "fas_name", "Name*");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"o_kbez\" ng-model=\"ctx.model.fas_funktion\" type=\"text\" name=\"fas_funktion\" maxlength=\"400\" ng-attr-aria-invalid=\"{{ctx.errors.fas_funktion ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "fas_funktion", "Funktion");
buf.push("<div ng-repeat=\"item in ctx.komTyps\">");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input ng-model=\"ctx.kommdatenModel[item.kot_id]\" type=\"text\" maxlength=\"400\" ng-attr-aria-invalid=\"{{ctx.errors[item.kot_id] ? 'true' : 'false'}}\" ng-attr-id=\"{{item.kot_id}}\" ng-attr-name=\"{{item.kot_id}}\" class=\"form-control\">");
}
}, "{{item.kot_id}}", "{{item.kot_bez}}");
buf.push("</div></fieldset></form></section></div></div><div class=\"modal-footer\"><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button type=\"submit\" ng-click=\"exitWithCallback(ctx.saveNutzer)\" ng-disabled=\"loading\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><a ng-click=\"exit()\" ng-disabled=\"loading\" class=\"btn btn-default\"><span>Abbrechen</span></a></div></div></div>");;return buf.join("");
}