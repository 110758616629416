import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";
import Spaced from "../../../global/components/Spaced/Spaced.vue";

import {
  FilterDateMixin,
} from "../../../global/mixins/FiltersMixin";

import getTableApi from "../../../const/TableApis";

const TABLE_ID = "projektbericht";
// const VIEW_STATUS_COLUMN_PERM = "workflow.list.column.status_detail.view";

// @vue/component
export default {
  name: "ProjektberichtList",
  components: {
    AngularLink,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
    Spaced,
  },
  mixins: [
    FilterDateMixin,
  ],
  props: {
    createProjektbericht: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_LBL_PROJEKTBERICHT_LIST_ACTIONS_ROW_DETAILS_",
            route: "root.projektberichte.detail",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [
          {
            permission: "projektbericht.create",
            label: "_LBL_PROJEKTBERICHT_LIST_NEUEN_PRJEKTBERICHT_ERSTELLEN_",
            callback: this.onCreateProjektbericht,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
          },
        ],
        label: "_LBL_PROJEKTBERICHT_LIST_PROJEKTBERICHTE_",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
    };
  },
  computed: {
    getHrefVertrag() {
      return vertragPk => `/vertrag/${ vertragPk }/`;
    },

    getHrefProjektbericht() {
      return projektberichtPk => `/projektberichte/${ projektberichtPk }/`;
    },

    getTitleVertrag() {
      return vertrag_nr => `Zu Vertrag ${ vertrag_nr } gehen`;
    },
  },
  methods: {
    onCreateProjektbericht() {
      this.createProjektbericht();
    },
  },
};
