export const ERWERBART_CHOICES = {
  1: "_LBL_GR_INVESTITIONSFOERDERUNG_ERWERBART_CHOICES_1_",
  2: "_LBL_GR_INVESTITIONSFOERDERUNG_ERWERBART_CHOICES_2_",
  3: "_LBL_GR_INVESTITIONSFOERDERUNG_ERWERBART_CHOICES_3_",
};


export const EINRICHTUNGSART_CHOICES = [
  { id: 1, label: "_LBL_GR_INVESTITIONSFOERDERUNG_EINRICHTUNGSART_CHOICES_1_" },
  { id: 2, label: "_LBL_GR_INVESTITIONSFOERDERUNG_EINRICHTUNGSART_CHOICES_2_" },
  { id: 3, label: "_LBL_GR_INVESTITIONSFOERDERUNG_EINRICHTUNGSART_CHOICES_3_" },
  { id: 4, label: "_LBL_GR_INVESTITIONSFOERDERUNG_EINRICHTUNGSART_CHOICES_4_" },
];
