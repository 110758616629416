"use strict";
import angular from "angular";

import models from "../models";
import services from "../services";

const modul = angular.module("dias.workflows.serverfunktionen", [
  models.name,
  services.name,
]);

modul.config($stateProvider => {
  $stateProvider

  // Liste der KAufgaben
    .state("root.workflowfolgeserverfunktionen", {
      url: `/workflowfolgeserverfunktionen/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-workflowfolgeserverfunktionen-list></vue-workflowfolgeserverfunktionen-list></div>"
        }
      },
      data: {
        permissions: "serverfunktion.ui"
      }
    })

    .state("root.workflowfolgeserverfunktionen.detail", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><vue-workflowfolgeserverfunktionen-details></vue-workflowfolgeserverfunktionen-details></div>"
        }
      },
    })
  ;
})

;

export default modul;
