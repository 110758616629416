"use strict";

import angular from "angular";

import coreModels from "dias/core/models";
import Projektberichtvorlagen from "./projektberichtvorlagen.service";
import Projektberichte from "./projektberichte.service";

const modelsModule = angular.module("projektberichtvorlage.models", [
  coreModels.name
])

  .provider("Projektberichtvorlagen", Projektberichtvorlagen)
  .provider("Projektberichte", Projektberichte)
;

export default modelsModule;
