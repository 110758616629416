import KFStandard from "../../KFDetail/KFStandard/KFStandard.vue";
import FinanzierugMixin from "../Mixins/FinanzierugMixin";

// @vue/component
export default {
  name: "Rueckforderungsverzicht",
  components: {
    KFStandard,
  },
  mixins: [
    FinanzierugMixin,
  ],
  data() {
    return {
      statusCanView: false,
      allowsInput: true,
    };
  },
  computed: {
    statusNotLoad() {
      return this.kontext !== "zuschuss" && this.kontext !== "zuschuss_vertrag";
    },

    sumLocal() {
      return this.antragBetragCurrency;
    },
  },
  methods: {
    onArtLoaded_KFBaseMixin() {
      setTimeout(() => {
        let betrag;
        if (this.kontext !== "zuschuss_vertrag") {
          betrag = Number(this.antragBetragValue);
          this.statusCanView = !Number.isNaN(betrag) && betrag !== 0;
        } else {
          betrag = this.vertragBetragValue;
          this.statusCanView = (!Number.isNaN(betrag) && betrag !== 0);
        }
      });
    },
  },
};
