import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import {
  cloneDeep,
  isEmpty,
  get,
  forEach,
} from "lodash-es";

const SNAPSHOT_CHANGES_LIST = [
  "ast_registernummer",
  "ast_gericht",
];

// @vue/component
export default {
  name: "AstRegisternummer",
  components: {
    SnapshotModule,
    SnapshotIcon,
  },
  mixins: [
    ModuleMixin,
  ],
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.list = [
        {
          id: "ast_registernummer",
          htmlId: `${ this.htmlRegelNummer }_ast_registernummer`,
          type: "text",
          label: "_LBL_GR_AST_REGISTERNUMMER_REGISTERNUMMER_",
          translate: true,
          required: false,
          view: "v",
          editonly: true,
          startupFocus: true,
        },
        {
          id: "ast_gericht",
          htmlId: `${ this.htmlRegelNummer }_ast_gericht`,
          type: "text",
          label: "_LBL_GR_AST_REGISTERNUMMER_GERICHT_",
          translate: true,
          required: false,
          view: "v",
          editonly: true,
        },
      ];
      return OPTIONS;
    },

    labelHeader() {
      if (this.modelParameter.frage_text) {
        return this.modelParameter.frage_text;
      }
      return "_TXT_GR_AST_REGISTERNUMMER_HEADER_";
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(SNAPSHOT_CHANGES_LIST, key => {
          if (get(this.model, key) !== get(this.snapshotModule, key)) {
            DIFF[key] = true;
          }
        });
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },
  },
};
