import Alert from "../../../../../global/components/Alert/Alert.vue";
import GutachtenUebersichtTr from "./GutachtenUebersichtTr/GutachtenUebersichtTr.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  EventBus,
} from "../../../../../global/functions/event-bus";

import {
  forEach,
  startsWith,
  orderBy,
  isNil,
  map,
  get,
} from "lodash-es";


import { createNamespacedHelpers } from "vuex";
const { mapMutations, mapGetters } = createNamespacedHelpers("table");

const COL_LABELS = {
  ga_nr: "_LBL_GUTACHTEN_UEBERSICHT_SPALTE_NR_",
  ga_kbez: "_LBL_GUTACHTEN_UEBERSICHT_SPALTE_KBEZ_",
  ga_bez: "_LBL_GUTACHTEN_UEBERSICHT_SPALTE_BEZ_",
  ga_veroeffentlichtdatum: "_LBL_GUTACHTEN_UEBERSICHT_SPALTE_VEROEFFENTLICHTDATUM_",
  ga_gutachtendatum: "_LBL_GUTACHTEN_UEBERSICHT_SPALTE_GUTACHTENDATUM_",
  ga_termin: "_LBL_GUTACHTEN_UEBERSICHT_SPALTE_TERMIN_",
  ergebnis_lang: "_LBL_GUTACHTEN_UEBERSICHT_SPALTE_ERGEBNIS_LANG_",
  ergebnis_kurz: "_LBL_GUTACHTEN_UEBERSICHT_SPALTE_ERGEBNIS_KURZ_",
  ersteller: "_LBL_GUTACHTEN_UEBERSICHT_SPALTE_ERSTELLER_",
  pruefer: "_LBL_GUTACHTEN_UEBERSICHT_SPALTE_PRUEFER_",
  nutzer: "_LBL_GUTACHTEN_UEBERSICHT_SPALTE_GUTACHTER_",
  typ: "_LBL_GUTACHTEN_UEBERSICHT_SPALTE_TYP_",
  vorlage: "_LBL_GUTACHTEN_UEBERSICHT_SPALTE_VORLAGE_",
};

const COL_SORT = {
  ga_nr: "ga_nr",
  ga_kbez: "ga_kbez",
  ga_bez: "ga_bez",
  ga_veroeffentlichtdatum: "ga_veroeffentlichtdatum",
  ga_gutachtendatum: "ga_gutachtendatum",
  ga_termin: "ga_termin",
  ergebnis_lang: "ergebnis_bez",
  ergebnis_kurz: "ergebnis_bez",
  ersteller: "ersteller_name",
  pruefer: "pruefer_name",
  nutzer: "nutzer_name",
  typ: "typ_bez",
  vorlage: "vorlage_bez",
};

// @vue/component
export default {
  name: "GutachtenUebersichtTable",
  components: {
    Alert,
    GutachtenUebersichtTr,
    PuxButton,
    PuxTranslate,
  },
  props: {
    headerName: {
      type: String,
      required: false,
      default: undefined,
    },
    htmlId: {
      type: String,
      required: true,
    },
    gutachten: {
      type: Array,
      required: true,
    },
    cols: {
      type: Array,
      required: true,
    },
    ergebnisse: {
      type: Array,
      required: false,
      default: undefined,
    },
    ergebnisseAuswahl: {
      type: Array,
      required: false,
      default: undefined,
    },
    showColorBorder: {
      type: Boolean,
      default: false,
    },
    canLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: undefined,
      sortBy: undefined,
    };
  },
  computed: {
    tableId() {
      return `gutachten_uebersicht_${ this.htmlId }`;
    },

    tableCls() {
      return this.canLink ? "table-hover" : "";
    },

    colDefs() {
      return map(this.cols, col => {
        return {
          id: col,
          key: col,
          label: COL_LABELS[col],
          keySort: COL_SORT[col],
          css_klasse: col === "ergebnis_lang" ? "mw-100" : undefined,
        };
      });
    },

    colSortInfo() {
      const CLS_MAP = {};
      const SORT_MODEL = this.GET_SORT_MODEL(this.tableId);

      forEach(this.colDefs, col => {
        const COL_SORT = this.GET_SORT_TH({ tableId: this.tableId, colId: col.keySort });
        CLS_MAP[col.key] = {
          class: { table_smart__table__th_sort_active: COL_SORT !== "none" },
          sort: COL_SORT,
          asc: col.keySort === SORT_MODEL,
          desc: `-${ col.keySort }` === SORT_MODEL,
        };
      });

      return CLS_MAP;
    },

    sortedGutachten() {
      if (isNil(this.sortBy)) {
        return this.gutachten;
      } else if (startsWith(this.sortBy, "-")) {
        return orderBy(this.gutachten, [this.sortBy.substring(1)], ["desc"]);
      }
      return orderBy(this.gutachten, [this.sortBy], ["asc"]);
    },

    ...mapGetters([
      "GET_SORT_MODEL",
      "GET_SORT_TH",
    ]),
  },

  created() {
    this.MUT_CREATE_TABLE_BY_ID(this.tableId);
    const SORT = this.GET_SORT_MODEL(this.tableId) || get(this, "colDefs[0].key");
    this.changeSort(SORT);
  },
  beforeUnmount() {
    this.MUT_REMOVE_TABLE_BY_ID(this.tableId);
  },
  methods: {
    getSortId(id) {
      return `${ this.htmlId }__sort_${ id }`;
    },

    changeSort(id) {
      const modelSort = this.GET_SORT_MODEL(this.tableId);
      let tempId;
      if (modelSort === id) {
        tempId = `-${ id }`;
      } else if (modelSort === `-${ id }`) {
        tempId = id.replace("-", "");
      } else {
        tempId = id;
      }

      this.MUT_SET_SORT_MODEL({
        tableId: this.tableId,
        sortModel: tempId,
      });
      EventBus.$emit(`changeSort${ this.tableId }`, { id: tempId });
      this.sortBy = tempId;
    },

    ...mapMutations([
      "MUT_SET_SORT_MODEL",
      "MUT_CREATE_TABLE_BY_ID",
      "MUT_REMOVE_TABLE_BY_ID",
    ]),
  },
};
