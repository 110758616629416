"use strict";

import BaseController from "dias/foerderregeln/parameter/config.base.controller";

class ParametermoduleChoiceController extends BaseController {
  /*@ngInject*/
  constructor($scope, Katalog, DiasNotification, Restangular) {
    super();
    this.Restangular = Restangular;
    this.selectedIndex = -1;
    this.hoverIndex = -1;
    this.groups = [];
    this.choices = [];
    this.showEmpty = this.parameter[this.key].options && !this.parameter[this.key].options.required;
    if (angular.isUndefined(this.parameter[this.key].wert)) {
      this.parameter[this.key].wert = null;
    }
    if (this.parameter[this.key].katalog) {
      Katalog.getKatalog(this.parameter[this.key].katalog, this.parameter[this.key].katalog_filter).then(
        result => {
          this.setChoices(result.plain());
          this.ensureEmptyResultIfNotRequired(this.choices);
        },
        error => {
          DiasNotification.form.error(error.data.detail, "Katalog nicht gefunden.");
        }
      );
    } else if (this.parameter[this.key].choices) {
      $scope.$watch(() => this.parameter[this.key].choices, () => this.prepareLocalChoices());
    }
    $scope.$watch(() => this.parameter[this.key].wert, () => this.updateSelectedIndex());
    this.get_displayvalue = id => {
      let x = "";
      angular.forEach(this.choices, c => {
        if (String(c.id) === String(id)) {
          x = c.bez;
        }
      });
      return x;
    };
  }

  prepareLocalChoices() {
    const choices = this.parameter[this.key].choices.map(v => {
      if (angular.isArray(v)) {
        return { id: v[0], bez: v[1], group: v[2] };
      }
      return v;
    });
    this.setChoices(choices);
  }

  setChoices(choices) {
    this.choices = choices;
    this.updateSelectedIndex();
  }

  updateSelectedIndex() {
    this.selectedIndex = this.choices.findIndex(c => String(c.id) === String(this.parameter[this.key].wert));
  }

  setSelectedIndex(idx) {
    this.parameter[this.key].wert = this.choices[idx].id;
    this.onChange();
  }

  setHoverIndex(idx) {
    this.hoverIndex = idx;
  }

  onChange() {
    this.updateSelectedIndex();
    if (this.change) {
      this.change();
    }
  }

  getIconCls(idx) {
    if (idx <= this.hoverIndex && this.hoverIndex !== this.selectedIndex) {
      return "glyphicon-star text-orange-light";
    } else if (idx > this.selectedIndex ||
               (this.hoverIndex >= 0 && this.hoverIndex !== this.selectedIndex)) {
      return "glyphicon-star-empty text-dark";
    } else if (idx <= this.selectedIndex) {
      return "glyphicon-star text-orange";
    }
  }
}

export default ParametermoduleChoiceController;
