import Alert from "../../../global/components/Alert/Alert.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  forEach,
} from "lodash-es";

const TABLE_ID = "BuchungenTable";

const TYPE_MAPPING = {
  date: {
    filter: "date",
    filterParameter: "DD.MM.YY"
  },
  antrag_nr: {
    filter: "spaced",
  },
  currency: {
    filter: "currency"
  },
  string: {},
};

// @vue/component
export default {
  name: "BuchungenList",
  components: {
    Alert,
    ContextPanel,
    PuxCloak,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    objekt: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        rowId: "lfdnr",
        cols: [],
        data: [],
        rowActions: [],
        actions: [
          {
            label: "_LBL_BUCHUNGEN_TABLE_ACTION_RELOAD_",
            title: "_LBL_BUCHUNGEN_TABLE_ACTION_RELOAD_TITLE_",
            callback: this.initColsAndData,
            type: "button",
            className: "btn btn-default",
            icon: "glyphicon glyphicon-changes",
          },
        ],
        pagination: false,
        showColsConfig: false,
        details: false,
        showHeader: false,
        sortable: false,
        hideTitle: true,
        csv: {
          url: `${ this.url }/${ this.objekt.pk }/`,
          status: true,
          title: "_LBL_BUCHUNGEN_LIST_CSV_TITLE_",
          params: {},
          action: "buchungen_csv",
          downloadAction: "buchungen_csv_download",
          label: "_LBL_BUCHUNGEN_LIST_CSV_",
        },
      },
      noData: false,
      loadingBuchungen: true,
    };
  },
  created() {
    this.initColsAndData();
  },
  methods: {
    initColsAndData() {
      this.loadingBuchungen = true;
      this.noData = true;
      this.getHttp({ url: `${ this.url }/${ this.objekt.pk }/buchungen/` }).then(
        response => {
          if (response.data.length !== 0) {
            this.prepareCols({ colSchema: response.schema });
            this.options.data = response.data;
            this.noData = false;
          }
        }
      ).finally(() => this.loadingBuchungen = false);
    },

    prepareCols({ colSchema }) {
      const COL_ARR = [];
      forEach(colSchema, (item, itemIndex) => {
        const COL_OBJ = {
          id: item.key,
          label: item.label,
          priority: (itemIndex + 1) * 10,
        };
        COL_ARR.push({ ...COL_OBJ, ...TYPE_MAPPING[item.type] });
      });
      this.options.cols = COL_ARR;
    },
  },
};
