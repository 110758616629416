import BelegDokumentSelectItem from "../BelegDokumentSelectItem/BelegDokumentSelectItem.vue";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";
import { FilterDateMixin } from "../../../../../global/mixins/FiltersMixin";

import { replaceText } from "../../../../../global/functions/utils";
import {
  assign,
  filter,
  find,
  get,
  isNil,
  isNumber,
  map,
} from "lodash-es";

// @vue/component
export default {
  name: "KofiErfassungBelegFormStandardFields",
  components: {
    BelegDokumentSelectItem,
    FormElement,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    FilterDateMixin,
  ],
  props: {
    field: {
      type: Object,
      required: true,
      info: "Basiskonfiguration des Felds",
    },
    config: {
      type: Object,
      required: true,
      info: "Nutzerkonfiguration des Felds aus Regel",
    },
    model: {
      type: Object,
      required: false,
      default: undefined,
      info: "Model",
    },
    errors: {
      type: Array,
      required: false,
      default: undefined,
      info: "Fehlerinformation",
    },
    classesHorizontal: {
      type: Array,
      required: false,
      default: undefined,
      info: "CSS-Klasse für form-element",
    },
    belegDokumente: {
      type: Array,
      required: true,
      info: "Belegdokumente",
    },
    belegDokumenteAnzahl: {
      type: Object,
      required: false,
      info: "Mapping dok_id zur Anzahl der Belege, in der dieses Dokument benutzt wird",
      default: () => ({}),
    },
    apiAntragDokumente: {
      type: String,
      required: true,
      info: "API foerderantraege/{{ AntragPk }}/dokumente/?dokart={{ dokart }}",
    },
    vertrag: {
      type: Object,
      required: true,
      info: "Vertrag zu dem der Beleg gehört",
    },
    view: {
      type: String,
      required: false,
      default: "h",
      info: "view für form-element"
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  emits: [
    "changeModel",
    "reloadBelegDokumente",
  ],
  data() {
    return {
      fieldOptions: undefined,
      fieldData: undefined,
      dokOptions: {
        template: undefined,
        select: undefined,
        button: undefined,
      },
      dokumentSelectWidth: undefined,
      dokumentContainerWidth: undefined,
      statusButtonDokumentDisabled: false,
    };
  },
  computed: {

    fieldId() {
      return this.config.field;
    },

    isDokumentField() {
      return this.field.type === "document";
    },

    dokumentButtonDiasabled() {
      return this.statusButtonDokumentDisabled || this.disabled;
    },

    dokumentProgressId() {
      return `${ this.config.htmlId }_progress`;
    },

    dokumentSelected() {
      if (!this.isDokumentField || isNil(this.model[this.fieldId])) {
        return;
      }
      return find(this.belegDokumente, dok => dok.pk === this.model[this.fieldId]);
    },

    dokumentDownloadBtnAttributes() {
      const URL = get(this.dokumentSelected, "url");
      const HAS_URL = !isNil(URL);
      const ATTR = {
        id: `${ this.config.htmlId }_download`,
        tag: "span",
        class: "disabled",
        title: "_BTN_DOWNLOAD_DISABLED_TITLE_"
      };
      if (HAS_URL) {
        assign(ATTR, {
          tag: "a",
          class: "",
          title: "_BTN_DOWNLOAD_TITLE_{{dokumentname}}_{{dokumenttitel}}_",
          extra: {
            dokumentname: get(this.dokumentSelected, "dokument.dok_dokumentname"),
            dokumenttitel: get(this.dokumentSelected, "dokument.dok_titel"),
          },
          href: URL,
          download: true,
          target: "_blank",
        });
      }
      return ATTR;
    },
  },
  created() {
    this.initField();
  },
  methods: {
    initField() {
      if (!this.isDokumentField) {
        this.initDefaultField();
      } else if (this.isDokumentField) {
        this.initDokumentFields();
      }
    },

    initDefaultField() {
      const FIELD = assign({}, this.field, {
        id: this.config.field,
        htmlId: this.config.htmlId,
        helpText: this.config.help_text,
        required: this.config.required,
        view: this.view,
        classesHorizontal: this.classesHorizontal,
        change: this.changeModel,
      });
      if (this.config.field === "objektkatalogwert") {
        FIELD.url = replaceText({
          text: FIELD.url,
          object: {
            okw_ctid: this.vertrag.content_type,
            okw_objid: this.vertrag.pk,
            okw_okaid: this.config.objektkatalog
          }
        });
        FIELD.apiSaveId = replaceText({
          text: FIELD.apiSaveId,
          object: {
            okw_ctid: this.vertrag.content_type,
            okw_objid: this.vertrag.pk,
            okw_okaid: this.config.objektkatalog
          }
        });
      } else if (this.config.field === "verausgabung_az") {
        const URL = replaceText({
          text: FIELD.url,
          object: {
            v_id: this.vertrag.pk
          }
        });
        const API_SAVE_URL = replaceText({
          text: FIELD.apiSaveId,
          object: {
            v_id: this.vertrag.pk
          }
        });
        this.getListHttp({ url: URL, apiSaveId: API_SAVE_URL }).then(
          azs => {
            this.fieldData = map(
              filter(azs, az => !isNil(az.az_datum_ausgezahlt)),
              az => {
                return {
                  pk: az.pk,
                  bez: `${ az.name } - ${ az.az_nummer } (${ this.filterDate(az.az_datum_ausgezahlt) })`
                };
              }
            );
          }
        );
        FIELD.url = undefined;
      }
      this.fieldOptions = FIELD;
    },

    initDokumentFields() {
      this.dokOptions = {
        template: {
          type: "template",
          id: `${ this.config.field }_template`,
          htmlId: `${ this.config.htmlId }_template`,
          label: this.field.label,
          helpText: this.config.help_text,
          required: this.config.required,
          view: this.view,
          classesHorizontal: this.classesHorizontal,
          slot: "dokument",
        },
        button: {
          type: "document",
          id: `${ this.config.field }_button`,
          htmlId: `${ this.config.htmlId }_button`,
          progressTeleport: this.dokumentProgressId,
          saveUrl: this.apiAntragDokumente,
          saveStart: this.saveStartDokument,
          saveDone: this.saveDoneDokument,
          change: this.loadDocument,
          buttonText: "_BTN_DATEI_HOCHLADEN_",
        },
        select: {
          type: "select",
          id: this.config.field,
          htmlId: this.config.htmlId,
          label: undefined,
          helpText: undefined,
          keyId: "pk",
          change: this.changeModel,
          deselect: true,
          placeholder: this.belegDokumente.length ? "_TXT_DATEI_AUSWAEHLEN_" : "_TXT_BITTE_DATEI_HOCHLADEN_",
          slot: "dokumente-select",
          statusHideErrorsBottom: true,
          menuWidthAll: false,
          elementItemClass: "mw-100",
          placement: "bottom-start",
          menuStyles: undefined,
          open: this.setDokumentWidth,
        }
      };
    },

    saveStartDokument() {
      this.statusButtonDokumentDisabled = true;
    },

    saveDoneDokument() {
      this.statusButtonDokumentDisabled = false;
    },

    loadDocument({ model }) {
      if (model && model.pk) {
        this.changeModel({ model: model.pk, id: this.config.field });
      }
      this.$emit("reloadBelegDokumente");
    },

    changeModel({ model, id }) {
      this.$emit("changeModel", { model, id });
    },

    setDokumentWidth() {
      const CONTAINER = this.getPixelsOrAuto(get(this.$refs, "dokument_container.clientWidth"));
      const SELECT = this.getPixelsOrAuto(get(this.$refs, "dokument_select.clientWidth"));
      this.dokOptions.select.menuStyles = `max-width: ${ CONTAINER }; min-width: ${ SELECT };`;
    },

    getPixelsOrAuto(val) {
      return isNumber(val) ? `${ val }px` : "auto";
    },
  },
};
