import AsideRight from "../../../global/components/AsideRight/AsideRight.vue";
import AsideRightBox from "../../../global/components/AsideRightBox/AsideRightBox.vue";
import CalendarEvent from "./CalendarEvent/CalendarEvent.vue";
import CalendarEventDetails from "../CalendarEventDetails/CalendarEventDetails.vue";
import CalendarFilters from "../CalendarFilters/CalendarFilters.vue";
import CalendarModalCreateOrEditEvent from "../CalendarModalCreateOrEditEvent/CalendarModalCreateOrEditEvent.vue";
import CalendarNavigation from "../CalendarNavigation/CalendarNavigation.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import CalendarModalMixin from "../CalendarModalMixin";
import EventActionMixin from "../EventActionMixin";

import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/de";
import fileDownload from "js-file-download";
import moment from "moment";
import VueCal from "vue-cal";
import {
  cloneDeep,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "Calendar",
  components: {
    AsideRight,
    AsideRightBox,
    CalendarEvent,
    CalendarEventDetails,
    CalendarFilters,
    CalendarModalCreateOrEditEvent,
    CalendarNavigation,
    Modal,
    PageTabTitle,
    PuxIcon,
    PuxTranslate,
    VueCal,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    CalendarModalMixin,
    EventActionMixin,
  ],
  data() {
    return {
      modelFilter: {
        typ: [],
        objekt_typ: [],
      },
      events: [],
      statusReady: undefined,
      statusLoading: false,
      currentView: "month",
      currentDate: undefined,
      currentEvent: undefined,
      currentEventEdit: undefined,
      eventsDetails: {},
      startDate: undefined,
      endDate: undefined,
      modal: {
        createOrEdit: false,
        delete: undefined,
      },
      confirmOptions: undefined,
      mePk: undefined,
      filteredCalendarObjectTypes: [],
      filename: "",
      offentlich_allowed: undefined,
    };
  },
  created() {
    this.filterCalendarObjectTypes();
    this.getMe();
    this.getOffentlich();
  },
  methods: {
    searchFromFilters() {
      this.loadData({
        startDate: this.startDate,
        endDate: this.endDate,
      });
    },

    changeView(arg) {
      this.currentView = arg.view;
      this.changeCurrentDate(arg);
      if (this.currentView === "year") {
        return;
      }
      this.onLoadData(arg);
    },

    changeCurrentDate({ startDate }) {
      this.currentDate = moment(startDate).format();
    },

    onLoadData({ firstCellDate, lastCellDate, startDate, endDate }) {
      this.startDate = moment(firstCellDate || startDate).startOf("day").format("YYYY-MM-DD HH:mm");
      this.endDate = moment(lastCellDate || endDate).endOf("day").format("YYYY-MM-DD HH:mm");
      this.loadData({
        startDate: this.startDate,
        endDate: this.endDate,
      });
    },

    loadData({ startDate, endDate }) {
      this.statusLoading = true;
      this.events = [];
      const URL_PARAMS = cloneDeep(this.modelFilter);
      URL_PARAMS.termin_frame_after = startDate;
      URL_PARAMS.termin_frame_before = endDate;

      this.getListHttp({
        url: this.getUrl,
        urlParams: URL_PARAMS,
      }).then(
        response => {
          this.prepareEventsForVueCal({ response });
          this.statusLoading = false;
        }
      );
    },

    prepareEventsForVueCal({ response }) {
      const EVENTS = [];
      forEach(response, event => {
        let startDay = moment(event.ter_von).format("YYYY-MM-DD HH:mm");
        const END_DAY = moment(event.ter_bis).format("YYYY-MM-DD HH:mm");
        let counter = 0;
        while (moment(startDay).format("YYYY-MM-DD") <= moment(END_DAY).format("YYYY-MM-DD")) {
          const START_DATE = counter === 0 ? startDay : moment(startDay).startOf("day").format("YYYY-MM-DD HH:mm");
          const END_DATE = moment(startDay).format("YYYY-MM-DD") === moment(END_DAY).format("YYYY-MM-DD") ? END_DAY : moment(startDay).endOf("day").format("YYYY-MM-DD HH:mm");
          const CURRENT_EVENT = cloneDeep(event);
          CURRENT_EVENT.start = START_DATE;
          CURRENT_EVENT.end = END_DATE;
          CURRENT_EVENT.title = event.ter_titel;
          CURRENT_EVENT.resizable = true;
          CURRENT_EVENT.bg = event.typ.tmt_hintergrund || "#e3181a";
          CURRENT_EVENT.color = event.typ.tmt_farbe || "#fff";
          CURRENT_EVENT.icon = event.typ.tmt_bild;
          CURRENT_EVENT.allDay = moment(START_DATE).format("HH:mm") === "00:00" && moment(END_DATE).format("HH:mm") === "23:59";
          EVENTS.push(CURRENT_EVENT);
          startDay = moment(startDay).add(1, "days").format("YYYY-MM-DD HH:mm");
          counter++;
        }
      });
      this.events = EVENTS;
    },

    changeDateTypeFromChild({ key, statusToday }) {
      if (statusToday) {
        this.$refs.vuecal.switchView(this.currentView, new Date());
        return;
      }
      this.$refs.vuecal.switchView(key);
    },

    changeCurrentDateFromChild({ direction }) {
      this.$refs.vuecal[direction]();
    },

    closeModalCreateOrEdit({ status, statusCloseDetails } = {}) {
      if (status) {
        this.loadData({
          startDate: this.startDate,
          endDate: this.endDate,
        });
      }
      if (statusCloseDetails) {
        this.currentEvent = undefined;
      }
      this.currentEventEdit = undefined;
      this.modal.createOrEdit = false;
    },

    onEventClick(event) {
      this.currentEvent = cloneDeep(event);
      this.$refs.asideRight.open();
      this.setEventDetail();
    },

    closeEventDetails() {
      this.currentEvent = undefined;
      this.currentEventEdit = undefined;
    },

    downloadCalendar() {
      const URL_PARAMS = cloneDeep(this.modelFilter);
      URL_PARAMS.termin_frame_after = this.startDate;
      URL_PARAMS.termin_frame_before = this.endDate;

      this.getHttp({ url: `${ this.getUrl }ics_download_kalender/`, urlParams: URL_PARAMS, responseType: "blob", fullResponse: true }).then(
        response => {
          const disposition = response.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i;
            const matches = filenameRegex.exec(disposition);
            if (matches !== null && matches[2]) {
              this.filename = matches[2];
            }
          }
          fileDownload(response.data, this.filename);
        },
        () => {
          this.addNotification({ text: "_MSG_CALENDAR_ICS_EXPORT_ERROR_", type: "error" });
        }
      );
    },

    closeConfirm({ status }) {
      if (status) {
        this.loadData({
          startDate: this.startDate,
          endDate: this.endDate,
        });
        this.currentEvent = undefined;
      }
      this.modal.delete = false;
    },

    setEventDetail() {
      this.getHttp({
        url: `${ this.getUrl }${ this.currentEvent.pk }/`
      }).then(
        response => {
          this.eventsDetails[response.pk] = response;
        }
      );
    },
  },
};
