import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import AuszahlungGoToBoxAuszahlungLink from "./AuszahlungGoToBoxAuszahlungLink/AuszahlungGoToBoxAuszahlungLink.vue";
import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import FormularListeBox from "../../../Formulare/FormularListeBox/FormularListeBox.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  CheckPermissionsSyncMixin,
} from "../../../../global/mixins/PermissionMixin";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";
import SyConfigAPI from "../../../../global/compositionAPI/SyConfigAPI";

// @vue/component
export default {
  name: "AuszahlungGoToBox",
  components: {
    AngularLink,
    AuszahlungGoToBoxAuszahlungLink,
    AsideRightBox,
    FormularListeBox,
    PuxTranslate,
  },
  mixins: [
    CheckPermissionsSyncMixin,
  ],
  props: {
    antrag: {
      type: Object,
      required: false,
      default: undefined,
    },
    ast: {
      type: Object,
      required: false,
      default: undefined,
    },
    auszahlung: {
      type: Object,
      required: true,
    },
    auszahlungenWithoutCurrent: {
      type: Array,
      required: true,
    },
    isAuszahlungReloading: {
      type: Boolean,
      required: false,
    },
    isPersonalBaumVisible: {
      type: Boolean,
      required: false,
    },
    vertrag: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  setup() {
    const {
      filterSpaced,
    } = FiltersAPI();

    const {
      getSyConfigsValue,
    } = SyConfigAPI();

    return {
      filterSpaced,
      getSyConfigsValue,
    };
  },
  data() {
    return {
      optionsAsideRightBox: {
        active: true,
        icon: "verbunden",
      },
      isFormularListeVisible: undefined,
    };
  },
  computed: {
    isAntragLinkVisible() {
      return this.checkPermissionsSync({ perm: "foerderantrag.view" }) &&
        this.antrag;
    },

    isVertragLinkVisible() {
      return this.checkPermissionsSync({ perm: "vertrag.view" }) &&
        this.vertrag;
    },

    isAstLinkVisible() {
      return this.checkPermissionsSync({ perm: "antragstellerorg.view" }) &&
        this.ast;
    },

    isPersonalBaumLinkVisible() {
      return this.isPersonalBaumVisible &&
        this.vertrag;
    },

    extraForTranslateAntragTxt() {
      return {
        a_nr: this.filterSpaced(this.antrag.a_nr, true),
      };
    },

    extraForTranslateAntragHtml() {
      return {
        a_nr: this.filterSpaced(this.antrag.a_nr),
      };
    },

    extraForTranslateVertragTxt() {
      return {
        v_nr: this.filterSpaced(this.vertrag.v_nr, true),
      };
    },

    extraForTranslateVertragHtml() {
      return {
        v_nr: this.filterSpaced(this.vertrag.v_nr, true),
      };
    },

    extraForTranslateAst() {
      return {
        ast_name: this.ast.ast_name,
      };
    },

    canViewBelege() {
      return !!this.vertrag &&
        !!this.instanceConfig.vertragsbeleg &&
        this.checkPermissionsSync({
          perm: "vertragskostenpositionsbelege.view",
          permArray: this.vertrag.user_permissions });
    },

    extraForVertragTranslate() {
      return {
        v_nr: this.filterSpaced(this.vertrag.v_nr),
      };
    },

    extraForVertragTitleTranslate() {
      return {
        v_nr: this.filterSpaced(this.vertrag.v_nr, true),
      };
    },
  },
  created() {
    this.setConfig();
  },
  methods: {
    setConfig() {
      this.instanceConfig = this.getSyConfigsValue("instance_pages", {});
    },
  },
};
