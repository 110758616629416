"use strict";

import BaseEditController from "../../standard_foerdermodule.edit.controller";

class RegisternummerController extends BaseEditController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule) {
    super($scope, $q, Foerdermodule);
    this.name = "RegisternummerController";
    this.errorsLabel = {
      ast_registernummer: "Vereins- oder Handelsregisternummer: ",
      ast_gericht: "Zuständiges Gericht",
    };

    this.globalBearbeiten = true;
  }
}

export default RegisternummerController;
