import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";

import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";
import {
  CheckPermissionsSyncMixin,
} from "../../../../global/mixins/PermissionMixin";

import {
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "UnterzeichnendeHinweis",
  components: {
    PuxAlert,
  },
  mixins: [
    CheckPermissionsSyncMixin,
    SyConfigMixin,
  ],
  props: {
    auszahlung: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      auszahlungConfig: undefined,
      unterzeichnendeStatusHinweis: undefined,
    };
  },
  computed: {
    isBankverbindungStatusVisible() {
      return this.checkPermissionsSync({
        perm: "auszahlung.bv_status.view",
        permArray: this.userPermissions,
      });
    },

    unterzeichnendeHinweisVisible() {
      return !!this.unterzeichnendeHinweis;
    },

    unterzeichnendeHinweis() {
      if (this.isBankverbindungStatusVisible && this.auszahlung.az_vertreter_geaendert !== null) {
        if (this.unterzeichnendeStatusHinweis[`${ this.auszahlung.az_vertreter_geaendert }`] &&
          size(this.unterzeichnendeStatusHinweis[`${ this.auszahlung.az_vertreter_geaendert }`].text) > 0) {
          return this.unterzeichnendeStatusHinweis[`${ this.auszahlung.az_vertreter_geaendert }`];
        }
      }
      return "";
    },

    userPermissions() {
      return this.auszahlung.user_permissions;
    },
  },
  created() {
    this.checkSyConfig();
    this.setUnterzeichendeStatusHinweisWithSyConfig();
  },
  methods: {
    checkSyConfig() {
      this.auszahlungConfig = this.getSyConfigsValue("auszahlung", {});
    },

    setUnterzeichendeStatusHinweisWithSyConfig() {
      this.unterzeichnendeStatusHinweis = {
        null: {
          class: "info",
          text: this.auszahlungConfig.hinweis_vertreter_leer,
        },
        false: {
          class: "success",
          text: this.auszahlungConfig.hinweis_vertreter_gleich,
        },
        true: {
          class: "warning",
          text: this.auszahlungConfig.hinweis_vertreter_geaendert,
        },
      };
    },
  },
};
