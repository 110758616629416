"use strict";

import "restangular";
import angular from "angular";

import coreServices from "dias/core/services";

import Foerdermodule from "./foerdermodule.service";
import Foerderanzeigekontext from "./foerderanzeigekontexte.service";
import Systemmodul from "./systemmodule.service";

const modelsModule = angular.module("foerdermodule.models", [
  "restangular",
  coreServices.name
])

  .provider("Foerdermodule", Foerdermodule)
  .provider("Foerderanzeigekontext", Foerderanzeigekontext)
  .provider("Systemmodul", Systemmodul)
;

export default modelsModule;
