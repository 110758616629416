import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import KWorkflowstatusgrupppenUUIDs from "../../../../../const/KWorkflowstatusgrupppenUUIDs";

// @vue/component
export default {
  name: "AufgabenvorlagenDetailsStatusgruppenTableHeader",
  components: {
    PuxTranslate,
  },
  props: {
    workflowTypenGroups: {
      type: Object,
      required: true,
    },
  },
  computed: {
    KWorkflowstatusgrupppenUUIDs() {
      return KWorkflowstatusgrupppenUUIDs;
    },
  },
};
