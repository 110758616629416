"use strict";

import BaseModel from "dias/core/models/baseModel";

class Durchfuehrendeorgeinheiten extends BaseModel {
  constructor() {
    super("durchfuehrendeorgeinheiten");
  }
}

export default Durchfuehrendeorgeinheiten;
