import Module, {
  MAPPING,
} from "../Module/Module";
import {
  toString,
} from "lodash-es";
import {
  EventBus,
} from "../../../global/functions/event-bus";
import SNAPSHOT_MODES from "../../Snapshot/SnapshotModule/modes";

// @vue/component
export default {
  name: "GeschaeftsregelModul",
  components: {
    ...Module,
  },
  props: {
    baseUrl: {
      type: String,
      required: false,
      default: undefined,
    },
    module: {
      type: Object,
      required: true,
    },
    updateModule: {
      type: Function,
      required: false,
      default: undefined,
    },
    obj: {
      type: Object,
      required: false,
      default: undefined,
    },
    readonly: {
      type: Boolean,
    },
    hasBaseEditPerm: {
      type: Boolean,
      default: undefined,
    },
    kontext: {
      type: String,
      default: undefined,
    },
    group: {
      type: [String, Number],
      default: undefined,
    },
    idx: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    idPrefix: {
      type: String,
      default: () => ""
    },
    saveCallback: {
      type: Function,
      default: undefined,
    },
    infoprovider: { // für KF
      type: Object,
      default: undefined,
    },
    updateInfoprovider: { // für KF
      type: Function,
      required: false,
      default: undefined,
    },
    snapshot: {
      type: Object,
      default: undefined,
    },
    snapshotMode: {
      type: String,
      default: "snapshot",
      validator: value => SNAPSHOT_MODES.indexOf(value) !== -1,
    },
    astdiffstatsus: {
      type: Boolean,
    },
    notizenKey: {
      type: String,
      default: undefined,
    },
    extra: {
      type: Object,
      default: undefined,
    },
    updateSnapshotDiff: {
      type: Function,
      default: () => {},
    },
    anzeigekontext: {
      type: String,
      required: false,
      default: undefined,
    },
    dokumentePermissionsFromObj: {
      type: Object,
      required: false,
      default: undefined,
    },
    statusExample: {
      type: Boolean,
      required: false,
    },
    dokumenteBaseUrl: {
      type: String,
      required: false,
      default: undefined,
    },
    statusKF: {
      type: Boolean,
      required: false,
    },
    statusKFDiff: {
      type: Boolean,
      required: false,
    },
    periodKeyShow: {
      type: String,
      required: false,
      default: undefined,
      info: "Key nur für KF mit Perioden",
    },
    extraStatic: {
      type: Object,
      required: false,
      default: () => ({}),
      info: "Objekt mit Daten, die sich nicht ändern",
    },
  },
  data() {
    return {
      mapping: MAPPING,
      baseUrlLocal: undefined,
      statusShow: true,
    };
  },
  computed: {
    idxLocal() {
      const IDX = toString(this.idx);
      if (IDX[0] !== ".") {
        return this.idx;
      }
      return undefined;
    },
  },
  created() {
    this.initEventBus();
    this.setBaseUrlLocal();
  },
  beforeUnmount() {
    this.destroyEventBus();
  },
  methods: {
    setBaseUrlLocal() {
      if (this.statusExample || this.baseUrl || !this.obj.getRestangularUrl) {
        return;
      }

      let baseUrl = this.obj.getRestangularUrl().replace("/api/", "");
      if (baseUrl[baseUrl.length - 1] !== "/") {
        baseUrl += "/";
      }
      this.baseUrlLocal = baseUrl;
    },

    initEventBus() {
      if (!this.statusExample) {
        return;
      }
      EventBus.$on("updateVorbelegung", this.updateVorbelegung);
    },

    destroyEventBus() {
      if (!this.statusExample) {
        return;
      }
      EventBus.$off("updateVorbelegung", this.updateVorbelegung);
    },

    updateVorbelegung() { // reload gr
      this.statusShow = false;
      setTimeout(() => {
        this.statusShow = true;
      });
    },
  },
};
