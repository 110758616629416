import {
  ref,
} from "vue";

export default function SitzungAntraegeDocumentsAPI() {
  const dokumentePermissions = ref({
    view: "dokument.view",
    create: "dokument.create",
    delete: "dokument.delete",
    update: "dokument.update",
    update_gueltigkeit: "",
  });

  const docPermissions = ref(["dokument.view", "notizen.view"]);

  return {
    dokumentePermissions,
    docPermissions,
  };
}
