"use strict";

import BaseModel from "dias/core/models/baseModel";
import Objektaufgabe from "dias/workflow/models/objektaufgabe.service";
import Foerdermodule from "dias/foerderantrag/foerdermodule/models/foerdermodule.service";
import ObjektBenachrichtigung from "dias/benachrichtigungen/models/objektbenachrichtigung.service";

class Projektberichte extends BaseModel {
  constructor() {
    super("projektberichte", {
      subPaths: {
        aufgaben: new Objektaufgabe(),
        module: new Foerdermodule(),
        benachrichtigungen: new ObjektBenachrichtigung(),
      }
    });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.getKAufgaben = () => collection.customGET("kaufgaben");
    return collection;
  }

  extendModel(model) {
    model = super.extendModel(model);
    model.restangularizeAufgabe = aufgabe => this.Restangular.restangularizeElement(model, aufgabe, "aufgaben", {});

    if (model.aktuelle_aufgabe) {
      // console.log("restangularize");
      model.aktuelle_aufgabe = model.restangularizeAufgabe(model.aktuelle_aufgabe);
      // console.log(model.aktuelle_aufgabe);
    }
    return model;
  }
}

export default Projektberichte;
