import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "WorkflowfolgenDetailsAusfuerungskonfigurationServerfunktion",
  components: {
    Modal,
    PuxButton,
  },
  props: {
    serverfunktion: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    itemsCount: {
      type: Number,
      required: true,
    },
  },
  emits: [
    "moveServerfunktionUp",
    "moveServerfunktionDown",
    "removeServerfunktion",
  ],
  data() {
    return {
      confirmDeleteShow: false,
      confirmDeleteOptions: {},
    };
  },
  computed: {
    moveUpButtonHtmlId() {
      return `move_up_serverfunktion_button_${ this.index }`;
    },

    moveDownButtonHtmlId() {
      return `move_down_serverfunktion_button_${ this.index }`;
    },

    removeButtonHtmlId() {
      return `remove_serverfunktion_button_${ this.index }`;
    },

    selectorClose() {
      return `#${ this.removeButtonHtmlId }`;
    },

    moveUpDisabled() {
      return this.index === 0;
    },

    moveDownDisabled() {
      return this.index === this.itemsCount - 1;
    },
  },
  methods: {
    moveServerfunktionUp() {
      this.$emit("moveServerfunktionUp", {
        index: this.index,
      });
    },

    moveServerfunktionDown() {
      this.$emit("moveServerfunktionDown", {
        index: this.index,
      });
    },

    removeServerfunktion() {
      this.closeModalConfirmRemoveServerfunktion();
      this.$emit("removeServerfunktion", {
        index: this.index,
      });
    },

    closeModalConfirmRemoveServerfunktion() {
      this.confirmDeleteShow = false;
    },

    openModalConfirmRemoveServerfunktion() {
      this.confirmDeleteOptions = {
        okClass: "btn btn-primary",
        msg: "_TITLE_WORKFLOWFOLGENDETAILS_SERVERFUNKTION_REMOVE_{{index}}_{{name}}_",
        title: "_TITLE_WORKFLOWFOLGENDETAILS_REMOVE_SELECTION_",
        extra: {
          name: this.serverfunktion.wfs_bez,
          index: this.index + 1,
        },
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: this.removeServerfunktion,
        cancelCallback: this.closeModalConfirmRemoveServerfunktion,
        loading: false,
      };
      this.confirmDeleteShow = true;
    },
  },
};
