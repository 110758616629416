// Modul zur Erfassung von sich wiederholenden Elenten im Antrag
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import FoerderantragModels from "dias/foerderantrag/models";
import FoerderantragPipes from "dias/foerderantrag/pipes";

import parameter from "./parameter";


const elementliste = angular.module("dias.standard_foerdermodule.elementliste", [
  CoreModels.name,
  parameter.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
  FoerderantragPipes.name,
]);

export default elementliste;
