"use strict";

import BaseFunction from "../baseFunction.js";

class SimpleFunction extends BaseFunction {
  /*@ngInject*/
  constructor(DiasNotification, $q, workflowfolge, obj, extra) {
    super(DiasNotification, $q, workflowfolge, obj, extra);
  }
}

export default SimpleFunction;
