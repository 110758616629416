import UiAuszahlungsmodiReadOnlyElement from "./UiAuszahlungsmodiReadOnlyElement/UiAuszahlungsmodiReadOnlyElement.vue";
import translate from "../../../../../../global/directives/translate";
import {
  initModelFromList,
  toFormElementFromParameter
} from "../../../../../../global/functions/mappingForParameterToFormElement";
import { cloneDeep, forEach, isNil } from "lodash-es";

// @vue/component
export default {
  name: "UiAuszahlungsmodiReadOnly",
  components: {
    UiAuszahlungsmodiReadOnlyElement,
  },
  directives: {
    translate,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      listConfig: [],
    };
  },
  computed: {
    modelDefault() {
      return initModelFromList({ list: this.listConfig });
    },
    modelLocal() {
      const MODEL = cloneDeep(this.model);
      forEach(MODEL, (item, index) => {
        forEach(item, (value, key) => {
          if (isNil(value) && key in this.modelDefault) {
            MODEL[index][key] = this.modelDefault[key];
          }
        });
      });
      return MODEL;
    },
  },
  created() {
    this.setList();
  },
  methods: {
    setList() {
      this.listConfig = toFormElementFromParameter({ obj: this.options.konfig, showLabel: true }).list;
    },
  },
};
