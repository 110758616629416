<div><h3>Permission</h3><h4>Diese Permission habe ich.</h4><permission :permission="'profil.view'"><div>Show me</div></permission><div v-highlight=""><pre>JS<code>import Permission from "../../global/components/Permission/Permission";

export default {
  ...
  components: [
    Permission,
  ],
  ...
};</code></pre><pre>PUG<code>permission(
  :permission="'profil.view'"
)
  div Show me
</code></pre></div><h4>Diese Permission habe ich nicht.</h4><permission :permission="'perm_habe_ich_nicht'"><div>Show me</div></permission><div v-highlight=""><pre>JS<code>import Permission from "../../global/components/Permission/Permission";

export default {
  ...
  components: [
    Permission,
  ],
  ...
};</code></pre><pre>PUG<code>permission(
  :permission="'perm_habe_ich_nicht'"
)
  div Show me
</code></pre></div><h4>Diese Permission habe ich nicht, aber der Parameter 'missing' ist 'true'.</h4><permission :permission="'perm_habe_ich_nicht'" :missing="true"><div>Show me</div></permission><div v-highlight=""><pre>JS<code>import Permission from "../../global/components/Permission/Permission";

export default {
  ...
  components: [
    Permission,
  ],
  ...
};</code></pre><pre>PUG<code>permission(
  :permission="'perm_habe_ich_nicht'"
  :missing="true"
)
div Show me
</code></pre></div><h4>Diese Permission habe ich im Array.</h4><permission :permission="'profil.view'" :src="['profil.view', 'profil.create']"><div>Show me</div></permission><div v-highlight=""><pre>JS<code>import Permission from "../../global/components/Permission/Permission";

export default {
  ...
  components: [
    Permission,
  ],
  ...
};</code></pre><pre>PUG<code>permission(
  :permission="'profil.view'"
  :src="['profil.view', ''profil.create'']"
)
div Show me
</code></pre></div><h4>Props</h4><table class="table table-striped table-bordered"><thead><tr><th style="width: 150px;">Name</th><th>Beschreibung</th><th>Typ</th></tr></thead><tbody><tr v-for="prop in props" :key="prop.name"><td>{{ prop.name }}</td><td>{{ prop.description }}</td><td>{{ prop.type }}</td></tr></tbody></table></div>