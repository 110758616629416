"use strict";

import parameterServices from "dias/foerderregeln/services";
import template from "./config.jade";
import rotemplate from "./readonly.jade";
import controller from "./controller";

const parametermoduleSingleChoice = angular.module("dias.bewertungen.parameter.rating", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("rating", "parametermodul-rating");
  })
  .component("parametermodulRating", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
      change: "&?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulRatingRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: rotemplate(),
    controller: controller,
    controllerAs: "vm",
  });
export default parametermoduleSingleChoice;
