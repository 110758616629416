"use strict";

import template from "./datenschutzeinwilligung.jade";

class DatenschutzEinwilligungController {
  /*@ngInject*/
  constructor(
    DiasNotification, 
    SyConfigs, 
    Logout, 
    AuthService,
    Datenschutzeinwilligung, 
    DatenschutzaktionService, 
    $state
  ) {
    this.loading = true;
    this.$state = $state;
    this.Logout = Logout;
    this.AuthService = AuthService;
    this.Datenschutzeinwilligung = Datenschutzeinwilligung;
    this.DiasNotification = DiasNotification;
    this.DatenschutzaktionService = DatenschutzaktionService;
    this.model = {
      de_kontext: "Login",
      nutzer: undefined,
      frage: undefined,
      einwilligung: undefined,
    };
    this.frage = {};
    this.errorsLabel = {
      einwilligung: "Datenschutzerklärung: ",
    };

    this.AuthService.refreshUser().then(user => this.updateUser(user));
  }

  updateUser(user) {
    this.user = user;
    this.frage = angular.merge(
      this.frage,
      this.user.offene_datenschutzfragen[0]
    );
    this.model.nutzer = user.pk;
    this.model.frage = this.frage.pk;
    this.model.einwilligung = this.frage.def_default;
    this.loading = false;
  }

  accept() {
    this.saveStatus = true;
    this.model.aktion = this.model.einwilligung ? this.DatenschutzaktionService.EINWILLIGUNG : this.DatenschutzaktionService.WIDERRUF;
    this.Datenschutzeinwilligung.post(this.model).then(
      () => {
        this.DiasNotification.page.success(this.frage.def_hinweis_akzeptiert);
        this.AuthService.refreshUser().then(
          user => {
            this.updateUser(user);
            this.$state.go("root.home");
          }
        );
      },
      errors => this.onErrors(errors, this.errorsLabel)
    ).finally(() => this.saveStatus = false);
  }

  decline() {
    this.saveStatus = true;
    this.Logout.post().then(
      () => {
        this.AuthService.refreshUser().then(
          () => {
            this.saveStatus = false;
            this.$state.go("root.login", { m: "datenschutz_declined" });
          }
        );
      }
    );
  }

  onErrors(errors, labelErrors) {
    this.DiasNotification.form.clear();
    this.DiasNotification.form.error(errors, "Bitte prüfen Sie Ihre Angaben.", {}, labelErrors);
    if (errors.data) {
      this.errors = errors.data;
    } else {
      this.errors = errors;
    }
  }
}

export default {
  template: template(),
  controller: DatenschutzEinwilligungController,
  controllerAs: "vm",
  bindings: {}
};
