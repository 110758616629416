import Alert from "../../../../../global/components/Alert/Alert.vue";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  cloneDeep,
  forEach,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "ExportauftragJsonWizardStepVorbereiten",
  components: {
    Alert,
    FormElement,
    PuxTranslate,
  },
  props: {
    impexpauftragvorlage: {
      type: Array,
      default: () => [],
    },
    tabelle: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => {},
    },
    updateModel: {
      type: Function,
      default: undefined,
    },
  },
  data() {
    return {
      editModel: {
        vorlage: undefined,
        tabelle: undefined,
      }
    };
  },
  computed: {
    selectOptions() {
      return {
        id: "vorlage",
        type: "select",
        label: "_LBL_EXPORTAUFTRAG_JSON_WIZARD_VORLAGE_",
        required: "true",
        keyId: "pk",
        keyLabel: "bez",
        search: "true",
        searchList: ["bez"],
        view: "v-alt",
        translate: "true",
        deselect: "false",
      };
    },
  },
  created() {
    this.initModel();
    this.initErrorLabels();
  },
  methods: {
    initModel() {
      this.onUpdateModel();
    },

    initErrorLabels() {
      const ERROR_LABELS = {
        non_field_errors: ""
      };
      forEach(this.fields, field => {
        ERROR_LABELS[field.id] = field.label;
      });
      this.errorLabels = ERROR_LABELS;
    },

    showErrors() {
      return size(this.errors);
    },

    onUpdateModel() {
      const MODEL_CLONE = cloneDeep(this.editModel);
      this.updateModel({ model: MODEL_CLONE });
    },
  },
};
