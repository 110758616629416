import VertretungsberechtigteTr from "./VertretungsberechtigteTr/VertretungsberechtigteTr.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import VertretungsberechtigteModalCreate from "../../../../components/Vertretungsberechtigte/VertretungsberechtigteModalCreate/VertretungsberechtigteModalCreate.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import Alert from "../../../../global/components/Alert/Alert.vue";

import ModuleMixin from "../ModuleMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import {
  cloneDeep,
  map,
  keyBy,
  forEach,
  get,
  values,
  indexOf,
} from "lodash-es";


const SNAPSHOT_CHANGES_LIST = [
  "display_name",
  "av_deaktiviert",
  "gueltig_antrag",
  "av_funktion",
  "berechtigung",
];

// @vue/component
export default {
  name: "Vertretungsberechtigte",
  components: {
    VertretungsberechtigteTr,
    PuxButton,
    VertretungsberechtigteModalCreate,
    Modal,
    Alert,
  },
  mixins: [
    ModuleMixin,
    PermissionMixin
  ],
  data() {
    return {
      statusLoadingVertretungsberechtigte: false,
      statusLoadingPermissions: true,
      antragVertretungsberechtigte: [],
      statusModalCreate: undefined,
      confirmShow: undefined,
      confirmOptions: undefined,
      selectorClose: undefined,
      astPermissions: [],
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      if (!this.antragVertretungsberechtigte.length) {
        OPTIONS.list = [
          {
            id: "_antrag_vertretungsberechtigte_not_date",
            htmlId: `${ this.htmlRegelNummer }_antrag_vertretungsberechtigte_not_date`,
            type: "template",
            slot: "templateNotDate",
            editonly: true,
          },
        ];
      } else {
        OPTIONS.list = [
          {
            id: "antrag_vertretungsberechtigte",
            htmlId: `${ this.htmlRegelNummer }_antrag_vertretungsberechtigte`,
            type: "multiselect",
            label: "_LBL_FORM_ELEMENT_VERTRETUNGSBERECHTIGTE_",
            translate: true,
            keyId: "pk",
            keyLabelCallback: ({ item }) => `${ item.display_name }, (${ item.berechtigung })`,
            editonly: true,
            view: "v-alt",
            data: this.antragVertretungsberechtigte,
            required: this.modelParameter.pflichtfeld,
          },
        ];
      }
      OPTIONS.list.push(
        {
          id: "_antrag_vertretungsberechtigte_add",
          htmlId: `${ this.htmlRegelNummer }_antrag_vertretungsberechtigte_add`,
          type: "template",
          slot: "templateAdd",
          editonly: true,
        }
      );
      return OPTIONS;
    },

    isLoading() {
      return this.statusLoadingPermissions || this.statusLoadingVertretungsberechtigte || this.statusReloading;
    },

    statusVisible() {
      return this.obj.antragstellerorg && indexOf(this.astPermissions, "astorgvertreter.view") !== -1;
    },

    statusAddVisible() {
      return this.modelParameter.erlaube_hinzufügen && this.obj.antragstellerorg && indexOf(this.astPermissions, "astorgvertreter.create") !== -1;
    },

    buttonAddId() {
      return `${ this.htmlRegelNummer }_add`;
    },

    modelVertretungList() {
      const MODEL = cloneDeep(this.model);
      MODEL.antrag_vertretungsberechtigte = map(MODEL.antrag_vertretungsberechtigte, "pk");
      return MODEL;
    },

    snapshotModuleObj() {
      if (this.statusSnapshot &&
        this.snapshotModule &&
        this.snapshotModule.antrag_vertretungsberechtigte &&
        this.snapshotModule.antrag_vertretungsberechtigte.length) {
        return keyBy(this.snapshotModule.antrag_vertretungsberechtigte, "pk");
      }
      return {};
    },

    snapshotFiltered() {
      const SNAPSHOT = {
        add: {},
        delete: [],
        changes: {},
        status: false,
      };
      if (this.statusSnapshot) {
        const SNAPSHOT_MODULE_OBJ = cloneDeep(this.snapshotModuleObj);
        if (this.model.antrag_vertretungsberechtigte && this.model.antrag_vertretungsberechtigte.length) {
          forEach(this.model.antrag_vertretungsberechtigte, item => {
            if (!SNAPSHOT_MODULE_OBJ[item.pk]) {
              SNAPSHOT.add[item.pk] = true;
              SNAPSHOT.status = true;
            } else {
              forEach(SNAPSHOT_CHANGES_LIST, key => {
                if (get(item, key) !== get(SNAPSHOT_MODULE_OBJ[item.pk], key)) {
                  SNAPSHOT.changes[item.pk] = cloneDeep(SNAPSHOT_MODULE_OBJ[item.pk]);
                  SNAPSHOT.status = true;
                  return false;
                }
              });
              delete SNAPSHOT_MODULE_OBJ[item.pk];
            }
          });
        }
        SNAPSHOT.delete = values(SNAPSHOT_MODULE_OBJ);
        if (SNAPSHOT.delete.length) {
          SNAPSHOT.status = true;
        }
      }
      return SNAPSHOT;
    },

    statusDiff() {
      return this.snapshotFiltered.status;
    },

    statusNoData() {
      return !(this.snapshotFiltered.delete.length ||
        (this.model.antrag_vertretungsberechtigte &&
          this.model.antrag_vertretungsberechtigte.length));
    },

    antragstellerUrl() {
      return `antragstellerorganisationen/${ this.antragstellerorgPk }/vertretungsberechtigte/`;
    },

    antragstellerorgPk() {
      if (get(this.obj, "antragstellerorg.pk")) {
        return this.obj.antragstellerorg.pk;
      }
      return this.obj.antragstellerorg;
    },

    showAstLink() {
      return !get(this.obj, "block_link_ast", false);
    },

    isButtonDeleteDisabled() {
      return this.modelParameter.pflichtfeld && this.model.antrag_vertretungsberechtigte.length === 1;
    },

    isModuleEditable() {
      return !this.statusReadonly && this.statusVisible;
    },
  },
  watch: {
    "obj.antragstellerorg"() {
      this.loadData();
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadVertretungsberechtigte() {
      this.statusLoadingVertretungsberechtigte = true;
      return this.getListHttp({
        url: "vertretungsberechtigte_modul/",
        urlParams: {
          antragstellerorg_id: this.antragstellerorgPk,
          valid: true,
          deaktiviert: false,
        }
      }).then(
        response => {
          this.antragVertretungsberechtigte = response;
        }
      ).finally(() => this.statusLoadingVertretungsberechtigte = false);
    },

    loadData() {
      const ASTORG = this.obj.antragstellerorg_obj || this.obj.antragstellerorg;
      this.statusLoadingPermissions = true;
      if (!ASTORG) {
        this.astPermissions = [];
        this.statusLoadingPermissions = false;
        return;
      }
      if (this.kontext === "sitzungsantrag") {
        this.astPermissions = ["astorgvertreter.view"];
        this.statusLoadingPermissions = false;
        this.loadVertretungsberechtigte();
        return;
      }
      this.checkPermissionsApi({
        perms: [
          "astorgvertreter.create",
          "astorgvertreter.view"
        ],
        content_type: ASTORG.content_type,
        pk: ASTORG.pk
      }).then(
        response => {
          this.astPermissions = response;
          if (indexOf(this.astPermissions, "astorgvertreter.view") !== -1) {
            this.loadVertretungsberechtigte();
          }
        },
        () => this.astPermissions = []
      ).finally(() => this.statusLoadingPermissions = false);
    },

    onReceiveSignal() {
      this.reloadModule();
    },

    openModalCreate() {
      this.statusModalCreate = true;
    },

    closeModalCreate({ response } = {}) {
      if (response) {
        this.reloadData();
      }
      this.statusModalCreate = false;
    },

    reloadData() {
      this.loadVertretungsberechtigte();
    },

    confirmDeleteVertreter({ vertreter }) {
      this.selectorClose = [
        `#${ this.htmlRegelNummer }_delete_${ vertreter.pk }`,
        `#${ this.htmlRegelNummer }`
      ];
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: `_HTML_GR_VERTRETUNGSBERECHTIGTE_MODAL_DELETE_HEADER_{{name}}_`,
        msg: "_HTML_GR_VERTRETUNGSBERECHTIGTE_MODAL_DELETE_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteVertreter({ vertreter }),
        cancelCallback: this.closeModalDelete,
        loading: false,
        extra: {
          name: vertreter.display_name,
        },
      };
      this.confirmShow = true;
    },

    deleteVertreter({ vertreter }) {
      const MODEL = cloneDeep(this.modelVertretungList) || {};
      MODEL.antrag_vertretungsberechtigte = MODEL.antrag_vertretungsberechtigte || [];
      const INDEX = MODEL.antrag_vertretungsberechtigte.indexOf(vertreter.pk);
      if (INDEX !== -1) {
        MODEL.antrag_vertretungsberechtigte.splice(INDEX, 1);
      }
      this.save({ model: MODEL }).then(
        () => {
          this.closeModalDelete();
        }
      );
    },

    closeModalDelete() {
      this.confirmShow = false;
    },
  },
};
