var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;







jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :ctx.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "ctx.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in ctx.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<div dias-cloak=\"ctx.loadingModule\" class=\"modal-body\"><div class=\"row\"><section class=\"main col-10 offset-1\"><div ng-attr-id=\"{{ ctx.DiasNotification.form.containerId }}\"></div><form><fieldset>");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"re_kbez\" type=\"text\" name=\"tom_kbez\" ng-model=\"ctx.model.tom_kbez\" aria-required=\"true\" ng-attr-aria-invalid=\"{{ctx.errors.tom_kbez ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "tom_kbez", "Name");
buf.push("<hr>");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<select id=\"modul\" name=\"modul\" ng-model=\"ctx.ssm_id\" ng-change=\"ctx.onSelectSitzungsmodul()\" aria-required=\"true\" role=\"listbox\" ng-disabled=\"ctx.model.pk\" ng-attr-aria-invalid=\"{{ctx.errors.modul ? 'true' : 'false'}}\" class=\"form-control\"><option value=\"\">---------</option><option ng-repeat=\"ssm in ctx.sitzungsmodulkatalog\" value=\"{{ ssm.pk }}\">{{ ssm.ssm_bez }}</option></select>");
}
}, "modul", "Modul");
buf.push("<div class=\"well\"><label>Modulbeschreibung</label><div ng-bind-html=\"ctx.ssm.ssm_beschreibung\"></div></div><h4 ng-if=\"ctx.parameter &amp;&amp; ctx.paramKeys.length &gt; 0\">Parameter</h4><div vueinit props=\"{ errors: ctx.errors, list: ctx.vueParameterList, model: ctx.vueModel, change: ctx.vueChange }\"><form-element v-for=\"conf in propsAngular.list\" v-bind:key=\"conf.id\" v-bind:options=\"conf\" v-bind:model-value=\"propsAngular.model[conf.id]\" v-bind:errors=\"propsAngular.errors[conf.id]\" v-bind:change=\"propsAngular.change\"></form-element></div></fieldset></form></section></div></div><div class=\"modal-footer\"><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"exitWithCallback(ctx.save)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary\"><span>{{:: ctx.model.pk?'Übernehmen':'Hinzufügen'}}</span></button><button ng-click=\"exit()\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-default\"><span>Abbrechen</span></button></div></div></div>");;return buf.join("");
}