"use strict";

import StandardFinanzierungsController from "../../finanzierung.controller";

class ZuschussPeriodenController extends StandardFinanzierungsController {
  /*@ngInject*/
  constructor(
    $scope,
    $q,
    Foerdermodule,
    Foerderantrag,
    KFinanzierungsart,
    BetragsrechnerRegistry,
    Vertrag,
    $stateParams,
    $filter,
    i18n,
    AuthService,
  ) {
    super($scope, $q, Foerdermodule, Foerderantrag, KFinanzierungsart, BetragsrechnerRegistry, Vertrag, $stateParams, $filter, i18n, AuthService);
    this.name = "ZuschussPeriodenController";
    // Antrag-Finanzierungsart
    this.antragFinanzierungsart = {};
    this.vertragFinanzierungsart = {};
    this._reloadAntragFinanzierungsart();
  }

  get_prozent() {
    // Im Zweifelsfalle immer den Wert aus der aktuellen Berechnung zurückgeben:
    if (this.infoprovider.details && this.infoprovider.details[this.modul.regel.afr_id]) {
      return this.infoprovider.details[this.modul.regel.afr_id].prozent;
    } else if (this.antragFinanzierungsart) {
      return this.antragFinanzierungsart.afa_prozent;
    }
  }

  show_prozente() {
    // Im Zuschusskontext werden keine Prozente angezeigt
    if (this.kontext === "zuschuss") {
      return false;
    }
    // Wenn eine Zuschussberechnung durchgeführt werden konnte:
    if (this.infoprovider && this.infoprovider.status === 1) {
      return true;
    }
    // Wenn keine Zuschussberechnung durchgeführt wurde:
    if (this.infoprovider && !this.infoprovider.status) {
      return this.antragFinanzierungsart.afa_prozent !== null;
    }
  }

  _reloadAntragFinanzierungsart() {
    const antragId = this.modul.regel.afr_aid;
    const antragregelId = this.modul.regel.afr_id;
    this.Foerderantrag.one(antragId).getList("finanzierungsarten", { antragregel: antragregelId }).then(result => {
      this.antragFinanzierungsart = result[0]; // sollte pro antragregel max. eine geben
      if (this.kontext === "zuschuss_vertrag") {
        this.Vertrag.one(this.$stateParams.id).getList("finanzierung", { foerderantragfinanzierungsart: this.antragFinanzierungsart.pk }).then(
          result => {
            this.vertragFinanzierungsart = result[0];
          }
        );
      }
    });
  }
}

export default ZuschussPeriodenController;
