import NutzerModalAction from "../NutzerModalAction/NutzerModalAction.vue";
import NutzerModalCreate from "../NutzerModalCreate/NutzerModalCreate.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import getTableApi from "../../../const/TableApis";
import { EventBus } from "../../../global/functions/event-bus";
import { gettext } from "../../../global/functions/utils";
import { size } from "lodash-es";

const TABLE_ID = "NutzerListInAntragsteller";
const ASTADMIN_BEZ = "Nutzerverwalter";

const ABGELEHNT = "238533f4-2fbd-4b2f-898f-4ae9c6546c58";
const AKTIV = "8a2e1f7b-9f0d-4302-acc4-04e4c97ff9ba";
const BEANTRAGT = "eec6ccab-85a8-4717-8ff7-0d5317758a4d";
const GESPERRT = "4cb597bd-3939-474b-9076-ad74a7909405";
const FREIGEGEBEN = "d3917a03-734e-4383-ac07-079afa6ce3b2";

// @vue/component
export default {
  name: "NutzerListInAntragsteller",
  components: {
    NutzerModalAction,
    NutzerModalCreate,
    PuxIcon,
    SmartTable,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  props: {
    antragstellerPk: {
      type: String,
      default: undefined,
    },
    antragstellerUserPermissions: {
      type: Array,
      default: undefined,
    },
    amIFachAdmin: {
      type: Boolean,
    },
    amIMasterOfCurrentAstOrg: {
      type: Boolean,
    },
    perms: {
      type: Object,
      default: () => ({}),
    },
    oneAstadminOnly: {
      type: Boolean,
    },
    astMasterLength: {
      type: Number,
      default: 0,
    },
    onAstMasterAction: {
      type: Function,
      required: false,
      default: undefined,
    }
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: "",
        rowActions: [
          {
            label: "_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_ANFRAGE_ANNEHMEN_",
            callback: this.doAccept,
            isHidden: ({ item }) => !this.canAcceptDeclineUser({ item }),
          },
          {
            label: "_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_ANFRAGE_ABLEHNEN_",
            callback: this.doDecline,
            isHidden: ({ item }) => !this.canAcceptDeclineUser({ item }),
          },
          {
            label: "_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_NUTZER_ENTFERNEN_",
            callback: this.doRemoveDeclined,
            isHidden: ({ item }) => !this.canRemoveDeclinedUser({ item }),
          },
          {
            label: "_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_NUTZER_SPERREN_",
            callback: this.doDeactivate,
            isHidden: ({ item }) => !this.canDeactivateUser({ item }),
          },
          {
            label: "_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_NUTZER_FREIGEBEN_",
            callback: this.doReactivate,
            isHidden: ({ item }) => !this.canReactivateUser({ item }),
          },
          {
            label: "_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_ROLLE_NUTZERVERWALTER_ZUWEISEN_",
            callback: this.doAssignAstAdmin,
            isHidden: ({ item }) => !this.canBecomeAstAdmin({ item }),
          },
          {
            label: "_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_ROLLE_NUTZERVERWALTER_ENTFERNEN_",
            callback: this.doRemoveAstAdmin,
            isHidden: ({ item }) => !this.canRemoveAstAdmin({ item }),
          },
        ],
        actions: [],
        label: "_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_NUTZER_",
        sortable: false,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
      },
      status: {
        loading: true,
      },
      modal: {
        action: undefined,
        create: undefined,
      },
      modalOptions: undefined,
      currentNutzer: undefined,
      currentIndex: undefined,
      defaultAuthmethod: undefined,
      hideAuthmethod: undefined,
      AKTIV: AKTIV,
      BEANTRAGT: BEANTRAGT,
      ABGELEHNT: ABGELEHNT,
      GESPERRT: GESPERRT,
      FREIGEGEBEN: FREIGEGEBEN,
    };
  },
  computed: {
    urlCreateUser() {
      return `antragstellerorganisationen/${ this.antragstellerPk }/nutzer_anlegen/`;
    },
  },
  created() {
    this.initUrl();
    this.initTableActions();
    this.initDefaultValues();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { antragstellerPk: this.antragstellerPk } });
    },

    initDefaultValues() {
      const conf = this.getSyConfigsValue("auth_defaults");
      this.defaultAuthmethod = conf.default_astorg;
      this.hideAuthmethod = !conf.show_auth_astorg;
    },

    initTableActions() {
      if (!this.checkPermissionsSync({ perm: "astorgnutzer.create", permArray: this.antragstellerUserPermissions })) {
        return;
      }
      this.options.actions.push({
        label: "_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_NUTZER_HINZUFUEGEN_",
        callback: () => this.modal.create = true,
        type: "button",
        className: "btn btn-primary",
        htmlId: "user_create"
      });
    },

    canAcceptDeclineUser({ item }) {
      return (item.is_aktiv && item.zuordnung_status === this.BEANTRAGT &&
        this.perms["antragsteller.zuordnen.update"] &&
        (this.amIFachAdmin || this.amIMasterOfCurrentAstOrg)
      );
    },

    canRemoveDeclinedUser({ item }) {
      return (item.zuordnung_status === this.ABGELEHNT &&
        this.perms["antragsteller.zuordnen.update"] &&
        (this.amIFachAdmin || this.amIMasterOfCurrentAstOrg)
      );
    },

    canDeactivateUser({ item }) {
      return (item.is_aktiv && !item.is_astadmin &&
        item.zuordnung_status === this.FREIGEGEBEN &&
        item.anu_status === this.AKTIV &&
        this.perms["astorgnutzer.deactivate"] &&
        (this.amIFachAdmin || this.amIMasterOfCurrentAstOrg)
      );
    },

    canReactivateUser({ item }) {
      return (!item.is_aktiv &&
        item.zuordnung_status === this.FREIGEGEBEN &&
        (item.anu_status === this.GESPERRT || size(item.aktive_rollen) === 0) &&
        this.perms["astorgnutzer.deactivate"] &&
        (this.amIFachAdmin || this.amIMasterOfCurrentAstOrg)
      );
    },

    canBecomeAstAdmin({ item }) {
      return (item.is_aktiv &&
        !item.is_astadmin &&
        item.zuordnung_status === this.FREIGEGEBEN &&
        this.perms["antragsteller.astadminrolle_zuweisen"] &&
        (this.amIFachAdmin || this.amIMasterOfCurrentAstOrg)
      );
    },

    canRemoveAstAdmin({ item }) {
      return (item.is_astadmin &&
        this.astMasterLength > 1 &&
        this.perms["antragsteller.astadminrolle_zuweisen"] &&
        (this.amIFachAdmin || this.amIMasterOfCurrentAstOrg)
      );
    },

    doAccept({ item }) {
      this.modalOptions = {
        notificationsSuccessText: this.buildNotificationsSuccessText("_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_AKZEPTIERT_"),
        buttonLabel: "_BTN_NUTZER_LIST_IN_ANTRAGSTELLER_ANNEHMEN_",
        bodyHtml: "_HTML_NUTZER_LIST_IN_ANTRAGSTELLER_ANNEHMEN_{{name}}_",
        bodyHtmlExtra: { name: item.name },
        header: "_TXT_NUTZER_LIST_IN_ANTRAGSTELLER_ANNEHMEN_{{name}}_",
        headerExtra: { name: item.name },
        urlSave: this.buildUrlSave({ userPk: item.pk, action: "accept" }),
      };
      this.openModalAction();
    },

    doDecline({ item }) {
      this.modalOptions = {
        notificationsSuccessText: this.buildNotificationsSuccessText("_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_ABGELEHNT_"),
        buttonLabel: "_BTN_NUTZER_LIST_IN_ANTRAGSTELLER_ABLEHNEN_",
        bodyHtml: "_HTML_NUTZER_LIST_IN_ANTRAGSTELLER_ABLEHNEN_{{name}}_",
        bodyHtmlExtra: { name: item.name },
        header: "_TXT_NUTZER_LIST_IN_ANTRAGSTELLER_ABLEHNEN_{{name}}_",
        headerExtra: { name: item.name },
        urlSave: this.buildUrlSave({ userPk: item.pk, action: "decline" }),
      };
      this.openModalAction();
    },

    doRemoveDeclined({ item }) {
      this.modalOptions = {
        notificationsSuccessText: this.buildNotificationsSuccessText("_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_ABGELEHNTEN_NUTZER_ENTFERNT_"),
        buttonLabel: "_BTN_NUTZER_LIST_IN_ANTRAGSTELLER_ENTFERNEN_",
        bodyHtml: "_HTML_NUTZER_LIST_IN_ANTRAGSTELLER_ENTFERNEN_{{name}}_",
        bodyHtmlExtra: { name: item.name },
        header: "_TXT_NUTZER_LIST_IN_ANTRAGSTELLER_ENTFERNEN_{{name}}_",
        headerExtra: { name: item.name },
        urlSave: this.buildUrlSave({ userPk: item.pk, action: "remove_declined" }),
      };
      this.openModalAction();
    },

    doDeactivate({ item }) {
      this.modalOptions = {
        notificationsSuccessText: this.buildNotificationsSuccessText("_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_SPERREN_"),
        actionLabel: "_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_SPERREN_",
        buttonLabel: "_BTN_NUTZER_LIST_IN_ANTRAGSTELLER_SPERREN_",
        bodyHtml: "_HTML_NUTZER_LIST_IN_ANTRAGSTELLER_SPERREN_{{name}}_",
        bodyHtmlExtra: { name: item.name },
        header: "_TXT_NUTZER_LIST_IN_ANTRAGSTELLER_SPERREN_{{name}}_",
        headerExtra: { name: item.name },
        urlSave: this.buildUrlSave({ userPk: item.pk, action: "deactivate" }),
      };
      this.openModalAction();
    },

    doReactivate({ item }) {
      this.modalOptions = {
        notificationsSuccessText: this.buildNotificationsSuccessText("_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_FREIGEBEN_"),
        buttonLabel: "_BTN_NUTZER_LIST_IN_ANTRAGSTELLER_FREIGEBEN_",
        bodyHtml: "_HTML_NUTZER_LIST_IN_ANTRAGSTELLER_FREIGEBEN_{{name}}_",
        bodyHtmlExtra: { name: item.name },
        header: "_TXT_NUTZER_LIST_IN_ANTRAGSTELLER_FREIGEBEN_{{name}}_",
        headerExtra: { name: item.name },
        urlSave: this.buildUrlSave({ userPk: item.pk, action: "reactivate" }),
      };
      this.openModalAction();
    },

    doAssignAstAdmin({ item }) {
      if (!this.oneAstadminOnly) {
        this.modalOptions = {
          notificationsSuccessText: "_MSG_NUTZER_LIST_ANTRAGSTELLER_MODAL_ASSIGN_AST_SUCCESS_",
          buttonLabel: "_BTN_NUTZER_LIST_ANTRAGSTELLER_MODAL_ASSIGN_AST_SAVE_",
          bodyHtml: "_HTML_NUTZER_LIST_ANTRAGSTELLER_MODAL_ASSIGN_AST_BODY_{{rolle}}_{{name}}_",
          bodyHtmlExtra: {
            rolle: ASTADMIN_BEZ,
            name: item.name
          },
          header: "_LBL_NUTZER_LIST_ANTRAGSTELLER_MODAL_ASSIGN_AST_HEADER_{{rolle}}_",
          headerExtra: {
            rolle: ASTADMIN_BEZ,
          },
          urlSave: this.buildUrlSave({ userPk: item.anu_id, action: "assign_astadmin" }),
          onSave: this.onAstMasterAction
        };
      } else {
        this.modalOptions = {
          notificationsSuccessText: "_MSG_NUTZER_LIST_ANTRAGSTELLER_MODAL_ASSIGN_AST_SUCCESS_",
          buttonLabel: "_BTN_NUTZER_LIST_ANTRAGSTELLER_MODAL_ASSIGN_ASTONE_SAVE_",
          bodyHtml: "_HTML_NUTZER_LIST_ANTRAGSTELLER_MODAL_ASSIGN_ASTONE_BODY_{{rolle}}_{{name}}_",
          bodyHtmlExtra: {
            rolle: ASTADMIN_BEZ,
            name: item.name
          },
          header: "_LBL_NUTZER_LIST_ANTRAGSTELLER_MODAL_ASSIGN_ASTONE_HEADER_{{rolle}}_",
          headerExtra: {
            rolle: ASTADMIN_BEZ,
          },
          urlSave: this.buildUrlSave({ userPk: item.anu_id, action: "assign_astadmin" }),
          onSave: this.onAstMasterAction
        };
      }
      this.openModalAction();
    },

    doRemoveAstAdmin({ item }) {
      this.modalOptions = {
        notificationsSuccessText: "_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_ROLLE_ENTFERNT_",
        buttonLabel: "_BTN_NUTZER_LIST_IN_ANTRAGSTELLER_ROLLE_ENTFERNEN_",
        bodyText: "_HTML_NUTZER_LIST_IN_ANTRAGSTELLER_ROLLE_ENTFERNEN_{{name}}_",
        bodyTextExtra: { name: item.name },
        header: "_TXT_NUTZER_LIST_IN_ANTRAGSTELLER_ROLLE_ENTFERNEN_",
        urlSave: this.buildUrlSave({ userPk: item.anu_id, action: "remove_astadmin" }),
        onSave: this.onAstMasterAction
      };
      this.openModalAction();
    },

    buildUrlSave({ userPk, action }) {
      return `antragstellerorganisationen/${ this.antragstellerPk }/nutzer/${ userPk }/${ action }/`;
    },

    buildNotificationsSuccessText(text) {
      return `${ gettext("_LBL_NUTZER_LIST_IN_ANTRAGSTELLER_BUILD_NOTIFICATION_SUCCESS_") }: ${ text }`;
    },

    openModalAction() {
      this.modal.action = true;
    },

    closeModalAction({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.modal.action = false;
    },

    closeModalCreate({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
      this.modal.create = false;
    },
  },
};
