import AngularLink from "../../AngularLink/AngularLink.vue";
import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";

import {
  gettext,
} from "../../../functions/utils";
import {
  isArray,
  toString,
} from "lodash-es";

const COLORS_MAPPING = [
  "help",
  "edit",
  "error",
  "success",
];

// @vue/component
export default {
  name: "PuxChartBar",
  components: {
    AngularLink,
    PuxTranslate,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    barInfo: {
      type: Object,
      required: true,
    },
    widthPercent: {
      type: Number,
      required: false,
      default: undefined,
    },
    isLink: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    componentLocal() {
      return this.isLinkLocal ?
        "angular-link" :
        "div";
    },

    isLinkLocal() {
      return this.isLink && !!this.barInfo.value;
    },

    styleWidth() {
      if (this.widthPercent) {
        return `width: ${ this.widthPercent }%`;
      }
      return "";
    },

    title() {
      if (this.barInfo.value === 1) {
        return `${ this.titleFromBarInfo.prefixTitleOne } ${ this.titleFromBarInfo.suffixTitleOne } (${ this.signatureTranslate })`;
      }
      return this.barInfo.title.prefixTitle + " " + (!this.barInfo.value ? "keine" : (this.barInfo.formattedValue ? this.barInfo.formattedValue : this.barInfo.value)) + " " + this.barInfo.title.suffixTitle + " (" + this.signatureTranslate + ")";
    },

    signatureTranslate() {
      return gettext(this.barInfo.signature);
    },

    titleFromBarInfo() {
      return this.barInfo.title;
    },

    attributes() {
      if (this.isLinkLocal) {
        return this.attributesForLink;
      }
      return {};
    },

    attributesForLink() {
      if (this.barInfo.value === 1 && this.barInfo.goToRelevantesObjekt && this.barInfo.relevantesObjektPk) {
        return this.attributesForLinkToDetails;
      }
      return this.attributesForLinkToList;
    },

    attributesForLinkToDetails() {
      let parameter;
      if (isArray(this.barInfo.relevantesObjektPk)) {
        parameter = {
          id: this.barInfo.relevantesObjektPk[0],
          aus_id: this.barInfo.relevantesObjektPk[1],
        };
      } else {
        parameter = {
          id: this.barInfo.relevantesObjektPk,
        };
      }
      return {
        sref: this.barInfo.goToRelevantesObjekt,
        parameter,
      };
    },

    attributesForLinkToList() {
      return {
        sref: this.barInfo.goTo,
        options: this.barInfo.goToParams,
      };
    },

    barText() {
      return this.barInfo.formattedValue ?
        this.barInfo.formattedValue :
        this.barInfo.value;
    },

    classLocal() {
      return [
        this.classColorLocal,
        {
          disabled: !this.barInfo.value,
        },
      ];
    },

    classColorLocal() {
      return `pux_chart__color_${ this.classColorFromMapping }`;
    },

    classColorFromMapping() {
      for (let i = 0; i < COLORS_MAPPING.length; i++) {
        const CURRENT_COLOR = COLORS_MAPPING[i];
        if (toString(this.barInfo.class).indexOf(CURRENT_COLOR) !== -1) {
          return CURRENT_COLOR;
        }
      }
      return "";
    },
  },
};
