"use strict";

import "restangular";
import angular from "angular";

import Foerderantrag from "./foerderantraege.service";
import Foerderantragorganisation from "./foerderantragorganisation.service";
import GeloeschterAntrag from "./GeloeschterAntrag.service";

const modelsModule = angular.module("foerderantrag.models", [
  "restangular",
])

  .provider("Foerderantrag", Foerderantrag)
  .provider("GeloeschterAntrag", GeloeschterAntrag)
  .provider("Forderantragorganisation", Foerderantragorganisation)
;

export default modelsModule;
