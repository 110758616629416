"use strict";

import template from "./top.details.jade";


class TopDetailsController {
  /*@ngInject*/
  constructor(
    $q,
    $filter,
    $stateParams,
    $window,
    DiasNotification,
    Sitzungen,
    SitzungsmodulRegistry,
    Sitzungtagesordnung,
    i18n
  ) {
    this.$stateParams = $stateParams;
    this.$filter = $filter;
    this.$window = $window;
    this.$q = $q;
    this.gettext = i18n.gettext;
    this.DiasNotification = DiasNotification;
    this.SitzungsmodulRegistry = SitzungsmodulRegistry;
    this.Sitzungen = Sitzungen;
    this.Sitzungtagesordnung = Sitzungtagesordnung;

    this.initData();
    this.loadData();

    this.hasMainview = this.hasMainview.bind(this);
  }

  initData() {
    this.topPk = this.$stateParams.tos_id;
    this.selected = null;

    this.docAnzeige = "efe5c6f7-65a1-455c-823f-098e39471e38";
    this.dokumentePermissions = {
      view: "sitzungdokument.view",
      create: "sitzungdokument.create",
      delete: "sitzungdokument.delete",
      update: "sitzungdokument.update",
      update_gueltigkeit: "",
    };

    this.loading = true;
  }

  loadData() {
    const sitzungsPfad = this.Sitzungen.one(this.$stateParams.id);
    const topPfad = sitzungsPfad.tops.one(this.$stateParams.tos_id);
    const modulPfad = topPfad.sitzungsmodule;

    this.$q.all([
      sitzungsPfad.get(),
      topPfad.get(),
      modulPfad.getList()
    ]).then(
      responses => {
        this.sitzung = responses[0];
        this.top = responses[1];
        this.sitzungsmodule = responses[2];

        this.initNotizenData();
        this.sitzungtagesordnung = this.top.clone().plain();
        this.Sitzungtagesordnung.restangularizeElement(this.sitzungtagesordnung);
      },
      () => this.DiasNotification.page.error("Fehler beim Laden der Tagesordnung")
    ).finally(() => this.loading = false);
  }


  initNotizenData() {
    this.notizOptions = { orgChoices: [], fuerSitzung: true };
    this.stepMap = [];
    this.steps = [];

    angular.forEach(this.sitzungsmodule, mod => {
      this.stepMap.push(mod.modulname);
      this.steps.push({
        key: mod.modulname,
        kbez: mod.modul ? mod.modul.stm_kbez : ""
      });
    });
    this.stepMap.push("dokumente");
    this.steps.push({
      key: "dokumente",
      kbez: "Dokumente zum Tagesordnungspunkt"
    });
    this.statusNotizenDataReady = true;
  }

  hasMainview(sitzungsmodul) {
    return angular.isString(this.SitzungsmodulRegistry.getMain(sitzungsmodul.modulname));
  }

  dndStop() {
    this.dndStatus = true;
  }

  savePdfUebersicht() {
    this.Sitzungen.one(this.$stateParams.id).tops.one(this.$stateParams.tos_id).withHttpConfig({ responseType: "blob" }).customGET("uebersicht_pdf").then(
      response => {
        const fileName = (
          `${ this.$filter("date")(new Date(), "yyyyMMdd") }_sitzung_${ this.sitzung.si_sitzungsnr }_top_${ this.top.posStr.replace(".", "-") }_uebersicht.pdf`);
        if (this.$window.navigator.msSaveBlob) {
          this.$window.navigator.msSaveBlob(response, fileName);
        } else {
          const fileURL = (this.$window.URL || this.$window.webkitURL).createObjectURL(response);
          const aLink = this.$window.document.createElement("a");
          aLink.download = fileName;
          aLink.href = fileURL;
          aLink.target = "_self";
          const eventClick = this.$window.document.createEvent("MouseEvents");
          eventClick.initEvent(
            "click",
            true,
            true,
            this.$window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
          );
          aLink.dispatchEvent(eventClick);
        }
      }
    );
  }
}


export default {
  template: template(),
  controller: TopDetailsController,
  controllerAs: "vm"
};
