import HttpMixin from "../../../../../global/mixins/HttpMixin";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import translate from "../../../../../global/directives/translate";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "AntragsverbundModalCreate",
  components: {
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    modelParameter: {
      type: Object,
      required: true,
    },
    urlSave: {
      type: String,
      required: true,
    },
    infoIText: {
      type: String,
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      model: {
        angebot: null,
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "angebot",
            type: "select",
            label: "_LBL_GR_ANTRAGSVERBUND_FOERDERANGEBOT_",
            translate: true,
            url: "katalog/",
            urlParams: {
              key: "angebot",
              pk: this.modelParameter.angebote,
            },
            search: true,
            searchList: ["bez"],
            required: true,
            keyLabel: "bez",
            keyId: "pk",
            view: "v",
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  methods: {
    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.putHttp({ url: this.urlSave, data }).then(
        response => {
          this.addNotification({ text: "_MSG_GR_ANTRAGSVERBUND_ERSTELLT_SUCCESS_" });
          this.close({ response });
        },
        error => {
          this.errors = error.data || {};
          this.errorsClone = cloneDeep(this.errors);
        }
      ).finally(
        () => this.status.loading = false
      );
    },
  },
};
