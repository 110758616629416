"use strict";

import angular from "angular";
import ibanDirective from "./iban.directive";
import pipes from "./pipes";


const formStepsModule = angular.module("iban", [
  pipes.name
])

  .directive("iban", ibanDirective)
;

export default formStepsModule;
