// Modul zur Erfassung von einfachen Kosten
"use strict";

import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";
import BetragsrechnerRegistry from "dias/kosten_finanzierung/betragsrechner/services";

import builder from "dias/standard_foerdermodule/utils/builder";


const standard_kosten = angular.module("dias.kosten_finanzierung.modul.abzug_nicht_foerderfaehige_kosten", [
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
  BetragsrechnerRegistry.name,
]);

standard_kosten.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("abzug_nicht_foerderfaehige_kosten", "modul-abzug-nicht-foerderfaehige-kosten");
})

  .directive("modulAbzugNichtFoerderfaehigeKosten", builder.directive(template, controller));

export default standard_kosten;
