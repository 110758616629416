import PuxPageDetails from "../../../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import SmartTable from "../../../../../global/components/table/table.vue";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";


import getTableApi from "../../../../../const/TableApis";

const TABLE_ID = "BerichtFoerderantragKumasta";

// @vue/component
export default {
  name: "BerichtFoerderantragKumasta",
  components: {
    PuxPageDetails,
    SmartTable,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterLimitTo,
    } = FiltersAPI();

    return {
      filterLimitTo,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_TXT_BERICHT_KUMASTA_FOERDERANTRAG_ACTION_LABEL_",
            route: "root.foerderantrag.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        label: "_TXT_BERICHT_KUMASTA_FOERDERANTRAG_LABEL_",
        sortable: true,
        pagination: true,
        details: false,
        showHeader: false,
        view: {
          filter: "top",
        },
      },
    };
  },
};
