import translate from "../../../../../../global/directives/translate";
import {
  cloneDeep,
} from "lodash-es";
import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    PuxButton,
  },
  directives: {
    translate,
  },
  props: {
    vorlagen: {
      type: Array,
      required: true,
    },
    model: {
      type: Array,
      required: true,
    },
    currentModel: {
      type: Object,
      default: undefined,
    },
    currentIndex: {
      type: Number,
      default: undefined,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modelLocal: {
        label: null,
        vorlage: null,
        minimum: null,
        maximum: null,
      },
    };
  },
  computed: {
    getHeader() {
      return this.currentModel ? "Projektbericht ändern" : "Projektbericht hinzufügen";
    },

    optionsModal() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "label",
            htmlid: "modal_label",
            type: "text",
            view: "v",
            label: "Bezeichnung",
            required: true,
          },
          {
            type: "select",
            id: "vorlage",
            htmlid: "modal_vorlage",
            label: "Vorlage",
            required: true,
            view: "v",
            keyLabel: "bez",
            keyId: "id",
            deselect: false,
            data: this.vorlagen,
          },
          {
            id: "minimum",
            htmlid: "modal_minimum",
            type: "number",
            view: "v",
            label: "Mindestanzahl",
            required: false,
          },
          {
            id: "maximum",
            htmlid: "modal_maximum",
            type: "number",
            view: "v",
            label: "Maximalanzahl",
            required: false,
          },
        ],
      };
    },

    isDisabledSaveButton() {
      return !this.modelLocal.label || !this.modelLocal.vorlage;
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.currentModel) {
        this.modelLocal = cloneDeep(this.currentModel);
      }
    },

    save() {
      if (this.isDisabledSaveButton) {
        return;
      }
      const DATA = cloneDeep(this.modelLocal);
      const MODEL = cloneDeep(this.model) || [];

      if (this.currentModel) {
        MODEL.splice(this.currentIndex, 1, DATA);
      } else {
        MODEL.push(DATA);
      }
      this.close({ model: MODEL });
    },
  },
};
