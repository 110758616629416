import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "Sperrbemerkung",
  components: {
    FormstepDetail,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    nutzer: {
      type: Object,
      required: true,
    },
    panelPermissions: {
      type: Object,
      required: true,
    },
    updateNutzer: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      options: {
        label: "_LBL_SPERRBEMERKUNG_",
        required: true,
        saveCallback: this.save,
        list: [
          {
            id: "n_sperrbemerkung",
            type: "textarea",
            label: "_LBL_SPERRBEMERKUNG_",
            required: true,
            view: "v-alt",
          },
        ],
      },
      confirmOptions: undefined,
      confirmShow: undefined,
    };
  },
  methods: {
    save({ model }) {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: `nutzer/${ this.nutzer.pk }/sperrbemerkung_aendern/`,
          data: model,
        }).then(
          () => {
            this.updateNutzerLocal({ model });
            this.addNotification({ text: "_MSG_SPERRBEMERKUNG_AENDERUNGEN_GESPEICHERT_" });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },

    updateNutzerLocal({ model }) {
      const NUTZER = cloneDeep(this.nutzer);
      NUTZER.n_sperrbemerkung = model.n_sperrbemerkung;

      this.updateNutzer({ nutzer: NUTZER });
    },
  },
};
