"use strict";

class AntragaufgabeEditTerminController {
	/*@ngInject*/
  constructor($scope, $q, DiasNotification, Nutzeraufgabe) {
    this.DiasNotification = DiasNotification;

    this.aufgabe = $scope.extras.aufgabe;

    this.model = {
      termin: this.aufgabe.termin
    };

    this.save = () => {
      const defer = $q.defer();

      Nutzeraufgabe.one(this.aufgabe.pk).customPUT(this.model, "termin_aendern").then(
        response => {
          this.aufgabe.termin = response.termin;
          defer.resolve(response);
        },
        error => {
          defer.reject(error);
          this.onError(error);
        }
      );

      return defer.promise;
    };

    this.onError = error => {
      this.DiasNotification.form.clear();
      this.DiasNotification.form.error(
        error.data,
        "Bitte überprüfen Sie Ihre Eingaben"
      );
      this.errors = error.data;
    };
  }
}

export default AntragaufgabeEditTerminController;
