"use strict";

import template from "./select.jade";

class DiasSelectController {
  /*@ngInject*/
  constructor($scope, $q, $filter, $element, $timeout, i18n) {
    this.$scope = $scope;
    this.$filter = $filter;
    this.$element = $element;
    this.$timeout = $timeout;
    this.$q = $q;
    this.gettext = i18n.gettext;

    this.selected = this.selected || [];
    this.settings = this.settings || {};

    this.ngDropdownSelectSettings = {
      showUncheckAll: false,
      showCheckAll: false,
      closeOnSelect: true,
      scrollableHeight: "300px",
      scrollable: true,
      keyboardControls: true,
      enableSearch: true,
      displayProp: "label",
      idProp: "value",
      template: "<span title='{{:: getPropertyForObject(option, settings.displayProp)}}'>{{::getPropertyForObject(option, settings.displayProp)}}</span>",
      smartButtonMaxItems: 100,
    };

    this.ngDropdownMultiselectSettings = {
      scrollableHeight: "300px",
      scrollable: true,
      keyboardControls: true,
      enableSearch: true,
      displayProp: "label",
      idProp: "value",
      template: "<span title='{{:: getPropertyForObject(option, settings.displayProp)}}'>{{::getPropertyForObject(option, settings.displayProp)}}</span>"
    };

    this.filterTranslationText = {
      checkAll: "alle auswählen",
      uncheckAll: "alle abwählen",
      searchPlaceholder: "Suche",
      dynamicButtonTextSuffix: "ausgewählt",
      buttonDefaultText: "Bitte auswählen"
    };
    this.init();
  }

  init() {
    this.childScope = angular.element(this.$element.children()[0]).scope();
    this.rootId = `dias_select_${ this.id }`;
    this.minSearchLength = this.minSearch || 2;
    this.debounce = this.debounce || 1000;
    this.maxResults = this.maxResults || 100;
    this.searchField = this.searchField || this.labelField;
    if (this.asyncBlockInitial === undefined) {
      this.asyncBlockInitial = true;
    }
    if (this.multiselect) {
      this.internalSettings = this.ngDropdownMultiselectSettings;
      this.onSelect = () => this.multiSelectFilterChanged();
    } else {
      this.internalSettings = this.ngDropdownSelectSettings;
      this.onSelect = () => this.singleSelectFilterChanged();
    }
    if (this.settings) {
      this.internalSettings = angular.merge(this.internalSettings, this.settings);
    }
    if (this.translations) {
      this.internalTranslations = angular.merge(this.filterTranslationText, this.translations);
    }
    this.choices = [];
    if (!this.options) {
      return;
    }
    if (angular.isFunction(this.options.getChoices)) {
      this.isAsync = true;
      this.dataLoading = !this.asyncBlockInitial;
      this.$scope.$applyAsync(() => this.initAsync());
    } else if (angular.isFunction(this.options.then)) {
      this.loading = true;
      this.dataLoading = true;
      this.options.then(
        response => this.updateChoices(response)
      ).finally(() => {
        this.loading = false;
        this.dataLoading = false;
      });
    } else if (angular.isArray(this.options)) {
      this.$scope.$watchCollection(() => this.options, () => {
        this.updateChoices(this.options);
      });
    }
  }

  onButtonToggle($event) {
    const ul = this.$element.find("ul")[0];

    if ($event.path && $event.path.indexOf(ul) !== -1) {
      return;
    }
    if (this.isAsync) {
      const searchFilter = this.childScope.$$childHead.input.searchFilter;
      if (!searchFilter && this.choices.length > 0 && !this.asyncBlockInitial) {
        this.setMsgEl(this.buildInitialLoadNote());
      } else if ((!searchFilter || searchFilter.length < this.minSearchLength)) {
        this.setMsgEl(this.buildMinSearchNote(this.minSearchLength));
      } else if (!this.loading && this.choices.length === 0) {
        this.setMsgEl(this.buildEmptyNote());
      } else if (this.loading) {
        this.setMsgEl(this.buildLoadingSearchMsg(searchFilter));
      } else {
        this.clearMsg();
      }
    } else if (this.choices.length === 0 && !this.loading) {
      this.setMsgEl(this.buildEmptyNote());
    } else {
      this.clearMsg();
    }
  }

  multiSelectFilterChanged() {
    const findElById = i => c => c[this.internalSettings.idProp] === this.selected[i].id;
    for (let i = 0; i < this.selected.length; i++) {
      const choice = this.choices.find(findElById(i));
      Object.assign(this.selected[i], choice);
    }
    if (this.onChange) {
      this.onChange();
    }
  }

  singleSelectFilterChanged() {
    if (this.selected.length > 1) {
      this.selected.splice(0, 1);
    }
    if (!angular.isObject(this.selected[0])) {
      this.selected.splice(0, 1);
    } else {
      const choice = this.choices.find(c => c[this.internalSettings.idProp] === this.selected[0].id);
      Object.assign(this.selected[0], choice);
    }
    if (this.onChange) {
      this.onChange();
    }
  }

  buildMinSearchNote(minSearch) {
    return `<note-msg>
      <li>Bitte geben Sie mindestens ${ minSearch } Zeichen ein, um die Suche zu beginnen.</li>
      <li ng-if="settings.enableSearch" class="divider ng-scope"></li>
    </note-msg>`;
  }
  buildLoadingSearchMsg(searchFilter) {
    return `<note-msg class="loading">
      <li>Suchergebnisse für "<strong>${ searchFilter }</strong>" werden geladen...</li>
      <li ng-if="settings.enableSearch" class="divider ng-scope"></li>
    </not-msg>`;
  }
  buildEqualSearchNote(searchFilter) {
    return `<note-msg>
      <li>Suchergebnisse für "<strong>${ searchFilter }</strong>" werden bereits angezeigt.</li>
      <li ng-if="settings.enableSearch" class="divider ng-scope"></li>
    </note-msg>`;
  }
  buildEmptyNote() {
    if (this.isAsync) {
      return `<note-msg>
        <li>Die Suche ergab kein Ergebnis.</li>
        <li ng-if="settings.enableSearch" class="divider ng-scope"></li>
      </note-msg>`;
    }
    return `<note-msg>
        <li>Keine Daten gefunden.</li>
        <li ng-if="settings.enableSearch" class="divider ng-scope"></li>
      </note-msg>`;
  }
  buildInitialLoadNote() {
    return `<note-msg>
      <li>Die ersten ${ this.maxResults } Elemente wurden geladen.<br>Geben Sie einen Suchbegriff ein, um andere zu finden.</li>
      <li ng-if="settings.enableSearch" class="divider ng-scope"></li>
    </note-msg>`;
  }

  setMsgEl(elStr) {
    const el = angular.element(elStr)[0];
    const oldEl = this.$element.find("note-msg");
    if (oldEl.length === 1) {
      oldEl.replaceWith(el);
    } else {
      const ul = this.$element.find("ul");
      const lis = ul.children();
      let li;
      for (let i = 0; i < lis.length; i++) {
        if (!lis[i].hidden && lis[i].classList.contains("divider")) {
          li = lis[i];
          break;
        }
      }
      if (li) {
        angular.element(li).after(el);
      } else {
        angular.element(ul).append(el);
      }
    }
  }

  clearMsg() {
    this.$element.find("note-msg").remove();
  }

  initAsync() {
    const scope = this.childScope.$$childHead;
    scope.input.searchFilter = "";
    scope.keyDownSearchDefault = () => { };
    scope.keyDownSearch = () => {
      this.updatechoicesAsync();
    };
    scope.getFilter = () => () => true;
    const promises = [];
    if (this.selected && this.selected.length) {
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i].id && this.selected[i][this.labelField] && this.selected[i][this.labelField] !== undefined) {
          this.choices.push({ label: this.selected[i][this.labelField], value: this.selected[i].id });
        } else if (this.selected[i].id && this.selected[i].id !== this.emptyValue) {
          promises.push(this.options.one(this.selected[i].id).customGET("", { fields: ["pk", this.labelField] }));
        }
      }
      if (promises.length === 0) {
        this.updateChoices(this.choices);
      }
    }
    if (!this.asyncBlockInitial) {
      promises.push(this.loadAsyncData(undefined, { limit: this.maxResults }));
    }
    if (promises) {
      this.loading = true;
      this.$q.all(promises).then(
        responses => {
          for (let i = 0; i < responses.length; i++) {
            if (angular.isArray(responses[i])) {
              this.choices.push(...responses[i]);
            } else if (angular.isArray(responses[i].results)) {
              this.choices.push(...responses[i].results);
            } else if (angular.isObject(responses[i])) {
              this.choices.push(
                { label: responses[i][this.labelField], value: responses[i].pk }
              );
            }
          }
          this.updateChoices(this.choices);
        },
        err => console.error(err)
      ).finally(() => {
        this.loading = false;
        this.dataLoading = false;
      });
    }
  }

  updatechoicesAsync() {
    if (this.loading) {
      return;
    }
    if (this.debounceFn) {
      this.$timeout.cancel(this.debounceFn);
    }
    this.debounceFn = this.$timeout(() => {
      const searchFilter = this.childScope.$$childHead.input.searchFilter;
      if (searchFilter.length < this.minSearchLength) {
        this.setMsgEl(this.buildMinSearchNote(this.minSearchLength));
      } else if (this.lastSearch !== searchFilter) {
        this.$element.find("input").attr("disabled", "true");
          // this.choices = [];
        this.setMsgEl(this.buildLoadingSearchMsg(searchFilter));
        this.loadAsyncData(searchFilter).then(
          result => this.updateChoices(result)
        ).finally(() => {
          this.loading = false;
          const inputEl = this.$element.find("input");
          inputEl.removeAttr("disabled");
          inputEl[0].focus();
        });
      } else {
        this.setMsgEl(this.buildEqualSearchNote(searchFilter));
      }
    }, this.debounce);
  }

  loadAsyncData(searchFilter, filterParams) {
    const filter = filterParams || {};
    if (searchFilter) {
      filter[this.searchField] = searchFilter;
    }
    this.loading = true;
    this.lastSearch = searchFilter;
    return this.options.getChoices("pk", this.labelField, filter);
  }

  updateChoices(new_choices) {
    const copy = [];
    copy.push(...new_choices);
    for (let i = 0; i < copy.length; i++) {
      copy[i].label = this.gettext(copy[i].label);
    }
    const emptyResult = copy.length === 0;
    if (this.selected && this.selected.length > 0) {
      // keep selected elements
      const selectedChoices = this.choices.filter(
        choice => this.selected.find(
          sel => choice[this.internalSettings.idProp] === sel.id
        )
      );
      copy.unshift(...selectedChoices);
    }
    this.choices = copy;
    if (this.emptyLabel || this.emptyValue !== undefined) {
      const emptyLabel = this.emptyLabel || "Leer";
      const emptyValue = this.emptyValue !== undefined ? this.emptyValue : "null";
      if (this.choices.length === 0 || this.choices[0].value !== this.emptyValue) {
        this.choices.unshift({ label: emptyLabel, value: emptyValue });
      }
    }
    // make unique
    this.choices = this.choices.filter(
      (item, idx, arr) => {
        return arr.findIndex(el => el.value === item.value) === idx;
      }
    );
    if (emptyResult) {
      this.setMsgEl(this.buildEmptyNote());
    } else {
      this.clearMsg();
    }
  }
}

export default {
  template: template(),
  controller: DiasSelectController,
  controllerAs: "vm",
  bindings: {
    id: "@",
    labelField: "@",
    searchField: "@?",
    minSearch: "<?",
    debounce: "<?debounce",
    disabled: "<?disabled",
    /** ACHTUNG: selected muss ein Array sein.
     * Auch im Single-Select Modus wird mit einem Array gearbeitet.
     * Jedes Array-Element enthält das ausgewählte Element (z.B. {id: 'foo'}) */
    selected: "=?",
    options: "<?",
    multiselect: "<?",
    settings: "<?",
    translations: "<?",
    focus: "<?",
    selectTitle: "<?",
    emptyLabel: "@?",
    emptyValue: "<?",
    asyncBlockInitial: "<?",
    maxResults: "<?",
    onChange: "&?"
  }
};
