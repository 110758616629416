import KontextKonfig from "./KontextKonfig/KontextKonfig.vue";
import OptionsList from "./OptionsList/OptionsList.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

import UiAntragScoreFieldsMixin from "./UiAntragScoreFieldsMixin";
import UIComponentMixin from "../../../../../global/components/ui/UIComponentMixin";

import translate from "../../../../../global/directives/translate";

import {
  cloneDeep,
  size
} from "lodash-es";

// @vue/component
export default {
  name: "UiAntragScore",
  components: {
    KontextKonfig,
    OptionsList,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    UiAntragScoreFieldsMixin,
    UIComponentMixin,
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
    errors: undefined,
  },
  beforeCreate() {
    this.$options.components.FormElement = require("../../../../../global/components/FormElement/FormElement.vue").default;
  },
  methods: {
    extraFieldCss(field_name) {
      return {
        "no-error": this.errors && !this.errors[field_name]
      };
    },

    showErrors(field_name) {
      return this.errors && size(this.errors[field_name]) > 0;
    },

    updateTextOptions({ model }) {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.text_options = cloneDeep(model);
    },

    updateNumOptions({ model }) {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.num_options = cloneDeep(model);
    },

    updateNumKontext({ model }) {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.num_kontext = cloneDeep(model);
    },

    numVariantSelected() {
      if (this.model.num_variant === 1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.model.num_options = this.model.num_options || [];
      } else if (this.model.num_variant === 2) {
        // eslint-disable-next-line vue/no-mutating-props
        this.model.num_kontext = this.model.num_kontext || [];
      }
    },

    textVariantSelected() {
      if (this.model.text_variant === 1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.model.text_options = this.model.text_options || [];
      }
    },
  },
};
