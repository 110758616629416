import StyleguideVueinit from "./StyleguideVueinit/StyleguideVueinit.vue";
import StyleguideHttp from "./StyleguideHttp/StyleguideHttp.vue";
import StyleguideAlert from "./StyleguideAlert/StyleguideAlert.vue";
import StyleguidePermission from "./StyleguidePermission/StyleguidePermission.vue";
import StyleguideModalConfirm from "./StyleguideModalConfirm/StyleguideModalConfirm.vue";
import StyleguideTable from "./StyleguideTable/StyleguideTable.vue";
import StyleguideUiSelect from "./StyleguideUiSelect/StyleguideUiSelect.vue";
import StyleguideFilters from "./StyleguideFilters/StyleguideFilters.vue";
import StyleguideExample from "./StyleguideExample/StyleguideExample.vue";
import StyleguideLoading from "./StyleguideLoading/StyleguideLoading.vue";
import PageTabTitle from "../../global/components/PageTabTitle/PageTabTitle.vue";

// @vue/component
export default {
  name: "Styleguide",
  components: {
    StyleguideVueinit,
    StyleguideHttp,
    StyleguideAlert,
    StyleguidePermission,
    StyleguideModalConfirm,
    StyleguideTable,
    StyleguideUiSelect,
    StyleguideFilters,
    StyleguideExample,
    StyleguideLoading,
    PageTabTitle,
  },
  data() {
    return {
      currentItem: 0,
      menuTabs: [
        {
          label: "vueinit",
          description: "Diese Direktive wird Vue.js in Angular.js initialisiert",
          key: "styleguide-vueinit",
        },
        {
          label: "http",
          description: "HTTP-FUNKTIONEN",
          key: "styleguide-http",
        },
        {
          label: "Alert",
          description: "Alert-Komponente",
          key: "styleguide-alert",
        },
        {
          label: "Confirm Dialog",
          description: "Modal-Komponente",
          key: "styleguide-modal-confirm",
        },
        {
          label: "Permission",
          description: "Permission-Komponente",
          key: "styleguide-permission",
        },
        // {
        //   label: "smart-table",
        //   description: "Diese Komponente ist für die Smart-Tabelle",
        //   key: "styleguide-table",
        // },
        // {
        //   label: "ui-select",
        //   description: "Diese Komponente ist für Selectbox",
        //   key: "styleguide-ui-select",
        // },
        // {
        //   label: "loading",
        //   description: "Loading",
        //   key: "styleguide-loading",
        // },
        // {
        //   label: "Filter",
        //   description: "Alle Filter",
        //   key: "styleguide-filters",
        // },
        // {
        //   label: "Beispiel",
        //   description: "",
        //   key: "styleguide-example",
        // },
      ],
    };
  },
  methods: {
    checkItem(index) {
      this.currentItem = index;
    },
  },
};
