import FormElement from "../../../../../../../../libs/vue/client/vue/global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../../../../../libs/vue/client/vue/global/components/FormstepDetail/FormstepDetail.vue";
import Modal from "../../../../../../../../libs/vue/client/vue/global/components/Modal/Modal.vue";

import HttpMixin from "../../../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";
import NotificationMixin from "../../../../../../../../libs/vue/client/vue/global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "HochschuleDetailsStandort",
  components: {
    FormElement,
    FormstepDetail,
    Modal,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    standorteUrl: {
      type: String,
      required: true,
    },
    hochschuleStandort: {
      type: Object,
      required: true,
    },
    hochschuleStandortIndex: {
      type: Number,
      required: true,
    },
    parentHtmlId: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    "loadStandorte",
  ],
  data() {
    return {
      model: {
        ort_bez: undefined,
        ort_kbez: undefined,
        pk: undefined,
      },
      errors: {},
      modalLoading: false,
      confirmDeleteShow: undefined,
      confirmDeleteOptions: undefined,
    };
  },
  computed: {
    htmlId() {
      return `${ this.parentHtmlId }_standort_${ this.hochschuleStandortIndex }`;
    },

    standortLabel() {
      return `${ this.hochschuleStandortIndex + 1 }. Standort`;
    },

    selectorClose() {
      return [
        `#${ this.htmlId }_delete`,
        `#${ this.parentHtmlId }`
      ];
    },

    optionsList() {
      return [
        {
          id: "ort_bez",
          htmlId: `standort_ort_bez_${ this.hochschuleStandortIndex }`,
          type: "text",
          view: "v-alt",
          label: "Ort",
          required: true,
        }
      ];
    },

    formstepDetailOptions() {
      return {
        required: true,
        deletable: this.model.changeable && this.editable,
        openCallback: this.open,
        saveCallback: this.save,
        deleteCallback: () => this.openModalConfirmDelete(),
      };
    },
  },
  created() {
    this.setModel();
  },
  methods: {
    open() {
      this.errors = {};
    },

    setModel() {
      this.model = cloneDeep(this.hochschuleStandort);
    },

    closeModalConfirmDelete() {
      this.confirmDeleteShow = false;
    },

    openModalConfirmDelete() {
      this.confirmDeleteOptions = {
        okClass: "btn btn-primary",
        title: "Standort löschen?",
        msg: `Wollen Sie den Standort ${ this.model.ort_bez } wirklich löschen?`,
        okLabel: "_BTN_DELETE_",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: () => this.delete(),
        cancelCallback: this.closeModalConfirmDelete,
      };
      this.confirmDeleteShow = true;
    },

    save() {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: `${ this.standorteUrl }${ this.model.pk }/standort_aendern/`,
          data: {
            ort_bez: this.model.ort_bez,
            ort_kbez: this.model.ort_bez,
          },
        }).then(
          () => {
            this.addNotification({ text: "Änderungen erfolgreich gespeichert" });
            this.$emit("loadStandorte");
            resolve();
          },
          errors => {
            delete errors.data.ort_kbez;
            this.onErrors(errors);
            reject(errors);
          }
        );
      });
    },

    delete() {
      this.modalLoading = true;
      return new Promise((resolve, reject) => {
        this.deleteHttp({
          url: `${ this.standorteUrl }${ this.model.pk }/`,
          data: this.model,
        }).then(
          () => {
            this.$emit("loadStandorte");
            this.closeModalConfirmDelete();
            this.addNotification({ text: `Standort ${ this.model.ort_bez } erfolgreich gelöscht` });
            resolve();
          },
          errors => {
            this.onErrors(errors);
            reject(errors);
          }
        ).finally(
          () => {
            this.modalLoading = false;
          }
        );
      });
    },

    onErrors(errors) {
      this.errors = errors.data;
    },
  },
};
