"use strict";

import BetragsrechnerBase from "../betragsrechner.base";

class KategorieMitPauschaleRechner extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "kategoriemitpauschalerechner";
    this.component_name = "kategoriemitpauschale";
    this.labels = { erlaeuterung: "Erläuterung",
                    anzahl: "Anzahl" };
  }

  get_labelmapping() {
    return this.labels;
  }

  get_fields() {
    return { erlaeuterung: "",
             anzahl: "",
    };
  }
}
export default KategorieMitPauschaleRechner;
