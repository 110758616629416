import PuxTooltip from "../../../global/components/PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import Loading from "../../../global/directives/loading";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import getTableApi from "../../../const/TableApis";
import { EventBus } from "../../../global/functions/event-bus";
import {
  assign,
  find,
  forEach,
  sortBy,
} from "lodash-es";

const TABLE_ID = "modulauszahlungslisteobjektlisteelementlist";

// @vue/component
export default {
  name: "ModulAuszahlungslisteObjektlisteElementList",
  components: {
    PuxTooltip,
    PuxTranslate,
    SmartTable,
  },
  directives: {
    Loading,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
  ],
  props: {
    modul: {
      type: Object,
      default: () => ({}),
    },
    objekt: {
      type: Object,
      default: () => ({}),
    }
  },
  setup() {
    const {
      filterLimitTo,
    } = FiltersAPI();

    return {
      filterLimitTo,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: "",
        cols: [
          {
            id: "ole_pos",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_POSITION_",
            sort_id: "ole_pos",
            sortable: true,
            sorting_default: "asc",
            priority: 5,
          },
          {
            id: "vertrag_nr",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_VERTRAGSNUMMER_",
            sort_id: "auszahlungsplan__vertrag__v_nr",
            sortable: true,
            link_to: { url: "/vertrag/{{vertrag}}/auszahlung/{{pk}}/" },
            priority: 10,
          },
          {
            id: "az_nummer",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_NR_AUSZAHLUNG_",
            sort_id: "az_nummer",
            sortable: true,
            priority: 20,
          },
          {
            id: "regelsatz_kbez",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_FOERDERANGEBOT_",
            sort_id: "auszahlungsplan__vertrag__antrag__regelsatz__ars_kbez",
            sortable: true,
            hide: true,
            priority: 30,
          },
          {
            id: "az_datum_eingereicht",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_DATUM_",
            sort_id: "az_datum_eingereicht",
            sortable: true,
            filter: "date",
            priority: 40,
          },
          {
            id: "betrag_auszuzahlen",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_AUSZAHLENDER_BETRAG_",
            filter: "currency",
            sort_id: "_betrag_auszuzahlen",
            sortable: true,
            priority: 50,
          },
          {
            id: "betrag_beantragt",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_BEANTRAGTER_BETRAG_",
            filter: "currency",
            hide: true,
            priority: 55,
          },
          {
            id: "az_betrag",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_BETRAG_",
            filter: "currency",
            hide: true,
            priority: 60,
          },
          {
            id: "az_datum_ausgezahlt",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_AUSZAHLUNG_AM_",
            filter: "date",
            hide: true,
            priority: 70,
          },
          {
            id: "statusgruppe",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ARBEITSSCHRITT_",
            hide: true,
            priority: 80,
          },
          {
            id: "status",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_STATUS_",
            hide: true,
            priority: 90,
          },
          {
            id: "antragstellerorg_obj.ast_nr",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ANTRAGSTELLER_NR_",
            sort_id: "auszahlungsplan__vertrag__antragstellerorg__ast_nr",
            sortable: true,
            hide: true,
            priority: 100,
          },
          {
            id: "auszahlungsplan__vertrag__antragstellerorg__ast_name",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ANTRAGSTELLER_",
            sort_id: "auszahlungsplan__vertrag__antragstellerorg__ast_name",
            sortable: true,
            slot: "antragsteller",
            priority: 110,
          },
          {
            id: "status_detail",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ETAILLIERT_STATUS_",
            hide: true,
            permission: ["workflow.list.column.status_detail.view"],
            priority: 120,
          },
          {
            id: "zustaendig",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ZUSTAENDIG_",
            hide: true,
            permission: ["auszahlung.list.column.zustaendig.view"],
            priority: 130,
          },
          {
            id: "az_aoviban",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_IBAN_",
            filter: "iban",
            filter_parameter: "True",
            hide: true,
            priority: 140,
          },
          {
            id: "entscheidung",
            slot: "status",
            label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ENTSCHEIDUNG_",
            sort_id: "ole_estkbez",
            sortable: true,
            priority: 150,
          },
        ],
        rowActions: [],
        actions: [],
        label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_AUSZAHLUNGSLISTEN_",
        sortable: true,
        pagination: true,
        details: false,
        urlUpdate: false,
        showHeader: false,
        view: {
          filter: "top",
        },
      },
      filters: [
        {
          id: "vertrag",
          type: "text",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_VERTRAGSNUMMER_",
          always_visible: true,
          group: "Vertrag",
        },
        {
          id: "az_nummer",
          type: "text",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_NR_AUSZAHLUNG_",
          group: "Auszahlung",
        },
        {
          id: "regelsatz",
          type: "multiselect",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_FOERDERANGEBOT_",
          group: "Förderangebot",
          url: "foerderantraege/regelsaetze/",
          key_label: "label",
          key_id: "value",
          search: true,
          search_list: ["value"],
        },
        {
          id: "friststatus",
          type: "select",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_FRIST_",
          group: "Bearbeitung",
          url: "katalog/?fields=pk&fields=bez&key=friststatus",
          key_label: "bez",
          key_id: "pk",
          search: true,
          search_list: ["bez"],
        },
        {
          id: "status",
          type: "multiselect",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_STATUS_",
          group: "Bearbeitung",
          url: "katalog/?fields=pk&fields=au_kbezbeobachter&key=auszahlungsaufgabe",
          key_label: "au_kbezbeobachter",
          key_id: "pk",
          search: true,
          search_list: ["bez"],
          combine_copies: true,
        },
        {
          id: "status_detail",
          type: "multiselect",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ETAILLIERT_STATUS_",
          group: "Bearbeitung",
          permission: ["workflow.list.column.status_detail.view"],
          url: "katalog/?fields=pk&fields=au_kbezdetail&key=auszahlungsaufgabe",
          key_label: "au_kbezdetail",
          key_id: "pk",
          search: true,
          search_list: ["au_kbezdetail"],
          combine_copies: true,
        },
        {
          id: "zustaendig",
          type: "select",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ZUSTAENDIG_",
          group: "Bearbeitung",
          permission: ["auszahlung.list.column.zustaendig.view"],
          url: "nutzer/?fields=pk&fields=name",
          key_label: "name",
          key_id: "pk",
          search_global: true,
          search_parameter: "name",
          empty_label: "Ohne Zuständigkeit",
          empty_value: "null",
        },
        {
          id: "workflowstatusgruppe",
          type: "multiselect",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ARBEITSSCHRITT_",
          group: "Bearbeitung",
          url: "workflowstatusgruppen/?fields=pk&fields=wsg_kbez&workflowtyp=b2c69c92-ec79-4bad-a272-1be33aeb9ee6",
          key_label: "wsg_kbez",
          key_id: "pk",
          search: true,
          search_list: ["wsg_kbez"],
        },
        {
          id: "workflowtyp",
          type: "select",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_AUSZAHLUNGSTYP_",
          group: "Bearbeitung",
          url: "katalog/?fields=pk&fields=bez&key=workflowtyp&pk=b2c69c92-ec79-4bad-a272-1be33aeb9ee6&pk=2948f684-d9c4-4e28-b91b-b10e8b19c523",
          key_label: "bez",
          key_id: "pk",
          search: true,
          search_list: ["bez"],
        },
        {
          id: "meine_teams",
          type: "boolean",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_TEAMS_AUSZAHLUNGEN_",
          group: "Bearbeitung",
          permission: ["team.view"],
          default_label: "Nicht ausgewählt",
        },
        {
          id: "zu_bearbeiten",
          type: "select",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ZU_BEARBEITEN_",
          group: "Bearbeitung",
          url: "katalog/?fields=pk&fields=bez&key=filter_antragteamrelevanz",
          key_label: "bez",
          key_id: "pk",
          search: true,
          search_list: ["bez"],
        },
        {
          id: "az_betrag",
          type: "numberrange",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_BETRAG_",
          placeholder_min: "ohne Minimum",
          placeholder_max: "ohne Maximum",
          group: "Auszahlung",
        },
        {
          id: "az_datum_eingereicht",
          type: "daterange",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_EINGEREICHT_",
          placeholder_min: "Beginn beliebig",
          placeholder_max: "Ende beliebig",
          group: "Auszahlung",
        },
        {
          id: "az_datum_ausgezahlt",
          type: "daterange",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_AUSGEZAHLT_",
          placeholder_min: "Beginn beliebig",
          placeholder_max: "Ende beliebig",
          group: "Auszahlung",
        },
        {
          id: "antragstellerorg",
          type: "select",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ANTRAGSTELLERORGANISATION_",
          group: "Antragsteller",
          url: "antragstellerorganisationen/?fields=pk&fields=bez",
          key_label: "bez",
          key_id: "pk",
          search_global: true,
          search_parameter: "bez",
        },
        {
          id: "show_gesperrte_ast",
          type: "boolean",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_GESPERRTE_ANTRAGSTELLER_EINBENDEN_",
          always_visible: true,
          group: "Antragsteller",
          permission: ["antragstellerorgsperrung.view"],
          default_label: "Nicht ausgewählt",
        },
        {
          id: "meine_ast_auszahlungen",
          type: "boolean",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ANTRAGSTELLER_AUSZAHLUNG_",
          group: "Antragsteller",
          permission: ["foerderantrag.create"],
          default_label: "Nicht ausgewählt",
          permission_context_type: "FOERDERORG",
        },
        {
          id: "ast_bundesland",
          type: "select",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_BUNDESLAND_",
          group: "Antragsteller",
          url: "katalog/?fields=bun_id&fields=bun_bez&key=kbundesland",
          key_label: "bun_bez",
          key_id: "bun_id",
          search: true,
          search_list: ["bun_bez"],
        },
        {
          id: "ast_ort",
          type: "text",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ORT_",
          group: "Antragsteller",
        },
        {
          id: "suche",
          type: "text",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_SUCHE_IN_",
          main: true,
        },
        {
          id: "entscheidung",
          type: "multiselect",
          label: "_LBL_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ENTSCHEIDUNG_",
          group: "Auszahlungsliste",
          url: "katalog/?fields=pk&fields=est_kbez&key=entscheidungsstatus",
          key_label: "est_kbez",
          key_id: "pk",
          search: true,
          search_list: ["est_kbez"],
        },
      ],
      status: {
        loading: true,
        entscheidung: false
      },
      entscheidungsstatus: undefined,
    };
  },
  created() {
    this.initUrl();
    this.initFilter();
    this.loadEntscheidungen();
  },
  methods: {
    initUrl() {
      this.options.url = getTableApi({ id: TABLE_ID, params: { olId: this.modul.data.ol_id } });
    },

    initFilter() {
      const est_filter = find(this.filters, filter => filter.id === "entscheidung");
      if (!est_filter) {
        return;
      }
      est_filter.url = `${ est_filter.url }&gruppe=${ this.modul.regel.argument.entscheidungsstatusgruppe.wert }`;
    },

    loadEntscheidungen() {
      this.getHttp({ url: `katalog/?gruppe=${ this.modul.regel.argument.entscheidungsstatusgruppe.wert }&key=entscheidungsstatus` }).then(
        response => {
          this.entscheidungsstatus = {};
          forEach(sortBy(response, ["pos"]), status => {
            this.entscheidungsstatus[status.pk] = status;
            this.options.rowActions.push({
              label: status.est_aktion_bez,
              callback: ({ item, index }) => this.setEntscheidungsstatus({ item, index, status: status }),
              isHidden: () => !this.checkPermissionsSync({ perm: "vertrag.cortex.auszahlungslisten.update", permArray: this.objekt.user_permissions }),
            });
          });
        }
      ).finally(() => this.status.loading = false);
    },

    setEntscheidungsstatus({ item, index, status }) {
      if (this.status.entscheidung) {
        return;
      }
      this.status.entscheidung = true;
      this.postHttp({
        url: `${ this.options.url }${ item.pk }/set_entscheidung/`,
        data: { entscheidung: status.pk },
      }).then(
        response => {
          this.addNotification({
            text: "_MSG_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ENTSCHEIDUNG_SUCCESS_{{status}}_{{name}}_",
            extra: { status: status.bez, name: item.ole_objektname }
          });
          const ROW = assign({}, item, response);
          EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: ROW, index }));
        },
        () => this.addNotification({ text: "_MSG_AUSZAHLUNGSLISTE_MODUL_OBJEKTLISTE_ENTSCHEIDUNG_ERROR_", type: "error" })
      ).then(
        () => this.status.entscheidung = false
      );
    },
  },
};
