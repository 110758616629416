"use strict";

import angular from "angular";

import FoerderangebotAnzeigekontextUUIDs from "./foerderangebotAnzeigekontextUUIDs.service";
import FoerderangebotstatusUUIDs from "./foerderangebotstatusUUIDs.service";
import AnzeigekontextstatusUUIDs from "./anzeigekontextstatusUUIDs.service";

const modelsModule = angular.module("foerderangebote.services", [
])

  .constant("FoerderangebotAnzeigekontextUUIDs", FoerderangebotAnzeigekontextUUIDs)
  .constant("FoerderangebotstatusUUIDs", FoerderangebotstatusUUIDs)
  .constant("AnzeigekontextstatusUUIDs", AnzeigekontextstatusUUIDs)
;

export default modelsModule;
