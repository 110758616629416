"use strict";

import parameterServices from "../services";
import template from "./config.jade";
import controller from "./controller";


const parametermoduleIBAN = angular.module("dias.foerderregeln.parameter.iban", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("iban", "parametermodul-iban");
  })
  .component("parametermodulIban", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulIbanRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: "<span ng-bind-html='vm.parameter[vm.key].wert | iban'></span>",
    controllerAs: "vm",
  });
export default parametermoduleIBAN;
