import FormElement from "../../../global/components/FormElement/FormElement.vue";
import NutzerschlagwortBadge from "../NutzerschlagwortBadge/NutzerschlagwortBadge.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import { gettext } from "../../../global/functions/utils";
import {
  cloneDeep,
  filter,
  find,
  findIndex,
  isNil,
  map,
  pick,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "NutzerschlagwortAuswahl",
  components: {
    FormElement,
    NutzerschlagwortBadge,
    PuxButton,
    PuxCloak,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    model: {
      type: Array,
      default: () => [],
    },
    change: {
      type: Function,
      required: false,
      default: undefined,
    },
    allowAdd: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: {
        nutzerschlagworte: true,
        katalog: true
      },
      addModel: {
        nsw_kbez: undefined,
        nsw_cssklassen: undefined
      },
      selectionModel: undefined,
      nutzerschlagworte: undefined,
      cssklassen: undefined,
      cssklassenDefault: undefined,
    };
  },
  computed: {
    anyLoading() {
      return this.loading.nutzerschlagworte || this.loading.katalog;
    },

    inputDisabled() {
      return this.anyLoading || this.disabled;
    },

    inputEmpty() {
      return size(this.addModel.nsw_kbez) === 0;
    },

    inputAlreadyExisting() {
      return !isNil(find(this.nutzerschlagworte, nsw => nsw.nsw_kbez === this.addModel.nsw_kbez));
    },

    canAdd() {
      return !this.inputDisabled && !this.inputEmpty && !this.inputAlreadyExisting;
    },

    addBtnTitle() {
      let txt = "_TXT_NUTZERSCHLAGWORT_AUSWAHL_ADD_";
      if (this.anyLoading) {
        txt = "_TXT_NUTZERSCHLAGWORT_AUSWAHL_ADD_DISABLED_TITLE_IS_LOADING_";
      } else if (this.inputAlreadyExisting) {
        txt = "_TXT_NUTZERSCHLAGWORT_AUSWAHL_ADD_DISABLED_TITLE_ALREADY_EXISTING_";
      } else if (this.inputEmpty) {
        txt = "_TXT_NUTZERSCHLAGWORT_AUSWAHL_ADD_DISABLED_TITLE_INPUT_EMPTY_";
      }
      return gettext(txt);
    },

    hasNutzerschlagworte() {
      return size(this.nutzerschlagworte) > 0;
    },

    addFormOptions() {
      return [
        {
          label: "_LBL_NUTZERSCHLAGWORT_AUSWAHL_ADD_LABEL_",
          showLabel: true,
          id: "nsw_kbez",
          type: "text",
          maxlength: 60,
          required: false,
          view: "v",
        }, {
          label: "_LBL_NUTZERSCHLAGWORT_AUSWAHL_ADD_CSSKLASSEN_",
          showLabel: true,
          id: "nsw_cssklassen",
          type: "single_choice",
          keyId: "pk",
          keyLabel: "bez",
          data: this.cssklassen,
          required: true,
          view: "v",
        },
      ];
    },

    selectionFormOptions() {
      return [{
        id: "nsw_selection",
        type: "checkbox",
        keyId: "nsw_kbez",
        slot: "uiCheckboxLabel",
        data: this.nutzerschlagworte,
      }];
    }
  },
  created() {
    this.loadData();
    this.loadAddData();
    this.selectionModel = map(this.model, nswo => nswo.nsw_kbez);
  },
  methods: {
    loadData() {
      this.getListHttp({ url: "nutzerschlagworte/" }).then(
        response => {
          this.nutzerschlagworte = response || [];
        }
      ).finally(() => this.loading.nutzerschlagworte = false);
    },

    loadAddData() {
      if (!this.allowAdd) {
        this.loading.katalog = false;
        return;
      }
      this.getHttp({ url: "katalog/?key=nutzerschlagworte_cssklassen" }).then(
        response => {
          this.cssklassen = response;
          const idxDefault = findIndex(this.cssklassen, cls => cls.default);
          this.cssklassenDefault = this.cssklassen[idxDefault !== -1 ? idxDefault : 0].pk;
          this.resetAddModel();
        }
      ).finally(() => this.loading.katalog = false);
    },

    resetAddModel() {
      this.addModel = {
        nsw_kbez: undefined,
        nsw_cssklassen: this.cssklassenDefault
      };
    },

    addSchlagwort() {
      const NSW = cloneDeep(this.addModel);
      this.nutzerschlagworte = [
        NSW,
        ...this.nutzerschlagworte
      ];
      this.resetAddModel();
      this.onChange({ model: this.selectionModel, el: NSW.nsw_kbez });
    },

    onChange({ model, el, $event }) {
      this.updateSelection({ model, el });
      if (!isNil(this.change)) {
        const DATA = map(
          filter(
            cloneDeep(this.nutzerschlagworte),
            item => this.selectionModel.indexOf(item.nsw_kbez) !== -1
          ),
          nsw => pick(nsw, ["nsw_kbez", "nsw_cssklassen"])
        );
        this.change({
          model: DATA,
          $event: $event
        });
      }
    },

    updateSelection({ el, model }) {
      const NEW_MODEL = cloneDeep(model || this.selectionModel);
      if (!isNil(el)) {
        NEW_MODEL.push(el);
      }
      this.selectionModel = NEW_MODEL;
    }
  },
};
