var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<fieldset><div ng-attr-id=\"{{ vm.errorContainerId }}\"></div><div class=\"row\"><div class=\"col-12 col-sm-6\">");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"von\" ng-model=\"vm.model.foerdervertragsbestaetigung.von\" type=\"text\" bs-datepicker=\"bs-datepicker\" name=\"von\" ng-change=\"vm.updateDifference();\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.von ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "von", "Beginn");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"bis\" ng-model=\"vm.model.foerdervertragsbestaetigung.bis\" type=\"text\" bs-datepicker=\"bs-datepicker\" name=\"bis\" ng-change=\"vm.updateDifference();\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.bis ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "bis", "Ende");
buf.push("<label>Genehmigte Durchführungsdauer<p class=\"form-control-static\"><span ng-if=\"vm.model.foerdervertragsbestaetigung.vertragMonths\">{{ vm.model.foerdervertragsbestaetigung.vertragMonths }} {{ vm.model.foerdervertragsbestaetigung.vertragMonths === 1 ? \"Monat\" : \"Monate\" }} &nbsp;</span><span ng-if=\"vm.model.foerdervertragsbestaetigung.vertragDays\">{{ vm.model.foerdervertragsbestaetigung.vertragDays }} {{ vm.model.foerdervertragsbestaetigung.vertragDays === 1 ? \"Tag\" : \"Tage\" }}</span></p></label></div><div class=\"col-sm-6 col-12\"><label>Durchführungszeitraum im Antrag<p class=\"form-control-static\"><span>{{ ::vm.model.antrag_durchfuehrungszeitraum }}</span></p></label><label>Genehmigte Durchführungsdauer<p class=\"form-control-static\"><span ng-if=\"vm.model.antragMonths\">{{ vm.model.antragMonths }} {{ vm.model.antragMonths === 1 ? \"Monat\" : \"Monate\" }} &nbsp;</span><span ng-if=\"vm.model.antragDays\">{{ vm.model.antragDays }} {{ vm.model.antragDays === 1 ? \"Tag\" : \"Tage\" }}</span></p></label></div></div></fieldset><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Abbrechen</span></button></div></div>");;return buf.join("");
}