"use strict";

import template from "./dias.report.jade";
import controller from "./dias.report.controller";

const diasReportComponent = /*@ngInject*/ function() {
  return {
    restrict: "EA",
    scope: {
      report: "@",
      height: "@"
    },
    template,
    controller,
    controllerAs: "vm",
    bindToController: true
  };
};

export default diasReportComponent;
