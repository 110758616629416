import {
  get,
} from "lodash-es";

// @vue/component
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    parameter() {
      return get(this.data, "medium.kme_data", {});
    },
  },
};
