import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import VertragDocumentsItem from "./VertragDocumentsItem/VertragDocumentsItem.vue";

import VertragSnapshotMixin from "../VertragSnapshotMixin";

import {
  cloneDeep,
  forEach,
  get,
  keyBy,
  values,
} from "lodash-es";

const SNAPSHOT_CHANGES_LIST = [
  "url",
  "titel",
  "groesse",
  "datei",
];

// @vue/component
export default {
  name: "VertragDocuments",
  components: {
    FormstepDetail,
    PuxIcon,
    PuxTranslate,
    VertragDocumentsItem,
  },
  mixins: [
    VertragSnapshotMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    vertrag: {
      type: Object,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
    isFoerdervertragsbestaetigungPdfVisible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    optionsFormstepDetail() {
      return {
        label: this.header,
      };
    },

    hrefFoerdervertragsbestaetigungPdf() {
      return `/api/vertraege/${ this.vertrag.pk }/fvb_pdf/`;
    },

    isOneOfDocumentsVisible() {
      return this.isFoerdervertragsbestaetigungPdfVisible ||
        this.isVertragDocumentsVisible ||
        (this.isSnapshot && this.dokumenteSnapshot.length);
    },

    isVertragDocumentsVisible() {
      return this.dokumente.length;
    },

    dokumente() {
      return this.vertrag.dokumenten || [];
    },

    dokumenteSnapshot() {
      return get(this.snapshotObject, "vertrag.dokumenten") || [];
    },

    dokumenteSnapshotKeyByPk() {
      return keyBy(this.dokumenteSnapshot, "pk");
    },

    diff() {
      const DIFF = {
        add: {},
        delete: [],
        changes: {},
        status: false,
      };
      if (this.isSnapshot) {
        const DOKUMENTE_SNAPSHOT = cloneDeep(this.dokumenteSnapshotKeyByPk);
        forEach(this.dokumente, item => {
          const CURRENT_PK = item.pk;
          const CURRENT_SNAPSHOT = cloneDeep(DOKUMENTE_SNAPSHOT[CURRENT_PK]);
          if (!CURRENT_SNAPSHOT) {
            DIFF.add[CURRENT_PK] = true;
            DIFF.status = true;
          } else {
            forEach(SNAPSHOT_CHANGES_LIST, key => {
              if (item[key] !== CURRENT_SNAPSHOT[key]) {
                DIFF.changes[CURRENT_PK] = true;
                DIFF.status = true;
                return false;
              }
            });
            delete DOKUMENTE_SNAPSHOT[CURRENT_PK];
          }
        });
        DIFF.delete = values(DOKUMENTE_SNAPSHOT);
        if (DIFF.delete.length) {
          DIFF.status = true;
        }
      }
      return DIFF;
    },

    idSnapshotDelete() {
      return `${ this.id }_snapshot_delete`;
    },

    isDiff() {
      return this.diff.status;
    },
  },
};
