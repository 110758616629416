import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import {
  EventBus,
} from "../../../global/functions/event-bus";

// @vue/component
export default {
  name: "DokumenteSimpleList",
  components: {
    PuxCloak,
    PuxIcon,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    docDokumente: {
      type: Array,
      default: () => [],
    },
    url: {
      type: String,
      default: undefined,
    },
    docAnzeige: {
      type: String,
      default: undefined,
    },
    docUpdateEvent: {
      type: String,
      default: "dokumente.update",
    },
    docLimit: {
      type: [String, Number],
      default: undefined,
      // default: 3,
    },
    docEmptyMsg: {
      type: String,
      default: "Noch kein Dokument vorhanden",
    },
    docRender: {
      type: Boolean,
    },
  },
  setup() {
    const {
      filterDate,
      filterFileSize,
    } = FiltersAPI();

    return {
      filterDate,
      filterFileSize,
    };
  },
  data() {
    return {
      documentsLocal: [],
      loading: false,
    };
  },
  computed: {
    getTitle() {
      return ({ dok }) => {
        if (dok.dokument) {
          return "";
        }
        return `_TXT_DOKUMENTE_SIMPLE_LIST_HERUNTERLADEN_{{ dok_dokumentname }}_`;
      };
    },

    getTitleExtra() {
      return ({ dok }) => {
        if (dok.dokument) {
          return {
            dok_dokumentname: dok.dokument.dok_dokumentname,
          };
        }
      };
    },

    documentsLocalOrFromProps() {
      if (this.documentsLocal.length) {
        return this.documentsLocal;
      }
      return this.docDokumente;
    },

    canView() {
      return this.hasPermissionDokumentView || this.docDokumente.length;
    },

    hasPermissionDokumentView() {
      return this.checkPermissionsSync({
        perm: "dokument.view",
      });
    },

    urlLocal() {
      return `${ this.url }dokumente/`;
    },

    urlParams() {
      const URL_PARAMS = {
        anzeige: this.docAnzeige,
      };
      if (this.docLimit) {
        URL_PARAMS.last = this.docLimit;
      }
      return URL_PARAMS;
    },
  },
  created() {
    this.loadData();
    this.initEventBus();
  },
  beforeUnmount() {
    this.destroyEventBus();
  },
  methods: {
    loadData() {
      if (!this.hasPermissionDokumentView || !this.url || !this.docAnzeige) {
        return;
      }
      this.loading = true;
      this.getHttp({
        url: this.urlLocal,
        urlParams: this.urlParams,
      }).then(
        response => {
          this.documentsLocal = response || [];
          this.loading = false;
        }
      );
    },

    initEventBus() {
      EventBus.$on(this.docUpdateEvent, this.loadData);
    },

    destroyEventBus() {
      EventBus.$off(this.docUpdateEvent, this.loadData);
    },
  },
};
