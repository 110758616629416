import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import FormElementReadOnly from "../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxGet from "../../../../../global/components/PuxGet/PuxGet.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SitzungsartTagesordnungModulCreateEditButtonModal from "../SitzungsartTagesordnungModulCreateEditButtonModal/SitzungsartTagesordnungModulCreateEditButtonModal.vue";

import SitzungsartTagesordnungModalAPI from "./compositionAPI/SitzungsartTagesordnungModalAPI";
import SitzungsartTagesordnungModuleAPI from "./compositionAPI/SitzungsartTagesordnungModuleAPI";
import SitzungsartTagesordnungModulFunctionsAPI from "./compositionAPI/SitzungsartTagesordnungModulFunctionsAPI";
import SitzungsartTagesordnungNotGroupedAPI from "./compositionAPI/SitzungsartTagesordnungNotGroupedAPI";

import {
  uniqueId,
} from "lodash-es";

// @vue/component
export default {
  name: "SitzungsartTagesordnungEditButtonModal",
  components: {
    FormElement,
    FormElementReadOnly,
    Modal,
    PuxButton,
    PuxCloak,
    PuxGet,
    PuxTranslate,
    SitzungsartTagesordnungModulCreateEditButtonModal,
  },
  props: {
    tagesordnungspunkt: {
      type: Object,
      required: true,
    },
    tagesordnungspunktIndex: {
      type: Number,
      required: true,
    },
    tagesordnungspunktParentIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
    tagesordnungspunkteUrl: {
      type: String,
      required: true,
    },
    buttonId: {
      type: String,
      required: false,
      default: () => uniqueId("sitzungsart_tagesordnung_btn_modal_"),
    },
    number: {
      type: String,
      required: true,
    },
    sitzungsmoduleKeyByPk: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      loadingModule,
      loadTopModules,
      module,
      tagesordnungspunktUrl,
      tagesordnungspunktModuleUrl,
    } = SitzungsartTagesordnungModuleAPI(props);

    const {
      buttonAddId,
      buttonDeletePrefixId,
      buttonDownPrefixId,
      buttonUpPrefixId,
      confirmOptions,
      confirmShow,
      createModul,
      isDetailsVisible,
      moveDownModul,
      moveUpModul,
      openConfirmRemoveModul,
      selectorCloseDelete,
      toggleDetails,
      updateModul,
    } = SitzungsartTagesordnungModulFunctionsAPI({
      module,
    });

    const {
      closeModal,
      errors,
      errorsLabelsOptions,
      isModalVisible,
      loading,
      openModal,
      optionsModal,
      optionsPos,
      optionsToBez,
      optionsStoInfo,
      save,
      selectorClose,
    } = SitzungsartTagesordnungModalAPI(props, {
      loadTopModules,
      module,
      tagesordnungspunktUrl,
    });

    const {
      extraForTranslateHeader,
      isObjectEmpty,
    } = SitzungsartTagesordnungNotGroupedAPI(props);

    return {
      loadingModule,
      loadTopModules,
      module,
      tagesordnungspunktModuleUrl,

      buttonAddId,
      buttonDeletePrefixId,
      buttonDownPrefixId,
      buttonUpPrefixId,
      confirmOptions,
      confirmShow,
      createModul,
      isDetailsVisible,
      moveDownModul,
      moveUpModul,
      openConfirmRemoveModul,
      selectorCloseDelete,
      toggleDetails,
      updateModul,

      closeModal,
      errors,
      errorsLabelsOptions,
      isModalVisible,
      loading,
      openModal,
      optionsModal,
      optionsPos,
      optionsToBez,
      optionsStoInfo,
      save,
      selectorClose,

      extraForTranslateHeader,
      isObjectEmpty,
    };
  },
};
