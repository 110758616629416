"use strict";

class DynamischeZahlenConfigController {
  /*@ngInject*/
  constructor() {}

  preventFurtherAction($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  isRequired(antwort) {
    return antwort.pflichtfeld || _.isNil(antwort.pflichtfeld);
  }
}

export default DynamischeZahlenConfigController;
