"use strict";

import template from "./dokument.download.jade";

class DokumenteController {
  /*@ngInject*/
  constructor(Upload, ListenConstantDokumente, SyConfigs) {
    this.allowedMimeType = SyConfigs.wert("allowed_mime_type").join(",");
    this.ListenConstantDokumente = ListenConstantDokumente;
    this.upload = Upload;
  }

  uploadDokument(file, invalidFile) {
    this.statusDokumentDownload = true;
    this.deleteErrors();
    this.downloadFiles = file;
    if (file) {
      file.upload = this.upload.upload({
        url: this.docpk.dokumente.getUploadUrl(this.dokart),
        data: { file: file }
      });
      file.upload.then(
        response => {
          // file is uploaded successfully
          if (response.status === 200) {
            file.progress = 100;
          }
          this.deleteErrors();
          this.loadBelegDokumente().finally(() => {
            this.fileDownloadPk = response.data;
            this.statusDokumentDownload = false;
          });
        },
        error => {
          // handle error
          this.errorServer = error;
          this.statusDokumentDownload = false;
        },
        evt => {
          // progress notify
          file.progress = Math.min(50, parseInt(100.0 * evt.loaded / evt.total));
        }
      );
    } else if (invalidFile) {
      this.errorFile = invalidFile;
      this.statusDokumentDownload = false;
    }
  }

  deleteErrors() {
    this.errorFile = null;
    this.errorServer = null;
  }
}

export default {
  template: template(),
  controller: DokumenteController,
  controllerAs: "vm",
  bindings: {
    docpk: "<",
    statusMultiple: "<",
    statusDokumentDownload: "=",
    dokart: "<",
    fileDownloadPk: "=",
    loadBelegDokumente: "&"
  }
};
