"use strict";

import template from "./uiSelect.jade";

class DiasSelectController {
  /*@ngInject*/
  constructor($timeout, $scope, $document) {
    this.$scope = $scope;
    this.$document = $document;
    this.$timeout = $timeout;


    this.initData();

    $scope.$watchCollection("vm.selectList", () => {
      this.selectListCopy = angular.copy(this.selectList);
      if (this.selectListCopy && this.selectListCopy.length) {
        this.initPlaceholder();
      }


      if (this.groupParameter) {
        this.changeListByGroupParameter();
      }
      if (this.limit) {
        this.changeLimitList();
      }
    });

    // If vm.selectModel === null inside controller
    $scope.$watch("vm.selectModel", () => {
      if (_.isNil(this.selectModel) || this.selectModel === "") {
        this.selected = null;
        this.selectAdditionalModelObj = null;
      } else {
        this.initPlaceholder();
      }
    });

    $scope.$watchCollection("vm.selectModelMultiselect", () => {
      if (!this.statusNotWatchMultiselectModel && _.isArray(this.selectModelMultiselect)) {
        this.modelArr = {};
        this.selectAdditionalModelObj = {};
        _.forEach(this.selectModelMultiselect, value => {
          this.modelArr[value] = true;
          this.selectAdditionalModelObj[value] =
            _.find(this.selectList, [this.selectLabelId, value]) || {
              [this.selectLabelId]: value
            };
        });
      }
    });

    $scope.$watch("vm.modelSearch", () => {
      this.$timeout.cancel(this.timerSearch);
      if (this.modelSearch.length >= this.minChars) {
        this.timerSearch = this.$timeout(() => {
          if (this.insideSearch) {
            this.insideSearchFunction();
          } else if (!_.isNil(this.searchLabel)) {
            this.filterList();
          }
        }, this.timeoutSearch);
      }
    });

    this.docClickHandler = $event => {
      let target = $event.target;
      while (target.parentElement) {
        if (target.id === this.componentId) {
          return;
        }
        target = target.parentElement;
      }
      if ($scope && this.statusOpen) {
        $scope.$apply(() => {
          $scope.vm.closeDropdown();
        });
      }
    };
    this.$document.on("click", this.docClickHandler);
    this.$onDestroy = () => {
      this.$document.off("click", this.docClickHandler);
    };
  }


  initData() {
    this.minChars = this.minChars || 0;
    this.statusCloseOnBlur = this.statusCloseOnBlur || true;
    this.modelSearch = this.modelSearch || "";
    this.limitList = [];
    // this.limit = this.limit || 20;
    this.limitOffset = 20;
    this.selectPlaceholder = this.selectPlaceholder || "Bitte auswählen";
    this.componentId = this.componentId || `${ this.selectId || Math.random() }_container`;
    this.selectLabelId = this.selectLabelId || "pk";
    this.searchLabel = this.searchLabel || "bez";
    this.selectSearchLabel = this.selectSearchLabel || "Suche";
    this.selectAdditionalModelObj = this.selectAdditionalModelObj || {};
    this.timeoutSearch = this.timeoutSearch || 400;
    this.onOpen = this.onOpen || angular.noop;
    this.onSelect = this.onSelect || angular.noop;
    this.insideSearchFunction = this.insideSearchFunction || angular.noop;
    if (this.multiselect) {
      this.selected = this.selected || [];
      this.statusWhiteSpace = !!this.selectMatchTemplate;
      this.selectMatchTemplate = this.selectMatchTemplate || "multiselectDefaultMatchTemplate.html";
    } else {
      this.statusCloseOnChoose = this.statusCloseOnChoose || true;
    }
  }

  initPlaceholder() {
    if (!this.selectModel || this.multiselect || !this.selectListCopy) {
      return;
    }
    for (let i = 0; i < this.selectListCopy.length; i++) {
      if (this.selectListCopy[i][this.selectLabelId] === this.selectModel) {
        this.selected = this.selectListCopy[i];
        break;
      }
    }
  }

  changeInputMultiselect() {
    this.statusNotWatchMultiselectModel = true;
    this.getModelMultiselect();
    this.$timeout(() => {
      this.onSelect();
      this.statusNotWatchMultiselectModel = false;
    }, 1);

    if (this.statusCloseOnChoose) {
      this.closeDropdown();
    }
  }

  getModelMultiselect() {
    this.selectModelMultiselect = [];
    this.selectAdditionalModelObj = {};
    _.forEach(this.modelArr, (value, key) => {
      if (value) {
        this.selectModelMultiselect.push(key);
        this.selectAdditionalModelObj[key] =
          _.find(this.selectList, [this.selectLabelId, key]) || {
            [this.selectLabelId]: key
          };
      }
    });
  }

  changeInput(item) {
    this.selected = item;
    this.selectAdditionalModelObj = item;
    this.$timeout(() => this.onSelect(), 1);

    if (this.statusCloseOnChoose) {
      this.closeDropdown();
    }
  }

  clearSelection() {
    this.selectModel = null;
    this.selectAdditionalModelObj = null;
  }

  changeListByGroupParameter() {
    const tempParameter = {};
    this.selectListCopy = _.forEach(_.sortBy(this.selectListCopy, [this.groupParameter]), item => {
      item.groupParameterShow = !tempParameter[item[this.groupParameter]];
      tempParameter[item[this.groupParameter]] = true;
    });
  }

  changeLimitList() {
    this.loading = true;
    this.selectListCopy = angular.isArray(this.selectListCopy) ? this.selectListCopy : [];
    this.limitList = this.selectListCopy.slice(0, this.limit);
    this.loading = false;
    this.statusLoadMoreDisabled = false;
  }

  loadMore() {
    if (!this.selectListCopy || !this.selectListCopy.length) {
      return;
    }
    this.statusLoadMoreDisabled = true;
    this.limitList.push(...this.selectListCopy.slice(this.limitList.length, this.limitList.length + 20));
    this.statusLoadMoreDisabled = false;

    if (this.limitList.length === this.selectListCopy.length) {
      this.statusLoadMoreDisabled = true;
    }
  }

  getIdScrollContainer(prefix) {
    let containerId = "";
    if (prefix) {
      containerId += prefix;
    }
    containerId += this.componentId;
    containerId += "-dropdown-menu";
    return containerId;
  }

  showLoadingMsg() {
    if (this.modelSearch.length > 0) {
      return `Suchergebnisse für "${ this.modelSearch }" werden geladen...`;
    }
    return "Auswahl wird geladen...";
  }

  toggleDropdown() {
    this.statusOpen = !this.statusOpen;
    if (this.statusOpen) {
      this.searchAutoFocus = true;
      this.onOpen();
      this.filterList();
    } else {
      this.searchAutoFocus = false;
    }
  }

  removeEventForDocument() {
    if (this.statusCloseOnBlur) {
      this.$document.off("click");
    }
    this.statusStopPropagation = false;
  }

  closeDropdown() {
    // this.removeEventForDocument();
    this.statusOpen = false;
  }

  isLabelHidden() {
    return this.multiselect ? !!(this.selectModelMultiselect && this.selectModelMultiselect.length) : !!this.selected;
  }

  filterList() {
    this.selectListCopy = angular.copy(this.selectList);
    if (this.modelSearch && this.modelSearch.length) {
      const list = [];
      const search = this.modelSearch.toLowerCase();
      if (angular.isArray(this.searchLabel)) {
        angular.forEach(this.selectListCopy, item => {
          for (let i = 0; i < this.searchLabel.length; i++) {
            const label = item[this.searchLabel[i]] || "";
            if (label.toLowerCase().indexOf(search) !== -1) {
              list.push(item);
              break;
            }
          }
        });
      } else {
        angular.forEach(this.selectListCopy, item => {
          const label = item[this.searchLabel] || "";
          if (label.toLowerCase().indexOf(search) !== -1) {
            list.push(item);
          }
        });
      }
      this.selectListCopy = list;
    }
    if (this.groupParameter) {
      this.changeListByGroupParameter();
    }
  }

  deleteItem(item) {
    this.statusNotWatchMultiselectModel = true;
    if (item === undefined) {
      item = { [this.selectLabelId]: undefined };
    }
    for (let i = 0; i < this.selectModelMultiselect.length; i++) {
      if (this.selectModelMultiselect[i] === item[this.selectLabelId]) {
        this.selectModelMultiselect.splice(i, 1);
        break;
      }
    }
    if (this.modelArr[item[this.selectLabelId]]) {
      delete this.modelArr[item[this.selectLabelId]];
    }
    if (this.selectAdditionalModelObj[item[this.selectLabelId]]) {
      delete this.selectAdditionalModelObj[item[this.selectLabelId]];
    }

    this.$timeout(() => {
      this.onSelect();
      this.statusNotWatchMultiselectModel = false;
    }, 1);
  }
}

export default {
  template: template(),
  controller: DiasSelectController,
  controllerAs: "vm",
  bindings: {
    autoFocus: "<?",
    componentId: "<?",
    extra: "<?",
    groupParameter: "<?",
    insideSearch: "<?",
    insideSearchFunction: "&?",
    limit: "<?",
    loadingList: "<?",
    modelSearch: "=?",
    multiselect: "<?",
    onOpen: "&?",
    onSelect: "&?",
    selectAdditionalModelObj: "=?",
    selectAllowDeselect: "<?",
    selectChoicesTemplate: "<?",
    selectDisabled: "<?",
    selectId: "<?",
    searchLabel: "<?",
    selectLabelId: "<?",
    selectList: "<",
    selectMatchTemplate: "<?",
    selectModel: "=?",
    selectModelMultiselect: "=?",
    selectPlaceholder: "<?",
    selectRequired: "<?",
    selectSearch: "<?",
    selectSearchLabel: "@?",
    statusCloseOnBlur: "<?",
    statusCloseOnChoose: "<?",
    timeoutSearch: "<?",
    minChars: "<?",
  }
};
