"use strict";

import DefaultWizardController from "dias/core/wizard/defaultWizard.controller.js";


class WizardController extends DefaultWizardController {
  /*@ngInject*/
  constructor() {
    super();
  }

  goToWizardStep({ error, data }) {
    if (error && error.anzeigekontext && error.anzeigekontext.key) {
      const INDEX = _.findIndex(data, ["key", error.anzeigekontext.key]);
      if (INDEX !== -1) {
        this.activeStep = INDEX;
      }
    }
  }
}

export default WizardController;
