"use strict";

import FoerderantragBase from "dias/foerderantrag/models/foerderantraege.base.service";

class SitzungsFoerderantrag extends FoerderantragBase {
  constructor() {
    super("sitzungsantraege");
  }
}

export default SitzungsFoerderantrag;
