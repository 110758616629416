import ModuleAutomatischeAntragslisteMittellosPreview from "./Module/ModuleAutomatischeAntragslisteMittellosPreview/ModuleAutomatischeAntragslisteMittellosPreview.vue";
import ModuleAutomatischeAntragslistePreview from "./Module/ModuleAutomatischeAntragslistePreview/ModuleAutomatischeAntragslistePreview.vue";
import ModuleBeratungsvorlagePreview from "./Module/ModuleBeratungsvorlagePreview/ModuleBeratungsvorlagePreview.vue";
import ModuleManuelleAntragslistePreview from "./Module/ModuleManuelleAntragslistePreview/ModuleManuelleAntragslistePreview.vue";
import ModuleMuendlicherBerichtPreview from "./Module/ModuleMuendlicherBerichtPreview/ModuleMuendlicherBerichtPreview.vue";
import ModuleNiederschriftPreview from "./Module/ModuleNiederschriftPreview/ModuleNiederschriftPreview.vue";

import SitzungModuleMapping from "./SitzungModuleMapping";

// @vue/component
export default {
  name: "SitzungModulePreview",
  components: {
    ModuleAutomatischeAntragslisteMittellosPreview,
    ModuleAutomatischeAntragslistePreview,
    ModuleBeratungsvorlagePreview,
    ModuleManuelleAntragslistePreview,
    ModuleMuendlicherBerichtPreview,
    ModuleNiederschriftPreview,
  },
  props: {
    module: {
      type: Object,
      required: true,
    },
  },
  computed: {
    modulname() {
      return this.module.modulname;
    },

    currentComponent() {
      const COMPONENT = SitzungModuleMapping.preview[this.modulname];
      if (COMPONENT) {
        return COMPONENT;
      }
      return undefined;
    },
  },
};
