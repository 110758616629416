import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";

import {
  FilterDateMixin,
} from "../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "TrackingDetailsMilestone",
  components: {
    PuxIcon,
  },
  mixins: [
    FilterDateMixin,
  ],
  props: {
    isFirst: {
      type: Boolean,
      required: true,
      info: "Ist der Meilenstein erster",
    },
    isLast: {
      type: Boolean,
      required: true,
      info: "Ist der Meilenstein letzter",
    },
    isNextReach: {
      type: Boolean,
      required: false,
      info: "Ist der nächste Meilenstein erreicht",
    },
    isPreviousReach: {
      type: Boolean,
      required: false,
      info: "Ist der vorige Meilenstein erreicht",
    },
    milestone: {
      type: Object,
      required: true,
      info: "Information über Meilenstein",
    },
  },
  computed: {
    formattedDate() {
      return this.filterDate(this.milestone.datum, "dddd, DD.MM.YYYY");
    },

    isLineBottomReach() {
      return this.isNextReach || (this.isLast && this.milestone.erreicht);
    },

    isLastReachedMilestone() {
      return this.milestone.erreicht && (this.isFirst || !this.isPreviousReach);
    },
  },
};
