// @vue/component
export default {
  name: "StyleguideTableOptionsRowActions",
  data() {
    return {
      rowActions: [
        {
          label: "label",
          type: "String",
          default: "undefined",
          description: "Anzeigetext der 'RowAction'.",
          required: true,
          example: `label: "label"`,
          options: "",
        },
        {
          label: "labelCallback",
          type: "Funktion",
          default: "undefined",
          description: "Callback des Anzeigetextes.",
          required: false,
          example: `labelCallback: this.showVertragLabel,`,
          options: "",
        },
        {
          label: "href",
          type: "Funktion",
          default: "undefined",
          description: "Die Aktion ist ein Link.",
          required: false,
          example: "href: ({ item }) => `/vertrag/${ item.vertrag.pk }/`,",
          options: "",
        },
        {
          label: "callback",
          type: "Funktion",
          default: "undefined",
          description: "Die Aktion ist ein Button.",
          required: false,
          example: "callback: this.toggleFavorit,",
          options: "",
        },
        {
          label: "isHidden",
          type: "Boolean",
          default: "undefined",
          description: "Parameter der angibt ob die Action angezeigt wird oder nicht.",
          required: false,
          example: "isHidden: false,",
          options: "",
        },
        {
          label: "className",
          type: "String",
          default: "undefined",
          description: "Klasse für Link oder Button.",
          required: false,
          example: `className: "btn btn-primary",`,
          options: "",
        },
        {
          label: "titelCallback",
          type: "Funktion",
          default: "undefined",
          description: "",
          required: false,
          example: "",
          options: "",
        },
      ]
    };
  },
};
