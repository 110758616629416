"use strict";

import BetragsrechnerRegistry from "../services";

import KategorieMitPauschaleRechner from "./betragsrechner.js";
import KategorieMitPauschalerechnerController from "./controller";

const BetrgsrechnerKategorieMitPauschaleModul = angular.module("dias.betragsrechner.BetragsrechnerKategorieMitPauschaleModul", [
  BetragsrechnerRegistry.name,
]);

BetrgsrechnerKategorieMitPauschaleModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("kategoriemitpauschalerechner", KategorieMitPauschaleRechner);
})
  .component("kategoriemitpauschale", KategorieMitPauschalerechnerController);

export default BetrgsrechnerKategorieMitPauschaleModul;
