var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;







jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<fieldset dias-cloak=\"vm.loadingEditData\"><div ng-attr-id=\"{{ vm.errorContainerId }}\"></div><div class=\"row\"><div class=\"col-12 col-sm-6\">");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"sbv_kbez\" ng-model=\"vm.model.sbv_rubriklabel\" type=\"text\" name=\"sbv_rubriklabel\" maxlength=\"100\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.sbv_rubriklabel ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "sbv_rubriklabel", "Rubriklabel");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"sbv_kbez\" ng-model=\"vm.model.sbv_kbez\" type=\"text\" name=\"sbv_kbez\" maxlength=\"100\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.sbv_kbez ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "sbv_kbez", "Name");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<textarea id=\"sbv_bez\" ng-model=\"vm.model.sbv_bez\" dias-richtext name=\"sbv_bez\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.sbv_bez ? 'true' : 'false'}}\" class=\"form-control\"></textarea>");
}
}, "sbv_bez", "Beschreibung");
buf.push("</div></div></fieldset><div class=\"form-footer\"><div class=\"required\"><dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save)\" ng-disabled=\"loading\" type=\"button\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Abbrechen</span></button></div></div>");;return buf.join("");
}