"use strict";

import template from "./template.jade";
import FinanzierungDetailliertController from "./controller";

export default {
  template: template(),
  controller: FinanzierungDetailliertController,
  controllerAs: "ctx",
  bindings: {
    rechner: "=",
    posmodel: "=",
    errors: "=",
    extras: "=",
    kontext: "=?",
    vorschau: "=",
    prefix: "<?"
  },
};
