// @vue/component
export default {
  data() {
    return {
      wizardSteps: [
        {
          label: "_TXT_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_VORBEREITUNG_HEADER_",
          slotId: "step1",
          title: "_TXT_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_VORBEREITUNG_HEADER_TITLE_",
        },
        {
          label: "_TXT_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_ANTRAGSTELLER_HEADER_",
          slotId: "step2",
          title: "_TXT_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_ANTRAGSTELLER_HEADER_TITLE_",
        },
        {
          label: "_TXT_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_AUSFUEHREN_HEADER_",
          slotId: "step3",
          title: "_TXT_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_AUSFUEHREN_HEADER_TITLE_",
        },
        {
          label: "_TXT_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_ANLAGEN_HEADER_",
          slotId: "step4",
          title: "_TXT_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_ANLAGEN_HEADER_TITLE_",
        },
        {
          label: "_TXT_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_ABSCHLUSS_HEADER_",
          slotId: "step5",
          title: "_TXT_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_STEP_ABSCHLUSS_HEADER_TITLE_",
        },
      ]
    };
  },
}
;
