import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import PanelComponents from "./PanelComponents";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

// @vue/component
export default {
  name: "TeamsDetails",
  components: {
    ContextPanel,
    PanelComponents,
    PuxPageDetails,
    PuxTranslate,
    ...PanelComponents,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  data() {
    return {
      loading: true,
      team: undefined,
      mitgliederlist: undefined,
      teamPk: undefined,
      organisationPk: undefined,
      panels: [
        {
          label: "_TXT_TEAMS_DETAILS_TEAM_PANEL_NAME_",
          icon: "glyphicon-organisation",
          active: true,
          pk: "team",
          components: [
            {
              name: "Team",
              pk: "Team",
            },
          ],
        },
        {
          label: "_TXT_TEAMS_DETAILS_MITGLIEDER_PANEL_NAME_",
          icon: "glyphicon-file",
          active: false,
          pk: "mitglieder",
          components: [
            {
              name: "MitgliederList",
              pk: "MitgliederList",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.loadTeam();
    this.getOrg();
  },
  methods: {
    loadTeam() {
      this.getHttp({ url: `teams/${ this.$stateParams.id }/` }).then(
        response => {
          this.team = response;
          this.teamPk = this.team.pk;
        }
      ).then(
        () => this.loading = false
      );
    },

    getOrg() {
      this.loading = true;
      this.organisationPk = this.me.kontexte && this.me.kontexte[0] && this.me.kontexte[0].org_id;
    },

    updateTeam({ team }) {
      this.team = team;
    },

    updateTeamPk({ teamPk }) {
      this.teamPk = teamPk;
    },

    updateOrganisationPk({ organisationPk }) {
      this.organisationPk = organisationPk;
    },
  },
};
