import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SachberichtvorlageDetailsBasisdaten
  from "./SachberichtvorlageDetailsBasisdaten/SachberichtvorlageDetailsBasisdaten.vue";
import SachberichtvorlageDetailsKonfiguration
  from "./SachberichtvorlageDetailsKonfiguration/SachberichtvorlageDetailsKonfiguration.vue";
import Signalverarbeitung from "../../Signalverarbeitung/Signalverarbeitung.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../global/compositionAPI/NotificationAPI";

// @vue/component
export default {
  name: "SachberichtvorlageDetails",
  components: {
    ContextPanel,
    Modal,
    PuxButton,
    PuxPageDetails,
    PuxTranslate,
    SachberichtvorlageDetailsBasisdaten,
    SachberichtvorlageDetailsKonfiguration,
    Signalverarbeitung,
  },
  setup() {
    const {
      deleteHttp,
      getHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      deleteHttp,
      getHttp,
    };
  },
  data() {
    return {
      loading: false,
      confirmDeleteShow: false,
      confirmDeleteOptions: undefined,
      sachberichtvorlage: {},
    };
  },
  computed: {
    sachberichtvorlageUrl() {
      return `sachberichtvorlagen/${ this.$stateParams.id }/`;
    },

    pageTitleExtra() {
      return { sbv_kbez: this.sachberichtvorlage.sbv_kbez };
    },
  },
  created() {
    this.loadSachberichtvorlage();
  },
  methods: {
    loadSachberichtvorlage() {
      this.loading = true;
      this.getHttp({
        url: this.sachberichtvorlageUrl,
      }).then(
        response => {
          this.sachberichtvorlage = response;
        },
        () => {
          this.addNotification({
            text: "_MSG_SACHBERICHTVORLAGE_DETAILS_LOADING_ERROR_",
            type: "error",
          });
        },
      ).finally(
        () => this.loading = false
      );
    },

    updateSachberichtvorlage(data) {
      this.sachberichtvorlage = Object.assign({}, this.sachberichtvorlage, data);
    },

    reloadSignale() {
      this.$refs.signalverarbeitung.reloadSignale();
    },

    closeModalConfirmDelete() {
      this.confirmDeleteShow = false;
    },

    openModalConfirmDelete() {
      this.confirmDeleteOptions = {
        title: "_TXT_SACHBERICHTVORLAGE_DETAILS_SACHBERICHTVORLAGE_DELETE_",
        msg: "_MSG_SACHBERICHTVORLAGE_DETAILS_SACHBERICHTVORLAGE_CONFIRM_DELETE_{{name}}_",
        extra: {
          name: this.sachberichtvorlage.sbv_kbez,
        },
        loading: false,
      };
      this.confirmDeleteShow = true;
    },

    deleteSachberichtvorlage() {
      this.confirmDeleteOptions.loading = true;
      this.deleteHttp({
        url: this.sachberichtvorlageUrl,
        data: this.sachberichtvorlage,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_SACHBERICHTVORLAGE_DETAILS_SACHBERICHTVORLAGE_DELETE_SUCCESS_{{name}}_",
            extra: { name: this.sachberichtvorlage.sbv_kbez },
          });
          this.closeModalConfirmDelete();
          this.$goTo("root.sachberichtvorlagen");
        },
        () => {
          this.addNotification({
            text: "_MSG_SACHBERICHTVORLAGE_DETAILS_SACHBERICHTVORLAGE_DELETE_ERROR_",
            type: "error",
          });
        },
      ).finally(
        () => {
          this.confirmDeleteOptions.loading = false;
        }
      );
    },
  },
};
