import MediaPlayer from "../../../global/components/MediaPlayer/MediaPlayer.vue";
import Alert from "../../../global/components/Alert/Alert.vue";
import translate from "../../../global/directives/translate";
import VideoMapping from "./VideoMapping.json";
import {
  onUtag,
} from "../../../global/functions/utag";

const UTAG_MAPPING = {
  "25%": "video_25%",
  "50%": "video_50%",
  "75%": "video_75%",
  "100%": "video_full",
  play: "video_play",
  weiter: "video_weiter",
  pause: "video_pause",
  spulen_vorwaerts: "video_spulen_vorwaerts",
  spulen_rueckwaerts: "video_spulen_rueckwaerts",
  fertig: "video_fertig",
};

// @vue/component
export default {
  name: "VideoDetails",
  components: {
    Alert,
    MediaPlayer,
  },
  directives: {
    translate,
  },
  data() {
    return {
      statusShowBefragung: undefined,
      idVideo: undefined,
      VideoMapping: VideoMapping,
      videoDescription: [
        // {
        //   src: "https://video.aktion-mensch.de/magnolia/aktion-mensch-videoplayer/aktion-mensch_beispiel/aktion-mensch_beispiel_signlanguageVideo.mp4",
        //   type: "video/mp4",
        // },
        // {
        //   src: "https://video.aktion-mensch.de/magnolia/aktion-mensch-videoplayer/aktion-mensch_beispiel/aktion-mensch_beispiel_signlanguageVideo.webm",
        //   type: "video/webm",
        // },
        // {
        //   src: "https://video.aktion-mensch.de/magnolia/aktion-mensch-videoplayer/aktion-mensch_beispiel/aktion-mensch_beispiel_signlanguageVideo.ogv",
        //   type: "video/ogv",
        // },
      ],
      audioDescription: [
        // {
        //   src: "https://video.aktion-mensch.de/magnolia/aktion-mensch_beispiel_audioDescription.mp3",
        //   type: "audio/mp3",
        // },
      ],

      // src: "https://akme-my.sharepoint.com/personal/erlenr_aktion-mensch_de/Documents/Videos%20geteilt/Michael/Zuordnung%20zu%20einer%20Organisation%20V2.mp4?App=OneDriveWebVideo",
    };
  },
  computed: {
    sources() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.idVideo = this.$stateParams.id;
      return this.VideoMapping[this.idVideo] || [];
    },

    videoName() {
      return `video_namen_${ this.idVideo }`;
    },
  },
  created() {
    this.setEventCallbackForUsabilla();
  },
  beforeUnmount() {
    this.destroyEventDocumentMouseleave();
  },
  methods: {
    setEventCallbackForUsabilla() {
      if (window && window.usabilla_live) {
        window.usabilla_live("setEventCallback", (category, action) => {
          if (action === "Campaign:Open" && this.$refs.media_player && this.$refs.media_player.pause) {
            this.$refs.media_player.pause();
          }
        });
      }
    },

    onVideoUsage({ key }) {
      if (key === "25%") {
        this.initEventDocumentMouseleave();
        this.statusShowBefragung = true;
      }
      onUtag({
        event_id: this.videoName,
        event_id2: UTAG_MAPPING[key],
        etracker_event_action: "video",
      });
    },

    initEventDocumentMouseleave() {
      $("html").mouseleave(this.onDocumentMouseleave);
    },

    onDocumentMouseleave() {
      this.destroyEventDocumentMouseleave();
      onUtag({
        page_exit: "true",
        page_exit_typ: "exit-mouse-curser",
        video_name: this.videoName,
        video_usage: "video_25%",
      });
    },

    destroyEventDocumentMouseleave() {
      $("html").off("mouseleave");
    },
  },
};
