import ContextPanel from "../../../../global/components/ContextPanel/ContextPanel.vue";
import IjfTemplateTreeTr from "./IjfTemplateTreeTr/IjfTemplateTreeTr.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";

import {
  get,
  last,
} from "lodash-es";

// @vue/component
export default {
  name: "IjfTemplateTreeBranch",
  components: {
    ContextPanel,
    IjfTemplateTreeTr,
    PuxTranslate,
    PuxButton,
    PuxCloak,
  },
  props: {
    obj: {
      type: Object,
      required: false,
      default: undefined,
    },
    data: {
      type: Array,
      required: true,
    },
    spalten: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    openModalCreateOrEdit: {
      type: Function,
      required: false,
      default: () => {},
    },
    openModalDeleteItem: {
      type: Function,
      required: false,
      default: () => {},
    },
    showMoveUpButton: {
      type: Function,
      required: false,
      default: () => false,
    },
    showMoveDownButton: {
      type: Function,
      required: false,
      default: () => false,
    },
    moveUp: {
      type: Function,
      required: false,
      default: () => {},
    },
    moveDown: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      expanded: true,
    };
  },
  computed: {
    getPadding() {
      return this.getPaddingByLevel({ level: (this.level + 1) });
    },

    getColWidth() {
      return this.getColWidthByLevel({ level: (this.level + 1) });
    },

    modalAddButtonTranslate() {
      if (this.obj) {
        return {
          title: "_BTN_IJF_UNTERGEORDNETES_FELD_HINZUFUEGEN_{{parent}}_",
          extra: {
            parent: this.obj.ivf_name,
          }
        };
      }
      
      return {
        title: "_BTN_IJF_FELD_HINZUFUEGEN_",
        extra: {}
      };
    },

    idButtonAdd() {
      return this.obj ? `#${ this.obj.pk }_add` : "#nullparent_add";
    },

    addButtonStyle() {
      const widthForOneLevel = 24;

      return {
        "padding-left": `${ widthForOneLevel * (this.level + 1) + 8 }px`,
      };
    },
  },
  methods: {
    onExpand() {
      this.expanded = !this.expanded;
    },

    getPaddingByLevel({ level }) {
      return {
        "padding-left": `${ level * 0 + 0 }px`
      };
    },

    getColWidthByLevel({ level }) {
      return level * 3;
    },

    openModalCreateLocal() {
      this.openModalCreateOrEdit({
        element: undefined,
        parent: this.obj,
      });
    },

    showMoveUpButtonLocal({ ivf_pos = null, pk }) {
      if (this.obj && (pk === this.obj.pk)) {
        return this.showMoveUpButton(this.obj);
      }

      return Number(ivf_pos) !== 0;
    },

    showMoveDownButtonLocal({ ivf_pos = null, pk }) {
      if (this.obj && (pk === this.obj.pk)) {
        return this.showMoveDownButton(this.obj);
      }

      return Number(ivf_pos) !== Number(last(this.data).ivf_pos);
    },

    isNotExpandable(item) {
      const fieldTypKbez = get(item, "feldtyp_kbez");
      const itemType = get(item, "ivf_argument.item_type");

      return !(fieldTypKbez === "Array" && itemType === "object") && fieldTypKbez !== "Object";
    },
  },
};
