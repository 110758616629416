import Modal from "../../../../../global/components/Modal/Modal.vue";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import Spinner from "../../../../../global/components/Spinner/Spinner.vue";
import translate from "../../../../../global/directives/translate";
import HttpMixin from "../../../../../global/mixins/HttpMixin";
import {
  round,
} from "lodash-es";
import moment from "moment";

const MAX_HOURS = 50;
const HTML_ID = "rechenhilfe";

// @vue/component
export default {
  name: "RechenhilfeButton",
  components: {
    Modal,
    FormElement,
    Spinner,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    vollzeitstelle: {
      type: [String, Number],
      required: true,
    },
    stellenumfang: {
      type: [String, Number],
      required: true,
    },
    stellenumfangProjekt: {
      type: [String, Number],
      required: true,
    },
    belegbetrag: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    pktId: {
      type: String,
      required: true,
    },
    obergrenze: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false
    },
    notBefore: {
      type: String,
      required: false,
      default: undefined,
    },
    notAfter: {
      type: String,
      required: false,
      default: undefined,
    },
    von: {
      type: String,
      required: false,
      default: undefined,
    },
    bis: {
      type: String,
      required: false,
      default: undefined,
    },
    statusUse: {
      type: Boolean,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      statusModal: undefined,
      statusLoadingPersonalkostentabelle: true,
      model: {
        vollzeitstelle: 0,
        stellenumfang: 0,
        stellenumfangProjekt: 0,
        belegbetrag: 0,
        pauschale: 0,
        von: null,
        bis: null,
      },
      modelBetrag: 0,
    };
  },
  computed: {
    buttonId() {
      return "rechenhilfe_button";
    },

    selectorClose() {
      return `#${ this.buttonId }`;
    },

    optionsModal() {
      return {
        header: "_TXT_RECHENHILFE_MODAL_HEADER_",
        showCloseButton: true,
        required: true,
      };
    },

    optionsVollzeit() {
      return {
        id: "vollzeitstelle",
        htmlId: `${ HTML_ID }_vollzeitstelle`,
        type: "float",
        label: "_LBL_RECHENHILFE_MODAL_VOLLZEITSTELLE_",
        tooltip: "_HTML_RECHENHILFE_MODAL_VOLLZEITSTELLE_INFO_I_",
        required: true,
        addonBack: "_LBL_SUFFIX_STUNDEN_PRO_WOCHE_",
        view: "v-alt",
        translate: true,
        nullValue: 0,
        maxValue: MAX_HOURS,
        change: this.changeVollzeitstelle,
      };
    },

    optionsStellenumfang() {
      return {
        id: "stellenumfang",
        htmlId: `${ HTML_ID }_stellenumfang`,
        type: "float",
        label: "_LBL_RECHENHILFE_MODAL_STUNDENUMFANG_",
        tooltip: "_HTML_RECHENHILFE_MODAL_STUNDENUMFANG_INFO_I_",
        required: true,
        addonBack: "_LBL_SUFFIX_STUNDEN_PRO_WOCHE_",
        view: "v-alt",
        translate: true,
        nullValue: 0,
        maxValue: this.model.vollzeitstelle || MAX_HOURS,
        change: this.changeUmfangStelle,
      };
    },

    optionsStellenumfangProjekt() {
      return {
        id: "stellenumfang_projekt",
        htmlId: `${ HTML_ID }_stellenumfang_projekt`,
        type: "float",
        label: "_LBL_RECHENHILFE_MODAL_STUNDENUMFANG_PROJEKT_",
        tooltip: "_HTML_RECHENHILFE_MODAL_STUNDENUMFANG_PROJEKT_INFO_I_",
        addonBack: "_LBL_SUFFIX_STUNDEN_PRO_WOCHE_",
        required: true,
        view: "v-alt",
        translate: true,
        nullValue: 0,
        maxValue: this.model.stellenumfang || MAX_HOURS,
      };
    },

    optionsBelegbetrag() {
      return {
        id: "belegbetrag",
        htmlId: `${ HTML_ID }_belegbetrag`,
        type: "currency",
        label: "_LBL_RECHENHILFE_MODAL_BELEGBETRAG_",
        tooltip: "_HTML_RECHENHILFE_MODAL_BELEGBETRAG_INFO_I_",
        view: "v-alt",
        required: true,
        translate: true,
        nullValue: 0,
      };
    },

    optionsVon() {
      const NOT_AFTER = this.model.bis && this.model.bis < this.notAfter ? this.model.bis : this.notAfter;
      return {
        id: "von",
        htmlId: `${ HTML_ID }_von`,
        type: "date",
        label: "_LBL_RECHENHILFE_MODAL_VON_",
        tooltip: "_HTML_RECHENHILFE_MODAL_VON_INFO_I_",
        view: "v-alt",
        required: true,
        translate: true,
        notAfter: NOT_AFTER,
        notBefore: this.notBefore,
      };
    },

    optionsBis() {
      const NOT_BEFORE = this.model.von && this.model.von > this.notBefore ? this.model.von : this.notBefore;
      return {
        id: "bis",
        htmlId: `${ HTML_ID }_bis`,
        type: "date",
        label: "_LBL_RECHENHILFE_MODAL_BIS_",
        tooltip: "_HTML_RECHENHILFE_MODAL_BIS_INFO_I_",
        view: "v-alt",
        required: true,
        translate: true,
        notAfter: this.notAfter,
        notBefore: NOT_BEFORE,
      };
    },

    optionsPauschale() {
      return {
        id: "pauschale",
        htmlId: `${ HTML_ID }_pauschale`,
        type: "float",
        label: "_LBL_RECHENHILFE_MODAL_AG_PAUSCHALE_",
        tooltip: "_HTML_RECHENHILFE_MODAL_AG_PAUSCHALE_INFO_I_",
        required: true,
        view: "v-alt",
        suffix: "%",
        translate: true,
        nullValue: 0,
      };
    },

    optionsBetrag() {
      return {
        id: "betrag",
        htmlId: `${ HTML_ID }_betrag`,
        type: "currency",
        label: "_LBL_RECHENHILFE_MODAL_FOERDERFAEHIGER_BETRAG_",
        tooltip: "_HTML_RECHENHILFE_MODAL_FOERDERFAEHIGER_BETRAG_INFO_I_",
        required: true,
        view: "v-alt",
        translate: true,
        nullValue: 0,
      };
    },

    buttonTypeClass() {
      return this.statusUse ? "default" : "primary";
    },
  },
  methods: {
    openModal() {
      if (this.disabled) {
        return;
      }
      this.setModelFromProps();
      this.loadPersonalkostentabelle();
      this.statusModal = true;
    },

    loadPersonalkostentabelle() {
      if (!this.statusLoadingPersonalkostentabelle) {
        this.calculateBetrag();
        return;
      }
      this.getHttp({
        url: "katalog/",
        urlParams: {
          key: "personalkostentabelle",
          pk: this.pktId
        },
      }).then(
        response => {
          if (response && response.length) {
            this.model.pauschale = response[0].pkt_pauschale;
          }
          this.calculateBetrag();
          this.statusLoadingPersonalkostentabelle = false;
        }
      );
    },

    closeModal() {
      this.statusModal = false;
    },

    changeModel() {
      this.calculateBetrag();
    },

    setModelFromProps() {
      this.model.vollzeitstelle = this.vollzeitstelle;
      this.model.stellenumfang = this.stellenumfang;
      this.model.stellenumfangProjekt = this.stellenumfangProjekt;
      this.model.belegbetrag = this.belegbetrag || 0;
      this.model.von = this.von || this.notBefore;
      this.model.bis = this.bis || this.notAfter;
    },

    calculateBetrag() {
      // F1 - Obergrenze
      // F15 - COUNT_DAYS
      const {
        vollzeitstelle, // B3
        stellenumfang, // B5
        stellenumfangProjekt, // B7
        belegbetrag, // B1
        pauschale,
        von,
        bis,
      } = this.model;
      if (stellenumfang === 0 ||
        vollzeitstelle === 0 ||
        !von ||
        !bis) {
        this.modelBetrag = 0;
      } else {
        // AN-Jahresbrutto (für seine gesamte Stelle) B11: AN_JAHRESBRUTTO = B1*B3/B5*(365/MIN(F15;365))
        // AG-Jahresbrutto (für seine gesamte Stelle) B13: AG_JAHRESBRUTTO = B11*1,23
        // Max. förderfähig (als Vollzeitstelle, mit Kappung) B15: MAX_FOERDERFAEHIG = MIN(B13;F1)
        // Max. förderfähig für die Stelle im Projekt BETRAG = B15*B7/B3
        // max förderfähiger Betrag (gem Arbeitszeit) this.modelBetrag = BETRAG*((MIN(F15;365)/365))
        const BIS_PLUS_DAY = moment(bis).add(1, "days");
        const COUNT_DAYS = Math.abs(BIS_PLUS_DAY.diff(moment(von), "days"));
        const AN_JAHRESBRUTTO = belegbetrag * vollzeitstelle / stellenumfang * (365 / Math.min(COUNT_DAYS, 365));
        const AG_JAHRESBRUTTO = AN_JAHRESBRUTTO * (pauschale / 100 + 1);
        const MAX_FOERDERFAEHIG = this.obergrenze ? Math.min(AG_JAHRESBRUTTO, this.obergrenze) : AG_JAHRESBRUTTO;
        const BETRAG = MAX_FOERDERFAEHIG * stellenumfangProjekt / vollzeitstelle;
        this.modelBetrag = round(BETRAG * ((Math.min(COUNT_DAYS, 365) / 365)), 2);
      }
    },

    saveLocal() {
      this.save({
        betrag: this.modelBetrag,
        von: this.model.von,
        bis: this.model.bis,
      });
      this.closeModal();
    },

    changeVollzeitstelle() {
      if (this.model.vollzeitstelle < this.model.stellenumfang) {
        this.model.stellenumfang = this.model.vollzeitstelle;
        this.changeUmfangStelle();
      }
    },

    changeUmfangStelle() {
      if (this.model.stellenumfang < this.model.stellenumfangProjekt) {
        this.model.stellenumfangProjekt = this.model.stellenumfang;
        this.calculateBetrag();
      }
    }
  },
};
