"use strict";

import template from "./button-print.jade";

class ButtonPrintController {
  /*@ngInject*/
  constructor($window) {
    this.$window = $window;
  }

  printDiv() {
    const printContents = this.$window.document.getElementById("printableArea").innerHTML;
    const popupWin = this.$window.open("Print", "_blank", "width=1000,height=1000");
    popupWin.document.open();
    popupWin.document.write("<html><head><link rel='stylesheet' type='text/css' href='/static/styles.css' /></head><body onload='window.print()'>" + printContents + "</body></html>");
    popupWin.document.close();
  }
}

export default {
  template: template(),
  controller: ButtonPrintController,
  controllerAs: "vm",
  bindings: { }
};
