"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";

import models from "./models";
import gutachten from "./gutachten";
import ergebnis from "./ergebnis";
import regeln from "./regel";
import vorlagen from "./vorlagen";

const gutachtenModule = angular.module("root.gutachten", [
  uiRouter,
  models.name,
  gutachten.name,
  ergebnis.name,
  regeln.name,
  vorlagen.name,
]);

export default gutachtenModule;
