"use strict";

import template from "./details.jade";
import createTagesordnungController from "./tagesordnung.modal.create.controller";
import createTagesordnungTemplate from "./tagesordnung.modal.create.jade";
import datePlaceTemplate from "./dateplace.edit.jade";
import datePlaceController from "./dateplace.edit.controller";
import protocolTemplate from "./protokoll.edit.jade";
import protocolController from "./protokoll.edit.controller";

import createTeilnehmerTemplate from "dias/sitzungen/teilnehmer.modal.create.jade";
import createTeilnehmerController from "dias/sitzungen/teilnehmer.modal.create.controller";

import {
  get, orderBy, values,
} from "lodash-es";
import { EventBus } from "../../../vue/client/vue/global/functions/event-bus";


class SitzungenDetailsController {
  /*@ngInject*/
  constructor(
    $stateParams,
    $rootScope,
    $q,
    $anchorScroll,
    $window,
    $filter,
    AuthService,
    Sitzungen,
    DiasNotification,
    diasModalDialog,
    diasConfirmDialog,
    KAufgabe,
    Katalog,
    i18n,
    $timeout,
  ) {
    this.diasModalDialog = diasModalDialog;
    this.diasConfirmDialog = diasConfirmDialog;
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.$filter = $filter;
    this.$stateParams = $stateParams;
    this.$anchorScroll = $anchorScroll;
    this.$window = $window;
    this.AuthService = AuthService;
    this.DiasNotification = DiasNotification;
    this.Sitzungen = Sitzungen;
    this.KAufgabe = KAufgabe;
    this.Katalog = Katalog;
    this.$timeout = $timeout;
    this.gettext = i18n.gettext;

    this.docAnzeige = "4014cc4c-2042-421f-a21b-d83b14520c7e";
    this.dokumentePermissions = {
      view: "sitzungdokument.view",
      create: "sitzungdokument.create",
      delete: "sitzungdokument.delete",
      update: "sitzungdokument.update",
      update_gueltigkeit: "", // Das Bearbeiten von Gültigkeiten ergibt bei den kurzlebigen Sitzungsdokumenten keinen Sinn.
    };

    this.sitzungTeilnehmerTableDeferred = $q.defer();
    this.sitTeilnemerTable = this.sitzungTeilnehmerTableDeferred.promise;
    this.reloadTeilnehmerTable = this.reloadTeilnehmerTable.bind(this);
    this.teilnehmerChangeStatus = this.teilnehmerChangeStatus.bind(this);

    this.sitzungTopTableDeferred = $q.defer();
    this.sitTopTable = this.sitzungTopTableDeferred.promise;

    this.name = "SitzungenDetailsController";
    this.selected = null;
    this.model = {};
    this.statusChanges = {};

    this.datePlaceTemplate = datePlaceTemplate;
    this.datePlaceController = datePlaceController;

    this.protocolTemplate = protocolTemplate;
    this.protocolController = protocolController;
    this.canReset = false;

    // Alle loading-flags:
    this.initialized = false;
    this.loading = {
      sitzung: true,
      tops: false,
      aufgaben: false,
    };

    this.reloadSitzung().finally(() => this.initialized = true);

    this.editPositionModes = {};
    this.editPositionen = {};
    this.editPositionModules = {};
    this.editPositionLoaded = {};
    this.editPositionLoading = {};
    this.errors = {};
    this.createErrors = {};
    this.newPosition = {};
    this.addPositionMode = {};
    this.countOberpunkten = {};
    this.countPunkten = {};
    this.dropdownPositionStatus = {};
    this.hoverEdit = {};
    this.chartOptions = {
      animationSteps: 40,
      animationEasing: "linear",
      legendTemplate: "<ul class='<%=name.toLowerCase()%>-legend'><% for (var i=0; i<segments.length; i++){%> <li> <span style='background-color:<%=segments[i].fillColor%>'></span><%if(segments[i]){%> <span><%=segments[i].value%>%</span> <span><%=segments[i].label%></span><%}%></li> <%}%></ul>"
    };
    this.chart = {
      data: [2.3, 1.8, 2.7, 1.5, 5.0, 1.2, 3.1],
      labels: ["Kinder- und Jugendhilfe", "Projekte", "Inklusion", "Langer Titel mit Mega-Wortgebilden", "Kurz", "Kinder- und Jugendhilfe", "Viele Möglichkeiten"]
    };
    this.chartStatistik = [
      {
        label: "Kinder- und Jugendhilfe",
        data: [
          { label: "Bewilligt", value: 40 },
          { label: "Zureuckgestellt", value: 30 },
          { label: "Abgelehnt", value: 20 }
        ]
      },
      {
        label: "Projekte",
        data: [
          { label: "Bewilligt", value: 20 },
          { label: "Zureuckgestellt", value: 40 },
          { label: "Abgelehnt", value: 10 }
        ]
      },
      {
        label: "Inklusion",
        data: [
          { label: "Bewilligt", value: 80 },
          { label: "Zureuckgestellt", value: 10 },
          { label: "Abgelehnt", value: 0 }
        ]
      },
      {
        label: "Langer Titel",
        data: [
          { label: "Bewilligt", value: 50 },
          { label: "Zureuckgestellt", value: 20 },
          { label: "Abgelehnt", value: 30 }
        ]
      },
      {
        label: "Kurz",
        data: [
          { label: "Bewilligt", value: 40 },
          { label: "Zureuckgestellt", value: 80 },
          { label: "Abgelehnt", value: 20 }
        ]
      }
    ];
    // Timeline
    this.timelineConfig = {
      benachrichtigungen: {
        bezug: "sitzung",
        bezugpk: $stateParams.id,
        autorefresh: true,
      },
      url: `sitzungen/${ $stateParams.id }/timeline/`,
    };
    this.timelineService = Sitzungen.one($stateParams.id).timeline;
    this.loadCSVURL();
    this.loadPresseCSVURL();
    this.loadZahlenprotokollCSVURL();

    this.onAufgabeAngenommen = () => {
      this.$timeout(() => {
        this.reloadSitzung();
      });
    };

    this.onWorkflowCanceled = this.onWorkflowCanceled.bind(this);
    this.updateAufgabeFromVue = this.updateAufgabeFromVue.bind(this);
    this.onWorkflowInit = this.onWorkflowInit.bind(this);
    this.onWorkflowStarted = this.onWorkflowStarted.bind(this);
    this.onWorkflowFinish = this.onWorkflowFinish.bind(this);
    this.onWorkflowSuccess = this.onWorkflowSuccess.bind(this);
    this.onWorkflowFailure = this.onWorkflowFailure.bind(this);

    // Notizen
    this.steps = {
      ubersicht: {
        id: "step_ubersicht",
        key: "step_ubersicht",
        kbez: "_TXT_SITZUNG_DETAILS_RUBRIK_UEBERSICHT_",
        prio: 0,
      },
      sitzungsdaten: {
        id: "step_sitzungsdaten",
        key: "step_sitzungsdaten",
        kbez: "_TXT_SITZUNG_DETAILS_RUBRIK_SITZUNGSDATEN_",
        prio: 1,
      },
      tops: {
        id: "step_tops",
        key: "step_tops",
        kbez: "_TXT_SITZUNG_DETAILS_RUBRIK_TOPS_",
        prio: 2,
      },
      mittel: {
        id: "step_mittel",
        key: "step_mittel",
        kbez: "_TXT_SITZUNG_DETAILS_RUBRIK_MITTEL_",
        prio: 3,
      },
      protokoll: {
        id: "step_protokoll",
        key: "step_protokoll",
        kbez: "_TXT_SITZUNG_DETAILS_RUBRIK_PROTOKOLL_",
        prio: 4,
      },
      teilnehmer: {
        id: "step_teilnehmer",
        key: "step_teilnehmer",
        kbez: "_TXT_SITZUNG_DETAILS_RUBRIK_TEILNEHMER_",
        prio: 5,
      },
      dokumente: {
        id: "step_dokumente",
        key: "step_dokumente",
        kbez: "_TXT_SITZUNG_DETAILS_RUBRIK_DOKUMENTE_",
        prio: 6,
      },
    };
    this.notizenSteps = orderBy(values(this.steps), ["prio"]);
  }

  loadCSVURL() {
    this.Sitzungen.one(this.$stateParams.id).getList("antraege", { limit: 1 }).then(
      response => {
        if (response.extra && response.extra.csvAvailable) {
          this.csvURL = response.getRestangularUrl();
        }
      }
    );
  }

  loadPresseCSVURL() {
    this.Sitzungen.one(this.$stateParams.id).getList("antraege_presse", { limit: 1 }).then(
      response => {
        if (response.extra && response.extra.csvAvailable) {
          this.presseCSVURL = response.getRestangularUrl();
        }
      }
    );
  }

  loadZahlenprotokollCSVURL() {
    this.Sitzungen.one(this.$stateParams.id).getList("antraege_zahlenprotokoll", { limit: 1 }).then(
      response => {
        if (response.extra && response.extra.csvAvailable) {
          this.zahlenprotokollCSVURL = response.getRestangularUrl();
        }
      }
    );
  }

  savePdf() {
    this.Sitzungen.one(this.$stateParams.id).withHttpConfig({ responseType: "blob" }).customGET("pdf").then(
      response => {
        const fileName = "sitzung_" + this.sitzung.si_sitzungsnr + ".pdf";
        if (this.$window.navigator.msSaveBlob) {
          this.$window.navigator.msSaveBlob(response, fileName);
        } else {
          const fileURL = (this.$window.URL || this.$window.webkitURL).createObjectURL(response);
          const aLink = this.$window.document.createElement("a");
          aLink.download = fileName;
          aLink.href = fileURL;
          aLink.target = "_self";
          const eventClick = this.$window.document.createEvent("MouseEvents");
          eventClick.initEvent(
            "click",
            true,
            true,
            this.$window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
          );
          aLink.dispatchEvent(eventClick);
        }
      }
    );
  }

  savePdfUebersicht() {
    this.Sitzungen.one(this.$stateParams.id).withHttpConfig({ responseType: "blob" }).customGET("uebersicht_pdf").then(
      response => {
        const fileName = (
          `${ this.$filter("date")(new Date(), "yyyyMMdd") }_sitzung_${ this.sitzung.si_sitzungsnr }_uebersicht.pdf`);
        if (this.$window.navigator.msSaveBlob) {
          this.$window.navigator.msSaveBlob(response, fileName);
        } else {
          const fileURL = (this.$window.URL || this.$window.webkitURL).createObjectURL(response);
          const aLink = this.$window.document.createElement("a");
          aLink.download = fileName;
          aLink.href = fileURL;
          aLink.target = "_self";
          const eventClick = this.$window.document.createEvent("MouseEvents");
          eventClick.initEvent(
            "click",
            true,
            true,
            this.$window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
          );
          aLink.dispatchEvent(eventClick);
        }
      }
    );
  }

  seeWidth(value, rowData) {
    let allValue = 0;
    for (let i = 0; i < rowData.length; i++) {
      allValue += rowData[i].value;
    }
    return value / allValue * 100;
  }

  reloadSitzung() {
    this.lastReload = new Date().getTime();
    this.loading.sitzung = true;
    return this.Sitzungen.one(this.$stateParams.id).get().then(
      response => {
        if (!this.sitzung || this.sitzung.pk === undefined) {
          this.timelineCsvUrl = response.getRestangularUrl() + "/timeline/";
          this.sitzung = response;
          this.initTable();
          this.reloadTagesordnung();
        } else {
          // only copy server received properties into existing sitzung object
          angular.extend(this.sitzung, response.plain());
          this.initTable();
          this.reloadTagesordnung();
        }
        this.$rootScope.$title = "Sitzung " + this.sitzung.si_sitzungsnr;
        this.reloadProtokoll();
        // this.loadAktuelleAufgabe();
        this.setUrl();
      }
    ).finally(() => this.loading.sitzung = false);
  }

  setUrl() {
    this.sitzungUrl = `sitzungen/${ this.sitzung.pk }/`;
  }

  reloadProtokoll() {
    if (this.AuthService.syncHasPerm("sitzungen.protokoll.view", this.sitzung.user_permissions)) {
      this.loading.protokoll = true;
      this.sitzung.loadProtokoll().then(
        response => {
          this.sitzung.si_protokoll = response.si_protokoll;
        }
      ).finally(() => this.loading.protokoll = false);
    }
  }

  initTable() {
    this.AuthService.hasPerms(["tops.mittel.view", "sitzungsteilnehmer.view"], this.sitzung.user_permissions).then(perms => {
      if (perms["tops.mittel.view"]) {
        this.resolveSitzungTopTable();
      }
      if (perms["sitzungsteilnehmer.view"]) {
        this.Katalog.getKatalog("teilnehmerstatus").then(
          response => {
            this.teilnehmerstatus = response;
          }
        ).finally(() => this.resolveSitzungTeilnehmerTable());
      } else {
        this.sitzungTeilnehmerTableDeferred.reject();
      }
    });
  }

  reloadTeilnehmerTable() {
    this.teilnehmerLastChange = new Date().getTime();
  }

  teilnehmerChangeStatus(row, status) {
    row.status = status;
    return row.save().then(
      () => {
        this.DiasNotification.page.success("Status geändert.");
        this.reloadTeilnehmerTable();
      },
      () => {
        this.DiasNotification.page.error("Status konnte nicht geändert werden.");
      }
    );
  }

  teilnehmerChangeVorsitz(row, vorsitz) {
    row.ste_vorsitz = vorsitz;
    return row.save().then(
      () => {
        this.DiasNotification.page.success("Vorsitz geändert.");
        this.reloadTeilnehmerTable();
      },
      () => {
        this.DiasNotification.page.error("Vorsitz konnte nicht geändert werden.");
      }
    );
  }

  buildTeilnehmerstatusActions(perms) {
    const actions = [];
    const createCb = status => {
      return row => this.teilnehmerChangeStatus(row, status.pk);
    };
    const createVisible = status => {
      return row => perms["sitzungsteilnehmer.update"] && row.status !== status.pk;
    };
    for (let i = 0; this.teilnehmerstatus && i < this.teilnehmerstatus.length; i++) {
      actions.push({
        label: this.teilnehmerstatus[i].tst_kbez + " vermerken",
        callback: createCb(this.teilnehmerstatus[i]),
        isVisible: createVisible(this.teilnehmerstatus[i])
      });
    }
    return actions;
  }

  resolveSitzungTeilnehmerTable() {
    const requiredPerms = [
      "sitzungsteilnehmer.create",
      "sitzungsteilnehmer.update",
      "sitzungsteilnehmer.delete"
    ];
    this.AuthService.hasPerms(requiredPerms, this.sitzung.user_permissions).then(perms => {
      this.sitzungTeilnehmerTableDeferred.resolve({
        dataSource: this.sitzung.teilnehmer.getList,
        columnDefs: [
          { label: "Name", field: "nutzer_obj.name" },
          { label: "Organisation", field: "org_obj.name" },
          { label: "Teilnahme", field: "status_obj.tst_kbez", cellTemplate: "{{ row.status_obj ? row.status_obj.tst_kbez : 'Keine Rückmeldung'}}" },
          { label: "Vorsitz", field: "ste_vorsitz", cellClass: "no-min-width text-right", cellFilter: "yesNo" }
        ],
        emptyText: "Zu dieser Sitzung wurden noch keine Teilnehmer hinzugefügt.",
        tableActions: [
          { label: "Teilnehmer hinzufügen",
            icon: "glyphicon glyphicon-plus",
            callback: this.addTeilnehmer.bind(this),
            isVisible: () => {
              return perms["sitzungsteilnehmer.create"];
            } }
        ],
        actions: [
          ...this.buildTeilnehmerstatusActions(perms),
          { label: "Vorsitz zuweisen",
            callback: row => this.teilnehmerChangeVorsitz(row, true),
            isVisible: row => {
              return perms["sitzungsteilnehmer.update"] && row.vorsitz_moeglich && !row.ste_vorsitz;
            } },
          { label: "Vorsitz entziehen",
            callback: row => this.teilnehmerChangeVorsitz(row, false),
            isVisible: row => {
              return perms["sitzungsteilnehmer.update"] && row.ste_vorsitz;
            } },
          null,
          { label: "Teilnehmer entfernen",
            callback: row => {
              this.sitzung.teilnehmer.one(row.pk).remove().then(
                () => {
                  this.DiasNotification.page.success("Teilnehmer wurde entfernt.");
                  this.reloadTeilnehmerTable();
                },
                () => {
                  this.DiasNotification.page.error("Teilnehmer konnte nicht entfernt werden.");
                }
              );
            },
            isVisible: () => {
              return perms["sitzungsteilnehmer.delete"];
            }
          }
        ],
        isDirty: scope => {
          return this.teilnehmerLastChange > scope.vm.lastRefresh;
        }
      });
    });
  }

  addTeilnehmer() {
    this.sitzung.customGET("moegliche_teilnehmer").then(users => {
      this.diasModalDialog({
        title: "Teilnehmer hinzufügen",
        template: createTeilnehmerTemplate,
        controller: createTeilnehmerController,
        extras: {
          users: users,
          sitzung: this.sitzung
        }
      }).then(this.reloadTeilnehmerTable);
    });
  }

  resolveSitzungTopTable() {
    this.sitzungTopTableDeferred.resolve({
      dataSource: this.Sitzungen.one(this.$stateParams.id).mittelverteilung.getList,
      onRefreshData: list => {
        const sumRow = {
          isSum: true,
          tos_bez: "Gesamt",
          tos_bereitgestellt: 0,
          antraege: 0,
          tos_bedarf: 0,
          tos_bewilligt: 0
        };
        for (let i = 0; i < list.length; i++) {
          sumRow.tos_bereitgestellt += Number(list[i].tos_bereitgestellt);
          sumRow.antraege += Number(list[i].antraege);
          sumRow.tos_bedarf += Number(list[i].tos_bedarf);
          sumRow.tos_bewilligt += Number(list[i].tos_bewilligt);
        }
        list.push(sumRow);
      },
      rowClass: row => {
        if (row.isSum) {
          return "sum";
        }
        return;
      },
      columnDefs: [
        { label: "Tagesordnungspunkt", field: "tos_bez",
          cellTemplate: `
          <a ng-if="!row.isSum" ui-sref="root.sitzungen.details.top({id: '` + this.sitzung.pk + `', tos_id:'{{row.pk}}'})">
            {{row.tos_bez}}
          </a>
          <strong ng-if="row.isSum">{{row.tos_bez}}</strong>` },
        { label: "Budget", field: "tos_bereitgestellt", cellClass: "text-right",
          cellTemplate: `{{row.tos_bereitgestellt | zeroNumber | currency }}` },
        { label: "Anzahl Anträge", field: "antraege", cellClass: "text-right",
          cellFilter: "zeroNumber" },
        { label: "Beantragte Summe", field: "tos_bedarf", cellClass: "text-right",
          cellTemplate: `{{row.tos_bedarf | zeroNumber | currency }}` },
        { label: "Bewilligte Summe", field: "tos_bewilligt", cellClass: "text-right",
          cellTemplate: `{{row.tos_bewilligt | zeroNumber | currency }}` },
        { label: "Priorisierung", field: "priorisierungsstatus",
          cellTemplate: "<strong class='{{row.priorisiert?\"font-weight-bold status-done\":\"font-weight-bold status-open\"}}'>{{row.priorisiert?\"Priorisierung abgeschlossen\":\"Nicht priorisiert\"}}</strong>" }
      ],
      isDirty: scope => {
        return this.lastReload > scope.vm.lastRefresh;
      }
    });
  }

  confirmResetTagesordnung() {
    return this.diasConfirmDialog({
      titleTemplate: this.gettext("_TXT_SITZUNG_DETAILS_TOPS_ZURUECKSETZEN_MODAL_TITLE_"),
      contentTemplate: this.gettext("_HTML_SITZUNG_DETAILS_TOPS_ZURUECKSETZEN_MODAL_BODY_"),
      okLabel: this.gettext("_BTN_SITZUNG_DETAILS_TOPS_ZURUECKSETZEN_MODAL_"),
      okCallback: () => this.resetTagesordnung()
    });
  }

  resetTagesordnung() {
    this.loading.tops = true;
    this.sitzung.tops.resetTops().then(
      () => {
        this.reloadTagesordnung();
        EventBus.$emit("reloadNotizen");
      },
      err => this.DiasNotification.page.error(err, "Fehler beim Zurücksetzen der Tagesordnungspunkte")
    ).finally(() => this.loading.tops = false);
  }

  reloadTagesordnung() {
    if (this.AuthService.syncHasPerm("tops.list", this.sitzung.user_permissions)) {
      this.loading.tops = true;
      this.sitzung.tops.getList().then(
        response => {
          this.tagesordnung = response;
          this.canReset = true;
          this.tagesordnung.forEach(top => {
            this.canReset = this.canReset && top.deletable;
            top.unterpunkte.forEach(utop => {
              this.canReset = this.canReset && utop.deletable;
            });
          });
          this.showLastEditTop();
        }
      ).finally(() => this.loading.tops = false);
      this.loading.modulpreview = true;
      this.sitzung.tops.getModulPreview().then(
        response => {
          this.modulvorschau = response;
        }
      ).finally(() => this.loading.modulpreview = false);
    } else {
      // Tagesordnung entfernen, falls Berechtigung nachträglich entfernt wurde
      this.tagesordnung = undefined;
    }
  }

  changeTop(punkt) {
    this.model.tos_bez = punkt.tos_bez;
    this.statusChanges[punkt.pk] = true;
  }

  createTagesordnung(oberpunktPk) {
    this.createModalTagesordnung(oberpunktPk).finally(() => {
      this.reloadTagesordnung();
    });
  }

  createModalTagesordnung() {
    return this.diasModalDialog({
      title: "Tagesordnungspunkt hinzufügen",
      template: createTagesordnungTemplate,
      controller: createTagesordnungController,
      extras: {
        sitzung: this.sitzung,
        tagesordnung: this.tagesordnung
      }
    });
  }

  sortTagesordnung() {
    if (!this.sortingStatus) {
      this.showLastEditTop();
    }
    this.sortingStatus = !this.sortingStatus;
  }

  cancelSorting() {
    this.reloadTagesordnung();
  }

  dragstart(punkt) {
    if (punkt.unterpunkte && punkt.unterpunkte.length > 0) {
      this.sortingStatusLevel2 = true;
    }
  }

  dragoverCallback(event) {
    const headerHeight = $("#navbar-fixed-top").height();
    const windowHeight = $(window).height();
    const scrollTop = $(window).scrollTop();
    if (event.screenY + 20 > windowHeight) {
      $(window).scrollTop(scrollTop + 4);
    } else if (event.screenY - headerHeight - 100 < 0) {
      $(window).scrollTop(scrollTop - 6);
    }
    return true;
  }

  dragend(punkt) {
    this.sortingStatusLevel2 = false;
    this.dndCanseled(punkt);
  }

  dndCanseled(punkt) {
    this.saveChanges(punkt);
  }


  toUp(punkt, index, oberpunkt) {
    oberpunkt.splice(index, 1);
    oberpunkt.splice(index - 1, 0, punkt);
    this.dndCanseled(punkt);
  }

  toBottom(punkt, index, oberpunkt) {
    oberpunkt.splice(index, 1);
    oberpunkt.splice(index + 1, 0, punkt);
    this.dndCanseled(punkt);
  }

  showLastEditTop() {
    this.sitzung.tops.lastEditTops().then(
      response => {
        this.lastEditTop = response.date;
      }
    );
  }

  saveChanges(punkt) {
    this.loading.tops = true;
    let position = null;
    let oberpunkt = null;
    for (let i = 0; i < this.tagesordnung.length; i++) {
      if (this.tagesordnung[i].pk === punkt.pk) {
        position = i + 1;
        break;
      }
      if (this.tagesordnung[i].unterpunkte.length > 0) {
        for (let j = 0; j < this.tagesordnung[i].unterpunkte.length; j++) {
          if (this.tagesordnung[i].unterpunkte[j].pk === punkt.pk) {
            position = j + 1;
            oberpunkt = this.tagesordnung[i].pk;
            break;
          }
        }
      }
    }
    const data = {
      pk: punkt.pk,
      tos_bez: punkt.tos_bez,
      tos_info: punkt.tos_info,
      tos_pos: position,
      oberpunkt: oberpunkt,
      top: punkt.top
    };
    this.updatePositionServer(punkt, data, true);
  }

  loadTopModules(top) {
    const moduleDefer = this.$q.defer();
    const katalogDefer = this.$q.defer();
    if (!this.editPositionen[top.pk].module) {
      this.Sitzungen.one(this.sitzung.pk).tops.one(top.pk).sitzungtagesordnungmodul.getList().then(
        response => {
          this.editPositionen[top.pk].module = response.plain();
          moduleDefer.resolve(response.plain());
        },
        error => moduleDefer.reject(error)
      );
    } else {
      moduleDefer.resolve(this.editPositionen[top.pk].module);
    }
    if (!this.sitzungsmodulkatalog) {
      this.Katalog.getKatalog("sitzungsmodule", { ssm_konfigurierbar: "True" }).then(
        response => {
          this.sitzungsmodulkatalog = response;
          katalogDefer.resolve(response);
        },
        error => katalogDefer.reject(error)
      );
    } else {
      katalogDefer.resolve(this.sitzungsmodulkatalog);
    }
    return this.$q.all([moduleDefer.promise, katalogDefer.promise]);
  }

  modulInputMissing() {
    return !this.selectedModul;
  }

  addModul(topModel) {
    if (topModel.module === undefined) {
      topModel.module = [];
    }

    if (!this.modulInputMissing()) {
      topModel.module.push({
        modul: this.selectedModul.pk,
        stm_kbez: this.selectedModul.ssm_bez,
        stm_paramterin: {}
      });
      topModel.modulChoices = this.getPositionModulauswahl(topModel.module);
      this.selectedModul = null;
    }
  }

  canRemoveModul(index, topModel) {
    return this.sitzungsmodulkatalog && !this.sitzungsmodulkatalog.getById(topModel.module[index].modul);
  }

  removeModul(index, topModel) {
    topModel.module.splice(index, 1);
    topModel.modulChoices = this.getPositionModulauswahl(topModel.module);
  }

  moveUpModul(index, topModel) {
    if (index === 0) {
      return;
    }
    const elem = topModel.module[index];
    const previous = topModel.module[index - 1];
    topModel.module[index - 1] = elem;
    topModel.module[index] = previous;
  }

  moveDownModul(index, topModel) {
    if (index >= topModel.module.length - 1) {
      return;
    }
    const elem = topModel.module[index];
    const next = topModel.module[index + 1];
    topModel.module[index + 1] = elem;
    topModel.module[index] = next;
  }

  getPositionModulauswahl(topModule) {
    if (!this.sitzungsmodulkatalog) {
      return [];
    }
    return this.sitzungsmodulkatalog.filter(value => {
      for (let i = 0; topModule && i < topModule.length; i++) {
        if (topModule[i].modul === value.pk) {
          return;
        }
      }
      return value;
    });
  }

  toggleEditPosition(punkt, oberPunkt, flag, index, parentIndex) {
    if (!this.editPositionModes[punkt.pk]) {
      let labelPosition = "";
      if (!angular.isUndefined(parentIndex)) {
        labelPosition = parentIndex + 1 + ".";
      }
      if (this.errors[punkt.pk]) {
        delete this.errors[punkt.pk];
      }
      this.editPositionen[punkt.pk] = {
        tos_bez: punkt.tos_bez,
        tos_info: punkt.tos_info,
        tos_pos: index + 1 + ""
      };
      if (flag) {
        this.countPunktenGlobal = [];
        for (let i = 0; i < oberPunkt.length; i++) {
          const j = i + 1;
          const data = { label: labelPosition + j,
                         value: j };
          this.countPunktenGlobal.push(data);
        }
      } else {
        this.countPunkten[oberPunkt.pk] = [];
        for (let i = 0; i < oberPunkt.unterpunkte.length; i++) {
          const j = i + 1;
          const data = { label: labelPosition + j,
                         value: j };
          this.countPunkten[oberPunkt.pk].push(data);
        }
      }
      this.loadTopModules(punkt).finally(
        () => {
          this.editPositionen[punkt.pk].modulChoices = this.getPositionModulauswahl(this.editPositionen[punkt.pk].module);
          this.editPositionLoaded[punkt.pk] = true;
        }
      );
    }
    this.editPositionModes[punkt.pk] = !this.editPositionModes[punkt.pk];
  }

  toggleAddPosition(punkt, count, parentIndex) {
    if (!this.addPositionMode[punkt.pk]) {
      if (!count) {
        this.newPosition[punkt.pk] = {
          tos_pos: "1"
        };
      } else {
        this.newPosition[punkt.pk] = {};
      }
      const labelPosition = parentIndex + 1 + ".";
      if (this.createErrors[punkt.pk]) {
        delete this.createErrors[punkt.pk];
      }
      this.countOberpunkten[punkt.pk] = [];
      for (let i = 0; i <= count; i++) {
        const j = i + 1;
        const data = { label: labelPosition + j,
                       value: j };
        this.countOberpunkten[punkt.pk].push(data);
      }
    }
    this.addPositionMode[punkt.pk] = !this.addPositionMode[punkt.pk];
  }

  updatePosition(punkt, model) {
    model.module = model.module || [];
    const data = {
      pk: punkt.pk,
      tos_bez: model.tos_bez,
      tos_info: model.tos_info,
      tos_pos: model.tos_pos,
      oberpunkt: punkt.oberpunkt,
      top: punkt.top,
      module: model.module.map((value, idx) => {
        value.stm_pos = idx;
        value.sitzungstop = punkt.pk;
        return value;
      })
    };
    this.editPositionLoading[punkt.pk] = true;
    this.updatePositionServer(punkt, data, false).finally(
      () => this.editPositionenLoading[punkt.pk] = false
    );
  }

  updatePositionServer(punkt, data, updateStatus) {
    return this.sitzung.tops.updateTop(punkt.pk, data).then(
      () => {
        if (!updateStatus) {
          this.toggleEditPosition(punkt);
        }

        this.reloadTagesordnung();
        if (this.errors[punkt.pk]) {
          delete this.errors[punkt.pk];
        }
      },
      errors => {
        this.errors[punkt.pk] = {};
        this.errors[punkt.pk] = errors.data;
      }
    );
  }

  createPosition(oberpunkt, model) {
    const data = {
      tos_bez: model.tos_bez,
      tos_pos: model.tos_pos,
      oberpunkt: oberpunkt.pk,
    };
    this.sitzung.tops.post(data).then(
      () => {
        if (this.createErrors[oberpunkt.pk]) {
          delete this.createErrors[oberpunkt.pk];
        }
        this.addPositionMode[oberpunkt.pk] = false;
        this.reloadTagesordnung();
      },
      errors => {
        this.createErrors = {};
        this.createErrors[oberpunkt.pk] = errors.data;
      }
    );
  }

  confirmDeleteTop(punkt) {
    return this.diasConfirmDialog({
      titleTemplate: this.gettext("_TXT_SITZUNG_DETAILS_TOPS_LOESCHEN_MODAL_TITLE_"),
      contentTemplate: this.gettext("_HTML_SITZUNG_DETAILS_TOPS_LOESCHEN_MODAL_BODY_"),
      okLabel: this.gettext("_BTN_DELETE_"),
      okCallback: () => this.deleteTop(punkt)
    });
  }

  confirmResetTop(punkt) {
    return this.diasConfirmDialog({
      titleTemplate: this.gettext("_LBL_SITZUNG_CONFIRM_RESET_HEADER_"),
      contentTemplate: this.gettext("_HTML_SITZUNG_CONFIRM_RESET_INFO_"),
      okLabel: this.gettext("_LBL_SITZUNG_CONFIRM_RESET_OK_"),
      okCallback: () => this.resetTop(punkt)
    });
  }

  deleteTop(punkt) {
    this.loading.tops = true;
    this.sitzung.tops.deleteTops(punkt.pk).then(
      () => {
        EventBus.$emit("reloadNotizen");
        this.reloadTagesordnung();
      },
      () => {
        this.loading.tops = false;
      }
    );
  }

  resetTop(punkt) {
    this.loading.tops = true;
    this.sitzung.tops.resetOneTop(punkt.pk).then(
      () => {
        this.reloadTagesordnung();
      }
    ).finally(
      () => {
        this.loading.tops = false;
      }
    );
  }

  dropdownPosition(punkt) {
    this.dropdownPositionStatus[punkt.pk] = !this.dropdownPositionStatus[punkt.pk];
  }

  showErrorLabel(field) {
    if (field === "tos_bez") {
      return "Name: ";
    } else if (field === "tos_pos") {
      return "Position: ";
    }
  }

  getObjectLength(obj) {
    return Object.keys(obj).length;
  }

  scrollTop() {
    $(window).scrollTop(0);
  }

  changeTimelineStatus() {
    this.timelineStatus = !this.timelineStatus;
  }

  resetCache() {
    this.sitzung.cache.reset().then(
      () => this.DiasNotification.page.success("Der Zwischenspeicher wurde erfolgreich zurückgesetzt."),
      err => this.DiasNotification.page.error(err, "Fehler beim Zurücksetzen des Zwischenspeichers.")
    );
  }

  mouseenterPosition(pk) {
    if (pk) {
      this.hoverEdit[pk] = true;
    } else {
      this.hoverEditNew = true;
    }
  }

  mouseleavePosition(pk) {
    if (pk) {
      this.hoverEdit[pk] = false;
    } else {
      this.hoverEditNew = false;
    }
  }

  activeNumDays(sitzungsdatum) {
    const date1 = new Date(sitzungsdatum);
    const date2 = new Date();
    date1.setHours(0);
    date2.setHours(0);
    date2.setMinutes(0);
    date2.setSeconds(0);
    date2.setMilliseconds(0);
    if (date2 > date1) {
      return "";
    }
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const activeNumDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return `(in ${ activeNumDays } ${ activeNumDays === 1 ? "Tage" : "Tagen" })`;
  }

  onWorkflowCanceled() {
    this.loading.aufgabe = false;
    this.clientFunktion = undefined;
  }

  updateAufgabeFromVue(newAufgabe) {
    this.$timeout(() => {
      this.updateAufgabe(newAufgabe);
    });
  }

  updateAufgabe(newAufgabe) {
    Object.assign(this.sitzung.aktuelle_aufgabe, newAufgabe);
  }

  onWorkflowInit(clientFunktion) {
    this.$timeout(() => {
      this.loading.aufgabe = true;
      this.clientFunktion = clientFunktion;
    });
  }

  onWorkflowStarted(newAufgabe, currentWorkflowfolge) {
    this.$timeout(() => {
      this.updateAufgabe(newAufgabe);
      this.currentWorkflowfolge = currentWorkflowfolge;
    });
  }

  onWorkflowFinish() {
    this.$timeout(() => {
      this.loading.aufgabe = false;
      this.clientFunktion = undefined;
      this.currentWorkflowfolge = undefined;
    });
  }

  onWorkflowSuccess(aufgabe) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerSuccess(aufgabe);
      }
      this.scrollTop();
      if (get(this.currentWorkflowfolge, "wfo_reload")) {
        return this.reloadSitzung();
      }
    });
  }

  onWorkflowFailure(err) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerError(err);
      }
      if (err.data && err.data.error_data) {
        this.validationErrors = err.data.error_data;
      } else if (err.data && !angular.isString(err.data)) {
        this.validationErrors = err.data;
      } else {
        this.validationErrors = undefined;
      }
      this.scrollTop();
      return this.reloadSitzung();
    });
  }
}


export default {
  template: template(),
  controller: SitzungenDetailsController,
  controllerAs: "vm"
};
