import PeriodsTabs from "../PeriodsTabs/PeriodsTabs.vue";

import {
  FilterCurrencyMixin,
  FilterDateMixin,
} from "../../../global/mixins/FiltersMixin";
import {
  filter,
  forEach,
} from "lodash-es";

const FINANZIERUNG_MODULE_NAME_NOT_SHOW = {
  bearbeitbare_pauschale: true,
  ehrenamt_pauschale: true,
  manuelle_pauschale: true,
  manuelle_rueckforderung: true,
  rollstuhlplatz_pauschale: true,
  rueckforderungsverzicht: true,
  standard_pauschale: true,
};

// @vue/component
export default {
  components: {
    PeriodsTabs,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterDateMixin,
  ],
  props: {
    kostenModule: {
      type: Array,
      required: false,
      default: () => [],
      info: "GR-Module für Kosten",
    },
    finanzierungModule: {
      type: Array,
      required: false,
      default: () => [],
      info: "GR-Module für Finanzierung",
    },
  },
  data() {
    return {
      periodKeyShow: "",
    };
  },
  computed: {
    statusShowPerioden() {
      return this.perioden.length > 0;
    },

    periodenFiltered() {
      if (this.periodKeyShow === "all") {
        return this.perioden;
      }
      return filter(this.perioden, period => {
        return this.periodKeyShow === period.pk;
      });
    },

    kostenModuleGrouped() {
      return this.filterModule({
        module: this.kostenModule,
        modulPeriodeName: ["perioden_signal_kosten"],
      });
    },

    finanzierungModuleGrouped() {
      return this.filterModule({
        module: this.finanzierungModule,
        modulPeriodeName: ["perioden_signal_finanzierung", "zuschuss_perioden", "perioden_signal_zuschuss_eingabe"],
        statusFinanzierung: true,
      });
    },

    zuschussModuleGrouped() {
      return this.filterModule({
        module: this.finanzierungModule,
        modulPeriodeName: ["zuschuss_perioden", "perioden_signal_zuschuss_eingabe"],
        statusZuschuss: true,
      });
    },
  },
  methods: {
    filterModule({ module, modulPeriodeName, statusZuschuss, statusFinanzierung }) {
      let moduleLocal = module;
      if (statusZuschuss) {
        moduleLocal = filter(module, item => {
          return item.modulgruppen.indexOf("zuschuss") !== -1 || item.modulgruppen.indexOf("pauschale") !== -1;
        });
      }
      if (statusFinanzierung) {
        moduleLocal = filter(moduleLocal, item => {
          return !FINANZIERUNG_MODULE_NAME_NOT_SHOW[item.modulname];
        });
      }
      if (!this.statusShowPerioden) {
        return {
          perioden: [],
          notPerioden: moduleLocal,
        };
      }
      const KOSTEN_MODULE_GROUP = {
        perioden: [],
        notPerioden: [],
      };

      forEach(moduleLocal, modul => {
        const MODUL_NAME = modul.modulname;
        if (modulPeriodeName.indexOf(MODUL_NAME) !== -1) {
          KOSTEN_MODULE_GROUP.perioden.push(modul);
        } else {
          KOSTEN_MODULE_GROUP.notPerioden.push(modul);
        }
      });
      return KOSTEN_MODULE_GROUP;
    },
  },
};
