import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

import {
  cloneDeep,
  isObject,
  isString,
} from "lodash-es";

// @vue/component
export default {
  name: "TemplateAdminInhalt",
  components: {
    FormstepDetail,
    PuxTranslate,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "updateTemplate",
  ],
  setup() {
    const {
      addNotification,
      putHttp,
    } = HttpAPI();

    return {
      addNotification,
      putHttp,
    };
  },
  data() {
    return {
      options: {
        list: [
          {
            id: "tpl_content",
            type: "json_editor",
            label: "_LBL_TEMPLATEADMIN_DETAILS_CONTENT_",
            showLabel: true,
            view: "v-alt",
            doubleClickInsert: true,
            doubleClickInsertCallback: ({ text }) => {
              if (isString(text)) {
                return `{${ text }}`;
              } else if (isObject(text)) {
                return `${ JSON.stringify(text, null, 2) },`;
              }
              return "";
            }
          },
        ],
        required: true,
        saveCallback: this.save,
        editablePermission: "templateadmin.content.update",
      },
    };
  },
  computed: {
    urlSave() {
      return `templates/${ this.template.pk }/template_inhalt_aendern/`;
    },
  },
  methods: {
    save({ model }) {
      const DATA = cloneDeep(model);
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.urlSave,
          data: DATA,
        }).then(
          response => {
            this.$emit("updateTemplate", { template: response });
            this.addNotification({ text: "_MSG_TEMPLATEADMIN_DETAILS_UPDATE_CONTENT_SUCCESS_" });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },
  },
};
