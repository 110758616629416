import DashboardBalkendiagrammStatistic from "./DashboardBalkendiagrammStatistic/DashboardBalkendiagrammStatistic.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxChart from "../../../../global/components/PuxChart/PuxChart.vue";

import DashboardModuleMixin from "../DashboardModuleMixin";
import DashboardStylesMixin from "../DashboardStylesMixin";
import HttpMixin from "../../../../global/mixins/HttpMixin";

import {
  cloneDeep,
  filter,
  findLastIndex,
  get,
  isArray,
  isNil,
  map,
  size,
  toNumber,
} from "lodash-es";

// @vue/component
export default {
  name: "DashboardBalkendiagramm",
  components: {
    DashboardBalkendiagrammStatistic,
    PuxButton,
    PuxChart,
  },
  mixins: [
    DashboardModuleMixin,
    DashboardStylesMixin,
    HttpMixin,
  ],
  data() {
    return {
      statisticsList: [],
      activeStatisticIndex: 0,
    };
  },
  computed: {
    activeStatistic() {
      return this.statisticsList[this.activeStatisticIndex];
    },

    parameter() {
      return this.argument.parameter || {};
    },

    isPreviousButtonActive() {
      return this.dataExists && this.activeStatisticIndex > 0;
    },

    isNextButtonActive() {
      return this.dataExists && this.activeStatisticIndex < this.statisticsList.length - 1;
    },

    dataExists() {
      return size(this.data) > 0;
    },

    idForPreviousButton() {
      return `${ this.idLocal }_previous`;
    },

    idForNextButton() {
      return `${ this.idLocal }_next`;
    },

    statisticContainerStyles() {
      const MAX_HEIGHT = this.parameter.max_height;
      if (isNil(MAX_HEIGHT)) {
        return {};
      }
      return {
        maxHeight: MAX_HEIGHT,
        overflowY: "auto"
      };
    },
  },
  watch: {
    parameter() {
      this.init();
    },

    data() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const CONFIG = get(this.parameter, "seiten");
      if (!this.dataExists || size(CONFIG) === 0) {
        this.statisticsList = [];
        return;
      }
      const DATA = cloneDeep(this.data);
      const STATISTICS_LIST = this.buildStatistics(CONFIG, DATA);
      this.statisticsList = STATISTICS_LIST;
      this.setActiveRelevantObjIndex(0);
    },

    buildStatistics(config, data) {
      const STATISTICS_LIST = [];
      if (!config || !data) {
        return STATISTICS_LIST;
      }
      return filter(map(config, (statisticConfig, idx) => {
        const STATISTIC_DATA = data[idx];
        const STATISTIC_ID = `${ this.idLocal }__${ get(statisticConfig, "id") }`;
        return {
          data: STATISTIC_DATA,
          id: STATISTIC_ID,
          titel: get(statisticConfig, "titel"),
          untertitel: get(statisticConfig, "untertitel"),
          beschreibung: get(statisticConfig, "beschreibung"),
          rows: this.buildRows(STATISTIC_ID, statisticConfig, STATISTIC_DATA),
        };
      }), statistic => size(statistic.rows) > 0);
    },

    buildRows(id, statisticConfig, statisticData) {
      return filter(map(statisticData, (row, idx) => {
        const ROW_ID = `${ id }__row_${ idx }`;
        const BAR_CONFIG = get(statisticConfig, "balken", []);
        return {
          data: row,
          id: ROW_ID,
          spacing: get(statisticConfig, "datensatz_spacing", "16px"),
          seperator: get(statisticConfig, "datensatz_seperator", true),
          titel: get(statisticConfig, "datensatz_titel"),
          bars: this.buildBars(ROW_ID, BAR_CONFIG, row),
          isLast: idx === (statisticData.length - 1)
        };
      }), row => size(row.bars) > 0);
    },

    buildBars(rowId, barConfig, row) {
      return map(barConfig, (config, idx) => {
        const BALKEN_ID = `${ rowId }__${ get(config, "id") }`;
        const WERT = get(row, config.wert_field) || 0;
        const REFERENZWERT = get(row, config.referenzwert_field) || 0;
        const PERCENTAGE = REFERENZWERT !== 0 ? WERT / REFERENZWERT * 100 : 100;
        return {
          id: BALKEN_ID,
          data: row,
          value: WERT,
          reference_value: REFERENZWERT,
          percentage: PERCENTAGE,
          label: get(config, "label"),
          text: get(config, "text"),
          hover: get(config, "hover"),
          spacing: get(config, "spacing", "10px"),
          height: get(config, "height", "auto"),
          class_foreground: this.getValueForPercentage("class_foreground", config, PERCENTAGE),
          color_foreground: this.getValueForPercentage("color_foreground", config, PERCENTAGE),
          color_background: this.getValueForPercentage("color_background", config, PERCENTAGE),
          color_text: this.getValueForPercentage("color_text", config, PERCENTAGE),
          isLast: idx === (barConfig.length - 1)
        };
      });
    },

    getValueForPercentage(key, barConfig, percentage) {
      let val = barConfig[key];
      if (isArray(barConfig.color_prozent)) {
        const IDX = findLastIndex(barConfig.color_prozent, colorConfig => {
          return percentage >= toNumber(get(colorConfig, "min", 0));
        });
        if (IDX !== -1) {
          val = get(barConfig.color_prozent[IDX], key, val);
        }
      }
      return val;
    },

    setActiveRelevantObjIndex(index) {
      if (!this.data) {
        return;
      }
      this.activeStatisticIndex = index;
    },

    setActivePrevious() {
      if (!this.isPreviousButtonActive) {
        return;
      }
      this.setActiveRelevantObjIndex(this.activeStatisticIndex - 1);
    },

    setActiveNext() {
      if (!this.isNextButtonActive) {
        return;
      }
      this.setActiveRelevantObjIndex(this.activeStatisticIndex + 1);
    },
  },
};
