"use strict";

import BetragsrechnerRegistry from "dias/kosten_finanzierung/betragsrechner/services";

import MDMKostenMitBegruendungBetragsrechner from "./betragsrechner.js";
import MDMKostenMitBegruendungController from "./controller";

const MdmKostenMitBegruendungModul = angular.module("dias.mdm_kosten_finanzierung.betragsrechner.kosten_mit_begruendung", [
  BetragsrechnerRegistry.name,
]);

MdmKostenMitBegruendungModul.config(/*@ngInject*/ BetragsrechnerRegistryProvider => {
  BetragsrechnerRegistryProvider.register("mdm_kosten_mit_begruendung", MDMKostenMitBegruendungBetragsrechner);
})
  .component("mdmKostenMitBegruendungBetragsrechner", MDMKostenMitBegruendungController);

export default MdmKostenMitBegruendungModul;
