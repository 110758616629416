import FormstepDetail from "../../../../../global/components/FormstepDetail/FormstepDetail.vue";
import Modal from "../../../../../global/components/Modal/Modal.vue";
import ModalCreateOrUpdate from "../ModalCreateOrUpdate/ModalCreateOrUpdate.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";

import { cloneDeep } from
  "lodash-es";

// @vue/component
export default {
  name: "AngebotDetailsBenachrichtigungenElement",
  components: {
    FormstepDetail,
    Modal,
    ModalCreateOrUpdate,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    indexBenachrichtigung: {
      type: Number,
      required: true,
      info: "Benachrichtigung index",
    },
    benachrichtigung: {
      type: Object,
      required: true,
      info: "Benachrichtigung object",
    },
    benachrichtigungenArt: {
      type: Object,
      required: true,
      info: "Katalogdaten benachrichtigungenArt für Select",
    },
    benachrichtigungenTyp: {
      type: Object,
      required: true,
      info: "Katalogdaten benachrichtigungenTyp für Select",
    },
    regelsatzUrl: {
      type: String,
      required: true,
      info: "Url für Regelsatz",
    },
    regelsatzPk: {
      type: String,
      required: false,
      default: undefined,
      info: "Regelsatz UUID",
    },
    statusEditable: {
      type: Boolean,
      required: true,
      info: "EditMode oder ReadOnlyMode"
    },
    close: {
      type: Function,
      default: () => {},
    },
    saveCallBack: {
      type: Function,
      default: () => {},
      required: false,
    }
  },
  data() {
    return {
      modelLocal: {},
      confirmShow: undefined,
      confirmOptions: undefined,
      id: "ui_angebotsdetails_benachrichtigungen_",
      isOpen: {},
      currentModel: undefined,
      currentElementIndex: undefined,
      statusModal: undefined,
    };
  },
  computed: {
    benachrichtigungElementTitel() {
      const kurzBez = this.getKurzbeschreibung(this.benachrichtigung.benachrichtigungsart);
      return `${ this.indexBenachrichtigung + 1 }. ${ this.benachrichtigung.asb_titel } ${ kurzBez }`;
    },

    selectorCloseUpdate() {
      return [
        `${ this.id }_list_item_${ this.indexBenachrichtigung }`,
        `ui_angebotdetails_benachrichtigungen_modalcreateorupdate_create_`
      ];
    },

    selectorCloseDelete() {
      return [
        `${ this.id }_list`,
        `ui_angebot_details_benachrichtigungen_confirm_delete_button_`
      ];
    },
  },
  methods: {
    openModalUpdate({ item }) {
      this.currentModel = cloneDeep(item);
      this.statusModal = true;
    },

    closeModalUpdate() {
      this.statusModal = false;
    },

    toggleButton(pk) {
      this.isOpen[pk] = !this.isOpen[pk];
    },

    getKurzbeschreibung(uuid) {
      if (this.benachrichtigungenTyp[uuid]) {
        return this.benachrichtigungenTyp[uuid].bnt_kbez;
      } else if (this.benachrichtigungenArt[uuid]) {
        return this.benachrichtigungenArt[uuid].kfb_kbez;
      }
    },

    openConfirmDelete({ pk, index }) {
      const msg = "_TXT_ANGEBOTDETAILS_BENACHRICHTIGUNGEN_ELEMENT_DELETE_MODAL_BODY_";
      const okCallback = () => this.deleteBenachrichtigung({ pk, index });
      const EXTRA = {};
      this.confirmOptions = {
        id: "ui_angebot_details_benachrichtigungen_confirm_delete_button_",
        okClass: "btn btn-primary",
        title: "_BTN_ANGEBOTDETAILS_BENACHRICHTIGUNGEN_ELEMENT_DELETE_",
        msg,
        okLabel: "_LBL_ANGEBOT_BENACHRICHTIGUNG_FORTFAHREN_",
        okCallback,
        cancelCallback: this.closeConfirmDelete,
        extra: EXTRA,
        loading: false,
      };
      this.confirmShow = true;
    },

    deleteBenachrichtigung({ pk, index }) {
      this.confirmOptions.loading = true;
      const titel = this.benachrichtigung.asb_titel;
      const url = `${ this.regelsatzUrl }${ pk }/`;
      this.deleteHttp({
        url: url
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_ANGEBOTDETAILS_BENACHRICHTIGUNGEN_ELEMENT_MODAL_DELETE_BENACHRICHTIGUNG_DELETE_SUCCESS_{{titel}}_",
            extra: { titel: titel, },
          });
        },
        () => {
          this.addNotification({
            text: "_MSG_ANGEBOTDETAILS_BENACHRICHTIGUNGEN_ELEMENT_MODAL_DELETE_BENACHRICHTIGUNG_DELETE_ERROR_{{titel}}_",
            type: "error",
            extra: { titel: titel },
          });
        }
      ).finally(
        () => {
          this.saveCallBack({ index: index, statusDelete: true });
          this.confirmOptions.loading = false;
        }
      );
      this.closeConfirmDelete();
    },

    closeConfirmDelete() {
      this.confirmShow = false;
    },
  },
};
