"use strict";

import "restangular";
import angular from "angular";

import CoreModels from "dias/core/models";
import DiasNotification from "dias/core/notification";
import DiasErrors from "dias/core/errors";

const modelsModule = angular.module("sitzungen.sitzungsmodule.models", [
  "restangular",
  CoreModels.name,
  DiasErrors.name,
  DiasNotification.name
]);

export default modelsModule;
