"use strict";

import template from "./navbar.jade";
import NavbarController from "./navbar.controller";


export default {
  template: template(),
  controller: NavbarController,
  controllerAs: "vm",
};
