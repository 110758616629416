"use strict";

import parameterServices from "dias/foerderregeln/services";
import comp from "./config.component";
import rocomp from "./readonly.component";

const parametermodulBewertung = angular.module("dias.bewertungen.parameter.bewertung", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("bewertung", "parametermodul-bewertung");
  })
  .component("parametermodulBewertung", comp)
  .component("parametermodulBewertungRo", rocomp);

export default parametermodulBewertung;
