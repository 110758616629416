import ManuelleAufgabenBoxItem from "../ManuelleAufgabenBoxItem/ManuelleAufgabenBoxItem.vue";

// @vue/component
export default {
  name: "ManuelleAufgabenBox",
  components: {
    ManuelleAufgabenBoxItem,
  },
  props: {
    aufgaben: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  emits: [
    "onAufgabenChanged",
  ],
  data() {
    return {
      id: "manuelle_aufgabe_aside_right_box",
    };
  },
  created() {
    this.onAufgabenChangedLocal();
  },
  methods: {
    onAufgabenChangedLocal() {
      this.$emit("onAufgabenChanged");
    },
  },
};
