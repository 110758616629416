import {
  computed,
  inject,
  ref,
  toRef,
} from "vue";

import HttpAPI from "../../../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../../../global/compositionAPI/NotificationAPI";

import {
  gettext,
  replaceText,
} from "../../../../../../global/functions/utils";
import {
  cloneDeep,
  forEach,
  isArray,
  isPlainObject,
} from "lodash-es";

export default function SitzungsartTagesordnungModalAPI(props, {
  loadTopModules = () => {},
  module = ref([]),
  tagesordnungspunktUrl = computed(() => ""),
}) {
  const buttonId = toRef(props, "buttonId");
  const selectorClose = computed(() => {
    return `#${ buttonId.value }`;
  });

  const isModalVisible = ref(false);
  const openModal = () => {
    isModalVisible.value = true;
    loadTopModules();
  };
  const closeModal = () => {
    isModalVisible.value = false;
  };

  const errors = ref(undefined);
  const onError = (errorsLocal, data) => {
    const ERRORS = errorsLocal.data || {};
    if (isArray(ERRORS.module)) {
      const modulErrors = [];
      forEach(ERRORS.module, (error, index) => {
        if (isPlainObject(error) && Object.keys(error).length > 0 && data.module[index]) {
          const ERROR_TEXT = replaceText({
            text: gettext("_TXT_SITZUNGSART_TAGESORDNUNGSPUNKT_SAVE_ERROR_{{number}}_{{tom_kbez}}_"),
            object: {
              number: index + 1,
              tom_kbez: data.module[index].tom_kbez,
            }
          });
          modulErrors.push(ERROR_TEXT);
        }
      });
      ERRORS.module = modulErrors;
    }
    errors.value = ERRORS;
  };

  const tagesordnungspunkt = toRef(props, "tagesordnungspunkt");
  const getDataForSave = () => {
    const DATA = cloneDeep(tagesordnungspunkt.value);
    const MODULE = cloneDeep(module.value);
    forEach(MODULE, (modul, index) => {
      modul.tom_pos = index;
      modul.top = DATA.top;
    });
    DATA.module = MODULE;
    return DATA;
  };
  const {
    putHttp,
  } = HttpAPI();
  const {
    addNotification,
  } = NotificationAPI();
  const updateTagesordnungspunkt = inject("updateTagesordnungspunkt");
  const number = toRef(props, "number");
  const save = () => {
    const DATA = getDataForSave();
    putHttp({
      url: tagesordnungspunktUrl.value,
      data: DATA,
    }).then(
      response => {
        addNotification({
          text: "_MSG_SITZUNGSART_TAGESORDNUNGSPUNKT_SAVE_SUCCESS_{{number}}_{{to_bez}}_",
          extra: {
            number: number.value,
            to_bez: tagesordnungspunkt.value.top_obj.to_bez,
          },
        });
        closeModal();
        updateTagesordnungspunkt({
          tagesordnungspunkt: response,
          tagesordnungspunktIndex: toRef(props, "tagesordnungspunktIndex").value,
          tagesordnungspunktParentIndex: toRef(props, "tagesordnungspunktParentIndex").value,
        });
      },
      error => {
        onError(error, DATA);
      }
    );
  };
  const optionsModal = {
    required: true,
    showCloseButton: true,
  };
  const loading = ref(false);
  const optionsToBez = {
    type: "text",
    label: "_TXT_SITZUNGSART_TAGESORDNUNGSPUNKT_NAME_",
    id: "to_bez",
    showLabel: true,
  };
  const optionsPos = {
    type: "text",
    label: "_TXT_SITZUNGSART_TAGESORDNUNGSPUNKT_POSITION_",
    id: "sto_pos",
    showLabel: true,
  };
  const optionsStoInfo = {
    type: "text",
    label: "_TXT_SITZUNGSART_TAGESORDNUNGSPUNKT_STO_INFO_",
    id: "sto_info",
    showLabel: true,
  };
  const errorsLabelsOptions = computed(() => {
    return [
      optionsToBez,
      optionsPos,
      optionsStoInfo,
    ];
  });

  return {
    closeModal,
    errors,
    errorsLabelsOptions,
    isModalVisible,
    loading,
    openModal,
    optionsModal,
    optionsPos,
    optionsToBez,
    optionsStoInfo,
    save,
    selectorClose,
  };
}
