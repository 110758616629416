import translate from "../../../../../global/directives/translate";
import {
  FilterCurrencyMixin,
  FilterDateMixin,
} from "../../../../../global/mixins/FiltersMixin";
import {
  getTitleBetragAnerkannt,
} from "../../../../../const/KVertragskostenbelegStatusUUIDs";

// @vue/component
export default {
  name: "BelegeElementInListe",
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterDateMixin,
  ],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    translateOptions() {
      return {
        text: this.placeholder,
        title: this.placeholder,
        extra: {
          wert: this.filterCurrency(this.item.argument_anerkannt.wert || this.item.argument_erfasst.wert),
          von: this.filterDate(this.item.argument_anerkannt.von || this.item.argument_erfasst.von),
          bis: this.filterDate(this.item.argument_anerkannt.bis || this.item.argument_erfasst.bis),
        },
      };
    },

    placeholder() {
      const BELEG_STATUS_PLACEHOLDER = getTitleBetragAnerkannt({ beleg: this.item });
      return `_TXT_VERTRAG_DETAILS_PERSONALBAUM_BELEGE_MODAL_ELEMENT_IN_LISTE${ BELEG_STATUS_PLACEHOLDER }{{wert}}_{{von}}_{{bis}}_`;
    },
  },
};
