"use strict";

import angular from "angular";

import uiRouter from "angular-ui-router";

import coreModal from "dias/core/modal";
import coreTable from "dias/core/table";
import coreLoading from "dias/core/loading";
import coreModels from "dias/core/models";

import models from "./models";

import routenComponent from "./teamrouting.component";
import routenDetailComponent from "./teamrouting.detail.component";

const teamsModule = angular.module("teams", [
  uiRouter,
  coreModal.name,
  coreTable.name,
  coreLoading.name,
  coreModels.name,
  models.name,
]);

teamsModule.config($stateProvider => {
  $stateProvider

    .state("root.teams", {
      url: `/teams/?
            user_cookie
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-teams-list></vue-teams-list></div>"
        }
      },
      data: {
        permissions: ["teammitglied.create"]
      }
    })

    .state("root.teams.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><teams-details></teams-details></div>"
        }
      },
      data: {
        permissions: ["teammitglied.create"]
      }
    })

    .state("root.routen", {
      url: "/teamsteuerung/",
      views: {
        "main@root": {
          template: "<routen></routen>"
        }
      },
      data: {
        permissions: ["teamrouting.view"]
      }
    })
  ;
})

  .component("routen", routenComponent)
  .component("routenDetail", routenDetailComponent)

;

export default teamsModule;
