import HttpMixin from "../../../../global/mixins/HttpMixin";
import {
  FilterCurrencyMixin,
} from "../../../../global/mixins/FiltersMixin";

import {
  gettext,
  replaceText,
} from "../../../../global/functions/utils";

// @vue/component
export default {
  mixins: [
    FilterCurrencyMixin,
    HttpMixin,
  ],
  emits: [
    "updateValue",
  ],
  data() {
    return {
      personalfunktionen: [],
    };
  },
  computed: {
    optionsVollzeitstelle() {
      const ID = "vollzeitstelle";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_PERSONALKOSTEN_MIT_PKT_VOLLZEITSTELLE_",
        view: this.view,
        type: "float",
        addonBack: "_TXT_HOURS_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    optionsStellenbezeichnung() {
      const ID = "stellenbezeichnung";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_PERSONALKOSTEN_MIT_PKT_STELLENBEZEICHNUNG_",
        view: this.view,
        type: "text",
        maxlength: 300,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    optionsName() {
      const ID = "name";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_PERSONALKOSTEN_MIT_PKT_NAME_",
        view: this.view,
        type: "text",
        maxlength: 300,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    optionsEinwilligung() {
      const ID = "einwilligung";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_PERSONALKOSTEN_MIT_PKT_EINWILLIGUNG_",
        view: this.view,
        type: "one_checkbox",
        required: false,
        disabled: this.statusPruefer,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    optionsPersonalstelle() {
      const ID = "personalstelle";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_PERSONALKOSTEN_MIT_PKT_PERSONALSTELLE_",
        view: this.view,
        type: "select",
        keyLabel: "ps_kbez",
        keyId: "pk",
        search: true,
        searchList: ["ps_kbez"],
        required: true,
        url: "personalstellen/",
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    optionsBeschaeftigtVon() {
      const ID = "beschaeftigt_von";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_PERSONALKOSTEN_MIT_PKT_BESCHAEFTIGT_VON_",
        view: this.view,
        type: "date",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    optionsBeschaeftigtBis() {
      const ID = "beschaeftigt_bis";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_PERSONALKOSTEN_MIT_PKT_BESCHAEFTIGT_BIS_",
        view: this.view,
        type: "date",
        required: false,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    optionsPersonalfunktion() {
      const ID = "personalfunktion";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_PERSONALKOSTEN_MIT_PKT_PERSONALFUNKTION_",
        view: this.view,
        type: "select",
        keyLabel: "pf_kbez",
        keyId: "pk",
        search: true,
        searchList: ["pf_kbez"],
        required: true,
        data: this.personalfunktionen,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    optionsStellendauer() {
      const ID = "stellendauer";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_PERSONALKOSTEN_MIT_PKT_STELLENDAUER_",
        view: this.view,
        type: "float",
        addonBack: "_TXT_MONTHS_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    optionsStellenumfang() {
      const ID = "stellenumfang";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_PERSONALKOSTEN_MIT_PKT_STELLENUMFANG_",
        view: this.view,
        type: "float",
        addonBack: "_TXT_HOURS_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    optionsStellenumfangProjekt() {
      const ID = "stellenumfang_projekt";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_PERSONALKOSTEN_MIT_PKT_STELLENUMFANG_PROJEKT_",
        view: this.view,
        type: "float",
        addonBack: "_TXT_HOURS_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    optionsMonatsgehalt() {
      const ID = "monatsgehalt";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_PERSONALKOSTEN_MIT_PKT_MONATSGEHALT_",
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 14,
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    optionsEinmalzahlung() {
      const ID = "einmalzahlung";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_PERSONALKOSTEN_MIT_PKT_EINMALZAHLUNG_",
        view: this.view,
        type: "float",
        addonBack: "_HTML_SUFFIX_EURO_",
        maxlength: 14,
        required: false,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    optionsVerguetungsgruppeBez() {
      const ID = "verguetungsgruppe_bez";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_PERSONALKOSTEN_MIT_PKT_VERGUETUNGSGRUPPE_",
        view: this.view,
        type: "text",
        required: false,
        disabled: true,
        helpText: this.helpTextForVerguetungsgruppeBez,
        classesHorizontal: this.classesHorizontal,
      };
    },

    helpTextForVerguetungsgruppeBez() {
      let helpText = "";
      if (this.vorschau.bruttojahresgehalt) {
        helpText = replaceText({
          text: gettext(`_TXT_PERSONALKOSTEN_MIT_PKT_HELP_TEXT_BRUTTOJAHRESGEHALT_{{bruttojahresgehalt}}_`),
          object: {
            bruttojahresgehalt: this.filterCurrency(this.vorschau.bruttojahresgehalt || 0),
          },
        });
        if (this.vorschau.arbeitgeberpauschale) {
          helpText += gettext(`_TXT_PERSONALKOSTEN_MIT_PKT_HELP_TEXT_BRUTTOJAHRESGEHALT_UND_ARBEITGEBERPAUSCHALE_`);
        }
      }
      if (this.vorschau.arbeitgeberpauschale) {
        helpText += replaceText({
          text: gettext(`_TXT_PERSONALKOSTEN_MIT_PKT_HELP_TEXT_ARBEITGEBERPAUSCHALE_{{arbeitgeberpauschale}}_`),
          object: {
            arbeitgeberpauschale: this.filterCurrency(this.vorschau.arbeitgeberpauschale || 0, "%"),
          },
        });
      }
      if (!this.vorschau.bruttojahresgehalt && !this.vorschau.arbeitgeberpauschale) {
        helpText = "-";
      }
      return helpText;
    },

    optionsDokumentGeschwaerzt() {
      const ID = "dokument_geschwaerzt";
      return {
        id: ID,
        htmlId: `${ this.htmlId }_${ ID }`,
        label: "_LBL_VERTRAG_DETAILS_BELEG_MODAL_CONFIRMATION_",
        view: this.view,
        type: "one_checkbox",
        required: false,
        disabled: this.statusPruefer,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },
  },
  created() {
    this.initValues();
  },
  methods: {
    initValues() {
      this.getHttp({
        url: this.getUrlPkt(),
      }).then(
        response => {
          this.personalfunktionen = response.personalfunktionen;
          if (!this.extra.pkt_id) {
            this.$emit("updateValue", { pkt_id: response.pkt_id });
          }
          this.updateModelWithPktId(response.pkt_id);
        }
      );
    },

    getUrlPkt() {
      // Wenn das Objekt (Antragskostenpos oder Beleg zu Vertragskostenpos)
      // bereits existiert, sollte die PKT_ID bereits da sein,
      // da sie beim Anlegen mit abgelegt wird:
      let pkt_id = null;
      if (this.model.argument) {
        pkt_id = this.model.argument.pkt_id;
      }
      // Wenn das Objekt neu ist, dann kommt die PKT-ID bei Belegen aus den Daten
      // der Antragskosten:
      if (!pkt_id && this.extra.argument && this.extra.argument.pkt_id) {
        pkt_id = this.extra.argument.pkt_id;
      }
      if (pkt_id) {
        return `personalkostentabellen/${ pkt_id }/`;
      }
      // Wenn die Personalkostentabelle bis jetzt nicht ermittelt werden konnte
      // z.B. bei neuen Antragskosten oder bei neuen Belegen zu neuen
      // Vertragspositionen, dann muss die für den Antrag gültigte Pkt
      // ermittelt werden:

      const ANTRAG_ID = this.extra.regel.afr_aid;
      const PERSONALKOSTETYP_ID = this.extra.modelParameter.personalkostentyp;
      return `personalkostentypen/${ PERSONALKOSTETYP_ID }/get_gueltige_pkt/?a_id=${ ANTRAG_ID }`;
    },

    updateModelWithPktId() {
      // mock
    },
  },
};
