import {
  getCurrentInstance,
  ref,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

export default function SitzungsartAPI() {
  const APP = getCurrentInstance();
  const $stateParams = APP.appContext.config.globalProperties.$stateParams;
  const $goTo = APP.appContext.config.globalProperties.$goTo;

  const sitzungsart = ref({});
  const loadingSitzungsart = ref(true);

  const sitzungsartUrl = `sitzungsarten/${ $stateParams.id }/`;

  const {
    getHttp,
  } = HttpAPI();
  const {
    addNotification,
  } = NotificationAPI();
  const loadSitzungsart = () => {
    return getHttp({
      url: sitzungsartUrl,
    }).then(
      response => {
        sitzungsart.value = response;
        loadingSitzungsart.value = false;
      },
      () => {
        addNotification({
          text: "_MSG_SITZUNGSART_LADEN_ERROR_",
          type: "error",
        });
        $goTo("root.sitzungsarten");
      }
    );
  };

  return {
    loadingSitzungsart,
    loadSitzungsart,
    sitzungsart,
    sitzungsartUrl,
  };
}
