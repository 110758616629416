"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";


class WorkflowfolgenEditBeschreibungController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, ParameterUtils, DiasNotification, i18n) {
    super($q, $scope, DiasNotification);
    this.ParameterUtils = ParameterUtils;
    this.gettext = i18n.gettext;
    this.initForm();
  }

  _save() {
    const data = this.ParameterUtils.to_request_body(this.formModel, null, "");
    Object.assign(this.model, data, {});
    return this.model.patch(data).then(
      response => {
        this.$scope.model.merge(this.$scope.model, response.plain());
        this.DiasNotification.page.success("Änderungen erfolgreich gespeichert");
      },
      errors => this.onErrors(errors, this.errorLabels)
    );
  }

  onEnterEditMode() {
    super.onEnterEditMode();
    this.updateFormModel();
  }

  updateFormModel() {
    if (this.ParameterUtils && this.formModelTemplate && this.model) {
      this.formModel = this.ParameterUtils.params_from_template(this.formModelTemplate, this.model);
    }
  }

  initForm() {
    this.formModelTemplate = this.ParameterUtils.init_parameter_in({
      wft_kbez: {
        bez: this.gettext("Name"),
        typ: "string",
        prio: 1,
        options: {
          required: true
        }
      },
      wft_bez: {
        bez: this.gettext("Langbezeichnung"),
        typ: "string",
        prio: 2,
        options: {
          required: true
        }
      },
    });
    this.form_keys = this.ParameterUtils.get_ordered_keys(this.formModelTemplate);
    this.errorLabels = {};
    this.form_keys.forEach(
      k => {
        this.errorLabels[k] = `${ this.formModelTemplate[k].bez }: `;
      }
    );
    this.updateFormModel();
  }
}

export default WorkflowfolgenEditBeschreibungController;
