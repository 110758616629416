import {
  computed,
  getCurrentInstance,
  ref,
} from "vue";

import HttpAPI from "../../../../../libs/vue/client/vue/global/compositionAPI/HttpAPI";

import moment from "moment";
import VersionStatus from "../const/VersionStatus";
import {
  compact,
  find,
  forEach,
  get,
  includes,
  orderBy,
  size,
} from "lodash-es";

export default function AkkreditierungenAPI({
  baseUrl,
  akkreditierungHistoriePk,
}) {
  const {
    addNotification,
    getHttp,
    postHttp,
  } = HttpAPI();

  const APP = getCurrentInstance();
  const $goTo = APP.appContext.config.globalProperties.$goTo;
  const akkreditierungen = ref([]);
  const antragShortcuts = ref([]);
  const letzteAkkreditierung = ref({
    studiengang_obj: {},
  });
  const letzteAkkreditierungGueltig = ref({});
  const versionen = ref({});
  const gutachtenTauschenAkkr = ref(null);
  const auflagenerfuellungAkkr = ref(null);
  const programmakkreditierungHistorieUrl = computed(() => {
    return `${ baseUrl }/${ akkreditierungHistoriePk }/historie/`;
  });
  const antragErstellenAktionenUrl = computed(() => {
    return `${ baseUrl }/${ akkreditierungHistoriePk }/foerderantrag_erstellen_optionen/`;
  });
  const letzteAkkreditierungGueltigFoerderantragErstellenUrl = computed(() => {
    return `${ baseUrl }/${ letzteAkkreditierungGueltig.value.pk }/foerderantrag_erstellen/`;
  });
  const antragShortcutsLabel = computed(() => {
    return get(antragShortcuts.value, "[0].label", "");
  });
  const pageTitleExtra = computed(() => {
    let pageTitleObj;

    if (baseUrl === "programmakkreditierungen") {
      const studiengangSgBez = get(letzteAkkreditierung.value, "studiengang_obj.sg_bez");
      const studiengangAbschlussbezeichnungBez = get(letzteAkkreditierung.value, "studiengang_obj.abschlussbezeichnung_bez");
      const studiengangHsBez = get(letzteAkkreditierung.value, "studiengang_obj.hochschule_obj.hs_bez");
      pageTitleObj = {
        sg_bez: studiengangSgBez,
        abschluss_bez: studiengangAbschlussbezeichnungBez,
        hs_bez: studiengangHsBez,
      };
    } else {
      const hochschuleHsBez = get(letzteAkkreditierung.value, "hochschule_obj.hs_bez");
      pageTitleObj = {
        hs_bez: hochschuleHsBez,
      };
    }

    return pageTitleObj;
  });
  const programmakkreditierungTitlePrimary = computed(() => {
    const {
      sg_bez,
      abschluss_bez,
    } = pageTitleExtra.value;
    const labelsReady = sg_bez && abschluss_bez;

    return labelsReady ? `${ sg_bez } (${ abschluss_bez })` : "";
  });
  const programmakkreditierungTitleSecondary = computed(() => {
    const {
      hs_bez,
    } = pageTitleExtra.value;

    return hs_bez || "";
  });
  const laufendeVerfahren = computed(() => {
    return get(letzteAkkreditierungGueltig.value, "laufende_verfahren", []);
  });
  const hasLaufendeVerfahren = computed(() => {
    return laufendeVerfahren.value.length > 0;
  });
  const laufendesVerfahrenAntragTitle = computed(() => {
    const antragNr = get(akkreditierungen.value, "[0].laufende_verfahren[0].antrag_nr");

    return compact(["Laufendes Verfahren: Antrag", antragNr]).join(" ");
  });
  const showLaufendeVerfahren = computed(() => {
    return hasLaufendeVerfahren.value && letzteAkkreditierungGueltig.value.is_visible;
  });
  const showAntragShortcuts = computed(() => {
    return antragShortcuts.value.length && !hasLaufendeVerfahren.value && letzteAkkreditierungGueltig.value.is_visible;
  });
  const filterAkkreditierungenByUrsprung = response => {
    const verarbeitet = [];
    const sortFields = baseUrl === "programmakkreditierung"
      ? {
        von: "pro_von",
        bis: "pro_bis",
      }
      : {
        von: "aks_von",
        bis: "aks_bis",
      };

    forEach(response, responseItem => {
      // Hier kommen eventuell mehrere pro Usprung zurück, die brauchen
      // dann nicht nochmal berücksichtigt werden, wenn der Ursprung
      // schon verarbeitet wurde:
      if (!includes(verarbeitet, responseItem.ursprung)) {
        akkreditierungen.value.push(responseItem);
        verarbeitet.push(responseItem.ursprung);
      }

      akkreditierungen.value = orderBy(akkreditierungen.value, [sortFields.bis, sortFields.von], ["desc", "desc"]);
    });
  };
  const findLetzteAkkreditierungGueltig = () => {
    const now = moment().format("YYYY-MM-DD");

    letzteAkkreditierungGueltig.value = find(akkreditierungen.value, akkr => {
      return akkr.pro_gueltig_von <= now && (akkr.pro_gueltig_bis >= now || akkr.pro_gueltig_bis === null);
    });
  };
  const loadAkkrhistorie = async() => {
    try {
      const response = await getHttp({
        url: programmakkreditierungHistorieUrl.value,
      });

      filterAkkreditierungenByUrsprung(response);
      if (baseUrl === "programmakkreditierungen") {
        findLetzteAkkreditierungGueltig();
      }
    } catch (e) {
      console.error(e);
      addNotification({
        text: "Fehler API",
        type: "error",
      });
    }
  };
  const loadAntragErstellenAktionen = async() => {
    try {
      antragShortcuts.value = await getHttp({
        url: antragErstellenAktionenUrl.value,
      });
    } catch (e) {
      console.error(e);
      addNotification({
        text: "Fehler API",
        type: "error",
      });
    }
  };
  const findLetzteAkkreditierung = () => {
    letzteAkkreditierung.value = find(versionen.value[akkreditierungen.value[0].ursprung], { versionstatus: VersionStatus.AKTUELL })
      || akkreditierungen.value[0];
  };
  const assembleVersionen = (versionenToAdd, ursprung) => {
    versionen.value[ursprung] = versionenToAdd;

    if (size(versionen.value) === size(akkreditierungen.value)) {
      findLetzteAkkreditierung();
    }
  };
  const executeAktion = async aktion => {
    try {
      const response = await postHttp({
        url: letzteAkkreditierungGueltigFoerderantragErstellenUrl.value,
        data: aktion,
      });

      addNotification({ text: "Aktion erfolgreich ausgeführt" });
      $goTo("root.foerderantrag.details", { id: response.a_id });
    } catch (err) {
      console.error(err);
      addNotification({
        text: "Fehler beim Ausführen der Aktion",
        type: "error",
      });
    }
  };
  const executeAntragErstellenAktionen = async(aktion = antragShortcuts.value[0]) => {
    try {
      if (!letzteAkkreditierungGueltig.value) {
        addNotification({
          text: "Fehler beim Ausführen der Aktion, da kein gültiger Akkreditierungsdatensatz vorliegt.",
          type: "error",
        });
      } else {
        await executeAktion(aktion);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const openLaufendesVerfahrenAntrag = () => {
    const idParam = get(letzteAkkreditierungGueltig.value, "laufende_verfahren[0].antrag");

    if (idParam) {
      $goTo("root.foerderantrag.details", { id: idParam });
    }
  };
  const gutachtenAustauschenDialogschliessen = () => {
    gutachtenTauschenAkkr.value = null;
  };
  const gutachtenAustauschenDialogoeffnen = akkr => {
    gutachtenTauschenAkkr.value = akkr;
  };
  const auflagenerfuellungBearbeitenClose = () => {
    auflagenerfuellungAkkr.value = null;
  };
  const auflagenerfuellungBearbeiten = akkr => {
    auflagenerfuellungAkkr.value = akkr;
  };

  return {
    akkreditierungen,
    antragShortcutsLabel,
    antragShortcuts,
    auflagenerfuellungAkkr,
    auflagenerfuellungBearbeiten,
    auflagenerfuellungBearbeitenClose,
    assembleVersionen,
    executeAntragErstellenAktionen,
    gutachtenAustauschenDialogoeffnen,
    gutachtenAustauschenDialogschliessen,
    gutachtenTauschenAkkr,
    hasLaufendeVerfahren,
    laufendeVerfahren,
    laufendesVerfahrenAntragTitle,
    letzteAkkreditierung,
    letzteAkkreditierungGueltig,
    loadAkkrhistorie,
    loadAntragErstellenAktionen,
    openLaufendesVerfahrenAntrag,
    pageTitleExtra,
    programmakkreditierungTitlePrimary,
    programmakkreditierungTitleSecondary,
    showAntragShortcuts,
    showLaufendeVerfahren,
  };
}
