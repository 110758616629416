"use strict";

import template from "./table.dnd.jade";
import controller from "./table.dnd.controller";

const nutzerverwaltungComponent = function() {
  return {
    restrict: "EA",
    scope: {
      config: "="
    },
    template,
    controller,
    controllerAs: "vm",
    bindToController: true
  };
};

export default nutzerverwaltungComponent;
