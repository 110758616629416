var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["foerdermodul"] = jade_interp = function(idx, title, info, diffBlock){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if ( info === undefined)
{
var info = "vm.modul.regel[vm.modul.prefix + '_astbez']"
}
var rulesKbez = "vm.modul.regel[vm.modul.prefix + '_kbez']"
var rulesBez = "vm.modul.regel[vm.modul.prefix + '_bezeichnung']"
buf.push("<div" + (jade.attr("ng-class", "{'dirty': !vm.isClean(), 'edit': vm.editMode, 'version-detail': " + (diffBlock) + ", 'version-loading': vm.loadingDiff}", true, true)) + " class=\"form-step-detail\"><dias-info-box" + (jade.attr("idx", "" + (idx) + "", true, true)) + (jade.attr("name", "" + (title) + "", true, true)) + (jade.attr("info-text", "" + (info) + "", true, true)) + (jade.attr("rules-kbez", "" + (rulesKbez) + "", true, true)) + (jade.attr("rules-bez", "" + (rulesBez) + "", true, true)) + " disabled=\"vm.loading\" is-readonly=\"vm.isReadonly()\" edit-mode=\"vm.editMode\" toggle-edit-mode=\"vm.toggleEditMode()\" is-missing-input=\"vm.isMissingInput()\" notizen=\"vm.notizen\" notiz-obj=\"vm.obj\" notizoptions=\"vm.notizoptions\" notizkey=\"vm.notizkey\" notiz-weitere-obj=\"[vm.modul.regel]\" tealium-track-info=\"vm.modul.regel.regel_nummer\" regel=\"vm.modul.regel\"></dias-info-box><form ng-submit=\"vm.saveChanges()\" name=\"{{:: vm.getFormName()}}\" novalidate dias-loading=\"vm.loading\" loading-msg=\"'Daten werden gespeichert'\" class=\"form-horizontal\">");
block && block();
buf.push("</form></div>");
};













jade_mixins["readonly-block"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div ng-if=\"vm.isReadonly() || !vm.globalBearbeiten\" ng-show=\"!vm.editMode\" ng-class=\"{'clearfix-hover' : vm.hoverPosition}\" class=\"clearfix box_hover\">");
block && block();
buf.push("</div><div ng-if=\"!vm.isReadonly() &amp;&amp; vm.globalBearbeiten\" ng-show=\"!vm.editMode\" ng-click=\"!vm.isReadonly() &amp;&amp; vm.globalBearbeiten ? vm.toggleEditMode() : null\" data-toggle=\"{{!vm.isReadonly() &amp;&amp; vm.globalBearbeiten ? 'edit' : ''}}\" ng-class=\"{ 'editable' : !vm.isReadonly() &amp;&amp; vm.globalBearbeiten }\" tabindex=\"-1\" class=\"clearfix box_hover\">");
block && block();
buf.push("</div>");
};





































jade_mixins["foerdermodul"].call({
block: function(){
jade_mixins["readonly-block"].call({
block: function(){
buf.push("<fieldset><legend>Rahmenbedingungen</legend><div><label><span class=\"titel\">Maximale Anzahl Auszahlungen</span><div class=\"form-control-static\">{{ vm.parameter.max_anzahl }}</div></label></div><div><label><span class=\"title\">Bis Zuschusshöhe</span><div class=\"form-control-static\">{{ vm.parameter.max_zuschuss }}</div></label></div></fieldset><fieldset><legend>Auszahlungsmodularitäten</legend><parametermodule parameter=\"vm.parameter\" key=\"'auszahlungen'\" readonly=\"true\"></parametermodule></fieldset>");
}
});
}
}, "vm.idx", "'Auszahlungsplan'", undefined, undefined);;return buf.join("");
}