import Bowser from "bowser";
import {
  getHttp
} from "../mixins/HttpMixin";
import {
  isEmpty,
  lowerCase,
} from "lodash-es";

export function initApp(callback) {
  getHttp({
    url: "konfigurationen/browser/",
  }).then(
    response => {
      checkBrowser(response.data, callback);
    },
    () => {
      callback();
    }
  );
}

function checkBrowser(browserObj, callback) {
  const BROWSERS_CONFIG = browserObj.syc_wert && browserObj.syc_wert.browsers;
  const BROWSERS_TEXT = browserObj.syc_wert && browserObj.syc_wert.browsers_text;
  const BROWSER_INFO = Bowser.parse(window.navigator.userAgent);
  const BROWSER_NAME = lowerCase(BROWSER_INFO.browser.name);
  if (!BROWSERS_CONFIG || isEmpty(BROWSERS_CONFIG) || !BROWSERS_CONFIG[BROWSER_NAME]) {
    callback();
    return;
  }
  const BROWSER = Bowser.getParser(window.navigator.userAgent);
  const STATUS_VALID_BROWSER = BROWSER.satisfies(BROWSERS_CONFIG);
  if (STATUS_VALID_BROWSER === false) {
    addBrowsersTextInBody(BROWSERS_TEXT);
  } else {
    callback();
  }
}


function addBrowsersTextInBody(text) {
  const TEMP_DIV = document.createElement("div");
  TEMP_DIV.innerHTML = `<div class="d-inline-block" style="max-width: 500px;"><h2>Der Browser wird nicht unterstützt.<br>The browser is not supported.</h2>${ text }</div>`;
  TEMP_DIV.className = "text-center position-absolute";
  TEMP_DIV.style.left = "50%";
  TEMP_DIV.style.top = "50%";
  TEMP_DIV.style.transform = "translateX(-50%) translateY(-50%)";

  document.body.insertAdjacentElement("afterbegin", TEMP_DIV);
  const APP_ELEMENT = document.querySelector("[ng-app]");
  if (APP_ELEMENT) {
    document.body.removeChild(APP_ELEMENT);
  }
  document.body.removeAttribute("ng-cloak");
}
