"use strict";

class FoerderfinderantwortModalCreateController {
  /*@ngInject*/
  constructor($q, $state, $stateParams, Foerderfinderfragen, Foerderfinderantworttypen, DiasNotification) {
    this.DiasNotification = DiasNotification;
    this.$stateParams = $stateParams;
    this.antwort = {};
    this.errors = {};
    Foerderfinderantworttypen.getList().then(result => {
      this.antworttypen = result;
    });
    Foerderfinderfragen.getList().then(result => {
      this.folgefragen = result;
    });

    this.create = () => {
      if (!this.antwort.folgefrage) {
        this.antwort.folgefrage = null;
      }
      return Foerderfinderfragen.one($stateParams.id).antworten.post(this.antwort).then(
        response => {
          DiasNotification.page.success("Förderfinderantwort erfolgreich erstellt");
          $state.go(
            "root.foerderfinderfragen.detail.antwort",
            { id: response.frage, ffa_id: response.pk }
          );
        },
        error => {
          const errorsLabel = {
            ffa_text: "Text: ",
            ffa_ueberschrift: "Überschrift: ",
            ffa_info: "Info: ",
            ffa_erklaerung: "Erklärung: ",
            typ: "Antworttyp: ",
            folgefrage: "Folgefrage: ",
            ffa_pos: "Position: ",


          };
          DiasNotification.form.clear();
          DiasNotification.form.error(error.data, "Ihre Daten sind fehlerhaft oder unvollständig.", {}, errorsLabel);
          this.errors = error.data;
          return $q.reject(error);
        }
      );
    };
  }
}
export default FoerderfinderantwortModalCreateController;
