"use strict";

import angular from "angular";

import SyConfigs from "./syconfigs.service";
import ProandiScroll from "./scroll.service";
import ParameterUtils from "./parameterUtils.service";

const modelsModule = angular.module("core.services", [
])

  .provider("SyConfigs", SyConfigs)
  .service("ParameterUtils", ParameterUtils)
  .service("ProandiScroll", ProandiScroll)
;

export default modelsModule;
