import AnsprechpartnerModalZuweisen from "../AnsprechpartnerModalZuweisen/AnsprechpartnerModalZuweisen.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "AnsprechpartnerAssignButtonModal",
  components: {
    AnsprechpartnerModalZuweisen,
    PuxButton,
  },
  inject: [
    "updateAnsprechpartner",
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    ansprechpartner: {
      type: Object,
      required: true,
    },
    antragPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isModalAssignVisible: false,
    };
  },
  computed: {
    selectorClose() {
      return `#${ this.id }`;
    },
  },
  methods: {
    openModalAssignAnsprechpartner() {
      this.isModalAssignVisible = true;
    },

    closeModalAssignAnsprechpartner({ response } = {}) {
      if (response) {
        this.updateAnsprechpartner({
          ansprechpartner: response.foerderorg_ansprechpartner,
        });
      }
      this.isModalAssignVisible = false;
    },
  },
};
