"use strict";

import angular from "angular";

import FoerderantragquelleUUIDs from "./foerderantragquelleUUIDs.service";

const modelsModule = angular.module("foerderantrag.services", [
])

  .constant("FoerderantragquelleUUIDs", FoerderantragquelleUUIDs)
;

export default modelsModule;
