import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxGet from "../../../../../global/components/PuxGet/PuxGet.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import PermissionAPI from "../../../../../global/compositionAPI/PermissionAPI";

// @vue/component
export default {
  name: "WorkflowtypDetailsBeschreibungWorkflows",
  components: {
    AngularLink,
    PuxGet,
    PuxTranslate,
  },
  props: {
    workflowObjects: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      checkPermissionsSync,
    };
  },
  computed: {
    hasPermissionWorkflowUi() {
      return this.checkPermissionsSync({ perm: "workflow.ui" });
    },
  },
};
