import PuxAlert from "../../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import VertragWizardFoerdervertragsbestaetigungErrorsItem from "../VertragWizardFoerdervertragsbestaetigungErrorsItem/VertragWizardFoerdervertragsbestaetigungErrorsItem.vue";

// @vue/component
export default {
  name: "VertragWizardFoerdervertragsbestaetigungErrors",
  components: {
    PuxAlert,
    PuxTranslate,
    VertragWizardFoerdervertragsbestaetigungErrorsItem,
  },
  props: {
    validationErrors: {
      type: Array,
      required: true,
    },
    showErrorModulName: {
      type: Function,
      required: true,
    },
  },
  computed: {
    isVisible() {
      return this.validationErrors.length > 0;
    },
  },
};
