import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import VertragOverviewAnsprechpartner from "./VertragOverviewAnsprechpartner/VertragOverviewAnsprechpartner.vue";
import VertragOverviewAntrag from "./VertragOverviewAntrag/VertragOverviewAntrag.vue";
import VertragOverviewFoerderung from "./VertragOverviewFoerderung/VertragOverviewFoerderung.vue";
import VertragOverviewVertrag from "./VertragOverviewVertrag/VertragOverviewVertrag.vue";
import VertragOverviewVertragspartner from "./VertragOverviewVertragspartner/VertragOverviewVertragspartner.vue";

// @vue/component
export default {
  name: "VertragOverview",
  components: {
    FormstepDetail,
    VertragOverviewAnsprechpartner,
    VertragOverviewAntrag,
    VertragOverviewFoerderung,
    VertragOverviewVertrag,
    VertragOverviewVertragspartner,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    vertrag: {
      type: Object,
      required: true,
    },
    auszahlungen: {
      type: Array,
      required: false,
      default: () => [],
    },
    canViewBetrag: {
      type: Boolean,
      required: true,
    },
    canAntragAnsprechpartnerAendern: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  data() {
    return {
      optionsForFormstepDetails: {
        hideHeader: true,
      },
    };
  },
};
