import {
  getCurrentInstance,
  ref,
  toRef,
} from "vue";

import Modal from "../../../../global/components/Modal/Modal.vue";
import Permission from "../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

// @vue/component
export default {
  name: "GeschaeftsregelCopyButtonConfirm",
  components: {
    Modal,
    Permission,
    PuxButton,
  },
  props: {
    regel: {
      type: Object,
      required: true,
    },
    regelUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const APP = getCurrentInstance();
    const $goTo = APP.appContext.config.globalProperties.$goTo;

    const regel = toRef(props, "regel");
    const regelUrl = toRef(props, "regelUrl");

    const isModalOpen = ref(false);
    const confirmOptions = ref(undefined);
    const buttonId = "regel_btn_copy";
    const selectorClose = `#${ buttonId }`;

    const closeModal = () => {
      isModalOpen.value = false;
    };

    const {
      postHttp,
    } = HttpAPI();
    const {
      addNotification,
    } = NotificationAPI();
    const copyRegel = () => {
      confirmOptions.value.loading = true;
      postHttp({
        url: `${ regelUrl.value }kopieren/`,
      }).then(
        response => {
          addNotification({
            text: "_MSG_GR_DETAILS_COPY_SUCCESS_",
          });
          closeModal();
          $goTo("root.foerderregeln.detail", { id: response.pk });
        },
        () => {
          addNotification({
            text: "_MSG_GR_DETAILS_COPY_ERROR_",
            type: "error",
          });
        }
      ).finally(
        () => {
          confirmOptions.value.loading = false;
        }
      );
    };

    const openModal = () => {
      confirmOptions.value = {
        okClass: "btn btn-primary",
        title: "_TXT_GR_DETAILS_COPY_HEADER_",
        msg: "_TXT_GR_DETAILS_COPY_BODY_{{re_bez}}_",
        okLabel: "_BTN_GR_DETAILS_COPY_OK_LABEL_",
        okCallback: copyRegel,
        cancelCallback: closeModal,
        extra: {
          re_bez: regel.value.re_bez,
        },
        loading: false,
      };

      isModalOpen.value = true;
    };

    return {
      buttonId,
      closeModal,
      confirmOptions,
      isModalOpen,
      openModal,
      selectorClose,
    };
  },
};
