import FoerderdokumenteModalCreate from "../FoerderdokumenteModalCreate/FoerderdokumenteModalCreate.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import getTableApi from "../../../const/TableApis";
import {
  uniq,
} from "lodash-es";

const TABLE_ID = "foerderdokumente";

// @vue/component
export default {
  name: "FoerderdokumenteList",
  components: {
    FoerderdokumenteModalCreate,
    PageTabTitle,
    PuxIcon,
    PuxTranslate,
    SmartTable,
  },
  setup() {
    const {
      filterDate,
    } = FiltersAPI();

    return {
      filterDate,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_TXT_DETAILS_ANZEIGEN_",
            route: "root.foerderdokumente.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        actions: [
          {
            permission: "foerderdokument.create",
            label: "_TXT_FOERDERDOKUMENTE_LIST_FOERDERDOKUMENT_HINZUFUEGEN_",
            callback: () => this.modal.create = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "foerderdokument_create",
          },
        ],
        label: "_TXT_FOERDERDOKUMENTE_LIST_FOERDERDOKUMENTE_HINZUFUEGEN_",
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
        },
      },
      modal: {
        create: undefined,
      },
      currentBerichte: undefined,
      currentIndex: undefined,
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },

    getAngeboteUnique() {
      return angebote => uniq(angebote);
    },
  },
  methods: {
    closeModalCreate() {
      this.modal.create = false;
    },
  },
};
