import {
  computed,
  inject,
  ref,
  toRefs,
  watch,
} from "vue";

import Permission from "../../../../../../global/components/Permission/Permission.vue";
import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../../../global/components/PuxIcon/PuxIcon.vue";

import SitzungDetailsTagesordnungItemAPI from "../../compositionAPI/SitzungDetailsTagesordnungItemAPI";

// @vue/component
export default {
  name: "SitzungDetailsTagesordnungSortingItem",
  components: {
    Permission,
    PuxButton,
    PuxIcon,
  },
  props: {
    sitzung: {
      type: Object,
      required: true,
    },
    tagesordnung: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    parentIndex: {
      type: Number,
      default: undefined,
    },
    parentItem: {
      type: Object,
      default: undefined,
    },
    topsUrl: {
      type: String,
      required: true,
    },
    statusChanges: {
      type: Object,
      required: true,
    },
    dragoverPk: {
      type: String,
      default: undefined,
    },
  },
  emits: [
    "movePunktDown",
    "movePunktUp",
  ],
  setup(props) {
    const {
      index,
      item,
      parentIndex,
      statusChanges,
      topsUrl,
    } = toRefs(props);

    const showModalConfirmDelete = ref(false);
    const modalDeleteConfirmOptions = ref({});

    const statusLastChange = computed(() => {
      return statusChanges.value.lastChange;
    });

    const isUnteritem = computed(() => {
      return parentIndex.value !== undefined;
    });

    const htmlISuffix = computed(() => {
      return `${ isUnteritem.value ? parentIndex.value + "_" : "" }${ index.value }`;
    });

    const buttonMoveUpId = computed(() => {
      return `move_item_up_${ htmlISuffix.value }`;
    });

    const buttonMoveDownId = computed(() => {
      return `move_item_down_${ htmlISuffix.value }`;
    });

    const deleteButtonId = computed(() => {
      return `delete_item_button_${ htmlISuffix.value }`;
    });

    const {
      deleteTagesordnungItem,
    } = SitzungDetailsTagesordnungItemAPI({
      topsUrl,
      tagesordnungItem: item,
    });

    const reloadTagesordnung = inject("reloadTagesordnung");

    const callDeleteItem = () => {
      modalDeleteConfirmOptions.value.loading = true;
      deleteTagesordnungItem().then(
        () => reloadTagesordnung(),
      );
    };

    const updateFocusMoveButtons = () => {
      setTimeout(() => {
        if (statusChanges.value.movedDown === item.value.pk) {
          $(`#${ buttonMoveDownId.value }`).focus();
        } else if (statusChanges.value.movedUp === item.value.pk) {
          $(`#${ buttonMoveUpId.value }`).focus();
        }
      });
    };

    watch(statusLastChange, updateFocusMoveButtons);

    const closeModalDeleteItem = () => {
      showModalConfirmDelete.value = false;
    };

    const confirmDeleteItem = () => {
      modalDeleteConfirmOptions.value = {
        okClass: "btn btn-primary",
        title: "_TXT_SITZUNG_DETAILS_TOPS_LOESCHEN_MODAL_TITLE_",
        okLabel: "_BTN_DELETE_",
        msg: "_HTML_SITZUNG_DETAILS_TOPS_LOESCHEN_MODAL_BODY_",
        okCallback: callDeleteItem,
        cancelCallback: closeModalDeleteItem,
        loading: false,
      };
      showModalConfirmDelete.value = true;
    };

    return {
      confirmDeleteItem,
      deleteTagesordnungItem,
      updateFocusMoveButtons,

      buttonMoveUpId,
      buttonMoveDownId,
      deleteButtonId,
      isUnteritem,
      modalDeleteConfirmOptions,
      reloadTagesordnung,
      showModalConfirmDelete
    };
  },
  computed: {
    isDragOver() {
      return this.item.pk === this.dragoverPk;
    },

    selectorCloseDeleteButtonId() {
      return `#${ this.deleteButtonId }`;
    },

    canMoveUpItem() {
      return this.index !== 0;
    },

    canMoveDownItem() {
      if (this.isUnteritem) {
        return this.index < this.parentItem.unterpunkte.length - 1;
      }

      return this.index < this.tagesordnung.length - 1;
    },

    tagesordnungspunktNummer() {
      if (this.isUnteritem) {
        return `${ this.parentIndex + 1 }.${ this.index + 1 }`;
      }
      return this.index + 1;
    },
  },
  methods: {
    moveItemUp() {
      this.$emit("movePunktUp", {
        punkt: this.item,
        index: this.index,
        parentIndex: this.parentIndex,
      });
      $(`#${ this.buttonMoveUpId }`).blur();
    },

    moveItemDown() {
      this.$emit("movePunktDown", {
        punkt: this.item,
        index: this.index,
        parentIndex: this.parentIndex,
      });
      $(`#${ this.buttonMoveDownId }`).blur();
    },
  }
};
