"use strict";

import template from "./details.jade";

import basedataEditTemplate from "./basisdaten.edit.jade";

class ProjektberichtvorlageDetailsController {
  /*@ngInject*/
  constructor(
    $q,
    $filter,
    $state,
    $stateParams,
    DiasNotification,
    diasConfirmDialog,
    Projektberichtvorlagen,
    Foerderregeln
  ) {
    this.$q = $q;
    this.$filter = $filter;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.DiasNotification = DiasNotification;
    this.Projektberichtvorlagen = Projektberichtvorlagen;
    this.Foerderregeln = Foerderregeln;

    this.baseEditTemplate = basedataEditTemplate;

    this.init();
  }

  init() {
    this.loading = {
      projektberichtvorlage: true,
      projektberichtregeln: true,
      regeln: true
    };
    this.loadProjektberichtvorlage().finally(
      () => this.loading.projektberichtvorlage = false
    );
    const existingRules = this.loadProjektberichtregeln().finally(
      () => this.loading.projektberichtregeln = false
    );
    const allRules = this.loadRegeln().finally(
      () => this.loading.regeln = false
    );
    this.$q.all(
      [existingRules, allRules]
    ).then(() => this.initVerwendbareRegeln());
  }

  loadProjektberichtvorlage() {
    return this.Projektberichtvorlagen.one(this.$stateParams.id).get().then(
      response => this.pbv = response,
      error => this.DiasNotification.page.error(error, "Fehler beim Laden der Projektberichtvorlage")
    );
  }

  loadProjektberichtregeln() {
    return this.Projektberichtvorlagen.one(this.$stateParams.id).regeln.getList().then(
      response => this.pbvRegeln = response,
      error => this.DiasNotification.page.error(error, "Fehler beim Laden der Projektberichtvorlage")
    );
  }

  loadRegeln() {
    return this.Foerderregeln.getProjektberichtregeln().then(
      response => this.regeln = angular.forEach(response, item => {
        item.titel = (item.re_nummer ? ("[" + item.re_nummer + "] ") : "") + item.re_kbez;
      }),
      error => this.DiasNotification.page.error(error, "Fehler beim Laden der Geschäftsregeln")
    );
  }

  initVerwendbareRegeln() {
    if (!angular.isUndefined(this.regeln)) {
      this.updateVerwendbareRegeln(this.$filter("filter")(this.regeln, value => this.filterVerwendbareRegeln(value)));
    }
  }

  filterVerwendbareRegeln(value) {
    for (let i = 0; i < this.pbvRegeln.length; i++) {
      if (this.pbvRegeln[i].regel === value.pk) {
        return false;
      }
    }
    return true;
  }

  updateVerwendbareRegeln(newRegeln) {
    this.verwendbareRegeln = this.$filter("orderBy")(newRegeln, "re_nummer");
  }

  addProjektberichtregel() {
    return regel => {
      this.loading.projektberichtregeln = true;
      return this.pbvRegeln.post({ regel: regel.pk }).then(
        response => {
          this.pbvRegeln.push(response);
          this.updateVerwendbareRegeln(this.$filter("filter")(this.verwendbareRegeln, { pk: "!".concat(response.regel) }));
          this.DiasNotification.page.success("Geschäftsregel erfolgreich hinzugefügt");
        },
        error => this.DiasNotification.page.error(error, "Fehler beim Hinzufügen der Geschäftsregel")
      ).finally(() => this.loading.projektberichtregeln = false);
    };
  }

  deleteProjektberichtregel() {
    return pbvRegel => {
      this.loading.projektberichtregeln = true;
      return pbvRegel.remove().then(
        () => {
          // remove from our list
          this.pbvRegeln.splice(this.pbvRegeln.indexOf(pbvRegel), 1);
          // update remainig positions
          const rule = this.$filter("filter")(this.regeln, { pk: pbvRegel.regel })[0];
          this.verwendbareRegeln.push(rule);
          this.updateVerwendbareRegeln(this.verwendbareRegeln);
          this.DiasNotification.page.success("Geschäftsregel erfolgreich enfernt");
        },
        error => this.DiasNotification.page.error(error, "Fehler beim Entfernen der Geschäftsregel")
      ).finally(() => this.loading.projektberichtregeln = false);
    };
  }

  moveUpProjektberichtregel() {
    return pbvRegel => {
      if (pbvRegel.pbr_pos === 1) {
        // Can't move first further up
        return;
      }
      this.loading.projektberichtregeln = true;
      return pbvRegel.moveUp().then(
        () => {
          const previous = this.pbvRegeln[pbvRegel.pbr_pos - 2];
          previous.pbr_pos += 1;
          pbvRegel.pbr_pos -= 1;
          this.pbvRegeln[previous.pbr_pos - 1] = previous;
          this.pbvRegeln[pbvRegel.pbr_pos - 1] = pbvRegel;
          this.DiasNotification.page.success("Geschäftsregel erfolgreich verschoben");
        },
        error => this.DiasNotification.page.error(error, "Fehler beim Verschieben der Geschäftsregel")
      ).finally(() => this.loading.projektberichtregeln = false);
    };
  }

  moveDownProjektberichtregel() {
    return pbvRegel => {
      if (pbvRegel.pbr_pos === this.pbvRegeln.length) {
        // Can't move last further down
        return;
      }
      this.loading.projektberichtregeln = true;
      return pbvRegel.moveDown().then(
        () => {
          const next = this.pbvRegeln[pbvRegel.pbr_pos];
          next.pbr_pos -= 1;
          pbvRegel.pbr_pos += 1;
          this.pbvRegeln[next.pbr_pos - 1] = next;
          this.pbvRegeln[pbvRegel.pbr_pos - 1] = pbvRegel;
          this.DiasNotification.page.success("Geschäftsregel erfolgreich verschoben");
        },
        error => this.DiasNotification.page.error(error, "Fehler beim Verschieben der Geschäftsregel")
      ).finally(() => this.loading.projektberichtregeln = false);
    };
  }

  deleteProjektberichtvorlage() {
    if (this.pbv.deletable) {
      this.diasConfirmDialog({
        titleTemplate: `Projektberichtvorlage löschen`,
        contentTemplate: `<p>Sind Sie sicher, dass Sie die Projektberichtvorlage {{ context.pbv.pbv_kbez }} löschen wollen?</p>`,
        context: { pbv: this.pbv },
        okLabel: "Projektberichtvorlage löschen",
        okCallback: () => this.doDelete()
      });
    }
  }

  doDelete() {
    this.loading.deleting = true;
    return this.pbv.remove().then(
      () => {
        this.DiasNotification.page.success(
          "Projektberichtvorlage <strong>" + this.pbv.pbv_kbez + "' erfolgreich gelöscht."
        );
        this.$state.go("root.projektberichtvorlagen");
      },
      error => {
        this.DiasNotification.page.error(error, "Fehler beim löschen der Projektberichtvorlage");
        this.loading.deleting = false;
      }
    );
  }
}

export default {
  template: template(),
  controller: ProjektberichtvorlageDetailsController,
  controllerAs: "vm"
};
