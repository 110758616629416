import Alert from "../../../../global/components/Alert/Alert.vue";

import KFPlanMixin from "../Mixins/KFPlanMixin";

import InfoproviderStatuses from "../../../../const/InfoproviderStatuses";
import {
  filter,
  get,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "ZuschussPlan",
  components: {
    Alert,
  },
  mixins: [
    KFPlanMixin,
  ],
  data() {
    return {
      kofiPlanHtmlId: "zuschuss_plan",
      infoproviderStatuses: InfoproviderStatuses,
    };
  },
  computed: {
    statusShowZuschussModul() {
      return !this.infoprovider.status ||
        (this.infoprovider.status === InfoproviderStatuses.PROCESSOR_STATUS_OK &&
          this.infoprovider.gesamtzuschuss > 0);
    },

    statusShowPauschaleModul() {
      return size(this.modulePauschalenLocal);
    },

    moduleLocal() {
      return this.module || [];
    },


    modulePauschalenLocal() {
      return filter(this.moduleLocal, el => el.modulgruppen.indexOf("pauschale") !== -1);
    },


    moduleZuschussLocal() {
      return filter(this.moduleLocal, el => el.modulgruppen.indexOf("zuschuss") !== -1);
    },

    statusZuschussSaved() {
      // Gibt an ob die Zuschussinformationen gespeichert wurden oder nicht.
      return this.infoprovider && this.infoprovider.gespeichert === true;
    },

    gesamtzuschussCurrency() {
      return this.filterCurrency(this.infoprovider.gesamtzuschuss);
    },

    snapshotGesamtzuschussCurrency() {
      const GESAMTZUSCHUSS = get(this.snapshot, "asn_snapshot.zuschuss.gesamtzuschuss");
      return this.filterCurrency(GESAMTZUSCHUSS);
    },

    antragZuschussbeantragt() {
      return this.filterCurrency(this.obj.a_zuschussbeantragt);
    },

    statusShowErrors() {
      return this.infoprovider && (
        (
          this.infoprovider.meldungen &&
          this.infoprovider.meldungen.length
        ) ||
        this.infoprovider.status === InfoproviderStatuses.PROCESSOR_STATUS_IMPERFECT ||
        (
          this.infoprovider.status === InfoproviderStatuses.PROCESSOR_STATUS_OK &&
          this.infoprovider.zuschuss === 0 &&
          this.infoprovider.gesamtzuschuss === 0
        )
      );
    },

    statusKeineZuschussinformationen() {
      return this.infoprovider.status === InfoproviderStatuses.PROCESSOR_STATUS_OK &&
        this.infoprovider.zuschuss === 0 &&
        this.infoprovider.gesamtzuschuss === 0;
    },

    infoproviderMeldungen() {
      return this.infoprovider.meldungen || [];
    },
  },
};
