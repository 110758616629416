import {
  cloneDeep,
  forEach,
  get,
  isNil,
  orderBy,
  size,
  values,
} from "lodash-es";

export function dynFormToFormElements({ formDef, object, htmlId }) {
  const FORM = orderBy(cloneDeep(values(formDef)), ["pos"]);
  const CT = get(object, "content_type");
  const PK = get(object, "pk");
  forEach(FORM, el => {
    el.htmlId = `${ htmlId }${ isNil(htmlId) ? "" : "_" }${ el.id }`;
    el.helpText = el.helpText || el.help_text;

    switch (el.type) {
    case "boolean":
      el.trueLabel = el.trueLabel || el.true_label;
      el.falseLabel = el.falseLabel || el.false_label;
      break;
    case "float":
      el.addonBack = el.addonBack || el.suffix;
      break;
    case "select":
      el.url = "katalog/";
      el.urlParams = {
        key: el.katalog
      };
      if (!el.komplett && size(el.ids) > 0) {
        el.urlParams.pk = el.ids;
      }
      el.keyId = "pk";
      el.keyLabel = "bez";
      el.search = true;
      el.searchList = ["bez"];
      el.deselect = !el.required;
      if (el.many) {
        el.type = "multiselect";
      }
      break;
    case "select_objektkatalog":
      if (CT && PK) {
        el.url = `generics/${ CT }/objekte/${ PK }/katalogwerte/`;
        el.urlParams = {
          katalog: el.objektkatalog
        };
      } else {
        el.disabled = true;
      }
      el.keyLabel = "bez";
      el.keyId = "pk";
      el.search = true;
      el.searchList = ["bez"];
      el.deselect = !el.required;
      if (el.many) {
        el.type = "multiselect";
      } else {
        el.type = "select";
      }
      break;
    }
  });
  return FORM;
}
