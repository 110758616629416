import FormstepDetail from "../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../global/components/ui/ShowMore/ShowMore.vue";

import {
  get,
  isArray,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "Analyse",
  components: {
    FormstepDetail,
    PuxTranslate,
    ShowMore,
  },
  props: {
    analyse: {
      type: Object,
      default: () => ({}),
    },
    getIcon: {
      type: Function,
      required: true,
    },
    analyseIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    htmlLocal() {
      return `analyse_${ this.analyseIndex }`;
    },

    icon() {
      if (this.analyse.result) {
        return this.getIcon(this.analyse.result.status);
      }
      return this.getIcon();
    },

    getOption() {
      return {
        label: this.analyse.bezeichnung,
        hideHeader: false,
      };
    },

    detailMode() {
      if (this.analyse.result && this.analyse.result.details) {
        const data = this.analyse.result.details;
        if (isArray(data)) {
          if (data.length > 0) {
            if (isArray(data[0])) {
              return "table";
            }
            return "list";
          }
        }
        return "string";
      }
      return "";
    },

    tableOptions() {
      if (!this.analyse.result || !this.analyse.result.details) {
        return {};
      }
      const data = this.analyse.result.details;
      let cols = [];
      const rows = [];
      // let ids = [];
      forEach(data, (r, cnt_rows) => {
        if (cnt_rows === 0) {
          cols = r;
        } else {
          rows.push(r);
        }
      });
      return {
        cols: cols,
        data: rows,
      };
    },

    extraTranslate() {
      return {
        truncated: get(this.analyse, "result.truncated"),
      };
    },
  },
};
