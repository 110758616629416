"use strict";

import BaseController from "../config.base.controller";

class ParametermoduleController extends BaseController {
  /*@ngInject*/
  constructor() {
    super();
    this.initialValue = this.parameter[this.key].wert || "";
    this.form = JSON.parse(JSON.stringify(this.initialValue));
    this.JSONtoString();
  }

  reset() {
    this.parameter[this.key].wert = this.initialValue;
    this.form = JSON.parse(JSON.stringify(this.initialValue));
    this.JSONtoString();
  }

  format() {
    this.jsonStringInput = JSON.stringify(JSON.parse(this.jsonStringInput), undefined, 2);
  }

  JSONtoString() {
    if (this.parameter[this.key].wert) {
      this.jsonString = JSON.stringify(this.parameter[this.key].wert, undefined, 2);
    } else {
      this.jsonString = "";
    }
    this.jsonStringInput = this.jsonString;
    this.parseError = undefined;
  }

  stringToJSON() {
    if (!this.jsonStringInput || this.jsonStringInput.length === 0) {
      this.parameter[this.key].wert = null;
      this.jsonString = "";
    } else {
      try {
        this.parameter[this.key].wert = JSON.parse(this.jsonStringInput);
        this.jsonString = JSON.stringify(this.parameter[this.key].wert, undefined, 2);
        this.form = JSON.parse(JSON.stringify(this.parameter[this.key].wert));
        this.parseError = null;
      } catch (e) {
        this.parseError = e.toString();
      }
    }
  }

  updateJSON() {
    this.parameter[this.key].wert = this.form;
    this.JSONtoString();
  }
}

export default ParametermoduleController;
