"use strict";

import angular from "angular";

import modul from "./dynamicElement.component";

const parameter = angular.module("dias.dynamicElement", [])

  .component("dynamicElement", modul)
;

export default parameter;
