import AngularLink from "../../../AngularLink/AngularLink.vue";
import PuxTranslate from "../../../PuxTranslate/PuxTranslate.vue";

import TableCellMixin from "../TableCellMixin";

import {
  replaceText,
} from "../../../../functions/utils";
import {
  assign,
  forEach,
  get,
  isFunction,
  isString,
} from "lodash-es";

// @vue/component
export default {
  name: "TableViewTableCellLinkAngular",
  components: {
    AngularLink,
    PuxTranslate,
  },
  mixins: [
    TableCellMixin
  ],
  props: {
    index: {
      type: Number,
      required: true,
    },
    html: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    routeLocal() {
      if (isFunction(this.options.route)) {
        return this.options.route({ item: this.row, index: this.index });
      }
      return this.options.route;
    },

    parameterAngularLink() {
      let parameter = {};
      if (this.options.routeParameters && this.options.routeParameters.length) {
        forEach(this.options.routeParameters, item => {
          if (item.rowPathObject) {
            parameter = assign({}, parameter, get(this.row, item.rowPathObject));
          }
          const KEY = item.routeId;
          if (KEY) {
            let value = "";
            if (item.rowPath) {
              value = get(this.row, item.rowPath);
            }
            parameter[KEY] = value;
          }
        });
      }
      return parameter;
    },

    optionsAngularLink() {
      const OPTIONS = {};
      if (this.options.routeOptions && this.options.routeOptions.length) {
        forEach(this.options.routeOptions, item => {
          const KEY = item.routeId;
          let value = "";
          if (item.rowPath) {
            value = get(this.row, item.rowPath);
          }
          OPTIONS[KEY] = value;
        });
      }
      return OPTIONS;
    },

    href() {
      if (isString(this.options.url)) {
        return replaceText({ text: this.options.url, object: this.row });
      }
      if (isFunction(this.options.href)) {
        return this.options.href({ item: this.row, index: this.index });
      }
      return undefined;
    },

    linkAttributes() {
      if (this.options.target) {
        return {
          target: this.options.target,
        };
      }
      return {};
    },
  },
};
