"use strict";

import BaseModel from "dias/core/models/baseModel";


class Foerdermittel extends BaseModel {
  constructor() {
    super("foerdermittel");
  }
}

export default Foerdermittel;
