import {
  computed,
  toRef,
} from "vue";

import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import GeschaeftsregelModul from "../../GeschaeftsregelModul/GeschaeftsregelModul.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "GeschaeftsregelVorbelegung",
  components: {
    FormstepDetail,
    GeschaeftsregelModul,
    PuxTranslate,
  },
  props: {
    regel: {
      type: Object,
      required: true,
    },
    moduleVorbelegungUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const regel = toRef(props, "regel");

    const isVorbelegungPossible = computed(() => {
      return !!get(regel.value, "modul_obj.sm_userdefault");
    });

    const optionsFormstepDetail = {
      label: "_TXT_GR_DETAILS_VORBELEGUNG_",
    };

    return {
      isVorbelegungPossible,
      optionsFormstepDetail,
    };
  },
};
