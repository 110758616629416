import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import moment from "moment";
import SaveCallbackActions from "../../../../const/SaveCallbackActions";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "AuszahlungBetragEditButtonModal",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  props: {
    auszahlung: {
      type: Object,
      required: true,
    },
    auszahlungUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "saveCallback",
  ],
  setup() {
    const {
      putHttp
    } = HttpAPI();

    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      checkPermissionsSync,
      putHttp,
    };
  },
  data() {
    return {
      isModalVisible: undefined,
      buttonId: "auszahlung_betrag_modal_btn",
      optionsForModal: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "az_betrag",
            label: "_TXT_AUSZAHLUNGSBETRAG_",
            type: "currency",
            required: true,
            view: "v",
          },
          {
            id: "az_datum_ausgezahlt",
            label: "_TXT_AUSZAHLUNGSDATUM_",
            type: "date",
            required: true,
            view: "v",
          },
        ],
      },
      errors: undefined,
      errorsClone: undefined,
      loading: false,
      model: {},
    };
  },
  computed: {
    isAuszahlungsbetragEditable() {
      const CAN_EDIT = this.checkPermissionsSync({
        perm: "auszahlung.auszahlungsbetrag.edit",
        permArray: this.auszahlung.user_permissions,
      });
      const CAN_RESET = this.checkPermissionsSync({
        perm: "auszahlung.auszahlungsbetrag.reset",
        permArray: this.auszahlung.user_permissions,
      });
      return CAN_EDIT && ((CAN_RESET && this.auszahlung.az_betrag === null) || !CAN_RESET);
    },

    selectorClose() {
      return [
        `#${ this.buttonId }`,
      ];
    },

    urlSave() {
      return `${ this.auszahlungUrl }auszahlung_eintragen/`;
    },
  },
  methods: {
    openModal() {
      this.initModel();
      this.isModalVisible = true;
      this.deleteErrors();
    },

    initModel() {
      this.model = {
        az_betrag: this.auszahlung.az_betrag,
        az_datum_ausgezahlt: this.auszahlung.az_datum_ausgezahlt || moment().format("YYYY-MM-DD"),
      };
    },

    save() {
      this.deleteErrors();
      this.loading = true;
      this.putHttp({
        url: this.urlSave,
        data: this.model,
      }).then(
        () => {
          this.$emit("saveCallback", { statusSaveCallback: SaveCallbackActions.RELOAD_ALL });
          this.closeModal();
        },
        error => {
          this.errors = error.data;
          this.errorsClone = cloneDeep(error.data);
        }
      ).then(
        () => this.loading = false
      );
    },

    closeModal() {
      this.isModalVisible = false;
    },

    deleteErrors() {
      this.errors = undefined;
      this.errorsClone = undefined;
    },
  },
};
