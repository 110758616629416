"use strict";

import BaseModel from "dias/core/models/baseModel";


class TempAntraegeEigene extends BaseModel {
  constructor() {
    super("/temp_antraege/eigene/", {
      subPaths: {
        antrag_erstellen: "antrag_erstellen",
      }
    });
  }
}

export default TempAntraegeEigene;
