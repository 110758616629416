"use strict";

import BetragsrechnerBase from "../betragsrechner.base";

class BetragsrechnerStandard extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "standard_kostenrechner";
    this.component_name = "standardkosten";
    this.betrag_editable = true;
    this.fields = {
      antrag: { wert: null,
                bezeichnung: "" },
      beleg: { wert: null }
    };
  }

  get_labelmapping() {
    return {
      wert: "Betrag in EUR",
      bezeichnung: "Bezeichnung",
      kostenartdetail: "Typ / Kategorie",
      finanzierungsartdetail: "Typ / Kategorie"
    };
  }
}

export default BetragsrechnerStandard;
