import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import {
  FilterCurrencyMixin,
} from "../../../global/mixins/FiltersMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import KWorkflowtypUUIDs from "../../../const/KWorkflowtypUUIDs";
import getTableApi from "../../../const/TableApis";

const TABLE_ID = "sammelvertraege";
const VIEW_STATUS_COLUMN_PERM = "workflow.list.column.status_detail.view";

// @vue/component
export default {
  name: "SammelvertragList",
  components: {
    AngularLink,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    FilterCurrencyMixin,
    PermissionMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        cols: [
          {
            label: "_TXT_ANTRAGSTELLER_",
            id: "antragstellerorg__ast_name",
            sortable: true,
            sortId: "antragstellerorg__ast_name",
            slot: "antragstellerorg__ast_name",
          },
          {
            label: "_LBL_SAMMELVERTRAG_LIST_ANTRAGSTELLERNUMMER_",
            id: "ast_nr",
            sortable: true,
            sortId: "antragstellerorg__ast_nr",
          },
          {
            label: "_LBL_SAMMELVERTRAG_LIST_NUMMER_",
            id: "sv_nr",
          },
          {
            label: "_LBL_SAMMELVERTRAG_LIST_BUDGET_",
            id: "budget.bud_kbez",
            sortable: true,
            sortId: "budget__bud_kbez",
          },
          {
            label: "_LBL_SAMMELVERTRAG_LIST_ZUGEWIESENER_BETRAG_",
            id: "budget__bud_kbez",
            className: "text-right",
            headerClassName: "text-right",
            templateCallback: ({ row }) => this.filterCurrency(row.sv_budget),
          },
          {
            label: "_LBL_SAMMELVERTRAG_LIST_BEWILLIGT_",
            id: "bewilligt",
            className: "text-right",
            headerClassName: "text-right",
            filter: "currency",
            filterParameter: "€",
          },
          {
            label: "_LBL_SAMMELVERTRAG_LIST_AUSGEZAHLT_",
            id: "ausgezahlt",
            className: "text-right",
            headerClassName: "text-right",
            filter: "currency",
            filterParameter: "€",
          },
          {
            label: "_LBL_SAMMELVERTRAG_LIST_VERFUEGBAR_",
            id: "verfuegbar",
            className: "text-right",
            headerClassName: "text-right",
            filter: "currency",
            filterParameter: "€",
          },
          {
            label: "_LBL_SAMMELVERTRAG_LIST_ANTRAEGE_",
            id: "num_antraege",
            className: "text-right",
            headerClassName: "text-right",
          },
          {
            label: "_LBL_SAMMELVERTRAG_LIST_ABGERECHNETE_ANTRAEGE_",
            id: "num_abgerechnet",
            className: "text-right",
            headerClassName: "text-right",
          },
          {
            label: "_LBL_SAMMELVERTRAG_LIST_STATUS_",
            id: "status",
          },
        ],
        rowActions: [
          {
            label: "_LBL_SAMMELVERTRAG_LIST_ACTIONS_ROW_DETAILS_",
            route: "root.sammelvertrag.details",
            routeParameters: [{ routeId: "id", rowPath: "pk" }],
          },
        ],
        label: "_LBL_SAMMELVERTRAG_LIST_WEITERLEITUNGSVERTRAEGE_",
        sortable: true,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
      filters: [
        {
          type: "multiselect",
          id: "antragstellerorg",
          label: "_LBL_SAMMELVERTRAG_LIST_ANTRAGSTELLERORGANISATION_",
          searchParameter: "ast_name",
          searchGlobal: true,
          url: "antragstellerorganisationen/?fields=pk&fields=bez",
          keyLabel: "bez",
          keyId: "pk",
          main: true,
        },
        {
          type: "multiselect",
          id: "budget",
          label: "_LBL_SAMMELVERTRAG_LIST_BUDGET_",
          searchParameter: "bud_kbez",
          searchGlobal: true,
          url: "budgets/?fields=pk&fields=bud_kbez",
          keyLabel: "bud_kbez",
          keyId: "pk",
          alwaysVisible: true,
        },
        {
          type: "multiselect",
          id: "workflowstatusgruppe",
          url: `workflowstatusgruppen/?fields=pk&fields=wsg_kbez&workflowtyp=${ KWorkflowtypUUIDs.sammelvertrag }`,
          label: "_LBL_SAMMELVERTRAG_LIST_ARBEITSSCHRITT_",
          keyLabel: "wsg_kbez",
          keyId: "pk",
          search: true,
          searchList: ["wsg_kbez"],
          alwaysVisible: true,
        },
        {
          type: "multiselect",
          id: "status",
          url: "katalog/?fields=pk&fields=au_kbezbeobachter&key=sammelvertragaufgabe",
          combineCopies: true,
          label: "_LBL_SAMMELVERTRAG_LIST_STATUS_",
          keyLabel: "au_kbezbeobachter",
          keyId: "pk",
          search: true,
          searchList: ["au_kbezbeobachter"],
          alwaysVisible: true,
        },
      ],
    };
  },
  computed: {
    getHrefSammelvertrag() {
      return pk => `/sammelvertraege/${ pk }/`;
    },
  },
  created() {
    this.initFilters();
  },
  methods: {
    initFilters() {
      if (this.checkPermissionsSync({ perm: VIEW_STATUS_COLUMN_PERM })) {
        this.filters.push({
          type: "multiselect",
          id: "status_detail",
          url: "katalog/?fields=pk&fields=au_kbezdetail&key=sammelvertragaufgabe",
          label: "_LBL_SAMMELVERTRAG_LIST_STATUS_DETAILLIERT_",
          combineCopies: true,
          keyLabel: "au_kbezdetail",
          keyId: "pk",
          search: true,
          searchList: ["au_kbezdetail"],
        });
      }
    },
  },
};
