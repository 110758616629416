"use strict";
import angular from "angular";

import DiasModal from "dias/core/modal";
import DiasNotification from "dias/core/notification";
import parameter from "dias/foerderregeln/parameter";

import services from "dias/workflow/services";
import functionCls from "./function.js";

const modul = angular.module("standard_workflowmodule.sv_aktualisieren", [
  DiasModal.name,
  DiasNotification.name,
  parameter.name,
  services.name
])

  .config(/*@ngInject*/WorkflowfunktionRegistryProvider => {
    WorkflowfunktionRegistryProvider.register("sv_aktualisieren", functionCls);
  });


export default modul;
