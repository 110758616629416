"use strict";

import parameterServices from "dias/foerderregeln/parameter/services";
import readonlyTemplate from "./readonly.jade";
import template from "./config.jade";
import controller from "./controller";


const parametermoduleJSON = angular.module("dias.sitzungen.parameter.sitzungstopauswahl", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("sitzungstopauswahl", "parametermodul-sitzungstopauswahl");
  })
  .component("parametermodulSitzungstopauswahl", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulSitzungstopauswahlRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: readonlyTemplate(),
    controller: controller,
    controllerAs: "vm",
  });
export default parametermoduleJSON;
