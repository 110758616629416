// Modul zur Beantragung von Rollstuhlplatz-Pauschalen
"use strict";

import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";
import VertragModels from "dias/vertrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const rollstuhlplatz_pauschale = angular.module("dias.rollstuhlplatz_pauschale", [
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
  VertragModels.name,
]);

rollstuhlplatz_pauschale.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("rollstuhlplatz_pauschale", "modul-rollstuhlplatz-pauschale");
})

  .directive("modulRollstuhlplatzPauschale", builder.directive(template, controller));

export default rollstuhlplatz_pauschale;
