"use strict";

import BaseModel from "dias/core/models/baseModel";

class Anonymisierungsauftrag extends BaseModel {
  constructor() {
    super("anonymisierungsauftraege");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);
    collection.STATE_PLANNED = 1;
    collection.STATE_RUNNING = 2;
    collection.STATE_SUCCESS = 3;
    collection.STATE_FAILURE = 4;
    return collection;
  }
}

export default Anonymisierungsauftrag;
