"use strict";

import uiRouter from "angular-ui-router";
import angular from "angular";

import Raven from "./raven.service";
import ExceptionHandler from "./exceptionHandler.service.js";

const services = angular.module("dias.errors.services", [
  uiRouter
])

  .service("Raven", Raven)
  .provider("$exceptionHandler", ExceptionHandler)
;

export default services;
