import AstBankverbindungenModalCreateOrUpdate
  from "./AstBankverbindungenModalCreateOrUpdate/AstBankverbindungenModalCreateOrUpdate.vue";
import AstBankverbindungenTr from "./AstBankverbindungenTr/AstBankverbindungenTr.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";

import ModuleMixin from "../ModuleMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import {
  cloneDeep,
  forEach,
  keyBy,
  values,
} from "lodash-es";

const SNAPSHOT_CHANGES_LIST = [
  "aov_inhaber",
  "aov_iban",
  "aov_bic",
  "aov_bank",
];

// @vue/component
export default {
  name: "AstBankverbindungen",
  components: {
    AstBankverbindungenModalCreateOrUpdate,
    AstBankverbindungenTr,
    Modal,
    PuxButton,
  },
  mixins: [
    ModuleMixin,
    PermissionMixin
  ],
  data() {
    return {
      currentBv: undefined,
      statusModalCreateOrUpdate: undefined,
      statusModalDelete: undefined,
      confirmDeleteOptions: undefined,
    };
  },
  computed: {
    labelHeader() {
      if (this.modelParameter.frage_text) {
        return this.modelParameter.frage_text;
      }
      return "_TXT_GR_AST_BANKVERBINDUNGEN_HEADER_";
    },

    bankverbindungenLocal() {
      if (this.model && this.model.bankverbindungen) {
        return this.model.bankverbindungen;
      }
      return [];
    },

    statusEditable() {
      return !this.statusReadonly && this.canEditBankverbindung;
    },

    statusDeletable() {
      return !this.statusReadonly && this.canDeleteBankverbindung;
    },

    canEditBankverbindung() {
      return this.checkPermissionsSync({
        perm: "astorgbankverbindung.update",
        permArray: this.obj.user_permissions,
      });
    },

    canCreateBankverbindung() {
      return this.checkPermissionsSync({
        perm: "astorgbankverbindung.create",
        permArray: this.obj.user_permissions,
      });
    },

    canDeleteBankverbindung() {
      return this.checkPermissionsSync({
        perm: "astorgbankverbindung.delete",
        permArray: this.obj.user_permissions,
      });
    },

    snapshotFiltered() {
      const SNAPSHOT = {
        add: {},
        delete: [],
        changes: {},
        changesDiff: {},
        status: false,
      };
      if (this.statusSnapshot) {
        const SNAPSHOT_BANKVERBINDUNGEN_OBJ = cloneDeep(this.snapshotBankverbindungenObj);
        forEach(this.bankverbindungenLocal, item => {
          const CURRENT_PK = item.pk;
          const CURRENT_SNAPSHOT = cloneDeep(SNAPSHOT_BANKVERBINDUNGEN_OBJ[CURRENT_PK]);
          if (!CURRENT_SNAPSHOT) {
            SNAPSHOT.add[CURRENT_PK] = true;
            SNAPSHOT.status = true;
          } else {
            forEach(SNAPSHOT_CHANGES_LIST, key => {
              const VALUE_IN_MODEL = item[key];
              const VALUE_IN_SNAPSHOT = CURRENT_SNAPSHOT[key];
              if (VALUE_IN_MODEL !== VALUE_IN_SNAPSHOT) {
                SNAPSHOT.changes[CURRENT_PK] = CURRENT_SNAPSHOT;
                SNAPSHOT.changesDiff[CURRENT_PK] = SNAPSHOT.changesDiff[CURRENT_PK] || {};
                SNAPSHOT.changesDiff[CURRENT_PK][key] = true;
                SNAPSHOT.status = true;
              }
            });
            delete SNAPSHOT_BANKVERBINDUNGEN_OBJ[CURRENT_PK];
          }
        });
        SNAPSHOT.delete = values(SNAPSHOT_BANKVERBINDUNGEN_OBJ);
        if (SNAPSHOT.delete.length) {
          SNAPSHOT.status = true;
        }
      }
      return SNAPSHOT;
    },

    snapshotBankverbindungenObj() {
      if (!this.snapshotModule || !this.snapshotModule.bankverbindungen) {
        return {};
      }
      return keyBy(this.snapshotModule.bankverbindungen, "pk");
    },

    statusDiff() {
      return this.snapshotFiltered.status;
    },

    statusNoData() {
      return !(this.snapshotFiltered.delete.length ||
        this.bankverbindungenLocal.length);
    },

    isModuleEditable() {
      return false;
    },
  },
  methods: {
    openModalCreateOrUpdate({ bv } = {}) {
      if (bv) {
        this.currentBv = bv;
        this.selectorClose = `#${ this.htmlRegelNummer }_edit_${ bv.pk }`;
      } else {
        this.selectorClose = `#${ this.htmlRegelNummer }_add`;
      }
      this.statusModalCreateOrUpdate = true;
    },

    closeModalCreateOrUpdate() {
      this.currentBv = undefined;
      this.statusModalCreateOrUpdate = false;
    },

    openModalDelete({ bv, index }) {
      this.selectorClose = [
        `#${ this.htmlRegelNummer }_edit_${ bv.pk }`,
        this.selectorHtmlRegelNummer,
      ];
      this.confirmDeleteOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_GR_AST_BANKVERBINDUNGEN_DELETE_HEADER_",
        msg: "_HTML_GR_AST_BANKVERBINDUNGEN_DELETE_BODY_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteBv({ index }),
        cancelCallback: this.closeModalDelete,
        loading: false,
      };
      this.statusModalDelete = true;
    },

    closeModalDelete() {
      this.statusModalDelete = false;
    },

    deleteBv({ index }) {
      this.confirmDeleteOptions.loading = true;
      const LIST = cloneDeep(this.model.bankverbindungen);
      LIST.splice(index, 1);

      this.save({
        model: { bankverbindungen: LIST },
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_GR_AST_BANKVERBINDUNGEN_MODAL_DELETE_SUCCESS_",
          });
          this.closeModalDelete();
        },
        () => {
          this.addNotification({
            text: "_MSG_GR_AST_BANKVERBINDUNGEN_MODAL_DELETE_ERROR_",
            type: "error",
          });
        }
      ).then(
        () => this.confirmDeleteOptions.loading = false
      );
    },
  },
};
