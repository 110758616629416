import BetragsrechnerMixin from "../../Mixins/BetragsrechnerMixin";
import DvvVeranstaltungsrechnerMixin from "../DvvVeranstaltungsrechnerMixin";

// @vue/component
export default {
  name: "DvvVeranstaltungsrechner",
  mixins: [
    BetragsrechnerMixin,
    DvvVeranstaltungsrechnerMixin,
  ],
  data() {
    return {
      labelDatumVon: "Beginn",
      labelDatumBis: "Ende",
      labelTage: "Veranstaltungstage",
      labelTeilnehmer: "Teilnehmer*innen (Gesamt)",
      labelHonorartage: "Tagessätze mit Honorarkräften",
    };
  },
  computed: {
    optionsDatumVon() {
      return {
        id: "datum_von",
        htmlId: this.htmlIdDatumVon,
        label: this.labelDatumVon,
        view: this.view,
        type: "date",
        required: true,
        classesHorizontal: this.classesHorizontal,
        autoFocus: this.autoFocus,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdDatumVon() {
      return `${ this.htmlId }_datum_von`;
    },

    optionsDatumBis() {
      return {
        id: "datum_bis",
        htmlId: this.htmlIdDatumBis,
        label: this.labelDatumBis,
        view: this.view,
        type: "date",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdDatumBis() {
      return `${ this.htmlId }_datum_bis`;
    },

    optionsTage() {
      return {
        id: "tage",
        htmlId: this.htmlIdTage,
        label: this.labelTage,
        view: this.view,
        type: "text",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdTage() {
      return `${ this.htmlId }_tage`;
    },

    optionsTeilnehmer() {
      return {
        id: "teilnehmer",
        htmlId: this.htmlIdTeilnehmer,
        label: this.labelTeilnehmer,
        view: this.view,
        type: "text",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdTeilnehmer() {
      return `${ this.htmlId }_teilnehmer`;
    },

    optionsHonorartage() {
      return {
        id: "honorartage",
        htmlId: this.htmlIdHonorartage,
        label: this.labelHonorartage,
        view: this.view,
        type: "text",
        required: true,
        classesHorizontal: this.classesHorizontal,
        change: this.changeVorschauLocal,
      };
    },

    htmlIdHonorartage() {
      return `${ this.htmlId }_honorartage`;
    },

    errorLabels() {
      return {
        datum_von: {
          label: this.labelDatumVon,
          link: true,
          id: this.htmlIdDatumVon,
        },
        datum_bis: {
          label: this.labelDatumBis,
          link: true,
          id: this.htmlIdDatumBis,
        },
        tage: {
          label: this.labelTage,
          link: true,
          id: this.htmlIdTage,
        },
        teilnehmer: {
          label: this.labelTeilnehmer,
          link: true,
          id: this.htmlIdTeilnehmer,
        },
        honorartage: {
          label: this.labelHonorartage,
          link: true,
          id: this.htmlIdHonorartage,
        },
        teilnehmer_uebernachtung: {
          label: this.labelTeilnehmerUebernachtung,
          link: true,
          id: this.htmlIdTeilnehmerUebernachtung,
        },
      };
    },
  },
  created() {
    this.initErrorLabels();
  },
};
