"use strict";

import angular from "angular";
import uiRouter from "angular-ui-router";
import diasLoading from "dias/core/loading";

import diasAuthService from "dias/auth/services";

import models from "../models";


const modul = angular.module("dias.vertrag.belegdokumente", [
  uiRouter,
  diasLoading.name,
  models.name,
  diasAuthService.name,
])
;

export default modul;
