"use strict";

import baseController from "./table.controller";

class DndTableController extends baseController {
  /*@ngInject*/
  constructor(
    $scope, 
    $q, 
    $sessionStorage, 
    $templateCache, 
    $location, 
    $httpParamSerializer, 
    $stateParams,
    $timeout, 
    DiasNotification, 
    diasConfirmDialog, 
    $filter, 
    moment,
    WorkflowfunktionRegistry, 
    Workflowfolge, 
    diasModalDialog, 
    Nutzerfilter, 
    Nutzertabellenconfigs,
    SyConfigs, 
    AuthService, 
    i18n, 
    Katalog
  ) {
    super(
      $scope, 
      $q, 
      $sessionStorage, 
      $templateCache, 
      $location, 
      $httpParamSerializer, 
      $stateParams,
      $timeout, 
      DiasNotification, 
      diasConfirmDialog, 
      $filter, 
      moment,
      WorkflowfunktionRegistry, 
      Workflowfolge, 
      diasModalDialog, 
      Nutzerfilter, 
      Nutzertabellenconfigs,
      SyConfigs, 
      AuthService, 
      i18n, 
      Katalog
    );
  }

  _furtherConfig(conf) {
    if (angular.isFunction(conf.dndDisabled)) {
      this.dndDisabled = conf.dndDisabled;
    } else {
      this.dndDisabled = () => false;
    }
    if (angular.isFunction(conf.dndDrop)) {
      this.dndDrop = (event, index, row, type, external) => conf.dndDrop(event, index, row, type, external, this.data);
    } else {
      this.dndDrop = (event, index, row) => row;
    }
    this._setDndActive(false);
  }

  _removeActionColumn() {
    if (this._hasActionColumn()) {
      this.columnDefs.splice(this.columnDefs.length - 1, 1);
    }
  }

  _addActionColumn() {
    if (!this._hasActionColumn()) {
      super._addActionColumn();
    }
  }

  _hasActionColumn() {
    const lastColumn = this.columnDefs[this.columnDefs.length - 1];
    return lastColumn.field === "aktionen";
  }

  _setDndActive(bool) {
    this.dndActive = bool;
    if (bool) {
      this._removeActionColumn();
    } else {
      this._addActionColumn();
    }
  }
}

export default DndTableController;
