import Spinner from "../../Spinner/Spinner.vue";
import Permission from "../../Permission/Permission.vue";
import translate from "../../../directives/translate";
import { getUrlParams } from "../../../functions/help";
import { createNamespacedHelpers } from "vuex";

import {
  isFunction
} from "lodash-es";


const { mapGetters } = createNamespacedHelpers("table");

// @vue/component
export default {
  name: "TablePanelActionsButton",
  components: {
    Spinner,
    Permission,
  },
  directives: {
    translate,
  },
  props: {
    loading: {
      type: Boolean,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    tableOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters([
      "GET_URL_PARAMS",
    ]),

    hide() {
      return isFunction(this.options.isHidden) && this.options.isHidden();
    }
  },
  methods: {
    onClick() {
      const URL_PARAMS = this.GET_URL_PARAMS({ tableId: this.tableOptions.id });
      const URL_PARAMS_OBJ = getUrlParams({ url: URL_PARAMS });
      this.options.callback({ urlParams: URL_PARAMS, urlParamsObj: URL_PARAMS_OBJ, options: this.options });
    },
  },
};
