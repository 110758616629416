import ComponentsBerichte from "../Module/index";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "BerichteDetails",
  components: {
    PuxCloak,
    ...ComponentsBerichte,
  },
  mixins: [
    HttpMixin,
  ],
  data() {
    return {
      loading: true,
      report: undefined,
    };
  },
  created() {
    this.loadReport();
  },
  methods: {
    loadReport() {
      this.getHttp({ url: `tableau/${ this.$stateParams.id }/` }).then(
        response => {
          this.report = response;
          this.loading = false;
        }
      );
    },
  },
};
