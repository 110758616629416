"use strict";

import angular from "angular";

import authService from "dias/auth/services";

import permissionDirective from "./permission.directive.js";

const permissionModule = angular.module("auth.permissions", [
  authService.name
])

  .directive("permission", permissionDirective)
;

export default permissionModule;
