import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  getTextFromPlaceholder,
} from "../../../../../global/functions/utils";

// @vue/component
export default {
  name: "VertragWizardFoerdervertragsbestaetigungErrorsItem",
  components: {
    PuxButton,
    PuxTranslate,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
    errorIndex: {
      type: Number,
      required: true,
    },
    showErrorModulName: {
      type: Function,
      required: true,
    },
  },
  emits: [
    "goToWizardStep",
  ],
  computed: {
    errorKontext() {
      return this.showErrorModulName(this.error);
    },

    errorKontextTranslated() {
      return getTextFromPlaceholder(this.errorKontext);
    },

    buttonId() {
      return `wizard_fvb_${ this.errorIndex }`;
    },

    extraForTranslate() {
      return {
        name: this.errorKontextTranslated,
      };
    },
  },
  methods: {
    goToWizardStepLocal() {
      this.$emit("goToWizardStep", this.error);
    },
  },
};
