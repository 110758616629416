// @vue/component
export default {
  data() {
    return {
      fields: [
        {
          id: "suche",
          type: "text",
          label: "_LBL_IMPORTEXPORTAUFTRAG_ANTRAG_WIZARD_ANTRAGSTELLER_SUCHE_",
          required: false,
          view: "v",
          translate: true,
        },
      ]
    };
  },
};
