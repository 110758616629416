"use strict";

import angular from "angular";
import "restangular";
import diasLoading from "dias/core/loading";

import kontoComponent from "./astkonto";

const astkonto = angular.module("dias.antragsteller.konto", [
  diasLoading.name,
]);


astkonto.component("astkonto", kontoComponent);


export default astkonto;
