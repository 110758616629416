import PuxGet from "../../../global/components/PuxGet/PuxGet.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import {
  EventBus,
} from "../../../global/functions/event-bus";
import {
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "MenuDetails",
  components: {
    PuxGet,
    PuxTranslate,
  },
  props: {
    rubrics: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  emits: [
    "openRubric",
  ],
  data() {
    return {
      statusRender: undefined,
      statusOpen: true,
      idChildrenBox: "menu_details__children_box",
    };
  },
  computed: {
    ariaExpanded() {
      return `${ this.statusOpen }`;
    },
  },
  mounted() {
    this.renderEl();
    if (this.statusRender) {
      EventBus.$emit("toggleMenuDetails", { statusShow: true });
    }
  },
  beforeUnmount() {
    if (this.$el && isFunction(this.$el.remove)) {
      this.$el.remove();
    }
    if (this.statusRender) {
      EventBus.$emit("toggleMenuDetails", { statusShow: false });
    }
  },
  methods: {
    renderEl() {
      const MENU_DETAILS = document.getElementById("menu_details");
      if (MENU_DETAILS) { // PROANDI-Menu
        MENU_DETAILS.appendChild(this.$el);
        this.statusRender = true;
      }
    },

    toggleMenuDetails() {
      this.statusOpen = !this.statusOpen;
    },

    onClickChildLocal({ rubric, index }) {
      this.$emit("openRubric", { rubric, index });
    },
  },
};
