"use strict";

class AsyncCsvDownloadController {
  /*@ngInject*/
  constructor($scope, $interval, moment, DiasNotification, CsvDownload, SyConfigs) {
    this.DiasNotification = DiasNotification;
    this.CsvDownload = CsvDownload;
    this.$interval = $interval;
    this.errors = null;
    this.extras = $scope.extras;
    this.configTimeToInvalid = SyConfigs.wert("cache_time"); // in Seconds
    this.configTimeToInvalid = Math.floor(this.configTimeToInvalid / 60); // in Minutes
    this.configMaxEntries = SyConfigs.wert("csv_max_eintraege");

    this.onInit = () => {
      this.taskId = undefined;
      this.dateiUrl = undefined;
      this.dateiName = undefined;
      this.gueltigBis = undefined;
      this.toInvalidPromise = undefined;
      this.requestingCsv = true;
      this.CsvDownload.requestCsvAsync(this.extras.baseUrl, this.extras.urlParams, this.extras.action).then(
        response => this.taskId = response.task_id,
        err => {
          this.DiasNotification.form.clear();
          this.DiasNotification.form.error(err.data, "Fehler beim Anfragen der CSV-Datei");
        }
      ).finally(() => this.requestingCsv = false);
    };

    this.onInit();

    this.onSuccess = result => {
      this.dateiUrl = CsvDownload.getAsyncCsvLink(this.extras.baseUrl, this.taskId, this.extras.downloadAction);
      this.dateiName = result.name;
      this.gueltigBis = moment(result.cached_until);
      this.calcTimeToInvalid();
      this.toInvalidPromise = $interval(this.calcTimeToInvalid, 1000);
    };

    this.onFailure = err => {
      console.error(err);
      this.DiasNotification.form.clear();
      this.DiasNotification.form.error(err.data, "Fehler beim Erstellen der CSV-Datei");
    };

    this.calcTimeToInvalid = () => {
      this.dateiGueltig = moment().isBefore(this.gueltigBis);
      this.timeToInvalid = this.gueltigBis.diff(moment(), "minutes");
      if (!this.dateiGueltig && this.toInvalidPromise) {
        $interval.cancel(this.toInvalidPromise);
        this.toInvalidPromise = undefined;
      }
    };

    this.close = () => {
      if (this.toInvalidPromise) {
        $interval.cancel(this.toInvalidPromise);
      }
      $scope.exit();
    };
  }
}

export default AsyncCsvDownloadController;
