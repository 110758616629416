import PuxGet from "../../../../global/components/PuxGet/PuxGet.vue";
import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTooltip from "../../../../global/components/PuxTooltip/PuxTooltip.vue";

import FinanzierugMixin from "../Mixins/FinanzierugMixin";
import ZuschussMixin from "../Mixins/ZuschussMixin";
import PeriodenMixin from "../Mixins/PeriodenMixin";
import {
  FilterCurrencyMixin,
} from "../../../../global/mixins/FiltersMixin";

import {
  forEach,
  get,
  isFunction,
  toNumber,
} from "lodash-es";

// @vue/component
export default {
  name: "ZuschussPerioden",
  components: {
    PuxGet,
    PuxIcon,
    PuxTooltip,
  },
  mixins: [
    FinanzierugMixin,
    ZuschussMixin,
    FilterCurrencyMixin,
    PeriodenMixin,
  ],
  computed: {
    htmlId() {
      return `${ this.htmlRegelNummer }_zuschuss`;
    },

    sumLocal() {
      return this.antragBetragCurrency;
    },

    periodenLocalBewilligt() {
      return get(this.extra, "antrag.a_zuschussbewilligt_data.perioden");
    },

    modelWithPositionenForPeriode() {
      return this.setModelWithPositionenForPeriode({
        zuschussPerioden: this.infoprovider ? this.infoprovider.perioden : {},
        statusSnapshot: false,
      });
    },

    modelWithPositionenForPeriodeSnapshot() {
      return this.setModelWithPositionenForPeriode({
        zuschussPerioden: this.snapshotZuschuss.perioden,
        statusSnapshot: true,
      });
    },

    statusZuschussValid() {
      return this.kontext === "zuschuss" && this.statusZuschuss === 1;
    },

    isDiffPerioden() {
      return get(this.extra, "isDiffPerioden");
    },

    statusShowProzenteZuschuss() {
      return this.kontext !== "zuschuss" && this.statusShowProzente;
    },

    sumPeriodenKosten() {
      return get(this.extra, "sumPeriodenKosten");
    },

    sumPeriodenFinanzierung() {
      return get(this.extra, "sumPeriodenFinanzierung");
    },

    percentsByPeriods() {
      const periodsWithPercents = {};
      forEach(this.perioden, period => {
        const periodKostenFound = get(this.sumPeriodenKosten, period.pk);
        const periodFinanzierungFound = get(this.sumPeriodenFinanzierung, period.pk);
        if (periodKostenFound && periodFinanzierungFound) {
          const percentValueWert = (periodKostenFound.wert - periodFinanzierungFound.wert) / periodKostenFound.wert * 100;
          const percentValueBetragErfasst = (periodKostenFound.betrag_erfasst - periodFinanzierungFound.betrag_erfasst) / periodKostenFound.betrag_erfasst * 100;
          periodsWithPercents[period.pk] = {
            percentValue: {
              wert: percentValueWert ? this.filterCurrency(percentValueWert, "%") : null,
              betrag_erfasst: percentValueBetragErfasst ? this.filterCurrency(percentValueBetragErfasst, "%") : null,
            },
          };
        }
      });
      return periodsWithPercents;
    },

    periodenLocal() {
      return get(this.extra, "periodenFiltered", []);
    },

    screenreaderLabelLocal() {
      return get(this.extraStatic, "text", "");
    },
  },
  methods: {
    loadVertragFinanzierungRest_FinanzierugMixin() {
      this.loading = false;
      this.reloading = false;
    },

    toggleTable() {
      this.statusTableShow = !this.statusTableShow;
    },

    setModelWithPositionenForPeriode({ zuschussPerioden = {}, statusSnapshot }) {
      const MODEL = {
        positionen: {},
        all: {
          wert: 0,
          wertCurrency: this.filterCurrency(0),
        },
      };
      forEach(this.perioden, period => {
        const PERIOD_PK = period.pk;
        MODEL[PERIOD_PK] = {
          wert: 0,
          wertCurrency: this.filterCurrency(0),
          meldungen: [],
        };
      });
      forEach(zuschussPerioden, (periodData, periodPk) => {
        MODEL[periodPk] = MODEL[periodPk] || {};
        MODEL[periodPk].wert = toNumber(periodData.zuschuss);
        MODEL[periodPk].wertCurrency = this.filterCurrency(periodData.zuschuss);
        MODEL[periodPk].meldungen = periodData.meldungen || [];
        MODEL.all.wert += MODEL[periodPk].wert;
      });
      MODEL.all.wertCurrency = this.filterCurrency(MODEL.all.wert);
      if (isFunction(this.extraStatic.setPeriodenWert)) {
        this.extraStatic.setPeriodenWert({
          model: MODEL,
          regel: this.regel.regel,
          statusSnapshot,
          typeKoFi: this.kontext === "zuschuss" ? "zuschuss" : this.typeKoFi,
        });
      }
      return MODEL;
    },
  },
};
