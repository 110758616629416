import FormElementReadOnly from "../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

import translate from "../../../../../global/directives/translate";

import UserMixin from "../../../../../global/mixins/UserMixin";

import {
  cloneDeep,
  filter,
  find,
  forEach,
  get,
  isEmpty,
  isNil,
} from "lodash-es";

// @vue/component
export default {
  name: "DynamischeTabelleTr",
  components: {
    FormElementReadOnly,
    SnapshotIcon,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    UserMixin,
  ],
  props: {
    element: {
      type: Object,
      required: true,
    },
    elementIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
    statusReadonly: {
      type: Boolean,
      required: true,
    },
    spalten: {
      type: Array,
      required: true,
    },
    spaltenObjSnapshot: { // Nur für snapshot
      type: Object,
      required: true,
    },
    htmlRegelNummer: {
      type: String,
      required: false,
      default: undefined,
    },
    statusShowRowNumber: {
      type: Boolean,
      required: false,
      default: () => false
    },
    statusMovable: {
      type: Boolean,
      required: false,
      default: () => false
    },
    isFirst: {
      type: Boolean,
      required: false,
    },
    isLast: {
      type: Boolean,
      required: false,
    },
    statusSnapshot: {
      type: Boolean,
      required: false,
    },
    statusSnapshotAdd: {
      type: Boolean,
      required: false,
    },
    statusSnapshotDelete: {
      type: Boolean,
      required: false,
    },
    statusSnapshotChanges: {
      type: Boolean,
      required: false,
    },
    statusSnapshotChangesIcon: {
      type: Boolean,
      required: false,
    },
    snapshotDate: {
      type: String,
      required: false,
      default: undefined,
    },
    snapshotChangesDiff: {
      type: Object,
      required: false,
      default: undefined,
    },
    dataCollection: {
      type: Object,
      required: true,
    },
    extraForTranslate: {
      type: Object,
      required: false,
      default: undefined,
    },
    openModalCreateOrEdit: {
      type: Function,
      required: false,
      default: () => {},
    },
    confirmDelete: {
      type: Function,
      required: false,
      default: () => {},
    },
    moveUp: {
      type: Function,
      required: false,
      default: () => {},
    },
    moveDown: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  computed: {
    idButtonEdit() {
      return `${ this.htmlRegelNummer }_edit_${ this.element.pk }`;
    },

    tooltipButtonEdit() {
      return this.disabledForUser ? "_BTN_GR_DYN_TABELLE_EDIT_TITLE_DISABLED_{{element_bezeichnung}}_{{user_bez}}_" : "_BTN_GR_DYN_TABELLE_EDIT_TITLE_{{element_bezeichnung}}_";
    },

    idButtonDelete() {
      return `${ this.htmlRegelNummer }_delete_${ this.element.pk }`;
    },

    tooltipButtonDelete() {
      return this.disabledForUser ? "_BTN_GR_DYN_TABELLE_DELETE_TITLE_DISABLED_{{element_bezeichnung}}_{{user_bez}}_" : "_BTN_GR_DYN_TABELLE_DELETE_TITLE_{{element_bezeichnung}}_";
    },

    idButtonUp() {
      return `${ this.htmlRegelNummer }_up_${ this.element.pk }`;
    },

    idButtonDown() {
      return `${ this.htmlRegelNummer }_down_${ this.element.pk }`;
    },

    statusShowButtons() {
      return !this.statusSnapshotDelete && !this.statusSnapshotChanges;
    },

    rowNumber() {
      if (this.statusSnapshot && this.statusSnapshotChanges) {
        return get(this.snapshotChangesDiff, "rownum");
      }
      return (this.elementIndex || 0) + 1;
    },

    rowNumberSpan() {
      return (this.statusSnapshotChangesIcon && isNil(get(this.snapshotChangesDiff, "rownum"))) ? 2 : null;
    },

    statusRowNumberVisible() {
      return this.statusShowRowNumber && (!this.statusSnapshotChanges || !isNil(get(this.snapshotChangesDiff, "rownum")));
    },

    snapshotIconFirstTdType() {
      if (this.statusSnapshotAdd) {
        return "add";
      }
      if (this.statusSnapshotDelete) {
        return "delete";
      }
      if (this.statusSnapshotChangesIcon) {
        return "changes";
      }
      return "";
    },

    translateVersionTdOptions() {
      return {
        text: this.translateVersionTdText,
        extra: {
          date: this.snapshotDate,
        },
      };
    },

    translateVersionTdText() {
      if (this.statusSnapshotAdd) {
        return "_TXT_AKTUELLE_VERSION_ADDED_COLUMN_{{date}}_";
      }
      if (this.statusSnapshotDelete) {
        return "_TXT_VERSION_REMOVED_COLUMN_{{date}}_";
      }
      if (this.statusSnapshotChanges) {
        return "_TXT_VERSION_MODIFIED_COLUMN_{{date}}_";
      }
      return "_TXT_AKTUELLE_VERSION_";
    },

    classVersion() {
      if (this.statusSnapshotDelete || this.statusSnapshotChanges) {
        return "version-bg";
      }
      return "";
    },

    rowspanFirstTd() {
      return this.statusSnapshotChangesIcon ? 2 : 1;
    },

    statusShowFirstTd() {
      return !(this.statusSnapshotChanges && !this.statusSnapshotChangesIcon);
    },

    spaltenLocal() {
      const SPALTEN = cloneDeep(this.spalten);
      forEach(SPALTEN, spalte => {
        if (spalte.type === "user" && get(this.element, spalte.id)) {
          spalte.text = get(this.element, spalte.id + "_bez", this.me.name);
        }
        if (spalte.type === "date" && spalte.uhrzeit) {
          spalte.type = "datetime";
        }
      });
      return SPALTEN;
    },

    spaltenFiltered() {
      if (this.statusSnapshotChanges && this.snapshotChangesDiff) {
        return filter(this.spaltenLocal, item => {
          return this.snapshotChangesDiff[item.id];
        });
      }
      return this.spaltenLocal;
    },

    rowspan() {
      const ROWSPAN = {};
      if (this.statusSnapshotChangesIcon && this.snapshotChangesDiff) {
        forEach(this.spalten, item => {
          const ID = item.id;
          if (!this.snapshotChangesDiff[ID]) {
            ROWSPAN[ID] = 2;
          }
        });
      }
      return ROWSPAN;
    },

    statusSnapshotDeleteOrChange() {
      return this.statusSnapshotDelete || this.statusSnapshotChanges;
    },

    assignedUserPk() {
      const USER_SPALTEN = filter(this.spalten, ["type", "user"]);
      if (isEmpty(USER_SPALTEN)) {
        return;
      }
      return get(this.element, USER_SPALTEN[0].id);
    },

    disabledForUser() {
      return this.assignedUserPk && this.assignedUserPk !== this.me.pk;
    },

    extraForTranslateLocal() {
      const OBJECT = cloneDeep(this.extraForTranslate);
      const USER_SPALTE = find(this.spaltenLocal, ["type", "user"]);
      OBJECT.user_bez = get(USER_SPALTE, "text", "");
      return OBJECT;
    },
  },
  methods: {
    openModalCreateOrEditLocal() {
      this.openModalCreateOrEdit({
        element: this.element,
        index: this.elementIndex,
        selectorClose: `#${ this.idButtonEdit }`,
      });
    },

    confirmDeleteLocal() {
      this.confirmDelete({
        index: this.elementIndex,
        selectorClose: `#${ this.idButtonDelete }`,
      });
    },

    moveUpLocal() {
      this.moveUp({
        index: this.elementIndex
      });
    },

    moveDownLocal() {
      this.moveDown({
        index: this.elementIndex
      });
    },
  },
};
