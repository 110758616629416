import { getTextFromPlaceholder } from "../global/functions/utils";
import {
  cloneDeep,
  forEach,
  isNil,
  isArray,
  isPlainObject,
  size,
} from "lodash-es";

export default {
  namespaced: true,
  state() {
    return {
      TABLES: {},
      TABLES_MULTIPLE_ACTIONS: {},
    };
  },
  getters: {
    GET_TABLE_MODEL_BY_ID: state => id => {
      return state.TABLES[id].model;
    },

    GET_TABLE_DEFINED_MODEL_BY_ID: state => id => {
      const model = cloneDeep(state.TABLES[id].model);
      forEach(model, (item, key) => {
        if (isArray(item) && !item.length) {
          delete model[key];
        } else if (isNil(item)) {
          delete model[key];
        } else if (isPlainObject(item)) {
          forEach(item, (i, k) => {
            if (isNil(i)) {
              delete item[k];
            }
          });
          if (!size(item)) {
            delete model[key];
          }
        }
      });
      return model;
    },

    GET_TABLE_DEFINED_CURRENT_MODEL: state => ({ tableId, filterId }) => {
      return state.TABLES[tableId].model[filterId];
    },

    GET_LABEL_BY_ID: state => (tableId, labelId, currentModel) => {
      if (!state.TABLES[tableId].modelLabels[labelId]) {
        const LABEL_NEW = `${ labelId }_${ currentModel }`;
        if (state.TABLES[tableId].modelLabels[LABEL_NEW]) {
          return state.TABLES[tableId].modelLabels[LABEL_NEW].child || "Loading";
        } else if (state.TABLES[tableId].modelLabels[currentModel]) {
          return state.TABLES[tableId].modelLabels[currentModel].child || "Loading";
        }
        return "Loading";
      }
      const labelObj = state.TABLES[tableId].modelLabels[labelId];
      return `${ getTextFromPlaceholder(labelObj.child) }${ labelObj.suffix ? ` ${ labelObj.suffix }` : "" }: ${ labelObj[currentModel] || currentModel }`;
    },

    GET_LABEL_ARRAY_BY_ID: state => (tableId, labelId, filterId) => {
      if (!state.TABLES[tableId].modelLabels[`${ filterId }_${ labelId }`]) {
        return "Loading";
      }
      // return `<strong>${ state.TABLES[tableId].modelLabels[labelId].parent }</strong>:<span>${ state.TABLES[tableId].modelLabels[labelId].child }</span>`;
      return state.TABLES[tableId].modelLabels[`${ filterId }_${ labelId }`].child ;
    },

    GET_IS_FILTER_BY_ID: state => tableId => {
      const model = state.TABLES[tableId].model;
      let statusIsFilter = false;
      forEach(model, mod => {
        if (isArray(mod)) {
          if (mod.length) {
            statusIsFilter = true;
            return false;
          }
        } else if (isPlainObject(mod)) {
          forEach(mod, item => {
            if (!isNil(item)) {
              statusIsFilter = true;
              return false;
            }
          });
        } else if (!isNil(mod)) {
          statusIsFilter = true;
          return false;
        }
      });
      return statusIsFilter;
    },

    GET_CONFIG: state => tableId => {
      return state.TABLES[tableId].config;
    },

    GET_DATA_LIST: state => tableId => {
      return state.TABLES[tableId].dataList;
    },

    GET_SORT_MODEL: state => tableId => {
      return state.TABLES[tableId].sortModel;
    },

    GET_URL_PARAMS: state => ({ tableId }) => {
      return state.TABLES[tableId].urlGetParams;
    },

    GET_SORT_TH: state => ({ tableId, colId }) => {
      const sortModel = state.TABLES[tableId].sortModel;
      if (sortModel === colId) {
        return "ascending";
      }
      if (sortModel === `-${ colId }`) {
        return "descending";
      }
      return "none";
    },

    GET_MULTIPLE_STATUS: state => ({ tableId }) => {
      return state.TABLES_MULTIPLE_ACTIONS[tableId] && state.TABLES_MULTIPLE_ACTIONS[tableId].status;
    },

    GET_MULTIPLE_MODEL_COUNT: state => ({ tableId }) => {
      return state.TABLES_MULTIPLE_ACTIONS[tableId] && state.TABLES_MULTIPLE_ACTIONS[tableId].model.length;
    },

    GET_MULTIPLE_MODEL: state => ({ tableId }) => {
      return state.TABLES_MULTIPLE_ACTIONS[tableId] && state.TABLES_MULTIPLE_ACTIONS[tableId].model;
    },
  },
  mutations: {
    MUT_CREATE_TABLE_BY_ID(state, id) {
      const tables = cloneDeep(state.TABLES);
      tables[id] = {
        model: {},
        modelLabels: {},
        multiple: {
          status: undefined,
        },
        config: {
          id: undefined,
          ntc_kennung: undefined,
          ntc_conf: undefined,
          ntc_anzahl: undefined,
        },
        dataList: {},
        sortModel: undefined,
        urlGetParams: undefined,
      };
      state.TABLES = tables;
    },

    MUT_REMOVE_TABLE_BY_ID(state, { id }) {
      const tables = cloneDeep(state.TABLES);
      if (tables[id]) {
        delete tables[id];
      }
      state.TABLES = tables;
    },

    MUT_CHANGE_MODEL_TABLE_BY_ID(state, { id, model }) {
      const tables = cloneDeep(state.TABLES);
      tables[id].model = cloneDeep(model);
      state.TABLES = tables;
    },

    MUT_CHANGE_CURRENT_MODEL_TABLE_BY_ID(state, { tableId, model, filterId }) {
      const tables = cloneDeep(state.TABLES);
      tables[tableId].model[filterId] = cloneDeep(model);
      state.TABLES = tables;
    },

    MUT_CHANGE_URL_GET_PARAMS(state, { tableId, url }) {
      const tables = cloneDeep(state.TABLES);
      tables[tableId].urlGetParams = url;
      state.TABLES = tables;
    },

    MUT_ADD_LABEL_BY_ID(state, { tableId, labelId, label, filterId }) {
      const tables = cloneDeep(state.TABLES);
      const LABEL_NAME = filterId && filterId !== labelId ? `${ filterId }_${ labelId }` : labelId;
      if (tables[tableId].modelLabels[LABEL_NAME]) {
        return;
      }
      tables[tableId].modelLabels[LABEL_NAME] = label;
      state.TABLES = tables;
    },

    MUT_INIT_LABEL_BY_ID(state, { id, labels }) {
      const tables = cloneDeep(state.TABLES);
      tables[id].modelLabels = cloneDeep(labels);
      state.TABLES = tables;
    },

    MUT_ADD_DATA_LIST(state, { tableId, filterId, list }) {
      const tables = cloneDeep(state.TABLES);
      tables[tableId].dataList[filterId] = list;
      state.TABLES = tables;
    },

    MUT_SET_CONFIG(state, { tableId, configId, ntc_kennung, ntc_conf, ntc_anzahl }) {
      const tables = cloneDeep(state.TABLES);
      tables[tableId].config.id = configId || tables[tableId].config.id;
      tables[tableId].config.ntc_kennung = ntc_kennung || tables[tableId].config.ntc_kennung;
      tables[tableId].config.ntc_conf = ntc_conf || tables[tableId].config.ntc_conf;
      tables[tableId].config.ntc_anzahl = ntc_anzahl || tables[tableId].config.ntc_anzahl;

      state.TABLES = tables;
    },

    MUT_SET_SORT_MODEL(state, { tableId, sortModel }) {
      const tables = cloneDeep(state.TABLES);
      tables[tableId].sortModel = sortModel;
      state.TABLES = tables;
    },

    MUT_TOGGLE_MULTIPLE_STATUS(state, { tableId, status }) {
      const TABLES_MULTIPLE_ACTIONS = cloneDeep(state.TABLES_MULTIPLE_ACTIONS);
      if (!TABLES_MULTIPLE_ACTIONS[tableId]) {
        TABLES_MULTIPLE_ACTIONS[tableId] = {
          status,
          model: [],
        };
      } else {
        TABLES_MULTIPLE_ACTIONS[tableId].status = status;
      }
      state.TABLES_MULTIPLE_ACTIONS = TABLES_MULTIPLE_ACTIONS;
    },

    MUT_CLEAN_MULTIPLE_MODEL(state, { tableId }) {
      if (!state.TABLES_MULTIPLE_ACTIONS[tableId]) {
        return;
      }
      const TABLES_MULTIPLE_ACTIONS = cloneDeep(state.TABLES_MULTIPLE_ACTIONS);
      TABLES_MULTIPLE_ACTIONS[tableId].model = [];
      state.TABLES_MULTIPLE_ACTIONS = TABLES_MULTIPLE_ACTIONS;
    },

    MUT_ADD_MULTIPLE_MODEL(state, { tableId, model }) {
      const TABLES_MULTIPLE_ACTIONS = cloneDeep(state.TABLES_MULTIPLE_ACTIONS);
      TABLES_MULTIPLE_ACTIONS[tableId].model = cloneDeep(model);

      state.TABLES_MULTIPLE_ACTIONS = TABLES_MULTIPLE_ACTIONS;
    }
  },
  actions: {

  },
};
