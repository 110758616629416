import {
  computed,
  ref,
  toRef,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import {
  cloneDeep,
  forEach,
  isArray,
  size,
} from "lodash-es";

export default function AnonymisierungPlanFormstepDetailAPI(props, {
  anonymisierungsplan = ref([]),
  anonymisierungsplanUrl = computed(() => ""),
  katalogList = ref([]),
}) {
  const model = ref({});
  const errors = ref(undefined);

  const open = () => {
    const MODEL = {};
    forEach(cloneDeep(anonymisierungsplan.value), anp => {
      const ANP_KEY = `${ anp.anon_content_type }_${ anp.anp_stufe }`;
      MODEL[ANP_KEY] = {
        selected: true,
        el: anp,
      };
    });
    if (katalogList.value.length) {
      forEach(cloneDeep(katalogList.value), katalogItem => {
        const STUFEN = katalogItem.stufen || [];
        forEach(STUFEN, stufe => {
          const KATALOG_STUFE_KEY = `${ katalogItem.pk }_${ stufe.pk }`;
          MODEL[KATALOG_STUFE_KEY] = MODEL[KATALOG_STUFE_KEY] || {
            selected: false,
            el: {
              anon_content_type: parseInt(katalogItem.pk),
              anp_stufe: stufe.pk,
              anp_frist: undefined,
            },
          };
        });
      });
    }
    model.value = MODEL;
  };
  const getErrorsAndErrorsLabels = ({ error, dataForSave = [] }) => {
    const ERRORS_REFORMED = {};
    let errorsLocal;
    if (error.data) {
      errorsLocal = error.data;
    }
    if (!isArray(errorsLocal)) {
      errorsLocal = [errorsLocal];
    }
    forEach(errorsLocal, (err, index) => {
      if (!size(err)) {
        return;
      }
      const errModel = dataForSave[index];
      const errList = [];
      Object.keys(err).forEach(errKey => errList.push(...err[errKey]));
      ERRORS_REFORMED[`${ errModel.anon_content_type }_${ errModel.anp_stufe }_anp_frist`] = errList;
    });

    return ERRORS_REFORMED;
  };

  const deleteErrors = () => {
    errors.value = undefined;
  };

  const {
    postHttp,
  } = HttpAPI();
  const {
    addNotification,
  } = NotificationAPI();
  const save = () => {
    return new Promise((resolve, reject) => {
      deleteErrors();
      const DATA_FOR_SAVE = [];
      forEach(cloneDeep(model.value), item => {
        if (item.selected) {
          DATA_FOR_SAVE.push(item.el);
        }
      });

      postHttp({
        url: `${ anonymisierungsplanUrl.value }set_konfig/`,
        data: DATA_FOR_SAVE,
      }).then(
        response => {
          anonymisierungsplan.value = response;
          addNotification({ text: "_MSG_UPDATE_SUCCESS_" });
          resolve();
        },
        error => {
          const ERRORS = getErrorsAndErrorsLabels({ error, dataForSave: DATA_FOR_SAVE });
          errors.value = ERRORS;
          reject(ERRORS);
        },
      );
    });
  };

  const close = () => {
    deleteErrors();
  };

  const planHeaderText = toRef(props, "planHeaderText");
  const optionsFormstepDetail = computed(() => {
    return {
      label: planHeaderText.value,
      required: true,
      openCallback: open,
      saveCallback: save,
      closeCallback: close,
    };
  });

  const errorsLabelsOptions = computed(() => {
    const ERRORS_LABELS_OPTIONS = [];
    forEach(katalogList.value, katalog => {
      const STUFEN = katalog.stufen || [];
      forEach(STUFEN, stufe => {
        ERRORS_LABELS_OPTIONS.push({
          id: `${ katalog.pk }_${ stufe.pk }_anp_frist`,
          label: `${ katalog.bez } - ${ stufe.bez }`
        });
      });
    });
    return ERRORS_LABELS_OPTIONS;
  });

  return {
    errors,
    errorsLabelsOptions,
    model,
    optionsFormstepDetail,
  };
}
