import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxAlert from "../../../../global/components/PuxAlert/PuxAlert.vue";
import PuxGet from "../../../../global/components/PuxGet/PuxGet.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import ShowMore from "../../../../global/components/ui/ShowMore/ShowMore.vue";
import WorkflowtypDetailsStatusgruppenBlock
  from "./WorkflowtypDetailsStatusgruppenBlock/WorkflowtypDetailsStatusgruppenBlock.vue";
import WorkflowtypDetailsStatusgruppenBlockEdit
  from "./WorkflowtypDetailsStatusgruppenBlockEdit/WorkflowtypDetailsStatusgruppenBlockEdit.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

import KworkflowstatusgruppentypUUIDs from "../../../../const/kworkflowstatusgruppentypUUIDs";

import {
  cloneDeep,
  find,
  forEach,
  isNil,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "WorkflowtypDetailsStatusgruppen",
  components: {
    AngularLink,
    FormElement,
    FormstepDetail,
    PuxAlert,
    PuxGet,
    PuxTranslate,
    ShowMore,
    WorkflowtypDetailsStatusgruppenBlock,
    WorkflowtypDetailsStatusgruppenBlockEdit,
  },
  props: {
    workflowtyp: {
      type: Object,
      required: true,
    },
    workflowstatusgruppentypen: {
      type: Array,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
  emits: [
    "updateWorkflowtyp",
  ],
  setup() {
    const {
      checkPermissionsSync,
    } = PermissionAPI();

    const {
      addNotification,
    } = NotificationAPI();

    const {
      postHttp,
    } = HttpAPI();

    return {
      checkPermissionsSync,
      addNotification,
      postHttp,
    };
  },
  data() {
    return {
      loading: false,
      errors: {},
      errorsLocal: [],
      dataToSave: [],
      formstepOptions: {
        label: "_LBL_WORKFLOWTYP_DETAILS_FORMSTEP_STATUSGRUPPEN_",
        openCallback: this.open,
        saveCallback: this.save,
        closeCallback: this.close,
      },
      htmlId: "workflowtypen_statusgruppen",
      model: {},
    };
  },
  computed: {
    hasPermissionEditStatusgruppen() {
      return this.checkPermissionsSync({ perm: "workflowtyp.update.statusgruppen" });
    },

    hasPermissionKaufgabeUi() {
      return this.checkPermissionsSync({ perm: "kaufgabe.ui" });
    },

    workflowStatusGruppenTypenUUIDs() {
      return KworkflowstatusgruppentypUUIDs;
    },

    workflowGruppenAll() {
      const LIST = {};
      forEach(KworkflowstatusgruppentypUUIDs, pk => {
        LIST[pk] = {};
        LIST[pk].workflowStatusGruppenTypen = this.getStatusGruppenTypenFilteredByPk({ pk: pk });
        LIST[pk].bez = this.getGrouppenBezeichnungByPk({ pk: pk });
      });
      return LIST;
    },

    singleGroupContainer() {
      return this.workflowGruppenAll[this.workflowStatusGruppenTypenUUIDs.erfolg]
        .workflowStatusGruppenTypen.length === 1;
    },
  },
  methods: {
    getGrouppenBezeichnungByPk({ pk }) {
      return this.workflowstatusgruppentypen
        .find(workflowstatusgruppentyp => workflowstatusgruppentyp.pk === pk).bez;
    },

    getStatusGruppenTypenFilteredByPk({ pk }) {
      return this.workflowtyp.statusgruppen
        .filter(statusgruppe => statusgruppe.workflowstatusgruppentyp === pk)
        .sort((a, b) => a.wsg_pos - b.wsg_pos);
    },

    open() {
      this.setModel();
    },

    setModel() {
      const MODEL = this.getModelFrame();
      forEach(KworkflowstatusgruppentypUUIDs, pk => {
        forEach(MODEL[pk], (item, key) => {
          const GROUP = this.workflowtyp.statusgruppen.find(gruppe => gruppe.wsg_pos === item.wsg_pos);
          if (GROUP) {
            MODEL[pk][key].wsg_kbez = GROUP.wsg_kbez;
            MODEL[pk][key].wsg_bez = GROUP.wsg_bez;
            MODEL[pk][key].wsg_phasenbez = GROUP.wsg_phasenbez;
            MODEL[pk][key].pk = GROUP.pk;
            MODEL[pk][key].au_ids.push(...GROUP.aufgaben.map(aufgabe => aufgabe.pk));
          }
        });
      });
      this.model = MODEL;
    },

    updateModel({ item }) {
      this.model[item.workflowstatusgruppentyp][item.index][item.field] = item.value;
    },

    save() {
      const DATA = [
        ...this.model[KworkflowstatusgruppentypUUIDs.bearbeiten],
        ...this.model[KworkflowstatusgruppentypUUIDs.erfolg],
        ...this.model[KworkflowstatusgruppentypUUIDs.misserfolg],
      ].filter(wsg => this.isActive(wsg.wsg_pos, wsg.workflowstatusgruppentyp));
      this.dataToSave = DATA;
      return new Promise((resolve, reject) => {
        this.postHttp({
          url: `${ this.baseUrl }statusgruppen_bearbeiten/`,
          data: DATA,
        }).then(
          response => {
            this.$emit("updateWorkflowtyp", {
              data: {
                statusgruppen: response,
              }
            });
            this.addNotification({ text: "_MSG_WORKFLOWTYP_DETAILS_CHANGES_SUCCESSFUL_SAVED_" });
            resolve();
          },
          errors => reject(this.onErrors({ errors: errors })),
        );
      });
    },

    getModelFrame() {
      return {
        [KworkflowstatusgruppentypUUIDs.bearbeiten]: [
          {
            wsg_pos: 1,
            wsg_kbez: undefined,
            au_ids: [],
            workflowtyp: this.workflowtyp.pk,
            workflowstatusgruppentyp: KworkflowstatusgruppentypUUIDs.bearbeiten
          },
          {
            wsg_pos: 2,
            wsg_kbez: undefined,
            au_ids: [],
            workflowtyp: this.workflowtyp.pk,
            workflowstatusgruppentyp: KworkflowstatusgruppentypUUIDs.bearbeiten
          },
          {
            wsg_pos: 3,
            wsg_kbez: undefined,
            au_ids: [],
            workflowtyp: this.workflowtyp.pk,
            workflowstatusgruppentyp: KworkflowstatusgruppentypUUIDs.bearbeiten
          },
        ],
        [KworkflowstatusgruppentypUUIDs.erfolg]: [
          {
            wsg_pos: 4,
            wsg_kbez: undefined,
            au_ids: [],
            workflowtyp: this.workflowtyp.pk,
            workflowstatusgruppentyp: KworkflowstatusgruppentypUUIDs.erfolg
          },
          {
            wsg_pos: 6,
            wsg_kbez: undefined,
            au_ids: [],
            workflowtyp: this.workflowtyp.pk,
            workflowstatusgruppentyp: KworkflowstatusgruppentypUUIDs.erfolg
          }
        ],
        [KworkflowstatusgruppentypUUIDs.misserfolg]: [
          {
            wsg_pos: 5,
            wsg_kbez: undefined,
            au_ids: [],
            workflowtyp: this.workflowtyp.pk,
            workflowstatusgruppentyp: KworkflowstatusgruppentypUUIDs.misserfolg
          },
        ],
      };
    },

    isActive(wsg_pos, wtg_id) {
      return !isNil(find(this.model[wtg_id], wsg => wsg.wsg_pos === wsg_pos && size(wsg.wsg_kbez) > 0));
    },

    getStatus({ wsg_pos, wtg_id }) {
      if (this.isAdded(wsg_pos, wtg_id)) {
        return "add";
      }
      if (this.isRemoved(wsg_pos, wtg_id)) {
        return "remove";
      }
      if (this.isChanged(wsg_pos, wtg_id)) {
        return "change";
      }
      return undefined;
    },

    isAdded(wsg_pos, wtg_id) {
      const WSG = this.model[wtg_id].find(wsg => wsg.wsg_pos === wsg_pos);
      return this.isActive(wsg_pos, wtg_id) && !WSG.pk;
    },

    isRemoved(wsg_pos, wtg_id) {
      const WSG = this.model[wtg_id].find(wsg => wsg.wsg_pos === wsg_pos);
      return !this.isActive(wsg_pos, wtg_id) && WSG.pk;
    },

    isChanged(wsg_pos, wtg_id) {
      const TWSG = this.model[wtg_id].find(wsg => wsg.wsg_pos === wsg_pos);
      if (!this.isActive(wsg_pos, wtg_id)) {
        return false;
      }
      const WSG = this.workflowGruppenAll[wtg_id]
        .workflowStatusGruppenTypen.find(wsg => wsg.wsg_pos === TWSG.wsg_pos);
      if (TWSG.wsg_kbez !== WSG.wsg_kbez || TWSG.au_ids.length !== WSG.aufgaben.length) {
        return true;
      }
      return WSG.aufgaben
        .find(au => TWSG.au_ids.indexOf(au.pk) === -1) !== undefined;
    },

    close() {
      this.errors = {};
      this.errorsLocal = [];
      this.dataToSave = [];
    },

    onErrors({ errors }) {
      this.errors = errors.data;
      const ERRORS = cloneDeep(this.errors);
      forEach(this.dataToSave, (group, index) => {
        ERRORS[index].wgs_pos = group.wsg_pos;
      });
      this.errorsLocal = ERRORS;
      return errors;
    },
  },
};
