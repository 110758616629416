import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import VertragDurchfuehrungszeitraumDifference from "./VertragDurchfuehrungszeitraumDifference/VertragDurchfuehrungszeitraumDifference.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import VertragSnapshotMixin from "../VertragSnapshotMixin";
import {
  CheckPermissionsSyncMixin,
} from "../../../../global/mixins/PermissionMixin";

import {
  cloneDeep,
  get,
  isEmpty,
} from "lodash-es";

// @vue/component
export default {
  name: "VertragDurchfuehrungszeitraum",
  components: {
    FormElement,
    FormstepDetail,
    PuxTranslate,
    VertragDurchfuehrungszeitraumDifference,
  },
  mixins: [
    CheckPermissionsSyncMixin,
    HttpMixin,
    VertragSnapshotMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    vertrag: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "onSave",
    "updateVertrag",
  ],
  data() {
    return {
      model: {},
      errors: undefined,
    };
  },
  computed: {
    optionsFormstepDetail() {
      return {
        label: "_TXT_VERTRAG_DURCHFUEHRUNGSZEITRAUM_",
        info: "_TXT_VERTRAG_DURCHFUEHRUNGSZEITRAUM_INFO_",
        required: true,
        openCallback: this.open,
        saveCallback: this.save,
      };
    },

    isEditable() {
      return this.$Client === "proandi-dhw" ?
        this.hasWFPermissionVertragFvbUpdate :
        this.hasWFPermissionVertragFvbUpdate || this.hasWFPermissionVertragUpdate;
    },

    hasWFPermissionVertragFvbUpdate() {
      return this.checkPermissionsSync({
        perm: "vertrag.fvb.update",
        permArray: this.vertrag.user_permissions,
      });
    },

    hasWFPermissionVertragUpdate() {
      return this.checkPermissionsSync({
        perm: "vertrag.update",
        permArray: this.vertrag.user_permissions,
      });
    },

    foerdervertragsbestaetigungLocal() {
      return this.vertrag.foerdervertragsbestaetigung || {};
    },

    errorsLabelsOptions() {
      return [
        this.optionsVon,
        this.optionsBis,
      ];
    },

    optionsVon() {
      const ID = "von";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "date",
        label: "_LBL_VERTRAG_DURCHFUEHRUNGSZEITRAUM_BEGINN_",
        view: "v-alt",
        required: true,
      };
    },

    optionsBis() {
      const ID = "bis";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "date",
        label: "_LBL_VERTRAG_DURCHFUEHRUNGSZEITRAUM_ENDE_",
        view: "v-alt",
        required: true,
      };
    },

    urlSave() {
      return `vertraege/${ this.vertrag.pk }/fvb_durchfuehrungszeitraum/`;
    },

    idForVertragDeference() {
      return `${ this.id }_vertrag_deference`;
    },

    idForAntragDeference() {
      return `${ this.id }_antrag_deference`;
    },

    idForVertragDeferenceEdit() {
      return `${ this.id }_vertrag_deference_edit`;
    },

    snapshotVertrag() {
      return get(this.snapshotObject, "vertrag") || {};
    },

    isDiff() {
      return !isEmpty(this.diff);
    },

    diff() {
      const DIFF = {};
      if (this.isSnapshot) {
        if (this.foerdervertragsbestaetigungLocal.durchfuehrungszeitraum !== this.snapshotVertrag.durchfuehrungszeitraum) {
          DIFF.vertragDurchfuehrungszeitraum = true;
        }
        if (this.vertrag.antrag_durchfuehrungszeitraum !== this.snapshotVertrag.antrag_durchfuehrungszeitraum) {
          DIFF.antragDurchfuehrungszeitraum = true;
        }
        if (this.foerdervertragsbestaetigungLocal.von !== this.snapshotVertrag.v_durchfuehrung_von ||
          this.foerdervertragsbestaetigungLocal.bis !== this.snapshotVertrag.v_durchfuehrung_bis) {
          DIFF.vertragDurchfuehrungszeitraumDifference = true;
        }
        if (this.vertrag.antrag_durchfuehrung_von !== this.snapshotVertrag.antrag_durchfuehrung_von ||
          this.vertrag.antrag_durchfuehrung_bis !== this.snapshotVertrag.antrag_durchfuehrung_bis) {
          DIFF.antragDurchfuehrungszeitraumDifference = true;
        }
      }
      return DIFF;
    },
  },
  methods: {
    open() {
      this.model = cloneDeep(this.foerdervertragsbestaetigungLocal);
    },

    save() {
      return new Promise((resolve, reject) => {
        this.deleteErrors();
        this.putHttp({
          url: this.urlSave,
          data: {
            von: this.model.von,
            bis: this.model.bis,
          },
        }).then(
          response => {
            this.updateVertragLocal({ response });
            this.addNotification({ text: "_MSG_UPDATE_SUCCESS_" });
            this.$emit("onSave");
            resolve();
          },
          error => {
            this.onErrors(error.data);
            reject(error);
          },
        );
      });
    },

    updateVertragLocal({ response }) {
      const VERTRAGSBESTAETIGUNG = cloneDeep(this.foerdervertragsbestaetigungLocal);
      VERTRAGSBESTAETIGUNG.von = response.von;
      VERTRAGSBESTAETIGUNG.bis = response.bis;
      VERTRAGSBESTAETIGUNG.durchfuehrungszeitraum = response.durchfuehrungszeitraum;

      this.$emit("updateVertrag", {
        vertrag: {
          foerdervertragsbestaetigung: VERTRAGSBESTAETIGUNG,
        },
      });
    },

    deleteErrors() {
      this.errors = undefined;
    },

    onErrors(errors) {
      const ERRORS = errors;
      if (ERRORS.__all__) {
        ERRORS.von = cloneDeep(ERRORS.__all__);
        ERRORS.bis = cloneDeep(ERRORS.__all__);
        delete ERRORS.__all__;
      }
      this.errors = ERRORS;
    },
  },
};
