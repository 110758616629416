import WorkflowModulBaseMixin from "../WorkflowModulBaseMixin";

// @vue/component
export default {
  name: "WorkflowModulFormSimple",
  mixins: [
    WorkflowModulBaseMixin,
  ],
  created() {
    this.startModul();
    this.save();
  },
  methods: {
    save() {
      this.finish().then(
        response => {
          this.startWorkflowfolge(response);
        },
        errors => {
          this.startWorkflowfolge(errors);
        }
      );
    },
  },
  render() {
    return "";
  },
};
