import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SystemKonfigurationInformationen from "./SystemKonfigurationInformationen/SystemKonfigurationInformationen.vue";
import SystemKonfigurationParameter from "./SystemKonfigurationParameter/SystemKonfigurationParameter.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";

import {
  toFormElementFromParameter,
  initModelFromList,
} from "../../../global/functions/mappingForParameterToFormElement";
import {
  forOwn,
  isBoolean,
} from "lodash-es";

// @vue/component
export default {
  name: "SystemKonfigurationDetails",
  components: {
    ContextPanel,
    PuxPageDetails,
    PuxTranslate,
    SystemKonfigurationInformationen,
    SystemKonfigurationParameter,
  },
  setup() {
    const {
      getHttp,
    } = HttpAPI();

    return {
      getHttp,
    };
  },
  data() {
    return {
      loading: true,
      config: {},
      listConfig: [],
      model: undefined,
      panelOptions: {
        label: "_TXT_SY_KONFIG_DETAILS_KONFIGURATION_KONTEXT_",
        icon: "list",
        active: true,
      },
    };
  },
  computed: {
    syconfigadminUrl() {
      return `syconfigadmin/${ this.$stateParams.kennung }/`;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.getHttp({
        url: this.syconfigadminUrl,
      }).then(
        response => {
          this.config = response;
          this.setList();
          this.setModelFromList();
          this.loading = false;
        }
      );
    },

    setList() {
      this.listConfig = toFormElementFromParameter({
        obj: this.config.parameter,
        showLabel: true,
      }).list;
    },

    setModelFromList() {
      this.model = initModelFromList({ list: this.listConfig });
    },

    updateModel({ model }) {
      forOwn(model, (value, key) => {
        if (!value && !isBoolean(value)) {
          const el = this.listConfig.find(el => el.id === key);
          if (el && el.default) {
            model[key] = el.default;
          }
        }
      });
      this.model = model;
    },
  },
};
