"use strict";

class AufgabeZuweisenController {
  /*@ngInject*/
  constructor($scope, $q, DiasNotification, Nutzeraufgabe) {
    this.DiasNotification = DiasNotification;
    this.Nutzeraufgabe = Nutzeraufgabe;
    this.$q = $q;
    this.nutzer = null;
    this.aufgabe = $scope.extras.aufgabe;
    let reloadPage = "";
    this.zustaendigeSearch = [];

    if ($scope.extras.reloadPage) {
      reloadPage = $scope.extras.reloadPage;
    } else {
      reloadPage = () => {};
    }

    this.save = () => {
      return this.Nutzeraufgabe.one(this.aufgabe.pk).customPOST({ nutzer: this.nutzer }, "zuweisen").then(
        response => {
          this.DiasNotification.page.success("Aufgabe zugewiesen");
          reloadPage(response);
        },
        error => {
          this.onError(error);
          return this.$q.reject(error);
        }
      );
    };

    this.onError = error => {
      const errorsLabel = {
        nutzer: "Nutzer: ",
      };
      this.DiasNotification.form.clear();
      this.DiasNotification.form.error(error.data, "Bitte überprüfen Sie Ihre Eingaben", {}, errorsLabel);
      this.errors = error.data;
    };

    this.searchZustaendige = term => {
      if (term === "") {
        // Suche zurücksetzen.
        this.zustaendigeSearch = [];
        return;
      }
      const params = { suche: term };
      this.Nutzeraufgabe.one(this.aufgabe.pk).customGET("zustaendige", params).then(result => {
        this.zustaendigeSearch = result.results;
      });
    };
  }
}

export default AufgabeZuweisenController;
