<template>
  <div />
</template>

<script>
import { optionsMerger, propsBinder, findRealParent } from "../utils/utils.js";
import TileLayerMixin from "../mixins/TileLayer.js";
import Options from "../mixins/Options.js";
import { tileLayer, DomEvent } from "leaflet";

import {
  DomEventsAPI
} from "../API/DomEventsAPI";

/**
 * Load tiles from a map server and display them accordingly to map zoom, center and size
 */
export default {
  name: "LTileLayer",
  mixins: [TileLayerMixin, Options],
  props: {
    url: {
      type: String,
      default: null,
    },
    tileLayerClass: {
      type: Function,
      default: tileLayer,
    },
  },
  emits: [
    "ready",
  ],
  setup(props, context) {
    const {
      domEvents,
    } = DomEventsAPI(props, context);

    return {
      domEvents,
    };
  },
  mounted() {
    const options = optionsMerger(this.tileLayerOptions, this);
    this.mapObject = this.tileLayerClass(this.url, options);
    DomEvent.on(this.mapObject, this.domEvents);
    propsBinder(this, this.mapObject, this.$options.props);
    this.parentContainer = findRealParent(this.$parent);
    this.parentContainer.addLayer(this, !this.visible);
    this.$nextTick(() => {
      /**
       * Triggers when the component is ready
       * @type {object}
       * @property {object} mapObject - reference to leaflet map object
       */
      this.$emit("ready", this.mapObject);
    });
  },
};
</script>
