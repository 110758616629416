"use strict";

import BaseModel from "dias/core/models/baseModel";

class TemplateService extends BaseModel {
  constructor() {
    super("templates", {});
  }
}

export default TemplateService;
