var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
jade_mixins["required-form-group"] = jade_interp = function(field, labelText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<label" + (jade.attr("ng-class", "{'has-error has-feedback' :vm.errors['" + (field) + "']}", true, true)) + " class=\"font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = labelText) == null ? '' : jade_interp)) + " *<span class=\"alert-danger\"></span></span>");
block && block();
buf.push("<span" + (jade.attr("ng-if", "vm.errors['" + (field) + "']", true, true)) + " class=\"help-block\"><ul class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in vm.errors['" + (field) + "']", true, true)) + ">{{ err }}</li></ul></span></label>");
};
buf.push("<div dias-cloak=\"vm.loadingEditData\"><fieldset><div ng-attr-id=\"{{vm.errorContainerId}}\"></div><div class=\"row\"><div class=\"col-sm-6 col-12\">");
jade_mixins["required-form-group"].call({
block: function(){
buf.push("<input id=\"o_name\" ng-model=\"vm.model.o_name\" type=\"text\" name=\"o_name\" maxlength=\"400\" auto-focus=\"true\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.o_name ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "o_name", "Name");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"o_kbez\" ng-model=\"vm.model.o_kbez\" type=\"text\" name=\"o_kbez\" maxlength=\"400\" auto-focus=\"true\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.o_kbez ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "o_kbez", "Kurzbezeichnung");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"o_strasse\" ng-model=\"vm.model.o_strasse\" type=\"text\" name=\"o_strasse\" maxlength=\"100\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.o_strasse ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "o_strasse", "Straße");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"o_hsnr\" ng-model=\"vm.model.o_hsnr\" type=\"text\" name=\"o_hsnr\" maxlength=\"10\" ng-attr-aria-invalid=\"{{vm.errors.o_hsnr ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "o_hsnr", "Hausnummer");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"o_plz\" ng-model=\"vm.model.o_plz\" type=\"text\" name=\"o_plz\" maxlength=\"10\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.o_plz ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "o_plz", "PLZ");
jade_mixins["form-group"].call({
block: function(){
buf.push("<input id=\"o_ort\" ng-model=\"vm.model.o_ort\" type=\"text\" name=\"o_ort\" maxlength=\"100\" aria-required=\"true\" ng-attr-aria-invalid=\"{{vm.errors.o_ort ? 'true' : 'false'}}\" class=\"form-control\">");
}
}, "o_ort", "Ort");
buf.push("</div></div></fieldset></div><div class=\"form-footer\"><div class=\"required\"> <dias-requirement></dias-requirement></div><div class=\"form-actions\"><button ng-click=\"setEditMode(false, vm.save)\" ng-disabled=\"loading\" type=\"submit\" class=\"btn btn-primary test-save\"><span>Speichern</span></button><button ng-click=\"setEditMode(false, vm.reset)\" ng-disabled=\"loading\" role=\"button\" class=\"btn btn-icon\"><span>Abbrechen</span></button></div></div>");;return buf.join("");
}