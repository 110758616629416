import FormElementReadOnly from "../../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PersonalfunktionElementInListe
  from "../../../PersonalkostenPeriodenWizard/WizardSteps/WizardStep1/PersonalfunktionElementInListe/PersonalfunktionElementInListe.vue";

// @vue/component
export default {
  name: "PersonalkostenPeriodenTableInAntragDetails",
  components: {
    FormElementReadOnly,
    PersonalfunktionElementInListe,
  },
  props: {
    position: {
      type: Object,
      required: true,
    },
    positionIndex: {
      type: Number,
      required: true,
    },
    periodenLength: {
      type: Number,
      required: true,
    },
    personalfunktionen: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      nameOptions: {
        id: "name",
        type: "text",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_NAME_",
        showLabel: true,
      },
      stellenumfangOptions: {
        id: "stellenumfang",
        type: "float",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_STELLENUMFANG_",
        addonBack: "_LBL_SUFFIX_STUNDEN_PRO_WOCHE_",
        showLabel: true,
      },
      stellenumfangProjektOptions: {
        id: "stellenumfang_projekt",
        type: "float",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_STELLENUMFANG_PROJEKT_",
        addonBack: "_LBL_SUFFIX_STUNDEN_PRO_WOCHE_",
        showLabel: true,
      },
      lohnsteigerungOptions: {
        id: "lohnsteigerung",
        type: "float",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_LOHNSTEIGERUNG_",
        suffix: "%",
        showLabel: true,
      },
    };
  },
  computed: {
    colspan() {
      return this.periodenLength + 2;
    },

    positionArgument() {
      return this.position.argument || {};
    },

    personalfunktionOptions() {
      return {
        id: "personalfunktion",
        type: "select",
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_FUNKTION_IM_PROJEKT_",
        data: this.personalfunktionen,
        keyId: "pk",
        keyLabel: "bez",
        slot: "personalfunktion",
        showLabel: true,
      };
    },

    anJahresbruttoOptions() {
      return {
        id: "an_jahresbrutto",
        type: "currency",
        showLabel: true,
        label: "_LBL_ANTRAG_DETAILS_PERSONAL_WIZARD_AN_JAHRESBRUTTO_{{stellenanteil}}_",
        extra: {
          stellenanteil: this.position.stellenanteil,
        },
      };
    },

    idDetails() {
      return `details_positionen_${ this.positionIndex }`;
    },
  },
};
