<template>
  <div style="display: none;">
    <slot v-if="ready" />
  </div>
</template>

<script>
import { optionsMerger, propsBinder, findRealParent } from "../utils/utils.js";
import PolylineMixin from "../mixins/Polyline.js";
import Options from "../mixins/Options.js";
import { polyline, DomEvent } from "leaflet";

import {
  DomEventsAPI
} from "../API/DomEventsAPI";

/**
 * Easily draw a polyline on the map
 */
export default {
  name: "LPolyline",
  mixins: [PolylineMixin, Options],
  props: {
    latLngs: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    "click",
    "mouseup",
    "mousemove",
    "mousedown",
    "ready",
  ],
  setup(props, context) {
    const {
      domEvents,
    } = DomEventsAPI(props, context);

    return {
      domEvents,
    };
  },
  data() {
    return {
      ready: false,
    };
  },
  mounted() {
    const options = optionsMerger(this.polyLineOptions, this);
    this.mapObject = polyline(this.latLngs, options);
    DomEvent.on(this.mapObject, this.domEvents);
    propsBinder(this, this.mapObject, this.$options.props);
    this.ready = true;
    this.parentContainer = findRealParent(this.$parent);
    this.parentContainer.addLayer(this, !this.visible);
    this.$nextTick(() => {
      /**
       * Triggers when the component is ready
       * @type {object}
       * @property {object} mapObject - reference to leaflet map object
       */
      this.$emit("ready", this.mapObject);
    });
  },
};
</script>
