import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import WorkflowtypDetailsBeschreibung from "./WorkflowtypDetailsBeschreibung/WorkflowtypDetailsBeschreibung.vue";
import WorkflowtypDetailsStatusgruppen from "./WorkflowtypDetailsStatusgruppen/WorkflowtypDetailsStatusgruppen.vue";

import HttpAPI from "../../../global/compositionAPI/HttpAPI";

// @vue/component
export default {
  name: "WorkflowtypDetails",
  components: {
    ContextPanel,
    PuxPageDetails,
    PuxTranslate,
    WorkflowtypDetailsBeschreibung,
    WorkflowtypDetailsStatusgruppen,
  },
  setup() {
    const {
      getHttp,
    } = HttpAPI();

    return {
      getHttp,
    };
  },
  data() {
    return {
      wtgs: {},
      pageLoading: true,
      workflowtyp: {},
      workflowstatusgruppentypen: [],
      model: {},
      panelWorkflowtypOptions: {
        label: "_TXT_WORKFLOWTYP_DETAILS_PANEL_HEADER_",
        icon: "file",
        active: true,
      },
    };
  },
  computed: {
    baseUrl() {
      return `workflowtypen/${ this.$stateParams.id }/`;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.pageLoading = true;
      Promise.all([
        this.getHttp({
          url: "katalog/",
          urlParams: {
            key: "workflowstatusgruppentypen",
          },
          apiSaveId: "workflowstatusgruppentypen",
        }),
        this.getHttp({
          url: this.baseUrl,
        }),
      ]).then(
        responses => {
          this.workflowstatusgruppentypen = responses[0];
          this.workflowtyp = responses[1];
        }
      ).finally(() => this.pageLoading = false);
    },

    updateWorkflowtyp({ data }) {
      this.workflowtyp = Object.assign({}, this.workflowtyp, data);
    },
  },
};
