import MediaPlayer from "../../../../../global/components/MediaPlayer/MediaPlayer.vue";

import FaqDetailsItemMixin from "../FaqDetailsItemMixin";

// @vue/component
export default {
  name: "FaqDetailsItemAudio",
  components: {
    MediaPlayer,
  },
  mixins: [
    FaqDetailsItemMixin,
  ],
  computed: {
    idLocal() {
      return `faq_video_${ this.data.pk }`;
    },

    sources() {
      return this.parameter.sources || [];
    },

    alt() {
      return this.parameter.alt;
    },

    idForDescription() {
      return `${ this.idLocal }_description`;
    },
  },
};
