import FormstepDetail from "../../../../global/components/FormstepDetail/FormstepDetail.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import NotificationAPI from "../../../../global/compositionAPI/NotificationAPI";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "SystemKonfigurationParameter",
  components: {
    FormstepDetail,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    listConfig: {
      type: Array,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    updateModel: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const {
      putHttp,
    } = HttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      putHttp,
    };
  },
  computed: {
    options() {
      return {
        label: "_TXT_SY_KONFIG_DETAILS_PARAMETER_LABEL_",
        required: true,
        saveCallback: this.save,
        editablePermission: "syconfigadmin.update",
        list: this.listConfig,
      };
    },

    syconfigadminUrl() {
      return `syconfigadmin/${ this.config.kennung }/`;
    },
  },
  methods: {
    save({ model }) {
      return new Promise((resolve, reject) => {
        this.putHttp({
          url: this.syconfigadminUrl,
          data: model,
        }).then(
          () => {
            this.addNotification({ text: "_MSG_SY_KONFIG_DETAILS_PARAMETER_SUCCESS_" });
            this.updateModel({ model: cloneDeep(model) });
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },
  },
};
