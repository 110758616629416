import Alert from "../../../../global/components/Alert/Alert.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../../global/components/table/table.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import {
  findIndex,
  forEach,
  get,
  isNil,
} from "lodash-es";

const TABLE_ID = "AuszahlungsplanmodalitaetenInAuszahlungModalCreate";

// @vue/component
export default {
  name: "AuszahlungModalCreate",
  components: {
    Alert,
    FormElement,
    Modal,
    PuxButton,
    PuxCloak,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      disabledObjs: undefined,
      options: {
        showCloseButton: true,
        required: true,
        list: [],
      },
      auszahlungsplaene: undefined,
      vertrag: undefined,
      status: {
        loading: undefined,
        loadingDetails: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    elementVertrag() {
      return {
        id: "vertrag",
        type: "select",
        label: "_LBL_AUSZAHLUNG_MODAL_CREATE_SELECT_VERTRAG_",
        required: true,
        view: "v",
        keyId: "pk",
        keyLabel: "v_nr",
        searchGlobal: true,
        searchParameter: "v_nr",
        url: "vertraege/?fields=pk&fields=v_nr",
        translate: true,
        change: this.onVertragSelected
      };
    },
    modeEasy() {
      return get(this, "vertrag.auszahlungserstellungsmodus") === "einfach";
    },
    easyData() {
      if (!this.modeEasy) {
        return;
      }
      const DATA = {
        auszahlung: undefined,
        endabrechnung: undefined
      };
      forEach(this.auszahlungsplaene, zp => {
        if (zp.can_create_auszahlung && this.checkPermissionsSync({ perm: "auszahlung.create", permArray: zp.user_permissions })) {
          if (zp.is_last) {
            DATA.endabrechnung = zp;
          } else if (isNil(DATA.auszahlung) || zp.zp_nummer > DATA.auszahlung.zp_nummer) {
            DATA.auszahlung = zp;
          }
        }
      });
      return DATA;
    },
    modeComplex() {
      return get(this, "vertrag.auszahlungserstellungsmodus") === "modalitaet";
    },
    complexData() {
      if (!this.modeComplex) {
        return;
      }
      const DATA = {
        id: TABLE_ID,
        url: undefined,
        data: this.auszahlungsplaene,
        cols: [
          {
            label: "_LBL_AUSZAHLUNGSLISTE_MODAL_CREATE_COMPLEX_TABLE_COL_STUFE_",
            id: "zp_nummer",
          },
          {
            label: "_LBL_AUSZAHLUNGSLISTE_MODAL_CREATE_COMPLEX_TABLE_COL_NAME_",
            id: "name",
          },
          {
            label: "_LBL_AUSZAHLUNGSLISTE_MODAL_CREATE_COMPLEX_TABLE_COL_MODALITAET_",
            id: "display",
          },
          {
            label: "_LBL_AUSZAHLUNGSLISTE_MODAL_CREATE_COMPLEX_TABLE_COL_STATUS_",
            id: "status.aps_kbez",
          },
        ],
        rowActions: [
          {
            type: "button",
            className: "btn btn-primary",
            label: "_LBL_AUSZAHLUNGSLISTE_MODAL_CREATE_COMPLEX_TABLE_ROW_ACTIONS_CREATE_",
            callback: ({ item }) => this.save(item),
            isHidden: ({ item }) => !item.can_create_auszahlung || !this.checkPermissionsSync({ perm: "auszahlung.create", permArray: item.user_permissions }),
          },
        ],
        label: "_TXT_AUSZAHLUNGSLISTE_MODAL_CREATE_COMPLEX_TABLE_HEADER_",
        sortable: true,
        pagination: false,
        details: false,
        urlUpdate: false,
        showHeader: false,
        showColsConfig: false,
      };
      if (findIndex(this.auszahlungsplaene, el => !isNil(el.frist)) !== -1) {
        DATA.cols.push({
          label: "_LBL_AUSZAHLUNGSLISTE_MODAL_CREATE_COMPLEX_TABLE_COL_FRIST_",
          id: "frist",
        });
      }
      return DATA;
    },
    noCreationPossible() {
      return (
        (this.modeEasy && !this.easyData.endabrechnung && !this.easyData.auszahlung)
        || (this.modeComplex && findIndex(this.auszahlungsplaene, zp => zp.can_create_auszahlung) === -1)
      );
    }
  },
  methods: {
    onVertragSelected({ model }) {
      this.status.loadingDetails = true;
      Promise.all([
        this.getHttp({ url: `vertraege/${ model }/` }),
        this.getListHttp({ url: `vertraege/${ model }/auszahlungsplaene/` })
      ]).then(
        responses => {
          this.auszahlungsplaene = responses[1];
          this.vertrag = responses[0];
        },
        err => {
          this.errors = get(err, "data") || err;
          this.errorsClone = this.errors;
        }
      ).finally(() => this.status.loadingDetails = false);
    },

    save(zp) {
      this.status.loading = true;
      if (this.complexData) {
        const DISABLED_OBJ = {};
        forEach(this.auszahlungsplaene, zp => DISABLED_OBJ[zp.pk] = true);
        this.disabledObjs = DISABLED_OBJ;
      }
      this.postHttp({
        url: `vertraege/${ this.vertrag.pk }/auszahlungsplaene/${ zp.pk }/auszahlung_erstellen/`,
      }).then(
        response => {
          this.addNotification({
            text: "_MSG_AUSZAHLUNG_TABLE_CREATE_SUCCESS_{{zp_name}}_",
            extra: { zp_name: zp.name }
          });
          this.$goTo("root.vertrag.details.auszahlung", { id: this.vertrag.pk, aus_id: response.pk });
        },
        error => {
          if (error.status !== 429) {
            this.errors = error.data;
          }
          this.addNotification({
            text: "_MSG_AUSZAHLUNG_TABLE_CREATE_ERROR_{{zp_name}}_",
            extra: { zp_name: zp.name },
            type: "error"
          });
        }
      ).finally(
        () => {
          this.status.loading = false;
          this.disabledObjs = undefined;
        }
      );
    },
  },
};
