import {
  computed,
  ref,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";

export default function AntragFavoritAPI({ antrag, antragUrl }) {
  const {
    checkPermissionsSync,
  } = PermissionAPI();
  const hasWFPermissionFavoritenToggle = computed(() => {
    return checkPermissionsSync({
      perm: "favoriten.toggle",
      permArray: antrag.value.user_permissions,
    });
  });
  const loadingFavorit = ref(undefined);
  const antragFavorisierenUrl = computed(() => {
    return `${ antragUrl.value }favorisieren/`;
  });
  const {
    deleteHttp,
    postHttp,
  } = HttpAPI();
  const toggleFavorit = () => {
    if (loadingFavorit.value) {
      return;
    }
    loadingFavorit.value = true;
    const HTTP_METHOD = antrag.value.favorit ? deleteHttp : postHttp;
    HTTP_METHOD({
      url: antragFavorisierenUrl.value,
    }).then(
      () => {
        antrag.value.favorit = !antrag.value.favorit;
      }
    ).finally(
      () => loadingFavorit.value = false
    );
  };
  const buttonToggleFavoritText = computed(() => {
    return antrag.value.favorit ?
      "_BTN_ANTRAG_NICHT_MARKIEREN_" :
      "_BTN_ANTRAG_MARKIEREN_";
  });

  return {
    buttonToggleFavoritText,
    hasWFPermissionFavoritenToggle,
    loadingFavorit,
    toggleFavorit,
  };
}
