"use strict";

import BaseModel from "dias/core/models/baseModel";

class Workflowfolgeclientfunktion extends BaseModel {
  constructor() {
    super("clientfunktionen");
  }
}

export default Workflowfolgeclientfunktion;
