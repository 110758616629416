import AsideRightBox from "../../../../global/components/AsideRightBox/AsideRightBox.vue";
import FormularListeBox from "../../../Formulare/FormularListeBox/FormularListeBox.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import { isNil } from "lodash-es";

// @vue/component
export default {
  name: "SitzungDetailsGoToBox",
  components: {
    AsideRightBox,
    FormularListeBox,
    PuxTranslate,
  },
  props: {
    sitzung: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      optionsAsideRightBox: {
        active: true,
        icon: "verbunden",
      },
    };
  },
  computed: {
    isVisible() {
      return !isNil(this.sitzung);
    },
  },
};
