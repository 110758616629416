import AbhaengigeKostenpauschaleRechner from "./AbhaengigeKostenpauschaleRechner/AbhaengigeKostenpauschaleRechner.vue";
import BildungsKostenrechner from "./BildungsKostenrechner/BildungsKostenrechner.vue";
import Ferienkostenrechner from "./Ferienkostenrechner/Ferienkostenrechner.vue";
import FinanzierungDetailliertBetragsrechner from "./FinanzierungDetailliertBetragsrechner/FinanzierungDetailliertBetragsrechner.vue";
import FinanzierungDetailliertNationalBetragsrechner from "./FinanzierungDetailliertNationalBetragsrechner/FinanzierungDetailliertNationalBetragsrechner.vue";
import FinanzierungMitLandBetragsrechner from "./FinanzierungMitLandBetragsrechner/FinanzierungMitLandBetragsrechner.vue";
import HonorarkostenOhneQuali from "./HonorarkostenOhneQuali/HonorarkostenOhneQuali.vue";
import Honorarkostenrechner from "./Honorarkostenrechner/Honorarkostenrechner.vue";
import Kategoriemitpauschalerechner from "./Kategoriemitpauschalerechner/Kategoriemitpauschalerechner.vue";
import KostenabhaengigeFinanzierungRechner from "./KostenabhaengigeFinanzierungRechner/KostenabhaengigeFinanzierungRechner.vue";
import KostenOhneBezeichnung from "./KostenOhneBezeichnung/KostenOhneBezeichnung.vue";
import Personalkosten from "./Personalkosten/Personalkosten.vue";
import PersonalkostenLohnjournal from "./PersonalkostenLohnjournal/PersonalkostenLohnjournal.vue";
import PersonalkostenMitPkt from "./PersonalkostenMitPkt/PersonalkostenMitPkt.vue";
import PersonalkostenPerioden from "./PersonalkostenPerioden/PersonalkostenPerioden.vue";
import Rolliplaetzerechner from "./Rolliplaetzerechner/Rolliplaetzerechner.vue";
import StandardKostenrechner from "./StandardKostenrechner/StandardKostenrechner.vue";
import Stueckkosten from "./Stueckkosten/Stueckkosten.vue";
import UebernachtungsKostenrechner from "./UebernachtungsKostenrechner/UebernachtungsKostenrechner.vue";
// DVV /
import DvvVeranstaltungsrechner from "./DVV/DvvVeranstaltungsrechner/DvvVeranstaltungsrechner.vue";
import DvvVeranstaltungsrechnerEinfach from "./DVV/DvvVeranstaltungsrechnerEinfach/DvvVeranstaltungsrechnerEinfach.vue";
// DVV
// Kumasta /
import KumastaEhrenamtskostenrechner from "./Kumasta/KumastaEhrenamtskostenrechner/KumastaEhrenamtskostenrechner.vue";
import KumastaHonorarkostenrechner from "./Kumasta/KumastaHonorarkostenrechner/KumastaHonorarkostenrechner.vue";
import KumastaStandardfinanzierungrechner from "./Kumasta/KumastaStandardfinanzierungrechner/KumastaStandardfinanzierungrechner.vue";
import KumastaStandardkostenrechner from "./Kumasta/KumastaStandardkostenrechner/KumastaStandardkostenrechner.vue";
// Kumasta
// MDM /
import MdmKostenMitBegruendung from "./MDM/MdmKostenMitBegruendung/MdmKostenMitBegruendung.vue";
import MdmRegionaleEffekte from "./MDM/MdmRegionaleEffekte/MdmRegionaleEffekte.vue";
import MdmRegionaleEffekteMitBegruendung from "./MDM/MdmRegionaleEffekteMitBegruendung/MdmRegionaleEffekteMitBegruendung.vue";
// MDM
// DHW /
import DhwPersonalkostenPerioden from "./DHW/DhwPersonalkostenPerioden/DhwPersonalkostenPerioden.vue";
// DHW


// @vue/component
export default {
  name: "Betragsrechner",
  components: {
    AbhaengigeKostenpauschaleRechner,
    BildungsKostenrechner,
    Ferienkostenrechner,
    FinanzierungDetailliertBetragsrechner,
    FinanzierungDetailliertNationalBetragsrechner,
    FinanzierungMitLandBetragsrechner,
    HonorarkostenOhneQuali,
    Honorarkostenrechner,
    Kategoriemitpauschalerechner,
    KostenabhaengigeFinanzierungRechner,
    KostenOhneBezeichnung,
    Personalkosten,
    PersonalkostenLohnjournal,
    PersonalkostenMitPkt,
    PersonalkostenPerioden,
    Rolliplaetzerechner,
    StandardKostenrechner,
    Stueckkosten,
    UebernachtungsKostenrechner,
    // DVV /
    DvvVeranstaltungsrechner,
    DvvVeranstaltungsrechnerEinfach,
    // DVV
    // Kumasta /
    KumastaEhrenamtskostenrechner,
    KumastaHonorarkostenrechner,
    KumastaStandardfinanzierungrechner,
    KumastaStandardkostenrechner,
    // Kumasta
    // MDM /
    MdmKostenMitBegruendung,
    MdmRegionaleEffekte,
    MdmRegionaleEffekteMitBegruendung,
    // MDM
    // DHW /
    DhwPersonalkostenPerioden,
    // DHW
  },
  props: {
    htmlId: {
      type: String,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    betragsrechner: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    extra: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    autoFocus: {
      type: Boolean,
      required: false,
    },
    vorschau: {
      type: Object,
      required: true,
    },
    vertrag: {
      type: Object,
      required: false,
      default: undefined,
    },
    position: {
      type: Object,
      required: false,
      default: undefined,
    },
    kontext: {
      type: String,
      required: true,
      validator: value => ["antrag", "beleg", "zuschuss", "zuschuss_vertrag"].indexOf(value) !== -1,
    },
    view: {
      type: String,
      required: false,
      default: "h",
      info: "view für form-element"
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      betragsrechnerMapping: {
        abhaengige_kostenpauschale_rechner: "AbhaengigeKostenpauschaleRechner",
        bildungskostenrechner: "BildungsKostenrechner",
        ferienkostenrechner: "Ferienkostenrechner",
        ferienkostenrechner_mit_reisetagen: "Ferienkostenrechner",
        finanzierung_detailliert_betragsrechner: "FinanzierungDetailliertBetragsrechner",
        finanzierung_detailliert_national_betragsrechner: "FinanzierungDetailliertNationalBetragsrechner",
        finanzierung_mit_land_betragsrechner: "FinanzierungMitLandBetragsrechner",
        freier_betrag: "StandardKostenrechner",
        honorarkosten_ohne_quali: "HonorarkostenOhneQuali",
        honorarkostenrechner: "Honorarkostenrechner",
        kategoriemitpauschalerechner: "Kategoriemitpauschalerechner",
        kostenabhaengige_finanzierung_rechner: "KostenabhaengigeFinanzierungRechner",
        kosten_ohne_bezeichnung: "KostenOhneBezeichnung",
        personalkosten: "Personalkosten",
        personalkosten_lohnjournal: "PersonalkostenLohnjournal",
        personalkosten_mit_pkt: "PersonalkostenMitPkt",
        personalkosten_perioden: "PersonalkostenPerioden",
        rolliplaetzerechner: "Rolliplaetzerechner",
        standard_finanzierungsrechner: "StandardKostenrechner",
        standard_kostenrechner: "StandardKostenrechner",
        stueckkosten: "Stueckkosten",
        uebernachtungskostenrechner: "UebernachtungsKostenrechner",
        // ...
        // DVV /
        dvv_veranstaltungsrechner: "DvvVeranstaltungsrechner",
        dvv_veranstaltungsrechner_einfach: "DvvVeranstaltungsrechnerEinfach",
        // DVV
        // Kumasta /
        kumasta_ehrenamtskostenrechner: "KumastaEhrenamtskostenrechner",
        kumasta_honorarkostenrechner: "KumastaHonorarkostenrechner",
        kumasta_standardfinanzierungrechner: "KumastaStandardfinanzierungrechner",
        kumasta_standardkostenrechner: "KumastaStandardkostenrechner",
        // Kumasta
        // MDM /
        mdm_kosten_mit_begruendung: "MdmKostenMitBegruendung",
        mdm_regionale_effekte: "MdmRegionaleEffekte",
        mdm_regionale_effekte_mit_begruendung: "MdmRegionaleEffekteMitBegruendung",
        // MDM
        // DHW /
        dhw_personalkosten_perioden: "DhwPersonalkostenPerioden",
        // DHW
      },
    };
  },
  computed: {
    betragrechnerName() {
      return this.betragsrechnerMapping[this.betragsrechner];
    },
  },
};
