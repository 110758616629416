import ListenstatusUUIDs from "../../../../const/ListenstatusUUIDs";

// @vue/component
export default {
  name: "IconListenstatus",
  props: {
    uuid: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    getIconClass() {
      switch (this.uuid) {
      case ListenstatusUUIDs.ersatz:
        return "glyphicon-ersatzantrag listenstatus";
      case ListenstatusUUIDs.vorgesehen:
        return "glyphicon-ok listenstatus";
      case ListenstatusUUIDs.nichtVorgesehen:
        return "glyphicon-close listenstatus";
      }
      return "";
    },
  },
};
