import PuxDropdown from "../../../global/components/PuxDropdown/PuxDropdown.vue";
import WorkflowFolgenButton from "../WorkflowFolgenButton/WorkflowFolgenButton.vue";
import WorkflowModulConfirm from "../Module/WorkflowModulConfirm/WorkflowModulConfirm.vue";
import WorkflowModulEinreichenBestaetigung from "../Module/WorkflowModulEinreichenBestaetigung/WorkflowModulEinreichenBestaetigung.vue";
import WorkflowModulDownloadAfterConfirm from "../Module/WorkflowModulDownloadAfterConfirm/WorkflowModulDownloadAfterConfirm.vue";
import WorkflowModulForm from "../Module/WorkflowModulForm/WorkflowModulForm.vue";
import WorkflowModulSimple from "../Module/WorkflowModulSimple/WorkflowModulSimple.vue";
import WorkflowModulSVAktualisieren from "../Module/WorkflowModulSVAktualisieren/WorkflowModulSVAktualisieren.vue";
// DHW
import WorkflowModulDhwZweckbindungRueckforderung from "../../../../../../../apps/proandi-dhw/libs/vue/Workflowfolgen/Module/WorkflowModulDhwZweckbindungRueckforderung/WorkflowModulDhwZweckbindungRueckforderung.vue";
// DHW

import HttpMixin from "../../../global/mixins/HttpMixin";

import {
  cloneDeep,
  forEach,
  get,
  sortBy,
} from "lodash-es";

// @vue/component
export default {
  name: "WorkflowFolgen",
  components: {
    PuxDropdown,
    WorkflowFolgenButton,
    WorkflowModulConfirm,
    WorkflowModulDhwZweckbindungRueckforderung,
    WorkflowModulEinreichenBestaetigung,
    WorkflowModulDownloadAfterConfirm,
    WorkflowModulForm,
    WorkflowModulSimple,
    WorkflowModulSVAktualisieren,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
    aufgabe: {
      type: Object,
      required: false,
      default: undefined,
    },
    updateOnChange: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    isButtonSingle: {
      type: Boolean,
      required: false,
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
    },
    onStarted: {
      type: Function,
      required: false,
      default: () => {},
    },
    onCanceled: {
      type: Function,
      required: false,
      default: () => {},
    },
    onInit: {
      type: Function,
      required: false,
      default: () => {},
    },
    onWorkflowfolgenUpdated: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  emits: [
    "updateAufgabe",
  ],
  data() {
    return {
      loading: undefined,
      workflowfolgen: [],
      workflowModuleMapping: {
        confirm: "WorkflowModulConfirm",
        dhw_zweckbindung_rueckforderung: "WorkflowModulDhwZweckbindungRueckforderung",
        einreichen_bestaetigung: "WorkflowModulEinreichenBestaetigung",
        einreichen_mit_tealium: "WorkflowModulEinreichenBestaetigung",
        download_after_confirm: "WorkflowModulDownloadAfterConfirm",
        form: "WorkflowModulForm",
        simple: "WorkflowModulSimple",
        sv_aktualisieren: "WorkflowModulSVAktualisieren",
      },
      activeClientFunction: undefined,
      isStarted: undefined,
      currentWorkflowfolge: undefined,
      selectorClose: undefined,
    };
  },
  computed: {
    urlWorkflowfolgen() {
      return `${ this.baseUrl }aufgaben/${ this.aufgabe.pk }/workflowfolgen/`;
    },

    isSingleButtonVisible() {
      return this.isButtonsVisible && this.workflowfolgen.length === 1;
    },

    isButtonGroupVisible() {
      return this.isButtonsVisible && this.workflowfolgen.length > 1;
    },

    isButtonsVisible() {
      return !this.aufgabe.task_id && !this.aufgabe.is_beobachter;
    },

    isSecondButtonVisible() {
      return !this.isButtonSingle && this.workflowfolgen.length === 2;
    },

    isDropdownVisible() {
      return !this.isButtonSingle && this.workflowfolgen.length > 2;
    },

    workflowfolgenWithoutFirst() {
      const WORKFLOWFOLGEN = cloneDeep(this.workflowfolgen);
      WORKFLOWFOLGEN.splice(0, 1);
      return WORKFLOWFOLGEN;
    },

    componentForWorkflowFolge() {
      return this.workflowModuleMapping[this.activeClientFunction];
    },
  },
  watch: {
    "aufgabe.pk"() {
      this.updateWorkflowfolgen();
    },

    updateOnChange() {
      this.updateWorkflowfolgen();
    },
  },
  created() {
    this.updateWorkflowfolgen();
  },
  methods: {
    updateWorkflowfolgen() {
      if (this.aufgabe && this.aufgabe.bearbeiter) {
        this.loading = true;
        return this.getListHttp({
          url: this.urlWorkflowfolgen,
        }).then(
          response => {
            this.workflowfolgen = sortBy(response, ["wfo_pos"]);
            this.onWorkflowfolgenUpdated(this.workflowfolgen);
          },
          () => {
            this.addNotification({
              text: "_MSG_WF_FOLGEN_ERMITTELN_AKTIONEN_ERROR_",
              type: "error",
            });
          }
        ).finally(
          () => this.loading = false
        );
      }
      this.workflowfolgen = [];
      return Promise.resolve();
    },

    onWorkflowfolge({ folge, buttonId }) {
      this.isStarted = true;
      this.currentWorkflowfolge = cloneDeep(folge);
      this.activeClientFunction = get(folge, "clientfunktion.modulname");
      this.selectorClose = [
        `#${ buttonId }`,
        "#wf_buttons",
      ];
    },

    startWorkflowModulFromChild(activeFunc) {
      this.onInit(activeFunc);
    },

    cancelWorkflowfolge() {
      this.closeWorkflowfolge();
      this.onCanceled();
    },

    closeWorkflowfolge() {
      this.isStarted = false;
      this.currentWorkflowfolge = undefined;
      this.activeClientFunction = undefined;
    },

    startWorkflowfolgeFromChildModulComponent(aufgabeFromResponse) {
      // Bestätigen des Dialogs
      // Workflow wird durchgeführt
      this.onStarted(aufgabeFromResponse, this.currentWorkflowfolge);
      if (aufgabeFromResponse !== undefined) {
        this.$emit("updateAufgabe", aufgabeFromResponse);
      }
      this.closeWorkflowfolge();
    },

    getTitle(folge) {
      if (folge.status !== 0) {
        return;
      }
      let title = "";
      forEach(folge.status_messages, (msg, index) => {
        title += `${ index !== 0 ? " " : "" }${ msg }`;
      });
      return title;
    },
  },
};
