import ModuleKF, {
  MAPPING_KF,
} from "../../KF/Module/KFModule";

import Adressen from "./Adressen/Adressen.vue";
import Ampel from "./Ampel/Ampel.vue";
import AnlageAllgemein from "./AnlagenAllgemein/AnlagenAllgemein.vue";
import AntragBudgetkonto from "./AntragBudgetkonto/AntragBudgetkonto.vue";
import AntragScore from "./AntragScore/AntragScore.vue";
import Antragsdatum from "./Antragsdatum/Antragsdatum.vue";
import AntragsnummerExtern from "./AntragsnummerExtern/AntragsnummerExtern.vue";
import AntragstellerAuswahl from "./AntragstellerAuswahl/AntragstellerAuswahl/AntragstellerAuswahl.vue";
import AntragstellerAuswahlDufo from "./AntragstellerAuswahl/AntragstellerAuswahlDufo/AntragstellerAuswahlDufo.vue";
import AntragstellerPruefung from "./AntragstellerPruefung/AntragstellerPruefung.vue";
import Antragstellerregel from "./Antragstellerregel/Antragstellerregel.vue";
import AntragstellerRechtsformZusatz from "./AntragstellerRechtsformZusatz/AntragstellerRechtsformZusatz.vue";
import Antragstitel from "./Antragstitel/Antragstitel.vue";
import Antragsverbund from "./Antragsverbund/Antragsverbund.vue";
import AnzahlBetreuerFerien from "./AnzahlBetreuerFerien/AnzahlBetreuerFerien.vue";
import AstAdministrativeDaten from "./AstAdministrativeDaten/AstAdministrativeDaten.vue";
import AstBankverbindungen from "./AstBankverbindungen/AstBankverbindungen.vue";
import AstOrganisationsdaten from "./AstOrganisationsdaten/AstOrganisationsdaten.vue";
import AstOrganisationszuordnungen from "./AstOrganisationszuordnungen/AstOrganisationszuordnungen.vue";
import AstRegisternummer from "./AstRegisternummer/AstRegisternummer.vue";
import AstSichtbarkeit from "./AstSichtbarkeit/AstSichtbarkeit.vue";
import AstVertretungsberechtigte from "./AstVertretungsberechtigte/AstVertretungsberechtigte.vue";
import AuswahlMitText from "./AuswahlMitText/AuswahlMitText.vue";
import AuswahlKatalog from "./AuswahlKatalog/AuswahlKatalog.vue";
import AutomatischerAntragstitel from "./AutomatischerAntragstitel/AutomatischerAntragstitel.vue";
import Bestaetigung from "./Bestaetigung/Bestaetigung.vue";
import BelegeDynamischeTabelle from "./BelegeDynamischeTabelle/BelegeDynamischeTabelle.vue";
import Bewertung from "./Bewertung/Bewertung.vue";
import Datum from "./Datum/Datum.vue";
import DokumentFrage from "./DokumentFrage/DokumentFrage.vue";
import DynamischeAdressen from "./DynamischeAdressen/DynamischeAdressen.vue";
import DynamischeMehrfachauswahl from "./DynamischeMehrfachauswahl/DynamischeMehrfachauswahl.vue";
import DynamischeTabelle from "./DynamischeTabelle/DynamischeTabelle.vue";
import DynamischeTabellePerioden from "./DynamischeTabellePerioden/DynamischeTabellePerioden.vue";
import DynamischeZahlen from "./DynamischeZahlen/DynamischeZahlen.vue";
import EinfacheFoerderregel from "./EinfacheFoerderregel/EinfacheFoerderregel.vue";
// import EinreichenErklaerung from "./EinreichenErklaerung/EinreichenErklaerung.vue";
import Elementliste from "./Elementliste/Elementliste.vue";
import Elementverzweigung from "./Elementverzweigung/Elementverzweigung.vue";
import Foerderdokumente from "./Foerderdokumente/Foerderdokumente.vue";
import FoerderfinderkategorieAuswahl from "./FoerderfinderkategorieAuswahl/FoerderfinderkategorieAuswahl.vue";
import GutachtenUebersicht from "./GutachtenUebersicht/GutachtenUebersicht.vue";
import JsonEingabe from "./JsonEingabe/JsonEingabe.vue";
import Kennzahlen from "./Kennzahlen/Kennzahlen.vue";
import Kurzbeschreibung from "./Kurzbeschreibung/Kurzbeschreibung.vue";
import ObjektanmerkungenAnzeigen from "./ObjektanmerkungenAnzeigen/ObjektanmerkungenAnzeigen.vue";
import ObjektanmerkungErfassen from "./ObjektanmerkungErfassen/ObjektanmerkungErfassen.vue";
import Objekttitel from "./Objekttitel/Objekttitel.vue";
import Objektliste from "./Objektliste/Objektliste.vue";
import ObjektlisteAntragsliste from "./ObjektlisteAntragsliste/ObjektlisteAntragsliste.vue";
import ObjektlisteAuszahlungsliste from "./ObjektlisteAuszahlungsliste/ObjektlisteAuszahlungsliste.vue";
import ObjektlisteEntscheidung from "./ObjektlisteEntscheidung/ObjektlisteEntscheidung.vue";
import Organisationsbeschreibung from "./Organisationsbeschreibung/Organisationsbeschreibung.vue";
import Personenzahl from "./Personenzahl/Personenzahl.vue";
import Projektberichte from "./Projektberichte/Projektberichte.vue";
import Pruefkriterien from "./Pruefkriterien/Pruefkriterien/Pruefkriterien.vue";
import PruefungBetreuungsverhaeltnis from "./PruefungBetreuungsverhaeltnis/PruefungBetreuungsverhaeltnis.vue";
import Publizieren from "./Publizieren/Publizieren.vue";
import Quartierskarte from "./Quartierskarte/Quartierskarte.vue";
import Regelvergleich from "./Regelvergleich/Regelvergleich.vue";
import RegexTexteingabe from "./RegexTexteingabe/RegexTexteingabe.vue";
import Schlagworte from "./Schlagworte/Schlagworte.vue";
import StandardDurchfuehrungszeitraum from "./StandardDurchfuehrungszeitraum/StandardDurchfuehrungszeitraum.vue";
import StandardVorhabenfrage from "./StandardVorhabenfrage/StandardVorhabenfrage.vue";
import Termineingabe from "./Termineingabe/Termineingabe.vue";
import Terminreihe from "./Terminreihe/Terminreihe.vue";
import Textbaustein from "./Textbaustein/Textbaustein.vue";
import Themenauswahl from "./Themenauswahl/Themenauswahl.vue";
import VertragNachweise from "./VertragNachweise/VertragNachweise.vue";
import Vertretungsberechtigte from "./Vertretungsberechtigte/Vertretungsberechtigte.vue";
import Wahlkreise from "./Wahlkreise/Wahlkreise.vue";
import ZahlenVerhaeltnis from "./ZahlenVerhaeltnis/ZahlenVerhaeltnis.vue";
import Zahlungsverkehr from "./Zahlungsverkehr/Zahlungsverkehr.vue";
import ZielgruppenAuswahl from "./ZielgruppenAuswahl/ZielgruppenAuswahl.vue";
import ZuordnungOrganisation from "./ZuordnungOrganisation/ZuordnungOrganisation.vue";
// // DHW /
import Zweckbindung from "./DHW/Zweckbindung/Zweckbindung.vue";
// // DHW

// Kumasta /
import Buendnispartner from "./Kumasta/Buendnispartner/Buendnispartner.vue";
import Projekt from "./Kumasta/Projekt/Projekt.vue";
import Projektdokumentation from "./Kumasta/Projektdokumentation/Projektdokumentation.vue";
// Kumasta
// AM /
import AntragslistePoolfilter from "./AM/AntragslistePoolfilter/AntragslistePoolfilter.vue";
import AntragKfz from "./AM/Kfz/AntragKfz/AntragKfz.vue";
import Grundpfandrecht from "./AM/Grundpfandrecht/Grundpfandrecht.vue";
import Grundpfandrechtdaten from "./AM/Grundpfandrechtdaten/Grundpfandrechtdaten.vue";
import GrundpfandrechtpruefungVertrag from "./AM/GrundpfandrechtpruefungVertrag/GrundpfandrechtpruefungVertrag.vue";
import Investitionsfoerderung from "./Investitionsfoerderung/Investitionsfoerderung.vue";
import VertragKfz from "./AM/Kfz/VertragKfz/VertragKfz.vue";
// AM
// AR /
import AkkreditierungAuswahl from "./AR/AkkreditierungAuswahl/AkkreditierungAuswahl.vue";
import HochschulAuswahl from "./AR/HochschulAuswahl/HochschulAuswahl.vue";
import StudiengangAuswahl from "./AR/StudiengangAuswahl/StudiengangAuswahl.vue";
// AR
// DVV /
import Terminserie from "./DVV/Terminserie/Terminserie.vue";
// DVV

export default {
  Adressen,
  Ampel,
  AnlageAllgemein,
  AntragBudgetkonto,
  AntragScore,
  Antragsdatum,
  AntragsnummerExtern,
  AntragstellerAuswahl,
  AntragstellerAuswahlDufo,
  AntragstellerPruefung,
  Antragstellerregel,
  AntragstellerRechtsformZusatz,
  Antragstitel,
  Antragsverbund,
  AnzahlBetreuerFerien,
  AstAdministrativeDaten,
  AstBankverbindungen,
  AstOrganisationsdaten,
  AstOrganisationszuordnungen,
  AstRegisternummer,
  AstSichtbarkeit,
  AstVertretungsberechtigte,
  AuswahlMitText,
  AuswahlKatalog,
  AutomatischerAntragstitel,
  Bestaetigung,
  BelegeDynamischeTabelle,
  Bewertung,
  Datum,
  DokumentFrage,
  DynamischeAdressen,
  DynamischeMehrfachauswahl,
  DynamischeTabelle,
  DynamischeTabellePerioden,
  DynamischeZahlen,
  EinfacheFoerderregel,
  // EinreichenErklaerung,
  Elementliste,
  Elementverzweigung,
  Foerderdokumente,
  FoerderfinderkategorieAuswahl,
  GutachtenUebersicht,
  JsonEingabe,
  Kennzahlen,
  Kurzbeschreibung,
  ObjektanmerkungenAnzeigen,
  ObjektanmerkungErfassen,
  Objekttitel,
  Objektliste,
  ObjektlisteAntragsliste,
  ObjektlisteAuszahlungsliste,
  ObjektlisteEntscheidung,
  Organisationsbeschreibung,
  Personenzahl,
  Projektberichte,
  Pruefkriterien,
  PruefungBetreuungsverhaeltnis,
  Publizieren,
  Quartierskarte,
  Regelvergleich,
  RegexTexteingabe,
  Schlagworte,
  StandardDurchfuehrungszeitraum,
  StandardVorhabenfrage,
  Termineingabe,
  Terminreihe,
  Textbaustein,
  Themenauswahl,
  VertragNachweise,
  Vertretungsberechtigte,
  Wahlkreise,
  ZahlenVerhaeltnis,
  Zahlungsverkehr,
  ZielgruppenAuswahl,
  ZuordnungOrganisation,
  // // DHW /
  Zweckbindung,
  // // DHW
  // Kumasta /
  Buendnispartner,
  Projekt,
  Projektdokumentation,
  // Kumasta
  // AM /
  AntragslistePoolfilter,
  AntragKfz,
  Grundpfandrecht,
  Grundpfandrechtdaten,
  GrundpfandrechtpruefungVertrag,
  Investitionsfoerderung,
  VertragKfz,
  // AM
  // AR /
  AkkreditierungAuswahl,
  HochschulAuswahl,
  StudiengangAuswahl,
  // AR
  // DVV /
  Terminserie,
  // DVV
  ...ModuleKF,
};

export const MAPPING = {
  adressen: "Adressen",
  ampel: "Ampel",
  anlagen_allgemein: "AnlageAllgemein",
  antrag_budgetkonto: "AntragBudgetkonto",
  antrag_score: "AntragScore",
  antragsdatum: "Antragsdatum",
  antragsnummer_extern: "AntragsnummerExtern",
  antragsteller_auswahl: "AntragstellerAuswahl",
  antragsteller_dufo_auswahl: "AntragstellerAuswahlDufo",
  antragsteller_pruefung: "AntragstellerPruefung",
  antragsteller_rechtsform_zusatz: "AntragstellerRechtsformZusatz",
  antragstellerregel: "Antragstellerregel",
  antragstitel: "Antragstitel",
  antragsverbund: "Antragsverbund",
  antrag_vertretungsberechtigte: "Vertretungsberechtigte",
  anzahl_betreuer_ferien: "AnzahlBetreuerFerien",
  ast_administrative_daten: "AstAdministrativeDaten",
  ast_bankverbindungen: "AstBankverbindungen",
  ast_organisationsdaten: "AstOrganisationsdaten",
  ast_organisationszuordnungen: "AstOrganisationszuordnungen",
  ast_registernummer: "AstRegisternummer",
  ast_sichtbarkeit: "AstSichtbarkeit",
  ast_vertretungsberechtigte: "AstVertretungsberechtigte",
  auflage: "Textbaustein",
  auswahl_mit_text: "AuswahlMitText",
  auswahl_katalog: "AuswahlKatalog",
  automatischer_antragstitel: "AutomatischerAntragstitel",
  bestaetigung: "Bestaetigung",
  belege_dynamische_tabelle: "BelegeDynamischeTabelle",
  bewertung: "Bewertung",
  datum: "Datum",
  dokument_frage: "DokumentFrage",
  dynamische_adressen: "DynamischeAdressen",
  dynamische_mehrfachauswahl: "DynamischeMehrfachauswahl",
  dynamische_tabelle: "DynamischeTabelle",
  dynamische_tabelle_perioden: "DynamischeTabellePerioden",
  dynamische_zahlen: "DynamischeZahlen",
  foerderdokumente: "Foerderdokumente",
  foerderfinderkategorie_auswahl: "FoerderfinderkategorieAuswahl",
  gutachten_uebersicht: "GutachtenUebersicht",
  dhw_quartierskarte: "Quartierskarte", // Jetzt nur für DHW
  einfache_foerderregel: "EinfacheFoerderregel",
  // einreichen_erklaerung: "EinreichenErklaerung",
  elementverzweigung: "Elementverzweigung",
  elementliste: "Elementliste",
  json_eingabe: "JsonEingabe",
  kennzahlen: "Kennzahlen",
  kurzbeschreibung: "Kurzbeschreibung",
  objektanmerkungen_anzeigen: "ObjektanmerkungenAnzeigen",
  objektanmerkung_erfassen: "ObjektanmerkungErfassen",
  objekttitel: "Objekttitel",
  objektliste: "Objektliste",
  cortext_auszahlungsliste_objektliste: "ObjektlisteAuszahlungsliste",
  cortex_antragsliste_objektliste: "ObjektlisteAntragsliste",
  objektliste_entscheidung: "ObjektlisteEntscheidung",
  organisationsbeschreibung: "Organisationsbeschreibung",
  personenzahl: "Personenzahl",
  projektberichte: "Projektberichte",
  pruefkriterien: "Pruefkriterien",
  pruefkriterien_wenn_auflage: "Pruefkriterien",
  pruefung_betreuungsverhaeltnis: "PruefungBetreuungsverhaeltnis",
  publizieren: "Publizieren",
  regelvergleich: "Regelvergleich",
  regex_texteingabe: "RegexTexteingabe",
  schlagworte: "Schlagworte",
  standard_durchfuehrungszeitraum: "StandardDurchfuehrungszeitraum",
  standard_vorhabenfrage: "StandardVorhabenfrage",
  termineingabe: "Termineingabe",
  terminreihe: "Terminreihe",
  mdm_terminreihe: "Terminreihe",
  text_mit_textbaustein: "Textbaustein",
  vorhaben_themenauswahl: "Themenauswahl",
  wahlkreise: "Wahlkreise",
  zahlen_verhaeltnis: "ZahlenVerhaeltnis",
  vertrag_nachweise: "VertragNachweise",
  zahlungsverkehr: "Zahlungsverkehr",
  zielgruppen_auswahl: "ZielgruppenAuswahl",
  zuordnung_organisationen: "ZuordnungOrganisation",
  // DHW /
  dhw_zweckbindung: "Zweckbindung",
  // DHW
  // Kumasta /
  kumasta_buendnispartner: "Buendnispartner",
  kumasta_projekt: "Projekt",
  kumasta_projektdokumentation: "Projektdokumentation",
  // Kumasta
  // AM /
  cortex_antragsliste_poolfilter_am: "AntragslistePoolfilter",
  am_automatischer_antragstitel_ferien: "AutomatischerAntragstitel",
  antragkfzdaten: "AntragKfz",
  grundpfandrecht: "Grundpfandrecht",
  grundpfandrechtdaten: "Grundpfandrechtdaten",
  grundpfandrechtpruefung_vertrag: "GrundpfandrechtpruefungVertrag",
  investitionsfoerderung: "Investitionsfoerderung",
  vertragkfzdaten: "VertragKfz",
  // AM
  // AR /
  ar_akkreditierungauswahl: "AkkreditierungAuswahl",
  hochschulauswahl: "HochschulAuswahl",
  studiengangauswahl: "StudiengangAuswahl",
  // AR
  // DVV /
  terminserie: "Terminserie",
  // DVV
  // AR /
  ar_gutachter: "Textbaustein",
  // AR
  ...MAPPING_KF,
};
