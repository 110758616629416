"use strict";

export default {
  forcePasteAsPlainText: true,
  disableNativeSpellChecker: false,
  extraPlugins: "abbr,language,divarea,autogrow",
  removePlugins: "scayt,contextmenu,tabletools,tableselection",
  language_list: ["en:Englisch", "fr:Französisch", "es:Spanisch"],
  toolbarGroups: [
    {
      name: "basicstyles",
      groups: ["basicstyles", "cleanup"],
    },
    {
      name: "paragraph",
      groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
    },
    {
      name: "links",
      groups: ["links"],
    },
    {
      name: "insert",
      groups: ["insert"],
    },
    {
      name: "clipboard",
      groups: ["clipboard", "undo"],
    },
  ],
  removeButtons: "Cut,Copy,Paste,Undo,Redo,Anchor,Strike,Subscript,Superscript,Underline,RemoveFormat,Blockquote,Image,Table,HorizontalRule,SpecialChar,PasteText,PasteFromWord",
  title: false,
};
