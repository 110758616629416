var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["dropdownAll"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div class=\"dropdown-all\"><div data-toggle=\"inactive-all\" title=\"alle schließen\" tabindex=\"-1\"><span class=\"glyphicon-double-up\"></span></div><div data-toggle=\"active-all\" title=\"alle öffnen\" tabindex=\"-1\"><span class=\"glyphicon-double-bottom\"></span></div></div>");
};
jade_mixins["schaltflaechen"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div id=\"always-visible\" class=\"actions right panel_always_visible\"><a ui-sref=\"root.foerderorganisationen.details({id: vm.org.pk})\" class=\"btn btn-icon float-left\"><span class=\"glyphicon glyphicon-chevron-left\"></span><span>{{ vm.gettext(\"Zur Förderorganisation\") }} {{ vm.org.o_name }}</span></a></div>");
};
buf.push("<script type=\"text/ng-template\" id=\"ansprechpartnerReadonly.html\"><fieldset class=\"fieldset-version\"><label class=\"font-weight-bold\">Name<p class=\"form-control-static\"><span>{{ model.fas_name }}</span></p></label><label ng-if=\"model.fas_funktion\" class=\"font-weight-bold\">Funktion<p class=\"form-control-static\"><span>{{ model.fas_funktion }}</span></p></label><label ng-repeat=\"item in model.kommunikationsdaten\" class=\"font-weight-bold\">{{item.typ.kot_bez}}<p class=\"form-control-static\"><span>{{ item.fak_wert }}</span></p></label></fieldset></script><div dias-cloak=\"vm.loading.partner\" cloak-msg=\"'Daten des Ansprechpartner werden geladen'\"><section class=\"main row no-margin\"><section class=\"main-dias application-form\"><header class=\"page-title header-detail\"><div vueinit props=\"{ fas_name: vm.partner.fas_name }\"><page-tab-title v-bind:placeholder=\"'_PAGE_TITLE_AST_ANSPRECHPARTNER_{{fas_name}}_'\" v-bind:extra=\"propsAngular\"></page-tab-title></div><h1><span>Ansprechpartner</span></h1><h1><small>{{ vm.partner.fas_name }}</small></h1>");
jade_mixins["schaltflaechen"]();
buf.push("</header><div class=\"main-details main-details\"><form formsteps><div class=\"form-step active\"><header formstep><h3><span class=\"glyphicon glyphicon-partner\"></span><span> Ansprechpartner</span></h3>");
jade_mixins["dropdownAll"]();
buf.push("</header><formstep-detail formstep-edit-controller=\"vm.ansprechpartnerEditController\" formstep-edit-template=\"vm.ansprechpartnerEditTemplate\" formstep-editable=\"!vm.loading.komTyps &amp;&amp; vm.partnerEditable\" formstep-title=\"Ansprechpartner\" ng-model=\"vm.partner\" formstep-readonly-template-url=\"ansprechpartnerReadonly.html\" class=\"form-step-detail\"></formstep-detail></div></form></div></section></section></div>");;return buf.join("");
}