import Alert from "../../../global/components/Alert/Alert.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import DetailsInfoBox from "../../DetailsInfoBox/DetailsInfoBox.vue";
import FormularDetailsBasisdaten from "../FormularDetailsBasisdaten/FormularDetailsBasisdaten.vue";
import GeschaeftsregelModul from "../../Geschaeftsregel/GeschaeftsregelModul/GeschaeftsregelModul.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import {
  scrollToGlobal,
} from "../../../global/functions/help";
import {
  cloneDeep,
  get,
  forEach,
  findIndex,
  sortBy,
} from "lodash-es";

// @vue/component
export default {
  name: "FormularDetailsBody",
  components: {
    Alert,
    ContextPanel,
    DetailsInfoBox,
    FormularDetailsBasisdaten,
    GeschaeftsregelModul,
    PuxCloak,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    formular: {
      type: Object,
      required: true,
      info: "Hauptinformation",
    },
    statusRubrikFixed: {
      type: Boolean,
      required: true,
      info: "Anzeigen Allgemeine Informationen?",
    },
    dokBaseUrl: {
      type: String,
      required: false,
      default: undefined,
      info: "Base-URL für Dokumente",
    },
    validationErrors: {
      type: Array,
      default: () => [],
      required: false,
      info: "WF-Validation-Errors",
    },
    validationErrorsLoading: {
      type: Boolean,
      required: false,
      info: "",
    },
    canViewFormularobjekt: {
      type: Boolean,
      required: false,
      info: "Hat man WF-Berechtigung Objekt zu sehnen",
    },
    vertragId: {
      type: String,
      required: false,
      default: undefined,
      info: "Vertrag-UUId",
    },
    setPanelsForNotizen: {
      type: Function,
      required: true,
      info: "Set 'this.steps' in 'angular.js' für Notizen",
    },
    resetValidation: {
      type: Function,
      required: true,
      info: "Fehler löschen",
    },
    saveCallback: {
      type: Function,
      required: true,
      info: "'saveCallback' der Detailseite wird an die GR weitergegeben."
    }
  },
  data() {
    return {
      statusLoading: true,
      panels: [],
      moduleGrouped: {},
      regelNummerByRubrikId: {},
    };
  },
  computed: {
    textForDetailsInfoBox() {
      return get(this.formular, "aktuelle_aufgabe.bez");
    },

    formularUrl() {
      return `formulare/${ this.formular.pk }/`;
    },

    statusEditPermission() {
      return this.checkPermissionsSync({
        perm: `${ this.formular.permissionset }.update`,
        permArray: this.formular.user_permissions,
      });
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      Promise.all([
        this.getListHttp({
          url: `${ this.formularUrl }module/`,
        }),
        this.getListHttp({
          url: `${ this.formularUrl }rubriken/`,
        }),
      ]).then(
        responses => {
          this.groupModule({ module: responses[0] });
          this.setPanels({ anzeigekontextrubriken: responses[1] });
          this.setPanelsForNotizen({ panels: cloneDeep(this.panels) });
          this.statusLoading = false;
        }
      );
    },

    groupModule({ module }) {
      const MODULE_GROUPED = {};
      const REGEL_NUMMER_BY_RUBRIK_ID = {};
      forEach(module, modul => {
        const RUBRIK = modul.regel.rubrik;
        if (!MODULE_GROUPED[RUBRIK]) {
          MODULE_GROUPED[RUBRIK] = [];
        }
        MODULE_GROUPED[RUBRIK].push(modul);
        REGEL_NUMMER_BY_RUBRIK_ID[modul.regel.regel_nummer] = RUBRIK;
      });
      this.moduleGrouped = MODULE_GROUPED;
      this.regelNummerByRubrikId = REGEL_NUMMER_BY_RUBRIK_ID;
    },

    setPanels({ anzeigekontextrubriken }) {
      const PANELS = [];
      if (this.statusRubrikFixed) {
        PANELS.push({
          label: "_TXT_FORMULAR_RUBRIK_ALLGEMEINE_INFORMATION_",
          icon: "list",
          key: "uebersicht",
          priority: -1,
          showNotizen: true,
          components: [
            "FormularDetailsBasisdaten",
          ],
          active: false,
        });
      }
      forEach(anzeigekontextrubriken, item => {
        if (this.moduleGrouped[item.rubrik]) {
          const PANEL = item.axr_config || {};
          PANEL.priority = item.axr_pos;
          PANEL.rubrik = item.rubrik;
          PANEL.showNotizen = true;
          PANEL.active = false;
          PANELS.push(PANEL);
        }
      });
      this.panels = sortBy(PANELS, ["priority"]);
    },

    updateModule({ group, response }) {
      const INDEX = findIndex(this.moduleGrouped[group], ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.moduleGrouped[group].splice(INDEX, 1, response);
      }
    },

    goToModul(error) {
      const REGELNUMMER = get(error, "modul.regelnummer");
      if (!REGELNUMMER) {
        return;
      }
      const REGELNUMMER_HTML_ID = `#${ REGELNUMMER }`;
      const STATUS_ELEMENT_VISIBLE = $(REGELNUMMER_HTML_ID).is(":visible");
      if (STATUS_ELEMENT_VISIBLE) {
        scrollToGlobal(REGELNUMMER_HTML_ID, undefined, undefined, undefined, 200);
        return;
      }
      const RUBRIK = this.regelNummerByRubrikId[REGELNUMMER];
      if (!RUBRIK || !this.$refs[RUBRIK] || !this.$refs[RUBRIK] || this.$refs[RUBRIK].status.active) {
        return;
      }
      this.$refs[RUBRIK].toggleCard();
      setTimeout(() => {
        scrollToGlobal(REGELNUMMER_HTML_ID, undefined, undefined, undefined, 200);
      });
    },
  },
};
