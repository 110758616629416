import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "IbanInSelect",
  props: {
    iban: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      filterIban,
    } = FiltersAPI();

    return {
      filterIban,
    };
  },
  computed: {
    ibanLocal() {
      return this.filterIban(this.iban, true);
    },
  },
};
