// Modul zur Zurordnung einer Terminreihe zum Antrag
"use strict";

import AuthServices from "dias/auth/services";
import CoreModels from "dias/core/models";
import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import FoerderantragModels from "dias/foerderantrag/models";
import controller from "./controller";
import template from "./template.jade";

import builder from "dias/standard_foerdermodule/utils/builder";


const terminreihe = angular.module("dias.kalender.foerdermodule.terminreihe", [
  AuthServices.name,
  CoreModels.name,
  foerdermoduleRegistry.name,
  FoerderantragModels.name
]);

terminreihe.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("terminreihe", "modul-terminreihe");
})

  .directive("modulTerminreihe", builder.directive(template, controller));

export default terminreihe;
