"use strict";

import BaseModel from "dias/core/models/baseModel";

class Nutzerfilter extends BaseModel {
  constructor() {
    super("nutzerfilter");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.getCurrentList = filterName => {
      return collection.customGETLIST("", { nfi_kennung: filterName });
    };

    return collection;
  }
}

export default Nutzerfilter;
