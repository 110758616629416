import Alert from "../../../global/components/Alert/Alert.vue";
import FormElement from "../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Spinner from "../../../global/components/Spinner/Spinner.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import TrackingObjectsMixin, {
  POSSIBLE_OBJECTS,
  validateObjects,
} from "../TrackingObjectsMixin";

import {
  filter,
} from "lodash-es";

// @vue/component
export default {
  name: "TrackingFilter",
  components: {
    Alert,
    FormElement,
    PuxButton,
    PuxTranslate,
    Spinner,
  },
  mixins: [
    HttpMixin,
    TrackingObjectsMixin,
  ],
  props: {
    htmlId: {
      type: String,
      required: true,
      info: "HTML-ID aus Parent",
    },
    keysForFilterObjects: {
      type: Array,
      required: true,
      validator: validateObjects,
      info: "Objekte werden mit die Keys gefiltert",
    },
    modelNumberDefault: {
      type: String,
      required: false,
      default: undefined,
      info: "Default-Wert für this.modelNumber",
    },
    modelObjectDefault: {
      type: String,
      required: false,
      validator: value => POSSIBLE_OBJECTS.indexOf(value) !== -1,
      default: undefined,
      info: "Default-Wert für this.modelObject aus POSSIBLE_OBJECTS",
    },
  },
  emits: [
    "onSearchSuccess",
  ],
  data() {
    return {
      answersCount: undefined,
      isSearching: false,
      modelObject: null,
      modelNumber: "",
    };
  },
  computed: {
    optionsObject() {
      return {
        type: "select",
        view: "v",
        id: "object",
        htmlId: `object_${ this.htmlId }`,
        label: "_LBL_TRACKING_OBJECT_",
        keyId: "value",
        keyLabel: "label",
        change: this.changeModelObject,
        dataTranslate: true,
      };
    },

    optionsNumber() {
      return {
        type: "text",
        view: "v",
        id: "number",
        htmlId: `number_${ this.htmlId }`,
        label: this.currentObject.labelNumber,
      };
    },

    buttonId() {
      return `btn_search_${ this.htmlId }`;
    },

    errorMessage() {
      if (this.answersCount > 1) {
        return "_TXT_TRACKING_ZU_VIELE_ANTWORTEN_";
      }
      if (this.answersCount === 0) {
        return "_TXT_TRACKING_KEINE_ANTWORTEN_";
      }
      return "";
    },

    filteredObjects() {
      return filter(this.objects, object => {
        return this.keysForFilterObjects.indexOf(object.value) !== -1;
      });
    },

    isFilteredObjectsShow() {
      return this.filteredObjects.length > 1;
    },

    currentObject() {
      return this.objectsByValue[this.modelObject] || {};
    },
  },
  created() {
    this.setModelObject();
    this.setModelNumber();
  },
  methods: {
    setModelObject() {
      if (this.modelObjectDefault) {
        this.modelObject = this.modelObjectDefault;
      } else if (this.keysForFilterObjects.length === 1) {
        this.modelObject = this.keysForFilterObjects[0];
      }
    },

    setModelNumber() {
      if (this.modelNumberDefault) {
        this.modelNumber = this.modelNumberDefault;
      }
    },

    onSearch() {
      if (this.isSearching) {
        return;
      }
      this.setSearching(true);
      this.deleteAnswersCount();
      this.getHttp({
        url: `${ this.currentObject.url }count/`,
        urlParams: {
          [this.currentObject.numberKey]: this.modelNumber,
        },
      }).then(
        response => {
          this.answersCount = response.count;
          this.setSearching(false);
          this.onSearchSuccess();
        }
      );
    },

    onSearchSuccess() {
      if (this.answersCount !== 1) {
        return;
      }
      this.$emit("onSearchSuccess", {
        objectNumber: this.modelNumber,
        objectName: this.currentObject.value,
      });
    },

    changeModelObject() {
      this.setDefaultModelNumber();
      this.deleteAnswersCount();
    },

    deleteAnswersCount() {
      this.answersCount = undefined;
    },

    setSearching(isSearching) {
      this.isSearching = isSearching;
    },

    setDefaultModelNumber() {
      this.modelNumber = "";
    },
  },
};
