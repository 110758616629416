"use strict";
import angular from "angular";
import listComponent from "./list.component";
import models from "../models";
import diasLoading from "dias/core/loading";
import dokumenteModels from "dias/dokumente/models";
import foerderantragModels from "dias/foerderantrag/models";
import teamModels from "dias/teams/models";
import foerderangeboteModels from "dias/foerderangebote/models";
import antragstellerModels from "dias/antragsteller/models";
import diasNotification from "dias/core/notification";
import diasConfirm from "dias/core/confirm";

const deckblattModule = angular.module("deckblatt.generieren", [
  models.name,
  diasLoading.name,
  dokumenteModels.name,
  foerderantragModels.name,
  foerderangeboteModels.name,
  antragstellerModels.name,
  diasNotification.name,
  diasConfirm.name,
  teamModels.name,
]);

deckblattModule.config($stateProvider => {
  $stateProvider

    // Liste
    .state("root.deckblatt_generieren", {
      url: "/deckblatt/generieren",
      params: {},
      views: {
        "main@root": {
          template: "<deckblatt-generieren-list></deckblatt-generieren-list>"
        }
      },
      data: {
        permissions: ["deckblatt.create"]
      }
    });
})

  .component("deckblattGenerierenList", listComponent)

;
export default deckblattModule;
