import AufgabeListDetails from "../AufgabeListDetails/AufgabeListDetails.vue";
import AufgabeModalAendernZustaendigkeit from "../AufgabeModalAendernZustaendigkeit/AufgabeModalAendernZustaendigkeit.vue";
import AufgabeModalAnnehmen from "../AufgabeModalAnnehmen/AufgabeModalAnnehmen.vue";
import AufgabeModalCreate from "../AufgabeModalCreate/AufgabeModalCreate.vue";
import AufgabeModalUpdateStatus from "../AufgabeModalUpdateStatus/AufgabeModalUpdateStatus.vue";
import AufgabeModalZuweisen from "../AufgabeModalZuweisen/AufgabeModalZuweisen.vue";
import NutzerschlagwortBadge from "../../Nutzerschlagwort/NutzerschlagwortBadge/NutzerschlagwortBadge.vue";
import NutzerschlagwortObjektModalAdd from "../../Nutzerschlagwort/NutzerschlagwortObjektModalAdd/NutzerschlagwortObjektModalAdd.vue";
import NutzerschlagwortObjektModalEdit from "../../Nutzerschlagwort/NutzerschlagwortObjektModalEdit/NutzerschlagwortObjektModalEdit.vue";
import NutzerschlagwortObjektModalRemove from "../../Nutzerschlagwort/NutzerschlagwortObjektModalRemove/NutzerschlagwortObjektModalRemove.vue";
import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import PermissionMixin from "../../../global/mixins/PermissionMixin";

import getTableApi from "../../../const/TableApis";
import KaufgabenstatusUUIDs from "../../../const/KaufgabenstatusUUIDs";
import { EventBus } from "../../../global/functions/event-bus";
import {
  cloneDeep,
  size,
} from "lodash-es";
import {
  getSelectorCloseForTableRowFunction
} from "../../../global/functions/utils";

const TABLE_ID = "aufgabe";

// @vue/component
export default {
  name: "AufgabeList",
  components: {
    AufgabeListDetails,
    AufgabeModalAendernZustaendigkeit,
    AufgabeModalAnnehmen,
    AufgabeModalCreate,
    AufgabeModalUpdateStatus,
    AufgabeModalZuweisen,
    NutzerschlagwortBadge,
    NutzerschlagwortObjektModalAdd,
    NutzerschlagwortObjektModalEdit,
    NutzerschlagwortObjektModalRemove,
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    PermissionMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [
          {
            label: "_LBL_AUFGABE_TABLE_ROW_ACTIONS_ZUM_ANTRAG_",
            isHidden: ({ item }) => item.herkunft !== "foerderantrag",
            route: "root.foerderantrag.details",
            routeParameters: [{ routeId: "id", rowPath: "obj_id" }],
          },
          {
            label: "_LBL_AUFGABE_TABLE_ROW_ACTIONS_ZUM_VERTRAG_",
            isHidden: ({ item }) => item.herkunft !== "vertrag",
            route: "root.vertrag.details",
            routeParameters: [{ routeId: "id", rowPath: "obj_id" }],
          },
          {
            label: "_LBL_AUFGABE_TABLE_ROW_ACTIONS_ZUR_SITZUNG_",
            isHidden: ({ item }) => item.herkunft !== "sitzung",
            route: "root.sitzungen.details",
            routeParameters: [{ routeId: "id", rowPath: "obj_id" }],
          },
          {
            label: "_LBL_AUFGABE_TABLE_ROW_ACTIONS_ZUR_ANTRAGSTELLERORGANISATION_",
            isHidden: ({ item }) => item.herkunft !== "antragstellerorganisation",
            route: "root.astorganisationen.details",
            routeParameters: [{ routeId: "id", rowPath: "obj_id" }],
          },
          {
            label: "_LBL_AUFGABE_TABLE_ROW_ACTIONS_ZUR_AUSZAHLUNG_",
            isHidden: ({ item }) => item.herkunft !== "auszahlung",
            route: "root.vertrag.details.auszahlung",
            routeParameters: [
              { routeId: "id", rowPath: "obj_parent_id[0]" },
              { routeId: "aus_id", rowPath: "obj_id" },
            ],
          },
          {
            label: "_LBL_AUFGABE_TABLE_ROW_ACTIONS_ZUM_VERTRAG_",
            isHidden: ({ item }) => item.herkunft !== "auszahlungsplan",
            route: "root.vertrag.details",
            routeParameters: [{ routeId: "id", rowPath: "obj_parent_id[0]" }],
          },
          {
            label: "_LBL_AUFGABE_TABLE_ROW_ACTIONS_ZUM_WEITERLEITUNGSVERTRAGVERTRAG_",
            isHidden: ({ item }) => item.herkunft !== "sammelvertrag",
            route: "root.sammelvertrag.details",
            routeParameters: [{ routeId: "id", rowPath: "obj_id" }],
          },
          {
            label: "_LBL_AUFGABE_TABLE_ROW_ACTIONS_ANNEHMEN_",
            isHidden: ({ item }) => item.nutzer,
            callback: ({ item }) => this.annehmen({ item }),
          },
          {
            label: "_LBL_AUFGABE_TABLE_ROW_ACTIONS_ZUWEISEN_ME_",
            isHidden: ({ item }) => !item.nutzer || item.nutzer.pk === this.user.pk,
            callback: ({ item }) => this.annehmen({ item }),
          },
          {
            label: "_LBL_AUFGABE_TABLE_ROW_ACTIONS_ZUWEISEN_",
            callback: ({ item }) => this.zuweisen({ item }),
          },
          {
            label: "_LBL_AUFGABE_TABLE_ROW_ACTIONS_ERLEDIGT_MARKIEREN_",
            isHidden: ({ item }) => !(item.is_manuelle_aufgabe && item.status.pk === KaufgabenstatusUUIDs.offen),
            callback: ({ item }) => this.markTaskAsDone({ item }),
          },
          {
            label: "_LBL_AUFGABE_TABLE_ROW_ACTIONS_UNERLEDIGT_MARKIEREN_",
            isHidden: ({ item }) => !(item.is_manuelle_aufgabe && item.status.pk === KaufgabenstatusUUIDs.abgeschlossen),
            callback: ({ item }) => this.markTaskAsUndone({ item }),
          },
          {
            label: "_TXT_AUFGABE_TABLE_NUTZERSCHLAGWORTOBJEKT_UPDATE_BTN_",
            callback: this.openModalNutzerschlagwortobjektEdit,
            isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          },
        ],
        actions: [
          {
            label: "_LBL_AUFGABE_TABLE_ACTIONS_CREATE_",
            callback: () => this.modal.create = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "aufgabe_create",
          },
        ],
        massActions: [],
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
        },
      },
      modal: {
        create: undefined,
        annehmen: undefined,
        zuweisen: undefined,
        updateStatus: undefined,
        aendernZustaendigkeit: undefined,
        nutzerschlagwortobjektEdit: undefined,
        nutzerschlagwortobjektMassActionAdd: undefined,
        nutzerschlagwortobjektMassActionRemove: undefined,
      },
      parameterUpdateStatus: undefined,
      currentAufgabe: undefined,
      user: {},
      rows: undefined,
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },


    nutzerschlagwortModalEditTitleTranslation() {
      return {
        text: "_TXT_AUFGABENLISTE_NUTZERSCHLAGWORT_MODAL_EDIT_TITLE_"
      };
    },

    nutzerschlagwortModalEditMsgTranslation() {
      return {
        html: "_HTML_AUFGABENLISTE_NUTZERSCHLAGWORT_MODAL_EDIT_INFO_"
      };
    },

    nutzerschlagwortModalMassActionAddTitleTranslation() {
      return {
        text: "_TXT_AUFGABENLISTE_NUTZERSCHLAGWORT_MODAL_MANY_ADD_TITLE_"
      };
    },

    nutzerschlagwortModalMassActionAddMsgTranslation() {
      return {
        html: "_HTML_AUFGABENLISTE_NUTZERSCHLAGWORT_MODAL_MANY_ADD_INFO_{{anz}}_",
        extra: {
          anz: size(this.selectedRows) || 0,
        }
      };
    },

    nutzerschlagwortModalMassActionRemoveTitleTranslation() {
      return {
        text: "_TXT_AUFGABENLISTE_NUTZERSCHLAGWORT_MODAL_MANY_REMOVE_TITLE_"
      };
    },

    nutzerschlagwortModalMassActionRemoveMsgTranslation() {
      return {
        html: "_HTML_AUFGABENLISTE_NUTZERSCHLAGWORT_MODAL_MANY_REMOVE_INFO_{{anz}}_",
        extra: {
          anz: size(this.selectedRows) || 0,
        }
      };
    },

  },
  created() {
    this.initMassActions();
    this.getUser();
  },
  methods: {
    initMassActions() {
      if (this.checkPermissionsSync({ perm: "aufgabe.zuweisen.batch" })) {
        this.options.massActions.push({
          label: "_LBL_AUFGABE_MASS_ACTIONS_ZUSTAENDIGKEIT_AENDERN_",
          callback: ({ rows }) => this.batchAufgabeZuweisen({ rows }),
          modal: true,
        });
      }
      if (this.checkPermissionsSync({ perm: "nutzerschlagworte.update" })) {
        this.options.massActions.push({
          label: "_TXT_AUFGABE_MASS_ACTIONS_NUTZERSCHLAGWORT_ADD_BTN_",
          callback: ({ rows }) => this.openModalNutzerschlagwortobjektMassActionAdd({ rows }),
          isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          modal: true,
        });
        this.options.massActions.push({
          label: "_TXT_AUFGABE_MASS_ACTIONS_NUTZERSCHLAGWORT_REMOVE_BTN_",
          callback: ({ rows }) => this.openModalNutzerschlagwortobjektMassActionRemove({ rows }),
          isHidden: () => !this.checkPermissionsSync({ perm: "nutzerschlagworte.update" }),
          modal: true,
        });
      }
    },

    getUser() {
      this.user = cloneDeep(this.me);
    },

    closeModalCreate({ status } = {}) {
      this.modal.create = false;
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
    },

    closeModalAnnehmen({ status } = {}) {
      this.modal.annehmen = false;
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
    },

    annehmen({ item }) {
      this.currentAufgabe = item;
      this.modal.annehmen = true;
    },

    zuweisen({ item }) {
      this.currentAufgabe = item;
      this.modal.zuweisen = true;
    },

    closeModalUpdateStatus({ status } = {}) {
      this.modal.updateStatus = false;
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
    },

    markTaskAsDone({ item }) {
      this.currentAufgabe = item;
      this.parameterUpdateStatus = {
        text: "_TXT_AUFGABE_TABLE_ITEM_ERLEDIGT_",
        status: KaufgabenstatusUUIDs.abgeschlossen,
      };
      this.modal.updateStatus = true;
    },

    markTaskAsUndone({ item }) {
      this.currentAufgabe = item;
      this.parameterUpdateStatus = {
        text: "_TXT_AUFGABE_TABLE_ITEM_UNERLEDIGT_",
        status: KaufgabenstatusUUIDs.offen,
      };
      this.modal.updateStatus = true;
    },

    closeModalZuweisen({ status } = {}) {
      this.modal.zuweisen = false;
      if (status) {
        EventBus.$emit(`updateTable${ TABLE_ID }`);
      }
    },

    batchAufgabeZuweisen({ rows }) {
      this.rows = rows;
      this.modal.aendernZustaendigkeit = true;
    },

    closeModalAendernZustaendigkeit({ status } = {}) {
      this.modal.aendernZustaendigkeit = false;
      EventBus.$emit(`cancelMassActions${ TABLE_ID }`, { status });
    },

    closeModalNutzerschlagwortobjektEdit({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektEdit = false;
    },

    openModalNutzerschlagwortobjektEdit({ item, index }) {
      this.selectorClose = getSelectorCloseForTableRowFunction({
        tableId: TABLE_ID,
        index,
      });
      this.currentAufgabe = item;
      this.modal.nutzerschlagwortobjektEdit = true;
    },

    closeModalNutzerschlagwortobjektMassActionAdd({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektMassActionAdd = false;
      EventBus.$emit(`cancelMassActions${ this.tableId }`, { status });
    },

    openModalNutzerschlagwortobjektMassActionAdd({ rows }) {
      this.selectorClose = `#${ TABLE_ID }`;
      this.selectedRows = rows;
      this.modal.nutzerschlagwortobjektMassActionAdd = true;
    },

    closeModalNutzerschlagwortobjektMassActionRemove({ status } = {}) {
      if (status) {
        EventBus.$emit(`updateTable${ this.tableId }`);
      }
      this.modal.nutzerschlagwortobjektMassActionRemove = false;
      EventBus.$emit(`cancelMassActions${ this.tableId }`, { status });
    },

    openModalNutzerschlagwortobjektMassActionRemove({ rows }) {
      this.selectorClose = `#${ TABLE_ID }`;
      this.selectedRows = rows;
      this.modal.nutzerschlagwortobjektMassActionRemove = true;
    },

  },
};
