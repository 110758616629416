import HttpMixin from "../../../../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../../../../global/mixins/PermissionMixin";

import KStudiengangartUUIDs from "../../../../../../const/KStudiengangartUUIDs";
import {
  cloneDeep,
  findIndex,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "StudiengangEditMixin",
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    studiengang: {
      type: Object,
      required: true,
    },
    copymode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: {
        sg_bez: undefined,
        sg_kbez: undefined,
        abschlussgrad_kbez: undefined,
        mastertyp_kbez: undefined,
        abschlussbezeichnung: undefined,
        typ_kbez: undefined,
        studienformen: undefined,
        art_kbez: undefined,
        sg_regelstudienzeit: undefined,
        regelstudieneinheit_kbez: undefined,
        sg_laufnr: undefined,
        sg_fakturieren: undefined,
        sg_abschlussbez_freitext: undefined,
        standorte: undefined,
        studienfelder: undefined,
        studienorganisatorische_teileinheit: undefined,
        sg_beginn_studienbetrieb: undefined,
        sg_aufnahmekapazitaet: undefined,
        sg_studienanfaenger: undefined,
        sg_absolventen: undefined,
        sg_ects_punkte: undefined,
        sg_erfolgsquote: undefined,
        sg_studiendauer: undefined,
        sg_studierende_w: undefined,
        sg_studierende_m: undefined,
        sg_studierende_s: undefined,
        lehramtstyp: undefined,
        polyvalenz_erlaeuterung: undefined,
        polyvalent: undefined,
        akkr_kopieren: undefined,
      },
      studiengangDefault: {},
      studienfelderList: [],
      standorte: [],
      teileinheitenChoices: [],
      studienfeld: "",
      studienfeldObj: {},
      showAdd: false,
      loading: false,
      loadingHochschule: false,
      loadingStandorte: false,
      loadingStudienorganisatorischeTeileinheiten: false,
      loadingStudienform: false,
      artChoices: [],
      typChoices: [],
      abschlussChoices: [],
      gradChoices: [],
      einheitChoices: [],
      mastertypChoices: [],
      lehramtstypChoices: [],
      formChoices: [],
      feldChoices: [],
      feldChoicesCopy: [],
      feldChoicesObj: {},
      bereichChoices: [],
      bereichChoicesObj: {},
      gruppeChoices: [],
      gruppeChoicesObj: {},
      bereichChoicesCopy: [],
      standortChoices: [],
      hochschuleChoices: [],
      studienbereich: "",
      faechergruppe: "",
      errors: {},
    };
  },
  computed: {
    formstepDetailOptionForStudienfelderAdd() {
      return {
        label: "Studienfelder hinzufügen",
        closeCallback: this.deleteModelStFeld,
      };
    },

    idForFormstepDetailStudienfelderAdd() {
      return `${ this.id }_studienfelder_add_form`;
    },

    idForSaveButton() {
      return `${ this.id }_studiengang_save`;
    },

    idForStudienfelderAddButton() {
      return `${ this.id }_studienfelder_add`;
    },

    idForStudienfelderSaveButton() {
      return `${ this.id }_studienfelder_save`;
    },

    idPrefixForStudienfelderDeleteButton() {
      return `${ this.id }_studienfelder_delete`;
    },

    showTeilstudiengaenge() {
      return (this.studiengang && this.studiengang.art === KStudiengangartUUIDs.KOMBI);
    },

    optionsSgBez() {
      const ID = "sg_bez";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "text",
        maxlength: 100,
        label: "Name",
        required: true,
        view: "v-alt",
        placeholder: "Name",
      };
    },

    optionsAbschlussgrad() {
      const ID = "abschlussgrad";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "select",
        label: "Abschlussgrad",
        required: true,
        view: "v-alt",
        keyId: "pk",
        keyLabel: "ag_kbez",
        placeholder: "---------",
        search: true,
        searchLabel: "Geben Sie einen Abschlussgrad ein",
        searchList: ["ag_kbez"],
        url: "katalog/?key=abschlussgrad",
        deselect: true,
      };
    },

    optionsAbschlussbezeichnung() {
      const ID = "abschlussbezeichnung";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "select",
        label: "Abschlussbezeichnung",
        required: true,
        view: "v-alt",
        keyId: "pk",
        keyLabel: "abs_kbez",
        menuWidthAll: true,
        url: "katalog/",
        urlParams: {
          key: "abschlussbezeichnung",
        },
        apiSaveId: "abschlussbezeichnungen_katalog",
        placeholder: "---------",
        deselect: true,
        search: true,
        searchLabel: "Geben Sie eine Abschlussbezeichnung ein",
        searchList: ["abs_kbez"],
        infoText: "Wenn eine freie Bezeichnung bzw. Eingabe mehrerer Bezeichnungen gewünscht ist, wählen Sie bitte „sonstiges“ aus. Es wird dann ein zusätzliches Eingabefeld zur Verfügung gestellt.",
        infoTextActiveDefault: true,
      };
    },

    optionsTyp() {
      const ID = "typ";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "select",
        label: "Studiengangstyp",
        required: true,
        view: "v-alt",
        keyId: "pk",
        keyLabel: "kst_kbez",
        menuWidthAll: true,
        url: "katalog/?key=studientyp",
        apiSaveId: "studientypen_katalog",
        placeholder: "---------",
        deselect: true,
        search: true,
        searchLabel: "Geben Sie eine Studiengangstyp ein",
        searchList: ["kst_kbez"],
      };
    },

    optionsLehramtstyp() {
      const ID = "lehramtstyp";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "select",
        label: "Lehramtstyp",
        required: false,
        view: "v-alt",
        keyId: "pk",
        keyLabel: "lat_kbez",
        menuWidthAll: true,
        url: "katalog/?key=lehramtstyp",
        apiSaveId: "lehramtstypen_katalog",
        placeholder: "---------",
        deselect: true,
        search: true,
        searchLabel: "Geben Sie einen Lehramtstyp ein",
        searchList: ["lat_kbez"],
      };
    },

    optionsSgRegelstudienzeit() {
      const ID = "sg_regelstudienzeit";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "number",
        label: "Regelstudienzeit",
        required: true,
        view: "v-alt",
        placeholder: "Regelstudienzeit",
        maxlength: 100,
      };
    },

    optionsRegelstudieneinheit() {
      const ID = "regelstudieneinheit";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "select",
        label: "Regelstudieneinheit",
        view: "v-alt",
        keyId: "pk",
        keyLabel: "szg_kbez",
        menuWidthAll: true,
        required: true,
        url: "katalog/?key=regelstudieneinheit",
        apiSaveId: "regelstudieneinheiten_katalog",
        placeholder: "---------",
        search: true,
        searchList: ["szg_kbez"],
      };
    },

    optionSgLaufnummer() {
      const ID = "sg_laufnr";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "text",
        label: "HSK Laufnummer",
        required: false,
        view: "v-alt",
        placeholder: "HSK Laufnummer",
        maxlength: 60,
        infoText: "Dieses Feld muss nicht ausgefüllt werden.",
        infoTextActiveDefault: true,
      };
    },

    optionStudienorganisatorischeTeileinheit() {
      const ID = "studienorganisatorische_teileinheit";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "select",
        label: "Studienorganisatorische Teileinheit",
        required: false,
        view: "v-alt",
        keyId: "pk",
        keyLabel: "sot_kbez",
        placeholder: "---------",
        deselect: true,
        search: true,
        searchList: ["sot_kbez"],
        data: this.teileinheitenChoices,
        loading: this.loadingStudienorganisatorischeTeileinheiten,
      };
    },

    optionPolyvalent() {
      const ID = "polyvalent";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        label: "Polyvalent",
        key: "key2",
        type: "radio",
        view: "v-alt",
        required: false,
        data: [
          {
            label: "Ja",
            value: true,
          },
          {
            label: "Nein",
            value: false,
          },
        ],
      };
    },

    optionPolyvalenzErlaeuterung() {
      const ID = "polyvalenz_erlaeuterung";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "text",
        label: "Anmerkungen zum Studiengang",
        required: false,
        view: "v-alt",
        placeholder: "Anmerkungen zum Studiengang",
        maxlength: 1000,
        infoText: "Dieses Feld muss nicht ausgefüllt werden.",
        infoTextActiveDefault: true,
      };
    },

    optionSgBeginnStudienbetrieb() {
      const ID = "sg_beginn_studienbetrieb";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        required: false,
        type: "date",
        label: "Beginn Studienbetrieb",
        placeholder: "Von",
        view: "v-alt",
      };
    },

    optionSgAufnahmekapazitaet() {
      const ID = "sg_aufnahmekapazitaet";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        required: false,
        type: "number",
        label: "Aufnahmekapazität pro Semester oder Jahr",
        view: "v-alt",
      };
    },

    optionSgStudienanfaenger() {
      const ID = "sg_studienanfaenger";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        required: false,
        type: "number",
        label: "Durchschnittliche Anzahl von StudienanfängerInnen pro Semester oder Jahr",
        view: "v-alt",
      };
    },

    optionSgAbsolventen() {
      const ID = "sg_absolventen";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        required: false,
        type: "number",
        label: "Durchschnittliche Anzahl von AbsolventInnen pro Semester oder Jahr",
        view: "v-alt",
      };
    },

    optionSgEctsPunkte() {
      const ID = "sg_ects_punkte";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        required: false,
        type: "number",
        label: "ECTS Punkte",
        view: "v-alt",
      };
    },

    optionSgStudiendauer() {
      const ID = "sg_studiendauer";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "text",
        label: "Durchschnittliche Studiendauer (in Semestern/Trimestern/Quartalen)",
        required: false,
        view: "v-alt",
      };
    },

    optionSgStudierendeW() {
      const ID = "sg_studierende_w";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        required: false,
        type: "number",
        label: "Anzahl der weiblichen Studierenden",
        view: "v-alt",
      };
    },

    optionSgStudierendeM() {
      const ID = "sg_studierende_m";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        required: false,
        type: "number",
        label: "Anzahl der männlichen Studierenden",
        view: "v-alt",
      };
    },

    optionSgStudierendeS() {
      const ID = "sg_studierende_s";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        required: false,
        type: "number",
        label: "Anzahl der Studierenden (Geschlecht divers/keine Angabe)",
        view: "v-alt",
      };
    },

    optionMastertyp() {
      const ABSCHLUSSGRAD_MASTER_UUID = "e47fbd04-90ec-4f11-af4c-5e3be408aaf9";
      const ID = "mastertyp";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "select",
        label: "Mastertyp",
        required: false,
        view: "v-alt",
        keyId: "pk",
        keyLabel: "mt_kbez",
        menuWidthAll: true,
        url: "katalog/?key=mastertyp",
        apiSaveId: "mastertyp_katalog",
        placeholder: "---------",
        deselect: true,
        search: true,
        searchLabel: "Geben Sie einen Mastertyp ein",
        searchList: ["mt_kbez"],
        hideIf: !(this.model.abschlussgrad && this.model.abschlussgrad === ABSCHLUSSGRAD_MASTER_UUID),
      };
    },

    optionSgAbschlussbezFreitext() {
      const SG_ABSCHLUSSBEZ_FREITEXT_UUID = "03ed4220-8b11-4254-865d-74ff717ceeb9";
      const ID = "sg_abschlussbez_freitext";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "text",
        label: "weitere Abschlussbezeichnung",
        required: false,
        view: "v-alt",
        placeholder: "weitere Abschlussbezeichnung",
        maxlength: 200,
        hideIf: !(this.model.abschlussbezeichnung && this.model.abschlussbezeichnung === SG_ABSCHLUSSBEZ_FREITEXT_UUID),
      };
    },

    optionStudienformen() {
      const ID = "studienformen";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "multiselect",
        label: "Studienformen",
        required: true,
        view: "v-alt",
        keyId: "pk",
        keyLabel: "sdf_kbez",
        placeholder: "Elemente auswählen",
        deselect: true,
        search: true,
        searchLabel: "Geben Sie Studienformen ein",
        searchList: ["sdf_kbez"],
        data: this.formChoices,
        hideIf: !(this.formChoices.length),
      };
    },

    optionHochschule() {
      const ID = "hochschule";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "select",
        label: "Hochschule",
        required: true,
        view: "v-alt",
        keyId: "pk",
        keyLabel: "hs_bez",
        menuWidthAll: true,
        placeholder: "Hochschule auswählen",
        deselect: true,
        search: true,
        searchLabel: "Geben Sie Hochschule ein",
        searchList: ["hs_bez"],
        data: this.hochschuleChoices,
        loading: this.loadingHochschule,
        hideIf: !(this.showCopy && this.model.art === KStudiengangartUUIDs.EINZEL),
      };
    },

    optionSgFakturieren() {
      const ID = "sg_fakturieren";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "checkbox",
        label: "Teilstudiengang fakturieren",
        required: false,
        view: "v-alt",
        hideIf: !(this.model.art === KStudiengangartUUIDs.TEIL && this.checkPermissionsSync({ perm: "studiengang.fakturierung.verwalten" })),
      };
    },

    optionStandorte() {
      const ID = "standorte";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "multiselect",
        label: "Standorte",
        required: true,
        view: "v-alt",
        keyId: "pk",
        keyLabel: "ort_bez",
        placeholder: "Standorte auswählen",
        search: true,
        searchList: ["ort_bez"],
        data: this.standortChoices,
        loading: this.loadingStandorte,
        hideIf: !(this.standortChoices.length),
      };
    },

    optionsErfolgsquote() {
      const ID = "sg_erfolgsquote";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "text",
        label: "Erfolgsquote",
        required: false,
        view: "v-alt",
        placeholder: "Erfolgsquote",
      };
    },

    optionsList() {
      return [
        this.optionsSgBez,
        this.optionsAbschlussgrad,
        this.optionMastertyp,
        this.optionsAbschlussbezeichnung,
        this.optionSgAbschlussbezFreitext,
        this.optionsTyp,
        this.optionsLehramtstyp,
        this.optionStudienformen,
        this.optionHochschule,
        this.optionsSgRegelstudienzeit,
        this.optionsRegelstudieneinheit,
        this.optionSgLaufnummer,
        this.optionSgFakturieren,
        this.optionStandorte,
        this.optionStudienorganisatorischeTeileinheit,
        this.optionPolyvalent,
        this.optionPolyvalenzErlaeuterung,
        this.optionSgBeginnStudienbetrieb,
        this.optionSgAufnahmekapazitaet,
        this.optionSgStudienanfaenger,
        this.optionSgAbsolventen,
        this.optionSgEctsPunkte,
        this.optionsErfolgsquote,
        this.optionSgStudiendauer,
        this.optionSgStudierendeW,
        this.optionSgStudierendeM,
        this.optionSgStudierendeS,
      ];
    },

    optionFaechergruppe() {
      const ID = "faechergruppe";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "select",
        label: "Fächergruppe",
        view: "v-alt",
        keyId: "pk",
        keyLabel: "fae_kbez",
        placeholder: "Fächergruppe auswählen",
        deselect: true,
        search: true,
        searchList: ["fae_kbez"],
        data: this.gruppeChoices,
        loading: !this.feldChoices.length,
        change: this.onSelectFaechergruppe,
      };
    },

    optionStudienbereiche() {
      const ID = "studienbereiche";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "select",
        label: "Studienbereiche",
        view: "v-alt",
        keyId: "pk",
        keyLabel: "sdb_kbez",
        placeholder: "Studienbereiche auswählen",
        deselect: true,
        search: true,
        searchList: ["sdb_kbez"],
        data: this.bereichChoicesCopy,
        loading: !this.feldChoices.length,
        change: this.onSelectStudienbereich,
      };
    },

    optionStudienfelder() {
      const ID = "studienfelder";
      return {
        id: ID,
        htmlId: `${ this.id }_${ ID }`,
        type: "select",
        label: "Studienfelder",
        view: "v-alt",
        keyId: "pk",
        keyLabel: "stf_kbez",
        placeholder: "Studienfelder auswählen",
        deselect: true,
        search: true,
        searchList: ["stf_kbez"],
        data: this.feldChoicesCopy,
        loading: !this.feldChoices.length,
        change: this.onSelectStudienfeld,
      };
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.model = cloneDeep(this.studiengang);
      this.studiengangDefault = cloneDeep(this.studiengang);
      this.loadData();
      if (this.showCopy) {
        this.loadHochschule();
      }
      this.onSelectHochschule(true);
      this.model.studienformen = cloneDeep(this.model.studienformen_id);
      this.model.standorte = cloneDeep(this.model.standorte_id);
      this.model.studienfelder = cloneDeep(this.model.studienfelder_id);
    },

    onErrors(errors) {
      this.addNotification({ text: "Ihre Daten sind fehlerhaft oder unvollständig.", type: "error" });
      if (errors.data) {
        this.errors = errors.data;
      } else {
        this.errors = errors;
      }
    },

    async loadData() {
      try {
        this.loadingStudienform = false;

        const [
          formChoicesResponse,
          feldChoicesResponse,
          bereichChoicesResponse,
          gruppeChoicesResponse,
        ] = await Promise.all([
          this.getHttp({
            url: "katalog/",
            urlParams: {
              key: "studienform",
              fields: ["pk", "sdf_kbez"],
            },
          }),
          this.getHttp({
            url: "studienfelder/",
          }),
          this.getHttp({
            url: "studienbereiche/",
            urlParams: {
              fields: ["pk", "sdb_kbez", "faechergruppe", "studienfelder"],
            },
          }),
          this.getHttp({
            url: "katalog/",
            urlParams: {
              key: "faechergruppe",
              fields: ["pk", "fae_kbez"],
            },
            apiSaveId: "faechergruppe_katalog",
          }),
        ]);

        this.formChoices = formChoicesResponse;
        this.loadingStudienform = true;

        this.feldChoices = feldChoicesResponse.results;
        this.feldChoicesCopy = feldChoicesResponse.results;
        this.feldChoicesObj = {};
        forEach(this.feldChoices, val => {
          this.feldChoicesObj[val.stf_id] = val;
        });
        this.getStudienfelderList();

        this.bereichChoices = bereichChoicesResponse.results;
        this.bereichChoicesObj = {};
        forEach(this.bereichChoices, val => {
          this.bereichChoicesObj[val.sdb_id] = val;
        });

        this.gruppeChoices = gruppeChoicesResponse;
        this.gruppeChoicesObj = {};
        forEach(this.gruppeChoices, val => {
          this.gruppeChoicesObj[val.id] = val;
        });
      } catch (e) {
        console.error(e);
      }
    },

    loadHochschule() {
      this.loadingHochschule = true;
      this.getHttp({
        url: "hochschulen/",
        urlParams: {
          fields: ["pk", "hs_bez"],
        },
      }).then(result => {
        this.hochschuleChoices = result.results;
        if (this.hochschuleChoices.length === 1) {
          this.model.hochschule = this.hochschuleChoices[0].hs_id;
          this.onSelectHochschule();
        }
      }).finally(
        () => this.loadingHochschule = false
      );
    },

    onSelectHochschule(statusFirstLaden) {
      this.standorte = [];
      this.loadingStandorte = true;
      this.loadingStudienorganisatorischeTeileinheiten = true;

      this.getHttp({
        url: `hochschulen/${ this.model.hochschule }/standorte/`
      }).then(
        response => {
          if (!statusFirstLaden) {
            if (response.results.length === 1) {
              this.model.standorte = [response.results[0].ort_id];
            } else if (response.results.length) {
              this.model.standorte = [];
            }
          }
          this.standortChoices = response.results;
        },
        () => this.addNotification({ text: "Fehler beim Laden der Standorte.", type: "error" })
      ).finally(
        () => this.loadingStandorte = false
      );

      this.getHttp({
        url: `hochschulen/${ this.model.hochschule_obj.pk }/teileinheiten/`
      }).then(
        response => {
          this.teileinheitenChoices = response.results;
        },
        () => this.addNotification({ text: "Fehler beim Laden der studienorganisatorische Teileinheiten.", type: "error" })
      ).finally(
        () => this.loadingStudienorganisatorischeTeileinheiten = false
      );
    },

    saveStudienfeld() {
      this.model.studienfelder.push(this.studienfeld);
      this.studienfelderList.push(this.studienfeldObj);
      this.showAdd = false;
    },

    onSelectStudienbereich() {
      if (this.studienfelderList.length) {
        this.feldChoicesCopy = this.feldChoices.filter(val => {
          if (this.faechergruppe) {
            return (val.faechergruppe === this.faechergruppe) && (val.studienbereich === this.studienbereich);
          }
          return (val.studienbereich === this.studienbereich);
        });
        forEach(this.model.studienfelder, feld => {
          const index = findIndex(this.feldChoicesCopy, ["stf_id", feld]);
          if (index !== -1) {
            this.feldChoicesCopy.splice(index, 1);
          }
        });
      } else {
        this.feldChoicesCopy = this.feldChoices.filter(val => {
          if (this.faechergruppe) {
            return (val.faechergruppe === this.faechergruppe) && (val.studienbereich === this.studienbereich);
          }
          return (val.studienbereich === this.studienbereich);
        });
        if (!this.faechergruppe) {
          this.faechergruppe = this.studienbereichObj.faechergruppe;
        }
        this.studienfeld = "";
      }
    },

    onSelectFaechergruppe() {
      if (this.studienfelderList.length) {
        this.feldChoicesCopy = this.feldChoices.filter(val => val.faechergruppe === this.faechergruppe);
        forEach(this.model.studienfelder, feld => {
          const index = findIndex(this.feldChoicesCopy, ["stf_id", feld]);
          if (index !== -1) {
            this.feldChoicesCopy.splice(index, 1);
          }
        });
      } else {
        this.feldChoicesCopy = this.feldChoices.filter(val => val.faechergruppe === this.faechergruppe);
      }
      this.bereichChoicesCopy = this.bereichChoices.filter(val => val.faechergruppe === this.faechergruppe);
      this.studienbereich = "";
      this.studienfeld = "";
    },

    onSelectStudienfeld() {
      this.studienfeldObj = this.feldChoicesObj[this.studienfeld];
      this.studienbereich = this.studienfeldObj.studienbereich;
      this.faechergruppe = this.studienfeldObj.faechergruppe;
    },

    deleteModelStFeld() {
      this.studienfeld = "";
      this.studienbereich = "";
      this.faechergruppe = "";
      this.showAdd = false;
    },

    deleteStudienFeld(feld) {
      const indexList = findIndex(this.studienfelderList, ["stf_id", feld.stf_id]);
      this.studienfelderList.splice(indexList, 1);
      const index = this.model.studienfelder.indexOf(feld.stf_id);
      this.model.studienfelder.splice(index, 1);
      this.initStFelderData();
    },

    addStudienfelder() {
      this.initStFelderData();
      this.showAdd = true;
    },

    initStFelderData() {
      this.feldChoicesCopy = cloneDeep(this.feldChoices);
      forEach(this.model.studienfelder, feld => {
        const index = findIndex(this.feldChoices, ["stf_id", feld]);
        if (index !== -1) {
          this.feldChoicesCopy.splice(index, 1);
        }
      });
      this.bereichChoicesCopy = cloneDeep(this.bereichChoices);
    },

    getStudienfelderList() {
      forEach(this.model.studienfelder, feld => {
        const index = findIndex(this.feldChoices, ["stf_id", feld]);
        if (index !== -1) {
          this.studienfelderList.push(this.feldChoicesObj[feld]);
        }
        this.initStFelderData();
        this.showAdd = false;
      });
    },
  },
};
