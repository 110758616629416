import ModuleMixin from "../ModuleMixin";
import SnapshotModule from "../../../Snapshot/SnapshotModule/SnapshotModule.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import ThemenauswahlCheckboxOrRadio from "./ThemenauswahlCheckboxOrRadio/ThemenauswahlCheckboxOrRadio.vue";
import {
  cloneDeep,
  keyBy,
  forEach,
  find,
  findIndex,
  isEmpty,
} from "lodash-es";

// @vue/component
export default {
  name: "Themenauswahl",
  components: {
    SnapshotModule,
    SnapshotIcon,
    ThemenauswahlCheckboxOrRadio,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      auswahlByPk: {},
    };
  },
  computed: {
    optionsFormstepDetailLocal() {
      const OPTIONS = cloneDeep(this.optionsFormstepDetail);
      OPTIONS.openCallback = this.open;
      return OPTIONS;
    },

    labelHeader() {
      return this.modelParameter.frage;
    },

    wahl() {
      const WAHL = [];
      forEach(this.modelParameter.auswahl, item => {
        const MODEL = find(this.model.wahl, ["pk", item.pk]);
        if (MODEL) {
          WAHL.push(MODEL);
        }
      });
      return WAHL;
    },

    wahlSnapshot() {
      const WAHL = [];
      if (this.snapshotModule && this.snapshotModule.wahl) {
        forEach(this.modelParameter.auswahl, item => {
          const MODEL = find(this.snapshotModule.wahl, ["pk", item.pk]);
          if (MODEL) {
            WAHL.push(MODEL);
          }
        });
      }
      return WAHL;
    },

    snapshotModuleWahl() {
      if (this.snapshotModule && this.snapshotModule.wahl) {
        return this.snapshotModule.wahl;
      }
      return [];
    },

    modelWahl() {
      return this.model.wahl || [];
    },

    diff() {
      const DIFF = {};
      if (this.snapshotModule) {
        forEach(this.modelWahl, item => {
          const INDEX = findIndex(this.snapshotModuleWahl, ["pk", item.pk]);
          if (INDEX === -1) {
            DIFF[item.pk] = true;
            DIFF[`${ item.pk }_freitext`] = true;
          } else if (item.freitext !== this.snapshotModuleWahl[INDEX].freitext) {
            DIFF[`${ item.pk }_freitext`] = true;
          }
        });
        forEach(this.snapshotModuleWahl, item => {
          const INDEX = findIndex(this.modelWahl, ["pk", item.pk]);
          if (INDEX === -1) {
            DIFF[item.pk] = true;
            DIFF[`${ item.pk }_freitext`] = true;
          } else if (item.freitext !== this.modelWahl[INDEX].freitext) {
            DIFF[`${ item.pk }_freitext`] = true;
          }
        });
      }
      return DIFF;
    },

    statusDiff() {
      return !isEmpty(this.diff);
    },
  },
  created() {
    this.initAuswahlByPk();
  },
  methods: {
    prepareModel() {
      const MODEL = cloneDeep(this.module.data) || {};
      MODEL.wahl = MODEL.wahl || [];
      this.model = MODEL;
    },

    initAuswahlByPk() {
      if (this.modelParameter.auswahl) {
        this.auswahlByPk = keyBy(this.modelParameter.auswahl, "pk");
      }
    },

    open() {
      this.modelEdit = cloneDeep(this.model);
    },

    updateModel({ model } = {}) {
      this.modelEdit = cloneDeep(model);
    },
  },
};
