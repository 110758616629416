"use strict";

import parameterServices from "../services";
import template from "./config.jade";
import controller from "./controller";


const parametermoduleString = angular.module("dias.foerderregeln.parameter.text", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("text", "parametermodul-text");
  })
  .component("parametermodulText", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
      change: "&?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulTextRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: `<div
    class="code-content scroll-content"
    style="max-height: 150px; width: 100%;">{{ vm.parameter[vm.key].wert }}</div>`,

    controllerAs: "vm",
  });
export default parametermoduleString;
