"use strict";

import BaseModel from "dias/core/models/baseModel";

class Benachrichtigungen extends BaseModel {
  constructor() {
    super("benachrichtigungen");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.batchDelete = pks => {
      return collection.customPOST({ pks: pks }, "batch_delete");
    };
    collection.markRead = pks => {
      return collection.one("markRead").customPOST({ pks: pks });
    };
    collection.send = data => {
      return collection.one("send").customPOST(data);
    };
    return collection;
  }
}

export default Benachrichtigungen;
