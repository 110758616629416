import SnapshotIcon from "../../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";
import {
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "CheckboxReadonly",
  components: {
    SnapshotIcon,
    PuxIcon,
  },
  props: {
    antwort: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      default: () => ({}),
    },
    mehrfachauswahl: {
      type: Boolean,
      required: true,
    },
    diff: {
      type: Object,
      required: false,
      default: undefined,
    },
    statusAnzeigeAuswahl: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    iconLocal() {
      if (this.statusIconOk) {
        if (this.mehrfachauswahl) {
          return "check";
        }
        return "ok";
      }
      if (this.mehrfachauswahl) {
        return "unchecked";
      }
      return "nicht-entschieden";
    },

    iconName() {
      if (!this.antwort.children || !this.antwort.children.length) {
        return this.model.status ? "ok" : undefined;
      }
      let countOk = 0;
      forEach(this.antwort.children, child => {
        if (this.model.children &&
          this.model.children[child.label] &&
          this.model.children[child.label].status) {
          countOk++;
        }
      });
      if (countOk === 0) {
        return undefined;
      } else if (countOk === this.antwort.children.length) {
        return "ok";
      } else if (countOk === 1 && !this.mehrfachauswahl) {
        return "ok";
      }
      return "partly";
    },

    statusShow() {
      if (!this.statusAnzeigeAuswahl) {
        return true;
      }
      return this.statusIconOk;
    },

    statusIconOk() {
      return this.iconName === "ok" || this.iconName === "partly";
    },
  },
};
