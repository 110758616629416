import {
  computed,
  toRefs,
} from "vue";

import FiltersAPI from "../../../../../global/compositionAPI/FiltersAPI";

export default {
  name: "SitzungDetailsMittelTableCellCurrency",
  props: {
    amount: {
      type: [Number, String],
      default: undefined,
    },
    isSumRow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      amount,
    } = toRefs(props);

    const {
      filterCurrency,
    } = FiltersAPI();

    const filteredValue = computed(() => {
      return filterCurrency(amount.value || 0);
    });

    return {
      filteredValue,
    };
  }
};
