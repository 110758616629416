var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

jade_mixins["form-element"] = jade_interp = function(label, fieldName, errorsMap, prefix){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-class", "{'has-error': " + (errorsMap) + "['" + (fieldName) + "']}", true, true)) + (jade.cls(['form-group','row',attributes.class], [null,null,false])) + "><label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"col-form-label col-sm-3 text-right font-weight-bold required\"><span" + (jade.attr("ng-class", "{'required':vm.isRequired('" + (fieldName) + "')}", true, true)) + " class=\"titel\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span></label><div class=\"col-sm-9 col-xl-6\">");
block && block();
buf.push("<label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"help-block\"><ul" + (jade.attr("ng-if", "" + (errorsMap) + "['" + (fieldName) + "']", true, true)) + " class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errorsMap) + "['" + (fieldName) + "']", true, true)) + ">{{ err }}</li></ul></label></div></div>");
};
jade_mixins["not-required-form-element"] = jade_interp = function(label, fieldName, errorsMap, prefix){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-class", "{'has-error': " + (errorsMap) + "['" + (fieldName) + "']}", true, true)) + (jade.cls(['form-group','row',attributes.class], [null,null,false])) + "><label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"col-form-label col-sm-3 text-right font-weight-bold\"><span class=\"titel\">" + (jade.escape((jade_interp = label) == null ? '' : jade_interp)) + "<span class=\"alert-danger\"></span></span></label><div class=\"col-sm-9 col-xl-6\">");
block && block();
buf.push("<label" + (jade.attr("ng-attr-for", "{{" + (prefix) + "}}_" + (fieldName) + "", true, true)) + " class=\"help-block\"><ul" + (jade.attr("ng-if", "" + (errorsMap) + "['" + (fieldName) + "']", true, true)) + " class=\"errorlist\"><li" + (jade.attr("ng-repeat", "err in " + (errorsMap) + "['" + (fieldName) + "']", true, true)) + ">{{ err }}</li></ul></label></div></div>");
};




















jade_mixins["vorschau-daten"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-repeat", "(key, val) in " + (vorschau) + "", true, true)) + "><span ng-if=\"val\">{{key}}: {{ val }}</span></div>");
};






buf.push("<div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" ng-model=\"ctx.posmodel.argument.gesamt\" maxlength=\"14\" dias-decimal-field name=\"gesamt\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_gesamt\" ng-attr-aria-invalid=\"{{ctx.errors.gesamt ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">&euro;</span></div></div>");
}
}, "{{ctx.gettext('Gesamtkosten')}}", "gesamt", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" ng-model=\"ctx.posmodel.argument.part_de\" maxlength=\"14\" dias-decimal-field name=\"part_de\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_part_de\" ng-attr-aria-invalid=\"{{ctx.errors.part_de ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">&euro;</span></div></div>");
}
}, "{{ctx.gettext('deutsche Kosten')}}", "part_de", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["not-required-form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" ng-model=\"ctx.posmodel.argument.part_de_sn\" maxlength=\"14\" dias-decimal-field name=\"part_de_sn\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_part_de_sn\" ng-attr-aria-invalid=\"{{ctx.errors.part_de_sn ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">&euro;</span></div></div>");
}
}, "{{ctx.gettext('davon Kosten in Sachsen')}}", "part_de_sn", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["not-required-form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" ng-model=\"ctx.posmodel.argument.part_de_st\" maxlength=\"14\" dias-decimal-field name=\"part_de_st\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_part_de_st\" ng-attr-aria-invalid=\"{{ctx.errors.part_de_st ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">&euro;</span></div></div>");
}
}, "{{ctx.gettext('davon Kosten in Sachsen-Anhalt')}}", "part_de_st", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["not-required-form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" ng-model=\"ctx.posmodel.argument.part_de_th\" maxlength=\"14\" dias-decimal-field name=\"part_de_th\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_part_de_th\" ng-attr-aria-invalid=\"{{ctx.errors.part_de_th ? 'true' : 'false'}}\" class=\"form-control\"><div class=\"input-group-append\"><span class=\"input-group-text\">&euro;</span></div></div>");
}
}, "{{ctx.gettext('davon Kosten in Thüringen')}}", "part_de_th", "ctx.errors", "ctx.prefix");
buf.push("</div><div>");
jade_mixins["not-required-form-element"].call({
block: function(){
buf.push("<div class=\"input-group\"><input type=\"text\" ng-model=\"ctx.posmodel.argument.begruendung\" maxlength=\"200\" name=\"begruendung\" aria-required=\"true\" ng-attr-id=\"{{ctx.prefix}}_begruendung\" ng-attr-aria-invalid=\"{{ctx.errors.begruendung ? 'true' : 'false'}}\" class=\"form-control\"></div>");
}
}, "{{ctx.gettext('Begründung für höheren Ansatz')}}", "begruendung", "ctx.errors", "ctx.prefix");
buf.push("</div>");;return buf.join("");
}