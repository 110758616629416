// @vue/component
export default {
  data() {
    return {
      classIconMapping: {
        edit: "text-color-edit",
        success: "text-color-success",
        info: "",
        info_big: "",
        info_edit: "text-color-edit",
        info_success: "text-color-success",
      },
    };
  },
  computed: {
    classIcon() {
      if (!this.parameter) {
        return;
      }
      return this.classIconMapping[this.parameter.css_klasse];
    },

    parameterIcon() {
      return this.parameter && this.parameter.icon;
    },
  },
};
