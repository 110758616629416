"use strict";

import angular from "angular";

import services from "dias/sitzungen/services";

import ListenstatusComp from "./icon.listenstatus.component";

const modelsModule = angular.module("sitzungen.ui", [
  services.name
])

  .component("iconListenstatus", ListenstatusComp)
;

export default modelsModule;
