"use strict";

const decimalFieldComponent = /*@ngInject*/ function($filter) {
  return {
    restrict: "A",
    require: "ngModel",
    link: function(scope, elem, attrs, ctrl) {
      const blocked = !_.isNil(attrs.diasDecimalFieldBlocked) && attrs.diasDecimalFieldBlocked.toLowerCase() === "true";
      let places = 2;
      if (attrs.diasDecimalField && !Number.isNaN(attrs.diasDecimalField)) {
        places = Number(attrs.diasDecimalField);
      }
      if (!elem.attr("placeholder")) {
        elem.attr("placeholder", "0,00");
      }
      ctrl.$formatters.unshift(() => {
        // convert data from model format to view format
        if (Number.isNaN(Number.parseFloat(ctrl.$modelValue))) {
          return "";
        }
        if (blocked) {
          return ctrl.$modelValue;
        }
        return $filter("number")(ctrl.$modelValue, places);
      });
      const regexStr = RegExp(`(\\d*\\.\\d{${ places }})\\d+`);
      ctrl.$parsers.unshift(viewValue => {
        // convert data from view format to model format
        if (!viewValue || viewValue.length === 0) {
          return null;
        }
        const result = viewValue.replace(/\./g, "").replace(",", ".").replace(regexStr, "$1");
        return result;
      });
    }
  };
};

export default decimalFieldComponent;
