const PRUEFMETHODE = {
  INTERN_ERGAENZT: "c23c47ec-8076-4661-8e03-2e8d6c36fc38",
  PAUSCHAL_ANERKANNT: "c4efcc11-ee1e-4916-bc37-a94754295c7e",
  EINZELN_GEPRUEFT: "ae58b058-8aec-49b3-9e95-8bbef9aaad79",
};

export default PRUEFMETHODE;

export const PRUEFMETHODE_LABELS = {
  [PRUEFMETHODE.INTERN_ERGAENZT]: "durch Sachbearbeiter ergänzt",
  [PRUEFMETHODE.PAUSCHAL_ANERKANNT]: "pauschal anerkannt",
  [PRUEFMETHODE.EINZELN_GEPRUEFT]: "einzeln geprüft",
};
