import {
  computed,
  ref,
  toRef,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

export default function AnonymisierungPlanLoadingAPI(props) {
  const planKatalogKey = toRef(props, "planKatalogKey");
  const katalogList = ref([]);
  const {
    getHttp,
  } = HttpAPI();
  const loadingKatalog = () => {
    return getHttp({
      url: "katalog/",
      urlParams: {
        key: planKatalogKey.value,
      },
      apiSaveId: planKatalogKey.value,
    }).then(
      response => {
        katalogList.value = response;
      }
    );
  };

  const loading = ref(true);
  const reloading = ref(false);

  const baseUrl = toRef(props, "baseUrl");
  const anonymisierungsplanUrl = computed(() => {
    return `${ baseUrl.value }anonymisierungsplaene/`;
  });
  const object = toRef(props, "object");
  const loadedObjectPk = ref(undefined);
  const anonymisierungsplan = ref([]);
  const {
    getListHttp,
  } = HttpAPI();
  const loadAnonymisierungsplan = () => {
    return getListHttp({
      url: anonymisierungsplanUrl.value,
    }).then(
      response => {
        anonymisierungsplan.value = response;
        loadedObjectPk.value = object.value.pk;
      }
    );
  };

  const updateAnonymisierungsplan = newValue => {
    if (newValue.value.pk === loadedObjectPk.value) {
      return;
    }
    reloading.value = true;
    loadAnonymisierungsplan().then(
      () => reloading.value = false
    );
  };

  const loadAllData = () => {
    Promise.all([
      loadingKatalog(),
      loadAnonymisierungsplan(),
    ]).then(
      () => {
        loading.value = false;
      }
    );
  };

  return {
    anonymisierungsplan,
    anonymisierungsplanUrl,
    katalogList,
    loadAllData,
    loadedObjectPk,
    loading,
    loadAnonymisierungsplan,
    reloading,
    updateAnonymisierungsplan,
  };
}
