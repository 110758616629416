import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxPageDetails from "../../../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTemplateDownload from "../../../../../global/components/PuxTemplateDownload/PuxTemplateDownload.vue";
import Spaced from "../../../../../global/components/Spaced/Spaced.vue";

import HttpAPI from "../../../../../global/compositionAPI/HttpAPI";

import moment from "moment";
import {
  find,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "GesamtfinanzplanZFD",
  components: {
    FormElement,
    PuxPageDetails,
    PuxTemplateDownload,
    Spaced,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      getHttp,
    } = HttpAPI();

    return {
      getHttp,
    };
  },
  data() {
    return {
      filterModel: {
        haushaltsjahr: undefined,
        antragsteller: undefined,
      },
      templateDownloadData: [],
      templateOptions: {
        obj_url: "tableau",
        obj_id: this.report.pk
      },
      relevantePerioden: [],
      loading: true,
    };
  },
  computed: {
    optionList() {
      return [
        {
          id: "haushaltsjahr",
          label: "_LBL_BERICHT_GESAMTFINANZPLAN_ZFD_OPTION_HHJ_LABEL_",
          type: "select",
          keyId: "pk",
          keyLabel: "pe_kbez",
          view: "v",
          search: true,
          searchList: ["pe_kbez"],
          translate: true,
          loading: this.loading,
          data: this.relevantePerioden,
        },
        {
          id: "antragsteller",
          label: "_LBL_BERICHT_GESAMTFINANZPLAN_ZFD_OPTION_AST_LABEL_",
          type: "select",
          url: "antragstellerorganisationen/?show_dubletten=False&status=ac0b6e73-a481-424e-8d83-cb817be719e6",
          keyId: "pk",
          keyLabel: "ast_name",
          view: "v",
          search: true,
          searchList: ["ast_name"],
          deselect: true,
          translate: true,
        },
      ];
    },
  },
  created() {
    this.initPerioden();
    this.initTemplates();
  },
  methods: {
    initTemplates() {
      if (this.templateOptions && this.templateOptions.obj_url && this.templateOptions.obj_id) {
        this.templatesLoading = true;
        this.getHttp({
          url: `${ this.templateOptions.obj_url }/${ this.templateOptions.obj_id }/templates/`
        }).then(
          response => {
            const TEMPLATE_DATA = [];
            forEach(response, value => {
              TEMPLATE_DATA.push({ url: `${ this.templateOptions.obj_url }/${ this.templateOptions.obj_id }/render_template/?tpl=${ value.id }`,
                                   label: value.bez,
                                   title: `${ value.bez } herunterladen` });
            });
            this.templateDownloadData = TEMPLATE_DATA;
          }
        ).finally(
          () => this.templatesLoading = false
        );
      }
    },

    initPerioden() {
      this.loading = true;
      this.getHttp({ url: "katalog/?key=konkrete_perioden&typ=8eaec1da-6fe7-47fd-8b7f-ceebcee24913" }).then(
        response => {
          this.relevantePerioden = response;
        }
      ).finally(
        () => {
          const NOW = moment();
          const PERIODE = find(this.relevantePerioden, per => {
            return moment(per.pe_von) < NOW && NOW < moment(per.pe_bis);
          });
          this.filterModel.haushaltsjahr = PERIODE ? PERIODE.pk : undefined;
          this.loading = false;
        }
      );
    },
  },
};
