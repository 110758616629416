import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import FoerderfinderListDetailsMixin from "../FoerderfinderListDetails.mixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

// @vue/component
export default {
  name: "FoerderfinderListFoerderangebotDetails",
  components: {
    AngularLink,
    PuxButton,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    FoerderfinderListDetailsMixin,
    HttpMixin,
    SyConfigMixin,
  ],
  props: {
    className: {
      type: String,
      default: "secondary-details",
    },
    statusInFoerderprogramm: {
      type: Boolean,
    },
  },
  data() {
    return {
      status: {
        loading: true,
        hideZuschuss: undefined,
        monetaere_foerderung: undefined,
        kennzahlen_foerderfahigekosten: undefined,
        kennzahlen_zeitraum: undefined,
        kennzahlen_foerderhohe: undefined,
        kennzahlen_eigenmittel: undefined,
      },
    };
  },
  computed: {
    getFoerderHohe() {
      if (this.data.foerderhoehe_bez) {
        return this.data.foerderhoehe_bez;
      }
      return this.data.zuschuss && this.data.zuschuss[0] ? this.data.zuschuss[0].max_foerderhoehe : "";
    },
    getEigenmittel() {
      if (this.data.eigenmittel_bez) {
        return this.data.eigenmittel_bez;
      }
      return this.data.eigenmittelanteil;
    }
  },
  created() {
    this.checkSyConfig();
    this.initData();
  },
  methods: {
    checkSyConfig() {
      const tabellenkonfig = this.getSyConfigsValue("tabellenkonfig");
      if (tabellenkonfig) {
        this.status.hideZuschuss = tabellenkonfig.neuerantrag !== undefined &&
                                   tabellenkonfig.neuerantrag.hide &&
                                   tabellenkonfig.neuerantrag.hide.indexOf("zuschuss") >= 0;
      }
      this.status.monetaere_foerderung = this.getSyConfigsValue("monetaere_foerderung");
      this.foerderzeitraum_ausblenden = this.getSyConfigsValue("foerderzeitraum_ausblenden");
      const kennzahlen_werte = this.getSyConfigsValue("f_finder_kennzahlen");
      this.status.kennzahlen_foerderfahigekosten = kennzahlen_werte.f_finder_kennzahlen_foerderfahigekosten;
      this.status.kennzahlen_zeitraum = kennzahlen_werte.f_finder_kennzahlen_zeitraum;
      this.status.kennzahlen_foerderhohe = kennzahlen_werte.f_finder_kennzahlen_foerderhohe;
      this.status.kennzahlen_eigenmittel = kennzahlen_werte.f_finder_kennzahlen_eigenmittel;
    },

    initData() {
      this.status.loading = true;
      if (!this.data || !this.data.eigenmittelanteil) {
        this.loadAngebot();
      } else {
        this.status.loading = false;
      }
    },

    loadAngebot() {
      const param = `mit_login=${ !!this.statusUser }`;
      this.getHttp({ url: `ff/v3/foerderangebote/${ this.data.pk }/?${ param }` }).then(
        response => {
          this.replace(response);
          this.status.loading = false;
        }
      );
    },

  },
};
