import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import {
  find,
} from "lodash-es";

// @vue/component
export default {
  name: "AnkuendigungModalCreate",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
  ],
  props: {
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        nws_gueltig_ab: undefined,
        nws_gueltig_bis: undefined,
        nws_intern: false,
        nws_extern: false,
        rollen_ids: [],
        sichtbarkeit: []
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "nws_titel",
            type: "text",
            label: "_LBL_ANKUENDIGUNG_MODAL_CREATE_TITEL_",
            required: true,
            maxlength: 200,
            view: "v",
            translate: true,
          },
          {
            id: "nws_inhalt",
            type: "richtext",
            label: "_LBL_ANKUENDIGUNG_MODAL_CREATE_INHALT_",
            required: true,
            view: "v-alt",
            translate: true,
          },
          {
            id: "nws_gueltig_ab",
            type: "datetime",
            label: "_LBL_ANKUENDIGUNG_MODAL_CREATE_GUELTIG_AB_",
            required: true,
            view: "v-alt",
            translate: true,
          },
          {
            id: "nws_gueltig_bis",
            type: "datetime",
            label: "_LBL_ANKUENDIGUNG_MODAL_CREATE_GUELTIG_BIS_",
            required: false,
            view: "v-alt",
            translate: true,
          },
          {
            type: "multiselect",
            id: "rollen_ids",
            label: "_LBL_ANKUENDIGUNG_MODAL_CREATE_SICHTBARKEIT_SICHTBAR_FUR_",
            url: "rollen/",
            keyLabelCallback: ({ item }) => `${ item.bez } (${ item.kbez })`,
            keyId: "pk",
            search: true,
            searchList: ["bez", "kbez"],
            helpText: undefined,
            view: "v-alt",
            translate: true,
            hideIf: true,
            change: this.changeRollen
          },
          {
            id: "sichtbarkeit",
            type: "multiselect",
            label: "_LBL_ANKUENDIGUNG_MODAL_CREATE_SICHTBARKEIT_",
            required: true,
            data: [
              { id: "intern", label: "_LBL_ANKUENDIGUNG_MODAL_CREATE_SICHTBARKEIT_INTERN_" },
              { id: "extern", label: "_LBL_ANKUENDIGUNG_MODAL_CREATE_SICHTBARKEIT_EXTERN_" },
            ],
            dataTranslate: true,
            view: "v-alt",
            disabled: false,
            translate: true,
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  created() {
    this.checkRollePermission();
  },
  methods: {
    checkRollePermission() {
      if (this.checkPermissionsSync({ perm: "rollen.view" })) {
        find(this.options.list, ["id", "rollen_ids"]).hideIf = false;
      }
    },

    changeRollen() {
      if (this.model.rollen_ids.length > 0) {
        this.model.sichtbarkeit.splice(0);
        this.model.sichtbarkeit.push("intern");
        const el = this.options.list.find(el => el.id === "rollen_ids");
        el.helpText = "_LBL_ANKUENDIGUNG_MODAL_CREATE_SICHTBAR_FUR_HELP_TEXT_";
      }
      find(this.options.list, ["id", "sichtbarkeit"]).disabled = this.model.rollen_ids.length > 0;
    },

    save() {
      this.status.loading = true;
      this.model.nws_extern = !!this.model.sichtbarkeit.find(v => v === "extern");
      this.model.nws_intern = !!this.model.sichtbarkeit.find(v => v === "intern");
      this.postHttp({ url: `news/`, data: this.model }).then(
        response => {
          this.addNotification({
            text: "_MSG_ANKUENDIGUNG_MODAL_CREATE_SUCCESS_{{titel}}_",
            extra: { titel: this.model.nws_titel },
          });
          this.close({ response });
          this.$goTo("root.news.details", { id: response.pk });
        },
        error => {
          const errorTemp = error.data;
          const kommError = errorTemp.kommunikationsdaten;
          if (kommError && kommError[0] && kommError[0].nkm_wert) {
            errorTemp.kommunikationsdaten = kommError[0].nkm_wert;
          }
          this.errors = errorTemp;
          this.errorsClone = errorTemp;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
