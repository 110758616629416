import PositionInTd from "../PositionInTd/PositionInTd.vue";
import DurchfuehrungszeitraumLine from "../DurchfuehrungszeitraumLine/DurchfuehrungszeitraumLine.vue";
import DurchfuehrungszeitraumLineMixin from "../DurchfuehrungszeitraumLine/DurchfuehrungszeitraumLineMixin";

// @vue/component
export default {
  name: "PositionTd",
  components: {
    PositionInTd,
    DurchfuehrungszeitraumLine,
  },
  mixins: [
    DurchfuehrungszeitraumLineMixin,
  ],
  props: {
    period: {
      type: Object,
      required: true,
    },
    positionPeriodTeile: {
      type: Array,
      default: () => [],
    },
    positionFirst: {
      type: Boolean,
      required: true,
    },
    positionLast: {
      type: Boolean,
      required: true,
    },
    position: {
      type: Object,
      required: true,
    },
    durchfuehrung: {
      type: Object,
      required: true,
    },
  },
};
