"use strict";

import BaseModel from "dias/core/models/baseModel";

import Mitglieder from "./mitglieder.service";

class Teams extends BaseModel {
  constructor() {
    super("teams", {
      subPaths: {
        mitglieder: new Mitglieder()
      }
    });
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.getChoices = () => {
      const deferred = this.$q.defer();
      const data = [];
      collection.one("alle").customGET("").then(result => {
        angular.forEach(result, item => {
          data.push({ value: item.pk, label: item.t_kbez });
        });
        deferred.resolve(data);
      });
      return deferred.promise;
    };

    return collection;
  }
}

export default Teams;
