"use strict";

import DefaultWizardController from "dias/core/wizard/defaultWizard.controller.js";


class RSWechselWizardController extends DefaultWizardController {
  /*@ngInject*/
  constructor($stateParams, Foerderantrag, i18n, SyConfigs) {
    super();
    this.Foerderantrag = Foerderantrag;
    this.$stateParams = $stateParams;
    this.gettext = i18n.gettext;
    this.monetaere_foerderung = SyConfigs.wert("monetaere_foerderung");

    this.regelsaetze = [];

    this.RSWechselResult = {};
    this.RSWechselPreview = {};
    this.forwardStepDisabled = true;
    this.backStepDisabled = false;
    this.loadingRSListe = false;
    this.loadingZRS = false;
    this.loadingWechselPreview = false;
    this.loadingRSWechselResult = false;


    this.loadRegelsaetze = () => {
      this.loadingRSListe = true;
      this.Foerderantrag.one(this.$stateParams.id).getList("rswechsel_regelsaetze").then(result => {
        this.regelsaetze = result;
      }).finally(() => this.loadingRSListe = false);
    };

    this.onChangeZielregelsatz = () => {
      if (this.zielregelsatz) {
        this.loadingWechselPreview = true;
        this.Foerderantrag.one(this.$stateParams.id).customGET("rswechsel_vorschau", { zielregelsatz: this.zielregelsatz }).then(result => {
          this.RSWechselPreview = result;
          this.forwardStepDisabled = false;
        }).finally(() => this.loadingWechselPreview = false);
      } else {
        this.forwardStepDisabled = true;
      }
    };

    this.performRSWechsel = () => {
      this.loadingRSWechselResult = true;
      this.backTargetStepDisabled = true;
      this.backStepDisabled = true;
      this.Foerderantrag.one(this.$stateParams.id).customPOST({ zielregelsatz: this.zielregelsatz }, "rswechsel_durchfuehren").then(result => {
        this.RSWechselResult = result;
        this.zielregelsatz = null;
        if (this.RSWechselResult.status !== 1) {
          this.backTargetStepDisabled = false;
          this.backStepDisabled = false;
        }
      }).finally(() => this.loadingRSWechselResult = false);
    };

    this.loadRegelsaetze();
  }

  forwardStep() {
    if (this.activeStep === 1) {
      this.performRSWechsel();
    }
    super.forwardStep();
  }
}

export default RSWechselWizardController;
