import AsideRightBox from "../../../global/components/AsideRightBox/AsideRightBox.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import StatusGruppenIcon from "./StatusGruppenIcon/StatusGruppenIcon.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";

import {
  cloneDeep,
  isEmpty,
  isUndefined,
  forEach,
  orderBy,
} from "lodash-es";

const WGT_ERFOLG = "6acf334c-d5fd-411e-9dff-536949f7bebf";
const WGT_MISSERFOLG = "9da607d6-940f-4913-a1e5-8f7d9a7c4c97";

// @vue/component
export default {
  name: "WFStatusGruppen",
  components: {
    AsideRightBox,
    PuxCloak,
    PuxTranslate,
    StatusGruppenIcon,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    relevantObj: {
      type: Object,
      required: true,
    },
    relevantObjName: {
      type: [String, Number],
      required: true,
    },
    relevantObjLabel: {
      type: String,
      default: "",
    },
    kontextName: {
      type: String,
      default: undefined,
    },
    wfKontext: {
      type: Object,
      default: () => ({}),
    },
    foeangebot: {
      type: Object,
      default: () => ({}),
    },
    additionalText: {
      type: String,
      default: "",
    },
    statusInHeader: {
      type: Boolean,
      required: false,
      info: "Anzeigen der Komponente im Header",
    },
    classInHeader: {
      type: String,
      required: false,
      default: "",
      info: "CSS-Klasse, wenn die Komponente im Header steht"
    },
  },
  data() {
    return {
      options: {
        active: true,
        classBody: "p-0",
        hideHeader: true,
      },
      workflowStatusGruppenDefault: [],
      workflowStatusGruppen: [],
      status: {
        loading: undefined,
        componentDisable: undefined,
      },
      kontextNameInData: undefined,
    };
  },
  computed: {
    statusShowFoeangebot() {
      return !isEmpty(this.foeangebot) && this.foeangebot.ars_kbez;
    },

    classLocal() {
      return {
        st_gr_success: this.relevantObj.workflowstatusgruppe.workflowstatusgruppentyp === WGT_ERFOLG,
        st_gr_warning: this.relevantObj.workflowstatusgruppe.workflowstatusgruppentyp === WGT_MISSERFOLG,
      };
    },

    orderedWorkflowStatusGruppen() {
      return orderBy(this.workflowStatusGruppen, ["wsg_pos"]);
    },

    kontextNameLocal() {
      return this.kontextName || this.kontextNameInData;
    },

    angebotTranslateOptions() {
      return {
        text: "_TXT_WF_STATUSGRUPPEN_ANGEBOT_VERSION_{{kbez}}_{{version}}_",
        extra: {
          kbez: this.foeangebot.ars_kbez,
          version: this.foeangebot.ars_version,
        },
      };
    },
  },
  watch: {
    "relevantObj.workflowstatusgruppe.pk"() {
      this.prepareGruppen();
    }
  },
  created() {
    this.initStatusGruppen();
  },
  methods: {
    initStatusGruppen() {
      this.status.loading = true;
      if (this.relevantObj && this.relevantObj.workflow) {
        this.getWorkflowtypUUIDUndKontextName();
      } else {
        this.status.ComponentDisable = true;
        this.status.loading = false;
      }
    },

    getWorkflowtypUUIDUndKontextName() {
      if (!isEmpty(this.wfKontext)) {
        this.getWorkflowstatusGruppen({ workflowtypUUID: this.wfKontext.pk });
        this.updateKontextName(this.wfKontext.wft_kbez);
      } else {
        this.getHttp({
          url: "katalog/",
          urlParams: {
            key: "workflowtyp",
            pk: this.relevantObj.workflowstatusgruppe.workflowtyp
          },
        }).then(
          response => {
            this.updateKontextName(response[0].wft_kbez);
            this.getWorkflowstatusGruppen({ workflowtypUUID: response[0].pk });
          }
        );
      }
    },

    getWorkflowstatusGruppen({ workflowtypUUID }) {
      this.getListHttp({
        url: `workflowstatusgruppen/`,
        urlParams: {
          workflowtyp: workflowtypUUID
        }
      }).then(
        response => {
          this.workflowStatusGruppenDefault = response;
          this.prepareGruppen();
        },
      ).then(
        () => this.status.loading = false
      );
    },

    prepareGruppen() {
      if (this.relevantObj && this.relevantObj.workflowstatusgruppe) {
        this.filterWorkflowstatusgruppen();
      }
      this.status.loading = false;
    },

    filterWorkflowstatusgruppen() {
      const WF_STATUSGRUPPEN = [];
      forEach(cloneDeep(this.workflowStatusGruppenDefault), gruppe => {
        if (gruppe.workflowstatusgruppentyp === this.relevantObj.workflowstatusgruppe.workflowstatusgruppentyp) {
          if (gruppe.workflowstatusgruppentyp === WGT_ERFOLG) {
            gruppe.icon = "glyphicon-ok";
          } else if (gruppe.workflowstatusgruppentyp === WGT_MISSERFOLG) {
            gruppe.icon = "glyphicon-remove";
          } else {
            gruppe.wizard_pos = gruppe.wsg_pos;
          }
          WF_STATUSGRUPPEN.push(gruppe);
        }
      });
      if (WF_STATUSGRUPPEN.length === 1) {
        WF_STATUSGRUPPEN.unshift({
          wsg_pos: -1,
          onlyLine: true,
        });
        WF_STATUSGRUPPEN.push({
          wsg_pos: 100,
          onlyLine: true,
        });
      }
      this.workflowStatusGruppen = WF_STATUSGRUPPEN;
    },

    updateKontextName(name) {
      if (isUndefined(this.kontextName)) {
        this.kontextNameInData = this.$gettext(name);
      }
    },
  },
};
