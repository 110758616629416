var jade = require("/var/lib/jenkins/workspace/rel_build_mdm/frontend/client/node_modules/jade/lib/runtime.js");

module.exports = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;


























jade_mixins["readonly-block"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div ng-if=\"vm.isReadonly() || !vm.globalBearbeiten\" ng-show=\"!vm.editMode\" ng-class=\"{'clearfix-hover' : vm.hoverPosition}\" class=\"clearfix box_hover\">");
block && block();
buf.push("</div><div ng-if=\"!vm.isReadonly() &amp;&amp; vm.globalBearbeiten\" ng-show=\"!vm.editMode\" ng-click=\"!vm.isReadonly() &amp;&amp; vm.globalBearbeiten ? vm.toggleEditMode() : null\" data-toggle=\"{{!vm.isReadonly() &amp;&amp; vm.globalBearbeiten ? 'edit' : ''}}\" ng-class=\"{ 'editable' : !vm.isReadonly() &amp;&amp; vm.globalBearbeiten }\" tabindex=\"-1\" class=\"clearfix box_hover\">");
block && block();
buf.push("</div>");
};

























































jade_mixins["position-without-details"] = jade_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div class=\"position\"><div><span ng-class=\"vm.snapshotIcons[p.pk]\" ng-if=\"vm.snapshot &amp;&amp; vm.isChanges() &amp;&amp; vm.snapshotIcons[p.pk]\" class=\"glyphicon version-color\"></span><span>{{vm.idx}}</span></div><div>{{vm.antragKoFiArt.bez}}<button ng-click=\"vm.toggleStatusInfo()\" ng-class=\"{ 'active': vm.statusInfo }\" type=\"button\" ng-attr-title=\"Zusatzinformation bezüglich {{vm.idx}} {{vm.antragKoFiArt.bez}} {{vm.statusInfo ? 'ausblenden': 'einblenden'}}\" ng-attr-aria-expanded=\"{{vm.statusInfo}}\" ng-if=\"vm.modul.regel.afr_astbez\" class=\"btn btn-icon toggle-info\"><span class=\"glyphicon glyphicon-info-sign\"></span></button></div><div ng-attr-title=\"{{vm.antragKoFiArt.gekappt?'Der maximale Betrag für diesen Kostenpunkt wurde erreicht.':''}}\"><span ng-if=\"vm.antragKoFiArt.gekappt\" class=\"glyphicon glyphicon-ok text-success\">&nbsp;</span><span>{{vm.getSumme() | currency}}</span></div><div class=\"version\">{{vm.searchInSnapshot(vm.antragKoFiArt.artPk,  vm.antragKoFiArt.diffKey) | currency}}</div><div class=\"procent\"></div><div><!-- placeholder--></div></div><div ng-show=\"vm.modul.regel.afr_kbez || vm.modul.regel.afr_bezeichnung\" class=\"rules\"><h5 ng-bind-html=\"vm.modul.regel.afr_kbez\"></h5><div ng-bind-html=\"vm.modul.regel.afr_bezeichnung\"></div></div><div ng-show=\"vm.statusInfo\" role=\"alert\" class=\"info\"><button ng-attr-title=\"Zusatzinformation bezüglich {{vm.idx}} {{vm.antragKoFiArt.bez}} {{vm.statusInfo ? 'ausblenden': 'einblenden'}}\" ng-click=\"vm.toggleStatusInfo()\" class=\"btn btn-icon btn-absolute\"><i class=\"glyphicon glyphicon-remove\"></i></button><div ng-bind-html=\"vm.modul.regel.afr_astbez\"></div></div>");
};








jade_mixins["vorschau-daten"] = jade_interp = function(vorschau){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<div" + (jade.attr("ng-repeat", "(key, val) in " + (vorschau) + "", true, true)) + "><span ng-if=\"val\">{{key}}: {{ val }}</span></div>");
};






jade_mixins["readonly-block"].call({
block: function(){
jade_mixins["position-without-details"]();
}
});;return buf.join("");
}