import Alert from "../../../../global/components/Alert/Alert.vue";
import FormElement from "../../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import PuxErrors from "../../../../global/components/PuxErrors/PuxErrors.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

import {
  EventBus,
} from "../../../../global/functions/event-bus";
import {
  createNamespacedHelpers,
} from "vuex";
import {
  cloneDeep,
  endsWith,
  forEach,
  size,
} from "lodash-es";

const {
  mapMutations,
  mapGetters,
} = createNamespacedHelpers("notizen");

// @vue/component
export default {
  name: "NotizCreateOrEditModal",
  components: {
    Alert,
    FormElement,
    PuxButton,
    PuxErrors,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    keyNotiz: {
      type: String,
      required: false,
      default: undefined,
    },
    notiz: {
      type: Object,
      required: false,
      default: undefined,
    },
    fuerSitzung: {
      type: Boolean,
      required: true,
    },
    fuerAntragstellerorg: {
      type: Boolean,
      required: false,
      default: false,
    },
    fuerFoerderorg: {
      type: Boolean,
      required: false,
      default: false,
    },
    notizenObjektid: {
      type: String,
      required: false,
      default: undefined,
    },
    notizenObjektContentType: {
      type: Number,
      required: false,
      default: undefined,
    },
    selectorClose: {
      type: String,
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      required: true,
    },
    mainObjektid: { // Für Notizen in Dokumente
      type: String,
      required: false,
      default: undefined,
    },
    mainContentType: { // Für Notizen in Dokumente
      type: Number,
      required: false,
      default: undefined,
    },
    rubrics: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      statusLoading: false,
      statusInfo: true,
      model: {
        no_text: null,
        no_fuer_sitzung: false,
        foerderorg: null,
        antragstellerorg_sichtbar: false,
        privat: false,
        weitere_objekte: [],
        keyNotiz: undefined,
        antragstellerorg: null
      },
      errors: {},
    };
  },
  computed: {
    optionsModal() {
      return {
        header: this.notiz ?
          "_TXT_NOTIZEN_MODAL_UPDATE_HEADER_" :
          "_TXT_NOTIZEN_MODAL_CREATE_HEADER_",
        showCloseButton: true,
        required: true,
      };
    },

    htmlId() {
      return `${ this.keyNotiz }${ this.notiz ? this.notiz.pk : "new" }`;
    },

    optionsText() {
      return {
        type: "richtext",
        id: "no_text",
        htmlId: `${ this.htmlId }_no_text`,
        label: "_LBL_NOTIZEN_MODAL_CREATE_OR_UPDATE_TEXT_",
        required: true,
        view: "v-alt",
      };
    },

    optionsPrivat() {
      return {
        type: "boolean",
        id: "privat",
        htmlId: `${ this.htmlId }_privat`,
        label: "_LBL_NOTIZEN_MODAL_CREATE_OR_UPDATE_SICHTBARKEIT_",
        trueLabel: "_LBL_NOTIZEN_MODAL_CREATE_OR_UPDATE_PRIVAT_TRUE_",
        falseLabel: "_LBL_NOTIZEN_MODAL_CREATE_OR_UPDATE_PRIVAT_FALSE_",
        change: this.changePrivat,
        view: "v-alt",
      };
    },

    optionsFoerderorg() {
      return {
        type: "select",
        id: "foerderorg",
        htmlId: `${ this.htmlId }_foerderorg`,
        label: "_LBL_NOTIZEN_MODAL_CREATE_OR_UPDATE_FOERDERORG_",
        keyLabel: "name",
        keyId: "pk",
        view: "v",
        data: this.GET_NOTIZEN_OPTIONS.orgList,
        deselect: true,
        translate: true,
        search: true,
        searchList: ["name"],
      };
    },

    optionsAstorg() {
      return {
        type: "boolean",
        id: "antragstellerorg_sichtbar",
        htmlId: `${ this.htmlId }_antragstellerorg_sichtbar`,
        label: "_LBL_NOTIZEN_MODAL_ASTORG_SICHTBARKEIT_",
        trueLabel: "_LBL_NOTIZEN_MODAL_ASTORG_SICHTBAR_TRUE_",
        falseLabel: "_LBL_NOTIZEN_MODAL_ASTORG_SICHTBAR_FALSE_",
        view: "v-alt",
        change: this.changeAntragstellerorg,
      };
    },

    optionsPrivateOrVisible() {
      return {
        type: "boolean",
        id: "privat",
        htmlId: `${ this.htmlId }_privat`,
        label: "_LBL_NOTIZEN_MODAL_CREATE_OR_UPDATE_SICHTBARKEIT_",
        trueLabel: "_LBL_NOTIZEN_MODAL_CREATE_OR_UPDATE_PRIVAT_OR_VISIBLE_TRUE_",
        falseLabel: "_LBL_NOTIZEN_MODAL_CREATE_OR_UPDATE_PRIVAT_OR_VISIBLE_FALSE_",
        change: this.changePrivatAndAntragstellerorg,
        view: "v-alt",
      };
    },

    optionsFuerSitzung() {
      return {
        type: "boolean",
        id: "no_fuer_sitzung",
        htmlId: `${ this.htmlId }_no_fuer_sitzung`,
        label: "_LBL_NOTIZEN_MODAL_CREATE_OR_UPDATE_FUER_SITZUNG_",
        view: "v-alt",
      };
    },

    optionsRubrics() {
      return {
        type: "select",
        id: "keyNotiz",
        htmlId: `${ this.htmlId }_keyNotiz`,
        label: "_LBL_NOTIZEN_MODAL_CREATE_OR_UPDATE_PANELS_",
        keyId: "key",
        view: "v",
        data: this.rubricsWithVerlauf,
        deselect: false,
        translate: true,
        search: true,
        searchList: ["label", "kbez"],
        slot: "rubrics",
      };
    },

    rubricsWithVerlauf() {
      if (!this.isRubrics) {
        return [];
      }
      const RUBRICS = cloneDeep(this.rubrics);
      RUBRICS.unshift({
        label: "_TXT_NOTIZEN_ALLGEMEIN_",
        key: "null",
      });
      if (this.hasPermissionTimelineUiNotizen) {
        RUBRICS.push({
          label: "_TXT_NOTIZEN_VERLAUF_",
          key: "verlauf",
        });
      }
      return RUBRICS;
    },

    hasPermissionTimelineUiNotizen() {
      return this.checkPermissionsSync({
        perm: "timeline.ui.notizen",
      });
    },

    isRubrics() {
      return this.rubrics && this.rubrics.length;
    },

    noOrgToSelect() {
      return !(this.fuerFoerderorg || this.fuerAntragstellerorg);
    },

    optionsListForPuxErrors() {
      return [
        this.optionsText,
        this.optionsFoerderorg,
        this.optionsPrivat,
        this.optionsAstorg,
        this.optionsPrivateOrVisible,
        this.optionsFuerSitzung,
        this.optionsRubrics,
      ];
    },

    ...mapGetters([
      "GET_NOTIZEN_OPTIONS",
    ]),
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.notiz) {
        this.model = {
          no_text: this.notiz.no_text,
          no_fuer_sitzung: this.notiz.no_fuer_sitzung,
          privat: this.notiz.privat,
          antragstellerorg_sichtbar: !!(this.notiz.antragstellerorg),
          antragstellerorg: this.notiz.antragstellerorg ? this.notiz.antragstellerorg.pk || null : null,
          foerderorg: this.notiz.foerderorg ? this.notiz.foerderorg.pk || null : null,
          weitere_objekte: this.setModelWeitereObjekte(),
          content_type: this.notiz.content_type,
          no_objektid: this.notiz.no_objektid,
        };
      } else {
        this.model.antragstellerorg_sichtbar = false;
        if (this.GET_NOTIZEN_OPTIONS.orgList && this.GET_NOTIZEN_OPTIONS.orgList.length) {
          this.model.foerderorg = this.GET_NOTIZEN_OPTIONS.orgList[0].pk;
        }
        if (!this.fuerFoerderorg) {
          this.model.antragstellerorg = this.GET_NOTIZEN_OPTIONS.antragstellerorg;
        }
        if (this.notizenObjektid && this.notizenObjektContentType) {
          this.model.weitere_objekte.push({
            noo_objektid: this.notizenObjektid,
            content_type: this.notizenObjektContentType,
          });
        }
        if (this.isRubrics) {
          this.model.keyNotiz = "null";
        }
        if (this.noOrgToSelect) {
          this.model.private = true;
        }
      }
      if (size(this.GET_NOTIZEN_OPTIONS.weitereObjekte) > 0) {
        this.model.weitere_objekte.push(...cloneDeep(this.GET_NOTIZEN_OPTIONS.weitereObjekte));
      }
    },

    setModelWeitereObjekte() {
      const WEITERE_OBJEKTE = [];
      forEach(this.notiz.weitere_objekte || [], item => {
        WEITERE_OBJEKTE.push({
          noo_objektid: item.noo_objektid,
          content_type: item.content_type,
        });
      });
      return WEITERE_OBJEKTE;
    },

    changePrivat() {
      this.model.antragstellerorg_sichtbar = false;
      this.model.antragstellerorg = null;
      this.model.foerderorg = null;
    },

    changeAntragstellerorg() {
      if (this.model.antragstellerorg_sichtbar) {
        this.model.antragstellerorg = this.GET_NOTIZEN_OPTIONS.antragstellerorg;
      } else {
        this.model.antragstellerorg = null;
      }
    },

    changePrivatAndAntragstellerorg() {
      if (this.model.privat) {
        this.model.antragstellerorg = null;
      } else {
        this.model.antragstellerorg = this.GET_NOTIZEN_OPTIONS.antragstellerorg;
      }
    },

    addExtraKey(key) {
      if (size(this.GET_NOTIZEN_OPTIONS.extraKey) > 0 && !endsWith(key, this.GET_NOTIZEN_OPTIONS.extraKey)) {
        return `${ key }__${ this.GET_NOTIZEN_OPTIONS.extraKey }`;
      }
      return key;
    },

    save() {
      if (this.statusLoading) {
        return;
      }
      this.statusLoading = true;
      const DATA = {
        content_type: this.model.content_type || this.mainContentType || this.GET_NOTIZEN_OPTIONS.content_type,
        no_objektid: this.model.no_objektid || this.mainObjektid || this.GET_NOTIZEN_OPTIONS.no_objektid,
        no_fuer_sitzung: this.model.no_fuer_sitzung,
        no_key: this.addExtraKey(this.keyNotiz || this.model.keyNotiz),
        no_text: this.model.no_text,
        privat: this.model.privat,
        foerderorg: this.model.foerderorg,
        antragstellerorg: this.model.antragstellerorg,
        weitere_objekte: this.model.weitere_objekte,
        dok_no_objektid: this.mainObjektid ? this.GET_NOTIZEN_OPTIONS.no_objektid : undefined,
        dok_content_type: this.mainContentType ? this.GET_NOTIZEN_OPTIONS.content_type : undefined,
      };
      if (this.notiz) {
        this.update({ data: DATA });
        return;
      }
      this.create({ data: DATA });
    },

    update({ data }) {
      this.putHttp({
        url: `notizen/${ this.notiz.pk }/`,
        data,
      }).then(
        response => {
          this.UPDATE_NOTIZ({ notiz: response });
          EventBus.$emit("updateNotizen", { no_key: data.no_key });
          this.closeEditMode(true);
        },
        error => {
          this.errors = error.data;
        }
      ).then(
        () => {
          this.statusLoading = false;
        }
      );
    },

    create({ data }) {
      this.postHttp({
        url: "notizen/",
        data,
      }).then(
        response => {
          this.CREATE_NOTIZ({ notiz: response });
          EventBus.$emit("updateNotizen", { no_key: data.no_key });
          this.closeEditMode(true);
        },
        error => {
          this.errors = error.data;
        }
      ).then(
        () => {
          this.statusLoading = false;
        }
      );
    },

    closeEditMode(isSaved) {
      this.close(isSaved);
    },

    onDismissInfo() {
      this.statusInfo = false;
    },

    ...mapMutations([
      "CREATE_NOTIZ",
      "UPDATE_NOTIZ",
    ]),
  },
};
