import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import {
  FilterCurrencyMixin,
} from "../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "KFPeriodenPosition",
  components: {
    SnapshotIcon,
  },
  mixins: [
    FilterCurrencyMixin,
  ],
  props: {
    perioden: {
      type: Array,
      required: true,
      info: "Perioden-Liste",
    },
    position: {
      type: Object,
      required: true,
      info: "Position-Objekt",
    },
    periodKeyShow: {
      type: String,
      required: true,
      info: "Welche Perioden-Teil werden angezeigt",
    },
    idx: {
      type: String,
      required: true,
      info: "Nummer von KoFi-Art",
    },
    typeKoFi: {
      type: String,
      required: true,
      validator: value => ["kosten", "finanzierung"].indexOf(value) !== -1,
      info: "Kosten oder Finanzierung",
    },
    positionWertForPerioden: {
      type: Object,
      required: true,
      info: "Geld für Position mit Perioden",
    },
    statusKFDiff: {
      type: Boolean,
      required: false,
      info: "StatusDiff from parent",
    },
    statusSnapshotDelete: {
      type: Boolean,
      required: false,
      info: "Wenn die Position ist gelöscht",
    },
    statusSnapshotAdd: {
      type: Boolean,
      required: false,
      info: "Wenn die Position ist erstellt",
    },
    snapshotChanges: {
      type: Object,
      required: false,
      default: undefined,
      info: "Diff-Änderungen",
    },
    snapshotPosition: {
      type: Object,
      required: false,
      default: undefined,
      info: "Snapshot-Position-Objekt",
    },
  },
  computed: {
    prefixKoFi() {
      if (this.typeKoFi === "kosten") {
        return "akp";
      }
      if (this.typeKoFi === "finanzierung") {
        return "afp";
      }
      return "";
    },

    posNrKey() {
      return `${ this.prefixKoFi }_pos`;
    },

    posBezeichnungKey() {
      return `${ this.prefixKoFi }_bez`;
    },

    posBezeichnung() {
      if (this.typeKoFi === "kosten") {
        return (this.position.kostenartdetail_kbez ?
            this.position.kostenartdetail_kbez + ": " :
            "") + this.position[this.posBezeichnungKey];
      }
      if (this.typeKoFi === "finanzierung") {
        return this.position[this.posBezeichnungKey];
      }
      return "";
    },

    posNr() {
      return this.position[this.posNrKey];
    },

    snapshotIconRowType() {
      if (this.statusSnapshotDelete) {
        return "delete";
      }
      if (this.statusSnapshotAdd) {
        return "add";
      }
      if (this.snapshotChanges) {
        return "changes";
      }
      return "";
    },

    wertZero() {
      return this.filterCurrency(0);
    },
  },
};
