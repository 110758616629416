import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";

import DashboardModuleMixin from "../DashboardModuleMixin";
import SyConfigMixin from "../../../../global/mixins/SyConfigMixin";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

// @vue/component
export default {
  name: "DashboardSitzungInfo",
  components: {
    AngularLink,
  },
  mixins: [
    DashboardModuleMixin,
    SyConfigMixin,
  ],
  setup() {
    const {
      filterCurrency,
      filterDate,
    } = FiltersAPI();

    return {
      filterCurrency,
      filterDate,
    };
  },
  data() {
    return {
      monetaere_foerderung: undefined,
    };
  },
  computed: {
    classBox() {
      if (this.data.sitzungsart_obj && this.data.sitzungsart_obj.sia_cssklasse) {
        return this.data.sitzungsart_obj.sia_cssklasse;
      }
      return "green";
    },
  },
  created() {
    this.setSyConfig();
  },
  methods: {
    setSyConfig() {
      this.monetaere_foerderung = this.getSyConfigsValue("monetaere_foerderung");
    },
  },
};
