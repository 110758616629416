"use strict";

import "restangular";
import angular from "angular";
import CoreModels from "dias/core/models";

import EmailAendern from "./email-aendern.service";
import Login from "./login.service";
import Logout from "./logout.service";
import PasswortNeu from "./passwort-neu.service";
import PasswortZuruecksetzen from "./passwort-zuruecksetzen.service";
import Registrieren from "./registrieren.service";
import RegistrierungAbschliessen from "./registrierung-abschliessen.service";
import Registrierungsfragen from "./registrierungsfragen";

const modelsModule = angular.module("dias.registration.models", [
  "restangular",
  CoreModels.name
])

  .provider("EmailAendern", EmailAendern)
  .provider("Login", Login)
  .provider("Logout", Logout)
  .provider("PasswortNeu", PasswortNeu)
  .provider("PasswortZuruecksetzen", PasswortZuruecksetzen)
  .provider("Registrieren", Registrieren)
  .provider("RegistrierungAbschliessen", RegistrierungAbschliessen)
  .provider("Registrierungsfragen", Registrierungsfragen)
;

export default modelsModule;
