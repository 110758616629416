"use strict";

import BaseController from "../config.base.controller";

class ParametermoduleController extends BaseController {
  /*@ngInject*/
  constructor() {
    super();
  }
}

export default ParametermoduleController;
