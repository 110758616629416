import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "AngebotRegelsatzVorlage",
  components: {
    PuxTranslate,
  },
  props: {
    angebot: {
      type: String,
      default: "",
    },
    version: {
      type: Number,
      default: 1,
    },
    kbez: {
      type: String,
      default: "",
    },
  },
  computed: {
    extraTranslate() {
      return {
        angebot: this.angebot,
        version: this.version,
        kbez: this.kbez,
      };
    },
  },
};
