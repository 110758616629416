import PositionTr from "./PositionTr/PositionTr.vue";
import PersonTr from "./PersonTr/PersonTr.vue";
import BelegeTr from "./BelegeTr/BelegeTr.vue";
import Spaced from "../../../../global/components/Spaced/Spaced.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import FooterTd from "./FooterTd/FooterTd.vue";
import HttpMixin from "../../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../../global/mixins/NotificationMixin";
import translate from "../../../../global/directives/translate";
import DokartUUIDs from "../../../../const/DokartUUIDs";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";
import moment from "moment";
import {
  FilterCurrencyMixin,
  FilterDateMixin,
} from "../../../../global/mixins/FiltersMixin";
import {
  reduce,
  forEach,
  cloneDeep,
  groupBy,
  sortBy,
  findIndex,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "PersonalkostenPerioden",
  components: {
    PositionTr,
    PersonTr,
    BelegeTr,
    Spaced,
    PuxCloak,
    FooterTd,
  },
  directives: {
    translate,
  },
  mixins: [
    FilterCurrencyMixin,
    FilterDateMixin,
    HttpMixin,
    NotificationMixin,
    PermissionMixin,
  ],
  props: {
    positionen: {
      type: Array,
      required: true,
    },
    vertrag: {
      type: Object,
      required: true,
    },
    canCreateBeleg: {
      type: Boolean,
      required: true,
    },
    canUpdateBeleg: {
      type: Boolean,
      required: true,
    },
    canDeleteBeleg: {
      type: Boolean,
      required: true,
    },
    canAblehnenBeleg: {
      type: Boolean,
      required: true,
    },
    canAnerkennenBeleg: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      periodenColsWidth: {},
      loading: true,
      loadingBelege: undefined,
      loadingDocumentsForBelege: undefined,
      periodenAll: [],
      positionenPeriodenObj: {},
      personenObj: {},
      periodeTeilForPeriode: {},
      periodeTeilForPosition: {},
      positionenAllGehalt: {},
      allGehalt: 0,

      vertragkosten: undefined,
      belegeForPositionForPersonForPeriod: {},
      documentsForBelege: {},
      durchfuehrung: {},
    };
  },
  created() {
    this.setDurchfuehrung();
    this.loadDaten();
  },
  methods: {
    setDurchfuehrung() {
      this.durchfuehrung = {
        antrag: {
          von: this.vertrag.antrag_durchfuehrung_von,
          bis: this.vertrag.antrag_durchfuehrung_bis,
        },
        vertrag: {
          von: this.vertrag.foerdervertragsbestaetigung.von,
          bis: this.vertrag.foerdervertragsbestaetigung.bis,
        },
      };
    },

    loadDaten() {
      const ARRAY_PROMISE = [
        this.getListHttp({
          url: `vertraege/${ this.vertrag.pk }/vertragspositionperioden/`,
        }),
      ];

      if (this.checkPermissionsSync({ perm: "vertragskostenpersonalstellenperson.view", permArray: this.vertrag.user_permissions })) {
        ARRAY_PROMISE.push(
          this.getListHttp({
            url: `vertraege/${ this.vertrag.pk }/vertragspersonalstellenpersonen/?ordering=crdate`,
          }),
        );
      }

      Promise.all(ARRAY_PROMISE).then(
        responses => {
          if (responses[1]) {
            this.personenObj = groupBy(responses[1], "personalstelle");
          }
          this.loadAllPeriode({ vertragspositionperioden: responses[0] });
        }
      );
    },

    loadBelege() {
      if (!this.vertragkosten) {
        return;
      }
      this.loadingBelege = true;
      Promise.all(this.positionen.map(
        position => this.getListHttp({
          url: `vertraege/${ this.vertrag.pk }/kosten/${ this.vertragkosten }/positionen/${ position.position }/belege/`,
        })
      )).then(
        responses => {
          this.initBelegeForPositionForPersonForPeriod({ responses });
          this.loadingBelege = false;
        }
      );
    },

    loadDocuments() {
      this.loadingDocumentsForBelege = true;
      this.getHttp({
        url: `foerderantraege/${ this.vertrag.antrag }/dokumente/?dokart=${ DokartUUIDs.kosten }`,
        keyId: "pk",
      }).then(
        response => {
          this.documentsForBelege = response;
          this.loadingDocumentsForBelege = false;
        }
      );
    },

    loadAllPeriode({ vertragspositionperioden }) {
      const VON_MIN = this.durchfuehrung.antrag.von < this.durchfuehrung.vertrag.von ?
        this.durchfuehrung.antrag.von : this.durchfuehrung.vertrag.von;
      const BIS_MAX = this.durchfuehrung.antrag.bis > this.durchfuehrung.vertrag.bis ?
        this.durchfuehrung.antrag.von : this.durchfuehrung.vertrag.bis;
      const PERIOD_TYPE_PK = vertragspositionperioden[0].periode_obj.typ;
      this.getListHttp({
        url: `kperiodentypen/${ PERIOD_TYPE_PK }/get_perioden_in_zeitraum/?content_type=${ this.vertrag.content_type }&objekt_id=${ this.vertrag.pk }`,
        urlParams: {
          von: VON_MIN,
          bis: BIS_MAX,
        },
      }).then(
        response => {
          this.periodenAll = sortBy(response, ["pe_von"]);
          this.setPeriodenAndAllGehalt({ response: vertragspositionperioden });
          this.loadBelege();
          this.loadDocuments();
          this.loading = false;
        }
      );
    },

    initBelegeForPositionForPersonForPeriod({ responses }) {
      const BELEG_FOR_POSITION_FOR_PERSON = {};
      forEach(responses, (belege, belegeIndex) => {
        const POSITION_PK = this.positionen[belegeIndex].position;
        BELEG_FOR_POSITION_FOR_PERSON[POSITION_PK] = this.setBelegeForOnePosition({ belege });
      });
      this.belegeForPositionForPersonForPeriod = BELEG_FOR_POSITION_FOR_PERSON;
    },

    setBelegeForOnePosition({ belege }) {
      const OBJ_BELEGE = {};
      forEach(belege, beleg => {
        const PERSON_PK = beleg.argument_erfasst.personalstellenperson;
        const PERIOD_TEIL_PK = beleg.argument_erfasst.posperiode;
        const PERIOD_PK = this.periodeTeilForPeriode[PERIOD_TEIL_PK];

        if (!OBJ_BELEGE[PERSON_PK]) {
          OBJ_BELEGE[PERSON_PK] = {};
        }
        if (!OBJ_BELEGE[PERSON_PK][PERIOD_PK]) {
          OBJ_BELEGE[PERSON_PK][PERIOD_PK] = [];
        }
        OBJ_BELEGE[PERSON_PK][PERIOD_PK].push(beleg);
      });
      return OBJ_BELEGE;
    },

    updateBelegeForPosition({ position, beleg, statusCreate }) {
      if (statusCreate && beleg) {
        this.addBelegForPosition({ position, beleg });
      }
    },

    addBelegForPosition({ beleg }) {
      const BELEGE_FOR_POSITION_FOR_PERSON = cloneDeep(this.belegeForPositionForPersonForPeriod);
      const POSITION_PK = beleg.vertragskostenposition;
      const PERSON_PK = beleg.argument_erfasst.personalstellenperson;
      const PERIOD_TEIL_PK = beleg.argument_erfasst.posperiode;
      const PERIOD_PK = this.periodeTeilForPeriode[PERIOD_TEIL_PK];
      if (!BELEGE_FOR_POSITION_FOR_PERSON[POSITION_PK]) {
        BELEGE_FOR_POSITION_FOR_PERSON[POSITION_PK] = {};
      }
      if (!BELEGE_FOR_POSITION_FOR_PERSON[POSITION_PK][PERSON_PK]) {
        BELEGE_FOR_POSITION_FOR_PERSON[POSITION_PK][PERSON_PK] = {};
      }
      if (!BELEGE_FOR_POSITION_FOR_PERSON[POSITION_PK][PERSON_PK][PERIOD_PK]) {
        BELEGE_FOR_POSITION_FOR_PERSON[POSITION_PK][PERSON_PK][PERIOD_PK] = [];
      }
      BELEGE_FOR_POSITION_FOR_PERSON[POSITION_PK][PERSON_PK][PERIOD_PK].push(beleg);
      this.belegeForPositionForPersonForPeriod = BELEGE_FOR_POSITION_FOR_PERSON;
    },

    deleteBelegForPosition({ beleg }) {
      const BELEGE_FOR_POSITION_FOR_PERSON = cloneDeep(this.belegeForPositionForPersonForPeriod);
      const POSITION_PK = beleg.vertragskostenposition;
      const PERSON_PK = beleg.argument_erfasst.personalstellenperson;
      const PERIOD_TEIL_PK = beleg.argument_erfasst.posperiode;
      const PERIOD_PK = this.periodeTeilForPeriode[PERIOD_TEIL_PK];
      const BELEG_INDEX = findIndex(BELEGE_FOR_POSITION_FOR_PERSON[POSITION_PK][PERSON_PK][PERIOD_PK], ["pk", beleg.pk]);
      BELEGE_FOR_POSITION_FOR_PERSON[POSITION_PK][PERSON_PK][PERIOD_PK].splice(BELEG_INDEX, 1);
      if (!BELEGE_FOR_POSITION_FOR_PERSON[POSITION_PK][PERSON_PK][PERIOD_PK].length) {
        delete BELEGE_FOR_POSITION_FOR_PERSON[POSITION_PK][PERSON_PK][PERIOD_PK];
      }
      if (!size(BELEGE_FOR_POSITION_FOR_PERSON[POSITION_PK][PERSON_PK])) {
        delete BELEGE_FOR_POSITION_FOR_PERSON[POSITION_PK][PERSON_PK];
      }
      this.belegeForPositionForPersonForPeriod = BELEGE_FOR_POSITION_FOR_PERSON;
    },

    updateBelegForPosition({ beleg }) {
      const BELEGE_FOR_POSITION_FOR_PERSON = cloneDeep(this.belegeForPositionForPersonForPeriod);
      const POSITION_PK = beleg.vertragskostenposition;
      const PERSON_PK = beleg.argument_erfasst.personalstellenperson;
      const PERIOD_TEIL_PK = beleg.argument_erfasst.posperiode;
      const PERIOD_PK = this.periodeTeilForPeriode[PERIOD_TEIL_PK];
      const BELEG_INDEX = findIndex(BELEGE_FOR_POSITION_FOR_PERSON[POSITION_PK][PERSON_PK][PERIOD_PK], ["pk", beleg.pk]);
      BELEGE_FOR_POSITION_FOR_PERSON[POSITION_PK][PERSON_PK][PERIOD_PK].splice(BELEG_INDEX, 1, beleg);
      this.belegeForPositionForPersonForPeriod = BELEGE_FOR_POSITION_FOR_PERSON;
    },

    setPeriodenAndAllGehalt({ response }) {
      const PERIODEN_OBJ = {};
      const PERIODE_TEIL_FOR_PERIODE = {};
      const PERIODE_TEIL_FOR_POSITION = {};
      const POSITIONEN_PERIODEN_OBJ = {};
      const POSITIONEN_ALL_GEHALT = {};
      forEach(response, item => {
        PERIODE_TEIL_FOR_PERIODE[item.pk] = item.periode;

        if (!PERIODEN_OBJ[item.periode]) {
          PERIODEN_OBJ[item.periode] = true;
          const PERIODE_OBJ = cloneDeep(item.periode_obj);
          PERIODE_OBJ.vertragskosten = item.vertragskosten;
        }
        this.vertragkosten = item.vertragskosten;

        if (!POSITIONEN_PERIODEN_OBJ[item.position]) {
          POSITIONEN_PERIODEN_OBJ[item.position] = {};
        }
        if (!POSITIONEN_PERIODEN_OBJ[item.position][item.periode]) {
          POSITIONEN_PERIODEN_OBJ[item.position][item.periode] = [];
        }
        POSITIONEN_PERIODEN_OBJ[item.position][item.periode].push(item);

        if (!POSITIONEN_ALL_GEHALT[item.position]) {
          POSITIONEN_ALL_GEHALT[item.position] = 0;
        }
        POSITIONEN_ALL_GEHALT[item.position] += +item.gehalt;
        this.allGehalt += +item.gehalt;

        if (!PERIODE_TEIL_FOR_POSITION[item.position]) {
          PERIODE_TEIL_FOR_POSITION[item.position] = [];
        }
        PERIODE_TEIL_FOR_POSITION[item.position].push(cloneDeep(item));
      });
      this.positionenPeriodenObj = POSITIONEN_PERIODEN_OBJ;
      this.positionenAllGehalt = POSITIONEN_ALL_GEHALT;
      this.periodeTeilForPeriode = PERIODE_TEIL_FOR_PERIODE;
      this.periodeTeilForPosition = PERIODE_TEIL_FOR_POSITION;
    },

    addPerson({ person }) {
      const PERSONEN_OBJ = cloneDeep(this.personenObj);
      if (!PERSONEN_OBJ[person.personalstelle]) {
        PERSONEN_OBJ[person.personalstelle] = [];
      }
      PERSONEN_OBJ[person.personalstelle].push(person);
      this.personenObj = PERSONEN_OBJ;
    },

    updatePerson({ person, personIndex }) {
      const PERSONEN_OBJ = cloneDeep(this.personenObj);
      if (!PERSONEN_OBJ[person.personalstelle]) {
        PERSONEN_OBJ[person.personalstelle] = [];
      }
      PERSONEN_OBJ[person.personalstelle].splice(personIndex, 1, person);
      this.personenObj = PERSONEN_OBJ;
    },

    deletePerson({ person, personIndex }) {
      const PERSONEN_OBJ = cloneDeep(this.personenObj);
      PERSONEN_OBJ[person.personalstelle].splice(personIndex, 1);
      this.personenObj = PERSONEN_OBJ;
    },

    initColsWidth() {
      const PERIODEN_DAYS = [];
      const COUNT_ALL_DAYS = reduce(this.perioden, (sum, period) => {
        const DAYS_DIFF = moment(period.bis_max).diff(moment(period.von_min), "days");
        PERIODEN_DAYS.push(DAYS_DIFF);
        return sum + DAYS_DIFF;
      }, 0);
      const PERIODEN_COLS_WIDTH = {};
      forEach(this.perioden, (period, periodIndex) => {
        const WIDTH = `width: ${ PERIODEN_DAYS[periodIndex] * 100 / COUNT_ALL_DAYS }%`;
        PERIODEN_COLS_WIDTH[period.pk] = WIDTH;
      });

      this.periodenColsWidth = PERIODEN_COLS_WIDTH;
    },

    updateDokumentForBelege({ dokument }) {
      const DOCUMENTS_FOR_BELEGE = cloneDeep(this.documentsForBelege);
      DOCUMENTS_FOR_BELEGE[dokument.pk] = cloneDeep(dokument);
      this.documentsForBelege = DOCUMENTS_FOR_BELEGE;
    },
  },
};
