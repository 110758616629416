"use strict";

import template from "./formularliste.box.template.jade";


class FormularlisteController {
  /*@ngInject*/
  constructor(i18n, Formular, AuthService, $state, Restangular) {
    this.$state = $state;
    this.gettext = i18n.gettext;
    this.AuthService = AuthService;
    this.Formular = Formular;
    this.Restangular = Restangular;
    this.formulare = [];
    this.loadFormulare();
  }

  loadFormulare() {
    this.Restangular.one("genericformulare").one(`${ this.objekt.content_type }`).one("object").one(this.objekt.pk).customGETLIST("formulare").then(
      response => {
        this.formulare = response;
      }
    );
  }

  getLink(formular) {
    return this.$state.href("root." + formular.cortex + ".detail", { id: formular.pk });
  }

  canViewFormular(formular) {
    if (!formular.permissionset) {
      return true;
    }
    return formular.user_permissions.indexOf(formular.permissionset + ".view") >= 0;
  }
}

export default {
  template: template(),
  controller: FormularlisteController,
  controllerAs: "vm",
  bindings: {
    objekt: "<"
  }
};
