import WizardStep1 from "./WizardSteps/WizardStep1/WizardStep1/WizardStep1.vue";
import WizardStep1DHW from "./WizardSteps/WizardStep1/WizardStep1DHW/WizardStep1DHW.vue";
import WizardStep2 from "./WizardSteps/WizardStep2/WizardStep2/WizardStep2.vue";
import WizardStep2DHW from "./WizardSteps/WizardStep2/WizardStep2DHW/WizardStep2DHW.vue";
import WizardStep3 from "./WizardSteps/WizardStep3/WizardStep3/WizardStep3.vue";
import WizardStep3DHW from "./WizardSteps/WizardStep3/WizardStep3DHW/WizardStep3DHW.vue";
import WizardStep4 from "./WizardSteps/WizardStep4/WizardStep4/WizardStep4.vue";
import WizardStep4DHW from "./WizardSteps/WizardStep4/WizardStep4DHW/WizardStep4DHW.vue";
import WizardStepsANBruttoMixin from "./WizardSteps/WizardStepsANBruttoMixin";

// @vue/component
export default {
  components: {
    WizardStep1,
    WizardStep1DHW,
    WizardStep2,
    WizardStep2DHW,
    WizardStep3,
    WizardStep3DHW,
    WizardStep4,
    WizardStep4DHW,
  },
  mixins: [
    WizardStepsANBruttoMixin,
  ],
  data() {
    return {
      wizardSteps: [
        {
          label: "_TXT_ANTRAG_DETAILS_PERSONAL_WIZARD_STEP1_HEADER_",
          slotId: "step1",
          title: "_TXT_ANTRAG_DETAILS_PERSONAL_WIZARD_STEP1_HEADER_TITLE_",
        },
        {
          label: "_TXT_ANTRAG_DETAILS_PERSONAL_WIZARD_STEP2_HEADER_",
          slotId: "step2",
          title: "_TXT_ANTRAG_DETAILS_PERSONAL_WIZARD_STEP2_HEADER_TITLE_",
        },
        {
          label: "_TXT_ANTRAG_DETAILS_PERSONAL_WIZARD_STEP3_HEADER_",
          slotId: "step3",
          title: "_TXT_ANTRAG_DETAILS_PERSONAL_WIZARD_STEP3_HEADER_TITLE_",
        },
        {
          label: "_TXT_ANTRAG_DETAILS_PERSONAL_WIZARD_STEP4_HEADER_",
          slotId: "step4",
          title: "_TXT_ANTRAG_DETAILS_PERSONAL_WIZARD_STEP4_HEADER_TITLE_",
        },
      ],
      slotsBetragsrechnerMapping: {
        step1: {
          personalkosten_perioden: {
            component: "WizardStep1",
          },
          dhw_personalkosten_perioden: {
            component: "WizardStep1DHW",
          },
        },
        step2: {
          personalkosten_perioden: {
            component: "WizardStep2",
          },
          dhw_personalkosten_perioden: {
            component: "WizardStep2DHW",
          },
        },
        step3: {
          personalkosten_perioden: {
            component: "WizardStep3",
          },
          dhw_personalkosten_perioden: {
            component: "WizardStep3DHW",
          },
        },
        step4: {
          personalkosten_perioden: {
            component: "WizardStep4",
          },
          dhw_personalkosten_perioden: {
            component: "WizardStep4DHW",
          },
        },
      },
    };
  },
  computed: {
    getStepComponent() {
      return ({ slot, betragsrechner }) => {
        return this.slotsBetragsrechnerMapping[slot][betragsrechner].component;
      };
    },
  },
};
