import {
  computed,
  getCurrentInstance,
} from "vue";
import {
  useStore,
} from "vuex";

import FormElement from "../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../global/components/PuxButton/PuxButton.vue";
import Permission from "../../global/components/Permission/Permission.vue";

import {
  EventBus,
} from "../../global/functions/event-bus";

// @vue/component
export default {
  name: "TheSearchGlobal",
  components: {
    FormElement,
    PuxButton,
    Permission,
  },
  setup() {
    const store = useStore();
    const model = computed(() => {
      return store.getters["searchGlobal/MODEL"];
    });

    const options = {
      type: "text",
      view: "v",
      label: "_BTN_SEARCH_GLOBAL_",
      id: "the_search_global",
      nullValue: "",
    };

    const changeModel = ({ model }) => {
      store.commit("searchGlobal/MUT_CHANGE_MODEL", model);
      EventBus.$emit("onChangeSearchGlobalModel", model);
    };

    const APP = getCurrentInstance();
    const $stateIs = APP.appContext.config.globalProperties.$stateIs;
    const $goTo = APP.appContext.config.globalProperties.$goTo;

    const onSearch = () => {
      if ($stateIs && !$stateIs("root.suche")) {
        $goTo("root.suche");
      }
      EventBus.$emit("onSearchGlobal");
    };

    return {
      changeModel,
      model,
      onSearch,
      options,
    };
  },
};
