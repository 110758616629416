"use strict";

import angular from "angular";

class SitzungsmodulPreviewController {
  /*@ngInject*/
  constructor($element, $scope, $compile, SitzungsmodulRegistry) {
    this.SitzungsmodulRegistry = SitzungsmodulRegistry;
    this.$compile = $compile;
    this.$element = $element;
    this.$scope = $scope;
    this.loading = true;
    this.readonly = this.readonly !== undefined ? this.readonly : false;
    $scope.$watch("vm.module", () => {
      if (!angular.isUndefined(this.module)) {
        this.create();
      } else {
        console.warn("Sitzungsmodule konnten nicht gerendert werden", this.module, this.top);
      }
    });
  }

  create() {
    let template = "";
    if (angular.isArray(this.module)) {
      if (this.module.length === 0) {
        return;
      }
      for (let i = 0; i < this.module.length; i++) {
        const temp = this.buildTemplate(this.module[i], "vm.module[" + i + "]", "vm.top");
        if (angular.isString(temp)) {
          template += temp;
        }
      }
    } else {
      template = this.buildTemplate(this.module, "vm.module", "vm.top");
    }
    if (!template) {
      return;
    }
    const el = this.$compile(template)(this.$scope);
    this.$element.replaceWith(el);
    this.$element = angular.element(el);
  }

  buildTemplate(obj, modulPropertyName, topPropertyName) {
    let errMsg;
    if (obj === undefined || obj.modulname === undefined) {
      errMsg = "Angegebene Eigenschaft " + modulPropertyName + " am Scope hat keinen Modulnamen.";
      console.error(errMsg, obj);
      return;
    }
    const modul = this.SitzungsmodulRegistry.getPreview(obj.modulname);
    if (angular.isUndefined(modul)) {
      return;
    }
    return "<" + modul + " modul='" + modulPropertyName + "' top='" + topPropertyName + "'></" + modul + ">";
  }
}

export default {
  bindings: {
    module: "=",
    top: "=",
  },
  template: "",
  controller: SitzungsmodulPreviewController,
  controllerAs: "vm",
};
