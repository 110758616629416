import AngularLink from "../../../../../global/components/AngularLink/AngularLink.vue";
import PuxIcon from "../../../../../global/components/PuxIcon/PuxIcon.vue";

import PermissionAPI from "../../../../../global/compositionAPI/PermissionAPI";

// @vue/component
export default {
  name: "AufgabenvorlagenDetailsBerechtigungenTableRow",
  components: {
    AngularLink,
    PuxIcon,
  },
  props: {
    funktionsgruppenKey: {
      type: String,
      required: true,
    },
    funktionsgruppenMap: {
      type: Object,
      required: true,
    },
    berechtigungen: {
      type: Object,
      required: true,
    },
    beobachterRolle: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup() {
    const {
      checkPermissionsSync,
    } = PermissionAPI();

    return {
      checkPermissionsSync,
    };
  },
  computed: {
    hasPermissionFunktionsgruppenUi() {
      return this.checkPermissionsSync({
        perm: "funktionsgruppen.ui",
      });
    },

    beobachterRollePk() {
      return this.beobachterRolle.pk;
    },
  },
};
