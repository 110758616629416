"use strict";

import BaseModel from "dias/core/models/baseModel";


class FoerderangebotInfo extends BaseModel {
  constructor() {
    super("/info/foerderangebote/");
  }
}

export default FoerderangebotInfo;
