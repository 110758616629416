import ModuleMixin from "../ModuleMixin";
import ElementlisteElement from "./ElementlisteElement/ElementlisteElement.vue";
import PuxButton from "../../../../global/components/PuxButton/PuxButton.vue";
import Modal from "../../../../global/components/Modal/Modal.vue";
import loading from "../../../../global/directives/loading";
import {
  cloneDeep,
  findIndex,
  forEach,
  get,
  isEmpty,
  keyBy,
  size,
  values,
} from "lodash-es";
import {
  EventBus,
} from "../../../../global/functions/event-bus";
import { createNamespacedHelpers } from "vuex";
const {
  mapState,
} = createNamespacedHelpers("snapshot");

// @vue/component
export default {
  name: "Elementliste",
  components: {
    ElementlisteElement,
    PuxButton,
    Modal,
  },
  directives: {
    loading,
  },
  mixins: [
    ModuleMixin,
  ],
  data() {
    return {
      statusLoadingButton: false,
      confirmShow: undefined,
      confirmOptions: undefined,
      unterregelnObj: {},
      timerSignalChildren: undefined,
      elementNewIndex: {},
    };
  },
  computed: {
    labelHeader() {
      return get(this.modelParameter, "frage_text") || this.modelParameter.element_bezeichnung_plural;
    },

    elemente() {
      return this.model.elemente || [];
    },

    extraForTranslate() {
      return {
        element_bezeichnung: this.modelParameter.element_bezeichnung,
      };
    },

    editable() {
      return !this.statusReadonly;
    },

    snapshotModuleElementeObj() {
      if (this.statusSnapshot &&
        this.snapshotModule &&
        this.snapshotModule.elemente &&
        this.snapshotModule.elemente.length) {
        return keyBy(this.snapshotModule.elemente, "pk");
      }
      return {};
    },

    snapshotFiltered() {
      const SNAPSHOT = {
        add: {},
        delete: [],
        changes: {},
      };
      if (this.statusSnapshot) {
        const SNAPSHOT_MODULE_ELEMENTE_OBJ = cloneDeep(this.snapshotModuleElementeObj);
        forEach(get(this.snapshotModule, "elemente", []), (el, idx) => el.index = idx);
        forEach(this.elemente, item => {
          if (!SNAPSHOT_MODULE_ELEMENTE_OBJ[item.pk]) {
            SNAPSHOT.add[item.pk] = true;
          } else {
            SNAPSHOT.changes[item.pk] = SNAPSHOT_MODULE_ELEMENTE_OBJ[item.pk];
            delete SNAPSHOT_MODULE_ELEMENTE_OBJ[item.pk];
          }
          if (!SNAPSHOT_MODULE_ELEMENTE_OBJ[item.label]) {
            SNAPSHOT.add[item.label] = true;
          } else {
            SNAPSHOT.changes[item.label] = SNAPSHOT_MODULE_ELEMENTE_OBJ[item.label];
          }
        });
        SNAPSHOT.delete = values(SNAPSHOT_MODULE_ELEMENTE_OBJ);
      }
      return SNAPSHOT;
    },

    hasModulPermissionCreate() {
      if (this.readonlyLocal || this.readonly) {
        return false;
      }
      return get(this.regel, "rechte.create", this.hasBaseEditPerm);
    },

    hasModulPermissionDelete() {
      if (this.readonlyLocal || this.readonly) {
        return false;
      }
      return get(this.regel, "rechte.delete", this.hasBaseEditPerm);
    },

    statusShowAddButton() {
      if (this.modelParameter.max_entries) {
        return this.modelLength < this.modelParameter.max_entries && this.hasModulPermissionCreate;
      }
      return this.hasModulPermissionCreate;
    },

    modelLength() {
      return this.elemente.length;
    },

    elementRegelnForSave() {
      const ELEMENT_REGELN = {};
      forEach(this.modelParameter.element_regeln, regelPk => {
        ELEMENT_REGELN[regelPk] = {};
      });
      return ELEMENT_REGELN;
    },

    statusDiff() {
      return !!(this.snapshotFiltered.delete.length || !isEmpty(this.snapshotFiltered.add) || this.statusSnapshotChange);
    },

    statusSnapshotChange() {
      let statusChange = false;
      forEach(this.elemente, (element, elementIndex) => {
        forEach(this.modelParameter.element_regeln, item => {
          if (this.STATUS_DIFF_FOR_REGELS[`${ item }_${ elementIndex }`]) {
            statusChange = true;
            return false;
          }
        });
        if (statusChange) {
          return false;
        }
      });
      return statusChange;
    },

    ...mapState([
      "STATUS_DIFF_FOR_REGELS",
    ]),
  },
  beforeUnmount() {
    this.registerOrDeactiveSignalReceiverForChildren({ eventBusAction: "$off" });
  },
  methods: {
    initDataLocal({ statusFirstLoad }) {
      if (statusFirstLoad) {
        this.unterregelnObj = keyBy(this.regel.unterregeln, "regel");
        this.registerOrDeactiveSignalReceiverForChildren({ eventBusAction: "$on" });
      }
    },

    registerOrDeactiveSignalReceiverForChildren({ eventBusAction }) {
      forEach(this.regel.unterregeln, unterregel => {
        forEach(unterregel.signal_empfang, empfang => {
          if (empfang.signal_kennung && empfang.sender_reid) {
            const SIGNAL_NAME = `${ empfang.signal_kennung }_${ empfang.sender_reid }`;
            EventBus[eventBusAction](SIGNAL_NAME, arg => this.onReceiveSignalForChildren(empfang.signal_kennung, arg));
          }
        });
      });
    },

    onReceiveSignalForChildren(signal_kennung, arg = {}) {
      const REGEL = arg.regel && arg.regel.regel;
      if (REGEL && this.unterregelnObj[REGEL]) { // Wenn Signal aus child von elementliste kommt
        return;
      }
      clearTimeout(this.timerSignalChildren);
      this.timerSignalChildren = setTimeout(() => { // Workaround, wenn 2 oder mehrere Signale gleichzeitig kommen
        this.reloadModule();
      }, 100);
    },

    addElement() {
      this.statusLoadingButton = true;
      const DATA = this.perepareDataForSave();
      this.save({ model: DATA }).then(
        () => {
          this.addNotification({
            text: `_MSG_GR_ELEMENTLISTE_ADD_SUCCESS_{{element_bezeichnung}}_`,
            extra: {
              element_bezeichnung: this.modelParameter.element_bezeichnung,
            },
          });
          if (DATA.elemente.length) {
            this.elementNewIndex[DATA.elemente.length - 1] = false;
          }
        },
        err => {
          const ERROR = get(err, "data.non_field_errors[0]", `_MSG_GR_ELEMENTLISTE_ADD_ERROR_{{element_bezeichnung}}_`);
          this.addNotification({
            text: ERROR,
            type: "error",
            extra: {
              element_bezeichnung: this.modelParameter.element_bezeichnung,
            },
          });
        }
      ).finally(() => this.statusLoadingButton = false);
    },

    perepareDataForSave({ data, elementIndex, regel } = {}) {
      const DATA = cloneDeep(this.model) || {};
      DATA.elemente = DATA.elemente || [];
      if (regel) {
        const ELEMENT = cloneDeep(DATA.elemente[elementIndex]) || {};
        ELEMENT.validierung_erforderlich = true;
        ELEMENT.partial_update = [regel];
        ELEMENT.element_regeln = {
          [regel]: data
        };
        DATA.elemente.splice(elementIndex, 1, ELEMENT);
      } else {
        DATA.elemente.push({
          partial_update: [],
          validierung_erforderlich: true,
          // element_regeln: this.elementRegelnForSave,
        });
      }
      return DATA;
    },

    openConfirmDeleteElement({ elementIndex, elementHeaderLabel }) {
      this.confirmOptions = {
        okClass: "btn btn-primary",
        title: "_TXT_GR_ELEMENTLISTE_MODAL_DELETE_HEADER_{{element_label}}_",
        msg: "_TXT_GR_ELEMENTLISTE_MODAL_DELETE_BODY_{{element_label}}_",
        okLabel: "_BTN_DELETE_",
        okCallback: () => this.deleteElement({ elementIndex, elementHeaderLabel }),
        cancelCallback: this.closeConfirm,
        loading: false,
        extra: {
          element_label: elementHeaderLabel,
        },
      };
      this.confirmShow = true;
    },

    deleteElement({ elementIndex, elementHeaderLabel }) {
      this.confirmOptions.loading = true;
      const ELEMENTE = cloneDeep(this.elemente);
      ELEMENTE.splice(elementIndex, 1);
      forEach(ELEMENTE, element => {
        element.validierung_erforderlich = true;
      });

      const DATA = {
        elemente: ELEMENTE,
      };

      return this.save({
        model: DATA,
      }).then(
        () => {
          this.confirmShow = false;
          this.addNotification({
            text: `_MSG_GR_ELEMENTLISTE_DELETE_SUCCESS_{{element_label}}_`,
            extra: {
              element_label: elementHeaderLabel,
            },
          });
        },
        err => {
          const ERROR = get(err, "data.non_field_errors[0]", `_MSG_GR_ELEMENTLISTE_DELETE_ERROR_{{element_label}}_`);
          this.addNotification({
            text: ERROR,
            type: "error",
            extra: {
              element_label: elementHeaderLabel,
            },
          });
        }
      ).finally(
        () => this.confirmOptions.loading = false
      );
    },

    closeConfirm() {
      this.confirmShow = false;
    },

    updateElement({ data, elementIndex, regel }) {
      const DATA = this.perepareDataForSave({ data, elementIndex, regel });
      return this.save({
        model: DATA,
        childObj: {
          childIndex: elementIndex,
          childRegel: regel,
        },
      });
    },

    onError({ errors, childObj = {} } = {}) {
      const {
        childIndex,
        childRegel,
      } = childObj;
      const ERRORS = cloneDeep(errors);
      const CURRENT_DATA = get(ERRORS, `data.elemente[${ childIndex }].element_regeln.${ childRegel }`, ERRORS.data);
      ERRORS.data = CURRENT_DATA;
      return ERRORS;
    },

    updateModuleLocal({ response, extra, group }) {
      const RESPONSE = cloneDeep(response);
      const MODULE = cloneDeep(this.module);
      MODULE.data = RESPONSE.data;
      this.updateModule({ response: MODULE, extra, group });
    },

    updateModuleElement() {

    },

    showErrorsInModule({ errors }) {
      if (this.$refs.formstepDetail) {
        const MODULES_INDEPENDENT_ERRORS = get(errors, "__all__");
        if (MODULES_INDEPENDENT_ERRORS) {
          this.$refs.formstepDetail.setErrors({
            errors: {
              __all__: MODULES_INDEPENDENT_ERRORS,
            },
          });
        } else {
          this.$refs.formstepDetail.setErrors({
            errors: undefined,
          });
        }
      }
      this.showErrorsInChildrenModule({ errors });
    },

    showErrorsInChildrenModule({ errors }) {
      const ERRORS_ELEMENTE = errors.elemente || [];
      forEach(ERRORS_ELEMENTE, elementErrors => {
        const EL_IDX = findIndex(get(this.$refs, "element", []), el => elementErrors.pk === get(el, "model.pk"));
        this.openChildElement({ elementIndex: EL_IDX });
        const ELEMENT_REGELN = elementErrors.element_regeln || {};
        forEach(ELEMENT_REGELN, (regelErrors, regelId) => {
          const REGEL_ID_FOR_CHILD = `${ this.regelIdWithParentsRegelId }_${ regelId }_${ EL_IDX }`;
          EventBus.$emit("showErrorsInModule", {
            regelId: REGEL_ID_FOR_CHILD,
            errors: regelErrors });
        });
      });
    },

    openChildElement({ elementIndex }) {
      if (elementIndex !== -1 && size(this.$refs.element) > elementIndex) {
        this.$refs.element[elementIndex].openElement();
      }
    },
  },
};
