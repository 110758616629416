"use strict";

import {
  filter,
  forEach,
  isFunction,
} from "lodash-es";
import {
  MAPPING
} from "../../../vue/client/vue/components/Geschaeftsregel/Module/Module";

class FoerderantragBaseWizardController {
  /*@ngInject*/
  constructor(
    $q,
    Foerdermodule,
    FoerdermoduleRegistry,
    FoerderangebotAnzeigekontextUUIDs,
    AuthService,
    i18n,
    $timeout,
  ) {
    this.MAPPING = MAPPING;
    this.$timeout = $timeout;
    this.name = "FoerderantragBaseWizardController";
    this.UEBERSICHT = "step_ubersicht";
    this.KENNZAHLEN = "step_kennzahlen";
    this.FOERDERANGEBOT = "step_angebot";
    this.ORGANISATION = "step_orga";
    this.KOSTEN_FINANZIERUNG = "step_finanz";
    this.VORHABEN = "step_vorhaben";
    this.ANLAGEN = "step_anlagen";
    this.BESCHEIDE = "step_bescheide";
    this.SITZUNGSANLAGEN = "step_sitzungsanlagen";
    this.SACHBEARBEITER_DOKUMENTE = "step_sach_dok";
    this.PRUEFUNG = "step_pruefung";
    this.PROJEKTE = "step_projekte";
    this.BUENDNISPARTNER = "step_buendnispartner";
    this.ERKLAERUNGEN = "step_erklaerungen";
    this.STELLUNGNAHME = "step_stellungnahme";
    this.AUFLAGEN = "step_auflagen";
    this.PRESSE = "presse";
    this.ERKLAERUNGEN_ZUM_EINREICHEN = "step_erklaerungen_zum_einreichen";

    this.GESCHAEFTSREGEL_MODUL = "einfache_foerderregel";

    this.AuthService = AuthService;
    this.gettext = i18n.gettext;
    this.$q = $q;
    this.Foerdermodule = Foerdermodule;
    this.FoerdermoduleRegistry = FoerdermoduleRegistry;
    this.stepMap = {};
    this.notizen = {};
    this.stepMap[this.UEBERSICHT] = {
      bez: this.gettext("Übersicht ansehen"),
      kbez: this.gettext("Zusammenfassung"),
      key: this.UEBERSICHT,
      display_number: false,
      priority: 5,
      data: { errors: null },
      hideDiff: true,
      moduleContexts: [],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.UEBERSICHT, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.updateImmerVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    this.stepMap.step_rubrik_0 = {
      bez: this.gettext("Rubrik 0"),
      kbez: this.gettext("Rubrik 0"),
      display_number: true,
      key: "step_rubrik_0",
      priority: 7,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.rubrik_0],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp("step_rubrik_0", ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    this.stepMap[this.KENNZAHLEN] = {
      bez: this.gettext("Kennzahlen"),
      kbez: this.gettext("Kennzahlen"),
      key: this.KENNZAHLEN,
      display_number: false,
      priority: 9,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.kennzahlen],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.KENNZAHLEN, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    this.stepMap[this.FOERDERANGEBOT] = {
      bez: `${ this.gettext("_LBL_ANTRAG_RUBRIK_FOERDERANGEBOT_") } auswählen`,
      kbez: this.gettext("_LBL_ANTRAG_RUBRIK_FOERDERANGEBOT_"),
      key: this.FOERDERANGEBOT,
      display_number: true,
      priority: 10,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.foerderangebot],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.FOERDERANGEBOT, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.updateImmerVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    this.stepMap[this.ORGANISATION] = {
      bez: this.gettext("_LBL_ANTRAG_RUBRIK_ORGANISATION_BEZEICHNUNG_"),
      kbez: this.gettext("_LBL_ANTRAG_RUBRIK_ORGANISATION_KURZ_BEZEICHNUNG_"),
      key: this.ORGANISATION,
      display_number: true,
      priority: 20,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.antragsteller],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.ORGANISATION, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    this.stepMap[this.BUENDNISPARTNER] = {
      bez: this.gettext("Bündnispartner"),
      kbez: this.gettext("Bündnispartner"),
      key: this.BUENDNISPARTNER,
      display_number: true,
      priority: 21,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.buendnispartner],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.BUENDNISPARTNER, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    this.stepMap[this.PROJEKTE] = {
      bez: this.gettext("Projekte"),
      kbez: this.gettext("Projekte"),
      display_number: true,
      key: this.PROJEKTE,
      priority: 22,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.projekte],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.PROJEKTE, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    for (let i = 1; i < 11; i++) {
      this.stepMap["step_rubrik_" + i] = {
        bez: this.gettext("Rubrik " + i),
        kbez: this.gettext("Rubrik " + i),
        display_number: true,
        key: "step_rubrik_" + i,
        priority: i > 4 ? 24 + i : 22 + i, // 27&28 für 4_1 und 4_2
        data: { errors: null },
        moduleContexts: [FoerderangebotAnzeigekontextUUIDs["rubrik_" + i]],
        template: (...args) => this.defaultTemplate(...args),
        setUp: (...args) => this.defaultSetUp("step_rubrik_" + i, ...args),
        saveCallback: (...args) => this.defaultSave(...args),
        disabled: (...args) => this.defaultDisabled(...args),
        updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
        updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
        done: (...args) => this.defaultDone(...args),
        updateVisible: (...args) => this.defaultVisible(...args),
        updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
        updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
      };
      this.stepMap["step_rubrik_10_" + i] = {
        bez: this.gettext("Rubrik 10-" + i),
        kbez: this.gettext("Rubrik 10-" + i),
        display_number: true,
        key: "step_rubrik_10_" + i,
        priority: 34 + i,
        data: { errors: null },
        moduleContexts: [FoerderangebotAnzeigekontextUUIDs["rubrik_10_" + i]],
        template: (...args) => this.defaultTemplate(...args),
        setUp: (...args) => this.defaultSetUp("step_rubrik_10_" + i, ...args),
        saveCallback: (...args) => this.defaultSave(...args),
        disabled: (...args) => this.defaultDisabled(...args),
        updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
        updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
        done: (...args) => this.defaultDone(...args),
        updateVisible: (...args) => this.defaultVisible(...args),
        updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
        updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
      };
    }

    for (let i = 1; i < 3; i++) {
      this.stepMap["step_rubrik_4_" + i] = {
        bez: this.gettext("Rubrik 4-" + i),
        kbez: this.gettext("Rubrik 4-" + i),
        display_number: true,
        key: "step_rubrik_4_" + i,
        priority: 26 + i,
        data: { errors: null },
        moduleContexts: [FoerderangebotAnzeigekontextUUIDs["rubrik_4_" + i]],
        template: (...args) => this.defaultTemplate(...args),
        setUp: (...args) => this.defaultSetUp("step_rubrik_4_" + i, ...args),
        saveCallback: (...args) => this.defaultSave(...args),
        disabled: (...args) => this.defaultDisabled(...args),
        updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
        updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
        done: (...args) => this.defaultDone(...args),
        updateVisible: (...args) => this.defaultVisible(...args),
        updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
        updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
      };
    }

    this.stepMap[this.KOSTEN_FINANZIERUNG] = {
      bez: `Angaben zu ${ this.gettext("Geplante Kosten & Finanzierung") } bearbeiten`,
      kbez: this.gettext("Geplante Kosten & Finanzierung"),
      key: this.KOSTEN_FINANZIERUNG,
      display_number: true,
      priority: 46,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.kosten,
                       FoerderangebotAnzeigekontextUUIDs.finanzierung],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.KOSTEN_FINANZIERUNG, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    for (let i = 11; i < 21; i++) {
      this.stepMap["step_rubrik_" + i] = {
        bez: this.gettext("Rubrik " + i),
        kbez: this.gettext("Rubrik " + i),
        display_number: true,
        key: "step_rubrik_" + i,
        priority: 47 + (i - 10),
        data: { errors: null },
        moduleContexts: [FoerderangebotAnzeigekontextUUIDs["rubrik_" + i]],
        template: (...args) => this.defaultTemplate(...args),
        setUp: (...args) => this.defaultSetUp("step_rubrik_" + i, ...args),
        saveCallback: (...args) => this.defaultSave(...args),
        disabled: (...args) => this.defaultDisabled(...args),
        updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
        updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
        done: (...args) => this.defaultDone(...args),
        updateVisible: (...args) => this.defaultVisible(...args),
        updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
        updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
      };
    }
    this.stepMap[this.VORHABEN] = {
      bez: this.gettext("Vorhaben beschreiben"),
      kbez: this.gettext("Beschreibung des Vorhabens"),
      key: this.VORHABEN,
      display_number: true,
      priority: 59,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.vorhaben],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.VORHABEN, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    this.stepMap[this.ERKLAERUNGEN] = {
      bez: this.gettext("Erklärungen"),
      kbez: this.gettext("Erklärungen"),
      key: this.ERKLAERUNGEN,
      display_number: true,
      priority: 60,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.erklaerungen],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.ERKLAERUNGEN, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    this.stepMap[this.BESCHEIDE] = {
      bez: i18n.gettext("Bescheide"),
      kbez: i18n.gettext("Bescheide"),
      key: this.BESCHEIDE,
      display_number: true,
      priority: 61,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.bescheide],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.BESCHEIDE, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    this.stepMap[this.ANLAGEN] = {
      bez: i18n.gettext("Anlagen zum Projekt verwalten"),
      kbez: i18n.gettext("Projektunterlagen"),
      key: this.ANLAGEN,
      display_number: true,
      priority: 62,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.dokumente],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.ANLAGEN, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    this.stepMap[this.SACHBEARBEITER_DOKUMENTE] = {
      bez: i18n.gettext("Gremienunterlagen verwalten"),
      kbez: i18n.gettext("Gremienunterlagen"),
      key: this.SACHBEARBEITER_DOKUMENTE,
      priority: 65,
      display_number: true,
      data: { errors: null },
      updateStatus: true,
      hideDiff: true,
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.sachbearbeiter_dokumente],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.SACHBEARBEITER_DOKUMENTE, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: () => {},
      updateSnapshotDiff: () => {},
    };
    this.stepMap[this.PRUEFUNG] = {
      bez: this.gettext("Ergebnisse der Prüfung"),
      kbez: this.gettext("Prüfung"),
      key: this.PRUEFUNG,
      display_number: true,
      priority: 70,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.pruefung],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.PRUEFUNG, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    this.stepMap[this.STELLUNGNAHME] = {
      bez: this.gettext("Stellungnahme"),
      kbez: this.gettext("Stellungnahme"),
      key: this.STELLUNGNAHME,
      display_number: true,
      priority: 80,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.stellungnahme],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.STELLUNGNAHME, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    this.stepMap[this.AUFLAGEN] = {
      bez: this.gettext("Auflagen"),
      kbez: this.gettext("Auflagen"),
      key: this.AUFLAGEN,
      display_number: true,
      priority: 90,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.auflagen],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.AUFLAGEN, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    this.stepMap[this.PRESSE] = {
      bez: this.gettext("Presse"),
      kbez: this.gettext("Presse"),
      key: this.PRESSE,
      display_number: true,
      priority: 110,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.presse],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.PRESSE, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    this.stepMap[this.ERKLAERUNGEN_ZUM_EINREICHEN] = {
      bez: this.gettext("Erklärungen zum Einreichen"),
      kbez: this.gettext("Erklärungen zum Einreichen"),
      key: this.ERKLAERUNGEN_ZUM_EINREICHEN,
      display_number: true,
      priority: 120,
      data: { errors: null },
      moduleContexts: [FoerderangebotAnzeigekontextUUIDs.erklaerungen_zum_einreichen],
      template: (...args) => this.defaultTemplate(...args),
      setUp: (...args) => this.defaultSetUp(this.ERKLAERUNGEN_ZUM_EINREICHEN, ...args),
      saveCallback: (...args) => this.defaultSave(...args),
      disabled: (...args) => this.defaultDisabled(...args),
      updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
      updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
      done: (...args) => this.defaultDone(...args),
      updateVisible: (...args) => this.defaultVisible(...args),
      updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
      updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
    };
    for (let i = 21; i < 31; i++) {
      this.stepMap["step_rubrik_" + i] = {
        bez: this.gettext("Rubrik " + i),
        kbez: this.gettext("Rubrik " + i),
        display_number: true,
        key: "step_rubrik_" + i,
        priority: 130 + (i - 20),
        data: { errors: null },
        moduleContexts: [FoerderangebotAnzeigekontextUUIDs["rubrik_" + i]],
        template: (...args) => this.defaultTemplate(...args),
        setUp: (...args) => this.defaultSetUp("step_rubrik_" + i, ...args),
        saveCallback: (...args) => this.defaultSave(...args),
        disabled: (...args) => this.defaultDisabled(...args),
        updateReadonly: (...args) => this.defaultUpdateReadOnly(...args),
        updateHasBaseEditPerm: (...args) => this.defaultUpdateHasBaseEditPerm(...args),
        done: (...args) => this.defaultDone(...args),
        updateVisible: (...args) => this.defaultVisible(...args),
        updateSnapshot: (...args) => this.defaultUpdateSnapshot(...args),
        updateSnapshotDiff: (...args) => this.defaultUpdateSnapshotDiff(...args),
      };
    }
    this.steps = [];
    this.antrag = {};
    this.module = {};
    this.geschaeftsregeln = {};
    this.anzeigekontexte = FoerderangebotAnzeigekontextUUIDs;
    this.openStepFromVue = this.openStepFromVue.bind(this);
  }

  setAntrag(antrag) {
    if (!this.antrag || this.antrag.pk === undefined) {
      this.antrag = antrag;
    } else {
      // only copy server received properties into existing antrag object
      angular.extend(this.antrag, antrag.plain());
    }
    this.buildSteps();
    for (let i = 0; i < this.steps.length; i++) {
      this.steps[i].updateReadonly(this.steps[i]);
      this.steps[i].updateHasBaseEditPerm(this.steps[i]);
    }
    this.updatePermissions();
  }

  updatePermissions() {
    this.canViewVerlauf = this.AuthService.syncHasPerm("foerderantrag.verlauf.view", this.antrag.user_permissions || []);
  }

  loadModules(kontexte) {
    const defer = this.$q.defer();
    if (!kontexte || !kontexte.length) {
      defer.resolve();
    } else {
      this.antrag.module.getList({
        anzeigekontext: kontexte,
        objekttyp: "antrag",
      }).then(
        response => {
          response.forEach(modul => {
            this.notizen[modul.regel.pk] = this.notizen[modul.regel.pk] || [];
          });
          this.groupModules(response).then(
            response => defer.resolve(response),
            err => defer.reject(err)
          );
        },
        err => defer.reject(err)
      );
    }
    return defer.promise;
  }

  groupModules(modules) {
    const defer = this.$q.defer();
    const grouped = modules.groupedByAnzeigekontext();
    for (const key in grouped) {
      this.module[key] = grouped[key].filter(
        v => v.modulname !== this.GESCHAEFTSREGEL_MODUL &&
          (this.FoerdermoduleRegistry.getModule(v.modulname) !== null ||
          this.MAPPING[v.modulname]) // Vue.js GR
      );
      this.geschaeftsregeln[key] = grouped[key].filter(v => v.modulname === this.GESCHAEFTSREGEL_MODUL);
    }
    defer.resolve(modules);
    return defer.promise;
  }

  hasGeschaeftsregeln(step) {
    let found = false;
    step.moduleContexts.forEach(key => {
      found = found || (this.geschaeftsregeln[key] &&
                        this.geschaeftsregeln[key].length > 0);
    });
    return found;
  }

  openStep(idx, status) {
    if (idx >= this.steps.length || this.steps[idx].disabled()) {
      return;
    }
    this.allOpen = false;
    if (!status) {
      this.steps[idx].open = !this.steps[idx].open;
    } else {
      this.steps[idx].open = true;
    }
    if (this.steps[idx].open) {
      const promise = this.steps[idx].setUp();
      return promise;
    }
  }

  openStepFromVue({ rubric }) {
    this.$timeout(() => {
      this.setAllSteps(false);
      this.$timeout(() => {
        const PANEL = document.querySelector(`[name="form_${ rubric.key }"] > header`);
        if (!PANEL) {
          return;
        }
        PANEL.click();
      });
    });
  }

  setAllSteps(open) {
    this.allOpen = true;
    for (let i = 0; i < this.steps.length; i++) {
      this.steps[i].open = false;
      if (open) {
        this.openStep(i);
      }
    }
  }

  getStepFromKontextUUID(faz_id) {
    if (faz_id === null) {
      const idx = this.steps.indexOf(this.stepMap[this.FOERDERANGEBOT]);
      return [idx, this.stepMap[this.FOERDERANGEBOT]];
    }
    for (const key in this.stepMap) {
      if (this.stepMap[key].moduleContexts.indexOf(faz_id) !== -1) {
        const idx = this.steps.indexOf(this.stepMap[key]);
        return [idx, this.stepMap[key]];
      }
    }
  }

  openStepFromKontextUUID(faz_id, status) {
    const idx = this.getStepFromKontextUUID(faz_id)[0];
    this.openStep(idx, status);
  }

  openStepFromKey(key) {
    const idx = this.steps.indexOf(this.stepMap[key]);
    if (idx !== -1) {
      this.openStep(idx);
    }
  }

  buildSteps() {
    this.steps.splice(0, this.steps.length);
    for (const key in this.stepMap) {
      this.stepMap[key].updateVisible(this.stepMap[key]);
      if (this.stepMap[key].visible) {
        this.steps.push(this.stepMap[key]);
      }
    }
    this.steps.sort((o1, o2) => o1.priority - o2.priority);
    forEach(
      filter(this.steps, step => step.visible && step.display_number),
      (step, idx) => step.number = idx + 1
    );
  }

  getFirstUndoneStepIdx() {
    for (let i = 0; i < this.steps.length; i++) {
      if (!this.steps[i].done()) {
        return i;
      }
    }
    return -1;
  }

  defaultTemplate() {
    return undefined;
  }
  defaultSetUp(step_key) {
    if (this.stepMap[step_key].loaded) {
      return;
    }
    return this.loadModules(this.stepMap[step_key].moduleContexts).finally(
      () => {
        this.stepMap[step_key].loaded = true;
        this.stepMap[step_key].updateReadonly(this.stepMap[step_key]);
      }
    );
  }
  defaultSave() {}

  defaultDisabled() {
    return this.antrag === null;
  }

  defaultUpdateReadOnly(step) {
    step.data.readonly = false;

    if (this.antrag === null) {
      return;
    }
    if (!this.antrag.eingereicht) {
      // Vor dem Einreichen muss auch auf den Regelsatz geachtet
      // werden. Nach dem Einreichen ist diese Prüfung egal.
      step.data.readonly = this.antrag.is_regelsatz_valid === false;
    }
  }

  defaultUpdateHasBaseEditPerm(step) {
    step.data.hasBaseEditPerm = true;
    if (this.antrag === null) {
      return;
    }
    step.data.hasBaseEditPerm = this.AuthService.syncHasPerm("foerderantrag.update", this.antrag.user_permissions);
  }

  defaultDone() {
    return false;
  }
  updateImmerVisible(step) {
    step.visible = this.antrag.pk !== undefined;
  }
  defaultVisible(step) {
    if (this.antrag.kontexte !== null) {
      for (let i = 0; i < step.moduleContexts.length; i++) {
        if (this.antrag.kontexte.indexOf(step.moduleContexts[i]) !== -1) {
          step.visible = true;
          return;
        }
      }
    }
    // modules not loaded yet - assume every context is not visible
    step.visible = false;
  }
  defaultUpdateSnapshot(step) {
    step.snapshot = {};
    if (this.snapshot) {
      step.snapshot = angular.extend(step.snapshot, isFunction(this.snapshot.plain) ? this.snapshot.plain() : this.snapshot);
      let sn = {};
      for (let i = 0; i < step.moduleContexts.length; i++) {
        if (this.snapshot.asn_snapshot[step.moduleContexts[i]]) {
          sn = angular.merge(sn, this.snapshot.asn_snapshot[step.moduleContexts[i]]);
        }
      }
      step.snapshot.asn_snapshot = sn;
    }
  }
  defaultUpdateSnapshotDiff(step) {
    step.snapshotDiff = {
      diff_result: {
        added: {},
        modified: {},
        removed: {}
      }
    };
    if (this.snapshotDiff) {
      for (let i = 0; i < step.moduleContexts.length; i++) {
        if (this.snapshotDiff.diff_result.added[step.moduleContexts[i]]) {
          step.snapshotDiff.diff_result.added = angular.merge(step.snapshotDiff.diff_result.added, this.snapshotDiff.diff_result.added[step.moduleContexts[i]]);
        }
        if (this.snapshotDiff.diff_result.modified[step.moduleContexts[i]]) {
          step.snapshotDiff.diff_result.modified = angular.merge(step.snapshotDiff.diff_result.modified, this.snapshotDiff.diff_result.modified[step.moduleContexts[i]]);
        }
        if (this.snapshotDiff.diff_result.removed[step.moduleContexts[i]]) {
          step.snapshotDiff.diff_result.removed = angular.merge(step.snapshotDiff.diff_result.removed, this.snapshotDiff.diff_result.removed[step.moduleContexts[i]]);
        }
      }
    }
  }
}

export default FoerderantragBaseWizardController;
