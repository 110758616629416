import AngularLink from "../../../../../../../../libs/vue/client/vue/global/components/AngularLink/AngularLink.vue";
import Modal from "../../../../../../../../libs/vue/client/vue/global/components/Modal/Modal.vue";
import PuxButton from "../../../../../../../../libs/vue/client/vue/global/components/PuxButton/PuxButton.vue";

import HttpMixin from "../../../../../../../../libs/vue/client/vue/global/mixins/HttpMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "StudiengangDetailsTeilstudiengang",
  components: {
    AngularLink,
    Modal,
    PuxButton,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    studiengang: {
      type: Object,
      required: true,
    },
    teilStudiengang: {
      type: Object,
      required: true,
    },
    teilStudiengangIndex: {
      type: Number,
      required: true,
    },
    parentHtmlId: {
      type: String,
      required: true,
    },
  },
  emits: [
    "loadTeilStudiengange",
  ],
  data() {
    return {
      loading: false,
      confirmDeleteShow: undefined,
      confirmDeleteOptions: undefined,
    };
  },
  computed: {
    teilStudiengangRemoveUrl() {
      return `studiengaenge/${ this.studiengang.pk }/tsg_zuordnung_loesen/`;
    },

    teilStudiengangButtonTip() {
      return `${ this.teilStudiengang.name } vom Kombinationsstudiengang lösen`;
    },
    teilStudiengangButtonIp() {
      return `teilstudiengang_uebersicht_${ this.teilStudiengangIndex }`;
    },

    studiengangLinkText() {
      return `${ this.teilStudiengang.name }${ this.teilStudiengang.lehramtstyp_kbez ? " (" + this.teilStudiengang.lehramtstyp_kbez + ")" : "" }`;
    },

    selectorClose() {
      return `#${ this.parentHtmlId }`;
    },
  },
  methods: {
    closeModalConfirmDelete() {
      this.confirmDeleteShow = false;
    },

    openModalConfirmDelete() {
      this.confirmDeleteOptions = {
        okClass: "btn btn-primary",
        title: "Teilstudiengang lösen",
        msg: `Sind Sie sicher, dass Sie den Teilstudiengang ${ this.teilStudiengang.name } vom Kombinationsstudiengang lösen wollen?`,
        okLabel: "Teilstudiengang lösen",
        cancelLabel: "_BTN_CANCEL_",
        okCallback: this.deleteTeilstudiengang,
        cancelCallback: this.closeModalConfirmDelete,
      };
      this.confirmDeleteShow = true;
    },

    deleteTeilstudiengang() {
      this.loading = true;
      const DATA = cloneDeep(this.studiengang);
      DATA.removeTsg = this.teilStudiengang;

      this.putHttp({
        url: this.teilStudiengangRemoveUrl,
        data: DATA,
      }).then(
        () => {
          this.$emit("loadTeilStudiengange", this.teilStudiengangIndex);
          this.closeModalConfirmDelete();
          this.addNotification({ text: "Teilstudiengang wurde erfolgreich vom Kombinationsstudiengang gelöst" });
        },
        () => {
          this.addNotification({
            text: "Teilstudiengang kann nicht gelöscht werden",
            type: "error",
          });
        }
      ).finally(
        () => {
          this.loading = false;
        }
      );
    },
  },
};
