import FormElement from "../../../../global/components/FormElement/FormElement.vue";

import translate from "../../../../global/directives/translate";

import BetragsrechnerBetragMixin from "./BetragsrechnerBetragMixin";
import PermissionMixin from "../../../../global/mixins/PermissionMixin";

// @vue/component
export default {
  components: {
    FormElement,
  },
  directives: {
    translate,
  },
  mixins: [
    BetragsrechnerBetragMixin,
    PermissionMixin,
  ],
  props: {
    htmlId: {
      type: String,
      required: true,
    },
    model: {
      type: Object,
      required: true,
      info: "Model: 'position' oder 'beleg'",
    },
    kontext: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    extra: {
      type: Object,
      required: true,
    },
    vorschau: {
      type: Object,
      required: true,
    },
    vertrag: {
      type: Object,
      required: false,
      default: undefined,
    },
    position: {
      type: Object,
      required: false,
      default: undefined,
    },
    autoFocus: {
      type: Boolean,
      required: false,
    },
    view: {
      type: String,
      required: false,
      default: "h",
      info: "view für form-element"
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      classesHorizontal: [
        "col-sm-3 text-left text-sm-right",
        "col-sm-6",
        "offset-sm-3 col-sm-6",
      ],
      timer: undefined,
    };
  },
  computed: {
    statusPruefer() {
      return this.checkPermissionsSync({
        perm: "vertragskostenpositionsbelege.anerkennen",
        permArray: this.model.user_permissions || [],
      });
    },
  },
  methods: {
    changeVorschauLocal() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$attrs.onChangeVorschau();
      }, 500);
    },

    initErrorLabels(labels) {
      if (this.$attrs.onInitErrorLabels) {
        this.$attrs.onInitErrorLabels({
          labels: labels || this.errorLabels,
        });
      }
    },
  },
};
