import Modal from "../../../../../global/components/Modal/Modal.vue";
import FormElement from "../../../../../global/components/FormElement/FormElement.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "DocumentsModalCreate",
  components: {
    Modal,
    FormElement,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
    selectorClose: {
      type: String,
      required: true,
    },
    dokumentarten: {
      type: Array,
      required: true,
    },
    addDocument: {
      type: Function,
      required: true,
    },
    pflichtOrOptional: {
      type: String,
      required: true,
    },
    headerName: {
      type: String,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modelDokart: null,
      countStart: 0,
      countFinish: 0,
      options: {
        showCloseButton: false,
        required: true,
      },
      status: {
        loading: undefined,
      },
      optionsDokart: {
        type: "select",
        id: "dokart",
        label: "_LBL_AST_WIZARD_MODAL_CREATE_DOKUMENTTYP_",
        translate: true,
        required: true,
        view: "v",
        keyLabel: "name",
        keyId: "pk",
        deselect: false,
        search: true,
        searchList: ["name"],
      },
    };
  },
  computed: {
    optionsUpload() {
      return {
        multiple: true,
        drop: true,
        type: "document",
        progressHideTime: 0,
        saveUrl: `${ this.baseUrl }dokumente/?dokart=${ this.modelDokart }`,
        saveStart: this.saveStart,
        saveError: this.saveError,
      };
    },

    loading() {
      return this.countStart !== this.countFinish;
    },
  },
  methods: {
    addDokumentLocal({ currentModel }) {
      this.countFinish++;
      this.addDocument({ document: currentModel, key: this.pflichtOrOptional });
    },

    saveStart() {
      this.countStart++;
    },

    saveError() {
      this.countFinish++;
    },

    closeLocal() {
      this.close();
    },
  },
};
