import Modal from "../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "CodeModalGueltigkeit",
  components: {
    Modal,
    PuxButton,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    obj: {
      type: Object,
      default: () => ({}),
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      model: {
        co_gueltig_bis: undefined
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "co_gueltig_bis",
            type: "date",
            label: "_LBL_CODE_TABLE_MODAL_VALID_DATE_GUELTIG_BIS_",
            required: true,
            view: "v-alt",
            translate: true,
          },
        ],
      },
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    translationMsgSuccess() {
      return {
        text: "_MSG_CODE_TABLE_MODAL_VALID_DATE_SUCCESS_{{code}}_",
        extra: { code: this.obj.co_code }
      };
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.model.co_gueltig_bis = this.obj.co_gueltig_bis;
    },

    save() {
      this.status.loading = true;
      const data = cloneDeep(this.model);
      this.putHttp({ url: `codes/${ this.obj.pk }/`, data }).then(
        response => {
          this.addNotification(this.translationMsgSuccess);
          this.close({ response });
        },
        error => {
          this.errors = error.data;
          this.errorsClone = error.data;
        }
      ).then(
        () => this.status.loading = false
      );
    },
  },
};
