"use strict";

import KostenController from "../../kosten.controller";

class AllgemeinkostenPauschaleController extends KostenController {
  /*@ngInject*/
  constructor($scope, $q, Foerdermodule, Foerderantrag, KKostenart, BetragsrechnerRegistry, i18n) {
    super($scope, $q, Foerdermodule, Foerderantrag, KKostenart, BetragsrechnerRegistry, i18n);

    // Keine Felder zum Erzeugen, da es ein readonly Kostenmodul ist:
    this.newPosition = {};
  }
}

export default AllgemeinkostenPauschaleController;
