import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../global/components/table/table.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import getTableApi from "../../../const/TableApis";
import fileDownload from "js-file-download";

const TABLE_ID = "NutzerDatenSucheTable";

// @vue/component
export default {
  name: "NutzerDatenSucheList",
  components: {
    PageTabTitle,
    PuxTranslate,
    SmartTable,
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: getTableApi({ id: TABLE_ID }),
        rowActions: [],
        massActions: [{
          label: "_LBL_NUTZER_DATEN_SUCHE_LIST_DATEN_EXPORTIEREN_",
          callback: this.exportDaten,
        }],
        label: "_LBL_NUTZER_DATEN_SUCHE_LIST_PERSONENBEZOGENE_DATEN_",
        sortable: false,
        pagination: true,
        details: false,
        view: {
          filter: "top",
        },
      },
    };
  },
  methods: {
    exportDaten({ pks }) {
      this.postHttp({ url: "nutzerdatensuche/batch_export/", data: { pks } }).then(
        response => {
          const DATA_STRING = JSON.stringify(response, null, 2);
          fileDownload(DATA_STRING, "personenbezogene_daten.json");
          this.addNotification({ text: "_MSG_NUTZERDATEN_SUCHE_LIST_EXPORT_SUCCESS_" });
        },
        () => this.addNotification({ text: "_MSG_NUTZERDATEN_SUCHE_LIST_EXPORT_ERROR_", type: "error" })
      );
    },
  },
};
