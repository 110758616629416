import AnalysenStatus from "../../Analysen/AnalysenStatus/AnalysenStatus.vue";
import AngularLink from "../../../global/components/AngularLink/AngularLink.vue";
import AnlagenAuszahlung from "./AnlagenAuszahlung/AnlagenAuszahlung.vue";
import AsideRightBox from "../../../global/components/AsideRightBox/AsideRightBox.vue";
import AufgabeBox from "../../Aufgabe/AufgabeBox/AufgabeBox.vue";
import AuszahlungBankverbindung from "./AuszahlungBankverbindung/AuszahlungBankverbindung.vue";
import AuszahlungBelegSummenBox from "./AuszahlungBelegSummenBox/AuszahlungBelegSummenBox.vue";
import AuszahlungBetragAnpassenButtonModal from "./AuszahlungBetragAnpassenButtonModal/AuszahlungBetragAnpassenButtonModal.vue";
import AuszahlungBetragBox from "./AuszahlungBetragBox/AuszahlungBetragBox.vue";
import AuszahlungBetragEditButtonModal from "./AuszahlungBetragEditButtonModal/AuszahlungBetragEditButtonModal.vue";
import AuszahlungErrors from "./AuszahlungErrors/AuszahlungErrors.vue";
import AuszahlungGoToBox from "./AuszahlungGoToBox/AuszahlungGoToBox.vue";
import AuszahlungMeldungVollstaendigkeit from "./AuszahlungMeldungVollstaendigkeit/AuszahlungMeldungVollstaendigkeit.vue";
import AuszahlungModalitaetChangeButtonModal from "./AuszahlungModalitaetChangeButtonModal/AuszahlungModalitaetChangeButtonModal.vue";
import AuszahlungNotifikationBox from "./AuszahlungNotifikationBox/AuszahlungNotifikationBox.vue";
import AuszahlungUebersicht from "./AuszahlungUebersicht/AuszahlungUebersicht.vue";
import AuszahlungWizard from "./AuszahlungWizard/AuszahlungWizard.vue";
import AuszahlungZeitraum from "./AuszahlungZeitraum/AuszahlungZeitraum.vue";
import BelegCreateButton from "../../KF/BelegCreateButton/BelegCreateButton.vue";
import ContextPanel from "../../../global/components/ContextPanel/ContextPanel.vue";
import CsvDownload from "../../../global/components/CsvDownload/CsvDownload.vue";
import DetailsInfoBox from "../../DetailsInfoBox/DetailsInfoBox.vue";
import DokumentDownload from "../../Dokumente/DokumentDownload/DokumentDownload.vue";
import FoerderorgAnsprechpartnerAddBtn from "../../Foerderorganisation/FoerderorgAnsprechpartnerAddBtn/FoerderorgAnsprechpartnerAddBtn.vue";
import FormularCreate from "../../Formulare/FormularCreate/FormularCreate.vue";
import HinweiseZurAuszahlung from "./HinweiseZurAuszahlung/HinweiseZurAuszahlung.vue";
// import KFAuszahlungPerioden from "../../KF/KFAuszahlungPerioden/KFAuszahlungPerioden.vue";
import NachrichtenObjectListBox from "../../Nachrichten/NachrichtenObjectListBox/NachrichtenObjectListBox.vue";
import NotizenBox from "../../Notizen/NotizenBox/NotizenBox.vue";
import NotizenIcon from "../../Notizen/NotizenIcon/NotizenIcon.vue";
import Permission from "../../../global/components/Permission/Permission.vue";
import PuxAlert from "../../../global/components/PuxAlert/PuxAlert.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxDropdown from "../../../global/components/PuxDropdown/PuxDropdown.vue";
import PuxIcon from "../../../global/components/PuxIcon/PuxIcon.vue";
import PuxPageDetails from "../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import RechtsformdokumenteList from "../../Dokumente/RechtsformdokumenteList/RechtsformdokumenteList.vue";
import SachPruefBericht from "./SachPruefBericht/SachPruefBericht.vue";
import SnapshotsBox from "../../Snapshot/SnapshotsBox/SnapshotsBox.vue";
import TaskLogBox from "../../../global/components/TaskLogBox/TaskLogBox.vue";
import TemplateRendering from "../../Templates/TemplateRendering/TemplateRendering.vue";
import Timeline from "../../Timeline/Timeline.vue";
import VertragsKonto from "../../Vertrag/VertragsKonto/VertragsKonto.vue";
import Vertretungsberechtigte from "../../Vertretungsberechtigte/ObjectVertretungsberechtigte/Vertretungsberechtigte.vue";
import Verwendungszweck from "./Verwendungszweck/Verwendungszweck.vue";
import WFStatusGruppen from "../../Workflows/StatusGruppen/StatusGruppen.vue";
import WorkflowFolgen from "../../Workflowfolgen/WorkflowFolgen/WorkflowFolgen.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import fileDownload from "js-file-download";
import FoerderangebotAnzeigekontextUUIDs from "../../../const/FoerderangebotAnzeigekontextUUIDs";
import KWorkflowtypUUIDs from "../../../const/KWorkflowtypUUIDs";
import SaveCallbackActions from "../../../const/SaveCallbackActions";
import {
  gettext,
} from "../../../global/functions/utils";
import {
  EventBus,
} from "../../../global/functions/event-bus";
import { createNamespacedHelpers } from "vuex";
import {
  cloneDeep,
  filter,
  find,
  findIndex,
  forEach,
  get,
  includes,
  isArray,
  isEmpty,
  isNil,
  isString,
  keyBy,
  orderBy,
  size,
} from "lodash-es";

const {
  mapMutations,
  mapState,
} = createNamespacedHelpers("snapshot");

// @vue/component
export default {
  name: "AuszahlungDetails",
  components: {
    AnalysenStatus,
    AngularLink,
    AnlagenAuszahlung,
    AsideRightBox,
    AufgabeBox,
    AuszahlungBankverbindung,
    AuszahlungBelegSummenBox,
    AuszahlungBetragAnpassenButtonModal,
    AuszahlungBetragBox,
    AuszahlungBetragEditButtonModal,
    AuszahlungErrors,
    AuszahlungGoToBox,
    AuszahlungMeldungVollstaendigkeit,
    AuszahlungModalitaetChangeButtonModal,
    AuszahlungNotifikationBox,
    AuszahlungUebersicht,
    AuszahlungWizard,
    AuszahlungZeitraum,
    BelegCreateButton,
    ContextPanel,
    CsvDownload,
    DetailsInfoBox,
    DokumentDownload,
    FoerderorgAnsprechpartnerAddBtn,
    FormularCreate,
    HinweiseZurAuszahlung,
    // KFAuszahlungPerioden,
    NachrichtenObjectListBox,
    NotizenBox,
    NotizenIcon,
    Permission,
    PuxAlert,
    PuxButton,
    PuxDropdown,
    PuxIcon,
    PuxPageDetails,
    PuxTranslate,
    RechtsformdokumenteList,
    SachPruefBericht,
    SnapshotsBox,
    TaskLogBox,
    TemplateRendering,
    Timeline,
    VertragsKonto,
    Vertretungsberechtigte,
    Verwendungszweck,
    WFStatusGruppen,
    WorkflowFolgen,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
    SyConfigMixin,
  ],
  provide() {
    return {
      updateAnsprechpartner: this.loadAuszahlung,
    };
  },
  setup() {
    const {
      filterSpaced,
    } = FiltersAPI();

    return {
      filterSpaced,
    };
  },
  data() {
    return {
      allAuszahlungenWithoutCurrent: [],
      ast: {},
      auszahlung: {},
      auszahlungsplan: {},
      canCreateBeleg: false,
      clientFunktion: undefined,
      einreichenMessage: "_HTML_AUSZAHLUNG_DETAILS_EINREICHEN_MSG_{{auszahlung_name}}_{{referenznr}}_",
      einreichenStatus: false,
      finanzierungsModule: [],
      isAllAuszahlungenForVertragLoaded: false,
      isPersonalBaumVisible: false,
      kofiErfassungReloadStatus: undefined,
      kostenModule: [],
      lastWfUpdate: undefined,
      lastWfAfterWfoReload: undefined,
      loading: {
        antrag: true,
        ast: true,
        auszahlung: true,
        auszahlungsplan: true,
        bericht: true,
        kofimodule: true,
        vertrag: true,
        workflow: true,
        zuschuss: true,
      },
      loadingChangeAllBelege: undefined,
      pbModule: [],
      reloading: false,
      sbModule: [],
      snapshot: undefined,
      snapshotActivePk: undefined,
      statusShowDokumenteRubrik: false,
      validationErrors: undefined,
      validationErrorsLoading: undefined,
      vertrag: {},
      viewName: "details",
      wfKontext: undefined,
      zuschuss: undefined,
      vertragsKonto: undefined,
      currentWorkflowfolge: undefined,
    };
  },
  computed: {
    vertragUrl() {
      return `vertraege/${ this.$stateParams.id }/`;
    },

    antragUrl() {
      return `foerderantraege/${ this.vertrag.antrag_id }/`;
    },

    antragModuleUrl() {
      return `${ this.antragUrl }module/`;
    },

    auszahlungenUrl() {
      return `${ this.vertragUrl }auszahlungen/`;
    },

    auszahlungUrl() {
      return `${ this.auszahlungenUrl }${ this.$stateParams.aus_id }/`;
    },

    vertragspersonalstellenUrl() {
      return `${ this.vertragUrl }vertragspersonalstellen/`;
    },

    vertragCheckMyPermissionsUrl() {
      return `${ this.vertragUrl }check_my_permissions/`;
    },

    vertragFibuPermissionUrl() {
      return `${ this.vertragUrl }check_fibu_permission/`;
    },

    vertragKontoUrl() {
      return `${ this.vertragUrl }konto/`;
    },

    antragstellerUrl() {
      return `antragstellerorganisationen/${ this.antragstellerPk }/`;
    },

    antragstellerPk() {
      return this.ast.pk || get(this.vertrag, "antragstellerorg.pk");
    },

    auszahlungsplanUrl() {
      return `auszahlungsplaene/${ this.auszahlung.auszahlungsplan }/`;
    },

    zuschussUrl() {
      return `${ this.auszahlungUrl }zuschuss/`;
    },

    auszahlungModuleUrl() {
      return `${ this.auszahlungUrl }module/`;
    },

    auszahlungZuruecksetzenUrl() {
      return `${ this.auszahlungUrl }auszahlung_zuruecksetzen/`;
    },

    auszahlungUebersichtPdfUrl() {
      return `${ this.auszahlungUrl }uebersicht_pdf/`;
    },

    auszahlungPdfUrl() {
      return `${ this.auszahlungUrl }pdf/`;
    },

    auszahlungZahlungsanweisungUrl() {
      return `${ this.auszahlungUrl }zahlungsanweisung/`;
    },

    auszahlungKostenbelegStatusEingereichtUrl() {
      return `${ this.auszahlungUrl }kostenbeleg_status_eingereicht/`;
    },

    auszahlungBelegeAnerkennenUrl() {
      return `${ this.auszahlungUrl }belege_anerkennen/`;
    },

    auszahlungBetrantragtenBetragAnerkennenUrl() {
      return `${ this.auszahlungUrl }beantragten_betrag_anerkennen/`;
    },

    auszahlungValidierenUrl() {
      return `${ this.auszahlungUrl }validieren/`;
    },

    vertragDhwVerwendungsnachweisdokumentUrl() {
      return `${ this.vertragUrl }dhw_verwendungsnachweisdokument/`;
    },
    auszahlungKofiDownloadUrl() {
      return `${ this.auszahlungUrl }kofi_dokument/`;
    },
    timelineConfig() {
      return {
        config: {
          kontexte: [
            {
              titel: "Erstellung",
              key: "A",
            },
            {
              titel: "Prüfung",
              key: "F"
            },
          ],
          benachrichtigungen: {
            bezug: "auszahlung",
            bezugpk: this.$stateParams.aus_id,
            autorefresh: true,
          },
          url: `${ this.auszahlungUrl }timeline/`,
        },
        bisection: true,
      };
    },

    stepsFiltered() {
      return filter(this.stepsForNotizen, item => {
        return !(item.visible && !this[item.visible]);
      });
    },

    stepsForNotizen() {
      return [
        this.rubricsMapping.ubersicht,
        this.rubricsMapping.hinweis,
        this.rubricsMapping.zeitraum,
        this.rubricsMapping.belege,
        this.rubricsMapping.bankverbindung,
        this.rubricsMapping.vertretungsberechtigte,
        this.rubricsMapping.sachbericht,
        this.rubricsMapping.pruefbericht,
        this.rubricsMapping.dokumente,
      ];
    },

    rubricsMapping() {
      return {
        ubersicht: {
          id: "ubersicht",
          kbez: "_TXT_AUSZAHLUNG_DETAILS_RUBRIK_UEBERSICHT_",
          key: "step_ubersicht",
          prio: 0,
        },
        hinweis: {
          id: "hinweis",
          key: "step_hinweis",
          kbez: "_TXT_AUSZAHLUNG_DETAILS_RUBRIK_HINWEISE_",
          visible: "hasAuszahlungWfPermissionAuszahlungHinweisView",
          prio: 1,
        },
        zeitraum: {
          id: "zeitraum",
          key: "step_zeitraum",
          kbez: "_TXT_AUSZAHLUNG_DETAILS_RUBRIK_ZEITRAUM_",
          visible: "hasAuszahlungWfPermissionAuszahlungZeitraumView",
          prio: 2,
        },
        belege: {
          id: "belege",
          key: "step_belege",
          kbez: "_TXT_AUSZAHLUNG_DETAILS_RUBRIK_BELEGE_",
          visible: "isRubrikKofiVisible",
          prio: 3,
        },
        bankverbindung: {
          id: "bankverbindung",
          key: "step_bv",
          kbez: "_TXT_AUSZAHLUNG_DETAILS_RUBRIK_BANKVERBINDUNG_",
          visible: "isBankverbindungVisible",
          componentsId: [
            "auszahlung_bankverbindung",
            "verwendungszweck",
          ],
          prio: 4,
        },
        vertretungsberechtigte: {
          id: "vertretungsberechtigte",
          key: "step_vertretungsberechtigte",
          kbez: "_TXT_AUSZAHLUNG_DETAILS_RUBRIK_VERTRETUNGSBERECHTIGTE_",
          visible: "isVertretungsberechtigteVisible",
          prio: 5,
        },
        sachbericht: {
          id: "sachbericht",
          key: "step_berichte",
          kbez: this.sachberichtRubrikName,
          visible: "isRubrikSachberichtVisible",
          prio: 6,
        },
        pruefbericht: {
          id: "pruefbericht",
          key: "step_pruefberichte",
          kbez: this.pruefberichtRubrikName,
          visible: "isRubrikPruefberichtVisible",
          prio: 7,
        },
        dokumente: {
          id: "dokumente",
          key: "step_dokumente",
          kbez: "_TXT_AUSZAHLUNG_DETAILS_RUBRIK_DOKUMENTE_",
          visible: "isNotizenForDokumenteRubrikVisible",
          prio: 8,
        },
        auszahlung_einreichen: {
          kbez: "_TXT_ALLGEMEINE_FEHLER_",
        },
      };
    },

    rubricsIdsMapping() {
      return {
        ubersicht: [],
        hinweis: [
          "hinweis_zur_auszahlung",
        ],
        zeitraum: [
          "auszahlung_zeitraum",
        ],
        belege: [
          "belege",
        ],
        bankverbindung: [
          "auszahlung_bankverbindung",
          "verwendungszweck",
        ],
        vertretungsberechtigte: [
          "auszahlung_vertreter",
        ],
        sachbericht: [
          "auszahlung_sachbericht",
        ],
        pruefbericht: [
          "auszahlung_pruefbericht",
        ],
        dokumente: [
          "anlagen_auszahlung_documents",
        ],
      };
    },

    sachberichtRubrikName() {
      if (this.modalitaetSachbericht) {
        return get(this.sachberichtVorlageObj, `[${ this.modalitaetSachbericht }].sbv_rubriklabel`);
      }
      return "";
    },

    modalitaetSachbericht() {
      return get(this.auszahlungsplan, "zp_modalitaet.sachbericht");
    },

    pruefberichtRubrikName() {
      if (this.modalitaetPruefbericht) {
        return get(this.sachberichtVorlageObj, `[${ this.modalitaetPruefbericht }].sbv_rubriklabel`);
      }
      return "";
    },

    modalitaetPruefbericht() {
      return get(this.auszahlungsplan, "zp_modalitaet.pruefbericht");
    },

    hasPermissionVertragskostenpersonalstellenView() {
      return this.checkPermissionsSync({
        perm: "vertragskostenpersonalstellen.view",
        permArray: this.vertrag.user_permissions,
      });
    },

    hasPermissionVertragskostenpositionsbelegeView() {
      return this.checkPermissionsSync({
        perm: "vertragskostenpositionsbelege.view",
        permArray: this.vertrag.user_permissions,
      });
    },

    wizardConfig() {
      return this.getSyConfigsValue("auszahlung_wizard", {});
    },

    canSeeWizard() {
      return !!(this.checkPermissionsSync({
        perm: "auszahlung.wizard.view",
        permArray: this.auszahlung.user_permissions,
      }) && this.wizardConfig.wizard_anzeigen && this.wizardConfig.initial_anzeigen);
    },

    canOpenWizard() {
      return !!(this.checkPermissionsSync({
        perm: "auszahlung.wizard.view",
        permArray: this.auszahlung.user_permissions,
      }) && this.wizardConfig.wizard_anzeigen);
    },

    auszahlungLabel() {
      return `${ this.filterSpaced(this.vertrag.v_nr) } - ${ this.auszahlung.az_nummer }`;
    },

    anzeigekontexteAuszahlung() {
      return get(this.auszahlung, "aktuelle_aufgabe.au_kontext_config.markiert.auszahlung") || {};
    },

    anzeigekontexteAuszahlungOffen() {
      return get(this.auszahlung, "aktuelle_aufgabe.au_kontext_config.offen.auszahlung") || {};
    },

    statusAuszahlungEinreichen() {
      return !this.auszahlung.eingereicht &&
        !this.hasAuszahlungWfPermissionVertragskostenpositionsbelegeAnerkennen;
    },

    isBelegCreateButtonVisible() {
      return this.hasAuszahlungWfPermissionVertragskostenpositionsbelegeCreateButton;
    },

    sachberichtVorlageObj() {
      return keyBy(this.auszahlung.sachberichtvorlage, "pk");
    },

    isRubrikSachberichtVisible() {
      return !!(this.sachberichtRubrikName &&
        this.auszahlung.sachbericht &&
        this.hasAuszahlungWfPermissionSachberichtView);
    },

    isRubrikPruefberichtVisible() {
      return !!(this.pruefberichtRubrikName &&
        this.auszahlung.pruefbericht &&
        this.hasAuszahlungWfPermissionPruefberichtView);
    },

    usesZuschussberechnungLocal() {
      return usesZuschussberechnung({ auszahlungsplan: this.auszahlungsplan });
    },

    isRubrikKofiVisible() {
      return !this.auszahlungsplan.zp_modalitaet || this.auszahlungsplan.zp_modalitaet.koplfipl_anzeigen !== false;
    },

    isLoading() {
      return this.loading.antrag || this.loading.ast || this.loading.auszahlung || this.loading.auszahlungsplan || this.reloading;
    },

    pageTitleExtra() {
      return {
        name: this.auszahlung.name,
        az_referenznr: this.auszahlung.az_referenznr,
      };
    },

    extraAuszahlungName() {
      return {
        name: this.auszahlung.name,
      };
    },

    extraForLinkBackTranslate() {
      return {
        v_nr: this.filterSpaced(this.vertrag.v_nr),
      };
    },

    isWFStatusGruppenInHeader() {
      const WF_STATUS_GRUPPEN_IN_HEADER = this.getSyConfigsValue("wf_statusgruppen_in_header", {});
      WF_STATUS_GRUPPEN_IN_HEADER.objects = WF_STATUS_GRUPPEN_IN_HEADER.objects || [];
      return WF_STATUS_GRUPPEN_IN_HEADER.objects.indexOf("auszahlung") !== -1;
    },

    isModalitaetLast() {
      return !!get(this.auszahlungsplan, "zp_modalitaet.letzte");
    },

    isWeitereAktionenVisible() {
      return this.isFunctionUebersichtPdfVisible ||
        this.isFunctionAuszahlungPdfVisible ||
        this.isKoFiDownloadVisible ||
        this.isFunctionBelegeInPruefungVisible ||
        this.isFunctionUngepruefteBelegeAnerkennenVisible ||
        this.isFunctionBeantragteBelegeAnerkennenVisible ||
        this.isFunctionBetragAnpassenVisible ||
        this.isFunctionZahlungsanweisungPdfVisible ||
        this.isFunctionDhwVerwendungsnachweisVisible ||
        this.isFunctionModalitaetWechselnVisible ||
        this.isFunctionAuszahlungWizardVisible ||
        this.isFunctionAuszahlungValidateVisible ||
        this.isFunctionCreateSnapshotVisible;
    },

    isFunctionUebersichtPdfVisible() {
      return this.hasAuszahlungWfPermissionDokumentView &&
        this.hasAuszahlungWfPermissionAuszahlungDokumentUebersichtView;
    },

    isFunctionAuszahlungPdfVisible() {
      return !!(this.auszahlung.eingereicht &&
        this.hasAuszahlungWfPermissionAuszahlungPDFView);
    },

    isKoFiDownloadVisible() {
      return this.hasAuszahlungKoFiDownloadPermission;
    },

    isFunctionBelegeInPruefungVisible() {
      // TODO: Wir müssen das mit Aplikationskontexte ändern
      return !!((this.$Client === "dias-am" ||
          this.$Client === "dias-sdb") &&
        this.hasAuszahlungWfPermissionVertragskostenpositionsbelegeAnerkennen);
    },

    isFunctionUngepruefteBelegeAnerkennenVisible() {
      return !!(this.auszahlung.belege_vorhanden &&
        this.hasAuszahlungWfPermissionVertragskostenpositionsbelegeAnerkennenPauschal);
    },

    isFunctionBeantragteBelegeAnerkennenVisible() {
      return !!(!this.auszahlung.belege_vorhanden &&
        this.hasAuszahlungWfPermissionVertragskostenpositionsbelegeAnerkennenPauschal);
    },

    isFunctionBetragAnpassenVisible() {
      return !!(this.auszahlung.az_betrag_anerkannt &&
        this.hasAuszahlungWfPermissionAuszahlungAuszahlungsbetragUpdate);
    },

    isFunctionZahlungsanweisungPdfVisible() {
      return this.hasAuszahlungWfPermissionAuszahlungZahlungsanweisung;
    },

    isFunctionDhwVerwendungsnachweisVisible() {
      return this.hasAuszahlungWfPermissionDhwVerwendungsnachweisdokumentView;
    },

    isFunctionModalitaetWechselnVisible() {
      return this.hasAuszahlungWfPermissionAuszahlungModalitaetWechsel;
    },

    isFunctionAuszahlungWizardVisible() {
      return this.canOpenWizard;
    },

    isFunctionAuszahlungValidateVisible() {
      return this.hasAuszahlungWfPermissionAuszahlungValidieren;
    },

    isFunctionCreateSnapshotVisible() {
      return this.hasPermissionAuszahlungVersionenManuellErstellen;
    },

    isRechtsformdokumenteListVisible() {
      return this.hasAuszahlungWfPermissionRechtsformdokumenteAnsehen;
    },

    hasAuszahlungWfPermissionVertragskostenpositionsbelegeCreateButton() {
      return this.checkPermissionsSync({
        perm: "vertragskostenpositionsbelege.create.button",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionDokumentView() {
      return this.checkPermissionsSync({
        perm: "dokument.view",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionAuszahlungDokumentUebersichtView() {
      return this.checkPermissionsSync({
        perm: "auszahlung.dokument.uebersicht.view",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionAuszahlungEinreichen() {
      return this.checkPermissionsSync({
        perm: "auszahlung.einreichen",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionAuszahlungPDFView() {
      return this.checkPermissionsSync({
        perm: "auszahlung.dokument.pdf.view",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionVertragskostenpositionsbelegeAnerkennenPauschal() {
      return this.checkPermissionsSync({
        perm: "vertragskostenpositionsbelege.anerkennen.pauschal",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionVertragskostenpositionsbelegeAnerkennen() {
      return this.checkPermissionsSync({
        perm: "vertragskostenpositionsbelege.anerkennen",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionAuszahlungAuszahlungsbetragUpdate() {
      return this.checkPermissionsSync({
        perm: "auszahlung.auszahlungsbetrag.update",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionAuszahlungZahlungsanweisung() {
      return this.checkPermissionsSync({
        perm: "auszahlung.zahlungsanweisung",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionDhwVerwendungsnachweisdokumentView() {
      return this.checkPermissionsSync({
        perm: "dhw_verwendungsnachweisdokument.view",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionAuszahlungModalitaetWechsel() {
      return this.checkPermissionsSync({
        perm: "auszahlung.modalitaet.wechsel",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionAuszahlungValidieren() {
      return this.checkPermissionsSync({
        perm: "auszahlung.validieren",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionAuszahlungHinweisView() {
      return this.checkPermissionsSync({
        perm: "auszahlung.hinweis.view",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionAuszahlungZeitraumView() {
      return this.checkPermissionsSync({
        perm: "auszahlung.zeitraum.view",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionVertragskostenpositionsbelegeUpdate() {
      return this.checkPermissionsSync({
        perm: "vertragskostenpositionsbelege.update",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionAuszahlungVertreterView() {
      return this.checkPermissionsSync({
        perm: "auszahlung.vertreter.view",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionSachberichtView() {
      return this.checkPermissionsSync({
        perm: "sachbericht.view",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungWfPermissionPruefberichtView() {
      return this.checkPermissionsSync({
        perm: "pruefbericht.view",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasPermissionAuszahlungVersionenManuellErstellen() {
      return this.checkPermissionsSync({
        perm: "auszahlung.versionen_manuell_erstellen",
      });
    },

    hasAuszahlungWfPermissionRechtsformdokumenteAnsehen() {
      return this.checkPermissionsSync({
        perm: "auszahlung.rechtsformdokumente.view",
        permArray: this.auszahlung.user_permissions,
      });
    },

    hasAuszahlungKoFiDownloadPermission() {
      return this.checkPermissionsSync({
        perm: "auszahlung.kofi_download",
        permArray: this.auszahlung.user_permissions,
      });
    },

    astAlsButtonConfig() {
      return this.getSyConfigsValue("ast_als_button", {});
    },

    textTranslateForChangeView() {
      return this.viewName === "timeline" ?
        "_BTN_AUSZAHLUNG_ANZEIGEN_{{name}}_" :
        "_BTN_VERLAUF_ANZEIGEN_";
    },

    iconForChangeView() {
      return this.viewName === "timeline" ? "euro" : "time";
    },

    isEinreichenMessageTranslated() {
      return !!gettext(this.einreichenMessage);
    },

    auszahlungAktuelleAufgabeBez() {
      return get(this.auszahlung, "aktuelle_aufgabe.bez");
    },

    isBankverbindungVisible() {
      return this.auszahlungConfig.bv_relevant !== false;
    },

    auszahlungConfig() {
      return this.getSyConfigsValue("auszahlung", {});
    },

    isVertretungsberechtigteVisible() {
      // TODO: Wir müssen das mit Aplikationskontexte ändern
      return this.$Client !== "dias-kumasta" && this.hasAuszahlungWfPermissionAuszahlungVertreterView;
    },

    isNotizenForDokumenteRubrikVisible() {
      return this.statusShowDokumenteRubrik && this.hasAuszahlungWfPermissionDokumentView;
    },

    workflowTypeDefault() {
      return get(this.auszahlung, "aktuelle_aufgabe.workflowstatusgruppe.workflowtyp");
    },

    aktuelleAufgabeTaskId() {
      return get(this.auszahlung, "aktuelle_aufgabe.task_id");
    },

    isSnapshot() {
      return !!this.snapshot;
    },

    diffsForRubrics() {
      const DIFFS = {};
      if (this.isSnapshot && !isEmpty(this.STATUS_DIFF_FOR_REGELS)) {
        forEach(this.stepsFiltered, step => {
          if (this.rubricsIdsMapping[step.id]) {
            forEach(this.rubricsIdsMapping[step.id], id => {
              if (this.STATUS_DIFF_FOR_REGELS[id]) {
                DIFFS[step.key] = true;
                return false;
              }
            });
          }
        });
      }
      return DIFFS;
    },

    isVertragsKontoVisible() {
      return this.isEndabrechnung && this.isVertragsKontoVisibleFromSyConfig && this.kontoComponentExists && !this.hasSimpleVertragPermission;
    },

    isVertragsKontoVisibleFromSyConfig() {
      return this.instancePagesConfig.vertragskonto;
    },

    isVertragsBelegVisible() {
      return this.instancePagesConfig.vertragsbeleg;
    },

    instancePagesConfig() {
      return this.getSyConfigsValue("instance_pages", {});
    },

    kontoComponentExists() {
      return this.vertragsKonto && this.vertragsKonto.client_komponente;
    },

    hasSimpleVertragPermission() {
      return this.checkPermissionsSync({
        perm: "vertrag.einfach",
      });
    },

    canViewBelege() {
      const HAS_PERMISSION = this.hasPermissionVertragskostenpositionsbelegeView;
      if (HAS_PERMISSION &&
          this.belegeAstorgrollenFromSyConfig &&
          this.belegeAngeboteFromSyConfig &&
          this.userAstorgKontext) {
        return this.kontextAstOrgRolleIndexNotFound || this.vertragAstorgAngeboteNotFound;
      }
      return HAS_PERMISSION;
    },

    belegeAstorgrollenFromSyConfig() {
      return this.belegeSichtbarkeitConfig.astorgrollen;
    },

    belegeAngeboteFromSyConfig() {
      return this.belegeSichtbarkeitConfig.astorgangebote;
    },

    vertragAstorgAngeboteNotFound() {
      return findIndex(this.belegeAngeboteFromSyConfig, foe_id => foe_id === this.vertrag.foe_id) === -1;
    },

    kontextAstOrgRolleIndexNotFound() {
      return findIndex(this.belegeAstorgrollenFromSyConfig, r => this.userAstorgKontext.rollen.indexOf(r) !== -1);
    },

    userAstorgKontext() {
      return find(this.me.kontexte, (k => k.org_id === get(this.vertrag, "antragstellerorg.pk")));
    },

    belegeSichtbarkeitConfig() {
      return this.getSyConfigsValue("belege_sichtbarkeit", {});
    },

    isEndabrechnung() {
      return this.wfKontext && this.wfKontext.id === KWorkflowtypUUIDs.endabrechnung;
    },

    ...mapState([
      "STATUS_DIFF_FOR_REGELS",
    ]),
  },
  created() {
    this.loadData().then(
      () => this.openAufgabenKontextPanel()
    );
  },
  beforeUnmount() {
    this.MUT_RESET_STATUS_DIFF();
  },
  methods: {
    loadData() {
      const PROMISE = new Promise((resolve, reject) => {
        this.loadVertrag().then(
          () => {
            Promise.all([
              this.checkPermissionCreateBeleg(),
              this.loadAntrag(),
              this.updateKoFiModule(),
              this.loadAuszahlung({ statusSetViewName: true }).finally(
                () => this.loadAuszahlungsplan()
              ),
              this.loadAntragstellerorg(),
            ]).then(
              () => {},
              () => reject()
            ).finally(resolve);
          }
        );
      });
      return PROMISE;
    },

    loadVertrag() {
      return this.getHttp({
        url: this.vertragUrl,
      }).then(
        response => {
          this.vertrag = response;
          this.loadVertragspersonalstellen();
          this.checkFibuPermission();
        }
      ).finally(
        () => this.loading.vertrag = false
      );
    },

    loadVertragspersonalstellen() {
      if (!this.hasPermissionVertragskostenpersonalstellenView) {
        this.isPersonalBaumVisible = false;
        return;
      }
      this.getListHttp({
        url: this.vertragspersonalstellenUrl,
        urlParams: {
          limit: 1,
        },
      }).then(
        response => {
          this.isPersonalBaumVisible = response.length > 0;
        }
      );
    },

    checkFibuPermission() {
      if (!this.canViewBelege || ! this.isVertragsBelegVisible) {
        return;
      }
      this.getHttp({
        url: this.vertragFibuPermissionUrl
      }).then(
        response => {
          if (response.status) {
            this.loadVertragsKonto();
          }
        }
      );
    },

    loadVertragsKonto() {
      this.getHttp({
        url: this.vertragKontoUrl
      }).then(
        response => this.vertragsKonto = response
      );
    },

    checkPermissionCreateBeleg() {
      this.canCreateBeleg = false;
      this.getHttp({
        url: this.vertragCheckMyPermissionsUrl,
        urlParams: {
          permissions: ["vertragskostenpositionsbelege.create"],
        },
      }).then(
        response => {
          this.canCreateBeleg = response.length > 0;
        }
      );
    },

    loadAntrag() {
      this.getHttp({
        url: this.antragUrl,
      }).then(
        response => {
          this.antrag = response;
        }
      ).finally(
        () => {
          this.loading.antrag = false;
        }
      );
    },

    updateKoFiModule() {
      this.loading.kofimodule = true;
      return this.getHttp({
        url: this.antragModuleUrl,
        urlParams: {
          anzeigekontext: [
            FoerderangebotAnzeigekontextUUIDs.finanzierung,
            FoerderangebotAnzeigekontextUUIDs.kosten
          ],
          objekttyp: "antrag",
        }
      }).then(
        response => {
          this.groupModule(response);
        }
      ).finally(
        () => this.loading.kofimodule = false
      );
    },

    groupModule(module) {
      const KOSTEN_MODULE = [];
      const FINANZIERUNGS_MODULE = [];
      forEach(module, modul => {
        if (modul.modulgruppen.indexOf("kosten") !== -1) {
          KOSTEN_MODULE.push(modul);
        }
        if (modul.modulgruppen.indexOf("finanzierung") !== -1) {
          FINANZIERUNGS_MODULE.push(modul);
        }
      });
      this.kostenModule = KOSTEN_MODULE;
      this.finanzierungsModule = FINANZIERUNGS_MODULE;
    },

    loadAuszahlung({ statusSetViewName, statusShowLoading = true } = {}) {
      this.reloading = statusShowLoading;
      return this.getHttp({
        url: this.auszahlungUrl,
      }).then(
        response => {
          this.auszahlung = response;
          if (statusSetViewName) {
            this.setViewName();
          }
          this.loadWorkflowTyp();
          this.loadAllAuszahlungenForVertrag();
        },
        error => {
          if (error.status === 404) {
            this.$goTo("root.home");
          }
        }
      ).finally(
        () => {
          this.loading.auszahlung = false;
          this.reloading = false;
        }
      );
    },

    setViewName() {
      if (this.canSeeWizard) {
        this.viewName = "wizard";
      } else {
        this.viewName = "details";
      }
    },

    loadWorkflowTyp() {
      if (this.auszahlung.aktuelle_aufgabe &&
        this.auszahlung.aktuelle_aufgabe.workflowstatusgruppe) {
        this.getHttp({
          url: "katalog/",
          urlParams: {
            key: "workflowtyp",
            pk: this.auszahlung.aktuelle_aufgabe.workflowstatusgruppe.workflowtyp,
          },
        }).then(
          response => {
            this.wfKontext = response[0];
          }
        );
      } else {
        this.wfKontext = undefined;
      }
    },

    loadAllAuszahlungenForVertrag() {
      if (this.isAllAuszahlungenForVertragLoaded) {
        return;
      }
      this.getListHttp({
        url: this.auszahlungenUrl,
        urlParams: {
          ordering: "az_nummer",
        },
      }).then(
        response => {
          this.allAuszahlungenWithoutCurrent = this.getAllAuszahlungenWithoutCurrent(response);
          this.isAllAuszahlungenForVertragLoaded = true;
        },
        error => {
          if (error.status === 404) {
            this.$goTo("root.home");
          }
        }
      );
    },

    getAllAuszahlungenWithoutCurrent(auszahlungen) {
      return filter(auszahlungen, item => {
        return item.pk !== this.auszahlung.pk;
      });
    },

    loadAuszahlungsplan() {
      return this.getHttp({
        url: this.auszahlungsplanUrl,
      }).then(
        response => {
          this.auszahlungsplan = response;
          this.reevaluateZuschuss();
          this.loadBerichte();
        }
      ).finally(
        () => this.loading.auszahlungsplan = false
      );
    },

    reevaluateZuschuss() {
      // Der Zuschuss muss nur solange ermittelt werden, solange die Auszahlung
      // noch nicht ausgezahlt ist.
      return this.getHttp({
        url: this.zuschussUrl,
      }).then(
        response => {
          this.zuschuss = response;
        }
      ).finally(
        () => this.loading.zuschuss = false
      );
    },

    loadBerichte() {
      if ((!this.modalitaetSachbericht && !this.modalitaetPruefbericht) ||
        (isNil(this.auszahlung.sachbericht) && isNil(this.auszahlung.pruefbericht))) {
        this.sbModule = [];
        this.pbModule = [];
        this.loading.bericht = false;
        return Promise.resolve();
      }
      this.loading.bericht = true;
      return this.getHttp({
        url: this.auszahlungModuleUrl,
      }).then(
        response => {
          const {
            sbModule,
            pbModule
          } = this.getGroupedModule(response);
          this.sbModule = sbModule;
          this.pbModule = pbModule;
        }
      ).finally(() => this.loading.bericht = false);
    },

    getGroupedModule(module) {
      const GROUPED_MODULE = {
        sbModule: [],
        pbModule: [],
      };
      forEach(module, modul => {
        if (!isNil(this.auszahlung.sachbericht) &&
          modul.regel.sachberichtvorlage === this.modalitaetSachbericht) {
          GROUPED_MODULE.sbModule.push(modul);
        }
        if (!isNil(this.auszahlung.pruefbericht) &&
          modul.regel.sachberichtvorlage === this.modalitaetPruefbericht) {
          GROUPED_MODULE.pbModule.push(modul);
        }
      });
      return GROUPED_MODULE;
    },

    loadAntragstellerorg() {
      return this.getHttp({
        url: this.antragstellerUrl,
      }).then(
        response => {
          this.ast = response;
        }
      ).finally(
        () => this.loading.ast = false
      );
    },

    updateAufgabe(newAufgabe) {
      const AUSZAHLUNG = cloneDeep(this.auszahlung);
      AUSZAHLUNG.aktuelle_aufgabe = Object.assign({}, AUSZAHLUNG.aktuelle_aufgabe, newAufgabe);
      this.auszahlung = AUSZAHLUNG;
    },

    onAufgabeAngenommen() {
      this.loadAuszahlung();
    },

    closeWizard() {
      this.viewName = "details";
      this.openAufgabenKontextPanel();
    },

    onLoadDokarten(response) {
      if (response && response.length) {
        this.statusShowDokumenteRubrik = true;
      }
    },

    updateAuszahlungFromChild(response, statusUpdateWorkflowfolgen) {
      this.auszahlung = Object.assign({}, this.auszahlung, response);
      if (statusUpdateWorkflowfolgen) {
        this.updateWorkflowfolgen();
      }
    },

    updateWorkflowfolgen() {
      this.lastWfUpdate = new Date();
    },

    updateAuszahlungAfterModalitaetChange() {
      return new Promise(resolve => {
        this.loadAuszahlung({ statusSetViewName: true }).then(
          () => {
            this.updateWorkflowfolgen();
            Promise.all([
              this.loadAuszahlungsplan(),
              this.loadBerichte(),
            ]).then(
              () => {
                resolve();
              }
            );
          }
        );
      });
    },

    saveCallback({ statusReloadVertrag, statusSaveCallback } = {}) {
      const CB_ACTION = {
        all: statusSaveCallback === SaveCallbackActions.RELOAD_ALL,
        kofi: statusSaveCallback === SaveCallbackActions.RELOAD_KOFI,
        wf: statusSaveCallback === SaveCallbackActions.RELOAD_WF,
      };
      if (CB_ACTION.all || CB_ACTION.kofi) {
        this.loadAuszahlung({
          statusShowLoading: !CB_ACTION.kofi
        }).then(
          () => {
            if (CB_ACTION.all) {
              this.loadAuszahlungsplan();
            }
            this.updateWorkflowfolgen();
          }
        );
        this.loading.kofimodule = true;
        this.loading.zuschuss = true;
        this.updateKoFiModule();
        if (this.usesZuschussberechnungLocal || statusReloadVertrag) {
          this.loading.vertrag = true;
          /* Bei bestimmten Konstellationen (Nachträgliches Ändern von Belegen, wenn schon ein
            Auszahlungsbetrag gesetzt ist, siehe KMS-619) können sich Werte am Vertrag
            ändern, daher muss dieser beim Speichern auch neu geladen werden.
          */
          this.loadVertrag();
          this.reevaluateZuschuss();
        } else {
          this.loading.zuschuss = false;
        }
      } else if (statusSaveCallback === SaveCallbackActions.RELOAD_WF) {
        this.updateWorkflowfolgen();
      }
    },

    updateModuleSachbericht({ response }) {
      const INDEX = findIndex(this.sbModule, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.sbModule.splice(INDEX, 1, response);
      }
    },

    updateModulePruefbericht({ response }) {
      const INDEX = findIndex(this.pbModule, ["regel.pk", response.regel.pk]);
      if (INDEX !== -1) {
        this.pbModule.splice(INDEX, 1, response);
      }
    },

    onWorkflowInit(clientFunktion) {
      this.clientFunktion = clientFunktion;
      this.loading.workflow = true;
    },

    onWorkflowCanceled() {
      this.clientFunktion = undefined;
      this.loading.workflow = false;
    },

    onWorkflowStarted(aufgabe, currentWorkflowfolge) {
      this.closeWizard();
      this.resetValidation();
      this.updateAufgabe(aufgabe);
      this.currentWorkflowfolge = currentWorkflowfolge;
    },

    onWorkflowFinish() {
      this.clientFunktion = undefined;
      this.loading.workflow = false;
      this.currentWorkflowfolge = undefined;
    },

    onWorkflowSuccess({ result } = {}) {
      if (this.clientFunktion) {
        this.clientFunktion.onServerSuccess(result);
      }
      EventBus.$emit("workflow.aufgabe.changed");
      const AZ_DATUM_EINGEREICHT = this.auszahlung.az_datum_eingereicht;
      this.checkPermissionCreateBeleg();
      if (!this.openAufgabenKontextPanel()) {
        this.scrollTop();
      }
      return this.reloadAuszahlung(AZ_DATUM_EINGEREICHT);
    },

    onWorkflowFailure({ result } = {}) {
      if (this.clientFunktion) {
        this.clientFunktion.onServerError(result);
      }
      this.validationErrorsLoading = false;
      let errorData = result.data;
      if (errorData && errorData.error_data) {
        errorData = errorData.error_data;
      }
      if (errorData && !isString(errorData)) {
        const errData = [];
        forEach(errorData, (modulErr, key) => {
          errData[key] = cloneDeep(modulErr);
          forEach(errData[key][1], _err => {
            forEach(_err.errors, (_errors, field) => {
              if (!isArray(_errors) || !isString(_errors[0])) {
                _err.errors[field] = [gettext("_MSG_BITTE_PRUEFEN_SIE_DIE_EINGABEN_")];
              }
            });
          });
        });
        this.validationErrors = errData;
      } else {
        this.validationErrors = undefined;
      }
      this.scrollTop();
      return this.loadAuszahlung();
    },

    resetValidation() {
      this.validationErrorsLoading = undefined;
      this.validationErrors = undefined;
    },

    scrollTop() {
      $(window).scrollTop(0);
    },

    resetAuszahlungsbetrag() {
      this.putHttp({
        url: this.auszahlungZuruecksetzenUrl,
      }).then(
        () => {
          this.addNotification({
            text: "_MSG_AUSZAHLUNGSBETRAG_RESET_SUCCESS_",
          });
          this.saveCallback({ statusSaveCallback: SaveCallbackActions.RELOAD_ALL });
        },
        () => this.addNotification({
          text: "_MSG_AUSZAHLUNGSBETRAG_RESET_ERROR_",
          type: "error",
        })
      );
    },

    loadDocumentPdfUebersicht() {
      this.loadDocument({
        url: this.auszahlungUebersichtPdfUrl,
      });
    },

    loadDocumentPdfAuszahlung() {
      this.loadDocument({
        url: this.auszahlungPdfUrl,
        fileName: `${ gettext("_TXT_AUSZAHLUNGSANFORDERUNG_") }_${ this.vertrag.v_nr }_${ this.auszahlung.az_nummer }.pdf`,
      });
    },

    loadDocumentPdfZahlungsanweisung() {
      this.loadDocument({
        url: this.auszahlungZahlungsanweisungUrl,
        fileName: `${ gettext("_TXT_AUSZAHLUNGSANWEISUNG_") }_${ this.vertrag.v_nr }_${ this.auszahlung.az_nummer }.pdf`,
      });
    },

    loadDocumentKoFi() {
      this.getHttp({
        url: this.auszahlungKofiDownloadUrl,
        responseType: "blob",
        fullResponse: true,
      }).then(
        response => {
          const fileName = response.headers["content-disposition"].split("filename=")[1].split(";")[0].split("\"")[1];
          fileDownload(response.data, fileName);
        },
        () => this.addNotification({
          text: "_MSG_DOCUMENT_CREATE_ERROR_",
          type: "error"
        })
      );
    },

    loadDocument({ url, fileName }) {
      this.getHttp({
        url,
        responseType: "blob",
        fullResponse: true,
      }).then(
        response => {
          if (!fileName) {
            const disposition = response.headers["content-disposition"];
            if (disposition && disposition.indexOf("attachment") !== -1) {
              const filenameRegex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i;
              const matches = filenameRegex.exec(disposition);
              if (matches !== null && matches[2]) {
                fileName = matches[2];
              }
              if (!fileName) {
                fileName = "unbenanntes_dokument";
              }
            }
          }
          fileDownload(response.data, fileName);
        },
        () => this.addNotification({
          text: "_MSG_DOCUMENT_CREATE_ERROR_",
          type: "error"
        })
      );
    },

    reloadAuszahlung(azDatumEingereicht) {
      if (get(this.currentWorkflowfolge, "wfo_reload")) {
        this.loadData().finally(
          () => {
            this.lastWfAfterWfoReload = new Date().getTime();
            EventBus.$emit("updateBeleg", { kosten: true, finanzierung: true });
            EventBus.$emit("reloadAnlagenAllgemein");
            if (!azDatumEingereicht && this.auszahlung.az_datum_eingereicht) {
              this.einreichenStatus = true;
            }
          }
        );
      }
    },

    changeBelegeStatusEingereicht() {
      this.loadingChangeAllBelege = true;
      this.putHttp({
        url: this.auszahlungKostenbelegStatusEingereichtUrl,
      }).then(
        () => {
          this.saveCallback({ statusSaveCallback: SaveCallbackActions.RELOAD_ALL });
          this.kofiErfassungReloadStatus = true;
          this.loadingChangeAllBelege = false;
          EventBus.$emit("updateBeleg", { kosten: true, finanzierung: true });
        }
      );
    },

    acceptAllBelege() {
      this.loadingChangeAllBelege = true;
      this.putHttp({
        url: this.auszahlungBelegeAnerkennenUrl,
      }).then(
        () => {
          this.saveCallback({ statusSaveCallback: SaveCallbackActions.RELOAD_ALL });
          this.kofiErfassungReloadStatus = true;
          this.loadingChangeAllBelege = false;
          EventBus.$emit("updateBeleg", { kosten: true, finanzierung: true });
        }
      );
    },

    acceptBeantragtenBetrag() {
      this.loadingChangeAllBelege = true;
      this.putHttp({
        url: this.auszahlungBetrantragtenBetragAnerkennenUrl,
      }).then(
        () => {
          this.saveCallback({ statusSaveCallback: SaveCallbackActions.RELOAD_ALL });
          this.kofiErfassungReloadStatus = true;
          this.loadingChangeAllBelege = false;
          EventBus.$emit("updateBeleg", { kosten: true, finanzierung: true });
        },
        error => {
          this.addNotification({
            text: error.data,
            type: "error",
          });
        }
      );
    },

    openWizard() {
      this.viewName = "wizard";
    },

    onValidate() {
      this.validationErrorsLoading = true;
      this.getHttp({
        url: this.auszahlungValidierenUrl,
      }).then(
        response => {
          this.validationErrors = response;
        },
        () => this.addNotification({
          text: "_ERR_AUSZAHLUNG_DETAILS_VALIDIEREN_",
          type: "error",
        })
      ).finally(() => {
        this.validationErrorsLoading = false;
        this.scrollTop();
      });
    },

    closeEinreichenMessage() {
      this.einreichenStatus = false;
    },

    changeViewName() {
      if (this.viewName === "timeline") {
        this.viewName = "details";
      } else if (this.viewName === "details") {
        this.viewName = "timeline";
      }
    },

    updateInfoprovider({ response } = {}) {
      if (isNil(this.zuschuss)) {
        this.zuschuss = {};
      }
      this.zuschuss = Object.assign({}, this.zuschuss, response || {});
    },

    createSnapshot() {
      EventBus.$emit("createSnapshot");
    },

    changeActiveSnapshot({ snapshotLocal } = {}) {
      if (snapshotLocal) {
        this.snapshotActivePk = snapshotLocal.pk;
        this.snapshot = cloneDeep(snapshotLocal);
      } else {
        if (this.snapshot) {
          this.snapshot = undefined;
        }
        this.snapshotActivePk = undefined;
      }
    },

    openAufgabenKontextPanel() {
      const PANELS = orderBy(filter(this.rubricsMapping, rubrik => {
        return this.anzeigekontexteAuszahlungOffen[rubrik.key];
      }), ["prio"]);
      if (size(PANELS) === 0 || this.viewName !== "details") {
        return false;
      }
      setTimeout(() => {
        forEach(PANELS, (panel, idx) => {
          EventBus.$emit("toggleContextPanel", { statusOpen: true, id: panel.id, withoutScroll: true, forceScroll: idx === 0 });
        });
      }, 250);
      return true;
    },

    ...mapMutations([
      "MUT_RESET_STATUS_DIFF",
    ]),
  },
};

export function usesZuschussberechnung({ auszahlungsplan }) {
  const MODUS_ZUSCHUSSBERECHNUNG = [
    "e6a564f4-65cd-40e8-b39e-27fc791bbd4d",
    "749b04d1-b699-4b55-a332-81ceb663a774",
  ];
  return auszahlungsplan &&
    ((auszahlungsplan.zp_modalitaet &&
        includes(MODUS_ZUSCHUSSBERECHNUNG, auszahlungsplan.zp_modalitaet.modus)) ||
      auszahlungsplan.is_last);
}
