"use strict";

import diasLoading from "dias/core/loading";

import WizardComponent from "./wizard.component";

const diasWizard = angular.module("dias.wizard", [
  diasLoading.name
])

  .component("diasWizard", WizardComponent);

export default diasWizard;
