import KFAuszahlungPeriodenBeleg from "../KFAuszahlungPeriodenBeleg/KFAuszahlungPeriodenBeleg.vue";
import PuxGet from "../../../../global/components/PuxGet/PuxGet.vue";

import Teleport from "../../../../global/directives/Teleport";

import KofiErfassungPositionMixin from "../../KofiErfassung/Mixins/KofiErfassungPositionMixin";

import {
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "KFAuszahlungPeriodenPosition",
  components: {
    KFAuszahlungPeriodenBeleg,
    PuxGet,
  },
  directives: {
    Teleport,
  },
  mixins: [
    KofiErfassungPositionMixin,
  ],
  inheritAttrs: false,
  props: {
    periodenFiltered: {
      type: Array,
      required: true,
    },
    periodKeyShow: {
      type: String,
      required: true,
      info: "Welche Perioden-Teil werden angezeigt",
    },
    isDiffPerioden: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      betragKeys: [
        "wert",
        "betrag_erfasst",
        "betrag_anerkannt",
      ],
    };
  },
  computed: {
    keyPositionBetragBewilligt() {
      return `${ this.prefixCurrent.position }_betrag_bewilligt`;
    },

    keyPositionBetragErfasst() {
      return `${ this.prefixCurrent.position }_betrag_erfasst`;
    },

    keyPositionBetragAnerkannt() {
      return `${ this.prefixCurrent.position }_betrag_anerkannt`;
    },

    positionBetragBewilligtCurrency() {
      return this.filterCurrency(this.position[this.keyPositionBetragBewilligt]);
    },

    positionBetragErfasstFilterView() {
      return this.filterView(this.position.erfasst, this.position[this.keyPositionBetragErfasst], this.auszahlung);
    },

    positionBetragAnerkanntFilterView() {
      return this.filterView(this.position.anerkannt, this.position[this.keyPositionBetragAnerkannt], this.auszahlung);
    },

    posPeriodenKeyByPk() {
      return keyBy(this.position.posperioden, "periode");
    },

    snapshotPeriodenKeyByPk() {
      if (this.snapshot && this.snapshot.posperioden) {
        return keyBy(this.snapshot.posperioden, "periode");
      }
      return {};
    },

    diffChangesPositionPosperioden() {
      return this.diffChangesPosition.posperioden || {};
    },
  },
};
