import KFStandard from "../../../KFDetail/KFStandard/KFStandard.vue";
import FinanzierugMixin from "../../Mixins/FinanzierugMixin";
import DvvVeranstaltungenMixin from "../DvvVeranstaltungenMixin";

// @vue/component
export default {
  name: "DvvVeranstaltungen",
  components: {
    KFStandard,
  },
  mixins: [
    FinanzierugMixin,
    DvvVeranstaltungenMixin,
  ],
  computed: {
    betragsrechner() {
      return this.modelParameter.betragsrechner || "dvv_veranstaltungsrechner";
    },

    sumLocal() {
      return this.filterCurrency(this.antragKoFiArt.summe);
    },
  },
};
