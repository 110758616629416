"use strict";

import parameterServices from "../services";
import template from "./config.jade";
import controller from "./controller";


const parametermoduleString = angular.module("dias.foerderregeln.parameter.richtext", [
  parameterServices.name,
])

  .config(function(ParametermoduleRegistryProvider) {
    ParametermoduleRegistryProvider.register("richtext", "parametermodul-richtext");
  })
  .component("parametermodulRichtext", {
    bindings: {
      parameter: "=",
      key: "@",
      controlId: "@?",
    },
    template: template(),
    controller: controller,
    controllerAs: "vm",
  })

  .component("parametermodulRichtextRo", {
    bindings: {
      parameter: "=",
      key: "@",
    },
    template: "<span ng-bind-html='vm.parameter[vm.key].wert'></span>",
    controllerAs: "vm",
  });
export default parametermoduleString;
