import {
  computed,
  toRef,
} from "vue";

import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";

// @vue/component
export default {
  name: "AnonymisierungPlanStufe",
  components: {
    PuxTranslate,
  },
  props: {
    anonymisierungsplan: {
      type: Array,
      required: true,
    },
    katalogItem: {
      type: Object,
      required: true,
    },
    stufe: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const anonymisierungsplan = toRef(props, "anonymisierungsplan");
    const katalogItem = toRef(props, "katalogItem");
    const stufe = toRef(props, "stufe");

    const labelTranslateOptions = computed(() => {
      let text = "";
      const EXTRA = {};
      const CURRENT_ANONYMISIERUNGSPLAN = anonymisierungsplan.value.find(
        anp => Number(anp.anp_stufe) === Number(stufe.value.pk) &&
          Number(anp.anon_content_type) === Number(katalogItem.value.pk)
      );
      if (!CURRENT_ANONYMISIERUNGSPLAN) {
        text = "_TXT_ANONYMISIERUNG_PLAN_NICHT_GEPLANT_";
      } else {
        if (CURRENT_ANONYMISIERUNGSPLAN.anp_frist === 1) {
          text = "_TXT_ANONYMISIERUNG_PLAN_MONAT_{{anp_frist}}_";
        } else {
          text = "_TXT_ANONYMISIERUNG_PLAN_MONATE_{{anp_frist}}_";
        }
        EXTRA.anp_frist = CURRENT_ANONYMISIERUNGSPLAN.anp_frist;
      }

      return {
        text,
        extra: EXTRA,
      };
    });

    return {
      labelTranslateOptions,
    };
  },
};
