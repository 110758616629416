"use strict";

import angular from "angular";
import "restangular";

import Betragsrechner from "./betragsrechner/";
import uebersicht from "./uebersicht";

const kostenFinanzierungModelsModule = angular.module("dias.mdm_kosten_finanzierung", [
  Betragsrechner.name,
  uebersicht.name
])
;
export default kostenFinanzierungModelsModule;
