import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";
import PuxIcon from "../../../../../../global/components/PuxIcon/PuxIcon.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../../../../global/mixins/HttpMixin";
import ModuleMappingMixin from "../../../../../../global/mixins/ModuleMappingMixin";

import FiltersAPI from "../../../../../../global/compositionAPI/FiltersAPI";

import {
  EventBus,
} from "../../../../../../global/functions/event-bus";
import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "DokumenteSuchenWizardStep3TableDetails",
  components: {
    PuxButton,
    PuxIcon,
    PuxTranslate,
  },
  mixins: [
    HttpMixin,
    ModuleMappingMixin,
  ],
  props: {
    data: {
      type: Object,
      required: true,
    },
    modelKontext: {
      type: String,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
    replace: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const {
      filterDate,
      filterDefaultForEmpty,
      filterFileSize,
    } = FiltersAPI();

    return {
      filterDate,
      filterDefaultForEmpty,
      filterFileSize,
    };
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    getPreview() {
      return `/api/${ this.getModuleApi({ model: this.data.link_to_obj.model }) }${ this.data.link_to_obj.pk }/preview/${ this.data.pk }/`;
    },
  },
  methods: {
    createPreview() {
      this.loading = true;
      this.postHttp({
        url: `${ this.getModuleApi({ model: this.data.link_to_obj.model }) }${ this.data.link_to_obj.pk }/dokumente/${ this.data.pk }/create_preview/`
      }).then(
        response => {
          if (response && response.dokument && response.dokument.dok_preview) {
            const NEW_ROW = cloneDeep(this.data);
            NEW_ROW.dokument = response.dokument;
            EventBus.$emit(`updateRow${ this.tableId }`, ({ row: NEW_ROW, index: this.rowIndex }));
          }
          this.loading = false;
        }
      );
    },
  },
};
