"use strict";

function changeFormat(value) {
  const valueStr = "" + value;
  const zeroPos = valueStr.indexOf(".");
  if (zeroPos !== -1) {
    let str = valueStr.slice(zeroPos);

    str = str.split("");
    let statusNull = false;
    for (let i = str.length - 1; i >= 0; i--) {
      if (!statusNull && str[i] === "0") {
        str.splice(i, 1);
      } else {
        if (str[i] === ".") {
          if (!statusNull) {
            str.splice(i, 1);
            break;
          }
          str[i] = ",";
        }
        statusNull = true;
      }
    }
    str = str.join("");
    value = valueStr.slice(0, zeroPos) + str;
  }

  return value;
}

export default () => value => {
  if (value === undefined || !angular.isNumber(value)) {
    return value;
  }
  const mio = 1000000;
  const tsd = 1000;

  if (value >= mio) {
    value /= mio;
    value = changeFormat(value.toFixed(1));
    return value + " Mio €";
  }
  if (value >= tsd) {
    value /= tsd;
    value = changeFormat(value.toFixed(1));
    return value + " Tsd €";
  }
  return changeFormat(value.toFixed(0)) + " €";
};

