import translate from "../../../../../../global/directives/translate";
import NotificationMixin from "../../../../../../global/mixins/NotificationMixin";
import {
  assign,
  isNil,
  toLower,
  forEach,
  cloneDeep,
  isUndefined,
  size,
  filter,
} from "lodash-es";
import PuxButton from "../../../../../../global/components/PuxButton/PuxButton.vue";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    NotificationMixin,
  ],
  props: {
    model: {
      type: Array,
      required: true,
    },
    currentModel: {
      type: Object,
      default: undefined,
    },
    currentIndex: {
      type: Number,
      default: undefined,
    },
    currentParentIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modelLocal: {
        label: null,
        id: null,
        value: undefined,
        oberpunkt: -1,
        pflicht: false,
        radio: false,
        freitext: null,
        count: 20,
        freitextpflicht: false,
      },
      options: {
        showCloseButton: true,
        required: true,
      },
      labelOptions: {
        type: "text",
        id: "label",
        label: "_LBL_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_ANTWORT_",
        required: true,
        view: "v",
        change: this.changeAntwort,
      },
      idOptions: {
        type: "text",
        id: "id",
        label: "_LBL_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_TECHNISCHE_ID_",
        required: true,
        view: "v",
        change: this.changeId,
      },
      valueOptions: {
        type: "text",
        id: "value",
        label: "_LBL_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_WERT_",
        required: false,
        view: "v",
      },
      pflichtOptions: {
        type: "boolean",
        id: "pflicht",
        label: "_LBL_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_PFLICHT_ANTWORT_",
        required: true,
        view: "v-alt",
      },
      radioOptions: {
        type: "boolean",
        id: "radio",
        label: "_LBL_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_MIT_EINGABEMOEGLICHKEIT_",
        required: true,
        view: "v-alt",
      },
      freitextOptions: {
        type: "text",
        id: "freitext",
        label: "_LBL_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_MIT_EINGABEFELD_LABEL_",
        required: true,
        view: "v",
      },
      countOptions: {
        type: "number",
        id: "count",
        label: "_LBL_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_MIT_EINGABEFELD_MAX_ANZ_",
        required: true,
        view: "v",
      },
      freitextPflichtOptions: {
        type: "boolean",
        id: "freitextpflicht",
        label: "_LBL_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_MIT_EINGABEFELD_PFLICHT_",
        required: true,
        view: "v-alt",
      },
    };
  },
  computed: {
    header() {
      return this.currentModel ? "_TXT_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_MODAL_TITLE_EDIT_" : "_TXT_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_MODAL_TITLE_CREATE_";
    },

    dataForOberpunkt() {
      const DATA = [];
      forEach(
        filter(this.model, el => !this.currentModel || el.id !== this.currentModel.id || el.label !== this.currentModel.label),
        (item, index) => {
          DATA.push({
            value: index,
            label: item.label,
          });
        }
      );
      return DATA;
    },

    oberpunktOptions() {
      return {
        type: "select",
        id: "oberpunkt",
        label: "_LBL_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_MODAL_PARENT_",
        required: true,
        keyLabel: "label",
        keyId: "value",
        view: "v",
        emptyValue: -1,
        emptyLabel: "_LBL_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_MODAL_OPTION_OBERPUNKT_",
        data: this.dataForOberpunkt,
        deselect: false,
      };
    },

    isDisabledSaveButton() {
      return !this.modelLocal.label ||
        !this.modelLocal.id ||
        (this.modelLocal.radio && (!this.modelLocal.freitext || !this.modelLocal.count));
    },

    isAltBoxParent() {
      return !isUndefined(this.currentParentIndex) && isUndefined(this.currentIndex);
    },

    isNewBoxParent() {
      return this.modelLocal.oberpunkt === -1;
    },
  },
  beforeCreate() {
    this.$options.components.FormElement = require("../../../../../../global/components/FormElement/FormElement.vue").default;
    this.$options.components.Modal = require("../../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.currentModel) {
        const MODEL_LOCAL = assign({}, this.modelLocal, this.currentModel);
        if (MODEL_LOCAL.freitext) {
          MODEL_LOCAL.radio = true;
        }
        MODEL_LOCAL.oberpunkt = !isUndefined(this.currentIndex) ? this.currentParentIndex : -1;
        this.modelLocal = MODEL_LOCAL;
      }
    },

    changeAntwort() {
      if (isNil(this.currentModel)) {
        this.modelLocal.id = toLower(this.modelLocal.label).replace(/[^a-zA-Z0-9]/g, "");
      }
    },

    changeId() {
      this.modelLocal.id = toLower(this.modelLocal.id).replace(/[^a-zA-Z0-9]/g, "");
    },

    save() {
      if (this.isDisabledSaveButton || !this.checkAntwortAndId() || !this.checkTiefe()) {
        return;
      }
      const DATA = {
        label: this.modelLocal.label,
        id: this.modelLocal.id,
        pflicht: this.modelLocal.pflicht,
      };
      if (this.modelLocal.value) {
        DATA.value = this.modelLocal.value;
      }
      if (this.modelLocal.radio) {
        DATA.freitext = this.modelLocal.freitext;
        DATA.count = this.modelLocal.count;
        DATA.freitextpflicht = this.modelLocal.freitextpflicht;
      }
      if (this.currentModel) {
        this.update({ data: DATA });
      } else {
        this.create({ data: DATA });
      }
    },

    create({ data }) {
      const MODEL = cloneDeep(this.model) || [];
      if (this.modelLocal.oberpunkt === -1) {
        data.children = [];
        MODEL.push(data);
      } else {
        if (MODEL[this.modelLocal.oberpunkt] && MODEL[this.modelLocal.oberpunkt].children) {
          MODEL[this.modelLocal.oberpunkt].children.push(data);
        } else {
          MODEL[this.modelLocal.oberpunkt].children = [data];
        }
      }
      this.close({ model: MODEL });
    },

    update({ data }) {
      let model = cloneDeep(this.model) || [];
      if (this.isAltBoxParent && this.isNewBoxParent) {
        model = this.updateBoxParentToParent({ data, model });
      } else if (this.isAltBoxParent && !this.isNewBoxParent) {
        model = this.updateBoxParentToChild({ data, model });
      } else if (!this.isAltBoxParent && !this.isNewBoxParent) {
        model = this.updateBoxChildToChild({ data, model });
      } else {
        model = this.updateBoxChildToParent({ data, model });
      }
      this.close({ model });
    },

    updateBoxParentToParent({ data, model }) {
      data.children = this.currentModel.children || [];
      model.splice(this.currentParentIndex, 1, data);
      return model;
    },

    updateBoxParentToChild({ data, model }) {
      model.splice(this.currentParentIndex, 1);
      model[this.modelLocal.oberpunkt].children = model[this.modelLocal.oberpunkt].children || [];
      model[this.modelLocal.oberpunkt].children.push(data);
      return model;
    },

    updateBoxChildToChild({ data, model }) {
      if (this.modelLocal.oberpunkt === this.currentParentIndex) {
        model[this.modelLocal.oberpunkt].children.splice(this.currentIndex, 1, data);
      } else {
        model[this.currentParentIndex].children.splice(this.currentIndex, 1);
        model[this.modelLocal.oberpunkt].children = model[this.modelLocal.oberpunkt].children || [];
        model[this.modelLocal.oberpunkt].children.push(data);
      }
      return model;
    },

    updateBoxChildToParent({ data, model }) {
      model[this.currentParentIndex].children.splice(this.currentIndex, 1);
      data.children = [];
      model.push(data);
      return model;
    },

    checkAntwortAndId() {
      let statusError = false;
      const CURRENT_PARENT_INDEX = isUndefined(this.currentParentIndex) ? -1 : this.currentParentIndex;
      const CURRENT_INDEX = isUndefined(this.currentIndex) ? -1 : this.currentIndex;
      forEach(this.model, (item, parentIndex) => {
        if (item.label === this.modelLocal.label &&
          (parentIndex !== CURRENT_PARENT_INDEX || CURRENT_INDEX !== -1)) {
          this.addNotification({
            text: `_MSG_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_MODAL_LABEL_UNIQUE_ERROR_{{label}}_`,
            extra: { label: item.label },
            type: "error",
          });
          statusError = true;
          return;
        }
        if (item.id === this.modelLocal.id &&
          (parentIndex !== CURRENT_PARENT_INDEX || CURRENT_INDEX !== -1)) {
          this.addNotification({
            text: `_MSG_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_MODAL_ID_UNIQUE_ERROR_{{id}}_`,
            extra: { id: item.id },
            type: "error",
          });
          statusError = true;
          return;
        }
        if (item.children && item.children.length) {
          forEach(item.children, (child, childIndex) => {
            if (child.label === this.modelLocal.label &&
              parentIndex !== CURRENT_PARENT_INDEX &&
              childIndex !== CURRENT_INDEX) {
              this.addNotification({
                text: `_MSG_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_MODAL_LABEL_UNIQUE_ERROR_{{label}}_`,
                extra: { label: child.label },
                type: "error",
              });
              statusError = true;
              return;
            }
            if (child.id === this.modelLocal.id &&
              parentIndex !== CURRENT_PARENT_INDEX &&
              childIndex !== CURRENT_INDEX) {
              this.addNotification({
                text: `_MSG_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_MODAL_ID_UNIQUE_ERROR_{{id}}_`,
                extra: { id: child.id },
                type: "error",
              });
              statusError = true;
              return;
            }
          });
        }
      });
      return !statusError;
    },

    checkTiefe() {
      if (this.currentModel && isUndefined(this.currentIndex) && this.modelLocal.oberpunkt !== -1) {
        if (size(this.model[this.currentParentIndex].children) > 0) {
          this.addNotification({
            text: `_MSG_GR_DYNAMISCHE_MEHRFACHAUSWAHL_CONFIG_MODAL_UNTERPUNKT_MIT_UNTERPUNKTEN_ERROR_`,
            type: "error",
          });
          return false;
        }
      }
      return true;
    },
  },
};
