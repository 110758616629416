"use strict";

import BaseEditController from "dias/ui/formsteps/formstepBaseEdit.controller.js";

class RegelsatzVersionEditController extends BaseEditController {
  /*@ngInject*/
  constructor($q, $scope, DiasNotification, i18n) {
    super($q, $scope, DiasNotification);
    this.DiasNotification = DiasNotification;
    this.gettext = i18n.gettext;
    this.errorsLabel = {
      ars_suchbegriffe: `${ this.gettext("Suchbegriffe") }: `,
      ars_astbez: `${ this.gettext("Info für Antragsteller") }: `,
      ars_foerderfinder_sichtbar: `${ this.gettext("Sichtbar im Förderfinder") }: `,
      ars_foerderfinder_kurz: `${ this.gettext("Kurztext für Förderfinder") }: `,
      ars_foerderfinder_lang: `${ this.gettext("Langtext für Förderfinder") }: `,
      ars_prio: `${ this.gettext("Sortierreihenfolge") }: `,
      ars_ff_eigenmittel_bez: `${ this.gettext("_LBL_ANGEBOTS_VERSIONS_INFORMATION_EIGENMITTEL_BEZ_") }: `,
      ars_ff_eigenmittel_kbez: `${ this.gettext("_LBL_ANGEBOTS_VERSIONS_INFORMATION_EIGENMITTEL_KBEZ_") }: `,
      ars_ff_foerderfaehigekosten_bez: `${ this.gettext("_LBL_ANGEBOTS_VERSIONS_INFORMATION_FOERDERFAEHIGE_KOSTEN_BEZ_") }: `,
      ars_ff_foerderfaehigekosten_kbez: `${ this.gettext("_LBL_ANGEBOTS_VERSIONS_INFORMATION_FOERDERFAEHIGE_KOSTEN_KBEZ_") }: `,
      ars_ff_foerderhoehe_bez: `${ this.gettext("_LBL_ANGEBOTS_VERSIONS_INFORMATION_FOERDERHOEHE_BEZ_") }: `,
      ars_ff_foerderhoehe_kbez: `${ this.gettext("_LBL_ANGEBOTS_VERSIONS_INFORMATION_FOERDERHOEHE_KBEZ_") }: `,
      ars_ff_zeitraum_bez: `${ this.gettext("_LBL_ANGEBOTS_VERSIONS_INFORMATION_ZEITRAUM_BEZ_") }: `,
      ars_ff_zeitraum_kbez: `${ this.gettext("_LBL_ANGEBOTS_VERSIONS_INFORMATION_ZEITRAUM_KBEZ_") }: `,
    };
    this.errorContainerId = "errors_edit_" + Math.random().toString(36).slice(2);

    this.save = () => {
      const defer = $q.defer();
      const data = {
        ars_suchbegriffe: this.model.ars_suchbegriffe,
        ars_astbez: this.model.ars_astbez,
        ars_foerderfinder_sichtbar: this.model.ars_foerderfinder_sichtbar,
        ars_foerderfinder_kurz: this.model.ars_foerderfinder_kurz,
        ars_foerderfinder_lang: this.model.ars_foerderfinder_lang,
        ars_prio: this.model.ars_prio,
        ars_ff_eigenmittel_bez: this.model.ars_ff_eigenmittel_bez,
        ars_ff_eigenmittel_kbez: this.model.ars_ff_eigenmittel_kbez,
        ars_ff_foerderfaehigekosten_bez: this.model.ars_ff_foerderfaehigekosten_bez,
        ars_ff_foerderfaehigekosten_kbez: this.model.ars_ff_foerderfaehigekosten_kbez,
        ars_ff_foerderhoehe_bez: this.model.ars_ff_foerderhoehe_bez,
        ars_ff_foerderhoehe_kbez: this.model.ars_ff_foerderhoehe_kbez,
        ars_ff_zeitraum_bez: this.model.ars_ff_zeitraum_bez,
        ars_ff_zeitraum_kbez: this.model.ars_ff_zeitraum_kbez,
      };

      const promise = this.model.customPUT(data, "update_info");
      promise.then(
        response => {
          this.$scope.model.merge(this.$scope.model, this.model);
          this.DiasNotification.page.success("Änderungen gespeichert.");
          defer.resolve(response);
        },
        errors => {
          defer.reject();
          return this.onErrors(errors, this.errorsLabel);
        }
      );
      return defer.promise;
    };
  }
}

export default RegelsatzVersionEditController;
