import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import Favoriten from "../../../../components/Favoriten/Favoriten.vue";
import GenericListSlot from "./GenericListSlot/GenericListSlot.vue";
import NutzerschlagwortBadge from "../../../../components/Nutzerschlagwort/NutzerschlagwortBadge/NutzerschlagwortBadge.vue";
import PuxPageDetails from "../../../../global/components/PuxPageDetails/PuxPageDetails.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import Sichtbarkeit from "../../../../components/Sichtbarkeit/Sichtbarkeit.vue";
import SmartTable from "../../../../global/components/table/table.vue";
import Spaced from "../../../../global/components/Spaced/Spaced.vue";

import DynamicRootForComponentsMixin from "../../../DynamicRoot/DynamicRootForComponentsMixin";

import FiltersAPI from "../../../../global/compositionAPI/FiltersAPI";

import { EventBus } from "../../../../global/functions/event-bus";
import {
  render_ffk_columns,
} from "../../../../global/components/table/TableColsUndFiltersFromSyConfig";


const TABLE_ID = "BerichtLiegezeiten";

// @vue/component
export default {
  name: "BerichtLiegezeiten",
  components: {
    AngularLink,
    Favoriten,
    GenericListSlot,
    NutzerschlagwortBadge,
    PuxPageDetails,
    PuxTranslate,
    Sichtbarkeit,
    Spaced,
    SmartTable,
  },
  mixins: [
    DynamicRootForComponentsMixin
  ],
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      filterCurrency,
      filterDate,
      filterLimitTo,
      filterSpaced,
    } = FiltersAPI();

    return {
      filterCurrency,
      filterDate,
      filterLimitTo,
      filterSpaced,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        url: this.initUrl({ tableId: TABLE_ID }),
        label: this.report.tab_kbez,
        sortable: true,
        pagination: true,
        details: false,
        showHeader: false,
        view: {
          filter: "top",
        },
        template: {
          obj_url: "tableau",
          obj_id: this.report.pk
        }
      },
      liegezeitDurchschnitt: undefined,
      render_ffk_columns: render_ffk_columns,
    };
  },
  methods: {
    loadDataFromTable({ response }) {
      this.liegezeitDurchschnitt = response.liegezeit_durchschnitt;
    },

    renderFoerderorg({ row, typ, field }) {
      let result = "";
      if (row[field] && row[field].length > 0) {
        result = row[field].filter(item => item.o_otid === typ).map(item => item.o_kbez).join(", ");
      }
      return result;
    },

    gettextStatusgruppe() {
      return row => this.$gettext(row.statusgruppe);
    },

    onCallbackFavoriten({ rowIndex, data }) {
      EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: data, index: rowIndex }));
    },

    onCallbackSichtbarkeit({ rowIndex, data }) {
      EventBus.$emit(`updateRow${ TABLE_ID }`, ({ row: data, index: rowIndex }));
    },
  },
};
