import ModuleAntragslistenUebersichtMain from "./Module/ModuleAntragslistenUebersichtMain/ModuleAntragslistenUebersichtMain.vue";
import ModuleAutomatischeAntragslisteMain from "./Module/ModuleAutomatischeAntragslisteMain/ModuleAutomatischeAntragslisteMain.vue";
import ModuleAutomatischeAntragslisteMittellosMain from "./Module/ModuleAutomatischeAntragslisteMittellosMain/ModuleAutomatischeAntragslisteMittellosMain.vue";
import ModuleTopProtokollMain from "./Module/ModuleTopProtokollMain/ModuleTopProtokollMain.vue";

import SitzungModuleMapping from "./SitzungModuleMapping";

// @vue/component
export default {
  name: "SitzungModule",
  components: {
    ModuleAntragslistenUebersichtMain,
    ModuleAutomatischeAntragslisteMain,
    ModuleAutomatischeAntragslisteMittellosMain,
    ModuleTopProtokollMain,
  },
  props: {
    moduleUrl: {
      type: String,
      required: true,
    },
    module: {
      type: Object,
      required: true,
    },
    tagesordnungspunkt: {
      type: Object,
      required: true,
    },
    permissionSrc: {
      type: Array,
      required: true,
    },
  },
  computed: {
    modulname() {
      return this.module.modulname;
    },

    currentComponent() {
      const CURRENT_COMPONENT = SitzungModuleMapping.main[this.modulname];
      if (CURRENT_COMPONENT) {
        return CURRENT_COMPONENT;
      }
      console.error(`Angegebene Eigenschaft ${ this.modulname } am Scope hat keinen Modulnamen.`);
      return "div";
    },

    currentModuleUrl() {
      return `${ this.moduleUrl }${ this.module.modul.pk }/`;
    },
  },
};
