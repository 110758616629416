import {
  FilterCurrencyMixin,
} from "../../../../global/mixins/FiltersMixin";

// @vue/component
export default {
  name: "ZuschussMeldung",
  mixins: [
    FilterCurrencyMixin,
  ],
  props: {
    meldung: {
      type: Array,
      required: true,
    },
    meldungIndex: {
      type: Number,
      required: true,
    },
    snapshotZuschussDetailMeldung: {
      type: Array,
      required: false,
      default: () => [],
    },
    statusKFDiff: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    money() {
      return this.filterCurrency(this.meldung[1] || 0);
    },

    moneySnapshot() {
      return this.filterCurrency(this.snapshotZuschussDetailMeldung[1] || 0);
    },
  },
};
