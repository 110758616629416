"use strict";

import BaseModel from "dias/core/models/baseModel";


class FoerderorgInfo extends BaseModel {
  constructor() {
    super("/info/foerderorganisationen/");
  }

  extendCollection(collection) {
    collection = super.extendCollection(collection);

    collection.getExtendList = () => params => collection.getList(Object.assign(params, { fields: ["pk", "o_name", "typ_name", "o_konzept_titel", "kategoriewerte"] }));

    return collection;
  }
}

export default FoerderorgInfo;
