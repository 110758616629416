import AngularLink from "../../../../global/components/AngularLink/AngularLink.vue";
import IjfTemplateCreateModal from "./IjfTemplateCreateModal/IjfTemplateCreateModal.vue";
import PuxCloak from "../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SmartTable from "../../../../global/components/table/table.vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

import {
  maxBy,
} from "lodash-es";

const TABLE_ID = "IjfTemplateVersionen";

// @vue/component
export default {
  name: "IjfTemplateList",
  components: {
    AngularLink,
    IjfTemplateCreateModal,
    PuxCloak,
    PuxTranslate,
    SmartTable,
  },
  setup() {
    const {
      getHttp,
    } = HttpAPI();

    return {
      getHttp,
    };
  },
  data() {
    return {
      tableId: TABLE_ID,
      options: {
        id: TABLE_ID,
        label: "_TXT_IJF_TEMPLATE_LIST_TABLE_TITLE_",
        url: "ijf_vorlage/",
        cols: [
          {
            label: "_TXT_IJF_TEMPLATE_LIST_LABEL_NAME_",
            id: "ijv_kbez",
            sortable: true,
          },
        ],
        rowActions: [
          {
            label: "_TXT_IJF_TEMPLATE_LIST_LABEL_SHOW_DETAILS_",
            href: ({ item }) => this.getDetailsLink({ item }),
            isHidden: ({ item }) => !item.versionen.length,
          },
        ],
        actions: [
          {
            label: "_TXT_IJF_TEMPLATE_LIST_LABEL_CREATE_TEMPLATE_",
            callback: () => this.showCreateModal = true,
            type: "button",
            className: "btn btn-primary",
            icon: "glyphicon-plus",
            htmlId: "angebot_create",
          },
        ],
        sortable: true,
        pagination: true,
        details: true,
        view: {
          filter: "top",
        },
      },
      promise: null,
      ijfData: null,
      showCreateModal: false,
      filters: [
        {
          type: "text",
          id: "suche",
          label: "_LBL_IJF_TEMPLATE_LIST_SEARCH_",
          main: true,
        },
      ]
    };
  },
  computed: {
    getSlotNameTableDetails() {
      return `${ this.tableId }_table_details`;
    },
  },
  methods: {
    getDetailsLink({ item }) {
      const lastVersion = maxBy(item.versionen, "ivv_version");
      return `/ijf_template_ver/${ lastVersion.pk }/`;
    },
  },
};
