"use strict";

import "restangular";
import angular from "angular";

import Anzeigekontextstatus from "./anzeigekontextstatus.service";
import Foerderangebote from "./foerderangebote.service";
import Foerderangebotstatus from "./foerderangebotstatus.service";
import Foerderinstrumente from "./foerderinstrumente.service";
import Foerderinstrumentvarianten from "./foerderinstrumentvarianten.service";
import Foerderprogramme from "./foerderprogramme.service";
import FoerderprogrammFoerderangebote from "./foerderprogrammFoerderangebote.service";
import RegelsatzAktiv from "./regelsaetze.aktiv.service";
import Regelsaetze from "./regelsaetze.service";
import RegelsatzVorlagen from "./regelsatzvorlagen.service";

const modelsModule = angular.module("foerderangebote.models", [
  "restangular",
])

  .provider("Anzeigekontextstatus", Anzeigekontextstatus)
  .provider("Foerderangebote", Foerderangebote)
  .provider("Foerderangebotstatus", Foerderangebotstatus)
  .provider("Foerderangebotinstrumente", Foerderinstrumente)
  .provider("Foerderangebotinstrumentvarianten", Foerderinstrumentvarianten)
  .provider("Foerderprogramme", Foerderprogramme)
  .provider("FoerderprogrammFoerderangebote", FoerderprogrammFoerderangebote)
  .provider("RegelsatzAktiv", RegelsatzAktiv)
  .provider("Regelsaetze", Regelsaetze)
  .provider("RegelsatzVorlagen", RegelsatzVorlagen)
;

export default modelsModule;
