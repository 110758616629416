"use strict";

import BetragsrechnerBase from "../betragsrechner.base";

class RolliplaetzeRechner extends BetragsrechnerBase {
  /*@ngInject*/
  constructor($scope, kontext) {
    super($scope, kontext);
    this.name = "rolliplaetzerechner";
    this.component_name = "rolliplaetze";
    this.labels = { anzahl_plaetze: "Anzahl Rollstuhlfahrerplätze" };
  }

  get_labelmapping() {
    return this.labels;
  }

  get_fields(params) {
    return { anzahl_plaetze: "",
             pauschale: params.pauschale,
    };
  }
}
export default RolliplaetzeRechner;
