"use strict";

import BaseModel from "dias/core/models/baseModel";


class Dokumentation extends BaseModel {
  constructor() {
    super("dokumentation", {});
  }
}

export default Dokumentation;
