import {
  computed,
  getCurrentInstance,
  ref,
} from "vue";

import HttpAPI from "../../../../global/compositionAPI/HttpAPI";

export default function AntragAntragstellerRegelnAPI({ antrag }) {
  const antragstellerRegelnKbez = ref([]);
  const antragstellerUrl = computed(() => {
    return `antragstellerorganisationen/${ antrag.value.antragstellerorg }/`;
  });
  const antragstellerModuleUrl = computed(() => {
    return `${ antragstellerUrl.value }module/`;
  });
  const {
    getListHttp,
  } = HttpAPI();
  const loadAstRegeln = () => {
    antragstellerRegelnKbez.value = [];
    if (!antrag.value.antragstellerorg) {
      return;
    }
    getListHttp({
      url: antragstellerModuleUrl.value,
    }).then(
      response => {
        for (let i = 0; i < response.length; i++) {
          antragstellerRegelnKbez.value.push(response[i].regel.asr_kbez);
        }
      }
    );
  };
  const APP = getCurrentInstance();
  const $stateHref = APP.appContext.config.globalProperties.$stateHref;
  const goToModule = ({ error }) => {
    return new Promise((resolve, reject) => {
      if (!error.modul || antragstellerRegelnKbez.value.indexOf(error.modul.kbez) === -1) {
        return reject();
      }
      const url = $stateHref("root.astorganisationen.details", { id: antrag.value.antragstellerorg });
      window.open(`${ url }?asr_nummer=${ error.modul.regelnummer }`, "_blank");
      return resolve();
    });
  };

  return {
    goToModule,
    loadAstRegeln,
  };
}
