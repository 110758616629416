import GutachtenErgebnisseBox from "./GutachtenErgebnisseBox/GutachtenErgebnisseBox.vue";
import PuxGet from "../../../global/components/PuxGet/PuxGet.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import PermissionMixin from "../../../global/mixins/PermissionMixin";

import FiltersAPI from "../../../global/compositionAPI/FiltersAPI";

import {
  cloneDeep,
  forEach,
  isEmpty,
  keyBy,
} from "lodash-es";

// @vue/component
export default {
  name: "GutachtenErgebnisse",
  components: {
    GutachtenErgebnisseBox,
    PuxGet,
  },
  mixins: [
    HttpMixin,
    PermissionMixin,
  ],
  props: {
    userPermissions: {
      type: Array,
      required: true,
    },
    antragPk: {
      type: String,
      required: true,
    },
    isGroupByTyp: {
      type: Boolean,
      required: true,
    },
    minimal: {
      type: Boolean,
      required: false,
    },
    gutachten: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup() {
    const {
      filterCurrency,
    } = FiltersAPI();

    return {
      filterCurrency,
    };
  },
  data() {
    return {
      ergebnisse: [],
      auswahlKeyByPk: {},
    };
  },
  computed: {
    hasWorkflowPermissionGutachtenView() {
      return this.checkPermissionsSync({
        perm: "gutachten.view",
        permArray: this.userPermissions,
      });
    },

    hasPermissionGutachtenList() {
      return this.checkPermissionsSync({
        perm: "gutachten.list",
      });
    },

    grouped() {
      if (!this.gutachten.length || isEmpty(this.auswahlKeyByPk)) {
        return;
      }
      const GROUPED = {};
      forEach(cloneDeep(this.gutachten), ga => {
        let key = "_TXT_GUTACHTEN_";
        let label = key;
        if (this.isGroupByTyp) {
          key = ga.typ;
          label = ga.typ_bez;
        }
        GROUPED[key] = GROUPED[key] || {
          key: key,
          label: label,
          selected: undefined,
          length: 0,
          linkedLength: 0
        };
        GROUPED[key].length++;
        const GUTACHTEN_AUSWAHL = this.auswahlKeyByPk[ga.ergebnis] || {
          ergebnis: "null",
        };
        GROUPED[key][GUTACHTEN_AUSWAHL.ergebnis] = GROUPED[key][GUTACHTEN_AUSWAHL.ergebnis] || {};
        GROUPED[key][GUTACHTEN_AUSWAHL.ergebnis].gutachten = GROUPED[key][GUTACHTEN_AUSWAHL.ergebnis].gutachten || [];
        ga.canLink = ga.pk && ga.can_link && this.hasPermissionGutachtenList;
        if (ga.canLink) {
          GROUPED[key].linkedLength++;
        }
        GROUPED[key][GUTACHTEN_AUSWAHL.ergebnis].gutachten.push(ga);
      });
      forEach(GROUPED, group => {
        forEach(this.ergebnisseLocal, ergebnis => {
          let percentage = 0;
          if (group[ergebnis.pk]) {
            percentage = (group[ergebnis.pk].gutachten.length / group.length) * 100;
          } else {
            group[ergebnis.pk] = {
              gutachten: [],
            };
          }
          group[ergebnis.pk].percentage = this.filterCurrency(percentage, "%");
          group[ergebnis.pk].lineStyle = { "flex-grow": `${ percentage.toFixed(2) }` };
        });
      });

      return GROUPED;
    },

    ergebnisseLocal() {
      const ERGEBNISSE = cloneDeep(this.ergebnisse);
      ERGEBNISSE.push({
        pk: null,
        gae_bez: "_TXT_GUTACHTEN_ERGEBNIS_IN_BEARBEITUNG_",
        gae_cssklasse: "grey-4",
        gae_icon: "glyphicon-pencil-2"
      });
      return ERGEBNISSE;
    },

    isGrouped() {
      return this.grouped && !isEmpty(this.grouped);
    },
  },
  created() {
    this.loadCatalogData();
  },
  methods: {
    loadCatalogData() {
      Promise.all([
        this.getHttp({
          url: "katalog/",
          urlParams: {
            key: "kgutachtenergebnisse",
          },
          apiSaveId: "kgutachtenergebnisse",
        }),
        this.getHttp({
          url: "katalog/",
          urlParams: {
            key: "kgutachtenergebnisauswahl",
          },
          apiSaveId: "kgutachtenergebnisauswahl",
        })
      ]).then(
        responses => {
          this.ergebnisse = responses[0];
          this.auswahlKeyByPk = keyBy(responses[1], "pk");
        }
      );
    },

    getGutachtenErgebnisTitle(group, gae) {
      return `${ gae.gae_bez } x ${ group[gae.pk].gutachten.length } (${ group[gae.pk].percentage })`;
    },

    getGutachtenErgebnisClass(group, gae) {
      return `${ gae.gae_cssklasse } ${ (group.selected === gae) ? "active" : "" }`;
    },
  },
};
