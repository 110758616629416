"use strict";

import BaseRegelModel from "dias/foerderangebote/models/baseRegelModel";


class FoerderregelAppkontexte extends BaseRegelModel {
  constructor() {
    super("foerderregelappkontexte");
  }

  configRestangular(RestangularConfigurer) {
    RestangularConfigurer.setDefaultHttpFields({ cache: true });
  }
}

export default FoerderregelAppkontexte;
