"use strict";

import BaseModel from "dias/core/models/baseModel";

class Gutachtenvorlagen extends BaseModel {
  constructor() {
    super("gutachtenvorlagen", {
      subPaths: {
        regeln: "regeln",
      }
    });
  }

  regelnExtendModel(model) {
    model.moveUp = () => model.customPOST({}, "nach_oben");
    model.moveDown = () => model.customPOST({}, "nach_unten");
    model.titel = (model.gvr_nummer ? ("[" + model.gvr_nummer + "] ") : "") + model.gvr_kbez;
    return model;
  }
}

export default Gutachtenvorlagen;
