import PuxIcon from "../../../../global/components/PuxIcon/PuxIcon.vue";

import { isArray } from "lodash-es";

// @vue/component
export default {
  name: "NachrichtenListCellEmpfaenger",
  components: {
    PuxIcon,
  },
  props: {
    empfaenger: {
      type: [Object, Array],
      required: true
    }
  },
  computed: {
    isEmpfaengerArray() {
      return isArray(this.empfaenger);
    },
  },
};
