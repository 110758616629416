"use strict";

import angular from "angular";

import diasNotification from "dias/core/notification";
import diasModal from "dias/core/modal";
import diasLoading from "dias/core/loading";

import models from "./models";
import aufgaben from "./aufgaben";
import kaufgaben from "./kaufgaben";
import workflowtypen from "./kworkflowtypen";
import workflowfolgen from "./workflowfolgen";
import workflowfolgeclientfunktionen from "./workflowfolgeclientfunktionen";
import workflowfolgeserverfunktionen from "./workflowfolgeserverfunktionen";
import workflowfolgestatusfunktionen from "./workflowfolgestatusfunktionen";
import editorComponent from "./editor.component";
import termineComponent from "./termine.component";
import workflowfolgeComponent from "./workflowfolgen.component";

const workflowComponent = angular.module("dias.workflows", [
  "mgcrea.ngStrap.tooltip",
  models.name,
  aufgaben.name,
  kaufgaben.name,
  workflowfolgen.name,
  workflowtypen.name,
  workflowfolgeclientfunktionen.name,
  workflowfolgeserverfunktionen.name,
  workflowfolgestatusfunktionen.name,
  diasNotification.name,
  diasModal.name,
  diasLoading.name
]);

/*@ngInject*/
workflowComponent.config($stateProvider => {
  $stateProvider
    .state("root.workflows", {
      url: `/workflows/?
            user_cookie&
            workflowtyp
      `,
      reloadOnSearch: false,
      views: {
        "main@root": {
          template: "<div vueinit><vue-workflows-list></vue-workflows-list></div>"
        }
      },
      data: {
        permissions: "workflow.ui"
      }
    })

    .state("root.workflows.details", {
      url: ":id/",
      views: {
        "main@root": {
          template: "<div vueinit><workflows-details></workflows-details></div>"
        }
      },
    })
    .state("root.termine", {
      url: "/termine/",
      views: {
        "main@root": {
          template: "<termine></termine>"
        }
      },
    })
  ;
})

  .component("workflowEditor", editorComponent)
  .component("termine", termineComponent)
  .component("workflowfolgen", workflowfolgeComponent)
;

export default workflowComponent;
