"use strict";
import SaveCallbackActions from "../../../../../vue/client/vue/const/SaveCallbackActions";
import {
  cloneDeep,
  forEach,
  get,
  isArray,
  isObject,
  isString,
} from "lodash-es";

class BasisformularDetailsController {
  /*@ngInject*/
  constructor(
    $q,
    $filter,
    $state,
    $stateParams,
    $timeout,
    $anchorScroll,
    $window,
    i18n,
    diasConfirmDialog,
    diasModalDialog,
    AuthService,
    DiasNotification,
    Formular,
    Foerderantrag,
    Vertrag,
    Antragstellerorganisationen,
    Sitzungen,
    Auszahlung,
  ) {
    this.$q = $q;
    this.gettext = i18n.gettext;
    this.$filter = $filter;
    this.$state = $state;
    this.$timeout = $timeout;
    this.$anchorScroll = $anchorScroll;
    this.$window = $window;
    this.gettext = i18n.gettext;
    this.$stateParams = $stateParams;
    this.AuthService = AuthService;
    this.DiasNotification = DiasNotification;
    this.Foerderantrag = Foerderantrag;
    this.Vertrag = Vertrag;
    this.Antragstellerorganisation = Antragstellerorganisationen;
    this.Sitzung = Sitzungen;
    this.Auszahlung = Auszahlung;
    this.Formular = Formular;
    this.diasModalDialog = diasModalDialog;
    this.diasConfirmDialog = diasConfirmDialog;
    this.eingereichtStatus = false;
    this.canViewFormularobjekt = false;
    this.objekt = {};
    this.v_id = undefined;
    this.dokBaseUrl = undefined;
    this.lastWfExecution = new Date().toISOString();

    this.stepMap = {};
    this.steps = [];
    this.panels = [];
    this.statusPanelsReady = false;

    this.init();

    this.viewName = "details";

    this.onAufgabeAngenommen = () => {
      this.$timeout(() => {
        this.loadFormular(false);
      });
    };

    this.updateWorkflowfolgen = () => this.lastWfExecution = new Date().toISOString();

    this.setPanelsForNotizen = this.setPanelsForNotizen.bind(this);
    this.resetValidation = this.resetValidation.bind(this);
    this.updateAufgabeFromVue = this.updateAufgabeFromVue.bind(this);
    this.onWorkflowInit = this.onWorkflowInit.bind(this);
    this.onWorkflowStarted = this.onWorkflowStarted.bind(this);
    this.onWorkflowFinish = this.onWorkflowFinish.bind(this);
    this.onWorkflowCanceled = this.onWorkflowCanceled.bind(this);
    this.onWorkflowSuccess = this.onWorkflowSuccess.bind(this);
    this.onWorkflowFailure = this.onWorkflowFailure.bind(this);
    this.saveCallback = this.saveCallback.bind(this);
  }

  init() {
    this.loading = {
      formular: true,
    };
    this.initNotizenData();
    this.initTimelineData();
    this.loadFormular(false).then(
      () => this.loadFormularobjekt()
    ).finally(
      () => this.loading.formular = false
    );
  }

  initNotizenData() {
    this.notizOptions = {
      fuerSitzung: true,
      orgChoices: [],
    };
  }

  initTimelineData() {
    // Timeline
    this.timelineConfig = {
      kontexte: [
        {
          titel: "Bearbeitung",
          key: "F",
        },
      ],
      benachrichtigungen: {
        bezug: "formular",
        bezugpk: this.$stateParams.id,
        autorefresh: true,
      },
      url: `formulare/${ this.$stateParams.id }/timeline/`,
    };
  }

  saveCallback({ statusSaveCallback } = {}) {
    if (statusSaveCallback === SaveCallbackActions.RELOAD_WF) {
      this.updateWorkflowfolgen();
    } else if (statusSaveCallback === SaveCallbackActions.RELOAD_ALL) {
      this.loadFormular();
      this.updateWorkflowfolgen();
    }
  }

  updateFormularForObjekt() {
    switch (this.formular.herkunft) {
    case "foerderantrag": {
      if (this.AuthService.syncHasPerm("foerderantrag.view")) {
        this.formular.objekt_service = this.Foerderantrag;
        this.formular.req_perm = "foerderantrag.view";
        this.formular.label = this.gettext("Antrag");
        this.formular.details = "root.foerderantrag.details";
        this.formular.infix = " Nr.";
        if (this.formular.dokcontainermodus !== "formular") {
          this.dokBaseUrl = `foerderantraege/${ this.formular.for_objektid }/`;
        }
      }
      break; }
    case "vertrag": {
      if (this.AuthService.syncHasPerm("vertrag.view")) {
        this.formular.objekt_service = this.Vertrag;
        this.formular.req_perm = "vertrag.view";
        this.formular.label = this.gettext("Vertrag");
        this.formular.details = "root.vertrag.details";
        this.formular.infix = " Nr.";
        if (this.formular.dokcontainermodus !== "formular") {
          this.dokBaseUrl = `vertraege/${ this.formular.for_objektid }/`;
        }
      }
      break; }
    case "antragstellerorganisation": {
      if (this.AuthService.syncHasPerm("antragstellerorg.view")) {
        this.formular.objekt_service = this.Antragstellerorganisation;
        this.formular.req_perm = "antragstellerorg.view";
        this.formular.label = this.gettext("Antragstellerorganisation");
        this.formular.details = "root.astorganisationen.details";
        this.formular.infix = "";
        if (this.formular.dokcontainermodus !== "formular") {
          this.dokBaseUrl = `antragstellerorganisationen/${ this.formular.for_objektid }/`;
        }
      }
      break; }
    case "sitzung": {
      if (this.AuthService.syncHasPerm("sitzungen.view")) {
        this.formular.objekt_service = this.Sitzung;
        this.formular.req_perm = "sitzungen.view";
        this.formular.label = this.gettext("Sitzung");
        this.formular.details = "root.sitzungen.details";
        this.formular.infix = "";
        if (this.formular.dokcontainermodus !== "formular") {
          this.dokBaseUrl = `sitzungen/${ this.formular.for_objektid }/`;
        }
      }
      break; }
    case "auszahlung": {
      if (this.AuthService.syncHasPerm("auszahlung.view")) {
        this.formular.objekt_service = this.Auszahlung;
        this.formular.req_perm = "auszahlung.view";
        this.formular.label = this.gettext("Auszahlung");
        this.formular.details = "root.vertrag.details.auszahlung";
        this.formular.infix = "";
        if (this.formular.dokcontainermodus !== "formular") {
          this.dokBaseUrl = `auszahlungen/${ this.formular.for_objektid }/`;
        }
      }
      break; }
    }
  }

  loadFormularobjekt() {
    let promise;
    this.loading.objekt = true;
    // todo: Link-Anzeige generalisieren wenn möglich.
    this.updateFormularForObjekt();
    if (this.formular.objekt_service) {
      promise = this.formular.objekt_service.one(this.formular.for_objektid).get();
    } else {
      const defer = this.$q.defer();
      promise = defer.promise;
      defer.resolve();
    }
    return promise.then(
      response => {
        this.objekt = response;
        if (this.objekt) {
          this.canViewFormularobjekt = this.AuthService.syncHasPerms([this.formular.req_perm], this.objekt.user_permissions)[this.formular.req_perm];
          if (this.objekt.vertrag) {
            this.v_id = this.objekt.vertrag;
          }
        }
      }
    ).finally(() => this.loading.objekt = false);
  }

  updatePermissions() {
    this.permissions = this.AuthService.syncHasPerms([
      this.formular.permissionset + ".update",
      this.formular.permissionset + ".create",
    ], this.formular.user_permissions);
    this.editPerm = this.formular.permissionset + ".update";
    this.deletePerm = this.formular.permissionset + ".delete";
  }

  onValidate() {
    this.validationErrorsLoading = true;
    this.formular.customGET("validieren").then(
      result => {
        if (result && isArray(result)) {
          const errData = [];
          forEach(result, (modulErr, key) => {
            errData[key] = cloneDeep(modulErr);
            forEach(errData[key][1], _err => {
              forEach(_err.errors, (_errors, field) => {
                if (isObject(_errors) && !isArray(_errors)) {
                  _err.errors[field] = _errors;
                } else if (!isArray(_errors) || !isString(_errors[0])) {
                  _err.errors[field] = [this.gettext("Bitte prüfen Sie die Eingaben.")];
                }
              });
            });
          });
          this.validationErrors = errData;
        } else {
          this.validationErrors = result;
        }
      },
      () => this.DiasNotification.page.error("Fehler beim Prüfen des Formulars")
    ).finally(() => {
      this.validationErrorsLoading = false;
      this.scrollTop();
    });
  }

  resetValidation() {
    this.$timeout(() => {
      this.validationErrorsLoading = undefined;
      this.validationErrors = undefined;
    });
  }

  scrollTop() {
    $(window).scrollTop(0);
  }

  getIdxAndKeyForModul(modul) {
    let stepinfo = null;
    if (modul === null) {
      return stepinfo;
    }
    let rubrik = null;
    for (let i = 0; i < this.module.length; i++) {
      if (modul.regelnummer === this.module[i].regel.regel_nummer) {
        rubrik = this.module[i].regel.rubrik;
      }
    }
    if (!rubrik) {
      return stepinfo;
    }
    angular.forEach(this.anzeigekontextrubriken, axr => {
      if (axr.rubrik === rubrik) {
        for (let i = 0; i < this.steps.length; i++) {
          if (this.steps[i].key === axr.axr_config.key) {
            stepinfo = [i, axr.axr_config.key];
          }
        }
      }
    });
    return stepinfo;
  }

  goToModul(modul) {
    this.$timeout(() => {
      this.$anchorScroll.yOffset = $(window).height() / 2;
      const stepinfo = this.getIdxAndKeyForModul(modul);
      if (stepinfo) {
        this.openStep(stepinfo[0], true);
      }
      if (modul && modul.regelnummer) {
        this.$anchorScroll(modul.regelnummer);
      }
    }, 1000);
  }

  openStep(idx, status) {
    if (status == undefined) {
      this.steps[idx].open = !this.steps[idx].open;
    } else {
      this.steps[idx].open = status;
    }
  }

  loadFormular() {
    return this.Formular.one(this.$stateParams.id).get().then(
      response => {
        if (this.formular && !this.formular.for_formulardatum && response.for_formulardatum) {
          this.eingereichtStatus = true;
        }
        this.formular = response;
        this.updateFormularForObjekt();
        this.updatePermissions();
        this.setUrl();
      },
      error => this.DiasNotification.page.error(error, "Fehler beim Laden des Formulars")
    );
  }

  setUrl() {
    this.formularUrl = `formulare/${ this.formular.pk }/`;
  }

  changeView() {
    this.viewName = this.viewName === "details" ? "timeline" : "details";
  }

  setPanelsForNotizen({ panels }) {
    this.$timeout(() => {
      this.panels = panels;
      this.statusPanelsReady = true;
    });
  }

  updateAufgabeFromVue(newAufgabe) {
    this.$timeout(() => {
      this.updateAufgabe(newAufgabe);
    });
  }

  updateAufgabe(newAufgabe) {
    Object.assign(this.formular.aktuelle_aufgabe, newAufgabe);
  }

  onWorkflowInit(clientFunktion) {
    this.$timeout(() => {
      this.clientFunktion = clientFunktion;
    });
  }

  onWorkflowStarted(newAufgabe, currentWorkflowfolge) {
    this.$timeout(() => {
      this.updateAufgabe(newAufgabe);
      this.currentWorkflowfolge = currentWorkflowfolge;
    });
  }

  onWorkflowFinish() {
    this.$timeout(() => {
      this.clientFunktion = undefined;
      this.currentWorkflowfolge = undefined;
    });
  }

  onWorkflowCanceled() {
    this.$timeout(() => {
      this.reload = false;
    });
  }

  onWorkflowSuccess(aufgabe) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerSuccess(aufgabe);
      }
      this.resetValidation();
      this.scrollTop();
      if (get(this.currentWorkflowfolge, "wfo_reload")) {
        return this.loadFormular(true);
      }
    });
  }

  onWorkflowFailure(err) {
    this.$timeout(() => {
      if (this.clientFunktion) {
        this.clientFunktion.onServerError(err);
      }
      if (err.data && err.data.error_data) {
        this.validationErrors = err.data.error_data;
        this.validationErrorsLoading = false;
      } else if (err.data && !angular.isString(err.data)) {
        this.validationErrors = err.data;
        this.validationErrorsLoading = false;
      } else {
        this.resetValidation();
      }

      if (this.validationErrors && isArray(this.validationErrors)) {
        const errData = [];
        forEach(this.validationErrors, (modulErr, key) => {
          errData[key] = cloneDeep(modulErr);
          forEach(errData[key][1], _err => {
            forEach(_err.errors, (_errors, field) => {
              if (isObject(_errors) && !isArray(_errors)) {
                _err.errors[field] = _errors;
              } else if (!isArray(_errors) || !isString(_errors[0])) {
                _err.errors[field] = [this.gettext("Bitte prüfen Sie die Eingaben.")];
              }
            });
          });
        });
        this.validationErrors = errData;
      }
      this.scrollTop();
      return this.loadFormular();
    });
  }
}

export default BasisformularDetailsController;
