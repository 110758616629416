import PageTabTitle from "../../../global/components/PageTabTitle/PageTabTitle.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";
import Wizard from "../../../global/components/Wizard/Wizard.vue";
import WizardStep1 from "./WizardSteps/WizardStepVorbereiten/WizardStepVorbereiten.vue";
import WizardStep2 from "./WizardSteps/WizardStepAntragsteller/WizardStepAntragsteller.vue";
import WizardStep3 from "./WizardSteps/WizardStepAusfuehren/WizardStepAusfuehren.vue";
import WizardStep4 from "./WizardSteps/WizardStepAnlagen/WizardStepAnlagen.vue";
import WizardStep5 from "./WizardSteps/WizardStepAbschluss/WizardStepAbschluss.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import SyConfigMixin from "../../../global/mixins/SyConfigMixin";
import WizardStepsMixin from "./WizardStepsMixin";

import {
  cloneDeep,
  find,
  isArray,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "ImportauftragAntragWizard",
  components: {
    PageTabTitle,
    PuxCloak,
    PuxTranslate,
    Wizard,
    WizardStep1,
    WizardStep2,
    WizardStep3,
    WizardStep4,
    WizardStep5,
  },
  mixins: [
    HttpMixin,
    SyConfigMixin,
    WizardStepsMixin,
  ],
  data() {
    return {
      stepActive: 0,
      stepsVisited: {
        0: true,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      booleanPropsWizard: {
        backTargetStepDisabled: true,
        backStepHide: true,
        backStepDisabled: true,
        forwardStepHide: undefined,
        forwardStepDisabled: true,
        buttonsLoading: false,
        closeButtonShow: true,
      },
      wizardOptions: {
        forwardStepButtonSuffix: undefined,
      },
      loading: {
        init: true,
        step1: false,
        step2: false,
        step3: false
      },
      dataStep1: {
        angebote: [],
        errors: {},
      },
      modelStep1: {
        angebot: undefined,
        antragsdatum: undefined,
        file: undefined
      },
      dataStep2: {
        auftrag: undefined,
        regelsatz: undefined,
      },
      modelStep2: {
        antragsteller: undefined,
      },
      dataStep3: {
        auftrag: undefined,
        regelsatz: undefined,
        antragsteller: undefined,
        task: undefined,
        taskFailure: false,
        taskError: undefined,
      },
      dataStep3_4: {
        antragId: undefined,
      },
      syConfig: {
        angebote: undefined,
        dokartImportdatei: undefined,
        dokartAnlagen: undefined,
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.iniSyConfigs();
      this.loadData();
    },

    iniSyConfigs() {
      const SY_CONFIGS_IMPORTEXPORTAUFTRAG_ANTRAG = this.getSyConfigsValue("importexportauftrag_antrag");
      if (SY_CONFIGS_IMPORTEXPORTAUFTRAG_ANTRAG) {
        this.syConfig.angebote = SY_CONFIGS_IMPORTEXPORTAUFTRAG_ANTRAG.angebote || [];
        this.syConfig.dokartImportdatei = SY_CONFIGS_IMPORTEXPORTAUFTRAG_ANTRAG.dokart_impexpdatei;
        this.syConfig.dokartAnlagen = SY_CONFIGS_IMPORTEXPORTAUFTRAG_ANTRAG.dokart_anlagen;
      }
    },

    loadData() {
      this.loading.init = true;
      const ANGEBOT_URL = `katalog/?key=angebot&pk=${ this.syConfig.angebote.join("&pk=") }`;
      this.getHttp({
        url: ANGEBOT_URL,
      }).then(
        response => {
          this.dataStep1.angebote = response;
        }
      ).finally(
        () => {
          this.loading.init = false;
        }
      );
    },

    updateModelStep1({ model }) {
      this.modelStep1 = cloneDeep(model);
      if (this.modelStep1.angebot && this.modelStep1.file) {
        this.booleanPropsWizard.forwardStepDisabled = false;
      }
    },

    updateModelStep2({ model }) {
      this.modelStep2 = cloneDeep(model || { antragsteller: undefined });
    },

    goOneStepForward() {
      const NUMBER_STEP_TO_GO = this.stepActive + 2;
      if (isFunction(this[`goForwardToStep${ NUMBER_STEP_TO_GO }`])) {
        this[`goForwardToStep${ NUMBER_STEP_TO_GO }`]();
      } else {
        this.goNextStep();
      }
    },

    goForwardToStep2() {
      this.dataStep1.errors = {};
      this.loading.step1 = true;
      this.booleanPropsWizard.buttonsLoading = true;
      this.booleanPropsWizard.forwardStepDisabled = true;
      const FORM_DATA = new FormData();
      FORM_DATA.append("angebot", this.modelStep1.angebot);
      FORM_DATA.append("antragsdatum", this.modelStep1.antragsdatum);
      FORM_DATA.append("file", this.modelStep1.file.file);
      FORM_DATA.append("iea_impexpdatei_name", this.modelStep1.file.name);
      this.postHttp({
        url: `importauftraege_antrag/`, data: FORM_DATA }).then(
        iea => {
          this.dataStep2.auftrag = iea;
          this.getHttp({ url: `foerderangebote/${ this.modelStep1.angebot }/regelsaetze/${ iea.iea_parameter.regelsatz }/` }).then(
            ars => {
              this.dataStep2.regelsatz = ars;
              this.dataStep3.regelsatz = ars;
            },
            errors => {
              this.dataStep1.errors = cloneDeep(errors);
            }
          ).finally(() => {
            this.booleanPropsWizard.buttonsLoading = false;
            this.booleanPropsWizard.forwardStepDisabled = false;
            this.loading.step1 = false;
            this.goNextStep();
          });
        },
        errors => {
          this.dataStep1.errors = cloneDeep(errors.data);
          this.booleanPropsWizard.buttonsLoading = false;
          this.booleanPropsWizard.forwardStepDisabled = false;
          this.loading.step1 = false;
        }
      );
    },

    goForwardToStep3() {
      this.dataStep2.errors = {};
      this.loading.step2 = true;
      this.booleanPropsWizard.buttonsLoading = true;
      this.booleanPropsWizard.forwardStepDisabled = true;
      this.postHttp({
        url: `importauftraege_antrag/${ this.dataStep2.auftrag.pk }/set_antragsteller/`,
        data: { antragsteller: this.modelStep2.antragsteller || null }
      }).then(
        iea => {
          this.dataStep2.auftrag = iea;
          this.dataStep3.auftrag = iea;
          if (this.modelStep2.antragsteller) {
            this.getHttp({ url: `antragstellerorganisationen/${ this.modelStep2.antragsteller }/` }).then(
              ast => {
                this.dataStep3.antragsteller = ast;
                this.booleanPropsWizard.forwardStepDisabled = true;
              },
              errors => {
                this.dataStep2.errors = cloneDeep(errors);
                this.booleanPropsWizard.forwardStepDisabled = false;
              }
            ).finally(() => {
              this.booleanPropsWizard.buttonsLoading = false;
              this.loading.step2 = false;
              this.goNextStep();
            });
          } else {
            this.booleanPropsWizard.buttonsLoading = false;
            this.booleanPropsWizard.forwardStepDisabled = true;
            this.loading.step2 = false;
            this.goNextStep();
          }
        },
        errors => {
          this.dataStep2.errors = cloneDeep(errors.data);
          this.booleanPropsWizard.buttonsLoading = false;
          this.booleanPropsWizard.forwardStepDisabled = false;
          this.loading.step2 = false;
        }
      );
    },

    goNextStep() {
      this.stepActive += 1;
      this.stepsVisited[this.stepActive] = true;
    },

    goOneStepBack() {
      if (isFunction(this[`goBackToStep${ this.stepActive }`])) {
        this[`goBackToStep${ this.stepActive }`]();
      }
    },

    goBackToStep2() {
      this.stepActive -= 1;
      this.stepsVisited[2] = false;
    },

    goBackToStep1() {
      this.stepActive -= 1;
      this.stepsVisited[1] = false;
    },

    updateModelKontext({ model }) {
      this.model.kontext = model;
      this.booleanPropsWizard.forwardStepDisabled = false;
    },

    initModelFromChild({ model }) {
      this.modelStep2 = model;
    },

    changeModelFromStep2() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getCountDokumente();
      }, 1000);
    },

    taskStart() {
      this.loading.step3 = true;
      this.dataStep3.taskFailure = false;
      this.postHttp({ url: `importauftraege/${ this.dataStep3.auftrag.pk }/ausfuehren/` }).then(
        response => {
          this.dataStep3.task = response.task_id;
        },
        errors => {
          this.dataStep3.taskErrors = errors;
        }
      ).finally(() => this.loading.step3 = false);
    },

    onTaskSuccess() {
      this.getHttp({ url: `importauftraege/${ this.dataStep3.auftrag.pk }/` }).then(
        response => {
          this.dataStep3.auftrag = cloneDeep(response);
          if (isArray(response.objekte)) {
            const IOA_ANTRAG = find(response.objekte, obj => obj.ref_objekt === "foerderantrag");
            if (IOA_ANTRAG) {
              this.dataStep3_4.antragId = IOA_ANTRAG.iao_objektid;
              this.booleanPropsWizard.forwardStepDisabled = false;
            }
          }
        }
      );
    },

    onTaskFailure({ result }) {
      this.dataStep3.taskError = result.data || true;
      this.getHttp({ url: `importauftraege/${ this.dataStep3.auftrag.pk }/` }).then(
        response => this.dataStep3.auftrag = cloneDeep(response)
      );
    },

    onTaskFinish() {
      this.loading.step3 = false;
    },

    resetWizard() {
      // save initial Data
      const SYCONF = cloneDeep(this.syConfig);
      const ANGEBOTE = cloneDeep(this.dataStep1.angebote);
      // Internen Zustand auf initialen Zustand setzen
      Object.assign(this.$data, this.$options.data.apply(this));
      // restore initial Data
      this.syConfig = SYCONF;
      this.dataStep1.angebote = ANGEBOTE;
      this.loading.init = false;
    }
  },
};
