"use strict";

import BaseRegelModel from "dias/foerderangebote/models/baseRegelModel";


class Elementfoerderregeln extends BaseRegelModel {
  constructor() {
    super("elementfoerderregeln");
  }
}

export default Elementfoerderregeln;
