import {
  forEach,
  filter,
} from "lodash-es";

export function initColsFromSyConfig({ currentTable = {} } = {}) {
  const COLS = [];
  if (currentTable.wf_status_columns) {
    forEach(currentTable.wf_status_columns, col => {
      COLS.push({
        label: col.bez,
        id: _wfStatusColumnsKey({ col }),
        sortable: true,
        sortId: _wfStatusColumnsKey({ col }),
        filter: "date",
        hide: true,
      });
    });
  }
  if (currentTable.ffk_columns) {
    forEach(currentTable.ffk_columns, col => {
      const KEY = _ffkColumnsKey({ col });
      COLS.push({
        label: col.bez,
        id: KEY,
        hide: true,
        slot: "ffk_column",
        extra: {
          col: col,
        }
        // templateCallback: ({ row }) => render_ffk_columns({ row, KEY, col }),
      });
    });
  }
  return COLS;
}

export function initFiltersFromSyConfig({ currentTable = {}, gettext } = {}) {
  const FILTERS = [];
  if (currentTable.wf_status_columns) {
    forEach(currentTable.wf_status_columns, col => {
      const KEY = _wfStatusColumnsKey({ col });
      FILTERS.push({
        type: "daterange",
        id: KEY,
        label: col.bez,
        placeholderMin: "Beginn beliebig",
        placeholderMax: "Ende beliebig",
        group: gettext("Bearbeitung"),
      });
    });
  }
  if (currentTable.ffk_columns) {
    forEach(currentTable.ffk_columns, col => {
      const KEY = _ffkColumnsKey({ col });
      FILTERS.push({
        type: "multiselect",
        label: col.bez,
        id: KEY,
        url: `katalog/?fields=pk&fields=bez&key=foerderfinderkategoriewerte&kategorie=${ col.pk }`,
        keyLabel: "bez",
        keyId: "pk",
        group: gettext("Bearbeitung"),
      });
    });
  }
  return FILTERS;
}

function _wfStatusColumnsKey({ col } = {}) {
  let key = "wf_datum_";
  if (col.lookup) {
    key = `${ key }${ col.lookup }_`;
  }
  key = `${ key }${ col.pk.replace(/-/g, "") }`;
  return key;
}

function _ffkColumnsKey({ col } = {}) {
  let key = "ffk_";
  if (col.lookup) {
    key = `${ key }${ col.lookup }_`;
  }
  key = `${ key }${ col.pk.replace(/-/g, "") }`;
  return key;
}

export function render_ffk_columns({ row, KEY, col }) {
  let li = "";
  forEach(filter(row[KEY], ["kategorie", col.pk]), item => li = `${ li }<li>${ item.fkw_kbez }</li>`);
  return `<ul class="list-unstyled">${ li }</ul>`;
}
