export default {
  zugang: {
    label: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_ZUGANG_AKTIVIERT_",
    slotId: "zugang",
    title: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_ZUGANG_AKTIVIERT_",
  },
  suche: {
    label: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_SUCHEN_",
    slotId: "suche",
    title: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_SUCHEN_",
  },
  speichern: {
    label: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_SPEICHERN_",
    slotId: "speichern",
    title: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_SPEICHERN_",
  },
  beschreibung: {
    label: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_BESCHREIBUNG_",
    slotId: "beschreibung",
    title: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_BESCHREIBUNG_",
  },
  registernummer: {
    label: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_VEREINS_HANDELSREGISTERNUMMER_",
    slotId: "registernummer",
    title: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_VEREINS_HANDELSREGISTERNUMMER_",
  },
  vertretungsberechtigte: {
    label: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_VERTRETUNGSBERECHTIGTE_",
    slotId: "vertretungsberechtigte",
    title: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_VERTRETUNGSBERECHTIGTE_",
  },
  bankverbindung: {
    label: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_BANKVERBINDUNG_",
    slotId: "bankverbindung",
    title: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_BANKVERBINDUNG_",
  },
  documents: {
    label: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_NACHWEISE_",
    slotId: "documents",
    title: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_NACHWEISE_",
  },
  regeln: {
    label: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_WEITERE_ANGABEN_",
    slotId: "regeln",
    title: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_WEITERE_ANGABEN_",
  },
  fertig: {
    label: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_FERTIG_",
    slotId: "fertig",
    title: "_TXT_ANTRAGSTELLER_POSSIBLE_STEPS_FERTIG_",
  },
};

const allSteps = [
  "zugang",
  "suche",
  "speichern",
  "beschreibung",
  "registernummer",
  "vertretungsberechtigte",
  "bankverbindung",
  "documents",
  "regeln",
  "fertig"
];

export { allSteps };
