"use strict";

import BaseModel from "dias/core/models/baseModel";

class Workflowfolge extends BaseModel {
  constructor() {
    super("workflowfolgen");
  }

  extendModel(model) {
    super.extendModel(model);
    model.startServerProcess = (obj, userInput) => {
      const params = { obj_id: obj.pk };
      for (const key in userInput) {
        params[key] = userInput[key].wert;
      }
      return model.customPOST(params, "ausfuehren");
    };
    model.getClientKey = () => model.clientfunktion.modulname;
    return model;
  }
}

export default Workflowfolge;
