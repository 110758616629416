import AbhaengigeKostenpauschaleRechnerBelegData from "./BelegButtonVersionsvergleichBetragsrechnerComponents/AbhaengigeKostenpauschaleRechnerBelegVergleich/AbhaengigeKostenpauschaleRechnerBelegVergleich.vue";
import BildungsKostenrechnerBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/BildungsKostenrechnerBelegVergleich/BildungsKostenrechnerBelegVergleich.vue";
import FinanzierungDetailliertBetragsrechnerBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/FinanzierungDetailliertBetragsrechnerBelegVergleich/FinanzierungDetailliertBetragsrechnerBelegVergleich.vue";
import FinanzierungDetailliertNationalBetragsrechnerBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/FinanzierungDetailliertNationalBetragsrechnerBelegVergleich/FinanzierungDetailliertNationalBetragsrechnerBelegVergleich.vue";
import FinanzierungMitLandBetragsrechnerBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/FinanzierungMitLandBetragsrechnerBelegVergleich/FinanzierungMitLandBetragsrechnerBelegVergleich.vue";
import FormElementReadOnly from "../../../../global/components/FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import HonorarkostenOhneQualiBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/HonorarkostenOhneQualiBelegVergleich/HonorarkostenOhneQualiBelegVergleich.vue";
import HonorarkostenrechnerBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/HonorarkostenrechnerBelegVergleich/HonorarkostenrechnerBelegVergleich.vue";
import KategoriemitpauschalerechnerBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/KategoriemitpauschalerechnerBelegVergleich/KategoriemitpauschalerechnerBelegVergleich.vue";
import KostenabhaengigeFinanzierungRechnerBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/KostenabhaengigeFinanzierungRechnerBelegVergleich/KostenabhaengigeFinanzierungRechnerBelegVergleich.vue";
import KostenOhneBezeichnungBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/KostenOhneBezeichnungBelegVergleich/KostenOhneBezeichnungBelegVergleich.vue";
import PersonalkostenBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/PersonalkostenBelegVergleich/PersonalkostenBelegVergleich.vue";
import PersonalkostenLohnjournalBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/PersonalkostenLohnjournalBelegVergleich/PersonalkostenLohnjournalBelegVergleich.vue";
import PersonalkostenMitPktBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/PersonalkostenMitPktBelegVergleich/PersonalkostenMitPktBelegVergleich.vue";
import PersonalkostenPeriodenBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/PersonalkostenPeriodenBelegVergleich/PersonalkostenPeriodenBelegVergleich.vue";
import RolliplaetzerechnerBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/RolliplaetzerechnerBelegVergleich/RolliplaetzerechnerBelegVergleich.vue";
import PuxTranslate from "../../../../global/components/PuxTranslate/PuxTranslate.vue";
import SnapshotIcon from "../../../Snapshot/SnapshotIcon/SnapshotIcon.vue";
import StandardKostenrechnerBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/StandardKostenrechnerBelegVergleich/StandardKostenrechnerBelegVergleich.vue";
import StueckkostenBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/StueckkostenBelegVergleich/StueckkostenBelegVergleich.vue";
import UebernachtungsKostenrechnerBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/UebernachtungsKostenrechnerBelegVergleich/UebernachtungsKostenrechnerBelegVergleich.vue";
// DVV /
import DvvVeranstaltungsrechnerBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/DvvVeranstaltungsrechnerBelegVergleich/DvvVeranstaltungsrechnerBelegVergleich.vue";
import DvvVeranstaltungsrechnerEinfachBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/DvvVeranstaltungsrechnerEinfachBelegVergleich/DvvVeranstaltungsrechnerEinfachBelegVergleich.vue";
// DVV
// Kumasta /
import KumastaEhrenamtskostenrechnerBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/KumastaEhrenamtskostenrechnerBelegVergleich/KumastaEhrenamtskostenrechnerBelegVergleich.vue";
import KumastaHonorarkostenrechnerBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/KumastaHonorarkostenrechnerBelegVergleich/KumastaHonorarkostenrechnerBelegVergleich.vue";
import KumastaStandardfinanzierungrechnerBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/KumastaStandardfinanzierungrechnerBelegVergleich/KumastaStandardfinanzierungrechnerBelegVergleich.vue";
import KumastaStandardkostenrechnerBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/KumastaStandardkostenrechnerBelegVergleich/KumastaStandardkostenrechnerBelegVergleich.vue";
// Kumasta
// MDM /
import MdmKostenMitBegruendungBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/MdmKostenMitBegruendungBelegVergleich/MdmKostenMitBegruendungBelegVergleich.vue";
import MdmRegionaleEffekteBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/MdmRegionaleEffekteBelegVergleich/MdmRegionaleEffekteBelegVergleich.vue";
import MdmRegionaleEffekteMitBegruendungBelegVergleich from "./BelegButtonVersionsvergleichBetragsrechnerComponents/MdmRegionaleEffekteMitBegruendungBelegVergleich/MdmRegionaleEffekteMitBegruendungBelegVergleich.vue";
// MDM

// @vue/component
export default {
  name: "BelegButtonVersionsvergleichBetragsrechner",
  components: {
    AbhaengigeKostenpauschaleRechnerBelegData,
    BildungsKostenrechnerBelegVergleich,
    FinanzierungDetailliertBetragsrechnerBelegVergleich,
    FinanzierungDetailliertNationalBetragsrechnerBelegVergleich,
    FinanzierungMitLandBetragsrechnerBelegVergleich,
    FormElementReadOnly,
    HonorarkostenOhneQualiBelegVergleich,
    HonorarkostenrechnerBelegVergleich,
    KategoriemitpauschalerechnerBelegVergleich,
    KostenabhaengigeFinanzierungRechnerBelegVergleich,
    KostenOhneBezeichnungBelegVergleich,
    PersonalkostenBelegVergleich,
    PersonalkostenLohnjournalBelegVergleich,
    PersonalkostenMitPktBelegVergleich,
    PersonalkostenPeriodenBelegVergleich,
    RolliplaetzerechnerBelegVergleich,
    StueckkostenBelegVergleich,
    PuxTranslate,
    SnapshotIcon,
    StandardKostenrechnerBelegVergleich,
    UebernachtungsKostenrechnerBelegVergleich,
    // DVV /
    DvvVeranstaltungsrechnerBelegVergleich,
    DvvVeranstaltungsrechnerEinfachBelegVergleich,
    // DVV
    // Kumansta /
    KumastaEhrenamtskostenrechnerBelegVergleich,
    KumastaHonorarkostenrechnerBelegVergleich,
    KumastaStandardfinanzierungrechnerBelegVergleich,
    KumastaStandardkostenrechnerBelegVergleich,
    // Kumasta
    // MDM /
    MdmKostenMitBegruendungBelegVergleich,
    MdmRegionaleEffekteBelegVergleich,
    MdmRegionaleEffekteMitBegruendungBelegVergleich,
    // MDM
  },
  props: {
    htmlId: {
      type: String,
      required: true,
    },
    modelErfasst: {
      type: Object,
      required: true,
    },
    modelAnerkannt: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    betragsrechner: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    extra: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    autoFocus: {
      type: Boolean,
      required: false,
    },
    vorschau: {
      type: Object,
      required: true,
    },
    vertrag: {
      type: Object,
      required: false,
      default: undefined,
    },
    position: {
      type: Object,
      required: false,
      default: undefined,
    },
    kontext: {
      type: String,
      required: true,
    },
    view: {
      type: String,
      required: false,
      default: "h",
      info: "view für form-element"
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      betragsrechnerMapping: {
        abhaengige_kostenpauschale_rechner: "AbhaengigeKostenpauschaleRechnerBelegVergleich",
        bildungskostenrechner: "BildungsKostenrechnerBelegVergleich",
        ferienkostenrechner: "FerienkostenrechnerBelegVergleich",
        ferienkostenrechner_mit_reisetagen: "FerienkostenrechnerBelegVergleich",
        finanzierung_detailliert_betragsrechner: "FinanzierungDetailliertBetragsrechnerBelegVergleich",
        finanzierung_detailliert_national_betragsrechner: "FinanzierungDetailliertNationalBetragsrechnerBelegVergleich",
        finanzierung_mit_land_betragsrechner: "FinanzierungMitLandBetragsrechnerBelegVergleich",
        freier_betrag: "StandardKostenrechnerBelegVergleich",
        honorarkosten_ohne_quali: "HonorarkostenOhneQualiBelegVergleich",
        honorarkostenrechner: "HonorarkostenrechnerBelegVergleich",
        kategoriemitpauschalerechner: "KategoriemitpauschalerechnerBelegVergleich",
        kostenabhaengige_finanzierung_rechner: "KostenabhaengigeFinanzierungRechnerBelegVergleich",
        kosten_ohne_bezeichnung: "KostenOhneBezeichnungBelegVergleich",
        personalkosten: "PersonalkostenBelegVergleich",
        personalkosten_lohnjournal: "PersonalkostenLohnjournalBelegVergleich",
        personalkosten_mit_pkt: "PersonalkostenMitPktBelegVergleich",
        personalkosten_perioden: "PersonalkostenPeriodenBelegVergleich",
        rolliplaetzerechner: "RolliplaetzerechnerBelegVergleich",
        standard_finanzierungsrechner: "StandardKostenrechnerBelegVergleich",
        standard_kostenrechner: "StandardKostenrechnerBelegVergleich",
        stueckkosten: "StueckkostenBelegVergleich",
        uebernachtungskostenrechner: "UebernachtungsKostenrechnerBelegVergleich",
        // ...
        // DVV /
        dvv_veranstaltungsrechner: "DvvVeranstaltungsrechnerBelegVergleich",
        dvv_veranstaltungsrechner_einfach: "DvvVeranstaltungsrechnerEinfachBelegVergleich",
        // DVV
        // Kumasta /
        kumasta_ehrenamtskostenrechner: "KumastaEhrenamtskostenrechnerBelegVergleich",
        kumasta_honorarkostenrechner: "KumastaHonorarkostenrechnerBelegVergleich",
        kumasta_standardfinanzierungrechner: "KumastaStandardfinanzierungrechnerBelegVergleich",
        kumasta_standardkostenrechner: "KumastaStandardkostenrechnerBelegVergleich",
        // Kumasta
        // MDM /
        mdm_kosten_mit_begruendung: "MdmKostenMitBegruendungBelegVergleich",
        mdm_regionale_effekte: "MdmRegionaleEffekteBelegVergleich",
        mdm_regionale_effekte_mit_begruendung: "MdmRegionaleEffekteMitBegruendungBelegVergleich",
        // MDM
        // DHW /
        dhw_personalkosten_perioden: "PersonalkostenPeriodenBelegVergleich",
        // DHW
      },
    };
  },
  computed: {
    betragrechnerName() {
      return this.betragsrechnerMapping[this.betragsrechner];
    },
  },
};
