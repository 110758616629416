// Modul zur Beantragung von Rollstuhlplatz-Pauschalen
"use strict";

import foerdermoduleRegistry from "dias/foerderantrag/foerdermodule";
import controller from "./controller";
import template from "./template.jade";
import FoerderantragModels from "dias/foerderantrag/models";
import VertragModels from "dias/vertrag/models";

import builder from "dias/standard_foerdermodule/utils/builder";


const manuelleRueckforderung = angular.module("dias.manuelle_rueckforderung", [
  foerdermoduleRegistry.name,
  FoerderantragModels.name,
  VertragModels.name,
]);

manuelleRueckforderung.config(/*@ngInject*/ FoerdermoduleRegistryProvider => {
  FoerdermoduleRegistryProvider.register("manuelle_rueckforderung", "modul-manuelle-rueckforderung");
})

  .directive("modulManuelleRueckforderung", builder.directive(template, controller));

export default manuelleRueckforderung;
