import Modal from "../../../../../global/components/Modal/Modal.vue";
import PuxButton from "../../../../../global/components/PuxButton/PuxButton.vue";

import translate from "../../../../../global/directives/translate";

import HttpMixin from "../../../../../global/mixins/HttpMixin";
import UserMixin from "../../../../../global/mixins/UserMixin";

import {
  cloneDeep,
  get,
  isUndefined,
  isNil,
  forEach,
} from "lodash-es";

// @vue/component
export default {
  name: "DynamischeTabelleModalCreateOrUpdate",
  components: {
    Modal,
    PuxButton,
  },
  directives: {
    translate,
  },
  mixins: [
    HttpMixin,
    UserMixin,
  ],
  props: {
    spalten: {
      type: Array,
      required: true,
    },
    element: {
      type: Object,
      required: false,
      default: undefined,
    },
    elementIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
    selectorClose: {
      type: [String, Array],
      required: true,
    },
    urlSave: {
      type: String,
      required: true,
    },
    modelDefault: {
      type: Object,
      required: true,
    },
    modelFromModul: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    dataCollection: {
      type: Object,
      required: true,
    },
    obj: {
      type: Object,
      default: () => ({}),
    },
    parameter: {
      type: Object,
      default: () => ({}),
    },
    extraForTranslate: {
      type: Object,
      required: true,
    },
    infoIText: {
      type: String,
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
    reloadObjektData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      saveIndex: undefined,
      model: {},
      status: {
        loading: undefined,
      },
      errors: undefined,
      errorsClone: undefined,
    };
  },
  computed: {
    headerTranslateOptions() {
      return {
        text: this.headerText,
        extra: this.extraForTranslate,
      };
    },

    headerText() {
      return this.element ?
        "_TXT_GR_DYN_TABELLE_MODAL_UPDATE_HEADER_{{element_bezeichnung}}_" :
        "_TXT_GR_DYN_TABELLE_MODAL_CREATE_HEADER_{{element_bezeichnung}}_";
    },

    msgSuccess() {
      return this.element ?
        "_MSG_GR_DYN_TABELLE_MODAL_UPDATE_SUCCESS_{{element_bezeichnung}}_" :
        "_MSG_GR_DYN_TABELLE_MODAL_CREATE_SUCCESS_{{element_bezeichnung}}_";
    },

    msgError() {
      return this.element ?
        "_MSG_GR_DYN_TABELLE_MODAL_UPDATE_ERROR_{{element_bezeichnung}}_" :
        "_MSG_GR_DYN_TABELLE_MODAL_CREATE_ERROR_{{element_bezeichnung}}_";
    },

    optionsModal() {
      return {
        list: this.optionsModalList,
        required: true,
        showCloseButton: true,
        showReadonlyInEdit: true,
      };
    },

    optionsModalList() {
      const LIST = [];
      forEach(cloneDeep(this.spalten), el => {
        if (el.readonly) {
          el.showLabel = true;
        }
        if (el.type === "user") {
          el.text = get(this.model, el.id + "_bez", this.me.name);
        }
        if (el.type === "date" && el.uhrzeit) {
          el.type = "datetime";
        }
        LIST.push(el);
      });
      return LIST;
    },
  },
  created() {
    this.reloadObjektData();
    this.initModel();
    this.initModelForObjektkatalogCols();
    this.initSaveIndex();
  },
  methods: {
    initModel() {
      let model = {};
      if (this.element) {
        model = cloneDeep(this.element);
      } else {
        model = cloneDeep(this.modelDefault);
      }
      model.validierung_erforderlich = true;
      this.model = model;
    },

    initModelForObjektkatalogCols() {
      forEach(this.spalten, col => {
        if (col.type === "single_choice_objektkatalog") {
          const OBJEKT_KATALOG_ID = this.model[col.id];
          let objektKatalogValue = undefined;
          if (OBJEKT_KATALOG_ID) {
            objektKatalogValue = this.dataCollection[col.id] && this.dataCollection[col.id][OBJEKT_KATALOG_ID];
          }
          this.updateObjektkatalogCols({ objektKatalogValue, katalogColId: OBJEKT_KATALOG_ID });
        } else if (col.type === "single_choice") {
          const KATALOG_ID = this.model[col.id];
          let katalogValue = undefined;
          if (KATALOG_ID) {
            katalogValue = this.dataCollection[col.id] && this.dataCollection[col.id][KATALOG_ID];
          }
          this.updateObjektkatalogCols({ katalogValue, katalogColId: KATALOG_ID });
        }
      });
    },

    initSaveIndex() {
      if (!isUndefined(this.elementIndex)) {
        this.saveIndex = this.elementIndex;
      } else {
        this.saveIndex = this.modelFromModul.elemente.length;
      }
    },

    saveLocal() {
      this.status.loading = true;
      this.clearErrors();
      const DATA = this.prepareDataForSave();
      this.save({
        model: DATA,
      }).then(
        () => {
          this.addNotification({
            text: this.msgSuccess,
            extra: this.extraForTranslate,
          });
          this.close();
        },
        error => {
          this.onError({ error: error.data });
          this.addNotification({
            text: this.msgError,
            type: "error",
            extra: this.extraForTranslate,
          });
        }
      ).finally(
        () => this.status.loading = false
      );
    },

    prepareDataForSave() {
      const DATA = cloneDeep(this.modelFromModul);
      if (!isUndefined(this.elementIndex)) {
        DATA.elemente.splice(this.elementIndex, 1, this.model);
      } else {
        DATA.elemente.push(this.model);
      }
      return DATA;
    },

    onError({ error }) {
      if (error && error.elemente && error.elemente[this.saveIndex]) {
        this.errors = error.elemente[this.saveIndex];
        this.errorsClone = error.elemente[this.saveIndex];
      }
    },

    clearErrors() {
      this.errors = undefined;
      this.errorsClone = undefined;
    },

    changeModel({ options, item, currentModel }) {
      if (options.type === "single_choice_objektkatalog") {
        let objektKatalogValue = undefined;
        if (!isNil(currentModel)) {
          objektKatalogValue = item;
        }
        this.updateObjektkatalogCols({ objektKatalogValue, katalogColId: options.id });
      }
      if (options.type === "select") {
        let katalogValue = undefined;
        if (!isNil(currentModel)) {
          katalogValue = item;
        }
        this.updateKatalogCols({ katalogValue, katalogColId: options.id });
      }
    },

    updateObjektkatalogCols({ objektKatalogValue, katalogColId }) {
      const MODEL = cloneDeep(this.model);
      forEach(this.spalten, col => {
        if (!isNil(col.objektkatalog_spalte_id) && col.objektkatalog_spalte_id === katalogColId) {
          if (!isNil(objektKatalogValue) && objektKatalogValue.okw_data) {
            MODEL[col.id] = objektKatalogValue.okw_data[col.objektkatalog_data_id];
          } else {
            MODEL[col.id] = null;
          }
        }
      });
      this.model = MODEL;
    },
    updateKatalogCols({ katalogValue, katalogColId }) {
      const MODEL = cloneDeep(this.model);
      forEach(this.spalten, col => {
        if (!isNil(col.katalog_spalte_id) && col.katalog_spalte_id === katalogColId) {
          if (!isNil(katalogValue)) {
            MODEL[col.id] = katalogValue[col.katalog_data_id];
          } else {
            MODEL[col.id] = null;
          }
        }
      });
      this.model = MODEL;
    },
  },
};
