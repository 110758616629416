import AuszahlungWechselModalitaetData from "./AuszahlungWechselModalitaetData/AuszahlungWechselModalitaetData.vue";
import FormElement from "../../../global/components/FormElement/FormElement.vue";
import PuxAlert from "../../../global/components/PuxAlert/PuxAlert.vue";
import PuxButton from "../../../global/components/PuxButton/PuxButton.vue";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../global/components/PuxTranslate/PuxTranslate.vue";

import HttpMixin from "../../../global/mixins/HttpMixin";
import NotificationMixin from "../../../global/mixins/NotificationMixin";

import {
  cloneDeep,
  get,
  isNil,
  size,
} from "lodash-es";


// @vue/component
export default {
  name: "AuszahlungWechselModalitaetModal",
  components: {
    AuszahlungWechselModalitaetData,
    FormElement,
    PuxAlert,
    PuxButton,
    PuxCloak,
    PuxTranslate
  },
  mixins: [
    HttpMixin,
    NotificationMixin,
  ],
  props: {
    auszahlungPk: {
      type: String,
      required: true,
    },
    vertragPk: {
      type: String,
      required: true,
    },
    onLoading: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      model: {
        auszahlungsplan: undefined,
      },
      auszahlungsplanData: undefined,
      initLoading: true,
      current: undefined,
      preview: {
        modalitaet: undefined,
        title: "_TXT_AUSZAHLUNG_WECHSEL_MODALITAET_RESULT_PREVIEW_TITLE_",
        result: undefined,
        loading: undefined,
        loadingMsg: "_MSG_AUSZAHLUNG_WECHSEL_MODALITAET_PREVIEW_LOADING_",
        errors: undefined
      },
      execute: {
        modalitaet: undefined,
        title: "_TXT_AUSZAHLUNG_WECHSEL_MODALITAET_RESULT_EXECUTE_TITLE_",
        result: undefined,
        loading: undefined,
        loadingMsg: "_MSG_AUSZAHLUNG_WECHSEL_MODALITAET_EXECUTE_LOADING_",
        errors: undefined
      },
    };
  },
  computed: {
    auszahlungUrl() {
      return `vertraege/${ this.vertragPk }/auszahlungen/${ this.auszahlungPk }/`;
    },
    auszahlungsplanOptions() {
      return {
        id: "auszahlungsplan",
        type: "select",
        label: "_LBL_AUSZAHLUNG_WECHSEL_MODALITAET_SELECT_AUSZAHLUNGSPLAN_",
        required: true,
        keyLabelCallback: ({ item }) => `${ item.zp_nummer } - ${ item.name }: ${ item.display }`,
        keyId: "pk",
        search: true,
        searchList: ["zp_nummer", "name", "display"],
        view: "v",
        data: this.auszahlungsplanData,
        translate: true,
        showLabel: true,
        change: this.onChangeSelection
      };
    },
    hasChoices() {
      return size(this.auszahlungsplanData) > 0;
    },
    auszahlungsplanFieldDisabled() {
      return this.loading || !isNil(this.execute.result);
    },
    loading() {
      return this.execute.loading || this.preview.loading;
    },
    showPreviewBtn() {
      return !this.preview.result;
    },
    previewBtnDisabled() {
      return this.loading || !this.model.auszahlungsplan || this.model.auszahlungsplan === this.preview.modalitaet;
    },
    showData() {
      return this.model.auszahlungsplan && this.model.auszahlungsplan === this.preview.modalitaet && !this.execute.result;
    },
    showSuccessAlert() {
      return !isNil(this.execute.result);
    },
    showExecuteBtn() {
      return !this.showPreviewBtn && isNil(this.execute.result);
    },
    executeBtnDisabled() {
      return this.loading || !this.preview.modalitaet || size(get(this.preview, "result.errors", [])) > 0;
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.initLoading = true;
      this.getHttp({
        url: `${ this.auszahlungUrl }modalitaet_wechsel_choices/`
      }).then(
        response => this.auszahlungsplanData = response
      ).finally(() => this.initLoading = false);
    },

    onChangeSelection() {
      this.preview.result = undefined;
      this.preview.errors = undefined;
      this.execute.result = undefined;
      this.execute.errors = undefined;
    },

    doPreview() {
      this.doAction(
        this.preview,
        `${ this.auszahlungUrl }modalitaet_wechsel_preview/`
      );
    },

    doExecute() {
      this.doAction(
        this.execute,
        `${ this.auszahlungUrl }modalitaet_wechsel_execute/`
      );
    },

    setLoading(obj, status) {
      obj.loading = status;
      this.onLoading(this.loading);
    },

    doAction(obj, url) {
      this.current = obj;
      this.setLoading(obj, true);
      const data = cloneDeep(this.model);
      obj.modalitaet = this.model.auszahlungsplan;
      this.postHttp({
        url: url,
        data
      }).then(
        response => {
          obj.result = response;
        },
        error => {
          obj.errors = error.data;
        }
      ).finally(
        () => this.setLoading(obj, false)
      );
    }
  },
};
