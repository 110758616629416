"use strict";

import BaseModel from "dias/core/models/baseModel";
import KAufgaben from "./kaufgabe.service";

class Workflow extends BaseModel {
  constructor() {
    super("workflows", {
      subPaths: {
        aufgaben: new KAufgaben(),
      }
    });
  }
}

export default Workflow;
