// import moment from "moment";
// @vue/component
export default {
  computed: {
    statusAntragDurchfuehrungszeitraumVon() {
      return this.getStatusDurchfuehrungszeitraumVon({ objectName: "antrag", vonOrBis: "von" });
    },

    statusAntragDurchfuehrungszeitraumBis() {
      return this.getStatusDurchfuehrungszeitraumVon({ objectName: "antrag", vonOrBis: "bis" });
    },

    statusVertragDurchfuehrungszeitraumVon() {
      return this.getStatusDurchfuehrungszeitraumVon({ objectName: "vertrag", vonOrBis: "von" });
    },

    statusVertragDurchfuehrungszeitraumBis() {
      return this.getStatusDurchfuehrungszeitraumVon({ objectName: "vertrag", vonOrBis: "bis" });
    },
  },
  methods: {
    getStatusDurchfuehrungszeitraumVon({ objectName, vonOrBis }) {
      return this.durchfuehrung[objectName][vonOrBis] >= this.period.pe_von &&
        this.durchfuehrung[objectName][vonOrBis] <= this.period.pe_bis;
    },
  },
};
